import { Account } from '../../../projectApi/AccountManager/Satellite/list'
import { AccountOption, LoginActions, LoginTypes } from '../types/login'

export type LoginState = {
  username: string
  password: string
  loginSuccess: boolean
  loading: boolean
  loadingAccounts: boolean
  accounts: Account[]
  selectedAccount: AccountOption
  fetchedAccounts: boolean
  error: string
}

export const SG_OPTION = {
  value: 0,
  label: 'SpaceGuru'
}

const initialState = {
  username: '',
  password: '',
  loginSuccess: false,
  loading: false,
  loadingAccounts: false,
  accounts: [],
  selectedAccount: SG_OPTION,
  fetchedAccounts: false,
  error: ''
}

function root(state: LoginState = initialState, action: LoginActions): LoginState {
  switch (action.type) {
    case LoginTypes.SET_USERNAME:
      return { ...state, username: action.payload.username }
    case LoginTypes.SET_PASSWORD:
      return { ...state, password: action.payload.password }
    case LoginTypes.LOGIN_REQUEST:
      return { ...state, loading: true, error: '' }
    case LoginTypes.LOGIN_SUCCESS:
      return { ...state, loading: false, loginSuccess: true }
    case LoginTypes.LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case LoginTypes.GET_PARENT_ACCOUNTS_REQUEST:
      return { ...state, loadingAccounts: true, error: '' }
    case LoginTypes.GET_PARENT_ACCOUNTS_SUCCESS:
      return { ...state, loadingAccounts: false, accounts: action.payload.accounts, fetchedAccounts: true }
    case LoginTypes.GET_PARENT_ACCOUNTS_FAILURE:
      return { ...state, loadingAccounts: false, error: action.payload.error }
    case LoginTypes.SET_ACCOUNT:
      return { ...state, selectedAccount: action.payload.account }
    default:
      return state
  }
}

export default root
