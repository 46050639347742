import types from '../types/locationObjects'

const LIMIT = 50

const initialRoot = {
  open: false,
  objects: [],
  loadingObjects: false,
  id: '',
  params: {
    locationCode: '',
    Limit: LIMIT,
    Offset: 0,
    column: 'id',
    order: 'desc'
  },
  pagination: {
    current: 1,
    total: 0
  },
  searchFilters: [{ key: 'user_id', text: '' }],
  sorter: {
    order: 'descend',
    field: 'id',
    columnKey: 'id'
  },
  filter: {}
}

function locationObjectsReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.FETCH_OBJECTS_REQUEST:
      return {
        ...state,
        objects: [],
        loadingObjects: true,
        error: ''
      }
    case types.FETCH_OBJECTS_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        objects: action.payload.objects,
        loadingObjects: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case types.FETCH_OBJECTS_FAILURE:
      return {
        ...state,
        loadingObjects: false,
        error: action.payload.error
      }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    case types.OPEN_QRMODAL: {
      return {
        ...state,
        open: action.payload.open
      }
    }
    default:
      return state
  }
}

export default locationObjectsReducer
