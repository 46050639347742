import React from 'react'
import { DynamicTable } from '../../../../components/DynamicTable'
import { Column, Pagination } from '../../../../components/DynamicTable/types/types'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { UsersSearchFilter, UsersTableSearchKey } from '../types/usersTable'
import { SatelliteAccount } from '../../../../projectApi/AccountManager/Satellite/corporate'

type UserTableProps = {
  isLoading: boolean
  associatedUsers: SatelliteAccount[]
  pagination: Pagination
  searchFilters: UsersSearchFilter[]
  handleSearch: (key: UsersTableSearchKey, newValue: string) => void
  actions: MenuAction<SatelliteAccount>[]
}

const UsersTable = ({
  isLoading,
  associatedUsers,
  pagination,
  handleSearch,
  searchFilters,
  actions
}: UserTableProps) => {
  const columns: Column<SatelliteAccount>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: 'Nombre'
    },
    {
      key: 'lastName',
      label: 'Apellido'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'role',
      label: 'Rol',
      renderDataCell: ({ role }) => role.descripcion
    }
  ]
  return (
    <DynamicTable
      columns={columns}
      loading={isLoading}
      rows={associatedUsers}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default UsersTable
