import React from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/ObjectsToRemoveSelection'
import objectActionCreators from '../actions/objects'
import CarouselModalActionCreators from '../../../components/CarouselModal/actions/CarouselModal'

const Container = () => {
  const dispatch = useDispatch()
  const objectsActions = bindActionCreators(objectActionCreators, dispatch)
  const objectsState = useSelector((state) => state.NewRemoval.objects)

  const CarouselModalActions = bindActionCreators(CarouselModalActionCreators, dispatch)

  const props = {
    objectsActions,
    objectsState,
    toggleCarrouselOpen: (images: string[]) => CarouselModalActions.setOpen(true, images)
  }

  return <Component {...props} />
}

export default Container
