import { Reducer } from 'redux'
import {
  GET_REMOVAL_DETAILS_FAILURE,
  GET_REMOVAL_DETAILS_REQUEST,
  GET_REMOVAL_DETAILS_SUCCESS,
  SingleRemovalDetailAction,
  SingleRemovalState
} from '../types/singleRemoval'

const initialState: SingleRemovalState = {
  removalDetails: {
    operationCountryCode: '',
    objects: [],
    transportCost: 0,
    tollCostInCents: 0,
    objectsM3: 0,
    additionalCost: 0,
    discountCode: '',
    baseInfo: {
      status: '',
      fullAddress: '',
      id: 0,
      user: {
        createdAt: '',
        updatedAt: '',
        deletedAt: null,
        id: '',
        name: '',
        countryId: '',
        lastName: '',
        phone: ''
      },
      createdAt: null,
      datetime: null
    },
    minCost: 0
  },
  loading: false,
  error: ''
}

const SingleRemovalReducer: Reducer<SingleRemovalState, SingleRemovalDetailAction> = (
  state = initialState,
  action
): SingleRemovalState => {
  switch (action.type) {
    case GET_REMOVAL_DETAILS_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case GET_REMOVAL_DETAILS_SUCCESS:
      return { ...state, loading: false, removalDetails: action.payload.removalDetails }
    case GET_REMOVAL_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default SingleRemovalReducer
