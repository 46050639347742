import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Paging, Role } from './common'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'

export interface APIGuestUser {
  id: number
  created_at: string
  updated_at: string
  email: string
  user: number
  used: boolean
  role: Role
}

export type GuestUser = CamelCasedPropertiesDeep<APIGuestUser>

interface APIResponse {
  paging: Paging
  results: APIGuestUser[]
}

interface Response {
  guestUsers: GuestUser[]
  total: number
}

interface ListParams {
  limit?: number
  offset?: number
  order?: 'asc' | 'desc'
  column?: string
}

export function guestUsersList(params: ListParams): Promise<Response> {
  const formattedParams = {
    order: params.order ?? 'desc',
    column: params.column ?? 'id',
    ...params
  }

  return apiPrivate
    .get(`${URL_BASE_USERS}/user-invites`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return {
        guestUsers: camelcaseKeys(response.data.results, { deep: true }) as GuestUser[],
        total: response.data.paging.total
      }
    })
    .catch((error) => {
      throw error
    })
}
