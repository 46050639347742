import { Moment } from 'moment'
import { SortDirection } from '../components/DynamicTable/types/types'

export type SearchFilter<T extends string> = {
  key: T
  text: string
}

export const searchFiltersToParams = <T extends string>(
  searchFilters: SearchFilter<T>[] = [],
  allowEmptySearchText = false
) =>
  searchFilters.reduce((map, filter) => {
    if (allowEmptySearchText || filter.text.length > 0) return { ...map, [filter.key]: filter.text }
    else return map
  }, {})

export type SingleDateFilter<DateKey extends string> = {
  key: DateKey
  date: Moment | null
}

export const singleDateFiltersToParams = <T extends string>(
  singleDateFilters: SingleDateFilter<T>[] = [],
  allowNullDateValues = false
) => {
  return singleDateFilters.reduce((map, filter) => {
    if (allowNullDateValues || filter.date) return { ...map, [filter.key]: filter.date }
    else return map
  }, {})
}

export type DateRangeFilter<DateRangeKey extends string> = {
  key: DateRangeKey
  startDate: Moment | null
  endDate: Moment | null
}

export enum KeyType {
  SNAKE = 'SNAKE',
  CAMEL = 'CAMEL'
}

const getFromToKey = (keyType: KeyType) =>
  keyType === KeyType.SNAKE ? { from: '_from', to: '_to' } : { from: 'From', to: 'To' }

export const dateRangeFiltersToParams = <T extends string>(
  dateRangeFilters: DateRangeFilter<T>[] = [],
  keyType: KeyType = KeyType.CAMEL,
  allowNullDateValues = false
) => {
  const { from, to } = getFromToKey(keyType)
  return dateRangeFilters.reduce((map, filter) => {
    if (allowNullDateValues || (filter.startDate && filter.endDate))
      return {
        ...map,
        [filter.key + from]: filter.startDate,
        [filter.key + to]: filter.endDate
      }
    else return map
  }, {})
}

export type CategoryFilter<T extends string, S = string> = {
  // TODO: Fix typing for multiple filters
  [key in T]: S[]
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export type Sort<T extends string> = {
  direction: SortDirection
  field: T
}
