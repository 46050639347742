import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'

interface Paging {
  total: number
  limit: number
  offset: number
}

interface APIResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  results: APIDiscount[]
}

interface APIDiscount {
  amount: number
  billing_detail_id?: number
  days: number
  discount_id: number
  id: number
}

export interface Response {
  total: number
  billingDiscounts: Discount[]
}

export type Discount = CamelCasedPropertiesDeep<APIDiscount>

type ListParams = {
  limit: number
  billingId: number
}

export function list(params: ListParams): Promise<Response> {
  const { limit, billingId } = params
  const snakeParams = snakecaseKeys({ billingId, limit })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billing-discounts/search`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse: Response = {
        total: response.data.paging.total,
        billingDiscounts: camelcaseKeys(response.data.results, { deep: true })
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
