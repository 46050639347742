import types from '../types/NewEditDiscount'
import moment from 'moment'

export const initialNewEditDiscount = {
  editMode: false,
  loading: false,
  id: null,
  code: '',
  description: '',
  publicDescription: '',
  transportDiscountPercent: 0,
  depositDiscountPercent: 0,
  depositDiscountMonths: 0,
  startDate: moment(),
  endDate: moment().add(1, 'months'),
  openModal: false,
  error: ''
}

function newEditDiscountReducer(state = initialNewEditDiscount, action) {
  switch (action.type) {
    case types.CREATE_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.CREATE_DISCOUNT_SUCCESS:
      return initialNewEditDiscount
    case types.CREATE_DISCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case types.GET_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        code: action.payload.code,
        description: action.payload.description,
        transportDiscountPercent: action.payload.transportDiscountPercent,
        depositDiscountPercent: action.payload.depositDiscountPercent,
        depositDiscountMonths: action.payload.depositDiscountMonths,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        publicDescription: action.payload.publicDescription,
        loading: false
      }
    case types.GET_DISCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case types.UPDATE_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_DISCOUNT_SUCCESS:
      return initialNewEditDiscount
    case types.UPDATE_DISCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case types.SET_CODE:
      return {
        ...state,
        code: action.payload.code
      }
    case types.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description
      }
    case types.SET_PUBLIC_DESCRIPTION:
      return {
        ...state,
        publicDescription: action.payload.publicDescription
      }
    case types.SET_TRANSPORT_DISCOUNT_PERCENT:
      return {
        ...state,
        transportDiscountPercent: action.payload.transportDiscountPercent
      }
    case types.SET_DEPOSIT_DISCOUNT_PERCENT:
      return {
        ...state,
        depositDiscountPercent: action.payload.depositDiscountPercent
      }
    case types.SET_DEPOSIT_DISCOUNT_MONTHS:
      return {
        ...state,
        depositDiscountMonths: action.payload.depositDiscountMonths
      }
    case types.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload.startDate
      }
    case types.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload.endDate
      }
    case types.SET_OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        id: action.payload.id || '',
        editMode: !!action.payload.id
      }
    case types.SET_CLOSE_MODAL:
      return initialNewEditDiscount
    default:
      return state
  }
}

export default newEditDiscountReducer
