import { apiPrivate } from '@/api'
import { URL_BASE_CATEGORIES } from '@/endpoints'
import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'

type APIProduct = {
  description: string
  weight_in_gr: number
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  catalog_name: string
  category: {
    id: string
    name: string
    catalog_name: string
  }
  product_catalog_id: string
  product_by_countries: {
    name: string
    country_id: string
    photo: {
      url: string
    }
  }[]
  solution: {
    description: string
  }
}

type APIResponse = {
  content: {
    total: number
    products: APIProduct[]
  }
}
export type ProductCatalogue = CamelCasedPropertiesDeep<APIProduct>

type Response = {
  total: number
  products: ProductCatalogue[]
}

interface Params {
  description: string
}

export function searchAll(params: Params): Promise<Response> {
  const { description } = params
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/catalog/search-product`, { params: { description, count: true, limit: 20 } })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Error al obtener los productos')

      const camelCaseResponse: Response = {
        total: response.data.content.total,
        products: response.data.content.products.map((product) => ({
          description: product.description,
          weightInGr: product.weight_in_gr,
          lengthInCm: product.length_in_cm,
          widthInCm: product.width_in_cm,
          heightInCm: product.height_in_cm,
          productCatalogId: product.product_catalog_id,
          catalogName: product.catalog_name,
          category: {
            id: product.category.id,
            name: product.category.name,
            catalogName: product.category.catalog_name
          },
          productByCountries: product.product_by_countries.map((productByCountry) => ({
            name: productByCountry.name,
            countryId: productByCountry.country_id,
            photo: {
              url: productByCountry.photo.url
            }
          })),
          solution: {
            description: product.solution.description
          }
        }))
      }
      return camelCaseResponse
    })
    .catch((error) => {
      throw error
    })
}
