import React from 'react'
import Component from '../components/termsPanel'
import { bindActionCreators } from 'redux'
import actions from '../actions/terms'
import { connect } from 'react-redux'

const Container = (props) => {
  return <Component {...props} />
}

const mapStateToProps = (state) => {
  const { Users } = state
  return {
    ...Users.terms
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
