import {
  GET_TRANSACTIONS_REPORT_FAILURE,
  GET_TRANSACTIONS_REPORT_REQUEST,
  GET_TRANSACTIONS_REPORT_SUCCESS,
  GetTransactionsReportRequest,
  GetTransactionsReportSuccess,
  SET_METRICS_WITH_CONFLICT,
  SetMetricsWithConflict
} from './../types/TransactionsTable'
import moment from 'moment'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { TransactionStatusId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { OperationType, Transaction } from '../../../projectApi/TransactionHandler/Transactions/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  CLEAR_SELECTED_TRANSACTIONS,
  CLEAR_STATE,
  ClearSelectedTransactions,
  ClearState,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_METRICS_FAILURE,
  GET_TRANSACTIONS_METRICS_REQUEST,
  GET_TRANSACTIONS_METRICS_SUCCESS,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GetTransactionsMetricsRequest,
  GetTransactionsMetricsSuccess,
  GetTransactionsRequest,
  GetTransactionsSuccess,
  Pagination,
  TOGGLE_ALL_TRANSACTIONS,
  TOGGLE_SELECTED_TRANSACTION,
  ToggleAllTransactions,
  ToggleSelectedTransaction,
  TransactionsTableCategoryFilter,
  TransactionsTableDateRangeFilter,
  TransactionsTableSearchFilter,
  TransactionsTableSort
} from '../types/TransactionsTable'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

const TransactionsTableActionCreators = {
  getTransactions:
    ({
      pagination,
      searchFilters,
      dateRangeFilters,
      sort,
      categoryFilter,
      silentLoading,
      resetSelectedTransactions,
      conflict
    }: {
      pagination: Pagination
      searchFilters?: TransactionsTableSearchFilter[]
      categoryFilter?: TransactionsTableCategoryFilter
      dateRangeFilters?: TransactionsTableDateRangeFilter[]
      sort?: TransactionsTableSort
      silentLoading?: boolean
      resetSelectedTransactions?: boolean
      conflict?: boolean
    }): AppThunk =>
    async (dispatch) => {
      const request: GetTransactionsRequest = {
        type: GET_TRANSACTIONS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          dateRangeFilters,
          searchFilters,
          categoryFilter,
          sort,
          silentLoading,
          resetSelectedTransactions,
          conflict
        }
      }

      dispatch(request)

      try {
        const { transactions, total } = await API.TransactionHandler.Transactions.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          sort: sort?.field,
          order: sort?.direction,
          conflict,
          status: categoryFilter?.status as TransactionStatusId[],
          typeTransaction: categoryFilter?.transactionType.join(),
          operationType: categoryFilter?.operationType[0] as OperationType,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters),
          now: moment()
        })

        const success: GetTransactionsSuccess = {
          type: GET_TRANSACTIONS_SUCCESS,
          payload: {
            transactions,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_TRANSACTIONS_FAILURE, dispatch)
      }
    },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  clearSelectedTransactions: (): ClearSelectedTransactions => ({ type: CLEAR_SELECTED_TRANSACTIONS }),
  toggleAllTransactions: (): ToggleAllTransactions => ({ type: TOGGLE_ALL_TRANSACTIONS }),
  toggleSelectedTransaction: (transaction: Transaction): ToggleSelectedTransaction => ({
    type: TOGGLE_SELECTED_TRANSACTION,
    payload: {
      transaction
    }
  }),
  getTransactionMetrics:
    ({ countryCode, silentLoading }: { countryCode: CountryIdCode; silentLoading?: boolean }): AppThunk =>
    async (dispatch) => {
      const request: GetTransactionsMetricsRequest = {
        type: GET_TRANSACTIONS_METRICS_REQUEST,
        payload: { silentLoading }
      }

      dispatch(request)

      try {
        const data = await API.TransactionHandler.Transactions.Metrics.list({ countryCode, date: moment() })
        const success: GetTransactionsMetricsSuccess = {
          type: GET_TRANSACTIONS_METRICS_SUCCESS,
          payload: {
            metrics: data
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_TRANSACTIONS_METRICS_FAILURE, dispatch)
      }
    },
  getTransactionsReport:
    ({
      pagination,
      searchFilters,
      dateRangeFilters,
      sort,
      categoryFilter,
      conflict
    }: {
      pagination: Pagination
      searchFilters?: TransactionsTableSearchFilter[]
      categoryFilter?: TransactionsTableCategoryFilter
      dateRangeFilters?: TransactionsTableDateRangeFilter[]
      sort?: TransactionsTableSort
      conflict?: boolean
    }): AppThunk =>
    async (dispatch) => {
      const request: GetTransactionsReportRequest = {
        type: GET_TRANSACTIONS_REPORT_REQUEST
      }

      const toast = setToastLoading('Generando reporte, por favor espere...')

      dispatch(request)

      try {
        await API.TransactionHandler.Transactions.list({
          limit: 50,
          offset: (pagination.page - 1) * pagination.pageSize,
          sort: sort?.field,
          order: sort?.direction,
          conflict,
          status: categoryFilter?.status as TransactionStatusId[],
          typeTransaction: categoryFilter?.transactionType.join(),
          operationType: categoryFilter?.operationType[0] as OperationType,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters),
          now: moment(),
          format: 'csv'
        })

        const success: GetTransactionsReportSuccess = {
          type: GET_TRANSACTIONS_REPORT_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Reporte generado con éxito. Revise su correo electrónico.' })
      } catch (error) {
        handleErrorsWithAction(error, GET_TRANSACTIONS_REPORT_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'Error al generar el reporte.' })
      }
    },
  setConflict: (conflict: boolean): SetMetricsWithConflict => ({
    type: SET_METRICS_WITH_CONFLICT,
    payload: { conflict }
  })
}

export default TransactionsTableActionCreators
