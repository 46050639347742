import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/vehiclesTable'
import {
  VehiclesSearchFilter,
  GetVehicleListRequest,
  GetVehicleListSuccess,
  GetVehiclesListTypes,
  RESET_FILTERS,
  SendVehiclesCSVTypes,
  SendVehiclesCSVRequest,
  SendVehiclesCSVSuccess,
  VehiclesTableSort
} from '../types/vehiclesTable'

const CoporativeDepositListActionsCreator = {
  getVehicles({
    pagination,
    sort,
    searchFilters
  }: {
    pagination: Pagination
    sort: VehiclesTableSort
    searchFilters?: VehiclesSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetVehicleListRequest = {
        type: GetVehiclesListTypes.GET_VEHICLE_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          sort,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { vehicles, total } = await API.Trips.Vehicles.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          column: sort.field,
          order: sort.direction
        })

        const success: GetVehicleListSuccess = {
          type: GetVehiclesListTypes.GET_VEHICLE_SUCCESS,
          payload: {
            vehicles,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetVehiclesListTypes.GET_VEHICLE_FAILURE, dispatch)
      }
    }
  },

  sendVehiclesCSV({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: VehiclesSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: SendVehiclesCSVRequest = {
        type: SendVehiclesCSVTypes.SEND_VEHICLES_CSV_REQUEST
      }

      const toast = setToastLoading('Enviando CSV. Por favor espere...')

      dispatch(request)

      try {
        await API.Trips.Vehicles.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          format: 'csv'
        })

        const success: SendVehiclesCSVSuccess = {
          type: SendVehiclesCSVTypes.SEND_VEHICLES_CSV_SUCCESS
        }

        dispatch(success)

        setToastSuccessUpdate(toast, { render: 'CSV enviado. Revise su correo electrónico.' })
      } catch (error) {
        handleErrorsWithAction(error, GetVehiclesListTypes.GET_VEHICLE_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'Hubo un error al enviar el CSV. Por favor, intente mas tarde.' })
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.Vehicles.CLEAN_FILTERS), 50)
    }
  }
}

export default CoporativeDepositListActionsCreator
