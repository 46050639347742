import React from 'react'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import nextStatusActionCreators from '../actions/nextStatus'
import { NextStatusState } from '../reducers/nextStatus'
import { CloseRemovalTypes } from '../constants'
import OptionsModal from '../../../components/OptionsModal'

type CloseRemovalProps = {
  nextStatusActions: typeof nextStatusActionCreators
  nextStatusState: NextStatusState
}

const options = [
  { label: 'Devolución total', value: CloseRemovalTypes.TOTALLY },
  { label: 'Devolución parcial', value: CloseRemovalTypes.PARTIALLY }
]

const CloseRemovalModal = (props: CloseRemovalProps) => {
  const { nextStatusActions, nextStatusState } = props
  const { openCloseRemovalModal, loading, closeRemoval, removalId } = nextStatusState

  const onCloseRemovalChange = (e: RadioChangeEvent) => nextStatusActions.setCloseRemoval(e.target.value)

  const onClose = () => nextStatusActions.toggleCloseRemovalModal(false)
  const onOk = () => nextStatusActions.closeRemoval(removalId, closeRemoval)

  const modalProps = {
    title: 'Informar cierre',
    visible: openCloseRemovalModal,
    closable: !loading,
    maskClosable: !loading,
    onCancel: onClose,
    onOk: onOk,
    centered: true,
    okText: 'Continuar',
    cancelText: 'Cancelar',
    cancelButtonProps: { disabled: loading },
    style: { maxWidth: '500px' },
    bodyStyle: { padding: 0 },
    okButtonProps: {
      loading,
      disabled: loading
    }
  }

  const optionsProps = {
    options,
    selectedOption: closeRemoval,
    onOptionChange: onCloseRemovalChange
  }

  return <OptionsModal modalProps={modalProps} optionsProps={optionsProps} />
}

export default CloseRemovalModal
