import React from 'react'
import { Modal } from 'antd'
import styles from './cardsModal.module.scss'
import { Info } from '../../../icons'
import CreditCardComponent from '../../../components/CreditCard/container'

export type identificationTypes = {
  id: number
  type: string
  country: {
    id: string
    name: string
  }
  rules: {
    input_type: string
    min: number
    max: number
  }
}

export interface CardsModalProps {
  open: boolean
  okDisabled: boolean
  handleClose: () => void
  onOk: () => void
}

const CardsModal = ({ open, okDisabled, handleClose, onOk }: CardsModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={handleClose}
      title="Agregar tarjeta"
      visible={open}
      okText="Agregar"
      cancelText="Cancelar"
      onOk={onOk}
      okButtonProps={{ disabled: okDisabled }}
      destroyOnClose>
      <div className={styles.tipContainer}>
        <div className={styles.tipContent}>
          <Info fill="#333" />
          <p className={styles.tipParagraph}>Al agregarse la tarjeta se marcará como principal</p>
        </div>
      </div>
      <CreditCardComponent />
    </Modal>
  )
}

export default CardsModal
