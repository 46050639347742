import { Tooltip } from 'antd'
import React from 'react'
import styles from './ColorBadge.module.scss'
import clsx from 'clsx'

type ColorBadgeProps = {
  backgroundColor: string
  borderColor: string
  className?: string
  border?: string
  tooltip?: string
  extraStyles?: React.CSSProperties
}

const ColorBadge: React.FC<ColorBadgeProps> = ({
  backgroundColor,
  borderColor,
  className,
  children,
  border,
  extraStyles,
  tooltip
}) => {
  const badgeClassName = clsx(styles.ColorBadge, className)
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <div
          className={badgeClassName}
          style={{ backgroundColor, borderColor, color: borderColor, border, ...extraStyles }}>
          {children}
        </div>
      </Tooltip>
    )
  } else
    return (
      <div
        className={badgeClassName}
        style={{ backgroundColor, borderColor, color: borderColor, border, ...extraStyles }}>
        {children}
      </div>
    )
}

export default ColorBadge
