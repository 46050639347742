import StatusCard from '@/components/StatusCard'
import { StatusCardContainer } from '@/components/StatusCard/subComponents/StatusCardContainer'
import { Metrics } from '@/projectApi/Complaints/metrics'
import React from 'react'
import styles from './Metrics.module.scss'
import { formatNumber } from '@/utils/formatNumber'
import { MultiStatsCards, StatsCardItem } from '@/components/MultiStatsCard/MultiStatsCard'

export type ObjectMetricsProps = {
  metrics: Metrics
  loading: boolean
}

export const ComplaintsMetrics: React.FC<ObjectMetricsProps> = ({ metrics, loading }) => {
  return (
    <StatusCardContainer fetchingMetrics={loading} lengthSkeletons={6}>
      <StatusCard
        key={mapOfRemovalsMetrics.deposit}
        titleCard={mapOfRemovalsMetrics.deposit}
        total={formatNumber(metrics.deposit, 0, true)}
        customClassname={styles.longerCard}
        useOnlyCustomClass={true}
        titleStyles={styles.title}
      />
      <StatusCard
        key={mapOfRemovalsMetrics.removal}
        titleCard={mapOfRemovalsMetrics.removal}
        total={formatNumber(metrics.removal, 0, true)}
        customClassname={styles.longerCard}
        useOnlyCustomClass={true}
        titleStyles={styles.title}
      />

      <div>
        <p className={styles.divider}></p>
      </div>

      <MultiStatsCards>
        <StatsCardItem title={mapOfRemovalsMetrics.new} total={metrics.new} />
        <StatsCardItem title={mapOfRemovalsMetrics.newDelayed} total={metrics.newDelayed} />
      </MultiStatsCards>
      <MultiStatsCards backgroundColor="#FFF8EB" borderColor="#B25E09">
        <StatsCardItem title={mapOfRemovalsMetrics.open} total={metrics.open} textColor="#B25E09" />
        <StatsCardItem title={mapOfRemovalsMetrics.openDelayed} total={metrics.openDelayed} textColor="#B25E09" />
      </MultiStatsCards>

      <StatusCard
        key={mapOfRemovalsMetrics.closed}
        titleCard={mapOfRemovalsMetrics.closed}
        total={formatNumber(metrics.closed, 0, true)}
        type="success"
        width={'10rem'}
        description={'Reclamos resueltos + Reclamos atrasados'}
      />
    </StatusCardContainer>
  )
}

enum mapOfRemovalsMetrics {
  deposit = 'Reclamos por ingresos',
  new = 'Nuevos reclamos',
  newDelayed = 'Atrasados',
  open = 'Reclamos en curso',
  openDelayed = 'Atrasados',
  removal = 'Reclamos por devoluciones',
  closed = 'Reclamos resueltos'
}
