import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/removals'
import moment from 'moment'
import { eqProps, equals, forEachObjIndexed, isEmpty } from 'ramda'
import ValidationsTable, { DateFilters } from '../component/removals'
import { PaginationConfig, SorterResult } from 'antd/es/table'
import { Order, Params, RemovalFilter, RemovalSearchFilter } from '../types/removals'
import { DEFAULT_STATUS_FILTER } from '../reducers/removals'
import nextStatusActionCreators from '../actions/nextStatus'
import { useCountries } from '../../../components/CountrySelector/hooks/hooks'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import removalValidationMetricsActionsCreator from '../actions/metrics'
import ValidationsMetrics from '../component/ValidationMetrics'
import { RadioChangeEvent } from 'antd/es/radio'

moment.locale('es')

const getDateValue = (value: DateFilters): [string, string] => {
  if (value === DateFilters.TOMORROW)
    return [moment().add(1, 'days').format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
}

const Removals = () => {
  const dispatch = useDispatch()
  const [dateFilterValue, setDateFilterValue] = useState(null)
  const removalTableActions = bindActionCreators(actions, dispatch)
  const nextStatusActions = bindActionCreators(nextStatusActionCreators, dispatch)
  const { getMetrics } = bindActionCreators(removalValidationMetricsActionsCreator, dispatch)

  const { table } = useSelector((state) => state.RemovalsValidation)
  const validationsMetrics = useSelector((state) => state.RemovalsValidation.metrics)
  const countries = useCountries()
  const {
    params,
    searchFilters: currentSearchFilters,
    filter: currentFilter,
    sorter: currentSorter,
    pagination: currentPagination
  } = table

  useEffect(() => {
    removalTableActions.getRemovals(params)
    getMetrics({})
  }, [])

  useEvents(Events.Global.CHANGE_COUNTRY, () => {
    removalTableActions.getRemovals(params)
    getMetrics({})
  })
  useEvents(Events.RemovalsValidation.CLEAN_FILTERS, () => removalTableActions.getRemovalsFromStore())

  const handleButtonFilterChange = (e: RadioChangeEvent) => {
    const { value } = e.target

    const dateValue = getDateValue(value)

    setDateFilterValue(value)
    handleDateRangeSearch('transport_datetime', dateValue)
  }

  const handleTransportDateRangeSearch = (dataIndex: string, text: [string, string], confirm: () => void) => {
    setDateFilterValue(null)
    handleDateRangeSearch(dataIndex, text, confirm)
  }

  const cleanFilters = () => {
    setDateFilterValue(null)
    removalTableActions.cleanFilters()
    getMetrics({})
  }

  const getMultipleFiltersValue = (filters: string[]): string => {
    const stringFilters = filters.reduce((acc: string, filter: string) => acc + `${filter},`, '')
    return stringFilters.substring(0, stringFilters.length - 1)
  }

  const getNewFilterParams = (params: Params, filters: RemovalFilter): Params => {
    let formatFilters = {
      status: DEFAULT_STATUS_FILTER
    }
    const Offset = 0
    forEachObjIndexed((value, key) => {
      const valueFilter = value && value.length > 0 ? getMultipleFiltersValue(value) : value?.[0]
      if (valueFilter) formatFilters = { ...formatFilters, [getKeyFilter(key)]: valueFilter }
    }, filters)
    return { ...params, ...formatFilters, Offset }
  }

  const handleDateRangeSearch = (dataIndex: string, text: [string, string], confirm = () => {}) => {
    const { setSearchFilters, setPagination, getRemovals } = removalTableActions
    const searchFilters = currentSearchFilters.map((searchFilter: RemovalSearchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )
    const dateParams = {
      [`${dataIndex}_from`]: !isEmpty(text) ? text[0] : undefined,
      [`${dataIndex}_to`]: !isEmpty(text) ? text[1] : undefined
    }
    const newParams = { ...params, ...dateParams }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    getRemovals(newParams)

    confirm()
    if (dataIndex === 'created_at') {
      const dateFrom = text[0] ? moment(text[0]) : undefined
      const dateTo = text[1] ? moment(text[1]) : undefined

      getMetrics({ dateFrom, dateTo })
    }
  }

  const handleSearch = (dataIndex: string, text: string, confirm: () => void) => {
    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text
          }
        : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    removalTableActions.setSearchFilters(searchFilters)
    removalTableActions.setPagination({ ...currentPagination, current: 1 })
    removalTableActions.getRemovals(newParams)

    confirm()
  }

  const handleChange = (pagination: PaginationConfig, filters: RemovalFilter, sorter: SorterResult<unknown>) => {
    if (!equals(filters, currentFilter)) {
      const newParams = { ...getNewFilterParams(params, filters) }

      removalTableActions.setFilter(filters)
      removalTableActions.setPagination({ ...currentPagination, current: 1 })
      removalTableActions.getRemovals(newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order, field } = sorter
      const Order = getOrder(order)
      const Column = getColumn(field)
      const Offset = 0

      removalTableActions.setSorter(sorter)
      removalTableActions.setPagination({ ...currentPagination, current: 1 })
      removalTableActions.getRemovals({ ...params, Offset, Column, Order })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current = 1, pageSize = 10 } = pagination
      const Offset = (current - 1) * pageSize
      const newParams = { ...params, Offset }

      removalTableActions.setPagination(pagination)
      removalTableActions.getRemovals(newParams)
    }
  }

  const getOrder = (order: string): Order => (order === 'descend' ? Order.DESC : Order.ASC)

  const getKeyFilter = (key: string): string => {
    switch (key) {
      case 'TransactionDescription':
        return 'type'
      case 'Status':
        return 'status'
      default:
        return key
    }
  }

  const getColumn = (column: string): string => {
    switch (column) {
      case 'user[id]':
        return 'user_id'
      case 'total_storage_cost':
        return 'storage_cost'
      default:
        return column
    }
  }

  const metricsProps = {
    metrics: validationsMetrics.quantityMetrics,
    fetchingMetrics: validationsMetrics.fetchingMetrics
  }

  const props = {
    countries,
    removalTableActions,
    nextStatusActions,
    tableState: table,
    handleSearch,
    handleDateRangeSearch,
    handleChange,
    handleTransportDateRangeSearch,
    cleanFilters,
    handleButtonFilterChange,
    dateFilterValue
  }

  return (
    <>
      <ValidationsMetrics {...metricsProps} />
      <ValidationsTable {...props} />
    </>
  )
}

export default Removals
