import { list } from './list'
import { create } from './create'
import { userList } from './corporate'
import { guestUsersList } from './guest'
import { deleteInvitation } from './deleteInvites'
import { deleteSatelliteUser } from './delete'
import { update } from './update'

export const SatelliteAPI = {
  list,
  create,
  satelliteUsers: userList,
  satelliteGuestUsers: guestUsersList,
  deleteInvitation,
  deleteSatelliteUser,
  update
}
