export enum StorageDepositsEnum {
  CLEAN_FILTERS = 'STORAGE_DEPOSIT/CLEAN_FILTERS',
  CREATE_DEPOSIT = 'STORAGE_DEPOSIT/CREATE_DEPOSIT',
  DELETE_DEPOSIT = 'STORAGE_DEPOSIT/DELETE_DEPOSIT',
  EDIT_DEPOSIT = 'STORAGE_DEPOSIT/EDIT_DEPOSIT'
}

export type StorageDepositsEvents = {
  [StorageDepositsEnum.CLEAN_FILTERS]: () => void
  [StorageDepositsEnum.CREATE_DEPOSIT]: () => void
  [StorageDepositsEnum.DELETE_DEPOSIT]: () => void
  [StorageDepositsEnum.EDIT_DEPOSIT]: () => void
}
