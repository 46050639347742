import React, { useEffect, useMemo, useState } from 'react'
import styles from './validationView.module.scss'
import InfoBanner, { Item } from '../../../components/infoBanner'
import OverviewCard, { Header, Module } from '../../../components/overviewCard'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { Country, CountryCurrency, CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { AttributesNameEnum, ValidationViewObject } from '../types/validationView'
import { Moment } from 'moment'
import { Button, Checkbox, Input, Modal, Spin, Tooltip } from 'antd'
import NumberInput from '../../../components/numberInput'
import { DynamicTable } from '../../../components/DynamicTable'
import { Camera, Edit, Info, PercentageRound } from '../../../icons'
import { Column } from '../../../components/DynamicTable/types/types'
import { formatNumber } from '../../../utils/formatNumber'
import CountUp from 'react-countup'
import clsx from 'clsx'
import { ExpandableModule } from '../../../components/overviewCard/overviewCard'
import Search from 'antd/lib/input/Search'
import { Billing, BillingStatus } from '../../../projectApi/TransactionHandler/Billings/common'
import ModalImages from '../../../components/CarouselModal/containers/CarouselModal'
import ImgWithCarousel from '../../../components/CarouselModal/components/ImgWithCarousel'
import { CopyButton } from '../../../components/CopyButton'
import { clone } from 'ramda'
import { useLocalization } from '../../../packages/localization'
import { MenuAction } from '../../../components/actionMenu/baseMenu'

export type ValidationViewProps = {
  operationCountryCode: CountryIdCode
  objects: ValidationViewObject[]
  quotedTransportCost: number
  discountCode: string
  provisionalBilling: Billing | null
  baseInfo: {
    id: number
    fullAddress: string
    transactionId: number
    user: {
      id: number
      name: string
      lastName: string
    }
    createdAt: Moment | null
    datetime: Moment | null
  }
  extraPrices: {
    assistants: {
      enabled: boolean
      price: number
      count: number
    }
    kmsExtra: {
      enabled: boolean
      price: number
      count: number
    }
    transport: {
      enabled: boolean
      price: number
      tollCostInCents: number
    }
  }
  headerToggles: {
    disassembly: boolean
    stairs: boolean
    packaging: boolean
  }
  headerCounters: {
    stairs: number
  }
  loading: boolean
  loadingDepositOperation: boolean
  validating: boolean
  error: string
  fetchedRecalculation: boolean
  countries: Country[]
  openRemittanceModal: (transactionId: number) => void
  validateOperation: () => void
  toggleTransportExtra: (type: 'assistants' | 'kmsExtra' | 'transport') => void
  setTransportExtraCount: (type: 'assistants' | 'kmsExtra', count: number) => void
  setTransportExtraPrice: (type: 'transport', price: number) => void
  toggleObjectExtra: (objectId: number | 'all', type: 'disassembly' | 'stairs' | 'packaging') => void
  setObjectFloors: (objectId: number | 'all', floors: number) => void
  handleRecalculate: () => void
  editObject: (objectID: number) => void
  getBillingByTransaction: () => void
}

const ValidationView: React.FC<ValidationViewProps> = ({
  countries,
  operationCountryCode,
  baseInfo,
  loading,
  loadingDepositOperation,
  validating,
  objects,
  quotedTransportCost,
  extraPrices,
  headerToggles,
  headerCounters,
  discountCode,
  provisionalBilling,
  fetchedRecalculation,
  openRemittanceModal,
  validateOperation,
  toggleObjectExtra,
  toggleTransportExtra,
  setTransportExtraCount,
  setTransportExtraPrice,
  setObjectFloors,
  handleRecalculate,
  editObject,
  getBillingByTransaction
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [searchField, setSearchField] = useState('')
  const [transportExtraTooltipOpen, setTransportExtraTooltipOpen] = useState(false)
  const { strings } = useLocalization()
  const ValidationViewStrings = strings.ValidationViewScreen

  const currencySymbol = useMemo(
    () => countries.find((c) => c.code === operationCountryCode)?.currency,
    [operationCountryCode]
  )

  const objectActions: MenuAction<ValidationViewObject>[] = [
    {
      label: 'Modificar objeto',
      icon: <Edit />,
      onClick: (object) => editObject(object.objectId)
    }
  ]

  const objectColumns: Column<ValidationViewObject>[] = [
    {
      key: 'photos',
      label: <Camera />,
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ photos, productName }) => (
        <ImgWithCarousel
          carouselImages={clone(photos)
            .sort((a, b) => (a.mainPhoto ? 1 : -1))
            .map((p) => p.url)}
          alt={productName}
        />
      )
    },
    {
      key: 'objectId',
      label: ValidationViewStrings.extraServices.table.columns.objectId,
      minWidth: '100px',
      maxWidth: 'min-content'
    },
    {
      key: 'name',
      label: 'Nombre producto',
      minWidth: '150px',
      alignment: 'left',
      renderDataCell: ({ productId, productName, deletedAt }) => (
        <span
          style={{
            textAlign: 'left',
            textDecoration: deletedAt ? 'line-through' : '',
            color: deletedAt ? 'red' : ''
          }}>
          {productId} - {productName}
        </span>
      )
    },
    {
      key: 'stairs-checkbox',
      noPadding: true,
      minWidth: 'min-content',
      maxWidth: 'min-content',
      label: (
        <Checkbox
          checked={headerToggles.stairs}
          onChange={() => toggleObjectExtra('all', 'stairs')}
          // disabled={!objects.find((o) => o.extras.stairs.floorsCostPerFloorInCents > 0)}
        />
      ),
      renderDataCell: ({ objectId, extras: { stairs }, deletedAt }) => (
        <Checkbox
          disabled={!!deletedAt}
          checked={stairs.enabled}
          onChange={() => toggleObjectExtra(objectId, 'stairs')}
          // disabled={stairs.floorsCostPerFloorInCents === 0}
        />
      )
    },
    {
      key: 'stairs-price',
      label: ValidationViewStrings.extraServices.table.columns.stairs,
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ extras: { stairs }, deletedAt }) => (
        <span
          className={clsx(styles.extraPrice)}
          style={{ color: deletedAt ? 'gray' : '', opacity: deletedAt ? '0.5' : '' }}>
          +{currencySymbol}
          {!deletedAt ? formatNumber(stairs.floorsCostPerFloorInCents / 100) : 0}
        </span>
      )
    },
    {
      key: 'stairs-floors',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      label: (
        <span style={{ marginRight: 24 }}>
          <NumberInput
            onAnyChange={(newValue) => setObjectFloors('all', newValue)}
            value={headerCounters.stairs}
            // disabled={!objects.find((o) => o.extras.stairs.enabled)}
          />
        </span>
      ),
      renderDataCell: ({ objectId, extras: { stairs }, deletedAt }) => (
        <span style={{ marginRight: 24 }}>
          <NumberInput
            onAnyChange={(newValue) => setObjectFloors(objectId, newValue)}
            value={stairs.floors}
            disabled={!stairs.enabled || !!deletedAt}
          />
        </span>
      )
    },
    {
      key: 'packaging-checkbox',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      label: (
        <Checkbox
          checked={headerToggles.packaging}
          onChange={() => toggleObjectExtra('all', 'packaging')}
          disabled={!objects.find((o) => o.extras.packaging.packagingCostInCents > 0)}
        />
      ),
      renderDataCell: ({ objectId, extras: { packaging } }) => (
        <Tooltip title={packaging.enabled ? 'Contiene embalaje' : 'No contiene embalaje'}>
          <Checkbox
            checked={packaging.enabled}
            onChange={() => toggleObjectExtra(objectId, 'packaging')}
            disabled={packaging.packagingCostInCents === 0}
          />
        </Tooltip>
      )
    },
    {
      key: 'packaging-price',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      label: ValidationViewStrings.extraServices.table.columns.packaging,
      renderDataCell: ({ extras: { packaging } }) => (
        <>
          <span className={clsx(styles.extraPrice, packaging.packagingCostInCents === 0 && styles.disabled)}>
            +{currencySymbol}
            {formatNumber(packaging.packagingCostInCents / 100)}
          </span>
        </>
      )
    },
    {
      key: 'disassembly-checkbox',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      label: (
        <Checkbox
          checked={headerToggles.disassembly}
          onChange={() => toggleObjectExtra('all', 'disassembly')}
          disabled={!objects.find((o) => o.extras.disassembly.disassemblyCost > 0)}
        />
      ),
      renderDataCell: ({ objectId, extras: { disassembly } }) => (
        <Checkbox
          checked={disassembly.enabled}
          onChange={() => toggleObjectExtra(objectId, 'disassembly')}
          disabled={disassembly.disassemblyCost === 0}
        />
      )
    },
    {
      key: 'disassembly-price',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      label: ValidationViewStrings.extraServices.table.columns.disassembly,
      renderDataCell: ({ extras: { disassembly } }) => (
        <span className={clsx(styles.extraPrice, disassembly.disassemblyCost === 0 && styles.disabled)}>
          +{currencySymbol}
          {formatNumber(disassembly.disassemblyCost)}
        </span>
      )
    },
    {
      key: 'deletedAt',
      label: '',
      maxWidth: '140px',
      renderDataCell: ({ deletedAt }) => {
        if (!deletedAt) return ''
        return <span style={{ color: 'red' }}>{ValidationViewStrings.extraServices.table.rows.deleted}</span>
      }
    },
    {
      key: 'status',
      label: 'Estado',
      maxWidth: '140px',
      renderDataCell: ({ objectXAttributes }) => {
        const status =
          objectXAttributes?.find((attr) => attr.attribute.name === AttributesNameEnum.STATUS)?.value || '-'
        return <span>{status}</span>
      }
    }
  ]

  if (loading)
    return (
      <div className={styles.placeholder}>
        <LoadingIndicator />
      </div>
    )
  if (!baseInfo.id)
    return (
      <div className={styles.placeholder}>
        <span>{ValidationViewStrings.extraServices.table.error}</span>
      </div>
    )

  return (
    <div className={styles.validationView}>
      <InfoBanner title={ValidationViewStrings.infoBanner.title}>
        <Item heading={ValidationViewStrings.infoBanner.fields.depositId} value={baseInfo.id} />
        <Item heading={ValidationViewStrings.infoBanner.fields.clientId} value={baseInfo.user.id} />
        <Item heading={ValidationViewStrings.infoBanner.fields.clientName} value={baseInfo.user.name} />
        <Item heading={ValidationViewStrings.infoBanner.fields.clientLastName} value={baseInfo.user.lastName} />
        <Item
          heading={ValidationViewStrings.infoBanner.fields.operationDate}
          value={baseInfo.createdAt ? baseInfo.createdAt.format('dd DD[-]MM[-]YY[ ]HH[:]mm') : '-'}
        />
        <Item
          heading={ValidationViewStrings.infoBanner.fields.serviceDate}
          value={baseInfo.datetime ? baseInfo.datetime.format('dd DD[-]MM[-]YY[ ]HH[:]mm') : '-'}
        />
        <Item
          heading={ValidationViewStrings.infoBanner.fields.openRemittance}
          value={ValidationViewStrings.infoBanner.fields.remittance}
          onValueClick={() => openRemittanceModal(baseInfo.transactionId)}
        />
        <Item heading={ValidationViewStrings.infoBanner.fields.fullAddress} value={baseInfo.fullAddress}>
          <CopyButton textToCopy={baseInfo.fullAddress} tooltipText={'Copiado!'} />
        </Item>
      </InfoBanner>
      <section className={styles.transportExtras}>
        <h1>{ValidationViewStrings.transportExtras.title}</h1>
        <div className={styles.transportControls}>
          <div className={styles.control}>
            <Checkbox checked={extraPrices.kmsExtra.enabled} onChange={() => toggleTransportExtra('kmsExtra')} />
            <span className={styles.extraTitle}>{ValidationViewStrings.transportExtras.extraKms}</span>
            <NumberInput
              onAnyChange={(newValue) => setTransportExtraCount('kmsExtra', newValue)}
              value={extraPrices.kmsExtra.count}
              disabled={!extraPrices.kmsExtra.enabled}
            />
            <span className={styles.extraPrice}>
              x {currencySymbol}
              {extraPrices.kmsExtra.price}
            </span>
          </div>
          <div className={styles.control}>
            <Checkbox checked={extraPrices.transport.enabled} onChange={() => toggleTransportExtra('transport')} />
            <span className={styles.extraTitle}>Extras de transporte/embalaje</span>
            <Tooltip
              overlay={ValidationViewStrings.transportExtras.warningTooltip}
              onVisibleChange={() => setTransportExtraTooltipOpen(false)}
              visible={transportExtraTooltipOpen}>
              <Input
                className={styles.input}
                value={extraPrices.transport.price}
                type="number"
                onChange={(e) => {
                  const parsed = parseInt(e.target.value)
                  const value = isNaN(parsed) ? 0 : parsed

                  if (value < 0 - quotedTransportCost) {
                    setTransportExtraTooltipOpen(true)
                    return
                  }
                  setTransportExtraTooltipOpen(false)

                  setTransportExtraPrice('transport', value)
                }}
                disabled={!extraPrices.transport.enabled}
              />
            </Tooltip>
          </div>
          <div className={styles.control}>
            <Checkbox checked={extraPrices.assistants.enabled} onChange={() => toggleTransportExtra('assistants')} />
            <span className={styles.extraTitle}>{ValidationViewStrings.transportExtras.assistants}</span>
            <NumberInput
              onAnyChange={(newValue) => setTransportExtraCount('assistants', newValue)}
              value={extraPrices.assistants.count}
              disabled={!extraPrices.assistants.enabled}
            />
            <span className={styles.extraPrice}>
              x {currencySymbol}
              {extraPrices.assistants.price}
            </span>
          </div>
        </div>
      </section>

      <section className={styles.overview}>
        <ValidationOverviewCard
          objects={objects}
          quotedTransportCost={quotedTransportCost}
          currencySymbol={currencySymbol}
          extraPrices={extraPrices}
          buttonAction={() => setConfirmationModalOpen(true)}
          buttonRecalculateAction={() => handleRecalculate()}
          discountCode={discountCode}
          totalM3={provisionalBilling?.m3}
          provisionalBilling={
            provisionalBilling?.startDate && provisionalBilling.endDate
              ? {
                  ...provisionalBilling,
                  startDate: provisionalBilling.startDate,
                  endDate: provisionalBilling.endDate,
                  value: provisionalBilling.totalPrice
                }
              : undefined
          }
          fetchedRecalculation={fetchedRecalculation}
          getBillingByTransaction={getBillingByTransaction}
        />
      </section>

      <section>
        <h1>{ValidationViewStrings.extraServices.title}</h1>
        <div className={styles.tableTopBar}>
          <Search
            placeholder={ValidationViewStrings.extraServices.search}
            onSearch={(value) => setSearchField(value)}
            enterButton
          />
        </div>
        <DynamicTable
          columns={objectColumns}
          rows={
            searchField.length > 0
              ? objects.filter(
                  (obj) =>
                    obj.productName.toLowerCase().includes(searchField) || obj.objectId.toString().includes(searchField)
                )
              : objects
          }
          actions={objectActions}
          loading={loadingDepositOperation}
          keyExtractor={({ objectId }) => objectId}
          customClassNames={{
            table: styles.customTableProps
          }}
          customStrings={{
            emptyState: ValidationViewStrings.extraServices.emptyState
          }}
        />
      </section>

      <Modal
        centered
        closable={false}
        visible={confirmationModalOpen}
        style={{ maxWidth: 500 }}
        maskClosable={!validating}
        onOk={() => setConfirmationModalOpen(false)}
        onCancel={() => setConfirmationModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setConfirmationModalOpen(false)} disabled={validating}>
            {ValidationViewStrings.modal.buttons.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={validating} onClick={validateOperation}>
            {ValidationViewStrings.modal.buttons.confirm}
          </Button>
        ]}>
        <div className={styles.modalBody}>
          <Info fill={'#FAAF19'} />
          <span> {ValidationViewStrings.modal.warning}</span>
        </div>
      </Modal>
      <ModalImages />
    </div>
  )
}

type CompoundPrices = {
  stairsTotal: number
  disassemblyTotal: number
  packagingTotal: number
  storageTotal: number
}

export const ValidationOverviewCard: React.FC<{
  objects: ValidationViewObject[]
  quotedTransportCost: number
  fetchedRecalculation?: boolean
  getBillingByTransaction?: () => void
  currencySymbol?: string
  extraHeaderInfo?: string
  discountCode?: string
  provisionalBilling?: Billing & { value: number }
  totalM3?: number
  extraPrices: {
    assistants: {
      enabled: boolean
      price: number
      count: number
    }
    kmsExtra: {
      enabled: boolean
      price: number
      count: number
    }
    transport: {
      enabled: boolean
      price: number
      tollCostInCents: number
    }
  }
  buttonAction?: () => void
  buttonRecalculateAction?: () => void
  buttonText?: string
  recalculateButtonText?: string
}> = ({
  objects,
  quotedTransportCost,
  fetchedRecalculation,
  getBillingByTransaction,
  currencySymbol,
  extraPrices,
  extraHeaderInfo,
  discountCode,
  totalM3,
  provisionalBilling,
  buttonAction,
  buttonRecalculateAction,
  buttonText = 'Confirmar',
  recalculateButtonText = 'Recalcular'
}) => {
  const [provisionalBillingIntervalID, setProvisionalBillingIntervalID] = useState<NodeJS.Timeout>()
  const { strings } = useLocalization()
  const ValidationViewStrings = strings.ValidationViewScreen
  const { stairsTotal, disassemblyTotal, packagingTotal, storageTotal }: CompoundPrices = useMemo(() => {
    const objectsCompounded: CompoundPrices = objects.reduce<CompoundPrices>(
      (map, obj) => {
        const { disassembly, packaging, stairs } = obj.extras

        return {
          stairsTotal:
            map.stairsTotal + (stairs.enabled ? (stairs.floorsCostPerFloorInCents / 100) * stairs.floors : 0),
          disassemblyTotal: map.disassemblyTotal + (disassembly.enabled ? disassembly.disassemblyCost : 0),
          packagingTotal: map.packagingTotal + (packaging.enabled ? packaging.packagingCostInCents / 100 : 0),
          storageTotal: map.storageTotal + obj.storageCostInCents / 100
        }
      },
      {
        stairsTotal: 0,
        disassemblyTotal: 0,
        packagingTotal: 0,
        storageTotal: 0
      }
    )

    return objectsCompounded
  }, [objects])

  const pendingProvisionalBilling =
    provisionalBilling && BillingStatus[provisionalBilling.status] === BillingStatus.PENDING

  const clearProvisionalBillingInterval = () => {
    if (provisionalBillingIntervalID) {
      clearInterval(provisionalBillingIntervalID)
    }
  }

  useEffect(() => {
    if (fetchedRecalculation && getBillingByTransaction && pendingProvisionalBilling && !provisionalBillingIntervalID) {
      const intervalID = setInterval(() => getBillingByTransaction(), 3000)
      setProvisionalBillingIntervalID(intervalID)
    } else if (provisionalBillingIntervalID) {
      clearProvisionalBillingInterval()
      setProvisionalBillingIntervalID(undefined)
    }
    return () => clearProvisionalBillingInterval()
  }, [provisionalBilling, fetchedRecalculation, getBillingByTransaction])

  const kmsTotalPrice = extraPrices.kmsExtra.enabled ? extraPrices.kmsExtra.count * extraPrices.kmsExtra.price : 0
  const assistantsTotalPrice = extraPrices.assistants.enabled
    ? extraPrices.assistants.count * extraPrices.assistants.price
    : 0

  const transportExtraTotalPrice = extraPrices.transport.enabled ? extraPrices.transport.price : 0

  const SubtotalTitle = (
    <div className={styles.subtotal}>
      <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.title}</span>
      <span>
        <PriceAnimator
          value={
            kmsTotalPrice +
            transportExtraTotalPrice +
            assistantsTotalPrice +
            stairsTotal +
            disassemblyTotal +
            packagingTotal +
            extraPrices.transport.tollCostInCents / 100 +
            quotedTransportCost
          }
          currencySymbol={currencySymbol}
        />
      </span>
    </div>
  )

  const disabledButtonAction = !fetchedRecalculation || pendingProvisionalBilling

  return (
    <OverviewCard>
      <Header extraInfo={extraHeaderInfo}>{ValidationViewStrings.overviewCard.header}</Header>
      <Module className={styles.priceGrid}>
        <span>
          <b>{ValidationViewStrings.overviewCard.fields.objects.title}</b>
        </span>
        <span>
          <b>{objects.length}</b>
          {totalM3 && <span className={styles.totalM3}> ({formatNumber(totalM3)} m3)</span>}
        </span>
      </Module>
      <Module className={styles.priceGrid}>
        <span>
          <b>{ValidationViewStrings.overviewCard.fields.proportionalBilling.title}</b>
        </span>
        <b>
          {provisionalBilling ? (
            pendingProvisionalBilling ? (
              <Spin />
            ) : (
              <PriceAnimator value={provisionalBilling?.value} currencySymbol={currencySymbol} />
            )
          ) : (
            ''
          )}
        </b>
        <span className={styles.footnote}>
          {provisionalBilling ? (
            <>
              {ValidationViewStrings.overviewCard.fields.proportionalBilling.period(
                provisionalBilling.startDate?.utc().format('DD/MM/YYYY') || '',
                provisionalBilling.endDate?.utc().format('DD/MM/YYYY') || ''
              )}
            </>
          ) : (
            <>{ValidationViewStrings.overviewCard.fields.proportionalBilling.emptyState}</>
          )}
          <div>{discountCode && ValidationViewStrings.overviewCard.fields.proportionalBilling.discountDisclaimer}</div>
        </span>
      </Module>
      {/* <Module className={styles.priceGrid}>
        <span>Almacenaje</span>
        <b>
          <PriceAnimator value={storageTotal} currencySymbol={currencySymbol} />
        </b>
      </Module> */}
      <ExpandableModule title={SubtotalTitle}>
        <div className={styles.priceGrid}>
          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.quotedTransport}</span>
          <span>
            <PriceAnimator value={quotedTransportCost} currencySymbol={currencySymbol} />
          </span>
          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.tollCost}</span>
          <span>
            <PriceAnimator value={extraPrices.transport.tollCostInCents / 100} currencySymbol={currencySymbol} />
          </span>
          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.extraKms}</span>
          <PriceAnimator value={kmsTotalPrice} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.extraTransport}</span>
          <PriceAnimator value={transportExtraTotalPrice} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.assistants}</span>
          <PriceAnimator value={assistantsTotalPrice} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.packaging}</span>
          <PriceAnimator value={packagingTotal} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.stairs}</span>
          <PriceAnimator value={stairsTotal} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.disassembly}</span>
          <PriceAnimator value={disassemblyTotal} currencySymbol={currencySymbol} />
        </div>
      </ExpandableModule>
      <Module className={styles.priceGrid}>
        <span className={styles.total}>{ValidationViewStrings.overviewCard.fields.total.title}</span>
        <PriceAnimator
          value={
            quotedTransportCost +
            extraPrices.transport.tollCostInCents / 100 +
            kmsTotalPrice +
            transportExtraTotalPrice +
            assistantsTotalPrice +
            stairsTotal +
            disassemblyTotal +
            packagingTotal +
            (provisionalBilling?.value || 0)
          }
          currencySymbol={currencySymbol}
          className={clsx(styles.total, styles.totalNumber)}
        />
      </Module>
      {discountCode && (
        <Module className={styles.discountCodeModule}>
          <PercentageRound />
          <span>
            {ValidationViewStrings.overviewCard.fields.discounts.title} {discountCode}
          </span>
        </Module>
      )}
      {buttonRecalculateAction && (
        <Module>
          <button className={styles.confirmButton} onClick={buttonRecalculateAction}>
            {recalculateButtonText}
          </button>
        </Module>
      )}
      {buttonAction && (
        <Module>
          <Tooltip title={disabledButtonAction && ValidationViewStrings.overviewCard.fields.confirm.tooltip}>
            <span>
              <button disabled={disabledButtonAction} className={styles.confirmButton} onClick={buttonAction}>
                {buttonText}
              </button>
            </span>
          </Tooltip>
        </Module>
      )}
    </OverviewCard>
  )
}

export const PriceAnimator: React.FC<{ currencySymbol?: string; value: number; className?: string }> = ({
  currencySymbol = CountryCurrency.ARGENTINA,
  value,
  className
}) => {
  const [totalPrice, setTotalPrice] = useState({ current: 0, previous: 0 })

  useEffect(() => {
    setTotalPrice((current) => ({
      previous: current.current,
      current: value
    }))
  }, [value])

  return (
    <span className={className}>
      {currencySymbol}
      <CountUp
        start={totalPrice.previous}
        end={totalPrice.current}
        duration={0.2}
        decimals={2}
        decimal=","
        separator="."
      />
    </span>
  )
}

export default ValidationView
