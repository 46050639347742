import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Modal, Row } from 'antd'

const styles = {
  dialogSubtitle: {
    marginBottom: 20
  },
  dialogContainer: {
    maxWidth: 700
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  }
}

const CancelPanel = (props) => {
  const { open, guid, loading, error, operationId, cancel, close } = props

  const [notifyUser, setNotifyUser] = useState(true)

  function onChange(e) {
    setNotifyUser(e.target.checked)
  }

  return (
    <Modal
      title="Cancelar Retiro"
      visible={open}
      closable={!loading}
      maskClosable={!loading}
      style={styles.dialogContainer}
      onCancel={close}
      onOk={() => cancel(guid, notifyUser)}
      okText={'Sí'}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading
      }}
      cancelText={'Cerrar'}>
      <p>¿Esta seguro que desea cancelar la operación #{operationId}?</p>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <span style={styles.explainer}>Establece si se le debe enviar una notificación por email al usuario.</span>
          <br />
          <Checkbox onChange={(e) => onChange(e)} defaultChecked={notifyUser}>
            Notificar Usuario
          </Checkbox>
        </Col>
        {error && (
          <Col span={24}>
            <span style={styles.error}>{error}</span>
          </Col>
        )}
      </Row>

      <p style={styles.error}>{error}</p>
    </Modal>
  )
}

CancelPanel.propTypes = {
  operationId: PropTypes.number,
  guid: PropTypes.string,
  userId: PropTypes.number,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  close: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
}

export default CancelPanel
