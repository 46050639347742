import React, { useEffect } from 'react'
import DonationsTable from '../components/donationsTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import DonationListActionsCreator from '../actions/donationsTable'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import {
  DonationDateRangeFilter,
  DonationTableDateRangeKey,
  DonationsSearchFilter,
  DonationsTableCategoryFilter,
  DonationsTableSearchKey,
  DonationsTableSort
} from '../types/donationsTable'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../components/TableActionBar'
import { DonationsListSortKey } from '../../../projectApi/Sales/DonationRequest/list'
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { removeNullishValues, updateMultipleSearchFilters } from '../../../components/DynamicTable/queryParamsUtils'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { Donation } from '../../../projectApi/Sales/DonationRequest/common'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Edit } from '../../../icons'
import { Moment } from 'moment'
import { useHistory } from 'react-router'
import styles from '../components/DonationTable.module.scss'

const queryParams = {
  page: NumberParam,
  sortField: StringParam,
  sortDirection: StringParam,
  id: StringParam,
  objectId: StringParam,
  userId: StringParam,
  status: StringParam,
  requestId: NumberParam,
  showDemoUsers: BooleanParam
}

const DonationsTableContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [query, setQuery] = useQueryParams(queryParams)
  const { getDonations, sendDonationsCSV, resetFilters } = bindActionCreators(DonationListActionsCreator, dispatch)
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const { sendingCSV, donations, fetchingDonations, ...tableState } = useSelector(
    (state) => state.DonationsReducer.table
  )
  const emptyPagination = { ...tableState.pagination, page: 1 }
  const handleGetDonations = (newParams: {
    pagination?: Pagination
    searchFilters?: DonationsSearchFilter[]
    categoryFilter?: DonationsTableCategoryFilter
    sort?: DonationsTableSort
    dateRangeFilters?: DonationDateRangeFilter[]
    showDemoUsers?: boolean
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      sort: newParams.sort || tableState.sort,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      showDemoUsers: newParams.showDemoUsers ?? tableState.showDemoUsers
    }
    getDonations(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      sortDirection: actualParams.sort.direction,
      status: actualParams.categoryFilter.status.join(),
      sortField: actualParams.sort.field,
      showDemoUsers: actualParams.showDemoUsers
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handleShowDemoUsers = (showDemoUsers: boolean) => {
    handleGetDonations({ showDemoUsers })
  }

  useEffect(() => {
    handleGetDonations({
      pagination: tableState.pagination,
      sort:
        query.sortField && query.sortDirection
          ? {
              field: query.sortField as DonationsListSortKey,
              direction: query.sortDirection as SortDirection
            }
          : tableState.sort,
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: 'id', text: query.id },
        { key: 'objectId', text: query.objectId },
        { key: 'userId', text: query.userId },
        { key: 'requestId', text: query.requestId?.toString() }
      ]),
      categoryFilter: {
        ...tableState.categoryFilter,
        status: query.status ? [query.status] : tableState.categoryFilter.status
      },
      showDemoUsers: query.showDemoUsers || tableState.showDemoUsers
    })

    return () => {
      resetFilters()
    }
  }, [])

  useEvents([Events.Global.CHANGE_COUNTRY, Events.Donations.CLEAN_FILTERS], () => {
    handleGetDonations({ pagination: { ...tableState.pagination, page: 1 } })
  })

  const handleSendCSV = () => {
    sendDonationsCSV({
      pagination: tableState.pagination,
      searchFilters: tableState.searchFilters
    })
  }

  const handleSort = (newSort: DonationsTableSort) => {
    handleGetDonations({ sort: newSort })
  }

  const handlePageChange = (newPage: number) => {
    handleGetDonations({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: DonationsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetDonations({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const handleDateRangePiker = (key: DonationTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newDateRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetDonations({ dateRangeFilters: newDateRangeFilters, pagination: emptyPagination })
  }

  const menuActions: MenuAction<Donation>[] = [
    {
      label: (donation) =>
        donation?.status === 'RECEIVED' ? 'Iniciar' : donation?.status === 'ACCEPTED' ? 'Finalizar' : 'Detalle',
      icon: <Edit />,
      onClick: (donation) => {
        history.push(`/donations/${donation.id}`)
      }
    }
  ]

  const handleCategoryFilter = (newCategoryFilter: DonationsTableCategoryFilter) => {
    handleGetDonations({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const onGoRequestTable = () => {
    history.push('/requests')
  }

  const props = {
    fetchingDonations,
    donations,
    sort: tableState.sort,
    searchFilters: tableState.searchFilters,
    handleCategoryFilter,
    handleDateRangePiker,
    dateRangeFilters: tableState.dateRangeFilters,
    categoryFilter: tableState.categoryFilter,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    handleSort,
    handleOpenQRCodeModal: openQRModal,
    actions: menuActions
  }

  return (
    <>
      <TableActionBar customStyles={{ margin: '8px 0 16px 0' }}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            variant="secondary"
            iconType="arrow-left"
            titleButton="Tabla de solicitudes"
            onClickButton={onGoRequestTable}
          />
          <TableActionBar.ButtonAction toolTip variant="csv" disabled={sendingCSV} onClickButton={handleSendCSV} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={() => resetFilters()} />
          <TableActionBar.Filters>
            <div />
            <label className={styles.testCheckbox}>
              <input
                type="checkbox"
                checked={tableState.showDemoUsers}
                onChange={(e) => handleShowDemoUsers(e.target.checked)}
              />
              Incluir usuarios demo
            </label>
          </TableActionBar.Filters>
        </TableActionBar.Wrapper>
      </TableActionBar>
      <DonationsTable {...props} />
    </>
  )
}

export default DonationsTableContainer
