import React, { useMemo } from 'react'
import { Input, Modal, Card } from 'antd'
import { redirectToTypes } from './Users.constants'
import { getOptionValue } from './Users.selectors'
import InputGroup from '../../components/InputGroup'
import InputGroupAddon from '../../components/InputGroupAddon/InputGroupAddon'
import Select from '../../components/Select'

const styles = {
  dialogSubtitle: {
    marginBottom: 20
  },
  dialogContainer: {
    maxWidth: 700
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  select: {
    width: '100%'
  },
  redirectToContainer: {
    display: 'block'
  }
}

const LoginAsModal = (props) => {
  const {
    open,
    user,
    userAdmin,
    loading,
    error,
    setLoginAsModalOpen,
    setAdminEmail,
    setAdminPassword,
    setRedirection,
    loginAsUser
  } = props
  const { email, password, redirectTo } = userAdmin
  const { Name, LastName, RealID } = user || {}

  const title = 'Ingresar como ' + Name + ' ' + LastName
  const description =
    'Ingresá tu usuario y contraseña de administrador. Luego presioná continuar para entrar a la web app como el usuario ID ' +
    RealID

  function onPressEnter(event) {
    if (event.key === 'Enter') {
      loginAsUser(userAdmin, RealID)
    }
  }

  const redirectToOption = useMemo(
    () => redirectToTypes.find((redirectToType) => redirectToType.value === redirectTo),
    [redirectTo]
  )

  return (
    <Modal
      title={title}
      visible={open}
      closable={!loading}
      maskClosable={!loading}
      style={styles.dialogContainer}
      onOk={() => loginAsUser(userAdmin, RealID)}
      onCancel={() => setLoginAsModalOpen(false)}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: loading
      }}
      okText={'Continuar'}
      cancelText={'Cancelar'}>
      <p style={styles.dialogSubtitle}>{description}</p>
      <Card>
        <InputGroup className="mb-3" style={{ display: 'flex', border: 'none' }}>
          <InputGroupAddon>
            <i className="icon-user"></i>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setAdminEmail(e.target.value)}
            onKeyPress={onPressEnter}
          />
        </InputGroup>
        <InputGroup className="mb-4" style={{ border: 'none', display: 'flex' }}>
          <InputGroupAddon>
            <i className="icon-lock"></i>
          </InputGroupAddon>
          <Input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setAdminPassword(e.target.value)}
            onKeyPress={onPressEnter}
          />
        </InputGroup>
        <InputGroup style={(styles.redirectToContainer, { border: 'none' })}>
          <p style={styles.inputTitle}>Ingresar a</p>
          <Select
            placeholder="Ingresar a*"
            selected={redirectToOption}
            options={redirectToTypes}
            onSelect={(option) => setRedirection(getOptionValue(option))}
          />
        </InputGroup>
      </Card>
      <p style={styles.error}>{error}</p>
    </Modal>
  )
}

export default LoginAsModal
