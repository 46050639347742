import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import actions from '../actionCreators/cardTableActions'
import modalActions from '../actionCreators/cardModalActions'
import { bindActionCreators } from 'redux'
import Component from '../components/cardsTable'
import queryString from 'query-string'
import CardsModal from './cardsModal'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Buffer } from 'buffer'

type ParsedParams = {
  name: string
  lastName: string
  country: string
}

const CardsTableContainer = (ownProps: any) => {
  const dispatch = useDispatch()
  const { getCards, markMainCard, disableCard } = bindActionCreators(actions, dispatch)
  const { cards, loadingCards } = useSelector((state) => state.Cards.table)
  const { setModalOpen } = bindActionCreators(modalActions, dispatch)

  const urlEncoded = ownProps.history.location.search

  const { name, lastName, country } = queryString.parse(
    unescape(Buffer.from(urlEncoded, 'base64').toString('ascii'))
  ) as ParsedParams
  const { userId }: { userId: string } = useParams()

  useEffect(() => {
    getCards({ userId })
  }, [])

  useEvents(Events.CreditCard.MARK_MAIN_CARD, () => getCards({ userId, silentLoading: true }))
  useEvents(Events.CreditCard.DISABLE_CARD, () => getCards({ userId, silentLoading: true }))
  useEvents(Events.CreditCard.ADD_NEW_CARD, () => getCards({ userId, silentLoading: true }))

  const props = {
    cards,
    userId,
    userName: name,
    userLastName: lastName,
    setModalOpen,
    loadingCards,
    markMainCard,
    disableCard
  }

  return (
    <>
      <CardsModal country={country} />
      <Component {...props} />
    </>
  )
}

export default CardsTableContainer
