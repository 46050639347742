import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export interface Description {
  transport: number
  deposit: number
  general: number
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = CamelCasedPropertiesDeep<Description>

interface GetByIdParams {
  code: string
}

export type Discount = Description

export function getById({ code }: GetByIdParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/compute/transaction/discount/code/${code}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener un descuento')
      if (!response.data?.description) throw new Error('Respuesta incorrecta al obtener un descuento')

      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
