import React from 'react'
import { Modal } from 'antd'
import styles from './DeleteShipper.module.scss'
import { Shipper } from '../../../projectApi/Trips/Shippers/list'

export type DeleteShipperProps = {
  currentShipper: Shipper | null
  deletingShipper: boolean
  deleteModalVisible: boolean
  disabledDeleteButton: boolean
  handleCancelDeleteButton: () => void
  handleDeleteShipper: () => void
}

const DeleteShipperModal = ({
  currentShipper,
  deletingShipper,
  deleteModalVisible,
  disabledDeleteButton,
  handleCancelDeleteButton,
  handleDeleteShipper
}: DeleteShipperProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={handleCancelDeleteButton}
      title={'Eliminar transportista'}
      confirmLoading={deletingShipper}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={handleDeleteShipper}
      okButtonProps={{ disabled: disabledDeleteButton }}
      destroyOnClose>
      <InfoCard id={currentShipper?.id} fullName={`${currentShipper?.name} ${currentShipper?.lastName}`} />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar a este transportista?</h4>
    </Modal>
  )
}

export default DeleteShipperModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; fullName?: string }> = ({ id, fullName }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos del transportista:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={fullName} label={'Nombre completo'} />
    </div>
  </header>
)
