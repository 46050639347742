import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'

type DeleteParams = {
  discountId: number
}

export function deleteIt({ discountId }: DeleteParams) {
  return apiPrivate
    .delete(`${URL_BASE_TIMESLOT}/discount/by-day/${discountId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
