import { CamelCasedPropertiesDeep } from 'type-fest'
import moment, { Moment } from 'moment'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'

export interface APIResponse {
  billings_metrics: APIBillingMetrics[]
}

export interface APIBillingMetrics {
  created_at: Date
  updated_at: Date
  billing_quantity: number
  positive_dif_billing_quantity: number
  negative_dif_billing_quantity: number
  excluded_users: number
  positive_dif_excluded_users: number
  negative_dif_excluded_users: number
  period: string
}

export type BillingMetrics = Omit<CamelCasedPropertiesDeep<APIBillingMetrics>, 'createdAt' | 'updatedAt'> & {
  createdAt: Moment | null
  updatedAt: Moment | null
}

export function metrics(period: string): Promise<BillingMetrics> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billing-metrics?period=${period}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.billings_metrics[0]) throw new Error('Hubo un error al buscar las métricas')
      const billings = response.data.billings_metrics[0]

      const newResponse: BillingMetrics = {
        billingQuantity: billings.billing_quantity,
        excludedUsers: billings.excluded_users,
        negativeDifBillingQuantity: billings.negative_dif_billing_quantity,
        negativeDifExcludedUsers: billings.negative_dif_excluded_users,
        period: billings.period,
        positiveDifBillingQuantity: billings.positive_dif_billing_quantity,
        positiveDifExcludedUsers: billings.positive_dif_excluded_users,
        createdAt: moment(billings.created_at),
        updatedAt: moment(billings.updated_at)
      }

      return newResponse
    })
    .catch((err) => {
      throw err
    })
}
