export type ProposalMetric = {
  id: string
  metric: string
  quantity: number
}

export type ProposalMetricsState = {
  quantityMetrics: ProposalMetric[]
  fetchingMetrics: boolean
}

export enum GetProposalMetricsTypes {
  GET_PROPOSAL_METRICS_REQUEST = 'PROPOSALS/PROPOSALS_METRICS/GET_PROPOSAL_METRICS_REQUEST',
  GET_PROPOSAL_METRICS_SUCCESS = 'PROPOSALS/PROPOSALS_METRICS/GET_PROPOSAL_METRICS_SUCCESS',
  GET_PROPOSAL_METRICS_FAILURE = 'PROPOSALS/PROPOSALS_METRICS/GET_PROPOSAL_METRICS_FAILURE'
}

export interface GetProposalMetricsRequest {
  type: typeof GetProposalMetricsTypes.GET_PROPOSAL_METRICS_REQUEST
}

export interface GetProposalMetricsSuccess {
  type: typeof GetProposalMetricsTypes.GET_PROPOSAL_METRICS_SUCCESS
  payload: {
    quantityMetrics: ProposalMetric[]
  }
}

export interface GetProposalMetricsFailure {
  type: typeof GetProposalMetricsTypes.GET_PROPOSAL_METRICS_FAILURE
}

type GetProposalMetricsActions = GetProposalMetricsRequest | GetProposalMetricsSuccess | GetProposalMetricsFailure

export type ProposalMetricsActions = GetProposalMetricsActions
