import React, { useEffect } from 'react'
import UsersGuestTable from '../components/userGuestTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import UsersABMActionsCreator from '../actions/userABM'
import { Pagination } from '../../../../utils/searchFilterUtils'
import { TableActionBar } from '../../../../components/TableActionBar'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { Edit, Trash } from '../../../../icons'
import { UsersSearchFilter, UsersTableSearchKey } from '../types/usersTable'
import { Events } from '../../../../utils/eventEmitter/events'
import { useEvents } from '../../../../utils/eventEmitter'
import UserInviteModal from '../containers/UserInviteModal'
import UserDeleteInviteModal from '../containers/UserDeleteInviteModal'
import { UserABMTypes } from '../types/userABM'
import { GuestUser } from '../../../../projectApi/AccountManager/Satellite/guest'

const UsersTableContainer = () => {
  const dispatch = useDispatch()
  const { openModalInvite, getGuestUsers, resetFilters, openGuestDeleteInviteModal } = bindActionCreators(
    UsersABMActionsCreator,
    dispatch
  )

  const { pagination, searchFilters, isLoading, guestUsers, isLoadingGuests } = useSelector(
    (state) => state.CorporateUsersABM
  ) as UserABMTypes

  useEffect(() => {
    getGuestUsers({ pagination })
  }, [])

  useEvents(Events.CorporateGuestUsers.CLEAN_FILTERS, () => getGuestUsers({ pagination: { ...pagination, page: 1 } }))

  const handleGetUsers = (newParams: { pagination?: Pagination; searchFilters?: UsersSearchFilter[] }) => {
    getGuestUsers({
      pagination: newParams.pagination || pagination,
      searchFilters: newParams.searchFilters || searchFilters
    })
  }

  const handlePageChange = (newPage: number) => {
    getGuestUsers({ pagination: { ...pagination, page: newPage } })
  }

  const handleSearch = (key: UsersTableSearchKey, newValue: string) => {
    const newSearchFilters = searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetUsers({ searchFilters: newSearchFilters, pagination: { ...pagination, page: 1 } })
  }

  const menuActions: MenuAction<GuestUser>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: () => {},
      disabled: true
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      disabled: (guestUser) => Boolean(guestUser?.used),
      onClick: (guestUser) =>
        openGuestDeleteInviteModal({ isDeleteInviteModalOpen: true, selectedGuestUser: guestUser })
    }
  ]

  const props = {
    isLoading,
    isLoadingGuests,
    guestUsers,
    searchFilters,
    pagination: {
      currentPage: pagination.page,
      pageSize: pagination.pageSize,
      total: pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    actions: menuActions
  }

  return (
    <>
      <TableActionBar>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={resetFilters} />
        <TableActionBar.ButtonAction
          titleButton="Invitar"
          onClickButton={() => openModalInvite({ isAddModalOpen: true })}
        />
      </TableActionBar>
      <UsersGuestTable {...props} />
      <UserInviteModal />
      <UserDeleteInviteModal />
    </>
  )
}

export default UsersTableContainer
