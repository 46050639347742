import { Reducer } from 'redux'
import { SortDirection } from '../../../../components/DynamicTable/types/types'
import {
  CLEAR_STATE,
  GET_METRICS_OBJECTS_FAILURE,
  GET_METRICS_OBJECTS_REQUEST,
  GET_METRICS_OBJECTS_SUCCESS,
  GET_OBJECTS_FAILURE,
  GET_OBJECTS_REQUEST,
  GET_OBJECTS_STATUS_FAILURE,
  GET_OBJECTS_STATUS_REQUEST,
  GET_OBJECTS_STATUS_SUCCESS,
  GET_OBJECTS_SUCCESS,
  ObjectsTableAction,
  ObjectsTableState,
  TOGGLE_ALL_OBJECTS,
  TOGGLE_EXACT_LOCATION_CODE,
  TOGGLE_SELECTED_OBJECT
} from '../types/objectsTable'

const initialState: ObjectsTableState = {
  objects: [],
  selectedObjects: [],
  loadingObjects: false,
  error: '',
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'identificationCode', text: '' },
    { key: 'product', text: '' },
    { key: 'locationCode', text: '' },
    { key: 'ownerId', text: '' },
    { key: 'owner', text: '' },
    { key: 'exactLocationCode', text: 'true' },
    { key: 'location', text: 'true' }
  ],
  categoryFilter: { status: [], objectSituation: [] },
  sort: {
    field: 'id',
    direction: SortDirection.DESC
  },
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  objectStatus: [],
  metrics: {
    total: 0,
    totalM3: 0,
    cataloging: 0,
    locating: 0,
    takingPhotos: 0,
    saved: 0
  },
  loadingMetrics: false
}

const ObjectsTableReducer: Reducer<ObjectsTableState, ObjectsTableAction> = (
  state = initialState,
  action
): ObjectsTableState => {
  switch (action.type) {
    case GET_METRICS_OBJECTS_REQUEST:
      return {
        ...state,
        loadingMetrics: true
      }
    case GET_METRICS_OBJECTS_SUCCESS:
      return {
        ...state,
        metrics: {
          total: action.payload.total,
          totalM3: action.payload.totalM3,
          cataloging: action.payload.cataloging,
          locating: action.payload.locating,
          takingPhotos: action.payload.takingPhotos,
          saved: action.payload.saved
        },
        loadingMetrics: false
      }
    case GET_METRICS_OBJECTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loadingMetrics: false
      }
    case CLEAR_STATE:
      return initialState
    case GET_OBJECTS_STATUS_REQUEST:
      return {
        ...state
      }
    case GET_OBJECTS_STATUS_SUCCESS:
      return {
        ...state,
        objectStatus: action.payload.objectStatus
      }
    case GET_OBJECTS_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case GET_OBJECTS_REQUEST:
      return {
        ...state,
        loadingObjects: !action.payload.silentLoading,
        selectedObjects: action.payload.silentLoading ? state.selectedObjects : initialState.selectedObjects,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        sort: action.payload.sort || initialState.sort,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GET_OBJECTS_SUCCESS:
      return {
        ...state,
        loadingObjects: false,
        objects: action.payload.objects,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_OBJECTS_FAILURE:
      return {
        ...state,
        loadingObjects: false,
        error: action.payload.error
      }
    case TOGGLE_EXACT_LOCATION_CODE:
      return {
        ...state,
        searchFilters: state.searchFilters.map((searchFilter) =>
          searchFilter.key === 'exactLocationCode'
            ? { ...searchFilter, text: searchFilter.text === 'true' ? 'false' : 'true' }
            : searchFilter
        )
      }
    case TOGGLE_ALL_OBJECTS:
      return {
        ...state,
        selectedObjects: state.selectedObjects.length ? initialState.selectedObjects : state.objects
      }
    case TOGGLE_SELECTED_OBJECT: {
      const filtered = state.selectedObjects.filter((obj) => obj.realId !== action.payload.object.realId)
      if (state.selectedObjects.length === filtered.length) filtered.push(action.payload.object)
      return {
        ...state,
        selectedObjects: filtered
      }
    }
    default:
      return state
  }
}

export default ObjectsTableReducer
