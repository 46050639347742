import { TransactionStatusId } from '../../projectApi/TransactionHandler/Transaction/common'
import { OperationType, TypeTransaction } from '../../projectApi/TransactionHandler/Transactions/list'

export enum OperationTypeLabel {
  REMOVAL = 'DEV',
  DEPOSIT = 'ING'
}

export const OPERATION_TYPES = [
  {
    text: OperationTypeLabel.REMOVAL,
    value: OperationType.REMOVAL
  },
  {
    text: OperationTypeLabel.DEPOSIT,
    value: OperationType.DEPOSIT
  }
]

export const TRANSACTION_STATUS_OPTIONS = [
  {
    value: TransactionStatusId.CREADA,
    text: 'Creada'
  },
  {
    value: TransactionStatusId.PENDIENTE,
    text: 'Pendiente'
  },
  {
    value: TransactionStatusId.ASIGNADA_A_VIAJE,
    text: 'Asignada'
  },
  {
    value: TransactionStatusId.REALIZADA,
    text: 'Realizada'
  },
  {
    value: TransactionStatusId.ERRONEA,
    text: 'Errónea'
  },
  {
    value: TransactionStatusId.CANCELADA,
    text: 'Cancelada'
  },
  {
    value: TransactionStatusId.LEVANTADA,
    text: 'Levantada'
  },
  {
    value: TransactionStatusId.ENTREGADA,
    text: 'Entregada'
  },
  {
    value: TransactionStatusId.EN_CURSO,
    text: 'En curso'
  },
  {
    value: TransactionStatusId.EN_DESTINO,
    text: 'En destino'
  },
  {
    value: TransactionStatusId.LISTO_PARA_VIAJAR,
    text: 'Listo para viajar'
  }
]

export const TRANSACTION_TYPE_OPTIONS = [
  { text: 'Entrega x Empresa', value: TypeTransaction.ENTREGA_EMPRESA },
  { text: 'Colecta x Empresa', value: TypeTransaction.COLECTA_EMPRESA },
  { text: 'Entrega x Cliente', value: TypeTransaction.ENTREGA_CLIENTE },
  { text: 'Colecta x Cliente', value: TypeTransaction.COLECTA_CLIENTE }
]
