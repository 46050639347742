import types from '../types/table'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const multiFilterFormatter = (params, keys) => {
  const map = {}

  keys.forEach((k) => {
    map[k] = Array.isArray(params[k]) ? params[k].join(',') : undefined
  })
  return map
}

export default {
  cleanFilters() {
    return (dispatch) => {
      dispatch({ type: types.CLEAN_FILTERS })
      setTimeout(() => Emitter.emit(Events.Leads.CLEAN_FILTERS), 50)
    }
  },

  getLeadsConfig() {
    return (dispatch) => {
      dispatch({ type: types.FETCH_LEADS_CONFIG })

      return apiPrivate
        .get(URL_BASE_MERCURIO + '/bo/config/v1')
        .then((response) => {
          dispatch({
            type: types.FETCH_LEADS_CONFIG_SUCCESS,
            payload: { config: keysToCamel(response.data.description) }
          })
          return true
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_LEADS_CONFIG_FAIL, dispatch)
          return false
        })
    }
  },

  getProspects(params) {
    return (dispatch, getState) => {
      const {
        CountrySelector: { countrySelected }
      } = getState()

      const paramsWithCountry = {
        ...params,
        country: countrySelected.fullName,
        ...multiFilterFormatter(params, [
          'agent_ids',
          'prospect_type_id',
          'contact_medium_id',
          'source',
          'status',
          'provider'
        ])
      }

      dispatch({ type: types.FETCH_PROSPECTS })
      return apiPrivate
        .get(URL_BASE_MERCURIO + '/prospects/v1', { params: paramsWithCountry })
        .then((response) => {
          const formattedData = keysToCamel(response.data.description.prospects)
          const quantity = response.data.description.total_quantity

          dispatch({
            type: types.FETCH_PROSPECTS_SUCCESS,
            payload: { prospects: formattedData, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_PROSPECTS_FAIL, dispatch)
        })
    }
  },

  getReport(params) {
    return (dispatch, getState) => {
      const {
        CountrySelector: { countrySelected }
      } = getState()

      const toastId = setToastLoading('Solicitando Reporte, por favor espere...')
      const paramsWithCountry = {
        ...params,
        country: countrySelected.fullName,
        ...multiFilterFormatter(params, [
          'agent_ids',
          'prospect_type_id',
          'contact_medium_id',
          'source',
          'status',
          'provider'
        ])
      }

      dispatch({ type: types.GET_REPORT_REQUEST })

      return apiPrivate
        .get(URL_BASE_MERCURIO + '/prospect/report', { params: paramsWithCountry })
        .then(() => {
          dispatch({
            type: types.GET_REPORT_SUCCESS
          })
          setToastSuccessUpdate(toastId, {
            render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
          })
        })
        .catch((error) => {
          setToastErrorUpdate(toastId, {
            render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
          })
          handleErrorsWithAction(error, types.GET_REPORT_FAILURE, dispatch)
        })
    }
  },

  setSorter(sorter) {
    return { type: types.SET_SORTER, payload: { sorter } }
  },

  setFilter(filter) {
    return { type: types.SET_FILTER, payload: { filter } }
  },

  setFunnelStateFilterBar(state) {
    return { type: types.SET_FUNNEL_STATE_FILTER_BAR, payload: { state } }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  }
}
