import { UploadFilesAction, UploadFileActionTypes } from './types'

const initialState = {
  loadingFile: false,
  error: ''
}

export type UploadFilesState = {
  loadingFile: boolean
  error: string
}

function reducer(state: UploadFilesState = initialState, action: UploadFilesAction): UploadFilesState {
  switch (action.type) {
    case UploadFileActionTypes.UPLOAD_FILES_REQUEST:
      return {
        ...state,
        loadingFile: true,
        error: ''
      }
    case UploadFileActionTypes.UPLOAD_FILES_SUCCESS:
      return initialState
    case UploadFileActionTypes.UPLOAD_FILES_FAILURE:
      return {
        ...state,
        loadingFile: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default reducer
