import {
  AddEditLocationModalActions,
  AddEditLocationModalState,
  AddEditLocationModalTypes,
  ClearStateTypes,
  CreateLocationTypes,
  EditLocationTypes,
  GetDepositLocationTypes,
  GetLocationTypes,
  SetLocationAddEditFields
} from '../types/AddEditLocationModal'

const DEFAULT_AMOUNT_LENGTH_INPUT = 100
const DEFAULT_AMOUNT_HEIGHT_INPUT = 100
const DEFAULT_AMOUNT_WIDTH_INPUT = 230
const DEFAULT_AMOUNT_WEIGHT_INPUT = 5000
const DEFAULT_VOLUMEN_INPUT = DEFAULT_AMOUNT_LENGTH_INPUT * DEFAULT_AMOUNT_HEIGHT_INPUT * DEFAULT_AMOUNT_WIDTH_INPUT

const addEditLocationModalInitialState: AddEditLocationModalState = {
  locationTypes: [],
  deposits: [],
  isOpen: false,
  selectedLocation: null,
  isEdit: false,
  isLoading: false,
  code: '',
  description: '',
  type: '',
  depositId: 0,
  locationId: 0,
  maxHeightInCm: DEFAULT_AMOUNT_HEIGHT_INPUT,
  maxLengthInCm: DEFAULT_AMOUNT_LENGTH_INPUT,
  maxVolumeInCm: DEFAULT_VOLUMEN_INPUT,
  maxWeightInKg: DEFAULT_AMOUNT_WEIGHT_INPUT,
  maxWidthInCm: DEFAULT_AMOUNT_WIDTH_INPUT,
  error: ''
}

function AddEditLocationModalReducer(
  state: AddEditLocationModalState = addEditLocationModalInitialState,
  action: AddEditLocationModalActions
): AddEditLocationModalState {
  switch (action.type) {
    case SetLocationAddEditFields.SET_NUMBER_FIELD:
    case SetLocationAddEditFields.SET_STRING_FIELD: {
      const { value, field } = action.payload
      return {
        ...state,
        [field]: value
      }
    }
    case AddEditLocationModalTypes.SET_MODAL_OPEN:
      if (action.payload.selectedLocation) {
        return {
          ...state,
          ...action.payload.selectedLocation,
          depositId: action.payload.selectedLocation.depositID,
          locationId: action.payload.selectedLocation.id,
          maxWeightInKg: action.payload.selectedLocation.maxWeightInGr / 1000,
          isOpen: true,
          selectedLocation: action.payload.selectedLocation,
          isEdit: true
        }
      } else {
        return {
          ...state,
          isOpen: true,
          selectedLocation: null,
          isEdit: false
        }
      }

    case AddEditLocationModalTypes.SET_MODAL_CLOSE:
      return addEditLocationModalInitialState
    case CreateLocationTypes.CREATE_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case CreateLocationTypes.CREATE_LOCATION_SUCCESS:
      return addEditLocationModalInitialState
    case CreateLocationTypes.CREATE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case EditLocationTypes.EDIT_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case EditLocationTypes.EDIT_LOCATION_SUCCESS:
      return addEditLocationModalInitialState
    case EditLocationTypes.EDIT_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GetLocationTypes.GET_LOCATION_TYPES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GetLocationTypes.GET_LOCATION_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        locationTypes: action.payload.locationTypes
      }
    case GetLocationTypes.GET_LOCATION_TYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GetDepositLocationTypes.GET_DEPOSITS_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GetDepositLocationTypes.GET_DEPOSITS_LOCATION_SUCCESS:
      return {
        ...state,
        deposits: action.payload.deposits,
        isLoading: false
      }
    case GetDepositLocationTypes.GET_DEPOSITS_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case ClearStateTypes.CLEAR_STATE:
      return addEditLocationModalInitialState
    default:
      return state
  }
}

export default AddEditLocationModalReducer
