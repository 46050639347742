import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { VehiclesSearchFilter, VehiclesTableSearchKey, VehiclesTableSort } from '../types/vehiclesTable'
import { getSearchProps, getSortProps } from '../../../components/DynamicTable/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Vehicle } from '../../../projectApi/Trips/Vehicles/common'
import { Link } from 'react-router-dom'

type VehicleTableProps = {
  fetchingVehicles: boolean
  sort: VehiclesTableSort
  vehicles: Vehicle[]
  pagination: Pagination
  searchFilters: VehiclesSearchFilter[]
  actions: MenuAction<Vehicle>[]
  handleSearch: (key: VehiclesTableSearchKey, newValue: string) => void
  handleSort: (newSort: VehiclesTableSort) => void
}

const VehiclesTable = ({
  fetchingVehicles,
  sort,
  vehicles,
  pagination,
  handleSearch,
  handleSort,
  searchFilters,
  actions
}: VehicleTableProps) => {
  const columns: Column<Vehicle>[] = [
    {
      key: 'id',
      label: 'ID',
      maxWidth: '200px',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'name',
      label: 'Nombre',
      minWidth: '1px',
      maxWidth: '300px',
      renderDataCell: ({ name }) => name
    },
    {
      key: 'provider',
      label: 'Proveedor',
      renderDataCell: ({ provider }) => (
        <Link to={`/providers?id=${provider.id}`}>
          {provider.id} - {provider.name}
        </Link>
      ),
      search: getSearchProps('providerId', 'ID Proveedor', handleSearch, searchFilters)
    },
    {
      key: 'maxCapacity',
      label: 'Capacidad Máxima',
      renderDataCell: ({ maxCapacity }) => `${maxCapacity} m³`,
      sort: getSortProps('max_capacity', sort, handleSort)
    },
    {
      key: 'usableCapacity',
      label: 'Capacidad Utilizable',
      renderDataCell: ({ usableCapacity }) => `${usableCapacity} m³`,
      sort: getSortProps('usable_capacity', sort, handleSort)
    },
    {
      key: 'maxHeight',
      label: 'Alto Máx.',
      renderDataCell: ({ maxHeight }) => `${maxHeight} m`,
      sort: getSortProps('max_height', sort, handleSort)
    },
    {
      key: 'maxWidth',
      label: 'Ancho Máx.',
      renderDataCell: ({ maxWidth }) => `${maxWidth} m`,
      sort: getSortProps('max_width', sort, handleSort)
    },
    {
      key: 'maxLength',
      label: 'Largo Máx.',
      renderDataCell: ({ maxLength }) => `${maxLength} m`,
      sort: getSortProps('max_length', sort, handleSort)
    },
    {
      key: 'maxWeight',
      label: 'Peso Máx.',
      renderDataCell: ({ maxWeight }) => `${maxWeight} kg`,
      sort: getSortProps('max_weight', sort, handleSort)
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingVehicles}
      rows={vehicles}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default VehiclesTable
