import types from '../types/removeObject'

const initialRoot = {
  open: false,
  objectId: '',
  deletedReasons: [],
  deletedReason: 1,
  deletedReasonsFetched: false,
  reason: '',
  isLoading: false,
  loadingReasons: false,
  errorReasons: '',
  error: ''
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.OPEN_REASON_MODAL:
      return {
        ...state,
        open: true,
        objectId: action.payload.objectId
      }
    case types.GET_OBJECT_DELETED_REASONS_REQUEST:
      return { ...state, loadingReasons: true }
    case types.GET_OBJECT_DELETED_REASONS_SUCCESS:
      return {
        ...state,
        loadingReasons: false,
        deletedReasons: action.payload.objectDeletedReasons,
        deletedReasonsFetched: true
      }
    case types.GET_OBJECT_DELETED_REASONS_FAILURE:
      return {
        ...state,
        loadingReasons: false,
        errorReasons: action.payload.error
      }
    case types.REMOVE_OBJECT_REQUEST: {
      return { ...state, isLoading: true, error: '' }
    }
    case types.REMOVE_OBJECT_SUCCESS: {
      return { ...initialRoot, deletedReasons: state.deletedReasons }
    }
    case types.REMOVE_OBJECT_FAILURE: {
      return { ...state, isLoading: false, error: action.payload.error }
    }
    case types.SET_DELETED_REASON:
      return { ...state, deletedReason: action.payload.deletedReason }
    case types.SET_REASON:
      return { ...state, reason: action.payload.reason }
    case types.CANCEL_REASON_MODAL:
      return { ...initialRoot, deletedReasons: state.deletedReasons }
    case types.CLOSE_REASON_MODAL:
      return { ...initialRoot, deletedReasons: state.deletedReasons }
    default:
      return state
  }
}

export default root
