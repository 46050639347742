import {
  ObjectsSearchFilter,
  ObjectsTableCategoryFilter,
  ObjectsTableSort
} from '../../../sections/ObjectsV2/types/objectsTable'

export enum ObjectsEventsEnum {
  OBJECT_PHOTO_MARKED_AS_MAIN = 'OBJECTS/OBJECT_PHOTO_MARKED_AS_MAIN',
  OBJECT_PHOTO_DELETED = 'OBJECTS/OBJECT_PHOTO_DELETED',
  OBJECT_DELETED = 'OBJECTS/OBJECT_DELETED',
  MASSIVE_EDIT_COMPLETED = 'OBJECTS/MASSIVE_EDIT_COMPLETED',
  OBJECT_EDITED = 'OBJECTS/OBJECT_EDITED',
  OBJECT_REDIRECT_WITH_FILTER = 'OBJECTS/OBJECT_REDIRECT_WITH_FILTER',
  OBJECT_TOGGLE_LOCATION = 'OBJECTS/OBJECT_TOGGLE_LOCATION'
}

export type ObjectsEvents = {
  [ObjectsEventsEnum.OBJECT_PHOTO_MARKED_AS_MAIN]: () => void
  [ObjectsEventsEnum.OBJECT_PHOTO_DELETED]: () => void
  [ObjectsEventsEnum.MASSIVE_EDIT_COMPLETED]: () => void
  [ObjectsEventsEnum.OBJECT_EDITED]: () => void
  [ObjectsEventsEnum.OBJECT_DELETED]: () => void
  [ObjectsEventsEnum.OBJECT_TOGGLE_LOCATION]: () => void
  [ObjectsEventsEnum.OBJECT_REDIRECT_WITH_FILTER]: (newParams: {
    searchFilters?: ObjectsSearchFilter[]
    categoryFilter?: ObjectsTableCategoryFilter
    sort?: ObjectsTableSort
    silentLoading?: boolean
  }) => void
}
