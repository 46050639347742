import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Paging, Role } from './common'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'

type APIParent = {
  id: number
  name: string
  last_name: string
}

type APIAccount = {
  id: number
  parent: APIParent
  user_id: number
  role: Role
}

export type Account = CamelCasedPropertiesDeep<APIAccount>

interface APIResponse {
  paging: Paging
  results: APIAccount[]
}

interface Response {
  accounts: Account[]
  total: number
}

export function list(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/satellite-user`)
    .then((response: AxiosResponse<APIResponse>) => {
      return {
        accounts: camelcaseKeys(response.data.results, { deep: true }),
        total: response.data.paging.total
      }
    })
    .catch((error) => {
      throw error
    })
}
