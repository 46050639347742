import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IdentificationType } from '../../../../services/mercado-pago'

type DNICardProps = {
  name: string
  identificationType: IdentificationType | undefined
  documentNumber: string
}

const Container = styled.div`
  background: linear-gradient(106.15deg, #cedbf0 -13.54%, #dbe2f7 50.58%, #e8effd 108.08%),
    linear-gradient(106.15deg, #041c6e -13.54%, #2a3b86 50.58%, #4f65c6 108.08%);
  border-radius: 18.6269px;
  margin: auto;
  padding: 36px 0 36px 19px;
  width: 100%;
  max-width: 262px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
`

const ImageWrapper = styled.div`
  background: #fafbfe;
  border-radius: 9px;
  width: 73px;
  height: 79px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  justify-content: center;
`

const TextWrapper = styled.div`
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #999999;
`

const Name = styled.div`
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'Consolas', 'Courier', monospace;
`

const DNIValue = styled.div`
  font-size: 12px;
`

const DNICard = (props: DNICardProps) => {
  const { name, identificationType, documentNumber } = props
  const formattedDNI = useMemo(() => {
    const minLength = identificationType?.min_length || 0
    let newFormattedDNI = documentNumber
    while (newFormattedDNI.length < minLength) {
      newFormattedDNI += '*'
    }

    return newFormattedDNI.replace(/\B(?=(.{3})+(?!.))/g, ' ')
  }, [identificationType, documentNumber])

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <ImageWrapper>
          <img src={`${process.env.PUBLIC_URL}/dni_avatar.svg`} alt="dniAvatar" width="42" height="55" />
        </ImageWrapper>
        <TextWrapper>
          <Name>{name}</Name>
          <DNIValue>{formattedDNI}</DNIValue>
        </TextWrapper>
      </div>
    </Container>
  )
}

export default DNICard
