import React, { useMemo } from 'react'
import { Modal } from 'antd'
import { IN_TRIP, TO_CATEGORIZE, TO_LOCATE, TO_PHOTOGRAPH, WITH_OWNER } from '../../Objects/constants/objectStatus'

const styles = {
  modal: {
    maxWidth: 600
  }
}

const WarningModal = (props) => {
  const { warning, objectsState, objectsActions } = props

  const toCategorizeObjects = useMemo(
    () => objectsState.objects.filter((object) => object.ObjectStatus.ID === TO_CATEGORIZE.numberValue).length,
    [objectsState]
  )
  const toPhotographObjects = useMemo(
    () => objectsState.objects.filter((object) => object.ObjectStatus.ID === TO_PHOTOGRAPH.numberValue).length,
    [objectsState]
  )
  const toLocateObjects = useMemo(
    () => objectsState.objects.filter((object) => object.ObjectStatus.ID === TO_LOCATE.numberValue).length,
    [objectsState]
  )
  const withOwnerObjects = useMemo(
    () => objectsState.objects.filter((object) => object.ObjectStatus.ID === WITH_OWNER.numberValue).length,
    [objectsState]
  )
  const inTripObjects = useMemo(
    () => objectsState.objects.filter((object) => object.ObjectStatus.ID === IN_TRIP.numberValue).length,
    [objectsState]
  )
  const notDepositedObjects =
    toCategorizeObjects + toPhotographObjects + toLocateObjects + withOwnerObjects + inTripObjects

  return (
    <Modal
      title="Advertencia"
      visible={warning}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => objectsActions.closeWarningModal(false)}
      okText="Entendido"
      style={styles.modal}>
      <div>
        <p>
          El usuario cuenta con <span style={{ fontWeight: 'bold' }}>{notDepositedObjects}</span> objetos no disponibles
          para devolución. Deberá realizar la acción correspondiente:
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>A Catalogar ({toCategorizeObjects}) :</span>{' '}
          <span>{toCategorizeObjects > 1 ? 'Catalogarlos' : 'Catalogarlo'}</span>
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>A Fotografiar ({toPhotographObjects}) : </span>
          <span>{toPhotographObjects > 1 ? 'Fotografiarlos' : 'Fotografiarlo'}</span>
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>A Ubicar ({toLocateObjects}) : </span>
          <span>{toLocateObjects > 1 ? 'Ubicarlos' : 'Ubicarlo'}</span>
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>En Propietario ({withOwnerObjects}) : </span>
          <span>{withOwnerObjects > 1 ? 'No es posible devolverlos' : 'No es posible devolverlo'}</span>
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>En Tránsito ({inTripObjects}) : </span>
          <span>{inTripObjects > 1 ? 'No es posible devolverlos' : 'No es posible devolverlo'}</span>
        </p>
      </div>
    </Modal>
  )
}

export default WarningModal
