/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { PickingStatus } from './common'
import { Moment } from 'moment'
import { convertToMoment, formatDates } from '../../utils'
import { OverrideProps } from '../../../utils/utilityTypes'

type APIPicking = {
  id: number
  created_at: string
  picking_date: string
  start_date?: string
  end_date?: string
  location_id: number
  operation_id: number
  agents: APIAgent[]
  picking_objects: APIPickingObject[]
  priority: string
  status: PickingStatus
  owner: APIOwner
  objects_metrics: APIObjectMetricResponse
}

type APIAgent = {
  id: number
  created_at: string
  updated_at: string
  deleted_at: null
  picking_id: number
  agent_id: number
  name: string
  last_name: string
}

export type APIOwner = {
  id: number
  name: string
  last_name: string
}

type APIPickingObject = {
  id: number
  agent_id: number
  picking_id: number
  object: APIObjectSearch
}

type APIObjectSearch = {
  id: number
  description: string
  photos: APIPhoto[]
  objectStatus: APIObjectStatus
  product: APIProduct
  location: APILocation
  identification_code: APIIdentificationCode[]
}

type APILocation = {
  id: number
  code: string
}

type APIObjectMetricResponse = {
  quantity_objects: number
  quantity_objects_founded: number
  quantity_objects_not_founded: number
}

type APIPhoto = {
  id: number
  url: string
}

type APIProduct = {
  id: number
  description: string
}

type APIObjectStatus = {
  id: number
  description: string
}

type APIIdentificationCode = {
  id: number
  code: string
}

interface APIResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  results: APIPicking[]
}

type DateProps = 'createdAt' | 'pickingDate' | 'startDate' | 'endDate'

export type Agent = CamelCasedPropertiesDeep<APIAgent>
export type PickingObject = CamelCasedPropertiesDeep<APIPickingObject>
export type PickingWithoutFormat = CamelCasedPropertiesDeep<APIPicking>
export type Picking = OverrideProps<PickingWithoutFormat, DateProps, Moment | null>

type Response = {
  pickings: Picking[]
  total: number
}

export type PickingsListSortKey = 'id' | 'picking_date'

interface ListParams {
  limit?: number
  offset?: number
  sort?: PickingsListSortKey
  order?: SortDirection
  id?: number
  status?: string
  agent_id?: string
  owner_id?: number
  operation_id?: number
  picking_date_from?: Moment
  picking_date_to?: Moment
  start_date_from?: Moment
  start_date_to?: Moment
  end_date_from?: Moment
  end_date_to?: Moment
  created_at_from?: Moment
  created_at_to?: Moment
}

export function list({
  limit,
  offset,
  id,
  sort,
  order,
  status,
  agent_id,
  owner_id,
  operation_id,
  picking_date_from,
  picking_date_to,
  start_date_from,
  start_date_to,
  end_date_from,
  end_date_to,
  created_at_from,
  created_at_to
}: ListParams): Promise<Response> {
  const params = {
    limit,
    offset,
    id,
    sort,
    order,
    status,
    agent_id,
    operation_id,
    owner_id,
    ...formatDates({
      dates: {
        created_at_from,
        created_at_to,
        picking_date_from,
        picking_date_to,
        start_date_from,
        start_date_to,
        end_date_from,
        end_date_to
      }
    })
  }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/pickings/search`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelPickings: PickingWithoutFormat[] = camelcaseKeys(response.data.results, { deep: true })
      const formattedPickings: Picking[] = camelPickings.map((picking) => ({
        ...picking,
        createdAt: convertToMoment(picking.createdAt),
        pickingDate: convertToMoment(picking.pickingDate),
        startDate: convertToMoment(picking.startDate),
        endDate: convertToMoment(picking.endDate)
      }))
      const camelResponse: Response = {
        pickings: formattedPickings,
        total: response.data.paging.total
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
