import { combineReducers } from 'redux'
import cardsTable from './cardsTable'
import cardsModal from './cardsModal'

const rootReducer = combineReducers({
  table: cardsTable,
  modal: cardsModal
})

export default rootReducer
