import React from 'react'
import moment from 'moment'
import { Modal } from 'antd'
import { formatDateWithoutTime } from '../utils'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  message: {
    color: 'green',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

const CalculateBillingModal = (props) => {
  const { calculateBillingModal, calculateBillingActions } = props
  const { calculateBillingModalOpen, loading, error, message } = calculateBillingModal

  const billingStart = moment().startOf('month').format('YYYY-MM-DD')
  const retroactiveDate = moment().subtract(1, 'month').startOf('month').add(1, 'day').format('YYYY-MM-DD')

  const calculate = () => {
    calculateBillingActions.calculateBillingFromDate(billingStart, retroactiveDate)
  }

  return (
    <Modal
      title={'Calcular Abonos'}
      visible={calculateBillingModalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={calculate}
      onCancel={() => calculateBillingActions.closeCalculateBillingModal()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p>¿Esta seguro que quiere calcular los abonos?</p>
      <p>
        Fecha de inicio del periodo: {formatDateWithoutTime(billingStart)}
        <br />
        Fecha Retroactiva: {formatDateWithoutTime(retroactiveDate)}{' '}
      </p>
      <div className="container">
        {error && <p style={styles.error}>{error}</p>}
        {message && <p style={styles.message}>{message}</p>}
      </div>
    </Modal>
  )
}

export default CalculateBillingModal
