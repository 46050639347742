import {
  GetActualM3StorageRequest,
  GetActualM3StorageSuccess,
  ItemEditView,
  ItemsDataTypes,
  ItemsFetchInitialItemsTypes,
  ItemsFetchItemsTypes,
  ItemsGetActualM3StorageTypes,
  ItemsRecalculateItemsTypes,
  RecalculateItemsSuccess,
  SelectedItem,
  SetInitialItems
} from '../../types/EditDepositViewTypes/items'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { getRoundedTotalM3, getSelectedItem } from '../../../../common/operations/selectors/items'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { AppThunk } from '../../../../store'
import {
  FormattedProduct,
  NewProductPrice,
  formatProductsToProductPrice
} from '../../../../projectApi/CategoryCreation/Product/common'
import { API } from '../../../../projectApi'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'

const LIMIT = 10

let searchTimer: NodeJS.Timeout
let recalculateItemsTimer: NodeJS.Timeout
let recalculateItemsOldLastSearchId: number
let getItemPricesOldLastSearchId: number

const ItemsActionsCreators = {
  getActualM3Storage:
    (userId: number): AppThunk =>
    async (dispatch) => {
      const request: GetActualM3StorageRequest = { type: ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_REQUEST }
      dispatch(request)

      try {
        const params = { user_id: userId }
        const { m3 } = await API.ObjectAdministration.Object.getM3Storage(params)

        const success: GetActualM3StorageSuccess = {
          type: ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_SUCCESS,
          payload: { actualM3Storage: m3 }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_FAILURE, dispatch)
      }
    },

  recalculateItemsFromStore: (): AppThunk => (dispatch, getState) => {
    const { selectedItems, actualM3Storage } = getState().Deposits.items
    const { serviceType } = getState().Deposits.serviceTypes
    const { transaction } = getState().Deposits.editView
    dispatch(
      ItemsActionsCreators.recalculateItems({
        items: selectedItems,
        actualM3Storage,
        countryCode: transaction.generalData.countryId as CountryIdCode,
        userId: transaction.generalData.user.id,
        serviceTypeId: serviceType.value
      })
    )
  },

  recalculateItems:
    ({
      items,
      actualM3Storage,
      countryCode,
      userId,
      serviceTypeId
    }: {
      items: SelectedItem[]
      actualM3Storage: number
      countryCode: CountryIdCode
      userId: number
      serviceTypeId: string
    }): AppThunk =>
    (dispatch) => {
      const lastSearchID = new Date().getMilliseconds()
      recalculateItemsOldLastSearchId = lastSearchID

      dispatch({ type: ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_REQUEST, payload: { lastSearchID } })

      clearTimeout(recalculateItemsTimer)
      setTimeout(async () => {
        // Validate if is the last search
        if (lastSearchID !== recalculateItemsOldLastSearchId) return

        try {
          // Filter items that product has not been selected and map guids
          const itemsWithSelectedProduct = items.filter((item) => item.selectedItem !== null && !item.deleted)
          const m3Storage = getRoundedTotalM3(itemsWithSelectedProduct) + actualM3Storage
          const guid: Set<string> = new Set(itemsWithSelectedProduct.map((item) => item.selectedItem?.guid || ''))

          // Execute requests and validate if is still the last search
          const products = (
            await API.CategoryCreation.Product.listAdmin(
              {
                mt3: m3Storage,
                countryCode,
                guids: Array.from(guid).join(','),
                userId,
                serviceTypeId
              },
              formatProductsToProductPrice
            )
          ).products as NewProductPrice[]
          if (lastSearchID !== recalculateItemsOldLastSearchId) return

          const success: RecalculateItemsSuccess = {
            type: ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_SUCCESS,
            payload: { products }
          }

          dispatch(success)
        } catch (error) {
          handleErrorsWithAction(error, ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_FAILURE, dispatch)
        }
      }, 250)
    },

  getInitialItem:
    (countryCode = CountryIdCode.ARGENTINA, serviceTypeId: string): AppThunk =>
    async (dispatch) => {
      dispatch({ type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_REQUEST })

      try {
        const params = {
          limit: LIMIT,
          countryCode,
          show_hidden: false,
          serviceTypeId
        }
        const { products } = await API.CategoryCreation.Product.listAdmin(params)

        dispatch({
          type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_SUCCESS,
          payload: { items: products }
        })
      } catch (e) {
        handleErrorsWithAction(e, ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_FAILURE, dispatch)
      }
    },

  getItemsPrice:
    (name: string, index: number, countryCode = CountryIdCode.ARGENTINA, serviceTypeId: string): AppThunk =>
    (dispatch) => {
      clearTimeout(searchTimer)
      searchTimer = setTimeout(async () => {
        const params = {
          limit: LIMIT,
          name,
          countryCode,
          show_hidden: false,
          serviceTypeId
        }

        const lastSearchID = new Date().getMilliseconds()
        getItemPricesOldLastSearchId = lastSearchID

        dispatch({
          type: ItemsFetchItemsTypes.FETCH_ITEMS_REQUEST,
          payload: {
            index,
            lastSearchID
          }
        })

        try {
          if (lastSearchID === getItemPricesOldLastSearchId) {
            const { products } = await API.CategoryCreation.Product.listAdmin(params)

            dispatch({
              type: ItemsFetchItemsTypes.FETCH_ITEMS_SUCCESS,
              payload: {
                items: products,
                index
              }
            })
            dispatch(ItemsActionsCreators.cleanItem(index))
          }
        } catch (e) {
          handleErrorsWithAction(e, ItemsFetchItemsTypes.FETCH_ITEMS_FAILURE, dispatch, {
            index
          })
        }
      }, 250)
    },

  setInitialItems: (index: number): SetInitialItems => {
    const lastSearchID = new Date().getMilliseconds()
    return { type: ItemsDataTypes.SET_INITIAL_ITEMS, payload: { lastSearchID, index } }
  },

  cleanItem:
    (index: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: ItemsDataTypes.CLEAN_ITEM, payload: { index } })

      setTimeout(() => {
        Emitter.emit(Events.Proposal.CLEAN_ITEM)
      }, 250)
    },

  removeAllItems: (): AppThunk => (dispatch) => {
    dispatch({ type: ItemsDataTypes.REMOVE_ALL_ITEMS })

    setTimeout(() => {
      Emitter.emit(Events.Proposal.REMOVE_ALL_ITEMS)
    }, 250)
  },

  removeItem:
    (index: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: ItemsDataTypes.REMOVE_ITEM, payload: { index } })

      setTimeout(() => {
        Emitter.emit(Events.Proposal.REMOVE_ITEM)
      }, 250)
    },

  setItemQuantity:
    (index: number, quantity: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: ItemsDataTypes.SET_ITEM_QUANTITY, payload: { index, quantity } })

      setTimeout(() => {
        Emitter.emit(Events.Proposal.MODIFY_ITEM_QUANTITY)
      }, 250)
    },

  setItemPackaging:
    (index: number, packaging: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: ItemsDataTypes.SET_ITEM_PACKAGING,
        payload: { index, packaging }
      })

      setTimeout(() => {
        Emitter.emit(Events.EditDepositView.SET_ITEM_PACKAGING)
      }, 250)
    },

  setItemFloors:
    (index: number, floors: number): AppThunk =>
    (dispatch) => {
      dispatch({
        type: ItemsDataTypes.SET_ITEM_FLOORS,
        payload: { index, floors }
      })

      setTimeout(() => {
        Emitter.emit(Events.EditDepositView.SET_ITEM_FLOORS)
      }, 250)
    },

  setDisassemble:
    (index: number, disassemble: boolean): AppThunk =>
    (dispatch) => {
      dispatch({ type: ItemsDataTypes.SET_DISASSEMBLE, payload: { index, disassemble } })

      setTimeout(() => {
        Emitter.emit(Events.EditDepositView.SET_ITEM_DISASSEMBLE)
      }, 250)
    },

  setItem:
    (
      selectedItem: string,
      items: FormattedProduct[] | ItemEditView[],
      index: number,
      selectedItems: SelectedItem[]
    ): AppThunk =>
    (dispatch) => {
      const item = getSelectedItem(selectedItem, items)
      const length = selectedItems.length

      dispatch({ type: ItemsDataTypes.SET_ITEM, payload: { item, index } })

      if (index + 1 === length) {
        dispatch({ type: ItemsDataTypes.NEW_ITEM })
      }

      setTimeout(() => {
        Emitter.emit(Events.Proposal.SET_ITEM)
      }, 250)
    },

  getInitialItemsFiltered:
    (serviceTypeId: string, countryCode: CountryIdCode): AppThunk =>
    async (dispatch) => {
      const params = {
        limit: LIMIT,
        serviceTypeId,
        countryCode,
        show_hidden: false
      }
      dispatch({ type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_REQUEST })

      try {
        const { products } = await API.CategoryCreation.Product.listAdmin(params)

        dispatch({
          type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_SUCCESS,
          payload: { items: products }
        })
      } catch (e) {
        handleErrorsWithAction(e, ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_FAILURE, dispatch)
      }
    }
}

export default ItemsActionsCreators
