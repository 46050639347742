import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { ProductListSortKey, ProductsListCategoryFilterKey } from '../../../projectApi/CategoryCreation/Product/list'
import { CategoryFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'
import { Category as SearchCategory } from '../../../projectApi/CategoryCreation/Search/Categories/list'

export type ProductsTableSortKey = ProductListSortKey
export type ProductsTableSort = Sort<ProductsTableSortKey>

export type ProductsTableSearchKey = 'id' | 'name' | 'showInSearch' | 'deprecated' | 'categoryId'
export type ProductsSearchFilter = SearchFilter<ProductsTableSearchKey>

export type ProductsCategoryFilter = CategoryFilter<ProductsListCategoryFilterKey>

export type ParamsType = {
  Limit: number
  Offset: number
  Order: string
  Column: ProductsTableSortKey
}

export type SearchFiltersType = [
  {
    key: string
    text: any | null
  }
]
export interface ProductsTableState {
  products: FormattedProduct[]
  loadingProducts: boolean
  sendingCSV: boolean
  error: string
  pagination: Pagination
  params: ParamsType
  searchFilters: ProductsSearchFilter[]
  Column: ProductsTableSort
  sort: ProductsTableSort
  categoryFilter: ProductsCategoryFilter
  serviceTypes: ServiceType[]
  allCategories: Category[]
  categories: {
    fetching: boolean
    list: SearchCategory[]
    error: string
  }
  categoryId: number
}

export const GET_PRODUCTS_REQUEST = 'PRODUCTS/TABLE/GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'PRODUCTS/TABLE/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'PRODUCTS/TABLE/GET_PRODUCTS_FAILURE'

export const GET_ALL_CATEGORIES_SUCCESS = 'PRODUCTS/GET_ALL_CATEGORIES_SUCCESS'
export const GET_SERVICE_TYPE_SUCCESS = 'PRODUCTS/GET_SERVICE_TYPE_SUCCESS'
export interface GetProductsRequest {
  type: typeof GET_PRODUCTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: ProductsSearchFilter[]
    Column?: ProductsTableSort
    sort: ProductsTableSort
    categoryFilter: ProductsCategoryFilter
    categoryId?: number
  }
}

export interface GetProductsSuccess {
  type: typeof GET_PRODUCTS_SUCCESS
  payload: {
    products: FormattedProduct[]
    total: number
  }
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface GetProductsFailure {
  type: typeof GET_PRODUCTS_FAILURE
  payload: {
    error: string
  }
}

export type GetCategories = {
  type: typeof GET_ALL_CATEGORIES_SUCCESS
  payload: { categories: Category[] }
}

export type GetServiceTypes = {
  type: typeof GET_SERVICE_TYPE_SUCCESS
  payload: { serviceTypes: ServiceType[] }
}

export type Category = {
  value: number
  label: string
}

export type ServiceType = {
  OperationType: string
  Type: string
  Multiplier: number
}

export enum CategoriesTypes {
  SET_CATEGORY = 'PRODUCTS/PRODUCTS_TABLE/SET_CATEGORY'
}

export interface SetCategory {
  type: typeof CategoriesTypes.SET_CATEGORY
}

export enum SearchCategoriesTypes {
  SEARCH_CATEGORIES_REQUEST = 'PRODUCTS/PRODUCTS_TABLE/SEARCH_CATEGORIES_REQUEST',
  SEARCH_CATEGORIES_SUCCESS = 'PRODUCTS/PRODUCTS_TABLE/SEARCH_CATEGORIES_SUCCESS',
  SEARCH_CATEGORIES_FAILURE = 'PRODUCTS/PRODUCTS_TABLE/SEARCH_CATEGORIES_FAILURE'
}

export interface SearchCategoriesRequest {
  type: typeof SearchCategoriesTypes.SEARCH_CATEGORIES_REQUEST
}

export interface SearchCategoriesSuccess {
  type: typeof SearchCategoriesTypes.SEARCH_CATEGORIES_SUCCESS
  payload: { categories: SearchCategory[] }
}

export interface SearchCategoriesFailure {
  type: typeof SearchCategoriesTypes.SEARCH_CATEGORIES_FAILURE
}

export type SearchCategoriesActions = SearchCategoriesRequest | SearchCategoriesSuccess | SearchCategoriesFailure

export const SEND_CSV_REQUEST = 'PRODUCTS/PRODUCTS_TABLE/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'PRODUCTS/PRODUCTS_TABLE/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'PRODUCTS/PRODUCTS_TABLE/SEND_CSV_FAILURE'

export interface SendCSVRequest {
  type: typeof SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SEND_CSV_FAILURE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export type GetProductsActions =
  | GetProductsRequest
  | GetProductsSuccess
  | GetProductsFailure
  | GetCategories
  | GetServiceTypes

export type ProductsTableActions = GetProductsActions | SetCategory | SearchCategoriesActions | SendCSVActions
