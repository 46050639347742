import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { CamelCasedPropertiesDeep } from 'type-fest'
import camelcaseKeys from 'camelcase-keys'
import { SortDirection } from '../../../../components/DynamicTable/types/types'
import moment, { Moment } from 'moment'
import { formatDates } from '../../../utils'
import snakecaseKeys from 'snakecase-keys'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'

export interface Paging {
  total: number
  limit: number
  offset: number
}

export interface APIDiscount {
  id: number
  created_at: string
  updated_at: string
  date: string
  discount: number
}

export type Discount = Omit<CamelCasedPropertiesDeep<APIDiscount>, 'date' | 'createdAt' | 'updatedAt'> & {
  date: Moment | null
  createdAt: Moment | null
  updatedAt: Moment | null
}

interface APIResponse {
  paging: Paging
  results: APIDiscount[]
}

interface Response {
  total: number
  discounts: Discount[]
}

export type DiscountDatesSortKey = 'created_at' | 'date'

export type listDiscountDatesParams = {
  date?: string
  createdAtFrom?: Moment
  createdAtTo?: Moment
  limit?: number
  offset?: number
  column?: DiscountDatesSortKey
  order?: SortDirection
}

export function list({ createdAtFrom, createdAtTo, date, ...params }: listDiscountDatesParams): Promise<Response> {
  const formatedParams = {
    ...snakecaseKeys({
      date: date?.split('-').reverse().join('-'),
      ...params,
      ...formatDates({
        dates: {
          createdAtFrom,
          createdAtTo
        },
        format: ''
      })
    })
  }

  return apiPrivate
    .get(`${URL_BASE_TIMESLOT}/discount/by/specific-day`, { params: formatedParams })
    .then(({ data }: AxiosResponse<APIResponse>) => {
      const discounts = camelcaseKeys(data.results, { deep: true }).map((discount) => ({
        ...discount,
        date: moment(discount.date),
        createdAt: moment(discount.createdAt),
        updatedAt: moment(discount.updatedAt)
      }))

      return {
        total: data.paging.total,
        discounts
      }
    })
    .catch((error) => {
      throw error
    })
}
