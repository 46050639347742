import React, { useRef, useState } from 'react'
import styles from './TextField.module.scss'
import { InputIcon, InputIconName } from './Icons'
import clsx from 'clsx'

interface TextFieldProps {
  label: string
  value: string
  onChange: (text: string) => void
  onFocus?: () => void
  onBlur?: () => void
  error?: boolean
  disabled?: boolean
  type?: 'password' | 'text'
  leftIcon?: InputIconName
  rightIcon?: InputIconName
  errorText?: string
  maxLength?: number
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  readOnly?: boolean
  className?: string
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  type = 'text',
  onChange,
  onFocus,
  onBlur,
  value,
  disabled,
  error,
  leftIcon,
  rightIcon,
  maxLength,
  errorText = 'Hubo un error',
  inputProps,
  readOnly,
  className
}) => {
  const [focused, setFocused] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const onFocused = () => {
    onFocus?.()
    if (disabled) return
    setFocused(true)
  }

  const onBlurFn = () => {
    onBlur?.()
    setFocused(false)
  }

  return (
    <>
      <div className={clsx(styles.textfieldContent, className)}>
        <div
          className={clsx(
            styles.container,
            focused && styles.isFocusContainer,
            error && styles.isErrorContainer,
            disabled && styles.isDisabledContainer
          )}
          onClick={() => ref.current?.focus()}>
          {leftIcon && <InputIcon name={leftIcon} />}
          <div className={styles.inputContent}>
            <label
              className={clsx(
                styles.label,
                focused && styles.isFocusLabel,
                value?.length > 0 && styles.unFocusedWithValue,
                error && styles.isErrorLabel,
                disabled && styles.isDisabledLabel
              )}>
              {label}
            </label>
            <input
              ref={ref}
              type={showPassword || type === 'text' ? 'text' : 'password'}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              disabled={disabled}
              onFocus={onFocused}
              onBlur={onBlurFn}
              autoComplete="off"
              maxLength={maxLength}
              readOnly={readOnly}
              {...inputProps}
            />
          </div>
          {type === 'password' && (
            <div onClick={() => setShowPassword(!showPassword)}>
              <InputIcon name={showPassword ? 'eye-pupil' : 'eye'} />
            </div>
          )}
          {rightIcon && type !== 'password' && <InputIcon name={rightIcon} />}
        </div>
        {errorText && error && <p className={styles.error}>{errorText}</p>}
      </div>
    </>
  )
}
