import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'
import { convertToMoment } from '../../../utils'
import { APIAgent, Agent } from './common'

type SearchParams = {
  email?: string
}

interface Response {
  agent: Agent[]
}

export function search(params: SearchParams): Promise<Response> {
  const { email } = params

  const snakeParams = snakecaseKeys({ email })

  return apiPrivate
    .get(`${URL_BASE_MERCURIO}/bo/agents/search`, { params: snakeParams })
    .then((response: AxiosResponse<APIAgent[]>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener el agente')

      const camelResponse: Response = {
        agent: camelcaseKeys(response.data, { deep: true }).map((agent) => ({
          id: agent.id,
          createdAt: convertToMoment(agent.createdAt),
          updatedAt: convertToMoment(agent.updatedAt),
          firstName: agent.firstName,
          lastName: agent.lastName,
          email: agent.email,
          userGuid: agent.userGuid,
          userId: agent.userId,
          roleId: agent.roleId,
          role: {
            roleId: agent.role.id,
            createdAt: convertToMoment(agent.role.createdAt),
            updatedAt: convertToMoment(agent.role.updatedAt),
            roleType: agent.role.name
          }
        }))
      }
      return camelResponse
    })
}
