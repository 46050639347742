import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ServiceDataRow from '../../../../common/operations/components/ServiceDataRow/ServiceDataRow'
import { SelectedItem } from '../../types/EditDepositViewTypes/items'
import itemsActionsCreators from '../../actions/EditDepositViewActionCreators/items'
import { useCountryInfo } from '../../../../components/CountrySelector/hooks/hooks'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { formatNumber } from '../../../../utils/formatNumber'

type ServiceDataRowContainerProps = {
  selectedItem: SelectedItem
  index: number
  serviceType: string
}

const ServiceDataRowContainer = (props: ServiceDataRowContainerProps) => {
  const { selectedItem, index, serviceType } = props
  const {
    items,
    selectedItem: selectedItemOption,
    quantity,
    floors,
    loading,
    packaging,
    disassemble,
    byProposal
  } = selectedItem

  const dispatch = useDispatch()
  const itemsActions = bindActionCreators(itemsActionsCreators, dispatch)
  const itemsState = useSelector((state) => state.Deposits.items)
  const transaction = useSelector((state) => state.Deposits.editView.transaction)
  const userCountry = useCountryInfo(transaction.generalData.countryId || CountryIdCode.ARGENTINA)

  const options = useMemo(() => {
    return items.map((item) => ({ value: item.guid, label: item.description }))
  }, [items])

  const selectedOption = useMemo(() => {
    return selectedItemOption
      ? {
          value: selectedItemOption.guid,
          label: selectedItemOption.description
        }
      : null
  }, [selectedItemOption])

  const onSelectItem = useCallback(
    (option) => itemsActions.setItem(option.value, selectedItem.items, index, itemsState.selectedItems),
    [index, itemsActions, itemsState.selectedItems, selectedItem.items]
  )

  const weightInKg = (selectedItemOption?.weightInGr || 0) / 1000

  const dimensions = formatNumber(((selectedItemOption?.volumeInCm3 || 0) / 1000000) * quantity)
  const weight = formatNumber(weightInKg * quantity)

  const packagingCost = (selectedItemOption?.packagingCostInCents || 0) / 100
  const disassemblyCost = selectedItemOption?.disassemblyCost || 0
  const floorCost = (selectedItemOption?.depositFloorsByStairsCostInCents || 0) / 100

  const getDimensionTooltip = () => (
    <span>
      {formatNumber(selectedItemOption?.heightInCm)} cm x {formatNumber(selectedItemOption?.widthInCm)} cm x{' '}
      {formatNumber(selectedItemOption?.lengthInCm)} cm <br /> {formatNumber(weightInKg)} kg
    </span>
  )

  const onInputChange = (text: string) => {
    if (text.length < 2) {
      if (text.length > 0) {
        itemsActions.setInitialItems(index)
        itemsActions.cleanItem(index)
      }
    } else {
      itemsActions.getItemsPrice(text, index, userCountry.code, serviceType)
    }
  }

  return (
    <ServiceDataRow
      index={index}
      country={userCountry}
      loadingOptions={loading}
      disabled={byProposal}
      options={options}
      selectedOption={selectedOption}
      onDeleteItem={itemsActions.removeItem}
      onSelectItem={onSelectItem}
      itemQuantity={quantity}
      setItemQuantity={itemsActions.setItemQuantity}
      tooltipDimensions={getDimensionTooltip()}
      dimensions={dimensions}
      weight={weight}
      withPackagingCost={packaging}
      packagingCost={packagingCost}
      setItemPackaging={itemsActions.setItemPackaging}
      disassemblyCost={disassemblyCost}
      withDisassemblyCost={disassemble}
      setItemDisassembly={itemsActions.setDisassemble}
      floors={floors}
      floorCost={floorCost}
      setItemFloors={itemsActions.setItemFloors}
      onInputChange={onInputChange}
    />
  )
}

export default ServiceDataRowContainer
