import React, { useEffect } from 'react'
import Component from '../components/removeLogical'
import { bindActionCreators } from 'redux'
import modalDeleteActions from '../actions/removeObject'
import { connect } from 'react-redux'

const Container = (props) => {
  useEffect(() => {
    props.getObjectsDeletedReasons()
  }, [])

  return <Component {...props} />
}

const mapStateToProps = (state) => {
  const { Objects } = state
  return {
    ...Objects
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(modalDeleteActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Container)
