import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'
import { Complaint } from '../../../projectApi/Complaints/list'
import { CategoryFilter, DateRangeFilter, Pagination, SearchFilter } from '../../../utils/searchFilterUtils'
import { CommonComplaintsActions } from './common'
import { Metrics } from '@/projectApi/Complaints/metrics'

export type SearchableParams = {
  id?: number
  userId?: number
  operationId?: number
  assignedUserId?: number
  clientId?: number
}

export type ComplaintsCategoryFilterKey = 'status' | 'complaintType' | 'priority' | 'reasonId' | 'closeReason'
export type ComplaintsCategoryFilter = CategoryFilter<ComplaintsCategoryFilterKey, string>

export type ComplaintsTableSearchKey = keyof SearchableParams
export type ComplaintsSearchFilter = SearchFilter<ComplaintsTableSearchKey>

export type ComplaintsDateRangeFilter = DateRangeFilter<ComplaintsTableDateRangeKey>
export enum ComplaintsTableDateRangeKey {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CLOSED_AT = 'closedAt'
}

export type Filters = {
  id?: number
}

export type ComplaintsListStateType = {
  isLoading: boolean
  complaints: Complaint[]
  reasons: ComplaintsReasons[]
  pagination: Pagination
  searchFilters: ComplaintsSearchFilter[]
  dateRangeFilters: ComplaintsDateRangeFilter[]
  categoryFilter: ComplaintsCategoryFilter
  filters: Filters
  metircs: Metrics
  loadingMetrics: boolean
  errors?: string
}

export enum GetComplaintsListTypes {
  GET_COMPLAINTS_REQUEST = 'COMPLAINTS/GET_COMPLAINTS_REQUEST',
  GET_COMPLAINTS_SUCCESS = 'COMPLAINTS/GET_COMPLAINTS_SUCCESS',
  GET_COMPLAINTS_FAILURE = 'COMPLAINTS/GET_COMPLAINTS_FAILURE'
}

export interface GetComplaintsListRequest {
  type: GetComplaintsListTypes.GET_COMPLAINTS_REQUEST
  payload: {
    pagination: Pagination
    searchFilters?: ComplaintsSearchFilter[]
    dateRangeFilters?: ComplaintsDateRangeFilter[]
    categoryFilter?: ComplaintsCategoryFilter
  }
}

export interface GetComplaintsListSuccess {
  type: GetComplaintsListTypes.GET_COMPLAINTS_SUCCESS
  payload: {
    complaints: Complaint[]
    total: number
  }
}

export interface GetComplaintsListFailure {
  type: GetComplaintsListTypes.GET_COMPLAINTS_FAILURE
  payload: {
    error: string
  }
}

type GetComplaintsListActions = GetComplaintsListRequest | GetComplaintsListSuccess | GetComplaintsListFailure

export enum SetterComplaintsTypes {
  SET_COMPLAINT_FILTERS = 'COMPLAINTS/SET_COMPLAINT_FILTERS'
}

export interface SetComplaintsFilters {
  type: SetterComplaintsTypes.SET_COMPLAINT_FILTERS
  payload: {
    id?: number
  }
}

export const RESET_FILTERS = 'COMPLAINTS/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export enum GetComplaintsMetricsTypes {
  GET_COMPLAINTS_METRICS_REQUEST = 'COMPLAINTS/GET_COMPLAINTS_METRICS_REQUEST',
  GET_COMPLAINTS_METRICS_SUCCESS = 'COMPLAINTS/GET_COMPLAINTS_METRICS_SUCCESS',
  GET_COMPLAINTS_METRICS_FAILURE = 'COMPLAINTS/GET_COMPLAINTS_METRICS_FAILURE'
}

export interface GetComplaintsMetricsRequest {
  type: typeof GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_REQUEST
}

export interface GetComplaintsMetricsSuccess {
  type: typeof GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_SUCCESS
  payload: {
    metrics: Metrics
  }
}

export interface GetComplaintsMetricsFailure {
  type: typeof GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_FAILURE
  payload: {
    error: string
  }
}

export type ComplaintsListActions =
  | GetComplaintsListActions
  | SetComplaintsFilters
  | ResetFilters
  | CommonComplaintsActions
  | GetComplaintsMetricsRequest
  | GetComplaintsMetricsSuccess
  | GetComplaintsMetricsFailure
