import { Reducer } from 'react'
import {
  CLOSE_DEFAULT_ADDRESS_MODAL,
  DEFAULT_ADDRESS_FAILURE,
  DEFAULT_ADDRESS_REQUEST,
  DEFAULT_ADDRESS_SUCCESS,
  DefaultAddressActions,
  DefaultAddressState,
  OPEN_DEFAULT_ADDRESS_MODAL
} from '../types/defaultAddress'

const initialState: DefaultAddressState = {
  userId: 0,
  addressId: 0,
  addressText: '',
  defaultAddressModalOpen: false,
  loading: false,
  error: ''
}

const DefaultAddressReducer: Reducer<DefaultAddressState, DefaultAddressActions> = (
  state = initialState,
  action
): DefaultAddressState => {
  switch (action.type) {
    case DEFAULT_ADDRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: ''
      }
    }
    case DEFAULT_ADDRESS_SUCCESS: {
      return initialState
    }
    case DEFAULT_ADDRESS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }
    case OPEN_DEFAULT_ADDRESS_MODAL: {
      return {
        ...state,
        userId: action.payload.userId,
        addressId: action.payload.addressId,
        addressText: action.payload.addressText,
        defaultAddressModalOpen: true,
        error: ''
      }
    }
    case CLOSE_DEFAULT_ADDRESS_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default DefaultAddressReducer
