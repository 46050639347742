import React, { useEffect } from 'react'
import ShippersAM, { AMShippersProps } from '../components/ShippersAM'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import shippersABMActionCreators from '../actions/shippersABM'
import ShipperDeleteModal, { DeleteShipperProps } from '../components/DeleteShipper'

const ShippersABMContainer = () => {
  const {
    modalVisible,
    deleteModalVisible,
    editMode,
    currentShipper,
    selectedDriver,
    driverList,
    fetchingDrivers,
    selectedProvider,
    providerList,
    fetchingProviders,
    creatingShipper,
    updatingShipper,
    deletingShipper,
    disabledDeleteButton
  } = useSelector((state) => state.ShippersCombinerReducer.shippersABM)

  const dispatch = useDispatch()
  const {
    setModalVisibility,
    setDeleteModalVisibility,
    setDriver,
    setProvider,
    getDrivers,
    getProviders,
    createShipper,
    updateShipper,
    deleteShipper
  } = bindActionCreators(shippersABMActionCreators, dispatch)

  useEffect(() => {
    if (modalVisible) {
      getDrivers({})
      getProviders({})
    }
  }, [modalVisible])

  const hanldeOkButton = () => {
    if (!selectedDriver || !selectedProvider) return

    const create = () => createShipper({ driverId: selectedDriver.userId, providerId: selectedProvider.userId })
    const update = () => updateShipper({ shipperId: currentShipper?.id ?? 0, providerId: selectedProvider.userId })

    editMode ? update() : create()
  }

  const handleCancelButton = () => setModalVisibility({ modalVisible: false })

  const disabledSuccessButton = !selectedDriver || !selectedProvider || creatingShipper || updatingShipper

  const handleDeleteShipper = () => {
    if (!currentShipper) return
    deleteShipper({ shipperId: currentShipper.id })
  }

  const handleCancelDeleteButton = () => setDeleteModalVisibility({ deleteModalVisible: false })

  const AMShippersProps: AMShippersProps = {
    modalVisible,
    editMode,
    disabledSuccessButton,
    drivers: {
      list: driverList,
      selected: selectedDriver,
      fetching: fetchingDrivers,
      onSearch: getDrivers
    },
    providers: {
      list: providerList,
      selected: selectedProvider,
      fetching: fetchingProviders,
      onSearch: getProviders
    },
    handleCancelButton,
    setSelectedDriver: setDriver,
    setSelectedProvider: setProvider,
    hanldeOkButton
  }

  const DeleteShipperProps: DeleteShipperProps = {
    deleteModalVisible,
    handleDeleteShipper,
    handleCancelDeleteButton,
    deletingShipper,
    currentShipper,
    disabledDeleteButton: disabledDeleteButton || deletingShipper
  }

  return (
    <>
      <ShippersAM {...AMShippersProps} />
      <ShipperDeleteModal {...DeleteShipperProps} />
    </>
  )
}

export default ShippersABMContainer
