import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

export interface CorporateObjectReportParams {
  countryId: string
  objectIds?: number[]
  depositId?: number
  productId?: number
}

export function corporateReport(body: CorporateObjectReportParams): Promise<void> {
  const formattedBody = {
    ...snakecaseKeys({ ...body }, { deep: true }),
    ...{ object_ids: body.objectIds && body.objectIds.join(',') }
  }

  return apiPrivate
    .post(`${URL_BASE_OBJECTS}/objects/report-corporate`, formattedBody)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
