import React, { useEffect } from 'react'
import DepositsTable from '../components/depositsTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import DepositListActionsCreator from '../actions/depositList'
import { DepositsSearchFilter, DepositsTableSearchKey } from '../types/depositList'
import { Pagination } from '../../../utils/searchFilterUtils'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../components/TableActionBar'
import ABMDepositsActionsCreator from '../actions/ABMDeposits'
import ABMDepositsContainer from './ABMDeposits'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'
import { Edit, Trash } from '../../../icons'

const DepositsTableContainer = () => {
  const dispatch = useDispatch()

  const { getDeposits, resetFilters } = bindActionCreators(DepositListActionsCreator, dispatch)
  const { setModalVisibility, setDeleteModalVisibility, setSelectedDeposit } = bindActionCreators(
    ABMDepositsActionsCreator,
    dispatch
  )

  const { deposits, fetchingDeposits, ...tableState } = useSelector((state) => state.StorageDepositListReducer)

  useEffect(() => {
    getDeposits({ pagination: tableState.pagination })
  }, [])

  useEvents(
    [
      Events.Global.CHANGE_COUNTRY,
      Events.StorageDeposits.CLEAN_FILTERS,
      Events.StorageDeposits.CREATE_DEPOSIT,
      Events.StorageDeposits.EDIT_DEPOSIT,
      Events.StorageDeposits.DELETE_DEPOSIT
    ],
    () => getDeposits({ pagination: { ...tableState.pagination, page: 1 } })
  )

  const handleGetDeposits = (newParams: { pagination?: Pagination; searchFilters?: DepositsSearchFilter[] }) => {
    getDeposits({
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    })
  }

  const handlePageChange = (newPage: number) => {
    getDeposits({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: DepositsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetDeposits({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const menuActions: MenuAction<Deposit>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (deposit) => {
        setModalVisibility({ modalVisible: true, editMode: true })
        setSelectedDeposit(deposit)
      }
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (deposit) => {
        setDeleteModalVisibility(true)
        setSelectedDeposit(deposit)
      }
    }
  ]

  const props = {
    fetchingDeposits,
    deposits,
    searchFilters: tableState.searchFilters,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    actions: menuActions
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={resetFilters} />
        <TableActionBar.ButtonAction
          titleButton={'Crear depósito'}
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar>
      <DepositsTable {...props} />
      <ABMDepositsContainer />
    </>
  )
}

export default DepositsTableContainer
