import { AxiosResponse } from 'axios'
import { URL_BASE_USERS } from '../../../endpoints'
import { apiPrivate } from '../../../api'

export type Permission = {
  id: number
  description: string
}

export enum Role {
  ADMINISTRADOR = 1,
  ROOT = 9,
  CORPORATIVE = 18,
  CORPORATIVE_ADMIN = 19
}

export interface Response {
  id: Role
  name: string
  permissions: Permission[]
}

type GetByParentIdParams = {
  parentId?: number
}

export function getByParentId({ parentId }: GetByParentIdParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/users/role`, { headers: { ...(parentId && { 'parent-id': parentId }) } })
    .then((response: AxiosResponse<Response>) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
