import React, { TextareaHTMLAttributes } from 'react'
import clsx from 'clsx'
import styles from './TextArea.module.scss'

interface TextAreaInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLength?: number
  value: string
  className?: string
  debounceTime?: number
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({ maxLength = 300, value, onChange, className, ...props }) => {
  return (
    <div className={styles.textAreaWrapper}>
      <textarea
        className={clsx(styles.textArea, className)}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        spellCheck="false"
        {...props}
      />
      <div className={styles.charCount}>
        {value.length}/{maxLength}
      </div>
    </div>
  )
}

export default TextAreaInput
