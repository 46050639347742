export const OPEN_DELETE_ORIGIN_MODAL = 'ORIGINS/EDIT/OPEN_DELETE_ORIGIN_MODAL'
export const CLOSE_DELETE_ORIGIN_MODAL = 'ORIGINS/EDIT/CLOSE_DELETE_ORIGIN_MODAL'
export const CANCEL_DELETE_ORIGIN_MODAL = 'ORIGINS/EDIT/CANCEL_DELETE_ORIGIN_MODAL'

export const SET_ERROR = 'ORIGINS/EDTI/SET_ERROR'

export const DELETE_ORIGIN_REQUEST = 'ORIGINS/DELETE/DELETE_ORIGIN_REQUEST'
export const DELETE_ORIGIN_SUCCESS = 'ORIGINS/DELETE/DELETE_ORIGIN_SUCCESS'
export const DELETE_ORIGIN_FAILURE = 'ORIGINS/DELETE/DELETE_ORIGIN_FAILURE'

export interface OpenDeleteOriginModal {
  type: typeof OPEN_DELETE_ORIGIN_MODAL
  payload: {
    id: number
    sirenaSource: string
    channel: string
  }
}
export interface CloseDeleteOriginModal {
  type: typeof CLOSE_DELETE_ORIGIN_MODAL
}

export interface CancelDeleteOriginModal {
  type: typeof CANCEL_DELETE_ORIGIN_MODAL
}

export interface DeleteOriginState {
  open: boolean
  id: number
  sirenaSource: string
  channel: number
  isLoading: boolean
  error: string
}

export interface DeleteOriginRequest {
  type: typeof DELETE_ORIGIN_REQUEST
}

export interface DeleteOriginSuccess {
  type: typeof DELETE_ORIGIN_SUCCESS
}

export interface DeleteOriginFailure {
  type: typeof DELETE_ORIGIN_FAILURE
  payload: {
    error: string
  }
}
export type DeleteOriginAction = DeleteOriginRequest | DeleteOriginSuccess | DeleteOriginFailure
