import * as React from 'react'

const BagIcon = () => (
  <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y={0.5} width={24} height={24} rx={12} fill="#F7F7FF" />
    <path
      d="M8 5.833 6 8.5v9.333a1.333 1.333 0 0 0 1.333 1.334h9.334A1.333 1.333 0 0 0 18 17.833V8.5l-2-2.667H8ZM6 8.5h12"
      stroke="#5D58F7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.667 11.167a2.667 2.667 0 0 1-5.334 0" stroke="#5D58F7" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default BagIcon
