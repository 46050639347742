import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'
import { convertToMoment } from '../../utils'
import { convertPrice } from './common'
import { APISalesRequest, SalesRequest } from './list'
import camelcaseKeys from 'camelcase-keys'

type SearchParams = {
  id?: number
}

type APIResponse = APISalesRequest

type Response = SalesRequest

export function search(params: SearchParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_SALES}/sales-requests/${params.id}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('No se pudo obtener la solicitud')
      const salesRequest = camelcaseKeys(response.data, { deep: true })
      const camelResponse: Response = {
        createdAt: convertToMoment(salesRequest.createdAt),
        id: salesRequest.id,
        object: {
          description: salesRequest.object.description,
          heightInCm: salesRequest.object.heightInCm,
          id: salesRequest.object.id,
          identificationCode: salesRequest.object.identificationCode.description,
          lengthInCm: salesRequest.object.lengthInCm,
          objectSituation: salesRequest.object.objectSituation,
          photoUrl: salesRequest.object.photo.url,
          product: {
            name: salesRequest.object.product.name,
            multiplier: salesRequest.object.product.multiplier
          },
          status: salesRequest.object.status,
          weightInGr: salesRequest.object.weightInGr,
          widthInCm: salesRequest.object.widthInCm
        },
        expressSale: salesRequest.expressSale
          ? {
              pve: convertPrice(salesRequest.expressSale.pve),
              saleCost: convertPrice(salesRequest.expressSale.saleCost),
              saleMargin: convertPrice(salesRequest.expressSale.saleMargin)
            }
          : undefined,
        prc: convertPrice(salesRequest.prc),
        pve: convertPrice(salesRequest.expressSale?.pve || 0),
        pvp: convertPrice(salesRequest.pvp),
        saleCost: convertPrice(salesRequest.saleCost),
        saleMargin: convertPrice(salesRequest.saleMargin),
        status: salesRequest.status.name,
        updatedAt: convertToMoment(salesRequest.updatedAt),
        user: {
          id: salesRequest.user.id,
          name: salesRequest.user.name,
          lastName: salesRequest.user.lastName,
          email: salesRequest.user.email
        }
      }
      return camelResponse
    })
}
