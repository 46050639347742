import { CamelCaseOperation } from '../../../projectApi/TransactionHandler/Operation/common'
import { DepositStatus } from '../../../projectApi/TransactionHandler/Operation/list'
import { CategoryFilter, DateRangeFilter, SearchFilter } from '../../../utils/searchFilterUtils'

export type DepositsValidationSearchKey = 'id' | 'userId' | 'user_name' | 'user_last_name'

export type DepositsValidationTableSearchFilter = SearchFilter<DepositsValidationSearchKey>

export type DepositsValidationDateRangeKey = 'datetime' | 'createdAt'

export type DepositsValidationDateRangeFilter = DateRangeFilter<DepositsValidationDateRangeKey>

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export enum DepositValidationTableFilterKey {
  STATUS = 'status'
}
export type DepositsValidationTableCategoryFilter = CategoryFilter<DepositValidationTableFilterKey, DepositStatus>

export interface DepositsValidationTableState {
  deposits: CamelCaseOperation[]
  categoryFilter: {
    status: DepositStatus[]
  }
  searchFilters: DepositsValidationTableSearchFilter[]
  pagination: Pagination
  dateRangeFilters: DepositsValidationDateRangeFilter[]
  loading: boolean
  error: string
}

export const RESET_ALL_FILTERS = 'DEPOSITS_VALIDATION/TABLE/SET_DATE_RANGE_FILTERS'
export interface ResetAllFilters {
  type: typeof RESET_ALL_FILTERS
}

export const GET_DEPOSITS_REQUEST = 'DEPOSITS_VALIDATION/TABLE/GET_DEPOSITS_REQUEST'
export const GET_DEPOSITS_SUCCESS = 'DEPOSITS_VALIDATION/TABLE/GET_DEPOSITS_SUCCESS'
export const GET_DEPOSITS_FAILURE = 'DEPOSITS_VALIDATION/TABLE/GET_DEPOSITS_FAILURE'

export interface GetDepositsRequest {
  type: typeof GET_DEPOSITS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    categoryFilter?: DepositsValidationTableCategoryFilter
    dateRangeFilters?: DepositsValidationDateRangeFilter[]
    searchFilters?: DepositsValidationTableSearchFilter[]
  }
}

export interface GetDepositsSuccess {
  type: typeof GET_DEPOSITS_SUCCESS
  payload: {
    deposits: CamelCaseOperation[]
    total: number
  }
}

export interface GetDepositsFailure {
  type: typeof GET_DEPOSITS_FAILURE
  payload: {
    error: string
  }
}

export type GetDepositsAction = GetDepositsRequest | GetDepositsSuccess | GetDepositsFailure

export type DepositsValidationTableAction = GetDepositsAction | ResetAllFilters
