import React, { useState } from 'react'
import Dot from './icons/Dot'
import DotDone from './icons/DotDone'
import DotActive from './icons/DotActive'
import styles from './ViewStep.module.scss'
import { Button } from '../../../../components/Button/Button'
import clsx from 'clsx'
import RowBackIcon from './icons/RowBack'
import { useHistory } from 'react-router'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'

export const enum Step {
  GENERAL_DATA = 1,
  COST = 2
}

export type StepsProps = {
  value: Step
  component: React.ReactNode
  label: string
}

export const ViewSteps = ({
  steps,
  validateAndHandleCreate,
  firstStepButtonDisabled,
  secondStepButtonDisabled,
  isEditMode,
  productCostCleanState,
  generalDataCleanState,
  handleUpdateProduct,
  loadingSaveProduct,
  isFromCatalog,
  handleCreateNewByCatalogProduct
}: {
  steps: StepsProps[]
  validateAndHandleCreate: () => void
  productCostCleanState: () => void
  generalDataCleanState: () => void
  handleUpdateProduct: () => void
  firstStepButtonDisabled?: boolean
  secondStepButtonDisabled?: boolean
  isEditMode: boolean
  loadingSaveProduct: boolean
  isFromCatalog: boolean
  handleCreateNewByCatalogProduct: () => void
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const route = useHistory()

  const isLastStep = currentStep === steps.length

  const onProductCreated = () => {
    validateAndHandleCreate()
  }

  const onUpdateProduct = () => {
    handleUpdateProduct()
  }

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1)
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    })
  }

  const onCreateByCatalog = () => {
    handleCreateNewByCatalogProduct()
  }

  const handleSavedProduct = () => {
    route.push('/products')
    productCostCleanState()
    generalDataCleanState()
    setCurrentStep(1)
  }

  const handleBackOnClick = () => {
    if (currentStep > 1) {
      return setCurrentStep(currentStep - 1)
    }
    route.goBack()
  }

  const handleClick = () => {
    if (!isLastStep) {
      return handleNextStep()
    }

    if (isEditMode) {
      return onUpdateProduct()
    }

    if (isFromCatalog) {
      return onCreateByCatalog()
    }

    return onProductCreated()
  }

  useEvents(
    [
      Events.NewProduct.PRODUCT_CREATED,
      Events.NewProduct.PRODUCT_UPDATED,
      Events.NewProduct.PRODUCT_CREATED_BY_CATALOG
    ],
    handleSavedProduct
  )

  return (
    <div>
      <button className={styles.backButton} onClick={handleBackOnClick}>
        <RowBackIcon />
      </button>
      <div className={styles.container}>
        {steps.map((x) => (
          <React.Fragment key={x.value}>
            <div style={{ position: 'relative' }}>
              {x.value === currentStep && <DotActive key={x.value} />}
              {x.value > currentStep && <Dot key={x.value} />}
              {x.value < currentStep && <DotDone key={x.value} />}
              {x.value && (
                <p className={clsx(styles.title, x.value === currentStep && styles.titleActive)}>{x.label}</p>
              )}
            </div>
            {x.value < steps.length && (
              <div className={clsx(styles.line, currentStep > x.value && styles.lineActive)} key={x.label}></div>
            )}
          </React.Fragment>
        ))}
      </div>

      {steps && steps[currentStep - 1].component}

      <Button
        label={isLastStep ? (isEditMode ? 'Editar producto' : 'Crear producto') : 'Siguiente'}
        className={styles.button}
        onClick={handleClick}
        loading={loadingSaveProduct}
        disabled={currentStep === 1 ? firstStepButtonDisabled : secondStepButtonDisabled}
      />
    </div>
  )
}
