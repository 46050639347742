import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import camelcaseKeys from 'camelcase-keys'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'

type APIRemovalReason = {
  id: number
  description: string
  available: boolean
}

export type RemovalReason = CamelCasedPropertiesDeep<APIRemovalReason>

type APIResponse = {
  removals_reasons: RemovalReason[]
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export function reasons(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operations/removal-reasons`)
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse = camelcaseKeys(response.data, { deep: true })

      return camelResponse
    })
    .catch((error) => {
      throw error
    })
}
