import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export function sendEmailBilled(billingId: number): Promise<void> {
  const data = {
    billing_id: billingId
  }

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/authorization-afip/email`, data)
    .then((res) => {})
    .catch((err) => {
      throw err
    })
}
