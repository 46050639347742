import * as React from 'react'

const CubeLocation = () => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.437.113 9.9.975a4.813 4.813 0 0 1 6.712 1.163 4.833 4.833 0 0 1-.524 6.187l-.076.075v5.1l-.187.113-3.675 2.137-3.713 2.137L8.25 18l-.188-.113L4.35 15.75.637 13.613.45 13.5v-9l.187-.112L4.35 2.25 8.062.112 8.25 0l.187.113Zm5.813 3.262c.825.825.825 2.213 0 3.037a2.14 2.14 0 0 1-3.037.075 2.14 2.14 0 0 1-.075-3.037l.075-.075c.825-.825 2.175-.825 3.037 0Zm-.113 1.5c0-.375-.15-.75-.412-1.013a1.381 1.381 0 0 0-1.987 0 1.381 1.381 0 0 0 0 1.988 1.381 1.381 0 0 0 1.987 0c.263-.225.412-.6.412-.975ZM9.3 1.462l-1.05-.6-3.525 2.026-3.15 1.837L8.25 8.588l.825-.488C7.387 6.188 7.462 3.263 9.3 1.463Zm.337 7.163-1.012.563v7.725l3.15-1.838L15.3 13.05V9.112l-2.325 2.325-.262.263-.263-.262-2.813-2.813Zm7.163-3.75A4.097 4.097 0 0 0 12.712.787c-2.25 0-4.087 1.8-4.087 4.088 0 1.088.45 2.138 1.2 2.925l2.888 2.888L15.6 7.8a4.113 4.113 0 0 0 1.2-2.925ZM4.162 11.813v2.962h-.037l.6.338 3.15 1.837V9.225L1.2 5.363v7.724l.75.413v-2.438h.75v2.888l.75.413v-2.55h.712Z"
      fill="#263238"
    />
  </svg>
)

export default CubeLocation
