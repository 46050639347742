import React, { useEffect } from 'react'
import { Button, Input } from 'antd'
import InputGroupAddon from '../../../components/InputGroupAddon/InputGroupAddon'
import InputGroup from '../../../components/InputGroup'
import loginActions from '../actions/login'
import { LoginState } from '../reducers/login'
import AccountSelector from '../container/accountSelector'
import styles from './login.module.scss'
import { useHistory } from 'react-router'

type LoginProps = {
  loginActions: typeof loginActions
  loginReducer: LoginState
}

const Login = (props: LoginProps) => {
  const { loginActions, loginReducer } = props
  const { setUsername, setPassword, login } = loginActions
  const { username, password, loading, error, loginSuccess } = loginReducer
  const history = useHistory()

  const onLogin = async () => {
    await login({
      email: username.trim(),
      password
    })
  }

  const onPressEnter = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onLogin()
    }
  }

  const goToDashboard = () => {
    history.push('/dashboard')
  }

  useEffect(() => {
    const token = localStorage.getItem('token') || ''
    if (token.length >= 10) {
      goToDashboard()
    }
  }, [])

  const onButtonClick = () => {
    if (loginSuccess) {
      goToDashboard()
    } else {
      onLogin()
    }
  }

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <div className={styles.cardLogin}>
          <div className={styles.col}>
            {loginSuccess ? (
              <>
                <h1>Cuenta</h1>
                <p className="text-muted">Seleccione la cuenta con la que quiere ingresar</p>
                <InputGroup className="mb-4" style={{ border: 'none', display: 'flex' }}>
                  <InputGroupAddon>
                    <i className="icon-user"></i>
                  </InputGroupAddon>
                  <AccountSelector />
                </InputGroup>
              </>
            ) : (
              <>
                <h1>Ingreso</h1>
                <p className="text-muted">¡Ingresá a tu cuenta!</p>
                <InputGroup className="mb-3" style={{ border: 'none', display: 'flex' }}>
                  <InputGroupAddon>
                    <i className="icon-user"></i>
                  </InputGroupAddon>
                  <Input
                    className={styles.antInput}
                    disabled={loading || loginSuccess}
                    type="text"
                    name="input-login-user"
                    placeholder="Nombre de usuario"
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={onPressEnter}
                  />
                </InputGroup>
                <InputGroup className="mb-4" style={{ border: 'none', display: 'flex' }}>
                  <InputGroupAddon>
                    <i className="icon-lock"></i>
                  </InputGroupAddon>
                  <Input
                    className={styles.antInput}
                    disabled={loading || loginSuccess}
                    type="password"
                    name="input-login-address"
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={onPressEnter}
                  />
                </InputGroup>
              </>
            )}
            <p className={styles.error} id="msg-invalid-credentials">
              {error}
            </p>
          </div>
          <div>
            <Button
              loading={loading}
              type="primary"
              className={styles.loginBtn}
              onClick={onButtonClick}
              disabled={!password || !username}>
              Ingresar
            </Button>
          </div>
        </div>
        <div className={styles.cardLogo} style={{ backgroundColor: '#5D58F7' }}>
          <div>
            <img src={'img/logo-white.png'} className="img-logo" alt="spaceguru" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
