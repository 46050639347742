import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { KeyType, Pagination, dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  GET_OPERATORS_FAILURE,
  GET_OPERATORS_REQUEST,
  GET_OPERATORS_SUCCESS,
  GET_PICKINGS_FAILURE,
  GET_PICKINGS_REQUEST,
  GET_PICKINGS_SUCCESS,
  GetOperatorsRequest,
  GetOperatorsSuccess,
  GetPickingsRequest,
  GetPickingsSuccess,
  PickingsDateRangeFilter,
  PickingsSearchFilter,
  PickingsTableCategoryFilter,
  PickingsTableSort
} from '../types/pickingsTable'

const PickingsTableActionCreators = {
  getOperators: (): AppThunk => async (dispatch) => {
    const request: GetOperatorsRequest = {
      type: GET_OPERATORS_REQUEST
    }

    dispatch(request)

    try {
      const { operators } = await API.TransactionHandler.Pickupers.list({ limit: 200 })

      const success: GetOperatorsSuccess = {
        type: GET_OPERATORS_SUCCESS,
        payload: {
          operators
        }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GET_OPERATORS_FAILURE, dispatch)
    }
  },
  getPickings:
    ({
      pagination,
      searchFilters,
      dateRangeFilters,
      sort,
      categoryFilter,
      silentLoading
    }: {
      pagination: Pagination
      searchFilters?: PickingsSearchFilter[]
      categoryFilter?: PickingsTableCategoryFilter
      dateRangeFilters?: PickingsDateRangeFilter[]
      sort?: PickingsTableSort
      silentLoading?: boolean
    }): AppThunk =>
    async (dispatch) => {
      const request: GetPickingsRequest = {
        type: GET_PICKINGS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          dateRangeFilters,
          searchFilters,
          categoryFilter,
          sort,
          silentLoading
        }
      }

      dispatch(request)

      const formattedDateFilters = dateRangeFiltersToParams(dateRangeFilters, KeyType.SNAKE)

      try {
        const { pickings, total } = await API.TransactionHandler.Picking.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          sort: sort?.field,
          order: sort?.direction,
          status: categoryFilter?.status[0],
          agent_id: categoryFilter?.agent_id[0],
          ...searchFiltersToParams(searchFilters),
          ...formattedDateFilters
        })

        const success: GetPickingsSuccess = {
          type: GET_PICKINGS_SUCCESS,
          payload: {
            pickings,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_PICKINGS_FAILURE, dispatch)
      }
    }
}

export default PickingsTableActionCreators
