import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { parseMomentDate } from '../../../components/DynamicTable/utils/utils'
import { Agent } from '../../../projectApi/Mercurio/BO/Agents/common'
import AgentsModalContainer from '../container/agentModal'
import { ROLE_TYPE } from '../types/agentsTable'
import { TableActionBar } from '../../../components/TableActionBar'

export type AgentsTableProps = {
  agents: Agent[]
  loadingAgents: boolean
  openModal: () => void
  pagination: Pagination
}

const AgentsTable: React.FC<AgentsTableProps> = ({ openModal, agents, loadingAgents, pagination }) => {
  const handleRoleType = (roleId: number) => ROLE_TYPE[roleId]

  const column: Column<Agent>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'userId',
      label: 'ID Usuario'
    },
    {
      key: 'firstName',
      label: 'Nombre'
    },
    {
      key: 'lastName',
      label: 'Apellido'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'role.roleType',
      label: 'Rol',
      renderDataCell: (row) => handleRoleType(row.role.roleId)
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      renderDataCell: ({ createdAt }) => parseMomentDate(createdAt)
    }
  ]

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.Spacer />
        <TableActionBar.ButtonAction titleButton={'Crear Agente'} onClickButton={() => openModal()} />
      </TableActionBar>
      <DynamicTable
        columns={column}
        rows={agents}
        keyExtractor={(entity) => entity.id}
        loading={loadingAgents}
        pagination={pagination}
      />
      <AgentsModalContainer />
    </>
  )
}

export default AgentsTable
