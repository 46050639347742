import { ApolloClient, ApolloError, Observable, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { URL_BASE_BFF } from '../../endpoints'
import { apolloCache } from './typePolicies'

const httpLink = createHttpLink({
  uri: URL_BASE_BFF
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
      'x-origin': 'backoffice/adm-web'
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { response } = operation.getContext()
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      // show some popup or any global related handling to your errors
    })
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)

    return new Observable((obs) => {
      obs.error(
        new ApolloError({
          errorMessage: networkError.message,
          graphQLErrors,
          networkError,
          extraInfo: {
            statusResponse: response.status
          }
        })
      )
    })
  }
})

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  connectToDevTools: true,
  cache: apolloCache
})
