import { Moment } from 'moment'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import {
  GET_UNAVAILABLE_DATES_FAILURE,
  GET_UNAVAILABLE_DATES_REQUEST,
  GET_UNAVAILABLE_DATES_SUCCESS,
  GetUnavailableDatesFailure,
  GetUnavailableDatesRequest,
  GetUnavailableDatesSuccess,
  SET_AVAILABLE_DATE,
  SET_SELECTED_REMOVAL_DATE,
  SET_TIME_INTERVAL,
  SET_TIME_INTERVAL_LIST,
  SetAvailableDate,
  SetSelectedRemovalDate,
  SetTimeInterval,
  SetTimeIntervalList,
  TimeInterval
} from '../types/calendar'
import { CalendarDay } from '@/components/Calendar/calendar.types'

const CalendarActionCreators = {
  getUnavailableDates: (): AppThunk => async (dispatch) => {
    const request: GetUnavailableDatesRequest = {
      type: GET_UNAVAILABLE_DATES_REQUEST
    }

    dispatch(request)

    try {
      const DAYS_ONWARDS = 90

      const { unavailableDates } = await API.Timeslot.timeslot.dates({ daysOnwards: DAYS_ONWARDS })
      if (!unavailableDates) throw new Error('Error al obtener las fechas no disponibles')

      const success: GetUnavailableDatesSuccess = {
        type: GET_UNAVAILABLE_DATES_SUCCESS,
        payload: { unavailableDates }
      }

      dispatch(success)
    } catch (error) {
      const failure: GetUnavailableDatesFailure = {
        type: GET_UNAVAILABLE_DATES_FAILURE
      }

      dispatch(failure)
    }
  },
  setAvailableDate:
    (availableDate: Moment): AppThunk =>
    (dispatch) => {
      const setter: SetAvailableDate = {
        type: SET_AVAILABLE_DATE,
        payload: { availableDate }
      }

      dispatch(setter)
    },
  setTimeInterval: (timeInterval?: TimeInterval): SetTimeInterval => {
    return {
      type: SET_TIME_INTERVAL,
      payload: { timeInterval }
    }
  },
  setTimeIntervalList: (timeIntervalList: TimeInterval[]): SetTimeIntervalList => ({
    type: SET_TIME_INTERVAL_LIST,
    payload: { timeIntervalList }
  }),
  setSelectedCalendarDate: (selectedRemovalDate: CalendarDay | null): SetSelectedRemovalDate => ({
    type: SET_SELECTED_REMOVAL_DATE,
    payload: { selectedRemovalDate }
  })
}

export default CalendarActionCreators
