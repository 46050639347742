import { Button, Modal } from 'antd'
import React, { useMemo } from 'react'
import { Operator } from '../../projectApi/TransactionHandler/Pickupers/list'
import { Transaction } from '../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import Select from '../../components/Select/Select'
import { Column } from '../../components/DynamicTable/types/types'
import { TransactionTypeId } from '../../projectApi/TransactionHandler/Transaction/common'
import { formatDate } from '../../utils/formatDate'
import { TransactionStatusBadges } from '../Trips/constants'
import ColorBadge from '../../components/ColorBadge'
import { DynamicTable } from '../../components/DynamicTable'
import InputGroup from '../../components/InputGroup'

export interface TransactionOperatorsAssignmentModalProps {
  open: boolean
  loadingOperators: boolean
  editMode: boolean
  allOperators: Operator[]
  selectedOperators: Operator[]
  transactions: Transaction[]
  error: string
  loadingAssignment: boolean
  onSelectOperators: (operators: Operator[]) => void
  onClose: () => void
  onConfirm: () => void
}

const TransactionOperatorsAssignmentModal: React.FC<TransactionOperatorsAssignmentModalProps> = ({
  open,
  loadingOperators,
  error,
  transactions,
  allOperators,
  selectedOperators,
  onSelectOperators,
  loadingAssignment,
  onConfirm,
  onClose,
  editMode
}) => {
  const operatorOptions = useMemo(() => {
    const options = allOperators.map((op) => ({
      label: `${op.id} - ${op.name} ${op.lastName}`,
      value: op.id,
      operator: op
    }))

    return options
  }, [allOperators])

  const selectedOperatorOptions = useMemo(() => {
    const options = selectedOperators.map((op) => ({
      label: `${op.id} - ${op.name} ${op.lastName}`,
      value: op.id,
      operator: op
    }))

    return options
  }, [selectedOperators])

  return (
    <Modal
      width={'80%'}
      centered
      destroyOnClose
      closable={true}
      visible={open}
      title={'Asignar operarios a transacciones'}
      style={{ maxWidth: 1000 }}
      maskClosable={!loadingAssignment}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loadingAssignment}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingAssignment}
          onClick={onConfirm}
          disabled={loadingAssignment || loadingOperators}>
          Asignar
        </Button>
      ]}>
      <Transactions transactions={transactions} />
      <InputGroup title="Operarios asignados">
        <Select
          options={operatorOptions}
          // @ts-ignore
          onSelect={(newSelection) => {
            if (!newSelection) return
            onSelectOperators([...selectedOperators, newSelection.operator])
          }}
          disabled={loadingAssignment || loadingOperators}
          multiple={true}
          onDelete={(value) => onSelectOperators(selectedOperators.filter((s) => s.id !== value.operator.id))}
          selected={selectedOperatorOptions}
          loading={loadingOperators}
          placeholder="Seleccione uno o más operarios..."
        />
      </InputGroup>
      {error && error}
    </Modal>
  )
}

export default TransactionOperatorsAssignmentModal

const Transactions: React.FC<{ transactions: Transaction[] }> = ({ transactions }) => {
  const columns: Column<Transaction>[] = [
    {
      key: 'id',
      label: 'ID TX'
    },
    {
      key: 'reservationDate',
      label: 'F. de reserva',
      maxWidth: '120px',
      alignment: 'left',
      renderDataCell: ({ reservationDate }) => formatDate(reservationDate)
    },
    {
      key: 'transactionType',
      label: 'Transacción',
      renderDataCell: ({ typeId }) => {
        switch (typeId) {
          case TransactionTypeId.DELIVERY_DEPOSITO:
            return 'Delivery depósito'
          case TransactionTypeId.PICKUP_DEPOSITO:
            return 'Pickup depósito'
        }
      }
    },
    {
      key: 'client',
      label: 'Cliente',
      renderDataCell: ({ client }) => `${client.id} - ${client.name} ${client.lastName}`
    },
    {
      key: 'client.phone',
      label: 'Teléfono'
    },
    {
      key: 'destinationAddress',
      label: 'Destino',
      minWidth: '220px'
    },
    {
      key: 'transactionStatusId',
      label: 'Estado',
      maxWidth: 'min-content',
      renderDataCell: ({ transactionStatusId }) => {
        const found = TransactionStatusBadges.find((tsb) => tsb.value === transactionStatusId)
        if (!found) return '-'
        const props = {
          ...found.styleProps
        }
        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    }
  ]

  return (
    <div style={{ padding: '12px 0', maxHeight: 500 }}>
      <DynamicTable columns={columns} rows={transactions} keyExtractor={(entity) => entity.id} />
    </div>
  )
}
