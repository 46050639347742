import React, { ReactNode, useMemo } from 'react'
import { Col, DatePicker, Input, Modal, Row, Spin } from 'antd'
import InputGroup from '../../../components/InputGroup'
import Select from '../../../components/Select/Select'
import { CountrySelectorState } from '../../../components/CountrySelector/types/CountrySelector'
import createPriceBandActionCreators from '../actionCreators/create'
import countryActionCreators from '../../../components/CountrySelector/actions/CountrySelector'
import { CreatePriceBandState } from '../reducers/create'
import { getOptionValue } from '../../Users/Users.selectors'
import { formatOptionLabel } from '../../Users/components/cellphone'
import { CountryIdCode, Country } from '../../../components/CountrySelector/constants/constants'
import { getValue } from '../../ProductsV2/utils/utils'
import moment, { Moment } from 'moment'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Permissions } from '../../UserLogged/permissions'

const styles = {
  modal: {
    maxWidth: 700
  },
  select: {
    width: '100%'
  },
  bodyModal: {
    maxHeight: '70vh',
    overflow: 'auto'
  },
  button: {
    backgroundColor: '#5d58f7',
    border: 'none',
    color: 'white',
    padding: '5px 37px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px'
  },
  input: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  },
  inlineText: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '400px',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inlineSelect: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '100%'
  },
  cont: {
    marginBottom: '6px'
  },
  explainer: {
    marginTop: 5,
    fontSize: '0.75rem',
    color: '#a7a7a7'
  },
  error: {
    color: 'red',
    textAlign: 'center' as const,
    marginTop: 10,
    marginBottom: 0
  },
  checkbox: {
    marginLeft: 0,
    padding: '6px 0'
  },
  range: {
    zIndex: 10000
  }
}

interface CreatePriceBandProps {
  createPriceBandActions: typeof createPriceBandActionCreators
  createPriceBandState: CreatePriceBandState
  countryActions: typeof countryActionCreators
  countryState: CountrySelectorState
  permissions: Permissions[]
}

type OptionCountry = {
  value: CountryIdCode
  label: string
  image: ReactNode
}

type OptionType = {
  value: string
  label: string
}

const create = ({
  createPriceBandActions,
  createPriceBandState,
  countryActions,
  countryState,
  permissions
}: CreatePriceBandProps) => {
  const {
    setCreateModalOpen,
    createPriceBand,
    modifyProduct,
    setDateFrom,
    setPrice,
    setTypePrice,
    setCountry,
    closeModal,
    deletePriceBand
  } = createPriceBandActions
  const { loading, error, editMode, modalOpen, modalDelete } = createPriceBandState
  const { country, dateFrom, id, price, typePrice } = createPriceBandState
  const { countries } = countryState

  const countryOptions: OptionCountry[] = useMemo(
    () =>
      countries.map((c: Country) => ({
        value: c.code,
        label: c.label,
        image: <img width="100%" src={c.flagUrl} alt={c.label} />
      })),
    []
  )

  const typeOptions: OptionType[] = [
    { label: 'KG', value: 'kg' },
    { label: 'M3', value: 'm3' }
  ]

  const checkCountryLabel = (value: string) => {
    return value.length > 3 ? country : value
  }

  const userCountrySelected = useMemo(
    () => countryOptions.find((countryOption) => countryOption.value === checkCountryLabel(country)),
    [countryOptions, country]
  )

  const typeSelected = useMemo(
    () => typeOptions.find((typeOption) => typeOption.value === typePrice),
    [typeOptions, typePrice]
  )

  const isReadyToSubmit = country && dateFrom && price && typePrice

  const handleSubmit = async () => {
    if (editMode && id) {
      modifyProduct({ country, dateFrom, price, typePrice, id })
    } else {
      createPriceBand({ country, dateFrom, price, typePrice })
    }
  }

  return (
    <div>
      <Modal
        title={'Franja de precios'}
        visible={modalOpen}
        okButtonProps={{
          disabled: !isReadyToSubmit
        }}
        onOk={() => handleSubmit()}
        onCancel={() => setCreateModalOpen(false)}
        okText={'ok'}
        cancelText={'Cancelar'}
        bodyStyle={styles.bodyModal}
        style={styles.modal}>
        <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
          <Col sm={24} xs={24}>
            <InputGroup title="Franja de precios">
              <Row gutter={[16, 16]}>
                <Col sm={12} xs={24}>
                  <p style={{ marginBottom: 5, color: 'grey', textTransform: 'uppercase', fontSize: 10 }}>País</p>
                  <Select
                    placeholder="Todos"
                    loading={loading}
                    selected={userCountrySelected}
                    options={countryOptions}
                    optionRenderer={formatOptionLabel}
                    onSelect={(option) => {
                      const value = getOptionValue(option) as string
                      setCountry(value)
                    }}
                    disabled={loading}
                  />
                </Col>
                <Col sm={12} xs={24}>
                  <p style={{ marginBottom: 5, color: 'grey', textTransform: 'uppercase', fontSize: 10 }}>Tipo</p>
                  <Select
                    placeholder="Tipo"
                    loading={loading}
                    options={typeOptions}
                    selected={typeSelected}
                    onSelect={(option) => {
                      const value = getOptionValue(option) as 'm3' | 'kg'
                      setTypePrice(value)
                    }}
                    disabled={loading}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col sm={12} xs={24}>
                  <p style={styles.inputTitle}>Precio*: </p>
                  <Input
                    type="number"
                    style={styles.input}
                    disabled={loading}
                    placeholder="Precio"
                    value={price}
                    onChange={(e) => setPrice(getValue(e))}
                    suffix={loading && <Spin size="small" />}
                  />
                </Col>
                <Col sm={12} xs={24}>
                  <p style={styles.inputTitle}>Fecha inicio validez: </p>
                  <DatePicker
                    disabledDate={(current: Moment | null) => (current ? moment().add(-1, 'days') >= current : false)}
                    locale={locale}
                    onChange={(date: any) => {
                      setDateFrom(date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
                    }}
                    style={{ width: '100%' }}
                    popupStyle={styles.range}
                    value={dateFrom || moment()}
                    allowClear={false}
                    format="DD/MM/YYYY"
                  />
                </Col>
              </Row>
            </InputGroup>
          </Col>
        </Row>
        <div className="container">
          <p style={{ color: 'red', textAlign: 'right' }}>{error}</p>
        </div>
      </Modal>
      <Modal
        title={'Eliminar Franja de precio'}
        visible={modalDelete}
        closable={!loading}
        maskClosable={!loading}
        onOk={() => {
          if (id) deletePriceBand(id)
        }}
        onCancel={() => closeModal()}
        cancelButtonProps={{
          disabled: loading
        }}
        okButtonProps={{
          loading,
          disabled: loading
        }}
        okText={'Aceptar'}
        cancelText={'Cancelar'}
        bodyStyle={styles.bodyModal}
        style={styles.modal}>
        <p>¿Está seguro que quiere eliminar la franja de precio?</p>
        <div className="container">
          <p style={styles.error}>{error}</p>
        </div>
      </Modal>
    </div>
  )
}

export default create
