import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import {
  AttributesTableCategoryFilter,
  AttributesTableSearchFilter,
  AttributesTableSort,
  CLEAR_STATE,
  ClearState,
  GET_ATTRIBUTES_FAILURE,
  GET_ATTRIBUTES_REPORT_FAILURE,
  GET_ATTRIBUTES_REPORT_REQUEST,
  GET_ATTRIBUTES_REPORT_SUCCESS,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS
} from '../types/AttributesTable'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'

const actions = {
  getAttributes({
    productId,
    searchFilters,
    pagination,
    sort,
    categoryFilter
  }: {
    productId?: number
    searchFilters?: AttributesTableSearchFilter[]
    pagination: Pagination
    sort?: AttributesTableSort
    categoryFilter?: AttributesTableCategoryFilter
  }): AppThunk {
    return async function (dispatch) {
      const toast = setToastLoading('Buscando atributos, por favor espere...')
      dispatch({
        type: GET_ATTRIBUTES_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          categoryFilter,
          sort
        }
      })
      try {
        const response = await API.ObjectAdministration.Attribute.list({
          productId,
          limit: pagination?.pageSize || 0,
          offset: pagination ? (pagination.page - 1) * pagination.pageSize : 0,
          ...searchFiltersToParams(searchFilters),
          sort: sort?.field,
          order: sort?.direction,
          visible: categoryFilter?.is_visible[0]
        })
        const { attributes, total } = response
        dispatch({ type: GET_ATTRIBUTES_SUCCESS, payload: { attributes, total } })
        setToastSuccessUpdate(toast, { render: 'Atributos cargados con éxito.' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al cargar los atributos...' })
        handleErrorsWithAction(e, GET_ATTRIBUTES_FAILURE, dispatch)
      }
    }
  },
  sendAttributesReport({
    productId,
    searchFilters,
    pagination,
    sort,
    categoryFilter
  }: {
    productId?: number
    searchFilters?: AttributesTableSearchFilter[]
    pagination: Pagination
    sort?: AttributesTableSort
    categoryFilter?: AttributesTableCategoryFilter
  }): AppThunk {
    return async function (dispatch) {
      const toast = setToastLoading('Generando reporte de atributos, por favor espere...')
      dispatch({
        type: GET_ATTRIBUTES_REPORT_REQUEST
      })
      try {
        await API.ObjectAdministration.Attribute.reports({
          productId,
          limit: pagination?.pageSize || 50,
          offset: pagination ? (pagination.page - 1) * pagination.pageSize : 0,
          ...searchFiltersToParams(searchFilters),
          sort: sort?.field,
          order: sort?.direction,
          visible: categoryFilter?.is_visible[0]
        })
        dispatch({ type: GET_ATTRIBUTES_REPORT_SUCCESS })
        setToastSuccessUpdate(toast, { render: 'Reporte enviado con éxito.' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: handleErrorsWithAction(e, GET_ATTRIBUTES_REPORT_FAILURE, dispatch) })
      }
    }
  },
  clearState: (): ClearState => ({ type: CLEAR_STATE })
}

export default actions
