import React, { useMemo } from 'react'
import styles from './summaryReview.module.scss'
import { useLocalization } from '../../../packages/localization'
import { RemovalObject } from '../../../projectApi/TransactionHandler/Removals/details'
import OverviewCard, { Module } from '../../../components/overviewCard'
import { PercentageRound } from '../../../icons'
import { ExpandableModule, Header } from '../../../components/overviewCard/overviewCard'
import { formatNumber } from '../../../utils/formatNumber'
import clsx from 'clsx'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { PriceAnimator } from '../../DepositsValidation/components/validationView'

type CompoundPrices = {
  stairsTotal: number
  packagingTotal: number
  assemblyTotal: number
}

export const SummaryViewCard: React.FC<{
  objects: CamelCasedPropertiesDeep<RemovalObject>[]
  minCost: number
  quotedTransportCost: number
  fetchedRecalculation?: boolean
  getBillingByTransaction?: () => void
  currencySymbol?: string
  extraHeaderInfo?: string
  discountCode?: string
  totalM3?: number
  buttonAction?: () => void
  buttonRecalculateAction?: () => void
  buttonText?: string
  recalculateButtonText?: string
}> = ({
  objects,
  minCost,
  quotedTransportCost,
  currencySymbol,
  extraHeaderInfo,
  discountCode,
  totalM3,
  buttonRecalculateAction,
  recalculateButtonText = 'Recalcular'
}) => {
  const { strings } = useLocalization()
  const ValidationViewStrings = strings.ValidationViewScreen

  const { stairsTotal, packagingTotal, assemblyTotal }: CompoundPrices = useMemo(() => {
    const objectsCompounded: CompoundPrices = objects.reduce<CompoundPrices>(
      (acc, obj) => {
        const { packagingCost, floorsByStairsCost, assemblyCost } = obj
        return {
          stairsTotal: acc.stairsTotal + floorsByStairsCost,
          packagingTotal: acc.packagingTotal + packagingCost,
          assemblyTotal: acc.assemblyTotal + assemblyCost
        }
      },
      {
        stairsTotal: 0,
        packagingTotal: 0,
        assemblyTotal: 0
      }
    )

    return objectsCompounded
  }, [objects])

  const totalSum = stairsTotal + packagingTotal + quotedTransportCost + minCost

  const SubtotalTitle = (
    <div className={styles.subtotal}>
      <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.title}</span>
      <span>
        <PriceAnimator value={totalSum} currencySymbol={currencySymbol} />
      </span>
    </div>
  )

  return (
    <OverviewCard>
      <Header extraInfo={extraHeaderInfo}>{ValidationViewStrings.overviewCard.summaryHeader}</Header>
      <Module className={styles.priceGrid}>
        <span>
          <b>{ValidationViewStrings.overviewCard.fields.objects.title}</b>
        </span>
        <span>
          <b>{objects.length}</b>
          {totalM3 && <span className={styles.totalM3}> ({formatNumber(totalM3)} m3)</span>}
        </span>
      </Module>
      <ExpandableModule title={SubtotalTitle}>
        <div className={styles.priceGrid}>
          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.transport}</span>
          <span>
            <PriceAnimator value={quotedTransportCost} currencySymbol={currencySymbol} />
          </span>

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.stairs}</span>
          <PriceAnimator value={stairsTotal} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.packaging}</span>
          <PriceAnimator value={packagingTotal} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.assembly}</span>
          <PriceAnimator value={assemblyTotal} currencySymbol={currencySymbol} />

          <span>{ValidationViewStrings.overviewCard.fields.additionalCosts.subFields.minCost}</span>
          <PriceAnimator value={minCost} currencySymbol={currencySymbol} />
        </div>
      </ExpandableModule>
      <Module className={styles.priceGrid}>
        <span className={styles.total}>{ValidationViewStrings.overviewCard.fields.total.title}</span>
        <PriceAnimator
          value={totalSum}
          currencySymbol={currencySymbol}
          className={clsx(styles.total, styles.totalNumber)}
        />
      </Module>
      {discountCode && (
        <Module className={styles.discountCodeModule}>
          <PercentageRound />
          <span>
            {ValidationViewStrings.overviewCard.fields.discounts.title} {discountCode}
          </span>
        </Module>
      )}
      {buttonRecalculateAction && (
        <Module>
          <button className={styles.confirmButton} onClick={buttonRecalculateAction}>
            {recalculateButtonText}
          </button>
        </Module>
      )}
    </OverviewCard>
  )
}

export default SummaryViewCard
