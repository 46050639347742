import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import {
  Complaint,
  CreateComplaintRequest,
  CreateComplaintSuccess,
  CreateComplaintTypes,
  Priority,
  SET_OPEN,
  SetOpen,
  SettersComplaintsTypes,
  setClient,
  setComplaintEvidence,
  setComplaintReason,
  setComplaintType,
  setOperationId,
  setObjects,
  setPriority,
  Photo,
  setComplaintDescription,
  ComplaintType,
  GetUsersComplaintTypes,
  GetUsersComplaintSuccess,
  GetUsersComplaintRequest,
  GetObjectByIdRequest,
  GetObjectByIdTypes,
  GetObjectByIdSuccess,
  GetAgentsTypes,
  setAgent,
  GetOperationByIdRequest,
  GetOperationByIdSuccess,
  GetOperationByIdTypes,
  GetAgentsSuccess,
  GetAgentsRequest
} from '../types/createComplaintModal'
import { API } from '@/projectApi'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import Emitter from '@/utils/eventEmitter'
import { Events } from '@/utils/eventEmitter/events'
import { DELETE_EVIDENCE, deleteEvidence, GetComplaintsReasonsTypes, HANDLE_CLEAR, HandleClear } from '../types/common'

const CreateComplaintModalActions = {
  setOpen: ({
    open,
    operationId,
    type,
    userId
  }: {
    open: boolean
    operationId?: number
    type?: keyof typeof ComplaintType
    userId?: number
  }): SetOpen => ({
    type: SET_OPEN,
    payload: { open, operationId, type, userId }
  }),
  setOperationId: (operationId: number): setOperationId => ({
    type: SettersComplaintsTypes.SET_OPERATION_ID,
    payload: { operationId }
  }),
  setComplaintType: (type: keyof typeof ComplaintType): setComplaintType => ({
    type: SettersComplaintsTypes.SET_TYPE,
    payload: { type }
  }),
  setComplaintReason: (reasonId: number): setComplaintReason => ({
    type: SettersComplaintsTypes.SET_REASON,
    payload: { reasonId }
  }),
  setComplaintDescription: (description: string): setComplaintDescription => ({
    type: SettersComplaintsTypes.SET_DESCRIPTION,
    payload: { description }
  }),
  setComplaintEvidence: (photo: Photo[]): setComplaintEvidence => ({
    type: SettersComplaintsTypes.SET_EVIDENCE,
    payload: { photo }
  }),

  deleteEvidence: (index: number): deleteEvidence => ({
    type: DELETE_EVIDENCE,
    payload: { index }
  }),

  setAgent: (assignedUserId: number | null): setAgent => ({
    type: SettersComplaintsTypes.SET_AGENT,
    payload: { assignedUserId }
  }),
  setPriority: (priority: keyof typeof Priority): setPriority => ({
    type: SettersComplaintsTypes.SET_PRIORITY,
    payload: { priority }
  }),
  setObjects: (objects: number[]): setObjects => ({
    type: SettersComplaintsTypes.SET_OBJECTS,
    payload: { objects }
  }),

  setUser: (userId: number): setClient => ({
    type: SettersComplaintsTypes.SET_USER_ID,
    payload: { userId }
  }),

  handleClear: (): HandleClear => ({
    type: HANDLE_CLEAR
  }),

  createComplaint:
    (complaint: Complaint): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Creando reclamo, por favor espere...')
      const request: CreateComplaintRequest = {
        type: CreateComplaintTypes.CREATE_COMPLAINT_REQUEST
      }

      dispatch(request)

      try {
        await API.Complaints.create(complaint)
        const success: CreateComplaintSuccess = {
          type: CreateComplaintTypes.CREATE_COMPLAINT_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Reclamo creado correctamente' })
        Emitter.emit(Events.Complaints.COMPLAINTS_CREATED)
      } catch (error: any) {
        dispatch({
          type: CreateComplaintTypes.CREATE_COMPLAINT_FAILURE
        })
        setToastErrorUpdate(toastId, { render: 'Error al crear el reclamo' })
      }
    },

  getUsers:
    ({ userId, name }: { userId: string; name: string }): AppThunk =>
    async (dispatch) => {
      const request: GetUsersComplaintRequest = {
        type: GetUsersComplaintTypes.GET_USERS_REQUEST
      }

      dispatch(request)

      try {
        const { users } = await API.AccountManager.Users.list({
          id: userId ? parseInt(userId) : undefined,
          name: name || undefined
        })
        if (users.length === 0) throw new Error('No se encontraron usuarios')
        const success: GetUsersComplaintSuccess = {
          type: GetUsersComplaintTypes.GET_USERS_SUCCESS,
          payload: { users }
        }
        dispatch(success)
      } catch (error: any) {
        handleErrorsWithAction(error, GetUsersComplaintTypes.GET_USERS_FAILURE, dispatch)
      }
    },

  getObjectById:
    ({ objectId, userId }: { objectId?: number; userId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetObjectByIdRequest = {
        type: GetObjectByIdTypes.GET_OBJECT_BY_ID_REQUEST
      }

      dispatch(request)

      try {
        const { objects } = await API.ObjectAdministration.Object.list({
          id: objectId,
          location: 'true',
          ownerId: userId
        })

        if (objects.length < 1) throw new Error('No se encontró objeto con el id especificado')

        const success: GetObjectByIdSuccess = {
          type: GetObjectByIdTypes.GET_OBJECT_BY_ID_SUCCESS,
          payload: {
            object: objects
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetObjectByIdTypes.GET_OBJECT_BY_ID_FAILURE, dispatch)
      }
    },

  getReasons(): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: GetComplaintsReasonsTypes.GET_REASONS_REQUEST
      })
      try {
        const { reasons } = await API.Complaints.reasons({
          column: 'id',
          order: 'desc',
          type: 'COMPLAINTS'
        })
        dispatch({
          type: GetComplaintsReasonsTypes.GET_REASONS_SUCCESS,
          payload: {
            reasons
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetComplaintsReasonsTypes.GET_REASONS_FAILURE, dispatch)
      }
    }
  },

  getAgents: (): AppThunk => async (dispatch) => {
    const request: GetAgentsRequest = {
      type: GetAgentsTypes.GET_AGENTS_REQUEST
    }

    dispatch(request)

    try {
      const { agents } = await API.Mercurio.BO.Agents.list({
        limit: 200,
        offset: 0
      })

      if (agents.length < 2) throw new Error('No se encontraron agentes')

      const success: GetAgentsSuccess = {
        type: GetAgentsTypes.GET_AGENTS_SUCCESS,
        payload: { agents }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GetAgentsTypes.GET_AGENTS_FAILURE, dispatch)
    }
  },

  getOperationById:
    ({ operationId }: { operationId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetOperationByIdRequest = {
        type: GetOperationByIdTypes.GET_OPERATION_REQUEST
      }

      dispatch(request)

      try {
        const operation = await API.TransactionHandler.Operation.getOperationById({
          id: operationId
        })

        const success: GetOperationByIdSuccess = {
          type: GetOperationByIdTypes.GET_OPERATION_SUCCESS,
          payload: { operation }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GetOperationByIdTypes.GET_OPERATION_FAILURE, dispatch)
      }
    }
}

export default CreateComplaintModalActions
