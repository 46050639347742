import types from '../types/cbus'

const initialRoot = {
  cbus: [],
  loading: false,
  error: ''
}

function cbusReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.GET_CBUS_DATA_REQUEST:
      return {
        ...state,
        cbus: [],
        loading: true,
        error: ''
      }
    case types.GET_CBUS_DATA_SUCCESS:
      return {
        ...state,
        cbus: action.payload.cbus,
        loading: false
      }
    case types.GET_CBUS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default cbusReducer
