import { Modal } from 'antd'
import React, { useMemo, useState } from 'react'
import { ComplaintHistoryItem } from './ComplaintHistory'
import { useLocalization } from '@/packages/localization'
import Carousel from '@/components/Carousel/Carousel'
import styles from '../ComplaintUpdateView.module.scss'
import { ComplaintHistory } from '@/projectApi/Complaints/list'
import Text from 'antd/lib/typography/Text'

export type AgentsModalProps = {
  handleCloseEvidence: () => void
  selectedEvidence: ComplaintHistory | null
  openEvidence: boolean
}

const EvidenceModal: React.FC<AgentsModalProps> = ({ handleCloseEvidence, selectedEvidence, openEvidence }) => {
  const { strings } = useLocalization()

  if (!selectedEvidence || !selectedEvidence.photos) {
    return null
  }

  const [selectedImage, setSelectedImage] = useState(selectedEvidence.photos[0]?.url || '')

  const images = useMemo(
    () => (selectedEvidence.photos ? selectedEvidence.photos.map((photo) => photo.url) : null),
    [selectedEvidence.photos]
  )

  return (
    <Modal
      centered
      closable={true}
      okButtonProps={{
        style: { display: 'none' }
      }}
      width={700}
      visible={openEvidence}
      onCancel={handleCloseEvidence}
      cancelButtonProps={{
        className: styles.cancelBtn
      }}
      cancelText={strings.generic.close}
      title={strings.ComplaintsViewScreen.updateView.evidenceModal.title}
      destroyOnClose>
      <ComplaintHistoryItem historyItem={selectedEvidence} />
      {images && images?.length > 0 ? (
        <Carousel
          images={images}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          carouselCustomClass={styles.carouselEvidenceCustom}
          miniaturesCustomClass={styles.carouselEvidenceMiniatures}
          zoomScale={2}
          placeholder="/img/image-placeholder.svg"
        />
      ) : (
        <Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 16
          }}>
          {strings.ComplaintsViewScreen.updateView.clientDetail.emptyPhotos}
        </Text>
      )}
    </Modal>
  )
}

export default EvidenceModal
