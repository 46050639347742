import { NewProductPrice, FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { Product as ProductProposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { ITEM_TYPES_VALUES } from '../../../common/operations/constants'
import { GetProposalByIdSuccess, SetNewProposalFromScratch } from './proposals'
import { GetProspectRequest } from './prospect'
import { SetServiceType, ResetItemsBySolution } from './transport'

export enum ItemsGetActualM3StorageTypes {
  GET_ACTUAL_M3_STORAGE_REQUEST = 'NEW_PROPOSAL_V2/ITEMS/GET_ACTUAL_M3_STORAGE_REQUEST',
  GET_ACTUAL_M3_STORAGE_SUCCESS = 'NEW_PROPOSAL_V2/ITEMS/GET_ACTUAL_M3_STORAGE_SUCCESS',
  GET_ACTUAL_M3_STORAGE_FAILURE = 'NEW_PROPOSAL_V2/ITEMS/GET_ACTUAL_M3_STORAGE_FAILURE'
}

export enum ItemsRecalculateItemsTypes {
  RECALCULATE_ITEMS_REQUEST = 'NEW_PROPOSAL_V2/ITEMS/RECALCULATE_ITEMS',
  RECALCULATE_ITEMS_SUCCESS = 'NEW_PROPOSAL_V2/ITEMS/RECALCULATE_ITEMS_SUCCESS',
  RECALCULATE_ITEMS_FAILURE = 'NEW_PROPOSAL_V2/ITEMS/RECALCULATE_ITEMS_FAILURE'
}

export enum ItemsFetchInitialItemsTypes {
  FETCH_INITIAL_ITEMS_REQUEST = 'NEW_PROPOSAL_V2/ITEMS/FETCH_INITIAL_ITEMS',
  FETCH_INITIAL_ITEMS_SUCCESS = 'NEW_PROPOSAL_V2/ITEMS/FETCH_INITIAL_ITEMS_SUCCESS',
  FETCH_INITIAL_ITEMS_FAILURE = 'NEW_PROPOSAL_V2/ITEMS/FETCH_INITIAL_ITEMS_FAILURE'
}

export enum ItemsFetchItemsTypes {
  FETCH_ITEMS_REQUEST = 'NEW_PROPOSAL_V2/ITEMS/FETCH_ITEMS',
  FETCH_ITEMS_SUCCESS = 'NEW_PROPOSAL_V2/ITEMS/FETCH_ITEMS_SUCCESS',
  FETCH_ITEMS_FAILURE = 'NEW_PROPOSAL_V2/ITEMS/FETCH_ITEMS_FAILURE'
}

export enum ItemsDataTypes {
  SET_ITEM = 'NEW_PROPOSAL_V2/ITEMS/SET_ITEM',
  SET_INITIAL_ITEMS = 'NEW_PROPOSAL_V2/ITEMS/SET_INITIAL_ITEMS',
  SET_ITEM_QUANTITY = 'NEW_PROPOSAL_V2/ITEMS/SET_ITEM_QUANTITY',
  SET_ITEM_PACKAGING = 'NEW_PROPOSAL_V2/ITEMS/SET_ITEM_PACKAGING',
  SET_ITEM_FLOORS = 'NEW_PROPOSAL_V2/ITEMS/SET_ITEM_FLOORS',
  SET_DISASSEMBLE = 'NEW_PROPOSAL_V2/ITEMS/SET_DISASSEMBLE',
  CLEAN_ITEM = 'NEW_PROPOSAL_V2/ITEMS/CLEAN_ITEM',
  NEW_ITEM = 'NEW_PROPOSAL_V2/ITEMS/NEW_ITEM',
  REMOVE_ITEM = 'NEW_PROPOSAL_V2/ITEMS/REMOVE_ITEM'
}

export interface GetActualM3StorageRequest {
  type: ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_REQUEST
}

export interface GetActualM3StorageSuccess {
  type: ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_SUCCESS
  payload: {
    actualM3Storage: number
  }
}

export interface GetActualM3StorageFailure {
  type: ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_FAILURE
  payload: {
    error: string
  }
}

export type GetActualM3StorageTypes = GetActualM3StorageRequest | GetActualM3StorageSuccess | GetActualM3StorageFailure

export interface RecalculateItemsRequest {
  type: ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_REQUEST
  payload: { lastSearchID: number }
}

export interface RecalculateItemsSuccess {
  type: ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_SUCCESS
  payload: {
    products: NewProductPrice[]
  }
}

export interface RecalculateItemsFailure {
  type: ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_FAILURE
  payload: {
    error: string
  }
}

export type RecalculateItemsTypes = RecalculateItemsRequest | RecalculateItemsSuccess | RecalculateItemsFailure

export interface FetchInitialItemsRequest {
  type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_REQUEST
}

export interface FetchInitialItemsSuccess {
  type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_SUCCESS
  payload: {
    items: FormattedProduct[]
    isDifferent: boolean
  }
}

export interface FetchInitialItemsFailure {
  type: ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_FAILURE
  payload: {
    error: string
  }
}

type FetchInitialItemsTypes = FetchInitialItemsRequest | FetchInitialItemsSuccess | FetchInitialItemsFailure

export interface FetchItemsRequest {
  type: ItemsFetchItemsTypes.FETCH_ITEMS_REQUEST
  payload: {
    index: number
    lastSearchID: number
  }
}

export interface FetchItemsSuccess {
  type: ItemsFetchItemsTypes.FETCH_ITEMS_SUCCESS
  payload: {
    index: number
    items: FormattedProduct[]
  }
}

export interface FetchItemsFailure {
  type: ItemsFetchItemsTypes.FETCH_ITEMS_FAILURE
  payload: {
    index: number
    error: string
  }
}

type FetchItemsTypes = FetchItemsRequest | FetchItemsSuccess | FetchItemsFailure

export interface SetInitialItems {
  type: ItemsDataTypes.SET_INITIAL_ITEMS
  payload: {
    lastSearchID: number
    index: number
  }
}

export interface CleanItem {
  type: ItemsDataTypes.CLEAN_ITEM
  payload: {
    index: number
  }
}

export interface RemoveItem {
  type: ItemsDataTypes.REMOVE_ITEM
  payload: {
    index: number
  }
}

export interface SetItemQuantity {
  type: ItemsDataTypes.SET_ITEM_QUANTITY
  payload: {
    index: number
    quantity: number
  }
}

export interface SetItemPackaging {
  type: ItemsDataTypes.SET_ITEM_PACKAGING
  payload: {
    index: number
    packaging: boolean
  }
}

export interface SetItemFloors {
  type: ItemsDataTypes.SET_ITEM_FLOORS
  payload: {
    index: number
    floors: number
  }
}

export interface SetDisassemble {
  type: ItemsDataTypes.SET_DISASSEMBLE
  payload: {
    index: number
    disassemble: boolean
  }
}

export interface SetItem {
  type: ItemsDataTypes.SET_ITEM
  payload: {
    item: FormattedProduct
    index: number
  }
}

export interface NewItem {
  type: ItemsDataTypes.NEW_ITEM
}

export type DataItemsTypes =
  | SetInitialItems
  | CleanItem
  | RemoveItem
  | SetItemQuantity
  | SetItemPackaging
  | SetItemFloors
  | SetDisassemble
  | SetItem
  | NewItem

export interface SelectedItem {
  items: FormattedProduct[] | ProductProposal[]
  lastSearchID: number | null
  selectedItem: FormattedProduct | ProductProposal | null
  quantity: number
  loading: boolean
  byProposal?: boolean
  type: ITEM_TYPES_VALUES
  packaging: boolean
  disassemble: boolean
  deleted?: boolean
  floors: number
}

export interface ItemsState {
  initialItems: FormattedProduct[]
  loadingActualM3Storage: boolean
  loadingRecalculatePrice: boolean
  lastRecalculateSearchTime: number
  actualM3Storage: number
  errorActualM3Storage: string
  errorRecalculatePrice: string
  fetched: boolean
  selectedItems: SelectedItem[]
}

export type ItemsActionTypes =
  | GetActualM3StorageTypes
  | RecalculateItemsTypes
  | FetchInitialItemsTypes
  | FetchItemsTypes
  | DataItemsTypes
  | GetProposalByIdSuccess
  | GetProspectRequest
  | SetNewProposalFromScratch
  | SetServiceType
  | ResetItemsBySolution
