import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/ProviderList'
import {
  ProvidersSearchFilter,
  GetProviderListRequest,
  GetProviderListSuccess,
  GetProviderListTypes,
  RESET_FILTERS,
  SendCSVListRequest,
  SendCSVListTypes,
  SendCSVListSuccess,
  ProvidersTableSort
} from '../types/ProviderList'

const ProviderListActionsCreator = {
  getProviders({
    pagination,
    searchFilters,
    sort
  }: {
    pagination: Pagination
    sort: ProvidersTableSort
    searchFilters?: ProvidersSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetProviderListRequest = {
        type: GetProviderListTypes.GET_PROVIDER_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          searchFilters,
          sort
        }
      }

      dispatch(request)

      try {
        const { providers, total } = await API.Trips.Providers.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          column: sort.field,
          order: sort.direction
        })
        const success: GetProviderListSuccess = {
          type: GetProviderListTypes.GET_PROVIDER_SUCCESS,
          payload: {
            providers,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetProviderListTypes.GET_PROVIDER_FAILURE, dispatch)
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.Provider.CLEAN_FILTERS), 50)
    }
  },
  sendCSVProviders({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: ProvidersSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: SendCSVListRequest = {
        type: SendCSVListTypes.SEND_CSV_REQUEST
      }
      const toast = setToastLoading('Enviando CSV, por favor espere...')
      dispatch(request)

      try {
        await API.Trips.Providers.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          format: 'csv'
        })

        const success: SendCSVListSuccess = {
          type: SendCSVListTypes.SEND_CSV_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'CSV enviado correctamente. Revise su correo electrónico.' })
      } catch (error) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(error, SendCSVListTypes.SEND_CSV_FAILURE, dispatch)
        })
      }
    }
  }
}

export default ProviderListActionsCreator
