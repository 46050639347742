import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'
import { convertPrice } from './common'

export type AcceptParams = {
  id?: number
  existExpressSale?: boolean
  pve?: number
  expressSaleCost?: number
  expressSaleMargin?: number
  pvp: number
  saleCost: number
  saleMargin: number
}

export function acceptProposal(params: AcceptParams) {
  const { pvp, saleCost, saleMargin, id, existExpressSale, expressSaleCost, expressSaleMargin, pve } = params

  const data = {
    exist_express_sale: existExpressSale,
    express_sale: {
      pve: convertPrice(pve || 0, 'cents'),
      sale_cost: convertPrice(expressSaleCost || 0, 'cents'),
      sale_margin: convertPrice(expressSaleMargin || 0, 'cents')
    },
    pvp: convertPrice(pvp, 'cents'),
    sale_cost: convertPrice(saleCost, 'cents'),
    sale_margin: convertPrice(saleMargin, 'cents')
  }

  return apiPrivate
    .put(`${URL_BASE_SALES}/sales-requests/accept/${id}`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
