export enum NewAttributesEventsEnum {
  ATTRIBUTE_CREATED = 'NEW_ATTRIBUTE/ATTRIBUTE_CREATED',
  ATTRIBUTE_UPDATED = 'NEW_ATTRIBUTE/ATTRIBUTE_UPDATED',
  ATTRIBUTE_DELETED = 'NEW_ATTRIBUTE/ATTRIBUTE_DELETED'
}

export type NewAttributesEvents = {
  [NewAttributesEventsEnum.ATTRIBUTE_CREATED]: () => void
  [NewAttributesEventsEnum.ATTRIBUTE_UPDATED]: () => void
  [NewAttributesEventsEnum.ATTRIBUTE_DELETED]: () => void
}
