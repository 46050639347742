import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import {
  GET_OPERATORS_FAILURE,
  GET_OPERATORS_REQUEST,
  GET_OPERATORS_SUCCESS,
  GET_PICKINGS_FAILURE,
  GET_PICKINGS_REQUEST,
  GET_PICKINGS_SUCCESS,
  PickingsTableAction,
  PickingsTableState
} from '../types/pickingsTable'

const initialState: PickingsTableState = {
  operators: [],
  loadingOperators: false,
  pickings: [],
  loadingPickings: false,
  error: '',
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'operation_id', text: '' },
    { key: 'owner_id', text: '' }
  ],
  categoryFilter: {
    status: [],
    agent_id: []
  },
  sort: {
    field: 'id',
    direction: SortDirection.DESC
  },
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  dateRangeFilters: [
    { key: 'created_at', startDate: null, endDate: null },
    { key: 'picking_date', startDate: null, endDate: null },
    { key: 'start_date', startDate: null, endDate: null },
    { key: 'end_date', startDate: null, endDate: null }
  ]
}

const PickingsTableReducer: Reducer<PickingsTableState, PickingsTableAction> = (
  state = initialState,
  action
): PickingsTableState => {
  switch (action.type) {
    case GET_PICKINGS_REQUEST:
      return {
        ...state,
        loadingPickings: !action.payload.silentLoading,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        sort: action.payload.sort || initialState.sort,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GET_PICKINGS_SUCCESS: {
      return {
        ...state,
        loadingPickings: false,
        pickings: action.payload.pickings,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    }
    case GET_PICKINGS_FAILURE:
      return {
        ...state,
        loadingPickings: false,
        error: action.payload.error
      }
    case GET_OPERATORS_REQUEST:
      return { ...state, loadingOperators: true }
    case GET_OPERATORS_SUCCESS:
      return { ...state, loadingOperators: false, operators: action.payload.operators }
    case GET_OPERATORS_FAILURE:
      return { ...state, loadingOperators: false, error: action.payload.error }
    default:
      return state
  }
}

export default PickingsTableReducer
