import {
  CLOSE_DEFAULT_ADDRESS_MODAL,
  CloseDefaultAddressModal,
  DEFAULT_ADDRESS_FAILURE,
  DEFAULT_ADDRESS_REQUEST,
  DEFAULT_ADDRESS_SUCCESS,
  DefaultAddressRequest,
  DefaultAddressSuccess,
  OPEN_DEFAULT_ADDRESS_MODAL,
  OpenDefaultAddressModal
} from '../types/defaultAddress'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'

export const DefaultAddressActionCreator = {
  setDefaultAddress:
    (userId: number, addressId: number): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const request: DefaultAddressRequest = { type: DEFAULT_ADDRESS_REQUEST }
      dispatch(request)

      try {
        await API.AccountManager.Addresses.setDefaultAddress({ userId, addressId })
        const success: DefaultAddressSuccess = {
          type: DEFAULT_ADDRESS_SUCCESS
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, DEFAULT_ADDRESS_FAILURE, dispatch)
      }
    },
  openModal: (userId: number, addressId: number, addressText: string): OpenDefaultAddressModal => ({
    type: OPEN_DEFAULT_ADDRESS_MODAL,
    payload: { userId, addressId, addressText }
  }),
  closeModal: (): CloseDefaultAddressModal => ({ type: CLOSE_DEFAULT_ADDRESS_MODAL })
}
