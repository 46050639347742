import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { convertToMoment } from '../../../utils'
import { APIRemoval, Removal } from './common'

export interface Description {
  operations: APIRemoval[]
  quantity: number
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = {
  operation: Removal
}

interface GetByIdParams {
  operationId: number
}

export function getById({ operationId }: GetByIdParams): Promise<Response> {
  const params = { id: operationId }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operation/removal`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.success) throw new Error('No se pudo obtener la operación')
      if (response.data.description?.operations.length !== 1)
        throw new Error('Respuesta incorrecta al obtener la operación')

      const camelResponse = camelcaseKeys(response.data.description.operations, { deep: true }).map((op) => ({
        ...op,
        createdAt: convertToMoment(op.createdAt),
        datetime: convertToMoment(op.datetime),
        removalAuth: {
          ...op.removalAuth,
          dateAuthRemoval: convertToMoment(op.removalAuth.dateAuthRemoval)
        }
      }))[0]

      return { operation: camelResponse }
    })
    .catch((err) => {
      throw err
    })
}
