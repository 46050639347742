import { UploadPhotosAction, UploadPhotoActionTypes } from './types'

export type UploadPhotosState = {
  loadingPhoto: boolean
  error: string
}

const initialState = {
  loadingPhoto: false,
  error: '',
  uploadedPhotos: []
}

function reducer(state: UploadPhotosState = initialState, action: UploadPhotosAction): UploadPhotosState {
  switch (action.type) {
    case UploadPhotoActionTypes.UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        loadingPhoto: true,
        error: ''
      }
    case UploadPhotoActionTypes.UPLOAD_PHOTO_SUCCESS:
      return initialState
    case UploadPhotoActionTypes.UPLOAD_PHOTO_FAILURE:
      return {
        ...state,
        loadingPhoto: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default reducer
