import React from 'react'

const ItemsToLoad: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1604_4331)">
        <path
          d="M7.35 16.8V9.22498L0.825 5.43748V13.0125L1.4625 13.3875V11.025H2.2875V13.875L2.9625 14.25V11.775H3.75V14.7L4.275 15L7.35 16.8ZM12.075 3.41248C10.8375 3.97498 10.275 5.43748 10.8375 6.67498C10.9125 6.82498 10.9875 6.93748 11.0625 7.04998L10.3875 7.49998C9.375 5.99998 9.7125 3.97498 11.2125 2.96248C11.3625 2.84998 11.55 2.73748 11.7375 2.66248L12.075 3.41248ZM13.5 2.36248V5.84998L15.7125 7.53748L15.225 8.17498L12.8625 6.37498L12.7125 6.26248V2.36248H13.5ZM13.0875 0.712476C15.7875 0.712476 18 2.92498 18 5.62498C18 7.38748 17.0625 8.99998 15.525 9.89998V13.5L15.3375 13.6125L11.6625 15.75L7.9875 17.8875L7.8 18L7.6125 17.8875L3.9375 15.75L0.2625 13.6125L0 13.5V4.53748L0.225 4.42498L3.9 2.28748L7.575 0.149976L7.7625 0.0374756L7.95 0.149976L10.35 1.53748C11.175 1.01248 12.15 0.712476 13.0875 0.712476ZM14.7375 10.275C12.75 10.9875 10.5 10.3125 9.225 8.66248L8.2125 9.26248V16.8L11.2875 15.0375L14.775 13.05V10.275H14.7375ZM8.775 7.94998C7.7625 6.03748 8.1 3.67498 9.6375 2.17498L9.7125 2.09998L7.8 0.974976L4.3125 2.99998L1.2375 4.76248L7.8 8.54998C7.7625 8.51248 8.775 7.94998 8.775 7.94998ZM15.975 2.73748C14.3625 1.12498 11.775 1.12498 10.2 2.73748C8.625 4.34998 8.5875 6.93748 10.2 8.51248C11.8125 10.0875 14.4 10.125 15.975 8.51248C16.725 7.76248 17.175 6.71248 17.175 5.62498C17.175 4.57498 16.7625 3.52498 15.975 2.73748Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1604_4331">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ItemsToLoad
