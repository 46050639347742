import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type BillingParams = {
  id: number
}

export function doesAuthorizationDocumentExist(params: BillingParams): Promise<string> {
  const { id } = params

  const url = `${URL_BASE_TRANSACTIONS}/authorization-document?format=pdf&billing_id=${id}&source=backoffice`

  return apiPrivate
    .get(url, { params: { country_code: undefined } })
    .then(() => {
      return url
    })
    .catch((err) => {
      throw err
    })
}
