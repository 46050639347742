import { CamelCasedPropertiesDeep } from 'type-fest'
import { Item as DepositAPIItem } from '../../../projectApi/TransactionHandler/Operation/common'
import { ObjectsOperation as DepositAPIObject } from '../../../projectApi/TransactionHandler/DepositsOperations/getById'
import { Moment } from 'moment'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'

export type SingleDepositItem = CamelCasedPropertiesDeep<DepositAPIItem>
export type SingleDepositObject = CamelCasedPropertiesDeep<DepositAPIObject>

export type DepositExtras = {
  kmsExtra: number
  kmsExtraPrice: number
  transportExtra: number
  shipmentAssistantCount: number
  shipmentAssistantPrice: number
}

export type SingleDepositState = {
  operationCountryCode: CountryIdCode
  items: SingleDepositItem[]
  objects: SingleDepositObject[]
  extras: DepositExtras
  itemsM3: number
  transportCost: {
    real: number
    remittance: number
    tollCostInCents: number
  }
  legacyAdditionalCost: number
  baseInfo: {
    status: string
    fullAddress: string
    transactionId: number
    id: number
    user: {
      id: number
      name: string
      lastName: string
    }
    createdAt: Moment | null
    datetime: Moment | null
  }
  discountCode: string
  billings: Billing[]
  loading: boolean
  error: string
}

export const FETCH_SINGLE_DEPOSIT_DATA_REQUEST = 'SINGLE_DEPOSIT/FETCH_SINGLE_DEPOSIT_DATA_REQUEST'
export const FETCH_SINGLE_DEPOSIT_DATA_SUCCESS = 'SINGLE_DEPOSIT/FETCH_SINGLE_DEPOSIT_DATA_SUCCESS'
export const FETCH_SINGLE_DEPOSIT_DATA_FAILURE = 'SINGLE_DEPOSIT/FETCH_SINGLE_DEPOSIT_DATA_FAILURE'

export interface FetchSingleDepositDataRequest {
  type: typeof FETCH_SINGLE_DEPOSIT_DATA_REQUEST
}

export interface FetchSingleDepositDataSuccess {
  type: typeof FETCH_SINGLE_DEPOSIT_DATA_SUCCESS
  payload: {
    operationCountryCode: CountryIdCode
    items: SingleDepositItem[]
    objects: SingleDepositObject[]
    extras: DepositExtras
    transportCost: {
      real: number
      remittance: number
      tollCostInCents: number
    }
    legacyAdditionalCost: number
    baseInfo: {
      status: string
      fullAddress: string
      transactionId: number
      id: number
      user: {
        id: number
        name: string
        lastName: string
      }
      createdAt: Moment | null
      datetime: Moment | null
    }
    discountCode: string
    billings: Billing[]
    itemsM3: number
  }
}

export interface FetchSingleDepositDataFailure {
  type: typeof FETCH_SINGLE_DEPOSIT_DATA_FAILURE
  payload: {
    error: string
  }
}

export type FetchSingleDepositDataAction =
  | FetchSingleDepositDataRequest
  | FetchSingleDepositDataSuccess
  | FetchSingleDepositDataFailure

export type SingleDepositAction = FetchSingleDepositDataAction
