import { Modal, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio'
import React, { useState } from 'react'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { Reasons } from '../../../projectApi/Sales/reasons'
import styles from './SalesRequestView.module.scss'

export interface ModalProps {
  isOpen: boolean
  setModalClosed: () => void
  handleRejectSaleRequest: ({
    id,
    reason,
    saleCost,
    saleMargin
  }: {
    id: number
    reason: string
    saleCost: number
    saleMargin: number
  }) => void
  reasons: Reasons[]
  loadingReasons: boolean
  SALE_COST: number
  SALE_MARGIN: number
}

export const ModalReject: React.FC<ModalProps> = ({
  isOpen,
  setModalClosed,
  handleRejectSaleRequest,
  reasons,
  loadingReasons,
  SALE_COST,
  SALE_MARGIN
}) => {
  const [reasonValue, setReasonValue] = useState(11)

  const handleChange = (e: RadioChangeEvent) => {
    setReasonValue(e.target.value)
  }

  const handleOk = (id: number) => {
    const found = reasons.find((x) => x.id === id)
    if (!found) return

    handleRejectSaleRequest({ id: found.id, reason: found.text, saleCost: SALE_COST, saleMargin: SALE_MARGIN })
  }
  return (
    <Modal
      style={{ height: '200px' }}
      visible={isOpen}
      onCancel={setModalClosed}
      onOk={() => handleOk(reasonValue)}
      title="¿Por qué motivo rechaza la Solicitud?">
      <Radio.Group className={styles.radioGroup} onChange={(e) => handleChange(e)} value={reasonValue}>
        {loadingReasons ? (
          <LoadingIndicator size="small" />
        ) : (
          reasons.map((reason) => (
            <Radio value={reason.id} key={reason.id}>
              {reason.text}
            </Radio>
          ))
        )}
      </Radio.Group>
    </Modal>
  )
}
