import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { GetProductByIdActions } from './GeneralData'

export type AllowedCountriesCodes = CountryIdCode.ARGENTINA | CountryIdCode.SPAIN

export type ProductCostsState = {
  [CountryIdCode.ARGENTINA]: ProductByCountriesInformation
  [CountryIdCode.SPAIN]: ProductByCountriesInformation
}

export enum CostFieldsNameEnum {
  PRODUCT_NAME = 'productName',
  DEPOSIT_COST_PER_FLOOR = 'depositCostPerFloor',
  DEPOSIT_PACKAGING_COST = 'depositPackagingCost',
  DEPOSIT_DISASSEMBLY_COST = 'depositDisassemblyCost',
  REMOVAL_COST_PER_FLOOR = 'removalCostPerFloor',
  REMOVAL_UNPACKAGING_COST = 'removalUnpackagingCost',
  REMOVAL_ASSEMBLY_COST = 'removalAssemblyCost',
  MAX_AMOUNT_AVOWED = 'maxAmountAvowed',
  PERCETAGE_ON_DECLARED_PRICE = 'percentageOnDeclaredPrice',
  MAX_REFUND_VALUE = 'maxRefundValue'
}

export type ProductByCountriesInformation = {
  productName: string
  depositCostPerFloor: string
  depositPackagingCost: string
  depositDisassemblyCost: string
  removalCostPerFloor: string
  removalUnpackagingCost: string
  removalAssemblyCost: string
  maxAmountAvowed: string
  percentageOnDeclaredPrice: string
  maxRefundValue: string
  isRemovalAutomaticPrice: boolean
  isDepositAutomaticPrice: boolean
  eligibility: EligibilityTypes
  depositAutomaticPrice: number
  removalAutomaticPrice: number
  photoUrl: string
  photoKey: string
  error?: string
}

export type AutomaticPriceOperationType = Pick<
  ProductByCountriesInformation,
  'removalAutomaticPrice' | 'depositAutomaticPrice'
>

export type EligibilityTypes = {
  isElegibleForSale: boolean
  isElegibleForDonation: boolean
  isElegibleForRecycled: boolean
  isElegibleForRent: boolean
}

export enum EligibilityFields {
  ELEGIBLE_FOR_SALE = 'isElegibleForSale',
  ELEGIBLE_FOR_DONATION = 'isElegibleForDonation',
  ELEGIBLE_FOR_RECYCLED = 'isElegibleForRecycled',
  ELEGIBLE_FOR_RENT = 'isElegibleForRent'
}

export const SET_STRING_FIELD = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_STRING_FIELD'
export const SET_BOOLEAN_FIELD = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_BOOLEAN_FIELD'

export interface SetStringField {
  type: typeof SET_STRING_FIELD
  payload: {
    country: AllowedCountriesCodes
    value: string
    field: keyof ProductByCountriesInformation
  }
}

export interface SetBooleanField {
  type: typeof SET_BOOLEAN_FIELD
  payload: {
    country: AllowedCountriesCodes
    value: boolean
    field: keyof ProductByCountriesInformation
  }
}

export const SET_PRODUCT_ELIGIBILITY = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_PRODUCT_ELIGIBILITY'

export interface SetProductEligibility {
  type: typeof SET_PRODUCT_ELIGIBILITY
  payload: {
    value: boolean
    field: EligibilityFields
    country: AllowedCountriesCodes
  }
}

export const GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST'
export const GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS'
export const GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE'

export interface GetAutomaticPricePerFloorRequest {
  type: typeof GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST
}

export interface GetAutomaticPricePerFloorSuccess {
  type: typeof GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS
  payload: {
    automaticPrice: number
    countryId: AllowedCountriesCodes
    operationType: keyof AutomaticPriceOperationType
  }
}

export interface GetAutomaticPricePerFloorFailure {
  type: typeof GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE
  payload: { error: string }
}

export type GetAutomaticPricePerFloorAction =
  | GetAutomaticPricePerFloorRequest
  | GetAutomaticPricePerFloorSuccess
  | GetAutomaticPricePerFloorFailure

export const PRODUCT_COST_CLEAN_STATE = 'PRODUCTS/CREATE_NEW_PRODUCT/PRODUCT_COST_CLEAN_STATE'

export interface ProductCostCleanState {
  type: typeof PRODUCT_COST_CLEAN_STATE
}

export type CostsAction =
  | SetStringField
  | SetBooleanField
  | SetProductEligibility
  | GetAutomaticPricePerFloorAction
  | ProductCostCleanState
  | GetProductByIdActions
