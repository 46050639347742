import { Reducer } from 'redux'
import { GetUsersMetricsTypes, UsersMetricsActions, UsersMetricsState } from '../types/usersMetrics'

const initialState: UsersMetricsState = {
  usersMetrics: null,
  fetchingMetrics: false
}

const UsersMetricsReducer: Reducer<UsersMetricsState, UsersMetricsActions> = (
  state = initialState,
  action
): UsersMetricsState => {
  switch (action.type) {
    case GetUsersMetricsTypes.GET_USERS_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetUsersMetricsTypes.GET_USERS_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        usersMetrics: action.payload.usersMetrics
      }
    case GetUsersMetricsTypes.GET_USERS_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default UsersMetricsReducer
