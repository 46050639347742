/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { convertToMoment } from '../../utils'

enum RemittanceStatus {
  PROCESSED = 'PROCESSED',
  PENDING = 'PENDING'
}

interface APIRemittancePhoto {
  id: number
  url: string
}

interface APIRemittance {
  id: number
  created_at: string
  photo: APIRemittancePhoto
  photos: APIRemittancePhoto[]
  quantity_items: number
  quantity_items_on_paper: number
  reasons: string[] | null
  status: RemittanceStatus
  transaction_id: number
  updated_at: string
}

interface APIResponse {
  paging: {
    limit: number
    offset: number
    total: number
  }
  results: APIRemittance[]
}

export type RemittancePhoto = CamelCasedPropertiesDeep<APIRemittancePhoto>

export type Remittance = {
  id: number
  createdAt: Moment | null
  photo: RemittancePhoto
  photos: RemittancePhoto[]
  quantityItems: number
  quantityItemsOnPaper: number
  reasons: string[] | null
  status: RemittanceStatus
  transactionId: number
  updatedAt: Moment | null
}

type Response = {
  total: number
  remittances: Remittance[]
}

interface Params {
  transactionId: number
}

export function search({ transactionId }: Params): Promise<Response> {
  const params = snakecaseKeys({ transactionId })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/remittances/search`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.results) throw new Error('Respuesta incorrecta al obtener los remitos')

      return {
        total: response.data.paging.total,
        remittances: camelcaseKeys(response.data.results, { deep: true }).map((camelRemittance) => ({
          ...camelRemittance,
          createdAt: convertToMoment(camelRemittance.createdAt),
          updatedAt: convertToMoment(camelRemittance.updatedAt)
        }))
      }
    })
    .catch((err) => {
      throw err
    })
}
