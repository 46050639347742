import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { ErrorMessageResponse } from '../../AccountManager/Satellite/create'
import { AxiosError } from 'axios'

type CreateServiceParams = {
  id: string
  type: string
  multiplier: number
  displayOrder: number
  deposits: number[]
  operationType: string
  solutionId: number
}

export async function create(params: CreateServiceParams): Promise<void> {
  const parsedParams = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .post(`${URL_BASE_CATEGORIES}/service-types`, parsedParams)
    .then(() => {})
    .catch((error: AxiosError<ErrorMessageResponse>) => {
      throw error
    })
}
