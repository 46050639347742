import React from 'react'

const ImagePlaceholder = () => {
  return (
    <svg width="114" height="103" viewBox="0 0 114 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0079 99.5035L50.959 63.2225C53.1173 61.0444 54.1965 59.9554 55.4409 59.5474C56.5355 59.1884 57.7145 59.1884 58.8091 59.5474C60.0535 59.9554 61.1327 61.0445 63.291 63.2225L99.0023 99.2615M68.025 68L83.659 52.2225C85.8173 50.0444 86.8965 48.9554 88.1409 48.5474C89.2354 48.1884 90.4145 48.1884 91.5091 48.5474C92.7535 48.9554 93.8327 50.0444 95.991 52.2225L111.625 68M46.225 35C46.225 41.0751 41.3449 46 35.325 46C29.3051 46 24.425 41.0751 24.425 35C24.425 28.9249 29.3051 24 35.325 24C41.3449 24 46.225 28.9249 46.225 35ZM28.785 101H85.465C94.6219 101 99.2003 101 102.698 99.2016C105.774 97.6197 108.275 95.0955 109.843 91.9908C111.625 88.4613 111.625 83.8409 111.625 74.6V28.4C111.625 19.1591 111.625 14.5387 109.843 11.0092C108.275 7.90448 105.774 5.3803 102.698 3.79839C99.2003 2 94.6219 2 85.465 2H28.785C19.6281 2 15.0497 2 11.5523 3.79839C8.47581 5.3803 5.97457 7.90448 4.40704 11.0092C2.625 14.5387 2.625 19.1591 2.625 28.4V74.6C2.625 83.8409 2.625 88.4613 4.40704 91.9908C5.97457 95.0955 8.47581 97.6197 11.5523 99.2016C15.0497 101 19.6281 101 28.785 101Z"
        stroke="#6C717A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ImagePlaceholder
