export const states = [
  {
    text: 'Depositado',
    value: 1
  },
  {
    text: 'Alquilado',
    value: 2
  },
  {
    text: 'En Propietario',
    value: 3
  },
  {
    text: 'A Catalogar',
    value: 4
  },
  {
    text: 'A Fotografiar',
    value: 5
  },
  {
    text: 'En Tránsito',
    value: 6
  },
  {
    text: 'A Ubicar',
    value: 7
  },
  {
    text: 'Buscando',
    value: 8
  },
  {
    text: 'Encontrado',
    value: 9
  },
  {
    text: 'No Encontrado',
    value: 10
  },
  {
    text: 'A Donar',
    value: 11
  }
]

export enum ObjectStatusLabels {
  DEPOSITED = 'Depositado',
  RENTED = 'Alquilado',
  WITH_OWNER = 'En Propietario',
  IN_TRIP = 'En Tránsito',
  TO_CATEGORIZE = 'A Catalogar',
  TO_PHOTOGRAPH = 'A Fotografiar',
  TO_LOCATE = 'A Ubicar',
  WITH_PRICING = 'Con Abono',
  SEARCHING = 'Buscando',
  FOUND = 'Encontrado',
  NOT_FOUND = 'No Encontrado',
  DELETED = 'Eliminado'
}

export type ObjectStatusKeys = keyof typeof ObjectStatusLabels
