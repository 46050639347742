import moment from 'moment'
import { clone } from 'ramda'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  FETCH_SINGLE_DEPOSIT_DATA_FAILURE,
  FETCH_SINGLE_DEPOSIT_DATA_REQUEST,
  FETCH_SINGLE_DEPOSIT_DATA_SUCCESS,
  FetchSingleDepositDataRequest,
  FetchSingleDepositDataSuccess
} from '../types/singleDeposit'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const singleDepositsActionCreators = {
  fetchSingleDepositData:
    ({ depositId }: { depositId: number }): AppThunk =>
    async (dispatch) => {
      const request: FetchSingleDepositDataRequest = {
        type: FETCH_SINGLE_DEPOSIT_DATA_REQUEST
      }

      dispatch(request)

      try {
        const depositsOperationsResponse = await API.TransactionHandler.DepositsOperations.getById({ id: depositId })
        if (!depositsOperationsResponse) throw new Error('Falló la búsqueda en DepositsOperation')
        const countryCode: CountryIdCode = depositsOperationsResponse.operation.countryId as CountryIdCode
        const operationResponse = await API.TransactionHandler.Operation.getById({ id: depositId, countryCode })

        if (!operationResponse) throw new Error('Falló la búsqueda en Operation')

        const {
          operation: {
            objectsOperation,
            discountCode,
            transportCost: realTransportCost,
            status,
            additionalCost,
            operationTransportExtras,
            tollCostInCents
          }
        } = depositsOperationsResponse
        const {
          transactionId,
          id: operationId,
          user,
          createdAt,
          datetime,
          items,
          cubicM,
          fullAddressString,
          transportCost: remittanceTransportCost
        } = operationResponse

        const { billings } = await API.TransactionHandler.Billings.getBillingByTransaction({
          transactionId,
          order: 'id',
          orderType: SortDirection.DESC
        })

        const success: FetchSingleDepositDataSuccess = {
          type: FETCH_SINGLE_DEPOSIT_DATA_SUCCESS,
          payload: {
            operationCountryCode: countryCode,
            items: clone(items).sort((a, b) => b.amount - a.amount),
            objects: clone(objectsOperation).sort((a, b) => a.object.product.id - b.object.product.id),
            transportCost: {
              real: realTransportCost,
              remittance: remittanceTransportCost,
              tollCostInCents
            },
            legacyAdditionalCost: additionalCost,
            billings,
            itemsM3: cubicM,
            discountCode,
            baseInfo: {
              status,
              fullAddress: fullAddressString,
              transactionId,
              id: operationId,
              user,
              createdAt: moment(createdAt).isValid() ? moment(createdAt) : null,
              datetime: moment(datetime).isValid() ? moment(datetime) : null
            },
            extras: operationTransportExtras
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, FETCH_SINGLE_DEPOSIT_DATA_FAILURE, dispatch)
      }
    }
}

export default singleDepositsActionCreators
