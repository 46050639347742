import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AMDiscountModal from '../components/Modales/DiscountDates/AMDiscountDate'
import createDiscountDatesActionsCreator from '../actions/ABMDiscountDates'
import DeleteDiscountModal from '../components/Modales/DiscountDates/DeleteDiscountDate'

const CreateDiscountDatesContainer: React.FC = () => {
  const dispatch = useDispatch()

  const {
    discountRate,
    selectedDate,
    modalVisible,
    creatingDiscount,
    updatingDiscount,
    deletingDiscount,
    editMode,
    selectedDiscount,
    deleteModalVisible
  } = useSelector((state) => state.Discount.createDiscountDatesReducer)

  const {
    setDiscountDate,
    setDiscountRate,
    setErrorDiscountRate,
    setModalVisibility,
    createDiscount,
    updateDiscount,
    setDeleteModalVisibility,
    deleteDiscount
  } = bindActionCreators(createDiscountDatesActionsCreator, dispatch)

  const onChangeDiscountRate = (discountRate: string) => {
    setErrorDiscountRate('')
    setDiscountRate(discountRate)
  }

  const handleConfirmButton = () => {
    if (!selectedDate || !discountRate.value) return

    if (editMode) {
      updateDiscount({
        discountId: selectedDiscount?.id ?? 0,
        date: selectedDate,
        discountRate: discountRate.value,
        callback: () => setModalVisibility({ modalVisible: false })
      })
    } else {
      createDiscount({
        date: selectedDate,
        discountRate: discountRate.value,
        callback: () => setModalVisibility({ modalVisible: false })
      })
    }
  }

  const disabledCreateButton = selectedDate === null || Boolean(discountRate.error)
  const loadingCreateButton = creatingDiscount || updatingDiscount

  const modalProps = {
    editMode,
    selectedDiscount,
    discountRate,
    selectedDate,
    modalVisible,
    loadingCreateButton,
    disabledCreateButton,
    setModalVisibility,
    onChangeDiscountRate,
    handleConfirmButton,
    setDiscountDate
  }

  const handleDeleteDiscount = () => {
    deleteDiscount({
      discountId: selectedDiscount?.id ?? 0,
      callback: () => setDeleteModalVisibility(false)
    })
  }

  const deleteModalProps = {
    selectedDiscount,
    deletingDiscount,
    deleteModalVisible,
    setDeleteModalVisibility,
    handleDeleteDiscount
  }

  return (
    <>
      <AMDiscountModal {...modalProps} />
      <DeleteDiscountModal {...deleteModalProps} />
    </>
  )
}

export default CreateDiscountDatesContainer
