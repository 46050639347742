import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'

export interface Flag {
  iD: number
  name: string
  value: string
}

interface Description {
  keys: Flag[]
  quantity: number
}

interface APIResponse {
  description: Description
  success: string
}

interface Response {
  flags: Flag[]
}

interface ListFlagsParams {
  limit?: number
  offset?: number
  column?: string
  order?: 'asc' | 'desc'
}

export function listFlags(params: ListFlagsParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/config/key/`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const { keys } = response.data.description

      const flags = camelcaseKeys(keys, { deep: true })

      return { flags }
    })
    .catch((error) => {
      throw error
    })
}
