import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import { ToastContainer } from 'react-toastify'
import { parse, stringify } from 'query-string'

// Styles
import 'font-awesome/css/font-awesome.min.css'
import 'simple-line-icons/css/simple-line-icons.css'
import './utils/notifications/ReactToastify.css'
import './App.scss'

// Containers
import Full from './sections/Full/Full'
import Login from './sections/Login'
import { PersistGate } from 'redux-persist/integration/react'
import LoadingComponent from './sections/Full/LoadingComponent'
import { QueryParamProvider } from 'use-query-params'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './projectApi/BFF/apolloClient'

// TODO remove "react/no-deprecated": "warn" from .eslintrc.json when migrate to React 18

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingComponent description="Cargando, por favor espere..." />} persistor={persistor}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify
            }}>
            <Switch>
              <Route path="/login" name="Login" component={Login} />
              <Route path="/" name="Home" component={Full} />
            </Switch>
          </QueryParamProvider>
        </BrowserRouter>
      </ApolloProvider>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
