import React, { NamedExoticComponent } from 'react'
import styles from './OccupancyCard.module.scss'
import { Spin, Tooltip } from 'antd'
import { Info } from '../../icons'

interface OccupancyCardCompound {
  Container: typeof Container
}

interface BaseOccupancyCardProps {
  color?: 'green' | 'orange' | 'red' | 'spaceguru'
  info: {
    leftContainer: OccupancyInfo
    rightContainer: OccupancyInfo
  }
  fetchingMetrics: boolean
}

interface OccupancyInfo {
  title: string
  value: string
  percentage: string
  percentageRaw?: number
  description?: string
}

interface ContainerProps {
  color?: 'green' | 'orange' | 'red' | 'spaceguru'
  info: OccupancyInfo
  variant: 'left' | 'right'
}

const BaseOccupancyCard: React.FC<BaseOccupancyCardProps> = ({ fetchingMetrics, info, color = 'green' }) => {
  return (
    <section className={`${styles.card} ${styles[color]}`}>
      {fetchingMetrics ? (
        <div className={styles.spinContainer}>
          <Spin size="default" />
        </div>
      ) : (
        <>
          <Container variant="left" color={color} info={info.leftContainer} />
          <Container variant="right" color={color} info={info.rightContainer} />
        </>
      )}
    </section>
  )
}

const Container: React.FC<ContainerProps> = ({ info, color = 'green', variant }) => {
  const containerClass = `${styles.container} ${styles[variant]} ${styles[color]} ${
    variant === 'left' ? styles.leftContainer : styles.rightContainer
  }`

  const { title, value, percentage, description } = info

  return (
    <article className={containerClass}>
      <>
        <p>{title}</p>
        <h3>{value}</h3>
        <footer>
          <p>{percentage}</p>
          {variant === 'right' && description ? (
            <Tooltip placement="bottom" title={description}>
              <span className={styles.tooltip}>
                <Info fill={`var(--secondary-color-${color})`} />
              </span>
            </Tooltip>
          ) : null}
        </footer>
      </>
    </article>
  )
}

const MemoizedOccupancyCard = React.memo(BaseOccupancyCard) as NamedExoticComponent<BaseOccupancyCardProps> &
  OccupancyCardCompound

MemoizedOccupancyCard.Container = Container

export const OccupancyCard: NamedExoticComponent<BaseOccupancyCardProps> & OccupancyCardCompound = MemoizedOccupancyCard
