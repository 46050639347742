import { Reducer } from 'redux'
import {
  DevelopmentCommonTypes,
  CreateGenericObjectLabelsTypes,
  DevelopmentViewAction,
  DevelopmentViewState,
  FetchCodeGenTypes,
  GetUsersTypes
} from '../types/developmentView'

const initialState: DevelopmentViewState = {
  objectLabel: {
    codeGEN: '',
    users: [],
    isLoadingUsers: false,
    objectLabelModal: false
  },
  error: '',
  isLoading: false
}

const DevelopmentViewReducer: Reducer<DevelopmentViewState, DevelopmentViewAction> = (
  state: DevelopmentViewState = initialState,
  action: DevelopmentViewAction
): DevelopmentViewState => {
  switch (action.type) {
    case DevelopmentCommonTypes.CLEAR_STATE:
      return initialState
    case DevelopmentCommonTypes.OBJECT_LABEL_MODAL:
      return {
        ...initialState,
        objectLabel: {
          ...initialState.objectLabel,
          objectLabelModal: action.payload.objectLabelModal
        }
      }
    case CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FetchCodeGenTypes.FETCH_CODE_GEN_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FetchCodeGenTypes.FETCH_CODE_GEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        objectLabel: {
          ...state.objectLabel,
          codeGEN: action.payload.code
        }
      }
    case FetchCodeGenTypes.FETCH_CODE_GEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GetUsersTypes.GET_USERS_REQUEST:
      return {
        ...state,
        objectLabel: {
          ...state.objectLabel,
          isLoadingUsers: true
        }
      }
    case GetUsersTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        objectLabel: {
          ...state.objectLabel,
          isLoadingUsers: false,
          users: action.payload.users
        }
      }
    case GetUsersTypes.GET_USERS_FAILURE:
      return {
        ...state,
        objectLabel: {
          ...state.objectLabel,
          isLoadingUsers: false
        },
        error: action.payload.error
      }
    default:
      return state
  }
}

export default DevelopmentViewReducer
