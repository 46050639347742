import { Reducer } from 'redux'
import { STEP_OPTION } from '../constants'
import {
  ActionsModalState,
  SET_MODAL_OPEN,
  SET_OPTION_SELECTED,
  StepModalActions,
  UPDATED_LEAD,
  UPDATED_LEAD_FAILURE,
  UPDATED_LEAD_SUCCESS
} from '../types/ActionsModal'

const initialState: ActionsModalState = {
  openModal: false,
  loading: false,
  error: '',
  optionSelected: STEP_OPTION.NOT_SELECTED,
  leadId: 0,
  leadFunnelState: '',
  interestReasonId: 0
}

const ActionsModalReducer: Reducer<ActionsModalState, StepModalActions> = (
  state = initialState,
  action
): ActionsModalState => {
  switch (action.type) {
    case SET_MODAL_OPEN: {
      const { openModal, leadId, leadFunnelState, interestReasonId } = action.payload
      return openModal
        ? {
            ...state,
            openModal,
            leadId: leadId ?? initialState.leadId,
            leadFunnelState: leadFunnelState ?? initialState.leadFunnelState,
            interestReasonId: interestReasonId ?? initialState.interestReasonId
          }
        : initialState
    }
    case SET_OPTION_SELECTED: {
      const { option } = action.payload

      if (option === STEP_OPTION.DISCARD) {
        return {
          ...state,
          optionSelected: option
        }
      }
      if (option === STEP_OPTION.INTEREST) {
        return {
          ...state,
          optionSelected: option
        }
      } else {
        return {
          ...state,
          optionSelected: option
        }
      }
    }
    case UPDATED_LEAD:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case UPDATED_LEAD_SUCCESS:
      return initialState
    case UPDATED_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      }
    default:
      return state
  }
}

export default ActionsModalReducer
