import React from 'react'
import { Button, Table } from 'antd'
import moment from '../../../moment'
import { Buffer } from 'buffer'
import { formatMoney } from '../../../utils/formatNumber'
import { getColumnSearchProps } from '../../../utils/columnSearchProps'
import MassBillingModal from '../containers/massBillingModal'
import CalculateBillingModal from '../containers/calculateBillingModal'
import { usePaginationLabel } from '../../../utils/hooks/usePaginationLabel'
import FilesModal from '../containers/filesModal'
import { TableActionBar } from '../../../components/TableActionBar'
import styles from './Table.module.scss'

const BillingTable = (props) => {
  const {
    billings,
    loadingBillings,
    handleChange,
    handleSearch,
    searchFilters,
    billingMassActions,
    billingCalculateActions,
    loadingPreBillingReport,
    lastPreBillingReportDate,
    role,
    pagination,
    params,
    cleanFilters,
    getBillingsFromStore,
    preBillingReportActions,
    openFilesModal
  } = props
  const { history } = props

  const formatDate = (d) => moment(d).format('DD/MM/YYYY')

  const handleByChangeUrl = (userId, name, lastName) => {
    const uriEncode = Buffer.from(escape(`userId=${userId}&name=${name}&lastName=${lastName}`)).toString('base64')
    history.push('billingByClient?' + uriEncode)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'ID Cliente',
      width: 100,
      dataIndex: 'userId',
      ...getColumnSearchProps('user_id', 'ID Cliente', handleSearch, searchFilters)
    },
    {
      title: 'Nombre',
      dataIndex: 'userName'
    },
    {
      title: 'Apellido',
      dataIndex: 'userLastName'
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'firstBillingStart',
      render: (n) => formatDate(n)
    },
    {
      title: (
        <span>
          m<sup>3</sup>
        </span>
      ),
      dataIndex: 'm3'
    },
    {
      title: 'Abono',
      dataIndex: 'lastBillingAmount',
      render: (n) => formatMoney(n)
    },
    {
      title: 'Acciones',
      render: (_, row) => (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            handleByChangeUrl(row.userId, row.userName, row.userLastName)
          }}>
          Ver mas
        </Button>
      )
    }
  ]

  const handleCleanFilters = () => {
    cleanFilters()
    getBillingsFromStore()
  }

  const handlePreBillingReport = () => {
    preBillingReportActions.sendPreBillingReport(lastPreBillingReportDate)
  }

  const showTotal = usePaginationLabel(pagination.total, 'clientes con factura')

  const billingPagination = {
    pageSize: params.limit,
    total: pagination.total,
    current: pagination.current,
    size: 'small',
    style: {
      margin: '12px 0'
    },
    showTotal
  }

  const importCSVTip = 'Se utiliza para cargar pagos realizados vía transferencias y/o CBU'

  return (
    <div className="animation fadeIn">
      <TableActionBar hideBorder>
        {(role?.name === 'Root' || role?.name === 'Administrador') && (
          <>
            <TableActionBar.Wrapper>
              <TableActionBar.ButtonAction
                toolTip
                toolTipCustomMessage={'Generará un reporte con los abonos de todos los usuarios de los últimos 60 días'}
                variant="csv"
                onClickButton={handlePreBillingReport}
              />

              <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />
            </TableActionBar.Wrapper>

            <TableActionBar.Wrapper>
              {role?.name === 'Root' && (
                <>
                  <TableActionBar.ButtonAction
                    iconType="calculator"
                    type="primary"
                    titleButton="Calcular Abonos"
                    onClickButton={(e) => {
                      e.stopPropagation()
                      billingCalculateActions.openCalculateBillingModal()
                    }}
                  />
                  <TableActionBar.ButtonAction
                    iconType="file"
                    type="primary"
                    titleButton="Facturar"
                    onClickButton={(e) => {
                      e.stopPropagation()
                      billingMassActions.openMassBillingModal()
                    }}
                  />
                </>
              )}
              <TableActionBar.ButtonAction
                toolTip
                toolTipCustomMessage={importCSVTip}
                iconType="upload"
                titleButton="Importar CSV"
                onClickButton={() => openFilesModal({ modalOpen: true })}
              />
            </TableActionBar.Wrapper>
          </>
        )}
      </TableActionBar>

      <Table
        columns={columns}
        dataSource={billings}
        loading={loadingBillings}
        pagination={billingPagination}
        style={{ overscrollX: 'auto' }}
        scroll={{ x: true }}
        onChange={handleChange}
        rowKey="id"
        className={styles.customTableProps}
      />
      <MassBillingModal />
      <FilesModal />
      <CalculateBillingModal />
    </div>
  )
}

export default BillingTable
