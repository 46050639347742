/* eslint-disable camelcase */
import axios, { AxiosError } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { Moment } from 'moment'
import { formatDates } from '../../../utils'

interface APIError {
  message: string
  error: string
  status: number
  cause: string | null
}

export interface EditDateServiceParams {
  newDateService: Moment
  reason: string
}

export function edit(operationId: number, { newDateService, reason }: EditDateServiceParams): Promise<void> {
  const params = snakecaseKeys({
    ...formatDates({ dates: { newDateService }, format: 'YYYY-MM-DDTHH:mm:ssZ' }),
    reason
  })

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/operations/date-service/${operationId}`, params)
    .then(() => {})
    .catch((error: AxiosError<APIError> | Error) => {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data.message
        throw errorMessage
      } else throw error
    })
}
