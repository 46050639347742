import React from 'react'
import UserIcon from './UserIcon'
import EyeIcon from './EyeIcon'
import EyePupilIcon from './EyePupilIcon'
import CheckCircle from './CheckCircle'
export type InputIconName = 'user' | 'eye' | 'eye-pupil' | 'check-circle'

interface InputIconProps {
  name: InputIconName
}
export const InputIcon: React.FC<InputIconProps> = ({ name, ...props }) => {
  switch (name) {
    case 'user':
      return <UserIcon />
    case 'eye':
      return <EyeIcon />
    case 'eye-pupil':
      return <EyePupilIcon />
    case 'check-circle':
      return <CheckCircle />
    default:
      return null
  }
}
