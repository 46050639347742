import React from 'react'
import styles from './CatalogGrid.module.scss'
import { ProductCatalogue } from '@/projectApi/CategoryCreation/Catalog/searchAll'
import { Button } from '@/components/Button/Button'

export const CatalogGrid = ({
  products,
  handleGetProductByDescription,
  isFetching
}: {
  products: ProductCatalogue[]
  handleGetProductByDescription: ({ description }: { description: string }) => void
  isFetching: boolean
}) => {
  return (
    <>
      <div className={`${styles.container} animated fadeIn`}>
        {isFetching ? (
          <>
            <CatalogProductSkeleton />
            <CatalogProductSkeleton />
            <CatalogProductSkeleton />
          </>
        ) : (
          products.map((product, index) => (
            <CatalogCard
              key={index}
              product={product}
              onSelect={() => handleGetProductByDescription({ description: product.productCatalogId })}
            />
          ))
        )}
      </div>
    </>
  )
}

const CatalogCard = ({ product, onSelect }: { product: ProductCatalogue; onSelect: () => void }) => {
  return (
    <div className={styles.cardContainer}>
      <img src={product.productByCountries[0].photo.url} alt="product" className={styles.productImage} />

      <div className={styles.productInfoContainer}>
        <h3 className={styles.title}>{product.description}</h3>
        <div className={styles.buttonContainer}>
          <Button label="Ver producto" className={styles.button} rightIcon="next" onClick={onSelect} />
        </div>
      </div>
    </div>
  )
}

const CatalogProductSkeleton = () => {
  return (
    <article
      style={{
        width: '100%',
        height: '400px',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#e0e0e0'
      }}>
      <svg
        role="img"
        width="100%"
        height="100%"
        fill="red"
        aria-labelledby="loading-aria"
        viewBox="0 0 400 400"
        preserveAspectRatio="none">
        <title id="loading-aria">Loading...</title>
        <rect x="0" y="0" width="100%" height="100%" clipPath="url(#clip-path)" style={{ fill: 'url(#fill)' }}></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="40" y="20" width="80%" height="230" />
            <rect x="40" y="260" rx={4} ry={4} width="70%" height="20" />
            <rect x="40" y="290" rx={4} ry={4} width="60%" height="20" />
            <rect x="200" y="340" rx={4} ry={4} width="150px" height="40" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0%" stopColor="#cccccc">
              <animate attributeName="offset" values="-2; 1" keyTimes="0; 1" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="50%" stopColor="#dddddd">
              <animate attributeName="offset" values="-1.5; 1.5" keyTimes="0; 1" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#cccccc">
              <animate attributeName="offset" values="-1; 2" keyTimes="0; 1" dur="2s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </article>
  )
}
