import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDates/list'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  SetDiscountDate,
  ModalCreateDiscountDateTypes,
  CreateDiscountDateRequest,
  CreateDiscountDateTypes,
  SetModalVisibility,
  SetSelectedDiscount,
  UpdateDiscountDateTypes,
  UpdateDiscountDateRequest,
  SetDeleteModalVisibility,
  DeleteDiscountDateRequest,
  DeleteDiscountDateTypes
} from '../types/ABMDiscountDates'
import moment, { Moment } from 'moment'

const createDiscountDatesActionsCreator = {
  createDiscount({
    date,
    discountRate,
    callback
  }: {
    date: Moment
    discountRate: number
    callback?: () => void
  }): AppThunk {
    return async (dispatch) => {
      const request: CreateDiscountDateRequest = {
        type: CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Creando descuento, por favor espere...')
      try {
        const formatedDate = moment(date).format('YYYY-MM-DD')

        await API.Timeslot.discounts.SpecificDates.create({ date: formatedDate, discount: discountRate })

        dispatch({
          type: CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento creado correctamente.' })
        Emitter.emit(Events.Discounts.CREATE_DISCOUNT)
        callback !== undefined && callback()
      } catch (error) {
        const messageError = handleErrorsWithAction(
          error,
          CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_FAILURE,
          dispatch
        )
        setToastErrorUpdate(toastId, { render: messageError ?? 'Error al crear el descuento' })
      }
    }
  },

  updateDiscount({
    discountId,
    date,
    discountRate,
    callback
  }: {
    discountId: number
    date: Moment
    discountRate: number
    callback?: () => void
  }): AppThunk {
    return async (dispatch) => {
      const request: UpdateDiscountDateRequest = {
        type: UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Editando descuento, por favor espere...')
      try {
        const formatedDate = moment(date).format('YYYY-MM-DD')

        await API.Timeslot.discounts.SpecificDates.update({ discountId, date: formatedDate, discount: discountRate })

        dispatch({
          type: UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento editado correctamente.' })
        Emitter.emit(Events.Discounts.UPDATE_DISCOUNT)
        callback !== undefined && callback()
      } catch (error) {
        const messageError = handleErrorsWithAction(
          error,
          UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_FAILURE,
          dispatch
        )
        setToastErrorUpdate(toastId, { render: messageError ?? 'Error al editar el descuento' })
      }
    }
  },

  deleteDiscount({ discountId, callback }: { discountId: number; callback?: () => void }): AppThunk {
    return async (dispatch) => {
      const request: DeleteDiscountDateRequest = {
        type: DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Eliminando descuento, por favor espere...')
      try {
        await API.Timeslot.discounts.SpecificDates.delete({ discountId })

        dispatch({
          type: DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento eliminado correctamente.' })
        Emitter.emit(Events.Discounts.UPDATE_DISCOUNT)
        callback !== undefined && callback()
      } catch (error) {
        const messageError = handleErrorsWithAction(
          error,
          DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_FAILURE,
          dispatch
        )
        setToastErrorUpdate(toastId, { render: messageError ?? 'Error al eliminar el descuento' })
      }
    }
  },

  setModalVisibility({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }): SetModalVisibility {
    return {
      type: ModalCreateDiscountDateTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible, editMode }
    }
  },

  setDeleteModalVisibility(deleteModalVisible: boolean): SetDeleteModalVisibility {
    return {
      type: ModalCreateDiscountDateTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisible }
    }
  },

  setDiscountDate(selectedDate: Moment | null): SetDiscountDate {
    return {
      type: ModalCreateDiscountDateTypes.SET_DISCOUNT_DATE,
      payload: { selectedDate }
    }
  },

  setErrorDiscountRate(errorRate: string) {
    return {
      type: ModalCreateDiscountDateTypes.SET_DISCOUNT_RATE_ERROR,
      payload: { errorRate }
    }
  },

  setDiscountRate(discountRate: string): AppThunk {
    return (dispatch) => {
      dispatch({
        type: ModalCreateDiscountDateTypes.SET_DISCOUNT_RATE,
        payload: { discountRate }
      })

      if (Number(discountRate) > 100) {
        dispatch(createDiscountDatesActionsCreator.setErrorDiscountRate('El descuento no puede ser más del 100%'))
      }
    }
  },

  setSelectedDiscount(selectedDiscount: Discount): SetSelectedDiscount {
    return {
      type: ModalCreateDiscountDateTypes.SET_SELECTED_DISCOUNT,
      payload: { selectedDiscount }
    }
  }
}

export default createDiscountDatesActionsCreator
