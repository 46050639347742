import EventEmitter from 'events'
import TypedEmitter from 'typed-emitter'
import { AllEvents } from './events'
import useDeepCompareEffect from 'use-deep-compare-effect'

const Emitter = new EventEmitter() as TypedEmitter<AllEvents>

export const useEvents = <E extends keyof AllEvents>(eventType: E | E[], eventCallback: AllEvents[E]) => {
  useDeepCompareEffect(() => {
    if (Array.isArray(eventType)) {
      eventType.forEach((event) => Emitter.on(event, eventCallback))
      return () => eventType.forEach((event) => Emitter.off(event, eventCallback))
    } else {
      Emitter.on(eventType, eventCallback)
      return () => Emitter.off(eventType, eventCallback)
    }
  }, [eventCallback, eventType])
}

export default Emitter
