import { Reducer } from 'redux'
import {
  CostsAction,
  GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE,
  GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST,
  GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS,
  PRODUCT_COST_CLEAN_STATE,
  ProductCostsState,
  SET_BOOLEAN_FIELD,
  SET_PRODUCT_ELIGIBILITY,
  SET_STRING_FIELD
} from '../types/productCosts'
import { GET_PRODUCT_BY_ID_FAILURE, GET_PRODUCT_BY_ID_REQUEST, GET_PRODUCT_BY_ID_SUCCESS } from '../types/GeneralData'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { clone } from 'ramda'

const initialState: ProductCostsState = {
  ARG: {
    productName: '',
    isRemovalAutomaticPrice: false,
    isDepositAutomaticPrice: false,
    depositCostPerFloor: '',
    depositPackagingCost: '',
    depositDisassemblyCost: '',
    removalCostPerFloor: '',
    removalUnpackagingCost: '',
    removalAssemblyCost: '',
    maxAmountAvowed: '',
    percentageOnDeclaredPrice: '',
    maxRefundValue: '',
    photoUrl: '',
    photoKey: '',
    depositAutomaticPrice: 0,
    removalAutomaticPrice: 0,
    eligibility: {
      isElegibleForSale: false,
      isElegibleForDonation: false,
      isElegibleForRecycled: false,
      isElegibleForRent: false
    },
    error: ''
  },
  ESP: {
    productName: '',
    isRemovalAutomaticPrice: false,
    isDepositAutomaticPrice: false,
    depositCostPerFloor: '',
    depositPackagingCost: '',
    depositDisassemblyCost: '',
    removalCostPerFloor: '',
    removalUnpackagingCost: '',
    removalAssemblyCost: '',
    maxAmountAvowed: '',
    percentageOnDeclaredPrice: '',
    maxRefundValue: '',
    photoUrl: '',
    photoKey: '',
    depositAutomaticPrice: 0,
    removalAutomaticPrice: 0,
    eligibility: {
      isElegibleForSale: false,
      isElegibleForDonation: false,
      isElegibleForRecycled: false,
      isElegibleForRent: false
    },
    error: ''
  }
}

// unpackaging

export const ProductCostsReducer: Reducer<ProductCostsState, CostsAction> = (
  state = initialState,
  action
): ProductCostsState => {
  switch (action.type) {
    case SET_STRING_FIELD:
      if (typeof action.payload.value !== 'string') return state
      return {
        ...state,
        [action.payload.country]: {
          ...state[action.payload.country],
          [action.payload.field]: action.payload.value
        }
      }
    case SET_BOOLEAN_FIELD:
      if (typeof action.payload.value !== 'boolean') return state
      return {
        ...state,
        [action.payload.country]: {
          ...state[action.payload.country],
          [action.payload.field]: action.payload.value
        }
      }
    case SET_PRODUCT_ELIGIBILITY:
      return {
        ...state,
        [action.payload.country]: {
          ...state[action.payload.country],
          eligibility: {
            ...state[action.payload.country].eligibility,
            [action.payload.field]: action.payload.value
          }
        }
      }
    case GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST:
      return {
        ...state
      }
    case GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS:
      return {
        ...state,
        [action.payload.countryId]: {
          ...state[action.payload.countryId],
          [action.payload.operationType]: action.payload.automaticPrice
        }
      }
    case GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE:
      return {
        ...state
      }

    case PRODUCT_COST_CLEAN_STATE:
      return initialState
    case GET_PRODUCT_BY_ID_REQUEST:
      return {
        ...state
      }
    case GET_PRODUCT_BY_ID_SUCCESS: {
      const newInitialState: ProductCostsState = clone(initialState)
      action.payload.product.productsByCountry
        .filter((prod) => prod.countryId === CountryIdCode.ARGENTINA || prod.countryId === CountryIdCode.SPAIN)
        .forEach((prod) => {
          newInitialState[prod.countryId] = {
            productName: prod.name,
            depositCostPerFloor: Math.round(prod.depositCostPerFloorInCents / 100).toString(),
            depositPackagingCost: Math.round(prod.packagingCost / 100).toString(),
            depositDisassemblyCost: prod.disassemblyCost.toString(),
            removalCostPerFloor: Math.round(prod.costPerFloor / 100).toString(),
            removalUnpackagingCost: Math.round(prod.unpackagingCost / 100).toString(),
            removalAssemblyCost: Math.round(prod.assemblyCost / 100).toString(),
            maxAmountAvowed: Math.round(prod.maxAmountAvowedInCents / 100).toString(),
            percentageOnDeclaredPrice: prod.percentageAvowed.toString(),
            maxRefundValue: Math.round(prod.maxRefundAmountInCents / 100).toString(),
            depositAutomaticPrice: Math.round(prod.automaticDepositCostPerFloor / 100),
            removalAutomaticPrice: Math.round(prod.automaticCostPerFloor / 100),
            photoUrl: prod.photo?.url ?? '',
            photoKey: prod.photo?.key ?? '',
            isDepositAutomaticPrice: false,
            isRemovalAutomaticPrice: false,
            eligibility: {
              isElegibleForDonation: prod.eligibleForDonation,
              isElegibleForRecycled: prod.eligibleForRecycled,
              isElegibleForRent: prod.eligibleForRent,
              isElegibleForSale: prod.eligibleForSale
            }
          }
        })
      return {
        ...state,
        ARG: newInitialState.ARG,
        ESP: newInitialState.ESP
      }
    }
    case GET_PRODUCT_BY_ID_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}
