import { values } from 'ramda'
import { ITEM_TYPES_VALUES } from '../../../common/operations/constants'
import { ObjectResponse, ObjectToRemove, ObjectsToRemove } from '../types/objects'
import { DEPOSITED } from '../../Objects/constants/objectStatus'
import { Address } from '../types/transport'
import { RemovalItemBody } from '../../../projectApi/TransactionHandler/Operation/Removals/update'
import { OBJECT_SITUATION_SAVED } from '../../../projectApi/ObjectAdministration/common'

type ObjectsForFetchCostData = {
  type: string
  product_id: string
  amount: number
}

type ObjectsForFetchCost = {
  data: ObjectsForFetchCostData[]
}

type ObjectsForRemovalData = {
  object_id: string
  id: number
  floors_by_stairs_cost: number
  packaging_cost: number
  assembly_cost: number
}

type ObjectsForRemoval = {
  data: ObjectsForRemovalData[]
}

type ObjectToRemoveValues = {
  weight: number
  volume: number
  stairs: number
  packaging: number
  assembly: number
}

export const formatObjectsForFetchCost = (objects: ObjectsToRemove): ObjectsForFetchCost => {
  const data = Object.values(objects).reduce((accum, object): ObjectsForFetchCostData[] => {
    if (object.deleted) return [...accum]
    return [
      ...accum,
      {
        type: ITEM_TYPES_VALUES.PRODUCT,
        product_id: object.objectDetails.Product.ID,
        amount: 1
      }
    ]
  }, [] as ObjectsForFetchCostData[])
  return { data }
}

export const formatObjectsForRemoval = (objects: ObjectsToRemove): ObjectsForRemoval => {
  const data = Object.values(objects).reduce((accum, object): ObjectsForRemovalData[] => {
    const { objectDetails } = object
    const { ID, RealID } = objectDetails

    const objectToRemoveValues = calculateObjectToRemoveValues(object)
    return [
      ...accum,
      {
        object_id: ID,
        id: RealID,
        floors_by_stairs_cost: objectToRemoveValues.stairs,
        packaging_cost: objectToRemoveValues.packaging,
        assembly_cost: objectToRemoveValues.assembly
      }
    ]
  }, [] as ObjectsForRemovalData[])
  return { data }
}

export const formatObjectsForRemovalFromRemoval = (objects: ObjectsToRemove): RemovalItemBody[] => {
  const data = Object.values(objects).reduce<RemovalItemBody[]>((accum, object): RemovalItemBody[] => {
    const { objectDetails } = object
    const { RealID } = objectDetails

    const objectToRemoveValues = calculateObjectToRemoveValues(object)
    return [
      ...accum,
      {
        objectId: RealID,
        floorByStairsCost: objectToRemoveValues.stairs,
        packagingCost: objectToRemoveValues.packaging,
        assemblyCost: objectToRemoveValues.assembly,
        deleted: object.deleted
      }
    ]
  }, [])
  return data
}

export const calculateObjectToRemoveValues = (objectToRemove: ObjectToRemove): ObjectToRemoveValues => {
  const { objectDetails, floors, numberOfFloors, removePackagingCost, assemblyCost, floorCostFromRemoval } =
    objectToRemove
  const { WeightInGr, LengthInCm, WidthInCm, HeightInCm, Product } = objectDetails
  const { FloorByStairsCostInCents, RemovalPackagingCostInCents, AssemblyCostInCents } = Product
  const weight = (WeightInGr || 0) / 1000
  const volume = ((LengthInCm || 0) * (WidthInCm || 0) * (HeightInCm || 0)) / 1000000
  const stairs = Math.round(
    !floorCostFromRemoval ? (floors ? FloorByStairsCostInCents * numberOfFloors : 0) / 100 : floorCostFromRemoval
  )
  const packaging = Math.round((removePackagingCost ? RemovalPackagingCostInCents : 0) / 100)
  const assembly = Math.round((assemblyCost ? AssemblyCostInCents : 0) / 100)

  return {
    weight,
    stairs,
    packaging,
    assembly,
    volume
  }
}

export const isAnyObjectSelected = (objects: ObjectsToRemove): boolean => values(objects).length > 0

export const objectAvailableForRemoval = (object: ObjectResponse): boolean =>
  object.ObjectStatus.ID === DEPOSITED.numberValue && object.ObjectSituation === OBJECT_SITUATION_SAVED
export const filterObjectsForRemoval = (objects: ObjectResponse[]): ObjectResponse[] =>
  objects.filter(objectAvailableForRemoval)

export const formatAddressesToOptions = (addresses: Address[]) => [
  ...addresses.map((address) => ({
    label: address.Original,
    value: address.ID
  })),
  { value: 0, label: 'Crear nueva dirección' }
]
