import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Header from '../Nav/components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import Users from '../Users/Users.js'
import Dashboard from '../Dashboard/containers/Dashboard'
import ObjectChanges from '../Objects/containers/objectChanges'
import CorporativeObjectChanges from '../Corporative/Objects/containers/objectChanges'
import Products from '../ProductsV2/containers/productTable'
import CorporativeProducts from '../Corporative/Products/containers/productTable'
import CorporativeGuestProducts from '../Corporative/users/containers/userGuestTable'
import Categories from '../Categories/Categories.js'
import Trips from '../Trips/containers/tripsTable'
import Pickings from '../Pickings/containers/pickingsTable'
import Component from '../PhotographObjectsV2/container/PhotographObjectsTable'
import Movements from '../MovementsV2/container/MovementsTable'
import Flags from '../Flags/container/flags.js'
import Payments from '../Payments/Payments'
import { CheckTokenValidation } from './CheckTokenValidation'
import { initMP } from '../../services/mercado-pago'
import { pathOr } from 'ramda'
import Deposits from '../Deposits/containers/DepositsTable'
import EditDeposit from '../Deposits/containers/EditDepositViewContainers'
import DepositHistory from '../Deposits/containers/depositHistory'
import Removals from '../Removals/containers/RemovalsTable'
import RemovalHistory from '../Removals/containers/RemovalHistory'
import RemovalsValidation from '../RemovalsValidation'
import Activity from '../Activity/Activity'
import RegisterObjects from '../RegisterObjects/container/registerObjects'
import NewRemoval from '../NewRemoval/container'
import Prospects from '../Leads/containers/LeadsTable'
import Billing from '../Billing'
import Discount from '../Discount'
import Address from '../Address/containers/address'
import Cbus from '../CBUs/containers/cbus'
import Cards from '../Cards'
import BillingByClientTable from '../BillingV2/containers/BillingByClientTable'
import AttributeTable from '../Attributes/containers/AttributeTable'
import NewEditAttribute from '../Attributes/containers/NewEditAttribute'
import Locations from '../Locations'
import CorporativeLocations from '../Corporative/Locations'
import LocationObjects from '../Locations/containers/locationObjects'
import QRModal from '../../components/QRModal/QRModalContainer'
import SingleDepositContainer from '../SingleDepositView/containers/singleDeposit'
import SingleRemovalContainer from '../SingleRemovalView/containers/singleRemoval'
import DepositsValidationTableContainer from '../DepositsValidation/containers/table'
import ValidationViewContainer from '../DepositsValidation/containers/validationView'
import ProposalsTableContainer from '../ProposalsV2/containers/proposals'
import { useDispatch } from 'react-redux'
import countriesActions from '../../components/CountrySelector/actions/CountrySelector'
import FeatureManagerPopup from '../featureManager/featureManagerPopup'
import CorporativeObjectsTableContainer from '../Corporative/Objects/containers/objectsTable'
import ObjectsTableContainer from '../ObjectsV2/containers/objectsTable'
import ServicesTableContainer from '../ServicesV2/containers/servicesTable'
import AgentsTable from '../Agents/container/agentsTable'
import Nav from '../Nav/containers/NavContainer'
import SalesRequestTable from '../SalesRequest/containers/SalesRequestTable'
import SalesRequestViewContainer from '../SalesRequest/containers/SalesRequestView'
import * as H from 'history'
import RoutePermission from './containers/RoutePermission'
import { Permissions } from '../UserLogged/permissions'
import NewProposalScreen from '../NewProposal'
import Origins from '../Origins/container/originsTable'
import CreateNewProductContainer from '../NewProduct/containers/CreateNewProduct'
import CorporateNewProductContainer from '../Corporative/Products/containers/AMProducts'
import { PriceBandsTableContainer } from '../PriceBands/containers/priceBandsTable'
import SearchUser from '../BillingPayment/containers/SearchUser'
import BillingPaymentTable from '../BillingPayment/containers/BillingPaymentTable'
import PaymentProcess from '../BillingPayment/containers/PaymentProcess'
import DepositsTableContainer from '../StorageDeposits/containers/depositsTable'
import CorporativeDepositsTableContainer from '../Corporative/Deposits/containers/depositsTable'
import DiscountDatesContainer from '../Discount/containers/DiscountDates'
import UsersTableContainer from '../Corporative/users/containers/usersTable'
import DiscountDaysContainer from '../Discount/containers/DiscountDays'
import NewBillingTable from '../BillingV2/containers/newBillingTable'
import LeadHistoryContainer from '../Leads/containers/LeadHistoryContainer'
import OperationSpecificDatesContainer from '../OperationCalendar/container/operationDatesContainer'
import OperationCalendarContainer from '../OperationCalendar/container/operationDaysContainer'
import ProviderContainer from '../Providers/containers/ProviderTable'
import TransactionsTableContainer from '../Transactions/containers/TransactionsTable'
import RequestsTableContainer from '../Requests/containers/RequestsTable'
import ShippersContainer from '../Shippers/containers/ShippersTable'
import VehiclesContainer from '../Vehicles/containers/vehiclesTable'
import DonationsContainer from '../Donations/containers/donationsTable'
import DonationsViewContainer from '../Donations/containers/donationsView'
import ComplaintsContainer from '../Complaints/containers/complaintsTable'
import DevelopmentViewContainer from '../development/containers/developmentView'
import ProductCatalogContainer from '../NewProduct/containers/ProductsCatalog'
import ComplaintUpdateViewContainer from '../Complaints/containers/ComplaintUpdateView'

type FullProps = {
  history: H.History
}

function Full(props: FullProps) {
  const { history } = props
  const [navHidden, toggleNavHidden] = useState(false)
  const dispatch = useDispatch()
  const prevPath = pathOr('', ['location', 'state', 'prevPath'], history)

  useEffect(() => {
    dispatch(countriesActions.getCountries())
    initMP()
  }, [])

  return (
    <CheckTokenValidation prevPath={prevPath} history={history}>
      <Header toggleNavHidden={() => toggleNavHidden(!navHidden)} />
      <div className="app-body">
        <Nav hidden={navHidden} />
        <FeatureManagerPopup />
        <main className="main">
          <Breadcrumb />
          <QRModal />
          <section
            style={{ paddingRight: 15, paddingLeft: 15, width: '100%', marginRight: 'auto', marginLeft: 'auto' }}>
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <RoutePermission permission={Permissions.VerUsuarios} path="/users/:userId/address" component={Address} />
              <RoutePermission permission={Permissions.VerUsuarios} path="/users/:userId/cbus" component={Cbus} />
              <RoutePermission permission={Permissions.VerUsuarios} path="/users/:userId/cards" component={Cards} />
              <RoutePermission permission={Permissions.VerUsuarios} path="/users" component={Users} />
              <RoutePermission permission={Permissions.VerAgentes} path="/agents" component={AgentsTable} />
              <RoutePermission
                permission={Permissions.VerObjetos}
                path="/objectChanges/:objectID"
                component={ObjectChanges}
              />
              <RoutePermission
                permission={Permissions.VerObjetosCorporativos}
                path="/corporative/objects"
                component={CorporativeObjectsTableContainer}
              />
              <RoutePermission
                permission={Permissions.VerObjetosCorporativos}
                path="/corporative/objectChanges/:objectID"
                component={CorporativeObjectChanges}
              />
              <RoutePermission permission={Permissions.VerObjetos} path="/objects" component={ObjectsTableContainer} />
              <RoutePermission permission={Permissions.VerProductos} path="/products" component={Products} />
              <RoutePermission
                permission={Permissions.VerProductosCorporativosBO}
                path="/corporative/products"
                component={CorporativeProducts}
              />
              <RoutePermission
                permission={Permissions.VerProductos}
                path="/newProduct"
                exact
                component={CreateNewProductContainer}
              />
              <RoutePermission
                permission={Permissions.VerProductos}
                path="/product/catalogue"
                exact
                component={ProductCatalogContainer}
              />
              <RoutePermission
                permission={Permissions.VerProductos}
                path="/editProduct/:productId"
                exact
                component={CreateNewProductContainer}
              />
              <RoutePermission
                permission={Permissions.VerProductosCorporativosBO}
                path="/corporative/newProduct"
                exact
                component={CorporateNewProductContainer}
              />
              <RoutePermission
                permission={Permissions.VerProductosCorporativosBO}
                path="/corporative/editProduct/:productId"
                exact
                component={CorporateNewProductContainer}
              />
              <RoutePermission permission={Permissions.VerProductos} path="/attributes" component={AttributeTable} />
              <RoutePermission
                permission={Permissions.VerProductos}
                exact
                path="/newAttribute"
                component={NewEditAttribute}
              />
              <RoutePermission
                permission={Permissions.VerProductos}
                path="/editAttribute/:attributeId"
                component={NewEditAttribute}
              />
              <RoutePermission permission={Permissions.VerCategorias} path="/categories" component={Categories} />
              <RoutePermission permission={Permissions.VerFlags} path="/flags" component={Flags} />
              <RoutePermission
                permission={Permissions.VerTransacciones}
                path="/transactions"
                component={TransactionsTableContainer}
              />
              <RoutePermission
                permission={Permissions.VisualizarProveedores}
                path="/providers"
                component={ProviderContainer}
              />
              <RoutePermission
                permission={Permissions.ABMTransportistas}
                path="/shippers"
                component={ShippersContainer}
              />
              <RoutePermission permission={Permissions.VerViajes} path="/trips" component={Trips} />
              <RoutePermission
                permission={Permissions.VerRegistrarObjetos}
                path="/register_objects"
                component={RegisterObjects}
              />
              <RoutePermission
                permission={Permissions.VerFotografiarObjetos}
                path="/photograph_objects"
                component={Component}
              />
              <RoutePermission permission={Permissions.VerMovimientos} path="/movements" component={Movements} />
              <RoutePermission permission={Permissions.VerReglas} path="/payments" component={Payments} />
              <RoutePermission permission={Permissions.VerActividad} path="/payments_history" component={Activity} />
              <RoutePermission permission={Permissions.VerIngresos} path="/deposits" exact component={Deposits} />
              <RoutePermission
                permission={Permissions.VerIngresos}
                path="/editDeposit/:depositId"
                exact
                component={EditDeposit}
              />
              <RoutePermission
                permission={Permissions.VerIngresos}
                path="/depositHistory/:depositId"
                exact
                component={DepositHistory}
              />
              <RoutePermission
                permission={Permissions.VerIngresos}
                path="/deposits/:depositId"
                component={SingleDepositContainer}
              />
              <RoutePermission
                permission={Permissions.VerSolicitudDeVentas}
                path="/salesRequest"
                component={SalesRequestTable}
                exact={true}
              />
              <RoutePermission
                permission={Permissions.VerSolicitudesGenericas}
                path="/requests"
                component={RequestsTableContainer}
                exact={true}
              />
              <RoutePermission
                permission={Permissions.VerSolicitudDeVentas}
                path="/salesRequest/:saleRequestID"
                component={SalesRequestViewContainer}
                exact={true}
              />
              <RoutePermission
                permission={Permissions.VerValidarIngresos}
                path="/depositsValidation"
                exact
                component={DepositsValidationTableContainer}
              />
              <RoutePermission
                permission={Permissions.VerValidarIngresos}
                path="/depositsValidation/:depositId"
                component={ValidationViewContainer}
              />
              <RoutePermission permission={Permissions.VerDevoluciones} path="/removals" component={Removals} />
              <RoutePermission
                permission={Permissions.VerDevoluciones}
                path="/removalDetails/:removalId"
                component={SingleRemovalContainer}
              />
              <RoutePermission permission={Permissions.VerDevoluciones} path="/newRemoval" component={NewRemoval} />
              <RoutePermission
                permission={Permissions.VerDevoluciones}
                path="/removalHistory/:removalId"
                exact
                component={RemovalHistory}
              />
              <RoutePermission
                permission={Permissions.VerDevoluciones}
                path="/editRemoval/:removalId"
                exact
                component={NewRemoval}
              />
              <RoutePermission
                permission={Permissions.VerValidarDevoluciones}
                path="/removalsValidation"
                component={RemovalsValidation}
              />
              <RoutePermission permission={Permissions.VerOportunidades} path="/leads" component={Prospects} />
              <RoutePermission
                permission={Permissions.VerOportunidades}
                path="/lead_history/:leadId"
                component={LeadHistoryContainer}
              />
              <RoutePermission
                permission={Permissions.VerCotizaciones}
                path="/proposals"
                component={ProposalsTableContainer}
              />
              <RoutePermission
                permission={Permissions.VerOportunidades}
                path="/newProposal/:prospectID"
                component={NewProposalScreen}
              />
              <RoutePermission
                permission={Permissions.VerOportunidades}
                path="/proposalDetail/:prospectID/:proposalID"
                component={NewProposalScreen}
              />
              <RoutePermission
                permission={Permissions.VerTiposDeServicios}
                path="/services"
                component={ServicesTableContainer}
              />
              <RoutePermission permission={Permissions.VerFacturacion} path="/billing" component={Billing} />
              <RoutePermission
                permission={Permissions.VerFacturacion}
                path="/billingByClient"
                component={BillingByClientTable}
              />
              <RoutePermission
                permission={Permissions.VerFacturacion}
                path="/subscriptions"
                component={NewBillingTable}
              />
              <RoutePermission permission={Permissions.VerDescuentos} path="/discounts" component={Discount} />
              <RoutePermission
                permission={Permissions.VerDescuentos}
                path="/discountDates"
                component={DiscountDatesContainer}
              />
              <RoutePermission
                permission={Permissions.VerDescuentos}
                path="/discountDays"
                component={DiscountDaysContainer}
              />
              <RoutePermission
                permission={Permissions.VerCalendarioDeOperaciones}
                path="/operation_days"
                component={OperationCalendarContainer}
              />
              <RoutePermission
                permission={Permissions.VerCalendarioDeOperaciones}
                path="/operation_dates"
                component={OperationSpecificDatesContainer}
              />
              <RoutePermission
                permission={Permissions.VerObjetos}
                exact
                path="/locations/:locationCode"
                component={LocationObjects}
              />
              <RoutePermission
                permission={Permissions.VerObjetosCorporativos}
                exact
                path="/corporative/locations/:locationCode"
                component={LocationObjects}
              />
              <RoutePermission
                permission={Permissions.VerUsuariosCuentaCorporativa}
                path="/corporateUsers"
                component={UsersTableContainer}
              />
              <RoutePermission
                permission={Permissions.VisualizarMisInvitaciones}
                path="/pendingUsers"
                component={CorporativeGuestProducts}
              />
              <RoutePermission
                permission={Permissions.VerTodosLosDepositos}
                path="/storageDeposits"
                component={DepositsTableContainer}
              />
              <RoutePermission
                permission={Permissions.VerDepositosCorp}
                path="/corporative/deposits"
                component={CorporativeDepositsTableContainer}
              />
              <RoutePermission permission={Permissions.VerDepositos} path="/locations" component={Locations} />
              <RoutePermission
                permission={Permissions.VerUbicacionesBO}
                path="/corporative/locations"
                component={CorporativeLocations}
              />
              <RoutePermission permission={Permissions.VerBusquedas} path="/pickings" component={Pickings} />
              <RoutePermission permission={Permissions.VerOrigenes} path="/origins" component={Origins} />
              <RoutePermission
                permission={Permissions.ConsultarDeudasTerceros}
                path="/billing_payment"
                component={SearchUser}
              />
              <RoutePermission
                permission={Permissions.ConsultarDeudasTerceros}
                path="/billings_payments_list"
                component={BillingPaymentTable}
              />
              <RoutePermission
                permission={Permissions.PagarDeuda}
                path="/billing_payment_process"
                component={PaymentProcess}
              />
              <RoutePermission
                permission={Permissions.VerFranjasDePrecios}
                path="/priceBands"
                component={PriceBandsTableContainer}
              />
              <RoutePermission permission={Permissions.ABMVehiculos} path="/vehicles" component={VehiclesContainer} />
              <RoutePermission
                exact
                permission={Permissions.SolicitudDeDonacionOperario}
                path="/donations"
                component={DonationsContainer}
              />
              <RoutePermission
                exact
                permission={Permissions.ListarReclamos}
                path="/complaints"
                component={ComplaintsContainer}
              />
              <RoutePermission
                permission={Permissions.SolicitudDeDonacionOperario}
                path="/donations/:donationID"
                component={DonationsViewContainer}
              />
              <RoutePermission
                permission={Permissions.SolicitudDeDonacionOperario}
                path="/development"
                component={DevelopmentViewContainer}
              />
              <RoutePermission
                permission={Permissions.ComplaintsView}
                path="/complaints/:complaintId"
                component={ComplaintUpdateViewContainer}
              />
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </section>
        </main>
      </div>
    </CheckTokenValidation>
  )
}

export default Full
