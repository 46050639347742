import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'
import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'
import { ServiceOperationType, ServiceTypeId } from '../../../projectApi/TransactionHandler/ServiceType/common'

export interface ServiceModalState {
  open: boolean
  isCreate: boolean
  deposits: Deposit[]
  selectedDeposit: SelectedDeposit[]
  isLoadingDeposits: boolean
  error: string
  isSaving: boolean
  isCreatingService: boolean
  isDeletingService: boolean
  deleteModalVisible: boolean
  selectedService: ServiceSearch
}

export type SelectedDeposit = {
  label: string
  value: number
}

export type CreateServiceType = {
  displayOrder: number
  deposits: number[]
  selectedService: ServiceSearch
}

export const SELECT_SERVICE = 'SERVICE/SERVICE_EDIT/SELECT_SERVICE'

export interface SelectService {
  type: typeof SELECT_SERVICE
  payload: {
    id: string
    multiplier: number
    type: string
    operationType: ServiceOperationType
    deposits: number[]
    solution: ServiceTypeId
    solutionId: number
  }
}

export enum ABMServiceTypes {
  SET_MULTIPLIER = 'SERVICES/SERVICE_EDIT/SET_MULTIPLIER',
  SET_TYPE = 'SERVICES/SERVICE_CREATE/SET_TYPE',
  SET_ID = 'SERVICES/SERVICE_CREATE/SET_ID',
  CANCEL = 'SERVICE/SERVICE_EDIT/CANCEL',
  SET_OPEN = 'SERVICES/SERVICE_EDIT/SET_OPEN'
}

export interface SetMultiplier {
  type: typeof ABMServiceTypes.SET_MULTIPLIER
  payload: { multiplier: number }
}
export interface SetType {
  type: typeof ABMServiceTypes.SET_TYPE
  payload: { type: string }
}
export interface SetId {
  type: typeof ABMServiceTypes.SET_ID
  payload: { id: string }
}

export interface SetOpen {
  type: typeof ABMServiceTypes.SET_OPEN
  payload: {
    open: boolean
    isCreate: boolean
    service?: ServiceSearch
  }
}
export interface SetCancel {
  type: typeof ABMServiceTypes.CANCEL
}

export type ABMServiceActions = SetMultiplier | SetType | SetId | SetCancel | SetOpen

export enum UpdateServiceTypes {
  UPDATE_SERVICE_REQUEST = 'SERVICES/SERVICE_EDIT/UPDATE_SERVICE_REQUEST',
  UPDATE_SERVICE_SUCCESS = 'SERVICES/SERVICE_EDIT/UPDATE_SERVICE_SUCCESS',
  UPDATE_SERVICE_FAILURE = 'SERVICES/SERVICE_EDIT/UPDATE_SERVICE_FAILURE'
}

export interface UpdateServiceRequest {
  type: typeof UpdateServiceTypes.UPDATE_SERVICE_REQUEST
}

export interface UpdateServiceSuccess {
  type: typeof UpdateServiceTypes.UPDATE_SERVICE_SUCCESS
}

export interface UpdateServiceFailure {
  type: typeof UpdateServiceTypes.UPDATE_SERVICE_FAILURE
  payload: {
    error: string
  }
}

export type UpdateServiceAction = UpdateServiceRequest | UpdateServiceSuccess | UpdateServiceFailure

export enum CreateServiceTypes {
  CREATE_SERVICE_REQUEST = 'SERVICES/SERVICE_CREATE/CREATE_SERVICE_REQUEST',
  CREATE_SERVICE_SUCCESS = 'SERVICES/SERVICE_CREATE/CREATE_SERVICE_SUCCESS',
  CREATE_SERVICE_FAILURE = 'SERVICES/SERVICE_CREATE/CREATE_SERVICE_FAILURE'
}

export interface CreateServiceRequest {
  type: typeof CreateServiceTypes.CREATE_SERVICE_REQUEST
}

export interface CreateServiceSuccess {
  type: typeof CreateServiceTypes.CREATE_SERVICE_SUCCESS
}

export interface CreateServiceFailure {
  type: typeof CreateServiceTypes.CREATE_SERVICE_FAILURE
  payload: {
    error: string
  }
}

export type CreateServiceAction = CreateServiceRequest | CreateServiceSuccess | CreateServiceFailure

export enum DeleteServiceTypes {
  DELETE_SERVICE_REQUEST = 'SERVICES/SERVICE_DELETE/DELETE_SERVICE_REQUEST',
  DELETE_SERVICE_SUCCESS = 'SERVICES/SERVICE_DELETE/DELETE_SERVICE_SUCCESS',
  DELETE_SERVICE_FAILURE = 'SERVICES/SERVICE_DELETE/DELETE_SERVICE_FAILURE',
  SET_DELETE_MODAL_VISIBILITY = 'SERVICES/SERVICE_DELETE/SET_DELETE_MODAL_VISIBILITY'
}

export interface SetDeleteModalVisibility {
  type: typeof DeleteServiceTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisibility: boolean; service: ServiceSearch }
}

export interface DeleteServiceRequest {
  type: DeleteServiceTypes.DELETE_SERVICE_REQUEST
}

export interface DeleteServiceSuccess {
  type: DeleteServiceTypes.DELETE_SERVICE_SUCCESS
}

export interface DeleteServiceFailure {
  type: DeleteServiceTypes.DELETE_SERVICE_FAILURE
}

export type DeleteServiceActions =
  | DeleteServiceRequest
  | DeleteServiceSuccess
  | DeleteServiceFailure
  | SetDeleteModalVisibility

export enum GetDepositTypes {
  GET_DEPOSITS_REQUEST = 'SERVICES/SERVICE_CREATE/GET_DEPOSITS_REQUEST',
  GET_DEPOSITS_SUCCESS = 'SERVICES/SERVICE_CREATE/GET_DEPOSITS_SUCCESS',
  GET_DEPOSITS_FAILURE = 'SERVICES/SERVICE_CREATE/GET_DEPOSITS_FAILURE'
}

export interface GetDepositsRequest {
  type: GetDepositTypes.GET_DEPOSITS_REQUEST
}

export interface GetDepositsSuccess {
  type: GetDepositTypes.GET_DEPOSITS_SUCCESS
  payload: {
    deposits: Deposit[]
  }
}

export interface GetDepositsFailure {
  type: GetDepositTypes.GET_DEPOSITS_FAILURE
  payload: {
    error: string
  }
}

export type GetDepositsActions = GetDepositsRequest | GetDepositsSuccess | GetDepositsFailure

export enum GetDepositsByIdTypes {
  GET_DEPOSITS_BY_ID_REQUEST = 'SERVICES/SERVICE_CREATE/GET_DEPOSITS_BY_ID_REQUEST',
  GET_DEPOSITS_BY_ID_SUCCESS = 'SERVICES/SERVICE_CREATE/GET_DEPOSITS_BY_ID_SUCCESS',
  GET_DEPOSITS_BY_ID_FAILURE = 'SERVICES/SERVICE_CREATE/GET_DEPOSITS_BY_ID_FAILURE'
}

export interface GetDepositsByIdRequest {
  type: GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_REQUEST
}

export interface GetDepositsByIdSuccess {
  type: GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_SUCCESS
  payload: {
    selectedDeposits: SelectedDeposit[]
  }
}

export interface GetDepositsByIdFailure {
  type: GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_FAILURE
  payload: {
    error: string
  }
}

export type GetDepositsByIdActions = GetDepositsByIdRequest | GetDepositsByIdSuccess | GetDepositsByIdFailure

export const SET_SELECTED_DEPOSIT = 'SERVICES/SERVICE_CREATE/SET_SELECTED_DEPOSIT'

export interface SetSelectedDeposit {
  type: typeof SET_SELECTED_DEPOSIT
  payload: { selectedDeposit: SelectedDeposit[] }
}

export type ServicesModalAction =
  | SetOpen
  | SelectService
  | UpdateServiceAction
  | CreateServiceAction
  | SetMultiplier
  | SetCancel
  | GetDepositsActions
  | ABMServiceActions
  | DeleteServiceActions
  | SetSelectedDeposit
  | GetDepositsByIdActions
