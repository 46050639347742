import { apiPrivate } from '@/api'
import { AddCBUParams } from './add'
import { URL_BASE_PROFILE } from '@/endpoints'

export type UpdateCBUParams = AddCBUParams & {
  cbuId: number
}

export function update({ userId, isMain, holderName, cbu, cbuId }: UpdateCBUParams): Promise<void> {
  const formData = new FormData()

  formData.append('user_id', userId)
  formData.append('main', isMain.toString())
  formData.append('holder_name', holderName)
  formData.append('cbu', cbu)

  return apiPrivate
    .put(`${URL_BASE_PROFILE}/admin/cbu/id/${cbuId}`, formData)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
