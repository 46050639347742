import { Vehicle } from '../../../projectApi/Trips/Vehicles/common'
import { VehiclesListSortKey } from '../../../projectApi/Trips/Vehicles/list'
import { Pagination, SearchFilter, Sort } from '../../../utils/searchFilterUtils'
import { EditVehicleSuccess } from './ABMVehicles'

export type VehiclesTableSearchKey = 'providerId' | 'name' | 'id'
export type VehiclesSearchFilter = SearchFilter<VehiclesTableSearchKey>

export type VehiclesTableSort = Sort<VehiclesListSortKey>

export type VehiclesListStateType = {
  fetchingVehicles: boolean
  sendingCSV: boolean
  vehicles: Vehicle[]
  pagination: Pagination
  sort: VehiclesTableSort
  searchFilters: VehiclesSearchFilter[]
}

export enum GetVehiclesListTypes {
  GET_VEHICLE_REQUEST = 'VEHICLE/GET_VEHICLE_REQUEST',
  GET_VEHICLE_SUCCESS = 'VEHICLE/GET_VEHICLE_SUCCESS',
  GET_VEHICLE_FAILURE = 'VEHICLE/GET_VEHICLE_FAILURE'
}

export interface GetVehicleListRequest {
  type: GetVehiclesListTypes.GET_VEHICLE_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: VehiclesSearchFilter[]
    sort: VehiclesTableSort
  }
}

export interface GetVehicleListSuccess {
  type: GetVehiclesListTypes.GET_VEHICLE_SUCCESS
  payload: {
    vehicles: Vehicle[]
    total: number
  }
}

export interface GetVehicleListFailure {
  type: GetVehiclesListTypes.GET_VEHICLE_FAILURE
  payload: {
    error: string
  }
}

type GetVehicleActions = GetVehicleListRequest | GetVehicleListSuccess | GetVehicleListFailure

export enum SendVehiclesCSVTypes {
  SEND_VEHICLES_CSV_REQUEST = 'VEHICLE/SEND_VEHICLES_CSV_REQUEST',
  SEND_VEHICLES_CSV_SUCCESS = 'VEHICLE/SEND_VEHICLES_CSV_SUCCESS',
  SEND_VEHICLES_CSV_FAILURE = 'VEHICLE/SEND_VEHICLES_CSV_FAILURE'
}

export interface SendVehiclesCSVRequest {
  type: SendVehiclesCSVTypes.SEND_VEHICLES_CSV_REQUEST
}

export interface SendVehiclesCSVSuccess {
  type: SendVehiclesCSVTypes.SEND_VEHICLES_CSV_SUCCESS
}

export interface SendVehiclesCSVFailure {
  type: SendVehiclesCSVTypes.SEND_VEHICLES_CSV_FAILURE
  payload: {
    error: string
  }
}

type SendVehiclesCSVActions = SendVehiclesCSVRequest | SendVehiclesCSVSuccess | SendVehiclesCSVFailure

export const RESET_FILTERS = 'VEHICLE/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export type VehicleListActions = GetVehicleActions | SendVehiclesCSVActions | ResetFilters | EditVehicleSuccess
