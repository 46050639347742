import React, { useMemo, useState } from 'react'
import { Formik, Form, yupToFormErrors, FormikErrors } from 'formik'
import styles from './styles.module.scss'
import * as Yup from 'yup'
import Payment from 'payment'
import CreditCardComponent from '../NewCard/Card/CreditCardComponent'
import { FormikTextInput } from '../FormikInput'
import { CreditCardState, DocumentTypeOptions as DocumentTypesOps, FormikCardErrors } from './types'
import { INPUTS_MASKS } from '../TextFieldMasked'
import Select from '../Select'
import DNICard from './DNI/DNICard'

export type CreditCardProps = {
  setStringField: ({ field, value }: { field: string; value: string }) => void
  setNumberField: ({ field, value }: { field: string; value: number }) => void
  creditCardState: CreditCardState
  setErrors: (errors: FormikCardErrors) => void
}

type FormValues = {
  cardNumber: string
  expirationDate: string
  cvc: string
  cardOwnerName: string
  documentType: string
  documentNumber: string
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .max(19, 'El campo no puede superar los 18 caracteres')
    .test('test-number', 'Número de tarjeta inválido', (value) => Payment.fns.validateCardNumber(value || ''))
    .required('Campo requerido'),
  cvc: Yup.string()
    .min(3, 'Campo inválido')
    .max(4)
    .required('Campo requerido')
    .test('test-number', 'Código de seguridad inválido', (value) =>
      Payment.fns.validateCardCVC(value.replace(/\s/g, '') || '')
    ),
  expirationDate: Yup.string()
    .max(5, 'La cantidad de caracteres no es la esperada')
    .test('test-number', 'Fecha Inválida', (value) => Payment.fns.validateCardExpiry(value || '')),
  cardOwnerName: Yup.string()
    .max(50, 'El campo puede poseer un máximo de 50 caracteres')
    .min(5, 'Cantidad de caracteres inválida')
    .required('Campo requerido')
    .matches(/^[a-zA-Z\s]+\s+[a-zA-Z]+$/, 'Cantidad de caracteres inválida'),
  documentType: Yup.string().max(4).min(2).required('Campo requerido'),
  documentNumber: Yup.string().required('Campo requerido')
})

const amexRegex = /^3[47]/
const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/

const CreditCard = ({ setStringField, creditCardState, setErrors }: CreditCardProps) => {
  const {
    cvc,
    cardNumber,
    documentType,
    cardOwnerName,
    expirationDate,
    isLoading,
    documentNumber,
    documentTypeOptions
  } = creditCardState
  const [focus, setFocus] = useState<string>('')

  const options = useMemo(() => {
    if (documentTypeOptions.length === 0) return DocumentTypesOps
    return documentTypeOptions.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [documentTypeOptions])

  const useCreditCardMask = (): INPUTS_MASKS => {
    return useMemo(() => {
      if (amexRegex.test(cardNumber) || dinersRegex.test(cardNumber)) return INPUTS_MASKS.AMEX
      return INPUTS_MASKS.VISA
    }, [cardNumber])
  }

  const useCreditCardCCVMask = (): INPUTS_MASKS => {
    return useMemo(() => {
      if (amexRegex.test(cardNumber) || dinersRegex.test(cardNumber)) return INPUTS_MASKS.CCV_AMEX
      return INPUTS_MASKS.CCV_VISA
    }, [cardNumber])
  }

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        {focus === 'documentNumber' ? (
          <DNICard name={cardOwnerName} documentNumber={documentNumber} />
        ) : (
          <CreditCardComponent
            cvc={cvc}
            expiry={expirationDate}
            name={cardOwnerName}
            number={cardNumber}
            focused={focus}
            locale={{
              valid: ''
            }}
            placeholders={{
              name: 'Nombre y Apellido'
            }}
          />
        )}
      </div>
      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            cardNumber: '',
            expirationDate: '',
            cvc: '',
            cardOwnerName: '',
            documentType: '',
            documentNumber: ''
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          validate={async (values: FormValues) => {
            try {
              await DisplayingErrorMessagesSchema.validate(values, { abortEarly: false })
            } catch (e) {
              setErrors(yupToFormErrors(e))
              const errors: FormikErrors<FormValues> = {}
              const foundDocumentType = documentTypeOptions.find((x) => x.id === values.documentType)
              const documentNumberValue = values.documentNumber.replace(/\s/g, '').length
              if (
                foundDocumentType &&
                documentNumberValue >= foundDocumentType.min_length &&
                documentNumberValue <= foundDocumentType.max_length
              ) {
                delete errors.documentNumber
              } else {
                errors.documentNumber = 'Cantidad de caracteres inválida'
              }
              if (amexRegex.test(cardNumber) || dinersRegex.test(cardNumber)) {
                if (cvc.replace(/\s/g, '').length !== 4) errors.cvc = 'Cantidad de caracteres inválida'
              } else if (cvc.replace(/\s/g, '').length !== 3) {
                errors.cvc = 'Cantidad de caracteres inválida'
              } else {
                delete errors.cvc
              }

              return {
                ...errors,
                ...yupToFormErrors(e)
              }
            }
            setErrors({})
          }}
          onSubmit={() => {}}>
          {({ handleChange }) => {
            return (
              <Form className={styles.form}>
                <FormikTextInput
                  disabled={isLoading}
                  mask={useCreditCardMask()}
                  focusSetter={setFocus}
                  fieldSetter={setStringField}
                  maxLength={19}
                  name="cardNumber"
                  label="Número de tarjeta:"
                />
                <FormikTextInput
                  disabled={isLoading}
                  fieldSetter={setStringField}
                  focusSetter={setFocus}
                  maxLength={30}
                  name="cardOwnerName"
                  label="Nombre del titular:"
                />
                <div className={styles.twoColumns}>
                  <FormikTextInput
                    focusSetter={setFocus}
                    disabled={isLoading}
                    mask={INPUTS_MASKS.DATE_MONTH_YEAR}
                    fieldSetter={setStringField}
                    name="expirationDate"
                    label="Fecha de vencimiento:"
                  />
                  <FormikTextInput
                    focusSetter={setFocus}
                    disabled={isLoading}
                    mask={useCreditCardCCVMask()}
                    fieldSetter={setStringField}
                    name="cvc"
                    label="CVC:"
                  />
                </div>
                <div className={styles.twoColumns}>
                  <Select
                    onSelect={(value) => {
                      setStringField({ field: 'documentType', value: value.value })
                      setFocus('documentNumber')
                      handleChange({ target: { name: 'documentType', value: value.value } })
                    }}
                    name="documentType"
                    id="documentType"
                    multiple={false}
                    options={options}
                    placeholder="Tipo de documento"
                    selected={options.find((x) => x.value === documentType)}
                    onDelete={() => {
                      setStringField({ field: 'documentType', value: '' })
                      handleChange({ target: { name: 'documentType', value: '' } })
                    }}
                    loading={isLoading}
                    disabled={isLoading}
                  />
                  <FormikTextInput
                    focusSetter={setFocus}
                    mask={INPUTS_MASKS.DOCUMENT}
                    fieldSetter={setStringField}
                    name="documentNumber"
                    label="Número de documento:"
                    disabled={isLoading}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
export default CreditCard
