import moment from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type ListParams = {
  billingId: number
}

export function payBilling(params: ListParams): Promise<void> {
  const { billingId } = params
  const data = { paid_date: moment().format('yyyy-MM-DD') }

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS} /billings/${billingId}`, { data })
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
