import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserABMActionCreators from '../actions/userABM'
import UserDeleteModal from '../components/UserDeleteModal'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { openDeleteUserModal, deleteSatelliteUser } = bindActionCreators(UserABMActionCreators, dispatch)

  const userABMState = useSelector((state) => state.CorporateUsersABM)
  const { selectedUser } = userABMState

  const closeModal = () => {
    openDeleteUserModal({ isDeleteModalOpen: false, selectedUser: null })
  }

  const onOkClick = async () => {
    if (selectedUser) {
      const result = await deleteSatelliteUser(selectedUser?.id)
      // @ts-ignore
      if (result) closeModal()
    }
  }

  const props = {
    userABMState,
    closeModal,
    onOkClick
  }

  return <UserDeleteModal {...props} />
}

export default Container
