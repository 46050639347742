import { apiPrivate } from '../../../../api'
import { URL_BASE_CATEGORIES } from '../../../../endpoints'

interface DeleteParams {
  productId: number
}

export function deleteProduct({ productId }: DeleteParams): Promise<void> {
  return apiPrivate.delete(`${URL_BASE_CATEGORIES}/product/corporate/${productId}`)
}
