import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'

export type LocationType = {
  name: string
}

export enum AddEditLocationModalTypes {
  SET_MODAL_OPEN = 'LOCATIONS/ADD_EDIT_LOCATION_MODAL/SET_MODAL_OPEN',
  SET_MODAL_CLOSE = 'LOCATIONS/ADD_EDIT_LOCATION_MODAL/SET_MODAL_CLOSE'
}

export enum SetLocationAddEditFields {
  SET_STRING_FIELD = 'DEPOSIT/LOCATION_ADD_EDIT/SET_STRING_FIELD',
  SET_NUMBER_FIELD = 'DEPOSIT/LOCATION_ADD_EDIT/SET_NUMBER_FIELD',
  SET_UNIT_TYPE = 'DEPOSIT/LOCATION_ADD_EDIT/SET_UNIT_TYPE'
}

export type SetStringField = {
  type: SetLocationAddEditFields.SET_STRING_FIELD
  payload: {
    field: LocationAddEditPossibleStringFields
    value: string
  }
}

export type SetNumberField = {
  type: SetLocationAddEditFields.SET_NUMBER_FIELD
  payload: {
    field: LocationAddEditPossibleNumberFields
    value: number
  }
}

type SetLocationAddEditActions = SetStringField | SetNumberField

export type LocationAddEditPossibleStringFields = 'code' | 'description' | 'type'
export type LocationAddEditPossibleNumberFields =
  | 'depositId'
  | 'locationId'
  | 'maxLengthInCm'
  | 'maxWidthInCm'
  | 'maxHeightInCm'
  | 'maxVolumeInCm'
  | 'maxWeightInKg'

export type AddEditLocationModalState = {
  locationTypes: LocationType[]
  deposits: Deposit[]
  isOpen: boolean
  isEdit: boolean
  code: string
  description: string
  type: string
  depositId: number
  locationId: number
  maxLengthInCm: number
  maxWidthInCm: number
  maxHeightInCm: number
  maxVolumeInCm: number
  maxWeightInKg: number
  isLoading: boolean
  selectedLocation: SelectedLocation | null
  error: string
}

export type SelectedLocation = {
  active: boolean
  code: string
  depositID: number
  description: string
  id: number
  loadRate: number
  maxHeightInCm: number
  maxLengthInCm: number
  maxVolumeInCm: number
  maxWeightInGr: number
  maxWidthInCm: number
  occupancyRate: number
  quantityObjects: number
  type: string
  volumeInCm: number
  weightInGr: number
}

export type SetModalOpen = {
  type: typeof AddEditLocationModalTypes.SET_MODAL_OPEN
  payload: {
    selectedLocation?: SelectedLocation
  }
}

export type SetModalClose = {
  type: typeof AddEditLocationModalTypes.SET_MODAL_CLOSE
}

export enum CreateLocationTypes {
  CREATE_LOCATION_REQUEST = 'DEPOSIT/LOCATIONS/CREATE_LOCATION_REQUEST',
  CREATE_LOCATION_SUCCESS = 'DEPOSIT/LOCATIONS/CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAILURE = 'DEPOSIT/LOCATIONS/CREATE_LOCATION_FAILURE'
}

export interface CreateLocationsRequest {
  type: CreateLocationTypes.CREATE_LOCATION_REQUEST
}

export interface CreateLocationsSuccess {
  type: CreateLocationTypes.CREATE_LOCATION_SUCCESS
}

export interface CreateLocationsFailure {
  type: CreateLocationTypes.CREATE_LOCATION_FAILURE
  payload: {
    error: string
  }
}

export type CreateLocationActions = CreateLocationsRequest | CreateLocationsSuccess | CreateLocationsFailure

export enum EditLocationTypes {
  EDIT_LOCATION_REQUEST = 'DEPOSIT/LOCATIONS/EDIT_LOCATION_REQUEST',
  EDIT_LOCATION_SUCCESS = 'DEPOSIT/LOCATIONS/EDIT_LOCATION_SUCCESS',
  EDIT_LOCATION_FAILURE = 'DEPOSIT/LOCATIONS/EDIT_LOCATION_FAILURE'
}

export interface EditLocationsRequest {
  type: EditLocationTypes.EDIT_LOCATION_REQUEST
}

export interface EditLocationsSuccess {
  type: EditLocationTypes.EDIT_LOCATION_SUCCESS
}

export interface EditLocationsFailure {
  type: EditLocationTypes.EDIT_LOCATION_FAILURE
  payload: {
    error: string
  }
}

export type EditLocationActions = EditLocationsRequest | EditLocationsSuccess | EditLocationsFailure

export enum ClearStateTypes {
  CLEAR_STATE = 'DEPOSIT/LOCATION_ADD_EDIT/CLEAR_STATE'
}

export type ClearState = {
  type: ClearStateTypes.CLEAR_STATE
}

type ClearStateActions = ClearState

export enum GetLocationTypes {
  GET_LOCATION_TYPES_REQUEST = 'DEPOSIT/LOCATIONS/GET_LOCATION_TYPES_REQUEST',
  GET_LOCATION_TYPES_SUCCESS = 'DEPOSIT/LOCATIONS/GET_LOCATION_TYPES_SUCCESS',
  GET_LOCATION_TYPES_FAILURE = 'DEPOSIT/LOCATIONS/GET_LOCATION_TYPES_FAILURE'
}

export interface GetLocationsRequest {
  type: GetLocationTypes.GET_LOCATION_TYPES_REQUEST
}

export interface GetLocationsSuccess {
  type: GetLocationTypes.GET_LOCATION_TYPES_SUCCESS
  payload: {
    locationTypes: LocationType[]
  }
}

export interface GetLocationsFailure {
  type: GetLocationTypes.GET_LOCATION_TYPES_FAILURE
  payload: {
    error: string
  }
}

export type GetLocationActions = GetLocationsRequest | GetLocationsSuccess | GetLocationsFailure

export enum GetDepositLocationTypes {
  GET_DEPOSITS_LOCATION_REQUEST = 'DEPOSIT/LOCATIONS/GET_DEPOSITS_LOCATION_REQUEST',
  GET_DEPOSITS_LOCATION_SUCCESS = 'DEPOSIT/LOCATIONS/GET_DEPOSITS_LOCATION_SUCCESS',
  GET_DEPOSITS_LOCATION_FAILURE = 'DEPOSIT/LOCATIONS/GET_DEPOSITS_LOCATION_FAILURE'
}

export interface GetDepositsLocationRequest {
  type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_REQUEST
}

export interface GetDepositsLocationSuccess {
  type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_SUCCESS
  payload: {
    deposits: Deposit[]
  }
}

export interface GetDepositsLocationFailure {
  type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_FAILURE
  payload: {
    error: string
  }
}

export type GetDepositsLocationActions =
  | GetDepositsLocationRequest
  | GetDepositsLocationSuccess
  | GetDepositsLocationFailure

export type AddEditLocationModalActions =
  | SetModalOpen
  | SetModalClose
  | SetLocationAddEditActions
  | CreateLocationActions
  | EditLocationActions
  | ClearStateActions
  | GetLocationActions
  | GetDepositsLocationActions
