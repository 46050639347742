import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { OverrideProps } from '../../../utils/utilityTypes'
import { CamelCasedPropertiesDeep } from 'type-fest'
import moment, { Moment } from 'moment'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

interface APIItem {
  amount: number
  description: string
  price: number
  product_id: number
}

interface APIProposal {
  created_at: string
  deposit_id: number
  id: number
  item: APIItem[]
  prospect_id: number
  shipment_assistant_count: number
  shipment_assistant_price: number
  status: {
    ID: number
    Description: string
  }
  total_amount: number
  transport_mode_id: number
  transport_type: string
  type: string
}

interface APIProspect {
  country: string
  country_code: CountryIdCode
  email: string
  first_name: string
  gender: string
  id: number
  last_name: string
  phone: string
}

interface APIDescription {
  count: number
  proposal: APIProposal[]
  prospect: APIProspect
}

interface APIResponse {
  description: APIDescription
  success: string
}

export type Prospect = CamelCasedPropertiesDeep<APIProspect>
export type Proposal = OverrideProps<CamelCasedPropertiesDeep<APIProposal>, 'createdAt', Moment>
type Response = OverrideProps<CamelCasedPropertiesDeep<APIDescription>, 'proposal', Proposal[]>

export interface GetFromProspectParams {
  statusID?: number
  recalculatePrice?: boolean
}

export function getFromProspect(prospectID: number, params: GetFromProspectParams = {}): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/proposals/prospect/${prospectID}`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener la propuesta')
      if (!response.data.description?.proposal) throw new Error('Respuesta incorrecta al obtener las propuestas')

      const camelUnformattedProposal = camelcaseKeys(response.data.description, { deep: true })
      return {
        ...camelUnformattedProposal,
        proposal: camelUnformattedProposal.proposal.map((proposal) => ({
          ...proposal,
          createdAt: moment.utc(proposal.createdAt)
        }))
      }
    })
    .catch((err) => {
      throw err
    })
}
