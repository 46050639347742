import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'

export type ComplaintsCommonStateType = {
  reasons: ComplaintsReasons[]
  totalReasons: number
}

export type ComplaintsReasonsType = 'CLOSE' | 'COMPLAINTS'

export enum GetComplaintsReasonsTypes {
  GET_REASONS_REQUEST = 'COMMON/COMPLAINTS/GET_REASONS_REQUEST',
  GET_REASONS_SUCCESS = 'COMMON/COMPLAINTS/GET_REASONS_SUCCESS',
  GET_REASONS_FAILURE = 'COMMON/COMPLAINTS/GET_REASONS_FAILURE'
}

export interface GetComplaintsReasonsRequest {
  type: GetComplaintsReasonsTypes.GET_REASONS_REQUEST
}

export interface GetComplaintsReasonsSuccess {
  type: GetComplaintsReasonsTypes.GET_REASONS_SUCCESS
  payload: {
    reasons: ComplaintsReasons[]
    totalReasons?: number
  }
}

export interface GetComplaintsReasonsFailure {
  type: GetComplaintsReasonsTypes.GET_REASONS_FAILURE
  payload: {
    error: string
  }
}

type GetComplaintsReasonsActions =
  | GetComplaintsReasonsRequest
  | GetComplaintsReasonsSuccess
  | GetComplaintsReasonsFailure

export const HANDLE_CLEAR = 'COMMON/COMPLAINTS/HANDLE_CLEAR'
export interface HandleClear {
  type: typeof HANDLE_CLEAR
}

export const DELETE_EVIDENCE = 'COMMON/COMPLAINTS/DELETE_EVIDENCE'
export interface deleteEvidence {
  type: typeof DELETE_EVIDENCE
  payload: { index: number }
}

export type CommonComplaintsActions = GetComplaintsReasonsActions | HandleClear | deleteEvidence
