import React from 'react'
import { Modal } from 'antd'
import { OperationDay } from '../types/operationDays'
import styles from './operationDays.module.scss'
import { SelectCheckbox } from './SelectCheckbox'
import { translateDayName } from '../utils/processDays'
import { OperationTypeValues } from '../types/common'

type EditOperationDayModalProps = {
  isEditModalOpen: boolean
  openEditDayModal: (args: { isEditModalOpen: boolean; selectedDay: string }) => void
  selectedDay: string
  updateOperation: (body: OperationDay) => void
  availableOperations: OperationTypeValues[]
  setSelectedOperations: (args: { selectedOperations: OperationTypeValues[] }) => void
  selectedOperations: OperationTypeValues[]
  setAvailableOperations: ({ availableOperations }: { availableOperations: OperationTypeValues[] }) => void
}

const EditOperationDayModal = ({
  isEditModalOpen,
  openEditDayModal,
  selectedDay,
  updateOperation,
  availableOperations,
  setSelectedOperations,
  selectedOperations,
  setAvailableOperations
}: EditOperationDayModalProps) => {
  const handleUpdateDay = async () => {
    openEditDayModal({
      selectedDay: '',
      isEditModalOpen: false
    })
    setAvailableOperations({ availableOperations: selectedOperations })
    for (const operationType of Object.values(OperationTypeValues)) {
      const available = selectedOperations.includes(operationType)

      const operationUpdatePayload = {
        day: translateDayName(selectedDay, 'en'),
        operationType,
        available
      }

      updateOperation(operationUpdatePayload)
    }
  }

  const modalTitle = `Editar operación para el día ${selectedDay}`
  const handleCancelModal = () => {
    openEditDayModal({
      selectedDay: '',
      isEditModalOpen: false
    })
    setAvailableOperations({ availableOperations: [] })
  }

  const selectedCheckboxProps = {
    setSelectedOperations,
    availableOperations,
    selectedOperations
  }

  return (
    <Modal
      width={'max-content'}
      onCancel={handleCancelModal}
      title={modalTitle}
      visible={isEditModalOpen}
      okText="Confirmar edición"
      okButtonProps={{
        disabled: !selectedDay,
        className: styles.modalButtonOk
      }}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText="Cancelar"
      className={styles.ModalWrapper}
      onOk={handleUpdateDay}
      destroyOnClose>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Tipo de operación:</label>
        <SelectCheckbox {...selectedCheckboxProps} />
      </div>
    </Modal>
  )
}

export default EditOperationDayModal
