import React from 'react'
import { Modal } from 'antd'
import { CancelState } from '../types/cancel'
import styles from './CreditNoteWarning.module.scss'
import { useHistory } from 'react-router'
import { formatMoney } from '../../../utils/formatNumber'

type FindUserProps = {
  cancelState: CancelState
  closeModal: () => void
  cancelRemovalAction: () => void
}

const FindUser = (props: FindUserProps) => {
  const { cancelState, closeModal, cancelRemovalAction } = props
  const { modalCreditNoteOpen, selectedUser, isNotCreateCreditNote } = cancelState
  const history = useHistory()
  const onOkClick = () => {
    closeModal()
    cancelRemovalAction()
  }

  return (
    <Modal
      width={697}
      title="Nota de crédito"
      visible={modalCreditNoteOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={closeModal}
      okButtonProps={{
        className: styles.modalButtonOk
      }}
      okText={'Confirmar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p className={styles.description}>
        ¡Atención! Al cancelar esta devolución, {isNotCreateCreditNote && 'no'} se generará automáticamente una nota de
        crédito al usuario asociado con la siguiente información:
      </p>
      <p className={styles.description}>ID del Cliente: {selectedUser?.id}</p>
      <p className={styles.description}>Nombre: {selectedUser?.name}</p>
      <p className={styles.description}>Apellido: {selectedUser?.lastName}</p>
      <p className={styles.description}>Monto Abonado por Flete: {formatMoney(selectedUser?.amount)}</p>
      <p className={styles.description}>¿Está seguro que desea cancelar la devolución?</p>
    </Modal>
  )
}

export default FindUser
