import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

type APIResponse = ObjectStatus[]
export interface ObjectStatus {
  id: number
  description: string
}

type Response = CamelCasedPropertiesDeep<ObjectStatus[]>

export function search(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/object/status/all`)
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse: Response = camelcaseKeys(response.data)
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
