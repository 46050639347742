import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import AgentModalActionCreator from '../actionCreator/agentModal'
import AgentsTableActionCreator from '../actionCreator/agentsTable'
import AgentsTable, { AgentsTableProps } from '../components/AgentsTable'
import { Pagination } from '../types/agentsTable'

const AgentsTableContainer = () => {
  const dispatch = useDispatch()
  const { openModal } = bindActionCreators(AgentModalActionCreator, dispatch)
  const { getAgents } = bindActionCreators(AgentsTableActionCreator, dispatch)

  const { agents, loadingAgents, pagination } = useSelector((state) => state.Agents.table)

  const emptyPagination = { ...pagination, page: 1 }

  const handleGetAgents = (newParams: { pagination?: Pagination }) => {
    getAgents({ pagination: newParams.pagination || pagination })
  }

  useEffect(() => {
    handleGetAgents({ pagination: emptyPagination })
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetAgents({ pagination: { ...pagination, page: newPage } })
  }

  const paging = {
    currentPage: pagination.page,
    pageSize: pagination.pageSize,
    total: pagination.total,
    onPageChange: handlePageChange
  }

  useEvents(Events.Agents.AGENT_CREATED, () => handleGetAgents({ pagination: emptyPagination }))

  const props: AgentsTableProps = {
    agents,
    loadingAgents,
    openModal: () => openModal({ openModal: true }),
    pagination: paging
  }

  return <AgentsTable {...props} />
}

export default AgentsTableContainer
