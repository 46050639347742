import React from 'react'
import { Link, Route } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import routes from '../../routes'
import styles from './breadcrumb.module.scss'

const findRouteName = (url) => routes[url]

const getPaths = (pathname) => {
  const paths = ['/']

  if (pathname === '/') return paths

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`
    paths.push(currPath)
    return currPath
  })
  return paths
}

const BreadcrumbsItem = ({ match }) => {
  const routeName = findRouteName(match.url)
  if (routeName) {
    return match.isExact ? (
      <Breadcrumb.Item active>{routeName}</Breadcrumb.Item>
    ) : (
      <Breadcrumb.Item>
        <Link to={match.url || ''}>{routeName}</Link>
      </Breadcrumb.Item>
    )
  }
  return null
}

const Breadcrumbs = ({ location: { pathname }, match }) => {
  const paths = getPaths(pathname)
  const items = paths.map((path, i) => <Route key={i++} path={path} component={BreadcrumbsItem} />)
  return <Breadcrumb>{items}</Breadcrumb>
}

// eslint-disable-next-line react/display-name
export default (props) => (
  <div className={styles.breadcrum}>
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
)
