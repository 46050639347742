import { API } from '../../projectApi'
import { Operator } from '../../projectApi/TransactionHandler/Pickupers/list'
import { Transaction } from '../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import { AppThunk } from '../../store'
import Emitter from '../../utils/eventEmitter'
import { Events } from '../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import {
  ASSIGN_OPERATORS_FAILURE,
  ASSIGN_OPERATORS_REQUEST,
  ASSIGN_OPERATORS_SUCCESS,
  AssignOperatorsRequest,
  AssignOperatorsSuccess,
  GET_OPERATORS_FAILURE,
  GET_OPERATORS_REQUEST,
  GET_OPERATORS_SUCCESS,
  GetOperatorsRequest,
  GetOperatorsSuccess,
  SET_ASSIGNMENT_MODAL_OPEN,
  SET_SELECTED_OPERATORS,
  SetAssignmentModalOpen,
  SetSelectedOperators
} from './types'

const TransactionOperatorsAssignmentActionCreators = {
  setOpen: ({
    open,
    transactions,
    operators
  }: {
    open: boolean
    transactions?: Transaction[]
    operators?: Operator[]
  }): SetAssignmentModalOpen => ({
    type: SET_ASSIGNMENT_MODAL_OPEN,
    payload: {
      open,
      transactions,
      operators
    }
  }),
  setSelectedOperators: (operators: Operator[]): SetSelectedOperators => ({
    type: SET_SELECTED_OPERATORS,
    payload: {
      operators
    }
  }),
  getOperators: (): AppThunk => async (dispatch) => {
    const request: GetOperatorsRequest = {
      type: GET_OPERATORS_REQUEST
    }

    dispatch(request)

    try {
      const { operators } = await API.TransactionHandler.Pickupers.list({ limit: 200 })

      const success: GetOperatorsSuccess = {
        type: GET_OPERATORS_SUCCESS,
        payload: {
          operators
        }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GET_OPERATORS_FAILURE, dispatch)
    }
  },
  assignOperators:
    ({ operatorIds, transactionIds }: { operatorIds: number[]; transactionIds: number[] }): AppThunk =>
    async (dispatch) => {
      const request: AssignOperatorsRequest = {
        type: ASSIGN_OPERATORS_REQUEST
      }

      dispatch(request)

      try {
        await API.TransactionHandler.Transactions.assignOperatorsToTransactions({
          transactionIds,
          operatorIds
        })

        const success: AssignOperatorsSuccess = {
          type: ASSIGN_OPERATORS_SUCCESS
        }

        Emitter.emit(Events.Transaction.OPERATORS_ASSIGNED_TO_TRANSACTIONS, { transactionIds })
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, ASSIGN_OPERATORS_FAILURE, dispatch)
      }
    }
}

export default TransactionOperatorsAssignmentActionCreators
