/* eslint-disable camelcase */
import { Moment } from 'moment'
import { convertToMoment } from '../../utils'
import camelcaseKeys from 'camelcase-keys'

export interface APIBilling {
  applications: APIApplication[] | null
  authorization_enabled: boolean
  source_type: keyof typeof BillingType
  details?: APIBillingDetail[]
  end_date: string
  id: number
  m3: number
  m3_value: number
  start_date: string
  status: keyof typeof BillingStatus
  total: number
  user_id: number
  created_at: string
}
interface APIBillingDetail {
  end_date: string
  id: number
  item_description: string
  item_id: number
  m3: number
  m3_price: number
  price: number
  start_date: string
  total: number
}

export type Application = {
  amount: number
  type: string
  entityId: number | null
  entityType: string
}

type APIApplication = {
  amount: number
  type: string
  entity_id: number | null
  entity_type: string
}

export enum BillingStatus {
  PAID = 'Pagado',
  PENDING = 'Pendiente',
  CANCELLED = 'Cancelado',
  BILLED = 'Facturado',
  ANNULLED = 'Anulado',
  PROCESSED = 'Procesado'
}

export enum BillingType {
  remittance = 'Ingreso',
  monthly = 'Abono Mensual',
  removal = 'Devolución'
}

export type BillingObject = {
  endDate: Moment | null
  id: number
  itemDescription: string
  itemId: number
  m3: number
  m3Price: number
  proportionalPrice: number
  startDate: Moment | null
  totalPrice: number
}

export type Billing = {
  applications: Application[] | null
  authorizationEnabled: boolean
  objects: BillingObject[]
  endDate: Moment | null
  id: number
  m3: number
  m3Value: number
  startDate: Moment | null
  sourceType: keyof typeof BillingType
  status: keyof typeof BillingStatus
  totalPrice: number
  userId: number
  createdAt: Moment | null
}

export const formatBilling = (apiBilling: APIBilling): Billing => {
  const billing = camelcaseKeys(apiBilling, { deep: true })

  return {
    id: billing.id,
    userId: billing.userId,
    createdAt: convertToMoment(billing.createdAt),
    startDate: convertToMoment(billing.startDate),
    endDate: convertToMoment(billing.endDate),
    m3: billing.m3,
    m3Value: billing.m3Value,
    totalPrice: billing.total,
    status: billing.status,
    objects:
      billing.details?.map((detail) => ({
        id: detail.id,
        itemId: detail.itemId,
        itemDescription: detail.itemDescription,
        startDate: convertToMoment(detail.startDate),
        endDate: convertToMoment(detail.endDate),
        m3Price: detail.m3Price,
        proportionalPrice: detail.price,
        totalPrice: detail.total,
        m3: detail.m3
      })) || [],
    authorizationEnabled: billing.authorizationEnabled,
    sourceType: billing.sourceType,
    applications: billing.applications
      ? billing.applications.map((application) => ({
          amount: application.amount,
          type: application.type,
          entityId: application.entityId,
          entityType: application.entityType
        }))
      : null
  }
}
