import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type ListParams = {
  proposalId: string
  email: string
}

export function sendEmail(sendMailProps: ListParams): Promise<void> {
  const params = new FormData()
  params.append('email_address', sendMailProps.email)
  params.append('proposal_id', sendMailProps.proposalId)

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/admin/operation/proposal/notify-proposal-email`, params)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
