import * as React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import styles from './objectChanges.module.scss'
import { ObjectChangesFormatted } from '../types/objectChanges'
import DeletedObjectStatus from '../components/deletedObjectStatus'

export type FormatedDate = JSX.Element | '-' | ''
export type AlignText = 'start' | 'center' | 'end'
type Key = keyof ObjectChangesFormatted

export type DeterminateIfValueChange = (objects: ObjectChangesFormatted[], index: number, key: Key) => boolean

export const determinateIfValueChange: DeterminateIfValueChange = (objects, index, key) => {
  return objects[index + 1] !== undefined && objects[index][key] !== objects[index + 1][key]
}

export const changeSpan = (value: string | number | FormatedDate, alignText: AlignText = 'center'): JSX.Element => {
  return <span className={clsx(styles.changeValueCell, styles[alignText])}>{value}</span>
}

export const formatDate = (d: string | Date | moment.Moment): string =>
  moment(d).format('DD/MM/YY HH:mm').toLocaleString()

type RenderObjectIDStatus = (
  deletedReason: string | null,
  objectChanges: ObjectChangesFormatted[],
  record: ObjectChangesFormatted,
  index: number
) => JSX.Element | string | number

export const renderObjectIDStatus: RenderObjectIDStatus = (deletedReason, objectChanges, record, index) => {
  const value = record.objectStatusId
  const isDeleted = record.deletedAt

  const objectStatusChanged = determinateIfValueChange(objectChanges, index, 'objectStatusId')

  if (isDeleted) {
    const isDeletedChanged =
      determinateIfValueChange(objectChanges, index, 'deletedAt') ||
      determinateIfValueChange(objectChanges, index, 'deletedDescription') ||
      objectStatusChanged

    const deletedObject = <DeletedObjectStatus objectStatus={record.objectStatusId} deletedReason={deletedReason} />
    return isDeletedChanged ? changeSpan(deletedObject) : deletedObject
  }
  return objectStatusChanged ? changeSpan(value) : value
}
