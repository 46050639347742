import React from 'react'
import styles from './AMProducts.module.scss'
import RowBackIcon from '../../../NewProduct/components/Step/icons/RowBack'
import { TextField } from '../../../../components/TextField/TextField'
import InputGroup from '../../../../components/InputGroup'
import { Row } from '../../../NewProduct/components/subcomponents/GeneralData'
import UploadPhotos from '../../../../components/UploadPhotos/container'
import { CategoryOption, ProductFormFields } from '../types/ABMProducts'
import { Button } from '../../../../components/Button/Button'
import Select from '../../../../components/Select'

export type AMCorporateProductsProps = {
  productFormFields: ProductFormFields
  categoryOptions: CategoryOption[]
  selectedCategoryOption?: CategoryOption
  editMode: boolean
  loadingButton: boolean
  disabledSubmitButton: boolean
  handleBack: () => void
  setCategory: (categoryOption?: CategoryOption) => void
  setFormField: ({ field, value }: { field: keyof ProductFormFields; value: string }) => void
  handleCreateProduct: () => void
}

const AMCorporateProducts: React.FC<AMCorporateProductsProps> = ({
  productFormFields,
  categoryOptions,
  selectedCategoryOption,
  editMode,
  loadingButton,
  disabledSubmitButton,
  handleBack,
  setCategory,
  setFormField,
  handleCreateProduct
}) => {
  const [autocomplete, setAutocomplete] = React.useState('')

  const titleMessage = editMode ? 'Editá los datos del producto' : 'Ingresá los datos del producto'
  const submittButtonMessage = editMode ? 'Editar producto' : 'Crear producto'

  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={handleBack}>
        <RowBackIcon />
      </button>
      <h1 className={styles.title}>{titleMessage}</h1>
      <Row>
        <TextField
          label="Nombre del producto"
          maxLength={50}
          onChange={(value) => setFormField({ field: 'name', value })}
          value={productFormFields.name}
          className={styles.fieldName}
        />
        <Select
          onSelect={(value) => setCategory(value)}
          options={categoryOptions}
          selected={selectedCategoryOption}
          placeholder="Seleccione una categoría"
          className={styles.selectCategory}
          autocomplete={{
            filterByInput: true,
            input: autocomplete,
            onInputChange: setAutocomplete
          }}
          onDelete={() => setCategory(undefined)}
        />
      </Row>
      <InputGroup title="Dimensiones" style={{ paddingLeft: 16, paddingRight: 16, maxWidth: 630 }}>
        <Row>
          <TextField
            label="Peso (Kg)"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'dimmensionsWeightInKg', value })}
            value={productFormFields.dimmensionsWeightInKg}
            error={undefined}
          />
          <TextField
            label="Largo (cm)"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'dimmensionsLengthInCm', value })}
            value={productFormFields.dimmensionsLengthInCm}
            error={undefined}
          />
          <TextField
            label="Ancho (cm)"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'dimmensionsWidthInCm', value })}
            value={productFormFields.dimmensionsWidthInCm}
            error={undefined}
          />
          <TextField
            label="Alto (cm)"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'dimmensionsHeightInCm', value })}
            value={productFormFields.dimmensionsHeightInCm}
            error={undefined}
          />
        </Row>
      </InputGroup>

      <InputGroup title="Desviación" style={{ paddingLeft: 16, paddingRight: 16, maxWidth: 630 }}>
        <Row>
          <TextField
            label="Mínimo (%)"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'deviationMinPercentage', value })}
            value={productFormFields.deviationMinPercentage}
            error={undefined}
          />
          <TextField
            label="Máximo (%)"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'deviationMaxPercentage', value })}
            value={productFormFields.deviationMaxPercentage}
            error={undefined}
          />
        </Row>
      </InputGroup>
      <InputGroup title="Multimedia" style={{ paddingLeft: 16, paddingRight: 16, maxWidth: 630 }}>
        <Row>
          <TextField
            label="Mínimo de fotos por objeto"
            maxLength={10}
            inputProps={{
              type: 'number'
            }}
            onChange={(value) => setFormField({ field: 'multimediaMinPhotos', value })}
            value={productFormFields.multimediaMinPhotos}
            error={false}
          />
        </Row>
        <div>
          <h4 className={styles.productPhotoHeader}>Imagen del producto</h4>
          <UploadPhotos
            fileType="products"
            uploadedPhoto={productFormFields.multimediaPhotoUrl}
            onDropCallback={() => {}}
          />
        </div>
      </InputGroup>
      <Button
        label={submittButtonMessage}
        className={styles.button}
        onClick={handleCreateProduct}
        loading={loadingButton}
        disabled={disabledSubmitButton}
      />
    </div>
  )
}

export default AMCorporateProducts
