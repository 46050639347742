import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TIMESLOT } from '../../../endpoints'

type TimeintervalAPI = {
  Enabled: number
  End: number
  ID: number
  Start: number
}

export type TimeInterval = {
  enabled: number
  end: number
  id: number
  start: number
}

interface Response {
  timeIntervals: TimeInterval[]
}

interface APIResponse {
  description: TimeintervalAPI[]
  success: boolean
}

interface ListParams {
  timeslotId: number
}

export function timeIntervals(params: ListParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TIMESLOT}/timeInterval?time_slot_selected=${params.timeslotId}`)
    .then((response: AxiosResponse<APIResponse>) => {
      const timeIntervals = response.data.description

      const formatResponse: Response = {
        timeIntervals: timeIntervals.map(({ Enabled, End, ID, Start }) => {
          return {
            enabled: Enabled,
            end: End,
            id: ID,
            start: Start
          }
        })
      }

      return formatResponse
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
