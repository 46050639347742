import React from 'react'

const DocumentDownload = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33366 1.513V4.26669C7.33366 4.64006 7.33366 4.82675 7.40632 4.96935C7.47024 5.0948 7.57222 5.19678 7.69767 5.2607C7.84027 5.33336 8.02696 5.33336 8.40033 5.33336H11.154M4.00033 9.99998L6.00033 12M6.00033 12L8.00033 9.99998M6.00033 12L6.00033 7.99998M7.33366 1.33331H3.86699C2.74689 1.33331 2.18683 1.33331 1.75901 1.5513C1.38269 1.74305 1.07673 2.04901 0.884979 2.42533C0.666992 2.85316 0.666992 3.41321 0.666992 4.53331V11.4666C0.666992 12.5868 0.666992 13.1468 0.884979 13.5746C1.07673 13.951 1.38269 14.2569 1.75901 14.4487C2.18683 14.6666 2.74689 14.6666 3.86699 14.6666H8.13366C9.25376 14.6666 9.81382 14.6666 10.2416 14.4487C10.618 14.2569 10.9239 13.951 11.1157 13.5746C11.3337 13.1468 11.3337 12.5868 11.3337 11.4666V5.33331L7.33366 1.33331Z"
        stroke="#5D58F7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DocumentDownload
