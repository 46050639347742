/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

interface CancelParams {
  tripId: number
}

export function cancel({ tripId }: CancelParams): Promise<void> {
  return apiPrivate
    .put(`${URL_BASE_TRIPS}/trips/${tripId}/cancel`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
