import React from 'react'
import actionCreators from '../actions/actionsModal'
import reasonsActionCreators from '../actions/reasonsModal'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/stepsModals/ActionsModal'
import { bindActionCreators } from 'redux'
import { FunnelState } from '../constants'

const StepModalContainer = () => {
  const dispatch = useDispatch()
  const modalProps = useSelector((state) => state.Leads.actionsModal)
  const modalActions = bindActionCreators(actionCreators, dispatch)
  const reasonsActions = bindActionCreators(reasonsActionCreators, dispatch)

  const { openModal, leadId, leadFunnelState } = modalProps
  const { setOpenModal } = modalActions
  const { setOpenModal: setOpenReasonsModal } = reasonsActions

  const handleClose = () => {
    setOpenModal(false)
  }

  const openReasonsModal = (leadId: number, leadFunnelState?: FunnelState) => {
    setOpenReasonsModal(true, leadId, leadFunnelState)
  }

  const props = {
    openModal,
    leadId,
    leadFunnelState,
    handleClose,
    openReasonsModal
  }

  return <Component {...props} />
}

export default StepModalContainer
