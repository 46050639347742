import { ComplaintHistory } from '@/projectApi/Complaints/list'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { Photo } from '../types/createComplaintModal'
import {
  GetAgentsRequest,
  GetAgentsSuccess,
  GetAgentsTypes,
  GetComplaintByIdRequest,
  GetComplaintByIdSuccess,
  GetComplaintByIdTypes,
  Priority,
  SET_OPEN_EVIDENCE,
  setAgent,
  setDescription,
  setEvidence,
  SetOpenEvidence,
  setPriority,
  SettersUpdateComplaintTypes,
  UpdateComplaintRequest,
  UpdateComplaintSuccess,
  UpdateComplaintTypes
} from '../types/updateView'
import { DELETE_EVIDENCE, deleteEvidence, GetComplaintsReasonsTypes, HANDLE_CLEAR, HandleClear } from '../types/common'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import { Events } from '@/utils/eventEmitter/events'
import Emitter from '@/utils/eventEmitter'

const ComplaintUpdateViewActionCreator = {
  getComplaintById:
    (complaintId: number, silentLoading?: boolean): AppThunk =>
    async (dispatch) => {
      const request: GetComplaintByIdRequest = {
        type: GetComplaintByIdTypes.GET_COMPLAINT_VIEW_REQUEST,
        payload: {
          silentLoading
        }
      }

      dispatch(request)

      try {
        const { complaints } = await API.Complaints.list({
          id: complaintId,
          limit: 1,
          withObjects: true,
          withUsers: true
        })

        const success: GetComplaintByIdSuccess = {
          type: GetComplaintByIdTypes.GET_COMPLAINT_VIEW_SUCCESS,
          payload: {
            complaint: complaints[0]
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetComplaintByIdTypes.GET_COMPLAINT_VIEW_FAILURE, dispatch)
      }
    },

  updateComplaint:
    (updatedComplaint: any): AppThunk =>
    async (dispatch) => {
      const request: UpdateComplaintRequest = {
        type: UpdateComplaintTypes.UPDATE_COMPLAINT_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Actualizando reclamo...')

      try {
        const { complaint } = await API.Complaints.update(updatedComplaint)

        const success: UpdateComplaintSuccess = {
          type: UpdateComplaintTypes.UPDATE_COMPLAINT_SUCCESS,
          payload: {
            complaint
          }
        }
        dispatch(success)
        Emitter.emit(Events.Complaints.COMPLAINTS_UPDATED)
        setToastSuccessUpdate(toastId, {
          render: `Reclamo actualizado correctamente`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, UpdateComplaintTypes.UPDATE_COMPLAINT_FAILURE, dispatch)
        })
      }
    },

  getAgents: (): AppThunk => async (dispatch) => {
    const request: GetAgentsRequest = {
      type: GetAgentsTypes.GET_AGENTS_REQUEST
    }

    dispatch(request)

    try {
      const { agents } = await API.Mercurio.BO.Agents.list({
        limit: 200,
        offset: 0
      })

      if (agents.length < 2) throw new Error('No se encontraron agentes')

      const success: GetAgentsSuccess = {
        type: GetAgentsTypes.GET_AGENTS_SUCCESS,
        payload: { agents }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GetAgentsTypes.GET_AGENTS_FAILURE, dispatch)
    }
  },

  setOpenEvidence: ({
    selectedEvidence,
    setOpenEvidence
  }: {
    selectedEvidence?: ComplaintHistory
    setOpenEvidence: boolean
  }): SetOpenEvidence => ({
    type: SET_OPEN_EVIDENCE,
    payload: { setOpenEvidence, selectedEvidence }
  }),

  setDescription: (description: string): setDescription => ({
    type: SettersUpdateComplaintTypes.SET_DESCRIPTION,
    payload: { description }
  }),

  setPriority: (priority: keyof typeof Priority | null): setPriority => ({
    type: SettersUpdateComplaintTypes.SET_PRIORITY,
    payload: { priority }
  }),

  setAgent: (assignedUserId: number | null): setAgent => ({
    type: SettersUpdateComplaintTypes.SET_AGENT,
    payload: { assignedUserId }
  }),

  setEvidence: (photo: Photo[]): setEvidence => ({
    type: SettersUpdateComplaintTypes.SET_EVIDENCE,
    payload: { photo }
  }),

  deleteEvidence: (index: number): deleteEvidence => ({
    type: DELETE_EVIDENCE,
    payload: { index }
  }),

  getReasons(): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: GetComplaintsReasonsTypes.GET_REASONS_REQUEST
      })
      try {
        const { reasons } = await API.Complaints.reasons({
          column: 'id',
          order: 'desc'
        })
        dispatch({
          type: GetComplaintsReasonsTypes.GET_REASONS_SUCCESS,
          payload: {
            reasons
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetComplaintsReasonsTypes.GET_REASONS_FAILURE, dispatch)
      }
    }
  },
  handleClear: (): HandleClear => ({
    type: HANDLE_CLEAR
  })
}

export default ComplaintUpdateViewActionCreator
