import { Donation } from '../../../projectApi/Sales/DonationRequest/common'

export type DonationViewState = {
  donation: Donation | null
  error: string
  isLoading: boolean
}

export const CLEAR_STATE = 'DONATIONS/DONATION_VIEW/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export type CommonAction = ClearState

export enum AcceptDonationTypes {
  ACCEPT_DONATION_REQUEST = 'DONATIONS/DONATION_VIEW/ACCEPT_DONATION_REQUEST',
  ACCEPT_DONATION_SUCCESS = 'DONATIONS/DONATION_VIEW/ACCEPT_DONATION_SUCCESS',
  ACCEPT_DONATION_FAILURE = 'DONATIONS/DONATION_VIEW/ACCEPT_DONATION_FAILURE'
}

export interface AcceptDonationRequest {
  type: typeof AcceptDonationTypes.ACCEPT_DONATION_REQUEST
}

export interface AcceptDonationSuccess {
  type: typeof AcceptDonationTypes.ACCEPT_DONATION_SUCCESS
}

export interface AcceptDonationFailure {
  type: typeof AcceptDonationTypes.ACCEPT_DONATION_FAILURE
  payload: { error: string }
}

export type AcceptDonationAction = AcceptDonationRequest | AcceptDonationSuccess | AcceptDonationFailure

export enum RejectDonationTypes {
  REJECT_DONATION_REQUEST = 'DONATIONS/DONATION_VIEW/REJECT_DONATION_REQUEST',
  REJECT_DONATION_SUCCESS = 'DONATIONS/DONATION_VIEW/REJECT_DONATION_SUCCESS',
  REJECT_DONATION_FAILURE = 'DONATIONS/DONATION_VIEW/REJECT_DONATION_FAILURE'
}
export interface RejectDonationRequest {
  type: typeof RejectDonationTypes.REJECT_DONATION_REQUEST
}

export interface RejectDonationSuccess {
  type: typeof RejectDonationTypes.REJECT_DONATION_SUCCESS
}

export interface RejectDonationFailure {
  type: typeof RejectDonationTypes.REJECT_DONATION_FAILURE
  payload: { error: string }
}

export type RejectDonationAction = RejectDonationRequest | RejectDonationSuccess | RejectDonationFailure

export enum GetDonationByIdTypes {
  GET_DONATION_BY_ID_REQUEST = 'DONATION/GET_DONATION_BY_ID_REQUEST',
  GET_DONATION_BY_ID_SUCCESS = 'DONATION/GET_DONATION_BY_ID_SUCCESS',
  GET_DONATION_BY_ID_FAILURE = 'DONATION/GET_DONATION_BY_ID_FAILURE'
}
export interface GetDonationByIdRequest {
  type: typeof GetDonationByIdTypes.GET_DONATION_BY_ID_REQUEST
}

export interface GetDonationByIdSuccess {
  type: typeof GetDonationByIdTypes.GET_DONATION_BY_ID_SUCCESS
  payload: {
    donation: Donation
  }
}

export interface GetDonationByIdFailure {
  type: typeof GetDonationByIdTypes.GET_DONATION_BY_ID_FAILURE
  payload: {
    error: string
  }
}

type GetDonationActions = GetDonationByIdRequest | GetDonationByIdSuccess | GetDonationByIdFailure

export enum DonateTypes {
  DONATE_REQUEST = 'DONATION/DONATE_REQUEST',
  DONATE_SUCCESS = 'DONATION/DONATE_SUCCESS',
  DONATE_FAILURE = 'DONATION/DONATE_FAILURE'
}
export interface DonateRequest {
  type: typeof DonateTypes.DONATE_REQUEST
}

export interface DonateSuccess {
  type: typeof DonateTypes.DONATE_SUCCESS
}

export interface DonateFailure {
  type: typeof DonateTypes.DONATE_FAILURE
  payload: {
    error: string
  }
}

type DonateActions = DonateRequest | DonateSuccess | DonateFailure

export type DonationViewAction =
  | AcceptDonationAction
  | RejectDonationAction
  | CommonAction
  | GetDonationActions
  | DonateActions
