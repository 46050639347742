import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import snakecaseKeys from 'snakecase-keys'

interface APIRequest {
  service_type_id: string
  total_weight: string
  packaging_cost: string
  disassembly_cost: string
  cost_per_floor_in_cents: string
  items: string
  prospect_id: string
  proposal_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  identification_type: string
  dni: string
  landline: string
  country_code: string
  lead_creation_date: string
  date_of_birth: string
  gender: string
  time_slot_id: string
  time_interval: string
  date: string
  transport_cost: string
  toll_cost_in_cents?: number
  storage_cost: string
  total_additional_cost?: string
  additional_cost_comment?: string
  transport_mode_type_id: string
  deposit_id: string
  people_id: string
  shipment_assistant_count: string
  shipment_assistant_price: string
  address_street?: string
  address_string?: string
  address_number?: string
  address_floor?: string
  address_apartment?: string
  address_postal_code?: string
  address_city?: string
  address_province?: string
  transport_type_pricing?: string
  comment?: string
  notify_user_email?: string
  email_address?: string
  payment_type: string
  card_id?: string
  cbu?: string
  cbu_holder?: string
  discount_code?: string
  apply_discount?: string
  detail?: string
}

export type CreateDepositRequest = CamelCasedPropertiesDeep<APIRequest>

export function create(request: CreateDepositRequest, config: AxiosRequestConfig): Promise<any> {
  const formattedRequest: APIRequest = snakecaseKeys(request, { deep: true })
  const formData = new FormData()
  Object.entries(formattedRequest).forEach(([key, value]) => {
    formData.append(key, value)
  })
  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/admin/operation/proposal/deposit`, formData, config)
    .then((response: AxiosResponse<any>) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}
