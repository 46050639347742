import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { AddressDetail } from './common'

type depositUpdateParams = {
  name: string
  countryCode: string
  depositId?: number
  addressId?: number
  addressDetail: Partial<AddressDetail>
}

export function update(params: depositUpdateParams): Promise<void> {
  const formattedParams = snakecaseKeys(params, { deep: true })
  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/deposits`, formattedParams, { headers: { 'x-origin': null } })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
