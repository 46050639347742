/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { TripTransaction, TripTransactionError } from './create'
import axios from 'axios'

interface AddTransactionsParams {
  tripId?: number
  transactions: TripTransaction[]
}

export function addTransactions(params: AddTransactionsParams): Promise<void> {
  const data = snakecaseKeys({
    transactions: params.transactions
  })

  return apiPrivate
    .put(`${URL_BASE_TRIPS}/trips/${params.tripId}/add-transactions`, data)
    .then(() => {})
    .catch((err) => {
      let errorMessage = 'Algo salió mal'
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.code) {
          switch (err.response.data.code as TripTransactionError) {
            case 'TNED-1':
              errorMessage = 'Las transacciones tienen distintos destinos'
              break
            case 'TNEO-1':
              errorMessage = 'Las transacciones tienen distintos orígenes'
              break
            case 'TNET-1':
              errorMessage = 'Las transacciones son de distintos tipos'
              break
            case 'TTNF-1':
              errorMessage = 'Tipo de transacción no encontrada'
              break
            case 'TCA-1':
              errorMessage = 'Una transacción tiene un estado incorrecto para crear el viaje'
              break
          }
        }
      }
      throw errorMessage
    })
}
