import React from 'react'

interface BsChevronDownProps {
  size?: number
  className?: string
}

const BsChevronDown: React.FC<BsChevronDownProps> = ({ size = '1em', className }) => (
  <svg
    className={className}
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    height={`${size}px`}
    width={`${size}px`}
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
    />
  </svg>
)

export default BsChevronDown
