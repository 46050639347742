import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { getColumnSearchProps } from '../../../utils/columnSearchProps'
import { Removal, User, RemovalFilter } from '../types/removals'
import { SortOrder } from 'antd/lib/table'
import { STATUS_FILTERS, STATUS_FILTERS_PROPS } from '../constants'
import { STATUS_VALUES } from '../../../common/operationsTable/constants'
import { usePaginationLabel } from '../../../utils/hooks/usePaginationLabel'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import ThreeDotMenu from '../../../components/actionMenu/threeDotMenu'
import { Edit, MoneyIcon, SignIn, TextFile } from '../../../icons'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import ColorBadge from '../../../components/ColorBadge'
import { Country } from '../../../components/CountrySelector/constants/constants'
import nextStatusActionCreators from '../actions/nextStatus'
import { RemovalTableState } from '../reducers/removals'
import { PaginationConfig, SorterResult } from 'antd/es/table'
import styles from './Table.module.scss'

const DELIVERED_ID = 8

const formatDate = (date: string) => moment(date).local().format('DD/MM/YY HH:mm')

export type RemovalsTableProps = {
  countries: Country[]
  tableState: RemovalTableState
  nextStatusActions: typeof nextStatusActionCreators
  handleChange: (pagination: PaginationConfig, filters: RemovalFilter, sorter: SorterResult<any>) => void
  handleDateRangeSearch: (dataIndex: string, text: [string, string], confirm?: () => void) => void
  handleSearch: (dataIndex: string, text: string, confirm: () => void) => void
  handleTransportDateRangeSearch: (dataIndex: string, text: [string, string], confirm: () => void) => void
}

const RemovalsTable = (props: RemovalsTableProps) => {
  const {
    countries,
    tableState,
    nextStatusActions,
    handleSearch,
    handleChange,
    handleDateRangeSearch,
    handleTransportDateRangeSearch
  } = props
  const { loading, pagination: currentPagination, filter, searchFilters, params, removals } = tableState

  const onNextActionClick = (row: Removal) => () => {
    const { core_id: id, status, debt_amount } = row
    if (status === STATUS_VALUES.CONFIRMED) {
      nextStatusActions.toggleUpdateDebtStatusModal(true, id)
    }
    if (status === STATUS_VALUES.PENDING_PAYMENT) {
      nextStatusActions.toggleInformPaymentModal(true, id, debt_amount)
    }
    if (status === STATUS_VALUES.AUTHORIZED) {
      nextStatusActions.toggleCloseRemovalModal(true, id)
    }
  }

  const getActionPerStatus = (row: Removal): MenuAction<Removal>[] => {
    const { id: transactionID, transaction_status_id } = row.transaction

    const reportClosure = () => {
      const disabled = transaction_status_id !== DELIVERED_ID
      const tooltip = disabled
        ? `La devolución no se puede cerrar hasta que la transacción con ID ${transactionID} sea entregada`
        : ''
      return {
        label: 'Informar cierre',
        icon: <TextFile width="14px" height="14px" />,
        onClick: onNextActionClick(row),
        tooltip,
        disabled
      }
    }

    const editAction = {
      label: 'Editar devolución',
      icon: <Edit />,
      onClick: () => nextStatusActions.toggleUpdateDebtStatusModal(true, row.core_id)
    }
    switch (row.status) {
      case STATUS_VALUES.CONFIRMED:
        return [{ label: 'Iniciar', icon: <SignIn />, onClick: onNextActionClick(row) }]
      case STATUS_VALUES.AUTHORIZED:
        return [editAction, reportClosure()]
      case STATUS_VALUES.PROGRAMMED:
        return [editAction, reportClosure()]
      case STATUS_VALUES.PROGRAMMED_M:
        return [editAction, reportClosure()]
      case STATUS_VALUES.PENDING_PAYMENT:
        return [editAction, { label: 'Informar pago', icon: <MoneyIcon />, onClick: onNextActionClick(row) }]
      default:
        return []
    }
  }

  const columns = [
    {
      key: 'country_code',
      title: 'País',
      maxWidth: '30px',
      render: (removal: Removal) => {
        const flag = getCurrentCountry(countries, removal.country_code)

        if (!flag) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend' as SortOrder,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
      width: 80,
      ...getColumnSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      title: 'N° de Operación',
      dataIndex: 'operation_number',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 100,
      ...getColumnSearchProps('operation_number', 'Operación', handleSearch, searchFilters)
    },
    {
      title: 'ID Cliente',
      dataIndex: 'user[id]',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 60,
      render: (id: number) => <ClientIDLinkComponent id={id}>{id}</ClientIDLinkComponent>,
      ...getColumnSearchProps('user_id', 'ID Usuario', handleSearch, searchFilters)
    },
    {
      title: 'Nombre y Apellido',
      dataIndex: 'user',
      render: (user: User) => `${user.name} ${user.last_name}`
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'created_at',
      render: formatDate,
      ...getColumnSearchProps('created_at', 'Fecha de creación', handleDateRangeSearch, searchFilters, 'rangepicker')
    },
    {
      title: 'Fecha de retiro',
      dataIndex: 'datetime',
      render: formatDate,
      ...getColumnSearchProps(
        'transport_datetime',
        'Fecha de retiro',
        handleTransportDateRangeSearch,
        searchFilters,
        'rangepicker'
      )
    },
    {
      title: 'Estado',
      width: 140,
      dataIndex: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      filters: STATUS_FILTERS,
      filterMultiple: true,
      filteredValue: filter.status,
      render: (text: string) => {
        const found = STATUS_FILTERS_PROPS.find((sf) => sf.value === text)
        if (!found) return text
        const props = {
          ...found.styleProps,
          border: '0.5px solid'
        }
        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    },
    {
      title: 'Acciones',
      render: (row: Removal) => {
        const actions = getActionPerStatus(row)

        return (
          row.status !== STATUS_VALUES.CLOSED_PARTIALLY &&
          row.status !== STATUS_VALUES.CLOSED_TOTALLY && <ThreeDotMenu actions={actions} callbackArgs={row} />
        )
      }
    }
  ]

  const showTotal = usePaginationLabel(currentPagination.total || 0, 'devoluciones')

  const pagination = {
    pageSize: params.Limit,
    total: currentPagination.total,
    current: currentPagination.current,
    size: 'small',
    style: {
      margin: '12px 0'
    },
    showTotal
  }

  return (
    <Table
      columns={columns}
      dataSource={removals}
      loading={loading}
      pagination={pagination}
      scroll={{ x: false }}
      onChange={handleChange}
      rowKey="id"
      className={styles.customTableProps}
    />
  )
}

export default RemovalsTable
