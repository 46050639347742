import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'
import { ProductListSortKey, ProductsListCategoryFilterKey } from '../../../../projectApi/CategoryCreation/Product/list'
import { CategoryFilter, SearchFilter, Sort } from '../../../../utils/searchFilterUtils'
import { Category as SearchCategory } from '../../../../projectApi/CategoryCreation/Search/Categories/list'

export type ProductsTableSortKey = ProductListSortKey
export type ProductsTableSort = Sort<ProductsTableSortKey>

export type ProductsTableSearchKey = 'id' | 'name' | 'showInSearch' | 'deprecated' | 'categoryId'
export type ProductsSearchFilter = SearchFilter<ProductsTableSearchKey>

export type ProductsCategoryFilter = CategoryFilter<ProductsListCategoryFilterKey>

export type ParamsType = {
  Limit: number
  Offset: number
  Order: string
  Column: ProductsTableSortKey
}

export type SearchFiltersType = [
  {
    key: string
    text: any | null
  }
]
export interface ProductsTableState {
  products: FormattedProduct[]
  loadingProducts: boolean
  error: string
  pagination: Pagination
  params: ParamsType
  searchFilters: ProductsSearchFilter[]
  Column: ProductsTableSort
  sort: ProductsTableSort
  categoryFilter: ProductsCategoryFilter
  allCategories: Category[]
  categories: {
    fetching: boolean
    list: SearchCategory[]
    error: string
  }
  categoryId: number
}

export const GET_PRODUCTS_REQUEST = 'CORPORATIVE/PRODUCTS/TABLE/GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'CORPORATIVE/PRODUCTS/TABLE/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'CORPORATIVE/PRODUCTS/TABLE/GET_PRODUCTS_FAILURE'

export const GET_ALL_CATEGORIES_SUCCESS = 'CORPORATIVE/PRODUCTS/GET_ALL_CATEGORIES_SUCCESS'
export const GET_SERVICE_TYPE_SUCCESS = 'CORPORATIVE/PRODUCTS/GET_SERVICE_TYPE_SUCCESS'
export interface GetProductsRequest {
  type: typeof GET_PRODUCTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: ProductsSearchFilter[]
    Column?: ProductsTableSort
    sort: ProductsTableSort
    categoryFilter: ProductsCategoryFilter
    categoryId?: number
  }
}

export interface GetProductsSuccess {
  type: typeof GET_PRODUCTS_SUCCESS
  payload: {
    products: FormattedProduct[]
    total: number
  }
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface GetProductsFailure {
  type: typeof GET_PRODUCTS_FAILURE
  payload: {
    error: string
  }
}

export type GetCategories = {
  type: typeof GET_ALL_CATEGORIES_SUCCESS
  payload: { categories: Category[] }
}

export type Category = {
  value: number
  label: string
}

export enum CorporateProductsTableTypes {
  CLEAN_STATE = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/CLEAN_STATE'
}

export interface CleanState {
  type: typeof CorporateProductsTableTypes.CLEAN_STATE
}

export enum SearchCategoriesTypes {
  SEARCH_CATEGORIES_REQUEST = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/SEARCH_CATEGORIES_REQUEST',
  SEARCH_CATEGORIES_SUCCESS = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/SEARCH_CATEGORIES_SUCCESS',
  SEARCH_CATEGORIES_FAILURE = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/SEARCH_CATEGORIES_FAILURE'
}

export interface SearchCategoriesRequest {
  type: typeof SearchCategoriesTypes.SEARCH_CATEGORIES_REQUEST
}

export interface SearchCategoriesSuccess {
  type: typeof SearchCategoriesTypes.SEARCH_CATEGORIES_SUCCESS
  payload: { categories: SearchCategory[] }
}

export interface SearchCategoriesFailure {
  type: typeof SearchCategoriesTypes.SEARCH_CATEGORIES_FAILURE
}

export type SearchCategoriesActions = SearchCategoriesRequest | SearchCategoriesSuccess | SearchCategoriesFailure

export type GetProductsActions = GetProductsRequest | GetProductsSuccess | GetProductsFailure | GetCategories

export type ProductsTableActions = GetProductsActions | CleanState | SearchCategoriesActions
