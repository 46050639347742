export enum CorporateProductsEnum {
  CLEAN_STATE = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/CLEAN_STATE',
  CREATE_PRODUCT = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/CREATE_PRODUCT',
  UPDATE_PRODUCT = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/UPDATE_PRODUCT',
  DELETE_PRODUCT = 'CORPORATIVE/PRODUCTS/PRODUCTS_TABLE/DELETE_PRODUCT'
}

export type CorporateProductsEvents = {
  [CorporateProductsEnum.CLEAN_STATE]: () => void
  [CorporateProductsEnum.CREATE_PRODUCT]: () => void
  [CorporateProductsEnum.UPDATE_PRODUCT]: () => void
  [CorporateProductsEnum.DELETE_PRODUCT]: () => void
}
