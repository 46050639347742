import React from 'react'
import Component from '../component/informPayment'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import nextStatusActionCreators from '../actions/nextStatus'

const Container = () => {
  const nextStatusState = useSelector((state) => state.RemovalsValidation.nextStatus)

  const dispatch = useDispatch()
  const nextStatusActions = bindActionCreators(nextStatusActionCreators, dispatch)

  const props = {
    nextStatusState,
    nextStatusActions
  }

  return <Component {...props} />
}

export default Container
