import React from 'react'
import { bindActionCreators } from 'redux'
import actions from '../actions/defaultCBUs'
import Component from '../components/defaultCBU'
import { useDispatch, useSelector } from 'react-redux'
import cbuActions from '../../CBUs/actions/cbus'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const defaultCbuActionCreator = bindActionCreators(actions, dispatch)
  const cbuActionsCreator = bindActionCreators(cbuActions, dispatch)

  const { defaultCbus, cbus } = useSelector((state) => state.Cbus)

  const { params: paramsFromCbu } = cbus

  const props = {
    ...ownProps,
    defaultCbuActionCreator,
    cbuActionsCreator,
    defaultCbus,
    paramsFromCbu
  }

  return <Component {...props} />
}

export default Container
