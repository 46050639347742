import types from '../types/removePhoto'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const actions = {
  selectPhoto: (objectId, photoId, url) => ({
    type: types.SELECT_PHOTO,
    payload: {
      objectId,
      photoId,
      url
    }
  }),
  setDeletedDescription: (deletedDescription) => ({
    type: types.SET_DELETED_DESCRIPTION,
    payload: { deletedDescription }
  }),
  cancel: () => ({ type: types.CANCEL }),
  removePhoto: (objectId, photoId, deletedDescription) => {
    return function (dispatch) {
      dispatch({ type: types.REMOVE_PHOTO_REQUEST })
      const params = { deletedDescription }

      return apiPrivate
        .delete(`${URL_BASE_OBJECTS}/object/${objectId}/photo/${photoId}`, {
          params
        })
        .then(
          (response) => {
            dispatch(actions.removePhotoSuccess(objectId, photoId, deletedDescription))
            Emitter.emit(Events.Objects.OBJECT_PHOTO_DELETED)

            return true
          },
          (error) => {
            handleErrorsWithAction(error, types.REMOVE_PHOTO_FAILURE, dispatch)
            return false
          }
        )
    }
  },
  removePhotoSuccess: (objectId, photoId, deletedDescription) => ({
    type: types.REMOVE_PHOTO_SUCCESS,
    payload: {
      objectId,
      photoId,
      deletedDescription
    }
  }),
  removePhotoFail: (error) => ({
    type: types.REMOVE_PHOTO_FAILURE,
    payload: { error }
  })
}

export default actions
