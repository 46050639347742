import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { ValueOf } from 'type-fest'
import { FieldsByCountry } from '../../../sections/ProductsV2/utils/utils'

export enum ProductEventsEnum {
  SET_PRODUCT_BY_COUNTRY_FIELD = 'PRODUCT/SET_PRODUCT_BY_COUNTRY_FIELD',
  SET_DIMENSION = 'PRODUCT/SET_DIMENSION'
}

export type ProductEvents = {
  [ProductEventsEnum.SET_PRODUCT_BY_COUNTRY_FIELD]: (
    countryId: CountryIdCode,
    field: keyof FieldsByCountry,
    value: ValueOf<FieldsByCountry>
  ) => void
  [ProductEventsEnum.SET_DIMENSION]: () => void
}
