import React from 'react'
import styles from './CreateNewDiscount.module.scss'
import Select from '../../../../components/Select'
import { CollapsablePanel } from '../../../../components/CollapsablePanel/CollapsablePanel'
import { DiscountType, ReasonOption, RequestData } from '../../types/billingDiscountsModal'
import { DiscountReason } from '../../../../projectApi/TransactionHandler/UserDiscounts/reasons'
import { Moment } from 'moment'
import { DatePicker, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import CurrencyInput from 'react-currency-input-field'
import { useLocalization } from '../../../../packages/localization'

export type CreateNewDiscountSectionProps = {
  newDiscountValue?: string
  newDiscountType: DiscountType
  selectedReason?: ReasonOption
  selectedStartDate?: Moment
  selectedEndDate?: Moment
  discountReasons: RequestData<DiscountReason[]>
  setDiscountReason: (selectedReason: ReasonOption) => void
  setDiscountDate: ({
    selectedStartDate,
    selectedEndDate
  }: {
    selectedStartDate?: Moment
    selectedEndDate?: Moment
  }) => void
  setNewDiscountType: (newDiscountType: DiscountType) => void
  setNewDiscountValue: (newDiscountValue?: string) => void
}

const CreateNewDiscountSection = ({
  newDiscountValue,
  newDiscountType,
  selectedReason,
  selectedStartDate,
  selectedEndDate,
  discountReasons,
  setDiscountReason,
  setDiscountDate,
  setNewDiscountType,
  setNewDiscountValue
}: CreateNewDiscountSectionProps) => {
  const { strings } = useLocalization()
  const header = (
    <div className={styles.createNewDiscountsHeader}>{strings.Billings.DiscountModal.createDiscount.title}</div>
  )

  const discountTypeOptions = [
    {
      value: DiscountType.PERCENTAGE,
      label: strings.Billings.DiscountModal.createDiscount.types.percentage
    },
    {
      value: DiscountType.AMOUNT,
      label: strings.Billings.DiscountModal.createDiscount.types.amount
    }
  ]

  const handleChangeDiscountType = (event: RadioChangeEvent) => {
    setNewDiscountType(event.target.value)
  }

  const reasonOptions: ReasonOption[] = discountReasons.data.map((reason) => ({
    value: reason.id,
    label: reason.description
  }))

  return (
    <CollapsablePanel header={header}>
      <div className={styles.container}>
        <div className={styles.datesContainer}>
          <label className={styles.date}>
            {strings.Billings.DiscountModal.createDiscount.startDate}:
            <DatePicker
              placeholder={strings.Billings.DiscountModal.createDiscount.placeholders.startDatePicker}
              popupStyle={{ zIndex: 9999 }}
              allowClear={false}
              format="DD/MM/YYYY"
              value={selectedStartDate}
              onChange={(selectedStartDate) =>
                selectedStartDate !== null ? setDiscountDate({ selectedStartDate }) : undefined
              }
            />
          </label>
          <label className={styles.date}>
            {strings.Billings.DiscountModal.createDiscount.endDate}:
            <DatePicker
              placeholder={strings.Billings.DiscountModal.createDiscount.placeholders.endDatePicker}
              popupStyle={{ zIndex: 9999 }}
              allowClear={false}
              format="DD/MM/YYYY"
              value={selectedEndDate}
              onChange={(selectedEndDate) =>
                selectedEndDate !== null ? setDiscountDate({ selectedEndDate }) : undefined
              }
              disabledDate={(current) => current?.isBefore(selectedStartDate) || false}
            />
          </label>
        </div>
        <div>
          <div className={styles.radioSection}>
            <span className={styles.sectionTitle}>{strings.Billings.DiscountModal.createDiscount.discountType}:</span>
            <Radio.Group onChange={handleChangeDiscountType} value={newDiscountType}>
              {discountTypeOptions.map((discountType) => (
                <Radio key={discountType.value} value={discountType.value}>
                  {discountType.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          {newDiscountType === DiscountType.PERCENTAGE ? (
            <PercentageInput inputValue={newDiscountValue} setInputValue={setNewDiscountValue} />
          ) : (
            <AmountInput inputValue={newDiscountValue} setInputValue={setNewDiscountValue} />
          )}
        </div>
        <div>
          <span className={styles.sectionTitle}>{strings.Billings.DiscountModal.createDiscount.discountReason}:</span>
          <Select
            position="top"
            selected={selectedReason}
            options={reasonOptions}
            onSelect={setDiscountReason}
            placeholder={strings.Billings.DiscountModal.createDiscount.placeholders.selectReason}
            disabled={Boolean(discountReasons.errorMessage)}
            loading={discountReasons.fetching}
          />
        </div>
      </div>
    </CollapsablePanel>
  )
}

export default CreateNewDiscountSection

const PercentageInput = ({
  inputValue,
  setInputValue
}: {
  inputValue?: string
  setInputValue: (newValue: string) => void
}) => {
  const { strings } = useLocalization()
  const handleSetInputValue = (newValue: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(newValue.target.value) > 100 || newValue.target.value.includes('-')) return
    setInputValue(newValue.target.value)
  }

  return (
    <div className={styles.inputPercentageContainer}>
      <input
        type="number"
        value={inputValue}
        onChange={handleSetInputValue}
        placeholder={strings.Billings.DiscountModal.createDiscount.placeholders.percentageInput}
        step="1"
        min={0}
        max={100}
      />
      <span className={styles.suffix}>%</span>
    </div>
  )
}

const AmountInput = ({
  inputValue,
  setInputValue
}: {
  inputValue?: string
  setInputValue: (newValue?: string) => void
}) => {
  const { strings } = useLocalization()
  const handleSetInputValue = (newValue: string | undefined) => {
    setInputValue(newValue)
  }

  return (
    <div className={styles.inputContainer}>
      <span className={styles.prefix}>$</span>
      <CurrencyInput
        maxLength={20}
        value={inputValue}
        decimalScale={2}
        decimalsLimit={2}
        intlConfig={{ locale: 'es-AR' }}
        onValueChange={handleSetInputValue}
        placeholder={strings.Billings.DiscountModal.createDiscount.placeholders.amountInput}
        allowNegativeValue={false}
      />
    </div>
  )
}
