import React from 'react'

const MailLine: React.FC = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3335 9.99996L9.90492 5.99996M6.0954 5.99996L1.66685 9.99996M1.3335 2.66663L6.77678 6.47692C7.21756 6.78547 7.43795 6.93974 7.67767 6.9995C7.88943 7.05228 8.1109 7.05228 8.32265 6.9995C8.56238 6.93974 8.78277 6.78547 9.22355 6.47692L14.6668 2.66663M4.5335 11.3333H11.4668C12.5869 11.3333 13.147 11.3333 13.5748 11.1153C13.9511 10.9236 14.2571 10.6176 14.4488 10.2413C14.6668 9.81345 14.6668 9.2534 14.6668 8.13329V3.86663C14.6668 2.74652 14.6668 2.18647 14.4488 1.75864C14.2571 1.38232 13.9511 1.07636 13.5748 0.884613C13.147 0.666626 12.5869 0.666626 11.4668 0.666626H4.5335C3.41339 0.666626 2.85334 0.666626 2.42552 0.884613C2.04919 1.07636 1.74323 1.38232 1.55148 1.75864C1.3335 2.18647 1.3335 2.74652 1.3335 3.86663V8.13329C1.3335 9.2534 1.3335 9.81345 1.55148 10.2413C1.74323 10.6176 2.04919 10.9236 2.42552 11.1153C2.85334 11.3333 3.41339 11.3333 4.5335 11.3333Z"
        stroke="#777692"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MailLine
