import { API } from '../../../projectApi'
import { Vehicle, VehicleCreateUpdateParams } from '../../../projectApi/Trips/Vehicles/common'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  SettersVehiclesModalTypes,
  SetModalVisibility,
  CreateVehicleRequest,
  CreateVehicleTypes,
  CreateVehicleSuccess,
  SetDeleteModalVisibility,
  EditVehicleRequest,
  EditVehicleTypes,
  EditVehicleSuccess,
  SetSelectedVehicle,
  SetVehicleField,
  DeleteVehicleRequest,
  DeleteVehicleTypes,
  DeleteVehicleSuccess,
  GetProvidersTypes
} from '../types/ABMVehicles'

let getProvidersSearchTimer: NodeJS.Timeout
let getProvidersLastSearchID: number

const ABMVehiclesActionsCreator = {
  createVehicle({ params }: { params: VehicleCreateUpdateParams }): AppThunk {
    return async (dispatch) => {
      const request: CreateVehicleRequest = {
        type: CreateVehicleTypes.CREATE_VEHICLE_REQUEST
      }

      const toastId = setToastLoading('Creando vehículo, por favor espere...')

      dispatch(request)
      try {
        await API.Trips.Vehicles.create(params)
        const success: CreateVehicleSuccess = {
          type: CreateVehicleTypes.CREATE_VEHICLE_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Vehicles.CREATE_VEHICLE)
        setToastSuccessUpdate(toastId, { render: 'Vehículo creado correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, CreateVehicleTypes.CREATE_VEHICLE_FAILURE, dispatch) ??
            'Error al intentar crear el vehículo.'
        })
      }
    }
  },

  getProviders:
    (name: string): AppThunk =>
    (dispatch) => {
      dispatch({
        type: GetProvidersTypes.FETCH_PROVIDERS_REQUEST
      })

      const lastSearchID = new Date().getMilliseconds()
      getProvidersLastSearchID = lastSearchID
      clearTimeout(getProvidersSearchTimer)
      getProvidersSearchTimer = setTimeout(async () => {
        const params = {
          limit: 10,
          offset: 0,
          name
        }

        try {
          if (lastSearchID === getProvidersLastSearchID) {
            const { providers } = await API.Trips.Providers.list(params)

            dispatch({
              type: GetProvidersTypes.FETCH_PROVIDERS_SUCCESS,
              payload: {
                providers
              }
            })
          }
        } catch (e) {
          handleErrorsWithAction(e, GetProvidersTypes.FETCH_PROVIDERS_FAILURE, dispatch)
        }
      }, 250)
    },

  deleteVehicle({ vehicleId }: { vehicleId: number }): AppThunk {
    return async (dispatch) => {
      const request: DeleteVehicleRequest = {
        type: DeleteVehicleTypes.DELETE_VEHICLE_REQUEST
      }

      const toastId = setToastLoading('Eliminando vehículo, por favor espere...')

      dispatch(request)
      try {
        await API.Trips.Vehicles.delete({ vehicleId })

        const success: DeleteVehicleSuccess = {
          type: DeleteVehicleTypes.DELETE_VEHICLE_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Vehicles.DELETE_VEHICLE)
        setToastSuccessUpdate(toastId, { render: 'El vehículo se eliminó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, DeleteVehicleTypes.DELETE_VEHICLE_FAILURE, dispatch) ??
            'Error al intentar eliminar el vehículo.'
        })
      }
    }
  },

  editVehicle({ id, params }: { id: number; params: VehicleCreateUpdateParams }): AppThunk {
    return async (dispatch) => {
      const request: EditVehicleRequest = {
        type: EditVehicleTypes.EDIT_VEHICLE_REQUEST
      }

      const toastId = setToastLoading('Editando vehículo, por favor espere...')

      dispatch(request)
      try {
        const updatedVehicle = await API.Trips.Vehicles.update(id, params)

        const success: EditVehicleSuccess = {
          type: EditVehicleTypes.EDIT_VEHICLE_SUCCESS,
          payload: { updatedVehicle }
        }

        dispatch(success)
        Emitter.emit(Events.Vehicles.EDIT_VEHICLE)
        setToastSuccessUpdate(toastId, { render: 'El vehículo se editó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, EditVehicleTypes.EDIT_VEHICLE_FAILURE, dispatch) ??
            'Error al intentar editar el vehículo.'
        })
      }
    }
  },

  setModalVisibility({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }): SetModalVisibility {
    return {
      type: SettersVehiclesModalTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible, editMode }
    }
  },

  setDeleteModalVisibility(deleteModalVisibility: boolean, vehicle?: Vehicle): SetDeleteModalVisibility {
    return {
      type: SettersVehiclesModalTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisibility, vehicle }
    }
  },

  setVehicleField(
    field: keyof VehicleCreateUpdateParams,
    value: VehicleCreateUpdateParams[typeof field]
  ): SetVehicleField {
    return {
      type: SettersVehiclesModalTypes.SET_VEHICLE_FIELD,
      payload: { field, value }
    }
  },

  setSelectedVehicle(selectedVehicle: Vehicle): SetSelectedVehicle {
    return {
      type: SettersVehiclesModalTypes.SET_SELECTED_VEHICLE,
      payload: { selectedVehicle }
    }
  }
}

export default ABMVehiclesActionsCreator
