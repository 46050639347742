import types from '../types/delete'

const initialProspect = {
  openDelete: false,
  loading: false,
  prospect: {}
}

function prospectReducer(state = initialProspect, action) {
  switch (action.type) {
    case types.DELETE_LEAD:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.DELETE_LEAD_SUCCESS:
      return initialProspect
    case types.DELETE_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.SET_DELETE_MODAL_OPEN: {
      const { prospect, open } = action.payload
      return { ...state, prospect, openDelete: open }
    }
    default:
      return state
  }
}

export default prospectReducer
