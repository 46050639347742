import { combineReducers } from 'redux'
import OriginsTableReducer from './originsTable'
import EditOriginReducer from './editOrigin'
import DeleteOriginReducer from './deleteOrigin'

export default combineReducers({
  table: OriginsTableReducer,
  modal: EditOriginReducer,
  delete: DeleteOriginReducer
})
