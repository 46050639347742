import { GenericObject } from '../../../utils/utilityTypes'
import { SearchFilter, Sorter } from './entities'
import { SearchProps, SortDirection, SortProps } from '../../../components/DynamicTable/types/types'

export const getSearchProps = (
  key: string,
  label: string,
  searchFunction: (key: string, newValue: string) => void,
  currentSearchFilters: SearchFilter[]
): SearchProps | undefined => {
  const found = currentSearchFilters.find((sf) => sf.key === key)
  if (!found) return

  return {
    label,
    value: found.text,
    onConfirm: (newValue) => searchFunction(key, newValue)
  }
}

export const getSortProps = <T extends GenericObject>(
  key: keyof T,
  currentSorter: Sorter<T>,
  sorterFunction: (newSorter: Sorter<T>) => void
): SortProps => {
  return {
    direction: currentSorter.order,
    isActive: currentSorter.columnKey === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        order: currentSorter.columnKey === key ? newSortDirection : SortDirection.ASC,
        columnKey: key,
        field: key
      })
  }
}
