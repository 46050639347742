import { API } from '../../../projectApi'
import { OperationType } from '../../../projectApi/TransactionHandler/Operation/Cancel/list'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  CancelOperationFailure,
  CancelOperationRequest,
  CancelOperationSuccess,
  CancelOperationType,
  GetCancelReasonsFailure,
  GetCancelReasonsRequest,
  GetCancelReasonsSuccess,
  GetCancelReasonsTypes,
  ModalDataTypes,
  SetCancelReason,
  SetCreditNoteModalOpen,
  SetModalOpen,
  SelectedUser
} from '../types/cancel'

const CancelActionCreators = {
  openModal: ({ isOpen, operationId }: { isOpen: boolean; operationId?: number }): SetModalOpen => {
    return {
      type: ModalDataTypes.SET_MODAL_OPEN,
      payload: { isOpen, operationId }
    }
  },

  openCreditNoteModal: ({
    isOpen,
    operationId,
    selectedUser,
    isNotCreateCreditNote
  }: {
    isOpen: boolean
    operationId?: number
    selectedUser: SelectedUser | null
    isNotCreateCreditNote?: boolean
  }): SetCreditNoteModalOpen => {
    return {
      type: ModalDataTypes.SET_CREDIT_NOTE_MODAL_OPEN,
      payload: { isOpen, operationId, selectedUser, isNotCreateCreditNote }
    }
  },

  getCancelReasons: (): AppThunk => async (dispatch) => {
    const request: GetCancelReasonsRequest = {
      type: GetCancelReasonsTypes.GET_CANCEL_REASONS_REQUEST
    }

    dispatch(request)

    try {
      const operationType = OperationType.REMOVAL

      const { cancelReasons: cancelReasonsList } = await API.TransactionHandler.Operation.Cancel.list({
        operationType
      })

      const success: GetCancelReasonsSuccess = {
        type: GetCancelReasonsTypes.GET_CANCEL_REASONS_SUCCESS,
        payload: { cancelReasonsList }
      }

      dispatch(success)
    } catch (error) {
      const failure: GetCancelReasonsFailure = {
        type: GetCancelReasonsTypes.GET_CANCEL_REASONS_FAILURE,
        payload: { errorMessage: (error as string) ?? 'Error al obtener los motivos de cancelación' }
      }
      dispatch(failure)
    }
  },

  cancelOperation:
    ({ operationId, cancelReasonId }: { operationId: number; cancelReasonId: number }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Cancelando operación, por favor espere...')
      const request: CancelOperationRequest = {
        type: CancelOperationType.CANCEL_OPERATION_REQUEST
      }

      dispatch(request)
      try {
        await API.TransactionHandler.Operation.Cancel.cancel({ operationId, cancelReasonId })

        const success: CancelOperationSuccess = {
          type: CancelOperationType.CANCEL_OPERATION_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Operación cancelada correctamente' })
        Emitter.emit(Events.Removal.REMOVAL_CANCELLED)
      } catch (error) {
        const failure: CancelOperationFailure = {
          type: CancelOperationType.CANCEL_OPERATION_FAILURE
        }

        dispatch(failure)
        setToastErrorUpdate(toastId, { render: (error as string) ?? 'Error al cancelar la operación' })
      }
    },

  setReason: ({ reasonId }: { reasonId: number }): SetCancelReason => {
    return {
      type: ModalDataTypes.SET_CANCEL_REASON,
      payload: { reasonId }
    }
  }
}

export default CancelActionCreators
