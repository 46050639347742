export enum InputErrors {
  CARD_NUMBER = 'cardNumber',
  EXPIRATION_DATE = 'expirationDate',
  CVC = 'CVC',
  CARD_OWNER_NAME = 'cardOwnerName',
  DOCUMENT_NUMBER = 'documentNumber'
}

export enum DocumentType {
  NUMBER = 'number',
  TEXT = 'text'
}

export type IdentificationTypeRules = {
  minLength: number
  maxLength: number
  type: DocumentType
}

export type CardObjectValue = {
  value: string
  error: boolean
  errorMessage: string
}

export interface CardModalState {
  open: boolean
  loading: boolean
  card: {
    cardNumber: CardObjectValue
    expirationDate: CardObjectValue
    CVC: CardObjectValue
    cardOwnerName: CardObjectValue
    documentType: string
    documentNumber: CardObjectValue
  }
}

export const SET_OPEN = 'CARDS/CARD_MODAL/SET_OPEN'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
  }
}

export const SET_CARD_NUMBER = 'CARDS/CARD_MODAL/SET_CARD_NUMBER'
export const SET_EXPIRATION_DATE = 'CARDS/CARD_MODAL/SET_EXPIRATION_DATE'
export const SET_CVC = 'CARDS/CARD_MODAL/SET_CVC'
export const SET_CARD_OWNER_NAME = 'CARDS/CARD_MODAL/SET_CARD_OWNER_NAME'
export const SET_DOCUMENT_TYPE = 'CARDS/CARD_MODAL/SET_DOCUMENT_TYPE'
export const SET_DOCUMENT_NUMBER = 'CARDS/CARD_MODAL/SET_DOCUMENT_NUMBER'

export interface SetCardNumber {
  type: typeof SET_CARD_NUMBER
  payload: {
    cardNumber: string
  }
}

export interface SetExpirationDate {
  type: typeof SET_EXPIRATION_DATE
  payload: {
    expirationDate: string
  }
}

export interface SetCVC {
  type: typeof SET_CVC
  payload: {
    CVC: string
  }
}

export interface SetCardOwnerName {
  type: typeof SET_CARD_OWNER_NAME
  payload: {
    cardOwnerName: string
  }
}

export interface SetDocumentType {
  type: typeof SET_DOCUMENT_TYPE
  payload: {
    documentType: string
  }
}

export interface SetDocumentNumber {
  type: typeof SET_DOCUMENT_NUMBER
  payload: {
    documentNumber: string
  }
}

export type SetterDataCards =
  | SetCardNumber
  | SetExpirationDate
  | SetCVC
  | SetCardOwnerName
  | SetDocumentType
  | SetDocumentNumber

export const ADD_CARD_REQUEST = 'CARDS/CARD_MODAL/ADD_CARD_REQUEST'
export const ADD_CARD_SUCCESS = 'CARDS/CARD_MODAL/ADD_CARD_SUCCESS'
export const ADD_CARD_FAILURE = 'CARDS/CARD_MODAL/ADD_CARD_FAILURE'

export interface AddCardRequest {
  type: typeof ADD_CARD_REQUEST
}

export interface AddCardSuccess {
  type: typeof ADD_CARD_SUCCESS
}

export interface AddCardFailure {
  type: typeof ADD_CARD_FAILURE
  payload: {
    errorMessage: string
  }
}

export type AddCardAction = AddCardRequest | AddCardSuccess | AddCardFailure

export const SET_INPUT_ERROR = 'CARDS/CARD_MODAL/SET_INPUT_ERROR'

export interface SetInputError {
  type: typeof SET_INPUT_ERROR
  payload: {
    input: InputErrors
    errorMessage: string
  }
}

export type CardsModalActions = SetOpen | AddCardAction | SetterDataCards | SetInputError
