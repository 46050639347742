import { Reducer } from 'redux'
import {
  GET_REMOVAL_HISTORY_FAILURE,
  GET_REMOVAL_HISTORY_REQUEST,
  GET_REMOVAL_HISTORY_SUCCESS,
  RemovalHistoryAction,
  RemovalHistoryState
} from '../types/removalHistory'

const initialRemovalHistory: RemovalHistoryState = {
  audits: [],
  loading: false
}

const RemovalHistoryReducer: Reducer<RemovalHistoryState, RemovalHistoryAction> = (
  state = initialRemovalHistory,
  action
): RemovalHistoryState => {
  switch (action.type) {
    case GET_REMOVAL_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_REMOVAL_HISTORY_SUCCESS:
      return {
        ...state,
        audits: action.payload.audits,
        loading: false
      }
    case GET_REMOVAL_HISTORY_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default RemovalHistoryReducer
