import config from '../config'

export type IdentificationType = {
  id: string
  name: string
  type: string
  min_length: number
  max_length: number
}

type CardTokenParamsType = {
  cardNumber: string
  cardholderName: string
  cardExpirationMonth: string
  cardExpirationYear: string
  securityCode: string
  identificationType: string
  identificationNumber: string
}

type PaymentMethodParamsType = {
  bin: string
  processingMode?: 'aggregator' | 'gateway'
}

type CardTokenType = {
  id: string
}

export type PaymentMethodType = {
  secure_thumbnail: string
  min_accreditation_days: number
  max_accreditation_days: number
  id: string
  payment_type_id: string
  accreditation_time: number
  thumbnail: string
  marketplace: string
  deferred_capture: string
  labels: string[]
  name: string
  site_id: string
  processing_mode: string
  additional_info_needed: string[]
  status: string
  settings: [
    {
      security_code: {
        mode: string
        card_location: string
        length: number
      }
      card_number: {
        length: number
        validation: string
      }
      bin: {
        pattern: string
        installments_pattern: string
        exclusion_pattern: string
      }
    }
  ]
  issuer: {
    default: boolean
    name: string
    id: number
  }
}

type PaymentMethodResults = {
  paging?: {
    total: number
    limit: number
    offset: number
  }
  results: PaymentMethodType[]
}

type MercadoPagoInstanceType = {
  getIdentificationTypes: () => IdentificationType[]
  getPaymentMethods: (paymentMethodsParams: PaymentMethodParamsType) => PaymentMethodResults
  createCardToken: (cardTokenParams: CardTokenParamsType) => Promise<CardTokenType>
}

let mercadoPagoInstance: MercadoPagoInstanceType

export const initMP = () => {
  // @ts-ignore
  mercadoPagoInstance = new MercadoPago(config.MERCADOPAGO_PUBLIC_KEY)
  console.log('MercadoPago instance created: ', mercadoPagoInstance)
}

export const getIdentificationTypes = async (): Promise<IdentificationType[]> => {
  return (await mercadoPagoInstance?.getIdentificationTypes()) || []
}

export const createCardToken = async (cardTokenParams: CardTokenParamsType): Promise<CardTokenType> => {
  return mercadoPagoInstance?.createCardToken(cardTokenParams) || { id: '' }
}

export const getPaymentMethods = async (paymentMethod: PaymentMethodParamsType): Promise<PaymentMethodResults> => {
  return mercadoPagoInstance?.getPaymentMethods(paymentMethod) || { results: [] }
}
