import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'

export enum PaymentMpTypes {
  GET_CARDS_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/GET_CARDS_REQUEST',
  GET_CARDS_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/GET_CARDS_SUCCESS',
  GET_CARDS_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/GET_CARDS_FAILURE',
  GET_SPREEDLY_PM_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/GET_SPREEDLY_PM_REQUEST',
  GET_SPREEDLY_PM_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/GET_SPREEDLY_PM_SUCCESS',
  GET_SPREEDLY_PM_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/GET_SPREEDLY_PM_FAILURE',
  SET_CARD_ID = 'NEW_REMOVAL/PAYMENT_MP/SET_CARD_ID',
  SET_SPREEDLY_PM_ID = 'NEW_REMOVAL/PAYMENT_MP/SET_SPREEDLY_PM_ID',
  SET_CARD_NUMBER = 'NEW_REMOVAL/PAYMENT_MP/SET_CARD_NUMBER',
  SET_CARD_EXPIRY = 'NEW_REMOVAL/PAYMENT_MP/SET_CARD_EXPIRY',
  SET_CARD_CVC = 'NEW_REMOVAL/PAYMENT_MP/SET_CARD_CVC',
  SET_CARDHOLDER_NAME = 'NEW_REMOVAL/PAYMENT_MP/SET_CARDHOLDER_NAME',
  SET_DOCUMENT_TYPE = 'NEW_REMOVAL/PAYMENT_MP/SET_DOCUMENT_TYPE',
  SET_DOCUMENT_NUMBER = 'NEW_REMOVAL/PAYMENT_MP/SET_DOCUMENT_NUMBER',
  SET_CARD_TYPE = 'NEW_REMOVAL/PAYMENT_MP/SET_CARD_TYPE',
  SET_CARD_FIELD_ERROR = 'NEW_REMOVAL/PAYMENT_MP/SET_CARD_FIELD_ERROR',
  SAVE_CARD_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/SAVE_CARD_REQUEST',
  SAVE_CARD_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/SAVE_CARD_SUCCESS',
  SAVE_CARD_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/SAVE_CARD_FAILURE',
  SAVE_SPREEDLY_CARD_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/SAVE_SPREEDLY_CARD_REQUEST',
  SAVE_SPREEDLY_CARD_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/SAVE_SPREEDLY_CARD_SUCCESS',
  SAVE_SPREEDLY_CARD_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/SAVE_SPREEDLY_CARD_FAILURE',
  SAVE_REMOVAL_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/SAVE_REMOVAL_REQUEST',
  SAVE_REMOVAL_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/SAVE_REMOVAL_SUCCESS',
  SAVE_REMOVAL_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/SAVE_REMOVAL_FAILURE',
  CREATE_DRAFT_REMOVAL_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/CREATE_DRAFT_REMOVAL_REQUEST',
  CREATE_DRAFT_REMOVAL_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/CREATE_DRAFT_REMOVAL_SUCCESS',
  CREATE_DRAFT_REMOVAL_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/CREATE_DRAFT_REMOVAL_FAILURE',
  PAY_DRAFT_REMOVAL_REQUEST = 'NEW_REMOVAL/PAYMENT_MP/PAY_DRAFT_REMOVAL_REQUEST',
  PAY_DRAFT_REMOVAL_SUCCESS = 'NEW_REMOVAL/PAYMENT_MP/PAY_DRAFT_REMOVAL_SUCCESS',
  PAY_DRAFT_REMOVAL_FAILURE = 'NEW_REMOVAL/PAYMENT_MP/PAY_DRAFT_REMOVAL_FAILURE',
  SET_REMOVAL_OPERATION_ID = 'NEW_REMOVAL/PAYMENT_MP/SET_REMOVAL_OPERATION_ID',
  CLEAN_ERRORS = 'NEW_REMOVAL/PAYMENT_MP/CLEAN_ERRORS',
  CLEAN_CREDIT_CARD_FIELDS = 'NEW_REMOVAL/PAYMENT_MP/CLEAN_CREDIT_CARD_FIELDS'
}

export type Card = {
  CreatedAt: string
  DeletedAt: string | null
  Expiration: string
  ID: number
  LastDigits: string
  Main: boolean
  PaymentType: string
  Type: string
  TypePhoto: string
  UpdatedAt: string
}

export enum CardField {
  DOCUMENT = 'document',
  HOLDER = 'holder',
  EXPIRY = 'expiry',
  CVC = 'cvc',
  NUMBER = 'number'
}

export type SetRemovalOperationId = {
  type: typeof PaymentMpTypes.SET_REMOVAL_OPERATION_ID
  payload: {
    operationId: number
  }
}

export type GetCardsRequest = {
  type: typeof PaymentMpTypes.GET_CARDS_REQUEST
}

export type GetCardsSuccess = {
  type: typeof PaymentMpTypes.GET_CARDS_SUCCESS
  payload: { cards: Card[] }
}

export type GetSpreedlyPMRequest = {
  type: typeof PaymentMpTypes.GET_SPREEDLY_PM_REQUEST
}

export type GetSpreedlyPMSuccess = {
  type: typeof PaymentMpTypes.GET_SPREEDLY_PM_SUCCESS
  payload: { paymentMethods: PaymentMethod[] }
}

export type FetchObjectsFailure = {
  type: typeof PaymentMpTypes.GET_CARDS_FAILURE
  payload: { error: string }
}

export type SetCardId = {
  type: typeof PaymentMpTypes.SET_CARD_ID
  payload: { cardId: number }
}

export type SetSpreedlyPMID = {
  type: typeof PaymentMpTypes.SET_SPREEDLY_PM_ID
  payload: { spreedlyPMID: number }
}

export type SetCardNumber = {
  type: typeof PaymentMpTypes.SET_CARD_NUMBER
  payload: { number: string }
}

export type SetCardExpiry = {
  type: typeof PaymentMpTypes.SET_CARD_EXPIRY
  payload: { expiry: string }
}

export type SetCardCvc = {
  type: typeof PaymentMpTypes.SET_CARD_CVC
  payload: { cvc: string }
}

export type SetCardholderName = {
  type: typeof PaymentMpTypes.SET_CARDHOLDER_NAME
  payload: { cardholderName: string }
}

export type SetDocumentType = {
  type: typeof PaymentMpTypes.SET_DOCUMENT_TYPE
  payload: { documentType: string }
}

export type SetDocumentNumber = {
  type: typeof PaymentMpTypes.SET_DOCUMENT_NUMBER
  payload: { documentNumber: string }
}

export type SetCardType = {
  type: typeof PaymentMpTypes.SET_CARD_TYPE
  payload: { type: string }
}

export type SetCardFieldError = {
  type: typeof PaymentMpTypes.SET_CARD_FIELD_ERROR
  payload: { field: CardField; error: string }
}

export type SaveCardRequest = {
  type: typeof PaymentMpTypes.SAVE_CARD_REQUEST
}

export type SaveCardSuccess = {
  type: typeof PaymentMpTypes.SAVE_CARD_SUCCESS
  payload: { card: Card }
}

export type SaveCardFailure = {
  type: typeof PaymentMpTypes.SAVE_CARD_FAILURE
  payload: { error: string }
}

export type SaveSpreedlyCardRequest = {
  type: typeof PaymentMpTypes.SAVE_SPREEDLY_CARD_REQUEST
}

export type SaveSpreedlyCardSuccess = {
  type: typeof PaymentMpTypes.SAVE_SPREEDLY_CARD_SUCCESS
}

export type SaveSpreedlyCardFailure = {
  type: typeof PaymentMpTypes.SAVE_SPREEDLY_CARD_FAILURE
  payload: { error: string }
}

export type SaveRemovalRequest = {
  type: typeof PaymentMpTypes.SAVE_REMOVAL_REQUEST
}

export type SaveRemovalSuccess = {
  type: typeof PaymentMpTypes.SAVE_REMOVAL_SUCCESS
}

export type SaveRemovalFailure = {
  type: typeof PaymentMpTypes.SAVE_REMOVAL_FAILURE
  payload: { error: string }
}

export type CreateDraftRemovalRequest = {
  type: typeof PaymentMpTypes.CREATE_DRAFT_REMOVAL_REQUEST
}

export type CreateDraftRemovalSuccess = {
  type: typeof PaymentMpTypes.CREATE_DRAFT_REMOVAL_SUCCESS
}

export type CreateDraftRemovalFailure = {
  type: typeof PaymentMpTypes.CREATE_DRAFT_REMOVAL_FAILURE
  payload: { error: string }
}

export type PayDraftRemovalRequest = {
  type: typeof PaymentMpTypes.PAY_DRAFT_REMOVAL_REQUEST
}

export type DraftRemovalSuccess = {
  type: typeof PaymentMpTypes.PAY_DRAFT_REMOVAL_SUCCESS
}

export type DraftRemovalFailure = {
  type: typeof PaymentMpTypes.PAY_DRAFT_REMOVAL_FAILURE
  payload: { error: string }
}

export type CleanErrors = {
  type: typeof PaymentMpTypes.CLEAN_ERRORS
}

export type CleanCreditCardFields = {
  type: typeof PaymentMpTypes.CLEAN_CREDIT_CARD_FIELDS
}

export type PaymentMpActions =
  | GetCardsRequest
  | GetCardsSuccess
  | GetSpreedlyPMRequest
  | GetSpreedlyPMSuccess
  | FetchObjectsFailure
  | SetCardId
  | SetSpreedlyPMID
  | SetCardNumber
  | SetCardExpiry
  | SetCardCvc
  | SetCardholderName
  | SetDocumentType
  | SetDocumentNumber
  | SetCardType
  | SaveSpreedlyCardRequest
  | SaveSpreedlyCardSuccess
  | SaveSpreedlyCardFailure
  | SaveCardRequest
  | SaveCardSuccess
  | SaveCardFailure
  | SaveRemovalRequest
  | SaveRemovalSuccess
  | SaveRemovalFailure
  | SetCardFieldError
  | CreateDraftRemovalRequest
  | CreateDraftRemovalSuccess
  | CreateDraftRemovalFailure
  | PayDraftRemovalRequest
  | DraftRemovalSuccess
  | DraftRemovalFailure
  | SetRemovalOperationId
  | CleanErrors
  | CleanCreditCardFields
