import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import camelcaseKeys from 'camelcase-keys'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type APIResponse = {
  programmed_quantity: number
  in_trip_quantity: number
  validated_quantity: number
}

type Response = CamelCasedPropertiesDeep<APIResponse>

type MetricsParams = {
  dateFrom?: Moment
  dateTo?: Moment
}

export function metrics({ dateFrom, dateTo }: MetricsParams): Promise<Response> {
  const formattedDateParams = {
    date_from: dateFrom?.format('YYYY-MM-DD'),
    date_to: dateTo?.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/pickings/metrics`, { params: formattedDateParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const formattedResponse = camelcaseKeys(response.data, { deep: true })

      return formattedResponse
    })
    .catch((error) => {
      throw error
    })
}
