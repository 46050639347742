import { GetComplaintsReasonsTypes } from '../types/common'
import {
  ComplaintsListActions,
  ComplaintsListStateType,
  ComplaintsTableDateRangeKey,
  GetComplaintsListTypes,
  GetComplaintsMetricsTypes,
  RESET_FILTERS
} from '../types/complaintsTable'

const LIMIT = 10

const initialState: ComplaintsListStateType = {
  complaints: [],
  reasons: [],
  isLoading: false,
  errors: '',
  pagination: {
    page: 1,
    pageSize: LIMIT,
    total: 0
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'userId', text: '' },
    { key: 'operationId', text: '' },
    { key: 'assignedUserId', text: '' },
    { key: 'clientId', text: '' }
  ],
  filters: {
    id: undefined
  },
  categoryFilter: { status: [], complaintType: [], priority: [], reasonId: [], closeReason: [] },
  dateRangeFilters: [
    { key: ComplaintsTableDateRangeKey.CREATED_AT, startDate: null, endDate: null },
    { key: ComplaintsTableDateRangeKey.UPDATED_AT, startDate: null, endDate: null },
    { key: ComplaintsTableDateRangeKey.CLOSED_AT, startDate: null, endDate: null }
  ],
  metircs: {
    deposit: 0,
    removal: 0,
    new: 0,
    newDelayed: 0,
    open: 0,
    openDelayed: 0,
    closed: 0
  },
  loadingMetrics: false
}

function complaintsTableReducer(
  state: ComplaintsListStateType = initialState,
  action: ComplaintsListActions
): ComplaintsListStateType {
  switch (action.type) {
    case GetComplaintsListTypes.GET_COMPLAINTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        pagination: action.payload.pagination,
        searchFilters: action.payload.searchFilters || state.searchFilters,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters
      }
    case GetComplaintsListTypes.GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        complaints: action.payload.complaints,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetComplaintsListTypes.GET_COMPLAINTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.error
      }

    case GetComplaintsReasonsTypes.GET_REASONS_REQUEST:
      return {
        ...state
      }
    case GetComplaintsReasonsTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload.reasons
      }
    case GetComplaintsReasonsTypes.GET_REASONS_FAILURE:
      return {
        ...state,
        errors: action.payload.error
      }

    case RESET_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
        searchFilters: initialState.searchFilters,
        pagination: initialState.pagination,
        dateRangeFilters: initialState.dateRangeFilters,
        categoryFilter: initialState.categoryFilter
      }
    case GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_REQUEST:
      return {
        ...state,
        loadingMetrics: true
      }
    case GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_SUCCESS:
      return {
        ...state,
        metircs: action.payload.metrics,
        loadingMetrics: false
      }
    case GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_FAILURE:
      return {
        ...state,
        loadingMetrics: false
      }
    default:
      return state
  }
}

export default complaintsTableReducer
