import { Button, Checkbox } from 'antd'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Select from '../../../../components/Select'
import { BaseOption } from '../../../../components/Select/Select'
import { AcceptParams } from '../../../../projectApi/Sales/SalesRequest/accept'
import { SalesRequest } from '../../../../projectApi/Sales/SalesRequest/list'
import { getExpressOption } from '../../utils'
import styles from '../SalesRequestView.module.scss'
import { DataDisplay, DataDisplayBox } from './DataDisplayBox'
import { InputPrice } from './WorkingView'

export interface CounterProposalProps {
  saleCostArg: number
  publicationPrice: number
  salesRequest: SalesRequest | null
  setCloseCounterProposalView: () => void
  handleAcceptProposal: ({
    pvp,
    saleCost,
    saleMargin,
    existExpressSale,
    expressSaleCost,
    expressSaleMargin,
    pve
  }: AcceptParams) => void
  handleCreateCounterProposal: ({
    isExpressSale,
    prc,
    pve,
    pvp,
    saleCost,
    saleMargin,
    expressSaleCost,
    expressSaleMargin
  }: {
    isExpressSale: boolean
    prc: number
    pve?: number
    pvp: number
    saleCost: number
    saleMargin: number
    expressSaleCost?: number
    expressSaleMargin?: number
  }) => void
  SALE_COST: number
  SALE_MARGIN: number
}

type Option = BaseOption<number>

export const CounterProposal: React.FC<CounterProposalProps> = ({
  saleCostArg,
  publicationPrice,
  salesRequest,
  handleCreateCounterProposal,
  setCloseCounterProposalView,
  handleAcceptProposal,
  SALE_COST,
  SALE_MARGIN
}) => {
  const [isSuggestChecked, setSuggestChecked] = useState(false)
  const [isSaleExpressChecked, setExpressChecked] = useState(false)
  const [suggestPrice, setSuggestPrice] = useState('')
  const [priceExpress, setPriceExpress] = useState<Option | null>(null)

  const basePrice = isSuggestChecked ? Number(suggestPrice) : salesRequest?.prc || 0

  const SUGGESTION_SALE_COST = isSuggestChecked
    ? Number(suggestPrice) * saleCostArg
    : (salesRequest?.prc || 0) * saleCostArg
  const SUGGESTION_SALE_MARGIN = publicationPrice - (Number(suggestPrice) + SUGGESTION_SALE_COST)

  const EXPRESS_SALE_COST = priceExpress && priceExpress.value * saleCostArg
  const EXPRESS_SALE_MARGIN = EXPRESS_SALE_COST && publicationPrice - (priceExpress.value + EXPRESS_SALE_COST)

  useEffect(() => {
    setPriceExpress(null)
  }, [basePrice])

  const options: Option[] = [
    getExpressOption(0.25, basePrice),
    getExpressOption(0.5, basePrice),
    getExpressOption(0.75, basePrice)
  ]

  const dataClientPrice: DataDisplay[] = [
    { label: 'Costo de venta', value: SUGGESTION_SALE_COST },
    { label: 'Margen', value: SUGGESTION_SALE_MARGIN },
    { label: 'Precio de publicación', value: publicationPrice }
  ]

  const dataSaleExpress: DataDisplay[] = [
    {
      label: 'Costo de venta',
      value: isSaleExpressChecked ? EXPRESS_SALE_COST : null
    },
    { label: 'Margen', value: isSaleExpressChecked ? EXPRESS_SALE_MARGIN : null },
    { label: 'Precio de publicación', value: publicationPrice }
  ]

  const onSuggestCheckboxChange = () => {
    const newValue = !isSuggestChecked
    setSuggestChecked(newValue)

    if (!newValue) {
      setSuggestPrice('')
    }
  }

  const onExpressCheckboxChange = () => {
    const newValue = !isSaleExpressChecked
    setExpressChecked(newValue)

    if (!newValue) {
      setPriceExpress(null)
    }
  }

  // const disabled = (!isSaleExpressChecked && !isSuggestChecked) || publicationPrice === 0
  const disabled =
    (isSaleExpressChecked ? !priceExpress : false) ||
    (isSuggestChecked ? !Number(suggestPrice) : false) ||
    publicationPrice === 0

  return (
    <div>
      <div className={styles.counterProposal}>
        <div className={styles.clientPrice}>
          <Checkbox onChange={onSuggestCheckboxChange} checked={isSuggestChecked}>
            Sugerir nuevo precio cliente (PRC)
          </Checkbox>
          <InputPrice
            onChange={(value) => setSuggestPrice(value || '0')}
            value={Number(suggestPrice)}
            disabled={!isSuggestChecked}
          />
          <DataDisplayBox data={dataClientPrice} />
        </div>
        <div className={styles.saleExpress}>
          <Checkbox onChange={onExpressCheckboxChange} checked={isSaleExpressChecked}>
            Ofrecer venta express
          </Checkbox>
          <Select
            onSelect={(value) => setPriceExpress(value)}
            options={options}
            selected={priceExpress}
            placeholder="Seleccione una opción"
            disabled={!isSaleExpressChecked}
          />
          <DataDisplayBox data={dataSaleExpress} />
        </div>
      </div>
      <div className={styles.buttonSectionProposal}>
        <Button className={styles.cancelButton} onClick={setCloseCounterProposalView}>
          Cancelar
        </Button>
        <Button
          disabled={disabled}
          className={clsx(styles.sendButton, disabled && styles.disabledSendButton)}
          type="primary"
          onClick={() => {
            if (isSuggestChecked || isSaleExpressChecked) {
              handleCreateCounterProposal({
                isExpressSale: isSaleExpressChecked,
                pve: isSaleExpressChecked && priceExpress ? priceExpress.value : undefined,
                prc: basePrice,
                pvp: publicationPrice,
                saleCost: SUGGESTION_SALE_COST,
                saleMargin: SUGGESTION_SALE_MARGIN,
                expressSaleCost: EXPRESS_SALE_COST || undefined,
                expressSaleMargin: EXPRESS_SALE_MARGIN || undefined
              })
            } else {
              handleAcceptProposal({
                pve: priceExpress?.value,
                pvp: publicationPrice,
                saleCost: SALE_COST,
                saleMargin: SALE_MARGIN,
                existExpressSale: isSaleExpressChecked,
                expressSaleCost: EXPRESS_SALE_COST || 0,
                expressSaleMargin: EXPRESS_SALE_MARGIN || 0
              })
            }
          }}>
          Enviar Propuesta
        </Button>
      </div>
    </div>
  )
}
