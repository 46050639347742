import { GetProposalByIdSuccess, SetNewProposalFromScratch } from './proposals'
import { GetProspectRequest } from './prospect'

export enum ExtraTypes {
  SET_EXTRA_ASSISTANT_COUNT = 'NEW_PROPOSAL_V2/SET_EXTRA_ASSISTANT_COUNT',
  SET_EXTRA_ASSISTANT_ENABLED = 'NEW_PROPOSAL_V2/SET_EXTRA_ASSISTANT_ENABLED',
  SET_ADDITIONAL_COST_ENABLED = 'NEW_PROPOSAL_V2/SET_ADDITIONAL_COST_ENABLED',
  SET_ADDITIONAL_COST = 'NEW_PROPOSAL_V2/COST/SET_ADDITIONAL_COST',
  SET_ADDITIONAL_COST_COMMENT = 'NEW_PROPOSAL_V2/COST/SET_ADDITIONAL_COST_COMMENT'
}

export interface SetExtraAssistantCount {
  type: ExtraTypes.SET_EXTRA_ASSISTANT_COUNT
  payload: { extraAssistantCount: number }
}

export interface SetExtraAssistantEnabled {
  type: ExtraTypes.SET_EXTRA_ASSISTANT_ENABLED
  payload: { extraAssistantEnabled: boolean }
}

export interface SetAdditionalCostEnabled {
  type: ExtraTypes.SET_ADDITIONAL_COST_ENABLED
  payload: { additionalCostEnabled: boolean }
}

export interface SetAdditionalCost {
  type: ExtraTypes.SET_ADDITIONAL_COST
  payload: { additionalCost: number }
}

export interface SetAdditionalCostComment {
  type: ExtraTypes.SET_ADDITIONAL_COST_COMMENT
  payload: { additionalCostComment: string }
}

export type ExtraActions =
  | SetExtraAssistantCount
  | SetExtraAssistantEnabled
  | GetProposalByIdSuccess
  | SetAdditionalCostEnabled
  | SetAdditionalCost
  | SetAdditionalCostComment
  | GetProspectRequest
  | SetNewProposalFromScratch
