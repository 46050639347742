import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import {
  EditServiceDateFailure,
  EditServiceDateRequest,
  EditServiceDateSuccess,
  EditServiceDateType,
  ModalDataTypes,
  SetModalOpen,
  SetNewDateService,
  SetReason
} from '../types/editDateService'
import { EditDateServiceParams } from '../../../../projectApi/TransactionHandler/Operation/DateService/edit'
import { Moment } from 'moment'

const CancelActionCreators = {
  openModal: ({
    isOpen,
    operationId,
    currentDateTime
  }: {
    isOpen: boolean
    operationId?: number
    currentDateTime?: string
  }): SetModalOpen => {
    return {
      type: ModalDataTypes.SET_MODAL_OPEN,
      payload: { isOpen, operationId, currentDateTime }
    }
  },

  editDateService:
    (operationId: number, params: EditDateServiceParams): AppThunk<void> =>
    async (dispatch) => {
      const toastId = setToastLoading('Editando fecha de servicio, por favor espere...')
      const request: EditServiceDateRequest = {
        type: EditServiceDateType.EDIT_SERVICE_DATE_REQUEST
      }

      dispatch(request)
      try {
        await API.TransactionHandler.Operation.DateService.edit(operationId, params)

        const success: EditServiceDateSuccess = {
          type: EditServiceDateType.EDIT_SERVICE_DATE_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Fecha de servicio editada correctamente' })
        Emitter.emit(Events.Operations.DATE_SERVICE_EDITED)
      } catch (error) {
        const failure: EditServiceDateFailure = {
          type: EditServiceDateType.EDIT_SERVICE_DATE_FAILURE
        }

        dispatch(failure)
        setToastErrorUpdate(toastId, { render: (error as string) ?? 'Error al editar la fecha de servicio' })
      }
    },

  setReason: (reason: string): SetReason => {
    return {
      type: ModalDataTypes.SET_REASON,
      payload: { reason }
    }
  },

  setNewDateService: (newDateService: Moment): SetNewDateService => {
    return {
      type: ModalDataTypes.SET_NEW_DATE_SERVICE,
      payload: { newDateService }
    }
  }
}

export default CancelActionCreators
