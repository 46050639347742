import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, Col, Input, Modal, Row, Tooltip } from 'antd'
import validateEmail from '../../../utils/validateEmail'
import {
  DocumentType,
  TaxCondition,
  dniDocument,
  typeOfConsumerTaxCondition,
  typeOfDocumentByTaxCondition
} from '../Users.constants'
import { getOptionValue } from '../Users.selectors'
import { validateIdentificationCode } from '../utils/validateFields'
import Select from '../../../components/Select'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { BillingModalState } from '../reducers/billingModal'
import billingActions from '../actions/billingModal'
import { BaseOption } from '../../../components/Select/Select'
import { PlacesAutocomplete } from '../../../components/PlacesAutocomplete/PlacesAutocomplete'
import { GeocodeResult } from 'use-places-autocomplete'
import { findAddressComponent } from '../../../components/Address/utils'
import { parsePostalCode } from '../../../common/operations/utils'
import { SaveUserBillingInformationRequest } from '../../../projectApi/TransactionHandler/UserBillingInformation/common'

const styles = {
  placeholder: {
    display: 'inline-block'
  },
  modal: {
    maxWidth: 600
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  select: {
    width: '100%'
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  },
  inputFullWidth: {
    width: '100%'
  }
}

type BillingModalProps = {
  billing: BillingModalState
  billingActions: typeof billingActions
}

type ErrorMapping = { [key in keyof BillingModalState]?: string }

const BillingModal = (props: BillingModalProps) => {
  const {
    id,
    userId,
    country,
    billingAddress,
    identificationCode,
    identificationNameClient,
    identificationType,
    loading,
    openModal,
    saleCondition,
    taxCondition,
    province,
    city,
    email,
    postalCode,
    billingEnabled,
    authorizationEnabled,
    error
  } = props.billing

  const {
    setIdentificationType,
    setIdentificationNameClient,
    setTaxCondition,
    setIdentificationCode,
    setSaleCondition,
    setEmail,
    setAuthorizationEnabled,
    setBillingAddress,
    setProvince,
    setCity,
    setPostalCode,
    updateBillingClientInfo,
    closeBillingModal,
    saveBillingClientInfo
  } = props.billingActions

  const [errors, setErrors] = useState<ErrorMapping>({})

  useEffect(() => {
    setErrors({})
  }, [openModal])

  const { minLength = 0, maxLength = 10 } = useMemo(() => {
    return (
      typeOfDocumentByTaxCondition[taxCondition]?.find((docType) => docType.value === identificationType) || dniDocument
    )
  }, [taxCondition, identificationType])

  const checkErrors = () => {
    const objectWithErrors: ErrorMapping = {}
    for (const key in props.billing) {
      const keyObject = key as keyof BillingModalState
      if (
        keyObject !== 'error' &&
        keyObject !== 'id' &&
        keyObject !== 'loading' &&
        keyObject !== 'openModal' &&
        keyObject !== 'billingEnabled' &&
        keyObject !== 'authorizationEnabled'
      ) {
        if (!props.billing[keyObject]) objectWithErrors[keyObject] = 'El campo es obligatorio'
      }
    }

    if (!validateEmail(props.billing.email)) {
      objectWithErrors.email = 'El email es invalido'
    }

    const errorIdentificationCode = validateIdentificationCode(identificationType, identificationCode, minLength)
    if (errorIdentificationCode) objectWithErrors.identificationCode = errorIdentificationCode

    setErrors(objectWithErrors)
    return objectWithErrors
  }

  const isDisable = Boolean(loading || Object.keys(errors).length > 0)

  const handleSubmitBillingModal = () => {
    if (Object.keys(checkErrors()).length > 0) {
      return true
    } else {
      const billingClientInfo: SaveUserBillingInformationRequest = {
        userId,
        identificationType,
        identificationCode,
        identificationNameClient,
        billingAddress,
        taxCondition,
        saleCondition,
        email,
        city,
        province,
        postalCode,
        authorizationEnabled,
        billingEnabled
      }
      if (id === 0) {
        saveBillingClientInfo(billingClientInfo)
      } else {
        updateBillingClientInfo(id, billingClientInfo)
      }
    }
  }

  const handleCleanErrors = (field: keyof BillingModalState) => {
    if (errors.hasOwnProperty(field)) {
      const newErrors = errors
      delete newErrors[field]
      setErrors({ ...newErrors })
    }
  }

  const getValueFromInput = (input: React.ChangeEvent<HTMLInputElement>) => {
    return input.target.value
  }

  const handleOnChangeSelectTaxCondition = (option: BaseOption<TaxCondition>) => {
    const value = option.value
    setTaxCondition(value)
    setIdentificationCode('')
    if (value === 'CF') {
      setIdentificationType('DNI')
    } else {
      setIdentificationType('CUIT')
    }
  }

  const selectedTypeOfConsumer = useMemo(
    () => typeOfConsumerTaxCondition.find((typeOfConsumer) => typeOfConsumer.value === taxCondition),
    [taxCondition]
  )

  const documentTypeOptions: DocumentType[] = useMemo(() => typeOfDocumentByTaxCondition[taxCondition], [taxCondition])
  const selectedDocumentType = useMemo(
    () => documentTypeOptions.find((docType) => docType.value === identificationType),
    [documentTypeOptions, identificationType]
  )

  const handleSuggest = (suggest: GeocodeResult) => {
    const postalCode = findAddressComponent(suggest.address_components, 'postal_code')
    const formattedPostalCode = postalCode ? parsePostalCode(postalCode, country) : ''

    const city = findAddressComponent(suggest.address_components, 'administrative_area_level_2')
    const province = findAddressComponent(suggest.address_components, 'administrative_area_level_1')

    setBillingAddress(suggest.formatted_address)
    setProvince(province)
    setCity(city)
    setPostalCode(formattedPostalCode)

    handleCleanErrors('billingAddress')
  }

  return (
    <Modal
      title="Datos de facturación"
      visible={openModal}
      onOk={() => handleSubmitBillingModal()}
      onCancel={() => closeBillingModal()}
      okText="Aceptar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ disabled: isDisable }}
      confirmLoading={loading}
      closable={!loading}
      maskClosable={!loading}
      style={styles.modal}>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col xs={12}>
          <p style={styles.inputTitle}>Tipo de consumidor*</p>
          <Select
            placeholder="Tipo de consumidor*"
            selected={selectedTypeOfConsumer}
            disabled={loading}
            options={typeOfConsumerTaxCondition}
            onSelect={(option) => {
              handleCleanErrors('taxCondition')
              handleOnChangeSelectTaxCondition(option)
            }}
          />
          {errors.hasOwnProperty('taxCondition') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.taxCondition}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>{taxCondition === 'RI' ? 'Razón Social*' : 'Nombre completo*'}</p>
          <Input
            disabled={loading}
            name="1"
            value={identificationNameClient}
            onChange={(value) => {
              handleCleanErrors('identificationNameClient')
              setIdentificationNameClient(getValueFromInput(value))
            }}
            style={styles.inputFullWidth}
          />
          {errors.hasOwnProperty('identificationNameClient') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.identificationNameClient}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Tipo de documento*</p>
          <Select
            disabled={loading}
            placeholder="Tipo de documento*"
            selected={selectedDocumentType}
            options={documentTypeOptions}
            onSelect={(option) => {
              handleCleanErrors('identificationType')
              setIdentificationType(getOptionValue(option))
            }}
          />
          {errors.hasOwnProperty('identificationType') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.identificationType}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Numero de {identificationType}*</p>
          <Input
            name="3"
            maxLength={maxLength}
            type="number"
            value={identificationCode}
            disabled={loading}
            onChange={(e) => {
              handleCleanErrors('identificationCode')
              const value = getValueFromInput(e)
              if (maxLength >= value.length) setIdentificationCode(value)
            }}
            style={styles.inputFullWidth}
          />
          {errors.hasOwnProperty('identificationCode') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.identificationCode}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Dirección fiscal*</p>
          <PlacesAutocomplete
            onRemove={() => setBillingAddress('')}
            currentCountry={country as CountryIdCode}
            initialValueAutocomplete={billingAddress}
            disabled={loading}
            handleSuggest={handleSuggest}
          />
          {errors.hasOwnProperty('billingAddress') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.billingAddress}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Provincia*</p>
          <Input
            name="5"
            value={province}
            disabled={loading}
            onChange={(value) => {
              handleCleanErrors('province')
              setProvince(getValueFromInput(value))
            }}
            style={styles.inputFullWidth}
          />
          {errors.hasOwnProperty('province') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.province}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Ciudad*</p>
          <Input
            name="6"
            value={city}
            disabled={loading}
            onChange={(value) => {
              handleCleanErrors('city')
              setCity(getValueFromInput(value))
            }}
            style={styles.inputFullWidth}
          />
          {errors.hasOwnProperty('city') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.city}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Código Postal*</p>
          <Input
            name="7"
            value={postalCode}
            disabled={loading}
            onChange={(value) => {
              handleCleanErrors('postalCode')
              setPostalCode(getValueFromInput(value))
            }}
            style={styles.inputFullWidth}
          />
          {errors.hasOwnProperty('postalCode') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.postalCode}
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p style={styles.inputTitle}>Email*</p>
          <Input
            name="8"
            value={email}
            disabled={loading}
            onChange={(value) => {
              handleCleanErrors('email')
              setEmail(getValueFromInput(value))
            }}
            style={styles.inputFullWidth}
          />
          {errors.hasOwnProperty('email') && (
            <p className="error-description" style={styles.errorFieldLabel}>
              {errors.email}
            </p>
          )}
        </Col>
        <Tooltip title="Días a partir de la emisión que tiene el cliente para el pago de una factura">
          <Col xs={12}>
            <p style={styles.inputTitle}>Condición de venta*</p>
            <Input
              name="9"
              value={saleCondition}
              type="number"
              disabled={loading}
              onChange={(value) => {
                handleCleanErrors('saleCondition')
                setSaleCondition(Number(getValueFromInput(value)))
              }}
              style={styles.inputFullWidth}
            />
            {errors.hasOwnProperty('saleCondition') && (
              <p className="error-description" style={styles.errorFieldLabel}>
                {errors.saleCondition}
              </p>
            )}
          </Col>
        </Tooltip>
        <Col xs={12}>
          <Checkbox
            name="10"
            disabled={true}
            checked={authorizationEnabled}
            onChange={(option) => setAuthorizationEnabled(option.target.checked)}>
            Facturación automática
          </Checkbox>
        </Col>
        <Col xs={12}>
          <Checkbox name="10" disabled={true} checked={billingEnabled}>
            Generar abono del usuario
          </Checkbox>
        </Col>
      </Row>
      {error && (
        <p className="error-description" style={styles.errorFieldLabel}>
          {error}
        </p>
      )}
    </Modal>
  )
}

export default BillingModal
