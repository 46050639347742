import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { APIDeposit } from './common'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export interface APIResponse {
  deposits: APIDeposit[]
  total: number
}

interface ListParams {
  limit: number
  offset: number
  order?: 'asc' | 'desc'
  column?: string
  countryId?: string
  id?: number
  depositId?: number
  name?: string
  owner?: string
  deprecated?: boolean
}

export function list(params: ListParams): Promise<CamelCasedPropertiesDeep<APIResponse>> {
  const { deprecated, ...restParams } = params
  const formattedParams = {
    order: params.order ?? 'desc',
    column: params.column ?? 'id',
    id: params.depositId,
    deprecated,
    ...snakecaseKeys(restParams)
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/deposits`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const deposits = response.data.deposits

      return {
        deposits: camelcaseKeys(deposits, { deep: true }) ?? [],
        total: response.data.total
      }
    })
    .catch((error) => {
      throw error
    })
}
