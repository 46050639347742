import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Pagination, dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  DiscountDatesDateRangeFilter,
  DiscountDatesSearchFilter,
  DiscountDatesTableTypes,
  DiscountDatesTableSort,
  GetDiscountDatesRequest,
  GetDiscountDatesSuccess,
  GetDiscountDatesTypes
} from '../types/discountDates'

const discountDatesActionsCreator = {
  getDiscountDates({
    pagination,
    sort,
    dateRangeFilters,
    searchFilters
  }: {
    pagination: Pagination
    sort?: DiscountDatesTableSort
    dateRangeFilters?: DiscountDatesDateRangeFilter[]
    searchFilters?: DiscountDatesSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetDiscountDatesRequest = {
        type: GetDiscountDatesTypes.GET_DISCOUNT_DATES_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          sort
        }
      }

      dispatch(request)

      try {
        const { discounts, total } = await API.Timeslot.discounts.SpecificDates.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          column: sort?.field,
          order: sort?.direction,
          ...dateRangeFiltersToParams(dateRangeFilters),
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetDiscountDatesSuccess = {
          type: GetDiscountDatesTypes.GET_DISCOUNT_DATES_SUCCESS,
          payload: {
            discounts,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetDiscountDatesTypes.GET_DISCOUNT_DATES_FAILURE, dispatch)
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: DiscountDatesTableTypes.RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.Discounts.CLEAN_FILTERS), 50)
    }
  }
}

export default discountDatesActionsCreator
