import snakecaseKeys from 'snakecase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'

export interface APIResponse {
  users: User[]
  total: number
}

export interface User {
  id: string
  fantasy_name: string
  cuit: string
  email: string
  saldo: number
  saldo_pagar: boolean
}

export type UserColppy = CamelCasedPropertiesDeep<User>
type Response = CamelCasedPropertiesDeep<APIResponse>

export function userInfo(userId: number): Promise<Response> {
  const params = snakecaseKeys({ userId })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/colppy/clients`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse = camelcaseKeys(response.data, { deep: true })
      return camelResponse
    })
    .catch((err: AxiosError) => {
      throw err
    })
}
