import React from 'react'
import { Modal, Radio, Spin } from 'antd'
import styles from './ModalReasons.module.scss'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import { RemovalReason } from '../../../projectApi/TransactionHandler/Operation/Removals/reasons'
import clsx from 'clsx'

export enum DefaultOptions {
  ANOTHER = 'otro'
}

export interface ModalReasonsProps {
  modalOpen: boolean
  reasonsList: RemovalReason[]
  loadingReasons: boolean
  errorLoading: string
  reasonSelected: string | number
  anotherReason: string
  setReasonSelected: ({ reasonSelected }: { reasonSelected: string | number }) => void
  setAnotherReason: ({ anotherReason }: { anotherReason: string }) => void
  handleCloseModal: () => void
  handleOkModal: () => void
}

const ModalReasons = ({
  modalOpen,
  anotherReason,
  reasonsList,
  loadingReasons,
  reasonSelected,
  setAnotherReason,
  setReasonSelected,
  handleCloseModal,
  handleOkModal
}: ModalReasonsProps) => {
  const anotherSelected = reasonSelected === DefaultOptions.ANOTHER

  const notCustomReason = anotherSelected && !anotherReason
  const disabledOkButton = !reasonSelected || notCustomReason

  return (
    <Modal
      visible={modalOpen}
      title={'¿Por qué motivo realiza la devolución?'}
      okText="Continuar"
      cancelText="Cancelar"
      okButtonProps={{ disabled: false }}
      onCancel={handleCloseModal}
      className={styles.modal}
      footer={<CustomFooter onCancel={handleCloseModal} onOk={handleOkModal} disabledOkButton={disabledOkButton} />}>
      {loadingReasons ? (
        <Spin size="default" style={{ display: 'flex', alignItems: 'center' }} />
      ) : (
        <Radio.Group
          style={{ width: '100%' }}
          onChange={(e) => setReasonSelected({ reasonSelected: e.target.value })}
          value={reasonSelected}>
          <div className={styles.optionsContainer}>
            {reasonsList.map((reason) => (
              <Radio
                key={`removal-reason-${reason.id}-${reason.description}`}
                className={styles.option}
                value={reason.id}>
                {reason.description}
              </Radio>
            ))}
            <Radio className={styles.option} value={DefaultOptions.ANOTHER}>
              Otro
            </Radio>
            {anotherSelected && (
              <InputFieldWithError
                placeholder="Indique el motivo"
                inputStyles={{ minHeight: '34.01px', marginTop: '20px' }}
                value={anotherReason}
                onChange={(e) => setAnotherReason({ anotherReason: e.target.value })}
              />
            )}
          </div>
        </Radio.Group>
      )}
    </Modal>
  )
}

export default ModalReasons

const CustomFooter = ({
  onCancel,
  onOk,
  disabledOkButton
}: {
  onCancel: () => void
  onOk: () => void
  disabledOkButton: boolean
}) => {
  return (
    <div className={styles.modalFooter}>
      <button className={clsx(styles.actionButton, styles.cancelButton)} onClick={() => onCancel()}>
        Cancelar
      </button>
      <button
        disabled={disabledOkButton}
        className={clsx(styles.actionButton, styles.okButton, disabledOkButton && styles.okDisabled)}
        onClick={() => onOk()}>
        Continuar
      </button>
    </div>
  )
}
