import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Select from '../../../components/Select'
import useDebounce from '../../../utils/hooks/useDebounce'
import { BaseOption } from '../../../components/Select/Select'
import { Driver, Provider } from '../types/shippersABM'
import styles from './ShippersAM.module.scss'

type SearchInputProps = {
  id: string
  name: string
  placeholder: string
  onSearchCallback: ({ name }: { name: string }) => void
  selected: BaseOption<number> | null
  onSelect: (option: BaseOption<number> | null) => void
  disableSearch?: boolean
  options: BaseOption<number>[]
  loading: boolean
  eraseable?: boolean
}

type SelectOption<T> = {
  list: T[]
  selected: T | null
  fetching: boolean
  onSearch: ({ name }: { name: string }) => void
}

export type AMShippersProps = {
  modalVisible: boolean
  editMode: boolean
  disabledSuccessButton: boolean
  drivers: SelectOption<Driver>
  providers: SelectOption<Provider>
  handleCancelButton: () => void
  setSelectedDriver: (driver: Driver | null) => void
  setSelectedProvider: (provider: Provider | null) => void
  hanldeOkButton: () => void
}

const AMShippers = ({
  modalVisible,
  editMode,
  drivers,
  providers,
  disabledSuccessButton,
  handleCancelButton,
  setSelectedDriver,
  setSelectedProvider,
  hanldeOkButton
}: AMShippersProps) => {
  const modalTitle = editMode ? 'Edición de transportista' : 'Crear transportista'
  const textConfirmButton = editMode ? 'Editar' : 'Crear'

  const handleSelectDriver = (selectedOption: BaseOption<number> | null, options: Driver[]) => {
    const findOption = options.find((o) => o.userId === selectedOption?.value)
    setSelectedDriver(findOption ?? null)
  }

  const handleSelectProvider = (selectedOption: BaseOption<number> | null, options: Provider[]) => {
    const findOption = options.find((o) => o.userId === selectedOption?.value)
    setSelectedProvider(findOption ?? null)
  }

  return (
    <Modal
      width={540}
      title={modalTitle}
      okText={textConfirmButton}
      visible={modalVisible}
      onCancel={handleCancelButton}
      cancelText={'Cancelar'}
      onOk={hanldeOkButton}
      okButtonProps={{ disabled: disabledSuccessButton }}
      destroyOnClose>
      <div>
        {!editMode ? (
          <h2 style={{ fontSize: '16px', marginBottom: 24 }}>
            Complete los siguientes campos con información valida para crear un nuevo transportista
          </h2>
        ) : null}

        <div className={styles.selectorsContainer}>
          <div>
            <label htmlFor="shipper" className={styles.label}>
              Transportista*
            </label>
            <SearchInput
              id="shipper"
              name="shipper"
              placeholder="Seleccione un transportista"
              options={formatListToOptions(drivers.list)}
              onSelect={(selectedDriver) => handleSelectDriver(selectedDriver, drivers.list)}
              selected={formatSelectedOption(drivers.selected)}
              loading={drivers.fetching}
              disableSearch={editMode}
              onSearchCallback={drivers.onSearch}
              eraseable={!editMode}
            />
          </div>
          <div>
            <label htmlFor="provider" className={styles.label}>
              Proveedor*
            </label>
            <SearchInput
              id="provider"
              name="provider"
              placeholder="Seleccione un proveedor"
              options={formatListToOptions(providers.list)}
              onSelect={(selectedProvider) => handleSelectProvider(selectedProvider, providers.list)}
              selected={formatSelectedOption(providers.selected)}
              loading={providers.fetching}
              onSearchCallback={providers.onSearch}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AMShippers

const SearchInput: React.FC<SearchInputProps> = ({
  id,
  name,
  placeholder,
  options,
  selected,
  loading,
  disableSearch,
  onSelect,
  onSearchCallback,
  eraseable = true
}) => {
  const [localValue, setLocalValue] = useState(selected?.label)
  const debouncedValue = useDebounce(localValue, 500)

  useEffect(() => {
    if (debouncedValue) onSearchCallback({ name: debouncedValue })
  }, [debouncedValue])

  useEffect(() => {
    setLocalValue(selected?.label)
  }, [selected?.label])

  return (
    <Select
      id={id}
      name={name}
      className={styles.selectInput}
      placeholder={placeholder}
      options={options}
      selected={selected}
      onSelect={onSelect}
      autocomplete={{
        filterByInput: true,
        input: localValue ?? '',
        onInputChange: (text) => setLocalValue(text)
      }}
      loading={loading}
      disabled={disableSearch}
      onDelete={
        eraseable
          ? () => {
              onSelect(null)
              setLocalValue('')
            }
          : undefined
      }
    />
  )
}

const formatListToOptions = (list: Driver[] | Provider[]): BaseOption<number>[] => {
  return list.map((item) => ({
    label: `${item.name} ${(item as Driver)?.lastName ?? ''}`,
    value: item.userId
  }))
}

const formatSelectedOption = (selected: Driver | Provider | null): BaseOption<number> | null => {
  if (!selected) {
    return null
  }

  return {
    label: `${selected.name} ${(selected as Driver)?.lastName ?? ''}`,
    value: selected.userId
  }
}
