import React, { useRef, useState } from 'react'
import styles from './TextFieldMasked.module.scss'
import { InputIcon, InputIconName } from './Icons'
import clsx from 'clsx'
import MaskedInput from 'antd-mask-input'
import { Field } from 'formik'

export enum INPUTS_MASKS {
  AMEX = '1111 111111 11111',
  VISA = '1111 1111 1111 1111',
  DATE_MONTH_YEAR = '11/11',
  CCV_AMEX = '1111',
  CCV_VISA = '111',
  DOCUMENT = '11 111 111111'
}

interface TextFieldProps {
  label: string
  value: string
  onChange: (text: string) => void
  onBlured?: () => void
  error?: boolean
  disabled?: boolean
  type?: 'password' | 'text'
  leftIcon?: InputIconName
  rightIcon?: InputIconName
  errorText?: string
  maxLength?: number
  inputProps?: typeof Field
  mask?: INPUTS_MASKS
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  type = 'text',
  onChange,
  onBlured,
  value,
  disabled,
  error,
  leftIcon,
  rightIcon,
  maxLength,
  mask,
  errorText = 'Hubo un error',
  inputProps
}) => {
  const [focused, setFocused] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const onFocused = () => {
    if (disabled) return
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
    if (onBlured) onBlured()
  }

  return (
    <>
      <div className={styles.textfieldContent}>
        <div
          className={clsx(
            styles.container,
            focused && styles.isFocusContainer,
            error && styles.isErrorContainer,
            disabled && styles.isDisabledContainer
          )}
          onClick={() => ref.current?.focus()}>
          {leftIcon && <InputIcon name={leftIcon} />}
          <div className={styles.inputContent}>
            <label
              className={clsx(
                styles.label,
                focused && styles.isFocusLabel,
                value.length > 0 && styles.unFocusedWithValue,
                error && styles.isErrorLabel,
                disabled && styles.isDisabledLabel
              )}>
              {label}
            </label>
            {mask ? (
              <MaskedInput
                className={styles.maskedInput}
                mask={mask}
                type={showPassword || type === 'text' ? 'text' : 'password'}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                onFocus={onFocused}
                onBlur={onBlur}
                autoComplete="off"
                maxLength={maxLength}
                placeholderChar={' '}
              />
            ) : (
              <input
                ref={ref}
                type={showPassword || type === 'text' ? 'text' : 'password'}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                disabled={disabled}
                onFocus={onFocused}
                onBlur={onBlur}
                autoComplete="off"
                maxLength={maxLength}
                {...inputProps}
              />
            )}
          </div>
          {type === 'password' && (
            <div onClick={() => setShowPassword(!showPassword)}>
              <InputIcon name={showPassword ? 'eye-pupil' : 'eye'} />
            </div>
          )}
          {rightIcon && type !== 'password' && <InputIcon name={rightIcon} />}
        </div>
        {errorText && error && <p className={styles.error}>{errorText}</p>}
      </div>
    </>
  )
}
