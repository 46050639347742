/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APIServices, Services } from './common'

interface APIResponse {
  description: {
    service_type: APIServices[]
    quantity: number
  }
}
export interface ServiceTypeResponse {
  services: Services[]
  total: number
}

export type ServicesListSortKey = 'id' | 'type' | 'multiplier' | 'operation_type'

type ListParams = {
  limit?: number
  offset?: number
  countryCode?: string
  order?: SortDirection
  sort?: ServicesListSortKey
}

export function list(params?: ListParams): Promise<ServiceTypeResponse> {
  const snakesParams = snakecaseKeys({
    limit: params?.limit,
    offset: params?.offset,
    order: params?.order,
    sort: params?.sort,
    countryCode: params?.countryCode
  })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/serviceType`, { params: snakesParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const resp = response.data?.description
      if (!resp) throw new Error('Respuesta incorrecta al obtener servicios')
      const camelResponse: ServiceTypeResponse = {
        total: resp.quantity,
        services: camelcaseKeys(resp.service_type, { deep: true }).map((service) => ({
          id: service.id,
          multiplier: service.multiplier,
          operationType: service.operationType,
          type: service.type
        }))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
