import React, { useEffect, useState } from 'react'
import CalendarSVG from '@/icons/calendar'
import styles from './DateSelector.module.scss'
import { Button, DatePicker, Dropdown } from 'antd'
import moment, { Moment } from 'moment'
import Overlay from '@/components/DynamicTable/subcomponents/Dropdowns/Overlay/Overlay'
import BsChevronDown from '@/icons/bsChevronDown'
import { CURRENT_MONTH_DAY, FIRST_MONTH_DAY } from '../../reducers/Dashboard'
import { useLocalization } from '@/packages/localization'

const { RangePicker } = DatePicker

type RangePickerDropdownProps = {
  startDate?: Moment
  endDate?: Moment
  onConfirmDate: (startDate: Moment, endDate: Moment) => void
}

const DateSelectorComponent = ({ startDate, endDate, onConfirmDate }: RangePickerDropdownProps) => {
  const [localStartDate, setLocalStartDate] = useState<Moment>(FIRST_MONTH_DAY)
  const [localEndDate, setLocalEndDate] = useState<Moment>(CURRENT_MONTH_DAY)
  const [visible, setVisible] = useState(false)

  const { strings } = useLocalization()

  useEffect(() => {
    if (startDate && !startDate?.isSame(localStartDate)) setLocalStartDate(startDate)
  }, [startDate])

  useEffect(() => {
    if (endDate && !endDate?.isSame(localEndDate)) setLocalEndDate(endDate)
  }, [endDate])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleDatesChange = (newStartDate?: Moment | null, newEndDate?: Moment | null) => {
    if (!newStartDate || !newEndDate) return

    setLocalStartDate(newStartDate)
    setLocalEndDate(newEndDate)
  }

  const handleConfirm = (reset?: boolean) => {
    if (reset) {
      setLocalStartDate(FIRST_MONTH_DAY)
      setLocalEndDate(CURRENT_MONTH_DAY)
      onConfirmDate(FIRST_MONTH_DAY, CURRENT_MONTH_DAY)
    } else {
      onConfirmDate(localStartDate, localEndDate)
    }
    setVisible(false)
  }

  const formatDateRange = () => {
    if (localStartDate && localEndDate) {
      const formattedStart = localStartDate.format('D MMMM YYYY')
      const formattedEnd = localEndDate.format('D MMMM YYYY')
      return `${formattedStart} - ${formattedEnd}`
    }
    return strings.dashboardViewScreen.header.dateRangeOptions.selectRange
  }

  const disabledDate = (current: Moment | null): boolean => {
    // Date not after today
    return current ? current > moment().endOf('day') : false
  }

  const overlay = (
    <Overlay
      customClassName={styles.overlay}
      buttons={
        <>
          <Button className={styles.resetBtn} onClick={() => handleConfirm(true)} size="small">
            {strings.generic.reset}
          </Button>
          <Button
            type="primary"
            onClick={() => handleConfirm(false)}
            icon="search"
            size="small"
            className={styles.searchBtn}>
            {strings.generic.search}
          </Button>
        </>
      }>
      <RangePicker
        onCalendarChange={([newStartDate, newEndDate]) => handleDatesChange(newStartDate, newEndDate)}
        value={[localStartDate, localEndDate]}
        placeholder={['Desde', 'Hasta']}
        size="small"
        format="DD/MM/YYYY"
        disabledDate={disabledDate}
      />
    </Overlay>
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
      <section className={styles.selectContainer}>
        <article className={styles.iconContainer}>
          <CalendarSVG className={styles.iconSize} color="#5D58F7" />
        </article>
        <article className={styles.textContainer}>
          <h6>{strings.dashboardViewScreen.header.dateRangeOptions.title}</h6>
          <p>{formatDateRange()}</p>
        </article>
        <article className={`${styles.chevronContainer} `}>
          <BsChevronDown className={visible ? styles.chevronOpen : styles.chevronClosed} />
        </article>
      </section>
    </Dropdown>
  )
}

export default DateSelectorComponent
