import moment from 'moment'
import { clone } from 'ramda'
import { DateRangeFilter, SearchFilter } from '../../utils/searchFilterUtils'
import { GenericObject, OverrideProps } from '../../utils/utilityTypes'

export const updateSingleSearchFilter = <T extends string>(
  currentSearchFilters: SearchFilter<T>[],
  key: T,
  newValue: string
) =>
  currentSearchFilters.map((filter) =>
    filter.key === key
      ? {
          ...filter,
          text: newValue
        }
      : filter
  )

export const updateMultipleSearchFilters = <T extends string>(
  currentSearchFilters: SearchFilter<T>[],
  searchFiltersToUpdate: OverrideProps<SearchFilter<T>, 'text', string | null | undefined>[]
) =>
  currentSearchFilters.map((currentFilter) => {
    const updatedFilter = searchFiltersToUpdate.find((sf) => sf.key === currentFilter.key)

    return updatedFilter?.text ? { ...currentFilter, text: updatedFilter.text } : currentFilter
  })

export const updateMultipleDateRangeFilters = <T extends string>(
  currentDateRangeFilters: DateRangeFilter<T>[],
  dateRangeFiltersToUpdate: OverrideProps<DateRangeFilter<T>, 'startDate' | 'endDate', string | null | undefined>[],
  maintainCurrentFilter = false
) =>
  currentDateRangeFilters.map((currentFilter) => {
    const updatedFilter = dateRangeFiltersToUpdate.find((sf) => sf.key === currentFilter.key)

    return updatedFilter?.startDate &&
      updatedFilter?.endDate &&
      moment(updatedFilter.startDate).isValid() &&
      moment(updatedFilter?.endDate).isValid()
      ? { key: updatedFilter.key, startDate: moment(updatedFilter.startDate), endDate: moment(updatedFilter.endDate) }
      : maintainCurrentFilter
      ? currentFilter
      : { ...currentFilter, startDate: null, endDate: null }
  })

export const removeNullishValues = (object: GenericObject) => {
  const newObject = clone(object)

  Object.keys(newObject).forEach((key) => {
    if (!newObject[key]) delete newObject[key]
  })

  return newObject
}

export const parseQueryParam = (arr: string, convertTo?: 'number' | 'string') => {
  return arr.split(',').map((a) => (convertTo === 'number' ? parseInt(a) : a))
}
