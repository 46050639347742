import types from './Users.types'
import { combineReducers } from 'redux'
import { URL_BASE_LANDING } from '../../endpoints'
import termsReducer from './reducers/terms'
import billingModalReducer from './reducers/billingModal'
import newUserReducer, { initialNewUser } from './reducers/newUser'
import identificationTypesReducer from './reducers/identificationTypes'
import checkoutReducer from './reducers/checkout'
import changeEmailReducer from './reducers/changeEmail'
import rolesReducer from './reducers/roles'
import sendCSVReducer from './reducers/sendCSV'
import { UsersTableDateRangeKey } from './types/usersTable'
import UsersMetricsReducer from './reducers/usersMetrics'

const initialPaymentMethods = {
  paymentMethods: [],
  loading: false,
  error: ''
}

function paymentMethodReducer(state = initialPaymentMethods, action) {
  switch (action.type) {
    case types.GET_PAYMENT_METHODS: {
      return { ...state, loading: true }
    }
    case types.GET_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods
      }
    }
    case types.GET_PAYMENT_METHODS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    default:
      return state
  }
}

const initialLoginUserAs = {
  selectedUser: {},
  loginAsModalOpen: false,
  userAdmin: {
    email: '',
    password: '',
    redirectTo: URL_BASE_LANDING
  },
  loading: false,
  error: ''
}

function loginAsUserReducer(state = initialLoginUserAs, action) {
  switch (action.type) {
    /** **** Login As functionality ******/
    case types.SET_LOGIN_AS_MODAL_OPEN: {
      const loginAsModalOpen = action.payload.loginAsModalOpen
      return {
        ...state,
        loginAsModalOpen,
        selectedUser: loginAsModalOpen ? action.payload.selectedUser : state.selectedUser,
        userAdmin: initialLoginUserAs.userAdmin,
        error: ''
      }
    }
    case types.SET_ADMIN_EMAIL:
      return {
        ...state,
        userAdmin: { ...state.userAdmin, email: action.payload.email }
      }
    case types.SET_ADMIN_PASSWORD:
      return {
        ...state,
        userAdmin: { ...state.userAdmin, password: action.payload.password }
      }
    case types.SET_REDIRECTION:
      return {
        ...state,
        userAdmin: { ...state.userAdmin, redirectTo: action.payload.redirectTo }
      }
    case types.LOGIN_AS_USER:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.LOGIN_AS_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loginAsModalOpen: false,
        selectedUser: initialNewUser.selectedUser,
        error: ''
      }
    case types.LOGIN_AS_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

const LIMIT = 50

const initialTableUsers = {
  users: [],
  loading: false,
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'ID',
    Order: 'desc'
  },
  quantity: 0,
  sorter: {
    order: 'descend',
    field: 'RealID',
    columnKey: 'RealID'
  },
  filter: {
    role: [],
    has_card: [],
    accepted: [],
    has_cbu: []
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'name', text: '' },
    { key: 'lastName', text: '' },
    { key: 'phone', text: '' },
    { key: 'email', text: '' }
  ],
  pagination: {
    current: 1,
    total: 0
  },
  dateRangeFilters: [{ key: UsersTableDateRangeKey.CREATED_AT, dateFrom: null, dateTo: null }]
}

function usersTableReducer(state = initialTableUsers, action) {
  switch (action.type) {
    case types.RESET_STATE: {
      return initialTableUsers
    }
    case types.CLEAN_FILTERS: {
      return {
        ...state,
        params: {
          ...initialTableUsers.params,
          Column: state.params.Column,
          Order: state.params.Order
        },
        filter: initialTableUsers.filter,
        searchFilters: initialTableUsers.searchFilters,
        pagination: initialTableUsers.pagination,
        dateRangeFilters: initialTableUsers.dateRangeFilters
      }
    }
    case types.FETCH_USERS:
      return {
        ...state,
        users: [],
        loading: true,
        error: ''
      }
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        },
        dateRangeFilters: action.payload.dateRangeFilters || initialTableUsers.dateRangeFilters
      }
    case types.FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  billing: billingModalReducer,
  terms: termsReducer,
  paymentMethods: paymentMethodReducer,
  identificationTypes: identificationTypesReducer,
  newUser: newUserReducer,
  loginAsUser: loginAsUserReducer,
  table: usersTableReducer,
  checkout: checkoutReducer,
  changeEmail: changeEmailReducer,
  roles: rolesReducer,
  sendCSV: sendCSVReducer,
  metrics: UsersMetricsReducer
})
