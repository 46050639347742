import { Removal } from '../../../projectApi/TransactionHandler/Operation/Removals/common'

import { RemovalsDetailObject } from '../../../projectApi/TransactionHandler/Operation/Removals/details'
import { RemovalsSearchableParams } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type RemovalsTableSortKey = 'id'
export type RemovalsTableSort = Sort<RemovalsTableSortKey>

export type RemovalsTableDateRangeFilter = DateRangeFilter<RemovalsTableDateRangeKey>

export type RemovalsTableCategoryFilterKey = 'origin' | 'status'
export type RemovalsTableCategoryFilter = CategoryFilter<RemovalsTableCategoryFilterKey>

export type RemovalsTableSearchFilterKey = keyof RemovalsSearchableParams
export type RemovalsTableSearchFilter = SearchFilter<RemovalsTableSearchFilterKey>

export enum RemovalsTableSearchKey {
  ID = 'id',
  USER_ID = 'userId',
  IS_FULL_REMOVAL = 'isFullRemoval'
}

export enum RemovalsTableDateRangeKey {
  CREATED_AT = 'createdAt',
  TRANSPORT_DATETIME = 'transportDatetime'
}

export type RemovalsTableState = {
  removals: Removal[]
  details: RemovalsDetailObject[]
  loading: boolean
  loadingObjects: boolean
  sendingCSV: boolean
  error: string
  pagination: Pagination
  sort: RemovalsTableSort
  dateRangeFilters: RemovalsTableDateRangeFilter[]
  activeViewButton: ViewTab | null
  categoryFilter: RemovalsTableCategoryFilter
  searchFilters: RemovalsTableSearchFilter[]
  quantityClientsWithFullRemovals: number
}

export type Pagination = {
  total: number
  page: number
  limit: number
}

export enum ViewTab {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow'
}

export const CLEAR_STATE = 'REMOVALS/REMOVALS_TABLE/CLEAR_STATE'
export interface ClearState {
  type: typeof CLEAR_STATE
}

export const SET_VIEW_BUTTON_ACTIVE = 'REMOVALS/REMOVALS_TABLE/SET_VIEW_BUTTON_ACTIVE'
export interface SetViewButtonActive {
  type: typeof SET_VIEW_BUTTON_ACTIVE
  payload: {
    activeViewButton: ViewTab | null
  }
}

export const GET_REMOVALS_REQUEST = 'REMOVALS/REMOVALS_TABLE/GET_REMOVALS_REQUEST'
export const GET_REMOVALS_SUCCESS = 'REMOVALS/REMOVALS_TABLE/GET_REMOVALS_SUCCESS'
export const GET_REMOVALS_FAILURE = 'REMOVALS/REMOVALS_TABLE/GET_REMOVALS_FAILURE'

export interface GetRemovalsRequest {
  type: typeof GET_REMOVALS_REQUEST
  payload: {
    newPage: number
    pageSize: number
    sort?: RemovalsTableSort
    dateRangeFilters?: RemovalsTableDateRangeFilter[]
    categoryFilter?: RemovalsTableCategoryFilter
    searchFilters?: RemovalsTableSearchFilter[]
  }
}

export interface GetRemovalsSuccess {
  type: typeof GET_REMOVALS_SUCCESS
  payload: {
    removals: Removal[]
    total: number
    quantityClientsWithFullRemovals: number
  }
}

export interface GetRemovalsFailure {
  type: typeof GET_REMOVALS_FAILURE
  payload: {
    error: string
  }
}

export type GetRemovalsAction = GetRemovalsRequest | GetRemovalsSuccess | GetRemovalsFailure

export const GET_REMOVAL_DETAILS_REQUEST = 'REMOVALS/GET_REMOVAL_DETAILS_REQUEST'
export const GET_REMOVAL_DETAILS_SUCCESS = 'REMOVALS/GET_REMOVAL_DETAILS_SUCCESS'
export const GET_REMOVAL_DETAILS_FAILURE = 'REMOVALS/GET_REMOVAL_DETAILS_FAILURE'

export interface GetRemovalDetailsRequest {
  type: typeof GET_REMOVAL_DETAILS_REQUEST
}

export interface GetRemovalDetailsSuccess {
  type: typeof GET_REMOVAL_DETAILS_SUCCESS
  payload: {
    objects: RemovalsDetailObject[]
  }
}

export interface GetRemovalDetailsFailure {
  type: typeof GET_REMOVAL_DETAILS_FAILURE
  payload: { error: string }
}

export type GetRemovalDetailAction = GetRemovalDetailsRequest | GetRemovalDetailsSuccess | GetRemovalDetailsFailure

export const SEND_CSV_REQUEST = 'REMOVALS/REMOVALS_TABLE/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'REMOVALS/REMOVALS_TABLE/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'REMOVALS/REMOVALS_TABLE/SEND_CSV_FAILURE'

export interface SendCSVRequest {
  type: typeof SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SEND_CSV_FAILURE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export type RemovalsActions =
  | GetRemovalsAction
  | GetRemovalDetailAction
  | SetViewButtonActive
  | ClearState
  | SendCSVActions
