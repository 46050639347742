import React from 'react'
import { ProductsCatalog } from '../components/ProductsCatalog'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GeneralDataActionCreator } from '../actionCreator/generalData'
import { ModalProductDetail } from '../components/subcomponents/ModalProductDetail'

const ProductCatalogContainer = () => {
  const dispatch = useDispatch()
  const {
    productSuggestions,
    selectedProduct,
    products,
    openDetailModal,
    productDetail,
    isLoadingCatalogProducts,
    isProductDetailLoading
  } = useSelector((state) => state.NewProduct.generalData)
  const { getSuggestions, setProductSuggested, getCatalogByProduct, setOpenDetailModal, getProductByDescription } =
    bindActionCreators(GeneralDataActionCreator, dispatch)

  const handleGetSuggestions = (keyword: string) => {
    getSuggestions(keyword)
  }

  const handleGetCatalogByProduct = () => {
    getCatalogByProduct({ suggestion: selectedProduct })
  }

  const handleGetProductByDescription = ({ description }: { description: string }) => {
    getProductByDescription({ description })
    setOpenDetailModal(true)
  }

  const productSuggestionsProps = {
    handleGetSuggestions,
    productSuggestions,
    setProductSuggested,
    selectedProduct,
    handleGetCatalogByProduct,
    products,
    handleGetProductByDescription,
    isLoadingCatalogProducts
  }

  return (
    <>
      <ProductsCatalog {...productSuggestionsProps} />
      <ModalProductDetail
        visible={openDetailModal}
        onClose={() => setOpenDetailModal(false)}
        productDetail={productDetail}
        isFetching={isProductDetailLoading}
      />
    </>
  )
}

export default ProductCatalogContainer
