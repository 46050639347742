export const OPERATION_TYPE_VALUES = {
  ITEMS: 'items',
  M3: 'm3'
  // Wine: 'wine' // Este tipo estará disponible desde back a futuro
}

export const PAYMENT_BY_VALUES = {
  CREDIT_CARD_MP: 'credit_card_mp',
  OFFLINE: 'offline',
  CBU: 'cbu'
}

export const STATUS_VALUES = {
  CONFIRMED: 'confirmed',
  CREATED: 'created',
  CANCELLED: 'cancelled',
  AUTHORIZED: 'authorized',
  PENDING_PAYMENT: 'pending_payment',
  CLOSED_TOTALLY: 'closed_totally',
  CLOSED_PARTIALLY: 'closed_partially',
  DEPOSITED: 'deposited',
  VALIDATED: 'validated',
  ASSIGNED_TO_TRIP: 'assigned_to_trip',
  IN_TRIP: 'in_trip',
  PROGRAMMED: 'programmed',
  PROGRAMMED_M: 'programmed_m'
}

export const PAYMENT_BY_FILTERS = [
  {
    text: 'Tarjeta de crédito',
    value: PAYMENT_BY_VALUES.CREDIT_CARD_MP
  },
  {
    text: 'Pago Offline',
    value: PAYMENT_BY_VALUES.OFFLINE
  },
  {
    text: 'CBU',
    value: PAYMENT_BY_VALUES.CBU
  }
]

export const OPERATION_TYPE_FILTERS = [
  {
    text: 'Ítems',
    value: OPERATION_TYPE_VALUES.ITEMS
  },
  {
    text: 'M3',
    value: OPERATION_TYPE_VALUES.M3
  }
]

export const MULTIPLE_STATUS_FILTERS = [
  {
    text: 'Ingresada',
    value: STATUS_VALUES.DEPOSITED,
    styleProps: {
      borderColor: '#1890FF',
      backgroundColor: '#E6F7FF'
    }
  },
  {
    text: 'Validada',
    value: STATUS_VALUES.VALIDATED,
    styleProps: {
      borderColor: '#00a716',
      backgroundColor: '#dfffe3'
    }
  }
]

export const STATUS_FILTERS = [
  {
    text: 'Asignada a viaje',
    value: STATUS_VALUES.ASSIGNED_TO_TRIP,
    styleProps: {
      borderColor: '#52C487',
      backgroundColor: '#F6FFFF'
    }
  },
  {
    text: 'Confirmada',
    value: STATUS_VALUES.CONFIRMED,
    styleProps: {
      borderColor: '#52C41A',
      backgroundColor: '#F6FFED'
    }
  },
  {
    text: 'Creada',
    value: STATUS_VALUES.CREATED,
    styleProps: {
      borderColor: '#FAAF19',
      backgroundColor: '#FFFBE6'
    }
  },
  {
    text: 'Cancelada',
    value: STATUS_VALUES.CANCELLED,
    styleProps: {
      borderColor: '#FF4D4F',
      backgroundColor: '#FFF2F0'
    }
  },
  {
    text: 'En viaje',
    value: STATUS_VALUES.IN_TRIP,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    text: 'Ingresada',
    value: STATUS_VALUES.DEPOSITED,
    styleProps: {
      borderColor: '#1890FF',
      backgroundColor: '#E6F7FF'
    }
  },
  {
    text: 'Programada',
    value: STATUS_VALUES.PROGRAMMED,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Programada (M)',
    value: STATUS_VALUES.PROGRAMMED_M,
    styleProps: {
      borderColor: '#027A65',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Validada',
    value: STATUS_VALUES.VALIDATED,
    styleProps: {
      borderColor: '#00a716',
      backgroundColor: '#dfffe3'
    }
  }
]

export const TRIPS_FILTERS = [
  {
    text: 'Creado',
    value: 1,
    styleProps: {
      borderColor: '#52C41A',
      backgroundColor: '#F6FFED'
    }
  },
  {
    text: 'En proceso',
    value: 2,
    styleProps: {
      borderColor: '#FAAF19',
      backgroundColor: '#FFFBE6'
    }
  },
  {
    text: 'Realizado',
    value: 3,
    styleProps: {
      borderColor: '#FF4D4F',
      backgroundColor: '#FFF2F0'
    }
  },
  {
    text: 'Confirmado',
    value: 4,
    styleProps: {
      borderColor: '#1890FF',
      backgroundColor: '#E6F7FF'
    }
  },
  {
    text: 'Asignado',
    value: 5,
    styleProps: {
      borderColor: '#FAAF19',
      backgroundColor: '#FFFBE6'
    }
  },
  {
    text: 'Regresando',
    value: 6,
    styleProps: {
      borderColor: '#FF4D4F',
      backgroundColor: '#FFF2F0'
    }
  }
]

export const OPERATION_ORIGIN_FILTERS = [
  {
    text: 'B.O.',
    value: 'backoffice|proposal'
  },
  {
    text: 'Landings',
    value: 'landing'
  },
  {
    text: 'Adm-web',
    value: 'adm-web'
  },
  {
    text: 'Sitio web',
    value: 'Sitio web'
  }
]

export const REMOVAL_ORIGIN_FILTERS = [
  {
    text: 'App',
    value: 'App'
  },
  {
    text: 'backoffice|removal',
    value: 'backoffice|removal'
  }
]
