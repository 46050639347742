/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { ServiceOperationType, ServiceTypeId } from '../../TransactionHandler/ServiceType/common'
import { Paging } from '../../AccountManager/Satellite/common'
import { ServicesListSortKey } from '../../TransactionHandler/ServiceType/list'
import { CamelCasedPropertiesDeep } from 'type-fest'

export interface Service {
  id: string
  multiplier: number
  operation_type: ServiceOperationType
  type: string
  deposits: number[]
  solution: ServiceTypeId
  solution_id: number
}
interface APIResponse {
  results: Service[]
  paging: Paging
}

export type ServiceSearch = CamelCasedPropertiesDeep<Service>
export interface ServiceTypeResponse {
  services: ServiceSearch[]
  total: number
}

type ListParams = {
  limit?: number
  offset?: number
  countryCode?: string
  order?: SortDirection
  sort?: ServicesListSortKey
  displayOrder?: number
}

export function search(params?: ListParams): Promise<ServiceTypeResponse> {
  const snakesParams = snakecaseKeys({
    limit: params?.limit,
    offset: params?.offset,
    order: params?.order,
    sort: params?.sort,
    countryCode: params?.countryCode,
    displayOrder: params?.displayOrder
  })
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/service-types/search`, { params: snakesParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const resp = response.data
      if (!resp) throw new Error('Respuesta incorrecta al obtener servicios')
      const camelResponse: ServiceTypeResponse = {
        total: resp.paging.total,
        services: camelcaseKeys(resp.results).map((service) => ({
          id: service.id,
          multiplier: service.multiplier,
          operationType: service.operationType,
          type: service.type,
          deposits: service.deposits,
          solution: service.solution,
          solutionId: service.solutionId
        }))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
