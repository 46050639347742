import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

interface PriceBandsParams {
  id: number
  country: string
  dateFrom: Moment | null
  price: number
  typePrice: string
}

export function updatePriceBand(params: PriceBandsParams): Promise<void> {
  const { id } = params

  const priceBand = {
    country_id: params.country,
    date_from: params.dateFrom,
    price: params.price,
    type: params.typePrice
  }

  return apiPrivate
    .put(URL_BASE_CATEGORIES + '/price-bands/' + id, priceBand)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
