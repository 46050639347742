import React from 'react'
import ColorBadge from '../../../components/ColorBadge'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { getFilterProps, getSearchProps, getSortProps } from '../../../components/DynamicTable/utils'
import { Payment } from '../../../projectApi/Payments/Payment/list'
import { formatDate } from '../../../utils/formatDate'
import { formatMoney } from '../../../utils/formatNumber'
import {
  MovementsTableCategoryFilter,
  MovementsTableSearchFilter,
  MovementsTableSearchKey,
  MovementsTableSort
} from '../types/MovementsTable'
import { PaymentStatusKey, PaymentTypeKey } from '../utils'
import { TableActionBar } from '../../../components/TableActionBar'

export type MovementsTableProps = {
  payments: Payment[]
  loading: boolean
  sendingCSV: boolean
  pagination: Pagination
  searchFilters: MovementsTableSearchFilter[]
  sort: MovementsTableSort
  categoryFilter: MovementsTableCategoryFilter
  handleSort: (newSort: MovementsTableSort) => void
  handleSearch: (key: MovementsTableSearchKey, text: string) => void
  handleCategoryFilter: (newCategoryFilter: MovementsTableCategoryFilter) => void
  handleSendCSV: () => void
  handleResetFilters: () => void
}

const MovementsTable: React.FC<MovementsTableProps> = ({
  payments,
  loading,
  sendingCSV,
  pagination,
  sort,
  categoryFilter,
  searchFilters,
  handleCategoryFilter,
  handleSearch,
  handleSort,
  handleSendCSV,
  handleResetFilters
}) => {
  const column: Column<Payment>[] = [
    {
      key: 'id',
      label: 'ID',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'mercadoPagoId',
      label: 'Mercado Pago ID',
      search: getSearchProps('mercadoPagoId', 'Mercado Pago ID', handleSearch, searchFilters)
    },
    {
      key: 'userName',
      label: 'Usuario',
      renderDataCell: ({ user }) => `${user.name} ${user.lastName}`
    },
    {
      key: 'email',
      label: 'Email',
      renderDataCell: ({ user }) => `${user.email}`,
      search: getSearchProps('email', 'Email', handleSearch, searchFilters)
    },
    {
      key: 'total',
      label: 'Importe',
      renderDataCell: ({ total }) => formatMoney(total),
      sort: getSortProps('total', sort, handleSort)
    },
    {
      key: 'creditCard',
      label: 'Tarjeta',
      renderDataCell: ({ card }) => `${card.type} - ${card.lastDigits}`
    },
    {
      key: 'paymentDate',
      label: 'Fecha',
      renderDataCell: ({ paymentDate }) => (paymentDate ? formatDate(paymentDate) : '-')
    },
    {
      key: 'paymentType',
      label: 'Tipo',
      filters: getFilterProps(
        PaymentTypeKey,
        categoryFilter,
        'paymentType',
        // @ts-ignore
        handleCategoryFilter
      )
    },
    {
      key: 'paymentStatus',
      label: 'Estado',
      renderDataCell: ({ paymentStatus }) => {
        const found = PaymentStatusKey.find((ps) => ps.value === paymentStatus.id)
        if (!found) return '-'
        const props = {
          ...found.styleProps
        }
        return <ColorBadge {...props}>{found.text}</ColorBadge>
      },
      filters: getFilterProps(
        PaymentStatusKey,
        categoryFilter,
        'paymentStatus',
        // @ts-ignore
        handleCategoryFilter,
        true
      )
    }
  ]

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction toolTip variant="csv" disabled={sendingCSV} onClickButton={handleSendCSV} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        </TableActionBar.Wrapper>
      </TableActionBar>
      <DynamicTable
        columns={column}
        rows={payments}
        keyExtractor={({ id }) => id}
        loading={loading}
        pagination={pagination}
      />
    </>
  )
}

export default MovementsTable
