import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Buffer } from 'buffer'
import BillingByClientActionCreator from '../actionCreators/billingByClientTable'
import { BillingByClientTable, BillingsByClientProps } from '../components/BillingByClientTable'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import PaymentModalActionCreator from '../actionCreators/paymentModal'
import { Pagination } from '../../../utils/searchFilterUtils'
import { QueryTypes } from '../types/billingByClientTable'

const BillingByClientContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { getBillingByClient, getPdfWithBillingId, getBillingDiscounts, setQueryData, getClientBillingInformation } =
    bindActionCreators(BillingByClientActionCreator, dispatch)

  const {
    billingsByClient,
    loadingBillingsByClient,
    pagination,
    discounts,
    queryData,
    fetchingClientBillingInformation,
    clientBillingInformation,
    errorClientBillingInformation
  } = useSelector((state) => state.BillingV2.BillingByClientTable)

  const emptyPagination = { ...pagination, page: 1 }

  const { openPaymentModal } = bindActionCreators(PaymentModalActionCreator, dispatch)

  const urlEncoded = history.location.search

  const checkQueryParsed = (): QueryTypes => {
    const { userId, name, lastName } = queryString.parse(unescape(Buffer.from(urlEncoded, 'base64').toString('ascii')))
    return { userId: Number(userId), name, lastName }
  }

  useEffect(() => {
    const { lastName, name, userId } = checkQueryParsed()
    if (!userId && (!name || !lastName)) {
      history.push('billing')
    } else {
      setQueryData({ userId, lastName, name })
    }
  }, [])

  useEffect(() => {
    const { userId } = checkQueryParsed()
    getBillingByClient({ pagination: emptyPagination, userId })
    getClientBillingInformation(userId)
  }, [])

  const handleReturnButton = () => history.goBack()

  const handleGetBillingByClient = (newParams: { pagination?: Pagination }) => {
    const { userId } = checkQueryParsed()
    getBillingByClient({
      pagination: newParams.pagination || pagination,
      userId
    })
  }

  const handleGetBillingDiscount = (billingId: number) => {
    getBillingDiscounts(billingId)
  }

  const handlePageChange = (newPage: number) => {
    handleGetBillingByClient({ pagination: { ...pagination, page: newPage } })
  }

  const handlePdfDownload = (billingId: number) => {
    getPdfWithBillingId(billingId)
  }
  useEvents(Events.Billing.AUTHORIZATION_EXISTS, ({ pdfUrl }) => window.open(pdfUrl))
  useEvents(Events.Billing.AUTHORIZATION_DOESNT_EXIST, () => {
    alert('No existe factura para ese abono')
  })
  useEvents(Events.Billing.BILLING_PAID, () => handleGetBillingByClient({}))

  const props: BillingsByClientProps = {
    discounts,
    name: queryData.name,
    lastName: queryData.lastName,
    userId: queryData.userId,
    billings: billingsByClient,
    loading: loadingBillingsByClient,
    pagination: {
      currentPage: pagination.page,
      pageSize: pagination.pageSize,
      total: pagination.total,
      onPageChange: handlePageChange
    },
    handlePdfDownload,
    handleReturnButton,
    handleModalOpen: (row) => openPaymentModal({ modalOpen: true, billing: row }),
    handleGetBillingDiscount,
    fetchingClientBillingInformation,
    clientAuthorizationEnable: clientBillingInformation?.authorizationEnabled || false,
    errorClientBillingInformation
  }

  return <BillingByClientTable {...props} />
}

export default BillingByClientContainer
