import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_PROFILE } from '../../../../endpoints'
import { APIAddress, Address, formatAddress } from './common'
import camelcaseKeys from 'camelcase-keys'

interface APIResponse {
  description: APIAddress
  success: boolean
}

interface Response {
  address: Address
}

export function save(formData: FormData): Promise<Response> {
  return apiPrivate
    .post(URL_BASE_PROFILE + '/admin/address', formData)
    .then((response: AxiosResponse<APIResponse>) => {
      const address = camelcaseKeys(response.data.description, { deep: true })

      return {
        address: formatAddress(address)
      }
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
