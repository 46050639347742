import types from '../types/confirmModal'

const initialRoot = {
  objectTarget: {},
  isOpenModal: false
}

function modalConfirmReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.OPEN_MODAL_CONFIRM:
      return {
        ...state,
        isOpenModal: true
      }
    case types.CLOSE_MODAL_CONFIRM:
      return {
        ...state,
        isOpenModal: false
      }
    case types.SET_OBJECT_CONFIRM:
      return {
        ...state,
        objectTarget: action.payload.objectTarget
      }
    case types.CLEAN_STATE:
      return {
        initialRoot
      }
    default:
      return state
  }
}

export default modalConfirmReducer
