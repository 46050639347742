import { User } from '../../../projectApi/AccountManager/Users/common'
import { Agent } from '../../../projectApi/Mercurio/BO/Agents/common'

export interface AgentModalState {
  openModal: boolean
  user: User | null
  agent: Agent | null
  loadingUser: boolean
  loadingCreateAgent: boolean
  error: string
  firstName: string
  lastName: string
  email: string
  userGuid: string
  userId: number
  roleId: number
  total: number
}

export interface RoleValue {
  label?: string
  value?: number
}

export enum ERROR_EMAIL_TYPES {
  ALREADY_IN_USE = 'Ya existe un agente con este email',
  INVALID = 'No se puede crear agente con este usuario, por favor verificar email',
  NOTUSER = 'No existe un usuario con este email, por favor verificar email',
  ACCEPTED = ''
}

export type AgentCreateData = {
  firstName: string
  lastName: string
  email: string
  userGuid: string
  userId: number
  roleId: number
}

export const OPEN_MODAL = 'AGENTS/AGENT_MODAL/OPEN_MODAL'
export const SET_ERROR_MODAL_AGENT = 'AGENTS/AGENT_MODAL/SET_ERROR_MODAL_AGENT'

export interface OpenModal {
  type: typeof OPEN_MODAL
  payload: { openModal: boolean }
}

export const GET_USER_BY_EMAIL_REQUEST = 'AGENTS/AGENT_MODAL/GET_USER_BY_EMAIL_REQUEST'
export const GET_USER_BY_EMAIL_SUCCESS = 'AGENTS/AGENT_MODAL/GET_USER_BY_EMAIL_SUCCESS'
export const GET_USER_BY_EMAIL_FAILURE = 'AGENTS/AGENT_MODAL/GET_USER_BY_EMAIL_FAILURE'

export interface GetUserByEmailRequest {
  type: typeof GET_USER_BY_EMAIL_REQUEST
}
export interface GetUserByEmailSuccess {
  type: typeof GET_USER_BY_EMAIL_SUCCESS
  payload: {
    total: number
    user: User
    error: string
  }
}
export interface GetUserByEmailFailure {
  type: typeof GET_USER_BY_EMAIL_FAILURE
  payload: { error: string }
}

export type GetUserAction = GetUserByEmailRequest | GetUserByEmailSuccess | GetUserByEmailFailure

export const SET_AGENT_ROLE_ID = 'AGENTS/AGENT_MODAL/SET_AGENT_ROLE_ID'

export interface SetAgentRoleId {
  type: typeof SET_AGENT_ROLE_ID
  payload: { roleId: number }
}

export type SetAgentValuesAction = SetAgentRoleId

export const CREATE_AGENT_REQUEST = 'AGENTS/AGENTS_MODAL/CREATE_AGENT_REQUEST'
export const CREATE_AGENT_SUCCESS = 'AGENTS/AGENTS_MODAL/CREATE_AGENT_SUCCESS'
export const CREATE_AGENT_FAILURE = 'AGENTS/AGENTS_MODAL/CREATE_AGENT_FAILURE'

export interface CreateAgentRequest {
  type: typeof CREATE_AGENT_REQUEST
}

export interface CreateAgentSuccess {
  type: typeof CREATE_AGENT_SUCCESS
}

export interface CreateAgentFailure {
  type: typeof CREATE_AGENT_FAILURE
  payload: { error: string }
}

export type CreateAgentAction = CreateAgentRequest | CreateAgentSuccess | CreateAgentFailure

export const GET_AGENT_BY_EMAIL_REQUEST = 'AGENTS/AGENT_MODAL/GET_AGENT_BY_EMAIL_REQUEST'
export const GET_AGENT_BY_EMAIL_SUCCESS = 'AGENTS/AGENT_MODAL/GET_AGENT_BY_EMAIL_SUCCESS'
export const GET_AGENT_BY_EMAIL_FAILURE = 'AGENTS/AGENT_MODAL/GET_AGENT_BY_EMAIL_FAILURE'

export interface GetAgentByEmailRequest {
  type: typeof GET_AGENT_BY_EMAIL_REQUEST
}

export interface GetAgentByEmailSuccess {
  type: typeof GET_AGENT_BY_EMAIL_SUCCESS
  payload: { agent: Agent; error: string }
}

export interface GetAgentByEmailFailure {
  type: typeof GET_AGENT_BY_EMAIL_FAILURE
  payload: { error: string }
}

export interface SetErrorModalAgent {
  type: typeof SET_ERROR_MODAL_AGENT
  payload: { errorMessage: string }
}

export type GetAgentByEmailAction = GetAgentByEmailRequest | GetAgentByEmailSuccess | GetAgentByEmailFailure

export type AgentModalAction =
  | OpenModal
  | SetErrorModalAgent
  | GetUserAction
  | CreateAgentAction
  | SetAgentValuesAction
  | GetAgentByEmailAction
