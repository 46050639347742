import React, { useMemo } from 'react'
import { Modal } from 'antd'
import styles from './CancelModal.module.scss'
import Select from '../../../../components/Select'
import { CancelReason } from '../../../../projectApi/TransactionHandler/Operation/Cancel/list'
import { BaseOption } from '../../../../components/Select/Select'

type Option = BaseOption<number>
interface CancelModalProps {
  operationId: number
  modalTitle: string
  modalOpen: boolean
  sendingData: boolean
  isDisabled: boolean
  closeModal: () => void
  setReason: ({ reasonId }: { reasonId: number }) => void
  onOk: () => void
  selectData: {
    loading: boolean
    cancelOptions: CancelReason[]
    selected: number
  }
  errorHandler?: {
    message?: string
    error?: boolean
  }
}

const CancelModal = ({
  modalTitle,
  modalOpen,
  sendingData,
  isDisabled,
  selectData: { loading: loadingReasons, cancelOptions, selected },
  operationId,
  errorHandler,
  closeModal,
  setReason,
  onOk
}: CancelModalProps) => {
  const placeHolderSelect = 'Seleccione un motivo de cancelación'

  const formatCancelOptions: Option[] = useMemo(() => {
    return cancelOptions.map((reason: CancelReason) => ({ value: reason.id, label: reason.name }))
  }, [cancelOptions])

  const getOptionSelected = (reasonId: number): Option => {
    const { id, name } = cancelOptions.find((reason: CancelReason) => reason.id === reasonId) || { id: 0, name: '' }

    return { value: id, label: name }
  }

  return (
    <Modal
      title={modalTitle}
      visible={modalOpen}
      okText={'Sí'}
      onOk={onOk}
      cancelText={'Cerrar'}
      onCancel={closeModal}
      className={styles.modalContainer}
      okButtonProps={{ loading: sendingData, disabled: isDisabled }}>
      <h2 className={styles.modalTitle}>{`¿Esta seguro que desea cancelar la operación #${operationId}?`}</h2>
      <Select
        placeholder={placeHolderSelect}
        loading={loadingReasons}
        options={formatCancelOptions}
        selected={getOptionSelected(selected)}
        onSelect={(option) => setReason({ reasonId: option.value })}
      />
      {errorHandler?.error && <p className={styles.errorMessage}>{errorHandler.message}</p>}
    </Modal>
  )
}

export default CancelModal
