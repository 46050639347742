import React, { useEffect, useState } from 'react'
import useDebounce from '../../utils/hooks/useDebounce'
import InputFieldWithError from '../InputFieldWithError/inputFieldWithError'

interface Props {
  type?: React.HTMLInputTypeAttribute
  styles?: any
  label: string
  value: string
  errorMessage?: string
  successMessage?: string
  tabIndex?: number
  loading?: boolean
  disabled?: boolean
  required?: boolean
  className?: string
  setValue: (newValue: string) => void
}

const DebouncedInputFieldWithError: React.FC<Props> = ({
  label,
  value,
  errorMessage,
  successMessage,
  loading,
  disabled,
  required,
  tabIndex,
  type,
  setValue,
  className,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value)
  const debouncedValue = useDebounce(localValue)

  useEffect(() => {
    setValue(debouncedValue)
  }, [debouncedValue])

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <InputFieldWithError
      label={label}
      value={localValue}
      onChange={(e) => {
        setLocalValue(e.target.value)
      }}
      successMessage={successMessage}
      error={errorMessage}
      loading={loading}
      disabled={disabled}
      required={required}
      tabIndex={tabIndex}
      type={type}
      className={className}
      {...props}
    />
  )
}

export default DebouncedInputFieldWithError
