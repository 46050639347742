import {
  BillingModalTypes,
  CancelBillingModal,
  CloseBillingModal,
  OpenBillingModal,
  SetAuthorizationEnabled,
  SetBillingAddress,
  SetBillingEnabled,
  SetCity,
  SetEmail,
  SetIdentificationCode,
  SetIdentificationNameClient,
  SetIdentificationType,
  SetPostalCode,
  SetProvince,
  SetSaleCondition,
  SetTaxCondition
} from '../types/billingModal'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { SaveUserBillingInformationRequest } from '../../../projectApi/TransactionHandler/UserBillingInformation/common'
import { TaxCondition } from '../Users.constants'

const actions = {
  setIdentificationType: (identificationType: string): SetIdentificationType => ({
    type: BillingModalTypes.SET_IDENTIFICATION_TYPE,
    payload: { identificationType }
  }),

  setIdentificationCode: (identificationCode: string): SetIdentificationCode => ({
    type: BillingModalTypes.SET_IDENTIFICATION_CODE,
    payload: { identificationCode }
  }),

  setIdentificationNameClient: (identificationNameClient: string): SetIdentificationNameClient => ({
    type: BillingModalTypes.SET_IDENTIFICATION_NAME_CLIENT,
    payload: { identificationNameClient }
  }),

  setTaxCondition: (taxCondition: TaxCondition): SetTaxCondition => ({
    type: BillingModalTypes.SET_TAX_CONDITION,
    payload: { taxCondition }
  }),

  setSaleCondition: (saleCondition: number): SetSaleCondition => ({
    type: BillingModalTypes.SET_SALE_CONDITION,
    payload: { saleCondition }
  }),

  setEmail: (email: string): SetEmail => ({
    type: BillingModalTypes.SET_EMAIL,
    payload: { email }
  }),

  setBillingAddress: (billingAddress: string): SetBillingAddress => ({
    type: BillingModalTypes.SET_BILLING_ADDRESS,
    payload: { billingAddress }
  }),

  setCity: (city: string): SetCity => ({
    type: BillingModalTypes.SET_CITY,
    payload: { city }
  }),

  setProvince: (province: string): SetProvince => ({
    type: BillingModalTypes.SET_PROVINCE,
    payload: { province }
  }),

  setPostalCode: (postalCode: string): SetPostalCode => ({
    type: BillingModalTypes.SET_POSTAL_CODE,
    payload: { postalCode }
  }),

  setBillingEnabled: (billingEnabled: boolean): SetBillingEnabled => ({
    type: BillingModalTypes.SET_BILLING_ENABLED,
    payload: { billingEnabled }
  }),

  setAuthorizationEnabled: (authorizationEnabled: boolean): SetAuthorizationEnabled => ({
    type: BillingModalTypes.SET_AUTHORIZATION_ENABLED,
    payload: { authorizationEnabled }
  }),

  openBillingModal: (userId: number, country: CountryIdCode): OpenBillingModal => ({
    type: BillingModalTypes.OPEN_BILLING_MODAL,
    payload: {
      userId,
      country
    }
  }),

  cancelBillingModal: (): CancelBillingModal => ({ type: BillingModalTypes.CANCEL_BILLING_MODAL }),

  closeBillingModal: (): CloseBillingModal => ({ type: BillingModalTypes.CLOSE_BILLING_MODAL }),

  getBillingClientInfo: (userId: number): AppThunk => {
    return function (dispatch) {
      dispatch({ type: BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_REQUEST })
      return API.TransactionHandler.UserBillingInformation.getByUserId({ userId }).then(
        (response) => {
          if (response.results.length > 0) {
            dispatch({
              type: BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_SUCCESS,
              payload: response.results[0]
            })
          } else {
            dispatch({
              type: BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_SUCCESS,
              payload: { userId }
            })
          }
        },
        (error) => {
          handleErrorsWithAction(error, BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_FAILURE, dispatch)
        }
      )
    }
  },
  saveBillingClientInfo: (billingInfoObject: SaveUserBillingInformationRequest): AppThunk => {
    return function (dispatch) {
      dispatch({ type: BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_REQUEST })

      return API.TransactionHandler.UserBillingInformation.save(billingInfoObject).then(
        () => {
          dispatch({ type: BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_SUCCESS })
        },
        (error) => {
          handleErrorsWithAction(error, BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_FAILURE, dispatch)
        }
      )
    }
  },

  updateBillingClientInfo: (userId: number, billingInfoObject: SaveUserBillingInformationRequest): AppThunk => {
    return function (dispatch) {
      dispatch({ type: BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_REQUEST })
      return API.TransactionHandler.UserBillingInformation.update(userId, billingInfoObject).then(
        () => {
          dispatch({ type: BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_SUCCESS })
        },
        (error) => {
          handleErrorsWithAction(error, BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_FAILURE, dispatch)
        }
      )
    }
  }
}

export default actions
