import { Remittance } from '../../projectApi/TransactionHandler/Remittances/search'

export type RemittanceModalState = {
  open: boolean
  remittance: Remittance | null
  transactionId: number
  loading: boolean
  error: string
}

export const SET_OPEN = 'REMITTANCE_MODAL/SET_OPEN'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    transactionId?: number
  }
}

export const GET_TRANSACTION_REMITTANCE_REQUEST = 'REMITTANCE_MODAL/GET_TRANSACTION_REMITTANCE_REQUEST'
export const GET_TRANSACTION_REMITTANCE_SUCCESS = 'REMITTANCE_MODAL/GET_TRANSACTION_REMITTANCE_SUCCESS'
export const GET_TRANSACTION_REMITTANCE_FAILURE = 'REMITTANCE_MODAL/GET_TRANSACTION_REMITTANCE_FAILURE'

export interface GetTransactionRemittancesRequest {
  type: typeof GET_TRANSACTION_REMITTANCE_REQUEST
  payload: {
    transactionId: number
  }
}

export interface GetTransactionRemittancesSuccess {
  type: typeof GET_TRANSACTION_REMITTANCE_SUCCESS
  payload: {
    transactionId: number
    remittance: Remittance | null
  }
}

export interface GetTransactionRemittancesFailure {
  type: typeof GET_TRANSACTION_REMITTANCE_FAILURE
  payload: {
    error: string
  }
}

export type GetTransactionRemittancesAction =
  | SetOpen
  | GetTransactionRemittancesRequest
  | GetTransactionRemittancesSuccess
  | GetTransactionRemittancesFailure

export type RemittanceModalAction = GetTransactionRemittancesAction
