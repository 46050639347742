import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'

export interface UserResponse {
  addresses: {
    city: string
    country: {
      name: string
    }
    full: string
    id: number
    main_address: boolean
    number: string
    postal_code: {
      allowed: boolean
      code: string
      id: number
    }
    province: string
    street: string
  }
  country_id: string
  date_of_birth: Moment
  email: string
  guid: string
  gender: string
  identificationType: CamelCasedPropertiesDeep<APIIdentificationType>
  landline: string
  lastName: string
  name: string
  payDay: number
  paymentMethod: {
    id: number
    description: string
  }
  phone: string
  realId: number
  registeredFrom: string
  role: CamelCasedPropertiesDeep<APIRole>
}

export interface User {
  id: number
  acceptedTerms: string
  accountType: number
  addresses: CamelCasedPropertiesDeep<APIAddress[]>
  billType: string
  cuil: string
  cards: CamelCasedPropertiesDeep<APICard[]>
  company: string
  country: string
  countryId?: string
  creationDate: string
  dni: string
  dateOfBirth: Moment | null
  disableDate: string
  email: string
  guid: string
  gender: string
  identificationType: CamelCasedPropertiesDeep<APIIdentificationType>
  landline: string
  lastName: string
  name: string
  payDay: number
  paymentMethod: {
    id: number
    description: string
  }
  phone: string
  realId: number
  registeredFrom: string
  role: Role
}

export type Role = CamelCasedPropertiesDeep<APIRole>

export type APICard = {
  ID: number
  MpCardID: string
  Main: boolean
  LastDigits: string
  Type: string
  TypePhoto: string
  Expiration: string
}

export interface APIUser {
  id: number
  AcceptedTerms: string
  AccountType: number
  Addresses: APIAddress[]
  BillType: string
  CUIL: string
  Cards: any[] // TODO: Type this correctly
  Company: string
  Country: string
  CountryId: string
  CreationDate: string
  DNI: string
  DateOfBirth: string
  DisableDate: string
  Email: string
  GUID: string
  Gender: string
  IdentificationType: APIIdentificationType
  Landline: string
  LastName: string
  Name: string
  PayDay: number
  PaymentMethod: APIPaymentMethod
  Phone: string
  RealId: number
  RegisteredFrom: string
  Role: APIRole
}

export interface APIAddress {
  ID: number
  Original: string
  Address: string
  Number: string
  Floor: string
  Apartment: string
  MainAddress: boolean
  Latitude: number
  Longitude: number
  PostalCode: APIPostalCode
  InDeliveryRange: boolean
  City: string
  Province: string
  Comment: string
  Country: string
}

export interface APIPostalCode {
  PostalCode: string
  Allowed: boolean
}

export interface APIIdentificationType {
  ID: number
  Type: string
  Country: APICountry
}

export interface APICountry {
  ID: string
  Name: string
}

export interface APIPaymentMethod {
  ID: number
  Description: string
}

export interface APIRole {
  ID: UserRole
  Description: string
  Permissions: any // TODO: Type this correctly
}

export enum UserRole {
  ADMINISTRADOR = 1,
  PICKUPERO = 2,
  REGISTRADOR = 3,
  CLIENTE = 4,
  DEPOSITADOR = 5,
  FOTOGRAFO = 6,
  OPERADOR_LOGISTICO = 7,
  ADMINISTRACION_Y_FACTURACION = 8,
  ROOT = 9,
  INVENTARIADOR = 10,
  OPERARIO = 11,
  TRANSPORTISTA = 13,
  EQUIPO = 100
}
