import { Country, CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { ChangeEvent } from 'react'
import { pathOr } from 'ramda'
import { CategoryOption, ServiceTypeOption } from '../components/productTable'
import { PartialRecord } from '../../../utils/utilityTypes'

export const getValue = (e: ChangeEvent<HTMLInputElement>): number =>
  parseFloat(String(pathOr(0, ['target', 'value'], e)))

const defaultFields: FieldsByCountry = {
  name: '',
  depositFloorsByStairsCost: 0,
  packagingCost: 0,
  disassembleCost: 0,
  floorsByStairsCost: 0,
  removalPackagingCost: 0,
  assemblyCost: 0,
  maxAmountAvowedInCents: 0,
  percentageAvowed: 0,
  maxRefundAmountInCents: 0,
  eligibleForDonation: false,
  eligibleForRecycled: false,
  eligibleForRent: false,
  eligibleForSale: false,
  automaticDepositCostPerFloor: false,
  automaticCostPerFloor: false,
  depositFloorsByStairsCostLoading: false,
  floorsByStairsCostLoading: false
}

export type FieldsByCountry = {
  name: string
  packagingCost: number
  disassembleCost: number
  depositFloorsByStairsCost: number
  floorsByStairsCost: number
  removalPackagingCost: number
  assemblyCost: number
  maxAmountAvowedInCents: number
  percentageAvowed: number
  maxRefundAmountInCents: number
  eligibleForDonation: boolean
  eligibleForRecycled: boolean
  eligibleForRent: boolean
  eligibleForSale: boolean
  automaticDepositCostPerFloor: boolean
  automaticCostPerFloor: boolean
  depositFloorsByStairsCostLoading: boolean
  floorsByStairsCostLoading: boolean
}

export enum OperationType {
  REMOVAL = 'removal',
  DEPOSIT = 'deposit'
}

export type CountryDescription = {
  countryCode: string
  name: string
  productId: number
  assurance: {
    maxAmountAvowedInCents: number
    percentageAvowed: number
    maxRefundAmountInCents: number
  }
  entryCosts: {
    depositCostPerFloorInCents: number
    disassemblyCost: number
    packagingCostInCents: number
  }
  returnCosts: {
    assemblyCostInCents: number
    costPerFloorInCents: number
    unpackagingCostInCents: number
  }
  eligibility: {
    eligibleForDonation: boolean
    eligibleForRecycled: boolean
    eligibleForRent: boolean
    eligibleForSale: boolean
  }
  automaticCostPerFloor: boolean
  automaticDepositCostPerFloor: boolean
}

export type FormattedProduct = {
  GUID: string
  ID: number
  fieldsByCountry: FieldsByCountryRecord
  category: CategoryOption | null
  serviceType: ServiceTypeOption | null
  packagingTime: number
  disassembleTime: number
  weight: number
  width: number
  height: number
  length: number
  minVolumeDeviation: number
  maxVolumeDeviation: number
  showInSearch: boolean
  multiplier: number
  deprecated: boolean
  photoMin: number
}

export type FieldsByCountryRecord = PartialRecord<CountryIdCode, FieldsByCountry>

const formatFieldsByCountry = (countryDescription: CountryDescription | undefined): FieldsByCountry => {
  if (!countryDescription) return defaultFields

  const { name, entryCosts, returnCosts, assurance, eligibility, automaticDepositCostPerFloor, automaticCostPerFloor } =
    countryDescription
  return {
    name,
    depositFloorsByStairsCost: entryCosts.depositCostPerFloorInCents / 100,
    packagingCost: entryCosts.packagingCostInCents / 100,
    disassembleCost: entryCosts.disassemblyCost,
    floorsByStairsCost: returnCosts.costPerFloorInCents / 100,
    assemblyCost: returnCosts.assemblyCostInCents / 100,
    removalPackagingCost: returnCosts.unpackagingCostInCents / 100,
    maxAmountAvowedInCents: assurance.maxAmountAvowedInCents / 100,
    percentageAvowed: assurance.percentageAvowed,
    maxRefundAmountInCents: assurance.maxRefundAmountInCents / 100,
    eligibleForDonation: eligibility.eligibleForDonation,
    eligibleForRecycled: eligibility.eligibleForRecycled,
    eligibleForRent: eligibility.eligibleForRent,
    eligibleForSale: eligibility.eligibleForSale,
    automaticDepositCostPerFloor,
    automaticCostPerFloor,
    depositFloorsByStairsCostLoading: false,
    floorsByStairsCostLoading: false
  }
}

export const createFieldsByCountry = (
  countryDescriptions: CountryDescription[],
  countries: Country[]
): FieldsByCountryRecord => {
  return countries.reduce((acc: FieldsByCountryRecord, country) => {
    const countryDescription = countryDescriptions.find((countryDesc) => country.code === countryDesc.countryCode)

    return {
      ...acc,
      [country.code]: formatFieldsByCountry(countryDescription)
    }
  }, {})
}

export type AutomaticFloorByStairsKeyType = 'automaticDepositCostPerFloor' | 'automaticCostPerFloor'
export type FloorByStairsKeyType = 'depositFloorsByStairsCost' | 'floorsByStairsCost'

export const AutomaticFloorByStairsKeys: AutomaticFloorByStairsKeyType[] = [
  'automaticDepositCostPerFloor',
  'automaticCostPerFloor'
]

export const getOperationTypeFromAutomaticStairField = (field: AutomaticFloorByStairsKeyType): OperationType => {
  switch (field) {
    case 'automaticDepositCostPerFloor':
      return OperationType.DEPOSIT
    case 'automaticCostPerFloor':
    default:
      return OperationType.REMOVAL
  }
}

export const getStairFieldByOperationType = (operationType: OperationType): FloorByStairsKeyType => {
  switch (operationType) {
    case OperationType.DEPOSIT:
      return 'depositFloorsByStairsCost'
    case OperationType.REMOVAL:
      return 'floorsByStairsCost'
  }
}

export const calculateM3 = (length: number, width: number, height: number) =>
  Math.round((length * width * height) / 10000) / 100
