import { Reducer } from 'redux'
import {
  CANCEL_EDIT_ORIGIN_MODAL,
  CLOSE_EDIT_ORIGIN_MODAL,
  EDIT_ORIGIN_FAILURE,
  EDIT_ORIGIN_REQUEST,
  EDIT_ORIGIN_SUCCESS,
  EditOriginState,
  OPEN_EDIT_ORIGIN_MODAL,
  SET_CHANNEL,
  SET_ERROR,
  SET_SIRENA_SOURCE
} from '../types/editOrigin'

const initialState: EditOriginState = {
  open: false,
  id: 0,
  sirenaSource: '',
  channel: 0,
  isLoading: false,
  error: ''
}

const EditOriginReducer: Reducer<EditOriginState> = (state = initialState, action): EditOriginState => {
  switch (action.type) {
    case OPEN_EDIT_ORIGIN_MODAL:
      return {
        ...state,
        open: true,
        id: action.payload.id,
        sirenaSource: action.payload.sirenaSource,
        channel: action.payload.channel
      }
    case CLOSE_EDIT_ORIGIN_MODAL:
      return {
        ...state,
        open: false
      }
    case CANCEL_EDIT_ORIGIN_MODAL:
      return {
        ...state,
        open: false
      }
    case SET_SIRENA_SOURCE:
      return {
        ...state,
        sirenaSource: action.payload.sirenaSource,
        error: ''
      }
    case SET_CHANNEL:
      return {
        ...state,
        channel: action.payload.channel,
        error: ''
      }
    case EDIT_ORIGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case EDIT_ORIGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        open: false
      }
    case EDIT_ORIGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default EditOriginReducer
