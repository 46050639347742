import { BillingsAPI } from './Billings'
import { DepositsOperationsAPI } from './DepositsOperations'
import { OperationAPI } from './Operation'
import { RemittancesAPI } from './Remittances'
import { PickupersAPI } from './Pickupers'
import { ServiceTypeAPI } from './ServiceType'
import { TransactionAPI } from './Transaction'
import { ProposalsAPI } from './Proposals'
import { OtherEndpoints } from './Other'
import { DiscountsAPI } from './Discounts'
import { TransactionsAPI } from './Transactions'
import { BillingDiscountsAPI } from './BillingDiscounts'
import { PickingsAPI } from './Pickings'
import { DepositsAPI } from './Deposits'
import { ComputeAPI } from './Compute'
import { TransportModeAPI } from './TransportMode'
import { ReportsAPI } from './Reports'
import { NoTransportChargeReasonsAPI } from './NoTransportChargeReasons'
import { UserBillingInformationAPI } from './UserBillingInformation'
import { ColppyAPI } from './Colppy'
import { DebtsAPI } from './Debts'
import { FileAPI } from './Files'
import { LocationsAPI } from './Locations'
import { BillingDataAPI } from './BillingData'
import { AuthDocumentAPI } from './AuthDocument'
import { RemovalsAPI } from './Removals'
import { UserDiscountsAPI } from './UserDiscounts'
import { DashboardAPI } from './Dashboard'

export const TransactionHandlerAPI = {
  DepositsOperations: DepositsOperationsAPI,
  Colppy: ColppyAPI,
  Operation: OperationAPI,
  Proposals: ProposalsAPI,
  Transaction: TransactionAPI,
  Transactions: TransactionsAPI,
  ServiceType: ServiceTypeAPI,
  Billings: BillingsAPI,
  BillingData: BillingDataAPI,
  OtherEndpoints,
  BillingDiscounts: BillingDiscountsAPI,
  Discounts: DiscountsAPI,
  Remittances: RemittancesAPI,
  Pickupers: PickupersAPI,
  Picking: PickingsAPI,
  TransportMode: TransportModeAPI,
  Deposits: DepositsAPI,
  Compute: ComputeAPI,
  Reports: ReportsAPI,
  NoTransportChargeReasons: NoTransportChargeReasonsAPI,
  UserBillingInformation: UserBillingInformationAPI,
  UserDiscounts: UserDiscountsAPI,
  Debts: DebtsAPI,
  File: FileAPI,
  Location: LocationsAPI,
  AuthDocument: AuthDocumentAPI,
  Removals: RemovalsAPI,
  Dashboard: DashboardAPI
}
