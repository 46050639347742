import { Address, TransportActionTypes, TransportTypes } from '../types/transport'
import commonTypes from '../types/common'
import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'
import { NoTransportChargeReason } from '../../../projectApi/TransactionHandler/NoTransportChargeReasons/list'
import { GetRemovalData } from '../types/editMode'
import { TransportModeName } from '../../../common/operations/constants'

const initialState = {
  addresses: [],
  loadingAddresses: false,
  errorAddresses: '',
  address_id: 0,
  loadingSaveAddress: false,
  errorSaveAddress: '',
  where: '',
  loadingCost: false,
  cost: 300,
  errorCost: '',
  transportModes: [],
  loadingTransportModes: false,
  errorTransportModes: '',
  noTransportChargeReasons: [],
  loadingNoTransportChargeReasons: false,
  errorNoTransportChargeReasons: '',
  noTransportCharge: false,
  noTransportChargeReason: 0,
  reasonModalOpen: false,
  selectedTransport: TransportModeName.DROPOFF,
  requireAddressDestination: false,
  selectedDeposit: null,
  fetchedCost: 0,
  fetchedAddresses: false,
  fetchedTransportModes: false,
  fetchedRemovalData: false,
  shouldFetchCost: true,
  tollCostInCents: 0
}

export type TransportState = {
  addresses: Address[]
  loadingAddresses: boolean
  errorAddresses: string
  address_id: number
  where: string
  loadingSaveAddress: boolean
  errorSaveAddress: string
  loadingCost: boolean
  cost: number
  errorCost: string
  transportModes: TransportMode[]
  loadingTransportModes: boolean
  errorTransportModes: string
  noTransportChargeReasons: NoTransportChargeReason[]
  loadingNoTransportChargeReasons: boolean
  errorNoTransportChargeReasons: string
  noTransportCharge: boolean
  noTransportChargeReason: number
  reasonModalOpen: boolean
  selectedTransport: TransportModeName
  requireAddressDestination: boolean
  fetchedCost: number
  fetchedAddresses: boolean
  fetchedTransportModes: boolean
  fetchedRemovalData: boolean
  shouldFetchCost: boolean
  tollCostInCents: number
}

const root = (state: TransportState = initialState, action: TransportActionTypes): TransportState => {
  switch (action.type) {
    case commonTypes.FINISH:
      return initialState
    case TransportTypes.SET_ADDRESS_ID:
      return {
        ...state,
        address_id: action.payload.address_id,
        where: action.payload.addressString
      }
    case TransportTypes.GET_ADDRESSES_REQUEST:
      return {
        ...state,
        loadingAddresses: true,
        errorAddresses: ''
      }
    case TransportTypes.GET_ADDRESSES_SUCCESS: {
      const { addresses } = action.payload
      return {
        ...state,
        loadingAddresses: false,
        fetchedAddresses: true,
        addresses
      }
    }
    case TransportTypes.GET_ADDRESSES_FAILURE:
      return {
        ...state,
        loadingAddresses: false,
        errorAddresses: action.payload.error
      }
    case TransportTypes.SAVE_ADDRESS_REQUEST:
      return {
        ...state,
        loadingSaveAddress: true,
        errorSaveAddress: ''
      }
    case TransportTypes.SAVE_ADDRESS_SUCCESS: {
      const { address } = action.payload
      return {
        ...state,
        loadingSaveAddress: false,
        addresses: [address, ...state.addresses],
        address_id: address.ID
      }
    }
    case TransportTypes.SAVE_ADDRESS_FAILURE:
      return {
        ...state,
        loadingSaveAddress: false,
        errorSaveAddress: action.payload.error
      }
    case TransportTypes.FETCH_COST_REQUEST:
      return {
        ...state,
        loadingCost: true,
        errorCost: ''
      }
    case TransportTypes.FETCH_COST_SUCCESS:
      return {
        ...state,
        loadingCost: false,
        fetchedCost: action.payload.cost,
        cost: action.payload.cost,
        where: action.payload.where
      }
    case TransportTypes.FETCH_COST_FAILURE:
      return {
        ...state,
        loadingCost: false,
        errorCost: action.payload.error
      }
    case TransportTypes.SET_TRANSPORT_COST:
      return {
        ...state,
        cost: action.payload.cost
      }
    case TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST:
      return {
        ...state,
        loadingTransportModes: true,
        errorTransportModes: ''
      }
    case TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS: {
      const { transportModes } = action.payload
      return {
        ...state,
        loadingTransportModes: false,
        fetchedTransportModes: true,
        transportModes,
        requireAddressDestination:
          state.selectedTransport === TransportModeName.DROPOFF ? true : state.requireAddressDestination
      }
    }
    case TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_FAILURE:
      return {
        ...state,
        loadingTransportModes: false,
        errorTransportModes: action.payload.error
      }
    case TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_REQUEST:
      return {
        ...state,
        loadingNoTransportChargeReasons: true,
        errorNoTransportChargeReasons: ''
      }
    case TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_SUCCESS: {
      const { reasons } = action.payload
      return {
        ...state,
        loadingNoTransportChargeReasons: false,
        noTransportChargeReasons: reasons
      }
    }
    case TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_FAILURE:
      return {
        ...state,
        loadingNoTransportChargeReasons: false,
        errorNoTransportChargeReasons: action.payload.error
      }
    case TransportTypes.SET_NO_TRANSPORT_CHARGE:
      return {
        ...state,
        reasonModalOpen: action.payload.noTransportCharge,
        noTransportCharge: action.payload.noTransportCharge,
        noTransportChargeReason: (action.payload.noTransportCharge && state.noTransportChargeReasons[0]?.id) || 0,
        cost: action.payload.noTransportCharge ? 0 : state.fetchedCost
      }
    case TransportTypes.SET_NO_TRANSPORT_CHARGE_REASON:
      return {
        ...state,
        noTransportChargeReason: action.payload.noTransportChargeReason
      }
    case TransportTypes.SET_NO_TRANSPORT_CHARGE_REASON_MODAL_OPEN:
      return {
        ...state,
        reasonModalOpen: action.payload.modalOpen
      }
    case TransportTypes.SET_TRANSPORT_MODE: {
      const { transportMode, requireAddressDestination, shouldFetchCost } = action.payload

      return {
        ...state,
        selectedTransport: transportMode,
        requireAddressDestination,
        address_id: 0,
        fetchedCost: requireAddressDestination ? state.fetchedCost : 0,
        cost: requireAddressDestination ? state.cost : 0,
        noTransportCharge: requireAddressDestination ? state.noTransportCharge : false,
        shouldFetchCost
      }
    }
    case GetRemovalData.GET_REMOVAL_DATA_SUCCESS: {
      const isDropoff = action.payload.operation.transportType === TransportModeName.DROPOFF

      // Al editar devolución. En caso de no encontrar la dirección de devolución en la lista actual de direcciones del usuario. Se reemplazaría por la dirección principal del usuario.
      // const mainAddress = setMainAddress(action.payload.operation.address.id, state.addresses)

      return {
        ...state,
        selectedTransport: action.payload.operation.transportType as TransportModeName,
        // address_id: isDropoff ? mainAddress?.ID : 0,
        address_id: isDropoff ? action.payload.operation.address.id : 0,
        cost: action.payload.operation.transportCost,
        tollCostInCents: action.payload.operation.tollCostInCents,
        fetchedRemovalData: true,
        shouldFetchCost: isDropoff
      }
    }
    case TransportTypes.FETCH_TOLL_COST_REQUEST:
      return {
        ...state,
        loadingCost: true,
        errorCost: ''
      }
    case TransportTypes.FETCH_TOLL_COST_SUCCESS:
      return {
        ...state,
        loadingCost: false,
        tollCostInCents: action.payload.tollCostInCents
      }
    case TransportTypes.FETCH_TOLL_COST_FAILURE:
      return {
        ...state,
        loadingCost: false,
        errorCost: action.payload.error
      }
    default:
      return state
  }
}

export default root
