import React, { useEffect } from 'react'
import ModalComponent, { ModalReasonsProps } from '../components/ModalReasons'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import modalActionCreators from '../actions/ModalReasons'
import commonActionCreators from '../actions/common'
import { useHistory } from 'react-router'

const ModalReasonsContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { modalOpen, removalReasons, reasonSelected, anotherReason } = useSelector(
    (store) => store.NewRemoval.modalReasons
  )

  const { finish } = bindActionCreators(commonActionCreators, dispatch)
  const { openModal, getRemovalReasons, setReasonSelected, setAnotherReason } = bindActionCreators(
    modalActionCreators,
    dispatch
  )

  useEffect(() => {
    modalOpen && getRemovalReasons()
  }, [modalOpen])

  const handleCloseModal = () => {
    openModal({ modalOpen: false })
    finish()
  }

  const handleOkModal = () => {
    history.push('newRemoval')
    openModal({ modalOpen: false })
  }

  const props: ModalReasonsProps = {
    modalOpen,
    reasonsList: removalReasons.list,
    loadingReasons: removalReasons.fetching,
    errorLoading: removalReasons.error,
    reasonSelected,
    anotherReason,
    setReasonSelected,
    setAnotherReason,
    handleCloseModal,
    handleOkModal
  }

  return <ModalComponent {...props} />
}

export default ModalReasonsContainer
