import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/shippersTable'
import {
  GetShippersListRequest,
  GetShippersListSuccess,
  GetShippersListTypes,
  SendCSVReportOfShippersRequest,
  SendCSVReportOfShippersSuccess,
  SendCSVReportOfShippersTypes,
  ShippersSearchFilter,
  ShippersTableGeneralTypes
} from '../types/shippersTable'

const shippersTableActionCreators = {
  cleanTableState: (): AppThunk => (dispatch) => {
    dispatch({ type: ShippersTableGeneralTypes.CLEAN_TABLE_STATE })
    setTimeout(() => Emitter.emit(Events.Shippers.CLEAN_FILTERS), 50)
  },
  getShippers:
    ({ pagination, searchFilters }: { pagination: Pagination; searchFilters?: ShippersSearchFilter[] }): AppThunk =>
    async (dispatch) => {
      const request: GetShippersListRequest = {
        type: GetShippersListTypes.GET_SHIPPERS_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          searchFilters
        }
      }

      dispatch(request)
      try {
        const { shippers, total } = await API.Trips.Shippers.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetShippersListSuccess = {
          type: GetShippersListTypes.GET_SHIPPERS_SUCCESS,
          payload: {
            shippers,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetShippersListTypes.GET_SHIPPERS_FAILURE, dispatch)
      }
    },

  sendCSVReportOfShippers:
    ({ pagination, searchFilters }: { pagination: Pagination; searchFilters?: ShippersSearchFilter[] }): AppThunk =>
    async (dispatch) => {
      const request: SendCSVReportOfShippersRequest = {
        type: SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')
      try {
        await API.Trips.Shippers.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          format: 'csv'
        })

        const success: SendCSVReportOfShippersSuccess = {
          type: SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(error, SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_FAILURE, dispatch)
      }
    }
}

export default shippersTableActionCreators
