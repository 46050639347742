import React, { ChangeEvent } from 'react'
import { Checkbox, Col, Icon, Row } from 'antd'
import InputFieldWithError from '../InputFieldWithError/inputFieldWithError'
import actions from './actions'
import { CBUState, CBUStatus, CBUStatusType } from './types'
import MaskedInput from 'antd-mask-input'
import { getAssociatedBank, isValidCBU, removeCBUSpaces } from '../../utils/cbuChecker'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const styles = {
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  errorFieldLabel: {
    color: 'red',
    marginTop: 6,
    marginBottom: 0
  },
  successFieldLabel: {
    color: 'green',
    marginTop: 6,
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red !important'
  },
  select: {
    width: '100%'
  },
  checkbox: {
    marginLeft: 8,
    marginTop: 3
  }
}

export type CBUComponentProps = {
  cbuActions: typeof actions
  cbuReducer: CBUState
  isEdit: boolean
}

type FormattedCBU = {
  cbuValid: boolean
  formattedCBU: string
}

const getCBUStatusStyle = (cbuStatus: CBUStatus) => {
  switch (cbuStatus?.status) {
    case CBUStatusType.success:
      return styles.successFieldLabel
    case CBUStatusType.error:
      return styles.errorFieldLabel
  }
}

const getCBUStatus = (formattedCBU: FormattedCBU, isBlurEvent: boolean): CBUStatus => {
  const { cbuValid, formattedCBU: cbu } = formattedCBU
  if (cbuValid) {
    return {
      status: CBUStatusType.success,
      message: getAssociatedBank(cbu)
    }
  }
  if (!cbuValid && !isBlurEvent) {
    return {
      status: CBUStatusType.typing,
      message: ''
    }
  }
  return {
    status: CBUStatusType.error,
    message: 'CBU/CVU incorrecto'
  }
}

const getFormattedCBU = (cbu: string): FormattedCBU => {
  const formattedCBU = removeCBUSpaces(cbu)
  const cbuValid = isValidCBU(formattedCBU)
  return { formattedCBU, cbuValid }
}

const CBU = (props: CBUComponentProps) => {
  const { cbuActions, cbuReducer } = props
  const { setHolderName, setCBU, setCBUStatus, useDefaultHolderName } = cbuActions
  const { holderName, cbu, cbuStatus, isUsingDefaultHolderName } = cbuReducer

  const onBlur = () => {
    const formattedCBU = getFormattedCBU(cbu)
    checkCBU(formattedCBU, true)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCBU = e.target.value
    setCBU(newCBU)
    const formattedCBU = getFormattedCBU(newCBU)
    const validateCBU = formattedCBU.cbuValid
    checkCBU(formattedCBU, validateCBU)
  }

  const checkCBU = (formattedCBU: FormattedCBU, isBlurEvent: boolean) => {
    setCBUStatus(getCBUStatus(formattedCBU, isBlurEvent))
  }

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <InputFieldWithError
          label="Titular"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setHolderName(e.target.value)}
          value={holderName}
          disabled={isUsingDefaultHolderName}
        />
      </Col>
      <Checkbox
        style={styles.checkbox}
        checked={isUsingDefaultHolderName}
        onChange={(e: CheckboxChangeEvent) => useDefaultHolderName(e.target.checked)}>
        Usar mismo nombre del titular
      </Checkbox>
      <Col>
        <p style={styles.inputTitle}>CBU/CVU</p>
        <MaskedInput
          mask="11111111 11111111111111"
          placeholder="CBU/CVU"
          value={cbu}
          onChange={onChange}
          onBlur={onBlur}
          disabled={props.isEdit}
          suffix={
            cbuStatus?.status === CBUStatusType.success ? (
              <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
            ) : (
              <div />
            )
          }
        />
        {cbuStatus && <p style={getCBUStatusStyle(cbuStatus)}>{cbuStatus.message}</p>}
      </Col>
    </Row>
  )
}

export default CBU
