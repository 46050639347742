import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'

type UpdateParams = {
  discountId: number
  date: string
  discount: number
}

export function update({ discountId, ...params }: UpdateParams) {
  return apiPrivate
    .put(`${URL_BASE_TIMESLOT}/discount/by/specific-day/${discountId}`, params)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
