import moment from 'moment'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_REMOVAL_DETAILS_FAILURE,
  GET_REMOVAL_DETAILS_REQUEST,
  GET_REMOVAL_DETAILS_SUCCESS,
  GetRemovalDetailsRequest,
  GetRemovalDetailsSuccess,
  SingleRemovalFormattedObject
} from '../types/singleRemoval'

const singleRemovalActionCreators = {
  getRemovalDetails:
    (removalId: number): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalDetailsRequest = {
        type: GET_REMOVAL_DETAILS_REQUEST
      }
      dispatch(request)

      try {
        const response = await API.TransactionHandler.Removals.details({ id: removalId })
        const { id, core, minCost, totalMetersCubic } = response

        const removalDetails: SingleRemovalFormattedObject = {
          operationCountryCode: core.countryId || 'ARG',
          objects: core.removalObjects,
          transportCost: core.transportCostInCents / 100,
          tollCostInCents: response.tollCost,
          objectsM3: totalMetersCubic,
          additionalCost: core.additionalCostInCents / 100,
          discountCode: core.discountCode,
          baseInfo: {
            status: core.status,
            fullAddress: core.originalAddress,
            id,
            user: core.user,
            createdAt: moment(core.createdAt).isValid() ? moment(core.createdAt) : null,
            datetime: moment(core.transportDatetime).isValid() ? moment(core.transportDatetime) : null
          },
          minCost: minCost / 100
        }

        const success: GetRemovalDetailsSuccess = {
          type: GET_REMOVAL_DETAILS_SUCCESS,
          payload: { removalDetails }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REMOVAL_DETAILS_FAILURE, dispatch)
      }
    }
}

export default singleRemovalActionCreators
