import { Tooltip, Button } from 'antd'
import React from 'react'
import ImgWithCarousel from '../../../components/CarouselModal/components/ImgWithCarousel'
import ColorBadge from '../../../components/ColorBadge'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { parseMomentDate } from '../../../components/DynamicTable/utils/utils'
import { Camera, QRIcon } from '../../../icons'
import { formatNumber } from '../../../utils/formatNumber'
import notAvailableImage from '../../Objects/images/notAvailableImage.jpg'
import { STATUS_OPTIONS, TYPE_OPTIONS } from '../utils'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import {
  RequestsCategoryFilter,
  RequestsSearchFilter,
  RequestsSearchKey,
  RequestsTableSort
} from '../types/requestsTable'
import { getFilterProps, getSearchProps, getSortProps } from '../../../components/DynamicTable/utils'
import { OBJECT_SITUATION } from '../../../projectApi/ObjectAdministration/common'
import { truncateText } from '../../../utils/truncateText'
import { TableActionBar } from '../../../components/TableActionBar'
import styles from './RequestsTable.module.scss'
import { useHistory } from 'react-router'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Request } from '../../../projectApi/Sales/Requests/common'
import { CategoryFilter } from '../../../utils/searchFilterUtils'

export type RequestsTableProps = {
  pagination: Pagination
  loading: boolean
  requests: Request[]
  searchFilters: RequestsSearchFilter[]
  categoryFilter: RequestsCategoryFilter
  sort: RequestsTableSort
  showDemoUsers: boolean
  handleResetFilters: () => void
  handleOpenQRCodeModal: (code: string) => void
  handleSearch: (key: RequestsSearchKey, newValue: string) => void
  handleCategoryFilter: (newCategoryFilter: RequestsCategoryFilter) => void
  handleShowSaleRequests: () => void
  handleShowDonationRequests: () => void
  handleSort: (newSort: RequestsTableSort) => void
  handleShowDemoUsers: (showDemoUsers: boolean) => void
  actions: MenuAction<Request>[]
}

export const RequestsTable: React.FC<RequestsTableProps> = ({
  requests,
  searchFilters,
  categoryFilter,
  sort,
  loading,
  pagination,
  showDemoUsers,
  handleShowDemoUsers,
  handleOpenQRCodeModal,
  handleSearch,
  handleCategoryFilter,
  handleResetFilters,
  handleShowSaleRequests,
  handleShowDonationRequests,
  handleSort,
  actions
}) => {
  const formattedStatus = STATUS_OPTIONS.map((s) => ({ value: s.value, text: s.text }))
  const formattedTypes = TYPE_OPTIONS.map((t) => ({ value: t.value, text: t.text }))

  const history = useHistory()
  const columns: Column<Request>[] = [
    {
      key: 'id',
      label: 'ID',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'requestType',
      label: 'Tipo de solicitud',
      filters: getFilterProps(
        formattedTypes,
        categoryFilter,
        'type',
        handleCategoryFilter as (newFilter: CategoryFilter<'status'>) => void
      ),
      renderDataCell: ({ requestType }) => {
        const found = TYPE_OPTIONS.find((sf) => sf.value === requestType)

        if (!found) return requestType

        const props = {
          ...found.styleProps
        }
        return (
          <ColorBadge {...props} extraStyles={{ minWidth: 150 }}>
            {found.text}
          </ColorBadge>
        )
      }
    },
    {
      key: 'user',
      label: 'Cliente',
      noPadding: true,
      search: getSearchProps('userId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ user }) => (
        <ClientIDLinkComponent id={user.id} noPadding={true}>
          <div>
            {user.id}-{user.name} {user.lastName}
          </div>
        </ClientIDLinkComponent>
      )
    },
    {
      key: 'identificationCode',
      label: 'Etiqueta',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ object }) => {
        const { identificationCode } = object
        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenQRCodeModal(identificationCode.description)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
            {identificationCode.description}
          </div>
        )
      }
    },
    {
      key: 'photo',
      label: <Camera />,
      renderDataCell: ({ object }) => {
        if (!object.photo) {
          return <img src={notAvailableImage} alt="-" style={{ objectFit: 'cover', width: '40px', height: '42px' }} />
        }

        return <ImgWithCarousel carouselImages={[object.photo.url]} alt={'-'} />
      }
    },
    {
      key: 'objects',
      label: 'Objeto',
      alignment: 'left',
      minWidth: 'max-content',
      sort: getSortProps('object_id', sort, handleSort),
      search: getSearchProps('objectId', 'ID Objeto', handleSearch, searchFilters),
      renderDataCell: ({ object }) => {
        const { widthInCm, heightInCm, lengthInCm, product, description } = object
        const totalM3 = (lengthInCm * widthInCm * heightInCm) / 1_000_000

        return (
          <Button
            type="link"
            style={{
              height: 'auto',
              maxHeight: '90px'
            }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/objects?id=${object.id}`)
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: '12px' }}>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>ID: {object.id}</span>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{truncateText(product.name, 30)}</span>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{truncateText(description, 30)}</span>
              <span>
                {widthInCm}x{heightInCm}x{lengthInCm}(cm)= {formatNumber(totalM3)} m3
              </span>
              <span>Coef. Multip x{product.multiplier}</span>
            </div>
          </Button>
        )
      }
    },
    {
      key: 'createdAt',
      label: 'Creación',
      alignment: 'left',
      sort: getSortProps('created_at', sort, handleSort),
      renderDataCell: ({ createdAt }) => parseMomentDate(createdAt, 'left')
    },
    {
      key: 'updatedAt',
      label: 'Ult. interacción',
      sort: getSortProps('updated_at', sort, handleSort),
      renderDataCell: ({ updatedAt }) => parseMomentDate(updatedAt, 'left')
    },
    {
      key: 'statusAndSituation',
      label: 'Estado y situación del objeto',
      maxWidth: '200px',
      minWidth: 'min-content',
      alignment: 'left',
      renderDataCell: ({ object }) => (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', whiteSpace: 'nowrap' }}>
          <div>
            <b>{object.status}</b>
          </div>
          {object.objectSituation && <div>{OBJECT_SITUATION[object.objectSituation]}</div>}
        </div>
      )
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      sort: getSortProps('status', sort, handleSort),
      filters: getFilterProps(
        formattedStatus,
        categoryFilter,
        'status',
        handleCategoryFilter as (newFilter: CategoryFilter<'status'>) => void,
        true
      ),
      renderDataCell: ({ status }) => {
        const found = STATUS_OPTIONS.find((sf) => sf.value === status)

        if (!found) return status

        const props = {
          ...found.styleProps
        }
        return (
          <ColorBadge {...props} extraStyles={{ minWidth: 150 }}>
            {found.text}
          </ColorBadge>
        )
      }
    }
  ]

  return (
    <>
      <TableActionBar className={styles.actionBar}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction onClickButton={handleResetFilters} variant="cleanFilters" />
          <TableActionBar.Filters>
            <div />
            <label className={styles.checkLabel_userDemo}>
              <input type="checkbox" checked={showDemoUsers} onChange={(e) => handleShowDemoUsers(e.target.checked)} />
              Incluir usuarios demo
            </label>
          </TableActionBar.Filters>
        </TableActionBar.Wrapper>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            iconType="arrow-right"
            onClickButton={handleShowSaleRequests}
            titleButton="Ver solicitudes de venta"
          />
          <TableActionBar.ButtonAction
            iconType="arrow-right"
            onClickButton={handleShowDonationRequests}
            titleButton="Ver solicitudes de donación"
          />
        </TableActionBar.Wrapper>
      </TableActionBar>
      <DynamicTable
        columns={columns}
        rows={requests}
        keyExtractor={({ id }) => id}
        rowHeight={80}
        loading={loading}
        pagination={pagination}
        actions={actions}
        customHeightOffset={270}
      />
    </>
  )
}
