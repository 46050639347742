import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { TransitStateProspect } from '../entities'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import {
  OpenStepModal,
  SET_DISCARD_REASON,
  SET_INTEREST_REASON,
  SET_MODAL_OPEN,
  SET_OPTION_SELECTED,
  SetDiscardReason,
  SetInterestReason,
  SetOptionSelectd,
  UPDATED_LEAD,
  UPDATED_LEAD_FAILURE,
  UPDATED_LEAD_SUCCESS,
  UpdatedLead,
  UpdatedLeadSuccess
} from '../types/OptionsModal'
import { STEP_OPTION } from '../constants'

const stepModalsActionCreators = {
  setOpenModal: (openModal: boolean, leadId: number): OpenStepModal => {
    return { type: SET_MODAL_OPEN, payload: { openModal, leadId } }
  },

  setInterestReason: (interestReason: string): SetInterestReason => {
    return { type: SET_INTEREST_REASON, payload: { interestReason } }
  },

  setDiscardReason: (discardReason: string): SetDiscardReason => {
    return { type: SET_DISCARD_REASON, payload: { discardReason } }
  },

  setOptionSelected: (option: STEP_OPTION): SetOptionSelectd => {
    return { type: SET_OPTION_SELECTED, payload: { option } }
  },

  updateProspect:
    (prospect: TransitStateProspect): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Actualizando oportunidad, por favor espere...')
      const request: UpdatedLead = {
        type: UPDATED_LEAD
      }

      dispatch(request)

      try {
        await API.Mercurio.Prospect.update(prospect)
        const success: UpdatedLeadSuccess = {
          type: UPDATED_LEAD_SUCCESS,
          payload: {
            message: 'Oportunidad actualizada correctamente'
          }
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Oportunidad actualizada correctamente' })
        Emitter.emit(Events.Leads.LEAD_UPDATED)
      } catch (error: any) {
        if (error.response.status === 304) {
          dispatch({
            type: UPDATED_LEAD_FAILURE,
            payload: { error: `Ocurrio un error, intentalo más tarde` }
          })
        } else {
          dispatch({
            type: UPDATED_LEAD_FAILURE,
            payload: { error: `Error al guardar la Oportunidad` }
          })
          setToastErrorUpdate(toastId, { render: 'Error al guardar la Oportunidad' })
          handleErrorsWithAction(error, UPDATED_LEAD_FAILURE, dispatch)
        }
      }
    }
}

export default stepModalsActionCreators
