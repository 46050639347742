import * as React from 'react'
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    x={0}
    y={0}
    width={350}
    height={350}
    style={{
      enableBackground: 'new 0 0 507 383'
    }}
    viewBox="0 0 507 383"
    {...props}>
    <style>
      {
        '.st3{fill:#ffbe90}.st4{fill:#fff}.st5{fill:#5753b8}.st6{fill:#263238}.st7{fill:#37474f}.st9{fill:#5d58f7}.st10{fill:#393991}'
      }
    </style>
    <path
      d="M253.5 383c140 0 253.5-8 253.5-17.8 0-9.9-113.5-17.8-253.5-17.8S0 355.4 0 365.2c0 9.9 113.5 17.8 253.5 17.8z"
      style={{
        fill: '#5d58f7',
        fillOpacity: 0.11
      }}
    />
    <path
      d="m106.7 362.1 197.5.8c48-34.6 53-85.8 18.7-116.9-20.7-18.8-47-15-72.1-18h-.1c-8.7-1-17.3-2.9-25.4-6.8-25.8-12.5-40.3-42-62.1-66.7-2.7-3.1-5.5-6.1-8.5-9-17.9-17.5-53.4-18.1-72 6.5-47.2 62.4-25.5 155.3 24 210.1z"
      style={{
        fill: '#b7b4f3'
      }}
    />
    <path
      d="M102.3 219.1c5.2 74.3 98.7 102.1 157.2 79.8 50.8-19.4 34.2-63.6-8.7-71h-.1c-8.7-1-17.3-2.9-25.4-6.8-25.8-12.5-40.3-42-62.1-66.7-32.3-15.4-64.7 10.3-60.9 64.7z"
      style={{
        fill: '#9e9bd8'
      }}
    />
    <path
      d="M266.4 357.1c-2.7-.9-64.5-46.2-65.7-49.3-.5-1.2 6.2-9.8 14.6-20 1.8-2.2 3.7-4.4 5.6-6.8 12.5-14.9 26.1-30.5 26.1-30.5l41.4 30.2-27.2 29.7-6 6.5s15.2 27 16.1 30.6c.8 3.4-2.2 10.5-4.9 9.6z"
      className="st3"
    />
    <path
      d="M266.4 357.1c-2.7-.9-64.5-46.2-65.7-49.3-.5-1.4 9.4-13.9 20.2-26.8l40.3 29.4-6 6.5s15.2 27 16.1 30.6c.8 3.4-2.2 10.5-4.9 9.6z"
      className="st4"
    />
    <path
      d="M266.4 357.1c-2.7-.9-64.5-46.2-65.7-49.3-.5-1.2 6.2-9.8 14.6-20l39.9 29.1s15.2 27 16.1 30.6c.9 3.4-2.2 10.5-4.9 9.6z"
      className="st5"
    />
    <path
      d="M267.5 354.1c-10.1-7.8-51.9-38.5-62.6-45.5-.1-.1-.1 0 0 .1 9.9 8 51.9 38.3 62.5 45.6.2.1.3 0 .1-.2zM269.1 342.4c-1.1-.9-2.5-1.5-3.9-1.9-1.4-.4-2.9-.5-4.4-.3-2.6.3-5 1.5-6.7 3.4 0 .1.1.1.1.1 2-1.6 4.5-2.6 7.1-2.8 2.6-.2 5.3.4 7.6 1.7h.2c.1-.1.1-.1 0-.2.1 0 0 0 0 0zM258.4 320.7c-4.1-3.1-10.1-5.3-15.4-4.1-.2.1-.1.3 0 .4 5.1.9 10.1 2.3 15 4.2.1 0 .2.1.3 0h.1l.1-.1s0-.1.1-.1v-.1s0-.1-.2-.2c.1 0 .1 0 0 0z"
      className="st6"
    />
    <path
      d="M260.6 324.1c-4.1-3.1-10.1-5.3-15.4-4.1-.2.1-.1.3 0 .4 5.1.9 10.2 2.4 15 4.3h.3c.1 0 .2-.1.2-.2v-.2s0-.1-.1-.1c.1 0 .1-.1 0-.1zM262.8 327.7c-4.1-3.1-10.1-5.3-15.4-4.1-.2 0-.1.3 0 .3 5.1.9 10.1 2.3 15 4.2.1 0 .2.1.3 0h.1l.1-.1s0-.1.1-.1v-.1s-.1-.1-.2-.1zM265 331.2c-4.1-3.1-10.1-5.3-15.4-4.1-.2 0-.1.3 0 .3 5.1.9 10.1 2.3 15 4.2.1 0 .2.1.3 0 .1 0 .2-.1.2-.1s0-.1.1-.1v-.1l-.2-.1c.1 0 .1 0 0 0zM215.4 301.5c-4.7-3.4-10.4 3.2-5.8 6.6 4.7 3.4 10.5-3.2 5.8-6.6zM271.7 313.8c-.7-3.1-4.7-3.5-7.4-2.8-4.6 1.3-8.8 3.6-12.2 7v.2c0 .1-.1.2-.1.3 0 .1 0 .2.1.3.1.1.1.2.2.2.1.1.2.1.3.1 4.6.3 9.4 1.1 14 .1 2.6-.6 5.8-2.4 5.1-5.4zm-12.1 4.4c-2.3-.1-4.5-.3-6.8-.3 2.4-1.4 4.7-2.8 7.2-3.9 1.2-.6 2.5-1 3.7-1.4 2-.6 8.8-.8 5.6 3.6-1.6 2.4-7 2.1-9.7 2z"
      className="st6"
    />
    <path
      d="M240.5 311.9c3.2 3.1 7.7 4.9 11.7 7 .1 0 .2.1.3.1.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2.1-.1.1-.2.1-.3.1 0 .2 0 .2-.1-.8-4.5-2.9-8.6-5.9-12-1.8-2-5.4-3.7-7.9-1.5s-.8 5.2 1 7.1zm-.6-4c0-5.3 5.6-1.7 6.9-.2.8 1 1.6 2 2.2 3.1 1.3 2.2 2.6 4.6 3.6 7-1.9-1.1-3.9-2.1-5.8-3.2-2.2-1.3-6.9-3.8-6.9-6.7z"
      className="st6"
    />
    <path
      d="M330.3 222.6c-.1 5.7-6.3 16-14.7 27.6-16.3 22.3-41.1 49.1-47.2 55.6l-1.4 1.5-43.8-30.9.5-.6 38.6-46.4-115.3-.6 43.8-31.2s3.6 1.9 10.9.9c25.4-3.4 82.8-11 96.3-10.5 17.3.7 32.5 16.3 32.3 34.6z"
      className="st7"
    />
    <path d="M315.5 250.2c-16.3 22.3-41.1 49.1-47.2 55.6l-44.7-30 38.6-46.4 53.3 20.8z" className="st6" />
    <path
      d="M276 212.2c-1.4 1.5-2.8 3-4 4.7l-4.1 4.9v.1c.2-.5.5-1 .7-1.4.9-1.6 1.5-3.3 2.6-4.9.1-.1-.1-.2-.2-.1-1 1.5-2.3 3-3.2 4.7-.4.8-.8 1.6-1.2 2.3-.2.4-.4.9-.6 1.3l-.3.6c-.6.8-1.2 1.5-1.8 2.3-1.3 1.7-2.4 3.5-3.8 5.3-.1.1.1.2.2.1 1.3-1.6 2.8-3.1 4.2-4.7 1.4-1.6 2.7-3.3 4-5 1.3-1.7 2.5-3.3 3.8-4.9 1.4-1.6 2.6-3.3 3.8-5.1v-.1c0-.2 0-.2-.1-.1.1-.1 0 0 0 0z"
      className="st6"
    />
    <path
      d="M162.8 153.4c8.2 9.2 37.8 35.6 56.3 39.5 10.2 2.1 59.5 8.5 66.2 2.8 7.9-6.6 4.3-31.5-2-31.7-19.2-.7-54.7 2.7-63.6 1-4-.8-25.3-13.4-48-23.5-8.6-3.9-14.4 5.6-8.9 11.9z"
      style={{
        fill: '#ad6359'
      }}
    />
    <path
      d="M275.7 161.7s-.1 1-.3 2.6c0 .3 0 .6-.1.9-.1.9-.2 2-.4 3.2v.4c-.1 1-.2 2.1-.4 3.2 0 .3-.1.5-.1.8-.1 1-.3 2.2-.4 3.2v.4c-.1 1.1-.3 2.2-.4 3.3 0 .3-.1.5-.1.8-.1 1.1-.3 2.2-.4 3.2 0 .1 0 .3-.1.4-.1 1.1-.3 2.2-.4 3.2l-.1.8c-.1.7-.2 1.4-.3 2-.1.4-.1.8-.2 1.2v.4l-.3 1.8c-.1.6-.1 1-.2 1.5 0 .3-.1.6-.1.8-.2 1.5-.4 2.7-.6 3.3 0 .1-.1.3-.2.4l-.1.1-.6-.1-1.7-.2-.9-.1-2-.2-1.6-.1h-.5l-3.5-.3-.9-.1-3.5-.3h-.3l-3.5-.3-.9-.1-3.5-.4h-.5l-3.5-.4-.9-.1-3.5-.4h-.7l-3.5-.4-.9-.1-.8-.1-2.8-.3-.5-.1-3.5-.4h-.2l-.7-.1-3.5-.5-.5-.1c-1.3-.2-2.5-.4-3.5-.5l-.9-.2c-1.4-.2-2.6-.4-3.5-.7l-.5-.1-.2-.1c-1-.3-1.9-.6-2.8-1.1l-.4-.2-.4-.2-.5-.3c-1.1-.6-2.3-1.3-3.5-2.1-.2-.1-.3-.2-.5-.3l-.9-.6-1.3-.8-1.4-.9-.9-.7-2.3-1.7-.6-.4-.7-.5-.5-.4-.6-.5c-.8-.6-1.7-1.3-2.5-1.9-.1-.1-.3-.2-.4-.3l-.7-.5-.3-.2c-1.2-.9-2.4-1.9-3.5-2.8l-.3-.2-.2-.2-.3-.3-3.2-2.5-.7-.6-.2-.2-.8-.7c-.9-.8-1.8-1.5-2.7-2.3l-.7-.7-.6-.5-.5-.4-2.4-2.1-.9-.8-.4-.4-1-.9-2.1-1.9-.5-.4-1.1-.9-.5-.4-2-1.8-.9-.8-.7-.6-.8-.7-.1-.1c-.7-.7-1.4-1.4-2-2.2-.2-.2-.3-.4-.5-.6-.1-.2-.2-.3-.3-.5-.1-.1-.2-.3-.3-.4-.7-1-1.2-2.1-1.6-3.2-.1-.3-.2-.6-.3-.8-.3-1.1-.4-2.1-.4-3.2v-.4c.1-1.2.6-2.3 1.3-3.2.3-.3.6-.6.9-.8.2-.2.4-.3.6-.4.2-.1.3-.2.5-.3 1.1-.5 2.3-.8 3.5-.8h1c1 0 2 .2 3 .4l.5.1c.2 0 .3.1.5.1.9.2 1.9.5 2.8.8l.8.3.9.4.5.2c.6.3 1.2.5 1.9.9l1.2.5.5.2 3.2 1.6.3.2.6.3c.1.1.2.1.4.2 1.2.6 2.4 1.2 3.5 1.8l.5.2 2 1.1 1.5.8.2.1.8.4 3.5 1.8.5.3 1.4.8.8.4 1.2.7.9.5 3.5 1.9.4.2 1.6.8 2 1 .9.5 3.4 1.7h.1l.5.2.3.2c1.2.5 2.2 1.1 3.2 1.5l.9.4c1.1.5 2.3 1 3.5 1.3h.2l.3.1c.2 0 .3.1.5.1 1 .1 2 .1 3 .1h.9c1 0 2.2 0 3.5-.1h.5l1.4-.1 2.1-.1h.9l3.5-.2h.5l3.5-.2h.9l3.5-.2h.5l3.5-.2.9-.1 3.5-.2h.5l3.5-.2.9-.1 3.5-.2h.5l3.5-.2.9-.1 3.5-.2h.5l4-.2z"
      className="st9"
    />
    <path
      d="M194.8 182.7c-11.5-8.7-24.6-20.4-30.7-26-8.4-7.8-7.6-19.4 5.3-16.8 3.5 5.3 18.8 31.5 25.4 42.8z"
      className="st10"
    />
    <path
      d="M267.8 165.1c-.1 0-.1-.1 0 0-.1-.1-.1-.1-.2-.1v.1c-.5 2.6-.8 5.2-1.1 7.8-.3 2.6-.6 5.4-.9 8.1-.5 5.3-1.1 10.7-1.3 16.1 0 0 0 .1.1.1h.2l.1-.1c.9-5.3 1.3-10.6 1.9-16 .3-2.6.5-5.3.7-7.9.2-2.6.5-5.4.5-8.1z"
      className="st6"
    />
    <path
      d="M197.8 222.1c-.6.3-1.2.5-1.9.5l-.5.1-1.2.2-2.3.4-.4.1-.5.1-3.5.5-.5.1c-1.1.2-2.3.3-3.5.5l-.9.1-3.5.5-.5.1-3.5.4-.9.1-3.5.4-.5.1-1.4.2-2.1.3h-.4c-.2 0-.4 0-.5.1l-3.5.4-.5.1-3 .3-.5.1-.9.1-3.5.4-.5.1-3.5.4-.9.1-3.5.4-.5.1-3.5.4c-.3 0-.6 0-.9.1l-3.5.4-.5.1h-.6l-2.9.3-.9.1-3.5.3-.5.1h-.2c-1.3.1-2.4.2-3.4.3l-.9.1-1 .1h-.8c-.1-.1-.1-.3-.1-.4 0-1.1.1-2.2.2-3.2 0-.3 0-.6.1-.9.1-1 .2-2 .4-3.2v-.4c.1-1 .3-2.1.4-3.2l.1-.8c.1-1 .3-2.2.4-3.3v-.4l.3-1.8.2-1.5c0-.3.1-.6.1-.8.2-1.1.3-2.2.5-3.2l.1-.4v-.2c.2-1 .3-2 .5-3.1 0-.3.1-.6.1-.8.2-1.1.4-2.2.5-3.2l.1-.4c.2-1.1.4-2.2.5-3.3 0-.3.1-.6.2-.8.2-1.1.4-2.2.6-3.2 0-.1 0-.3.1-.4.2-1.1.4-2.2.6-3.2 0-.3.1-.6.2-.8.1-.3.1-.6.2-.9l.5-2.4v-.2l.1-.4c.2-1.1.4-2.2.7-3.2 0-.3.1-.6.2-.8.2-1.1.5-2.2.7-3.3l.1-.4c.2-1.1.5-2.2.7-3.2.1-.3.1-.5.2-.8.3-1.1.5-2.2.8-3.2 0-.1 0-.2.1-.4.1-.1 0-.1 0-.1.3-1.1.5-2.2.8-3.2l.1-.3c0-.2.1-.4.2-.6.3-1.1.6-2.2.9-3.2 0-.2.1-.3.1-.4.3-1.1.7-2.2 1-3.2l.3-.9c.3-1 .7-2 1-2.9 0-.1.1-.2.1-.3 0-.1.1-.3.2-.4.1-.1.1-.3.2-.5.2-.5.4-1.1.6-1.6.2-.5.4-.8.5-1.2l.4-.8c.5-1.1 1.2-2.2 1.9-3.2 0 0 .2-.3.3-.4.2-.2.4-.4.6-.7l.3-.3.6-.5c.8-.7 1.6-1.2 2.3-1.8.1-.1.2-.1.3-.2l.5-.3.5-.3c1.1-.8 2.1-1.4 3.1-1.9l1.1-.3.2-.1c.5-.3 1.1-.5 1.6-.7.5-.2 1.1-.3 1.7-.4h.1l.3-.1c1.2-.2 2.4-.3 3.5-.4h.9c1.2 0 2.4-.1 3.5 0h.5c1.3 0 2.5.1 3.5.2l.9.1c.6.1 1.1.1 1.6.3l.2.1c.2.1.5.2.6.4.4.3.7.6 1 .9l.5.5c.5.6 1.1 1.2 1.5 1.8.2.3.4.5.6.9.4.6.9 1.3 1.4 2 .3.4.5.8.8 1.3l.1.1.2.3c.6 1 1.2 2.1 1.8 3.2.2.3.3.6.4.9.4.8.7 1.6 1.1 2.4.1.3.3.6.4.9l.1.3.1.2c.4 1 .7 2.1 1 3.1v.1c.1.2.1.5.2.8.2.9.5 1.9.9 3.2 0 .2.1.3.1.4.3 1 .6 2.1.9 3.2 0 .3.1.5.2.8v.1c.3 1 .6 2 .9 3.1v.1c0 .1.1.3.1.4l.9 3.2c.1.3.2.5.2.9.3 1.1.6 2.2.9 3.2 0 .2.1.3.1.4l.9 3.3.2.8v.1c.2.5.3 1.1.5 1.6l.5 1.7c0 .1.1.3.1.4.3 1.1.6 2.2.9 3.2.1.3.2.5.2.8.3 1.1.6 2.2.9 3.2 0 .1.1.3.1.4l.7 2.6.2.7c.1.3.2.6.2.8l.5 1.8c.1.5.3 1 .4 1.5 0 .1.1.3.1.4l.9 3.2c.1.3.2.5.2.8.3 1.1.6 2.2.9 3.2 0 .1.1.3.1.4l.9 3.2.2.8c.1.4.2.7.3 1.1.2.8.4 1.5.5 2.2l.1.4c.3 1.2.5 2.3.8 3.3.1.3.1.6.2.8.5-.3.7.4.6 1.2z"
      className="st9"
    />
    <path
      d="M168.5 226.4c-11.3 1.3-23.8 2.7-33.1 3.6l1.7-79.2c1.4-3.2 2.8-5.5 4.2-6.7l.6-.5 26.6 82.8zM184.2 215.3c-.6-2.5-1.2-4.9-1.7-7.4l-3.6-14.7c-2.5-9.9-5.1-19.7-7.9-29.5-1.5-5.5-3.2-11-4.7-16.5h-.2c2.8 9.8 5.2 19.6 7.7 29.5 2.5 9.8 4.9 19.6 7.3 29.4l2 8.3c.7 2.8 1.2 5.7 2.1 8.4.1.3.7.2.6-.1-.3-2.4-1-4.9-1.6-7.4z"
      className="st10"
    />
    <path
      d="M166.2 149.8c-4.5.5-11.3-6.2-13.8-11.4-.2-.4.8-3.9 1.7-8.2.5-2.6 1.1-5.6 1.4-8.3 0-.5 2 .6 2 .6l3.7 1.2 12.4 3.9c-1.4 3.3-2.4 6.8-2.9 10.3v1.3c.3 2.2-.2 10-4.5 10.6z"
      className="st3"
    />
    <path
      d="M170.8 139v.2c-.6 0-1.3 0-1.9-.1-12.5-1.3-12.6-16.1-12.6-16.1l4.9.6 12.4 3.9c-1.4 3.3-2.4 6.8-2.9 10.3.1.5.1.9.1 1.2z"
      style={{
        fill: '#263238',
        fillOpacity: 0.17
      }}
    />
    <path
      d="M157.8 100.1c-3.3 5.5-3.8 23.4-.1 28.2 5.4 6.8 16.3 9.5 23.1 3.1 6.6-6.2 6.8-29.5 2.4-34-6.4-6.6-20.5-5.5-25.4 2.7z"
      className="st3"
    />
    <path d="m173.9 115-.1.1c0 1.2-.2 2.6-1.4 2.9v.1c1.4-.2 1.7-2 1.5-3.1z" className="st6" />
    <path
      d="M172.8 113.7c-2.1-.2-2.4 3.7-.4 3.9 1.9.1 2.1-3.8.4-3.9zM181.5 115.6l.1.1c-.1 1.2 0 2.6 1.1 3.1v.1c-1.5-.3-1.6-2.2-1.2-3.3z"
      className="st6"
    />
    <path
      d="M182.7 114.5c2.1 0 2 3.9 0 3.9-2-.1-1.7-3.9 0-3.9zM171.2 111.8c.5-.1 1.1-.3 1.7-.4.6-.1 1.2-.3 1.6-.7.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.3-.2-.7-.4-1.1-.5-.4-.1-.8 0-1.2.1-.8.1-1.5.6-2 1.2-.1.1-.2.3-.2.5s.1.3.2.5c.1.1.2.3.4.3h.6zM184.5 112.6c-.6-.1-1.1-.3-1.7-.5-.6-.1-1.1-.4-1.6-.8-.1-.2-.2-.4-.1-.5 0-.2.1-.4.2-.5.3-.2.7-.4 1.1-.4.4 0 .8 0 1.2.2.8.2 1.4.6 1.9 1.3.1.1.1.3.1.5s-.1.3-.2.5c-.1.1-.3.2-.4.3-.1-.1-.3-.1-.5-.1z"
      className="st6"
    />
    <path
      d="M173.5 122.5c.3.5.7.9 1.2 1.3.5.3 1 .6 1.6.7.5.1 1.1.2 1.6.2h.6c.1 0 .1 0 .2-.1 0 0 .1-.1.1-.2v-.2c.1-.9.1-2.3.1-2.3.4.2 2.2 1.1 2.2.6.2-4.2-.1-8.4-.8-12.5v-.1h-.2v.1c-.2 3.9.3 7.7.2 11.6-.7-.3-1.3-.6-2.1-.7-.1 0 0 2.7 0 3.1-.8 0-1.6-.1-2.4-.4-.8-.3-1.5-.7-2.1-1.2-.2-.1-.3 0-.2.1z"
      className="st6"
    />
    <path
      d="M184.1 103.5c-6.7 3.5-14.8-3.2-14.8-3.2-.7 1.3-1.8 2.3-3.1 2.9-1.3.6-2.9.8-4.3.6 0 0-2.2 9.7-5 9.5-4.3-.4-4.4-13.9-2.8-16.4 1.6-2.5 5.3-2.9 5.3-2.9.6-1.6 1.8-2.9 3.3-3.7 3.1-1.8 13 1.7 13 1.7.7-1.2 1.7-2.3 2.9-3 .3-.2.6-.3 1-.4.7-.2 1.4 0 2.1.3.3.2.6.4.8.7-.6.7-1 1.5-1.2 2.4-.8 2.8 1.8 3 2 .4.1-1-.2-2-.8-2.8 1.4-1.6 3.5-2 4.7-1 2.1 1.8 2.5 12-3.1 14.9z"
      style={{
        fill: '#25244f'
      }}
    />
    <path
      d="M158.6 114.9s-3.1-6.3-6.2-5.4c-3 .9-1.5 9.6 1.5 11.2.4.2.8.4 1.2.5.4.1.9.1 1.3 0s.9-.2 1.2-.5c.4-.2.7-.5.9-.9l.1-4.9z"
      className="st3"
    />
    <path
      d="M152.9 112s0 .1 0 0c2.1 1.3 2.9 3.5 3.3 5.6-.1-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4-.3-.1-.5-.1-.8-.1-.3 0-.5.1-.8.2-.1 0 0 .1 0 .1.4 0 .9 0 1.2.2.4.2.7.5.9.8.3.6.5 1.2.7 1.8.1.2.5.2.4-.1.8-2.6-.7-6.7-3.8-7.5z"
      className="st6"
    />
    <path d="m170.8 139.2-4 9.3-14-12.3-3.7 4.2s13.9 19.8 14 18.9l4.2-8.8 6.7 5.8-1-15.1-2.2-2z" className="st9" />
    <path
      d="M173.9 148.8c-.3-2.5-.4-5-.8-7.5h-.2c-.5 2.4.6 11.9.6 14.2-.3-.3-6.2-5.1-6.3-5.1-.9 1.4-4 7.7-4.3 8.4-2.2-3.1-11.5-15.5-14.1-18.5h-.2c2 3.3 14 19.7 14.2 19.7 1.6-3 3.1-6.1 4.4-9.3 1 1.1 7 6.6 7 6.2.2-2.6.1-5.4-.3-8.1z"
      className="st10"
    />
    <path
      d="M225.9 206.2h48.9l9.2-63.5h-48.9l-9.2 63.5z"
      style={{
        fill: '#a6a6a6'
      }}
    />
    <path
      d="M228.3 206.2h48.9l9.2-63.5h-48.9l-9.2 63.5z"
      style={{
        fill: '#dbdbdb'
      }}
    />
    <path
      d="M262.1 171.3c-.1 1.2-.8 2.3-1.7 3.1-1 .8-2.2 1.3-3.5 1.3-.6 0-1.2-.1-1.8-.3-.6-.2-1.1-.5-1.5-1-.4-.4-.7-.9-.9-1.4-.2-.5-.2-1.1-.2-1.6.1-1.2.7-2.3 1.7-3.1 1-.8 2.2-1.3 3.5-1.3.6 0 1.2.1 1.8.3.6.2 1.1.6 1.5 1 .4.4.7.9.9 1.5.2.4.3.9.2 1.5z"
      className="st4"
    />
    <path
      d="M137.9 156.9c-2.3 29.6 3.4 49.9 9 52.1 15.3 5.9 61.8 9.1 72.4 8.1 4.2-.4 1.4-32.1-3.1-32-11.1.3-51.8.6-52.3.4-3.5-1.5-3.6-11.3-11.6-32.4-6.3-17.1-13.5-8.3-14.4 3.8z"
      className="st3"
    />
    <path
      d="M251.9 178.8c.6.7.9 1.7.9 2.6s-.3 1.9-.9 2.6c1.6.5 2.9 1.6 3.8 3 1.4 2.5-2.3 5.5-2.3 5.5 1.5.9 2.7 2.2 3.5 3.8 1 2.5-2.2 6.1-2.2 6.1.9 1.5 1.2 3.3.8 5.1-.8 2.9-8.8 6.7-13.6 8.1-4.8 1.4-19.7 1.3-22.5 1.5l-10-27.9s19.4-11.8 25.2-12.7c4.9-.8 15-.6 17.3 2.3z"
      className="st3"
    />
    <path
      d="M251.6 183.6c-5.4-.3-10.7.8-16 1.7-.1 0-.1.2 0 .2 5.3-.9 10.7-.9 16-1.6h.1v-.1c-.1-.1-.1-.1-.1-.2 0 .1 0 .1 0 0zM253.2 192.3c-1.2 0-2.4.2-3.6.5-1.3.3-2.6.6-3.8.9-2.5.6-5.1 1.3-7.6 2.1 2.5-.6 5.1-1.2 7.7-1.7 1.3-.3 2.6-.5 3.9-.8 1.2-.2 2.4-.4 3.6-.9 0 0 .1 0 .1-.1v-.1s0-.1-.1-.1c-.1.1-.2.1-.2.2zM254.6 202.5c-1.1 0-2.2.3-3.3.7l-3.5.9c-2.3.6-4.6 1.2-7 1.7-.1 0-.1.2 0 .2 2.4-.5 4.8-1 7.1-1.5 1.2-.3 2.3-.5 3.5-.8 1.1-.2 2.1-.5 3.1-1 .1-.1.1-.2.1-.2z"
      className="st6"
    />
    <path
      d="M212 183.5v35.6l-3.1-.1h-2l-2.5-.1h-.5l-3.5-.2h-.9l-3.5-.2h-.5l-3.5-.3-.9-.1-3.5-.3h-.6l-3.5-.3-.9-.1-3.5-.3h-.5c-1.2-.1-2.4-.2-3.5-.4l-.9-.1-3.5-.4-.5-.3-1.7-.2-1.8-.2-.9-.1-.3-.1c-1.1-.2-2.2-.3-3.2-.5l-.5-.1c-1.2-.2-2.4-.4-3.5-.6l-.9-.2c-1.2-.3-2.4-.5-3.5-.8l-.5-.1c-1.2-.3-2.3-.6-3.4-1l-.2-.1-.9-.3c-.4-.2-.8-.3-1.1-.5-.9-.4-1.7-.8-2.4-1.4l-.1-.1-.4-.3c-.4-.4-.9-.9-1.2-1.4-.1-.1-.2-.3-.3-.4-.6-1-1.2-2.1-1.6-3.2l-.3-.8-.5-.5c-.3-.9-.6-1.8-.9-2.8v-.1l-.1-.4c-.3-1-.5-2.1-.8-3.3 0-.3-.1-.6-.2-.8-.2-1-.4-2.2-.6-3.2 0-.1 0-.3-.1-.4-.2-1.1-.4-2.2-.5-3.2 0-.3-.1-.6-.1-.8-.1-1.1-.3-2.2-.4-3.2v-.4c-.1-1.1-.2-2.2-.3-3.2l-.1-.8c-.1-.8-.1-1.7-.2-2.5v-1.3c-.1-1.1-.1-2.2-.2-3.2v-.8c0-1.1-.1-2.2-.1-3.2v-4.5c0-1.1 0-2.2.1-3.2v-.4c0-1.1.1-2.2.2-3.2v-.9c0-.5.1-1 .1-1.5s.1-1.2.2-1.7v-.5c.1-.8.2-1.4.4-2l.3-1.2c.1-.4.2-.6.3-.8.4-1.2.9-2.2 1.6-3.2l.4-.4.3-.3c.3-.3.6-.6.9-.8.5-.4 1.1-.7 1.7-.8.6-.2 1.2-.2 1.9-.1l.5.1c1.3.3 2.5 1 3.5 2l.4.4.5.5c.7.8 1.4 1.7 2 2.7l.5.8c.4.7.7 1.4 1.1 2.1.2.4.3.7.5 1.1l.2.4c.4.9.7 1.8 1 2.8 0 .2.1.3.2.5 0 .2.2.5.3.8l1.1 3.2.1.4c.2.7.4 1.3.7 2 .1.4.3.8.4 1.3.1.4.2.6.3.8.1.3.2.4.2.7l.9 2.6c0 .2.1.3.2.4.4 1.1.7 2.2 1.1 3.2l.3.9c.4 1.1.8 2.2 1.1 3.2v.1c0 .1.1.3.2.4l.3.8c.3.9.6 1.7.9 2.4l.3.8c.4 1.1 1 2.2 1.6 3.2.1.2.2.3.4.4.1.1.2.1.3.1h.9l3.5-.1h4.9l3.5-.1h.5l3.5-.1h.9l3.5-.1h.5l3.5-.1h.9l3.5-.1h.5l3.5-.1h.9l3.5-.1h.5l3.5-.1h.9l3.2-.6z"
      className="st9"
    />
    <path
      d="M212.2 183.6v-.2c0-.1 0-.1-.1-.2 0-.1-.1-.1-.2-.1h-.2c-6.1-.1-12.2.2-18.3.4-6.1.1-12.2.3-18.3.4-3.4.1-10 .4-10.1.3-.1-.1-1.9-5.3-2.3-6.4-.8-2.2-1.6-4.3-2.4-6.4-.2-.6-.5-1.2-.7-1.8-.1-.4-.2-.8-.2-1.2 0-.4-.1-1.1-.2-1.7-.1-1.1-.3-2.3-.6-3.4h-.2v.1c.2 1.2.4 2.3.4 3.5l.1.9c-1.2-2.9-1.5-3.5-2.5-6.4 1.2 4.3 1.9 6.1 3.3 10.4.7 2.2 1.5 4.3 2.3 6.4.4 1 2.2 5.9 2.7 6.9.1.1 6.3-.2 9.3-.2 3 0 6.1-.1 9.2-.2l18.3-.4c3.4-.1 6.8-.1 10.2-.3 0 .4.3 2.2.3 2.3 0 .1.2.1.2 0-.2-.4 0-2.7 0-2.7zM205.1 186.4s0-.1 0 0c0-.1-.1-.1 0 0-.1-.1-.1-.1-.2-.1v.1c-.4 2.5-.4 5-.6 7.5-.2 2.5-.3 5.1-.4 7.7-.2 5.1-.5 10.3-.3 15.5v.2l.1.1s.1 0 .1-.1v-.2c.5-5.1.7-10.2.9-15.3.1-2.5.2-5 .2-7.6.3-2.6.4-5.3.2-7.8z"
      className="st10"
    />
    <path
      d="M212.3 204.6v-2.1h-.1v.4c-.1 2.7-.4 5.3-.5 8-.1 2.7-.2 5.4-.2 8.1-3.3-.3-6.7-.2-10-.4s-6.9-.4-10.3-.6c-6.9-.5-13.8-1.1-20.7-2l-5.8-.7c-1.9-.3-4-.4-5.9-.8-.1 0-.2.2 0 .2 1.7.2 3.5.6 5.2.9 1.7.3 3.4.5 5.1.8 3.4.5 6.8.9 10.3 1.3 6.8.8 13.7 1.3 20.6 1.6l5.9.2c1.9.1 3.9.2 5.8.1.1 0 .2 0 .2-.1.1 0 .1-.1.1-.2 0-2.8.2-5.6.2-8.4v-4.1c.1-.8.1-1.5.1-2.2z"
      className="st6"
    />
    <path
      d="M283.8 161c3.1 3.6 10.2 7.6 11.4 10.8.3.8.4 1.6.1 2.5-.3.8-.8 1.6-1.5 2.1 0 0 4 .9 4.6 4 .3 1.6-2.2 3.4-2.2 3.4.9.2 1.7.7 2.3 1.4.6.6 1 1.4 1.2 2.3.3 2.2-2.3 3.7-2.3 3.7s2.6 2.1 2.1 4.4c-1 4.7-21.4 8.8-26.2 2.6-2.2-2.9 2.8-3.7 2.8-3.7s-7.5-.5-8.5-4.3 5.6-4.4 5.6-4.4-7.3-.5-7.9-4.6c-.6-4.1 6-3.8 6-3.8s-5.2-1.9-4.9-5.1c.5-5.3 11.8-.8 16.1-2.7l1.3-8.6z"
      className="st3"
    />
    <path
      d="M296.3 183.7c.1 0 .1.1 0 .1-7.4 3.2-15.6 4-23.4 2.1-.3-.1-.4-.4-.1-.3 5.4 1 17.1 0 23.5-1.9zM270.8 176.9c7.6 1.8 15.2 1.1 22.8-.5.1 0 .1.1 0 .1-3.5 1.8-8.4 2.4-12.4 2.5-3.6.2-7.2-.4-10.5-1.9v-.2c0 .1 0 .1.1 0-.1 0 0 0 0 0z"
      className="st6"
    />
    <path
      d="M426.5 305.7c-1 2.5-50.6 59-53.9 60.1-1.3.4-10.7-5.7-21.8-13.5-2.4-1.7-4.8-3.4-7.4-5.2-16.2-11.5-33.1-24.1-33.1-24.1l33-37.9 32.2 25.1 7.1 5.5s29.5-13.9 33.4-14.6c3.8-.7 11.5 2.1 10.5 4.6z"
      className="st3"
    />
    <path
      d="M426.5 305.7c-1 2.5-50.6 59-53.9 60.1-1.6.5-15.1-8.7-29.2-18.6l32.1-36.9 7.1 5.5s29.5-13.9 33.4-14.6c3.8-.8 11.5 2 10.5 4.5z"
      className="st4"
    />
    <path
      d="M426.5 305.7c-1 2.5-50.6 59-53.9 60.1-1.3.4-10.7-5.7-21.8-13.5l31.8-36.5s29.5-13.9 33.4-14.7c3.8-.7 11.5 2.1 10.5 4.6z"
      className="st5"
    />
    <path
      d="M423.3 304.7c-8.6 9.3-42.1 47.5-49.8 57.2 0 .1 0 .1.1 0 8.7-9 41.9-47.5 49.9-57.2.2-.1 0-.2-.2 0zM410.6 303.1c-.9 1-1.6 2.2-2.1 3.5-.4 1.3-.5 2.7-.3 4 .2 1.2.6 2.4 1.2 3.5s1.4 2 2.4 2.7c.1 0 .2-.1.1-.1-1.7-1.8-2.8-4.1-3.1-6.6-.2-2.4.5-4.8 1.8-7 .1.1.1.1 0 0zM386.9 312.9c-3.4 3.7-5.8 9.2-4.5 14.1 0 .2.3.1.4 0 1-4.7 2.6-9.3 4.6-13.7 0 0 0-.1.1-.1v-.2l-.1-.1c-.1 0-.2-.1-.3 0-.1-.1-.2-.1-.2 0zM390.7 310.9c-3.4 3.7-5.8 9.2-4.5 14.1 0 .2.3.1.4 0 1-4.7 2.6-9.3 4.7-13.7 0 0 0-.1.1-.1v-.1s0-.1-.1-.1l-.1-.1c-.1 0-.2-.1-.3 0-.1-.1-.2-.1-.2 0z"
      className="st6"
    />
    <path
      d="M394.5 308.9c-3.4 3.7-5.8 9.2-4.5 14 .1.2.3.1.4 0 1-4.7 2.6-9.3 4.6-13.7 0 0 0-.1.1-.1v-.2l-.1-.1c-.1 0-.2-.1-.3 0-.1 0-.2 0-.2.1zM398.3 306.9c-3.4 3.8-5.8 9.2-4.5 14.1 0 .2.3.1.4 0 1-4.7 2.6-9.3 4.7-13.7 0 0 0-.1.1-.1v-.1s0-.1-.1-.1l-.1-.1c-.1 0-.2-.1-.3 0 0-.1-.1-.1-.2 0 .1-.1 0-.1 0 0zM365.8 352.3c-3.7 4.3 3.5 9.6 7.2 5.3 3.8-4.3-3.5-9.5-7.2-5.3zM379.5 300.7c-3.4.6-3.8 4.3-3.1 6.8 1.4 4.4 4.1 8.3 7.6 11.2h.2c.1 0 .2.1.3.1.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2.1-.1.1-.2.1-.3.3-4.2 1.2-8.6.2-12.8-.7-2.4-2.6-5.3-5.8-4.7zm4.7 11.1c-.1 2.1-.3 4.1-.4 6.2-1.5-2.1-2.9-4.3-4.2-6.6-.6-1.1-1.1-2.3-1.6-3.4-.7-1.8-.8-8.1 4-5.2 2.6 1.6 2.3 6.5 2.2 9z"
      className="st6"
    />
    <path
      d="M377.2 329.3c3.4-2.9 5.4-7.1 7.7-10.7 0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.2-.1-.1-.2-.1-.3-.1v-.1h-.2c-4.8.7-9.3 2.5-13.1 5.4-2.2 1.7-4.1 4.9-1.6 7.2 2.4 2.3 5.7.8 7.7-.9zm-4.4.5c-5.8-.1-1.8-5.1-.1-6.3 1.1-.8 2.2-1.4 3.4-2 2.4-1.3 5-2.2 7.6-3.3-1.2 1.7-2.3 3.5-3.5 5.3-1.4 2.1-4.2 6.4-7.4 6.3z"
      className="st6"
    />
    <path
      d="m373.8 304.6-34.4 42.6-116-73.8-11.2-7.1s-3.2 2-7.9 4.4c-6.6 3.3-16.3 7.6-24.9 8.8-14.4 2-38.4 1.7-50.6-13.1-10.2-12.5-3.2-35.3-3.2-35.3l16.9-1.7s34.9-26.8 59.2-27.6c10.3-.4 25.3 6.8 39.2 14.3 13.8 7.3 132.9 88.5 132.9 88.5z"
      className="st7"
    />
    <path
      d="M212.3 266.2s-3.2 2-7.9 4.4c-8.4-9.3-26.3-26.1-26.8-26.7l34.7 22.3zM153.7 221.5c-2.2 1.4-4.4 2.9-6.6 4.4-2.2 1.5-4.4 2.8-6.5 4.4-2.1 1.6-4.1 3.3-6 5-1 .8-1.9 1.7-2.8 2.5-1 .8-1.9 1.7-2.7 2.7-.1.1.1.2.2.1 1-.7 2-1.4 3-2.2 1-.8 2.1-1.6 3.1-2.4 2-1.6 4.1-3.1 6-4.7s3.8-3.3 5.8-4.9c2-1.5 4.3-3.3 6.5-4.9.1 0 0-.1 0 0zM365.7 303.1c-2.2 2.2-4.3 4.6-6.4 6.9-2.1 2.3-4.2 4.7-6.2 7.1-4.1 4.7-8.3 9.4-12.3 14.2-2.2 2.6-4.4 5.3-6.5 8h.2c4.4-4.5 8.4-9.3 12.6-14 4.2-4.7 8.2-9.5 12.3-14.3 2.2-2.6 4.5-5.2 6.5-8v-.2h-.1c0 .3 0 .3-.1.3zM273.6 236.6c-4.1-4.3-21.6-15.7-32.8-20.8h-.1c11.1 6.6 29.5 19 32.7 20.9h.1s.1 0 .1-.1c.1.2.1.1 0 0 .1.1.1 0 0 0z"
      className="st6"
    />
  </svg>
)
export default SvgComponent
