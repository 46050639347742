export enum DepositsEventsEnum {
  DEPOSIT_VALIDATED = 'DEPOSITS/DEPOSIT_VALIDATED',
  DEPOSIT_CANCELLED = 'DEPOSITS/DEPOSIT_CANCELLED',
  CLEAN_FILTERS = 'DEPOSITS/CLEAN_FILTERS'
}

export type DepositsEvents = {
  [DepositsEventsEnum.DEPOSIT_VALIDATED]: () => void
  [DepositsEventsEnum.DEPOSIT_CANCELLED]: () => void
  [DepositsEventsEnum.CLEAN_FILTERS]: () => void
}
