import { SortDirection, SortProps } from '../../components/DynamicTable/types/types'
import { FormattedObject } from '../../projectApi/ObjectAdministration/common'
import { PhotographObjectTableKey, PhotographObjectsTableSort } from './types/PhotographObjectsTable'

export const getIdentificationCode = (obj: FormattedObject) => {
  const found = obj.identificationCodes.find((idcode) => idcode.actual)
  if (!found) return '-'

  return found.code
}

export const getSortProps = (
  key: PhotographObjectTableKey,
  currentSorter: PhotographObjectsTableSort,
  sorterFunction: (newSorter: PhotographObjectsTableSort) => void
): SortProps => {
  return {
    direction: currentSorter.direction,
    isActive: currentSorter.field === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        direction: currentSorter.field === key ? newSortDirection : SortDirection.ASC,
        field: key
      })
  }
}
