export enum OperationCalendarEventsEnum {
  OPERATION_DATE_CREATED = 'OPERATION_CALENDAR/OPERATION_DATE_CREATED',
  OPERATION_DATE_DELETED = 'OPERATION_CALENDAR/OPERATION_DATE_DELETED',
  OPERATION_DAY_UPDATED = 'OPERATION_CALENDAR/DAY_OPERATION_UPDATED'
}

export type OperationCalendarEvents = {
  [OperationCalendarEventsEnum.OPERATION_DATE_CREATED]: () => void
  [OperationCalendarEventsEnum.OPERATION_DATE_DELETED]: () => void
  [OperationCalendarEventsEnum.OPERATION_DAY_UPDATED]: () => void
}
