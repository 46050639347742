import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'

interface DeleteParams {
  id: number
}

export function deleteOrigin(params: DeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_MERCURIO}/sirena-sources/${params.id}`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
