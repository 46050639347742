export const SET_EMAIL = 'PROPOSALS/SEND/SET_EMAIL'
export const SET_ERROR = 'PROPOSALS/SEND/SET_ERROR'

export const OPEN_SEND_BY_EMAIL_MODAL = 'PROPOSALS/SEND/OPEN_SEND_BY_EMAIL_MODAL'
export const CLOSE_SEND_BY_EMAIL_MODAL = 'PROPOSALS/SEND/CLOSE_SEND_BY_EMAIL_MODAL'
export const CANCEL_SEND_BY_EMAIL_MODAL = 'PROPOSALS/SEND/CANCEL_SEND_BY_EMAIL_MODAL'

export const SEND_BY_EMAIL_REQUEST = 'PROPOSALS/SEND/SEND_BY_EMAIL_REQUEST'
export const SEND_BY_EMAIL_SUCCESS = 'PROPOSALS/SEND/SEND_BY_EMAIL_SUCCESS'
export const SEND_BY_EMAIL_FAILURE = 'PROPOSALS/SEND/SEND_BY_EMAIL_FAILURE'

export interface SendByEmailState {
  open: boolean
  proposalId: string
  email: string
  isLoading: boolean
  error: string
}

export interface SetError {
  type: typeof SET_ERROR
  payload: {
    error: string
  }
}

export interface SetEmail {
  type: typeof SET_EMAIL
  payload: {
    email: string
  }
}
export interface OpenSendByEmailModal {
  type: typeof OPEN_SEND_BY_EMAIL_MODAL
  payload: {
    proposalId: string
  }
}

export interface CloseSendByEmailModal {
  type: typeof CLOSE_SEND_BY_EMAIL_MODAL
}

export interface CancelSendByEmailModal {
  type: typeof CANCEL_SEND_BY_EMAIL_MODAL
}

export type OpenCloseCancelAction = OpenSendByEmailModal | CloseSendByEmailModal | CancelSendByEmailModal

export interface SendByEmailRequest {
  type: typeof SEND_BY_EMAIL_REQUEST
}

export interface SendByEmailSuccess {
  type: typeof SEND_BY_EMAIL_SUCCESS
}

export interface SendByEmailFailure {
  type: typeof SEND_BY_EMAIL_FAILURE
  payload: {
    error: string
  }
}

export type SendByEmailAction = SendByEmailRequest | SendByEmailSuccess | SendByEmailFailure

export type ProposalSendByEmailAction =
  | SendByEmailRequest
  | SendByEmailSuccess
  | SendByEmailFailure
  | OpenSendByEmailModal
  | CloseSendByEmailModal
  | CancelSendByEmailModal
  | SetEmail
  | SetError
