/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export const MINUTES_BETWEEN_REPORTS = 10

interface Params {
  tripIds: number[]
}

export function transactionsByTrips({ tripIds }: Params): Promise<void> {
  const params = snakecaseKeys({ tripIds })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/report/transaction-by-trips`, params)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
