import React from 'react'

const SearchIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3242 11.0171L8.52002 7.21289C9.11035 6.44971 9.42969 5.5166 9.42969 4.53516C9.42969 3.36035 8.97119 2.25879 8.14209 1.42822C7.31299 0.597656 6.2085 0.140625 5.03516 0.140625C3.86182 0.140625 2.75732 0.599121 1.92822 1.42822C1.09766 2.25732 0.640625 3.36035 0.640625 4.53516C0.640625 5.7085 1.09912 6.81299 1.92822 7.64209C2.75732 8.47266 3.86035 8.92969 5.03516 8.92969C6.0166 8.92969 6.94824 8.61035 7.71143 8.02148L11.5156 11.8242C11.5268 11.8354 11.54 11.8442 11.5546 11.8503C11.5692 11.8563 11.5848 11.8594 11.6006 11.8594C11.6164 11.8594 11.632 11.8563 11.6466 11.8503C11.6611 11.8442 11.6744 11.8354 11.6855 11.8242L12.3242 11.187C12.3354 11.1759 12.3442 11.1626 12.3503 11.148C12.3563 11.1335 12.3594 11.1178 12.3594 11.1021C12.3594 11.0863 12.3563 11.0706 12.3503 11.0561C12.3442 11.0415 12.3354 11.0282 12.3242 11.0171ZM7.35547 6.85547C6.73438 7.4751 5.91113 7.81641 5.03516 7.81641C4.15918 7.81641 3.33594 7.4751 2.71484 6.85547C2.09522 6.23438 1.75391 5.41113 1.75391 4.53516C1.75391 3.65918 2.09522 2.83447 2.71484 2.21484C3.33594 1.59522 4.15918 1.25391 5.03516 1.25391C5.91113 1.25391 6.73584 1.59375 7.35547 2.21484C7.9751 2.83594 8.31641 3.65918 8.31641 4.53516C8.31641 5.41113 7.9751 6.23584 7.35547 6.85547Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SearchIcon
