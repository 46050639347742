import React from 'react'
import actionCreators from '../actions/optionsModal'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/stepsModals/OptionsModal'
import { bindActionCreators } from 'redux'
import { pathOr } from 'ramda'
import { STEP_OPTION } from '../constants'

const StepModalContainer = () => {
  const dispatch = useDispatch()
  const stepsProps = useSelector((state) => state.Leads.optionsModal)
  const {
    table: { config }
  } = useSelector((state) => state.Leads)
  const stepsActions = bindActionCreators(actionCreators, dispatch)

  const { openModal, interestReason, discardReason, optionSelected, disabledSelect, leadId } = stepsProps
  const { setOpenModal, setInterestReason, setDiscardReason, setOptionSelected, updateProspect } = stepsActions

  const getOptions = (value: string) => {
    const options = pathOr({}, [value], config)
    return Object.entries(options).map(([key, value]) => ({
      label: value,
      value: Number(key)
    }))
  }

  const discardReasons = getOptions('discardReasons')
  const interestReasons = getOptions('interestReason')

  const handleClose = () => {
    setOpenModal(false, 0)
  }

  const onOk = () => {
    const leadUpdate = { id: leadId, interestReason, discardReason, transit: {} }
    updateProspect(leadUpdate)
  }

  const isDisabled =
    optionSelected === STEP_OPTION.NOT_SELECTED ||
    (optionSelected === STEP_OPTION.INTEREST && !interestReason) ||
    (optionSelected === STEP_OPTION.DISCARD && !discardReason)

  const props = {
    openModal,
    discardReasons,
    interestReasons,
    interestReason,
    discardReason,
    optionSelected,
    disabledSelect,
    isDisabled,
    handleClose,
    setInterestReason,
    setDiscardReason,
    setOptionSelected,
    onOk
  }

  return <Component {...props} />
}

export default StepModalContainer
