import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { DatesData } from '../types/operationDates'
import { Trash } from '../../../icons'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import moment, { Moment } from 'moment'
import OperationBadge from './OperationBadge'
import { OperationTypeValues } from '../types/common'

type OperationDatesTableProps = {
  datesData: DatesData[]
  openDeleteDateModal: (arg: boolean) => void
  setSelectedDeleteDate: ({ date, operationType }: { date: Moment; operationType: OperationTypeValues }) => void
  fetchingDates: boolean
}

export const OperationDatesTable = ({
  datesData,
  openDeleteDateModal,
  setSelectedDeleteDate,
  fetchingDates
}: OperationDatesTableProps) => {
  const columns: Column<DatesData>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'date',
      label: 'Fecha',
      renderDataCell: ({ date }) => (date ? moment.utc(date).format('dd DD[-]MM[-]YY') : '-')
    },
    {
      key: 'operationType',
      label: 'Operación',
      renderDataCell: ({ operationType }) => <OperationBadge operation={operationType} />
    }
  ]

  const menuActions: MenuAction<DatesData>[] = [
    {
      label: 'Eliminar registro',
      icon: <Trash />,
      onClick: ({ date, operationType }) => {
        openDeleteDateModal(true)
        setSelectedDeleteDate({ date, operationType })
      }
    }
  ]
  return (
    <div>
      <DynamicTable
        loading={fetchingDates}
        columns={columns}
        rows={datesData}
        keyExtractor={(entity) => entity.id}
        actions={menuActions}
      />
    </div>
  )
}
