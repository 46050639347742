export const DEPOSITED = {
  text: 'Depositado',
  value: 'Depositado',
  numberValue: 1
}

export const RENTED = {
  text: 'Alquilado',
  value: 'Alquilado',
  numberValue: 2
}

export const WITH_OWNER = {
  text: 'En Propietario',
  value: 'En Propietario',
  numberValue: 3
}

export const TO_CATEGORIZE = {
  text: 'A Catalogar',
  value: 'A Catalogar',
  numberValue: 4
}

export const TO_PHOTOGRAPH = {
  text: 'A Fotografiar',
  value: 'A Fotografiar',
  numberValue: 5
}

export const IN_TRIP = {
  text: 'En Tránsito',
  value: 'En Tránsito',
  numberValue: 6
}

export const TO_LOCATE = {
  text: 'A Ubicar',
  value: 'A Ubicar',
  numberValue: 7
}

export const SEARCHING = {
  text: 'Buscando',
  value: 'Buscando',
  numberValue: 8
}

export const FOUND = {
  text: 'Encontrado',
  value: 'Encontrado',
  numberValue: 9
}

export const NOT_FOUND = {
  text: 'No Encontrado',
  value: 'No Encontrado',
  numberValue: 10
}

export const WITH_PRICING = {
  text: 'Con Abono',
  value: 'Con Abono'
}

export const ALL = {
  text: 'Todos',
  value: '',
  numberValue: 0
}

export const OBJECT_STATUS_FILTER = [
  DEPOSITED,
  RENTED,
  WITH_OWNER,
  IN_TRIP,
  TO_CATEGORIZE,
  TO_PHOTOGRAPH,
  TO_LOCATE,
  WITH_PRICING,
  SEARCHING,
  FOUND,
  NOT_FOUND
]

export const OBJECT_STATUS = [
  DEPOSITED,
  RENTED,
  WITH_OWNER,
  IN_TRIP,
  TO_CATEGORIZE,
  TO_PHOTOGRAPH,
  TO_LOCATE,
  SEARCHING,
  FOUND,
  NOT_FOUND
]

export const OBJECT_STATUS_FILTER_FOR_REMOVALS = [
  DEPOSITED,
  RENTED,
  WITH_OWNER,
  IN_TRIP,
  TO_CATEGORIZE,
  TO_PHOTOGRAPH,
  TO_LOCATE,
  SEARCHING,
  FOUND,
  NOT_FOUND,
  ALL
]
