import React from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/Extras'
import objectActionCreators from '../actions/objects'
import CarouselModalActionCreators from '../../../components/CarouselModal/actions/CarouselModal'
import actionsModalConfirm from '../actions/confirmModal'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'

const Container = () => {
  const dispatch = useDispatch()
  const objectsActions = bindActionCreators(objectActionCreators, dispatch)
  const objectsState = useSelector((state) => state.NewRemoval.objects)
  const userSearchState = useSelector((state) => state.NewRemoval.userSearch)
  const userCountry = useCountryInfo(userSearchState.user?.Country)

  const CarouselModalActions = bindActionCreators(CarouselModalActionCreators, dispatch)
  const modalConfirmActions = bindActionCreators(actionsModalConfirm, dispatch)
  const modalConfirm = useSelector((state) => state.NewRemoval.modalConfirmDelete)

  const props = {
    objectsActions,
    objectsState,
    toggleCarouselOpen: (images: string[]) => CarouselModalActions.setOpen(true, images),
    modalConfirmActions,
    modalConfirm,
    userCountry
  }

  return <Component {...props} />
}

export default Container
