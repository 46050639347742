import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'

export type User = {
  RealID: number
  Name: string
  LastName: string
  Country: string
  Email: string
  Phone: string
  DNI: string
  IdentificationType: {
    Type: string
  }
}

export type Deposit = {
  createdAt: string
  datetime: string
  id: number
}

export type DepositResponse = {
  created_at: string
  datetime: string
  id: number
} | null

export enum UserActionTypes {
  TOGGLE_USER_MODAL_OPEN = 'NEW_REMOVAL/USER/TOGGLE_USER_MODAL_OPEN',
  GET_USER_REQUEST = 'NEW_REMOVAL/USER/GET_USER_REQUEST',
  GET_USER_SUCCESS = 'NEW_REMOVAL/USER/GET_USER_SUCCESS',
  GET_USER_FAILURE = 'NEW_REMOVAL/USER/GET_USER_FAILURE',
  GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST = 'NEW_REMOVAL/USER/GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST',
  GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS = 'NEW_REMOVAL/USER/GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS',
  GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE = 'NEW_REMOVAL/USER/GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE'
}

export type GetInfoAboutUserHasDebtsRequest = {
  type: UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST
}

export type GetInfoAboutUserHasDebtsSuccess = {
  type: UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS
  payload: {
    user: UserColppy
  }
}

export type GetInfoAboutUserHasDebtsFailure = {
  type: UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE
  payload: {
    error: string
  }
}

export type ToggleUserModalOpen = {
  type: UserActionTypes.TOGGLE_USER_MODAL_OPEN
  payload: {
    open: boolean
  }
}

export type GetUserRequest = {
  type: UserActionTypes.GET_USER_REQUEST
}

export type GetUserSuccess = {
  type: UserActionTypes.GET_USER_SUCCESS
  payload: {
    user: User
    lastDeposit: Deposit | null
    totalObjects: number
  }
}

export type GetUserFailure = {
  type: UserActionTypes.GET_USER_FAILURE
  payload: {
    error: string
  }
}

export type UsersActions =
  | ToggleUserModalOpen
  | GetUserRequest
  | GetUserSuccess
  | GetUserFailure
  | GetInfoAboutUserHasDebtsRequest
  | GetInfoAboutUserHasDebtsSuccess
  | GetInfoAboutUserHasDebtsFailure
