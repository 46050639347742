import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/login'
import Component from '../components/login'
import { useHistory } from 'react-router'

const Container = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const loginActions = bindActionCreators(actions, dispatch)
  const loginReducer = useSelector((state) => state.Login)

  const { accounts, fetchedAccounts } = loginReducer

  const goToDashboard = () => {
    history.push('/dashboard')
  }

  useEffect(() => {
    if (fetchedAccounts && accounts.length === 0) {
      goToDashboard()
    }
  }, [fetchedAccounts, accounts])

  const props = {
    loginActions,
    loginReducer
  }

  return <Component {...props} />
}

export default Container
