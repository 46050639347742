import { Flag } from '@/projectApi/ObjectAdministration/Value/getValue'
import { TransportTypesPricing } from '../../../../common/operations/constants'
import { formatItemsForDeposit, getRoundedTotalM3 } from '../../../../common/operations/selectors/items'
import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { SelectedItem } from '../../types/EditDepositViewTypes/items'
import {
  FETCH_COST_FAILURE,
  FETCH_COST_REQUEST,
  FETCH_COST_SUCCESS,
  FetchCostFailure,
  FetchCostRequest,
  FetchCostSuccess,
  GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE,
  GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST,
  GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS,
  GET_TRANSPORT_MODE_FAILURE,
  GET_TRANSPORT_MODE_REQUEST,
  GET_TRANSPORT_MODE_SUCCESS,
  GetDepositByTransportModeFailure,
  GetDepositByTransportModeRequest,
  GetDepositByTransportModeSuccess,
  GetTransportModeFailure,
  GetTransportModeRequest,
  GetTransportModeSuccess,
  SET_CUSTOM_TRANSPORT_COST,
  SET_DESTINATION_DEPOSIT,
  SET_TRANSPORT_MODE,
  SET_WHERE_FETCH_COST,
  SetCustomTransportCost,
  SetDestinationDeposit,
  SetTransportMode,
  SetWhereFetchCost
} from '../../types/EditDepositViewTypes/transport'

const getTransportCostMultiplier = (transportTypePricing: string, customDateTransportMult: number): number => {
  switch (transportTypePricing) {
    case TransportTypesPricing.CUSTOM:
      return customDateTransportMult
    case TransportTypesPricing.FREE:
      return 0
    case TransportTypesPricing.COMMON:
    default:
      return 1
  }
}

const TransportActionCreators = {
  getTransportModeByService:
    (serviceType: string): AppThunk =>
    async (dispatch) => {
      const request: GetTransportModeRequest = {
        type: GET_TRANSPORT_MODE_REQUEST
      }

      dispatch(request)

      try {
        const { transportMode: transportModes } = await API.TransactionHandler.TransportMode.getByService(serviceType)

        const success: GetTransportModeSuccess = {
          type: GET_TRANSPORT_MODE_SUCCESS,
          payload: { transportModeList: transportModes }
        }

        dispatch(success)
      } catch (error) {
        console.error(error)
        const failure: GetTransportModeFailure = {
          type: GET_TRANSPORT_MODE_FAILURE
        }
        dispatch(failure)
      }
    },

  setTransportMode: ({ transportMode }: { transportMode: string }): SetTransportMode => {
    setTimeout(() => {
      Emitter.emit(Events.EditDepositView.SET_TRANSPORT_MODE)
    }, 250)

    return {
      type: SET_TRANSPORT_MODE,
      payload: { transportMode }
    }
  },

  fetchCost:
    ({
      where,
      serviceType,
      transportTypePricing,
      customDateTransportMult,
      selectedItems,
      countryCode
    }: {
      where: string
      serviceType: string
      transportTypePricing: string
      customDateTransportMult: number
      selectedItems: SelectedItem[]
      countryCode: string
    }): AppThunk =>
    async (dispatch) => {
      const request: FetchCostRequest = {
        type: FETCH_COST_REQUEST
      }

      dispatch(request)

      const filteredSelectedItems = selectedItems.filter((selectedItem) => !selectedItem.deleted)

      try {
        const params = {
          destination: where,
          type: serviceType,
          items: JSON.stringify(formatItemsForDeposit(filteredSelectedItems)),
          m3: getRoundedTotalM3(filteredSelectedItems),
          countryCode
        }

        const { price, rule: transportDetail } = await API.TransactionHandler.Compute.cost(params)
        const tollCostInCents = await API.ObjectAdministration.Value.getValue({ flag: Flag.TOLL_COST_IN_CENTS })
        const customTransportMult = getTransportCostMultiplier(transportTypePricing, customDateTransportMult)
        const priceParsed = Math.ceil(price)
        const priceWithMult = Math.ceil(price * customTransportMult)

        const success: FetchCostSuccess = {
          type: FETCH_COST_SUCCESS,
          payload: {
            fetchedCost: priceParsed,
            cost: priceWithMult,
            where,
            transportDetail,
            tollCostInCents: parseInt(tollCostInCents)
          }
        }
        dispatch(success)
      } catch (error) {
        const failure: FetchCostFailure = {
          type: FETCH_COST_FAILURE
        }
        dispatch(failure)
        console.error(error)
      }
    },

  getDepositByTransportAndService:
    ({
      serviceType,
      transportModeId,
      countryCode
    }: {
      serviceType: string
      transportModeId: number
      countryCode: string
    }): AppThunk =>
    async (dispatch) => {
      const request: GetDepositByTransportModeRequest = {
        type: GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST
      }
      dispatch(request)

      try {
        const { deposits } = await API.TransactionHandler.ServiceType.deposit({
          countryCode,
          serviceType,
          transportModeId
        })

        const success: GetDepositByTransportModeSuccess = {
          type: GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS,
          payload: { deposits }
        }

        dispatch(success)
      } catch (error) {
        const failure: GetDepositByTransportModeFailure = {
          type: GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE
        }
        dispatch(failure)
      }
    },

  setDestinationDeposit: ({ destinationDepositId }: { destinationDepositId: number }): SetDestinationDeposit => {
    return {
      type: SET_DESTINATION_DEPOSIT,
      payload: { destinationDepositId }
    }
  },

  setWhereDestination:
    ({ where }: { where: string }): AppThunk =>
    (dispatch) => {
      const setter: SetWhereFetchCost = {
        type: SET_WHERE_FETCH_COST,
        payload: { where }
      }

      dispatch(setter)

      setTimeout(() => {
        Emitter.emit(Events.EditDepositView.SET_WHERE)
      }, 250)
    },

  setCustomTransportType: ({
    transportTypePricing,
    newTransportCost
  }: {
    transportTypePricing: TransportTypesPricing
    newTransportCost: number
  }): SetCustomTransportCost => ({
    type: SET_CUSTOM_TRANSPORT_COST,
    payload: { transportTypePricing, newTransportCost }
  })
}

export default TransportActionCreators
