import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Pagination } from '../../../utils/searchFilterUtils'
import ServicesTableActionCreators from '../actionCreators/servicesTable'
import ServicesModalABMActions from '../actionCreators/serviceABM'
import { ServicesTable, ServicesTableProps } from '../components/ServicesTable'
import { ServicesTableSort } from '../types/servicesTable'
import { Edit, Trash } from '../../../icons'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../components/TableActionBar'
import ServiceABMContainer from './serviceABM'
import { Role } from '../../../projectApi/AccountManager/Roles/getByParentId'
import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'

const ServicesTableContainer = () => {
  const dispatch = useDispatch()

  const { services, loadingServices, pagination, sort } = useSelector((state) => state.Services.table)
  const { role } = useSelector((root) => root.UserLogged.userProfile)

  const emptyPagination = { ...pagination, page: 1 }

  const { getServices } = bindActionCreators(ServicesTableActionCreators, dispatch)
  const { selectService, setOpen, setDeleteModalVisibility } = bindActionCreators(ServicesModalABMActions, dispatch)

  const handleGetServices = (newParams: { pagination?: Pagination; sort?: ServicesTableSort }) => {
    getServices({
      pagination: newParams.pagination || pagination,
      sort: newParams.sort || sort
    })
  }

  useEvents(Events.Services.SERVICE_UPDATED, () => handleGetServices({}))

  const handlePageChange = (newPage: number) => {
    handleGetServices({ pagination: { ...pagination, page: newPage } })
  }

  useEffect(() => {
    handleGetServices({ pagination: emptyPagination })
  }, [])
  const validRoles = role?.id === Role.ROOT

  const menuActions = [
    {
      label: 'Editar servicio',
      icon: <Edit />,
      disabled: !validRoles,
      onClick: ({ id, multiplier, operationType, type, solution, deposits, solutionId }: ServiceSearch) => {
        selectService({ id, multiplier, operationType, type, solution, deposits, solutionId })
      }
    },
    {
      label: 'Eliminar servicio',
      icon: <Trash />,
      disabled: !validRoles,
      onClick: (service: ServiceSearch) => setDeleteModalVisibility(true, service)
    }
  ]

  const props: ServicesTableProps = {
    services,
    loadingServices,
    pagination: {
      currentPage: pagination.page,
      pageSize: pagination.pageSize,
      total: pagination.total,
      onPageChange: handlePageChange
    },
    actions: menuActions
  }

  const handleCreationModalOpen = () => {
    setOpen({ open: true, isCreate: true })
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.Spacer />
        <TableActionBar.ButtonAction
          disabled={!validRoles}
          toolTip={!validRoles}
          toolTipCustomMessage={!validRoles ? 'Sólo usuarios Root pueden realizar ésta acción' : ''}
          titleButton="Crear tipo de servicio"
          onClickButton={handleCreationModalOpen}
        />
      </TableActionBar>

      <ServicesTable {...props} />
      <ServiceABMContainer />
    </>
  )
}

export default ServicesTableContainer
