import * as React from 'react'

const CubeReturn = () => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m7.237.164 3.188 2.014v.863L7.387 1.11v2.506H6.75V1.11L3.862 2.918 1.575 4.356H11.1V2.63l.525.288 5.85 3.698.525.33-.488.328-5.85 3.699-.487.328V9.534H7.462v7.356l2.85-1.808 3.226-2.014v-2.424l.675-.411v3.246l-.188.124-3.375 2.137-3.375 2.137-.188.123-.187-.123-3.375-2.137-3.337-2.137L0 13.48V4.52l.15-.123L3.525 2.26 6.9.123 7.05 0l.188.164ZM1.575 5.096l5.813 3.699h4.387v1.191l4.875-3.04-4.837-3.042v1.192H1.575Zm1.8 6.698v2.96h-.038l.525.328L6.75 16.89V9.205L.675 5.384v7.684l.675.412v-2.425h.675v2.877l.675.41v-2.548h.675Z"
      fill="#263238"
    />
  </svg>
)

export default CubeReturn
