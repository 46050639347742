import React, { useEffect } from 'react'
import actions from './actions'
import Component from './component'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { CreditCardNumberFields, CreditCardStringFields } from './types'
import { getIdentificationTypes } from '../../services/mercado-pago'

const Container = () => {
  const dispatch = useDispatch()
  const creditCardActions = bindActionCreators(actions, dispatch)
  const creditCardState = useSelector((state) => state.Components.CreditCard)
  const { setNumberField, setStringField, setDocumentTypeOptions, setErrors } = creditCardActions

  const handleSetNumberField = ({ field, value }: { field: string; value: number }) => {
    const newField = field as CreditCardNumberFields
    setNumberField({ field: newField, value })
  }

  const handleSetStringField = ({ field, value }: { field: string; value: string }) => {
    const newField = field as CreditCardStringFields
    setStringField({ field: newField, value })
  }

  const getIdentificationTypesAsync = async () => {
    const identificationTypes = await getIdentificationTypes()
    setDocumentTypeOptions(identificationTypes)
  }

  useEffect(() => {
    getIdentificationTypesAsync()
  }, [])

  return (
    <Component
      creditCardState={creditCardState}
      setNumberField={handleSetNumberField}
      setStringField={handleSetStringField}
      setErrors={setErrors}
    />
  )
}

export default Container
