import { GetProspectRequest } from './prospect'
import { SetServiceType } from './transport'

export enum FlagsTypes {
  FETCH_CONFIGURATION_FLAGS = 'NEW_PROPOSAL_V2/M3/FETCH_CONFIGURATION_FLAGS',
  FETCH_CONFIGURATION_FLAGS_SUCCESS = 'NEW_PROPOSAL_V2/M3/FETCH_CONFIGURATION_FLAGS_SUCCESS',
  FETCH_CONFIGURATION_FLAGS_FAIL = 'NEW_PROPOSAL_V2/M3/FETCH_CONFIGURATION_FLAGS_FAIL'
}

export interface FetchConfigurationFlags {
  type: FlagsTypes.FETCH_CONFIGURATION_FLAGS
}

export interface FetchConfigurationFlagsSuccess {
  type: FlagsTypes.FETCH_CONFIGURATION_FLAGS_SUCCESS
  payload: {
    m3Price: number
    minBillableM3: number
    customDateTransportMult: number
    extraAssistantPrice: number
    tollCostInCents: number
  }
}

export interface FetchConfigurationFlagsFail {
  type: FlagsTypes.FETCH_CONFIGURATION_FLAGS_FAIL
  payload: { error: string }
}

export type FlagsActions =
  | FetchConfigurationFlags
  | FetchConfigurationFlagsSuccess
  | FetchConfigurationFlagsFail
  | SetServiceType
  | GetProspectRequest
