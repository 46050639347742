import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import {
  FetchDiscountEnum,
  FetchDiscountFailure,
  FetchDiscountRequest,
  FetchDiscountSuccess,
  SetAdditionalCost,
  SetAdditionalCostComment,
  SetApplyDiscount,
  SetDiscountCode,
  SetterDataDiscountEnum
} from '../../types/EditDepositViewTypes/cost'

const CostActionsCreators = {
  setApplyDiscount: ({ applyDiscount }: { applyDiscount: boolean }): SetApplyDiscount => {
    return { type: SetterDataDiscountEnum.SET_APPLY_DISCOUNT, payload: { applyDiscount } }
  },

  setDiscountCode: ({ discountCode }: { discountCode: string }): SetDiscountCode => {
    return { type: SetterDataDiscountEnum.SET_DISCOUNT_CODE, payload: { discountCode } }
  },
  setAdditionalCost: ({ additionalCost }: { additionalCost: string }): SetAdditionalCost => {
    return {
      type: SetterDataDiscountEnum.SET_ADDITIONAL_COST,
      payload: { additionalCost: parseInt(additionalCost, 10) | 0 }
    }
  },
  setAdditionalCostComment: ({
    additionalCostComment
  }: {
    additionalCostComment: string
  }): SetAdditionalCostComment => {
    return { type: SetterDataDiscountEnum.SET_ADDITIONAL_COST_COMMENT, payload: { additionalCostComment } }
  },

  fetchDiscount:
    (code: string): AppThunk =>
    async (dispatch) => {
      const request: FetchDiscountRequest = {
        type: FetchDiscountEnum.FETCH_DISCOUNT_REQUEST
      }
      dispatch(request)

      try {
        const { id, deposit, general, transport, valid } = await API.TransactionHandler.Compute.discount({ code })

        const success: FetchDiscountSuccess = {
          type: FetchDiscountEnum.FETCH_DISCOUNT_SUCCESS,
          payload: {
            id,
            deposit,
            general,
            transport,
            valid
          }
        }

        dispatch(success)
      } catch (error) {
        const failure: FetchDiscountFailure = {
          type: FetchDiscountEnum.FETCH_DISCOUNT_FAILURE
        }

        dispatch(failure)
      }
    }
}

export default CostActionsCreators
