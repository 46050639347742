import { Reducer } from 'redux'
import {
  CreateShipperTypes,
  DeleteShipperTypes,
  GetDriverListTypes,
  GetProviderListTypes,
  SettersShippersModalTypes,
  ShippersABMActions,
  ShippersABMStateTypes,
  UpdateShipperTypes
} from '../types/shippersABM'

const initialState: ShippersABMStateTypes = {
  driverList: [],
  providerList: [],
  currentShipper: null,
  selectedDriver: null,
  selectedProvider: null,
  modalVisible: false,
  deleteModalVisible: false,
  editMode: false,
  fetchingDrivers: false,
  fetchingProviders: false,
  creatingShipper: false,
  updatingShipper: false,
  deletingShipper: false,
  disabledDeleteButton: false
}

const shippersABMReducer: Reducer<ShippersABMStateTypes, ShippersABMActions> = (
  state = initialState,
  action
): ShippersABMStateTypes => {
  switch (action.type) {
    case SettersShippersModalTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible, editMode } = action.payload

      return modalVisible
        ? {
            ...state,
            editMode: editMode ?? false,
            modalVisible
          }
        : initialState
    }
    case SettersShippersModalTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisible } = action.payload
      return deleteModalVisible
        ? {
            ...state,
            deleteModalVisible
          }
        : initialState
    }
    case SettersShippersModalTypes.SET_CURRENT_SHIPPER:
      return {
        ...state,
        currentShipper: action.payload.shipper
      }
    case SettersShippersModalTypes.SET_DRIVER:
      return {
        ...state,
        selectedDriver: action.payload.driver
      }
    case SettersShippersModalTypes.SET_PROVIDER:
      return {
        ...state,
        selectedProvider: action.payload.provider
      }
    case GetDriverListTypes.GET_DRIVER_LIST_REQUEST:
      return {
        ...state,
        fetchingDrivers: true
      }
    case GetDriverListTypes.GET_DRIVER_LIST_SUCCESS:
      return {
        ...state,
        driverList: action.payload.drivers,
        fetchingDrivers: false
      }
    case GetDriverListTypes.GET_DRIVER_LIST_FAILURE:
      return {
        ...state,
        fetchingDrivers: false
      }
    case GetProviderListTypes.GET_PROVIDER_LIST_REQUEST:
      return {
        ...state,
        fetchingProviders: true
      }
    case GetProviderListTypes.GET_PROVIDER_LIST_SUCCESS:
      return {
        ...state,
        providerList: action.payload.providers,
        fetchingProviders: false
      }
    case GetProviderListTypes.GET_PROVIDER_LIST_FAILURE:
      return {
        ...state,
        fetchingProviders: false
      }
    case CreateShipperTypes.CREATE_SHIPPER_REQUEST:
      return {
        ...state,
        creatingShipper: true
      }
    case CreateShipperTypes.CREATE_SHIPPER_SUCCESS:
    case CreateShipperTypes.CREATE_SHIPPER_FAILURE:
      return {
        ...state,
        creatingShipper: false
      }
    case UpdateShipperTypes.UPDATE_SHIPPER_REQUEST:
      return {
        ...state,
        updatingShipper: true
      }
    case UpdateShipperTypes.UPDATE_SHIPPER_SUCCESS:
    case UpdateShipperTypes.UPDATE_SHIPPER_FAILURE:
      return {
        ...state,
        updatingShipper: false
      }
    case DeleteShipperTypes.DELETE_SHIPPER_REQUEST:
      return {
        ...state,
        deletingShipper: true
      }
    case DeleteShipperTypes.DELETE_SHIPPER_SUCCESS:
      return {
        ...state,
        deletingShipper: false
      }
    case DeleteShipperTypes.DELETE_SHIPPER_FAILURE:
      return {
        ...state,
        deletingShipper: false,
        disabledDeleteButton: true
      }
    default:
      return state
  }
}

export default shippersABMReducer
