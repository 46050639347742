import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'

export interface APIConfig {
  agents: APIAgent[]
  contact_mediums: APIContactMediums
  contact_states: APIContactStates
  discard_reasons: APIDiscardReasons
  interest_reason: APIInterestReason
  lose_reasons: APILoseReasons
  lose_reasons_new: APILoseReasonNew[]
  prospect_type: APIProspectType
  providers: string[]
  roles: {
    [key: number]: APIRole
  }
  sources: APISource[]
  state: APIState[]
  states: APIStates
}

interface APIAgent {
  created_at: string
  deleted_at: string
  email: string
  first_name: string
  id: number
  last_name: string
  role: APIRole
  role_id: number
  updated_at: string
  user_guid: string
  user_id: number
}
interface APIContactMediums {
  [key: number]: string
}
interface APIContactStates {
  [key: number]: string
}
interface APIDiscardReasons {
  [key: number]: string
}
interface APIInterestReason {
  [key: number]: string
}
interface APILoseReasons {
  [key: number]: string
}
interface APILoseReasonNew {
  available: boolean
  created_at: string
  deleted_at: string
  id: number
  spanish_display: string
  state: APIState
  status_id: number
  updated_at: string
}
interface APIState {
  available: boolean
  created_at: string
  deleted_at: string
  descriptions: string
  qualified: string
  state_id: number
  update_at: string
}
interface APIProspectType {
  [key: number]: string
}

interface APIRole {
  name: string
  new_prospect_create: boolean
  new_prospect_delete: boolean
  new_prospect_modify: boolean
  new_prospect_view: boolean
  others_prospect_create: boolean
  others_prospect_delete: boolean
  others_prospect_modify: boolean
  others_prospect_view: boolean
  own_prospect_create: boolean
  own_prospect_delete: boolean
  own_prospect_modify: boolean
  own_prospect_view: boolean
}
interface APISource {
  available: boolean
  created_at: string
  filter_visibility: boolean
  id: number
  spanish_display: string
  updated_at: string
}
interface APIStates {
  [key: string]: string
}

export type Source = CamelCasedPropertiesDeep<APISource>
export type Config = CamelCasedPropertiesDeep<APIConfig>

type APIResponse = {
  description: APIConfig
}

export function list(): Promise<Config> {
  return apiPrivate.get(`${URL_BASE_MERCURIO}/bo/config/v1`).then((response: AxiosResponse<APIResponse>) => {
    if (!response.data) throw new Error('Respuesta incorrecta al obtener la información')
    const camelResponse: Config = camelcaseKeys(response.data.description, { deep: true })
    return camelResponse
  })
}
