import React, { useEffect } from 'react'
import Component from '../components/RemovalHistory'
import actions from '../actionCreators/removalHistory'
import { useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

interface ParamTypes {
  removalId: number
}

const RemovalHistoryContainer = () => {
  const dispatch = useDispatch()
  const { audits } = useSelector((state) => state.Removals.history)
  const { getAudits } = bindActionCreators(actions, dispatch)
  const useParsedParams = (): ParamTypes => {
    const { removalId } = useParams<{ removalId: string }>()

    return { removalId: parseInt(removalId) }
  }

  const { removalId } = useParsedParams()

  useEffect(() => {
    getAudits({ removalId })
  }, [removalId])

  const props = {
    audits
  }

  return <Component {...props} />
}

export default RemovalHistoryContainer
