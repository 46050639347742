import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'
import { convertToMoment } from '../../../utils'
import { APIAgent, Agent } from './common'

type ListParams = {
  limit?: number
  offset?: number
}

interface Response {
  total: number
  agents: Agent[]
}

interface APIResponse {
  paging: {
    total: number
  }
  results: [{ agents: APIAgent }]
}

export function list({ limit, offset }: ListParams): Promise<Response> {
  const params = { limit, offset }

  return apiPrivate.get(`${URL_BASE_MERCURIO}/bo/agents/`, { params }).then((response: AxiosResponse<APIResponse>) => {
    if (!response.data) throw new Error('Respuesta incorrecta al obtener los agentes')
    const camelResponse: Response = {
      total: response.data.paging.total,
      agents: camelcaseKeys(response.data.results, { deep: true })
        .filter(({ agents }) => {
          return !!agents.role
        })
        .map(({ agents }) => {
          return {
            id: agents.id,
            createdAt: convertToMoment(agents.createdAt),
            updatedAt: convertToMoment(agents.updatedAt),
            firstName: agents.firstName,
            lastName: agents.lastName,
            email: agents.email,
            userGuid: agents.userGuid,
            userId: agents.userId,
            roleId: agents.roleId,
            role: {
              roleId: agents.role.id,
              createdAt: convertToMoment(agents.role.createdAt),
              updatedAt: convertToMoment(agents.role.updatedAt),
              roleType: agents.role.name
            }
          }
        })
    }
    return camelResponse
  })
}
