import { useMemo } from 'react'
import { ObjectSituationLabels } from '../constants/objectSituations'
import { ObjectSituation } from '../../../projectApi/ObjectAdministration/ObjectSituation/search'
import { BaseOption } from '../../../components/Select/Select'

export type ObjectSituationOption = BaseOption<string>

export const useObjectSituationOptions = (objectSituations: ObjectSituation[]): ObjectSituationOption[] =>
  useMemo(() => {
    return objectSituations.map(({ situation }) => ({
      label: ObjectSituationLabels[situation] || situation,
      value: situation
    }))
  }, [objectSituations])
