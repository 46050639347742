import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_PROPOSALS_FAILURE,
  GET_PROPOSALS_REQUEST,
  GET_PROPOSALS_SUCCESS,
  GetProposalsRequest,
  GetProposalsSuccess,
  ProposalsSearchFilter,
  ProposalsTableSort,
  Pagination,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  SEND_CSV_FAILURE,
  CLEAR_STATE,
  ProposalsDateRangeFilter,
  ProposalsTableCategoryFilter
} from '../types/proposals'
import { searchFiltersToParams, dateRangeFiltersToParams } from '../../../utils/searchFilterUtils'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { downloadFile } from '../../../utils/downloadFile'
import { ListParams, ProposalListSortKey } from '../../../projectApi/TransactionHandler/Proposals/list'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const ProposalsTableActionCreators = {
  sendProposalsCSV:
    ({
      Limit,
      Offset,
      Order,
      Column,
      searchFilters
    }: {
      Limit: number
      Offset: ListParams['Offset']
      Order: SortDirection
      Column: ProposalListSortKey
      searchFilters?: ProposalsSearchFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')

      dispatch({ type: SEND_CSV_REQUEST })

      try {
        await API.TransactionHandler.Proposals.sendProposalsCSV({
          Limit,
          Offset,
          Order,
          Column,
          ...searchFiltersToParams(searchFilters)
        })
        dispatch({ type: SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(error, SEND_CSV_FAILURE, dispatch)
      }
    },
  getProposalPDF:
    (params: { proposalId: number }): AppThunk =>
    async () => {
      const toast = setToastLoading('Descargando PDF, por favor espere...')
      try {
        const blob = await API.TransactionHandler.Proposals.getPDF(params)
        const fileName = `Cotización ${params.proposalId}.pdf`
        downloadFile(blob, fileName)
        setToastSuccessUpdate(toast, { render: 'PDF descargado con éxito' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al descargar PDF' })
      }
    },
  getProposals:
    ({
      pagination,
      Column,
      searchFilters,
      dateRangeFilters,
      categoryFilter
    }: {
      pagination: Pagination
      Column?: ProposalsTableSort
      searchFilters?: ProposalsSearchFilter[]
      dateRangeFilters?: ProposalsDateRangeFilter[]
      categoryFilter?: ProposalsTableCategoryFilter
    }): AppThunk =>
    async (dispatch) => {
      const request: GetProposalsRequest = {
        type: GET_PROPOSALS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          dateRangeFilters,
          categoryFilter,
          Column
        }
      }

      dispatch(request)

      try {
        const { operations, total } = await API.TransactionHandler.Proposals.list({
          Limit: pagination.pageSize,
          Offset: (pagination.page - 1) * pagination.pageSize,
          Column: Column?.field,
          Order: Column?.direction,
          statuses: categoryFilter?.status.join(),
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        const success: GetProposalsSuccess = {
          type: GET_PROPOSALS_SUCCESS,
          payload: {
            proposals: operations,
            total
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_PROPOSALS_FAILURE, dispatch)
      }
    },
  clearState: () => {
    return { type: CLEAR_STATE }
  }
}

export default ProposalsTableActionCreators
