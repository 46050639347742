import { Reducer } from 'redux'
import {
  CLEAR_SELECTED_PRODUCTS,
  CONFIRM_MASSIVE_EDIT_FAILURE,
  CONFIRM_MASSIVE_EDIT_REQUEST,
  CONFIRM_MASSIVE_EDIT_SUCCESS,
  ENABLE_ITEM,
  FIND_USER_FAILURE,
  FIND_USER_REQUEST,
  FIND_USER_SUCCESS,
  MODIFY_ITEM,
  MassiveObjectsEditAction,
  MassiveObjectsEditState,
  SEARCH_PRODUCTS_FAILURE,
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SELECT_PRODUCT,
  SET_OPEN,
  SET_PRODUCT_DIMENSION
} from './types'
import { clone } from 'ramda'

const initialState: MassiveObjectsEditState = {
  objects: [],
  open: false,
  fields: {
    name: {
      enabled: false,
      value: ''
    },
    description: {
      enabled: false,
      value: ''
    },
    objectSituation: {
      enabled: false,
      value: null
    },
    objectSituationReason: {
      enabled: false,
      value: ''
    },
    rentEnabled: {
      enabled: false,
      value: false
    },
    objectStatus: {
      enabled: false,
      value: ''
    },
    product: {
      enabled: false,
      value: null,
      loadingProducts: false,
      products: [],
      lastSearchTimestamp: 0,
      dimensions: {
        heightInCm: 0,
        widthInCm: 0,
        lengthInCm: 0,
        weightInGr: 0
      }
    },
    owner: {
      enabled: false,
      value: '',
      loadingUsers: false,
      user: null,
      alreadySearchedOnce: false
    }
  },
  loadingConfirmation: false,
  error: ''
}

const MassiveObjectsEditReducer: Reducer<MassiveObjectsEditState, MassiveObjectsEditAction> = (
  state = initialState,
  action
): MassiveObjectsEditState => {
  switch (action.type) {
    case SET_OPEN:
      if (!action.payload.open) return initialState
      return { ...state, open: true, objects: action.payload.objects || [] }
    case ENABLE_ITEM:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.key]: { ...state.fields[action.payload.key], enabled: action.payload.enabled }
        }
      }
    case MODIFY_ITEM:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.key]: { ...state.fields[action.payload.key], value: action.payload.value }
        }
      }
    case CLEAR_SELECTED_PRODUCTS:
      return {
        ...state,
        fields: {
          ...state.fields,
          product: {
            ...initialState.fields.product,
            products: state.fields.product.products,
            value: null,
            enabled: true
          }
        }
      }

    case SELECT_PRODUCT:
      return {
        ...state,
        fields: {
          ...state.fields,
          product: {
            ...state.fields.product,
            value: action.payload.product,
            dimensions: {
              heightInCm: action.payload.product.heightInCm,
              widthInCm: action.payload.product.widthInCm,
              lengthInCm: action.payload.product.lengthInCm,
              weightInGr: action.payload.product.weightInGr
            }
          }
        }
      }
    case SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        fields: {
          ...state.fields,
          product: {
            ...state.fields.product,
            loadingProducts: true,
            lastSearchTimestamp: action.payload.lastSearchTimestamp
          }
        }
      }
    case SEARCH_PRODUCTS_SUCCESS:
      if (action.payload.lastSearchTimestamp !== state.fields.product.lastSearchTimestamp) return state

      return {
        ...state,
        fields: {
          ...state.fields,
          product: { ...state.fields.product, loadingProducts: false, products: action.payload.products }
        }
      }
    case SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        fields: {
          ...state.fields,
          product: { ...state.fields.product, loadingProducts: false }
        },
        error: action.payload.error
      }
    case SET_PRODUCT_DIMENSION:
      return {
        ...state,
        fields: {
          ...state.fields,
          product: {
            ...state.fields.product,
            dimensions: { ...state.fields.product.dimensions, [action.payload.dimensionType]: action.payload.value }
          }
        }
      }
    case CONFIRM_MASSIVE_EDIT_REQUEST:
      return { ...state, loadingConfirmation: true }
    case CONFIRM_MASSIVE_EDIT_SUCCESS:
      return initialState
    case CONFIRM_MASSIVE_EDIT_FAILURE:
      return { ...state, loadingConfirmation: false, error: action.payload.error }
    case FIND_USER_REQUEST: {
      const newState = clone(state)
      newState.fields.owner.loadingUsers = true
      newState.fields.owner.user = null
      return newState
    }
    case FIND_USER_SUCCESS: {
      const newState = clone(state)
      newState.fields.owner.loadingUsers = false
      newState.fields.owner.alreadySearchedOnce = true
      newState.fields.owner.user = action.payload.user
      return newState
    }
    case FIND_USER_FAILURE: {
      const newState = clone(state)
      newState.fields.owner.loadingUsers = false
      newState.fields.owner.alreadySearchedOnce = true
      newState.error = action.payload.error

      return newState
    }
    default:
      return state
  }
}

export default MassiveObjectsEditReducer
