import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

export interface AttributeEditParams {
  productId: number
  attributes: AttributeUpdate[]
}

export interface AttributeUpdate {
  attributeId: number
  deleted?: boolean
}

export function updateProductAttribute(propsParams: AttributeEditParams): Promise<boolean> {
  const params = snakecaseKeys({ ...propsParams }, { deep: true })
  return apiPrivate
    .put(`${URL_BASE_OBJECTS}/attribute/product`, params)
    .then(() => true)
    .catch((err) => {
      throw err
    })
}
