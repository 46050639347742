import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

export interface Description {
  value: string
}

export interface APIResponse {
  success: string
}
type Response = boolean

export function update(): Promise<Response> {
  return apiPrivate
    .put(`${URL_BASE_CATEGORIES}/location-info`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo actualizar la información de las ubicaciones')

      return true
    })
    .catch((err) => {
      throw err
    })
}
