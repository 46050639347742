import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'

interface AuthorizationsParams {
  periodFrom: Moment
}

export function authorizations({ periodFrom }: AuthorizationsParams): Promise<boolean> {
  const params = snakecaseKeys({ periodFrom: periodFrom.format('YYYY-MM-DD') })
  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/authorizations`, {}, { params })
    .then((response: AxiosResponse) => {
      if (!response.data) throw new Error('Hubo un error al facturar un abono.')
      return true
    })
    .catch((err) => {
      throw err
    })
}
