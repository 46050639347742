import { PaymentTypes, SetPaymentBy } from '../types/payment'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'

const actions = {
  setPaymentBy: (paymentBy: PAYMENT_BY_VALUES): SetPaymentBy => ({
    type: PaymentTypes.SET_PAYMENT_BY,
    payload: { paymentBy }
  })
}

export default actions
