export type CreditCardType =
  | 'visa'
  | 'deb_visa'
  | 'master'
  | 'mastercard'
  | 'discover'
  | 'amex'
  | 'jcb'
  | 'dinersclub'
  | 'maestro'
  | 'laser'
  | 'unionpay'
  | null

export const getCreditCardTypeName = (type: CreditCardType): string => {
  switch (type) {
    case 'visa':
      return 'Visa'
    case 'deb_visa':
      return 'Visa Débito'
    case 'master':
      return 'Mastercard'
    case 'mastercard':
      return 'Mastercard'
    case 'discover':
      return 'Discover'
    case 'amex':
      return 'American Express'
    case 'jcb':
      return 'JCB'
    case 'dinersclub':
      return 'Diners Club'
    case 'maestro':
      return 'Maestro'
    case 'laser':
      return 'Laser'
    case 'unionpay':
      return 'Union Pay'
    default:
      return type || ''
  }
}
