import { API } from '../../../projectApi'
import { apiPrivate } from '../../../api'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_ALL_CATEGORIES_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_SERVICE_TYPE_SUCCESS,
  GetProductsRequest,
  GetProductsSuccess,
  ProductsCategoryFilter,
  ProductsSearchFilter,
  ProductsTableSort,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  SearchCategoriesRequest,
  SearchCategoriesSuccess,
  SearchCategoriesTypes
} from '../types/productTable'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { getLeafCategories } from '../../../projectApi/CategoryCreation/getCategories'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

const ProductsTableActionCreators = {
  getProducts:
    ({
      countryCode,
      pagination,
      Column,
      searchFilters,
      sort,
      categoryId,
      categoryFilter
    }: {
      countryCode: string
      pagination: Pagination
      Column?: ProductsTableSort
      searchFilters?: ProductsSearchFilter[]
      categoryId?: number
      sort: ProductsTableSort
      categoryFilter: ProductsCategoryFilter
    }): AppThunk =>
    async (dispatch) => {
      const request: GetProductsRequest = {
        type: GET_PRODUCTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          Column,
          sort,
          categoryFilter,
          categoryId
        }
      }

      dispatch(request)

      try {
        const { products, total } = await API.CategoryCreation.Product.listAdmin({
          countryCode,
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          order: sort?.direction,
          column: sort?.field,
          showInSearch: categoryFilter?.showInSearch[0],
          deprecated: categoryFilter?.deprecated[0],
          categoryId: categoryId !== 0 ? categoryId : undefined,
          serviceTypeId: 'all',
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetProductsSuccess = {
          type: GET_PRODUCTS_SUCCESS,
          payload: {
            products,
            total
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_PRODUCTS_FAILURE, dispatch)
      }
    },

  getCategories: (): AppThunk => async (dispatch) => {
    try {
      const { categories } = await API.CategoryCreation.getCategories()

      const leafCategories = getLeafCategories(categories)

      const success = {
        type: GET_ALL_CATEGORIES_SUCCESS,
        payload: {
          categories: leafCategories.map((cat) => ({
            value: cat.id,
            label: cat.description
          }))
        }
      }

      dispatch(success)
    } catch (err: any) {
      if (err.response.data.description) {
        alert(err.response.data.description)
      }
    }
  },

  searchCategoryId:
    (categoryName: string): AppThunk =>
    async (dispatch) => {
      const request: SearchCategoriesRequest = {
        type: SearchCategoriesTypes.SEARCH_CATEGORIES_REQUEST
      }

      dispatch(request)
      try {
        const { categories } = await API.CategoryCreation.Search.Categories.list({ name: categoryName })

        const success: SearchCategoriesSuccess = {
          type: SearchCategoriesTypes.SEARCH_CATEGORIES_SUCCESS,
          payload: { categories }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, SearchCategoriesTypes.SEARCH_CATEGORIES_FAILURE, dispatch)
      }
    },

  getServiceTypes: (): AppThunk => async (dispatch) => {
    return apiPrivate
      .get(URL_BASE_TRANSACTIONS + '/serviceType')
      .then((response) => {
        const tempArray = []

        for (let i = 0; i < response.data.description.service_type.length; i++) {
          const ServiceType = {
            label: response.data.description.service_type[i].type,
            value: response.data.description.service_type[i].id
          }

          tempArray.push(ServiceType)
        }
        dispatch({ type: GET_SERVICE_TYPE_SUCCESS, payload: { serviceTypes: tempArray } })
      })
      .catch((error) => {
        if (error.response.data.description) {
          alert(error.response.data.description)
        }
      })
  },

  sendCSV:
    ({
      countryCode,
      pagination,
      Column,
      searchFilters,
      sort,
      categoryId,
      categoryFilter
    }: {
      countryCode: string
      pagination: Pagination
      Column?: ProductsTableSort
      searchFilters?: ProductsSearchFilter[]
      categoryId?: number
      sort: ProductsTableSort
      categoryFilter: ProductsCategoryFilter
    }): AppThunk =>
    async (dispatch) => {
      const REPORT_LIMIT = 50
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')

      dispatch({ type: SEND_CSV_REQUEST })
      try {
        await API.CategoryCreation.Reports.products({
          countryCode,
          limit: REPORT_LIMIT,
          offset: (pagination.page - 1) * pagination.pageSize,
          order: sort?.direction,
          column: sort?.field,
          showInSearch: categoryFilter?.showInSearch[0],
          deprecated: categoryFilter?.deprecated[0],
          categoryId: categoryId !== 0 ? categoryId : undefined,
          serviceTypeId: 'all',
          ...searchFiltersToParams(searchFilters)
        })

        dispatch({ type: SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(error, SEND_CSV_FAILURE, dispatch)
      }
    }
}

export default ProductsTableActionCreators
