import {
  GetLocationListTypes,
  GetLocationsMetricsTypes,
  LocationListActions,
  LocationListStateType,
  RESET_FILTERS
} from '../types/locationsTable'

const LIMIT = 10

const initialRoot: LocationListStateType = {
  locations: [],
  locationsMetrics: {
    volumeInCm: 0,
    maxVolumeInCm: 0,
    weightInGr: 0,
    maxWeightInGr: 0,
    quantityObjects: 0,
    quantityLocations: 0,
    occupancyRate: 0,
    loadRate: 0,
    totalObjects: 0,
    totalM3: 0
  },
  fetchingLocations: false,
  fetchingLocationsMetrics: false,
  pagination: {
    page: 1,
    pageSize: LIMIT,
    total: 0
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'deposit_id', text: '' },
    { key: 'owner_id', text: '' }
  ],
  filters: {
    id: undefined
  }
}

function locationsTableReducer(
  state: LocationListStateType = initialRoot,
  action: LocationListActions
): LocationListStateType {
  switch (action.type) {
    case GetLocationListTypes.GET_LOCATIONS_REQUEST:
      return {
        ...state,
        fetchingLocations: true,
        pagination: action.payload.pagination,
        searchFilters: action.payload.searchFilters || state.searchFilters
      }
    case GetLocationListTypes.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        fetchingLocations: false,
        locations: action.payload.locations,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetLocationListTypes.GET_LOCATIONS_FAILURE:
      return {
        ...state,
        fetchingLocations: false,
        locations: []
      }
    case GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_REQUEST:
      return {
        ...state,
        fetchingLocationsMetrics: true
      }
    case GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_SUCCESS:
      return {
        ...state,
        locationsMetrics: action.payload.locationsMetrics,
        fetchingLocationsMetrics: false
      }
    case GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_FAILURE:
      return {
        ...state,
        fetchingLocationsMetrics: false
      }
    case RESET_FILTERS:
      return {
        ...state,
        filters: initialRoot.filters,
        searchFilters: initialRoot.searchFilters,
        pagination: initialRoot.pagination
      }
    default:
      return state
  }
}

export default locationsTableReducer
