import React from 'react'
import { DatePicker, Input, Modal } from 'antd'
import styles from './AMDiscountDate.module.scss'
import moment, { Moment } from 'moment'
import { ModalCreateDiscountDateState } from '../../../types/ABMDiscountDates'
import { InfoCard } from '../InfoDiscountCard'

type CreateDiscountModalProps = {
  disabledCreateButton: boolean
  loadingCreateButton: boolean
  setModalVisibility: ({ modalVisible }: { modalVisible: boolean }) => void
  onChangeDiscountRate: (discountRate: string) => void
  handleConfirmButton: () => void
  setDiscountDate: (selectedAccount: Moment | null) => void
} & Omit<
  ModalCreateDiscountDateState,
  'creatingDiscount' | 'updatingDiscount' | 'deleteModalVisible' | 'deletingDiscount'
>

const CreateDiscountModal = ({
  editMode,
  modalVisible,
  discountRate,
  selectedDate,
  selectedDiscount,
  disabledCreateButton,
  loadingCreateButton,
  setModalVisibility,
  handleConfirmButton,
  setDiscountDate,
  onChangeDiscountRate
}: CreateDiscountModalProps) => {
  const disabledDate = (current: Moment | null) =>
    // Date not before today or anticipated date
    current && current < moment().add(0, 'hour').startOf('day')

  const modalTitle = editMode ? 'Editar descuento para un día específico' : 'Crear descuento para un día específico'
  const confirmButtonText = editMode ? 'Editar' : 'Crear'

  return (
    <Modal
      width={'max-content'}
      onCancel={() => setModalVisibility({ modalVisible: false })}
      title={modalTitle}
      confirmLoading={loadingCreateButton}
      visible={modalVisible}
      okText={confirmButtonText}
      cancelText="Cancelar"
      onOk={handleConfirmButton}
      okButtonProps={{ disabled: disabledCreateButton }}
      destroyOnClose>
      {editMode ? (
        <InfoCard
          id={selectedDiscount?.id}
          day={selectedDiscount?.date?.format('DD-MM-YYYY')}
          discountRate={selectedDiscount?.discount}
        />
      ) : null}
      <div className={styles.container}>
        <label className={styles.label}>
          Fecha de descuento:
          <DatePicker
            placeholder="Día de descuento"
            popupStyle={{ zIndex: 9999 }}
            // @ts-ignore
            disabledDate={disabledDate}
            allowClear={false}
            format="DD/MM/YYYY"
            value={selectedDate}
            onChange={(date) => setDiscountDate(date)}
          />
        </label>
        <label className={styles.label}>
          Porcentaje de descuento:
          <Input
            className={styles.rateInput}
            type="number"
            value={discountRate.value}
            max={100}
            onChange={(rate) => onChangeDiscountRate(rate.target.value)}
          />
        </label>
      </div>
      {discountRate.error ? (
        <p style={{ textAlign: 'center', color: '#F5522E', margin: '12px 0 0' }}>{discountRate.error}</p>
      ) : null}
    </Modal>
  )
}

export default CreateDiscountModal
