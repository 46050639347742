import {
  CLEAR_STATE,
  ClearState,
  GET_METRICS_OBJECTS_FAILURE,
  GET_METRICS_OBJECTS_REQUEST,
  GET_METRICS_OBJECTS_SUCCESS,
  GET_OBJECTS_FAILURE,
  GET_OBJECTS_REQUEST,
  GET_OBJECTS_STATUS_FAILURE,
  GET_OBJECTS_STATUS_REQUEST,
  GET_OBJECTS_STATUS_SUCCESS,
  GET_OBJECTS_SUCCESS,
  GetMetricsObjectsRequest,
  GetMetricsObjectsSuccess,
  GetObjectsRequest,
  GetObjectsStatusSuccess,
  GetObjectsSuccess,
  ObjectsSearchFilter,
  ObjectsTableCategoryFilter,
  ObjectsTableDateRangeFilter,
  ObjectsTableSort,
  TOGGLE_ALL_OBJECTS,
  TOGGLE_EXACT_LOCATION_CODE,
  TOGGLE_SELECTED_OBJECT,
  ToggleAllObjects,
  ToggleExactLocationCode,
  ToggleEmptyLocations,
  ToggleSelectedObject,
  TOGGLE_EMPTY_LOCATIONS
} from '../types/objectsTable'
import { API } from '../../../projectApi'
import { FormattedObject } from '../../../projectApi/ObjectAdministration/common'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { KeyType, Pagination, dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { ObjectStatus } from '../../../projectApi/ObjectAdministration/ObjectStatus/search'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const ObjectsTableActionCreators = {
  toggleExactLocationCode: (): ToggleExactLocationCode => ({
    type: TOGGLE_EXACT_LOCATION_CODE
  }),
  toggleEmptyLocations: (): ToggleEmptyLocations => {
    Emitter.emit(Events.Objects.OBJECT_TOGGLE_LOCATION)
    return {
      type: TOGGLE_EMPTY_LOCATIONS
    }
  },
  toggleSelectedObject: (object: FormattedObject): ToggleSelectedObject => ({
    type: TOGGLE_SELECTED_OBJECT,
    payload: { object }
  }),
  toggleAllObjects: (): ToggleAllObjects => ({
    type: TOGGLE_ALL_OBJECTS
  }),
  getObjects:
    ({
      pagination,
      searchFilters,
      sort,
      categoryFilter,
      silentLoading,
      dateRangeFilter
    }: {
      pagination: Pagination
      searchFilters?: ObjectsSearchFilter[]
      categoryFilter?: ObjectsTableCategoryFilter
      sort?: ObjectsTableSort
      silentLoading?: boolean
      dateRangeFilter: ObjectsTableDateRangeFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetObjectsRequest = {
        type: GET_OBJECTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          categoryFilter,
          sort,
          silentLoading,
          dateRangeFilter
        }
      }

      dispatch(request)

      const formattedDateFilters = dateRangeFiltersToParams(dateRangeFilter, KeyType.CAMEL)
      try {
        const { objects, total } = await API.ObjectAdministration.Object.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          order: sort?.direction,
          column: sort?.field,
          status: categoryFilter?.status[0],
          situation: categoryFilter?.objectSituation.join(),
          withDeletedPhotos: true,
          withDeleted: true,
          ...formattedDateFilters,
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetObjectsSuccess = {
          type: GET_OBJECTS_SUCCESS,
          payload: {
            objects,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_OBJECTS_FAILURE, dispatch)
      }
    },

  getObjectsStatusSuccess: (objectStatus: ObjectStatus[]): GetObjectsStatusSuccess => ({
    type: GET_OBJECTS_STATUS_SUCCESS,
    payload: { objectStatus }
  }),

  getStatusObjects(): AppThunk {
    return async (dispatch) => {
      dispatch({ type: GET_OBJECTS_STATUS_REQUEST })

      try {
        const response = await API.ObjectAdministration.ObjectStatus.search()
        dispatch(ObjectsTableActionCreators.getObjectsStatusSuccess(response))
      } catch (error) {
        handleErrorsWithAction(error, GET_OBJECTS_STATUS_FAILURE, dispatch)
      }
    }
  },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  getMetricsObjects:
    ({
      searchFilters,
      categoryFilter,
      dateRangeFilter
    }: {
      searchFilters?: ObjectsSearchFilter[]
      categoryFilter?: ObjectsTableCategoryFilter
      dateRangeFilter?: ObjectsTableDateRangeFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetMetricsObjectsRequest = {
        type: GET_METRICS_OBJECTS_REQUEST,
        payload: {
          searchFilters,
          categoryFilter,
          dateRangeFilter
        }
      }
      dispatch(request)
      try {
        const { totalObject, totalM3, situations } = await API.ObjectAdministration.Object.metrics({
          status: categoryFilter?.status[0],
          situation: categoryFilter?.objectSituation.join(),
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilter)
        })

        const success: GetMetricsObjectsSuccess = {
          type: GET_METRICS_OBJECTS_SUCCESS,
          payload: { totalObjects: totalObject, totalM3, situations }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_METRICS_OBJECTS_FAILURE, dispatch)
      }
    }
}

export default ObjectsTableActionCreators
