/* eslint-disable camelcase */
import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import camelcaseKeys from 'camelcase-keys'
import { AxiosResponse } from 'axios'
import { convertToMoment } from '../../utils'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

type PriceBandType = 'm3' | 'kg'

type GetPriceBandsParams = {
  country: CountryIdCode
  type?: PriceBandType
}

type APIPriceBand = {
  id: number
  country: CountryIdCode
  date_from: string
  type: PriceBandType
  price: number
}

export type PriceBand = {
  id: number
  country: CountryIdCode
  dateFrom: Moment | null
  type: PriceBandType
  price: number
}

export function getCurrentPriceBand(params: GetPriceBandsParams): Promise<PriceBand> {
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/price-bands/current`, { params })
    .then((response: AxiosResponse<APIPriceBand>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener la banda de precios')

      const camelResponse = camelcaseKeys(response.data)

      return {
        ...camelResponse,
        dateFrom: convertToMoment(camelResponse.dateFrom)
      }
    })
}
