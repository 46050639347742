import { Reducer } from 'redux'
import {
  UserExclusionTypes,
  Periods,
  UserExclusionActions,
  UserExclusionState,
  EnableUserBillingRequestTypes
} from '../types/userExclusionModals'
import { CURRENT_PERIOD } from './newBillingTable'
import moment from 'moment'

const initialState: UserExclusionState = {
  openModal: false,
  openEnableModal: false,
  selectedOption: Periods.THIS_PERIOD,
  selectedReason: null,
  specificPeriodFrom: moment().add(1, 'day'),
  specificPeriodTo: moment().add(1, 'day').endOf('month'),
  periodFrom: CURRENT_PERIOD,
  periodTo: CURRENT_PERIOD.clone().add(1, 'month'),
  userExclusionReasons: [],
  error: '',
  loadingUserExclusionReasons: false,
  loading: false,
  enablingUser: false
}

const UserExclusionReducer: Reducer<UserExclusionState, UserExclusionActions> = (
  state = initialState,
  action
): UserExclusionState => {
  switch (action.type) {
    case UserExclusionTypes.GET_USER_EXCLUSION_REASONS_REQUEST:
      return { ...state, loadingUserExclusionReasons: true }
    case UserExclusionTypes.GET_USER_EXCLUSION_REASONS_SUCCESS:
      return { ...state, userExclusionReasons: action.payload.reasons, loadingUserExclusionReasons: false }
    case UserExclusionTypes.GET_USER_EXCLUSION_REASONS_FAILURE:
      return { ...state, loadingUserExclusionReasons: false, error: action.payload.error }
    case UserExclusionTypes.USER_EXCLUSION_REQUEST:
      return { ...state, loading: true }
    case UserExclusionTypes.USER_EXCLUSION_SUCCESS:
      return { ...initialState, userExclusionReasons: state.userExclusionReasons }
    case UserExclusionTypes.USER_EXCLUSION_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case UserExclusionTypes.SET_MODAL_OPEN:
      return action.payload.open
        ? { ...state, openModal: true, user: action.payload.user }
        : { ...initialState, userExclusionReasons: state.userExclusionReasons }
    case UserExclusionTypes.SET_ENABLE_MODAL_OPEN:
      return action.payload.openEnableModal
        ? { ...state, openEnableModal: true, user: action.payload.user }
        : { ...initialState, userExclusionReasons: state.userExclusionReasons }
    case UserExclusionTypes.SET_SELECTED_OPTION:
      return { ...state, selectedOption: action.payload.option }
    case UserExclusionTypes.SET_SPECIFIC_PERIOD_FROM:
      return { ...state, specificPeriodFrom: action.payload.specificPeriodFrom }
    case UserExclusionTypes.SET_SPECIFIC_PERIOD_TO:
      return { ...state, specificPeriodTo: action.payload.specificPeriodTo }
    case UserExclusionTypes.SET_PERIOD_TO:
      return { ...state, periodTo: action.payload.periodTo }
    case UserExclusionTypes.SET_SELECTED_REASON:
      return { ...state, selectedReason: action.payload.reason }

    case EnableUserBillingRequestTypes.ENABLE_USER_BILLING_REQUEST: {
      return {
        ...state,
        enablingUser: true
      }
    }
    case EnableUserBillingRequestTypes.ENABLE_USER_BILLING_SUCCESS: {
      return { ...initialState, userExclusionReasons: state.userExclusionReasons }
    }
    case EnableUserBillingRequestTypes.ENABLE_USER_BILLING_FAILURE: {
      return {
        ...state,
        enablingUser: false
      }
    }
    default:
      return { ...state }
  }
}
export default UserExclusionReducer
