import VisaIcon from '../../icons/visa'
import AmericanIcon from '../../icons/american'
import DiscoversIcon from '../../icons/discovers'
import DinersIcon from '../../icons/diners'
import MasterIcon from '../../icons/master'

export enum Steps {
  PAYMENT = 0,
  FINISH_SUMMARY = 1,
  SELECT_CARD = 2,
  ADD_CARD = 3
}

export enum PaymentScreens {
  PAYMENT_SELECTION = 'paymentSelection',
  NEW_CARD = 'newCard'
}

export const creditCardRegx =
  /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/

export const ccvRegx = /^[0-9]{3,4}$/
export const expirateDateRegx = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/

export enum BillingPaymentStatusId {
  PAGADA = 'Pagada',
  APROBADA = 'Aprobada',
  ANULADA = 'Anulada',
  BORRADOR = 'Borrador',
  PARCIALMENTE_PAGA = 'Parcialmente pagada'
}

export const BillingPaymentStatusBadges = [
  {
    value: BillingPaymentStatusId.APROBADA,
    text: 'Sin pagar',
    styleProps: {
      borderColor: '#922007',
      backgroundColor: '#FDDFD8'
    }
  },
  {
    value: BillingPaymentStatusId.ANULADA,
    text: BillingPaymentStatusId.ANULADA,
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: BillingPaymentStatusId.PAGADA,
    text: BillingPaymentStatusId.PAGADA,
    styleProps: {
      borderColor: '#0D5438',
      backgroundColor: '#CEF7E7'
    }
  },
  {
    value: BillingPaymentStatusId.BORRADOR,
    text: BillingPaymentStatusId.BORRADOR,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: BillingPaymentStatusId.PARCIALMENTE_PAGA,
    text: BillingPaymentStatusId.PARCIALMENTE_PAGA,
    styleProps: {
      borderColor: '#0859A1',
      backgroundColor: '#DDEEFD'
    }
  }
]

export const BILLING_PAYMENT_STATUS_OPTIONS = [
  {
    value: 'paid',
    text: 'Pagada'
  },
  {
    value: 'unPaid',
    text: 'Sin pagar'
  }
]

export const CREDIT_CARD_ICONS = [
  {
    value: 'visa',
    component: VisaIcon
  },
  {
    value: 'visa_debit',
    component: VisaIcon
  },
  {
    value: 'american_express',
    component: AmericanIcon
  },
  {
    value: 'discovers',
    component: DiscoversIcon
  },
  {
    value: 'diners',
    component: DinersIcon
  },
  {
    value: 'master',
    component: MasterIcon
  },
  {
    value: 'master_debit',
    component: MasterIcon
  }
]
