export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type ApiBillingEntity = {
  __typename?: 'APIBillingEntity';
  billingColppyId: Scalars['String']['output'];
  billingDate: Scalars['String']['output'];
  billingId: Scalars['Float']['output'];
  entityType: Scalars['String']['output'];
  number: Scalars['String']['output'];
  status: Scalars['String']['output'];
  total: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Removal operation into Space Guru */
export type ApiRemoval = {
  __typename?: 'APIRemoval';
  /** Removal address */
  address: RemovalAddressEntity;
  agentName: Scalars['String']['output'];
  /** Cancel reason of Removal */
  cancelReason?: Maybe<RemovalCancelReasonEntity>;
  cardLastDigits: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  coreId: Scalars['Float']['output'];
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  datetime: Scalars['String']['output'];
  debtAmount: Scalars['Float']['output'];
  discountCode: Scalars['String']['output'];
  discountId: Scalars['Float']['output'];
  fullAddressString: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  /** Modify reason of Removal */
  modifyReason?: Maybe<RemovalModifyReasonEntity>;
  noTransportChargeReason: RemovalNoTransportChargeReasonEntity;
  objectCount: Scalars['Float']['output'];
  objectFoundCount: Scalars['Float']['output'];
  objectNotFoundCount: Scalars['Float']['output'];
  objectReturnedCount: Scalars['Float']['output'];
  objectReturnedM3: Scalars['Float']['output'];
  objectToReturnCount: Scalars['Float']['output'];
  objectToReturnM3: Scalars['Float']['output'];
  objectTotalM3: Scalars['Float']['output'];
  objects?: Maybe<Array<RemovalObjectEntity>>;
  operationNumber: Scalars['Float']['output'];
  origin: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  removalAuth: RemovalAuthEntity;
  status: Scalars['String']['output'];
  tollCostInCents: Scalars['Float']['output'];
  transaction: RemovalTransactionEntity;
  transportCost: Scalars['Float']['output'];
  transportType: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  user: RemovalUserEntity;
};

export type ApiRemovalListResponse = {
  __typename?: 'APIRemovalListResponse';
  operations: Array<ApiRemoval>;
  quantity: Scalars['Float']['output'];
};

export type ApiResponse = {
  __typename?: 'APIResponse';
  total: Scalars['Float']['output'];
  users: Array<ApiUserTotalDebt>;
};

export type ApiSearchPublicProduct = {
  __typename?: 'APISearchPublicProduct';
  categories: Array<ApiSearchPublicProductCategory>;
  deprecated: Scalars['Boolean']['output'];
  guid: Scalars['String']['output'];
  heightInCm: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  labels: Array<SearchPublicProductLabels>;
  lengthInCm: Scalars['Float']['output'];
  maxVolumeDeviation: Scalars['Float']['output'];
  minVolumeDeviation: Scalars['Float']['output'];
  multiplier: Scalars['Float']['output'];
  packagingTimeInMinutes: Scalars['Float']['output'];
  photoMin: Scalars['Float']['output'];
  productsByCountry: Array<ApiSearchPublicProductByCountry>;
  serviceType: ApiSearchPublicProductServiceType;
  showInSearch: Scalars['Boolean']['output'];
  unpackagingTimeInMinutes: Scalars['Float']['output'];
  volumeInCm3: Scalars['Float']['output'];
  weightInGr: Scalars['Float']['output'];
  widthInCm: Scalars['Float']['output'];
};

export type ApiSearchPublicProductByCountry = {
  __typename?: 'APISearchPublicProductByCountry';
  assemblyCost: Scalars['Float']['output'];
  automaticCostPerFloor: Scalars['Float']['output'];
  automaticDepositCostPerFloor: Scalars['Float']['output'];
  costPerFloor: Scalars['Float']['output'];
  countryId: Scalars['String']['output'];
  depositCostPerFloorInCents: Scalars['Float']['output'];
  disassemblyCost: Scalars['Float']['output'];
  eligibleForDonation: Scalars['Boolean']['output'];
  eligibleForRecycled: Scalars['Boolean']['output'];
  eligibleForRent: Scalars['Boolean']['output'];
  eligibleForSale: Scalars['Boolean']['output'];
  guid: Scalars['String']['output'];
  maxAmountAvowedInCents: Scalars['Float']['output'];
  maxRefundAmountInCents: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  packagingCost: Scalars['Float']['output'];
  percentageAvowed: Scalars['Float']['output'];
  photo?: Maybe<ApiSearchPublicProductPhoto>;
  price: Scalars['Float']['output'];
  productId: Scalars['Float']['output'];
  refId: Scalars['Float']['output'];
  unpackagingCost: Scalars['Float']['output'];
};

export type ApiSearchPublicProductCategory = {
  __typename?: 'APISearchPublicProductCategory';
  description: Scalars['String']['output'];
  id: Scalars['Float']['output'];
};

export type ApiSearchPublicProductPhoto = {
  __typename?: 'APISearchPublicProductPhoto';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ApiSearchPublicProductServiceType = {
  __typename?: 'APISearchPublicProductServiceType';
  displayOrder: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  multiplier: Scalars['Float']['output'];
  operationType: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ApiSearchPublicProductsResponse = {
  __typename?: 'APISearchPublicProductsResponse';
  products: Array<ApiSearchPublicProduct>;
  quantity: Scalars['Float']['output'];
};

export type ApiUserCardsListResponse = {
  __typename?: 'APIUserCardsListResponse';
  paging: Paging;
  results: Array<UserCardEntity>;
};

export type ApiUserTotalDebt = {
  __typename?: 'APIUserTotalDebt';
  cuit: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fantasyName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  saldo: Scalars['Float']['output'];
  saldoPagar: Scalars['Boolean']['output'];
};

export type AddUserAddressInput = {
  address: Scalars['String']['input'];
  apartment?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  floor?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  number: Scalars['String']['input'];
  /** Used for internal tracking. NOT RELATED TO USER. */
  origin: Scalars['String']['input'];
  original: Scalars['String']['input'];
  postalCodeId: Scalars['String']['input'];
  province: Scalars['String']['input'];
  userId: Scalars['Float']['input'];
};

export type AddUserPaymentMethodInput = {
  cardholderIdentificationNumber?: InputMaybe<Scalars['Float']['input']>;
  cardholderIdentificationType?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type AdditionalCostItems = {
  __typename?: 'AdditionalCostItems';
  costPerFloor?: Maybe<Scalars['Int']['output']>;
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  packagingCost?: Maybe<Scalars['Int']['output']>;
};

export type AdditionalCostObjects = {
  __typename?: 'AdditionalCostObjects';
  costPerFloor?: Maybe<Scalars['Int']['output']>;
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  packagingCost?: Maybe<Scalars['Int']['output']>;
};

export type AdditionalCosts = {
  __typename?: 'AdditionalCosts';
  costPerFloor?: Maybe<Scalars['Int']['output']>;
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  packagingCost?: Maybe<Scalars['Int']['output']>;
};

export type AdditionalInfoEntity = {
  __typename?: 'AdditionalInfoEntity';
  cardholderIdentificationNumber: Scalars['String']['output'];
  cardholderIdentificationType: Scalars['String']['output'];
  payer: PayerEntity;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']['output']>;
  apartment?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  postalCodeId?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
};

export type AddressCreateDepositProposal = {
  addressString?: InputMaybe<Scalars['String']['input']>;
  apartment?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['Float']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type AddressDetailInput = {
  addressApartment?: InputMaybe<Scalars['String']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressComment?: InputMaybe<Scalars['String']['input']>;
  addressFloor?: InputMaybe<Scalars['String']['input']>;
  addressNumber?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressProvince?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  completeAddress?: InputMaybe<Scalars['String']['input']>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  address: Scalars['String']['output'];
  apartment?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inDeliveryRange: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  main: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  original: Scalars['String']['output'];
  postalCode: PostalCode;
  province: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type AddressResponseDepositProposal = {
  __typename?: 'AddressResponseDepositProposal';
  addressString?: Maybe<Scalars['String']['output']>;
  apartment?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['Int']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressSearchOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** The descriptions of the allowed deposit statuses based on simplified status */
export enum AllowedDepositSimplifiedStatus {
  Cancelado = 'CANCELADO',
  EnCurso = 'EN_CURSO',
  Finalizado = 'FINALIZADO'
}

/** The descriptions of the allowed object statuses based on simplified status */
export enum AllowedObjectSimplifiedStatus {
  Devuelto = 'DEVUELTO',
  EnProcesoDeGuardado = 'EN_PROCESO_DE_GUARDADO',
  Guardado = 'GUARDADO',
  Otro = 'OTRO'
}

/** The descriptions of the allowed object statuses based on description */
export enum AllowedObjectSituations {
  Cataloging = 'CATALOGING',
  Donated = 'DONATED',
  DonationRequest = 'DONATION_REQUEST',
  Empty = 'EMPTY',
  ForcedDonation = 'FORCED_DONATION',
  ForcedRecycled = 'FORCED_RECYCLED',
  ForcedSale = 'FORCED_SALE',
  FoundAfterLost = 'FOUND_AFTER_LOST',
  InOwner = 'IN_OWNER',
  Locating = 'LOCATING',
  Lost = 'LOST',
  Recycled = 'RECYCLED',
  RecyclingRequest = 'RECYCLING_REQUEST',
  RemovalRequest = 'REMOVAL_REQUEST',
  Repaired = 'REPAIRED',
  Sale = 'SALE',
  SalesProduction = 'SALES_PRODUCTION',
  SalesRequest = 'SALES_REQUEST',
  SalesRequestCp = 'SALES_REQUEST_CP',
  SalesRequestR = 'SALES_REQUEST_R',
  SalePreparation = 'SALE_PREPARATION',
  Saved = 'SAVED',
  SavedNotValidated = 'SAVED_NOT_VALIDATED',
  SoldOut = 'SOLD_OUT',
  TakingPhotos = 'TAKING_PHOTOS',
  UnderRepair = 'UNDER_REPAIR'
}

/** The countries allowed to create sale requests */
export enum AllowedSaleRequestCountries {
  Arg = 'ARG',
  Esp = 'ESP',
  Fra = 'FRA'
}

/** The descriptions of the allowed sale request statuses based on simplified status */
export enum AllowedSaleRequestSimplifiedStatus {
  CanceladaCliente = 'CANCELADA_CLIENTE',
  EnProcesoDeEvaluacion = 'EN_PROCESO_DE_EVALUACION',
  EnVenta = 'EN_VENTA',
  ObjetoVendidoASg = 'OBJETO_VENDIDO_A_SG',
  RechazadaPorElEquipoDeVentasDeSg = 'RECHAZADA_POR_EL_EQUIPO_DE_VENTAS_DE_SG',
  RechazadaPorUsuario = 'RECHAZADA_POR_USUARIO',
  SolicitudVencida = 'SOLICITUD_VENCIDA',
  ValidarNuevoPrecio = 'VALIDAR_NUEVO_PRECIO',
  Vendido = 'VENDIDO'
}

export type BillingEntity = {
  __typename?: 'BillingEntity';
  billingColppyId: Scalars['String']['output'];
  billingDate: Scalars['String']['output'];
  billingId: Scalars['Float']['output'];
  billingNumber: Scalars['String']['output'];
  sourceBilling: Scalars['String']['output'];
  status: Scalars['String']['output'];
  totalDebt: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BillingListFilters = {
  status?: InputMaybe<Array<BillingStatuses>>;
};

export type BillingListSearchOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  limitInternal?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortOptions>;
};

/** The descriptions of the allowed billing status ids based on simplified status */
export enum BillingStatuses {
  Pagada = 'PAGADA',
  SinPagar = 'SIN_PAGAR'
}

export type CalculateMinCostInput = {
  objectsId: Array<Scalars['Int']['input']>;
  removalDate: Scalars['String']['input'];
};

export type CalculateTripCostsInput = {
  countryId: CountryId;
  destination: Scalars['String']['input'];
  items: Array<CalculateTripCostsItem>;
  m3: Scalars['Float']['input'];
};

export type CalculateTripCostsItem = {
  amount: Scalars['Int']['input'];
  productGuid: Scalars['String']['input'];
  type: CalculateTripCostsItemType;
};

/** The item types available for the user */
export enum CalculateTripCostsItemType {
  Product = 'PRODUCT'
}

export type CalculateTripCostsResponse = {
  __typename?: 'CalculateTripCostsResponse';
  administrativeLevel_1?: Maybe<Scalars['String']['output']>;
  administrativeLevel_2?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  rule: Rule;
  tollCostInCents: Scalars['Float']['output'];
};

export type CalendarDateEntity = {
  __typename?: 'CalendarDateEntity';
  date: Scalars['String']['output'];
  day: Scalars['String']['output'];
  /** Deposit is available or not */
  depositDisabled: Scalars['Boolean']['output'];
  discount: Scalars['Int']['output'];
  /** Removal is available or not */
  removalDisabled: Scalars['Boolean']['output'];
};

/** Card Data */
export type CardEntity = {
  __typename?: 'CardEntity';
  cardLastDigits: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
};

export type CardInfoEntity = {
  __typename?: 'CardInfoEntity';
  expirationDate: Scalars['String']['output'];
  holderName: Scalars['String']['output'];
  lastFourDigits: Scalars['String']['output'];
  network: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CollectDownPaymentInput = {
  amountInCents: Scalars['Float']['input'];
  operationId: Scalars['Int']['input'];
  paymentToken: Scalars['String']['input'];
  spreedlyPmId: Scalars['Int']['input'];
};

export type CollpyUserTotalDebt = {
  __typename?: 'CollpyUserTotalDebt';
  active: Scalars['Boolean']['output'];
  cuit: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fantasyName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  saldo: Scalars['Float']['output'];
  saldoPagar: Scalars['Boolean']['output'];
};

export type Concepts = {
  billingColppyId: Scalars['Float']['input'];
  entityId: Scalars['Float']['input'];
  entityType: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

/** The countries available for the user */
export enum CountryId {
  Arg = 'ARG',
  Esp = 'ESP',
  Fra = 'FRA'
}

export type CreateDebtsPaymentInput = {
  amountInCents: Scalars['Int']['input'];
  concepts: Array<Concepts>;
  country: Scalars['String']['input'];
  paymentMethodToken: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type CreateDepositDraftInput = {
  addressDetail?: InputMaybe<AddressDetailInput>;
  applyDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  depositId?: InputMaybe<Scalars['Int']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  extrasDetail?: InputMaybe<ExtrasDetailInput>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<ItemInput>>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['Int']['input']>;
  prospectId?: InputMaybe<Scalars['Int']['input']>;
  serviceTypeId?: InputMaybe<Scalars['String']['input']>;
  storageCost?: InputMaybe<Scalars['Int']['input']>;
  timeIntervalId?: InputMaybe<Scalars['Int']['input']>;
  timeSlotId?: InputMaybe<Scalars['Int']['input']>;
  totalAdditionalCostInCents?: InputMaybe<Scalars['Int']['input']>;
  transportCostInCents?: InputMaybe<Scalars['Int']['input']>;
  transportDetail?: InputMaybe<TransportDetailInput>;
  transportModeTypeId?: InputMaybe<Scalars['Int']['input']>;
  transportTypePricing?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDraftDepositProposalInput = {
  additionalCostComment?: InputMaybe<Scalars['String']['input']>;
  additionalCostInCents?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<AddressCreateDepositProposal>;
  costPerFloorInCents?: InputMaybe<Scalars['Float']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  cubicCm?: InputMaybe<Scalars['Float']['input']>;
  cubicMPriceInCents?: InputMaybe<Scalars['Float']['input']>;
  depositId?: InputMaybe<Scalars['Float']['input']>;
  destination?: InputMaybe<Scalars['String']['input']>;
  disassemblyCost?: InputMaybe<Scalars['Float']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  discountId?: InputMaybe<Scalars['Float']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  items?: InputMaybe<Array<ItemCreateDepositProposal>>;
  notify?: InputMaybe<NotifyCreateDepositProposal>;
  origin?: InputMaybe<Scalars['String']['input']>;
  packagingCostInCents?: InputMaybe<Scalars['Float']['input']>;
  peopleId?: InputMaybe<Scalars['Float']['input']>;
  proposalStatusId?: InputMaybe<Scalars['Float']['input']>;
  prospectAddressId?: InputMaybe<Scalars['Float']['input']>;
  prospectId?: InputMaybe<Scalars['Float']['input']>;
  serviceTypeId?: InputMaybe<Scalars['String']['input']>;
  shipmentAssistantCount?: InputMaybe<Scalars['Float']['input']>;
  shipmentAssistantCountInCents?: InputMaybe<Scalars['Float']['input']>;
  shipmentAssistantPrice?: InputMaybe<Scalars['Float']['input']>;
  shipmentAssistantPriceInCents?: InputMaybe<Scalars['Float']['input']>;
  storageCostInCents?: InputMaybe<Scalars['Float']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
  totalAmountInCents?: InputMaybe<Scalars['Float']['input']>;
  totalWeightInGr?: InputMaybe<Scalars['Float']['input']>;
  transportCostInCents?: InputMaybe<Scalars['Float']['input']>;
  transportModeId?: InputMaybe<Scalars['Float']['input']>;
  transportType?: InputMaybe<Scalars['String']['input']>;
  userCreatedId?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateDraftRemovalInput = {
  addressId: Scalars['Int']['input'];
  minCost: Scalars['Int']['input'];
  objects: Array<CreateRemovalOperationObject>;
  timeIntervalId: Scalars['Int']['input'];
  timeSlotId: Scalars['Int']['input'];
  tollCostInCents: Scalars['Int']['input'];
  totalDiscountPercentage?: InputMaybe<Scalars['Int']['input']>;
  transportCost: Scalars['Int']['input'];
  transportModeTypeId: Scalars['Int']['input'];
  unixTimestamp: Scalars['String']['input'];
};

export type CreateDraftRemovalResponse = {
  __typename?: 'CreateDraftRemovalResponse';
  operationId: Scalars['Float']['output'];
};

/** The input to create multiple sales request in one request from the App */
export type CreateMultipleSaleRequestInput = {
  /** The sale request inputs */
  inputs: Array<CreateSaleRequestInput>;
};

export type CreateRemovalOperationInput = {
  addressId: Scalars['Int']['input'];
  discountCode?: InputMaybe<Scalars['String']['input']>;
  objects: Array<CreateRemovalOperationObject>;
  paymentMethodId: Scalars['Int']['input'];
  paymentProvider: Scalars['String']['input'];
  timeIntervalId: Scalars['Int']['input'];
  timeSlotId: Scalars['Int']['input'];
  tollCostInCents: Scalars['Int']['input'];
  totalDiscountPercentage?: InputMaybe<Scalars['Float']['input']>;
  transportCost: Scalars['Int']['input'];
  transportModeTypeId: Scalars['Int']['input'];
  unixTimestamp: Scalars['Int']['input'];
};

export type CreateRemovalOperationObject = {
  costs: CreateRemovalOperationObjectCosts;
  guid: Scalars['String']['input'];
  realId: Scalars['Float']['input'];
};

export type CreateRemovalOperationObjectCosts = {
  assemblyCost: Scalars['Float']['input'];
  floorsByStairsCost: Scalars['Float']['input'];
  numberOfFloors: Scalars['Float']['input'];
  packagingCost: Scalars['Float']['input'];
};

/** The input to create a sales request from the App */
export type CreateSaleRequestInput = {
  /** The object owner's country */
  country: AllowedSaleRequestCountries;
  /** The object to sell */
  objectId: Scalars['Float']['input'];
  /** The price the customer asks for. (PRECIO REQUERIDO CLIENTE) */
  prc: Scalars['Float']['input'];
  /** The object to sell's owner */
  userId: Scalars['Int']['input'];
};

/** The input to create a sales request from the App */
export type CreateSaleRequestInputType = {
  __typename?: 'CreateSaleRequestInputType';
  /** The object owner's country */
  country: AllowedSaleRequestCountries;
  /** The object to sell */
  objectId: Scalars['Float']['output'];
  /** The price the customer asks for. (PRECIO REQUERIDO CLIENTE) */
  prc: Scalars['Float']['output'];
  /** The object to sell's owner */
  userId: Scalars['Int']['output'];
};

export type Debts = {
  __typename?: 'Debts';
  amountInCents: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  preferenceStatus: PreferenceStatus;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
};

export type DepositAllResponse = {
  __typename?: 'DepositAllResponse';
  operations?: Maybe<Array<Operation>>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type DepositDraftOperationResponse = {
  __typename?: 'DepositDraftOperationResponse';
  additionalCostComment: Scalars['String']['output'];
  additionalCostInCents: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  cardId: Scalars['Int']['output'];
  cardLastDigits: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  coreId: Scalars['Int']['output'];
  coreType: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  discountCode: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  operationId: Scalars['Int']['output'];
  operationNumber: Scalars['Float']['output'];
  originalAddress: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tollCostInCents: Scalars['Int']['output'];
  transportCostInCents: Scalars['Int']['output'];
  transportDatetime: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type DepositOperationItemType = {
  __typename?: 'DepositOperationItemType';
  amount?: Maybe<Scalars['Int']['output']>;
  costPerFloorInCents?: Maybe<Scalars['Int']['output']>;
  depositId?: Maybe<Scalars['Int']['output']>;
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  heightInCm?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lengthInCm?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packagingCostInCents?: Maybe<Scalars['Int']['output']>;
  priceInCents?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  volumeInM_3?: Maybe<Scalars['Float']['output']>;
  weightInGr?: Maybe<Scalars['Int']['output']>;
  widthInCm?: Maybe<Scalars['Int']['output']>;
};

export type DepositProduct = {
  __typename?: 'DepositProduct';
  guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  operation?: Maybe<Operation>;
  packagingCost?: Maybe<Scalars['Int']['output']>;
  transaction?: Maybe<Transaction>;
};

export type DiscountDescriptionResponse = {
  __typename?: 'DiscountDescriptionResponse';
  deposit: Scalars['Int']['output'];
  general: Scalars['Int']['output'];
  transport: Scalars['Int']['output'];
  valid: Scalars['Boolean']['output'];
};

/** Discount Data */
export type DiscountEntity = {
  __typename?: 'DiscountEntity';
  discountCode: Scalars['String']['output'];
  discountId?: Maybe<Scalars['Float']['output']>;
};

/** The donation request */
export type DonationRequestEntity = {
  __typename?: 'DonationRequestEntity';
  /** The donation request ID */
  id: Scalars['Int']['output'];
  request: RequestDonationDetails;
  /** The request ID */
  requestId: Scalars['Int']['output'];
  /** The status of the donation request */
  status: Scalars['String']['output'];
};

/** The input to create a donation request */
export type DonationRequestInput = {
  /** Country ID for the donation request */
  countryId: Scalars['String']['input'];
  /** Array of object IDs to create a donation request for */
  objectIds: Array<Scalars['Int']['input']>;
  /** User ID creating the donation request */
  userId: Scalars['Int']['input'];
};

export type DonationRequestOptions = {
  createdFrom?: InputMaybe<Scalars['String']['input']>;
  createdTo?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Scalars['String']['input']>;
  updatedFrom?: InputMaybe<Scalars['String']['input']>;
  updatedTo?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type DraftDepositProposal = {
  __typename?: 'DraftDepositProposal';
  additionalCostComment?: Maybe<Scalars['String']['output']>;
  additionalCostInCents?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<AddressResponseDepositProposal>;
  costPerFloorInCents?: Maybe<Scalars['Int']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  cubicCm?: Maybe<Scalars['Int']['output']>;
  cubicMPriceInCents?: Maybe<Scalars['Int']['output']>;
  depositId?: Maybe<Scalars['Int']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  discountCode?: Maybe<Scalars['String']['output']>;
  discountId?: Maybe<Scalars['Float']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  items?: Maybe<Array<ItemResponseDepositProposal>>;
  notify?: Maybe<NotifyResponseDepositProposal>;
  origin?: Maybe<Scalars['String']['output']>;
  packagingCostInCents?: Maybe<Scalars['Int']['output']>;
  peopleId?: Maybe<Scalars['Int']['output']>;
  proposalStatusId?: Maybe<Scalars['Float']['output']>;
  prospectAddressId?: Maybe<Scalars['Int']['output']>;
  prospectId: Scalars['Int']['output'];
  serviceTypeId?: Maybe<Scalars['String']['output']>;
  shipmentAssistantCountInCents?: Maybe<Scalars['Int']['output']>;
  shipmentAssistantPriceInCents?: Maybe<Scalars['Int']['output']>;
  storageCostInCents?: Maybe<Scalars['Int']['output']>;
  totalAmountInCents?: Maybe<Scalars['Int']['output']>;
  totalWeightInGr?: Maybe<Scalars['Float']['output']>;
  transportCostInCents?: Maybe<Scalars['Int']['output']>;
  transportModeId?: Maybe<Scalars['Int']['output']>;
  transportType?: Maybe<Scalars['String']['output']>;
  userCreatedId?: Maybe<Scalars['Float']['output']>;
};

export type Eligibilities = {
  __typename?: 'Eligibilities';
  donation: Scalars['Boolean']['output'];
  sale: Scalars['Boolean']['output'];
};

/** The entity used when there is no response besides status code. */
export type EmptyServerResponse = {
  __typename?: 'EmptyServerResponse';
  /** The operation was succesful */
  success: Scalars['Boolean']['output'];
};

/** The entity that represents an express sale */
export type ExpressSaleRequestEntity = {
  __typename?: 'ExpressSaleRequestEntity';
  /** The sale ID */
  id: Scalars['Int']['output'];
  /** The price Space Guru offer to the user for an express sale. (PRECIO VENTA EXPRESS) */
  pve: Scalars['Float']['output'];
};

export type ExtrasDetailInput = {
  additionalCostComment?: InputMaybe<Scalars['String']['input']>;
  costPerFloorInCents?: InputMaybe<Scalars['Int']['input']>;
  disassemblyCostInCents?: InputMaybe<Scalars['Int']['input']>;
  packagingCostInCents?: InputMaybe<Scalars['Int']['input']>;
  shipmentAssistantCount?: InputMaybe<Scalars['Int']['input']>;
  shipmentAssistantPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type FinishDepositProposalResponse = {
  __typename?: 'FinishDepositProposalResponse';
  deposit: DepositDraftOperationResponse;
  depositProposal: DraftDepositProposal;
};

export type FinishDraftDepositProposalInput = {
  additionalCostComment?: InputMaybe<Scalars['String']['input']>;
  additionalCostInCents?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<AddressCreateDepositProposal>;
  costPerFloorInCents?: InputMaybe<Scalars['Float']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  cubicCm?: InputMaybe<Scalars['Float']['input']>;
  cubicMPriceInCents?: InputMaybe<Scalars['Float']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  depositId?: InputMaybe<Scalars['Float']['input']>;
  destination?: InputMaybe<Scalars['String']['input']>;
  disassemblyCost?: InputMaybe<Scalars['Float']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  discountId?: InputMaybe<Scalars['Float']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  items?: InputMaybe<Array<ItemCreateDepositProposal>>;
  notify?: InputMaybe<NotifyCreateDepositProposal>;
  origin?: InputMaybe<Scalars['String']['input']>;
  packagingCostInCents?: InputMaybe<Scalars['Float']['input']>;
  peopleId?: InputMaybe<Scalars['Float']['input']>;
  proposalStatusId?: InputMaybe<Scalars['Float']['input']>;
  prospectAddressId?: InputMaybe<Scalars['Float']['input']>;
  prospectId?: InputMaybe<Scalars['Float']['input']>;
  serviceTypeId?: InputMaybe<Scalars['String']['input']>;
  shipmentAssistantCount?: InputMaybe<Scalars['Float']['input']>;
  shipmentAssistantCountInCents?: InputMaybe<Scalars['Float']['input']>;
  shipmentAssistantPrice?: InputMaybe<Scalars['Float']['input']>;
  shipmentAssistantPriceInCents?: InputMaybe<Scalars['Float']['input']>;
  storageCostInCents?: InputMaybe<Scalars['Float']['input']>;
  timeIntervalId?: InputMaybe<Scalars['Float']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
  totalAmountInCents?: InputMaybe<Scalars['Float']['input']>;
  totalWeightInGr?: InputMaybe<Scalars['Float']['input']>;
  transportCostInCents?: InputMaybe<Scalars['Float']['input']>;
  transportDetail?: InputMaybe<TransportDetailInput>;
  transportModeId?: InputMaybe<Scalars['Float']['input']>;
  transportType?: InputMaybe<Scalars['String']['input']>;
  userCreatedId?: InputMaybe<Scalars['Float']['input']>;
};

/** The flags available for get values */
export enum Flag {
  CustomDateTransportMultArg = 'CUSTOM_DATE_TRANSPORT_MULT_ARG',
  CustomDateTransportMultEsp = 'CUSTOM_DATE_TRANSPORT_MULT_ESP',
  CustomItemMinPrice = 'CUSTOM_ITEM_MIN_PRICE',
  ExtraAssistantPriceArg = 'EXTRA_ASSISTANT_PRICE_ARG',
  ExtraAssistantPriceEsp = 'EXTRA_ASSISTANT_PRICE_ESP',
  ExtraKmPriceArg = 'EXTRA_KM_PRICE_ARG',
  ExtraKmPriceEsp = 'EXTRA_KM_PRICE_ESP',
  KgPrice = 'KG_PRICE',
  KgPriceEsp = 'KG_PRICE_ESP',
  MaxVolumeDeviation = 'MAX_VOLUME_DEVIATION',
  MinBillableM3 = 'MIN_BILLABLE_M3',
  MinDaysBeforeRemovalApp = 'MIN_DAYS_BEFORE_REMOVAL_APP',
  MinVolumeDeviation = 'MIN_VOLUME_DEVIATION',
  RemovalAnticipationDays = 'REMOVAL_ANTICIPATION_DAYS',
  RequireCardForDeposit = 'REQUIRE_CARD_FOR_DEPOSIT',
  RetryChargeDays = 'RETRY_CHARGE_DAYS',
  SaleCostArg = 'SALE_COST_ARG',
  TollCostInCents = 'TOLL_COST_IN_CENTS',
  TransportCostMinPrice = 'TRANSPORT_COST_MIN_PRICE'
}

export type GetDashboardAnnualMetricsInput = {
  countryCode: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetDashboardAnnualMetricsResponse = {
  __typename?: 'GetDashboardAnnualMetricsResponse';
  date: Scalars['String']['output'];
  m3Removals: Scalars['Int']['output'];
  m3WonDepositOperations: Scalars['Int']['output'];
  quantityClientsWithFullRemovals: Scalars['Int']['output'];
  quantityNewUsersWithFirstDepositOperation: Scalars['Int']['output'];
};

export type GetDashboardMetricsInput = {
  countryCode: Scalars['String']['input'];
  periodFrom: Scalars['String']['input'];
  periodTo: Scalars['String']['input'];
};

export type GetDashboardMetricsResponse = {
  __typename?: 'GetDashboardMetricsResponse';
  m3CancelledDepositOperations: Scalars['Int']['output'];
  m3DepositedDepositOperations: Scalars['Int']['output'];
  m3Removals: Scalars['Int']['output'];
  m3TotalDepositOperations: Scalars['Int']['output'];
  m3ValidatedDepositOperations: Scalars['Int']['output'];
  m3WonDepositOperations: Scalars['Int']['output'];
  quantityCancelledDepositOperations: Scalars['Int']['output'];
  quantityClientsWithFullRemovals: Scalars['Int']['output'];
  quantityDepositedDepositOperations: Scalars['Int']['output'];
  quantityNewUsersWithOneDepositOperation: Scalars['Int']['output'];
  quantityValidatedDepositOperations: Scalars['Int']['output'];
  quantityWonDepositOperations: Scalars['Int']['output'];
  quantityWonProspects: Scalars['Int']['output'];
  quotedM3: Scalars['Int']['output'];
  realM3: Scalars['Int']['output'];
  totalDepositOperations: Scalars['Int']['output'];
  totalRemovals: Scalars['Int']['output'];
};

export type GetPriceBandResponse = {
  __typename?: 'GetPriceBandResponse';
  country: CountryId;
  dateFrom: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type GetPriceBandsInput = {
  country: CountryId;
  type: PriceBand;
};

export type GetValueByFlagRequestInput = {
  countryCode: CountryId;
  flag: Flag;
};

export type GetValueByFlagResponse = {
  __typename?: 'GetValueByFlagResponse';
  value: Scalars['String']['output'];
};

export type GoogleUtmInput = {
  adGroup?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  landing?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type HolderInfoEntity = {
  __typename?: 'HolderInfoEntity';
  email: Scalars['String']['output'];
  holderIdentificationNumber: Scalars['String']['output'];
  holderIdentificationType: Scalars['String']['output'];
};

export type IdentificationCode = {
  __typename?: 'IdentificationCode';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type InputUpdateProspect = {
  prospectId: Scalars['Int']['input'];
};

export type ItemCreateDepositProposal = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  costPerFloorInCents?: InputMaybe<Scalars['Float']['input']>;
  depositProposalId?: InputMaybe<Scalars['Float']['input']>;
  disassemblyCost?: InputMaybe<Scalars['Float']['input']>;
  heightInCm?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  lengthInCm?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packagingCostInCents?: InputMaybe<Scalars['Float']['input']>;
  priceInCents?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['Float']['input']>;
  weightInGr?: InputMaybe<Scalars['Float']['input']>;
  widthInCm?: InputMaybe<Scalars['Float']['input']>;
};

export type ItemInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  costPerFloorInCents?: InputMaybe<Scalars['Int']['input']>;
  disassemblyCostInCents?: InputMaybe<Scalars['Int']['input']>;
  fromProposal?: InputMaybe<Scalars['Boolean']['input']>;
  packagingCostInCents?: InputMaybe<Scalars['Int']['input']>;
  priceInCents?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Float']['input']>;
};

export type ItemResponseDepositProposal = {
  __typename?: 'ItemResponseDepositProposal';
  amount?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  costPerFloorInCents?: Maybe<Scalars['Int']['output']>;
  depositProposalId?: Maybe<Scalars['Float']['output']>;
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  heightInCm?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lengthInCm?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packagingCostInCents?: Maybe<Scalars['Int']['output']>;
  priceInCents?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  weightInGr?: Maybe<Scalars['Int']['output']>;
  widthInCm?: Maybe<Scalars['Int']['output']>;
};

export type LeadDataInput = {
  country: CountryId;
  device: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originMedia: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<UtmInput>;
};

export type LeadGoogleSheetDataInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country: CountryId;
  device: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<GoogleUtmInput>;
};

/** Removal entity in list query */
export type ListRemoval = {
  __typename?: 'ListRemoval';
  /** Removal address */
  address: RemovalAddressEntity;
  /** Cancel reason of Removal */
  cancelReason?: Maybe<RemovalCancelReasonEntity>;
  cardData: CardEntity;
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  datetime: Scalars['String']['output'];
  discountData: DiscountEntity;
  id: Scalars['Float']['output'];
  /** Modify reason of Removal */
  modifyReason?: Maybe<RemovalModifyReasonEntity>;
  objectData: ObjectDataEntity;
  operationNumber: Scalars['Float']['output'];
  originalStatus: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  removalAuth: RemovalAuthEntity;
  status: Scalars['String']['output'];
  transaction: RemovalTransactionEntity;
  transportData: TransportEntity;
  updatedAt: Scalars['String']['output'];
  userData: RemovalUserEntity;
};

export type MercurioProspectInputParam = {
  countryId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  prospectId: Scalars['Int']['input'];
};

export type MinCost = {
  __typename?: 'MinCost';
  minCost: Scalars['Float']['output'];
  objectId: Scalars['Int']['output'];
};

export type MinCosts = {
  __typename?: 'MinCosts';
  objects: Array<MinCost>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCounterProposal: EmptyServerResponse;
  acceptDonationRequest: EmptyServerResponse;
  acceptExpressSale: EmptyServerResponse;
  addUserAddress: AddressEntity;
  addUserPaymentMethod: EmptyServerResponse;
  calculateMinCost: MinCosts;
  cancelDonationRequest: EmptyServerResponse;
  cancelSale: EmptyServerResponse;
  confirmVerificationCode: EmptyServerResponse;
  createDonationRequest: Array<DonationRequestEntity>;
  createDraftRemoval: CreateDraftRemovalResponse;
  createLead: EmptyServerResponse;
  createSale: SaleRequestCreationResponse;
  createSales: Array<SaleRequestCreationResponse>;
  deleteUserAddress: EmptyServerResponse;
  deleteUserPaymentMethod: EmptyServerResponse;
  donateToDonationRequest: EmptyServerResponse;
  finishDepositProposal: FinishDepositProposalResponse;
  markPaymentMethodAsMain: EmptyServerResponse;
  markUserAddressAsMain: EmptyServerResponse;
  paymentRemoval: EmptyServerResponse;
  postRowIntoLeadSheet: EmptyServerResponse;
  quoterEntryRequest: DraftDepositProposal;
  rejectProposal: EmptyServerResponse;
  requestCollectDownPayment: Scalars['Boolean']['output'];
  requestDebts: Debts;
  requestDepositProposal: DraftDepositProposal;
  requestRemoval: RequestRemovalResponse;
  resendVerificationCode: EmptyServerResponse;
  reserveTimeslot: EmptyServerResponse;
  sendVerificationCode: EmptyServerResponse;
  trackingEvents: EmptyServerResponse;
  updateBaseUserProfileInformation: EmptyServerResponse;
  updateObject: ObjectEntity;
  updateProspect: EmptyServerResponse;
  updateUserAddress: EmptyServerResponse;
  updateUserEmail: EmptyServerResponse;
};


export type MutationAcceptCounterProposalArgs = {
  id: Scalars['Float']['input'];
};


export type MutationAcceptDonationRequestArgs = {
  id: Scalars['Float']['input'];
};


export type MutationAcceptExpressSaleArgs = {
  id: Scalars['Float']['input'];
};


export type MutationAddUserAddressArgs = {
  input: AddUserAddressInput;
};


export type MutationAddUserPaymentMethodArgs = {
  input: AddUserPaymentMethodInput;
};


export type MutationCalculateMinCostArgs = {
  input: CalculateMinCostInput;
};


export type MutationCancelDonationRequestArgs = {
  id: Scalars['Float']['input'];
};


export type MutationCancelSaleArgs = {
  id: Scalars['Float']['input'];
};


export type MutationConfirmVerificationCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationCreateDonationRequestArgs = {
  input: DonationRequestInput;
};


export type MutationCreateDraftRemovalArgs = {
  input: CreateDraftRemovalInput;
};


export type MutationCreateLeadArgs = {
  input: LeadDataInput;
};


export type MutationCreateSaleArgs = {
  payload: CreateSaleRequestInput;
};


export type MutationCreateSalesArgs = {
  input: CreateMultipleSaleRequestInput;
};


export type MutationDeleteUserAddressArgs = {
  addressId: Scalars['Float']['input'];
};


export type MutationDeleteUserPaymentMethodArgs = {
  cardId: Scalars['Float']['input'];
};


export type MutationDonateToDonationRequestArgs = {
  id: Scalars['Float']['input'];
};


export type MutationFinishDepositProposalArgs = {
  input?: InputMaybe<FinishDraftDepositProposalInput>;
};


export type MutationMarkPaymentMethodAsMainArgs = {
  paymentMethodId: Scalars['Float']['input'];
};


export type MutationMarkUserAddressAsMainArgs = {
  addressId: Scalars['Float']['input'];
};


export type MutationPaymentRemovalArgs = {
  input: PaymentRemovalInput;
};


export type MutationPostRowIntoLeadSheetArgs = {
  input: LeadGoogleSheetDataInput;
};


export type MutationQuoterEntryRequestArgs = {
  input: QuoterEntryRequestInput;
  proposalInput?: InputMaybe<CreateDraftDepositProposalInput>;
};


export type MutationRejectProposalArgs = {
  id: Scalars['Float']['input'];
  input: RejectSaleRequestCounterProposalReason;
};


export type MutationRequestCollectDownPaymentArgs = {
  input: CollectDownPaymentInput;
};


export type MutationRequestDebtsArgs = {
  input: CreateDebtsPaymentInput;
};


export type MutationRequestDepositProposalArgs = {
  input: CreateDraftDepositProposalInput;
};


export type MutationRequestRemovalArgs = {
  input: CreateRemovalOperationInput;
};


export type MutationReserveTimeslotArgs = {
  id: Scalars['Float']['input'];
  input: ReserveTimeSlotInput;
};


export type MutationSendVerificationCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationTrackingEventsArgs = {
  input: TrackingEvent;
};


export type MutationUpdateBaseUserProfileInformationArgs = {
  input: UpdateUserBasicInfoInput;
};


export type MutationUpdateObjectArgs = {
  id: Scalars['String']['input'];
  input: UpdateObjectInput;
};


export type MutationUpdateProspectArgs = {
  input: InputUpdateProspect;
};


export type MutationUpdateUserAddressArgs = {
  addressId: Scalars['Float']['input'];
  input: UpdateUserAddressInput;
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};

/** An entity representing an object that was rejected permanently as sellable by Space Guru */
export type NonSellableObjectEntity = {
  __typename?: 'NonSellableObjectEntity';
  id: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type NotifyCreateDepositProposal = {
  email?: InputMaybe<Scalars['String']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotifyResponseDepositProposal = {
  __typename?: 'NotifyResponseDepositProposal';
  email?: Maybe<Scalars['String']['output']>;
  notify?: Maybe<Scalars['Boolean']['output']>;
};

/** All data related to objects */
export type ObjectDataEntity = {
  __typename?: 'ObjectDataEntity';
  objectCount: Scalars['Float']['output'];
  objectFoundCount: Scalars['Float']['output'];
  objectNotFoundCount: Scalars['Float']['output'];
  objectReturnedCount: Scalars['Float']['output'];
  objectReturnedM3: Scalars['Float']['output'];
  objectToReturnCount: Scalars['Float']['output'];
  objectToReturnM3: Scalars['Float']['output'];
  objectTotalM3: Scalars['Float']['output'];
  objects?: Maybe<Array<RemovalObjectEntity>>;
};

/** The object to store in Space Guru's deposits */
export type ObjectDonationEntity = {
  __typename?: 'ObjectDonationEntity';
  /** A brief description about the object. */
  description: Scalars['String']['output'];
  /** The object GUID (internal identifier) */
  guid: Scalars['String']['output'];
  heightInCm: Scalars['Float']['output'];
  /** The object id (database identifier) */
  id: Scalars['Int']['output'];
  identificationCode: IdentificationCode;
  lengthInCm: Scalars['Float']['output'];
  objectSituation?: Maybe<Scalars['String']['output']>;
  photo: ObjectPhotoDonationEntity;
  product: ProductDonation;
  status?: Maybe<Scalars['String']['output']>;
  translateState: Scalars['String']['output'];
  weightInGr: Scalars['Float']['output'];
  widthInCm: Scalars['Float']['output'];
};

/** The object to store in Space Guru's deposits */
export type ObjectEntity = {
  __typename?: 'ObjectEntity';
  allowedForSale: Scalars['Boolean']['output'];
  /** A brief description about the object. */
  description: Scalars['String']['output'];
  eligibilities: Eligibilities;
  /** The object GUID (internal identifier) */
  guid: Scalars['String']['output'];
  heightInCm: Scalars['Float']['output'];
  /** The object id (database identifier) */
  id: Scalars['Int']['output'];
  lengthInCm: Scalars['Float']['output'];
  /** The object's m3 size */
  m3: Scalars['Float']['output'];
  /** The object's name */
  name: Scalars['String']['output'];
  objectSituation?: Maybe<AllowedObjectSituations>;
  objectSituationReason?: Maybe<Scalars['String']['output']>;
  /** An Object containing the status id and a description */
  objectStatus: ObjectStatusEntity;
  /** The object owner */
  owner: ObjectOwnerEntity;
  /** The object id (database identifier) */
  ownerId: Scalars['Int']['output'];
  /** The object's photos */
  photos: Array<ObjectPhotosEntity>;
  product: Product;
  simplifiedState?: Maybe<Scalars['String']['output']>;
  weightInGr: Scalars['Float']['output'];
  widthInCm: Scalars['Float']['output'];
};

export type ObjectOperation = {
  __typename?: 'ObjectOperation';
  disassemblyCost?: Maybe<Scalars['Int']['output']>;
  floors?: Maybe<Scalars['Int']['output']>;
  floorsCostPerFloorInCents?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<ObjectT>;
  operationId?: Maybe<Scalars['Int']['output']>;
  packagingCostInCents?: Maybe<Scalars['Int']['output']>;
  priceInCents?: Maybe<Scalars['Int']['output']>;
};

export type ObjectOwnerEntity = {
  __typename?: 'ObjectOwnerEntity';
  countryId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type ObjectPhotoDonationEntity = {
  __typename?: 'ObjectPhotoDonationEntity';
  id: Scalars['Int']['output'];
  /** The object id (database identifier) */
  objectId: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type ObjectPhotosEntity = {
  __typename?: 'ObjectPhotosEntity';
  deletedDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  mainPhoto: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type ObjectSearchFilters = {
  category?: InputMaybe<Scalars['Int']['input']>;
  eligibleForSale?: InputMaybe<Scalars['Boolean']['input']>;
  objectsAllowedForDonation?: InputMaybe<Scalars['Boolean']['input']>;
  objectsAllowedForSale?: InputMaybe<Scalars['Boolean']['input']>;
  simplifiedStatus?: InputMaybe<Array<AllowedObjectSimplifiedStatus>>;
  situation?: InputMaybe<Array<AllowedObjectSituations>>;
};

export type ObjectSearchOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortOptions>;
};

export type ObjectStatusEntity = {
  __typename?: 'ObjectStatusEntity';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ObjectT = {
  __typename?: 'ObjectT';
  id?: Maybe<Scalars['Int']['output']>;
  photos?: Maybe<Array<Photo>>;
  product?: Maybe<DepositProduct>;
};

export type Operation = {
  __typename?: 'Operation';
  additionalCost?: Maybe<Scalars['Int']['output']>;
  additionalCostsItems?: Maybe<AdditionalCostItems>;
  additionalCostsObjects?: Maybe<AdditionalCostObjects>;
  additional_costs?: Maybe<AdditionalCosts>;
  address?: Maybe<Address>;
  countryId?: Maybe<Scalars['String']['output']>;
  depositOperationItems?: Maybe<Array<DepositOperationItemType>>;
  discountCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  monthlyPayment?: Maybe<Scalars['Int']['output']>;
  objectsOperation?: Maybe<Array<ObjectOperation>>;
  operationCoreId?: Maybe<Scalars['Int']['output']>;
  operationCoreType?: Maybe<Scalars['String']['output']>;
  operationNumber?: Maybe<Scalars['Int']['output']>;
  originalStatus?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Payment>;
  quotedTotal?: Maybe<Scalars['Int']['output']>;
  reserveDate?: Maybe<Scalars['String']['output']>;
  savedTotal?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tollCostInCents?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalVolume?: Maybe<Scalars['Float']['output']>;
  transaction?: Maybe<Transaction>;
  transportCost: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type PaginatedAddressEntities = {
  __typename?: 'PaginatedAddressEntities';
  hasMore: Scalars['Boolean']['output'];
  items: Array<AddressEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedAddressEntityResponse = {
  __typename?: 'PaginatedAddressEntityResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<AddressEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedBillingEntityResponse = {
  __typename?: 'PaginatedBillingEntityResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<BillingEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedBillingListEntity = {
  __typename?: 'PaginatedBillingListEntity';
  hasMore: Scalars['Boolean']['output'];
  items: Array<BillingEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalDebt: Scalars['Float']['output'];
};

export type PaginatedDepositEntities = {
  __typename?: 'PaginatedDepositEntities';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Operation>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDonationRequestEntityResponse = {
  __typename?: 'PaginatedDonationRequestEntityResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<DonationRequestEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDonationRequests = {
  __typename?: 'PaginatedDonationRequests';
  hasMore: Scalars['Boolean']['output'];
  items: Array<DonationRequestEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedListRemovalResponse = {
  __typename?: 'PaginatedListRemovalResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<ListRemoval>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedObjectEntities = {
  __typename?: 'PaginatedObjectEntities';
  hasMore: Scalars['Boolean']['output'];
  items: Array<ObjectEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedObjectEntityResponse = {
  __typename?: 'PaginatedObjectEntityResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<ObjectEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedOperationResponse = {
  __typename?: 'PaginatedOperationResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Operation>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedRemovalListEntity = {
  __typename?: 'PaginatedRemovalListEntity';
  hasMore: Scalars['Boolean']['output'];
  items: Array<ListRemoval>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedSaleRequestEntityWithCountersResponse = {
  __typename?: 'PaginatedSaleRequestEntityWithCountersResponse';
  counters: Array<SaleRequestEntityCounterByStatus>;
  hasMore: Scalars['Boolean']['output'];
  items: Array<SaleRequestEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedSaleRequestsEntitiesWithCounters = {
  __typename?: 'PaginatedSaleRequestsEntitiesWithCounters';
  counters: Array<SaleRequestEntityCounterByStatus>;
  hasMore: Scalars['Boolean']['output'];
  items: Array<SaleRequestEntity>;
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Paging = {
  __typename?: 'Paging';
  limit: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PayerEntity = {
  __typename?: 'PayerEntity';
  email: Scalars['String']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  cardId?: Maybe<Scalars['Int']['output']>;
  cardLastDigits?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['Float']['output'];
  main: Scalars['Boolean']['output'];
  paymentMethod: PaymentMethodType;
  paymentMethodType: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
};

export type PaymentMethodEntity = {
  __typename?: 'PaymentMethodEntity';
  cardType: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  lastFourDigits: Scalars['String']['output'];
  month: Scalars['Float']['output'];
  paymentMethodType: Scalars['String']['output'];
  year: Scalars['Float']['output'];
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  cardType: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  lastFourDigits: Scalars['String']['output'];
};

/** The payment method of the removal */
export enum PaymentMethodVariant {
  None = 'none',
  Offline = 'offline',
  Spreedly = 'spreedly'
}

export type PaymentRemovalInput = {
  amountInCents: Scalars['Int']['input'];
  paymentMethodId: Scalars['Int']['input'];
  paymentSpreedlyToken: Scalars['String']['input'];
  removalOperationId: Scalars['Int']['input'];
};

export type Photo = {
  __typename?: 'Photo';
  id?: Maybe<Scalars['Int']['output']>;
  mainPhoto?: Maybe<Scalars['Boolean']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
  s3Key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PostalCode = {
  __typename?: 'PostalCode';
  allowedDelivery: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type PreferenceStatus = {
  __typename?: 'PreferenceStatus';
  description: Scalars['String']['output'];
  id: Scalars['Float']['output'];
};

/** Typeof PriceBand available for get values */
export enum PriceBand {
  Kg = 'KG',
  M3 = 'M3'
}

export type Product = {
  __typename?: 'Product';
  assemblyCostInCents: Scalars['Int']['output'];
  /** Whether the product is eligible for donation */
  eligibleForDonation: Scalars['Boolean']['output'];
  /** Whether the product is eligible for sale */
  eligibleForSale: Scalars['Boolean']['output'];
  floorByStairsCostInCents: Scalars['Int']['output'];
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  removalPackagingCostInCents: Scalars['Int']['output'];
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
};

export type ProductByCountry = {
  __typename?: 'ProductByCountry';
  countryId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productId: Scalars['ID']['output'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProductDonation = {
  __typename?: 'ProductDonation';
  multiplier: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ProfilePicture = {
  __typename?: 'ProfilePicture';
  id: Scalars['Int']['output'];
  s3Key?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

/** Service type of product at search */
export enum PublicProdcutServiceType {
  ItemB2B = 'ITEM_B2B',
  ItemB2C = 'ITEM_B2C'
}

export type PublicProductSearchInput = {
  categoryId?: InputMaybe<Scalars['Float']['input']>;
  countryCode: Scalars['String']['input'];
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  guids?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['Float']['input']>;
  labelId?: InputMaybe<Scalars['Int']['input']>;
  mt3?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serviceTypeId?: InputMaybe<PublicProdcutServiceType>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type PublicProductSearchOptions = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  sort?: InputMaybe<SortOptions>;
};

export type Query = {
  __typename?: 'Query';
  addresses: PaginatedAddressEntities;
  calculateTripCost: CalculateTripCostsResponse;
  getAllUserPaymentMethods: Array<PaymentMethod>;
  getBillings: PaginatedBillingListEntity;
  getCalendar: Array<CalendarDateEntity>;
  getCurrentPriceBands: GetPriceBandResponse;
  getDashboardAnnualMetrics: GetDashboardAnnualMetricsResponse;
  getDashboardMetrics: GetDashboardMetricsResponse;
  getDepositById: DepositResponse;
  getDeposits: PaginatedDepositEntities;
  getDiscountDetail: DiscountDescriptionResponse;
  getDonationRequests: PaginatedDonationRequests;
  getM3Price: QuoterGetM3Price;
  getRemoval: RemovalDetails;
  getRemovals: PaginatedRemovalListEntity;
  getTimeIntervalByID: TimeInterval;
  getTimeIntervalsByHour: Array<TimeIntervalsByHourEntity>;
  getTotalDebt: UserTotalDebt;
  getUserCards: UserCardsListResponse;
  getValueByFlag: GetValueByFlagResponse;
  hasObjectBeenBannedForDonationOrSale: Scalars['Boolean']['output'];
  nonSellableObjects: Array<NonSellableObjectEntity>;
  object: ObjectEntity;
  objects: PaginatedObjectEntities;
  profile: UserEntity;
  sale: SaleRequestEntity;
  sales: PaginatedSaleRequestsEntitiesWithCounters;
  searchPublicProducts: SearchPublicProductsResponse;
  timeslots: Array<TimeSlotEntity>;
};


export type QueryAddressesArgs = {
  options?: InputMaybe<AddressSearchOptions>;
};


export type QueryCalculateTripCostArgs = {
  input: CalculateTripCostsInput;
};


export type QueryGetBillingsArgs = {
  filters?: InputMaybe<BillingListFilters>;
  options?: InputMaybe<BillingListSearchOptions>;
};


export type QueryGetCalendarArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryGetCurrentPriceBandsArgs = {
  input: GetPriceBandsInput;
};


export type QueryGetDashboardAnnualMetricsArgs = {
  input: GetDashboardAnnualMetricsInput;
};


export type QueryGetDashboardMetricsArgs = {
  input: GetDashboardMetricsInput;
};


export type QueryGetDepositByIdArgs = {
  depositId: Scalars['Float']['input'];
};


export type QueryGetDepositsArgs = {
  options?: InputMaybe<ObjectSearchOptions>;
  statuses?: InputMaybe<Array<AllowedDepositSimplifiedStatus>>;
};


export type QueryGetDiscountDetailArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetDonationRequestsArgs = {
  options?: InputMaybe<DonationRequestOptions>;
};


export type QueryGetM3PriceArgs = {
  input: QuoterGetM3PriceInput;
};


export type QueryGetRemovalArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  removalId: Scalars['Float']['input'];
};


export type QueryGetRemovalsArgs = {
  filters?: InputMaybe<RemovalListFilters>;
  options?: InputMaybe<RemovalListSearchOptions>;
};


export type QueryGetTimeIntervalByIdArgs = {
  id: Scalars['Float']['input'];
};


export type QueryGetValueByFlagArgs = {
  input: GetValueByFlagRequestInput;
};


export type QueryHasObjectBeenBannedForDonationOrSaleArgs = {
  objectId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryNonSellableObjectsArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryObjectArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type QueryObjectsArgs = {
  filters?: InputMaybe<ObjectSearchFilters>;
  options?: InputMaybe<ObjectSearchOptions>;
  userId: Scalars['Int']['input'];
};


export type QuerySaleArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySalesArgs = {
  filters?: InputMaybe<SaleRequestSearchFilters>;
  options?: InputMaybe<SaleRequestSearchOptions>;
  userId: Scalars['Int']['input'];
};


export type QuerySearchPublicProductsArgs = {
  input: PublicProductSearchInput;
  options: PublicProductSearchOptions;
};


export type QueryTimeslotsArgs = {
  date: Scalars['Float']['input'];
};

export type QuoterEntryRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country: CountryId;
  device: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originMedia: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<GoogleUtmInput>;
};

export type QuoterGetM3Price = {
  __typename?: 'QuoterGetM3Price';
  m3PriceInCents: Scalars['Int']['output'];
  minBillableM3: Scalars['Int']['output'];
};

export type QuoterGetM3PriceInput = {
  country: CountryId;
  priceBandType: PriceBand;
};

/** The input to create multiple sales request in one request from the App */
export type RejectSaleRequestCounterProposalReason = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RemovalAddressEntity = {
  __typename?: 'RemovalAddressEntity';
  addressString: Scalars['String']['output'];
  apartment: Scalars['String']['output'];
  city: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  floor: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  number: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type RemovalAuthEntity = {
  __typename?: 'RemovalAuthEntity';
  agentId: Scalars['Float']['output'];
  agentName: Scalars['String']['output'];
  dateAuthRemoval: Scalars['String']['output'];
};

export type RemovalCancelReasonEntity = {
  __typename?: 'RemovalCancelReasonEntity';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  operationType: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

/** Removal entity in individual query */
export type RemovalDetails = {
  __typename?: 'RemovalDetails';
  additionalCostsInCents: RemovalDetailsAdditionalCosts;
  /** Removal address */
  address: Address;
  id: Scalars['Float']['output'];
  objects: Array<RemovalDetailsObject>;
  operationNumber: Scalars['Float']['output'];
  paymentMethod: RemovalDetailsPaymentMethod;
  scheduledDate: Scalars['String']['output'];
  status: Scalars['String']['output'];
  timeInterval: Scalars['String']['output'];
  tollCostInCents: Scalars['Float']['output'];
  totalM3: Scalars['Float']['output'];
  transportCostInCents: Scalars['Float']['output'];
};

export type RemovalDetailsAdditionalCosts = {
  __typename?: 'RemovalDetailsAdditionalCosts';
  assemblyCost: Scalars['Float']['output'];
  minCost: Scalars['Float']['output'];
  packagingCost: Scalars['Float']['output'];
  stairsCost: Scalars['Float']['output'];
};

export type RemovalDetailsObject = {
  __typename?: 'RemovalDetailsObject';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  photos: Array<Photo>;
  product: RemovalDetailsProduct;
};

export type RemovalDetailsPaymentMethod = {
  __typename?: 'RemovalDetailsPaymentMethod';
  cardLastDigits?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  methodType: PaymentMethodVariant;
};

export type RemovalDetailsProduct = {
  __typename?: 'RemovalDetailsProduct';
  name: Scalars['String']['output'];
};

export type RemovalListFilters = {
  excludedStatuses?: InputMaybe<Array<RemovalOperationSimplifiedStatus>>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<RemovalOperationSimplifiedStatus>>;
};

export type RemovalListResponse = {
  __typename?: 'RemovalListResponse';
  removal?: Maybe<Array<ListRemoval>>;
  total: Scalars['Float']['output'];
};

export type RemovalListSearchOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortOptions>;
};

export type RemovalModifyReasonEntity = {
  __typename?: 'RemovalModifyReasonEntity';
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  operationType: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RemovalNoTransportChargeReasonEntity = {
  __typename?: 'RemovalNoTransportChargeReasonEntity';
  id: Scalars['Float']['output'];
  reason: Scalars['String']['output'];
};

export type RemovalObjectDetailsEntity = {
  __typename?: 'RemovalObjectDetailsEntity';
  id: Scalars['Float']['output'];
  photos: RemovalObjectPhotosEntity;
};

export type RemovalObjectEntity = {
  __typename?: 'RemovalObjectEntity';
  assemblyCost: Scalars['Float']['output'];
  floorsByStairsCost: Scalars['Float']['output'];
  object?: Maybe<RemovalObjectDetailsEntity>;
  objectId: Scalars['Float']['output'];
  operationId: Scalars['Float']['output'];
  packagingCost: Scalars['Float']['output'];
};

export type RemovalObjectPhotosEntity = {
  __typename?: 'RemovalObjectPhotosEntity';
  id: Scalars['Float']['output'];
  mainPhoto: Scalars['Boolean']['output'];
  objectId: Scalars['Float']['output'];
  s3Key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type RemovalOperationEntity = {
  __typename?: 'RemovalOperationEntity';
  cardLastDigits: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  datetime: Scalars['String']['output'];
  discountCode?: Maybe<Scalars['String']['output']>;
  discountId?: Maybe<Scalars['Float']['output']>;
  fullAddressString: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  operationNumber: Scalars['Float']['output'];
  paymentType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  transportCost: Scalars['Float']['output'];
};

/** The descriptions of the allowed deposit statuses based on simplified status */
export enum RemovalOperationSimplifiedStatus {
  Borrador = 'BORRADOR',
  Canceladas = 'CANCELADAS',
  EnCurso = 'EN_CURSO',
  Finalizadas = 'FINALIZADAS',
  Pendientes = 'PENDIENTES',
  PendientesDePago = 'PENDIENTES_DE_PAGO'
}

export type RemovalTransactionEntity = {
  __typename?: 'RemovalTransactionEntity';
  id: Scalars['Float']['output'];
  timeInterval: Scalars['String']['output'];
  timeSlotId: Scalars['Float']['output'];
  transactionStatusId: Scalars['Float']['output'];
};

export type RemovalUserEntity = {
  __typename?: 'RemovalUserEntity';
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RequestBillingsResponse = {
  __typename?: 'RequestBillingsResponse';
  billings: Array<ApiBillingEntity>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RequestDonationDetails = {
  __typename?: 'RequestDonationDetails';
  countryCode: Scalars['String']['output'];
  /** The ID of the request */
  id: Scalars['Int']['output'];
  object: ObjectDonationEntity;
  /** The ID of the request */
  objectId: Scalars['Int']['output'];
  requestType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  user: UserDonationRequest;
};

export type RequestDonationsResponse = {
  __typename?: 'RequestDonationsResponse';
  donationRequests: Array<DonationRequestEntity>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RequestRemovalResponse = {
  __typename?: 'RequestRemovalResponse';
  address: AddressEntity;
  cardLastDigits: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  datetime: Scalars['String']['output'];
  discountCode?: Maybe<Scalars['String']['output']>;
  discountId?: Maybe<Scalars['String']['output']>;
  fullAddressString: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  minCost: Scalars['Int']['output'];
  operationNumber: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tollCostInCents: Scalars['Float']['output'];
  transportCost: Scalars['Float']['output'];
};

export type ReserveTimeSlotInput = {
  /** Date to reserve in timestamp */
  date: Scalars['Float']['input'];
};

export type ResponseError = {
  __typename?: 'ResponseError';
  /** The additional details */
  details: Scalars['String']['output'];
  /** An error if occured. */
  message: Scalars['String']['output'];
  /** The status code */
  statusCode: Scalars['Int']['output'];
};

export type Rule = {
  __typename?: 'Rule';
  countryId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  distanceKm?: Maybe<Scalars['Float']['output']>;
  maxHeightM?: Maybe<Scalars['Float']['output']>;
  maxLengthInM?: Maybe<Scalars['Float']['output']>;
  maxWeightM?: Maybe<Scalars['Float']['output']>;
  maxWidthM?: Maybe<Scalars['Float']['output']>;
  volumeM3: Scalars['Float']['output'];
};

/** The entity used when there is response with a result. */
export type SaleRequestCreationResponse = {
  __typename?: 'SaleRequestCreationResponse';
  /** An error if occured. */
  error?: Maybe<ResponseError>;
  /** An error if occured. */
  payload?: Maybe<CreateSaleRequestInputType>;
  /** The operation was succesful */
  success: Scalars['Boolean']['output'];
};

export enum SaleRequestDetailAllowedOrigin {
  Client = 'CLIENT',
  Sg = 'SG'
}

export enum SaleRequestDetailAllowedStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** The object to store in Space Guru's deposits */
export type SaleRequestEntity = {
  __typename?: 'SaleRequestEntity';
  details: Array<SalesRequestDetail>;
  expressSale?: Maybe<ExpressSaleRequestEntity>;
  /** The sale ID */
  id: Scalars['Int']['output'];
  object: ObjectEntity;
  objectIdentifiers: SaleRequestObjectIdentifiers;
  /** The price the customer asks for. (PRECIO REQUERIDO CLIENTE) */
  prc: Scalars['Float']['output'];
  /** The price Space Guru will sell the object for. (PRECIO VENTA PUBLICO) */
  pvp: Scalars['Float']['output'];
  reason?: Maybe<SalesRequestReasonEntity>;
  simplifiedState?: Maybe<Scalars['String']['output']>;
  status: SaleRequestStatusEntity;
  user: SaleRequestOwner;
};

/** The counter for the sale requests by status */
export type SaleRequestEntityCounterByStatus = {
  __typename?: 'SaleRequestEntityCounterByStatus';
  /** The name of the counter */
  name: Scalars['String']['output'];
  /** The number of sale requests with that status */
  quantity: Scalars['Int']['output'];
};

export type SaleRequestObjectIdentifiers = {
  __typename?: 'SaleRequestObjectIdentifiers';
  guid: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type SaleRequestOwner = {
  __typename?: 'SaleRequestOwner';
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SaleRequestSearchFilters = {
  objectId?: InputMaybe<Scalars['Int']['input']>;
  simplifiedStatus?: InputMaybe<AllowedSaleRequestSimplifiedStatus>;
};

export type SaleRequestSearchOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortOptions>;
};

export type SaleRequestStatusEntity = {
  __typename?: 'SaleRequestStatusEntity';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SalesRequestDetail = {
  __typename?: 'SalesRequestDetail';
  id: Scalars['Int']['output'];
  origin: SaleRequestDetailAllowedOrigin;
  prc: Scalars['Float']['output'];
  pvp: Scalars['Float']['output'];
  saleCost: Scalars['Float']['output'];
  saleMargin: Scalars['Float']['output'];
  status: SaleRequestDetailAllowedStatus;
};

export type SalesRequestReasonEntity = {
  __typename?: 'SalesRequestReasonEntity';
  action: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SearchPublicProduct = {
  __typename?: 'SearchPublicProduct';
  categories: Array<SearchPublicProductCategory>;
  contentMedia: SearchPublicProductContentMedia;
  costs: SearchPublicProductCosts;
  countryCode: Scalars['String']['output'];
  deprecated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  dimensions: SearchPublicProductDimensions;
  guid: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  labels?: Maybe<Array<SearchPublicProductLabels>>;
  multiplier: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  properties: SearchPublicProductEligibilities;
  serviceType: SearchPublicProductServiceType;
};

export type SearchPublicProductCategory = {
  __typename?: 'SearchPublicProductCategory';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type SearchPublicProductContentMedia = {
  __typename?: 'SearchPublicProductContentMedia';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SearchPublicProductCosts = {
  __typename?: 'SearchPublicProductCosts';
  assemblyCost: Scalars['Int']['output'];
  automaticCostPerFloor: Scalars['Int']['output'];
  automaticDepositCostPerFloor: Scalars['Int']['output'];
  costPerFloor: Scalars['Int']['output'];
  depositCostPerFloorInCents: Scalars['Int']['output'];
  disassemblyCost: Scalars['Int']['output'];
  packagingCost: Scalars['Int']['output'];
  unpackagingCost: Scalars['Int']['output'];
};

export type SearchPublicProductDimensions = {
  __typename?: 'SearchPublicProductDimensions';
  heightInCm: Scalars['Int']['output'];
  lengthInCm: Scalars['Int']['output'];
  volumeInCm3: Scalars['Int']['output'];
  weightInGr: Scalars['Int']['output'];
  widthInCm: Scalars['Int']['output'];
};

export type SearchPublicProductEligibilities = {
  __typename?: 'SearchPublicProductEligibilities';
  eligibleForDonation: Scalars['Boolean']['output'];
  eligibleForRecycled: Scalars['Boolean']['output'];
  eligibleForRent: Scalars['Boolean']['output'];
  eligibleForSale: Scalars['Boolean']['output'];
};

export type SearchPublicProductLabels = {
  __typename?: 'SearchPublicProductLabels';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SearchPublicProductServiceType = {
  __typename?: 'SearchPublicProductServiceType';
  id: Scalars['String']['output'];
  multiplier: Scalars['Float']['output'];
  operationType: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SearchPublicProductsResponse = {
  __typename?: 'SearchPublicProductsResponse';
  products: Array<SearchPublicProduct>;
  total: Scalars['Float']['output'];
};

/** The allowed sort directions */
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOptions = {
  column?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<SortDirection>;
};

export type TimeInterval = {
  __typename?: 'TimeInterval';
  enabled: Scalars['Boolean']['output'];
  end: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

/** The operation type of the time interval */
export enum TimeIntervalOperationType {
  DepositOperation = 'DEPOSIT_OPERATION',
  RemovalOperation = 'REMOVAL_OPERATION'
}

export type TimeIntervalsByHourEntity = {
  __typename?: 'TimeIntervalsByHourEntity';
  discount: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
  end: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  operationType: TimeIntervalOperationType;
  slotId: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type TimeSlotEntity = {
  __typename?: 'TimeSlotEntity';
  hour: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
};

export type TrackingEvent = {
  context?: InputMaybe<Scalars['JSONObject']['input']>;
  date: Scalars['DateTime']['input'];
  entityId: Scalars['Float']['input'];
  entityType: Scalars['String']['input'];
  event: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id?: Maybe<Scalars['Int']['output']>;
  reserveDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TransactionStatus>;
  timeInterval?: Maybe<Scalars['String']['output']>;
  typeId?: Maybe<Scalars['Int']['output']>;
};

export type TransactionStatus = {
  __typename?: 'TransactionStatus';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type TransportDetailInput = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  distanceKm?: InputMaybe<Scalars['Float']['input']>;
  maxHeightM?: InputMaybe<Scalars['Float']['input']>;
  maxLengthInM?: InputMaybe<Scalars['Float']['input']>;
  maxWeightM?: InputMaybe<Scalars['Float']['input']>;
  maxWidthM?: InputMaybe<Scalars['Float']['input']>;
  volumeM3?: InputMaybe<Scalars['Float']['input']>;
};

/** Transport Data */
export type TransportEntity = {
  __typename?: 'TransportEntity';
  noTransportChargeReason: RemovalNoTransportChargeReasonEntity;
  tollCostInCents: Scalars['Float']['output'];
  transportCost: Scalars['Float']['output'];
  transportType: Scalars['String']['output'];
};

/** The fields you can update from the App */
export type UpdateObjectInput = {
  /** The object's description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The object's name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  apartment?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  original?: InputMaybe<Scalars['String']['input']>;
  postalCodeId?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
};

/** The input to modify the basic information (first name and last name) from the user in the app */
export type UpdateUserBasicInfoInput = {
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** The input to modify the basic information (first name and last name) from the user in the app */
export type UpdateUserEmailInput = {
  newEmail: Scalars['String']['input'];
  oldEmail: Scalars['String']['input'];
};

export type UserCard = {
  __typename?: 'UserCard';
  cardInfo: CardInfoEntity;
  id: Scalars['Float']['output'];
  main: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
};

export type UserCardEntity = {
  __typename?: 'UserCardEntity';
  additionalInfo: AdditionalInfoEntity;
  countryId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  main: Scalars['Boolean']['output'];
  paymentMethod: PaymentMethodEntity;
  token: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
};

export type UserCardsListResponse = {
  __typename?: 'UserCardsListResponse';
  cards: Array<UserCard>;
};

export type UserDonationRequest = {
  __typename?: 'UserDonationRequest';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserEntity = {
  __typename?: 'UserEntity';
  addresses: Array<AddressEntity>;
  countryId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['Int']['output'];
  itemAmount: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  missingProfileInformation: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  profilePicture: ProfilePicture;
};

export type UserTotalDebt = {
  __typename?: 'UserTotalDebt';
  active: Scalars['Boolean']['output'];
  hasDebt: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  totalDebt: Scalars['Float']['output'];
};

export type UtmInput = {
  adGroup?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  landing?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export const namedOperations = {
  Query: {
    GetDashboardAnnualMetrics: 'GetDashboardAnnualMetrics',
    GetDashboardMetrics: 'GetDashboardMetrics',
    GetDisabledCalendar: 'GetDisabledCalendar',
    GetCalendar: 'GetCalendar',
    GetTimeIntervals: 'GetTimeIntervals'
  }
}