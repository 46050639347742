import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

type ListParams = {
  countryCode: string
}

export function create(formData: FormData, params: ListParams): Promise<void> {
  const config = {
    headers: {
      'X-Request-Origin': 'backoffice|proposal'
    },
    params: snakecaseKeys(params, { deep: true })
  }
  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/admin/operation/proposal/deposit`, formData, config)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
