import { TransportTypesPricing } from '../constants'
import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'
import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/getByTransportAndService'
import { GetProposalByIdSuccess, SetNewProposalFromScratch } from './proposals'
import { Services } from '../../../projectApi/TransactionHandler/ServiceType/common'
import { GetProspectRequest } from './prospect'
import { TransportDetail } from '../../../projectApi/TransactionHandler/Compute/cost'

export enum TransportTypes {
  SET_CUSTOM_TRANSPORT_COST = 'NEW_PROPOSAL_V2/TRANSPORT/SET_CUSTOM_TRANSPORT_COST',
  FETCH_COST_REQUEST = 'NEW_PROPOSAL_V2/TRANSPORT/FETCH_COST_REQUEST',
  FETCH_COST_SUCCESS = 'NEW_PROPOSAL_V2/TRANSPORT/FETCH_COST_SUCCESS',
  FETCH_COST_FAILURE = 'NEW_PROPOSAL_V2/TRANSPORT/FETCH_COST_FAILURE',
  GET_SERVICE_TYPES_REQUEST = 'NEW_PROPOSAL_V2/TRANSPORT/GET_SERVICE_TYPES_REQUEST',
  GET_SERVICE_TYPES_SUCCESS = 'NEW_PROPOSAL_V2/TRANSPORT/GET_SERVICE_TYPES_SUCCESS',
  GET_SERVICE_TYPES_FAILURE = 'NEW_PROPOSAL_V2/TRANSPORT/GET_SERVICE_TYPES_FAILURE',
  SET_SERVICE_TYPE = 'NEW_PROPOSAL_V2/TRANSPORT/SET_SERVICE_TYPE',
  RESET_ITEMS_BY_SOLUTION = 'NEW_PROPOSAL_V2/TRANSPORT/RESET_ITEMS_BY_SOLUTION',
  GET_TRANSPORT_MODE_BY_SERVICE_REQUEST = 'NEW_PROPOSAL_V2/TRANSPORT/GET_TRANSPORT_MODE_BY_SERVICE_REQUEST',
  GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS = 'NEW_PROPOSAL_V2/TRANSPORT/GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS',
  GET_TRANSPORT_MODE_BY_SERVICE_FAILURE = 'NEW_PROPOSAL_V2/TRANSPORT/GET_TRANSPORT_MODE_BY_SERVICE_FAILURE',
  SET_TRANSPORT_MODE = 'NEW_PROPOSAL_V2/TRANSPORT/SET_TRANSPORT_MODE',
  GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST = 'NEW_PROPOSAL_V2/TRANSPORT/GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST',
  GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS = 'NEW_PROPOSAL_V2/TRANSPORT/GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS',
  GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE = 'NEW_PROPOSAL_V2/TRANSPORT/GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE',
  SET_DEPOSIT = 'NEW_PROPOSAL_V2/TRANSPORT/SET_DEPOSIT'
}

export interface SetCustomTransportCost {
  type: TransportTypes.SET_CUSTOM_TRANSPORT_COST
  payload: {
    transportTypePricing: TransportTypesPricing
    newCost: number
  }
}

export interface FetchCostRequest {
  type: TransportTypes.FETCH_COST_REQUEST
}

export interface FetchCostSuccess {
  type: TransportTypes.FETCH_COST_SUCCESS
  payload: {
    fetchedCost: number
    cost: number
    where: string
    transportDetail: TransportDetail
  }
}

export interface FetchCostFailure {
  type: TransportTypes.FETCH_COST_FAILURE
  payload: {
    error: string
  }
}

export type FetchCostActions = FetchCostRequest | FetchCostSuccess | FetchCostFailure

export interface GetServiceTypesRequest {
  type: TransportTypes.GET_SERVICE_TYPES_REQUEST
}

export interface GetServiceTypesSuccess {
  type: TransportTypes.GET_SERVICE_TYPES_SUCCESS
  payload: {
    serviceTypes: Services[]
  }
}

export interface GetServiceTypesFailure {
  type: TransportTypes.GET_SERVICE_TYPES_FAILURE
  payload: {
    error: string
  }
}

export type GetServiceTypesActions = GetServiceTypesRequest | GetServiceTypesSuccess | GetServiceTypesFailure

export interface SetServiceType {
  type: TransportTypes.SET_SERVICE_TYPE
  payload: {
    multiplier: number
    serviceType: string
    resetInitialItems?: boolean
  }
}

export interface ResetItemsBySolution {
  type: TransportTypes.RESET_ITEMS_BY_SOLUTION
  payload: {
    resetInitialItems?: boolean
  }
}

export interface GetTransportModeByServiceRequest {
  type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST
  payload: {
    removeSelectedDeposit: boolean
  }
}

export interface GetTransportModeByServiceSuccess {
  type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS
  payload: {
    transportModes: TransportMode[]
  }
}

export interface GetTransportModeByServiceFailure {
  type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_FAILURE
  payload: {
    error: string
  }
}

export type GetTransportModeByServiceActions =
  | GetTransportModeByServiceRequest
  | GetTransportModeByServiceSuccess
  | GetTransportModeByServiceFailure

export interface SetTransportMode {
  type: TransportTypes.SET_TRANSPORT_MODE
  payload: {
    transportMode: number
    requireAddressOrigin: boolean
  }
}

export interface GetDepositByTransportModeRequest {
  type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST
}

export interface GetDepositByTransportModeSuccess {
  type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS
  payload: {
    deposits: Deposit[]
  }
}

export interface GetDepositByTransportModeFailure {
  type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE
  payload: {
    error: string
  }
}

export type GetDepositByTransportModeActions =
  | GetDepositByTransportModeRequest
  | GetDepositByTransportModeSuccess
  | GetDepositByTransportModeFailure

export interface SetDeposit {
  type: TransportTypes.SET_DEPOSIT
  payload: {
    deposit: number
  }
}

export type TransportActions =
  | SetCustomTransportCost
  | FetchCostActions
  | GetServiceTypesActions
  | SetServiceType
  | ResetItemsBySolution
  | GetTransportModeByServiceActions
  | SetTransportMode
  | GetDepositByTransportModeActions
  | SetDeposit
  | GetProposalByIdSuccess
  | GetProspectRequest
  | SetNewProposalFromScratch
