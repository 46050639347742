import types from '../types/objectChanges'

const LIMIT = 10

const initialRoot = {
  error: '',
  loading: false,
  objectChanges: [],
  params: {
    limit: LIMIT,
    offset: 0,
    column: 'id',
    order: 'desc'
  },
  sorter: {
    order: 'descend',
    field: 'RealID',
    columnKey: 'RealID'
  },
  filter: {},
  searchFilters: [
    {
      key: 'id',
      text: ''
    }
  ],
  pagination: {
    current: 1,
    total: 0,
    pageSize: LIMIT
  }
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.GET_OBJECT_CHANGES_FROM_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.GET_OBJECT_CHANGES_FROM_ID_SUCCESS:
      return {
        ...state,
        objectChanges: action.payload.objectChanges,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        },
        loading: false
      }
    case types.GET_OBJECT_CHANGES_FROM_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.CLEAN_FILTERS:
      return {
        ...state,
        params: initialRoot.params,
        filter: initialRoot.filter,
        searchFilters: initialRoot.searchFilters,
        pagination: initialRoot.pagination
      }
    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      }
    case types.SET_SORTER:
      return {
        ...state,
        sorter: action.payload.sorter
      }
    case types.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload.pagination
      }
    case types.SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    default:
      return state
  }
}

export default root
