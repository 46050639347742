import { Reducer } from 'redux'
import { DepositStatus } from '../../../projectApi/TransactionHandler/Operation/list'
import {
  DepositsValidationTableAction,
  DepositsValidationTableState,
  GET_DEPOSITS_FAILURE,
  GET_DEPOSITS_REQUEST,
  GET_DEPOSITS_SUCCESS
} from '../types/table'

const initialState: DepositsValidationTableState = {
  deposits: [],
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'userId', text: '' },
    { key: 'user_name', text: '' },
    { key: 'user_last_name', text: '' }
  ],
  categoryFilter: {
    status: [DepositStatus.DEPOSITED, DepositStatus.VALIDATED]
  },
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  dateRangeFilters: [
    { key: 'datetime', startDate: null, endDate: null },
    { key: 'createdAt', startDate: null, endDate: null }
  ],
  loading: false,
  error: ''
}

const DepositsValidationReducer: Reducer<DepositsValidationTableState, DepositsValidationTableAction> = (
  state = initialState,
  action
): DepositsValidationTableState => {
  switch (action.type) {
    case GET_DEPOSITS_REQUEST:
      return {
        ...state,
        loading: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        categoryFilter: {
          status: action.payload.categoryFilter?.status.length
            ? action.payload.categoryFilter?.status
            : initialState.categoryFilter.status
        },
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }

    case GET_DEPOSITS_SUCCESS:
      return {
        ...state,
        loading: false,
        deposits: action.payload.deposits,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_DEPOSITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default DepositsValidationReducer
