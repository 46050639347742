import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'

export type ProposalMetricsKeys = 'total' | 'quantity_converted_proposals' | 'quantity_current_proposals'

interface APIResponse {
  total: number
  quantity_converted_proposals: number
  quantity_current_proposals: number
}

type Response = CamelCasedPropertiesDeep<APIResponse>
export type QuantityProposalMetrics = CamelCasedPropertiesDeep<APIResponse>

export interface MetricsParams {
  dateFrom?: Moment
  dateTo?: Moment
}

export function metrics({ dateFrom, dateTo }: MetricsParams): Promise<Response> {
  const params = {
    date_from: dateFrom?.format('YYYY-MM-DD'),
    date_to: dateTo?.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/proposal/metrics`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
