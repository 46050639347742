import React from 'react'
import styles from './MetricGroup.module.scss'

type ItemProps = {
  icon: JSX.Element
  labelBold: string
  labelRegular?: string | number
  details?: string | number
}

export const Item: React.FC<ItemProps> = ({ icon, labelBold, labelRegular, details }: ItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.icon}>{icon} </div>
        <div className={styles.content}>
          <span>{labelBold}</span>
          {labelRegular}
          <div className={styles.details}>{details}</div>
        </div>
      </div>
    </div>
  )
}

type MetricGroupProps = {
  children: React.ReactNode
  style?: React.CSSProperties
}

const MetricGroup = ({ children, style }: MetricGroupProps) => {
  return (
    <div className={styles.MetricGroup} style={style}>
      {children}
    </div>
  )
}

export default MetricGroup
