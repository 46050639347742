const toSnake = (s) => {
  return s
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}

const isArray = function (a) {
  return Array.isArray(a)
}

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

export const keysToSnake = function (o) {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach((k) => {
      n[toSnake(k)] = keysToSnake(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i)
    })
  }

  return o
}
