import React, { Component } from 'react'
import { URL_BASE_CATEGORIES } from '../../endpoints'
import { Modal, Spin } from 'antd'
import Dropzone from 'react-dropzone'
import { apiPrivate } from '../../api'

const styles = {
  placeholder: {
    display: 'inline-block'
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inlineSelect: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '70%'
  },
  cont: {
    marginBottom: '6px'
  },
  dropzone: {
    display: 'flex',
    height: '200px',
    margin: 'auto',
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '5px',
    marginTop: '6px'
  },
  dropzoneContainer: {
    margin: 'auto',
    textAlign: 'center'
  },
  dropzoneHeader: {
    margin: 'auto',
    fontSize: '20px'
  },

  dropzoneText: {
    fontSize: '12px'
  },
  imgBig: {
    maxHeight: '190px',
    maxWidth: 'inherit'
  },
  centerThings: {
    width: '95%',
    textAlign: 'center'
  }
}

const initialState = {
  photo: null,
  uploading: false
}

class AddPanel extends Component {
  constructor(props) {
    super(props)

    this.state = initialState

    this.handleClose = this.handleClose.bind(this)
    this.changeName = this.changeName.bind(this)
    this.add = this.add.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  handleClose() {
    this.setState({
      photo: null,
      uploading: false
    })

    this.props.handleClose()
  }

  changeName(event, value) {
    this.props.changeName(event.target.value)
  }

  add() {
    if (this.state.photo === null) {
      alert('Debes agregar una foto')
      return
    }

    const NewCategory = new FormData()

    if (
      this.state.photo.type === 'image/png' ||
      this.state.photo.type === 'image/jpg' ||
      this.state.photo.type === 'image/jpeg'
    ) {
      NewCategory.append('category_picture', this.state.photo)
    } else {
      alert('El formato de la imagen es erróneo')
      return
    }

    NewCategory.append('father', this.props.parentID)
    NewCategory.append('description', this.props.description)

    this.setState({
      uploading: true
    })

    return apiPrivate
      .post(URL_BASE_CATEGORIES + '/category', NewCategory)
      .then((response) => {
        this.props.handleClose()
      })
      .catch((error) => {
        if (error.response.data.description) {
          alert(error.response.data.description)
        }

        this.setState({
          uploading: false
        })
      })
  }

  onDrop(file) {
    this.setState({
      photo: file[0]
    })
  }

  render() {
    const accept = {
      'image/*': ['.png', '.jpeg', '.jpg']
    }
    return (
      <Modal
        title="Agregar Categoría"
        visible={this.props.open}
        onOk={this.add}
        onCancel={this.handleClose}
        okText={'Agregar'}
        cancelText={'Cancelar'}>
        {!this.state.uploading ? (
          <div>
            <div style={styles.cont}>
              <span style={styles.placeholder}>Descripción: </span>
              <input
                style={styles.inline}
                type="text"
                placeholder="Descripción"
                value={this.props.description}
                onChange={this.changeName}
              />
            </div>

            <div>
              <Dropzone multiple={false} accept={accept} onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={styles.dropzone}>
                    <input {...getInputProps()} />{' '}
                    <div style={styles.dropzoneContainer}>
                      {this.state.photo !== null ? (
                        <div>
                          <img style={styles.imgBig} src={URL.createObjectURL(this.state.photo)} />
                        </div>
                      ) : (
                        <div>
                          <p style={styles.dropzoneHeader}>Subida de imagen</p>
                          <p style={styles.dropzoneText}>Arrastra la imagen o haz click aquí</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        ) : (
          <div style={styles.centerThings}>
            <Spin />
          </div>
        )}
      </Modal>
    )
  }
}

export default AddPanel
