import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserABMActionCreators from '../actions/userABM'
import UserDeleteInviteModal from '../components/UserDeleteInviteModal'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { openGuestDeleteInviteModal, deleteInvitation } = bindActionCreators(UserABMActionCreators, dispatch)

  const userABMState = useSelector((state) => state.CorporateUsersABM)

  const closeModal = () => {
    openGuestDeleteInviteModal({ isDeleteInviteModalOpen: false, selectedGuestUser: null })
  }

  const props = {
    userABMState,
    closeModal,
    deleteInvitation
  }

  return <UserDeleteInviteModal {...props} />
}

export default Container
