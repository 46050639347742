import { Reducer } from 'react'
import {
  GET_OBJECT_CHANGES_FAILURE,
  GET_OBJECT_CHANGES_REQUEST,
  GET_OBJECT_CHANGES_SUCCESS,
  ObjectChangesState,
  ObjectsChangesAction
} from '../types/objectChanges'

const LIMIT = 50

const initialState: ObjectChangesState = {
  error: '',
  loading: false,
  objectChanges: [],
  pagination: {
    page: 1,
    total: 0,
    pageSize: LIMIT
  }
}

const ObjectChangesTableReducer: Reducer<ObjectChangesState, ObjectsChangesAction> = (
  state = initialState,
  action
): ObjectChangesState => {
  switch (action.type) {
    case GET_OBJECT_CHANGES_REQUEST:
      return {
        ...state,
        objectChanges: [],
        loading: true,
        error: ''
      }
    case GET_OBJECT_CHANGES_SUCCESS:
      return {
        ...state,
        objectChanges: action.payload.objectChanges,
        loading: false
      }
    case GET_OBJECT_CHANGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default ObjectChangesTableReducer
