import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import FilesModalActionCreator from '../actions/filesModal'
import { PaymentModal, FilesModalProps } from '../components/filesModal'

const PaymentModalContainer = () => {
  const dispatch = useDispatch()

  const filesModalActions = bindActionCreators(FilesModalActionCreator, dispatch)

  const { filesModal } = useSelector((state) => state.Billing)

  const { modalOpen: openModal, loading, error } = filesModal

  const props: FilesModalProps = {
    modalOpen: openModal,
    loading,
    onCancel: () => filesModalActions.openFilesModal({ modalOpen: false }),
    error
  }

  return <PaymentModal {...props} />
}

export default PaymentModalContainer
