import { Reducer } from 'react'
import {
  CLOSE_DELETE_MODAL,
  DELETE_ADDRESS_FAILURE,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DeleteAddressActions,
  DeleteAddressState,
  OPEN_DELETE_MODAL
} from '../types/deleteAddress'

const initialState = {
  userId: 0,
  addressId: 0,
  deleteAddressModalOpen: false,
  loading: false,
  error: ''
}

const DeleteAddressReducer: Reducer<DeleteAddressState, DeleteAddressActions> = (
  state = initialState,
  action
): DeleteAddressState => {
  switch (action.type) {
    case DELETE_ADDRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: ''
      }
    }
    case DELETE_ADDRESS_SUCCESS: {
      return initialState
    }
    case DELETE_ADDRESS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }
    case OPEN_DELETE_MODAL: {
      return {
        ...state,
        userId: action.payload.userId,
        addressId: action.payload.addressId,
        deleteAddressModalOpen: true,
        error: ''
      }
    }
    case CLOSE_DELETE_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default DeleteAddressReducer
