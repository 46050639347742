export const PAYMENT_BY_VALUES = {
  CREDIT_CARD_MP: 'credit_card_mp',
  OFFLINE: 'offline'
}

export const STATUS_FILTERS = [
  {
    text: 'Realizado',
    value: 1
  },
  {
    text: 'Erroneo',
    value: 2
  },
  {
    text: 'En progreso',
    value: 3
  },
  {
    text: 'Rechazado',
    value: 4
  },
  {
    text: 'Devuelto',
    value: 5
  },
  {
    text: 'Cancelado',
    value: 6
  },
  {
    text: 'Desconocido',
    value: 7
  },
  {
    text: 'Pendiente',
    value: 8
  },
  {
    text: 'Pendiente manual',
    value: 9
  }
]
