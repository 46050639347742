import Nav from '../components/Nav'
import React from 'react'
import { useLocation } from 'react-router'

const NavContainer: React.FC<{ hidden: boolean }> = ({ hidden }) => {
  const email = localStorage.getItem('email')
  const { pathname } = useLocation()

  const handleLogout = function logout() {
    localStorage.clear()
    window.location.assign('/login')
  }

  const props = {
    pathname,
    email,
    onLogout: handleLogout,
    hidden
  }

  return <Nav {...props} />
}

export default NavContainer
