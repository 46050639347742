import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import snakecaseKeys from 'snakecase-keys'

export interface Address {
  address_string: string
  apartment: string
  city: string
  comment: string
  floor: string
  number: string
  postal_code: string
  province: string
  street: string
}

export interface APIDeposit {
  country_id: string
  id: number
  name: string
  address: Address
}

export interface APIDescription {
  deposits: APIDeposit[]
}

export interface APIResponse {
  description?: APIDescription
  success: string
}

export type Deposit = CamelCasedPropertiesDeep<APIDeposit>
export type DepositsResponse = CamelCasedPropertiesDeep<APIDescription>

interface GetByIdParams {
  countryCode: CountryIdCode
}

export function getByTransportAndService(
  serviceType: string,
  transportMode: string | number,
  params: GetByIdParams
): Promise<DepositsResponse> {
  const config = { params: snakecaseKeys({ ...params }) }
  return apiPrivate
    .get(URL_BASE_TRANSACTIONS + `/serviceType/${serviceType}/transportMode/${transportMode}/deposit`, config)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener el deposito')
      if (!response.data.description?.deposits) throw new Error('Respuesta incorrecta al obtener el deposito')
      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
