import React from 'react'
import styles from './pickingsMetrics.module.scss'
import StatusCard, { StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { StatusCardContainer } from '../../../components/StatusCard/subComponents/StatusCardContainer'
import { PickingMetrics } from '../types/pickingsMetrics'
import { formatNumber } from '../../../utils/formatNumber'

export type DepositsMetricsProps = {
  metrics: PickingMetrics[]
  fetchingMetrics: boolean
}

const PickingsMetrics: React.FC<DepositsMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const lengthSkeletons = Object.keys(mapOfPickingsMetricTypes).length
  return (
    <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={lengthSkeletons}>
      {metrics.map(({ id, metric, quantity }) => (
        <StatusCard
          key={id}
          type={mapOfPickingsMetricTypes[id]}
          customClassname={styles.card}
          titleCard={metric}
          total={formatNumber(quantity, 0, true)}
        />
      ))}
    </StatusCardContainer>
  )
}

export default PickingsMetrics

const mapOfPickingsMetricTypes: { [key: string]: StatusCardTypes } = {
  total: 'infoSolid',
  quantityActivePickings: 'warning',
  quantityClosedPickings: 'success',
  quantityCancelledPickings: 'danger'
}

export const mapOfPickingsMetrics: { [key: string]: string } = {
  total: 'Búsquedas totales',
  quantityActivePickings: 'Activas',
  quantityClosedPickings: 'Cerradas',
  quantityCancelledPickings: 'Canceladas'
}
