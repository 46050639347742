import { Moment } from 'moment'
import { BaseOption } from '../../../../components/Select/Select'
import { CleanStates, GetTransactionDataAction } from './EditDepositView'

type InputProps<T, U> = {
  list: T[]
  selected: U
  loading: boolean
}

export type Option = BaseOption<number>

export type Timeslot = {
  hour: number
  id: number
  minute: number
}

export type TimeInterval = {
  enabled: number
  end: number
  id: number
  start: number
}

export interface CalendarState {
  datetime: Moment
  unavailableDates: InputProps<Moment, Moment>
  timeslots: InputProps<Timeslot, number>
  timeIntervals: InputProps<TimeInterval, number>
}

export const GET_UNAVAILABLE_DATES_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_UNAVAILABLE_DATES_REQUEST'
export const GET_UNAVAILABLE_DATES_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_UNAVAILABLE_DATES_SUCCESS'
export const GET_UNAVAILABLE_DATES_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_UNAVAILABLE_DATES_FAILURE'

export interface GetUnavailableDatesRequest {
  type: typeof GET_UNAVAILABLE_DATES_REQUEST
}

export interface GetUnavailableDatesSuccess {
  type: typeof GET_UNAVAILABLE_DATES_SUCCESS
  payload: { unavailableDates: Moment[] }
}

export interface GetUnavailableDatesFailure {
  type: typeof GET_UNAVAILABLE_DATES_FAILURE
}

export type GetUnavailableDatesActions =
  | GetUnavailableDatesRequest
  | GetUnavailableDatesSuccess
  | GetUnavailableDatesFailure

export const GET_TIMESLOTS_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_TIMESLOTS_REQUEST'
export const GET_TIMESLOTS_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_TIMESLOTS_SUCCESS'
export const GET_TIMESLOTS_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_TIMESLOTS_FAILURE'

export interface GetTimeslotsRequest {
  type: typeof GET_TIMESLOTS_REQUEST
}

export interface GetTimeslotsSuccess {
  type: typeof GET_TIMESLOTS_SUCCESS
  payload: { timeslots: Timeslot[]; selectFirstTimeslot: boolean }
}

export interface GetTimeslotsFailure {
  type: typeof GET_TIMESLOTS_FAILURE
}

export type GetTimeslotsActions = GetTimeslotsRequest | GetTimeslotsSuccess | GetTimeslotsFailure

export const GET_TIMEINTERVALS_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_TIMEINTERVALS_REQUEST'
export const GET_TIMEINTERVALS_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_TIMEINTERVALS_SUCCESS'
export const GET_TIMEINTERVALS_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_TIMEINTERVALS_FAILURE'

export interface GetTimeIntervalsRequest {
  type: typeof GET_TIMEINTERVALS_REQUEST
}

export interface GetTimeIntervalsSuccess {
  type: typeof GET_TIMEINTERVALS_SUCCESS
  payload: { timeIntervals: TimeInterval[] }
}

export interface GetTimeIntervalsFailure {
  type: typeof GET_TIMEINTERVALS_FAILURE
}

export type GetTimeIntervalsActions = GetTimeIntervalsRequest | GetTimeIntervalsSuccess | GetTimeIntervalsFailure

export const SET_AVAILABLE_DATE = 'DEPOSIT/EDIT_DEPOSIT/SET_AVAILABLE_DATE'
export const SET_TIMESLOT = 'DEPOSIT/EDIT_DEPOSIT/SET_TIMESLOT'
export const SET_TIME_INTERVALS = 'DEPOSIT/EDIT_DEPOSIT/SET_TIMEINTERVALS'

export interface SetAvailableDate {
  type: typeof SET_AVAILABLE_DATE
  payload: { availableDate: Moment }
}

export interface SetTimeslot {
  type: typeof SET_TIMESLOT
  payload: { timeslot: number }
}

export interface SetTimeInterval {
  type: typeof SET_TIME_INTERVALS
  payload: { timeInterval: number }
}

export type SetTimesActions = SetAvailableDate | SetTimeslot | SetTimeInterval

export type CalendarActions =
  | GetUnavailableDatesActions
  | GetTimeslotsActions
  | GetTimeIntervalsActions
  | SetTimesActions
  | GetTransactionDataAction
  | CleanStates
