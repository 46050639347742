import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export enum Keys {
  I = 'KeyI',
  ENTER = 'Enter',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ESCAPE = 'Escape'
}

export enum KeyModifier {
  CTRL = 'ctrlKey',
  ALT = 'altKey',
  SHIFT = 'shiftKey',
  META = 'metaKey'
}

type Params = {
  key: Keys
  modifiers: KeyModifier[]
  callback: (event: KeyboardEvent) => void
}

export const useKeyPress = ({ key, modifiers, callback }: Params) => {
  const callbackRef = useRef(callback)
  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const modifiersActive = modifiers.every((m) => event[m])
      if (!modifiersActive) return
      if (event.code === key) {
        callbackRef.current(event)
      }
    },
    [key, modifiers]
  )

  useEffect(() => {
    const targetNode = document
    targetNode && targetNode.addEventListener('keydown', handleKeyPress)

    return () => targetNode && targetNode.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])
}
