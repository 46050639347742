import types from '../types/removePhoto'

const initialRoot = {
  open: false,
  objectId: '',
  photoId: 0,
  deletedDescription: '',
  url: '',
  isLoading: false,
  error: ''
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.SELECT_PHOTO:
      return {
        ...state,
        open: true,
        objectId: action.payload.objectId,
        url: action.payload.url,
        photoId: action.payload.photoId,
        deletedDescription: ''
      }
    case types.SET_DELETED_DESCRIPTION: {
      return {
        ...state,
        deletedDescription: action.payload.deletedDescription
      }
    }
    case types.CANCEL:
      return initialRoot
    case types.REMOVE_PHOTO_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case types.REMOVE_PHOTO_SUCCESS:
      return initialRoot
    case types.REMOVE_PHOTO_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
