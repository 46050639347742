import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import commonActionCreators from '../actions/common'
import paymentMPActionCreators from '../actions/payment-mp'
import Component from '../components/Payment'
import { PaymentScreens } from '../constants'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

export type PaymentProps = {
  paymentScreen: PaymentScreens
  setPaymentScreen: (screen: PaymentScreens) => void
}

const Container = (ownProps: PaymentProps) => {
  const { paymentMp, common, userSearch } = useSelector((state) => state.NewRemoval)

  const dispatch = useDispatch()

  const commonActions = bindActionCreators(commonActionCreators, dispatch)
  const paymentMPActions = bindActionCreators(paymentMPActionCreators, dispatch)

  useEvents(Events.Removal.ADD_SPREEDLY_PM, () =>
    paymentMPActions.getUserSpreedlyPM({ userId: userSearch.user?.RealID || 0 })
  )

  const props = {
    ...ownProps,
    commonState: common,
    paymentMpState: paymentMp,
    commonActions,
    paymentMPActions
  }

  return <Component {...props} />
}
export default Container
