import { Reducer } from 'redux'
import {
  DashboardActions,
  DashboardDateRangeKey,
  DashboardState,
  GetDashboardAnnualMetricsTypes,
  GetDashboardMetricsTypes
} from '../types/Dashboard.types'
import moment from 'moment'

export const FIRST_MONTH_DAY = moment().startOf('month')
export const CURRENT_MONTH_DAY = moment().startOf('day')

const initialState: DashboardState = {
  fetchingMetrics: false,
  fetchingAnnualMetrics: false,
  dateRangeFilter: [
    {
      key: DashboardDateRangeKey.PERIOD_DATE,
      startDate: FIRST_MONTH_DAY,
      endDate: CURRENT_MONTH_DAY
    }
  ],
  metrics: {
    quotedM3: 0,
    realM3: 0,
    totalDepositOperations: 0,
    m3TotalDepositOperations: 0,
    quantityWonDepositOperations: 0,
    m3WonDepositOperations: 0,
    quantityCancelledDepositOperations: 0,
    m3CancelledDepositOperations: 0,
    quantityValidatedDepositOperations: 0,
    m3ValidatedDepositOperations: 0,
    quantityDepositedDepositOperations: 0,
    m3DepositedDepositOperations: 0,
    totalRemovals: 0,
    m3Removals: 0,
    quantityClientsWithFullRemovals: 0,
    quantityWonProspects: 0,
    totalRps: 0,
    m3TotalRps: 0
  },
  annualMetrics: [
    {
      m3WonDepositOperations: 0,
      m3Removals: 0,
      quantityClientsWithFullRemovals: 0,
      quantityNewUsersWithFirstDepositOperation: 0,
      date: ''
    }
  ],
  error: ''
}

const DashboardReducer: Reducer<DashboardState, DashboardActions> = (state = initialState, action): DashboardState => {
  switch (action.type) {
    case GetDashboardMetricsTypes.GET_METRICS_REQUEST:
      return {
        ...state,
        dateRangeFilter: action.payload.dateRangeFilter,
        fetchingMetrics: true
      }
    case GetDashboardMetricsTypes.GET_METRICS_SUCCESS:
      return {
        ...state,
        metrics: action.payload.metrics,
        fetchingMetrics: false
      }
    case GetDashboardMetricsTypes.GET_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    case GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_REQUEST:
      return {
        ...state,
        fetchingAnnualMetrics: true
      }
    case GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_SUCCESS:
      return {
        ...state,
        annualMetrics: action.payload.annualMetrics,
        fetchingAnnualMetrics: false
      }
    case GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_FAILURE:
      return {
        ...state,
        fetchingAnnualMetrics: false
      }
    default:
      return state
  }
}

export default DashboardReducer
