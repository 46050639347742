import React from 'react'
import { Modal } from 'antd'
import styles from './BillPeriodModal.module.scss'

export type BillPeriodModalProps = {
  modalOpen: boolean
  loadingBillingToBill: boolean
  loadingBillPeriod: boolean
  billingToBillTotal: number
  error: string
  handleBillPeriod: () => void
  onCancel: () => void
}

export const BillPeriodModal: React.FC<BillPeriodModalProps> = ({
  modalOpen,
  loadingBillPeriod,
  loadingBillingToBill,
  handleBillPeriod,
  onCancel,
  billingToBillTotal,
  error
}) => {
  const loadingSomething = loadingBillPeriod || loadingBillingToBill
  return (
    <Modal
      title="Facturación del período"
      visible={modalOpen}
      onOk={handleBillPeriod}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: loadingBillPeriod
      }}
      okButtonProps={{
        loading: loadingSomething,
        disabled: loadingSomething || !billingToBillTotal
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p>
        Se crearán las facturas de todos los abonos del período correspondientes a usuarios argentinos no excluidos.
      </p>
      <span>(Cantidad de abonos a facturar: {loadingBillingToBill ? '...' : billingToBillTotal})</span>
      <p className={styles.error}>{error}</p>
    </Modal>
  )
}
