import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import EditObjectModal from '../../Objects/containers/edit'
import RemoveObjectModal from '../../Objects/containers/removeObject'
import ObjectsTableActionCreators from '../actionCreators/objectsTable'
import ObjectsTable, { ObjectsTableProps } from '../components/objectsTable'
import {
  ObjectTableSearchFilterKey,
  ObjectsSearchFilter,
  ObjectsTableCategoryFilter,
  ObjectsTableDateRangeFilter,
  ObjectsTableDateRangeKey,
  ObjectsTableSearchKey,
  ObjectsTableSort,
  ObjectsTableSortKey,
  Pagination
} from '../types/objectsTable'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import OldObjectsActionCreators from '../../Objects/actions/edit'
import OldDeleteObjectActionCreators from '../../Objects/actions/removeObject'
import ReportsActionCreators from '../../Reports/actions'
import { useHistory } from 'react-router'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import markPhotoAsMainActionCreators from '../../Objects/actions/markPhotoAsMain'
import removePhotoActionCreators from '../../Objects/actions/removePhoto'
import DeletePhotoModal from '../../Objects/containers/markPhotoAsMain'
import MarkPhotoAsMainModal from '../../Objects/containers/removePhoto'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { MassiveObjectsEditActionCreators } from '../../MassiveObjectsEdit/actionCreators'
import { MassiveObjectsEditContainer } from '../../MassiveObjectsEdit/container'
import { RequestReportParams } from '../../../projectApi/ObjectAdministration/Object/report'
import CarouselModal from '../../../components/CarouselModal/containers/CarouselModal'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import {
  removeNullishValues,
  updateMultipleDateRangeFilters,
  updateMultipleSearchFilters
} from '../../../components/DynamicTable/queryParamsUtils'
import { Permissions } from '../../UserLogged/permissions'
import { Moment } from 'moment'
import ObjectsMetrics from '../components/objectsMetrics'

type LocationState = {
  withoutRefresh?: boolean
}

const ObjectsTableContainer = () => {
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.ObjectsV2.table)
  const userRole = useSelector((root) => root.UserLogged.userProfile.role)
  const { requestingReport, lastObjectReports } = useSelector((root) => root.Reports)
  const history = useHistory<LocationState>()

  const dispatch = useDispatch()
  const {
    getObjects,
    toggleAllObjects,
    toggleSelectedObject,
    toggleExactLocationCode,
    getStatusObjects,
    clearState,
    getMetricsObjects
  } = bindActionCreators(ObjectsTableActionCreators, dispatch)
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const { getObjectSituations, selectObject } = bindActionCreators(OldObjectsActionCreators, dispatch)
  const openReasonModal = bindActionCreators(OldDeleteObjectActionCreators.openReasonModal, dispatch)
  const requestObjectsReport = bindActionCreators(ReportsActionCreators.requestObjectsReport, dispatch)
  const selectMainPhoto = bindActionCreators(markPhotoAsMainActionCreators.selectMainPhoto, dispatch)
  const selectPhoto = bindActionCreators(removePhotoActionCreators.selectPhoto, dispatch)
  const setMassiveEditModalOpen = bindActionCreators(MassiveObjectsEditActionCreators.setOpen, dispatch)

  const QueryTypes = {
    page: NumberParam,
    id: StringParam,
    sortField: StringParam,
    sortDirection: StringParam,
    identificationCode: StringParam,
    product: StringParam,
    status: StringParam,
    objectSituation: StringParam,
    locationCode: StringParam,
    ownerId: StringParam,
    owner: StringParam,
    exactLocationCode: StringParam,
    location: StringParam,
    createdAtFrom: StringParam,
    createdAtTo: StringParam
  }

  const [query, setQuery] = useQueryParams(QueryTypes)

  const emptyPagination = { ...tableState.pagination, page: 1 }
  const withoutRefresh = history.location.state?.withoutRefresh

  const handleGetObjects = (newParams: {
    pagination?: Pagination
    searchFilters?: ObjectsSearchFilter[]
    categoryFilter?: ObjectsTableCategoryFilter
    sort?: ObjectsTableSort
    silentLoading?: boolean
    dateRangeFilter?: ObjectsTableDateRangeFilter[]
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      sort: newParams.sort || tableState.sort,
      silentLoading: newParams.silentLoading,
      dateRangeFilter: newParams.dateRangeFilter || tableState.dateRangeFilter
    }
    getObjects(actualParams)

    const newQuery = {
      page: actualParams.pagination.page,
      sortDirection: actualParams.sort.direction,
      sortField: actualParams.sort.field,
      status: actualParams.categoryFilter.status[0],
      objectSituation: actualParams.categoryFilter.objectSituation.join(),
      ...searchFiltersToParams(actualParams.searchFilters, true),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilter, undefined, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')
    getMetricsObjects({
      searchFilters: actualParams.searchFilters,
      categoryFilter: actualParams.categoryFilter,
      dateRangeFilter: actualParams.dateRangeFilter
    })
  }

  useEvents(
    [
      Events.Objects.OBJECT_PHOTO_MARKED_AS_MAIN,
      Events.Objects.OBJECT_PHOTO_DELETED,
      Events.Objects.MASSIVE_EDIT_COMPLETED,
      Events.Objects.OBJECT_DELETED,
      Events.Objects.OBJECT_EDITED,
      Events.Objects.OBJECT_TOGGLE_LOCATION
    ],
    () => {
      handleGetObjects({ silentLoading: true })
    }
  )

  /**
   * When we have a redirection to a screen that has a table and have to filter with some parameters, we have to do some tricks to make the event work
   *  - Add a timeout when then event is dispatch, because we can't catch it before the consumer is initialized
   *  - Add something to identify that we don't have to call the getEntity action when entering the screen to not overlap results (in this case, we are using withoutRefresh parameter inside history state)
   */
  useEvents(Events.Objects.OBJECT_REDIRECT_WITH_FILTER, (newParams) => {
    handleGetObjects(newParams)
    history.push('objects', { withoutRefresh: false })
  })

  useEffect(() => {
    getObjectSituations()
    getStatusObjects()
    return () => {
      clearState()
    }
  }, [])

  useEffect(() => {
    if (!withoutRefresh)
      handleGetObjects({
        pagination: { ...emptyPagination, page: query.page || tableState.pagination.page },
        searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
          { key: ObjectTableSearchFilterKey.ID, text: query.id },
          { key: ObjectTableSearchFilterKey.IDENTIFICATION_CODE, text: query.identificationCode },
          { key: ObjectTableSearchFilterKey.PRODUCT, text: query.product },
          { key: ObjectTableSearchFilterKey.LOCATION_CODE, text: query.locationCode },
          { key: ObjectTableSearchFilterKey.OWNER_ID, text: query.ownerId },
          { key: ObjectTableSearchFilterKey.OWNER, text: query.owner },
          { key: ObjectTableSearchFilterKey.EXACT_LOCATION_CODE, text: query.exactLocationCode },
          { key: ObjectTableSearchFilterKey.LOCATION, text: query.location }
        ]),
        dateRangeFilter: updateMultipleDateRangeFilters(
          tableState.dateRangeFilter,
          [
            {
              key: ObjectsTableDateRangeKey.CREATION_DATE,
              startDate: query.createdAtFrom,
              endDate: query.createdAtTo
            }
          ],
          true
        ),
        categoryFilter: {
          ...tableState.categoryFilter,
          objectSituation: query.objectSituation ? [query.objectSituation] : tableState.categoryFilter.objectSituation,
          status: query.status ? [query.status] : tableState.categoryFilter.status
        },
        sort:
          query.sortField && query.sortDirection
            ? {
                ...tableState.sort,
                field: query.sortField as ObjectsTableSortKey,
                direction: query.sortDirection as SortDirection
              }
            : tableState.sort
      })
  }, [countryCode])

  const handlePageChange = (newPage: number) => {
    handleGetObjects({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: ObjectsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetObjects({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleCategoryFilter = (newCategoryFilter: ObjectsTableCategoryFilter) => {
    handleGetObjects({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleSort = (newSort: ObjectsTableSort) => {
    handleGetObjects({ sort: newSort })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => {
      return {
        ...filter,
        text: filter.key === 'location' ? 'true' : ''
      }
    })

    const resetRangeFilters = tableState.dateRangeFilter.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))

    const resetSort: ObjectsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleGetObjects({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters,
      categoryFilter: { status: [], objectSituation: [] },
      dateRangeFilter: resetRangeFilters
    })
  }

  const handleRequestReport = () => {
    const params: RequestReportParams = {
      order: tableState.sort?.direction,
      column: tableState.sort?.field,
      status: tableState.categoryFilter?.status[0],
      objectSituation: tableState.categoryFilter?.objectSituation.join(),
      ...searchFiltersToParams(tableState.searchFilters),
      countryId: countryCode,
      ...dateRangeFiltersToParams(tableState.dateRangeFilter, undefined, false)
    }
    requestObjectsReport({
      lastObjectReports,
      params
    })
  }

  const handleRangePicker = (key: ObjectsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilter.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetObjects({ dateRangeFilter: newRangeFilters, pagination: emptyPagination })
  }

  const props: ObjectsTableProps = {
    objects: tableState.objects,
    loadingObjects: tableState.loadingObjects,
    error: tableState.error,
    objectStatus: tableState.objectStatus,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    selectedObjects: tableState.selectedObjects,
    sort: tableState.sort,
    hasPermissionToDelete: !!userRole?.permissions?.some(
      (permission) => permission.description === Permissions.ModificarObjeto
    ),
    isReportAvailable:
      requestingReport ||
      tableState.dateRangeFilter.some(
        (filter) => filter.key === ObjectsTableDateRangeKey.CREATION_DATE && filter.startDate && filter.endDate
      ),
    handleOpenMassiveEditModal: (selectedObjects) => setMassiveEditModalOpen(true, selectedObjects),
    handleToggleSelectedObject: toggleSelectedObject,
    handleToggleAllObjects: toggleAllObjects,
    handleMarkPhotoAsMain: selectMainPhoto,
    handleRemovePhoto: selectPhoto,
    handleRequestReport,
    handleViewObjectChanges: (objectId) => history.push(`/objectChanges/${objectId}`),
    handleDeleteObject: openReasonModal,
    handleModifyObject: (obj) => selectObject(obj),
    handleOpenQRCodeModal: openQRModal,
    handleSort,
    handleResetFilters,
    handleSearch,
    handleCategoryFilter,
    toggleExactLocationCode,
    dateRangeFilters: tableState.dateRangeFilter,
    handleRangePicker
  }

  return (
    <>
      <ObjectsMetrics metrics={tableState.metrics} fetchingMetrics={tableState.loadingMetrics} />
      <ObjectsTable {...props} />
      <EditObjectModal />
      <RemoveObjectModal />
      <DeletePhotoModal />
      <MarkPhotoAsMainModal />
      <MassiveObjectsEditContainer />
      <CarouselModal />
    </>
  )
}

export default ObjectsTableContainer
