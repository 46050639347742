import { AxiosResponse } from 'axios'
import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APICard } from '../../AccountManager/Users/common'
import { convertArrayForGetRequests, convertToMoment, formatDates } from '../../utils'
import { TransactionStatusId, TransactionTypeId } from '../Transaction/common'
import { OperationStatus } from '../Operation/Removals/list'

interface APITransactionObject {
  DeletedDescription: string | null
  Description: string
  HeightInCm: number
  ID: string
  IdentificationCodes: {
    ID: number
    Code: string
    Number: number
    Actual: boolean
  }[]
  LengthInCm: number
  Location: {
    Code: string
    Description: string
    DepositID: number
    Deposit: {
      Address: string
      Addresses: APIAddress
      Name: string
      country_id: CountryIdCode
    }
  }
  Name: string
  NeedsAuthorization: boolean
  ObjectStatus: { ID: string; Description: string }
  OwnerData: { Name: string; LastName: string; GUID: string; Email: string }
  Photos: { ID: number; MainPhoto: boolean; Url: string }[]
  Product: {
    Deprecated: boolean
    Description: string
    DisassemblyCost: number
    HeightInCm: number
    ID: string
    LengthInCm: number
    MaxVolumeDeviation: number
    MinVolumeDeviation: number
    Multiplier: number
    PackagingCostInCents: number
    PackagingTimeInMinutes: number
    ProdByCountry: { Name: string; CountryId: CountryIdCode; ProductId: number }
    RealID: number
    ShowInSearch: boolean
    UnpackagingTimeInMinutes: number
    VolumeInCm3: number
    WeightInGr: number
    WidthInCm: number
  }
  ServiceTypeID: string
  RealId: number
  RentEnabled: boolean
  RentPrice: number
  VolumeInCm3: number
  WeightInGr: number
  WidthInCm: number
}

interface APIAddress {
  Address: string
  Apartment: string
  City: string
  Comment: string
  Floor: string
  ID: number
  InDeliveryRange: boolean
  Latitude: number
  Longitude: number
  MainAddress: boolean
  Number: string
  Original: string
  PostalCode: { Allowed: boolean; PostalCode: string }
  Province: string
}
interface APITransaction {
  Card: Pick<APICard, 'ID' | 'LastDigits' | 'Main' | 'Type' | 'TypePhoto'>
  Client: {
    CreatedAt: string
    DeletedAt: string | null
    Email: { Email: string }
    ID: string
    LastName: string
    Name: string
    PayDay: number
    Phone: string
    ProfilePicture: { ID: number; Url: string }
    RealID: number
    UpdatedAt: string
  }
  ClientAddress: APIAddress
  Comments: string
  ConfirmationDate: string
  CreationDate: string
  Destination: string
  GeneralInformation: string
  ID: string
  M3Total: number
  Objects: APITransactionObject[] // TODO
  Origin: string
  Price: number
  Problems: string
  RealID: number
  Realized: boolean
  Reserve: {
    Date: string
    ID: number
    TimeSlot: {
      Hour: number
      Minute: number
      ID: number
    }
    TimeSlotID: number
  }
  ReserveDate: string
  TransactionStatus: { Description: string; ID: TransactionStatusId }
  TransactionType: { Description: string; ID: TransactionTypeId }
  TripID: number
  Operation: APIOperation
  Metrics: {
    ItemsQuoted: number
    QuantityM3Items: number
    QuantityM3Objects: number
    QuantityObjects: number
  }
  forecast: {
    elements: number
    m3: number
  }
  type_transaction: TypeTransaction
}

export enum OperationType {
  REMOVAL = 'removal_operations',
  DEPOSIT = 'deposit_operations'
}

export enum TypeTransaction {
  COLECTA_EMPRESA = 'company_pickup',
  ENTREGA_CLIENTE = 'client_dropoff',
  ENTREGA_EMPRESA = 'company_dropoff',
  COLECTA_CLIENTE = 'client_pickup'
}

export type Operation = {
  additionalCostInCents: number
  countryId: CountryIdCode
  discountCode: string
  id: number
  operationCoreId: number
  operationCoreType: OperationType
  status: OperationStatus
  transportCostInCents: number
  userId: number
}

type APIOperation = {
  AdditionalCostInCents: number
  CountryID: CountryIdCode
  DiscountCode: string
  ID: number
  OperationCoreID: number
  OperationCoreType: OperationType
  Status: OperationStatus
  TransportCostInCents: number
  UserId: number
}

interface APIResponse {
  description: {
    quantity: number
    transactions: APITransaction[]
  }
  success: 'true' | 'false' // yes, it's a string
}

type FormattedTransactionObject = {
  deletedDescription: string | null
  description: string
  id: number
  identificationCodes: {
    id: number
    code: string
    number: number
    actual: boolean
  }[]
  location: {
    code: string
    description: string
    depositID: number
    deposit: {
      address: CamelCasedPropertiesDeep<APIAddress> & { fullAddressString: string }
      name: string
      countryId: CountryIdCode
    }
  }
  name: string
  needsAuthorization: boolean
  objectStatus: { id: string; description: string }
  ownerData: { name: string; lastName: string; guid: string; email: string }
  photos: { id: number; mainPhoto: boolean; url: string }[]
  product: {
    deprecated: boolean
    description: string
    disassemblyCost: number
    guid: string
    maxVolumeDeviation: number
    minVolumeDeviation: number
    multiplier: number
    packagingCostInCents: number
    packagingTimeInMinutes: number
    prodByCountry: { name: string; countryId: CountryIdCode; productId: number }
    id: number
    showInSearch: boolean
    unpackagingTimeInMinutes: number
    dimensions: {
      volumeInCm3: number
      weightInGr: number
      widthInCm: number
      lengthInCm: number
      heightInCm: number
    }
  }
  serviceTypeID: string
  guid: string
  rentEnabled: boolean
  rentPrice: number
  dimensions: {
    volumeInCm3: number
    weightInGr: number
    widthInCm: number
    lengthInCm: number
    heightInCm: number
  }
}

export type Transaction = {
  card: { id: number; lastDigits: string; main: boolean; type: string; typePhoto: string }
  client: {
    id: number
    guid: string
    email: string
    name: string
    lastName: string
    payday: number
    phone: string
    profilePicture: { id: number; url: string }
    address: {
      address: string
      apartment: string
      city: string
      comment: string
      floor: string
      id: number
      inDeliveryRange: boolean
      latitude: number
      longitude: number
      mainAddress: boolean
      number: string
      original: string
      postalCode: { allowed: boolean; code: string }
      province: string
    }
  }
  comments: string
  confirmationDate: Moment | null
  creationDate: Moment | null
  destination: string
  generalInformation: string
  guid: string
  m3Total: number
  objects: FormattedTransactionObject[] // TODO
  origin: string
  price: number
  problems: string
  id: number
  realized: boolean
  reservation: {
    date: Moment | null
    id: number
    timeSlot: {
      hour: number
      minute: number
      id: number
    }
  }
  status: { description: string; id: TransactionStatusId }
  type: { description: string; id: TransactionTypeId }
  tripId: number
  operation: Operation
  forecastMetrics: TransactionForecastMetrics
  forecast: {
    elements: number
    m3: number
  }
  typeTransaction: TypeTransaction
}
export type TransactionForecastMetrics = {
  quantityItems: number
  quantityM3Items: number
  quantityM3Objects: number
  quantityObjects: number
}

type Response = {
  transactions: Transaction[]
  total: number
}

export type TransactionsListSortKey =
  | 'ID'
  | 'transaction_type_id'
  | 'creation_date'
  | 'reserve_date'
  | 'confirmation_date'
  | 'transaction_status_id'
  | 'client_id'

interface ListParams {
  limit?: number
  offset?: number
  sort?: TransactionsListSortKey
  order?: SortDirection
  typeTransaction?: string
  status?: TransactionStatusId[]
  creationDateFrom?: Moment
  creationDateTo?: Moment
  reservationDateFrom?: Moment // reserve
  reservationDateTo?: Moment
  confirmationDateFrom?: Moment
  confirmationDateTo?: Moment
  client?: string
  clientId?: number
  id?: string | number
  operationType?: OperationType
  operationId?: number
  conflict?: boolean
  format?: string
  now: Moment
}

export function list(params: ListParams): Promise<Response> {
  const {
    limit,
    offset,
    sort,
    order,
    typeTransaction,
    status,
    creationDateFrom,
    creationDateTo,
    reservationDateFrom,
    reservationDateTo,
    confirmationDateFrom,
    confirmationDateTo,
    client,
    clientId,
    id,
    operationType,
    operationId,
    conflict,
    format,
    now
  } = params
  const formattedParams = {
    Limit: limit,
    Offset: offset,
    Column: sort,
    Order: order,
    client,
    client_id: clientId,
    id,
    conflict,
    type_transaction: typeTransaction,
    status: convertArrayForGetRequests(status),
    operation_type: operationType,
    operation_id: operationId,
    ...formatDates({
      dates: {
        creation_date_from: creationDateFrom,
        creation_date_to: creationDateTo,
        reserve_date_from: reservationDateFrom,
        reserve_date_to: reservationDateTo,
        confirmation_date_from: confirmationDateFrom,
        confirmation_date_to: confirmationDateTo
      }
    }),
    ...formatDates({
      format: 'YYYY-MM-DDTHH:mm:ssZ',
      dates: {
        now
      }
    }),
    format
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/transactions`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (params.format === 'csv') return { total: 0, transactions: [] }
      if (response.data.success !== 'true' || !response.data.description.transactions)
        throw new Error('Respuesta incorrecta al obtener los viajes')

      const camelResponse: Response = {
        total: response.data.description.quantity,
        transactions: response.data.description.transactions.map((tx) => ({
          card: {
            id: tx.Card.ID,
            lastDigits: tx.Card.LastDigits,
            main: tx.Card.Main,
            type: tx.Card.Type,
            typePhoto: tx.Card.TypePhoto
          },
          client: {
            id: tx.Client.RealID,
            guid: tx.Client.ID,
            email: tx.Client.Email.Email,
            name: tx.Client.Name,
            lastName: tx.Client.LastName,
            payday: tx.Client.PayDay,
            phone: tx.Client.Phone,
            profilePicture: { id: tx.Client.ProfilePicture.ID, url: tx.Client.ProfilePicture.Url },
            address: {
              address: tx.ClientAddress.Address,
              apartment: tx.ClientAddress.Apartment,
              city: tx.ClientAddress.City,
              comment: tx.ClientAddress.Comment,
              floor: tx.ClientAddress.Floor,
              id: tx.ClientAddress.ID,
              inDeliveryRange: tx.ClientAddress.InDeliveryRange,
              latitude: tx.ClientAddress.Latitude,
              longitude: tx.ClientAddress.Longitude,
              mainAddress: tx.ClientAddress.MainAddress,
              number: tx.ClientAddress.Number,
              original: tx.ClientAddress.Original,
              postalCode: {
                allowed: tx.ClientAddress.PostalCode.Allowed,
                code: tx.ClientAddress.PostalCode.PostalCode
              },
              province: tx.ClientAddress.Province
            }
          },
          comments: tx.Comments,
          confirmationDate: convertToMoment(tx.ConfirmationDate),
          creationDate: convertToMoment(tx.CreationDate),
          destination: tx.Destination,
          generalInformation: tx.GeneralInformation,
          guid: tx.ID,
          m3Total: tx.M3Total,
          objects: tx.Objects.map((obj) => ({
            deletedDescription: obj.DeletedDescription,
            description: obj.Description,
            dimensions: {
              heightInCm: obj.HeightInCm,
              lengthInCm: obj.LengthInCm,
              volumeInCm3: obj.VolumeInCm3,
              weightInGr: obj.WeightInGr,
              widthInCm: obj.WidthInCm
            },
            id: obj.RealId,
            identificationCodes: obj.IdentificationCodes.map((code) => ({
              id: code.ID,
              code: code.Code,
              number: code.Number,
              actual: code.Actual
            })),
            location: {
              code: obj.Location.Code,
              description: obj.Location.Description,
              depositID: obj.Location.DepositID,
              deposit: {
                address: {
                  fullAddressString: obj.Location.Deposit.Address, // TODO
                  address: obj.Location.Deposit.Addresses.Address,
                  apartment: obj.Location.Deposit.Addresses.Apartment,
                  city: obj.Location.Deposit.Addresses.City,
                  comment: obj.Location.Deposit.Addresses.Comment,
                  floor: obj.Location.Deposit.Addresses.Floor,
                  id: obj.Location.Deposit.Addresses.ID,
                  inDeliveryRange: obj.Location.Deposit.Addresses.InDeliveryRange,
                  latitude: obj.Location.Deposit.Addresses.Latitude,
                  longitude: obj.Location.Deposit.Addresses.Longitude,
                  mainAddress: obj.Location.Deposit.Addresses.MainAddress,
                  number: obj.Location.Deposit.Addresses.Number,
                  original: obj.Location.Deposit.Addresses.Original,
                  postalCode: {
                    allowed: obj.Location.Deposit.Addresses.PostalCode.Allowed,
                    postalCode: obj.Location.Deposit.Addresses.PostalCode.PostalCode
                  },
                  province: obj.Location.Deposit.Addresses.Province
                },
                name: obj.Location.Deposit.Name,
                countryId: obj.Location.Deposit.country_id
              }
            },
            name: obj.Name,
            needsAuthorization: obj.NeedsAuthorization,
            objectStatus: { id: obj.ObjectStatus.ID, description: obj.ObjectStatus.Description },
            ownerData: {
              name: obj.OwnerData.Name,
              lastName: obj.OwnerData.LastName,
              guid: obj.OwnerData.GUID,
              email: obj.OwnerData.Email
            },
            photos: obj.Photos.map((photo) => ({
              id: photo.ID,
              mainPhoto: photo.MainPhoto,
              url: photo.Url
            })),
            product: {
              deprecated: obj.Product.Deprecated,
              description: obj.Product.Description,
              dimensions: {
                heightInCm: obj.Product.HeightInCm,
                lengthInCm: obj.Product.LengthInCm,
                volumeInCm3: obj.Product.VolumeInCm3,
                weightInGr: obj.Product.WeightInGr,
                widthInCm: obj.Product.WidthInCm
              },
              disassemblyCost: obj.Product.DisassemblyCost,
              guid: obj.Product.ID,
              maxVolumeDeviation: obj.Product.MaxVolumeDeviation,
              minVolumeDeviation: obj.Product.MinVolumeDeviation,
              multiplier: obj.Product.Multiplier,
              packagingCostInCents: obj.Product.PackagingCostInCents,
              packagingTimeInMinutes: obj.Product.PackagingTimeInMinutes,
              prodByCountry: {
                name: obj.Product.ProdByCountry.Name,
                countryId: obj.Product.ProdByCountry.CountryId,
                productId: obj.Product.ProdByCountry.ProductId
              },
              id: obj.Product.RealID,
              showInSearch: obj.Product.ShowInSearch,
              unpackagingTimeInMinutes: obj.Product.UnpackagingTimeInMinutes
            },
            serviceTypeID: obj.ServiceTypeID,
            guid: obj.ID,
            rentEnabled: obj.RentEnabled,
            rentPrice: obj.RentPrice
          })),
          origin: tx.Origin,
          price: tx.Price,
          problems: tx.Problems,
          id: tx.RealID,
          realized: tx.Realized,
          reservation: {
            date: convertToMoment(tx.ReserveDate),
            id: tx.Reserve.ID,
            timeSlot: {
              hour: tx.Reserve.TimeSlot.Hour,
              minute: tx.Reserve.TimeSlot.Minute,
              id: tx.Reserve.TimeSlot.ID
            }
          },
          status: { description: tx.TransactionStatus.Description, id: tx.TransactionStatus.ID },
          type: { description: tx.TransactionType.Description, id: tx.TransactionType.ID },
          tripId: tx.TripID,
          operation: {
            additionalCostInCents: tx.Operation.AdditionalCostInCents,
            countryId: tx.Operation.CountryID,
            discountCode: tx.Operation.DiscountCode,
            id: tx.Operation.ID,
            operationCoreId: tx.Operation.OperationCoreID,
            operationCoreType: tx.Operation.OperationCoreType,
            status: tx.Operation.Status,
            transportCostInCents: tx.Operation.TransportCostInCents,
            userId: tx.Operation.UserId
          },
          forecastMetrics: {
            quantityItems: tx.Metrics.ItemsQuoted,
            quantityM3Items: tx.Metrics.QuantityM3Items,
            quantityM3Objects: tx.Metrics.QuantityM3Objects,
            quantityObjects: tx.Metrics.QuantityObjects
          },
          forecast: tx.forecast,
          typeTransaction: tx.type_transaction
        }))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
