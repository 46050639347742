import { ReactText } from 'react'
import { UpdateOptions, toast } from 'react-toastify'

const calculateAutoClose = (options: UpdateOptions): number => {
  if (typeof options.render === 'string') {
    return 2000 + Math.round((options.render.length / 25) * 500)
  }
  return 2000
}

export const sendToastNotificationInfo = (message: string): ReactText => toast.info(message)

export const sendToastNotificationError = (message: string): ReactText => toast.error(message)

export const sendToastNotificationSuccess = (message: string): ReactText => toast.success(message)

export const setToastLoading = (message: string): ReactText => toast.loading(message)
export const setToastWarningUpdate = (id: ReactText, options: UpdateOptions) =>
  toast.update(id, {
    type: 'warning',
    isLoading: false,
    autoClose: calculateAutoClose(options),
    ...options
  })
export const setToastSuccessUpdate = (id: ReactText, options: UpdateOptions) =>
  toast.update(id, {
    type: 'success',
    isLoading: false,
    autoClose: calculateAutoClose(options),
    ...options
  })
export const setToastSuccessInfo = (id: ReactText, options: UpdateOptions) =>
  toast.update(id, {
    type: 'info',
    isLoading: false,
    autoClose: calculateAutoClose(options),
    ...options
  })
export const setToastErrorUpdate = (id: ReactText, options: UpdateOptions) =>
  toast.update(id, {
    type: 'error',
    isLoading: false,
    autoClose: calculateAutoClose(options),
    ...options
  })
