import { compose, find, forEachObjIndexed, isEmpty, prop, propEq, propOr } from 'ramda'
import { accountTypes } from './Users.constants'
import moment from 'moment'

export const getAccountTypeLabel = (accountTypeID) =>
  compose(prop('label'), find(propEq('value', accountTypeID)))(accountTypes)

export const getRoleFilters = (roles) => [
  ...roles.map(({ value, label }) => ({ text: label, value })),
  { text: 'Equipo', value: '1,2,5' }
]

export const getPaymentMethodLabels = (paymentMethods) =>
  paymentMethods.map(({ ID, Description }) => ({
    label: Description,
    value: ID
  }))

export const formatDocumentTypeOptions = (docTypes) =>
  docTypes.map((docType) => ({
    label: `${docType.type} ${docType?.country?.name ? '(' + docType.country.name + ')' : ''}`,
    value: docType.id
  }))

export const getOptionValue = propOr('', 'value')

function lowerCaseFirstLetter(string) {
  return string[0].toLowerCase() + string.slice(1)
}

const getDate = (date) => (isEmpty(date) ? null : moment(date))

export const formatUsers = (users) =>
  users.map((u) => ({
    ...u,
    has_card: !isEmpty(u.Cards),
    accepted: u.AcceptedTerms
  }))

export const formatEditUser = (user) => {
  const formattedUser = {}
  forEachObjIndexed((value, key) => {
    switch (key) {
      case 'DNI':
      case 'CUIL': {
        formattedUser[key.toLowerCase()] = value
        break
      }
      case 'BillType': {
        formattedUser.billingType = value
        break
      }
      case 'Company': {
        formattedUser.companyName = value
        break
      }
      case 'ID': {
        formattedUser[key] = value
        break
      }
      case 'GUID': {
        formattedUser.guid = value
        break
      }
      case 'DateOfBirth': {
        formattedUser.dateBirth = getDate(value)
        break
      }
      case 'PaymentMethod': {
        formattedUser[lowerCaseFirstLetter(key)] = value.ID
        break
      }
      case 'Addresses': {
        formattedUser.address = formatEditUser(value.find((address) => address.MainAddress) || value[0]) || {}
        break
      }
      // address specify cases
      case 'Address': {
        formattedUser.street = value
        break
      }
      case 'PostalCode': {
        formattedUser.postalCode = value.PostalCode
        break
      }
      case 'Original': {
        formattedUser.addressGoogle = value
        break
      }
      case 'IdentificationType': {
        formattedUser.docType = value.ID
        break
      }
      default:
        formattedUser[lowerCaseFirstLetter(key)] = value
    }
  }, user)

  return formattedUser
}
