import { DashboardMetrics } from '../../../projectApi/TransactionHandler/Dashboard/metrics'
import { AnnualMetrics } from '../../../projectApi/TransactionHandler/Dashboard/annualMetrics'
import { Moment } from 'moment'
import { ChartInfo } from '@/components/LineChart/types/LicheChart.types'

export enum DashboardDateRangeKey {
  PERIOD_DATE = 'period'
}

export type DashboardDateRangeFilterKey = 'period'

type DateRangeFilter<DateRangeKey extends string> = {
  key: DateRangeKey
  startDate: Moment
  endDate: Moment
}

export enum AnnualMetricTypesKeys {
  m3WonDepositOperations = 'm3WonDepositOperations',
  m3Removals = 'm3Removals',
  quantityClientsWithFullRemovals = 'quantityClientsWithFullRemovals',
  quantityNewUsersWithFirstDepositOperation = 'quantityNewUsersWithFirstDepositOperation'
}

export type AnnualMetricTypes = {
  m3WonDepositOperations: number
  m3Removals: number
  quantityClientsWithFullRemovals: number
  quantityNewUsersWithFirstDepositOperation: number
}

export type ChartDataSchema = {
  title: string
  info: ChartInfo[]
}

export type DashboardDateRangeFilter = DateRangeFilter<DashboardDateRangeFilterKey>

export type DashboardState = {
  fetchingMetrics: boolean
  fetchingAnnualMetrics: boolean
  dateRangeFilter: DashboardDateRangeFilter[]
  metrics: DashboardMetrics
  annualMetrics: AnnualMetrics
  error: string
}

export enum GetDashboardMetricsTypes {
  GET_METRICS_REQUEST = 'DASHBOARD/METRICS/GET_METRICS_REQUEST',
  GET_METRICS_SUCCESS = 'DASHBOARD/METRICS/GET_METRICS_SUCCESS',
  GET_METRICS_FAILURE = 'DASHBOARD/METRICS/GET_METRICS_FAILURE'
}

export interface GetDashboardMetricsRequest {
  type: GetDashboardMetricsTypes.GET_METRICS_REQUEST
  payload: { dateRangeFilter: DashboardDateRangeFilter[] }
}

export interface GetDashboardMetricsSuccess {
  type: GetDashboardMetricsTypes.GET_METRICS_SUCCESS
  payload: { metrics: DashboardMetrics }
}

export interface GetDashboardMetricsFailure {
  type: GetDashboardMetricsTypes.GET_METRICS_FAILURE
}

type GetDashboardMetricsActions = GetDashboardMetricsRequest | GetDashboardMetricsSuccess | GetDashboardMetricsFailure

export enum GetDashboardAnnualMetricsTypes {
  GET_ANNUAL_METRICS_REQUEST = 'DASHBOARD/METRICS/GET_ANNUAL_METRICS_REQUEST',
  GET_ANNUAL_METRICS_SUCCESS = 'DASHBOARD/METRICS/GET_ANNUAL_METRICS_SUCCESS',
  GET_ANNUAL_METRICS_FAILURE = 'DASHBOARD/METRICS/GET_ANNUAL_METRICS_FAILURE'
}

export interface GetDashboardAnnualMetricsRequest {
  type: GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_REQUEST
}

export interface GetDashboardAnnualMetricsSuccess {
  type: GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_SUCCESS
  payload: { annualMetrics: AnnualMetrics }
}

export interface GetDashboardAnnualMetricsFailure {
  type: GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_FAILURE
}

type GetDashboardaAnnualMetricsActions =
  | GetDashboardAnnualMetricsRequest
  | GetDashboardAnnualMetricsSuccess
  | GetDashboardAnnualMetricsFailure

export type DashboardActions = GetDashboardMetricsActions | GetDashboardaAnnualMetricsActions
