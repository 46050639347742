import React, { useMemo } from 'react'
import { Column, Pagination } from '../../../../components/DynamicTable/types/types'
import { DynamicTable } from '../../../../components/DynamicTable'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { getFilterProps, getSearchProps } from '../../../../components/DynamicTable/utils'
import { Checkbox, Tooltip } from 'antd'
import {
  ObjectTableSearchFilterKey,
  ObjectsSearchFilter,
  ObjectsTableCategoryFilter,
  ObjectsTableSearchKey,
  ObjectsTableSort
} from '../types/objectsTable'
import { FormattedObject, OBJECT_SITUATION } from '../../../../projectApi/ObjectAdministration/common'
import { ObjectStatus } from '../../../../projectApi/ObjectAdministration/ObjectStatus/search'
import notAvailableImage from '../../../Objects/images/notAvailableImage.jpg'
import { Edit, List, QRIcon } from '../../../../icons'
import {
  ObjectSituationSortKeys,
  getIdentificationCode,
  getSortProps,
  parseDate,
  renderProduct,
  renderStatus
} from '../../../ObjectsV2/utils'
import { REPORT_MAX_OBJECTS } from '../../../../projectApi/ObjectAdministration/Object/report'
import styles from './objectsTable.module.scss'
import Photos from '../../../Objects/components/photos'
import ImgWithCarousel from '../../../../components/CarouselModal/components/ImgWithCarousel'
import { TableActionBar } from '../../../../components/TableActionBar'

export type ObjectsTableProps = {
  objects: FormattedObject[]
  selectedObjects: FormattedObject[]
  loadingObjects: boolean
  requestingReport: boolean
  error: string
  categoryFilter: ObjectsTableCategoryFilter
  pagination: Pagination
  searchFilters: ObjectsSearchFilter[]
  sort: ObjectsTableSort
  objectStatus: ObjectStatus[]
  handleMarkPhotoAsMain: (objectId: number, photoId: number, photoUrl: string) => void
  handleRemovePhoto: (objectId: number, photoId: number, photoUrl: string) => void
  handleRequestReport: () => void
  handleModifyObject: (obj: FormattedObject) => void
  handleOpenQRCodeModal: (code: string) => void
  handleSort: (newSorter: ObjectsTableSort) => void
  handleResetFilters: () => void
  handleSearch: (key: ObjectsTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newFilter: ObjectsTableCategoryFilter) => void
  handleToggleSelectedObject: (object: FormattedObject) => void
  handleToggleAllObjects: () => void
  toggleExactLocationCode: () => void
  handleOpenTransferModal: () => void
  handleViewObjectChanges: (objectId: number) => void
}

const ObjectsTable: React.FC<ObjectsTableProps> = ({
  objects,
  selectedObjects,
  loadingObjects,
  pagination,
  categoryFilter,
  searchFilters,
  sort,
  requestingReport,
  objectStatus,
  handleMarkPhotoAsMain,
  handleRemovePhoto,
  handleRequestReport,
  handleModifyObject,
  handleOpenQRCodeModal,
  handleCategoryFilter,
  handleSort,
  handleResetFilters,
  handleSearch,
  handleToggleAllObjects,
  handleToggleSelectedObject,
  toggleExactLocationCode,
  handleOpenTransferModal,
  handleViewObjectChanges
}) => {
  const exactLocationValue = useMemo(
    () =>
      searchFilters.find((searchFilter) => searchFilter.key === ObjectTableSearchFilterKey.EXACT_LOCATION_CODE)
        ?.text === 'true',
    [searchFilters]
  )

  const SearchExactLocation = (
    <div className={styles.checkboxLocation}>
      <Checkbox checked={exactLocationValue} onChange={toggleExactLocationCode}>
        Búsqueda exacta
      </Checkbox>
    </div>
  )

  const objectStatusKeys = objectStatus.map((v: ObjectStatus) => ({ value: v.description, text: v.description }))

  const columns: Column<FormattedObject>[] = [
    {
      key: 'realId',
      label: 'ID',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('id', sort, handleSort)
    },
    {
      key: 'identificationCode',
      label: 'Etiqueta',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      search: getSearchProps('identificationCode', 'etiqueta', handleSearch, searchFilters),
      renderDataCell: (object) => {
        const identificationCode = getIdentificationCode(object)
        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenQRCodeModal(identificationCode)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
            {identificationCode}
          </div>
        )
      }
    },
    {
      key: 'photo',
      label: 'Foto',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ photos, product: { description } }) => {
        if (photos.length === 0) {
          return <img src={notAvailableImage} alt="-" style={{ objectFit: 'cover', width: '40px', height: '42px' }} />
        } else {
          const parsedPhotos = photos
            .filter((p) => p.deletedDescription.length === 0)
            .sort((a) => (a.mainPhoto ? -1 : 1))
            .map((p) => p.url)

          return <ImgWithCarousel carouselImages={parsedPhotos} alt={description} />
        }
      }
    },
    {
      key: 'product.description',
      label: 'Producto',
      alignment: 'left',
      search: getSearchProps('product', 'Producto', handleSearch, searchFilters),
      renderDataCell: renderProduct
    },
    {
      key: 'status',
      label: 'Estado',
      filters: getFilterProps(
        objectStatusKeys,
        categoryFilter,
        'status',
        // @ts-ignore
        handleCategoryFilter,
        false
      ),
      renderDataCell: renderStatus
    },
    {
      key: 'objectSituation',
      label: 'Situación',
      renderDataCell: ({ objectSituation, objectSituationReason }) =>
        objectSituation ? (
          <Tooltip placement="top" title={objectSituationReason}>
            {OBJECT_SITUATION[objectSituation]}
          </Tooltip>
        ) : null,
      // @ts-ignore
      filters: getFilterProps(ObjectSituationSortKeys, categoryFilter, 'objectSituation', handleCategoryFilter, true)
    },
    {
      key: 'creationDate',
      label: 'Fecha de creación',
      minWidth: 'min-content',
      maxWidth: '124px',
      sort: getSortProps('created_at', sort, handleSort),
      renderDataCell: ({ creationDate }) => (creationDate ? parseDate(creationDate) : '-')
    },
    {
      key: 'location.deposit.name',
      label: 'Depósito',
      alignment: 'left'
    },
    {
      key: 'location.description',
      label: 'Ubicación',
      alignment: 'left',
      search: getSearchProps('locationCode', 'Ubicación', handleSearch, searchFilters, SearchExactLocation)
    }
  ]

  const objectActions: MenuAction<FormattedObject>[] = [
    {
      label: 'Modificar objeto',
      icon: <Edit />,
      onClick: (object) => handleModifyObject(object)
    },
    {
      label: 'Ver historial de cambios',
      icon: <List />,
      onClick: ({ realId }) => handleViewObjectChanges(realId)
    }
  ]

  const tooManyObjectsForReport = pagination.total > REPORT_MAX_OBJECTS
  const reportButtonProps = {
    disabled: tooManyObjectsForReport || loadingObjects,
    toolTipCustomMessage: tooManyObjectsForReport
      ? `No se puede solicitar reportes de más de ${REPORT_MAX_OBJECTS} objetos. Aplique filtros para reducir la cantidad de resultados`
      : '',
    onClickButton: handleRequestReport,
    loading: requestingReport
  }

  return (
    <div>
      <TableActionBar>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction variant="csv" {...reportButtonProps} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        </TableActionBar.Wrapper>
        <TableActionBar.ButtonAction
          iconType="export"
          titleButton="Traslado interno"
          disabled={selectedObjects.length < 1}
          onClickButton={handleOpenTransferModal}
        />
      </TableActionBar>

      <DynamicTable
        columns={columns}
        rowHeight={65}
        checkboxes={{
          rowIsChecked: (row) => !!selectedObjects.find((o) => o.realId === row.realId),
          onRowCheckboxClick: (row) => handleToggleSelectedObject(row),
          headerIsChecked: () => selectedObjects.length > 0 && selectedObjects.length === objects.length,
          onHeaderCheckboxClick: handleToggleAllObjects
        }}
        loading={loadingObjects}
        actions={objectActions}
        rowExpander={{
          render: (object) => (
            <Photos
              {...object.unformattedAPIObject}
              selectPhoto={handleRemovePhoto}
              selectMainPhoto={handleMarkPhotoAsMain}
            />
          )
        }}
        rows={objects}
        keyExtractor={(entity) => entity.id}
        pagination={pagination}
      />
    </div>
  )
}

export default ObjectsTable
