export default {
  SET_EMAIL: 'PROPOSALS/SEND/SET_EMAIL',
  SET_ERROR: 'PROPOSALS/SEND/SET_ERROR',
  SEND_BY_EMAIL_REQUEST: 'PROPOSALS/SEND/SEND_BY_EMAIL_REQUEST',
  SEND_BY_EMAIL_SUCCESS: 'PROPOSALS/SEND/SEND_BY_EMAIL_SUCCESS',
  SEND_BY_EMAIL_FAILURE: 'PROPOSALS/SEND/SEND_BY_EMAIL_FAILURE',
  OPEN_SEND_BY_EMAIL_MODAL: 'PROPOSALS/SEND/OPEN_SEND_BY_EMAIL_MODAL',
  CLOSE_SEND_BY_EMAIL_MODAL: 'PROPOSALS/SEND/CLOSE_SEND_BY_EMAIL_MODAL',
  CANCEL_SEND_BY_EMAIL_MODAL: 'PROPOSALS/SEND/CANCEL_SEND_BY_EMAIL_MODAL'
}
