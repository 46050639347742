import React from 'react'
import styles from './OptionsModal.module.scss'
import { Modal, Radio } from 'antd'
import { ModalProps } from 'antd/es/modal'
import { RadioChangeEvent } from 'antd/lib/radio/interface'

type OptionValue = number | string

type Option<T> = {
  value: T
  label: string | React.ReactNode
  extraComponent?: React.ReactNode
}

type OptionsProps<T> = {
  options: Option<T>[]
  selectedOption: T
  onOptionChange: (e: RadioChangeEvent) => void
}

type OptionsModalProps<T> = {
  modalProps: ModalProps
  optionsProps: OptionsProps<T>
}

const OptionsModal = <T extends OptionValue>(props: OptionsModalProps<T>) => {
  const { modalProps, optionsProps } = props
  const { options, selectedOption, onOptionChange } = optionsProps

  return (
    <Modal {...modalProps}>
      <Radio.Group style={{ width: '100%' }} value={selectedOption} onChange={onOptionChange}>
        {options.map(({ value, label, extraComponent }) => (
          <div key={value} className={styles.radioContainer}>
            <Radio className={styles.radio} value={value}>
              {label}
            </Radio>
            {extraComponent}
          </div>
        ))}
      </Radio.Group>
    </Modal>
  )
}

export default OptionsModal
