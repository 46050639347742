import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import OperationDatesActionCreator from '../actions/operationDatesAction'
import DeleteDateModal from '../components/deleteDateModal'

const DeleteDateModalContainer = () => {
  const dispatch = useDispatch()
  const { isDeleteModalOpen, selectedDeleteDate, deletingDate } = useSelector((state) => state.OperationCalendar.Dates)
  const { openDeleteDateModal, deleteDate } = bindActionCreators(OperationDatesActionCreator, dispatch)

  const props = {
    isDeleteModalOpen,
    openDeleteDateModal,
    deleteDate,
    selectedDeleteDate,
    deletingDate
  }

  return <DeleteDateModal {...props} />
}

export default DeleteDateModalContainer
