export enum STATUS_VALUES {
  DISCARDED = 10,
  LOST = 11,
  NEW = 1,
  PROPOSED = 2,
  CONVERTED = 3,
  DRAFT = 4
}

export enum STATUS_NAMES {
  VIGENTE = 'Vigente',
  CONVERTIDA = 'Convertida',
  VENCIDA = 'Vencida',
  BORRADOR = 'Borrador'
}

export const STATUS_FILTERS = [
  {
    text: STATUS_NAMES.VIGENTE,
    value: STATUS_VALUES.NEW,
    styleProps: {
      borderColor: '#FAAF19',
      backgroundColor: '#FFFBE6'
    }
  },
  {
    text: STATUS_NAMES.CONVERTIDA,
    value: STATUS_VALUES.PROPOSED,
    styleProps: {
      borderColor: '#52C41A',
      backgroundColor: '#F6FFED'
    }
  },
  {
    text: STATUS_NAMES.VENCIDA,
    value: STATUS_VALUES.CONVERTED,
    styleProps: {
      borderColor: '#FF4D4F',
      backgroundColor: '#FFF2F0'
    }
  },
  {
    text: STATUS_NAMES.BORRADOR,
    value: STATUS_VALUES.DRAFT,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  }
]
