import { Moment } from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import PickingsTableActionCreators from '../actionCreators/pickingsTable'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import PickingsTable, { PickingsTableProps } from '../components/pickingsTable'
import {
  Pagination,
  PickingsDateRangeFilter,
  PickingsSearchFilter,
  PickingsTableCategoryFilter,
  PickingsTableDateRangeKey,
  PickingsTableSearchKey,
  PickingsTableSort
} from '../types/pickingsTable'
import CarouselModal from '../../../components/CarouselModal/containers/CarouselModal'
import PickingMetricsActionsCreator from '../actionCreators/pickingsMetrics'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { removeNullishValues } from '../../../components/DynamicTable/queryParamsUtils'
import PickingsMetrics from '../components/pickingsMetrics'
import { TableActionBar } from '../../../components/TableActionBar'

const PickingsTableContainer = () => {
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.Pickings.table)
  const { quantityMetrics, fetchingMetrics } = useSelector((root) => root.Pickings.metrics)
  const dispatch = useDispatch()
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const { getOperators, getPickings } = bindActionCreators(PickingsTableActionCreators, dispatch)
  const { getMetrics } = bindActionCreators(PickingMetricsActionsCreator, dispatch)

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    id: NumberParam,
    ownerId: NumberParam,
    limit: NumberParam,
    status: StringParam,
    sort: StringParam,
    order: StringParam,
    agentId: NumberParam,
    operationId: NumberParam,
    createdAtFrom: StringParam,
    createdAtTo: StringParam,
    pickingDateFrom: StringParam,
    pickingDateTo: StringParam,
    startDateFrom: StringParam,
    startDateTo: StringParam,
    endDateFrom: StringParam,
    endDateTo: StringParam
  })

  const handleGetPickings = (newParams: {
    pagination?: Pagination
    sort?: PickingsTableSort
    searchFilters?: PickingsSearchFilter[]
    dateRangeFilters?: PickingsDateRangeFilter[]
    categoryFilter?: PickingsTableCategoryFilter
    silentLoading?: boolean
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      sort: newParams.sort || tableState.sort,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      silentLoading: newParams.silentLoading
    }
    getPickings(actualParams)

    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')

    handleGetTripsMetrics({ newRangeFilters: actualParams.dateRangeFilters })
  }

  useEffect(() => {
    getOperators()
    handleGetPickings({ pagination: emptyPagination })
  }, [countryCode])

  const handleGetTripsMetrics = ({ newRangeFilters }: { newRangeFilters?: PickingsDateRangeFilter[] }) => {
    const currentPeriod = newRangeFilters || tableState.dateRangeFilters
    const createdAt = currentPeriod.find((dateRange) => dateRange.key === 'created_at') ?? undefined

    getMetrics({
      dateFrom: createdAt?.startDate ?? undefined,
      dateTo: createdAt?.endDate ?? undefined
    })
  }

  const handlePageChange = (newPage: number) => {
    handleGetPickings({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleRangePicker = (key: PickingsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetPickings({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
  }

  const handleSearch = (key: PickingsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetPickings({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleCategoryFilter = (newCategoryFilter: PickingsTableCategoryFilter) => {
    handleGetPickings({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleSort = (newSort: PickingsTableSort) => {
    handleGetPickings({ sort: newSort })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetRangeFilters = tableState.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))
    const resetSort: PickingsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleGetPickings({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters,
      dateRangeFilters: resetRangeFilters,
      categoryFilter: { status: [], agent_id: [] }
    })
  }

  const props: PickingsTableProps = {
    operators: tableState.operators,
    loadingOperators: tableState.loadingOperators,
    pickings: tableState.pickings,
    loadingPickings: tableState.loadingPickings,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    dateRangeFilters: tableState.dateRangeFilters,
    sort: tableState.sort,
    openQRModal,
    handleSort,
    handleSearch,
    handleCategoryFilter,
    handleRangePicker
  }

  return (
    <>
      <PickingsMetrics fetchingMetrics={tableState.loadingPickings || fetchingMetrics} metrics={quantityMetrics} />
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
      </TableActionBar>
      <PickingsTable {...props} />
      <CarouselModal />
    </>
  )
}

export default PickingsTableContainer
