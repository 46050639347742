import { combineReducers } from 'redux'
import complaintsTable from './complaintsTable'
import CreateComplaintModalReducer from './createClompaint'
import ComplaintUpdateViewReducer from './updateViewReducer'
import ResolveComplaintReducer from './resolveModal'

const rootReducer = combineReducers({
  complaintsTable,
  Create: CreateComplaintModalReducer,
  updateView: ComplaintUpdateViewReducer,
  resolve: ResolveComplaintReducer
})

export default rootReducer
