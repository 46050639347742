import React from 'react'
import styles from './UserInfo.module.scss'
import { MailLine, UserLine } from '../../../../icons'
import { RequestData, UserPersonalInfo } from '../../types/billingDiscountsModal'
import { useLocalization } from '../../../../packages/localization'

type UserInfoHeaderProps = RequestData<Partial<UserPersonalInfo>>

const UserInfoHeader = (userPersonalInfo: UserInfoHeaderProps) => {
  if (userPersonalInfo.fetching) return <LoadingUserInfoHeader />

  return (
    <div className={styles.userPersonalInfoContainer}>
      <div className={styles.infoElement}>
        <span className={styles.icon}>
          <UserLine />
        </span>{' '}
        {userPersonalInfo.data.userId} - {userPersonalInfo.data.name} {userPersonalInfo.data.lastName}
      </div>
      <div className={styles.infoElement}>
        <span className={styles.icon}>
          <MailLine />
        </span>{' '}
        {userPersonalInfo.data.email}
      </div>
    </div>
  )
}

export default UserInfoHeader

const LoadingUserInfoHeader = () => {
  const { strings } = useLocalization()

  return (
    <div className={styles.userPersonalInfoContainer}>
      <div className={styles.infoElement}>
        <span className={styles.icon}>
          <UserLine />
        </span>{' '}
        {strings.Billings.DiscountModal.userInfo.loading}
      </div>
    </div>
  )
}
