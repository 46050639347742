import { User } from '../../../projectApi/AccountManager/Users/common'

export type DevelopmentViewState = {
  objectLabel: {
    objectLabelModal: boolean
    codeGEN: string
    users: User[]
    isLoadingUsers: boolean
  }
  error: string
  isLoading: boolean
}

export type GenericParams = {
  ownerId: number
  quantityIdentificationCodes: number
  identificationCode: string
}
export enum DevelopmentCommonTypes {
  CLEAR_STATE = 'DEVELOPMENT/DEVELOPMENT_VIEW/CLEAR_STATE',
  OBJECT_LABEL_MODAL = 'DEVELOPMENT/DEVELOPMENT_VIEW/OBJECT_LABEL_MODAL'
}

export interface ClearState {
  type: typeof DevelopmentCommonTypes.CLEAR_STATE
}

export type CommonAction = ClearState

export interface ObjectLabelModal {
  type: typeof DevelopmentCommonTypes.OBJECT_LABEL_MODAL
  payload: {
    objectLabelModal: boolean
  }
}

export enum CreateGenericObjectLabelsTypes {
  CREATE_GENERIC_OBJECT_LABELS_REQUEST = 'DEVELOPMENT/DEVELOPMENT_VIEW/CREATE_GENERIC_OBJECT_LABELS_REQUEST',
  CREATE_GENERIC_OBJECT_LABELS_SUCCESS = 'DEVELOPMENT/DEVELOPMENT_VIEW/CREATE_GENERIC_OBJECT_LABELS_SUCCESS',
  CREATE_GENERIC_OBJECT_LABELS_FAILURE = 'DEVELOPMENT/DEVELOPMENT_VIEW/CREATE_GENERIC_OBJECT_LABELS_FAILURE'
}

export interface CreateGenericObjectLabelsRequest {
  type: typeof CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_REQUEST
}

export interface CreateGenericObjectLabelsSuccess {
  type: typeof CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_SUCCESS
}

export interface CreateGenericObjectLabelsFailure {
  type: typeof CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_FAILURE
  payload: {
    error: string
  }
}

export type CreateGenericObjectLabelsActions =
  | CreateGenericObjectLabelsRequest
  | CreateGenericObjectLabelsSuccess
  | CreateGenericObjectLabelsFailure

export enum FetchCodeGenTypes {
  FETCH_CODE_GEN_REQUEST = 'DEVELOPMENT/DEVELOPMENT_VIEW/FETCH_CODE_GEN_REQUEST',
  FETCH_CODE_GEN_SUCCESS = 'DEVELOPMENT/DEVELOPMENT_VIEW/FETCH_CODE_GEN_SUCCESS',
  FETCH_CODE_GEN_FAILURE = 'DEVELOPMENT/DEVELOPMENT_VIEW/FETCH_CODE_GEN_FAILURE'
}

export interface FetchCodeGenRequest {
  type: typeof FetchCodeGenTypes.FETCH_CODE_GEN_REQUEST
}

export interface FetchCodeGenSuccess {
  type: typeof FetchCodeGenTypes.FETCH_CODE_GEN_SUCCESS
  payload: {
    code: string
  }
}

export interface FetchCodeGenFailure {
  type: typeof FetchCodeGenTypes.FETCH_CODE_GEN_FAILURE
  payload: {
    error: string
  }
}

export type FetchCodeGenActions = FetchCodeGenRequest | FetchCodeGenSuccess | FetchCodeGenFailure

export enum GetUsersTypes {
  GET_USERS_REQUEST = 'DEPOSIT/LOCATIONS/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'DEPOSIT/LOCATIONS/GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'DEPOSIT/LOCATIONS/GET_USERS_FAILURE'
}

export interface GetUsersRequest {
  type: GetUsersTypes.GET_USERS_REQUEST
}

export interface GetUsersSuccess {
  type: GetUsersTypes.GET_USERS_SUCCESS
  payload: {
    users: User[]
  }
}

export interface GetUsersFailure {
  type: GetUsersTypes.GET_USERS_FAILURE
  payload: {
    error: string
  }
}

export type GetUsersActions = GetUsersRequest | GetUsersSuccess | GetUsersFailure

export type DevelopmentViewAction =
  | ObjectLabelModal
  | CommonAction
  | CreateGenericObjectLabelsActions
  | FetchCodeGenActions
  | GetUsersActions
