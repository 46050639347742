import { GeneralDataState } from './GeneralData'

export type GeneralDataCreateStates = Omit<GeneralDataState, 'categories' | 'services' | 'error'>

export const SET_PRODUCT_CREATED_SUCCESSFULLY = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_PRODUCT_CREATED_SUCCESSFULLY'

export const SET_EDIT_MODE = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_EDIT_MODE'

export interface SetEditMode {
  type: typeof SET_EDIT_MODE
  payload: { isEditMode: boolean }
}

export const CREATE_NEW_PRODUCT_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/CREATE_NEW_PRODUCT_REQUEST'
export const CREATE_NEW_PRODUCT_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/CREATE_NEW_PRODUCT_SUCCESS'
export const CREATE_NEW_PRODUCT_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/CREATE_NEW_PRODUCT_FAILURE'

export interface CreateNewProductRequest {
  type: typeof CREATE_NEW_PRODUCT_REQUEST
}

export interface CreateNewProductSuccess {
  type: typeof CREATE_NEW_PRODUCT_SUCCESS
}

export interface CreateNewProductFailure {
  type: typeof CREATE_NEW_PRODUCT_FAILURE
  payload: { error: string }
}

export const CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST'
export const CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS'
export const CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE'

export interface CreateNewProductByCatalogRequest {
  type: typeof CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST
}

export interface CreateNewProductByCatalogSuccess {
  type: typeof CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS
}

export interface CreateNewProductByCatalogFailure {
  type: typeof CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE
  payload: { error: string }
}

export type CreateNewProductActions =
  | CreateNewProductRequest
  | CreateNewProductSuccess
  | CreateNewProductFailure
  | CreateNewProductByCatalogRequest
  | CreateNewProductByCatalogSuccess
  | CreateNewProductByCatalogFailure

export const UPDATE_PRODUCT_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/UPDATE_PRODUCT_FAILURE'

export interface UpdateProductRequest {
  type: typeof UPDATE_PRODUCT_REQUEST
}

export interface UpdateProductSuccess {
  type: typeof UPDATE_PRODUCT_SUCCESS
}

export interface UpdateProductFailure {
  type: typeof UPDATE_PRODUCT_FAILURE
  payload: { error: string }
}

export type UpdateProductAction = UpdateProductRequest | UpdateProductSuccess | UpdateProductFailure
