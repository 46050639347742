import { Moment } from 'moment'
import { Trip } from '../../../projectApi/Trips/Trip/common'
import { TripTransaction } from '../../../projectApi/Trips/Trip/create'
import { GetTripTransactionsAction } from './common'
import { TripTransactionOperation } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'

export type Driver = {
  userId: number
  name: string
  lastName: string
}

export enum CountryLabel {
  ARG = 'AR',
  ESP = 'ES'
}

export type TripModalTransaction = TripTransaction & {
  id: number
  statusId: number
  creationDate: Moment
  reservationDate: Moment
  destinationAddress: string
  client: {
    id: number
    name: string
    lastName: string
    phone: string
  }
  operation: TripTransactionOperation
  contents: {
    itemsOrObjects: number
    m3: number
  }
}

export interface TripModalState {
  open: boolean
  loadingTransactions: boolean
  loadingDrivers: boolean
  creatingOrUpdating: boolean
  error: string
  transactions: TripModalTransaction[]
  extraTransactionsToAdd: TripModalTransaction[]
  searchingExtraTransaction: boolean
  drivers: Driver[]
  selectedDriverId: number
  trip: Trip | null
}

export const SET_OPEN = 'TRIPS/TRIP_MODAL/SET_OPEN'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    transactions?: TripModalTransaction[]
    trip?: Trip
  }
}

export const GET_DRIVERS_REQUEST = 'TRIPS/TRIP_MODAL/GET_DRIVERS_REQUEST'
export const GET_DRIVERS_SUCCESS = 'TRIPS/TRIP_MODAL/GET_DRIVERS_SUCCESS'
export const GET_DRIVERS_FAILURE = 'TRIPS/TRIP_MODAL/GET_DRIVERS_FAILURE'

export interface GetDriversRequest {
  type: typeof GET_DRIVERS_REQUEST
}

export interface GetDriversSuccess {
  type: typeof GET_DRIVERS_SUCCESS
  payload: {
    drivers: Driver[]
  }
}

export interface GetDriversFailure {
  type: typeof GET_DRIVERS_FAILURE
  payload: {
    error: string
  }
}

export type GetDriversAction = GetDriversRequest | GetDriversSuccess | GetDriversFailure

export const SELECT_DRIVER = 'TRIPS/TRIP_MODAL/SELECT_DRIVER'

export interface SelectDriver {
  type: typeof SELECT_DRIVER
  payload: {
    driverId: number
  }
}

export const CREATE_TRIP_REQUEST = 'TRIPS/TRIP_MODAL/CREATE_TRIP_REQUEST'
export const CREATE_TRIP_SUCCESS = 'TRIPS/TRIP_MODAL/CREATE_TRIP_SUCCESS'
export const CREATE_TRIP_FAILURE = 'TRIPS/TRIP_MODAL/CREATE_TRIP_FAILURE'

export interface CreateTripRequest {
  type: typeof CREATE_TRIP_REQUEST
}

export interface CreateTripSuccess {
  type: typeof CREATE_TRIP_SUCCESS
}

export interface CreateTripFailure {
  type: typeof CREATE_TRIP_FAILURE
  payload: {
    error: string
  }
}

export type CreateTripAction = CreateTripRequest | CreateTripSuccess | CreateTripFailure

export const UPDATE_TRIP_REQUEST = 'TRIPS/TRIP_MODAL/UPDATE_TRIP_REQUEST'
export const UPDATE_TRIP_SUCCESS = 'TRIPS/TRIP_MODAL/UPDATE_TRIP_SUCCESS'
export const UPDATE_TRIP_FAILURE = 'TRIPS/TRIP_MODAL/UPDATE_TRIP_FAILURE'

export interface UpdateTripRequest {
  type: typeof UPDATE_TRIP_REQUEST
}

export interface UpdateTripSuccess {
  type: typeof UPDATE_TRIP_SUCCESS
}

export interface UpdateTripFailure {
  type: typeof UPDATE_TRIP_FAILURE
  payload: {
    error: string
  }
}

export type UpdateTripAction = UpdateTripRequest | UpdateTripSuccess | UpdateTripFailure

export const FIND_TRANSACTION_BY_ID_REQUEST = 'TRIPS/TRIP_MODAL/FIND_TRANSACTION_BY_ID_REQUEST'
export const FIND_TRANSACTION_BY_ID_SUCCESS = 'TRIPS/TRIP_MODAL/FIND_TRANSACTION_BY_ID_SUCCESS'
export const FIND_TRANSACTION_BY_ID_FAILURE = 'TRIPS/TRIP_MODAL/FIND_TRANSACTION_BY_ID_FAILURE'

export interface FindTransactionByIdRequest {
  type: typeof FIND_TRANSACTION_BY_ID_REQUEST
}

export interface FindTransactionByIdSuccess {
  type: typeof FIND_TRANSACTION_BY_ID_SUCCESS
  payload: {
    transaction: TripModalTransaction
  }
}

export interface FindTransactionByIdFailure {
  type: typeof FIND_TRANSACTION_BY_ID_FAILURE
  payload: {
    error: string
  }
}

export type FindTransactionByIdAction =
  | FindTransactionByIdRequest
  | FindTransactionByIdSuccess
  | FindTransactionByIdFailure

export type TripsModalAction =
  | SetOpen
  | GetDriversAction
  | SelectDriver
  | CreateTripAction
  | UpdateTripAction
  | GetTripTransactionsAction
  | FindTransactionByIdAction
