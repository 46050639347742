import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { OperationTypeValues } from '../../../../sections/OperationCalendar/types/common'

export interface Paging {
  total: number
  limit: number
  offset: number
}

export interface APIDate {
  id: number
  date: Moment
  operation_type: OperationTypeValues
}

interface Response {
  days: CamelCasedPropertiesDeep<APIDate>[]
}

export enum OperationDays {
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
}

export interface ResponseOperationDays {
  days: CamelCasedPropertiesDeep<APIDate>[]
}

export async function getDates(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TIMESLOT}/operation-days/specific-day`)
    .then(({ data }: AxiosResponse<Response>) => {
      return { days: camelcaseKeys(data.days) }
    })
    .catch((error) => {
      throw error
    })
}
