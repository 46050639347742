import { Reducer } from 'redux'
import {
  GET_LAST_CHANGE_LEAD_FAILURE,
  GET_LAST_CHANGE_LEAD_REQUEST,
  GET_LAST_CHANGE_LEAD_SUCCESS,
  GET_LEAD_HISTORY_FAILURE,
  GET_LEAD_HISTORY_REQUEST,
  GET_LEAD_HISTORY_SUCCESS,
  LeadHistoryAction,
  LeadHistoryState
} from '../types/LeadHistory'

const initialLeadHistory: LeadHistoryState = {
  leadHistory: [],
  fetchingLead: false,
  processLastLead: false
}

const ProspectHistoryReducer: Reducer<LeadHistoryState, LeadHistoryAction> = (
  state = initialLeadHistory,
  action
): LeadHistoryState => {
  switch (action.type) {
    case GET_LEAD_HISTORY_REQUEST:
      return {
        ...state,
        fetchingLead: true
      }
    case GET_LEAD_HISTORY_SUCCESS:
      return {
        ...state,
        leadHistory: action.payload.leadHistory,
        fetchingLead: false
      }
    case GET_LEAD_HISTORY_FAILURE:
      return {
        ...state,
        fetchingLead: false
      }
    case GET_LAST_CHANGE_LEAD_REQUEST:
      return {
        ...state,
        processLastLead: true
      }
    case GET_LAST_CHANGE_LEAD_SUCCESS:
      return {
        ...state,
        leadHistory: [action.payload.lead, ...state.leadHistory],
        processLastLead: false
      }
    case GET_LAST_CHANGE_LEAD_FAILURE:
      return {
        ...state,
        processLastLead: false
      }
    default:
      return state
  }
}

export default ProspectHistoryReducer
