import { Moment } from 'moment'
import { AppThunk } from '../../../store'
import { GetProposalMetricsRequest, GetProposalMetricsSuccess, GetProposalMetricsTypes } from '../types/metrics'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { sendToastNotificationError } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import { mapOfMetrics } from '../component/ProposalsMetrics'

const ProposalMetricsActionsCreator = {
  getMetrics:
    ({ dateFrom, dateTo }: { dateFrom?: Moment; dateTo?: Moment }): AppThunk =>
    async (dispatch) => {
      const request: GetProposalMetricsRequest = {
        type: GetProposalMetricsTypes.GET_PROPOSAL_METRICS_REQUEST
      }

      dispatch(request)
      try {
        const response = await API.TransactionHandler.Proposals.tableMetrics({
          dateFrom,
          dateTo
        })

        const quantityMetrics = Object.entries(response).map(([key, value]) => ({
          id: key,
          metric: mapOfMetrics[key],
          quantity: value
        }))

        const success: GetProposalMetricsSuccess = {
          type: GetProposalMetricsTypes.GET_PROPOSAL_METRICS_SUCCESS,
          payload: { quantityMetrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(error, GetProposalMetricsTypes.GET_PROPOSAL_METRICS_FAILURE, dispatch)
      }
    }
}

export default ProposalMetricsActionsCreator
