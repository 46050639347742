import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { UpdateBody, Attribute as ObjectAttributeRequest } from '../../../projectApi/ObjectAdministration/Object/update'
import { ObjectSituation } from '../../../projectApi/ObjectAdministration/ObjectSituation/search'
import { FormattedObject } from '../../../projectApi/ObjectAdministration/common'
import { EditObjectActions } from '../types/edit'
import { formatProduct } from '../utils/formatProduct'
import { Location } from '../../../projectApi/CategoryCreation/LocationInfo/getByCode'
import { Attribute } from '../../../projectApi/ObjectAdministration/Attribute/list'
import { ObjectTypes } from '../types/edit.enum'

const attributeTemplate = { attributeId: 0, id: 0, value: '', deleted: false }

export type EditObjectState = {
  open: boolean
  id: number
  serviceTypeId: string
  lastProductSearchID: number
  currentObject: FormattedObject | null
  currentObjectBody: UpdateBody
  newObject: UpdateBody
  newLocation: Location | null
  selectedProduct: FormattedProduct | null
  products: FormattedProduct[]
  productAttributes: Attribute[]
  objectSituations: ObjectSituation[]
  isSaving: boolean
  loadingProductAttributes: boolean
  loadingObject: boolean
  loadingLocation: boolean
  loadingProducts: boolean
  loadingObjectSituations: boolean
  loadingObjectStatus: boolean
  error: string
  locationError: string
}

const initialObject: UpdateBody = {
  name: '',
  description: '',
  objectSituationReason: '',
  productId: 0,
  weightInGr: 0,
  lengthInCm: 0,
  widthInCm: 0,
  heightInCm: 0,
  deletedDescription: '',
  rentEnabled: false,
  rentPrice: 0,
  registryDate: '',
  objectStatusId: 0,
  locationId: 0,
  needsAuthorization: false,
  attributes: [attributeTemplate]
}

const initialRoot = {
  open: false,
  id: 0,
  serviceTypeId: 'items_b2b',
  lastProductSearchID: 0,
  currentObject: null,
  currentObjectBody: initialObject,
  newObject: initialObject,
  newLocation: null,
  selectedProduct: null,
  products: [],
  productAttributes: [],
  objectSituations: [],
  isSaving: false,
  loadingProductAttributes: false,
  loadingObject: false,
  loadingLocation: false,
  loadingProducts: false,
  loadingObjectSituations: false,
  loadingObjectStatus: false,
  error: '',
  locationError: ''
}

function root(state: EditObjectState = initialRoot, action: EditObjectActions): EditObjectState {
  switch (action.type) {
    case ObjectTypes.SELECT_OBJECT_BY_ID_REQUEST: {
      return {
        ...state,
        loadingObject: true,
        open: true
      }
    }
    case ObjectTypes.SELECT_OBJECT_BY_ID_SUCCESS:
    case ObjectTypes.SELECT_OBJECT: {
      const { object, rawObject } = action.payload

      const formattedProduct = formatProduct(rawObject.unformattedAPIObject.Product)

      return {
        ...state,
        id: rawObject.realId,
        serviceTypeId: rawObject.serviceTypeId,
        loadingObject: false,
        open: true,
        currentObject: rawObject,
        currentObjectBody: object,
        newObject: { ...object, attributes: [...(object.attributes || []), attributeTemplate] },
        selectedProduct: formattedProduct
      }
    }
    case ObjectTypes.SELECT_OBJECT_BY_ID_FAILURE: {
      return {
        ...state,
        loadingObject: false,
        error: action.payload.error
      }
    }
    case ObjectTypes.SET_HEIGHT: {
      return {
        ...state,
        newObject: {
          ...state.newObject,
          heightInCm: action.payload.height
        }
      }
    }
    case ObjectTypes.SET_WIDTH: {
      return {
        ...state,
        newObject: {
          ...state.newObject,
          widthInCm: action.payload.width
        }
      }
    }
    case ObjectTypes.SET_LENGTH: {
      return {
        ...state,
        newObject: {
          ...state.newObject,
          lengthInCm: action.payload.length
        }
      }
    }
    case ObjectTypes.SET_DESCRIPTION:
      return { ...state, newObject: { ...state.newObject, description: action.payload.description } }
    case ObjectTypes.SET_OBJECT_SITUATION_REASON:
      return {
        ...state,
        newObject: { ...state.newObject, objectSituationReason: action.payload.objectSituationReason }
      }
    case ObjectTypes.SET_LOCATION:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          locationId: action.payload.location
        }
      }
    case ObjectTypes.SET_NAME:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          name: action.payload.name
        }
      }
    case ObjectTypes.SET_NEEDS_AUTHORIZATION:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          needsAuthorization: action.payload.needsAuthorization
        }
      }
    case ObjectTypes.SET_RENT_ENABLED:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          rentEnabled: action.payload.rentEnabled
        }
      }
    case ObjectTypes.SET_RENT_PRICE:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          rentPrice: action.payload.rentPrice
        }
      }
    case ObjectTypes.SET_OBJECT_SITUATION:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          objectSituation: action.payload.objectSituation
        }
      }
    case ObjectTypes.SET_OBJECT_STATUS_ID:
      return {
        ...state,
        newObject: {
          ...state.newObject,
          objectStatusId: action.payload.objectStatus
        }
      }
    case ObjectTypes.SET_PRODUCT: {
      const product = action.payload.product
      const deletedAttributes =
        state.newObject.attributes?.reduce<ObjectAttributeRequest[]>((acc, attribute) => {
          if (attribute.id) {
            return [...acc, { ...attribute, deleted: true }]
          }
          return acc
        }, []) || []

      return {
        ...state,
        selectedProduct: product,
        newObject: {
          ...state.newObject,
          productId: product?.id || 0,
          heightInCm: product?.heightInCm || 0,
          widthInCm: product?.widthInCm || 0,
          lengthInCm: product?.lengthInCm || 0,
          weightInGr: product?.weightInGr || 0,
          attributes: product ? [...deletedAttributes, attributeTemplate] : deletedAttributes
        }
      }
    }
    case ObjectTypes.GET_PRODUCTS_REQUEST: {
      return {
        ...state,
        loadingProducts: true,
        lastProductSearchID: action.payload.lastSearchID
      }
    }
    case ObjectTypes.MODIFY_OBJECT_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: ''
      }
    case ObjectTypes.MODIFY_OBJECT_FAILURE:
      return { ...state, isSaving: false, error: action.payload.error }
    case ObjectTypes.MODIFY_OBJECT_SUCCESS:
    case ObjectTypes.CANCEL:
      return {
        ...initialRoot,
        products: state.products,
        objectSituations: state.objectSituations
      }
    case ObjectTypes.GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload.products, loadingProducts: false }
    case ObjectTypes.GET_LOCATION_BY_CODE_REQUEST:
      return { ...state, loadingLocation: true, locationError: '' }
    case ObjectTypes.GET_LOCATION_BY_CODE_SUCCESS:
      return {
        ...state,
        loadingLocation: false,
        newLocation: action.payload.location,
        newObject: {
          ...state.newObject,
          locationId: action.payload.location.id
        }
      }
    case ObjectTypes.GET_LOCATION_BY_CODE_FAILURE:
      return {
        ...state,
        loadingLocation: false,
        locationError: action.payload.error
      }
    case ObjectTypes.GET_OBJECT_SITUATION_REQUEST:
      return { ...state, loadingObjectSituations: true, error: '' }
    case ObjectTypes.GET_OBJECT_SITUATION_SUCCESS:
      return {
        ...state,
        loadingObjectSituations: false,
        objectSituations: action.payload.objectSituations
      }
    case ObjectTypes.GET_OBJECT_SITUATION_FAILURE:
      return {
        ...state,
        loadingObjectSituations: false,
        error: action.payload.error
      }
    case ObjectTypes.GET_PRODUCTS_FAILURE:
      return { ...state, loadingProducts: false, error: action.payload.error }
    case ObjectTypes.GET_PRODUCT_ATTRIBUTES_REQUEST:
      return { ...state, loadingProductAttributes: true, error: '' }
    case ObjectTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS:
      return { ...state, loadingProductAttributes: false, productAttributes: action.payload.attributes }
    case ObjectTypes.GET_PRODUCT_ATTRIBUTES_FAILURE:
      return { ...state, error: action.payload.error, loadingProductAttributes: false }
    case ObjectTypes.SET_ATTRIBUTE_TYPE: {
      const { index, attributeId, newAttributeTemplate } = action.payload
      const newAttributes =
        state.newObject.attributes?.map((attribute, i) => {
          if (i === index) {
            return { ...attribute, attributeId }
          }
          return attribute
        }) || []
      return {
        ...state,
        newObject: {
          ...state.newObject,
          attributes: newAttributeTemplate ? [...newAttributes, attributeTemplate] : newAttributes
        }
      }
    }
    case ObjectTypes.SET_ATTRIBUTE_VALUE: {
      const { index, value } = action.payload

      const newAttributes = state.newObject.attributes?.map((attribute, i) => {
        if (i === index) {
          return { ...attribute, value }
        }
        return attribute
      })
      return { ...state, newObject: { ...state.newObject, attributes: newAttributes } }
    }
    case ObjectTypes.DELETE_ATTRIBUTE: {
      const { index, newAttributeTemplate } = action.payload
      const newAttributes =
        state.newObject.attributes?.reduce<ObjectAttributeRequest[]>((acc, attribute, i) => {
          if (i === index) {
            if (attribute.id) {
              return [...acc, { ...attribute, deleted: true }]
            }
            return acc
          }
          return [...acc, attribute]
        }, []) || []

      return {
        ...state,
        newObject: {
          ...state.newObject,
          attributes: newAttributeTemplate ? [...newAttributes, attributeTemplate] : newAttributes
        }
      }
    }
    default:
      return state
  }
}

export default root
