import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import searchUserActionCreators from '../actionCreators/SearchUser'
import BillingPaymentTable from '../components/BillingPaymentTable'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { useHistory } from 'react-router'
import { parseQueryParam, removeNullishValues } from '../../../components/DynamicTable/queryParamsUtils'
import {
  BillingPaymentTableCategoryFilter,
  BillingPaymentTableSort,
  BillingPaymentTableSortKey,
  Pagination
} from '../types/BillingPayment'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { sendToastNotificationInfo } from '../../../utils/notifications'
import { BillingPaymentStatusId } from '../constants'

const BillingPaymentContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const searchUserActions = bindActionCreators(searchUserActionCreators, dispatch)
  const { billingPayment } = useSelector((state) => state.BillingPayment)
  const { error, loading, userColppyInfo, billings, selectedBillings } = billingPayment
  const { getBillingsByUser, toggleSelectedBilling, toggleAllBillings } = searchUserActions

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    clientId: StringParam,
    billingId: StringParam,
    sortField: StringParam,
    sortDirection: StringParam,
    BillingDate: StringParam,
    id: NumberParam,
    status: StringParam
  })

  const emptyPagination = { ...billingPayment.pagination, page: 1 }

  const handleToggleBilling = (bi: Billing) => toggleSelectedBilling(bi)
  const handleGetBillings = (
    newParams: {
      pagination?: Pagination
      sort?: BillingPaymentTableSort
      categoryFilter?: BillingPaymentTableCategoryFilter
    },
    userId: number
  ) => {
    const actualParams = {
      pagination: newParams.pagination || billingPayment.pagination,
      sort: newParams.sort || billingPayment.sort,
      categoryFilter: newParams.categoryFilter || billingPayment.categoryFilter
    }
    getBillingsByUser({ userId, ...actualParams })
    const newQuery = {
      id: userId,
      page: actualParams.pagination.page,
      sortField: actualParams.sort.field,
      status: actualParams.categoryFilter.status as BillingPaymentStatusId[]
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const getUserDataById = async (userId: number) => {
    await searchUserActions.getInfoAboutUserHasDebts(userId)
  }

  useEffect(() => {
    if (!query.id) history.push('/billings_payments')
  }, [])

  useEffect(() => {
    if (userColppyInfo) {
      handleGetBillings(
        {
          pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
          categoryFilter: {
            ...billingPayment.categoryFilter,
            status: query.status
              ? (parseQueryParam(query.status, 'string') as BillingPaymentStatusId[])
              : billingPayment.categoryFilter.status
          },
          sort:
            query.sortField && query.sortDirection
              ? {
                  ...billingPayment.sort,
                  field: query.sortField as BillingPaymentTableSortKey,
                  direction: query.sortDirection as SortDirection
                }
              : billingPayment.sort
        },
        Number(userColppyInfo?.id)
      )
    } else {
      query.id && getUserDataById(query.id)
    }
  }, [userColppyInfo?.id])

  const handlePageChange = (newPage: number) => {
    if (userColppyInfo)
      handleGetBillings({ pagination: { ...billingPayment.pagination, page: newPage } }, Number(userColppyInfo?.id))
  }

  const handleCategoryFilter = (newCategoryFilter: BillingPaymentTableCategoryFilter) => {
    handleGetBillings({ categoryFilter: newCategoryFilter, pagination: emptyPagination }, Number(userColppyInfo?.id))
  }

  const handleSort = (newSort: BillingPaymentTableSort) => {
    handleGetBillings({ sort: newSort }, Number(userColppyInfo?.id))
  }

  useEvents(Events.BillingPayment.AUTHORIZATION_EXISTS, ({ pdfUrl }) => window.open(pdfUrl))
  useEvents(Events.BillingPayment.AUTHORIZATION_DOESNT_EXIST, () => {
    sendToastNotificationInfo('No existe factura.')
  })

  const props = {
    searchUserActions,
    error,
    loading,
    userColppyInfo,
    billings,
    pagination: {
      currentPage: billingPayment.pagination.page,
      pageSize: billingPayment.pagination.pageSize,
      total: billingPayment.pagination.total,
      onPageChange: handlePageChange
    },
    sort: billingPayment.sort,
    handleSort,
    selectedBillings,
    handleToggleAllBillings: toggleAllBillings,
    handleToggleBilling,
    handleCategoryFilter,
    categoryFilter: billingPayment.categoryFilter
  }

  return <BillingPaymentTable {...props} />
}

export default BillingPaymentContainer
