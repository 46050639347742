import React, { useEffect } from 'react'

import { CreateNewProduct } from '../components/CreateNewProduct'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ProductCostsActionCreator } from '../actionCreator/productCosts'
import { GeneralDataActionCreator } from '../actionCreator/generalData'
import { useParams } from 'react-router'
import { useQueryParam } from 'use-query-params'
import { ProductByCatalog } from '@/projectApi/CategoryCreation/Catalog/create'

const CreateNewProductContainer = () => {
  const dispatch = useDispatch()
  const generalDataState = useSelector((state) => state.NewProduct.generalData)
  const productCostsState = useSelector((state) => state.NewProduct.productCosts)
  const { createNewProduct, productCostCleanState, updateProduct, createProductByCatalog } = bindActionCreators(
    ProductCostsActionCreator,
    dispatch
  )
  const { generalDataCleanState, setEditMode, getProductById, getCategories, getSolutions } = bindActionCreators(
    GeneralDataActionCreator,
    dispatch
  )

  const handleCreateNewProduct = () => {
    createNewProduct(generalDataState, productCostsState)
  }

  const handleCreateNewByCatalogProduct = () => {
    const { productDetail } = generalDataState
    const productInformation: ProductByCatalog = {
      description: productCostsState.ARG.productName,
      weightInGr: Number(generalDataState.dimensionsWeight),
      lengthInCm: Number(generalDataState.dimensionsLength),
      widthInCm: Number(generalDataState.dimensionsWidth),
      catalogName: productDetail?.catalogName || '',
      category: {
        externalId: productDetail?.category.id || '',
        catalogName: productDetail?.category.catalogName || ''
      },
      solution: 1,
      oldCategory: [generalDataState.categorySelected?.value || 0],
      heightInCm: Number(generalDataState.dimensionsHeight),
      multiplier: Number(generalDataState.multiplier),
      photoMin: Number(generalDataState.minPhotos),
      productCatalogId: productDetail?.productCatalogId || '',
      productByCountries: Object.entries(productCostsState).map(([key, value]) => ({
        countryId: key,
        name: value.productName,
        photo: {
          url: productDetail?.productByCountries[0].photo.url || ''
        },
        depositCostPerFloorInCents: value.isDepositAutomaticPrice
          ? value.depositAutomaticPrice * 100
          : Number(value.depositCostPerFloor) * 100,
        packagingCostInCents: Number(value.depositPackagingCost) * 100,
        disassemblyCost: Number(value.depositDisassemblyCost),
        costPerFloorInCents: value.isRemovalAutomaticPrice
          ? value.removalAutomaticPrice * 100
          : Number(value.removalCostPerFloor) * 100,
        assemblyCostInCents: Number(value.removalAssemblyCost) * 100,
        unpackagingCostInCents: Number(value.removalUnpackagingCost) * 100,
        maxAmountAvowedInCents: Number(value.maxAmountAvowed) * 100,
        percentageAvowed: Number(value.percentageOnDeclaredPrice),
        maxRefundAmountInCents: Number(value.maxRefundValue) * 100,
        eligibleForSale: value.eligibility.isElegibleForSale,
        eligibleForDonation: value.eligibility.isElegibleForDonation,
        eligibleForRecycled: value.eligibility.isElegibleForRecycled,
        eligibleForRent: value.eligibility.isElegibleForRent
      }))
    }
    createProductByCatalog(productInformation)
  }

  const handleUpdateProduct = () => {
    updateProduct({ generalData: generalDataState, productCosts: productCostsState })
  }

  const useParsedParams = (): { productId: number } => {
    const data = useParams<{ productId: string }>()

    return { productId: parseInt(data.productId) }
  }

  const { productId } = useParsedParams()

  useEffect(() => {
    getCategories()
    getSolutions()
  }, [])

  useEffect(() => {
    if (productId) {
      setEditMode(true)
      getProductById(productId)
    }
  }, [productId])

  useEffect(() => {
    return () => {
      productCostCleanState()
      generalDataCleanState()
    }
  }, [])

  const isFromCatalog = useQueryParam('byCatalog')[0] === 'true'

  return (
    <CreateNewProduct
      handleCreateNewProduct={handleCreateNewProduct}
      generalDataState={generalDataState}
      productCostsState={productCostsState}
      productCostCleanState={productCostCleanState}
      generalDataCleanState={generalDataCleanState}
      setEditMode={setEditMode}
      loadingSaveProduct={generalDataState.loadingSaveProduct}
      handleUpdateProduct={handleUpdateProduct}
      isFromCatalog={isFromCatalog}
      handleCreateNewByCatalogProduct={handleCreateNewByCatalogProduct}
    />
  )
}

export default CreateNewProductContainer
