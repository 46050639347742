import types from '../types/removeObject'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const actions = {
  getObjectsDeletedReasons: () => (dispatch) => {
    dispatch({ type: types.GET_OBJECT_DELETED_REASONS_REQUEST })

    return apiPrivate
      .get(URL_BASE_OBJECTS + '/object-deleted-reasons/search')
      .then((response) => {
        const results = response.data.results
        return dispatch(actions.getObjectsDeletedReasonsSuccess(results))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.GET_OBJECT_DELETED_REASONS_FAILURE, dispatch)
      })
  },
  getObjectsDeletedReasonsSuccess: (objectDeletedReasons) => ({
    type: types.GET_OBJECT_DELETED_REASONS_SUCCESS,
    payload: { objectDeletedReasons }
  }),
  setDeletedReason: (deletedReason) => ({
    type: types.SET_DELETED_REASON,
    payload: { deletedReason }
  }),
  setReason: (reason) => ({
    type: types.SET_REASON,
    payload: { reason }
  }),
  openReasonModal: (objectId) => ({
    type: types.OPEN_REASON_MODAL,
    payload: {
      objectId
    }
  }),
  cancelReasonModal: () => ({ type: types.CANCEL_REASON_MODAL }),
  closeReasonModal: () => ({ type: types.CLOSE_REASON_MODAL }),
  removeSelectObject: (objectId, reasonID, description) => {
    return function (dispatch) {
      dispatch({ type: types.REMOVE_OBJECT_REQUEST })
      const form = new FormData()
      form.append('deleted_reason_id', reasonID)
      form.append('description', description)
      return apiPrivate.post(`${URL_BASE_OBJECTS}/object/${objectId}/delete`, form).then(
        () => {
          dispatch(actions.removeSelectObjectSuccess(objectId, reasonID))
          Emitter.emit(Events.Objects.OBJECT_DELETED)
          return true
        },
        (error) => {
          handleErrorsWithAction(error, types.REMOVE_OBJECT_FAILURE, dispatch)
          return false
        }
      )
    }
  },
  removeSelectObjectSuccess: (objectId, reason) => ({
    type: types.REMOVE_OBJECT_SUCCESS,
    payload: {
      objectId,
      reason
    }
  }),
  removeObjectFail: (error) => ({
    type: types.REMOVE_OBJECT_FAILURE,
    payload: { error }
  })
}

export default actions
