import eventEmitter from '../../../utils/eventEmitter'
import { API } from '../../../projectApi'
import { CreateLocationParams } from '../../../projectApi/TransactionHandler/Locations/create'
import { EditLocationParams } from '../../../projectApi/TransactionHandler/Locations/edit'
import { AppThunk } from '../../../store'
import { getErrorMessage, handleErrorsWithAction } from '../../../utils/HandleErrors'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  AddEditLocationModalTypes,
  ClearState,
  ClearStateTypes,
  CreateLocationTypes,
  CreateLocationsRequest,
  CreateLocationsSuccess,
  EditLocationTypes,
  EditLocationsRequest,
  EditLocationsSuccess,
  GetDepositLocationTypes,
  GetDepositsLocationRequest,
  GetLocationTypes,
  GetLocationsRequest,
  GetLocationsSuccess,
  LocationAddEditPossibleNumberFields,
  LocationAddEditPossibleStringFields,
  SelectedLocation,
  SetLocationAddEditFields,
  SetModalClose,
  SetModalOpen,
  SetNumberField,
  SetStringField
} from '../types/AddEditLocationModal'

const LIMIT = 10
let searchTimer: NodeJS.Timeout
let getItemPricesOldLastSearchId: number

const AddEditLocationModalActionCreators = {
  getLocationTypes(): AppThunk {
    return async (dispatch) => {
      const request: GetLocationsRequest = {
        type: GetLocationTypes.GET_LOCATION_TYPES_REQUEST
      }
      dispatch(request)
      try {
        const result = await API.TransactionHandler.Location.listTypes()
        const success: GetLocationsSuccess = {
          type: GetLocationTypes.GET_LOCATION_TYPES_SUCCESS,
          payload: { locationTypes: result }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetLocationTypes.GET_LOCATION_TYPES_FAILURE, dispatch)
      }
    }
  },
  createLocation(params: CreateLocationParams): AppThunk {
    return async (dispatch) => {
      const request: CreateLocationsRequest = {
        type: CreateLocationTypes.CREATE_LOCATION_REQUEST
      }

      const toast = setToastLoading('Creando ubicación. Por favor espere...')
      dispatch(request)
      try {
        await API.TransactionHandler.Location.create(params)
        const success: CreateLocationsSuccess = {
          type: CreateLocationTypes.CREATE_LOCATION_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Ubicación creada.' })
        eventEmitter.emit(Events.Location.LOCATION_CREATED)
        dispatch(success)
      } catch (error: any) {
        const message = getErrorMessage(error)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, CreateLocationTypes.CREATE_LOCATION_FAILURE, dispatch)
      }
    }
  },
  editLocation(params: EditLocationParams): AppThunk {
    return async (dispatch) => {
      const request: EditLocationsRequest = {
        type: EditLocationTypes.EDIT_LOCATION_REQUEST
      }
      const toast = setToastLoading('Editando ubicación. Por favor espere...')

      dispatch(request)
      try {
        await API.TransactionHandler.Location.edit(params)
        const success: EditLocationsSuccess = {
          type: EditLocationTypes.EDIT_LOCATION_SUCCESS
        }

        setToastSuccessUpdate(toast, { render: 'Ubicación editada.' })
        eventEmitter.emit(Events.Location.LOCATION_UPDATED)
        dispatch(success)
      } catch (error: any) {
        const message = getErrorMessage(error)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, EditLocationTypes.EDIT_LOCATION_FAILURE, dispatch)
      }
    }
  },
  openModal: (selectedLocation?: SelectedLocation): SetModalOpen => {
    return {
      type: AddEditLocationModalTypes.SET_MODAL_OPEN,
      payload: {
        selectedLocation
      }
    }
  },
  closeModal: (): SetModalClose => {
    return {
      type: AddEditLocationModalTypes.SET_MODAL_CLOSE
    }
  },
  setStringField({ field, value }: { field: LocationAddEditPossibleStringFields; value: string }): SetStringField {
    return {
      type: SetLocationAddEditFields.SET_STRING_FIELD,
      payload: {
        field,
        value
      }
    }
  },
  setNumberField({ field, value }: { field: LocationAddEditPossibleNumberFields; value: number }): SetNumberField {
    return {
      type: SetLocationAddEditFields.SET_NUMBER_FIELD,
      payload: {
        field,
        value
      }
    }
  },
  getDeposits:
    ({ name, id }: { name?: string; id?: number }): AppThunk =>
    (dispatch) => {
      const request: GetDepositsLocationRequest = {
        type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_REQUEST
      }

      const toast = setToastLoading('Buscando ubicaciones. Por favor espere...')

      dispatch(request)
      clearTimeout(searchTimer)
      searchTimer = setTimeout(async () => {
        const params = {
          limit: LIMIT,
          offset: 0,
          name,
          id,
          allDeposits: true
        }

        const lastSearchID = new Date().getMilliseconds()
        getItemPricesOldLastSearchId = lastSearchID

        dispatch({ type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_REQUEST })

        try {
          if (lastSearchID === getItemPricesOldLastSearchId) {
            const { deposits } = await API.TransactionHandler.Deposits.Admin.list(params)
            setToastSuccessUpdate(toast, { render: 'Ubicaciones encontradas.' })
            dispatch({
              type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_SUCCESS,
              payload: {
                deposits
              }
            })
          }
        } catch (e: any) {
          const message = getErrorMessage(e)

          setToastErrorUpdate(toast, { render: message })
          handleErrorsWithAction(e, GetDepositLocationTypes.GET_DEPOSITS_LOCATION_FAILURE, dispatch)
        }
      }, 250)
    },
  clearState(): ClearState {
    return {
      type: ClearStateTypes.CLEAR_STATE
    }
  }
}

export default AddEditLocationModalActionCreators
