import moment from 'moment'
import React, { ReactElement } from 'react'
import { SearchProps } from '../../components/DynamicTable/types/types'
import { SearchFilter } from './entities'

export const getSearchProps = (
  key: string,
  label: string,
  searchFunction: (key: string, newValue: string) => void,
  currentSearchFilters: SearchFilter[]
): SearchProps | undefined => {
  const found = currentSearchFilters.find((sf) => sf.key === key)
  if (!found) return

  return {
    label,
    value: found.text,
    onConfirm: (newValue) => searchFunction(key, newValue)
  }
}

export const parseDate = (stringDate: string): ReactElement => {
  return moment(stringDate).isValid() ? <>{moment(stringDate).format('dd DD[-]MM[-]YY')}</> : <span />
}
