import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type FileBody = {
  file: File
  fileType: string
}

export async function uploadFile(body: FileBody): Promise<boolean> {
  const formData = new FormData()
  formData.append('csv_file', body.file)
  formData.append('file_type', body.fileType)

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/colppy/payments-with-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((_) => true)
}
