import React, { useMemo } from 'react'
import { UserState } from '../reducers/users'
import { DEPOSITED } from '../../Objects/constants/objectStatus'
import { ObjectResponse } from '../types/objects'
import { useGetObjectsToRemove } from '../hooks'
import { ObjectsState } from '../reducers/objects'
import { PaymentMpState } from '../reducers/payment-mp'
import ColInfo, { ColInfoProps } from '../../../components/ColInfo/ColInfo'
import ColInfoContainer from '../../../components/ColInfoContainer/ColInfoContainer'

const styles = {
  prospectDataContainer: {
    display: 'flex',
    justifyContent: 'space-between' as const
  }
}

type UserDataProps = {
  userModalState: UserState
  paymentMPState: PaymentMpState
  paymentCBUState: any
  objectsState: ObjectsState
}

const ProspectSearch = (props: UserDataProps) => {
  const { userModalState, objectsState } = props

  const depositObjects = useMemo(
    () =>
      objectsState.objects.filter((object: ObjectResponse) => object.ObjectStatus.ID === DEPOSITED.numberValue).length,
    [objectsState]
  )
  const remainingObjects = useMemo(() => userModalState.totalObjects - depositObjects, [objectsState])
  const staticsObjects = useMemo(
    () => objectsState.objects.filter((object: ObjectResponse) => object.Location.type?.toUpperCase() === 'STATIC'),
    [objectsState]
  )
  const selectedObjects = useGetObjectsToRemove(objectsState.objectsToRemove).length

  const colsInformation = [
    { label: 'Obj. con ubicación "STATIC"', value: staticsObjects.length },
    { label: 'Objetos depositados', value: depositObjects },
    { label: 'Objetos en otro estado', value: remainingObjects },
    { label: 'Objetos totales', value: selectedObjects, bold: true }
  ]

  return (
    <div style={styles.prospectDataContainer}>
      <ColInfoContainer flex>
        {colsInformation.map((col: ColInfoProps) => (
          <ColInfo key={col.label} {...col} />
        ))}
      </ColInfoContainer>
      <ColInfoContainer blue>
        <ColInfo label="Objetos seleccionados" value={selectedObjects} blue bold />
      </ColInfoContainer>
    </div>
  )
}

export default ProspectSearch
