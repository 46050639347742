import types from '../types/page'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { handleErrorsWithActionLoginAs } from '../../../utils/HandleErrors'
import { apiPrivate } from '../../../api'
import config from '../../../config'

const actions = {
  openPanel(operationId, userId, name, lastName) {
    return {
      type: types.OPEN,
      payload: { operationId, userId, name, lastName }
    }
  },
  close() {
    return { type: types.CLOSE }
  },
  generate(operationId) {
    return (dispatch) => {
      dispatch({ type: types.GENERATE_REQUEST })

      const form = new FormData()

      form.append('deposit_id', operationId)

      return apiPrivate
        .post(URL_BASE_TRANSACTIONS + '/pages/deposit/card', form)
        .then((response) => {
          const code = response.data.description.code
          const link = config.WEB_APP_URL + '/tarjeta/' + code
          dispatch(actions.generateSuccess(link))
        })
        .catch((error) => {
          handleErrorsWithActionLoginAs(error, types.GENERATE_FAILURE, dispatch)
        })
    }
  },
  generateSuccess: (link) => ({
    type: types.GENERATE_SUCCESS,
    payload: { link }
  })
}

export default actions
