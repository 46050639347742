import { Moment } from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import { DepositStatus } from '../../../projectApi/TransactionHandler/Operation/list'
import depositsValidationTableActionCreators from '../actionCreators/table'
import DepositsValidationTable, { DepositsValidationTableProps } from '../components/table'
import {
  DepositsValidationDateRangeFilter,
  DepositsValidationDateRangeKey,
  DepositsValidationSearchKey,
  DepositsValidationTableCategoryFilter,
  DepositsValidationTableSearchFilter,
  Pagination
} from '../types/table'
import depositValidationMetricsActionsCreator from '../actionCreators/depositsValidationMetrics'

const DepositsValidationTableContainer = () => {
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.DepositsValidation.table)
  const { fetchingMetrics, quantityMetrics } = useSelector((root) => root.DepositsValidation.metrics)
  const history = useHistory()
  const dispatch = useDispatch()
  const getDeposits = bindActionCreators(depositsValidationTableActionCreators.getDeposits, dispatch)
  const { getMetrics } = bindActionCreators(depositValidationMetricsActionsCreator, dispatch)

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetDeposits = (newParams: {
    pagination?: Pagination
    searchFilters?: DepositsValidationTableSearchFilter[]
    dateRangeFilters?: DepositsValidationDateRangeFilter[]
    categoryFilter?: DepositsValidationTableCategoryFilter
  }) => {
    const categoryFilterHandler = newParams.categoryFilter
      ? {
          ...newParams.categoryFilter,
          status: newParams.categoryFilter.status.length
            ? newParams.categoryFilter.status
            : [DepositStatus.DEPOSITED, DepositStatus.VALIDATED]
        }
      : tableState.categoryFilter
    getDeposits({
      pagination: newParams.pagination || tableState.pagination,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      categoryFilter: categoryFilterHandler,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    })
    getMetrics({
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      categoryFilter: categoryFilterHandler
    })
  }

  useEffect(() => {
    handleGetDeposits({ pagination: emptyPagination })
  }, [countryCode])

  const handlePageChange = (newPage: number) => {
    handleGetDeposits({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleCategoryFilter = (newCategoryFilter: DepositsValidationTableCategoryFilter) => {
    handleGetDeposits({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleRangePicker = (key: DepositsValidationDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetDeposits({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
  }

  const handleSearch = (key: DepositsValidationSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetDeposits({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetRangeFilters = tableState.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))
    const resetPagination = emptyPagination

    handleGetDeposits({
      pagination: resetPagination,
      searchFilters: resetSearchFilters,
      categoryFilter: { status: [DepositStatus.DEPOSITED, DepositStatus.VALIDATED] },
      dateRangeFilters: resetRangeFilters
    })
  }

  const handleOpenValidationView = (id: number) => {
    history.push(`/depositsValidation/${id}`)
  }

  const props: DepositsValidationTableProps = {
    deposits: tableState.deposits,
    loading: tableState.loading,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    dateRangeFilters: tableState.dateRangeFilters,
    categoryFilter: tableState.categoryFilter,
    handleCategoryFilter,
    handleResetFilters,
    handleSearch,
    handleRangePicker,
    handleOpenValidationView,
    fetchingMetrics,
    quantityMetrics
  }

  return <DepositsValidationTable {...props} />
}

export default DepositsValidationTableContainer
