import { create } from './create'
import { deleteService } from './delete'
import { search } from './search'
import { update } from './update'

export const ServiceTypesAPI = {
  create,
  delete: deleteService,
  search,
  update
}
