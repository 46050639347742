import { Radio } from 'antd'
import React from 'react'
import styles from './stepOption.module.scss'

const StepOption = ({
  Icon,
  cardType,
  fourDigits,
  description,
  month,
  year,
  option
}: {
  Icon: JSX.Element
  cardType?: string
  fourDigits?: string
  description?: string
  month?: number
  year?: string
  option: number
}) => {
  return (
    <div key={option} className={styles.itemPayment}>
      <div className={styles.colLeft}>
        <div className={styles.divIcon}>{Icon}</div>
        <div>
          <div>
            {!description ? (
              <span className={styles.titleItem}>{`${cardType} **** ${fourDigits}`}</span>
            ) : (
              <span className={styles.description}>{description}</span>
            )}
          </div>
          {!description && (
            <div>
              <span className={styles.descriptionItem}>
                Vto. {month}/{year}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <Radio key={option} value={option} className={styles.radioStyle} />
      </div>
    </div>
  )
}

export default StepOption
