import { Operation } from '../../../projectApi/TransactionHandler/Proposals/common'
import { ProposalListSortKey } from '../../../projectApi/TransactionHandler/Proposals/list'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type ProposalsTableSortKey = ProposalListSortKey
export type ProposalsTableSort = Sort<ProposalsTableSortKey>

export type ProposalsTableSearchKey = 'id' | 'prospect_id'
export type ProposalsSearchFilter = SearchFilter<ProposalsTableSearchKey>

export type ProposalsTableDateRangeKey = 'createdAt'

export type ProposalsDateRangeFilter = DateRangeFilter<ProposalsTableDateRangeKey>

export type ProposalCategoryFilterKey = 'status'

export type ProposalsTableCategoryFilter = CategoryFilter<ProposalCategoryFilterKey>

export const DEFAULT_PROPOSAL_CATEGORY_FILTERS = { status: [] }

export type ParamsType = {
  Limit: number
  Offset: number
  Column: ProposalListSortKey
  Order: string
}

export type SearchFiltersType = [
  {
    key: string
    text: number
  }
]
export interface ProposalsTableState {
  proposals: Operation[]
  loadingProposals: boolean
  error: string
  pagination: Pagination
  params: ParamsType
  searchFilters: ProposalsSearchFilter[]
  dateRangeFilters: ProposalsDateRangeFilter[]
  Column: ProposalsTableSort
  sendingCSV: boolean
  categoryFilter: ProposalsTableCategoryFilter
}

export const GET_PROPOSALS_REQUEST = 'PROPOSALS/TABLE/GET_PROPOSALS_REQUEST'
export const GET_PROPOSALS_SUCCESS = 'PROPOSALS/TABLE/GET_PROPOSALS_SUCCESS'
export const GET_PROPOSALS_FAILURE = 'PROPOSALS/TABLE/GET_PROPOSALS_FAILURE'

export interface GetProposalsRequest {
  type: typeof GET_PROPOSALS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: ProposalsSearchFilter[]
    dateRangeFilters?: ProposalsDateRangeFilter[]
    categoryFilter?: ProposalsTableCategoryFilter
    Column?: ProposalsTableSort
  }
}

export interface GetProposalsSuccess {
  type: typeof GET_PROPOSALS_SUCCESS
  payload: {
    proposals: Operation[]
    total: number
  }
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
  csvSize: number
}

export interface GetProposalsFailure {
  type: typeof GET_PROPOSALS_FAILURE
  payload: {
    error: string
  }
}

export const SEND_CSV_REQUEST = 'PROPOSALS/TABLE/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'PROPOSALS/TABLE/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'PROPOSALS/TABLE/SEND_CSV_FAILURE'

export interface SendCSVRequest {
  type: typeof SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SEND_CSV_FAILURE
}

export const CLEAR_STATE = 'PROPOSALS/TABLE/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export type GetProposalsActions = GetProposalsRequest | GetProposalsSuccess | GetProposalsFailure | SendCSVActions

export type ProposalsTableActions = GetProposalsActions | ClearState
