import React from 'react'

const Tag: React.FC = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_452_4054)">
        <path
          d="M14.1564 7.10734L13.6939 1.87408C13.6705 1.60288 13.4689 1.38859 13.2158 1.36181L8.33139 0.866272H8.32514C8.27514 0.866272 8.23608 0.883013 8.20639 0.914821L0.8892 8.75467C0.874715 8.77015 0.863223 8.78855 0.855383 8.8088C0.847542 8.82905 0.843506 8.85076 0.843506 8.87269C0.843506 8.89462 0.847542 8.91633 0.855383 8.93658C0.863223 8.95683 0.874715 8.97523 0.8892 8.99071L6.57358 15.0811C6.60326 15.1129 6.64233 15.1297 6.68451 15.1297C6.7267 15.1297 6.76576 15.1129 6.79545 15.0811L14.1126 7.24127C14.1439 7.20612 14.1595 7.15757 14.1564 7.10734ZM6.68295 13.4003L2.45795 8.87353L8.70326 2.18212L12.5626 2.57386L12.9283 6.70891L6.68295 13.4003ZM10.1251 3.71225C9.36733 3.71225 8.75014 4.37353 8.75014 5.18547C8.75014 5.99741 9.36733 6.65868 10.1251 6.65868C10.883 6.65868 11.5001 5.99741 11.5001 5.18547C11.5001 4.37353 10.883 3.71225 10.1251 3.71225ZM10.1251 5.72118C9.84858 5.72118 9.62514 5.48179 9.62514 5.18547C9.62514 4.88915 9.84858 4.64975 10.1251 4.64975C10.4017 4.64975 10.6251 4.88915 10.6251 5.18547C10.6251 5.48179 10.4017 5.72118 10.1251 5.72118Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_452_4054">
          <rect width="14" height="15" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Tag
