export enum TransactionStatusId {
  CREADA = 1,
  PENDIENTE = 2,
  ASIGNADA_A_VIAJE = 3,
  REALIZADA = 4,
  ERRONEA = 5,
  CANCELADA = 6,
  LEVANTADA = 7,
  ENTREGADA = 8,
  EN_CURSO = 9,
  EN_DESTINO = 10,
  LISTO_PARA_VIAJAR = 11
}

export enum TransactionTypeId {
  PICKUP_ALQUILER = 1,
  DELIVERY_ALQUILER = 2,
  PICKUP_DEPOSITO = 3,
  DELIVERY_DEPOSITO = 4,
  PICKUP_DEPOSITO_EXISTENTE = 5
}
