import types from '../types/defaultCBUs'
import { URL_BASE_PROFILE } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

export default {
  setDefaultCbu(cbuId, isMain = true) {
    const toastId = setToastLoading('Marcando CBU como principal, por favor espere...')
    const formData = new FormData()
    formData.append('main', isMain)

    return (dispatch) => {
      dispatch({ type: types.DEFAULT_CBU_REQUEST })
      return apiPrivate
        .put(URL_BASE_PROFILE + `/admin/cbu/id/${cbuId}`, formData)
        .then((_) => {
          setToastSuccessUpdate(toastId, {
            render: 'CBU marcado como principal'
          })
          dispatch({ type: types.DEFAULT_CBU_SUCCESS })
        })
        .catch((error) => {
          setToastErrorUpdate(toastId, {
            render: 'Error al marcar CBU como principal'
          })
          handleErrorsWithAction(error, types.DEFAULT_CBU_FAILURE, dispatch)
        })
    }
  },
  openModal(userId, cbuId, cbuText, owner, bankName) {
    return {
      type: types.OPEN_DEFAULT_CBU_MODAL,
      payload: { userId, cbuId, cbuText, owner, bankName }
    }
  },
  closeModal() {
    return { type: types.CLOSE_DEFAULT_CBU_MODAL }
  }
}
