import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { Edit } from '../../../icons'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { ProcessedOperationDay } from '../types/operationDays'
import OperationBadge from './OperationBadge'
import { Icon } from 'antd'
import styles from './operationDays.module.scss'
import moment from 'moment'
import { OperationTypeValues } from '../types/common'

type EditDayModalProps = {
  isEditModalOpen: boolean
  selectedDay: string
}

type OperationDaysTableProps = {
  daysData: ProcessedOperationDay[]
  loadingDays: boolean
  openEditDayModal: (args: EditDayModalProps) => void
  setAvailableOperations: ({ availableOperations }: { availableOperations: OperationTypeValues[] }) => void
}

export const OperationDaysTable = ({
  daysData,
  loadingDays,
  openEditDayModal,
  setAvailableOperations
}: OperationDaysTableProps) => {
  const columns: Column<ProcessedOperationDay>[] = [
    {
      key: 'day',
      label: 'Día',
      renderDataCell: ({ day }) => {
        const currentDayName = moment().locale('es').format('dddd')

        const formattedCurrentDayName = currentDayName.charAt(0).toUpperCase() + currentDayName.slice(1)

        return (
          <div className={styles.WeekDayWrapper}>
            <span className={styles.WeekDayIndicator}>
              {formattedCurrentDayName === day && <Icon type="caret-right" className={styles.currentDay} />}
            </span>

            <p className={styles.WeekDay}>{day}</p>
          </div>
        )
      }
    },
    {
      key: 'operationAvailables',
      label: 'Operación',
      renderDataCell: ({ availableOperations }) =>
        availableOperations.length === 0 ? (
          <OperationBadge key="none" operation={OperationTypeValues.NONE} />
        ) : (
          <div className={styles.OperationBadgesWrapper}>
            {availableOperations.map((operation, index) => (
              <OperationBadge key={index} operation={operation} />
            ))}
          </div>
        )
    }
  ]

  const menuActions: MenuAction<ProcessedOperationDay>[] = [
    {
      label: 'Editar operación',
      icon: <Edit />,
      onClick: ({ day, availableOperations }: { day: string; availableOperations: OperationTypeValues[] }) => {
        openEditDayModal({ isEditModalOpen: true, selectedDay: day })
        setAvailableOperations({ availableOperations })
      }
    }
  ]

  return (
    <div>
      <DynamicTable
        columns={columns}
        rowHeight={70}
        loading={loadingDays}
        rows={daysData}
        actions={menuActions}
        keyExtractor={(entity) => entity.day}
      />
    </div>
  )
}
