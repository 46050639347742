import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'antd'
import styles from './AddEditLocationModal.module.scss'
import addEditLocationModalActions from '../actions/AddEditLocationModal'
import { useHistory } from 'react-router'
import { AddEditLocationModalState } from '../types/AddEditLocationModal'
import InputGroup from '../../../components/InputGroup'
import { Row } from '../../NewProduct/components/subcomponents/GeneralData'
import NumberInput from '../../../components/numberInput'
import { TextField } from '../../../components/TextField/TextField'
import Select from '../../../components/Select'
import { CreateLocationParams } from '../../../projectApi/TransactionHandler/Locations/create'
import { sendToastNotificationInfo } from '../../../utils/notifications'

type AddEditLocationModalProps = {
  addEditLocationModalActions: typeof addEditLocationModalActions
  addEditLocationModalState: AddEditLocationModalState
  noLocationType?: boolean
  onOkClick: (state: CreateLocationParams) => void
  onDepositInputChange: (name: string) => void
}

const regexCode = /^[a-zA-Z0-9_]+$/
const MAX_VALUE = 999999999999999

const AddEditLocationModal = (props: AddEditLocationModalProps) => {
  const { addEditLocationModalActions, addEditLocationModalState, noLocationType, onOkClick, onDepositInputChange } =
    props
  const { setNumberField, setStringField, clearState } = addEditLocationModalActions
  const {
    selectedLocation,
    error,
    isEdit,
    isLoading,
    isOpen,
    code,
    depositId,
    description,
    type,
    maxHeightInCm,
    maxLengthInCm,
    maxVolumeInCm,
    maxWeightInKg,
    maxWidthInCm,
    locationTypes,
    deposits
  } = addEditLocationModalState
  const [autocomplete, setAutocomplete] = useState('')

  const locationTypesOptions = useMemo(() => {
    return locationTypes.map((locationType, index) => ({
      label: locationType.name,
      value: index
    }))
  }, [locationTypes])

  const depositsOptions = useMemo(() => {
    return deposits.map((deposit) => ({
      label: `${deposit.id} - ${deposit.name} - ${
        deposit.owner.id === 0 ? 'SpaceGuru' : deposit.owner.name + ' ' + deposit.owner.lastName
      }`,
      value: deposit.id
    }))
  }, [deposits])

  useEffect(() => {
    setNumberField({ field: 'maxVolumeInCm', value: maxHeightInCm * maxLengthInCm * maxWidthInCm })
  }, [maxHeightInCm, maxLengthInCm, maxWidthInCm])

  const history = useHistory()

  const onClose = () => {
    clearState()
  }

  const isReadyToSubmit = isEdit
    ? selectedLocation &&
      (maxLengthInCm !== selectedLocation.maxLengthInCm ||
        maxWidthInCm !== selectedLocation.maxWidthInCm ||
        maxHeightInCm !== selectedLocation.maxHeightInCm ||
        maxWeightInKg !== selectedLocation.maxWeightInGr / 1000 ||
        (description !== selectedLocation.description && description.length > 3) ||
        (code !== selectedLocation.code && code.length > 3) ||
        type !== selectedLocation.type)
    : Boolean(
        (maxLengthInCm &&
          maxWidthInCm &&
          maxHeightInCm &&
          description.length > 3 &&
          code.length > 3 &&
          Boolean(depositId) &&
          Boolean(type)) ||
          noLocationType
      )

  const handleMessageVolumn = (volumen: number) => {
    sendToastNotificationInfo(
      `El volumen no puede ser menor al volumen ocupado (${(volumen / 1000000).toFixed(2)} m³).`
    )
  }

  const handleChangeCode = (value: string) => {
    if (regexCode.test(value) || value === '') setStringField({ field: 'code', value })
  }

  const currentDepositName =
    deposits.find((deposit) => deposit.id === depositId)?.name || 'Cargando nombre del depósito...'

  return (
    <Modal
      width={750}
      title={isEdit ? 'Editar una ubicación' : 'Crear una ubicación'}
      visible={isOpen}
      closable={true}
      keyboard={false}
      onOk={() => onOkClick(addEditLocationModalState)}
      onCancel={onClose}
      okButtonProps={{
        loading: isLoading,
        disabled: isLoading || !isReadyToSubmit,
        className: styles.modalButtonOk
      }}
      okText={isEdit ? 'Editar' : 'Crear'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p className={styles.description}>
        {isEdit ? 'Edite los datos de la ubicación:' : 'Ingrese los datos de la nueva ubicación:'}
      </p>

      <Row>
        <div className={styles.select}>
          <p style={{ marginBottom: 5 }}>Depósito</p>
          <Select
            disabled={isEdit}
            onSelect={(option) => setNumberField({ field: 'depositId', value: option.value })}
            options={depositsOptions}
            selected={depositsOptions.find((x) => x.value === depositId)}
            placeholder={isEdit ? currentDepositName : 'Seleccione un depósito'}
            className={styles.select}
            autocomplete={{
              filterByInput: true,
              input: autocomplete,
              onInputChange: (text) => {
                setAutocomplete(text)
                onDepositInputChange(text)
              }
            }}
            onDelete={() => setNumberField({ field: 'depositId', value: 0 })}
          />
        </div>
      </Row>

      <InputGroup title="Dimensiones" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Row>
          <TextField
            label="Código:"
            disabled={isLoading}
            onChange={(value) => handleChangeCode(value.toUpperCase())}
            value={code}
          />
          <TextField
            label="Descripción: "
            disabled={isLoading}
            onChange={(value) => setStringField({ field: 'description', value })}
            value={description}
          />
          {!noLocationType && (
            <Select
              onSelect={(value) => setStringField({ field: 'type', value: value.label })}
              options={locationTypesOptions}
              selected={locationTypesOptions.find((x) => x.label === type)}
              placeholder="Seleccione un tipo"
              onDelete={() => setStringField({ field: 'type', value: '' })}
            />
          )}
        </Row>
        <div className={styles.inputsContainer}>
          <DivContainer>
            <span>Largo (cm)*</span>
            <NumberInput
              min={0}
              value={maxLengthInCm}
              disabled={isLoading}
              max={MAX_VALUE}
              onAnyChange={(newValue) => {
                if (isEdit && selectedLocation) {
                  const volm = newValue * maxWidthInCm * maxHeightInCm
                  if (volm < selectedLocation?.volumeInCm) {
                    handleMessageVolumn(selectedLocation?.volumeInCm)
                    return
                  }
                }
                setNumberField({ field: 'maxLengthInCm', value: newValue })
              }}
            />
          </DivContainer>
          <DivContainer>
            <span>Ancho (cm)*</span>
            <NumberInput
              min={0}
              value={maxWidthInCm}
              disabled={isLoading}
              max={MAX_VALUE}
              onAnyChange={(newValue) => {
                if (isEdit && selectedLocation) {
                  const volm = maxLengthInCm * newValue * maxHeightInCm
                  if (volm < selectedLocation?.volumeInCm) {
                    handleMessageVolumn(selectedLocation?.volumeInCm)
                    return
                  }
                }
                setNumberField({ field: 'maxWidthInCm', value: newValue })
              }}
            />
          </DivContainer>
          <DivContainer>
            <span>Alto (cm)*</span>
            <NumberInput
              min={0}
              value={maxHeightInCm}
              disabled={isLoading}
              max={MAX_VALUE}
              onAnyChange={(newValue) => {
                if (isEdit && selectedLocation) {
                  const volm = maxLengthInCm * maxWidthInCm * newValue
                  if (volm < selectedLocation?.volumeInCm) {
                    handleMessageVolumn(selectedLocation?.volumeInCm)
                    return
                  }
                }
                setNumberField({ field: 'maxHeightInCm', value: newValue })
              }}
            />
          </DivContainer>
          <DivContainer>
            <span>Volumen (cm³)*</span>
            <NumberInput
              min={0}
              value={maxVolumeInCm}
              disabled={isLoading}
              max={MAX_VALUE}
              onAnyChange={(newValue) => {
                if (isEdit && selectedLocation) {
                  if (newValue < selectedLocation?.volumeInCm) return
                }
                setNumberField({ field: 'maxVolumeInCm', value: newValue })
              }}
            />
          </DivContainer>
          <DivContainer>
            <span>Peso (kg)*</span>
            <NumberInput
              min={0}
              value={maxWeightInKg}
              disabled={isLoading}
              max={MAX_VALUE}
              onPlusClick={(value) => setNumberField({ field: 'maxWeightInKg', value })}
              onInputChange={(value) => setNumberField({ field: 'maxWeightInKg', value })}
              onMinusClick={(value) => setNumberField({ field: 'maxWeightInKg', value })}
            />
          </DivContainer>
        </div>
      </InputGroup>
      {error && (
        <div className={styles.errorContainer}>
          <p>{error}</p>
        </div>
      )}
    </Modal>
  )
}

const DivContainer: React.FC = ({ children }) => {
  return <div className={styles.inputContainer}>{children}</div>
}

export default AddEditLocationModal
