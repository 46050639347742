import { AppThunk } from '@/store'
import {
  CBUStatus,
  CBUStatusType,
  CLEAN_CBU,
  SET_CBU,
  SET_CBU_STATUS,
  SET_DEFAULT_HOLDER_NAME,
  SET_HOLDER_NAME,
  USE_DEFAULT_HOLDER_NAME
} from './types'
import { APICBU } from '@/projectApi/UserProfile/Admin/CBU/common'
import { getAssociatedBank, removeCBUSpaces } from '@/utils/cbuChecker'

const cbuActions = {
  cleanCBU() {
    return { type: CLEAN_CBU }
  },
  setCBU(cbu: string) {
    return { type: SET_CBU, payload: { cbu } }
  },
  setCBUStatus(cbuStatus: CBUStatus) {
    return { type: SET_CBU_STATUS, payload: { cbuStatus } }
  },
  setHolderName(holderName: string) {
    return { type: SET_HOLDER_NAME, payload: { holderName } }
  },
  setDefaultHolderName(holderName: string) {
    return { type: SET_DEFAULT_HOLDER_NAME, payload: { holderName } }
  },
  useDefaultHolderName(useDefaultHolderName: boolean) {
    return { type: USE_DEFAULT_HOLDER_NAME, payload: { useDefaultHolderName } }
  },
  setUserInfo:
    (fullName: string): AppThunk =>
    async (dispatch) => {
      dispatch(cbuActions.setHolderName(fullName))
      dispatch(cbuActions.setDefaultHolderName(fullName))
    },
  populateForm:
    (currentCU: APICBU): AppThunk =>
    async (dispatch) => {
      dispatch(cbuActions.setCBU(currentCU.cbu))
      dispatch(cbuActions.setHolderName(currentCU.account_holder))
      dispatch(cbuActions.setDefaultHolderName(currentCU.account_holder))
      dispatch(
        cbuActions.setCBUStatus({
          status: CBUStatusType.success,
          message: getAssociatedBank(removeCBUSpaces(currentCU.cbu))
        })
      )
    }
}

export default cbuActions
