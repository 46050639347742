import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dropdown, Radio } from 'antd'
import styles from './FilterDropdown.module.scss'
import { FiltersProps } from '../../../types/types'
import Overlay from '../Overlay/Overlay'
import HeaderIcon from '../../HeaderIcon/HeaderIcon'
import { Filter as FilterIcon } from '../../../../../icons'

type FilterDropdownProps = FiltersProps

const FilterDropdown = ({ options, multiple, selected, onConfirm }: FilterDropdownProps) => {
  const [visible, setVisible] = useState(false)
  const [localSelected, setLocalSelected] = useState(selected)

  useEffect(() => {
    setLocalSelected(selected)
  }, [selected])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleLocalCheckboxChange = (value: string | number) => {
    const filtered = localSelected.filter((ls) => ls !== value)
    if (localSelected.length === filtered.length) filtered.push(value)
    setLocalSelected(filtered)
  }

  const handleLocalRadioChange = (value: string | number) => {
    setLocalSelected([value])
  }

  const handleConfirm = () => {
    setVisible(false)
    onConfirm(localSelected)
  }

  const handleReset = () => {
    setVisible(false)
    setLocalSelected([])
    onConfirm([])
  }

  const overlay = (
    <Overlay
      buttons={
        <>
          <Button type="primary" onClick={handleConfirm} size="small">
            Filtrar
          </Button>
          <Button onClick={handleReset} size="small">
            Resetear
          </Button>
        </>
      }>
      <div className={styles.OptionsContainer}>
        {options.map((option) =>
          multiple ? (
            <div key={option.text}>
              <Checkbox
                checked={localSelected.includes(option.value)}
                onChange={() => handleLocalCheckboxChange(option.value)}>
                {option.text}
              </Checkbox>
            </div>
          ) : (
            <Radio
              key={option.text}
              onChange={() => handleLocalRadioChange(option.value)}
              checked={!!localSelected.find((ls) => ls === option.value)}>
              {option.text}
            </Radio>
          )
        )}
      </div>
    </Overlay>
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
      <div>
        <HeaderIcon overlayOpen={visible} active={selected.length > 0}>
          <FilterIcon />
        </HeaderIcon>
      </div>
    </Dropdown>
  )
}

export default FilterDropdown
