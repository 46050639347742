import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CancelActionCreators from '../actionCreators/cancel'
import CreditNoteWarning from '../components/CreditNoteWarning'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { openCreditNoteModal, openModal } = bindActionCreators(CancelActionCreators, dispatch)
  const cancelState = useSelector((state) => state.Removals.cancel)

  const { operationId } = cancelState

  const closeModal = () => {
    openCreditNoteModal({ isOpen: false, selectedUser: null, operationId })
  }

  const openCancelModal = () => {
    openModal({ isOpen: true, operationId })
  }

  const props = {
    cancelState,
    closeModal,
    cancelRemovalAction: openCancelModal
  }

  return <CreditNoteWarning {...props} />
}

export default Container
