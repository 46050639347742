import types from '../types/markPhotoAsMain'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const actions = {
  selectMainPhoto: (objectId, photoId, url) => ({
    type: types.SELECT_PHOTO,
    payload: {
      objectId,
      photoId,
      url
    }
  }),
  cancel: () => ({ type: types.CANCEL }),
  markPhotoAsMain: (object_id, photo_id) => {
    return function (dispatch) {
      dispatch({ type: types.MARK_PHOTO_AS_MAIN_REQUEST })

      return apiPrivate.put(URL_BASE_OBJECTS + '/object/' + object_id + '/photo/' + photo_id + '/main').then(
        (response) => {
          dispatch(actions.markPhotoAsMainSuccess(object_id, photo_id))
          Emitter.emit(Events.Objects.OBJECT_PHOTO_MARKED_AS_MAIN)
          return true
        },
        (error) => {
          handleErrorsWithAction(error, types.MARK_PHOTO_AS_MAIN_FAILURE, dispatch)
          return false
        }
      )
    }
  },
  markPhotoAsMainSuccess: (objectId, photoId) => ({
    type: types.MARK_PHOTO_AS_MAIN_SUCCESS,
    payload: {
      objectId,
      photoId
    }
  }),
  markPhotoAsMainFail: (error) => ({
    type: types.MARK_PHOTO_AS_MAIN_FAILURE,
    payload: { error }
  })
}

export default actions
