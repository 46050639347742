import { combineReducers } from 'redux'
import attributesTable from './AttributesTable'
import DeleteAttributeReducer from './DeleteAttribute'
import NewEditAttributeReducer from './NewEditAttribute'

const rootReducer = combineReducers({
  attributesTable,
  DeleteAttribute: DeleteAttributeReducer,
  NewEditAttribute: NewEditAttributeReducer
})

export default rootReducer
