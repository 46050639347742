import { Reducer } from 'redux'
import { DepositMetricsActions, DepositMetricsTypes, GetDepositMetricsTypes } from '../types/metrics'

const initialState: DepositMetricsTypes = {
  metrics: [],
  fetchingMetrics: false
}

const depositMetricsReducer: Reducer<DepositMetricsTypes, DepositMetricsActions> = (
  state = initialState,
  action
): DepositMetricsTypes => {
  switch (action.type) {
    case GetDepositMetricsTypes.GET_DEPOSIT_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetDepositMetricsTypes.GET_DEPOSIT_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        metrics: action.payload.metrics
      }
    case GetDepositMetricsTypes.GET_DEPOSIT_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default depositMetricsReducer
