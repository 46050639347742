import { CategoryFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'
import { Moment } from 'moment'

export const SHOW_HIDDEN_FILTERS = [
  {
    text: 'SÍ',
    value: 'true'
  },
  {
    text: 'NO',
    value: 'false'
  }
]

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export enum AttributesTableSearchKey {
  ID = 'id',
  NAME = 'name',
  VISIBLE = 'isVisible'
}
export type AttributeCategoryFilterKey = 'is_visible'

export type AttributesTableCategoryFilter = CategoryFilter<AttributeCategoryFilterKey>

export type AttributesTableSearchFilter = SearchFilter<AttributesTableSearchKey>

export type AttributesListSortKey = 'id' | 'data_type' | 'unit' | 'name' | 'created_at' | 'deleted_at'

export type AttributesTableSortKey = AttributesListSortKey
export type AttributesTableSort = Sort<AttributesTableSortKey>

export const CLEAR_STATE = 'ATTRIBUTES/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export const GET_ATTRIBUTES_REQUEST = 'ATTRIBUTES/GET_ATTRIBUTES_REQUEST'
export const GET_ATTRIBUTES_SUCCESS = 'ATTRIBUTES/GET_ATTRIBUTES_SUCCESS'
export const GET_ATTRIBUTES_FAILURE = 'ATTRIBUTES/GET_ATTRIBUTES_FAILURE'

export interface GetAttributesRequest {
  type: typeof GET_ATTRIBUTES_REQUEST
  payload: {
    pageSize: number
    newPage: number
    sort?: AttributesTableSort
    categoryFilter?: AttributesTableCategoryFilter
    searchFilters?: AttributesTableSearchFilter[]
  }
}

export interface GetAttributesSuccess {
  type: typeof GET_ATTRIBUTES_SUCCESS
  payload: {
    attributes: Attribute[]
    total: number
  }
}

export interface GetAttributesFailure {
  type: typeof GET_ATTRIBUTES_FAILURE
  payload: {
    error: string
  }
}

export type Attribute = {
  id: number
  dataType: string
  isVisible: boolean
  unit: string
  name: string
  createdAt: Moment
  deletedAt: Moment | null
  countries: Country[]
}

export interface Country {
  id: number
  label: string
  countryId: string
  options: Option[]
}

export interface Option {
  id: number
  dataType: string
  value: string
}

export interface DeletedOption {
  id: number
  deleted: boolean
}

export const GET_ATTRIBUTES_REPORT_REQUEST = 'ATTRIBUTES/GET_ATTRIBUTES_REPORT_REQUEST'
export const GET_ATTRIBUTES_REPORT_SUCCESS = 'ATTRIBUTES/GET_ATTRIBUTES_REPORT_SUCCESS'
export const GET_ATTRIBUTES_REPORT_FAILURE = 'ATTRIBUTES/GET_ATTRIBUTES_REPORT_FAILURE'

export interface GetAttributesReportRequest {
  type: typeof GET_ATTRIBUTES_REPORT_REQUEST
}

export interface GetAttributesReportSuccess {
  type: typeof GET_ATTRIBUTES_REPORT_SUCCESS
}

export interface GetAttributesReportFailure {
  type: typeof GET_ATTRIBUTES_REPORT_FAILURE
}

export type AttributesActions =
  | GetAttributesRequest
  | GetAttributesSuccess
  | GetAttributesFailure
  | ClearState
  | GetAttributesReportRequest
  | GetAttributesReportSuccess
  | GetAttributesReportFailure
