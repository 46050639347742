import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AddEditLocationModal from '../../../Locations/components/AddEditLocationModal'
import AddEditLocationModalActionCreators from '../../../Locations/actions/AddEditLocationModal'
import AddEditCorporativeLocationModalActionCreators from '../actions/AddEditCorporativeLocationModal'
import { CreateLocationParams } from '../../../../projectApi/TransactionHandler/Locations/create'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()

  const addEditCorporativeLocaitonModalActions = bindActionCreators(
    AddEditCorporativeLocationModalActionCreators,
    dispatch
  )
  const addEditLocationModalActions = bindActionCreators(AddEditLocationModalActionCreators, dispatch)
  const addEditLocationModalState = useSelector((state) => state.Locations.addEditLocationModal)

  const { createCorporativeLocation, editCorporativeLocation, getCorporativeDeposits } =
    addEditCorporativeLocaitonModalActions
  const { isOpen, locationId, depositId, isEdit } = addEditLocationModalState

  useEffect(() => {
    if (isOpen) {
      getCorporativeDeposits({ id: depositId })
    }
  }, [isOpen, locationId])

  const onOkClick = (values: CreateLocationParams) => {
    if (!isEdit) {
      createCorporativeLocation({
        code: values.code,
        depositId: values.depositId,
        description: values.description,
        maxHeightInCm: values.maxHeightInCm,
        maxLengthInCm: values.maxLengthInCm,
        maxVolumeInCm: values.maxVolumeInCm,
        maxWeightInKg: values.maxWeightInKg,
        maxWidthInCm: values.maxWidthInCm,
        locationId: values.locationId
      })
    } else {
      editCorporativeLocation({
        code: values.code,
        description: values.description,
        maxHeightInCm: values.maxHeightInCm,
        maxLengthInCm: values.maxLengthInCm,
        maxVolumeInCm: values.maxVolumeInCm,
        maxWeightInKg: values.maxWeightInKg,
        maxWidthInCm: values.maxWidthInCm,
        locationId: values.locationId
      })
    }
  }

  const onDepositInputChange = (name: string) => {
    if (name.length < 2) {
      if (name.length > 0) {
        getCorporativeDeposits({})
      }
    } else {
      getCorporativeDeposits({ name })
    }
  }

  const props = {
    addEditLocationModalActions,
    addEditLocationModalState,
    noLocationType: true,
    onOkClick,
    onDepositInputChange
  }

  return <AddEditLocationModal {...props} />
}

export default Container
