import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from './QRModalActions'
import Component from './QRModal'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const QRModalActions = bindActionCreators(actions, dispatch)

  const QRModal = useSelector((state) => state.Components.QRModalReducer)
  const { code, open } = QRModal

  const props = {
    ...ownProps,
    ...QRModalActions,
    code,
    open
  }

  return <Component {...props} />
}

export default Container
