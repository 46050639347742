import React from 'react'
import { Modal } from 'antd'
import styles from './stepModals.module.scss'
import PhoneIcon from '../../../../icons/phone'
import ArrowRight from '../../../../icons/arrowRight'
import DenyIcon from '../../../../icons/deny'
import TrashIcon from '../../../../icons/trash'
import { FunnelState } from '../../constants'
import TruckIcon from '../../../../icons/truckPR'
import { useHistory } from 'react-router'
import { PROPOSAL_MODES } from '../../../../common/operations/constants'

export type ActionsModalProps = {
  openModal: boolean
  leadId: number
  leadFunnelState?: string
  handleClose: () => void
  openReasonsModal: (leadId: number, leadFunnelState: FunnelState) => void
}

export type ActionCardProps = {
  icon?: React.ReactNode
  title?: string
  text?: string
  onClickCard?: () => void
  minWidth?: string
}

const getCardsAction = ({
  leadFunnelState,
  leadId,
  history,
  handleClose,
  openReasonsModal
}: {
  leadFunnelState?: string
  leadId: number
  history: any
  handleClose: () => void
  openReasonsModal: (leadId: number, leadFunnelState: FunnelState) => void
}): ActionCardProps[] => {
  const redirectUserToNewProposal = (mode: string, additionalsParams?: string) => {
    const aditionalParam = additionalsParams || ''

    history.push(`/newProposal/${leadId}?mode=${mode}${aditionalParam}`)
    handleClose()
  }

  const openReasonsModalByFunnelState = (funnelState: FunnelState) => {
    handleClose()
    openReasonsModal(leadId, funnelState)
  }

  switch (leadFunnelState) {
    case FunnelState.MQL:
      return [
        {
          icon: <PhoneIcon />,
          title: 'Cotizar',
          text: 'Se va a dirigir a la pantalla de cotizaciones',
          onClickCard: () => redirectUserToNewProposal(PROPOSAL_MODES.CREATE_PROPOSAL, '&isNewProposal=true')
        },
        {
          icon: <DenyIcon />,
          title: 'Definir como perdido (MQLP)',
          text: 'Se indicará como prospecto perdido',
          onClickCard: () => openReasonsModalByFunnelState(leadFunnelState)
        },
        {
          icon: <TrashIcon stroke="#FF7A7F" width="24px" height="24px" />,
          title: 'Descartar (Solo en caso de error o auditoría)',
          text: 'Se indicará como prospecto descartado',
          onClickCard: () => openReasonsModalByFunnelState(FunnelState.DISCARD)
        }
      ]
    case FunnelState.SQL:
      return [
        {
          icon: <TruckIcon />,
          title: 'Pactar retiro',
          text: 'Se va a dirigir a la pantalla de retiro',
          onClickCard: () => redirectUserToNewProposal(PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL)
        },
        {
          icon: <PhoneIcon />,
          title: 'Recotizar',
          text: 'Se va a dirigir a la pantalla de cotización',
          onClickCard: () => redirectUserToNewProposal(PROPOSAL_MODES.CREATE_PROPOSAL)
        },
        {
          icon: <DenyIcon />,
          title: 'Definir como perdido (SQLP)',
          text: 'Se indicará como SQL perdido',
          onClickCard: () => openReasonsModalByFunnelState(leadFunnelState)
        }
      ]
    case FunnelState.SQLP:
      return [
        {
          icon: <TruckIcon />,
          title: 'Pactar retiro',
          text: 'Se va a dirigir a la pantalla de retiro',
          onClickCard: () => redirectUserToNewProposal(PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL)
        },
        {
          icon: <PhoneIcon />,
          title: 'Recotizar',
          text: 'Se va a dirigir a la pantalla de cotización',
          onClickCard: () => redirectUserToNewProposal(PROPOSAL_MODES.CREATE_PROPOSAL)
        },
        {
          icon: <TrashIcon stroke="#FF7A7F" width="24px" height="24px" />,
          title: 'Descartar (Solo en caso de error o auditoría)',
          text: 'Se indicará como prospecto descartado',
          onClickCard: () => openReasonsModalByFunnelState(FunnelState.DISCARD)
        }
      ]
    case FunnelState.MQLP:
      return [
        {
          icon: <PhoneIcon />,
          title: 'Cotizar',
          text: 'Se va a dirigir a la pantalla de cotizaciones',
          onClickCard: () => redirectUserToNewProposal(PROPOSAL_MODES.CREATE_PROPOSAL, '&isNewProposal=true')
        },
        {
          icon: <TrashIcon stroke="#FF7A7F" width="24px" height="24px" />,
          title: 'Descartar (Solo en caso de error o auditoría)',
          text: 'Se indicará como prospecto descartado',
          onClickCard: () => openReasonsModalByFunnelState(FunnelState.DISCARD)
        }
      ]
    default:
      return [{}]
  }
}

const ActionsModal: React.FC<ActionsModalProps> = ({
  openModal,
  leadId,
  leadFunnelState,
  handleClose,
  openReasonsModal
}) => {
  const history = useHistory()
  const title = <h2 className={styles.actionsTitle}>¿Qué acción desea realizar?</h2>
  const cards = getCardsAction({
    leadFunnelState,
    leadId,
    history,
    handleClose,
    openReasonsModal
  })

  const witdhDiscard =
    leadFunnelState === FunnelState.MQL || leadFunnelState === FunnelState.SQLP || leadFunnelState === FunnelState.MQLP
      ? '418px'
      : ''

  return (
    <Modal
      visible={openModal}
      onCancel={handleClose}
      destroyOnClose={true}
      className={styles.actionsModal}
      title={title}
      footer={false}>
      <div className={styles.modalContent}>
        {cards.map((card) => (
          <ActionCard
            minWidth={witdhDiscard}
            key={`actionCard_${card.title}`}
            icon={card.icon}
            title={card.title}
            text={card.text}
            onClickCard={card.onClickCard}
          />
        ))}
      </div>
    </Modal>
  )
}

export default ActionsModal

export const ActionCard: React.FC<ActionCardProps> = ({ icon, title, text, onClickCard, minWidth }) => {
  return (
    <article onClick={onClickCard} className={styles.cardContainer} style={{ minWidth }}>
      <span className={styles.cardIcon}>{icon}</span>
      <div className={styles.cardContent}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <span className={styles.cardArrow}>
        <ArrowRight />
      </span>
    </article>
  )
}
