import { Moment } from 'moment'
import React, { useEffect } from 'react'
import ActionBarButton from '../../../components/ActionBarButton/ActionBarButton'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { Discount } from '../../../projectApi/TransactionHandler/BillingDiscounts/list'
import { Billing, BillingStatus, BillingType } from '../../../projectApi/TransactionHandler/Billings/common'
import { formatMoney, formatNumber } from '../../../utils/formatNumber'
import PaymentModalContainer from '../containers/paymentModal'
import { BillingWithDiscounts, EXTRA_PRICES, ObjectWithDiscount } from '../types/billingByClientTable'
import { LoadingIndicator } from '../../../components/LoadingIndicator'

export const parseMomentDate = (date: Moment | null) => {
  const formattedDate = (
    <>
      {date?.format('dd DD[/]MM[/]YY')}
      <br />
      {date?.format('HH[:]mm')}
    </>
  )
  return date?.isValid() ? (
    <span style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
      {date.isAfter('01-01-0001') ? formattedDate : '-'}
    </span>
  ) : (
    ''
  )
}

export type BillingsByClientProps = {
  billings: BillingWithDiscounts[]
  loading: boolean
  name: string | null | string[]
  lastName: string | null | string[]
  userId: number
  discounts?: Discount[]
  pagination: Pagination
  handlePdfDownload: (billingId: number) => void
  handleReturnButton: () => void
  handleModalOpen: (row: Billing) => void
  handleGetBillingDiscount: (billingId: number) => void
  clientAuthorizationEnable: boolean
  fetchingClientBillingInformation: boolean
  errorClientBillingInformation: string
}

export const BillingByClientTable: React.FC<BillingsByClientProps> = ({
  loading,
  billings,
  name,
  lastName,
  userId,
  pagination,
  handlePdfDownload,
  handleReturnButton,
  handleModalOpen,
  handleGetBillingDiscount,
  clientAuthorizationEnable,
  fetchingClientBillingInformation,
  errorClientBillingInformation
}) => {
  const columns: Column<BillingWithDiscounts>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'startDate',
      label: 'Inicio del Periodo',
      renderDataCell: ({ startDate }) => parseMomentDate(startDate)
    },
    {
      key: 'endDate',
      label: 'Fin del Periodo',
      renderDataCell: ({ endDate }) => parseMomentDate(endDate)
    },
    {
      key: 'm3',
      label: 'm³',
      renderDataCell: ({ m3 }) => formatNumber(m3, 3)
    },
    {
      key: 'kmExtra',
      label: 'KM Extra',
      renderDataCell: ({ applications }) => {
        if (!applications) return '-'
        const appAmount = applications.find((app) => app.type === EXTRA_PRICES.KMS_EXTRA)?.amount
        return appAmount ? formatMoney(appAmount) : '-'
      }
    },
    {
      key: 'shipmentAssistant',
      label: 'Asistentes',
      renderDataCell: ({ applications }) => {
        if (!applications) return '-'
        const appAmount = applications.find((app) => app.type === EXTRA_PRICES.SHIPMENT_ASSISTANT)?.amount
        return appAmount ? formatMoney(appAmount) : '-'
      }
    },
    {
      key: 'transport',
      label: 'Transporte',
      renderDataCell: ({ applications }) => {
        if (!applications) return '-'
        const appAmount = applications.find((app) => app.type === EXTRA_PRICES.TRANSPORT)?.amount
        return appAmount ? formatMoney(appAmount) : '-'
      }
    },
    {
      key: 'total',
      label: 'Abono',
      renderDataCell: ({ totalPrice }) => formatMoney(totalPrice)
    },
    {
      key: 'status',
      label: 'Tipo de factura',
      renderDataCell: ({ sourceType }) => BillingType[sourceType] || sourceType || '-'
    },
    {
      key: 'status',
      label: 'Estado',
      renderDataCell: ({ status }) => BillingStatus[status]
    }
  ]

  const BillingActions: MenuAction<Billing>[] = [
    {
      label: 'Descargar Factura',
      onClick: ({ id }) => handlePdfDownload(id)
    },
    {
      label: 'Marcar Pago',
      onClick: (row) => handleModalOpen(row)
    }
  ]

  return (
    <>
      <ActionBarButton
        action={handleReturnButton}
        description="Volver"
        icon="arrow-left"
        style={{ marginBottom: '15px' }}
      />
      <h5>
        Cliente: {name} {lastName} (ID: {userId})
      </h5>
      <div style={{ display: 'flex', gap: '8px' }}>
        <h4 style={{ marginBottom: '8px' }}>
          Facturación:{' '}
          {!fetchingClientBillingInformation && !errorClientBillingInformation ? (
            clientAuthorizationEnable ? (
              <span style={{ color: '#1AA86F', fontSize: '16px' }}>Activada</span>
            ) : (
              <span style={{ color: '#F5522E', fontSize: '16px' }}>Desactivada</span>
            )
          ) : (
            <b style={{ color: '#F5522E', fontSize: '16px' }}>{errorClientBillingInformation}</b>
          )}
        </h4>
        {fetchingClientBillingInformation ? <LoadingIndicator size="small" /> : null}
      </div>

      <DynamicTable
        columns={columns}
        loading={loading}
        actions={BillingActions}
        pagination={pagination}
        rows={billings}
        keyExtractor={(entity) => entity.id}
        rowExpander={{
          render: (row) => (
            <BillingExpanded
              loading={loading}
              billingObject={row.objects}
              billingInfo={row}
              handleGetBillingDiscount={handleGetBillingDiscount}
            />
          )
        }}
      />

      <PaymentModalContainer />
    </>
  )
}

const BillingExpanded: React.FC<{
  loading: boolean
  billingObject: ObjectWithDiscount[]
  billingInfo: BillingWithDiscounts
  handleGetBillingDiscount: (billingId: number) => void
}> = ({ loading, billingObject, billingInfo, handleGetBillingDiscount }) => {
  useEffect(() => {
    handleGetBillingDiscount(billingInfo.id)
  }, [billingInfo.id])

  const columns: Column<ObjectWithDiscount>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'startDate',
      label: 'Inicio de ciclo',
      renderDataCell: ({ startDate }) => parseMomentDate(startDate)
    },
    {
      key: 'endDate',
      label: 'Fin de ciclo',
      renderDataCell: ({ endDate }) => parseMomentDate(endDate)
    },
    {
      key: 'itemDescription',
      label: 'Descripcion'
    },
    {
      key: 'm3',
      label: 'm³',
      renderDataCell: ({ m3 }) => formatNumber(m3, 3)
    },
    {
      key: 'costPerFloor',
      label: 'Escaleras (Pisos)',
      renderDataCell: ({ objectApplications }) => {
        if (!objectApplications || !objectApplications.length) return '-'
        const appAmount = objectApplications.find((app) => app.type === EXTRA_PRICES.COST_PER_FLOOR)?.amount
        return appAmount ? formatMoney(appAmount) : '-'
      }
    },
    {
      key: 'packaging',
      label: 'Embalaje',
      renderDataCell: ({ objectApplications }) => {
        if (!objectApplications || !objectApplications.length) return '-'
        const appAmount = objectApplications.find((app) => app.type === EXTRA_PRICES.PACKAGING)?.amount
        return appAmount ? formatMoney(appAmount) : '-'
      }
    },
    {
      key: 'costPerFloor',
      label: 'Desarmado',
      renderDataCell: ({ objectApplications }) => {
        if (!objectApplications || !objectApplications.length) return '-'
        const appAmount = objectApplications.find((app) => app.type === EXTRA_PRICES.DISASSEMBLY)?.amount
        return appAmount ? formatMoney(appAmount) : '-'
      }
    },
    {
      key: 'totalPrice',
      label: 'Subtotal',
      renderDataCell: ({ totalPrice }) => formatMoney(totalPrice)
    },
    {
      key: 'days',
      label: 'Días de descuento',
      renderDataCell: ({ discount }) => {
        if (!discount) return '-'

        return `${discount.days} días`
      }
    },
    {
      key: 'discountDetail',
      label: 'Descuento',
      renderDataCell: ({ discount }) => {
        if (!discount) return '-'

        return formatMoney(discount.amount)
      }
    },
    {
      key: 'total',
      label: 'Total',
      renderDataCell: ({ discount, totalPrice }) => {
        if (!discount) return formatMoney(totalPrice)
        const totalWithDiscount = totalPrice - discount.amount
        return formatMoney(totalWithDiscount)
      }
    }
  ]

  return <DynamicTable columns={columns} loading={loading} rows={billingObject} keyExtractor={(entity) => entity.id} />
}
