import { REHYDRATE } from 'redux-persist'

export enum FeatureType {
  NEW_TRIPS = 'NEW_TRIPS',
  SPREEDLY_PAYMENT = 'SPREEDLY_PAYMENT',
  NEW_CANCEL_OPERATIONS = 'NEW_CANCEL_OPERATIONS',
  SEE_SALES_REQUEST_TABLE = 'SEE_SALES_REQUEST_TABLE'
}

export type Feature = {
  type: FeatureType
  description: string
  active: boolean
  deprecated: boolean
}

export interface FeatureManagerState {
  features: Feature[]
  popupOpen: boolean
}

export const SET_FEATURE_ACTIVE = 'FEATURE_MANAGER/SET_FEATURE_ACTIVE'

export interface SetFeatureActive {
  type: typeof SET_FEATURE_ACTIVE
  payload: {
    featureType: FeatureType
  }
}

export const SET_POPUP_OPEN = 'FEATURE_MANAGER/SET_POPUP_OPEN'

export interface SetPopupOpen {
  type: typeof SET_POPUP_OPEN
  payload: {
    open: boolean
  }
}

type ReduxRehydrate = {
  type: typeof REHYDRATE
  payload?: { FeatureManager?: FeatureManagerState }
}

export type FeatureManagerAction = SetFeatureActive | ReduxRehydrate | SetPopupOpen
