import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'

export function notification(id: number): Promise<void> {
  return apiPrivate
    .post(`${URL_BASE_SALES}/sales-requests/email/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
