import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import { convertArrayForGetRequests } from '../../utils'
import { APIMetrics, MQLMetrics, RPMetrics, SQLMetrics, StatusMetrics } from './common'

type Response = {
  status: StatusMetrics
  mqls: MQLMetrics
  sqls: SQLMetrics
  rps: RPMetrics
}

export interface ListParams {
  dateFrom?: string
  dateTo?: string
  updatedAtFrom?: string
  updatedAtTo?: string
  updateStatusAtFrom?: string
  updateStatusAtTo?: string
  country?: string
  source?: number[]
  contactMediumId?: number[]
  assignedAgentId?: number[]
  prospectTypeId?: number[]
  provider?: number[]
  winDateFrom?: string
  winDateTo?: string
}

export function list(params: ListParams): Promise<Response> {
  const {
    dateFrom,
    dateTo,
    updatedAtFrom,
    updatedAtTo,
    updateStatusAtFrom,
    updateStatusAtTo,
    assignedAgentId,
    country,
    source,
    contactMediumId,
    provider,
    prospectTypeId,
    winDateFrom,
    winDateTo
  } = params
  const snakeParams = snakecaseKeys({
    country,
    dateFrom,
    dateTo,
    updatedAtFrom,
    updatedAtTo,
    updateStatusAtFrom,
    updateStatusAtTo,
    winDateFrom,
    winDateTo,
    assignedAgentId: convertArrayForGetRequests(assignedAgentId),
    source: convertArrayForGetRequests(source),
    contactMediumId: convertArrayForGetRequests(contactMediumId),
    prospectTypeId: convertArrayForGetRequests(prospectTypeId),
    provider: convertArrayForGetRequests(provider)
  })
  return apiPrivate
    .get(`${URL_BASE_MERCURIO}/metrics`, { params: snakeParams })
    .then((response: AxiosResponse<APIMetrics>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener las metricas')
      const responseMetrics = response.data

      const camelResponse: Response = {
        status: {
          leadsToServe: responseMetrics.leads_por_atender,
          leadsInProgress: responseMetrics.leads_en_curso,
          leadsServed: responseMetrics.leads_atendidos,
          leadsToServicePr: responseMetrics.leads_por_atender_pr,
          leadsInProgressPr: responseMetrics.leads_en_curso_pr,
          leadsServicedPr: responseMetrics.leads_atendidos_pr,
          discarded: responseMetrics.descartados
        },
        mqls: {
          mql: responseMetrics.mql,
          mqlOverLeads: responseMetrics.mql_over_leads,
          mqlDiscarded: responseMetrics.descartados_leads
        },
        sqls: {
          sql: responseMetrics.sql,
          sqlOverLeads: responseMetrics.sql_over_leads,
          sqlOverMql: responseMetrics.sql_over_mql,
          sqlM3: responseMetrics.sql_m3,
          sqlTotal: responseMetrics.sql_total
        },
        rps: {
          rp: responseMetrics.rp,
          rpOverLeads: responseMetrics.rp_over_leads,
          rpOverSql: responseMetrics.rp_over_sql,
          rpOverMql: responseMetrics.rp_over_mql,
          rpc: responseMetrics.rpc,
          rpcPercentage: responseMetrics.rpc_p,
          rpM3: responseMetrics.total_m3_rp,
          rpTotal: responseMetrics.total_rp
        }
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
