import { FeatureType, SET_FEATURE_ACTIVE, SET_POPUP_OPEN, SetFeatureActive, SetPopupOpen } from './types'

const FeatureManagerActionCreators = {
  setFeatureActive: (featureType: FeatureType): SetFeatureActive => ({
    type: SET_FEATURE_ACTIVE,
    payload: {
      featureType
    }
  }),
  setPopupOpen: (open: boolean): SetPopupOpen => ({
    type: SET_POPUP_OPEN,
    payload: {
      open
    }
  })
}

export default FeatureManagerActionCreators
