import { ObjectSituationKeys } from '../../sections/Objects/constants/objectSituations'

type PresentationalItem = {
  type: 'PRESENTATIONAL'
  node: React.ReactNode
}

export enum ModifiableItemType {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  AUTOCOMPLETE_SEARCH = 'AUTOCOMPLETE_SEARCH',
  SWITCH = 'SWITCH',
  TEXT_FIELD = 'TEXT_FIELD',
  NUMBER_INPUT = 'NUMBER_INPUT',
  SEARCH_INPUT = 'SEARCH_INPUT'
}

interface ModifiableItemBase<T> {
  enabled: boolean
  label: string
  disabledCheckbox?: boolean
  currentValue: T
  onChange: (newValue: T) => void
  onEnable: (newValue: boolean) => void
}

export interface AutocompleteItem extends ModifiableItemBase<string | boolean | ObjectSituationKeys | null | number> {
  type: ModifiableItemType.AUTOCOMPLETE
  onInputChange: (newValue: string) => void
  loading?: boolean
  options: { value: string | boolean | ObjectSituationKeys | null | number; label: string }[]
}

export interface AutocompleteSearchItem
  extends ModifiableItemBase<string | boolean | ObjectSituationKeys | null | number> {
  type: ModifiableItemType.AUTOCOMPLETE_SEARCH
  onInputChange: (newValue: string) => void
  loading?: boolean
  options: { value: string | boolean | ObjectSituationKeys | null | number; label: string }[]
  autocomplete: string
  setAutocomplete: React.Dispatch<React.SetStateAction<string>>
  clearSelectedProducts: () => void
}

interface SearchInputItem extends ModifiableItemBase<string> {
  type: ModifiableItemType.SEARCH_INPUT
  onSearch: (query: string) => void
  disableSearch?: boolean
  loading?: boolean
  onlyNumbers?: boolean
}

interface SwitchItem extends ModifiableItemBase<boolean> {
  type: ModifiableItemType.SWITCH
}

interface TextFieldItem extends ModifiableItemBase<string> {
  type: ModifiableItemType.TEXT_FIELD
}

interface NumberInputItem extends ModifiableItemBase<number> {
  type: ModifiableItemType.NUMBER_INPUT
}

export type ModifiableItem =
  | AutocompleteItem
  | AutocompleteSearchItem
  | SwitchItem
  | TextFieldItem
  | NumberInputItem
  | SearchInputItem

export type Item = PresentationalItem | ModifiableItem

export type ItemGroup = {
  title: string
  items: Item[]
}
