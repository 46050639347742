import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import { getErrorMessage, handleErrorsWithAction } from '../../../../utils/HandleErrors'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import {
  DeleteLocationTypes,
  DeleteLocationsRequest,
  DeleteLocationsSuccess
} from '../../../Locations/types/LocationDelete'

const locationDeleteActionsCreator = {
  deleteLocation(locationId: number): AppThunk {
    return async (dispatch) => {
      const request: DeleteLocationsRequest = {
        type: DeleteLocationTypes.DELETE_LOCATION_REQUEST
      }
      dispatch(request)

      const toast = setToastLoading('Eliminando ubicación. Por favor espere...')

      try {
        await API.TransactionHandler.Location.Corporative.delete(locationId)
        const success: DeleteLocationsSuccess = {
          type: DeleteLocationTypes.DELETE_LOCATION_SUCCESS
        }
        Emitter.emit(Events.Location.LOCATION_DELETED)
        setToastSuccessUpdate(toast, { render: 'Ubicación eliminada.' })
        dispatch(success)
      } catch (error: any) {
        const message = getErrorMessage(error)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, DeleteLocationTypes.DELETE_LOCATION_FAILURE, dispatch)
      }
    }
  }
}

export default locationDeleteActionsCreator
