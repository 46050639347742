import { Col, Modal, Row } from 'antd'
import * as React from 'react'
import InputGroup from '../../../components/InputGroup'
import Select from '../../../components/Select/Select'
import styles from './ModalLeads.module.scss'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import DebouncedInputFieldWithError from '../../../components/DebouncedInputFieldWithError/DebouncedInputFieldWithError'
import { selectedCountry } from '../types/ModalLeads'
import { formatCountryOptions } from '../../../components/CountrySelector/component/utils'
import { Country, CountryPhoneCode } from '../../../components/CountrySelector/constants/constants'
import { allowedFunnelStates, FunnelState as funnelStates, genders } from '../constants'
import { getOptionValue } from '../../Users/Users.selectors'
import { Info } from '../../../icons'
import { Option } from '../entities'
import CellPhone from '../../../components/CellPhone'
import { countriesList } from '../../../utils/countryCodeList'

export interface DynamicDataProps {
  funnelState: string
  loseReason: string
  discardReasons: Option[]
  discardReason: string
  loseOptionsMQL: Option[]
  loseReasonMQL: string
  loseOptionsSQL: Option[]
  loseReasonSQL: string
  setDiscardReason: (discardReasonId: number) => void
  setLoseReasonMQL: (loseReason: number) => void
  setLoseReasonSQL: (loseReason: number) => void
}

export interface ModalLeadsProps extends DynamicDataProps {
  open: boolean
  selectedCountry: selectedCountry
  gender: string
  name: {
    value: string
    errorMessage: string
    successMessage: string
  }
  email: {
    value: string
    loading: boolean
    errorMessage: string
    successMessage: string
    lastSearchTimestampEmail: number
  }
  phone: {
    currentNumber: string
    countryCode: string
    codeArea: {
      value: string
      error: boolean
      errorMessage: string
    }
    phoneNumber: {
      value: string
      error: boolean
      errorMessage: string
    }
    limitNumber: number
    value: string
    errorMessage: string
    successMessage: string
  }
  lastName: {
    value: string
    errorMessage: string
    successMessage: string
  }
  actualM3Storage: number
  countries: Country[]
  sources: Option[]
  contactMediums: Option[]
  sourceChannel: string
  contactMedium: string
  interestReasons: Option[]
  interestReason: string
  freeLoseReason: string
  editMode: boolean
  countryPref: {
    ARG: string
    ESP: string
  }
  okDisabled: boolean
  handleClose: () => void
  setCountry: (country: selectedCountry) => void
  setEmail: (email: string) => void
  setGender: (gender: string) => void
  setPhone: (phone: string, countryCode: string) => void
  setName: (name: string) => void
  setLastName: (lastName: string) => void
  setSourceChannel: (sourceChannelId: number) => void
  setContactMedium: (contactMediumId: number) => void
  setInterestReason: (interestReasonId: number) => void
  setCountryCode: (countryCode: string) => void
  setCodeArea: (codeArea: string, countryCode: string) => void
  setPhoneNumber: (phoneNumber: string, codeArea: string, countryCode: string) => void
  setErrorPhone: (error: string) => void
  onOk: () => void
}

export type DynamicOptionsProps = {
  title: string
  placeholder: string
  value: string
  options: Option[]
  onChange: (value: number) => void
}

export const getDynamicOptions = ({
  funnelState,
  discardReasons,
  loseReason,
  discardReason,
  loseOptionsMQL,
  loseReasonMQL,
  loseOptionsSQL,
  loseReasonSQL,
  setDiscardReason,
  setLoseReasonMQL,
  setLoseReasonSQL
}: DynamicDataProps): DynamicOptionsProps => {
  switch (funnelState) {
    case funnelStates.MQLP:
      return {
        title: 'Motivo de MQL perdido',
        placeholder: 'Seleccione un motivo',
        value: loseReasonMQL || loseReason,
        options: loseOptionsMQL,
        onChange: (reason) => setLoseReasonMQL(reason)
      }
    case funnelStates.SQLP:
      return {
        title: 'Motivo de SQL perdido',
        placeholder: 'Seleccione un motivo',
        value: loseReasonSQL || loseReason,
        options: loseOptionsSQL,
        onChange: (reason) => setLoseReasonSQL(reason)
      }
    case funnelStates.DISCARD:
    default:
      return {
        title: 'Motivos de descarte',
        placeholder: 'Seleccione un motivo',
        value: discardReason,
        options: discardReasons,
        onChange: (reason) => setDiscardReason(reason)
      }
  }
}

const ModalLeads: React.FC<ModalLeadsProps> = ({
  open,
  selectedCountry,
  countries,
  email,
  gender,
  phone,
  name,
  lastName,
  actualM3Storage,
  sources,
  contactMediums,
  sourceChannel,
  contactMedium,
  discardReasons,
  discardReason,
  interestReason,
  interestReasons,
  editMode,
  okDisabled,
  funnelState,
  loseReason,
  loseOptionsMQL,
  loseReasonMQL,
  loseOptionsSQL,
  loseReasonSQL,
  freeLoseReason,
  handleClose,
  setCountry,
  setEmail,
  setGender,
  setName,
  setLastName,
  setSourceChannel,
  setContactMedium,
  setDiscardReason,
  setInterestReason,
  setLoseReasonMQL,
  setLoseReasonSQL,
  setCountryCode,
  setCodeArea,
  setPhoneNumber,
  setErrorPhone,
  onOk
}) => {
  const countryOptions = formatCountryOptions(countries, 'code')
  const optionFlag = (e: any) => e.customLabel
  const isFunnelStateAllowed = (funnelState: string) => Boolean(allowedFunnelStates.find((fs) => fs === funnelState))
  const disabledDynamicSelect = !editMode || isFunnelStateAllowed(funnelState)

  const showModal = !editMode
    ? {
        title: 'Crear nuevo Prospecto',
        okButton: 'Crear'
      }
    : {
        title: 'Editar Prospecto',
        okButton: 'Editar'
      }

  const { title, placeholder, value, options, onChange } = getDynamicOptions({
    funnelState,
    loseReason,
    discardReasons,
    discardReason,
    loseOptionsMQL,
    loseReasonMQL,
    loseOptionsSQL,
    loseReasonSQL,
    setDiscardReason,
    setLoseReasonMQL,
    setLoseReasonSQL
  })
  const disabledInputsForEmail = Boolean(email.errorMessage) || Boolean(!email.value)
  const freeReasonValue = options.some((option) => Number(option.value) === Number(value)) ? false : freeLoseReason

  const setCodeAreaAction = (codeArea: string) => {
    setCodeArea(codeArea, phone.countryCode)
  }

  const setPhoneNumberAction = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber, phone.codeArea.value, phone.countryCode)
  }

  const cellPhoneCleanErrors = () => setErrorPhone('')

  const countryCodeList = [
    ...countriesList,
    {
      value: CountryPhoneCode.ALL,
      label: CountryPhoneCode.ALL,
      image: '/img/newflags/all-v2.svg',
      alt: 'Otro'
    }
  ]

  return (
    <Modal
      width={'60%'}
      centered
      destroyOnClose
      title={showModal.title}
      okText={showModal.okButton}
      onCancel={handleClose}
      visible={open}
      onOk={onOk}
      okButtonProps={{ disabled: okDisabled }}
      cancelText="Cancelar">
      <Row
        gutter={[16, 16]}
        style={{ margin: 0, display: 'flex', flexDirection: 'column', gap: '8px', flexWrap: 'wrap' }}>
        <InputGroup title="Datos personales">
          {!disabledInputsForEmail && (
            <Row gutter={[16, 16]}>
              <div className={styles.tipContent}>
                <Info fill="#333" />
                <p className={styles.tipParagraph}>
                  M3 ACTUALMENTE ALMACENADOS POR ESTE USUARIO: {actualM3Storage.toFixed(2)} m<sub>3</sub>
                </p>
              </div>
            </Row>
          )}
          <Col sm={12} xs={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <p className={styles.inputTitle}>País*</p>
                <Select
                  placeholder="País"
                  selected={countryOptions.find((x) => x.value === selectedCountry.code)}
                  optionRenderer={optionFlag}
                  options={countryOptions}
                  disabled={disabledInputsForEmail}
                  // @ts-ignore
                  onSelect={(country: Country) => setCountry(country.fullCountry)}
                  required
                  tabIndex={1}
                  searchable={false}
                  clearable={false}
                />
              </Col>
              <Col span={24}>
                <DebouncedInputFieldWithError
                  label="Email"
                  className={styles.inputEmail}
                  errorMessage={email.errorMessage}
                  value={email.value}
                  setValue={setEmail}
                  tabIndex={2}
                  loading={email.loading}
                  required
                />
              </Col>
              <Col span={24}>
                <CellPhone
                  editMode={editMode || Boolean(phone.currentNumber)}
                  disabled={disabledInputsForEmail}
                  countries={countryCodeList}
                  currentNumber={phone.currentNumber}
                  countryCode={phone.countryCode}
                  setCountryCode={setCountryCode}
                  codeArea={phone.codeArea.value}
                  errorCodeArea={phone.codeArea.error}
                  setCodeArea={setCodeAreaAction}
                  errorPhoneNumber={phone.phoneNumber.error}
                  numberPhone={phone.phoneNumber.value}
                  setNumberPhone={setPhoneNumberAction}
                  cleanErrors={cellPhoneCleanErrors}
                  limit={phone.limitNumber}
                  tabsIndex={[3, 4, 5]}
                />
                {phone.errorMessage && (
                  <p style={{ marginBottom: 0 }} className={styles.errorFieldLabel}>
                    {phone.errorMessage}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <p className={styles.inputTitle}>Género</p>
                <Select
                  placeholder="Género"
                  selected={genders.find((x) => x.value === gender)}
                  options={genders}
                  disabled={disabledInputsForEmail}
                  // @ts-ignore
                  onSelect={(option: string) => setGender(getOptionValue(option))}
                  tabIndex={6}
                />
              </Col>
              <Col span={24}>
                <InputFieldWithError
                  error={name.errorMessage}
                  inputStyles={{ minHeight: '34.01px' }}
                  label="Nombre"
                  value={name.value}
                  onChange={(e) => setName(e.target.value)}
                  disabled={disabledInputsForEmail}
                  tabIndex={7}
                />
              </Col>
              <Col span={24}>
                <InputFieldWithError
                  label="Apellido"
                  inputStyles={{ minHeight: '34.01px' }}
                  error={lastName.errorMessage}
                  value={lastName.value}
                  onChange={(e) => setLastName(e.target.value)}
                  tabIndex={8}
                  disabled={disabledInputsForEmail}
                />
              </Col>
            </Row>
          </Col>
        </InputGroup>
        <InputGroup title="Usuarios">
          <Row gutter={[16, 16]}>
            <Col sm={12} xs={24}>
              <p className={styles.inputTitle}>Canal de origen*</p>
              <Select
                placeholder="Origen"
                // @ts-ignore
                selected={sources.find((x) => x.value === sourceChannel)}
                // @ts-ignore
                options={sources}
                clearable={false}
                onSelect={(source) => setSourceChannel(getOptionValue(source))}
                tabIndex={9}
                disabled={disabledInputsForEmail}
                required={!editMode}
              />
            </Col>
            <Col sm={12} xs={24}>
              <p className={styles.inputTitle}>Medio de contacto</p>
              <Select
                placeholder="Contacto"
                // @ts-ignore
                selected={contactMediums.find((x) => x.value === Number(contactMedium))}
                // @ts-ignore
                options={contactMediums}
                onSelect={(contact) => setContactMedium(getOptionValue(contact))}
                tabIndex={10}
                disabled={disabledInputsForEmail}
                searchable={false}
                clearable={false}
              />
            </Col>
          </Row>
        </InputGroup>
        <InputGroup title="Clasificación">
          {!editMode ? (
            <Row gutter={[16, 16]}>
              <div className={styles.tipContent}>
                <Info fill="#333" />
                <p className={styles.tipParagraph}>Estos campos solo estan habilitados en el modo edición</p>
              </div>
            </Row>
          ) : (
            ''
          )}
          <Row gutter={[16, 16]}>
            <Col sm={12} xs={24}>
              <p className={styles.inputTitle}>Interés</p>
              <Select
                placeholder="Interés"
                // @ts-ignore
                selected={interestReasons.find((x) => x.value === Number(interestReason))}
                // @ts-ignore
                options={interestReasons}
                disabled={!editMode || funnelState === funnelStates.PENDING || funnelState === funnelStates.DISCARD}
                onSelect={(reason) => setInterestReason(getOptionValue(reason))}
                tabIndex={11}
                searchable={false}
                clearable={false}
              />
            </Col>
            <Col sm={12} xs={24}>
              <DynamicSelectReasons
                title={title}
                placeholder={placeholder}
                value={value}
                freeReasonValue={freeReasonValue}
                options={options}
                disabled={disabledDynamicSelect}
                onChange={onChange}
              />
            </Col>
          </Row>
        </InputGroup>
      </Row>
    </Modal>
  )
}

export default ModalLeads

export type DynamicSelectProps = {
  title?: string
  placeholder: string
  value: string
  disabled?: boolean
  options: Option[]
  freeReasonValue?: string | boolean
  onChange: (reason: number) => void
}

export const DynamicSelectReasons: React.FC<DynamicSelectProps> = ({
  title,
  placeholder,
  value,
  options,
  disabled,
  freeReasonValue,
  onChange
}) => {
  const finalOptions = freeReasonValue ? [...options, { label: freeReasonValue, value }] : options

  return (
    <>
      {title ? <p className={styles.inputTitle}>{title}</p> : ''}
      <Select
        placeholder={placeholder}
        // @ts-ignore
        selected={value}
        // @ts-ignore
        options={finalOptions}
        clearable={false}
        searchable={false}
        disabled={disabled}
        onSelect={(reason) => onChange(getOptionValue(reason))}
      />
    </>
  )
}
