import React, { useMemo } from 'react'
import {
  PickingsDateRangeFilter,
  PickingsSearchFilter,
  PickingsTableCategoryFilter,
  PickingsTableCategoryFilterKey,
  PickingsTableDateRangeKey,
  PickingsTableSearchKey,
  PickingsTableSort
} from '../types/pickingsTable'
import { Column, Pagination, SortDirection, SortProps } from '../../../components/DynamicTable/types/types'
import { DynamicTable } from '../../../components/DynamicTable'
import { Moment } from 'moment'
import styles from './pickingsTable.module.scss'
import { getFilterProps, getRangePickerProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { formatDate } from '../../../utils/formatDate'
import {
  Agent,
  Picking,
  PickingObject,
  PickingsListSortKey
} from '../../../projectApi/TransactionHandler/Pickings/list'
import { CubeCompass, CubeLocation, ObjectsToSearch, QRIcon } from '../../../icons'
import { Tooltip } from 'antd'
import notAvailableImage from '../../Objects/images/notAvailableImage.jpg'
import ImgWithCarousel from '../../../components/CarouselModal/components/ImgWithCarousel'
import { ObjectStatusBadges, PickingStatusBadges } from '../constants'
import ColorBadge from '../../../components/ColorBadge'
import { Operator } from '../../../projectApi/TransactionHandler/Pickupers/list'

export const getSortProps = (
  key: PickingsListSortKey,
  currentSorter: PickingsTableSort,
  sorterFunction: (newSorter: PickingsTableSort) => void
): SortProps => {
  return {
    direction: currentSorter.direction,
    isActive: currentSorter.field === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        direction: currentSorter.field === key ? newSortDirection : SortDirection.ASC,
        field: key
      })
  }
}

export type PickingsTableProps = {
  operators: Operator[]
  loadingOperators: boolean
  pickings: Picking[]
  loadingPickings: boolean
  pagination: Pagination
  searchFilters: PickingsSearchFilter[]
  categoryFilter: PickingsTableCategoryFilter
  dateRangeFilters: PickingsDateRangeFilter[]
  sort: PickingsTableSort
  handleSort: (newSorter: PickingsTableSort) => void
  handleSearch: (key: PickingsTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newFilter: PickingsTableCategoryFilter) => void
  openQRModal: (code: string) => void
  handleRangePicker: (key: PickingsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
}

const PickingsTable: React.FC<PickingsTableProps> = ({
  operators,
  pickings,
  loadingPickings,
  pagination,
  categoryFilter,
  searchFilters,
  dateRangeFilters,
  sort,
  openQRModal,
  handleRangePicker,
  handleCategoryFilter,
  handleSort,
  handleSearch
}) => {
  const formattedOperators = useMemo(
    () =>
      operators.map((o) => ({
        value: o.id,
        text: `${o.name} ${o.lastName}`
      })),
    [operators]
  )

  const renderAgentText = (agent?: Agent) => (agent ? `${agent.name} ${agent.lastName}` : '-')
  const renderPlusAgents = (agents: Agent[]) =>
    agents.length > 0 ? <span className={styles.plusAgents}> +{agents.length}</span> : null

  const renderMultipleAgents = (agents: Agent[]) => {
    if (agents.length === 0) return null
    return (
      <>
        {agents.map((agent) => (
          <div key={agent.agentId}>{renderAgentText(agent)}</div>
        ))}
      </>
    )
  }

  const renderAgents = (agents: Agent[]) => {
    const [firstAgent, ...rest] = agents

    return (
      <Tooltip title={renderMultipleAgents(rest)}>
        {renderAgentText(firstAgent)}
        {renderPlusAgents(rest)}
      </Tooltip>
    )
  }

  const columns: Column<Picking>[] = [
    {
      key: 'id',
      label: 'ID Búsqueda',
      noPadding: true,
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('id', sort, handleSort)
    },
    {
      key: 'owner.id',
      label: 'ID Usuario',
      noPadding: true,
      search: getSearchProps('owner_id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'operationId',
      label: 'ID Operación',
      noPadding: true,
      search: getSearchProps('operation_id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'agents',
      label: 'Responsables',
      alignment: 'center',
      renderDataCell: ({ agents }) => renderAgents(agents),
      filters: getFilterProps<PickingsTableCategoryFilterKey>(
        formattedOperators,
        categoryFilter,
        'agent_id',
        handleCategoryFilter
      )
    },
    {
      key: 'createdAt',
      label: 'F. de creación',
      alignment: 'left',
      renderDataCell: ({ createdAt }) => (createdAt ? formatDate(createdAt) : '-'),
      rangePicker: getRangePickerProps('created_at', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'pickingDate',
      label: 'F. de servicio',
      alignment: 'left',
      renderDataCell: ({ pickingDate }) => (pickingDate ? formatDate(pickingDate) : '-'),
      rangePicker: getRangePickerProps('picking_date', dateRangeFilters, handleRangePicker),
      sort: getSortProps('picking_date', sort, handleSort)
    },
    {
      key: 'startDate',
      label: 'Inicio',
      alignment: 'left',
      renderDataCell: ({ startDate }) => (startDate ? formatDate(startDate) : '-'),
      rangePicker: getRangePickerProps('start_date', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'endDate',
      label: 'Fin',
      alignment: 'left',
      renderDataCell: ({ endDate }) => (endDate ? formatDate(endDate) : '-'),
      rangePicker: getRangePickerProps('end_date', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'objectsMetrics.quantityObjects',
      label: <ObjectsToSearch />,
      tooltip: 'Objetos a buscar',
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'objectsMetrics.quantityObjectsFounded',
      label: <CubeCompass />,
      tooltip: 'Objetos Encontrados',
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'objectsMetrics.quantityObjectsNotFounded',
      tooltip: 'Objetos No Encontrados',
      label: <CubeLocation />,
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'status',
      label: 'Estado',
      filters: getFilterProps<PickingsTableCategoryFilterKey>(
        PickingStatusBadges,
        categoryFilter,
        'status',
        handleCategoryFilter
      ),
      renderDataCell: ({ status }) => {
        const pickingBadge = PickingStatusBadges.find((badge) => badge.value === status)
        if (!pickingBadge) return status

        return <ColorBadge {...pickingBadge.styleProps}>{pickingBadge.text}</ColorBadge>
      }
    }
  ]

  return (
    <div>
      <DynamicTable
        columns={columns}
        loading={loadingPickings}
        rows={pickings}
        keyExtractor={(entity) => entity.id}
        rowExpander={{
          render: (picking) => <PickingExpanded openQRModal={openQRModal} objects={picking.pickingObjects} />,
          triggerFunctionOnce: false
        }}
        pagination={pagination}
        customHeightOffset={350}
      />
    </div>
  )
}

const PickingExpanded: React.FC<{
  objects: PickingObject[]
  openQRModal: (code: string) => void
}> = ({ objects, openQRModal }) => {
  const columns: Column<PickingObject>[] = [
    {
      key: 'photo',
      label: 'Foto',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({
        object: {
          photos,
          product: { description }
        }
      }) => {
        if (photos.length === 0) {
          return <img src={notAvailableImage} alt="-" style={{ objectFit: 'cover', width: '40px', height: '42px' }} />
        } else {
          const parsedPhotos = photos.map((p) => p.url)

          return <ImgWithCarousel carouselImages={parsedPhotos} alt={description} />
        }
      }
    },
    {
      key: 'object.id',
      label: 'ID Objeto'
    },
    {
      key: 'object.product.id',
      label: 'ID Producto'
    },
    {
      key: 'object.product.description',
      label: 'Nombre del Producto'
    },
    {
      key: 'object',
      label: 'Etiqueta',
      renderDataCell: ({ object: { identificationCode } }) => {
        if (!identificationCode[0]) return '-'

        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i style={{ cursor: 'pointer', marginRight: 8 }} onClick={() => openQRModal(identificationCode[0].code)}>
                <QRIcon width="16px" height="16px" />
              </i>
            </Tooltip>
            {identificationCode[0]?.code}
          </div>
        )
      }
    },
    {
      key: 'object.location.code',
      label: 'Ubicación',
      renderDataCell: ({
        object: {
          location: { code }
        }
      }) => {
        if (!code) return '-'

        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i style={{ cursor: 'pointer', marginRight: 8 }} onClick={() => openQRModal(code)}>
                <QRIcon width="16px" height="16px" />
              </i>
            </Tooltip>
            {code}
          </div>
        )
      }
    },
    {
      key: 'object.objectStatus.description',
      label: 'Estado',
      renderDataCell: ({
        object: {
          objectStatus: { description }
        }
      }) => {
        const objectBadge = ObjectStatusBadges.find((badge) => badge.value === description)
        if (!objectBadge) return description

        return <ColorBadge {...objectBadge.styleProps}>{objectBadge.text}</ColorBadge>
      }
    }
  ]

  return (
    <div style={{ padding: '12px 0', maxHeight: 500 }}>
      <DynamicTable
        columns={columns}
        rows={objects}
        keyExtractor={(entity) => entity.id}
        customClassNames={{
          table: styles.objectsTable
        }}
      />
    </div>
  )
}

export default PickingsTable
