import { Country } from '../../../components/CountrySelector/constants/constants'

export enum GlobalEventsEnum {
  CHANGE_ACCOUNT = 'GLOBAL/CHANGE_ACCOUNT',
  CHANGE_COUNTRY = 'GLOBAL/CHANGE_COUNTRY'
}

export type GlobalEvents = {
  [GlobalEventsEnum.CHANGE_COUNTRY]: (country: Country) => void
  [GlobalEventsEnum.CHANGE_ACCOUNT]: (accountId: number) => void
}
