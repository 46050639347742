import React, { ReactNode, useEffect, useMemo } from 'react'
import styles from './GeneralData.module.scss'
import InputGroup from '../../../../components/InputGroup'
import Select from '../../../../components/Select/Select'
import { Checkbox } from 'antd'
import { TextField } from '../../../../components/TextField/TextField'

import { LeafCategory } from '../../../../projectApi/CategoryCreation/getCategories'
import { CategoryOption, SolutionOption } from '../../types/GeneralData'
import clsx from 'clsx'
import { handleErrorNumberFields } from '../../utils'
import { Solution } from '../../../../projectApi/CategoryCreation/Solutions/list'
import { ProductCatalogue } from '@/projectApi/CategoryCreation/Catalog/search'

export type GeneralDataProps = {
  categories: LeafCategory[]
  solutions: Solution[]
  isProductSearchable: boolean
  dimensionsHeight: string
  dimensionsLength: string
  dimensionsWeight: string
  dimensionsWidth: string
  disassembleTime: string
  maxDeviation: string
  minDeviation: string
  minPhotos: string
  multiplier: string
  packagingTime: string
  categorySelected: CategoryOption | null
  solutionsSelected: SolutionOption[]
  setCategorySelected: (categorySelected: CategoryOption | null) => void
  setSolutionsSelected: (solutionsSelected: SolutionOption[]) => void
  setMultiplier: (multiplier: string) => void
  setDimensionsHeight: (dimensionsHeight: string) => void
  setDimensionsLength: (dimensionsLength: string) => void
  setDimensionsWeight: (dimensionsWeight: string) => void
  setDimensionsWidth: (dimensionsWidth: string) => void
  setDisassembleTime: (disassembleTime: string) => void
  setMaxDeviation: (maxDeviation: string) => void
  setMinDeviation: (minDeviation: string) => void
  setMinPhotos: (minPhotos: string) => void
  setPackagingTime: (packagingTime: string) => void
  setIsProductSearchable: (isProductSearchable: boolean) => void
  isFromCatalog: boolean
  productDetail: ProductCatalogue | null
}

export const handleFieldChange = (value: string, callback: (value: string) => void) => {
  if (isNaN(Number(value))) return
  if (Number(value) < 0) return
  callback(value)
}

export const GeneralData: React.FC<GeneralDataProps> = ({
  categories,
  solutions,
  dimensionsHeight,
  dimensionsLength,
  dimensionsWeight,
  dimensionsWidth,
  disassembleTime,
  maxDeviation,
  minDeviation,
  minPhotos,
  multiplier,
  packagingTime,
  isProductSearchable,
  setDimensionsHeight,
  setDimensionsLength,
  setDimensionsWeight,
  setDimensionsWidth,
  setDisassembleTime,
  setMaxDeviation,
  setMinDeviation,
  setMinPhotos,
  setMultiplier,
  setPackagingTime,
  setIsProductSearchable,
  categorySelected,
  solutionsSelected,
  setCategorySelected,
  setSolutionsSelected,
  isFromCatalog,
  productDetail
}) => {
  const [autocomplete, setAutocomplete] = React.useState('')

  const categoriesOptions: CategoryOption[] = useMemo(() => {
    return categories
      .filter((c) => c.isLeaf)
      .map((category) => ({
        label: category.description,
        value: category.id
      }))
  }, [categories])

  const solutionsOptions: SolutionOption[] = useMemo(() => {
    return solutions.map((service) => ({
      label: service.description,
      value: service.id
    }))
  }, [solutions])

  useEffect(() => {
    if (isFromCatalog && productDetail) {
      handleFieldChange((productDetail.weightInGr / 100).toString(), setDimensionsWeight)
      handleFieldChange(productDetail.heightInCm.toString(), setDimensionsHeight)
      handleFieldChange(productDetail.widthInCm.toString(), setDimensionsWidth)
      handleFieldChange(productDetail.lengthInCm.toString(), setDimensionsLength)

      const foundSolution = solutionsOptions.find((x) => x.label === productDetail.solution.description)

      if (foundSolution) {
        setSolutionsSelected([foundSolution])
      }
    }
  }, [productDetail, solutionsOptions])

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Ingresá los datos del producto</h1>
      <p className={styles.subtitle}>Esta información es única para todos los países</p>

      <div className={styles.groupContainer}>
        <InputGroup title="Características" style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Row className={styles.categoryGroup}>
            <div>
              <p style={{ marginBottom: 5 }}>Categoría</p>
              <Select
                onSelect={(value) => setCategorySelected(value)}
                options={categoriesOptions}
                selected={categorySelected}
                placeholder="Seleccione una categoría"
                className={styles.select}
                autocomplete={{
                  filterByInput: true,
                  input: autocomplete,
                  onInputChange: setAutocomplete
                }}
                onDelete={() => setCategorySelected(null)}
              />
            </div>
            <TextField
              label="Categoría Mercado Libre"
              onChange={(value) => handleFieldChange(value, () => {})}
              value={isFromCatalog && productDetail ? productDetail.category.name : ''}
              disabled={true}
            />
          </Row>
          <Checkbox
            className={styles.search}
            onChange={(e) => setIsProductSearchable(e.target.checked)}
            checked={isProductSearchable}>
            Aparecer en búsquedas (¿Necesita que el producto aparezca cuando lo busca el cliente?)
          </Checkbox>
          <Row>
            <div>
              <p style={{ marginBottom: 5 }}>Soluciones</p>
              <Select
                onSelect={(value) => setSolutionsSelected([...solutionsSelected, value])}
                onDelete={(value) => setSolutionsSelected(solutionsSelected.filter((s) => s.value !== value.value))}
                selected={solutionsSelected}
                multiple={true}
                options={solutionsOptions}
                placeholder="Seleccione las soluciones"
                className={styles.select}
                disabled={isFromCatalog}
              />
            </div>
            <div style={{ marginTop: 25, width: '100%' }}>
              <TextField
                label="Multiplicador"
                onChange={(value) => handleFieldChange(value, setMultiplier)}
                value={multiplier}
                error={handleErrorNumberFields(multiplier)}
              />
            </div>
          </Row>
        </InputGroup>

        <InputGroup title="Tiempos de Ingreso" style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Row>
            <TextField
              label="Tiempo de embalaje (en minutos)"
              onChange={(value) => handleFieldChange(value, setPackagingTime)}
              value={packagingTime}
              error={handleErrorNumberFields(packagingTime)}
            />
            <TextField
              label="Tiempo de desarmado (en minutos)"
              onChange={(value) => handleFieldChange(value, setDisassembleTime)}
              value={disassembleTime}
              error={handleErrorNumberFields(disassembleTime)}
            />
          </Row>
        </InputGroup>

        <InputGroup title="Dimensiones" style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Row>
            <TextField
              label="Peso (Kg)"
              onChange={(value) => handleFieldChange(value, setDimensionsWeight)}
              value={dimensionsWeight}
              error={handleErrorNumberFields(dimensionsWeight)}
            />
            <TextField
              label="Largo (cm)"
              onChange={(value) => handleFieldChange(value, setDimensionsLength)}
              value={dimensionsLength}
              error={handleErrorNumberFields(dimensionsLength)}
            />
            <TextField
              label="Ancho (cm)"
              onChange={(value) => handleFieldChange(value, setDimensionsWidth)}
              value={dimensionsWidth}
              error={handleErrorNumberFields(dimensionsWidth)}
            />
            <TextField
              label="Alto (cm)"
              onChange={(value) => handleFieldChange(value, setDimensionsHeight)}
              value={dimensionsHeight}
              error={handleErrorNumberFields(dimensionsHeight)}
            />
          </Row>
        </InputGroup>

        <InputGroup title="Desviación" style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Row>
            <TextField
              label="Mínimo (%)"
              onChange={(value) => handleFieldChange(value, setMinDeviation)}
              value={minDeviation}
              error={handleErrorNumberFields(minDeviation)}
              disabled={isFromCatalog}
            />
            <TextField
              label="Máximo (%)"
              onChange={(value) => handleFieldChange(value, setMaxDeviation)}
              value={maxDeviation}
              error={handleErrorNumberFields(maxDeviation)}
              disabled={isFromCatalog}
            />
          </Row>
        </InputGroup>

        <InputGroup title="Producto" style={{ paddingLeft: 16, paddingRight: 16 }}>
          <div className={styles.minPhotos}>
            <TextField
              label="Mínimo de fotos"
              onChange={(value) => handleFieldChange(value, setMinPhotos)}
              value={minPhotos}
              error={handleErrorNumberFields(minPhotos)}
            />
          </div>
        </InputGroup>
      </div>
    </div>
  )
}

interface RowProps {
  children: ReactNode
  className?: string
}

export const Row: React.FC<RowProps> = ({ children, className }) => {
  return <div className={clsx(styles.row, className && className)}>{children}</div>
}
