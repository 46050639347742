import { combineReducers } from 'redux'
import TripModalReducer from './reducers/tripModal'
import TripsTableReducer from './reducers/tripsTable'
import tripsMetricsReducer from './reducers/tripsMetrics'

export default combineReducers({
  table: TripsTableReducer,
  modal: TripModalReducer,
  metrics: tripsMetricsReducer
})
