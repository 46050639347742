import { Role } from '../../../projectApi/AccountManager/Roles/getAll'

export const GET_ROLES_REQUEST = 'USERS/CHECKOUT/GET_ROLES_REQUEST'
export const GET_ROLES_SUCCESS = 'USERS/CHECKOUT/GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = 'USERS/CHECKOUT/GET_ROLES_FAILURE'

export type GetRolesRequest = {
  type: typeof GET_ROLES_REQUEST
}

export type GetRolesSuccess = {
  type: typeof GET_ROLES_SUCCESS
  payload: {
    roles: Role[]
  }
}

export type GetRolesFailure = {
  type: typeof GET_ROLES_FAILURE
  payload: {
    error: string
  }
}

export type RolesState = {
  loading: boolean
  roles: Role[]
  error: string
}

export type RolesActions = GetRolesRequest | GetRolesSuccess | GetRolesFailure
