import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { convertToMoment } from '../../utils'
import { Audit, AuditToCamel } from './common'

interface APIResponse {
  audits: Audit[]
}

interface Response {
  audits: AuditToCamel[]
}

interface AuditParams {
  operationId: number
}

export function audit({ operationId }: AuditParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operations/audit/${operationId}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.audits) throw new Error('Respuesta incorrecta al obtener las auditorias')

      const camelOperations = camelcaseKeys(response.data.audits, { deep: true }).map((op) => ({
        ...op,
        date: convertToMoment(op.date),
        entityNew: {
          ...op.entityNew,
          serviceDate: convertToMoment(op.entityNew.serviceDate),
          items: op.entityNew.items?.map((item) => ({
            ...item,
            updatedAt: convertToMoment(item.updatedAt)
          }))
        },
        entityOld: {
          ...op.entityOld,
          serviceDate: convertToMoment(op.entityOld.serviceDate),
          items: op.entityOld.items?.map((item) => ({
            ...item,
            updatedAt: convertToMoment(item.updatedAt)
          }))
        }
      }))

      return {
        audits: camelOperations
      }
    })
    .catch((error) => {
      throw error
    })
}
