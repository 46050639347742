import moment from '../../../moment'
import { pathOr } from 'ramda'

export function getAstrologicalSign(dateBirth) {
  const astrologicalSigns = [
    { from: '03-21', to: '04-19', sign: 'Aries' },
    { from: '04-20', to: '05-20', sign: 'Tauro' },
    { from: '05-21', to: '06-20', sign: 'Geminis' },
    { from: '06-21', to: '07-22', sign: 'Cancer' },
    { from: '07-23', to: '08-22', sign: 'Leo' },
    { from: '08-23', to: '09-22', sign: 'Virgo' },
    { from: '09-23', to: '10-22', sign: 'Libra' },
    { from: '10-23', to: '11-21', sign: 'Escorpio' },
    { from: '11-22', to: '12-21', sign: 'Sagitario' },
    { from: '12-22', to: '01-19', sign: 'Capricornio' },
    { from: '01-20', to: '02-18', sign: 'Acuario' },
    { from: '02-19', to: '03-20', sign: 'Piscis' }
  ]
  const formatDate = dateBirth.format('MM-DD')

  const astrologicalSign = astrologicalSigns.find((astrologicalSign) => {
    if (astrologicalSign.sign === 'Capricornio') {
      const month = dateBirth.month()
      const year = dateBirth.year()
      if (month === 11)
        return moment(dateBirth).isBetween(
          `${year}-${astrologicalSign.from}`,
          `${year + 1}-${astrologicalSign.to}`,
          'days',
          '[]'
        )
      return moment(dateBirth).isBetween(
        `${year - 1}-${astrologicalSign.from}`,
        `${year}-${astrologicalSign.to}`,
        'days',
        '[]'
      )
    }
    return moment(formatDate).isBetween(astrologicalSign.from, astrologicalSign.to, 'days', '[]')
  })

  return pathOr(' - ', ['sign'], astrologicalSign)
}
