import React from 'react'
import styles from './excludeUserModal.module.scss'
import { Col, Modal, Row } from 'antd'
import { BillingData } from '../../../projectApi/TransactionHandler/BillingData/list'
import { InfoCard } from '../../../components/InfoCard/InfoCard'

export type SendDebtReminderModalComponentProps = {
  handleClose: () => void
  handleSendDebtReminder: () => void
  IsDebtModalOpen: boolean
  error: string
  isLoadingCharge: boolean
  selectedBilling: BillingData | null
}

const SendDebtReminderModal = (props: SendDebtReminderModalComponentProps) => {
  const { IsDebtModalOpen, error, handleSendDebtReminder, isLoadingCharge, handleClose, selectedBilling } = props

  return (
    <Modal
      className={styles.modal}
      title={'Enviar Recordatorio de Deuda'}
      visible={IsDebtModalOpen}
      closable={true}
      keyboard={false}
      onOk={handleSendDebtReminder}
      onCancel={handleClose}
      cancelButtonProps={{
        disabled: isLoadingCharge
      }}
      okButtonProps={{
        disabled: isLoadingCharge
      }}
      okText="Confirmar"
      cancelText={'Cancelar'}>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24}>
          <InfoCard
            title="Información del usuario"
            id={selectedBilling?.userResponse.id || 0}
            lastName={selectedBilling?.userResponse.lastName || ''}
            name={selectedBilling?.userResponse.name || ''}
          />

          <p className={styles.excludedText}>¿Está seguro que desea mandar el recordatorio de deuda?</p>
        </Col>
      </Row>
      {error && <p>{error}</p>}
    </Modal>
  )
}

export default SendDebtReminderModal
