import { AxiosResponse } from 'axios'
import { RequireAllOrNone } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

type APIResponse = void
type Response = void

export type ObjectAttributeRequest = {
  id: number
  value: string
  attributeId: number
  deleted: boolean
}

export type EditCorporateObjectBody = RequireAllOrNone<
  {
    countryId: string
    locationId?: number
    productId?: number
    weightInGr?: number
    lengthInCm: number
    widthInCm: number
    heightInCm: number
    attributes?: ObjectAttributeRequest[]
  },
  'lengthInCm' | 'widthInCm' | 'heightInCm'
>

export function editObject(objectId: number, params: EditCorporateObjectBody): Promise<Response> {
  const body = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .put(`${URL_BASE_OBJECTS}/corporate/object/${objectId}`, body)
    .then((response: AxiosResponse<APIResponse>) => {})
    .catch((err) => {
      throw err
    })
}
