import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { convertToMoment } from '../../utils'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'
import { OverrideProps } from '../../../utils/utilityTypes'

interface APIBillingData {
  id: number
  billing_id: number | null
  created_at: string
  start_date: string
  end_date: string
  paid_date: string
  m_3: number
  m_3_value: number
  total: number
  status: string
  details: APIDetail[]
  user_response: APIUserResponse
  billing_metrics_response: APIBillingMetricsResponse
  excluded_user_response: APIExcludedUserResponse
}

interface APIExcludedUserResponse {
  id: number
  reason: APIReason
  user_id: number
  key: string
  date_from: string
  date_to: string | null
}

type ExcludedUserResponse = Omit<
  CamelCasedPropertiesDeep<APIExcludedUserResponse>,
  'dateFrom' | 'dateTo' | 'reason'
> & {
  reason: Reason
  dateFrom: Moment | null
  dateTo: Moment | null
}

interface APIReason {
  id: number
  description: string
  created_at: string
  updated_at: string
  deleted_at: string | null
}

type Reason = OverrideProps<CamelCasedPropertiesDeep<APIReason>, 'createdAt' | 'updatedAt' | 'deletedAt', Moment | null>

export type BillingData = Omit<
  CamelCasedPropertiesDeep<APIBillingData>,
  'details' | 'startDate' | 'endDate' | 'paidDate' | 'excludedUserResponse' | 'createdAt'
> & {
  excludedUserResponse: ExcludedUserResponse
  details: Detail[]
  startDate: Moment | null
  endDate: Moment | null
  paidDate: Moment | null
  createdAt: Moment | null
}

interface APIDetail {
  id: number | null
  item_id: number
  item_description: string
  start_date: string
  end_date: string
  m3_price: number
  price: number
  total: number
  m3: number
}

export type Detail = OverrideProps<CamelCasedPropertiesDeep<APIDetail>, 'startDate' | 'endDate', Moment | null>

export interface APIUserResponse {
  id: number
  name: string
  last_name: string
}

export type UserResponse = CamelCasedPropertiesDeep<APIUserResponse>

export interface APIBillingMetricsResponse {
  deposit_quantity: number
  removals_quantity: number
  percentage_dif_total: number
  percentage_dif_m_3: number
}

export interface APIResponse {
  results: APIBillingData[]
  paging: {
    total: number
    limit: number
    offset: number
  }
}

interface Response {
  billings: BillingData[]
  total: number
}

export type ListParams = {
  id?: number
  status?: string
  userId?: string
  excludedUsers?: string
  withBillingDetail?: boolean
  limit?: number
  offset?: number
  periodFrom?: Moment | null
  periodTo?: Moment | null
  warningId?: number
}

export function list(params: ListParams): Promise<Response> {
  const { periodFrom, periodTo, warningId, ...otherParams } = params

  const snakeParams = snakecaseKeys({
    ...otherParams,
    warningTypeId: warningId || undefined,
    periodFrom: periodFrom?.format('YYYY-MM-DD'),
    periodTo: periodTo?.format('YYYY-MM-DD')
  })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billing-data`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.results) {
        throw new Error('Respuesta incorrecta al obtener los datos de facturación')
      }

      const camelResponse: Response = {
        total: response.data.paging.total,
        billings: camelcaseKeys(response.data.results, { deep: true }).map((billing) => ({
          ...billing,
          createdAt: convertToMoment(billing.createdAt),
          startDate: convertToMoment(billing.startDate),
          endDate: convertToMoment(billing.endDate),
          paidDate: convertToMoment(billing.paidDate),
          details: billing.details?.map((detail) => ({
            ...detail,
            startDate: convertToMoment(detail.startDate),
            endDate: convertToMoment(detail.endDate)
          })),
          excludedUserResponse: {
            ...billing.excludedUserResponse,
            dateFrom: convertToMoment(billing.excludedUserResponse?.dateFrom),
            dateTo: convertToMoment(billing.excludedUserResponse?.dateTo),
            reason: {
              ...billing.excludedUserResponse?.reason,
              createdAt: convertToMoment(billing.excludedUserResponse?.reason.createdAt),
              updatedAt: convertToMoment(billing.excludedUserResponse?.reason.updatedAt),
              deletedAt: convertToMoment(billing.excludedUserResponse?.reason.deletedAt)
            }
          }
        }))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
