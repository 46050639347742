export enum CardsEventsEnum {
  MARK_MAIN_CARD = 'CREDIT_CARD/MARK_MAIN_CARD',
  DISABLE_CARD = 'CREDIT_CARD/DISABLE_CARD',
  ADD_NEW_CARD = 'CREDIT_CARD/ADD_NEW_CARD'
}

export type CardsEvents = {
  [CardsEventsEnum.MARK_MAIN_CARD]: () => void
  [CardsEventsEnum.DISABLE_CARD]: () => void
  [CardsEventsEnum.ADD_NEW_CARD]: () => void
}
