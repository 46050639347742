import React, { useState } from 'react'
import { Steps as AntdSteps, Col, Row } from 'antd'
import { useHistory } from 'react-router'
import RemovalDate from './containers/RemovalDate'
import Transport from './containers/Transport'
import Payment from './containers/Payment'
import Summary from './containers/Summary'
import ObjectsData from './containers/ObjectsData'
import ObjectsToRemoveSelection from './containers/ObjectsToRemoveSelection'
import Extras from './containers/Extras'
import FinishSummary from './containers/FinishSummary'
import { UserState } from './reducers/users'
import { PaymentScreens, Steps } from './constants'
import { PaymentMpState } from './reducers/payment-mp'
import { EditModeState } from './types/editMode'
import { PaymentTypeRemoval } from '../../projectApi/TransactionHandler/Operation/Removals/update'

const { Step } = AntdSteps

const styles = {
  container: {
    maxWidth: 1280,
    margin: '0 auto'
  },
  row: {
    margin: 0
  },
  summaryContainer: {
    position: 'sticky' as const,
    top: 65
  }
}

type ComponentMap = {
  [key in Steps]?: JSX.Element
}

type NewRemovalProps = {
  userSearchState: UserState
  commonState: any
  paymentMpState: PaymentMpState
  editState: EditModeState
  editRemoval: (paymentType?: PaymentTypeRemoval) => Promise<boolean>
  finish: () => void
  saveRemoval: () => Promise<boolean>
  createDraftRemoval: () => Promise<boolean>
  openModalResume: () => void
  handlePayRemoval: () => Promise<boolean>
  handleEditMode: () => void
}

export const Component = (props: NewRemovalProps) => {
  const {
    paymentMpState,
    editState,
    finish,
    saveRemoval,
    editRemoval,
    createDraftRemoval,
    handlePayRemoval,
    handleEditMode
  } = props

  const { loading: paymentMpLoading } = paymentMpState
  const { editMode } = editState

  const history = useHistory()
  const [step, setStep] = useState<Steps>(0)
  const [paymentScreen, setPaymentScreen] = React.useState<PaymentScreens>(PaymentScreens.PAYMENT_SELECTION)

  const save = async () => {
    if (editMode) {
      await editRemoval()
      setStep(Steps.PAYMENT)
    } else {
      createDraftRemoval().then((success) => {
        if (success) {
          setStep(Steps.PAYMENT)
          handleEditMode()
        }
      })
    }
  }

  const payAsFree = async (paymentTypeRemoval: PaymentTypeRemoval) => {
    const result = await editRemoval(paymentTypeRemoval)
    if (result) pay()
  }

  const pay = () => {
    handlePayRemoval().then((success) => {
      if (success) {
        history.push('/removals')
        finish()
      }
    })
  }

  const stepsComponent: ComponentMap = {
    [Steps.SELECTION]: (
      <>
        <ObjectsData />
        <ObjectsToRemoveSelection />
      </>
    ),
    [Steps.EXTRAS]: <Extras />,
    [Steps.ADDRESS]: (
      <>
        <Transport />
        <RemovalDate />
      </>
    ),
    [Steps.FINISH_SUMMARY]: <FinishSummary editMode={editMode} setStep={setStep} />,
    [Steps.PAYMENT]: <Payment paymentScreen={paymentScreen} setPaymentScreen={setPaymentScreen} />
  }

  const getStepComponent = () => {
    return stepsComponent[step] || null
  }

  return (
    <div style={styles.container}>
      <Row style={styles.container}>
        <Col md={24} lg={16}>
          <Row gutter={[24, 24]} style={styles.row}>
            <Col span={24}>
              <AntdSteps size="small" current={step}>
                <Step title="Selección" />
                <Step title="Extras" />
                <Step title="Dirección" />
                <Step title="Resumen" />
                <Step title="Pago" />
              </AntdSteps>
            </Col>
          </Row>
          <Col span={24}>{getStepComponent()}</Col>
        </Col>
        <Col md={24} lg={8} style={styles.summaryContainer}>
          <Row gutter={[16, 16]} style={styles.row}>
            <Col span={24}>
              <Summary
                paymentScreen={paymentScreen}
                setPaymentScreen={setPaymentScreen}
                loading={paymentMpLoading}
                editMode={editMode}
                step={step}
                setStep={setStep}
                save={save}
                pay={pay}
                payAsFree={payAsFree}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Component
