import {
  CreditCardAction,
  CreditCardState,
  SetInputsActionTypes,
  CommonActionTypes,
  SetDocumentTypesOptionsActionTypes
} from './types'

const initialState = {
  cardNumber: '',
  cvc: '',
  expirationDate: '',
  cardOwnerName: '',
  documentType: '',
  documentNumber: '',
  isLoading: false,
  documentTypeOptions: [],
  errors: {}
}

function CreditCardReducer(state: CreditCardState = initialState, action: CreditCardAction): CreditCardState {
  switch (action.type) {
    case SetInputsActionTypes.SET_NUMBER_FIELD:
    case SetInputsActionTypes.SET_STRING_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    case CommonActionTypes.CLEAR_STATE:
      return initialState
    case CommonActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    case SetDocumentTypesOptionsActionTypes.SET_DOCUMENT_TYPES_OPTIONS:
      return {
        ...state,
        documentTypeOptions: action.payload.documentTypesOptions
      }
    case CommonActionTypes.SET_ERRORS:
      return {
        ...state,
        errors: action.payload.errors
      }
    default:
      return state
  }
}

export default CreditCardReducer
