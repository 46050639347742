import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { SearchableParams, MovementListSortKey } from '../../../projectApi/Payments/Payment/list'
import {
  GET_MOVEMENTS_FAILURE,
  GET_MOVEMENTS_REQUEST,
  GET_MOVEMENTS_SUCCESS,
  MovementsTableAction,
  MovementsTableState,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS
} from '../types/MovementsTable'

export const DEFAULT_MOVEMENTS_SORT: { field: MovementListSortKey; direction: SortDirection } = {
  field: 'id',
  direction: SortDirection.DESC
}
export const DEFAULT_MOVEMENTS_CATEGORY_FILTERS = { paymentStatus: [], paymentType: [] }
export const DEFAULT_MOVEMENTS_SEARCH_FILTERS: { key: keyof SearchableParams; text: string }[] = [
  { key: 'id', text: '' },
  { key: 'email', text: '' },
  { key: 'mercadoPagoId', text: '' }
]

const initialState: MovementsTableState = {
  payments: [],
  total: 0,
  loading: false,
  sendingCSV: false,
  pagination: {
    total: 0,
    page: 1,
    pageSize: 20
  },
  error: '',
  searchFilters: DEFAULT_MOVEMENTS_SEARCH_FILTERS,
  categoryFilter: DEFAULT_MOVEMENTS_CATEGORY_FILTERS,
  sort: DEFAULT_MOVEMENTS_SORT
}

const MovementsTableReducer: Reducer<MovementsTableState, MovementsTableAction> = (
  state = initialState,
  action
): MovementsTableState => {
  switch (action.type) {
    case GET_MOVEMENTS_REQUEST:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        sort: action.payload.sort || initialState.sort,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        loading: true
      }
    case GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload.payments,
        total: action.payload.total,
        loading: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_MOVEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case SEND_CSV_REQUEST: {
      return {
        ...state,
        sendingCSV: true
      }
    }
    case SEND_CSV_SUCCESS:
    case SEND_CSV_FAILURE: {
      return {
        ...state,
        sendingCSV: false
      }
    }
    default:
      return state
  }
}

export default MovementsTableReducer
