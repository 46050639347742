import React, { useEffect } from 'react'
import BillingDiscountModal, { BillingDiscountModalProps } from '../components/BillingDiscountsModal'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import billingDiscountsModalActionCreator from '../actionCreators/billingDiscountsModal'
import { BillingsTableDateRangeKey } from '../types/newBillingTable'
import { DiscountType } from '../types/billingDiscountsModal'
import { Modal } from 'antd'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { useLocalization } from '../../../packages/localization'

const BillingDiscountModalContainer = () => {
  const dispatch = useDispatch()
  const { strings } = useLocalization()
  const {
    modalVisibility,
    creatingNewDiscount,
    deleteModalVisibility,
    newDiscountType,
    newDiscountValue,
    deleteDiscountId,
    userDiscounts,
    userPersonalInfo,
    discountReasons,
    selectedReason,
    selectedStartDate,
    selectedEndDate
  } = useSelector((state) => state.BillingV2.discountsModal)
  const { dateRangeFilters } = useSelector((state) => state.BillingV2.NewBillingTable)

  const {
    setModalVisibility,
    setDeleteModalVisibility,
    setNewDiscountType,
    setNewDiscountValue,
    setDiscountReason,
    setDiscountDate,
    getUserDiscounts,
    getUserPersonalInfo,
    getDiscountReasons,
    createDiscount,
    deleteDiscount,
    cleanStateFromCreate
  } = bindActionCreators(billingDiscountsModalActionCreator, dispatch)

  useEvents([Events.BillingDiscount.CREATED_NEW_DISCOUNT, Events.BillingDiscount.DELETED_DISCOUNT], () => {
    if (userPersonalInfo.data.userId) {
      const currentPeriod = dateRangeFilters.find((filter) => filter.key === BillingsTableDateRangeKey.PERIOD)
      getUserDiscounts({
        userId: userPersonalInfo.data.userId,
        startDate: currentPeriod?.startDate,
        endDate: currentPeriod?.endDate
      })
    }
  })

  useEvents(Events.BillingDiscount.CREATED_NEW_DISCOUNT, cleanStateFromCreate)

  useEffect(() => {
    if (userPersonalInfo.data.userId) {
      const currentPeriod = dateRangeFilters.find((filter) => filter.key === BillingsTableDateRangeKey.PERIOD)

      getUserDiscounts({
        userId: userPersonalInfo.data.userId,
        startDate: currentPeriod?.startDate,
        endDate: currentPeriod?.endDate
      })
      getUserPersonalInfo({ userId: userPersonalInfo.data.userId })
      getDiscountReasons()
    }
  }, [userPersonalInfo.data.userId])

  const formmatedToCents = (value: string) => {
    const parsedValue = parseFloat(value.replace(',', '.'))
    return Math.round(parsedValue * 100)
  }

  const handleCreateDiscount = () => {
    const isAmountType = newDiscountType === DiscountType.AMOUNT
    const isPercentageType = newDiscountType === DiscountType.PERCENTAGE
    if (!selectedStartDate || !selectedEndDate || !selectedReason || !newDiscountValue || !userPersonalInfo.data.userId)
      return

    createDiscount({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      userId: userPersonalInfo.data.userId,
      userDiscountReasonId: selectedReason.value,
      fixedAmountDiscountInCents: isAmountType ? formmatedToCents(newDiscountValue) : undefined,
      discountPercent: isPercentageType ? parseFloat(newDiscountValue.replace(',', '.')) : undefined
    })
  }

  const handleDeleteDiscount = () => {
    if (!deleteDiscountId) return
    deleteDiscount(deleteDiscountId)
    setDeleteModalVisibility(false)
  }

  const modalProps: BillingDiscountModalProps = {
    newDiscountType,
    newDiscountValue,
    userDiscounts,
    selectedReason,
    selectedStartDate,
    selectedEndDate,
    userPersonalInfo,
    discountReasons,
    modalVisibility,
    creatingNewDiscount,
    setModalVisibility,
    setDeleteModalVisibility,
    setNewDiscountType,
    setNewDiscountValue,
    setDiscountReason,
    setDiscountDate,
    handleCreateDiscount
  }

  return (
    <>
      <BillingDiscountModal {...modalProps} />
      <Modal
        title={strings.Billings.DiscountModal.deleteModal.title}
        width={350}
        visible={deleteModalVisibility}
        centered
        okText="Eliminar"
        cancelText="Cancelar"
        onCancel={() => setDeleteModalVisibility(false)}
        onOk={handleDeleteDiscount}>
        <span>{strings.Billings.DiscountModal.deleteModal.confirmation}</span>
      </Modal>
    </>
  )
}

export default BillingDiscountModalContainer
