import { API } from '../../../projectApi'
import { AddressDetail, Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  SettersDepositsModalTypes,
  FormFields,
  SetDepositAddress,
  SetDepositName,
  SetFormField,
  SetModalVisibility,
  CreateDepositRequest,
  CreateDepositTypes,
  CreateDepositSuccess,
  SetAddressError,
  DeleteDepositRequest,
  DeleteDepositTypes,
  DeleteDepositSuccess,
  SetDeleteModalVisibility,
  GetAddressDetailsRequest,
  GetAddressDetailsTypes,
  GetAddressDetailsSuccess,
  SetChangeAddress,
  EditDepositRequest,
  EditDepositTypes,
  EditDepositSuccess,
  SetSelectedDeposit
} from '../types/ABMDeposits'

const ABMDepositsActionsCreator = {
  createDeposit({
    depositName,
    depositCountryCode,
    addressDetails
  }: {
    depositName: string
    depositCountryCode: string
    addressDetails: Partial<AddressDetail>
  }): AppThunk {
    return async (dispatch) => {
      const request: CreateDepositRequest = {
        type: CreateDepositTypes.CREATE_DEPOSIT_REQUEST
      }

      const toastId = setToastLoading('Creando depósito, por favor espere...')

      dispatch(request)
      try {
        await API.TransactionHandler.Deposits.create({
          name: depositName,
          countryCode: depositCountryCode,
          addressDetail: addressDetails
        })
        const success: CreateDepositSuccess = {
          type: CreateDepositTypes.CREATE_DEPOSIT_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.StorageDeposits.CREATE_DEPOSIT)
        setToastSuccessUpdate(toastId, { render: 'Depósito creado correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, CreateDepositTypes.CREATE_DEPOSIT_FAILURE, dispatch) ??
            'Error al intentar crear el depósito.'
        })
      }
    }
  },

  deleteDeposit({ depositId }: { depositId: number }): AppThunk {
    return async (dispatch) => {
      const request: DeleteDepositRequest = {
        type: DeleteDepositTypes.DELETE_DEPOSIT_REQUEST
      }

      const toastId = setToastLoading('Eliminando depósito, por favor espere...')

      dispatch(request)
      try {
        await API.TransactionHandler.Deposits.Admin.delete({ depositId })

        const success: DeleteDepositSuccess = {
          type: DeleteDepositTypes.DELETE_DEPOSIT_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.StorageDeposits.DELETE_DEPOSIT)
        setToastSuccessUpdate(toastId, { render: 'El depósito se eliminó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, DeleteDepositTypes.DELETE_DEPOSIT_FAILURE, dispatch) ??
            'Error al intentar eliminar el depósito.'
        })
      }
    }
  },

  editDeposit({
    depositName,
    depositCountryCode,
    addressDetails,
    depositId,
    addressId
  }: {
    addressId?: number
    depositId?: number
    depositName: string
    depositCountryCode: string
    addressDetails: Partial<AddressDetail>
  }): AppThunk {
    return async (dispatch) => {
      const request: EditDepositRequest = {
        type: EditDepositTypes.EDIT_DEPOSIT_REQUEST
      }

      const toastId = setToastLoading('Editando depósito, por favor espere...')

      dispatch(request)
      try {
        await API.TransactionHandler.Deposits.update({
          depositId,
          addressId,
          name: depositName,
          countryCode: depositCountryCode,
          addressDetail: addressDetails
        })

        const success: EditDepositSuccess = {
          type: EditDepositTypes.EDIT_DEPOSIT_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.StorageDeposits.EDIT_DEPOSIT)
        setToastSuccessUpdate(toastId, { render: 'El depósito se editó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, EditDepositTypes.EDIT_DEPOSIT_FAILURE, dispatch) ??
            'Error al intentar editar el depósito.'
        })
      }
    }
  },

  getAddressDetials({ addressId }: { addressId: number }): AppThunk {
    return async (dispatch) => {
      const request: GetAddressDetailsRequest = {
        type: GetAddressDetailsTypes.GET_ADDRESS_DETAILS_REQUEST
      }

      const toastId = setToastLoading('Obteniendo datos del depósito, por favor espere...')

      dispatch(request)
      try {
        const address = await API.AccountManager.Addresses.getById({ addressId })

        const formatedAddressDetails = {
          addressId: address.id,
          addressStreet: address.address,
          addressNumber: address.number,
          addressFloor: address.floor,
          addressApartment: address.apartment,
          addressPostalCode: address.postalCode.Code,
          addressCity: address.city,
          addressProvince: address.province,
          completeAddress: address.original,
          addressComment: address.comment
        }

        const success: GetAddressDetailsSuccess = {
          type: GetAddressDetailsTypes.GET_ADDRESS_DETAILS_SUCCESS,
          payload: { addressDetails: formatedAddressDetails }
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Datos del depósito obtenidos correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, GetAddressDetailsTypes.GET_ADDRESS_DETAILS_FAILURE, dispatch) ??
            'Error al intentar obtener los datos del depósito.'
        })
      }
    }
  },

  setModalVisibility({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }): SetModalVisibility {
    return {
      type: SettersDepositsModalTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible, editMode }
    }
  },

  setDeleteModalVisibility(deleteModalVisibility: boolean): SetDeleteModalVisibility {
    return {
      type: SettersDepositsModalTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisibility }
    }
  },

  setFormField({ field, value }: { field: FormFields; value: string }): SetFormField {
    return {
      type: SettersDepositsModalTypes.SET_FORM_FIELD,
      payload: { field, value }
    }
  },

  setDepositName(depositName: string): SetDepositName {
    return {
      type: SettersDepositsModalTypes.SET_DEPOSIT_NAME,
      payload: { depositName }
    }
  },

  setAddress(selectedAddress: Partial<AddressDetail> & { country?: string }): SetDepositAddress {
    return {
      type: SettersDepositsModalTypes.SET_DEPOSIT_ADDRESS,
      payload: { selectedAddress }
    }
  },

  setAddressError(errorMessage: string): SetAddressError {
    return {
      type: SettersDepositsModalTypes.SET_ADDRESS_ERROR,
      payload: { errorMessage }
    }
  },

  changeAddress(changeAddress: boolean): SetChangeAddress {
    return {
      type: SettersDepositsModalTypes.SET_CHANGE_ADDRESS,
      payload: { changeAddress }
    }
  },

  setSelectedDeposit(selectedDeposit: Deposit): SetSelectedDeposit {
    return {
      type: SettersDepositsModalTypes.SET_SELECTED_DEPOSIT,
      payload: { selectedDeposit }
    }
  }
}

export default ABMDepositsActionsCreator
