/* eslint-disable camelcase */

import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { APIRole } from '../Users/common'

interface APIResponse {
  description: {
    quantity: number
    roles: APIRole[]
  }
}

export type Role = CamelCasedPropertiesDeep<APIRole>

export function getAll(): Promise<Role[]> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/roles`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.description.roles || response.data.description.roles.length === 0)
        throw new Error('Respuesta incorrecta al obtener los roles')

      return camelcaseKeys(response.data.description.roles, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
