import React, { useMemo, useState } from 'react'
import { Button, InputNumber, Modal } from 'antd'
import commonActions from '../actions/common'
import userModalActions from '../actions/users'
import { UserState } from '../reducers/users'
import moment from 'moment'
import { ObjectsState } from '../reducers/objects'
import styles from './FindUser.module.scss'
import { DEPOSITED } from '../../Objects/constants/objectStatus'
import { calculateDaysAgo } from '../utils/calculateDaysAgo'
import { formatMoney } from '../../../utils/formatNumber'
import ExclamationIcon from './icons/ExclamationIcon'
import { useHistory } from 'react-router'

type FindUserProps = {
  commonActions: typeof commonActions
  userModalActions: typeof userModalActions
  userModalState: UserState
  objectsState: ObjectsState
  openReasonsModal: ({ modalOpen }: { modalOpen: boolean }) => void
}

const FindUser = (props: FindUserProps) => {
  const { commonActions, userModalActions, userModalState, objectsState, openReasonsModal } = props
  const { lastDeposit, user, loadingUser, open, userColppyInfo } = userModalState
  const history = useHistory()

  const [userID, setUserID] = useState<number | undefined>()

  const existsDebts: boolean = userColppyInfo !== null && userColppyInfo.saldoPagar

  const depositObjects = useMemo(
    () => objectsState.objects.filter((object) => object.ObjectStatus.ID === DEPOSITED.numberValue).length,
    [objectsState]
  )

  const onSearchButtonClick = () => {
    if (typeof userID === 'number') {
      userModalActions.getUser(userID)
      userModalActions.getInfoAboutUserHasDebts(userID)
    }
  }

  const onClose = () => {
    commonActions.finish()
    userModalActions.toggleUserModalOpen(false)
    setUserID(undefined)
  }

  const onOkClick = () => {
    if (existsDebts) {
      history.push(`/billings_payments_list?id=${userID}`)
      return
    }
    userModalActions.toggleUserModalOpen(false)
    openReasonsModal({ modalOpen: true })
  }

  return (
    <Modal
      width={697}
      title="Seleccioná un usuario"
      visible={open}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={onClose}
      okButtonProps={{
        loading: loadingUser,
        disabled: loadingUser || !user,
        className: styles.modalButtonOk
      }}
      okText={existsDebts ? 'Ver deudas' : 'Continuar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p className={styles.description}>Ingrese el ID del usuario al que quiere realizar una devolución:</p>
      <div className={styles.userIdContainer}>
        <div>
          <InputNumber
            className={styles.inputNumber}
            min={1}
            type="number"
            value={userID}
            autoFocus
            placeholder="ID Cliente"
            onPressEnter={onSearchButtonClick}
            onChange={(value) => setUserID(value)}
          />
        </div>
        <Button
          className={styles.searchButton}
          type="primary"
          onClick={onSearchButtonClick}
          disabled={!userID}
          loading={loadingUser}>
          Buscar
        </Button>
      </div>
      {user && !loadingUser && (
        <div className={styles.userDetailsContainer}>
          <h3 className={styles.detailsTitle}>Detalles del usuario</h3>
          <p className={styles.userDetail}>Nombre: {user.Name}</p>
          <p className={styles.userDetail}>Apellido/s: {user.LastName}</p>
          <p className={styles.userDetail}>
            Documento: {user.IdentificationType.Type} {user.DNI}
          </p>
          <p className={styles.userDetail}>Objetos guardados: {depositObjects}</p>
          {lastDeposit && (
            <p className={styles.userDetail}>
              {`Último ingreso: ${moment(lastDeposit.datetime).format('DD/MM/YY HH:mm')} - ${calculateDaysAgo(
                lastDeposit.datetime
              )}`}
            </p>
          )}

          {userColppyInfo !== null && userColppyInfo.saldoPagar && (
            <div className={styles.errorContainer}>
              <ExclamationIcon />
              <div>
                <p>{`El cliente tiene una deuda por ${formatMoney(
                  userColppyInfo.saldo
                )} Una vez realizado el pago podrás continuar con el proceso de devolución`}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
}

export default FindUser
