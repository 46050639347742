import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'
import { DepositStatus } from '../list'
import { formatDates } from '../../../utils'

export type DepositsValidationMetricsKeys = 'validated_quantity' | 'deposited_quantity'

interface APIResponse {
  validated_quantity: number
  deposited_quantity: number
}

type Response = CamelCasedPropertiesDeep<APIResponse>
export type QuantityDepositsValidationMetrics = CamelCasedPropertiesDeep<APIResponse>

export interface MetricsParams {
  createdAtFrom?: Moment
  createdAtTo?: Moment
  datetimeFrom?: Moment
  datetimeTo?: Moment
  statuses?: DepositStatus[]
}

export function metrics(params: MetricsParams): Promise<Response> {
  const statusParam = { statuses: params.statuses?.join(',') }
  const formattedParams = {
    ...statusParam,
    ...formatDates({
      dates: {
        date_from: params.createdAtFrom,
        date_to: params.createdAtTo
      }
    })
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/deposits-operations/validated-metrics`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
