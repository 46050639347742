import { Reducer } from 'redux'
import {
  AgentModalAction,
  AgentModalState,
  CREATE_AGENT_REQUEST,
  CREATE_AGENT_SUCCESS,
  GET_AGENT_BY_EMAIL_FAILURE,
  GET_AGENT_BY_EMAIL_REQUEST,
  GET_AGENT_BY_EMAIL_SUCCESS,
  GET_USER_BY_EMAIL_FAILURE,
  GET_USER_BY_EMAIL_REQUEST,
  GET_USER_BY_EMAIL_SUCCESS,
  OPEN_MODAL,
  SET_AGENT_ROLE_ID,
  SET_ERROR_MODAL_AGENT
} from '../types/agentModal'

const initalState: AgentModalState = {
  openModal: false,
  user: null,
  agent: null,
  loadingUser: false,
  loadingCreateAgent: false,
  error: '',
  firstName: '',
  lastName: '',
  email: '',
  userGuid: '',
  userId: 0,
  roleId: 0,
  total: 0
}

const AgentModalReducer: Reducer<AgentModalState, AgentModalAction> = (
  state = initalState,
  action
): AgentModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      if (!action.payload.openModal) return initalState

      return {
        ...state,
        openModal: action.payload.openModal
      }
    case SET_ERROR_MODAL_AGENT:
      return {
        ...state,
        error: action.payload.errorMessage
      }
    case GET_USER_BY_EMAIL_REQUEST:
      return {
        ...state,
        loadingUser: true
      }
    case GET_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        user: action.payload.user,
        error: action.payload.error
      }
    case GET_USER_BY_EMAIL_FAILURE:
      return {
        ...state,
        loadingUser: false,
        error: action.payload.error
      }
    case CREATE_AGENT_REQUEST:
      return {
        ...state,
        loadingCreateAgent: true
      }
    case CREATE_AGENT_SUCCESS:
      return {
        ...state,
        loadingCreateAgent: false,
        openModal: false
      }
    case SET_AGENT_ROLE_ID:
      return {
        ...state,
        loadingCreateAgent: false,
        roleId: action.payload.roleId
      }
    case GET_AGENT_BY_EMAIL_REQUEST:
      return {
        ...state,
        loadingCreateAgent: true
      }
    case GET_AGENT_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loadingCreateAgent: false,
        agent: action.payload.agent,
        error: action.payload.error
      }
    case GET_AGENT_BY_EMAIL_FAILURE:
      return {
        ...state,
        loadingCreateAgent: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default AgentModalReducer
