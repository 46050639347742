import types from '../types/cost'
import commonTypes from '../types/common'

const initialState = {
  discountCode: '',
  valid: false,
  transportDiscount: 0,
  removalDiscount: 0,
  loading: false,
  applyDiscount: false,
  error: ''
}

const root = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.FINISH:
      return initialState
    case types.SET_DISCOUNT_CODE: {
      return { ...state, discountCode: action.payload.discountCode, error: '' }
    }
    case types.SET_APPLY_DISCOUNT: {
      return {
        ...state,
        applyDiscount: action.payload.applyDiscount,
        error: ''
      }
    }
    case types.FETCH_DISCOUNT_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.FETCH_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        valid: action.payload.valid,
        transportDiscount: action.payload.transport,
        removalDiscount: action.payload.removal,
        error: action.payload.valid ? '' : 'Código inválido'
      }
    case types.FETCH_DISCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
