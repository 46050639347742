import React, { useEffect, useMemo } from 'react'
import Component from '../components/edit'
import { bindActionCreators } from 'redux'
import actionCreators from '../actions/edit'
import { useDispatch, useSelector } from 'react-redux'
import { SelectedAttribute } from '../types/edit'
import { CountryIdCode } from '@/components/CountrySelector/constants/constants'

const Container = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(actionCreators, dispatch)

  const editState = useSelector((state) => state.Objects.edit)
  const userProfile = useSelector((state) => state.UserLogged.userProfile)

  const { newObject, productAttributes } = editState

  useEffect(() => {
    if (editState.open && editState.selectedProduct && editState.currentObject) {
      actions.getProducts(
        editState.selectedProduct.description,
        editState.serviceTypeId,
        editState.currentObject?.owner.countryId || CountryIdCode.ARGENTINA
      )
      actions.getProductAttributes(editState.selectedProduct.id)
    }
  }, [editState.open, editState.selectedProduct])
  useEffect(() => {
    if (editState.open && editState.objectSituations.length === 0) {
      actions.getObjectSituations()
    }
  }, [editState.open])

  const selectedAttributes = useMemo(
    () =>
      newObject.attributes?.reduce<SelectedAttribute[]>((acc, attribute, index) => {
        if (attribute.deleted) {
          return acc
        }
        return [...acc, { ...attribute, realIndex: index }]
      }, []) || [],
    [newObject.attributes]
  )

  const remainingAttributes = productAttributes.filter(
    (attribute) => !selectedAttributes.some((newAttribute) => newAttribute.attributeId === attribute.id)
  )

  const onSetAttributeType = (index: number, attributeId: number) => {
    const shouldAddAttributeTemplate = selectedAttributes.length !== productAttributes.length

    actions.setAttributeType(index, attributeId, shouldAddAttributeTemplate)
  }

  const onDeleteAttribute = (index: number) => {
    const shouldAddAttributeTemplate = !selectedAttributes.some((attribute) => !attribute.attributeId)
    actions.deleteAttribute(index, shouldAddAttributeTemplate)
  }

  const props = {
    actions,
    editState,
    userProfile,
    onSetAttributeType,
    onDeleteAttribute,
    remainingAttributes,
    selectedAttributes
  }

  return <Component {...props} />
}

export default Container
