import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

interface APIResponse {
  convert: number
  expired: number
  current: number
}

export interface MetricsParams {
  peopleId: number
}

export type ProposalMetrics = CamelCasedPropertiesDeep<APIResponse>

export function metrics(camelCaseParams: MetricsParams): Promise<ProposalMetrics> {
  const params = snakecaseKeys({ ...camelCaseParams })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/proposal`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
