import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_CATEGORIES } from '../../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { LocationsMetrics } from '../../../../sections/Corporative/Locations/types/locationsTable'
import { LocationInfo } from '../../../../sections/Locations/types/locationsTable'

export interface APIResponse {
  locationInfo: LocationsMetrics | null
  total: number
}

export type Metrics = CamelCasedPropertiesDeep<LocationsMetrics>

interface Response {
  locationsMetrics: LocationInfo
}

export interface LocationListParams {
  depositId?: number
  ownerId?: number
  id?: string
  limit: number
  offset: number
  name?: string
}

export function metrics(params: LocationListParams): Promise<Response> {
  const formattedParams = snakecaseKeys({
    ...params,
    depositName: params.name
  })

  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/location-info`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const locationsMetrics = camelcaseKeys(response.data.locationInfo || {}) as Metrics
      return { locationsMetrics }
    })
    .catch((error) => {
      throw error
    })
}
