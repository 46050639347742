import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  GET_OBJECTS_TABLE_FAILURE,
  GET_OBJECTS_TABLE_REQUEST,
  GET_OBJECTS_TABLE_SUCCESS,
  GetObjectsTableRequest,
  GetObjectsTableSuccess,
  PhotographObjectsTableSearchFilter,
  PhotographObjectsTableSort
} from '../types/PhotographObjectsTable'

const PhotographObjectsTableActionCreators = {
  getObjects:
    ({
      pagination,
      silentLoading,
      sort,
      searchFilters
    }: {
      pagination: Pagination
      silentLoading?: boolean
      sort?: PhotographObjectsTableSort
      searchFilters?: PhotographObjectsTableSearchFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetObjectsTableRequest = {
        type: GET_OBJECTS_TABLE_REQUEST,
        payload: {
          newPage: pagination.page,
          pageSize: pagination.pageSize,
          silentLoading,
          sort,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { objects, total } = await API.ObjectAdministration.Object.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          withDeletedPhotos: true,
          order: sort?.direction,
          column: sort?.field,
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetObjectsTableSuccess = {
          type: GET_OBJECTS_TABLE_SUCCESS,
          payload: {
            objects,
            total
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_OBJECTS_TABLE_FAILURE, dispatch)
      }
    }
}

export default PhotographObjectsTableActionCreators
