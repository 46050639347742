import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import {
  CLEAR_STATE,
  CLOSE_DELETE_MODAL,
  ClearState,
  CloseDeleteModal,
  DELETE_ATTRIBUTE_FAILURE,
  DELETE_ATTRIBUTE_REQUEST,
  DELETE_ATTRIBUTE_SUCCESS,
  DeleteAttributeRequest,
  DeleteAttributeSuccess,
  OPEN_DELETE_MODAL,
  OpenDeleteModal
} from '../types/DeleteAttribute'
import { Events } from '../../../utils/eventEmitter/events'
import eventEmitter from '../../../utils/eventEmitter'

const actions = {
  deleteAttribute:
    (id: number): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const toast = setToastLoading('Eliminando atributo, por favor espere...')
      const request: DeleteAttributeRequest = { type: DELETE_ATTRIBUTE_REQUEST }
      dispatch(request)

      try {
        await API.ObjectAdministration.Attribute.delete(id)
        const success: DeleteAttributeSuccess = {
          type: DELETE_ATTRIBUTE_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Atributo eliminado exitosamente' })
        eventEmitter.emit(Events.Attributes.ATTRIBUTE_DELETED)
      } catch (err) {
        const message = handleErrorsWithAction(err, DELETE_ATTRIBUTE_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: message })
      }
    },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  openDeleteModal: (id: number): OpenDeleteModal => ({ type: OPEN_DELETE_MODAL, payload: { id } }),
  closeDeleteModal: (): CloseDeleteModal => ({ type: CLOSE_DELETE_MODAL })
}

export default actions
