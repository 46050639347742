import React from 'react'

function UserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" fill="none" viewBox="0 0 20 19">
      <path
        stroke="#777692"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 18c2.336-2.477 5.507-4 9-4 3.493 0 6.664 1.523 9 4M14.5 5.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"></path>
    </svg>
  )
}

export default UserIcon
