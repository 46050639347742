import { Col, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import InputGroup from '../../../components/InputGroup'
import { User } from '../../../projectApi/AccountManager/Users/common'
import Select from '../../../components/Select'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import { AgentCreateData, ERROR_EMAIL_TYPES } from '../types/agentModal'
import { getOptionValue } from '../common'
import validateEmail from '../../../utils/validateEmail'
import { BaseOption } from '../../../components/Select/Select'

export type AgentsModalProps = {
  isOpenModal: boolean
  userData: User | null
  agentRoleId: number
  loadingCreate: boolean
  error: string
  onClose: () => void
  setErrorModalAgent: (errorMessage: string) => void
  handleSearchEmail: (email: string) => void
  handleAgentData: ({ firstName, lastName, email, userGuid, userId, roleId }: AgentCreateData) => void
  setAgentRoleId: (roleId: number) => void
}

const AGENT_ROLE_OPTIONS: Option[] = [
  {
    label: 'Administrador de sistemas',
    value: 1
  },
  {
    label: 'Administrador',
    value: 2
  },
  {
    label: 'Vendedor',
    value: 3
  }
]

const { Search } = Input

type Option = BaseOption<number>
const AgentModal: React.FC<AgentsModalProps> = ({
  isOpenModal,
  onClose,
  handleSearchEmail,
  setErrorModalAgent,
  userData,
  handleAgentData,
  setAgentRoleId,
  agentRoleId,
  loadingCreate,
  error
}) => {
  const userCountry = useCountryInfo(userData?.country)

  const [roleValue, setRoleValue] = useState<Option | null>(null)
  const [isRoleEmpty, setIsRoleEmpty] = useState(true)
  const [disabledField, setDisabledField] = useState(false)

  const handleValidateEmail = (email: string) => {
    const currentEmail = email.split('@')
    if (currentEmail[1].toUpperCase() === 'SPACEGURU.COM') {
      handleSearchEmail(email)
    } else {
      setErrorModalAgent(ERROR_EMAIL_TYPES.INVALID)
    }
  }

  const handleDisabled = () => {
    setDisabledField(error !== ERROR_EMAIL_TYPES.ACCEPTED)
  }

  useEffect(() => {
    handleDisabled()
  }, [error])

  return (
    <Modal
      width={'60%'}
      centered
      destroyOnClose
      closable={true}
      okButtonProps={{
        disabled: isRoleEmpty || disabledField,
        loading: loadingCreate
      }}
      visible={isOpenModal}
      onCancel={onClose}
      onOk={() =>
        userData
          ? handleAgentData({
              firstName: userData.name,
              lastName: userData.lastName,
              email: userData.email,
              userGuid: userData.guid,
              userId: userData.realId,
              roleId: agentRoleId
            })
          : null
      }
      okText="Crear Agente"
      title={'Crear Agente'}
      style={{ maxWidth: 1000 }}>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col sm={12} xs={24}>
          <Search
            placeholder="Ingresar email"
            enterButton="Buscar"
            size="default"
            onSearch={(email) => {
              if (validateEmail(email)) {
                handleValidateEmail(email)
              }
            }}
            style={{ marginBottom: '20px', maxWidth: '95%', marginLeft: '5px' }}
          />
          <InputGroup title="Datos del usuario">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Select
                  placeholder={userData ? userCountry.label : 'país'}
                  disabled={true}
                  onSelect={() => {}}
                  options={[]}
                />
              </Col>
              <Col span={12}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.name || ''}
                  disabled={true}
                  label={'Nombre'}
                />
              </Col>
              <Col span={12}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.lastName || ''}
                  disabled={true}
                  label={'Apellido'}
                />
              </Col>
              <Col span={8}>
                <p style={{ marginBottom: '5', color: 'grey', textTransform: 'uppercase', fontSize: '10px' }}>
                  Tipo de doc.*
                </p>
                <Select
                  placeholder={userData ? userData.identificationType.type : '-'}
                  disabled={true}
                  onSelect={() => {}}
                  options={[]}
                />
              </Col>
              <Col span={16}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.dni || ''}
                  disabled={true}
                  label={`Numero de  ${userData ? userData.identificationType.type : '-'}`}
                  inputStyles={{ height: '35px', marginTop: '5px' }}
                />
              </Col>
              <Col span={24}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.phone || ''}
                  disabled={true}
                  label={'Celular'}
                />
              </Col>
            </Row>
          </InputGroup>
        </Col>
        <Col sm={12} xs={24} style={{ marginTop: '50px' }}>
          <InputGroup title="Direccion">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.addresses[0]?.address || ''}
                  disabled={true}
                  label={'Direccion'}
                />
              </Col>
              <Col span={12}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.addresses[0]?.postalCode.postalCode || ''}
                  disabled={true}
                  label={'Cod. Postal'}
                />
              </Col>
              <Col span={12}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.addresses[0]?.floor || ''}
                  disabled={true}
                  label={'Piso'}
                />
              </Col>
              <Col span={24}>
                <InputFieldWithError
                  onChange={() => {}}
                  value={userData?.addresses[0]?.province || ''}
                  disabled={true}
                  label={'Provincia'}
                />
              </Col>
            </Row>
          </InputGroup>
          <InputGroup title="Rol de Agente">
            <Select
              disabled={false}
              selected={roleValue}
              placeholder="Elegir rol de agente"
              options={AGENT_ROLE_OPTIONS}
              onSelect={(value) => {
                setRoleValue(value)
                setIsRoleEmpty(false)
                const roleValue = getOptionValue(value)
                setAgentRoleId(Number(roleValue))
              }}
            />
          </InputGroup>
        </Col>
      </Row>

      <p style={{ fontSize: '12px', marginTop: '5px', color: 'red', textAlign: 'center' }}>{error}</p>
    </Modal>
  )
}

export default AgentModal
