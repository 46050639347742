import { Moment } from 'moment'
import { API } from '../../../projectApi'
import {
  SettersBillingDiscountsModalTypes,
  GetUserDiscountsRequest,
  GetUserDiscountsSuccess,
  GetUserDiscountsTypes,
  GetUserPersonalInfoRequest,
  GetUserPersonalInfoSuccess,
  GetUserPersonalInfoTypes,
  SetModalVisibility,
  SetNewDiscountType,
  DiscountType,
  SetNewDiscountValue,
  GetDiscountReasonsRequest,
  GetDiscountReasonsTypes,
  GetDiscountReasonsSuccess,
  ReasonOption,
  SetDiscountReason,
  CreateNewDiscountRequest,
  CreateNewDiscountTypes,
  CreateNewDiscountSuccess,
  SetDeleteModalVisibility,
  DeleteDiscountRequest,
  DeleteDiscountTypes,
  DeleteDiscountSuccess,
  SetCleanStateFromCreate
} from '../types/billingDiscountsModal'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  sendToastNotificationError,
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate
} from '../../../utils/notifications'
import { CreateParams } from '../../../projectApi/TransactionHandler/UserDiscounts/create'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const billingDiscountsModalActionCreator = {
  setModalVisibility: (isVisibile: boolean, userId?: number): SetModalVisibility => ({
    type: SettersBillingDiscountsModalTypes.SET_MODAL_VISIBILITY,
    payload: { modalVisibility: isVisibile, userId }
  }),

  setDeleteModalVisibility: (deleteModalVisibility: boolean, deleteDiscountId?: number): SetDeleteModalVisibility => ({
    type: SettersBillingDiscountsModalTypes.SET_DELETE_MODAL_VISIBILITY,
    payload: { deleteModalVisibility, deleteDiscountId }
  }),

  setNewDiscountType: (newDiscountType: DiscountType): SetNewDiscountType => ({
    type: SettersBillingDiscountsModalTypes.SET_NEW_DISCOUNT_TYPE,
    payload: { newDiscountType }
  }),

  setNewDiscountValue: (newDiscountValue?: string): SetNewDiscountValue => ({
    type: SettersBillingDiscountsModalTypes.SET_NEW_DISCOUNT_VALUE,
    payload: { newDiscountValue }
  }),

  setDiscountReason: (selectedReason: ReasonOption): SetDiscountReason => ({
    type: SettersBillingDiscountsModalTypes.SET_DISCOUNT_REASON,
    payload: { selectedReason }
  }),

  setDiscountDate: ({
    selectedStartDate,
    selectedEndDate
  }: {
    selectedStartDate?: Moment
    selectedEndDate?: Moment
  }) => ({
    type: SettersBillingDiscountsModalTypes.SET_DISCOUNT_DATE,
    payload: { selectedStartDate, selectedEndDate }
  }),

  getUserDiscounts:
    ({
      userId,
      startDate,
      endDate
    }: {
      userId: number
      startDate?: Moment | null
      endDate?: Moment | null
    }): AppThunk =>
    async (dispatch) => {
      if (!startDate || !endDate) return sendToastNotificationError('Error al obtener los descuentos del usuario')
      const request: GetUserDiscountsRequest = {
        type: GetUserDiscountsTypes.GET_USER_DISCOUNTS_REQUEST
      }
      dispatch(request)

      try {
        const { userDiscounts } = await API.TransactionHandler.UserDiscounts.list({
          userId,
          startDate,
          endDate
        })
        const success: GetUserDiscountsSuccess = {
          type: GetUserDiscountsTypes.GET_USER_DISCOUNTS_SUCCESS,
          payload: {
            userDiscounts
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GetUserDiscountsTypes.GET_USER_DISCOUNTS_FAILURE, dispatch)
        sendToastNotificationError('Error al obtener los descuentos del usuario')
      }
    },
  getUserPersonalInfo:
    ({ userId }: { userId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetUserPersonalInfoRequest = {
        type: GetUserPersonalInfoTypes.GET_USER_INFO_REQUEST
      }
      dispatch(request)

      try {
        const { users } = await API.AccountManager.Users.list({ id: userId })
        const user = users[0]

        const success: GetUserPersonalInfoSuccess = {
          type: GetUserPersonalInfoTypes.GET_USER_INFO_SUCCESS,
          payload: {
            userPersonalInfo: {
              userId,
              name: user.name,
              lastName: user.lastName,
              email: user.email
            }
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GetUserPersonalInfoTypes.GET_USER_INFO_FAILURE, dispatch)
        sendToastNotificationError('Error al obtener la información del usuario')
      }
    },

  getDiscountReasons: (): AppThunk => async (dispatch) => {
    const request: GetDiscountReasonsRequest = {
      type: GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_REQUEST
    }
    dispatch(request)

    try {
      const { reasons: discountReasons } = await API.TransactionHandler.UserDiscounts.reasons()
      const success: GetDiscountReasonsSuccess = {
        type: GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_SUCCESS,
        payload: {
          discountReasons
        }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_FAILURE, dispatch)
      sendToastNotificationError('Error al obtener las razones de descuento')
    }
  },

  createDiscount:
    (params: CreateParams): AppThunk =>
    async (dispatch) => {
      const request: CreateNewDiscountRequest = {
        type: CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_REQUEST
      }

      const toast = setToastLoading('Creando descuento, por favor espere...')

      dispatch(request)
      try {
        await API.TransactionHandler.UserDiscounts.create(params)
        const success: CreateNewDiscountSuccess = {
          type: CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Descuento creado con éxito' })
        Emitter.emit(Events.BillingDiscount.CREATED_NEW_DISCOUNT)
      } catch (err) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(err, CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_FAILURE, dispatch)
        })
      }
    },

  deleteDiscount:
    (discountId: number): AppThunk =>
    async (dispatch) => {
      const request: DeleteDiscountRequest = {
        type: DeleteDiscountTypes.DELETE_DISCOUNT_REQUEST
      }

      const toast = setToastLoading('Eliminando descuento, por favor espere...')
      dispatch(request)

      try {
        await API.TransactionHandler.UserDiscounts.delete({ discountId })
        const success: DeleteDiscountSuccess = {
          type: DeleteDiscountTypes.DELETE_DISCOUNT_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Descuento eliminado con éxito' })
        Emitter.emit(Events.BillingDiscount.DELETED_DISCOUNT)
      } catch (error) {
        handleErrorsWithAction(error, DeleteDiscountTypes.DELETE_DISCOUNT_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'Error al eliminar el descuento' })
      }
    },

  cleanStateFromCreate: (): SetCleanStateFromCreate => ({
    type: SettersBillingDiscountsModalTypes.SET_CLEAN_STATE_FROM_CREATE
  })
}

export default billingDiscountsModalActionCreator
