import types from '../types/RegisterObjects'
import { URL_BASE_OBJECTS, URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { apiPrivate } from '../../../api'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { TransactionStatusId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { API } from '../../../projectApi'

const generateNewCode = (codeTMP) => {
  const er = /(\d)*$/
  const codeNumbers = er.exec(codeTMP)[0]
  const codeAdd = parseInt(codeNumbers || 0) + 1
  const stringAdd = codeAdd.toString().padStart(codeNumbers.length, '0')
  return codeTMP.substring(0, codeTMP.length - codeNumbers.length) + stringAdd
}

const validateDimension = (dimension) => dimension <= 0 || isNaN(dimension)

let searchTimer = null
let searchTimerCode = null
let missingObjectModalActionsSearchTimer = null
let codeTMPSearchTimer = null

const missingObjectModalActions = {
  setSearchClientID(id) {
    return { type: types.SET_SEARCH_CLIENT_BY_ID, payload: { id } }
  },

  getPickedUpTransactions(id) {
    return (dispatch, getState) => {
      const fn = () => {
        const lastSearchID = new Date().getMilliseconds()
        dispatch({ type: types.FETCH_TRANSACTIONS, payload: { lastSearchID } })

        return apiPrivate
          .get(URL_BASE_TRANSACTIONS + `/transactions?status=${TransactionStatusId.LEVANTADA}`, {
            params: { client_id: id }
          })
          .then((response) => {
            if (lastSearchID === getState().RegisterObjects.missingObjectModal.lastSearchID) {
              const { transactions } = response.data.description
              dispatch({
                type: types.FETCH_TRANSACTIONS_SUCCESS,
                payload: { transactions }
              })
              if (transactions.length > 0) dispatch(missingObjectModalActions.setTransactionGUID(transactions[0].ID))
            }
          })
          .catch((error) => {
            handleErrorsWithAction(error, types.FETCH_TRANSACTIONS_FAIL, dispatch)
          })
      }
      if (missingObjectModalActionsSearchTimer) clearTimeout(missingObjectModalActionsSearchTimer)
      missingObjectModalActionsSearchTimer = setTimeout(fn, 500)
    }
  },

  getCodeTMP() {
    return (dispatch, getState) => {
      const fn = () => {
        const lastSearchID = new Date().getMilliseconds()
        dispatch({ type: types.FETCH_CODE_TEMP_REQUEST, payload: { lastSearchID } })

        return apiPrivate
          .get(URL_BASE_OBJECTS + '/identification-codes/search?code=TMP', {
            params: { limit: 1, offset: 0, sort: 'number', order: 'desc' }
          })
          .then((response) => {
            if (lastSearchID === getState().RegisterObjects.missingObjectModal.lastCodeTMPSearchID) {
              const codeTMP = response.data.results[0].code
              dispatch({
                type: types.FETCH_CODE_TEMP_SUCCESS,
                payload: { codeTMP, newValue: generateNewCode(codeTMP) }
              })
            }
          })
          .catch((error) => {
            handleErrorsWithAction(error, types.FETCH_CODE_TEMP_FAILURE, dispatch)
          })
      }
      if (codeTMPSearchTimer) clearTimeout(codeTMPSearchTimer)
      codeTMPSearchTimer = setTimeout(fn, 500)
    }
  },

  addMissingObject(transactionGUID, code) {
    const toastId = setToastLoading('Registrando objeto, por favor espere...')
    return (dispatch) => {
      dispatch({ type: types.ADD_MISSING_OBJECT })

      const formData = new FormData()
      formData.append('code', code)

      return apiPrivate
        .post(URL_BASE_TRANSACTIONS + '/transaction/' + transactionGUID + '/objectCodes/missing', formData)
        .then((response) => {
          const object = response.data.description
          setToastSuccessUpdate(toastId, {
            render: 'Objeto agregado correctamente'
          })
          dispatch({
            type: types.ADD_MISSING_OBJECT_SUCCESS,
            payload: { object }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.ADD_MISSING_OBJECT_FAIL, dispatch)
          setToastErrorUpdate(toastId, {
            render: 'Error al registrar el objeto'
          })
        })
    }
  },

  setMissingObjectCode(code) {
    return { type: types.SET_CODE, payload: { code } }
  },

  setTransactionGUID(transactionGUID) {
    return { type: types.SET_TRANSACTION_GUID, payload: { transactionGUID } }
  },

  setMissingObjectModalOpen() {
    return { type: types.SET_MISSING_OBJECT_MODAL_OPEN }
  },

  setMissingObjectModalClose() {
    return { type: types.SET_MISSING_OBJECT_MODAL_CLOSE }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  },

  getObjectsToRecordFromStore() {
    return (dispatch, getState) => {
      const params = getState().RegisterObjects.table.params
      dispatch(missingObjectModalActions.getObjectsToRecord(params))
    }
  },

  cleanFilters() {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({ type: types.CLEAN_FILTERS })
        resolve()
      })
    }
  },

  getProductsByDescription(description, serviceTypeId) {
    return (dispatch, getState) => {
      if (searchTimer) clearTimeout(searchTimer)
      searchTimer = setTimeout(() => {
        const lastSearchID = new Date().getMilliseconds()
        dispatch({
          type: types.FETCH_PRODUCTS,
          payload: { lastSearchID }
        })

        return API.CategoryCreation.Product.listAdmin({ name: description, limit: 50, offset: 0, serviceTypeId })
          .then((response) => {
            if (lastSearchID === getState().RegisterObjects.scanModal.lastSearchIDProduct) {
              const { products } = response

              dispatch({
                type: types.FETCH_PRODUCTS_SUCCESS,
                payload: { products }
              })
            }
          })
          .catch((error) => {
            handleErrorsWithAction(error, types.FETCH_PRODUCTS_FAIL, dispatch)
          })
      }, 500)
    }
  },

  getObjectsToRecord(params) {
    return (dispatch) => {
      dispatch({ type: types.FETCH_OBJECTS })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + '/recorder/objects', { params })
        .then((response) => {
          const { objects, quantity } = response.data.description

          dispatch({
            type: types.FETCH_OBJECTS_SUCCESS,
            payload: { objects, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_OBJECTS_FAIL, dispatch)
        })
    }
  },

  getObjectsToRecordByCode(code) {
    return (dispatch, getState) => {
      if (searchTimerCode) clearTimeout(searchTimerCode)
      searchTimerCode = setTimeout(() => {
        const lastSearchID = new Date().getMilliseconds()

        dispatch({
          type: types.FETCH_OBJECTS_BY_CODE,
          payload: { lastSearchIDCode: lastSearchID }
        })
        return apiPrivate
          .get(URL_BASE_TRANSACTIONS + '/recorder/objects', {
            params: { code }
          })
          .then((response) => {
            if (lastSearchID === getState().RegisterObjects.scanModal.lastSearchIDCode) {
              const { objects } = response.data.description

              if (objects.length >= 1) {
                dispatch({
                  type: types.FETCH_OBJECTS_BY_CODE_SUCCESS,
                  payload: { object: objects[0].real_id }
                })
              } else {
                dispatch({
                  type: types.FETCH_OBJECTS_BY_CODE_FAIL,
                  payload: { error: 'Código de barras incorrecto' }
                })
              }
            }
          })
          .catch((error) => {
            handleErrorsWithAction(error, types.FETCH_OBJECTS_BY_CODE_FAIL, dispatch)
          })
      }, 500)
    }
  },

  recordObject(object, product, closeModal, editDimensions = false) {
    return (dispatch, getState) => {
      dispatch({ type: types.RECORD_OBJECT })
      const toast = setToastLoading('Guardando objeto, por favor espere')
      const formData = new FormData()
      formData.append('product', product)

      if (editDimensions) {
        const modal = getState().RegisterObjects.scanModal
        const { height, width, length, weight } = modal
        formData.append('object_height_cm', height)
        formData.append('object_length_cm', length)
        formData.append('object_width_cm', width)
        formData.append('object_weight_gr', String(weight * 1000))
      }

      return apiPrivate
        .put(URL_BASE_OBJECTS + '/object/' + object + '/product', formData)
        .then((response) => {
          dispatch({
            type: types.RECORD_OBJECT_SUCCESS,
            payload: { id: object }
          })
          setToastSuccessUpdate(toast, {
            render: 'Objeto guardado correctamente'
          })
          if (closeModal) {
            dispatch({ type: types.SET_SCAN_MODAL_CLOSE })
          } else {
            dispatch({ type: types.RESET_FORM })
          }
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.RECORD_OBJECT_FAIL, dispatch)
          if (error?.response?.data?.description) {
            const message = String(error.response.data.description)
            setToastErrorUpdate(toast, { render: message })
          } else {
            setToastErrorUpdate('Error al registrar el objeto')
          }
        })
    }
  },

  setScanModalOpen(editMode, object) {
    return { type: types.SET_SCAN_MODAL_OPEN, payload: { editMode, object } }
  },

  setScanModalClose() {
    return { type: types.SET_SCAN_MODAL_CLOSE }
  },

  setObjectCode(code) {
    return { type: types.SET_OBJECT_CODE, payload: { code } }
  },

  setProduct(product) {
    return { type: types.SET_PRODUCT, payload: { product } }
  },

  setModalClose() {
    return { type: types.SET_MODAL_CLOSE }
  },
  setHeight(height) {
    const error = validateDimension(height)
    return { type: types.SET_HEIGHT, payload: { height, error } }
  },
  setWidth(width) {
    const error = validateDimension(width)
    return { type: types.SET_WIDTH, payload: { width, error } }
  },
  setLength(length) {
    const error = validateDimension(length)
    return { type: types.SET_LENGTH, payload: { length, error } }
  },
  setWeight(weight) {
    const error = validateDimension(weight)
    return { type: types.SET_WEIGHT, payload: { weight, error } }
  }
}

export default missingObjectModalActions
