import {
  ExtraTypes,
  SetAdditionalCost,
  SetAdditionalCostComment,
  SetAdditionalCostEnabled,
  SetExtraAssistantCount,
  SetExtraAssistantEnabled
} from '../types/extras'

const actions = {
  setExtraAssistantCount: (extraAssistantCount: number): SetExtraAssistantCount => ({
    type: ExtraTypes.SET_EXTRA_ASSISTANT_COUNT,
    payload: { extraAssistantCount }
  }),
  setExtraAssistantEnabled: (extraAssistantEnabled: boolean): SetExtraAssistantEnabled => ({
    type: ExtraTypes.SET_EXTRA_ASSISTANT_ENABLED,
    payload: { extraAssistantEnabled }
  }),
  setAdditionalCostEnabled: (additionalCostEnabled: boolean): SetAdditionalCostEnabled => ({
    type: ExtraTypes.SET_ADDITIONAL_COST_ENABLED,
    payload: { additionalCostEnabled }
  }),
  setAdditionalCost: (additionalCost: number): SetAdditionalCost => ({
    type: ExtraTypes.SET_ADDITIONAL_COST,
    payload: { additionalCost }
  }),
  setAdditionalCostComment: (additionalCostComment: string): SetAdditionalCostComment => ({
    type: ExtraTypes.SET_ADDITIONAL_COST_COMMENT,
    payload: { additionalCostComment }
  })
}

export default actions
