import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

export type ColInfoContainerProps = {
  blue?: boolean
  flex?: boolean
  loading?: boolean
  children: React.ReactNode
}

const StyledColInfoContainer = styled.div<ColInfoContainerProps>`
  margin-right: 8px;
  display: flex;
  flex: ${(props) => (props.flex ? 1 : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;
  background-color: ${(props) => (props.blue ? '#DFDEFD' : '#FFFFFF')};
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border: 1px solid;
  border-color: ${(props) => (props.blue ? '#AEACFB' : '#C8C7FF')};
`

const StyledSpin = styled(Spin)`
  padding: 18px 0;
`

const ColInfoContainer = (props: ColInfoContainerProps) => (
  <StyledColInfoContainer {...props}>{props.loading ? <StyledSpin /> : props.children}</StyledColInfoContainer>
)

export default ColInfoContainer
