import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { ObjectsListSortKey } from './list'
import { formatDates } from '../../utils'

export const MINUTES_BETWEEN_REPORTS = 5
export const REPORT_MAX_OBJECTS = 30000

export interface RequestReportParams {
  id?: number
  identificationCode?: number
  product?: string
  status?: string
  location?: number
  locationCode?: string
  owner?: string
  ownerId?: number
  column?: ObjectsListSortKey
  order?: SortDirection
  countryId?: CountryIdCode
  objectSituation?: string
  createdAtFrom?: Moment
  createdAtTo?: Moment
}

export function requestReport(params: RequestReportParams): Promise<void> {
  const data = {
    ...params,
    ownerId: undefined,
    ownerID: params.ownerId,
    countryId: undefined,
    country_id: params.countryId,
    createdAtFrom: undefined,
    createdAtTo: undefined,
    ...formatDates({
      dates: {
        created_at_from: params.createdAtFrom,
        created_at_to: params.createdAtTo
      },
      format: 'YYYY-MM-DD'
    })
  }

  return apiPrivate
    .post(URL_BASE_OBJECTS + '/objects/report', data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
