import { Address, AddressTypes, CleanAddress, SetAddress, SetErrorAddress } from './types'

const actions = {
  cleanAddress(): CleanAddress {
    return { type: AddressTypes.CLEAN_ADDRESS }
  },
  setAddress(address: Partial<Address>): SetAddress {
    return { type: AddressTypes.SET_ADDRESS, payload: { address } }
  },
  setErrorAddress(error: string): SetErrorAddress {
    return { type: AddressTypes.SET_ERROR_ADDRESS, payload: { error } }
  }
}

export default actions
