import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../actions/EditDepositViewActionCreators/transport'
import addressActionsCreators from '../../actions/EditDepositViewActionCreators/address'
import TransportComponent from '../../../../common/operations/components/Transport/Transport'
import { getTransportModeId } from '../../../../common/operations/components/Transport/utils'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { TransportTypesPricing } from '../../../NewProposal/constants'

const TransportContainer = () => {
  const transportState = useSelector((state) => state.Deposits.transport)
  const { transportMode, transportModeList, requireTransport, fetchedCost, transportTypePricing } = transportState
  const addressState = useSelector((state) => state.Deposits.address)
  const { transaction } = useSelector((state) => state.Deposits.editView)
  const serviceTypeState = useSelector((state) => state.Deposits.serviceTypes)
  const addressComponentState = useSelector((state) => state.Components.address)
  const itemsState = useSelector((state) => state.Deposits.items)

  const dispatch = useDispatch()
  const transportActions = bindActionCreators(actions, dispatch)
  const addressActions = bindActionCreators(addressActionsCreators, dispatch)

  useEffect(() => {
    if (transportMode) {
      const transportModeId = getTransportModeId(transportMode, transportModeList)
      transportActions.getDepositByTransportAndService({
        transportModeId,
        countryCode: transaction.generalData.countryId as CountryIdCode,
        serviceType: serviceTypeState.serviceType.value
      })
    }
  }, [transportMode, transportModeList])

  useEvents(
    [
      Events.EditDepositView.SET_WHERE,
      Events.EditDepositView.SET_ITEM_PACKAGING,
      Events.EditDepositView.SET_ITEM_FLOORS,
      Events.EditDepositView.SET_ITEM_DISASSEMBLE,
      Events.EditDepositView.SET_PREV_TRANSPORT_COST,
      Events.EditDepositView.SET_TRANSPORT_MODE,
      Events.Proposal.SET_ITEM,
      Events.Proposal.CLEAN_ITEM,
      Events.Proposal.REMOVE_ITEM,
      Events.Proposal.REMOVE_ALL_ITEMS,
      Events.Proposal.MODIFY_ITEM_QUANTITY
    ],
    () => {
      const fetchTransportForFirstTime = transportTypePricing === TransportTypesPricing.FREE && fetchedCost === 0
      if (requireTransport || fetchTransportForFirstTime) {
        transportActions.fetchCost({
          where: transportState.where ?? transportState.address.addressString,
          countryCode: transaction.generalData.countryId,
          customDateTransportMult: transaction.flags.customDateTransportMult,
          transportTypePricing: transportState.transportTypePricing,
          selectedItems: itemsState.selectedItems,
          serviceType: serviceTypeState.serviceType.value
        })
      }
    }
  )

  const saveAddress = () => addressActions.saveAddress(transaction.generalData.user.id, addressComponentState.address)

  return (
    <TransportComponent
      customDateTransportMult={transaction.flags.customDateTransportMult}
      countryCode={transaction.generalData.countryId}
      transportState={transportState}
      transportActions={transportActions}
      addressState={addressState}
      addressActions={addressActions}
      saveAddress={saveAddress}
    />
  )
}

export default TransportContainer
