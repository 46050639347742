import { getAssociatedBank } from '../../../utils/cbuChecker'

export const formatCBUsToOptions = (cbus) => [
  ...cbus.map((cbu) => ({
    label: formatCBU(cbu),
    value: cbu.id
  })),
  { label: 'Crear nuevo CBU', value: 0 }
]

export const formatCBU = ({ bank, cbu }) =>
  `${bank?.name || getAssociatedBank(cbu)} finalizado en ${cbu.substring(cbu.length, cbu.length - 4)}`
