import moment from 'moment'
import { Reducer } from 'redux'
import {
  CalendarActions,
  CalendarState,
  GET_TIMEINTERVALS_FAILURE,
  GET_TIMEINTERVALS_REQUEST,
  GET_TIMEINTERVALS_SUCCESS,
  GET_TIMESLOTS_FAILURE,
  GET_TIMESLOTS_REQUEST,
  GET_TIMESLOTS_SUCCESS,
  GET_UNAVAILABLE_DATES_FAILURE,
  GET_UNAVAILABLE_DATES_REQUEST,
  GET_UNAVAILABLE_DATES_SUCCESS,
  SET_AVAILABLE_DATE,
  SET_TIMESLOT,
  SET_TIME_INTERVALS
} from '../../types/EditDepositViewTypes/calendar'
import {
  CLEAN_STATES,
  GET_TRANSACTION_DATA_FAILURE,
  GET_TRANSACTION_DATA_REQUEST,
  GET_TRANSACTION_DATA_SUCCESS
} from '../../types/EditDepositViewTypes/EditDepositView'

const CalendarInitialState: CalendarState = {
  datetime: moment().add(1, 'day'),
  unavailableDates: {
    list: [],
    selected: moment(),
    loading: false
  },
  timeslots: {
    list: [],
    selected: 0,
    loading: false
  },
  timeIntervals: {
    list: [],
    selected: 0,
    loading: false
  }
}

const CalendarReducer: Reducer<CalendarState, CalendarActions> = (
  state = CalendarInitialState,
  action
): CalendarState => {
  switch (action.type) {
    case CLEAN_STATES:
      return CalendarInitialState
    case GET_TRANSACTION_DATA_REQUEST:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: true
        },
        timeslots: {
          ...state.timeslots,
          loading: true
        },
        timeIntervals: {
          ...state.timeIntervals,
          loading: true
        }
      }
    case GET_TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        datetime: moment(action.payload.transaction.dateData.datetime),
        unavailableDates: {
          ...state.unavailableDates,
          selected: moment(action.payload.transaction.dateData.datetime),
          loading: false
        },
        timeslots: {
          ...state.timeslots,
          selected: action.payload.transaction.dateData.timeSlotId,
          loading: false
        },
        timeIntervals: {
          ...state.timeIntervals,
          selected: 0, // TODO add time_interval_id to back
          loading: false
        }
      }
    case GET_TRANSACTION_DATA_FAILURE:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: false
        },
        timeslots: {
          ...state.timeslots,
          loading: false
        },
        timeIntervals: {
          ...state.timeIntervals,
          loading: false
        }
      }
    case GET_UNAVAILABLE_DATES_REQUEST:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: true
        }
      }
    case GET_UNAVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          list: action.payload.unavailableDates,
          loading: false
        }
      }
    case GET_UNAVAILABLE_DATES_FAILURE: {
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: false
        }
      }
    }
    case SET_AVAILABLE_DATE:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          selected: action.payload.availableDate
        },
        timeslots: {
          ...state.timeslots,
          selected: 0
        },
        timeIntervals: {
          ...state.timeIntervals,
          selected: 0
        }
      }
    case GET_TIMESLOTS_REQUEST:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          loading: true
        }
      }
    case GET_TIMESLOTS_SUCCESS:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          list: action.payload.timeslots,
          selected: action.payload.selectFirstTimeslot ? action.payload.timeslots[0].id : state.timeslots.selected,
          loading: false
        }
      }
    case GET_TIMESLOTS_FAILURE: {
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          loading: false
        }
      }
    }
    case SET_TIMESLOT:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          selected: action.payload.timeslot
        },
        timeIntervals: {
          ...state.timeIntervals,
          selected: 0
        }
      }
    case GET_TIMEINTERVALS_REQUEST:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          loading: true
        }
      }
    case GET_TIMEINTERVALS_SUCCESS:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          list: action.payload.timeIntervals,
          selected: action.payload.timeIntervals[0]?.id || 0,
          loading: false
        }
      }
    case GET_TIMEINTERVALS_FAILURE: {
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          loading: false
        }
      }
    }
    case SET_TIME_INTERVALS:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          selected: action.payload.timeInterval
        }
      }
    default:
      return state
  }
}

export default CalendarReducer
