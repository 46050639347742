import React from 'react'

export const CameraIcon: React.FC<{ className?: string }> = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.83325 5.13453C1.83325 4.13976 2.63967 3.33334 3.63444 3.33334C4.15129 3.33334 4.61016 3.00261 4.77361 2.51228L4.83325 2.33334C4.86138 2.24896 4.87544 2.20677 4.89049 2.16934C5.08262 1.69143 5.53284 1.36692 6.04699 1.33578C6.08726 1.33334 6.13173 1.33334 6.22068 1.33334H10.7792C10.8681 1.33334 10.9126 1.33334 10.9528 1.33578C11.467 1.36692 11.9172 1.69143 12.1093 2.16934C12.1244 2.20677 12.1385 2.24896 12.1666 2.33334L12.2262 2.51228C12.3897 3.00261 12.8485 3.33334 13.3654 3.33334C14.3602 3.33334 15.1666 4.13976 15.1666 5.13453V10.8C15.1666 11.9201 15.1666 12.4802 14.9486 12.908C14.7569 13.2843 14.4509 13.5903 14.0746 13.782C13.6467 14 13.0867 14 11.9666 14H5.03325C3.91315 14 3.35309 14 2.92527 13.782C2.54895 13.5903 2.24299 13.2843 2.05124 12.908C1.83325 12.4802 1.83325 11.9201 1.83325 10.8V5.13453Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49992 11C10.1568 11 11.4999 9.65686 11.4999 8.00001C11.4999 6.34316 10.1568 5.00001 8.49992 5.00001C6.84306 5.00001 5.49992 6.34316 5.49992 8.00001C5.49992 9.65686 6.84306 11 8.49992 11Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
