import { API } from '../../../projectApi'
import { EnabledFormats } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  CLEAR_STATE,
  ClearState,
  GET_REMOVALS_FAILURE,
  GET_REMOVALS_REQUEST,
  GET_REMOVALS_SUCCESS,
  GET_REMOVAL_DETAILS_FAILURE,
  GET_REMOVAL_DETAILS_REQUEST,
  GET_REMOVAL_DETAILS_SUCCESS,
  GetRemovalDetailsRequest,
  GetRemovalDetailsSuccess,
  GetRemovalsRequest,
  GetRemovalsSuccess,
  Pagination,
  RemovalsTableCategoryFilter,
  RemovalsTableDateRangeFilter,
  RemovalsTableSearchFilter,
  RemovalsTableSort,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  SET_VIEW_BUTTON_ACTIVE,
  SetViewButtonActive,
  ViewTab
} from '../types/removalsTable'

const RemovalsActionCreator = {
  getRemovals:
    ({
      pagination,
      sort,
      dateRangeFilters,
      categoryFilter,
      searchFilters
    }: {
      pagination: Pagination
      sort?: RemovalsTableSort
      dateRangeFilters?: RemovalsTableDateRangeFilter[]

      categoryFilter?: RemovalsTableCategoryFilter
      searchFilters?: RemovalsTableSearchFilter[]
      format?: EnabledFormats
    }): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalsRequest = {
        type: GET_REMOVALS_REQUEST,
        payload: {
          newPage: pagination.page,
          pageSize: pagination.limit,
          sort,
          dateRangeFilters,
          categoryFilter,
          searchFilters
        }
      }
      dispatch(request)
      const origin = categoryFilter?.origin.join(',')

      try {
        const { operations, total, quantityClientsWithFullRemovals } =
          await API.TransactionHandler.Operation.Removals.list({
            limit: pagination.limit,
            offset: (pagination.page - 1) * pagination.limit,
            column: sort?.field,
            order: sort?.direction,
            ...dateRangeFiltersToParams(dateRangeFilters),
            ...searchFiltersToParams(searchFilters),
            status: categoryFilter?.status[0],
            ...(origin && { origin }),
            withMetricQuantityFullRemovals: true
          })

        const success: GetRemovalsSuccess = {
          type: GET_REMOVALS_SUCCESS,
          payload: {
            removals: operations,
            total,
            quantityClientsWithFullRemovals
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REMOVALS_FAILURE, dispatch)
      }
    },
  getRemovalDetails:
    (id: number): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalDetailsRequest = {
        type: GET_REMOVAL_DETAILS_REQUEST
      }

      dispatch(request)

      try {
        const { transaction } = await API.TransactionHandler.Operation.Removals.details({ id })
        const { objects } = transaction

        const success: GetRemovalDetailsSuccess = {
          type: GET_REMOVAL_DETAILS_SUCCESS,
          payload: {
            objects
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REMOVAL_DETAILS_FAILURE, dispatch)
      }
    },
  setViewButtonActive: (viewButton: ViewTab | null): SetViewButtonActive => ({
    type: SET_VIEW_BUTTON_ACTIVE,
    payload: {
      activeViewButton: viewButton
    }
  }),
  sendReport:
    ({
      pagination,
      sort,
      dateRangeFilters,
      categoryFilter,
      searchFilters,
      format
    }: {
      pagination: Pagination
      sort?: RemovalsTableSort
      dateRangeFilters?: RemovalsTableDateRangeFilter[]
      categoryFilter?: RemovalsTableCategoryFilter
      searchFilters?: RemovalsTableSearchFilter[]
      format?: EnabledFormats
    }): AppThunk =>
    async (dispatch) => {
      const REPORT_LIMIT = 50
      const origin = categoryFilter?.origin.join(',')

      const toastId = setToastLoading('Solicitando reporte, por favor espere...')
      dispatch({ type: SEND_CSV_REQUEST })
      try {
        await API.TransactionHandler.Operation.Removals.list({
          limit: REPORT_LIMIT,
          offset: (pagination.page - 1) * pagination.limit,
          column: sort?.field,
          order: sort?.direction,
          ...dateRangeFiltersToParams(dateRangeFilters),
          ...searchFiltersToParams(searchFilters),
          status: categoryFilter?.status[0],
          ...(origin && { origin }),
          format,
          withMetricQuantityFullRemovals: true
        })
        dispatch({ type: SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (err) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(err, SEND_CSV_FAILURE, dispatch)
      }
    },
  clearState: (): ClearState => ({ type: CLEAR_STATE })
}

export default RemovalsActionCreator
