import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { ObjectSituationKeys } from '../../../sections/Objects/constants/objectSituations'

export interface Description {
  value: string
}

export type ObjectSituation = {
  situation: ObjectSituationKeys
}

export interface APIResponse {
  results: ObjectSituation[]
}

type Response = CamelCasedPropertiesDeep<ObjectSituation[]>

export function search(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/object-situation/search`)
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data.results)
    })
    .catch((err) => {
      throw err
    })
}
