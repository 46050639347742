import React from 'react'
import { Modal } from 'antd'
import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'
import { parseMomentDate } from './BillingByClientTable'

export type PaymentModalProps = {
  modalOpen: boolean
  loading: boolean
  billing?: Billing | null
  error: string
  handlePayment: () => void
  onCancel: () => void
}

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

export const PaymentModal: React.FC<PaymentModalProps> = ({
  modalOpen: openModal,
  loading,
  handlePayment,
  onCancel,
  billing,
  error
}) => {
  return (
    <Modal
      title="Facturación del periodo"
      visible={openModal}
      onOk={handlePayment}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p>
        ¿Esta seguro que quiere marcar como pago este período {billing && parseMomentDate(billing?.startDate)} -{' '}
        {billing && parseMomentDate(billing?.endDate)}
      </p>
      <div className="container">
        <p style={{ color: 'red', textAlign: 'center', marginTop: '10', marginBottom: '0' }}>{error}</p>
      </div>
    </Modal>
  )
}
