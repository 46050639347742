import { GetUserSuccess as RemovalGetUserSuccess } from '../../sections/NewRemoval/types/users'
import { GetProposalSuccess } from '../../sections/NewProposal/types/proposals'

export const CLEAN_CBU = 'CBU/CLEAN_CBU'
export const CLEAN_STATE = 'CBU/CLEAN_STATE'
export const SET_HOLDER_NAME = 'CBU/SET_HOLDER_NAME'
export const SET_DEFAULT_HOLDER_NAME = 'CBU/SET_DEFAULT_HOLDER_NAME'
export const SET_CBU = 'CBU/SET_CBU'
export const SET_CBU_STATUS = 'CBU/SET_CBU_STATUS'
export const USE_DEFAULT_HOLDER_NAME = 'CBU/USE_DEFAULT_HOLDER_NAME'

export enum CBUStatusType {
  error = 'Error',
  success = 'Success',
  typing = 'Typing'
}

export type CBUStatus = {
  status: CBUStatusType
  message: string
}

export interface CleanCBU {
  type: typeof CLEAN_CBU
}

export interface SetHolderName {
  type: typeof SET_HOLDER_NAME
  payload: { holderName: string }
}

export interface SetCBU {
  type: typeof SET_CBU
  payload: { cbu: string }
}

export interface SetCbuStatus {
  type: typeof SET_CBU_STATUS
  payload: { cbuStatus: CBUStatus }
}

export type CBUState = {
  defaultHolderName: string
  cbuStatus: CBUStatus
  cbu: string
  holderName: string
  isUsingDefaultHolderName: boolean
}

export interface UseDefaultHolderName {
  type: typeof USE_DEFAULT_HOLDER_NAME
  payload: { useDefaultHolderName: boolean }
}

export interface SetDefaultHolderName {
  type: typeof SET_DEFAULT_HOLDER_NAME
  payload: { holderName: string }
}

export type CBUActionTypes =
  | CleanCBU
  | SetHolderName
  | SetDefaultHolderName
  | SetCBU
  | SetCbuStatus
  | UseDefaultHolderName
  | RemovalGetUserSuccess
  | GetProposalSuccess
