import {
  ADD_ALL_OBJECTS_TO_REMOVE,
  ADD_OBJECT_TO_REMOVE,
  CALCULATE_MIN_COST_REQUEST,
  CALCULATE_MIN_COST_SUCCESS,
  CALCULATE_MIN_COST_FAILURE,
  CLOSE_WARNING_MODAL,
  FETCH_OBJECTS,
  FETCH_OBJECTS_FAILURE,
  FETCH_OBJECTS_SUCCESS,
  OPEN_WARNING_MODAL,
  ObjectResponse,
  ObjectToRemove,
  ObjectsActionTypes,
  ObjectsToRemove,
  SET_ALL_OBJECTS_ASSEMBLY_COST,
  SET_ALL_OBJECTS_FLOORS,
  SET_ALL_OBJECTS_NUMBER_OF_FLOORS,
  SET_ALL_OBJECTS_REMOVE_PACKAGING_COST,
  SET_ASSEMBLY_COST,
  SET_FLOORS,
  SET_NUMBER_OF_FLOORS,
  SET_OBJECTS_FROM_REMOVAL,
  SET_REMOVE_PACKAGING_COST,
  UNDO_ALL_OBJECTS_TO_REMOVE,
  UNDO_OBJECT_TO_REMOVE
} from '../types/objects'
import commonTypes from '../types/common'
import { objectAvailableForRemoval } from '../utils/objects'
import { GetRemovalData } from '../types/editMode'
import { ObjectFromRemoval } from '../../../projectApi/TransactionHandler/Operation/Removals/common'
import { MinCostResponse } from '../../../projectApi/ObjectAdministration/Object/calculateMinCost'

const initialState = {
  objects: [],
  fetchedObjects: false,
  error: '',
  loading: false,
  loadingMinCost: false,
  quantity: 0,
  objectsToRemove: {},
  objectsFromRemoval: [],
  objectsMinCost: [],
  warning: false
}

export type ObjectsState = {
  objects: Array<ObjectResponse>
  fetchedObjects: boolean
  error: string
  loading: boolean
  loadingMinCost: boolean
  objectsMinCost: MinCostResponse[]
  quantity: number
  objectsToRemove: ObjectsToRemove
  objectsFromRemoval: ObjectFromRemoval[]
  warning: boolean
}

const root = (state: ObjectsState = initialState, action: ObjectsActionTypes): ObjectsState => {
  switch (action.type) {
    case commonTypes.FINISH:
      return initialState
    case FETCH_OBJECTS: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_OBJECTS_SUCCESS: {
      const { objects } = action.payload
      return {
        ...state,
        loading: false,
        fetchedObjects: true,
        objects
      }
    }
    case FETCH_OBJECTS_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        error
      }
    }
    case CALCULATE_MIN_COST_REQUEST: {
      return {
        ...state,
        loadingMinCost: true
      }
    }
    case CALCULATE_MIN_COST_SUCCESS: {
      const { objects } = action.payload
      return {
        ...state,
        loadingMinCost: false,
        objectsMinCost: objects
      }
    }
    case CALCULATE_MIN_COST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loadingMinCost: false,
        error
      }
    }
    case ADD_OBJECT_TO_REMOVE: {
      const objectToRemove = action.payload.objectToRemove
      return {
        ...state,
        objectsToRemove: {
          ...state.objectsToRemove,
          [objectToRemove.ID]: {
            objectDetails: objectToRemove,
            floors: false,
            numberOfFloors: 1,
            removePackagingCost: false,
            assemblyCost: false,
            deleted: false
          }
        }
      }
    }
    case UNDO_OBJECT_TO_REMOVE: {
      const objectToRemove = action.payload.objectToRemove
      const objectsToRemove = Object.values(state.objectsToRemove).reduce<ObjectsToRemove>(
        (accum: ObjectsToRemove, obj: ObjectToRemove) => {
          if (objectToRemove.ID === obj.objectDetails.ID) {
            if (obj.fromRemoval) return { ...accum, [obj.objectDetails.ID]: { ...obj, deleted: true } }
            return accum
          }
          return { ...accum, [obj.objectDetails.ID]: obj }
        },
        {}
      )

      return {
        ...state,
        objectsToRemove
      }
    }
    case ADD_ALL_OBJECTS_TO_REMOVE: {
      const { objects } = state
      const objectsToRemove = objects.reduce((acc, object) => {
        if (objectAvailableForRemoval(object)) {
          return {
            ...acc,
            [object.ID]: {
              objectDetails: object,
              floors: false,
              numberOfFloors: 1,
              removePackagingCost: false,
              assemblyCost: false,
              deleted: false
            }
          }
        }
        return acc
      }, {})

      return {
        ...state,
        objectsToRemove
      }
    }
    case UNDO_ALL_OBJECTS_TO_REMOVE: {
      return {
        ...state,
        objectsToRemove: {}
      }
    }
    case SET_FLOORS: {
      const { objectID, floors } = action.payload
      const newObjectsToRemove = {
        ...state.objectsToRemove,
        [objectID]: { ...state.objectsToRemove[objectID], floors }
      }
      return {
        ...state,
        objectsToRemove: newObjectsToRemove
      }
    }
    case SET_NUMBER_OF_FLOORS: {
      const { objectID, numberOfFloors } = action.payload
      const newObjectsToRemove = {
        ...state.objectsToRemove,
        [objectID]: { ...state.objectsToRemove[objectID], numberOfFloors }
      }
      return {
        ...state,
        objectsToRemove: newObjectsToRemove
      }
    }
    case SET_ASSEMBLY_COST: {
      const { objectID, assemblyCost } = action.payload
      const newObjectsToRemove = {
        ...state.objectsToRemove,
        [objectID]: { ...state.objectsToRemove[objectID], assemblyCost }
      }
      return {
        ...state,
        objectsToRemove: newObjectsToRemove
      }
    }
    case SET_REMOVE_PACKAGING_COST: {
      const { objectID, removePackagingCost } = action.payload
      const newObjectsToRemove = {
        ...state.objectsToRemove,
        [objectID]: { ...state.objectsToRemove[objectID], removePackagingCost }
      }
      return {
        ...state,
        objectsToRemove: newObjectsToRemove
      }
    }
    case SET_ALL_OBJECTS_FLOORS: {
      const { floors } = action.payload
      return {
        ...state,
        objectsToRemove: Object.keys(state.objectsToRemove).reduce((acc, objectID) => {
          return {
            ...acc,
            [objectID]: {
              ...state.objectsToRemove[objectID],
              floors
            }
          }
        }, {})
      }
    }
    case SET_ALL_OBJECTS_NUMBER_OF_FLOORS: {
      const { numberOfFloors } = action.payload
      return {
        ...state,
        objectsToRemove: Object.keys(state.objectsToRemove).reduce((acc, objectID) => {
          return {
            ...acc,
            [objectID]: {
              ...state.objectsToRemove[objectID],
              ...(state.objectsToRemove[objectID].floors ? { numberOfFloors } : {})
            }
          }
        }, {})
      }
    }
    case SET_ALL_OBJECTS_ASSEMBLY_COST: {
      const { assemblyCost } = action.payload
      return {
        ...state,
        objectsToRemove: Object.keys(state.objectsToRemove).reduce((acc, objectID) => {
          return {
            ...acc,
            [objectID]: {
              ...state.objectsToRemove[objectID],
              assemblyCost
            }
          }
        }, {})
      }
    }
    case SET_ALL_OBJECTS_REMOVE_PACKAGING_COST: {
      const { removePackagingCost } = action.payload
      return {
        ...state,
        objectsToRemove: Object.keys(state.objectsToRemove).reduce((acc, objectID) => {
          return {
            ...acc,
            [objectID]: {
              ...state.objectsToRemove[objectID],
              removePackagingCost
            }
          }
        }, {})
      }
    }
    case OPEN_WARNING_MODAL: {
      return {
        ...state,
        warning: true
      }
    }
    case CLOSE_WARNING_MODAL: {
      return {
        ...state,
        warning: false
      }
    }
    case SET_OBJECTS_FROM_REMOVAL: {
      const { objects, objectsFromRemoval } = state

      const objectsToRemove: ObjectsToRemove = objectsFromRemoval.reduce<ObjectsToRemove>((acc, objToRemove) => {
        // @ts-ignore Type-fest library is wrongly formatting data to camel. TS and type-fest update needed
        const objExist = objects.find((obj) => obj.RealID === objToRemove.objectId)

        if (objExist) {
          acc[objExist?.ID] = {
            objectDetails: objExist,
            floors: !!objToRemove.floorsByStairsCost,
            numberOfFloors: 1, // Put floors as 1 just for convenient. We don't save floors quantity on BE
            floorCostFromRemoval: objToRemove.floorsByStairsCost,
            removePackagingCost: !!objToRemove.packagingCost,
            assemblyCost: !!objToRemove.assemblyCost,
            fromRemoval: true
          }
        }

        return acc
      }, {})
      return {
        ...state,
        objectsToRemove
      }
    }
    case GetRemovalData.GET_REMOVAL_DATA_SUCCESS:
      return {
        ...state,
        objectsFromRemoval: action.payload.operation.objects
      }
    default:
      return state
  }
}

export default root
