import { API } from '../../../projectApi'
import { ListParams } from '../../../projectApi/Mercurio/Metrics/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_METRICS_FAILURE,
  GET_METRICS_REQUEST,
  GET_METRICS_SUCCESS,
  GetMetricsRequest,
  GetMetricsSuccess,
  ParamsFilter
} from '../types/MetricsLeads'

const modalLeadsActionCreators = {
  getMetrics:
    (params: ParamsFilter): AppThunk =>
    async (dispatch, getState) => {
      const request: GetMetricsRequest = {
        type: GET_METRICS_REQUEST
      }

      const country = getState().CountrySelector.countrySelected.fullName ?? ''

      dispatch(request)
      const paramsFilters: ListParams = {
        source: params.source,
        contactMediumId: params.contact_medium_id,
        dateFrom: params.created_at_from_v2,
        dateTo: params.created_at_to_v2,
        updatedAtFrom: params.updated_at_from_v2,
        updatedAtTo: params.updated_at_to_v2,
        winDateFrom: params.win_date_from,
        winDateTo: params.win_date_to,
        assignedAgentId: params.agent_ids,
        prospectTypeId: params.prospect_type_id,
        provider: params.provider
      }

      try {
        const metrics = await API.Mercurio.Metrics.list({ ...paramsFilters, country })

        const success: GetMetricsSuccess = {
          type: GET_METRICS_SUCCESS,
          payload: {
            metrics
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_METRICS_FAILURE, dispatch)
      }
    }
}

export default modalLeadsActionCreators
