import * as Types from '../gqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCalendarQueryVariables = Types.Exact<{
  endDate: Types.Scalars['String']['input'];
  startDate: Types.Scalars['String']['input'];
  input: Types.CalculateTripCostsInput;
  flagInput: Types.GetValueByFlagRequestInput;
}>;


export type GetCalendarQuery = { __typename?: 'Query', getCalendar: Array<{ __typename?: 'CalendarDateEntity', date: string, day: string, discount: number, depositDisabled: boolean, removalDisabled: boolean }>, calculateTripCost: { __typename?: 'CalculateTripCostsResponse', price: number }, getValueByFlag: { __typename?: 'GetValueByFlagResponse', value: string } };


export const GetCalendarDocument = gql`
    query GetCalendar($endDate: String!, $startDate: String!, $input: CalculateTripCostsInput!, $flagInput: GetValueByFlagRequestInput!) {
  getCalendar(endDate: $endDate, startDate: $startDate) {
    date
    day
    discount
    depositDisabled
    removalDisabled
  }
  calculateTripCost(input: $input) {
    price
  }
  getValueByFlag(input: $flagInput) {
    value
  }
}
    `;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      input: // value for 'input'
 *      flagInput: // value for 'flagInput'
 *   },
 * });
 */
export function useGetCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables> & ({ variables: GetCalendarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, options);
      }
export function useGetCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, options);
        }
export function useGetCalendarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, options);
        }
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<typeof useGetCalendarLazyQuery>;
export type GetCalendarSuspenseQueryHookResult = ReturnType<typeof useGetCalendarSuspenseQuery>;
export type GetCalendarQueryResult = Apollo.QueryResult<GetCalendarQuery, GetCalendarQueryVariables>;
export function refetchGetCalendarQuery(variables: GetCalendarQueryVariables) {
      return { query: GetCalendarDocument, variables: variables }
    }