import {
  ADD_ALL_OBJECTS_TO_REMOVE,
  ADD_OBJECT_TO_REMOVE,
  CLOSE_WARNING_MODAL,
  FETCH_OBJECTS,
  FETCH_OBJECTS_FAILURE,
  FETCH_OBJECTS_SUCCESS,
  OPEN_WARNING_MODAL,
  ObjectResponse,
  SET_ALL_OBJECTS_ASSEMBLY_COST,
  SET_ALL_OBJECTS_FLOORS,
  SET_ALL_OBJECTS_NUMBER_OF_FLOORS,
  SET_ALL_OBJECTS_REMOVE_PACKAGING_COST,
  SET_ASSEMBLY_COST,
  SET_FLOORS,
  SET_NUMBER_OF_FLOORS,
  SET_OBJECTS_FROM_REMOVAL,
  SET_REMOVE_PACKAGING_COST,
  SetAllObjectsAssemblyCost,
  SetAllObjectsFloors,
  SetAllObjectsNumberOfFloors,
  SetAllObjectsRemovePackagingCost,
  SetAssemblyCost,
  SetFloors,
  SetNumberOfFloors,
  SetRemovePackagingCost,
  UNDO_ALL_OBJECTS_TO_REMOVE,
  UNDO_OBJECT_TO_REMOVE,
  ObjectsToRemove,
  CalculateMinCostRequest,
  CALCULATE_MIN_COST_REQUEST,
  CalculateMinCostSuccess,
  CALCULATE_MIN_COST_SUCCESS
} from '../types/objects'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import transportActions from './transport'
import { AppThunk } from '../../../store'
import { Moment } from 'moment'
import { API } from '../../../projectApi'

export const LIMIT = 100

type GetRemainingObjectsResponse = {
  data: {
    description: {
      objects: ObjectResponse[]
    }
  }
}

const fetchTransportCost: AppThunk = (dispatch, getState) => {
  const { transport } = getState().NewRemoval

  const { address_id, where, shouldFetchCost } = transport

  if (address_id !== 0 && shouldFetchCost) {
    dispatch(transportActions.fetchCost(where))
  }
}

const getRemainingObjects = async (ownerID: number, quantity: number): Promise<ObjectResponse[]> => {
  const requests = []
  for (let i = LIMIT; i < quantity; i = i + LIMIT) {
    const params = { Limit: LIMIT, Offset: i, ownerID, withoutDeleted: true }
    requests.push(apiPrivate.get(URL_BASE_OBJECTS + '/object', { params }))
  }
  const responses: GetRemainingObjectsResponse[] = await Promise.all(requests)

  return responses.reduce((acc: ObjectResponse[], response) => [...acc, ...response.data.description.objects], [])
}

const actions = {
  getObjects(ownerID: number): AppThunk {
    return function (dispatch) {
      const params = {
        params: {
          Limit: LIMIT,
          ownerID,
          withoutDeleted: true
        }
      }

      dispatch({ type: FETCH_OBJECTS })

      return apiPrivate
        .get(URL_BASE_OBJECTS + '/object', params)
        .then(async (response) => {
          const { objects, quantity } = response.data.description
          const remainingObjects = await getRemainingObjects(ownerID, quantity)

          dispatch({
            type: FETCH_OBJECTS_SUCCESS,
            payload: {
              objects: [...objects, ...remainingObjects]
            }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, FETCH_OBJECTS_FAILURE, dispatch)
        })
    }
  },

  calculateMinCost(objects: ObjectsToRemove, removalDate: Moment): AppThunk {
    return async function (dispatch) {
      if (Object.keys(objects).length === 0) {
        const fetchObjectSuccess: CalculateMinCostSuccess = {
          type: CALCULATE_MIN_COST_SUCCESS,
          payload: {
            objects: []
          }
        }

        dispatch(fetchObjectSuccess)

        return
      }

      const objectsId = Object.values(objects).map((object) => object.objectDetails.RealID)
      const params = {
        objectsId,
        removalDate
      }

      const fetchObjectRequest: CalculateMinCostRequest = {
        type: CALCULATE_MIN_COST_REQUEST
      }

      dispatch(fetchObjectRequest)

      try {
        const { objects } = await API.ObjectAdministration.Object.calculateMinCost(params)

        const fetchObjectSuccess: CalculateMinCostSuccess = {
          type: CALCULATE_MIN_COST_SUCCESS,
          payload: {
            objects
          }
        }

        dispatch(fetchObjectSuccess)
      } catch (error) {
        handleErrorsWithAction(error, FETCH_OBJECTS_FAILURE, dispatch)
      }
    }
  },

  addObjectToRemove(object: ObjectResponse): AppThunk {
    return function (dispatch, getState) {
      dispatch({
        type: ADD_OBJECT_TO_REMOVE,
        payload: { objectToRemove: object }
      })
      dispatch(fetchTransportCost)
    }
  },

  undoObjectToRemove(object: ObjectResponse): AppThunk {
    return function (dispatch) {
      dispatch({
        type: UNDO_OBJECT_TO_REMOVE,
        payload: { objectToRemove: object }
      })
      dispatch(fetchTransportCost)
    }
  },

  addAllObjectsToRemove(): AppThunk {
    return function (dispatch) {
      dispatch({
        type: ADD_ALL_OBJECTS_TO_REMOVE
      })
      dispatch(fetchTransportCost)
    }
  },

  undoAllObjectsToRemove(): AppThunk {
    return function (dispatch) {
      dispatch({
        type: UNDO_ALL_OBJECTS_TO_REMOVE
      })
      dispatch(fetchTransportCost)
    }
  },

  openWarningModal: (warning: boolean) => ({
    type: OPEN_WARNING_MODAL
  }),

  closeWarningModal: (warning: boolean) => ({
    type: CLOSE_WARNING_MODAL
  }),

  setAllObjectsFloors: (floors: boolean): SetAllObjectsFloors => ({
    type: SET_ALL_OBJECTS_FLOORS,
    payload: { floors }
  }),
  setAllObjectsNumberOfFloors: (numberOfFloors: number): SetAllObjectsNumberOfFloors => ({
    type: SET_ALL_OBJECTS_NUMBER_OF_FLOORS,
    payload: { numberOfFloors }
  }),
  setAllObjectsAssemblyCost: (assemblyCost: boolean): SetAllObjectsAssemblyCost => ({
    type: SET_ALL_OBJECTS_ASSEMBLY_COST,
    payload: { assemblyCost }
  }),
  setAllObjectsRemovePackagingCost: (removePackagingCost: boolean): SetAllObjectsRemovePackagingCost => ({
    type: SET_ALL_OBJECTS_REMOVE_PACKAGING_COST,
    payload: { removePackagingCost }
  }),

  setFloors: (objectID: string, floors: boolean): SetFloors => ({
    type: SET_FLOORS,
    payload: { objectID, floors }
  }),
  setNumberOfFloors: (objectID: string, numberOfFloors: number): SetNumberOfFloors => ({
    type: SET_NUMBER_OF_FLOORS,
    payload: { objectID, numberOfFloors }
  }),
  setAssemblyCost: (objectID: string, assemblyCost: boolean): SetAssemblyCost => ({
    type: SET_ASSEMBLY_COST,
    payload: { objectID, assemblyCost }
  }),
  setRemovePackagingCost: (objectID: string, removePackagingCost: boolean): SetRemovePackagingCost => ({
    type: SET_REMOVE_PACKAGING_COST,
    payload: { objectID, removePackagingCost }
  }),

  setObjectsFromRemoval:
    (isDraft: boolean): AppThunk =>
    (dispatch) => {
      dispatch({ type: SET_OBJECTS_FROM_REMOVAL, payload: { isDraft } })
    }
}

export default actions
