import { Removal } from '../../../projectApi/TransactionHandler/Operation/Removals/common'

export interface EditModeState {
  editMode: boolean
  isDraft: boolean
  userId: number
}

export enum EditRemovalTypes {
  EDIT_REMOVAL_REQUEST = 'REMOVALS/EDIT_REMOVAL/EDIT_REMOVAL_REQUEST',
  EDIT_REMOVAL_SUCCESS = 'REMOVALS/EDIT_REMOVAL/EDIT_REMOVAL_SUCCESS',
  EDIT_REMOVAL_FAILURE = 'REMOVALS/EDIT_REMOVAL/EDIT_REMOVAL_FAILURE'
}

export interface EditRemovalRequest {
  type: typeof EditRemovalTypes.EDIT_REMOVAL_REQUEST
}

export interface EditRemovalSuccess {
  type: typeof EditRemovalTypes.EDIT_REMOVAL_SUCCESS
}

export interface EditRemovalFailure {
  type: typeof EditRemovalTypes.EDIT_REMOVAL_FAILURE
}

export type EditRemovalActions = EditRemovalRequest | EditRemovalSuccess | EditRemovalFailure

export enum GetRemovalData {
  GET_REMOVAL_DATA_REQUEST = 'REMOVALS/EDIT_REMOVAL/GET_REMOVAL_DATA_REQUEST',
  GET_REMOVAL_DATA_SUCCESS = 'REMOVALS/EDIT_REMOVAL/GET_REMOVAL_DATA_SUCCESS',
  GET_REMOVAL_DATA_FAILURE = 'REMOVALS/EDIT_REMOVAL/GET_REMOVAL_DATA_FAILURE'
}

export interface GetRemovalDataRequest {
  type: GetRemovalData.GET_REMOVAL_DATA_REQUEST
}

export interface GetRemovalDataSuccess {
  type: GetRemovalData.GET_REMOVAL_DATA_SUCCESS
  payload: {
    operation: Removal
  }
}

export interface GetRemovalDataFailure {
  type: GetRemovalData.GET_REMOVAL_DATA_FAILURE
}

export type GetRemovalDataActions = GetRemovalDataRequest | GetRemovalDataSuccess | GetRemovalDataFailure

export enum EditDataTypes {
  ACTIVE_EDIT_MODE = 'REMOVALS/EDIT_REMOVAL/ACTIVE_EDIT_MODE'
}

export interface ActiveEditMode {
  type: EditDataTypes.ACTIVE_EDIT_MODE
  payload: {
    activeMode: boolean
    userId: number
    isDraft?: boolean
  }
}

export type EditDataActions = ActiveEditMode

export type EditModeActions = EditDataActions | EditRemovalActions | GetRemovalDataActions
