import clsx from 'clsx'
import React from 'react'
import useInjectChildrenWithProps from '../../utils/hooks/useInjectChildrenWithProps'
import smallTabsStyles from './smallTabs.module.scss'

type SmallTabsProps<T extends string | number> = {
  children: React.ReactNode
  activeTabId: T
  onChange: (newId: T) => void
}

const SmallTabs = <T extends string | number>({ children, activeTabId, onChange }: SmallTabsProps<T>) => {
  const childrenWithProps = useInjectChildrenWithProps(children, (child) => ({
    active: activeTabId === child.props.id,
    onClick: onChange
  }))

  return <div className={smallTabsStyles.smallTabs}>{childrenWithProps}</div>
}

export default SmallTabs

export const Tab: React.FC<{ id: string | number; active?: boolean; onClick?: (id: number | string) => void }> = ({
  id,
  active,
  children,
  onClick
}) => {
  return (
    <button
      key={id}
      className={clsx(smallTabsStyles.tab, active && smallTabsStyles.active)}
      onClick={() => !active && onClick && onClick(id)}>
      <span>{children}</span>
    </button>
  )
}
