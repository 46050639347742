import React from 'react'
import { Modal } from 'antd'
import { Params } from '../types/address'
import { DefaultAddressState } from '../types/defaultAddress'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center' as const,
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  },
  textDefault: {
    textAlign: 'center' as const
  }
}

type DefaultAddressModal = {
  setDefaultAddress: (userId: number, addressId: number) => void
  closeModal: () => void
  defaultAddress: DefaultAddressState
  getAddresses: (userId: number, paramsFromAddress: Params) => void
  paramsFromAddress: Params
}

const DefaultAddress = (props: DefaultAddressModal) => {
  const { defaultAddress, paramsFromAddress, setDefaultAddress, getAddresses, closeModal } = props
  const { error, loading, defaultAddressModalOpen, userId, addressId, addressText } = defaultAddress

  return (
    <Modal
      title={'Dirección predeterminada'}
      visible={defaultAddressModalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={async () => {
        await setDefaultAddress(userId, addressId)
        await getAddresses(userId, paramsFromAddress)
      }}
      onCancel={() => closeModal()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p style={styles.textDefault}>
        ¿Está seguro que quiere marcar la siguiente dirección como predeterminada? <br /> {addressText}
      </p>
      <div className="container">
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

export default DefaultAddress
