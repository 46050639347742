import { Role } from '../../../../projectApi/AccountManager/Roles/list'
import { SatelliteAccount } from '../../../../projectApi/AccountManager/Satellite/corporate'
import { GuestUser } from '../../../../projectApi/AccountManager/Satellite/guest'
import { Pagination, SearchFilter } from '../../../../utils/searchFilterUtils'

export type GuestUsersTableSearchKey = 'id'
export type GuestUsersSearchFilter = SearchFilter<GuestUsersTableSearchKey>

export type Filters = {
  id?: number
}

export type UserABMTypes = {
  isEdit: boolean
  email: string
  roleId: number
  corporateRoles: Role[]
  isAddModalOpen: boolean
  isDeleteModalOpen: boolean
  isDeleteInviteModalOpen: boolean
  isLoading: boolean
  isLoadingGuests: boolean
  error: string
  guestUsers: GuestUser[]
  selectedGuestUser: GuestUser | null
  selectedUser: SatelliteAccount | null
  pagination: Pagination
  searchFilters: GuestUsersSearchFilter[]
  filters: Filters
}

export enum FormTypes {
  SET_EMAIL = 'CORPORATIVE/USER/USERABM/SET_EMAIL',
  SET_ROLE_ID = 'CORPORATIVE/USER/USERABM/SET_ROLE_ID'
}

export interface SetEmail {
  type: typeof FormTypes.SET_EMAIL
  payload: {
    email: string
  }
}

export interface SetRoleId {
  type: typeof FormTypes.SET_ROLE_ID
  payload: {
    roleId: number
  }
}

export type FormTypesActions = SetEmail | SetRoleId

export enum GetCorporateRolesTypes {
  GET_CORPORATE_ROLES_REQUEST = 'CORPORATIVE/USER/USERABM/GET_CORPORATE_ROLES_REQUEST',
  GET_CORPORATE_ROLES_SUCCESS = 'CORPORATIVE/USER/USERABM/GET_CORPORATE_ROLES_SUCCESS',
  GET_CORPORATE_ROLES_FAILURE = 'CORPORATIVE/USER/USERABM/GET_CORPORATE_ROLES_FAILURE'
}

export interface GetCorporateRolesRequest {
  type: GetCorporateRolesTypes.GET_CORPORATE_ROLES_REQUEST
}

export interface GetCorporateRolesSuccess {
  type: GetCorporateRolesTypes.GET_CORPORATE_ROLES_SUCCESS
  payload: {
    coporateRoles: Role[]
  }
}

export interface GetCorporateRolesFailure {
  type: GetCorporateRolesTypes.GET_CORPORATE_ROLES_FAILURE
  payload: {
    error: string
  }
}

type GetCorporateRolesActions = GetCorporateRolesRequest | GetCorporateRolesSuccess | GetCorporateRolesFailure

export enum SendInvitationTypes {
  SEND_INVITATION_REQUEST = 'CORPORATIVE/USER/USERABM/SEND_INVITATION_REQUEST',
  SEND_INVITATION_SUCCESS = 'CORPORATIVE/USER/USERABM/SEND_INVITATION_SUCCESS',
  SEND_INVITATION_FAILURE = 'CORPORATIVE/USER/USERABM/SEND_INVITATION_FAILURE'
}

export interface SendInvitationRequest {
  type: SendInvitationTypes.SEND_INVITATION_REQUEST
}

export interface SendInvitationSuccess {
  type: SendInvitationTypes.SEND_INVITATION_SUCCESS
}

export interface SendInvitationFailure {
  type: SendInvitationTypes.SEND_INVITATION_FAILURE
  payload: {
    error: string
  }
}

type SendInvitationActions = SendInvitationRequest | SendInvitationSuccess | SendInvitationFailure

export enum ModalInviteTypes {
  SET_MODAL_INVITE_OPEN = 'CORPORATIVE/USER/USERABM/SET_MODAL_INVITE_OPEN'
}

export interface SetModalOpen {
  type: typeof ModalInviteTypes.SET_MODAL_INVITE_OPEN
  payload: {
    isAddModalOpen: boolean
  }
}

export type ModalInviteActions = SetModalOpen

export enum VerifyEmailTypes {
  VERIFY_EMAIL_REQUEST = 'CORPORATIVE/USER/USERABM/VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS = 'CORPORATIVE/USER/USERABM/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE = 'CORPORATIVE/USER/USERABM/VERIFY_EMAIL_FAILURE'
}

export interface VerifyEmailRequest {
  type: VerifyEmailTypes.VERIFY_EMAIL_REQUEST
}

export interface VerifyEmailSuccess {
  type: VerifyEmailTypes.VERIFY_EMAIL_SUCCESS
}

export interface VerifyEmailFailure {
  type: VerifyEmailTypes.VERIFY_EMAIL_FAILURE
  payload: {
    error: string
  }
}

type VerifyEmailActions = VerifyEmailRequest | VerifyEmailSuccess | VerifyEmailFailure

export enum GetGuestUsersTypes {
  GET_GUEST_USERS_REQUEST = 'CORPORATIVE/USER/USERABM/GET_GUEST_USERS_REQUEST',
  GET_GUEST_USERS_SUCCESS = 'CORPORATIVE/USER/USERABM/GET_GUEST_USERS_SUCCESS',
  GET_GUEST_USERS_FAILURE = 'CORPORATIVE/USER/USERABM/GET_GUEST_USERS_FAILURE'
}

export interface GetGuestUsersRequest {
  type: GetGuestUsersTypes.GET_GUEST_USERS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: GuestUsersSearchFilter[]
  }
}

export interface GetGuestUsersSuccess {
  type: GetGuestUsersTypes.GET_GUEST_USERS_SUCCESS
  payload: {
    guestUsers: GuestUser[]
    total: number
  }
}

export interface GetGuestUsersFailure {
  type: GetGuestUsersTypes.GET_GUEST_USERS_FAILURE
  payload: {
    error: string
  }
}

type GetGuestUsersActions = GetGuestUsersRequest | GetGuestUsersSuccess | GetGuestUsersFailure

export const RESET_FILTERS = 'CORPORATIVE/USER/USERABM/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export enum DeleteInvitationTypes {
  DELETE_INVITATION_REQUEST = 'CORPORATIVE/USER/USERABM/DELETE_INVITATION_REQUEST',
  DELETE_INVITATION_SUCCESS = 'CORPORATIVE/USER/USERABM/DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_FAILURE = 'CORPORATIVE/USER/USERABM/DELETE_INVITATION_FAILURE'
}

export interface DeleteInvitationRequest {
  type: DeleteInvitationTypes.DELETE_INVITATION_REQUEST
}

export interface DeleteInvitationSuccess {
  type: DeleteInvitationTypes.DELETE_INVITATION_SUCCESS
}

export interface DeleteInvitationFailure {
  type: DeleteInvitationTypes.DELETE_INVITATION_FAILURE
  payload: {
    error: string
  }
}

type DeleteInvitationActions = DeleteInvitationRequest | DeleteInvitationSuccess | DeleteInvitationFailure

export enum ModalDeleteInviteTypes {
  SET_MODAL_DELETE_INVITE_OPEN = 'CORPORATIVE/USER/USERABM/SET_MODAL_DELETE_INVITE_OPEN'
}

export interface SetDeleteInviteModalOpen {
  type: typeof ModalDeleteInviteTypes.SET_MODAL_DELETE_INVITE_OPEN
  payload: {
    isDeleteInviteModalOpen: boolean
    selectedGuestUser: GuestUser | null
  }
}

export type ModalDeleteInviteActions = SetDeleteInviteModalOpen

export enum ModalDeleteTypes {
  SET_MODAL_DELETE_OPEN = 'CORPORATIVE/USER/USERABM/SET_MODAL_DELETE_OPEN'
}

export interface SetDeleteModalOpen {
  type: typeof ModalDeleteTypes.SET_MODAL_DELETE_OPEN
  payload: {
    isDeleteModalOpen: boolean
    selectedUser: SatelliteAccount | null
  }
}

export type ModalDeleteActions = SetDeleteModalOpen

export enum ChangeRoleTypes {
  CHANGE_ROLE_REQUEST = 'CORPORATIVE/USER/USERABM/CHANGE_ROLE_REQUEST',
  CHANGE_ROLE_SUCCESS = 'CORPORATIVE/USER/USERABM/CHANGE_ROLE_SUCCESS',
  CHANGE_ROLE_FAILURE = 'CORPORATIVE/USER/USERABM/CHANGE_ROLE_FAILURE'
}

export interface ChangeRoleRequest {
  type: ChangeRoleTypes.CHANGE_ROLE_REQUEST
}

export interface ChangeRoleSuccess {
  type: ChangeRoleTypes.CHANGE_ROLE_SUCCESS
}

export interface ChangeRoleFailure {
  type: ChangeRoleTypes.CHANGE_ROLE_FAILURE
  payload: {
    error: string
  }
}

type ChangeRoleActions = ChangeRoleRequest | ChangeRoleSuccess | ChangeRoleFailure

export enum DeleteSatelliteUserTypes {
  DELETE_SATELLITE_REQUEST = 'CORPORATIVE/USER/USERABM/DELETE_SATELLITE_REQUEST',
  DELETE_SATELLITE_SUCCESS = 'CORPORATIVE/USER/USERABM/DELETE_SATELLITE_SUCCESS',
  DELETE_SATELLITE_FAILURE = 'CORPORATIVE/USER/USERABM/DELETE_SATELLITE_FAILURE'
}

export interface DeleteSatelliteUserRequest {
  type: DeleteSatelliteUserTypes.DELETE_SATELLITE_REQUEST
}

export interface DeleteSatelliteUserSuccess {
  type: DeleteSatelliteUserTypes.DELETE_SATELLITE_SUCCESS
}

export interface DeleteSatelliteUserFailure {
  type: DeleteSatelliteUserTypes.DELETE_SATELLITE_FAILURE
  payload: {
    error: string
  }
}

type DeleteSatelliteUserActions = DeleteSatelliteUserRequest | DeleteSatelliteUserSuccess | DeleteSatelliteUserFailure

export enum ModalEditTypes {
  SET_MODAL_EDIT_OPEN = 'CORPORATIVE/USER/USERABM/SET_MODAL_EDIT_OPEN'
}

export interface SetEditModalOpen {
  type: typeof ModalEditTypes.SET_MODAL_EDIT_OPEN
  payload: {
    isAddModalOpen: boolean
    selectedUser: SatelliteAccount
  }
}

export type ModalEditActions = SetEditModalOpen
type ResetFilterActions = ResetFilters

export type UserABMActions =
  | GetCorporateRolesActions
  | SendInvitationActions
  | ModalInviteActions
  | VerifyEmailActions
  | GetGuestUsersActions
  | DeleteInvitationActions
  | ModalDeleteInviteActions
  | FormTypesActions
  | ChangeRoleActions
  | DeleteSatelliteUserActions
  | ModalDeleteActions
  | ModalEditActions
  | ResetFilterActions
