import { AxiosResponse } from 'axios'
import { apiPrivate } from './../../api'
import { URL_BASE_SALES } from '../../endpoints'
interface Response {
  reasons: Reasons[]
}

export type Reasons = {
  id: number
  text: string
}

export enum SaleActions {
  REJECT = 'REJECT'
}

type ReasonsParams = {
  action: SaleActions
}

export function reasons(params: ReasonsParams): Promise<Response> {
  const { action } = params
  return apiPrivate.get(`${URL_BASE_SALES}/reasons/${action}`).then((response: AxiosResponse<Response>) => {
    if (!response.data) throw new Error('Hubo un error al obtener las razones')

    return response.data
  })
}
