import React from 'react'
import { DynamicTable } from '../../../../components/DynamicTable'
import { Column, Pagination } from '../../../../components/DynamicTable/types/types'
import {
  ProductsCategoryFilter,
  ProductsSearchFilter,
  ProductsTableSearchKey,
  ProductsTableSort
} from '../types/productTable'
import { getSearchProps, getAutocompleteProps } from '../../../../components/DynamicTable/utils'
import { formatNumber } from '../../../../utils/formatNumber'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'
import { Category } from '../../../../projectApi/CategoryCreation/Search/Categories/list'
import { BaseOption } from '../../../../components/Select/Select'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'

const SHOW_HIDDEN_FILTERS = [
  {
    text: 'SÍ',
    value: 'false'
  },
  {
    text: 'NO',
    value: 'true'
  }
]

export type ServiceTypeOption = BaseOption<string>
export type CategoryOption = BaseOption<number>

export type ProductsTableProps = {
  products: FormattedProduct[]
  loadingProducts: boolean
  pagination: Pagination
  actions: MenuAction<FormattedProduct>[]
  searchFilters: ProductsSearchFilter[]
  countryCode: string
  handleResetFilters: () => void
  handleSearch: (key: ProductsTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newCategoryFilter: ProductsCategoryFilter) => void
  categories: CategoryOption[]
  sort: ProductsTableSort
  categoryFilter: ProductsCategoryFilter
  searchCategories: {
    fetching: boolean
    list: Category[]
    error: string
  }
  searchCategoryId: (categoryName: string) => void
  handleByCategoryId: (categoryId: number) => void
  categoryId: number
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
  loadingProducts,
  products,
  pagination,
  actions,
  searchFilters,
  countryCode,
  handleSearch,
  handleCategoryFilter,
  searchCategories,
  categoryFilter,
  searchCategoryId,
  handleByCategoryId,
  categoryId
}) => {
  const formatCategoriesOptions = (options: Category[]): BaseOption<number>[] =>
    options.map((option) => ({ label: option.description, value: option.id }))

  const columns: Column<FormattedProduct>[] = [
    {
      label: 'ID',
      key: 'id',
      search: getSearchProps('id', 'ID producto', handleSearch, searchFilters)
    },
    {
      label: 'Categoría Final',
      key: 'categories',
      renderDataCell: ({ category }) => {
        return `${category.description}`
      },
      autocomplete: getAutocompleteProps(
        'categoryId',
        'Categoria',
        handleByCategoryId,
        categoryId,
        searchCategoryId,
        formatCategoriesOptions(searchCategories.list),
        searchCategories.fetching
      )
    },
    {
      label: 'Producto',
      key: 'description',
      search: getSearchProps('name', 'Nombre producto', handleSearch, searchFilters)
    },
    {
      label: 'Peso[kg]',
      key: 'weightInGr',
      renderDataCell: ({ weightInGr }) => {
        return formatNumber(weightInGr / 1000)
      }
    },
    {
      label: 'Medidas[cms]',
      key: 'product',
      renderDataCell: ({ lengthInCm, widthInCm, heightInCm }) => {
        const size = `${lengthInCm} x ${widthInCm} x ${heightInCm}`
        const sizeM3 = formatNumber((lengthInCm * widthInCm * heightInCm) / 1000000)
        return size + ' = ' + sizeM3 + ' m³'
      }
    }
  ]

  return (
    <>
      <DynamicTable
        columns={columns}
        loading={loadingProducts}
        rows={products}
        keyExtractor={(entity) => entity.id}
        actions={actions}
        pagination={pagination}
        customStrings={{
          emptyState: !countryCode
            ? 'Selecciona un país para ver la información de los productos'
            : 'No se encontraron productos'
        }}
      />
    </>
  )
}
