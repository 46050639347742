import { map } from 'ramda'
import { BaseOption } from '../../../../components/Select/Select'
import moment, { Moment } from 'moment'
import { TimeInterval, Timeslot } from './types'

type Option = BaseOption<number>

export function pad(string: string) {
  const pad = '00'
  return pad.substring(0, pad.length - string.length) + string
}

export const getFormattedOptions = map((option: Timeslot): Option => {
  const { id, hour, minute } = option

  return {
    value: id,
    label: hour + ':' + pad(minute.toString()),
    disabled: false
  }
})

export const getFormattedIntervalOptions = map((option: TimeInterval): Option => {
  const { id, start, end } = option

  return {
    value: id,
    label: start + ':' + pad('') + ' - ' + end + ':' + pad('')
  }
})

export const getFormatFirstTimeslot = (value: number, list: Timeslot[], datetime: Moment): Option => {
  const time = list.find((time: Timeslot) => time.id === value)

  if (time) return { value: time.id, label: time.hour + ':' + pad(time.minute.toString()) }
  else return { value, label: datetime.isValid() ? moment(datetime).format('HH:mm') : '' }
}

export const getFormatFirstTimeInterval = (value: number, list: TimeInterval[]): Option => {
  const time = list.find((time: TimeInterval) => time.id === value)

  if (time) return { value: time.id, label: time.start + ':' + pad('') + ' - ' + time.end + ':' + pad('') }
  else return { value, label: '' }
}
