import React from 'react'
import styles from './Dashboard.module.scss'
import Divisor from '@/components/Divisor'
import { DashboardMetrics } from '@/projectApi/TransactionHandler/Dashboard/metrics'
import { AnnualMetrics } from '@/projectApi/TransactionHandler/Dashboard/annualMetrics'
import { Moment } from 'moment'
import { SCREEN_SIZE } from '../../../common/constants'
import { DashboardDateRangeFilterKey } from '../types/Dashboard.types'
import AnnualMetricsComponent from './AnnualMetrics/AnnualMetrics'
import { DashboardHeaderMetrics } from './HeaderMetrics/DashboardHeaderMetrics'
import DashboardSideMetrics from './SideMetrics/DashboardSideMetrics'
import DateSelectorComponent from './DateSelector/DateSelector'
import { useLocalization } from '@/packages/localization'

type DashboardProps = {
  userName: string
  metrics: DashboardMetrics
  fetchingMetrics: boolean
  annualMetrics: AnnualMetrics
  fetchingAnnualMetrics: boolean
  handleDateRangePicker: (key: DashboardDateRangeFilterKey, startDate: Moment, endDate: Moment) => void
}

const DashboardComponent = ({
  userName,
  metrics,
  fetchingMetrics,
  annualMetrics,
  fetchingAnnualMetrics,
  handleDateRangePicker
}: DashboardProps) => {
  const { strings } = useLocalization()

  const onConfirmDate = (startDate: Moment, endDate: Moment) => {
    handleDateRangePicker('period', startDate, endDate)
  }

  const dateSelectorProps = {
    onConfirmDate
  }

  const metricsHeaderProps = {
    fetchingMetrics,
    metrics
  }

  const annualMetricsProps = {
    isFetching: fetchingAnnualMetrics,
    annualMetrics
  }

  const sideMetricsProps = {
    metrics
  }

  return (
    <section className={styles.dashboard}>
      <article className={styles.header}>
        <h1>
          {strings.dashboardViewScreen.header.title}
          {`, ${userName}.`}
        </h1>
        <DateSelectorComponent {...dateSelectorProps} />
      </article>
      <DashboardHeaderMetrics {...metricsHeaderProps} />
      <article className={styles.charts}>
        <DashboardSideMetrics {...sideMetricsProps} />
        <div className={styles.dividerContainer}>
          <Divisor orientation="vertical" breakPoint={SCREEN_SIZE.MOBILE} color="#dddddd" />
        </div>
        <AnnualMetricsComponent {...annualMetricsProps} />
      </article>
    </section>
  )
}

export default DashboardComponent
