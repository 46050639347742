import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import moment, { Moment } from 'moment'
import camelcaseKeys from 'camelcase-keys'

interface Paging {
  total: number
  limit: number
  offset: number
}

interface APIResult {
  id: number
  description: string
  created_at: string
  updated_at: string
  deleted_at: string
}

interface APIResponse {
  paging: Paging
  results: APIResult[]
}

type Dates = 'createdAt' | 'updatedAt' | 'deletedAt'

type MomentDates = Record<Dates, Moment>

export type DiscountReason = Omit<CamelCasedPropertiesDeep<APIResult>, Dates> & MomentDates

type Response = {
  reasons: DiscountReason[]
}

export function reasons(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/user-discount-reasons`)
    .then(({ data }: AxiosResponse<APIResponse>) => {
      const { results } = data

      return {
        reasons: camelcaseKeys(results, { deep: true }).map((result) => {
          return {
            ...result,
            createdAt: moment(result.createdAt),
            updatedAt: moment(result.updatedAt),
            deletedAt: moment(result.deletedAt)
          }
        })
      }
    })
    .catch((error) => {
      throw error
    })
}
