import { CamelCasedPropertiesDeep } from 'type-fest'
import { RemovalObject, UserRemoval } from '../../../projectApi/TransactionHandler/Removals/details'
import { Moment } from 'moment'

export type RemovalDetailsFormatted = {
  operationCountryCode: string
  objects: CamelCasedPropertiesDeep<RemovalObject>[]
  transportCost: number
  tollCostInCents: number
  additionalCost: number
  objectsM3: number
  discountCode: string
  baseInfo: {
    status: string
    fullAddress: string
    id: number
    user: CamelCasedPropertiesDeep<UserRemoval>
    createdAt: Moment | null
    datetime: Moment | null
  }
  minCost: number
}

export type SingleRemovalFormattedObject = CamelCasedPropertiesDeep<RemovalDetailsFormatted>

export type SingleRemovalState = {
  removalDetails: SingleRemovalFormattedObject
  loading: boolean
  error: string
}

export const GET_REMOVAL_DETAILS_REQUEST = 'REMOVALS/GET_REMOVAL_DETAILS_REQUEST'
export const GET_REMOVAL_DETAILS_SUCCESS = 'REMOVALS/GET_REMOVAL_DETAILS_SUCCESS'
export const GET_REMOVAL_DETAILS_FAILURE = 'REMOVALS/GET_REMOVAL_DETAILS_FAILURE'

export interface GetRemovalDetailsRequest {
  type: typeof GET_REMOVAL_DETAILS_REQUEST
}

export interface GetRemovalDetailsSuccess {
  type: typeof GET_REMOVAL_DETAILS_SUCCESS
  payload: {
    removalDetails: RemovalDetailsFormatted
  }
}

export interface GetRemovalDetailsFailure {
  type: typeof GET_REMOVAL_DETAILS_FAILURE
  payload: { error: string }
}
export type SingleRemovalDetailAction = GetRemovalDetailsRequest | GetRemovalDetailsSuccess | GetRemovalDetailsFailure
