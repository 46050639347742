import { CleanStates, GetTransactionDataAction } from './EditDepositView'

export interface CostState {
  fetchDiscount: {
    id: number
    transportDiscount: number
    depositDiscount: number
    valid: boolean
    loading: boolean
  }
  applyDiscount: boolean
  discountCode: string
  additionalCost: number
  additionalCostComment: string
  error: string
}

export enum FetchDiscountEnum {
  FETCH_DISCOUNT_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/FETCH_DISCOUNT_REQUEST',
  FETCH_DISCOUNT_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/FETCH_DISCOUNT_SUCCESS',
  FETCH_DISCOUNT_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/FETCH_DISCOUNT_FAILURE'
}

export interface FetchDiscountRequest {
  type: typeof FetchDiscountEnum.FETCH_DISCOUNT_REQUEST
}

export interface FetchDiscountSuccess {
  type: typeof FetchDiscountEnum.FETCH_DISCOUNT_SUCCESS
  payload: {
    id: number
    transport: number
    deposit: number
    general: number
    valid: boolean
  }
}

export interface FetchDiscountFailure {
  type: typeof FetchDiscountEnum.FETCH_DISCOUNT_FAILURE
}

export type FetchDiscountActions = FetchDiscountRequest | FetchDiscountSuccess | FetchDiscountFailure

export enum SetterDataDiscountEnum {
  SET_APPLY_DISCOUNT = 'DEPOSIT/EDIT_DEPOSIT/SET_APPLY_DISCOUNT',
  SET_DISCOUNT_CODE = 'DEPOSIT/EDIT_DEPOSIT/SET_DISCOUNT_CODE',
  SET_ADDITIONAL_COST = 'DEPOSIT/EDIT_DEPOSIT/SET_ADDITIONAL_COST',
  SET_ADDITIONAL_COST_COMMENT = 'DEPOSIT/EDIT_DEPOSIT/SET_ADDITIONAL_COST_COMMENT'
}

export interface SetApplyDiscount {
  type: typeof SetterDataDiscountEnum.SET_APPLY_DISCOUNT
  payload: { applyDiscount: boolean }
}

export interface SetDiscountCode {
  type: typeof SetterDataDiscountEnum.SET_DISCOUNT_CODE
  payload: { discountCode: string }
}

export interface SetAdditionalCost {
  type: typeof SetterDataDiscountEnum.SET_ADDITIONAL_COST
  payload: { additionalCost: number }
}

export interface SetAdditionalCostComment {
  type: typeof SetterDataDiscountEnum.SET_ADDITIONAL_COST_COMMENT
  payload: { additionalCostComment: string }
}

export type SetterDataDiscountActions =
  | SetApplyDiscount
  | SetDiscountCode
  | SetAdditionalCost
  | SetAdditionalCostComment

export type CostActions = FetchDiscountActions | SetterDataDiscountActions | GetTransactionDataAction | CleanStates
