import { STEP_OPTION } from '../constants'

export type ActionsModalState = {
  openModal: boolean
  loading: boolean
  error: string
  optionSelected: STEP_OPTION
  leadId: number
  leadFunnelState: string
  interestReasonId: number
}

export const SET_MODAL_OPEN = 'LEADS/ACTIONS_MODAL/SET_MODAL_OPEN'

export interface OpenActionsModal {
  type: typeof SET_MODAL_OPEN
  payload: { openModal: boolean; leadId?: number; leadFunnelState?: string; interestReasonId?: number }
}

export const SET_OPTION_SELECTED = 'LEADS/ACTIONS_MODAL/SET_OPTION_SELECTED'

export interface SetOptionSelected {
  type: typeof SET_OPTION_SELECTED
  payload: { option: STEP_OPTION }
}

export const UPDATED_LEAD = 'LEADS/ACTIONS_MODAL/EDIT_LEAD'
export const UPDATED_LEAD_SUCCESS = 'LEADS/ACTIONS_MODAL/EDIT_LEAD_SUCCESS'
export const UPDATED_LEAD_FAILURE = 'LEADS/ACTIONS_MODAL/UPDATED_LEAD_FAILURE'

export interface UpdatedLead {
  type: typeof UPDATED_LEAD
}

export interface UpdatedLeadSuccess {
  type: typeof UPDATED_LEAD_SUCCESS
  payload: { message: string }
}

export interface UpdatedLeadFailure {
  type: typeof UPDATED_LEAD_FAILURE
  payload: { message: string }
}

type UpdatedLeadActions = UpdatedLead | UpdatedLeadSuccess | UpdatedLeadFailure

export type StepModalActions = OpenActionsModal | SetOptionSelected | UpdatedLeadActions
