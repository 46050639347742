import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../api'
import { URL_BASE_COMPLAINTS } from '../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { convertToMoment, formatDates } from '../utils'
import { Moment } from 'moment'

export interface APIMain {
  content: APIResponse
}

export interface Response {
  total: number
  complaints: Complaint[]
}

export interface APIResponse {
  total: number
  complaints: APIComplaint[]
}
export interface APIComplaint {
  id: number
  created_at: string
  updated_at: string
  deleted_at: string
  complaint_type: string
  create_user_id: number
  assigned_user_id: number
  client_id: number
  status: string
  close_date: string
  operation_id: number
  reason_id: number
  description: string
  delayed: boolean
  days_open: number
  complaint_objects: APIComplaintObject[]
  complaint_histories: APIComplaintHistory[]
  priority: string
  photos: APIComplaintPhoto[] | null
  client_data: APIData
  create_user_data: APIData
  assigned_user_data: APIData
  close_reason?: number
}

export interface APIData {
  id: number
  name: string
  last_name: string
  email: string
}
export interface APIComplaintHistory {
  id: number
  created_at: string
  updated_at: string
  deleted_at: string
  complaint_id: number
  user_id: number
  status: string
  description: string
  priority: string
  assigned_user_id: number
  photos: APIComplaintHistoryPhoto[] | null
}

export interface APIComplaintHistoryPhoto {
  id: number
  created_at: string
  updated_at: string
  deleted_at: string
  complaint_history_id: number
  url: string
}
export interface APIComplaintObject {
  complaint_id: number
  object_id: number
  created_at: string
  updated_at: string
  deleted_at: string
  object: APIObject
}

export interface APIObject {
  id: number
  name: string
}

export interface APIComplaintPhoto {
  complaint_id: number
  url: string
}
export type Complaint = {
  id: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  complaintType: string
  createUserId: number
  assignedUserId: number
  clientId: number
  status: string
  closeDate: Moment | null
  operationId: number
  reasonId: number
  description: string
  delayed: boolean
  daysOpen: number
  complaintObjects: ComplaintObject[]
  complaintHistories: ComplaintHistory[]
  priority: string
  photos: ComplaintPhoto[] | null
  clientData: Data
  createUserData: Data
  assignedUserData: Data
  closeReason?: number
}

export interface Data {
  id: number
  name: string
  lastName: string
  email: string
}
export interface ComplaintHistory {
  id: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  complaintId: number
  userId: number
  status: string
  description: string
  priority: string
  assignedUserId: number
  assignedUserName?: string
  updatedByAgentId?: number
  updatedByAgentName?: string
  photos: ComplaintHistoryPhoto[] | null
}

export interface ComplaintHistoryPhoto {
  id: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  complaintHistoryId: number
  url: string
}
export interface ComplaintObject {
  complaintId: number
  objectId: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  object: APIObject
}

export interface ComplaintPhoto {
  complaintId: number
  url: string
}

export type ListParams = {
  limit?: number
  offset?: number
  order?: 'asc' | 'desc'
  column?: string
  id?: number
  userId?: string
  statuses?: string
  priorities?: string
  operationId?: string
  assignedUserId?: string
  complaintType?: string
  reasonId?: string
  closeReason?: string
  clientId?: string
  createdAtFrom?: Moment
  createdAtTo?: Moment
  updatedAtFrom?: Moment
  updatedAtTo?: Moment
  closedAtFrom?: Moment
  closedAtTo?: Moment
  withUsers?: boolean
  withObjects?: boolean
  delayed?: boolean
}

export function list(params: ListParams): Promise<Response> {
  const {
    limit,
    offset,
    column,
    createdAtFrom,
    createdAtTo,
    order,
    updatedAtFrom,
    updatedAtTo,
    closedAtFrom,
    closedAtTo,
    assignedUserId,
    clientId,
    id,
    operationId,
    userId,
    statuses,
    priorities,
    complaintType,
    reasonId,
    closeReason,
    withObjects,
    withUsers
  } = params
  const formattedParams = {
    limit,
    offset,
    column,
    order,
    id,
    user_id: userId,
    operation_id: operationId,
    assigned_user_id: assignedUserId,
    client_id: clientId,
    status: statuses,
    priority: priorities,
    complaint_type: complaintType,
    reason_id: reasonId,
    close_reason: closeReason,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo,
        updated_at_from: updatedAtFrom,
        updated_at_to: updatedAtTo,
        close_date_from: closedAtFrom,
        close_date_to: closedAtTo
      },
      format: 'YYYY-MM-DD'
    }),
    count: true,
    with_objects: withObjects,
    with_users: withUsers
  }

  return apiPrivate
    .get(`${URL_BASE_COMPLAINTS}/complaints`, { params: formattedParams })
    .then((response: AxiosResponse<APIMain>) => {
      const complaints = response.data.content.complaints
      return {
        complaints: Array.isArray(complaints)
          ? camelcaseKeys(complaints, { deep: true }).map((complaint) => ({
              ...complaint,
              createdAt: convertToMoment(complaint.createdAt),
              updatedAt: convertToMoment(complaint.updatedAt),
              deletedAt: convertToMoment(complaint.deletedAt),
              closeDate: convertToMoment(complaint.closeDate),
              complaintObjects: Array.isArray(complaint.complaintObjects)
                ? complaint.complaintObjects.map((complaintObject) => ({
                    ...complaintObject,
                    createdAt: convertToMoment(complaintObject.createdAt),
                    updatedAt: convertToMoment(complaintObject.updatedAt),
                    deletedAt: convertToMoment(complaintObject.deletedAt)
                  }))
                : [],
              complaintHistories: Array.isArray(complaint.complaintHistories)
                ? complaint.complaintHistories.map((complaintHistory) => ({
                    ...complaintHistory,
                    createdAt: convertToMoment(complaintHistory.createdAt),
                    updatedAt: convertToMoment(complaintHistory.updatedAt),
                    deletedAt: convertToMoment(complaintHistory.deletedAt),
                    photos: Array.isArray(complaintHistory.photos)
                      ? complaintHistory.photos.map((photo) => ({
                          ...photo,
                          createdAt: convertToMoment(photo.createdAt),
                          updatedAt: convertToMoment(photo.updatedAt),
                          deletedAt: convertToMoment(photo.deletedAt)
                        }))
                      : []
                  }))
                : []
            }))
          : [],
        total: response.data.content.total || 0
      }
    })
    .catch((error) => {
      throw error
    })
}
