import {
  Params,
  RemovalFilter,
  RemovalSearchFilter,
  RemovalSorter,
  RemovalTableTypes,
  SetFilter,
  SetPagination,
  SetSearchFilters,
  SetSorter
} from '../types/removals'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { PaginationConfig } from 'antd/es/table'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const actions = {
  cleanFilters: (): AppThunk => (dispatch) => {
    dispatch({ type: RemovalTableTypes.CLEAN_FILTERS })
    setTimeout(() => Emitter.emit(Events.RemovalsValidation.CLEAN_FILTERS), 50)
  },

  getRemovalsFromStore(): AppThunk {
    return (dispatch, getState) => {
      const params = getState().RemovalsValidation.table.params
      dispatch(actions.getRemovals(params))
    }
  },

  getRemovals(params: Params): AppThunk {
    return (dispatch) => {
      dispatch({ type: RemovalTableTypes.FETCH_REMOVALS })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + '/operation/removal', { params })
        .then((response) => {
          const removals = response.data.description.operations

          const quantity = response.data.description.quantity

          dispatch({
            type: RemovalTableTypes.FETCH_REMOVALS_SUCCESS,
            payload: {
              removals,
              params,
              quantity
            }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, RemovalTableTypes.FETCH_REMOVALS_FAIL, dispatch)
        })
    }
  },

  setSorter(sorter: RemovalSorter): SetSorter {
    return {
      type: RemovalTableTypes.SET_SORTER,
      payload: { sorter }
    }
  },

  setFilter(filter: RemovalFilter): SetFilter {
    return {
      type: RemovalTableTypes.SET_FILTER,
      payload: { filter }
    }
  },

  setPagination(pagination: PaginationConfig): SetPagination {
    return {
      type: RemovalTableTypes.SET_PAGINATION,
      payload: { pagination }
    }
  },

  setSearchFilters(searchFilters: RemovalSearchFilter[]): SetSearchFilters {
    return {
      type: RemovalTableTypes.SET_SEARCH_FILTERS,
      payload: { searchFilters }
    }
  }
}

export default actions
