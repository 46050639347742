import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/CountrySelector'
import Component from '../component/CountrySelector'
import { Permissions } from '../../../sections/UserLogged/permissions'
import { usePermissions } from '../../../sections/UserLogged/hooks/usePermissions'

const CountrySelector = () => {
  const dispatch = useDispatch()

  const userCountry = useSelector((state) => state.UserLogged.userProfile.country)
  const permissions = usePermissions()

  const countrySelectorActions = bindActionCreators(actions, dispatch)
  const countrySelectorState = useSelector((state) => state.CountrySelector)
  const { countries } = countrySelectorState

  useEffect(() => {
    if (permissions.length > 0 && !permissions.includes(Permissions.MostrarTodosLosPaises) && countries.length > 0)
      countrySelectorActions.SelectCountryFilter(
        countries.find((country) => country.code === userCountry) || countries[0]
      )
  }, [permissions, countries, userCountry])

  const props = {
    countrySelectorActions,
    countrySelectorState,
    userCountry,
    permissions
  }

  return <Component {...props} />
}

export default CountrySelector
