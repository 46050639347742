import { useDispatch, useSelector } from 'react-redux'
import { priceBandsTableActionCreators } from '../actionCreators/priceBandsTable'
import priceBandCreateActionCreators from '../actionCreators/create'
import { bindActionCreators } from 'redux'
import React, { useEffect } from 'react'
import { PriceBandsTable, PriceBandsTableProps } from '../components/priceBandsTable'
import { Pagination, PriceBandsSingleDateFilter, PriceBandsSingleDateFilterKey } from '../types/priceBandsTable'
import moment, { Moment } from 'moment'
import { Edit, Trash } from '../../../icons'
import { PriceBand } from '../../../projectApi/CategoryCreation/getPriceBands'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Permissions } from '../../UserLogged/permissions'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'

export const PriceBandsTableContainer = () => {
  const permissions = usePermissions()
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.PriceBands.table)
  const dispatch = useDispatch()

  const { getPriceBands } = bindActionCreators(priceBandsTableActionCreators, dispatch)
  const {
    setEditModalOpen,
    openModal: openDeleteModal,
    setCreateModalOpen
  } = bindActionCreators(priceBandCreateActionCreators, dispatch)

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetPriceBands = async (newParams: {
    pagination?: Pagination
    singleDateFilters?: PriceBandsSingleDateFilter[]
    silentLoading?: boolean
  }) => {
    if (!countryCode) return

    const priceBandsSuccess = await getPriceBands({
      pagination: newParams.pagination || tableState.pagination,
      singleDateFilters: newParams.singleDateFilters || tableState.singleDateFilters,
      silentLoading: newParams.silentLoading,
      countryId: countryCode
    })

    return priceBandsSuccess
  }

  useEffect(() => {
    handleGetPriceBands({ pagination: emptyPagination })
  }, [countryCode])

  useEvents(Events.PriceBands.PRICE_BAND_CREATED, () => handleGetPriceBands({ pagination: emptyPagination }))
  useEvents(Events.PriceBands.PRICE_BAND_UPDATED, () => handleGetPriceBands({ pagination: emptyPagination }))
  useEvents(Events.PriceBands.PRICE_BAND_DELETED, () => handleGetPriceBands({ pagination: emptyPagination }))

  const handleSingleDatePicker = (key: PriceBandsSingleDateFilterKey, date?: Moment) => {
    const singleDateFilters = tableState.singleDateFilters.map((filter) => {
      if (filter.key === key) {
        return { ...filter, date: date ?? null }
      }

      return filter
    })

    handleGetPriceBands({ singleDateFilters, pagination: emptyPagination })
  }

  const handleResetFilters = () => {
    const resetSingleDateFilters = tableState.singleDateFilters.map((filter) => ({ ...filter, date: null }))
    handleGetPriceBands({ singleDateFilters: resetSingleDateFilters, pagination: emptyPagination })
  }

  const handlePageChange = (page: number) => {
    handleGetPriceBands({ pagination: { ...tableState.pagination, page } })
  }

  const actions: MenuAction<PriceBand>[] = [
    {
      label: 'Editar Franja de precio',
      icon: <Edit />,
      onClick: (row: PriceBand) => {
        setEditModalOpen(row)
      },
      disabled: (row) =>
        !row ||
        !row.dateFrom ||
        row?.dateFrom.isSameOrBefore(moment()) ||
        !permissions.includes(Permissions.EditarBandaDePrecio)
    },
    {
      label: 'Eliminar Franja de precio',
      icon: <Trash />,
      onClick: (row: PriceBand) => openDeleteModal(row.id),
      disabled: (row) =>
        !row ||
        !row.dateFrom ||
        row?.dateFrom.isSameOrBefore(moment()) ||
        !permissions.includes(Permissions.EliminarBandaDePrecio)
    }
  ]

  const props: PriceBandsTableProps = {
    priceBands: tableState.priceBands,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    loading: tableState.loading,
    singleDateFilters: tableState.singleDateFilters,
    handleResetFilters,
    handleSingleDatePicker,
    noCountrySelected: !countryCode,
    actions,
    setCreateModalOpen,
    permissions
  }

  return <PriceBandsTable {...props} />
}
