import { Moment } from 'moment'

export interface EditDateServiceState {
  operationId: number
  newDateService: Moment
  currentDateTime: Moment
  reason: string
  modalOpen: boolean
  loadingSave: boolean
}

export enum EditServiceDateType {
  EDIT_SERVICE_DATE_REQUEST = 'OPERATION/DATE_SERVICE/EDIT_SERVICE_DATE_REQUEST',
  EDIT_SERVICE_DATE_SUCCESS = 'OPERATION/DATE_SERVICE/EDIT_SERVICE_DATE_SUCCESS',
  EDIT_SERVICE_DATE_FAILURE = 'OPERATION/DATE_SERVICE/EDIT_SERVICE_DATE_FAILURE'
}

export interface EditServiceDateRequest {
  type: typeof EditServiceDateType.EDIT_SERVICE_DATE_REQUEST
}

export interface EditServiceDateSuccess {
  type: typeof EditServiceDateType.EDIT_SERVICE_DATE_SUCCESS
}

export interface EditServiceDateFailure {
  type: typeof EditServiceDateType.EDIT_SERVICE_DATE_FAILURE
}

export type EditServiceDateActions = EditServiceDateRequest | EditServiceDateSuccess | EditServiceDateFailure

export enum ModalDataTypes {
  SET_MODAL_OPEN = 'OPERATION/DATE_SERVICE/SET_MODAL_OPEN',
  SET_REASON = 'OPERATION/DATE_SERVICE/SET_REASON',
  SET_NEW_DATE_SERVICE = 'OPERATION/DATE_SERVICE/SET_NEW_DATE_SERVICE'
}

export interface SetModalOpen {
  type: typeof ModalDataTypes.SET_MODAL_OPEN
  payload: {
    isOpen: boolean
    operationId?: number
    currentDateTime?: string
  }
}

export interface SetReason {
  type: typeof ModalDataTypes.SET_REASON
  payload: { reason: string }
}

export interface SetNewDateService {
  type: typeof ModalDataTypes.SET_NEW_DATE_SERVICE
  payload: { newDateService: Moment }
}

export type ModalDataActions = SetModalOpen | SetReason | SetNewDateService

export type EditDateServiceActions = EditServiceDateActions | ModalDataActions
