import React from 'react'
import { DatePicker, Modal } from 'antd'
import styles from './operationDates.module.scss'
import moment, { Moment } from 'moment'
import { CreateDateParams } from '../../../projectApi/Timeslot/OperationCalendar/SpecificDates/create'
import SelectCheckbox from './SelectCheckbox/SelectCheckbox'
import { OperationTypeValues } from '../types/common'

type CreateOperationModalProps = {
  openCreateDateModal: (isCreateModalOpen: boolean) => void
  isCreateModalOpen: boolean
  setSelectedCreateDate: (selectedDate: Moment | null) => void
  selectedCreateDate: Moment | null
  createDate: (baseBody: CreateDateParams) => void
  selectedOperations: OperationTypeValues[]
  setSelectedOperations: ({ selectedOperations }: { selectedOperations: OperationTypeValues[] }) => void
  availableOperations: OperationTypeValues[]
  setAvailableOperations: ({ availableOperations }: { availableOperations: OperationTypeValues[] | [] }) => void
}

const CreateDateModal = ({
  openCreateDateModal,
  isCreateModalOpen,
  setSelectedCreateDate,
  createDate,
  selectedCreateDate,
  setSelectedOperations,
  selectedOperations,
  availableOperations,
  setAvailableOperations
}: CreateOperationModalProps) => {
  const disabledDate = (current: Moment | null): boolean =>
    current ? current < moment().startOf('day') || moment(current).day() === 0 : false

  const handleCreateDate = async () => {
    for (const operationType of Object.values(OperationTypeValues)) {
      const available = selectedOperations.includes(operationType)

      if (available) {
        const operationCreatePayload = {
          date: selectedCreateDate,
          operationType
        }

        createDate(operationCreatePayload)
      }
    }
    handleCloseModal()
  }

  const disabledCreateButton = !selectedCreateDate || selectedOperations.length === 0
  const handleCloseModal = () => {
    openCreateDateModal(false)
    setSelectedCreateDate(null)
    setAvailableOperations({ availableOperations: [] })
  }

  const selectedCheckboxProps = {
    setSelectedOperations,
    selectedOperations,
    availableOperations
  }

  return (
    <Modal
      onCancel={handleCloseModal}
      title="Deshabilitar operación para fecha específica"
      visible={isCreateModalOpen}
      okText="Deshabilitar"
      cancelText="Cancelar"
      width="max-content"
      onOk={handleCreateDate}
      okButtonProps={{ disabled: disabledCreateButton, className: styles.ModalButtonOk }}
      cancelButtonProps={{ className: styles.ModalButtonCancel }}
      destroyOnClose>
      <section className={styles.ModalCreateSection}>
        <article className={styles.ModalOptionWrapper}>
          <label className={styles.label}>
            Fecha de operación:
            <DatePicker
              className={styles.DatePicker}
              placeholder="Seleccionar día"
              popupStyle={{ zIndex: 9999 }}
              disabledDate={disabledDate}
              allowClear={false}
              format="DD/MM/YYYY"
              value={selectedCreateDate}
              onChange={(date) => setSelectedCreateDate(date)}
            />
          </label>
        </article>
        <article className={styles.ModalOptionWrapper}>
          <label className={styles.SelectOperation}>
            Tipo de operación:
            <SelectCheckbox {...selectedCheckboxProps} />
          </label>
        </article>
      </section>
    </Modal>
  )
}

export default CreateDateModal
