import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'

export type UserInfoToDisplay = {
  name: string
  email: string
  phone: string
  source: string
}

const getUserInfoToDisplay = (mode: string, selectedProspect: Prospect | null): UserInfoToDisplay => {
  return {
    name: `${selectedProspect?.id} - ${selectedProspect?.name}`,
    email: selectedProspect?.email || '',
    phone: selectedProspect?.phone || '',
    source: selectedProspect?.source.spanishDisplay || ''
  }
}

export default getUserInfoToDisplay
