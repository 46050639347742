import React from 'react'
import { Checkbox, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import FeatureManagerActionCreators from './actionCreators'
import { DynamicTable } from '../../components/DynamicTable'
import { Column } from '../../components/DynamicTable/types/types'
import { Feature } from './types'
import { KeyModifier, Keys, useKeyPress } from '../../utils/hooks/useKeyPress'

const FeatureManagerPopup = () => {
  const dispatch = useDispatch()
  const { setFeatureActive, setPopupOpen } = bindActionCreators(FeatureManagerActionCreators, dispatch)
  const { popupOpen, features } = useSelector((root) => root.FeatureManager)

  useKeyPress({
    key: Keys.I,
    modifiers: [KeyModifier.CTRL, KeyModifier.ALT, KeyModifier.SHIFT],
    callback: () => setPopupOpen(true)
  })

  const columns: Column<Feature>[] = [
    {
      key: 'description',
      label: 'Funcionalidad',
      alignment: 'left'
    },
    {
      key: 'active',
      label: '',
      maxWidth: 'min-content',
      renderDataCell: ({ active, type, deprecated }) => (
        <Checkbox checked={active} disabled={deprecated} onChange={() => setFeatureActive(type)} />
      )
    }
  ]

  return (
    <Modal
      centered
      closable={false}
      visible={popupOpen}
      style={{ maxWidth: 500 }}
      onCancel={() => setPopupOpen(false)}
      footer={null}>
      <div>
        <DynamicTable columns={columns} rows={features} keyExtractor={(entity) => entity.type} />
      </div>
    </Modal>
  )
}

export default FeatureManagerPopup
