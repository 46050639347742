import { CountryIdCode } from './../../../../components/CountrySelector/constants/constants'
/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { Moment } from 'moment'
import { formatDates } from '../../../utils'

export enum MetricsLabels {
  TODAY = 'Hoy',
  TOMORROW = 'Mañana',
  WITH_CONFLICT = 'En conflicto',
  WITHOUT_ASSIGN = 'Sin asignar'
}

interface APIMetrics {
  transaction_with_conflict: {
    total_m3_by_object_with_conflict: number
    total_object_with_conflict: number
    total_transaction: number
    total_transaction_with_conflict: number
    transaction_date: string
  }
  transaction_with_out_assign: {
    total_m3_by_object_with_out_trip: number
    total_object_with_out_trip: number
    total_transaction: number
    total_transaction_with_out_trip: number
  }
  transaction_with_trip_today: {
    total_m3_by_object: number
    total_object: number
    total_transaction: number
    total_transaction_with_trip: number
    transaction_date: string
  }
  transaction_with_trip_tomorrow: {
    total_m3_by_object: number
    total_object: number
    total_transaction: number
    total_transaction_with_trip: number
    transaction_date: string
  }
}

export type Metrics = {
  transactionsWithConflict: {
    title: MetricsLabels
    totalObjectsM3: number
    totalObjects: number
    totalTransactions: number
    totalTransactionsWithConflicts: number
    totalTransactionsWithTrip?: number
  }
  transactionWithoutAssign: {
    title: MetricsLabels
    totalObjectsM3: number
    totalObjects: number
    totalTransactions: number
    totalTransactionsWithTrip?: number
    totalTransactionsWithConflicts?: number
  }
  transactionsTripToday: {
    title: MetricsLabels
    totalObjectsM3: number
    totalObjects: number
    totalTransactions: number
    totalTransactionsWithTrip: number
    totalTransactionsWithConflicts?: number
  }
  transactionsTripTomorrow: {
    title: MetricsLabels
    totalObjectsM3: number
    totalObjects: number
    totalTransactions: number
    totalTransactionsWithTrip: number
    totalTransactionsWithConflicts?: number
  }
}

type APIResponse = APIMetrics

type Response = Metrics

type ListParams = {
  date: Moment
  countryCode: CountryIdCode
}

export function list(params: ListParams): Promise<Response> {
  const formatParams = {
    country: params.countryCode,
    ...formatDates({
      format: 'YYYY-MM-DDTHH:mm:ssZ',
      dates: {
        date: params.date
      }
    })
  }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/transactions/metrics`, { params: formatParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const resp = response.data
      if (!resp) throw new Error('No se encontraron las metricas')

      const camelResponse: Response = {
        transactionsTripToday: {
          title: MetricsLabels.TODAY,
          totalObjects: resp.transaction_with_trip_today.total_object,
          totalObjectsM3: resp.transaction_with_trip_today.total_m3_by_object,
          totalTransactions: resp.transaction_with_trip_today.total_transaction,
          totalTransactionsWithTrip: resp.transaction_with_trip_today.total_transaction_with_trip
        },
        transactionsTripTomorrow: {
          title: MetricsLabels.TOMORROW,
          totalObjects: resp.transaction_with_trip_tomorrow.total_object,
          totalObjectsM3: resp.transaction_with_trip_tomorrow.total_m3_by_object,
          totalTransactions: resp.transaction_with_trip_tomorrow.total_transaction,
          totalTransactionsWithTrip: resp.transaction_with_trip_tomorrow.total_transaction_with_trip
        },
        transactionsWithConflict: {
          title: MetricsLabels.WITH_CONFLICT,
          totalObjects: resp.transaction_with_conflict.total_object_with_conflict,
          totalObjectsM3: resp.transaction_with_conflict.total_m3_by_object_with_conflict,
          totalTransactions: resp.transaction_with_conflict.total_transaction,
          totalTransactionsWithConflicts: resp.transaction_with_conflict.total_transaction_with_conflict
        },
        transactionWithoutAssign: {
          title: MetricsLabels.WITHOUT_ASSIGN,
          totalObjects: resp.transaction_with_out_assign.total_object_with_out_trip,
          totalObjectsM3: resp.transaction_with_out_assign.total_m3_by_object_with_out_trip,
          totalTransactions: resp.transaction_with_out_assign.total_transaction_with_out_trip
        }
      }

      return camelResponse
    })
}
