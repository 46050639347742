import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

type vehicleDeleteParams = {
  vehicleId: number
}

export function deleteIt({ vehicleId }: vehicleDeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_TRIPS}/vehicles/${vehicleId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
