import { Reducer } from 'redux'
import {
  DiscountDaysActions,
  DiscountDaysState,
  DiscountDaysTableTypes,
  GetDiscountDaysTypes
} from '../types/discountDays'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const initialState: DiscountDaysState = {
  categoryFilters: {
    weekday: []
  },
  discounts: [],
  fetchingDiscounts: false,
  pagination: {
    page: 1,
    total: 0,
    pageSize: 10
  },
  sort: {
    field: 'created_at',
    direction: SortDirection.DESC
  },
  dateRangeFilters: [{ key: 'createdAt', startDate: null, endDate: null }]
}

const discountDatesReducer: Reducer<DiscountDaysState, DiscountDaysActions> = (
  state: DiscountDaysState = initialState,
  action: DiscountDaysActions
): DiscountDaysState => {
  switch (action.type) {
    case DiscountDaysTableTypes.RESET_FILTERS:
      return initialState
    case GetDiscountDaysTypes.GET_DISCOUNT_DAYS_REQUEST:
      return {
        ...state,
        fetchingDiscounts: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        categoryFilters: action.payload.categoryFilters || initialState.categoryFilters,
        sort: action.payload.sort || initialState.sort,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters
      }
    case GetDiscountDaysTypes.GET_DISCOUNT_DAYS_SUCCESS:
      return {
        ...state,
        fetchingDiscounts: false,
        discounts: action.payload.discounts,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetDiscountDaysTypes.GET_DISCOUNT_DAYS_FAILURE:
      return {
        ...state,
        fetchingDiscounts: false
      }
    default:
      return state
  }
}

export default discountDatesReducer
