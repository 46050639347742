import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

import {
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CancelSubscriptionRequest,
  CancelSubscriptionSuccess,
  SetModalCancelSubscription,
  SET_MODAL_CANCEL_SUBSCRIPTION
} from '../types/cancelSubscriptionModal'

export const CancelSubscriptionModalActionCreator = {
  cancelSubscription:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: CancelSubscriptionRequest = {
        type: CANCEL_SUBSCRIPTION_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Cancelando abono...')

      try {
        await API.TransactionHandler.Billings.anulled(billingId)

        const success: CancelSubscriptionSuccess = {
          type: CANCEL_SUBSCRIPTION_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, {
          render: `¡Abono cancelado con éxito! `
        })
      } catch (error) {
        setToastErrorUpdate(toastId, { render: handleErrorsWithAction(error, CANCEL_SUBSCRIPTION_FAILURE, dispatch) })
      }
    },
  openCancelSubscriptionModal: ({
    modalOpen,
    billingId
  }: {
    modalOpen: boolean
    billingId?: number
  }): SetModalCancelSubscription => ({
    type: SET_MODAL_CANCEL_SUBSCRIPTION,
    payload: { billingId, modalOpen }
  })
}

export default CancelSubscriptionModalActionCreator
