import { Reducer } from 'redux'
import {
  DepositListActions,
  GetCorporativeDepositListTypes,
  CorporativeDepositListStateType,
  RESET_FILTERS,
  GetCorporativeDepositMetricsTypes
} from '../types/depositsTable'

export const PAGE_SIZE = 10

const initialState: CorporativeDepositListStateType = {
  fetchingDeposits: false,
  fetchingMetrics: false,
  deposits: [],
  depositsMetrics: {
    volumeInCm: 0,
    maxVolumeInCm: 0,
    weightInGr: 0,
    maxWeightInGr: 0,
    quantityObjects: 0,
    quantityLocations: 0,
    occupancyRate: 0,
    loadRate: 0,
    totalObjects: 0,
    totalM3: 0
  },
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  searchFilters: [
    { key: 'depositId', text: '' },
    { key: 'name', text: '' }
  ]
}

const CorporativeDepositListReducer: Reducer<CorporativeDepositListStateType, DepositListActions> = (
  state: CorporativeDepositListStateType = initialState,
  action: DepositListActions
): CorporativeDepositListStateType => {
  switch (action.type) {
    case RESET_FILTERS:
      return initialState
    case GetCorporativeDepositListTypes.GET_DEPOSIT_REQUEST:
      return {
        ...state,
        fetchingDeposits: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GetCorporativeDepositListTypes.GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        fetchingDeposits: false,
        deposits: action.payload.deposits,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetCorporativeDepositListTypes.GET_DEPOSIT_FAILURE:
      return {
        ...state,
        fetchingDeposits: false
      }
    case GetCorporativeDepositMetricsTypes.GET_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetCorporativeDepositMetricsTypes.GET_METRICS_SUCCESS:
      return {
        ...state,
        depositsMetrics: action.payload.depositsMetrics,
        fetchingMetrics: false
      }
    case GetCorporativeDepositMetricsTypes.GET_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default CorporativeDepositListReducer
