import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export function sendReminder(billing_id: number): Promise<void> {
  const params = { billing_id }

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/debts/send-remainder-user-debt`, params)
    .then((response: AxiosResponse) => {})
    .catch((err) => {
      throw err
    })
}
