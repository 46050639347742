import { Reducer } from 'redux'
import {
  ADD_CARD_FAILURE,
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  CardModalState,
  CardsModalActions,
  SET_CARD_NUMBER,
  SET_CARD_OWNER_NAME,
  SET_CVC,
  SET_DOCUMENT_NUMBER,
  SET_DOCUMENT_TYPE,
  SET_EXPIRATION_DATE,
  SET_INPUT_ERROR,
  SET_OPEN
} from '../types/cardModal'

const initialCardsModal: CardModalState = {
  open: false,
  loading: false,
  card: {
    cardNumber: {
      value: '',
      error: false,
      errorMessage: 'string'
    },
    expirationDate: {
      value: '',
      error: false,
      errorMessage: 'string'
    },
    CVC: {
      value: '',
      error: false,
      errorMessage: 'string'
    },
    cardOwnerName: {
      value: '',
      error: false,
      errorMessage: 'string'
    },
    documentNumber: {
      value: '',
      error: false,
      errorMessage: 'string'
    },
    documentType: 'string'
  }
}

const cardsModalReducer: Reducer<CardModalState, CardsModalActions> = (
  state = initialCardsModal,
  action
): CardModalState => {
  switch (action.type) {
    case SET_OPEN:
      return action.payload.open
        ? {
            ...initialCardsModal,
            open: action.payload.open
          }
        : initialCardsModal
    case SET_CARD_NUMBER:
      return {
        ...state,
        card: { ...state.card, cardNumber: { ...initialCardsModal.card.cardNumber, value: action.payload.cardNumber } }
      }
    case SET_EXPIRATION_DATE:
      return {
        ...state,
        card: {
          ...state.card,
          expirationDate: { ...initialCardsModal.card.expirationDate, value: action.payload.expirationDate }
        }
      }
    case SET_CVC:
      return { ...state, card: { ...state.card, CVC: { ...initialCardsModal.card.CVC, value: action.payload.CVC } } }
    case SET_CARD_OWNER_NAME:
      return {
        ...state,
        card: {
          ...state.card,
          cardOwnerName: { ...initialCardsModal.card.cardOwnerName, value: action.payload.cardOwnerName }
        }
      }
    case SET_DOCUMENT_TYPE:
      return {
        ...state,
        card: {
          ...state.card,
          documentNumber: initialCardsModal.card.documentNumber,
          documentType: action.payload.documentType
        }
      }
    case SET_DOCUMENT_NUMBER:
      return {
        ...state,
        card: {
          ...state.card,
          documentNumber: { ...initialCardsModal.card.documentNumber, value: action.payload.documentNumber }
        }
      }
    case SET_INPUT_ERROR: {
      const { input, errorMessage } = action.payload
      return {
        ...state,
        card: {
          ...state.card,
          [input]: { ...state.card[input], error: true, errorMessage }
        }
      }
    }
    case ADD_CARD_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case ADD_CARD_SUCCESS: {
      return initialCardsModal
    }
    case ADD_CARD_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}

export default cardsModalReducer
