/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import snakecaseKeys from 'snakecase-keys'
import { TaxCondition } from '../../../sections/Users/Users.constants'

interface APIUserBillingInformation {
  id: number
  identification_type: string
  identification_code: string
  identification_name_client: string
  billing_address: string
  user_id: number
  tax_condition: TaxCondition
  sale_condition: number
  email: string
  city: string
  province: string
  postal_code: string
  billing_enabled: boolean
  authorization_enabled: boolean
}

export type UserBillingInformation = CamelCasedPropertiesDeep<APIUserBillingInformation>

interface APIResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  results: APIUserBillingInformation[]
}

type Response = {
  results: UserBillingInformation[]
  total: number
}

interface GetBillingByTransactionParams {
  userId: number
}

export async function getByUserId(params: GetBillingByTransactionParams): Promise<Response> {
  const formattedParams = snakecaseKeys({ ...params }, { deep: true })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/user-billing-information/search`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse: Response = {
        total: response.data.paging.total,
        results: camelcaseKeys(response.data.results, { deep: true })
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
