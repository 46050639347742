import React from 'react'
import clsx from 'clsx'
import styles from './Divisor.module.scss'

export type DivisorOrientation = 'horizontal' | 'vertical'

type DivisorProps = {
  orientation: DivisorOrientation
  size?: string
  color?: string
  margin?: string
  customClassName?: string
  colorType?: 'gradient' | 'solid'
  breakPoint?: number
}

const orientationToClassName = {
  horizontal: styles.horizontal,
  vertical: styles.vertical
}

export const Divisor: React.FC<DivisorProps> = ({
  orientation,
  size = '1px',
  color = '#EAEAEA',
  margin = '0',
  customClassName,
  colorType = 'solid',
  breakPoint
}) => {
  const style = {
    '--divisor-color': color,
    '--divisor-size': size,
    '--divisor-margin': margin
  } as React.CSSProperties

  const divisorClass = clsx(
    styles.divisor,
    orientationToClassName[orientation],
    breakPoint && styles[`responsive-${breakPoint}`],
    colorType === 'gradient' && styles.gradient,
    customClassName
  )

  return <div className={divisorClass} style={style} />
}

export default Divisor
