import React, { useMemo } from 'react'
import objectsActions from '../actions/objects'
import { ObjectsState } from '../reducers/objects'
import { Checkbox, Input, Pagination, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import ObjectToRemoveComponent from './objectsToRemove/ObjectToRemove'
import { ObjectResponse } from '../types/objects'
import { filterObjectsForRemoval } from '../utils/objects'
import { ALL, OBJECT_STATUS_FILTER_FOR_REMOVALS } from '../../Objects/constants/objectStatus'
import { getOptionValue } from '../../../common/operations/utils'
import Select from '../../../components/Select'
import CarouselModal from '../../../components/CarouselModal/containers/CarouselModal'
import WarningModal from './warningModal'
import { BaseOption } from '../../../components/Select/Select'
import { ObjectFromRemoval } from '../../../projectApi/TransactionHandler/Operation/Removals/common'

const styles = {
  results: {
    display: 'flex',
    flexDirection: 'column' as const,
    paddingTop: 0,
    minHeight: 360,
    height: 'calc(100vh - 430px)',
    overflowY: 'auto' as const,
    overflowX: 'auto' as const
  },
  containerList: {
    display: 'flex',
    flexDirection: 'column' as const,
    paddingTop: 0
  },
  notObjectFound: {
    paddingTop: 16,
    textAlign: 'center' as const,
    fontWeight: 'bold' as const,
    fontSize: '14px'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center' as const,
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 16
  },
  filtersContainer: {
    display: 'flex'
  },
  searchInput: {
    width: 300,
    marginLeft: 20
  },
  pagination: {
    marginTop: 16,
    marginLeft: 'auto'
  },
  select: {
    width: 140
  }
}

const PAGE_SIZE = 20

const filterObjects = (objects: ObjectResponse[], search: string) =>
  objects.filter(
    ({ Name, Product, RealID }) =>
      Name.toLowerCase().includes(search.toLowerCase()) ||
      Product.Description.toLowerCase().includes(search.toLowerCase()) ||
      String(RealID) === search
  )

const filterObjectsByStatus = (objects: ObjectResponse[], objectsFromRemoval: ObjectFromRemoval[], status: number) => {
  const addObjectsFromRemoval = objects.filter((objResponse) => {
    // @ts-ignore Type-fest library is wrongly formatting data to camel. TS and type-fest update needed
    const objExist = objectsFromRemoval.find((objFromRemoval) => objResponse.RealID === objFromRemoval.objectId)
    return objExist
  })

  const filterStatus = objects.filter(({ ObjectStatus }) => ObjectStatus.ID === status || status === ALL.numberValue)

  return Array.from(new Set([...filterStatus, ...addObjectsFromRemoval]))
}

export interface ObjectsToRemoveSelectionProps {
  objectsActions: typeof objectsActions
  objectsState: ObjectsState
  toggleCarrouselOpen: (images: string[]) => void
}

type Option = BaseOption<number>
const ObjectsToRemoveSelection = (props: ObjectsToRemoveSelectionProps) => {
  const { objectsActions, objectsState, toggleCarrouselOpen } = props
  const { objects, objectsToRemove, loading, warning, objectsFromRemoval } = objectsState

  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<number>(1)
  const [selected, setSelected] = React.useState<Option | null>(null)

  const notAvailable = status > 1

  const filteredObjectsBySearch = useMemo(() => filterObjects(objects, search), [objects, search, page])

  const filteredObjectsByStatus = useMemo(
    () => filterObjectsByStatus(filteredObjectsBySearch, objectsFromRemoval, status),
    [filteredObjectsBySearch, status, page, objectsFromRemoval]
  )
  const filteredObjectsByPage = useMemo(
    () => filteredObjectsByStatus.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
    [filteredObjectsByStatus]
  )

  const checked = useMemo(
    () => filterObjectsForRemoval(objects).length === Object.values(objectsToRemove).length,
    [objects, objectsToRemove]
  )
  const indeterminate = useMemo(() => Object.values(objectsToRemove).length > 0 && !checked, [objectsToRemove])
  const statusOptions = OBJECT_STATUS_FILTER_FOR_REMOVALS.map((object) => ({
    value: object.numberValue,
    label: object.text
  }))

  const onSearchChange = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  const onObjectStatusChange = (value: number) => {
    setPage(1)
    setStatus(value)
  }

  const onPageChange = (page: number) => setPage(page)

  const onCheckChange = (object: ObjectResponse) => (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      objectsActions.addObjectToRemove(object)
    } else {
      objectsActions.undoObjectToRemove(object)
    }
  }
  const onSelectAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      objectsActions.addAllObjectsToRemove()
      if (status === 0) {
        objectsActions.openWarningModal(true)
      }
    } else {
      objectsActions.undoAllObjectsToRemove()
    }
  }
  return (
    <div style={styles.containerList}>
      <div style={styles.searchContainer}>
        <div style={styles.filtersContainer}>
          <Select
            placeholder="Elegir estado"
            options={statusOptions}
            selected={selected}
            onSelect={(option) => {
              onObjectStatusChange(getOptionValue(option))
              setSelected(option)
            }}
          />
          <Input.Search
            allowClear
            style={styles.searchInput}
            placeholder="Buscar objetos por ID o nombre"
            onSearch={onSearchChange}
            enterButton
          />
        </div>
        <Checkbox indeterminate={indeterminate} checked={checked} onChange={onSelectAllChange} disabled={notAvailable}>
          Todos
        </Checkbox>
      </div>
      <div style={styles.results}>
        {loading && <Spin />}
        {filteredObjectsByPage.map((object, index) => (
          <ObjectToRemoveComponent
            onChange={onCheckChange}
            index={index}
            object={object}
            toggleCarrouselOpen={toggleCarrouselOpen}
            key={object.RealID}
            objectsToRemove={objectsToRemove}
          />
        ))}
        {filteredObjectsByPage.length === 0 && !loading && (
          <span style={styles.notObjectFound}>No se encontraron objetos. Realice otra búsqueda</span>
        )}
      </div>
      {filteredObjectsByStatus.length > PAGE_SIZE && (
        <Pagination
          style={styles.pagination}
          current={page}
          pageSize={PAGE_SIZE}
          total={filteredObjectsByStatus.length}
          onChange={onPageChange}
        />
      )}
      <CarouselModal />
      <WarningModal warning={warning} objectsState={objectsState} objectsActions={objectsActions} />
    </div>
  )
}

export default ObjectsToRemoveSelection
