import { list } from './list'
import { search } from './search'
import { reject } from './reject'
import { acceptProposal } from './accept'
import { counterProposal } from './counterProposal'
import { getCsv } from './getCsv'
import { notification } from './notification'
import { confirm } from './confirm'
import { cancelAsSG } from './cancel'

export const APISalesRequest = {
  getCsv,
  list,
  search,
  reject,
  acceptProposal,
  counterProposal,
  notification,
  confirm,
  cancel: cancelAsSG
}
