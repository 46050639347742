import React, { useEffect } from 'react'
import UsersTable from '../components/usersTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import UsersActionsCreator from '../actions/users'
import UsersABMActionsCreator from '../actions/userABM'
import { Pagination } from '../../../../utils/searchFilterUtils'
import { TableActionBar } from '../../../../components/TableActionBar'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { Edit, Trash } from '../../../../icons'
import { UsersSearchFilter, UsersTableSearchKey } from '../types/usersTable'
import { SatelliteAccount } from '../../../../projectApi/AccountManager/Satellite/corporate'
import { Events } from '../../../../utils/eventEmitter/events'
import { useEvents } from '../../../../utils/eventEmitter'
import UserInviteModal from '../containers/UserInviteModal'
import UserDeleteModal from '../containers/UserDeleteModal'

const UsersTableContainer = () => {
  const dispatch = useDispatch()

  const { getAssociatedAccounts, resetFilters } = bindActionCreators(UsersActionsCreator, dispatch)
  const { openModalInvite, openDeleteUserModal, openEditUserModal } = bindActionCreators(
    UsersABMActionsCreator,
    dispatch
  )

  const { pagination, searchFilters, isLoading, associatedUsers } = useSelector((state) => state.CorporateUsers)

  useEffect(() => {
    getAssociatedAccounts({ pagination })
  }, [])

  useEvents(Events.CorporateDeposits.CLEAN_FILTERS, () =>
    getAssociatedAccounts({ pagination: { ...pagination, page: 1 } })
  )

  const handleGetUsers = (newParams: { pagination?: Pagination; searchFilters?: UsersSearchFilter[] }) => {
    getAssociatedAccounts({
      pagination: newParams.pagination || pagination,
      searchFilters: newParams.searchFilters || searchFilters
    })
  }

  const handlePageChange = (newPage: number) => {
    getAssociatedAccounts({ pagination: { ...pagination, page: newPage } })
  }

  const handleSearch = (key: UsersTableSearchKey, newValue: string) => {
    const newSearchFilters = searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetUsers({ searchFilters: newSearchFilters, pagination: { ...pagination, page: 1 } })
  }

  const menuActions: MenuAction<SatelliteAccount>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (satelliteUser) => openEditUserModal(satelliteUser, true)
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (satelliteUser) => openDeleteUserModal({ isDeleteModalOpen: true, selectedUser: satelliteUser })
    }
  ]

  const props = {
    isLoading,
    associatedUsers,
    searchFilters,
    pagination: {
      currentPage: pagination.page,
      pageSize: pagination.pageSize,
      total: pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    actions: menuActions
  }

  return (
    <>
      <TableActionBar>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={resetFilters} />
        <TableActionBar.ButtonAction
          titleButton="Invitar"
          onClickButton={() => openModalInvite({ isAddModalOpen: true })}
        />
      </TableActionBar>
      <UsersTable {...props} />
      <UserInviteModal />
      <UserDeleteModal />
    </>
  )
}

export default UsersTableContainer
