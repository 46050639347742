import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/locationsObjects'
import Component from '../components/locationObjects'
import { useParams } from 'react-router-dom'
import { eqProps, equals, isEmpty } from 'ramda'
import { getNewFilterParams } from '../../../utils/formatParams'
import QRActions from '../../../components/QRModal/QRModalActions'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const locationsActions = bindActionCreators(actions, dispatch)
  const QRModalactions = bindActionCreators(QRActions, dispatch)

  const locationObjects = useSelector((state) => state.Locations.locationObjects)
  const { objects, params, loadingObjects, pagination, id } = locationObjects

  const { locationCode } = useParams()

  useEffect(() => {
    locationsActions.getObjects({ ...params, locationCode: locationCode })
  }, [])

  const getOrder = (order) => (order === 'descend' ? 'desc' : 'asc')

  const getColumn = (column) => {
    switch (column) {
      default:
        return column
    }
  }

  const handleChange = (pagination, filters, sorter, _) => {
    const { getObjects, setFilter, setSorter, setPagination } = locationsActions
    const currentFilter = locationObjects.filter
    const currentSorter = locationObjects.sorter
    const currentPagination = locationObjects.pagination

    if (!equals(filters, currentFilter)) {
      const newParams = { ...getNewFilterParams(params, filters) }

      setFilter(filters)
      setPagination({ ...currentPagination, current: 1 })
      getObjects(newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order, field } = sorter
      const Order = getOrder(order)
      const Column = getColumn(field)
      const Offset = 0

      setSorter(sorter)
      setPagination({ ...currentPagination, current: 1 })
      getObjects({ ...params, Offset, Column, Order })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current, pageSize } = pagination
      const Offset = (current - 1) * pageSize
      const newParams = { ...params, Offset }

      setPagination(pagination)
      getObjects(newParams)
    }
  }

  const props = {
    ...ownProps,
    ...locationsActions,
    ...QRModalactions,
    loadingObjects,
    handleChange,
    pagination,
    params,
    objects,
    id
  }

  return <Component {...props} />
}

export default Container
