import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'

export interface EmailBilledState {
  modalOpen: boolean
  sendEmail?: Billing | null
  billingId?: number | null
  error: string
  loading: boolean
}
export const OPEN_MODAL_EMAIL_BILLED = 'BILLINGS/BILLINGS_BY_CLIENT/EMAIL/OPEN_MODAL_EMAIL_BILLED'

export interface OpenModalEmailBilled {
  type: typeof OPEN_MODAL_EMAIL_BILLED
  payload: {
    modalOpen: boolean
    billingId?: number
  }
}

export const SET_BILLING_ID = 'BILLINGS/BILLINGS_BY_CLIENT/EMAIL/SET_BILLING_ID'

export const SEND_EMAIL_BILLED_REQUEST = 'BILLINGS/BILLINGS_BY_CLIENT/EMAIL/SEND_EMAIL_BILLED_REQUEST'
export const SEND_EMAIL_BILLED_SUCCESS = 'BILLINGS/BILLINGS_BY_CLIENT/EMAIL/SEND_EMAIL_BILLED_SUCCESS'
export const SEND_EMAIL_BILLED_FAILURE = 'BILLINGS/BILLINGS_BY_CLIENT/EMAIL/SEND_EMAIL_BILLED_FAILURE'

export interface SendEmailBilledRequest {
  type: typeof SEND_EMAIL_BILLED_REQUEST
}

export interface SendEmailBilledSuccess {
  type: typeof SEND_EMAIL_BILLED_SUCCESS
}

export interface SendEmailBilledFailure {
  type: typeof SEND_EMAIL_BILLED_FAILURE
  payload: {
    error: string
  }
}

export type EmailBilledModalAction =
  | OpenModalEmailBilled
  | SendEmailBilledRequest
  | SendEmailBilledSuccess
  | SendEmailBilledFailure
