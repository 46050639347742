import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { convertToMoment, formatDates } from '../../utils'
import { URL_BASE_SALES } from '../../../endpoints'
import { APIResponse, ListParams, ResponseFromAPI } from './common'
import snakecaseKeys from 'snakecase-keys'

export type DonationsListSortKey = 'id' | 'objectId' | 'userId' | 'created_at' | 'updated_at'

export function list(params: ListParams): Promise<ResponseFromAPI> {
  const { status, createdAtFrom, createdAtTo, updatedAtFrom, updatedAtTo, showDemoUsers, ...restParams } = params
  const formattedParams = {
    ...snakecaseKeys(restParams, { deep: true }),
    statuses: status,
    test_requests: showDemoUsers,
    ...formatDates({
      dates: {
        created_from: createdAtFrom,
        created_to: createdAtTo,
        updated_from: updatedAtFrom,
        updated_to: updatedAtTo
      },
      format: 'YYYY-MM-DD'
    })
  }
  return apiPrivate
    .get(`${URL_BASE_SALES}/donation-requests`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('No se pudo obtener las solicitudes de venta')

      const camelResponse: ResponseFromAPI = {
        total: response.data.total,
        donationRequests:
          response.data.donation_requests === null
            ? []
            : camelcaseKeys(response.data.donation_requests, { deep: true }).map((donationRequests) => ({
                ...donationRequests,
                request: {
                  ...donationRequests.request,
                  createdAt: convertToMoment(donationRequests.request.createdAt),
                  updatedAt: convertToMoment(donationRequests.request.updatedAt),
                  deletedAt: convertToMoment(donationRequests.request.deletedAt)
                }
              }))
      }

      return camelResponse
    })
}
