import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Modal } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const styles = {
  dialogSubtitle: {
    marginBottom: 20
  },
  dialogContainer: {
    maxWidth: 700
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  }
}

const PagePanel = (props) => {
  const { open, loading, error, operationId, userId, userName, userLastName, link, close, generate } = props
  return (
    <Modal
      title="Generar link para agregar tarjeta"
      visible={open}
      closable={!loading}
      maskClosable={!loading}
      style={styles.dialogContainer}
      onCancel={close}
      footer={null}>
      <p>Operación: {operationId}</p>
      <p>
        Usuario: ({userId}) {userName} {userLastName}
      </p>
      <>
        <Button onClick={() => generate(operationId)} loading={loading} type="primary">
          Generar
        </Button>
        <Input type="text" value={link} contentEditable={false} />
        <CopyToClipboard text={link}>
          <Button disabled={link === ''}>Copiar</Button>
        </CopyToClipboard>
      </>
      <p style={styles.error}>{error}</p>
    </Modal>
  )
}

PagePanel.propTypes = {
  operationId: PropTypes.number,
  userId: PropTypes.number,
  userName: PropTypes.string,
  userLastName: PropTypes.string,
  open: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  close: PropTypes.func.isRequired,
  generate: PropTypes.func.isRequired
}

export default PagePanel
