import types from '../types/RegisterObjects'

const LIMIT = 50

const initialMissingObjectModal = {
  modalOpen: false,
  code: '',
  transactions: [],
  loadingTransactions: false,
  errorTransactions: '',
  transactionGUID: '',
  lastSearchID: 0,
  lastCodeTMPSearchID: 0,
  searchTransactionID: '',
  loading: false,
  error: '',
  codeTEMP: '',
  errorCode: '',
  newValue: ''
}

function MissingObjectModalReducer(state = initialMissingObjectModal, action) {
  switch (action.type) {
    case types.SET_SEARCH_CLIENT_BY_ID:
      return {
        ...state,
        searchTransactionID: action.payload.id
      }
    case types.FETCH_TRANSACTIONS:
      return {
        ...state,
        lastSearchID: action.payload.lastSearchID,
        loadingTransactions: true,
        errorTransactions: ''
      }
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload.transactions,
        loadingTransactions: false
      }
    case types.FETCH_TRANSACTIONS_FAIL:
      return {
        ...state,
        loadingTransactions: false,
        errorTransactions: action.payload.error
      }
    case types.ADD_MISSING_OBJECT:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.ADD_MISSING_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        modalOpen: false
      }
    case types.ADD_MISSING_OBJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.RESET_MISSING_OBJECT_FORM:
      return {
        ...initialMissingObjectModal,
        modalOpen: true
      }
    case types.SET_TRANSACTION_GUID: {
      const { transactionGUID } = action.payload
      return {
        ...state,
        transactionGUID
      }
    }
    case types.SET_CODE: {
      return { ...state, code: action.payload.code }
    }
    case types.SET_NEW_CODE: {
      return { ...state, newValue: action.payload.newValue }
    }
    case types.FETCH_CODE_TEMP_REQUEST: {
      return { ...state, loading: true, error: '', lastCodeTMPSearchID: action.payload.lastSearchID }
    }
    case types.FETCH_CODE_TEMP_SUCCESS: {
      return {
        ...state,
        loading: false,
        codeTEMP: action.payload.codeTMP,
        newValue: action.payload.newValue
      }
    }
    case types.FETCH_CODE_TEMP_FAILURE: {
      return { ...state, errorCode: action.payload.error }
    }
    case types.SET_MISSING_OBJECT_MODAL_OPEN: {
      return {
        ...initialMissingObjectModal,
        modalOpen: true
      }
    }
    case types.SET_MISSING_OBJECT_MODAL_CLOSE: {
      return { ...state, modalOpen: false }
    }
    default:
      return state
  }
}

export default MissingObjectModalReducer
