import { search } from './search'
import { deleteAddress } from './delete'
import { setDefaultAddress } from './default'
import { getById } from './getById'
import { create } from './create'
import { update } from './update'

export const AddressesAPI = {
  search,
  deleteAddress,
  setDefaultAddress,
  getById,
  create,
  update
}
