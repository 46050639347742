const missingObjectTypes = {
  SET_SEARCH_CLIENT_BY_ID: 'REGISTER_OBJECTS/SET_SEARCH_CLIENT_BY_ID',
  FETCH_TRANSACTIONS: 'REGISTER_OBJECTS/FETCH_TRANSACTIONS',
  FETCH_TRANSACTIONS_SUCCESS: 'REGISTER_OBJECTS/FETCH_TRANSACTIONS_SUCCESS',
  FETCH_TRANSACTIONS_FAIL: 'REGISTER_OBJECTS/FETCH_TRANSACTIONS_FAIL',
  ADD_MISSING_OBJECT: 'REGISTER_OBJECTS/ADD_MISSING_OBJECT',
  ADD_MISSING_OBJECT_SUCCESS: 'REGISTER_OBJECTS/ADD_MISSING_OBJECT_SUCCESS',
  ADD_MISSING_OBJECT_FAIL: 'REGISTER_OBJECTS/ADD_MISSING_OBJECT_FAIL',
  RESET_MISSING_OBJECT_FORM: 'REGISTER_OBJECTS/RESET_MISSING_OBJECT_FORM',
  SET_TRANSACTION_GUID: 'REGISTER_OBJECTS/SET_TRANSACTION_GUID',
  SET_CODE: 'REGISTER_OBJECTS/SET_CODE',
  SET_CODE_TEMP: 'REGISTER_OBJECTS/SET_CODE_TEMP',
  SET_NEW_CODE: 'REGISTER_OBJECTS/SET_NEW_TEMP',
  FETCH_CODE_TEMP_REQUEST: 'REGISTER_OBJECTS/FETCH_CODE_TEMP_REQUEST',
  FETCH_CODE_TEMP_SUCCESS: 'REGISTER_OBJECTS/FETCH_CODE_TEMP_SUCCESS',
  FETCH_CODE_TEMP_FAILURE: 'REGISTER_OBJECTS/FETCH_CODE_TEMP_FAILURE',
  SET_MISSING_OBJECT_MODAL_OPEN: 'REGISTER_OBJECTS/SET_MISSING_OBJECT_MODAL_OPEN',
  SET_MISSING_OBJECT_MODAL_CLOSE: 'REGISTER_OBJECTS/SET_MISSING_OBJECT_MODAL_CLOSE',
  SET_SCAN_MODAL_OPEN: 'REGISTER_OBJECTS/SET_SCAN_MODAL_OPEN',
  SET_SCAN_MODAL_CLOSE: 'REGISTER_OBJECTS/SET_SCAN_MODAL_CLOSE'
}

const scanModalTypes = {
  FETCH_OBJECTS_BY_CODE: 'REGISTER_OBJECTS/FETCH_OBJECTS_BY_CODE',
  FETCH_OBJECTS_BY_CODE_SUCCESS: 'REGISTER_OBJECTS/FETCH_OBJECTS_BY_CODE_SUCCESS',
  FETCH_OBJECTS_BY_CODE_FAIL: 'REGISTER_OBJECTS/FETCH_OBJECTS_BY_CODE_FAIL',
  FETCH_PRODUCTS: 'REGISTER_OBJECTS/FETCH_PRODUCTS',
  FETCH_PRODUCTS_SUCCESS: 'REGISTER_OBJECTS/FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAIL: 'REGISTER_OBJECTS/FETCH_PRODUCTS_FAIL',
  RECORD_OBJECT: 'REGISTER_OBJECTS/RECORD_OBJECT',
  RECORD_OBJECT_SUCCESS: 'REGISTER_OBJECTS/RECORD_OBJECT_SUCCESS',
  RECORD_OBJECT_FAIL: 'REGISTER_OBJECTS/RECORD_OBJECT_FAIL',
  RESET_FORM: 'REGISTER_OBJECTS/RESET_FORM',
  SET_OBJECT_CODE: 'REGISTER_OBJECTS/SET_OBJECT_CODE',
  SET_PRODUCT: 'REGISTER_OBJECTS/SET_PRODUCT',
  SET_MODAL_OPEN: 'REGISTER_OBJECTS/SET_MODAL_OPEN',
  SET_MODAL_CLOSE: 'REGISTER_OBJECTS/SET_MODAL_CLOSE',
  SET_HEIGHT: 'REGISTER_OBJECTS/SET_HEIGHT',
  SET_WIDTH: 'REGISTER_OBJECTS/SET_WIDTH',
  SET_LENGTH: 'REGISTER_OBJECTS/SET_LENGTH',
  SET_WEIGHT: 'REGISTER_OBJECTS/SET_WEIGHT'
}

const tableTypes = {
  FETCH_OBJECTS: 'REGISTER_OBJECTS/FETCH_OBJECTS',
  FETCH_OBJECTS_SUCCESS: 'REGISTER_OBJECTS/FETCH_OBJECTS_SUCCESS',
  FETCH_OBJECTS_FAIL: 'REGISTER_OBJECTS/FETCH_OBJECTS_FAIL',
  SET_SORTER: 'REGISTER_OBJECTS/SET_SORTER',
  SET_FILTER: 'REGISTER_OBJECTS/SET_FILTER',
  SET_PAGINATION: 'REGISTER_OBJECTS/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'REGISTER_OBJECTS/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'REGISTER_OBJECTS/CLEAN_FILTERS'
}

export default { ...missingObjectTypes, ...scanModalTypes, ...tableTypes }
