import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { formatDates } from '../../utils'
import { URL_BASE_SALES } from '../../../endpoints'
import { ListParams } from './common'

export function getCsv(params: ListParams): Promise<File> {
  const {
    limit,
    offset,
    userId,
    status,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    id,
    showDemoUsers = false
  } = params

  const formattedParams = {
    user_id: userId,
    status_id: status,
    test_requests: showDemoUsers,
    limit,
    offset,
    column: 'ID',
    order: 'desc',
    id,
    get_csv: true,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo,
        updated_at_from: updatedAtFrom,
        updated_at_to: updatedAtTo
      },
      format: 'YYYY/MM/DD'
    })
  }
  return apiPrivate
    .get(`${URL_BASE_SALES}/sales-requests`, { params: formattedParams, responseType: 'blob' })
    .then((response: AxiosResponse<File>) => {
      if (!response.data) throw new Error('No se pudo obtener las solicitudes de venta')

      return response.data
    })
}
