import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { SendTripsReportTypes, SendTransactionsByTripsReportTypes } from '../types/tripsReports'
import {
  TripWithTransactions,
  TripsDateRangeFilter,
  TripsSearchFilter,
  TripsTableCategoryFilter,
  TripsTableSort
} from '../types/tripsTable'
import { Pagination, dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'

const tripsReportsActionCreators = {
  sendCSV:
    ({
      pagination,
      searchFilters,
      dateRangeFilters,
      sort,
      categoryFilter
    }: {
      pagination: Pagination
      searchFilters?: TripsSearchFilter[]
      categoryFilter?: TripsTableCategoryFilter
      dateRangeFilters?: TripsDateRangeFilter[]
      sort?: TripsTableSort
    }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')

      dispatch({ type: SendTripsReportTypes.SEND_CSV_REQUEST })

      const LIMIT = 50

      try {
        await API.Trips.Trip.sendTripsCSV({
          limit: LIMIT,
          offset: (pagination.page - 1) * pagination.pageSize,
          sort: sort?.field,
          order: sort?.direction,
          tripStatus: categoryFilter?.tripStatus.join(),
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        dispatch({ type: SendTripsReportTypes.SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(error, SendTripsReportTypes.SEND_CSV_FAILURE, dispatch)
      }
    },

  sendTransactionsReport:
    ({ selectedTrips }: { selectedTrips: TripWithTransactions[] }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')
      dispatch({ type: SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_REQUEST })

      try {
        await API.TransactionHandler.Reports.txByTrips({ tripIds: selectedTrips.map((trip) => trip.id) })
        dispatch({ type: SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(
          error,
          SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_FAILURE,
          dispatch
        )
      }
    }
}

export default tripsReportsActionCreators
