import { Reducer } from 'redux'

import {
  CorporateProductsTableTypes,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  ProductsTableActions,
  ProductsTableState,
  SearchCategoriesTypes
} from '../types/productTable'
import { SortDirection } from '../../../../components/DynamicTable/types/types'

const LIMIT = 10

const initialState: ProductsTableState = {
  products: [],
  loadingProducts: false,
  error: '',
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'name', text: '' },
    { key: 'showInSearch', text: '' }
  ],
  pagination: {
    page: 1,
    total: 1,
    pageSize: LIMIT
  },
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'id',
    Order: 'desc'
  },
  Column: {
    field: 'id',
    direction: SortDirection.DESC
  },
  sort: {
    field: 'id',
    direction: SortDirection.DESC
  },
  categoryFilter: {
    showInSearch: [],
    deprecated: []
  },
  allCategories: [],
  categories: {
    fetching: false,
    list: [],
    error: ''
  },
  categoryId: 0
}
const ProductsTableReducer: Reducer<ProductsTableState, ProductsTableActions> = (
  state = initialState,
  action
): ProductsTableState => {
  switch (action.type) {
    case CorporateProductsTableTypes.CLEAN_STATE:
      return initialState
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingProducts: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        Column: action.payload.Column || initialState.Column,
        sort: action.payload.sort || initialState.sort,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        categoryFilter: action.payload.categoryFilter,
        categoryId: action.payload.categoryId ?? 0
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        loadingProducts: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }

    case GET_PRODUCTS_FAILURE:
      return { ...state, loadingProducts: false, error: action.payload.error }

    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: action.payload.categories
      }

    case SearchCategoriesTypes.SEARCH_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: { ...state.categories, fetching: true, error: '' }
      }

    case SearchCategoriesTypes.SEARCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          fetching: false,
          list: action.payload.categories
        }
      }

    case SearchCategoriesTypes.SEARCH_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          list: initialState.categories.list,
          fetching: false
        }
      }

    default:
      return { ...state }
  }
}

export default ProductsTableReducer
