import React from 'react'
import { Modal } from 'antd'
import UploadFiles from '../../../components/UploadFiles/container'
import { useEvents } from '../../../utils/eventEmitter'
import { UploadFilesEnum } from '../../../utils/eventEmitter/events/uploadFiles'

export type FilesModalProps = {
  modalOpen: boolean
  loading: boolean
  error: string
  onCancel: () => void
}

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

export const PaymentModal: React.FC<FilesModalProps> = ({ modalOpen: openModal, loading, onCancel, error }) => {
  useEvents(UploadFilesEnum.UPLOAD_FILE_SUCCESS, onCancel)

  return (
    <Modal
      title="Carga de archivos"
      visible={openModal}
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <UploadFiles fileType="csv" />
    </Modal>
  )
}
