/* eslint-disable camelcase */
import { Moment } from 'moment'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

export interface APITrip {
  id: number
  driver: {
    name: string
    last_name: string
    user_id: number
  }
  end_date: string
  created_at: string
  created_by: string
  quantity_deposits: number
  quantity_objects: number
  quantity_removals: number
  scheduled_end: string
  scheduled_start: string
  start_date: string
  status: {
    id: number
    description: string
  }
  total_m3: number
  country: CountryIdCode
}

export type Trip = {
  id: number
  createdAt: Moment
  scheduledStartDate: Moment
  scheduledEndDate: Moment
  realStartDate: Moment
  realEndDate: Moment
  createdBy: string
  transactionSummary: {
    deposits: number
    removals: number
  }
  forecast: {
    objects: number
    totalM3: number
  }
  driver: {
    name: string
    lastName: string
    userId: number
  }
  status: {
    id: TripStatusId
    description: string
  }
  country: CountryIdCode
}

export enum TripStatusId {
  PROGRAMADO = 1,
  ASIGNADO = 2,
  EN_CURSO = 3,
  FINALIZADO = 4,
  CANCELADO = 5
}

export interface ListParams {
  limit: number
  offset: number
  order?: 'asc' | 'desc'
  column?: string
  format: string
}
