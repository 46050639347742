import React, { useEffect } from 'react'
import { Col, DatePicker, Input, Modal, Row, Spin } from 'antd'
import moment from '../../../moment'
import { useErrors } from '../../../utils/hooks/useErrors'
import { func, string } from 'prop-types'

const { RangePicker } = DatePicker

const styles = {
  modal: {
    maxWidth: 700
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto',
    position: 'relative'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  warningField: {
    color: 'orange',
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red !important'
  },
  select: {
    width: '100%'
  },
  age: {
    marginLeft: 8
  },
  astrologicalSign: {
    marginBottom: 0,
    marginTop: 8
  },
  loadingContainer: {
    zIndex: 2,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingText: {
    fontSize: 16,
    marginTop: 15
  },
  actualPhone: {
    margin: 0,
    display: 'inline-block'
  },
  buttonModifyPhone: {
    float: 'right'
  }
}
const NewEditDiscount = (props) => {
  const {
    id,
    openModal,
    loading,
    closeModal,
    editMode,
    setCode,
    code,
    createDiscount,
    depositDiscountMonths,
    depositDiscountPercent,
    description,
    publicDescription,
    endDate,
    error,
    setDepositDiscountPercent,
    setDepositDiscountMonths,
    setDescription,
    setEndDate,
    setStartDate,
    setTransportDiscountPercent,
    setPublicDescription,
    transportDiscountPercent,
    startDate,
    updateDiscount
  } = props

  const { addErrors, removeError, showError, existError, cleanAllErrors } = useErrors()

  useEffect(() => {
    cleanAllErrors()
  }, [openModal])

  const checkErrors = () => {
    const newErrors = {}
    if (startDate === null) {
      newErrors.startDate = 'La fecha de inicio es requerida'
    }
    if (endDate === null) {
      newErrors.endDate = 'La fecha de fin es requerida'
    }
    if (code === '') {
      newErrors.code = 'El código es requerido'
    }
    if (description === '') {
      newErrors.description = 'La descripción es requerida'
    }
    if (depositDiscountMonths <= 0 || isNaN(depositDiscountMonths)) {
      newErrors.depositDiscountMonths = 'El descuento de depósito en meses es requerido y debe ser mayor a 1'
    }
    if (publicDescription.length < 5) {
      newErrors.publicDescription = 'La leyenda debe tener al menos 5 caracteres'
    }

    if (newErrors) addErrors(newErrors)

    return newErrors
  }

  const handleOnChangeIntValue = (value, func) => {
    if (value <= 100 || Number.isNaN(value)) {
      func(Number.isNaN(value) ? 0 : value)
    }
  }

  const handleSubmitNewEditDiscountModal = () => {
    if (Object.keys(checkErrors()).length > 0) {
      return true
    } else {
      if (id === null || editMode === false) {
        createDiscount({
          code,
          description,
          depositDiscountMonths,
          depositDiscountPercent,
          transportDiscountPercent,
          startDate,
          endDate,
          publicDescription
        }).then(() => {
          props.getDiscounts(props.params)
        })
      } else {
        updateDiscount({
          id,
          code,
          description,
          depositDiscountMonths,
          depositDiscountPercent,
          transportDiscountPercent,
          startDate,
          endDate,
          publicDescription
        }).then(() => {
          props.getDiscounts(props.params)
        })
      }
    }
  }

  const handleChangeRangeDataPicker = (date, stringDate) => {
    const newErrors = {}
    removeError('startDate')
    removeError('endDate')
    if (stringDate[0] !== '' && stringDate[0] === stringDate[1]) {
      newErrors.startDate = 'Un descuento no puede empezar y terminar un mismo día'
    }
    if (date.length === 2 && stringDate[0] !== stringDate[1]) {
      setStartDate(date[0])
      setEndDate(date[1])
    } else {
      setStartDate(null)
      setEndDate(null)
    }

    if (newErrors) addErrors(newErrors)
  }

  return (
    <Modal
      title={editMode ? 'Editar Descuento' : 'Nuevo Descuento'}
      visible={openModal}
      closable={!loading}
      maskClosable={!loading}
      keyboard={false}
      onOk={handleSubmitNewEditDiscountModal}
      onCancel={closeModal}
      cancelButtonProps={null}
      okButtonProps={null}
      okText="Aceptar"
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      {loading && editMode && (
        <div style={styles.loadingContainer}>
          <Spin size="large" />
          <p style={styles.loadingText}>Cargando datos del descuento, por favor espere...</p>
        </div>
      )}

      <Row
        gutter={[24, 24]}
        style={{
          margin: 0,
          display: 'flex',
          flexWrap: 'wrap'
        }}>
        <Col sm={24} xs={24}>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Spacer>
                <Row span={12}>
                  <p style={styles.inputTitle}>Código*: </p>
                  <Input
                    type="text"
                    style={styles.input}
                    placeholder="Código"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value)
                      removeError('code')
                    }}
                    disabled={editMode}
                  />
                  {existError('code') && (
                    <p className="error-description" style={styles.errorFieldLabel}>
                      {showError('code')}
                    </p>
                  )}
                </Row>
                <Row span={12}>
                  <p style={styles.inputTitle}>Descuento en depósito(%): </p>
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    value={depositDiscountPercent}
                    onChange={(e) => handleOnChangeIntValue(parseInt(e.target.value), setDepositDiscountPercent)}
                    style={styles.input}
                    placeholder="Depósito"
                    disabled={editMode}
                  />
                </Row>
                <Row span={12}>
                  <p style={styles.inputTitle}>Descuento transporte(%): </p>
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    value={transportDiscountPercent}
                    onChange={(e) => handleOnChangeIntValue(parseInt(e.target.value), setTransportDiscountPercent)}
                    style={styles.input}
                    placeholder="Transporte"
                    disabled={editMode}
                  />
                </Row>
              </Spacer>
            </Col>
            <Col span={12}>
              <Spacer>
                <Row span={12}>
                  <p style={styles.inputTitle}>Descripción*: </p>
                  <Input
                    type="text"
                    min="0"
                    max="100"
                    style={styles.input}
                    placeholder="Descripción"
                    value={description}
                    onChange={(e) => {
                      removeError('description')
                      setDescription(e.target.value)
                    }}
                  />
                  {existError('description') && (
                    <p className="error-description" style={styles.errorFieldLabel}>
                      {showError('description')}
                    </p>
                  )}
                </Row>
                <Row span={12}>
                  <p style={styles.inputTitle}>Meses de descuentos: </p>
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    value={depositDiscountMonths}
                    onChange={(e) => {
                      removeError('depositDiscountMonths')
                      setDepositDiscountMonths(parseInt(e.target.value))
                    }}
                    style={styles.input}
                    placeholder="Meses"
                    disabled={editMode}
                  />
                  {existError('depositDiscountMonths') && (
                    <p className="error-description" style={styles.errorFieldLabel}>
                      {showError('depositDiscountMonths')}
                    </p>
                  )}
                </Row>
                <Row span={12}>
                  <p style={styles.inputTitle}>Fechas inicio validez / fin validez: </p>
                  <RangePicker
                    popupStyle={{ zIndex: 10000 }}
                    onChange={(date, stringDate) => handleChangeRangeDataPicker(date, stringDate)}
                    value={[startDate, endDate]}
                    disabledDate={(current) => {
                      const customDate = moment().format('YYYY-MM-DD')
                      return current && current < moment(customDate, 'YYYY-MM-DD')
                    }}
                    allowClear={false}
                    format="DD/MM/YYYY"
                    dateRender={(current) => {
                      const style = {}
                      if (current.date() === 1) {
                        style.border = '1px solid #1890ff'
                        style.borderRadius = '50%'
                      }
                      return (
                        <div className="ant-calendar-date" style={style}>
                          {current.date()}
                        </div>
                      )
                    }}
                  />
                  {existError('startDate') && (
                    <p className="error-description" style={styles.errorFieldLabel}>
                      {showError('startDate')}
                    </p>
                  )}
                  {existError('endDate') && (
                    <p className="error-description" style={styles.errorFieldLabel}>
                      {showError('endDate')}
                    </p>
                  )}
                </Row>
                <Row span={12}>
                  <p style={styles.inputTitle}>Leyenda del descuento en la factura:</p>
                  <Input
                    value={publicDescription}
                    onChange={(e) => {
                      removeError('publicDescription')
                      setPublicDescription(e.target.value)
                    }}
                    style={styles.input}
                    placeholder="Leyenda"
                  />
                  {existError('publicDescription') && (
                    <p className="error-description" style={styles.errorFieldLabel}>
                      {showError('publicDescription')}
                    </p>
                  )}
                  {publicDescription.length > 50 && (
                    <p style={styles.warningField}>
                      La leyenda es muy larga. Es posible que se corte en la descripción de la factura
                    </p>
                  )}
                </Row>
              </Spacer>
            </Col>
          </Row>
        </Col>
      </Row>
      {error && (
        <div className="container">
          <p style={styles.error}>{error}</p>
        </div>
      )}
    </Modal>
  )
}
export const Spacer = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12
      }}>
      {children}
    </div>
  )
}

NewEditDiscount.propTypes = {
  publicDescription: string.isRequired,
  setPublicDescription: func.isRequired
}

export default NewEditDiscount
