import { list } from './list'
import { deleteProduct } from './delete'
import { listAdmin } from './listAdmin'
import { create } from './create'
import { getById } from './getById'
import { update } from './update'
import { CorporateProductAPI } from './corporate'

export const ProductAPI = {
  Corporate: CorporateProductAPI,
  list,
  listAdmin,
  deleteProduct,
  create,
  getById,
  update
}
