import { Deposit, DepositResponse, GetUserSuccess, ToggleUserModalOpen, User, UserActionTypes } from '../types/users'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS, URL_BASE_TRANSACTIONS, URL_BASE_USERS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { API } from '../../../projectApi'

const getLatestDeposit = async (userId: number): Promise<DepositResponse> => {
  const response = await apiPrivate.get(URL_BASE_TRANSACTIONS + '/operation/deposit', {
    params: { user_id: userId, Offset: 0, Limit: 1, Order: 'desc', Column: 'created_at' }
  })
  return response.data.description.operations[0] || null
}

const getTotalObjects = async (userId: number): Promise<number> => {
  const response = await apiPrivate.get(URL_BASE_OBJECTS + '/object', {
    params: { ownerID: userId, Limit: 1 }
  })
  return response.data.description.quantity || 0
}

const actions = {
  toggleUserModalOpen: (open: boolean): ToggleUserModalOpen => ({
    type: UserActionTypes.TOGGLE_USER_MODAL_OPEN,
    payload: { open }
  }),
  getUser:
    (id: number): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: UserActionTypes.GET_USER_REQUEST })
      const toast = setToastLoading('Buscando usuario, por favor espere...')
      return apiPrivate
        .get(URL_BASE_USERS + '/users', { params: { id, country_code: undefined } })
        .then(async (response) => {
          const user = response.data.description.users[0]

          if (!user) {
            const error = 'Usuario no encontrado o incorrecto. Ingréselo nuevamente.'
            setToastErrorUpdate(toast, { render: error })
            dispatch({
              type: UserActionTypes.GET_USER_FAILURE,
              payload: { error }
            })
            return
          }

          const [lastDeposit, totalObjects]: [DepositResponse, number] = await Promise.all([
            getLatestDeposit(id),
            getTotalObjects(id)
          ])

          setToastSuccessUpdate(toast, { render: 'Usuario encontrado.' })

          Emitter.emit(Events.Removal.GET_USER, { id })

          dispatch(actions.getUserSuccess(keysToCamel(user), keysToCamel(lastDeposit), totalObjects))
        })
        .catch((error) => {
          setToastErrorUpdate(toast, {
            render: 'Usuario no encontrado o incorrecto. Ingréselo nuevamente.'
          })

          handleErrorsWithAction(error, UserActionTypes.GET_USER_FAILURE, dispatch)
        })
    },
  getInfoAboutUserHasDebts(userId: number): AppThunk {
    return async function (dispatch) {
      dispatch({
        type: UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST
      })
      try {
        const response = await API.TransactionHandler.Colppy.userInfo(userId)
        const { users } = response
        dispatch({ type: UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS, payload: { user: users[0] } })
      } catch (e) {
        handleErrorsWithAction(e, UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE, dispatch)
      }
    }
  },

  getUserSuccess: (user: User, lastDeposit: Deposit | null, totalObjects: number): GetUserSuccess => ({
    type: UserActionTypes.GET_USER_SUCCESS,
    payload: {
      user,
      lastDeposit,
      totalObjects
    }
  })
}

export default actions
