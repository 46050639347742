import { combineReducers } from 'redux'
import address from './Address/reducer'
import cbu from './CBU/reducer'
import QRModalReducer from './QRModal/QRModalReducer'
import UploadPhotos from './UploadPhotos/reducer'
import UploadFiles from './UploadFiles/reducer'
import CreditCardReducer from './CreditCard/reducer'

const componentRoot = combineReducers({
  address,
  cbu,
  QRModalReducer,
  UploadPhotos,
  UploadFiles,
  CreditCard: CreditCardReducer
})

export default componentRoot
