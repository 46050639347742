import * as queryString from 'query-string'
import { URL_BASE_PAYMENTS } from '../../../endpoints'
import { handleErrorsWithActionLoginAs } from '../../../utils/HandleErrors'
import { apiPrivate } from '../../../api'
import {
  CLOSE_CHECKOUT_MODAL,
  CloseCheckoutModal,
  GENERATE_CHECKOUT_LINK_FAILURE,
  GENERATE_CHECKOUT_LINK_REQUEST,
  GENERATE_CHECKOUT_LINK_SUCCESS,
  GET_EXISTING_CHECKOUT_LINK_REQUEST,
  GET_EXISTING_CHECKOUT_LINK_SUCCESS,
  GenerateCheckoutLinkSuccess,
  GenerateCheckoutResponse,
  GetExistingCheckoutLinkSuccess,
  OPEN_CHECKOUT_MODAL,
  OpenCheckoutModal,
  OperationEnum
} from '../types/checkout'
import { AppThunk } from '../../../store'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import {
  sendToastNotificationInfo,
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate
} from '../../../utils/notifications'
import config from '../../../config'

const getFormattedLink = (response: GenerateCheckoutResponse, country: string, paymentProcessor: string): string => {
  const queryParams = queryString.stringify({
    session: response.code,
    operation: OperationEnum.DIRECT_DEBIT,
    country,
    payment_processor: paymentProcessor
  })
  return `${config.CHECKOUT_URL}?${queryParams}`
}

const actions = {
  openModal(id: number, name: string, lastName: string, country: string): OpenCheckoutModal {
    return {
      type: OPEN_CHECKOUT_MODAL,
      payload: {
        id,
        name,
        lastName,
        country
      }
    }
  },
  closeModal(): CloseCheckoutModal {
    return { type: CLOSE_CHECKOUT_MODAL }
  },

  getExistingCheckoutLink(userId: number, country: string, paymentProcessor: string): AppThunk {
    return (dispatch) => {
      dispatch({ type: GET_EXISTING_CHECKOUT_LINK_REQUEST })

      // is_expired param on true returns active codes
      const config = {
        params: { user_id: userId, is_expired: true }
      }

      return apiPrivate
        .get(URL_BASE_PAYMENTS + '/checkout-session/search', config)
        .then((response) => {
          if (response.data.results.length === 0) return dispatch(actions.getExistingCheckoutLinkSuccess(false))
          const formattedResponse: GenerateCheckoutResponse = keysToCamel(response.data.results[0])

          const link = getFormattedLink(formattedResponse, country, paymentProcessor)

          sendToastNotificationInfo('Ya existe un link existente. No es requerido crear uno nuevo')

          dispatch(actions.getExistingCheckoutLinkSuccess(true, link))
        })
        .catch((error) => {
          handleErrorsWithActionLoginAs(error, GENERATE_CHECKOUT_LINK_FAILURE, dispatch)
        })
    }
  },
  getExistingCheckoutLinkSuccess: (exists: boolean, link = ''): GetExistingCheckoutLinkSuccess => ({
    type: GET_EXISTING_CHECKOUT_LINK_SUCCESS,
    payload: { exists, link }
  }),

  generateCheckoutLink(userId: number, country: string, paymentProcessor: string): AppThunk {
    return (dispatch) => {
      dispatch({ type: GENERATE_CHECKOUT_LINK_REQUEST })

      const toastId = setToastLoading('Generando link...')

      const params = {
        headers: {
          'user-id': userId
        }
      }

      return apiPrivate
        .post(URL_BASE_PAYMENTS + '/checkout-session', null, params)
        .then((response) => {
          const formattedResponse: GenerateCheckoutResponse = keysToCamel(response.data)
          const link = getFormattedLink(formattedResponse, country, paymentProcessor)

          setToastSuccessUpdate(toastId, {
            render: '¡Link generado!'
          })

          dispatch(actions.generateSuccess(link))
        })
        .catch((error) => {
          setToastErrorUpdate(toastId, {
            render: 'Hubo un error al generar el link'
          })
          handleErrorsWithActionLoginAs(error, GENERATE_CHECKOUT_LINK_FAILURE, dispatch)
        })
    }
  },
  generateSuccess: (link: string): GenerateCheckoutLinkSuccess => ({
    type: GENERATE_CHECKOUT_LINK_SUCCESS,
    payload: { link }
  })
}

export default actions
