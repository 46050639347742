import { ProposalTypes } from '../types/proposals'
import { ExtraActions, ExtraTypes } from '../types/extras'
import { ProspectSearchTypes } from '../types/prospect'
import { Reducer } from 'redux'

const initialState = {
  extraAssistant: {
    enabled: false,
    count: 0
  },
  additionalCost: {
    enabled: false,
    amount: 0,
    comment: ''
  }
}

type ExtraState = {
  extraAssistant: {
    enabled: boolean
    count: number
  }
  additionalCost: {
    enabled: boolean
    amount: number
    comment: string
  }
}

const root: Reducer<ExtraState, ExtraActions> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
    case ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH:
      return initialState
    case ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS: {
      return {
        ...state,
        extraAssistant: {
          enabled: action.payload.proposal.shipmentAssistantCount > 0,
          count: action.payload.proposal.shipmentAssistantCount
        },
        additionalCost: {
          enabled: action.payload.proposal.additionalCost > 0,
          amount: action.payload.proposal.additionalCost,
          comment: action.payload.proposal.additionalCostComment
        }
      }
    }
    case ExtraTypes.SET_EXTRA_ASSISTANT_COUNT: {
      return { ...state, extraAssistant: { ...state.extraAssistant, count: action.payload.extraAssistantCount } }
    }
    case ExtraTypes.SET_EXTRA_ASSISTANT_ENABLED: {
      return { ...state, extraAssistant: { ...state.extraAssistant, enabled: action.payload.extraAssistantEnabled } }
    }
    case ExtraTypes.SET_ADDITIONAL_COST: {
      return { ...state, additionalCost: { ...state.additionalCost, amount: action.payload.additionalCost } }
    }
    case ExtraTypes.SET_ADDITIONAL_COST_ENABLED: {
      return { ...state, additionalCost: { ...state.additionalCost, enabled: action.payload.additionalCostEnabled } }
    }
    case ExtraTypes.SET_ADDITIONAL_COST_COMMENT: {
      return {
        ...state,
        additionalCost: { ...state.additionalCost, comment: action.payload.additionalCostComment }
      }
    }
    default:
      return state
  }
}

export default root
