export type MetricsLeadsState = {
  metrics: Metrics
  loadingMetrics: boolean
}

export type Metrics = {
  status: {
    leadsToServe: number
    leadsInProgress: number
    leadsServed: number
    leadsToServicePr: number
    leadsInProgressPr: number
    leadsServicedPr: number
  }
  mqls: {
    mql: number
    mqlOverLeads: number
    mqlDiscarded: number
  }
  sqls: {
    sql: number
    sqlOverLeads: number
    sqlOverMql: number
    sqlM3: number
    sqlTotal: number
  }
  rps: {
    rp: number
    rpOverLeads: number
    rpOverSql: number
    rpOverMql: number
    rpc: number
    rpcPercentage: number
    rpM3: number
    rpTotal: number
  }
}

export type ParamsFilter = {
  Column?: string
  Limit: number
  Offset?: number
  Order?: string
  created_at_from_v2?: string
  created_at_to_v2?: string
  updated_at_from_v2?: string
  updated_at_to_v2?: string
  win_date_from?: string
  win_date_to?: string
  source?: number[]
  contact_medium_id?: number[]
  prospect_type_id?: number[]
  agent_ids?: number[]
  provider?: number[]
}

export const GET_METRICS_REQUEST = 'LEADS/METRICS_LEADS/GET_METRICS_REQUEST'
export const GET_METRICS_SUCCESS = 'LEADS/METRICS_LEADS/GET_METRICS_SUCCESS'
export const GET_METRICS_FAILURE = 'LEADS/METRICS_LEADS/GET_METRICS_FAILURE'

export interface GetMetricsRequest {
  type: typeof GET_METRICS_REQUEST
}

export interface GetMetricsSuccess {
  type: typeof GET_METRICS_SUCCESS
  payload: {
    metrics: Metrics
  }
}

export interface GetMetricsFailure {
  type: typeof GET_METRICS_FAILURE
  payload: {
    error: string
  }
}

export type GetMetricsAction = GetMetricsRequest | GetMetricsSuccess | GetMetricsFailure

export type MetricsLeadsActions = GetMetricsAction
