import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import BillPeriodModalActions from '../actionCreators/billPeriod'
import { BillingsTableDateRangeKey } from '../types/newBillingTable'
import moment from 'moment'
import { BillPeriodModal, BillPeriodModalProps } from '../components/BillPeriodModal'

const BillPeriodModalContainer = () => {
  const dispatch = useDispatch()

  const billPeriodModalActions = bindActionCreators(BillPeriodModalActions, dispatch)
  const billPeriodModalState = useSelector((state) => state.BillingV2.billPeriodModal)
  const periodFrom =
    useSelector((state) =>
      state.BillingV2.NewBillingTable.dateRangeFilters.find((filter) => filter.key === BillingsTableDateRangeKey.PERIOD)
    )?.startDate || moment().startOf('month')

  const { modalOpen, loadingBillPeriod, loadingBillingToBill, billingToBillTotal, error } = billPeriodModalState

  useEffect(() => {
    if (modalOpen) {
      billPeriodModalActions.getBillingsToBill(periodFrom)
    }
  }, [modalOpen])

  const handleBillPeriod = () => billPeriodModalActions.billPeriod(periodFrom)

  const props: BillPeriodModalProps = {
    modalOpen,
    loadingBillPeriod,
    loadingBillingToBill,
    handleBillPeriod,
    onCancel: () => billPeriodModalActions.openBillPeriodModal({ modalOpen: false }),
    billingToBillTotal,
    error
  }

  return <BillPeriodModal {...props} />
}

export default BillPeriodModalContainer
