import React from 'react'
import { SortDirection, SortProps } from '../../components/DynamicTable/types/types'
import { Tooltip } from 'antd'
import { ObjectsTableSort, ObjectsTableSortKey } from './types/objectsTable'
import { FormattedObject, OBJECT_SITUATION } from '../../projectApi/ObjectAdministration/common'
import { formatNumber } from '../../utils/formatNumber'
import { Moment } from 'moment'
import styles from './components/objectsTable.module.scss'
import { formatDate } from '../Billing/utils'

export const parseDate = (date: Moment) => {
  const formattedDate = (
    <>
      {date.format('dd DD[/]MM[/]YY')}
      <br />
      {date.format('HH[:]mm')}
    </>
  )
  return date.isValid() ? (
    <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{date.isAfter('01-01-0001') ? formattedDate : '-'}</span>
  ) : (
    ''
  )
}

export const getSortProps = (
  key: ObjectsTableSortKey,
  currentSorter: ObjectsTableSort,
  sorterFunction: (newSorter: ObjectsTableSort) => void
): SortProps => {
  return {
    direction: currentSorter.direction,
    isActive: currentSorter.field === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        direction: currentSorter.field === key ? newSortDirection : SortDirection.ASC,
        field: key
      })
  }
}

export const getIdentificationCode = (obj: FormattedObject) => {
  const found = obj.identificationCodes.find((idcode) => idcode.actual)
  if (!found) return '-'

  return found.code
}

export const calculateDimensions = (height = 0, width = 0, length = 0) => {
  return (height * width * length) / 1000000
}

export const renderProduct = (obj: FormattedObject) => {
  const { product } = obj

  const volume = calculateDimensions(product.heightInCm, product.widthInCm, product.lengthInCm)

  if (!product.description) return <span>-</span>

  return (
    <div className={styles.multiline}>
      <div className={styles.description}>
        {product.description.length > 20 ? (
          <Tooltip title={product.description}>{product.description.substring(0, 18)}...</Tooltip>
        ) : (
          product.description
        )}
      </div>
      <div className={styles.dimensions}>
        {product.heightInCm}x{product.widthInCm}x{product.lengthInCm} (cm) = {formatNumber(volume, 2)} m<sup>3</sup>
      </div>
      <div className={styles.multiplier}>Coef. Multip x{product.multiplier} </div>
    </div>
  )
}

export const renderType = (obj: FormattedObject) => {
  const { product, heightInCm, widthInCm, lengthInCm } = obj
  const volume = (heightInCm * widthInCm * lengthInCm) / 1000000
  const info =
    heightInCm === product.heightInCm && widthInCm === product.widthInCm && lengthInCm === product.lengthInCm ? (
      <div className={styles.description}>ST</div>
    ) : (
      <>
        <Tooltip
          title={
            <div className={styles.dimensions}>
              {heightInCm} x {widthInCm} x {lengthInCm} (cm) = {formatNumber(volume, 2)} m<sup>3</sup>
            </div>
          }>
          <div className={styles.description}>CM*</div>
        </Tooltip>
      </>
    )

  return <div className={styles.multiline}>{info}</div>
}

export const renderStatus = (obj: FormattedObject) => {
  const { deletedAt, objectDeletedReason, deletedDescription, objectStatus } = obj
  const titleComponent = () => {
    return (
      <>
        {objectDeletedReason && <p style={{ marginBottom: '0' }}>Razón: {objectDeletedReason.reason}</p>}
        {deletedDescription}
        <div>Fecha de eliminación: {formatDate(deletedAt)}</div>
      </>
    )
  }
  return (
    <div>
      <p
        style={{
          textDecoration: deletedAt ? 'line-through' : '',
          marginBottom: 0,
          color: deletedAt ? 'red' : ''
        }}>
        {objectStatus.description}
      </p>
      {deletedAt && (
        <Tooltip title={titleComponent}>
          <span style={{ color: 'red' }}>Eliminado</span>
        </Tooltip>
      )}
    </div>
  )
}

export enum ObjectSituationKeys {
  IN_OWNER = 'IN_OWNER',
  SAVED = 'SAVED',
  LOST = 'LOST',
  SAVED_NOT_VALIDATED = 'SAVED_NOT_VALIDATED',
  FOUND_AFTER_LOST = 'FOUND_AFTER_LOST',
  UNDER_REPAIR = 'UNDER_REPAIR',
  REPAIRED = 'REPAIRED',
  DONATION_REQUEST = 'DONATION_REQUEST',
  DONATED = 'DONATED',
  FORCED_DONATION = 'FORCED_DONATION',
  SALES_REQUEST = 'SALES_REQUEST',
  SALE = 'SALE',
  SOLD_OUT = 'SOLD_OUT',
  FORCED_SALE = 'FORCED_SALE',
  RECYCLING_REQUEST = 'RECYCLING_REQUEST',
  RECYCLED = 'RECYCLED',
  FORCED_RECYCLED = 'FORCED_RECYCLED',
  SALES_PRODUCTION = 'SALES_PRODUCTION',
  LOCATING = 'LOCATING',
  CATALOGING = 'CATALOGING',
  TAKING_PHOTOS = 'TAKING_PHOTOS',
  REMOVAL_REQUEST = 'REMOVAL_REQUEST',
  SALE_PREPARATION = 'SALE_PREPARATION',
  SALES_REQUEST_R = 'SALES_REQUEST_R',
  SALES_REQUEST_CP = 'SALES_REQUEST_CP'
}

export const ObjectSituationSortKeys = [
  {
    value: ObjectSituationKeys.IN_OWNER,
    text: OBJECT_SITUATION.IN_OWNER,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: ObjectSituationKeys.SAVED,
    text: OBJECT_SITUATION.SAVED,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: ObjectSituationKeys.LOST,
    text: OBJECT_SITUATION.LOST,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.FOUND_AFTER_LOST,
    text: OBJECT_SITUATION.FOUND_AFTER_LOST,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.UNDER_REPAIR,
    text: OBJECT_SITUATION.UNDER_REPAIR,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.REPAIRED,
    text: OBJECT_SITUATION.REPAIRED,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.DONATION_REQUEST,
    text: OBJECT_SITUATION.DONATION_REQUEST,
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: ObjectSituationKeys.DONATED,
    text: OBJECT_SITUATION.DONATED,
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  },
  {
    value: ObjectSituationKeys.FORCED_DONATION,
    text: OBJECT_SITUATION.FORCED_DONATION,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SALES_REQUEST,
    text: OBJECT_SITUATION.SALES_REQUEST,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SALE,
    text: OBJECT_SITUATION.SALE,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SOLD_OUT,
    text: OBJECT_SITUATION.SOLD_OUT,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.FORCED_SALE,
    text: OBJECT_SITUATION.FORCED_SALE,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.RECYCLING_REQUEST,
    text: OBJECT_SITUATION.RECYCLING_REQUEST,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.RECYCLED,
    text: OBJECT_SITUATION.RECYCLED,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.FORCED_RECYCLED,
    text: OBJECT_SITUATION.FORCED_RECYCLED,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.LOCATING,
    text: OBJECT_SITUATION.LOCATING,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SAVED_NOT_VALIDATED,
    text: OBJECT_SITUATION.SAVED_NOT_VALIDATED,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.CATALOGING,
    text: OBJECT_SITUATION.CATALOGING,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.TAKING_PHOTOS,
    text: OBJECT_SITUATION.TAKING_PHOTOS,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.REMOVAL_REQUEST,
    text: OBJECT_SITUATION.REMOVAL_REQUEST,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SALES_PRODUCTION,
    text: OBJECT_SITUATION.SALES_PRODUCTION,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SALE_PREPARATION,
    text: OBJECT_SITUATION.SALE_PREPARATION,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SALES_REQUEST_CP,
    text: OBJECT_SITUATION.SALES_REQUEST_CP,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectSituationKeys.SALES_REQUEST_R,
    text: OBJECT_SITUATION.SALES_REQUEST_R,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  }
]
