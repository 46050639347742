import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

interface DeleteParams {
  id: string
}

export function deleteProduct(params: DeleteParams): Promise<void> {
  const { id } = params

  return apiPrivate
    .delete(`${URL_BASE_CATEGORIES}/product/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
