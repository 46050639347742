import { ObjectsStatusKey } from '../../projectApi/ObjectAdministration/common'
import { OperationStatus } from '../../projectApi/TransactionHandler/Operation/Removals/list'

export const STATUS_FILTERS = [
  {
    text: 'Cancelada',
    value: OperationStatus.CANCELLED,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  },
  {
    text: 'Programada',
    value: OperationStatus.PROGRAMMED,
    styleProps: {
      borderColor: '#175CD3',
      backgroundColor: '#EFF8FF'
    }
  },
  {
    text: 'Programada (M)',
    value: OperationStatus.PROGRAMMED_M,
    styleProps: {
      borderColor: '#175CD3',
      backgroundColor: '#EFF8FF'
    }
  },
  {
    text: 'En viaje',
    value: OperationStatus.IN_TRIP,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    text: 'Asignada a viaje',
    value: OperationStatus.ASSIGNED_TO_TRIP,
    styleProps: {
      borderColor: '#1000C0',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    text: 'Devuelta',
    value: OperationStatus.RETURNED,
    styleProps: {
      borderColor: '#852F44',
      backgroundColor: '#FEF9D3'
    }
  },
  {
    text: 'Pendiente de pago',
    value: OperationStatus.PENDING_PAYMENT,
    styleProps: {
      borderColor: '#C11574',
      backgroundColor: '#FDF2FA'
    }
  },
  {
    text: 'Autorizada',
    value: OperationStatus.AUTHORIZED,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Borrador',
    value: OperationStatus.DRAFT,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  }
]

export const OBJECT_STATUS_OPTIONS = [
  {
    text: 'Depositado',
    value: ObjectsStatusKey.DEPOSITADO,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Alquilado',
    value: ObjectsStatusKey.ALQUILADO,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'A donar',
    value: ObjectsStatusKey.A_DONAR,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Encontrado',
    value: ObjectsStatusKey.ENCONTRADO,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'En propietario',
    value: ObjectsStatusKey.EN_PROPIETARIO,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  },
  {
    text: 'A catalogar',
    value: ObjectsStatusKey.A_CATALOGAR,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  },
  {
    text: 'A fotagrafiar',
    value: ObjectsStatusKey.A_FOTOGRAFIAR,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    text: 'A ubicar',
    value: ObjectsStatusKey.A_UBICAR,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    text: 'No encontrado',
    value: ObjectsStatusKey.NO_ENCONTRADO,
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    text: 'Eliminado',
    value: ObjectsStatusKey.ELIMINADO,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    text: 'En transito',
    value: ObjectsStatusKey.EN_TRANSITO,
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  },
  {
    text: 'Buscando',
    value: ObjectsStatusKey.BUSCANDO,
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  }
]
