import { apiPrivate } from './../../../api'
import { URL_BASE_SALES } from '../../../endpoints'
import { convertPrice } from './common'
interface RejectParams {
  saleRequestId: number
  id: number
  reason?: string
  saleCost: number
  saleMargin: number
}
export function reject({ id, reason, saleRequestId, saleCost, saleMargin }: RejectParams): Promise<void> {
  const data = {
    permanent_reject: false,
    reason: { id, name: reason },
    sale_cost: convertPrice(saleCost, 'cents'),
    sale_margin: convertPrice(saleMargin, 'cents')
  }

  return apiPrivate
    .put(`${URL_BASE_SALES}/sales-requests/reject/${saleRequestId}`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
