import clsx from 'clsx'
import React, { CSSProperties, NamedExoticComponent, ReactNode } from 'react'
import styles from './TableActionBar.module.scss'
import { Icon, Tooltip } from 'antd'

interface ActionBarCompound {
  Filters: typeof Filters
  ButtonAction: typeof ButtonAction
  Wrapper: typeof Wrapper
  Spacer: typeof Spacer
}

type BaseActionBarProps = {
  children: ReactNode
  className?: string
  customStyles?: CSSProperties
  hideBorder?: boolean
}

type ButtonActionProps = {
  customButton?: JSX.Element | null
  customClassName?: string
  titleButton?: string
  colorButton?: string
  disabled?: boolean
  customIcon?: JSX.Element | null
  iconType?: string
  onClickButton?: () => void
  variant?: 'secondary' | 'cleanFilters' | 'csv'
  iconPosition?: 'left' | 'right'
  toolTip?: boolean
  toolTipCustomMessage?: string
}

type FiltersProps = {
  titleFilter?: string
  cleanFilters?: () => void
  customIcon?: JSX.Element | null
  iconType?: string
}

type WrapperProps = {
  children: ReactNode
}

export const BaseActionBar: React.FC<BaseActionBarProps> = ({ children, className, customStyles, hideBorder }) => {
  return (
    <div
      style={customStyles}
      className={clsx(styles.actionBarContainer, className, hideBorder ? null : styles.borderBar)}>
      {children}
    </div>
  )
}

export const Filters: React.FC<FiltersProps> = ({ children, titleFilter, cleanFilters, customIcon, iconType }) => {
  return (
    <div className={styles.actionBarFilters}>
      {titleFilter ? <span className={styles.titleFilter}>{titleFilter}</span> : null}
      {children}
      {cleanFilters ? (
        <span onClick={cleanFilters} className={styles.cleanFilters}>
          {customIcon ?? <Icon type={iconType ?? 'search'} />} Limpiar filtros
        </span>
      ) : null}
    </div>
  )
}

export const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return <div className={styles.actionBarWrapper}>{children}</div>
}

export const Spacer: React.FC = () => {
  return <div />
}

export const ButtonAction: React.FC<ButtonActionProps> = ({
  customButton,
  titleButton,
  colorButton,
  customClassName,
  disabled,
  onClickButton,
  customIcon,
  iconType,
  variant,
  toolTip,
  toolTipCustomMessage,
  iconPosition = 'left' // Default icon position
}) => {
  const defaultTooltipReportMessage = 'El archivo resultante usa los filtros y paginación aplicados a la tabla'

  // Function to generate icon JSX
  const getIconElement = () => customIcon ?? <Icon type={iconType ?? 'plus'} />

  // Function to generate button content based on iconPosition
  const getButtonContent = () => {
    const iconElement = getIconElement()
    const textContent =
      variant === 'cleanFilters' ? 'Limpiar filtros' : variant === 'csv' ? titleButton ?? 'Exportar CSV' : titleButton

    return iconPosition === 'left' ? (
      <>
        {iconElement}
        <span>{textContent}</span>
      </>
    ) : (
      <>
        <span>{textContent}</span>
        {iconElement}
      </>
    )
  }

  const getButtonStyle = () => {
    switch (variant) {
      case 'secondary':
      case 'cleanFilters':
        return styles.cleanFilters
      case 'csv':
        return styles.buttonCreate
      default:
        return styles.buttonCreate
    }
  }

  const button = (
    <button
      style={{ backgroundColor: colorButton }}
      onClick={onClickButton}
      className={clsx(getButtonStyle(), customClassName, disabled && styles.disabledButton)}
      disabled={disabled}>
      {getButtonContent()}
    </button>
  )

  return toolTip ? (
    <Tooltip
      style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
      placement="top"
      title={toolTipCustomMessage ?? defaultTooltipReportMessage}>
      {customButton || button}
    </Tooltip>
  ) : (
    button
  )
}

const MemoizedActionBar = React.memo(BaseActionBar) as NamedExoticComponent<BaseActionBarProps> & ActionBarCompound

MemoizedActionBar.Filters = Filters
MemoizedActionBar.ButtonAction = ButtonAction
MemoizedActionBar.Wrapper = Wrapper
MemoizedActionBar.Spacer = Spacer

export const TableActionBar = MemoizedActionBar
