import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import {
  CANCEL_DELETE_ORIGIN_MODAL,
  CLOSE_DELETE_ORIGIN_MODAL,
  CancelDeleteOriginModal,
  CloseDeleteOriginModal,
  DELETE_ORIGIN_FAILURE,
  DELETE_ORIGIN_REQUEST,
  DELETE_ORIGIN_SUCCESS,
  DeleteOriginRequest,
  DeleteOriginSuccess,
  OPEN_DELETE_ORIGIN_MODAL,
  OpenDeleteOriginModal
} from '../types/deleteOrigin'

const deleteOriginActionCreators = {
  openDeleteOriginModal: ({
    id,
    sirenaSource,
    channel
  }: {
    id: number
    sirenaSource: string
    channel: string
  }): OpenDeleteOriginModal => {
    return {
      type: OPEN_DELETE_ORIGIN_MODAL,
      payload: { id, sirenaSource, channel }
    }
  },

  cancelDeleteOriginModal: (): CancelDeleteOriginModal => ({
    type: CANCEL_DELETE_ORIGIN_MODAL
  }),

  closedeleteOriginModal: (): CloseDeleteOriginModal => ({
    type: CLOSE_DELETE_ORIGIN_MODAL
  }),

  deleteOrigin:
    ({ id }: { id: number }): AppThunk =>
    async (dispatch) => {
      const request: DeleteOriginRequest = {
        type: DELETE_ORIGIN_REQUEST
      }

      dispatch(request)

      try {
        await API.Mercurio.BO.Origins.delete({ id })

        const success: DeleteOriginSuccess = {
          type: DELETE_ORIGIN_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Origins.ORIGINS_UPDATED)
      } catch (error) {
        handleErrorsWithAction(error, DELETE_ORIGIN_FAILURE, dispatch)
      }
    }
}

export default deleteOriginActionCreators
