import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { ListParams } from './list'
import snakecaseKeys from 'snakecase-keys'

export function sendCSV({
  periodFrom,
  periodTo,
  userId,
  excludedUsers,
  warningId,
  ...otherParams
}: ListParams): Promise<void> {
  const snakeParams = snakecaseKeys({
    ...otherParams,
    userId: userId && JSON.parse(userId),
    warningTypeId: warningId || undefined,
    excludedUsers: excludedUsers && JSON.parse(excludedUsers),
    periodFrom: periodFrom?.format('YYYY-MM-DD'),
    periodTo: periodTo?.format('YYYY-MM-DD')
  })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/billing-data/generate-report`, snakeParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
