import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

export function deleteLocation(locationId: number): Promise<boolean> {
  return apiPrivate
    .delete(`${URL_BASE_TRANSACTIONS}/locations/${locationId}`)
    .then(() => true)
    .catch((error) => {
      throw error
    })
}
