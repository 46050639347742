import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { apiPrivate } from './../../../api'
import { SearchableParams } from '../Object/list'
import camelcaseKeys from 'camelcase-keys'
import { formatDates } from '../../utils'

interface MetricsParams extends SearchableParams {
  status?: string
  situation?: string
}

type APIResponse = {
  total: number
  total_m3: number
  cataloging: number
  locating: number
  takingPhotos: number
  saved: number
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export async function metrics(params: MetricsParams): Promise<Response> {
  const {
    id,
    identificationCode,
    product,
    locationCode,
    ownerId,
    owner,
    status,
    situation,
    exactLocationCode,
    createdAtFrom,
    createdAtTo
  } = params

  const axiosParams = {
    ownerID: params.ownerId,
    locationCode,
    ownerId,
    owner,
    status,
    situation,
    exactLocationCode,
    id,
    identificationCode,
    product,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo
      }
    })
  }

  try {
    const response: AxiosResponse<APIResponse> = await apiPrivate.get(`${URL_BASE_OBJECTS}/corporate/object/metrics`, {
      params: axiosParams
    })

    if (!response.data) {
      throw new Error('Error obtaining metrics: Response data is empty')
    }

    const camelResponse: Response = camelcaseKeys(response.data, { deep: true })

    return camelResponse
  } catch (error) {
    console.error('Error fetching metrics:', error)
    throw error
  }
}
