import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  GET_MOVEMENTS_FAILURE,
  GET_MOVEMENTS_REQUEST,
  GET_MOVEMENTS_SUCCESS,
  GetMovementsRequest,
  GetMovementsSuccess,
  MovementsTableCategoryFilter,
  MovementsTableSearchFilter,
  MovementsTableSort,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS
} from '../types/MovementsTable'

const MovementsTableActionCreator = {
  getMovements:
    ({
      pagination,
      sort,
      searchFilters,
      categoryFilter
    }: {
      pagination: Pagination
      sort?: MovementsTableSort
      searchFilters?: MovementsTableSearchFilter[]
      categoryFilter?: MovementsTableCategoryFilter
    }): AppThunk =>
    async (dispatch) => {
      const request: GetMovementsRequest = {
        type: GET_MOVEMENTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          categoryFilter,
          sort
        }
      }

      dispatch(request)

      try {
        const { payments, total } = await API.Payments.payment.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          column: sort?.field,
          order: sort?.direction,
          status: categoryFilter?.paymentStatus.join(),
          paymentType: categoryFilter?.paymentType[0],
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetMovementsSuccess = {
          type: GET_MOVEMENTS_SUCCESS,
          payload: {
            payments,
            total
          }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_MOVEMENTS_FAILURE, dispatch)
      }
    },

  sendCSV:
    ({
      pagination,
      sort,
      searchFilters,
      categoryFilter
    }: {
      pagination: Pagination
      sort?: MovementsTableSort
      searchFilters?: MovementsTableSearchFilter[]
      categoryFilter?: MovementsTableCategoryFilter
    }): AppThunk =>
    async (dispatch) => {
      const LIMIT_REPORT = 50
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')

      dispatch({ type: SEND_CSV_REQUEST })
      try {
        await API.Payments.payment.list({
          limit: LIMIT_REPORT,
          offset: (pagination.page - 1) * pagination.pageSize,
          column: sort?.field,
          order: sort?.direction,
          status: categoryFilter?.paymentStatus.join(),
          paymentType: categoryFilter?.paymentType[0],
          ...searchFiltersToParams(searchFilters),
          format: 'csv'
        })

        dispatch({ type: SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(error, SEND_CSV_FAILURE, dispatch)
      }
    }
}

export default MovementsTableActionCreator
