import React from 'react'
import actionCreators from '../actions/reasonsModal'
import optionsActionCreators from '../actions/optionsModal'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/stepsModals/ReasonsModal'
import { bindActionCreators } from 'redux'

const ReasonsModalContainer = () => {
  const dispatch = useDispatch()
  const ReasonsModalState = useSelector((state) => state.Leads.reasonsModal)
  const {
    table: { config }
  } = useSelector((state) => state.Leads)
  const reasonsActions = bindActionCreators(actionCreators, dispatch)
  const { updateProspect } = bindActionCreators(optionsActionCreators, dispatch)

  const {
    openModal,
    interestReason,
    textReason,
    discardReason,
    optionSelected,
    disabledSelect,
    leadId,
    leadFunnelState,
    loseReason,
    lackInterestReason
  } = ReasonsModalState
  const {
    setOpenModal,
    setInterestReason,
    setTextReason,
    setDiscardReason,
    setCheckSelected,
    setLoseReason,
    setLackInterestReason,
    setOpenInput
  } = reasonsActions

  const handleClose = () => {
    setOpenModal(false, 0)
  }

  const onOk = () => {
    const leadUpdate = {
      id: leadId,
      interestReason,
      discardReason,
      transit: {
        loseReason,
        lackInterestReason,
        textReason: textReason.value
      }
    }
    updateProspect(leadUpdate)
    handleClose()
  }

  const props = {
    openModal,
    config,
    interestReason,
    textReason,
    discardReason,
    optionSelected,
    disabledSelect,
    leadFunnelState,
    loseReason,
    leadId,
    lackInterestReason,
    handleClose,
    setLoseReason,
    setInterestReason,
    setTextReason,
    setDiscardReason,
    setCheckSelected,
    setLackInterestReason,
    setOpenInput,
    onOk
  }

  return <Component {...props} />
}

export default ReasonsModalContainer
