import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import EmailBilledModalActionCreator from '../actionCreators/emailBilledModal'
import { EmailBilledModal, EmailBilledModalProps } from '../components/EmailBilledModal'

const EmailBilledContainer = () => {
  const dispatch = useDispatch()

  const { sendEmailBilled, openEmailBilledModal } = bindActionCreators(EmailBilledModalActionCreator, dispatch)

  const { emailBilledModal } = useSelector((state) => state.BillingV2)

  const { modalOpen, loading, error, billingId } = emailBilledModal

  const handleSend = () => {
    if (billingId) {
      sendEmailBilled(billingId)
    }
  }

  const props: EmailBilledModalProps = {
    modalOpen,
    loading,
    handleSend,
    onCancel: () => openEmailBilledModal({ modalOpen: false }),
    billingId,
    error
  }

  return <EmailBilledModal {...props} />
}

export default EmailBilledContainer
