import React from 'react'
import { Modal, Radio } from 'antd'
import Select from '../../../../components/Select/Select'
import styles from './stepModals.module.scss'
import { Option } from '../../entities'
import { STEP_OPTION } from '../../constants'

export type StepModalProps = {
  openModal: boolean
  interestReasons: Option[]
  discardReasons: Option[]
  interestReason: string
  discardReason: string
  optionSelected: STEP_OPTION
  disabledSelect: boolean
  isDisabled: boolean
  handleClose: () => void
  setInterestReason: (interestReason: string) => void
  setDiscardReason: (discardReason: string) => void
  setOptionSelected: (option: STEP_OPTION) => void
  onOk: () => void
}

const OptionsModal: React.FC<StepModalProps> = ({
  openModal,
  interestReasons,
  discardReasons,
  interestReason,
  discardReason,
  optionSelected,
  disabledSelect,
  isDisabled,
  handleClose,
  setInterestReason,
  setDiscardReason,
  setOptionSelected,
  onOk
}) => {
  const title = (
    <h2 className={styles.modalTitle}>
      ¿La oportunidad presenta o presentó interés en alguno de los servicios de SpaceGuru?
    </h2>
  )

  return (
    <Modal
      visible={openModal}
      destroyOnClose={true}
      onCancel={handleClose}
      className={styles.modal}
      title={title}
      okText="Continuar"
      cancelText="Cancelar"
      onOk={onOk}
      okButtonProps={{ disabled: isDisabled }}>
      <Radio.Group style={{ width: '100%' }} onChange={(e) => setOptionSelected(e.target.value)} value={optionSelected}>
        <div className={styles.radioContainer}>
          <Radio className={styles.radioOption} value={STEP_OPTION.INTEREST}>
            Sí, es un MQL (Lead Calificado)
          </Radio>
          {optionSelected === STEP_OPTION.INTEREST ? (
            <Select
              // @ts-ignore
              selected={interestReasons.find((x) => x.value === interestReason)}
              // @ts-ignore
              options={interestReasons}
              onSelect={(e: any) => setInterestReason(e.value)}
              disabled={disabledSelect}
              placeholder={'Motivo de interés'}
            />
          ) : null}
          <Radio className={styles.radioOption} value={STEP_OPTION.DISCARD}>
            No, no tiene ni tuvo interés por los servicios de SpaceGuru <br />
            <span style={{ paddingLeft: '20px' }}>(Buscaba otra cosa)</span>
          </Radio>
          {optionSelected === STEP_OPTION.DISCARD ? (
            <Select
              // @ts-ignore
              options={discardReasons}
              onSelect={(e: any) => setDiscardReason(e.value)}
              // @ts-ignore
              selected={discardReasons.find((x) => x.value === discardReason)}
              placeholder={'Motivo de descarte'}
            />
          ) : (
            ''
          )}
        </div>
      </Radio.Group>
    </Modal>
  )
}

export default OptionsModal
