import { ProspectSearchTypes } from '../types/prospect'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'

const actions = {
  getInitialProspect(prospectID: number): AppThunk {
    return async function (dispatch) {
      dispatch({ type: ProspectSearchTypes.GET_PROSPECT_REQUEST })
      try {
        const prospect = await API.Mercurio.Prospect.getById({ prospectId: prospectID })
        dispatch(actions.setProspect(prospect))
      } catch (error) {
        handleErrorsWithAction(error, ProspectSearchTypes.GET_PROSPECT_FAILURE, dispatch)
      }
    }
  },
  setProspect: (prospect: Prospect) => ({
    type: ProspectSearchTypes.GET_PROSPECT_SUCCESS,
    payload: { prospect }
  })
}

export default actions
