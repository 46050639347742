import { CancelReason } from '../../../projectApi/TransactionHandler/Operation/Cancel/list'

export interface CancelState {
  operationId: number
  modalOpen: boolean
  sendingData: boolean
  cancelReasonId: number
  cancelReasonsList: CancelReason[]
  error: {
    message: string
    error: boolean
  }
  loadingReasons: boolean
}

export enum GetCancelReasonsTypes {
  GET_CANCEL_REASONS_REQUEST = 'DEPOSIT/CANCEL/GET_CANCEL_REASONS_REQUEST',
  GET_CANCEL_REASONS_SUCCESS = 'DEPOSIT/CANCEL/GET_CANCEL_REASONS_SUCCESS',
  GET_CANCEL_REASONS_FAILURE = 'DEPOSIT/CANCEL/GET_CANCEL_REASONS_FAILURE'
}

export interface GetCancelReasonsRequest {
  type: typeof GetCancelReasonsTypes.GET_CANCEL_REASONS_REQUEST
}

export interface GetCancelReasonsSuccess {
  type: typeof GetCancelReasonsTypes.GET_CANCEL_REASONS_SUCCESS
  payload: { cancelReasonsList: CancelReason[] }
}

export interface GetCancelReasonsFailure {
  type: typeof GetCancelReasonsTypes.GET_CANCEL_REASONS_FAILURE
  payload: { errorMessage: string }
}

export type GetCancelReasonsActions = GetCancelReasonsRequest | GetCancelReasonsSuccess | GetCancelReasonsFailure

export enum CancelOperationType {
  CANCEL_OPERATION_REQUEST = 'DEPOSIT/CANCEL/CANCEL_OPERATION_REQUEST',
  CANCEL_OPERATION_SUCCESS = 'DEPOSIT/CANCEL/CANCEL_OPERATION_SUCCESS',
  CANCEL_OPERATION_FAILURE = 'DEPOSIT/CANCEL/CANCEL_OPERATION_FAILURE'
}

export interface CancelOperationRequest {
  type: typeof CancelOperationType.CANCEL_OPERATION_REQUEST
}

export interface CancelOperationSuccess {
  type: typeof CancelOperationType.CANCEL_OPERATION_SUCCESS
}

export interface CancelOperationFailure {
  type: typeof CancelOperationType.CANCEL_OPERATION_FAILURE
}

export type CancelOperationActions = CancelOperationRequest | CancelOperationSuccess | CancelOperationFailure

export enum ModalDataTypes {
  SET_MODAL_OPEN = 'DEPOSIT/CANCEL/SET_MODAL_OPEN',
  SET_CANCEL_REASON = 'DEPOSIT/CANCEL/SET_CANCEL_REASON'
}

export interface SetModalOpen {
  type: typeof ModalDataTypes.SET_MODAL_OPEN
  payload: {
    isOpen: boolean
    operationId?: number
  }
}

export interface SetCancelReason {
  type: typeof ModalDataTypes.SET_CANCEL_REASON
  payload: { reasonId: number }
}

export type ModalDataActions = SetModalOpen | SetCancelReason

export type CancelActions = GetCancelReasonsActions | CancelOperationActions | ModalDataActions
