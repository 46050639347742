import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import snakecaseKeys from 'snakecase-keys'

export interface UsersReportParams {
  id?: number
  name?: string
  lastName: string
  phone?: string
  alternativePhone?: string
  email?: string
  hasCard?: string
  tyc?: string
  role?: number
  limit?: number
  offset?: number
  countryCode?: CountryIdCode
}

export function users(params: UsersReportParams): Promise<void> {
  const formattedParams = snakecaseKeys({ ...params })

  return apiPrivate
    .get(`${URL_BASE_USERS}/reports/users`, { params: formattedParams })
    .then((response: AxiosResponse<void>) => {})
    .catch((err) => {
      throw err
    })
}
