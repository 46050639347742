import {
  BulkTransferActions,
  BulkTransferStateType,
  GET_DEPOSITS_FAILURE,
  GET_DEPOSITS_SUCCESS,
  GET_DEPOSITS_REQUEST,
  SET_NUMBER_FIELD,
  SET_OPEN_BULK_TRANSFER_MODAL,
  TRANSFER_OBJECT_FAILURE,
  TRANSFER_OBJECT_REQUEST,
  TRANSFER_OBJECT_SUCCESS,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  SET_LOCATIONS
} from '../types/bulkTransfer'

const initialState: BulkTransferStateType = {
  isOpen: false,
  deposits: [],
  fetchingDeposits: false,
  fetchingLocations: false,
  loadingTransferObject: false,
  depositId: 0,
  locationId: 0,
  selectedDeposit: {
    field: 'depositId',
    value: 0
  },
  locations: []
}

const BulkTransferReducer = (
  state: BulkTransferStateType = initialState,
  action: BulkTransferActions
): BulkTransferStateType => {
  switch (action.type) {
    case SET_OPEN_BULK_TRANSFER_MODAL:
      return {
        ...state,
        isOpen: action.payload,
        locations: []
      }
    case TRANSFER_OBJECT_REQUEST:
      return {
        ...state,
        loadingTransferObject: true
      }
    case TRANSFER_OBJECT_SUCCESS:
    case TRANSFER_OBJECT_FAILURE:
      return {
        ...state,
        loadingTransferObject: false
      }
    case SET_NUMBER_FIELD: {
      const { value, field } = action.payload
      return {
        ...state,
        [field]: value
      }
    }
    case SET_LOCATIONS: {
      const { locations } = action.payload
      return {
        ...state,
        locations
      }
    }
    case GET_DEPOSITS_REQUEST:
      return {
        ...state,
        fetchingDeposits: true
      }
    case GET_DEPOSITS_SUCCESS:
      return {
        ...state,
        deposits: action.payload.deposits,
        fetchingDeposits: false
      }
    case GET_DEPOSITS_FAILURE:
      return {
        ...state,
        fetchingDeposits: false
      }
    case GET_LOCATIONS_REQUEST:
      return {
        ...state,
        fetchingLocations: true
      }
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        fetchingLocations: false,
        locations: action.payload.locations
      }
    case GET_LOCATIONS_FAILURE:
      return {
        ...state,
        fetchingLocations: false,
        locations: []
      }
    default:
      return state
  }
}

export default BulkTransferReducer
