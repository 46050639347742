import React, { Component } from 'react'
import { URL_BASE_CATEGORIES } from '../../endpoints'
import { Modal, Spin } from 'antd'
import Dropzone from 'react-dropzone'
import { apiPrivate } from '../../api'
import { TableActionBar } from '../../components/TableActionBar'

const initialState = {
  open: false,
  description: '',
  photo: null,
  uploading: false
}

const styles = {
  button: {
    backgroundColor: '#5d58f7',
    border: 'none',
    color: 'white',
    padding: '5px 37px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px'
  },
  placeholder: {
    display: 'inline-block'
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inlineSelect: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '70%'
  },
  cont: {
    marginBottom: '6px'
  },
  dropzone: {
    display: 'flex',
    height: '200px',
    margin: 'auto',
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '5px',
    marginTop: '6px'
  },
  dropzoneContainer: {
    margin: 'auto',
    textAlign: 'center'
  },
  dropzoneHeader: {
    margin: 'auto',
    fontSize: '20px'
  },
  dropzoneText: {
    fontSize: '12px'
  },
  imgBig: {
    maxHeight: '190px',
    maxWidth: 'inherit'
  },
  centerThings: {
    width: '95%',
    textAlign: 'center'
  }
}

class AddButton extends Component {
  constructor(props) {
    super(props)

    this.state = initialState

    this.handleOpen = this.handleOpen.bind(this)
    this.create = this.create.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.changeDescription = this.changeDescription.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  create() {
    if (this.state.photo === null) {
      alert('Debes agregar una foto')
      return
    }

    const NewCategory = new FormData()

    if (
      this.state.photo.type === 'image/png' ||
      this.state.photo.type === 'image/jpg' ||
      this.state.photo.type === 'image/jpeg'
    ) {
      NewCategory.append('category_picture', this.state.photo)
    } else {
      alert('El formato de la imagen es erróneo')
      return
    }

    NewCategory.append('father', 1)
    NewCategory.append('description', this.state.description)

    this.setState({
      uploading: true
    })

    return apiPrivate
      .post(URL_BASE_CATEGORIES + '/category', NewCategory)
      .then((response) => {
        this.handleClose()
      })
      .catch((error) => {
        if (error.response.data.description) {
          alert(error.response.data.description)
        }

        this.setState({
          uploading: false
        })
      })
  }

  handleOpen() {
    this.setState({
      open: true,
      photo: null,
      uploading: false
    })
  }

  handleClose() {
    this.setState({
      open: false,
      description: '',
      photo: null,
      uploading: false
    })

    this.props.renderAgain()
  }

  changeDescription(event, value) {
    this.setState({
      description: event.target.value
    })
  }

  onDrop(file) {
    this.setState({
      photo: file[0]
    })
  }

  render() {
    const accept = {
      'image/*': ['.png', '.jpeg', '.jpg']
    }
    return (
      <div>
        <TableActionBar hideBorder>
          <TableActionBar.Spacer />
          <TableActionBar.ButtonAction titleButton={'Agregar categoría'} onClickButton={this.handleOpen} />
        </TableActionBar>
        <Modal
          title="Agregar Categoría"
          visible={this.state.open}
          onOk={this.create}
          onCancel={this.handleClose}
          okText={'Agregar'}
          cancelText={'Cancelar'}>
          {!this.state.uploading ? (
            <div>
              <div style={styles.cont}>
                <span style={styles.placeholder}>Descripción: </span>
                <input
                  style={styles.inline}
                  type="text"
                  placeholder="Descripción"
                  value={this.state.description}
                  onChange={this.changeDescription}
                />
              </div>

              <div>
                <Dropzone multiple={false} accept={accept} onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={styles.dropzone}>
                      <input {...getInputProps()} />
                      <div style={styles.dropzoneContainer}>
                        {this.state.photo !== null ? (
                          <div>
                            <img style={styles.imgBig} src={URL.createObjectURL(this.state.photo)}></img>
                          </div>
                        ) : (
                          <div>
                            <p style={styles.dropzoneHeader}>Subida de imagen</p>
                            <p style={styles.dropzoneText}>Arrastra la imagen o haz click aquí</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          ) : (
            <div style={styles.centerThings}>
              <Spin />
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

export default AddButton
