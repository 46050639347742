import { AnnualMetricTypes } from '@/sections/Dashboard/types/Dashboard.types'
import { ChartInfo } from '../types/LicheChart.types'

type ChartDataCategory = {
  title: string
  info: ChartInfo[]
}

type MonthData = {
  name: string
  fullMonth: string
} & Partial<AnnualMetricTypes>

export type ChartDataFormatted = {
  title: string
  info: ChartInfo[]
  dataChart: MonthData[]
}

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

export function formatAnnualChartData(
  annualMetrics: AnnualMetricTypes[],
  chartDataSchema: ChartDataCategory[]
): ChartDataFormatted[] {
  const chartDataSchemaFormatted = chartDataSchema.map((category) => {
    const dataKeys = category.info.map((item) => ({
      key: item.dataKey as keyof AnnualMetricTypes,
      formatNumber: item.formatNumber
    }))

    const dataChart = annualMetrics.map((metric, index) => {
      const monthData: MonthData = {
        name: monthNames[index].slice(0, 3),
        fullMonth: monthNames[index]
      }

      dataKeys.forEach(({ key, formatNumber }) => {
        const rawValue = metric[key]
        monthData[key] = formatNumber ? parseInt(formatNumber(rawValue, 2)) : rawValue
      })
      return monthData
    })

    return {
      ...category,
      dataChart
    }
  })

  return chartDataSchemaFormatted
}
