import { Reducer } from 'redux'
import {
  DeleteLocationTypes,
  LocationDeleteActions,
  LocationDeleteStateType,
  SetModalLocationTypes
} from '../types/LocationDelete'

const initialState: LocationDeleteStateType = {
  location: null,
  loading: false,
  isModalOpen: false,
  error: ''
}

const LocationDeleteReducer: Reducer<LocationDeleteStateType, LocationDeleteActions> = (
  state: LocationDeleteStateType = initialState,
  action: LocationDeleteActions
): LocationDeleteStateType => {
  switch (action.type) {
    case DeleteLocationTypes.DELETE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case DeleteLocationTypes.DELETE_LOCATION_SUCCESS: {
      return initialState
    }
    case DeleteLocationTypes.DELETE_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    case SetModalLocationTypes.SET_MODAL:
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
        location: action.payload.location,
        error: ''
      }

    default:
      return state
  }
}

export default LocationDeleteReducer
