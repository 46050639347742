import {
  MovementListSortKey,
  MovementsListCategoryFilterKey,
  Payment,
  SearchableParams
} from '../../../projectApi/Payments/Payment/list'
import { CategoryFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type MovementsTableSearchKey = keyof SearchableParams
export type MovementsTableSearchFilter = SearchFilter<MovementsTableSearchKey>

export type MovementsTableSortKey = MovementListSortKey
export type MovementsTableSort = Sort<MovementListSortKey>

export type MovementsTableCategoryFilter = CategoryFilter<MovementsListCategoryFilterKey>

export interface MovementsTableState {
  payments: Payment[]
  total: number
  loading: boolean
  sendingCSV: boolean
  pagination: Pagination
  error: string
  categoryFilter: MovementsTableCategoryFilter
  searchFilters: MovementsTableSearchFilter[]
  sort: MovementsTableSort
}

export const GET_MOVEMENTS_REQUEST = 'MOVEMENTS/MOVEMENTS_TABLE/GET_MOVEMENTS_REQUEST'
export const GET_MOVEMENTS_SUCCESS = 'MOVEMENTS/MOVEMENTS_TABLE/GET_MOVEMENTS_SUCCESS'
export const GET_MOVEMENTS_FAILURE = 'MOVEMENTS/MOVEMENTS_TABLE/GET_MOVEMENTS_FAILURE'

export interface GetMovementsRequest {
  type: typeof GET_MOVEMENTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: MovementsTableSearchFilter[]
    categoryFilter?: MovementsTableCategoryFilter
    sort?: MovementsTableSort
  }
}

export type Pagination = {
  total: number
  page: number
  pageSize: number
}

export interface GetMovementsSuccess {
  type: typeof GET_MOVEMENTS_SUCCESS
  payload: {
    payments: Payment[]
    total: number
  }
}

export interface GetMovementsFailure {
  type: typeof GET_MOVEMENTS_FAILURE
  payload: {
    error: string
  }
}

export type GetMovementsAction = GetMovementsRequest | GetMovementsSuccess | GetMovementsFailure

export const SEND_CSV_REQUEST = 'MOVEMENTS/MOVEMENTS_TABLE/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'MOVEMENTS/MOVEMENTS_TABLE/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'MOVEMENTS/MOVEMENTS_TABLE/SEND_CSV_FAILURE'

export interface SendCSVRequest {
  type: typeof SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SEND_CSV_FAILURE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export type MovementsTableAction = GetMovementsAction | SendCSVActions
