// Icons: https://simplelineicons.github.io/
import { Permissions } from '../UserLogged/permissions'
import routes from '../../routes'
import { Either } from '../../utils/utilityTypes'
import { DepositIcon, Truck } from '../../icons'

export enum NavSelectionType {
  SELECTED = 'selected',
  HAS_SELECTED_SUBITEM = 'hasSelectedSubitem',
  NOT_SELECTED = 'notSelected'
}

export type NavItem = {
  name: string
  permission?: Permissions
  hidden?: boolean
} & Either<{ url: keyof typeof routes }, { subitems?: NavItem[] }> &
  Either<{ icon: string }, { svg?: React.ReactNode }>

export const navItems = ({ corporative }: { corporative: boolean }): NavItem[] => [
  {
    name: 'Configuración',
    icon: 'icon-wrench',
    subitems: [
      {
        name: 'Productos',
        url: '/products',
        icon: 'icon-social-dropbox',
        permission: Permissions.VerProductos
      },
      {
        name: 'Atributos',
        url: '/attributes',
        icon: 'icon-social-dropbox',
        permission: Permissions.VerProductos
      },
      {
        name: 'Categorías',
        url: '/categories',
        icon: 'icon-grid',
        permission: Permissions.VerCategorias
      },
      {
        name: 'Flags',
        url: '/flags',
        icon: 'icon-flag',
        permission: Permissions.VerFlags
      },
      {
        name: 'Tipos de servicios',
        url: '/services',
        icon: 'icon-list',
        permission: Permissions.VerTiposDeServicios
      },
      {
        name: 'Descuentos',
        url: '/discounts',
        icon: 'icon-list',
        permission: Permissions.VerDescuentos
      },
      {
        name: 'Calendario de descuentos',
        url: '/discountDays',
        icon: 'icon-list',
        permission: Permissions.VerDescuentos
      },
      {
        name: 'Descuentos por fecha',
        url: '/discountDates',
        icon: 'icon-list',
        permission: Permissions.VerDescuentos
      },

      {
        name: 'Calendario de operaciones',
        url: '/operation_days',
        icon: 'icon-calendar',
        permission: Permissions.VerCalendarioDeOperaciones
      }
    ]
  },
  {
    name: 'Gestión de usuarios',
    icon: 'icon-user',
    subitems: [
      {
        name: 'Usuarios Corporativos',
        url: '/corporateUsers',
        icon: 'icon-user',
        permission: Permissions.VerUsuariosCuentaCorporativa
      },
      {
        name: 'Invitaciones de usuarios',
        icon: 'icon-envelope',
        url: '/pendingUsers',
        permission: Permissions.VisualizarMisInvitaciones
      }
    ]
  },
  {
    name: 'Comercial',
    icon: 'icon-phone',
    subitems: [
      {
        name: 'Oportunidades',
        icon: 'icon-speech',
        url: '/leads',
        permission: Permissions.VerOportunidades
      },
      {
        name: 'Usuarios',
        url: '/users',
        icon: 'icon-user',
        permission: Permissions.VerUsuarios
      },
      {
        name: 'Cotizaciones',
        url: '/proposals',
        icon: 'icon-list',
        permission: Permissions.VerCotizaciones
      },
      {
        name: 'Reclamos',
        url: '/complaints',
        icon: 'icon-list',
        permission: Permissions.ListarReclamos
      },
      {
        name: 'Agentes',
        icon: 'icon-earphones-alt',
        url: '/agents',
        permission: Permissions.VerAgentes
      },
      {
        name: 'Orígenes',
        icon: 'icon-magnet',
        url: '/origins',
        permission: Permissions.VerOrigenes
      }
    ]
  },
  {
    name: 'Objetos',
    url: '/objects',
    icon: 'icon-diamond',
    permission: Permissions.VerObjetos
  },
  {
    name: 'Productos',
    url: '/corporative/products',
    icon: 'icon-social-dropbox',
    permission: Permissions.VerProductosCorporativosBO
  },
  {
    name: 'Objetos',
    url: '/corporative/objects',
    icon: 'icon-diamond',
    permission: Permissions.VerObjetosCorporativos
  },
  {
    name: 'Solicitudes',
    url: '/requests',
    icon: 'icon-grid',
    permission: Permissions.VerSolicitudesGenericas
  },
  {
    name: 'Operaciones',
    icon: 'icon-list',
    subitems: [
      {
        name: 'Ingresos',
        url: '/deposits',
        icon: 'icon-list',
        permission: Permissions.VerIngresos
      },
      {
        name: 'Búsquedas',
        url: '/pickings',
        icon: 'icon-list',
        permission: Permissions.VerBusquedas
      },
      {
        name: 'Devoluciones',
        url: '/removals',
        icon: 'icon-list',
        permission: Permissions.VerDevoluciones
      }
    ]
  },
  {
    name: 'Pendiente OPS',
    icon: 'icon-hourglass',
    subitems: [
      {
        name: 'Registrar Objetos',
        url: '/register_objects',
        icon: 'icon-note',
        permission: Permissions.VerRegistrarObjetos
      },
      {
        name: 'Fotografiar Objetos',
        url: '/photograph_objects',
        icon: 'icon-camera',
        permission: Permissions.VerFotografiarObjetos
      },
      {
        name: 'Validar ingresos',
        url: '/depositsValidation',
        icon: 'icon-list',
        permission: Permissions.VerValidarIngresos
      },
      {
        name: 'Validar devoluciones',
        url: '/removalsValidation',
        icon: 'icon-list',
        permission: Permissions.VerValidarDevoluciones
      }
    ]
  },
  {
    name: 'Transporte',
    svg: Truck,
    subitems: [
      {
        name: 'Vehículos',
        url: '/vehicles',
        svg: Truck,
        permission: Permissions.ABMVehiculos
      },
      {
        name: 'Transacciones',
        url: '/transactions',
        icon: 'icon-calculator',
        permission: Permissions.VerTransacciones
      },
      {
        name: 'Viajes',
        url: '/trips',
        icon: 'icon-plane',
        permission: Permissions.VerViajes
      },
      {
        name: 'Proveedores',
        url: '/providers',
        svg: Truck,
        permission: Permissions.VerViajes
      },
      {
        name: 'Transportistas',
        url: '/shippers',
        svg: Truck,
        permission: Permissions.ABMTransportistas
      }
    ]
  },
  {
    name: 'Gestión de depósitos',
    svg: DepositIcon,
    subitems: corporative
      ? [
          {
            name: 'Ubicaciones',
            icon: 'icon-location-pin',
            url: '/corporative/locations',
            permission: Permissions.VerUbicacionesBO
          },
          {
            name: 'Depósitos',
            svg: DepositIcon,
            url: '/corporative/deposits',
            permission: Permissions.VerDepositosCorp
          }
        ]
      : [
          {
            name: 'Ubicaciones',
            icon: 'icon-location-pin',
            url: '/locations',
            permission: Permissions.VerDepositos
          },
          {
            name: 'Depósitos',
            svg: DepositIcon,
            url: '/storageDeposits',
            permission: Permissions.VerTodosLosDepositos
          }
        ]
  },
  {
    name: 'Movimientos',
    url: '/movements',
    icon: 'icon-book-open',
    permission: Permissions.VerMovimientos
  },
  {
    name: 'Cobranza',
    icon: 'icon-wallet',
    subitems: [
      {
        name: 'Facturación',
        url: '/billing',
        icon: 'icon-docs',
        permission: Permissions.VerFacturacion
      },
      {
        name: 'Reglas',
        url: '/payments',
        icon: 'icon-tag',
        permission: Permissions.VerReglas
      },
      {
        name: 'Actividad',
        url: '/payments_history',
        icon: 'icon-notebook',
        permission: Permissions.VerActividad
      },
      {
        name: 'Facturas Colppy',
        icon: 'icon-docs',
        url: '/billing_payment',
        permission: Permissions.ConsultarDeudasTerceros
      },
      {
        name: 'Abonos',
        url: '/subscriptions',
        icon: 'icon-docs',
        permission: Permissions.VerFacturacion
      }
    ]
  },
  {
    name: 'Franjas de precios',
    icon: 'icon-menu',
    url: '/priceBands',
    permission: Permissions.VerFranjasDePrecios
  },
  {
    name: 'Desarrollo',
    icon: 'icon-game-controller',
    url: '/development'
  }
]
