import React from 'react'
import styles from './HeaderIcon.module.scss'
import clsx from 'clsx'

const HeaderIcon: React.FC<{ overlayOpen: boolean; active: boolean }> = ({ children, overlayOpen, active }) => (
  <div className={clsx(styles.HeaderIcon, overlayOpen && styles.OverlayOpen, active && styles.OverlayActive)}>
    {children}
  </div>
)

export default HeaderIcon
