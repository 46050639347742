import React, { useEffect, useMemo } from 'react'

import actionCreators from '../actions/modalLeads'
import { useDispatch, useSelector } from 'react-redux'
import CreateLead from '../components/ModalLeads'
import { bindActionCreators } from 'redux'
import { pathOr } from 'ramda'
import { FunnelState as funnelStates } from '../constants'
import itemActions from '../../NewProposal/actions/items'
import { LoseReasonOption, Option, Source } from '../entities'

const CreateLeadContainer = () => {
  const dispatch = useDispatch()
  const modalProps = useSelector((state) => state.Leads.modal)
  const {
    table: { config }
  } = useSelector((state) => state.Leads)
  const countries = useSelector((state) => state.CountrySelector.countries)
  const modalActions = bindActionCreators(actionCreators, dispatch)
  const getActualM3Storage = bindActionCreators(itemActions.getActualM3Storage, dispatch)
  const { actualM3Storage } = useSelector((state) => state.NewProposal.items)

  const { open, dataLead, editMode } = modalProps
  const {
    id,
    selectedCountry,
    email,
    gender,
    phone,
    name,
    lastName,
    sourceChannel,
    contactMedium,
    discardReason,
    interestReason,
    funnelState,
    loseReason,
    loseReasonMQL,
    loseReasonSQL,
    freeLoseReason,
    userId
  } = dataLead

  const {
    setCreateModalOpen,
    setCountry,
    setEmail,
    setGender,
    setPhone,
    setName,
    setLastName,
    setSourceChannel,
    setContactMedium,
    setDiscardReason,
    setInterestReason,
    createLead,
    editLead,
    setLoseReasonMQL,
    setLoseReasonSQL,
    setCountryCode,
    setCodeArea,
    setPhoneNumber,
    setErrorPhone
  } = modalActions

  const handleClose = () => {
    setCreateModalOpen(false, selectedCountry)
  }

  const countryPref = {
    ARG: '+541111111111',
    ESP: '+34111111111'
  }

  useEffect(() => {
    if (userId) {
      getActualM3Storage(userId)
    }
  }, [userId])

  const getReasonsLose = config?.loseReasonsNew ?? []

  const loseOptionsMQL = useMemo(() => {
    return getReasonsLose
      .filter((reason: LoseReasonOption) => reason.state.qualified === funnelStates.MQLP)
      .map((mqlReason: LoseReasonOption) => {
        return { label: mqlReason.spanishDisplay, value: mqlReason.id }
      })
  }, [getReasonsLose])

  const loseOptionsSQL = useMemo(() => {
    return getReasonsLose
      .filter((reason: LoseReasonOption) => reason.state.qualified === funnelStates.SQLP)
      .map((mqlReason: LoseReasonOption) => {
        return { label: mqlReason.spanishDisplay, value: mqlReason.id }
      })
  }, [getReasonsLose])

  const getOptions = (value: string) => {
    const options = pathOr({}, [value], config)
    return Object.entries(options).map(([key, value]) => ({
      label: value,
      value: Number(key)
    }))
  }

  const getSourceList: Option[] = useMemo(() => {
    const sourcesFilter = config.sourcesFilter
    if (sourcesFilter && Array.isArray(sourcesFilter))
      return sourcesFilter
        .filter((source: Source) => source.available && source)
        .map((source: Source) => ({ label: source.spanishDisplay, value: String(source.id) }))
    else return []
  }, [config.sources])

  const sources = getSourceList
  const contactMediums = getOptions('contactMediums')
  const discardReasons = getOptions('discardReasons')
  const interestReasons = getOptions('interestReason')

  const onOk = () => {
    const phoneValue =
      phone.phoneNumber.value !== '' &&
      phone.countryCode + phone.codeArea.value + phone.phoneNumber.value !== phone.currentNumber
        ? phone.countryCode + phone.codeArea.value + phone.phoneNumber.value
        : phone.currentNumber

    if (!editMode) {
      const createDataLead = {
        country: selectedCountry.fullName,
        email: email.value,
        phone: phoneValue,
        gender,
        name: name.value,
        lastName: lastName.value,
        source: sourceChannel,
        contactMedium
      }
      createLead(createDataLead)
    }
    if (editMode) {
      const editDataLead = {
        id,
        interestReason,
        discardReason,
        updated: {
          country: selectedCountry.fullName,
          email: email.value,
          phone: phoneValue,
          gender,
          name: name.value,
          lastName: lastName.value,
          source: sourceChannel,
          contactMedium,
          loseReasonMQL,
          loseReasonSQL
        }
      }
      editLead(editDataLead)
    }
  }

  const okDisabled =
    modalProps.loadingLead ||
    Boolean(email.errorMessage) ||
    Boolean(phone.errorMessage) ||
    phone.phoneNumber.error ||
    email.value === '' ||
    sourceChannel === '' ||
    (phone.currentNumber !== '' ? false : phone.phoneNumber.value === '') ||
    (editMode
      ? phone.countryCode + phone.codeArea.value + phone.phoneNumber.value !== phone.currentNumber &&
        phone.phoneNumber.error
      : false)

  const props = {
    open,
    selectedCountry,
    email,
    gender,
    phone,
    name,
    lastName,
    countries,
    actualM3Storage,
    sources,
    contactMediums,
    sourceChannel,
    contactMedium,
    discardReasons,
    discardReason,
    interestReasons,
    interestReason,
    editMode,
    countryPref,
    okDisabled,
    funnelState,
    loseReason,
    loseOptionsMQL,
    loseReasonMQL,
    loseOptionsSQL,
    loseReasonSQL,
    freeLoseReason,
    handleClose,
    setCountry,
    setEmail,
    setGender,
    setPhone,
    setName,
    setLastName,
    setSourceChannel,
    setContactMedium,
    setDiscardReason,
    setInterestReason,
    setLoseReasonMQL,
    setLoseReasonSQL,
    setCountryCode,
    setCodeArea,
    setPhoneNumber,
    setErrorPhone,
    onOk
  }

  return <CreateLead {...props} />
}

export default CreateLeadContainer
