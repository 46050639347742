import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AddEditLocationModal from '../components/AddEditLocationModal'
import AddEditLocationModalActionCreators from '../actions/AddEditLocationModal'
import { CreateLocationParams } from '../../../projectApi/TransactionHandler/Locations/create'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const addEditLocationModalActions = bindActionCreators(AddEditLocationModalActionCreators, dispatch)
  const addEditLocationModalState = useSelector((state) => state.Locations.addEditLocationModal)

  const { createLocation, editLocation, getDeposits } = addEditLocationModalActions
  const { isOpen, isEdit, locationId, depositId } = addEditLocationModalState

  useEffect(() => {
    if (isOpen) {
      addEditLocationModalActions.getLocationTypes()
      addEditLocationModalActions.getDeposits({ id: depositId })
    }
  }, [isOpen, locationId])

  const onOkClick = (values: CreateLocationParams) => {
    if (!isEdit) {
      createLocation({
        code: values.code,
        depositId: values.depositId,
        description: values.description,
        maxHeightInCm: values.maxHeightInCm,
        type: values.type,
        maxLengthInCm: values.maxLengthInCm,
        maxVolumeInCm: values.maxVolumeInCm,
        maxWeightInKg: values.maxWeightInKg,
        maxWidthInCm: values.maxWidthInCm,
        locationId: values.locationId
      })
    } else {
      editLocation({
        code: values.code,
        description: values.description,
        maxHeightInCm: values.maxHeightInCm,
        type: values.type,
        maxLengthInCm: values.maxLengthInCm,
        maxVolumeInCm: values.maxVolumeInCm,
        maxWeightInKg: values.maxWeightInKg,
        maxWidthInCm: values.maxWidthInCm,
        locationId: values.locationId
      })
    }
  }

  const onDepositInputChange = (name: string) => {
    if (name.length < 2) {
      if (name.length > 0) {
        getDeposits({})
      }
    } else {
      getDeposits({ name })
    }
  }

  const props = {
    addEditLocationModalActions,
    addEditLocationModalState,
    onOkClick,
    onDepositInputChange
  }

  return <AddEditLocationModal {...props} />
}

export default Container
