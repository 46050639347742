import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Pagination, searchFiltersToParams, dateRangeFiltersToParams } from '../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/donationsTable'
import {
  DonationsSearchFilter,
  GetDonationListRequest,
  GetDonationListSuccess,
  GetDonationsListTypes,
  RESET_FILTERS,
  SendDonationsCSVTypes,
  SendDonationsCSVRequest,
  SendDonationsCSVSuccess,
  DonationsTableSort,
  DonationsTableCategoryFilter,
  DonationDateRangeFilter
} from '../types/donationsTable'

const DonationTableActionsCreator = {
  getDonations({
    pagination,
    sort,
    searchFilters,
    categoryFilter,
    dateRangeFilters,
    showDemoUsers
  }: {
    pagination: Pagination
    sort: DonationsTableSort
    searchFilters?: DonationsSearchFilter[]
    categoryFilter?: DonationsTableCategoryFilter
    dateRangeFilters?: DonationDateRangeFilter[]
    showDemoUsers?: boolean
  }): AppThunk {
    return async (dispatch) => {
      const request: GetDonationListRequest = {
        type: GetDonationsListTypes.GET_DONATION_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          sort,
          searchFilters,
          dateRangeFilters,
          categoryFilter,
          showDemoUsers
        }
      }

      dispatch(request)
      try {
        const { donationRequests, total } = await API.Sales.DonationRequest.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters),
          column: sort.field,
          order: sort.direction,
          status: categoryFilter?.status.join(),
          showDemoUsers
        })

        const success: GetDonationListSuccess = {
          type: GetDonationsListTypes.GET_DONATION_SUCCESS,
          payload: {
            donations: donationRequests,
            total
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetDonationsListTypes.GET_DONATION_FAILURE, dispatch)
      }
    }
  },

  sendDonationsCSV({
    pagination,
    searchFilters,
    categoryFilter,
    dateRangeFilters
  }: {
    pagination: Pagination
    searchFilters?: DonationsSearchFilter[]
    categoryFilter?: DonationsTableCategoryFilter
    dateRangeFilters?: DonationDateRangeFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: SendDonationsCSVRequest = {
        type: SendDonationsCSVTypes.SEND_DONATIONS_CSV_REQUEST
      }

      const toast = setToastLoading('Enviando CSV. Por favor espere...')

      dispatch(request)

      try {
        await API.Sales.DonationRequest.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          status: categoryFilter?.status.join(),
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters),
          format: 'csv'
        })

        const success: SendDonationsCSVSuccess = {
          type: SendDonationsCSVTypes.SEND_DONATIONS_CSV_SUCCESS
        }

        dispatch(success)

        setToastSuccessUpdate(toast, { render: 'CSV enviado. Revise su correo electrónico.' })
      } catch (error) {
        setToastErrorUpdate(toast, {
          render:
            handleErrorsWithAction(error, SendDonationsCSVTypes.SEND_DONATIONS_CSV_FAILURE, dispatch) ??
            'Hubo un error al enviar el CSV. Por favor, intente más tarde.'
        })
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.Donations.CLEAN_FILTERS), 50)
    }
  }
}

export default DonationTableActionsCreator
