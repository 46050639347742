import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import {
  CANCEL_EDIT_ORIGIN_MODAL,
  CLOSE_EDIT_ORIGIN_MODAL,
  CancelEditOriginModal,
  CloseEditOriginModal,
  EDIT_ORIGIN_FAILURE,
  EDIT_ORIGIN_REQUEST,
  EDIT_ORIGIN_SUCCESS,
  EditOriginRequest,
  EditOriginSuccess,
  OPEN_EDIT_ORIGIN_MODAL,
  OpenEditOriginModal,
  SET_CHANNEL,
  SET_SIRENA_SOURCE,
  SetChannel,
  SetSirenaSource
} from '../types/editOrigin'

const editOriginActionCreators = {
  openEditOriginModal: ({
    id,
    sirenaSource,
    channel
  }: {
    id: number
    sirenaSource: string
    channel: number | string
  }): OpenEditOriginModal => {
    return {
      type: OPEN_EDIT_ORIGIN_MODAL,
      payload: {
        id,
        sirenaSource,
        channel
      }
    }
  },

  cancelEditOriginModal: (): CancelEditOriginModal => ({
    type: CANCEL_EDIT_ORIGIN_MODAL
  }),

  closeEditOriginModal: (): CloseEditOriginModal => ({
    type: CLOSE_EDIT_ORIGIN_MODAL
  }),

  setSirenaSource: ({ sirenaSource }: { sirenaSource: string }): SetSirenaSource => ({
    type: SET_SIRENA_SOURCE,
    payload: { sirenaSource }
  }),

  setChannel: ({ channel }: { channel: number }): SetChannel => ({
    type: SET_CHANNEL,
    payload: { channel }
  }),

  editOrigin:
    ({ id, sirenaSource, channel }: { id: number; sirenaSource: string; channel: number }): AppThunk =>
    async (dispatch) => {
      const request: EditOriginRequest = {
        type: EDIT_ORIGIN_REQUEST
      }
      const mercurioSource = channel
      dispatch(request)

      try {
        await API.Mercurio.BO.Origins.edit(id, { sirenaSource, mercurioSource })

        const success: EditOriginSuccess = {
          type: EDIT_ORIGIN_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Origins.ORIGINS_UPDATED)
      } catch (error) {
        handleErrorsWithAction(error, EDIT_ORIGIN_FAILURE, dispatch)
      }
    }
}

export default editOriginActionCreators
