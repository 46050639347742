import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actionCreators/complaintsTable'
import Component from '../components/complaintsTable'
import {
  ComplaintsCategoryFilter,
  ComplaintsDateRangeFilter,
  ComplaintsSearchFilter,
  ComplaintsTableDateRangeKey
} from '../types/complaintsTable'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { removeNullishValues, updateMultipleSearchFilters } from '../../../components/DynamicTable/queryParamsUtils'
import { dateRangeFiltersToParams, Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { Moment } from 'moment'
import CreateComplaintModalContainer from './CreateComplaintModal'
import { TableActionBar } from '@/components/TableActionBar'
import ComplaintIcon from '@/icons/complaint'
import { useLocalization } from '@/packages/localization'
import CreateComplaintModalActions from '../actionCreators/CreateComplaintModal'
import { ComplaintsMetrics } from '../components/Metrics'

const ComplaintsTable = () => {
  const dispatch = useDispatch()
  const complaintsActions = bindActionCreators(actions, dispatch)
  const { setOpen } = bindActionCreators(CreateComplaintModalActions, dispatch)

  const { strings } = useLocalization()

  const tableState = useSelector((state) => state.Complaints.complaintsTable)
  const { getComplaints, resetFilters, getReasons, getMetrics } = complaintsActions
  const emptyPagination = { ...tableState.pagination, page: 1 }
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    id: NumberParam,
    depositId: NumberParam,
    ownerId: NumberParam,
    createdAtFrom: StringParam,
    createdAtTo: StringParam,
    updatedAtFrom: StringParam,
    updatedAtTo: StringParam,
    closeDateFrom: StringParam,
    closeDateTo: StringParam,
    statuses: StringParam
  })

  useEffect(() => {
    getReasons()
    getMetrics()
  }, [])

  const handleGetComplaints = (newParams: {
    pagination?: Pagination
    searchFilters?: ComplaintsSearchFilter[]
    dateRangeFilters?: ComplaintsDateRangeFilter[]
    categoryFilter?: ComplaintsCategoryFilter
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter
    }
    getComplaints(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true),
      statuses: actualParams.categoryFilter.status.length > 0 && actualParams.categoryFilter.status
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  useEvents([Events.Complaints.RESETED_FILTERS, Events.Complaints.COMPLAINTS_CREATED], () => {
    handleGetComplaints({})
  })

  const handleDateRangePiker = (key: ComplaintsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newDateRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    getComplaints({
      dateRangeFilters: newDateRangeFilters,
      pagination: emptyPagination
    })
  }

  const handleCleanFilters = () => {
    resetFilters()
    setQuery(removeNullishValues({}), 'push')
  }

  useEffect(() => {
    const searchFilters = updateMultipleSearchFilters(tableState.searchFilters, [
      { key: 'id', text: query.id?.toString() || '' }
    ])

    handleGetComplaints({ searchFilters })
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetComplaints({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleCategoryFilter = (newCategoryFilter: ComplaintsCategoryFilter) => {
    handleGetComplaints({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleSearch = (key: string, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetComplaints({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const pagination = {
    currentPage: tableState.pagination.page,
    pageSize: tableState.pagination.pageSize,
    total: tableState.pagination.total,
    onPageChange: handlePageChange
  }

  const props = {
    complaintsActions,
    complaintsTable: tableState,
    pagination,
    dateRangeFilters: tableState.dateRangeFilters,
    categoryFilter: tableState.categoryFilter,
    handleSearch,
    handleCleanFilters,
    handleDateRangePiker,
    handleCategoryFilter
  }

  return (
    <>
      <ComplaintsMetrics metrics={tableState.metircs} loading={tableState.loadingMetrics} />
      <TableActionBar>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />
        <TableActionBar.ButtonAction
          titleButton={strings.ComplaintsViewScreen.createComplaintModal.title}
          customIcon={<ComplaintIcon />}
          onClickButton={() => setOpen({ open: true })}
        />
      </TableActionBar>

      <Component {...props} />
      <CreateComplaintModalContainer />
    </>
  )
}

export default ComplaintsTable
