import React, { useMemo } from 'react'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ObjectResponse, ObjectsToRemove } from '../../types/objects'
import { formatNumber } from '../../../../utils/formatNumber'
import { DEPOSITED, WITH_OWNER } from '../../../Objects/constants/objectStatus'
import ImgWithCarousel from '../../../../components/CarouselModal/components/ImgWithCarousel'
import { OBJECT_SITUATION_SAVED } from '../../../../projectApi/ObjectAdministration/common'

const styles = {
  container: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center'
  },
  objectDescription: {
    flex: 1,
    padding: '0 12px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 75,
    width: 87,
    cursor: 'pointer'
  },
  image: {
    maxWidth: 87,
    maxHeight: 75
  },
  label: {
    fontWeight: 500 as const,
    color: '#595959'
  },
  productName: {
    fontWeight: 'bold' as const,
    fontSize: '16px',
    marginBottom: 4
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column' as const
  },
  objectStatus: {
    marginTop: 8,
    marginBottom: 0
  }
}

type ObjectToRemoveComponentProps = {
  index: number
  object: ObjectResponse
  objectsToRemove: ObjectsToRemove
  onChange: (object: ObjectResponse) => (e: CheckboxChangeEvent) => void
  toggleCarrouselOpen: (images: string[]) => void
}

const getObjectStatusColor = (objectStatusID: number) => {
  switch (objectStatusID) {
    case DEPOSITED.numberValue:
      return '#52C41A'
    case WITH_OWNER.numberValue:
      return '#FF6272'
    default:
      return '#FAAF19'
  }
}

const ObjectToRemoveComponent = (props: ObjectToRemoveComponentProps) => {
  const { index, object, objectsToRemove, onChange } = props
  const checked: boolean = useMemo(() => {
    const isDeleted: boolean =
      Object.values(objectsToRemove).find((objToRemove) => object.ID === objToRemove.objectDetails.ID)?.deleted || false

    return Boolean(objectsToRemove[object.ID]) && !isDeleted
  }, [objectsToRemove, object.ID])
  const volume = useMemo(() => (object.HeightInCm * object.WidthInCm * object.LengthInCm) / 1000000, [object])
  const identificationCode = useMemo(
    () =>
      object.IdentificationCodes.find((identificationCode) => identificationCode.Actual) ||
      object.IdentificationCodes[0],
    [object]
  )
  const textStatus = object.ObjectStatus.Description
  const objectSituacion = object.ObjectSituation
  const fromRemoval = useMemo(
    () =>
      Object.values(objectsToRemove).find((objToRemove) => object.ID === objToRemove.objectDetails.ID)?.fromRemoval ||
      false,
    [objectsToRemove, object.ID]
  )
  const notDeposited = textStatus !== DEPOSITED.value && !fromRemoval

  const colorStatus = useMemo(() => getObjectStatusColor(object.ObjectStatus.ID), [])

  return (
    <div
      style={{
        ...styles.container,
        ...(index % 2 === 0 ? { backgroundColor: 'white' } : {})
      }}>
      <ImgWithCarousel carouselImages={object.Photos.map((p) => p.Url)} alt={object.Product.Description} />
      <div style={styles.objectDescription}>
        <p style={styles.productName}>{object.Product.Description || `Objeto ${textStatus.toLowerCase()}`}</p>
        <Row align="middle" gutter={4}>
          <Col className="gutter-row" span={6}>
            <span style={styles.label}>ID Objeto:</span> {object.RealID}
          </Col>
          <Col className="gutter-row" span={6}>
            <span style={styles.label}>ID Producto:</span> {object.Product.RealID || '-'}
          </Col>
          <Col className="gutter-row" span={4}>
            <span style={styles.label}>
              m<sup>3</sup>:
            </span>{' '}
            {formatNumber(volume)}
          </Col>
          <Col className="gutter-row" span={8}>
            <span style={styles.label}>Etiqueta:</span> {identificationCode.Code}
          </Col>
        </Row>
      </div>
      <Tooltip
        title={objectSituacion !== OBJECT_SITUATION_SAVED ? 'La situación del objeto puede no ser la adecuada' : ''}>
        <div style={styles.checkboxContainer}>
          <Checkbox
            checked={checked}
            onChange={onChange(object)}
            disabled={notDeposited || objectSituacion !== OBJECT_SITUATION_SAVED}>
            Agregar
          </Checkbox>
          <p style={{ ...styles.objectStatus, color: colorStatus }}>{textStatus}</p>
        </div>
      </Tooltip>
    </div>
  )
}

export default ObjectToRemoveComponent
