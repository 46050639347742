import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export interface Address {
  address_string: string
  apartment: string
  city: string
  comment: string
  floor: string
  number: string
  postal_code: string
  province: string
  street: string
}

export interface Deposit {
  country_id: string
  id: number
  name: string
  address: Address
}

export interface Description {
  deposits: Deposit
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = CamelCasedPropertiesDeep<Description>

export type DepositResponse = CamelCasedPropertiesDeep<Deposit>

interface GetByIdParams {
  depositId: number
}

export function getById({ depositId }: GetByIdParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/deposits/${depositId}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener el deposito')
      if (!response.data.description?.deposits) throw new Error('Respuesta incorrecta al obtener el deposito')
      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
