import { Reducer } from 'redux'
import { STEP_OPTION } from '../constants'
import {
  SET_DISCARD_REASON,
  SET_INTEREST_REASON,
  SET_MODAL_OPEN,
  SET_OPTION_SELECTED,
  StepModalActions,
  StepModalState,
  UPDATED_LEAD,
  UPDATED_LEAD_FAILURE,
  UPDATED_LEAD_SUCCESS
} from '../types/OptionsModal'

const initialStep: StepModalState = {
  openModal: false,
  loading: false,
  error: '',
  interestReason: '',
  discardReason: '',
  optionSelected: STEP_OPTION.NOT_SELECTED,
  disabledSelect: false,
  leadId: 0
}

const stepReducer: Reducer<StepModalState, StepModalActions> = (state = initialStep, action): StepModalState => {
  switch (action.type) {
    case SET_MODAL_OPEN: {
      const { openModal, leadId } = action.payload
      return openModal
        ? {
            ...state,
            openModal,
            leadId
          }
        : initialStep
    }
    case SET_INTEREST_REASON:
      return {
        ...state,
        interestReason: action.payload.interestReason
      }
    case SET_DISCARD_REASON:
      return {
        ...state,
        discardReason: action.payload.discardReason
      }
    case SET_OPTION_SELECTED: {
      const { option } = action.payload

      if (option === STEP_OPTION.DISCARD) {
        return {
          ...state,
          optionSelected: option,
          interestReason: initialStep.interestReason,
          disabledSelect: initialStep.disabledSelect
        }
      }
      if (option === STEP_OPTION.INTEREST) {
        return {
          ...state,
          optionSelected: option,
          discardReason: initialStep.discardReason,
          disabledSelect: initialStep.disabledSelect
        }
      } else {
        return {
          ...state,
          optionSelected: option
        }
      }
    }
    case UPDATED_LEAD:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case UPDATED_LEAD_SUCCESS:
      return initialStep
    case UPDATED_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      }
    default:
      return state
  }
}

export default stepReducer
