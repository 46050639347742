import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import DevelopmentViewActionCreator from '../actions/developmentView'
import { DevelopmentView, DevelopmentViewProps } from '../components/developmentView'
import ObjectLabelModal from '../components/objectLabelModal'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const DevelopmentViewContainer = () => {
  const dispatch = useDispatch()

  const developmentActions = bindActionCreators(DevelopmentViewActionCreator, dispatch)
  const { setObjectLabelModal, clearState } = developmentActions

  const developmentViewState = useSelector((state) => state.DevelopmentReducer.view)
  const { error, isLoading, objectLabel } = developmentViewState
  const { objectLabelModal } = objectLabel

  const handleOpenObjectLabelModal = () => {
    setObjectLabelModal(true)
  }

  const props: DevelopmentViewProps = {
    objectLabelModal,
    error,
    isLoading,
    handleOpenObjectLabelModal
  }

  useEvents([Events.Development.CREATE_OBJECT_LABELS, Events.Development.CLEAN_FILTERS], () => {
    clearState()
  })

  return (
    <>
      <DevelopmentView {...props} />
      <ObjectLabelModal DevelopmentViewState={developmentViewState} developmentActions={developmentActions} />
    </>
  )
}

export default DevelopmentViewContainer
