import React from 'react'
import { InfoIcon } from './info'
import { ScanIcon } from './scan'
import { CameraIcon } from './camera'
import { NextIcon } from './next'
import { BackIcon } from './back'
import { CheckIcon } from './check'
import { CrossIcon } from './cross'
import { ShareIcon } from './share'
import { DownloadIcon } from './download'
import { AddIcon } from './add'
import { DeleteIcon } from './delete'
import { SearchIcon } from './search'

export type IconName =
  | 'info'
  | 'scan'
  | 'camera'
  | 'next'
  | 'back'
  | 'check'
  | 'cross'
  | 'share'
  | 'download'
  | 'add'
  | 'delete'
  | 'search'

export const Icon = ({ name, ...props }: { name: IconName; className: string }) => {
  switch (name) {
    case 'info':
      return <InfoIcon {...props} />
    case 'scan':
      return <ScanIcon {...props} />

    case 'camera':
      return <CameraIcon {...props} />
    case 'next':
      return <NextIcon {...props} />
    case 'back':
      return <BackIcon {...props} />
    case 'check':
      return <CheckIcon {...props} />
    case 'cross':
      return <CrossIcon {...props} />
    case 'share':
      return <ShareIcon {...props} />
    case 'download':
      return <DownloadIcon {...props} />
    case 'add':
      return <AddIcon {...props} />
    case 'delete':
      return <DeleteIcon {...props} />
    case 'search':
      return <SearchIcon {...props} />

    default:
      return null
  }
}
