import { CostTypes, SetApplyDiscount, SetDiscountCode } from '../types/cost'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'

export default {
  setApplyDiscount: (applyDiscount: boolean): SetApplyDiscount => ({
    type: CostTypes.SET_APPLY_DISCOUNT,
    payload: { applyDiscount }
  }),

  setDiscountCode: (discountCode: string): SetDiscountCode => ({
    type: CostTypes.SET_DISCOUNT_CODE,
    payload: { discountCode }
  }),

  fetchDiscount: (code: string): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: CostTypes.FETCH_DISCOUNT_REQUEST })
      const params = {
        code
      }

      try {
        const discount = await API.TransactionHandler.Discounts.getByCode(params)

        dispatch({
          type: CostTypes.FETCH_DISCOUNT_SUCCESS,
          payload: {
            discount
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, CostTypes.FETCH_DISCOUNT_FAILURE, dispatch)
      }
    }
  }
}
