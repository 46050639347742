import React from 'react'

function DotActive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path fill="#5D58F7" fillRule="evenodd" d="M8.25 16a8 8 0 100-16 8 8 0 000 16z" clipRule="evenodd"></path>
      <path fill="#fff" fillRule="evenodd" d="M8.25 12a4 4 0 100-8 4 4 0 000 8z" clipRule="evenodd"></path>
    </svg>
  )
}

export default DotActive
