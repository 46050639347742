import React from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageTooltip from '../../../sections/DepositsValidation/components/ImageTooltip'
import CarouselModal from '../actions/CarouselModal'
import styles from './ImgWithCarousel.module.scss'

type ImgWithCarouselProps = {
  alt: string
  carouselImages: string[]
}

const ImgWithCarousel: React.FC<ImgWithCarouselProps> = ({ alt, carouselImages }) => {
  const dispatch = useDispatch()
  const { setOpen } = bindActionCreators(CarouselModal, dispatch)
  return carouselImages.length && carouselImages ? (
    <div className={styles.wrapper} onClick={() => setOpen(true, carouselImages)}>
      {carouselImages.length > 1 && <div className={styles.moreImages}>+{carouselImages.length - 1}</div>}
      <ImageTooltip src={carouselImages[0]} alt={alt} maxWidth={'50vw'} maxHeight={'50vh'} />
    </div>
  ) : (
    <>-</>
  )
}

export default ImgWithCarousel
