import React from 'react'

export const CheckIcon: React.FC<{ className?: string }> = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.49992 7.99998L7.49992 9.99998L11.4999 5.99998M15.1666 7.99998C15.1666 11.6819 12.1818 14.6666 8.49992 14.6666C4.81802 14.6666 1.83325 11.6819 1.83325 7.99998C1.83325 4.31808 4.81802 1.33331 8.49992 1.33331C12.1818 1.33331 15.1666 4.31808 15.1666 7.99998Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
