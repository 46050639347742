export type CarouselModalState = {
  open: boolean
  images: string[]
}

export const SET_OPEN = 'CAROUSEL_MODAL/SET_OPEN'
export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    imageUrls?: string[]
  }
}

export type CarouselModalAction = SetOpen
