import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'
import { OperationTypeValues } from '../../../../sections/OperationCalendar/types/common'

type UpdateParams = {
  day: string
  operationType: OperationTypeValues
  available: boolean
}

export async function updateOperation(body: UpdateParams) {
  const formattedBody = snakecaseKeys(body)
  return apiPrivate
    .put(`${URL_BASE_TIMESLOT}/operation-days`, formattedBody)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
