import React from 'react'
import camelcaseKeys from 'camelcase-keys'
import { any, find, propEq, propOr } from 'ramda'
import { CountryIdCode } from '../../components/CountrySelector/constants/constants'
import countryConfig from '../../components/Address/country.json'

export const getOptionValue = propOr('', 'value')
export const getOptionString = propOr('', 'label')

export const parsePostalCode = (value, country) => {
  const countryCode = country || CountryIdCode.ARGENTINA
  const length = value.length
  const config = camelcaseKeys(countryConfig[countryCode], { deep: true })

  const countryPostalCodeConfig = config.postalCode.find(
    (postalCodeRule) => postalCodeRule.googleAddressLength === length
  ) || { sliceFrom: 0, sliceTo: 4 }

  const { sliceFrom, sliceTo } = countryPostalCodeConfig
  return value.slice(sliceFrom, sliceTo)
}

export const getAddressByID = (id, addresses) => find(propEq('ID', id))(addresses)

export const isAnyItemSelected = any((item) => item.selectedItem !== null)

export const M3Label = () => (
  <span>
    m<sup>3</sup>
  </span>
)
