import { Moment } from 'moment'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { getErrorMessage, handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  BILL_PERIOD_REQUEST,
  BILL_PERIOD_SUCCESS,
  BillPeriodRequest,
  BillPeriodSuccess,
  GET_BILLINGS_TO_BILL_FAILURE,
  GET_BILLINGS_TO_BILL_REQUEST,
  GET_BILLINGS_TO_BILL_SUCCESS,
  GetBillingsToBillRequest,
  GetBillingsToBillSuccess,
  OPEN_MODAL_BILL_PERIOD,
  OpenModalBillPeriod
} from '../types/billPeriod'
import { sendToastNotificationError, setToastLoading, setToastSuccessInfo } from '../../../utils/notifications'
import axios from 'axios'

export const PaymentModalActionCreator = {
  getBillingsToBill:
    (periodFrom: Moment): AppThunk =>
    async (dispatch) => {
      const request: GetBillingsToBillRequest = {
        type: GET_BILLINGS_TO_BILL_REQUEST
      }
      dispatch(request)

      try {
        const { total } = await API.TransactionHandler.Billings.billingsToBillByPeriod({ periodFrom })
        const success: GetBillingsToBillSuccess = {
          type: GET_BILLINGS_TO_BILL_SUCCESS,
          payload: {
            billingToBillTotal: total
          }
        }

        dispatch(success)
        Emitter.emit(Events.Billing.BILLING_PAID)
      } catch (err) {
        handleErrorsWithAction(err, GET_BILLINGS_TO_BILL_FAILURE, dispatch)
      }
    },
  billPeriod:
    (periodFrom: Moment): AppThunk =>
    async (dispatch) => {
      const request: BillPeriodRequest = {
        type: BILL_PERIOD_REQUEST
      }
      dispatch(request)
      const toast = setToastLoading('Facturando período')

      const billPeriodCb = async () => {
        // Endpoint will respond with 504 since it will execute the billing process and takes more than one minute
        // We need to wrap it in async try / catch function and await for the response, otherwise the error will explode in the frontend
        try {
          await API.TransactionHandler.Billings.authorizations({ periodFrom })
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (e?.code !== '504') {
              sendToastNotificationError(getErrorMessage(e))
            }
          }
        }
      }

      billPeriodCb()
      const success: BillPeriodSuccess = {
        type: BILL_PERIOD_SUCCESS
      }

      setTimeout(() => {
        setToastSuccessInfo(toast, {
          render:
            'Se envío el proceso que factura el período. Esto puede tardar unos minutos. Vuelva en un rato para ver los resultados'
        })
        dispatch(success)
      }, 1000)
    },

  openBillPeriodModal: ({ modalOpen }: { modalOpen: boolean }): OpenModalBillPeriod => ({
    type: OPEN_MODAL_BILL_PERIOD,
    payload: { modalOpen }
  })
}

export default PaymentModalActionCreator
