import clsx from 'clsx'
import React from 'react'
import { Info } from '../../icons'
import styles from './InfoPopup.module.scss'

type InfoPopupProps = {
  visible: boolean
  title: string
  bottomOffset?: number
}

const InfoPopup: React.FC<InfoPopupProps> = ({ children, visible, title, bottomOffset }) => {
  const [minimized, setMinimized] = React.useState(true)

  React.useEffect(() => {
    if (visible) setMinimized(false)
    else setMinimized(true)
  }, [visible])

  return (
    <div
      className={clsx(styles.InfoPopup, visible && styles.visible, minimized && styles.minimized)}
      style={{ bottom: bottomOffset || 32 }}>
      <div className={styles.topBar} onClick={() => minimized && setMinimized(!minimized)}>
        <div className={styles.iconContainer}>
          <Info fill="#0D4DA7" />
        </div>
        <span className={styles.title}>{title}</span>
        <button className={styles.button} onClick={() => setMinimized(!minimized)}>
          <div className={styles.line1}></div>
          <div className={styles.line2}></div>
        </button>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  )
}

export default InfoPopup
