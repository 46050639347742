import { Reducer } from 'redux'
import {
  AddNewCBUModalTypes,
  type CBUModalAction,
  CBUModalCommonTypes,
  type CBUModalState,
  UpdateCBUModalTypes
} from '../types/cbusModal'

const initialState: CBUModalState = {
  modalVisibility: false,
  addingNewCBU: false,
  updatingCBU: false,
  editMode: false,
  asMain: false
}

const CBUModalReducer: Reducer<CBUModalState, CBUModalAction> = (state = initialState, action): CBUModalState => {
  switch (action.type) {
    case CBUModalCommonTypes.SET_MODAL_VISIBILITY: {
      const { isVisible: modalVisibility, editMode, currentCU, userInfo } = action.payload

      return modalVisibility
        ? {
            ...state,
            modalVisibility,
            editMode: editMode || false,
            currentCU,
            userInfo
          }
        : initialState
    }
    case CBUModalCommonTypes.SET_AS_MAIN:
      return {
        ...state,
        asMain: action.payload.check
      }
    case AddNewCBUModalTypes.ADD_NEW_CBU_REQUEST:
      return {
        ...state,
        addingNewCBU: true
      }
    case AddNewCBUModalTypes.ADD_NEW_CBU_SUCCESS:
    case AddNewCBUModalTypes.ADD_NEW_CBU_FAILURE:
      return {
        ...state,
        addingNewCBU: false
      }
    case UpdateCBUModalTypes.UPDATE_CBU_REQUEST:
      return {
        ...state,
        updatingCBU: true
      }
    case UpdateCBUModalTypes.UPDATE_CBU_SUCCESS:
    case UpdateCBUModalTypes.UPDATE_CBU_FAILURE:
      return {
        ...state,
        updatingCBU: false
      }
    default:
      return state
  }
}

export default CBUModalReducer
