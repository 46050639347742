import types from '../types/payment-cbu'
import { apiPrivate } from '../../../api'
import { URL_BASE_PROFILE } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { removeCBUSpaces } from '../../../utils/cbuChecker'
import cbuComponentActions from '../../../components/CBU/actions'

const actions = {
  setCbuID: (cbu_id) => ({ type: types.SET_CBU_ID, payload: { cbu_id } }),

  getCbus: (user_id) => {
    return function (dispatch) {
      dispatch({ type: types.GET_CBUS_REQUEST })

      return apiPrivate
        .get(URL_BASE_PROFILE + `/admin/cbu/user/id/${user_id}`)
        .then((response) => {
          const cbus = response.data.description

          dispatch({
            type: types.GET_CBUS_SUCCESS,
            payload: {
              cbus
            }
          })

          if (cbus.length > 0) {
            const mainCbu = cbus.find((a) => a.main)
            dispatch(actions.setCbuID(mainCbu ? mainCbu.id : cbus[0].id))
          }
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.GET_CBUS_FAILURE, dispatch)
        })
    }
  },

  saveCBU: () => {
    return function (dispatch, getState) {
      dispatch({ type: types.SAVE_CBU_REQUEST })

      const {
        NewRemoval: { userSearch },
        Components: { cbu: cbuReducer }
      } = getState()
      const { user } = userSearch
      const formData = new FormData()

      formData.append('user_id', user.RealID)
      formData.append('main', true)
      formData.append('holder_name', cbuReducer.holderName)
      formData.append('cbu', removeCBUSpaces(cbuReducer.cbu))

      const config = {
        headers: {
          'X-Request-Origin': 'adm-web|operation'
        }
      }

      return apiPrivate
        .post(URL_BASE_PROFILE + '/admin/cbu', formData, config)
        .then((response) => {
          const cbu = response.data.description
          dispatch({
            type: types.SAVE_CBU_SUCCESS,
            payload: {
              cbu
            }
          })
          dispatch(cbuComponentActions.cleanCBU())
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.SAVE_CBU_FAILURE, dispatch)
        })
    }
  }
}

export default actions
