import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

export enum Flag {
  CUSTOM_DATE_TRANSPORT_MULT_ESP = 'custom_date_transport_mult_esp',
  CUSTOM_DATE_TRANSPORT_MULT_ARG = 'custom_date_transport_mult_arg',
  EXTRA_ASSISTANT_PRICE_ESP = 'extra_assistant_price_esp',
  EXTRA_KM_PRICE_ESP = 'extra_km_price_esp',
  EXTRA_ASSISTANT_PRICE_ARG = 'extra_assistant_price_arg',
  EXTRA_KM_PRICE_ARG = 'extra_km_price_arg',
  KG_PRICE_ESP = 'kg_price_esp',
  KG_PRICE = 'kg_price',
  RETRY_CHARGE_DAYS = 'retry_charge_days',
  MIN_BILLABLE_M3 = 'min_billable_m3',
  MAX_VOLUME_DEVIATION = 'max_volume_deviation',
  MIN_VOLUME_DEVIATION = 'min_volume_deviation',
  TRANSPORT_COST_MIN_PRICE = 'transport_cost_min_price',
  CUSTOM_ITEM_MIN_PRICE = 'custom_item_min_price',
  REQUIRE_CARD_FOR_DEPOSIT = 'require_card_for_deposit',
  SALE_COST_ARG = 'sale_cost_arg',
  TOLL_COST_IN_CENTS = 'toll_cost_in_cents'
}

export interface Description {
  value: string
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = string

interface GetValueParams {
  flag: Flag
}

export interface GetValueConfig extends AxiosRequestConfig {
  params: {
    country_code: CountryIdCode
  }
}

export function getValue({ flag }: GetValueParams, config?: GetValueConfig): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/value/${flag}`, config)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true' || !response.data.description?.value)
        throw new Error('No se pudo obtener el valor')

      return response.data.description.value
    })
    .catch((err) => {
      throw err
    })
}
