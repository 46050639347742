import { Reducer } from 'redux'
import {
  DetailsState,
  POPULATE_VIEW_FAILURE,
  POPULATE_VIEW_REQUEST,
  POPULATE_VIEW_SUCCESS,
  ProposalActions,
  REMOVE_SELECTED_PROPOSAL
} from '../types/details'

const initialState: DetailsState = {
  proposal: null,
  deposits: null,
  transport: null,
  prospect: null,
  discount: null,
  servicesType: [],
  loading: false,
  error: ''
}

const ProposalReducer: Reducer<DetailsState, ProposalActions> = (state = initialState, action): DetailsState => {
  switch (action.type) {
    case POPULATE_VIEW_REQUEST:
      return {
        ...initialState,
        loading: true
      }
    case POPULATE_VIEW_SUCCESS:
      return {
        ...state,
        proposal: action.payload.proposal,
        deposits: action.payload.deposits,
        transport: action.payload.transport,
        prospect: action.payload.prospect,
        discount: action.payload.discount,
        servicesType: action.payload.servicesType,
        loading: false
      }
    case POPULATE_VIEW_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case REMOVE_SELECTED_PROPOSAL:
      return {
        ...initialState
      }
    default:
      return { ...state }
  }
}

export default ProposalReducer
