import { Reducer } from 'redux'
import {
  GET_REQUESTS_FAILURE,
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  RequestsAction,
  RequestsState
} from '../types/requestsTable'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const initialState: RequestsState = {
  requests: [],
  loading: false,
  error: '',
  loadingTable: false,
  categoryFilter: { status: [], type: [] },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'userId', text: '' },
    { key: 'objectId', text: '' }
  ],
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  sort: {
    direction: SortDirection.DESC,
    field: 'id'
  },
  showDemoUsers: false
}

export const RequestsReducer: Reducer<RequestsState, RequestsAction> = (
  state = initialState,
  action
): RequestsState => {
  switch (action.type) {
    case GET_REQUESTS_REQUEST:
      return {
        ...state,
        loadingTable: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        sort: action.payload.sort || initialState.sort,
        showDemoUsers: action.payload.showDemoUsers || initialState.showDemoUsers,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload.requests,
        loadingTable: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_REQUESTS_FAILURE:
      return {
        ...state,
        requests: [],
        error: action.payload.error,
        loadingTable: false
      }
    default:
      return state
  }
}
