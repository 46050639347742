export enum ProposalEventsEnum {
  SET_ITEM = 'PROPOSAL/SET_ITEM',
  CLEAN_ITEM = 'PROPOSAL/CLEAN_ITEM',
  REMOVE_ALL_ITEMS = 'PROPOSAL/REMOVE_ALL_ITEMS',
  REMOVE_ITEM = 'PROPOSAL/REMOVE_ITEM',
  MODIFY_ITEM_QUANTITY = 'PROPOSAL/MODIFY_ITEM_QUANTITY',
  GET_PROPOSAL = 'PROPOSAL/GET_PROPOSAL',
  SAVE_PROPOSAL_SUCCESS = 'PROPOSAL/SAVE_PROPOSAL_SUCCESS',
  SAVE_DEPOSIT_SUCCESS = 'PROPOSAL/SAVE_DEPOSIT_SUCCESS'
}

export type ProposalEvents = {
  [ProposalEventsEnum.SET_ITEM]: () => void
  [ProposalEventsEnum.CLEAN_ITEM]: () => void
  [ProposalEventsEnum.REMOVE_ALL_ITEMS]: () => void
  [ProposalEventsEnum.REMOVE_ITEM]: () => void
  [ProposalEventsEnum.MODIFY_ITEM_QUANTITY]: () => void
  [ProposalEventsEnum.GET_PROPOSAL]: () => void
  [ProposalEventsEnum.SAVE_PROPOSAL_SUCCESS]: ({ proposalId }: { proposalId: number }) => void
  [ProposalEventsEnum.SAVE_DEPOSIT_SUCCESS]: () => void
}
