import { Tooltip } from 'antd'
import React from 'react'

type DeletedObjectStatusProps = {
  objectDeletedReason?: string
  objectStatus: string
  deletedDescription: string
}

const DeletedObjectStatus = ({ objectDeletedReason, objectStatus, deletedDescription }: DeletedObjectStatusProps) => {
  const titleComponent = () => {
    return (
      <>
        {objectDeletedReason && <div>Razón: {objectDeletedReason}</div>}
        {deletedDescription}
      </>
    )
  }
  return (
    <Tooltip title={titleComponent}>
      <div style={{ textDecoration: 'line-through' }}>{objectStatus}</div>
      <span style={{ color: 'red' }}>Eliminado</span>
    </Tooltip>
  )
}

export default DeletedObjectStatus
