import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OperationDaysTable } from '../components/operationDaysTable'
import OperationDaysActionCreator from '../actions/operationDaysAction'
import { bindActionCreators } from 'redux'
import EditDayModalContainer from './editDayModalContainer'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../components/TableActionBar'
import { useHistory } from 'react-router'
import OperationCalendarActionsCreator from '../actions/common'

const OperationDaysContainer = () => {
  const dispatch = useDispatch()
  const { daysData, loadingDays } = useSelector((state) => state.OperationCalendar.Days)
  const router = useHistory()

  const { openEditDayModal, getDays } = bindActionCreators(OperationDaysActionCreator, dispatch)

  const { setAvailableOperations } = bindActionCreators(OperationCalendarActionsCreator, dispatch)

  useEffect(() => {
    getDays()
  }, [])

  useEvents(Events.OperationCalendar.OPERATION_DAY_UPDATED, () => {
    getDays()
  })

  const handleChangeRoute = () => {
    return router.push('/operation_dates')
  }

  const daysTableProps = {
    daysData,
    openEditDayModal,
    loadingDays,
    setAvailableOperations
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.Spacer />
        <TableActionBar.ButtonAction
          titleButton="Fechas especificas"
          iconType="calendar"
          onClickButton={handleChangeRoute}
        />
      </TableActionBar>
      <OperationDaysTable {...daysTableProps} />
      <EditDayModalContainer />
    </>
  )
}

export default OperationDaysContainer
