import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { apiPrivate } from './../../../api'
import { SearchableParams } from './list'
import camelcaseKeys from 'camelcase-keys'
import { formatDates } from '../../utils'

interface MetricsParams extends SearchableParams {
  status?: string
  situation?: string
}

export type ObjectsMetricSituations = {
  saved: number
  donated: number
  sale: number
  soldOut: number
  savedNotValidated: number
}

type APIResponse = {
  total_object: number
  total_m3: number
  situations: ObjectsMetricSituations
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export function metrics(params: MetricsParams): Promise<Response> {
  const {
    id,
    identificationCode,
    product,
    location,
    locationCode,
    ownerId,
    owner,
    status,
    situation,
    exactLocationCode,
    createdAtFrom,
    createdAtTo
  } = params

  const solicitedBackLocation = location === 'true' ? '' : 'null'

  const axiosParams = {
    ownerID: params.ownerId,
    location: solicitedBackLocation,
    locationCode,
    ownerId,
    owner,
    status,
    situation,
    exactLocationCode,
    id,
    identificationCode,
    product,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo
      }
    })
  }

  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/object/metrics`, {
      params: axiosParams
    })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) {
        throw new Error('Hubo un error al obtener las métricas')
      }

      const camelResponse: Response = camelcaseKeys(response.data, { deep: true })
      return camelResponse
    })
    .catch((error) => {
      throw error
    })
}
