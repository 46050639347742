import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { getSearchProps, parseMomentDate } from '../../../components/DynamicTable/utils/utils'
import {
  ComplaintsCategoryFilter,
  ComplaintsDateRangeFilter,
  ComplaintsListStateType,
  ComplaintsTableDateRangeKey
} from '../types/complaintsTable'
import { Complaint } from '@/projectApi/Complaints/list'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { COMPLAINTS_TYPES, COMPLAINTS_TYPES_KEYS, STATUS_OPTIONS, PRIORITY_TYPES, PRIORITY_TYPES_KEYS } from '../utils'
import ColorBadge from '@/components/ColorBadge'
import { formatNumber } from '@/utils/formatNumber'
import { Moment } from 'moment'
import { getFilterProps, getRangePickerProps } from '@/components/DynamicTable/utils'
import { CategoryFilter } from '@/utils/searchFilterUtils'
import { EyeIcon } from '@/icons'
import { useHistory } from 'react-router'
import { MenuAction } from '@/components/actionMenu/baseMenu'
import { useLocalization } from '@/packages/localization'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'

type ComplaintsTableProps = {
  complaintsTable: ComplaintsListStateType
  dateRangeFilters: ComplaintsDateRangeFilter[]
  pagination: Pagination
  handleSearch: (key: string, newValue: string) => void
  handleDateRangePiker: (key: ComplaintsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  categoryFilter: ComplaintsCategoryFilter
  handleCategoryFilter: (newCategoryFilter: ComplaintsCategoryFilter) => void
}

const formattedStatus = STATUS_OPTIONS.map((s) => ({ value: s.value, text: s.text }))
const formattedPriorityTypes = Object.keys(PRIORITY_TYPES).map((key) => ({
  value: key,
  text: PRIORITY_TYPES[key as PRIORITY_TYPES_KEYS]
}))
const formattedComplaintsTypes = Object.keys(COMPLAINTS_TYPES).map((key) => ({
  value: key,
  text: COMPLAINTS_TYPES[key as COMPLAINTS_TYPES_KEYS]
}))

const formattedReasons = (reasons: ComplaintsReasons[]) =>
  reasons.filter((x) => x.type === 'COMPLAINTS').map((reason) => ({ value: reason.id, text: reason.description }))

const formattedCloseReasons = (reasons: ComplaintsReasons[]) =>
  reasons.filter((x) => x.type === 'CLOSE').map((reason) => ({ value: reason.id, text: reason.description }))

const ComplaintsTable = (props: ComplaintsTableProps) => {
  const {
    complaintsTable,
    handleSearch,
    pagination,
    dateRangeFilters,
    handleDateRangePiker,
    categoryFilter,
    handleCategoryFilter
  } = props

  const { strings } = useLocalization()

  const { complaints, isLoading, searchFilters, reasons } = complaintsTable

  const columns: Column<Complaint>[] = [
    {
      label: 'ID reclamo',
      key: 'id',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      label: 'ID operación',
      key: 'operationId',
      search: getSearchProps('operationId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ operationId }) => operationId || '-'
    },
    {
      label: 'Creador de reclamo',
      key: 'createUserData',
      renderDataCell: ({ createUserData }) => (
        <ClientIDLinkComponent id={createUserData.id} noPadding={true}>
          {createUserData.id} - {createUserData?.name} {createUserData?.lastName}
        </ClientIDLinkComponent>
      )
    },
    {
      label: 'Tipo de reclamo',
      key: 'complaintType',
      filters: getFilterProps(
        formattedComplaintsTypes,
        categoryFilter,
        'complaintType',
        handleCategoryFilter as (newFilter: CategoryFilter<'complaintType'>) => void,
        false
      ),
      renderDataCell: ({ complaintType }) => COMPLAINTS_TYPES[complaintType as COMPLAINTS_TYPES_KEYS] || '-'
    },
    {
      label: 'Prioridad',
      key: 'priority',
      filters: getFilterProps(
        formattedPriorityTypes,
        categoryFilter,
        'priority',
        handleCategoryFilter as (newFilter: CategoryFilter<'priority'>) => void,
        true
      ),
      renderDataCell: ({ priority }) => PRIORITY_TYPES[priority as PRIORITY_TYPES_KEYS] || '-'
    },
    {
      label: 'Estado',
      key: 'status',
      filters: getFilterProps(
        formattedStatus,
        categoryFilter,
        'status',
        handleCategoryFilter as (newFilter: CategoryFilter<'status'>) => void,
        true
      ),
      renderDataCell: ({ status, delayed }) => {
        const found = STATUS_OPTIONS.find((sf) => sf.value === (delayed ? `${status}_DELAYED` : status))

        if (!found) return status

        const props = {
          ...found.styleProps
        }

        return (
          <ColorBadge {...props} extraStyles={{ minWidth: 150, border: '1px solid', textTransform: 'none' }}>
            {found.text}
          </ColorBadge>
        )
      }
    },
    {
      label: 'Cliente',
      key: 'clientData',
      search: getSearchProps('clientId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ clientData }) => (
        <ClientIDLinkComponent id={clientData.id} noPadding={true}>
          {clientData.id} - {clientData?.name} {clientData?.lastName}
        </ClientIDLinkComponent>
      )
    },
    {
      alignment: 'left',
      key: 'createdAt',
      label: 'Creación',
      tooltip: 'Fecha de creación',
      rangePicker: getRangePickerProps(ComplaintsTableDateRangeKey.CREATED_AT, dateRangeFilters, handleDateRangePiker),
      renderDataCell: ({ createdAt }) => parseMomentDate(createdAt)
    },
    {
      alignment: 'left',
      key: 'updatedAt',
      label: 'Última mod.',
      tooltip: 'Fecha de actualización',
      rangePicker: getRangePickerProps(ComplaintsTableDateRangeKey.UPDATED_AT, dateRangeFilters, handleDateRangePiker),
      renderDataCell: ({ updatedAt }) => parseMomentDate(updatedAt)
    },
    {
      key: 'assignedUserData',
      label: 'Agente Asignado',
      search: getSearchProps('assignedUserId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ assignedUserData }) => (
        <ClientIDLinkComponent id={assignedUserData.id} noPadding={true}>
          {assignedUserData.id} - {assignedUserData?.name} {assignedUserData?.lastName}
        </ClientIDLinkComponent>
      )
    },
    {
      key: 'daysOpen',
      label: 'Días abierto',
      renderDataCell: ({ daysOpen }) => formatNumber(daysOpen, 0)
    },
    {
      key: 'reasonId',
      label: 'Motivo',
      tooltip: 'Motivo',
      filters: getFilterProps(
        formattedReasons(reasons),
        categoryFilter,
        'reasonId',
        handleCategoryFilter as (newFilter: CategoryFilter<'reasonId'>) => void,
        false
      ),
      renderDataCell: ({ reasonId }) => reasons.find((reason) => reason.id === reasonId)?.description || '-'
    },
    {
      alignment: 'center',
      key: 'closeDate',
      label: 'Cierre',
      tooltip: 'Fecha de cierre',
      rangePicker: getRangePickerProps(ComplaintsTableDateRangeKey.CLOSED_AT, dateRangeFilters, handleDateRangePiker),
      renderDataCell: ({ closeDate }) => parseMomentDate(closeDate)
    },
    {
      key: 'closeReason',
      label: 'Motivo de cierre',
      tooltip: 'Motivo de cierre',
      minWidth: '120px',
      filters: getFilterProps(
        formattedCloseReasons(reasons),
        categoryFilter,
        'closeReason',
        handleCategoryFilter as (newFilter: CategoryFilter<'closeReason'>) => void,
        false
      ),
      renderDataCell: ({ closeReason }) => reasons.find((reason) => reason.id === closeReason)?.description || '-'
    }
  ]

  const history = useHistory()

  const actions: MenuAction<Complaint>[] = [
    {
      label: strings.ComplaintsViewScreen.table.actions.update,
      icon: <EyeIcon width="17px" height="15px" stroke="#6d6d6d" />,
      onClick: ({ id }) => history.push(`/complaints/${id}`)
    }
  ]

  return (
    <div className="animation fadeIn">
      <DynamicTable
        columns={columns}
        keyExtractor={(complaint: Complaint) => complaint.id}
        rows={complaints}
        loading={isLoading}
        pagination={pagination}
        customHeightOffset={260}
        actions={actions}
      />
    </div>
  )
}

export default ComplaintsTable
