import { combineReducers } from 'redux'
import ObjectsTableReducer from './reducers/objectsTable'
import EditObjectReducer from './reducers/edit'
import BulkTransferReducer from './reducers/bulkTransfer'
import ObjectChangesReducer from './reducers/objectChanges'

export default combineReducers({
  edit: EditObjectReducer,
  table: ObjectsTableReducer,
  transfer: BulkTransferReducer,
  objectChanges: ObjectChangesReducer
})
