import Address from '../../../components/Address/container'
import React, { useMemo } from 'react'
import { Button, Checkbox, Col, Row } from 'antd'
import Select from '../../../components/Select/Select'
import { getAddressByID, getOptionValue } from '../../../common/operations/utils'
import transportActions from '../actions/transport'
import addressActions from '../../../components/Address/actions'
import { Country } from '../../../components/CountrySelector/constants/constants'
import { SERVICE_TYPE } from '../constants'
import { formatAddressesToOptions } from '../utils/objects'
import { TransportState } from '../reducers/transport'
import OptionsModal from '../../../components/OptionsModal'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { GeocodeResult } from 'use-places-autocomplete'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const styles = {
  container: {
    backgroundColor: 'white',
    borderStyle: 'none',
    color: 'black',
    fontSize: 14,
    padding: 20,
    paddingTop: 20,
    marginBottom: 10
  },
  containerTitle: {
    color: 'black',
    fontSize: 18,
    fontWeight: 600
  },
  spinContainer: {
    zIndex: 1,
    position: 'absolute',
    background: 'rgba(247,247,247,0.7)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  radioStyle: {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  },
  radioStyleBolder: {
    fontWeight: 'bold'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'column' as const
  },
  saveAddress: {
    width: 'fit-content',
    alignSelf: 'flex-end',
    marginTop: 12
  },
  noTransportChargeReason: {
    opacity: 0.8
  }
}

type TransportComponentType = {
  transport: TransportState
  transportActions: typeof transportActions
  userID: number
  fromRemoval: boolean
  newStreet: string
  newNumber: string
  addressActions: typeof addressActions
  userCountry: Country
  handleSetTransportMode: (serviceType: string, transportMode: string) => void
}

const Transport = (props: TransportComponentType) => {
  const { transport, transportActions, userCountry, handleSetTransportMode } = props
  const {
    fetchCost,
    setAddressID,
    setNoTransportCharge,
    setNoTransportChargeReason,
    setNoTransportChargeReasonModalOpen
  } = transportActions
  const {
    selectedTransport,
    transportModes,
    loadingTransportModes,
    noTransportChargeReasons,
    loadingNoTransportChargeReasons,
    noTransportCharge,
    noTransportChargeReason,
    reasonModalOpen
  } = transport
  const { requireAddressDestination } = transport
  const { address_id, addresses, loadingAddresses } = transport

  const transportModeOptions = useMemo(
    () =>
      transportModes.map((transportMode) => ({
        value: transportMode.name,
        label: transportMode.nameSpanishRemoval
      })),
    [transportModes]
  )

  const onAddressChange = (suggest: GeocodeResult) => {
    const w = `${suggest.geometry.location.lat()},${suggest.geometry.location.lng()}`
    fetchCost(w)
  }

  const onNoTransportChargeChange = (e: CheckboxChangeEvent) => setNoTransportCharge(e.target.checked)
  const onNoTransportChargeReasonChange = (e: RadioChangeEvent) => setNoTransportChargeReason(e.target.value)
  const onCancel = () => {
    setNoTransportChargeReasonModalOpen(false)
    setNoTransportCharge(false)
  }

  const reasonOptions = useMemo(
    () => noTransportChargeReasons.map((reason) => ({ label: reason.reason, value: reason.id })),
    [noTransportChargeReasons]
  )

  const selectedReason = useMemo(
    () => reasonOptions.find((option) => option.value === noTransportChargeReason),
    [reasonOptions, noTransportChargeReason]
  )

  const modalProps = {
    title: '¿Por qué no se cobra el transporte?',
    visible: reasonModalOpen,
    closable: false,
    maskClosable: false,
    centered: true,
    onOk: () => setNoTransportChargeReasonModalOpen(false),
    onCancel,
    okText: 'Aceptar',
    cancelText: 'Cancelar',
    style: { maxWidth: '500px' },
    bodyStyle: { padding: 0 }
  }

  const optionsProps = {
    options: reasonOptions,
    selectedOption: noTransportChargeReason,
    onOptionChange: onNoTransportChargeReasonChange
  }

  return (
    <div style={styles.container}>
      <p style={styles.containerTitle}>Transporte</p>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p style={styles.inputTitle}>Modalidad</p>
          <Select
            placeholder="Seleccionar Modalidad"
            loading={loadingTransportModes}
            options={transportModeOptions}
            selected={transportModeOptions.find((x) => x.value === selectedTransport)}
            onSelect={(option) => handleSetTransportMode(SERVICE_TYPE, getOptionValue(option))}
          />
        </Col>
        {requireAddressDestination && (
          <Col span={24}>
            <p style={styles.inputTitle}>Dirección</p>
            <Select
              placeholder="Seleccionar Dirección"
              loading={loadingAddresses}
              options={formatAddressesToOptions(addresses)}
              selected={formatAddressesToOptions(addresses).find((x) => x.value === address_id)}
              onSelect={(option) => {
                const value = getOptionValue(option)
                if (value !== 0) {
                  setAddressID(getAddressByID(value, addresses))
                } else {
                  setAddressID({ ID: 0 })
                }
              }}
            />
          </Col>
        )}
        {requireAddressDestination && transport.address_id === 0 && (
          <Col style={styles.addressContainer} span={24}>
            <Address onAddressChange={onAddressChange} country={userCountry.code} />
            <Button style={styles.saveAddress} type="primary" onClick={() => transportActions.saveAddress()}>
              Guardar dirección
            </Button>
          </Col>
        )}
        {requireAddressDestination && (
          <Col span={24}>
            <Checkbox
              checked={noTransportCharge}
              disabled={loadingNoTransportChargeReasons}
              onChange={onNoTransportChargeChange}>
              No cobrar transporte{' '}
              {!reasonModalOpen && selectedReason && (
                <span style={styles.noTransportChargeReason}>({selectedReason.label})</span>
              )}
            </Checkbox>
          </Col>
        )}
      </Row>
      <OptionsModal modalProps={modalProps} optionsProps={optionsProps} />
    </div>
  )
}

export default Transport
