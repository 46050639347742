import { combineReducers } from 'redux'
import shippersTableReducer from './shippersTable'
import shippersABMReducer from './shippersABM'

const shipperReducers = combineReducers({
  shippersTable: shippersTableReducer,
  shippersABM: shippersABMReducer
})

export default shipperReducers
