import { UserDiscount } from '../../../projectApi/TransactionHandler/UserDiscounts/list'
import { DiscountReason } from '../../../projectApi/TransactionHandler/UserDiscounts/reasons'
import { BaseOption } from '../../../components/Select/Select'
import { Moment } from 'moment'

export type UserPersonalInfo = {
  userId: number
  email: string
  name: string
  lastName: string
}

export enum DiscountType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount'
}

export type ReasonOption = BaseOption<number>

export type RequestData<T> = {
  data: T
  fetching: boolean
  errorMessage: string
}

export type BillingDiscountsModalState = {
  modalVisibility: boolean
  deleteModalVisibility: boolean
  creatingNewDiscount: boolean
  deletingDiscount: boolean
  userDiscounts: RequestData<UserDiscount[]>
  userPersonalInfo: RequestData<Partial<UserPersonalInfo>>
  discountReasons: RequestData<DiscountReason[]>
  newDiscountType: DiscountType
  newDiscountValue?: string
  deleteDiscountId?: number
  selectedReason?: ReasonOption
  selectedStartDate?: Moment
  selectedEndDate?: Moment
}

export enum SettersBillingDiscountsModalTypes {
  SET_MODAL_VISIBILITY = 'BILLINGS/DISCOUNTS/SET_MODAL_VISIBILITY',
  SET_DELETE_MODAL_VISIBILITY = 'BILLINGS/DISCOUNTS/SET_DELETE_MODAL_VISIBILITY',
  SET_NEW_DISCOUNT_TYPE = 'BILLINGS/DISCOUNTS/SET_NEW_DISCOUNT_TYPE',
  SET_NEW_DISCOUNT_VALUE = 'BILLINGS/DISCOUNTS/SET_NEW_DISCOUNT_VALUE',
  SET_DISCOUNT_REASON = 'BILLINGS/DISCOUNTS/SET_DISCOUNT_REASON',
  SET_DISCOUNT_DATE = 'BILLINGS/DISCOUNTS/SET_DISCOUNT_DATE',
  SET_CLEAN_STATE_FROM_CREATE = 'BILLINGS/DISCOUNTS/SET_CLEAN_STATE_FROM_CREATE'
}

export interface SetModalVisibility {
  type: SettersBillingDiscountsModalTypes.SET_MODAL_VISIBILITY
  payload: {
    modalVisibility: boolean
    userId?: number
  }
}

export interface SetDeleteModalVisibility {
  type: SettersBillingDiscountsModalTypes.SET_DELETE_MODAL_VISIBILITY
  payload: {
    deleteModalVisibility: boolean
    deleteDiscountId?: number
  }
}

export interface SetNewDiscountType {
  type: SettersBillingDiscountsModalTypes.SET_NEW_DISCOUNT_TYPE
  payload: {
    newDiscountType: DiscountType
  }
}

export interface SetNewDiscountValue {
  type: SettersBillingDiscountsModalTypes.SET_NEW_DISCOUNT_VALUE
  payload: {
    newDiscountValue?: string
  }
}

export interface SetDiscountReason {
  type: SettersBillingDiscountsModalTypes.SET_DISCOUNT_REASON
  payload: {
    selectedReason: ReasonOption
  }
}

export interface SetDiscountDate {
  type: SettersBillingDiscountsModalTypes.SET_DISCOUNT_DATE
  payload: {
    selectedStartDate?: Moment
    selectedEndDate?: Moment
  }
}

export interface SetCleanStateFromCreate {
  type: SettersBillingDiscountsModalTypes.SET_CLEAN_STATE_FROM_CREATE
}

type SettersBillingDiscountsModalActions =
  | SetModalVisibility
  | SetDeleteModalVisibility
  | SetNewDiscountType
  | SetNewDiscountValue
  | SetDiscountReason
  | SetDiscountDate
  | SetCleanStateFromCreate

export enum GetUserDiscountsTypes {
  GET_USER_DISCOUNTS_REQUEST = 'BILLINGS/DISCOUNTS/GET_USER_DISCOUNTS_REQUEST',
  GET_USER_DISCOUNTS_SUCCESS = 'BILLINGS/DISCOUNTS/GET_USER_DISCOUNTS_SUCCESS',
  GET_USER_DISCOUNTS_FAILURE = 'BILLINGS/DISCOUNTS/GET_USER_DISCOUNTS_FAILURE'
}

export interface GetUserDiscountsRequest {
  type: GetUserDiscountsTypes.GET_USER_DISCOUNTS_REQUEST
}

export interface GetUserDiscountsSuccess {
  type: GetUserDiscountsTypes.GET_USER_DISCOUNTS_SUCCESS
  payload: {
    userDiscounts: UserDiscount[]
  }
}

export interface GetUserDiscountsFailure {
  type: GetUserDiscountsTypes.GET_USER_DISCOUNTS_FAILURE
}

type GetUserDiscountsActions = GetUserDiscountsRequest | GetUserDiscountsSuccess | GetUserDiscountsFailure

export enum GetUserPersonalInfoTypes {
  GET_USER_INFO_REQUEST = 'BILLINGS/DISCOUNTS/GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'BILLINGS/DISCOUNTS/GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE = 'BILLINGS/DISCOUNTS/GET_USER_INFO_FAILURE'
}

export interface GetUserPersonalInfoRequest {
  type: GetUserPersonalInfoTypes.GET_USER_INFO_REQUEST
}

export interface GetUserPersonalInfoSuccess {
  type: GetUserPersonalInfoTypes.GET_USER_INFO_SUCCESS
  payload: {
    userPersonalInfo: UserPersonalInfo
  }
}

export interface GetUserPersonalInfoFailure {
  type: GetUserPersonalInfoTypes.GET_USER_INFO_FAILURE
}

type GetUserInfoActions = GetUserPersonalInfoRequest | GetUserPersonalInfoSuccess | GetUserPersonalInfoFailure

export enum GetDiscountReasonsTypes {
  GET_DISCOUNT_REASONS_REQUEST = 'BILLINGS/DISCOUNTS/GET_DISCOUNT_REASONS_REQUEST',
  GET_DISCOUNT_REASONS_SUCCESS = 'BILLINGS/DISCOUNTS/GET_DISCOUNT_REASONS_SUCCESS',
  GET_DISCOUNT_REASONS_FAILURE = 'BILLINGS/DISCOUNTS/GET_DISCOUNT_REASONS_FAILURE'
}

export interface GetDiscountReasonsRequest {
  type: GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_REQUEST
}

export interface GetDiscountReasonsSuccess {
  type: GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_SUCCESS
  payload: {
    discountReasons: DiscountReason[]
  }
}

export interface GetDiscountReasonsFailure {
  type: GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_FAILURE
}

type GetDiscountReasonsActions = GetDiscountReasonsRequest | GetDiscountReasonsSuccess | GetDiscountReasonsFailure

export enum CreateNewDiscountTypes {
  CREATE_NEW_DISCOUNT_REQUEST = 'BILLINGS/DISCOUNTS/CREATE_NEW_DISCOUNT_REQUEST',
  CREATE_NEW_DISCOUNT_SUCCESS = 'BILLINGS/DISCOUNTS/CREATE_NEW_DISCOUNT_SUCCESS',
  CREATE_NEW_DISCOUNT_FAILURE = 'BILLINGS/DISCOUNTS/CREATE_NEW_DISCOUNT_FAILURE'
}

export interface CreateNewDiscountRequest {
  type: CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_REQUEST
}

export interface CreateNewDiscountSuccess {
  type: CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_SUCCESS
}

export interface CreateNewDiscountFailure {
  type: CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_FAILURE
}

type CreateNewDiscountActions = CreateNewDiscountRequest | CreateNewDiscountSuccess | CreateNewDiscountFailure

export enum DeleteDiscountTypes {
  DELETE_DISCOUNT_REQUEST = 'BILLINGS/DISCOUNTS/DELETE_DISCOUNT_REQUEST',
  DELETE_DISCOUNT_SUCCESS = 'BILLINGS/DISCOUNTS/DELETE_DISCOUNT_SUCCESS',
  DELETE_DISCOUNT_FAILURE = 'BILLINGS/DISCOUNTS/DELETE_DISCOUNT_FAILURE'
}

export interface DeleteDiscountRequest {
  type: DeleteDiscountTypes.DELETE_DISCOUNT_REQUEST
}

export interface DeleteDiscountSuccess {
  type: DeleteDiscountTypes.DELETE_DISCOUNT_SUCCESS
}

export interface DeleteDiscountFailure {
  type: DeleteDiscountTypes.DELETE_DISCOUNT_FAILURE
}

type DeleteDiscountActions = DeleteDiscountRequest | DeleteDiscountSuccess | DeleteDiscountFailure

export type BillingDiscountModalActions =
  | SettersBillingDiscountsModalActions
  | GetUserDiscountsActions
  | GetUserInfoActions
  | GetDiscountReasonsActions
  | CreateNewDiscountActions
  | DeleteDiscountActions
