import { Reducer } from 'react'
import {
  NewEditAttributeActions,
  NewEditAttributeState,
  SET_VISIBLE,
  SET_DATA_TYPE,
  SET_NAME,
  SET_UNIT,
  SET_COUNTRY_LABEL,
  SET_COUNTRY_ADD_OPTION,
  SET_COUNTRY_REMOVE_OPTION,
  CLEAR_STATE,
  CREATE_NEW_ATTRIBUTE_FAILURE,
  CREATE_NEW_ATTRIBUTE_SUCCESS,
  GET_ATTRIBUTE_REQUEST,
  GET_ATTRIBUTE_SUCCESS,
  GET_ATTRIBUTE_FAILURE,
  AllowedCountriesCodes,
  CREATE_NEW_ATTRIBUTE_REQUEST,
  UPDATE_COUNTRY_DATATYPE,
  UPDATE_COUNTRY_VALUE,
  DELETE_COUNTRY_OPTION,
  SET_COUNTRY_DATA_TYPE,
  SET_COUNTRY_DATA_TYPE_VALUE
} from '../types/NewEditAttribute'

const countryInitialState = {
  ESP: {
    name: '',
    label: '',
    options: []
  },
  ARG: {
    name: '',
    label: '',
    options: []
  }
}

const initialState: NewEditAttributeState = {
  loading: false,
  error: '',
  id: null,
  name: '',
  unit: '',
  isVisible: false,
  dataType: '',
  countries: countryInitialState
}

const NewEditAttributeReducer: Reducer<NewEditAttributeState, NewEditAttributeActions> = (
  state = initialState,
  action
): NewEditAttributeState => {
  switch (action.type) {
    case GET_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ATTRIBUTE_SUCCESS: {
      const countriesParse = action.payload.attribute.countries.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.countryId as AllowedCountriesCodes]: {
            id: curr.id,
            label: curr.label,
            name: curr.label,
            options: curr.options || []
          }
        }),
        countryInitialState
      )
      return {
        ...state,
        ...action.payload.attribute,
        loading: false,
        countries: countriesParse
      }
    }
    case GET_ATTRIBUTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case SET_VISIBLE: {
      return {
        ...state,
        isVisible: action.payload.isVisible
      }
    }
    case SET_DATA_TYPE: {
      return {
        ...state,
        dataType: action.payload.dataType
      }
    }
    case SET_NAME: {
      return {
        ...state,
        name: action.payload.name
      }
    }
    case SET_UNIT: {
      return {
        ...state,
        unit: action.payload.unit
      }
    }
    case SET_COUNTRY_LABEL: {
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            label: action.payload.label
          }
        }
      }
    }
    case SET_COUNTRY_ADD_OPTION: {
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: [...state.countries[action.payload.country].options, action.payload.option]
          }
        }
      }
    }
    case SET_COUNTRY_REMOVE_OPTION: {
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: state.countries[action.payload.country].options.filter(
              (_, index) => index !== action.payload.index
            )
          }
        }
      }
    }
    case CREATE_NEW_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CREATE_NEW_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case CREATE_NEW_ATTRIBUTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case UPDATE_COUNTRY_DATATYPE:
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: state.countries[action.payload.country].options.map((option) => {
              if (option.id === action.payload.id) {
                return {
                  ...option,
                  dataType: action.payload.dataType
                }
              } else {
                return option
              }
            })
          }
        }
      }
    case UPDATE_COUNTRY_VALUE:
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: state.countries[action.payload.country].options.map((option) => {
              if (option.id === action.payload.id) {
                return {
                  ...option,
                  value: action.payload.value
                }
              } else {
                return option
              }
            })
          }
        }
      }
    case DELETE_COUNTRY_OPTION:
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: state.countries[action.payload.country].options.map((option) => {
              if (option.id === action.payload.id) {
                return {
                  ...option,
                  deleted: true
                }
              } else {
                return option
              }
            })
          }
        }
      }
    case SET_COUNTRY_DATA_TYPE_VALUE:
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: state.countries[action.payload.country].options.map((option, index) => {
              if (index === action.payload.index) {
                return {
                  ...option,
                  value: action.payload.value
                }
              } else {
                return option
              }
            })
          }
        }
      }
    case SET_COUNTRY_DATA_TYPE:
      return {
        ...state,
        countries: {
          ...state.countries,
          [action.payload.country]: {
            ...state.countries[action.payload.country],
            options: state.countries[action.payload.country].options.map((option, index) => {
              if (index === action.payload.index) {
                return {
                  ...option,
                  dataType: action.payload.dataType
                }
              } else {
                return option
              }
            })
          }
        }
      }
    case CLEAR_STATE:
      return initialState
    default:
      return state
  }
}

export default NewEditAttributeReducer
