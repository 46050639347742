/* eslint-disable camelcase */
import { apiPrivate } from '@/api'
import { URL_BASE_COMPLAINTS } from '@/endpoints'
import snakecaseKeys from 'snakecase-keys'

export type PhotoUpload = {
  id: number | string
  url: string
}

interface Params {
  complaintId: number
  closeData: {
    reasonId: number
    photos: PhotoUpload[]
  }
}

export function close({ complaintId, closeData }: Params): Promise<void> {
  const formattedBody = snakecaseKeys(closeData)

  return apiPrivate
    .put(`${URL_BASE_COMPLAINTS}/complaints/${complaintId}/close`, formattedBody)
    .then(() => {})
    .catch((err) => {
      console.error(err)
      throw err
    })
}
