import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'

export interface APIResponse {
  paging: Paging
  results: Result[]
}

export interface Paging {
  total: number
  limit: number
  offset: number
}

export interface Result {
  id: number
  code: string
  object_id: number
  current: boolean
  number: number
}

export type ObjectsListSortKey = 'id' | 'created_at'

export type SearchableParams = {
  id?: number
  identificationCode?: string
  product?: string
  locationCode?: string
  ownerId?: number
  owner?: string
  location?: string | null
  exactLocationCode?: boolean
  createdAtFrom?: Moment
  createdAtTo?: Moment
}

interface ListParams extends SearchableParams {
  limit?: number
  offset?: number
  column?: ObjectsListSortKey
  order?: SortDirection
  code?: string
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export function list(params: ListParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/identification-codes/search`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.results)
        throw new Error('Respuesta incorrecta al obtener la lista de códigos de identificación.')

      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
