import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NewBillingActionCreator from '../actionCreators/newBillingTable'
import { bindActionCreators } from 'redux'
import SendDebtReminderModal, { SendDebtReminderModalComponentProps } from '../components/sendDebtReminderModal'

const SendDebtReminderModalContainer = () => {
  const dispatch = useDispatch()

  const newBillingActions = bindActionCreators(NewBillingActionCreator, dispatch)
  const newBillingTable = useSelector((state) => state.BillingV2.NewBillingTable)

  const { IsDebtModalOpen, error, isLoadingCharge, selectedBilling } = newBillingTable

  const { SendDebtReminder, closeDebtModal } = newBillingActions

  const handleSendDebtReminder = async () => {
    if (selectedBilling?.billingId) {
      const result = SendDebtReminder(selectedBilling?.billingId)
      // @ts-ignore
      if (result) closeDebtModal()
    }
  }

  const props: SendDebtReminderModalComponentProps = {
    handleClose: closeDebtModal,
    handleSendDebtReminder,
    IsDebtModalOpen,
    error,
    isLoadingCharge,
    selectedBilling
  }

  return <SendDebtReminderModal {...props} />
}

export default SendDebtReminderModalContainer
