import {
  ColumnsSearchFilters,
  Order,
  Params,
  Removal,
  RemovalActions,
  RemovalFilter,
  RemovalSearchFilter,
  RemovalSorter,
  RemovalTableTypes
} from '../types/removals'
import { PaginationConfig } from 'antd/es/table'
import { SortOrder } from 'antd/lib/table'
import { STATUS_VALUES } from '../../../common/operationsTable/constants'
import { NextStatusTypes } from '../types/nextStatus'

const LIMIT = 50
export const DEFAULT_STATUS_FILTER = `${STATUS_VALUES.CONFIRMED},${STATUS_VALUES.PENDING_PAYMENT},${STATUS_VALUES.AUTHORIZED},${STATUS_VALUES.PROGRAMMED},${STATUS_VALUES.PROGRAMMED_M}`

export type RemovalTableState = {
  removals: Removal[]
  loading: boolean
  params: Params
  quantity: number
  sorter: RemovalSorter
  filter: RemovalFilter
  searchFilters: RemovalSearchFilter[]
  pagination: PaginationConfig
}

const initialRoot = {
  removals: [],
  loading: false,
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'id',
    Order: Order.DESC,
    status: DEFAULT_STATUS_FILTER
  },
  quantity: 0,
  sorter: {
    order: 'descend' as SortOrder,
    field: 'id',
    columnKey: 'id'
  },
  filter: {
    status: [
      STATUS_VALUES.PROGRAMMED,
      STATUS_VALUES.PROGRAMMED_M,
      STATUS_VALUES.PENDING_PAYMENT,
      STATUS_VALUES.AUTHORIZED
    ]
  },
  searchFilters: [
    {
      key: ColumnsSearchFilters.ID,
      text: ''
    },
    {
      key: ColumnsSearchFilters.USER_ID,
      text: ''
    },
    {
      key: ColumnsSearchFilters.OPERATION_NUMBER,
      text: ''
    },
    {
      key: ColumnsSearchFilters.CREATED_AT,
      text: []
    },
    {
      key: ColumnsSearchFilters.TRANSPORT_DATETIME,
      text: []
    }
  ],
  pagination: {
    current: 1,
    total: 0
  }
}

function tableReducer(state: RemovalTableState = initialRoot, action: RemovalActions) {
  switch (action.type) {
    case RemovalTableTypes.CLEAN_FILTERS:
      return initialRoot
    case RemovalTableTypes.FETCH_REMOVALS:
      return {
        ...state,
        removals: [],
        loading: true,
        error: ''
      }
    case RemovalTableTypes.FETCH_REMOVALS_SUCCESS:
      return {
        ...state,
        removals: action.payload.removals,
        loading: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case RemovalTableTypes.FETCH_REMOVALS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case RemovalTableTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      }
    case RemovalTableTypes.SET_SORTER:
      return {
        ...state,
        sorter: action.payload.sorter
      }
    case RemovalTableTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload.pagination
      }
    case RemovalTableTypes.SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    case NextStatusTypes.UPDATE_DEBT_STATUS_SUCCESS:
    case NextStatusTypes.INFORM_PAYMENT_SUCCESS: {
      const { debtAmount, operation } = action.payload
      return {
        ...state,
        removals: state.removals.map((removal) => {
          if (removal.id === operation.id) {
            return {
              ...removal,
              status: operation.status,
              debt_amount: debtAmount
            }
          }
          return removal
        })
      }
    }
    default:
      return state
  }
}

export default tableReducer
