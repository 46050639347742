import { REHYDRATE } from 'redux-persist/es/constants'
import { FeatureManagerAction, FeatureManagerState, FeatureType, SET_FEATURE_ACTIVE, SET_POPUP_OPEN } from './types'

const initialState: FeatureManagerState = {
  features: [
    {
      type: FeatureType.NEW_TRIPS,
      description: 'Habilitar nueva tabla y modal de viajes (deprecado)',
      active: true,
      deprecated: true
    },
    {
      type: FeatureType.SPREEDLY_PAYMENT,
      description: 'Habilita el ingreso de métodos de pago mediante Spreedly',
      active: true,
      deprecated: true
    },
    {
      type: FeatureType.NEW_CANCEL_OPERATIONS,
      description: 'Habilitar el nuevo modal de cancelación de operaciones',
      active: true,
      deprecated: true
    },
    {
      type: FeatureType.SEE_SALES_REQUEST_TABLE,
      description: 'Habilita el acceso a la nueva seccion de solicitud de venta',
      active: true,
      deprecated: true
    }
  ],
  popupOpen: false
}

const FeatureManagerReducer = (
  state: FeatureManagerState = initialState,
  action: FeatureManagerAction
): FeatureManagerState => {
  switch (action.type) {
    case SET_FEATURE_ACTIVE:
      return {
        ...state,
        features: state.features.map((f) => ({
          ...f,
          active: f.type === action.payload.featureType ? !f.active : f.active
        }))
      }
    case SET_POPUP_OPEN: {
      return { ...state, popupOpen: action.payload.open }
    }
    case REHYDRATE: {
      if (action.payload?.FeatureManager?.features) {
        return {
          ...initialState,
          features: initialState.features.map((f) => {
            const lastStateFeature = action.payload?.FeatureManager?.features.find((ff) => ff.type === f.type)
            const active = f.deprecated ? true : lastStateFeature?.active ?? f.active
            return {
              ...f,
              active
            }
          })
        }
      }
      return state
    }
    default:
      return state
  }
}

export default FeatureManagerReducer
