import { Tooltip } from 'antd'
import React, { MouseEventHandler } from 'react'
import styles from './IconButton.module.scss'

type IconButtonProps = {
  tooltipText?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const IconButton: React.FC<IconButtonProps> = ({ tooltipText, onClick, children }) => {
  const button = (
    <button className={styles.IconButton} onClick={onClick}>
      {children}
    </button>
  )

  return tooltipText ? <Tooltip title={tooltipText}>{button}</Tooltip> : button
}

export default IconButton
