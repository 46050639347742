import { Reducer } from 'redux'
import {
  DonationViewAction,
  DonationViewState,
  CLEAR_STATE,
  AcceptDonationTypes,
  RejectDonationTypes,
  GetDonationByIdTypes,
  DonateTypes
} from '../types/donationsView'

export const PAGE_SIZE = 10

const initialState: DonationViewState = {
  donation: null,
  error: '',
  isLoading: false
}

const DonationsListReducer: Reducer<DonationViewState, DonationViewAction> = (
  state: DonationViewState = initialState,
  action: DonationViewAction
): DonationViewState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case AcceptDonationTypes.ACCEPT_DONATION_REQUEST:
    case DonateTypes.DONATE_REQUEST:
    case RejectDonationTypes.REJECT_DONATION_REQUEST:
    case GetDonationByIdTypes.GET_DONATION_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GetDonationByIdTypes.GET_DONATION_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        donation: action.payload.donation
      }
    case AcceptDonationTypes.ACCEPT_DONATION_SUCCESS:
    case RejectDonationTypes.REJECT_DONATION_SUCCESS:
    case DonateTypes.DONATE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case AcceptDonationTypes.ACCEPT_DONATION_FAILURE:
    case RejectDonationTypes.REJECT_DONATION_FAILURE:
    case DonateTypes.DONATE_FAILURE:
    case GetDonationByIdTypes.GET_DONATION_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: ''
      }
    default:
      return state
  }
}

export default DonationsListReducer
