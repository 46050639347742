import React from 'react'
import Component, { CarouselModalProps } from '../components/CarouselModal'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/CarouselModal'

const CarouselModalContainer = () => {
  const dispatch = useDispatch()
  const modalImagesActions = bindActionCreators(actions, dispatch)

  const { open, images } = useSelector((state) => state.CarouselModal)

  const props: CarouselModalProps = {
    open,
    images,
    onClose: () => modalImagesActions.setOpen(false)
  }

  return <Component {...props} />
}

export default CarouselModalContainer
