import moment, { Moment } from 'moment'
import { clone } from 'ramda'

export const formatDates = ({
  dates,
  format = 'YYYY[-]MM[-]DD',
  utc = false
}: {
  dates: { [key: string]: Moment | undefined }
  format?: string | null
  utc?: boolean
}) => {
  const formattedDates: { [key: string]: string } = {}

  Object.entries(clone(dates)).forEach(([key, value]) => {
    if (value) {
      const actualValue = utc ? value.utc() : value
      formattedDates[key] = format ? actualValue.format(format) : actualValue.format()
    }
  })

  return formattedDates
}

export const convertToMoment = (stringDate?: string | null): Moment | null => {
  if (!stringDate) return null
  if (!moment(stringDate).isValid()) return null

  return moment(stringDate)
}

export const convertArrayForGetRequests = (arr?: number[]) =>
  Array.isArray(arr) && arr.length > 0 ? arr.join(',') : undefined

export const mapErrorCodeToMessage = ({ map, code }: { map: { [code: string]: string }; code?: string }) => {
  return map[code ?? ''] || 'Algo salió mal'
}
