import React, { useEffect } from 'react'
import Component, { RemittanceModalProps } from './component'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actionCreators from './actionCreators'

const RemittanceModalContainer = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(actionCreators, dispatch)

  const { open, remittance, transactionId, error, loading } = useSelector((state) => state.RemittanceModal)

  useEffect(() => {
    if (transactionId) actions.getTransactionRemittance(transactionId)
  }, [transactionId])

  const props: RemittanceModalProps = {
    open,
    remittance,
    error,
    loading,
    onClose: () => actions.setOpen(false)
  }

  return <Component {...props} />
}

export default RemittanceModalContainer
