import { Pagination } from '../../../components/DynamicTable/types/types'
import { History } from 'history'
import { Moment } from 'moment'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Table, User, UsersFilterCategories } from '../components/entities'
import { CategoryFilter, SearchFilter, Sorter } from '../../../components/DynamicTable/utils/entities'
import { Country } from '../../../components/CountrySelector/constants/constants'
import { Role } from '../../../projectApi/AccountManager/Users/common'
import { DateRangeFilter } from '../../../utils/searchFilterUtils'
import { UsersMetrics } from './usersMetrics'

export type UsersTableProps = {
  users: User[]
  pagination: Pagination
  actions: MenuAction<User>[]
  loading: boolean
  searchFilters: SearchFilter[]
  sorter: Sorter<User>
  countries: Country[]
  categoryFilter: CategoryFilter<UsersFilterCategories>
  table: Table
  history: History
  dateRangeFilters: UsersDateRangeFilter[]
  handleDateRangePiker: (key: UsersTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  cleanFilters: () => void
  getUsersFromStore: () => void
  setCreateModalOpen: (data: boolean) => void
  handleSort: (newSorter: Sorter<User>) => void
  handleSearch: (key: string, newValue: string) => void
  handleFilter: (newFilter: CategoryFilter<UsersFilterCategories>) => void
  handleRangePicker: (key: keyof User, startDate?: Moment, endDate?: Moment) => void
  openTermsPanel: (RealID: number, Name: string, LastName: string, Country: string) => void
  onSendCSV: () => void
  roles: Role[]
  loadingCSV: boolean
  fetchingMetrics: boolean
  usersMetrics: UsersMetrics
}

export enum UsersTableDateRangeKey {
  CREATED_AT = 'createdAt'
}

export type UsersDateRangeFilter = DateRangeFilter<UsersTableDateRangeKey>
