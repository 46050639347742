import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'
import { formatObjectsForRemoval } from '../utils/objects'
import { RootState } from '../../../store'
import { ObjectsState } from '../reducers/objects'
import { PaymentMpState } from '../reducers/payment-mp'
import { TransportState } from '../reducers/transport'
import { CalendarState } from '../types/calendar'
import { ModalReasonState } from '../types/ModalReasons'
import { DefaultOptions } from '../components/ModalReasons'

const getPaymentByFields = (paymentBy: string, paymentMp: PaymentMpState, paymentCBU: any, formData: FormData) => {
  const { card_id, spreedlyPMID } = paymentMp
  const { cbu_id } = paymentCBU

  formData.append('payment_type', paymentBy)
  switch (paymentBy) {
    case PAYMENT_BY_VALUES.SPREEDLY: {
      formData.append('spreedly_pm_id', String(spreedlyPMID))
      break
    }
    case PAYMENT_BY_VALUES.CREDIT_CARD_MP: {
      formData.append('card_id', String(card_id))
      break
    }
    case PAYMENT_BY_VALUES.CBU: {
      formData.append('cbu_id', cbu_id)
      break
    }
    case PAYMENT_BY_VALUES.OFFLINE:
    default:
  }
}

const getRemovalCommonFields = (
  guid: string,
  objects: ObjectsState,
  transport: TransportState,
  calendar: CalendarState,
  costReducer: any,
  userSearch: any,
  common: any,
  formData: FormData,
  modalReasons: ModalReasonState
) => {
  const { objectsToRemove } = objects
  const { datetime, timeIntervals } = calendar
  const { address_id, cost, selectedTransport, noTransportCharge, noTransportChargeReason, transportModes } = transport
  const { discountCode, applyDiscount } = costReducer
  const { user } = userSearch
  const { notifyUser, storageCost } = common
  const { reasonSelected, anotherReason } = modalReasons

  const transportModeId = transportModes.find((transportMode) => transportMode.name === selectedTransport)?.id

  if (guid !== '') formData.append('guid', guid)
  formData.append('objects', JSON.stringify(formatObjectsForRemoval(objectsToRemove)))
  formData.append('transport_cost', String(cost))
  formData.append('storage_cost', storageCost)
  formData.append('apply_discount', applyDiscount)
  formData.append('notify_user', notifyUser)
  formData.append('user_id', user.RealID)
  formData.append('date', datetime?.format('X') || '0')
  formData.append('time_slot_id', String(timeIntervals.selected?.slotId))
  formData.append('discount_code', discountCode)
  formData.append('time_interval', String(timeIntervals.selected?.id))

  formData.append('transport_mode_type_id', String(transportModeId))
  if (address_id) formData.append('address_id', String(address_id))
  if (noTransportCharge) formData.append('no_transport_charge_reason_id', String(noTransportChargeReason))

  if (typeof reasonSelected === 'number')
    formData.append('removal_reason', JSON.stringify({ id: reasonSelected, description: '' }))
  if (reasonSelected === DefaultOptions.ANOTHER && anotherReason)
    formData.append('removal_reason', JSON.stringify({ id: 0, description: anotherReason }))
}

export const getFormattedRemoval = (state: RootState) => {
  const { NewRemoval } = state
  const { common, objects, transport, calendar, cost, paymentMp, paymentCBU, userSearch, modalReasons } = NewRemoval
  const { paymentBy, guid } = common
  const formData = new FormData()
  getRemovalCommonFields(guid, objects, transport, calendar, cost, userSearch, common, formData, modalReasons)
  getPaymentByFields(paymentBy, paymentMp, paymentCBU, formData)
  return formData
}
