import { ProposalActions, ProposalTypes } from '../types/proposals'
import { ProspectSearchTypes } from '../types/prospect'
import { Proposal as ProposalProspect } from '../../../projectApi/TransactionHandler/Proposals/getFromProspect'
import { Proposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { ProposalMetrics } from '../../../projectApi/TransactionHandler/Proposals/metrics'
import { Reducer } from 'redux'

const initialState = {
  loadingProposals: false,
  errorProposals: '',
  proposals: [],
  loadingProposalByID: false,
  errorProposalByID: '',
  loadingSave: false,
  selectedProposal: null,
  error: '',
  loadingMetrics: false,
  metrics: {
    convert: 0,
    expired: 0,
    current: 0
  },
  errorMetrics: ''
}

export type ProposalState = {
  loadingProposals: boolean
  errorProposals: string
  proposals: ProposalProspect[]
  loadingProposalByID: boolean
  errorProposalByID: string
  loadingSave: boolean
  selectedProposal: Proposal | null
  error: string
  loadingMetrics: boolean
  metrics: ProposalMetrics
  errorMetrics: string
}

const root: Reducer<ProposalState, ProposalActions> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return initialState
    case ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH:
      return { ...state, selectedProposal: null }
    case ProposalTypes.GET_PROPOSALS_REQUEST:
      return {
        ...state,
        loadingProposals: true,
        errorProposals: ''
      }
    case ProposalTypes.GET_PROPOSALS_SUCCESS: {
      const { proposals } = action.payload
      return {
        ...state,
        loadingProposals: false,
        proposals
      }
    }
    case ProposalTypes.GET_PROPOSALS_FAILURE:
      return {
        ...state,
        loadingProposals: false,
        errorProposals: action.payload.error
      }
    case ProposalTypes.GET_PROPOSAL_BY_ID_REQUEST:
      return {
        ...state,
        loadingProposalByID: true,
        errorProposalByID: ''
      }
    case ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS: {
      const { proposal } = action.payload
      return {
        ...state,
        loadingProposalByID: false,
        selectedProposal: proposal
      }
    }
    case ProposalTypes.GET_PROPOSAL_BY_ID_FAILURE:
      return {
        ...state,
        loadingProposalByID: false,
        errorProposalByID: action.payload.error
      }
    case ProposalTypes.SAVE_PROPOSAL_REQUEST:
      return { ...state, loadingSave: true, error: '' }
    case ProposalTypes.SAVE_PROPOSAL_SUCCESS:
      return { ...state, loadingSave: false }
    case ProposalTypes.SAVE_PROPOSAL_FAILURE:
      return { ...state, loadingSave: false, error: action.payload.error }
    case ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_REQUEST:
      return { ...state, loadingSave: true, error: '' }
    case ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_SUCCESS:
      return { ...state, loadingSave: false }
    case ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_FAILURE:
      return { ...state, loadingSave: false, error: action.payload.error }
    case ProposalTypes.GET_METRICS_REQUEST:
      return { ...state, loadingMetrics: true, errorMetrics: '' }
    case ProposalTypes.GET_METRICS_SUCCESS:
      return { ...state, loadingMetrics: false, metrics: action.payload.metrics }
    case ProposalTypes.GET_METRICS_FAILURE:
      return { ...state, loadingMetrics: false, errorMetrics: action.payload.error }
    default:
      return state
  }
}

export default root
