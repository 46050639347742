import { Reducer } from 'redux'
import {
  CommonDestination,
  GET_TRIP_TRANSACTIONS_FAILURE,
  GET_TRIP_TRANSACTIONS_REQUEST,
  GET_TRIP_TRANSACTIONS_SUCCESS
} from '../types/common'
import {
  CREATE_TRIP_FAILURE,
  CREATE_TRIP_REQUEST,
  CREATE_TRIP_SUCCESS,
  FIND_TRANSACTION_BY_ID_FAILURE,
  FIND_TRANSACTION_BY_ID_REQUEST,
  FIND_TRANSACTION_BY_ID_SUCCESS,
  GET_DRIVERS_FAILURE,
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  SELECT_DRIVER,
  SET_OPEN,
  TripModalState,
  TripsModalAction,
  UPDATE_TRIP_FAILURE,
  UPDATE_TRIP_REQUEST,
  UPDATE_TRIP_SUCCESS
} from '../types/tripModal'

const initialState: TripModalState = {
  open: false,
  loadingTransactions: false,
  loadingDrivers: false,
  creatingOrUpdating: false,
  error: '',
  transactions: [],
  extraTransactionsToAdd: [],
  searchingExtraTransaction: false,
  drivers: [],
  selectedDriverId: 0,
  trip: null
}

const TripModalReducer: Reducer<TripModalState, TripsModalAction> = (state = initialState, action): TripModalState => {
  switch (action.type) {
    case SET_OPEN:
      if (!action.payload.open) return initialState

      return {
        ...state,
        open: action.payload.open,
        transactions: action.payload.transactions || initialState.transactions,
        trip: action.payload.trip || initialState.trip
      }
    case GET_DRIVERS_REQUEST:
      return { ...state, loadingDrivers: true }
    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        loadingDrivers: false,
        drivers: action.payload.drivers,
        selectedDriverId: state.trip ? state.trip.driver.userId : initialState.selectedDriverId
      }
    case GET_DRIVERS_FAILURE:
      return { ...state, loadingDrivers: false, error: action.payload.error }
    case SELECT_DRIVER:
      return { ...state, selectedDriverId: action.payload.driverId }
    case CREATE_TRIP_REQUEST:
    case UPDATE_TRIP_REQUEST:
      return { ...state, creatingOrUpdating: true }
    case CREATE_TRIP_SUCCESS:
    case UPDATE_TRIP_SUCCESS:
      return initialState
    case CREATE_TRIP_FAILURE:
    case UPDATE_TRIP_FAILURE:
      return { ...state, creatingOrUpdating: false, error: action.payload.error }
    case GET_TRIP_TRANSACTIONS_REQUEST:
      if (action.payload.destination !== CommonDestination.MODAL) return state

      return {
        ...state,
        loadingTransactions: true
      }
    case GET_TRIP_TRANSACTIONS_SUCCESS: {
      if (action.payload.destination !== CommonDestination.MODAL) return state

      return {
        ...state,
        loadingTransactions: false,
        transactions: action.payload.transactions.map((t) => ({
          creationDate: t.createdAt,
          reservationDate: t.reservationDate,
          guid: t.guid,
          id: t.id,
          phone: t.client.phone,
          quantityObjects: t.contents.real.objects,
          statusId: t.transactionStatusId,
          transactionType: t.typeId,
          client: t.client,
          destinationAddress: t.destinationAddress,
          totalM3: t.contents.real.m3,
          operation: t.operation,
          contents: {
            itemsOrObjects: t.contents.forecast.items + t.contents.real.m3,
            m3: t.contents.forecast.m3 + t.contents.real.m3
          }
        }))
      }
    }
    case GET_TRIP_TRANSACTIONS_FAILURE:
      if (action.payload.destination !== CommonDestination.MODAL) return state

      return {
        ...state,
        loadingTransactions: false,
        error: action.payload.destination
      }
    case FIND_TRANSACTION_BY_ID_REQUEST:
      return {
        ...state,
        searchingExtraTransaction: true
      }
    case FIND_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        searchingExtraTransaction: false,
        extraTransactionsToAdd: [...state.extraTransactionsToAdd, action.payload.transaction]
      }
    case FIND_TRANSACTION_BY_ID_FAILURE:
      return {
        ...state,
        searchingExtraTransaction: false
      }
    default:
      return state
  }
}

export default TripModalReducer
