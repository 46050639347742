import { API } from '../../../projectApi'
import { UsersReportParams } from '../../../projectApi/AccountManager/Reports/users'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { SEND_CSV_FAILURE, SEND_CSV_REQUEST, SEND_CSV_SUCCESS } from '../types/sendCSV'

type UserParams = {
  Limit: number
  Offset: number
  Column: string
  Order: string
  id: number
  name: string
  lastName: string
  phone: string
  email: string
  role: number
  has_card: string
  accepted: string
}

const formatParamsToCSV = (params: UserParams): UsersReportParams => ({
  limit: params.Limit,
  offset: params.Offset,
  id: params.id,
  name: params.name,
  lastName: params.lastName,
  phone: params.phone,
  email: params.email,
  role: params.role,
  hasCard: params.has_card,
  tyc: params.accepted
})

const sendUsersCSVActions = {
  sendUsersCSV(params: UserParams): AppThunk {
    return async (dispatch) => {
      dispatch({ type: SEND_CSV_REQUEST })
      const toast = setToastLoading('Enviando CSV, por favor espere...')
      const formattedParams = formatParamsToCSV(params)
      try {
        await API.AccountManager.Reports.users(formattedParams)
        setToastSuccessUpdate(toast, { render: 'CSV enviado correctamente. Revise su correo electrónico.' })
        dispatch({ type: SEND_CSV_SUCCESS })
      } catch {
        setToastErrorUpdate(toast, { render: 'Error al enviar CSV' })
        dispatch({ type: SEND_CSV_FAILURE })
      }
    }
  }
}

export default sendUsersCSVActions
