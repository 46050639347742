import {
  Address,
  GetNoTransportChargeReasonsSuccess,
  SetNoTransportCharge,
  SetNoTransportChargeReason,
  SetNoTransportChargeReasonModalOpen,
  SetTransportCost,
  TransportTypes
} from '../types/transport'
import addressActions from '../../../components/Address/actions'
import { apiPrivate, apiPublic } from '../../../api'
import { URL_BASE_PROFILE, URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { formatObjectsForFetchCost } from '../utils/objects'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'
import { getMainAddress } from '../utils/transport'
import { objectsToRemoveTotalVolume, useGetObjectsToRemove } from '../utils/cost'
import { NoTransportChargeReason } from '../../../projectApi/TransactionHandler/NoTransportChargeReasons/list'
import { TransportModeName } from '../../../common/operations/constants'
import { Flag } from '@/projectApi/ObjectAdministration/Value/getValue'

const actions = {
  getTransportModeByService(serviceType: string): AppThunk {
    return async function (dispatch) {
      dispatch({
        type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST
      })

      try {
        const response = await API.TransactionHandler.TransportMode.getByService(serviceType)
        const { transportMode } = response
        dispatch(actions.getTransportModeByServiceSuccess(serviceType, transportMode))
      } catch (e) {
        handleErrorsWithAction(e, TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_FAILURE, dispatch)
      }
    }
  },

  getTransportModeByServiceSuccess:
    (serviceType: string, transportModes: TransportMode[]): AppThunk =>
    (dispatch) => {
      dispatch({
        type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS,
        payload: {
          serviceType,
          transportModes
        }
      })
    },

  getNoTransportChargeReasons(): AppThunk {
    return async function (dispatch) {
      dispatch({
        type: TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_REQUEST
      })

      try {
        const response = await API.TransactionHandler.NoTransportChargeReasons.list({
          sorts: 'display_order',
          orders: 'asc'
        })
        const { reasons } = response
        dispatch(actions.getNoTransportChargeReasonsSuccess(reasons))
      } catch (e) {
        handleErrorsWithAction(e, TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_FAILURE, dispatch)
      }
    }
  },

  getNoTransportChargeReasonsSuccess: (reasons: NoTransportChargeReason[]): GetNoTransportChargeReasonsSuccess => ({
    type: TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_SUCCESS,
    payload: {
      reasons
    }
  }),

  setNoTransportCharge: (noTransportCharge: boolean): SetNoTransportCharge => ({
    type: TransportTypes.SET_NO_TRANSPORT_CHARGE,
    payload: {
      noTransportCharge
    }
  }),

  setNoTransportChargeReason: (noTransportChargeReason: number): SetNoTransportChargeReason => ({
    type: TransportTypes.SET_NO_TRANSPORT_CHARGE_REASON,
    payload: {
      noTransportChargeReason
    }
  }),

  setNoTransportChargeReasonModalOpen: (modalOpen: boolean): SetNoTransportChargeReasonModalOpen => ({
    type: TransportTypes.SET_NO_TRANSPORT_CHARGE_REASON_MODAL_OPEN,
    payload: {
      modalOpen
    }
  }),

  setTransportMode:
    (serviceType: string, selectedTransportMode: string): AppThunk =>
    (dispatch, getState) => {
      const { addresses, transportModes } = getState().NewRemoval.transport
      const transportMode = transportModes.find(({ name }) => selectedTransportMode === name)
      const requireAddressDestination = transportMode?.requireAddressDestinationForRemoval
      const isDropoff = selectedTransportMode === TransportModeName.DROPOFF

      dispatch({
        type: TransportTypes.SET_TRANSPORT_MODE,
        payload: {
          transportMode: selectedTransportMode,
          requireAddressDestination,
          shouldFetchCost: isDropoff
        }
      })
      if (requireAddressDestination) {
        const mainAddress = getMainAddress(addresses)
        dispatch(actions.setAddressID(mainAddress, isDropoff))
      }
    },

  setAddressID:
    (address: Address, shouldFetchCost = true): AppThunk =>
    (dispatch) => {
      const { ID, Address, Number, Original } = address
      const addressString = Original || `${Address} ${Number}`
      dispatch({
        type: TransportTypes.SET_ADDRESS_ID,
        payload: {
          address_id: ID,
          addressString
        }
      })
      if (ID !== 0 && shouldFetchCost) dispatch(actions.fetchCost(addressString))
    },

  getAddresses:
    (user_id: number): AppThunk =>
    async (dispatch) => {
      dispatch({ type: TransportTypes.GET_ADDRESSES_REQUEST })

      try {
        const response = await apiPrivate.get(URL_BASE_PROFILE + '/admin/address', { params: { user_id } })
        const addresses = response.data.description

        dispatch({
          type: TransportTypes.GET_ADDRESSES_SUCCESS,
          payload: {
            addresses
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, TransportTypes.GET_ADDRESSES_FAILURE, dispatch)
      }
    },

  saveAddress: (): AppThunk => {
    return function (dispatch, getState) {
      dispatch({ type: TransportTypes.SAVE_ADDRESS_REQUEST })

      const {
        NewRemoval: { userSearch }
      } = getState()
      const {
        Components: { address }
      } = getState()
      const { user } = userSearch
      const { addressGoogle, street, number, floor, apartment, postalCode, province, city } = address.address

      const formData = new FormData()

      formData.append('user_id', String(user?.RealID || ''))
      formData.append('address', street)
      formData.append('number', number)
      formData.append('floor', floor)
      formData.append('apartment', apartment)
      formData.append('postal_code', postalCode)
      if (addressGoogle) formData.append('original', addressGoogle)
      formData.append('province', province)
      formData.append('city', city)

      return apiPrivate
        .post(URL_BASE_PROFILE + '/admin/address', formData)
        .then((response) => {
          const address = response.data.description
          dispatch({
            type: TransportTypes.SAVE_ADDRESS_SUCCESS,
            payload: {
              address
            }
          })
          dispatch(addressActions.cleanAddress())
        })
        .catch((error) => {
          handleErrorsWithAction(error, TransportTypes.SAVE_ADDRESS_FAILURE, dispatch)
        })
    }
  },

  fetchCost: (where: string): AppThunk => {
    return function (dispatch, getState) {
      dispatch({ type: TransportTypes.FETCH_COST_REQUEST })

      const state = getState().NewRemoval
      const country = state.userSearch.user?.Country

      const objectsToRemove = getState().NewRemoval.objects.objectsToRemove

      const destination = where

      const objects = formatObjectsForFetchCost(objectsToRemove)

      const objectsToRemoveValues = useGetObjectsToRemove(objectsToRemove)
      const totalVolume = objectsToRemoveTotalVolume(objectsToRemoveValues)

      const query = `?country_code=${country}`

      const body = {
        destination,
        items: objects,
        m3: totalVolume,
        service_type_id: 'items'
      }

      return apiPublic
        .post(URL_BASE_TRANSACTIONS + '/compute/trip/cost' + query, body)
        .then((response) => {
          const priceParam = response.data.description.price
          const price = Math.ceil(parseFloat(priceParam))
          dispatch(actions.fetchTollCost())
          dispatch({
            type: TransportTypes.FETCH_COST_SUCCESS,
            payload: {
              cost: price,
              where
            }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, TransportTypes.FETCH_COST_FAILURE, dispatch)
        })
    }
  },
  fetchTollCost: (): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: TransportTypes.FETCH_TOLL_COST_REQUEST })

      try {
        const tollCost = await API.ObjectAdministration.Value.getValue({ flag: Flag.TOLL_COST_IN_CENTS })
        dispatch({
          type: TransportTypes.FETCH_TOLL_COST_SUCCESS,
          payload: {
            tollCostInCents: parseFloat(tollCost)
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, TransportTypes.FETCH_TOLL_COST_FAILURE, dispatch)
      }
    }
  },

  setTransportCost: (cost: number): SetTransportCost => ({
    type: TransportTypes.SET_TRANSPORT_COST,
    payload: { cost }
  })
}
export const fetchCost: AppThunk = (dispatch, getState) => {
  const { where, requireAddressDestination, shouldFetchCost } = getState().NewRemoval.transport

  if (where && requireAddressDestination && shouldFetchCost) {
    dispatch(actions.fetchCost(where))
  }
}

export default actions
