import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'antd'
import styles from './SearchDropdown.module.scss'
import { SearchProps } from '../../../types/types'
import Overlay from '../Overlay/Overlay'
import HeaderIcon from '../../HeaderIcon/HeaderIcon'
import { Search as SearchIcon } from '../../../../../icons'
import useFocus from '../../../../../utils/hooks/useFocus'

type SearchDropdownProps = SearchProps

const SearchDropdown = ({ onConfirm, value, label, extraComponent, placeholder }: SearchDropdownProps) => {
  const [localValue, setLocalValue] = useState<string>('')
  const [visible, setVisible] = useState(false)
  const { ref, setFocus } = useFocus()

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFocus()
    }, 0)

    return () => clearTimeout(timeout)
  }, [visible])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleSearch = (newInputValue: string) => {
    setVisible(false)
    onConfirm(newInputValue)
  }

  const handleReset = () => handleSearch('')

  const overlay = (
    <Overlay
      buttons={
        <>
          <Button type="primary" onClick={() => handleSearch(localValue)} icon="search" size="small">
            Buscar
          </Button>
          <Button onClick={handleReset} size="small">
            Resetear
          </Button>
        </>
      }>
      <input
        ref={ref}
        value={localValue}
        className={styles.Input}
        placeholder={placeholder ?? `Buscar por ${label}`}
        onChange={(e) => setLocalValue(e.target.value)}
        onKeyPress={(event) => event.key === 'Enter' && handleSearch(localValue)}
      />
      {extraComponent}
    </Overlay>
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
      <div>
        <HeaderIcon overlayOpen={visible} active={!!value.length}>
          <SearchIcon />
        </HeaderIcon>
      </div>
    </Dropdown>
  )
}

export default SearchDropdown
