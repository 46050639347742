import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/editDateService'
import Component from '../components/EditDateService'

const CancelContainer = () => {
  const dispatch = useDispatch()

  const editDateServiceActions = bindActionCreators(actions, dispatch)
  const editDateServiceState = useSelector((state) => state.EditDateServiceOperation)
  const calendarState = useSelector((state) => state.Deposits.calendar)

  const EditDateServiceProps = {
    calendar: calendarState,
    editDateServiceActions,
    editDateServiceState
  }

  return <Component {...EditDateServiceProps} />
}

export default CancelContainer
