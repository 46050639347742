import types from './Users.types'
import { URL_BASE_USERS } from '../../endpoints'
import { handleErrorsWithAction, handleErrorsWithActionLoginAs } from '../../utils/HandleErrors'
import { openAnotherAppInNewTab } from '../../utils/ExternalLinks'
import { apiPrivate } from '../../api'
import { reduce } from 'ramda'
import { dateRangeFiltersToParams } from '../../utils/searchFilterUtils'
import { formatDates } from '../../projectApi/utils'
import usersMetricsActionsCreator from './actions/usersMetrics'

const paymentMethodsActions = {
  getPaymentMethods() {
    return (dispatch) => {
      dispatch({ type: types.GET_PAYMENT_METHODS })
      return apiPrivate
        .get(URL_BASE_USERS + '/users/paymentMethods')
        .then((response) => {
          const { paymentMethods } = response.data.description
          dispatch({
            type: types.GET_PAYMENT_METHODS_SUCCESS,
            payload: { paymentMethods }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.GET_PAYMENT_METHODS_FAIL, dispatch)
        })
    }
  }
}

const loginAsActions = {
  setLoginAsModalOpen(loginAsModalOpen, selectedUser) {
    return {
      type: types.SET_LOGIN_AS_MODAL_OPEN,
      payload: { loginAsModalOpen, selectedUser }
    }
  },
  setAdminEmail(email) {
    return { type: types.SET_ADMIN_EMAIL, payload: { email } }
  },

  setAdminPassword(password) {
    return { type: types.SET_ADMIN_PASSWORD, payload: { password } }
  },

  setRedirection(redirectTo) {
    return { type: types.SET_REDIRECTION, payload: { redirectTo } }
  },

  loginAsUser(userAdmin, userID) {
    return (dispatch) => {
      dispatch({ type: types.LOGIN_AS_USER })

      const formData = new FormData()
      const { email, password, redirectTo } = userAdmin

      formData.append('email', email)
      formData.append('password', password)
      formData.append('impersonatedId', userID)

      return apiPrivate
        .post(URL_BASE_USERS + '/users/impersonate', formData)
        .then((response) => {
          const url = '?lia=' + response.data.description.token
          dispatch({ type: types.LOGIN_AS_USER_SUCCESS })
          openAnotherAppInNewTab(redirectTo, url)
        })
        .catch((error) => {
          handleErrorsWithActionLoginAs(error, types.LOGIN_AS_USER_FAIL, dispatch)
        })
    }
  }
}

const usersTableActions = {
  cleanFilters() {
    return { type: types.CLEAN_FILTERS }
  },

  resetState() {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({ type: types.RESET_STATE })
        resolve()
      })
    }
  },

  selectUserAndFilter(id, history) {
    return (dispatch, getState) => {
      dispatch(usersTableActions.resetState()).then(() => {
        const { params, searchFilters } = getState().Users.table
        const filtersWithID = searchFilters.map((f) => (f.key === 'id' ? { key: 'id', text: id } : f))
        const formattedParams = reduce((acc, f) => ({ ...acc, [f.key]: f.text }), params, filtersWithID)
        dispatch(usersTableActions.setSearchFilters(filtersWithID))
        dispatch(usersTableActions.getUsers(formattedParams))
        history.push('/users', { redirect: true })
      })
    }
  },

  getUsersFromStore() {
    return (dispatch, getState) => {
      const params = getState().Users.table.params
      dispatch(this.getUsers(params))
    }
  },

  getUsers(params) {
    return (dispatch, getState) => {
      dispatch({ type: types.FETCH_USERS })

      const {
        CountrySelector: { countrySelected }
      } = getState()

      const { dateRangeFilters, ...restParams } = params

      const paramsWithCountry = {
        ...restParams,
        ...formatDates({
          dates: {
            date_from: dateRangeFiltersToParams(params.dateRangeFilters).createdAtFrom,
            date_to: dateRangeFiltersToParams(params.dateRangeFilters).createdAtTo
          },
          format: 'YYYY-MM-DD'
        }),
        country_code: countrySelected.code
      }

      dispatch(usersMetricsActionsCreator.getMetrics(paramsWithCountry))

      return apiPrivate
        .get(URL_BASE_USERS + '/users', { params: paramsWithCountry })
        .then((response) => {
          const { users, quantity } = response.data.description

          dispatch({
            type: types.FETCH_USERS_SUCCESS,
            payload: { users, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_USERS_FAIL, dispatch)
        })
    }
  },

  setSorter(sorter) {
    return { type: types.SET_SORTER, payload: { sorter } }
  },

  setFilter(filter) {
    return { type: types.SET_FILTER, payload: { filter } }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  }
}

export default {
  ...paymentMethodsActions,
  ...loginAsActions,
  ...usersTableActions
}
