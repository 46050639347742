import React from 'react'
import styles from './OperationData.module.scss'
import InputGroup from '../../../../components/InputGroup'
import { LoadingIndicator } from '../../../../components/LoadingIndicator'

type OperationValue = string | number

export type BaseOperationData<T extends OperationValue> = {
  label: string
  value: T
}

interface OperationDataProps {
  loading: boolean
  title: string
}

export const Loading = () => (
  <div className={styles.loadingContainer}>
    <LoadingIndicator size={'default'} />
  </div>
)

const OperationData: React.FC<OperationDataProps> = ({ loading, title, children }) => {
  const Content = () => {
    if (loading) return <Loading />
    else if (children) return <div className={styles.dataContent}>{children}</div>
    else return <p className={styles.noData}>No hay datos asociados a esta operación</p>
  }

  return (
    <InputGroup className={styles.dataContainer} title={title}>
      <Content />
    </InputGroup>
  )
}

export default OperationData

export const Data = <T extends OperationValue>(props: BaseOperationData<T>) => {
  const { label, value } = props
  return (
    <div className={styles.cell}>
      <p className={styles.inputTitle}>{label}</p>
      <p>{value}</p>
    </div>
  )
}
