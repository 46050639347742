import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import addressActions from '../../../../components/Address/actions'
import TransportActionCreators from './transport'
import { API } from '../../../../projectApi'
import { Address } from '../../../../projectApi/UserProfile/Admin/Address/common'
import {
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  SAVE_ADDRESS_FAILURE,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_SUCCESS,
  SET_ADDRESS_ID
} from '../../types/EditDepositViewTypes/address'

const AddressActionCreators = {
  setAddress:
    (address: Address | null): AppThunk =>
    (dispatch) => {
      if (!address) {
        dispatch({
          type: SET_ADDRESS_ID,
          payload: {
            addressID: 0
          }
        })
        return
      }

      const { id, address: street, number, original } = address
      const addressString = original || `${street} ${number}`
      dispatch({
        type: SET_ADDRESS_ID,
        payload: {
          addressID: id
        }
      })
      if (id !== 0) dispatch(TransportActionCreators.setWhereDestination({ where: addressString }))
    },

  getAddresses: (userId: number): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: GET_ADDRESSES_REQUEST })

      try {
        const { addresses } = await API.UserProfile.admin.Address.listByUser({ userId })
        dispatch({
          type: GET_ADDRESSES_SUCCESS,
          payload: {
            addresses
          }
        })
      } catch (e) {
        handleErrorsWithAction(e, GET_ADDRESSES_FAILURE, dispatch)
      }
    }
  },

  saveAddress: (userID: number, address: any): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: SAVE_ADDRESS_REQUEST })

      const { addressGoogle, street, number, floor, apartment, postalCode, province, city, comment } = address

      const formData = new FormData()

      formData.append('user_id', String(userID))
      formData.append('address', street)
      formData.append('number', number)
      formData.append('floor', floor)
      formData.append('apartment', apartment)
      formData.append('postal_code', postalCode)
      if (comment) formData.append('comment', comment)
      if (addressGoogle) formData.append('original', addressGoogle)
      formData.append('province', province)
      formData.append('city', city)

      try {
        const { address } = await API.UserProfile.admin.Address.save(formData)

        dispatch({
          type: SAVE_ADDRESS_SUCCESS,
          payload: {
            address
          }
        })
        dispatch(addressActions.cleanAddress())
      } catch (e) {
        handleErrorsWithAction(e, SAVE_ADDRESS_FAILURE, dispatch)
      }
    }
  }
}

export default AddressActionCreators
