import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import attributeActionCreators from '../actionCreators/Attributes'
import deleteAttributeActionCreators from '../actionCreators/DeleteAttributes'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import {
  removeNullishValues,
  updateMultipleSearchFilters,
  updateSingleSearchFilter
} from '../../../components/DynamicTable/queryParamsUtils'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { AttributeTable } from '../components/AttributeTable'
import { searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  AttributesTableCategoryFilter,
  AttributesTableSearchFilter,
  AttributesTableSearchKey,
  AttributesTableSort,
  AttributesTableSortKey,
  Pagination
} from '../types/AttributesTable'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const AttributeContainer = () => {
  const dispatch = useDispatch()
  const attributesActions = bindActionCreators(attributeActionCreators, dispatch)
  const deleteAttributeAction = bindActionCreators(deleteAttributeActionCreators, dispatch)
  const { attributesTable } = useSelector((state) => state.Attributes)
  const { error, loading, attributes, isLoadingReport } = attributesTable
  const { getAttributes, clearState, sendAttributesReport } = attributesActions

  const { sort, categoryFilter } = attributesTable

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    productId: StringParam,
    sortField: StringParam,
    sortDirection: StringParam,
    id: StringParam,
    name: StringParam,
    isVisible: StringParam
  })

  useEffect(() => {
    handleAttributes({
      pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
      searchFilters: updateMultipleSearchFilters(attributesTable.searchFilters, [
        { key: AttributesTableSearchKey.ID, text: query.id },
        { key: AttributesTableSearchKey.NAME, text: query.name },
        { key: AttributesTableSearchKey.VISIBLE, text: query.isVisible }
      ]),
      sort:
        query.sortField && query.sortDirection
          ? {
              ...attributesTable.sort,
              field: query.sortField as AttributesTableSortKey,
              direction: query.sortDirection as SortDirection
            }
          : attributesTable.sort
    })
  }, [])

  const handleSearch = (key: AttributesTableSearchKey, newValue: string) => {
    const newSearchFilters = updateSingleSearchFilter(attributesTable.searchFilters, key, newValue)
    handleAttributes({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const emptyPagination = { ...attributesTable.pagination, page: 1 }

  const handleAttributes = (
    newParams: {
      pagination?: Pagination
      sort?: AttributesTableSort
      searchFilters?: AttributesTableSearchFilter[]
      categoryFilter?: AttributesTableCategoryFilter
    },
    id?: number
  ) => {
    const actualParams = {
      pagination: newParams.pagination || attributesTable.pagination,
      sort: newParams.sort || attributesTable.sort,
      searchFilters: newParams.searchFilters || attributesTable.searchFilters,
      categoryFilter: newParams.categoryFilter || attributesTable.categoryFilter
    }
    getAttributes({ ...actualParams })
    const newQuery = {
      id,
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      sortField: actualParams.sort.field,
      categoryFilter: actualParams.categoryFilter
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handlePageChange = (newPage: number) => {
    handleAttributes({ pagination: { ...attributesTable.pagination, page: newPage } })
  }

  const handleCategoryFilter = (newCategoryFilter: AttributesTableCategoryFilter) => {
    handleAttributes({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleSort = (newSort: AttributesTableSort) => {
    handleAttributes({ sort: newSort })
  }

  useEvents(
    [Events.Attributes.ATTRIBUTE_CREATED, Events.Attributes.ATTRIBUTE_UPDATED, Events.Attributes.ATTRIBUTE_DELETED],
    () => handleAttributes({})
  )

  const handleResetTableFilters = () => {
    const resetSearchFilters = attributesTable.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetSort: AttributesTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleAttributes({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters
    })
  }

  const handleResetAllFilters = () => {
    handleResetTableFilters()
  }

  const handleSendReportCSV = () => {
    const actualParams = {
      pagination: attributesTable.pagination,
      sort: attributesTable.sort,
      searchFilters: attributesTable.searchFilters,
      categoryFilter: attributesTable.categoryFilter
    }
    sendAttributesReport({ ...actualParams })
  }

  const props = {
    attributesActions,
    error,
    loading,
    attributes,
    pagination: {
      currentPage: attributesTable.pagination.page,
      pageSize: attributesTable.pagination.pageSize,
      total: attributesTable.pagination.total,
      onPageChange: handlePageChange
    },
    sort: attributesTable.sort,
    handleSort,
    handleCategoryFilter,
    handleResetAllFilters,
    categoryFilter: attributesTable.categoryFilter,
    openDeleteModal: deleteAttributeAction.openDeleteModal,
    handleSearch,
    searchFilters: attributesTable.searchFilters,
    handleSendReportCSV,
    isLoadingReport
  }

  return <AttributeTable {...props} />
}

export default AttributeContainer
