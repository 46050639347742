import types from '../types/Discount'

const LIMIT = 10

const initialRoot = {
  discounts: [],
  loading: false,
  params: {
    limit: LIMIT,
    offset: 0,
    sort: 'id',
    order: 'desc'
  },
  pagination: {
    current: 1,
    total: 0,
    pageSize: LIMIT
  },
  searchFilters: [{ key: 'code', text: '' }],
  sorter: {
    order: 'desc',
    field: 'id',
    columnKey: 'id'
  },
  filter: {}
}

function discountReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.CLEAN_FILTERS: {
      return {
        ...state,
        params: initialRoot.params,
        searchFilters: initialRoot.searchFilters,
        filter: initialRoot.filter,
        pagination: initialRoot.pagination
      }
    }
    case types.FETCH_DISCOUNT_REQUEST:
      return {
        ...state,
        discounts: [],
        loading: true,
        error: ''
      }
    case types.FETCH_DISCOUNT_SUCCESS:
      return {
        ...state,
        discounts: action.payload.discounts,
        loading: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case types.FETCH_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    default:
      return state
  }
}

export default discountReducer
