import types from '../types/payment-cbu'
import commonTypes from '../types/common'

const initialState = {
  cbus: [],
  loadingCBUs: false,
  errorCBUs: '',
  cbu_id: 0,
  loadingSaveCBU: false,
  errorSaveCBU: ''
}

const root = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.FINISH:
      return initialState
    case types.SET_CBU_ID:
      return { ...state, cbu_id: action.payload.cbu_id }
    case types.GET_CBUS_REQUEST:
      return {
        ...state,
        loadingCBUs: true,
        errorCBUs: ''
      }
    case types.GET_CBUS_SUCCESS: {
      const { cbus } = action.payload
      return {
        ...state,
        loadingCBUs: false,
        cbus
      }
    }
    case types.GET_CBUS_FAILURE:
      return {
        ...state,
        loadingCBUs: false,
        errorCBUs: action.payload.error
      }
    case types.SAVE_CBU_REQUEST:
      return {
        ...state,
        loadingSaveCBU: true,
        errorSaveCBU: ''
      }
    case types.SAVE_CBU_SUCCESS: {
      const { cbu } = action.payload
      return {
        ...state,
        loadingSaveCBU: false,
        cbus: [cbu, ...state.cbus],
        cbu_id: cbu.id
      }
    }
    case types.SAVE_CBU_FAILURE:
      return {
        ...state,
        loadingSaveCBU: false,
        errorSaveCBU: action.payload.error
      }
    default:
      return state
  }
}

export default root
