import React from 'react'

function Dot() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
      <g fillRule="evenodd" clipPath="url(#clip0_214_1115)" clipRule="evenodd">
        <path fill="#fff" d="M8.75 16a8 8 0 100-16 8 8 0 000 16z"></path>
        <path fill="#E0E0E7" d="M8.75 12a4 4 0 100-8 4 4 0 000 8z"></path>
      </g>
      <defs>
        <clipPath id="clip0_214_1115">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.75)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Dot
