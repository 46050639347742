import { list } from './list'
import { deleteAttribute } from './delete'
import { createAttribute } from './create'
import { updateAttribute } from './update'
import { updateProductAttribute } from './product'
import { reports } from './reports'

export const AttributeAPI = {
  list,
  delete: deleteAttribute,
  create: createAttribute,
  update: updateAttribute,
  updateProduct: updateProductAttribute,
  reports
}
