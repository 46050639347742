import { Tooltip } from 'antd'
import React from 'react'

type DeletedObjectStatusProps = {
  deletedReason: string | null
  objectStatus: string
}

const DeletedObjectStatus = ({ deletedReason, objectStatus }: DeletedObjectStatusProps) => {
  const titleComponent = () => {
    return <>{deletedReason && <div>Razón: {deletedReason}</div>}</>
  }
  return (
    <Tooltip title={titleComponent}>
      <div style={{ textDecoration: 'line-through' }}>{objectStatus}</div>
      <span style={{ color: 'red' }}>Eliminado</span>
    </Tooltip>
  )
}

export default DeletedObjectStatus
