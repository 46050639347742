import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserABMActionCreators from '../actions/userABM'
import UserInviteModal from '../components/UserInviteModal'
import { BaseOption } from '../../../../components/Select/Select'
import { Role } from '../../../../projectApi/AccountManager/Roles/list'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const userABMActions = bindActionCreators(UserABMActionCreators, dispatch)

  const { getCorporateRoles, openModalInvite, sendInvitation, verifyIfEmailExists, setEmail, setRoleId, changeRole } =
    userABMActions

  const userABMState = useSelector((state) => state.CorporateUsersABM)
  const [errorEmail, setErrorEmail] = useState<string>('')

  const { isAddModalOpen, corporateRoles, email, isEdit, selectedUser } = userABMState

  const closeModal = () => {
    openModalInvite({ isAddModalOpen: false })
  }

  type Option = BaseOption<number>

  const handleCloseModal = () => {
    setEmail('')
    setErrorEmail('')
    setRoleId(0)
    closeModal()
  }

  const onOkClick = async ({ email, roleId }: { email: string; roleId: number }) => {
    if (!isEdit) {
      const sendResult = await sendInvitation({ email, roleId })
      // @ts-ignore
      if (sendResult) handleCloseModal()
    } else {
      if (selectedUser) {
        const resultChange = await changeRole({ userId: selectedUser?.id, roleId })
        // @ts-ignore
        if (resultChange) handleCloseModal()
      }
    }
  }

  const formatRolesOptions: Option[] = useMemo(() => {
    return corporateRoles.map((role: Role) => ({ value: role.id, label: role.description }))
  }, [corporateRoles])

  const handleChangeEmail = (value: string) => {
    setEmail(value)
  }

  const getOptionSelected = (roleId: number | null): Option => {
    const { id, description } = corporateRoles?.find((role: Role) => role.id === roleId) || { id: 0, description: '' }

    return { value: id, label: description }
  }

  useEffect(() => {
    if (isAddModalOpen) getCorporateRoles()
  }, [isAddModalOpen])

  const props = {
    userABMState,
    userABMActions,
    handleCloseModal,
    errorEmail,
    formatRolesOptions,
    onOkClick,
    getOptionSelected,
    handleChangeEmail
  }

  return <UserInviteModal {...props} />
}

export default Container
