import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DatePicker } from 'antd'
import Overlay from '../Overlay/Overlay'
import HeaderIcon from '../../HeaderIcon/HeaderIcon'
import moment, { Moment } from 'moment'
import { Calendar as CalendarIcon } from '../../../../../icons'
import { SingleDatePickerProps } from '../../../types/types'

type SingleDatePickerDropdownProps = SingleDatePickerProps

const SingleDatePickerDropdown = ({ date, onConfirm }: SingleDatePickerDropdownProps) => {
  const [localDate, setLocalDate] = useState<Moment>(moment())
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (date && !date?.isSame(localDate)) setLocalDate(date)
  }, [date])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleDateChange = (newDate?: Moment | null) => {
    if (!newDate) return

    setLocalDate(newDate)
  }

  const handleConfirm = (reset?: boolean) => {
    onConfirm(reset ? undefined : localDate)
    setVisible(false)
  }

  const overlay = (
    <Overlay
      buttons={
        <>
          <Button type="primary" onClick={() => handleConfirm(false)} icon="search" size="small">
            Buscar
          </Button>
          <Button onClick={() => handleConfirm(true)} size="small">
            Resetear
          </Button>
        </>
      }>
      <DatePicker
        onChange={(newDate) => handleDateChange(newDate)}
        value={localDate}
        size="small"
        format="DD/MM/YYYY"
      />
    </Overlay>
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} visible={visible} onVisibleChange={handleVisibleChange}>
      <div>
        <HeaderIcon overlayOpen={visible} active={Boolean(date)}>
          <CalendarIcon />
        </HeaderIcon>
      </div>
    </Dropdown>
  )
}

export default SingleDatePickerDropdown
