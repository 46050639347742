import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import {
  ADD_ATTRIBUTE,
  AddAttribute,
  CLEAR_STATE,
  CLOSE_ATTRIBUTE_MODAL,
  ClearState,
  CloseAttributeModal,
  GET_ATTRIBUTES_BY_PRODUCT_FAILURE,
  GET_ATTRIBUTES_BY_PRODUCT_REQUEST,
  GET_ATTRIBUTES_BY_PRODUCT_SUCCESS,
  GET_ATTRIBUTES_FAILURE,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  OPEN_ATTRIBUTE_MODAL,
  OpenAttributeModal,
  ProductAttribute,
  REMOVE_ATTRIBUTE,
  RemoveAttribute,
  UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE,
  UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST,
  UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS
} from '../types/attributeModal'
import { Pagination } from '../../../utils/searchFilterUtils'
import { AttributeUpdate } from '../../../projectApi/ObjectAdministration/Attribute/product'

let searchTimer: NodeJS.Timeout
let getItemPricesOldLastSearchId: number

const actions = {
  getAttributes({ productId, pagination }: { productId?: number; pagination: Pagination }): AppThunk {
    return async function (dispatch) {
      const toast = setToastLoading('Buscando atributos, por favor espere...')
      dispatch({
        type: GET_ATTRIBUTES_BY_PRODUCT_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page
        }
      })
      try {
        const response = await API.ObjectAdministration.Attribute.list({
          productId,
          limit: pagination?.pageSize || 0,
          offset: pagination ? (pagination.page - 1) * pagination.pageSize : 0
        })
        const { attributes } = response
        const productAttributes: ProductAttribute[] =
          attributes?.map((x) => {
            return {
              attribute: x
            }
          }) || []
        dispatch({ type: GET_ATTRIBUTES_BY_PRODUCT_SUCCESS, payload: { productAttributes } })
        setToastSuccessUpdate(toast, { render: 'Atributos cargados con éxito!.' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al cargar los atributos...' })
        handleErrorsWithAction(e, GET_ATTRIBUTES_BY_PRODUCT_FAILURE, dispatch)
      }
    }
  },
  getAttributesBySearch:
    (name: string, pagination: Pagination): AppThunk =>
    (dispatch) => {
      clearTimeout(searchTimer)
      searchTimer = setTimeout(async () => {
        const params = {
          limit: pagination?.pageSize || 0,
          offset: pagination ? (pagination.page - 1) * pagination.pageSize : 0,
          name
        }

        const lastSearchID = new Date().getMilliseconds()
        getItemPricesOldLastSearchId = lastSearchID

        dispatch({ type: GET_ATTRIBUTES_REQUEST, payload: { lastSearchID } })

        try {
          if (lastSearchID === getItemPricesOldLastSearchId) {
            const { attributes, total } = await API.ObjectAdministration.Attribute.list(params)

            dispatch({
              type: GET_ATTRIBUTES_SUCCESS,
              payload: {
                attributes,
                total
              }
            })
          }
        } catch (e) {
          handleErrorsWithAction(e, GET_ATTRIBUTES_FAILURE, dispatch)
        }
      }, 250)
    },
  updateProductAttributes:
    ({
      productId,
      productAttributes
    }: {
      productId: number
      productAttributes: ProductAttribute[]
    }): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const toast = setToastLoading('modificando atributos, por favor espere...')
      dispatch({ type: UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST })

      const attributes: AttributeUpdate[] = productAttributes.reduce((acc, curr) => {
        if (curr.deleted || curr.isNew) {
          return [...acc, { attributeId: curr.attribute.id, deleted: curr.deleted }]
        }
        return acc
      }, [] as AttributeUpdate[])

      try {
        await API.ObjectAdministration.Attribute.updateProduct({ productId, attributes })
        dispatch({ type: UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS })
        setToastSuccessUpdate(toast, { render: 'Atributos cargados con éxito!.' })
        return true
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al cargar los atributos...' })
        handleErrorsWithAction(e, UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE, dispatch)
        return false
      }
    },

  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  openAttributeModal: (productId: number): OpenAttributeModal => ({
    type: OPEN_ATTRIBUTE_MODAL,
    payload: { productId }
  }),
  closeAttributeModal: (): CloseAttributeModal => ({ type: CLOSE_ATTRIBUTE_MODAL }),
  AddAttribute: (productAttribute: ProductAttribute): AddAttribute => ({
    type: ADD_ATTRIBUTE,
    payload: { productAttribute }
  }),
  removeAttribute: (attributeId: number): RemoveAttribute => ({ type: REMOVE_ATTRIBUTE, payload: { attributeId } })
}

export default actions
