import React from 'react'
import styles from './confirmationModal.module.scss'
import { Button, Modal } from 'antd'
import { Info } from '../../icons'

const ConfirmationModal: React.FC<{
  open: boolean
  onClose: () => void
  onConfirm: () => void
  bodyText: string
  closingDisabled?: boolean
}> = ({ open, onClose, onConfirm, bodyText, closingDisabled }) => {
  return (
    <Modal
      centered
      closable={false}
      visible={open}
      style={{ maxWidth: 500 }}
      maskClosable={closingDisabled}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={closingDisabled}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={closingDisabled} onClick={onConfirm}>
          Confirmar
        </Button>
      ]}>
      <div className={styles.modalBody}>
        <Info fill={'#FAAF19'} />
        <span>{bodyText}</span>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
