import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export type BillingWarning = {
  id: number
  name: string
  description: string
  quantity: number
}

type APIResponse = {
  paging: {
    total: number
  }
  results: BillingWarning[]
}

type Response = {
  warnings: BillingWarning[]
}

type WarningListParams = {
  periodFrom?: Moment | null
  descriptionVariables?: { [key: string]: string }
}

export function warningList({ periodFrom, descriptionVariables }: WarningListParams): Promise<Response> {
  const snakeParams = snakecaseKeys({
    period: periodFrom?.format('YYYY-MM-DD')
  })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billing-data-warnings/quantity-of-all-types`, { params: snakeParams })
    .then(({ data }: AxiosResponse<APIResponse>) => {
      const warnings = data.results.map((warning) => ({
        ...warning,
        description: replaceVariablesInString(warning.description, descriptionVariables)
      }))
      return {
        total: data.paging.total,
        warnings
      }
    })
    .catch((error) => {
      throw error
    })
}

function replaceVariablesInString(str: string, variables?: { [key: string]: string }): string {
  if (!variables) return str
  return str.replace(/\{\{([^}]+)\}\}/g, function (_, key) {
    return variables[key] || `{${key}}`
  })
}
