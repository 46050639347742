import React from 'react'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'
import { DynamicTable } from '../../../components/DynamicTable'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { formatMoney } from '../../../utils/formatNumber'
import { formatDateWithoutHour } from '../../../utils/formatDate'
import styles from './billingPaymentTable.module.scss'
import {
  BillingPaymentCategoryFilterKey,
  BillingPaymentTableCategoryFilter,
  BillingPaymentTableSort
} from '../types/BillingPayment'
import ColorBadge from '../../../components/ColorBadge/ColorBadge'
import DocumentDownload from '../../../icons/documentDownload'
import SelectedBillingContainer from '../containers/SelectedBilling'
import { useHistory } from 'react-router'
import { BILLING_PAYMENT_STATUS_OPTIONS, BillingPaymentStatusBadges, BillingPaymentStatusId } from '../constants'

type BillingPaymentTableProps = {
  searchUserActions: typeof searchUserActionCreators
  error: string
  loading: boolean
  userColppyInfo: UserColppy | null
  billings: Billing[]
  categoryFilter: BillingPaymentTableCategoryFilter
  selectedBillings: Billing[]
  pagination: Pagination
  sort: BillingPaymentTableSort
  handleSort: (newSorter: BillingPaymentTableSort) => void
  handleToggleAllBillings: () => void
  handleToggleBilling: (bi: Billing) => void
  handleCategoryFilter: (newFilter: BillingPaymentTableCategoryFilter) => void
}

export const billingCanBeSelected = (bi: Billing) =>
  bi.status === BillingPaymentStatusId.APROBADA || bi.status === BillingPaymentStatusId.PARCIALMENTE_PAGA

const BillingPaymentTable = (props: BillingPaymentTableProps) => {
  const {
    loading,
    error,
    billings,
    pagination,
    sort,
    handleSort,
    handleCategoryFilter,
    selectedBillings,
    handleToggleAllBillings,
    handleToggleBilling,
    searchUserActions,
    userColppyInfo,
    categoryFilter
  } = props
  const history = useHistory()

  const columns: Column<Billing>[] = [
    {
      key: 'number',
      label: 'ID Factura',
      minWidth: 'max-content'
    },
    {
      key: 'billingDate',
      label: 'Fecha de facturación',
      renderDataCell: ({ billingDate }) => formatDateWithoutHour(billingDate)
    },

    {
      key: 'total',
      label: 'Monto',
      renderDataCell: ({ total }) => formatMoney(total)
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      filters: {
        options: BILLING_PAYMENT_STATUS_OPTIONS,
        multiple: false,
        selected: categoryFilter[BillingPaymentCategoryFilterKey.STATUS],
        onConfirm: (values) => handleCategoryFilter({ ...categoryFilter, status: values as BillingPaymentStatusId[] })
      },
      renderDataCell: ({ status }) => {
        const found = BillingPaymentStatusBadges.find((bps) => bps.value === status)
        if (!found) return '-'
        const props = {
          ...found.styleProps
        }

        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    },
    {
      key: 'number',
      label: 'PDF',
      noPadding: true,
      renderDataCell: ({ billingColppyId, billingId }) => {
        if (!billingColppyId) return null

        return (
          !!billingId && (
            <div className={styles.pdfContainer}>
              <DocumentDownload />
              <button onClick={() => searchUserActions.getPdfWithBillingId(billingId)}>
                <span className={styles.downloadLabel}>Descargar PDF</span>
              </button>
            </div>
          )
        )
      }
    },
    {
      key: 'billingColppyId',
      label: 'Acciones',
      noPadding: true,
      renderDataCell: (item) => {
        if (!item.billingColppyId || !billingCanBeSelected(item)) return null

        return (
          <button
            onClick={() => {
              searchUserActions.selectBilling(item)
              history.push('/billing_payment_process')
            }}
            className={styles.actionButton}>
            Realizar cobro
          </button>
        )
      }
    }
  ]

  return (
    <div>
      <h3>Cliente {userColppyInfo?.fantasyName.replace('-', ' - ')}</h3>
      <DynamicTable
        columns={columns}
        checkboxes={{
          headerIsChecked: () =>
            billings.every(
              (bi) => !billingCanBeSelected(bi) || selectedBillings.find((sbi) => sbi.number === bi.number)
            ),
          onHeaderCheckboxClick: handleToggleAllBillings,
          onRowCheckboxClick: (row) => handleToggleBilling(row),
          rowIsChecked: (row) => selectedBillings.some((bi) => bi.number === row.number),
          rowIsDisabled: (row) => !billingCanBeSelected(row)
        }}
        pagination={pagination}
        error={error}
        loading={loading}
        rows={billings}
        keyExtractor={(entity) => entity.number}
        customClassNames={{
          table: styles.customTableProps
        }}
      />
      {selectedBillings.length > 0 && <SelectedBillingContainer />}
    </div>
  )
}

export default BillingPaymentTable
