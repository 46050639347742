import React from 'react'
import { Button } from 'antd'

const styles = {
  button: {
    paddingLeft: 20,
    paddingRight: 20
  }
}

const ActionBarButton = ({ action, description, type = 'primary', icon = 'plus', ...props }) => {
  return (
    <Button size="large" type={type} icon={icon} style={styles.button} onClick={action} {...props}>
      {description}
    </Button>
  )
}

export default ActionBarButton
