import React from 'react'
import { Modal } from 'antd'
import styles from './DeleteProvider.module.scss'
import { Provider } from '../../../projectApi/Trips/Providers/list'

type DeleteProviderModalProps = {
  selectedProvider?: Provider
  loadingProvider: boolean
  deleteModalVisible: boolean
  setDeleteModalVisibility: (modalVisible: boolean) => void
  handleDeleteProvider: () => void
  error: string
}

const DeleteProviderModal = ({
  selectedProvider,
  loadingProvider,
  deleteModalVisible,
  setDeleteModalVisibility,
  handleDeleteProvider,
  error
}: DeleteProviderModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={() => setDeleteModalVisibility(false)}
      title={'Eliminar proveedor'}
      confirmLoading={loadingProvider}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={handleDeleteProvider}
      okButtonProps={{ disabled: loadingProvider }}
      destroyOnClose>
      <InfoCard id={selectedProvider?.id} name={selectedProvider?.name} />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar este proveedor?</h4>
      {error && <p className={styles.errorFieldLabel}>{error}</p>}
    </Modal>
  )
}

export default DeleteProviderModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; name?: string }> = ({ id, name }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos del proveedor:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
    </div>
  </header>
)
