import { Reducer } from 'redux'

import {
  CLEAR_STATE,
  DEFAULT_PROPOSAL_CATEGORY_FILTERS,
  GET_PROPOSALS_FAILURE,
  GET_PROPOSALS_REQUEST,
  GET_PROPOSALS_SUCCESS,
  ProposalsTableActions,
  ProposalsTableState,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS
} from '../types/proposals'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const LIMIT = 10
const LIMITCSV = 50

const initialState: ProposalsTableState = {
  proposals: [],
  loadingProposals: false,
  error: '',
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'prospect_id', text: '' }
  ],
  dateRangeFilters: [{ key: 'createdAt', startDate: null, endDate: null }],
  pagination: {
    page: 1,
    total: 1,
    pageSize: LIMIT,
    csvSize: LIMITCSV
  },
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'id',
    Order: 'desc'
  },
  categoryFilter: DEFAULT_PROPOSAL_CATEGORY_FILTERS,
  Column: {
    field: 'id',
    direction: SortDirection.DESC
  },
  sendingCSV: false
}
const ProposalsTableReducer: Reducer<ProposalsTableState, ProposalsTableActions> = (
  state = initialState,
  action
): ProposalsTableState => {
  switch (action.type) {
    case GET_PROPOSALS_REQUEST:
      return {
        ...state,
        loadingProposals: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        Column: action.payload.Column || initialState.Column,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }

    case GET_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposals: action.payload.proposals,
        loadingProposals: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }

    case GET_PROPOSALS_FAILURE:
      return { ...state, loadingProposals: false, error: action.payload.error }

    case SEND_CSV_REQUEST: {
      return {
        ...state,
        sendingCSV: true
      }
    }
    case SEND_CSV_SUCCESS:
    case SEND_CSV_FAILURE: {
      return {
        ...state,
        sendingCSV: false
      }
    }
    case CLEAR_STATE: {
      return initialState
    }
    default:
      return { ...state }
  }
}

export default ProposalsTableReducer
