import React from 'react'
import { Modal } from 'antd'
import moment, { Moment } from 'moment'
import { getOperation } from '../utils/getOperation'
import { OperationTypeValues } from '../types/common'

type DeleteOperationParams = {
  date: Moment
  operationType: OperationTypeValues
}

export type DeleteDateModalProps = {
  isDeleteModalOpen: boolean
  openDeleteDateModal: (args: boolean) => void
  deleteDate: (selectedDeleteDate: DeleteOperationParams) => void
  selectedDeleteDate: DeleteOperationParams
  deletingDate: boolean
}

const DeleteDateModal = ({
  isDeleteModalOpen,
  openDeleteDateModal,
  deleteDate,
  selectedDeleteDate,
  deletingDate
}: DeleteDateModalProps) => {
  const handleConfirm = () => {
    deleteDate(selectedDeleteDate)
    openDeleteDateModal(false)
  }

  const operationDisplay = selectedDeleteDate ? getOperation(selectedDeleteDate.operationType) : '-'
  const dateDisplay = selectedDeleteDate ? moment.utc(selectedDeleteDate.date).format('dddd DD[-]MM[-]YY') : '-'
  return (
    <Modal
      title={'Habilitar fecha'}
      visible={isDeleteModalOpen}
      closable={!deletingDate}
      onOk={handleConfirm}
      width={'max-content'}
      onCancel={() => openDeleteDateModal(false)}
      cancelButtonProps={{
        disabled: deletingDate
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}>
      <p>
        ¿Está seguro que quiere volver a habilitar la operación de <br /> <b>{operationDisplay}</b> el día{' '}
        <b>{dateDisplay}</b>?
      </p>
    </Modal>
  )
}

export default DeleteDateModal
