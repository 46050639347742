export enum SCREEN_SIZE {
  MOBILE = 640,
  TABLET = 768,
  DESKTOP_S = 1024,
  DESKTOP_M = 1280,
  DESKTOP_L = 1440,
  DESKTOP_X = 1600,
  DESKTOP_XL = 1920
}

export enum ROLES {
  CORPORATIVE = 18,
  CORPORATIVE_ADMIN = 19
}

export enum COLORS {
  SUCCESS = '#1DD4B3',
  DANGER = '#CC6266',
  WARNING = '#B25E09',
  SPACE_GURU = '#5D58F7'
}
