import React, { useMemo } from 'react'
import { Col, DatePicker, InputNumber, Modal, Row } from 'antd'
import InputGroup from '../../components/InputGroup'
import { getOptionValue } from './Payment.selectors'
import { chargeTypes } from './Payments.constants'
import locale from 'antd/lib/date-picker/locale/es_ES'
import UserSearch from '../../components/UserSearch/UserSearch'
import Select from '../../components/Select'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  },
  explainer: {
    marginTop: 5,
    fontSize: '0.75rem',
    color: '#a7a7a7'
  }
}

const NewPaymentModal = (props) => {
  const {
    open,
    charge,
    loading,
    setCreateModalOpen,
    paymentMethods,
    newCharge,
    setAmount,
    setType,
    setDayToCharge,
    setStartDate,
    setEndDate,
    setUserID,
    getUsers,
    setPaymentMethod
  } = props
  const { type, amount, paymentMethod, startDate, endDate, userID, error, userSearch } = charge
  const { byID, byName, byLastName } = userSearch

  const { title, buttonLabel } = {
    title: 'Crear Regla de Cobro',
    buttonLabel: 'Crear'
  }

  const datePickerLabel = type === 1 ? 'Fecha de Inicio' : 'Fecha de Cobro'

  const renderExplainerText = (type) => {
    switch (type) {
      case 1:
        return 'Efectúa cobros el día del mes a cobrar, todos los meses, a partir de la fecha de inicio elegida.'
      case 2:
        return 'Efectúa un único cobro en la fecha de cobro elegida.'
    }
  }

  const selectedChargeType = useMemo(() => {
    return chargeTypes.find((chargeType) => chargeType.value === type)
  }, [chargeTypes, type])

  const selectedPaymentMethod = useMemo(() => {
    return paymentMethods.find((pm) => pm.value === paymentMethod)
  }, [paymentMethods, paymentMethod])

  return (
    <Modal
      title={title}
      visible={open}
      closable={!loading}
      maskClosable={!loading}
      onOk={() => newCharge(charge)}
      onCancel={() => setCreateModalOpen(false)}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={buttonLabel}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24} lg={12}>
          <UserSearch
            setUserID={setUserID}
            getUsers={getUsers}
            userID={userID}
            byID={byID}
            byName={byName}
            byLastName={byLastName}
          />
        </Col>
        <Col span={24} lg={12}>
          <InputGroup title="Regla">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <p style={styles.inputTitle}>Tipo de regla de cobro</p>
                <Select
                  style={styles.inlineSelect}
                  name="Tipo de regla"
                  placeholder="Tipo de regla"
                  selected={selectedChargeType}
                  options={chargeTypes}
                  clearable={false}
                  searchable={false}
                  onSelect={(option) => setType(getOptionValue(option))}
                />
                <div style={styles.explainer}>{renderExplainerText(type)}</div>
              </Col>
              <Col span={24}>
                <p style={styles.inputTitle}>Metodo de Pago</p>
                <Select
                  style={styles.inlineSelect}
                  name="Metodo de Pago"
                  placeholder="Metodo de Pago"
                  selected={selectedPaymentMethod}
                  options={paymentMethods}
                  clearable={false}
                  searchable={false}
                  onSelect={(option) => setPaymentMethod(getOptionValue(option))}
                />
              </Col>
              <Col span={24}>
                <Row>
                  <p style={styles.inputTitle}>Monto*</p>
                  <InputNumber
                    placeholder="0"
                    value={amount}
                    min={0}
                    onChange={setAmount}
                    style={styles.inputFullWidth}
                  />
                </Row>
              </Col>
              {type === 1 && (
                <Col span={24}>
                  <p style={styles.inputTitle}>Día del mes a cobrar*</p>
                  <InputNumber
                    placeholder="1"
                    min={1}
                    max={28}
                    onChange={setDayToCharge}
                    style={styles.inputFullWidth}
                  />
                </Col>
              )}
              <Col span={24}>
                <p style={styles.inputTitle}>{datePickerLabel}</p>
                <DatePicker
                  placeholder={datePickerLabel}
                  value={startDate}
                  locale={locale}
                  format="DD/MM/YYYY"
                  popupStyle={{ zIndex: 10000 }}
                  allowClear={false}
                  onChange={(date) => {
                    setStartDate(date)
                    if (date.isAfter(endDate)) setEndDate(null)
                  }}
                  style={styles.inputFullWidth}
                />
              </Col>
              {type === 1 && (
                <Col span={24}>
                  <p style={styles.inputTitle}>Fecha de Fin</p>
                  <DatePicker
                    placeholder="Fecha de fin"
                    locale={locale}
                    disabledDate={(date) => date.isBefore(startDate)}
                    value={endDate}
                    format="DD/MM/YYYY"
                    popupStyle={{ zIndex: 10000 }}
                    onChange={(date) => setEndDate(date)}
                    style={styles.inputFullWidth}
                  />
                </Col>
              )}
            </Row>
          </InputGroup>
        </Col>
      </Row>
      <div className="container">
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

export default NewPaymentModal
