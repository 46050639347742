import { Photo } from '../types/createComplaintModal'
import {
  CloseComplaintRequest,
  CloseComplaintSuccess,
  CloseComplaintTypes,
  SetConfirmationOpen,
  SetEvidence,
  SetOpen,
  SetReasonId,
  SettersResolveComplaintsTypes
} from '../types/resolveModal'
import { AppThunk } from '@/store'
import { API } from '@/projectApi'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import { DELETE_EVIDENCE, deleteEvidence, GetComplaintsReasonsTypes } from '../types/common'
import { handleErrorsWithAction } from '@/utils/HandleErrors'

const ResolveComplaintActionCreator = {
  closeComplaint:
    ({ complaintId, reasonId, photos }: { complaintId: number; reasonId: number; photos: Photo[] }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Cerrando reclamo, por favor espere...')
      const request: CloseComplaintRequest = {
        type: CloseComplaintTypes.CLOSE_COMPLAINT_REQUEST
      }
      dispatch(request)

      try {
        await API.Complaints.close({ complaintId, closeData: { reasonId, photos } })

        const success: CloseComplaintSuccess = {
          type: CloseComplaintTypes.CLOSE_COMPLAINT_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: '¡Reclamo cerrado exitosamente!' })
      } catch (error) {
        setToastErrorUpdate(toastId, { render: 'Hubo un error al tratar de cerrar el reclamo' })
      }
    },

  setOpenResolve: ({ open }: { open: boolean }): SetOpen => ({
    type: SettersResolveComplaintsTypes.SET_OPEN,
    payload: { open }
  }),

  setConfirmationOpen: ({ confirmationOpen }: { confirmationOpen: boolean }): SetConfirmationOpen => ({
    type: SettersResolveComplaintsTypes.SET_CONFIRMATION_OPEN,
    payload: { confirmationOpen }
  }),

  setReasonId: (reasonId: number): SetReasonId => ({
    type: SettersResolveComplaintsTypes.SET_REASON_ID,
    payload: { reasonId }
  }),

  setEvidence: (photo: Photo[]): SetEvidence => ({
    type: SettersResolveComplaintsTypes.SET_EVIDENCE,
    payload: { photo }
  }),

  deleteEvidence: (index: number): deleteEvidence => ({
    type: DELETE_EVIDENCE,
    payload: { index }
  }),

  getReasons(): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: GetComplaintsReasonsTypes.GET_REASONS_REQUEST
      })
      try {
        const { reasons } = await API.Complaints.reasons({
          column: 'id',
          order: 'desc',
          type: 'CLOSE'
        })
        dispatch({
          type: GetComplaintsReasonsTypes.GET_REASONS_SUCCESS,
          payload: {
            reasons
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetComplaintsReasonsTypes.GET_REASONS_FAILURE, dispatch)
      }
    }
  }
}

export default ResolveComplaintActionCreator
