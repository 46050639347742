import { Reducer } from 'redux'
import { GetProposalMetricsTypes, ProposalMetricsActions, ProposalMetricsState } from '../types/metrics'

const initialState: ProposalMetricsState = {
  quantityMetrics: [],
  fetchingMetrics: false
}

const proposalMetricsReducer: Reducer<ProposalMetricsState, ProposalMetricsActions> = (
  state = initialState,
  action
): ProposalMetricsState => {
  switch (action.type) {
    case GetProposalMetricsTypes.GET_PROPOSAL_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetProposalMetricsTypes.GET_PROPOSAL_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        quantityMetrics: action.payload.quantityMetrics
      }
    case GetProposalMetricsTypes.GET_PROPOSAL_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default proposalMetricsReducer
