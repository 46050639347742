export enum LeadsEventsEnum {
  LEAD_CREATED = 'LEADS/LEAD_CREATED',
  LEAD_UPDATED = 'LEADS/LEAD_UPDATED',
  CLEAN_FILTERS = 'LEADS/CLEAN_FILTERS'
}

export type LeadsEvents = {
  [LeadsEventsEnum.LEAD_CREATED]: () => void
  [LeadsEventsEnum.LEAD_UPDATED]: () => void
  [LeadsEventsEnum.CLEAN_FILTERS]: () => void
}
