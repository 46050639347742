import InputGroup from '@/components/InputGroup'
import { InputTitle } from '@/components/NewCard/NewCard'
import { Col, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styles from '../ComplaintUpdateView.module.scss'
import Select from '@/components/Select'
import { TableActionBar } from '@/components/TableActionBar'
import RequiredField from '@/components/RequiredField/RequiredField'
import TextAreaInput from '@/components/TextAreaInput'
import { Priority } from '../../types/updateView'
import { generateOptions, getSelectedOption, handleRoleType } from '../../utils/generateSelectOptions'
import { useLocalization } from '@/packages/localization'
import { Agent } from '@/projectApi/Mercurio/BO/Agents/common'
import { Input } from '@/components/UploadPhotos'
import { useHistory } from 'react-router'
import useDebounce from '@/utils/hooks/useDebounce'

type ComplaintDetailsProps = {
  setDescription: (description: string) => void
  descriptionUpdate: string
  setPriority: (priority: keyof typeof Priority | null) => void
  priority: keyof typeof Priority | null
  setAgent: (assignedUserId: number | null) => void
  assignedUserId: number | null
  agents: Agent[]
  fetchingAgents: boolean
  createUserId: number
  complaintId: number
  handleConfirm: () => void
  loadingPhoto: boolean
  deleteEvidence: (index: number) => void
}

const priorityOptions = generateOptions(Priority)

const ComplaintDetails = ({
  setDescription,
  descriptionUpdate,
  setPriority,
  priority,
  setAgent,
  assignedUserId,
  agents,
  fetchingAgents,
  handleConfirm,
  loadingPhoto,
  deleteEvidence
}: ComplaintDetailsProps) => {
  const [agentName, setAgentName] = useState('')
  const [descriptionLocal, setDescriptionLocal] = useState('')
  const [clearPhotos, setClearPhotos] = useState(false)

  const debouncedDescriptionValue = useDebounce(descriptionLocal)

  useEffect(() => {
    setDescription(debouncedDescriptionValue)
  }, [debouncedDescriptionValue])

  const { strings } = useLocalization()

  const agentsOptions = useMemo(
    () =>
      agents.map((agent) => ({
        value: agent.userId,
        label: `${agent.userId} - ${agent.firstName} ${agent.lastName} - ${handleRoleType(agent.role.roleId)}`
      })),
    [agents]
  )
  const history = useHistory()

  const handleClearLocal = () => {
    setAgentName('')
    setDescriptionLocal('')
    setDescription('')
    setAgent(null)
    setPriority(null)
    setClearPhotos(true)
  }
  const isDisabled = !descriptionUpdate || !assignedUserId || !priority || loadingPhoto

  return (
    <div className={styles.details}>
      <Row>
        <Col>
          <Row>
            <InputGroup title={strings.generic.details}>
              <Row gutter={[16, 16]}>
                <Col sm={12} xs={24}>
                  <InputTitle title={strings.ComplaintsViewScreen.updateView.complaintDetails.agentTitle} />
                  <Select
                    loading={fetchingAgents}
                    disabled={fetchingAgents}
                    onSelect={({ value }) => setAgent(value)}
                    className={styles.selectAgent}
                    autocomplete={{
                      filterByInput: true,
                      input: agentName,
                      onInputChange: (text) => setAgentName(text)
                    }}
                    onDelete={() => setAgent(null)}
                    selected={agentsOptions.find((agent) => agent.value === assignedUserId)}
                    options={agentsOptions}
                    placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.agentPlaceholder}
                  />
                  <RequiredField />
                </Col>
                <Col sm={12} xs={24}>
                  <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.priorityTitle} />
                  <Select
                    onSelect={({ value }) => setPriority(value)}
                    selected={getSelectedOption(priorityOptions, priority)}
                    options={priorityOptions}
                    className={styles.selectPriority}
                    placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.priorityPlaceholder}
                    onDelete={() => setPriority(null)}
                  />
                  <RequiredField />
                </Col>
              </Row>
              <Row>
                <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.descriptionTitle} />
                <TextAreaInput
                  maxLength={300}
                  value={descriptionLocal}
                  onChange={(e) => setDescriptionLocal(e.target.value)}
                  placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.descriptionPlaceholder}
                />
                <RequiredField
                  text={strings.ComplaintsViewScreen.createComplaintModal.fields.requiredFieldDetails}
                  className={styles.textAreaRequired}
                />
              </Row>
              <Row className={styles.rowPhotos}>
                <Input
                  label={strings.ComplaintsViewScreen.createComplaintModal.fields.evidence}
                  maxCount={10}
                  fileType="objects"
                  clear={clearPhotos}
                  handleRemovePhoto={deleteEvidence}
                />
              </Row>
              <div className={styles.btnWrapper}>
                <span />
                <div className={styles.btnContainer}>
                  <TableActionBar.ButtonAction
                    customIcon={<></>}
                    variant="secondary"
                    titleButton={strings.generic.cancel}
                    customClassName={styles.btnAction}
                    onClickButton={() => {
                      handleClearLocal()
                      history.goBack()
                    }}
                  />
                  <TableActionBar.ButtonAction
                    customIcon={<></>}
                    titleButton={strings.generic.update}
                    customClassName={styles.btnAction}
                    disabled={isDisabled}
                    onClickButton={() => {
                      handleConfirm()
                      handleClearLocal()
                    }}
                  />
                </div>
              </div>
            </InputGroup>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ComplaintDetails
