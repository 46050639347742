export enum ObjectTypes {
  SELECT_OBJECT = 'EDIT_CORPORATIVE_OBJECT/SELECT_OBJECT',
  CANCEL = 'EDIT_CORPORATIVE_OBJECT/CANCEL',
  SET_LOCATION = 'EDIT_CORPORATIVE_OBJECT/SET_LOCATION',
  SET_PRODUCT = 'EDIT_CORPORATIVE_OBJECT/SET_PRODUCT',
  SET_HEIGHT = 'EDIT_CORPORATIVE_OBJECT/SET_HEIGHT',
  SET_WIDTH = 'EDIT_CORPORATIVE_OBJECT/SET_WIDTH',
  SET_LENGTH = 'EDIT_CORPORATIVE_OBJECT/SET_LENGTH',
  MODIFY_OBJECT_REQUEST = 'EDIT_CORPORATIVE_OBJECT/MODIFY_OBJECT_REQUEST',
  MODIFY_OBJECT_SUCCESS = 'EDIT_CORPORATIVE_OBJECT/MODIFY_OBJECT_SUCCESS',
  MODIFY_OBJECT_FAILURE = 'EDIT_CORPORATIVE_OBJECT/MODIFY_OBJECT_FAILURE',
  GET_LOCATIONS_REQUEST = 'EDIT_CORPORATIVE_OBJECT/GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS = 'EDIT_CORPORATIVE_OBJECT/GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE = 'EDIT_CORPORATIVE_OBJECT/GET_LOCATIONS_FAILURE',
  GET_PRODUCTS_REQUEST = 'EDIT_CORPORATIVE_OBJECT/GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS = 'EDIT_CORPORATIVE_OBJECT/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE = 'EDIT_CORPORATIVE_OBJECT/GET_PRODUCTS_FAILURE',
  GET_LOCATION_BY_CODE_REQUEST = 'EDIT_CORPORATIVE_OBJECT/GET_LOCATION_BY_CODE_REQUEST',
  GET_LOCATION_BY_CODE_SUCCESS = 'EDIT_CORPORATIVE_OBJECT/GET_LOCATION_BY_CODE_SUCCESS',
  GET_LOCATION_BY_CODE_FAILURE = 'EDIT_CORPORATIVE_OBJECT/GET_LOCATION_BY_CODE_FAILURE',
  GET_PRODUCT_ATTRIBUTES_REQUEST = 'EDIT_CORPORATIVE_OBJECT/GET_PRODUCT_ATTRIBUTES_REQUEST',
  GET_PRODUCT_ATTRIBUTES_SUCCESS = 'EDIT_CORPORATIVE_OBJECT/GET_PRODUCT_ATTRIBUTES_SUCCESS',
  GET_PRODUCT_ATTRIBUTES_FAILURE = 'EDIT_CORPORATIVE_OBJECT/GET_PRODUCT_ATTRIBUTES_FAILURE',
  SET_ATTRIBUTE_TYPE = 'EDIT_CORPORATIVE_OBJECT/SET_ATTRIBUTE_TYPE',
  SET_ATTRIBUTE_VALUE = 'EDIT_CORPORATIVE_OBJECT/SET_ATTRIBUTE_VALUE',
  DELETE_ATTRIBUTE = 'EDIT_CORPORATIVE_OBJECT/DELETE_ATTRIBUTE'
}
