import { getCreditCardTypeName } from '../creditCard'

export const formatCardsToOptions = (cards) => [
  ...cards.map((card) => ({
    label: formatCard(card),
    value: card.ID
  })),
  { label: 'Crear nueva tarjeta', value: 0 }
]

export const formatCard = ({ LastDigits, Type }) => `Tarjeta ${getCreditCardTypeName(Type)} finalizada en ${LastDigits}`
