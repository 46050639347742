import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Buffer } from 'buffer'
import { AddressActionCreator } from '../actions/address'
import { AddressModalActionCreator } from '../actions/addressModal'
import { DeleteAddressActionCreator } from '../actions/deleteAddress'
import { DefaultAddressActionCreator } from '../actions/defaultAddress'
import AddressTable from '../components/addressTable'
import queryString from 'query-string'
import { AddressUserState } from '../types/address'

type AdressTableParams = {
  userId: string
}

type ParseParams = {
  name: string
  lastName: string
}

const Container = () => {
  const dispatch = useDispatch()
  const addressActions = bindActionCreators(AddressActionCreator, dispatch)
  const addressModalActions = bindActionCreators(AddressModalActionCreator, dispatch)
  const deleteAddressActionsCreator = bindActionCreators(DeleteAddressActionCreator, dispatch)
  const defaultAddressActionsCreator = bindActionCreators(DefaultAddressActionCreator, dispatch)
  const history = useHistory()
  const urlEncoded = history.location.search

  const { userId } = useParams<AdressTableParams>()
  const { name, lastName } = queryString.parse(
    unescape(Buffer.from(urlEncoded, 'base64').toString('ascii'))
  ) as ParseParams

  if (userId === null) history.goBack()

  const addressUserState = useSelector((state) => state.UserAddresses.address) as AddressUserState
  const { params } = addressUserState

  useEffect(() => {
    addressActions.getAddresses(parseInt(userId), params)
  }, [])

  const props = {
    addressesState: addressUserState,
    addressModalActions,
    deleteAddressActionsCreator,
    defaultAddressActionsCreator,
    userId: parseInt(userId),
    name,
    lastName
  }

  return <AddressTable {...props} />
}

export default Container
