import types from '../types/deletePhoto'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  openModal: (photoID, objectID) => ({
    type: types.OPEN_MODAL,
    payload: { objectID, photoID }
  }),
  closeModal: () => ({ type: types.CLOSE_MODAL }),

  deletePhoto: (objectID, photoID) => (dispatch, getState) => {
    dispatch({ type: types.DELETE_PHOTO_REQUEST })

    return apiPrivate
      .delete(URL_BASE_OBJECTS + '/object/' + objectID + '/photo/' + photoID)
      .then((response) => {
        alert('Foto borrada con éxito')
        dispatch({ type: types.DELETE_PHOTO_SUCCESS, payload: { photoID } })
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.DELETE_PHOTO_FAILURE, dispatch)
      })
  }
}

export default actions
