import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

interface UpdateParams {
  id: string
  multiplier: number
  deposits: number[]
  solutionId: number
}
export function update({ id, multiplier, deposits, solutionId }: UpdateParams): Promise<void> {
  const form = new FormData()
  form.append('multiplier', multiplier.toString())
  form.append('deposits', deposits.toString())
  form.append('solution_id', solutionId.toString())
  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/serviceType/${id}`, form)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
