import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { Origins } from '../../../projectApi/Mercurio/BO/Origins/common'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import EditOrigin from '../container/editOrigin'
import DeleteOrigin from '../container/deleteOrigin'
import { Source } from '../../../projectApi/Mercurio/BO/Config/list'
import { parseDate } from '../../../components/DynamicTable/utils/utils'

export type OriginsTableProps = {
  origins: Origins[]
  sources: Source[]
  loadingOrigins: boolean
  actions: MenuAction<Origins>[]
}

const OriginsTable: React.FC<OriginsTableProps> = ({ origins, loadingOrigins, actions }) => {
  const column: Column<Origins>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'sirenaSource',
      label: 'Origen Sirena'
    },
    {
      key: 'mercurioSource',
      label: 'Canal asociado',
      renderDataCell: ({ mercurioSource }) => mercurioSource.spanishDisplay
    },
    {
      key: 'updatedAt',
      label: 'Fecha de creación',
      renderDataCell: ({ createdAt }) => parseDate(createdAt)
    },
    {
      key: 'createdAt',
      label: 'Fecha de actualización',
      renderDataCell: ({ updatedAt }) => parseDate(updatedAt)
    }
  ]

  return (
    <>
      <DynamicTable
        columns={column}
        rows={origins}
        keyExtractor={(entity) => entity.id}
        loading={loadingOrigins}
        actions={actions}
        customHeightOffset={140}
      />
      <EditOrigin />
      <DeleteOrigin />
    </>
  )
}

export default OriginsTable
