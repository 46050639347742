import React from 'react'
import Component from '../component/debtStatus'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import nextStatusActionCreators from '../actions/nextStatus'

const Container = () => {
  const nextStatusState = useSelector((state) => state.RemovalsValidation.nextStatus)
  const removalsState = useSelector((state) => state.RemovalsValidation.table)

  const dispatch = useDispatch()
  const nextStatusActions = bindActionCreators(nextStatusActionCreators, dispatch)

  const props = {
    nextStatusState,
    nextStatusActions,
    removalsState
  }

  return <Component {...props} />
}

export default Container
