import { Address } from '../../../../projectApi/UserProfile/Admin/Address/common'
import { CleanStates, GetTransactionDataAction } from './EditDepositView'

export const GET_ADDRESSES_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_ADDRESSES_REQUEST'
export const GET_ADDRESSES_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_ADDRESSES_SUCCESS'
export const GET_ADDRESSES_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_ADDRESSES_FAILURE'

export interface GetAddressesRequest {
  type: typeof GET_ADDRESSES_REQUEST
}

export interface GetAddressesSuccess {
  type: typeof GET_ADDRESSES_SUCCESS
  payload: {
    addresses: Address[]
  }
}

export interface GetAddressesFailure {
  type: typeof GET_ADDRESSES_FAILURE
  payload: {
    error: string
  }
}

export type GetAddressesActions = GetAddressesRequest | GetAddressesSuccess | GetAddressesFailure

export const SET_ADDRESS_ID = 'DEPOSIT/EDIT_DEPOSIT/SET_ADDRESS_ID'

export interface SetAddressID {
  type: typeof SET_ADDRESS_ID
  payload: { addressID: number }
}

export const SAVE_ADDRESS_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/SAVE_ADDRESS_REQUEST'
export const SAVE_ADDRESS_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/SAVE_ADDRESS_SUCCESS'
export const SAVE_ADDRESS_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/SAVE_ADDRESS_FAILURE'

export interface SaveAddressRequest {
  type: typeof SAVE_ADDRESS_REQUEST
}

export interface SaveAddressSuccess {
  type: typeof SAVE_ADDRESS_SUCCESS
  payload: {
    address: Address
  }
}

export interface SaveAddressFailure {
  type: typeof SAVE_ADDRESS_FAILURE
  payload: {
    error: string
  }
}

export type SaveAddressActions = SaveAddressRequest | SaveAddressSuccess | SaveAddressFailure

export type AddressesState = {
  addresses: Address[]
  addressID: number
  loadingAddresses: boolean
  error: string
}

export type AddressesActionsTypes =
  | CleanStates
  | GetTransactionDataAction
  | SaveAddressActions
  | GetAddressesActions
  | SetAddressID
