import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import LeadHistoryTable from '../components/LeadHistoryTable'
import { bindActionCreators } from 'redux'
import LeadHistoryActionCreators from '../actions/LeadHistory'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { getSpanishNameByCountry } from '../../../common/operations/flags/utils'

interface ParamTypes {
  leadId: number
}

const LeadHistoryContainer = () => {
  const dispatch = useDispatch()
  const { leadHistory, fetchingLead, processLastLead } = useSelector((state) => state.Leads.history)
  const { getHistoryLead, getLastChangeLead } = bindActionCreators(LeadHistoryActionCreators, dispatch)
  const userCountry = useCountryInfo()
  const history = useHistory()

  const { countries, countrySelected } = useSelector((state) => state.CountrySelector)

  const useParsedParams = (): ParamTypes => {
    const { leadId } = useParams<{ leadId: string }>()
    return { leadId: parseInt(leadId) }
  }
  const { leadId } = useParsedParams()

  const handleReturn = () => {
    history.goBack()
  }

  useEffect(() => {
    getHistoryLead({ leadId, country: getSpanishNameByCountry(userCountry.code || countrySelected.code) })
    getLastChangeLead(leadId)
  }, [])

  const props = {
    leadHistory,
    handleReturn,
    fetchingLead,
    countries,
    processLastLead
  }

  return <LeadHistoryTable {...props} />
}

export default LeadHistoryContainer
