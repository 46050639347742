import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { mapErrorCodeToMessage } from '../../utils'

interface Params {
  tripId: number
  transactionId: number
}

export function removeTx({ tripId, transactionId }: Params): Promise<void> {
  const params = snakecaseKeys({ transactionId })

  return apiPrivate
    .put(`${URL_BASE_TRIPS}/trips/${tripId}/remove-transaction`, null, { params })
    .then(() => {})
    .catch((error) => {
      if (!error.response?.data?.code) throw error
      const errorMessage = mapErrorCodeToMessage({
        map: {
          'TPR-1': 'No se puede quitar la transacción porque el viaje ha finalizado'
        },
        code: error.response?.data?.code
      })

      throw errorMessage
    })
}
