import React, { useEffect, useRef } from 'react'
import styles from './ProductsCatalog.module.scss'
import { Button } from '@/components/Button/Button'
import ObjectImage from '../../../icons/objects'
import CharacterImage from '@/icons/character'
import { CatalogGrid } from './subcomponents/CatalogGrid'
import { ProductCatalogue } from '@/projectApi/CategoryCreation/Catalog/searchAll'
import { Cross } from '@/icons'
import { Tooltip } from 'antd'
import { useLocalization } from '@/packages/localization'

type ProductsCatalogProps = {
  handleGetSuggestions: (keyword: string) => void
  productSuggestions: string[]
  setProductSuggested: (selectedProduct: string) => void
  selectedProduct: string
  handleGetCatalogByProduct: () => void
  products: ProductCatalogue[]
  handleGetProductByDescription: ({ description }: { description: string }) => void
  isLoadingCatalogProducts: boolean
}

export const ProductsCatalog: React.FC<ProductsCatalogProps> = ({
  handleGetSuggestions,
  productSuggestions,
  setProductSuggested,
  handleGetCatalogByProduct,
  selectedProduct,
  products,
  handleGetProductByDescription,
  isLoadingCatalogProducts
}) => {
  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const [isInputValueEmpty, setIsInputValueEmpty] = React.useState(false)
  const { strings } = useLocalization()

  const handleShowSuggestions = (isOpen: boolean) => {
    setShowSuggestions(isOpen)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>
            {strings.ProductCatalog.title.main} <span>{strings.ProductCatalog.title.span}</span>{' '}
          </h2>
          <p className={styles.subtitle}>{strings.ProductCatalog.subtitle}</p>
        </div>

        <div className={styles.inputContainer}>
          <InputAutocomplete
            suggestions={productSuggestions}
            handleGetSuggestions={handleGetSuggestions}
            setProductSuggested={setProductSuggested}
            selectedProduct={selectedProduct}
            setShowSuggestions={handleShowSuggestions}
            showSuggestions={showSuggestions}
            setIsInputValueEmpty={setIsInputValueEmpty}
          />
          <Button
            label={strings.generic.search}
            className={styles.button}
            onClick={() => {
              if (isInputValueEmpty) return
              handleShowSuggestions(false)
              handleGetCatalogByProduct()
            }}
            loading={isLoadingCatalogProducts}
          />
        </div>
      </div>
      {products.length ? (
        <CatalogGrid
          products={products}
          handleGetProductByDescription={handleGetProductByDescription}
          isFetching={isLoadingCatalogProducts}
        />
      ) : (
        <div className={styles.img}>
          <CharacterImage />
          <ObjectImage />
        </div>
      )}
    </>
  )
}

const InputAutocomplete = ({
  suggestions,
  handleGetSuggestions,
  setProductSuggested,
  setShowSuggestions,
  setIsInputValueEmpty,
  showSuggestions
}: {
  suggestions: string[]
  handleGetSuggestions: (keyword: string) => void
  setProductSuggested: (selectedProduct: string) => void
  selectedProduct: string
  setShowSuggestions: (isOpen: boolean) => void
  setIsInputValueEmpty: (value: boolean) => void
  showSuggestions: boolean
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const inputValueRef = useRef<string>('')
  const debounceRef = useRef<NodeJS.Timeout>()

  const { strings } = useLocalization()

  useEffect(() => {
    if (inputRef.current) {
      setIsInputValueEmpty(inputRef.current.value === '')
    } else {
      setIsInputValueEmpty(true)
    }
  }, [])

  const handleChange = () => {
    if (debounceRef.current) clearTimeout(debounceRef.current)
    if (suggestions.length) setShowSuggestions(true)
    if (inputRef.current) {
      setIsInputValueEmpty(inputRef.current.value === '')
      setProductSuggested(inputRef.current.value)
      inputValueRef.current = inputRef.current.value
      debounceRef.current = setTimeout(() => handleGetSuggestions(inputValueRef.current), 200)
    }
  }

  const handleSelectProduct = (product: string) => {
    setProductSuggested(product)
    setShowSuggestions(false)
    if (inputRef.current) inputRef.current.value = product
  }

  return (
    <div className={styles.inputContent}>
      <div className={styles.inputSearch}>
        <input
          type="text"
          placeholder={strings.ProductCatalog.inputAutocomplete.placeholder}
          className={styles.input}
          ref={inputRef}
          onChange={handleChange}
        />
        <Tooltip title={strings.ProductCatalog.inputAutocomplete.tooltip} placement="top">
          <div
            className={`${styles.crossIcon} ${showSuggestions ? styles.active : ''}`}
            onClick={() => setShowSuggestions(false)}>
            <Cross width="11px" height="11px" />
          </div>
        </Tooltip>
      </div>
      {showSuggestions ? (
        <ul>
          {suggestions.map((item) => (
            <li key={item} onClick={() => handleSelectProduct(item)}>
              {item}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
