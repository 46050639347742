import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import {
  CLEAR_STATE,
  GET_REMOVALS_FAILURE,
  GET_REMOVALS_REQUEST,
  GET_REMOVALS_SUCCESS,
  GET_REMOVAL_DETAILS_FAILURE,
  GET_REMOVAL_DETAILS_REQUEST,
  GET_REMOVAL_DETAILS_SUCCESS,
  RemovalsActions,
  RemovalsTableDateRangeKey,
  RemovalsTableSearchKey,
  RemovalsTableState,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  SET_VIEW_BUTTON_ACTIVE
} from '../types/removalsTable'

const initialState: RemovalsTableState = {
  removals: [],
  details: [],
  loading: false,
  loadingObjects: false,
  sendingCSV: false,
  error: '',
  pagination: {
    total: 0,
    page: 1,
    limit: 20
  },
  sort: {
    direction: SortDirection.DESC,
    field: 'id'
  },
  dateRangeFilters: [
    { key: RemovalsTableDateRangeKey.CREATED_AT, startDate: null, endDate: null },
    { key: RemovalsTableDateRangeKey.TRANSPORT_DATETIME, startDate: null, endDate: null }
  ],
  categoryFilter: { status: [], origin: [] },
  activeViewButton: null,
  searchFilters: [
    { key: RemovalsTableSearchKey.ID, text: '' },
    { key: RemovalsTableSearchKey.USER_ID, text: '' },
    { key: RemovalsTableSearchKey.IS_FULL_REMOVAL, text: '' }
  ],
  quantityClientsWithFullRemovals: 0
}

export const RemovalsTableReducer: Reducer<RemovalsTableState, RemovalsActions> = (
  state = initialState,
  action
): RemovalsTableState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case GET_REMOVALS_REQUEST:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          limit: action.payload.pageSize
        },
        sort: action.payload.sort || initialState.sort,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        searchFilters: action.payload.searchFilters || initialState.searchFilters
      }
    case GET_REMOVALS_SUCCESS:
      return {
        ...state,
        removals: action.payload.removals,
        quantityClientsWithFullRemovals: action.payload.quantityClientsWithFullRemovals,
        loading: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_REMOVALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case GET_REMOVAL_DETAILS_REQUEST:
      return {
        ...state,
        loadingObjects: true
      }
    case GET_REMOVAL_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.objects,
        loadingObjects: false
      }
    case GET_REMOVAL_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loadingObjects: false
      }
    case SET_VIEW_BUTTON_ACTIVE:
      return {
        ...state,
        activeViewButton: action.payload.activeViewButton
      }
    case SEND_CSV_REQUEST: {
      return {
        ...state,
        sendingCSV: true
      }
    }
    case SEND_CSV_SUCCESS:
    case SEND_CSV_FAILURE: {
      return {
        ...state,
        sendingCSV: false
      }
    }
    default:
      return state
  }
}
