import { AxiosError } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

type createSatelliteUserParams = {
  email: string
  role: number
}

export function create(params: createSatelliteUserParams): Promise<void> {
  return apiPrivate
    .post(`${URL_BASE_USERS}/satellite-user`, params)
    .then(() => {})
    .catch((error: AxiosError<ErrorMessageResponse>) => {
      throw error
    })
}

type Cause = {
  code: string
  message: string
}

export interface ErrorMessageResponse {
  code: string
  message: string
  error: string
  status: number
  cause: Cause[]
}
