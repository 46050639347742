/* eslint-disable camelcase */
import { Moment } from 'moment'
import { CountryIdCode } from '../../components/CountrySelector/constants/constants'
import { apiPrivate } from '../../api'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { AxiosResponse } from 'axios'
import { convertToMoment, formatDates } from '../utils'
import { URL_BASE_CATEGORIES } from '../../endpoints'

type PriceBandType = 'm3' | 'kg'

type GetPriceBandsParams = {
  countryId: CountryIdCode
  dateFrom?: Moment
  all: boolean
  limit?: number
  offset?: number
  type?: PriceBandType
}

type APIPriceBand = {
  id: number
  country: CountryIdCode
  date_from: string
  type: PriceBandType
  price: number
}

export type PriceBand = {
  id: number
  country: CountryIdCode
  dateFrom: Moment | null
  type: PriceBandType
  price: number
}

type APIResponse = {
  price_bands: APIPriceBand[] | null
  total: number
}

type Response = { priceBands: PriceBand[]; total: number }

export function getPriceBands(params: GetPriceBandsParams): Promise<Response> {
  const snakeParams = snakecaseKeys(
    {
      ...params,
      country: params.countryId,
      ...formatDates({
        dates: {
          dateFrom: params.dateFrom
        },
        format: null,
        utc: true
      })
    },
    { deep: true }
  )

  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/price-bands`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener los precios por escalera')

      const camelResponse = {
        total: response.data.total,
        priceBands: response.data.price_bands
          ? camelcaseKeys(response.data.price_bands, { deep: true }).map((pb) => ({
              ...pb,
              dateFrom: convertToMoment(pb.dateFrom)
            }))
          : []
      }

      return camelResponse
    })
}
