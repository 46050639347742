import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'

type Reason = {
  id: number
  text: string
}

type Params = {
  id: number
  reason: Reason
}

export function cancelAsSG(params: Params) {
  const { id, reason } = params

  return apiPrivate
    .put(`${URL_BASE_SALES}/sales-requests/cancel-as-sg/${id}`, { params: reason })
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
