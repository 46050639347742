import { list } from './list'
import { create } from './create'
import { update } from './update'
import { deleteIt } from './delete'

export const SpecificDates = {
  list,
  create,
  update,
  delete: deleteIt
}
