import { Reducer } from 'redux'
import {
  EditDateServiceActions,
  EditDateServiceState,
  EditServiceDateType,
  ModalDataTypes
} from '../types/editDateService'
import moment from 'moment'

const initialEditDateServiceState: EditDateServiceState = {
  operationId: 0,
  newDateService: moment(),
  currentDateTime: moment(),
  reason: '',
  modalOpen: false,
  loadingSave: false
}

const CancelReducer: Reducer<EditDateServiceState, EditDateServiceActions> = (
  state = initialEditDateServiceState,
  action
): EditDateServiceState => {
  switch (action.type) {
    case ModalDataTypes.SET_MODAL_OPEN: {
      const { isOpen, operationId, currentDateTime } = action.payload

      return isOpen && operationId
        ? {
            ...initialEditDateServiceState,
            modalOpen: isOpen,
            operationId,
            newDateService: moment(currentDateTime),
            currentDateTime: moment(currentDateTime)
          }
        : initialEditDateServiceState
    }
    case ModalDataTypes.SET_REASON:
      return {
        ...state,
        reason: action.payload.reason
      }
    case ModalDataTypes.SET_NEW_DATE_SERVICE:
      return {
        ...state,
        newDateService: action.payload.newDateService
      }
    case EditServiceDateType.EDIT_SERVICE_DATE_REQUEST:
      return {
        ...state,
        loadingSave: true
      }
    case EditServiceDateType.EDIT_SERVICE_DATE_SUCCESS:
      return initialEditDateServiceState
    case EditServiceDateType.EDIT_SERVICE_DATE_FAILURE:
      return {
        ...state,
        loadingSave: false
      }
    default:
      return state
  }
}

export default CancelReducer
