import { Moment } from 'moment'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDates/list'

export type ModalCreateDiscountDateState = {
  editMode: boolean
  modalVisible: boolean
  deleteModalVisible: boolean
  creatingDiscount: boolean
  updatingDiscount: boolean
  deletingDiscount: boolean
  selectedDiscount: Discount | null
  selectedDate: Moment | null
  discountRate: {
    error: string
    value?: number
  }
}

export enum CreateDiscountDateTypes {
  CREATE_DISCOUNT_DATE_REQUEST = 'DISCOUNTS/DISCOUNT_DATES/CREATE_DISCOUNT_DATE_REQUEST',
  CREATE_DISCOUNT_DATE_SUCCESS = 'DISCOUNTS/DISCOUNT_DATES/CREATE_DISCOUNT_DATE_SUCCESS',
  CREATE_DISCOUNT_DATE_FAILURE = 'DISCOUNTS/DISCOUNT_DATES/CREATE_DISCOUNT_DATE_FAILURE'
}

export interface CreateDiscountDateRequest {
  type: typeof CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_REQUEST
}

export interface CreateDiscountDateSuccess {
  type: typeof CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_SUCCESS
}

export interface CreateDiscountDateFailure {
  type: typeof CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_FAILURE
}

type CreateDiscountDateActions = CreateDiscountDateRequest | CreateDiscountDateSuccess | CreateDiscountDateFailure

export enum UpdateDiscountDateTypes {
  UPDATE_DISCOUNT_DATE_REQUEST = 'DISCOUNTS/DISCOUNT_DATES/UPDATE_DISCOUNT_DATE_REQUEST',
  UPDATE_DISCOUNT_DATE_SUCCESS = 'DISCOUNTS/DISCOUNT_DATES/UPDATE_DISCOUNT_DATE_SUCCESS',
  UPDATE_DISCOUNT_DATE_FAILURE = 'DISCOUNTS/DISCOUNT_DATES/UPDATE_DISCOUNT_DATE_FAILURE'
}

export interface UpdateDiscountDateRequest {
  type: typeof UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_REQUEST
}

export interface UpdateDiscountDateSuccess {
  type: typeof UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_SUCCESS
}

export interface UpdateDiscountDateFailure {
  type: typeof UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_FAILURE
}

type UpdateDiscountDateActions = UpdateDiscountDateRequest | UpdateDiscountDateSuccess | UpdateDiscountDateFailure

export enum DeleteDiscountDateTypes {
  DELETE_DISCOUNT_DATE_REQUEST = 'DISCOUNTS/DISCOUNT_DATES/DELETE_DISCOUNT_DATE_REQUEST',
  DELETE_DISCOUNT_DATE_SUCCESS = 'DISCOUNTS/DISCOUNT_DATES/DELETE_DISCOUNT_DATE_SUCCESS',
  DELETE_DISCOUNT_DATE_FAILURE = 'DISCOUNTS/DISCOUNT_DATES/DELETE_DISCOUNT_DATE_FAILURE'
}

export interface DeleteDiscountDateRequest {
  type: typeof DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_REQUEST
}

export interface DeleteDiscountDateSuccess {
  type: typeof DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_SUCCESS
}

export interface DeleteDiscountDateFailure {
  type: typeof DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_FAILURE
}

type DeleteDiscountDateActions = DeleteDiscountDateRequest | DeleteDiscountDateSuccess | DeleteDiscountDateFailure

export enum ModalCreateDiscountDateTypes {
  SET_DISCOUNT_DATE = 'DISCOUNTS/DISCOUNT_DATES/SET_DISCOUNT_DATE',
  SET_DISCOUNT_RATE = 'DISCOUNTS/DISCOUNT_DATES/SET_DISCOUNT_RATE',
  SET_DISCOUNT_RATE_ERROR = 'DISCOUNTS/DISCOUNT_DATES/SET_DISCOUNT_RATE_ERROR',
  SET_MODAL_VISIBILITY = 'DISCOUNTS/DISCOUNT_DATES/SET_MODAL_VISIBILITY',
  SET_DELETE_MODAL_VISIBILITY = 'DISCOUNTS/DISCOUNT_DATES/SET_DELETE_MODAL_VISIBILITY',
  SET_SELECTED_DISCOUNT = 'DISCOUNTS/DISCOUNT_DATES/SET_SELECTED_DISCOUNT'
}

export interface SetDeleteModalVisibility {
  type: typeof ModalCreateDiscountDateTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisible: boolean }
}

export interface SetSelectedDiscount {
  type: typeof ModalCreateDiscountDateTypes.SET_SELECTED_DISCOUNT
  payload: { selectedDiscount: Discount }
}

export interface SetModalVisibility {
  type: typeof ModalCreateDiscountDateTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean; editMode?: boolean }
}

export interface SetDiscountDate {
  type: typeof ModalCreateDiscountDateTypes.SET_DISCOUNT_DATE
  payload: {
    selectedDate: Moment | null
  }
}

export interface SetDiscountRate {
  type: typeof ModalCreateDiscountDateTypes.SET_DISCOUNT_RATE
  payload: {
    discountRate: string
  }
}

export interface SetDiscountRateError {
  type: typeof ModalCreateDiscountDateTypes.SET_DISCOUNT_RATE_ERROR
  payload: { errorRate: string }
}

type ModalCreateDiscountDateActions =
  | SetDiscountDate
  | SetDiscountRate
  | SetDiscountRateError
  | SetModalVisibility
  | SetSelectedDiscount
  | SetDeleteModalVisibility

export type CreateDiscountDatesActions =
  | ModalCreateDiscountDateActions
  | CreateDiscountDateActions
  | UpdateDiscountDateActions
  | DeleteDiscountDateActions
