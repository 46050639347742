import React from 'react'

export type ColInfoProps = {
  label: string
  value: string | number
  bold?: boolean
  blue?: boolean
}

const styles = {
  itemTitle: {
    margin: 0,
    textAlign: 'center' as const,
    color: '#595959',
    fontSize: 12,
    alignItems: 'center' as const
  },
  itemQuantity: {
    fontWeight: 400,
    fontSize: 18,
    marginBottom: 0,
    color: 'black',
    textAlign: 'center' as const
  }
}

const ColInfo = (props: ColInfoProps) => {
  const { label, value, bold, blue } = props
  const itemTitleStyle = {
    ...styles.itemTitle,
    ...(bold && { fontWeight: 'bold' as const }),
    ...(blue && { color: '#5D58F7' })
  }
  const itemQuantityStyle = {
    ...styles.itemQuantity,
    ...(bold && { fontWeight: 'bold' as const }),
    ...(blue && { color: '#5D58F7' })
  }
  return (
    <div style={{ padding: '8px 4px' }}>
      <p style={itemTitleStyle}>{label}</p>
      <p style={itemQuantityStyle}>{value}</p>
    </div>
  )
}

export default ColInfo
