import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { ListParams } from '../Product/listAdmin'
import snakecaseKeys from 'snakecase-keys'

export function sendCSV({ showInSearch, ...params }: ListParams): Promise<void> {
  const snakeParams = snakecaseKeys({ ...params, showHidden: showInSearch })

  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/reports/products`, { params: snakeParams })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
