/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

interface Params {
  userId: number
  addressId: number
}

export function setDefaultAddress({ addressId, userId }: Params): Promise<void> {
  const config = {
    headers: {
      'user-id': userId
    }
  }

  return apiPrivate
    .put(
      URL_BASE_USERS + `/addresses/${addressId}`,
      {
        user_id: userId,
        main: true
      },
      config
    )
    .then(() => {})
    .catch((err) => {
      console.error(err)
      throw err
    })
}
