import React from 'react'
import styles from './CurrentDiscounts.module.scss'
import { Trash } from '../../../../icons'
import { UserDiscount } from '../../../../projectApi/TransactionHandler/UserDiscounts/list'
import { CollapsablePanel } from '../../../../components/CollapsablePanel/CollapsablePanel'
import { RequestData } from '../../types/billingDiscountsModal'
import { formatMoney } from '../../../../utils/formatNumber'
import { useLocalization } from '../../../../packages/localization'

export type CurrentDiscountSectionProps = {
  userDiscounts: RequestData<UserDiscount[]>
  setDeleteModalVisibility: (isVisible: boolean, deleteDiscountId?: number) => void
}

const CurrentDiscountSection = ({ setDeleteModalVisibility, userDiscounts }: CurrentDiscountSectionProps) => {
  const { strings } = useLocalization()
  const fetchingDiscounts = userDiscounts.fetching
  const header = (
    <div className={styles.currentDiscountsHeader}>
      {strings.Billings.DiscountModal.currentDiscounts.title(
        fetchingDiscounts ? '...' : userDiscounts.data.length.toString()
      )}
    </div>
  )

  return (
    <CollapsablePanel header={header}>
      <div className={styles.currentDiscountsContainer}>
        {userDiscounts.fetching ? (
          <>
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          userDiscounts.data.map((discount) => (
            <CurrentDiscount key={discount.id} {...discount} setDeleteModalVisibility={setDeleteModalVisibility} />
          ))
        )}
        {userDiscounts.data.length === 0 && !fetchingDiscounts && <EmptyDiscounts />}
      </div>
    </CollapsablePanel>
  )
}

export default CurrentDiscountSection

type CurrentDiscountProps = UserDiscount & {
  setDeleteModalVisibility: (isVisible: boolean, deleteDiscountId?: number) => void
}

const CurrentDiscount = ({ setDeleteModalVisibility, ...discount }: CurrentDiscountProps) => {
  const { strings } = useLocalization()
  const { userDiscountReason, discountPercent, fixedAmountDiscountInCents, startDateFrom, endDateFrom } = discount
  const isPercentage = Boolean(discountPercent)

  return (
    <article className={styles.container}>
      <div>
        <div>
          <b className={styles.bold}>{userDiscountReason.description}</b> -{' '}
          <b className={styles.bold}>
            {isPercentage ? `${discountPercent}%` : `${formatMoney(fixedAmountDiscountInCents / 100)}`}
          </b>
        </div>
        <div>
          <span>
            {strings.Billings.DiscountModal.currentDiscounts.startDate}:{' '}
            <b>{startDateFrom.utc().format('DD/MM/YYYY')}</b>
          </span>{' '}
          <span>
            {strings.Billings.DiscountModal.currentDiscounts.endDate}: <b>{endDateFrom.utc().format('DD/MM/YYYY')}</b>
          </span>
        </div>
      </div>
      <button className={styles.deleteButton} onClick={() => setDeleteModalVisibility(true, discount.id)}>
        <Trash width="16px" height="16px" stroke="#f5522e" />
      </button>
    </article>
  )
}

const EmptyDiscounts = () => {
  const { strings } = useLocalization()
  return (
    <article className={styles.emptyDiscountsContainer}>
      <span>{strings.Billings.DiscountModal.currentDiscounts.emptyDiscounts}</span>
    </article>
  )
}

const Skeleton = () => {
  return (
    <article className={styles.container}>
      <svg
        role="img"
        width="390"
        height="42"
        aria-labelledby="loading-aria"
        viewBox="0 0 390 42"
        preserveAspectRatio="none">
        <title id="loading-aria">Loading...</title>
        <rect x="0" y="0" width="100%" height="100%" clipPath="url(#clip-path)" style={{ fill: 'url("#fill")' }}></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="1" y="6" rx="5" ry="5" width="79" height="12" />
            <rect x="1" y="26" rx="5" ry="5" width="181" height="14" />
            <rect x="192" y="26" rx="5" ry="5" width="181" height="14" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stopColor="#ffffff" stopOpacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stopColor="#5d58f7" stopOpacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stopColor="#ffffff" stopOpacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </article>
  )
}
