import { DELETE_EVIDENCE, GetComplaintsReasonsTypes, HANDLE_CLEAR } from '../types/common'
import {
  CreateComplaintModalActions,
  CreateComplaintModalState,
  CreateComplaintTypes,
  GetAgentsTypes,
  GetObjectByIdTypes,
  GetOperationByIdTypes,
  GetUsersComplaintTypes,
  SET_OPEN,
  SettersComplaintsTypes
} from '../types/createComplaintModal'
import { mapCoreTypeToComplaintType } from '../utils'

const initialState: CreateComplaintModalState = {
  open: false,
  fetchingUsers: false,
  creatingComplaint: true,
  selectedObjects: [],
  fetchingObject: false,
  object: [],
  users: [],
  agents: [],
  fetchingAgents: true,
  complaint: {
    userId: 0,
    operationId: null,
    complaintType: null,
    reasonId: null,
    description: '',
    photos: [],
    objects: [],
    assignedUserId: null,
    priority: null
  },
  reasons: [],
  fetchingOperation: false
}

function CreateComplaintModalReducer(
  state: CreateComplaintModalState = initialState,
  action: CreateComplaintModalActions
): CreateComplaintModalState {
  switch (action.type) {
    case SET_OPEN:
      return action.payload.open
        ? {
            ...initialState,
            open: action.payload.open,
            complaint: {
              ...state.complaint,
              operationId: action.payload.operationId || null,
              complaintType: action.payload.type || null,
              userId: action.payload.userId || 0
            }
          }
        : initialState

    case SettersComplaintsTypes.SET_OPERATION_ID:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          operationId: action.payload.operationId
        }
      }

    case SettersComplaintsTypes.SET_TYPE:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          complaintType: action.payload.type
        }
      }

    case SettersComplaintsTypes.SET_REASON:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          reasonId: action.payload.reasonId
        }
      }

    case DELETE_EVIDENCE:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          photos: state.complaint.photos.filter((_, index) => index !== action.payload.index)
        }
      }

    case SettersComplaintsTypes.SET_DESCRIPTION:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          description: action.payload.description
        }
      }

    case SettersComplaintsTypes.SET_EVIDENCE:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          photos:
            state.complaint.photos !== null
              ? [...state.complaint.photos, ...action.payload.photo]
              : action.payload.photo
        }
      }
    case SettersComplaintsTypes.SET_AGENT:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          assignedUserId: action.payload.assignedUserId
        }
      }

    case SettersComplaintsTypes.SET_PRIORITY:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          priority: action.payload.priority
        }
      }

    case SettersComplaintsTypes.SET_OBJECTS:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          objects: action.payload.objects
        }
      }
    case SettersComplaintsTypes.SET_USER_ID:
      return {
        ...state,
        complaint: {
          ...state.complaint,
          userId: action.payload.userId
        }
      }

    case CreateComplaintTypes.CREATE_COMPLAINT_REQUEST:
      return {
        ...state,
        creatingComplaint: true
      }
    case CreateComplaintTypes.CREATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        creatingComplaint: false
      }
    case CreateComplaintTypes.CREATE_COMPLAINT_FAILURE:
      return {
        ...state,
        creatingComplaint: false
      }
    case GetUsersComplaintTypes.GET_USERS_REQUEST:
      return {
        ...state,
        fetchingUsers: true
      }
    case GetUsersComplaintTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        fetchingUsers: false,
        users: action.payload.users
      }
    case GetUsersComplaintTypes.GET_USERS_FAILURE:
      return {
        ...state,
        fetchingUsers: false
      }

    case GetObjectByIdTypes.GET_OBJECT_BY_ID_REQUEST:
      return {
        ...state,
        fetchingObject: true
      }
    case GetObjectByIdTypes.GET_OBJECT_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingObject: false,
        object: action.payload.object
      }
    case GetObjectByIdTypes.GET_OBJECT_BY_ID_FAILURE:
      return {
        ...state,
        fetchingObject: false
      }

    case GetAgentsTypes.GET_AGENTS_REQUEST:
      return {
        ...state,
        fetchingAgents: true
      }
    case GetAgentsTypes.GET_AGENTS_SUCCESS:
      return {
        ...state,
        fetchingAgents: false,
        agents: action.payload.agents
      }
    case GetAgentsTypes.GET_AGENTS_FAILURE:
      return {
        ...state,
        fetchingAgents: false
      }

    case GetComplaintsReasonsTypes.GET_REASONS_REQUEST:
      return {
        ...state
      }
    case GetComplaintsReasonsTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload.reasons
      }
    case GetComplaintsReasonsTypes.GET_REASONS_FAILURE:
      return {
        ...state
      }

    case GetOperationByIdTypes.GET_OPERATION_REQUEST:
      return {
        ...state,
        fetchingOperation: true
      }
    case GetOperationByIdTypes.GET_OPERATION_SUCCESS: {
      const complaintTypeKey = mapCoreTypeToComplaintType(action.payload.operation.coreType)
      return {
        ...state,
        fetchingOperation: false,
        complaint: {
          ...state.complaint,
          operationId: action.payload.operation.id,
          complaintType: complaintTypeKey,
          userId: action.payload.operation.userId
        }
      }
    }
    case GetOperationByIdTypes.GET_OPERATION_FAILURE:
      return {
        ...state
      }

    case HANDLE_CLEAR:
      return initialState

    default:
      return state
  }
}

export default CreateComplaintModalReducer
