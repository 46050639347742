import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type DeleteParams = {
  discountId: number
}

export function deleteIt({ discountId }: DeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_TRANSACTIONS}/user-discounts/${discountId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
