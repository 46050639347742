export const OPEN_MODAL = 'USERS/ADDRESS_MODAL/OPEN_MODAL'
export const CLOSE_MODAL = 'USERS/ADDRESS_MODAL/CLOSE_MODAL'
export const SET_EDIT_MODE = 'USERS/ADDRESS_MODAL/SET_EDIT_MODE'
export const SET_USER_ID = 'USERS/ADDRESS_MODAL/SET_USER_ID'

export interface OpenModal {
  type: typeof OPEN_MODAL
  payload: {
    userId: number
    isEdit: boolean
    addressId: number
  }
}

export interface CloseModal {
  type: typeof CLOSE_MODAL
}

export interface SetEditMode {
  type: typeof SET_EDIT_MODE
}

export interface SetUserId {
  type: typeof SET_USER_ID
}

export const CREATE_ADDRESS_DATA_REQUEST = 'USERS/ADDRESS_MODAL/CREATE_ADDRESS_DATA_REQUEST'
export const CREATE_ADDRESS_DATA_SUCCESS = 'USERS/ADDRESS_MODAL/CREATE_ADDRESS_DATA_SUCCESS'
export const CREATE_ADDRESS_DATA_FAILURE = 'USERS/ADDRESS_MODAL/CREATE_ADDRESS_DATA_FAILURE'

export interface CreateAddressDataRequest {
  type: typeof CREATE_ADDRESS_DATA_REQUEST
}

export interface CreateAddressDataSuccess {
  type: typeof CREATE_ADDRESS_DATA_SUCCESS
}

export interface CreateAddressDataFailure {
  type: typeof CREATE_ADDRESS_DATA_FAILURE
  payload: {
    error: string
  }
}

export const GET_ADDRESS_DATA_REQUEST = 'USERS/ADDRESS_MODAL/GET_ADDRESS_DATA_REQUEST'
export const GET_ADDRESS_DATA_SUCCESS = 'USERS/ADDRESS_MODAL/GET_ADDRESS_DATA_SUCCESS'
export const GET_ADDRESS_DATA_FAILURE = 'USERS/ADDRESS_MODAL/GET_ADDRESS_DATA_FAILURE'

export interface GetAddressDataRequest {
  type: typeof GET_ADDRESS_DATA_REQUEST
}

export interface GetAddressDataSuccess {
  type: typeof GET_ADDRESS_DATA_SUCCESS
}

export interface GetAddressDataFailure {
  type: typeof GET_ADDRESS_DATA_FAILURE
  payload: {
    error: string
  }
}

export const UPDATE_ADDRESS_DATA_REQUEST = 'USERS/ADDRESS_MODAL/UPDATE_ADDRESS_DATA_REQUEST'
export const UPDATE_ADDRESS_DATA_SUCCESS = 'USERS/ADDRESS_MODAL/UPDATE_ADDRESS_DATA_SUCCESS'
export const UPDATE_ADDRESS_DATA_FAILURE = 'USERS/ADDRESS_MODAL/UPDATE_ADDRESS_DATA_FAILURE'

export interface UpdateAddressDataRequest {
  type: typeof UPDATE_ADDRESS_DATA_REQUEST
}

export interface UpdateAddressDataSuccess {
  type: typeof UPDATE_ADDRESS_DATA_SUCCESS
}

export interface UpdateAddressDataFailure {
  type: typeof UPDATE_ADDRESS_DATA_FAILURE
  payload: {
    error: string
  }
}

export interface AddressModalState {
  openModal: boolean
  loading: boolean
  isEdit: boolean
  userId: number
  addressId: number
  error: string
}

export type AddressModalActions =
  | UpdateAddressDataRequest
  | OpenModal
  | CloseModal
  | SetEditMode
  | SetUserId
  | CreateAddressDataRequest
  | CreateAddressDataSuccess
  | CreateAddressDataFailure
  | GetAddressDataRequest
  | GetAddressDataSuccess
  | GetAddressDataFailure
  | UpdateAddressDataRequest
  | UpdateAddressDataSuccess
  | UpdateAddressDataFailure
  | UpdateAddressDataRequest
