import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { PriceBand } from '../../../projectApi/CategoryCreation/getPriceBands'
import { formatDate } from '../../../utils/formatDate'
import { PriceBandsSingleDateFilter, PriceBandsSingleDateFilterKey } from '../types/priceBandsTable'
import { getSingleDateFilterProps } from '../../../components/DynamicTable/utils'
import { Moment } from 'moment'
import CreatePriceBandContainer from '../containers/create'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { formatMoney } from '../../../utils/formatNumber'
import { TableActionBar } from '../../../components/TableActionBar'
import { Permissions } from '../../UserLogged/permissions'

export type PriceBandsTableProps = {
  priceBands: PriceBand[]
  pagination: Pagination
  loading: boolean
  singleDateFilters: PriceBandsSingleDateFilter[]
  handleResetFilters: () => void
  handleSingleDatePicker: (key: PriceBandsSingleDateFilterKey, date?: Moment) => void
  noCountrySelected: boolean
  actions: MenuAction<PriceBand>[]
  setCreateModalOpen: (args: boolean) => void
  permissions: Permissions[]
}

export const PriceBandsTable: React.FC<PriceBandsTableProps> = ({
  priceBands,
  pagination,
  loading,
  singleDateFilters,
  handleResetFilters,
  handleSingleDatePicker,
  noCountrySelected,
  actions,
  setCreateModalOpen,
  permissions
}) => {
  const columns: Column<PriceBand>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'dateFrom',
      label: 'Desde',
      renderDataCell: (priceBand: PriceBand) => formatDate(priceBand.dateFrom),
      singleDatePicker: getSingleDateFilterProps('dateFrom', singleDateFilters, handleSingleDatePicker)
    },
    {
      key: 'price',
      label: 'Precio',
      renderDataCell: (priceBand: PriceBand) => formatMoney(priceBand.price)
    },
    {
      key: 'type',
      label: 'Tipo'
    }
  ]

  return (
    <div>
      <CreatePriceBandContainer />
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        {permissions.includes(Permissions.CrearBandaDePrecio) && (
          <TableActionBar.ButtonAction
            iconType="plus"
            onClickButton={() => setCreateModalOpen(true)}
            titleButton="Agregar Franja de precio"
          />
        )}
      </TableActionBar>
      <DynamicTable
        columns={columns}
        loading={loading}
        rows={priceBands}
        keyExtractor={(priceBand) => priceBand.id}
        pagination={pagination}
        customStrings={{
          emptyState: noCountrySelected
            ? 'Selecciona un país para ver sus bandas de precios'
            : 'No se encontraron bandas de precios'
        }}
        actions={actions}
      />
    </div>
  )
}
