import React from 'react'
import { bool, func, number, string } from 'prop-types'
import { Modal } from 'antd'

const PhotosModal = (props) => {
  const { open, error, objectID, photoID, loading, closeModal, deletePhoto } = props

  return (
    <div className="animated fadeIn">
      <Modal
        title="Eliminar foto"
        visible={open}
        onOk={() => deletePhoto(objectID, photoID)}
        onCancel={closeModal}
        okText="Eliminar"
        cancelText="Cancelar"
        cancelButtonProps={{ disabled: loading }}
        confirmLoading={loading}
        closable={!loading}
        maskClosable={!loading}>
        ¿Seguro deseas eliminar esta foto?
        <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
      </Modal>
    </div>
  )
}

PhotosModal.propTypes = {
  open: bool,
  error: string,
  objectID: string,
  photoID: number,
  loading: bool,
  closeModal: func.isRequired,
  deletePhoto: func.isRequired
}
export default PhotosModal
