import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APITransportMode } from './common'

export interface Description {
  quantity: number
  transportMode: APITransportMode
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = CamelCasedPropertiesDeep<Description>

interface GetByIdParams {
  transportModeId: number
}

export function getById({ transportModeId }: GetByIdParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/transportMode/${transportModeId}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener el modo de transporte')
      if (!response.data.description?.transportMode) throw new Error('Respuesta incorrecta el modo de transporte')
      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
