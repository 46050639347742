export const CLEAR_STATE = 'ATTRIBUTES/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export const DELETE_ATTRIBUTE_REQUEST = 'ATTRIBUTES/DELETE_ATTRIBUTE_REQUEST'
export const DELETE_ATTRIBUTE_SUCCESS = 'ATTRIBUTES/DELETE_ATTRIBUTE_SUCCESS'
export const DELETE_ATTRIBUTE_FAILURE = 'ATTRIBUTES/DELETE_ATTRIBUTE_FAILURE'

export const OPEN_DELETE_MODAL = 'ATTRIBUTES/OPEN_DELETE_MODAL'
export const CLOSE_DELETE_MODAL = 'ATTRIBUTES/CLOSE_DELETE_MODAL'

export interface OpenDeleteModal {
  type: typeof OPEN_DELETE_MODAL
  payload: {
    id: number
  }
}

export interface CloseDeleteModal {
  type: typeof CLOSE_DELETE_MODAL
}

export interface DeleteAttributeRequest {
  type: typeof DELETE_ATTRIBUTE_REQUEST
}

export interface DeleteAttributeSuccess {
  type: typeof DELETE_ATTRIBUTE_SUCCESS
}

export interface DeleteAttributeFailure {
  type: typeof DELETE_ATTRIBUTE_FAILURE
  payload: {
    error: string
  }
}

export interface DeleteAttributeState {
  id: number | null
  deleteAttributeModalOpen: boolean
  loading: boolean
  error: string
}

export type AttributesActions =
  | ClearState
  | DeleteAttributeRequest
  | DeleteAttributeSuccess
  | DeleteAttributeFailure
  | OpenDeleteModal
  | CloseDeleteModal
