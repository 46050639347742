import { close } from './close'
import { list } from './list'
import { create } from './create'
import { update } from './update'
import { reasons } from './reasons'
import { metrics } from './metrics'

export const ComplaintsAPI = {
  list,
  create,
  update,
  close,
  reasons,
  metrics
}
