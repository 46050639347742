import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'

type CreateParams = {
  date: string
  discount: number
}

export function create(params: CreateParams) {
  return apiPrivate
    .post(`${URL_BASE_TIMESLOT}/discount/by/specific-day`, params)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
