import { isEmpty } from 'ramda'

export function normalizeString(string: string) {
  const words = string.trim().split(' ')

  return words
    .map((word) => {
      if (isEmpty(word)) return ''
      return word[0].toUpperCase() + word.substring(1).toLowerCase()
    })
    .join(' ')
}

export const isValidName = (name: string) => {
  const string = normalizeString(name)
  const RegExrName = /^[A-ZÁÉÍÓÚ]{1}[a-z-A-Zñáéíóú\s]*$/

  if (RegExrName.test(string)) {
    return { value: string, isValid: true }
  } else {
    return { value: string, isValid: false }
  }
}
