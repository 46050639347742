import * as React from 'react'
import clsx from 'clsx'
import styles from './leadChanges.module.scss'
import { EntityAudit, ItemAudit, Lead, User } from './entities'
import { ResponseLeadHistory } from '../../../projectApi/Mercurio/Prospects/getHistory'
import moment from 'moment'

export type AcceptedKeys = keyof Lead | keyof User | keyof EntityAudit | keyof ItemAudit
export type FormatedDate = JSX.Element | '-' | ''
export type AlignText = 'start' | 'center' | 'end'
type Key = keyof ResponseLeadHistory

export interface DeterminateIfValueChangeProps {
  leadHistory: ResponseLeadHistory[]
  index: number
  keys: Key[]
}

export const determinateIfValueChange = ({ leadHistory, index, keys }: DeterminateIfValueChangeProps) => {
  if (index + 1 <= leadHistory.length && leadHistory[index + 1] !== undefined) {
    let currentValue = leadHistory[index]
    let nextValue = leadHistory[index + 1]
    for (const key of keys) {
      // @ts-ignore
      currentValue = currentValue[key]
      // @ts-ignore
      nextValue = nextValue[key]
    }

    if (moment.isMoment(currentValue) && moment.isMoment(nextValue)) {
      const isDifferentDate = !currentValue.isSame(nextValue)

      return isDifferentDate
    } else return currentValue !== nextValue
  } else {
    return false
  }
}

export const changeSpan = (value: string | number | FormatedDate, alignText: AlignText = 'center') => {
  return <span className={clsx(styles.changeValueCell, styles[alignText])}>{value}</span>
}
