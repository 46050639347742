import types from '../types/NewEditDiscount'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { keysToSnake } from '../../../utils/camelCaseToSnake'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import moment from '../../../moment'

export default {
  createDiscount(discount) {
    const discountSnake = keysToSnake(discount)
    discountSnake.start_date = discount.startDate.format()
    discountSnake.end_date = discount.endDate.format()

    return (dispatch) => {
      dispatch({ type: types.CREATE_DISCOUNT_REQUEST })
      return apiPrivate
        .post(URL_BASE_TRANSACTIONS + '/discounts', discountSnake)
        .then((_) => {
          dispatch({ type: types.CREATE_DISCOUNT_SUCCESS })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.CREATE_DISCOUNT_FAILURE, dispatch)
        })
    }
  },

  getDiscount(id) {
    return (dispatch) => {
      dispatch({ type: types.GET_DISCOUNT_REQUEST })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + `/discounts/${id}`)
        .then((response) => {
          const formattedDiscount = {
            ...keysToCamel(response.data),
            startDate: moment(response.data.start_date),
            endDate: moment(response.data.end_date)
          }
          dispatch({
            type: types.GET_DISCOUNT_SUCCESS,
            payload: formattedDiscount
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.GET_DISCOUNT_FAILURE, dispatch)
        })
    }
  },

  updateDiscount(discount) {
    const discountSnake = keysToSnake(discount)
    discountSnake.start_date = discount.startDate.format()
    discountSnake.end_date = discount.endDate.format()
    return (dispatch) => {
      dispatch({ type: types.UPDATE_DISCOUNT_REQUEST })
      return apiPrivate
        .put(URL_BASE_TRANSACTIONS + `/discounts/${discount.id}`, discountSnake)
        .then((_) => {
          dispatch({ type: types.UPDATE_DISCOUNT_SUCCESS })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_DISCOUNT_FAILURE, dispatch)
        })
    }
  },

  setCode(code) {
    return { type: types.SET_CODE, payload: { code } }
  },
  setId(id) {
    return { type: types.SET_ID, payload: { id } }
  },
  setDescription(description) {
    return { type: types.SET_DESCRIPTION, payload: { description } }
  },
  setPublicDescription(publicDescription) {
    return {
      type: types.SET_PUBLIC_DESCRIPTION,
      payload: { publicDescription }
    }
  },
  setTransportDiscountPercent(transportDiscountPercent) {
    return {
      type: types.SET_TRANSPORT_DISCOUNT_PERCENT,
      payload: { transportDiscountPercent }
    }
  },
  setDepositDiscountPercent(depositDiscountPercent) {
    return {
      type: types.SET_DEPOSIT_DISCOUNT_PERCENT,
      payload: { depositDiscountPercent }
    }
  },
  setDepositDiscountMonths(depositDiscountMonths) {
    return {
      type: types.SET_DEPOSIT_DISCOUNT_MONTHS,
      payload: { depositDiscountMonths }
    }
  },
  setStartDate(startDate) {
    return { type: types.SET_START_DATE, payload: { startDate } }
  },
  setEndDate(endDate) {
    return { type: types.SET_END_DATE, payload: { endDate } }
  },
  openModal(id) {
    return { type: types.SET_OPEN_MODAL, payload: { id } }
  },
  closeModal() {
    return { type: types.SET_CLOSE_MODAL }
  }
}
