import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'
import { downloadFile } from '@/utils/downloadFile'

type ExportPaymentsParams = {
  periodFrom?: Moment | null
  countryCode: string
}
export function exportPayments({ periodFrom, countryCode }: ExportPaymentsParams): Promise<void> {
  const dateFormatted = periodFrom?.format('YYYY-MM-DD')

  const params = snakecaseKeys({
    countryCode,
    periodFrom: dateFormatted
  })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/cbu-payments/generate-txt`, {
      params,
      responseType: 'arraybuffer'
    })
    .then((response) => {
      if (response.data && response.data.byteLength > 0) {
        const blob = new Blob([response.data], { type: 'text/plain' })
        downloadFile(blob, `${dateFormatted}_pagos_exportados.txt`)
      } else {
        throw new Error('Respuesta vacía desde el servidor')
      }
    })
    .catch((error) => {
      throw error
    })
}
