import React from 'react'
import { Modal } from 'antd'
import styles from './DeleteDeposit.module.scss'
import { Deposit } from '../../../../projectApi/TransactionHandler/Deposits/common'

type DeleteDepositModalProps = {
  selectedDeposit?: Deposit
  deletingDeposit: boolean
  disabledDeleteButton: boolean
  deleteModalVisible: boolean
  setDeleteModalVisibility: (modalVisible: boolean) => void
  handleDeleteDeposit: () => void
}

const DeleteDepositModal = ({
  selectedDeposit,
  deletingDeposit,
  disabledDeleteButton,
  deleteModalVisible,
  setDeleteModalVisibility,
  handleDeleteDeposit
}: DeleteDepositModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={() => setDeleteModalVisibility(false)}
      title={'Eliminar depósito'}
      confirmLoading={deletingDeposit}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={handleDeleteDeposit}
      okButtonProps={{ disabled: disabledDeleteButton }}
      destroyOnClose>
      <InfoCard id={selectedDeposit?.id} name={selectedDeposit?.name} address={selectedDeposit?.address} />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar este depósito?</h4>
    </Modal>
  )
}

export default DeleteDepositModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; name?: string; address?: string }> = ({ id, name, address }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos del depósito:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
      <InfoItem data={address} label={'Dirección'} />
    </div>
  </header>
)
