import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { apiPrivate } from '../../../api'

export type TripsMetricsKeys =
  | 'total'
  | 'quantity_programmed_trips'
  | 'quantity_asigned_trips'
  | 'quantity_in_progress_trips'
  | 'quantity_finished_trips'
  | 'quantity_cancelled_trips'

interface APIResponse {
  total: number
  quantity_programmed_trips: number
  quantity_asigned_trips: number
  quantity_in_progress_trips: number
  quantity_finished_trips: number
  quantity_cancelled_trips: number
}

type Response = CamelCasedPropertiesDeep<APIResponse>
export type QuantityTripsMetrics = CamelCasedPropertiesDeep<APIResponse>

export interface MetricsParams {
  dateFrom?: Moment
  dateTo?: Moment
}

export function metrics({ dateFrom, dateTo }: MetricsParams): Promise<Response> {
  const params = {
    date_from: dateFrom?.format('YYYY-MM-DD'),
    date_to: dateTo?.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRIPS}/trips/metrics`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
