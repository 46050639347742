import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'

export interface NoTransportChargeReasonAPI {
  id: number
  reason: string
  display_order: number
}

interface Params {
  sorts?: string
  orders?: string
}

interface APIResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  results: NoTransportChargeReasonAPI[]
}

export type NoTransportChargeReason = CamelCasedPropertiesDeep<NoTransportChargeReasonAPI>

type Response = {
  reasons: NoTransportChargeReason[]
}

export function list(params: Params): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/no-transport-charge-reasons/search`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelReasons = camelcaseKeys(response.data.results, { deep: true })
      return {
        reasons: camelReasons
      }
    })
    .catch((err) => {
      throw err
    })
}
