import { list } from './list'
import { update } from './update'
import details from './details'
import { getById } from './getById'
import { reasons } from './reasons'
import { admin } from './admin'
import { metrics as validationMetrics } from './validationMetrics'

export const RemovalsAPI = {
  list,
  details,
  update,
  getById,
  reasons,
  admin,
  validationMetrics
}
