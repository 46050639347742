import { isEmpty, isNil } from 'ramda'

export function areRequiredFieldsFulfilled(fields) {
  let fieldsFulfilled = true
  fields.forEach((field) => {
    if (isEmpty(field.value) || isNil(field.value)) {
      fieldsFulfilled = false
      field.errorFn('Este campo es requerido')
    }
  })
  return fieldsFulfilled
}

export const isCuitValid = (cuit) => {
  const regexCuit = /^(20|23|27|30|33)([0-9]{9})$/g
  return regexCuit.test(cuit)
}

export const validateIdentificationCode = (identificationType, identificationCode, minLength) => {
  if (identificationCode.length < minLength) return `El documento debe tener un mínimo de ${minLength} números`
  if (identificationType === 'CUIT' && !isCuitValid(identificationCode)) {
    return 'El CUIT es incorrecto. Deben ser 11 números sin guiones el cual empiece por 20, 23, 27, 30 o 33'
  }
  return ''
}
