import types from './QRModalTypes'

const initialRoot = {
  open: false,
  code: ''
}

function QRModalReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.OPEN_QRMODAL:
      return {
        ...state,
        open: true,
        code: action.payload.code
      }
    case types.CLOSE_QRMODAL:
      return {
        ...state,
        open: false
      }
    default:
      return state
  }
}

export default QRModalReducer
