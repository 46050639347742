import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CancelSubscriptionModalActionCreator from '../actionCreators/cancelSubscriptionModal'
import { CancelSubscriptionModal, CancelSubscriptionModalProps } from '../components/cancelSubscriptionModal'

const CancelSubscriptionModalContainer = () => {
  const dispatch = useDispatch()

  const { cancelSubscription, openCancelSubscriptionModal } = bindActionCreators(
    CancelSubscriptionModalActionCreator,
    dispatch
  )

  const cancelSubscriptionModalState = useSelector((state) => state.BillingV2.cancelSubscriptionModal)

  const { billingId } = cancelSubscriptionModalState

  const handleSend = () => {
    if (billingId) {
      cancelSubscription(billingId)
    }
  }

  const props: CancelSubscriptionModalProps = {
    cancelSubscriptionModalState,
    handleSend,
    onCancel: () => openCancelSubscriptionModal({ modalOpen: false })
  }

  return <CancelSubscriptionModal {...props} />
}

export default CancelSubscriptionModalContainer
