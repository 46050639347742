import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import {
  GET_REMOVAL_HISTORY_FAILURE,
  GET_REMOVAL_HISTORY_REQUEST,
  GET_REMOVAL_HISTORY_SUCCESS,
  GetRemovalHistoryFailure,
  GetRemovalHistoryRequest,
  GetRemovalHistorySuccess
} from '../types/removalHistory'

const RemovalHistoryActionCreators = {
  getAudits:
    ({ removalId }: { removalId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalHistoryRequest = {
        type: GET_REMOVAL_HISTORY_REQUEST
      }

      dispatch(request)

      try {
        const { audits } = await API.TransactionHandler.Operation.audit({ operationId: removalId })

        const success: GetRemovalHistorySuccess = {
          type: GET_REMOVAL_HISTORY_SUCCESS,
          payload: {
            audits
          }
        }
        dispatch(success)
      } catch (error) {
        const failure: GetRemovalHistoryFailure = {
          type: GET_REMOVAL_HISTORY_FAILURE
        }

        dispatch(failure)
      }
    }
}

export default RemovalHistoryActionCreators
