import React from 'react'
import { Modal } from 'antd'
import { DiscountType, ReasonOption, RequestData, UserPersonalInfo } from '../../types/billingDiscountsModal'
import { UserDiscount } from '../../../../projectApi/TransactionHandler/UserDiscounts/list'
import styles from './BillingDiscountModal.module.scss'
import { DiscountReason } from '../../../../projectApi/TransactionHandler/UserDiscounts/reasons'
import { Moment } from 'moment'
import CurrentDiscountSection from './CurrentDiscounts'
import CreateNewDiscountSection, { CreateNewDiscountSectionProps } from './CreateNewDiscount'
import UserInfoHeader from './UserInfo'
import { useLocalization } from '../../../../packages/localization'

export type BillingDiscountModalProps = {
  newDiscountType: DiscountType
  newDiscountValue?: string
  selectedReason?: ReasonOption
  selectedStartDate?: Moment
  selectedEndDate?: Moment
  userDiscounts: RequestData<UserDiscount[]>
  userPersonalInfo: RequestData<Partial<UserPersonalInfo>>
  discountReasons: RequestData<DiscountReason[]>
  modalVisibility: boolean
  creatingNewDiscount: boolean
  setModalVisibility: (isVisible: boolean) => void
  setDeleteModalVisibility: (isVisible: boolean, deleteDiscountId?: number) => void
  setNewDiscountType: (newDiscountType: DiscountType) => void
  setNewDiscountValue: (newDiscountValue?: string) => void
  setDiscountReason: (selectedReason: ReasonOption) => void
  setDiscountDate: ({
    selectedStartDate,
    selectedEndDate
  }: {
    selectedStartDate?: Moment
    selectedEndDate?: Moment
  }) => void
  handleCreateDiscount: () => void
}

const BillingDiscountModal = ({
  newDiscountType,
  newDiscountValue,
  selectedReason,
  selectedStartDate,
  selectedEndDate,
  modalVisibility,
  creatingNewDiscount,
  setModalVisibility,
  setDeleteModalVisibility,
  setNewDiscountType,
  setNewDiscountValue,
  setDiscountReason,
  setDiscountDate,
  userDiscounts,
  userPersonalInfo,
  discountReasons,
  handleCreateDiscount
}: BillingDiscountModalProps) => {
  const { strings } = useLocalization()

  const disabledOkButton =
    !selectedStartDate ||
    !selectedEndDate ||
    !newDiscountValue ||
    !selectedReason ||
    creatingNewDiscount ||
    parseFloat(newDiscountValue.replace(',', '.')) === 0

  const createNewDiscountProps: CreateNewDiscountSectionProps = {
    newDiscountValue,
    newDiscountType,
    selectedReason,
    selectedStartDate,
    selectedEndDate,
    discountReasons,
    setDiscountReason,
    setDiscountDate,
    setNewDiscountType,
    setNewDiscountValue
  }

  return (
    <Modal
      title={strings.Billings.DiscountModal.title}
      className={styles.modalBody}
      visible={modalVisibility}
      okText={strings.Billings.DiscountModal.okButton}
      okButtonProps={{ disabled: disabledOkButton }}
      onOk={handleCreateDiscount}
      cancelText={strings.Billings.DiscountModal.cancelButton}
      onCancel={() => setModalVisibility(false)}>
      <div className={styles.modalContent}>
        <UserInfoHeader {...userPersonalInfo} />
        <CurrentDiscountSection userDiscounts={userDiscounts} setDeleteModalVisibility={setDeleteModalVisibility} />
        <CreateNewDiscountSection {...createNewDiscountProps} />
      </div>
    </Modal>
  )
}

export default BillingDiscountModal
