import React, { useState } from 'react'
import { Col, Input, Row } from 'antd'
import { isEmpty } from 'ramda'
import { parsePostalCode } from '../../common/operations/utils'
import { PlacesAutocomplete } from '../PlacesAutocomplete/PlacesAutocomplete'
import { GeocodeResult } from 'use-places-autocomplete'
import actions from './actions'
import { Address } from './types'
import { AddressContainerProps } from './container'
import { CountryIdCode } from '../CountrySelector/constants/constants'
import { findAddressComponent } from './utils'

const styles = {
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red !important'
  },
  select: {
    width: '100%'
  }
}

type AddressProps = AddressContainerProps & {
  addressActions: typeof actions
  address: Address
  error: string
}

const AddressComponent = (props: AddressProps) => {
  const {
    address,
    onAddressChange,
    disabled,
    error,
    country: countrySelect = CountryIdCode.ARGENTINA,
    addressActions
  } = props

  const { setAddress, setErrorAddress, cleanAddress } = addressActions
  const { street, number, postalCode, floor, apartment, province, city, country, addressGoogle } = address

  const [suggestError, setSuggestError] = useState(true)

  const handleSuggest = (suggest: GeocodeResult) => {
    const addressGoogle = suggest.formatted_address
    const street = findAddressComponent(suggest.address_components, 'route')
    const number = findAddressComponent(suggest.address_components, 'street_number')

    const postalCode = findAddressComponent(suggest.address_components, 'postal_code')
    const formattedPostalCode = postalCode ? parsePostalCode(postalCode, countrySelect) : ''

    const city = findAddressComponent(suggest.address_components, 'administrative_area_level_2')
    const province = findAddressComponent(suggest.address_components, 'administrative_area_level_1')
    const country = findAddressComponent(suggest.address_components, 'country', 'long_name')
    setErrorAddress(!isEmpty(street) && isEmpty(number) ? 'Ingresar el número de calle en la dirección' : '')
    const formattedAddress = {
      addressGoogle,
      street,
      number,
      postalCode: formattedPostalCode,
      city,
      province,
      country,
      floor,
      apartment
    }

    setAddress(formattedAddress)

    setSuggestError(Boolean(street && number && postalCode && city && province && country))

    if (typeof onAddressChange === 'function') onAddressChange(suggest, formattedAddress)
  }

  const onRemove = () => cleanAddress()

  return (
    <Row gutter={[16, 16]}>
      <Col sm={18} xs={24}>
        <p style={styles.inputTitle}>Dirección*</p>
        <PlacesAutocomplete
          disabled={disabled}
          handleSuggest={handleSuggest}
          onRemove={onRemove}
          currentCountry={countrySelect}
          initialValueAutocomplete={addressGoogle}
        />
        {error && <p style={styles.errorFieldLabel}>{error}</p>}
      </Col>
      <Col sm={3} xs={24}>
        <p style={styles.inputTitle}>Piso</p>
        <Input
          placeholder="Piso"
          className="disabledFields"
          autoComplete="nope"
          value={floor}
          onChange={(e) => setAddress({ floor: e.target.value })}
          disabled={disabled}
        />
      </Col>
      <Col sm={3} xs={24}>
        <p style={styles.inputTitle}>Dpto.</p>
        <Input
          placeholder="Dpto."
          className="disabledFields"
          autoComplete="nope"
          value={apartment}
          onChange={(e) => setAddress({ apartment: e.target.value })}
          disabled={disabled}
        />
      </Col>
      <Col sm={12} xs={24}>
        <p style={styles.inputTitle}>Calle</p>
        <Input
          placeholder="Calle*"
          value={street}
          onChange={(e) => setAddress({ street: e.target.value })}
          disabled={suggestError}
          className="disabledFields"
        />
      </Col>
      <Col sm={6} xs={24}>
        <p style={styles.inputTitle}>Número</p>
        <Input
          placeholder="Número*"
          value={number}
          onChange={(e) => setAddress({ number: e.target.value })}
          disabled={suggestError}
          className="disabledFields"
        />
      </Col>
      <Col sm={6} xs={24}>
        <p style={styles.inputTitle}>Código Postal</p>
        <Input
          placeholder="Código Postal*"
          autoComplete="nope"
          // @ts-ignore TODO review postalCode?.Code case
          value={postalCode?.Code || postalCode}
          onChange={(e) => setAddress({ postalCode: e.target.value })}
          disabled={suggestError}
        />
      </Col>
      <Col sm={8} xs={24}>
        <p style={styles.inputTitle}>Ciudad</p>
        <Input
          placeholder="Ciudad"
          value={city}
          onChange={(e) => setAddress({ city: e.target.value })}
          disabled={suggestError}
          className="disabledFields"
        />
      </Col>
      <Col sm={8} xs={24}>
        <p style={styles.inputTitle}>Provincia</p>
        <Input
          placeholder="Provincia"
          value={province}
          onChange={(e) => setAddress({ province: e.target.value })}
          disabled={suggestError}
          className="disabledFields"
        />
      </Col>
      <Col sm={8} xs={24}>
        <p style={styles.inputTitle}>País</p>
        <Input
          placeholder="País"
          value={country}
          onChange={(e) => setAddress({ country: e.target.value })}
          disabled={suggestError}
          className="disabledFields"
        />
      </Col>
    </Row>
  )
}

export default AddressComponent
