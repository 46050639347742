import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GetRemovalReasons,
  GetRemovalReasonsRequest,
  GetRemovalReasonsSuccess,
  ModalReasonsTypes,
  SetAnotherReason,
  SetModalOpen,
  SetReasonSelected
} from '../types/ModalReasons'

const ModalReasonsActionCreators = {
  openModal: ({ modalOpen }: { modalOpen: boolean }): SetModalOpen => ({
    type: ModalReasonsTypes.SET_MODAL_OPEN,
    payload: { modalOpen }
  }),
  getRemovalReasons: (): AppThunk => async (dispatch) => {
    const request: GetRemovalReasonsRequest = {
      type: GetRemovalReasons.GET_REMOVAL_REASONS_REQUEST
    }

    dispatch(request)
    try {
      const { removalsReasons } = await API.TransactionHandler.Operation.Removals.reasons()

      const success: GetRemovalReasonsSuccess = {
        type: GetRemovalReasons.GET_REMOVAL_REASONS_SUCCESS,
        payload: { reasonsList: removalsReasons }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GetRemovalReasons.GET_REMOVAL_REASONS_FAILURE, dispatch)
    }
  },
  setReasonSelected: ({ reasonSelected }: { reasonSelected: string | number }): SetReasonSelected => ({
    type: ModalReasonsTypes.SET_REASON_SELECTED,
    payload: { reasonSelected }
  }),
  setAnotherReason: ({ anotherReason }: { anotherReason: string }): SetAnotherReason => ({
    type: ModalReasonsTypes.SET_ANOTHER_REASON,
    payload: { anotherReason }
  })
}

export default ModalReasonsActionCreators
