import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_ADDRESSES_DATA_FAILURE,
  GET_ADDRESSES_DATA_REQUEST,
  GET_ADDRESSES_DATA_SUCCESS,
  GetAddressesDataRequest,
  GetAddressesDataSuccess,
  Pagination,
  Params,
  SET_PAGINATION,
  SET_SORTER,
  SetPagination,
  SetSorter,
  Sorter
} from '../types/address'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'

export const AddressActionCreator = {
  getAddresses:
    (userId: number, params: Params): AppThunk =>
    async (dispatch) => {
      const request: GetAddressesDataRequest = { type: GET_ADDRESSES_DATA_REQUEST }
      dispatch(request)

      try {
        const { addresses, quantity } = await API.AccountManager.Addresses.search({ userId, params })
        const success: GetAddressesDataSuccess = {
          type: GET_ADDRESSES_DATA_SUCCESS,
          payload: { addresses, quantity, params }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_ADDRESSES_DATA_FAILURE, dispatch)
      }
    },
  setPagination: (pagination: Pagination): SetPagination => ({
    type: SET_PAGINATION,
    payload: { pagination }
  }),
  setSorter: (sorter: Sorter): SetSorter => ({ type: SET_SORTER, payload: { sorter } })
}
