import moment, { Moment } from 'moment'
import { Periods } from './types/userExclusionModals'

export const formatDate = (date: string) => moment(date).utc().format('DD/MM/YY HH:mm')

export const getPeriods = ({
  periodFrom,
  periodTo,
  specificPeriodFrom,
  specificPeriodTo,
  periodOption
}: {
  periodFrom: Moment
  periodTo: Moment
  specificPeriodFrom: Moment
  specificPeriodTo: Moment
  periodOption: Periods
}): { periodFrom: Moment; periodTo?: Moment; withDays?: boolean } => {
  switch (periodOption) {
    case Periods.THIS_PERIOD:
      return {
        periodFrom,
        periodTo: periodFrom
      }
    case Periods.FOREVER:
      return {
        periodFrom
      }
    case Periods.SPECIFIC_DATES:
      return {
        periodFrom: specificPeriodFrom,
        periodTo: specificPeriodTo,
        withDays: true
      }
    case Periods.SEVERAL_PERIODS:
    default:
      return {
        periodFrom,
        periodTo
      }
  }
}
