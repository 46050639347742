import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { Moment } from 'moment'

export type ListParams = {
  billingId?: number
  userId?: number
  periodFrom?: Moment | null
  periodTo?: Moment | null
}

export function update(params: ListParams): Promise<void> {
  const { userId, periodFrom, periodTo, billingId } = params

  const snakeParams = snakecaseKeys({
    userId,
    billingId,
    periodFrom: periodFrom?.format('YYYY-MM-DD'),
    periodTo: periodTo?.format('YYYY-MM-DD')
  })
  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/billing-data`, {}, { params: snakeParams })
    .then((response: AxiosResponse<void>) => {})
    .catch((err) => {
      throw err
    })
}
