import React from 'react'
import StatusCard from '../../../components/StatusCard/StatusCard'
import { StatusCardContainer } from '../../../components/StatusCard/subComponents/StatusCardContainer'
import { UsersMetrics } from '../types/usersMetrics'
import { formatNumber } from '../../../utils/formatNumber'

export type ObjectMetricsProps = {
  metrics: UsersMetrics
  fetchingMetrics: boolean
}

const TooltipContent = ({ firstText, secondText }: { firstText: string; secondText: string }) => {
  return (
    <div style={{ flexDirection: 'column', display: 'flex' }}>
      <span>{firstText}</span>
      <span>{secondText}</span>
    </div>
  )
}

const MetricsUsers: React.FC<ObjectMetricsProps> = ({ metrics, fetchingMetrics }) => {
  if (metrics !== null) {
    return (
      <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={3}>
        <StatusCard
          type={'info'}
          titleCard={'Usuarios'}
          total={formatNumber(metrics.quantityUsers, 0, true)}
          negative={metrics.notActiveUsers}
          positive={metrics.activeUsers}
          description={<TooltipContent firstText="+ Usuarios activos" secondText="- Usuarios inactivos" />}
        />
        <StatusCard
          type={'info'}
          titleCard={'Terminos y condiciones'}
          total={formatNumber(metrics.quantityAcceptedTerms + metrics.quantityNotAcceptedTerms, 0, true)}
          positive={metrics.quantityAcceptedTerms}
          negative={metrics.quantityNotAcceptedTerms}
          description={<TooltipContent firstText="+ TyC aceptados" secondText="- TyC no aceptados" />}
        />
      </StatusCardContainer>
    )
  } else {
    return null
  }
}

export default MetricsUsers
