import {
  ColumnsSearchFilters,
  Location,
  LocationFilter,
  LocationInfo,
  LocationSearchFilter,
  LocationSorter,
  LocationsTableActions,
  LocationsTableDateRangeFilter,
  LocationsTableDateRangeKey,
  LocationsTableTypes,
  Order,
  Params
} from '../types/locationsTable'
import { PaginationConfig } from 'antd/es/table'
import { SortOrder } from 'antd/lib/table'
import moment from 'moment'

const LIMIT = 10

export const nextMonth = moment().add(1, 'month')

export type LocationsTableState = {
  locationInfo: LocationInfo
  locations: Location[]
  loadingUpdateLocation: boolean
  loadingLocations: boolean
  loadingLocationInfo: boolean
  params: Params
  sorter: LocationSorter
  filter: LocationFilter
  searchFilters: LocationSearchFilter[]
  pagination: PaginationConfig
  quantity: number
  error: string
  errorLocationInfo: string
  errorUpdateLocation: string
  dateRangeFilters: LocationsTableDateRangeFilter[]
}

const initialRoot = {
  locationInfo: {
    volumeInCm: 0,
    maxVolumeInCm: 0,
    weightInGr: 0,
    maxWeightInGr: 0,
    quantityObjects: 0,
    quantityLocations: 0,
    occupancyRate: 0,
    loadRate: 0,
    totalObjects: 0,
    totalM3: 0
  },
  locations: [],
  loadingUpdateLocation: false,
  loadingLocations: false,
  loadingLocationInfo: false,
  params: {
    Limit: LIMIT,
    Offset: 0,
    column: 'id',
    order: Order.DESC,
    exactLocationCode: true,
    show: 'ALL'
  },
  sorter: {
    order: 'descend' as SortOrder,
    field: 'ID',
    columnKey: 'ID'
  },
  filter: {
    active: [],
    type: []
  },
  searchFilters: [
    {
      key: ColumnsSearchFilters.ID,
      text: ''
    },
    {
      key: ColumnsSearchFilters.DEPOSITID,
      text: ''
    },
    {
      key: ColumnsSearchFilters.CODE,
      text: ''
    },
    {
      key: ColumnsSearchFilters.DESCRIPTION,
      text: ''
    },
    {
      key: ColumnsSearchFilters.DEPOSIT_NAME,
      text: ''
    }
  ],
  pagination: {
    current: 1,
    total: 0
  },
  quantity: 0,
  error: '',
  errorLocationInfo: '',
  errorUpdateLocation: '',
  dateRangeFilters: [
    {
      key: LocationsTableDateRangeKey.PERIOD,
      startDate: nextMonth.startOf('month').clone(),
      endDate: nextMonth.endOf('month').clone()
    }
  ]
}

function locationsTableReducer(
  state: LocationsTableState = initialRoot,
  action: LocationsTableActions
): LocationsTableState {
  switch (action.type) {
    case LocationsTableTypes.CLEAN_FILTERS: {
      return initialRoot
    }
    case LocationsTableTypes.FETCH_LOCATIONS_REQUEST:
      return {
        ...state,
        locations: [],
        loadingLocations: true,
        error: '',
        dateRangeFilters: initialRoot.dateRangeFilters
      }
    case LocationsTableTypes.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload.locations,
        loadingLocations: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case LocationsTableTypes.FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        loadingLocations: false,
        error: action.payload.error
      }
    case LocationsTableTypes.FETCH_LOCATIONS_INFO_REQUEST:
      return {
        ...state,
        loadingLocationInfo: true,
        error: ''
      }
    case LocationsTableTypes.FETCH_LOCATIONS_INFO_SUCCESS:
      return {
        ...state,
        locationInfo: action.payload.locationInfo,
        loadingLocationInfo: false
      }
    case LocationsTableTypes.FETCH_LOCATIONS_INFO_FAILURE:
      return {
        ...state,
        loadingLocationInfo: false,
        errorLocationInfo: action.payload.error
      }
    case LocationsTableTypes.UPDATE_LOCATIONS_REQUEST:
      return {
        ...state,
        loadingUpdateLocation: true,
        errorUpdateLocation: ''
      }
    case LocationsTableTypes.UPDATE_LOCATIONS_SUCCESS:
      return {
        ...state,
        loadingUpdateLocation: false
      }
    case LocationsTableTypes.UPDATE_LOCATIONS_FAILURE:
      return {
        ...state,
        loadingUpdateLocation: false,
        errorUpdateLocation: action.payload.error
      }
    case LocationsTableTypes.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case LocationsTableTypes.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case LocationsTableTypes.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case LocationsTableTypes.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    default:
      return state
  }
}

export default locationsTableReducer
