import React, { useEffect, useState } from 'react'
import { isEmpty, pathOr } from 'ramda'
import { Button, DatePicker, Icon, Input } from 'antd'
import locale from 'antd/lib/date-picker/locale/es_ES'
import moment from 'moment'
import PropTypes from 'prop-types'

const { RangePicker } = DatePicker

const styles = {
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 8
  },
  rangePickerButtonContainer: {
    display: 'flex',
    marginTop: 8
  }
}

const DEFAULT_RANGE_VALUE = [moment().subtract(1, 'month'), moment()]

let searchInput
let inputValue

export const getColumnSearchProps = (dataIndex, searchLabel, handleSearch, searchFilters, type = 'string') => {
  const resetInput = () => {
    switch (type) {
      case 'date':
        searchInput.picker.state.value = null
        break
      default: {
        inputValue = ''
        searchInput.state.value = ''
      }
    }
  }

  const handleReset = (confirm) => {
    resetInput()
    handleSearch(dataIndex, '', confirm)
  }

  const getFiltered = (key, searchFilters) =>
    !isEmpty(
      pathOr(
        '',
        ['text'],
        searchFilters.find((searchFilter) => searchFilter.key === key)
      )
    )

  return {
    filterDropdown: ({ confirm }) => {
      switch (type) {
        case 'date': {
          return (
            <div style={styles.datePickerContainer}>
              <DatePicker
                allowClear={false}
                ref={(node) => {
                  searchInput = node
                }}
                autoFocus={true}
                locale={locale}
                format="DD/MM/YYYY"
                onChange={(date) => handleSearch(dataIndex, date.format('YYYY-MM-DD'), confirm)}
              />
              <Button
                onClick={() => handleReset(confirm)}
                size="small"
                style={{
                  width: 90,
                  marginTop: 8
                }}>
                Reset
              </Button>
            </div>
          )
        }
        case 'rangepicker': {
          const props = {
            confirm,
            dataIndex,
            handleSearch,
            searchFilters
          }

          return <RangePickerFilter {...props} />
        }
        case 'string':
        default:
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  searchInput = node
                }}
                className="ant-input"
                placeholder={`Buscar por ${searchLabel}`}
                onChange={(e) => (inputValue = e.target.value)}
                onPressEnter={() => handleSearch(dataIndex, inputValue, confirm)}
                style={{
                  width: 188,
                  marginBottom: 8,
                  display: 'block'
                }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch(dataIndex, inputValue, confirm)}
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8
                }}>
                Buscar
              </Button>
              <Button onClick={() => handleReset(confirm)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          )
      }
    },
    filterIcon: () => {
      const filtered = getFiltered(dataIndex, searchFilters)
      return <Icon type="search" style={{ color: filtered ? '#5d58f7' : undefined }} />
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && searchInput && type !== 'date' && type !== 'rangepicker') {
        if (!getFiltered(dataIndex, searchFilters)) resetInput()
        setTimeout(() => {
          searchInput.select()
        })
      }
    }
  }
}

const RangePickerFilter = ({ confirm, dataIndex, handleSearch, searchFilters }) => {
  const [rangePickerValue, setRangePickerValue] = useState(DEFAULT_RANGE_VALUE)

  useEffect(() => {
    const actualRangePickerFilter = searchFilters.find((searchFilter) => searchFilter.key === dataIndex)?.text || []
    setRangePickerValue(
      isEmpty(actualRangePickerFilter) ? DEFAULT_RANGE_VALUE : actualRangePickerFilter.map((filter) => moment(filter))
    )
  }, [searchFilters])

  const handleDatePickerReset = () => handleSearch(dataIndex, [], confirm)

  return (
    <div style={styles.datePickerContainer}>
      <RangePicker
        allowClear={false}
        ref={(node) => {
          searchInput = node
        }}
        value={rangePickerValue}
        autoFocus={true}
        locale={locale}
        format="DD/MM/YYYY"
        onChange={(dates) => {
          setRangePickerValue(dates)
        }}
      />
      <div style={styles.rangePickerButtonContainer}>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              dataIndex,
              rangePickerValue.map((date) => date.format('YYYY-MM-DD')),
              confirm
            )
          }
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}>
          Buscar
        </Button>
        <Button onClick={handleDatePickerReset} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    </div>
  )
}

RangePickerFilter.propTypes = {
  confirm: PropTypes.func.isRequired,
  dataIndex: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    })
  )
}
