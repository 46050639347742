import { PickingStatus } from '../../projectApi/TransactionHandler/Pickings/common'
import { ObjectsStatusKey } from '../../projectApi/ObjectAdministration/common'

export const PickingStatusBadges = [
  {
    value: PickingStatus.ACTIVE,
    text: 'Activa',
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: PickingStatus.CANCELED,
    text: 'Cancelada',
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: PickingStatus.CLOSED,
    text: 'Cerrada',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  }
]

export const ObjectStatusBadges = [
  {
    value: ObjectsStatusKey.DEPOSITADO,
    text: ObjectsStatusKey.DEPOSITADO,
    styleProps: {
      borderColor: '#175CD3',
      backgroundColor: '#EFF8FF'
    }
  },
  {
    value: ObjectsStatusKey.BUSCANDO,
    text: ObjectsStatusKey.BUSCANDO,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: ObjectsStatusKey.ENCONTRADO,
    text: ObjectsStatusKey.ENCONTRADO,
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: ObjectsStatusKey.EN_PROPIETARIO,
    text: ObjectsStatusKey.EN_PROPIETARIO,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: ObjectsStatusKey.NO_ENCONTRADO,
    text: ObjectsStatusKey.NO_ENCONTRADO,
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: ObjectsStatusKey.EN_TRANSITO,
    text: ObjectsStatusKey.EN_TRANSITO,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  },
  {
    value: ObjectsStatusKey.A_CATALOGAR,
    text: ObjectsStatusKey.A_CATALOGAR,
    styleProps: {
      borderColor: '#C11574',
      backgroundColor: '#FDF2FA'
    }
  },
  {
    value: ObjectsStatusKey.A_FOTOGRAFIAR,
    text: ObjectsStatusKey.A_FOTOGRAFIAR,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: ObjectsStatusKey.A_UBICAR,
    text: ObjectsStatusKey.A_UBICAR,
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  }
]
