import React from 'react'
import styles from './SalesRequestMetrics.module.scss'
import StatusCard from '../../../components/StatusCard'
import { SalesRequestMetric } from '../types/salesRequestTable'
import { StatusCardSkeleton, StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { useSelectStatusCard } from '../../../components/StatusCard/hook'
import { formatNumber } from '../../../utils/formatNumber'

export type SalesRequestMetricsProps = {
  type?: string
  metrics: SalesRequestMetric[]
  fetchingMetrics: boolean
  handleGetSalesRequest: (newParams: { type?: string }) => void
}

const SalesRequestMetrics: React.FC<SalesRequestMetricsProps> = ({
  type,
  metrics,
  fetchingMetrics,
  handleGetSalesRequest
}) => {
  const [salesRequestMetrics, handleClickSalesRequestMetrics] = useSelectStatusCard<SalesRequestMetric>({
    list: metrics,
    idListKey: 'id',
    callback: (list) => {
      handleGetSalesRequest({ type: list.find((item) => item.clicked)?.id })
    },
    utilsEffect: {
      dependencies: [type],
      conditionalReRender: type === undefined
    }
  })

  return (
    <div className={styles.statusContainer}>
      {fetchingMetrics ? (
        <>
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-1'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-2'} loadingColor="#B25E09" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-3'} loadingColor="#1DD4B3" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-4'} loadingColor="#CC6266" />
        </>
      ) : (
        salesRequestMetrics.map((metric) => {
          return (
            <StatusCard
              key={metric.id}
              type={mapOfMetricTypes[metric.id]}
              customClassname={styles.warningCard}
              titleCard={metric.metric}
              total={formatNumber(metric.quantity, 0, true)}
              clicked={metric.clicked}
              onClick={() => handleClickSalesRequestMetrics(metric.id)}
            />
          )
        })
      )}
    </div>
  )
}

export default SalesRequestMetrics

const mapOfMetricTypes: { [key: string]: StatusCardTypes } = {
  salesRequests: 'info',
  acceptedSalesRequests: 'success',
  rejectedSalesRequests: 'danger',
  pendingSalesRequests: 'warning'
}
