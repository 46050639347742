import { Moment } from 'moment'
import { Agent } from '@/projectApi/Mercurio/BO/Agents/common'
import { Complaint, ComplaintHistory } from '@/projectApi/Complaints/list'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'
import { CommonComplaintsActions } from './common'
import { Photo } from './createComplaintModal'

export type ComplaintUpdateViewState = {
  complaint: Complaint | null
  selectedEvidence: ComplaintHistory | null
  isFetchingComplaint: boolean
  openEvidence: boolean
  description: string
  priority: keyof typeof Priority | null
  assignedUserId: number | null
  agents: Agent[]
  photos: Photo[]
  fetchingAgents: boolean
  reasons: ComplaintsReasons[]
}

export enum ComplaintStatus {
  CLOSED = 'Resuelto',
  IN_PROGRESS = 'En curso',
  NEW = 'Nuevo'
}

export enum Priority {
  URGENT = 'Urgente',
  HIGH = 'Alta',
  MEDIUM = 'Media',
  LOW = 'Baja'
}
type ObjectType = {
  id: number
  name: string
}

type ComplaintObjectType = {
  complaintId: number
  objectId: number
  createdAt: Moment | string | null
  updatedAt: Moment | string | null
  deletedAt: Moment | string | null
  object: ObjectType
}

export type ComplaintPhoto = {
  id: number | string
  createdAt?: Moment | null
  updatedAt?: Moment | null
  deletedAt?: Moment | null
  complaintHistoryId?: number
  complaintId?: number
  url: string
}

export type ComplaintView = {
  id: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  complaintType: string
  priority: keyof typeof Priority
  createUserId: number
  assignedUserId: number
  clientId: number
  status: string
  closeDate: string | null
  closeReason: number | null
  reasonId: number
  description: string
  delayed: boolean
  complaintObjects: ComplaintObjectType[]
  complaintHistories: ComplaintHistory[] | null
  photos: ComplaintPhoto[]
  daysOpen: number
  operationId: number
}

export enum UpdateComplaintTypes {
  UPDATE_COMPLAINT_REQUEST = 'COMPLAINTS/UPDATE_VIEW/UPDATE_COMPLAINT_REQUEST',
  UPDATE_COMPLAINT_FAILURE = 'COMPLAINTS/UPDATE_VIEW/UPDATE_COMPLAINT_FAILURE',
  UPDATE_COMPLAINT_SUCCESS = 'COMPLAINTS/UPDATE_VIEW/UPDATE_COMPLAINT_SUCCESS'
}

export type UpdateComplaintRequest = {
  type: typeof UpdateComplaintTypes.UPDATE_COMPLAINT_REQUEST
}

export type UpdateComplaintSuccess = {
  type: typeof UpdateComplaintTypes.UPDATE_COMPLAINT_SUCCESS
  payload: { complaint: Complaint }
}

export type UpdateComplaintFailure = {
  type: typeof UpdateComplaintTypes.UPDATE_COMPLAINT_FAILURE
}

export type UpdateComplaintActions = UpdateComplaintRequest | UpdateComplaintSuccess | UpdateComplaintFailure

export enum GetComplaintByIdTypes {
  GET_COMPLAINT_VIEW_REQUEST = 'COMPLAINTS/UPDATE_VIEW/GET_COMPLAINT_REQUEST',
  GET_COMPLAINT_VIEW_SUCCESS = 'COMPLAINTS/UPDATE_VIEW/GET_COMPLAINT_SUCCESS',
  GET_COMPLAINT_VIEW_FAILURE = 'COMPLAINTS/UPDATE_VIEW/GET_COMPLAINT_FAILURE'
}

export type GetComplaintByIdRequest = {
  type: typeof GetComplaintByIdTypes.GET_COMPLAINT_VIEW_REQUEST
  payload: { silentLoading?: boolean }
}

export type GetComplaintByIdSuccess = {
  type: typeof GetComplaintByIdTypes.GET_COMPLAINT_VIEW_SUCCESS
  payload: { complaint: Complaint }
}

export type GetComplaintByIdFailure = {
  type: typeof GetComplaintByIdTypes.GET_COMPLAINT_VIEW_FAILURE
}

export type GetComplaintByIdAction = GetComplaintByIdRequest | GetComplaintByIdSuccess | GetComplaintByIdFailure

export enum SettersUpdateComplaintTypes {
  SET_DESCRIPTION = 'COMPLAINTS/UPDATE_VIEW/SET_DESCRIPTION',
  SET_EVIDENCE = 'COMPLAINTS/UPDATE_VIEW/SET_EVIDENCE',
  SET_AGENT = 'COMPLAINTS/UPDATE_VIEW/SET_AGENT',
  SET_PRIORITY = 'COMPLAINTS/UPDATE_VIEW/SET_PRIORITY',
  DELETE_EVIDENCE = 'COMPLAINTS/UPDATE_VIEW/DELETE_EVIDENCE'
}

export interface setDescription {
  type: typeof SettersUpdateComplaintTypes.SET_DESCRIPTION
  payload: { description: string }
}

export interface setPriority {
  type: typeof SettersUpdateComplaintTypes.SET_PRIORITY
  payload: { priority: keyof typeof Priority | null }
}

export interface setAgent {
  type: typeof SettersUpdateComplaintTypes.SET_AGENT
  payload: { assignedUserId: number | null }
}

export interface setEvidence {
  type: typeof SettersUpdateComplaintTypes.SET_EVIDENCE
  payload: { photo: Photo[] }
}

export type SetterUpdateActions = setDescription | setPriority | setAgent | setEvidence

export const SET_OPEN_EVIDENCE = 'COMPLAINTS/UPDATE_VIEW/EVIDENCE_OPEN'

export interface SetOpenEvidence {
  type: typeof SET_OPEN_EVIDENCE
  payload: {
    setOpenEvidence: boolean
    selectedEvidence?: ComplaintHistory
  }
}

export enum GetAgentsTypes {
  GET_AGENTS_REQUEST = 'COMPLAINTS/MODAL_CREATE/GET_AGENTS_REQUEST',
  GET_AGENTS_SUCCESS = 'COMPLAINTS/MODAL_CREATE/GET_AGENTS_SUCCESS',
  GET_AGENTS_FAILURE = 'COMPLAINTS/MODAL_CREATE/GET_AGENTS_FAILURE'
}

export interface GetAgentsRequest {
  type: typeof GetAgentsTypes.GET_AGENTS_REQUEST
}

export interface GetAgentsSuccess {
  type: typeof GetAgentsTypes.GET_AGENTS_SUCCESS
  payload: { agents: Agent[] }
}

export interface GetAgentsFailure {
  type: typeof GetAgentsTypes.GET_AGENTS_FAILURE
}

type GetAgentsActions = GetAgentsRequest | GetAgentsSuccess | GetAgentsFailure

export type ComplaintUpdateViewAction =
  | GetComplaintByIdAction
  | SetOpenEvidence
  | SetterUpdateActions
  | GetAgentsActions
  | UpdateComplaintActions
  | CommonComplaintsActions
