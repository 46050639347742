import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'

export interface APIResponse {
  Id: number
  Name: string
  DataType: string
  IsVisible: boolean
  Unit: string
  DeletedAt: null
  Countries: CountryResponse[]
}

export interface CountryResponse {
  Id: number
  Label: string
  Country: string
  AttributeId: number
  DeletedAt: null
  Options: OptionResponse[]
}

export interface OptionResponse {
  Id: number
  DataType: string
  Value: string
  AttributeCountryId: number
  DeletedAt: null
}

export interface AttributeParams {
  is_visible: boolean
  data_type: string
  name: string
  unit: string
  countries: Country[]
}

export interface Country {
  country: string
  label: string
  options: Option[]
}

export interface Option {
  data_type: string
  value: string
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export function createAttribute(params: AttributeParams): Promise<Response> {
  return apiPrivate
    .post(`${URL_BASE_OBJECTS}/attribute`, params)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener los atributos')
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
