import { apiPrivate } from '../../../api'
import { URL_BASE_PROFILE } from '../../../endpoints'

interface AddParams {
  userId: string
  token: string
  paymentMethod: string
}

export function add(params: AddParams): Promise<void> {
  const formData = new FormData()

  formData.append('userId', params.userId)
  formData.append('token', params.token)
  formData.append('payment_method', params.paymentMethod)

  return apiPrivate
    .post(`${URL_BASE_PROFILE}/payment_methods/cards`, formData)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
