import { Audit } from '../../../common/operations/audits/entities'

export interface RemovalHistoryState {
  audits: Audit[]
  loading: boolean
}

export const GET_REMOVAL_HISTORY_REQUEST = 'REMOVALS/REMOVAL_HISTORY/GET_REMOVAL_HISTORY_REQUEST'
export const GET_REMOVAL_HISTORY_SUCCESS = 'REMOVALS/REMOVAL_HISTORY/GET_REMOVAL_HISTORY_SUCCESS'
export const GET_REMOVAL_HISTORY_FAILURE = 'REMOVALS/REMOVAL_HISTORY/GET_REMOVAL_HISTORY_FAILURE'

export interface GetRemovalHistoryRequest {
  type: typeof GET_REMOVAL_HISTORY_REQUEST
}

export interface GetRemovalHistorySuccess {
  type: typeof GET_REMOVAL_HISTORY_SUCCESS
  payload: {
    audits: Audit[]
  }
}

export interface GetRemovalHistoryFailure {
  type: typeof GET_REMOVAL_HISTORY_FAILURE
}

export type GetRemovalHistoryAction = GetRemovalHistoryRequest | GetRemovalHistorySuccess | GetRemovalHistoryFailure

export type RemovalHistoryAction = GetRemovalHistoryAction
