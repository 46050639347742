import { combineReducers } from 'redux'
import { SalesRequestReducer } from './salesRequestTable'
import salesRequestViewReducer from './salesRequestView'
import cancelSaleModalReducer from './cancelSaleModal'

export default combineReducers({
  table: SalesRequestReducer,
  view: salesRequestViewReducer,
  cancel: cancelSaleModalReducer
})
