import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'
import {} from '../../../../sections/OperationCalendar/types/operationDates'
import moment, { Moment } from 'moment'
import { OperationTypeValues } from '../../../../sections/OperationCalendar/types/common'

export type DeleteOperationParams = {
  date: Moment
  operationType: OperationTypeValues
}

export async function deleteDate({ date, operationType }: DeleteOperationParams) {
  const formattedDate = moment.utc(date).format('YYYY-MM-DD')
  const formattedParams = snakecaseKeys({ date: formattedDate, operationType })
  return apiPrivate
    .delete(`${URL_BASE_TIMESLOT}/operation-days/specific-day`, { params: formattedParams })
    .then(() => {})
    .catch((error) => {
      console.error(error)
      throw error
    })
}
