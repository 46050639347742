import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import {
  DonationDateRangeFilter,
  DonationTableDateRangeKey,
  DonationsSearchFilter,
  DonationsTableCategoryFilter,
  DonationsTableSearchKey,
  DonationsTableSort
} from '../types/donationsTable'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import {
  getFilterProps,
  getRangePickerProps,
  getSearchProps,
  getSortProps
} from '../../../components/DynamicTable/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Donation } from '../../../projectApi/Sales/DonationRequest/common'
import { Button, Tooltip } from 'antd'
import { Camera, QRIcon } from '../../../icons'
import notAvailableImage from '../../Objects/images/notAvailableImage.jpg'
import ImgWithCarousel from '../../../components/CarouselModal/components/ImgWithCarousel'
import { useHistory } from 'react-router'
import { truncateText } from '../../../utils/truncateText'
import { formatNumber } from '../../../utils/formatNumber'
import { parseMomentDate } from '../../../components/DynamicTable/utils/utils'
import { OBJECT_SITUATION } from '../../../projectApi/ObjectAdministration/common'
import { STATUS_OPTIONS } from '../utils'
import ColorBadge from '../../../components/ColorBadge'
import { Moment } from 'moment'

type DonationTableProps = {
  fetchingDonations: boolean
  sort: DonationsTableSort
  donations: Donation[]
  pagination: Pagination
  searchFilters: DonationsSearchFilter[]
  categoryFilter: DonationsTableCategoryFilter
  actions: MenuAction<Donation>[]
  handleSearch: (key: DonationsTableSearchKey, newValue: string) => void
  handleSort: (newSort: DonationsTableSort) => void
  handleOpenQRCodeModal: (code: string) => void
  dateRangeFilters: DonationDateRangeFilter[]
  handleDateRangePiker: (key: DonationTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleCategoryFilter: (newCategoryFilter: DonationsTableCategoryFilter) => void
}

const DonationsTable = ({
  fetchingDonations,
  sort,
  donations,
  pagination,
  handleSearch,
  handleSort,
  searchFilters,
  handleOpenQRCodeModal,
  dateRangeFilters,
  categoryFilter,
  handleDateRangePiker,
  actions,
  handleCategoryFilter
}: DonationTableProps) => {
  const history = useHistory()
  const columns: Column<Donation>[] = [
    {
      key: 'id',
      label: 'ID',
      maxWidth: '200px',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'user',
      label: 'Cliente',
      noPadding: true,
      search: getSearchProps('userId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ request }) => (
        <ClientIDLinkComponent id={request.user.id} noPadding={true}>
          <div>
            {request.user.id}-{request.user.name} {request.user.lastName}
          </div>
        </ClientIDLinkComponent>
      )
    },
    {
      key: 'identificationCode',
      label: 'Etiqueta',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ request }) => {
        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenQRCodeModal(request.object.identificationCode.description)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
            {request.object.identificationCode.description}
          </div>
        )
      }
    },
    {
      key: 'photo',
      label: <Camera />,
      renderDataCell: ({ request }) => {
        if (!request.object.photo.url) {
          return <img src={notAvailableImage} alt="-" style={{ objectFit: 'cover', width: '40px', height: '55px' }} />
        }

        return <ImgWithCarousel carouselImages={[request.object.photo.url]} alt={'-'} />
      }
    },
    {
      key: 'object',
      label: 'Objetos',
      alignment: 'left',
      minWidth: 'max-content',
      renderDataCell: ({ request }) => {
        const { object } = request
        const { widthInCm, heightInCm, lengthInCm, product, description } = object
        const totalM3 = (lengthInCm * widthInCm * heightInCm) / 1_000_000

        return (
          <Button
            type="link"
            style={{
              height: 'auto',
              maxHeight: '90px'
            }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/objects?id=${object.id}`)
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                fontSize: '12px',
                height: '74px'
              }}>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{truncateText(product.name, 30)}</span>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{truncateText(description, 30)}</span>
              <span>
                {widthInCm}x{heightInCm}x{lengthInCm}(cm)= {formatNumber(totalM3)} m3
              </span>
              <span>Coef. Multip x{product.multiplier}</span>
            </div>
          </Button>
        )
      }
    },
    {
      key: 'createdAt',
      label: 'Creación',
      alignment: 'left',
      rangePicker: getRangePickerProps(DonationTableDateRangeKey.CREATED_AT, dateRangeFilters, handleDateRangePiker),
      renderDataCell: ({ request }) => parseMomentDate(request.createdAt, 'left'),
      sort: getSortProps('created_at', sort, handleSort)
    },
    {
      key: 'updatedAt',
      label: 'Ult. interacción',
      rangePicker: getRangePickerProps(DonationTableDateRangeKey.UPDATED_AT, dateRangeFilters, handleDateRangePiker),
      renderDataCell: ({ request }) => parseMomentDate(request.updatedAt, 'left'),
      sort: getSortProps('updated_at', sort, handleSort)
    },
    {
      key: 'statusAndSituation',
      label: 'Estado y situación del objeto',
      maxWidth: '200px',
      minWidth: 'min-content',
      alignment: 'left',
      renderDataCell: ({ request }) => {
        const { object } = request
        return (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', whiteSpace: 'nowrap' }}>
            <div>
              <b>{object.status}</b>
            </div>
            {object.objectSituation && (
              <div>{OBJECT_SITUATION[object.objectSituation as keyof typeof OBJECT_SITUATION]}</div>
            )}
          </div>
        )
      }
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      renderDataCell: ({ status }) => {
        const found = STATUS_OPTIONS.find((sf) => sf.value === status)
        if (!found) return status

        const props = {
          ...found.styleProps
        }
        return (
          <ColorBadge {...props} extraStyles={{ minWidth: 150 }}>
            {found.text}
          </ColorBadge>
        )
      },
      filters: getFilterProps(STATUS_OPTIONS, categoryFilter, 'status', handleCategoryFilter, true)
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDonations}
      rows={donations}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default DonationsTable
