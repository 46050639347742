export type CreditNoteWarningState = {
  modalOpen: boolean
}

export enum CreditNoteWarningTypes {
  SET_MODAL_OPEN = 'REMOVALS/CREDIT_NOTE_WARNING/SET_MODAL_OPEN'
}

export interface SetModalOpen {
  type: CreditNoteWarningTypes.SET_MODAL_OPEN
  payload: { modalOpen: boolean }
}

export type CreditNoteWarningActions = SetModalOpen
