import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { Moment } from 'moment'
import { convertToMoment, formatDates } from '../../utils'
import { URL_BASE_SALES } from '../../../endpoints'
import { ListParams, convertPrice } from './common'
import { OBJECT_SITUATION } from '../../ObjectAdministration/common'

type APIResponse = {
  sales_request: APISalesRequest[]
  total: number
}

export type APISalesRequest = {
  id: number
  created_at: string
  updated_at: string
  sale_cost: number
  sale_margin: number
  status: {
    id: number
    name: string
  }
  pvp: number
  prc: number
  express_sale?: {
    id: number
    pve: number
    sale_cost: number
    sale_margin: number
  }
  object: {
    id: number
    object_situation: keyof typeof OBJECT_SITUATION | null
    status: string
    weight_in_gr: number
    length_in_cm: number
    width_in_cm: number
    height_in_cm: number
    photo: {
      id: number
      url: string
      object_id: number
    }
    identification_code: {
      id: number
      description: string
    }
    description: string
    product: {
      multiplier: number
      name: string
    }
  }
  user: {
    id: number
    name: string
    last_name: string
    email: string
  }
}

type Response = {
  salesRequest: SalesRequest[]
  total: number
}

export type SalesRequest = {
  id: number
  status: string
  createdAt: Moment | null
  updatedAt: Moment | null
  expressSale?: {
    pve: number
    saleCost: number
    saleMargin: number
  }
  pve?: number
  pvp: number
  prc: number
  saleCost: number
  saleMargin: number
  user: {
    id: number
    name: string
    lastName: string
    email: string
  }
  object: {
    id: number
    objectSituation: keyof typeof OBJECT_SITUATION | null
    status: string
    weightInGr: number
    lengthInCm: number
    widthInCm: number
    heightInCm: number
    photoUrl: string
    identificationCode: string
    description: string
    product: {
      name: string
      multiplier: number
    }
  }
}

export function list(params: ListParams): Promise<Response> {
  const {
    userId,
    status,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    limit,
    offset,
    id,
    showDemoUsers = false,
    type,
    requestId
  } = params
  const formattedParams = {
    user_id: userId,
    status_id: status,
    test_requests: showDemoUsers,
    request_id: requestId,
    limit,
    offset,
    column: 'ID',
    order: 'desc',
    id,
    type,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo,
        updated_at_from: updatedAtFrom,
        updated_at_to: updatedAtTo
      },
      format: 'YYYY/MM/DD'
    })
  }
  return apiPrivate
    .get(`${URL_BASE_SALES}/sales-requests`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('No se pudo obtener las solicitudes de venta')

      const camelResponse: Response = {
        total: response.data.total,
        salesRequest:
          response.data.sales_request === null
            ? []
            : camelcaseKeys(response.data.sales_request, { deep: true }).map((salesRequest) => ({
                id: salesRequest.id,
                status: salesRequest.status.name,
                createdAt: convertToMoment(salesRequest.createdAt),
                updatedAt: convertToMoment(salesRequest.updatedAt),
                expressSale: salesRequest.expressSale
                  ? {
                      pve: convertPrice(salesRequest.expressSale.pve),
                      saleCost: convertPrice(salesRequest.expressSale.saleCost),
                      saleMargin: convertPrice(salesRequest.expressSale.saleMargin)
                    }
                  : undefined,
                pve: convertPrice(salesRequest.expressSale?.pve || 0),
                pvp: convertPrice(salesRequest.pvp),
                prc: convertPrice(salesRequest.prc),
                saleCost: convertPrice(salesRequest.saleCost),
                saleMargin: convertPrice(salesRequest.saleMargin),
                user: {
                  id: salesRequest.user.id,
                  name: salesRequest.user.name,
                  lastName: salesRequest.user.lastName,
                  email: salesRequest.user.email
                },
                object: {
                  id: salesRequest.object.id,
                  objectSituation: salesRequest.object.objectSituation,
                  status: salesRequest.object.status,
                  heightInCm: salesRequest.object.heightInCm,
                  lengthInCm: salesRequest.object.lengthInCm,
                  weightInGr: salesRequest.object.weightInGr,
                  widthInCm: salesRequest.object.widthInCm,
                  identificationCode: salesRequest.object.identificationCode.description,
                  photoUrl: salesRequest.object.photo.url,
                  description: salesRequest.object.description,
                  product: {
                    name: salesRequest.object.product.name,
                    multiplier: salesRequest.object.product.multiplier
                  }
                }
              }))
      }

      return camelResponse
    })
}
