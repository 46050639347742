/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { Params } from '../../../sections/Address/types/address'
import { APIAddress, Address } from '../common'

export interface APIResponse {
  paging: Paging
  results: APIAddress[]
}

export interface Paging {
  total: number
  limit: number
  offset: number
}

export interface PostalCode {
  ID: number
  Code: string
  AllowedDelivery: boolean
}

interface Response {
  addresses: Address[]
  quantity: number
  params: Params
}

export function search({ userId, params }: { userId: number; params: Params }): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/addresses/search?user_id=${userId}`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.results) throw new Error('Respuesta incorrecta al obtener los usuarios')

      return {
        addresses: camelcaseKeys(response.data.results),
        quantity: response.data.paging.total,
        params
      }
    })
    .catch((err) => {
      console.error(err)
      throw err
    })
}
