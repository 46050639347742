import React from 'react'
import { Modal } from 'antd'
import styles from './EnableUserModal.module.scss'
import { UserResponse } from '../../../projectApi/TransactionHandler/BillingData/list'

export type EnableUserModalProps = {
  userInfo?: UserResponse
  enablingUser: boolean
  openEnableModal: boolean
  setDeleteModalVisibility: (modalVisible: boolean) => void
  handleEnableUser: () => void
}

const EnableUserModal = ({
  userInfo,
  enablingUser,
  openEnableModal,
  setDeleteModalVisibility,
  handleEnableUser
}: EnableUserModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={() => setDeleteModalVisibility(false)}
      title={'Habilitar facturación'}
      confirmLoading={enablingUser}
      visible={openEnableModal}
      okText={'Habilitar'}
      cancelText="Cancelar"
      onOk={handleEnableUser}
      destroyOnClose>
      <InfoCard id={userInfo?.id} name={userInfo?.name} lastName={userInfo?.lastName} />
      <div className={styles.confirmationContainer}>
        <p className={styles.paragraph}>
          <h4 style={{ fontSize: 18, textAlign: 'center' }}>
            ¿Está seguro que desea habilitar la facturación automática al cliente?
          </h4>
        </p>
      </div>
    </Modal>
  )
}

export default EnableUserModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; name?: string; lastName?: string }> = ({ id, name, lastName }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos del cliente:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
      <InfoItem data={lastName} label={'Apellido'} />
    </div>
  </header>
)
