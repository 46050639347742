import React from 'react'
import { Modal } from 'antd'
import AttributesActions from '../actionCreators/Attributes'
import DeleteAttributesActions from '../actionCreators/DeleteAttributes'
import styles from './DeleteAttribute.module.scss'
import { DeleteAttributeState } from '../types/DeleteAttribute'

type DeleteAttributeProps = {
  attributesActions: typeof AttributesActions
  deleteAttributesActions: typeof DeleteAttributesActions
  deleteState: DeleteAttributeState
}

const DeleteAttributeModal = (props: DeleteAttributeProps) => {
  const { attributesActions, deleteState, deleteAttributesActions } = props
  const { loading, deleteAttributeModalOpen, error, id } = deleteState

  const onClose = () => {
    deleteAttributesActions.closeDeleteModal()
  }

  const onOkClick = async () => {
    const result = await deleteAttributesActions.deleteAttribute(id!)
  }

  return (
    <Modal
      width={697}
      title="Eliminar atributo"
      visible={deleteAttributeModalOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={onClose}
      okButtonProps={{
        loading,
        disabled: loading,
        className: styles.modalButtonOk
      }}
      okText={'Aceptar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p className={styles.description}>{`¿Está seguro que desea eliminar el atributo ID ${id}?`}</p>
    </Modal>
  )
}

export default DeleteAttributeModal
