import { useEffect, useState } from 'react'

export function useSelectStatusCard<T>({
  list,
  idListKey,
  callback,
  utilsEffect
}: {
  list: T[]
  idListKey: keyof T
  callback?: (list: (T & { clicked: boolean })[], idListKey: keyof T) => void
  utilsEffect?: {
    dependencies?: React.DependencyList
    conditionalReRender?: boolean
  }
}): [newList: (T & { clicked: boolean })[], handleClick: (keyOfList: (T & { clicked: boolean })[keyof T]) => void] {
  type BaseType = T & { clicked: boolean }
  const [newList, setnewList] = useState<BaseType[]>([])

  const effectDependencies = utilsEffect?.dependencies?.length ? utilsEffect.dependencies : []
  const effectFunction = () => setnewList(() => list.map((item) => ({ ...item, clicked: false })))

  useEffect(() => {
    if (utilsEffect?.conditionalReRender !== undefined) {
      if (utilsEffect.conditionalReRender) effectFunction()
    } else effectFunction()
  }, [list, utilsEffect?.conditionalReRender, ...effectDependencies])

  const handleClickStatusCard = (keyOfList: BaseType[typeof idListKey]) => {
    const updateList = newList.map((item) => {
      const isIdEqual = item[idListKey] === keyOfList

      if (isIdEqual && !item.clicked) return { ...item, clicked: true }
      else return { ...item, clicked: false }
    })

    setnewList(updateList)
    callback && callback(updateList, idListKey)
  }

  return [newList, handleClickStatusCard]
}
