import React from 'react'
import styles from './ExtraValueData.module.scss'
import { Checkbox } from 'antd'
import NumberInput from '../../../../components/numberInput'
import { LoadingIndicator } from '../../../../components/LoadingIndicator'
import clsx from 'clsx'

interface ExtraValueDataProps {
  disabled?: boolean
  loadingExtras?: boolean
  currencySymbol: string
  extraValuePrice: number | string
  extraValueCount: number
  hideCheckbox?: boolean
  titleExtra?: string
  titleStyles?: string
  extraValueEnabled: boolean
  setExtraValueCount: (valueAcount: number) => void
  setExtraValueEnabled?: (enabled: boolean) => void
}

const ExtraValueData = ({
  disabled,
  loadingExtras,
  currencySymbol,
  extraValuePrice,
  extraValueCount,
  titleExtra,
  titleStyles,
  hideCheckbox: notCheck = false,
  extraValueEnabled,
  setExtraValueCount,
  setExtraValueEnabled
}: ExtraValueDataProps) => {
  return (
    <>
      {loadingExtras && <LoadingIndicator size="small" />}
      {!notCheck && (
        <Checkbox
          className={styles.extraCheckbox}
          value={extraValueEnabled}
          checked={extraValueEnabled}
          onChange={() => setExtraValueEnabled && setExtraValueEnabled(!extraValueEnabled)}
          disabled={loadingExtras || disabled}>
          {titleExtra && <span className={clsx(styles.extraTitle, titleStyles)}>{titleExtra}</span>}
        </Checkbox>
      )}
      <NumberInput
        disabled={disabled || !extraValueEnabled || loadingExtras}
        onAnyChange={(newValue) => setExtraValueCount(newValue)}
        value={extraValueCount}
      />
      <span className={styles.extraPrice}>
        x {currencySymbol}
        {extraValuePrice}
      </span>
    </>
  )
}

export default ExtraValueData
