import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Buffer } from 'buffer'
import actions from '../actions/cbus'
import defaultActions from '../actions/defaultCBUs'
import Table from '../components/cbusTable'
import queryString from 'query-string'
import CBUModalActionsCreator from '../actions/cbusModal'
import { useEvents } from '@/utils/eventEmitter'
import { Events } from '@/utils/eventEmitter/events'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const cbusActions = bindActionCreators(actions, dispatch)
  const { setModalVisibility } = bindActionCreators(CBUModalActionsCreator, dispatch)
  const defaultCbusActionsCreator = bindActionCreators(defaultActions, dispatch)

  const urlEncoded = ownProps.history.location.search

  const { userId } = useParams()

  const { name, lastName } = queryString.parse(unescape(Buffer.from(urlEncoded, 'base64').toString('ascii')))
  const fullname = `${name}` + ' ' + `${lastName}`

  if (userId === null) {
    history.push('/')
  }

  const { cbus: cbusReducer } = useSelector((state) => state.Cbus)

  const { cbus, error, holder, loading } = cbusReducer
  const quantity = cbus.length

  useEvents([Events.CBU.CREATED_CBU, Events.CBU.UPDATED_CBU], () => {
    setModalVisibility({ isVisible: false })
    cbusActions.getCbus(userId, fullname)
  })

  useEffect(() => {
    cbusActions.getCbus(userId, fullname)
  }, [])

  const handleCBUModalVisibility = ({ userInfo, editMode, currentCU }) => {
    setModalVisibility({ isVisible: true, userInfo, editMode, currentCU })
  }

  const props = {
    name,
    lastName,
    userId,
    ...ownProps,
    ...cbusActions,
    handleCBUModalVisibility,
    defaultCbusActionsCreator,
    cbus,
    error,
    holder,
    loading,
    quantity
  }

  return (
    <div className="animated fadeIn">
      <Table {...props} />
    </div>
  )
}

export default Container
