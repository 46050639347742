import styled from 'styled-components'

export const StyledFlagContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: flex-start;
`
export const StyledFlagImage = styled.img`
  width: 24px;
  height: 24px;
`
export const StyledCountryName = styled.div`
  font-size: 12px;
  margin-left: 5px;
`
