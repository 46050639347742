import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'
import { convertToMoment } from '../../utils'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { BillingPaymentListSortKey } from '../../../sections/BillingPayment/types/BillingPayment'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { BillingPaymentStatusId } from '../../../sections/BillingPayment/constants'

export interface ApiResponse {
  billings: BillingAPI[]
  total: number
}

export interface BillingResponse {
  billings: Billing[]
  total: number
}

export interface BillingAPI {
  entity_type: EntityType
  total: string
  number: string
  type: string
  billing_id: number
  status: string
  billing_date: string
  billing_colppy_id: string
}

export type Billing = {
  entityType: string
  total: string
  number: string
  type: string
  billingId: number
  status: string
  billingDate: Moment | null
  billingColppyId: string
}

export enum EntityType {
  Billing = 'BILLING',
  Colppy = 'COLPPY'
}

interface BillingByUserIdParams {
  userId: number
  limit?: number
  offset?: number
  sort?: BillingPaymentListSortKey
  order?: SortDirection
  status?: BillingPaymentStatusId[]
}

export type Response = CamelCasedPropertiesDeep<ApiResponse>

export function billingsByUserId({
  userId,
  limit,
  offset,
  sort,
  order,
  status
}: BillingByUserIdParams): Promise<BillingResponse> {
  const params = {
    limit: 10000,
    limit_internal: limit,
    offset_internal: offset,
    offset: 0,
    order_field: sort,
    order_type: order,
    status_colppy: status?.join(),
    receipt_type: 'invoiceSale'
  }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/colppy/billings/bo/${userId}`, { params })
    .then((response: AxiosResponse<ApiResponse>) => {
      const camelResponse = camelcaseKeys(response.data, { deep: true })
      return {
        billings: camelResponse.billings.map((billing) => {
          return {
            ...billing,
            billingDate: convertToMoment(billing.billingDate)
          }
        }),
        total: camelResponse.total
      }
    })
    .catch((err) => {
      throw err
    })
}
