import { Country } from '../../../../components/CountrySelector/constants/constants'
import { BaseOption } from '../../../../components/Select/Select'
import { TransportMode } from '../../../../projectApi/TransactionHandler/TransportMode/common'
import { DestinationDeposit } from '../../../../projectApi/TransactionHandler/ServiceType/common'
import { TransportPricingLabel } from '../../../../sections/NewProposal/constants'
import { formatMoneyWithCountry, formatNumber } from '../../../../utils/formatNumber'
import { TransportTypesPricing } from '../../constants'
import styles from './Transport.module.scss'

export type Option = BaseOption<number>
export type OptionStr = BaseOption<string>

export const getFormatTransportMode = (transportMode: string, list: TransportMode[]): OptionStr | null => {
  const transportModeByString = list.find((mode: TransportMode) => mode.name === transportMode)

  if (!transportModeByString) return null

  return { label: transportModeByString?.nameSpanish, value: transportModeByString?.name }
}

export const getFormatDestinationDeposit = (destinationDeposit: number, list: DestinationDeposit[]): Option => {
  const destinationById = list.find((deposit: DestinationDeposit) => deposit.id === destinationDeposit)
  if (destinationById)
    return { label: `${destinationById.name} - ${destinationById.address.addressString}`, value: destinationById.id }
  else return { label: '', value: 0 }
}

export const getTransportModeId = (transportMode: string, transportModeList: TransportMode[]) => {
  const { id } = transportModeList.find((mode: TransportMode) => mode.name === transportMode) || { id: 0 }

  return id
}

export const getAddressSelectedOption = (addresses: Option[], addressID: number) =>
  addresses.find((address) => address.value === addressID)

export const getCustomTransportOptions = ({
  customDateTransportMult,
  prospectCountry,
  fetchedCost = 0,
  tollCost = 0
}: {
  customDateTransportMult: number
  prospectCountry: Country
  fetchedCost: number
  tollCost: number
}) => {
  const customTransportPrice = fetchedCost * customDateTransportMult
  const commonPercentageOff = formatNumber((1 - fetchedCost / customTransportPrice) * 100 || 0)

  const commonTransportPriceMoney = formatMoneyWithCountry(prospectCountry.currency, fetchedCost + tollCost)
  const customTransportPriceMoney = formatMoneyWithCountry(prospectCountry.currency, customTransportPrice + tollCost)
  const freeTransportPriceMoney = formatMoneyWithCountry(prospectCountry.currency, 0)

  const customTransportPrices = {
    [TransportTypesPricing.CUSTOM]: {
      number: customTransportPrice,
      money: customTransportPriceMoney,
      discount: '',
      styles: styles.radioStyle
    },
    [TransportTypesPricing.COMMON]: {
      number: fetchedCost,
      money: commonTransportPriceMoney,
      discount: ` (${commonPercentageOff}% OFF)`,
      styles: styles.radioStyle
    },
    [TransportTypesPricing.FREE]: {
      number: 0,
      money: freeTransportPriceMoney,
      discount: ` (100% OFF)`,
      styles: styles.radioStyle
    }
  }

  return Object.entries(TransportTypesPricing).map(([key, value]) => {
    const customTransportPrice = customTransportPrices[value]
    return {
      label: `${TransportPricingLabel[key as 'CUSTOM' | 'COMMON']} - ${customTransportPrice.money}${
        customTransportPrice.discount
      }`,
      value,
      price: customTransportPrice.number,
      styles: customTransportPrice.styles
    }
  })
}
