import { apiPrivate } from '../../../api'
import { URL_BASE_PROFILE } from '../../../endpoints'

interface Params {
  cardId: number
}

export function main(params: Params): Promise<void> {
  return apiPrivate
    .put(`${URL_BASE_PROFILE}/payment_methods/cards/${params.cardId}/main`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
