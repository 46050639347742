import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { OverrideProps } from '../../../utils/utilityTypes'
import { convertToMoment } from '../../utils'

interface APIPostalCode {
  Allowed: boolean
  ID: number
  PostalCode: string
}

interface APIProspectAddress {
  Address: string
  Apartment: string
  City: string
  Comment: string
  Country: string
  Floor: string
  ID: number
  InDeliveryRange: boolean
  Latitude: number
  Longitude: number
  MainAddress: boolean
  Number: string
  Original: string
  PostalCode: APIPostalCode
  Province: string
}

interface APIProduct {
  Comment: string
  DepositFloorsByStairsCostInCents: number
  Description: string
  DisassemblyCost: number
  GUID: string
  HeightInCm: number
  Id: number
  LengthInCm: number
  PackagingCostInCents: number
  PackagingTimeInMinutes: number
  Price: number
  Product_id: number
  UnPackagingTimeInMinutes: number
  VolumeInCm3: number
  WeightInGr: number
  WidthInCm: number
}

interface APIItem {
  Amount: number
  Product: APIProduct
  Type: string
}

interface APIProposal {
  AdditionalCost: number
  AdditionalCostComment: string
  CreatedAt: string
  CubicCm: number
  CubicMPriceInCents: number
  Deposit_id: number
  Destination: string
  DisassemblyCost: number
  DiscountCode: string
  ExpirationDate: string
  Id: number
  Item: APIItem[]
  Origin: string
  PackagingCostInCents: number
  ProspectAddress: APIProspectAddress
  Prospect_id: number
  Shipment_Assistant_Count: number
  Shipment_Assistant_Price: number
  StorageCostInCents: number
  TotalAmount: number
  TransportCostInCents: number
  toll_cost_in_cents: number
  TransportMode_id: number
  TransportType: string
  Type: string
}

interface APIDescription {
  proposal: APIProposal
}

interface APIResponse {
  description?: APIDescription
  success: string
}

export type Proposal = OverrideProps<
  CamelCasedPropertiesDeep<APIProposal>,
  'createdAt' | 'expirationDate',
  Moment | null
>

type Response = Proposal
export type Item = CamelCasedPropertiesDeep<APIItem>
export type Product = CamelCasedPropertiesDeep<APIProduct>
export type ProspectAddress = CamelCasedPropertiesDeep<APIProspectAddress>

export interface GetByIdParams {
  countryCode?: string
  proposalId: string | number
  recalculatePrice: boolean
}

export function getById({ proposalId, countryCode, recalculatePrice }: GetByIdParams): Promise<Response> {
  const params = { country_code: countryCode, recalculatePrice }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/proposal/${proposalId}`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener la devolución')
      if (!response.data.description?.proposal) throw new Error('Respuesta incorrecta al obtener la devolución')
      const camelUnformattedProposal = camelcaseKeys(response.data.description.proposal, { deep: true })
      return {
        ...camelUnformattedProposal,
        createdAt: convertToMoment(camelUnformattedProposal.createdAt),
        expirationDate: convertToMoment(camelUnformattedProposal.expirationDate)
      }
    })
    .catch((err) => {
      throw err
    })
}
