import { Reducer } from 'redux'
import {
  DepositHistoryAction,
  DepositHistoryState,
  GET_DEPOSIT_HISTORY_FAILURE,
  GET_DEPOSIT_HISTORY_REQUEST,
  GET_DEPOSIT_HISTORY_SUCCESS
} from '../types/depositHistory'

const initialDespositHistory: DepositHistoryState = {
  audits: [],
  loading: false
}

const DepositHistoryReducer: Reducer<DepositHistoryState, DepositHistoryAction> = (
  state = initialDespositHistory,
  action
): DepositHistoryState => {
  switch (action.type) {
    case GET_DEPOSIT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DEPOSIT_HISTORY_SUCCESS:
      return {
        ...state,
        audits: action.payload.audits,
        loading: false
      }
    case GET_DEPOSIT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default DepositHistoryReducer
