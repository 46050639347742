import { Reducer } from 'redux'
import { OPEN_MODAL_FILES, FilesModalAction, FilesModalState } from '../types/filesModal'

const initialState: FilesModalState = {
  modalOpen: false,
  error: '',
  loading: false
}

const FilesModalReducer: Reducer<FilesModalState, FilesModalAction> = (
  state = initialState,
  action
): FilesModalState => {
  switch (action.type) {
    case OPEN_MODAL_FILES:
      return {
        ...state,
        modalOpen: action.payload.modalOpen
      }
    default:
      return state
  }
}

export default FilesModalReducer
