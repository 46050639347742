import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Select from '../../../../components/Select'
import { Col, Input, Row } from 'antd'
import { getOptionValue } from '../../../../common/operations/utils'
import CreditCardComponent from './CreditCardComponent'
import { PaymentComponentProps } from '../Payment'
import MaskedInput from 'antd-mask-input'
import DNICard from './DNICard'
import styled from 'styled-components'
import { IdentificationType, getIdentificationTypes } from '../../../../services/mercado-pago'
import { BaseOption } from '../../../../components/Select/Select'

type ErrorProps = {
  error?: string
}

const RowContainer = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputTitle = styled.p<ErrorProps>`
  margin-bottom: 5px;
  color: ${({ error }) => (error ? 'red' : 'grey')};
  text-transform: uppercase;
  font-size: 10px;
`

const SaveCardError = styled.p`
  color: red;
`

// @ts-ignore
const MaskedInputWithError = styled(MaskedInput)<MaskedErrorProps>`
  border-color: ${({ error }) => (error ? 'red' : '#d9d9d9')};
`

const InputError = styled.div<ErrorProps>`
  color: red;
  display: ${({ error }) => (error ? 'block' : 'none')};
`

const InputWithError = styled(Input)<ErrorProps & { height?: number }>`
  height: ${({ height }) => height}px;
  border-color: ${({ error }) => (error ? 'red' : '#d9d9d9')};
`

const amexRegex = /^3[47]/
const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/

const useCreditCardMask = (value: string): string => {
  return useMemo(() => {
    if (amexRegex.test(value) || dinersRegex.test(value)) return '1111 111111 11111'
    return '1111 1111 1111 1111'
  }, [value])
}

const useCVVMask = (value: string): string => {
  return useMemo(() => {
    if (amexRegex.test(value)) return '1111'
    return '111'
  }, [value])
}

const identificationTypesOptions = [
  { value: 'DNI', label: 'DNI' },
  { value: 'CI', label: 'Cédula' },
  { value: 'LC', label: 'L.C.' },
  { value: 'LE', label: 'L.E.' },
  { value: 'Otro', label: 'Otro' }
]

type Option = BaseOption<string>

const NewCard = ({ paymentMpState, paymentMPActions }: PaymentComponentProps) => {
  const [identificationTypes, setIdentificationTypes] = useState<IdentificationType[]>([])
  const { errorSaveCard, number, expiry, cvc, cardholderName, documentType, documentNumber, errorCardFields } =
    paymentMpState
  const [selected, setSelected] = useState<Option | null>(null)

  useEffect(() => {
    const getIdentificationTypesWrapper = async () => {
      const identificationTypes = await getIdentificationTypes()
      setIdentificationTypes(identificationTypes)
    }
    getIdentificationTypesWrapper().catch((e) => console.error(e))
  }, [])

  const { setCardNumber, setCardExpiry, setCardCVC, setCardholderName, setDocumentType, setDocumentNumber } =
    paymentMPActions

  const [focus, setFocus] = React.useState('number')

  const cvvMask = useCVVMask(number)
  const numberMask = useCreditCardMask(number)

  // @ts-ignore
  const onFocusChange = (e: FocusEvent<HTMLInputElement>) => setFocus(e.target.name)

  const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => setCardNumber(e.target.value)
  const onExpiryChange = (e: ChangeEvent<HTMLInputElement>) => setCardExpiry(e.target.value)
  const onCVVChange = (e: ChangeEvent<HTMLInputElement>) => setCardCVC(e.target.value)
  const onDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (/^\d+$/.test(value)) setDocumentNumber(value)
  }

  const options = useMemo(() => {
    if (identificationTypes.length === 0) return identificationTypesOptions
    return identificationTypes.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [identificationTypes])
  const identificationType = identificationTypes.find((identificationType) => identificationType.id === documentType)

  return (
    <RowContainer gutter={[16, 16]}>
      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputTitle error={errorCardFields.number}>Número de tarjeta</InputTitle>
            <MaskedInputWithError
              onFocus={onFocusChange}
              error={errorCardFields.number}
              name="number"
              type="text"
              placeholder="Número de tarjeta"
              placeholderChar={' '}
              onChange={onNumberChange}
              mask={numberMask}
            />
            <InputError error={errorCardFields.number}>{errorCardFields.number}</InputError>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputTitle error={errorCardFields.holder}>Nombre del titular</InputTitle>
            <InputWithError
              error={errorCardFields.holder}
              type="text"
              placeholder="Nombre"
              value={cardholderName}
              onFocus={onFocusChange}
              onChange={(e) => setCardholderName(e.target.value)}
            />
            <InputError error={errorCardFields.holder}>{errorCardFields.holder}</InputError>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputTitle error={errorCardFields.expiry}>Fecha de vencimiento</InputTitle>
            <MaskedInputWithError
              onFocus={onFocusChange}
              error={errorCardFields.expiry}
              name="expiry"
              type="text"
              placeholder="MM/AA"
              onChange={onExpiryChange}
              placeholderChar={' '}
              mask="11/11"
            />
            <InputError error={errorCardFields.expiry}>{errorCardFields.expiry}</InputError>
          </Col>
          <Col span={12}>
            <InputTitle error={errorCardFields.cvc}>Código de seguridad</InputTitle>
            <MaskedInputWithError
              onFocus={onFocusChange}
              error={errorCardFields.cvc}
              name="cvc"
              type="text"
              placeholder={cvvMask}
              value={cvc}
              onChange={onCVVChange}
              placeholderChar={' '}
              mask={cvvMask}
            />
            <InputError error={errorCardFields.cvc}>{errorCardFields.cvc}</InputError>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <InputTitle>Tipo</InputTitle>
            <Select
              placeholder="ID"
              selected={selected}
              onSelect={(option) => {
                setDocumentType(getOptionValue(option))
                setSelected(option)
              }}
              options={options}
            />
          </Col>
          <Col span={16}>
            <InputTitle>Número de documento</InputTitle>
            <InputWithError
              height={36}
              error={errorCardFields.document}
              name="dni"
              onFocus={onFocusChange}
              type="text"
              maxLength={identificationType?.max_length}
              placeholder=""
              value={documentNumber}
              onChange={onDocumentChange}
            />
            <InputError error={errorCardFields.document}>{errorCardFields.document}</InputError>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{errorSaveCard && <SaveCardError>{errorSaveCard}</SaveCardError>}</Col>
        </Row>
      </Col>
      <Col span={12}>
        {focus === 'dni' ? (
          <DNICard name={cardholderName} identificationType={identificationType} documentNumber={documentNumber} />
        ) : (
          <CreditCardComponent
            cvc={cvc}
            expiry={expiry}
            name={cardholderName}
            number={number}
            focused={focus}
            locale={{
              valid: ''
            }}
            placeholders={{
              name: 'Nombre y Apellido'
            }}
          />
        )}
      </Col>
    </RowContainer>
  )
}

export default NewCard
