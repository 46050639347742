import { itemsPaginationMergeFunction } from './utils'
import { InMemoryCache } from '@apollo/client'
import { StrictTypedTypePolicies } from './apolloHelpers'

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      objects: {
        keyArgs: ['userId', 'filters'],
        merge: itemsPaginationMergeFunction
      },
      sales: {
        keyArgs: ['userId', 'filters'],
        merge: itemsPaginationMergeFunction
      },
      getDeposits: {
        keyArgs: ['statuses'],
        merge: itemsPaginationMergeFunction
      },
      getRemovals: {
        keyArgs: ['filters'],
        merge: itemsPaginationMergeFunction
      },
      getBillings: {
        keyArgs: ['filters'],
        merge: itemsPaginationMergeFunction
      }
    }
  },
  ObjectEntity: {
    // If I use [] as my keyFields, cache gets corrupted, even though the empty array is supposed to work the same as `false` as per the docs
    // If I instead use or ['id'] or ['guid'] or ['id', 'guid'], it still doesn't work, and I get the same error as before, even though they are unique values
    keyFields: false
  }
}

export const apolloCache = new InMemoryCache({ typePolicies })
