import { Reducer } from 'redux'
import { GetTripsMetricsTypes, TripsMetricsActions, TripsMetricsState } from '../types/tripsMetrics'

const initialState: TripsMetricsState = {
  quantityMetrics: [],
  fetchingMetrics: false
}

const tripsMetricsReducer: Reducer<TripsMetricsState, TripsMetricsActions> = (
  state = initialState,
  action
): TripsMetricsState => {
  switch (action.type) {
    case GetTripsMetricsTypes.GET_TRIPS_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetTripsMetricsTypes.GET_TRIPS_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        quantityMetrics: action.payload.quantityMetrics
      }
    case GetTripsMetricsTypes.GET_TRIPS_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default tripsMetricsReducer
