export enum Steps {
  SELECTION = 0,
  EXTRAS = 1,
  ADDRESS = 2,
  FINISH_SUMMARY = 3,
  PAYMENT = 4
}

export enum PaymentScreens {
  PAYMENT_SELECTION = 'paymentSelection',
  NEW_CARD = 'newCard'
}

export const ANTICIPATED_HOURS = 24
export const SERVICE_TYPE = 'items'
