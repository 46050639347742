import { Reducer } from 'redux'
import {
  ABMVehiclesActions,
  ABMVehiclesModalState,
  CreateVehicleTypes,
  DeleteVehicleTypes,
  EditVehicleTypes,
  GetProvidersTypes,
  SettersVehiclesModalTypes
} from '../types/ABMVehicles'
import moment from 'moment'

const defaultSelectedVehicle = {
  name: '',
  maxCapacity: 0,
  usableCapacity: 0,
  maxHeight: 0,
  maxWidth: 0,
  maxLength: 0,
  maxWeight: 0,
  providerId: 0
}

const initialState: ABMVehiclesModalState = {
  editMode: false,
  modalVisible: false,
  loadingProviders: false,
  providers: [],
  id: 0,
  deleteModalVisible: false,
  creatingVehicle: false,
  updatingVehicle: false,
  deletingVehicle: false,
  disabledDeleteButton: false,
  selectedVehicle: defaultSelectedVehicle,
  selectedVehicleToDelete: null
}

export const ABMVehicleModalReducer: Reducer<ABMVehiclesModalState, ABMVehiclesActions> = (
  state: ABMVehiclesModalState = initialState,
  action
): ABMVehiclesModalState => {
  switch (action.type) {
    case SettersVehiclesModalTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible, editMode } = action.payload

      return modalVisible
        ? {
            ...state,
            editMode: editMode ?? false,
            modalVisible
          }
        : initialState
    }
    case SettersVehiclesModalTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisibility, vehicle = null } = action.payload

      return deleteModalVisibility
        ? {
            ...state,
            deleteModalVisible: deleteModalVisibility,
            selectedVehicleToDelete: vehicle
          }
        : initialState
    }
    case SettersVehiclesModalTypes.SET_VEHICLE_FIELD: {
      const { field, value } = action.payload
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          [field]: value
        }
      }
    }
    case SettersVehiclesModalTypes.SET_SELECTED_VEHICLE: {
      const { id, name, maxCapacity, maxHeight, maxLength, maxWeight, maxWidth, usableCapacity, provider } =
        action.payload.selectedVehicle
      return {
        ...state,
        id,
        providers: [
          {
            ...provider,
            createdAt: moment(provider.createdAt),
            updatedAt: moment(provider.updatedAt),
            deletedAt: moment(provider.deletedAt)
          }
        ],
        selectedVehicle: {
          name,
          maxCapacity,
          maxHeight,
          maxLength,
          maxWeight,
          maxWidth,
          usableCapacity,
          providerId: provider.id
        }
      }
    }
    case CreateVehicleTypes.CREATE_VEHICLE_REQUEST:
      return {
        ...state,
        creatingVehicle: true
      }
    case CreateVehicleTypes.CREATE_VEHICLE_SUCCESS:
    case CreateVehicleTypes.CREATE_VEHICLE_FAILURE:
      return {
        ...state,
        creatingVehicle: false
      }
    case EditVehicleTypes.EDIT_VEHICLE_REQUEST:
      return {
        ...state,
        updatingVehicle: true
      }
    case EditVehicleTypes.EDIT_VEHICLE_SUCCESS:
    case EditVehicleTypes.EDIT_VEHICLE_FAILURE:
      return {
        ...state,
        updatingVehicle: false
      }
    case DeleteVehicleTypes.DELETE_VEHICLE_REQUEST:
      return {
        ...state,
        deletingVehicle: true
      }
    case DeleteVehicleTypes.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        deletingVehicle: false
      }
    case DeleteVehicleTypes.DELETE_VEHICLE_FAILURE:
      return {
        ...state,
        deletingVehicle: false,
        disabledDeleteButton: true
      }
    case GetProvidersTypes.FETCH_PROVIDERS_REQUEST:
      return { ...state, loadingProviders: true }
    case GetProvidersTypes.FETCH_PROVIDERS_SUCCESS:
      return { ...state, loadingProviders: false, providers: action.payload.providers }
    case GetProvidersTypes.FETCH_PROVIDERS_FAILURE:
      return { ...state, loadingProviders: false }
    default:
      return state
  }
}

export default ABMVehicleModalReducer
