import React from 'react'
import { Button } from 'antd'
import styles from './searchUser.module.scss'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'
import { TextField } from '../../../components/TextField/TextField'
import { handleErrorNumberFields } from '../../NewProduct/utils'
import { useHistory } from 'react-router'

type SearchUserProps = {
  searchUserActions: typeof searchUserActionCreators
  error: string
  loading: boolean
  userColppyInfo: UserColppy | null
}

export const handleFieldChange = (value: string, callback: (value: string) => void) => {
  if (isNaN(Number(value))) return
  if (Number(value) < 0) return
  callback(value)
}

const SearchUser = ({ searchUserActions, loading }: SearchUserProps) => {
  const [userID, setUserID] = React.useState<string>('')
  const history = useHistory()

  const onSearchButtonClick = async () => {
    const result = await searchUserActions.getInfoAboutUserHasDebts(Number(userID))
    // @ts-ignore
    if (result) history.push({ pathname: '/billings_payments_list', search: `?id=${userID}` })
  }

  return (
    <div className={styles.container}>
      <div className={styles.child}>
        <h3>Ingresá un ID de usuario para acceder al detalle de pagos</h3>
        <div>
          <div>
            <TextField
              label="ID Cliente"
              onChange={(value) => handleFieldChange(value, setUserID)}
              value={userID}
              error={handleErrorNumberFields(userID)}
            />
          </div>
          <Button
            loading={loading}
            disabled={!userID}
            className={styles.buttonSubmit}
            onClick={onSearchButtonClick}
            type="primary">
            Continuar
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SearchUser
