export enum SalesRequestEventsEnum {
  REJECT_REQUEST = 'SALE_REQUEST/REJECT_REQUEST',
  ACCEPT_PROPOSAL = 'SALE_REQUEST/ACCEPT_PROPOSAL',
  COUNTER_PROPOSAL = 'SALE_REQUEST/COUNTER_PROPOSAL'
}

export type SalesRequestEvents = {
  [SalesRequestEventsEnum.REJECT_REQUEST]: () => void
  [SalesRequestEventsEnum.ACCEPT_PROPOSAL]: () => void
  [SalesRequestEventsEnum.COUNTER_PROPOSAL]: () => void
}
