import styles from './MultiStatsCard.module.scss'
import React from 'react'

interface MultiStatsCardProps {
  children: React.ReactNode[]
  backgroundColor?: string
  borderColor?: string
}

export const MultiStatsCards: React.FC<MultiStatsCardProps> = ({
  children,
  backgroundColor = '#FFFAFA',
  borderColor = '#cc6266'
}) => {
  return (
    <div className={` ${styles.cardContainer}`} style={{ backgroundColor, borderColor }}>
      {children.map((child, index) => (
        <React.Fragment key={index}>
          {child}
          {index < children.length - 1 && (
            <span className={styles.divider} style={{ backgroundColor: borderColor }}></span>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

interface StatsCardItemProps {
  textColor?: string
  fontWeight?: string
  titleSize?: string
  valueSize?: string
  total: number
  title: string
}

export const StatsCardItem: React.FC<StatsCardItemProps> = ({
  fontWeight = 600,
  textColor = '#cc6266',
  titleSize = '12px',
  valueSize = '24px',
  total,
  title
}) => {
  return (
    <div className={styles.content}>
      <p style={{ fontSize: titleSize, color: textColor }}>{title}</p>
      <p style={{ fontSize: valueSize, fontWeight, color: textColor }}>{total}</p>
    </div>
  )
}
