import { AxiosResponse } from 'axios'
import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_PROFILE } from '../../../endpoints'
import { convertToMoment } from '../../utils'

export type APICard = {
  ID: number
  CreatedAt: string
  UpdatedAt: string | null
  DeletedAt: string | null
  Token: string
  UserID: number
  MpCardID: string
  Main: boolean
  LastDigits: string
  Type: string
  TypePhoto: string
  Expiration: string
  ExpirationDate: string
  PaymentType: string
  IsFromSpreedly: boolean
}

export type Card = {
  cardId: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  token: string
  userID: number
  mpCardID: string
  main: boolean
  lastDigits: string
  type: string
  typePhoto: string
  expiration: string
  expirationDate: Moment | null
  paymentType: string
  isFromSpreedly: boolean
}

interface Response {
  payments: Card[]
}

interface APIResponse {
  description: APICard[]
  success: boolean
}

interface ListParams {
  userId: string
}

export function list(params: ListParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_PROFILE}/payment_methods/cards?user_id=${params.userId}`)
    .then((response: AxiosResponse<APIResponse>) => {
      const responseCards = response.data.description

      const camelResponse: Response = {
        payments: responseCards.map((card) => ({
          cardId: card.ID,
          createdAt: convertToMoment(card.CreatedAt),
          updatedAt: convertToMoment(card.UpdatedAt),
          deletedAt: convertToMoment(card.DeletedAt),
          token: card.Token,
          userID: card.UserID,
          mpCardID: card.MpCardID,
          main: card.Main,
          lastDigits: card.LastDigits,
          type: card.Type,
          typePhoto: card.TypePhoto,
          expiration: card.Expiration,
          expirationDate: convertToMoment(card.ExpirationDate),
          paymentType: card.PaymentType,
          isFromSpreedly: card.IsFromSpreedly
        }))
      }

      return camelResponse
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
