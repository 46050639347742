import React from 'react'
import { Modal, Button } from 'antd'
import attributeModalActions from '../actions/attributeModal'
import { Attribute, AttributeModalState, ProductAttribute } from '../types/attributeModal'
import Select from '../../../components/Select'
import { BaseOption } from '../../../components/Select/Select'
import { isNil } from 'ramda'
import InputGroup from '../../../components/InputGroup'
import { TextField } from '../../../components/TextField/TextField'

const styles = {
  modal: {
    maxWidth: 500
  },
  error: {
    color: 'red',
    marginTop: 6
  },
  placeholder: {
    display: 'inline-block'
  },
  inlineText: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '196px',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  divText: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center'
  },
  inputTextContainer: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column' as const
  }
}
export interface AttributeModalProps {
  attributeModalState: AttributeModalState
  attributesModalActions: typeof attributeModalActions
}

const getTextItem = (item: Attribute) =>
  `${item.id} - ${item.name} - ${item.dataType} - ${item.isVisible ? 'Visible' : 'Invisible'}`

export const getFormattedOptions = (items: Attribute[]): BaseOption<string>[] =>
  items.map((item) => ({
    value: item.id.toString() || '',
    label: getTextItem(item) || ''
  }))

export const getFormattedSelectedOption = (selectedItem: Attribute | null): BaseOption<string> | null =>
  !isNil(selectedItem) ? { value: selectedItem?.id.toString(), label: selectedItem?.name } : null

const AttributeModalComponent = (props: AttributeModalProps) => {
  const { attributeModalState, attributesModalActions } = props
  const {
    clearState,
    closeAttributeModal,
    getAttributesBySearch,
    AddAttribute,
    removeAttribute,
    updateProductAttributes
  } = attributesModalActions
  const { open, error, attributes, productAttributes, loading, productId } = attributeModalState

  const [autoComplete, setAutoComplete] = React.useState('')
  const [selectedAttribute, setSelectedAttribute] = React.useState<Attribute | null>(null)

  const onInputChange = (name: string) => {
    getAttributesBySearch(name, { page: 1, pageSize: 10, total: 100 })
  }

  const options = getFormattedOptions(
    attributes.filter((x) => !productAttributes.find((y) => y.attribute.id === x.id)) || []
  )
  const selectedOption = getFormattedSelectedOption(selectedAttribute)
  const onSelectItem = (option: BaseOption<string>) =>
    option && setSelectedAttribute(attributes.find((x) => x.id.toString() === option.value) || null)

  const handleAddNewAttribute = (atrribute: Attribute) => {
    AddAttribute({
      attribute: atrribute,
      isNew: true
    })
    setSelectedAttribute(null)
  }

  const handleSubmitModal = (productId: number, productAttributes: ProductAttribute[]) => {
    updateProductAttributes({ productId, productAttributes })
  }

  return (
    <Modal
      title="Atributos de producto"
      visible={open}
      onCancel={() => {
        closeAttributeModal()
        setSelectedAttribute(null)
      }}
      onOk={() => {
        if (selectedAttribute) {
          handleSubmitModal(productId!, [
            ...productAttributes,
            {
              attribute: selectedAttribute,
              isNew: true
            }
          ])
        } else {
          handleSubmitModal(productId!, productAttributes)
        }
        setSelectedAttribute(null)
      }}
      okText="Editar"
      cancelText="Cancelar"
      style={styles.modal}>
      <div>
        {productAttributes.length > 0 && (
          <InputGroup title="Atributos" style={{ paddingLeft: 16, paddingRight: 16 }}>
            <div style={styles.inputTextContainer}>
              {productAttributes.map((x, index) => {
                if (x.deleted === true) return false
                return (
                  <div key={x.attribute.id + index} style={styles.divText}>
                    <TextField label="Atributo" disabled={true} onChange={() => {}} value={getTextItem(x.attribute)} />
                    <Button
                      onClick={() => removeAttribute(x.attribute.id)}
                      loading={false}
                      icon="minus"
                      type="primary"
                      size="small"
                    />
                  </div>
                )
              })}
            </div>
          </InputGroup>
        )}
        <InputGroup title="Nuevo Atributo" style={{ paddingLeft: 16, paddingRight: 16 }}>
          <div style={styles.divText}>
            <Select
              placeholder="Seleccionar Atributo"
              selected={selectedOption}
              onDelete={() => setSelectedAttribute(null)}
              onSelect={onSelectItem}
              autocomplete={{
                filterByInput: true,
                input: autoComplete,
                onInputChange: (text) => {
                  setAutoComplete(text)
                  onInputChange(text)
                }
              }}
              loading={loading}
              options={options}
            />
            <Button
              onClick={() => handleAddNewAttribute(selectedAttribute!)}
              loading={false}
              disabled={selectedAttribute === null}
              icon="plus"
              type="primary"
              size="small"
            />
          </div>
        </InputGroup>
      </div>
      {error !== '' ? <div style={styles.error}>{error}</div> : null}
    </Modal>
  )
}

export default AttributeModalComponent
