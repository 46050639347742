import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { APIProduct, Product, formatProducts, FormattedProduct } from './common'
import snakecaseKeys from 'snakecase-keys'

interface APIResponse {
  products: APIProduct[]
  quantity: number
}

type Response = {
  products: FormattedProduct[]
  total: number
}

export type ProductsListCategoryFilterKey = 'showInSearch' | 'deprecated'

export type ProductListSortKey = 'id'

interface ListParams {
  name?: string
  countryCode: string
  mt3?: number
  userId?: number
  id?: number
  column?: string
  order?: string
  limit?: number
  offset?: number
  guids?: string[]
  deprecated?: string
  categoryId?: number
  serviceTypeId?: string
}

export function list(params: ListParams): Promise<Response> {
  const convertedParams = snakecaseKeys({ ...params }, { deep: true })
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/search/product/v2`, { params: convertedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.products) throw new Error('Respuesta incorrecta al obtener los productos')

      const camelCasedProducts: Product[] = camelcaseKeys(response.data.products, {
        deep: true
      })

      const camelResponse: Response = {
        total: response.data.quantity,
        products: formatProducts(camelCasedProducts)
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
