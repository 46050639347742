import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ModalComponent from '../components/cardsModal'
import actions from '../actionCreators/cardModalActions'
import creditCardActionsCreator from '../../../components/CreditCard/actions'
import { useFeatureManager } from '../../featureManager/utils'
import { FeatureType } from '../../featureManager/types'
import { Events } from '../../../utils/eventEmitter/events'
import { useEvents } from '../../../utils/eventEmitter'

type CardsModalContainerProps = {
  country: string
}

const CardsModalContainer = (ownProps: CardsModalContainerProps) => {
  const dispatch = useDispatch()
  const cardsModalActions = bindActionCreators(actions, dispatch)
  const creditCardActions = bindActionCreators(creditCardActionsCreator, dispatch)
  const spreedlyFeature = useFeatureManager(FeatureType.SPREEDLY_PAYMENT)
  const creditCardState = useSelector((state) => state.Components.CreditCard)
  const { userId } = useSelector((state) => state.Cards.table)
  const { open, loading } = useSelector((state) => state.Cards.modal)

  const { cardNumber, expirationDate, cvc, cardOwnerName, documentType, documentNumber, errors } = creditCardState

  const { setLoading, clearState } = creditCardActions

  const { setModalOpen, saveCard, saveSpreedlyCard } = cardsModalActions

  const handleClose = () => {
    setModalOpen(false)
    clearState()
  }
  const hasData =
    Boolean(cardNumber) &&
    Boolean(expirationDate) &&
    Boolean(cvc) &&
    Boolean(cardOwnerName) &&
    Boolean(documentType) &&
    Boolean(documentNumber)

  const okDisabled = loading || Boolean(Object.keys(errors).length) || !hasData

  useEvents(Events.CreditCardComponent.CLEAR_STATE, () => clearState())

  const onOk = () => {
    setLoading(true)
    const cardData = {
      userId,
      cardNumber,
      expirationDate,
      CVC: cvc,
      cardOwnerName,
      documentType,
      documentNumber: documentNumber.replaceAll(' ', ''),
      country: ownProps.country
    }
    if (spreedlyFeature) {
      saveSpreedlyCard(cardData)
    } else {
      saveCard(cardData)
    }
    setLoading(false)
  }

  const props = {
    open,
    okDisabled,
    handleClose,
    onOk
  }

  return <ModalComponent {...props} />
}

export default CardsModalContainer
