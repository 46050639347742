import { API } from '../../../projectApi'
import { Provider } from '../../../projectApi/Trips/Providers/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  SettersProvidersModalTypes,
  SetProviderName,
  SetModalVisibility,
  CreateProviderRequest,
  CreateProviderTypes,
  CreateProviderSuccess,
  DeleteProviderRequest,
  DeleteProviderTypes,
  DeleteProviderSuccess,
  SetDeleteModalVisibility,
  EditProviderRequest,
  EditProviderTypes,
  EditProviderSuccess,
  SetSelectedProvider
} from '../types/ABMProviders'

const ABMProvidersActionsCreator = {
  createProvider({ providerName }: { providerName: string }): AppThunk {
    return async (dispatch) => {
      const request: CreateProviderRequest = {
        type: CreateProviderTypes.CREATE_PROVIDER_REQUEST
      }

      const toastId = setToastLoading('Creando proveedor, por favor espere...')

      dispatch(request)
      try {
        await API.Trips.Providers.create({
          name: providerName
        })
        const success: CreateProviderSuccess = {
          type: CreateProviderTypes.CREATE_PROVIDER_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Provider.CREATE_PROVIDER)
        setToastSuccessUpdate(toastId, { render: 'Proveedor creado correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, CreateProviderTypes.CREATE_PROVIDER_FAILURE, dispatch) ??
            'Error al intentar crear el proveedor.'
        })
      }
    }
  },

  deleteProvider({ providerId }: { providerId: number }): AppThunk {
    return async (dispatch) => {
      const request: DeleteProviderRequest = {
        type: DeleteProviderTypes.DELETE_PROVIDER_REQUEST
      }

      const toastId = setToastLoading('Eliminando proveedor, por favor espere...')

      dispatch(request)
      try {
        await API.Trips.Providers.delete({ providerId })

        const success: DeleteProviderSuccess = {
          type: DeleteProviderTypes.DELETE_PROVIDER_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Provider.DELETE_PROVIDER)
        setToastSuccessUpdate(toastId, { render: 'El proveedor se eliminó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, DeleteProviderTypes.DELETE_PROVIDER_FAILURE, dispatch) ??
            'Error al intentar eliminar el proveedor.'
        })
      }
    }
  },

  editProvider({ providerName, providerId }: { providerId: number; providerName: string }): AppThunk {
    return async (dispatch) => {
      const request: EditProviderRequest = {
        type: EditProviderTypes.EDIT_PROVIDER_REQUEST
      }

      const toastId = setToastLoading('Editando proveedor, por favor espere...')

      dispatch(request)
      try {
        await API.Trips.Providers.update({
          providerId,
          name: providerName
        })

        const success: EditProviderSuccess = {
          type: EditProviderTypes.EDIT_PROVIDER_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Provider.EDIT_PROVIDER)
        setToastSuccessUpdate(toastId, { render: 'El proveedor se editó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, EditProviderTypes.EDIT_PROVIDER_FAILURE, dispatch) ??
            'Error al intentar editar el proveedor.'
        })
      }
    }
  },

  setModalVisibility({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }): SetModalVisibility {
    return {
      type: SettersProvidersModalTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible, editMode }
    }
  },

  setDeleteModalVisibility(deleteModalVisibility: boolean): SetDeleteModalVisibility {
    return {
      type: SettersProvidersModalTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisibility }
    }
  },

  setProviderName(providerName: string): SetProviderName {
    return {
      type: SettersProvidersModalTypes.SET_PROVIDER_NAME,
      payload: { providerName }
    }
  },

  setSelectedProvider(selectedProvider: Provider): SetSelectedProvider {
    return {
      type: SettersProvidersModalTypes.SET_SELECTED_PROVIDER,
      payload: { selectedProvider }
    }
  }
}

export default ABMProvidersActionsCreator
