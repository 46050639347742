export interface BillPeriodState {
  modalOpen: boolean
  billingToBillTotal: number
  error: string
  loadingBillingToBill: boolean
  loadingBillPeriod: boolean
}
export const OPEN_MODAL_BILL_PERIOD = 'BILLINGS/BILL_PERIOD/OPEN_MODAL_BILL_PERIOD'

export interface OpenModalBillPeriod {
  type: typeof OPEN_MODAL_BILL_PERIOD
  payload: {
    modalOpen: boolean
  }
}

export const SET_BILLING_ID = 'BILLINGS/BILLINGS_BY_CLIENT/PAYMENT/SET_BILLING_ID'

export const GET_BILLINGS_TO_BILL_REQUEST = 'BILLINGS/BILL_PERIOD/GET_BILLLINGS_TO_BILL_REQUEST'
export const GET_BILLINGS_TO_BILL_SUCCESS = 'BILLINGS/BILL_PERIOD/GET_BILLLINGS_TO_BILL_SUCCESS'
export const GET_BILLINGS_TO_BILL_FAILURE = 'BILLINGS/BILL_PERIOD/GET_BILLLINGS_TO_BILL_FAILURE'

export interface GetBillingsToBillRequest {
  type: typeof GET_BILLINGS_TO_BILL_REQUEST
}

export interface GetBillingsToBillSuccess {
  type: typeof GET_BILLINGS_TO_BILL_SUCCESS
  payload: {
    billingToBillTotal: number
  }
}

export interface GetBillingsToBillFailure {
  type: typeof GET_BILLINGS_TO_BILL_FAILURE
  payload: {
    error: string
  }
}

export const BILL_PERIOD_REQUEST = 'BILLINGS/BILL_PERIOD/BILL_PERIOD_REQUEST'
export const BILL_PERIOD_SUCCESS = 'BILLINGS/BILL_PERIOD/BILL_PERIOD_SUCCESS'
export const BILL_PERIOD_FAILURE = 'BILLINGS/BILL_PERIOD/BILL_PERIOD_FAILURE'

export interface BillPeriodRequest {
  type: typeof BILL_PERIOD_REQUEST
}

export interface BillPeriodSuccess {
  type: typeof BILL_PERIOD_SUCCESS
}

export interface BillPeriodFailure {
  type: typeof BILL_PERIOD_FAILURE
  payload: {
    error: string
  }
}

export type BillPeriodModalAction =
  | OpenModalBillPeriod
  | GetBillingsToBillRequest
  | GetBillingsToBillSuccess
  | GetBillingsToBillFailure
  | BillPeriodRequest
  | BillPeriodSuccess
  | BillPeriodFailure
