import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

type providerUpdateParams = {
  name: string
  providerId: number
}

export function update(params: providerUpdateParams): Promise<void> {
  const formattedParams = snakecaseKeys(params, { deep: true })
  return apiPrivate
    .put(`${URL_BASE_TRIPS}/providers/${params.providerId}`, formattedParams, {
      headers: { 'x-origin': 'backoffice/adm-web' }
    })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
