import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import PaymentModalActionCreator from '../actionCreators/paymentModal'
import { PaymentModal, PaymentModalProps } from '../components/PaymentModal'

const PaymentModalContainer = () => {
  const dispatch = useDispatch()

  const paymentModalActions = bindActionCreators(PaymentModalActionCreator, dispatch)

  const { paymentModal } = useSelector((state) => state.BillingV2)

  const { modalOpen: openModal, loading, billing, error } = paymentModal

  const handlePayment = () => {
    if (billing) {
      paymentModalActions.payBilling(billing?.id)
    }
  }

  const props: PaymentModalProps = {
    modalOpen: openModal,
    loading,
    handlePayment,
    onCancel: () => paymentModalActions.openPaymentModal({ modalOpen: false }),
    billing,
    error
  }

  return <PaymentModal {...props} />
}

export default PaymentModalContainer
