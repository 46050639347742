import React from 'react'
import BulkTransferModal from '../components/bulkTransfer'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import BulkTransferActionCreator from '../actionCreators/bulkTransfer'

const BulkTransferContainer = () => {
  const dispatch = useDispatch()
  const TableState = useSelector((root) => root.CorporativeObjects.table)
  const BulkTransferState = useSelector((state) => state.CorporativeObjects.transfer)
  const BulkTransferActions = bindActionCreators(BulkTransferActionCreator, dispatch)

  const { isOpen, loadingTransferObject, locationId } = BulkTransferState
  const { setOpenBulkTransferModal, setNumberField, transferObjects } = BulkTransferActions

  const handleOpenModal = () => {
    setOpenBulkTransferModal(!isOpen)
    setNumberField({ field: 'depositId', value: 0 })
    setNumberField({ field: 'locationId', value: 0 })
  }

  const handleTransferObjects = async () => {
    if (!loadingTransferObject) {
      handleOpenModal()
    }
    transferObjects(TableState.selectedObjects, locationId)
  }

  const props = { BulkTransferState, BulkTransferActions, TableState, handleTransferObjects, handleOpenModal }

  return <BulkTransferModal {...props} />
}

export default BulkTransferContainer
