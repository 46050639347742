import React from 'react'
import { Modal } from 'antd'
import { UserABMTypes } from '../types/userABM'
import styles from './UserInviteModal.module.scss'
import InputGroup from '../../../../components/InputGroup'
import { Row } from '../../../NewProduct/components/subcomponents/GeneralData'
import { AppThunk } from '../../../../store'

type UserDeleteInviteModalProps = {
  userABMState: UserABMTypes
  closeModal: () => void
  deleteInvitation: ({ guestId }: { guestId: number }) => AppThunk<Promise<boolean>>
}

const UserDeleteInviteModal = (props: UserDeleteInviteModalProps) => {
  const { userABMState, closeModal, deleteInvitation } = props
  const { error, isLoading, isDeleteInviteModalOpen, selectedGuestUser } = userABMState

  const onOkClick = async () => {
    if (selectedGuestUser) {
      const result = await deleteInvitation({ guestId: selectedGuestUser?.id })
      // @ts-ignore
      if (result) closeModal()
    }
  }

  const handleCloseModal = () => {
    closeModal()
  }

  return (
    <Modal
      width={560}
      title="Eliminar invitación"
      visible={isDeleteInviteModalOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={handleCloseModal}
      okButtonProps={{
        className: styles.modalButtonOk,
        disabled: isLoading || selectedGuestUser?.used
      }}
      okText={'Eliminar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <InputGroup title="Información del usuario" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Row className={styles.row}>
          <p
            className={
              styles.centerText
            }>{`¿Está seguro que desea eliminar la invitación de: ${selectedGuestUser?.email}?`}</p>
        </Row>
      </InputGroup>
      {error && <div className={styles.errorContainer}>{error}</div>}
    </Modal>
  )
}

export default UserDeleteInviteModal
