export enum ShippersEventsEnum {
  CLEAN_FILTERS = 'CLEAN_FILTERS',
  CREATE_SHIPPER = 'CREATE_SHIPPER',
  UPDATE_SHIPPER = 'UPDATE_SHIPPER',
  DELETE_SHIPPER = 'DELETE_SHIPPER'
}

export type ShippersEvents = {
  [ShippersEventsEnum.CLEAN_FILTERS]: () => void
  [ShippersEventsEnum.CREATE_SHIPPER]: () => void
  [ShippersEventsEnum.UPDATE_SHIPPER]: () => void
  [ShippersEventsEnum.DELETE_SHIPPER]: () => void
}
