import { AxiosResponse } from 'axios'
import moment, { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TIMESLOT } from '../../../endpoints'

interface Response {
  unavailableDates: Moment[]
}

interface APIResponse {
  description: string[] // TODO needs to be tested, api keeps returning an empty array
  success: boolean
}

interface ListParams {
  daysOnwards: number
}

export function dates(params: ListParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TIMESLOT}/timeSlot/occupiedDates?daysOnwards=${params.daysOnwards}`)
    .then((response: AxiosResponse<APIResponse>) => {
      const responseDates = response.data.description

      const formatResponse: Response = {
        unavailableDates: responseDates.map((date) => moment(date))
      }

      return formatResponse
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
