import React, { Component } from 'react'
import PaymentsTable from './PaymentsTable'
import NewPaymentModal from './NewPaymentModal'
import actions from './Payments.actions'
import userActions from '../Users/Users.actions'
import { connect } from 'react-redux'
import moment from 'moment'
import { eqProps, equals, isEmpty } from 'ramda'
import PropTypes, { shape } from 'prop-types'
import DeletePaymentModal from './DeletePaymentModal'
import { getPaymentMethodLabels } from '../Users/Users.selectors'
import { getNewFilterParams } from '../../utils/formatParams'
import { TableActionBar } from '../../components/TableActionBar'

moment.locale('es')

class Payments extends Component {
  componentDidMount() {
    const { getCharges, params, paymentMethods, getPaymentMethods } = this.props
    getCharges(params)
    if (paymentMethods.length === 0) getPaymentMethods()
  }

  handleSearch = (dataIndex, text, confirm) => {
    const {
      params,
      pagination: currentPagination,
      searchFilters: currentSearchFilters,
      setSearchFilters,
      setPagination,
      getCharges
    } = this.props
    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text
          }
        : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    getCharges(newParams)

    confirm()
  }

  handleChange = (pagination, filters, sorter, _) => {
    const {
      filter: currentFilter,
      sorter: currentSorter,
      pagination: currentPagination,
      getCharges,
      params,
      setFilter,
      setSorter,
      setPagination,
      clientSearch
    } = this.props
    if (!equals(filters, currentFilter)) {
      const newParams = getNewFilterParams(params, filters)

      setFilter(filters)
      setPagination({ ...currentPagination, current: 1 })
      getCharges(newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order, field } = sorter
      const Order = this.getOrder(order)
      const Column = this.getColumn(field)
      const Offset = 0

      setSorter(sorter)
      setPagination({ ...currentPagination, current: 1 })
      getCharges({ ...params, Offset, Column, Order })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current, pageSize } = pagination
      const Offset = (current - 1) * pageSize
      const newParams = { ...params, Offset }

      setPagination(pagination)
      getCharges(newParams)
    }
  }

  getOrder(order) {
    return order === 'descend' ? 'desc' : 'asc'
  }

  getColumn(column) {
    switch (column) {
      case 'ID':
        return 'id'
      case 'Type':
        return 'type'
      case 'User.ID':
        return 'user_id'
      case 'Amount':
        return 'amount'
      case 'PaymentMethod':
        return 'payment_method'
      case 'DayToCharge':
        return 'day_to_charge'
      case 'StartDate':
        return 'start_date'
      case 'EndDate':
        return 'end_date'
      default:
        return column
    }
  }

  render() {
    const {
      params,
      loadingTable,
      charges,
      paymentMethods,
      history,
      setFilter,
      setSorter,
      pagination,
      getCharges,
      cleanFilters,
      setPaymentMethod,
      filter,
      searchFilters,
      setCreateModalOpen,
      charge,
      newCharge,
      setAmount,
      setDayToCharge,
      setStartDate,
      setEndDate,
      setType,
      setUserID,
      getUsers,
      deleteChargeObj,
      deleteCharge,
      setDeleteModalOpen
    } = this.props
    const { createModalOpen, loading } = charge

    return (
      <div className="animated fadeIn">
        <TableActionBar hideBorder>
          <TableActionBar.ButtonAction
            variant="cleanFilters"
            onClickButton={() => cleanFilters().then(() => getCharges(params))}
          />
          <TableActionBar.ButtonAction titleButton="Crear regla" onClickButton={() => setCreateModalOpen(true)} />
        </TableActionBar>

        <PaymentsTable
          charges={charges}
          params={params}
          paymentMethods={paymentMethods}
          handleChange={this.handleChange}
          loading={loadingTable}
          setFilter={setFilter}
          history={history}
          getCharges={getCharges}
          setSorter={setSorter}
          setDeleteModalOpen={setDeleteModalOpen}
          currentPagination={pagination}
          filter={filter}
          searchFilters={searchFilters}
          handleSearch={this.handleSearch}
        />

        <NewPaymentModal
          paymentMethods={paymentMethods}
          open={createModalOpen}
          charge={charge}
          loading={loading}
          params={params}
          newCharge={newCharge}
          getUsers={getUsers}
          setPaymentMethod={setPaymentMethod}
          setCreateModalOpen={setCreateModalOpen}
          setAmount={setAmount}
          setDayToCharge={setDayToCharge}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setType={setType}
          setUserID={setUserID}
        />

        <DeletePaymentModal
          deleteChargeObj={deleteChargeObj}
          deleteCharge={deleteCharge}
          setDeleteModalOpen={setDeleteModalOpen}
        />
      </div>
    )
  }
}

Payments.propTypes = {
  charges: PropTypes.array.isRequired,
  params: PropTypes.shape({
    Limit: PropTypes.number,
    Offset: PropTypes.number,
    Column: PropTypes.string,
    Order: PropTypes.string
  }).isRequired,
  loadingTable: PropTypes.bool.isRequired,
  filter: PropTypes.shape().isRequired,
  sorter: PropTypes.shape().isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  searchFilters: PropTypes.arrayOf(shape()),
  charge: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    userID: PropTypes.number,
    type: PropTypes.number,
    amount: PropTypes.number,
    dayToCharge: PropTypes.number,
    paymentMethod: PropTypes.number,
    startDate: PropTypes.shape(),
    endDate: PropTypes.shape()
  }).isRequired,
  deleteChargeObj: PropTypes.shape({
    selectedCharge: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    deleteModalOpen: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  paymentMethods: PropTypes.array,
  getPaymentMethods: PropTypes.func,
  newCharge: PropTypes.func.isRequired,
  cleanFilters: PropTypes.func.isRequired,
  getCharges: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setSorter: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  setCreateModalOpen: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  setDayToCharge: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setUserID: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  setDeleteModalOpen: PropTypes.func.isRequired,
  deleteCharge: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { Payments, Users } = state
  const { table, charge, deleteCharge, userSearch } = Payments
  const { paymentMethods } = Users

  return {
    paymentMethods: getPaymentMethodLabels(paymentMethods.paymentMethods),
    deleteChargeObj: {
      ...deleteCharge.toObject(),
      selectedCharge: deleteCharge.get('selectedCharge').toObject()
    },
    loadingTable: table.get('loading'),
    charges: table.get('charges').toArray(),
    params: table.get('params').toObject(),
    sorter: table.get('sorter').toObject(),
    filter: table.get('filter').toObject(),
    searchFilters: table.get('searchFilters').toArray(),
    pagination: table.get('pagination').toObject(),
    charge: {
      ...charge.toObject(),
      ...userSearch
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cleanFilters: () => dispatch(actions.cleanFilters()),
    getCharges: (params) => dispatch(actions.getCharges(params)),
    getPaymentMethods: () => dispatch(userActions.getPaymentMethods()),
    getUsers: (key, value) => dispatch(actions.getUsers(key, value)),
    newCharge: (charge) => dispatch(actions.newCharge(charge)),
    deleteCharge: (id) => dispatch(actions.deleteCharge(id)),
    setPaymentMethod: (paymentMethod) => dispatch(actions.setPaymentMethod(paymentMethod)),
    setFilter: (filter) => dispatch(actions.setFilter(filter)),
    setSorter: (sorter) => dispatch(actions.setSorter(sorter)),
    setPagination: (pagination) => dispatch(actions.setPagination(pagination)),
    setSearchFilters: (searchFilters) => dispatch(actions.setSearchFilters(searchFilters)),
    setCreateModalOpen: (bool) => dispatch(actions.setCreateModalOpen(bool)),
    setType: (type) => dispatch(actions.setType(type)),
    setUserID: (userID) => dispatch(actions.setUserID(userID)),
    setAmount: (amount) => dispatch(actions.setAmount(amount)),
    setDayToCharge: (dayToChange) => dispatch(actions.setDayToCharge(dayToChange)),
    setStartDate: (startDate) => dispatch(actions.setStartDate(startDate)),
    setDeleteModalOpen: (deleteModalOpen, selectedCharge) =>
      dispatch(actions.setDeleteModalOpen(deleteModalOpen, selectedCharge)),
    setEndDate: (endDate) => dispatch(actions.setEndDate(endDate))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments)
