import React, { ReactNode, useMemo } from 'react'
import styles from './CountrySelector.module.scss'
import Select from '../../../components/Select'
import { Country, CountryIdCode, allCountriesOption } from '../constants/constants'
import actions from '../actions/CountrySelector'
import styled from 'styled-components'
import { FlagComponent, formatCountryOptions } from './utils'
import { CountrySelectorState } from '../types/CountrySelector'
import { Permissions } from '../../../sections/UserLogged/permissions'
import { BaseOption } from '../../Select/Select'
import { useLocation } from 'react-router'

type OptionMew = BaseOption<CountryIdCode> & {
  customLabel?: ReactNode
}

const StyledSelect = styled(Select)`
  width: 200px;
  height: 40px;
`

type CountrySelectorProps = {
  countrySelectorActions: typeof actions
  countrySelectorState: CountrySelectorState
  userCountry: CountryIdCode
  permissions: Permissions[]
}

const formatOptionLabel = (option: any) => option.customLabel

const CountrySelector = (props: CountrySelectorProps) => {
  const { countrySelectorActions, countrySelectorState, userCountry, permissions } = props
  const { countries, countrySelected, loadingCountries } = countrySelectorState

  const location = useLocation()

  const handleChange = (value: CountryIdCode | null) => {
    const country = countries.find((x) => x.code === value)
    countrySelectorActions.SelectCountryFilter(country || allCountriesOption)
  }

  const filteredCountries = useMemo(() => {
    if (location.pathname === '/dashboard') {
      return countries.filter(
        (country) =>
          [CountryIdCode.ARGENTINA, CountryIdCode.SPAIN].includes(country.code) &&
          country.code !== allCountriesOption.code
      )
    }
    return countries
  }, [countries, location.pathname])

  const selectableCountries = useMemo(
    () =>
      permissions.includes(Permissions.MostrarTodosLosPaises)
        ? location.pathname === '/dashboard'
          ? filteredCountries
          : [allCountriesOption, ...filteredCountries]
        : [filteredCountries.find((country) => country.code === userCountry) || filteredCountries[0]],
    [filteredCountries, permissions, userCountry, location.pathname]
  )

  const selectorCountryOptions = useMemo(() => formatCountryOptions(selectableCountries, 'code'), [selectableCountries])

  const getOptionSelected = (countryCode: CountryIdCode): OptionMew => {
    const { code, flagUrl, label } = filteredCountries.find((country: Country) => country.code === countryCode) || {
      value: allCountriesOption.code,
      label: 'Todos',
      customLabel: <FlagComponent flagUrl={allCountriesOption.flagUrl} label={allCountriesOption.label} />
    }

    return {
      value: code!,
      label,
      customLabel: <FlagComponent flagUrl={flagUrl!} label={label} />
    }
  }

  return (
    <StyledSelect
      className={styles.selectComponent}
      placeholder={'Seleccione un país'}
      loading={loadingCountries}
      options={selectorCountryOptions}
      optionRenderer={formatOptionLabel}
      multiple={false}
      selected={getOptionSelected(countrySelected.code)}
      onSelect={(option: any) => handleChange(option.value)}
    />
  )
}

export default CountrySelector
