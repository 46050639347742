import { Moment } from 'moment'
import { PriceBand } from '../../../projectApi/CategoryCreation/getPriceBands'

export enum CreatePriceBandTypes {
  SET_MODAL_OPEN = 'PRICE_BAND/SET_MODAL_OPEN',
  SET_EDIT_MODAL_OPEN = 'PRICE_BAND/SET_EDIT_MODAL_OPEN',
  SET_DATE_FROM = 'PRICE_BAND/SET_DATE_FROM',
  SET_PRICE = 'PRICE_BAND/SET_PRICE',
  SET_TYPE = 'PRICE_BAND/SET_TYPE',
  SET_COUNTRY = 'PRICE_BAND/SET_COUNTRY',
  ADD_PRICE_BAND_REQUEST = 'PRICE_BAND/ADD_PRICE_BAND_REQUEST',
  ADD_PRICE_BAND_SUCCESS = 'PRICE_BAND/ADD_PRICE_BAND_SUCCESS',
  ADD_PRICE_BAND_FAILURE = 'PRICE_BAND/ADD_PRICE_BAND_FAILURE',
  EDIT_PRICE_BAND_REQUEST = 'PRICE_BAND/EDIT_PRICE_BAND_REQUEST',
  EDIT_PRICE_BAND_SUCCESS = 'PRICE_BAND/EDIT_PRICE_BAND_SUCCESS',
  EDIT_PRICE_BAND_FAILURE = 'PRICE_BAND/EDIT_PRICE_BAND_FAILURE',
  DELETE_PRICE_BAND_REQUEST = 'PRICE_BAND/DELETE_PRICE_BAND_REQUEST',
  DELETE_PRICE_BAND_SUCCESS = 'PRICE_BAND/DELETE_PRICE_BAND_SUCCESS',
  DELETE_PRICE_BAND_FAILURE = 'PRICE_BAND/DELETE_PRICE_BAND_FAILURE',
  GET_PRICE_BAND_REQUEST = 'PRICE_BAND/CREATE/GET_PRICE_BAND_REQUEST',
  GET_PRICE_BAND_SUCCESS = 'PRICE_BAND/CREATE/GET_PRICE_BAND_SUCCESS',
  GET_PRICE_BAND_FAILURE = 'PRICE_BAND/CREATE/GET_PRICE_BAND_FAILURE',
  OPEN_DELETE_MODAL = 'PRICE_BAND/CREATE/OPEN_DELETE_MODAL',
  CLOSE_DELETE_MODAL = 'PRICE_BAND/CREATE/CLOSE_DELETE_MODAL'
}

export type GetPriceBandRequest = {
  type: typeof CreatePriceBandTypes.GET_PRICE_BAND_REQUEST
}

export type GetPriceBandSuccess = {
  type: typeof CreatePriceBandTypes.GET_PRICE_BAND_SUCCESS
  payload: {
    id: number
    price: number
    country: string
    dateFrom: Moment
    type: 'm3' | 'kg'
  }
}

export type GetPriceBandFailure = {
  type: typeof CreatePriceBandTypes.GET_PRICE_BAND_FAILURE
  payload: {
    error: string
  }
}

export type OpenDeleteModal = {
  type: typeof CreatePriceBandTypes.OPEN_DELETE_MODAL
  payload: {
    id: number
  }
}

export type CloseDeleteModal = {
  type: typeof CreatePriceBandTypes.CLOSE_DELETE_MODAL
}

export type SetCreateModalOpen = {
  type: typeof CreatePriceBandTypes.SET_MODAL_OPEN
  payload: { modalOpen: boolean }
}

export type SetEditModalOpen = {
  type: typeof CreatePriceBandTypes.SET_EDIT_MODAL_OPEN
  payload: { priceBand: PriceBand }
}

export type SetDateFrom = {
  type: typeof CreatePriceBandTypes.SET_DATE_FROM
  payload: { dateFrom: Moment }
}

export type SetPrice = {
  type: typeof CreatePriceBandTypes.SET_PRICE
  payload: { price: number }
}

export type SetType = {
  type: typeof CreatePriceBandTypes.SET_TYPE
  payload: { type: 'm3' | 'kg' }
}

export type SetCountryId = {
  type: typeof CreatePriceBandTypes.SET_COUNTRY
  payload: { country: string }
}

export type AddPriceBandRequest = {
  type: typeof CreatePriceBandTypes.ADD_PRICE_BAND_REQUEST
}

export type AddPriceBandSuccess = {
  type: typeof CreatePriceBandTypes.ADD_PRICE_BAND_SUCCESS
}

export type AddPriceBandFailure = {
  type: typeof CreatePriceBandTypes.ADD_PRICE_BAND_FAILURE
  payload: {
    error: string
  }
}

export type deletePriceBandRequest = {
  type: typeof CreatePriceBandTypes.DELETE_PRICE_BAND_REQUEST
}

export type deletePriceBandSuccess = {
  type: typeof CreatePriceBandTypes.DELETE_PRICE_BAND_SUCCESS
}

export type deletePriceBandFailure = {
  type: typeof CreatePriceBandTypes.DELETE_PRICE_BAND_FAILURE
  payload: {
    error: string
  }
}

export type EditPriceBandRequest = {
  type: typeof CreatePriceBandTypes.EDIT_PRICE_BAND_REQUEST
}

export type EditPriceBandSuccess = {
  type: typeof CreatePriceBandTypes.EDIT_PRICE_BAND_SUCCESS
}

export type EditPriceBandFailure = {
  type: typeof CreatePriceBandTypes.EDIT_PRICE_BAND_FAILURE
  payload: {
    error: string
  }
}

export type FormattedPriceBand = {
  id?: number
  country: string
  dateFrom: Moment | null
  price: number
  typePrice: string
}

export type PriceBandType = {
  id: number
  country: string
  dateFrom: Moment | null
  price: number
  typePrice: string
}

export type NewPriceBandActions =
  | SetCreateModalOpen
  | SetEditModalOpen
  | SetDateFrom
  | SetPrice
  | SetType
  | SetCountryId
  | AddPriceBandRequest
  | AddPriceBandSuccess
  | AddPriceBandFailure
  | EditPriceBandRequest
  | EditPriceBandSuccess
  | EditPriceBandFailure
  | deletePriceBandRequest
  | deletePriceBandSuccess
  | deletePriceBandFailure
  | GetPriceBandRequest
  | GetPriceBandSuccess
  | GetPriceBandFailure
  | OpenDeleteModal
  | CloseDeleteModal
