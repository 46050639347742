import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDays/list'
import { DiscountWeekdaysFilterKey } from '../../../projectApi/Timeslot/Discounts/SpecificDays/common'

export type ModalCreateDiscountDayState = {
  editMode: boolean
  modalVisible: boolean
  deleteModalVisible: boolean
  creatingDiscount: boolean
  updatingDiscount: boolean
  deletingDiscount: boolean
  selectedDiscount: Discount | null
  selectedDay: DiscountWeekdaysFilterKey | null
  discountRate: {
    error: string
    value?: number
  }
}

export enum CreateDiscountDayTypes {
  CREATE_DISCOUNT_DAY_REQUEST = 'DISCOUNTS/DISCOUNT_DAYS/CREATE_DISCOUNT_DAY_REQUEST',
  CREATE_DISCOUNT_DAY_SUCCESS = 'DISCOUNTS/DISCOUNT_DAYS/CREATE_DISCOUNT_DAY_SUCCESS',
  CREATE_DISCOUNT_DAY_FAILURE = 'DISCOUNTS/DISCOUNT_DAYS/CREATE_DISCOUNT_DAY_FAILURE'
}

export interface CreateDiscountDayRequest {
  type: typeof CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_REQUEST
}

export interface CreateDiscountDaySuccess {
  type: typeof CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_SUCCESS
}

export interface CreateDiscountDayFailure {
  type: typeof CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_FAILURE
}

type CreateDiscountDayActions = CreateDiscountDayRequest | CreateDiscountDaySuccess | CreateDiscountDayFailure

export enum UpdateDiscountDayTypes {
  UPDATE_DISCOUNT_DAY_REQUEST = 'DISCOUNTS/DISCOUNT_DAYS/UPDATE_DISCOUNT_DAY_REQUEST',
  UPDATE_DISCOUNT_DAY_SUCCESS = 'DISCOUNTS/DISCOUNT_DAYS/UPDATE_DISCOUNT_DAY_SUCCESS',
  UPDATE_DISCOUNT_DAY_FAILURE = 'DISCOUNTS/DISCOUNT_DAYS/UPDATE_DISCOUNT_DAY_FAILURE'
}

export interface UpdateDiscountDayRequest {
  type: typeof UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_REQUEST
}

export interface UpdateDiscountDaySuccess {
  type: typeof UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_SUCCESS
}

export interface UpdateDiscountDayFailure {
  type: typeof UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_FAILURE
}

type UpdateDiscountDayActions = UpdateDiscountDayRequest | UpdateDiscountDaySuccess | UpdateDiscountDayFailure

export enum DeleteDiscountDayTypes {
  DELETE_DISCOUNT_DAY_REQUEST = 'DISCOUNTS/DISCOUNT_DAYS/DELETE_DISCOUNT_DAY_REQUEST',
  DELETE_DISCOUNT_DAY_SUCCESS = 'DISCOUNTS/DISCOUNT_DAYS/DELETE_DISCOUNT_DAY_SUCCESS',
  DELETE_DISCOUNT_DAY_FAILURE = 'DISCOUNTS/DISCOUNT_DAYS/DELETE_DISCOUNT_DAY_FAILURE'
}

export interface DeleteDiscountDayRequest {
  type: typeof DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_REQUEST
}

export interface DeleteDiscountDaySuccess {
  type: typeof DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_SUCCESS
}

export interface DeleteDiscountDayFailure {
  type: typeof DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_FAILURE
}

type DeleteDiscountDateActions = DeleteDiscountDayRequest | DeleteDiscountDaySuccess | DeleteDiscountDayFailure

export enum ModalCreateDiscountDayTypes {
  SET_DISCOUNT_DAY = 'DISCOUNTS/DISCOUNT_DAYS/SET_DISCOUNT_DAY',
  SET_DISCOUNT_RATE = 'DISCOUNTS/DISCOUNT_DAYS/SET_DISCOUNT_RATE',
  SET_DISCOUNT_RATE_ERROR = 'DISCOUNTS/DISCOUNT_DAYS/SET_DISCOUNT_RATE_ERROR',
  SET_MODAL_VISIBILITY = 'DISCOUNTS/DISCOUNT_DAYS/SET_MODAL_VISIBILITY',
  SET_DELETE_MODAL_VISIBILITY = 'DISCOUNTS/DISCOUNT_DAYS/SET_DELETE_MODAL_VISIBILITY',
  SET_SELECTED_DISCOUNT = 'DISCOUNTS/DISCOUNT_DAYS/SET_SELECTED_DISCOUNT'
}

export interface SetDeleteModalVisibility {
  type: typeof ModalCreateDiscountDayTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisible: boolean }
}

export interface SetSelectedDiscount {
  type: typeof ModalCreateDiscountDayTypes.SET_SELECTED_DISCOUNT
  payload: { selectedDiscount: Discount }
}

export interface SetModalVisibility {
  type: typeof ModalCreateDiscountDayTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean; editMode?: boolean }
}

export interface SetDiscountDay {
  type: typeof ModalCreateDiscountDayTypes.SET_DISCOUNT_DAY
  payload: {
    selectedDay: DiscountWeekdaysFilterKey | null
  }
}

export interface SetDiscountRate {
  type: typeof ModalCreateDiscountDayTypes.SET_DISCOUNT_RATE
  payload: {
    discountRate: string
  }
}

export interface SetDiscountRateError {
  type: typeof ModalCreateDiscountDayTypes.SET_DISCOUNT_RATE_ERROR
  payload: { errorRate: string }
}

type ModalCreateDiscountDayActions =
  | SetDiscountDay
  | SetDiscountRate
  | SetDiscountRateError
  | SetModalVisibility
  | SetSelectedDiscount
  | SetDeleteModalVisibility

export type CreateDiscountDatesActions =
  | ModalCreateDiscountDayActions
  | CreateDiscountDayActions
  | UpdateDiscountDayActions
  | DeleteDiscountDateActions
