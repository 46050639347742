export enum SendTransactionsByTripsReportTypes {
  SEND_TRANSACTIONS_BY_TRIPS_REPORT_REQUEST = 'TRIPS/REPORTS/SEND_TRANSACTIONS_BY_TRIPS_REPORT_REQUEST',
  SEND_TRANSACTIONS_BY_TRIPS_REPORT_SUCCESS = 'TRIPS/REPORTS/SEND_TRANSACTIONS_BY_TRIPS_REPORT_SUCCESS',
  SEND_TRANSACTIONS_BY_TRIPS_REPORT_FAILURE = 'TRIPS/REPORTS/SEND_TRANSACTIONS_BY_TRIPS_REPORT_FAILURE'
}

export interface SendTransactionsByTripsReportRequest {
  type: typeof SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_REQUEST
}

export interface SendTransactionsByTripsReportSuccess {
  type: typeof SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_SUCCESS
}

export interface SendTransactionsByTripsReportFailure {
  type: typeof SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_FAILURE
}

export type SendTransactionsByTripsReportActions =
  | SendTransactionsByTripsReportRequest
  | SendTransactionsByTripsReportSuccess
  | SendTransactionsByTripsReportFailure

export enum SendTripsReportTypes {
  SEND_CSV_REQUEST = 'TRIPS/REPORTS/SEND_CSV_REQUEST',
  SEND_CSV_SUCCESS = 'TRIPS/REPORTS/SEND_CSV_SUCCESS',
  SEND_CSV_FAILURE = 'TRIPS/REPORTS/SEND_CSV_FAILURE'
}

export interface SendCSVRequest {
  type: typeof SendTripsReportTypes.SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SendTripsReportTypes.SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SendTripsReportTypes.SEND_CSV_FAILURE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export type SendPreBillingReportActions = SendCSVActions | SendTransactionsByTripsReportActions
