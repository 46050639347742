/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APIDestinationDeposit, DestinationDeposit } from './common'

interface APIResponse {
  description: {
    deposits: APIDestinationDeposit[]
    quantity: number
  }
}
interface Response {
  deposits: DestinationDeposit[]
  total: number
}

type DepositParams = {
  countryCode: string
  serviceType: string
  transportModeId: number
}

export function deposit({ countryCode, serviceType, transportModeId }: DepositParams): Promise<Response> {
  const snakesParams = {
    country_code: countryCode
  }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/serviceType/${serviceType}/transportMode/${transportModeId}/deposit`, {
      params: snakesParams
    })
    .then((response: AxiosResponse<APIResponse>) => {
      const resp = response.data?.description
      if (!resp) throw new Error('Respuesta incorrecta al obtener los depositos')
      const camelResponse: Response = {
        total: resp.quantity,
        deposits: camelcaseKeys(resp.deposits, { deep: true })
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
