import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

type paramsCreate = {
  userId: number
  providerId: number
}

export function create(params: paramsCreate): Promise<void> {
  const formattedParams = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .post(`${URL_BASE_TRIPS}/shipper`, formattedParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
