import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

type providerCreateParams = {
  name: string
}

export type ResponseApp = CamelCasedPropertiesDeep<void>

export function create({ ...params }: providerCreateParams): Promise<ResponseApp> {
  const formattedParams = snakecaseKeys(params, { deep: true })
  return apiPrivate
    .post(`${URL_BASE_TRIPS}/providers`, formattedParams, {
      headers: { 'x-origin': 'backoffice/adm-web' }
    })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
