import { Reducer } from 'redux'
import {
  CancelSaleModalActions,
  CancelSaleModalStateType,
  CommonTypes,
  CancelSaleModalTypes
} from '../types/cancelSaleModal'

const initialState: CancelSaleModalStateType = {
  id: '',
  name: '',
  lastName: '',
  isOpen: false,
  isLoading: false,
  error: ''
}

const CancelSaleModalReducer: Reducer<CancelSaleModalStateType, CancelSaleModalActions> = (
  state: CancelSaleModalStateType = initialState,
  action: CancelSaleModalActions
): CancelSaleModalStateType => {
  switch (action.type) {
    case CommonTypes.CLEAN_STATE:
      return initialState
    case CommonTypes.SET_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        name: action.payload.name || '',
        lastName: action.payload.lastName || '',
        id: action.payload.id || ''
      }
    case CancelSaleModalTypes.CANCEL_SALE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case CancelSaleModalTypes.CANCEL_SALE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case CancelSaleModalTypes.CANCEL_SALE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default CancelSaleModalReducer
