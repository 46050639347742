import {
  ADD_CREDIT_CARD_SPREEDLY_FAILURE,
  ADD_CREDIT_CARD_SPREEDLY_REQUEST,
  ADD_CREDIT_CARD_SPREEDLY_SUCCESS,
  BillingPaymentActions,
  BillingPaymentTableCategoryFilter,
  BillingPaymentTableSearchFilter,
  BillingPaymentTableSearchKey,
  BillingPaymentTableSort,
  CLEAR_SELECTED_BILLINGS,
  CLEAR_STATE,
  GET_BILLINGS_BY_USER_FAILURE,
  GET_BILLINGS_BY_USER_REQUEST,
  GET_BILLINGS_BY_USER_SUCCESS,
  GET_CLIENT_PAYMENT_METHODS_FAILURE,
  GET_CLIENT_PAYMENT_METHODS_REQUEST,
  GET_CLIENT_PAYMENT_METHODS_SUCCESS,
  GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE,
  GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST,
  GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  PAY_CLIENT_BILLING_FAILURE,
  PAY_CLIENT_BILLING_REQUEST,
  PAY_CLIENT_BILLING_SUCCESS,
  Pagination,
  SELECT_BILLING,
  SELECT_PAYMENT_METHOD,
  TOGGLE_ALL_BILLINGS,
  TOGGLE_SELECTED_BILLING,
  UserCollpyWithCountry
} from '../types/BillingPayment'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { billingCanBeSelected } from '../components/BillingPaymentTable'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'

export type BillingPaymentState = {
  loading: boolean
  userColppyInfo: UserCollpyWithCountry | null
  error: string
  categoryFilter: BillingPaymentTableCategoryFilter
  billings: Billing[]
  selectedBillings: Billing[]
  pagination: Pagination
  searchFilters: BillingPaymentTableSearchFilter[]
  sort: BillingPaymentTableSort
  paymentMethods: PaymentMethod[]
  paymentMethodSelected: PaymentMethod | null
}

const initialBillingPaymentState: BillingPaymentState = {
  userColppyInfo: null,
  loading: false,
  selectedBillings: [],
  error: '',
  billings: [],
  pagination: {
    page: 1,
    total: 0,
    pageSize: 10
  },
  searchFilters: [{ key: BillingPaymentTableSearchKey.BILLING_ID, text: '' }],
  sort: {
    direction: SortDirection.DESC,
    field: 'nroFactura'
  },
  categoryFilter: {
    status: []
  },
  paymentMethods: [],
  paymentMethodSelected: null
}

const billingPaymentReducer = (
  state: BillingPaymentState = initialBillingPaymentState,
  action: BillingPaymentActions
): BillingPaymentState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialBillingPaymentState
    case CLEAR_SELECTED_BILLINGS:
      return { ...state, selectedBillings: initialBillingPaymentState.selectedBillings }
    case SELECT_BILLING: {
      return {
        ...state,
        selectedBillings: [action.payload.billing]
      }
    }
    case TOGGLE_SELECTED_BILLING: {
      const filtered = state.selectedBillings.filter(
        (bi) => bi.billingColppyId !== action.payload.billing.billingColppyId
      )
      if (state.selectedBillings.length === filtered.length) filtered.push(action.payload.billing)
      return {
        ...state,
        selectedBillings: filtered
      }
    }
    case TOGGLE_ALL_BILLINGS: {
      const allSelectableBillingsAreSelectedOrDisabled = state.billings.every(
        (bi) =>
          state.selectedBillings.find((sbi) => sbi.billingColppyId === bi.billingColppyId) || !billingCanBeSelected(bi)
      )

      return {
        ...state,
        selectedBillings: allSelectableBillingsAreSelectedOrDisabled
          ? initialBillingPaymentState.selectedBillings
          : state.billings.filter((bi) => billingCanBeSelected(bi))
      }
    }
    case GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST: {
      return {
        ...state,
        loading: true,
        userColppyInfo: null
      }
    }
    case GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userColppyInfo: action.payload.user
      }
    }
    case GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    case GET_BILLINGS_BY_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        sort: action.payload.sort || initialBillingPaymentState.sort,
        categoryFilter: action.payload.categoryFilter || initialBillingPaymentState.categoryFilter,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    }
    case GET_BILLINGS_BY_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        billings: action.payload.billings,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    }
    case GET_BILLINGS_BY_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    case GET_CLIENT_PAYMENT_METHODS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_CLIENT_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods
      }
    }
    case GET_CLIENT_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case SELECT_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethodSelected: action.payload.paymentMethod
      }
    }
    case PAY_CLIENT_BILLING_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case PAY_CLIENT_BILLING_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    case PAY_CLIENT_BILLING_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case ADD_CREDIT_CARD_SPREEDLY_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case ADD_CREDIT_CARD_SPREEDLY_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    case ADD_CREDIT_CARD_SPREEDLY_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_USER_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}
export default billingPaymentReducer
