import React from 'react'

type CalendarIconProps = {
  size?: string | number
  color?: string
  className?: string
}
const CalendarIcon: React.FC<CalendarIconProps> = ({ size = '1em', color = 'currentColor', className, ...props }) => {
  return (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="calendar"
      width={size}
      height={size}
      fill={color}
      aria-hidden="true"
      className={className}
      {...props}>
      <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
    </svg>
  )
}

export default CalendarIcon
