import { Request } from '../../../projectApi/Sales/Requests/common'
import { ListParams } from '../../../projectApi/Sales/Requests/list'
import { CategoryFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type RequestsSearchKey = keyof ListParams
export type RequestsSearchFilter = SearchFilter<RequestsSearchKey>

export type RequestsCategoryFilterKey = 'status' | 'type'
export type RequestsCategoryFilter = CategoryFilter<RequestsCategoryFilterKey, string>

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface RequestsState {
  requests: Request[]
  loading: boolean
  error: string
  pagination: Pagination
  categoryFilter: RequestsCategoryFilter
  searchFilters: RequestsSearchFilter[]
  loadingTable: boolean
  sort: RequestsTableSort
  showDemoUsers: boolean
}

export const GET_REQUESTS_REQUEST = 'REQUEST/REQUEST_TABLE_REQUEST'
export const GET_REQUESTS_SUCCESS = 'REQUEST/REQUEST_TABLE_SUCCESS'
export const GET_REQUESTS_FAILURE = 'REQUEST/REQUEST_TABLE_FAILURE'

export interface GetRequestsRequest {
  type: typeof GET_REQUESTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    type?: string
    searchFilters?: RequestsSearchFilter[]
    categoryFilter?: RequestsCategoryFilter
    sort?: RequestsTableSort
    showDemoUsers: boolean
  }
}

export interface GetRequestsSuccess {
  type: typeof GET_REQUESTS_SUCCESS
  payload: {
    requests: Request[]
    total: number
  }
}

export interface GetRequestsFailure {
  type: typeof GET_REQUESTS_FAILURE
  payload: {
    error: string
  }
}

type RequestsListSortKey = 'id' | 'request_type' | 'object_id' | 'status' | 'created_at' | 'updated_at'

export type RequestsTableSortKey = RequestsListSortKey
export type RequestsTableSort = Sort<RequestsTableSortKey>

export type GetRequestsAction = GetRequestsRequest | GetRequestsSuccess | GetRequestsFailure

export type RequestsAction = GetRequestsAction
