import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AMDiscountModal from '../components/Modales/DiscountDays/AMDiscountDay'
import createDiscountDaysActionsCreator from '../actions/ABMDiscountDays'
import DeleteDiscountModal from '../components/Modales/DiscountDays/DeleteDiscountDay'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const ABMDiscountDaysContainer: React.FC = () => {
  const dispatch = useDispatch()

  const {
    discountRate,
    selectedDay,
    modalVisible,
    creatingDiscount,
    updatingDiscount,
    deletingDiscount,
    editMode,
    selectedDiscount,
    deleteModalVisible
  } = useSelector((state) => state.Discount.createDiscountDaysReducer)

  const {
    setDiscountDay,
    setDiscountRate,
    setErrorDiscountRate,
    setModalVisibility,
    createDiscount,
    updateDiscount,
    setDeleteModalVisibility,
    deleteDiscount
  } = bindActionCreators(createDiscountDaysActionsCreator, dispatch)

  useEvents([Events.Discounts.CREATE_DISCOUNT, Events.Discounts.UPDATE_DISCOUNT], () =>
    setModalVisibility({ modalVisible: false })
  )

  useEvents(Events.Discounts.DELETE_DISCOUNT, () => setDeleteModalVisibility(false))

  const onChangeDiscountRate = (discountRate: string) => {
    setErrorDiscountRate('')
    setDiscountRate(discountRate)
  }

  const handleConfirmButton = () => {
    if (!selectedDay || !discountRate.value) return

    if (editMode) {
      updateDiscount({
        discountId: selectedDiscount?.id ?? 0,
        day: selectedDay || selectedDiscount?.weekday,
        discountRate: discountRate.value
      })
    } else {
      createDiscount({
        day: selectedDay,
        discountRate: discountRate.value
      })
    }
  }

  const disabledCreateButton =
    (selectedDay === null && selectedDiscount?.weekday === undefined) ||
    Boolean(discountRate.error) ||
    (!Number.isInteger(discountRate.value) && !Number.isInteger(selectedDiscount?.discount))

  const loadingCreateButton = creatingDiscount || updatingDiscount

  const modalProps = {
    editMode,
    selectedDiscount,
    discountRate,
    selectedDay,
    modalVisible,
    loadingCreateButton,
    disabledCreateButton,
    setModalVisibility,
    onChangeDiscountRate,
    handleConfirmButton,
    setDiscountDay
  }

  const handleDeleteDiscount = () => {
    deleteDiscount({
      discountId: selectedDiscount?.id ?? 0
    })
  }

  const deleteModalProps = {
    selectedDiscount,
    deletingDiscount,
    deleteModalVisible,
    setDeleteModalVisibility,
    handleDeleteDiscount
  }

  return (
    <>
      <AMDiscountModal {...modalProps} />
      <DeleteDiscountModal {...deleteModalProps} />
    </>
  )
}

export default ABMDiscountDaysContainer
