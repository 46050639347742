export const SET_ERROR = 'PRODUCTS/SET_ERROR'
export const OPEN_ATTRIBUTE_MODAL = 'PRODUCTS/OPEN_ATTRIBUTE_MODAL'
export const CLOSE_ATTRIBUTE_MODAL = 'PRODUCTS/CLOSE_ATTRIBUTE_MODAL'
export const ADD_ATTRIBUTE = 'PRODUCTS/ADD_ATTRIBUTE'
export const REMOVE_ATTRIBUTE = 'PRODUCTS/REMOVE_ATTRIBUTE'
export const GET_ATTRIBUTES_REQUEST = 'PRODUCTS/GET_ATTRIBUTES_REQUEST'
export const GET_ATTRIBUTES_SUCCESS = 'PRODUCTS/GET_ATTRIBUTES_SUCCESS'
export const GET_ATTRIBUTES_FAILURE = 'PRODUCTS/GET_ATTRIBUTES_FAILURE'
export const GET_ATTRIBUTES_BY_PRODUCT_REQUEST = 'PRODUCTS/GET_ATTRIBUTES_BY_PRODUCT_REQUEST'
export const GET_ATTRIBUTES_BY_PRODUCT_SUCCESS = 'PRODUCTS/GET_ATTRIBUTES_BY_PRODUCT_SUCCESS'
export const GET_ATTRIBUTES_BY_PRODUCT_FAILURE = 'PRODUCTS/GET_ATTRIBUTES_BY_PRODUCT_FAILURE'
export const UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST = 'PRODUCTS/UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST'
export const UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS = 'PRODUCTS/UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS'
export const UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE = 'PRODUCTS/UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE'

export interface Attribute {
  id: number
  dataType: string
  isVisible: boolean
  unit: string
  name: string
  countries: CountryAttribute[]
}

export interface CountryAttribute {
  id: number
  label: string
  countryId: string
  options: OptionCountryAttribute[]
}

export interface OptionCountryAttribute {
  id: number
  dataType: string
  value: string
}

export interface ProductAttribute {
  attribute: Attribute
  deleted?: boolean
  isNew?: boolean
}

export interface AttributeModalState {
  open: boolean
  error: string
  loading: boolean
  productId: number | null
  attributes: Attribute[]
  productAttributes: ProductAttribute[]
}
export interface SetError {
  type: typeof SET_ERROR
  payload: {
    error: string
  }
}
export interface OpenAttributeModal {
  type: typeof OPEN_ATTRIBUTE_MODAL
  payload: {
    productId: number
  }
}
export interface CloseAttributeModal {
  type: typeof CLOSE_ATTRIBUTE_MODAL
}

export interface AddAttribute {
  type: typeof ADD_ATTRIBUTE
  payload: {
    productAttribute: ProductAttribute
  }
}

export interface RemoveAttribute {
  type: typeof REMOVE_ATTRIBUTE
  payload: {
    attributeId: number
  }
}

export interface GetAttributesRequest {
  type: typeof GET_ATTRIBUTES_REQUEST
  payload: {
    lastSearchID: number
  }
}

export interface GetAttributesSuccess {
  type: typeof GET_ATTRIBUTES_SUCCESS
  payload: {
    attributes: Attribute[]
    total: number
  }
}

export interface GetAttributesFailure {
  type: typeof GET_ATTRIBUTES_FAILURE
  payload: {
    error: string
  }
}

export interface GetAttributesByProductIdRequest {
  type: typeof GET_ATTRIBUTES_BY_PRODUCT_REQUEST
}

export interface GetAttributesByProductIdSuccess {
  type: typeof GET_ATTRIBUTES_BY_PRODUCT_SUCCESS
  payload: {
    productAttributes: ProductAttribute[]
  }
}

export interface GetAttributesByProductIdFailure {
  type: typeof GET_ATTRIBUTES_BY_PRODUCT_FAILURE
  payload: {
    error: string
  }
}

export interface UpdateAttributesByProductIdRequest {
  type: typeof UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST
}

export interface UpdateAttributesByProductIdSuccess {
  type: typeof UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS
}

export interface UpdateAttributesByProductIdFailure {
  type: typeof UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE
  payload: {
    error: string
  }
}

export const CLEAR_STATE = 'PRODUCTS/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export type AttributeModalActions =
  | SetError
  | OpenAttributeModal
  | CloseAttributeModal
  | AddAttribute
  | RemoveAttribute
  | GetAttributesRequest
  | GetAttributesSuccess
  | GetAttributesFailure
  | GetAttributesByProductIdRequest
  | GetAttributesByProductIdSuccess
  | GetAttributesByProductIdFailure
  | UpdateAttributesByProductIdRequest
  | UpdateAttributesByProductIdSuccess
  | UpdateAttributesByProductIdFailure
  | ClearState
