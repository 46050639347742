import React from 'react'
import Component, { SendByEmailModalProps } from '../component/sendByEmail'
import { bindActionCreators } from 'redux'
import actions from '../actions/sendByMail'
import { useDispatch, useSelector } from 'react-redux'

const SendByEmailContainer = () => {
  const dispatch = useDispatch()
  const sendByEmail = useSelector((state) => state.Proposals.sendByEmail)
  const sendActions = bindActionCreators(actions, dispatch)

  const props: SendByEmailModalProps = {
    sendByEmail,
    sendEmailActions: sendActions
  }

  return <Component {...props} />
}

export default SendByEmailContainer
