import * as React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import styles from './audits.module.scss'
import { Audit, EntityAudit, ItemAudit, User } from './entities'

export type AcceptedKeys = keyof Audit | keyof User | keyof EntityAudit | keyof ItemAudit
export type FormatedDate = JSX.Element | '-' | ''
export type AlignText = 'start' | 'center' | 'end'

export interface DeterminateIfValueChangeProps {
  audits: Audit[]
  index: number
  keys: AcceptedKeys[]
}

export const determinateIfValueChange = ({ audits, index, keys }: DeterminateIfValueChangeProps) => {
  if (index + 1 <= audits.length && audits[index + 1] !== undefined) {
    let currentValue = audits[index]
    let nextValue = audits[index + 1]
    for (const key of keys) {
      // @ts-ignore
      currentValue = currentValue[key]
      // @ts-ignore
      nextValue = nextValue[key]
    }

    if (moment.isMoment(currentValue) && moment.isMoment(nextValue)) {
      const isDifferentDate = !currentValue.isSame(nextValue)

      return isDifferentDate
    } else return currentValue !== nextValue
  } else {
    return false
  }
}

export const changeSpan = (value: string | number | FormatedDate, alignText: AlignText = 'center') => {
  return <span className={clsx(styles.changeValueCell, styles[alignText])}>{value}</span>
}
