import React, { useMemo } from 'react'
import styles from './StatusCard.module.scss'
import { Tooltip } from 'antd'
import { Info } from '../../icons'
import clsx from 'clsx'

export type StatusCardTypes = 'info' | 'infoSolid' | 'warning' | 'danger' | 'success' | 'neutral'
export type StatusCardUnits = 'm³' | 'cm' | 'm' | 'kg' | '%' | ''

export type StatusCardProps = {
  key?: string | number
  description?: React.ReactElement | string
  titleCard: string
  total: number | string
  percentage?: string | number
  tooltipIconColor?: string
  backgroundColor?: string
  width?: string
  borderColor?: string
  positive?: number
  negative?: number
  customClassname?: string
  clicked?: boolean
  disabled?: boolean
  onClick?: () => void
  type?: StatusCardTypes
  unit?: StatusCardUnits
  unitStyles?: string
  totalStyles?: string
  titleStyles?: string
  useOnlyCustomClass?: boolean
}

const StatusCard: React.FC<StatusCardProps> = ({
  key,
  description,
  titleCard,
  total,
  percentage,
  tooltipIconColor,
  backgroundColor,
  borderColor,
  width,
  positive,
  negative,
  customClassname,
  clicked,
  disabled,
  onClick,
  type = 'warning',
  unit = '%',
  unitStyles,
  totalStyles,
  titleStyles,
  useOnlyCustomClass
}) => {
  const currentPaletteColor = useMemo(() => {
    const palette = colorPalette({ active: clicked })

    return palette[type]
  }, [clicked, type])

  return (
    <article
      key={key}
      onClick={onClick}
      aria-disabled={disabled}
      style={
        !useOnlyCustomClass
          ? {
              backgroundColor: backgroundColor || currentPaletteColor.backgroundColor,
              color: currentPaletteColor.color,
              borderColor: borderColor || currentPaletteColor.borderColor || tooltipIconColor,
              width
            }
          : {}
      }
      className={clsx(styles.card, customClassname, {
        [styles.clicked]: clicked,
        [styles.disabled]: disabled,
        [styles.clickable]: Boolean(onClick)
      })}>
      {description && (
        <StatusCardTooltip description={description} color={tooltipIconColor || currentPaletteColor.borderColor} />
      )}
      <p className={clsx(styles.title, titleStyles)}>{titleCard}</p>
      <p className={clsx(styles.total, totalStyles)}>{total}</p>
      {percentage && (
        <span className={clsx(unitStyles)}>
          {percentage} {unit}
        </span>
      )}
      {positive && <span className={styles.positive}>+ {positive}</span>}
      {(negative || negative === 0) && <span className={styles.negative}>- {negative}</span>}
    </article>
  )
}

export default StatusCard

export const StatusCardTooltip: React.FC<{ description?: React.ReactElement | string; color?: string }> = ({
  description,
  color
}) => {
  return (
    <Tooltip placement="bottom" title={description}>
      <span className={styles.tooltip}>
        <Info fill={color} />
      </span>
    </Tooltip>
  )
}

export const StatusCardSkeleton = ({ loadingColor = '#5d58f7', key }: { loadingColor: string; key?: string }) => {
  return (
    <article style={{ borderColor: loadingColor }} className={styles.card} key={key}>
      <svg
        role="img"
        width="94"
        height="74"
        aria-labelledby="loading-aria"
        viewBox="0 0 94 74"
        preserveAspectRatio="none">
        <title id="loading-aria">Cargando...</title>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clipPath="url(#clip-path)"
          key={key}
          style={{ fill: 'url("#fill")' }}></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="0" y="8" rx="3" ry="3" width="80" height="6" />
            <rect x="0" y="20" rx="3" ry="3" width="62" height="6" />
            <circle cx="14" cy="51" r="14" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stopColor="#f3f3f3" stopOpacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stopColor={loadingColor} stopOpacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stopColor="#f3f3f3" stopOpacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </article>
  )
}

export const colorPalette = ({
  active
}: {
  active?: boolean
}): { [key: string]: { backgroundColor: string; borderColor: string; color: string } } => ({
  neutral: {
    backgroundColor: '#FFFFF',
    borderColor: active ? '#efeefe' : '#efeefe',
    color: active ? '#272553' : '#272553'
  },
  info: {
    backgroundColor: '#F0F2FF',
    borderColor: active ? '#4A46C6' : '#5D58F7',
    color: active ? '#4A46C6' : '#5D58F7'
  },
  infoSolid: {
    backgroundColor: '#4A46C6',
    borderColor: '#4A46C6',
    color: '#FFF'
  },
  success: {
    backgroundColor: '#F0FFFC',
    borderColor: active ? '#1AA86F' : '#1DD4B3',
    color: active ? '#1AA86F' : '#1DD4B3'
  },
  warning: {
    backgroundColor: active ? '#f9e8c5' : '#FFF8EB',
    borderColor: active ? '#744503' : '#B25E09',
    color: active ? '#744503' : '#B25E09'
  },
  danger: {
    backgroundColor: '#FFFAFA',
    borderColor: active ? '#F5522E' : '#CC6266',
    color: active ? '#F5522E' : '#CC6266'
  }
})
