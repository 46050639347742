import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/photos'
import deletePhotoActions from '../actions/deletePhoto'
import Component from '../components/photos'

const Container = (props) => {
  return <Component {...props} />
}

const mapStateToProps = (state) => {
  const { photos } = state.PhotographObjects

  return photos
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  openDeletePhotoModal: bindActionCreators(deletePhotoActions.openModal, dispatch)
})

// Container.propTypes = {
//   open: bool,
//   object: shape({}),
//   loading: bool,
//   openModal: func.isRequired,
//   closeModal: func.isRequired,
//   setDefaultPhoto: func.isRequired,
//   addPhotos: func.isRequired,
//   openDeletePhotoModal: func.isRequired
// }

export default connect(mapStateToProps, mapDispatchToProps)(Container)
