import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { convertToMoment, formatDates } from '../../utils'
import { APIObject, FormattedObject } from '../common'
import { Moment } from 'moment'

interface APIResponse {
  success: boolean
  description: {
    objects: APIObject[]
    quantity: number
  }
}

type Response = {
  objects: FormattedObject[]
  total: number
}

export type ObjectsListSortKey = 'id' | 'created_at' | 'devolution_date'

export type SearchableParams = {
  id?: number
  identificationCode?: string
  product?: string
  locationCode?: string
  ownerId?: number
  owner?: string
  location?: string | null
  exactLocationCode?: boolean
  createdAtFrom?: Moment
  createdAtTo?: Moment
}

interface ListParams extends SearchableParams {
  limit?: number
  offset?: number
  column?: ObjectsListSortKey
  order?: SortDirection
  withDeleted?: boolean
  withDeletedPhotos?: boolean
  status?: string
  situation?: string
  createdAtFrom?: Moment
  createdAtTo?: Moment
}

export function list(params: ListParams): Promise<Response> {
  const {
    limit,
    offset,
    column,
    order,
    withDeleted,
    withDeletedPhotos,
    id,
    identificationCode,
    product,
    location,
    locationCode,
    ownerId,
    owner,
    status,
    situation,
    exactLocationCode,
    createdAtFrom,
    createdAtTo
  } = params

  const solicitedBackLocation = location === 'true' ? '' : 'null'

  const axiosParams = {
    Limit: limit,
    Offset: offset,
    Column: column,
    Order: order,
    ownerID: ownerId,
    withDeleted,
    withDeletedPhotos,
    id,
    identificationCode,
    locationCode,
    product,
    location: solicitedBackLocation,
    owner,
    status,
    situation,
    exactLocationCode,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo
      }
    })
  }

  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/object`, { params: axiosParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.success || !response.data.description.objects)
        throw new Error('Respuesta incorrecta al obtener los objetos')

      const camelResponse: Response = {
        total: response.data.description.quantity,
        objects: response.data.description.objects.map((obj) => {
          const unformattedAPIObject = obj
          const camelObject = camelcaseKeys(obj, { deep: true })
          return {
            unformattedAPIObject,
            ...camelObject,
            serviceTypeId: camelObject.serviceTypeId,
            deletedAt: convertToMoment(camelObject.deleteAt),
            deletedDescription: camelObject.deleteDescription || '',
            objectSituation: camelObject.objectSituation,
            devolutionDate: convertToMoment(camelObject.devolutionDate),
            registryDate: convertToMoment(camelObject.registryDate),
            creationDate: convertToMoment(camelObject.creationDate),
            owner: {
              ...camelObject.owner,
              realId: camelObject.owner.realId,
              createdAt: convertToMoment(camelObject.owner.createdAt),
              deletedAt: convertToMoment(camelObject.owner.deletedAt)
            },
            photos: camelObject.photos.map((photo) => ({
              ...photo,
              createdAt: convertToMoment(photo.createdAt),
              updatedAt: convertToMoment(photo.updatedAt)
            }))
          }
        })
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
