import React from 'react'
import { Modal, Tooltip } from 'antd'
import styles from './serviceDelete.module.scss'
import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'

type DeleteServiceModalProps = {
  selectedService: ServiceSearch
  isDeletingService: boolean
  deleteModalVisible: boolean
  setDeleteModalVisibility: (modalVisible: boolean, service: ServiceSearch) => void
  handleDeleteService: (serviceId: string) => void
}

const DeleteServiceModal = ({
  selectedService,
  isDeletingService,
  setDeleteModalVisibility,
  deleteModalVisible,
  handleDeleteService
}: DeleteServiceModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={() => setDeleteModalVisibility(false, selectedService)}
      title={'Eliminar Tipo de Servicio'}
      confirmLoading={isDeletingService}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={() => handleDeleteService(selectedService.id)}
      okButtonProps={{ disabled: false, className: styles.onOkBtn }}
      cancelButtonProps={{ className: styles.onCancelBtn }}
      destroyOnClose>
      <InfoCard
        id={selectedService?.id}
        type={selectedService?.type}
        operationType={selectedService.operationType}
        multiplier={selectedService.multiplier}
        deposits={selectedService.deposits}
        solutionId={selectedService.solution}
      />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar este tipo de servicio?</h4>
    </Modal>
  )
}

export default DeleteServiceModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{
  id: string
  type: string
  multiplier: number
  operationType: string
  deposits: number[]
  solutionId: string
}> = ({ id, type, multiplier, operationType, deposits, solutionId }) => (
  <header className={styles.infoCard}>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={type} label={'Tipo'} />
      <InfoItem data={multiplier} label={'Multiplicador'} />
      <InfoItem data={operationType} label={'Tipo de operación'} />
      <Tooltip title={'depositos'}>
        <InfoItem data={deposits.length} label={'Depósitos asociados'} />
      </Tooltip>
      {/* <InfoItem data={deposits.length} label={'Depósitos asociados'} /> */}
      <InfoItem data={solutionId} label={'Solución'} />
    </div>
  </header>
)
