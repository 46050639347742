import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Carousel from '../../Carousel/Carousel'

export type CarouselModalProps = {
  open: boolean
  images: string[]
  onClose: () => void
}

const CarouselModal: React.FC<CarouselModalProps> = ({ open, images, onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const handleCloseModal = () => {
    onClose()
  }

  useEffect(() => {
    if (open) {
      setSelectedImage(images.length ? images[0] : null)
    } else setSelectedImage(null)
  }, [images, open])

  return (
    <Modal
      visible={open}
      destroyOnClose
      title={'Visualizador de imágenes'}
      onCancel={handleCloseModal}
      centered
      style={{ width: 'unset' }}
      // bodyStyle={{ maxHeight: '80vh' }}
      footer={null}>
      <Carousel images={images} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
    </Modal>
  )
}

export default React.memo(CarouselModal)
