import React from 'react'
import loginActions from '../actions/login'
import { LoginState } from '../reducers/login'
import Select from '../../../components/Select'
import { AccountOption } from '../types/login'
import styles from './accountSelector.module.scss'

type LoginProps = {
  accountOptions: AccountOption[]

  loginActions: typeof loginActions
  loginReducer: LoginState
}

const AccountSelectorComponent = (props: LoginProps) => {
  const { loginActions, loginReducer, accountOptions } = props
  const { setAccountFromSelector } = loginActions
  const { selectedAccount } = loginReducer

  return (
    <Select
      loading={loginReducer.loadingAccounts}
      className={styles.select}
      selected={selectedAccount}
      placeholder="Seleccionar cuenta"
      options={accountOptions}
      onSelect={setAccountFromSelector}
    />
  )
}

export default AccountSelectorComponent
