import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_PAYMENT_COLLECTOR } from '../../../endpoints'

type Request = {
  token: string
  additionalInfo?: {
    cardholderIdentificationType: string
    cardholderIdentificationNumber: string
  }
  userId?: number
  sessionId?: number
}

export function add(request: Request): Promise<void> {
  return apiPrivate
    .post(`${URL_BASE_PAYMENT_COLLECTOR}/payment-methods`, snakecaseKeys(request))
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
