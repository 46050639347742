import { ComplaintHistory } from '@/projectApi/Complaints/list'
import { Photo } from './createComplaintModal'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'
import { CommonComplaintsActions } from './common'

export type ResolveComplaintState = {
  loading: boolean
  open: boolean
  photos: Photo[]
  reasonId: number
  isClosing: boolean
  confirmationOpen: boolean
  reasons: ComplaintsReasons[]
}

export enum CloseComplaintTypes {
  CLOSE_COMPLAINT_REQUEST = 'COMPLAINTS/UPDATE_VIEW/CLOSE_COMPLAINT_REQUEST',
  CLOSE_COMPLAINT_SUCCESS = 'COMPLAINTS/UPDATE_VIEW/CLOSE_COMPLAINT_SUCCESS',
  CLOSE_COMPLAINT_FAILURE = 'COMPLAINTS/UPDATE_VIEW/CLOSE_COMPLAINT_FAILURE'
}

export type CloseComplaintRequest = {
  type: typeof CloseComplaintTypes.CLOSE_COMPLAINT_REQUEST
}

export type CloseComplaintSuccess = {
  type: typeof CloseComplaintTypes.CLOSE_COMPLAINT_SUCCESS
}

export type CloseComplaintFailure = {
  type: typeof CloseComplaintTypes.CLOSE_COMPLAINT_FAILURE
}

export type CloseComplaintActions = CloseComplaintRequest | CloseComplaintSuccess | CloseComplaintFailure

export enum SettersResolveComplaintsTypes {
  SET_EVIDENCE = 'COMPLAINTS/RESOLVE/SET_EVIDENCE',
  SET_REASON_ID = 'COMPLAINTS/RESOLVE/SET_REASON_ID',
  SET_OPEN = 'COMPLAINTS/RESOLVE/SET_OPEN',
  SET_CONFIRMATION_OPEN = 'COMPLAINTS/RESOLVE/SET_CONFIRMATION_OPEN'
}

export interface SetReasonId {
  type: typeof SettersResolveComplaintsTypes.SET_REASON_ID
  payload: { reasonId: number }
}

export interface SetOpen {
  type: typeof SettersResolveComplaintsTypes.SET_OPEN
  payload: {
    open: boolean
    selectedEvidence?: ComplaintHistory
  }
}
export interface SetEvidence {
  type: typeof SettersResolveComplaintsTypes.SET_EVIDENCE
  payload: { photo: Photo[] }
}

export interface SetConfirmationOpen {
  type: typeof SettersResolveComplaintsTypes.SET_CONFIRMATION_OPEN
  payload: {
    confirmationOpen: boolean
  }
}

type SettersResolveComplaintActions = SetOpen | SetEvidence | SetConfirmationOpen | SetReasonId

export type ResolveComplaintAction = SettersResolveComplaintActions | CloseComplaintActions | CommonComplaintsActions
