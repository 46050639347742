import React, { Component } from 'react'
import { URL_BASE_USERS } from '../../endpoints'
import { Modal } from 'antd'
import { apiPrivate } from '../../api'
import { toast } from 'react-toastify'

const styles = {
  placeholder: {
    display: 'inline-block'
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  },
  inlineSelect: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '70%'
  },
  cont: {
    marginBottom: '6px'
  }
}

class DisablePanel extends Component {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.toggleDeletePanel(false)
  }

  notifyUserDisabled = () => toast('Usuario deshabilitado', { type: 'success' })

  delete() {
    return apiPrivate
      .delete(URL_BASE_USERS + '/users/' + this.props.user.GUID + '/disable')
      .then((response) => {
        this.handleClose()
      })
      .catch((error) => {
        if (error.response.data.description) {
          alert(error.response.data.description)
        }
      })
  }

  render() {
    return (
      <Modal
        title="Deshabilitar Usuario"
        visible={this.props.open}
        onOk={() => {
          this.delete()
          this.notifyUserDisabled()
        }}
        onCancel={this.handleClose}
        okText={'Deshabilitar'}
        cancelText={'Cancelar'}></Modal>
    )
  }
}

export default DisablePanel
