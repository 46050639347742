import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import actions from '../actions/objectChanges'
import removeObjectsActionsCreators from '../actions/removeObject'
import { eqProps, equals, forEachObjIndexed, isEmpty } from 'ramda'
import TableObjectChanges from '../components/tableObjectChanges'

const Container = (ownProps) => {
  const dispatch = useDispatch()

  const objectChangesActions = bindActionCreators(actions, dispatch)
  const removeObjectActions = bindActionCreators(removeObjectsActionsCreators, dispatch)

  const { objectID } = useParams()
  if (!objectID) {
    ownProps.history.push('objects')
  }

  const {
    objectChanges,
    error,
    params,
    loading,
    setSearchFilters,
    setPagination,
    searchFilters,
    pagination,
    filter,
    sorter: sorterProp
  } = useSelector((state) => state.Objects.objectChanges)
  const removeObjectReducer = useSelector((state) => state.Objects.removeObject)

  useEffect(() => {
    objectChangesActions.getObjectChangesFromObjectId(objectID, params)
    if (!removeObjectReducer.deletedReasonsFetched) {
      removeObjectActions.getObjectsDeletedReasons()
    }
  }, [])

  const getOrder = (order) => (order === 'descend' ? 'desc' : 'asc')

  const getKeyFilter = (key) => {
    switch (key) {
      case 'id':
        return 'id'
      default:
        return key
    }
  }

  const getColumn = (column) => {
    switch (column) {
      case 'id':
        return 'id'
      case 'userId':
        return 'user_id'
      default:
        return column
    }
  }

  const handleSearch = (dataIndex, text, confirm) => {
    const { params } = props
    const currentPagination = props.pagination
    const currentSearchFilters = props.searchFilters

    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    objectChangesActions.setSearchFilters(searchFilters)
    objectChangesActions.setPagination({ ...currentPagination, current: 1 })
    objectChangesActions.getObjectChangesFromObjectId(objectID, newParams)

    confirm()
  }

  const getNewFilterParams = (params, filters) => {
    let formatFilters = {}
    const offset = 0
    forEachObjIndexed((value, key) => {
      formatFilters = { ...formatFilters, [getKeyFilter(key)]: value[0] }
    }, filters)
    return { ...params, ...formatFilters, offset }
  }

  const handleChange = (pagination, filters, sorter, _) => {
    const { params } = props
    const currentFilter = filter
    const currentSorter = sorterProp
    const currentPagination = props.pagination

    if (!equals(filters, currentFilter)) {
      const newParams = { ...getNewFilterParams(params, filters) }

      objectChangesActions.setFilter(filters)
      objectChangesActions.setPagination({ ...currentPagination, current: 1 })
      objectChangesActions.getObjectChangesFromObjectId(objectID, newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order: orderSort, field } = sorter
      const order = getOrder(orderSort)
      const column = getColumn(field)
      const offset = 0

      objectChangesActions.setSorter(sorter)
      objectChangesActions.setPagination({ ...currentPagination, current: 1 })
      objectChangesActions.getObjectChangesFromObjectId(objectID, {
        ...params,
        offset,
        column,
        order
      })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current, pageSize } = pagination
      const offset = (current - 1) * pageSize
      const newParams = { ...params, offset }

      objectChangesActions.setPagination(pagination)
      objectChangesActions.getObjectChangesFromObjectId(objectID, newParams)
    }
  }

  const props = {
    ...ownProps,
    objectChanges,
    removeObjectReducer,
    ...objectChangesActions,
    error,
    loading,
    searchFilters,
    params,
    setSearchFilters,
    setPagination,
    pagination,
    handleSearch,
    handleChange
  }
  return <TableObjectChanges {...props} />
}

export default Container
