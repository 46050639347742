import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { AttributesListSortKey } from '../../../sections/Attributes/types/AttributesTable'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'
import camelcaseKeys from 'camelcase-keys'
import { convertToMoment } from '../../utils'
export interface Response {
  attributes: APIAttribute[]
  total: number
}

export interface ResponseApi {
  attributes: AttributeApi[]
  total: number
}

export interface AttributeApi {
  id: number
  data_type: string
  is_visible: boolean
  unit: string
  name: string
  createdAt: string | null
  deletedAt: string | null
  countries: Country[]
}

export interface APIAttribute {
  id: number
  data_type: string
  is_visible: boolean
  unit: string
  name: string
  createdAt: Moment | null
  deletedAt: Moment | null
  countries: Country[]
}

export interface Country {
  id: number
  label: string
  country_id: string
  options: Option[]
}

export interface Option {
  id: number
  data_type: string
  value: string
}

interface ListParams {
  productId?: number
  id?: number
  name?: string
  limit: number
  offset: number
  sort?: AttributesListSortKey
  order?: SortDirection
  visible?: string
}

type ResponseConvert = CamelCasedPropertiesDeep<Response>
export type Attribute = CamelCasedPropertiesDeep<APIAttribute>

export function list(params: ListParams): Promise<ResponseConvert> {
  const { productId, sort } = params

  const axiosParams = {
    ...params,
    product_id: productId,
    column: sort
  }

  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/attribute`, { params: axiosParams })
    .then((response: AxiosResponse<ResponseApi>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener los atributos')
      const responseTyped: ResponseConvert = {
        total: response.data.total,
        attributes:
          camelcaseKeys(response.data.attributes, { deep: true })?.map((attribute) => ({
            ...attribute,
            createdAt: convertToMoment(attribute.createdAt),
            deletedAt: convertToMoment(attribute.deletedAt)
          })) || []
      }
      return responseTyped
    })
    .catch((err) => {
      throw err
    })
}
