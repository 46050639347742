import React from 'react'
import { Modal } from 'antd'
import validateEmail from '../../../utils/validateEmail'
import sendEmailActions from '../actions/sendByMail'
import { SendByEmailState } from '../types/sendByMail'

export interface SendByEmailModalProps {
  sendByEmail: SendByEmailState
  sendEmailActions: typeof sendEmailActions
}

const styles = {
  modal: {
    maxWidth: 298
  },
  error: {
    color: 'red',
    marginTop: 6
  },
  placeholder: {
    display: 'inline-block'
  },
  inlineText: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '196px',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  }
}

const SendByEmailComponent = (props: SendByEmailModalProps) => {
  const { sendEmailActions, sendByEmail } = props
  const { setError, cancelSendByEmail, setEmail, sendByEmail: sendByEmailAction } = sendEmailActions
  const { open, email, error, proposalId, isLoading } = sendByEmail

  const handleSendEmail = (proposalId: string, email: string) => {
    if (validateEmail(email)) {
      sendByEmailAction({ proposalId, email })
    } else {
      setError({ error: 'Email invalido o vacio' })
    }
  }

  return (
    <Modal
      title="Enviar cotización por email"
      visible={open}
      onOk={() => handleSendEmail(proposalId, email)}
      onCancel={() => cancelSendByEmail()}
      okText="Enviar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading }}
      confirmLoading={isLoading}
      closable={!isLoading}
      maskClosable={!isLoading}
      style={styles.modal}>
      <div>
        <span style={styles.placeholder}>Email: </span>
        <input
          style={styles.inlineText}
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail({ email: e.target.value })}
        />
      </div>
      {error !== '' ? <div style={styles.error}>{error}</div> : null}
    </Modal>
  )
}

export default SendByEmailComponent
