import React from 'react'
import InputGroup from '@/components/InputGroup'
import { Col, Row } from 'antd'
import styles from '../ComplaintUpdateView.module.scss'
import { InputTitle } from '@/components/NewCard/NewCard'
import ColorBadge from '@/components/ColorBadge'
import { Priority } from '../../types/updateView'
import { TableActionBar } from '@/components/TableActionBar'
import { useLocalization } from '@/packages/localization'
import { PRIORITY_TYPES, STATUS_OPTIONS } from '../../utils'
import { ComplaintHistory } from '@/projectApi/Complaints/list'
import { parseMomentDate } from '@/components/DynamicTable/utils/utils'

type ComplaintHistoryProps = {
  setOpenEvidence: (value: { selectedEvidence: ComplaintHistory; setOpenEvidence: boolean }) => void
  complaintHistories: ComplaintHistory[] | null
  isCloseDisable: boolean
}

const ComplaintHistoryComponent = ({ complaintHistories, setOpenEvidence, isCloseDisable }: ComplaintHistoryProps) => {
  const { strings } = useLocalization()
  return (
    <div style={{ width: '100%' }} className={styles.complaintHistory}>
      <Row>
        <InputGroup title={strings.ComplaintsViewScreen.updateView.history.title}>
          <div
            style={{
              maxHeight: isCloseDisable ? 'calc(100dvh - 265px)' : '400px'
            }}
            className={styles.containerItems}>
            {complaintHistories
              ? complaintHistories
                  .slice()
                  .reverse()
                  .map((item, index) => (
                    <ComplaintHistoryItem key={index} historyItem={item} setOpenEvidence={setOpenEvidence} />
                  ))
              : strings.ComplaintsViewScreen.updateView.history.emptyHistory}
          </div>
        </InputGroup>
      </Row>
    </div>
  )
}

type ComplaintHistoryItemProps = {
  historyItem: ComplaintHistory
  setOpenEvidence?: (value: { selectedEvidence: ComplaintHistory; setOpenEvidence: boolean }) => void
}
export const ComplaintHistoryItem = ({ historyItem, setOpenEvidence }: ComplaintHistoryItemProps) => {
  const {
    assignedUserId,
    createdAt,
    description,
    id,
    photos,
    priority,
    status,
    updatedAt,
    deletedAt,
    complaintId,
    assignedUserName,
    updatedByAgentId,
    updatedByAgentName
  } = historyItem

  const { strings } = useLocalization()

  const statusFound = STATUS_OPTIONS.find((sf) => sf.value === status)

  return (
    <div className={styles.wrapper}>
      <Row
        gutter={[16, 16]}
        align="middle"
        style={{ flexWrap: 'nowrap', overflowX: 'auto', flexDirection: 'row', display: 'flex' }}>
        <Col
          style={{
            flex: 1
          }}>
          <InputTitle title={strings.ComplaintsViewScreen.updateView.history.updateDateTitle} />
          <div>{parseMomentDate(updatedAt, 'left', true)}</div>
        </Col>
        <Col
          style={{
            flex: 1
          }}>
          <InputTitle title={strings.ComplaintsViewScreen.updateView.history.userCreator} />
          <div>
            {updatedByAgentId} - {updatedByAgentName}
          </div>
        </Col>
        <Col
          style={{
            flex: 1
          }}>
          <InputTitle title={strings.ComplaintsViewScreen.updateView.history.assignedUser} />
          <div>
            {assignedUserId} - {assignedUserName}
          </div>
        </Col>
        <Col
          style={{
            flex: 1
          }}>
          <InputTitle title={strings.ComplaintsViewScreen.filters.priority} />
          <div>{Priority[priority as keyof typeof PRIORITY_TYPES]}</div>
        </Col>
        <Col
          style={{
            flex: '0 0 auto'
          }}>
          <InputTitle title={strings.ComplaintsViewScreen.updateView.history.status} />
          {statusFound ? (
            <ColorBadge
              {...statusFound?.styleProps}
              extraStyles={{
                width: '100px',
                fontSize: '.8rem',
                fontWeight: '500',
                border: '1px solid',
                whiteSpace: 'nowrap'
              }}>
              {statusFound?.text || status}
            </ColorBadge>
          ) : (
            '-'
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: '16px', flexWrap: 'wrap' }}>
        <Col span={24}>
          <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.descriptionTitle} />
          <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{description}</div>
        </Col>
      </Row>
      {setOpenEvidence && (
        <Row style={{ marginTop: '16px' }}>
          <Col span={24}>
            <TableActionBar.ButtonAction
              titleButton={strings.ComplaintsViewScreen.updateView.evidenceModal.title}
              variant="secondary"
              customClassName={styles.evidenceBtn}
              customIcon={<></>}
              onClickButton={() =>
                setOpenEvidence({
                  selectedEvidence: {
                    id,
                    complaintId,
                    assignedUserId,
                    assignedUserName,
                    deletedAt,
                    description,
                    updatedAt,
                    photos,
                    createdAt,
                    userId: id,
                    priority,
                    status,
                    updatedByAgentId,
                    updatedByAgentName
                  },
                  setOpenEvidence: true
                })
              }
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ComplaintHistoryComponent
