import React from 'react'
import { Field, useField } from 'formik'
import { SetOptional } from 'type-fest'
import { INPUTS_MASKS, TextField } from '../TextFieldMasked'

export const FormikTextInput = <Form extends Record<string, unknown>, FieldKey extends Extract<keyof Form, string>>({
  name,
  mask,
  onChange,
  fieldSetter,
  focusSetter,
  ...props
}: SetOptional<Omit<React.ComponentProps<typeof TextField>, 'value'>, 'onChange'> & {
  name: FieldKey
  label: string
  mask?: INPUTS_MASKS
  maskChar?: string
  focusSetter?: (field: string) => void
  fieldSetter: ({ field, value }: { field: string; value: string }) => void
}) => {
  const [field, meta, helpers] = useField(name)
  const showError = meta.touched && meta.error
  const handleChange = (value: string) => {
    helpers.setValue(value)
    onChange?.(value)
    fieldSetter({ field: field.name, value })
    focusSetter?.(field.name)
    // helpers.setTouched(true)
  }
  return (
    <Field name={field.name}>
      {({ textField }: { textField: typeof Field }) => (
        <TextField
          {...props}
          mask={mask}
          rightIcon={field.value && !showError && 'check-circle'}
          label={props.label}
          error={!!showError}
          errorText={meta.error}
          onChange={handleChange}
          value={field.value}
          onBlured={() => {
            helpers.setTouched(true)
          }}
          inputProps={textField}
        />
      )}
    </Field>
  )
}
