import Payment from 'payment'
import { API } from '../../../projectApi'
import { createCardToken, getPaymentMethods } from '../../../services/mercado-pago'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { getErrorMessage, handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  ADD_CARD_FAILURE,
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  AddCardFailure,
  AddCardRequest,
  AddCardSuccess,
  DocumentType,
  IdentificationTypeRules,
  InputErrors,
  SET_CARD_NUMBER,
  SET_CARD_OWNER_NAME,
  SET_CVC,
  SET_DOCUMENT_NUMBER,
  SET_DOCUMENT_TYPE,
  SET_EXPIRATION_DATE,
  SET_INPUT_ERROR,
  SET_OPEN,
  SetCVC,
  SetCardNumber,
  SetCardOwnerName,
  SetDocumentNumber,
  SetDocumentType,
  SetExpirationDate,
  SetInputError,
  SetOpen
} from '../types/cardModal'
import { createSpreedlyCardToken } from '../../../services/spreedly'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

const CardsModalActionCreators = {
  setModalOpen: (open: boolean): SetOpen => {
    return { type: SET_OPEN, payload: { open } }
  },

  setCardNumber:
    (cardNumber: string): AppThunk =>
    (dispatch) => {
      const success: SetCardNumber = {
        type: SET_CARD_NUMBER,
        payload: { cardNumber }
      }

      dispatch(success)
      if (!Payment.fns.validateCardNumber(cardNumber)) {
        const failure: SetInputError = {
          type: SET_INPUT_ERROR,
          payload: { input: InputErrors.CARD_NUMBER, errorMessage: 'Numero de tarjeta inválido' }
        }

        dispatch(failure)
      }
    },

  setExpirationDate:
    (expirationDate: string): AppThunk =>
    (dispatch) => {
      const success: SetExpirationDate = {
        type: SET_EXPIRATION_DATE,
        payload: { expirationDate }
      }

      dispatch(success)
      if (!Payment.fns.validateCardExpiry(expirationDate)) {
        const failure: SetInputError = {
          type: SET_INPUT_ERROR,
          payload: { input: InputErrors.EXPIRATION_DATE, errorMessage: 'Fecha de vencimiento inválida' }
        }

        dispatch(failure)
      }
    },

  setCVC:
    (CVC: string): AppThunk =>
    (dispatch) => {
      const success: SetCVC = {
        type: SET_CVC,
        payload: { CVC }
      }

      dispatch(success)
      if (!Payment.fns.validateCardCVC(CVC)) {
        const failure: SetInputError = {
          type: SET_INPUT_ERROR,
          payload: { input: InputErrors.CVC, errorMessage: 'CVC inválido' }
        }

        dispatch(failure)
      }
    },

  setCardOwnerName:
    (cardOwnerName: string): AppThunk =>
    (dispatch) => {
      const success: SetCardOwnerName = {
        type: SET_CARD_OWNER_NAME,
        payload: { cardOwnerName }
      }

      dispatch(success)
      if (!/^[a-z-A-Zñáéíóú\s]*$/.test(cardOwnerName)) {
        const failure: SetInputError = {
          type: SET_INPUT_ERROR,
          payload: { input: InputErrors.CARD_OWNER_NAME, errorMessage: 'Este campo solo acepta letras' }
        }

        dispatch(failure)
      }
    },

  setDocumentType: (documentType: string): SetDocumentType => {
    return { type: SET_DOCUMENT_TYPE, payload: { documentType } }
  },

  setDocumentNumber:
    (documentNumber: string, rules?: IdentificationTypeRules | null): AppThunk =>
    (dispatch) => {
      const success: SetDocumentNumber = {
        type: SET_DOCUMENT_NUMBER,
        payload: { documentNumber }
      }

      dispatch(success)

      if (rules) {
        const { minLength, maxLength, type } = rules

        const minMax = `${minLength},${maxLength}`

        const typeRegExp = {
          number: '0-9',
          text: 'a-zA-Z0-9'
        }

        const typeRequired = type === DocumentType.NUMBER ? 'números' : 'caracteres'

        const validDocument = new RegExp(`^[${typeRegExp[type]}]{${minMax}}$`)

        if (validDocument.test(documentNumber)) {
          return
        } else {
          const error =
            minLength !== maxLength
              ? `El identificador debe contener entre ${minLength} o ${maxLength} ${typeRequired}`
              : `El identificador debe contener ${minLength} ${typeRequired}`

          const failure: SetInputError = {
            type: SET_INPUT_ERROR,
            payload: {
              input: InputErrors.DOCUMENT_NUMBER,
              errorMessage: error
            }
          }
          dispatch(failure)
        }
      }
      if (!rules) {
        const failure: SetInputError = {
          type: SET_INPUT_ERROR,
          payload: {
            input: InputErrors.DOCUMENT_NUMBER,
            errorMessage: 'Tipo de DNI inválido'
          }
        }
        dispatch(failure)
      }
    },

  saveCard:
    ({
      userId,
      cardNumber,
      expirationDate,
      CVC,
      cardOwnerName,
      documentType,
      documentNumber
    }: {
      userId: string
      cardNumber: string
      expirationDate: string
      CVC: string
      cardOwnerName: string
      documentType: string
      documentNumber: string
    }): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const cardNumberFormatted = cardNumber.replace(/ /g, '').trim()

      const request: AddCardRequest = {
        type: ADD_CARD_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Agregando tarjeta. Por favor espere...')

      const split = expirationDate.split('/')
      const cardExpirationMonth = split[0]
      const cardExpirationYear = split[1]

      try {
        const cardToken = await createCardToken({
          cardNumber: cardNumberFormatted,
          cardExpirationMonth,
          cardExpirationYear,
          securityCode: CVC,
          cardholderName: cardOwnerName,
          identificationType: documentType,
          identificationNumber: documentNumber
        })

        const paymentMethod = await getPaymentMethods({
          bin: cardNumber.replace(' ', '').substring(0, 6)
        })
        const paymentMethodID = paymentMethod.results[0].id

        await API.UserProfile.paymentMethods.add({ userId, paymentMethod: paymentMethodID, token: cardToken.id })
        const success: AddCardSuccess = {
          type: ADD_CARD_SUCCESS
        }
        dispatch(success)
        Emitter.emit(Events.CreditCard.ADD_NEW_CARD)
        Emitter.emit(Events.CreditCardComponent.CLEAR_STATE)
        setToastSuccessUpdate(toast, { render: 'Tarjeta guardada' })
      } catch (error: any) {
        const message = error?.response?.data?.description ?? 'Algo salió mal'
        const failure: AddCardFailure = {
          type: ADD_CARD_FAILURE,
          payload: {
            errorMessage: message
          }
        }

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, ADD_CARD_FAILURE, dispatch)
        dispatch(failure)
      }
    },
  saveSpreedlyCard:
    ({
      userId,
      cardNumber,
      expirationDate,
      CVC,
      cardOwnerName,
      documentType,
      documentNumber,
      country
    }: {
      userId: string
      cardNumber: string
      expirationDate: string
      CVC: string
      cardOwnerName: string
      documentType: string
      documentNumber: string
      country: string
    }): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const cardNumberFormatted = cardNumber.replace(/ /g, '').trim()

      const request: AddCardRequest = {
        type: ADD_CARD_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Agregando tarjeta. Por favor espere...')

      const { month, year } = Payment.fns.cardExpiryVal(expirationDate)

      try {
        const spreedlyResponse = await createSpreedlyCardToken({
          number: cardNumberFormatted,
          fullName: cardOwnerName,
          verificationValue: CVC,
          month,
          year
        })

        const token = spreedlyResponse.transaction.payment_method?.token

        if (!token) {
          setToastErrorUpdate(toast, { render: 'Hubo un error al crear la tarjeta. Inténtelo con una distinta' })
          return
        }

        await API.PaymentCollector.paymentMethods.add({
          userId: Number(userId),
          token,
          ...(country === CountryIdCode.ARGENTINA && {
            additionalInfo: {
              cardholderIdentificationType: documentType,
              cardholderIdentificationNumber: documentNumber
            }
          })
        })

        const success: AddCardSuccess = {
          type: ADD_CARD_SUCCESS
        }
        dispatch(success)
        Emitter.emit(Events.CreditCard.ADD_NEW_CARD)
        Emitter.emit(Events.CreditCardComponent.CLEAR_STATE)
        setToastSuccessUpdate(toast, { render: 'Tarjeta guardada' })
      } catch (error: any) {
        const message = getErrorMessage(error)

        const failure: AddCardFailure = {
          type: ADD_CARD_FAILURE,
          payload: {
            errorMessage: message
          }
        }

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, ADD_CARD_FAILURE, dispatch)
        dispatch(failure)
      }
    }
}

export default CardsModalActionCreators
