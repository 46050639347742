import { apiPrivate } from '../../../api'
import { URL_BASE_PROFILE } from '../../../endpoints'

interface DeleteParams {
  cardId: number
  userId: number
}

export function disable(params: DeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_PROFILE}/payment_methods/cards/${params.cardId}?user_id=${params.userId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
