export const ANTICIPATED_HOURS = 0.5
export const SHOW_MONTHS = 3

export enum TransportTypesPricing {
  CUSTOM = 'custom',
  COMMON = 'common',
  FREE = 'free'
}

export enum TransportPricingLabel {
  CUSTOM = 'Tarifa plena',
  COMMON = 'Tarifa consolidada',
  FREE = 'Gratis'
}

export enum MetricTranslate {
  convert = 'Convertidas',
  current = 'Vigentes',
  expired = 'Vencidas'
}

export const PRICE_BY = 'items'

export const moneyFormat = { decimals: 2, withThousandSeparator: true, withSpace: true }
