export type TPickingsMetrics = {
  total: number
  quantityActivePickings: number
  quantityClosedPickings: number
  quantityCancelledPickings: number
}

export type PickingMetrics = {
  id: string
  metric: string
  quantity: number
}

export type PickingsMetricsState = {
  quantityMetrics: PickingMetrics[]
  fetchingMetrics: boolean
}

export enum GetPickingsMetricsTypes {
  GET_PICKINGS_METRICS_REQUEST = 'PICKINGS/PICKINGS_METRICS/GET_PICKING_METRICS_REQUEST',
  GET_PICKINGS_METRICS_SUCCESS = 'PICKINGS/PICKINGS_METRICS/GET_PICKING_METRICS_SUCCESS',
  GET_PICKINGS_METRICS_FAILURE = 'PICKINGS/PICKINGS_METRICS/GET_PICKING_METRICS_FAILURE'
}

export interface GetPickingsMetricsRequest {
  type: typeof GetPickingsMetricsTypes.GET_PICKINGS_METRICS_REQUEST
}

export interface GetPickingsMetricsSuccess {
  type: typeof GetPickingsMetricsTypes.GET_PICKINGS_METRICS_SUCCESS
  payload: {
    quantityMetrics: PickingMetrics[]
  }
}

export interface GetPickingsMetricsFailure {
  type: typeof GetPickingsMetricsTypes.GET_PICKINGS_METRICS_FAILURE
}

type GetPickingsMetricsActions = GetPickingsMetricsRequest | GetPickingsMetricsSuccess | GetPickingsMetricsFailure

export type PickingsMetricsActions = GetPickingsMetricsActions
