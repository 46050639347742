import types from '../types/massBillingModal'

const initialRoot = {
  massBillingModalOpen: false,
  error: '',
  message: ''
}

function massBillingModalReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.OPEN_MODAL_MASS_BILLING: {
      return {
        ...state,
        massBillingModalOpen: true,
        error: ''
      }
    }
    case types.CLOSE_MODAL_MASS_BILLING: {
      return initialRoot
    }
    case types.MASS_BILLING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: ''
      }
    }
    case types.MASS_BILLING_SUCCESS: {
      return {
        ...state,
        message: action.payload.message
      }
    }
    case types.MASS_BILLING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    default:
      return state
  }
}

export default massBillingModalReducer
