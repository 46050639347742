import React from 'react'
import { Icon } from 'antd'
import styled from 'styled-components'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'
import { formatCard } from '../../../common/operations/selectors/payment-mp'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import PaymentTypes, { PaymentTypesProps } from './payments/PaymentTypes'
import commonActionCreators from '../actions/common'
import paymentMPActionCreators from '../actions/payment-mp'
import NewCard from './payments/NewCard'
import { PaymentProps } from '../containers/Payment'
import { PaymentScreens } from '../constants'
import { PaymentMpState } from '../reducers/payment-mp'
import { Card } from '../types/payment-mp'
import { formatSpreedlyPM } from '../utils/payment'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import { useFeatureManager } from '../../featureManager/utils'
import { FeatureType } from '../../featureManager/types'

const StyledIcon = styled(Icon)`
  font-size: 32px;
  color: #5d58f7;
`

export type PaymentComponentProps = PaymentProps & {
  commonState: any
  paymentMpState: PaymentMpState
  commonActions: typeof commonActionCreators
  paymentMPActions: typeof paymentMPActionCreators
}

const Payment = (props: PaymentComponentProps) => {
  const { paymentScreen, commonState, paymentMpState, commonActions, paymentMPActions } = props
  const spreedlyFeature = useFeatureManager(FeatureType.SPREEDLY_PAYMENT)

  const selectCard = (card: Card) => {
    commonActions.setPaymentBy(PAYMENT_BY_VALUES.CREDIT_CARD_MP)
    paymentMPActions.setCardID(card.ID)
  }

  const selectSpreedlyPM = (paymentMethod: PaymentMethod) => {
    commonActions.setPaymentBy(PAYMENT_BY_VALUES.SPREEDLY)
    paymentMPActions.setSpreedlyPMID(paymentMethod.id)
  }

  const selectNewMethod = (paymentBy: string) => () => {
    commonActions.setPaymentBy(paymentBy)
    if (paymentBy === PAYMENT_BY_VALUES.CREDIT_CARD_MP) {
      paymentMPActions.setCardID(0)
    }
    if (paymentBy === PAYMENT_BY_VALUES.SPREEDLY) {
      paymentMPActions.setSpreedlyPMID(0)
    }
  }

  const commonPayments: PaymentTypesProps[] = [
    {
      key: 'new_card',
      title: 'Nueva tarjeta de Crédito o Débito',
      description: '',
      icon: <StyledIcon type="credit-card" />,
      onClick: selectNewMethod(spreedlyFeature ? PAYMENT_BY_VALUES.SPREEDLY : PAYMENT_BY_VALUES.CREDIT_CARD_MP),
      selected:
        (commonState.paymentBy === PAYMENT_BY_VALUES.CREDIT_CARD_MP && paymentMpState.card_id === 0) ||
        (commonState.paymentBy === PAYMENT_BY_VALUES.SPREEDLY && paymentMpState.spreedlyPMID === 0)
    },
    {
      key: 'offline',
      title: 'No cobrar',
      description: '',
      icon: <StyledIcon type="close-circle" />,
      onClick: selectNewMethod(PAYMENT_BY_VALUES.OFFLINE),
      selected: commonState.paymentBy === PAYMENT_BY_VALUES.OFFLINE
    }
  ]

  const cardsPayments: PaymentTypesProps[] = paymentMpState.cards.map((card: Card) => {
    return {
      key: String(card.ID),
      title: formatCard(card),
      description: `Vencimiento: ${card.Expiration}`,
      icon: <LazyLoadImage effect="opacity" style={{ width: '34px' }} src={card.TypePhoto} alt="card" />,
      onClick: () => selectCard(card),
      selected: commonState.paymentBy === PAYMENT_BY_VALUES.CREDIT_CARD_MP && paymentMpState.card_id === card.ID
    }
  })

  const spreedlyPaymentMethods: PaymentTypesProps[] = paymentMpState.spreedlyPM.map((paymentMethod) => {
    return {
      key: String(paymentMethod.id),
      title: formatSpreedlyPM(paymentMethod),
      description: `Vencimiento: ${paymentMethod.paymentMethod.month}/${paymentMethod.paymentMethod.year}`,
      icon: <StyledIcon type="credit-card" />,
      onClick: () => selectSpreedlyPM(paymentMethod),
      selected: commonState.paymentBy === PAYMENT_BY_VALUES.SPREEDLY && paymentMpState.spreedlyPMID === paymentMethod.id
    }
  })

  const options = [...cardsPayments, ...spreedlyPaymentMethods, ...commonPayments]

  return (
    <div>
      {paymentScreen === PaymentScreens.PAYMENT_SELECTION &&
        options.map(({ key, ...option }) => <PaymentTypes key={key} {...option} />)}
      {paymentScreen === PaymentScreens.NEW_CARD && <NewCard {...props} />}
    </div>
  )
}

export default Payment
