import { TransactionStatusId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { Metrics } from '../../../projectApi/TransactionHandler/Transactions/Metrics/list'
import {
  OperationType,
  Transaction,
  TransactionsListSortKey,
  TypeTransaction
} from '../../../projectApi/TransactionHandler/Transactions/list'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type Pagination = {
  // TODO: Unify all separate pagination types into one
  page: number
  total: number
  pageSize: number
}

export enum TransactionsTableSearchKey {
  CLIENT = 'client',
  CLIENT_ID = 'clientId',
  ID_TX = 'id',
  ID_OP = 'operationId'
}
export type TransactionsTableSearchFilter = SearchFilter<TransactionsTableSearchKey>

export enum TransactionsTableDateRangeKey {
  CREATION_DATE = 'creationDate',
  RESERVATION_DATE = 'reservationDate',
  CONFIRMATION_DATE = 'confirmationDate'
}

export type FormattedForecast = {
  deposits: {
    transactions: number
    objects: number
    items: number
    itemsM3: number
    objectsM3: number
  }
  removals: {
    transactions: number
    objects: number
    items: number
    itemsM3: number
    objectsM3: number
  }
  objects: {
    totalObjects: number
    totalObjectsM3: number
  }
}

export type TransactionsTableDateRangeFilter = DateRangeFilter<TransactionsTableDateRangeKey>

export type TransactionsTableSortKey = TransactionsListSortKey
export type TransactionsTableSort = Sort<TransactionsTableSortKey>

export enum TransactionsTableCategoryFilterKey {
  TRANSACTION_TYPE = 'transactionType',
  STATUS = 'status',
  OPERATION_TYPE = 'operationType'
}
export type TransactionsTableCategoryFilter = CategoryFilter<
  TransactionsTableCategoryFilterKey,
  TransactionStatusId | TypeTransaction | OperationType
>

export interface TransactionsTableState {
  transactions: Transaction[]
  loadingTransactions: boolean
  loadingReport: boolean
  selectedTransactions: Transaction[]
  pagination: Pagination
  categoryFilter: TransactionsTableCategoryFilter
  searchFilters: TransactionsTableSearchFilter[]
  dateRangeFilters: TransactionsTableDateRangeFilter[]
  sort: TransactionsTableSort
  error: string
  metrics: Metrics
  conflict: boolean
  loadingMetrics: boolean
}

export const SET_METRICS_WITH_CONFLICT = 'TRANSACTIONS/TABLE/SET_METRICS_WITH_CONFLICT'

export interface SetMetricsWithConflict {
  type: typeof SET_METRICS_WITH_CONFLICT
  payload: { conflict: boolean }
}

export const GET_TRANSACTIONS_METRICS_REQUEST = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_METRICS_REQUEST'
export const GET_TRANSACTIONS_METRICS_SUCCESS = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_METRICS_SUCCESS'
export const GET_TRANSACTIONS_METRICS_FAILURE = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_METRICS_FAILURE'

export interface GetTransactionsMetricsRequest {
  type: typeof GET_TRANSACTIONS_METRICS_REQUEST
  payload: {
    silentLoading?: boolean
  }
}

export interface GetTransactionsMetricsSuccess {
  type: typeof GET_TRANSACTIONS_METRICS_SUCCESS
  payload: {
    metrics: Metrics
  }
}

export interface GetTransactionsMetricsFailure {
  type: typeof GET_TRANSACTIONS_METRICS_FAILURE
  payload: { error: string }
}

export type GetTransactionMetricsAction =
  | GetTransactionsMetricsRequest
  | GetTransactionsMetricsSuccess
  | GetTransactionsMetricsFailure

export const TOGGLE_SELECTED_TRANSACTION = 'TRANSACTIONS/TABLE/TOGGLE_SELECTED_TRANSACTION'

export interface ToggleSelectedTransaction {
  type: typeof TOGGLE_SELECTED_TRANSACTION
  payload: {
    transaction: Transaction
  }
}

export const CLEAR_STATE = 'TRANSACTIONS/TABLE/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export const CLEAR_SELECTED_TRANSACTIONS = 'TRANSACTIONS/TABLE/CLEAR_SELECTED_TRANSACTIONS'

export interface ClearSelectedTransactions {
  type: typeof CLEAR_SELECTED_TRANSACTIONS
}

export const TOGGLE_ALL_TRANSACTIONS = 'TRANSACTIONS/TABLE/TOGGLE_ALL_TRANSACTIONS'

export interface ToggleAllTransactions {
  type: typeof TOGGLE_ALL_TRANSACTIONS
}

export const GET_TRANSACTIONS_REQUEST = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_REQUEST'
export const GET_TRANSACTIONS_SUCCESS = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAILURE = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_FAILURE'

export interface GetTransactionsRequest {
  type: typeof GET_TRANSACTIONS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: TransactionsTableSearchFilter[]
    dateRangeFilters?: TransactionsTableDateRangeFilter[]
    categoryFilter?: TransactionsTableCategoryFilter
    sort?: TransactionsTableSort
    silentLoading?: boolean
    resetSelectedTransactions?: boolean
    conflict?: boolean
  }
}

export interface GetTransactionsSuccess {
  type: typeof GET_TRANSACTIONS_SUCCESS
  payload: {
    total: number
    transactions: Transaction[]
  }
}

export interface GetTransactionsFailure {
  type: typeof GET_TRANSACTIONS_FAILURE
  payload: {
    error: string
  }
}

export type GetTransactionsAction = GetTransactionsRequest | GetTransactionsSuccess | GetTransactionsFailure

export const GET_TRANSACTIONS_REPORT_REQUEST = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_REPORT_REQUEST'
export const GET_TRANSACTIONS_REPORT_SUCCESS = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_REPORT_SUCCESS'
export const GET_TRANSACTIONS_REPORT_FAILURE = 'TRANSACTIONS/TABLE/GET_TRANSACTIONS_REPORT_FAILURE'

export interface GetTransactionsReportRequest {
  type: typeof GET_TRANSACTIONS_REPORT_REQUEST
}

export interface GetTransactionsReportSuccess {
  type: typeof GET_TRANSACTIONS_REPORT_SUCCESS
}

export interface GetTransactionsReportFailure {
  type: typeof GET_TRANSACTIONS_REPORT_FAILURE
}

export type GetTransactionsReportAction =
  | GetTransactionsReportRequest
  | GetTransactionsReportSuccess
  | GetTransactionsReportFailure

export type TransactionsTableAction =
  | GetTransactionsAction
  | GetTransactionsReportAction
  | ClearState
  | ToggleSelectedTransaction
  | ClearSelectedTransactions
  | ToggleAllTransactions
  | GetTransactionMetricsAction
  | SetMetricsWithConflict
