import { combineReducers } from 'redux'
import edit from './edit'
import removePhoto from './removePhoto'
import removeObject from './removeObject'
import markPhotoAsMain from './markPhotoAsMain'
import objectChanges from './objectChanges'

const rootReducer = combineReducers({
  edit,
  removePhoto,
  markPhotoAsMain,
  removeObject,
  objectChanges
})

export default rootReducer
