export default {
  FETCH_DEPOSITS: 'DEPOSITS/FETCH_DEPOSITS',
  FETCH_DEPOSITS_SUCCESS: 'DEPOSITS/FETCH_DEPOSITS_SUCCESS',
  FETCH_DEPOSITS_FAIL: 'DEPOSITS/FETCH_DEPOSITS_FAIL',
  FETCH_DEPOSITS_REPORT: 'DEPOSITS/FETCH_DEPOSITS_REPORT',
  FETCH_DEPOSITS_REPORT_SUCCESS: 'DEPOSITS/FETCH_DEPOSITS_REPORT_SUCCESS',
  FETCH_DEPOSITS_REPORT_FAIL: 'DEPOSITS/FETCH_DEPOSITS_REPORT_FAIL',
  SET_SORTER: 'DEPOSITS/SET_SORTER',
  SET_FILTER: 'DEPOSITS/SET_FILTER',
  SET_PAGINATION: 'DEPOSITS/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'DEPOSITS/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'DEPOSITS/CLEAN_FILTERS'
}
