import { Prospect } from '../entities'

export const processRowToHistory = (row: Prospect) => {
  return {
    id: row.id,
    createdAt: row.updatedAt,
    deletedAt: row.deletedAt,
    prospectId: row.id,
    firstName: row.firstName,
    lastName: row.lastName,
    phone: row.phone,
    email: row.email,
    country: row.country,
    userId: row.userId,
    gender: row.gender,
    provider: row.provider,
    providerId: row.providerId,
    sourceId: row.sourceId,
    assignedAgentId: row.assignedAgentId,
    prospectTypeId: row.prospectTypeId,
    qualified: row.qualified,
    duplicationCode: row.duplicationCode,
    rpValidate: {
      createdAt: row.rpValidate.createdAt
    },
    peopleId: row.peopleId,
    contactMediumsId: row.contactMediumsId,
    statusId: row.statusId,
    statusIdNew: row.statusIdNew,
    statusUpdatedAt: row.createdAt,
    assignedAgent: {
      id: row.assignedAgent.id,
      createdAt: row.assignedAgent.createdAt,
      updatedAt: row.assignedAgent.updatedAt,
      deletedAt: row.assignedAgent.deletedAt,
      firstName: row.assignedAgent.firstName,
      lastName: row.assignedAgent.lastName,
      email: row.assignedAgent.email,
      userGuid: row.assignedAgent.userGuid,
      userId: row.assignedAgent.userId,
      roleId: row.assignedAgent.roleId
    },
    state: {
      stateId: row.state.stateId,
      qualified: row.state.qualified,
      descriptions: row.state.descriptions,
      available: row.state.available,
      createAt: row.state.createAt,
      updateAt: row.state.updateAt,
      deletedAt: row.state.deletedAt
    }
  }
}
