import { API } from '../../../projectApi'
import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  CANCEL_DELETE_PRODUCT_MODAL,
  CLOSE_DELETE_PRODUCT_MODAL,
  CancelDeleteProductModal,
  CloseDeleteProductModal,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DeleteProductRequest,
  DeleteProductSuccess,
  OPEN_DELETE_PRODUCT_MODAL,
  OpenDeleteProductModal,
  SET_ERROR,
  SetError
} from '../types/deleteModal'
import ProductsTableActionCreators from './productTable'

const DeleteProductActionCreators = {
  setError: ({ error }: { error: string }): SetError => ({
    type: SET_ERROR,
    payload: { error }
  }),
  openDeleteModal: ({ product }: { product: FormattedProduct }): OpenDeleteProductModal => {
    return {
      type: OPEN_DELETE_PRODUCT_MODAL,
      payload: { product }
    }
  },
  closeDeleteProductModal: (): CloseDeleteProductModal => ({
    type: CLOSE_DELETE_PRODUCT_MODAL
  }),
  cancelDeleteProductModal: (): CancelDeleteProductModal => ({
    type: CANCEL_DELETE_PRODUCT_MODAL
  }),

  deleteProduct:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const request: DeleteProductRequest = {
        type: DELETE_PRODUCT_REQUEST,
        payload: { id }
      }

      dispatch(request)

      try {
        await API.CategoryCreation.Product.deleteProduct({ id })

        const success: DeleteProductSuccess = {
          type: DELETE_PRODUCT_SUCCESS
        }

        dispatch(success)
        const countryCode = getState().CountrySelector.countrySelected.code
        const state = getState().Products.table
        const { getProducts } = ProductsTableActionCreators
        const { pagination, Column, searchFilters, sort, categoryFilter } = state
        const params = { countryCode, pagination, Column, searchFilters, sort, categoryFilter }
        dispatch(getProducts(params))
      } catch (error) {
        handleErrorsWithAction(error, DELETE_PRODUCT_FAILURE, dispatch)
      }
    }
}

export default DeleteProductActionCreators
