import React from 'react'
import { bool, func, shape, string } from 'prop-types'
import { Button, Modal, Spin } from 'antd'
import Dropzone from 'react-dropzone'
import DeleteConfirmationModal from '../containers/deletePhoto'

const styles = {
  photo: {
    width: '120px',
    height: '120px',
    display: 'inline-block',
    marginLeft: '3px',
    marginRight: '3px'
  },
  dropzone: {
    display: 'flex',
    height: '200px',
    margin: 'auto',
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '5px',
    marginTop: '6px'
  },
  dropzoneContainer: {
    margin: 'auto',
    textAlign: 'center'
  },
  dropzoneHeader: {
    margin: 'auto',
    fontSize: '20px'
  },

  dropzoneText: {
    fontSize: '12px'
  },
  centerThings: {
    width: '95%',
    textAlign: 'center'
  },
  deletePhoto: {
    backgroundColor: '#a8361e',
    color: 'white',
    fontSize: '11px',
    textAlign: 'center',
    width: '120px',
    marginLeft: '3px',
    marginRight: '3px',
    cursor: 'pointer'
  },
  setDefaultPhoto: {
    backgroundColor: '#5d58f7',
    color: 'white',
    fontSize: '11px',
    textAlign: 'center',
    marginBottom: '6px',
    width: '120px',
    marginLeft: '3px',
    marginRight: '3px',
    cursor: 'pointer'
  },
  error: {
    color: 'red',
    textAlign: 'center'
  }
}

const PhotosModal = (props) => {
  const { open, error, object, loading, closeModal, setDefaultPhoto, addPhotos, openDeletePhotoModal } = props

  const { Photos, ID } = object

  const accept = {
    'image/*': ['.png', '.jpeg', '.jpg']
  }

  const renderPhotos = () => {
    return Photos.map((photo, i) => (
      <div style={styles.photo} key={i}>
        <img style={styles.photo} src={photo.Url} />

        <div style={styles.deletePhoto} onClick={() => openDeletePhotoModal(photo.ID, ID)}>
          Eliminar foto
        </div>

        <div style={styles.setDefaultPhoto} onClick={() => setDefaultPhoto(photo.ID, ID)}>
          Marcar como principal
        </div>
      </div>
    ))
  }

  return (
    <Modal
      title="Agregar o eliminar fotos"
      visible={open}
      width={810}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Volver
        </Button>
      ]}>
      {renderPhotos()}

      <p style={styles.error}>{error}</p>

      {loading ? (
        <div style={styles.centerThings}>
          <Spin />
        </div>
      ) : (
        <div>
          <Dropzone accept={accept} style={styles.dropzone} onDrop={(files) => addPhotos(files, ID)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={styles.dropzone}>
                <input {...getInputProps()} />
                <div style={styles.dropzoneContainer}>
                  <p style={styles.dropzoneHeader}>Subida de imágenes</p>
                  <p style={styles.dropzoneText}>Arrastra las imágenes o haz click aquí</p>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}

      <DeleteConfirmationModal />
    </Modal>
  )
}

PhotosModal.propTypes = {
  open: bool,
  error: string,
  object: shape({}),
  loading: bool,
  closeModal: func.isRequired,
  setDefaultPhoto: func.isRequired,
  addPhotos: func.isRequired,
  openDeletePhotoModal: func.isRequired
}
export default PhotosModal
