import { errorMonitor } from 'events'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  ABMServiceTypes,
  CreateServiceRequest,
  CreateServiceSuccess,
  CreateServiceType,
  CreateServiceTypes,
  DeleteServiceRequest,
  DeleteServiceSuccess,
  DeleteServiceTypes,
  GetDepositTypes,
  GetDepositsByIdTypes,
  GetDepositsRequest,
  SELECT_SERVICE,
  SET_SELECTED_DEPOSIT,
  SelectedDeposit,
  SetCancel,
  SetDeleteModalVisibility,
  SetOpen,
  SetSelectedDeposit,
  UpdateServiceRequest,
  UpdateServiceSuccess,
  UpdateServiceTypes
} from '../types/serviceABM'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'

const LIMIT_DEPOSITS = 50

const ServiceModalActionsCreators = {
  setOpen: ({ service, open, isCreate }: { open: boolean; service?: ServiceSearch; isCreate: boolean }): SetOpen => ({
    type: ABMServiceTypes.SET_OPEN,
    payload: { service, open, isCreate }
  }),
  setCancel: (): SetCancel => ({
    type: ABMServiceTypes.CANCEL
  }),
  selectService: ({ id, multiplier, type, operationType, solution, deposits, solutionId }: ServiceSearch) => ({
    type: SELECT_SERVICE,
    payload: {
      id,
      multiplier,
      type,
      operationType,
      deposits,
      solution,
      solutionId
    }
  }),
  setMultiplier: (multiplier: number) => ({
    type: ABMServiceTypes.SET_MULTIPLIER,
    payload: { multiplier }
  }),
  setType: (type: string) => ({
    type: ABMServiceTypes.SET_TYPE,
    payload: { type }
  }),
  setId: (id: string) => ({
    type: ABMServiceTypes.SET_ID,
    payload: { id }
  }),
  updateService:
    ({
      id,
      multiplier,
      deposits,
      solutionId
    }: {
      id: string
      multiplier: number
      deposits: number[]
      solutionId: number
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateServiceRequest = {
        type: UpdateServiceTypes.UPDATE_SERVICE_REQUEST
      }

      const toastId = setToastLoading('Editando Tipo de Servicio')

      dispatch(request)

      try {
        await API.CategoryCreation.ServiceTypes.update({ id, multiplier, deposits, solutionId })

        const success: UpdateServiceSuccess = {
          type: UpdateServiceTypes.UPDATE_SERVICE_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Services.SERVICE_UPDATED)
        setToastSuccessUpdate(toastId, { render: 'El servicio se editó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(errorMonitor, UpdateServiceTypes.UPDATE_SERVICE_FAILURE, dispatch)
        })
      }
    },
  getDeposits: (): AppThunk => async (dispatch) => {
    const request: GetDepositsRequest = {
      type: GetDepositTypes.GET_DEPOSITS_REQUEST
    }

    dispatch(request)
    const params = {
      limit: LIMIT_DEPOSITS,
      offset: 0,
      allDeposits: true,
      onlySpaceGuru: true,
      deprecated: false
    }
    dispatch({ type: GetDepositTypes.GET_DEPOSITS_REQUEST })

    try {
      const { deposits } = await API.TransactionHandler.Deposits.Admin.list(params)
      dispatch({
        type: GetDepositTypes.GET_DEPOSITS_SUCCESS,
        payload: {
          deposits
        }
      })
    } catch (e: any) {
      handleErrorsWithAction(e, GetDepositTypes.GET_DEPOSITS_FAILURE, dispatch)
    }
  },
  getDepositsById:
    ({ deposits }: { deposits: number[] }): AppThunk =>
    async (dispatch) => {
      dispatch({ type: GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_REQUEST })
      try {
        if (!deposits) return
        const depositsResponse = await Promise.all(
          deposits.map(async (id) => {
            const { deposits } = await API.TransactionHandler.Deposits.getById({ depositId: id })
            return deposits
          })
        )

        const depositsOptions = depositsResponse.map((deposit) => ({
          label: `${deposit.id} - ${deposit.name} - ${'SpaceGuru'}`,
          value: deposit.id
        }))

        dispatch({
          type: GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_SUCCESS,
          payload: {
            selectedDeposits: depositsOptions
          }
        })
      } catch (e) {
        handleErrorsWithAction(e, GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_FAILURE, dispatch)
      }
    },
  setSelectedDeposit(selectedDeposit: SelectedDeposit[]): SetSelectedDeposit {
    return {
      type: SET_SELECTED_DEPOSIT,
      payload: { selectedDeposit }
    }
  },
  createServiceType:
    ({ displayOrder, deposits, selectedService }: CreateServiceType): AppThunk =>
    async (dispatch) => {
      const request: CreateServiceRequest = {
        type: CreateServiceTypes.CREATE_SERVICE_REQUEST
      }

      dispatch(request)
      const { id, type, multiplier, operationType } = selectedService

      const toast = setToastLoading('Creando Tipo de servicio')

      try {
        await API.CategoryCreation.ServiceTypes.create({
          id,
          type,
          multiplier,
          displayOrder,
          deposits,
          operationType,
          solutionId: 1
        })

        const success: CreateServiceSuccess = {
          type: CreateServiceTypes.CREATE_SERVICE_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Tipo de servicio creado exitosamente' })
        dispatch(success)
        Emitter.emit(Events.Services.SERVICE_UPDATED)
      } catch (error) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(error, CreateServiceTypes.CREATE_SERVICE_FAILURE, dispatch)
        })
      }
    },
  deleteService({ serviceId }: { serviceId: string }): AppThunk {
    return async (dispatch) => {
      const request: DeleteServiceRequest = {
        type: DeleteServiceTypes.DELETE_SERVICE_REQUEST
      }

      const toastId = setToastLoading('Eliminando servicio, por favor espere...')

      dispatch(request)
      try {
        await API.CategoryCreation.ServiceTypes.delete({ serviceId })

        const success: DeleteServiceSuccess = {
          type: DeleteServiceTypes.DELETE_SERVICE_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Services.SERVICE_UPDATED)
        setToastSuccessUpdate(toastId, { render: 'El servicio se eliminó correctamente.' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, DeleteServiceTypes.DELETE_SERVICE_FAILURE, dispatch) ??
            'Error al intentar eliminar el servicio.'
        })
      }
    }
  },
  setDeleteModalVisibility(deleteModalVisibility: boolean, service: ServiceSearch): SetDeleteModalVisibility {
    return {
      type: DeleteServiceTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisibility, service }
    }
  }
}

export default ServiceModalActionsCreators
