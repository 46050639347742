import { Tooltip } from 'antd'
import React, { useLayoutEffect, useRef } from 'react'

const HeightClipperTooltip: React.FC<{ maxHeight?: number; textAlign?: 'left' | 'center' | 'right' }> = ({
  children,
  textAlign = 'center',
  maxHeight = 42
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = React.useState(false)

  useLayoutEffect(() => {
    if (ref.current) {
      const isContentOverflow = ref.current?.scrollHeight > ref.current?.clientHeight
      setShowTooltip(isContentOverflow)
    }
  }, [children, maxHeight])

  return (
    <div
      ref={ref}
      style={{
        maxHeight,
        display: 'flex',
        alignItems: showTooltip ? 'baseline' : 'center',
        overflow: 'hidden',
        textAlign
      }}>
      {showTooltip ? <Tooltip title={children}>{children}</Tooltip> : children}
    </div>
  )
}

export default HeightClipperTooltip
