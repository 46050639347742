import React from 'react'
import styles from './DashboardHeaderMetrics.module.scss'
import clsx from 'clsx'
import Divisor from '@/components/Divisor'
import StatusCard, { StatusCardTypes, StatusCardUnits } from '@/components/StatusCard/StatusCard'
import CaretRightIcon from '@/icons/caretRight'
import { SCREEN_SIZE } from '@/common/constants'
import { DashboardMetrics } from '@/projectApi/TransactionHandler/Dashboard/metrics'
import { formatNumber } from '@/utils/formatNumber'
import { useLocalization } from '@/packages/localization'

type DashboardMetricProps = {
  fetchingMetrics: boolean
  metrics: DashboardMetrics
}

type DashboardMetricsWrapperProps = {
  children: React.ReactNode
  title?: string
  customClassname?: string
}

type MetricCard = {
  titleCard: string
  total: string | number
  typeCard: 'neutral' | 'success' | 'danger'
  description: string
  unit?: StatusCardUnits
  percentage?: number
}

type HeaderMetric = {
  title: string
  arrow: boolean
  metricsCards: MetricCard[]
}

export const DashboardHeaderMetrics = ({ metrics }: DashboardMetricProps) => {
  const { strings } = useLocalization()
  const headerMetrics: HeaderMetric[] = [
    {
      title: strings.dashboardViewScreen.header.metrics.m3Title,
      arrow: true,
      metricsCards: [
        {
          titleCard: strings.dashboardViewScreen.header.metrics.quotedM3Card,
          typeCard: 'neutral',
          total: formatNumber(metrics.quotedM3, 2),
          description: strings.dashboardViewScreen.header.metrics.quotedM3Description
        },
        {
          titleCard: strings.dashboardViewScreen.header.metrics.realM3Card,
          typeCard: 'neutral',
          total: formatNumber(metrics.realM3, 2),
          description: strings.dashboardViewScreen.header.metrics.realM3Description
        }
      ]
    },
    {
      title: strings.dashboardViewScreen.header.metrics.leadsTitle,
      arrow: false,
      metricsCards: [
        {
          titleCard: strings.dashboardViewScreen.header.metrics.leadsCard,
          total: metrics.quantityWonProspects,
          typeCard: 'success',
          description: strings.dashboardViewScreen.header.metrics.leadsDescription
        }
      ]
    },
    {
      title: strings.dashboardViewScreen.header.metrics.rpTitle,
      arrow: true,
      metricsCards: [
        {
          titleCard: strings.dashboardViewScreen.header.metrics.totalRPCard,
          total: metrics.totalRps,
          typeCard: 'neutral',
          description: strings.dashboardViewScreen.header.metrics.totalRPDescription,
          unit: 'm³',
          percentage: metrics.m3TotalRps
        },
        {
          titleCard: strings.dashboardViewScreen.header.metrics.winRPCard,
          total: metrics.quantityWonDepositOperations,
          typeCard: 'success',
          description: strings.dashboardViewScreen.header.metrics.winRPDescription,
          unit: 'm³',
          percentage: metrics.m3WonDepositOperations
        },
        {
          titleCard: strings.dashboardViewScreen.header.metrics.cancelledRPCard,
          total: metrics.quantityCancelledDepositOperations,
          typeCard: 'danger',
          description: strings.dashboardViewScreen.header.metrics.cancelledRPDescription,
          unit: 'm³',
          percentage: metrics.m3CancelledDepositOperations
        }
      ]
    },
    {
      title: strings.dashboardViewScreen.header.metrics.depositsTitle,
      arrow: true,
      metricsCards: [
        {
          titleCard: strings.dashboardViewScreen.header.metrics.totalDepositsCard,
          total: metrics.totalDepositOperations,
          typeCard: 'neutral',
          description: strings.dashboardViewScreen.header.metrics.totalDepositsDescription,
          unit: 'm³',
          percentage: metrics.m3TotalDepositOperations
        },
        {
          titleCard: strings.dashboardViewScreen.header.metrics.validatedDepositCard,
          total: metrics.quantityValidatedDepositOperations,
          typeCard: 'success',
          description: strings.dashboardViewScreen.header.metrics.validatedDepositDescription,
          unit: 'm³',
          percentage: metrics.m3ValidatedDepositOperations
        },
        {
          titleCard: strings.dashboardViewScreen.header.metrics.pendingDepositCard,
          total: metrics.quantityDepositedDepositOperations,
          typeCard: 'danger',
          description: strings.dashboardViewScreen.header.metrics.pendingDepositDescription,
          unit: 'm³',
          percentage: metrics.m3DepositedDepositOperations
        }
      ]
    }
  ]

  return (
    <article className={styles.metricsHeader}>
      <div className={styles.container}>
        {headerMetrics.map(({ title, arrow, metricsCards }, index) => (
          <React.Fragment key={title}>
            <DashboardMetricsWrapper title={title}>
              {metricsCards.map(({ titleCard, total, description, typeCard, percentage, unit }, index) => (
                <React.Fragment key={titleCard}>
                  <StatusCard
                    titleCard={titleCard || ''}
                    total={total}
                    type={typeCard as StatusCardTypes}
                    customClassname={styles.customCardGeneral}
                    totalStyles={styles.totalCard}
                    titleStyles={styles.titleCard}
                    description={description}
                    tooltipIconColor={typeCard === 'neutral' ? '#3f444d' : undefined}
                    unit={unit}
                    percentage={percentage ? formatNumber(percentage, 2) : ''}
                  />
                  {arrow && index === 0 && (
                    <CaretRightIcon
                      color="#3f444d"
                      style={{
                        flexShrink: 0
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </DashboardMetricsWrapper>
            {index < headerMetrics.length - 1 && (
              <div className={styles.divisorContainer}>
                <Divisor orientation="vertical" breakPoint={SCREEN_SIZE.MOBILE} margin="0 10px" color="#dddddd" />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </article>
  )
}

export const DashboardMetricsWrapper = ({ children, title = '', customClassname }: DashboardMetricsWrapperProps) => {
  return (
    <section className={clsx(styles.metricContainer, customClassname)}>
      <h3>{title}</h3>
      <article className={styles.metrics}>{children}</article>
    </section>
  )
}
