import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

type APIResponse = {
  m3_won_deposit_operations: number
  m3_removals: number
  quantity_clients_with_full_removals: number
  quantity_new_users_with_first_deposit_operation: number
  date: string
}[]

export type AnnualMetrics = CamelCasedPropertiesDeep<APIResponse>

export type AnnualMetricsParams = {
  year: number
  countryCode: string
}

export function annualMetrics({ countryCode, year }: AnnualMetricsParams): Promise<{ annualMetrics: AnnualMetrics }> {
  const formattedParams = snakecaseKeys({ countryCode, year })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/dashboard-metrics/annual`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Hubo un error al obtener las métricas anuales')
      const annualMetrics: AnnualMetrics = camelcaseKeys(response.data || [])
      return { annualMetrics }
    })
    .catch((error) => {
      throw error
    })
}
