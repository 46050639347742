import { Reducer } from 'redux'
import { CLEAN_STATES, GET_TRANSACTION_DATA_SUCCESS } from '../../types/EditDepositViewTypes/EditDepositView'
import {
  AddressesActionsTypes,
  AddressesState,
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  SAVE_ADDRESS_FAILURE,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_SUCCESS,
  SET_ADDRESS_ID
} from '../../types/EditDepositViewTypes/address'

const initialState: AddressesState = {
  addresses: [],
  addressID: 0,
  loadingAddresses: false,
  error: ''
}

export const ServiceTypeReduce: Reducer<AddressesState, AddressesActionsTypes> = (
  state = initialState,
  action
): AddressesState => {
  switch (action.type) {
    case CLEAN_STATES:
      return initialState
    case GET_TRANSACTION_DATA_SUCCESS: {
      return {
        ...state,
        addressID: action.payload.transaction.transport.address.addressId
      }
    }
    case SET_ADDRESS_ID: {
      return {
        ...state,
        addressID: action.payload.addressID
      }
    }
    case GET_ADDRESSES_REQUEST: {
      return { ...state, loadingAddresses: true }
    }
    case GET_ADDRESSES_SUCCESS: {
      return { ...state, loadingAddresses: false, addresses: action.payload.addresses }
    }
    case GET_ADDRESSES_FAILURE: {
      return { ...state, loadingAddresses: false, error: action.payload.error }
    }
    case SAVE_ADDRESS_REQUEST: {
      return { ...state, loadingAddresses: true }
    }
    case SAVE_ADDRESS_SUCCESS: {
      return {
        ...state,
        loadingAddresses: false,
        addresses: [...state.addresses, action.payload.address],
        addressID: action.payload.address.id
      }
    }
    case SAVE_ADDRESS_FAILURE: {
      return { ...state, loadingAddresses: false, error: action.payload.error }
    }
    default:
      return state
  }
}

export default ServiceTypeReduce
