import { Discount } from '../../../projectApi/TransactionHandler/Discounts/getByCode'
import { SetNewProposalFromScratch } from './proposals'
import { GetProspectRequest } from './prospect'

export enum CostTypes {
  SET_APPLY_DISCOUNT = 'NEW_PROPOSAL_V2/COST/SET_APPLY_DISCOUNT',
  SET_DISCOUNT_CODE = 'NEW_PROPOSAL_V2/COST/SET_DISCOUNT_CODE',
  FETCH_DISCOUNT_REQUEST = 'NEW_PROPOSAL_V2/COST/FETCH_DISCOUNT_REQUEST',
  FETCH_DISCOUNT_SUCCESS = 'NEW_PROPOSAL_V2/COST/FETCH_DISCOUNT_SUCCESS',
  FETCH_DISCOUNT_FAILURE = 'NEW_PROPOSAL_V2/COST/FETCH_DISCOUNT_FAILURE'
}

export interface SetApplyDiscount {
  type: CostTypes.SET_APPLY_DISCOUNT
  payload: { applyDiscount: boolean }
}

export interface SetDiscountCode {
  type: CostTypes.SET_DISCOUNT_CODE
  payload: { discountCode: string }
}

export interface FetchDiscountRequest {
  type: CostTypes.FETCH_DISCOUNT_REQUEST
}

export interface FetchDiscountSuccess {
  type: CostTypes.FETCH_DISCOUNT_SUCCESS
  payload: { discount: Discount }
}

export interface FetchDiscountFailure {
  type: CostTypes.FETCH_DISCOUNT_FAILURE
  payload: { error: string }
}

type FetchDiscountActions = FetchDiscountRequest | FetchDiscountSuccess | FetchDiscountFailure

export type CostActions =
  | SetApplyDiscount
  | SetDiscountCode
  | FetchDiscountActions
  | GetProspectRequest
  | SetNewProposalFromScratch
