import React, { useEffect, useState } from 'react'

type Item = {
  node: React.ReactNode
  id: number | string
}

type Props = {
  items: Item[]
  activeId: number | string
  delay?: number
}

const VisibilitySwitcher: React.FC<Props> = ({ items, activeId, delay = 200 }) => {
  const [selectedElementId, setSelectedElementId] = useState(activeId)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSelectedElementId(activeId)
    }, delay)

    return () => clearTimeout(timeout)
  }, [activeId])

  const foundItem = items.find((i) => i.id === selectedElementId)
  if (!foundItem) return null

  return (
    <span
      style={{
        opacity: selectedElementId === activeId ? 1 : 0,
        transition: `opacity ${delay - 50}ms ease-in-out 50ms`
      }}
      key={selectedElementId}>
      {foundItem.node}
    </span>
  )
}

export default VisibilitySwitcher
