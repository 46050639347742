import React from 'react'
import Component from '../components/checkoutModal'
import { bindActionCreators } from 'redux'
import actions from '../actions/checkout'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureManager } from '../../featureManager/utils'
import { FeatureType } from '../../featureManager/types'
import { PAYMENT_PROCESSORS } from '../components/constants'

const Container = () => {
  const dispatch = useDispatch()
  const checkoutActions = bindActionCreators(actions, dispatch)
  const checkoutReducer = useSelector((state) => state.Users.checkout)
  const spreedlyFeature = useFeatureManager(FeatureType.SPREEDLY_PAYMENT)
  const paymentProcessor = spreedlyFeature ? PAYMENT_PROCESSORS.SPREEDLY : PAYMENT_PROCESSORS.MERCADOPAGO

  const props = {
    checkoutActions,
    checkoutReducer,
    paymentProcessor
  }

  return <Component {...props} />
}

export default Container
