import * as React from 'react'

const FastTruck = () => (
  <svg width={18} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.375 10.675H18V10.3l-.038-2.925v-.263l-.224-.075-1.65-.675-1.05-3.487-.075-.263h-3.225V.025H1.463v.75h9.562v2.55H14.4l.9 2.925h-3.525V4.037h-.75v5.138h.75V7h3.938l1.537.6v2.325H.375v.75ZM6.6 8.462H2.213v.75H6.6v-.75Zm-2.212 2.925h-3.3v.75H2.55a1.84 1.84 0 0 0 1.838 1.838c.487 0 .937-.188 1.312-.525.337-.337.525-.825.525-1.313h6.6a1.84 1.84 0 0 0 1.838 1.838c.487 0 .937-.188 1.312-.525.338-.337.525-.825.525-1.313h1.462v-.75H15.75v.75c0 .3-.113.563-.338.788a1.157 1.157 0 0 1-1.575 0 1.115 1.115 0 0 1-.3-.75h1.125v-.75H5.476v.75c0 .3-.112.563-.337.787a1.157 1.157 0 0 1-1.575 0 1.115 1.115 0 0 1-.3-.75H4.35v-.825h.038Zm2.587-7.312H1.838v.75h5.137v-.75ZM5.888 2.612H0v.75h5.888v-.75Z"
      fill="#263238"
    />
  </svg>
)

export default FastTruck
