import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import FinishSummary from '../components/FinishSummary'
import actions from '../actions/common'

type FinishSummaryProps = {
  editMode?: boolean
  setStep: (step: number) => void
}

const Container = (ownProps: FinishSummaryProps) => {
  const cbuState = useSelector((state) => state.NewRemoval.paymentCBU)
  const commonState = useSelector((state) => state.NewRemoval.common)
  const objectsState = useSelector((state) => state.NewRemoval.objects)
  const calendarState = useSelector((state) => state.NewRemoval.calendar)
  const transportState = useSelector((state) => state.NewRemoval.transport)
  const userDepositState = useSelector((state) => state.NewRemoval.userSearch)
  const paymentMpState = useSelector((state) => state.NewRemoval.paymentMp)

  const dispatch = useDispatch()

  const commonActions = bindActionCreators(actions, dispatch)

  const props = {
    ...ownProps,
    cbuState,
    userDepositState,
    transportState,
    objectsState,
    calendarState,
    commonState,
    paymentMpState,
    commonActions
  }

  return <FinishSummary {...props} />
}

export default Container
