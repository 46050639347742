import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AttributeModalActionCreators from '../actions/attributeModal'
import AttributeModal from '../components/AttributeModal'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const attributesModalActions = bindActionCreators(AttributeModalActionCreators, dispatch)
  const attributeModalState = useSelector((state) => state.Products.attributeModal)
  const { productId } = attributeModalState
  const props = {
    attributesModalActions,
    attributeModalState
  }

  React.useEffect(() => {
    if (productId) {
      attributesModalActions.getAttributesBySearch('', { page: 1, pageSize: 10, total: 100 })
      attributesModalActions.getAttributes({ productId, pagination: { page: 1, pageSize: 100, total: 100 } })
    }
  }, [productId])

  return <AttributeModal {...props} />
}

export default Container
