import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { SnakeCasedProperties } from 'type-fest'

export type EditLocationParams = {
  code: string
  description: string
  locationId: number
  maxHeightInCm: number
  maxLengthInCm: number
  maxVolumeInCm: number
  maxWeightInKg: number
  maxWidthInCm: number
  type: string
}

export type EditLocationCamelCase = SnakeCasedProperties<
  Omit<EditLocationParams, 'maxWeightInKg'> & {
    maxWeightInGr: number
  }
>

export function edit(params: EditLocationParams): Promise<boolean> {
  const body: EditLocationCamelCase = snakecaseKeys(
    { ...params, max_weight_in_gr: params.maxWeightInKg * 1000 },
    { deep: true }
  )

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/locations/admin`, body)
    .then(() => true)
    .catch((error) => {
      throw error
    })
}
