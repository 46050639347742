import { CamelCasedPropertiesDeep } from 'type-fest'
import { OverrideProps } from '../../../../utils/utilityTypes'
import { Moment } from 'moment/moment'
import { convertToMoment } from '../../../utils'

export type APIAddress = {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: string | null
  Original: string
  Address: string
  Number: string
  Floor: string
  Apartment: string
  MainAddress: boolean
  Latitude: number
  Longitude: number
  PostalCode: {
    PostalCode: string
    Allowed: boolean
  }
  InDeliveryRange: boolean
  City: string
  Province: string
  Comment: string
  Origin: string
  Country: string
}

type DateProps = 'createdAt' | 'updatedAt' | 'deletedAt'

type AddressWithoutFormat = CamelCasedPropertiesDeep<APIAddress>
export type Address = OverrideProps<AddressWithoutFormat, DateProps, Moment | null>

export const formatAddress = (address: AddressWithoutFormat): Address => ({
  ...address,
  createdAt: convertToMoment(address.createdAt),
  updatedAt: convertToMoment(address.updatedAt),
  deletedAt: convertToMoment(address.deletedAt)
})
