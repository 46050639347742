import * as React from 'react'

const CubeCompass = () => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.35 16.8V9.225L.825 5.438v7.574l.637.375v-2.362h.826v2.85l.675.375v-2.475h.787V14.7l.525.3 3.075 1.8Zm4.725-13.388c-1.237.563-1.8 2.026-1.237 3.263.075.15.15.263.225.375l-.676.45c-1.012-1.5-.675-3.525.825-4.537a2.39 2.39 0 0 1 .526-.3l.337.75Zm1.425-1.05V5.85l2.213 1.688-.488.637-2.362-1.8-.15-.112v-3.9h.787Zm-.412-1.65C15.788.712 18 2.925 18 5.625 18 7.388 17.062 9 15.525 9.9v3.6l-.188.113-3.675 2.137-3.675 2.137L7.8 18l-.188-.113-3.675-2.137-3.674-2.137L0 13.5V4.537l.225-.112L3.9 2.288 7.575.15l.188-.113.187.113 2.4 1.387c.825-.524 1.8-.825 2.738-.825Zm1.65 9.563a4.919 4.919 0 0 1-5.513-1.613l-1.012.6V16.8l3.075-1.763 3.487-1.987v-2.775h-.037ZM8.774 7.95c-1.013-1.913-.675-4.275.862-5.775l.075-.075L7.8.975 4.313 3 1.237 4.763 7.8 8.55c-.037-.038.975-.6.975-.6Zm7.2-5.213c-1.612-1.612-4.2-1.612-5.775 0-1.575 1.613-1.612 4.2 0 5.775 1.613 1.575 4.2 1.613 5.775 0 .75-.75 1.2-1.8 1.2-2.887 0-1.05-.413-2.1-1.2-2.888Z"
      fill="#263238"
    />
  </svg>
)

export default CubeCompass
