import React from 'react'
import { Tooltip } from 'antd'
import { Country } from '../constants/constants'

type FlagCountryProps = {
  country: Country
}

const FlagCountry = (props: FlagCountryProps) => {
  const { country } = props

  return (
    <div>
      <Tooltip title={country.label}>
        <img src={country.flagUrl} alt={country.fullName} />
      </Tooltip>
    </div>
  )
}

export default FlagCountry
