import React from 'react'

function DotDone() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path fill="#5D58F7" fillRule="evenodd" d="M8.25 16a8 8 0 100-16 8 8 0 000 16z" clipRule="evenodd"></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.2 6.293a1 1 0 10-1.414-1.414l-4.95 4.95-2.121-2.122A1 1 0 003.3 9.121L6.13 11.95a1 1 0 001.414 0L13.2 6.293z"
        clipRule="evenodd"></path>
    </svg>
  )
}

export default DotDone
