import { Address } from '../../../projectApi/AccountManager/common'

export interface Params {
  limit: number
  offset: number
  sort: string
  order: string
}

export interface Pagination {
  current: number
  total: number
  pageSize: number
}

export interface Sorter {
  order: string
  field: string
  columnKey: string
}

export interface AddressUserState {
  addresses: Address[]
  loading: boolean
  params: Params
  pagination: Pagination
  searchFilters: [
    {
      key: string
      text: string
    }
  ]
  sorter: Sorter
  error: string
}

export interface AddressList {
  addresses: Address[]
  quantity: number
  params: Params
}

export const GET_ADDRESSES_DATA_REQUEST = 'USERS/ADDRESS/GET_ADDRESSES_DATA_REQUEST'
export const GET_ADDRESSES_DATA_SUCCESS = 'USERS/ADDRESS/GET_ADDRESSES_DATA_SUCCESS'
export const GET_ADDRESSES_DATA_FAILURE = 'USERS/ADDRESS/GET_ADDRESSES_DATA_FAILURE'

export interface GetAddressesDataRequest {
  type: typeof GET_ADDRESSES_DATA_REQUEST
}

export interface GetAddressesDataSuccess {
  type: typeof GET_ADDRESSES_DATA_SUCCESS
  payload: {
    addresses: Address[]
    params: Params
    quantity: number
  }
}

export interface GetAddressesDataFailure {
  type: typeof GET_ADDRESSES_DATA_FAILURE
  payload: {
    error: string
  }
}

export const SET_SORTER = 'USERS/ADDRESS/SET_SORTER'

export interface SetSorter {
  type: typeof SET_SORTER
  payload: {
    sorter: Sorter
  }
}

export const SET_PAGINATION = 'USERS/ADDRESS/SET_PAGINATION'

export interface SetPagination {
  type: typeof SET_PAGINATION
  payload: {
    pagination: Pagination
  }
}

export const CLEAN_FILTERS = 'USERS/ADDRESS/CLEAN_FILTERS'

export interface CleanFilters {
  type: typeof CLEAN_FILTERS
}

export type AdressActions =
  | GetAddressesDataRequest
  | GetAddressesDataSuccess
  | GetAddressesDataFailure
  | SetSorter
  | SetPagination
  | CleanFilters
