import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import {
  CANCEL_TRIP_FAILURE,
  CANCEL_TRIP_REQUEST,
  CANCEL_TRIP_SUCCESS,
  CLEAR_STATE,
  FINISH_TRIP_FAILURE,
  FINISH_TRIP_REQUEST,
  FINISH_TRIP_SUCCESS,
  GET_TRIPS_FAILURE,
  GET_TRIPS_REQUEST,
  GET_TRIPS_SUCCESS,
  SET_CANCELING_MODAL_ID,
  START_TRIP_FAILURE,
  START_TRIP_REQUEST,
  START_TRIP_SUCCESS,
  SelectedTripsTypes,
  TOGGLE_SELECTED_TRANSACTION,
  TOGGLE_TRIP_TRANSACTIONS,
  TripsTableAction,
  TripsTableDateRangeFilterKey,
  TripsTableState
} from '../types/tripsTable'
import {
  CommonDestination,
  GET_TRIP_TRANSACTIONS_FAILURE,
  GET_TRIP_TRANSACTIONS_REQUEST,
  GET_TRIP_TRANSACTIONS_SUCCESS
} from '../types/common'
import { ASSIGN_OPERATORS_SUCCESS } from '../../TransactionOperatorsAssignment/types'
import { SendTransactionsByTripsReportTypes, SendTripsReportTypes } from '../types/tripsReports'

const initialState: TripsTableState = {
  trips: [],
  loadingTrips: false,
  loadingTransactionsForTripIds: [],
  error: '',
  searchFilters: [{ key: 'id', text: '' }],
  categoryFilter: { tripStatus: [] },
  sort: {
    field: 'id',
    direction: SortDirection.DESC
  },
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  dateRangeFilters: [
    { key: TripsTableDateRangeFilterKey.START_DATE, startDate: null, endDate: null },
    { key: TripsTableDateRangeFilterKey.END_DATE, startDate: null, endDate: null },
    { key: TripsTableDateRangeFilterKey.SCHEDULED_START, startDate: null, endDate: null },
    { key: TripsTableDateRangeFilterKey.SCHEDULED_END, startDate: null, endDate: null },
    { key: TripsTableDateRangeFilterKey.CREATED_AT, startDate: null, endDate: null }
  ],
  loadingTripIds: [],
  startedTripIds: [],
  finishedTripIds: [],
  cancelingModalId: null,
  canceledTripIds: [],
  canceling: false,
  selectedTransactions: [],
  selectedTrips: [],
  sendingCSV: false,
  sendingTXByTripsReport: false
}

const TripsTableReducer: Reducer<TripsTableState, TripsTableAction> = (
  state = initialState,
  action
): TripsTableState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case GET_TRIPS_REQUEST:
      return {
        ...state,
        loadingTrips: !action.payload.silentLoading,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        sort: action.payload.sort || initialState.sort,
        selectedTransactions: initialState.selectedTransactions,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GET_TRIPS_SUCCESS: {
      /**
       * When trips are updated because of a change (e.g. new Driver), transactions have to be kept,
       * as they come from a different endpoint and are overwritten when such an update occurs
       */
      if (!action.payload.trips) {
        return {
          ...state,
          loadingTrips: false,
          pagination: {
            ...state.pagination,
            total: action.payload.total
          }
        }
      }

      const tripsWithKeptTransactions = action.payload.trips.map((payloadTrip) => {
        const tripAlreadyInState = state.trips.find((stateTrip) => stateTrip.id === payloadTrip.id)
        if (!tripAlreadyInState || tripAlreadyInState.transactions.length === 0) return payloadTrip

        return { ...payloadTrip, transactions: tripAlreadyInState.transactions }
      })

      return {
        ...state,
        loadingTrips: false,
        trips: tripsWithKeptTransactions,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    }
    case GET_TRIPS_FAILURE:
      return {
        ...state,
        loadingTrips: false,
        error: action.payload.error,
        trips: []
      }
    case GET_TRIP_TRANSACTIONS_REQUEST:
      if (action.payload.destination !== CommonDestination.TABLE) return state

      return {
        ...state,
        loadingTransactionsForTripIds: [...state.loadingTransactionsForTripIds, action.payload.tripId]
      }
    case GET_TRIP_TRANSACTIONS_SUCCESS: {
      if (action.payload.destination !== CommonDestination.TABLE) return state

      return {
        ...state,
        loadingTransactionsForTripIds: state.loadingTransactionsForTripIds.filter((id) => id !== action.payload.tripId),
        trips: state.trips.map((t) =>
          t.id === action.payload.tripId ? { ...t, transactions: action.payload.transactions } : t
        )
      }
    }
    case GET_TRIP_TRANSACTIONS_FAILURE:
      if (action.payload.destination !== CommonDestination.TABLE) return state

      return {
        ...state,
        loadingTransactionsForTripIds: state.loadingTransactionsForTripIds.filter((id) => id !== action.payload.tripId)
      }
    case START_TRIP_REQUEST:
      return { ...state, loadingTripIds: [...state.loadingTripIds, action.payload.tripId] }
    case START_TRIP_SUCCESS:
      return {
        ...state,
        loadingTripIds: state.loadingTripIds.filter((tripId) => tripId !== action.payload.tripId),
        startedTripIds: [...state.startedTripIds, action.payload.tripId]
      }
    case START_TRIP_FAILURE:
      return {
        ...state,
        loadingTripIds: state.loadingTripIds.filter((tripId) => tripId !== action.payload.tripId),
        error: action.payload.error
      }
    case SET_CANCELING_MODAL_ID:
      return { ...state, cancelingModalId: action.payload.id }
    case CANCEL_TRIP_REQUEST:
      return { ...state, canceling: true }
    case CANCEL_TRIP_SUCCESS:
      return {
        ...state,
        canceling: false,
        cancelingModalId: null,
        canceledTripIds: [...state.canceledTripIds, action.payload.tripId]
      }
    case CANCEL_TRIP_FAILURE:
      return {
        ...state,
        canceling: false,
        error: action.payload.error
      }
    case FINISH_TRIP_REQUEST:
      return { ...state, loadingTripIds: [...state.loadingTripIds, action.payload.tripId] }
    case FINISH_TRIP_SUCCESS:
      return {
        ...state,
        loadingTripIds: state.loadingTripIds.filter((tripId) => tripId !== action.payload.tripId),
        finishedTripIds: [...state.finishedTripIds, action.payload.tripId]
      }
    case FINISH_TRIP_FAILURE:
      return {
        ...state,
        loadingTripIds: state.loadingTripIds.filter((tripId) => tripId !== action.payload.tripId),
        error: action.payload.error
      }
    case TOGGLE_SELECTED_TRANSACTION: {
      const filtered = state.selectedTransactions.filter((tx) => tx.id !== action.payload.transaction.id)
      if (state.selectedTransactions.length === filtered.length) filtered.push(action.payload.transaction)
      return {
        ...state,
        selectedTransactions: filtered
      }
    }
    case TOGGLE_TRIP_TRANSACTIONS: {
      const { trip } = action.payload

      if (!trip.transactions) return state

      const filtered = state.selectedTransactions.filter((tx) => !trip.transactions.find((t) => t.id === tx.id))
      if (state.selectedTransactions.length === filtered.length) filtered.push(...trip.transactions)
      return {
        ...state,
        selectedTransactions: filtered
      }
    }
    case ASSIGN_OPERATORS_SUCCESS:
      return { ...state, selectedTransactions: initialState.selectedTransactions }
    case SendTripsReportTypes.SEND_CSV_REQUEST: {
      return {
        ...state,
        sendingCSV: true
      }
    }
    case SendTripsReportTypes.SEND_CSV_SUCCESS:
    case SendTripsReportTypes.SEND_CSV_FAILURE: {
      return {
        ...state,
        sendingCSV: false
      }
    }
    case SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_REQUEST: {
      return {
        ...state,
        sendingTXByTripsReport: true
      }
    }
    case SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_SUCCESS: {
      return {
        ...state,
        sendingTXByTripsReport: false,
        selectedTrips: initialState.selectedTrips
      }
    }
    case SendTransactionsByTripsReportTypes.SEND_TRANSACTIONS_BY_TRIPS_REPORT_FAILURE: {
      return {
        ...state,
        sendingTXByTripsReport: false
      }
    }
    case SelectedTripsTypes.TOGGLE_SELECTED_TRIP: {
      const filtered = state.selectedTrips.filter((tx) => tx.id !== action.payload.trip.id)
      if (state.selectedTrips.length === filtered.length) filtered.push(action.payload.trip)

      return {
        ...state,
        selectedTrips: filtered
      }
    }
    case SelectedTripsTypes.TOGGLE_ALL_TRIPS: {
      const allSelectableTripsAreSelectedOrDisabled = state.trips.every(
        (tx) => state.selectedTrips.find((stx) => stx.id === tx.id) || tx.transactions
      )

      return {
        ...state,
        selectedTrips: allSelectableTripsAreSelectedOrDisabled
          ? initialState.selectedTrips
          : state.trips.filter((tx) => !tx.transactions)
      }
    }
    case SelectedTripsTypes.CLEAR_SELECTED_TRIPS: {
      return {
        ...state,
        selectedTrips: initialState.selectedTrips
      }
    }
    default:
      return state
  }
}

export default TripsTableReducer
