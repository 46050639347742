import React from 'react'
import { Tooltip } from 'antd'
import { DynamicTable } from '../../../../components/DynamicTable'
import { Edit, List, QRIcon, Trash } from '../../../../icons'
import { formatNumber } from '../../../../utils/formatNumber'
import { usePermissions } from '../../../UserLogged/hooks/usePermissions'
import { Permissions } from '../../../UserLogged/permissions'
import { Location } from '../../../../projectApi/TransactionHandler/Locations/Corporative/list'
import { Column, Pagination } from '../../../../components/DynamicTable/types/types'
import { LocationListStateType } from '../types/locationsTable'
import { getSearchProps } from '../../../../components/DynamicTable/utils/utils'
import { useHistory } from 'react-router'
import { TableActionBar } from '../../../../components/TableActionBar'
import LocationsMetricsComponent from './locationsMetrics'
import styles from './locationsMetrics.module.scss'

type LocationsTableProps = {
  locationsTable: LocationListStateType
  handleSearch: (key: string, newValue: string) => void
  pagination: Pagination
  QRModalActions: any // TODO move QRModalActions to typescript
  handleCleanFilters: () => void
  openModalCreateLocation: () => void
  handleOpenModalDelete: (selectedLocation: Location) => void
  handleOpenModalEdit: (selectedLocation: Location) => void
}

const LocationsTable = (props: LocationsTableProps) => {
  const {
    locationsTable,
    handleSearch,
    QRModalActions,
    pagination,
    handleCleanFilters,
    openModalCreateLocation,
    handleOpenModalDelete,
    handleOpenModalEdit
  } = props

  const { locations, locationsMetrics, fetchingLocationsMetrics, fetchingLocations, searchFilters } = locationsTable
  const { openQRModal } = QRModalActions

  const history = useHistory()

  const showObjectsByLocation = (locationCode: string) => {
    history.push(`/corporative/locations/${locationCode}`)
  }
  const permissions = usePermissions()

  const columns: Column<Location>[] = [
    {
      label: 'ID',
      key: 'id',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      label: 'Código de ubicación',
      key: 'code'
    },
    {
      label: 'ID Depósito',
      key: 'depositId',
      search: getSearchProps('deposit_id', 'ID depósito', handleSearch, searchFilters)
    },
    { label: 'Descripción', key: 'description' },
    { label: 'Activo', key: 'active', renderDataCell: ({ active }) => (active ? 'Sí' : 'No') },
    { label: 'Objetos', key: 'quantityObjects' },
    {
      label: 'Peso / Peso Máx. [Kg]',
      key: 'weightInGr',
      renderDataCell: ({ weightInGr, maxWeightInGr }) => {
        const weight = formatNumber(weightInGr / 1000, 2)
        const maxWeight = formatNumber(maxWeightInGr / 1000, 2)
        return (
          <span>
            {weight} / {maxWeight}
          </span>
        )
      }
    },
    {
      label: 'Volumen / Volumen Máx. [m³]',
      key: 'volumeInCm',
      renderDataCell: ({ volumeInCm, maxVolumeInCm }) => {
        const volume = formatNumber(volumeInCm / 1000000, 2)
        const maxVolume = formatNumber(maxVolumeInCm / 1000000, 2)
        return (
          <span>
            {volume} / {maxVolume}
          </span>
        )
      }
    },
    {
      label: 'Ocupación %',
      key: 'occupancyRate',
      renderDataCell: ({ occupancyRate }) => formatNumber(occupancyRate, 2)
    },
    {
      label: 'Carga %',
      key: 'loadRate',
      renderDataCell: ({ loadRate }) => formatNumber(loadRate, 2)
    },
    {
      label: 'Código QR',
      key: 'openQRModal',
      renderDataCell: ({ code }) => {
        return (
          (
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => openQRModal(code)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
          ) || '-'
        )
      }
    }
  ]

  const actions = [
    {
      label: 'Ver Objetos',
      icon: <List width="22px" height="22px" />,
      onClick: (record: Location) => showObjectsByLocation(record.code)
    },
    {
      label: 'Editar Ubicación',
      icon: <Edit width="22px" height="22px" />,
      onClick: (record: Location) => handleOpenModalEdit(record),
      disabled: !permissions.includes(Permissions.EditarUbicacionCorporativo)
    },
    {
      label: 'Eliminar Ubicación',
      icon: <Trash width="22px" height="22px" />,
      onClick: (record: Location) => handleOpenModalDelete(record),
      disabled: !permissions.includes(Permissions.EditarUbicacionCorporativo)
    }
  ]

  return (
    <div className="animation fadeIn">
      <div className={styles.headerContainer}>
        <LocationsMetricsComponent
          locationsMetrics={locationsMetrics}
          fetchingLocationsMetrics={fetchingLocationsMetrics}
        />
      </div>
      <TableActionBar>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />

        {permissions.includes(Permissions.CrearUbicacionCorporativo) && (
          <TableActionBar.ButtonAction titleButton="Crear ubicación" onClickButton={openModalCreateLocation} />
        )}
      </TableActionBar>

      <DynamicTable
        columns={columns}
        keyExtractor={(location) => location.id}
        rows={locations}
        loading={fetchingLocations}
        pagination={pagination}
        actions={actions}
      />
    </div>
  )
}

export default LocationsTable
