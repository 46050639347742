import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import countryActionCreators from '../../../components/CountrySelector/actions/CountrySelector'
import createPriceBandActionsCreators from '../actionCreators/create'
import CreatePriceBand from '../components/create'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'

const CreatePriceBandContainer = (ownProps: any) => {
  const dispatch = useDispatch()
  const countryActions = bindActionCreators(countryActionCreators, dispatch)
  const countryState = useSelector((state) => state.CountrySelector)
  const createPriceBandActions = bindActionCreators(createPriceBandActionsCreators, dispatch)
  const createPriceBandState = useSelector((state) => state.PriceBands.create)
  const permissions = usePermissions()

  const props = {
    ...ownProps,
    countryActions,
    countryState,
    createPriceBandActions,
    createPriceBandState,
    permissions
  }

  return <CreatePriceBand {...props} />
}

export default CreatePriceBandContainer
