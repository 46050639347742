import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { eqProps, equals, forEachObjIndexed, isEmpty } from 'ramda'
import actions from '../actions/locationsTable'
import Component from '../components/locationsTable'
import QRActions from '../../../components/QRModal/QRModalActions'
import { PaginationConfig, SorterResult } from 'antd/es/table'
import { Location, LocationFilter, Order, Params } from '../types/locationsTable'
import AddEditLocationModal from './AddEditLocationModal'
import AddEditLocationModalActionsCreator from '../actions/AddEditLocationModal'
import LocationDeleteModal from '../containers/LocationDeleteModal'
import locationDeleteActionsCreator from '../actions/LocationDelete'
import { Location as LocationDelete } from '../types/LocationDelete'
import { NumberParam, useQueryParams } from 'use-query-params'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { removeNullishValues } from '../../../components/DynamicTable/queryParamsUtils'

const Container = () => {
  const dispatch = useDispatch()
  const locationsActions = bindActionCreators(actions, dispatch)
  const QRModalActions = bindActionCreators(QRActions, dispatch)
  const addEditLocationModalActions = bindActionCreators(AddEditLocationModalActionsCreator, dispatch)
  const locationDeleteModalActions = bindActionCreators(locationDeleteActionsCreator, dispatch)

  const locationsTable = useSelector((state) => state.Locations.locationsTable)

  const [query, setQuery] = useQueryParams({
    depositId: NumberParam
  })

  useEvents(
    [Events.Location.LOCATION_CREATED, Events.Location.LOCATION_UPDATED, Events.Location.LOCATION_DELETED],
    () => handleEvent()
  )

  const handleCleanFilters = () => {
    locationsActions.cleanFilters()
    locationsActions.getLocationsFromStore()
    setQuery(removeNullishValues({}), 'push')
  }

  const {
    params,
    searchFilters: currentSearchFilters,
    filter: currentFilter,
    sorter: currentSorter,
    pagination: currentPagination
  } = locationsTable

  useEffect(() => {
    handleEvent()
  }, [])

  const handleEvent = () => {
    const newParams = {
      ...params,
      ...(query.depositId ? { deposit_id: query.depositId } : {})
    }

    locationsActions.getLocations(newParams)
    locationsActions.getLocationInfo(newParams)
  }

  const handleOpenModalDelete = (location: Location) => {
    const selectedLocation: LocationDelete = {
      active: location.active,
      code: location.code,
      depositId: location.depositId,
      description: location.description,
      id: location.id,
      loadRate: location.loadRate,
      maxVolumeInCm: location.maxVolumeInCm,
      maxHeightInCm: location.maxHeightInCm,
      maxLengthInCm: location.maxLengthInCm,
      maxWeightInGr: location.maxWeightInGr,
      maxWidthInCm: location.maxWidthInCm,
      occupancyRate: location.occupancyRate,
      quantityObjects: location.quantityObjects,
      weightInGr: location.weightInGr,
      volumeInCm: location.volumeInCm,
      type: location.type
    }
    locationDeleteModalActions.setModal({ isModalOpen: true, location: selectedLocation })
  }

  const getOrder = (order: string): Order => (order === 'descend' ? Order.DESC : Order.ASC)

  const getKeyFilter = (key: string): string => {
    switch (key) {
      case 'id':
        return 'id'
      case 'deposit_id':
        return 'deposit_id'
      case 'type':
        return 'type'
      case 'active':
        return 'show'
      default:
        return key
    }
  }

  const getColumn = (column: string): string => {
    switch (column) {
      case 'ID':
        return 'id'
      case 'depositId':
        return 'deposit_id'
      case 'weightInGr':
        return 'weight_in_gr'
      case 'createdAt':
        return 'created_at'
      case 'updatedAt':
        return 'updated_at'
      case 'volumeInCm':
        return 'volume_in_cm'
      case 'occupancyRate':
        return 'occupancy_rate'
      case 'loadRate':
        return 'load_rate'
      case 'quantityObjects':
        return 'quantity_objects'
      default:
        return column
    }
  }

  const handleSearch = (dataIndex: string, text: string, confirm: () => void) => {
    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )
    const Offset = 0

    const newParams = {
      ...params,
      Offset,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    locationsActions.setSearchFilters(searchFilters)
    locationsActions.setPagination({ ...currentPagination, current: 1 })
    locationsActions.getLocations(newParams)
    locationsActions.getLocationInfo(newParams)

    confirm()
  }

  const getNewFilterParams = (params: Params, filters: LocationFilter): Params => {
    let formatFilters = {}
    const Offset = 0
    forEachObjIndexed((value, key) => {
      formatFilters = { ...formatFilters, [getKeyFilter(key)]: value?.[0] }
    }, filters)
    return { ...params, ...formatFilters, Offset }
  }

  const handleChange = (pagination: PaginationConfig, filters: LocationFilter, sorter: SorterResult<unknown>) => {
    if (!equals(filters, currentFilter)) {
      const newParams = { ...getNewFilterParams(params, filters) }
      locationsActions.setFilter(filters)
      locationsActions.setPagination({ ...currentPagination, current: 1 })
      locationsActions.getLocations(newParams)
      locationsActions.getLocationInfo(newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order: orderSort, field } = sorter
      const order = getOrder(orderSort)
      const column = getColumn(field)
      const Offset = 0

      locationsActions.setSorter(sorter)
      locationsActions.setPagination({ ...currentPagination, current: 1 })
      locationsActions.getLocations({ ...params, Offset, column, order })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current = 1, pageSize = 10 } = pagination
      const Offset = (current - 1) * pageSize
      const newParams = { ...params, Offset }

      locationsActions.setPagination(pagination)
      locationsActions.getLocations(newParams)
    }
  }

  const openModalCreateLocation = () => {
    addEditLocationModalActions.openModal()
  }

  const handleOpenModalEdit = (location: Location) => {
    const selectedLocation = {
      active: location.active,
      code: location.code,
      depositID: location.depositId,
      description: location.description,
      id: location.id,
      loadRate: location.loadRate,
      maxVolumeInCm: location.maxVolumeInCm,
      maxHeightInCm: location.maxHeightInCm,
      maxLengthInCm: location.maxLengthInCm,
      maxWeightInGr: location.maxWeightInGr,
      maxWidthInCm: location.maxWidthInCm,
      occupancyRate: location.occupancyRate,
      quantityObjects: location.quantityObjects,
      weightInGr: location.weightInGr,
      volumeInCm: location.volumeInCm,
      type: location.type
    }
    addEditLocationModalActions.openModal(selectedLocation)
  }

  const props = {
    params,
    locationsTable,
    locationsActions,
    QRModalActions,
    handleSearch,
    handleChange,
    openModalCreateLocation,
    handleOpenModalDelete,
    handleOpenModalEdit,
    handleCleanFilters,
    dateRangeFilters: locationsTable.dateRangeFilters
  }

  return (
    <>
      <Component {...props} />
      <AddEditLocationModal />
      <LocationDeleteModal />
    </>
  )
}

export default Container
