import { Reducer } from 'redux'
import {
  ASSIGN_OPERATORS_FAILURE,
  ASSIGN_OPERATORS_REQUEST,
  ASSIGN_OPERATORS_SUCCESS,
  GET_OPERATORS_FAILURE,
  GET_OPERATORS_REQUEST,
  GET_OPERATORS_SUCCESS,
  SET_ASSIGNMENT_MODAL_OPEN,
  SET_SELECTED_OPERATORS,
  TransactionOperatorsAssignmentAction,
  TransactionOperatorsAssignmentState
} from './types'

const initialState: TransactionOperatorsAssignmentState = {
  open: false,
  loadingOperators: false,
  editMode: false,
  allOperators: [],
  selectedOperators: [],
  transactions: [],
  error: '',
  loadingAssignment: false
}

const TripModalReducer: Reducer<TransactionOperatorsAssignmentState, TransactionOperatorsAssignmentAction> = (
  state = initialState,
  action
): TransactionOperatorsAssignmentState => {
  switch (action.type) {
    case SET_ASSIGNMENT_MODAL_OPEN: {
      const { open, transactions, operators } = action.payload
      if (!open) return initialState

      return {
        ...state,
        open: open,
        transactions: transactions || initialState.transactions,
        selectedOperators: operators || initialState.selectedOperators
      }
    }
    case SET_SELECTED_OPERATORS:
      return { ...state, selectedOperators: action.payload.operators }
    case GET_OPERATORS_REQUEST:
      return { ...state, loadingOperators: true }
    case GET_OPERATORS_SUCCESS:
      return { ...state, loadingOperators: false, allOperators: action.payload.operators }
    case GET_OPERATORS_FAILURE:
      return { ...state, loadingOperators: false, error: action.payload.error }
    case ASSIGN_OPERATORS_REQUEST:
      return { ...state, loadingAssignment: true }
    case ASSIGN_OPERATORS_SUCCESS:
      return initialState
    case ASSIGN_OPERATORS_FAILURE:
      return { ...state, loadingAssignment: false, error: action.payload.error }
    default:
      return state
  }
}

export default TripModalReducer
