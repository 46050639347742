import {
  CANCEL_FAILURE,
  CANCEL_REQUEST,
  CANCEL_SUCCESS,
  CLOSE_MODAL,
  GET_REASONS_FAILURE,
  GET_REASONS_REQUEST,
  GET_REASONS_SUCCESS,
  OPEN_MODAL,
  Reason,
  ReasonOption,
  SET_REASON,
  cancelActions
} from '../types/cancel'

const initialState = {
  operationId: 0,
  reasonOption: null,
  guid: '',
  loading: false,
  error: '',
  open: false,
  reasons: null
}

export type CancelState = {
  operationId: number
  reasonOption: ReasonOption | null
  guid: string
  loading: boolean
  error: string
  open: boolean
  reasons: Reason[] | null
}

const CancelReducer = (state: CancelState = initialState, action: cancelActions): CancelState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
        operationId: action.payload.operationId,
        guid: action.payload.guid
      }
    case CLOSE_MODAL:
      return {
        ...initialState,
        reasons: state.reasons
      }
    case CANCEL_REQUEST:
      return { ...state, loading: true, error: '' }
    case CANCEL_SUCCESS:
      return initialState
    case CANCEL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_REASON:
      return { ...state, reasonOption: action.payload.reason }
    case GET_REASONS_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_REASONS_SUCCESS:
      return { ...state, loading: false, reasons: action.payload.reasons }
    case GET_REASONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default CancelReducer
