import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  CategoryOption,
  GENERAL_DATA_CLEAN_STATE,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_PRODUCT_BY_ID_FAILURE,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_SOLUTIONS_FAILURE,
  GET_SOLUTIONS_REQUEST,
  GET_SOLUTIONS_SUCCESS,
  GeneralDataCleanState,
  GetCategoriesRequest,
  GetCategoriesSuccess,
  GetProductByIdRequest,
  GetProductByIdSuccess,
  GetSolutionsRequest,
  GetSolutionsSuccess,
  SET_CATEGORY_SELECTED,
  SET_DIMENSIONS_HEIGHT,
  SET_DIMENSIONS_LENGTH,
  SET_DIMENSIONS_WEIGHT,
  SET_DIMENSIONS_WIDTH,
  SET_DISASSEMBLE_TIME,
  SET_IS_PRODUCT_SEARCHABLE,
  SET_MAX_DEVIATION,
  SET_MIN_DEVIATION,
  SET_MIN_PHOTOS,
  SET_MULTIPLIER,
  SET_PACKAGING_TIME,
  SET_SOLUTIONS_SELECTED,
  SolutionOption,
  SetCategorySelected,
  SetDimensionsHeight,
  SetDimensionsLength,
  SetDimensionsWeight,
  SetDimensionsWidth,
  SetDisassembleTime,
  SetIsProductSearchable,
  SetMaxDeviation,
  SetMinDeviation,
  SetMinPhotos,
  SetMultiplier,
  SetPackagingTime,
  SetSolutionSelected,
  GetProductSuggestionsRequest,
  GetProductSuggestionsSuccess,
  SET_PRODUCT_SUGGESTED,
  SetProductSeggested,
  GetCatalogByProductRequest,
  GetCatalogByProductSuccess,
  SET_OPEN_DETAIL_MODAL,
  GetProductByDescriptionRequest,
  GetProductByDescriptionSuccess,
  GetProductSuggestionEnum,
  GetCatalogByProductEnum,
  GetProductByDescriptionEnum
} from '../types/GeneralData'
import { SET_EDIT_MODE, SetEditMode } from '../types/common'
import { getLeafCategories } from '../../../projectApi/CategoryCreation/getCategories'

export const GeneralDataActionCreator = {
  getCategories: (): AppThunk => async (dispatch) => {
    const request: GetCategoriesRequest = {
      type: GET_CATEGORIES_REQUEST
    }

    dispatch(request)

    try {
      const { categories } = await API.CategoryCreation.getCategories()

      const leafCategories = getLeafCategories(categories)

      const success: GetCategoriesSuccess = {
        type: GET_CATEGORIES_SUCCESS,
        payload: { categories: leafCategories }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GET_CATEGORIES_FAILURE, dispatch)
    }
  },
  getSolutions: (): AppThunk => async (dispatch) => {
    const request: GetSolutionsRequest = {
      type: GET_SOLUTIONS_REQUEST
    }

    dispatch(request)

    try {
      const { solutions } = await API.CategoryCreation.Solution.list()

      const success: GetSolutionsSuccess = {
        type: GET_SOLUTIONS_SUCCESS,
        payload: { solutions }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GET_SOLUTIONS_FAILURE, dispatch)
    }
  },
  setMultiplier: (multiplier: string): SetMultiplier => ({
    type: SET_MULTIPLIER,
    payload: { multiplier }
  }),
  setDisassembleTime: (disassembleTime: string): SetDisassembleTime => ({
    type: SET_DISASSEMBLE_TIME,
    payload: { disassembleTime }
  }),
  setPackagingTime: (packagingTime: string): SetPackagingTime => ({
    type: SET_PACKAGING_TIME,
    payload: { packagingTime }
  }),
  setDimensionsWeight: (dimensionsWeight: string): SetDimensionsWeight => ({
    type: SET_DIMENSIONS_WEIGHT,
    payload: { dimensionsWeight }
  }),
  setDimensionsLength: (dimensionsLength: string): SetDimensionsLength => ({
    type: SET_DIMENSIONS_LENGTH,
    payload: { dimensionsLength }
  }),
  setDimensionsWidth: (dimensionsWidth: string): SetDimensionsWidth => ({
    type: SET_DIMENSIONS_WIDTH,
    payload: { dimensionsWidth }
  }),
  setDimensionsHeight: (dimensionsHeight: string): SetDimensionsHeight => ({
    type: SET_DIMENSIONS_HEIGHT,
    payload: { dimensionsHeight }
  }),
  setMinDeviation: (minDeviation: string): SetMinDeviation => ({
    type: SET_MIN_DEVIATION,
    payload: { minDeviation }
  }),
  setMaxDeviation: (maxDeviation: string): SetMaxDeviation => ({
    type: SET_MAX_DEVIATION,
    payload: { maxDeviation }
  }),
  setMinPhotos: (minPhotos: string): SetMinPhotos => ({
    type: SET_MIN_PHOTOS,
    payload: { minPhotos }
  }),
  setIsProductSearchable: (isProductSearchable: boolean): SetIsProductSearchable => ({
    type: SET_IS_PRODUCT_SEARCHABLE,
    payload: { isProductSearchable }
  }),
  setSolutionsSelected: (solutionsSelected: SolutionOption[]): SetSolutionSelected => ({
    type: SET_SOLUTIONS_SELECTED,
    payload: { solutionsSelected }
  }),
  setCategorySelected: (categorySelected: CategoryOption | null): SetCategorySelected => ({
    type: SET_CATEGORY_SELECTED,
    payload: { categorySelected }
  }),
  generalDataCleanState: (): GeneralDataCleanState => ({
    type: GENERAL_DATA_CLEAN_STATE
  }),
  setEditMode: (isEditMode: boolean): SetEditMode => ({
    type: SET_EDIT_MODE,
    payload: { isEditMode }
  }),
  getProductById:
    (id: number): AppThunk =>
    async (dispatch) => {
      const request: GetProductByIdRequest = {
        type: GET_PRODUCT_BY_ID_REQUEST
      }

      dispatch(request)

      try {
        const data = await API.CategoryCreation.Product.getById({ id })

        const success: GetProductByIdSuccess = {
          type: GET_PRODUCT_BY_ID_SUCCESS,
          payload: { product: data }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_PRODUCT_BY_ID_FAILURE, dispatch)
      }
    },
  getSuggestions:
    (keyword: string): AppThunk =>
    async (dispatch) => {
      const request: GetProductSuggestionsRequest = {
        type: GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_REQUEST
      }
      dispatch(request)

      try {
        const { suggestions } = await API.CategoryCreation.ProductSuggestions.getSuggestions({ keyword })
        const success: GetProductSuggestionsSuccess = {
          type: GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_SUCCESS,
          payload: { productSuggestions: suggestions }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_FAILURE, dispatch)
      }
    },
  setProductSuggested:
    (selectedProduct: string): AppThunk =>
    (dispatch) => {
      const setProductSuggested: SetProductSeggested = {
        type: SET_PRODUCT_SUGGESTED,
        payload: { selectedProduct }
      }
      dispatch(setProductSuggested)
    },
  getCatalogByProduct:
    ({ suggestion }: { suggestion: string }): AppThunk =>
    async (dispatch) => {
      const request: GetCatalogByProductRequest = {
        type: GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_REQUEST
      }
      dispatch(request)

      try {
        const { products, total } = await API.CategoryCreation.Catalog.searchAll({ description: suggestion })
        const success: GetCatalogByProductSuccess = {
          type: GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_SUCCESS,
          payload: { products, total }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_FAILURE, dispatch)
      }
    },
  setOpenDetailModal:
    (isOpen: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: SET_OPEN_DETAIL_MODAL,
        payload: { openDetailModal: isOpen }
      })
    },
  getProductByDescription:
    ({ description }: { description: string }): AppThunk =>
    async (dispatch) => {
      const request: GetProductByDescriptionRequest = {
        type: GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_REQUEST
      }
      dispatch(request)

      try {
        const { product } = await API.CategoryCreation.Catalog.search({ description })
        const success: GetProductByDescriptionSuccess = {
          type: GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_SUCCESS,
          payload: { product: product[0] }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_FAILURE, dispatch)
      }
    }
}
