import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/discount'
import delDiscount from '../actions/DeleteDiscount'
import actionsEdit from '../actions/newEditDiscount'
import Component from '../components/discount'
import { Edit } from '../../../icons'
import { isEmpty } from 'ramda'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const discountActions = bindActionCreators(actions, dispatch)
  const deleteDiscountActions = bindActionCreators(delDiscount, dispatch)
  const openModalAddEdit = bindActionCreators(actionsEdit.openModal, dispatch)

  const { discount } = useSelector((state) => state.Discount)

  const {
    discounts,
    error,
    params,
    loading,
    setSearchFilters,
    setPagination,
    searchFilters: searchFilterProp,
    pagination,
    sorter: sorterProp
  } = discount

  useEffect(() => {
    discountActions.getDiscounts(params)
  }, [])

  const getColumn = (column) => {
    switch (column) {
      case 'id':
        return 'id'
      default:
        return column
    }
  }

  const handlePagination = (newPage) => {
    const { params, pagination } = props

    const currentPagination = pagination
    const offset = (newPage - 1) * params.limit
    const newParams = { ...params, offset }

    discountActions.setPagination({ ...currentPagination, current: newPage })
    discountActions.getDiscounts(newParams)
  }

  const handleSearch = (dataIndex, text) => {
    const { getDiscounts, params, setPagination } = props
    const currentPagination = props.pagination
    const currentSearchFilters = searchFilterProp

    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    discountActions.setSearchFilters(searchFilters)
    discountActions.setPagination({ ...currentPagination, current: 1 })
    discountActions.getDiscounts(newParams)
  }

  const handleSort = (newSorter) => {
    const { setSorter, getDiscounts, setPagination } = discountActions
    const currentPagination = props.pagination
    setSorter(newSorter)
    setPagination({ ...currentPagination, current: 1 })
    getDiscounts({
      ...params,
      ...(newSorter.order ? { order: newSorter.order } : {}),
      Offset: 0,
      Column: getColumn(newSorter.field)
    })
  }

  const paginationObject = {
    pageSize: params.limit,
    total: pagination.total,
    current: pagination.current,
    onPageChange: handlePagination
  }

  const menuActions = [{ label: 'Editar', icon: <Edit />, onClick: ({ id }) => openModalAddEdit(id) }]

  const props = {
    ...ownProps,
    ...discountActions,
    discounts,
    openModalAddEdit,
    error,
    loading,
    searchFilters: searchFilterProp,
    params,
    setSearchFilters,
    setPagination,
    pagination,
    handleSearch,
    deleteDiscountActions,
    handleSort,
    sorter: sorterProp,
    getDiscounts: discountActions.getDiscounts,
    actions: menuActions
  }

  return <Component {...props} pagination={paginationObject} />
}

export default Container
