import React from 'react'
import { Button, Modal } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'

const StyledContainCodes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aling-items: center;
  text-align: center;
`

const StyledTextCode = styled.span`
  font-size: 20px;
  font-weight: bold;
  padding-top: 24px;
`

const StyledModal = styled(Modal)`
  max-width: 400;
  display: flex;
  justify-content: center;
`

const QRModal = (props) => {
  const { code, closeQRModal, open } = props

  return (
    <StyledModal
      title="Código QR"
      visible={open}
      onCancel={closeQRModal}
      footer={[
        <Button key="back" onClick={closeQRModal}>
          Cerrar
        </Button>
      ]}>
      <StyledContainCodes>
        <QRCodeSVG bgColor={'#FFFFFF'} fgColor={'#000000'} value={code} level={'L'} includeMargin={false} size={300} />
        <StyledTextCode>{code}</StyledTextCode>
      </StyledContainCodes>
    </StyledModal>
  )
}

export default QRModal
