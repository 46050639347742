import React, { useMemo } from 'react'
import styles from './AnnualMetrics.module.scss'
import { useSelector } from 'react-redux'
import LineChartComponent from '@/components/LineChart/components/LineChart.component'
import { formatAnnualChartData } from '@/components/LineChart/utils/formatAnnualData'
import { COLORS } from '@/common/constants'
import { AnnualMetricTypesKeys } from '../../types/Dashboard.types'
import { formatNumber } from '@/utils/formatNumber'
import { useLocalization } from '@/packages/localization'

type AnnualMetricsProps = {
  isFetching: boolean
}

const AnnualMetricsComponent = ({ isFetching }: AnnualMetricsProps) => {
  const { annualMetrics, dateRangeFilter } = useSelector((state) => state.Dashboard)
  const { strings } = useLocalization()

  const chartDataSchema = useMemo(
    () => [
      {
        title: strings.dashboardViewScreen.annual.m3,
        info: [
          {
            label: strings.dashboardViewScreen.annual.m3Win,
            dataKey: AnnualMetricTypesKeys.m3WonDepositOperations,
            color: COLORS.SUCCESS,
            formatNumber
          },
          {
            label: strings.dashboardViewScreen.annual.m3Removals,
            dataKey: AnnualMetricTypesKeys.m3Removals,
            color: COLORS.DANGER,
            formatNumber
          }
        ]
      },
      {
        title: strings.dashboardViewScreen.annual.clients,
        info: [
          {
            label: strings.dashboardViewScreen.annual.newUsers,
            dataKey: AnnualMetricTypesKeys.quantityNewUsersWithFirstDepositOperation,
            color: COLORS.SUCCESS
          },
          {
            label: strings.dashboardViewScreen.annual.clientsRemovals,
            dataKey: AnnualMetricTypesKeys.quantityClientsWithFullRemovals,
            color: COLORS.DANGER
          }
        ]
      }
    ],
    [strings]
  )

  const chartDataFormatted = useMemo(
    () => formatAnnualChartData(annualMetrics, chartDataSchema),
    [annualMetrics, chartDataSchema]
  )

  const selectedYear = useMemo(() => {
    return dateRangeFilter[0].startDate.format('YYYY')
  }, [dateRangeFilter])

  const chartKeys = useMemo(() => chartDataFormatted.map((chart) => JSON.stringify(chart)), [chartDataFormatted])

  return (
    <section className={styles.content}>
      <h3>
        {strings.dashboardViewScreen.annual.title} {selectedYear}
      </h3>
      <article className={styles.lineChartWrapper}>
        {chartDataFormatted.map((annualChart, index) => (
          <LineChartComponent key={chartKeys[index]} annualChart={annualChart} isFetching={isFetching} />
        ))}
      </article>
    </section>
  )
}

export default AnnualMetricsComponent
