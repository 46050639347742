export type Location = {
  id: number
  description: string
  depositId: number
  active: boolean
  code: string
  type: string
  quantityObjects: number
  maxLengthInCm: number
  maxWidthInCm: number
  maxHeightInCm: number
  volumeInCm: number
  weightInGr: number
  maxVolumeInCm: number
  maxWeightInGr: number
  occupancyRate: number
  loadRate: number
}

export type LocationDeleteStateType = {
  location: Location | null
  loading: boolean
  isModalOpen: boolean
  error: string
}

export enum DeleteLocationTypes {
  DELETE_LOCATION_REQUEST = 'DEPOSIT/LOCATIONS/DELETE_LOCATION_REQUEST',
  DELETE_LOCATION_SUCCESS = 'DEPOSIT/LOCATIONS/DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_FAILURE = 'DEPOSIT/LOCATIONS/DELETE_LOCATION_FAILURE'
}

export interface DeleteLocationsRequest {
  type: DeleteLocationTypes.DELETE_LOCATION_REQUEST
}

export interface DeleteLocationsSuccess {
  type: DeleteLocationTypes.DELETE_LOCATION_SUCCESS
}

export interface DeleteLocationsFailure {
  type: DeleteLocationTypes.DELETE_LOCATION_FAILURE
  payload: {
    error: string
  }
}

export type DeleteLocationActions = DeleteLocationsRequest | DeleteLocationsSuccess | DeleteLocationsFailure

export enum SetModalLocationTypes {
  SET_MODAL = 'DEPOSIT/LOCATIONS/SET_MODAL'
}

export interface SetModal {
  type: SetModalLocationTypes.SET_MODAL
  payload: {
    isModalOpen: boolean
    location: Location | null
  }
}

export type DeleteModalLocationActions = SetModal

export type LocationDeleteActions = DeleteLocationActions | DeleteModalLocationActions
