import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableActionBar } from '../../../components/TableActionBar'
import { bindActionCreators } from 'redux'
import { OperationDatesTable } from '../components/operationDatesTable'
import OperationDatesActionCreator from '../actions/operationDatesAction'
import CreateDateModalContainer from './createDateModalContainer'
import DeleteDateModalContainer from './deleteDateModalContainer'
import { Events } from '../../../utils/eventEmitter/events'
import { useEvents } from '../../../utils/eventEmitter'
import { useHistory } from 'react-router'

const OperationDaysContainer = () => {
  const dispatch = useDispatch()
  const { datesData, fetchingDates } = useSelector((state) => state.OperationCalendar.Dates)
  const router = useHistory()

  const { getDates, openCreateDateModal, openDeleteDateModal, setSelectedDeleteDate } = bindActionCreators(
    OperationDatesActionCreator,
    dispatch
  )

  useEffect(() => {
    getDates()
  }, [])

  useEvents([Events.OperationCalendar.OPERATION_DATE_CREATED, Events.OperationCalendar.OPERATION_DATE_DELETED], () => {
    getDates()
  })

  const handleChangeRoute = () => {
    return router.push('/operation_days')
  }

  const props = {
    datesData,
    openDeleteDateModal,
    setSelectedDeleteDate,
    fetchingDates
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction
          iconPosition="left"
          iconType="arrow-left"
          onClickButton={handleChangeRoute}
          titleButton="Volver"
        />
        <TableActionBar.ButtonAction
          iconType="minus"
          onClickButton={() => openCreateDateModal(true)}
          titleButton="Deshabilitar operación"
        />
      </TableActionBar>
      <OperationDatesTable {...props} />
      <CreateDateModalContainer />
      <DeleteDateModalContainer />
    </>
  )
}

export default OperationDaysContainer
