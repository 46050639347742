import React, { useEffect } from 'react'
import actions from '../actions/newEditDiscount'
import { useDispatch, useSelector } from 'react-redux'
import NewEditDscount from '../components/newEditDiscount'
import { bindActionCreators } from 'redux'

const NewEditDiscountContainer = (ownProps) => {
  const dispatch = useDispatch()
  const actionsNewEdit = bindActionCreators(actions, dispatch)

  const newEdit = useSelector((state) => state.Discount.newEdit)
  const { id, editMode } = newEdit

  useEffect(() => {
    if (id) {
      actionsNewEdit.getDiscount(id)
    }
  }, [id, editMode])

  const props = {
    ...ownProps,
    ...actionsNewEdit,
    ...newEdit
  }

  return <NewEditDscount {...props} />
}

export default NewEditDiscountContainer
