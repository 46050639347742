import types from '../types/sendByEmail'

const initialRoot = {
  open: false,
  proposalId: '',
  email: '',
  isLoading: false,
  error: ''
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.OPEN_SEND_BY_EMAIL_MODAL:
      return {
        ...state,
        open: true,
        proposalId: action.payload.proposalId,
        email: ''
      }
    case types.SET_EMAIL:
      return {
        ...state,
        email: action.payload.email,
        error: ''
      }
    case types.CANCEL_SEND_BY_EMAIL_MODAL:
      return initialRoot
    case types.CLOSE_SEND_BY_EMAIL_MODAL:
      return initialRoot
    case types.SEND_BY_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case types.SEND_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        open: false
      }
    case types.SEND_BY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case types.SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default root
