import { BaseOption } from '../../../components/Select/Select'
import { Account } from '../../../projectApi/AccountManager/Satellite/list'

export enum LoginTypes {
  SET_USERNAME = 'LOGIN/SET_USERNAME',
  SET_PASSWORD = 'LOGIN/SET_PASSWORD',
  SET_ACCOUNT = 'LOGIN/SET_ACCOUNT',
  LOGIN_REQUEST = 'LOGIN/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN/LOGIN_FAILURE',
  GET_PARENT_ACCOUNTS_REQUEST = 'LOGIN/GET_PARENT_ACCOUNTS_REQUEST',
  GET_PARENT_ACCOUNTS_SUCCESS = 'LOGIN/GET_PARENT_ACCOUNTS_SUCCESS',
  GET_PARENT_ACCOUNTS_FAILURE = 'LOGIN/GET_PARENT_ACCOUNTS_FAILURE'
}

export type AccountOption = BaseOption<number>

export type SetUsername = {
  type: LoginTypes.SET_USERNAME
  payload: { username: string }
}

export type SetPassword = {
  type: LoginTypes.SET_PASSWORD
  payload: { password: string }
}

export type SetAccount = {
  type: LoginTypes.SET_ACCOUNT
  payload: { account: AccountOption }
}

export type LoginRequest = {
  type: LoginTypes.LOGIN_REQUEST
}

export type LoginSuccess = {
  type: LoginTypes.LOGIN_SUCCESS
}

export type LoginFailure = {
  type: LoginTypes.LOGIN_FAILURE
  payload: { error: string }
}

export type GetParentAccountsRequest = {
  type: LoginTypes.GET_PARENT_ACCOUNTS_REQUEST
}

export type GetParentAccountsSuccess = {
  type: LoginTypes.GET_PARENT_ACCOUNTS_SUCCESS
  payload: { accounts: Account[] }
}

export type GetParentAccountsFailure = {
  type: LoginTypes.GET_PARENT_ACCOUNTS_FAILURE
  payload: { error: string }
}

export type LoginActions =
  | SetUsername
  | SetPassword
  | SetAccount
  | LoginRequest
  | LoginSuccess
  | LoginFailure
  | GetParentAccountsRequest
  | GetParentAccountsSuccess
  | GetParentAccountsFailure
