import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../../api'
import { URL_BASE_CATEGORIES } from '../../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

export interface ProductsByCountryAPI {
  country_id: string
  description: string
  photo_url: string
  photo_key: string
}

export interface ProductAPI {
  weight_in_gr: number
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  category_id: number
  min_volume_deviation: number
  max_volume_deviation: number
  description: string
  photo_min: number
  products_by_country: ProductsByCountryAPI[]
}

type UpdateProductParams = { productId: number } & CamelCasedPropertiesDeep<ProductAPI>

export const update = ({ productId, ...params }: UpdateProductParams): Promise<void> => {
  const formattedParams: ProductAPI = snakecaseKeys(params, { deep: true })

  return apiPrivate.put(`${URL_BASE_CATEGORIES}/product/corporate/${productId}`, formattedParams)
}
