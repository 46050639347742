import types from '../types/delete'
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import leadsTableActions from './table'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  deleteLead(lead) {
    return (dispatch, getState) => {
      dispatch({ type: types.DELETE_LEAD })

      const formData = new FormData()

      const { id } = lead

      return apiPrivate
        .delete(URL_BASE_MERCURIO + '/bo/prospects/v1/id/' + id, formData)
        .then((response) => {
          dispatch({ type: types.DELETE_LEAD_SUCCESS })
          const { params } = getState().Leads.table
          dispatch(leadsTableActions.getProspects(params))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.DELETE_LEAD_FAIL, dispatch)
        })
    }
  },
  setDeleteModalOpen(open, prospect) {
    return { type: types.SET_DELETE_MODAL_OPEN, payload: { open, prospect } }
  }
}

export default actions
