import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import nextStatusActionCreators from '../actions/nextStatus'
import { NextStatusState } from '../reducers/nextStatus'
import { formatMoneyWithoutDecimals } from '../../../utils/formatNumber'

const InformPaymentLabel = styled.div`
  text-align: center;
  font-size: 16px;
`

const InformPaymentAmount = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`

type DebtStatusProps = {
  nextStatusActions: typeof nextStatusActionCreators
  nextStatusState: NextStatusState
}

const DebtStatusModal = (props: DebtStatusProps) => {
  const { nextStatusActions, nextStatusState } = props
  const { openInformPaymentModal, loading, debtAmount, removalId } = nextStatusState

  const onClose = () => nextStatusActions.toggleInformPaymentModal(false)
  const onOk = () => nextStatusActions.informPayment(removalId)

  return (
    <Modal
      title="Informar pago"
      visible={openInformPaymentModal}
      closable={!loading}
      maskClosable={!loading}
      onCancel={onClose}
      onOk={onOk}
      centered
      okText="Continuar"
      cancelText="Cancelar"
      cancelButtonProps={{
        disabled: loading
      }}
      style={{ maxWidth: '500px' }}
      okButtonProps={{
        loading
      }}>
      <InformPaymentLabel>¿Confirma el pago de la deuda?</InformPaymentLabel>
      <InformPaymentAmount>Total a pagar: {formatMoneyWithoutDecimals(debtAmount)}</InformPaymentAmount>
    </Modal>
  )
}

export default DebtStatusModal
