import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/depositList'
import {
  DepositsSearchFilter,
  GetDepositListRequest,
  GetDepositListSuccess,
  GetDepositListTypes,
  RESET_FILTERS
} from '../types/depositList'

const DepositListActionsCreator = {
  getDeposits({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: DepositsSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetDepositListRequest = {
        type: GetDepositListTypes.GET_DEPOSIT_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { deposits, total } = await API.TransactionHandler.Deposits.Admin.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          allDeposits: true,
          deprecated: false
        })

        const success: GetDepositListSuccess = {
          type: GetDepositListTypes.GET_DEPOSIT_SUCCESS,
          payload: {
            deposits,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetDepositListTypes.GET_DEPOSIT_FAILURE, dispatch)
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.StorageDeposits.CLEAN_FILTERS), 50)
    }
  }
}

export default DepositListActionsCreator
