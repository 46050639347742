import React from 'react'
import Component, { DeleteProductModalProps } from '../components/deleteModal'
import { bindActionCreators } from 'redux'
import actions from '../actions/deleteModal'
import { useDispatch, useSelector } from 'react-redux'

const DeleteProductContainer = () => {
  const dispatch = useDispatch()
  const deleteProductState = useSelector((state) => state.Products.deleteModal)
  const deleteProductActions = bindActionCreators(actions, dispatch)

  const props: DeleteProductModalProps = {
    deleteProductState,
    deleteProductActions
  }

  return <Component {...props} />
}

export default DeleteProductContainer
