import { Reducer } from 'redux'
import {
  ABMProvidersActions,
  ABMProvidersModalState,
  CreateProviderTypes,
  DeleteProviderTypes,
  EditProviderTypes,
  SettersProvidersModalTypes
} from '../types/ABMProviders'

const initialState: ABMProvidersModalState = {
  editMode: false,
  modalVisible: false,
  deleteModalVisible: false,
  loadingProvider: false,
  providerId: 0,
  providerName: '',
  selectedProvider: undefined,
  error: ''
}

export const ABMProviderModalReducer: Reducer<ABMProvidersModalState, ABMProvidersActions> = (
  state: ABMProvidersModalState = initialState,
  action
): ABMProvidersModalState => {
  switch (action.type) {
    case SettersProvidersModalTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible, editMode } = action.payload

      return modalVisible
        ? {
            ...state,
            editMode: editMode ?? false,
            modalVisible
          }
        : initialState
    }
    case SettersProvidersModalTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisibility } = action.payload
      return deleteModalVisibility
        ? {
            ...state,
            deleteModalVisible: deleteModalVisibility
          }
        : initialState
    }
    case SettersProvidersModalTypes.SET_PROVIDER_NAME:
      return {
        ...state,
        providerName: action.payload.providerName
      }
    case SettersProvidersModalTypes.SET_SELECTED_PROVIDER: {
      const selectedProvider = action.payload.selectedProvider
      return {
        ...state,
        selectedProvider,
        providerId: selectedProvider.id,
        providerName: selectedProvider.name
      }
    }
    case CreateProviderTypes.CREATE_PROVIDER_REQUEST:
      return {
        ...state,
        loadingProvider: true
      }
    case CreateProviderTypes.CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        loadingProvider: false
      }
    case CreateProviderTypes.CREATE_PROVIDER_FAILURE:
      return {
        ...state,
        loadingProvider: false,
        error: action.payload.error
      }
    case EditProviderTypes.EDIT_PROVIDER_REQUEST:
      return {
        ...state,
        loadingProvider: true
      }
    case EditProviderTypes.EDIT_PROVIDER_SUCCESS:
      return {
        ...state,
        loadingProvider: false
      }
    case EditProviderTypes.EDIT_PROVIDER_FAILURE:
      return {
        ...state,
        loadingProvider: false,
        error: action.payload.error
      }
    case DeleteProviderTypes.DELETE_PROVIDER_REQUEST:
      return {
        ...state,
        loadingProvider: true
      }
    case DeleteProviderTypes.DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        loadingProvider: false
      }
    case DeleteProviderTypes.DELETE_PROVIDER_FAILURE:
      return {
        ...state,
        loadingProvider: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default ABMProviderModalReducer
