import { Reducer } from 'redux'
import {
  CloseComplaintTypes,
  ResolveComplaintAction,
  ResolveComplaintState,
  SettersResolveComplaintsTypes
} from '../types/resolveModal'
import { DELETE_EVIDENCE, GetComplaintsReasonsTypes } from '../types/common'

const initialResolveComplaintState: ResolveComplaintState = {
  loading: true,
  open: false,
  photos: [],
  reasonId: 0,
  isClosing: false,
  confirmationOpen: false,
  reasons: []
}

const ResolveComplaintReducer: Reducer<ResolveComplaintState, ResolveComplaintAction> = (
  state = initialResolveComplaintState,
  action
): ResolveComplaintState => {
  switch (action.type) {
    case CloseComplaintTypes.CLOSE_COMPLAINT_REQUEST:
      return {
        ...state,
        isClosing: true
      }

    case CloseComplaintTypes.CLOSE_COMPLAINT_SUCCESS:
      return {
        ...state,
        isClosing: false
      }

    case CloseComplaintTypes.CLOSE_COMPLAINT_FAILURE:
      return {
        ...state,
        isClosing: false
      }

    case SettersResolveComplaintsTypes.SET_OPEN: {
      return {
        ...state,
        open: action.payload.open
      }
    }
    case SettersResolveComplaintsTypes.SET_CONFIRMATION_OPEN: {
      return {
        ...state,
        confirmationOpen: action.payload.confirmationOpen
      }
    }

    case SettersResolveComplaintsTypes.SET_REASON_ID: {
      return {
        ...state,
        reasonId: action.payload.reasonId
      }
    }

    case SettersResolveComplaintsTypes.SET_EVIDENCE:
      return {
        ...state,
        photos: action.payload.photo
      }

    case DELETE_EVIDENCE:
      return {
        ...state,
        photos: state.photos.filter((_, index) => index !== action.payload.index)
      }

    case GetComplaintsReasonsTypes.GET_REASONS_REQUEST:
    case GetComplaintsReasonsTypes.GET_REASONS_FAILURE:
      return {
        ...state
      }
    case GetComplaintsReasonsTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload.reasons
      }

    default:
      return state
  }
}

export default ResolveComplaintReducer
