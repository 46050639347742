import { Action, applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import loggerMiddleware from './middleware/logger'

import Deposits from './sections/Deposits/Deposits.reducer'
import Removals from './sections/Removals/reducers'
import RemovalsValidation from './sections/RemovalsValidation/reducers/index'
import Users from './sections/Users/Users.reducer'
import Payments from './sections/Payments/Payments.reducer'
import Products from './sections/ProductsV2/reducers'
import CorporativeProducts from './sections/Corporative/Products/reducers'
import Activity from './sections/Activity/Activity.reducer'
import NewRemoval from './sections/NewRemoval/reducers'
import PhotographObjects from './sections/PhotographObjectsV2/reducer'
import Objects from './sections/Objects/reducers'
import ObjectsV2 from './sections/ObjectsV2/reducer'
import CorporativeObjects from './sections/Corporative/Objects/reducer'
import Services from './sections/ServicesV2/reducer'
import Login from './sections/Login/reducers'
import Leads from './sections/Leads/reducer'
import Proposals from './sections/ProposalsV2/reducer'
import Proposal from './sections/Proposals/reducers'
import NewProposal from './sections/NewProposal/reducers'
import Billing from './sections/Billing/reducers'
import BillingV2 from './sections/BillingV2/reducers'
import Locations from './sections/Locations/reducers'
import UserAddresses from './sections/Address/reducers'
import Components from './components/reducer'
import CountrySelector from './components/CountrySelector/reducer/CountrySelector'
import Discount from './sections/Discount/reducers'
import UserLogged from './sections/UserLogged/reducers'
import Reports from './sections/Reports/reducer'
import Cbus from './sections/CBUs/reducers'
import Cards from './sections/Cards/reducers'
import CarouselModal from './components/CarouselModal/reducers/CarouselModal'
import RemittanceModal from './sections/RemittanceModal/reducer'
import Movements from './sections/MovementsV2/reducer/MovementsTable'
import SingleDeposit from './sections/SingleDepositView/reducer'
import SingleRemoval from './sections/SingleRemovalView/reducer'
import DepositsValidation from './sections/DepositsValidation/reducer'
import RegisterObjects from './sections/RegisterObjects/reducer'
import Flags from './sections/Flags/reducers'
import Trips from './sections/Trips/reducer'
import FeatureManager from './sections/featureManager/reducer'
import DevelopmentReducer from './sections/development/reducers'
import MassiveObjectsEdit from './sections/MassiveObjectsEdit/reducer'
import Agents from './sections/Agents/reducer'
import TransactionOperatorsAssignment from './sections/TransactionOperatorsAssignment/reducer'
import Pickings from './sections/Pickings/reducer'
import Transactions from './sections/Transactions/reducers'
import SalesRequest from './sections/SalesRequest/reducers'
import Requests from './sections/Requests/reducers'
import Origins from './sections/Origins/reducers'
import NewProduct from './sections/NewProduct/reducer'
import PriceBands from './sections/PriceBands/reducer'
import EditDateServiceOperation from './common/operations/editDateService/reducers/editDateService'
import BillingPayment from './sections/BillingPayment/reducers'
import Attributes from './sections/Attributes/reducers'
import CorporativeUsersTableReducer from './sections/Corporative/users/reducers/usersTable'
import CorporateUsersABM from './sections/Corporative/users/reducers/userABM'
import CorporateLocationListReducer from './sections/Corporative/Locations/reducers'
import ABMStorageDepositModalReducer from './sections/StorageDeposits/reducers/ABMDeposits'
import ABMCorporativeDepositModalReducer from './sections/Corporative/Deposits/reducers/ABMDeposits'
import CorporativeDepositListReducer from './sections/Corporative/Deposits/reducers/depositsTable'
import StorageDepositListReducer from './sections/StorageDeposits/reducers/depositList'
import ProviderListReducer from './sections/Providers/reducers/ProviderList'
import ABMProviderModalReducer from './sections/Providers/reducers/ABMProviders'
import ShippersCombinerReducer from './sections/Shippers/reducers'
import OperationCalendar from './sections/OperationCalendar/reducers/reducer'
import VehiclesReducer from './sections/Vehicles/reducers'
import Dashboard from './sections/Dashboard/reducers/Dashboard'
import DonationsReducer from './sections/Donations/reducers'
import Complaints from './sections/Complaints/reducers'
import UploadPhotos from './components/UploadPhotos/reducer'

const middleware = [thunkMiddleware]
if (process.env.NODE_ENV === 'development') {
  middleware.push(loggerMiddleware)
}
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  StorageDepositListReducer,
  ProviderListReducer,
  ABMStorageDepositModalReducer,
  CorporativeDepositListReducer,
  CorporateLocations: CorporateLocationListReducer,
  ABMCorporativeDepositModalReducer,
  CorporateUsers: CorporativeUsersTableReducer,
  CorporateUsersABM,
  Transactions,
  Deposits,
  Removals,
  RemovalsValidation,
  Users,
  Payments,
  Products,
  CorporativeProducts,
  Activity,
  NewRemoval,
  // ObjectsToRecord,
  Objects,
  ObjectsV2,
  CorporativeObjects,
  Services,
  Login,
  PhotographObjects,
  Leads,
  Proposals,
  NewProposal,
  Components,
  Proposal,
  Billing,
  BillingV2,
  Discount,
  Reports,
  UserAddresses,
  UserLogged,
  Cbus,
  Cards,
  Locations,
  CarouselModal,
  CountrySelector,
  Movements,
  SingleDeposit,
  SingleRemoval,
  DepositsValidation,
  RegisterObjects,
  Flags,
  Trips,
  FeatureManager,
  MassiveObjectsEdit,
  Agents,
  RemittanceModal,
  TransactionOperatorsAssignment,
  Pickings,
  SalesRequest,
  Requests,
  Origins,
  NewProduct,
  PriceBands,
  EditDateServiceOperation,
  BillingPayment,
  Attributes,
  OperationCalendar,
  ShippersCombinerReducer,
  ABMProviderModalReducer,
  VehiclesReducer,
  DonationsReducer,
  DevelopmentReducer,
  Dashboard,
  Complaints,
  UploadPhotos
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Reports', 'CountrySelector', 'FeatureManager']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)))

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export type AppDispatch = typeof store.dispatch

export default store
