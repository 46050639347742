export default {
  CREATE_DISCOUNT_REQUEST: 'DISCOUNT/CREATE_DISCOUNT_REQUEST',
  CREATE_DISCOUNT_SUCCESS: 'DISCOUNT/CREATE_DISCOUNT_SUCCESS',
  CREATE_DISCOUNT_FAILURE: 'DISCOUNT/CREATE_DISCOUNT_FAILURE',
  GET_DISCOUNT_REQUEST: 'DISCOUNT/GET_DISCOUNT_REQUEST',
  GET_DISCOUNT_SUCCESS: 'DISCOUNT/GET_DISCOUNT_SUCCESS',
  GET_DISCOUNT_FAILURE: 'DISCOUNT/GET_DISCOUNT_FAILURE',
  UPDATE_DISCOUNT_REQUEST: 'DISCOUNT/UPDATE_DISCOUNT_REQUEST',
  UPDATE_DISCOUNT_SUCCESS: 'DISCOUNT/UPDATE_DISCOUNT_SUCCESS',
  UPDATE_DISCOUNT_FAILURE: 'DISCOUNT/UPDATE_DISCOUNT_FAILURE',
  SET_ID: 'DISCOUNT/SET_ID',
  SET_CODE: 'DISCOUNT/SET_CODE',
  SET_DESCRIPTION: 'DISCOUNT/SET_DESCRIPTION',
  SET_PUBLIC_DESCRIPTION: 'DISCOUNT/SET_PUBLIC_DESCRIPTION',
  SET_TRANSPORT_DISCOUNT_PERCENT: 'DISCOUNT/SET_TRANSPORT_DISCOUNT_PERCENT',
  SET_DEPOSIT_DISCOUNT_PERCENT: 'DISCOUNT/SET_DEPOSIT_DISCOUNT_PERCENT',
  SET_DEPOSIT_DISCOUNT_MONTHS: 'DISCOUNT/SET_DEPOSIT_DISCOUNT_MONTHS',
  SET_START_DATE: 'DISCOUNT/SET_START_DATE',
  SET_END_DATE: 'DISCOUNT/SET_END_DATE',
  SET_OPEN_MODAL: 'DISCOUNT/SET_OPEN_MODAL',
  SET_CLOSE_MODAL: 'DISCOUNT/SET_CLOSE_MODAL'
}
