import React from 'react'
import { Tooltip } from 'antd'
import styles from './validationView.module.scss'

export type ImageTooltipProps = {
  src: string
  alt: string
  maxWidth: string
  maxHeight: string
  imgWidth?: string
  imgHeight?: string
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined
  onClick?: () => void
  placeholder?: string
}

const ImageTooltip: React.FC<ImageTooltipProps> = ({
  src,
  alt,
  maxWidth,
  maxHeight,
  onClick,
  imgWidth = '40px',
  imgHeight = '42px',
  placement = 'rightBottom',
  placeholder = ''
}) => {
  const tooltipRef = React.useRef(null)
  return (
    <Tooltip
      ref={tooltipRef}
      overlayStyle={{ width: 'min-content' }}
      autoAdjustOverflow
      placement={placement}
      className={styles.productPhotoTooltip}
      overlayClassName={styles.overlay}
      title={
        <img
          style={{ maxWidth, maxHeight }}
          src={src}
          alt={alt}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.style.display = 'none'

            const parent = currentTarget.parentElement
            if (parent) {
              parent.innerHTML = `Hubo un error al cargar:<br />${src}`
            }
          }}
        />
      }>
      <img
        onClick={onClick}
        className={styles.productPhoto}
        style={{ width: imgWidth, height: imgHeight }}
        src={src}
        alt={alt}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = placeholder
        }}
      />
    </Tooltip>
  )
}

export default ImageTooltip
