import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

export type PaymentTypeRemoval = 'free' | 'offline' | 'spreedly'

export type RemovalItemBody = {
  objectId?: number
  floorByStairsCost: number
  packagingCost: number
  assemblyCost: number
  deleted?: boolean
}

export type UpdateRemovalBody = {
  serviceDate?: string
  type?: string
  address?: number
  transportCost?: number
  tollCostInCents?: number
  removalObjects?: RemovalItemBody[]
  modifyReason?: number
  timeSlotId?: number
  timeInterval?: string
  paymentType?: PaymentTypeRemoval
}

type UpdateParams = {
  operationId: number
  body: UpdateRemovalBody
}

export function update({ operationId, body }: UpdateParams): Promise<void> {
  const parsedBody = snakecaseKeys(body, { deep: true })

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/operations/removal/modify/${operationId}`, parsedBody)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
