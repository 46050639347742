import React from 'react'

const TruckIconPR = () => (
  <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" stroke="#5D58F7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M16.5 3h-15v13h15V3ZM16.5 8h4l3 3v5h-7V8ZM6 21a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM19 21a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default TruckIconPR
