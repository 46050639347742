import types from '../types/BillingTable'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  getBillings(params) {
    return (dispatch) => {
      dispatch({ type: types.FETCH_BILLINGS_REQUEST })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + '/balances/search', { params })
        .then((response) => {
          const billings = keysToCamel(response.data.results)
          const quantity = response.data.paging.total
          dispatch({
            type: types.FETCH_BILLINGS_SUCCESS,
            payload: { billings, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_BILLINGS_FAILURE, dispatch)
        })
    }
  },

  getBillingsFromStore() {
    return (dispatch, getState) => {
      const params = getState().Billing.billingTable.params
      dispatch(actions.getBillings(params))
    }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },
  setSorter: (sorter) => {
    return { type: types.SET_SORTER, payload: { sorter } }
  },
  setFilter: (filter) => {
    return { type: types.SET_FILTER, payload: { filter } }
  },
  setSearchFilters: (searchFilters) => {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  },
  cleanFilters: () => {
    return { type: types.CLEAN_FILTERS }
  }
}

export default actions
