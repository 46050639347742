import { Reducer } from 'redux'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import {
  FETCH_SINGLE_DEPOSIT_DATA_FAILURE,
  FETCH_SINGLE_DEPOSIT_DATA_REQUEST,
  FETCH_SINGLE_DEPOSIT_DATA_SUCCESS,
  SingleDepositAction,
  SingleDepositState
} from '../types/singleDeposit'

const initialState: SingleDepositState = {
  operationCountryCode: CountryIdCode.ARGENTINA,
  items: [],
  objects: [],
  discountCode: '',
  billings: [],
  itemsM3: 0,
  transportCost: {
    real: 0,
    remittance: 0,
    tollCostInCents: 0
  },
  legacyAdditionalCost: 0,
  baseInfo: {
    status: '',
    fullAddress: '',
    transactionId: 0,
    id: 0,
    user: {
      id: 0,
      name: '',
      lastName: ''
    },
    createdAt: null,
    datetime: null
  },
  loading: false,
  error: '',
  extras: {
    kmsExtra: 0,
    kmsExtraPrice: 0,
    transportExtra: 0,
    shipmentAssistantCount: 0,
    shipmentAssistantPrice: 0
  }
}

const SingleDepositReducer: Reducer<SingleDepositState, SingleDepositAction> = (
  state = initialState,
  action
): SingleDepositState => {
  switch (action.type) {
    case FETCH_SINGLE_DEPOSIT_DATA_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case FETCH_SINGLE_DEPOSIT_DATA_SUCCESS:
      return { ...state, loading: false, ...action.payload }
    case FETCH_SINGLE_DEPOSIT_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default SingleDepositReducer
