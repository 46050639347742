export const PRICE_BY_VALUES = {
  ITEMS: 'items',
  M3: 'm3'
}

export const PRICE_BY_LABELS = [
  {
    label: 'Items',
    value: PRICE_BY_VALUES.ITEMS
  },
  {
    label: 'M3',
    value: PRICE_BY_VALUES.M3
  }
]

export enum PAYMENT_BY_VALUES {
  CREDIT_CARD_MP = 'credit_card_mp',
  SPREEDLY = 'spreedly',
  OFFLINE = 'offline',
  CBU = 'cbu'
}

export enum ITEM_TYPES_VALUES {
  PRODUCT = 'product',
  CUSTOM = 'custom'
}

export const docTypesData = [
  { id: 'DNI', name: 'DNI', type: 'number', min_length: 7, max_length: 8 },
  { id: 'CI', name: 'Cédula', type: 'number', min_length: 1, max_length: 9 },
  { id: 'LC', name: 'L.C.', type: 'number', min_length: 6, max_length: 7 },
  { id: 'LE', name: 'L.E.', type: 'number', min_length: 6, max_length: 7 },
  { id: 'Otro', name: 'Otro', type: 'number', min_length: 5, max_length: 20 }
]

export const PROPOSAL_MODES = {
  CREATE_PROPOSAL: 'proposal',
  DEPOSIT_FROM_PROPOSAL: 'deposit_from_proposal',
  VIEW_PROPOSAL: 'view_proposal'
}

export const ANTICIPATED_HOURS = 0.5

export enum TransportTypesPricing {
  CUSTOM = 'custom',
  COMMON = 'common',
  FREE = 'free'
}

export enum TransportPricingLabel {
  CUSTOM = 'Día a elección',
  COMMON = 'Día Recorrido Programado',
  FREE = 'Gratis'
}

export enum TransportModeName {
  DROPOFF = 'dropoff',
  COLLECT = 'collect'
}
