/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { APIUser, User } from './common'
import { convertToMoment } from '../../utils'

interface Description {
  quantity: number
  users: APIUser[]
}

interface APIResponse {
  description: Description
}

type Response = {
  total: number
  users: User[]
}

interface Params {
  role?: number
  limit?: number
  offset?: number
  column?: number
  order?: SortDirection
  countryCode?: CountryIdCode
  email?: string
  id?: number
  name?: string
}

export function list({ role, limit, offset, column, order, countryCode, email, id, name }: Params): Promise<Response> {
  const params = {
    role,
    limit,
    email,
    id,
    name,
    Offset: offset,
    Column: column,
    Order: order,
    ...(countryCode ? { country_code: countryCode } : {})
  }

  return apiPrivate
    .get(`${URL_BASE_USERS}/users`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.description.users) throw new Error('Respuesta incorrecta al obtener los usuarios')
      return {
        total: response.data.description.quantity,
        users: camelcaseKeys(response.data.description.users, { deep: true }).map((user) => ({
          ...user,
          realId: user.realId,
          dateOfBirth: convertToMoment(user.dateOfBirth)
        }))
      }
    })
    .catch((err) => {
      throw err
    })
}
