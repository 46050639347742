import React, { useMemo } from 'react'
import { Button, Collapse, Modal, Row } from 'antd'
import Select from '../../../../components/Select'
import { getIdentificationCode } from '../../../ObjectsV2/utils'
import styles from './bulk.module.scss'
import BulkTransferActionCreator from '../actionCreators/bulkTransfer'
import { ObjectsTableState } from '../types/objectsTable'
import { BulkTransferStateType } from '../types/bulkTransfer'

type BulkTransferModalProps = {
  BulkTransferState: BulkTransferStateType
  BulkTransferActions: typeof BulkTransferActionCreator
  TableState: ObjectsTableState
  handleOpenModal: () => void
  handleTransferObjects: () => void
}

const BulkTransferModal = (props: BulkTransferModalProps) => {
  const { BulkTransferState, BulkTransferActions, TableState, handleOpenModal, handleTransferObjects } = props

  const {
    isOpen,
    deposits,
    fetchingDeposits,
    fetchingLocations,
    depositId,
    locations,
    locationId,
    loadingTransferObject
  } = BulkTransferState
  const { setNumberField, getLocations } = BulkTransferActions
  const { selectedObjects } = TableState

  const description = useMemo(
    () => (
      <div className={styles.description}>
        <Collapse>
          <Collapse.Panel header={'Lista de objetos a trasladar de ubicación'} key={1}>
            <div className={styles.objects}>
              {selectedObjects.map((obj) => (
                <div key={obj.id}>
                  {obj.realId} - {getIdentificationCode(obj)} {obj.product.description}
                </div>
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    ),
    [selectedObjects]
  )

  const depositsOptions = useMemo(() => {
    return deposits.map((deposit) => ({
      label: `${deposit.id} - ${deposit.name} - ${
        deposit.owner.id === 0 ? 'SpaceGuru' : deposit.owner.name + ' ' + deposit.owner.lastName
      }`,
      value: deposit.id
    }))
  }, [deposits])

  const locationsOptions = useMemo(() => {
    return locations.map((location) => ({
      label: `${location.id} - ${location.code}`,
      value: location.id
    }))
  }, [locations])

  const handleSelectDeposit = ({ value }: { value: number }) => {
    setNumberField({ field: 'depositId', value })
    setNumberField({ field: 'locationId', value: 0 })
    const params = {
      limit: 0,
      offset: 0,
      depositId: value
    }
    getLocations({ params })
  }

  const handleSelectLocation = ({ value }: { value: number }) => {
    setNumberField({ field: 'locationId', value })
  }

  const loading = loadingTransferObject || fetchingDeposits || fetchingLocations

  return (
    <Modal
      title="Traslado interno"
      visible={isOpen}
      onCancel={handleOpenModal}
      closable={!loading}
      confirmLoading={loadingTransferObject}
      footer={[
        <>
          <Button key="back" onClick={handleOpenModal} loading={loading} disabled={loading}>
            Cancelar
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleTransferObjects}
            loading={loadingTransferObject}
            disabled={loading || locationId === 0}>
            Confirmar
          </Button>
        </>
      ]}>
      {description}
      <Row>
        <div className={styles.selectInput}>
          <p style={{ marginBottom: 5 }}>Depósito</p>
          <Select
            className={styles.selectInput}
            disabled={fetchingDeposits}
            onSelect={(option) => handleSelectDeposit({ value: option.value })}
            options={depositsOptions}
            selected={depositsOptions.find((x) => x.value === depositId)}
            placeholder={fetchingDeposits ? 'Cargando depósitos...' : 'Seleccione un depósito'}
            onDelete={() => setNumberField({ field: 'depositId', value: 0 })}
          />
        </div>
      </Row>
      <Row>
        <p style={{ marginBottom: 5, marginTop: 10 }}>Ubicación</p>
        <Select
          disabled={locations.length < 1}
          onSelect={(location) => handleSelectLocation({ value: location.value })}
          options={locationsOptions}
          selected={locationsOptions.find((x) => x.value === locationId)}
          placeholder={
            locations.length >= 1 ? 'Seleccione una ubicación' : 'No hay ubicaciones disponibles en el depósito'
          }
          onDelete={() => handleSelectLocation({ value: 0 })}
        />
      </Row>
    </Modal>
  )
}

export default BulkTransferModal
