import React from 'react'

const PercentageRound: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 16.5C13.1422 16.5 16.5 13.1422 16.5 9C16.5 4.85775 13.1422 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1422 4.85775 16.5 9 16.5Z"
        stroke="#5D58F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.625 12C11.7245 12 11.8198 11.9605 11.8902 11.8902C11.9605 11.8198 12 11.7245 12 11.625C12 11.5255 11.9605 11.4302 11.8902 11.3598C11.8198 11.2895 11.7245 11.25 11.625 11.25C11.5255 11.25 11.4302 11.2895 11.3598 11.3598C11.2895 11.4302 11.25 11.5255 11.25 11.625C11.25 11.7245 11.2895 11.8198 11.3598 11.8902C11.4302 11.9605 11.5255 12 11.625 12ZM6.375 6.75C6.47446 6.75 6.56984 6.71049 6.64017 6.64017C6.71049 6.56984 6.75 6.47446 6.75 6.375C6.75 6.27554 6.71049 6.18016 6.64017 6.10983C6.56984 6.03951 6.47446 6 6.375 6C6.27554 6 6.18016 6.03951 6.10983 6.10983C6.03951 6.18016 6 6.27554 6 6.375C6 6.47446 6.03951 6.56984 6.10983 6.64017C6.18016 6.71049 6.27554 6.75 6.375 6.75Z"
        fill="#5D58F7"
        stroke="#5D58F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 6L6 12" stroke="#5D58F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default PercentageRound
