import React, { useMemo } from 'react'
import styles from './Transport.module.scss'
import Select from '../../../../components/Select'
import { Button, Col, Radio, Row, Spin } from 'antd'
import InputGroup from '../../../../components/InputGroup'
import Address from '../../../../components/Address/container'
import { useCountryInfo } from '../../../../components/CountrySelector/hooks/hooks'
import { GeocodeResult } from 'use-places-autocomplete'
import { TransportState } from '../../../../sections/Deposits/types/EditDepositViewTypes/transport'
import { TransportMode } from '../../../../projectApi/TransactionHandler/TransportMode/common'
import TransportActionsCreators from '../../../../sections/Deposits/actions/EditDepositViewActionCreators/transport'
import { DestinationDeposit } from '../../../../projectApi/TransactionHandler/ServiceType/common'
import { RadioChangeEvent } from 'antd/lib/radio'
import AddressActionCreators from '../../../../sections/Deposits/actions/EditDepositViewActionCreators/address'
import { AddressesState } from '../../../../sections/Deposits/types/EditDepositViewTypes/address'
import {
  Option,
  OptionStr,
  getAddressSelectedOption,
  getCustomTransportOptions,
  getFormatDestinationDeposit,
  getFormatTransportMode
} from './utils'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'

interface TransportProps {
  transportState: TransportState
  transportActions: typeof TransportActionsCreators
  addressActions: typeof AddressActionCreators
  addressState: AddressesState
  customDateTransportMult: number
  countryCode: string
  saveAddress: () => void
}

const Transport = ({
  transportState,
  transportActions,
  countryCode,
  customDateTransportMult,
  addressState,
  addressActions,
  saveAddress
}: TransportProps) => {
  const { setTransportMode, setDestinationDeposit, setWhereDestination, setCustomTransportType } = transportActions
  const {
    transportModeList,
    transportMode,
    transportTypePricing,
    loadingFetchCost,
    fetchedCost,
    loadingTransportMode,
    destinationDeposit,
    destinationDepositsList,
    loadingDestinationDeposits,
    tollCostInCents
  } = transportState
  const { setAddress } = addressActions
  const { addressID, addresses, loadingAddresses } = addressState

  const requireAddressOrigin = useMemo(() => {
    const actualTransportMode = getFormatTransportMode(transportMode, transportModeList)

    return !!transportModeList.find((transportMode) => transportMode.name === actualTransportMode?.value)
      ?.requireAddressOrigin
  }, [transportMode, transportModeList])

  const transportModeOptions = useMemo(
    () =>
      transportModeList.map(
        (transportMode: TransportMode): OptionStr => ({
          value: transportMode.name,
          label: transportMode.nameSpanish
        })
      ),
    [transportModeList]
  )
  const addressesOptions = useMemo(
    () => [
      ...addresses.map(
        (address): Option => ({
          value: address.id,
          label: `${address.original}
          ${address.floor ? ` - Piso: ${address.floor}` : ''}
          ${address.apartment ? ` - Departamento: ${address.apartment}` : ''}
          `
        })
      ),
      { label: 'Crear nueva dirección', value: 0 }
    ],
    [addresses]
  )
  const depositOptions = useMemo(
    () =>
      destinationDepositsList.map((destinationDeposit: DestinationDeposit) => ({
        value: destinationDeposit.id,
        label: `${destinationDeposit.name} - ${destinationDeposit.address.addressString}`
      })),
    [destinationDepositsList]
  )

  const onAddressChange = (suggest: GeocodeResult) => {
    const where = `${suggest.geometry.location.lat()},${suggest.geometry.location.lng()}`
    setWhereDestination({ where })
  }

  const prospectCountry = useCountryInfo(countryCode)

  const customTransportOptions = useMemo(
    () =>
      getCustomTransportOptions({
        prospectCountry,
        customDateTransportMult,
        fetchedCost,
        tollCost: tollCostInCents / 100
      }),
    [fetchedCost, customDateTransportMult]
  )

  const onCustomTransportChange = (e: RadioChangeEvent) => {
    const customTransportValue = e.target.value
    const customTransport = customTransportOptions.find(
      (customTransportOption) => customTransportValue === customTransportOption.value
    )
    setCustomTransportType({
      transportTypePricing: customTransportValue,
      newTransportCost: customTransport?.price ?? 0
    })
  }

  return (
    <InputGroup title="Transporte">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p className={styles.inputTitle}>Modalidad</p>
          <Select
            placeholder="Seleccionar Modalidad"
            loading={loadingTransportMode}
            options={transportModeOptions}
            selected={getFormatTransportMode(transportMode, transportModeList)}
            onSelect={(option) => setTransportMode({ transportMode: option.value })}
          />
        </Col>
        {requireAddressOrigin && (
          <Col span={24}>
            <p className={styles.inputTitle}>Dirección</p>
            <Select
              placeholder="Seleccionar Dirección"
              loading={loadingAddresses}
              options={addressesOptions}
              selected={getAddressSelectedOption(addressesOptions, addressID)}
              onSelect={(option) => setAddress(addresses.find((address) => address.id === option.value) || null)}
            />
          </Col>
        )}
        {requireAddressOrigin && addressID === 0 && (
          <Col span={24}>
            <Address onAddressChange={onAddressChange} country={countryCode as CountryIdCode} />
            <Button className={styles.saveAddress} type="primary" onClick={saveAddress}>
              Guardar dirección
            </Button>
          </Col>
        )}
        {requireAddressOrigin && (
          <Col span={24}>
            <p className={styles.inputTitle}>Valor transporte</p>
            <Radio.Group
              className={styles.relativePosition}
              onChange={onCustomTransportChange}
              value={transportTypePricing}>
              {loadingFetchCost && (
                <div className={styles.spinContainer}>
                  <Spin />
                </div>
              )}

              {customTransportOptions.map((customTransportOption) => (
                <Radio
                  key={customTransportOption.label}
                  className={customTransportOption.styles}
                  value={customTransportOption.value}>
                  {transportTypePricing === customTransportOption.value ? (
                    <b>{customTransportOption.label}</b>
                  ) : (
                    customTransportOption.label
                  )}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        )}
        <Col span={24}>
          <p className={styles.inputTitle}>Depósito de Destino</p>
          <Select
            placeholder="Seleccionar Depósito"
            loading={loadingDestinationDeposits}
            options={depositOptions}
            selected={getFormatDestinationDeposit(destinationDeposit, destinationDepositsList)}
            onSelect={(option) => setDestinationDeposit({ destinationDepositId: option.value })}
          />
        </Col>
      </Row>
    </InputGroup>
  )
}

export default Transport
