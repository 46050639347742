import types from './Payments.types'
import { URL_BASE_PAYMENTS, URL_BASE_USERS } from '../../endpoints'
import { apiPrivate } from '../../api'
import { handleErrorsWithAction, handleErrorsWithNoAction } from '../../utils/HandleErrors'
import { isEmpty, pathOr } from 'ramda'
import { getFormattedUserOptions } from '../../components/UserSearch/UserSearch.selectors'
import { getUserSearchType, getUserSearchTypeFailure } from '../../components/UserSearch/UserSearch.utils'

const deleteChargeActions = {
  deleteCharge(id) {
    return (dispatch) => {
      dispatch({ type: types.DELETE_CHARGE })
      return apiPrivate
        .delete(URL_BASE_PAYMENTS + '/charges/rules/' + id)
        .then((response) => {
          dispatch({ type: types.DELETE_CHARGE_SUCCESS, payload: { id } })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.DELETE_CHARGE_FAIL, dispatch)
        })
    }
  },
  setDeleteModalOpen(deleteModalOpen, selectedCharge = {}) {
    return {
      type: types.SET_DELETE_MODAL_OPEN,
      payload: { deleteModalOpen, selectedCharge }
    }
  }
}

const chargeTableActions = {
  cleanFilters() {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({ type: types.CLEAN_FILTERS })
        resolve()
      })
    }
  },

  getCharges(params) {
    return (dispatch) => {
      dispatch({ type: types.FETCH_CHARGES })
      return apiPrivate
        .get(URL_BASE_PAYMENTS + '/charges/rules', { params })
        .then((response) => {
          const { charges, quantity } = response.data.description

          dispatch({
            type: types.FETCH_CHARGES_SUCCESS,
            payload: { charges, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_CHARGES_FAIL, dispatch)
        })
    }
  },

  setSorter(sorter) {
    return { type: types.SET_SORTER, payload: { sorter } }
  },

  setFilter(filter) {
    return { type: types.SET_FILTER, payload: { filter } }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setClientSearch(client) {
    return { type: types.SET_CLIENT_SEARCH, payload: { client } }
  }
}

const createChargeActions = {
  setCreateModalOpen(bool) {
    return {
      type: types.SET_CREATE_MODAL_OPEN,
      payload: { createModalOpen: bool }
    }
  },
  newCharge(charge) {
    return (dispatch) => {
      dispatch({ type: types.NEW_CHARGE })

      const { type, amount, dayToCharge, startDate, endDate, userID, paymentMethod } = charge

      const formData = new FormData()
      formData.append('user_id', userID)
      formData.append('type', type)
      formData.append('amount', amount)
      formData.append('payment_method_id', paymentMethod)
      if (type === 1) formData.append('day_to_charge', dayToCharge)
      formData.append('start_date', startDate.format('YYYY-MM-DD'))
      if (endDate !== null) formData.append('end_date', endDate.format('YYYY-MM-DD'))

      return apiPrivate
        .post(URL_BASE_PAYMENTS + '/charges/rules', formData)
        .then((response) => {
          const { chargeRule } = response.data.description
          dispatch({ type: types.NEW_CHARGE_SUCCESS, payload: { chargeRule } })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.NEW_CHARGE_FAIL, dispatch)
        })
    }
  },

  setType(type) {
    return { type: types.SET_TYPE, payload: { type } }
  },

  setAmount(amount) {
    return { type: types.SET_AMOUNT, payload: { amount } }
  },

  setDayToCharge(dayToCharge) {
    return { type: types.SET_DAY_TO_CHARGE, payload: { dayToCharge } }
  },

  setStartDate(startDate) {
    return { type: types.SET_START_DATE, payload: { startDate } }
  },

  setEndDate(endDate) {
    return { type: types.SET_END_DATE, payload: { endDate } }
  },

  setPaymentMethod(paymentMethod) {
    return { type: types.SET_PAYMENT_METHOD, payload: { paymentMethod } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  }
}

let searchTimer = null

const userSearchActions = {
  getUsers(key, value) {
    return (dispatch, getState) => {
      const { type, objectKey } = getUserSearchType(types, key)

      if (searchTimer) clearTimeout(searchTimer)

      dispatch({
        type,
        payload: {
          value,
          options: [],
          loading: true
        }
      })

      searchTimer = setTimeout(() => {
        const lastSearchID = new Date().getMilliseconds()

        dispatch({
          type,
          payload: {
            lastSearchID
          }
        })
        return apiPrivate
          .get(URL_BASE_USERS + '/users', { params: { [key]: value } })
          .then((response) => {
            if (lastSearchID === getState().Payments.userSearch.userSearch[objectKey].lastSearchID) {
              const users = pathOr([], ['data', 'description', 'users'], response)
              const options = getFormattedUserOptions(users)
              dispatch({
                type,
                payload: {
                  value,
                  options,
                  loading: false
                }
              })
            }
          })
          .catch((error) => {
            const action = getUserSearchTypeFailure(types, key)
            if (isEmpty(action)) {
              handleErrorsWithNoAction(error)
            } else {
              handleErrorsWithAction(error, action, dispatch)
            }
          })
      }, 500)
    }
  },

  setUserID(userID) {
    return (dispatch) => {
      dispatch({ type: types.SET_USER_ID, payload: { userID } })
      return Promise.resolve()
    }
  }
}

export default {
  ...deleteChargeActions,
  ...chargeTableActions,
  ...createChargeActions,
  ...userSearchActions
}
