import { Reducer } from 'redux'
import {
  GET_METRICS_FAILURE,
  GET_METRICS_REQUEST,
  GET_METRICS_SUCCESS,
  MetricsLeadsActions,
  MetricsLeadsState
} from '../types/MetricsLeads'

const initialProspect: MetricsLeadsState = {
  metrics: {
    status: {
      leadsToServe: 0,
      leadsInProgress: 0,
      leadsServed: 0,
      leadsToServicePr: 0,
      leadsInProgressPr: 0,
      leadsServicedPr: 0
    },
    mqls: {
      mql: 0,
      mqlOverLeads: 0,
      mqlDiscarded: 0
    },
    sqls: {
      sql: 0,
      sqlOverLeads: 0,
      sqlOverMql: 0,
      sqlM3: 0,
      sqlTotal: 0
    },
    rps: {
      rp: 0,
      rpOverLeads: 0,
      rpOverSql: 0,
      rpOverMql: 0,
      rpc: 0,
      rpcPercentage: 0,
      rpM3: 0,
      rpTotal: 0
    }
  },
  loadingMetrics: false
}

const prospectReducer: Reducer<MetricsLeadsState, MetricsLeadsActions> = (
  state = initialProspect,
  action
): MetricsLeadsState => {
  switch (action.type) {
    case GET_METRICS_REQUEST:
      return { ...state, loadingMetrics: true }
    case GET_METRICS_SUCCESS:
      return {
        ...state,
        metrics: action.payload.metrics
      }
    case GET_METRICS_FAILURE:
      return { ...state, loadingMetrics: false }
    default:
      return state
  }
}

export default prospectReducer
