import { URL_BASE_SALES } from './../../../endpoints'
import { apiPrivate } from '../../../api'
import { convertPrice } from './common'

type CounterProposalParams = {
  id: number
  isExpressSale: boolean
  pve?: number
  prc: number
  pvp: number
  saleCost: number
  saleMargin: number
  expressSaleCost?: number
  expressSaleMargin?: number
}

interface CounterProposalData {
  exist_express_sale: boolean
  express_sale?: {
    pve?: number
    sale_cost?: number
    sale_margin?: number
  }
  prc: number
  pvp: number
  sale_cost: number
  sale_margin: number
}

export function counterProposal(params: CounterProposalParams) {
  const { id, isExpressSale, prc, pve, pvp, saleCost, saleMargin, expressSaleCost, expressSaleMargin } = params
  const data: CounterProposalData = {
    exist_express_sale: isExpressSale,
    express_sale: {
      pve: convertPrice(pve || 0, 'cents'),
      sale_cost: convertPrice(expressSaleCost || 0, 'cents'),
      sale_margin: convertPrice(expressSaleMargin || 0, 'cents')
    },
    prc: convertPrice(prc, 'cents'),
    pvp: convertPrice(pvp, 'cents'),
    sale_cost: convertPrice(saleCost, 'cents'),
    sale_margin: convertPrice(saleMargin, 'cents')
  }
  return apiPrivate
    .post(`${URL_BASE_SALES}/sales-requests/counter-proposal/${id}`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
