/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { APIAddress, Address } from '../common'

export interface PostalCode {
  ID: number
  Code: string
  AllowedDelivery: boolean
}

interface Params {
  addressId: number
}

export function getById({ addressId }: Params): Promise<Address> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/addresses/${addressId}`)
    .then((response: AxiosResponse<APIAddress>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener los usuarios')
      const addressesFormated = camelcaseKeys(response.data)
      return {
        ...addressesFormated,
        addressGoogle: response.data.original,
        street: response.data.address,
        postalCode: response?.data?.postal_code
      }
    })
    .catch((err) => {
      console.error(err)
      throw err
    })
}
