import React, { FocusEvent, ReactNode, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { Col, Input, Row } from 'antd'
import styled from 'styled-components'
import { CountryPhoneCode } from '../../../components/CountrySelector/constants/constants'
import Select from '../../../components/Select'
import { CountryPrefig, countriesList } from '../../../utils/countryCodeList'

const StyledContainerSelect = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const StyledSelect = styled(Select)`
  width: 93px;
`

const StyledDivImage = styled.div`
  margin-right: 6px;
  width: 19px;
`

const StyledDivPhone = styled.div`
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
`

const styles = {
  cellPhoneContainer: {
    display: 'flex'
  },
  countryCode: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 6
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red'
  }
}

type Option = {
  value: CountryPhoneCode
  label: string
  image: ReactNode
}

export const formatOptionLabel = (option: any) => {
  return (
    <StyledContainerSelect>
      <StyledDivImage>{option.image}</StyledDivImage>
      <StyledDivPhone>{option.label}</StyledDivPhone>
    </StyledContainerSelect>
  )
}

const Cellphone = (props: any) => {
  const {
    countries,
    reset,
    disabled,
    createModalOpen,
    isAlternative,
    countryPhoneCode,
    setCountryPhoneCode,
    countrySelected
  } = props
  const { errorAreaCode = '', errorPhoneBody = '', onPhoneBodyBlur = () => {} } = props
  const [areaCode, setAreaCode] = useState('')
  const [phoneBody, setPhoneBody] = useState('')

  const isCountryCodeArgentina = countryPhoneCode === CountryPhoneCode.ARGENTINA
  const isCountryCodeSpain = countryPhoneCode === CountryPhoneCode.SPAIN

  const phoneBodyDisabled = isCountryCodeArgentina && (isEmpty(areaCode) || errorAreaCode)

  useEffect(() => {
    if (reset) {
      setAreaCode('')
      setPhoneBody('')
    }
  }, [createModalOpen, reset])

  useEffect(() => {
    setAreaCode('')
  }, [countryPhoneCode])

  const onAreaCodeBlur = (e: FocusEvent<HTMLInputElement>) => {
    const areaCode = parseInt(e.target.value, 10)
    const areaCodeString = areaCode.toString()
    if (!isNaN(areaCode)) setAreaCode(areaCodeString)

    if (props.onAreaCodeBlur) {
      props.onAreaCodeBlur(isNaN(areaCode) ? '' : areaCodeString)
    }
  }

  const handleChangeCountryCode = (option: any) => {
    setCountryPhoneCode(option?.value || null)
  }

  const countryOptions: Option[] = useMemo(
    () =>
      countriesList.map((c: CountryPrefig) => ({
        value: c.value,
        label: c.label,
        image: <img width="100%" src={c.image} alt={c.alt} />
      })),
    []
  )

  const selectedCountry = useMemo(() => {
    if (countrySelected && !countryPhoneCode) return setCountryPhoneCode(countrySelected.countryPhoneCode)
    else return countryOptions.find((countryOption) => countryOption.value === countryPhoneCode)
  }, [countryOptions, countryPhoneCode, countrySelected])

  return (
    <Row gutter={[16, 16]} style={styles.cellPhoneContainer} align="top">
      <Col span={8} style={styles.countryCode}>
        <StyledSelect
          placeholder={'País'}
          selected={selectedCountry}
          options={countryOptions}
          optionRenderer={formatOptionLabel}
          disabled={disabled}
          onSelect={(option) => handleChangeCountryCode(option)}
        />
      </Col>
      {isCountryCodeArgentina && (
        <Col span={5}>
          <Input
            style={errorAreaCode ? styles.errorField : {}}
            placeholder="Area*"
            value={areaCode}
            onChange={(e) => {
              const value = e.target.value
              const valueInt = parseInt(value, 10)
              if (value.length > 5 || isNaN(valueInt)) return
              setAreaCode(value)
              setPhoneBody('')
            }}
            onBlur={onAreaCodeBlur}
            disabled={disabled}
            autoComplete="nope"
          />
          {errorAreaCode && <p style={styles.errorFieldLabel}>{errorAreaCode}</p>}
        </Col>
      )}
      <Col span={11}>
        <Input
          style={errorPhoneBody ? styles.errorField : {}}
          placeholder={isAlternative ? 'Número' : 'Número*'}
          value={phoneBody}
          onChange={(e) => {
            const value = e.target.value
            const valueInt = parseInt(value, 10)
            if (
              (isCountryCodeSpain && value.length > 9) ||
              (isCountryCodeArgentina && value.length > 10 - areaCode.length) ||
              value.length > 11 ||
              isNaN(valueInt)
            )
              return
            setPhoneBody(value)
          }}
          onBlur={(e) => {
            onPhoneBodyBlur(countryPhoneCode, areaCode, e.target.value)
          }}
          disabled={phoneBodyDisabled || disabled}
          autoComplete="nope"
        />
        {errorPhoneBody && <p style={styles.errorFieldLabel}>{errorPhoneBody}</p>}
      </Col>
    </Row>
  )
}

export default Cellphone
