import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type ListParams = {
  code: string
}

interface APIDiscount {
  deposit: number
  general: number
  transport: number
  valid: boolean
}

interface APIResponse {
  description: APIDiscount
  success: string
}

export type Discount = CamelCasedPropertiesDeep<APIDiscount>

export function getByCode(params: ListParams): Promise<Discount> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/compute/transaction/discount`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data.description, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
