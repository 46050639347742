import React, { Component } from 'react'
import EditIcon from '../../icons/edit'
import DeleteIcon from '../../icons/trash'
import AddIcon from '../../icons/addIcon'
import { Table, Tooltip } from 'antd'
import style from './Categories.module.scss'

const styles = {
  imgSmall: {
    width: '50px'
  }
}

const initialState = {}

class CategoriesTable extends Component {
  constructor() {
    super()
    this.state = initialState
  }

  getColumns() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '12%'
      },
      {
        title: 'Descripción',
        dataIndex: 'Description'
      },
      {
        title: 'Foto',
        render: (text, record) => <img style={styles.imgSmall} src={record.CategoryPhoto.Url}></img>
      },
      {
        title: 'Nueva subcategoría',
        key: 'action',
        render: (text, record) => (
          <span style={{ textAlign: 'center' }}>
            <Tooltip placement="top" title={'Agregar sub-categoria'}>
              <i style={{ cursor: 'pointer' }} onClick={() => this.addSubcategory(record.id)}>
                <AddIcon />
              </i>
            </Tooltip>
            <Tooltip placement="top" title={'Modificar'}>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => this.showEditPanel(record.id, record.Description, record.CategoryPhoto.Url)}>
                <EditIcon />
              </i>
            </Tooltip>
            <Tooltip placement="top" title={'Eliminar'}>
              <i style={{ cursor: 'pointer' }} onClick={() => this.showDeletePanel(record.id)}>
                <DeleteIcon />
              </i>
            </Tooltip>
          </span>
        )
      }
    ]
    return columns
  }

  addSubcategory(parentID) {
    this.props.showAddPanel(parentID)
  }

  showEditPanel(id, description, photoURL) {
    this.props.showEditPanel(id, description, photoURL)
  }

  showDeletePanel(id) {
    this.props.showDeletePanel(id)
  }

  render() {
    return (
      <Table
        columns={this.getColumns()}
        dataSource={this.props.categoryTree}
        pagination={{ size: 'small', style: { margin: '12px 0' } }}
        className={style.customTableProps}
        size="middle"
      />
    )
  }
}

export default CategoriesTable
