import types from '../types/deletePhoto'

const initialState = {
  open: false,
  objectID: 0,
  photoID: 0,
  loading: false
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_MODAL: {
      const { objectID, photoID } = action.payload
      return { ...state, photoID, objectID, open: true }
    }
    case types.CLOSE_MODAL: {
      return { ...state, open: false }
    }
    case types.DELETE_PHOTO_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.DELETE_PHOTO_SUCCESS:
      return { ...state, loading: false, open: false }
    case types.DELETE_PHOTO_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
