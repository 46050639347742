import { ResponseOperationDays } from '../../../projectApi/Timeslot/OperationCalendar/WeekDays/get'
import { OperationTypeValues } from '../types/common'

const ENGLISH_TO_SPANISH = new Map<string, string>([
  ['Monday', 'Lunes'],
  ['Tuesday', 'Martes'],
  ['Wednesday', 'Miércoles'],
  ['Thursday', 'Jueves'],
  ['Friday', 'Viernes'],
  ['Saturday', 'Sábado']
])

const SPANISH_TO_ENGLISH: Record<string, string> = {}
ENGLISH_TO_SPANISH.forEach((spanish, english) => {
  SPANISH_TO_ENGLISH[spanish] = english
})

export function translateDayName(dayName: string, toLanguage: 'en' | 'es'): string {
  if (toLanguage === 'es') {
    return ENGLISH_TO_SPANISH.get(dayName) || dayName
  } else if (toLanguage === 'en') {
    return SPANISH_TO_ENGLISH[dayName] || dayName
  } else {
    return dayName
  }
}

export function processDaysResponse(daysResponse: ResponseOperationDays) {
  const daysMap: Record<string, { day: string; availableOperations: OperationTypeValues[] }> = {}

  for (const { day, available, operationType } of daysResponse) {
    if (day === 'Sunday') continue
    if (!daysMap[day]) daysMap[day] = { day, availableOperations: [] }
    if (available) daysMap[day].availableOperations.push(operationType)
  }

  const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const sortedTranslatedDays = Object.values(daysMap)
    .sort((a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day))
    .map((day) => ({
      ...day,
      day: translateDayName(day.day, 'es') || day.day
    }))

  return sortedTranslatedDays
}
