import types from '../types/terms'

const initialState = {
  userId: 0,
  userName: '',
  userLastName: '',
  country: '',
  open: false,
  link: '',
  loading: false,
  error: ''
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.OPEN:
      return {
        ...state,
        open: true,
        userId: action.payload.id,
        userName: action.payload.name,
        userLastName: action.payload.lastName,
        country: action.payload.country
      }
    case types.CLOSE:
      return initialState
    case types.GENERATE_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.GENERATE_SUCCESS:
      return { ...state, loading: false, link: action.payload.link }
    case types.GENERATE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
