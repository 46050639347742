export enum DiscountsEventsEnum {
  CLEAN_FILTERS = 'DISCOUNTS/CLEAN_FILTERS',
  CREATE_DISCOUNT = 'DISCOUNTS/CREATE_DISCOUNT',
  UPDATE_DISCOUNT = 'DISCOUNTS/UPDATE_DISCOUNT',
  DELETE_DISCOUNT = 'DISCOUNTS/DELETE_DISCOUNT'
}

export type DiscountsEvents = {
  [DiscountsEventsEnum.CLEAN_FILTERS]: () => void
  [DiscountsEventsEnum.CREATE_DISCOUNT]: () => void
  [DiscountsEventsEnum.UPDATE_DISCOUNT]: () => void
  [DiscountsEventsEnum.DELETE_DISCOUNT]: () => void
}
