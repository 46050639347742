import { Transaction } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import { Trip, TripStatusId } from '../../../projectApi/Trips/Trip/common'
import { TripsListSortKey } from '../../../projectApi/Trips/Trip/list'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'
import { AssignOperatorsSuccess } from '../../TransactionOperatorsAssignment/types'
import { GetTripTransactionsAction } from './common'
import { SendPreBillingReportActions } from './tripsReports'

export type TripsTableSearchKey = 'id'
export type TripsSearchFilter = SearchFilter<TripsTableSearchKey>
export enum TripsTableSearchFilterKey {
  ID = 'id'
}

export type TripsTableDateRangeKey =
  | 'realStartDate'
  | 'realEndDate'
  | 'scheduledStartDate'
  | 'scheduledEndDate'
  | 'createdAt'

export enum TripsTableDateRangeFilterKey {
  START_DATE = 'realStartDate',
  END_DATE = 'realEndDate',
  SCHEDULED_START = 'scheduledStartDate',
  SCHEDULED_END = 'scheduledEndDate',
  CREATED_AT = 'createdAt'
}

export type TripsDateRangeFilter = DateRangeFilter<TripsTableDateRangeKey>

export type TripsTableSortKey = TripsListSortKey
export type TripsTableSort = Sort<TripsTableSortKey>

export type TripsTableCategoryFilterKey = 'tripStatus'
export type TripsTableCategoryFilter = CategoryFilter<TripsTableCategoryFilterKey, TripStatusId>

export type TripWithTransactions = Trip & {
  transactions: Transaction[]
}

export type Sorter = {
  order: string
  field: string
  columnKey: string
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface TripsTableState {
  trips: TripWithTransactions[]
  loadingTrips: boolean
  loadingTransactionsForTripIds: number[]
  loadingTripIds: number[]
  startedTripIds: number[]
  finishedTripIds: number[]
  error: string
  categoryFilter: TripsTableCategoryFilter
  pagination: Pagination
  searchFilters: TripsSearchFilter[]
  dateRangeFilters: TripsDateRangeFilter[]
  sort: TripsTableSort
  cancelingModalId: number | null
  canceling: boolean
  canceledTripIds: number[]
  selectedTransactions: Transaction[]
  selectedTrips: TripWithTransactions[]
  sendingCSV: boolean
  sendingTXByTripsReport: boolean
}

export const SET_CANCELING_MODAL_ID = 'TRIPS/TABLE/SET_CANCELING_MODAL_ID'

export interface SetCancelingModalId {
  type: typeof SET_CANCELING_MODAL_ID
  payload: {
    id: number | null
  }
}

export const TOGGLE_SELECTED_TRANSACTION = 'TRIPS/TABLE/TOGGLE_SELECTED_TRANSACTION'

export interface ToggleSelectedTransaction {
  type: typeof TOGGLE_SELECTED_TRANSACTION
  payload: {
    transaction: Transaction
  }
}

export const TOGGLE_TRIP_TRANSACTIONS = 'TRIPS/TABLE/TOGGLE_TRIP_TRANSACTIONS'

export interface ToggleTripTransactions {
  type: typeof TOGGLE_TRIP_TRANSACTIONS
  payload: {
    trip: TripWithTransactions
  }
}

export enum SelectedTripsTypes {
  TOGGLE_SELECTED_TRIP = 'TRIPS/TABLE/TOGGLE_SELECTED_TRIP',
  TOGGLE_ALL_TRIPS = 'TRIPS/TABLE/TOGGLE_ALL_TRIPS',
  CLEAR_SELECTED_TRIPS = 'TRIPS/TABLE/CLEAR_SELECTED_TRIPS'
}

export interface ToggleSelectedTrip {
  type: typeof SelectedTripsTypes.TOGGLE_SELECTED_TRIP
  payload: {
    trip: TripWithTransactions
  }
}

export interface ToggleAllTrips {
  type: typeof SelectedTripsTypes.TOGGLE_ALL_TRIPS
}

export interface ClearSelectedTrips {
  type: typeof SelectedTripsTypes.CLEAR_SELECTED_TRIPS
}

type SelectedTripsActions = ToggleSelectedTrip | ToggleAllTrips | ClearSelectedTrips

export const GET_TRIPS_REQUEST = 'TRIPS/TABLE/GET_TRIPS_REQUEST'
export const GET_TRIPS_SUCCESS = 'TRIPS/TABLE/GET_TRIPS_SUCCESS'
export const GET_TRIPS_FAILURE = 'TRIPS/TABLE/GET_TRIPS_FAILURE'

export interface GetTripsRequest {
  type: typeof GET_TRIPS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: TripsSearchFilter[]
    dateRangeFilters?: TripsDateRangeFilter[]
    categoryFilter?: TripsTableCategoryFilter
    sort?: TripsTableSort
    silentLoading?: boolean
  }
}

export interface GetTripsSuccess {
  type: typeof GET_TRIPS_SUCCESS
  payload: {
    total: number
    trips: TripWithTransactions[]
  }
}

export interface GetTripsFailure {
  type: typeof GET_TRIPS_FAILURE
  payload: {
    error: string
  }
}

export type GetTripsAction = GetTripsRequest | GetTripsSuccess | GetTripsFailure

export const START_TRIP_REQUEST = 'TRIPS/TABLE/START_TRIP_REQUEST'
export const START_TRIP_SUCCESS = 'TRIPS/TABLE/START_TRIP_SUCCESS'
export const START_TRIP_FAILURE = 'TRIPS/TABLE/START_TRIP_FAILURE'

export interface StartTripRequest {
  type: typeof START_TRIP_REQUEST
  payload: {
    tripId: number
  }
}

export interface StartTripSuccess {
  type: typeof START_TRIP_SUCCESS
  payload: {
    tripId: number
  }
}

export interface StartTripFailure {
  type: typeof START_TRIP_FAILURE
  payload: {
    error: string
    tripId: number
  }
}

export type StartTripAction = StartTripRequest | StartTripSuccess | StartTripFailure

export const FINISH_TRIP_REQUEST = 'TRIPS/TABLE/FINISH_TRIP_REQUEST'
export const FINISH_TRIP_SUCCESS = 'TRIPS/TABLE/FINISH_TRIP_SUCCESS'
export const FINISH_TRIP_FAILURE = 'TRIPS/TABLE/FINISH_TRIP_FAILURE'

export interface FinishTripRequest {
  type: typeof FINISH_TRIP_REQUEST
  payload: {
    tripId: number
  }
}

export interface FinishTripSuccess {
  type: typeof FINISH_TRIP_SUCCESS
  payload: {
    tripId: number
  }
}

export interface FinishTripFailure {
  type: typeof FINISH_TRIP_FAILURE
  payload: {
    error: string
    tripId: number
  }
}

export type FinishTripAction = FinishTripRequest | FinishTripSuccess | FinishTripFailure

export const CANCEL_TRIP_REQUEST = 'TRIPS/TABLE/CANCEL_TRIP_REQUEST'
export const CANCEL_TRIP_SUCCESS = 'TRIPS/TABLE/CANCEL_TRIP_SUCCESS'
export const CANCEL_TRIP_FAILURE = 'TRIPS/TABLE/CANCEL_TRIP_FAILURE'

export interface CancelTripRequest {
  type: typeof CANCEL_TRIP_REQUEST
  payload: {
    tripId: number
  }
}

export interface CancelTripSuccess {
  type: typeof CANCEL_TRIP_SUCCESS
  payload: {
    tripId: number
  }
}

export interface CancelTripFailure {
  type: typeof CANCEL_TRIP_FAILURE
  payload: {
    error: string
    tripId: number
  }
}

export type CancelTripAction = CancelTripRequest | CancelTripSuccess | CancelTripFailure

export const REMOVE_TRANSACTION_FROM_TRIP_REQUEST = 'TRIPS/TABLE/REMOVE_TRANSACTION_FROM_TRIP_REQUEST'
export const REMOVE_TRANSACTION_FROM_TRIP_SUCCESS = 'TRIPS/TABLE/REMOVE_TRANSACTION_FROM_TRIP_SUCCESS'
export const REMOVE_TRANSACTION_FROM_TRIP_FAILURE = 'TRIPS/TABLE/REMOVE_TRANSACTION_FROM_TRIP_FAILURE'

export interface RemoveTransactionFromTripRequest {
  type: typeof REMOVE_TRANSACTION_FROM_TRIP_REQUEST
}

export interface RemoveTransactionFromTripSuccess {
  type: typeof REMOVE_TRANSACTION_FROM_TRIP_SUCCESS
}

export interface RemoveTransactionFromTripFailure {
  type: typeof REMOVE_TRANSACTION_FROM_TRIP_FAILURE
}

export type RemoveTransactionFromTripActions =
  | RemoveTransactionFromTripRequest
  | RemoveTransactionFromTripSuccess
  | RemoveTransactionFromTripFailure

export const CLEAR_STATE = 'REMOVALS/REMOVALS_TABLE/CLEAR_STATE'
export interface ClearState {
  type: typeof CLEAR_STATE
}

export type TripsTableAction =
  | GetTripsAction
  | GetTripTransactionsAction
  | StartTripAction
  | FinishTripAction
  | CancelTripAction
  | SetCancelingModalId
  | ToggleSelectedTransaction
  | ToggleTripTransactions
  | AssignOperatorsSuccess
  | RemoveTransactionFromTripActions
  | SendPreBillingReportActions
  | SelectedTripsActions
  | ClearState
