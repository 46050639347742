import React from 'react'
import StatusCard from '@/components/StatusCard'
import { DashboardMetrics } from '@/projectApi/TransactionHandler/Dashboard/metrics'
import styles from './DashboardSideMetrics.module.scss'
import { formatNumber } from '@/utils/formatNumber'
import { useLocalization } from '@/packages/localization'

type DashboardSideMetricsProps = {
  metrics: DashboardMetrics
}

const DashboardSideMetrics = ({ metrics }: DashboardSideMetricsProps) => {
  const { strings } = useLocalization()

  const CardDevoluciones = [
    {
      titleCard: strings.dashboardViewScreen.removals.total,
      total: metrics.totalRemovals,
      description: strings.dashboardViewScreen.removals.totalDescription
    },
    {
      titleCard: strings.dashboardViewScreen.removals.m3,
      total: `${formatNumber(metrics.m3Removals, 2)}m³`,
      description: strings.dashboardViewScreen.removals.m3Description
    },
    {
      titleCard: strings.dashboardViewScreen.removals.clients,
      total: metrics.quantityClientsWithFullRemovals,
      description: strings.dashboardViewScreen.removals.clientsDescription
    }
  ]

  return (
    <div className={styles.removals}>
      <h3>{strings.dashboardViewScreen.removals.title}</h3>
      <div className={styles.removalCardsContainer}>
        {CardDevoluciones.map(({ titleCard, total, description }) => (
          <StatusCard
            key={`${titleCard}`}
            titleCard={titleCard || ''}
            total={total}
            type="neutral"
            customClassname={styles.customCardRemovals}
            totalStyles={styles.totalCard}
            titleStyles={styles.titleCard}
            description={description}
            tooltipIconColor="#3f444d"
          />
        ))}
      </div>
    </div>
  )
}

export default DashboardSideMetrics
