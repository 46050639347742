import { Discount } from '../../../projectApi/TransactionHandler/BillingDiscounts/list'
import { Application, Billing, BillingObject } from '../../../projectApi/TransactionHandler/Billings/common'
import { DiscountDetail } from '../../../projectApi/TransactionHandler/Discounts/discount'
import { UserBillingInformation } from '../../../projectApi/TransactionHandler/UserBillingInformation/getByUserId'

export interface BillingByClientState {
  clientBillingInformation: UserBillingInformation | null
  fetchingClientBillingInformation: boolean
  errorClientBillingInformation: string
  billingsByClient: BillingWithDiscounts[]
  loadingBillingsByClient: boolean
  error: string
  discounts: Discount[]
  loadingBillingDiscounts: boolean
  errorBillingDiscounts: string
  pagination: {
    page: number
    total: number
    pageSize: number
  }
  queryData: QueryTypes
}
export type QueryTypes = {
  userId: number
  name: string | string[] | null
  lastName: string | string[] | null
}

export enum EXTRA_PRICES {
  KMS_EXTRA = 'KMS_EXTRA',
  SHIPMENT_ASSISTANT = 'SHIPMENT_ASSISTANT',
  TRANSPORT = 'TRANSPORT',
  COST_PER_FLOOR = 'COST_PER_FLOOR',
  PACKAGING = 'PACKAGING',
  DISASSEMBLY = 'DISASSEMBLY'
}
export type ObjectWithDiscount = BillingObject & { discount: Discount | null } & {
  objectApplications: Application[] | null
}
export type BillingWithDiscounts = Billing & {
  objects: ObjectWithDiscount[]
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface DiscountDetailsById {
  [key: string | number]: DiscountDetail
}

export const GET_CLIENT_DISCOUNTS_REQUEST = 'BILLINGS_BY_CLIENT/GET_CLIENT_DISCOUNTS_REQUEST'
export const GET_CLIENT_DISCOUNTS_SUCCESS = 'BILLINGS_BY_CLIENT/GET_CLIENT_DISCOUNTS_SUCCESS'
export const GET_CLIENT_DISCOUNTS_FAILURE = 'BILLINGS_BY_CLIENT/GET_CLIENT_DISCOUNTS_FAILURE'

export interface GetBillingClientDiscountRequest {
  type: typeof GET_CLIENT_DISCOUNTS_REQUEST
}

export interface GetBillingClientDiscountSuccess {
  type: typeof GET_CLIENT_DISCOUNTS_SUCCESS
  payload: {
    billingId: number
    discounts: Discount[]
  }
}

export interface GetBillingClientDiscountFailure {
  type: typeof GET_CLIENT_DISCOUNTS_FAILURE
}

export type GetBillingDiscountAction =
  | GetBillingClientDiscountRequest
  | GetBillingClientDiscountSuccess
  | GetBillingClientDiscountFailure

export const GET_PDF_BY_CLIENT_REQUEST = 'BILLINGS_BY_CLIENT/GET_PDF_BY_CLIENT_REQUEST'
export const GET_PDF_BY_CLIENT_SUCCESS = 'BILLINGS_BY_CLIENT/GET_PDF_BY_CLIENT_SUCCESS'
export const GET_PDF_BY_CLIENT_FAILURE = 'BILLINGS_BY_CLIENT/GET_PDF_BY_CLIENT_FAILURE'

export interface GetPdfByClientRequest {
  type: typeof GET_PDF_BY_CLIENT_REQUEST
}

export interface GetPdfByClientSuccess {
  type: typeof GET_PDF_BY_CLIENT_SUCCESS
}

export interface GetPdfByClientFailure {
  type: typeof GET_PDF_BY_CLIENT_FAILURE
}

export type GetPdfByClient = GetPdfByClientRequest | GetPdfByClientSuccess | GetPdfByClientFailure

export const GET_BILLING_BY_CLIENT_REQUEST = 'BILLINGS/BILLINGS_BY_CLIENT/GET_BILLING_BY_CLIENT_REQUEST'
export const GET_BILLING_BY_CLIENT_SUCCESS = 'BILLINGS/BILLINGS_BY_CLIENT/GET_BILLING_BY_CLIENT_SUCCESS'
export const GET_BILLING_BY_CLIENT_FAILURE = 'BILLINGS/BILLINGS_BY_CLIENT/GET_BILLING_BY_CLIENT_FAILURE'

export interface GetBillingByClientRequest {
  type: typeof GET_BILLING_BY_CLIENT_REQUEST
  payload: {
    pageSize: number
    newPage: number
  }
}

export interface GetBillingByClientSuccess {
  type: typeof GET_BILLING_BY_CLIENT_SUCCESS
  payload: {
    billingsByClient: BillingWithDiscounts[]
    total: number
  }
}

export interface GetBillingByClientFailure {
  type: typeof GET_BILLING_BY_CLIENT_FAILURE
  payload: {
    error: string
  }
}

export type GetBillingByClientAction = GetBillingByClientRequest | GetBillingByClientSuccess | GetBillingByClientFailure

export const SET_QUERY_DATA = 'BILLINGS/BILLINGS_BY_CLIENT/SET_QUERY_DATA'

export interface setQueryData {
  type: typeof SET_QUERY_DATA
  payload: {
    queryData: QueryTypes
  }
}

export enum GetClientBillingInformationTypes {
  GET_CLIENT_BILLING_INFORMATION_REQUEST = 'BILLINGS/BILLINGS_BY_CLIENT/GET_CLIENT_BILLING_INFORMATION_REQUEST',
  GET_CLIENT_BILLING_INFORMATION_SUCCESS = 'BILLINGS/BILLINGS_BY_CLIENT/GET_CLIENT_BILLING_INFORMATION_SUCCESS',
  GET_CLIENT_BILLING_INFORMATION_FAILURE = 'BILLINGS/BILLINGS_BY_CLIENT/GET_CLIENT_BILLING_INFORMATION_FAILURE'
}

export interface GetClientBillingInformationRequest {
  type: GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_REQUEST
}

export interface GetClientBillingInformationSuccess {
  type: GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_SUCCESS
  payload: {
    clientBillingInformation: UserBillingInformation
  }
}

export interface GetClientBillingInformationFailure {
  type: GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_FAILURE
  payload: {
    error: string
  }
}

export type GetClientBillingInformationAction =
  | GetClientBillingInformationRequest
  | GetClientBillingInformationSuccess
  | GetClientBillingInformationFailure

export type BillingByClientAction =
  | GetBillingByClientAction
  | GetPdfByClient
  | GetBillingDiscountAction
  | setQueryData
  | GetClientBillingInformationAction
