export enum BillingEventsEnum {
  AUTHORIZATION_EXISTS = 'BILLING/AUTHORIZATION_EXISTS',
  AUTHORIZATION_DOESNT_EXIST = 'BILLING/AUTHORIZATION_DOESNT_EXIST',
  BILLING_PAID = 'BILLING/BILLING_PAID',
  USER_EXCLUSION = 'BILLING/USER_EXCLUSION',
  USER_BILLING_ENABLE = 'BILLING/USER_BILLING_ENABLE',
  CREDIT_NOTE_EXIST = 'BILLING/CREDIT_NOTE_EXIST',
  CREDIT_NOTE_DOESNT_EXIST = 'BILLING/CREDIT_NOTE_DOESNT_EXIST'
}

export type BillingEvents = {
  [BillingEventsEnum.AUTHORIZATION_EXISTS]: ({ pdfUrl }: { pdfUrl: string }) => void
  [BillingEventsEnum.AUTHORIZATION_DOESNT_EXIST]: () => void
  [BillingEventsEnum.BILLING_PAID]: () => void
  [BillingEventsEnum.USER_EXCLUSION]: () => void
  [BillingEventsEnum.USER_BILLING_ENABLE]: () => void
  [BillingEventsEnum.CREDIT_NOTE_EXIST]: ({ pdfUrl }: { pdfUrl: string }) => void
  [BillingEventsEnum.CREDIT_NOTE_DOESNT_EXIST]: () => void
}
