import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { APITransportMode } from './common'
import snakecaseKeys from 'snakecase-keys'

export interface Description {
  quantity: number
  transportMode: APITransportMode[]
}

export interface APIResponse {
  description?: Description
  success: string
}

interface Params {
  countryCode?: string
}

export type TransportModeResponse = CamelCasedPropertiesDeep<Description>

export function getByService(serviceType: string, params: Params = {}): Promise<TransportModeResponse> {
  const formattedParams = snakecaseKeys({ ...params })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/serviceType/${serviceType}/transportMode`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener el modo de transporte')
      if (!response.data.description?.transportMode)
        throw new Error('Respuesta incorrecta al obtener el modo de transporte')
      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
