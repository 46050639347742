import React, { useMemo } from 'react'
import { Input, Modal } from 'antd'
import styles from './AMDiscountDay.module.scss'
import { InfoCard } from '../InfoDiscountCard'
import { DiscountWeekdaysFilterKey, Weekdays } from '../../../../../projectApi/Timeslot/Discounts/SpecificDays/common'
import { ModalCreateDiscountDayState } from '../../../types/ABMDiscountDays'
import Select from '../../../../../components/Select'
import { BaseOption } from '../../../../../components/Select/Select'

type Option = BaseOption<DiscountWeekdaysFilterKey>

type CreateDiscountModalProps = {
  disabledCreateButton: boolean
  loadingCreateButton: boolean
  setModalVisibility: ({ modalVisible }: { modalVisible: boolean }) => void
  onChangeDiscountRate: (discountRate: string) => void
  handleConfirmButton: () => void
  setDiscountDay: (selectedDay: DiscountWeekdaysFilterKey | null) => void
} & Omit<
  ModalCreateDiscountDayState,
  'creatingDiscount' | 'updatingDiscount' | 'deleteModalVisible' | 'deletingDiscount'
>

const dayOptions: Option[] = [
  { value: Weekdays.LUNES, label: Weekdays.LUNES },
  { value: Weekdays.MARTES, label: Weekdays.MARTES },
  { value: Weekdays.MIERCOLES, label: Weekdays.MIERCOLES },
  { value: Weekdays.JUEVES, label: Weekdays.JUEVES },
  { value: Weekdays.VIERNES, label: Weekdays.VIERNES },
  { value: Weekdays.SABADO, label: Weekdays.SABADO },
  { value: Weekdays.DOMINGO, label: Weekdays.DOMINGO }
]

const CreateDiscountModal = ({
  editMode,
  modalVisible,
  discountRate,
  selectedDay,
  selectedDiscount,
  disabledCreateButton,
  loadingCreateButton,
  setModalVisibility,
  handleConfirmButton,
  setDiscountDay,
  onChangeDiscountRate
}: CreateDiscountModalProps) => {
  const selectedSelectOption = useMemo(() => {
    return (
      dayOptions.find((option) => option.value === selectedDay) ||
      dayOptions.find((option) => option.value === selectedDiscount?.weekday)
    )
  }, [selectedDay, selectedDiscount])

  const modalTitle = editMode ? 'Editar descuento para un día específico' : 'Crear descuento para un día específico'
  const confirmButtonText = editMode ? 'Editar' : 'Crear'

  return (
    <Modal
      width={'max-content'}
      onCancel={() => setModalVisibility({ modalVisible: false })}
      title={modalTitle}
      confirmLoading={loadingCreateButton}
      visible={modalVisible}
      okText={confirmButtonText}
      cancelText="Cancelar"
      onOk={handleConfirmButton}
      okButtonProps={{ disabled: disabledCreateButton }}
      destroyOnClose>
      {editMode ? (
        <InfoCard id={selectedDiscount?.id} day={selectedDiscount?.weekday} discountRate={selectedDiscount?.discount} />
      ) : null}
      <div className={styles.container}>
        <label className={styles.label}>
          Día del descuento:
          <Select
            className={styles.select}
            selected={selectedSelectOption}
            options={dayOptions}
            onSelect={(option) => setDiscountDay(option.value)}
            placeholder="Seleccioná un día"
            disabled={editMode}
          />
        </label>
        <label className={styles.label}>
          Porcentaje de descuento:
          <Input
            className={styles.rateInput}
            type="number"
            value={discountRate.value ?? selectedDiscount?.discount}
            max={100}
            onChange={(rate) => onChangeDiscountRate(rate.target.value)}
          />
        </label>
      </div>
      {discountRate.error ? (
        <p style={{ textAlign: 'center', color: '#F5522E', margin: '12px 0 0' }}>{discountRate.error}</p>
      ) : null}
    </Modal>
  )
}

export default CreateDiscountModal
