import { API } from '../../projectApi'
import { AppThunk } from '../../store'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import { UploadFileActionTypes, UploadFileRequest, UploadFileSuccess } from './types'
import Emitter from '../../utils/eventEmitter'
import { Events } from '../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../utils/notifications'

const actions = {
  uploadFiles:
    (files: File[], fileType: string): AppThunk =>
    async (dispatch) => {
      const toast = setToastLoading('Los archivos se están subiendo, por favor espera un momento')
      if (files.length === 0) {
        setToastErrorUpdate(toast, { render: 'Revisar el formato de archivo' })
        handleErrorsWithAction(
          'No se ha podido cargar el archivo',
          UploadFileActionTypes.UPLOAD_FILES_FAILURE,
          dispatch
        )
        return
      }
      const requests = files.map((file) => API.TransactionHandler.File.uploadFile({ file, fileType }))
      const requestAction: UploadFileRequest = { type: UploadFileActionTypes.UPLOAD_FILES_REQUEST }
      dispatch(requestAction)

      try {
        await Promise.all(requests)
        setToastSuccessUpdate(toast, { render: 'Los archivos se subieron correctamente' })
        const successAction: UploadFileSuccess = { type: UploadFileActionTypes.UPLOAD_FILES_SUCCESS }
        dispatch(successAction)
        Emitter.emit(Events.UploadFiles.UPLOAD_FILE_SUCCESS)
      } catch (error) {
        setToastErrorUpdate(toast, { render: 'Hubo un error al subir los archivos' })
        handleErrorsWithAction(error, UploadFileActionTypes.UPLOAD_FILES_FAILURE, dispatch)
      }
    }
}

export default actions
