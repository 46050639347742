import React from 'react'
import { Country } from '../CountrySelector/constants/constants'

const styles = {
  group: {
    padding: 10,
    border: '1px solid rgb(217,217,217)',
    borderRadius: 4
  },
  countryFlag: {
    position: 'relative' as const,
    bottom: 2,
    marginRight: '4px'
  },
  groupTitle: {
    position: 'relative' as const,
    top: -21,
    left: -4,
    width: 'fit-content',
    marginBottom: -20,
    padding: '0 4px',
    background: 'white',
    fontWeight: 500
  }
}

type InputGroupProps = {
  country?: Country
  title?: string
  style?: any
  className?: string
  children: React.ReactNode
}

const InputGroup = ({ country, title, style, className, children }: InputGroupProps) => (
  <div
    style={{
      ...styles.group,
      marginTop: title ? 12 : 0,
      ...style
    }}
    className={className}>
    {title ? (
      <p style={styles.groupTitle}>
        {country && <img style={styles.countryFlag} src={country.flagUrl} alt={country.fullName} />} {title}
      </p>
    ) : null}
    {children}
  </div>
)

export default InputGroup
