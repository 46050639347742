import { Col, Row } from 'antd'
import AsyncSelect from '../AsyncSelect/AsyncSelect'
import InputGroup from '../InputGroup'
import React from 'react'
import { func, number, shape } from 'prop-types'

const UserSearch = ({ setUserID, getUsers, userID, byID, byName, byLastName, onSelectCallback = () => {} }) => (
  <InputGroup title="Usuario">
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <AsyncSelect
          name="id"
          label="Buscar por ID"
          placeholder="ID"
          setUserID={setUserID}
          getUsers={getUsers}
          userID={userID}
          search={byID}
          onSelectCallback={onSelectCallback}
        />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <AsyncSelect
          name="name"
          label="Buscar por Nombre"
          placeholder="Nombre"
          setUserID={setUserID}
          getUsers={getUsers}
          userID={userID}
          search={byName}
          onSelectCallback={onSelectCallback}
        />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <AsyncSelect
          name="last_name"
          label="Buscar por Apellido"
          placeholder="Apellido"
          setUserID={setUserID}
          getUsers={getUsers}
          userID={userID}
          search={byLastName}
          onSelectCallback={onSelectCallback}
        />
      </Col>
    </Row>
  </InputGroup>
)

UserSearch.propTypes = {
  setUserID: func.isRequired,
  getUsers: func.isRequired,
  onSelectCallback: func,
  userID: number.isRequired,
  byID: shape(),
  byName: shape(),
  byLastName: shape()
}

export default UserSearch
