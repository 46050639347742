import { combineReducers } from 'redux'
import ProductsTableReducer from './productTable'
import deleteProductReducer from './deleteModal'
import attributeModalReducer from './attributeModal'

export default combineReducers({
  table: ProductsTableReducer,
  deleteModal: deleteProductReducer,
  attributeModal: attributeModalReducer
})
