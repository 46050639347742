/* eslint-disable camelcase */
import { CamelCasedPropertiesDeep } from 'type-fest'

type APIProductByCountry = {
  guid: string
  product_id: number
  price: number
  name: string
  packaging_cost: number
  disassembly_cost: number
  cost_per_floor: number
  unpackaging_cost: number
  assembly_cost: number
  deposit_cost_per_floor_in_cents: number
  ref_id: number
  eligible_for_sale: boolean
  max_amount_avowed_in_cents: number
  percentage_avowed: number
  max_refund_amount_in_cents: number
  eligible_for_donation: boolean
  eligible_for_recycled: boolean
  eligible_for_rent: boolean
  automatic_deposit_cost_per_floor: number
  automatic_cost_per_floor: number
  country_id: string
  photo: {
    key: string
    url: string
  }
}

type APIServiceType = {
  id: string
  operation_type: string
  type: string
  multiplier: number
  display_order: number
}

type APICategory = {
  id: number
  description: string
}

export interface APINewProductPrice {
  guid: string
  product_id: number
  price: number
  name: string
  packaging_cost: number
  disassembly_cost: number
  cost_per_floor: number
  unpackaging_cost: number
  assembly_cost: number
  deposit_cost_per_floor_in_cents: number
  ref_id: number
  eligible_for_sale: boolean
}

export interface APIProduct {
  id: number
  guid: string
  weight_in_gr: number
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  multiplier: number
  products_by_country: APIProductByCountry[]
  categories: APICategory[]
  min_volume_deviation: number
  max_volume_deviation: number
  volume_in_cm3: number
  deprecated: boolean
  packaging_time_in_minutes: number
  unpackaging_time_in_minutes: number
  show_in_search: boolean
  photo_min: number
  serviceType: {
    type: string
    solutionId: number
  }
}

export type Product = CamelCasedPropertiesDeep<APIProduct>
export type FormattedProduct = {
  id: number
  deprecated: boolean
  description: string
  minVolumeDeviation: number
  maxVolumeDeviation: number
  multiplier: number
  weightInGr: number
  widthInCm: number
  heightInCm: number
  lengthInCm: number
  volumeInCm3: number
  assemblyCostInCents: number
  packagingCostInCents: number
  packagingTimeInMinutes: number
  floorsByStairsCostInCents: number
  disassemblyCost: number
  removalPackagingCostInCents: number
  guid: string
  price: number
  unPackagingTimeInMinutes: number
  depositFloorsByStairsCostInCents: number
  photoMin: number
  photo: {
    key: string
    url: string
  }
  priceRecalculate: number
  category: APICategory
  showInSearch: boolean
  maxAmountAvowedInCents: number
  maxRefundAmountInCents: number
  percentageAvowed: number
  solutionId?: number
}

export type NewProductPrice = CamelCasedPropertiesDeep<APINewProductPrice>

export const formatProducts = (products: Product[]): FormattedProduct[] =>
  products.map((product) => ({
    id: product.id,
    deprecated: product.deprecated,
    description: product.productsByCountry[0].name,
    minVolumeDeviation: product.minVolumeDeviation,
    maxVolumeDeviation: product.maxVolumeDeviation,
    multiplier: product.multiplier,
    weightInGr: product.weightInGr,
    widthInCm: product.widthInCm,
    heightInCm: product.heightInCm,
    lengthInCm: product.lengthInCm,
    volumeInCm3: product.volumeInCm3,
    assemblyCostInCents: product.productsByCountry[0].assemblyCost,
    packagingCostInCents: product.productsByCountry[0].packagingCost,
    packagingTimeInMinutes: product.packagingTimeInMinutes,
    floorsByStairsCostInCents: product.productsByCountry[0].costPerFloor,
    disassemblyCost: product.productsByCountry[0].disassemblyCost,
    removalPackagingCostInCents: product.productsByCountry[0].unpackagingCost,
    guid: product.guid,
    price: product.productsByCountry[0].price,
    unPackagingTimeInMinutes: product.unpackagingTimeInMinutes,
    depositFloorsByStairsCostInCents: product.productsByCountry[0].depositCostPerFloorInCents,
    photo: {
      key: product.productsByCountry[0]?.photo?.key,
      url: product.productsByCountry[0]?.photo?.url
    },
    photoMin: product.photoMin,
    priceRecalculate: product.productsByCountry[0].price,
    category: product.categories[0],
    showInSearch: product.showInSearch,
    maxAmountAvowedInCents: product.productsByCountry[0].maxAmountAvowedInCents,
    maxRefundAmountInCents: product.productsByCountry[0].maxRefundAmountInCents,
    percentageAvowed: product.productsByCountry[0].percentageAvowed,
    solutionId: product.serviceType.solutionId || 0
  }))

export const formatProductsToProductPrice = (products: Product[]): NewProductPrice[] => {
  return products.map((product) => ({
    guid: product.guid,
    productId: product.id,
    price: product.productsByCountry[0].price,
    name: product.productsByCountry[0].name,
    packagingCost: product.productsByCountry[0].packagingCost,
    disassemblyCost: product.productsByCountry[0].disassemblyCost,
    costPerFloor: product.productsByCountry[0].costPerFloor,
    unpackagingCost: product.productsByCountry[0].unpackagingCost,
    assemblyCost: product.productsByCountry[0].assemblyCost,
    depositCostPerFloorInCents: product.productsByCountry[0].depositCostPerFloorInCents,
    refId: product.productsByCountry[0].refId,
    eligibleForSale: product.productsByCountry[0].eligibleForSale
  }))
}
