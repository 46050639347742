import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Select from '../../components/Select/Select'
import { Col, Input, Row } from 'antd'
import { getOptionValue } from '../../common/operations/utils'
import CreditCardComponent from './Card/CreditCardComponent'
import MaskedInput from 'antd-mask-input'
import DNICard from './DNICard'
import { IdentificationType, getIdentificationTypes } from '../../services/mercado-pago'
import styles from './NewCard.module.scss'
import clsx from 'clsx'
import { keysToCamel } from '../../utils/snakeCaseToCamel'
import { CountryIdCode } from '../CountrySelector/constants/constants'

export enum CardField {
  DOCUMENT = 'document',
  HOLDER = 'holder',
  EXPIRY = 'expiry',
  CVC = 'cvc',
  NUMBER = 'number'
}

export type InputData = {
  value: string
  error: boolean
  errorMessage: string
}

export enum DocumentType {
  NUMBER = 'number',
  TEXT = 'text'
}

export type IdentificationTypeToCamel = {
  id: string
  name: string
  type: DocumentType
  minLength: number
  maxLength: number
}

interface NewCardProps {
  country: string
  errorSaveCard?: boolean
  cardNumber: InputData | string
  expirationDate: InputData | string
  CVC: InputData | string
  cardOwnerName: InputData | string
  documentType: string
  documentNumber: InputData | string
  setCardNumber: (cardNumber: string) => void
  setExpirationDate: (expirationDate: string) => void
  setCVC: (CVC: string) => void
  setCardOwnerName: (ownerName: string) => void
  setDocumentType: (documentType: string) => void
  setDocumentNumber: (
    documentNumber: string,
    rules?: Pick<IdentificationTypeToCamel, 'minLength' | 'maxLength' | 'type'> | null
  ) => void
}

export const InputTitle: React.FC<{ title: string; error?: boolean }> = ({ title, error }) => {
  return <h3 className={clsx(styles.inputTitle, error && styles.inputError)}>{title}</h3>
}

export const InputError: React.FC<{ error: boolean; errorMessage?: string }> = ({ error, errorMessage }) => {
  if (error) return <p className={clsx(styles.inputError)}>{errorMessage}</p>
  return <></>
}

const amexRegex = /^3[47]/
const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/

const useCreditCardMask = (value: string): string => {
  return useMemo(() => {
    if (amexRegex.test(value) || dinersRegex.test(value)) return '1111 111111 11111'
    return '1111 1111 1111 1111'
  }, [value])
}

const useCVCMask = (value: string): string => {
  return useMemo(() => {
    if (amexRegex.test(value)) return '1111'
    return '111'
  }, [value])
}

const identificationTypesOptions = [
  { value: 'DNI', label: 'DNI' },
  { value: 'CI', label: 'Cédula' },
  { value: 'LC', label: 'L.C.' },
  { value: 'LE', label: 'L.E.' },
  { value: 'Otro', label: 'Otro' }
]

const NewCard = ({
  country,
  errorSaveCard,
  cardNumber,
  expirationDate,
  CVC,
  cardOwnerName,
  documentType,
  documentNumber,
  setCardNumber,
  setExpirationDate,
  setCVC,
  setCardOwnerName,
  setDocumentType,
  setDocumentNumber
}: NewCardProps) => {
  const [identificationTypes, setIdentificationTypes] = useState<IdentificationType[]>([])
  const [identificationTypeRules, setIdentificationTypeRules] = useState<Pick<
    IdentificationTypeToCamel,
    'minLength' | 'maxLength' | 'type'
  > | null>(null)

  useEffect(() => {
    const getIdentificationTypesWrapper = async () => {
      const identificationTypes = await getIdentificationTypes()
      setIdentificationTypes(identificationTypes)
    }
    const getIdentificationTypeRules = () => {
      const docType = identificationTypes.find((identificationType) => identificationType.id === documentType)
      if (!docType) return setIdentificationTypeRules(null)
      const docTypeToCamel: IdentificationTypeToCamel = keysToCamel(docType)
      const { minLength, maxLength, type: inputType } = docTypeToCamel

      setIdentificationTypeRules({ minLength, maxLength, type: inputType })
    }
    getIdentificationTypesWrapper().catch((e) => console.error(e))
    getIdentificationTypeRules()
  }, [documentType])

  const [focus, setFocus] = React.useState('number')

  const cvcMask = useCVCMask(typeof cardNumber === 'object' ? cardNumber.value : cardNumber)
  const numberMask = useCreditCardMask(typeof cardNumber === 'object' ? cardNumber.value : cardNumber)
  const onFocusChange = (e: React.FocusEvent<HTMLInputElement>) => setFocus(e.target.name)

  const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => setCardNumber(e.target.value)
  const onExpirationDateChange = (e: ChangeEvent<HTMLInputElement>) => setExpirationDate(e.target.value)
  const onCVCChange = (e: ChangeEvent<HTMLInputElement>) => setCVC(e.target.value)
  const onDocumentChange = (e: ChangeEvent<HTMLInputElement>) =>
    setDocumentNumber(e.target.value, identificationTypeRules)

  const options = useMemo(() => {
    if (identificationTypes.length === 0) return identificationTypesOptions
    return identificationTypes.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [identificationTypes])

  const identificationType = identificationTypes.find((identificationType) => identificationType.id === documentType)

  return (
    <Row className={styles.containerNewCard} gutter={[16, 16]}>
      <Col className={styles.containerData} span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputTitle error={typeof cardNumber === 'object' && cardNumber.error} title="Número de tarjeta" />
            <MaskedInput
              className={clsx(
                styles.maskedInput,
                typeof cardNumber === 'object' && cardNumber.error ? styles.maskedInputError : ''
              )}
              onFocus={onFocusChange}
              name="number"
              type="text"
              placeholder="Número de tarjeta"
              placeholderChar={' '}
              onChange={onNumberChange}
              mask={numberMask}
            />
            <InputError
              error={focus !== 'number' && typeof cardNumber === 'object' && cardNumber.error}
              errorMessage={(typeof cardNumber === 'object' && cardNumber.errorMessage) || ''}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputTitle error={typeof cardOwnerName === 'object' && cardOwnerName.error} title="Nombre del titular" />
            <Input
              className={typeof cardOwnerName === 'object' && cardOwnerName.error ? styles.inputError : ''}
              type="text"
              name="name"
              placeholder="Nombre"
              value={typeof cardOwnerName === 'object' ? cardOwnerName.value : cardOwnerName}
              onFocus={onFocusChange}
              onChange={(e) => setCardOwnerName(e.target.value)}
            />
            <InputError
              error={focus !== 'name' && typeof cardOwnerName === 'object' && cardOwnerName.error}
              errorMessage={(typeof cardOwnerName === 'object' && cardOwnerName.errorMessage) || ''}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputTitle
              error={typeof expirationDate === 'object' && expirationDate.error}
              title="Fecha de vencimiento"
            />
            <MaskedInput
              onFocus={onFocusChange}
              className={clsx(
                styles.maskedInput,
                typeof expirationDate === 'object' && expirationDate.error ? styles.maskedInputError : ''
              )}
              name="expirationDate"
              type="text"
              placeholder="MM/AA"
              onChange={onExpirationDateChange}
              placeholderChar={' '}
              mask="11/11"
            />
            <InputError
              error={focus !== 'expirationDate' && typeof expirationDate === 'object' && expirationDate.error}
              errorMessage={(typeof expirationDate === 'object' && expirationDate.errorMessage) || ''}
            />
          </Col>
          <Col span={12}>
            <InputTitle error={typeof CVC === 'object' && CVC.error} title="Código de seguridad" />
            <MaskedInput
              onFocus={onFocusChange}
              className={clsx(styles.maskedInput, typeof CVC === 'object' && CVC.error ? styles.maskedInputError : '')}
              name="cvc"
              type="text"
              placeholder={cvcMask}
              onChange={onCVCChange}
              placeholderChar={' '}
              mask={cvcMask}
            />
            <InputError
              error={focus !== 'cvc' && typeof CVC === 'object' && CVC.error}
              errorMessage={(typeof CVC === 'object' && CVC.errorMessage) || ''}
            />
          </Col>
        </Row>
        {country === CountryIdCode.ARGENTINA && (
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <InputTitle title="Tipo" />
              <Select
                placeholder="Tipo"
                selected={options.find((x) => x.value === documentType)}
                onSelect={(option) => setDocumentType(getOptionValue(option))}
                options={options}
              />
            </Col>
            <Col span={16}>
              <InputTitle title="Número de documento" />
              <Input
                style={{ height: '36px' }}
                className={typeof documentNumber === 'object' && documentNumber.error ? styles.inputError : ''}
                name="dni"
                onFocus={onFocusChange}
                type="text"
                maxLength={identificationType?.max_length}
                placeholder="Numero"
                value={typeof documentNumber === 'object' ? documentNumber.value : documentNumber}
                onChange={onDocumentChange}
              />
              <InputError
                error={focus !== 'dni' && typeof documentNumber === 'object' && documentNumber.error}
                errorMessage={(typeof documentNumber === 'object' && documentNumber.errorMessage) || ''}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>{errorSaveCard && <p>{errorSaveCard}</p>}</Col>
        </Row>
      </Col>
      <Col className={styles.containerData} span={12}>
        {focus === 'dni' ? (
          <DNICard
            name={typeof cardOwnerName === 'object' ? cardOwnerName.value : cardOwnerName}
            identificationType={identificationType}
            documentNumber={typeof documentNumber === 'object' ? documentNumber.value : documentNumber}
          />
        ) : (
          <CreditCardComponent
            cvc={typeof CVC === 'object' ? CVC.value : CVC}
            expiry={typeof expirationDate === 'object' ? expirationDate.value : expirationDate}
            name={typeof cardOwnerName === 'object' ? cardOwnerName.value : cardOwnerName}
            number={typeof cardNumber === 'object' ? cardNumber.value : cardNumber}
            focused={focus}
            locale={{
              valid: ''
            }}
            placeholders={{
              name: 'Nombre y Apellido'
            }}
          />
        )}
      </Col>
    </Row>
  )
}

export default NewCard
