import { Moment } from 'moment'
import { BaseOption } from '../../../components/Select/Select'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  UserExclusionTypes,
  GetUserExclusionReasonsRequest,
  GetUserExclusionReasonsSuccess,
  SetPeriodTo,
  SetSelectedOption,
  SetModalOpen,
  SetSelectedReason,
  UserExclusionRequest,
  UserExclusionSuccess,
  EnableUserBillingRequestTypes,
  SetEnableModalOpen,
  SetSpecificPeriodFrom,
  SetSpecificPeriodTo
} from '../types/userExclusionModals'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { UserResponse } from '../../../projectApi/TransactionHandler/BillingData/list'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { CURRENT_PERIOD } from '../reducers/newBillingTable'

const UserExclusionActionCreator = {
  getUserExclusionReasons: (): AppThunk => async (dispatch) => {
    const request: GetUserExclusionReasonsRequest = {
      type: UserExclusionTypes.GET_USER_EXCLUSION_REASONS_REQUEST
    }

    dispatch(request)

    try {
      const reasons = await API.AccountManager.userExclusionReasons()
      const success: GetUserExclusionReasonsSuccess = {
        type: UserExclusionTypes.GET_USER_EXCLUSION_REASONS_SUCCESS,
        payload: {
          reasons
        }
      }
      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, UserExclusionTypes.GET_USER_EXCLUSION_REASONS_FAILURE, dispatch)
    }
  },
  setModalOpen: (open: boolean, user?: UserResponse): SetModalOpen => ({
    type: UserExclusionTypes.SET_MODAL_OPEN,
    payload: {
      open,
      user
    }
  }),
  setEnableModalOpen: (openEnableModal: boolean, user?: UserResponse): SetEnableModalOpen => ({
    type: UserExclusionTypes.SET_ENABLE_MODAL_OPEN,
    payload: {
      openEnableModal,
      user
    }
  }),
  setSelectedOption: (option: number): SetSelectedOption => ({
    type: UserExclusionTypes.SET_SELECTED_OPTION,
    payload: {
      option
    }
  }),
  setPeriodTo: (periodTo: Moment): SetPeriodTo => ({
    type: UserExclusionTypes.SET_PERIOD_TO,
    payload: {
      periodTo
    }
  }),
  setSpecificPeriodFrom: (specificPeriodFrom: Moment): SetSpecificPeriodFrom => ({
    type: UserExclusionTypes.SET_SPECIFIC_PERIOD_FROM,
    payload: {
      specificPeriodFrom
    }
  }),
  setSpecificPeriodTo: (specificPeriodTo: Moment): SetSpecificPeriodTo => ({
    type: UserExclusionTypes.SET_SPECIFIC_PERIOD_TO,
    payload: {
      specificPeriodTo
    }
  }),
  setSelectedReason: (reason: BaseOption<number>): SetSelectedReason => ({
    type: UserExclusionTypes.SET_SELECTED_REASON,
    payload: {
      reason
    }
  }),

  userExclusion:
    (userId: number, reasonId: number, periodFrom: Moment, periodTo?: Moment, withDays = false): AppThunk =>
    async (dispatch) => {
      const request: UserExclusionRequest = {
        type: UserExclusionTypes.USER_EXCLUSION_REQUEST
      }
      dispatch(request)
      const toastId = setToastLoading('Excluyendo usuario...')

      try {
        await API.TransactionHandler.UserBillingInformation.exclude({
          withDays,
          userId,
          reasonId,
          periodFrom,
          periodTo,
          enabled: false
        })

        setToastSuccessUpdate(toastId, { render: 'Se excluyó correctamente el usuario' })
        Emitter.emit(Events.Billing.USER_EXCLUSION)
        const success: UserExclusionSuccess = {
          type: UserExclusionTypes.USER_EXCLUSION_SUCCESS
        }
        dispatch(success)
      } catch (err) {
        setToastErrorUpdate(toastId, {
          render: 'Hubo un error al excluír el usuario'
        })
        handleErrorsWithAction(err, UserExclusionTypes.USER_EXCLUSION_FAILURE, dispatch)
      }
    },

  enableUser:
    ({ userId }: { userId: number }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Habilitando facturación del cliente, por favor espere...')

      dispatch({
        type: EnableUserBillingRequestTypes.ENABLE_USER_BILLING_REQUEST
      })
      try {
        await API.TransactionHandler.UserBillingInformation.exclude({
          userId,
          enabled: true,
          periodFrom: CURRENT_PERIOD
        })

        setToastSuccessUpdate(toastId, { render: 'La facturación del usuario se habilitó correctamente.' })
        Emitter.emit(Events.Billing.USER_BILLING_ENABLE)
        dispatch({ type: EnableUserBillingRequestTypes.ENABLE_USER_BILLING_SUCCESS })
      } catch (error) {
        const messageError = handleErrorsWithAction(
          error,
          EnableUserBillingRequestTypes.ENABLE_USER_BILLING_FAILURE,
          dispatch
        )

        setToastErrorUpdate(toastId, {
          render: messageError ?? 'Error al intentar habilitar la facturación del cliente.'
        })
      }
    }
}

export default UserExclusionActionCreator
