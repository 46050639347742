import React from 'react'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1.5rem'
  }
}

const ActionBar = ({ containerStyles = {}, children }) => {
  return (
    <div style={{ ...styles.container, ...containerStyles }} className="animated fadeIn">
      {children}
    </div>
  )
}

export default ActionBar
