import { Removal } from '../../../../projectApi/TransactionHandler/Operation/Removals/common'

export type Reason = {
  reason: string
  id: number
}

export type ReasonOption = {
  label: string
  value: number
}

export const OPEN_MODAL = 'REMOVALS/CANCEL/OPEN_MODAL'
export const CLOSE_MODAL = 'REMOVALS/CANCEL/CLOSE_MODAL'
export const CANCEL_REQUEST = 'REMOVALS/CANCEL/CANCEL_REQUEST'
export const CANCEL_SUCCESS = 'REMOVALS/CANCEL/CANCEL_SUCCESS'
export const CANCEL_FAILURE = 'REMOVALS/CANCEL/CANCEL_FAILURE'
export const SET_REASON = 'REMOVALS/CANCEL/SET_REASON'
export const GET_REASONS_REQUEST = 'REMOVALS/CANCEL/GET_REASONS_REQUEST'
export const GET_REASONS_SUCCESS = 'REMOVALS/CANCEL/GET_REASONS_SUCCESS'
export const GET_REASONS_FAILURE = 'REMOVALS/CANCEL/GET_REASONS_FAILURE'

export type openModal = {
  type: typeof OPEN_MODAL
  payload: {
    operationId: number
    guid: string
  }
}

export type closeModal = {
  type: typeof CLOSE_MODAL
}

export type setReason = {
  type: typeof SET_REASON
  payload: {
    reason: ReasonOption
  }
}

export type getReasonsRequest = {
  type: typeof GET_REASONS_REQUEST
}

export type getReasonsSuccess = {
  type: typeof GET_REASONS_SUCCESS
  payload: {
    reasons: Reason[] | null
  }
}

export type getReasonsFailure = {
  type: typeof GET_REASONS_FAILURE
  payload: {
    error: string
  }
}

export type cancelRequest = {
  type: typeof CANCEL_REQUEST
}

export type cancelSuccess = {
  type: typeof CANCEL_SUCCESS
  payload: {
    operation: Removal
  }
}

export type cancelFailure = {
  type: typeof CANCEL_FAILURE
  payload: {
    error: string
  }
}

export type cancelActions =
  | openModal
  | closeModal
  | setReason
  | getReasonsRequest
  | getReasonsSuccess
  | getReasonsFailure
  | cancelRequest
  | cancelSuccess
  | cancelFailure
