import { Reducer } from 'redux'
import {
  GET_TRANSACTION_REMITTANCE_FAILURE,
  GET_TRANSACTION_REMITTANCE_REQUEST,
  GET_TRANSACTION_REMITTANCE_SUCCESS,
  RemittanceModalAction,
  RemittanceModalState,
  SET_OPEN
} from './types'

const initialState: RemittanceModalState = {
  open: false,
  remittance: null,
  transactionId: 0,
  loading: false,
  error: ''
}

const RemittanceModalReducer: Reducer<RemittanceModalState, RemittanceModalAction> = (
  state = initialState,
  action
): RemittanceModalState => {
  switch (action.type) {
    case SET_OPEN:
      if (!action.payload.open) return initialState
      return {
        ...state,
        open: action.payload.open,
        transactionId: action.payload.transactionId || initialState.transactionId
      }
    case GET_TRANSACTION_REMITTANCE_REQUEST:
      return {
        ...state,
        transactionId: action.payload.transactionId,
        loading: true
      }
    case GET_TRANSACTION_REMITTANCE_SUCCESS:
      if (action.payload.transactionId !== state.transactionId) {
        return { ...state, loading: false, error: 'Hubo un error buscando la información', remittance: null }
      }

      return { ...state, loading: false, remittance: action.payload.remittance }
    case GET_TRANSACTION_REMITTANCE_FAILURE:
      return { ...state, loading: false, remittance: null, error: action.payload.error }
    default:
      return state
  }
}

export default RemittanceModalReducer
