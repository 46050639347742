import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_CATEGORIES } from '../../../../endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'

export type Category = {
  id: number
  description: string
}

type Response = {
  categories: Category[]
}

type SearchCategoriesProps = {
  name: string
}

export function list({ name }: SearchCategoriesProps): Promise<Response> {
  const params = snakecaseKeys({ name: name || undefined })

  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/search/categories`, { params })
    .then((response: AxiosResponse<Category[]>) => {
      return { categories: camelcaseKeys(response.data, { deep: true }) }
    })
    .catch((error) => {
      throw error
    })
}
