import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import {
  GET_OBJECTS_TABLE_FAILURE,
  GET_OBJECTS_TABLE_REQUEST,
  GET_OBJECTS_TABLE_SUCCESS,
  PhotographObjectState,
  PhotographObjectsTableAction
} from '../types/PhotographObjectsTable'

const initialState: PhotographObjectState = {
  objects: [],
  total: 0,
  pagination: {
    page: 1,
    total: 0,
    pageSize: 20
  },
  loading: false,
  error: '',
  sort: {
    field: 'id',
    direction: SortDirection.DESC
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'identificationCode', text: '' },
    { key: 'product', text: '' }
  ]
}

const PhotographObjectsTableReducer: Reducer<PhotographObjectState, PhotographObjectsTableAction> = (
  state = initialState,
  action
): PhotographObjectState => {
  switch (action.type) {
    case GET_OBJECTS_TABLE_REQUEST:
      return {
        ...state,
        loading: !action.payload.silentLoading,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        sort: action.payload.sort || initialState.sort,
        searchFilters: action.payload.searchFilters || initialState.searchFilters
      }
    case GET_OBJECTS_TABLE_SUCCESS:
      return {
        ...state,
        objects: action.payload.objects,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        },
        loading: false
      }
    case GET_OBJECTS_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default PhotographObjectsTableReducer
