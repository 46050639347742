import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { APIDeposit } from '../common'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import camelcaseKeys from 'camelcase-keys'

export interface APIResponse {
  deposits: APIDeposit[]
  total: number
}

interface ListParams {
  limit: number
  offset: number
  order?: 'asc' | 'desc'
  column?: string
  countryId?: string
  id?: number
  name?: string
  owner?: string
  ownerId?: number
  allDeposits?: boolean
  deprecated?: boolean
  onlySpaceGuru?: boolean
}

export function list({
  countryId,
  ownerId,
  allDeposits,
  onlySpaceGuru,
  ...params
}: ListParams): Promise<CamelCasedPropertiesDeep<APIResponse>> {
  const formattedParams = {
    country_id: countryId ?? apiPrivate.defaults.params?.country_code,
    ownerId,
    order: params.order ?? 'desc',
    column: params.column ?? 'id',
    all: allDeposits || false,
    only_space_guru: onlySpaceGuru || false,
    ...params
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/admin/deposits`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const deposits = response.data.deposits

      return {
        deposits: camelcaseKeys(deposits, { deep: true }) ?? [],
        total: response.data.total
      }
    })
    .catch((error) => {
      throw error
    })
}
