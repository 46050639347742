import { Reducer } from 'redux'
import {
  ProviderListActions,
  GetProviderListTypes,
  ProviderListStateType,
  SetterProviderTypes,
  RESET_FILTERS,
  SendCSVListTypes
} from '../types/ProviderList'
import { SortDirection } from '../../../components/DynamicTable/types/types'

export const PAGE_SIZE = 10

const initialState: ProviderListStateType = {
  fetchingProviders: false,
  providers: [],
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'name', text: '' }
  ],
  filters: {
    id: undefined
  },
  sort: {
    direction: SortDirection.DESC,
    field: 'id'
  },
  loadingCSV: false
}

const ProviderListReducer: Reducer<ProviderListStateType, ProviderListActions> = (
  state: ProviderListStateType = initialState,
  action: ProviderListActions
): ProviderListStateType => {
  switch (action.type) {
    case RESET_FILTERS:
      return initialState
    case GetProviderListTypes.GET_PROVIDER_REQUEST:
      return {
        ...state,
        fetchingProviders: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        sort: action.payload.sort,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GetProviderListTypes.GET_PROVIDER_SUCCESS:
      return {
        ...state,
        fetchingProviders: false,
        providers: action.payload.providers,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetProviderListTypes.GET_PROVIDER_FAILURE:
      return {
        ...state,
        fetchingProviders: false
      }
    case SetterProviderTypes.SET_PROVIDER_FILTERS:
      return {
        ...state,
        filters: {
          id: action.payload.id
        }
      }
    case SendCSVListTypes.SEND_CSV_REQUEST:
      return { ...state, loadingCSV: true }
    case SendCSVListTypes.SEND_CSV_SUCCESS:
    case SendCSVListTypes.SEND_CSV_FAILURE:
      return { ...state, loadingCSV: false }
    default:
      return state
  }
}

export default ProviderListReducer
