import { SortDirection } from '../../../components/DynamicTable/types/types'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  DepositsValidationDateRangeFilter,
  DepositsValidationTableCategoryFilter,
  DepositsValidationTableSearchFilter,
  GET_DEPOSITS_FAILURE,
  GET_DEPOSITS_REQUEST,
  GET_DEPOSITS_SUCCESS,
  GetDepositsRequest,
  GetDepositsSuccess,
  Pagination
} from '../types/table'

const depositsValidationTableActionCreators = {
  getDeposits:
    ({
      pagination,
      searchFilters,
      categoryFilter,
      dateRangeFilters
    }: {
      pagination: Pagination
      searchFilters?: DepositsValidationTableSearchFilter[]
      categoryFilter?: DepositsValidationTableCategoryFilter
      dateRangeFilters?: DepositsValidationDateRangeFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetDepositsRequest = {
        type: GET_DEPOSITS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          categoryFilter,
          dateRangeFilters,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { operations, total } = await API.TransactionHandler.Operation.list({
          Limit: pagination.pageSize,
          Offset: (pagination.page - 1) * pagination.pageSize,
          Column: 'id',
          Order: SortDirection.DESC,
          statuses: categoryFilter?.status,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        const success: GetDepositsSuccess = {
          type: GET_DEPOSITS_SUCCESS,
          payload: {
            deposits: operations,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_DEPOSITS_FAILURE, dispatch)
      }
    }
}

export default depositsValidationTableActionCreators
