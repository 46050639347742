import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APIBilling, Billing, formatBilling } from './common'
import { Moment } from 'moment'

export interface APIResponse {
  results: APIBilling[]
  paging: {
    total: number
    limit: number
    offset: number
  }
}

interface Response {
  billings: Billing[]
  total: number
}

type ListParams = {
  limit?: number
  offset?: number
  periodFrom: Moment
}

export function billingsToBillByPeriod(params: ListParams): Promise<Response> {
  const { limit, offset, periodFrom } = params
  const snakeParams = snakecaseKeys({
    limit,
    offset,
    periodFrom: periodFrom.format('YYYY-MM-DD')
  })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billings/billing-to-bill-by-period`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.results) {
        throw new Error('Respuesta incorrecta al obtener los datos de facturación')
      }

      const camelResponse: Response = {
        total: response.data.paging.total,
        billings: response.data.results.map((billing) => formatBilling(billing))
      }

      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
