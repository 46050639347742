import React, { useEffect } from 'react'
import ProvidersTable from '../components/providersTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import ProviderListActionsCreator from '../actions/ProviderList'
import { ProvidersSearchFilter, ProvidersTableSearchKey, ProvidersTableSort } from '../types/ProviderList'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../components/TableActionBar'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Provider, ProvidersListSortKey } from '../../../projectApi/Trips/Providers/list'
import { Edit, Trash, Truck } from '../../../icons'
import ABMProvidersContainer from './ABMProviders'
import ABMProvidersActionsCreator from '../actions/ABMProviders'
import { useHistory } from 'react-router'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { removeNullishValues, updateMultipleSearchFilters } from '../../../components/DynamicTable/queryParamsUtils'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const queryParams = {
  page: NumberParam,
  sortField: StringParam,
  sortDirection: StringParam,
  id: StringParam,
  name: StringParam
}

const ProvidersTableContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { getProviders, resetFilters, sendCSVProviders } = bindActionCreators(ProviderListActionsCreator, dispatch)
  const { setModalVisibility, setDeleteModalVisibility, setSelectedProvider } = bindActionCreators(
    ABMProvidersActionsCreator,
    dispatch
  )
  const [query, setQuery] = useQueryParams(queryParams)
  const { providers, fetchingProviders, loadingCSV, ...tableState } = useSelector((state) => state.ProviderListReducer)

  const handleGetProviders = (newParams: {
    pagination?: Pagination
    searchFilters?: ProvidersSearchFilter[]
    sort?: ProvidersTableSort
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      sort: newParams.sort || tableState.sort
    }
    getProviders(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      sortDirection: actualParams.sort.direction,
      sortField: actualParams.sort.field
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  useEffect(() => {
    handleGetProviders({
      pagination: tableState.pagination,
      sort:
        query.sortField && query.sortDirection
          ? {
              field: query.sortField as ProvidersListSortKey,
              direction: query.sortDirection as SortDirection
            }
          : tableState.sort,
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: 'id', text: query.id },
        { key: 'name', text: query.name }
      ])
    })

    return () => {
      resetFilters()
    }
  }, [])

  useEvents(
    [
      Events.Global.CHANGE_COUNTRY,
      Events.Provider.CLEAN_FILTERS,
      Events.Provider.CREATE_PROVIDER,
      Events.Provider.EDIT_PROVIDER,
      Events.Provider.DELETE_PROVIDER
    ],
    () =>
      getProviders({
        pagination: { ...tableState.pagination },
        sort: tableState.sort
      })
  )

  const handlePageChange = (newPage: number) => {
    handleGetProviders({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSort = (newSort: ProvidersTableSort) => {
    handleGetProviders({ sort: newSort })
  }

  const handleSearch = (key: ProvidersTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetProviders({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const menuActions: MenuAction<Provider>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (provider) => {
        setModalVisibility({ modalVisible: true, editMode: true })
        setSelectedProvider(provider)
      }
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (provider) => {
        setDeleteModalVisibility(true)
        setSelectedProvider(provider)
      }
    },
    {
      label: 'Ver transportes',
      icon: <Truck />,
      onClick: ({ id }) => {
        history.push(`/vehicles?providerId=${id}`)
      }
    }
  ]

  const onSendCSV = () => sendCSVProviders(tableState)

  const props = {
    fetchingProviders,
    providers,
    searchFilters: tableState.searchFilters,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    handleSort,
    sort: tableState.sort,
    actions: menuActions
  }

  return (
    <>
      <TableActionBar customStyles={{ marginBottom: '16px' }}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction toolTip variant="csv" disabled={loadingCSV} onClickButton={onSendCSV} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={resetFilters} />
        </TableActionBar.Wrapper>

        <TableActionBar.ButtonAction
          titleButton={'Crear proveedor'}
          onClickButton={() => {
            setModalVisibility({ modalVisible: true })
          }}
        />
      </TableActionBar>
      <ProvidersTable {...props} />
      <ABMProvidersContainer />
    </>
  )
}

export default ProvidersTableContainer
