import { Input, Modal } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { Column } from '../../../components/DynamicTable/types/types'
import { TransactionTypeId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { Trip } from '../../../projectApi/Trips/Trip/common'
import { CountryLabel, Driver, TripModalTransaction } from '../types/tripModal'
import Select from '../../../components/Select/Select'
import styles from './tripModal.module.scss'
import { TransactionStatusBadges, TripStatusBadges } from '../constants'
import { formatDate } from '../../../utils/formatDate'
import { DynamicTable } from '../../../components/DynamicTable'
import ColorBadge from '../../../components/ColorBadge'
import { ArrowFromBracket, ArrowToBracket, BagIcon, Cube, Truck } from '../../../icons'
import MetricGroup, { Item } from '../../../components/MetricGroup'
import { OPERATION_TYPES, OperationTypeLabel } from '../../Transactions/constants'
import { formatNumber } from '../../../utils/formatNumber'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { Button } from '../../../components/Button/Button'
import { sendToastNotificationError } from '../../../utils/notifications'
import { OperationType } from '../../../projectApi/TransactionHandler/Transactions/list'

export interface TripModalProps {
  open: boolean
  loadingTransactions: boolean
  loadingDrivers: boolean
  creatingOrUpdating: boolean
  error: string
  transactions: TripModalTransaction[]
  drivers: Driver[]
  selectedDriverId: number
  trip: Trip | null
  extraTransactionsToAdd: TripModalTransaction[]
  ableToAddTransactions: boolean
  searchingExtraTransaction: boolean
  onSelectDriver: (driverId: number) => void
  onFindTransactionById: (id: number) => void
  onClose: () => void
  onConfirm: () => void
}

const TripModal: React.FC<TripModalProps> = ({
  open,
  loadingTransactions,
  loadingDrivers,
  creatingOrUpdating,
  trip,
  error,
  transactions,
  drivers,
  selectedDriverId,
  extraTransactionsToAdd,
  ableToAddTransactions,
  searchingExtraTransaction,
  onFindTransactionById,
  onSelectDriver,
  onConfirm,
  onClose
}) => {
  const editMode = !!trip
  const [txInputValue, setTxInputValue] = React.useState('')

  useEffect(() => {
    if (!open) setTxInputValue('')
  }, [open])

  useEffect(() => {
    setTxInputValue('')
  }, [extraTransactionsToAdd.length])

  const driverOptions = useMemo(() => {
    const noAssignment = { value: 0, label: 'Sin asignar' }
    const options = drivers.map((d) => ({ label: `${d.userId} - ${d.name} ${d.lastName}`, value: d.userId }))

    if (editMode) return options
    return [noAssignment, ...options]
  }, [drivers, editMode])

  const handleAddTX = () => {
    const foundInExistingTransactions = transactions.find((tx) => tx.id === parseInt(txInputValue))
    const foundInTransactionsToAdd = extraTransactionsToAdd.find((tx) => tx.id === parseInt(txInputValue))

    if (foundInExistingTransactions) {
      sendToastNotificationError('La transacción ya está en el viaje')
      return
    }
    if (foundInTransactionsToAdd) {
      sendToastNotificationError('Ya añadiste esta transacción')
      return
    }
    onFindTransactionById(parseInt(txInputValue))
  }

  return (
    <Modal
      width={'80%'}
      centered
      destroyOnClose
      closable={true}
      visible={open}
      title={editMode ? 'Editar viaje' : 'Crear viaje'}
      style={{ maxWidth: 1000 }}
      maskClosable={!creatingOrUpdating}
      onCancel={onClose}
      footer={[
        <Button label="Cancelar" key="back" mode="outlined" onClick={onClose} disabled={creatingOrUpdating} />,
        <Button
          key="submit"
          loading={creatingOrUpdating}
          onClick={onConfirm}
          disabled={
            loadingTransactions ||
            loadingDrivers ||
            searchingExtraTransaction ||
            (extraTransactionsToAdd.length === 0 && trip?.driver.userId === selectedDriverId)
          }
          label={trip?.id ? 'Editar viaje' : 'Crear viaje'}
        />
      ]}>
      <div>{trip && <TripCard trip={trip} />}</div>
      <Transactions loading={loadingTransactions} transactions={[...transactions, ...extraTransactionsToAdd]} />
      {ableToAddTransactions && (
        <div className={styles.moreTransactions}>
          <span>Añadir transacciones:</span>
          <Input
            placeholder="Buscar por ID de TX"
            style={{ flexGrow: 0 }}
            disabled={searchingExtraTransaction || creatingOrUpdating}
            type="number"
            value={txInputValue}
            onChange={(e) => setTxInputValue(e.target.value)}
          />
          <Button
            label="Agregar"
            compact
            loading={searchingExtraTransaction}
            onClick={handleAddTX}
            disabled={creatingOrUpdating}
          />
        </div>
      )}
      <span>Transportista</span>
      <Select
        options={driverOptions}
        onSelect={(option) => {
          // @ts-ignore
          if (!option?.value) return
          // @ts-ignore
          onSelectDriver(option.value)
        }}
        disabled={loadingDrivers || creatingOrUpdating}
        selected={driverOptions.find((x) => x.value === selectedDriverId)}
        loading={loadingDrivers}
        placeholder="Seleccionar Conductor"
        multiple={false}
      />
      {error && <div style={{ color: 'red', paddingTop: 20 }}>Error: {error}</div>}
    </Modal>
  )
}

export const TripCard: React.FC<{ trip: Trip }> = ({ trip }) => {
  const statusBadge = TripStatusBadges.find((tsb) => tsb.value === trip.status.id)
  if (!statusBadge) return null
  return (
    <div
      className={styles.tripCard}
      style={{ background: statusBadge.styleProps.backgroundColor, color: statusBadge.styleProps.borderColor }}>
      <div className={styles.hero}>
        <Truck className={styles.truckIcon} />
        <div className={styles.tripInfo}>
          <span>Viaje #{trip.id}</span>
          <span>{statusBadge.text}</span>
        </div>
      </div>
      <div className={styles.infoItems}>
        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>Creación:</span>
          {formatDate(trip.createdAt)}
        </div>
        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>Inicio programado:</span>
          {formatDate(trip.scheduledStartDate)}
        </div>
        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>Fin programado:</span>
          {formatDate(trip.scheduledEndDate)}
        </div>
        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>Previsión:</span>
          {trip.forecast.objects} Obj.
          <br />{' '}
        </div>
      </div>
    </div>
  )
}

export default TripModal

const formatCountry = (country: CountryIdCode) => {
  switch (country) {
    case CountryIdCode.ARGENTINA:
      return CountryLabel.ARG
    case CountryIdCode.SPAIN:
      return CountryLabel.ESP
    default:
      return CountryIdCode.ALL
  }
}

export const Transactions: React.FC<{
  transactions: TripModalTransaction[]
  loading: boolean
}> = ({ transactions, loading }) => {
  const columns: Column<TripModalTransaction>[] = [
    {
      key: 'id',
      label: 'ID TX'
    },
    {
      key: 'ID OP',
      label: 'ID OP',
      minWidth: 'max-content',
      renderDataCell: ({ operation }) => {
        if (!operation) return '-'
        const found = OPERATION_TYPES.find(
          (of) => of.value === operation.coreType || of.value === operation.operationCoreType
        )
        const opId = operation.id
        return `${found?.text}-${opId} `
      }
    },
    {
      key: 'reservationDate',
      label: 'Servicio',
      maxWidth: '120px',
      alignment: 'left',
      renderDataCell: ({ reservationDate }) => formatDate(reservationDate)
    },
    {
      key: 'client',
      label: 'Cliente',
      minWidth: '130px',
      renderDataCell: ({ client, operation }) => {
        let countryData = CountryIdCode.ALL
        if (operation) countryData = operation.countryId
        return (
          <div>
            {`[${formatCountry(countryData)}] `}
            <ClientIDLinkComponent id={client.id} noPadding={true}>
              <div>{client.id}</div>
            </ClientIDLinkComponent>
            {` ${client.name} ${client.lastName}`}
          </div>
        )
      }
    },
    {
      key: 'transactionType',
      label: 'Tipo TX',
      renderDataCell: ({ transactionType }) => {
        switch (transactionType) {
          case TransactionTypeId.DELIVERY_DEPOSITO:
            return 'Delivery depósito'
          case TransactionTypeId.PICKUP_DEPOSITO:
            return 'Pickup depósito'
        }
      }
    },
    {
      key: 'destinationAddress',
      label: 'Destino',
      minWidth: '200px',
      alignment: 'left'
    },
    {
      key: 'transactionStatusId',
      label: 'Estado',
      maxWidth: 'min-content',
      renderDataCell: ({ statusId }) => {
        const found = TransactionStatusBadges.find((tsb) => tsb.value === statusId)
        if (!found) return '-'
        const props = {
          ...found.styleProps
        }
        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    }
  ]

  const summaryData: SummaryData = getSummaryData(transactions)

  return (
    <div style={{ padding: '12px 0', maxHeight: 500 }}>
      <MetricGroup style={{ marginBottom: 20 }}>
        <Item
          icon={<Cube />}
          labelBold="OBJ:"
          labelRegular={`${summaryData.objects.count ?? '-'}`}
          details={`(${formatNumber(summaryData.objects.m3)} m3)`}
        />
        <Item
          icon={<ArrowToBracket />}
          labelBold={`${OperationTypeLabel.DEPOSIT}:`}
          labelRegular={summaryData.deposits.count || '-'}
          details={summaryData.deposits.m3 ? `(${formatNumber(summaryData.deposits.m3)} m3)` : ''}
        />
        <Item
          icon={<ArrowFromBracket />}
          labelBold={`${OperationTypeLabel.REMOVAL}:`}
          labelRegular={summaryData.removals.count || '-'}
          details={summaryData.removals.m3 ? `(${formatNumber(summaryData.removals.m3)} m3)` : ''}
        />
        <Item icon={<BagIcon />} labelBold={`VENT:`} labelRegular={'-'} />
      </MetricGroup>
      <DynamicTable
        columns={columns}
        loading={loading}
        rows={transactions}
        keyExtractor={(entity) => entity.id}
        customClassNames={{
          table: styles.transactionsTable,
          loadingContainer: styles.loadingContainer
        }}
      />
    </div>
  )
}

type SummaryData = {
  deposits: { m3: number; count: number }
  removals: { m3: number; count: number }
  objects: { m3: number; count: number }
}

const getSummaryData = (transactions: TripModalTransaction[]): SummaryData => {
  const { deposits, removals, objects } = transactions.reduce(
    (acc, tx) => {
      if (tx.operation.coreType === OperationType.DEPOSIT || tx.operation.operationCoreType === OperationType.DEPOSIT) {
        acc.deposits.m3 += tx.contents.m3
        acc.deposits.count += tx.contents.itemsOrObjects
      }
      if (tx.operation.coreType === OperationType.REMOVAL || tx.operation.operationCoreType === OperationType.REMOVAL) {
        acc.removals.m3 += tx.contents.m3
        acc.removals.count += tx.contents.itemsOrObjects
      }
      acc.objects.m3 += tx.contents.m3
      acc.objects.count += tx.contents.itemsOrObjects
      return acc
    },
    { deposits: { m3: 0, count: 0 }, removals: { m3: 0, count: 0 }, objects: { m3: 0, count: 0 } }
  )

  return { deposits, removals, objects }
}
