import { Tooltip, Button } from 'antd'
import React from 'react'
import ImgWithCarousel from '../../../components/CarouselModal/components/ImgWithCarousel'
import ColorBadge from '../../../components/ColorBadge'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { parseMomentDate } from '../../../components/DynamicTable/utils/utils'
import { Camera, QRIcon } from '../../../icons'
import { SalesRequest } from '../../../projectApi/Sales/SalesRequest/list'
import { formatMoney, formatNumber } from '../../../utils/formatNumber'
import notAvailableImage from '../../Objects/images/notAvailableImage.jpg'
import { STATUS_OPTIONS } from '../utils'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import {
  SalesRequestCategoryFilter,
  SalesRequestDateRangeFilter,
  SalesRequestSearchFilter,
  SalesRequestSearchKey,
  SalesRequestTableDateRangeKey
} from '../types/salesRequestTable'
import { getFilterProps, getRangePickerProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { Moment } from 'moment'
import { SaleStatusOptions } from '../../../projectApi/Sales/status'
import { OBJECT_SITUATION } from '../../../projectApi/ObjectAdministration/common'
import { truncateText } from '../../../utils/truncateText'
import { TableActionBar } from '../../../components/TableActionBar'
import styles from './SalesRequestTable.module.scss'
import { useHistory } from 'react-router'
import { MenuAction } from '../../../components/actionMenu/baseMenu'

export type SalesRequestTableProps = {
  downloadingCSV: boolean
  pagination: Pagination
  loading: boolean
  showDemoUsers: boolean
  salesRequest: SalesRequest[]
  searchFilters: SalesRequestSearchFilter[]
  categoryFilter: SalesRequestCategoryFilter
  dateRangeFilters: SalesRequestDateRangeFilter[]
  statusOptions: SaleStatusOptions[]
  handleDateRangePiker: (key: SalesRequestTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleResetFilters: () => void
  handleOpenQRCodeModal: (code: string) => void
  handleSearch: (key: SalesRequestSearchKey, newValue: string) => void
  handleCategoryFilter: (newCategoryFilter: SalesRequestCategoryFilter) => void
  handleShowDemoUsers: (showDemoUsers: boolean) => void
  onCSVDownload: () => void
  onGoRequestTable: () => void
  actions: MenuAction<SalesRequest>[]
}

export const SalesRequestTable: React.FC<SalesRequestTableProps> = ({
  downloadingCSV,
  salesRequest,
  searchFilters,
  categoryFilter,
  dateRangeFilters,
  loading,
  pagination,
  showDemoUsers,
  statusOptions,
  onCSVDownload,
  onGoRequestTable,
  handleOpenQRCodeModal,
  handleSearch,
  handleCategoryFilter,
  handleResetFilters,
  handleDateRangePiker,
  handleShowDemoUsers,
  actions
}) => {
  const formattedStatus = statusOptions.map((s) => ({ value: s.id, text: s.name }))
  const history = useHistory()
  const columns: Column<SalesRequest>[] = [
    {
      key: 'id',
      label: 'ID',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'user',
      label: 'Cliente',
      noPadding: true,
      search: getSearchProps('userId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ user }) => (
        <ClientIDLinkComponent id={user.id} noPadding={true}>
          <div>
            {user.id}-{user.name} {user.lastName}
          </div>
        </ClientIDLinkComponent>
      )
    },
    {
      key: 'identificationCode',
      label: 'Etiqueta',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ object }) => {
        const { identificationCode } = object
        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenQRCodeModal(identificationCode)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
            {identificationCode}
          </div>
        )
      }
    },
    {
      key: 'photo',
      label: <Camera />,
      renderDataCell: ({ object }) => {
        if (!object.photoUrl) {
          return <img src={notAvailableImage} alt="-" style={{ objectFit: 'cover', width: '40px', height: '42px' }} />
        }

        return <ImgWithCarousel carouselImages={[object.photoUrl]} alt={'-'} />
      }
    },
    {
      key: 'objects',
      label: 'Objetos',
      alignment: 'left',
      minWidth: 'max-content',
      renderDataCell: ({ object }) => {
        const { widthInCm, heightInCm, lengthInCm, product, description } = object
        const totalM3 = (lengthInCm * widthInCm * heightInCm) / 1_000_000

        return (
          <Button
            type="link"
            style={{
              height: 'auto',
              maxHeight: '90px'
            }}
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/objects?id=${object.id}`)
            }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: '12px', height: '60px' }}>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{truncateText(product.name, 30)}</span>
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{truncateText(description, 30)}</span>
              <span>
                {widthInCm}x{heightInCm}x{lengthInCm}(cm)= {formatNumber(totalM3)} m3
              </span>
              <span>Coef. Multip x{product.multiplier}</span>
            </div>
          </Button>
        )
      }
    },
    {
      key: 'createdAt',
      label: 'Creación',
      alignment: 'left',
      rangePicker: getRangePickerProps(
        SalesRequestTableDateRangeKey.CREATED_AT,
        dateRangeFilters,
        handleDateRangePiker
      ),
      renderDataCell: ({ createdAt }) => parseMomentDate(createdAt, 'left')
    },
    {
      key: 'updatedAt',
      label: 'Ult. interacción',
      rangePicker: getRangePickerProps(
        SalesRequestTableDateRangeKey.UPDATED_AT,
        dateRangeFilters,
        handleDateRangePiker
      ),
      renderDataCell: ({ updatedAt }) => parseMomentDate(updatedAt, 'left')
    },
    {
      key: 'statusAndSituation',
      label: 'Estado y situación del objeto',
      maxWidth: '200px',
      minWidth: 'min-content',
      alignment: 'left',
      renderDataCell: ({ object }) => (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', whiteSpace: 'nowrap' }}>
          <div>
            <b>{object.status}</b>
          </div>
          {object.objectSituation && <div>{OBJECT_SITUATION[object.objectSituation]}</div>}
        </div>
      )
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      // @ts-ignore
      filters: getFilterProps(formattedStatus, categoryFilter, 'status', handleCategoryFilter),
      renderDataCell: ({ status }) => {
        const found = STATUS_OPTIONS.find((sf) => sf.text === status)

        if (!found) return status

        const props = {
          ...found.styleProps
        }
        return (
          <ColorBadge {...props} extraStyles={{ minWidth: 150 }}>
            {found.text}
          </ColorBadge>
        )
      }
    },
    {
      key: 'pvp',
      label: <Tooltip title="Precio Venta Público">PVP</Tooltip>,
      renderDataCell: ({ pvp }) => {
        if (!pvp) return '-'

        return formatMoney(pvp)
      }
    },
    {
      key: 'prc',
      label: <Tooltip title="Precio que Recibe el Cliente">PRC</Tooltip>,
      renderDataCell: ({ prc }) => {
        if (!prc) return '-'
        return formatMoney(prc)
      }
    },
    {
      key: 'pve',
      label: <Tooltip title="Precio Venta Express">PVE</Tooltip>,
      renderDataCell: ({ pve }) => {
        if (!pve) return '-'

        return formatMoney(pve)
      }
    }
  ]

  return (
    <>
      <TableActionBar className={styles.actionBar}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            variant="secondary"
            iconType="arrow-left"
            titleButton="Tabla de solicitudes"
            onClickButton={onGoRequestTable}
          />
          <TableActionBar.ButtonAction variant="csv" disabled={downloadingCSV} onClickButton={onCSVDownload} />
          <TableActionBar.ButtonAction onClickButton={handleResetFilters} variant="cleanFilters" />

          <TableActionBar.Filters>
            <div />
            <label className={styles.checkLabel_userDemo}>
              <input type="checkbox" checked={showDemoUsers} onChange={(e) => handleShowDemoUsers(e.target.checked)} />
              Incluir usuarios demo
            </label>
          </TableActionBar.Filters>
        </TableActionBar.Wrapper>
      </TableActionBar>
      <DynamicTable
        columns={columns}
        rows={salesRequest}
        keyExtractor={({ id }) => id}
        rowHeight={80}
        loading={loading}
        pagination={pagination}
        actions={actions}
        hint="Al clicar sobre las métricas de arriba, el contenido de la tabla será filtrado por dicha condición"
        customClassNames={{ table: styles.customTableProps }}
      />
    </>
  )
}
