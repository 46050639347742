export enum LocationEventsEnum {
  LOCATION_CREATED = 'LOCATION/LOCATION_CREATED',
  LOCATION_UPDATED = 'LOCATION/LOCATION_UPDATED',
  LOCATION_DELETED = 'LOCATION/LOCATION_DELETED',
  RESETED_FILTERS = 'LOCATION/RESETED_FILTERS'
}

export type LocationEvents = {
  [LocationEventsEnum.LOCATION_CREATED]: () => void
  [LocationEventsEnum.LOCATION_UPDATED]: () => void
  [LocationEventsEnum.LOCATION_DELETED]: () => void
  [LocationEventsEnum.RESETED_FILTERS]: () => void
}
