import { Reducer } from 'redux'
import {
  CardsActions,
  CardsTableState,
  GET_CARDS_FAILURE,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS
} from '../types/cardTable'

const initialCardsTable: CardsTableState = {
  userId: '',
  cards: [
    {
      cardId: 0,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      token: '',
      userID: 0,
      mpCardID: '',
      main: false,
      lastDigits: '',
      type: '',
      typePhoto: '',
      expiration: '',
      expirationDate: null,
      paymentType: ''
    }
  ],
  loadingCards: false
}

const cardsTableReducer: Reducer<CardsTableState, CardsActions> = (
  state = initialCardsTable,
  action
): CardsTableState => {
  switch (action.type) {
    case GET_CARDS_REQUEST:
      return { ...state, loadingCards: !action.payload.silentLoading, userId: action.payload.userId }
    case GET_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.payload.cards,
        loadingCards: false
      }
    case GET_CARDS_FAILURE:
      return { ...state, loadingCards: false }
    default:
      return state
  }
}

export default cardsTableReducer
