import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { parseDate } from '../../../components/DynamicTable/utils/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { getSearchProps, getSortProps } from './utils'
import { Discount, Params, SearchFilter, Sorter } from './entities'
import NewEditDiscount from '../containers/newEditDiscount'
import moment from '../../../moment'
import { TableActionBar } from '../../../components/TableActionBar'

export type DiscountValidationTableProps = {
  discounts: Discount[]
  loading: boolean
  params: Params
  searchFilters: SearchFilter[]
  sorter: Sorter<Discount>
  pagination: Pagination
  handleSearch: (key: string, newValue: string) => void
  handleSort: (newSorter: Sorter<Discount>) => void
  openModalAddEdit: () => void
  getDiscounts: (params: Discount) => void
  getDiscountsFromStore: () => void
  cleanFilters: () => void
  actions: MenuAction<Discount>[]
}
const formatDate = (d: string) => moment(d).format('DD/MM/YYYY')

const DiscountTable = (props: DiscountValidationTableProps) => {
  const {
    discounts,
    loading,
    handleSearch,
    searchFilters,
    sorter,
    handleSort,
    params,
    getDiscounts,
    getDiscountsFromStore,
    cleanFilters,
    actions,
    pagination,
    openModalAddEdit
  } = props

  const columns: Column<Discount>[] = [
    {
      key: 'id',
      label: 'ID',
      sort: getSortProps('id', sorter, handleSort)
    },
    {
      key: 'code',
      label: 'Código',
      search: getSearchProps('code', 'Codigo', handleSearch, searchFilters)
    },
    {
      key: 'createdAt',
      label: 'Creación',
      minWidth: '102px',
      renderDataCell: ({ createdAt }) => {
        return parseDate(createdAt)
      }
    },
    {
      key: 'updatedAt',
      label: 'Actualización',
      minWidth: '102px',
      renderDataCell: ({ updatedAt }) => {
        return parseDate(updatedAt)
      }
    },
    {
      key: 'description',
      label: 'Descripción'
    },
    {
      key: 'transportDiscountPercent',
      label: 'Transporte',
      renderDataCell: (n) => n.transportDiscountPercent + '%'
    },
    {
      key: 'depositDiscountPercent',
      label: 'Depósito',
      renderDataCell: (n) => n.depositDiscountPercent + '%'
    },
    {
      key: 'depositDiscountMonths',
      label: 'Meses de descuento',
      renderDataCell: (n) => n.depositDiscountMonths
    },
    {
      key: 'startDate',
      label: 'Fecha de comienzo',
      renderDataCell: (n) => formatDate(n.startDate)
    },
    {
      key: 'endDate',
      label: 'Fecha de fin',
      renderDataCell: (n) => formatDate(n.endDate)
    },
    {
      key: 'publicDescription',
      label: 'Leyenda Factura'
    }
  ]
  const handleCleanFilters = () => {
    cleanFilters()
    getDiscountsFromStore()
  }

  return (
    <div>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />
        <TableActionBar.ButtonAction titleButton="Crear Descuento" onClickButton={() => openModalAddEdit()} />
      </TableActionBar>
      <DynamicTable
        columns={columns}
        rows={discounts}
        loading={loading}
        keyExtractor={(entity) => entity.id}
        pagination={pagination}
        actions={actions}
      />
      <NewEditDiscount getDiscounts={getDiscounts} params={params} />
    </div>
  )
}

export default DiscountTable
