import React from 'react'
import styles from './Carousel.module.scss'
import clsx from 'clsx'
import Zoom from './Zoom'

export type CarouselProps = {
  images: string[]
  selectedImage: string | null
  setSelectedImage: (image: string) => void
  carouselCustomClass?: string
  miniaturesCustomClass?: string
  zoomScale?: number
  placeholder?: string
}

const Carousel: React.FC<CarouselProps> = ({
  images,
  selectedImage,
  setSelectedImage,
  carouselCustomClass,
  miniaturesCustomClass,
  zoomScale = 4,
  placeholder = ''
}) => {
  return (
    <div className={clsx(styles.carousel, carouselCustomClass)}>
      {selectedImage ? (
        <Zoom height={500} width={470} zoomScale={zoomScale} src={selectedImage} />
      ) : (
        'Aún no se ha seleccionado una imagen.'
      )}
      <div className={clsx(miniaturesCustomClass, styles.miniatures)}>
        {images.map((i) => (
          <img
            src={i}
            alt=""
            key={i}
            onClick={() => setSelectedImage(i)}
            className={clsx(selectedImage === i && styles.selectedImage)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = placeholder
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default React.memo(Carousel)
