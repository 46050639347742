import { PriceBand } from '../../../projectApi/CategoryCreation/getPriceBands'
import { SingleDateFilter } from '../../../utils/searchFilterUtils'

export type PriceBandsSingleDateFilterKey = 'dateFrom'
export type PriceBandsSingleDateFilter = SingleDateFilter<PriceBandsSingleDateFilterKey>

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export type PriceBandsTableState = {
  priceBands: PriceBand[]
  pagination: Pagination
  loading: boolean
  error: string
  singleDateFilters: PriceBandsSingleDateFilter[]
}

export const GET_PRICE_BANDS_REQUEST = 'PRICE_BANDS/GET_PRICE_BANDS_REQUEST'
export const GET_PRICE_BANDS_SUCCESS = 'PRICE_BANDS/GET_PRICE_BANDS_SUCCESS'
export const GET_PRICE_BANDS_FAILURE = 'PRICE_BANDS/GET_PRICE_BANDS_FAILURE'

export interface GetPriceBandsRequest {
  type: typeof GET_PRICE_BANDS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    singleDateFilters?: PriceBandsSingleDateFilter[]
    silentLoading?: boolean
  }
}

export interface GetPriceBandsSuccess {
  type: typeof GET_PRICE_BANDS_SUCCESS
  payload: {
    total: number
    priceBands: PriceBand[]
  }
}

export interface GetPriceBandsFailure {
  type: typeof GET_PRICE_BANDS_FAILURE
  payload: {
    error: string
  }
}

export type GetPriceBandsAction = GetPriceBandsRequest | GetPriceBandsSuccess | GetPriceBandsFailure

export type PriceBandsTableAction = GetPriceBandsAction
