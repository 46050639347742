import moment, { Moment } from 'moment'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { UserRole } from '../../../projectApi/AccountManager/Users/common'
import { Trip } from '../../../projectApi/Trips/Trip/common'
import { TripTransaction } from '../../../projectApi/Trips/Trip/create'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  CREATE_TRIP_FAILURE,
  CREATE_TRIP_REQUEST,
  CREATE_TRIP_SUCCESS,
  CreateTripRequest,
  CreateTripSuccess,
  FIND_TRANSACTION_BY_ID_FAILURE,
  FIND_TRANSACTION_BY_ID_REQUEST,
  FIND_TRANSACTION_BY_ID_SUCCESS,
  FindTransactionByIdRequest,
  FindTransactionByIdSuccess,
  GET_DRIVERS_FAILURE,
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  GetDriversRequest,
  GetDriversSuccess,
  SELECT_DRIVER,
  SET_OPEN,
  SelectDriver,
  SetOpen,
  TripModalTransaction,
  UPDATE_TRIP_FAILURE,
  UPDATE_TRIP_REQUEST,
  UPDATE_TRIP_SUCCESS,
  UpdateTripRequest,
  UpdateTripSuccess
} from '../types/tripModal'
import { TransactionStatusId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { transactionToTripTransaction } from '../../Transactions/containers/TransactionsTable'
import {
  sendToastNotificationError,
  sendToastNotificationSuccess,
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate
} from '../../../utils/notifications'

const TripModalActionCreators = {
  setOpen: ({
    trip,
    open,
    transactions
  }: {
    open: boolean
    transactions?: TripModalTransaction[]
    trip?: Trip
  }): SetOpen => ({
    type: SET_OPEN,
    payload: { transactions, trip, open }
  }),
  selectDriver: (driverId: number): SelectDriver => ({ type: SELECT_DRIVER, payload: { driverId } }),
  getDrivers: (): AppThunk => async (dispatch) => {
    const request: GetDriversRequest = {
      type: GET_DRIVERS_REQUEST
    }

    dispatch(request)

    try {
      const { users } = await API.AccountManager.Users.list({ role: UserRole.TRANSPORTISTA })

      const success: GetDriversSuccess = {
        type: GET_DRIVERS_SUCCESS,
        payload: {
          drivers: users.map((u) => ({
            name: u.name,
            lastName: u.lastName,
            userId: u.realId
          }))
        }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GET_DRIVERS_FAILURE, dispatch)
    }
  },
  createTrip:
    ({
      driverId,
      transactions,
      scheduledEndDate,
      scheduledStartDate,
      country
    }: {
      driverId?: number
      transactions: TripTransaction[]
      scheduledStartDate: Moment
      scheduledEndDate: Moment
      country?: CountryIdCode
    }): AppThunk =>
    async (dispatch) => {
      const request: CreateTripRequest = {
        type: CREATE_TRIP_REQUEST
      }

      dispatch(request)

      try {
        await API.Trips.Trip.create({ driverId, transactions, scheduledEndDate, scheduledStartDate, country })

        const success: CreateTripSuccess = {
          type: CREATE_TRIP_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Trips.TRIP_CREATED)
      } catch (error) {
        handleErrorsWithAction(error, CREATE_TRIP_FAILURE, dispatch)
      }
    },
  updateTrip:
    ({
      tripId,
      driverId,
      transactionsToAdd
    }: {
      tripId: number
      driverId?: number
      transactionsToAdd?: TripTransaction[]
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateTripRequest = {
        type: UPDATE_TRIP_REQUEST
      }

      dispatch(request)

      try {
        if (transactionsToAdd) {
          await API.Trips.Trip.addTransactions({ tripId, transactions: transactionsToAdd })
        }
        if (driverId) {
          await API.Trips.Trip.update({ tripId, driverId })
        }

        const success: UpdateTripSuccess = {
          type: UPDATE_TRIP_SUCCESS
        }

        dispatch(success)
        sendToastNotificationSuccess('Viaje actualizado correctamente')
        Emitter.emit(Events.Trips.TRIP_UPDATED, tripId)
      } catch (error) {
        sendToastNotificationError('Hubo un error al actualizar el viaje')
        handleErrorsWithAction(error, UPDATE_TRIP_FAILURE, dispatch)
      }
    },
  findTransactionById:
    (txId: number): AppThunk =>
    async (dispatch) => {
      const request: FindTransactionByIdRequest = {
        type: FIND_TRANSACTION_BY_ID_REQUEST
      }

      const toast = setToastLoading('Buscando transacción')

      dispatch(request)

      try {
        const { transactions } = await API.TransactionHandler.Transactions.list({
          id: txId,
          status: [TransactionStatusId.PENDIENTE, TransactionStatusId.LISTO_PARA_VIAJAR],
          now: moment()
        })

        if (transactions.length === 0) {
          throw new Error('No se encontró la transacción')
        }

        const foundTX = transactions[0]

        const success: FindTransactionByIdSuccess = {
          type: FIND_TRANSACTION_BY_ID_SUCCESS,
          payload: {
            transaction: transactionToTripTransaction(foundTX)
          }
        }

        setToastSuccessUpdate(toast, { render: 'Transacción encontrada' })
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, FIND_TRANSACTION_BY_ID_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'No se encontró la transacción' })
      }
    }
}

export default TripModalActionCreators
