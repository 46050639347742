export const DEFAULT_ADDRESS_REQUEST = 'USER/ADDRESS/DEFAULT_ADDRESS_REQUEST'
export const DEFAULT_ADDRESS_SUCCESS = 'USER/ADDRESS/DEFAULT_ADDRESS_SUCCESS'
export const DEFAULT_ADDRESS_FAILURE = 'USER/ADDRESS/DEFAULT_ADDRESS_FAILURE'
export const OPEN_DEFAULT_ADDRESS_MODAL = 'USER/ADDRESS/OPEN_DEFAULT_ADDRESS_MODAL'
export const CLOSE_DEFAULT_ADDRESS_MODAL = 'USER/ADDRESS/CLOSE_DEFAULT_ADDRESS_MODAL'

export interface DefaultAddressState {
  userId: number
  addressId: number
  addressText: string
  defaultAddressModalOpen: boolean
  loading: boolean
  error: string
}

export interface DefaultAddressRequest {
  type: typeof DEFAULT_ADDRESS_REQUEST
}

export interface DefaultAddressSuccess {
  type: typeof DEFAULT_ADDRESS_SUCCESS
}

export interface DefaultAddressFailure {
  type: typeof DEFAULT_ADDRESS_FAILURE
  payload: {
    error: string
  }
}

export interface OpenDefaultAddressModal {
  type: typeof OPEN_DEFAULT_ADDRESS_MODAL
  payload: {
    userId: number
    addressId: number
    addressText: string
  }
}

export interface CloseDefaultAddressModal {
  type: typeof CLOSE_DEFAULT_ADDRESS_MODAL
}

export type DefaultAddressActions =
  | DefaultAddressRequest
  | DefaultAddressSuccess
  | DefaultAddressFailure
  | OpenDefaultAddressModal
  | CloseDefaultAddressModal
