import React from 'react'
import { formatNumber } from '../../../../utils/formatNumber'
import { LocationsMetrics } from '../types/locationsTable'
import OccupancyCard from '../../../../components/OccupancyCard'
import styles from './locationsMetrics.module.scss'
import QuantityCard from '../../../../components/QuantityCard'

type LocationMetricsProps = {
  locationsMetrics: LocationsMetrics
  fetchingLocationsMetrics: boolean
}

const grToKg = (gr: number) => gr / 1000
const cm3ToM3 = (cm3: number) => cm3 / 1000000

const LocationsMetricsComponent = (props: LocationMetricsProps) => {
  const { locationsMetrics, fetchingLocationsMetrics } = props

  const {
    volumeInCm,
    maxVolumeInCm,
    weightInGr,
    maxWeightInGr,
    quantityObjects,
    quantityLocations,
    occupancyRate,
    loadRate
  } = locationsMetrics

  const availableM3 = formatNumber(cm3ToM3(maxVolumeInCm - volumeInCm), 2, true)
  const availableKg = formatNumber(grToKg(maxWeightInGr - weightInGr), 0, true)

  const infoMetrics = [
    {
      leftContainer: {
        title: 'Ocupación',
        value: `${formatNumber(cm3ToM3(volumeInCm), 2, true)} m³`,
        percentage: `${occupancyRate}%`
      },
      rightContainer: {
        title: 'Disponibilidad m³',
        value: `${availableM3} m³`,
        percentage: `${formatNumber(100 - occupancyRate, 1)}%`,
        percentageToColor: parseInt(formatNumber(100 - occupancyRate, 1)),
        description: 'Espacio ocupado y disponible para almacenamiento en metros cúbicos'
      }
    },
    {
      leftContainer: {
        title: 'Peso',
        value: `${formatNumber(grToKg(weightInGr), 0, true)} Kg `,
        percentage: `${loadRate}%`
      },
      rightContainer: {
        title: 'Disponibilidad Kg',
        value: `${availableKg} Kg`,
        percentage: `${formatNumber(100 - loadRate, 1)}%`,
        percentageToColor: parseInt(formatNumber(100 - loadRate, 1)),
        description: 'Peso ocupado y disponible para almacenamiento en Kilogramos'
      }
    }
  ]

  const quantity = [
    { titleCard: 'Cantidades', title: 'Ubicaciones', value: quantityLocations },
    { titleCard: '', title: 'Objetos', value: quantityObjects }
  ]

  return (
    <div className={styles.metricsContainer}>
      {infoMetrics.map((info) => (
        <OccupancyCard
          key={info.leftContainer.title}
          info={info}
          fetchingMetrics={fetchingLocationsMetrics}
          color="spaceguru"
        />
      ))}
      <QuantityCard fetchingMetrics={fetchingLocationsMetrics}>
        {quantity.map(({ titleCard, title, value }) => (
          <QuantityCard.Cell key={title}>
            <QuantityCard.Title titleCard={titleCard} />
            <QuantityCard.MainContent content={formatNumber(value, 0, true)} />
            <QuantityCard.Type title={title} />
          </QuantityCard.Cell>
        ))}
      </QuantityCard>
    </div>
  )
}

export default LocationsMetricsComponent
