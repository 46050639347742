import React from 'react'
import Component, { EditOriginModalProps } from '../components/editOrigin'
import { bindActionCreators } from 'redux'
import actions from '../actionCreator/editOrigin'
import { useDispatch, useSelector } from 'react-redux'

const EditOriginContainer = () => {
  const dispatch = useDispatch()
  const editOrigin = useSelector((state) => state.Origins.modal)
  const sendActions = bindActionCreators(actions, dispatch)
  const { sources } = useSelector((state) => state.Origins.table)

  const props: EditOriginModalProps = {
    editOrigin,
    editOriginActions: sendActions,
    sources
  }

  return <Component {...props} />
}

export default EditOriginContainer
