import { OPEN_MODAL_FILES, OpenModalFiles } from '../types/filesModal'

export const FilesModalActionCreator = {
  openFilesModal: ({ modalOpen: openModal }: { modalOpen: boolean }): OpenModalFiles => ({
    type: OPEN_MODAL_FILES,
    payload: { modalOpen: openModal }
  })
}

export default FilesModalActionCreator
