import {
  ACCEPT_PROPOSAL_FAILURE,
  ACCEPT_PROPOSAL_REQUEST,
  ACCEPT_PROPOSAL_SUCCESS,
  CLEAR_VIEW_STATE,
  CREATE_COUNTER_PROPOSAL_FAILURE,
  CREATE_COUNTER_PROPOSAL_REQUEST,
  CREATE_COUNTER_PROPOSAL_SUCCESS,
  GET_REASONS_FAILURE,
  GET_REASONS_REQUEST,
  GET_REASONS_SUCCESS,
  GET_SALES_REQUEST_VIEW_FAILURE,
  GET_SALES_REQUEST_VIEW_REQUEST,
  GET_SALES_REQUEST_VIEW_SUCCESS,
  GET_SALE_COST_ARG_FAILURE,
  GET_SALE_COST_ARG_REQUEST,
  GET_SALE_COST_ARG_SUCCESS,
  MANAGE_SELL_MODAL,
  SELL_OBJECT_FAILURE,
  SELL_OBJECT_REQUEST,
  SELL_OBJECT_SUCCESS,
  SET_COUNTER_PROPOSAL_VIEW,
  SET_OPEN_MODAL,
  SET_PUBLICATION_PRICE,
  SalesRequestViewAction,
  SalesRequestViewState,
  UPDATE_SALE_REQUEST_FAILURE,
  UPDATE_SALE_REQUEST_REQUEST,
  UPDATE_SALE_REQUEST_SUCCESS
} from './../types/salesRequestView'
import { Reducer } from 'redux'

const initialState: SalesRequestViewState = {
  salesRequest: null,
  isSellModalOpen: false,
  isLoadingModal: false,
  error: '',
  isOpenModal: false,
  loadingView: true,
  saleCostArg: 0,
  publicationPrice: 0,
  reasons: [],
  loadingReasons: false,
  isCounterProposalActive: false
}

const salesRequestViewReducer: Reducer<SalesRequestViewState, SalesRequestViewAction> = (
  state = initialState,
  action
): SalesRequestViewState => {
  switch (action.type) {
    case SET_COUNTER_PROPOSAL_VIEW:
      return {
        ...state,
        isCounterProposalActive: action.payload.isCounterProposalActive
      }
    case CLEAR_VIEW_STATE:
      return initialState
    case SET_OPEN_MODAL:
      return {
        ...state,
        isOpenModal: action.payload.isOpenModal
      }
    case SET_PUBLICATION_PRICE:
      return {
        ...state,
        publicationPrice: action.payload.publicationPrice
      }

    case GET_SALES_REQUEST_VIEW_REQUEST:
      return {
        ...state,
        loadingView: true
      }
    case GET_SALES_REQUEST_VIEW_SUCCESS:
      return {
        ...state,
        salesRequest: action.payload.saleRequest,
        loadingView: false
      }
    case GET_SALES_REQUEST_VIEW_FAILURE:
      return {
        ...state,
        loadingView: false,
        error: action.payload.error
      }
    case UPDATE_SALE_REQUEST_REQUEST:
      return {
        ...state
      }
    case UPDATE_SALE_REQUEST_SUCCESS:
      return {
        ...state,
        isOpenModal: false
      }
    case UPDATE_SALE_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case GET_SALE_COST_ARG_REQUEST:
      return {
        ...state
      }
    case GET_SALE_COST_ARG_SUCCESS:
      return {
        ...state,
        saleCostArg: action.payload.saleCostArg
      }

    case GET_SALE_COST_ARG_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case GET_REASONS_REQUEST:
      return {
        ...state,
        loadingReasons: true
      }
    case GET_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload.reasons,
        loadingReasons: false
      }
    case GET_REASONS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loadingReasons: false
      }
    case ACCEPT_PROPOSAL_REQUEST:
      return {
        ...state
      }

    case ACCEPT_PROPOSAL_SUCCESS:
      return {
        ...state
      }
    case ACCEPT_PROPOSAL_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }

    case CREATE_COUNTER_PROPOSAL_REQUEST:
      return {
        ...state
      }
    case CREATE_COUNTER_PROPOSAL_SUCCESS:
      return {
        ...state
      }
    case CREATE_COUNTER_PROPOSAL_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case MANAGE_SELL_MODAL:
      return {
        ...state,
        isSellModalOpen: action.payload.isSellModalOpen
      }
    case SELL_OBJECT_REQUEST:
      return {
        ...state,
        isLoadingModal: true
      }
    case SELL_OBJECT_SUCCESS:
      return {
        ...state,
        isLoadingModal: false
      }
    case SELL_OBJECT_FAILURE:
      return {
        ...state,
        isLoadingModal: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default salesRequestViewReducer
