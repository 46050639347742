import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'antd'
import { AutocompleteProps } from '../../../types/types'
import Overlay from '../Overlay/Overlay'
import HeaderIcon from '../../HeaderIcon/HeaderIcon'
import { Search as SearchIcon } from '../../../../../icons'
import Select, { BaseOption } from '../../../../Select/Select'
import useDebounce from '../../../../../utils/hooks/useDebounce'

type AutocompleteDropdownProps = AutocompleteProps

const initialLocalValue = { label: '', value: 0 }

const AutocompleteDropdown = ({
  onConfirm,
  getOptions,
  options,
  loadingOptions,
  value,
  label,
  extraComponent
}: AutocompleteDropdownProps) => {
  const [autoComplete, setAutoComplete] = useState('')
  const [localValue, setLocalValue] = useState<BaseOption<number>>(initialLocalValue)
  const [visible, setVisible] = useState(false)
  const debouncedValue = useDebounce<string>(autoComplete)

  useEffect(() => {
    if (value !== localValue?.value) setLocalValue({ label: '', value })
  }, [value])

  useEffect(() => {
    if (localValue.value === 0) getOptions(debouncedValue)
  }, [debouncedValue])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleSearch = (newInputValue: number) => {
    setVisible(false)
    if (newInputValue !== value) {
      onConfirm(newInputValue)
    }
  }

  const handleDelete = () => {
    setLocalValue(initialLocalValue)
    setAutoComplete('')
  }

  const handleReset = () => {
    handleSearch(0)
    handleDelete()
  }

  const overlay = (
    <Overlay
      buttons={
        <>
          <Button
            disabled={!localValue.value}
            type="primary"
            onClick={() => handleSearch(localValue.value)}
            icon="search"
            size="small">
            Buscar
          </Button>
          <Button onClick={handleReset} size="small">
            Resetear
          </Button>
        </>
      }>
      <Select
        placeholder={`Buscar por ${label}`}
        loading={loadingOptions}
        disabled={false}
        options={options}
        selected={localValue}
        onSelect={(e) => setLocalValue(e)}
        autocomplete={{
          filterByInput: true,
          input: autoComplete,
          onInputChange: (text) => setAutoComplete(text)
        }}
        onDelete={localValue.value === 0 ? undefined : handleDelete}
      />
      {extraComponent}
    </Overlay>
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
      <div>
        <HeaderIcon overlayOpen={visible} active={!!localValue.value}>
          <SearchIcon />
        </HeaderIcon>
      </div>
    </Dropdown>
  )
}

export default AutocompleteDropdown
