import React from 'react'
import { Header } from '../../../../components/overviewCard'
import OverviewCard, { Module } from '../../../../components/overviewCard/overviewCard'
import { SalesRequest } from '../../../../projectApi/Sales/SalesRequest/list'
import styles from '../SalesRequestView.module.scss'
import notAvailableImage from '../../../Objects/images/notAvailableImage.jpg'
import ImageTooltip from '../../../DepositsValidation/components/ImageTooltip'
import { formatMoney, formatNumber } from '../../../../utils/formatNumber'
import { DataDisplay } from './DataDisplayBox'
import { StatusOptions } from '../../../../projectApi/Sales/SalesRequest/common'
import { Button } from 'antd'
import { disableToSell } from '../../../../projectApi/ObjectAdministration/common'

type Props = {
  salesRequest: SalesRequest | null
  openSaleRequestModal: () => void
  closeSaleRequestModal: () => void
}

export const SummaryView: React.FC<Props> = ({ salesRequest, openSaleRequestModal, closeSaleRequestModal }) => {
  const obj = salesRequest?.object
  const totalM3 = obj && (obj.lengthInCm * obj?.widthInCm * obj?.heightInCm) / 1_000_000

  const expressSaleAccepted =
    salesRequest?.status === StatusOptions.ACEPTADA_VENTA_EXPRESS ||
    salesRequest?.status === StatusOptions.CONTRAPROPUESTA_ACEPTADA_VE

  const summaryDetails: DataDisplay[] = [
    {
      label: 'Precio de venta',
      value: salesRequest && salesRequest?.status !== StatusOptions.RECHAZADA_SG ? salesRequest?.pvp : 0
    },
    {
      label: 'Costo por venta',
      value:
        salesRequest?.expressSale && expressSaleAccepted
          ? salesRequest.expressSale.saleCost
          : salesRequest?.saleCost || null
    },
    {
      label: 'Margen',
      value:
        salesRequest?.expressSale && expressSaleAccepted
          ? salesRequest.expressSale.saleMargin
          : salesRequest?.saleMargin || null
    }
  ]

  const summaryExpress: DataDisplay[] = [
    {
      label: 'Precio de venta Express',
      value: salesRequest?.expressSale ? salesRequest?.expressSale?.pve : 0
    },
    {
      label: 'Costo por venta',
      value: salesRequest?.expressSale ? salesRequest?.expressSale.saleCost : 0
    },
    {
      label: 'Margen',
      value: salesRequest?.expressSale ? salesRequest?.expressSale.saleMargin : 0
    }
  ]

  return (
    <>
      <div className={styles.rejectedContainer}>
        <section className={!salesRequest?.expressSale ? styles.onlyDetails : ''}>
          <article className={styles.product}>
            <div className={salesRequest?.expressSale ? styles.productDescriptionRow : styles.productDescriptionRow}>
              <div className={styles.imgContainer}>
                {!obj?.photoUrl ? (
                  <img src={notAvailableImage} />
                ) : (
                  <div className={styles.img}>
                    <ImageTooltip
                      src={obj.photoUrl}
                      alt={''}
                      maxWidth={'50vw'}
                      maxHeight={'50vh'}
                      imgWidth={'130px'}
                      imgHeight={'130px'}
                    />
                  </div>
                )}
              </div>

              <div className={styles.description}>
                <span>
                  <b>ID:</b> {salesRequest?.id}
                </span>
                <span style={{ fontWeight: 600 }}>{obj?.description}</span>
                <span>
                  <b>
                    {obj?.widthInCm}x{obj?.heightInCm}x{obj?.lengthInCm}(cm) =
                  </b>{' '}
                  {totalM3 && formatNumber(totalM3)}m3
                </span>
                <span>
                  <b>Coef. Multp.</b> x{obj?.product.multiplier}
                </span>
              </div>
            </div>
          </article>

          <div className={salesRequest?.expressSale ? styles.detailsAndExpress : styles.onlyDetails}>
            <article style={{ width: '100%', height: '100%' }}>
              <OverviewCard>
                <Header>Detalle de solicitud</Header>
                <Module>
                  {summaryDetails.map((x) => (
                    <div key={x.label} className={styles.summaryDetails}>
                      <p>{x.label}</p>
                      <p style={{ color: x.value && x.value < 0 ? 'red' : '' }}>{formatMoney(x.value)}</p>
                    </div>
                  ))}
                </Module>
                <Module className={styles.total}>
                  <span>Valor a Transferir</span>
                  <span>{formatMoney(expressSaleAccepted ? salesRequest.pve : salesRequest?.prc)}</span>
                </Module>
              </OverviewCard>
            </article>
            {salesRequest?.expressSale && (
              <article style={{ width: '100%', height: '100%' }}>
                <OverviewCard>
                  <Header>Detalle de venta Express</Header>
                  <Module>
                    {summaryExpress.map((x) => (
                      <div key={x.label} className={styles.summaryDetails}>
                        <p>{x.label}</p>
                        <p style={{ color: x.value && x.value < 0 ? 'red' : '' }}>{formatMoney(x.value)}</p>
                      </div>
                    ))}
                  </Module>
                  <Module className={styles.total}>
                    <span>Valor a Transferir</span>
                    <span>{formatMoney(summaryExpress[0].value)}</span>
                  </Module>
                </OverviewCard>
              </article>
            )}
          </div>
        </section>
      </div>
      {!disableToSell.includes(salesRequest?.status || '') && (
        <div className={styles.buttonsContainer}>
          <Button onClick={() => closeSaleRequestModal()} className={styles.modalButtonCancel}>
            Cancelar
          </Button>
          <Button onClick={() => openSaleRequestModal()} className={styles.modalButtonOk}>
            Confirmar
          </Button>
        </div>
      )}
    </>
  )
}
