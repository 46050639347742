/* eslint-disable camelcase */

import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

export interface CountryResponse {
  id: string
  name: string
  currency: string
}

interface APIResponse {
  countries?: CountryResponse[]
}

type Response = CamelCasedPropertiesDeep<CountryResponse>[]

export function getAll(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/countries`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.countries || response.data.countries.length === 0)
        throw new Error('Respuesta incorrecta al obtener los países')

      return camelcaseKeys(response.data.countries, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
