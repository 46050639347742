import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'

import {
  GET_SERVICES_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  ServiceTableState,
  ServicesTableActions
} from '../types/servicesTable'

const LIMIT = 50

const initialState: ServiceTableState = {
  services: [],
  loadingServices: false,
  error: '',
  pagination: {
    page: 1,
    total: 0,
    pageSize: LIMIT
  },
  sort: {
    field: 'id',
    direction: SortDirection.DESC
  }
}

const ServicesTableReducer: Reducer<ServiceTableState, ServicesTableActions> = (
  state = initialState,
  action
): ServiceTableState => {
  switch (action.type) {
    case GET_SERVICES_REQUEST:
      return { ...state, loadingServices: true }

    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload.services,
        loadingServices: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }

    case GET_SERVICES_FAILURE:
      return { ...state, loadingServices: false, error: action.payload.error }

    default:
      return { ...state }
  }
}

export default ServicesTableReducer
