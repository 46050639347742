import {
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_REQUEST,
  CREATE_LEAD_SUCCESS,
  CreateLeadRequest,
  CreateLeadSuccess,
  EDIT_LEAD_REQUEST,
  EDIT_LEAD_FAILURE,
  EDIT_LEAD_SUCCESS,
  EditLead,
  EditLeadSuccess,
  OpenModalCreate,
  OpenModalEdit,
  SET_CODE_AREA,
  SET_CONTACT_MEDIUM,
  SET_COUNTRY,
  SET_COUNTRY_CODE,
  SET_CREATE_MODAL_OPEN,
  SET_DISCARD,
  SET_EDIT_MODAL_OPEN,
  SET_EMAIL,
  SET_ERROR_CODE_AREA,
  SET_ERROR_EMAIL,
  SET_ERROR_LAST_NAME,
  SET_ERROR_NAME,
  SET_ERROR_PHONE,
  SET_ERROR_PHONE_NUMBER,
  SET_GENDER,
  SET_INTEREST,
  SET_LAST_NAME,
  SET_LOSE_MQL,
  SET_LOSE_SQL,
  SET_NAME,
  SET_PHONE,
  SET_PHONE_NUMBER,
  SET_SOURCE_CHANNEL,
  SET_STATUS,
  SetCodeArea,
  SetContactMedium,
  SetCountry,
  SetCountryCode,
  SetDiscard,
  SetErrorCodeArea,
  SetErrorEmail,
  SetErrorLastName,
  SetErrorName,
  SetErrorNumberPhone,
  SetErrorPhone,
  SetGender,
  SetInterest,
  SetLastName,
  SetLoseMQL,
  SetLoseSQL,
  SetName,
  SetPhone,
  SetPhoneNumber,
  SetSourceChannel,
  SetStatus,
  VALIDATE_EMAIL_FAILURE,
  VALIDATE_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  ValidateEmailFailure,
  ValidateEmailSuccess,
  VerifyEmailRequest,
  VerifyEmailSuccess,
  selectedCountry
} from '../types/ModalLeads'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { CreateLead, EditProspect, Prospect } from '../entities'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import validateEmail from '../../../utils/validateEmail'
import { isValidName } from '../../../utils/validateName'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { CountryIdCode, CountryName, CountryPhoneCode } from '../../../components/CountrySelector/constants/constants'
import { validAreaCodes } from '../../Users/Users.constants'

const modalLeadsActionCreators = {
  setName:
    (name: string): AppThunk =>
    (dispatch) => {
      const { value, isValid } = isValidName(name)
      const success: SetName = {
        type: SET_NAME,
        payload: {
          name: value
        }
      }

      dispatch(success)
      if (!isValid) {
        const failure: SetErrorName = {
          type: SET_ERROR_NAME,
          payload: {
            error: 'Este casillero solo permite letras'
          }
        }
        dispatch(failure)
      }
    },

  setLastName:
    (lastName: string): AppThunk =>
    (dispatch) => {
      const { value, isValid } = isValidName(lastName)
      const success: SetLastName = {
        type: SET_LAST_NAME,
        payload: {
          lastName: value
        }
      }

      dispatch(success)
      if (!isValid) {
        const failure: SetErrorLastName = {
          type: SET_ERROR_LAST_NAME,
          payload: {
            error: 'Este casillero solo permite letras'
          }
        }
        dispatch(failure)
      }
    },

  setStatus(statusId: number): SetStatus {
    return { type: SET_STATUS, payload: { statusId } }
  },

  setEmail:
    (email: string): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: SET_EMAIL,
        payload: { email }
      })

      if (validateEmail(email)) {
        dispatch(modalLeadsActionCreators.verifyIfEmailExists({ email }))
        const success: ValidateEmailSuccess = {
          type: VALIDATE_EMAIL_SUCCESS
        }
        dispatch(success)
      } else if (email !== '') {
        const failureValidate: ValidateEmailFailure = {
          type: VALIDATE_EMAIL_FAILURE,
          payload: {
            errorMessage: 'El email es inválido'
          }
        }
        dispatch(failureValidate)
      } else {
        const failureValidate: ValidateEmailFailure = {
          type: VALIDATE_EMAIL_FAILURE,
          payload: {
            errorMessage: ''
          }
        }
        dispatch(failureValidate)
      }
    },

  setPhone:
    (phone: string, countryCode: string): AppThunk =>
    (dispatch) => {
      const success: SetPhone = {
        type: SET_PHONE,
        payload: {
          phone
        }
      }

      dispatch(success)
      if (countryCode === CountryIdCode.SPAIN) {
        if (Array.from(phone).includes('_')) {
          const failure: SetErrorPhone = {
            type: SET_ERROR_PHONE,
            payload: {
              error: 'El número debe tener 9 dígitos'
            }
          }
          dispatch(failure)
        }
      }
      if (countryCode === CountryIdCode.ARGENTINA) {
        if (Array.from(phone).includes('_')) {
          const failure: SetErrorPhone = {
            type: SET_ERROR_PHONE,
            payload: {
              error: `El número debe tener 10 dígitos`
            }
          }
          dispatch(failure)
        }
      }
    },

  setCountryCode: (countryCode: string): SetCountryCode => {
    const limit = modalLeadsActionCreators.getLimitNumber(countryCode)
    return { type: SET_COUNTRY_CODE, payload: { countryCode, limit } }
  },

  setCodeArea:
    (codeArea: string, countryCode: string): AppThunk =>
    (dispatch) => {
      const success: SetCodeArea = {
        type: SET_CODE_AREA,
        payload: { codeArea }
      }

      dispatch(success)
      if (/^[0-9]*$/.test(codeArea)) {
        if (countryCode === CountryPhoneCode.ARGENTINA && !validAreaCodes.some((vAC) => vAC === codeArea)) {
          const failure: SetErrorCodeArea = {
            type: SET_ERROR_CODE_AREA,
            payload: {
              errorMessage: `El código de área es inválido`
            }
          }
          dispatch(failure)
        }
      } else {
        const failure: SetErrorCodeArea = {
          type: SET_ERROR_CODE_AREA,
          payload: {
            errorMessage: `El código de área solo acepta números`
          }
        }
        dispatch(failure)
      }
    },

  setPhoneNumber:
    (phoneNumber: string, codeArea: string, countryCode: string): AppThunk =>
    (dispatch) => {
      const success: SetPhoneNumber = {
        type: SET_PHONE_NUMBER,
        payload: { phoneNumber }
      }

      dispatch(success)
      if (/^[0-9]*$/.test(phoneNumber)) {
        if (countryCode === CountryPhoneCode.ARGENTINA) {
          if (phoneNumber.length > 10 - codeArea.length)
            dispatch({
              type: SET_ERROR_PHONE_NUMBER,
              payload: { errorMessage: `El telefono debe contener ${10 - codeArea.length} números` }
            })
          if (phoneNumber.length < 10 - codeArea.length)
            dispatch({
              type: SET_ERROR_PHONE_NUMBER,
              payload: { errorMessage: '' }
            })
        }

        if (countryCode === CountryPhoneCode.SPAIN) {
          if (phoneNumber.length > 9)
            dispatch({ type: SET_ERROR_PHONE_NUMBER, payload: { errorMessage: 'El telefono de contener 9 números' } })
          if (phoneNumber.length < 9 - codeArea.length)
            dispatch({
              type: SET_ERROR_PHONE_NUMBER,
              payload: { errorMessage: '' }
            })
        }
      } else {
        const failure: SetErrorNumberPhone = {
          type: SET_ERROR_PHONE_NUMBER,
          payload: {
            errorMessage: `Esta casilla solo acepta números`
          }
        }
        dispatch(failure)
      }
    },

  setGender: (gender: string): SetGender => {
    return { type: SET_GENDER, payload: { gender } }
  },

  setCountry: (country: selectedCountry): SetCountry => {
    const limitPhoneNumber = modalLeadsActionCreators.getLimitNumber(country.phoneCode)
    return { type: SET_COUNTRY, payload: { country, limitPhoneNumber } }
  },

  setSourceChannel: (sourceChannelId: number): SetSourceChannel => {
    return { type: SET_SOURCE_CHANNEL, payload: { sourceChannelId } }
  },

  setContactMedium: (contactMediumId: number): SetContactMedium => {
    return { type: SET_CONTACT_MEDIUM, payload: { contactMediumId } }
  },

  setInterestReason: (interestReasonId: number): SetInterest => {
    return { type: SET_INTEREST, payload: { interestReasonId } }
  },

  setDiscardReason: (discardReasonId: number): SetDiscard => {
    return { type: SET_DISCARD, payload: { discardReasonId } }
  },

  setLoseReasonMQL: (loseReasonMQL: number): SetLoseMQL => {
    return { type: SET_LOSE_MQL, payload: { loseReasonMQL } }
  },

  setLoseReasonSQL: (loseReasonSQL: number): SetLoseSQL => {
    return { type: SET_LOSE_SQL, payload: { loseReasonSQL } }
  },

  setErrorName: (error: string): SetErrorName => {
    return { type: SET_ERROR_NAME, payload: { error } }
  },

  setErrorLastName: (error: string): SetErrorLastName => {
    return { type: SET_ERROR_LAST_NAME, payload: { error } }
  },

  setErrorEmail: (error: string): SetErrorEmail => {
    return { type: SET_ERROR_EMAIL, payload: { error } }
  },

  setErrorPhone: (error: string): SetErrorPhone => {
    return { type: SET_ERROR_PHONE, payload: { error } }
  },

  setCreateModalOpen: (createModalOpen: boolean, selectedCountry: selectedCountry): OpenModalCreate => {
    const limit = modalLeadsActionCreators.getLimitNumber(selectedCountry.phoneCode)
    return { type: SET_CREATE_MODAL_OPEN, payload: { createModalOpen, selectedCountry, limit } }
  },

  setEditModalOpen: (open: boolean, lead: Prospect, countries: selectedCountry[]): OpenModalEdit => {
    const selectedCountry = countries.find((co) => co.fullName === lead.country) || countries[0]
    const limit = modalLeadsActionCreators.getLimitNumber(selectedCountry.phoneCode)

    return { type: SET_EDIT_MODAL_OPEN, payload: { open, lead, selectedCountry, limit } }
  },

  createLead:
    (lead: CreateLead): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Creando oportunidad, por favor espere...')
      const request: CreateLeadRequest = {
        type: CREATE_LEAD_REQUEST
      }

      dispatch(request)

      try {
        await API.Mercurio.Prospect.create(lead)
        const success: CreateLeadSuccess = {
          type: CREATE_LEAD_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Oportunidad creada correctamente' })
        Emitter.emit(Events.Leads.LEAD_CREATED)
      } catch (error: any) {
        if (error.response.status === 409) {
          const id = error.response.data.description.id
          dispatch({
            type: CREATE_LEAD_FAILURE,
            payload: { error: `Prospecto existente. ID: ${id}` }
          })
          handleErrorsWithAction(error, CREATE_LEAD_FAILURE, dispatch)
          setToastErrorUpdate(toastId, { render: `Prospecto existente. ID: ${id}` })
        } else {
          dispatch({
            type: CREATE_LEAD_FAILURE,
            payload: { error: `Error al guardar la Oportunidad` }
          })
          setToastErrorUpdate(toastId, { render: 'Error al guardar la Oportunidad' })
        }
      }
    },
  editLead:
    (lead: EditProspect): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Editando oportunidad, por favor espere...')
      const request: EditLead = {
        type: EDIT_LEAD_REQUEST
      }

      dispatch(request)

      try {
        await API.Mercurio.Prospect.update(lead)
        const success: EditLeadSuccess = {
          type: EDIT_LEAD_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Oportunidad editada correctamente' })
        Emitter.emit(Events.Leads.LEAD_UPDATED)
      } catch (error: any) {
        dispatch({
          type: EDIT_LEAD_FAILURE,
          payload: { error: `Error al editar la Oportunidad` }
        })
        setToastErrorUpdate(toastId, { render: 'Error al editar la Oportunidad' })
      }
    },
  verifyIfEmailExists:
    ({ email }: { email: string }): AppThunk =>
    async (dispatch) => {
      const lastSearchTimestamp = new Date().getMilliseconds()

      const request: VerifyEmailRequest = {
        type: VERIFY_EMAIL_REQUEST,
        payload: {
          lastSearchTimestamp
        }
      }

      dispatch(request)

      try {
        const { users } = await API.AccountManager.Users.search({ email })
        const { countryId, phone, gender, name, lastName, id } = users[0]

        const success: VerifyEmailSuccess = {
          type: VERIFY_EMAIL_SUCCESS,
          payload: {
            lastSearchTimestamp,
            userData: {
              id,
              countryFullName: modalLeadsActionCreators.getCountryFullName(countryId),
              countryId,
              phone,
              gender,
              name,
              lastName
            }
          }
        }

        if (users.length !== 0) {
          dispatch(success)
        }
      } catch (error) {
        handleErrorsWithAction(error, VERIFY_EMAIL_FAILURE, dispatch)
      }
    },
  getLimitNumber: (countryCode: string): number => {
    let limit: number
    switch (countryCode) {
      case CountryPhoneCode.ARGENTINA:
        limit = 8
        break
      case CountryPhoneCode.SPAIN:
        limit = 9
        break
      default:
        limit = 12
    }

    return limit
  },

  getCountryFullName: (countryCode?: string): CountryName => {
    switch (countryCode) {
      case CountryIdCode.SPAIN:
        return CountryName.SPAIN
      case CountryIdCode.ARGENTINA:
      default:
        return CountryName.ARGENTINA
    }
  }
}

export default modalLeadsActionCreators
