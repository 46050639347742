import camelcaseKeys from 'camelcase-keys'
import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

export type APISolution = {
  id: number
  description: string
}

export type Solution = CamelCasedPropertiesDeep<APISolution>

type APIResponse = APISolution[]

type Response = {
  solutions: Solution[]
}

export function list(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/solutions`)
    .then((response: AxiosResponse<APIResponse>) => {
      return { solutions: camelcaseKeys(response.data, { deep: true }) }
    })
    .catch((error) => {
      throw error
    })
}
