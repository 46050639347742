import { FlagsTypes } from '../types/flags'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { Flag } from '../../../projectApi/ObjectAdministration/Value/getValue'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { getCustomDateTransportCountry, getFlagsByCountry } from '../../../common/operations/flags/utils'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  getConfigurationFlags(countryCode = CountryIdCode.ARGENTINA): AppThunk {
    return async function (dispatch) {
      dispatch({ type: FlagsTypes.FETCH_CONFIGURATION_FLAGS })

      const config = {
        params: {
          country_code: countryCode
        }
      }

      const m3PriceBandsResponse = await API.CategoryCreation.PriceBand.getCurrentPriceBand({
        country: countryCode,
        type: 'm3'
      })

      const customDateTransportFlag = getCustomDateTransportCountry(countryCode)

      const requests = [
        API.ObjectAdministration.Value.getValue({ flag: Flag.MIN_BILLABLE_M3 }, config),
        API.ObjectAdministration.Value.getValue({ flag: customDateTransportFlag }),
        API.ObjectAdministration.Value.getValue({
          flag: getFlagsByCountry(countryCode).extraAssistantPrice
        }),
        API.ObjectAdministration.Value.getValue({ flag: Flag.TOLL_COST_IN_CENTS })
      ]

      return Promise.all(requests)
        .then((responses) => {
          const [minBillableM3Value, customDateTransportMultValue, extraAssistantPrice, tollCost] = responses

          const m3Price = m3PriceBandsResponse.price
          const minBillableM3 = parseInt(minBillableM3Value, 10)
          const customDateTransportMult = parseFloat(customDateTransportMultValue)

          dispatch({
            type: FlagsTypes.FETCH_CONFIGURATION_FLAGS_SUCCESS,
            payload: {
              m3Price,
              minBillableM3,
              customDateTransportMult,
              extraAssistantPrice: parseInt(extraAssistantPrice),
              tollCostInCents: parseFloat(tollCost)
            }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, FlagsTypes.FETCH_CONFIGURATION_FLAGS_FAIL, dispatch)
        })
    }
  }
}

export default actions
