import provincesJson from './provinces.json'

const { provinces } = provincesJson

export const getProvincesName = () => provinces.map((province) => province.iso_nombre)

export const getProvincesLabels = () =>
  provinces.map((province) => ({
    label: province.iso_nombre,
    value: province.iso_nombre
  }))
