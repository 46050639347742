import types from './QRModalTypes'

const actions = {
  openQRModal: (code) => {
    return { type: types.OPEN_QRMODAL, payload: { code } }
  },
  closeQRModal: () => {
    return { type: types.CLOSE_QRMODAL }
  }
}

export default actions
