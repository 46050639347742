export default {
  FETCH_ACTIVITY: 'ACTIVITY/FETCH_ACTIVITY',
  FETCH_ACTIVITY_SUCCESS: 'ACTIVITY/FETCH_ACTIVITY_SUCCESS',
  FETCH_ACTIVITY_FAIL: 'ACTIVITY/FETCH_ACTIVITY_FAIL',
  SET_SORTER: 'ACTIVITY/SET_SORTER',
  SET_FILTER: 'ACTIVITY/SET_FILTER',
  SET_PAGINATION: 'ACTIVITY/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'ACTIVITY/SET_SEARCH_FILTERS',
  SET_UPDATE_MODAL_OPEN: 'ACTIVITY/SET_UPDATE_MODAL_OPEN',
  SET_STATUS: 'ACTIVITY/SET_STATUS_ID',
  SET_REFERENCE: 'ACTIVITY/SET_REFERENCE',
  UPDATE_ACTIVITY: 'ACTIVITY/UPDATE_ACTIVITY',
  UPDATE_ACTIVITY_SUCCESS: 'ACTIVITY/UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_FAIL: 'ACTIVITY/UPDATE_ACTIVITY_FAIL',
  CLEAN_FILTERS: 'ACTIVITY/CLEAN_FILTERS'
}
