export interface CancelSubscriptionModalState {
  modalOpen: boolean
  billingId: number
  error: string
  isLoading: boolean
}
export const SET_MODAL_CANCEL_SUBSCRIPTION = 'BILLINGS/BILLINGS_BY_CLIENT/SET_MODAL_CANCEL_SUBSCRIPTION'

export interface SetModalCancelSubscription {
  type: typeof SET_MODAL_CANCEL_SUBSCRIPTION
  payload: {
    modalOpen: boolean
    billingId?: number
  }
}

export const CANCEL_SUBSCRIPTION_REQUEST = 'BILLINGS/BILLINGS_BY_CLIENT/CANCEL_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'BILLINGS/BILLINGS_BY_CLIENT/CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAILURE = 'BILLINGS/BILLINGS_BY_CLIENT/CANCEL_SUBSCRIPTION_FAILURE'

export interface CancelSubscriptionRequest {
  type: typeof CANCEL_SUBSCRIPTION_REQUEST
}

export interface CancelSubscriptionSuccess {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS
}

export interface CancelSubscriptionFailure {
  type: typeof CANCEL_SUBSCRIPTION_FAILURE
  payload: {
    error: string
  }
}

export type CancelSubscriptionModalAction =
  | SetModalCancelSubscription
  | CancelSubscriptionRequest
  | CancelSubscriptionSuccess
  | CancelSubscriptionFailure
