export enum ProvidersEnum {
  CLEAN_FILTERS = 'PROVIDERS/CLEAN_FILTERS',
  CREATE_PROVIDER = 'CREATE_PROVIDER',
  DELETE_PROVIDER = 'DELETE_PROVIDER',
  EDIT_PROVIDER = 'EDIT_PROVIDER'
}

export type ProvidersEvents = {
  [ProvidersEnum.CLEAN_FILTERS]: () => void
  [ProvidersEnum.CREATE_PROVIDER]: () => void
  [ProvidersEnum.DELETE_PROVIDER]: () => void
  [ProvidersEnum.EDIT_PROVIDER]: () => void
}
