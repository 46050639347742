import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export interface Description {
  administrative_level_1: string
  administrative_level_2: string
  destination: string
  price: number
  rule: TransportDetail
}

export interface TransportDetail {
  countryId: string
  description: string
  distanceKm: number
  maxHeightM: number
  maxLengthInM: number
  maxWeightM: number
  maxWidthM: number
  volumeM3: number
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = CamelCasedPropertiesDeep<Description>

interface FetchCostParams {
  destination: string
  type: string
  items: string
  countryCode: string
  m3: number
}

export function cost({ destination, type, items, countryCode, m3 }: FetchCostParams): Promise<Response> {
  const params = snakecaseKeys({ destination, type, items, countryCode, m3 })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/compute/trip/cost`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.success) throw new Error('No se pudo obtener el costo')
      if (!response.data?.description) throw new Error('Respuesta incorrecta al obtener el costo')

      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
