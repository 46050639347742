import { STATUS_VALUES } from '../../common/operationsTable/constants'

export const STATUS_FILTERS = [
  { text: 'Confirmada', value: STATUS_VALUES.CONFIRMED },
  { text: 'Pendiente de pago', value: STATUS_VALUES.PENDING_PAYMENT },
  { text: 'Autorizada', value: STATUS_VALUES.AUTHORIZED },
  { text: 'En viaje', value: STATUS_VALUES.IN_TRIP },
  { text: 'Programada', value: STATUS_VALUES.PROGRAMMED },
  { text: 'Programada (M)', value: STATUS_VALUES.PROGRAMMED_M },
  { text: 'Cerrada total', value: STATUS_VALUES.CLOSED_TOTALLY },
  { text: 'Cerrada parcial', value: STATUS_VALUES.CLOSED_PARTIALLY }
]

export const STATUS_FILTERS_PROPS = [
  {
    text: 'Confirmada',
    value: STATUS_VALUES.CONFIRMED,
    styleProps: {
      borderColor: '#175CD3',
      backgroundColor: '#EFF8FF'
    }
  },
  {
    text: 'Pendiente de pago',
    value: STATUS_VALUES.PENDING_PAYMENT,
    styleProps: {
      borderColor: '#C11574',
      backgroundColor: '#FDF2FA'
    }
  },
  {
    text: 'Autorizada',
    value: STATUS_VALUES.AUTHORIZED,
    styleProps: {
      borderColor: '#137617',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Programada',
    value: STATUS_VALUES.PROGRAMMED,
    styleProps: {
      borderColor: '#027A65',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'Programada (M)',
    value: STATUS_VALUES.PROGRAMMED_M,
    styleProps: {
      borderColor: '#027A65',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    text: 'En viaje',
    value: STATUS_VALUES.IN_TRIP,
    styleProps: {
      borderColor: '#C19623',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    text: 'Cerrada total',
    value: STATUS_VALUES.CLOSED_TOTALLY,
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    text: 'Cerrada parcial',
    value: STATUS_VALUES.CLOSED_PARTIALLY,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  }
]

export enum DebtsStatus {
  WITH_DEBTS = 'with_debts',
  NO_DEBTS = 'no_debts'
}

export enum CloseRemovalTypes {
  PARTIALLY = 'partially',
  TOTALLY = 'totally'
}
