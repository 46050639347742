type GeocoderAddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export function findAddressComponent(
  components: GeocoderAddressComponent[],
  type: string,
  name: 'short_name' | 'long_name' = 'short_name'
): string {
  const component = components.find((comp) => comp.types.includes(type))
  return component ? component[name] : ''
}
