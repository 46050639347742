import React, { useEffect } from 'react'
import VehiclesTable from '../components/vehiclesTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import VehicleListActionsCreator from '../actions/vehiclesTable'
import ABMVehicleActionsCreator from '../actions/ABMVehicles'
import { VehiclesSearchFilter, VehiclesTableSearchKey, VehiclesTableSort } from '../types/vehiclesTable'
import { Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../components/TableActionBar'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { VehiclesListSortKey } from '../../../projectApi/Trips/Vehicles/list'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { removeNullishValues, updateMultipleSearchFilters } from '../../../components/DynamicTable/queryParamsUtils'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { Vehicle } from '../../../projectApi/Trips/Vehicles/common'
import { Edit, Trash } from '../../../icons'
import ABMVehiclesContainer from './ABMVehicles'

const queryParams = {
  page: NumberParam,
  sortField: StringParam,
  sortDirection: StringParam,
  id: StringParam,
  providerId: StringParam
}

const VehiclesTableContainer = () => {
  const dispatch = useDispatch()

  const [query, setQuery] = useQueryParams(queryParams)
  const { getVehicles, sendVehiclesCSV, resetFilters } = bindActionCreators(VehicleListActionsCreator, dispatch)
  const { getProviders, setSelectedVehicle, setModalVisibility, setDeleteModalVisibility } = bindActionCreators(
    ABMVehicleActionsCreator,
    dispatch
  )

  const { sendingCSV, vehicles, fetchingVehicles, ...tableState } = useSelector((state) => state.VehiclesReducer.table)

  const handleGetVehicles = (newParams: {
    pagination?: Pagination
    searchFilters?: VehiclesSearchFilter[]
    sort?: VehiclesTableSort
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      sort: newParams.sort || tableState.sort
    }
    getVehicles(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      sortDirection: actualParams.sort.direction,
      sortField: actualParams.sort.field
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  useEffect(() => {
    handleGetVehicles({
      pagination: tableState.pagination,
      sort:
        query.sortField && query.sortDirection
          ? {
              field: query.sortField as VehiclesListSortKey,
              direction: query.sortDirection as SortDirection
            }
          : tableState.sort,
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: 'id', text: query.id },
        { key: 'providerId', text: query.providerId }
      ])
    })

    return () => {
      resetFilters()
    }
  }, [])

  useEvents(
    [
      Events.Global.CHANGE_COUNTRY,
      Events.Vehicles.CLEAN_FILTERS,
      Events.Vehicles.CREATE_VEHICLE,
      Events.Vehicles.DELETE_VEHICLE
    ],
    () => {
      handleGetVehicles({ pagination: { ...tableState.pagination, page: 1 } })
    }
  )

  const handleSendCSV = () => {
    sendVehiclesCSV({
      pagination: tableState.pagination,
      searchFilters: tableState.searchFilters
    })
  }

  const handleSort = (newSort: VehiclesTableSort) => {
    handleGetVehicles({ sort: newSort })
  }

  const handlePageChange = (newPage: number) => {
    handleGetVehicles({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: VehiclesTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetVehicles({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const menuActions: MenuAction<Vehicle>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (vehicle) => {
        setModalVisibility({ modalVisible: true, editMode: true })
        setSelectedVehicle(vehicle)
      }
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (vehicle) => setDeleteModalVisibility(true, vehicle)
    }
  ]

  const props = {
    fetchingVehicles,
    vehicles,
    sort: tableState.sort,
    searchFilters: tableState.searchFilters,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    handleSort,
    actions: menuActions
  }

  return (
    <>
      <TableActionBar customStyles={{ margin: '8px 0 16px 0' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={() => resetFilters()} />
          <TableActionBar.ButtonAction toolTip variant="csv" disabled={sendingCSV} onClickButton={handleSendCSV} />
        </div>
        <TableActionBar.ButtonAction
          titleButton="Crear vehículo"
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar>
      <ABMVehiclesContainer />
      <VehiclesTable {...props} />
    </>
  )
}

export default VehiclesTableContainer
