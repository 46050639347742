import { Country, CountryIdCode } from '../constants/constants'
import { ValueOf } from 'type-fest'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentCountry } from '../utils/utils'

export const useCountries = () => useSelector((state) => state.CountrySelector.countries)

export const useCountryInfo = (
  value: ValueOf<Country> = CountryIdCode.ARGENTINA,
  key: keyof Country = 'code'
): Country => {
  const countries = useCountries()
  return useMemo(() => getCurrentCountry(countries, value, key), [countries, value, key])
}
