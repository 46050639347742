import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import RequestViewActionCreators from '../actionCreators/salesRequestView'
import SaleModal from '../components/SaleModal'
import { useHistory } from 'react-router'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { setSellOpenModal, sellObjectRequest } = bindActionCreators(RequestViewActionCreators, dispatch)
  const salesRequestViewState = useSelector((state) => state.SalesRequest.view)
  const { salesRequest } = salesRequestViewState

  const history = useHistory()

  const closeModal = () => {
    setSellOpenModal(false)
  }

  const sellRequestHandler = async () => {
    if (salesRequest && salesRequest.id) {
      const result = await sellObjectRequest(salesRequest.id)
      // @ts-ignore
      if (result) {
        history.goBack()
      }
    }
  }

  const props = {
    salesRequestViewState,
    closeModal,
    sellRequestHandler
  }

  return <SaleModal {...props} />
}

export default Container
