import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Attribute } from './AttributesTable'

export type AllowedCountriesCodes = CountryIdCode.ARGENTINA | CountryIdCode.SPAIN

export const DATA_TYPE_SELECT = [
  { value: 'options', label: 'Opciones' },
  { value: 'string', label: 'Texto' },
  { value: 'number', label: 'Número' },
  { value: 'boolean', label: 'Sí/No' }
]

export const DATA_TYPE_OPTIONS = {
  options: 'Opciones',
  string: 'Texto',
  number: 'Número',
  boolean: 'Sí/No'
}

export const DEFAULT_TO_OPEN_OPTIONS = 'options'

export const COUNTRY_OPTIONS_SELECT = [
  { value: 'string', label: 'Texto' },
  { value: 'number', label: 'Número' }
]

export const UNITS_SELECT = [
  { value: 'liters', label: 'Litros' },
  { value: 'm3', label: 'M3' },
  { value: 'cm3', label: 'CM3' },
  { value: 'meter', label: 'Metros' },
  { value: 'centimeter', label: 'Centímetro' }
]

export type CountryOption = {
  id: number | null
  dataType: string
  value: string
  deleted?: boolean
}

export type CountriesState = {
  [CountryIdCode.ARGENTINA]: AttributeByCountriesInformation
  [CountryIdCode.SPAIN]: AttributeByCountriesInformation
}

export type AttributeByCountriesInformation = {
  label: string
  options: CountryOption[]
}

export type NewEditAttributeState = {
  id: number | null
  isVisible: boolean
  dataType: string
  name: string
  unit: string
  countries: CountriesState
  loading: boolean
  error: string
}

export const SET_VISIBLE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_VISIBLE'
export const SET_DATA_TYPE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_DATA_TYPE'
export const SET_NAME = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_NAME'
export const SET_UNIT = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_UNIT'

export const SET_COUNTRY_LABEL = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_COUNTRY_LABEL'
export const SET_COUNTRY_DATA_TYPE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_COUNTRY_DATA_TYPE'
export const SET_COUNTRY_DATA_TYPE_VALUE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_COUNTRY_DATA_TYPE_VALUE'
export const SET_COUNTRY_ADD_OPTION = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_COUNTRY_ADD_OPTION'
export const SET_COUNTRY_REMOVE_OPTION = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/SET_COUNTRY_REMOVE_OPTION'

export interface SetVisible {
  type: typeof SET_VISIBLE
  payload: {
    isVisible: boolean
  }
}

export interface SetDataType {
  type: typeof SET_DATA_TYPE
  payload: {
    dataType: string
  }
}
export interface SetName {
  type: typeof SET_NAME
  payload: {
    name: string
  }
}
export interface SetUnit {
  type: typeof SET_UNIT
  payload: {
    unit: string
  }
}

export interface SetCountryLabel {
  type: typeof SET_COUNTRY_LABEL
  payload: {
    country: AllowedCountriesCodes
    label: string
  }
}

export interface SetCountryDataType {
  type: typeof SET_COUNTRY_DATA_TYPE
  payload: {
    country: AllowedCountriesCodes
    dataType: string
    index: number
  }
}

export interface SetCountryDataTypeValue {
  type: typeof SET_COUNTRY_DATA_TYPE_VALUE
  payload: {
    country: AllowedCountriesCodes
    value: string
    index: number
  }
}
export interface SetCountryAddOption {
  type: typeof SET_COUNTRY_ADD_OPTION
  payload: {
    country: AllowedCountriesCodes
    option: CountryOption
  }
}

export interface SetCountryRemoveOption {
  type: typeof SET_COUNTRY_REMOVE_OPTION
  payload: {
    country: AllowedCountriesCodes
    index: number
  }
}

export const CREATE_NEW_ATTRIBUTE_REQUEST = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/CREATE_NEW_ATTRIBUTE_REQUEST'
export const CREATE_NEW_ATTRIBUTE_SUCCESS = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/CREATE_NEW_ATTRIBUTE_SUCCESS'
export const CREATE_NEW_ATTRIBUTE_FAILURE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/CREATE_NEW_ATTRIBUTE_FAILURE'

export interface CreateNewAttributeRequest {
  type: typeof CREATE_NEW_ATTRIBUTE_REQUEST
}

export interface CreateNewAttributeSuccess {
  type: typeof CREATE_NEW_ATTRIBUTE_SUCCESS
}

export interface CreateNewAttributeFailure {
  type: typeof CREATE_NEW_ATTRIBUTE_FAILURE
  payload: {
    error: string
  }
}

export const CLEAR_STATE = 'ATTRIBUTES/CLEAR_STATE'

export const GET_ATTRIBUTE_REQUEST = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/GET_ATTRIBUTE_REQUEST'
export const GET_ATTRIBUTE_SUCCESS = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/GET_ATTRIBUTE_SUCCESS'
export const GET_ATTRIBUTE_FAILURE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/GET_ATTRIBUTE_FAILURE'

export interface GetAttributesRequest {
  type: typeof GET_ATTRIBUTE_REQUEST
  payload: {
    id: number
  }
}
export interface GetAttributesSuccess {
  type: typeof GET_ATTRIBUTE_SUCCESS
  payload: {
    attribute: Attribute
  }
}

export interface GetAttributesFailure {
  type: typeof GET_ATTRIBUTE_FAILURE
  payload: {
    error: string
  }
}

export const UPDATE_ATTRIBUTE_REQUEST = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/UPDATE_ATTRIBUTE_REQUEST'
export const UPDATE_ATTRIBUTE_SUCCESS = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/UPDATE_ATTRIBUTE_SUCCESS'
export const UPDATE_ATTRIBUTE_FAILURE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/UPDATE_ATTRIBUTE_FAILURE'

export interface UpdateAttributeRequest {
  type: typeof UPDATE_ATTRIBUTE_REQUEST
}

export interface UpdateAttributeSuccess {
  type: typeof UPDATE_ATTRIBUTE_SUCCESS
}

export interface UpdateAttributeFailure {
  type: typeof UPDATE_ATTRIBUTE_FAILURE
  payload: {
    error: string
  }
}

export const UPDATE_COUNTRY_DATATYPE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/UPDATE_COUNTRY_DATATYPE'
export const UPDATE_COUNTRY_VALUE = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/UPDATE_COUNTRY_VALUE'
export const DELETE_COUNTRY_OPTION = 'ATTRIBUTES/NEW_EDIT_ATTRIBUTE/DELETE_COUNTRY_OPTION'

export interface UpdateCountryDataType {
  type: typeof UPDATE_COUNTRY_DATATYPE
  payload: {
    country: AllowedCountriesCodes
    id: number
    dataType: string
  }
}

export interface UpdateCountryValue {
  type: typeof UPDATE_COUNTRY_VALUE
  payload: {
    country: AllowedCountriesCodes
    id: number
    value: string
  }
}

export interface DeleteCountryOption {
  type: typeof DELETE_COUNTRY_OPTION
  payload: {
    country: AllowedCountriesCodes
    id: number
  }
}

export interface ClearState {
  type: typeof CLEAR_STATE
}

export type NewEditAttributeActions =
  | SetVisible
  | SetDataType
  | SetName
  | SetUnit
  | SetCountryLabel
  | SetCountryAddOption
  | SetCountryRemoveOption
  | CreateNewAttributeRequest
  | CreateNewAttributeSuccess
  | CreateNewAttributeFailure
  | ClearState
  | GetAttributesRequest
  | GetAttributesSuccess
  | GetAttributesFailure
  | UpdateCountryDataType
  | UpdateCountryValue
  | DeleteCountryOption
  | SetCountryDataType
  | SetCountryDataTypeValue
