import { CorporativeAPI } from './Corporative'
import { create } from './create'
import { deleteLocation } from './delete'
import { edit } from './edit'
import { listTypes } from './listTypes'

export const LocationsAPI = {
  Corporative: CorporativeAPI,
  create,
  edit,
  delete: deleteLocation,
  listTypes
}
