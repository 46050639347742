export const itemsPaginationMergeFunction = (existing: any, incoming: any, { args }: { args: any }) => {
  const page: number = args?.options?.page ?? 1
  const limit: number | undefined = args?.options?.limit
  const limitInternal: number | undefined = args?.options?.limitInternal

  const mainLimit = limitInternal ?? limit

  const existingItems = existing?.items ?? []
  const incomingItems = incoming?.items ?? []

  const mergedItems = existingItems.slice(0)

  if (page && mainLimit) {
    const offset = (page - 1) * mainLimit
    for (let i = 0; i < incomingItems.length; ++i) {
      mergedItems[offset + i] = incomingItems[i]
    }
  } else {
    mergedItems.push(...incomingItems)
  }

  const fullResult = {
    ...incoming,
    items: mergedItems
  }

  return fullResult
}
