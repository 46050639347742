import {
  AutomaticPriceOperationType,
  GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE,
  GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST,
  GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS,
  GetAutomaticPricePerFloorRequest,
  GetAutomaticPricePerFloorSuccess,
  PRODUCT_COST_CLEAN_STATE,
  ProductCostCleanState,
  AllowedCountriesCodes,
  EligibilityFields,
  ProductByCountriesInformation,
  ProductCostsState,
  SET_BOOLEAN_FIELD,
  SET_PRODUCT_ELIGIBILITY,
  SET_STRING_FIELD,
  SetBooleanField,
  SetProductEligibility,
  SetStringField
} from '../types/productCosts'

import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE,
  CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST,
  CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS,
  CREATE_NEW_PRODUCT_FAILURE,
  CREATE_NEW_PRODUCT_REQUEST,
  CREATE_NEW_PRODUCT_SUCCESS,
  CreateNewProductByCatalogRequest,
  CreateNewProductByCatalogSuccess,
  CreateNewProductRequest,
  CreateNewProductSuccess,
  GeneralDataCreateStates,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UpdateProductRequest,
  UpdateProductSuccess
} from '../types/common'

import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import eventEmitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { ProductByCatalog } from '@/projectApi/CategoryCreation/Catalog/create'

export const ProductCostsActionCreator = {
  setStringField: ({
    value,
    country,
    field
  }: {
    value: string
    country: AllowedCountriesCodes
    field: keyof ProductByCountriesInformation
  }): SetStringField => ({
    type: SET_STRING_FIELD,
    payload: {
      country,
      value,
      field
    }
  }),
  setBooleanFields: ({
    value,
    country,
    field
  }: {
    value: boolean
    country: AllowedCountriesCodes
    field: keyof ProductByCountriesInformation
  }): SetBooleanField => ({
    type: SET_BOOLEAN_FIELD,
    payload: {
      country,
      value,
      field
    }
  }),
  setProductEligibility: ({
    value,
    field,
    country
  }: {
    field: EligibilityFields
    value: boolean
    country: AllowedCountriesCodes
  }): SetProductEligibility => ({
    type: SET_PRODUCT_ELIGIBILITY,
    payload: { value, field, country }
  }),
  createNewProduct:
    (generalDataCreateStates: GeneralDataCreateStates, productCosts: ProductCostsState): AppThunk =>
    async (dispatch) => {
      const request: CreateNewProductRequest = {
        type: CREATE_NEW_PRODUCT_REQUEST
      }
      dispatch(request)
      const toast = setToastLoading('Creando producto...')

      try {
        await API.CategoryCreation.Product.create({ generalDataCreateStates, productCosts })

        const success: CreateNewProductSuccess = {
          type: CREATE_NEW_PRODUCT_SUCCESS
        }

        setToastSuccessUpdate(toast, { render: 'Producto creado exitosamente' })
        dispatch(success)
        eventEmitter.emit(Events.NewProduct.PRODUCT_CREATED)
      } catch (error) {
        const message = handleErrorsWithAction(error, CREATE_NEW_PRODUCT_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: message })
      }
    },
  getAutomaticPricePerFloor:
    ({
      countryId,
      operationType,
      m3
    }: {
      countryId: AllowedCountriesCodes
      operationType: keyof AutomaticPriceOperationType
      m3: number
    }): AppThunk =>
    async (dispatch) => {
      const request: GetAutomaticPricePerFloorRequest = {
        type: GET_AUTOMATIC_PRICE_PER_FLOOR_REQUEST
      }

      dispatch(request)

      try {
        const { price } = await API.CategoryCreation.getStairsPrice({ countryId, operationType, m3 })

        const success: GetAutomaticPricePerFloorSuccess = {
          type: GET_AUTOMATIC_PRICE_PER_FLOOR_SUCCESS,
          payload: { automaticPrice: price, countryId, operationType }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_AUTOMATIC_PRICE_PER_FLOOR_FAILURE, dispatch)
      }
    },
  productCostCleanState: (): ProductCostCleanState => ({
    type: PRODUCT_COST_CLEAN_STATE
  }),
  updateProduct:
    ({
      generalData,
      productCosts
    }: {
      generalData: GeneralDataCreateStates
      productCosts: ProductCostsState
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateProductRequest = {
        type: UPDATE_PRODUCT_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Editando producto...')

      try {
        await API.CategoryCreation.Product.update({ generalData, productCosts })
        const success: UpdateProductSuccess = {
          type: UPDATE_PRODUCT_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Producto editado exitosamente' })
        eventEmitter.emit(Events.NewProduct.PRODUCT_UPDATED)
      } catch (err) {
        const message = handleErrorsWithAction(err, UPDATE_PRODUCT_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: message })
      }
    },
  createProductByCatalog:
    (productByCatalog: ProductByCatalog): AppThunk =>
    async (dispatch) => {
      const request: CreateNewProductByCatalogRequest = {
        type: CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST
      }
      dispatch(request)

      try {
        await API.CategoryCreation.Catalog.create(productByCatalog)
        const success: CreateNewProductByCatalogSuccess = {
          type: CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS
        }
        dispatch(success)
        eventEmitter.emit(Events.NewProduct.PRODUCT_CREATED_BY_CATALOG)
      } catch (err) {
        handleErrorsWithAction(err, CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE, dispatch)
      }
    }
}
