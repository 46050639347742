import { CleanStates, GetTransactionDataAction } from './EditDepositView'

export interface AditionalsInitialState {
  extraServices: {
    shipmentAssistants: {
      checkedAssistants: boolean
      shipmentAssistantCount: number
      shipmentAssistantPrice: number
    }
  }
  loading: boolean
}

export const GET_ADDITIONAL_FLAGS_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_ADDITIONAL_FLAGS_REQUEST'
export const GET_ADDITIONAL_FLAGS_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_ADDITIONAL_FLAGS_SUCCESS'
export const GET_ADDITIONAL_FLAGS_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_ADDITIONAL_FLAGS_FAILURE'

export interface GetAdditionalFlagsRequest {
  type: typeof GET_ADDITIONAL_FLAGS_REQUEST
}

export interface GetAdditionalFlagsSuccess {
  type: typeof GET_ADDITIONAL_FLAGS_SUCCESS
  payload: {
    extraAssistantPrice: number
  }
}

export interface GetAdditionalFlagsFailure {
  type: typeof GET_ADDITIONAL_FLAGS_FAILURE
}

export type GetAdditionalFlagsActions =
  | GetAdditionalFlagsRequest
  | GetAdditionalFlagsSuccess
  | GetAdditionalFlagsFailure

export const SET_DISABLED_SHIPMENT_ASSISTANTS = 'DEPOSIT/EDIT_DEPOSIT/SET_DISABLED_SHIPMENT_ASSISTANTS'
export const SET_UPDATED_EXTRA_VALUE = 'DEPOSIT/EDIT_DEPOSIT/SET_UPDATED_EXTRA_VALUE'

export interface SetDisabledShipmentAssistants {
  type: typeof SET_DISABLED_SHIPMENT_ASSISTANTS
}

export interface SetUpdatedExtraValue {
  type: typeof SET_UPDATED_EXTRA_VALUE
  payload: {
    updatedValue: number
  }
}

export type SetDataAditionalsActions = SetDisabledShipmentAssistants | SetUpdatedExtraValue

export type AditionalsActions =
  | SetDataAditionalsActions
  | GetTransactionDataAction
  | GetAdditionalFlagsActions
  | CleanStates
