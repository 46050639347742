import { clone } from 'ramda'
import { Reducer } from 'redux'
import { DepositStatus } from '../../entities'
import {
  CLEAN_STATES,
  FETCH_CONFIGURATION_FLAGS_FAILURE,
  FETCH_CONFIGURATION_FLAGS_REQUEST,
  FETCH_CONFIGURATION_FLAGS_SUCCESS,
  GET_TRANSACTION_DATA_FAILURE,
  GET_TRANSACTION_DATA_REQUEST,
  GET_TRANSACTION_DATA_SUCCESS,
  GetModifyReasonsTypes,
  SET_MODIFY_REASON,
  TransactionDataActions,
  TransactionState
} from '../../types/EditDepositViewTypes/EditDepositView'

const initialTransactionState: TransactionState = {
  transaction: {
    generalData: {
      id: 0,
      guid: '',
      status: DepositStatus.INITIAL,
      countryId: '',
      createdAt: '',
      user: {
        id: 0,
        lastName: '',
        name: ''
      }
    },
    modifyReason: {
      reasonId: 0,
      reasonsList: [],
      loadingReasons: false,
      errorMessage: ''
    },
    flags: {
      loadingData: false,
      storageCost: 110,
      minBillableM3: 1,
      minBillableM3Price: 0,
      customDateTransportMult: 1
    }
  },
  loading: false
}

const EditDepositViewReducer: Reducer<TransactionState, TransactionDataActions> = (
  state = initialTransactionState,
  action
): TransactionState => {
  switch (action.type) {
    case CLEAN_STATES:
      return initialTransactionState
    case GET_TRANSACTION_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_TRANSACTION_DATA_SUCCESS: {
      const stateTransactionClone = clone(state.transaction)

      stateTransactionClone.generalData = {
        ...state.transaction.generalData,
        ...action.payload.transaction.generalData
      }

      return {
        ...state,
        transaction: {
          ...state.transaction,
          generalData: stateTransactionClone.generalData,
          modifyReason: {
            ...state.transaction.modifyReason,
            reasonId: action.payload.transaction.modifyReason?.reasonId ?? 0
          }
        },
        loading: false
      }
    }
    case GET_TRANSACTION_DATA_FAILURE:
      return {
        ...state,
        loading: false
      }
    case FETCH_CONFIGURATION_FLAGS_REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          flags: {
            ...state.transaction.flags,
            loadingData: true
          }
        }
      }
    case FETCH_CONFIGURATION_FLAGS_SUCCESS: {
      const { minBillableM3, minBillableM3Price, customDateTransportMult } = action.payload
      return {
        ...state,
        transaction: {
          ...state.transaction,
          flags: {
            ...state.transaction.flags,
            loadingData: false,
            storageCost: minBillableM3Price,
            minBillableM3,
            minBillableM3Price,
            customDateTransportMult
          }
        }
      }
    }
    case FETCH_CONFIGURATION_FLAGS_FAILURE:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          flags: {
            ...state.transaction.flags,
            loadingData: false
          }
        }
      }
    case GetModifyReasonsTypes.GET_MODIFY_REASONS_REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          modifyReason: {
            ...state.transaction.modifyReason,
            loadingReasons: true,
            errorMessage: ''
          }
        }
      }
    case GetModifyReasonsTypes.GET_MODIFY_REASONS_SUCCESS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          modifyReason: {
            ...state.transaction.modifyReason,
            reasonsList: action.payload.reasonsList,
            loadingReasons: false,
            errorMessage: ''
          }
        }
      }
    case GetModifyReasonsTypes.GET_MODIFY_REASONS_FAILURE:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          modifyReason: {
            ...state.transaction.modifyReason,
            loadingReasons: false,
            errorMessage: action.payload.errorMessage
          }
        }
      }
    case SET_MODIFY_REASON:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          modifyReason: {
            ...state.transaction.modifyReason,
            reasonId: action.payload.reasonId
          }
        }
      }
    default:
      return state
  }
}

export default EditDepositViewReducer
