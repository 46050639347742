import React from 'react'

function RowBackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" fill="none" viewBox="0 0 18 14">
      <path
        stroke="#777692"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 7H1m0 0l6 6M1 7l6-6"></path>
    </svg>
  )
}

export default RowBackIcon
