import types from '../types/identificationTypes'

const initialIdentificationTypes = {
  identificationTypes: [],
  fetched: false,
  loading: false,
  error: ''
}

function identificationTypesReducer(state = initialIdentificationTypes, action) {
  switch (action.type) {
    case types.GET_IDENTIFICATION_TYPES_REQUEST: {
      return { ...state, loading: true }
    }
    case types.GET_IDENTIFICATION_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        fetched: true,
        identificationTypes: action.payload.identificationTypes
      }
    }
    case types.GET_IDENTIFICATION_TYPES_FAILURE: {
      return { ...state, loading: false, error: action.payload.error }
    }
    default:
      return state
  }
}

export default identificationTypesReducer
