import { BaseOption } from '@/components/Select/Select'

export type CancelSaleModalStateType = {
  id: string
  name: string
  lastName: string
  isOpen: boolean
  isLoading: boolean
  error: string
}

export const CancelOptions: BaseOption<number>[] = [
  {
    value: 1,
    label: 'El cliente solicitó la cancelación.'
  },
  {
    value: 2,
    label: 'La empresa canceló la solicitud.'
  },
  {
    value: 3,
    label: 'Cambio de precio.'
  },
  {
    value: 4,
    label: 'Test'
  }
]

export enum CommonTypes {
  SET_LOADING = 'SALES_RESQUEST/CANCEL_SALE_MODAL/SET_LOADING',
  SET_OPEN = 'SALES_RESQUEST/CANCEL_SALE_MODAL/SET_OPEN',
  CLEAN_STATE = 'SALES_RESQUEST/CANCEL_SALE_MODAL/CLEAN_STATE'
}

export interface SetLoading {
  type: CommonTypes.SET_LOADING
  payload: {
    isLoading: boolean
  }
}

export interface SetOpen {
  type: CommonTypes.SET_OPEN
  payload: {
    isOpen: boolean
    name?: string
    lastName?: string
    id?: string
  }
}

export interface CleanState {
  type: CommonTypes.CLEAN_STATE
}

type CommonListActions = SetLoading | SetOpen | CleanState

export enum CancelSaleModalTypes {
  CANCEL_SALE_REQUEST = 'SALES_RESQUEST/CANCEL_SALE_MODAL/CANCEL_SALE_REQUEST',
  CANCEL_SALE_SUCCESS = 'SALES_RESQUEST/CANCEL_SALE_MODAL/CANCEL_SALE_SUCCESS',
  CANCEL_SALE_FAILURE = 'SALES_RESQUEST/CANCEL_SALE_MODAL/CANCEL_SALE_FAILURE'
}

export interface CancelSaleRequest {
  type: CancelSaleModalTypes.CANCEL_SALE_REQUEST
}

export interface CancelSaleSuccess {
  type: CancelSaleModalTypes.CANCEL_SALE_SUCCESS
}

export interface CancelSaleFailure {
  type: CancelSaleModalTypes.CANCEL_SALE_FAILURE
  payload: {
    error: string
  }
}

export type CancelSaleActions = CancelSaleRequest | CancelSaleSuccess | CancelSaleFailure

export type CancelSaleModalActions = CommonListActions | CancelSaleActions
