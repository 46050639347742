import { Moment } from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { getRangePickerProps, getSearchProps, getSortProps } from '../../../components/DynamicTable/utils'
import {
  Operation,
  OperationType,
  Transaction,
  TransactionForecastMetrics,
  TypeTransaction
} from '../../../projectApi/TransactionHandler/Transactions/list'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { formatDate } from '../../../utils/formatDate'
import {
  FormattedForecast,
  TransactionsTableCategoryFilter,
  TransactionsTableCategoryFilterKey,
  TransactionsTableDateRangeFilter,
  TransactionsTableDateRangeKey,
  TransactionsTableSearchFilter,
  TransactionsTableSearchKey,
  TransactionsTableSort
} from '../types/TransactionsTable'
import { TransactionStatusId, TransactionTypeId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { TransactionStatusBadges } from '../../Trips/constants'
import ColorBadge from '../../../components/ColorBadge'
import ActionBar from '../../../components/ActionBar/ActionBar'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import InfoPopup from '../../../components/InfoPopup'
import { Button, Tooltip } from 'antd'
import StatCard from '../../../components/StatCard'
import styles from './TransactionsTable.module.scss'
import { ArrowFromBracket, ArrowToBracket, Truck } from '../../../icons'
import { OperationStatus } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { formatNumber } from '../../../utils/formatNumber'
import { OPERATION_TYPES, TRANSACTION_STATUS_OPTIONS, TRANSACTION_TYPE_OPTIONS } from '../constants'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import { useCountries } from '../../../components/CountrySelector/hooks/hooks'
import { Metrics, MetricsLabels } from '../../../projectApi/TransactionHandler/Transactions/Metrics/list'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { TableActionBar } from '../../../components/TableActionBar'

export const TRANSACTION_TYPES = [
  { text: 'Pickup alquiler', value: TransactionTypeId.PICKUP_ALQUILER },
  { text: 'Delivery alquiler', value: TransactionTypeId.DELIVERY_ALQUILER },
  { text: 'Pickup deposito', value: TransactionTypeId.PICKUP_DEPOSITO },
  { text: 'Delivery deposito', value: TransactionTypeId.DELIVERY_DEPOSITO },
  { text: 'Pickup deposito existente', value: TransactionTypeId.PICKUP_DEPOSITO_EXISTENTE }
]

export const transactionCanBeSelected = (tx: Transaction) =>
  tx.status.id === TransactionStatusId.PENDIENTE || tx.status.id === TransactionStatusId.LISTO_PARA_VIAJAR

export type TransactionsTableProps = {
  setConflict: (conflict: boolean) => void
  conflict: boolean
  error: string
  metrics: Metrics
  loadingMetrics: boolean
  loadingReport: boolean
  transactions: Transaction[]
  loadingTransactions: boolean
  selectedTransactions: Transaction[]
  pagination: Pagination
  categoryFilter: TransactionsTableCategoryFilter
  searchFilters: TransactionsTableSearchFilter[]
  dateRangeFilters: TransactionsTableDateRangeFilter[]
  sort: TransactionsTableSort
  noCountrySelected: boolean
  totalForecast: FormattedForecast
  handleOpenTripModal: () => void
  handleToggleTransaction: (tx: Transaction) => void
  handleClearSelectedTransactions: () => void
  handleSort: (newSorter: TransactionsTableSort) => void
  handleResetFilters: () => void
  handleSearch: (key: TransactionsTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newFilter: TransactionsTableCategoryFilter) => void
  handleOpenRemittanceModal: (txId: number) => void
  handleRangePicker: (key: TransactionsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleToggleAllTransactions: () => void
  handleReport: () => void
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  transactions,
  loadingTransactions,
  selectedTransactions,
  pagination,
  categoryFilter,
  searchFilters,
  dateRangeFilters,
  sort,
  metrics,
  noCountrySelected,
  totalForecast,
  error,
  loadingMetrics,
  loadingReport,
  conflict,
  handleOpenTripModal,
  handleToggleTransaction,
  handleClearSelectedTransactions,
  handleToggleAllTransactions,
  handleSort,
  handleResetFilters,
  handleSearch,
  handleCategoryFilter,
  handleRangePicker,
  handleOpenRemittanceModal,
  handleReport,
  setConflict
}) => {
  const countries = useCountries()

  const handleForecastData = (operation: Operation, forecast: TransactionForecastMetrics) => {
    const totalObjects =
      operation.operationCoreType === OperationType.DEPOSIT && operation.status !== OperationStatus.VALIDATED
        ? forecast.quantityItems
        : forecast.quantityObjects
    const quantityM3 =
      operation.operationCoreType === OperationType.DEPOSIT && operation.status !== OperationStatus.VALIDATED
        ? forecast.quantityM3Items
        : forecast.quantityM3Objects

    return { totalObjects, quantityM3 }
  }
  const columns: Column<Transaction>[] = [
    {
      key: 'CountryCode',
      label: 'País',
      renderDataCell: ({ operation }) => {
        const flag = getCurrentCountry(countries, operation.countryId)
        if (!flag) return '-'
        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID TX',
      minWidth: 'max-content',
      sort: getSortProps('ID', sort, handleSort),
      search: getSearchProps(TransactionsTableSearchKey.ID_TX, 'ID TX', handleSearch, searchFilters)
    },
    {
      key: 'ID OP',
      label: 'ID OP',
      minWidth: 'max-content',
      filters: {
        multiple: false,
        options: OPERATION_TYPES,
        selected: categoryFilter[TransactionsTableCategoryFilterKey.OPERATION_TYPE],
        onConfirm: (selected) =>
          handleCategoryFilter({
            ...categoryFilter,
            operationType: selected as TransactionsTableCategoryFilter['operationType']
          })
      },
      search: getSearchProps(TransactionsTableSearchKey.ID_OP, 'ID OP', handleSearch, searchFilters),
      renderDataCell: ({ operation }) => {
        const found = OPERATION_TYPES.find((of) => of.value === operation.operationCoreType)
        const opId = operation.id

        return (
          <div>
            {found?.text}-
            <Link
              style={{ textDecoration: 'none' }}
              to={`${operation.operationCoreType === OperationType.DEPOSIT ? '/deposits/' : '/removals/?id='}${opId}`}>
              {opId}{' '}
            </Link>
          </div>
        )
      }
    },
    {
      key: 'creationDate',
      label: 'Creación',
      renderDataCell: ({ creationDate }) => formatDate(creationDate),
      rangePicker: getRangePickerProps(
        TransactionsTableDateRangeKey.CREATION_DATE,
        dateRangeFilters,
        handleRangePicker
      ),
      sort: getSortProps('creation_date', sort, handleSort)
    },
    {
      key: 'confirmationDate',
      label: 'Autorización',
      renderDataCell: ({ confirmationDate }) => formatDate(confirmationDate),
      rangePicker: getRangePickerProps(
        TransactionsTableDateRangeKey.CONFIRMATION_DATE,
        dateRangeFilters,
        handleRangePicker
      ),
      sort: getSortProps('confirmation_date', sort, handleSort)
    },
    {
      key: 'reservationDate',
      label: 'Servicio',
      renderDataCell: ({ reservation: { date } }) => formatDate(date),
      rangePicker: getRangePickerProps(
        TransactionsTableDateRangeKey.RESERVATION_DATE,
        dateRangeFilters,
        handleRangePicker
      ),
      sort: getSortProps('reserve_date', sort, handleSort)
    },
    {
      key: 'client',
      label: 'ID Cliente',
      alignment: 'left',
      search: getSearchProps(TransactionsTableSearchKey.CLIENT_ID, 'ID Cliente', handleSearch, searchFilters),
      renderDataCell: ({ client }) => {
        return (
          <ClientIDLinkComponent id={client.id} noPadding={true}>
            <div style={{ textAlign: 'left', width: '100%' }}>
              <div>{client.id}</div>
              {client.name} {client.lastName}
            </div>
          </ClientIDLinkComponent>
        )
      }
    },
    {
      key: 'typeTransaction',
      label: 'Tipo TX',
      minWidth: 'max-content',
      filters: {
        options: TRANSACTION_TYPE_OPTIONS,
        multiple: true,
        selected: categoryFilter.transactionType,
        onConfirm: (values) => handleCategoryFilter({ ...categoryFilter, transactionType: values as TypeTransaction[] })
      },
      renderDataCell: ({ typeTransaction }) => {
        if (!typeTransaction) return '-'
        const found = TRANSACTION_TYPE_OPTIONS.find((t) => t.value === typeTransaction)
        return found?.text
      }
    },
    {
      key: 'origin',
      label: 'Origen',
      minWidth: '160px'
    },
    {
      key: 'destination',
      label: 'Destino',
      minWidth: '160px'
    },
    {
      key: 'forecast',
      label: 'Previsión',
      renderDataCell: ({ operation, forecastMetrics: forecast }) => {
        const { quantityM3, totalObjects } = handleForecastData(operation, forecast)
        return (
          <div>
            <div>{totalObjects} Obj/ </div>
            <div>{formatNumber(quantityM3, 2)} m³</div>
          </div>
        )
      }
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      filters: {
        options: TRANSACTION_STATUS_OPTIONS,
        multiple: true,
        selected: categoryFilter.status,
        onConfirm: (values) => handleCategoryFilter({ ...categoryFilter, status: values as TransactionStatusId[] })
      },
      renderDataCell: ({ status: { id: statusId } }) => {
        const found = TransactionStatusBadges.find((tsb) => tsb.value === statusId)
        if (!found) return '-'
        const props = {
          ...found.styleProps
        }

        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    },
    {
      key: 'tripId',
      label: '',
      noPadding: true,
      renderDataCell: ({ tripId }) => {
        if (tripId === 0) return null

        return (
          <Tooltip title={`Asignada a viaje ${tripId}`}>
            <div className={styles.tripIcon}>
              <Truck />
            </div>
          </Tooltip>
        )
      }
    }
  ]

  const transactionActions: MenuAction<Transaction>[] = [
    { label: 'Ver remito', onClick: ({ id }) => handleOpenRemittanceModal(id) }
  ]

  const onlyOnePage = pagination.total > pagination.pageSize

  return (
    <div>
      <ActionBar containerStyles={{ gap: 8 }}>
        <div className={styles.statCards}>
          {Object.values(metrics).map((sc) =>
            loadingMetrics ? (
              <div key={sc.title} style={{ minWidth: '6rem', marginLeft: '2rem' }}>
                <LoadingIndicator />
              </div>
            ) : (
              <StatCard key={sc.title}>
                {sc.title === MetricsLabels.WITH_CONFLICT ? (
                  <StatCard.Cell onClick={() => setConflict(!conflict)} active={conflict}>
                    <StatCard.Title titleCard={sc.title} />
                    <StatCard.MainContent content={formatNumber(sc.totalTransactionsWithConflicts, 0, true)} />
                  </StatCard.Cell>
                ) : (
                  <StatCard.Cell>
                    <StatCard.Title titleCard={sc.title} />
                    <StatCard.MainContent content={formatNumber(sc.totalTransactions, 0, true)} />
                  </StatCard.Cell>
                )}
                <StatCard.Cell>
                  {!sc.totalTransactionsWithConflicts && (
                    <StatCard.BasicStat title="TX" stat={formatNumber(sc.totalTransactions || 0, 0, true)} />
                  )}

                  <StatCard.BasicStat title="Objetos" stat={formatNumber(sc.totalObjects || 0, 0, true)} />
                  <StatCard.BasicStat title="m3" stat={formatNumber(sc.totalObjectsM3 || 0, 2, true)} />
                  {sc.title !== MetricsLabels.WITHOUT_ASSIGN && sc.title !== MetricsLabels.WITH_CONFLICT && (
                    <StatCard.BasicStat
                      title="c/viaje"
                      stat={`${formatNumber(sc.totalTransactionsWithTrip, 0, true)}/${formatNumber(
                        sc.totalTransactions,
                        0,
                        true
                      )}`}
                    />
                  )}
                  {sc.totalTransactionsWithConflicts && (
                    <StatCard.BasicStat
                      title="c/conflictos"
                      stat={`${formatNumber(sc.totalTransactionsWithConflicts, 0, true)}`}
                    />
                  )}
                </StatCard.Cell>
              </StatCard>
            )
          )}
        </div>
      </ActionBar>

      <TableActionBar customStyles={{ marginBottom: noCountrySelected ? '40px' : '' }}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction toolTip disabled={loadingReport} variant="csv" onClickButton={handleReport} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        </TableActionBar.Wrapper>

        <TableActionBar.ButtonAction
          titleButton="Crear viaje"
          disabled={selectedTransactions.length === 0}
          onClickButton={handleOpenTripModal}
        />
      </TableActionBar>

      <DynamicTable
        columns={columns}
        error={error}
        hint={noCountrySelected ? 'Para poder asignar transacciones, primero debes seleccionar un país' : undefined}
        loading={loadingTransactions}
        actions={transactionActions}
        rows={transactions}
        keyExtractor={(entity) => entity.guid}
        pagination={pagination}
        customClassNames={{
          table: styles.customTableProps
        }}
        checkboxes={{
          headerIsChecked: () =>
            transactions.every(
              (tx) => !transactionCanBeSelected(tx) || selectedTransactions.find((stx) => stx.id === tx.id)
            ),
          onHeaderCheckboxClick: handleToggleAllTransactions,
          headerIsDisabled: () => onlyOnePage,
          onRowCheckboxClick: (row) => handleToggleTransaction(row),
          rowIsChecked: (row) => selectedTransactions.some((st) => st.id === row.id),
          rowIsDisabled: (row) => noCountrySelected || !transactionCanBeSelected(row)
        }}
      />
      <InfoPopup title="Selección" visible={selectedTransactions.length > 0 && !loadingTransactions} bottomOffset={84}>
        <div className={styles.quantityTransactions}>
          {selectedTransactions.length}{' '}
          {selectedTransactions.length === 1 ? 'transacción seleccionada' : 'transacciones seleccionadas'}
          <Button size="small" onClick={handleClearSelectedTransactions}>
            Limpiar
          </Button>
        </div>
        <div className={styles.content}>
          <div className={styles.objectsData}>
            <ArrowFromBracket style={{ marginRight: '5px' }} />
            {totalForecast.deposits.transactions} Ingresos:{' '}
            {totalForecast.deposits.items + totalForecast.deposits.objects} Objetos/Items
            <div className={styles.details}>
              ({formatNumber(totalForecast.deposits.itemsM3 + totalForecast.deposits.objectsM3, 2)} m³) previsión
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.objectsData}>
            <ArrowToBracket style={{ marginRight: '5px' }} />
            {totalForecast.removals.transactions} Egresos: {totalForecast.removals.objects} Objetos
            <span className={styles.details}>({formatNumber(totalForecast.removals.objectsM3, 2)} m³)</span>
          </div>
        </div>
      </InfoPopup>
    </div>
  )
}

export default TransactionsTable
