import { BillingStatus } from '../../projectApi/TransactionHandler/Billings/common'

export const BillingExcludedUsersOptions = [
  {
    text: 'Solo excluidos',
    value: 'true'
  },
  {
    text: 'No excluidos',
    value: 'false'
  }
]

export enum BillingStatusValue {
  PROCESSED = 'PROCESSED',
  BILLED = 'BILLED',
  PAID = 'PAID',
  ANNULLED = 'ANNULLED'
}

export const BillingStatusFilterOptions = [
  {
    value: BillingStatusValue.PROCESSED,
    text: BillingStatus[BillingStatusValue.PROCESSED]
  },
  {
    value: BillingStatusValue.BILLED,
    text: BillingStatus[BillingStatusValue.BILLED]
  },
  {
    value: BillingStatusValue.PAID,
    text: BillingStatus[BillingStatusValue.PAID]
  },
  {
    value: BillingStatusValue.ANNULLED,
    text: BillingStatus[BillingStatusValue.ANNULLED]
  }
]
