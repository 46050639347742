import { AxiosError } from 'axios'
import {
  AccountOption,
  GetParentAccountsSuccess,
  LoginSuccess,
  LoginTypes,
  SetAccount,
  SetPassword,
  SetUsername
} from '../types/login'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { apiPrivate } from '../../../api'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { APIRequest } from '../../../projectApi/AccountManager/login'
import { Account } from '../../../projectApi/AccountManager/Satellite/list'
import Emitter from '../../../utils/eventEmitter'
import { GlobalEventsEnum } from '../../../utils/eventEmitter/events/globalEvents'

const actions = {
  setUsername: (username: string): SetUsername => ({
    type: LoginTypes.SET_USERNAME,
    payload: { username }
  }),
  setPassword: (password: string): SetPassword => ({
    type: LoginTypes.SET_PASSWORD,
    payload: { password }
  }),

  login:
    (request: APIRequest): AppThunk =>
    async (dispatch): Promise<boolean> => {
      dispatch({ type: LoginTypes.LOGIN_REQUEST })

      try {
        const response = await API.AccountManager.login(request)

        const { token, id, email, name, realId } = response
        localStorage.setItem('token', token)
        localStorage.setItem('id', id)
        localStorage.setItem('real_id', String(realId))
        localStorage.setItem('email', email)
        localStorage.setItem('name', name)

        apiPrivate.defaults.headers.Authorization = token
        dispatch(actions.loginSuccess())
        return true
      } catch (e) {
        handleErrorsWithAction(e as AxiosError, LoginTypes.LOGIN_FAILURE, dispatch)
        return false
      }
    },
  loginSuccess: (): LoginSuccess => ({
    type: LoginTypes.LOGIN_SUCCESS
  }),

  getParentAccounts:
    (): AppThunk =>
    async (dispatch): Promise<boolean> => {
      dispatch({ type: LoginTypes.GET_PARENT_ACCOUNTS_REQUEST })

      try {
        const { accounts } = await API.AccountManager.Satellite.list()
        dispatch(actions.getParentAccountsSuccess(accounts))
        return true
      } catch (e) {
        handleErrorsWithAction(e as AxiosError, LoginTypes.GET_PARENT_ACCOUNTS_FAILURE, dispatch)
        return false
      }
    },
  getParentAccountsSuccess: (accounts: Account[]): GetParentAccountsSuccess => ({
    type: LoginTypes.GET_PARENT_ACCOUNTS_SUCCESS,
    payload: { accounts }
  }),

  setAccountFromSelector: (account: AccountOption): SetAccount => {
    if (account.value === 0) {
      localStorage.removeItem('parent-id')
    } else {
      localStorage.setItem('parent-id', account.value.toString())
    }
    setTimeout(() => Emitter.emit(GlobalEventsEnum.CHANGE_ACCOUNT, account.value), 100)
    return actions.setAccount(account)
  },

  setAccount: (account: AccountOption): SetAccount => {
    return {
      type: LoginTypes.SET_ACCOUNT,
      payload: { account }
    }
  }
}

export default actions
