import { Reducer } from 'redux'
import {
  DepositListActions,
  GetDepositListTypes,
  DepositListStateType,
  SetterDepositTypes,
  RESET_FILTERS
} from '../types/depositList'

export const PAGE_SIZE = 10

const initialState: DepositListStateType = {
  fetchingDeposits: false,
  deposits: [],
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'name', text: '' },
    { key: 'owner_id', text: '' }
  ],
  filters: {
    id: undefined
  }
}

const DepositListReducer: Reducer<DepositListStateType, DepositListActions> = (
  state: DepositListStateType = initialState,
  action: DepositListActions
): DepositListStateType => {
  switch (action.type) {
    case RESET_FILTERS:
      return initialState
    case GetDepositListTypes.GET_DEPOSIT_REQUEST:
      return {
        ...state,
        fetchingDeposits: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GetDepositListTypes.GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        fetchingDeposits: false,
        deposits: action.payload.deposits,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetDepositListTypes.GET_DEPOSIT_FAILURE:
      return {
        ...state,
        fetchingDeposits: false
      }
    case SetterDepositTypes.SET_DEPOSIT_FILTERS:
      return {
        ...state,
        filters: {
          id: action.payload.id
        }
      }
    default:
      return state
  }
}

export default DepositListReducer
