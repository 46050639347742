import { Reducer } from 'redux'
import {
  DonationListActions,
  GetDonationsListTypes,
  DonationsListStateType,
  RESET_FILTERS,
  SendDonationsCSVTypes,
  DonationTableDateRangeKey
} from '../types/donationsTable'
import { SortDirection } from '../../../components/DynamicTable/types/types'

export const PAGE_SIZE = 10

const initialState: DonationsListStateType = {
  fetchingDonations: false,
  sendingCSV: false,
  donations: [],
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  sort: {
    direction: SortDirection.DESC,
    field: 'id'
  },
  searchFilters: [
    { key: 'objectId', text: '' },
    { key: 'id', text: '' },
    { key: 'userId', text: '' },
    { key: 'requestId', text: '' }
  ],
  categoryFilter: { status: [] },
  dateRangeFilters: [
    { key: DonationTableDateRangeKey.CREATED_AT, startDate: null, endDate: null },
    { key: DonationTableDateRangeKey.UPDATED_AT, startDate: null, endDate: null }
  ],
  showDemoUsers: false
}

const DonationsListReducer: Reducer<DonationsListStateType, DonationListActions> = (
  state: DonationsListStateType = initialState,
  action: DonationListActions
): DonationsListStateType => {
  switch (action.type) {
    case RESET_FILTERS:
      return initialState
    case GetDonationsListTypes.GET_DONATION_REQUEST:
      return {
        ...state,
        fetchingDonations: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        sort: action.payload.sort,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        showDemoUsers: action.payload.showDemoUsers || initialState.showDemoUsers
      }
    case GetDonationsListTypes.GET_DONATION_SUCCESS:
      return {
        ...state,
        fetchingDonations: false,
        donations: action.payload.donations,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetDonationsListTypes.GET_DONATION_FAILURE:
      return {
        ...state,
        fetchingDonations: false
      }
    case SendDonationsCSVTypes.SEND_DONATIONS_CSV_REQUEST:
      return { ...state, sendingCSV: true }
    case SendDonationsCSVTypes.SEND_DONATIONS_CSV_FAILURE:
    case SendDonationsCSVTypes.SEND_DONATIONS_CSV_SUCCESS:
      return { ...state, sendingCSV: false }
    default:
      return state
  }
}

export default DonationsListReducer
