import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import TransactionOperatorsAssignmentActionCreators from './actionCreators'
import TransactionOperatorsAssignmentModal, { TransactionOperatorsAssignmentModalProps } from './component'

const TransactionOperatorsAssignmentContainer = () => {
  const dispatch = useDispatch()
  const { setOpen, getOperators, setSelectedOperators, assignOperators } = bindActionCreators(
    TransactionOperatorsAssignmentActionCreators,
    dispatch
  )
  const { transactions, allOperators, selectedOperators, loadingOperators, error, open, editMode, loadingAssignment } =
    useSelector((state) => state.TransactionOperatorsAssignment)

  useEffect(() => {
    if (open) getOperators()
  }, [open])

  const props: TransactionOperatorsAssignmentModalProps = {
    open,
    editMode,
    transactions,
    allOperators,
    selectedOperators,
    loadingOperators,
    error,
    loadingAssignment,
    onSelectOperators: setSelectedOperators,
    onConfirm: () =>
      assignOperators({
        operatorIds: selectedOperators.map((p) => p.id),
        transactionIds: transactions.map((tx) => tx.id)
      }),
    onClose: () => setOpen({ open: false })
  }

  return <TransactionOperatorsAssignmentModal {...props} />
}

export default TransactionOperatorsAssignmentContainer
