import { combineReducers } from 'redux'
import billingTable from './billingTable'
import paymentModal from '../../BillingV2/reducers/paymentModal'
import massBillingModal from './massBillingModal'
import calculateBillingModal from './calculateBillingModal'
import preBillingReport from './preBillingReport'
import filesModal from './filesModal'

const rootReducer = combineReducers({
  billingTable,
  massBillingModal,
  calculateBillingModal,
  paymentModal,
  preBillingReport,
  filesModal
})

export default rootReducer
