import { Reducer } from 'redux'
import { GetRemovalReasons, ModalReasonState, ModalReasonsActions, ModalReasonsTypes } from '../types/ModalReasons'
import commonTypes from '../types/common'

const modalReasonsInitialState: ModalReasonState = {
  modalOpen: false,
  removalReasons: {
    fetching: false,
    list: [],
    error: ''
  },
  reasonSelected: '',
  anotherReason: ''
}

const ModalReasonsReducer: Reducer<ModalReasonState, ModalReasonsActions> = (
  state = modalReasonsInitialState,
  action
): ModalReasonState => {
  switch (action.type) {
    case commonTypes.FINISH:
      return modalReasonsInitialState
    case ModalReasonsTypes.SET_MODAL_OPEN: {
      const { modalOpen } = action.payload

      return { ...state, modalOpen }
    }
    case GetRemovalReasons.GET_REMOVAL_REASONS_REQUEST:
      return {
        ...state,
        removalReasons: {
          ...state.removalReasons,
          fetching: true,
          error: modalReasonsInitialState.removalReasons.error
        }
      }
    case GetRemovalReasons.GET_REMOVAL_REASONS_SUCCESS:
      return {
        ...state,
        removalReasons: { ...state.removalReasons, fetching: false, list: action.payload.reasonsList }
      }
    case GetRemovalReasons.GET_REMOVAL_REASONS_FAILURE:
      return {
        ...state,
        removalReasons: { ...state.removalReasons, fetching: false, error: action.payload.messageError }
      }
    case ModalReasonsTypes.SET_REASON_SELECTED:
      return {
        ...state,
        reasonSelected: action.payload.reasonSelected,
        anotherReason: modalReasonsInitialState.anotherReason
      }
    case ModalReasonsTypes.SET_ANOTHER_REASON:
      return {
        ...state,
        anotherReason: action.payload.anotherReason
      }
    default:
      return state
  }
}

export default ModalReasonsReducer
