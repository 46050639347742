import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import camelcaseKeys from 'camelcase-keys'

interface ProdByCountry {
  Name: string
  CountryId: string
  ProductId: number
}

interface OldProduct {
  RealID: number
  ID: string
  WeightInGr: number
  LengthInCm: number
  WidthInCm: number
  HeightInCm: number
  MinVolumeDeviation: number
  MaxVolumeDeviation: number
  FloorByStairsCostInCents: number
  RemovalPackagingCostInCents: number
  AssemblyCostInCents: number
  Deprecated: boolean
  Multiplier: number
  Description: string
  Categories: any[] | null
  ProdByCountry: ProdByCountry
  PhotoMin: number
  EligibleForSale: boolean
}

export const formatProduct = (product: OldProduct): FormattedProduct => {
  // @ts-ignore
  const { id, realId, ...restOfProduct } = camelcaseKeys(product, { deep: true })

  return {
    category: {
      id: 0,
      description: ''
    },
    depositFloorsByStairsCostInCents: 0,
    disassemblyCost: 0,
    floorsByStairsCostInCents: 0,
    maxAmountAvowedInCents: 0,
    maxRefundAmountInCents: 0,
    packagingCostInCents: 0,
    packagingTimeInMinutes: 0,
    percentageAvowed: 0,
    price: 0,
    priceRecalculate: 0,
    showInSearch: false,
    unPackagingTimeInMinutes: 0,
    volumeInCm3: 0,
    id: realId,
    guid: id,
    photo: {
      key: '',
      url: ''
    },
    ...restOfProduct
  }
}
