import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

export interface APIResponse {
  Id: number
  Name: string
  DataType: string
  IsVisible: boolean
  Unit: string
  DeletedAt: null
  Countries: CountryResponse[]
}

export interface CountryResponse {
  Id: number
  Label: string
  Country: string
  AttributeId: number
  DeletedAt: null
  Options: OptionResponse[]
}

export interface OptionResponse {
  Id: number
  DataType: string
  Value: string
  AttributeCountryId: number
  DeletedAt: null
}

export interface AttributeEditParams {
  id: number
  is_visible: boolean
  data_type: string
  name: string
  unit: string
  countries: Country[]
}

export interface Country {
  country: string
  label: string
  options: Option[]
}

export interface Option {
  data_type: string
  value: string
  deleted?: boolean
}

export function updateAttribute(params: AttributeEditParams): Promise<boolean> {
  return apiPrivate
    .put(`${URL_BASE_OBJECTS}/attribute`, params)
    .then(() => true)
    .catch((err) => {
      throw err
    })
}
