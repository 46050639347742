/* eslint-disable camelcase */
import { mergeDeepWith } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PartialDeep } from 'type-fest'

import { SpanishArgentina } from './texts/es-AR'
import { Currency } from './utils/formatPrice'
import { CountryIdCode } from '../../components/CountrySelector/constants/constants'
import { SpanishSpain } from './texts/es-ES'

const BASE_STRINGS = SpanishArgentina
export type LocalizedStrings = Omit<typeof BASE_STRINGS, 'currency'> & { currency: Currency }

type LocalizationMap = {
  'es-AR': LocalizedStrings
  [key: string]: PartialDeep<LocalizedStrings>
}

export const localizationStrings: LocalizationMap = {
  'es-AR': SpanishArgentina,
  'es-ES': SpanishSpain
}

const DEFAULT_LOCALE = { locale: 'es-AR', strings: localizationStrings['es-AR'] }

export const useLocalization = (): { locale: keyof LocalizationMap; strings: LocalizedStrings } => {
  const countrySelected = useSelector((state) => state.CountrySelector.countrySelected)

  const localization = useMemo(() => {
    if (!countrySelected || countrySelected.code === CountryIdCode.ALL) return DEFAULT_LOCALE
    const preferredLocale = getPreferredLocale(countrySelected.code)
    const strings = mergeDeepWith((_, b) => b, DEFAULT_LOCALE.strings, localizationStrings[preferredLocale])

    return { locale: preferredLocale, strings }
  }, [countrySelected.code])

  return localization
}

const getPreferredLocale = (countryCode: CountryIdCode): keyof LocalizationMap => {
  switch (countryCode) {
    case CountryIdCode.SPAIN:
      return 'es-ES'
    case CountryIdCode.ARGENTINA:
    default:
      return 'es-AR'
  }
}
