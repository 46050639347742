import { apiPrivate } from '../../api'
import snakecaseKeys from 'snakecase-keys'
import { CountryIdCode } from '../../components/CountrySelector/constants/constants'
import { URL_BASE_CATEGORIES } from '../../endpoints'
import { AxiosResponse } from 'axios'
import { AutomaticPriceOperationType } from '../../sections/NewProduct/types/productCosts'

type GetPriceParams = {
  countryId: CountryIdCode
  operationType: keyof AutomaticPriceOperationType
  m3: number
}

type APIResponse = {
  price: number
}

enum operationTypes {
  REMOVAL = 'removal',
  DEPOSIT = 'deposit'
}

export function getStairsPrice(params: GetPriceParams): Promise<APIResponse> {
  const snakeParams = snakecaseKeys({
    countryId: params.countryId,
    operationType: params.operationType === 'depositAutomaticPrice' ? operationTypes.DEPOSIT : operationTypes.REMOVAL,
    m3: params.m3
  })

  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/stairs-price`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener los precios por escalera')

      const resp: APIResponse = {
        price: response.data.price
      }

      return resp
    })
}
