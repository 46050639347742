import React from 'react'
import { Input, Modal } from 'antd'

const styles = {
  modal: {
    maxWidth: 700
  },
  placeholder: {
    display: 'inline-block',
    marginRight: '10px'
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 0.5px #E7E7E7',
    padding: '6px',
    borderRadius: '4px',
    width: 300
  },
  disabledInline: {
    marginLeft: '6px',
    display: 'inline-block',
    border: 'solid 0.5px #cccccc',
    padding: '6px',
    borderRadius: '4px',
    background: '#F0EEF0',
    width: 300
  },
  cont: {
    marginBottom: '6px'
  }
}

const EditFlag = (props) => {
  const { setEditModalClose, modalEdit, loading, error, id, editFlag, changeFlagValue } = props

  const { Name, Value } = id

  return (
    <Modal
      title="Modificar Flag"
      visible={modalEdit}
      closable={!loading}
      maskClosable={!loading}
      onCancel={setEditModalClose}
      style={styles.modal}
      onOk={() => editFlag(Name, Value)}
      okText={'Modificar'}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      cancelText={'Cancelar'}>
      <div style={styles.cont}>
        <span style={styles.placeholder}>Nombre: </span>
        <Input style={styles.disabledInline} title="Nombre" value={Name} placeholder="Nombre" disabled={true} />
      </div>
      <div style={styles.cont}>
        <span style={styles.placeholder}>Valor: </span>
        <Input
          style={styles.inline}
          placeholder=""
          value={Value}
          onChange={(e) => {
            changeFlagValue(e.target.value, Name)
          }}
        />
      </div>
      <p style={styles.error}>{error}</p>
    </Modal>
  )
}

export default EditFlag
