import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type depositDeleteParams = {
  depositId: number
}

export function deleteit({ depositId }: depositDeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_TRANSACTIONS}/deposits/${depositId}`, { headers: { 'x-origin': null } })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
