import React, { useMemo } from 'react'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination, SortDirection, SortProps } from '../../../components/DynamicTable/types/types'
import { Operation } from '../../../projectApi/TransactionHandler/Proposals/common'
import moment, { Moment } from 'moment'
import { Country } from '../../../components/CountrySelector/constants/constants'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import ColorBadge from '../../../components/ColorBadge'
import { STATUS_FILTERS } from '../constants'
import { getFilterProps, getRangePickerProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { SearchFilter } from '../../../components/DynamicTable/utils/entities'
import {
  ProposalsTableCategoryFilter,
  ProposalsDateRangeFilter,
  ProposalsTableDateRangeKey,
  ProposalsTableSort,
  ProposalsTableSortKey
} from '../types/proposals'
import { Config } from '../../Leads/types/config'
import SendByEmail from '../containers/sendByEmail'
import sendMailActions from '../actions/sendByMail'
import { TableActionBar } from '../../../components/TableActionBar'

export const getSortProps = (
  key: ProposalsTableSortKey,
  currentSorter: ProposalsTableSort,
  sorterFunction: (newSorter: ProposalsTableSort) => void
): SortProps => {
  return {
    direction: currentSorter.direction,
    isActive: currentSorter.field === key,
    onClick: (newSortDirection) => {
      sorterFunction({
        direction: currentSorter.field === key ? newSortDirection : SortDirection.ASC,
        field: key
      })
    }
  }
}

export type ProposalsTableProps = {
  sendEmailActions: typeof sendMailActions
  proposals: Operation[]
  loadingProposals: boolean
  pagination: Pagination
  countries: Country[]
  handleResetFilters: () => void
  actions: MenuAction<Operation>[]
  config: Config
  column: ProposalsTableSort
  handleSort: (newSorter: ProposalsTableSort) => void
  categoryFilter: ProposalsTableCategoryFilter
  handleSearch: (key: any, newValue: string) => void
  handleRangePicker: (key: ProposalsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  searchFilters: SearchFilter[]
  dateRangeFilters: ProposalsDateRangeFilter[]
  handleCategoryFilter: (newCategoryFilter: ProposalsTableCategoryFilter) => void
  handleSendProposalsCSV: () => void
  sendingCSV: boolean
}

export const ProposalsTable: React.FC<ProposalsTableProps> = ({
  loadingProposals,
  proposals,
  pagination,
  countries,
  actions,
  config,
  column,
  handleSort,
  handleSearch,
  searchFilters,
  dateRangeFilters,
  handleResetFilters,
  handleRangePicker,
  handleSendProposalsCSV,
  sendingCSV,
  categoryFilter,
  handleCategoryFilter
}) => {
  const getSourceFilters = (config: Config) => {
    const sources = config.sources
    if (!sources?.length) return []

    return sources
  }
  const SOURCE_FILTERS = useMemo(() => getSourceFilters(config), [config.sources])

  const columns: Column<Operation>[] = [
    {
      label: 'País',
      key: 'countryId',
      maxWidth: '80px',
      renderDataCell: ({ countryId }) => {
        const flag = getCurrentCountry(countries, countryId, 'code')

        if (!flag) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      label: 'ID',
      key: 'id',
      maxWidth: '50px',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('id', column, handleSort)
    },
    {
      label: 'Fecha de creación',
      key: 'createdAt',
      minWidth: '100px',
      rangePicker: getRangePickerProps('createdAt', dateRangeFilters, handleRangePicker),
      renderDataCell: ({ createdAt }) => {
        const formatDate = (date: string) => moment.utc(date).local().format('dd[.] DD MMM YY HH:mm')
        return formatDate(createdAt)
      }
    },
    {
      label: 'ID Usuario lead',
      key: 'prospectId',
      minWidth: '50px',
      search: getSearchProps('prospect_id', 'ID Usuario lead', handleSearch, searchFilters),
      renderDataCell: ({ prospectId }) => prospectId
    },
    {
      label: 'Nombre y Apellido',
      key: 'prospect',
      minWidth: '150px',
      renderDataCell: ({ prospect }) => {
        return `${prospect?.name} ${prospect?.lastName}`
      }
    },
    {
      label: 'Servicio',
      key: 'type',
      maxWidth: '60px'
    },
    {
      label: 'Origen',
      key: 'origin',
      maxWidth: '60px',
      renderDataCell: ({ origin }) => {
        const originFound = SOURCE_FILTERS.find((sf) => sf.id === Number(origin))

        if (!originFound) return '-'

        return originFound.spanishDisplay
      }
    },
    {
      label: 'Creador',
      key: 'userCreated',
      renderDataCell: ({ userCreated: { name, lastName } }) => {
        if (!name && !lastName) return '-'

        return `${name} ${lastName}`
      }
    },
    {
      label: 'Agente asignado',
      key: 'assignedAgent',
      renderDataCell: ({ assignedAgent }) => {
        if (!assignedAgent) return '-'

        return `${assignedAgent?.firstName} ${assignedAgent?.lastName}`
      }
    },
    {
      label: 'Valor cotizado',
      key: 'totalAmount',
      maxWidth: '50px',
      renderDataCell: ({ totalAmount }) => {
        return '$' + ' ' + totalAmount
      },
      sort: getSortProps('total_amount', column, handleSort)
    },
    {
      label: 'Estado',
      key: 'status',
      renderDataCell: ({ statusId }) => {
        const colorState = STATUS_FILTERS.find((dp) => dp.value === statusId)

        if (!colorState) return `${status}`
        const props = {
          ...colorState?.styleProps
        }
        return <ColorBadge {...props}>{colorState?.text}</ColorBadge>
      },
      filters: getFilterProps(STATUS_FILTERS, categoryFilter, 'status', handleCategoryFilter, true)
    }
  ]

  return (
    <>
      <TableActionBar>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            toolTip
            variant="csv"
            disabled={sendingCSV}
            onClickButton={handleSendProposalsCSV}
          />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        </TableActionBar.Wrapper>
      </TableActionBar>

      <DynamicTable
        columns={columns}
        loading={loadingProposals}
        rows={proposals}
        keyExtractor={(entity) => entity.id}
        pagination={pagination}
        actions={actions}
      />
      <SendByEmail />
    </>
  )
}
