import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'
import { Discount } from '../../../projectApi/TransactionHandler/Compute/getById'
import { DepositResponse } from '../../../projectApi/TransactionHandler/Deposits/getById'
import { Proposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { Services } from '../../../projectApi/TransactionHandler/ServiceType/common'
import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'

export interface DetailsState {
  proposal: Proposal | null
  deposits: DepositResponse | null
  transport: TransportMode | null
  prospect: Prospect | null
  discount: Discount | null
  servicesType: Services[]
  loading: boolean
  error: string
}

export const POPULATE_VIEW_REQUEST = 'PROPOSALS/POPULATE_VIEW_REQUEST'
export const POPULATE_VIEW_SUCCESS = 'PROPOSALS/POPULATE_VIEW_SUCCESS'
export const POPULATE_VIEW_FAILURE = 'PROPOSALS/POPULATE_VIEW_FAILURE'

export interface PopulateViewRequest {
  type: typeof POPULATE_VIEW_REQUEST
}

export interface PopulateViewSuccess {
  type: typeof POPULATE_VIEW_SUCCESS
  payload: {
    proposal: Proposal
    deposits: DepositResponse
    transport: TransportMode
    prospect: Prospect
    discount: Discount | null
    servicesType: Services[]
  }
}

export interface PopulateViewFailure {
  type: typeof POPULATE_VIEW_FAILURE
  payload: {
    error: string
  }
}

export type PopulateView = PopulateViewRequest | PopulateViewSuccess | PopulateViewFailure

export const REMOVE_SELECTED_PROPOSAL = 'PROPOSAL/REMOVE_SELECTED_PROPOSAL'

export interface RemoveSelectedProposal {
  type: typeof REMOVE_SELECTED_PROPOSAL
}

export type ProposalActions = PopulateView | RemoveSelectedProposal
