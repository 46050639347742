import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AgentModalActionCreator from '../actionCreator/agentModal'
import AgentModal, { AgentsModalProps } from '../components/AgentModal'
import { AgentCreateData } from '../types/agentModal'

const AgentModalContainer = () => {
  const dispatch = useDispatch()
  const { openModal, getUserByEmail, setAgentRoleId, verifyAgentExists, setErrorModalAgent } = bindActionCreators(
    AgentModalActionCreator,
    dispatch
  )

  const agentModalState = useSelector((state) => state.Agents.modal)

  const handleClose = () => {
    openModal({ openModal: false })
  }

  const handleSearchEmail = async (email: string) => {
    await getUserByEmail({ email })
  }

  const handleAgentData = async ({ firstName, lastName, email, userGuid, userId, roleId }: AgentCreateData) => {
    await verifyAgentExists({ firstName, lastName, email, userGuid, userId, roleId })
  }

  const props: AgentsModalProps = {
    isOpenModal: agentModalState.openModal,
    onClose: handleClose,
    handleSearchEmail,
    userData: agentModalState.user ? agentModalState.user : null,
    handleAgentData,
    setAgentRoleId,
    setErrorModalAgent,
    agentRoleId: agentModalState.roleId,
    loadingCreate: agentModalState.loadingCreateAgent,
    error: agentModalState.error
  }

  return <AgentModal {...props} />
}

export default AgentModalContainer
