import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

import {
  AcceptDonationRequest,
  AcceptDonationSuccess,
  CLEAR_STATE,
  DonateRequest,
  DonateSuccess,
  GetDonationByIdRequest,
  GetDonationByIdSuccess,
  RejectDonationRequest,
  RejectDonationSuccess,
  AcceptDonationTypes,
  RejectDonationTypes,
  GetDonationByIdTypes,
  DonateTypes
} from '../types/donationsView'

const DonationViewActionsCreator = {
  getDonationById(donationID: number): AppThunk {
    return async (dispatch) => {
      const request: GetDonationByIdRequest = {
        type: GetDonationByIdTypes.GET_DONATION_BY_ID_REQUEST
      }

      dispatch(request)
      try {
        const { donationRequests } = await API.Sales.DonationRequest.list({ id: donationID })
        const success: GetDonationByIdSuccess = {
          type: GetDonationByIdTypes.GET_DONATION_BY_ID_SUCCESS,
          payload: {
            donation: donationRequests[0] || null
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetDonationByIdTypes.GET_DONATION_BY_ID_FAILURE, dispatch)
      }
    }
  },
  clearState(): AppThunk {
    return (dispatch) => {
      dispatch({ type: CLEAR_STATE })
    }
  },
  rejectDonation(donationID: number): AppThunk {
    return async (dispatch) => {
      const request: RejectDonationRequest = {
        type: RejectDonationTypes.REJECT_DONATION_REQUEST
      }
      dispatch(request)
      try {
        await API.Sales.DonationRequest.reject(donationID)

        const success: RejectDonationSuccess = {
          type: RejectDonationTypes.REJECT_DONATION_SUCCESS
        }
        Emitter.emit(Events.Donations.REJECT)
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, RejectDonationTypes.REJECT_DONATION_FAILURE, dispatch)
      }
    }
  },
  acceptDonation(donationID: number): AppThunk {
    return async (dispatch) => {
      const request: AcceptDonationRequest = {
        type: AcceptDonationTypes.ACCEPT_DONATION_REQUEST
      }
      dispatch(request)
      try {
        await API.Sales.DonationRequest.accept(donationID)

        const success: AcceptDonationSuccess = {
          type: AcceptDonationTypes.ACCEPT_DONATION_SUCCESS
        }
        Emitter.emit(Events.Donations.ACCEPT)
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, AcceptDonationTypes.ACCEPT_DONATION_FAILURE, dispatch)
      }
    }
  },
  donate(donationID: number): AppThunk {
    return async (dispatch) => {
      const request: DonateRequest = {
        type: DonateTypes.DONATE_REQUEST
      }
      dispatch(request)
      try {
        await API.Sales.DonationRequest.donate(donationID)

        const success: DonateSuccess = {
          type: DonateTypes.DONATE_SUCCESS
        }
        Emitter.emit(Events.Donations.DONATE)
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, DonateTypes.DONATE_FAILURE, dispatch)
      }
    }
  }
}

export default DonationViewActionsCreator
