import { API } from '../../projectApi'
import { FormattedProduct } from '../../projectApi/CategoryCreation/Product/common'
import { FormattedObject } from '../../projectApi/ObjectAdministration/common'
import { BulkUpdateParams } from '../../projectApi/ObjectAdministration/Object/bulkUpdate'
import { AppThunk } from '../../store'
import Emitter from '../../utils/eventEmitter'
import { Events } from '../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import {
  CLEAR_SELECTED_PRODUCTS,
  CONFIRM_MASSIVE_EDIT_FAILURE,
  CONFIRM_MASSIVE_EDIT_REQUEST,
  CONFIRM_MASSIVE_EDIT_SUCCESS,
  ConfirmMassiveEditRequest,
  ConfirmMassiveEditSuccess,
  ENABLE_ITEM,
  EnableItem,
  FIND_USER_FAILURE,
  FIND_USER_REQUEST,
  FIND_USER_SUCCESS,
  Fields,
  FindUserRequest,
  FindUserSuccess,
  MODIFY_ITEM,
  ModifyItem,
  ProductDimensions,
  SEARCH_PRODUCTS_FAILURE,
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SELECT_PRODUCT,
  SET_OPEN,
  SET_PRODUCT_DIMENSION,
  SearchProductsRequest,
  SearchProductsSuccess,
  SetOpen,
  SetProductDimension
} from './types'
import { ObjectSituationKeys } from '../Objects/constants/objectSituations'

export const MassiveObjectsEditActionCreators = {
  setOpen: (open: boolean, objects?: FormattedObject[]): SetOpen => ({
    type: SET_OPEN,
    payload: { open, objects }
  }),
  modifyItem: <T extends string | boolean | ObjectSituationKeys | null>(value: T, key: keyof Fields): ModifyItem => ({
    type: MODIFY_ITEM,
    payload: {
      value,
      key
    }
  }),
  enableItem: (enabled: boolean, key: keyof Fields): EnableItem => ({
    type: ENABLE_ITEM,
    payload: {
      enabled,
      key
    }
  }),
  clearSelectedProducts: () => ({
    type: CLEAR_SELECTED_PRODUCTS
  }),
  selectProduct: (product: FormattedProduct) => ({
    type: SELECT_PRODUCT,
    payload: {
      product
    }
  }),
  setProductDimension: (dimensionType: keyof ProductDimensions, value: number): SetProductDimension => ({
    type: SET_PRODUCT_DIMENSION,
    payload: { dimensionType, value }
  }),
  searchProducts:
    (countryCode: string, description: string): AppThunk =>
    async (dispatch) => {
      const lastSearchTimestamp = new Date().getMilliseconds()

      const request: SearchProductsRequest = {
        type: SEARCH_PRODUCTS_REQUEST,
        payload: {
          lastSearchTimestamp
        }
      }

      dispatch(request)

      try {
        const { products } = await API.CategoryCreation.Product.listAdmin({
          countryCode,
          name: description,
          limit: 50
        })

        const success: SearchProductsSuccess = {
          type: SEARCH_PRODUCTS_SUCCESS,
          payload: {
            products,
            lastSearchTimestamp
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, SEARCH_PRODUCTS_FAILURE, dispatch)
      }
    },
  confirmMassiveEdit:
    (objectIds: number[], fields: Fields): AppThunk =>
    async (dispatch) => {
      const request: ConfirmMassiveEditRequest = {
        type: CONFIRM_MASSIVE_EDIT_REQUEST
      }

      dispatch(request)

      const params: BulkUpdateParams = {
        objectIds,
        ...(fields.name.enabled ? { name: fields.name.value } : {}),
        ...(fields.description.enabled ? { description: fields.description.value } : {}),
        ...(fields.owner.enabled && fields.owner.user?.realId ? { ownerId: fields.owner.user.realId } : {}),
        ...(fields.product.enabled && fields.product.value
          ? {
              product: {
                ...fields.product.dimensions,
                id: fields.product.value.id
              }
            }
          : {}),
        ...(fields.objectSituation.enabled ? { objectSituation: fields.objectSituation.value } : {}),
        ...(fields.objectSituationReason.enabled ? { objectSituationReason: fields.objectSituationReason.value } : {}),
        ...(fields.objectStatus.enabled ? { objectStatusId: fields.objectStatus.value } : {})
      }

      try {
        await API.ObjectAdministration.Object.bulkUpdate(params)

        const success: ConfirmMassiveEditSuccess = {
          type: CONFIRM_MASSIVE_EDIT_SUCCESS
        }
        dispatch(success)
        Emitter.emit(Events.Objects.MASSIVE_EDIT_COMPLETED)
      } catch (error) {
        handleErrorsWithAction(error, CONFIRM_MASSIVE_EDIT_FAILURE, dispatch)
      }
    },
  findUser:
    (userId: number): AppThunk =>
    async (dispatch) => {
      const request: FindUserRequest = {
        type: FIND_USER_REQUEST
      }

      dispatch(request)

      try {
        const { users } = await API.AccountManager.Users.list({ id: userId })
        if (users.length !== 1) throw new Error('No se encontró el usuario')

        const success: FindUserSuccess = {
          type: FIND_USER_SUCCESS,
          payload: {
            user: users[0]
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, FIND_USER_FAILURE, dispatch)
      }
    }
}
