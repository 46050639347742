import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styles from './numberInput.module.scss'
import { Either } from '../../utils/utilityTypes'
import { flushSync } from 'react-dom'

type NumberInputProps = {
  value: number
  interval?: number
  disabled?: boolean
  max?: number
  min?: number
  acceptFloat?: boolean
} & Either<
  {
    onAnyChange: (newValue: number) => void
  },
  {
    onPlusClick: (newValue: number) => void
    onMinusClick: (newValue: number) => void
    onInputChange: (newValue: number) => void
  }
>

const NumberInput: React.FC<NumberInputProps> = ({
  onAnyChange,
  onPlusClick = () => {},
  onMinusClick = () => {},
  onInputChange = () => {},
  acceptFloat,
  max = 9999,
  min = 0,
  value,
  interval = 1,
  disabled
}) => {
  const [inputActive, setInputActive] = useState(false)
  const [localInputValue, setLocalInputValue] = useState(`${value}`)

  useEffect(() => {
    if (`${value}` !== localInputValue) setLocalInputValue(`${value}`)
  }, [value])

  const inputRef = useRef<HTMLInputElement>(null)

  const handleBlur = () => {
    const action = onAnyChange || onInputChange
    const parsedInput = acceptFloat ? parseFloat(localInputValue) : parseInt(localInputValue)

    if (parsedInput >= max) return action(max)
    if (parsedInput < min) return action(min)

    if (isNaN(parsedInput)) {
      setLocalInputValue(`${value}`)
      return
    }

    action(parsedInput)
    setInputActive(false)
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedChars = acceptFloat ? /[^0-9.]/ : /[^0-9]/

    const filteredString = e.target.value.replace(allowedChars, '')

    setLocalInputValue(filteredString)
  }

  const handleMinusButton = () => {
    const action = onAnyChange || onMinusClick
    const newValue = value - interval
    if (newValue < min) return action(min)

    action(newValue)
  }
  const handlePlusButton = () => {
    const action = onAnyChange || onPlusClick
    const newValue = value + interval

    if (newValue > max) return action(max)

    action(newValue)
  }

  const handleCenterClick = () => {
    flushSync(() => {
      setInputActive(true)
    })

    if (inputRef.current) inputRef.current.focus()
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  return (
    <div className={clsx(styles.numberInput, disabled && styles.disabled)}>
      <button className={styles.button} onClick={handleMinusButton} disabled={disabled || value === min}>
        -
      </button>
      {!inputActive ? (
        <span className={clsx(styles.count, disabled && styles.disabled)} onClick={handleCenterClick}>
          {value}
        </span>
      ) : (
        <input
          ref={inputRef}
          disabled={disabled}
          className={clsx(styles.count, disabled && styles.disabled)}
          value={localInputValue}
          onBlur={handleBlur}
          onChange={handleInput}
          onKeyDown={handleEnter}
        />
      )}
      <button className={styles.button} disabled={disabled || value === max} onClick={handlePlusButton}>
        +
      </button>
    </div>
  )
}

export default NumberInput
