import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ProductCostsActionCreator } from '../actionCreator/productCosts'
import { ProductCostProps, ProductCosts } from '../components/subcomponents/ProductCosts'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { AllowedCountriesCodes, AutomaticPriceOperationType } from '../types/productCosts'
import { useQueryParam } from 'use-query-params'

const ProductCostContainer = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.NewProduct.productCosts)
  const { dimensionsHeight, dimensionsLength, dimensionsWidth, productDetail } = useSelector(
    (state) => state.NewProduct.generalData
  )
  const { setStringField, setBooleanFields, setProductEligibility, getAutomaticPricePerFloor } = bindActionCreators(
    ProductCostsActionCreator,
    dispatch
  )

  const PRODUCT_M3 = (Number(dimensionsLength) * Number(dimensionsWidth) * Number(dimensionsHeight)) / 1000000

  const handleGetAutomaticPrice = (
    { target }: CheckboxChangeEvent,
    countryId: AllowedCountriesCodes,
    operationType: keyof AutomaticPriceOperationType
  ) => {
    if (!target.checked) return

    getAutomaticPricePerFloor({ countryId, operationType, m3: PRODUCT_M3 })
  }

  const isFromCatalog = useQueryParam('byCatalog')[0] === 'true'

  const props: ProductCostProps = {
    setStringField,
    setBooleanFields,
    setProductEligibility,
    handleGetAutomaticPrice,
    state,
    isFromCatalog,
    productDetail
  }

  return <ProductCosts {...props} />
}

export default ProductCostContainer
