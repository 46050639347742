import React from 'react'
import actions from './actions'
import Component from './component'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { DropzoneProps } from 'react-dropzone'

export type UploadContainerProps = {
  dropzoneOptions?: DropzoneProps
  uploadedFile?: string
  fileType: string
}

const Container = (ownProps: UploadContainerProps) => {
  const dispatch = useDispatch()
  const uploadFilesActions = bindActionCreators(actions, dispatch)
  const uploadFilesState = useSelector((state) => state.Components.UploadFiles)

  const props = {
    ...ownProps,
    uploadFilesActions,
    uploadFilesState
  }

  return <Component {...props} />
}

export default Container
