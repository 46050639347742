import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TIMESLOT } from '../../../endpoints'

type TimeslotAPI = {
  ID: number
  Hour: number
  Minute: number
}

export type Timeslot = {
  id: number
  hour: number
  minute: number
}

interface Response {
  timeslots: Timeslot[]
}

interface APIResponse {
  description: TimeslotAPI[]
  success: boolean
}

interface ListParams {
  dateInSeconds: number
}

export function timeslots(params: ListParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TIMESLOT}/timeSlot?date=${params.dateInSeconds}`)
    .then((response: AxiosResponse<APIResponse>) => {
      const timeslots = response.data.description

      const formatResponse: Response = {
        timeslots: timeslots.map(({ ID, Hour, Minute }) => {
          return {
            id: ID,
            hour: Hour,
            minute: Minute
          }
        })
      }

      return formatResponse
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
