import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ServiceModalActionsCreators from '../actionCreators/serviceABM'
import ServiceModal, { ServiceModalProps } from '../components/serviceAM'
import DeleteServiceModal from '../components/serviceDelete'

const ServiceABMContainer = () => {
  const dispatch = useDispatch()
  const {
    updateService,
    setMultiplier,
    setCancel,
    setSelectedDeposit,
    getDeposits,
    createServiceType,
    setId,
    setType,
    setDeleteModalVisibility,
    deleteService,
    getDepositsById
  } = bindActionCreators(ServiceModalActionsCreators, dispatch)

  const {
    open,
    error,
    isSaving,
    selectedService,
    isCreate,
    deposits,
    isLoadingDeposits,
    selectedDeposit,
    isCreatingService,
    isDeletingService,
    deleteModalVisible
  } = useSelector((state) => state.Services.modal)

  useEffect(() => {
    if (open) getDeposits()
  }, [open])

  useEffect(() => {
    if (!open) return
    getDepositsById({ deposits: selectedService.deposits })
  }, [selectedService])

  const handleCloseModal = async () => {
    setCancel()
  }

  const handleDeleteService = (serviceId: string) => {
    deleteService({ serviceId })
    setDeleteModalVisibility(false, selectedService)
  }

  const AMProps: ServiceModalProps = {
    open,
    error,
    isSaving: isSaving || isCreatingService,
    selectedService,
    onCancel: handleCloseModal,
    setMultiplier,
    updateService,
    isCreate,
    deposits,
    isLoadingDeposits,
    selectedDeposit,
    setSelectedDeposit,
    createServiceType,
    setId,
    setType
  }

  const DeleteProps = {
    isDeletingService,
    setDeleteModalVisibility,
    deleteModalVisible,
    selectedService,
    handleDeleteService
  }

  return (
    <>
      <ServiceModal {...AMProps} />
      <DeleteServiceModal {...DeleteProps} />
    </>
  )
}

export default ServiceABMContainer
