import { exportPayments } from './exportPayments'
import { list } from './list'
import { sendCSV } from './sendCSV'
import { update } from './update'
import { warningList } from './warnings'

export const BillingDataAPI = {
  update,
  list,
  sendCSV,
  warnings: warningList,
  exportPayments
}
