import {
  Card,
  CardField,
  CleanCreditCardFields,
  CleanErrors,
  CreateDraftRemovalRequest,
  CreateDraftRemovalSuccess,
  PaymentMpTypes,
  SaveCardFailure,
  SaveCardSuccess,
  SaveRemovalFailure,
  SaveRemovalSuccess,
  SetCardCvc,
  SetCardExpiry,
  SetCardFieldError,
  SetCardId,
  SetCardNumber,
  SetCardType,
  SetCardholderName,
  SetDocumentNumber,
  SetDocumentType,
  SetRemovalOperationId,
  SetSpreedlyPMID
} from '../types/payment-mp'
import { apiPrivate } from '../../../api'
import { URL_BASE_PROFILE, URL_BASE_TRANSACTIONS } from '../../../endpoints'
import Payment from 'payment'
import { getFormattedRemoval } from '../selectors/payment-mp'
import { getErrorMessage, handleErrorsWithAction } from '../../../utils/HandleErrors'
import { isAnyObjectSelected } from '../utils/objects'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { createCardToken, getPaymentMethods } from '../../../services/mercado-pago'
import { AppThunk } from '../../../store'
import { createSpreedlyCardToken } from '../../../services/spreedly'
import { API } from '../../../projectApi'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import commonActions from '../actions/common'
import editActions from './editMode'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'
import { AdminParams } from '../../../projectApi/TransactionHandler/Operation/Removals/admin'
import { PaymentParams } from '../../../projectApi/TransactionHandler/Operation/collectRemoval'

type ValidateCreditCardData = {
  cardNumber: string
  expiry: string
  cvc: string
  cardholderName: string
  documentNumber: string
}

const validateCreditCardInfo = ({
  cardNumber,
  expiry,
  cvc,
  cardholderName,
  documentNumber
}: ValidateCreditCardData): { fieldError?: CardField; error: string } => {
  if (documentNumber.length === 0) {
    return { fieldError: CardField.DOCUMENT, error: 'Ingrese el número de documento' }
  }
  if (cardholderName.length === 0) {
    return { fieldError: CardField.HOLDER, error: 'Ingrese el nombre del titular' }
  }
  if (!Payment.fns.validateCardNumber(cardNumber)) {
    return { fieldError: CardField.NUMBER, error: 'Número de la tarjeta inválido' }
  }
  if (!Payment.fns.validateCardExpiry(expiry)) {
    return { fieldError: CardField.EXPIRY, error: 'Fecha de venc. inválida' }
  }
  if (!Payment.fns.validateCardCVC(cvc, Payment.fns.cardType(cardNumber))) {
    return { fieldError: CardField.CVC, error: 'CVC inválido' }
  }
  return { error: '' }
}

const actions = {
  setCardID: (cardId: number): SetCardId => ({
    type: PaymentMpTypes.SET_CARD_ID,
    payload: { cardId }
  }),

  setCardNumber: (number: string): SetCardNumber => ({
    type: PaymentMpTypes.SET_CARD_NUMBER,
    payload: { number }
  }),

  setCardExpiry: (expiry: string): SetCardExpiry => ({
    type: PaymentMpTypes.SET_CARD_EXPIRY,
    payload: { expiry }
  }),

  setCardCVC: (cvc: string): SetCardCvc => ({
    type: PaymentMpTypes.SET_CARD_CVC,
    payload: { cvc }
  }),

  setCardholderName: (cardholderName: string): SetCardholderName => ({
    type: PaymentMpTypes.SET_CARDHOLDER_NAME,
    payload: { cardholderName }
  }),

  setDocumentType: (documentType: string): SetDocumentType => ({
    type: PaymentMpTypes.SET_DOCUMENT_TYPE,
    payload: { documentType }
  }),

  setDocumentNumber: (documentNumber: string): SetDocumentNumber => ({
    type: PaymentMpTypes.SET_DOCUMENT_NUMBER,
    payload: { documentNumber }
  }),

  setCardType: (type: string): SetCardType => ({
    type: PaymentMpTypes.SET_CARD_TYPE,
    payload: { type }
  }),

  setSpreedlyPMID: (spreedlyPMID: number): SetSpreedlyPMID => ({
    type: PaymentMpTypes.SET_SPREEDLY_PM_ID,
    payload: { spreedlyPMID }
  }),

  getUserSpreedlyPM:
    ({ userId }: { userId: number }): AppThunk =>
    async (dispatch) => {
      dispatch({ type: PaymentMpTypes.GET_SPREEDLY_PM_REQUEST })

      try {
        const paymentMethods = await API.PaymentCollector.paymentMethods.search({ userId })

        dispatch({
          type: PaymentMpTypes.GET_SPREEDLY_PM_SUCCESS,
          payload: { paymentMethods }
        })

        dispatch(commonActions.setPaymentBy(PAYMENT_BY_VALUES.SPREEDLY))
        if (paymentMethods.length > 0) {
          const mainPM = paymentMethods.find((pm: PaymentMethod) => pm.main)
          dispatch(actions.setSpreedlyPMID(mainPM ? mainPM.id : paymentMethods[0].id))
        }
      } catch (error) {
        handleErrorsWithAction(error, PaymentMpTypes.GET_SPREEDLY_PM_FAILURE, dispatch)
      }
    },

  getUserCards: (user_id: number): AppThunk => {
    return function (dispatch) {
      dispatch({ type: PaymentMpTypes.GET_CARDS_REQUEST })

      return apiPrivate.get(URL_BASE_PROFILE + '/admin/cards', { params: { user_id } }).then(
        (response) => {
          const cards = response.data.description
          dispatch({
            type: PaymentMpTypes.GET_CARDS_SUCCESS,
            payload: { cards }
          })

          if (cards.length > 0) {
            const mainCard = cards.find((a: Card) => a.Main)
            dispatch(actions.setCardID(mainCard ? mainCard.ID : cards[0].ID))
          }
        },
        (error) => {
          handleErrorsWithAction(error, PaymentMpTypes.GET_CARDS_FAILURE, dispatch)
        }
      )
    }
  },

  saveCard: (): AppThunk<Promise<boolean>> => {
    return function (dispatch, getState) {
      const {
        NewRemoval: { paymentMp, userSearch }
      } = getState()
      const { user } = userSearch

      const cardNumber = paymentMp.number.replace(/ /g, '').trim()
      const { expiry, cvc, cardholderName, documentType, documentNumber } = paymentMp

      const { fieldError, error } = validateCreditCardInfo({
        cardNumber,
        expiry,
        cvc,
        cardholderName,
        documentNumber
      })

      if (fieldError) {
        dispatch(actions.setCardFieldError(fieldError, error))
        return Promise.resolve(false)
      }

      dispatch({ type: PaymentMpTypes.SAVE_CARD_REQUEST })
      const toast = setToastLoading('Guardando tarjeta. Por favor espere...')

      const split = expiry.split('/')
      const cardExpirationMonth = split[0]
      const cardExpirationYear = split[1]

      return createCardToken({
        cardNumber,
        cardExpirationMonth,
        cardExpirationYear,
        securityCode: cvc,
        cardholderName,
        identificationType: documentType,
        identificationNumber: documentNumber
      }).then(
        async (cardToken) => {
          const paymentMethod = await getPaymentMethods({
            bin: cardNumber.replace(' ', '').substring(0, 6)
          })
          const paymentMethodID = paymentMethod.results[0].id
          const paymentTypeId = paymentMethod.results[0].payment_type_id

          const body = new FormData()

          if (user) body.append('userId', String(user.RealID))
          body.append('token', cardToken.id)
          body.append('payment_method', paymentMethodID)
          body.append('payment_type', paymentTypeId)

          return apiPrivate
            .post(URL_BASE_PROFILE + '/admin/cards', body)
            .then((response) => {
              const card = response.data.description
              dispatch(actions.saveCardSuccess(card))
              setToastSuccessUpdate(toast, { render: 'Tarjeta guardada' })

              return true
            })
            .catch((error) => {
              handleErrorsWithAction(error, PaymentMpTypes.SAVE_CARD_FAILURE, dispatch)
              setToastErrorUpdate(toast, {
                render: 'Error al guardar la tarjeta'
              })
              return false
            })
        },
        (error) => {
          let message = ''
          if (error.response) {
            message = getMPCardTokenErrorMessage(error.response.data)
          } else if (error.request) {
            message = 'Problemas de conexión'
          } else {
            message = 'Algo salió mal'
          }
          dispatch(actions.saveCardFailed(message))
          setToastErrorUpdate(toast, { render: message })
          return false
        }
      )
    }
  },

  saveSpreedlyCard: (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const {
      NewRemoval: { paymentMp, userSearch }
    } = getState()
    const { expiry, cvc, cardholderName, documentType, documentNumber } = paymentMp

    const cardNumber = paymentMp.number.replace(/ /g, '').trim()

    const { fieldError, error } = validateCreditCardInfo({
      cardNumber,
      expiry,
      cvc,
      cardholderName,
      documentNumber
    })

    if (fieldError) {
      dispatch(actions.setCardFieldError(fieldError, error))
      return Promise.resolve(false)
    }

    dispatch({ type: PaymentMpTypes.SAVE_SPREEDLY_CARD_REQUEST })

    const toast = setToastLoading('Agregando tarjeta. Por favor espere...')

    const { month, year } = Payment.fns.cardExpiryVal(expiry)

    try {
      const spreedlyResponse = await createSpreedlyCardToken({
        number: cardNumber,
        fullName: cardholderName,
        verificationValue: cvc,
        month,
        year
      })

      const token = spreedlyResponse.transaction.payment_method?.token

      if (!token) {
        setToastErrorUpdate(toast, { render: 'Hubo un error al crear la tarjeta. Inténtelo con una distinta' })
        return false
      }

      await API.PaymentCollector.paymentMethods.add({
        userId: Number(userSearch.user?.RealID || 0),
        additionalInfo: {
          cardholderIdentificationType: documentType,
          cardholderIdentificationNumber: documentNumber
        },
        token
      })
      dispatch({ type: PaymentMpTypes.SAVE_SPREEDLY_CARD_SUCCESS })
      Emitter.emit(Events.Removal.ADD_SPREEDLY_PM)
      setToastSuccessUpdate(toast, { render: 'Tarjeta guardada' })
      return true
    } catch (error: any) {
      const message = getErrorMessage(error)

      setToastErrorUpdate(toast, { render: message })
      handleErrorsWithAction(error, PaymentMpTypes.SAVE_SPREEDLY_CARD_FAILURE, dispatch)

      return false
    }
  },

  saveRemoval: (): AppThunk => {
    return function (dispatch, getState) {
      const state = getState()
      const formData = getFormattedRemoval(state)

      const {
        objects: { objectsToRemove }
      } = state.NewRemoval

      const toastId = setToastLoading('Guardando devolución, por favor espere...')

      if (!isAnyObjectSelected(objectsToRemove)) {
        dispatch(actions.saveRemovalError('No se seleccionaron objetos'))
        setToastErrorUpdate(toastId, {
          render: 'No se seleccionaron objetos'
        })
        return Promise.resolve(false)
      }

      dispatch({ type: PaymentMpTypes.SAVE_REMOVAL_REQUEST })

      const config = {
        headers: {
          'X-Request-Origin': 'backoffice|removal'
        }
      }

      return apiPrivate.post(URL_BASE_TRANSACTIONS + '/admin/operation/removal', formData, config).then(
        () => {
          setToastSuccessUpdate(toastId, {
            render: 'Devolución guardada con éxito'
          })
          dispatch(actions.saveRemovalSuccess())
          return true
        },
        (error) => {
          setToastErrorUpdate(toastId, {
            render: 'Error al guardar la devolución'
          })
          handleErrorsWithAction(error, PaymentMpTypes.SAVE_REMOVAL_FAILURE, dispatch)
          return false
        }
      )
    }
  },

  setRemovalOperationId: (operationId: number): SetRemovalOperationId => ({
    type: PaymentMpTypes.SET_REMOVAL_OPERATION_ID,
    payload: {
      operationId
    }
  }),

  saveRemovalSuccess: (): SaveRemovalSuccess => ({
    type: PaymentMpTypes.SAVE_REMOVAL_SUCCESS
  }),

  saveRemovalError: (message: string): SaveRemovalFailure => ({
    type: PaymentMpTypes.SAVE_REMOVAL_FAILURE,
    payload: {
      error: message
    }
  }),

  saveCardSuccess: (card: Card): SaveCardSuccess => ({
    type: PaymentMpTypes.SAVE_CARD_SUCCESS,
    payload: {
      card
    }
  }),

  saveCardFailed: (message: string): SaveCardFailure => ({
    type: PaymentMpTypes.SAVE_CARD_FAILURE,
    payload: {
      error: message
    }
  }),

  setCardFieldError: (field: CardField, error: string): SetCardFieldError => ({
    type: PaymentMpTypes.SET_CARD_FIELD_ERROR,
    payload: {
      field,
      error
    }
  }),
  createDraftRemoval:
    (params: AdminParams): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const toast = setToastLoading('Creando devolución en estado borrador. Por favor espere...')
      const request: CreateDraftRemovalRequest = {
        type: PaymentMpTypes.CREATE_DRAFT_REMOVAL_REQUEST
      }
      dispatch(request)

      try {
        const result = await API.TransactionHandler.Operation.Removals.admin(params)
        dispatch(actions.setRemovalOperationId(result.operationId))
        dispatch(editActions.getRemovalData({ operationId: result.operationId }))

        const success: CreateDraftRemovalSuccess = {
          type: PaymentMpTypes.CREATE_DRAFT_REMOVAL_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Devolución en estado borrador creada con éxito.' })

        dispatch(success)
        return true
      } catch (err: any) {
        const message = getErrorMessage(err)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(err, PaymentMpTypes.CREATE_DRAFT_REMOVAL_FAILURE, dispatch)
        return false
      }
    },
  payDraftRemoval:
    (params: PaymentParams): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const toast = setToastLoading('Pagando devolución. Por favor espere...')
      const request: CreateDraftRemovalRequest = {
        type: PaymentMpTypes.CREATE_DRAFT_REMOVAL_REQUEST
      }
      dispatch(request)

      try {
        await API.TransactionHandler.Operation.payRemoval(params)

        const success: CreateDraftRemovalSuccess = {
          type: PaymentMpTypes.CREATE_DRAFT_REMOVAL_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Devolución pagada con éxito.' })

        dispatch(success)
        return true
      } catch (err: any) {
        const message = getErrorMessage(err)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(err, PaymentMpTypes.CREATE_DRAFT_REMOVAL_FAILURE, dispatch)
        return false
      }
    },
  cleanErrors: (): CleanErrors => ({
    type: PaymentMpTypes.CLEAN_ERRORS
  }),
  cleanCreditCardFields: (): CleanCreditCardFields => ({
    type: PaymentMpTypes.CLEAN_CREDIT_CARD_FIELDS
  })
}

export default actions

// https://www.mercadopago.com.ar/developers/es/guides/payments/api/handling-responses
export function getMPCardTokenErrorMessage(response: { cause: { code: string }[] }) {
  if (Array.isArray(response.cause) && response.cause.length > 0) {
    const cause = response.cause[0]
    switch (cause.code) {
      case '205':
        return 'Ingresá el número de tu tarjeta'
      case '208':
        return 'Ingresá el mes de expiración'
      case '209':
        return 'Ingresá el año de expiración'
      case '212':
        return 'Ingresá el documento'
      case '213':
        return 'Ingresá el documento'
      case '214':
        return 'Ingresá el documento'
      case '220':
        return 'Ingresá tu banco emisor'
      case '221':
        return 'Ingresá el nombre del titular de la tarjeta'
      case '224':
        return 'Ingresá el código de seguridad'
      case 'E301':
      case 'E202':
        return 'El número de la tarjeta es inválido'
      case 'E302':
        return 'El código de seguridad es inválido'
      case '316':
        return 'El nombre del titular de la tarjeta es inválido'
      case '322':
        return 'El tipo de documento es inválido'
      case '323':
        return 'El tipo de documento es inválido'
      case '324':
        return 'El número de documento es inválido'
      case '325':
        return 'La fecha de expiración es inválida'
      case '326':
        return 'La fecha de expiración es inválida'
      default:
        return 'Algo salió mal'
    }
  } else {
    return 'Algo salió mal'
  }
}
