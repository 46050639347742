import { CamelCasedPropertiesDeep } from 'type-fest'
import { OverrideProps } from '../../../utils/utilityTypes'
import { Moment } from 'moment'
import { OBJECT_SITUATION } from '../../ObjectAdministration/common'

export interface APIPhoto {
  id: number
  url: string
  object_id: number
}

export interface APIIdentificationCode {
  id: number
  description: string
}

export interface APIProduct {
  multiplier: number
  name: string
}

export interface APIUser {
  id: number
  name: string
  last_name: string
  email: string
}

export interface APIObject {
  id: number
  guid: string
  object_situation: keyof typeof OBJECT_SITUATION | null
  status: string
  weight_in_gr: number
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  photo: APIPhoto
  identification_code: APIIdentificationCode
  description: string
  product: APIProduct
  translate_state: string
}

export interface APIRequest {
  id: number
  created_at: string
  updated_at: string
  deleted_at: string | null
  status: StatusStrings
  object: APIObject
  country_code: string
  object_id: number
  user: APIUser
  request_type: TypeStrings
}

export type Request = OverrideProps<CamelCasedPropertiesDeep<APIRequest>, 'createdAt' | 'updatedAt', Moment>

export enum TypeStrings {
  DONATION_REQUEST = 'DONATION_REQUEST',
  SALE_REQUEST = 'SALE_REQUEST'
}

export enum TypeOptions {
  DONATION_REQUEST = 'Donación',
  SALE_REQUEST = 'Venta'
}

export enum StatusStrings {
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED'
}

export enum StatusOptions {
  RECEIVED = 'Recibida',
  REJECTED = 'Rechazada',
  IN_PROGRESS = 'En Curso',
  APPROVED = 'Aprobada',
  CANCELLED = 'Cancelada'
}
