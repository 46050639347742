import { create } from './create'
import { deleteIt } from './delete'
import { discountList } from './list'
import { reasons } from './reasons'

export const UserDiscountsAPI = {
  list: discountList,
  reasons,
  create,
  delete: deleteIt
}
