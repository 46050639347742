import React from 'react'
import styles from './ProposalsMetrics.module.scss'
import { ProposalMetric } from '../types/metrics'
import StatusCard from '../../../components/StatusCard'
import { StatusCardSkeleton, StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { useSelectStatusCard } from '../../../components/StatusCard/hook'
import { formatNumber } from '../../../utils/formatNumber'

export type ProposalsMetricsProps = {
  metrics: ProposalMetric[]
  fetchingMetrics: boolean
}

const ProposalsMetrics: React.FC<ProposalsMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const [ProposalsMetrics] = useSelectStatusCard<ProposalMetric>({
    list: metrics,
    idListKey: 'id'
  })

  return (
    <div className={styles.statusContainer}>
      {fetchingMetrics ? (
        <>
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-1'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-2'} loadingColor="#1DD4B3" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-3'} loadingColor="#B25E09" />
        </>
      ) : (
        ProposalsMetrics.map((metric) => {
          return (
            <StatusCard
              key={metric.id}
              type={mapOfMetricTypes[metric.id]}
              customClassname={styles.warningCard}
              titleCard={metric.metric}
              total={formatNumber(metric.quantity, 0, true)}
              clicked={metric.clicked}
              description={mapOfMetricsDescriptions[metric.id]}
            />
          )
        })
      )}
    </div>
  )
}

export default ProposalsMetrics

const mapOfMetricTypes: { [key: string]: StatusCardTypes } = {
  total: 'info',
  quantityConvertedProposals: 'success',
  quantityCurrentProposals: 'warning'
}

export const mapOfMetrics: { [key: string]: string } = {
  total: 'Cotizaciones totales',
  quantityConvertedProposals: 'Cotizaciones convertidas',
  quantityCurrentProposals: 'Cotizaciones vigentes'
}

export const mapOfMetricsDescriptions: { [key: string]: string } = {
  total: 'Convertidas + Vigentes'
}
