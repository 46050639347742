export type TripsMetric = {
  id: string
  metric: string
  quantity: number
}

export type TripsMetricsState = {
  quantityMetrics: TripsMetric[]
  fetchingMetrics: boolean
}

export enum GetTripsMetricsTypes {
  GET_TRIPS_METRICS_REQUEST = 'TRIPS/METRICS/GET_TRIPS_METRICS_REQUEST',
  GET_TRIPS_METRICS_SUCCESS = 'TRIPS/METRICS/GET_TRIPS_METRICS_SUCCESS',
  GET_TRIPS_METRICS_FAILURE = 'TRIPS/METRICS/GET_TRIPS_METRICS_FAILURE'
}

export interface GetTripsMetricsRequest {
  type: typeof GetTripsMetricsTypes.GET_TRIPS_METRICS_REQUEST
}

export interface GetTripsMetricsSuccess {
  type: typeof GetTripsMetricsTypes.GET_TRIPS_METRICS_SUCCESS
  payload: {
    quantityMetrics: TripsMetric[]
  }
}

export interface GetTripsMetricsFailure {
  type: typeof GetTripsMetricsTypes.GET_TRIPS_METRICS_FAILURE
}

type GetTripsMetricsActions = GetTripsMetricsRequest | GetTripsMetricsSuccess | GetTripsMetricsFailure

export type TripsMetricsActions = GetTripsMetricsActions
