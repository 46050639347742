import React from 'react'
import RoutePermissionComponent from '../components/RoutePermission'
import { RouteProps } from 'react-router'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'
import { Permissions } from '../../UserLogged/permissions'

export type RoutePermissionProps = RouteProps & {
  permission: Permissions
}

const RoutePermission = (props: RoutePermissionProps) => {
  const permissions = usePermissions()
  return <RoutePermissionComponent permissions={permissions} {...props} />
}

export default RoutePermission
