import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APIService, Operation } from './common'
import snakecaseKeys from 'snakecase-keys'
import { Moment } from 'moment'

interface APIResponse {
  description: {
    operations: APIService[]
    quantity: number
  }
}
interface Response {
  operations: Operation[]
  total: number
}

export type ProposalListSortKey = 'id' | 'total_amount' | 'createdAt'
export type ProposalsSearchFilter = 'id' | 'prospectId'

export type ListParams = {
  Limit: number
  Offset: number
  Order?: SortDirection
  Column?: ProposalListSortKey
  id?: number
  prospect_id?: number
  country?: string
  statuses?: string
  createdAtFrom?: Moment
  createdAtTo?: Moment
}

export function list({ Limit, Offset, Order, Column, statuses, ...params }: ListParams): Promise<Response> {
  const snakesParams = {
    ...snakecaseKeys(params, { deep: true }),
    Limit,
    Offset,
    Order,
    Column,
    status_ids: statuses,
    created_at_from: params.createdAtFrom?.format('YYYY-MM-DD'),
    created_at_to: params.createdAtTo?.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operation/proposals`, { params: snakesParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const resp = response.data?.description
      if (!resp) throw new Error('Respuesta incorrecta al obtener las cotizaciones')

      const camelResponse: Response = {
        total: resp.quantity,
        operations: camelcaseKeys(resp.operations, { deep: true }).map((operation) => ({
          countryId: operation.countryId,
          id: operation.id,
          createdAt: operation.createdAt,
          prospect: {
            id: operation.prospect.id,
            name: operation.prospect.name,
            lastName: operation.prospect.lastname
          },
          prospectId: operation.prospectId,
          type: operation.type,
          origin: operation.origin,
          userCreated: {
            id: operation.userCreated.id,
            name: operation.userCreated.name,
            lastName: operation.userCreated.lastName
          },
          assignedAgent: operation.assignedAgent?.id
            ? {
                firstName: operation.assignedAgent?.firstName,
                id: operation.assignedAgent?.id,
                lastName: operation.assignedAgent?.lastName
              }
            : null,
          totalAmount: operation.totalAmount,
          status: operation.status,
          statusId: operation.statusId
        }))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
