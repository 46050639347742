import { Reducer } from 'redux'
import { ModalCalendarOperationTypes, OperationCalendarAction, OperationCalendarState } from '../types/common'

const initialState: OperationCalendarState = {
  selectedOperations: [],
  availableOperations: []
}

const OperationCalendarReducer: Reducer<OperationCalendarState, OperationCalendarAction> = (
  state = initialState,
  action
): OperationCalendarState => {
  switch (action.type) {
    case ModalCalendarOperationTypes.SET_SELECTED_DAYS_OPERATIONS:
      return {
        ...state,
        selectedOperations: action.payload.selectedOperations
      }
    case ModalCalendarOperationTypes.SET_AVAILABLE_OPERATIONS:
      return {
        ...state,
        availableOperations: action.payload.availableOperations
      }
    default:
      return state
  }
}

export default OperationCalendarReducer
