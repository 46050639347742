import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  },
  textDefault: {
    textAlign: 'center'
  }
}

const DefaultCbu = (props) => {
  const { defaultCbus, defaultCbuActionCreator, cbuActionsCreator } = props
  const { error, loading, defaultCbuModalOpen, userId, cbuId, cbuText, owner, bankName } = defaultCbus

  return (
    <Modal
      title={'CBU predeterminado'}
      visible={defaultCbuModalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={() => {
        defaultCbuActionCreator.setDefaultCbu(cbuId).then(() => {
          cbuActionsCreator.getCbus(userId)
        })
      }}
      onCancel={() => defaultCbuActionCreator.closeModal()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p style={styles.textDefault}>
        ¿Está seguro que quiere marcar el siguiente CBU como predeterminado? <br /> {owner} <br /> {bankName} -{' '}
        {cbuText}{' '}
      </p>
      <div className="container">
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

DefaultCbu.propTypes = {
  getCbus: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setDefaultCbu: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultCbuModalOpen: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  cbuId: PropTypes.number.isRequired,
  cbuText: PropTypes.string.isRequired
}

export default DefaultCbu
