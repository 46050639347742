import { Transaction } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'

export const GET_TRIP_TRANSACTIONS_REQUEST = 'TRIPS/TABLE/GET_TRIP_TRANSACTIONS_REQUEST'
export const GET_TRIP_TRANSACTIONS_SUCCESS = 'TRIPS/TABLE/GET_TRIP_TRANSACTIONS_SUCCESS'
export const GET_TRIP_TRANSACTIONS_FAILURE = 'TRIPS/TABLE/GET_TRIP_TRANSACTIONS_FAILURE'

export enum CommonDestination {
  TABLE = 'table',
  MODAL = 'modal'
}

export interface GetTripTransactionsRequest {
  type: typeof GET_TRIP_TRANSACTIONS_REQUEST
  payload: {
    destination: CommonDestination
    tripId: number
  }
}

export interface GetTripTransactionsSuccess {
  type: typeof GET_TRIP_TRANSACTIONS_SUCCESS
  payload: {
    destination: CommonDestination
    tripId: number
    transactions: Transaction[]
  }
}

export interface GetTripTransactionsFailure {
  type: typeof GET_TRIP_TRANSACTIONS_FAILURE
  payload: {
    destination: CommonDestination
    tripId: number
    error: string
  }
}

export type GetTripTransactionsAction =
  | GetTripTransactionsRequest
  | GetTripTransactionsSuccess
  | GetTripTransactionsFailure
