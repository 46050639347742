export type ObjectChangesFormatted = {
  objectId: number
  ownerId: number
  deletedAt: string | null
  deletedDescription: string | null
  objectDeletedReasonId: number
  identificationCodes: string
  createdAt: string
  location: string
  user: string
  objectStatusId: string
  objectSituation: string
  productId: number
  product: string
  description: string
  heightInCm: number
  widthInCm: number
  lengthInCm: number
  weightInGr: number
  source: string
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export const GET_OBJECT_CHANGES_REQUEST = 'CORPORATIVE_OBJECTS/CHANGES/GET_OBJECT_CHANGES_REQUEST'
export const GET_OBJECT_CHANGES_SUCCESS = 'CORPORATIVE_OBJECTS/CHANGES/GET_OBJECT_CHANGES_SUCCESS'
export const GET_OBJECT_CHANGES_FAILURE = 'CORPORATIVE_OBJECTS/CHANGES/GET_OBJECT_CHANGES_FAILURE'

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export type Params = {
  Limit: number
  Offset: number
  Column: string
  Order: Order
} & { [key: string]: string | number | undefined }

export interface ObjectChangesState {
  error: string
  loading: boolean
  objectChanges: ObjectChangesFormatted[]
  pagination: Pagination
}

export interface GetObjectChangesRequest {
  type: typeof GET_OBJECT_CHANGES_REQUEST
  payload: {
    objectID: number
  }
}

export interface GetObjectChangesSuccess {
  type: typeof GET_OBJECT_CHANGES_SUCCESS
  payload: {
    objectChanges: ObjectChangesFormatted[]
  }
}

export interface GetObjectChangesFailure {
  type: typeof GET_OBJECT_CHANGES_FAILURE
  payload: {
    error: string
  }
}

export type ObjectsChangesAction = GetObjectChangesRequest | GetObjectChangesSuccess | GetObjectChangesFailure
