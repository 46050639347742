import { combineReducers } from 'redux'
import NewBillingTableReducer from './reducers/newBillingTable'
import BillingByClientTableReducer from './reducers/billingByClientTable'
import PaymentModalReducer from './reducers/paymentModal'
import UserExclusionReducer from './reducers/userExclusionModals'
import BillPeriodModalReducer from './reducers/billPeriod'
import EmailBilledModalReducer from './reducers/emailBilledModal'
import CancelSubscriptionModalReducer from './reducers/cancelSubscriptionModal'
import discountsModalReducer from './reducers/billingDiscountsModal'

export default combineReducers({
  NewBillingTable: NewBillingTableReducer,
  BillingByClientTable: BillingByClientTableReducer,
  billPeriodModal: BillPeriodModalReducer,
  paymentModal: PaymentModalReducer,
  userExclusionModal: UserExclusionReducer,
  emailBilledModal: EmailBilledModalReducer,
  cancelSubscriptionModal: CancelSubscriptionModalReducer,
  discountsModal: discountsModalReducer
})
