import { STEP_OPTION, FunnelState } from '../constants'

export type ReasonsModalState = {
  openModal: boolean
  loading: boolean
  error: string
  interestReason: string
  textReason: {
    inputOpen: boolean
    value: string
    errorMessage: string
    limitText: number
  }
  loseReason: string
  discardReason: string
  disabledSelect: boolean
  optionSelected: STEP_OPTION
  leadId: number
  leadFunnelState?: FunnelState | string
  lackInterestReason: string
}

export const SET_MODAL_OPEN = 'LEADS/REASONS_MODAL/SET_MODAL_OPEN'

export interface OpenReasonsModal {
  type: typeof SET_MODAL_OPEN
  payload: { openModal: boolean; leadId?: number; leadFunnelState?: FunnelState }
}

export const SET_INTEREST_REASON = 'LEADS/REASONS_MODAL/SET_INTEREST_REASON'
export const SET_TEXT_REASON = 'LEADS/REASONS_MODAL/SET_TEXT_REASON'
export const SET_ERROR_TEXT_REASON = 'LEADS/REASONS_MODAL/SET_ERROR_TEXT_REASON'
export const SET_OPEN_INPUT_TEXT = 'LEADS/REASONS_MODAL/SET_OPEN_INPUT_TEXT'
export const SET_LACK_INTEREST_REASON = 'LEADS/REASONS_MODAL/SET_LACK_INTEREST_REASON'
export const SET_LOSE_REASON = 'LEADS/REASONS_MODAL/SET_LOSE_REASON'
export const SET_DISCARD_REASON = 'LEADS/REASONS_MODAL/SET_DISCARD_REASON'
export const SET_OPTION_SELECTED = 'LEADS/REASONS_MODAL/SET_OPTION_SELECTED'
export const SET_CHECK_SELECTED = 'LEADS/REASONS_MODAL/SET_CHECK_SELECTED'

export interface SetInterestReason {
  type: typeof SET_INTEREST_REASON
  payload: { interestReason: string }
}

export interface SetTextReason {
  type: typeof SET_TEXT_REASON
  payload: { textReason: string }
}

export interface SetErrorTextReason {
  type: typeof SET_ERROR_TEXT_REASON
  payload: { messageError: string }
}

export interface SetOpenInputText {
  type: typeof SET_OPEN_INPUT_TEXT
  payload: { inputOpen: boolean }
}

export interface SetLackInterestReason {
  type: typeof SET_LACK_INTEREST_REASON
  payload: { lackInterestReason: string }
}

export interface SetLoseReason {
  type: typeof SET_LOSE_REASON
  payload: { loseReason: string }
}

export interface SetDiscardReason {
  type: typeof SET_DISCARD_REASON
  payload: { discardReason: string }
}

export interface SetOptionSelectd {
  type: typeof SET_OPTION_SELECTED
  payload: { option: STEP_OPTION }
}

export interface SetCheckSelected {
  type: typeof SET_CHECK_SELECTED
  payload: { loseReason: string }
}

type SetterDataActions =
  | SetInterestReason
  | SetTextReason
  | SetErrorTextReason
  | SetOpenInputText
  | SetLackInterestReason
  | SetLoseReason
  | SetDiscardReason
  | SetOptionSelectd
  | SetCheckSelected

export const UPDATED_LEAD = 'LEADS/REASONS_MODAL/EDIT_LEAD'
export const UPDATED_LEAD_SUCCESS = 'LEADS/REASONS_MODAL/EDIT_LEAD_SUCCESS'
export const UPDATED_LEAD_FAILURE = 'LEADS/REASONS_MODAL/UPDATED_LEAD_FAILURE'

export interface UpdatedLead {
  type: typeof UPDATED_LEAD
}

export interface UpdatedLeadSuccess {
  type: typeof UPDATED_LEAD_SUCCESS
  payload: { message: string }
}

export interface UpdatedLeadFailure {
  type: typeof UPDATED_LEAD_FAILURE
  payload: { message: string }
}

type UpdatedLeadActions = UpdatedLead | UpdatedLeadSuccess | UpdatedLeadFailure

export type ReasonsModalActions = OpenReasonsModal | SetterDataActions | UpdatedLeadActions
