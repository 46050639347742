import clsx from 'clsx'
import React from 'react'
import useInjectChildrenWithProps from '../../utils/hooks/useInjectChildrenWithProps'
import buttonGroupStyles from './ButtonGroup.module.scss'

type ButtonGroupProps<T extends string | number> = {
  children: React.ReactNode
  activeButtonId: T | null
  onChange: (newId: T) => void
}

const ButtonGroup = <T extends string | number>({ children, activeButtonId, onChange }: ButtonGroupProps<T>) => {
  const childrenWithProps = useInjectChildrenWithProps(children, (child) => ({
    active: activeButtonId === child.props.id,
    onClick: onChange
  }))

  return <div className={buttonGroupStyles.smallTabs}>{childrenWithProps}</div>
}

export default ButtonGroup

export const Tab: React.FC<{
  id: string | number
  active?: boolean
  onClick?: (id: number | string | null) => void
}> = ({ id, active, children, onClick }) => {
  return (
    <button
      key={id}
      className={clsx(buttonGroupStyles.tab, active && buttonGroupStyles.active)}
      onClick={() => onClick && onClick(!active ? id : null)}>
      <span>{children}</span>
    </button>
  )
}
