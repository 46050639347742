import * as React from 'react'

const ArrowFromBracket = (props: any) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 2h2.667A1.333 1.333 0 0 1 14 3.333v9.334A1.334 1.334 0 0 1 12.667 14H10M6.667 11.333 10 8 6.667 4.667M10 8H2"
      stroke="#0D4DA7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowFromBracket
