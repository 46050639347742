import { API } from '../../../projectApi'
import { Discount, Response } from '../../../projectApi/TransactionHandler/BillingDiscounts/list'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { Pagination } from '../../../utils/searchFilterUtils'
import {
  GET_BILLING_BY_CLIENT_FAILURE,
  GET_BILLING_BY_CLIENT_REQUEST,
  GET_BILLING_BY_CLIENT_SUCCESS,
  GET_CLIENT_DISCOUNTS_FAILURE,
  GET_CLIENT_DISCOUNTS_REQUEST,
  GET_CLIENT_DISCOUNTS_SUCCESS,
  GET_PDF_BY_CLIENT_FAILURE,
  GET_PDF_BY_CLIENT_REQUEST,
  GET_PDF_BY_CLIENT_SUCCESS,
  GetBillingByClientRequest,
  GetBillingByClientSuccess,
  GetBillingClientDiscountRequest,
  GetBillingClientDiscountSuccess,
  GetPdfByClientRequest,
  GetPdfByClientSuccess,
  GetClientBillingInformationTypes,
  QueryTypes,
  SET_QUERY_DATA,
  setQueryData,
  GetClientBillingInformationRequest,
  GetClientBillingInformationSuccess
} from '../types/billingByClientTable'

const DISCOUNT_LIMIT = 10

const getRemainingDiscounts = async (billingID: number, total: number): Promise<Discount[]> => {
  const requests = []
  for (let i = DISCOUNT_LIMIT; i < total; i = i + DISCOUNT_LIMIT) {
    const params = { limit: DISCOUNT_LIMIT, offset: i, billingId: billingID }
    requests.push(API.TransactionHandler.BillingDiscounts.list(params))
  }
  return Promise.all(requests).then((responses: Response[]) =>
    responses.reduce((acc: Discount[], response) => [...acc, ...response.billingDiscounts], [])
  )
}

const BillingByClientActionCreator = {
  getBillingByClient:
    ({ pagination, userId }: { pagination: Pagination; userId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetBillingByClientRequest = {
        type: GET_BILLING_BY_CLIENT_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page
        }
      }

      dispatch(request)

      try {
        const { billings, total } = await API.TransactionHandler.Billings.list({
          userId,
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          authorizationEnabled: true
        })
        const success: GetBillingByClientSuccess = {
          type: GET_BILLING_BY_CLIENT_SUCCESS,
          payload: {
            billingsByClient: billings.map((b) => ({
              ...b,
              objects: b.objects.map((obj) => ({
                ...obj,
                discount: null,
                objectApplications: null
              }))
            })),
            total
          }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_BILLING_BY_CLIENT_FAILURE, dispatch)
      }
    },

  getPdfWithBillingId:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: GetPdfByClientRequest = {
        type: GET_PDF_BY_CLIENT_REQUEST
      }
      dispatch(request)

      try {
        const url = await API.TransactionHandler.OtherEndpoints.doesAuthorizationDocumentExist({
          id: billingId
        })

        const success: GetPdfByClientSuccess = {
          type: GET_PDF_BY_CLIENT_SUCCESS
        }

        Emitter.emit(Events.Billing.AUTHORIZATION_EXISTS, { pdfUrl: url })
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_PDF_BY_CLIENT_FAILURE, dispatch)
        Emitter.emit(Events.Billing.AUTHORIZATION_DOESNT_EXIST)
      }
    },

  getBillingDiscounts:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: GetBillingClientDiscountRequest = {
        type: GET_CLIENT_DISCOUNTS_REQUEST
      }

      dispatch(request)

      try {
        const { billingDiscounts, total } = await API.TransactionHandler.BillingDiscounts.list({
          billingId,
          limit: DISCOUNT_LIMIT
        })
        const remainingBillingDiscounts = await getRemainingDiscounts(billingId, total)
        const allBillingDiscounts = [...billingDiscounts, ...remainingBillingDiscounts]

        const success: GetBillingClientDiscountSuccess = {
          type: GET_CLIENT_DISCOUNTS_SUCCESS,
          payload: {
            billingId,
            discounts: allBillingDiscounts
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_CLIENT_DISCOUNTS_FAILURE, dispatch)
      }
    },
  setQueryData: (queryData: QueryTypes): setQueryData => ({
    type: SET_QUERY_DATA,
    payload: { queryData }
  }),

  getClientBillingInformation:
    (userId: number): AppThunk =>
    async (dispatch) => {
      const request: GetClientBillingInformationRequest = {
        type: GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_REQUEST
      }

      dispatch(request)

      try {
        const response = await API.TransactionHandler.UserBillingInformation.getByUserId({ userId })
        if (response.results.length <= 0)
          throw String('Error al obtener los datos del estado de facturación del cliente')

        const success: GetClientBillingInformationSuccess = {
          type: GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_SUCCESS,
          payload: {
            clientBillingInformation: response.results[0]
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_FAILURE, dispatch)
      }
    }
}

export default BillingByClientActionCreator
