import { APIDonationRequest } from './DonationRequest'
import { APIMetrics } from './Metrics'
import { RequestsAPI } from './Requests'
import { APISalesRequest } from './SalesRequest'
import { reasons } from './reasons'
import { status } from './status'
export const SalesAPI = {
  Requests: RequestsAPI,
  SalesRequest: APISalesRequest,
  DonationRequest: APIDonationRequest,
  Metrics: APIMetrics,
  reasons,
  status
}
