import { CamelCasedPropertiesDeep } from 'type-fest'

/* eslint-disable camelcase */
export interface APIServices {
  id: string
  multiplier: number
  operation_type: ServiceOperationType
  type: string
}

export type Services = CamelCasedPropertiesDeep<APIServices>

export type APIDestinationDeposit = {
  address: {
    address_string: string
    apartment: string
    city: string
    comment: string
    floor: string
    number: string
    postal_code: string
    province: string
    street: string
  }
  country_id: string
  id: number
  name: string
}

export type DestinationDeposit = CamelCasedPropertiesDeep<APIDestinationDeposit>

export enum ServiceTypeId {
  BOXES = 'boxes',
  ITEMS = 'items',
  M3 = 'm3',
  BAGGAGE = 'baggage',
  WINE = 'wine'
}

export enum ServiceType {
  BOXES = 'boxes',
  ITEMS = 'items',
  M3 = 'm3',
  BAGGAGE = 'valijas',
  WINE = 'vino'
}

export enum ServiceOperationType {
  IP = 'IP'
}
