import React, { ChangeEvent } from 'react'
import extraActions from '../actions/extras'
import styles from './ExtraService.module.scss'
import ExtraValueData from '../../../common/operations/components/ExtraValueData/ExtraValueData'
import { CollapsablePanel, ProposalHeader } from '../../../components/CollapsablePanel/CollapsablePanel'
import { Checkbox, Input } from 'antd'

export type ExtraServiceProps = {
  globalDisabled: boolean
  extraAssistantCount: number
  extraAssistantPrice: number
  extraAssistantEnabled: boolean
  additionalCostPrice: number
  additionalCostEnabled: boolean
  additionalCostComment: string
  loadingExtras: boolean
  currencySymbol: string
  extraActions: typeof extraActions
}

const ExtraService: React.FC<ExtraServiceProps> = ({
  globalDisabled,
  extraActions,
  extraAssistantCount,
  extraAssistantPrice,
  currencySymbol,
  extraAssistantEnabled,
  additionalCostPrice,
  additionalCostEnabled,
  additionalCostComment,
  loadingExtras
}) => {
  const {
    setExtraAssistantCount,
    setExtraAssistantEnabled,
    setAdditionalCostEnabled,
    setAdditionalCostComment,
    setAdditionalCost
  } = extraActions

  const onAdditionalCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (/^([0-9]{0,5}|(.*))$/.test(value)) {
      setAdditionalCost(parseFloat(parseFloat(value).toFixed(2)))
    }
  }

  return (
    <CollapsablePanel header={<ProposalHeader title="2. Adicionales" />}>
      <div className={styles.control}>
        <ExtraValueData
          disabled={globalDisabled}
          titleExtra="Asistentes de carga/descarga"
          titleStyles={styles.extraTitle}
          loadingExtras={loadingExtras}
          currencySymbol={currencySymbol}
          extraValuePrice={extraAssistantPrice}
          extraValueCount={extraAssistantCount}
          extraValueEnabled={extraAssistantEnabled}
          setExtraValueCount={setExtraAssistantCount}
          setExtraValueEnabled={setExtraAssistantEnabled}
        />
        <div className={styles.additionalCostContainer}>
          <Checkbox
            className={styles.additionalCostCheckbox}
            checked={additionalCostEnabled}
            disabled={globalDisabled}
            onChange={(e) => setAdditionalCostEnabled(e.target.checked)}>
            Otro
          </Checkbox>
          <Input
            className={styles.additionalCostComment}
            disabled={!additionalCostEnabled || globalDisabled}
            placeholder="Nombre del servicio"
            value={additionalCostComment}
            onChange={(e) => setAdditionalCostComment(e.target.value)}
          />
          <Input
            className={styles.additionalCostPrice}
            type="number"
            min="0"
            prefix={currencySymbol}
            disabled={!additionalCostEnabled || globalDisabled}
            placeholder="Monto"
            defaultValue={additionalCostPrice}
            value={additionalCostPrice}
            onChange={onAdditionalCostChange}
          />
        </div>
      </div>
    </CollapsablePanel>
  )
}
export default ExtraService
