import { Reducer } from 'redux'
import {
  CANCEL_SEND_BY_EMAIL_MODAL,
  CLOSE_SEND_BY_EMAIL_MODAL,
  OPEN_SEND_BY_EMAIL_MODAL,
  ProposalSendByEmailAction,
  SEND_BY_EMAIL_FAILURE,
  SEND_BY_EMAIL_REQUEST,
  SEND_BY_EMAIL_SUCCESS,
  SET_EMAIL,
  SET_ERROR,
  SendByEmailState
} from '../types/sendByMail'

const initialState: SendByEmailState = {
  open: false,
  proposalId: '',
  email: '',
  isLoading: false,
  error: ''
}

const SendByEmailReducer: Reducer<SendByEmailState, ProposalSendByEmailAction> = (
  state = initialState,
  action
): SendByEmailState => {
  switch (action.type) {
    case OPEN_SEND_BY_EMAIL_MODAL:
      return {
        ...state,
        open: true,
        proposalId: action.payload.proposalId,
        email: ''
      }
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload.email,
        error: ''
      }
    case CANCEL_SEND_BY_EMAIL_MODAL:
      return initialState
    case CLOSE_SEND_BY_EMAIL_MODAL:
      return initialState
    case SEND_BY_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case SEND_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        open: false
      }
    case SEND_BY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default SendByEmailReducer
