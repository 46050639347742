import { Pagination } from '../../../utils/searchFilterUtils'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_SERVICES_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GetServicesRequest,
  GetServicesSuccess,
  ServicesTableSort
} from '../types/servicesTable'

const ServicesTableActionCreators = {
  getServices:
    ({ pagination, sort }: { pagination: Pagination; sort: ServicesTableSort }): AppThunk =>
    async (dispatch) => {
      const request: GetServicesRequest = {
        type: GET_SERVICES_REQUEST
      }

      dispatch(request)

      try {
        const { services, total } = await API.CategoryCreation.ServiceTypes.search({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          sort: sort?.field,
          order: sort?.direction,
          displayOrder: 1
        })

        const success: GetServicesSuccess = {
          type: GET_SERVICES_SUCCESS,
          payload: {
            services,
            total
          }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_SERVICES_FAILURE, dispatch)
      }
    }
}

export default ServicesTableActionCreators
