import { STATUS_ID, SearchableParams } from '../../../projectApi/Sales/SalesRequest/common'
import { SalesRequest } from '../../../projectApi/Sales/SalesRequest/list'
import { SaleStatusOptions } from '../../../projectApi/Sales/status'
import { CategoryFilter, DateRangeFilter, SearchFilter } from '../../../utils/searchFilterUtils'
import { GetSalesStatusAction } from './common'

export type SalesRequestSearchKey = keyof SearchableParams
export type SalesRequestSearchFilter = SearchFilter<SalesRequestSearchKey>

export type SalesRequestCategoryFilterKey = 'status'
export type SalesRequestCategoryFilter = CategoryFilter<SalesRequestCategoryFilterKey, STATUS_ID>

export type SalesRequestDateRangeFilter = DateRangeFilter<SalesRequestTableDateRangeKey>

export type SalesRequestMetric = {
  id: string
  metric: string
  quantity: number
}

export enum SalesRequestTableDateRangeKey {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}
export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface SalesRequestState {
  salesRequest: SalesRequest[]
  metrics: SalesRequestMetric[]
  loading: boolean
  fetchingMetrics: boolean
  error: string
  type?: string
  pagination: Pagination
  categoryFilter: SalesRequestCategoryFilter
  searchFilters: SalesRequestSearchFilter[]
  loadingTable: boolean
  showDemoUsers: boolean
  dateRangeFilters: SalesRequestDateRangeFilter[]
  statusOptions: SaleStatusOptions[]
}

export const GET_SALES_REQUEST_REQUEST = 'SALES_RESQUEST/SALES_REQUEST_TABLE_REQUEST'
export const GET_SALES_REQUEST_SUCCESS = 'SALES_RESQUEST/SALES_REQUEST_TABLE_SUCCESS'
export const GET_SALES_REQUEST_FAILURE = 'SALES_RESQUEST/SALES_REQUEST_TABLE_FAILURE'

export interface GetSalesRequestRequest {
  type: typeof GET_SALES_REQUEST_REQUEST
  payload: {
    pageSize: number
    newPage: number
    showDemoUsers?: boolean
    type?: string
    dateRangeFilters?: SalesRequestDateRangeFilter[]
    searchFilters?: SalesRequestSearchFilter[]
    categoryFilter?: SalesRequestCategoryFilter
  }
}

export interface GetSalesRequestSuccess {
  type: typeof GET_SALES_REQUEST_SUCCESS
  payload: {
    salesRequest: SalesRequest[]
    total: number
  }
}

export interface GetSalesRequestFailure {
  type: typeof GET_SALES_REQUEST_FAILURE
  payload: {
    error: string
  }
}

export const SEND_SALES_REQUEST_NOTIFICATION_REQUEST = 'SALES_RESQUEST/SEND_SALES_REQUEST_NOTIFICATION_REQUEST'
export const SEND_SALES_REQUEST_NOTIFICATION_SUCCESS = 'SALES_RESQUEST/SEND_SALES_REQUEST_NOTIFICATION_SUCCESS'
export const SEND_SALES_REQUEST_NOTIFICATION_FAILURE = 'SALES_RESQUEST/SEND_SALES_REQUEST_NOTIFICATION_FAILURE'

export interface SendSalesRequestNotificationRequest {
  type: typeof SEND_SALES_REQUEST_NOTIFICATION_REQUEST
}

export interface SendSalesRequestNotificationSuccess {
  type: typeof SEND_SALES_REQUEST_NOTIFICATION_SUCCESS
}

export interface SendSalesRequestNotificationFailure {
  type: typeof SEND_SALES_REQUEST_NOTIFICATION_FAILURE
}

export type GetSalesRequestAction = GetSalesRequestRequest | GetSalesRequestSuccess | GetSalesRequestFailure

export type SendSalesRequestNotificationAction =
  | SendSalesRequestNotificationRequest
  | SendSalesRequestNotificationSuccess
  | SendSalesRequestNotificationFailure

export enum GetSalesRequestMetricsTypes {
  GET_SALES_REQUEST_METRICS_REQUEST = 'SALES_REQUEST/GET_SALES_REQUEST_METRICS_REQUEST',
  GET_SALES_REQUEST_METRICS_SUCCESS = 'SALES_REQUEST/GET_SALES_REQUEST_METRICS_SUCCESS',
  GET_SALES_REQUEST_METRICS_FAILURE = 'SALES_REQUEST/GET_SALES_REQUEST_METRICS_FAILURE'
}

export interface GetSalesRequestMetricsRequest {
  type: typeof GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_REQUEST
}

export interface GetSalesRequestMetricsSuccess {
  type: typeof GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_SUCCESS
  payload: {
    metrics: SalesRequestMetric[]
  }
}

export interface GetSalesRequestMetricsFailure {
  type: typeof GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_FAILURE
}

export enum RefreshTableDataTypes {
  REFRESH_TABLE_DATA = 'SALES_REQUEST/REFRESH_TABLE_DATA'
}

export interface RefreshTableDataRequest {
  type: typeof RefreshTableDataTypes.REFRESH_TABLE_DATA
}

export type RefreshTableDataActions = RefreshTableDataRequest

export type GetSalesRequestMetricsActions =
  | GetSalesRequestMetricsRequest
  | GetSalesRequestMetricsSuccess
  | GetSalesRequestMetricsFailure

export type SalesRequestAction =
  | GetSalesRequestAction
  | GetSalesStatusAction
  | SendSalesRequestNotificationAction
  | GetSalesRequestMetricsActions
  | RefreshTableDataActions
