import React, { useEffect, useState } from 'react'
import Select, { BaseOption } from '../../../../components/Select/Select'
import Styles from './Costs.module.scss'
import InputGroup from '../../../../components/InputGroup'
import { TextField } from '../../../../components/TextField/TextField'
import { Checkbox } from 'antd'
import { Row, handleFieldChange } from './GeneralData'
import generalDataStyles from './GeneralData.module.scss'
import {
  AllowedCountriesCodes,
  AutomaticPriceOperationType,
  CostFieldsNameEnum,
  EligibilityFields,
  ProductByCountriesInformation,
  ProductCostsState
} from '../../types/productCosts'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { handleErrorNumberFields } from '../../utils'
import { useCountryInfo } from '../../../../components/CountrySelector/hooks/hooks'
import UploadPhotos from '../../../../components/UploadPhotos/container'
import { Response as PhotoResponse } from '../../../../projectApi/CategoryCreation/Photos/uploadPhoto'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { ProductCatalogue } from '@/projectApi/CategoryCreation/Catalog/search'

export type ProductCostProps = {
  setBooleanFields: ({
    value,
    country,
    field
  }: {
    value: boolean
    country: AllowedCountriesCodes
    field: keyof ProductByCountriesInformation
  }) => void
  setStringField: ({
    value,
    country,
    field
  }: {
    value: string
    country: AllowedCountriesCodes
    field: keyof ProductByCountriesInformation
  }) => void
  setProductEligibility: ({
    value,
    country,
    field
  }: {
    value: boolean
    country: AllowedCountriesCodes
    field: EligibilityFields
  }) => void
  state: ProductCostsState
  handleGetAutomaticPrice: (
    { target }: CheckboxChangeEvent,
    countryId: AllowedCountriesCodes,
    operationType: keyof AutomaticPriceOperationType
  ) => void
  productDetail: ProductCatalogue | null
  isFromCatalog: boolean
}

export type CountryOptions = BaseOption<AllowedCountriesCodes | CountryIdCode.ALL>

const countryFilteredOptions: CountryOptions[] = [
  {
    label: 'Todos',
    value: CountryIdCode.ALL
  },
  {
    label: 'Argentina',
    value: CountryIdCode.ARGENTINA
  },
  {
    label: 'España',
    value: CountryIdCode.SPAIN
  }
]

export const ProductCosts: React.FC<ProductCostProps> = (props) => {
  const [countryFiltered, setCountryFiltered] = useState<CountryOptions>(countryFilteredOptions[0])
  const [newPhotoCountrySelected, setNewPhotoCountrySelected] = useState<AllowedCountriesCodes | null>(null)

  const onUploadedPhoto = (photos: PhotoResponse[]) => {
    const photo = photos[0]
    props.setStringField({ country: newPhotoCountrySelected!, field: 'photoUrl', value: photo.url })
    props.setStringField({ country: newPhotoCountrySelected!, field: 'photoKey', value: photo.s3Key })
  }

  useEvents(Events.UploadPhotos.UPLOAD_PHOTO_SUCCESS, onUploadedPhoto)

  const renderProductCostPerCountry = () => {
    if (countryFiltered.value === CountryIdCode.ALL) {
      return Object.entries(props.state).map(([country, countryState]) => {
        return (
          <ProductCostFormByCountry
            key={country}
            setStringField={props.setStringField}
            state={props.state}
            handleGetAutomaticPrice={props.handleGetAutomaticPrice}
            setProductEligibility={props.setProductEligibility}
            setBooleanFields={props.setBooleanFields}
            country={country as AllowedCountriesCodes}
            setNewPhotoCountrySelected={setNewPhotoCountrySelected}
            isFromCatalog={props.isFromCatalog}
            productDetail={props.productDetail}
          />
        )
      })
    }
    return (
      <ProductCostFormByCountry
        setStringField={props.setStringField}
        state={props.state}
        handleGetAutomaticPrice={props.handleGetAutomaticPrice}
        setProductEligibility={props.setProductEligibility}
        setBooleanFields={props.setBooleanFields}
        country={countryFiltered.value}
        setNewPhotoCountrySelected={setNewPhotoCountrySelected}
        isFromCatalog={props.isFromCatalog}
        productDetail={props.productDetail}
      />
    )
  }

  return (
    <div className={generalDataStyles.groupContainer}>
      <div>
        <h2 className={generalDataStyles.title}>Ingresá el nombre y costos</h2>
        <p className={generalDataStyles.subtitle}>Estos datos pueden variar de acuerdo a los países</p>
      </div>
      <div>
        <p style={{ margin: 0 }}>Selecciona un país</p>
        <Select
          onSelect={(value) => setCountryFiltered(value)}
          options={countryFilteredOptions}
          placeholder="País"
          className={Styles.countrySelect}
          selected={countryFiltered}
        />
      </div>
      {renderProductCostPerCountry()}
    </div>
  )
}

type ProductCostCountryProps = ProductCostProps & {
  country: CountryIdCode.SPAIN | CountryIdCode.ARGENTINA
  setNewPhotoCountrySelected: (country: AllowedCountriesCodes) => void
}

const ProductCostFormByCountry: React.FC<ProductCostCountryProps> = ({
  setStringField,
  state,
  handleGetAutomaticPrice,
  setProductEligibility,
  setBooleanFields,
  setNewPhotoCountrySelected,
  country,
  isFromCatalog,
  productDetail
}) => {
  const countryInfo = useCountryInfo(country)
  const countryState = state[country]

  const productDetailByCountry = isFromCatalog
    ? productDetail?.productByCountries.find((product) => product.countryId === country)
    : null

  useEffect(() => {
    if (isFromCatalog) {
      setStringField({ country, field: CostFieldsNameEnum.PRODUCT_NAME, value: productDetailByCountry?.name || '' })
    }
  }, [])

  return (
    <InputGroup country={countryInfo} title={countryInfo.label} style={{ position: 'relative', padding: 16 }}>
      <div className={Styles.groupContainer}>
        <div>
          <TextField
            label="Nombre"
            onChange={(value) =>
              setStringField({
                country,
                field: CostFieldsNameEnum.PRODUCT_NAME,
                value
              })
            }
            value={countryState.productName}
          />
        </div>
        <div>
          <h4 className={Styles.section}>Costos</h4>
        </div>

        <div>
          <h4>Ingreso</h4>
          <Row>
            <TextField
              label="Costo por piso"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.DEPOSIT_COST_PER_FLOOR,
                    value: checkedValue
                  })
                )
              }
              value={
                countryState.isDepositAutomaticPrice
                  ? countryState.depositAutomaticPrice.toString()
                  : countryState.depositCostPerFloor
              }
              disabled={countryState.isDepositAutomaticPrice}
              error={handleErrorNumberFields(countryState.depositCostPerFloor)}
            />

            <TextField
              label="Costo de embalaje"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.DEPOSIT_PACKAGING_COST,
                    value: checkedValue
                  })
                )
              }
              value={countryState.depositPackagingCost}
              error={handleErrorNumberFields(countryState.depositPackagingCost)}
            />
            <TextField
              label="Costo de desarmado"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.DEPOSIT_DISASSEMBLY_COST,
                    value: checkedValue
                  })
                )
              }
              value={countryState.depositDisassemblyCost}
              error={handleErrorNumberFields(countryState.depositDisassemblyCost)}
            />
          </Row>
          <div>
            <Checkbox
              onChange={(value) => {
                handleGetAutomaticPrice(value, country, 'depositAutomaticPrice')
                setBooleanFields({
                  country,
                  field: 'isDepositAutomaticPrice',
                  value: value.target.checked
                })
              }}
              checked={countryState.isDepositAutomaticPrice}>
              Calcular automáticamente
            </Checkbox>
          </div>
        </div>

        <div>
          <h4>Devolución</h4>
          <Row>
            <TextField
              label="Costo por piso"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.REMOVAL_COST_PER_FLOOR,
                    value: checkedValue
                  })
                )
              }
              value={
                countryState.isRemovalAutomaticPrice
                  ? countryState.removalAutomaticPrice.toString()
                  : countryState.removalCostPerFloor
              }
              disabled={countryState.isRemovalAutomaticPrice}
              error={handleErrorNumberFields(countryState.removalCostPerFloor)}
            />
            <TextField
              label="Costo desempaquetar"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.REMOVAL_UNPACKAGING_COST,
                    value: checkedValue
                  })
                )
              }
              value={countryState.removalUnpackagingCost}
              error={handleErrorNumberFields(countryState.removalUnpackagingCost)}
            />
            <TextField
              label="Costo de armado"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.REMOVAL_ASSEMBLY_COST,
                    value: checkedValue
                  })
                )
              }
              value={countryState.removalAssemblyCost}
              error={handleErrorNumberFields(countryState.removalAssemblyCost)}
            />
          </Row>
          <div>
            <Checkbox
              onChange={(value) => {
                handleGetAutomaticPrice(value, country, 'removalAutomaticPrice')
                setBooleanFields({
                  country,
                  field: 'isRemovalAutomaticPrice',
                  value: value.target.checked
                })
              }}
              checked={countryState.isRemovalAutomaticPrice}>
              Calcular automáticamente
            </Checkbox>
          </div>
        </div>

        <div>
          <h4>Seguro</h4>
          <Row>
            <TextField
              label="Monto máx. declarado"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.MAX_AMOUNT_AVOWED,
                    value: checkedValue
                  })
                )
              }
              value={countryState.maxAmountAvowed}
              error={handleErrorNumberFields(countryState.maxAmountAvowed)}
            />
            <TextField
              label="% sobre valor declarado"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.PERCETAGE_ON_DECLARED_PRICE,
                    value: checkedValue
                  })
                )
              }
              value={countryState.percentageOnDeclaredPrice}
              error={handleErrorNumberFields(countryState.percentageOnDeclaredPrice)}
            />
            <TextField
              label="Valor máx. de reembolso"
              onChange={(value) =>
                handleFieldChange(value, (checkedValue) =>
                  setStringField({
                    country,
                    field: CostFieldsNameEnum.MAX_REFUND_VALUE,
                    value: checkedValue
                  })
                )
              }
              value={countryState.maxRefundValue}
              error={handleErrorNumberFields(countryState.maxRefundValue)}
            />
          </Row>
        </div>
        <h4>Elegibilidad</h4>
        <div className={Styles.checkboxContainer}>
          <Checkbox
            onChange={() =>
              setProductEligibility({
                field: EligibilityFields.ELEGIBLE_FOR_SALE,
                value: !countryState.eligibility.isElegibleForSale,
                country
              })
            }
            checked={countryState.eligibility.isElegibleForSale}>
            Venta
          </Checkbox>
          <Checkbox
            onChange={() =>
              setProductEligibility({
                field: EligibilityFields.ELEGIBLE_FOR_DONATION,
                value: !countryState.eligibility.isElegibleForDonation,
                country
              })
            }
            checked={countryState.eligibility.isElegibleForDonation}>
            Donación
          </Checkbox>
          <Checkbox
            onChange={() =>
              setProductEligibility({
                field: EligibilityFields.ELEGIBLE_FOR_RECYCLED,
                value: !countryState.eligibility.isElegibleForRecycled,
                country
              })
            }
            checked={countryState.eligibility.isElegibleForRecycled}>
            Reciclado
          </Checkbox>
          <Checkbox
            onChange={() =>
              setProductEligibility({
                field: EligibilityFields.ELEGIBLE_FOR_RENT,
                value: !countryState.eligibility.isElegibleForRent,
                country
              })
            }
            checked={countryState.eligibility.isElegibleForRent}>
            Alquiler
          </Checkbox>
        </div>
        <div>
          <h4 className={Styles.productPhotoHeader}>Foto del producto</h4>
          {isFromCatalog ? (
            <div className={Styles.productImageContainer}>
              <img src={productDetail?.productByCountries[0].photo.url} className={Styles.productImage} />
            </div>
          ) : (
            <UploadPhotos
              fileType="products"
              uploadedPhoto={countryState.photoUrl}
              onDropCallback={() => setNewPhotoCountrySelected(country)}
            />
          )}
        </div>
      </div>
    </InputGroup>
  )
}
