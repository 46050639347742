import React, { useEffect, useState } from 'react'
import BaseMenu, { MenuAction } from '../../../actionMenu/baseMenu'
import styles from '../../DynamicTable.module.scss'
import { Column, RowExpandedRenderer } from '../../types/types'
import { Checkbox, Dropdown, Spin } from 'antd'
import clsx from 'clsx'
import ThreeDotMenu from '../../../actionMenu/threeDotMenu'
import HeightClipperTooltip from '../../../HeightClipperTooltip'
import { GenericObject } from '../../../../utils/utilityTypes'

const genericRenderFn = <T extends GenericObject>(item: T, key: string): string | number => {
  const keyHasDotNotation = key.split('.').length > 1

  // @ts-ignore
  if (keyHasDotNotation) return key.split('.').reduce((o, i) => o[i], item)
  // @ts-ignore
  return item[key]
}

type TableRowProps<T extends GenericObject> = {
  row: T
  loading: boolean
  columns: Column<T>[]
  key: string | number
  isEven: boolean
  actions?: MenuAction<T>[]
  expandedRowOpen: boolean
  onDoubleClick?: (row: T) => void
  setExpandedRowOpen: (expanded: boolean) => void
  rowHeight?: number
  rowExpander?: {
    render: RowExpandedRenderer<T>
    onExpansionClick?: (row: T) => void
    triggerFunctionOnce?: boolean
  }
  checkbox?: {
    onCheckboxClick: (row: T) => void
    isChecked: (row: T) => boolean
    isDisabled?: (row: T) => boolean
  }
  indexRow: number
}

const TableRow = <T extends GenericObject>({
  row,
  loading,
  columns,
  actions,
  isEven,
  rowExpander,
  expandedRowOpen,
  checkbox,
  rowHeight,
  indexRow,
  onDoubleClick,
  setExpandedRowOpen
}: TableRowProps<T>) => {
  const [expandedRowMounted, setExpandedRowMounted] = useState(false)
  const [shouldntTriggerAgain, setShouldntTriggerAgain] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!expandedRowOpen) setExpandedRowMounted(false)
    }, 400)

    return () => clearTimeout(timeout)
  }, [expandedRowOpen])

  const handleExpansion = (expand: boolean) => {
    if (expand) {
      setExpandedRowMounted(true)

      if (rowExpander?.onExpansionClick && !shouldntTriggerAgain) {
        rowExpander?.onExpansionClick(row)

        if (rowExpander.triggerFunctionOnce) setShouldntTriggerAgain(true)
      }
    }
    setExpandedRowOpen(expand)
  }

  return (
    <>
      <tr
        className={clsx(isEven && styles.evenRow, onDoubleClick && styles.clickableRow)}
        onDoubleClick={() => onDoubleClick && onDoubleClick(row)}>
        {rowExpander && (
          <td style={{ minHeight: rowHeight, paddingRight: checkbox && 0 }}>
            <ExpanderButton onClick={() => handleExpansion(!expandedRowMounted)} expanded={expandedRowOpen} />
          </td>
        )}
        {checkbox && (
          <td style={{ minHeight: rowHeight }}>
            <Checkbox
              checked={checkbox.isChecked(row)}
              disabled={checkbox.isDisabled ? checkbox.isDisabled(row) : false}
              onChange={() => checkbox.onCheckboxClick(row)}
              style={{ opacity: checkbox.isDisabled && checkbox.isDisabled(row) ? 0.5 : 1 }}
            />
          </td>
        )}
        {columns.map((col) => {
          const td = (
            <td
              key={String(col.key)}
              className={clsx(col.noPadding && styles.noPadding, col.alignment && styles[col.alignment])}
              style={{ minHeight: rowHeight }}>
              <HeightClipperTooltip maxHeight={rowHeight}>
                {col.renderDataCell ? col.renderDataCell(row, indexRow) : genericRenderFn(row, String(col.key))}
              </HeightClipperTooltip>
            </td>
          )
          return actions?.length ? (
            <Dropdown
              overlay={<BaseMenu actions={actions || []} callbackArgs={row} />}
              key={String(col.key)}
              trigger={['contextMenu']}>
              {td}
            </Dropdown>
          ) : (
            td
          )
        })}
        {actions?.length ? (
          <td style={{ minHeight: rowHeight }}>
            <ThreeDotMenu actions={actions} callbackArgs={row} />
          </td>
        ) : null}
        {loading && (
          <div style={{ gridRowStart: indexRow + 2, gridRowEnd: indexRow + 2 }} className={styles.loadingCell}>
            <Spin />
          </div>
        )}
      </tr>
      {rowExpander && (
        <tr className={clsx(isEven && styles.evenRow, styles.expandableRow, expandedRowOpen && styles.expanded)}>
          <td>{expandedRowMounted && rowExpander.render(row)}</td>
        </tr>
      )}
    </>
  )
}

const ExpanderButton: React.FC<{ expanded: boolean; onClick: () => void }> = ({ expanded, onClick }) => {
  return (
    <button className={clsx(styles.expanderButton, expanded && styles.expandedButton)} onClick={onClick}>
      <div />
    </button>
  )
}

export default TableRow
