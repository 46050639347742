import { Reducer } from 'redux'
import {
  ComplaintUpdateViewAction,
  ComplaintUpdateViewState,
  GetAgentsTypes,
  GetComplaintByIdTypes,
  SET_OPEN_EVIDENCE,
  SettersUpdateComplaintTypes,
  UpdateComplaintTypes
} from '../types/updateView'
import { DELETE_EVIDENCE, GetComplaintsReasonsTypes } from '../types/common'

const initialState: ComplaintUpdateViewState = {
  isFetchingComplaint: true,
  complaint: null,
  selectedEvidence: null,
  openEvidence: false,
  description: '',
  priority: null,
  assignedUserId: null,
  fetchingAgents: true,
  agents: [],
  photos: [],
  reasons: []
}

const ComplaintUpdateViewReducer: Reducer<ComplaintUpdateViewState, ComplaintUpdateViewAction> = (
  state = initialState,
  action
): ComplaintUpdateViewState => {
  switch (action.type) {
    case GetComplaintByIdTypes.GET_COMPLAINT_VIEW_REQUEST:
      return {
        ...state,
        isFetchingComplaint: !action.payload.silentLoading
      }

    case GetComplaintByIdTypes.GET_COMPLAINT_VIEW_SUCCESS:
      return {
        ...state,
        complaint: action.payload.complaint,
        isFetchingComplaint: false
      }

    case GetComplaintByIdTypes.GET_COMPLAINT_VIEW_FAILURE:
      return {
        ...state,
        isFetchingComplaint: false
      }

    case UpdateComplaintTypes.UPDATE_COMPLAINT_REQUEST:
    case UpdateComplaintTypes.UPDATE_COMPLAINT_FAILURE:
      return {
        ...state
      }

    case UpdateComplaintTypes.UPDATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        description: '',
        priority: null,
        assignedUserId: null,
        photos: []
        // complaint: {
        //   ...state.complaint,
        //   ...action.payload.complaint,
        //   complaintHistories: state.complaint
        //     ? [...state.complaint.complaintHistories, ...action.payload.complaint.complaintHistories]
        //     : action.payload.complaint.complaintHistories,
        //   photos:
        //     state.complaint?.photos && action.payload.complaint.photos
        //       ? [...state.complaint.photos, ...action.payload.complaint.photos]
        //       : action.payload.complaint.photos || state.complaint?.photos || []
        // }
      }

    case SET_OPEN_EVIDENCE: {
      return {
        ...state,
        openEvidence: action.payload.setOpenEvidence,
        selectedEvidence: action.payload.selectedEvidence || initialState.selectedEvidence
      }
    }

    case SettersUpdateComplaintTypes.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description
      }

    case SettersUpdateComplaintTypes.SET_PRIORITY:
      return {
        ...state,
        priority: action.payload.priority
      }

    case SettersUpdateComplaintTypes.SET_AGENT:
      return {
        ...state,
        assignedUserId: action.payload.assignedUserId
      }

    case SettersUpdateComplaintTypes.SET_EVIDENCE:
      return {
        ...state,
        photos: [...state.photos, ...action.payload.photo]
      }

    case DELETE_EVIDENCE:
      return {
        ...state,
        photos: state.photos.filter((_, index) => index !== action.payload.index)
      }
    case GetAgentsTypes.GET_AGENTS_REQUEST:
      return {
        ...state,
        fetchingAgents: true
      }
    case GetAgentsTypes.GET_AGENTS_SUCCESS:
      return {
        ...state,
        fetchingAgents: false,
        agents: action.payload.agents
      }
    case GetAgentsTypes.GET_AGENTS_FAILURE:
      return {
        ...state,
        fetchingAgents: false
      }

    case GetComplaintsReasonsTypes.GET_REASONS_REQUEST:
      return {
        ...state
      }
    case GetComplaintsReasonsTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload.reasons
      }
    case GetComplaintsReasonsTypes.GET_REASONS_FAILURE:
      return {
        ...state
      }

    default:
      return state
  }
}

export default ComplaintUpdateViewReducer
