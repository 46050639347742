import React from 'react'
import { Table, Tooltip } from 'antd'
import CrossIcon from '../../../icons/cross'
import CheckIcon from '../../../icons/check'
import ActionBar from '../../../components/ActionBar/ActionBar'
import ActionBarButton from '../../../components/ActionBarButton/ActionBarButton'
import CBUModal from '../containers/CBUModal'
import EditIcon from '../../../icons/edit'
import DefaultCBUs from '../containers/defaultCBUs'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

const CbusTable = (props) => {
  const { cbus, loading, name, lastName, handleCBUModalVisibility, userId, defaultCbusActionsCreator, quantity } = props
  const { openModal: openDefaultCbusModal } = defaultCbusActionsCreator

  const OrderByMain = (x, y) => {
    return x === y ? 0 : x ? -1 : 1
  }

  const fullname = `${name}` + ' ' + `${lastName}`

  const history = useHistory()

  const handleReturnButton = () => {
    history.goBack()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Titular',
      dataIndex: 'account_holder'
    },
    {
      title: 'CBU/CVU',
      dataIndex: 'cbu'
    },
    {
      title: 'Banco',
      dataIndex: 'bank[name]'
    },
    {
      title: 'CBU/CVU Principal',
      dataIndex: 'main',
      align: 'center',
      render: (n) =>
        n ? <CheckIcon fill="green" width="14px" height="14px" /> : <CrossIcon fill="red" width="14px" height="14px" />
    },
    {
      title: 'Acciones',
      render: (_) => (
        <>
          <Tooltip title="Editar CBU/CVU">
            <i
              style={{ marginLeft: '8px', fontSize: '15px' }}
              onClick={() => {
                handleCBUModalVisibility({ editMode: true, currentCU: _ })
              }}>
              <EditIcon />
            </i>
          </Tooltip>
          {!_.main && (
            <Tooltip title="Marcar CBU/CVU como principal">
              <i
                style={{ marginLeft: '15px' }}
                onClick={() => openDefaultCbusModal(_.user_id, _.id, _.cbu, _.account_holder, _.bank?.name)}>
                <CheckIcon width="15px" height="15px" fill="green" />
              </i>
            </Tooltip>
          )}
        </>
      )
    }
  ]

  return (
    <>
      <ActionBar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%'
          }}>
          <ActionBarButton action={handleReturnButton} description="Volver" icon="arrow-left" />
        </div>
        <ActionBarButton
          action={() => handleCBUModalVisibility({ userInfo: { userId, fullName: `${name} ${lastName}` } })}
          description="Agregar CBU/CVU"
          icon="wallet"
          type="primary"
        />
      </ActionBar>
      <div>
        <h3>
          DETALLE CBU/CVU - {name} {lastName} (USER ID: {userId}){' '}
        </h3>
        <Table
          columns={columns}
          dataSource={cbus.sort((a, b) => OrderByMain(a.main, b.main))}
          loading={loading}
          scroll={{ x: false }}
          rowKey="id"
          pagination={false}
        />
        <DefaultCBUs />
        <CBUModal />
      </div>
    </>
  )
}

CbusTable.propTypes = {
  pagination: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCBUModalVisibility: PropTypes.func.isRequired,
  defaultCbusActionsCreator: PropTypes.func.isRequired,
  cbus: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  openDefaultCbusModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired
}

export default CbusTable
