import React from 'react'
import { Modal } from 'antd'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  message: {
    color: 'green',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

const MassBillingModal = (props) => {
  const { massBillingModal, closeMassBillingModal, massBillingGenerate } = props
  const { massBillingModalOpen, loading, error, message } = massBillingModal

  const date = new Date()
  const actualMonth = date.getMonth() + 1
  const firstDate =
    (date.getMonth() ? date.getFullYear() : date.getFullYear() - 1) +
    ' ' +
    (date.getMonth() ? date.getMonth() : 12) +
    ' ' +
    2
  const lastDate = date.getFullYear() + ' ' + actualMonth + ' ' + 1
  return (
    <Modal
      title={'Generar Facturación'}
      visible={massBillingModalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={() => massBillingGenerate()}
      onCancel={() => closeMassBillingModal()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p>¿Esta seguro que quiere avanzar con la facturación?</p>
      <div className="container">
        {error && <p style={styles.error}>{error}</p>}
        {message && <p style={styles.message}>{message}</p>}
      </div>
    </Modal>
  )
}

export default MassBillingModal
