import React from 'react'
import CBUModal, { CBUModalProps } from '../components/CBUModal'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CBUModalActionsCreator from '../actions/cbusModal'
import { removeCBUSpaces } from '@/utils/cbuChecker'
const CBUModalContainer = () => {
  const dispatch = useDispatch()

  const { modalVisibility, editMode, currentCU, addingNewCBU, asMain, userInfo } = useSelector(
    (state) => state.Cbus.cbusModal
  )
  const { cbu, holderName } = useSelector((state) => state.Components.cbu)
  const { cbus: cbuList } = useSelector((state) => state.Cbus.cbus)
  const { setModalVisibility, setAsMain, addNewCBU, updateCBU } = bindActionCreators(CBUModalActionsCreator, dispatch)

  const handleCancelModal = () => {
    setModalVisibility({ isVisible: false })
  }

  const handleSetAsMain = (check: boolean) => {
    setAsMain({ check })
  }

  const handleAddNewCBU = () => {
    if (!userInfo?.userId) return

    addNewCBU({
      cbu: removeCBUSpaces(cbu),
      isMain: asMain,
      holderName,
      userId: userInfo?.userId
    })
  }

  const handleUpdateCBU = () => {
    if (!currentCU?.id) return

    updateCBU({
      cbu: removeCBUSpaces(cbu),
      isMain: currentCU.main || asMain,
      holderName,
      userId: currentCU.user_id.toString(),
      cbuId: currentCU.id
    })
  }

  const handleOkModal = () => {
    if (editMode) handleUpdateCBU()
    else handleAddNewCBU()
  }

  const cbuQuantity = cbuList.length
  const disabledOkButton = addingNewCBU || !cbu
  const disabledCheckboxAsMain = Boolean(currentCU?.main) && editMode

  const modalProps: CBUModalProps = {
    cbuQuantity,
    isMain: asMain || Boolean(currentCU?.main),
    editMode,
    modalVisibility,
    disabledOkButton,
    disabledCheckboxAsMain,
    handleOkModal,
    handleCancelModal,
    handleSetAsMain
  }

  return <CBUModal {...modalProps} />
}

export default CBUModalContainer
