import React from 'react'
import { Input, Modal, Spin } from 'antd'
import InputGroup from '../../../../components/InputGroup'
import styles from './AMDeposits.module.scss'
import { PlacesAutocomplete } from '../../../../components/PlacesAutocomplete/PlacesAutocomplete'
import { GeocodeResult } from 'use-places-autocomplete'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { FormFields } from '../types/ABMDeposits'
import { AddressDetail } from '../../../../projectApi/TransactionHandler/Deposits/common'
import clsx from 'clsx'

type AMModalDepositsTypes = {
  addressDetails: Partial<AddressDetail>
  modalVisible: boolean
  changeAddress: boolean
  editMode: boolean
  depositName: string
  addressError: string
  disableDetailFields: boolean
  disabledSuccessButton: boolean
  fetchingAddressDetails: boolean
  setFormField: ({ field, value }: { field: FormFields; value: string }) => void
  setModalVisibility: ({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }) => void
  setDepositName: (depositName: string) => void
  changeAddres: (changeAddress: boolean) => void
  handleGoogleSuggest: (suggest: GeocodeResult) => void
  handleCreateDeposit: () => void
}

const AMModalDeposits = ({
  addressDetails,
  addressError,
  modalVisible,
  changeAddress,
  editMode,
  depositName,
  disableDetailFields,
  disabledSuccessButton,
  fetchingAddressDetails,
  setFormField,
  setModalVisibility,
  setDepositName,
  changeAddres,
  handleGoogleSuggest,
  handleCreateDeposit
}: AMModalDepositsTypes) => {
  const modalTitle = editMode ? 'Editar depósito' : 'Creación de depósitos'
  const textConfirmButton = editMode ? 'Editar' : 'Crear'

  return (
    <Modal
      width={540}
      title={modalTitle}
      okText={textConfirmButton}
      visible={modalVisible}
      onCancel={() => setModalVisibility({ modalVisible: false })}
      cancelText={'Cancelar'}
      onOk={handleCreateDeposit}
      okButtonProps={{ disabled: disabledSuccessButton }}
      destroyOnClose>
      <div>
        {fetchingAddressDetails ? <LoadingForm /> : null}
        <InputGroup title="Datos obligatorios">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between'
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '470px' }}>
              <label className={styles.label}>
                Dirección*
                {editMode && !changeAddress ? (
                  <div className={styles.showCurrentAddressContainer}>
                    <span className={styles.currentAddress}>{addressDetails?.completeAddress}</span>
                    <button onClick={() => changeAddres(true)} className={styles.buttonEditCurrentAddress}>
                      Modificar
                    </button>
                  </div>
                ) : (
                  <PlacesAutocomplete
                    defaultValue={addressDetails?.completeAddress}
                    handleSuggest={handleGoogleSuggest}
                    currentCountry={CountryIdCode.ARGENTINA}
                    customClassname={styles.minHeightSelect}
                  />
                )}
              </label>
              {addressError ? (
                <p style={{ textAlign: 'center', color: '#F5522E', margin: '0' }}>{addressError}</p>
              ) : null}
            </div>
            <label className={styles.label}>
              Nombre del depósito*
              <Input value={depositName} onChange={({ target }) => setDepositName(target.value)} />
            </label>
          </div>
        </InputGroup>
        <InputGroup title="Detalles de dirección">
          <div
            style={{ display: 'grid', gridGap: '20px', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <label className={styles.label}>
                Piso
                <Input
                  disabled={disableDetailFields}
                  value={addressDetails?.addressFloor}
                  onChange={({ target }) => setFormField({ field: 'addressFloor', value: target.value })}
                />
              </label>
              <label className={styles.label}>
                Departamento
                <Input
                  disabled={disableDetailFields}
                  value={addressDetails?.addressApartment}
                  onChange={({ target }) => setFormField({ field: 'addressApartment', value: target.value })}
                />
              </label>
            </div>
            <label className={styles.label}>
              Ciudad/Comuna
              <Input
                disabled={disableDetailFields}
                value={addressDetails?.addressCity}
                onChange={({ target }) => setFormField({ field: 'addressCity', value: target.value })}
              />
            </label>
            <label className={styles.label}>
              Distrito
              <Input
                disabled={disableDetailFields}
                value={addressDetails?.addressProvince}
                onChange={({ target }) => setFormField({ field: 'addressProvince', value: target.value })}
              />
            </label>
            <label className={styles.label}>
              Código Postal
              <Input
                disabled={disableDetailFields}
                value={addressDetails?.addressPostalCode}
                onChange={({ target }) => setFormField({ field: 'addressPostalCode', value: target.value })}
              />
            </label>
          </div>
          <label className={clsx(styles.label, styles.textAreaLabel)}>
            Comentarios
            <textarea
              className={styles.textArea}
              disabled={disableDetailFields}
              maxLength={250}
              value={addressDetails?.addressComment}
              onChange={({ target }) => setFormField({ field: 'addressComment', value: target.value })}
            />
          </label>
        </InputGroup>
      </div>
    </Modal>
  )
}

export default AMModalDeposits

const LoadingForm = () => {
  return (
    <div style={{ position: 'absolute', zIndex: 3, top: 0, right: 0, width: '100%', height: '100%' }}>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.45)', opacity: 0.4, width: '100%', height: '100%' }} />
      <Spin size="large" style={{ position: 'absolute', top: '50%', right: '50%' }} />
    </div>
  )
}
