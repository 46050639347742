import React, { useMemo } from 'react'
import Select from '../../../components/Select'
import { Radio, Spin } from 'antd'
import transportActions from '../actions/transport'
import Address from '../../../components/Address/container'
import { getOptionValue } from '../../../common/operations/utils'
import { TransportPricingLabel, TransportTypesPricing, moneyFormat } from '../constants'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { formatMoneyWithCountry, formatNumber } from '../../../utils/formatNumber'
import { TransportState } from '../reducers/transport'
import { FlagsState } from '../reducers/flags'
import { ProspectState } from '../reducers/prospect'
import { Country, CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { GeocodeResult } from 'use-places-autocomplete'
import { RadioChangeEvent } from 'antd/es/radio'
import { CollapsablePanel, ProposalHeader } from '../../../components/CollapsablePanel/CollapsablePanel'
import styles from './Transport.module.scss'
import { Proposal } from '@/projectApi/TransactionHandler/Proposals/getById'

const getCustomTransportOptions = (
  prospectCountry: Country,
  flags: FlagsState,
  transport: TransportState,
  selectedProposal: Proposal | null
) => {
  const { fetchedCost = 0 } = transport
  const { customDateTransportMult, tollCostInCents } = flags
  const tollCost = (selectedProposal?.tollCostInCents ?? tollCostInCents) / 100
  const customTransportPrice = fetchedCost * customDateTransportMult
  const commonPercentageOff = formatNumber((1 - fetchedCost / customTransportPrice) * 100 || 0)

  const commonTransportPriceMoney = formatMoneyWithCountry(
    prospectCountry.currency,
    fetchedCost !== 0 ? fetchedCost + tollCost : 0,
    moneyFormat
  )
  const customTransportPriceMoney = formatMoneyWithCountry(
    prospectCountry.currency,
    customTransportPrice !== 0 ? customTransportPrice + tollCost : 0,
    moneyFormat
  )
  const freeTransportPriceMoney = formatMoneyWithCountry(prospectCountry.currency, 0, moneyFormat)

  const customTransportPrices = {
    [TransportTypesPricing.CUSTOM]: {
      number: customTransportPrice,
      money: customTransportPriceMoney,
      discount: ''
    },
    [TransportTypesPricing.COMMON]: {
      number: fetchedCost,
      money: commonTransportPriceMoney,
      discount: ` (${commonPercentageOff}% OFF)`
    },
    [TransportTypesPricing.FREE]: {
      number: 0,
      money: freeTransportPriceMoney,
      discount: ` (100% OFF)`
    }
  }

  return Object.entries(TransportTypesPricing).map(([key, value]) => {
    const customTransportPrice = customTransportPrices[value]
    const label = TransportPricingLabel[key as keyof typeof TransportPricingLabel]

    return {
      label: (
        <div className={styles.transportPricingContent}>
          <div>{label}</div>
          <b>{customTransportPrice.money}</b>
          <span className={styles.transportPricingDiscount}>{customTransportPrice.discount}</span>
        </div>
      ),
      value,
      price: customTransportPrice.number
    }
  })
}

type TransportProps = {
  globalDisabled: boolean
  transport: TransportState
  transportActions: typeof transportActions
  flags: FlagsState
  prospect: ProspectState
  selectedProposal: Proposal | null
}

const Transport = (props: TransportProps) => {
  const { globalDisabled, transport, transportActions, flags, prospect, selectedProposal } = props
  const { transportTypePricing, selectedTransport, transportModes, loadingTransportModes } = transport
  const { setTransportMode } = transportActions
  const { requireAddressOrigin, deposits, loadingDeposits, loadingCost, selectedDeposit } = transport
  const { fetchCost, setDeposit, setCustomTransportType } = transportActions

  const transportModeOptions = useMemo(
    () =>
      transportModes.map((transportMode) => ({
        value: transportMode.id,
        label: transportMode.nameSpanish
      })),
    [transportModes]
  )
  const depositOptions = useMemo(
    () =>
      deposits.map((deposit) => ({
        value: deposit.id,
        label: `${deposit.name} - ${deposit.address.addressString}`
      })),
    [deposits]
  )

  const onAddressChange = (suggest: GeocodeResult) => {
    const w = `${suggest.geometry.location.lat()},${suggest.geometry.location.lng()}`
    fetchCost(w)
  }

  const countrySelected = prospect.selectedProspect?.countryCode as CountryIdCode

  const prospectCountry = useCountryInfo(countrySelected)

  const customTransportOptions = useMemo(
    () => getCustomTransportOptions(prospectCountry, flags, transport, selectedProposal),
    [transport]
  )

  const onTransportModeChange = (e: RadioChangeEvent) => {
    const transportMode = e.target.value
    setTransportMode({ transportModeID: transportMode, countryCode: countrySelected })
  }

  const onCustomTransportChange = (e: RadioChangeEvent) => {
    const transportTypePricing = e.target.value
    const customTransport = customTransportOptions.find(
      (customTransportOption) => transportTypePricing === customTransportOption.value
    )
    setCustomTransportType(transportTypePricing, customTransport?.price || 0)
  }

  const selectedDepositOption = useMemo(
    () => depositOptions.find((deposit) => deposit.value === selectedDeposit),
    [depositOptions, selectedDeposit]
  )

  return (
    <CollapsablePanel header={<ProposalHeader title="3. Transporte" />}>
      <div className={styles.transportContent}>
        <div className={styles.transportContainer}>
          <span className={styles.label}>Modalidad de retiro</span>
          <Radio.Group
            className={styles.transportRadioGroup}
            onChange={onTransportModeChange}
            value={selectedTransport}>
            {loadingTransportModes && (
              <div className={styles.spinContainer}>
                <Spin />
              </div>
            )}
            {transportModeOptions.map((transportMode) => (
              <Radio
                disabled={globalDisabled && transportMode.value !== selectedTransport}
                key={transportMode.value}
                className={styles.radioStyle}
                value={transportMode.value}>
                <span className={styles.radioTextStyle}>
                  {selectedTransport === transportMode.value ? <b>{transportMode.label}</b> : transportMode.label}
                </span>
              </Radio>
            ))}
          </Radio.Group>
        </div>
        {requireAddressOrigin && (
          <Address disabled={globalDisabled} onAddressChange={onAddressChange} country={countrySelected} />
        )}
        {requireAddressOrigin && (
          <div className={styles.transportPricingContainer}>
            <span className={styles.labelWithMargin}>Valor del transporte:</span>
            <Radio.Group
              className={styles.transportPricingRadioGroup}
              onChange={onCustomTransportChange}
              value={transportTypePricing}>
              {loadingCost && (
                <div className={styles.spinContainer}>
                  <Spin />
                </div>
              )}

              {customTransportOptions.map((customTransportOption) => (
                <Radio
                  key={customTransportOption.value}
                  className={styles.transportPricingRadio}
                  value={customTransportOption.value}
                  disabled={globalDisabled && customTransportOption.value !== transportTypePricing}>
                  {customTransportOption.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )}
        <div>
          <span className={styles.labelWithMargin}>Depósito {requireAddressOrigin && 'de Destino'}</span>
          <Select
            placeholder="Seleccionar Depósito"
            loading={loadingDeposits}
            options={depositOptions}
            selected={selectedDepositOption}
            disabled={globalDisabled}
            onSelect={(option) => setDeposit(getOptionValue(option))}
            position="top"
          />
        </div>
      </div>
    </CollapsablePanel>
  )
}

export default Transport
