import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { AppThunk } from '../../../../store'
import { API } from '../../../../projectApi'
import {
  GetLocationListTypes,
  GetLocationsMetricsTypes,
  LocationsSearchFilter,
  RESET_FILTERS,
  ResetFilters
} from '../types/locationsTable'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'

const actions = {
  getLocations({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters: LocationsSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: GetLocationListTypes.GET_LOCATIONS_REQUEST,
        payload: {
          pagination,
          searchFilters
        }
      })
      try {
        const { locations, total } = await API.TransactionHandler.Location.Corporative.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          column: 'id',
          order: 'desc'
        })
        dispatch({
          type: GetLocationListTypes.GET_LOCATIONS_SUCCESS,
          payload: {
            locations,
            total
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetLocationListTypes.GET_LOCATIONS_FAILURE, dispatch)
      }
    }
  },

  resetFilters: (): ResetFilters => {
    setTimeout(() => Emitter.emit(Events.Location.RESETED_FILTERS), 50)
    return { type: RESET_FILTERS }
  },

  getMetrics({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters: LocationsSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      dispatch({ type: GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_REQUEST })
      try {
        const { locationsMetrics } = await API.TransactionHandler.Location.Corporative.metrics({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters)
        })

        dispatch({
          type: GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_SUCCESS,
          payload: {
            locationsMetrics
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_FAILURE, dispatch)
      }
    }
  }
}

export default actions
