import React from 'react'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import styles from './finishPayment.module.scss'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'
import { useHistory } from 'react-router'
import PaySuccessIcon from '../../../icons/paySuccess'
import { formatMoney } from '../../../utils/formatNumber'

interface FinishPaymentProps {
  paymentMethod?: PaymentMethod | null
  searchUserActions: typeof searchUserActionCreators
  userColppyInfo: UserColppy | null
  totalToPay: number
}

const FinishPayment = ({ paymentMethod, searchUserActions, totalToPay }: FinishPaymentProps) => {
  const history = useHistory()

  return (
    <div className={styles.paySuccessContainer}>
      <div>
        <PaySuccessIcon />
      </div>
      <div className={styles.bodyContainer}>
        <h3>¡Listo! se acreditó el pago</h3>
        <h5>Recibimos {formatMoney(totalToPay)}</h5>
        <p>
          {paymentMethod?.paymentMethod.cardType?.replace('_', ' ').toUpperCase()} ****{' '}
          {paymentMethod?.paymentMethod.lastFourDigits}.
        </p>
      </div>
      <div className={styles.nextPageContainer}>
        <button
          onClick={() => {
            searchUserActions.clearState()
            history.push('/billings_payments')
          }}>
          Volver al inicio
        </button>
      </div>
    </div>
  )
}

export default FinishPayment
