import React, { useEffect } from 'react'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import Select from '../../../components/Select'
import { ITEM_TYPES_VALUES } from '../../../common/operations/constants'
import { formatMoneyWithCountry, formatNumber } from '../../../utils/formatNumber'
import { getFormattedOptions, getFormattedSelectedOption } from '../../../common/operations/selectors/items'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { Info, Trash } from '../../../icons'
import { ItemsState, SelectedItem } from '../types/items'
import itemsActions from '../actions/items'
import transportActionsCreators from '../actions/transport'
import { Country, CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'
import { CollapsablePanel, ProposalHeader } from '../../../components/CollapsablePanel/CollapsablePanel'
import styles from './OperationType.module.scss'
import { BaseOption } from '../../../components/Select/Select'
import clsx from 'clsx'
import { moneyFormat } from '../constants'
import { TransportState } from '../reducers/transport'
import NumberInput from '../../../components/numberInput/numberInput'

const defaultSelectedItem = {
  heightInCm: 0,
  lengthInCm: 0,
  widthInCm: 0,
  volumeInCm3: 0,
  weightInGr: 0,
  packagingCostInCents: 0,
  disassemblyCost: 0,
  depositFloorsByStairsCostInCents: 0
}

enum SelectedTransportIdEnum {
  DELIVERY = 1,
  COLLECTION = 2
}

type SingleItemComponentProps = {
  globalDisabled: boolean
  selectedItems: SelectedItem[]
  index: number
  proposalCountry: Country
  itemsActions: typeof itemsActions
  item: SelectedItem
  serviceType: string
  selectedTransport: SelectedTransportIdEnum
}

const SingleItemComponent = ({
  globalDisabled,
  item,
  index,
  proposalCountry,
  itemsActions,
  selectedItems,
  serviceType,
  selectedTransport
}: SingleItemComponentProps) => {
  const { items, selectedItem, quantity, floors, loading, type, packaging, disassemble, byProposal } = item
  const {
    getItemsPrice,
    setInitialItems,
    removeItem,
    setItemQuantity,
    setItem,
    setItemPackaging,
    setDisassemble,
    setItemFloors
  } = itemsActions

  const [autoComplete, setAutoComplete] = React.useState('')

  const options = getFormattedOptions(items)
  const selectedOption = getFormattedSelectedOption(selectedItem)

  const onInputChange = (name: string) => {
    if (type !== ITEM_TYPES_VALUES.CUSTOM) {
      if (name.length < 2) {
        if (name.length > 0) {
          setInitialItems(index)
        }
      } else {
        getItemsPrice(name, index, proposalCountry.code, serviceType)
      }
    }
  }

  const {
    heightInCm,
    lengthInCm,
    widthInCm,
    volumeInCm3,
    weightInGr,
    packagingCostInCents,
    disassemblyCost,
    depositFloorsByStairsCostInCents
  } = selectedItem || defaultSelectedItem

  const floorCost = depositFloorsByStairsCostInCents / 100

  const onSelectItem = (option: BaseOption<string>) => option && setItem(option.value, items, index, selectedItems)

  const getTooltipTitle = () => (
    <span>
      {formatNumber(heightInCm)} cm x {formatNumber(widthInCm)} cm x {formatNumber(lengthInCm)} cm <br />{' '}
      {formatNumber(weightInGr / 1000)} kg
    </span>
  )

  const packagingStyles = clsx(styles.extraPrice, !packaging && styles.extraDisabled)
  const disassembleStyles = clsx(styles.extraPrice, !disassemble && styles.extraDisabled)
  const floorStyles = clsx(floors ? styles.extraPrice : styles.extraDisabled)

  const disabled = byProposal || globalDisabled
  const floorsDisabled = selectedTransport === SelectedTransportIdEnum.COLLECTION || disabled

  useEffect(() => {
    if (floorsDisabled) {
      setItemFloors(index, 0)
    }
  }, [floorsDisabled])

  return (
    <Row gutter={[16, 16]} type="flex" align="middle">
      <Col span={7}>
        <Select
          placeholder="Seleccionar Item"
          selected={selectedOption}
          onSelect={onSelectItem}
          autocomplete={{
            filterByInput: true,
            input: autoComplete,
            onInputChange: (text) => {
              setAutoComplete(text)
              onInputChange(text)
            }
          }}
          loading={loading}
          disabled={type === ITEM_TYPES_VALUES.CUSTOM || disabled}
          options={options}
        />
      </Col>
      {selectedOption && (
        <>
          <Col span={1}>
            {!globalDisabled && (
              <span className={styles.deleteIcon} onClick={() => removeItem(index)}>
                <Trash />
              </span>
            )}
          </Col>
          <Col className={styles.col} span={2}>
            <NumberInput
              disabled={disabled}
              onAnyChange={(newValue) => setItemQuantity(index, newValue)}
              value={quantity}
            />
          </Col>
          <Col span={3}>
            <Tooltip title={getTooltipTitle()}>
              <div className={styles.items}>{formatNumber((volumeInCm3 * quantity) / 1000000, 2)} m³</div>{' '}
              <div className={styles.items}>{formatNumber((weightInGr * quantity) / 1000, 1)} kg</div>
            </Tooltip>
          </Col>
          <Col span={3}>
            {packagingCostInCents > 0 && (
              <>
                <Checkbox
                  checked={packaging || byProposal}
                  disabled={disabled}
                  onChange={(e) => setItemPackaging(index, e.target.checked)}
                />
                <span className={packagingStyles}>
                  {formatMoneyWithCountry(
                    proposalCountry.currency,
                    (packagingCostInCents * quantity) / 100,
                    moneyFormat
                  )}
                </span>
              </>
            )}
          </Col>
          <Col span={3}>
            {disassemblyCost > 0 && (
              <>
                <Checkbox
                  checked={disassemble || byProposal}
                  disabled={disabled}
                  onChange={(e) => setDisassemble(index, e.target.checked)}
                />
                <span className={disassembleStyles}>
                  {formatMoneyWithCountry(proposalCountry.currency, disassemblyCost * quantity, moneyFormat)}
                </span>
              </>
            )}
          </Col>
          <Col span={5}>
            {byProposal && floorCost > 0 && (
              <>
                <Checkbox checked={true} disabled={true} />
                <span className={floorStyles}>
                  {formatMoneyWithCountry(proposalCountry.currency, floorCost * quantity, moneyFormat)}
                </span>
              </>
            )}
            {floorCost > 0 && !byProposal && (
              <Tooltip
                title={`Precio por piso: ${formatMoneyWithCountry(proposalCountry.currency, floorCost, moneyFormat)}`}>
                <span
                  style={{
                    alignItems: 'center',
                    display: 'flex'
                  }}>
                  <div>
                    <NumberInput
                      onAnyChange={(newValue) => setItemFloors(index, newValue)}
                      value={floors}
                      disabled={floorsDisabled}
                    />
                  </div>
                  <div className={floorStyles}>
                    {formatMoneyWithCountry(proposalCountry.currency, floorCost * floors * quantity, moneyFormat)}
                  </div>
                </span>
              </Tooltip>
            )}
          </Col>
        </>
      )}
    </Row>
  )
}

type ItemsComponentProps = {
  globalDisabled: boolean
  proposalCountry: Country
  itemsActions: typeof itemsActions
  items: ItemsState
  serviceType: string
  transport: TransportState
}

const ItemsComponent = ({
  globalDisabled,
  proposalCountry,
  itemsActions,
  items,
  serviceType,
  transport
}: ItemsComponentProps) => {
  const { selectedItems } = items
  const { selectedTransport } = transport

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <span className={styles.title}>Ítems</span>
          </Col>
          <Col span={2}>
            <span className={styles.title}>Cantidad</span>
          </Col>
          <Col span={3}>
            <span className={styles.title}>Dimensiones</span>
          </Col>
          <Col span={3}>
            <span className={styles.title}>Embalaje</span>
          </Col>
          <Col span={3}>
            <span className={styles.title}>Desarmado</span>
          </Col>
          <Col span={5}>
            <span className={styles.title}>Escaleras</span>
          </Col>
        </Row>
        {selectedItems.map((selectedItem, index) => (
          <SingleItemComponent
            globalDisabled={globalDisabled}
            selectedItems={selectedItems}
            proposalCountry={proposalCountry}
            key={index}
            index={index}
            item={selectedItem}
            itemsActions={itemsActions}
            serviceType={serviceType}
            selectedTransport={selectedTransport}
          />
        ))}
      </Col>
    </Row>
  )
}

type OperationTypeProps = {
  minBillableM3: number
  minBillableM3Price: number
  globalDisabled: boolean
  selectedProspect: Prospect | null
  items: ItemsState
  itemsActions: typeof itemsActions
  transport: TransportState
  transportActions: typeof transportActionsCreators
}

const OperationType = (props: OperationTypeProps) => {
  const {
    minBillableM3,
    minBillableM3Price,
    globalDisabled,
    items,
    itemsActions,
    selectedProspect,
    transport,
    transportActions
  } = props

  const { serviceType, serviceTypes, loadingServiceTypes } = transport

  const serviceTypeOptions = serviceTypes.map(
    (serviceType) => ({
      value: serviceType.id,
      label: serviceType.type
    }),
    [serviceTypes, serviceType]
  )

  const selectedServiceType = serviceTypeOptions.find((option) => option.value === serviceType)
  const countryCode = selectedProspect?.countryCode as CountryIdCode

  const onSelectServiceType = (option: BaseOption<string>) => {
    const newServiceType = serviceTypes.find((serviceType) => serviceType.id === option.value)
    transportActions.setServiceType({
      newServiceType: newServiceType!,
      actualServiceType: serviceType,
      countryCode
    })
  }

  const proposalCountry = useCountryInfo(countryCode)

  return (
    <CollapsablePanel header={<ProposalHeader title="1. Cotización" badgeContent="Vigente" />}>
      <div className={styles.serviceTypeContainer}>
        <Select
          className={styles.serviceTypeSelect}
          placeholder="Seleccioná un tipo de servicio"
          loading={loadingServiceTypes}
          options={serviceTypeOptions}
          selected={selectedServiceType}
          onSelect={onSelectServiceType}
          disabled={globalDisabled}
        />
        <span className={styles.serviceTypeLabel}>
          <Info fill="#0859A1" />
          Recordá que el valor mínimo de depósito es de {minBillableM3} m³ ={' '}
          {formatMoneyWithCountry(proposalCountry.currency, minBillableM3Price, moneyFormat)}
        </span>
      </div>
      <hr />
      <ItemsComponent
        globalDisabled={globalDisabled}
        proposalCountry={proposalCountry}
        items={items}
        itemsActions={itemsActions}
        serviceType={serviceType}
        transport={transport}
      />
    </CollapsablePanel>
  )
}

export default OperationType
