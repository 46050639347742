import { AxiosResponse } from 'axios'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { apiPrivate } from './../../../api'

interface M3Params {
  user_id: number
}

type APIResponse = {
  m3: number
  success: string
}

type Response = APIResponse

export function getM3Storage(params: M3Params): Promise<Response> {
  return apiPrivate.get(`${URL_BASE_OBJECTS}/object/m3`, { params }).then((response: AxiosResponse<APIResponse>) => {
    if (!response.data) throw new Error('hubo un problema al obtener las metricas')

    return response.data
  })
}
