import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductsTableActionCreators from '../actions/productTable'

import { CategoryOption, ProductsTable, ProductsTableProps } from '../components/productTable'
import {
  Pagination,
  ProductsCategoryFilter,
  ProductsSearchFilter,
  ProductsTableSearchKey,
  ProductsTableSort
} from '../types/productTable'

import { SortDirection } from '../../../../components/DynamicTable/types/types'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import CorporativeProductsTableActionBar from '../components/TableActionBar'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'
import { useHistory } from 'react-router'
import DeleteProductModal, { DeleteProductProps } from '../components/DeleteProduct'
import ABMCorporateProductsActionsCreator from '../actions/ABMProducts'

export type CreateProductContainerTypes = {
  categories: CategoryOption[]
}

const ProductsTableContainer = (ownProps: CreateProductContainerTypes) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { getProducts, getCategories, searchCategoryId, cleanState } = bindActionCreators(
    ProductsTableActionCreators,
    dispatch
  )
  const { setDeleteModalVisibility, deleteProduct } = bindActionCreators(ABMCorporateProductsActionsCreator, dispatch)
  const {
    products,
    loadingProducts,
    pagination,
    searchFilters,
    sort,
    categoryFilter,
    categories: searchCategories,
    categoryId
  } = useSelector((state) => state.CorporativeProducts.table)
  const { selectedProduct, deletingProduct, deleteModalVisible, disabledDeleteButton } = useSelector(
    (state) => state.CorporativeProducts.ABM
  )

  const countryCode = CountryIdCode.ARGENTINA
  const emptyPagination = { ...pagination, page: 1 }
  const { categories } = ownProps
  const emptyFilters = { showInSearch: [], deprecated: [] }

  const handleGetProducts = (newParams: {
    pagination?: Pagination
    searchFilters?: ProductsSearchFilter[]
    sort: ProductsTableSort
    categoryFilter: ProductsCategoryFilter
    categoryId?: number
  }) => {
    getProducts({
      countryCode,
      pagination: newParams.pagination || pagination,
      searchFilters: newParams.searchFilters || searchFilters,
      sort: newParams.sort || sort,
      categoryFilter: newParams.categoryFilter || categoryFilter,
      categoryId: newParams.categoryId ?? categoryId
    })
  }

  useEffect(() => {
    handleGetProducts({ pagination: emptyPagination, sort, categoryFilter })
    getCategories()

    return () => {
      cleanState()
    }
  }, [])

  useEvents(
    [
      Events.CorporateProducts.CLEAN_STATE,
      Events.CorporateProducts.CREATE_PRODUCT,
      Events.CorporateProducts.UPDATE_PRODUCT,
      Events.CorporateProducts.DELETE_PRODUCT
    ],
    () => {
      handleGetProducts({ pagination: emptyPagination, sort, categoryFilter })
      getCategories()
    }
  )

  const getKeyFilter = (newValue: string | number) => {
    if (typeof newValue === 'number') return newValue.toString().toLowerCase()

    switch (newValue.toLowerCase()) {
      case 'sí':
        return 'true'
      case 'no':
        return 'false'
      default:
        return newValue
    }
  }

  const handlePageChange = (newPage: number) => {
    handleGetProducts({ pagination: { ...pagination, page: newPage }, sort, categoryFilter })
  }

  const handleSearch = (key: ProductsTableSearchKey, newValue: string) => {
    const newSearchFilters = searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: getKeyFilter(newValue)
          }
        : filter
    )
    handleGetProducts({ pagination: emptyPagination, searchFilters: newSearchFilters, sort, categoryFilter })
  }

  const handleByCategoryId = (categoryId: number) => {
    handleGetProducts({ pagination: emptyPagination, searchFilters, sort, categoryFilter, categoryId })
  }

  const handleCategoryFilter = (newCategoryFilter: ProductsCategoryFilter) => {
    handleGetProducts({ categoryFilter: newCategoryFilter, pagination: emptyPagination, sort })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetSort: ProductsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination
    const resetCategoryFilter = emptyFilters

    handleGetProducts({
      pagination: resetPagination,
      searchFilters: resetSearchFilters,
      sort: resetSort,
      categoryFilter: resetCategoryFilter,
      categoryId: 0
    })
  }

  const paging = {
    currentPage: pagination.page,
    pageSize: pagination.pageSize,
    total: pagination.total,
    onPageChange: handlePageChange
  }

  const actions: MenuAction<FormattedProduct>[] = [
    {
      label: 'Editar',
      onClick: (product) => {
        history.push(`/corporative/editProduct/${product.id}`, { editMode: true, product })
      }
    },
    {
      label: 'Eliminar',
      onClick: (product) => {
        setDeleteModalVisibility({ deleteModalVisible: true, currentProduct: product })
      }
    }
  ]

  const handleCancelDeleteButton = () => setDeleteModalVisibility({ deleteModalVisible: false })
  const handleDeleteProduct = () => {
    if (!selectedProduct) return

    deleteProduct(selectedProduct.id)
  }

  const deleteProductModalProps: DeleteProductProps = {
    currentProduct: selectedProduct,
    deleteModalVisible,
    deletingProduct,
    disabledDeleteButton,
    handleCancelDeleteButton,
    handleDeleteProduct
  }

  const props: ProductsTableProps = {
    products,
    loadingProducts,
    pagination: paging,
    actions,
    searchFilters,
    countryCode,
    handleSearch,
    handleResetFilters,
    categories,
    sort,
    categoryFilter,
    handleCategoryFilter,
    searchCategories,
    searchCategoryId,
    handleByCategoryId,
    categoryId
  }

  return (
    <>
      <CorporativeProductsTableActionBar />
      <ProductsTable {...props} />
      <DeleteProductModal {...deleteProductModalProps} />
    </>
  )
}

export default ProductsTableContainer
