import { apiPrivate } from '@/api'
import { URL_BASE_COMPLAINTS } from '@/endpoints'
import { AxiosResponse } from 'axios'

type APIResponse = {
  content: {
    deposit: number
    removal: number
    new: number
    new_delayed: number
    in_progress: number
    in_progress_delayed: number
    closed: number
    closed_delayed: 1
  }
}

type Response = Metrics

export type Metrics = {
  closed: number
  deposit: number
  new: number
  newDelayed: number
  open: number
  openDelayed: number
  removal: number
}

export function metrics(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_COMPLAINTS}/complaints/metrics`)
    .then((response: AxiosResponse<APIResponse>) => {
      const { content } = response.data
      if (!response.data) throw new Error('No se pudieron obtener las métricas')
      const camelResponse: Response = {
        new: content.new,
        newDelayed: content.new_delayed,
        open: content.in_progress,
        openDelayed: content.in_progress_delayed,
        deposit: content.deposit,
        closed: content.closed + content.closed_delayed,
        removal: content.removal
      }

      return camelResponse
    })
    .catch((error) => {
      throw new Error(error)
    })
}
