import { getBillingByTransaction } from './getBillingByTransaction'
import { list } from './list'
import { payBilling } from './payBilling'
import { metrics } from './metrics'
import { RecalculateAPI } from './Recalculate'
import { authorizationAfip } from './authorizationAfip'
import { billingsToBillByPeriod } from './billingsToBillByPeriod'
import { authorizations } from './authorizations'
import { sendEmailBilled } from './sendEmailBilled'
import { anulled } from './anulled'

export const BillingsAPI = {
  getBillingByTransaction,
  billingsToBillByPeriod,
  list,
  payBilling,
  metrics,
  Recalculate: RecalculateAPI,
  authorizationAfip,
  authorizations,
  sendEmailBilled,
  anulled
}
