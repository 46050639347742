import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/ProspectData'
import leadsTableActions from '../../Leads/actions/table'
import { bindActionCreators } from 'redux'
import getUserInfoToDisplay from '../utils/getUserInfoToDisplay'

type ProspectDataContainerProps = {
  mode: string
}

const Container = (ownProps: ProspectDataContainerProps) => {
  const { mode } = ownProps
  const dispatch = useDispatch()
  const prospect = useSelector((state) => state.NewProposal.prospect)
  const { fetchedConfig, config, loadingConfig } = useSelector((state) => state.Leads.table)
  const { actualM3Storage } = useSelector((state) => state.NewProposal.items)
  const getLeadsConfig = bindActionCreators(leadsTableActions.getLeadsConfig, dispatch)

  useEffect(() => {
    if (!fetchedConfig) getLeadsConfig()
  }, [fetchedConfig])

  const userInfo = getUserInfoToDisplay(mode, prospect.selectedProspect)

  const props = {
    userInfo,
    config,
    loadingConfig,
    actualM3Storage
  }

  return <Component {...props} />
}

export default Container
