import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'

export enum ProspectSearchTypes {
  SET_PROSPECT_ID = 'NEW_PROPOSAL_V2/PROSPECT/SET_PROSPECT_ID',
  GET_PROSPECT_REQUEST = 'NEW_PROPOSAL_V2/PROSPECT/GET_PROSPECT_REQUEST',
  GET_PROSPECT_SUCCESS = 'NEW_PROPOSAL_V2/PROSPECT/GET_PROSPECT_SUCCESS',
  GET_PROSPECT_FAILURE = 'NEW_PROPOSAL_V2/PROSPECT/GET_PROSPECT_FAILURE'
}

export interface SetProspectId {
  type: ProspectSearchTypes.SET_PROSPECT_ID
  payload: {
    prospectId: number
  }
}

export interface GetProspectRequest {
  type: ProspectSearchTypes.GET_PROSPECT_REQUEST
}

export interface GetProspectSuccess {
  type: ProspectSearchTypes.GET_PROSPECT_SUCCESS
  payload: {
    prospect: Prospect
  }
}

export interface GetProspectFailure {
  type: ProspectSearchTypes.GET_PROSPECT_FAILURE
  payload: {
    error: string
  }
}

export type GetProspectActions = GetProspectRequest | GetProspectSuccess | GetProspectFailure

export type ProspectActions = SetProspectId | GetProspectActions
