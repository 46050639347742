import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'
import moment, { Moment } from 'moment'
import { OperationTypeValues } from '../../../../sections/OperationCalendar/types/common'

export type CreateDateParams = {
  date: Moment | null
  operationType: OperationTypeValues
}

export async function createDate({ date, operationType }: CreateDateParams) {
  const formattedDate = moment(date).format('YYYY-MM-DD')
  const formattedBody = snakecaseKeys({ date: formattedDate, operationType })
  return apiPrivate
    .post(`${URL_BASE_TIMESLOT}/operation-days/specific-day`, formattedBody)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
