import moment from 'moment'
import { RehydrateAction } from 'redux-persist/es/types'

export const SET_LAST_REQUEST_REPORT_TIME = 'REPORTS/SET_LAST_REQUEST_REPORT_TIME'
export const SET_OBJECT_REPORT_REQUEST_WITH_QUERIES = 'REPORTS/SET_OBJECT_REPORT_REQUEST_WITH_QUERIES'

export const REQUEST_OBJECTS_REPORT_REQUEST = 'REPORTS/REQUEST_OBJECTS_REPORT_REQUEST'
export const REQUEST_OBJECTS_REPORT_SUCCESS = 'REPORTS/REQUEST_OBJECTS_REPORT_SUCCESS'
export const REQUEST_OBJECTS_REPORT_FAILURE = 'REPORTS/REQUEST_OBJECTS_REPORT_FAILURE'

export interface RequestObjectsReportRequest {
  type: typeof REQUEST_OBJECTS_REPORT_REQUEST
}
export interface RequestObjectsReportSuccess {
  type: typeof REQUEST_OBJECTS_REPORT_SUCCESS
  payload: {
    encodedQueries: string
    time: moment.Moment
  }
}
export interface RequestObjectsReportFailure {
  type: typeof REQUEST_OBJECTS_REPORT_FAILURE
}

type RequestObjectsReportAction =
  | RequestObjectsReportRequest
  | RequestObjectsReportSuccess
  | RequestObjectsReportFailure

export type LastDateReport = Record<ReportsType, string | null>
export type LastDateObjectReport = Record<string, string>

export type SetLastRequestReportAction = {
  type: typeof SET_LAST_REQUEST_REPORT_TIME
  payload: {
    report: ReportsType
    time: moment.Moment
  }
}

export type SetLastRequestObjectReportWithQueries = {
  type: typeof SET_OBJECT_REPORT_REQUEST_WITH_QUERIES
  payload: {
    encodedQueries: string
    time: moment.Moment
  }
}

export enum ReportsType {
  PRE_BILLING_REPORT = 'PRE_BILLING_REPORT'
}

export type ReportsAction =
  | SetLastRequestReportAction
  | SetLastRequestObjectReportWithQueries
  | RehydrateAction
  | RequestObjectsReportAction

export type ReportsReducer = {
  requestingReport: boolean
  lastReports: LastDateReport
  lastObjectReports: LastDateObjectReport
}
