import { SearchFilter } from '../../../../utils/searchFilterUtils'
import { Deposit } from '../../../../projectApi/TransactionHandler/Deposits/common'
import { Location } from '../../../../projectApi/TransactionHandler/Locations/Corporative/list'

export type LocationsSearchKey = 'id' | 'deposit_id' | 'owner_id'
export type LocationsSearchFilter = SearchFilter<LocationsSearchKey>
export type CoporativeDepositsTableSearchKey = 'depositId' | 'name'

export type PaginationParams = {
  Limit: number
  Offset: number
  Column: 'asc' | 'desc'
  Order: string
}

type selectedLocation = {
  field: string
  value: number
}

export type BulkTransferStateType = {
  isOpen: boolean
  deposits: Deposit[]
  depositId: number
  locationId: number
  selectedDeposit: selectedLocation
  fetchingDeposits: boolean
  loadingTransferObject: boolean
  fetchingLocations: boolean
  locations: Location[]
}

export type BulkTransferPossibleNumberFields = 'depositId' | 'locationId'

export const SET_STRING_FIELD = 'CORPORATIVE_OBJECTS/BULK/SET_STRING_FIELD'
export const SET_NUMBER_FIELD = 'CORPORATIVE_OBJECTS/BULK/SET_NUMBER_FIELD'
export const SET_UNIT_TYPE = 'CORPORATIVE_OBJECTS/BULK/SET_UNIT_TYPE'

export const SET_LOCATIONS = 'CORPORATIVE_OBJECTS/BULK/SET_LOCATIONS'

export type SetLocations = {
  type: typeof SET_LOCATIONS
  payload: {
    locations: Location[]
  }
}

export type SetNumberField = {
  type: typeof SET_NUMBER_FIELD
  payload: {
    field: BulkTransferPossibleNumberFields
    value: number
  }
}
export const GET_DEPOSITS_REQUEST = 'CORPORATIVE_OBJECTS/BULK/GET_DEPOSITS_REQUEST'
export const GET_DEPOSITS_SUCCESS = 'CORPORATIVE_OBJECTS/BULK/GET_DEPOSITS_SUCCESS'
export const GET_DEPOSITS_FAILURE = 'CORPORATIVE_OBJECTS/BULK/GET_DEPOSITS_FAILURE'

export interface GetDepositListRequest {
  type: typeof GET_DEPOSITS_REQUEST
  payload: {
    fetchingDeposits: boolean
  }
}

export interface GetDepositListSuccess {
  type: typeof GET_DEPOSITS_SUCCESS
  payload: {
    deposits: Deposit[]
  }
}

export interface GetDepositListFailure {
  type: typeof GET_DEPOSITS_FAILURE
  payload: {
    error: string
  }
}

export const GET_LOCATIONS_REQUEST = 'CORPORATIVE_OBJECTS/BULK/GET_LOCATIONS_REQUEST'
export const GET_LOCATIONS_SUCCESS = 'CORPORATIVE_OBJECTS/BULK/GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAILURE = 'CORPORATIVE_OBJECTS/BULK/GET_LOCATIONS_FAILURE'

export interface GetLocationListRequest {
  type: typeof GET_LOCATIONS_REQUEST
  payload: {
    fetchingLocations: boolean
  }
}

export interface GetLocationListSuccess {
  type: typeof GET_LOCATIONS_SUCCESS
  payload: {
    locations: Location[]
    total: number
  }
}

export interface GetLocationListFailure {
  type: typeof GET_LOCATIONS_FAILURE
  payload: {
    error: string
  }
}

export const TRANSFER_OBJECT_REQUEST = 'CORPORATIVE_OBJECTS/BULK/TRANSFER_OBJECT_REQUEST'
export const TRANSFER_OBJECT_SUCCESS = 'CORPORATIVE_OBJECTS/BULK/TRANSFER_OBJECT_SUCCESS'
export const TRANSFER_OBJECT_FAILURE = 'CORPORATIVE_OBJECTS/BULK/TRANSFER_OBJECT_FAILURE'

export interface TransferObjectsRequest {
  type: typeof TRANSFER_OBJECT_REQUEST
}

export interface TransferObjectsSuccess {
  type: typeof TRANSFER_OBJECT_SUCCESS
}

export interface TransferObjectsFailure {
  type: typeof TRANSFER_OBJECT_FAILURE
}

export const SET_OPEN_BULK_TRANSFER_MODAL = 'CORPORATIVE_OBJECTS/BULK/SET_OPEN_TRANSFER_MODAL'

export interface setIsOpenModal {
  type: typeof SET_OPEN_BULK_TRANSFER_MODAL
  payload: boolean
}

export type BulkTransferActions =
  | TransferObjectsRequest
  | TransferObjectsSuccess
  | TransferObjectsFailure
  | setIsOpenModal
  | SetNumberField
  | GetDepositListRequest
  | GetDepositListSuccess
  | GetDepositListFailure
  | GetLocationListRequest
  | GetLocationListSuccess
  | GetLocationListFailure
  | SetLocations
