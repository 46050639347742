import config from './config'

const { URL, WEB_APP_URL, LANDING_URL } = config

export const URL_BASE_LOGIN = URL + '/account-manager'
export const URL_BASE_USERS = URL + '/account-manager'
export const URL_BASE_CATEGORIES = URL + '/category-creation'
export const URL_BASE_OBJECTS = URL + '/object-administration'
export const URL_BASE_COMPLAINTS = URL + '/complaints'
export const URL_BASE_SALES = URL + '/sales'
export const URL_BASE_SERVICES = URL + '/serviceType'
export const URL_BASE_TRANSACTIONS = URL + '/transaction-handler'
export const URL_BASE_PAYMENTS = URL + '/payments'
export const URL_BASE_PROFILE = URL + '/user-profile'
export const URL_BASE_TIMESLOT = URL + '/timeslot'
export const URL_BASE_NOTIFICATIONS = URL + '/notifications'
export const URL_BASE_MERCURIO = URL + '/mercurio'
export const URL_BASE_TRIPS = URL + '/trips-service'
export const URL_BASE_PAYMENT_COLLECTOR = URL + '/payment-collector'
export const URL_BASE_BFF = URL + '/app-bff'
export const URL_BASE_WEBAPP = WEB_APP_URL
export const URL_BASE_LANDING = LANDING_URL
export const URL_BASE_SPREEDLY = 'https://core.spreedly.com'
