import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { SaleActions } from '../../../projectApi/Sales/reasons'
import { AcceptParams } from '../../../projectApi/Sales/SalesRequest/accept'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import SalesActionCreator from '../actionCreators/common'
import SalesRequestViewActionCreator from '../actionCreators/salesRequestView'
import { ModalProps, ModalReject } from '../components/SalesRequestRejectModal'
import { SalesRequestView, SalesRequestViewProps } from '../components/SalesRequestView'
import SaleModal from '../containers/SaleModal'

type ParamsProps = {
  saleRequestID: number
}

const useParsedParams = (): ParamsProps => {
  const { saleRequestID } = useParams<{ saleRequestID: string }>()

  return { saleRequestID: parseInt(saleRequestID) }
}

const SalesRequestViewContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    getSearchSalesRequest,
    setOpenModal,
    rejectSaleRequest,
    clearViewState,
    getSaleCostArg,
    setPublicationPrice,
    getReasons,
    acceptProposal,
    createCounterProposal,
    setCounterProposalView,
    setSellOpenModal
  } = bindActionCreators(SalesRequestViewActionCreator, dispatch)
  const { getSalesStatus } = bindActionCreators(SalesActionCreator, dispatch)
  const { saleRequestID } = useParsedParams()

  useEffect(() => {
    if (!saleRequestID) return

    getSearchSalesRequest(saleRequestID)
    getSaleCostArg()
    getSalesStatus()

    return () => {
      clearViewState()
    }
  }, [saleRequestID])

  const viewState = useSelector((state) => state.SalesRequest.view)

  const openSaleRequestModal = () => {
    setSellOpenModal(true)
  }

  const closeSaleRequestModal = () => {
    history.goBack()
    setSellOpenModal(false)
  }

  const handleRejectSaleRequest = ({
    id,
    reason,
    saleCost,
    saleMargin
  }: {
    id: number
    reason: string
    saleCost: number
    saleMargin: number
  }) => {
    rejectSaleRequest({ id, reason, saleRequestId: viewState.salesRequest?.id || 0, saleCost, saleMargin })
  }

  const handleCreateCounterProposal = ({
    isExpressSale,
    prc,
    pve,
    pvp,
    saleCost,
    saleMargin,
    expressSaleCost,
    expressSaleMargin
  }: {
    isExpressSale: boolean
    prc: number
    pve?: number
    pvp: number
    saleCost: number
    saleMargin: number
    expressSaleCost?: number
    expressSaleMargin?: number
  }) => {
    createCounterProposal({
      id: saleRequestID,
      isExpressSale,
      prc,
      pvp,
      saleCost,
      saleMargin,
      pve,
      expressSaleCost,
      expressSaleMargin
    })
  }

  const handleOpenModal = (action: SaleActions) => {
    getReasons(action)
    setOpenModal(true)
  }

  const setModalClosed = () => {
    setOpenModal(false)
  }

  const handleAcceptProposal = ({
    pvp,
    saleCost,
    saleMargin,
    existExpressSale,
    expressSaleCost,
    expressSaleMargin,
    pve
  }: AcceptParams) => {
    acceptProposal({
      pvp,
      saleCost,
      saleMargin,
      id: saleRequestID,
      existExpressSale,
      expressSaleCost,
      expressSaleMargin,
      pve
    })
  }

  useEvents(
    [Events.SaleRequest.ACCEPT_PROPOSAL, Events.SaleRequest.REJECT_REQUEST, Events.SaleRequest.COUNTER_PROPOSAL],
    () => {
      getSearchSalesRequest(saleRequestID)
      history.push('/salesRequest')
    }
  )
  const setOpenCounterProposalView = () => {
    setCounterProposalView(true)
  }

  const setCloseCounterProposalView = () => {
    setCounterProposalView(false)
  }

  const SALE_COST = viewState.salesRequest ? viewState.salesRequest?.prc * viewState.saleCostArg : 0
  const SALE_MARGIN =
    viewState.salesRequest && viewState.publicationPrice !== 0
      ? viewState.publicationPrice - (viewState.salesRequest.prc + SALE_COST)
      : 0

  const props: SalesRequestViewProps = {
    salesRequest: viewState.salesRequest,
    openSaleRequestModal,
    loadingView: viewState.loadingView,
    saleCostArg: viewState.saleCostArg,
    setPublicationPrice,
    handleAcceptProposal,
    publicationPrice: viewState.publicationPrice,
    isCounterProposalActive: viewState.isCounterProposalActive,
    setOpenCounterProposalView,
    setCloseCounterProposalView,
    handleOpenModal,
    SALE_COST,
    SALE_MARGIN,
    handleCreateCounterProposal,
    closeSaleRequestModal
  }

  const modalProps: ModalProps = {
    isOpen: viewState.isOpenModal,
    setModalClosed,
    handleRejectSaleRequest,
    reasons: viewState.reasons,
    loadingReasons: viewState.loadingReasons,
    SALE_COST,
    SALE_MARGIN
  }

  return (
    <>
      <SalesRequestView {...props} />
      <ModalReject {...modalProps} />
      <SaleModal />
    </>
  )
}

export default SalesRequestViewContainer
