import { Reducer } from 'redux'
import {
  DiscountDatesActions,
  DiscountDatesState,
  DiscountDatesTableTypes,
  GetDiscountDatesTypes
} from '../types/discountDates'
import { SortDirection } from '../../../components/DynamicTable/types/types'

const initialState: DiscountDatesState = {
  discounts: [],
  fetchingDiscounts: false,
  pagination: {
    page: 1,
    total: 0,
    pageSize: 10
  },
  sort: {
    field: 'date',
    direction: SortDirection.DESC
  },
  dateRangeFilters: [{ key: 'createdAt', startDate: null, endDate: null }],
  searchFilters: [{ key: 'date', text: '' }]
}

const discountDatesReducer: Reducer<DiscountDatesState, DiscountDatesActions> = (
  state: DiscountDatesState = initialState,
  action: DiscountDatesActions
): DiscountDatesState => {
  switch (action.type) {
    case DiscountDatesTableTypes.RESET_FILTERS:
      return initialState
    case GetDiscountDatesTypes.GET_DISCOUNT_DATES_REQUEST:
      return {
        ...state,
        fetchingDiscounts: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        sort: action.payload.sort || initialState.sort,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        searchFilters: action.payload.searchFilters || initialState.searchFilters
      }
    case GetDiscountDatesTypes.GET_DISCOUNT_DATES_SUCCESS:
      return {
        ...state,
        fetchingDiscounts: false,
        discounts: action.payload.discounts,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetDiscountDatesTypes.GET_DISCOUNT_DATES_FAILURE:
      return {
        ...state,
        fetchingDiscounts: false
      }
    default:
      return state
  }
}

export default discountDatesReducer
