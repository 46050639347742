export default {
  SET_NOTIFY_USER: 'NEW_REMOVAL/SET_NOTIFY_USER',
  SET_PAYMENT_BY: 'NEW_REMOVAL/SET_PAYMENT_BY',
  SET_STORAGE_COST: 'NEW_REMOVAL/SET_STORAGE_COST',
  UPDATE_ROUTE: 'NEW_REMOVAL/UPDATE_ROUTE',
  CREATE_TAG: 'NEW_REMOVAL/CREATE_TAG',
  CREATE_GUID: 'NEW_REMOVAL/CREATE_GUID',
  FINISH: 'NEW_REMOVAL/FINISH',
  OPEN_MODAL_RESUME: 'NEW_REMOVAL/OPEN_MODAL_RESUME',
  CLOSE_MODAL_RESUME: 'NEW_REMOVAL/CLOSE_MODAL_RESUME'
}
