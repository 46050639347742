import { ROLE_TYPE } from '@/sections/Agents/types/agentsTable'

export interface Option<T> {
  label: string
  value: keyof T
}

export const generateOptions = <T extends object>(enumObj: T): Option<T>[] =>
  Object.entries(enumObj).map(([key, value]) => ({
    label: value as string,
    value: key as keyof T
  }))

export const getSelectedOption = <T extends object>(
  options: Option<T>[],
  value: keyof T | null
): Option<T> | undefined => (value === null ? undefined : options.find((option) => option.value === value))

export const handleRoleType = (roleId: number) => ROLE_TYPE[roleId]
