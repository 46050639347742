import {
  BulkTransferPossibleNumberFields,
  GET_DEPOSITS_FAILURE,
  GET_DEPOSITS_REQUEST,
  GET_DEPOSITS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GetDepositListRequest,
  GetDepositListSuccess,
  GetLocationListRequest,
  GetLocationListSuccess,
  SET_LOCATIONS,
  SET_NUMBER_FIELD,
  SET_OPEN_BULK_TRANSFER_MODAL,
  SetLocations,
  SetNumberField,
  TRANSFER_OBJECT_FAILURE,
  TRANSFER_OBJECT_REQUEST,
  TRANSFER_OBJECT_SUCCESS,
  setIsOpenModal
} from '../types/bulkTransfer'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { API } from '../../../../projectApi'
import { LocationListParams, Location } from '../../../../projectApi/TransactionHandler/Locations/Corporative/list'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import { FormattedObject } from '../../../../projectApi/ObjectAdministration/common'
import { Events } from '../../../../utils/eventEmitter/events'
import Emitter from '../../../../utils/eventEmitter'

const BulkTransferActionCreator = {
  setOpenBulkTransferModal: (isOpen: boolean): setIsOpenModal => ({
    type: SET_OPEN_BULK_TRANSFER_MODAL,
    payload: isOpen
  }),
  setNumberField({ field, value }: { field: BulkTransferPossibleNumberFields; value: number }): SetNumberField {
    return {
      type: SET_NUMBER_FIELD,
      payload: {
        field,
        value
      }
    }
  },
  setLocations({ locations }: { locations: Location[] }): SetLocations {
    return {
      type: SET_LOCATIONS,
      payload: {
        locations
      }
    }
  },
  getDeposits(): AppThunk {
    return async (dispatch) => {
      const request: GetDepositListRequest = {
        type: GET_DEPOSITS_REQUEST,
        payload: {
          fetchingDeposits: true
        }
      }

      dispatch(request)

      try {
        const { deposits } = await API.TransactionHandler.Deposits.list({ limit: 999, offset: 0 })

        const success: GetDepositListSuccess = {
          type: GET_DEPOSITS_SUCCESS,
          payload: {
            deposits
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_DEPOSITS_FAILURE, dispatch)
      }
    }
  },
  getLocations({ params }: { params: LocationListParams }): AppThunk {
    return async (dispatch) => {
      const request: GetLocationListRequest = {
        type: GET_LOCATIONS_REQUEST,
        payload: {
          fetchingLocations: true
        }
      }

      dispatch(request)

      try {
        const { locations, total } = await API.TransactionHandler.Location.Corporative.list({
          limit: 0,
          offset: 0,
          depositId: params.depositId
        })

        const success: GetLocationListSuccess = {
          type: GET_LOCATIONS_SUCCESS,
          payload: {
            locations,
            total
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_LOCATIONS_FAILURE, dispatch)
      }
    }
  },
  transferObjects:
    (objects: FormattedObject[], locationId: number): AppThunk =>
    async (dispatch) => {
      const toast = setToastLoading('Trasladando objetos')
      dispatch({ type: TRANSFER_OBJECT_REQUEST })
      try {
        const requests = objects.map(({ realId: objectId }) =>
          API.ObjectAdministration.Corporate.setLocation(objectId, {
            locationId
          })
        )
        await Promise.all(requests)
        dispatch({ type: TRANSFER_OBJECT_SUCCESS })
        setToastSuccessUpdate(toast, { render: 'Objetos trasladados correctamente' })
        Emitter.emit(Events.CorporateObjects.TRANSFER_OBJECTS)
      } catch (error) {
        dispatch({ type: TRANSFER_OBJECT_FAILURE, payload: { error } })
        setToastErrorUpdate(toast, { render: 'Hubo un error al trasladar los objetos' })
      }
    }
}

export default BulkTransferActionCreator
