import types from '../types/cancel'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { handleErrorsWithActionLoginAs } from '../../../../utils/HandleErrors'
import { apiPrivate } from '../../../../api'

const actions = {
  openPanel(operationId, guid) {
    return {
      type: types.OPEN,
      payload: { operationId, guid }
    }
  },
  close() {
    return { type: types.CLOSE }
  },
  cancel(guid, notify) {
    return (dispatch) => {
      dispatch({ type: types.CANCEL_REQUEST })

      return apiPrivate
        .delete(URL_BASE_TRANSACTIONS + '/admin/operation/deposit/' + guid + '?notify=' + notify.toString())
        .then((response) => {
          const { operation } = response.data
          dispatch(actions.cancelSuccess(operation))
        })
        .catch((error) => {
          handleErrorsWithActionLoginAs(error, types.CANCEL_FAILURE, dispatch)
        })
    }
  },
  cancelSuccess: (operation) => ({
    type: types.CANCEL_SUCCESS,
    payload: { operation }
  })
}

export default actions
