import { Prospect } from '../entities'

export type selectedCountry = {
  code: string
  currency: string
  flagUrl: string
  fullName: string
  label: string
  phoneCode: string
}

export type dataLead = {
  selectedCountry: selectedCountry
  gender: string
  name: {
    value: string
    errorMessage: string
    successMessage: string
  }
  userId: number
  email: {
    value: string
    loading: boolean
    errorMessage: string
    successMessage: string
    lastSearchTimestampEmail: number
  }
  phone: {
    currentNumber: string
    countryCode: string
    codeArea: {
      value: string
      error: boolean
      errorMessage: string
    }
    phoneNumber: {
      value: string
      error: boolean
      errorMessage: string
    }
    limitNumber: number
    value: string
    errorMessage: string
    successMessage: string
  }
  lastName: {
    value: string
    errorMessage: string
    successMessage: string
  }
  sourceChannel: string
  contactMedium: string
  interestReason: string
  discardReason: string
  freeLoseReason: string
  loseReason: string
  loseReasonMQL: string
  loseReasonSQL: string
  id: number
  comment: string
  funnelState: string
}

export type ModalLeadsState = {
  open: boolean
  editMode: boolean
  dataLead: dataLead
  statusId: number
  loadingLead: boolean
  error: string
}

export const SET_CREATE_MODAL_OPEN = 'LEADS/MODAL_LEADS/SET_CREATE_MODAL_OPEN'
export const SET_EDIT_MODAL_OPEN = 'LEADS/MODAL_LEADS/SET_EDIT_MODAL_OPEN'

export interface OpenModalCreate {
  type: typeof SET_CREATE_MODAL_OPEN
  payload: { createModalOpen: boolean; selectedCountry: selectedCountry; limit: number }
}

export interface OpenModalEdit {
  type: typeof SET_EDIT_MODAL_OPEN
  payload: { open: boolean; lead: Prospect; selectedCountry: selectedCountry; limit: number }
}

export const SET_NAME = 'LEADS/MODAL_LEADS/SET_NAME'
export const SET_LAST_NAME = 'LEADS/MODAL_LEADS/SET_LAST_NAME'
export const SET_EMAIL = 'LEADS/MODAL_LEADS/SET_EMAIL'
export const SET_PHONE = 'LEADS/MODAL_LEADS/SET_PHONE'
export const SET_COUNTRY_CODE = 'LEADS/MODAL_LEADS/SET_COUNTRY_CODE'
export const SET_CODE_AREA = 'LEADS/MODAL_LEADS/SET_CODE_AREA'
export const SET_PHONE_NUMBER = 'LEADS/MODAL_LEADS/SET_PHONE_NUMBER'
export const SET_GENDER = 'LEADS/MODAL_LEADS/SET_GENDER'
export const SET_COUNTRY = 'LEADS/MODAL_LEADS/SET_COUNTRY'
export const SET_SOURCE_CHANNEL = 'LEADS/MODAL_LEADS/SET_SOURCE_CHANNEL'
export const SET_CONTACT_MEDIUM = 'LEADS/MODAL_LEADS/SET_CONTACT_MEDIUM'
export const SET_INTEREST = 'LEADS/MODAL_LEADS/SET_INTEREST'
export const SET_DISCARD = 'LEADS/MODAL_LEADS/SET_DISCARD'
export const SET_LOSE_MQL = 'LEADS/MODAL_LEADS/SET_LOSE_MQL'
export const SET_LOSE_SQL = 'LEADS/MODAL_LEADS/SET_LOSE_SQL'
export const SET_STATUS = 'LEADS/MODAL_LEADS/SET_STATUS'

export interface SetName {
  type: typeof SET_NAME
  payload: { name: string }
}

export interface SetLastName {
  type: typeof SET_LAST_NAME
  payload: { lastName: string }
}

export interface SetSourceChannel {
  type: typeof SET_SOURCE_CHANNEL
  payload: { sourceChannelId: number }
}

export interface SetContactMedium {
  type: typeof SET_CONTACT_MEDIUM
  payload: { contactMediumId: number }
}

export interface SetEmail {
  type: typeof SET_EMAIL
  payload: { email: string }
}

export interface SetPhone {
  type: typeof SET_PHONE
  payload: { phone: string }
}

export interface SetCountryCode {
  type: typeof SET_COUNTRY_CODE
  payload: { countryCode: string; limit: number }
}

export interface SetCodeArea {
  type: typeof SET_CODE_AREA
  payload: { codeArea: string }
}

export interface SetPhoneNumber {
  type: typeof SET_PHONE_NUMBER
  payload: { phoneNumber: string }
}

export interface SetGender {
  type: typeof SET_GENDER
  payload: { gender: string }
}

export interface SetCountry {
  type: typeof SET_COUNTRY
  payload: { country: selectedCountry; limitPhoneNumber: number }
}

export interface SetStatus {
  type: typeof SET_STATUS
  payload: { statusId: number }
}

export interface SetInterest {
  type: typeof SET_INTEREST
  payload: { interestReasonId: number }
}

export interface SetDiscard {
  type: typeof SET_DISCARD
  payload: { discardReasonId: number }
}

export interface SetLoseMQL {
  type: typeof SET_LOSE_MQL
  payload: { loseReasonMQL: number }
}

export interface SetLoseSQL {
  type: typeof SET_LOSE_SQL
  payload: { loseReasonSQL: number }
}

type SetterDataAction =
  | SetName
  | SetLastName
  | SetSourceChannel
  | SetContactMedium
  | SetEmail
  | SetPhone
  | SetGender
  | SetCountry
  | SetInterest
  | SetDiscard
  | SetStatus
  | SetLoseMQL
  | SetLoseSQL
  | SetCountryCode
  | SetCodeArea
  | SetPhoneNumber

export const CREATE_LEAD_REQUEST = 'LEADS/MODAL_LEADS/CREATE_LEAD_REQUEST'
export const CREATE_LEAD_SUCCESS = 'LEADS/MODAL_LEADS/CREATE_LEAD_SUCCESS'
export const CREATE_LEAD_FAILURE = 'LEADS/MODAL_LEADS/CREATE_LEAD_FAILURE'

export interface CreateLeadRequest {
  type: typeof CREATE_LEAD_REQUEST
}

export interface CreateLeadFailure {
  type: typeof CREATE_LEAD_FAILURE
  payload: { error: string }
}

export interface CreateLeadSuccess {
  type: typeof CREATE_LEAD_SUCCESS
}

type CreateLeadActions = CreateLeadRequest | CreateLeadSuccess | CreateLeadFailure

export const EDIT_LEAD_REQUEST = 'LEADS/MODAL_LEADS/EDIT_LEAD_REQUEST'
export const EDIT_LEAD_SUCCESS = 'LEADS/MODAL_LEADS/EDIT_LEAD_SUCCESS'
export const EDIT_LEAD_FAILURE = 'LEADS/MODAL_LEADS/EDIT_LEAD_FAILURE'

export interface EditLead {
  type: typeof EDIT_LEAD_REQUEST
}

export interface EditLeadFailure {
  type: typeof EDIT_LEAD_FAILURE
  payload: { error: string }
}

export interface EditLeadSuccess {
  type: typeof EDIT_LEAD_SUCCESS
}

type EditLeadActions = EditLead | EditLeadSuccess | EditLeadFailure

export const VERIFY_EMAIL_REQUEST = 'LEADS/MODAL_LEADS/VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'LEADS/MODAL_LEADS/VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'LEADS/MODAL_LEADS/VERIFY_EMAIL_FAILURE'

export interface VerifyEmailRequest {
  type: typeof VERIFY_EMAIL_REQUEST
  payload: {
    lastSearchTimestamp: number
  }
}
export interface VerifyEmailSuccess {
  type: typeof VERIFY_EMAIL_SUCCESS
  payload: {
    lastSearchTimestamp: number
    userData: {
      countryId?: string
      countryFullName?: string
      phone: string
      gender: string
      name: string
      lastName: string
      id: number
    }
  }
}

export interface VerifyEmailFailure {
  type: typeof VERIFY_EMAIL_FAILURE
  payload: {
    error: string
  }
}

type VerifyEmailActions = VerifyEmailRequest | VerifyEmailSuccess | VerifyEmailFailure

export const VALIDATE_EMAIL_SUCCESS = 'LEADS/MODAL_LEADS/VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_FAILURE = 'LEADS/MODAL_LEADS/VALIDATE_EMAIL_FAILURE'

export interface ValidateEmailSuccess {
  type: typeof VALIDATE_EMAIL_SUCCESS
}

export interface ValidateEmailFailure {
  type: typeof VALIDATE_EMAIL_FAILURE
  payload: { errorMessage: string }
}

type ValidateEmailActions = ValidateEmailSuccess | ValidateEmailFailure

export const SET_ERROR_NAME = 'LEADS/MODAL_LEADS/SET_ERROR_NAME'
export const SET_ERROR_LAST_NAME = 'LEADS/MODAL_LEADS/SET_ERROR_LAST_NAME'
export const SET_ERROR_EMAIL = 'LEADS/MODAL_LEADS/SET_ERROR_EMAIL'
export const SET_ERROR_PHONE = 'LEADS/MODAL_LEADS/SET_ERROR_PHONE'
export const SET_ERROR_CODE_AREA = 'LEADS/MODAL_LEADS/SET_ERROR_CODE_AREA'
export const SET_ERROR_PHONE_NUMBER = 'LEADS/MODAL_LEADS/SET_ERROR_PHONE_NUMBER'

export interface SetErrorName {
  type: typeof SET_ERROR_NAME
  payload: { error: string }
}

export interface SetErrorLastName {
  type: typeof SET_ERROR_LAST_NAME
  payload: { error: string }
}
export interface SetErrorEmail {
  type: typeof SET_ERROR_EMAIL
  payload: { error: string }
}

export interface SetErrorPhone {
  type: typeof SET_ERROR_PHONE
  payload: { error: string }
}

export interface SetErrorCodeArea {
  type: typeof SET_ERROR_CODE_AREA
  payload: { errorMessage: string }
}

export interface SetErrorNumberPhone {
  type: typeof SET_ERROR_PHONE_NUMBER
  payload: { errorMessage: string }
}

type ErrorLeadActions =
  | SetErrorName
  | SetErrorLastName
  | SetErrorEmail
  | SetErrorPhone
  | SetErrorCodeArea
  | SetErrorNumberPhone

export type ModalLeadsActions =
  | SetterDataAction
  | OpenModalCreate
  | OpenModalEdit
  | CreateLeadActions
  | EditLeadActions
  | VerifyEmailActions
  | ValidateEmailActions
  | ErrorLeadActions
