import { ReactNode } from 'react'
import moment, { Moment } from 'moment'
import { CategoryFilter, Sort } from '../../utils/searchFilterUtils'
import {
  AutocompleteProps,
  FilterOption,
  FiltersProps,
  RangePickerProps,
  SearchProps,
  SingleDatePickerProps,
  SortDirection,
  SortProps
} from './types/types'
import { BaseOption } from '../Select/Select'

export const getSearchProps = <AcceptedKey extends string>(
  key: AcceptedKey,
  label: string,
  searchFunction: (key: AcceptedKey, newValue: string) => void,
  currentSearchFilters: { key: AcceptedKey; text: string }[],
  extraComponent?: ReactNode,
  placeholder?: string
): SearchProps | undefined => {
  const found = currentSearchFilters.find((sf) => sf.key === key)
  if (!found) return

  return {
    label,
    value: found.text,
    onConfirm: (newValue) => searchFunction(key, newValue),
    extraComponent,
    placeholder
  }
}

export const getAutocompleteProps = <AcceptedKey extends string>(
  key: AcceptedKey,
  label: string,
  searchFunction: (newValue: number) => void,
  categoryId: number,
  searchOptions: (text: string) => void,
  options: BaseOption<number>[],
  loadingOptions: boolean,
  extraComponent?: ReactNode
): AutocompleteProps | undefined => {
  return {
    label,
    value: categoryId,
    onConfirm: (newValue) => searchFunction(newValue),
    options,
    getOptions: (option: string) => searchOptions(option),
    loadingOptions,
    extraComponent
  }
}

export const getSingleDateFilterProps = <AcceptedKey extends string>(
  key: AcceptedKey,
  currentSingleDateFilters: { key: AcceptedKey; date: Moment | null }[],
  handleSingleDateFilter: (key: AcceptedKey, date?: Moment) => void
): SingleDatePickerProps | undefined => {
  const foundFilter = currentSingleDateFilters.find((sf) => sf.key === key)
  if (!foundFilter) return

  return {
    date: foundFilter?.date && moment(foundFilter.date).isValid() ? moment(foundFilter.date) : undefined,
    onConfirm: (date) => handleSingleDateFilter(key, date)
  }
}

export const getRangePickerProps = <AcceptedKey extends string>(
  key: AcceptedKey,
  currentRangePickers: { key: AcceptedKey; startDate: Moment | null; endDate: Moment | null }[],
  handleRangePicker: (key: AcceptedKey, startDate?: Moment, endDate?: Moment) => void
): RangePickerProps | undefined => {
  const foundFilter = currentRangePickers.find((sf) => sf.key === key)
  if (!foundFilter) return

  return {
    startDate:
      foundFilter?.startDate && moment(foundFilter.startDate).isValid() ? moment(foundFilter.startDate) : undefined,
    endDate: foundFilter?.endDate && moment(foundFilter.endDate).isValid() ? moment(foundFilter.endDate) : undefined,
    onConfirm: (startDate, endDate) => handleRangePicker(key, startDate, endDate)
  }
}

export const getFilterProps = <T extends string>(
  options: FilterOption[],
  currentFilter: CategoryFilter<T>,
  category: T,
  handleFiltering: (newFilter: CategoryFilter<T>) => void,
  multiple = false
): FiltersProps => {
  return {
    multiple,
    options: options,
    selected: currentFilter?.[category] || [],
    onConfirm: (selected) => handleFiltering({ ...currentFilter, [category]: selected })
  }
}

export const getSortProps = <T extends string>(
  key: T,
  currentSorter: Sort<T>,
  sorterFunction: (newSorter: Sort<T>) => void
): SortProps => {
  return {
    direction: currentSorter.direction,
    isActive: currentSorter.field === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        direction: currentSorter.field === key ? newSortDirection : SortDirection.ASC,
        field: key
      })
  }
}
