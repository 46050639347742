import React from 'react'
import { Modal } from 'antd'
import deleteProductActions from '../actions/deleteModal'
import { DeleteProductState } from '../types/deleteModal'

const styles = {
  modal: {
    maxWidth: 298
  },
  error: {
    color: 'red',
    marginTop: 6
  },
  placeholder: {
    display: 'inline-block'
  },
  inlineText: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '196px',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  }
}
export interface DeleteProductModalProps {
  deleteProductState: DeleteProductState
  deleteProductActions: typeof deleteProductActions
}

const DeleteProductComponent = (props: DeleteProductModalProps) => {
  const { deleteProductState, deleteProductActions } = props
  const { cancelDeleteProductModal, deleteProduct } = deleteProductActions
  const { open, error, product } = deleteProductState

  const handleDeleteProduct = (id: string) => {
    deleteProduct(id)
  }

  return (
    <Modal
      title="Eliminar producto"
      visible={open}
      onCancel={() => cancelDeleteProductModal()}
      onOk={() => handleDeleteProduct(product?.guid || '')}
      okText="Eliminar"
      cancelText="Cancelar"
      style={styles.modal}>
      <div>
        <span style={styles.placeholder}>
          Desea eliminar el producto? <br />
          {product?.id} - {product?.description}
        </span>
      </div>
      {error !== '' ? <div style={styles.error}>{error}</div> : null}
    </Modal>
  )
}

export default DeleteProductComponent
