import { API } from '../../../projectApi'
import { UserRole } from '../../../projectApi/AccountManager/Users/common'
import { Shipper } from '../../../projectApi/Trips/Shippers/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  CreateShipperRequest,
  CreateShipperTypes,
  DeleteShipperRequest,
  DeleteShipperTypes,
  Driver,
  GetDriverListRequest,
  GetDriverListSuccess,
  GetDriverListTypes,
  GetProviderListRequest,
  GetProviderListSuccess,
  GetProviderListTypes,
  Provider,
  SetCurrentShipper,
  SetDeleteModalVisibility,
  SetDriver,
  SetModalVisibility,
  SetProvider,
  SettersShippersModalTypes,
  UpdateShipperRequest,
  UpdateShipperTypes
} from '../types/shippersABM'

const shippersABMActionCreators = {
  setModalVisibility({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }): SetModalVisibility {
    return {
      type: SettersShippersModalTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible, editMode }
    }
  },
  setDeleteModalVisibility({ deleteModalVisible }: { deleteModalVisible: boolean }): SetDeleteModalVisibility {
    return {
      type: SettersShippersModalTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisible }
    }
  },
  setCurrentShipper: (shipper: Shipper | null): SetCurrentShipper => ({
    type: SettersShippersModalTypes.SET_CURRENT_SHIPPER,
    payload: { shipper }
  }),
  setDriver: (driver: Driver | null): SetDriver => ({
    type: SettersShippersModalTypes.SET_DRIVER,
    payload: { driver }
  }),

  setProvider: (provider: Provider | null): SetProvider => ({
    type: SettersShippersModalTypes.SET_PROVIDER,
    payload: { provider }
  }),
  getDrivers:
    ({ name }: { name?: string }): AppThunk =>
    async (dispatch) => {
      const request: GetDriverListRequest = {
        type: GetDriverListTypes.GET_DRIVER_LIST_REQUEST
      }

      dispatch(request)

      try {
        const { users } = await API.AccountManager.Users.list({ role: UserRole.TRANSPORTISTA, name })

        const success: GetDriverListSuccess = {
          type: GetDriverListTypes.GET_DRIVER_LIST_SUCCESS,
          payload: {
            drivers: users.map((u) => ({
              name: u.name,
              lastName: u.lastName,
              userId: u.realId
            }))
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetDriverListTypes.GET_DRIVER_LIST_FAILURE, dispatch)
      }
    },
  getProviders({ name }: { name?: string }): AppThunk {
    return async (dispatch) => {
      const request: GetProviderListRequest = {
        type: GetProviderListTypes.GET_PROVIDER_LIST_REQUEST
      }

      dispatch(request)

      try {
        const { providers } = await API.Trips.Providers.list({ limit: 10, offset: 0, name })
        const success: GetProviderListSuccess = {
          type: GetProviderListTypes.GET_PROVIDER_LIST_SUCCESS,
          payload: {
            providers: providers.map((p) => ({
              name: p.name,
              userId: p.id
            }))
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetProviderListTypes.GET_PROVIDER_LIST_FAILURE, dispatch)
      }
    }
  },

  createShipper:
    ({ driverId, providerId }: { driverId: number; providerId: number }): AppThunk =>
    async (dispatch) => {
      const request: CreateShipperRequest = {
        type: CreateShipperTypes.CREATE_SHIPPER_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Creando transportista, por favor espere...')
      try {
        await API.Trips.Shippers.create({ userId: driverId, providerId })

        const success = {
          type: CreateShipperTypes.CREATE_SHIPPER_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Transportista creado correctamente.' })
        dispatch(shippersABMActionCreators.setModalVisibility({ modalVisible: false }))
        Emitter.emit(Events.Shippers.CREATE_SHIPPER)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, CreateShipperTypes.CREATE_SHIPPER_FAILURE, dispatch) ??
            'Error al intentar crear el transportista.'
        })
      }
    },
  updateShipper:
    ({ shipperId, providerId }: { shipperId: number; providerId: number }): AppThunk =>
    async (dispatch) => {
      const request: UpdateShipperRequest = {
        type: UpdateShipperTypes.UPDATE_SHIPPER_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Actualizando transportista, por favor espere...')
      try {
        await API.Trips.Shippers.update({ shipperId, providerId })

        const success = {
          type: UpdateShipperTypes.UPDATE_SHIPPER_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Transportista actualizado correctamente.' })
        dispatch(shippersABMActionCreators.setModalVisibility({ modalVisible: false }))
        Emitter.emit(Events.Shippers.UPDATE_SHIPPER)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, UpdateShipperTypes.UPDATE_SHIPPER_FAILURE, dispatch) ??
            'Error al intentar actualizar el transportista.'
        })
      }
    },
  deleteShipper:
    ({ shipperId }: { shipperId: number }): AppThunk =>
    async (dispatch) => {
      const request: DeleteShipperRequest = {
        type: DeleteShipperTypes.DELETE_SHIPPER_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Eliminando transportista, por favor espere...')
      try {
        await API.Trips.Shippers.delete({ shipperId })

        const success = {
          type: DeleteShipperTypes.DELETE_SHIPPER_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Transportista eliminado correctamente.' })
        dispatch(shippersABMActionCreators.setDeleteModalVisibility({ deleteModalVisible: false }))
        Emitter.emit(Events.Shippers.DELETE_SHIPPER)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(error, DeleteShipperTypes.DELETE_SHIPPER_FAILURE, dispatch) ??
            'Error al intentar eliminar el transportista.'
        })
      }
    }
}

export default shippersABMActionCreators
