import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'

export function confirm(id: number) {
  return apiPrivate
    .put(`${URL_BASE_SALES}/sales-requests/confirm-sale/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
