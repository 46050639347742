import { Country } from '../constants/constants'
import { REHYDRATE } from 'redux-persist/es/constants'

export type CountrySelectorState = {
  loadingCountries: boolean
  error: string
  countries: Country[]
  countrySelected: Country
}

export enum CountrySelectorTypes {
  GET_COUNTRIES_REQUEST = 'COUNTRIES/GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS = 'COUNTRIES/GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE = 'COUNTRIES/GET_COUNTRIES_FAILURE',
  SELECT_COUNTRY_FILTER = 'COMPONENT/SELECT_COUNTRY_FILTER'
}

export type GetCountriesRequest = {
  type: typeof CountrySelectorTypes.GET_COUNTRIES_REQUEST
}

export type GetCountriesSuccess = {
  type: typeof CountrySelectorTypes.GET_COUNTRIES_SUCCESS
  payload: {
    countries: Country[]
  }
}

export type GetCountriesFailure = {
  type: typeof CountrySelectorTypes.GET_COUNTRIES_FAILURE
  payload: {
    error: string
  }
}

export type SelectCountryFilter = {
  type: typeof CountrySelectorTypes.SELECT_COUNTRY_FILTER
  payload: {
    value: Country
  }
}

type ReduxRehydrate = {
  type: typeof REHYDRATE
  payload?: { CountrySelector?: CountrySelectorState }
}

export type CountrySelectorActions =
  | GetCountriesRequest
  | GetCountriesSuccess
  | GetCountriesFailure
  | SelectCountryFilter
  | ReduxRehydrate
