import React from 'react'
import styles from './DNICard.module.scss'

type DNICardProps = {
  name: string
  documentNumber: string
}

const DNICard = (props: DNICardProps) => {
  const { name, documentNumber } = props

  return (
    <div className={styles.container}>
      <div style={{ display: 'flex' }}>
        <picture className={styles.imageWrapper}>
          <img src={`${process.env.PUBLIC_URL}/dni_avatar.svg`} alt="dniAvatar" width="42" height="55" />
        </picture>
        <div className={styles.textWrapper}>
          <p className={styles.name}>{name}</p>
          <p className={styles.dniValue}>{documentNumber}</p>
        </div>
      </div>
    </div>
  )
}

export default DNICard
