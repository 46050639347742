import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/Summary'
import objectsActionCreators from '../actions/objects'
import paymentMPActionCreators from '../actions/payment-mp'
import { PaymentScreens } from '../constants'
import { bindActionCreators } from 'redux'
import { PaymentTypeRemoval } from '../../../projectApi/TransactionHandler/Operation/Removals/update'
import moment from 'moment'

export interface SummaryContainerProps {
  paymentScreen: PaymentScreens
  setPaymentScreen: (paymentScreen: PaymentScreens) => void
  step: number
  setStep: (step: number) => void
  editMode: boolean
  loading: boolean
  save: () => void
  pay: () => void
  payAsFree: (paymentTypeRemoval: PaymentTypeRemoval) => void
}

const Summary = (ownProps: SummaryContainerProps) => {
  const commonState = useSelector((state) => state.NewRemoval.common)
  const calendarState = useSelector((state) => state.NewRemoval.calendar)
  const userSearchState = useSelector((state) => state.NewRemoval.userSearch)
  const objectsState = useSelector((state) => state.NewRemoval.objects)
  const transportState = useSelector((state) => state.NewRemoval.transport)
  const paymentMPState = useSelector((state) => state.NewRemoval.paymentMp)
  const paymentCBUState = useSelector((state) => state.NewRemoval.paymentCBU)

  const dispatch = useDispatch()
  const objectsActions = bindActionCreators(objectsActionCreators, dispatch)
  const paymentMPActions = bindActionCreators(paymentMPActionCreators, dispatch)

  useEffect(() => {
    objectsActions.calculateMinCost(objectsState.objectsToRemove, calendarState.datetime || moment())
  }, [objectsState.objectsToRemove, calendarState.datetime])

  const props = {
    ...ownProps,
    calendarState,
    commonState,
    userSearchState,
    objectsState,
    paymentMPState,
    paymentCBUState,
    transportState,
    paymentMPActions
  }

  return <Component {...props} />
}

export default Summary
