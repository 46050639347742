export const SET_SIRENA_SOURCE = 'ORIGINS/EDIT/SET_SIRENA_SOURCE'
export const SET_CHANNEL = 'ORIGINS/EDIT/SET_CHANNEL'
export const SET_ERROR = 'ORIGINS/EDTI/SET_ERROR'

export const OPEN_EDIT_ORIGIN_MODAL = 'ORIGINS/EDIT/OPEN_EDIT_ORIGIN_MODAL'
export const CLOSE_EDIT_ORIGIN_MODAL = 'ORIGINS/EDIT/CLOSE_EDIT_ORIGIN_MODAL'
export const CANCEL_EDIT_ORIGIN_MODAL = 'ORIGINS/EDIT/CANCEL_EDIT_ORIGIN_MODAL'

export const EDIT_ORIGIN_REQUEST = 'ORIGINS/EDIT/EDIT_ORIGIN_REQUEST'
export const EDIT_ORIGIN_SUCCESS = 'ORIGINS/EDIT/EDIT_ORIGIN_SUCCESS'
export const EDIT_ORIGIN_FAILURE = 'ORIGINS/EDIT/EDIT_ORIGIN_FAILURE'

export interface EditOriginState {
  open: boolean
  id: number
  sirenaSource: string
  channel: number
  isLoading: boolean
  error: string
}

export interface SetError {
  type: typeof SET_ERROR
  payload: {
    error: string
  }
}

export interface SetSirenaSource {
  type: typeof SET_SIRENA_SOURCE
  payload: {
    sirenaSource: string
  }
}

export interface SetChannel {
  type: typeof SET_CHANNEL
  payload: {
    channel: number
  }
}
export interface OpenEditOriginModal {
  type: typeof OPEN_EDIT_ORIGIN_MODAL
  payload: {
    id: number
    sirenaSource: string
    channel: number | string
  }
}

export interface CloseEditOriginModal {
  type: typeof CLOSE_EDIT_ORIGIN_MODAL
}

export interface CancelEditOriginModal {
  type: typeof CANCEL_EDIT_ORIGIN_MODAL
}

export type EditOriginModalAction = OpenEditOriginModal | CloseEditOriginModal | CancelEditOriginModal

export interface EditOriginRequest {
  type: typeof EDIT_ORIGIN_REQUEST
}

export interface EditOriginSuccess {
  type: typeof EDIT_ORIGIN_SUCCESS
}

export interface EditOriginFailure {
  type: typeof EDIT_ORIGIN_FAILURE
  payload: {
    error: string
  }
}
export type EditOriginAction = EditOriginRequest | EditOriginSuccess | EditOriginFailure
