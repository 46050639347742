import * as Types from '../gqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDisabledCalendarQueryVariables = Types.Exact<{
  endDate: Types.Scalars['String']['input'];
  startDate: Types.Scalars['String']['input'];
}>;


export type GetDisabledCalendarQuery = { __typename?: 'Query', getCalendar: Array<{ __typename?: 'CalendarDateEntity', date: string, day: string, discount: number, depositDisabled: boolean, removalDisabled: boolean }> };


export const GetDisabledCalendarDocument = gql`
    query GetDisabledCalendar($endDate: String!, $startDate: String!) {
  getCalendar(endDate: $endDate, startDate: $startDate) {
    date
    day
    discount
    depositDisabled
    removalDisabled
  }
}
    `;

/**
 * __useGetDisabledCalendarQuery__
 *
 * To run a query within a React component, call `useGetDisabledCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisabledCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisabledCalendarQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetDisabledCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables> & ({ variables: GetDisabledCalendarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables>(GetDisabledCalendarDocument, options);
      }
export function useGetDisabledCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables>(GetDisabledCalendarDocument, options);
        }
export function useGetDisabledCalendarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables>(GetDisabledCalendarDocument, options);
        }
export type GetDisabledCalendarQueryHookResult = ReturnType<typeof useGetDisabledCalendarQuery>;
export type GetDisabledCalendarLazyQueryHookResult = ReturnType<typeof useGetDisabledCalendarLazyQuery>;
export type GetDisabledCalendarSuspenseQueryHookResult = ReturnType<typeof useGetDisabledCalendarSuspenseQuery>;
export type GetDisabledCalendarQueryResult = Apollo.QueryResult<GetDisabledCalendarQuery, GetDisabledCalendarQueryVariables>;
export function refetchGetDisabledCalendarQuery(variables: GetDisabledCalendarQueryVariables) {
      return { query: GetDisabledCalendarDocument, variables: variables }
    }