import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import billingActionCreator from '../actions/billingModal'
import BillingModal from '../components/billingModal'
import { bindActionCreators } from 'redux'

const BillingModalContainer = () => {
  const dispatch = useDispatch()
  const billingActions = bindActionCreators(billingActionCreator, dispatch)
  const { billing } = useSelector((state) => state.Users)
  const { userId } = billing

  useEffect(() => {
    if (userId !== 0) {
      billingActions.getBillingClientInfo(userId)
    }
  }, [userId])

  const props = {
    billing,
    billingActions
  }

  return <BillingModal {...props} />
}

export default BillingModalContainer
