/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { DepositStatus } from '../Operation/list'

interface Transaction {
  id: number
  type_id: number
  reserve_date: string
}

interface EntryCosts {
  packaging_cost_in_cents: number
  disassembly_cost: number
  deposit_cost_per_floor_in_cents: number
}

export interface ObjectPhoto {
  id: number
  main_photo: boolean
  object_id: number
  s3_key: string
  url: string
}

interface Attribute {
  createdAt: string
  dataType: string
  id: number
  isVisible: boolean
  name: string
  unit: string
}

interface Product {
  id: number
  guid: string
  name: string
  price: number
  entry_costs: EntryCosts
}

interface ProductObject {
  id: number
  product: Product
  photos: ObjectPhoto[]
  deleted_at: string | null
}

export interface ObjectsOperation {
  operation_id: number
  disassembly_cost: number
  floors: number
  floors_cost_per_floor_in_cents: number
  deposit_cost_per_floor_in_cents: number
  packaging_cost_in_cents: number
  price_in_cents: number
  object: ProductObject
  objectXAttributes?: ObjectXAttribute[]
}

interface OperationTransportExtras {
  operation_id: number
  kms_extra: number
  kms_extra_price: number
  transport_extra: number
  shipment_assistant_count: number
  shipment_assistant_price: number
}

interface Operation {
  id: number
  additional_cost: number
  transport_cost: number
  discount_code: string
  operation_core_id: number
  operation_core_type: string
  status: DepositStatus
  objects_operation: ObjectsOperation[]
  operation_transport_extras: OperationTransportExtras
  country_id: string
  toll_cost_in_cents: number
}

export type ObjectXAttribute = {
  id: number
  objectId: number
  attributeId: number
  value: string
  deletedAt: string | null
  attribute: Attribute
}

interface APIResponse {
  id: number
  packaging_cost: number
  disassembly_cost: number
  transaction: Transaction
  operation: Operation
}

type Response = CamelCasedPropertiesDeep<APIResponse>

interface GetByIdParams {
  id: number
}

export function getById({ id }: GetByIdParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/deposits-operations/${id}`)
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse = camelcaseKeys(response.data, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
