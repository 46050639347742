import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { STATUS_FILTERS } from './Activity.constants'
import { pathOr } from 'ramda'
import { formatNumber } from '../../utils/formatNumber'
import { getColumnSearchProps } from '../../utils/columnSearchProps'
import ClientIDLinkComponent from '../../components/ClientIDLink/ClientIDLink'
import { usePaginationLabel } from '../../utils/hooks/usePaginationLabel'
import { Edit } from '../../icons'
import IconButton from '../../components/IconButton'
import styles from './ActivityTable.module.scss'

const formatDate = (date) => moment(date).format('DD/MM/YY')

const getLabel = (value, labels) =>
  pathOr(
    '',
    ['text'],
    labels.find((label) => label.value === value)
  )

const ActivityTable = (props) => {
  const {
    params,
    history,
    handleSearch,
    handleChange,
    activity,
    loading,
    currentPagination,
    filters,
    searchFilters,
    paymentMethods,
    setUpdateModalOpen
  } = props

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      ...getColumnSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      title: 'ID Regla',
      dataIndex: 'rule_id',
      width: 100,
      ...getColumnSearchProps('rule_id', 'ID Regla', handleSearch, searchFilters)
    },
    {
      title: 'Fecha de cobro',
      defaultSortOrder: 'descend',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      dataIndex: 'date',
      render: formatDate, // figure out how to put a calendar search
      ...getColumnSearchProps('date', 'Fecha de cobro', handleSearch, searchFilters, 'date')
    },
    {
      title: 'ID Usuario',
      dataIndex: 'user[id]',
      width: 120,
      render: (id) => (
        <ClientIDLinkComponent id={id} history={history}>
          {id}
        </ClientIDLinkComponent>
      ),
      ...getColumnSearchProps('user_id', 'ID Usuario', handleSearch, searchFilters)
    },
    {
      title: 'Nombre y Apellido',
      dataIndex: 'user[fullName]'
    },
    {
      title: 'ID Pago',
      dataIndex: 'payment_id',
      width: 100,
      ...getColumnSearchProps('payment_id', 'ID Pago', handleSearch, searchFilters)
    },
    {
      title: 'Forma de pago',
      dataIndex: 'payment_method',
      filters: paymentMethods,
      filterMultiple: false,
      filteredValue: filters.payment_method,
      render: (text) => getLabel(text, paymentMethods)
    },
    {
      title: 'Estado',
      dataIndex: 'status_id',
      filters: STATUS_FILTERS,
      filterMultiple: false,
      filteredValue: filters.status_id,
      render: (_, record) => record.status
    },
    {
      title: 'Monto',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      dataIndex: 'amount',
      render: (text) => '$' + formatNumber(text)
    },
    {
      title: 'Referencia',
      dataIndex: 'payment_reference'
    },
    {
      title: 'Acciones',
      render: (_, row) =>
        row.payment_method !== 2 && ( // Payment !== credit_card_mp
          <IconButton onClick={() => setUpdateModalOpen(true, row)} tooltipText="Actualizar pago">
            <Edit />
          </IconButton>
        )
    }
  ]

  const showTotal = usePaginationLabel(currentPagination.total, 'cobranzas')

  const pagination = {
    pageSize: params.Limit,
    total: currentPagination.total,
    current: currentPagination.current,
    size: 'small',
    style: {
      margin: '12px 0'
    },
    showTotal
  }

  return (
    <Table
      columns={columns}
      dataSource={activity}
      loading={loading}
      pagination={pagination}
      style={{ overscrollX: 'auto' }}
      scroll={{ x: true }}
      onChange={handleChange}
      rowKey="id"
      className={styles.customTableProps}
    />
  )
}

export default ActivityTable
