import { Address } from '../types/transport'

export const getMainAddress = (addresses: Address[]) => addresses.find((a: Address) => a.MainAddress) || addresses[0]

export const setMainAddress = (addressId: number, addresses: Address[]) =>
  addresses.find((address) => address.ID === addressId)

// En caso de que la dirección de la devolución no exista en la lista de direcciones del usuario. Buscar la dirección principal dentro de la lista de direcciones del usuario
// export const setMainAddress = (addressId: number, addresses: Address[]) =>
//   addresses.find((address) => address.ID === addressId) || getMainAddress(addresses)
