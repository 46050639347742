import { Tooltip } from 'antd'
import clsx from 'clsx'
import React, { NamedExoticComponent, ReactNode } from 'react'
import { StatusCardTooltip } from '../StatusCard/StatusCard'
import styles from './StatCard.module.scss'

interface StatCardCompound {
  Cell: typeof Cell
  Title: typeof Title
  MainContent: typeof MainContent
  Summary: typeof Summary
  Percentage: typeof Percentage
  BasicStat: typeof BasicStat
}

type BaseStatCardProps = {
  children: React.ReactNode
}

const BaseStatCard: React.FC<BaseStatCardProps> = ({ children }) => {
  if (!children) return null
  if (children && !Array.isArray(children))
    return (
      <article className={styles.card}>
        <Cell>{children}</Cell>
      </article>
    )
  return <article className={styles.card}>{children}</article>
}

const Cell: React.FC<{
  children?: ReactNode
  description?: React.ReactElement | string
  onClick?: () => void
  active?: boolean
}> = ({ children, description, onClick, active }) => {
  if (!children) return null

  return (
    <div className={styles.cell}>
      {description && <StatusCardTooltip description={description} color="#5D58F7" />}
      {children}
      {onClick && (
        <button
          className={`${styles.button} ${active && styles.active}`}
          onClick={() => {
            onClick()
          }}>
          Ver todas
        </button>
      )}
    </div>
  )
}

const Title: React.FC<{ titleCard?: string }> = ({ titleCard }) => {
  return <p className={styles.title}>{titleCard}</p>
}

const MainContent: React.FC<{ content?: string | number }> = ({ content }) => {
  return <p className={styles.mainContent}>{content}</p>
}

const Summary: React.FC<{ summary: string; className?: string; description?: React.ReactElement | string }> = ({
  summary,
  className,
  description
}) => {
  const Paragraph: React.FC = () => <p className={clsx(className && styles[className], styles.summary)}>{summary}</p>
  if (description)
    return (
      <Tooltip placement="bottom" title={description}>
        <div>
          <Paragraph />
        </div>
      </Tooltip>
    )
  return <Paragraph />
}

const Percentage: React.FC<{ stat?: string; from?: string; percentage?: string | number }> = ({
  stat,
  from,
  percentage
}) => {
  return (
    <p className={styles.percentageLine}>
      {stat} {from && `/ ${from}:`} <span className={styles.percentage}>{percentage && `${percentage}%`}</span>
    </p>
  )
}

const BasicStat: React.FC<{ title: string; stat: string | number }> = ({ title, stat }) => {
  return (
    <p className={styles.basicStat}>
      {title}: <span className={styles.bolder}>{stat}</span>
    </p>
  )
}

const MemoizedStatCard = React.memo(BaseStatCard) as NamedExoticComponent<BaseStatCardProps> & StatCardCompound

MemoizedStatCard.Summary = Summary
MemoizedStatCard.Cell = Cell
MemoizedStatCard.Title = Title
MemoizedStatCard.MainContent = MainContent
MemoizedStatCard.Summary = Summary
MemoizedStatCard.Percentage = Percentage
MemoizedStatCard.BasicStat = BasicStat

export const StatCard = MemoizedStatCard
