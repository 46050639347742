import { Provider } from '../../../projectApi/Trips/Providers/list'
import { Vehicle, VehicleCreateUpdateParams } from '../../../projectApi/Trips/Vehicles/common'

export type ABMVehiclesModalState = {
  loadingProviders: boolean
  providers: Provider[]
  editMode: boolean
  id: number
  modalVisible: boolean
  deleteModalVisible: boolean
  creatingVehicle: boolean
  updatingVehicle: boolean
  deletingVehicle: boolean
  disabledDeleteButton: boolean
  selectedVehicle: VehicleCreateUpdateParams
  selectedVehicleToDelete: Vehicle | null
}

export enum SettersVehiclesModalTypes {
  SET_MODAL_VISIBILITY = 'VEHICLE/SET_MODAL_VISIBILITY',
  SET_VEHICLE_FIELD = 'VEHICLE/SET_VEHICLE_FIELD',
  SET_DELETE_MODAL_VISIBILITY = 'VEHICLE/SET_DELETE_MODAL_VISIBILITY',
  SET_SELECTED_VEHICLE = 'VEHICLE/SET_SELECTED_VEHICLE'
}

export interface SetModalVisibility {
  type: typeof SettersVehiclesModalTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean; editMode?: boolean }
}

export interface SetVehicleField {
  type: typeof SettersVehiclesModalTypes.SET_VEHICLE_FIELD
  payload: {
    field: keyof VehicleCreateUpdateParams
    value: VehicleCreateUpdateParams[keyof VehicleCreateUpdateParams]
  }
}

export interface SetDeleteModalVisibility {
  type: typeof SettersVehiclesModalTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisibility: boolean; vehicle?: Vehicle }
}

export interface SetSelectedVehicle {
  type: typeof SettersVehiclesModalTypes.SET_SELECTED_VEHICLE
  payload: { selectedVehicle: Vehicle }
}

type SettersVehicleModalActions = SetModalVisibility | SetVehicleField | SetDeleteModalVisibility | SetSelectedVehicle

export enum CreateVehicleTypes {
  CREATE_VEHICLE_REQUEST = 'VEHICLE/ABM_VEHICLES/CREATE_VEHICLE_REQUEST',
  CREATE_VEHICLE_SUCCESS = 'VEHICLE/ABM_VEHICLES/CREATE_VEHICLE_SUCCESS',
  CREATE_VEHICLE_FAILURE = 'VEHICLE/ABM_VEHICLES/CREATE_VEHICLE_FAILURE'
}

export interface CreateVehicleRequest {
  type: CreateVehicleTypes.CREATE_VEHICLE_REQUEST
}

export interface CreateVehicleSuccess {
  type: CreateVehicleTypes.CREATE_VEHICLE_SUCCESS
}

export interface CreateVehicleFailure {
  type: CreateVehicleTypes.CREATE_VEHICLE_FAILURE
}

export type CreateVehicleActions = CreateVehicleRequest | CreateVehicleSuccess | CreateVehicleFailure

export enum DeleteVehicleTypes {
  DELETE_VEHICLE_REQUEST = 'VEHICLE/ABM_VEHICLES/DELETE_VEHICLE_REQUEST',
  DELETE_VEHICLE_SUCCESS = 'VEHICLE/ABM_VEHICLES/DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILURE = 'VEHICLE/ABM_VEHICLES/DELETE_VEHICLE_FAILURE'
}

export interface DeleteVehicleRequest {
  type: DeleteVehicleTypes.DELETE_VEHICLE_REQUEST
}

export interface DeleteVehicleSuccess {
  type: DeleteVehicleTypes.DELETE_VEHICLE_SUCCESS
}

export interface DeleteVehicleFailure {
  type: DeleteVehicleTypes.DELETE_VEHICLE_FAILURE
}

export type DeleteVehicleActions = DeleteVehicleRequest | DeleteVehicleSuccess | DeleteVehicleFailure

export enum EditVehicleTypes {
  EDIT_VEHICLE_REQUEST = 'VEHICLE/CREATE_VEHICLE/EDIT_VEHICLE_REQUEST',
  EDIT_VEHICLE_SUCCESS = 'VEHICLE/CREATE_VEHICLE/EDIT_VEHICLE_SUCCESS',
  EDIT_VEHICLE_FAILURE = 'VEHICLE/CREATE_VEHICLE/EDIT_VEHICLE_FAILURE'
}

export interface EditVehicleRequest {
  type: EditVehicleTypes.EDIT_VEHICLE_REQUEST
}

export interface EditVehicleSuccess {
  type: EditVehicleTypes.EDIT_VEHICLE_SUCCESS
  payload: {
    updatedVehicle: Vehicle
  }
}

export interface EditVehicleFailure {
  type: EditVehicleTypes.EDIT_VEHICLE_FAILURE
}

export type EditVehicleActions = EditVehicleRequest | EditVehicleSuccess | EditVehicleFailure

export enum GetProvidersTypes {
  FETCH_PROVIDERS_REQUEST = 'VEHICLES/PROVIDERS/FETCH_PROVIDERS',
  FETCH_PROVIDERS_SUCCESS = 'VEHICLES/PROVIDERS/FETCH_PROVIDERS_SUCCESS',
  FETCH_PROVIDERS_FAILURE = 'VEHICLES/PROVIDERS/FETCH_PROVIDERS_FAILURE'
}

export interface FetchProvidersRequest {
  type: GetProvidersTypes.FETCH_PROVIDERS_REQUEST
}

export interface FetchProvidersSuccess {
  type: GetProvidersTypes.FETCH_PROVIDERS_SUCCESS
  payload: {
    providers: Provider[]
  }
}

export interface FetchProvidersFailure {
  type: GetProvidersTypes.FETCH_PROVIDERS_FAILURE
  payload: {
    error: string
  }
}

type FetchProvidersTypes = FetchProvidersRequest | FetchProvidersSuccess | FetchProvidersFailure

export type ABMVehiclesActions =
  | SettersVehicleModalActions
  | CreateVehicleActions
  | DeleteVehicleActions
  | EditVehicleActions
  | FetchProvidersTypes
