import { AxiosError } from 'axios'
import { AppDispatch } from '../store'
import { getErrorMessageWithCode } from './CodeErrorHandler'

const endpointText = {
  sessionExpired: 'La sesión ha expirado',
  noPermissions: 'No tiene permisos para ver esta pantalla',
  somethingWentWrong: 'Algo salió mal',
  internetMayBeTheProblem: 'Algo salió mal, revisá tu conexión a internet'
}

const getErrorMessageLegacy = (error: AxiosError<any>) =>
  error?.response?.data?.description || error?.response?.data?.message || endpointText.somethingWentWrong

export const getErrorMessage = (error: AxiosError<any>) => {
  const data = error?.response?.data
  if (data?.code) {
    if (
      Boolean(data.cause) &&
      Boolean(data.course) &&
      Boolean(data.cause?.length) &&
      data.cause[0]?.code !== undefined
    ) {
      return getErrorMessageWithCode(data.cause[0]) || endpointText.somethingWentWrong
    }
    return getErrorMessageWithCode(data) || endpointText.somethingWentWrong
  }
  return getErrorMessageLegacy(error)
}

export const handleErrorsWithAction = (
  maybeError: unknown,
  action: string,
  dispatch: AppDispatch,
  optionalProps?: Record<string, unknown>
) => {
  const error = maybeError as AxiosError | string
  let message = ''

  if (typeof error === 'string') message = error
  else if (error.response) {
    switch (error.response.status) {
      case 401: {
        message = endpointText.sessionExpired
        break
      }
      case 403:
        message = endpointText.noPermissions
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = endpointText.internetMayBeTheProblem
  } else {
    message = endpointText.somethingWentWrong
  }
  dispatch({
    type: action,
    payload: {
      error: message,
      ...optionalProps
    }
  })
  return message
}

export const handleErrorsWithNoAction = (error: AxiosError) => {
  let message = ''

  if (error.response) {
    switch (error.response.status) {
      case 401:
        localStorage.clear()
        message = endpointText.sessionExpired
        window.location.assign('/login')
        break
      case 403:
        message = endpointText.noPermissions
        break
      case 404:
        message = endpointText.somethingWentWrong
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = endpointText.internetMayBeTheProblem
  } else {
    message = endpointText.somethingWentWrong
  }
  alert(message)
}

export const handleErrorsWithNoActionLogin = (error: AxiosError) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
      case 403:
        message = endpointText.noPermissions
        break
      case 404:
        message = endpointText.somethingWentWrong
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = endpointText.internetMayBeTheProblem
  } else {
    message = endpointText.somethingWentWrong
  }
  alert(message)
}

export const handleErrorsWithActionLoginAs = (error: AxiosError, action: string, dispatch: AppDispatch) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
      case 403:
        message = endpointText.noPermissions
        break
      case 404:
        message = endpointText.somethingWentWrong
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = endpointText.internetMayBeTheProblem
  } else {
    message = endpointText.somethingWentWrong
  }
  dispatch({
    type: action,
    payload: {
      error: message
    }
  })
}
