import React from 'react'
import { Moment } from 'moment'
import { DynamicTable } from '../../../components/DynamicTable'
import { CamelCaseOperation } from '../../../projectApi/TransactionHandler/Operation/common'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import {
  DepositsValidationDateRangeFilter,
  DepositsValidationDateRangeKey,
  DepositsValidationSearchKey,
  DepositsValidationTableCategoryFilter,
  DepositsValidationTableSearchFilter
} from '../types/table'
import { getRangePickerProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { parseDate } from '../../../components/DynamicTable/utils/utils'
import { MULTIPLE_STATUS_FILTERS, STATUS_FILTERS } from '../../../common/operationsTable/constants'
import ColorBadge from '../../../components/ColorBadge'
import { Button, Tooltip } from 'antd'
import { DepositStatus } from '../../../projectApi/TransactionHandler/Operation/list'
import { TableActionBar } from '../../../components/TableActionBar'
import { DepositsValidationMetrics } from '../types/depositsValidationMetrics'
import MetricsDepositsValidation from './depositsValidationMetrics'

export type DepositsValidationTableProps = {
  deposits: CamelCaseOperation[]
  pagination: Pagination
  searchFilters: DepositsValidationTableSearchFilter[]
  dateRangeFilters: DepositsValidationDateRangeFilter[]
  categoryFilter: DepositsValidationTableCategoryFilter
  loading: boolean
  handleCategoryFilter: (newFilter: DepositsValidationTableCategoryFilter) => void
  handleResetFilters: () => void
  handleSearch: (key: DepositsValidationSearchKey, newValue: string) => void
  handleRangePicker: (key: DepositsValidationDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleOpenValidationView: (depositId: number) => void
  quantityMetrics: DepositsValidationMetrics[]
  fetchingMetrics: boolean
}

const DepositsValidationTable: React.FC<DepositsValidationTableProps> = ({
  deposits,
  searchFilters,
  dateRangeFilters,
  loading,
  pagination,
  categoryFilter,
  handleCategoryFilter,
  handleResetFilters,
  handleSearch,
  handleRangePicker,
  handleOpenValidationView,
  quantityMetrics,
  fetchingMetrics
}) => {
  const columns: Column<CamelCaseOperation>[] = [
    {
      key: 'id',
      label: 'ID Ingreso',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'user.name',
      label: 'Nombre',
      minWidth: '104px',
      search: getSearchProps('user_name', 'nombre', handleSearch, searchFilters)
    },
    {
      key: 'user.lastName',
      label: 'Apellido',
      minWidth: '104px',
      search: getSearchProps('user_last_name', 'apellido', handleSearch, searchFilters)
    },
    {
      key: 'full_address_string',
      label: 'Dirección',
      minWidth: '128px',
      renderDataCell: ({ address }) => `${address?.street} ${address?.number}${address?.city && ', ' + address?.city}`
    },
    {
      key: 'created_at',
      label: 'F. de creación',
      minWidth: '102px',
      renderDataCell: ({ createdAt }) => {
        return parseDate(createdAt)
      },
      rangePicker: getRangePickerProps('createdAt', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'datetime',
      label: 'F. de servicio',
      minWidth: '116px',
      renderDataCell: ({ datetime, timeInterval }) => {
        const date = parseDate(datetime)
        return (
          <>
            {date} {timeInterval ? '(' + timeInterval.replace('-', 'a').replaceAll(':00', '') + ')' : ''}
          </>
        )
      },
      rangePicker: getRangePickerProps('datetime', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'status',
      label: 'Estado',
      renderDataCell: ({ status }) => {
        const found = STATUS_FILTERS.find((sf) => sf.value === status)
        if (!found) return status
        const props = {
          ...found.styleProps
        }
        return <ColorBadge {...props}>{found.text}</ColorBadge>
      },
      filters: {
        options: MULTIPLE_STATUS_FILTERS,
        multiple: true,
        selected: categoryFilter.status,
        onConfirm: (values) => handleCategoryFilter({ ...categoryFilter, status: values as DepositStatus[] })
      }
    },
    {
      key: 'validationAgent',
      label: 'Validado por',
      renderDataCell: ({ validationAgent, updatedAt, status }) => {
        if (status !== DepositStatus.VALIDATED) return '-'
        else {
          const date = parseDate(updatedAt || '')
          return (
            <div>
              {validationAgent} <br /> {date}
            </div>
          )
        }
      }
    },
    {
      key: 'button',
      label: '',
      renderDataCell: ({ id, status }) => {
        if (status === DepositStatus.VALIDATED)
          return (
            <Tooltip title='No se puede volver a validar un ingreso con estado "Validada"'>
              <Button disabled={true}>Iniciar</Button>
            </Tooltip>
          )
        else return <Button onClick={() => handleOpenValidationView(id)}>Iniciar</Button>
      }
    }
  ]
  return (
    <div>
      <MetricsDepositsValidation fetchingMetrics={fetchingMetrics} metrics={quantityMetrics} />
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
      </TableActionBar>

      <DynamicTable
        columns={columns}
        loading={loading}
        rows={deposits}
        keyExtractor={(entity) => entity.id}
        pagination={pagination}
        customHeightOffset={350}
      />
    </div>
  )
}

export default DepositsValidationTable
