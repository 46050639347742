import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

interface UpdateParams {
  id: string
  multiplier: number
  deposits: number[]
  solutionId: number
}
export function update({ id, multiplier, deposits, solutionId }: UpdateParams): Promise<void> {
  const payload = {
    multiplier,
    deposits,
    solution_id: solutionId
  }

  return apiPrivate
    .put(`${URL_BASE_CATEGORIES}/service-types/${id}`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
