import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import Select from '../../../components/Select'

const styles = {
  modal: {
    maxWidth: 298
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  selectStyles: {
    marginTop: 5
  },
  error: {
    color: 'red',
    textAlign: 'right'
  },
  placeholder: {
    display: 'inline-block'
  },
  inlineText: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '196px',
    border: 'solid 1px #cccccc',
    padding: '6px',
    borderRadius: '4px'
  }
}

const RemovePanel = (props) => {
  const { removeObject } = props
  const { cancelReasonModal, setDeletedReason } = props

  const deletedReasonsOptions = useMemo(
    () =>
      removeObject.deletedReasons.map((deletedReason) => ({
        label: deletedReason.reason,
        value: deletedReason.id
      })),
    [removeObject.deletedReasons]
  )
  const selectedDeletedReason = useMemo(
    () =>
      deletedReasonsOptions.find((deletedReasonsOption) => deletedReasonsOption.value === removeObject.deletedReason),
    [deletedReasonsOptions, removeObject.deletedReason]
  )

  const handleSubmitDelete = () => {
    if (props.removeObject.reason.length <= 5) {
      props.removeObjectFail('Minimo 5 caracteres')
      return
    }
    props.removeSelectObject(props.removeObject.objectId, props.removeObject.deletedReason, props.removeObject.reason)
  }
  const onSelectDeletedReason = (option) => setDeletedReason(option.value)

  return (
    <Modal
      title="Eliminar Objeto"
      visible={props.removeObject.open}
      onOk={() => handleSubmitDelete()}
      onCancel={() => cancelReasonModal()}
      okText="Eliminar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: props.isLoading }}
      okButtonProps={{ disabled: props.removeObject.reason.length <= 5 }}
      confirmLoading={props.removeObject.isLoading}
      closable={!props.removeObject.isLoading}
      maskClosable={!props.removeObject.isLoading}
      style={styles.modal}>
      <div style={styles.cont}>
        <span style={styles.inputTitle}>Razón:</span>
        <Select
          style={styles.selectStyles}
          name="deletedReason"
          placeholder="Razones de eliminación"
          selected={selectedDeletedReason}
          onSelect={onSelectDeletedReason}
          options={deletedReasonsOptions}
        />
        <InputFieldWithError
          type="text"
          label="Explicación"
          value={props.removeObject.reason}
          onChange={(e) => props.setReason(e.target.value)}
        />
      </div>
      {props.removeObject.error !== '' ? <div style={styles.error}>{props.removeObject.error}</div> : null}
    </Modal>
  )
}

RemovePanel.propTypes = {
  objectId: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  setReason: PropTypes.func.isRequired,
  setDeletedReason: PropTypes.func.isRequired,
  cancelReasonModal: PropTypes.func.isRequired,
  removeSelectObject: PropTypes.func.isRequired,
  removeObjectFail: PropTypes.func.isRequired,
  removeObject: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    deletedReasons: PropTypes.arrayOf(
      PropTypes.shape({
        reason: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    objectId: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    deletedReason: PropTypes.number.isRequired
  }).isRequired
}

export default RemovePanel
