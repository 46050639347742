import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/locationsTable'
import Component from '../components/locationsTable'
import QRActions from '../../../../components/QRModal/QRModalActions'
import { LocationsSearchFilter } from '../types/locationsTable'
import AddEditLocationModal from './AddEditLocationModal'
import LocationDeleteModal from './LocationDeleteModal'
import AddEditLocationModalActionsCreator from '../../../Locations/actions/AddEditLocationModal'
import locationDeleteActionsCreator from '../../../Locations/actions/LocationDelete'
import { NumberParam, useQueryParams } from 'use-query-params'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { removeNullishValues, updateMultipleSearchFilters } from '../../../../components/DynamicTable/queryParamsUtils'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import { Location } from '../../../../projectApi/TransactionHandler/Locations/Corporative/list'
import { Role } from '../../../../projectApi/AccountManager/Roles/getByParentId'

const Container = () => {
  const dispatch = useDispatch()
  const locationsActions = bindActionCreators(actions, dispatch)
  const QRModalActions = bindActionCreators(QRActions, dispatch)
  const addEditLocationModalActions = bindActionCreators(AddEditLocationModalActionsCreator, dispatch)
  const locationDeleteModalActions = bindActionCreators(locationDeleteActionsCreator, dispatch)

  const tableState = useSelector((state) => state.CorporateLocations.locationsTable)
  const { getLocations, getMetrics, resetFilters } = locationsActions

  const { role } = useSelector((state) => state.UserLogged.userProfile)
  const isRoleCorporative = role?.id === Role.CORPORATIVE
  const userId = localStorage.getItem('real_id') || ''

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    id: NumberParam,
    depositId: NumberParam,
    ownerId: NumberParam
  })

  const handleGetLocations = (newParams: { pagination?: Pagination; searchFilters?: LocationsSearchFilter[] }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    }
    getLocations(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handleGetMetrics = (newParams: { pagination?: Pagination; searchFilters?: LocationsSearchFilter[] }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    }
    getMetrics(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  useEvents(
    [
      Events.Location.LOCATION_CREATED,
      Events.Location.LOCATION_UPDATED,
      Events.Location.LOCATION_DELETED,
      Events.Location.RESETED_FILTERS
    ],
    () => {
      handleGetLocations({})
      handleGetMetrics({})
    }
  )

  const handleCleanFilters = () => {
    resetFilters()
    setQuery(removeNullishValues({}), 'push')
  }

  useEffect(() => {
    const searchFilters = updateMultipleSearchFilters(tableState.searchFilters, [
      { key: 'id', text: query.id?.toString() || '' },
      { key: 'deposit_id', text: query.depositId?.toString() || '' },
      { key: 'owner_id', text: (isRoleCorporative && userId) || '' }
    ])

    handleGetLocations({ searchFilters })
    handleGetMetrics({ searchFilters })
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetLocations({ pagination: { ...tableState.pagination, page: newPage } })
    handleGetMetrics({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: string, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetLocations({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
    handleGetMetrics({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }
  const handleOpenModalDelete = (location: Location) => {
    locationDeleteModalActions.setModal({ isModalOpen: true, location })
  }

  const handleOpenModalEdit = (location: Location) => {
    const formattedLocation = {
      ...location,
      depositID: location.depositId
    }
    addEditLocationModalActions.openModal(formattedLocation)
  }

  const openModalCreateLocation = () => {
    addEditLocationModalActions.openModal()
  }

  const pagination = {
    currentPage: tableState.pagination.page,
    pageSize: tableState.pagination.pageSize,
    total: tableState.pagination.total,
    onPageChange: handlePageChange
  }

  const props = {
    locationsActions,
    locationsTable: tableState,
    pagination,
    QRModalActions,
    handleSearch,
    handleOpenModalDelete,
    handleOpenModalEdit,
    handleCleanFilters,
    openModalCreateLocation
  }

  return (
    <>
      <Component {...props} />
      <AddEditLocationModal />
      <LocationDeleteModal />
    </>
  )
}

export default Container
