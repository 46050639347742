import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'ramda'
import { Edit } from '../../../icons'
import actions from '../actions/flags'
import FlagsTable from '../components/flagsTable'
import EditFlag from '../components/EditFlag'
import { TableActionBar } from '../../../components/TableActionBar'

const FlagsContainer = () => {
  const dispatch = useDispatch()
  const flagsActions = bindActionCreators(actions, dispatch)

  const { Flags } = useSelector((state) => state.Flags)
  const {
    flags,
    params,
    loading,
    pagination,
    modalEdit,
    newValue,
    id,
    searchFilters: currentSearchFilters,
    sorter: currentSorter
  } = Flags

  useEffect(() => {
    flagsActions.getFlags(params)
  }, [newValue])

  const getKeyFilter = (key) => {
    switch (key) {
      case 'ID':
        return 'id'
      case 'Name':
        return 'name'
      default:
        return key
    }
  }

  const handleSearch = (dataIndex, text) => {
    const { params, setSearchFilters, setPagination, getFlags, pagination, currentSearchFilters } = props
    const currentPagination = pagination

    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )

    const newParams = {
      ...params,
      [getKeyFilter(dataIndex)]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    getFlags(newParams)
  }

  const handlePagination = (newPage) => {
    const { currentPagination, getFlags, params, setPagination } = props

    const Offset = (newPage - 1) * params.Limit
    const newParams = { ...params, Offset }

    setPagination({ ...currentPagination, current: newPage })
    getFlags(newParams)
  }

  const handleSort = (newSorter) => {
    const { pagination, setSorter, setPagination, params, getFlags, currentSorter } = props
    const currentPagination = pagination

    setSorter(newSorter)
    setPagination({ ...currentPagination, current: 1 })
    getFlags({
      ...params,
      ...(newSorter.order ? { Order: newSorter.order } : {}),
      Offset: 0,
      Column: newSorter.field
    })
  }

  const flagsPagination = {
    pageSize: params.Limit,
    total: pagination.total,
    currentPage: pagination.current,
    onPageChange: handlePagination
  }

  const props = {
    ...flagsActions,
    flags,
    params,
    loading,
    modalEdit,
    id,
    pagination,
    currentSearchFilters,
    currentSorter
  }

  const menuActions = [
    {
      label: 'Editar Flag',
      icon: <Edit />,
      onClick: (row) => {
        flagsActions.setEditModalOpen(row)
      }
    }
  ]

  const handleCleanFilters = () => {
    flagsActions.cleanFilters()
    flagsActions.getTripsFromStore()
  }

  return (
    <div>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />
      </TableActionBar>
      <FlagsTable
        {...props}
        flags={flags}
        pagination={flagsPagination}
        loading={loading}
        searchFilters={currentSearchFilters}
        sorter={currentSorter}
        handleSearch={handleSearch}
        handleSort={handleSort}
        actions={menuActions}
      />
      <EditFlag {...props} />
    </div>
  )
}

export default FlagsContainer
