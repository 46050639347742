import types from '../types/flags'

const LIMIT = 50

const initialRoot = {
  error: '',
  loading: false,
  flags: [],
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'ID',
    Order: 'desc'
  },
  sorter: {
    order: 'desc',
    field: 'ID',
    columnKey: 'ID'
  },
  searchFilters: [
    { key: 'ID', text: '' },
    { key: 'Name', text: '' }
  ],
  pagination: {
    current: 1,
    total: 0
  },
  modalEdit: false,
  id: {
    Name: '',
    Value: 0
  },
  newValue: false
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.FETCH_FLAGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.FETCH_FLAGS_SUCCESS:
      return {
        ...state,
        flags: action.payload.flags,
        loading: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case types.FETCH_FLAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.SET_EDIT_MODAL_OPEN:
      return {
        ...state,
        modalEdit: true,
        id: action.payload.row
      }
    case types.SET_EDIT_MODAL_CLOSE: {
      return {
        ...state,
        modalEdit: false,
        newValue: false
      }
    }
    case types.EDIT_FLAG_REQUEST:
      return {
        ...state,
        newFlagValue: action.payload.newFlagValue
      }
    case types.EDIT_FLAG_SUCCESS:
      return {
        ...state,
        loading: false,
        newValue: true
      }
    case types.EDIT_FLAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.CHANGE_FLAG_VALUE:
      return {
        ...state,
        loading: false,
        id: {
          Value: action.payload.newFlagValue,
          Name: action.payload.Name
        }
      }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    case types.CLEAN_FILTERS: {
      return {
        ...state,
        params: initialRoot.params,
        searchFilters: initialRoot.searchFilters,
        pagination: initialRoot.pagination
      }
    }
    default:
      return state
  }
}

export default root
