import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { APIProduct, Product, formatProducts, FormattedProduct } from './common'
import snakecaseKeys from 'snakecase-keys'

interface APIResponse {
  products: APIProduct[]
  quantity: number
}

type Response<T> = {
  products: T[]
  total: number
}

export interface ListParams {
  name?: string
  countryCode: string
  mt3?: number
  userId?: number
  id?: number
  column?: string
  order?: string
  limit?: number
  offset?: number
  guids?: string
  showInSearch?: string
  deprecated?: string
  categoryId?: number
  serviceTypeId?: string
}

export function listAdmin<T = FormattedProduct>(
  params: ListParams,
  formatter?: (products: Product[]) => T[]
): Promise<Response<FormattedProduct | T>> {
  const { showInSearch, ...otherParams } = params
  const convertedParams = {
    ...snakecaseKeys(otherParams, { deep: true }),
    show_hidden: showInSearch
  }
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/search/product/admin/v2`, { params: convertedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.products || response.status !== 200)
        throw new Error('Respuesta incorrecta al obtener los productos')

      const camelCasedProducts: Product[] = camelcaseKeys(response.data.products, { deep: true })

      const camelResponse = {
        total: response.data.quantity,
        products: formatter ? formatter(camelCasedProducts) : formatProducts(camelCasedProducts)
      }

      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
