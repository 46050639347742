import React, { useMemo } from 'react'
import styles from './ValidationMetrics.module.scss'
import { RemovalValidationMetric } from '../types/metrics'
import StatusCard from '../../../components/StatusCard'
import { StatusCardSkeleton, StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { formatNumber } from '../../../utils/formatNumber'

export type ValidationsMetricsProps = {
  metrics: RemovalValidationMetric[]
  fetchingMetrics: boolean
}

const tooltipIconColor = '#b25e09'
const programmedMetricMessage = 'Programada + Programada (M)'

const ValidationsMetrics: React.FC<ValidationsMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const validationsMetrics = useMemo(() => {
    return metrics.map((metric) => ({
      ...metric,
      tooltipIconColor,
      description: metric.id === 'quantityProgrammedRemovals' ? programmedMetricMessage : ''
    }))
  }, [metrics, tooltipIconColor, programmedMetricMessage])

  return (
    <div className={styles.statusContainer}>
      {fetchingMetrics ? (
        <>
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-1'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-2'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-3'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-4'} loadingColor="#5D58F7" />
        </>
      ) : (
        validationsMetrics.map((metric) => {
          return (
            <StatusCard
              key={metric.id}
              type={mapOfMetricTypes[metric.id]}
              customClassname={`${styles.warningCard} ${styles[mapOfMetricsColor[metric.id]]}`}
              titleCard={metric.metric}
              total={formatNumber(metric.quantity, 0, true)}
              tooltipIconColor={metric.tooltipIconColor}
              description={metric.description}
              useOnlyCustomClass={true}
            />
          )
        })
      )}
    </div>
  )
}

export default ValidationsMetrics

const mapOfMetricTypes: { [key: string]: StatusCardTypes } = {
  total: 'info',
  quantityProgrammedRemovals: 'info',
  quantityPendingPaymentRemovals: 'info',
  quantityAuthorizedRemovals: 'info'
}

export const mapOfMetrics: { [key: string]: string } = {
  total: 'Total',
  quantityProgrammedRemovals: 'Programadas',
  quantityPendingPaymentRemovals: 'Pendientes de pago',
  quantityAuthorizedRemovals: 'Autorizadas'
}

export const mapOfMetricsColor: { [key: string]: string } = {
  total: 'spaceguru',
  quantityProgrammedRemovals: 'orange',
  quantityPendingPaymentRemovals: 'red',
  quantityAuthorizedRemovals: 'green'
}
