import { Moment } from 'moment'
import { getCustomDateTransportCountry } from '../../../../common/operations/flags/utils'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { API } from '../../../../projectApi'
import { Flag } from '../../../../projectApi/ObjectAdministration/Value/getValue'
import { AppThunk } from '../../../../store'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { ResponseOperation } from '../../entities'
import { Timeslot } from '../../types/EditDepositViewTypes/calendar'
import {
  CLEAN_STATES,
  CleanStates,
  EDIT_DEPOSIT_FAILURE,
  EDIT_DEPOSIT_REQUEST,
  EDIT_DEPOSIT_SUCCESS,
  EditDepositFailure,
  EditDepositRequest,
  EditDepositSuccess,
  FETCH_CONFIGURATION_FLAGS_FAILURE,
  FETCH_CONFIGURATION_FLAGS_REQUEST,
  FETCH_CONFIGURATION_FLAGS_SUCCESS,
  FetchConfigurationFlagsFailure,
  FetchConfigurationFlagsRequest,
  FetchConfigurationFlagsSuccess,
  GET_TRANSACTION_DATA_FAILURE,
  GET_TRANSACTION_DATA_REQUEST,
  GET_TRANSACTION_DATA_SUCCESS,
  GetModifyReasonsFailure,
  GetModifyReasonsRequest,
  GetModifyReasonsSuccess,
  GetModifyReasonsTypes,
  GetTransactionDataFailure,
  GetTransactionDataRequest,
  GetTransactionDataSuccess,
  SET_MODIFY_REASON,
  SetModifyReason
} from '../../types/EditDepositViewTypes/EditDepositView'
import CostActionsCreators from './cost'
import { SelectedItem } from '../../types/EditDepositViewTypes/items'
import { ItemBody } from '../../../../projectApi/TransactionHandler/Operation/Deposit/update'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import { OperationType } from '../../../../projectApi/TransactionHandler/Operation/Modify/list'
import { TransportMode } from '../../../../projectApi/TransactionHandler/TransportMode/common'
import { TransportTypesPricing } from '../../../../common/operations/constants'
import { TransportDetail } from '../../../../projectApi/TransactionHandler/Compute/cost'

const getItemsBody = (selectedItems: SelectedItem[]): ItemBody[] => {
  return selectedItems.reduce((accum: ItemBody[], item): ItemBody[] => {
    if (!item.selectedItem) return accum

    const itemBody: ItemBody = {
      id: 'itemID' in item.selectedItem ? item.selectedItem.itemID : 0,
      productId: item.selectedItem.id,
      amount: item.quantity,
      name: item.selectedItem.description,
      height: item.selectedItem.heightInCm,
      weight: item.selectedItem.weightInGr,
      length: item.selectedItem.lengthInCm,
      width: item.selectedItem.widthInCm,
      priceInCents: Math.round(item.selectedItem.price * 100),
      packagingCostInCents: Math.round(item.packaging ? item.selectedItem.packagingCostInCents : 0),
      costPerFloorInCents: Math.round(
        item.floors ? item.floors * item.selectedItem.depositFloorsByStairsCostInCents : 0
      ),
      disassemblyCost: item.disassemble ? item.selectedItem.disassemblyCost : 0,
      deleted: item.deleted
    }

    return [...accum, itemBody]
  }, [])
}

const EditDepositViewActionCreators = {
  getTransactionByOpId:
    ({ depositId }: { depositId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetTransactionDataRequest = {
        type: GET_TRANSACTION_DATA_REQUEST
      }

      dispatch(request)
      try {
        const operationResponse: ResponseOperation = (await API.TransactionHandler.Operation.getById({
          id: depositId
        })) as ResponseOperation
        if (!operationResponse) throw new Error('Falló la búsqueda en Operation')

        if (operationResponse.discountId) dispatch(CostActionsCreators.fetchDiscount(operationResponse.discountCode))

        const modifyReason = operationResponse.modifyReason?.id
          ? {
              reasonId: operationResponse.modifyReason?.id,
              name: operationResponse.modifyReason.name,
              description: operationResponse.modifyReason.description
            }
          : null

        const success: GetTransactionDataSuccess = {
          type: GET_TRANSACTION_DATA_SUCCESS,
          payload: {
            transaction: {
              generalData: {
                id: operationResponse.id,
                guid: operationResponse.guid,
                status: operationResponse.status,
                countryId: operationResponse.countryId,
                createdAt: operationResponse.createdAt,
                user: operationResponse.user
              },
              modifyReason,
              extraServices: {
                shipmentAssistants: {
                  shipmentAssistantCount: operationResponse.shipmentAssistantCount,
                  shipmentAssistantPrice: operationResponse.shipmentAssistantPrice
                }
              },
              serviceType: {
                serviceType: operationResponse.type,
                items: operationResponse.items
              },
              transport: {
                transportMode: operationResponse.transportModeType,
                transportType: operationResponse.transportType,
                address: operationResponse.address,
                transportCost: operationResponse.transportCost,
                depositId: operationResponse.depositId,
                tollCostInCents: operationResponse.tollCostInCents
              },
              dateData: {
                datetime: operationResponse.datetime,
                timeInterval: operationResponse.timeInterval,
                timeSlotId: operationResponse.timeSlotId
              },
              cost: {
                isPrevDiscount: Boolean(operationResponse.discountId),
                discountCode: operationResponse.discountCode,
                discountId: operationResponse.discountId
              }
            }
          }
        }
        dispatch(success)
        setTimeout(() => {
          Emitter.emit(Events.EditDepositView.GET_TRANSACTION_BY_ID_SUCCESS)
        }, 250)
      } catch (error) {
        const failure: GetTransactionDataFailure = {
          type: GET_TRANSACTION_DATA_FAILURE
        }
        dispatch(failure)
        console.error(error)
      }
    },

  getConfigurationFlags:
    (countryCode = CountryIdCode.ARGENTINA): AppThunk =>
    async (dispatch) => {
      const request: FetchConfigurationFlagsRequest = {
        type: FETCH_CONFIGURATION_FLAGS_REQUEST
      }

      dispatch(request)

      try {
        const config = {
          params: {
            country_code: countryCode
          }
        }

        const m3PriceBandsResponse = await API.CategoryCreation.PriceBand.getCurrentPriceBand({
          country: countryCode,
          type: 'm3'
        })

        const customDateTransportFlag = getCustomDateTransportCountry(countryCode)

        const [minBillableM3Value, customDateTransportMultValue] = await Promise.all([
          API.ObjectAdministration.Value.getValue({ flag: Flag.MIN_BILLABLE_M3 }, config),
          API.ObjectAdministration.Value.getValue({ flag: customDateTransportFlag })
        ])

        const m3Price = m3PriceBandsResponse.price

        const minBillableM3 = parseInt(minBillableM3Value, 10)
        const minBillableM3Price = m3Price * minBillableM3
        const customDateTransportMult = parseFloat(customDateTransportMultValue)

        const success: FetchConfigurationFlagsSuccess = {
          type: FETCH_CONFIGURATION_FLAGS_SUCCESS,
          payload: {
            m3Price,
            minBillableM3,
            minBillableM3Price,
            customDateTransportMult
          }
        }

        dispatch(success)

        setTimeout(() => {
          Emitter.emit(Events.EditDepositView.SET_PREV_TRANSPORT_COST)
        }, 250)
      } catch (error) {
        const failure: FetchConfigurationFlagsFailure = {
          type: FETCH_CONFIGURATION_FLAGS_FAILURE
        }

        dispatch(failure)
      }
    },

  editDeposit:
    ({
      destinationDeposit,
      operationId,
      timeslot,
      timeslots,
      timeInterval,
      date,
      addressId: address,
      transportCost,
      tollCostInCents,
      transportMode,
      transportModes,
      selectedItems,
      shipmentAssistantCount,
      shipmentAssistantPrice,
      modifyReasonId,
      totalStorageCost,
      transportType,
      validDiscount,
      discountCode,
      transportDetail
    }: {
      destinationDeposit: number
      operationId: number
      transportCost: number
      tollCostInCents: number
      date: Moment
      transportMode: string
      transportModes: TransportMode[]
      selectedItems: SelectedItem[]
      addressId: number
      timeslot: number
      timeslots: Timeslot[]
      timeInterval: string
      shipmentAssistantCount: number
      shipmentAssistantPrice: number
      modifyReasonId: number
      totalStorageCost: number
      transportType: TransportTypesPricing
      validDiscount: boolean
      discountCode: string
      transportDetail?: TransportDetail
    }): AppThunk =>
    async (dispatch) => {
      const request: EditDepositRequest = {
        type: EDIT_DEPOSIT_REQUEST
      }
      dispatch(request)
      const toastId = setToastLoading('Editando el ingreso, por favor espere...')

      try {
        const { hour, minute } = timeslots.find((t: Timeslot) => t.id === timeslot) || { hour: 0, minute: 0 }

        const formattedDate = date.hour(hour).minutes(minute).toISOString()
        const items = getItemsBody(selectedItems)

        const transportModeInfo = transportModes.find((tm) => tm.name === transportMode)
        const body = {
          serviceDate: formattedDate,
          type: transportMode,
          items,
          timeSlotId: timeslot,
          timeInterval,
          shipmentAssistantCount,
          shipmentAssistantPrice,
          modifyReason: modifyReasonId,
          totalStorageCost: totalStorageCost * 100,
          depositId: destinationDeposit,
          ...(transportModeInfo?.requireAddressOrigin && {
            transportCost,
            address,
            transportType,
            tollCostInCents
          }),
          ...(validDiscount && {
            discount: discountCode
          }),
          detail: transportDetail
            ? {
                transportCost: transportDetail
              }
            : null
        }

        await API.TransactionHandler.Operation.Deposits.edit({ operationId, body })

        const success: EditDepositSuccess = {
          type: EDIT_DEPOSIT_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'El ingreso se editó exitosamente' })
        Emitter.emit(Events.EditDepositView.EDIT_DEPOSIT_SUCCESS)
      } catch (error) {
        const failure: EditDepositFailure = {
          type: EDIT_DEPOSIT_FAILURE
        }

        dispatch(failure)
        setToastErrorUpdate(toastId, { render: 'Error al editar el ingreso' })
      }
    },

  getModifyReasons: (): AppThunk => async (dispatch) => {
    const request: GetModifyReasonsRequest = {
      type: GetModifyReasonsTypes.GET_MODIFY_REASONS_REQUEST
    }

    dispatch(request)

    try {
      const operationType = OperationType.DEPOSIT

      const { modifyReasons } = await API.TransactionHandler.Operation.Modify.list({ operationType })

      const success: GetModifyReasonsSuccess = {
        type: GetModifyReasonsTypes.GET_MODIFY_REASONS_SUCCESS,
        payload: { reasonsList: modifyReasons }
      }

      dispatch(success)
    } catch (error) {
      const failure: GetModifyReasonsFailure = {
        type: GetModifyReasonsTypes.GET_MODIFY_REASONS_FAILURE,
        payload: { errorMessage: (error as string) ?? 'Error al obtener los motivos de modificación' }
      }
      dispatch(failure)
    }
  },

  setModifyReason: ({ reasonId }: { reasonId: number }): SetModifyReason => {
    return {
      type: SET_MODIFY_REASON,
      payload: { reasonId }
    }
  },

  cleanStates: (): CleanStates => {
    return {
      type: CLEAN_STATES
    }
  }
}

export default EditDepositViewActionCreators
