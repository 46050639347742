import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { eqProps, equals, forEachObjIndexed, isEmpty } from 'ramda'
import actions from '../actions/billingTable'
import massBillingActions from '../actions/massBillingModal'
import calculateBillingActions from '../actions/calculateBillingModal'
import billingByClientTableActionCreators from '../../BillingV2/actionCreators/billingByClientTable'
import preBillingReportActionCreators from '../actions/preBillingReport'
import Component from '../components/table'
import { ReportsType } from '../../Reports/types'
import filesModalActionsCreator from '../actions/filesModal'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const preBillingReportActions = bindActionCreators(preBillingReportActionCreators, dispatch)
  const billingActions = bindActionCreators(actions, dispatch)
  const filesModalActions = bindActionCreators(filesModalActionsCreator, dispatch)
  const billingByClientActions = bindActionCreators(billingByClientTableActionCreators, dispatch)
  const billingMassActions = bindActionCreators(massBillingActions, dispatch)
  const billingCalculateActions = bindActionCreators(calculateBillingActions, dispatch)

  const {
    billings,
    error,
    params,
    loadingBillings,
    setSearchFilters,
    setPagination,
    searchFilters,
    pagination,
    filter,
    sorter: sorterProp
  } = useSelector((state) => state.Billing.billingTable)
  const loadingPreBillingReport = useSelector((state) => state.Billing.preBillingReport.loadingPreBillingReport)
  const lastPreBillingReportDate = useSelector((state) => state.Reports.lastReports[ReportsType.PRE_BILLING_REPORT])
  const { role } = useSelector((state) => state.UserLogged.userProfile)
  const { openFilesModal } = filesModalActions
  useEffect(() => {
    billingActions.getBillings(params)
  }, [])

  const getOrder = (order) => (order === 'descend' ? 'desc' : 'asc')

  const getKeyFilter = (key) => {
    switch (key) {
      case 'id':
        return 'id'
      default:
        return key
    }
  }

  const getColumn = (column) => {
    switch (column) {
      case 'id':
        return 'id'
      case 'userId':
        return 'user_id'
      default:
        return column
    }
  }

  const handleSearch = (dataIndex, text, confirm) => {
    const { params } = props
    const currentPagination = props.pagination
    const currentSearchFilters = props.searchFilters

    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    billingActions.setSearchFilters(searchFilters)
    billingActions.setPagination({ ...currentPagination, current: 1 })
    billingActions.getBillings(newParams)

    confirm()
  }

  const getNewFilterParams = (params, filters) => {
    let formatFilters = {}
    const offset = 0
    forEachObjIndexed((value, key) => {
      formatFilters = { ...formatFilters, [getKeyFilter(key)]: value[0] }
    }, filters)
    return { ...params, ...formatFilters, offset }
  }

  const handleChange = (pagination, filters, sorter, _) => {
    const { params } = props
    const currentFilter = filter
    const currentSorter = sorterProp
    const currentPagination = props.pagination

    if (!equals(filters, currentFilter)) {
      const newParams = { ...getNewFilterParams(params, filters) }

      billingActions.setFilter(filters)
      billingActions.setPagination({ ...currentPagination, current: 1 })
      billingActions.getBillings(newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order: orderSort, field } = sorter
      const order = getOrder(orderSort)
      const column = getColumn(field)
      const offset = 0

      billingActions.setSorter(sorter)
      billingActions.setPagination({ ...currentPagination, current: 1 })
      billingActions.getBillings({ ...params, offset, column, order })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current, pageSize } = pagination
      const offset = (current - 1) * pageSize
      const newParams = { ...params, offset }

      billingActions.setPagination(pagination)
      billingActions.getBillings(newParams)
    }
  }

  const props = {
    ...ownProps,
    ...billingActions,
    ...billingByClientActions,
    preBillingReportActions,
    role,
    loadingPreBillingReport,
    lastPreBillingReportDate,
    billings,
    billingMassActions,
    billingCalculateActions,
    error,
    loadingBillings,
    searchFilters,
    params,
    setSearchFilters,
    setPagination,
    pagination,
    handleSearch,
    handleChange,
    openFilesModal
  }

  return <Component {...props} />
}

export default Container
