import React from 'react'
import { Modal } from 'antd'
import { DeleteAddressState } from '../types/deleteAddress'
import { Params } from '../types/address'
import { AppThunk } from '../../../store'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center' as const,
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

export type DeleteAddressComponentProps = {
  deleteAddressAction: (userId: number, addressId: number) => AppThunk<Promise<void>>
  closeModal: () => void
  deleteAddress: DeleteAddressState
  getAddresses: (userId: number, params: Params) => void
  paramsFromAddress: Params
}

const DeleteAddress = (props: DeleteAddressComponentProps) => {
  const { deleteAddress, paramsFromAddress, deleteAddressAction, getAddresses, closeModal } = props
  const { error, loading, deleteAddressModalOpen, userId, addressId } = deleteAddress

  return (
    <Modal
      title={'Eliminar dirección'}
      visible={deleteAddressModalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={async () => {
        await deleteAddressAction(userId, addressId)
        await getAddresses(userId, paramsFromAddress)
      }}
      onCancel={() => closeModal()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p>¿Está seguro que quiere eliminar la dirección con id {addressId}?</p>
      <div className="container">
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

export default DeleteAddress
