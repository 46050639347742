import React from 'react'
import { DynamicTable } from '../../../../components/DynamicTable'
import { Column } from '../../../../components/DynamicTable/types/types'

import { ObjectChangesFormatted, Params } from '../types/objectChanges'
import { changeSpan, determinateIfValueChange, renderObjectIDStatus } from '../utils'
import { Tooltip } from 'antd'
import { parseDate } from '../../../../components/DynamicTable/utils/utils'

interface Props {
  objectChanges: ObjectChangesFormatted[]
  loading: boolean
  error: string
  params: Params
}

const ObjectChangesTable: React.FC<Props> = ({ objectChanges, loading, error }) => {
  const columns: Column<ObjectChangesFormatted>[] = [
    {
      key: 'objectId',
      label: 'ID'
    },
    {
      key: 'ownerId',
      label: 'ID Cliente',
      renderDataCell: ({ ownerId }, index) => {
        return determinateIfValueChange(objectChanges, index, 'ownerId') ? changeSpan(ownerId) : ownerId
      }
    },
    {
      key: 'identificationCodes',
      label: 'Etiqueta',
      alignment: 'center',
      renderDataCell: ({ identificationCodes }, index) =>
        determinateIfValueChange(objectChanges, index, 'identificationCodes')
          ? changeSpan(identificationCodes)
          : identificationCodes
    },
    {
      key: 'createdAt',
      label: 'Fecha de Cambio',
      minWidth: '150px',
      renderDataCell: ({ createdAt }) => parseDate(createdAt || '')
    },
    {
      key: 'location',
      label: 'Ubicación ID',
      minWidth: '130px',
      renderDataCell: ({ location }, index) =>
        determinateIfValueChange(objectChanges, index, 'location') ? changeSpan(location) : location
    },
    {
      key: 'user',
      label: 'GURU',
      minWidth: 'max-content',
      renderDataCell: ({ user }, index) =>
        determinateIfValueChange(objectChanges, index, 'user') ? changeSpan(user) : user
    },
    {
      key: 'objectStatusId',
      label: 'Objeto ID estado',
      minWidth: 'max-content',
      renderDataCell: (record, index) => renderObjectIDStatus(record.deletedDescription, objectChanges, record, index)
    },
    {
      key: 'objectSituation',
      label: 'Situación objeto',
      minWidth: 'max-content',
      renderDataCell: ({ objectSituation }, index) =>
        determinateIfValueChange(objectChanges, index, 'objectSituation')
          ? changeSpan(objectSituation)
          : objectSituation
    },
    {
      key: 'product',
      label: 'Producto ID',
      minWidth: 'max-content',
      renderDataCell: ({ product }, index) =>
        determinateIfValueChange(objectChanges, index, 'product') ? changeSpan(product) : product
    },
    {
      key: 'description',
      label: 'Descripción',
      renderDataCell: ({ description }, index) =>
        determinateIfValueChange(objectChanges, index, 'description') ? changeSpan(description) : description
    },
    {
      key: 'heightInCm',
      label: 'Alto [CM]',
      minWidth: 'max-content',
      renderDataCell: ({ heightInCm }, index) =>
        determinateIfValueChange(objectChanges, index, 'heightInCm') ? changeSpan(heightInCm) : heightInCm
    },
    {
      key: 'widthInCm',
      label: 'Ancho [CM]',
      minWidth: 'max-content',
      renderDataCell: ({ widthInCm }, index) =>
        determinateIfValueChange(objectChanges, index, 'widthInCm') ? changeSpan(widthInCm) : widthInCm
    },
    {
      key: 'lengthInCm',
      label: 'Largo [CM]',
      minWidth: 'max-content',
      renderDataCell: ({ lengthInCm }, index) =>
        determinateIfValueChange(objectChanges, index, 'lengthInCm') ? changeSpan(lengthInCm) : lengthInCm
    },
    {
      key: 'weightInGr',
      label: 'Peso [KG]',
      minWidth: 'max-content',
      renderDataCell: ({ weightInGr }, index) =>
        determinateIfValueChange(objectChanges, index, 'weightInGr') ? changeSpan(weightInGr / 1000) : weightInGr / 1000
    },
    {
      key: 'source',
      label: 'Fuente',
      renderDataCell: ({ source }, index) => (
        <Tooltip title={source}>
          <div style={{ maxWidth: '15ch' }}>
            {determinateIfValueChange(objectChanges, index, 'source') ? changeSpan(source) : source}
          </div>
        </Tooltip>
      )
    }
  ]

  return (
    <>
      <DynamicTable
        loading={loading}
        columns={columns}
        rows={objectChanges}
        rowHeight={80}
        customStrings={error ? { emptyState: error, entityName: 'forbidden' } : { emptyState: '', entityName: '' }}
        keyExtractor={({ objectId }) => objectId}
      />
    </>
  )
}

export default ObjectChangesTable
