import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

type updateParams = {
  shipperId: number
  providerId: number
}

export function update({ shipperId, ...params }: updateParams): Promise<void> {
  const formattedParams = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .put(`${URL_BASE_TRIPS}/shipper/${shipperId}`, formattedParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
