import React, { useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import styles from './LineChart.module.scss'
import CustomTooltip from './subComponents/CustomTooltip'
import { AnnualMetricTypes } from '@/sections/Dashboard/types/Dashboard.types'
import { ChartDataFormatted } from '../utils/formatAnnualData'

type LineChartProps = {
  annualChart: ChartDataFormatted
  isFetching: boolean
}

const STROKE_WIDTH = 1.5

enum LINE_OPACITY {
  ACTIVE = 1,
  DISABLED = 0.2
}

enum LEGEND_OPACITY {
  ACTIVE = 1,
  DISABLED = 0.5
}

const LineChartComponent = ({ annualChart, isFetching }: LineChartProps) => {
  const [activeLine, setActiveLine] = useState<string | null>(null)

  const handleLegendClick = (dataKey: keyof AnnualMetricTypes) => {
    setActiveLine((prev) => (prev === dataKey ? null : dataKey))
  }

  const tooltipFormatter = (value: number, name: string) => {
    const matchingData = annualChart.info.find((item) => item.dataKey === name)
    return [value, matchingData?.label || name, matchingData?.color]
  }

  return annualChart ? (
    <div className={styles.lineChartContainer}>
      <article className={styles.legendContainer}>
        <h3>{annualChart.title}</h3>
        {annualChart.info.map(({ color, label, dataKey }) => (
          <div
            key={label}
            className={styles.legendTextContainer}
            onClick={() => handleLegendClick(dataKey)}
            style={{
              opacity: activeLine && activeLine !== dataKey ? LEGEND_OPACITY.DISABLED : LEGEND_OPACITY.ACTIVE
            }}>
            <span
              className={styles.legendCircle}
              style={{
                backgroundColor: activeLine && activeLine !== dataKey ? `${color}80` : color,
                border: activeLine && activeLine !== dataKey ? `2px solid ${color}` : 'none'
              }}
            />
            <p
              className={styles.legendText}
              style={{
                color
              }}>
              {label}
            </p>
          </div>
        ))}
      </article>
      <article className={styles.chartContainer}>
        <ResponsiveContainer minWidth={460} minHeight={170} className={styles.responsiveContainer} debounce={300}>
          <LineChart data={annualChart.dataChart}>
            {annualChart.info.map((line) => (
              <Line
                key={line.label}
                type="monotoneX"
                dataKey={line.dataKey}
                stroke={line.color}
                opacity={activeLine && activeLine !== line.dataKey ? LINE_OPACITY.DISABLED : LINE_OPACITY.ACTIVE}
                dot={false}
                strokeWidth={STROKE_WIDTH}
                name={line.label}
              />
            ))}
            <CartesianGrid stroke="#cccccc50" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} formatter={tooltipFormatter} />
          </LineChart>
        </ResponsiveContainer>
      </article>
    </div>
  ) : (
    <>Loading</>
  )
}

export default LineChartComponent
