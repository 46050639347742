import React, { ReactElement } from 'react'
import { UserState } from '../reducers/users'
import { ObjectsState } from '../reducers/objects'
import { useGetObjectsToRemove, useObjectsToRemoveTotals } from '../hooks'
import { Button, Icon } from 'antd'
import moment from '../../../moment'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'
import { formatCBU } from '../../../common/operations/selectors/payment-cbu'
import { formatCard } from '../../../common/operations/selectors/payment-mp'
import { Steps } from '../constants'
import { formatAddress } from '../../../common/operations/selectors/transport'
import { PaymentMpState } from '../reducers/payment-mp'
import { Card } from '../types/payment-mp'
import { Address } from '../types/transport'
import { TransportState } from '../reducers/transport'
import { formatSpreedlyPM } from '../utils/payment'
import { CalendarState } from '../types/calendar'

const styles = {
  modalStyles: {
    maxWidth: 800
  },
  title: {
    color: '#242424',
    fontWeight: 700,
    fontSize: 20,
    marginBottom: 36
  },
  container: {
    maxWidth: 600,
    margin: '0 auto'
  },
  row: {
    margin: 0
  },
  inputTitle: {
    margin: 0,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  },
  spinContainer: {
    display: 'flex'
  },
  spin: {
    margin: 'auto'
  },
  error: {
    display: 'inline-block',
    color: 'red',
    marginLeft: 10
  },
  totalRow: {
    textAlign: 'right' as const
  }
}

interface ResumeRemovalProps {
  editMode?: boolean
  setStep: (step: number) => void
  cbuState: any
  commonActions: any
  userDepositState: UserState
  transportState: TransportState
  calendarState: CalendarState
  objectsState: ObjectsState
  commonState: any
  paymentMpState: PaymentMpState
}

export const FinishSummary = (props: ResumeRemovalProps) => {
  const {
    cbuState,
    commonState,
    userDepositState,
    transportState,
    calendarState,
    objectsState,
    paymentMpState,
    editMode,
    setStep
  } = props
  const { user } = userDepositState
  const { objectsToRemove, objectsMinCost } = objectsState
  const { addresses } = transportState
  const { unavailableDates, timeIntervals } = calendarState

  const objectsToRemoveValues = useGetObjectsToRemove(objectsToRemove)

  const { quantity } = useObjectsToRemoveTotals(objectsToRemoveValues, objectsMinCost, transportState.cost)

  const labels = {
    CLIENT: 'Datos del cliente',
    REMOVAL: 'Datos de devolución',
    PAYMENT_METHOD: 'Método de pago',
    OBJECT_TO_REMOVE: 'Items a devolver'
  }

  const icons = {
    CLIENT: <Icon type="user" />,
    REMOVAL_WHEN: <Icon type="schedule" />,
    REMOVAL_WHERE: <Icon type="environment" />,
    PAYMENT_METHOD: <Icon type="credit-card" />,
    OBJECT_TO_REMOVE: <Icon type="dropbox" />
  }

  const timeInterval = timeIntervals.selected

  const removalWhenFirstLine = capitalizeFirstLetter(moment(unavailableDates.selected).format('dddd DD/MM/YY'))
  const removalWhenSecondLine = `De ${timeInterval?.start}:00 a ${timeInterval?.end}:00`

  const getPaymentMethodLabelValue = (): string => {
    switch (commonState.paymentBy) {
      case PAYMENT_BY_VALUES.CBU: {
        const cbu = cbuState.cbus.find((cbu: { id: any }) => cbu.id === cbuState.cbu_id)
        return formatCBU(cbu)
      }
      case PAYMENT_BY_VALUES.CREDIT_CARD_MP: {
        const card = paymentMpState.cards.find((card: Card) => card.ID === paymentMpState.card_id) || {
          LastDigits: '',
          Type: ''
        }
        return formatCard(card)
      }
      case PAYMENT_BY_VALUES.SPREEDLY: {
        const card = paymentMpState.spreedlyPM.find((spreedlyPM) => spreedlyPM.id === paymentMpState.spreedlyPMID)
        return card ? formatSpreedlyPM(card) : ''
      }
      case PAYMENT_BY_VALUES.OFFLINE:
      default:
        return 'Pago offline'
    }
  }

  const address = addresses.find((address: Address) => address.ID === transportState.address_id)

  const getFloorAndApartment = (): string => {
    let floorAndApartment = ''
    if (address.Floor) floorAndApartment = floorAndApartment.concat('', `Piso ${address.Floor}`)
    if (address.Apartment) floorAndApartment = floorAndApartment.concat(' ', `dpto ${address.Apartment}`)
    return floorAndApartment
  }

  const objectDeposit = Object.values(objectsState.objectsToRemove)[0].objectDetails.Location.Deposit

  const removalWhereFirstLine = address ? formatAddress(address) : objectDeposit.Address
  const removalWhereSecondLine = address ? getFloorAndApartment() : objectDeposit.Name

  const labelsValues = {
    CLIENT: `${user?.Name} ${user?.LastName} (${user?.RealID})`,
    REMOVAL_WHEN: <LabelValueTwoLines firstLine={removalWhenFirstLine} secondLine={removalWhenSecondLine} />,
    REMOVAL_WHERE: <LabelValueTwoLines firstLine={removalWhereFirstLine} secondLine={removalWhereSecondLine} />,
    PAYMENT_METHOD: getPaymentMethodLabelValue(),
    OBJECT_TO_REMOVE: `Cantidad de ítems: ${quantity}`
  }

  const buttonActions = {
    REMOVAL: () => setStep(Steps.ADDRESS),
    PAYMENT_METHOD: () => setStep(Steps.PAYMENT),
    OBJECT_TO_REMOVE: () => setStep(Steps.SELECTION)
  }

  return (
    <div style={styles.container}>
      <p style={styles.title}>Verifica que los datos sean correctos</p>
      <Label label={labels.CLIENT} />
      <LabelValue icon={icons.CLIENT} value={labelsValues.CLIENT} />
      <Label label={labels.REMOVAL} />
      <LabelValue icon={icons.REMOVAL_WHEN} value={labelsValues.REMOVAL_WHEN} buttonAction={buttonActions.REMOVAL} />
      <LabelValue icon={icons.REMOVAL_WHERE} value={labelsValues.REMOVAL_WHERE} buttonAction={buttonActions.REMOVAL} />
      {/*
      {!editMode && (
        <>
          <Label label={labels.PAYMENT_METHOD} />
          <LabelValue
            icon={icons.PAYMENT_METHOD}
            value={labelsValues.PAYMENT_METHOD}
            buttonAction={buttonActions.PAYMENT_METHOD}
          />
        </>
      )}
      */}
      <Label label={labels.OBJECT_TO_REMOVE} />
      <LabelValue
        value={labelsValues.OBJECT_TO_REMOVE}
        buttonAction={buttonActions.OBJECT_TO_REMOVE}
        icon={icons.OBJECT_TO_REMOVE}
      />
      <span style={styles.error}>{paymentMpState?.error}</span>
    </div>
  )
}

const labelStyles = {
  label: {
    marginTop: 24,
    color: '#242424',
    fontSize: 16,
    fontWeight: 700
  }
}

type LabelProps = {
  label: string
}

const Label = (props: LabelProps) => {
  const { label } = props
  return <p style={labelStyles.label}>{label}</p>
}

type LabelValueProps = {
  icon: ReactElement
  value: string | ReactElement
  buttonAction?: () => void
  buttonLabel?: string
}

const labelValueStyles = {
  container: {
    display: 'flex',
    color: '#595959',
    fontSize: 16
  },
  icon: {
    marginRight: 16
  },
  value: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const
  }
}

const LabelValue = (props: LabelValueProps) => {
  const { icon, value, buttonAction, buttonLabel = 'Modificar' } = props
  return (
    <div style={labelValueStyles.container}>
      <div style={labelValueStyles.icon}>{icon}</div>
      <div style={labelValueStyles.value}>{value}</div>
      {buttonAction && (
        <Button onClick={buttonAction} type="link">
          {buttonLabel}
        </Button>
      )}
    </div>
  )
}

type LabelValueTwoLinesProps = {
  firstLine: string
  secondLine?: string
}

const LabelValueTwoLines = ({ firstLine, secondLine }: LabelValueTwoLinesProps): ReactElement => (
  <>
    <span style={{ textOverflow: 'ellipsis' }}>{firstLine}</span>
    {secondLine && <p>{secondLine}</p>}
  </>
)

export default FinishSummary
