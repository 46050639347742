export enum UploadFileActionTypes {
  UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST',
  UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE'
}

export interface UploadFileRequest {
  type: UploadFileActionTypes.UPLOAD_FILES_REQUEST
}

export interface UploadFileSuccess {
  type: UploadFileActionTypes.UPLOAD_FILES_SUCCESS
}

export interface UploadFileFailure {
  type: UploadFileActionTypes.UPLOAD_FILES_FAILURE
  payload: {
    error: string
  }
}

export type UploadFilesAction = UploadFileRequest | UploadFileSuccess | UploadFileFailure
