import React, { useEffect, useState } from 'react'

import { NewEditAttribute } from '../components/NewEditAttribute'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import NewEditAttributeActionCreator from '../actionCreators/NewEditAttribute'
import { useParams } from 'react-router'

const NewEditAttributeContainer = () => {
  const dispatch = useDispatch()
  const newEditAttributeState = useSelector((state) => state.Attributes.NewEditAttribute)
  const newEditAttributeActions = bindActionCreators(NewEditAttributeActionCreator, dispatch)
  const [editMode, setEditMode] = useState<boolean>(false)

  const useParsedParams = (): { attributeId: number } => {
    const data = useParams<{ attributeId: string }>()

    return { attributeId: parseInt(data.attributeId) }
  }

  const { attributeId } = useParsedParams()

  useEffect(() => {
    newEditAttributeActions.clearState()
    if (attributeId) {
      setEditMode(true)
      newEditAttributeActions.getAttribute(attributeId)
    }
  }, [attributeId])

  return (
    <NewEditAttribute
      newEditAttributeState={newEditAttributeState}
      newEditAttributeActions={newEditAttributeActions}
      editMode={editMode}
    />
  )
}

export default NewEditAttributeContainer
