import { Reducer } from 'redux'
import {
  AMBCorporateProductsState,
  AMBCorporativeProductsActions,
  AMProductsTypes,
  CreateProductTypes,
  DeleteProductTypes,
  GetCategoriesProductsTypes,
  UpdateProductTypes
} from '../types/ABMProducts'

const initialState: AMBCorporateProductsState = {
  editMode: false,
  deleteModalVisible: false,
  creatingProduct: false,
  updatingProduct: false,
  deletingProduct: false,
  fetchingCategories: false,
  disabledDeleteButton: false,
  categories: [],
  productFormFields: {
    name: '',
    dimmensionsWidthInCm: '',
    dimmensionsHeightInCm: '',
    dimmensionsLengthInCm: '',
    dimmensionsWeightInKg: '',
    deviationMinPercentage: '',
    deviationMaxPercentage: '',
    multimediaMinPhotos: '',
    multimediaPhotoUrl: '',
    multimediaPhotoKey: ''
  }
}

const ABMCorporateProductsReducer: Reducer<AMBCorporateProductsState, AMBCorporativeProductsActions> = (
  state = initialState,
  action
): AMBCorporateProductsState => {
  switch (action.type) {
    case AMProductsTypes.CLEAN_STATE:
      return initialState
    case AMProductsTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisible } = action.payload
      return deleteModalVisible
        ? {
            ...state,
            deleteModalVisible,
            selectedProduct: action.payload.currentProduct
          }
        : initialState
    }
    case AMProductsTypes.SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload.editMode,
        selectedProduct: action.payload.selectedProduct,
        selectedCategory: state.categories.find((c) => c.id === action.payload.selectedProduct?.category.id),
        selectedCategoryOption: {
          value: action.payload.selectedProduct?.category.id ?? 0,
          label: action.payload.selectedProduct?.category.description ?? ''
        },
        productFormFields: {
          ...state.productFormFields,
          name: action.payload.selectedProduct?.description || '',
          dimmensionsWidthInCm: action.payload.selectedProduct?.widthInCm.toString() || '',
          dimmensionsHeightInCm: action.payload.selectedProduct?.heightInCm.toString() || '',
          dimmensionsLengthInCm: action.payload.selectedProduct?.lengthInCm.toString() || '',
          dimmensionsWeightInKg: action.payload.selectedProduct?.weightInGr
            ? (action.payload.selectedProduct.weightInGr / 1000).toString()
            : '',
          deviationMinPercentage: action.payload.selectedProduct?.minVolumeDeviation.toString() || '',
          deviationMaxPercentage: action.payload.selectedProduct?.maxVolumeDeviation.toString() || '',
          multimediaMinPhotos: action.payload.selectedProduct?.photoMin.toString() || '',
          multimediaPhotoUrl: action.payload.selectedProduct?.photo.url || '',
          multimediaPhotoKey: action.payload.selectedProduct?.photo.key || ''
        }
      }
    case AMProductsTypes.SET_FORM_FIELD:
      return {
        ...state,
        productFormFields: {
          ...state.productFormFields,
          [action.payload.field]: action.payload.value
        }
      }
    case AMProductsTypes.SET_CATEGORY:
      return {
        ...state,
        selectedCategoryOption: action.payload.selectedCategory,
        selectedCategory: state.categories.find((c) => c.id === action.payload.selectedCategory?.value)
      }
    case GetCategoriesProductsTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        fetchingCategories: true
      }
    case GetCategoriesProductsTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchingCategories: false,
        categories: action.payload.categories
      }
    case GetCategoriesProductsTypes.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        fetchingCategories: false
      }
    case CreateProductTypes.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        creatingProduct: true
      }
    case CreateProductTypes.CREATE_PRODUCT_SUCCESS:
    case CreateProductTypes.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        creatingProduct: false
      }
    case UpdateProductTypes.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        updatingProduct: true
      }
    case UpdateProductTypes.UPDATE_PRODUCT_SUCCESS:
    case UpdateProductTypes.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        updatingProduct: false
      }
    case DeleteProductTypes.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        deletingProduct: true
      }
    case DeleteProductTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deletingProduct: false
      }
    case DeleteProductTypes.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        deletingProduct: false,
        disabledDeleteButton: true
      }
    default:
      return state
  }
}

export default ABMCorporateProductsReducer
