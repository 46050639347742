import React from 'react'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import {
  ProductsCategoryFilter,
  ProductsSearchFilter,
  ProductsTableSearchKey,
  ProductsTableSort
} from '../types/productTable'
import { getFilterProps, getSearchProps, getAutocompleteProps } from '../../../components/DynamicTable/utils'
import { formatMoneyWithCountry, formatNumber } from '../../../utils/formatNumber'
import DeleteModal from '../containers/deleteModal'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { useHistory } from 'react-router'
import { Category } from '../../../projectApi/CategoryCreation/Search/Categories/list'
import { BaseOption } from '../../../components/Select/Select'
import AttributeModal from '../containers/attributeModal'
import { TableActionBar } from '../../../components/TableActionBar'
import { useLocalization } from '@/packages/localization'

const SHOW_HIDDEN_FILTERS = [
  {
    text: 'No incluir ocultos',
    value: 'false'
  },
  {
    text: 'Incluir ocultos',
    value: 'true'
  }
]

const IS_DEPRECATED = [
  {
    text: 'Sí',
    value: 'true'
  },
  {
    text: 'No',
    value: 'false'
  }
]

export type ServiceTypeOption = BaseOption<string>
export type CategoryOption = BaseOption<number>

export type ProductsTableProps = {
  products: FormattedProduct[]
  loadingProducts: boolean
  sendingCSV: boolean
  pagination: Pagination
  searchFilters: ProductsSearchFilter[]
  countryCode: string
  actions: MenuAction<FormattedProduct>[]
  handleResetFilters: () => void
  handleSearch: (key: ProductsTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newCategoryFilter: ProductsCategoryFilter) => void
  serviceTypes: ServiceTypeOption[]
  categories: CategoryOption[]
  sort: ProductsTableSort
  categoryFilter: ProductsCategoryFilter
  searchCategories: {
    fetching: boolean
    list: Category[]
    error: string
  }
  searchCategoryId: (categoryName: string) => void
  handleByCategoryId: (categoryId: number) => void
  handleSendCSV: () => void
  categoryId: number
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
  loadingProducts,
  sendingCSV,
  products,
  pagination,
  searchFilters,
  countryCode,
  actions,
  handleSearch,
  handleResetFilters,
  handleCategoryFilter,
  searchCategories,
  categoryFilter,
  searchCategoryId,
  handleByCategoryId,
  handleSendCSV,
  categoryId
}) => {
  const route = useHistory()
  const countryCurrency = useCountryInfo(countryCode).currency
  const notSelectCountry = !countryCode
  const { strings } = useLocalization()

  const tooltipReportMessage = notSelectCountry
    ? 'Selecciona un país para poder exportar la información de los productos'
    : 'El archivo resultante usa los filtros y paginación aplicados a la tabla'

  const formatCategoriesOptions = (options: Category[]): BaseOption<number>[] =>
    options.map((option) => ({ label: option.description, value: option.id }))

  const columns: Column<FormattedProduct>[] = [
    {
      label: 'ID',
      key: 'id',
      search: getSearchProps('id', 'ID Producto', handleSearch, searchFilters)
    },
    {
      label: 'Categoría Final',
      key: 'categories',
      renderDataCell: ({ category }) => {
        return `${category.description}`
      },
      autocomplete: getAutocompleteProps(
        'categoryId',
        'Categoria',
        handleByCategoryId,
        categoryId,
        searchCategoryId,
        formatCategoriesOptions(searchCategories.list),
        searchCategories.fetching
      )
    },
    {
      label: 'Producto',
      key: 'description',
      search: getSearchProps('name', 'Nombre producto', handleSearch, searchFilters)
    },
    {
      label: 'Oculto en búsquedas',
      key: 'showInSearch',
      minWidth: '135px',
      filters: getFilterProps(
        SHOW_HIDDEN_FILTERS,
        categoryFilter,
        'showInSearch',
        // @ts-ignore
        handleCategoryFilter
      ),
      renderDataCell: ({ showInSearch }) => (showInSearch ? 'NO' : 'SÍ')
    },
    {
      label: 'Peso[kg]',
      key: 'weightInGr',
      renderDataCell: ({ weightInGr }) => {
        return formatNumber(weightInGr / 1000)
      }
    },
    {
      label: 'Medidas[cms]',
      key: 'product',
      renderDataCell: ({ lengthInCm, widthInCm, heightInCm }) => {
        const size = `${lengthInCm} x ${widthInCm} x ${heightInCm}`
        const sizeM3 = formatNumber((lengthInCm * widthInCm * heightInCm) / 1000000)
        return size + ' = ' + sizeM3 + ' m³'
      }
    },
    {
      label: 'Embalaje[$]',
      key: 'packagingCostInCents',
      renderDataCell: ({ packagingCostInCents }) => {
        const packagingCost = packagingCostInCents / 100
        return formatMoneyWithCountry(countryCurrency, packagingCost)
      }
    },
    {
      label: 'Embalaje[T]',
      key: 'packagingTimeInMinutes',
      renderDataCell: ({ packagingTimeInMinutes }) => {
        return packagingTimeInMinutes + ' min.'
      }
    },
    {
      label: 'Desarmado[$]',
      key: 'disassemblyCost',
      renderDataCell: ({ disassemblyCost }) => {
        return formatMoneyWithCountry(countryCurrency, disassemblyCost)
      }
    },
    {
      label: 'Desarmado[T]',
      key: 'unPackagingTimeInMinutes',
      renderDataCell: ({ unPackagingTimeInMinutes }) => {
        return unPackagingTimeInMinutes + ' min.'
      }
    },
    {
      label: 'Valor máx. declarado',
      alignment: 'center',
      minWidth: '125px',
      key: 'maxAmountAvowedInCents',
      renderDataCell: ({ maxAmountAvowedInCents }) => {
        const maxAmountAvowed = maxAmountAvowedInCents / 100
        return formatMoneyWithCountry(countryCurrency, maxAmountAvowed ?? 0)
      }
    },
    {
      label: '% Abono de seguro',
      minWidth: '100px',
      key: 'percentageAvowed',
      renderDataCell: ({ percentageAvowed }) => {
        return percentageAvowed + ' %'
      }
    },
    {
      label: 'Valor máx. reembolso',
      minWidth: '100px',
      key: 'maxRefundAmountInCents',
      renderDataCell: ({ maxRefundAmountInCents }) => {
        const maxRefundAmount = maxRefundAmountInCents / 100
        return formatMoneyWithCountry(countryCurrency, maxRefundAmount ?? 0)
      }
    },
    {
      label: 'Deprecado',
      key: 'deprecated',
      filters: getFilterProps(
        IS_DEPRECATED,
        categoryFilter,
        'deprecated',
        // @ts-ignore
        handleCategoryFilter
      ),
      renderDataCell: ({ deprecated }) => {
        return deprecated ? 'SÍ' : 'NO'
      }
    }
  ]

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            toolTip
            variant="csv"
            disabled={sendingCSV || notSelectCountry}
            onClickButton={handleSendCSV}
          />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
          <TableActionBar.ButtonAction
            titleButton={'Producto por catálogo'}
            onClickButton={() => route.push('/product/catalogue')}
            toolTip
            toolTipCustomMessage={strings.ProductsTable.button.tooltip}
          />
        </TableActionBar.Wrapper>
        <TableActionBar.ButtonAction titleButton={'Agregar producto'} onClickButton={() => route.push('/newProduct')} />
      </TableActionBar>
      <DynamicTable
        columns={columns}
        loading={loadingProducts}
        rows={notSelectCountry ? [] : products}
        keyExtractor={(entity) => entity.id}
        pagination={pagination}
        actions={actions}
        customStrings={{
          emptyState: notSelectCountry
            ? 'Selecciona un país para ver la información de los productos'
            : 'No se encontraron productos'
        }}
      />
      <DeleteModal />
      <AttributeModal />
    </>
  )
}
