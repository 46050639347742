import eventEmitter from '../../../../utils/eventEmitter'
import { API } from '../../../../projectApi'
import { CreateLocationParams as CreateCorporativeLocationParams } from '../../../../projectApi/TransactionHandler/Locations/Corporative/create'
import { EditLocationParams as EditCorporativeLocationParams } from '../../../../projectApi/TransactionHandler/Locations/Corporative/edit'
import { AppThunk } from '../../../../store'
import { getErrorMessage, handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { Events } from '../../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import {
  CreateLocationTypes,
  CreateLocationsRequest,
  CreateLocationsSuccess,
  EditLocationTypes,
  EditLocationsRequest,
  EditLocationsSuccess,
  GetDepositLocationTypes,
  GetDepositsLocationRequest
} from '../../../Locations/types/AddEditLocationModal'

const LIMIT = 10
let searchTimer: NodeJS.Timeout
let getItemPricesOldLastSearchId: number

const AddEditLocationModalActionCreators = {
  createCorporativeLocation(params: CreateCorporativeLocationParams): AppThunk {
    return async (dispatch) => {
      const request: CreateLocationsRequest = {
        type: CreateLocationTypes.CREATE_LOCATION_REQUEST
      }

      const toast = setToastLoading('Creando ubicación. Por favor espere...')
      dispatch(request)
      try {
        await API.TransactionHandler.Location.Corporative.create(params)
        const success: CreateLocationsSuccess = {
          type: CreateLocationTypes.CREATE_LOCATION_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Ubicación creada.' })
        eventEmitter.emit(Events.Location.LOCATION_CREATED)
        dispatch(success)
      } catch (error: any) {
        const message = getErrorMessage(error)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, CreateLocationTypes.CREATE_LOCATION_FAILURE, dispatch)
      }
    }
  },
  editCorporativeLocation(params: EditCorporativeLocationParams): AppThunk {
    return async (dispatch) => {
      const request: EditLocationsRequest = {
        type: EditLocationTypes.EDIT_LOCATION_REQUEST
      }

      const toast = setToastLoading('Actualizando ubicación. Por favor espere...')
      dispatch(request)
      try {
        await API.TransactionHandler.Location.Corporative.edit(params)
        const success: EditLocationsSuccess = {
          type: EditLocationTypes.EDIT_LOCATION_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: 'Ubicación actualizada.' })
        eventEmitter.emit(Events.Location.LOCATION_UPDATED)
        dispatch(success)
      } catch (error: any) {
        const message = getErrorMessage(error)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, EditLocationTypes.EDIT_LOCATION_FAILURE, dispatch)
      }
    }
  },
  getCorporativeDeposits:
    ({ name, id }: { name?: string; id?: number }): AppThunk =>
    (dispatch) => {
      const request: GetDepositsLocationRequest = {
        type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_REQUEST
      }

      dispatch(request)
      clearTimeout(searchTimer)
      searchTimer = setTimeout(async () => {
        const params = {
          limit: LIMIT,
          offset: 0,
          name,
          id,
          allDeposits: false
        }

        const lastSearchID = new Date().getMilliseconds()
        getItemPricesOldLastSearchId = lastSearchID

        dispatch({ type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_REQUEST })

        try {
          if (lastSearchID === getItemPricesOldLastSearchId) {
            const { deposits } = await API.TransactionHandler.Deposits.list(params)
            dispatch({
              type: GetDepositLocationTypes.GET_DEPOSITS_LOCATION_SUCCESS,
              payload: {
                deposits
              }
            })
          }
        } catch (e: any) {
          const message = getErrorMessage(e)
          handleErrorsWithAction(e, GetDepositLocationTypes.GET_DEPOSITS_LOCATION_FAILURE, dispatch)
        }
      }, 250)
    }
}

export default AddEditLocationModalActionCreators
