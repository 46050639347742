export enum ObjectTypes {
  SELECT_OBJECT = 'EDIT_OBJECT/SELECT_OBJECT',
  CANCEL = 'EDIT_OBJECT/CANCEL',
  SET_DESCRIPTION = 'EDIT_OBJECT/SET_DESCRIPTION',
  SET_OBJECT_SITUATION_REASON = 'EDIT_OBJECT/SET_OBJECT_SITUATION_REASON',
  SET_LOCATION = 'EDIT_OBJECT/SET_LOCATION',
  SET_NAME = 'EDIT_OBJECT/SET_NAME',
  SET_NEEDS_AUTHORIZATION = 'EDIT_OBJECT/SET_NEEDS_AUTHORIZATION',
  SET_RENT_ENABLED = 'EDIT_OBJECT/SET_RENT_ENABLED',
  SET_RENT_PRICE = 'EDIT_OBJECT/SET_RENT_PRICE',
  SET_PRODUCT = 'EDIT_OBJECT/SET_PRODUCT',
  SET_HEIGHT = 'EDIT_OBJECT/SET_HEIGHT',
  SET_WIDTH = 'EDIT_OBJECT/SET_WIDTH',
  SET_LENGTH = 'EDIT_OBJECT/SET_LENGTH',
  GET_OBJECT_SITUATION_REQUEST = 'EDIT_OBJECT/GET_OBJECT_SITUATION_REQUEST',
  GET_OBJECT_SITUATION_SUCCESS = 'EDIT_OBJECT/GET_OBJECT_SITUATION_SUCCESS',
  GET_OBJECT_SITUATION_FAILURE = 'EDIT_OBJECT/GET_OBJECT_SITUATION_FAILURE',
  SET_OBJECT_SITUATION = 'EDIT_OBJECT/SET_OBJECT_SITUATION',
  SET_OBJECT_STATUS_ID = 'EDIT_OBJECT/SET_OBJECT_STATUS_ID',
  MODIFY_OBJECT_REQUEST = 'EDIT_OBJECT/MODIFY_OBJECT_REQUEST',
  MODIFY_OBJECT_SUCCESS = 'EDIT_OBJECT/MODIFY_OBJECT_SUCCESS',
  MODIFY_OBJECT_FAILURE = 'EDIT_OBJECT/MODIFY_OBJECT_FAILURE',
  GET_LOCATIONS_REQUEST = 'EDIT_OBJECT/GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS = 'EDIT_OBJECT/GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE = 'EDIT_OBJECT/GET_LOCATIONS_FAILURE',
  SELECT_OBJECT_BY_ID_REQUEST = 'EDIT_OBJECT/SELECT_OBJECT_BY_ID_REQUEST',
  SELECT_OBJECT_BY_ID_SUCCESS = 'EDIT_OBJECT/SELECT_OBJECT_BY_ID_SUCCESS',
  SELECT_OBJECT_BY_ID_FAILURE = 'EDIT_OBJECT/SELECT_OBJECT_BY_ID_FAILURE',
  GET_PRODUCTS_REQUEST = 'EDIT_OBJECT/GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS = 'EDIT_OBJECT/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE = 'EDIT_OBJECT/GET_PRODUCTS_FAILURE',
  GET_LOCATION_BY_CODE_REQUEST = 'EDIT_OBJECT/GET_LOCATION_BY_CODE_REQUEST',
  GET_LOCATION_BY_CODE_SUCCESS = 'EDIT_OBJECT/GET_LOCATION_BY_CODE_SUCCESS',
  GET_LOCATION_BY_CODE_FAILURE = 'EDIT_OBJECT/GET_LOCATION_BY_CODE_FAILURE',
  GET_PRODUCT_ATTRIBUTES_REQUEST = 'EDIT_OBJECT/GET_PRODUCT_ATTRIBUTES_REQUEST',
  GET_PRODUCT_ATTRIBUTES_SUCCESS = 'EDIT_OBJECT/GET_PRODUCT_ATTRIBUTES_SUCCESS',
  GET_PRODUCT_ATTRIBUTES_FAILURE = 'EDIT_OBJECT/GET_PRODUCT_ATTRIBUTES_FAILURE',
  SET_ATTRIBUTE_TYPE = 'EDIT_OBJECT/SET_ATTRIBUTE_TYPE',
  SET_ATTRIBUTE_VALUE = 'EDIT_OBJECT/SET_ATTRIBUTE_VALUE',
  DELETE_ATTRIBUTE = 'EDIT_OBJECT/DELETE_ATTRIBUTE'
}
