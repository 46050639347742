import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

interface Params {
  tripId: number
}

export function start(params: Params): Promise<void> {
  return apiPrivate
    .put(`${URL_BASE_TRIPS}/trips/${params.tripId}/initialize`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
