import { corporateReport } from './corporateReport'
import { bulkUpdate } from './bulkUpdate'
import { list } from './list'
import { requestReport } from './report'
import { getM3Storage } from './getM3Storage'
import { metrics } from './metrics'
import { update } from './update'
import { calculateMinCost } from './calculateMinCost'
import { generic } from './generic'

export const ObjectAPI = {
  corporateReport,
  calculateMinCost,
  update,
  list,
  getM3Storage,
  requestReport,
  bulkUpdate,
  metrics,
  generic
}
