import moment, { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import camelcaseKeys from 'camelcase-keys'

interface Paging {
  total: number
  limit: number
  offset: number
}

interface UserDiscountReason {
  id: number
  description: string
}

interface APIResult {
  id: number
  created_at: string
  updated_at: string
  start_date_from: string
  end_date_from: string
  user_id: number
  fixed_amount_discount_in_cents: number
  discount_percent: number
  user_discount_reason: UserDiscountReason
}

interface APIResponse {
  paging: Paging
  results: APIResult[]
}

type Dates = 'createdAt' | 'updatedAt' | 'startDateFrom' | 'endDateFrom'

type MomentDates = Record<Dates, Moment>

export type UserDiscount = Omit<CamelCasedPropertiesDeep<APIResult>, Dates> & MomentDates

type Response = {
  userDiscounts: UserDiscount[]
}

interface Params {
  userId: number
  startDate: Moment
  endDate: Moment
}

export function discountList({ userId, startDate, endDate }: Params): Promise<Response> {
  const params = {
    user_id: userId,
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/user-discounts`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const userDiscounts = camelcaseKeys(response.data.results, { deep: true }).map((userDiscount) => ({
        ...userDiscount,
        createdAt: moment(userDiscount.createdAt),
        updatedAt: moment(userDiscount.updatedAt),
        startDateFrom: moment(userDiscount.startDateFrom),
        endDateFrom: moment(userDiscount.endDateFrom)
      }))

      return { userDiscounts }
    })
    .catch((error) => {
      throw error
    })
}
