import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Shipper } from '../../../projectApi/Trips/Shippers/list'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { ShippersSearchFilter, ShippersTableSearchKey } from '../types/shippersTable'
import { getSearchProps } from '../../../components/DynamicTable/utils'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'

export type ShippersTableParams = {
  shippers: Shipper[]
  fetchingShippers: boolean
  pagination: Pagination
  searchFilters: ShippersSearchFilter[]
  actions: MenuAction<Shipper>[]
  handleSearch: (key: ShippersTableSearchKey, newValue: string) => void
}

const ShippersTable = ({
  shippers,
  fetchingShippers,
  searchFilters,
  pagination,
  actions,
  handleSearch
}: ShippersTableParams) => {
  const columns: Column<Shipper>[] = [
    {
      key: 'id',
      label: 'ID',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'userId',
      label: 'ID usuario',
      renderDataCell: ({ userId }) => {
        return (
          <ClientIDLinkComponent id={userId} noPadding={true}>
            {userId}
          </ClientIDLinkComponent>
        )
      },
      search: getSearchProps('user_id', 'ID usuario', handleSearch, searchFilters)
    },
    {
      key: 'name',
      label: 'Nombre'
    },
    {
      key: 'lastName',
      label: 'Apellido'
    },
    {
      key: 'provider.name',
      label: 'Proveedor',
      renderDataCell: ({ provider: { name, id } }) => (
        <a style={{ textDecoration: 'none' }} href={`/providers?id=${id}`}>
          {name}
        </a>
      )
    }
  ]

  return (
    <DynamicTable
      keyExtractor={(entity) => entity.id}
      rows={shippers}
      columns={columns}
      loading={fetchingShippers}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default ShippersTable
