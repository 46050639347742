/* eslint-disable camelcase */
import axios, { AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

export enum OperationType {
  DEPOSIT = 'deposit_operations',
  REMOVAL = 'removal_operations'
}

interface APIError {
  message: string
  error: string
  status: number
  cause: string | null
}

type APIModifyReason = {
  id: number
  name: string
  description: string
  operation_type: string
  available: boolean
}

export type ModifyReason = CamelCasedPropertiesDeep<APIModifyReason>

type APIResponse = {
  reasons: APIModifyReason[]
}

type Response = {
  modifyReasons: ModifyReason[]
}

export interface ListParams {
  operationType: OperationType
}

export function list(listParams: ListParams): Promise<Response> {
  const params = snakecaseKeys({ ...listParams })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operations/modify-reasons`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.reasons || response.data.reasons?.length <= 0)
        throw new Error('Error al obtener los motivos de modificación')

      const camelResponse: Response = {
        modifyReasons: camelcaseKeys(response.data.reasons)
      }

      return camelResponse
    })
    .catch((error: AxiosError<APIError> | Error) => {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data.message
        throw errorMessage
      } else throw error
    })
}
