import types from '../types/DeleteDiscount'

const initialRoot = {
  idDiscount: null,
  code: '',
  deleteDiscountModalOpen: false,
  loading: false,
  error: ''
}

function deleteDiscountReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.DELETE_DISCOUNT_REQUEST: {
      return {
        ...state,
        deleteDiscountModalOpen: true,
        idDiscount: action.payload.id,
        loading: true,
        error: ''
      }
    }
    case types.DELETE_DISCOUNT_SUCCESS: {
      return initialRoot
    }
    case types.DELETE_DISCOUNT_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      }
    }
    case types.OPEN_DELETE_MODAL: {
      return {
        ...state,
        idDiscount: action.payload.id,
        code: action.payload.code,
        deleteDiscountModalOpen: true,
        error: ''
      }
    }
    case types.CLOSE_DELETE_MODAL: {
      return initialRoot
    }
    default:
      return state
  }
}

export default deleteDiscountReducer
