import { Reducer } from 'redux'
import {
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_REQUEST,
  CREATE_LEAD_SUCCESS,
  EDIT_LEAD_REQUEST,
  EDIT_LEAD_FAILURE,
  EDIT_LEAD_SUCCESS,
  ModalLeadsActions,
  ModalLeadsState,
  SET_CODE_AREA,
  SET_CONTACT_MEDIUM,
  SET_COUNTRY,
  SET_COUNTRY_CODE,
  SET_CREATE_MODAL_OPEN,
  SET_DISCARD,
  SET_EDIT_MODAL_OPEN,
  SET_EMAIL,
  SET_ERROR_CODE_AREA,
  SET_ERROR_EMAIL,
  SET_ERROR_LAST_NAME,
  SET_ERROR_NAME,
  SET_ERROR_PHONE,
  SET_ERROR_PHONE_NUMBER,
  SET_GENDER,
  SET_INTEREST,
  SET_LAST_NAME,
  SET_LOSE_MQL,
  SET_LOSE_SQL,
  SET_NAME,
  SET_PHONE,
  SET_PHONE_NUMBER,
  SET_SOURCE_CHANNEL,
  SET_STATUS,
  VALIDATE_EMAIL_FAILURE,
  VALIDATE_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS
} from '../types/ModalLeads'
import { STATUS_VALUES } from '../constants'

const initialProspect: ModalLeadsState = {
  open: false,
  editMode: false,
  dataLead: {
    selectedCountry: {
      code: '',
      currency: '',
      flagUrl: '',
      fullName: '',
      label: '',
      phoneCode: ''
    },
    gender: '',
    userId: 0,
    name: {
      value: '',
      errorMessage: '',
      successMessage: ''
    },
    email: {
      value: '',
      errorMessage: '',
      successMessage: '',
      loading: false,
      lastSearchTimestampEmail: 0
    },
    phone: {
      currentNumber: '',
      countryCode: '',
      codeArea: {
        value: '',
        error: false,
        errorMessage: ''
      },
      phoneNumber: {
        value: '',
        error: false,
        errorMessage: ''
      },
      limitNumber: 0,
      value: '',
      errorMessage: '',
      successMessage: ''
    },
    lastName: {
      value: '',
      errorMessage: '',
      successMessage: ''
    },
    sourceChannel: '',
    contactMedium: '',
    interestReason: '',
    discardReason: '',
    freeLoseReason: '',
    loseReason: '',
    loseReasonMQL: '',
    loseReasonSQL: '',
    id: 0,
    comment: '',
    funnelState: ''
  },
  statusId: STATUS_VALUES.NEW,
  loadingLead: false,
  error: ''
}

const prospectReducer: Reducer<ModalLeadsState, ModalLeadsActions> = (
  state = initialProspect,
  action
): ModalLeadsState => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          name: {
            ...state.dataLead.name,
            errorMessage: initialProspect.dataLead.name.errorMessage,
            value: action.payload.name
          }
        }
      }
    case SET_LAST_NAME:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          lastName: {
            ...state.dataLead.lastName,
            errorMessage: initialProspect.dataLead.lastName.errorMessage,
            value: action.payload.lastName
          }
        }
      }
    case SET_EMAIL:
      return {
        ...state,
        dataLead: { ...state.dataLead, email: { ...state.dataLead.email, value: action.payload.email } }
      }
    case SET_PHONE:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          phone: {
            ...state.dataLead.phone,
            errorMessage: initialProspect.dataLead.phone.errorMessage,
            value: action.payload.phone
          }
        }
      }
    case SET_COUNTRY_CODE:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          phone: {
            ...state.dataLead.phone,
            codeArea: initialProspect.dataLead.phone.codeArea,
            phoneNumber: { ...initialProspect.dataLead.phone.phoneNumber, error: true },
            limitNumber: action.payload.limit,
            errorMessage: initialProspect.dataLead.phone.errorMessage,
            countryCode: action.payload.countryCode
          }
        }
      }
    case SET_CODE_AREA:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          phone: {
            ...state.dataLead.phone,
            errorMessage: initialProspect.dataLead.phone.errorMessage,
            phoneNumber: initialProspect.dataLead.phone.phoneNumber,
            codeArea: {
              ...state.dataLead.phone.codeArea,
              error: initialProspect.dataLead.phone.codeArea.error,
              errorMessage: initialProspect.dataLead.phone.codeArea.errorMessage,
              value: action.payload.codeArea
            }
          }
        }
      }
    case SET_PHONE_NUMBER:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          phone: {
            ...state.dataLead.phone,
            errorMessage: initialProspect.dataLead.phone.errorMessage,
            phoneNumber: {
              ...state.dataLead.phone.phoneNumber,
              error: initialProspect.dataLead.phone.phoneNumber.error,
              errorMessage: initialProspect.dataLead.phone.phoneNumber.errorMessage,
              value: action.payload.phoneNumber
            }
          }
        }
      }
    case SET_ERROR_NAME:
      return {
        ...state,
        dataLead: { ...state.dataLead, name: { ...state.dataLead.name, errorMessage: action.payload.error } }
      }
    case SET_ERROR_LAST_NAME:
      return {
        ...state,
        dataLead: { ...state.dataLead, lastName: { ...state.dataLead.lastName, errorMessage: action.payload.error } }
      }
    case SET_ERROR_EMAIL:
      return {
        ...state,
        dataLead: { ...state.dataLead, email: { ...state.dataLead.email, errorMessage: action.payload.error } }
      }
    case SET_ERROR_PHONE:
      return {
        ...state,
        dataLead: { ...state.dataLead, phone: { ...state.dataLead.phone, errorMessage: action.payload.error } }
      }
    case SET_ERROR_CODE_AREA:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          phone: {
            ...state.dataLead.phone,
            errorMessage: action.payload.errorMessage,
            codeArea: { ...state.dataLead.phone.codeArea, errorMessage: action.payload.errorMessage, error: true }
          }
        }
      }
    case SET_ERROR_PHONE_NUMBER:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          phone: {
            ...state.dataLead.phone,
            errorMessage: action.payload.errorMessage,
            phoneNumber: { ...state.dataLead.phone.phoneNumber, errorMessage: action.payload.errorMessage, error: true }
          }
        }
      }
    case SET_GENDER:
      return {
        ...state,
        dataLead: { ...state.dataLead, gender: action.payload.gender }
      }
    case SET_COUNTRY:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          selectedCountry: action.payload.country,
          phone: {
            ...state.dataLead.phone,
            countryCode: action.payload.country.phoneCode,
            codeArea: initialProspect.dataLead.phone.codeArea,
            phoneNumber: initialProspect.dataLead.phone.phoneNumber,
            errorMessage: initialProspect.dataLead.phone.errorMessage,
            limitNumber: action.payload.limitPhoneNumber
          }
        }
      }
    case SET_INTEREST:
      return {
        ...state,
        dataLead: { ...state.dataLead, interestReason: action.payload.interestReasonId.toString() }
      }
    case SET_DISCARD:
      return {
        ...state,
        dataLead: { ...state.dataLead, discardReason: action.payload.discardReasonId.toString() }
      }
    case SET_LOSE_MQL:
      return {
        ...state,
        dataLead: { ...state.dataLead, loseReasonMQL: action.payload.loseReasonMQL.toString() }
      }
    case SET_LOSE_SQL:
      return {
        ...state,
        dataLead: { ...state.dataLead, loseReasonSQL: action.payload.loseReasonSQL.toString() }
      }
    case SET_STATUS:
      return { ...state, statusId: action.payload.statusId }
    case SET_SOURCE_CHANNEL:
      return { ...state, dataLead: { ...state.dataLead, sourceChannel: action.payload.sourceChannelId.toString() } }
    case SET_CONTACT_MEDIUM:
      return { ...state, dataLead: { ...state.dataLead, contactMedium: action.payload.contactMediumId.toString() } }
    case SET_CREATE_MODAL_OPEN: {
      const { createModalOpen, selectedCountry, limit } = action.payload
      return createModalOpen
        ? {
            ...initialProspect,
            open: createModalOpen,
            dataLead: {
              ...initialProspect.dataLead,
              selectedCountry,
              phone: {
                ...initialProspect.dataLead.phone,
                limitNumber: limit,
                countryCode: selectedCountry.phoneCode
              }
            }
          }
        : initialProspect
    }
    case EDIT_LEAD_REQUEST:
    case CREATE_LEAD_REQUEST:
      return {
        ...state,
        loadingLead: true,
        error: ''
      }
    case EDIT_LEAD_SUCCESS:
    case CREATE_LEAD_SUCCESS:
      return initialProspect
    case EDIT_LEAD_FAILURE:
    case CREATE_LEAD_FAILURE:
      return {
        ...state,
        loadingLead: false,
        error: action.payload.error
      }
    case SET_EDIT_MODAL_OPEN: {
      const { selectedCountry, open, lead, limit } = action.payload
      if (!open) return initialProspect
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          name: { ...state.dataLead.name, value: lead.firstName },
          lastName: { ...state.dataLead.lastName, value: lead.lastName },
          gender: lead.gender,
          email: { ...state.dataLead.email, value: lead.email },
          phone: {
            ...state.dataLead.phone,
            value: lead.phone,
            currentNumber: lead.phone,
            countryCode: selectedCountry.phoneCode,
            limitNumber: limit
          },
          contactMedium: lead.contactMediumsId?.toString() ?? initialProspect.dataLead.contactMedium,
          discardReason: lead.discardReasonId?.toString() ?? initialProspect.dataLead.discardReason,
          sourceChannel: lead.sourceId?.toString() ?? initialProspect.dataLead.sourceChannel,
          interestReason: lead.interestReasonId?.toString() ?? initialProspect.dataLead.interestReason,
          loseReason: lead.loseReasonId?.toString() ?? initialProspect.dataLead.loseReasonMQL,
          freeLoseReason: lead.loseReason?.spanishDisplay ?? initialProspect.dataLead.freeLoseReason,
          selectedCountry,
          funnelState: lead.state.qualified,
          id: lead.id
        },
        editMode: true,
        open: true,
        statusId: lead.statusId
      }
    }
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          email: {
            ...state.dataLead.email,
            loading: true,
            lastSearchTimestampEmail: action.payload.lastSearchTimestamp
          }
        }
      }
    case VERIFY_EMAIL_SUCCESS:
      if (action.payload.lastSearchTimestamp !== state.dataLead.email.lastSearchTimestampEmail) return state
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          selectedCountry: {
            ...state.dataLead.selectedCountry,
            code: action.payload.userData.countryId ?? '',
            fullName: action.payload.userData.countryFullName ?? ''
          },
          phone: {
            ...state.dataLead.phone,
            currentNumber: action.payload.userData.phone
          },
          gender: action.payload.userData.gender,
          name: { ...state.dataLead.name, value: action.payload.userData.name },
          lastName: { ...state.dataLead.lastName, value: action.payload.userData.lastName },
          email: { ...state.dataLead.email, loading: false },
          userId: action.payload.userData.id
        }
      }
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          email: {
            ...state.dataLead.email,
            loading: false
          }
        }
      }
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          email: { ...state.dataLead.email, errorMessage: initialProspect.dataLead.email.errorMessage }
        }
      }
    case VALIDATE_EMAIL_FAILURE:
      return {
        ...state,
        dataLead: {
          ...state.dataLead,
          email: {
            ...state.dataLead.email,
            errorMessage: action.payload.errorMessage
          }
        }
      }
    default:
      return state
  }
}

export default prospectReducer
