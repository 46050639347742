import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { AttributesListSortKey } from '../../../sections/Attributes/types/AttributesTable'

interface ListParams {
  productId?: number
  id?: number
  visible?: string
  name?: string
  limit: number
  offset: number
  order?: SortDirection
  sort?: AttributesListSortKey
}

export function reports(params: ListParams): Promise<void> {
  const { productId, sort } = params

  const axiosParams = {
    ...params,
    product_id: productId,
    column: sort
  }

  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/reports/attributes`, { params: axiosParams })
    .then((_: AxiosResponse) => {})
    .catch((err) => {
      throw err
    })
}
