import React from 'react'
import ColorBadge from '../../../components/ColorBadge'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { SaleActions } from '../../../projectApi/Sales/reasons'
import { SalesRequest } from '../../../projectApi/Sales/SalesRequest/list'
import { STATUS_OPTIONS } from '../utils'
import { StatusOptions } from '../../../projectApi/Sales/SalesRequest/common'
import styles from './SalesRequestView.module.scss'
import { DataDisplay } from './subcomponents/DataDisplayBox'
import { SummaryView } from './subcomponents/SummaryView'
import { WorkingView } from './subcomponents/WorkingView'
import { AcceptProposalActionProps } from '../types/salesRequestView'

export type SalesRequestViewProps = {
  salesRequest: SalesRequest | null
  openSaleRequestModal: () => void
  closeSaleRequestModal: () => void
  loadingView: boolean
  saleCostArg: number
  publicationPrice: number
  isCounterProposalActive: boolean
  setPublicationPrice: (price: string) => void
  setCloseCounterProposalView: () => void
  setOpenCounterProposalView: () => void
  handleOpenModal: (action: SaleActions) => void
  handleAcceptProposal: ({ pvp, saleCost, saleMargin }: AcceptProposalActionProps) => void
  SALE_COST: number
  SALE_MARGIN: number
  handleCreateCounterProposal: ({
    isExpressSale,
    prc,
    pve,
    pvp,
    saleCost,
    saleMargin,
    expressSaleCost,
    expressSaleMargin
  }: {
    isExpressSale: boolean
    prc: number
    pve?: number
    pvp: number
    saleCost: number
    saleMargin: number
    expressSaleCost?: number
    expressSaleMargin?: number
  }) => void
}

export const SalesRequestView: React.FC<SalesRequestViewProps> = ({
  salesRequest,
  loadingView,
  saleCostArg,
  setPublicationPrice,
  setOpenCounterProposalView,
  setCloseCounterProposalView,
  publicationPrice,
  isCounterProposalActive,
  handleOpenModal,
  handleAcceptProposal,
  handleCreateCounterProposal,
  SALE_COST,
  SALE_MARGIN,
  openSaleRequestModal,
  closeSaleRequestModal
}) => {
  const statusFound = STATUS_OPTIONS.find((sf) => sf.text === salesRequest?.status)

  const summary: DataDisplay[] = [
    {
      label: 'Costo de venta',
      value: SALE_COST
    },
    {
      label: 'Margen',
      value: SALE_MARGIN
    }
  ]

  return (
    <div className={styles.container}>
      {loadingView ? (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className={styles.requestInfo}>
            <div className={styles.info}>
              <div>
                <span style={{ fontWeight: 600 }}>ID Solicitud:</span> {salesRequest?.id}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Cliente:</span> {salesRequest?.user.id} - {salesRequest?.user.name}{' '}
                {salesRequest?.user.lastName}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Email:</span> {salesRequest?.user.email}
              </div>
            </div>
            {statusFound ? (
              <ColorBadge extraStyles={{ minWidth: '151px' }} {...statusFound?.styleProps}>
                {salesRequest?.status}
              </ColorBadge>
            ) : (
              <div>{salesRequest?.status}</div>
            )}
          </div>
          {salesRequest?.status !== StatusOptions.RECIBIDA ? (
            <SummaryView
              salesRequest={salesRequest}
              openSaleRequestModal={openSaleRequestModal}
              closeSaleRequestModal={closeSaleRequestModal}
            />
          ) : (
            <WorkingView
              handleAcceptProposal={handleAcceptProposal}
              isCounterProposalActive={isCounterProposalActive}
              setOpenCounterProposalView={setOpenCounterProposalView}
              setCloseCounterProposalView={setCloseCounterProposalView}
              salesRequest={salesRequest}
              summary={summary}
              publicationPrice={publicationPrice}
              setPublicationPrice={setPublicationPrice}
              handleOpenModal={handleOpenModal}
              SALE_COST={SALE_COST}
              SALE_MARGIN={SALE_MARGIN}
              saleCostArg={saleCostArg}
              handleCreateCounterProposal={handleCreateCounterProposal}
            />
          )}
        </>
      )}
    </div>
  )
}
