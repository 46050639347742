/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

export interface UpdateBody {
  name?: string
  description?: string
  objectSituation?: string | null
  objectSituationReason?: string | null
  productId?: number
  weightInGr?: number
  lengthInCm?: number
  widthInCm?: number
  heightInCm?: number
  deletedDescription?: string
  objectDeletedReasonId?: number
  rentEnabled?: boolean
  rentPrice?: number
  registryDate?: string
  objectStatusId?: number
  locationId?: number
  productTypeId?: number
  needsAuthorization?: boolean
  createdMethod?: string
  removalDate?: string
  countryId?: string
  attributes?: Attribute[]
}

export interface Attribute {
  id: number
  value: string
  attributeId: number
  deleted: boolean
}

export function update(id: number, body: UpdateBody): Promise<void> {
  const data = snakecaseKeys({ ...body }, { deep: true })
  return apiPrivate
    .put(`${URL_BASE_OBJECTS}/object/update/${id}`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
