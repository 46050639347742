import { Reducer } from 'react'
import {
  AddressUserState,
  AdressActions,
  CLEAN_FILTERS,
  GET_ADDRESSES_DATA_FAILURE,
  GET_ADDRESSES_DATA_REQUEST,
  GET_ADDRESSES_DATA_SUCCESS,
  SET_PAGINATION,
  SET_SORTER
} from '../types/address'

const LIMIT = 10

const initialState: AddressUserState = {
  addresses: [],
  loading: false,
  params: {
    limit: LIMIT,
    offset: 0,
    sort: 'id',
    order: 'desc'
  },
  pagination: {
    current: 1,
    total: 0,
    pageSize: LIMIT
  },
  searchFilters: [{ key: 'id', text: '' }],
  sorter: {
    order: 'descend',
    field: 'id',
    columnKey: 'id'
  },
  error: ''
}

const AddressReducer: Reducer<AddressUserState, AdressActions> = (state = initialState, action): AddressUserState => {
  switch (action.type) {
    case CLEAN_FILTERS: {
      return {
        ...state,
        params: initialState.params,
        searchFilters: initialState.searchFilters,
        pagination: initialState.pagination
      }
    }
    case GET_ADDRESSES_DATA_REQUEST:
      return {
        ...state,
        addresses: [],
        loading: true,
        error: ''
      }
    case GET_ADDRESSES_DATA_SUCCESS:
      return {
        ...state,
        addresses: action.payload.addresses,
        loading: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case GET_ADDRESSES_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    default:
      return state
  }
}

export default AddressReducer
