import moment from 'moment'
import { API } from '../../projectApi'
import { MINUTES_BETWEEN_REPORTS, RequestReportParams } from '../../projectApi/ObjectAdministration/Object/report'
import { AppThunk } from '../../store'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import {
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate,
  setToastWarningUpdate
} from '../../utils/notifications'
import {
  REQUEST_OBJECTS_REPORT_FAILURE,
  REQUEST_OBJECTS_REPORT_REQUEST,
  REQUEST_OBJECTS_REPORT_SUCCESS,
  ReportsType,
  RequestObjectsReportRequest,
  RequestObjectsReportSuccess,
  SET_LAST_REQUEST_REPORT_TIME,
  SET_OBJECT_REPORT_REQUEST_WITH_QUERIES,
  SetLastRequestObjectReportWithQueries,
  SetLastRequestReportAction
} from './types'
import { CorporateObjectReportParams } from '../../projectApi/ObjectAdministration/Object/corporateReport'

const actions = {
  setLastRequestReportTime: (report: ReportsType, time: moment.Moment): SetLastRequestReportAction => ({
    type: SET_LAST_REQUEST_REPORT_TIME,
    payload: { report, time }
  }),

  setLastRequestObjectReportWithQueries: (
    encodedQueries: string,
    time: moment.Moment
  ): SetLastRequestObjectReportWithQueries => ({
    type: SET_OBJECT_REPORT_REQUEST_WITH_QUERIES,
    payload: { encodedQueries, time }
  }),
  requestCorporativeObjectsReport:
    ({
      params,
      lastObjectReports
    }: {
      params: CorporateObjectReportParams
      lastObjectReports: { [encodedQuery: string]: string }
    }): AppThunk =>
    async (dispatch) => {
      const now = moment()

      const encodedQueries = window.btoa(JSON.stringify(params))
      const lastDateReport = lastObjectReports[encodedQueries]

      const toastId = setToastLoading('Solicitando Reporte, por favor espere...')
      const nextDateReport = lastDateReport ? moment(lastDateReport).add(MINUTES_BETWEEN_REPORTS, 'minutes') : now

      if (nextDateReport.isAfter(now)) {
        const minutesLeft = nextDateReport.diff(now, 'minutes')
        setToastWarningUpdate(toastId, {
          render: `Un reporte con estos filtros se solicitó hace poco. Por favor espere ${minutesLeft} minutos para volver a solicitarlo.`
        })

        return
      }

      const request: RequestObjectsReportRequest = {
        type: REQUEST_OBJECTS_REPORT_REQUEST
      }
      dispatch(request)

      try {
        await API.ObjectAdministration.Object.corporateReport(params)

        const success: RequestObjectsReportSuccess = {
          type: REQUEST_OBJECTS_REPORT_SUCCESS,
          payload: { encodedQueries, time: now }
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Reporte solicitado' })
      } catch (err) {
        handleErrorsWithAction(err, REQUEST_OBJECTS_REPORT_FAILURE, dispatch)
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte.'
        })
      }
    },
  requestObjectsReport:
    ({
      params,
      lastObjectReports
    }: {
      params: RequestReportParams
      lastObjectReports: { [encodedQuery: string]: string }
    }): AppThunk =>
    async (dispatch) => {
      const now = moment()

      const encodedQueries = window.btoa(JSON.stringify(params))
      const lastDateReport = lastObjectReports[encodedQueries]

      const toastId = setToastLoading('Solicitando Reporte, por favor espere...')
      const nextDateReport = lastDateReport ? moment(lastDateReport).add(MINUTES_BETWEEN_REPORTS, 'minutes') : now

      if (nextDateReport.isAfter(now)) {
        const minutesLeft = nextDateReport.diff(now, 'minutes')
        setToastWarningUpdate(toastId, {
          render: `Un reporte con estos filtros se solicitó hace poco. Por favor espere ${minutesLeft} minutos para volver a solicitarlo.`
        })

        return
      }

      const request: RequestObjectsReportRequest = {
        type: REQUEST_OBJECTS_REPORT_REQUEST
      }
      dispatch(request)

      try {
        await API.ObjectAdministration.Object.requestReport(params)

        const success: RequestObjectsReportSuccess = {
          type: REQUEST_OBJECTS_REPORT_SUCCESS,
          payload: { encodedQueries, time: now }
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Reporte solicitado' })
      } catch (err) {
        handleErrorsWithAction(err, REQUEST_OBJECTS_REPORT_FAILURE, dispatch)
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte.'
        })
      }
    }
}

export default actions
