import {
  ClearState,
  CommonActionTypes,
  CreditCardNumberFields,
  CreditCardStringFields,
  SetDocumentTypeOption,
  SetDocumentTypesOptionsActionTypes,
  SetInputsActionTypes,
  SetLoading,
  SetNumberField,
  SetStringField,
  IdentificationTypeOption,
  FormikCardErrors,
  SetErrors
} from './types'

const actions = {
  setStringField({ field, value }: { field: CreditCardStringFields; value: string }): SetStringField {
    return {
      type: SetInputsActionTypes.SET_STRING_FIELD,
      payload: {
        field,
        value
      }
    }
  },
  setNumberField({ field, value }: { field: CreditCardNumberFields; value: number }): SetNumberField {
    return {
      type: SetInputsActionTypes.SET_NUMBER_FIELD,
      payload: {
        field,
        value
      }
    }
  },
  setDocumentTypeOptions(documentTypesOptions: IdentificationTypeOption[]): SetDocumentTypeOption {
    return {
      type: SetDocumentTypesOptionsActionTypes.SET_DOCUMENT_TYPES_OPTIONS,
      payload: {
        documentTypesOptions
      }
    }
  },
  clearState(): ClearState {
    return {
      type: CommonActionTypes.CLEAR_STATE
    }
  },
  setLoading(isLoading: boolean): SetLoading {
    return {
      type: CommonActionTypes.SET_LOADING,
      payload: {
        isLoading
      }
    }
  },
  setErrors(errors: FormikCardErrors): SetErrors {
    return {
      type: CommonActionTypes.SET_ERRORS,
      payload: {
        errors
      }
    }
  }
}

export default actions
