import types from '../types/RegisterObjects'

const initialScanModal = {
  modalOpen: false,
  editMode: false,
  object: 0,
  objectCode: '',
  loadingObjects: false,
  errorObjects: '',
  lastSearchIDCode: 0,
  products: [],
  loadingProducts: false,
  errorProducts: '',
  lastSearchIDProduct: 0,
  product: '',
  loading: false,
  error: '',
  height: 0,
  length: 0,
  width: 0,
  weight: 0,
  serviceTypeId: '',
  errors: {
    height: false,
    length: false,
    width: false,
    weight: false
  }
}

function ScanObjectReducer(state = initialScanModal, action) {
  switch (action.type) {
    case types.FETCH_OBJECTS_BY_CODE:
      return {
        ...state,
        object: 0,
        loadingObjects: true,
        lastSearchIDCode: action.payload.lastSearchIDCode
      }
    case types.FETCH_OBJECTS_BY_CODE_SUCCESS:
      return {
        ...state,
        loadingObjects: false,
        object: action.payload.object,
        errorObjects: ''
      }
    case types.FETCH_OBJECTS_BY_CODE_FAIL:
      return {
        ...state,
        object: 0,
        loadingObjects: false,
        errorObjects: action.payload.error
      }
    case types.FETCH_PRODUCTS:
      return {
        ...state,
        products: [],
        loadingProducts: true,
        errorProducts: '',
        lastSearchIDProduct: action.payload.lastSearchID
      }
    case types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        loadingProducts: false
      }
    case types.FETCH_PRODUCTS_FAIL:
      return {
        ...state,
        loadingProducts: false,
        errorProducts: action.payload.error
      }
    case types.RECORD_OBJECT:
      return {
        ...state,
        error: '',
        loadingObjects: true
      }
    case types.RECORD_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case types.RECORD_OBJECT_FAIL:
      return {
        ...state,
        loading: false,
        loadingObjects: false,
        loadingProducts: false,
        error: action.payload.error
      }
    case types.RESET_FORM:
      return {
        ...initialScanModal,
        modalOpen: true
      }
    case types.SET_PRODUCT: {
      const { product } = action.payload
      if (product) {
        const fullProduct = state.products.find((p) => p.guid === product)
        return {
          ...state,
          product,
          height: fullProduct.heightInCm,
          width: fullProduct.widthInCm,
          weight: fullProduct.weightInGr / 1000,
          length: fullProduct.lengthInCm
        }
      }
      return {
        ...state,
        product: '',
        height: 0,
        width: 0,
        length: 0,
        weight: 0
      }
    }
    case types.SET_OBJECT_CODE: {
      return { ...state, objectCode: action.payload.code }
    }
    case types.SET_HEIGHT: {
      const { height, error } = action.payload
      return { ...state, height, errors: { ...state.errors, height: error } }
    }
    case types.SET_WIDTH: {
      const { width, error } = action.payload
      return { ...state, width, errors: { ...state.errors, width: error } }
    }
    case types.SET_LENGTH: {
      const { length, error } = action.payload
      return { ...state, length, errors: { ...state.errors, length: error } }
    }
    case types.SET_WEIGHT: {
      const { weight, error } = action.payload
      return { ...state, weight, errors: { ...state.errors, weight: error } }
    }
    case types.SET_SCAN_MODAL_OPEN: {
      const { editMode, object } = action.payload
      const { id, description, serviceTypeId } = object
      return {
        ...initialScanModal,
        modalOpen: true,
        ...(editMode && { editMode, object: description, objectCode: id, serviceTypeId })
      }
    }
    case types.SET_SCAN_MODAL_CLOSE: {
      return { ...state, modalOpen: false }
    }
    default:
      return state
  }
}

export default ScanObjectReducer
