import { Input, Spin } from 'antd'
import React, { ChangeEvent, ForwardedRef, HTMLInputTypeAttribute } from 'react'

type StylesType = {
  [key: string]: React.CSSProperties
}

const styles: StylesType = {
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red'
  },
  successFieldLabel: {
    color: 'green',
    marginBottom: 0
  }
}

type InputFieldProps = {
  label?: string
  value: string
  inputStyles?: React.CSSProperties
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  successMessage?: string
  loading?: boolean
  required?: boolean
  disabled?: boolean
  tabIndex?: number
  placeholder?: string
  maxLength?: number
  error?: string
  type?: HTMLInputTypeAttribute
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string
}

const InputFieldWithError = React.forwardRef((props: InputFieldProps, ref: ForwardedRef<any>) => {
  const {
    label,
    loading = false,
    required = false,
    disabled = false,
    value,
    error,
    placeholder,
    tabIndex,
    type,
    onBlur,
    onChange,
    successMessage,
    maxLength,
    inputStyles = {},
    className,
    ...otherInputProps
  } = props
  const requiredLabel = label + (required ? '*' : '')

  const inputStylesCombined = error ? { ...inputStyles, ...styles.errorFields } : inputStyles

  return (
    <>
      {label && <p style={styles.inputTitle}>{requiredLabel.toUpperCase()}</p>}
      <Input
        type={type}
        autoComplete="nope"
        style={inputStylesCombined}
        placeholder={placeholder ?? label ?? ''}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        suffix={loading && <Spin size="small" />}
        tabIndex={tabIndex}
        {...otherInputProps}
        maxLength={maxLength}
        ref={ref}
        className={className}
      />
      {error && <p style={styles.errorFieldLabel}>{error}</p>}
      {successMessage && <p style={styles.successFieldLabel}>{successMessage}</p>}
    </>
  )
})
InputFieldWithError.displayName = 'InputFieldWithError'

export default InputFieldWithError
