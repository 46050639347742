import React from 'react'
import { Input, Modal } from 'antd'
import InputGroup from '../../../components/InputGroup'
import styles from './AMProviders.module.scss'

type AMModalProvidersTypes = {
  modalVisible: boolean
  editMode: boolean
  providerName: string
  disabledSuccessButton: boolean
  setModalVisibility: ({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }) => void
  setProviderName: (providerName: string) => void
  handleCreateProvider: () => void
  error: string
}

const AMModalProviders = ({
  modalVisible,
  editMode,
  providerName,
  disabledSuccessButton,
  setModalVisibility,
  setProviderName,
  handleCreateProvider,
  error
}: AMModalProvidersTypes) => {
  const modalTitle = editMode ? 'Editar proveedor' : 'Creación de proveedor'
  const textConfirmButton = editMode ? 'Editar' : 'Crear'

  return (
    <Modal
      width={540}
      title={modalTitle}
      okText={textConfirmButton}
      visible={modalVisible}
      onCancel={() => setModalVisibility({ modalVisible: false })}
      cancelText={'Cancelar'}
      onOk={handleCreateProvider}
      okButtonProps={{ disabled: disabledSuccessButton }}
      destroyOnClose>
      <div>
        <InputGroup title="Datos obligatorios">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between'
            }}>
            <label className={styles.label}>
              Nombre del proveedor*
              <Input value={providerName} onChange={({ target }) => setProviderName(target.value)} />
            </label>
          </div>
        </InputGroup>
        {error && <p className={styles.errorFieldLabel}>{error}</p>}
      </div>
    </Modal>
  )
}

export default AMModalProviders
