import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CancelSaleActionCreators from '../actionCreators/cancelSaleModal'
import CancelSaleModal from '../components/CancelSaleModal'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { CancelSale, cleanState, setOpenModal } = bindActionCreators(CancelSaleActionCreators, dispatch)
  const cancelSaleState = useSelector((state) => state.SalesRequest.cancel)

  const handleCancelSaleRequest = (id: number, reason: { id: number; text: string }) => {
    if (id && reason) {
      CancelSale(Number(cancelSaleState.id), reason)
    }
  }

  const handleCloseModal = () => {
    cleanState()
    setOpenModal(false)
  }

  const cancelSaleModalProps = {
    closeModal: handleCloseModal,
    cancelSaleRequestHandler: handleCancelSaleRequest,
    cancelSaleState
  }

  return <CancelSaleModal {...cancelSaleModalProps} />
}

export default Container
