import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export function creditNote(billingId: number): Promise<string> {
  const url = `${URL_BASE_TRANSACTIONS}/authorization-document?format=pdf&billing_id=${billingId}&source=backoffice&authorization_type=credit_note`

  return apiPrivate
    .get(url, { params: { country_code: undefined } })
    .then(() => {
      return url
    })
    .catch((err) => {
      throw err
    })
}
