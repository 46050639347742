/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_PAYMENT_COLLECTOR } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CreditCardType } from '../../../common/operations/creditCard'

type Params = {
  userId?: number
  paymentMethodType?: string
  order?: string
  sort?: string
  limit?: number
  offset?: number
}

type CreditCardAPI = {
  card_type: CreditCardType
  created_at: string
  fingerprint: string
  first_name: string
  first_six_digits: string
  full_name: string
  issuer_identification_number: string
  last_four_digits: string
  last_name: string
  month: number
  payment_method_type: string
  updated_at: string
  year: number
}

type CreditCardAdditionalInfoAPI = {
  cardholder_identification_number: number
  cardholder_identification_type: string
}

export enum PaymentMethodType {
  CREDIT_CARD = 'credit_card'
}

type PaymentMethodAPI<T = CreditCardAPI, A = CreditCardAdditionalInfoAPI> = {
  id: number
  additional_info: A
  created_at: string
  updated_at: string
  token: string
  payment_method_type: PaymentMethodType
  user_id: number
  payment_method: T
  main: boolean
}

export type PaymentMethod = CamelCasedPropertiesDeep<PaymentMethodAPI>

type Response = {
  results: PaymentMethodAPI[]
}

export function search(params: Params): Promise<PaymentMethod[]> {
  const formattedParams = snakecaseKeys(params)
  return apiPrivate
    .get(`${URL_BASE_PAYMENT_COLLECTOR}/payment-methods/search`, { params: formattedParams })
    .then((response: AxiosResponse<Response>) => camelcaseKeys(response.data.results, { deep: true }))
    .catch((error) => {
      throw error
    })
}
