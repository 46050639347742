import { STATUS_ID } from '../../projectApi/Sales/SalesRequest/common'
import { formatMoney } from '../../utils/formatNumber'

export const getExpressOption = (percent: number, basePrice: number) => {
  const value = percent * basePrice
  const label = `${percent * 100}% - ${formatMoney(value)}`
  return { label, value }
}

export const MAX_PRICE_LENGTH = 8

export const STATUS_OPTIONS = [
  {
    value: STATUS_ID.RECIBIDA,
    text: 'Recibida',
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: STATUS_ID.EN_DIAGNOSTICO,
    text: 'En diagnóstico',
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: STATUS_ID.DIAGNOSTICO_FINALIZADO,
    text: 'Diagnóstico finalizado',
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: STATUS_ID.RECHAZADA_SG,
    text: 'Rechazada SG',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: STATUS_ID.CONTRAPROPUESTA_RECHAZADA_USER,
    text: 'Contrapropuesta Rechazada User',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: STATUS_ID.CANCELADA_CLIENTE,
    text: 'Cancelada Cliente',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: 9,
    text: 'Aceptada PRC',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: STATUS_ID.EN_CONTRAPROPUESTA,
    text: 'En Contrapropuesta',
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: STATUS_ID.ACEPTADA_CP_PRC,
    text: 'Aceptada CP PRC',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: STATUS_ID.ACEPTADA_VENTA_EXPRESS,
    text: 'Aceptada Venta Express',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: STATUS_ID.CONTRAPROPUESTA_ACEPTADA_VE,
    text: 'Contrapropuesta aceptada VE',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: STATUS_ID.VENDIDO,
    text: 'Vendido',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: STATUS_ID.CANCELADA,
    text: 'Cancelada SG',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  }
]
