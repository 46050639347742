import types from '../types/confirmModal'

export default {
  openModalConfirm() {
    return { type: types.OPEN_MODAL_CONFIRM }
  },
  closeModalConfirm() {
    return { type: types.CLOSE_MODAL_CONFIRM }
  },
  setObjectModal(objectTarget) {
    return { type: types.SET_OBJECT_CONFIRM, payload: { objectTarget } }
  },
  cleanState() {
    return { type: types.CLEAN_STATE }
  }
}
