import React from 'react'

import GeneralData from '../containers/GeneralData'
import styles from './CreateNewProduct.module.scss'
import { Step, StepsProps, ViewSteps } from './Step/ViewSteps'
import ProductCosts from '../containers/ProductCosts'
import { GeneralDataCreateStates } from '../types/common'
import { ProductCostsState } from '../types/productCosts'
import { sendToastNotificationError } from '../../../utils/notifications'
import { LoadingIndicator } from '../../../components/LoadingIndicator'

interface CreateNewProductProps {
  handleCreateNewProduct: () => void
  productCostCleanState: () => void
  setEditMode: (isEditMode: boolean) => void
  generalDataState: GeneralDataCreateStates
  productCostsState: ProductCostsState
  generalDataCleanState: () => void
  handleUpdateProduct: () => void
  loadingSaveProduct: boolean
  isFromCatalog: boolean
  handleCreateNewByCatalogProduct: () => void
}

const steps: StepsProps[] = [
  {
    value: Step.GENERAL_DATA,
    component: <GeneralData />,
    label: 'Datos generales'
  },
  {
    value: Step.COST,
    component: <ProductCosts />,
    label: 'Denominación y costos'
  }
]

export const CreateNewProduct: React.FC<CreateNewProductProps> = ({
  handleCreateNewProduct,
  generalDataState,
  productCostsState,
  productCostCleanState,
  generalDataCleanState,
  handleUpdateProduct,
  loadingSaveProduct,
  isFromCatalog,
  handleCreateNewByCatalogProduct
}) => {
  const {
    dimensionsHeight,
    dimensionsLength,
    dimensionsWeight,
    dimensionsWidth,
    categorySelected,
    isEditMode,
    loading
  } = generalDataState

  const firstStepButtonDisabled =
    !categorySelected ||
    !dimensionsHeight.length ||
    dimensionsHeight === '0' ||
    !dimensionsLength.length ||
    dimensionsLength === '0' ||
    !dimensionsWeight.length ||
    dimensionsWeight === '0' ||
    !dimensionsWidth.length ||
    dimensionsWidth === '0'

  const validateAndHandleCreate = () => {
    const incompleteCountries = Object.values(productCostsState).some((value) => !value.productName.length)

    if (incompleteCountries)
      return sendToastNotificationError('El nombre del producto es obligatorio para todos los paises')

    handleCreateNewProduct()
  }

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>
          <LoadingIndicator />
        </div>
      ) : (
        <ViewSteps
          isEditMode={isEditMode}
          steps={steps}
          validateAndHandleCreate={validateAndHandleCreate}
          firstStepButtonDisabled={firstStepButtonDisabled}
          productCostCleanState={productCostCleanState}
          generalDataCleanState={generalDataCleanState}
          handleUpdateProduct={handleUpdateProduct}
          loadingSaveProduct={loadingSaveProduct}
          isFromCatalog={isFromCatalog}
          handleCreateNewByCatalogProduct={handleCreateNewByCatalogProduct}
        />
      )}
    </div>
  )
}
