import moment from 'moment'
import { TimeInterval } from '../types/calendar'
import { BaseOption } from '@/components/Select/Select'
import { pad } from '@/sections/Deposits/component/subComponents/utils'

export const calculateDaysAgo = (date: string): string => {
  const today = moment()
  const startDate = moment(date)
  const daysDifference = today.diff(startDate, 'days')

  if (daysDifference <= 60) {
    return `Hace ${daysDifference} días`
  } else if (daysDifference > 60) {
    const monthsDifference = today.diff(startDate, 'months')
    return `Hace ${monthsDifference} meses`
  }

  return ''
}

export const getFormatFirstTimeInterval = (value: number, list: TimeInterval[]): BaseOption<number> => {
  const time = list.find((time: TimeInterval) => time.id === value)

  if (time) return { value: time.id, label: time.start + ':' + pad('') + ' - ' + time.end + ':' + pad('') }
  else return { value, label: '' }
}
