import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCaseOperation, Operation } from './common'
import { formatDates } from '../../utils'

export interface Description {
  operations: Operation[]
  quantity: number
}

interface APIResponse {
  description?: Description
  success: string
}

export enum DepositStatus {
  CONFIRMED = 'confirmed',
  CREATED = 'created',
  CANCELLED = 'cancelled',
  DEPOSITED = 'deposited',
  VALIDATED = 'validated'
}

type Response = {
  operations: CamelCaseOperation[]
  total: number
}

interface ListParams {
  Limit?: number
  Offset?: number
  Column?: 'id' | 'type'
  Order?: SortDirection
  id?: number
  userId?: number
  userName?: string
  userLastName?: string
  createdAtFrom?: Moment
  createdAtTo?: Moment
  datetimeFrom?: Moment
  datetimeTo?: Moment
  statuses?: DepositStatus[]
  format?: string
}

export function list(params: ListParams): Promise<Response> {
  const { statuses, createdAtFrom, createdAtTo, datetimeFrom, datetimeTo, ...restParams } = params
  const statusParam = { statuses: statuses?.join(',') }

  const snakeParams = {
    ...restParams,
    ...statusParam,
    ...formatDates({
      dates: {
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo,
        transport_datetime_from: datetimeFrom,
        transport_datetime_to: datetimeTo
      }
    })
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operation/deposit`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (params.format === 'csv') return { operations: [], total: 0 }
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener la operación')
      if (!response.data.description?.operations) throw new Error('Respuesta incorrecta al obtener las operaciones')

      const camelOperations = camelcaseKeys(response.data.description.operations, { deep: true })
      return {
        operations: camelOperations,
        total: response.data.description.quantity
      }
    })
    .catch((err) => {
      throw err
    })
}
