import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

export type GenericParams = {
  ownerId: number
  quantityIdentificationCodes: number
  identificationCode: string
}

export function generic(body: GenericParams): Promise<void> {
  const formattedBody = {
    ...snakecaseKeys({ ...body }, { deep: true })
  }

  return apiPrivate
    .post(`${URL_BASE_OBJECTS}/objects/generic`, formattedBody)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
