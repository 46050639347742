import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import {
  CleanState,
  CorporateProductsTableTypes,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GetProductsRequest,
  GetProductsSuccess,
  ProductsCategoryFilter,
  ProductsSearchFilter,
  ProductsTableSort,
  SearchCategoriesRequest,
  SearchCategoriesSuccess,
  SearchCategoriesTypes
} from '../types/productTable'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import { getLeafCategories } from '../../../../projectApi/CategoryCreation/getCategories'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'

const ProductsTableActionCreators = {
  getProducts:
    ({
      countryCode,
      pagination,
      Column,
      searchFilters,
      sort,
      categoryId,
      categoryFilter
    }: {
      countryCode: string
      pagination: Pagination
      Column?: ProductsTableSort
      searchFilters?: ProductsSearchFilter[]
      categoryId?: number
      sort: ProductsTableSort
      categoryFilter: ProductsCategoryFilter
    }): AppThunk =>
    async (dispatch) => {
      const request: GetProductsRequest = {
        type: GET_PRODUCTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          Column,
          sort,
          categoryFilter,
          categoryId
        }
      }

      dispatch(request)

      try {
        const { products, total } = await API.CategoryCreation.Product.listAdmin({
          countryCode,
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          order: sort?.direction,
          column: sort?.field,
          showInSearch: 'false',
          deprecated: categoryFilter?.deprecated[0],
          categoryId: categoryId !== 0 ? categoryId : undefined,
          serviceTypeId: 'all',
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetProductsSuccess = {
          type: GET_PRODUCTS_SUCCESS,
          payload: {
            products,
            total
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_PRODUCTS_FAILURE, dispatch)
      }
    },

  getCategories: (): AppThunk => async (dispatch) => {
    try {
      const { categories } = await API.CategoryCreation.getCategories()

      const leafCategories = getLeafCategories(categories)

      const success = {
        type: GET_ALL_CATEGORIES_SUCCESS,
        payload: {
          categories: leafCategories.map((cat) => ({
            value: cat.id,
            label: cat.description
          }))
        }
      }

      dispatch(success)
    } catch (err: any) {
      if (err.response.data.description) {
        alert(err.response.data.description)
      }
    }
  },

  searchCategoryId:
    (categoryName: string): AppThunk =>
    async (dispatch) => {
      const request: SearchCategoriesRequest = {
        type: SearchCategoriesTypes.SEARCH_CATEGORIES_REQUEST
      }

      dispatch(request)
      try {
        const { categories } = await API.CategoryCreation.Search.Categories.list({ name: categoryName })

        const success: SearchCategoriesSuccess = {
          type: SearchCategoriesTypes.SEARCH_CATEGORIES_SUCCESS,
          payload: { categories }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, SearchCategoriesTypes.SEARCH_CATEGORIES_FAILURE, dispatch)
      }
    },

  cleanState: (): AppThunk => (dispatch) => {
    dispatch<CleanState>({ type: CorporateProductsTableTypes.CLEAN_STATE })
    setTimeout(() => Emitter.emit(Events.CorporateProducts.CLEAN_STATE), 50)
  }
}

export default ProductsTableActionCreators
