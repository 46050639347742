/* eslint-disable camelcase */
import { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { formatDates } from '../../utils'
import axios from 'axios'

export type TripTransaction = {
  guid: string
  quantityObjects: number
  transactionType: number
  totalM3: number
}

interface CreateParams {
  driverId?: number
  transactions: TripTransaction[]
  scheduledStartDate: Moment
  scheduledEndDate: Moment
  country?: CountryIdCode
}

export type TripTransactionError = 'TNED-1' | 'TNEO-1' | 'TNET-1' | 'TTNF-1' | 'TCA-1'

export function create(params: CreateParams): Promise<void> {
  const data = snakecaseKeys({
    driver: params.driverId || null,
    transactions: params.transactions.map((t) => ({
      guid: t.guid,
      quantity_objects: t.quantityObjects,
      transaction_type: t.transactionType,
      total_m3: t.totalM3
    })),
    country: params.country,
    ...formatDates({
      dates: {
        scheduledStart: params.scheduledStartDate,
        scheduledEnd: params.scheduledEndDate
      },
      format: null,
      utc: true
    })
  })

  return apiPrivate
    .post(`${URL_BASE_TRIPS}/trips`, data)
    .then(() => {})
    .catch((err) => {
      let errorMessage = 'Algo salió mal'
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.code) {
          switch (err.response.data.code as TripTransactionError) {
            case 'TNED-1':
              errorMessage = 'Las transacciones tienen distintos destinos'
              break
            case 'TNEO-1':
              errorMessage = 'Las transacciones tienen distintos orígenes'
              break
            case 'TNET-1':
              errorMessage = 'Las transacciones son de distintos tipos'
              break
            case 'TTNF-1':
              errorMessage = 'Tipo de transacción no encontrada'
              break
            case 'TCA-1':
              errorMessage = 'Una transacción tiene un estado incorrecto para crear el viaje'
              break
          }
        }
      }
      throw errorMessage
    })
}
