import React from 'react'

interface CaretRightIconProps {
  size?: number
  color?: string
  style?: React.CSSProperties
}

const CaretRightIcon: React.FC<CaretRightIconProps> = ({ size = 16, color = '#3F444D', style }) => (
  <svg width={size} height={size} viewBox="0 0 8 14" fill="none" style={{ ...style, borderRadius: '1px' }}>
    <path
      d="M7.12713 5.24407C7.58759 5.64284 7.58759 6.35716 7.12713 6.75593L1.65465 11.4952C1.00701 12.0561 3.59372e-07 11.5961 3.96822e-07 10.7393L8.11145e-07 1.2607C8.48595e-07 0.403944 1.00701 -0.0561072 1.65465 0.504769L7.12713 5.24407Z"
      fill={color}
    />
  </svg>
)

export default CaretRightIcon
