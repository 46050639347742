import React from 'react'
import styles from './objectMetrics.module.scss'
import { Metrics } from '../types/objectsTable'
import StatusCard, { StatusCardTypes } from '../../../../components/StatusCard/StatusCard'
import { StatusCardContainer } from '../../../../components/StatusCard/subComponents/StatusCardContainer'
import { formatNumber } from '../../../../utils/formatNumber'

export type ObjectMetricsProps = {
  metrics: Metrics
  fetchingMetrics: boolean
}

const ObjectsMetrics: React.FC<ObjectMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const lengthSkeleton = Object.keys(mapOfSituationsMetrics).length

  return (
    <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={lengthSkeleton}>
      <StatusCard
        type={'infoSolid'}
        customClassname={styles.total}
        titleCard={'Objetos'}
        total={metrics.total}
        percentage={formatNumber(metrics.totalM3, 2, true)}
        unit="m³"
        unitStyles={styles.unitStyles}
      />

      {metrics &&
        Object.entries(metrics).map(([key, value]) => {
          if (key === 'total' || key === 'totalM3') {
            return null
          }
          return (
            <StatusCard
              key={key}
              type={mapOfSituationMetricTypes[key]}
              customClassname={styles.warningCard}
              titleCard={mapOfSituationsMetrics[key]}
              total={value}
            />
          )
        })}
    </StatusCardContainer>
  )
}

export default ObjectsMetrics

const mapOfSituationMetricTypes: { [key: string]: StatusCardTypes } = {
  total: 'infoSolid',
  saved: 'success',
  cataloging: 'danger',
  locating: 'warning',
  takingPhotos: 'info'
}

export const mapOfSituationsMetrics: { [key: string]: string } = {
  total: 'Objetos',
  saved: 'Guardados validados',
  takingPhotos: 'Fotografiando',
  cataloging: 'En clasificación',
  locating: 'A ubicar'
}
