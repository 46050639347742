import { API } from '../../../projectApi'
import { Transaction } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { Pagination, dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  CANCEL_TRIP_FAILURE,
  CANCEL_TRIP_REQUEST,
  CANCEL_TRIP_SUCCESS,
  CLEAR_STATE,
  CancelTripRequest,
  CancelTripSuccess,
  ClearSelectedTrips,
  ClearState,
  FINISH_TRIP_FAILURE,
  FINISH_TRIP_REQUEST,
  FINISH_TRIP_SUCCESS,
  FinishTripRequest,
  FinishTripSuccess,
  GET_TRIPS_FAILURE,
  GET_TRIPS_REQUEST,
  GET_TRIPS_SUCCESS,
  GetTripsRequest,
  GetTripsSuccess,
  REMOVE_TRANSACTION_FROM_TRIP_FAILURE,
  REMOVE_TRANSACTION_FROM_TRIP_REQUEST,
  REMOVE_TRANSACTION_FROM_TRIP_SUCCESS,
  RemoveTransactionFromTripRequest,
  RemoveTransactionFromTripSuccess,
  SET_CANCELING_MODAL_ID,
  START_TRIP_FAILURE,
  START_TRIP_REQUEST,
  START_TRIP_SUCCESS,
  SelectedTripsTypes,
  SetCancelingModalId,
  StartTripRequest,
  StartTripSuccess,
  TOGGLE_SELECTED_TRANSACTION,
  TOGGLE_TRIP_TRANSACTIONS,
  ToggleAllTrips,
  ToggleSelectedTransaction,
  ToggleSelectedTrip,
  ToggleTripTransactions,
  TripWithTransactions,
  TripsDateRangeFilter,
  TripsSearchFilter,
  TripsTableCategoryFilter,
  TripsTableSort
} from '../types/tripsTable'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const TripsTableActionCreators = {
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  setCancelingModalId: (id: number | null): SetCancelingModalId => ({
    type: SET_CANCELING_MODAL_ID,
    payload: { id }
  }),
  toggleSelectedTransaction: (transaction: Transaction): ToggleSelectedTransaction => ({
    type: TOGGLE_SELECTED_TRANSACTION,
    payload: { transaction }
  }),
  toggleTripTransactions: (trip: TripWithTransactions): ToggleTripTransactions => ({
    type: TOGGLE_TRIP_TRANSACTIONS,
    payload: { trip }
  }),
  toggleSelectedTrip: (trip: TripWithTransactions): ToggleSelectedTrip => ({
    type: SelectedTripsTypes.TOGGLE_SELECTED_TRIP,
    payload: {
      trip
    }
  }),
  toggleAllTrips: (): ToggleAllTrips => ({ type: SelectedTripsTypes.TOGGLE_ALL_TRIPS }),
  clearSelectedTrips: (): ClearSelectedTrips => ({ type: SelectedTripsTypes.CLEAR_SELECTED_TRIPS }),
  getTrips:
    ({
      pagination,
      searchFilters,
      dateRangeFilters,
      sort,
      categoryFilter,
      silentLoading
    }: {
      pagination: Pagination
      searchFilters?: TripsSearchFilter[]
      categoryFilter?: TripsTableCategoryFilter
      dateRangeFilters?: TripsDateRangeFilter[]
      sort?: TripsTableSort
      silentLoading?: boolean
    }): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const request: GetTripsRequest = {
        type: GET_TRIPS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          dateRangeFilters,
          searchFilters,
          categoryFilter,
          sort,
          silentLoading
        }
      }

      dispatch(request)

      try {
        const { trips, total } = await API.Trips.Trip.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          sort: sort?.field,
          order: sort?.direction,
          tripStatus: categoryFilter?.tripStatus.join(),
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        const success: GetTripsSuccess = {
          type: GET_TRIPS_SUCCESS,
          payload: {
            trips: trips.map((t) => ({ ...t, transactions: [] })),
            total
          }
        }

        dispatch(success)
        return true
      } catch (error) {
        handleErrorsWithAction(error, GET_TRIPS_FAILURE, dispatch)
        return false
      }
    },
  startTrip:
    ({ tripId }: { tripId: number }): AppThunk =>
    async (dispatch) => {
      const request: StartTripRequest = {
        type: START_TRIP_REQUEST,
        payload: {
          tripId
        }
      }

      const toastId = setToastLoading('Iniciando viaje, por favor espere...')

      dispatch(request)

      try {
        await API.Trips.Trip.start({ tripId })

        setToastSuccessUpdate(toastId, { render: 'Viaje iniciado exitosamente' })

        const success: StartTripSuccess = {
          type: START_TRIP_SUCCESS,
          payload: {
            tripId
          }
        }

        dispatch(success)
        Emitter.emit(Events.Trips.TRIP_UPDATED, tripId)
      } catch (error) {
        const message = handleErrorsWithAction(error, START_TRIP_FAILURE, dispatch, { tripId })
        setToastErrorUpdate(toastId, { render: message })
      }
    },
  finishTrip:
    ({ tripId }: { tripId: number }): AppThunk =>
    async (dispatch) => {
      const request: FinishTripRequest = {
        type: FINISH_TRIP_REQUEST,
        payload: {
          tripId
        }
      }

      dispatch(request)

      try {
        await API.Trips.Trip.finish({ tripId })

        const success: FinishTripSuccess = {
          type: FINISH_TRIP_SUCCESS,
          payload: {
            tripId
          }
        }

        dispatch(success)
        Emitter.emit(Events.Trips.TRIP_UPDATED, tripId)
      } catch (error) {
        handleErrorsWithAction(error, FINISH_TRIP_FAILURE, dispatch, { tripId })
      }
    },
  cancelTrip:
    ({ tripId }: { tripId: number }): AppThunk =>
    async (dispatch) => {
      const request: CancelTripRequest = {
        type: CANCEL_TRIP_REQUEST,
        payload: {
          tripId
        }
      }

      dispatch(request)

      try {
        await API.Trips.Trip.cancel({ tripId })

        const success: CancelTripSuccess = {
          type: CANCEL_TRIP_SUCCESS,
          payload: {
            tripId
          }
        }

        dispatch(success)
        Emitter.emit(Events.Trips.TRIP_CANCELED, tripId)
      } catch (error) {
        handleErrorsWithAction(error, CANCEL_TRIP_FAILURE, dispatch, { tripId })
      }
    },
  removeTransaccionFromTrip:
    ({ tripId, transactionId }: { tripId: number; transactionId: number }): AppThunk =>
    async (dispatch) => {
      const request: RemoveTransactionFromTripRequest = {
        type: REMOVE_TRANSACTION_FROM_TRIP_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading(`Quitando transacción ID: ${transactionId} del viaje, por favor espere...`)

      try {
        await API.Trips.Trip.removeTx({ tripId, transactionId })

        setToastSuccessUpdate(toastId, {
          render: `Se quito la transacción ID: ${transactionId} del viaje exitosamente`
        })

        const success: RemoveTransactionFromTripSuccess = {
          type: REMOVE_TRANSACTION_FROM_TRIP_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Transaction.TRANSACTIONS_REMOVED_FROM_TRIP, { transactionIds: [transactionId], tripId })
      } catch (error) {
        const message = handleErrorsWithAction(error, REMOVE_TRANSACTION_FROM_TRIP_FAILURE, dispatch, { tripId })
        setToastErrorUpdate(toastId, { render: message })
      }
    }
}

export default TripsTableActionCreators
