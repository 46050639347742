import { AppThunk } from '../../../store'
import { normalizeString } from '../../../utils/validateName'
import { FunnelState } from '../constants'
import {
  OpenReasonsModal,
  SET_CHECK_SELECTED,
  SET_DISCARD_REASON,
  SET_ERROR_TEXT_REASON,
  SET_INTEREST_REASON,
  SET_LACK_INTEREST_REASON,
  SET_LOSE_REASON,
  SET_MODAL_OPEN,
  SET_OPEN_INPUT_TEXT,
  SET_TEXT_REASON,
  SetCheckSelected,
  SetDiscardReason,
  SetInterestReason,
  SetLackInterestReason,
  SetLoseReason,
  SetOpenInputText
} from '../types/ReasonsModal'

const reasonsModalActionCreators = {
  setOpenModal: (openModal: boolean, leadId?: number, leadFunnelState?: FunnelState): OpenReasonsModal => {
    return { type: SET_MODAL_OPEN, payload: { openModal, leadId, leadFunnelState } }
  },

  setInterestReason: (interestReason: string): SetInterestReason => {
    return { type: SET_INTEREST_REASON, payload: { interestReason } }
  },

  setErrorTextReason:
    (textReason: string): AppThunk =>
    (dispatch, getState) => {
      const text = normalizeString(textReason)
      const validText = /^[A-ZÁÉÍÓÚ]{1}[a-z-A-Zñáéíóú\s,.-]*$/
      const limitText = getState().Leads.reasonsModal.textReason.limitText
      if (validText.test(text)) {
        const error = getState().Leads.reasonsModal.textReason.errorMessage

        if (error !== '') {
          dispatch({
            type: SET_ERROR_TEXT_REASON,
            payload: { messageError: '' }
          })
        }

        if (text.length >= limitText) {
          dispatch({
            type: SET_ERROR_TEXT_REASON,
            payload: { messageError: `El máximo de caracteres es de ${limitText}` }
          })
        }
      } else if (text === '') {
        dispatch({
          type: SET_ERROR_TEXT_REASON,
          payload: { messageError: `El motivo de pérdida no puede estar vacío` }
        })
      } else {
        dispatch({
          type: SET_ERROR_TEXT_REASON,
          payload: {
            messageError: `El motivo solo puede contener letras y su máximo de caracteres es de ${limitText}`
          }
        })
      }
    },

  setTextReason:
    (textReason: string): AppThunk =>
    (distpatch) => {
      distpatch(reasonsModalActionCreators.setErrorTextReason(textReason))
      distpatch({ type: SET_TEXT_REASON, payload: { textReason } })
    },

  setOpenInput: (inputOpen: boolean): SetOpenInputText => {
    return { type: SET_OPEN_INPUT_TEXT, payload: { inputOpen } }
  },

  setLackInterestReason: (lackInterestReason: string): SetLackInterestReason => {
    return { type: SET_LACK_INTEREST_REASON, payload: { lackInterestReason } }
  },

  setLoseReason: (loseReason: string): SetLoseReason => {
    return { type: SET_LOSE_REASON, payload: { loseReason } }
  },

  setDiscardReason: (discardReason: string): SetDiscardReason => {
    return { type: SET_DISCARD_REASON, payload: { discardReason } }
  },

  setCheckSelected: (loseReason: string): SetCheckSelected => {
    return { type: SET_CHECK_SELECTED, payload: { loseReason } }
  }
}

export default reasonsModalActionCreators
