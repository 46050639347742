import { Reducer } from 'redux'
import { GET_SALES_STATUS_FAILURE, GET_SALES_STATUS_REQUEST, GET_SALES_STATUS_SUCCESS } from '../types/common'
import {
  GET_SALES_REQUEST_FAILURE,
  GET_SALES_REQUEST_REQUEST,
  GET_SALES_REQUEST_SUCCESS,
  GetSalesRequestMetricsTypes,
  SEND_SALES_REQUEST_NOTIFICATION_FAILURE,
  SEND_SALES_REQUEST_NOTIFICATION_REQUEST,
  SEND_SALES_REQUEST_NOTIFICATION_SUCCESS,
  SalesRequestAction,
  SalesRequestState,
  SalesRequestTableDateRangeKey
} from '../types/salesRequestTable'

const initialState: SalesRequestState = {
  salesRequest: [],
  metrics: [],
  loading: false,
  fetchingMetrics: false,
  error: '',
  type: undefined,
  loadingTable: false,
  showDemoUsers: false,
  categoryFilter: { status: [] },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'userId', text: '' },
    { key: 'requestId', text: '' }
  ],
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  dateRangeFilters: [
    { key: SalesRequestTableDateRangeKey.CREATED_AT, startDate: null, endDate: null },
    { key: SalesRequestTableDateRangeKey.UPDATED_AT, startDate: null, endDate: null }
  ],
  statusOptions: []
}

export const SalesRequestReducer: Reducer<SalesRequestState, SalesRequestAction> = (
  state = initialState,
  action
): SalesRequestState => {
  switch (action.type) {
    case GET_SALES_REQUEST_REQUEST:
      return {
        ...state,
        loadingTable: true,
        showDemoUsers: action.payload.showDemoUsers || initialState.showDemoUsers,
        type: action.payload.type || initialState.type,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters
      }
    case GET_SALES_REQUEST_SUCCESS:
      return {
        ...state,
        salesRequest: action.payload.salesRequest,
        loadingTable: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_SALES_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loadingTable: false
      }

    case GET_SALES_STATUS_REQUEST:
      return {
        ...state
      }
    case GET_SALES_STATUS_SUCCESS:
      return {
        ...state,
        statusOptions: action.payload.statusOptions
      }
    case GET_SALES_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case SEND_SALES_REQUEST_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case SEND_SALES_REQUEST_NOTIFICATION_SUCCESS:
    case SEND_SALES_REQUEST_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false
      }

    case GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        metrics: action.payload.metrics
      }
    case GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}
