import React from 'react'
import Component from '../components/removePhoto'
import { bindActionCreators } from 'redux'
import actions from '../actions/removePhoto'
import { connect } from 'react-redux'

const Container = (props) => {
  return <Component {...props} />
}

const mapStateToProps = (state) => {
  const { Objects } = state
  return { ...Objects.removePhoto }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
