export enum ObjectSituationLabels {
  IN_OWNER = 'En propietario',
  SAVED = 'Guardado validado',
  SAVED_NOT_VALIDATED = 'Guardado no validado',
  LOCATING = 'A ubicar',
  CATALOGING = 'En clasificación',
  TAKING_PHOTOS = 'Fotografiando',
  REMOVAL_REQUEST = 'En proceso de devolución',
  SALE_PREPARATION = 'En preparación PDV',
  LOST = 'Extraviado',
  FOUND_AFTER_LOST = 'Encontrado',
  UNDER_REPAIR = 'En reparación',
  REPAIRED = 'Reparado',
  DONATION_REQUEST = 'Solicitud de donacion',
  DONATED = 'Donado',
  FORCED_DONATION = 'Donacion forzada',
  SALES_REQUEST = 'En solicitud de venta',
  SALES_REQUEST_R = 'En solicitud de venta R',
  SALES_REQUEST_CP = 'En solicitud de venta CP',
  SALES_PRODUCTION = 'En producción PDV',
  SALE = 'En venta',
  SOLD_OUT = 'Vendido',
  FORCED_SALE = 'Venta forzada',
  RECYCLING_REQUEST = 'Solicitud de reciclado',
  RECYCLED = 'Reciclado',
  FORCED_RECYCLED = 'Reciclado forzado'
}

export type ObjectSituationKeys = keyof typeof ObjectSituationLabels
