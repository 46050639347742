import { Reducer } from 'redux'
import {
  BILL_PERIOD_FAILURE,
  BILL_PERIOD_REQUEST,
  BILL_PERIOD_SUCCESS,
  BillPeriodModalAction,
  BillPeriodState,
  GET_BILLINGS_TO_BILL_FAILURE,
  GET_BILLINGS_TO_BILL_REQUEST,
  GET_BILLINGS_TO_BILL_SUCCESS,
  OPEN_MODAL_BILL_PERIOD
} from '../types/billPeriod'

const initialState: BillPeriodState = {
  modalOpen: false,
  error: '',
  loadingBillingToBill: false,
  loadingBillPeriod: false,
  billingToBillTotal: 0
}

const BillPeriodModalReducer: Reducer<BillPeriodState, BillPeriodModalAction> = (
  state = initialState,
  action
): BillPeriodState => {
  switch (action.type) {
    case OPEN_MODAL_BILL_PERIOD:
      return {
        ...state,
        modalOpen: action.payload.modalOpen
      }
    case GET_BILLINGS_TO_BILL_REQUEST:
      return {
        ...state,
        loadingBillingToBill: true
      }
    case GET_BILLINGS_TO_BILL_SUCCESS:
      return {
        ...state,
        loadingBillingToBill: false,
        billingToBillTotal: action.payload.billingToBillTotal
      }
    case GET_BILLINGS_TO_BILL_FAILURE:
      return {
        ...state,
        loadingBillingToBill: false,
        error: action.payload.error
      }
    case BILL_PERIOD_REQUEST:
      return {
        ...state,
        loadingBillPeriod: true
      }
    case BILL_PERIOD_SUCCESS:
      return {
        ...state,
        loadingBillPeriod: false,
        modalOpen: false
      }
    case BILL_PERIOD_FAILURE:
      return {
        ...state,
        loadingBillPeriod: false,
        error: action.payload.error,
        modalOpen: false
      }

    default:
      return state
  }
}

export default BillPeriodModalReducer
