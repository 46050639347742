import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

type serviceDeleteParams = {
  serviceId: string
}

export function deleteService({ serviceId }: serviceDeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_CATEGORIES}/service-types/${serviceId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
