import { STATUS_FILTERS } from './Activity.constants'

const getFullName = ({ name, last_name }) => name + ' ' + last_name

export const addFormatFields = (activity) =>
  activity.map((operation) => ({
    ...operation,
    user: {
      ...operation.user,
      fullName: getFullName(operation.user)
    }
  }))

export const formatStatusFiltersToOptions = () => STATUS_FILTERS.map(({ text, value }) => ({ value, label: text }))

export const getPaymentMethodOptions = (paymentMethods) =>
  paymentMethods.map(({ ID, Description }) => ({
    text: Description,
    value: ID
  }))
