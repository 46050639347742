import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'
import { Pagination, SearchFilter } from '../../../utils/searchFilterUtils'

export type DepositsTableSearchKey = 'id' | 'name' | 'owner_id'
export type DepositsSearchFilter = SearchFilter<DepositsTableSearchKey>

export type Filters = {
  id?: number
}

export type DepositListStateType = {
  fetchingDeposits: boolean
  deposits: Deposit[]
  pagination: Pagination
  searchFilters: DepositsSearchFilter[]
  filters: Filters
}

export enum GetDepositListTypes {
  GET_DEPOSIT_REQUEST = 'DEPOSIT/STORAGE_TABLE/GET_DEPOSIT_REQUEST',
  GET_DEPOSIT_SUCCESS = 'DEPOSIT/STORAGE_TABLE/GET_DEPOSIT_SUCCESS',
  GET_DEPOSIT_FAILURE = 'DEPOSIT/STORAGE_TABLE/GET_DEPOSIT_FAILURE'
}

export interface GetDepositListRequest {
  type: GetDepositListTypes.GET_DEPOSIT_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: DepositsSearchFilter[]
  }
}

export interface GetDepositListSuccess {
  type: GetDepositListTypes.GET_DEPOSIT_SUCCESS
  payload: {
    deposits: Deposit[]
    total: number
  }
}

export interface GetDepositListFailure {
  type: GetDepositListTypes.GET_DEPOSIT_FAILURE
  payload: {
    error: string
  }
}

type GetDepositListActions = GetDepositListRequest | GetDepositListSuccess | GetDepositListFailure

export enum SetterDepositTypes {
  SET_DEPOSIT_FILTERS = 'DEPOSIT/STORAGE_TABLE/SET_DEPOSIT_FILTERS'
}

export interface SetDepositFilters {
  type: SetterDepositTypes.SET_DEPOSIT_FILTERS
  payload: {
    id?: number
  }
}

export type SetterDepositActions = SetDepositFilters

export const RESET_FILTERS = 'DEPOSIT/STORAGE_TABLE/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export type DepositListActions = GetDepositListActions | SetterDepositActions | ResetFilters
