import React from 'react'
import { Modal } from 'antd'
import { UserABMTypes } from '../types/userABM'
import styles from './UserInviteModal.module.scss'
import InputGroup from '../../../../components/InputGroup'
import { Row } from '../../../NewProduct/components/subcomponents/GeneralData'

type UserDeleteModalProps = {
  userABMState: UserABMTypes
  closeModal: () => void
  onOkClick: () => Promise<void>
}

const UserDeleteModal = (props: UserDeleteModalProps) => {
  const { userABMState, closeModal, onOkClick } = props
  const { error, isLoading, isDeleteModalOpen, selectedUser } = userABMState

  return (
    <Modal
      width={560}
      title="Eliminar usuario"
      visible={isDeleteModalOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={closeModal}
      okButtonProps={{
        className: styles.modalButtonOk,
        disabled: isLoading
      }}
      okText={'Eliminar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <InputGroup title="Información del usuario" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Row className={styles.row}>
          <p className={styles.centerText}>{`¿Está seguro que desea eliminar el usuario: ${selectedUser?.email}?`}</p>
        </Row>
      </InputGroup>
      {error && <div className={styles.errorContainer}>{error}</div>}
    </Modal>
  )
}

export default UserDeleteModal
