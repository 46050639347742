import { find, isNil, pathOr, propEq, reduce } from 'ramda'
import { ITEM_TYPES_VALUES } from '../constants'
import {
  SelectedItem as EditDepositSelectedItem,
  ItemEditView
} from '../../../sections/Deposits/types/EditDepositViewTypes/items'
import { SelectedItem as ProposalSelectedItem } from '../../../sections/NewProposal/types/items'
import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'
import { BaseOption } from '../../../components/Select/Select'
import { Product as ProposalProduct } from '../../../projectApi/TransactionHandler/Proposals/getById'

const { round } = Math

type SelectedItem = ProposalSelectedItem | EditDepositSelectedItem
type Product = ItemEditView | FormattedProduct | ProposalProduct

export const getFormattedOptions = (items: Product[]): BaseOption<string>[] =>
  items.map((item) => ({
    value: item.guid || '',
    label: item.description || ''
  }))

export const getFormattedSelectedOption = (selectedItem: Product | null): BaseOption<string> | null =>
  !isNil(selectedItem) ? { value: selectedItem.guid, label: selectedItem.description } : null

export const getSelectedItem = (selectedItem: string, items: Product[]) => find(propEq('guid', selectedItem))(items)

export const getPricePerItem = (price: number, quantity: number): number => round(price * quantity * 100) / 100

const getTotalPrice = reduce(
  (accum, value: SelectedItem) =>
    accum +
    (value?.byProposal === false || value?.byProposal === undefined
      ? pathOr(0, ['selectedItem', 'priceRecalculate'], value)
      : pathOr(0, ['selectedItem', 'price'], value)) *
      value.quantity,
  0
)
export const getRoundedTotalPrice = (selectedItems: SelectedItem[]) =>
  Math.round(getTotalPrice(selectedItems) * 100) / 100

const getTotalM3 = reduce(
  (accum, value: SelectedItem) =>
    accum + (pathOr(0, ['selectedItem', 'volumeInCm3'], value) * value.quantity) / 1000000,
  0
)
export const getRoundedTotalM3 = (selectedItems: SelectedItem[]) => Math.round(getTotalM3(selectedItems) * 100) / 100

const getTotalKG = reduce(
  (accum, value: SelectedItem) => accum + (pathOr(0, ['selectedItem', 'weightInGr'], value) * value.quantity) / 1000,
  0
)
export const getRoundedTotalKG = (selectedItems: SelectedItem[]) => Math.round(getTotalKG(selectedItems) * 100) / 100

const getTotalPackagingPrice = reduce((accum, value: SelectedItem) => {
  if (value.packaging) {
    return accum + pathOr(0, ['selectedItem', 'packagingCostInCents'], value) * value.quantity
  } else {
    return accum
  }
}, 0)

const getTotalDisassemblePrice = reduce((accum, value: SelectedItem) => {
  if (value.disassemble) {
    return accum + pathOr(0, ['selectedItem', 'disassemblyCost'], value) * value.quantity
  } else {
    return accum
  }
}, 0)

const getTotalFloorsPrice = reduce((accum, value: SelectedItem) => {
  if (value.floors) {
    return (
      accum + pathOr(0, ['selectedItem', 'depositFloorsByStairsCostInCents'], value) * value.quantity * value.floors
    )
  } else {
    return accum
  }
}, 0)

export const getRoundedTotalPackagingPrice = (selectedItems: SelectedItem[]): number =>
  getTotalPackagingPrice(selectedItems) / 100
export const getRoundedTotalDisassemblePrice = (selectedItems: SelectedItem[]): number =>
  getTotalDisassemblePrice(selectedItems)
export const getRoundedTotalFloorsPrice = (selectedItems: SelectedItem[]): number =>
  getTotalFloorsPrice(selectedItems) / 100

export const getTotalItems = (selectedItems: SelectedItem[]): number =>
  selectedItems.reduce((accum, value) => (value.selectedItem ? accum + value.quantity : accum), 0)

export const formatItemsForDeposit = (items: SelectedItem[]) => {
  const data = items.reduce((accum: ItemAPI[], item) => {
    if (item.selectedItem === null) return accum
    const { packaging, disassemble, floors, byProposal } = item
    switch (item.type) {
      case ITEM_TYPES_VALUES.PRODUCT:
      default: {
        const { guid, disassemblyCost, depositFloorsByStairsCostInCents, packagingCostInCents, price } =
          item.selectedItem
        return [
          ...accum,
          {
            type: ITEM_TYPES_VALUES.PRODUCT,
            product_id: guid,
            amount: item.quantity,
            ...(packaging && {
              packaging_cost_in_cents: packagingCostInCents
            }),
            ...(disassemble && { disassembly_cost: disassemblyCost }),
            ...(floors && { cost_per_floor_in_cents: depositFloorsByStairsCostInCents * floors }),
            ...(byProposal && {
              price_in_cents: Math.round(price * 100),
              from_proposal: byProposal
            })
          }
        ]
      }
    }
  }, [])
  return { data }
}

type ItemAPI = {
  type: string
  product_id: string
  amount: number
  packaging_cost_in_cents?: number
  disassembly_cost?: number
  cost_per_floor_in_cents?: number
  price_in_cents?: number
  from_proposal?: boolean
}
