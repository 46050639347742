import React, { useEffect, useRef, useState } from 'react'
import { MenuAction } from '../actionMenu/baseMenu'
import styles from './DynamicTable.module.scss'
import { Column, Pagination, RowExpandedRenderer } from './types/types'
import HeaderCell from './subcomponents/HeaderCell/HeaderCell'
import { Checkbox, Pagination as PaginationComponent } from 'antd'
import { LoadingIndicator } from '../LoadingIndicator'
import clsx from 'clsx'
import { GenericObject } from '../../utils/utilityTypes'
import TableRow from './subcomponents/TableRow/TableRow'

type Props<T extends GenericObject, S extends number | string> = {
  rows: T[]
  error?: string
  columns: Column<T>[]
  loading?: boolean
  loadingRows?: S[]
  onDoubleClick?: (row: T) => void
  keyExtractor: (entity: T) => S
  actions?: MenuAction<T>[]
  customStrings?: {
    entityName?: string
    emptyState?: string | React.ReactNode
  }
  customClassNames?: {
    container?: string
    tableContainer?: string
    table?: string
    loadingContainer?: string
  }
  rowHeight?: number
  pagination?: Pagination
  rowExpander?: {
    render: RowExpandedRenderer<T>
    onExpansionClick?: (row: T) => void
    triggerFunctionOnce?: boolean
  }
  checkboxes?: {
    onRowCheckboxClick: (row: T) => void
    rowIsChecked: (row: T) => boolean
    rowIsDisabled?: (row: T) => boolean
    onHeaderCheckboxClick: (rows: T[]) => void
    headerIsChecked: (rows: T[]) => boolean
    headerIsDisabled?: (rows: T[]) => boolean
  }
  hint?: string
  customHeightOffset?: number
}

const DynamicTable = <T extends GenericObject, S extends number | string>({
  rows,
  loadingRows,
  columns,
  loading,
  error,
  customStrings,
  customClassNames,
  actions,
  pagination,
  onDoubleClick,
  keyExtractor,
  rowExpander,
  rowHeight,
  checkboxes,
  hint,
  customHeightOffset
}: Props<T, S>) => {
  const topScrollPointRef = useRef<HTMLTableElement>(null)
  const [currentlyExpandedRow, setCurrentlyExpandedRow] = useState<string | number | null>(null)
  const colsWithoutHidden = columns.filter((col) => !col.hidden)

  const checkboxColDimensions = checkboxes ? 'min-content' : ''
  const expandButtonColDimensions = rowExpander ? 'min-content' : ''

  const dataColsDimensions = colsWithoutHidden
    .map((col) => `minmax(${col.minWidth || 'min-content'}, ${col.maxWidth || '1fr'})`)
    .join(' ')

  const actionsColDimensions = actions?.length ? 'min-content' : ''

  const gridTemplateColumns = `${checkboxColDimensions} ${expandButtonColDimensions} ${dataColsDimensions} ${actionsColDimensions}`

  const getActionsTh = () => {
    if (!actions?.length) return null

    // @ts-ignore
    return <th />
  }

  const getExpanderTh = () => {
    if (!rowExpander) return null

    // @ts-ignore
    return <th style={{ paddingRight: checkboxes && 0 }} />
  }

  const getCheckboxesTh = () => {
    if (!checkboxes) return null

    // @ts-ignore
    const Th = ({ children }) => <th>{children}</th>

    return (
      <Th>
        <Checkbox
          checked={checkboxes.headerIsChecked(rows)}
          onChange={() => checkboxes.onHeaderCheckboxClick(rows)}
          disabled={checkboxes.headerIsDisabled ? checkboxes.headerIsDisabled(rows) : false}
        />
      </Th>
    )
  }

  const handlePagination = (newPage: number) => {
    if (!pagination) return
    pagination.onPageChange(newPage)
    setCurrentlyExpandedRow(null)
  }

  useEffect(() => {
    // timeout is meant to hide sudden scroll change behind loading animation
    const timeout = setTimeout(() => {
      if (pagination?.currentPage) topScrollPointRef?.current?.scrollTo(0, 0)
    }, 50)

    return () => {
      clearTimeout(timeout)
    }
  }, [pagination?.currentPage])

  return (
    <div className={clsx(styles.container, customClassNames?.container)}>
      <div className={clsx(styles.tableContainer, customClassNames?.tableContainer)}>
        {hint && (
          <div className={styles.hint}>
            <div className={styles.hintContents}>
              <span className={styles.hintPlaceholder}>?</span>
              <span className={styles.actualHint}>{hint}</span>
            </div>
          </div>
        )}
        <table
          ref={topScrollPointRef}
          className={clsx(styles.table, customClassNames?.table)}
          style={{
            gridTemplateColumns,
            pointerEvents: loading ? 'none' : 'all',
            maxHeight: customHeightOffset ? `calc(100dvh - ${customHeightOffset}px)` : ''
          }}>
          <thead>
            <tr>
              {getExpanderTh()}
              {getCheckboxesTh()}
              {colsWithoutHidden.map((col) => (
                <HeaderCell
                  column={col}
                  key={String(col.key)}
                  className={clsx(col.noPadding && styles.noPadding, col.alignment && styles[col.alignment])}
                />
              ))}
              {getActionsTh()}
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td className={styles.errorState} style={{ color: 'red' }}>
                  {error}
                </td>
              </tr>
            ) : rows.length ? (
              rows.map((row, i) => {
                const rowKey = keyExtractor(row)
                return (
                  <TableRow
                    rowHeight={rowHeight}
                    expandedRowOpen={rowKey === currentlyExpandedRow}
                    setExpandedRowOpen={(expanded) => {
                      setCurrentlyExpandedRow(expanded ? rowKey : null)
                    }}
                    loading={loadingRows?.includes(rowKey) || false}
                    key={rowKey}
                    rowExpander={rowExpander}
                    checkbox={
                      checkboxes && {
                        isChecked: checkboxes.rowIsChecked,
                        onCheckboxClick: checkboxes.onRowCheckboxClick,
                        isDisabled: checkboxes.rowIsDisabled
                      }
                    }
                    columns={colsWithoutHidden}
                    row={row}
                    indexRow={i}
                    actions={actions}
                    isEven={i % 2 === 0}
                    onDoubleClick={onDoubleClick ? (r) => onDoubleClick(r) : undefined}
                  />
                )
              })
            ) : (
              !loading && (
                <tr>
                  <td className={styles.emptyState}>
                    {customStrings?.emptyState || 'No hay resultados que coincidan con tu búsqueda.'}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <div
          className={clsx(
            styles.loadingContainer,
            loading && styles.activeLoadingContainer,
            customClassNames?.loadingContainer
          )}>
          <LoadingIndicator />
        </div>
      </div>
      {pagination && !loading && (
        <div className={styles.bottomBar}>
          {pagination.total > pagination.pageSize ? (
            <PaginationComponent
              current={pagination.currentPage}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handlePagination}
              showTotal={() => `Total: ${pagination.total} ${customStrings?.entityName || 'registros'}`}
              size={'small'}
            />
          ) : (
            !loading && `Total: ${pagination.total} ${customStrings?.entityName || 'registros'}`
          )}
        </div>
      )}
    </div>
  )
}

export default DynamicTable
