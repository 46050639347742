import { User } from '../../projectApi/AccountManager/Users/common'
import { FormattedProduct } from '../../projectApi/CategoryCreation/Product/common'
import { FormattedObject } from '../../projectApi/ObjectAdministration/common'
import { ObjectSituationKeys } from '../Objects/constants/objectSituations'

type BaseField<T> = {
  enabled: boolean
  value: T
}

export type ProductDimensions = {
  heightInCm: number
  widthInCm: number
  lengthInCm: number
  weightInGr: number
}

type NameField = BaseField<string>
type DescriptionField = BaseField<string>
type ObjectSituationField = BaseField<ObjectSituationKeys | null>
type ObjectSituationReasonField = BaseField<string>
type ObjectStatus = BaseField<string>
type RentEnabledField = BaseField<boolean>
type ProductField = BaseField<FormattedProduct | null> & {
  products: FormattedProduct[]
  loadingProducts: boolean
  lastSearchTimestamp: number
  dimensions: ProductDimensions
}
type OwnerField = BaseField<string> & {
  loadingUsers: boolean
  user: User | null
  alreadySearchedOnce: boolean
}

export type Fields = {
  name: NameField
  description: DescriptionField
  objectSituation: ObjectSituationField
  objectSituationReason: ObjectSituationReasonField
  objectStatus: ObjectStatus
  rentEnabled: RentEnabledField
  product: ProductField
  owner: OwnerField
}

export interface MassiveObjectsEditState {
  open: boolean
  objects: FormattedObject[]
  fields: Fields
  loadingConfirmation: boolean
  error: string
}

export const SET_OPEN = 'OBJECTS/MASSIVE_EDIT/SET_OPEN'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    objects?: FormattedObject[]
  }
}

export const ENABLE_ITEM = 'OBJECTS/MASSIVE_EDIT/ENABLE_ITEM'

export interface EnableItem {
  type: typeof ENABLE_ITEM
  payload: { enabled: boolean; key: keyof Fields }
}

export const MODIFY_ITEM = 'OBJECTS/MASSIVE_EDIT/MODIFY_ITEM'

export interface ModifyItem<T = string | boolean | ObjectSituationKeys | null> {
  type: typeof MODIFY_ITEM
  payload: { value: T; key: keyof Fields }
}

export const SEARCH_PRODUCTS_REQUEST = 'OBJECTS/MASSIVE_EDIT/SEARCH_PRODUCTS_REQUEST'
export const SEARCH_PRODUCTS_SUCCESS = 'OBJECTS/MASSIVE_EDIT/SEARCH_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS_FAILURE = 'OBJECTS/MASSIVE_EDIT/SEARCH_PRODUCTS_FAILURE'

export interface SearchProductsRequest {
  type: typeof SEARCH_PRODUCTS_REQUEST
  payload: {
    lastSearchTimestamp: number
  }
}
export interface SearchProductsSuccess {
  type: typeof SEARCH_PRODUCTS_SUCCESS
  payload: {
    lastSearchTimestamp: number
    products: FormattedProduct[]
  }
}
export interface SearchProductsFailure {
  type: typeof SEARCH_PRODUCTS_FAILURE
  payload: {
    error: string
  }
}

type SearchProductsAction = SearchProductsRequest | SearchProductsSuccess | SearchProductsFailure

export const SELECT_PRODUCT = 'OBJECTS/MASSIVE_EDIT/SELECT_PRODUCT'

export interface SelectProduct {
  type: typeof SELECT_PRODUCT
  payload: {
    product: FormattedProduct
  }
}

export const CLEAR_SELECTED_PRODUCTS = 'OBJECTS/MASSIVE_EDIT/CLEAR_SELECTED_PRODUCTS'

export interface ClearSelectedProducts {
  type: typeof CLEAR_SELECTED_PRODUCTS
}

export const SET_PRODUCT_DIMENSION = 'OBJECTS/MASSIVE_EDIT/SET_PRODUCT_DIMENSION'

export interface SetProductDimension {
  type: typeof SET_PRODUCT_DIMENSION
  payload: {
    dimensionType: keyof ProductDimensions
    value: number
  }
}

export const CONFIRM_MASSIVE_EDIT_REQUEST = 'OBJECTS/MASSIVE_EDIT/CONFIRM_MASSIVE_EDIT_REQUEST'
export const CONFIRM_MASSIVE_EDIT_SUCCESS = 'OBJECTS/MASSIVE_EDIT/CONFIRM_MASSIVE_EDIT_SUCCESS'
export const CONFIRM_MASSIVE_EDIT_FAILURE = 'OBJECTS/MASSIVE_EDIT/CONFIRM_MASSIVE_EDIT_FAILURE'

export interface ConfirmMassiveEditRequest {
  type: typeof CONFIRM_MASSIVE_EDIT_REQUEST
}
export interface ConfirmMassiveEditSuccess {
  type: typeof CONFIRM_MASSIVE_EDIT_SUCCESS
}
export interface ConfirmMassiveEditFailure {
  type: typeof CONFIRM_MASSIVE_EDIT_FAILURE
  payload: {
    error: string
  }
}

type ConfirmMassiveEditAction = ConfirmMassiveEditRequest | ConfirmMassiveEditSuccess | ConfirmMassiveEditFailure

export const FIND_USER_REQUEST = 'OBJECTS/MASSIVE_EDIT/FIND_USER_REQUEST'
export const FIND_USER_SUCCESS = 'OBJECTS/MASSIVE_EDIT/FIND_USER_SUCCESS'
export const FIND_USER_FAILURE = 'OBJECTS/MASSIVE_EDIT/FIND_USER_FAILURE'

export interface FindUserRequest {
  type: typeof FIND_USER_REQUEST
}
export interface FindUserSuccess {
  type: typeof FIND_USER_SUCCESS
  payload: {
    user: User
  }
}
export interface FindUserFailure {
  type: typeof FIND_USER_FAILURE
  payload: {
    error: string
  }
}

type FindUserAction = FindUserRequest | FindUserSuccess | FindUserFailure

export type MassiveObjectsEditAction =
  | SetOpen
  | ModifyItem
  | EnableItem
  | SearchProductsAction
  | SelectProduct
  | SetProductDimension
  | ConfirmMassiveEditAction
  | FindUserAction
  | ClearSelectedProducts
