import styles from './Button.module.scss'
import React from 'react'
import clsx from 'clsx'
import { Icon, IconName } from './icons'
import { Spin } from 'antd'
import { Loading } from './Loading'

type ButtonMode = 'filled' | 'tonal' | 'outlined' | 'text'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | React.ReactNode
  className?: string
  leftIcon?: IconName
  rightIcon?: IconName
  compact?: boolean
  mode?: ButtonMode
  theme?: 'light' | 'dark'
  fullWidth?: boolean
  loading?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  className,
  label,
  leftIcon,
  rightIcon,
  compact,
  mode = 'filled',
  theme = 'dark',
  fullWidth,
  loading,
  ...props
}) => {
  return (
    <button
      className={clsx(
        styles.base,
        styles[mode],
        styles[theme],
        compact && styles.compact,
        fullWidth && styles.fullWidth,
        loading && styles.loading,
        className
      )}
      {...props}>
      {leftIcon && <Icon name={leftIcon} className={styles.icon} />}
      {label}
      {rightIcon && <Icon name={rightIcon} className={styles.icon} />}
      <div className={styles.loadingContainer}>
        <Spin
          size="small"
          style={{ display: 'flex', alignItems: 'center' }}
          indicator={<Loading mode={getLoadingMode({ theme, mode })} />}
        />
      </div>
    </button>
  )
}

const getLoadingMode = ({ theme, mode }: { theme: 'light' | 'dark'; mode: ButtonMode }) => {
  switch (mode) {
    case 'filled':
      return theme === 'light' ? 'dark' : 'light'
  }
  return theme
}
