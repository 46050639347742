import { AxiosResponse } from 'axios'
import { APIRequest, Request } from './common'
import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import moment from 'moment'

export interface APIResponse {
  requests: APIRequest[]
  total: number
}

export interface Response {
  requests: Request[]
  total: number
}

export type ListParams = {
  limit: number
  offset: number
  order?: 'asc' | 'desc'
  column?: string
  id?: number
  objectId?: number
  userId?: number
  countryId?: string
  statuses?: string
  requestType?: string
  showDemoUsers: boolean
}

export function list(params: ListParams): Promise<Response> {
  const { showDemoUsers, ...rest } = params
  const formattedParams = { ...snakecaseKeys(rest), country_code: undefined, test_requests: showDemoUsers }

  return apiPrivate
    .get(`${URL_BASE_SALES}/requests`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const requests = response.data.requests

      return {
        requests:
          camelcaseKeys(requests, { deep: true }).map((request) => ({
            ...request,
            createdAt: moment(request.createdAt),
            updatedAt: moment(request.updatedAt)
          })) ?? [],
        total: response.data.total
      }
    })
    .catch((error) => {
      throw error
    })
}
