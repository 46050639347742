import { Moment } from 'moment'
import { AppThunk } from '../../../store'
import { GetPickingsMetricsRequest, GetPickingsMetricsSuccess, GetPickingsMetricsTypes } from '../types/pickingsMetrics'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { sendToastNotificationError } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import { mapOfPickingsMetrics } from '../components/pickingsMetrics'

const PickingMetricsActionsCreator = {
  getMetrics:
    ({ dateFrom, dateTo }: { dateFrom?: Moment; dateTo?: Moment }): AppThunk =>
    async (dispatch) => {
      const request: GetPickingsMetricsRequest = {
        type: GetPickingsMetricsTypes.GET_PICKINGS_METRICS_REQUEST
      }

      dispatch(request)
      try {
        const response = await API.TransactionHandler.Picking.metrics({
          dateFrom,
          dateTo
        })

        const quantityMetrics = Object.entries(response).map(([key, value]) => ({
          id: key,
          metric: mapOfPickingsMetrics[key],
          quantity: value
        }))

        const success: GetPickingsMetricsSuccess = {
          type: GetPickingsMetricsTypes.GET_PICKINGS_METRICS_SUCCESS,
          payload: { quantityMetrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(error, GetPickingsMetricsTypes.GET_PICKINGS_METRICS_FAILURE, dispatch)
      }
    }
}

export default PickingMetricsActionsCreator
