import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import { OverrideProps } from '../../../utils/utilityTypes'
import { convertToMoment } from '../../utils'

interface APIState {
  available: boolean
  create_at?: string
  deleted_at?: string
  descriptions: string
  qualified: string
  state_id: number
  update_at?: string
}

interface APISource {
  available: boolean
  created_at?: string
  deleted_at?: string
  id: number
  spanish_display: string
  updated_at?: string
}

interface APIProspectType {
  descriptions: string
  id: number
  name: string
}

interface APIProposal {
  created_at?: string
  id: number
  prospect_id: number
  provider_id: string
}

interface APIInterestReason {
  id: number
  reason: string
}

interface APIAssignedAgent {
  created_at?: string
  deleted_at?: string
  email: string
  first_name: string
  id: number
  last_name: string
  role_id: number
  updated_at?: string
  user_guid: string
  user_id: number
}

export interface APIProspect {
  assigned_agent: APIAssignedAgent
  assigned_agent_id: number
  country: string
  country_code: string
  created_at?: string
  deleted_at?: string
  duplication_code: number
  email: string
  first_name: string
  gender: string
  id: number
  interest_reason: APIInterestReason
  interest_reason_id: number
  last_name: string
  name: string
  people_id: number
  phone: string
  proposals?: APIProposal[]
  prospect_type: APIProspectType
  prospect_type_id: number
  provider: string
  provider_id: string
  qualified: number
  source: APISource
  source_id: number
  state: APIState
  status_id: number
  status_id_new: number
  status_updated_at?: string
  updated_at?: string
  user_id: number
}

interface APIResponse {
  description?: APIProspect
  success: string
}

type CommonDateProps = 'createdAt' | 'deletedAt' | 'updatedAt'
type StateDateProps = 'createAt' | 'updateAt' | 'deletedAt'

type ProspectPropsToOmit = 'assignedAgent' | 'proposals' | 'source' | 'state'
type AssignedAgent = OverrideProps<CamelCasedPropertiesDeep<APIAssignedAgent>, CommonDateProps, Moment | null>
type Proposal = OverrideProps<CamelCasedPropertiesDeep<APIProposal>, 'createdAt', Moment | null>
type Source = OverrideProps<CamelCasedPropertiesDeep<APISource>, CommonDateProps, Moment | null>
type State = OverrideProps<CamelCasedPropertiesDeep<APIState>, StateDateProps, Moment | null>

export type Prospect = Omit<
  OverrideProps<CamelCasedPropertiesDeep<APIProspect>, CommonDateProps | 'statusUpdatedAt', Moment | null>,
  ProspectPropsToOmit
> & {
  assignedAgent: AssignedAgent
  proposals?: Proposal[]
  source: Source
  state: State
}

interface GetByIdParams {
  prospectId: number
}

export function getById({ prospectId }: GetByIdParams): Promise<Prospect> {
  return apiPrivate
    .get(`${URL_BASE_MERCURIO}/prospects/v1/${prospectId}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener el prospect')
      if (!response.data?.description) throw new Error('Respuesta incorrecta al obtener el prospect')
      const camelUnformattedProposal = camelcaseKeys(response.data?.description, { deep: true })
      return {
        ...camelUnformattedProposal,
        countryCode: camelUnformattedProposal.countryCode.toUpperCase(),
        createdAt: convertToMoment(camelUnformattedProposal.createdAt),
        updatedAt: convertToMoment(camelUnformattedProposal.updatedAt),
        deletedAt: convertToMoment(camelUnformattedProposal.deletedAt),
        statusUpdatedAt: convertToMoment(camelUnformattedProposal.statusUpdatedAt),
        assignedAgent: {
          ...camelUnformattedProposal.assignedAgent,
          createdAt: convertToMoment(camelUnformattedProposal.assignedAgent.createdAt),
          updatedAt: convertToMoment(camelUnformattedProposal.assignedAgent.updatedAt),
          deletedAt: convertToMoment(camelUnformattedProposal.assignedAgent.deletedAt)
        },
        source: {
          ...camelUnformattedProposal.source,
          createdAt: convertToMoment(camelUnformattedProposal.source.createdAt),
          updatedAt: convertToMoment(camelUnformattedProposal.source.updatedAt),
          deletedAt: convertToMoment(camelUnformattedProposal.source.deletedAt)
        },
        state: {
          ...camelUnformattedProposal.state,
          createAt: convertToMoment(camelUnformattedProposal.state.createAt),
          updateAt: convertToMoment(camelUnformattedProposal.state.updateAt),
          deletedAt: convertToMoment(camelUnformattedProposal.state.deletedAt)
        },
        proposals: camelUnformattedProposal.proposals?.map((obj) => {
          return { ...obj, createdAt: convertToMoment(obj.createdAt) }
        })
      }
    })
    .catch((err) => {
      throw err
    })
}
