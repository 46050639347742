import { Reducer } from 'redux'
import {
  BillingDiscountModalActions,
  BillingDiscountsModalState,
  SettersBillingDiscountsModalTypes,
  GetUserDiscountsTypes,
  GetUserPersonalInfoTypes,
  CreateNewDiscountTypes,
  DiscountType,
  GetDiscountReasonsTypes
} from '../types/billingDiscountsModal'

const initialState: BillingDiscountsModalState = {
  modalVisibility: false,
  deleteModalVisibility: false,
  creatingNewDiscount: false,
  deletingDiscount: false,
  userDiscounts: {
    data: [],
    fetching: false,
    errorMessage: ''
  },
  userPersonalInfo: {
    data: {},
    fetching: false,
    errorMessage: ''
  },
  discountReasons: {
    data: [],
    fetching: false,
    errorMessage: ''
  },
  newDiscountType: DiscountType.PERCENTAGE
}

const billingDiscountsModalReducer: Reducer<BillingDiscountsModalState, BillingDiscountModalActions> = (
  state = initialState,
  action
): BillingDiscountsModalState => {
  switch (action.type) {
    case SettersBillingDiscountsModalTypes.SET_MODAL_VISIBILITY: {
      const { modalVisibility, userId } = action.payload

      if (!modalVisibility) return initialState
      return {
        ...state,
        modalVisibility,
        userPersonalInfo: {
          ...state.userPersonalInfo,
          data: {
            ...state.userPersonalInfo,
            userId
          }
        }
      }
    }
    case SettersBillingDiscountsModalTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisibility, deleteDiscountId } = action.payload

      return {
        ...state,
        deleteModalVisibility,
        deleteDiscountId: deleteModalVisibility ? deleteDiscountId : undefined
      }
    }
    case SettersBillingDiscountsModalTypes.SET_NEW_DISCOUNT_TYPE:
      return {
        ...state,
        newDiscountType: action.payload.newDiscountType,
        newDiscountValue: undefined
      }
    case SettersBillingDiscountsModalTypes.SET_NEW_DISCOUNT_VALUE:
      return {
        ...state,
        newDiscountValue: action.payload.newDiscountValue
      }
    case SettersBillingDiscountsModalTypes.SET_DISCOUNT_REASON:
      return {
        ...state,
        selectedReason: action.payload.selectedReason
      }
    case SettersBillingDiscountsModalTypes.SET_DISCOUNT_DATE: {
      const { selectedStartDate, selectedEndDate } = action.payload
      if (state.selectedEndDate !== undefined && selectedStartDate?.isAfter(state.selectedEndDate)) {
        return {
          ...state,
          selectedStartDate,
          selectedEndDate: undefined
        }
      }

      return {
        ...state,
        selectedStartDate: selectedStartDate || state.selectedStartDate,
        selectedEndDate: selectedEndDate || state.selectedEndDate
      }
    }
    case SettersBillingDiscountsModalTypes.SET_CLEAN_STATE_FROM_CREATE:
      return {
        ...state,
        newDiscountValue: undefined,
        selectedReason: undefined,
        selectedStartDate: undefined,
        selectedEndDate: undefined
      }
    case GetUserDiscountsTypes.GET_USER_DISCOUNTS_REQUEST:
      return {
        ...state,
        userDiscounts: {
          ...state.userDiscounts,
          fetching: true
        }
      }
    case GetUserDiscountsTypes.GET_USER_DISCOUNTS_SUCCESS:
      return {
        ...state,
        userDiscounts: {
          ...state.userDiscounts,
          data: action.payload.userDiscounts,
          fetching: false
        }
      }
    case GetUserDiscountsTypes.GET_USER_DISCOUNTS_FAILURE:
      return {
        ...state,
        userDiscounts: {
          ...state.userDiscounts,
          fetching: false
        }
      }
    case GetUserPersonalInfoTypes.GET_USER_INFO_REQUEST:
      return {
        ...state,
        userPersonalInfo: {
          ...state.userPersonalInfo,
          fetching: true
        }
      }
    case GetUserPersonalInfoTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userPersonalInfo: {
          ...state.userPersonalInfo,
          data: action.payload.userPersonalInfo,
          fetching: false
        }
      }
    case GetUserPersonalInfoTypes.GET_USER_INFO_FAILURE:
      return {
        ...state,
        userPersonalInfo: {
          ...state.userPersonalInfo,
          fetching: false
        }
      }
    case GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_REQUEST:
      return {
        ...state,
        discountReasons: {
          ...state.discountReasons,
          fetching: true
        }
      }
    case GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_SUCCESS:
      return {
        ...state,
        discountReasons: {
          ...state.discountReasons,
          data: action.payload.discountReasons,
          fetching: false
        }
      }
    case GetDiscountReasonsTypes.GET_DISCOUNT_REASONS_FAILURE:
      return {
        ...state,
        discountReasons: {
          ...state.discountReasons,
          fetching: false
        }
      }
    case CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_REQUEST:
      return {
        ...state,
        creatingNewDiscount: true
      }
    case CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_SUCCESS:
    case CreateNewDiscountTypes.CREATE_NEW_DISCOUNT_FAILURE:
      return {
        ...state,
        creatingNewDiscount: false
      }
    default:
      return state
  }
}

export default billingDiscountsModalReducer
