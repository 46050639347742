import types from '../types/identificationTypes'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  getDocumentTypes() {
    return (dispatch) => {
      dispatch({ type: types.GET_IDENTIFICATION_TYPES_REQUEST })

      return apiPrivate
        .get(URL_BASE_USERS + '/users/identification-types/search')
        .then((response) => {
          const identificationTypes = response.data
          dispatch({
            type: types.GET_IDENTIFICATION_TYPES_SUCCESS,
            payload: { identificationTypes }
          })
          return true
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.GET_IDENTIFICATION_TYPES_FAILURE, dispatch)
          return false
        })
    }
  }
}

export default actions
