/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

interface AssignParams {
  transactionIds: number[]
  operatorIds: number[]
}

export function assignOperatorsToTransactions(params: AssignParams): Promise<void> {
  const data = {
    transactions_ids: params.transactionIds,
    operator_ids: params.operatorIds
  }
  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/transactions/assign-operators-to-transactions`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
