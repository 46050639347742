import { handleErrorsWithAction } from './../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import {
  CancelSaleModalTypes,
  CancelSaleRequest,
  CancelSaleSuccess,
  CleanState,
  CommonTypes,
  SetOpen
} from '../types/cancelSaleModal'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '@/projectApi'
import { salesRequestActionCreator } from './salesRequestTable'

type DataUser = {
  id: string
  name: string
  lastName: string
}

const CancelSaleModalActionCreator = {
  CancelSale:
    (id: number, reason: { id: number; text: string }): AppThunk =>
    async (dispatch) => {
      const request: CancelSaleRequest = {
        type: CancelSaleModalTypes.CANCEL_SALE_REQUEST
      }
      const toast = setToastLoading('Cancelando venta...')
      dispatch(request)

      try {
        await API.Sales.SalesRequest.cancel({ id, reason })

        const success: CancelSaleSuccess = {
          type: CancelSaleModalTypes.CANCEL_SALE_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Venta cancelada, no olvide cancelar la publicación' })
        dispatch(CancelSaleModalActionCreator.setOpenModal(false))
        dispatch(salesRequestActionCreator.refreshTableData())
      } catch (err) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(err, CancelSaleModalTypes.CANCEL_SALE_FAILURE, dispatch)
        })
      }
    },
  setOpenModal: (isOpenModal: boolean, data?: DataUser): SetOpen => ({
    type: CommonTypes.SET_OPEN,
    payload: {
      isOpen: isOpenModal,
      name: data?.name,
      lastName: data?.lastName,
      id: data?.id
    }
  }),
  cleanState: (): CleanState => ({
    type: CommonTypes.CLEAN_STATE
  })
}

export default CancelSaleModalActionCreator
