import React from 'react'
import InputGroupAddoneStyle from './InputGroupAddon.module.scss'

const InputGroupAddon = ({ children, width }: { children: any; width?: number }) => {
  return (
    <div style={{ width: width }} className={InputGroupAddoneStyle.container}>
      {children}
    </div>
  )
}

export default InputGroupAddon
