import { Reducer } from 'redux'
import { STEP_OPTION } from '../constants'
import {
  ReasonsModalActions,
  ReasonsModalState,
  SET_CHECK_SELECTED,
  SET_DISCARD_REASON,
  SET_ERROR_TEXT_REASON,
  SET_INTEREST_REASON,
  SET_LACK_INTEREST_REASON,
  SET_LOSE_REASON,
  SET_MODAL_OPEN,
  SET_OPEN_INPUT_TEXT,
  SET_TEXT_REASON,
  UPDATED_LEAD,
  UPDATED_LEAD_FAILURE,
  UPDATED_LEAD_SUCCESS
} from '../types/ReasonsModal'

const initialState: ReasonsModalState = {
  openModal: false,
  loading: false,
  error: '',
  interestReason: '',
  textReason: {
    inputOpen: false,
    value: '',
    errorMessage: '',
    limitText: 50
  },
  loseReason: '',
  discardReason: '',
  disabledSelect: false,
  optionSelected: STEP_OPTION.NOT_SELECTED,
  leadId: 0,
  leadFunnelState: '',
  lackInterestReason: ''
}

const ReasonsModalReducer: Reducer<ReasonsModalState, ReasonsModalActions> = (
  state = initialState,
  action
): ReasonsModalState => {
  switch (action.type) {
    case SET_MODAL_OPEN: {
      const { openModal, leadId, leadFunnelState } = action.payload
      return openModal
        ? {
            ...state,
            openModal,
            leadId: leadId ?? initialState.leadId,
            leadFunnelState
          }
        : initialState
    }
    case SET_INTEREST_REASON:
      return {
        ...state,
        interestReason: action.payload.interestReason
      }
    case SET_TEXT_REASON:
      return {
        ...state,
        textReason: { ...state.textReason, value: action.payload.textReason }
      }
    case SET_OPEN_INPUT_TEXT: {
      const { disabledSelect } = state
      const { inputOpen } = action.payload
      return inputOpen
        ? {
            ...state,
            disabledSelect: !disabledSelect,
            loseReason: initialState.loseReason,
            textReason: { ...state.textReason, inputOpen }
          }
        : { ...state, disabledSelect: !disabledSelect, textReason: { ...initialState.textReason } }
    }
    case SET_ERROR_TEXT_REASON:
      return {
        ...state,
        textReason: { ...state.textReason, errorMessage: action.payload.messageError }
      }
    case SET_LACK_INTEREST_REASON:
      return {
        ...state,
        lackInterestReason: action.payload.lackInterestReason
      }
    case SET_LOSE_REASON:
      return {
        ...state,
        loseReason: action.payload.loseReason
      }
    case SET_DISCARD_REASON:
      return {
        ...state,
        discardReason: action.payload.discardReason
      }
    case SET_CHECK_SELECTED: {
      const { disabledSelect } = state
      return disabledSelect
        ? {
            ...state,
            loseReason: initialState.loseReason,
            disabledSelect: !disabledSelect
          }
        : {
            ...state,
            loseReason: action.payload.loseReason,
            disabledSelect: !disabledSelect
          }
    }
    case UPDATED_LEAD:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case UPDATED_LEAD_SUCCESS:
      return initialState
    case UPDATED_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      }
    default:
      return state
  }
}

export default ReasonsModalReducer
