import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_REQUESTS_FAILURE,
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  GetRequestsRequest,
  GetRequestsSuccess,
  Pagination,
  RequestsCategoryFilter,
  RequestsSearchFilter,
  RequestsTableSort
} from '../types/requestsTable'

import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

export const requestsActionCreator = {
  getRequests:
    ({
      pagination,
      type,
      searchFilters,
      categoryFilter,
      sort,
      countryId,
      showDemoUsers
    }: {
      pagination: Pagination
      showDemoUsers: boolean
      type?: string
      searchFilters?: RequestsSearchFilter[]
      categoryFilter?: RequestsCategoryFilter
      sort?: RequestsTableSort
      countryId?: CountryIdCode
    }): AppThunk =>
    async (dispatch) => {
      const request: GetRequestsRequest = {
        type: GET_REQUESTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          type,
          searchFilters,
          categoryFilter,
          sort,
          showDemoUsers
        }
      }

      dispatch(request)
      const statuses = categoryFilter?.status.join()
      const requestType = categoryFilter?.type[0]

      try {
        const { requests, total } = await API.Sales.Requests.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          order: sort?.direction,
          column: sort?.field,
          requestType,
          showDemoUsers,
          ...(statuses && { statuses }),
          ...(countryId && { countryId }),
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetRequestsSuccess = {
          type: GET_REQUESTS_SUCCESS,
          payload: {
            requests,
            total
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REQUESTS_FAILURE, dispatch)
      }
    }
}
