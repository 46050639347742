import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { formatItemsForDeposit, getRoundedTotalM3 } from '../../../common/operations/selectors/items'
import itemsActions from './items'
import { PRICE_BY, TransportTypesPricing } from '../constants'
import { Proposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { AppThunk } from '../../../store'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { TransportTypes } from '../types/transport'
import { TransportModeResponse } from '../../../projectApi/TransactionHandler/TransportMode/getByService'
import { Deposit, DepositsResponse } from '../../../projectApi/TransactionHandler/Deposits/getByTransportAndService'
import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'
import { Services } from '../../../projectApi/TransactionHandler/ServiceType/common'
import { ServiceTypeResponse } from '../../../projectApi/TransactionHandler/ServiceType/list'
import Emitter from '../../../utils/eventEmitter'
import { FlagsState } from '../reducers/flags'
import { Events } from '../../../utils/eventEmitter/events'

const getTransportCostMultiplier = (transportTypePricing: TransportTypesPricing, flagsState: FlagsState): number => {
  switch (transportTypePricing) {
    case TransportTypesPricing.CUSTOM:
      return flagsState.customDateTransportMult
    case TransportTypesPricing.FREE:
      return 0
    case TransportTypesPricing.COMMON:
    default:
      return 1
  }
}

const actions = {
  getServiceTypes(countryCode: CountryIdCode): AppThunk {
    return async function (dispatch) {
      dispatch({ type: TransportTypes.GET_SERVICE_TYPES_REQUEST })

      try {
        const { services } = await API.CategoryCreation.ServiceTypes.search({
          limit: 1000,
          offset: 0,
          displayOrder: 1
        })
        dispatch(actions.getServiceTypesSuccess(services, countryCode))
      } catch (error) {
        handleErrorsWithAction(error, TransportTypes.GET_SERVICE_TYPES_FAILURE, dispatch)
      }
    }
  },

  getServiceTypesSuccess:
    (serviceTypes: Services[], countryCode: CountryIdCode): AppThunk =>
    (dispatch) => {
      const selectedServiceType = serviceTypes[0]

      dispatch({
        type: TransportTypes.GET_SERVICE_TYPES_SUCCESS,
        payload: { serviceTypes }
      })
      dispatch(actions.setServiceType({ newServiceType: selectedServiceType, actualServiceType: '', countryCode }))
    },

  resetItemsBySolution:
    ({ resetInitialItems = true }: { resetInitialItems?: boolean }): AppThunk =>
    (dispatch) => {
      dispatch({
        type: TransportTypes.RESET_ITEMS_BY_SOLUTION,
        payload: { resetInitialItems }
      })
    },
  setServiceType:
    ({
      newServiceType,
      actualServiceType,
      countryCode
    }: {
      newServiceType: Services
      actualServiceType: string
      countryCode: CountryIdCode
    }): AppThunk =>
    (dispatch) => {
      const newServiceTypeID = newServiceType.id
      dispatch({
        type: TransportTypes.SET_SERVICE_TYPE,
        payload: { serviceType: newServiceType.id, multiplier: newServiceType.multiplier }
      })
      if (newServiceTypeID !== actualServiceType) {
        dispatch(itemsActions.getInitialItem({ countryCode, serviceTypeId: newServiceTypeID }))
        dispatch(actions.getTransportModeByService(newServiceTypeID, countryCode))
      }
    },

  getTransportModeByService(priceBy = PRICE_BY, countryCode: CountryIdCode): AppThunk {
    return async function (dispatch) {
      dispatch({
        type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST,
        payload: { removeSelectedDeposit: true }
      })

      try {
        const { transportMode } = await API.TransactionHandler.TransportMode.getByService(priceBy, { countryCode })
        dispatch(
          actions.getTransportModeByServiceSuccess({
            priceBy,
            transportModes: transportMode,
            countryCode
          })
        )
      } catch (error) {
        handleErrorsWithAction(error, TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_FAILURE, dispatch)
      }
    }
  },

  getTransportModeByServiceSuccess:
    ({
      priceBy,
      transportModes,
      countryCode
    }: {
      priceBy?: string
      transportModes: TransportMode[]
      countryCode: CountryIdCode
    }): AppThunk =>
    (dispatch) => {
      const selectedTransport = transportModes[0]

      dispatch({
        type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS,
        payload: { priceBy, transportModes }
      })
      if (selectedTransport)
        dispatch(
          actions.setTransportMode({
            priceBy,
            transportModeID: selectedTransport.id,
            countryCode
          })
        )
    },

  setTransportMode:
    ({
      priceBy = PRICE_BY,
      transportModeID,
      countryCode
    }: {
      priceBy?: string
      transportModeID: number
      countryCode: CountryIdCode
    }): AppThunk =>
    (dispatch, getState) => {
      const transportModes = getState().NewProposal.transport.transportModes
      const transportMode = transportModes.find(({ id }) => transportModeID === id)
      dispatch({
        type: TransportTypes.SET_TRANSPORT_MODE,
        payload: {
          transportMode: transportModeID,
          requireAddressOrigin: transportMode?.requireAddressOrigin
        }
      })
      dispatch(
        actions.getDepositByTransportAndService({
          priceBy,
          transportMode: transportModeID,
          countryCode
        })
      )
    },

  getDepositByTransportAndService({
    priceBy = PRICE_BY,
    transportMode,
    countryCode
  }: {
    priceBy?: string
    transportMode: number
    countryCode: CountryIdCode
  }): AppThunk {
    return async function (dispatch) {
      dispatch({ type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST })

      const params = {
        countryCode
      }
      try {
        const { deposits } = await API.TransactionHandler.Deposits.getByTransportAndService(
          priceBy,
          transportMode,
          params
        )
        dispatch(actions.getDepositByTransportAndServiceSuccess(deposits))
      } catch (error) {
        handleErrorsWithAction(error, TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE, dispatch)
      }
    }
  },

  getDepositByTransportAndServiceSuccess:
    (deposits: Deposit[]): AppThunk =>
    (dispatch) => {
      const selectedDeposit = deposits[0] || null

      dispatch({
        type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS,
        payload: { deposits }
      })
      dispatch(actions.setDeposit(selectedDeposit?.id || null))
      dispatch(fetchCost)
    },

  setDeposit: (deposit: number | null) => ({ type: TransportTypes.SET_DEPOSIT, payload: { deposit } }),
  setCustomTransportType: (transportTypePricing: TransportTypesPricing, newCost: number) => ({
    type: TransportTypes.SET_CUSTOM_TRANSPORT_COST,
    payload: { transportTypePricing, newCost }
  }),

  getTransportModesAndDepositsFromProposal:
    (proposal: Proposal): AppThunk =>
    (dispatch, getState) => {
      const { transportModeId, type } = proposal

      const countryCode = getState().NewProposal.prospect.selectedProspect?.countryCode as CountryIdCode

      const params = {
        countryCode
      }

      const requests = [
        API.TransactionHandler.ServiceType.list(params),
        API.TransactionHandler.TransportMode.getByService(type, params),
        API.TransactionHandler.Deposits.getByTransportAndService(type, transportModeId, params)
      ]
      dispatch({
        type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST,
        payload: { removeSelectedDeposit: false }
      })
      dispatch({ type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST })
      // @ts-ignore
      return Promise.all(requests).then((responses) => {
        const serviceTypes = responses[0] as ServiceTypeResponse
        const transportModes = responses[1] as TransportModeResponse
        const deposits = responses[2] as DepositsResponse

        dispatch({
          type: TransportTypes.GET_SERVICE_TYPES_SUCCESS,
          payload: { serviceTypes: serviceTypes.services }
        })
        dispatch({
          type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS,
          payload: { transportModes: transportModes.transportMode }
        })
        dispatch({
          type: TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS,
          payload: { deposits: deposits.deposits }
        })

        const selectedTransportMode = transportModes.transportMode.find(({ id }) => transportModeId === id)
        dispatch({
          type: TransportTypes.SET_TRANSPORT_MODE,
          payload: {
            transportMode: transportModeId,
            requireAddressOrigin: selectedTransportMode?.requireAddressOrigin
          }
        })
        dispatch(itemsActions.getInitialItem({ countryCode, serviceTypeId: type, fromProposal: true }))
        setTimeout(() => {
          Emitter.emit(Events.Proposal.GET_PROPOSAL)
        }, 250)
      })
    },

  fetchCost: (where: string): AppThunk => {
    return async function (dispatch, getState) {
      dispatch({ type: TransportTypes.FETCH_COST_REQUEST })

      const state = getState().NewProposal
      const itemsState = state.items
      const transportState = state.transport
      const flagsState = state.flags
      const destination = where
      const items = JSON.stringify(formatItemsForDeposit(itemsState.selectedItems))
      const totalVolume = getRoundedTotalM3(itemsState.selectedItems)
      const country = state.prospect.selectedProspect?.countryCode || CountryIdCode.ARGENTINA

      try {
        const { price: priceParam, rule: transportDetail } = await API.TransactionHandler.Compute.cost({
          destination,
          type: PRICE_BY,
          items,
          countryCode: country,
          m3: totalVolume
        })
        const customTransportMult = getTransportCostMultiplier(transportState.transportTypePricing, flagsState)

        const price = Math.ceil(priceParam)
        const priceWithMult = Math.ceil(priceParam * customTransportMult)

        dispatch({
          type: TransportTypes.FETCH_COST_SUCCESS,
          payload: {
            fetchedCost: price,
            cost: priceWithMult,
            where,
            transportDetail
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, TransportTypes.FETCH_COST_FAILURE, dispatch)
      }
    }
  }
}

export const fetchCost: AppThunk = (dispatch, getState) => {
  const { where, requireAddressOrigin, transportTypePricing, fetchedCost } = getState().NewProposal.transport

  const fetchTransportForFirstTime = transportTypePricing === TransportTypesPricing.FREE && fetchedCost === 0

  if ((where && requireAddressOrigin) || fetchTransportForFirstTime) {
    dispatch(actions.fetchCost(where))
  }
}

export default actions
