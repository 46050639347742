import React from 'react'
import Select from '../../../../components/Select'
import moment, { Moment } from 'moment'
import { Col, Row } from 'antd'
import styles from './OperationDate.module.scss'
import 'moment/locale/es'
import InputGroup from '../../../../components/InputGroup'
import {
  getFormatFirstTimeInterval,
  getFormatFirstTimeslot,
  getFormattedIntervalOptions,
  getFormattedOptions
} from './utils'
import { InputProps, TimeInterval, Timeslot } from './types'
import CalendarTextField from '@/components/Calendar/CalendarTextField'
import { CalendarDay, CalendarInitialStateDay } from '@/components/Calendar/calendar.types'

export interface CalendarTextFieldProps {
  currentMonth: Moment
  localDate: CalendarInitialStateDay | null
  anticipationsDate: Moment
  grid: CalendarDay[]
  loading: boolean
  handleChangeMonth: (direction: 'next' | 'prev') => void
  handleDayPress: (day: CalendarDay) => void
}

interface OperationDateProps {
  calendar: {
    datetime: Moment | null
    timeslots: InputProps<Timeslot, number>
    timeIntervals: InputProps<TimeInterval, number>
  }
  calendarActions: {
    setTimeslot: (timeslot: number) => void
    setTimeInterval: (timeInterval: number) => void
  }
  calendarTextFieldProps: CalendarTextFieldProps
}

const OperationDate = ({ calendarTextFieldProps, calendar, calendarActions }: OperationDateProps) => {
  const { timeslots, timeIntervals, datetime } = calendar
  const { setTimeInterval, setTimeslot } = calendarActions

  const timeslotsOptions = getFormattedOptions(timeslots.list)
  const timeIntervalsOptions = getFormattedIntervalOptions(timeIntervals.list)

  return (
    <InputGroup title={'Fecha y Hora de Colecta'}>
      <Row gutter={[16, 16]} className={styles.inputContainer}>
        <Col sm={24} md={8}>
          <p className={styles.inputTitle}>Día de depósito</p>
          <CalendarTextField {...calendarTextFieldProps} transportBasePrice={0} showDiscount={false} isDeposit />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <p className={styles.inputTitle}>Horario</p>
          <Select
            placeholder="Horario"
            selected={getFormatFirstTimeslot(timeslots.selected, timeslots.list, datetime || moment())}
            options={timeslotsOptions}
            loading={timeslots.loading}
            onSelect={(option) => setTimeslot(option.value)}
            position={'top'}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <p className={styles.inputTitle}>Franja Horaria</p>
          <Select
            placeholder="Franja Horaria"
            selected={getFormatFirstTimeInterval(timeIntervals.selected, timeIntervals.list)}
            options={timeIntervalsOptions}
            loading={timeIntervals.loading}
            onSelect={(option) => setTimeInterval(option.value)}
            position={'top'}
          />
          {timeIntervals.errorMessage && <p className={styles.inputError}>{timeIntervals.errorMessage}</p>}
        </Col>
      </Row>
    </InputGroup>
  )
}

export default OperationDate
