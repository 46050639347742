import { list } from './list'
import { create } from './create'
import { edit } from './edit'
import { deleteLocation } from './delete'
import { metrics } from './metrics'

export const CorporativeAPI = {
  create,
  delete: deleteLocation,
  edit,
  list,
  metrics
}
