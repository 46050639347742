import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { sendToastNotificationError } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import {
  GetUsersMetricsRequest,
  GetUsersMetricsSuccess,
  GetUsersMetricsTypes,
  MetricsParams
} from '../types/usersMetrics'

const usersMetricsActionsCreator = {
  getMetrics:
    (params: MetricsParams): AppThunk =>
    async (dispatch) => {
      const request: GetUsersMetricsRequest = {
        type: GetUsersMetricsTypes.GET_USERS_METRICS_REQUEST
      }
      dispatch(request)
      try {
        const response = await API.AccountManager.Users.metrics(params)

        const success: GetUsersMetricsSuccess = {
          type: GetUsersMetricsTypes.GET_USERS_METRICS_SUCCESS,
          payload: { usersMetrics: response }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(error, GetUsersMetricsTypes.GET_USERS_METRICS_FAILURE, dispatch)
      }
    }
}

export default usersMetricsActionsCreator
