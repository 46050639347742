import React from 'react'

export const ScanIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.83333 4.33333H4.84M12.1667 4.33333H12.1733M4.83333 11.6667H4.84M9.16667 8.66667H9.17333M12.1667 11.6667H12.1733M11.8333 14H14.5V11.3333M9.83333 11V14M14.5 9.33333H11.5M10.9 6.66667H13.4333C13.8067 6.66667 13.9934 6.66667 14.136 6.594C14.2614 6.53009 14.3634 6.4281 14.4273 6.30266C14.5 6.16005 14.5 5.97337 14.5 5.6V3.06667C14.5 2.6933 14.5 2.50661 14.4273 2.36401C14.3634 2.23856 14.2614 2.13658 14.136 2.07266C13.9934 2 13.8067 2 13.4333 2H10.9C10.5266 2 10.3399 2 10.1973 2.07266C10.0719 2.13658 9.96991 2.23856 9.906 2.36401C9.83333 2.50661 9.83333 2.6933 9.83333 3.06667V5.6C9.83333 5.97337 9.83333 6.16005 9.906 6.30266C9.96991 6.4281 10.0719 6.53009 10.1973 6.594C10.3399 6.66667 10.5266 6.66667 10.9 6.66667ZM3.56667 6.66667H6.1C6.47337 6.66667 6.66005 6.66667 6.80266 6.594C6.9281 6.53009 7.03009 6.4281 7.094 6.30266C7.16667 6.16005 7.16667 5.97337 7.16667 5.6V3.06667C7.16667 2.6933 7.16667 2.50661 7.094 2.36401C7.03009 2.23856 6.9281 2.13658 6.80266 2.07266C6.66005 2 6.47337 2 6.1 2H3.56667C3.1933 2 3.00661 2 2.86401 2.07266C2.73856 2.13658 2.63658 2.23856 2.57266 2.36401C2.5 2.50661 2.5 2.6933 2.5 3.06667V5.6C2.5 5.97337 2.5 6.16005 2.57266 6.30266C2.63658 6.4281 2.73856 6.53009 2.86401 6.594C3.00661 6.66667 3.1933 6.66667 3.56667 6.66667ZM3.56667 14H6.1C6.47337 14 6.66005 14 6.80266 13.9273C6.9281 13.8634 7.03009 13.7614 7.094 13.636C7.16667 13.4934 7.16667 13.3067 7.16667 12.9333V10.4C7.16667 10.0266 7.16667 9.83995 7.094 9.69734C7.03009 9.5719 6.9281 9.46991 6.80266 9.406C6.66005 9.33333 6.47337 9.33333 6.1 9.33333H3.56667C3.1933 9.33333 3.00661 9.33333 2.86401 9.406C2.73856 9.46991 2.63658 9.5719 2.57266 9.69734C2.5 9.83995 2.5 10.0266 2.5 10.4V12.9333C2.5 13.3067 2.5 13.4934 2.57266 13.636C2.63658 13.7614 2.73856 13.8634 2.86401 13.9273C3.00661 14 3.1933 14 3.56667 14Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
