import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../api'
import { URL_BASE_SALES } from '../../endpoints'

type APIResponse = APISaleStatusOptions

export type APISaleStatusOptions = {
  status: { id: number; name: string }[]
}
type Response = {
  status: SaleStatusOptions[]
}

export type SaleStatusOptions = {
  id: number
  name: string
}
export function status(): Promise<Response> {
  return apiPrivate.get(`${URL_BASE_SALES}/status`).then((response: AxiosResponse<APIResponse>) => {
    if (!response.data) throw new Error('Error al obtener los estados de las solicitudes')

    const res: Response = {
      status: response.data.status
    }

    return res
  })
}
