/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import { Either } from '../../../utils/utilityTypes'

type UpdateParams = {
  id: number
  interestReason?: string
  discardReason?: string
} & Either<{ transit?: TransitLead }, { updated?: UpdatedLead }>

interface TransitLead {
  loseReason?: string
  reactivateDiscard?: boolean
  lackInterestReason?: string
  textReason?: string
}

interface UpdatedLead {
  country?: string
  email?: string
  phone?: string
  gender?: string
  name?: string
  lastName?: string
  source?: string
  contactMedium?: string
  loseReasonMQL?: string
  loseReasonSQL?: string
}

export function update(lead: UpdateParams): Promise<void> {
  const formData = new FormData()
  const { id, interestReason, discardReason, transit, updated } = lead

  if (transit) {
    const { loseReason, reactivateDiscard, lackInterestReason, textReason } = transit

    if (interestReason) {
      formData.append('interest_reason_id', interestReason)
      formData.append('event', 'transit')
      formData.append('status', '13')
    }
    if (lackInterestReason) {
      formData.append('interest_reason_id', lackInterestReason)
    }
    if (reactivateDiscard) {
      formData.append('interest_reason_id', '0')
      formData.append('discard_reason_id', '')
      formData.append('event', 'create')
      formData.append('status', '9')
    }
    if (discardReason) {
      formData.append('discard_reason_id', discardReason)
      formData.append('event', 'discard')
      formData.append('status', '10')
    }
    if (loseReason) {
      formData.append('lose_reason_id', loseReason)
      formData.append('event', 'lost')
      formData.append('status', '11')
    }
    if (textReason) {
      formData.append('lose_reason_id', '0')
      formData.append('event', 'lost')
      formData.append('text_reason', textReason)
    }
  }

  if (updated) {
    const { country, email, phone, gender, name, lastName, source, contactMedium, loseReasonMQL, loseReasonSQL } =
      updated

    if (country) formData.append('country', country)
    if (email) formData.append('email', email)
    if (phone) formData.append('phone', phone)
    if (gender) formData.append('gender', gender)
    if (name) formData.append('first_name', name)
    if (lastName) formData.append('last_name', lastName)
    if (source) formData.append('source', source)
    if (contactMedium) formData.append('contact_medium_id', contactMedium)
    if (interestReason) formData.append('interest_reason_id', interestReason)
    if (discardReason) formData.append('discard_reason_id', discardReason)
    if (loseReasonMQL) formData.append('lose_reason_id', loseReasonMQL)
    if (loseReasonSQL) formData.append('lose_reason_id', loseReasonSQL)
  }

  return apiPrivate
    .put(`${URL_BASE_MERCURIO}/bo/prospects/v1/id/${id}`, formData)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
