import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/deletePhoto'
import Component from '../components/deletePhoto'
import { bool, func, number, string } from 'prop-types'

const Container = (props) => {
  return <Component {...props} />
}

const mapStateToProps = (state) => {
  const { deletePhoto } = state.PhotographObjects

  return deletePhoto
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch)
})

Container.propTypes = {
  open: bool,
  objectID: string,
  photoID: number,
  loading: bool,
  closeModal: func.isRequired,
  deletePhoto: func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
