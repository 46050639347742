export type DepositMetric = {
  id: string
  metric: string
  quantity: number
}

export type DepositMetricsTypes = {
  metrics: DepositMetric[]
  fetchingMetrics: boolean
}

export enum GetDepositMetricsTypes {
  GET_DEPOSIT_METRICS_REQUEST = 'DEPOSIT/DEPOSIT_TABLE/GET_DEPOSIT_METRICS_REQUEST',
  GET_DEPOSIT_METRICS_SUCCESS = 'DEPOSIT/DEPOSIT_TABLE/GET_DEPOSIT_METRICS_SUCCESS',
  GET_DEPOSIT_METRICS_FAILURE = 'DEPOSIT/DEPOSIT_TABLE/GET_DEPOSIT_METRICS_FAILURE'
}

export interface GetDepositMetricsRequest {
  type: typeof GetDepositMetricsTypes.GET_DEPOSIT_METRICS_REQUEST
}

export interface GetDepositMetricsSuccess {
  type: typeof GetDepositMetricsTypes.GET_DEPOSIT_METRICS_SUCCESS
  payload: { metrics: DepositMetric[] }
}

export interface GetDepositMetricsFailure {
  type: typeof GetDepositMetricsTypes.GET_DEPOSIT_METRICS_FAILURE
}

type GetDepositMetricsActions = GetDepositMetricsRequest | GetDepositMetricsSuccess | GetDepositMetricsFailure

export type DepositMetricsActions = GetDepositMetricsActions
