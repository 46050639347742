import React from 'react'
import moment from '../../moment'
import ChevronLeft from './svgs/chevronLeft'
import ChevronRight from './svgs/chevronRight'
import Discount from './svgs/discount'
import styles from './Calendar.module.scss'
import { formatPrice } from '../../packages/localization/utils/formatPrice'
import clsx from 'clsx'
import { Spin } from 'antd'
import { CalendarProps } from './calendar.types'
import { splitArrayIntoChunks } from './utils'

const DAY_HEADINGS = ['L', 'M', 'X', 'J', 'V', 'S']

export const Calendar = (props: CalendarProps) => {
  const {
    loading,
    grid,
    localDate,
    currentMonth,
    anticipationsDate,
    handleChangeMonth,
    handleDayPress,
    transportBasePrice,
    showDiscount = true
  } = props

  const weeks = splitArrayIntoChunks(grid, 7)

  const previousMonthDisabled = moment().isSame(currentMonth, 'month') || loading
  const nextMonthDisabled = loading || moment().add(2, 'month').isSame(currentMonth, 'month')
  const isSelectedDiscountNegative = localDate?.discount ? localDate.discount < 0 : false

  return (
    <div className={styles.flex1}>
      <div className={styles.flex1}>
        <div>
          <div className={styles.controlBar}>
            <button
              className={styles.controlChevron}
              onClick={() => handleChangeMonth('prev')}
              disabled={previousMonthDisabled}>
              <ChevronLeft />
            </button>
            <div className={styles.flexRow}>
              <div className={styles.monthTitle}>{moment(currentMonth).format('MMMM YYYY')}</div>
              {loading && <Spin className={styles.spin} size="small" />}
            </div>
            <button
              className={styles.controlChevron}
              disabled={nextMonthDisabled}
              onClick={() => {
                handleChangeMonth('next')
              }}>
              <ChevronRight />
            </button>
          </div>
          <div className={styles.flexRow}>
            {DAY_HEADINGS.map((day, index) => (
              <div key={index} className={styles.dayHeadings}>
                {day}
              </div>
            ))}
          </div>
          <div className={styles.calendar}>
            {weeks.map((week, index) => (
              <div key={`${currentMonth.format()}${index}}`} className={styles.flexRow}>
                {week.map((day, index) => {
                  const isSunday = index % 7 === 0
                  if (isSunday) return null
                  const IsConsideringTheDaysInAdvance = anticipationsDate.isAfter(day.date, 'day')

                  const shouldBeDisabled =
                    day.isBeforeToday || loading || IsConsideringTheDaysInAdvance || day.isDisabled

                  const dayIsInNextMonth = moment(day.date).isAfter(currentMonth, 'month')
                  const isInUnreachableFutureMonth = dayIsInNextMonth && nextMonthDisabled
                  const isNegativeDiscount = day.discount ? day.discount < 0 : false
                  const shouldBeGrey = shouldBeDisabled || !day.isInSelectedMonth
                  const isSelected = Boolean(localDate && moment(localDate.date).isSame(day.date, 'day'))

                  return (
                    <button
                      key={day.date.format()}
                      disabled={shouldBeDisabled || isInUnreachableFutureMonth}
                      onClick={() => handleDayPress(day)}
                      className={clsx([
                        styles.day,
                        shouldBeGrey && styles.greyDay,
                        isSelected && !day.discount && styles.selectedDay,
                        day.discount && !shouldBeDisabled && !isNegativeDiscount && styles.discountBg,
                        isSelected && day.discount && styles.selectedDay
                      ])}>
                      <>
                        <div className={clsx([styles.discountDays, styles.flexRow, styles.spaceBetween])}>
                          <span className={clsx([isSelected && styles.selectedText, shouldBeGrey && styles.greyText])}>
                            {moment(day.date).format('D')}
                          </span>
                          {day.discount && !shouldBeDisabled && !isInUnreachableFutureMonth && !isNegativeDiscount ? (
                            <span>
                              <Discount color={isSelected ? 'white' : '#5d58f7'} />
                            </span>
                          ) : null}
                        </div>
                        {!loading && transportBasePrice && !shouldBeDisabled && !isInUnreachableFutureMonth ? (
                          <p
                            className={clsx([
                              styles.priceText,
                              isSelected && styles.selectedText,
                              shouldBeGrey && styles.greyText
                            ])}>
                            {formatPrice({
                              value: day.discount
                                ? (transportBasePrice * (100 - day.discount)) / 100
                                : transportBasePrice,
                              isInCents: false
                            })}
                          </p>
                        ) : null}
                      </>
                    </button>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
        {showDiscount ? (
          <div className={clsx([styles.discountsLabel, styles.flexRow, styles.flexAlignCenter])}>
            <Discount color="#5d58f7" />
            <div>*Tarifas promocionales</div>
          </div>
        ) : null}
      </div>
      {showDiscount ? (
        <div className={styles.lowerContainer}>
          {localDate && (
            <div className={styles.priceSummary}>
              <div className={clsx([styles.flexRow, styles.flexAlignCenter, styles.spaceBetween])}>
                <p>Flete {moment(localDate.date).format('dddd D [de] MMMM')}</p>
                <div className={styles.selectedPrice}>
                  <p>
                    {formatPrice({
                      value:
                        localDate.discount && transportBasePrice
                          ? (transportBasePrice * (100 - localDate.discount)) / 100
                          : transportBasePrice ?? 0,
                      isInCents: false
                    })}
                  </p>
                </div>
              </div>
              {Boolean(localDate.discount) && !isSelectedDiscountNegative ? (
                <div>
                  <p>🎉 ¡Buena elección! ahorrá {localDate.discount}% en flete</p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}
