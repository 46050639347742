import { MinCostResponse } from '../../../projectApi/ObjectAdministration/Object/calculateMinCost'
import { GetRemovalDataActions } from './editMode'

export const ADD_OBJECT_TO_REMOVE = 'NEW_REMOVAL/OBJECTS/ADD_OBJECT_TO_REMOVE'
export const UNDO_OBJECT_TO_REMOVE = 'NEW_REMOVAL/OBJECTS/UNDO_OBJECT_TO_REMOVE'
export const ADD_ALL_OBJECTS_TO_REMOVE = 'NEW_REMOVAL/OBJECTS/ADD_ALL_OBJECTS_TO_REMOVE'
export const UNDO_ALL_OBJECTS_TO_REMOVE = 'NEW_REMOVAL/OBJECTS/UNDO_ALL_OBJECTS_TO_REMOVE'
export const FETCH_OBJECTS = 'NEW_REMOVAL/OBJECTS/FETCH_OBJECTS'
export const FETCH_OBJECTS_SUCCESS = 'NEW_REMOVAL/OBJECTS/FETCH_OBJECTS_SUCCESS'
export const FETCH_OBJECTS_FAILURE = 'NEW_REMOVAL/OBJECTS/FETCH_OBJECTS_FAIL'
export const CALCULATE_MIN_COST_REQUEST = 'NEW_REMOVAL/OBJECTS/CALCULATE_MIN_COST_REQUEST'
export const CALCULATE_MIN_COST_SUCCESS = 'NEW_REMOVAL/OBJECTS/CALCULATE_MIN_COST_SUCCESS'
export const CALCULATE_MIN_COST_FAILURE = 'NEW_REMOVAL/OBJECTS/CALCULATE_MIN_COST_FAILURE'
export const SET_FLOORS = 'NEW_REMOVAL/OBJECTS/SET_FLOORS'
export const SET_NUMBER_OF_FLOORS = 'NEW_REMOVAL/OBJECTS/SET_NUMBER_OF_FLOORS'
export const SET_REMOVE_PACKAGING_COST = 'NEW_REMOVAL/OBJECTS/SET_REMOVE_PACKAGING_COST'
export const SET_ASSEMBLY_COST = 'NEW_REMOVAL/OBJECTS/SET_ASSEMBLY_COST'
export const SET_ALL_OBJECTS_FLOORS = 'NEW_REMOVAL/OBJECTS/SET_ALL_OBJECTS_FLOORS'
export const SET_ALL_OBJECTS_NUMBER_OF_FLOORS = 'NEW_REMOVAL/OBJECTS/SET_ALL_OBJECTS_NUMBER_OF_FLOORS'
export const SET_ALL_OBJECTS_REMOVE_PACKAGING_COST = 'NEW_REMOVAL/OBJECTS/SET_ALL_OBJECTS_REMOVE_PACKAGING_COST'
export const SET_ALL_OBJECTS_ASSEMBLY_COST = 'NEW_REMOVAL/OBJECTS/SET_ALL_OBJECTS_ASSEMBLY_COST'
export const OPEN_WARNING_MODAL = 'NEW_REMOVAL/OBJECTS/OPEN_WARNING_MODAL'
export const CLOSE_WARNING_MODAL = 'NEW_REMOVAL/OBJECTS/CLOSE_WARNING_MODAL'
export const SET_OBJECTS_FROM_REMOVAL = 'REMOVALS/EDIT_REMOVAL/SET_OBJECTS_FROM_REMOVAL'

type Photo = {
  ID: number
  Url: string
  MainPhoto: boolean
}

type IdentificationCode = {
  Code: string
  Actual: boolean
}

export type ObjectStatus = {
  ID: number
  Description: string
}

export type ObjectResponse = {
  ID: string
  IdentificationCodes: IdentificationCode[]
  Photos: Photo[]
  RealID: number
  Name: string
  Code: string
  WeightInGr: number
  WidthInCm: number
  HeightInCm: number
  LengthInCm: number
  Description: string
  ObjectSituation: string
  ObjectStatus: ObjectStatus
  ObjectDeletedReason: string
  RegistryDate: string
  Product: Product
  OwnerID: number
  Location: Location
}

export type Location = {
  Description: string
  ID: number
  type: string
  Code: string
  Deposit: Deposit
}

export type Deposit = {
  Address: string
  Name: string
}

export type Product = {
  ID: string
  RealID: number
  Description: string
  HeightInCm: number
  LengthInCm: number
  WeightInGr: number
  WidthInCm: number
  MaxVolumeDeviation: number
  MinVolumeDeviation: number
  VolumeInCm3: number
  FloorByStairsCostInCents: number
  RemovalPackagingCostInCents: number
  AssemblyCostInCents: number
}

export type ObjectToRemove = {
  objectDetails: ObjectResponse
  floors: boolean
  numberOfFloors: number
  removePackagingCost: boolean
  assemblyCost: boolean
  floorCostFromRemoval?: number
  fromRemoval?: boolean
  deleted?: boolean
}

export type ObjectsToRemove = { [key: string]: ObjectToRemove }

export type FetchObjects = {
  type: typeof FETCH_OBJECTS
}

export type FetchObjectsSuccess = {
  type: typeof FETCH_OBJECTS_SUCCESS
  payload: { objects: ObjectResponse[] }
}

export type FetchObjectsFailure = {
  type: typeof FETCH_OBJECTS_FAILURE
  payload: { error: string }
}

export type CalculateMinCostRequest = {
  type: typeof CALCULATE_MIN_COST_REQUEST
}

export type CalculateMinCostSuccess = {
  type: typeof CALCULATE_MIN_COST_SUCCESS
  payload: { objects: MinCostResponse[] }
}

export type CalculateMinCostFailure = {
  type: typeof CALCULATE_MIN_COST_FAILURE
  payload: { error: string }
}

export type AddObjectToRemove = {
  type: typeof ADD_OBJECT_TO_REMOVE
  payload: { objectToRemove: ObjectResponse }
}

export type UndoObjectToRemove = {
  type: typeof UNDO_OBJECT_TO_REMOVE
  payload: { objectToRemove: ObjectResponse }
}

export type AddAllObjectsToRemove = {
  type: typeof ADD_ALL_OBJECTS_TO_REMOVE
}

export type UndoAllObjectsToRemove = {
  type: typeof UNDO_ALL_OBJECTS_TO_REMOVE
}

export type SetFloors = {
  type: typeof SET_FLOORS
  payload: { objectID: string; floors: boolean }
}

export type SetNumberOfFloors = {
  type: typeof SET_NUMBER_OF_FLOORS
  payload: { objectID: string; numberOfFloors: number }
}

export type SetRemovePackagingCost = {
  type: typeof SET_REMOVE_PACKAGING_COST
  payload: { objectID: string; removePackagingCost: boolean }
}

export type SetAssemblyCost = {
  type: typeof SET_ASSEMBLY_COST
  payload: { objectID: string; assemblyCost: boolean }
}

export type SetAllObjectsFloors = {
  type: typeof SET_ALL_OBJECTS_FLOORS
  payload: { floors: boolean }
}

export type SetAllObjectsNumberOfFloors = {
  type: typeof SET_ALL_OBJECTS_NUMBER_OF_FLOORS
  payload: { numberOfFloors: number }
}

export type SetAllObjectsRemovePackagingCost = {
  type: typeof SET_ALL_OBJECTS_REMOVE_PACKAGING_COST
  payload: { removePackagingCost: boolean }
}

export type SetAllObjectsAssemblyCost = {
  type: typeof SET_ALL_OBJECTS_ASSEMBLY_COST
  payload: { assemblyCost: boolean }
}

export type OpenWarningModal = {
  type: typeof OPEN_WARNING_MODAL
  payload: { warning: boolean }
}

export type CloseWarningModal = {
  type: typeof CLOSE_WARNING_MODAL
  payload: { warning: boolean }
}

export interface SetObjectsFromRemoval {
  type: typeof SET_OBJECTS_FROM_REMOVAL
  payload: { isDraft: boolean }
}

export type ObjectsActionTypes =
  | FetchObjects
  | FetchObjectsSuccess
  | FetchObjectsFailure
  | CalculateMinCostRequest
  | CalculateMinCostSuccess
  | CalculateMinCostFailure
  | AddObjectToRemove
  | UndoObjectToRemove
  | AddAllObjectsToRemove
  | UndoAllObjectsToRemove
  | SetFloors
  | SetNumberOfFloors
  | SetRemovePackagingCost
  | SetAssemblyCost
  | SetAllObjectsFloors
  | SetAllObjectsNumberOfFloors
  | SetAllObjectsRemovePackagingCost
  | SetAllObjectsAssemblyCost
  | OpenWarningModal
  | CloseWarningModal
  | SetObjectsFromRemoval
  | GetRemovalDataActions
