import React, { useEffect } from 'react'
import DiscountDatesPage from '../components/DiscountDays'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import discountDatesActionsCreator from '../actions/discountDays'
import {
  DiscountDaysDateRangeFilter,
  DiscountDaysTableCategoryFilter,
  DiscountDaysTableDateRangeKey,
  DiscountDaysTableSort
} from '../types/discountDays'
import { Pagination } from '../../../utils/searchFilterUtils'
import { Moment } from 'moment'
import { TableActionBar } from '../../../components/TableActionBar'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDays/list'
import { Edit, Trash } from '../../../icons'
import createDiscountDaysActionsCreator from '../actions/ABMDiscountDays'
import ABMDiscountDaysContainer from './ABMDiscountsDays'

const DiscountDaysContainer: React.FC = () => {
  const dispatch = useDispatch()

  const { discounts, fetchingDiscounts, ...tableState } = useSelector((state) => state.Discount.discountDaysReducer)

  const { getDiscountDays, resetFilters } = bindActionCreators(discountDatesActionsCreator, dispatch)
  const { setModalVisibility, setSelectedDiscount, setDeleteModalVisibility } = bindActionCreators(
    createDiscountDaysActionsCreator,
    dispatch
  )

  const handleGetDiscountDays = async (newParams: {
    categoryFilters?: DiscountDaysTableCategoryFilter
    pagination?: Pagination
    sort?: DiscountDaysTableSort
    dateRangeFilters?: DiscountDaysDateRangeFilter[]
  }) => {
    const tripsSuccess = await getDiscountDays({
      categoryFilters: newParams.categoryFilters || tableState.categoryFilters,
      pagination: newParams.pagination || tableState.pagination,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      sort: newParams.sort || tableState.sort
    })

    return tripsSuccess
  }

  const emptyPagination = { ...tableState.pagination, page: 1 }

  useEffect(() => {
    handleGetDiscountDays({})
  }, [])

  useEvents([Events.Discounts.CLEAN_FILTERS, Events.Discounts.CREATE_DISCOUNT, Events.Discounts.UPDATE_DISCOUNT], () =>
    handleGetDiscountDays({})
  )

  const handlePageChange = (newPage: number) => {
    handleGetDiscountDays({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleRangePicker = (key: DiscountDaysTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetDiscountDays({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
  }

  const handleSort = (newSort: DiscountDaysTableSort) => {
    handleGetDiscountDays({ sort: newSort })
  }

  const handleWeekdayFilter = (categoryFilters: DiscountDaysTableCategoryFilter) => {
    handleGetDiscountDays({ categoryFilters, pagination: emptyPagination, sort: tableProps.sort })
  }

  const handleResetFilters = () => resetFilters()

  const menuActions: MenuAction<Discount>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (discount) => {
        setModalVisibility({ modalVisible: true, editMode: true })
        setSelectedDiscount(discount)
      }
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (discount) => {
        setDeleteModalVisibility(true)
        setSelectedDiscount(discount)
      }
    }
  ]

  const tableProps = {
    categoryFilters: tableState.categoryFilters,
    discounts,
    fetchingDiscounts,
    handleRangePicker,
    dateRangeFilters: tableState.dateRangeFilters,
    sort: tableState.sort,
    handleSort,
    handleWeekdayFilter,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    actions: menuActions
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        <TableActionBar.ButtonAction
          titleButton="Crear descuento"
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar>
      <DiscountDatesPage {...tableProps} />
      <ABMDiscountDaysContainer />
    </>
  )
}

export default DiscountDaysContainer
