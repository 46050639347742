import { combineReducers } from 'redux'
import discount from './discount'
import newEdit from './newEditDiscount'
import deleteDiscount from './deleteDiscount'
import discountDatesReducer from './discountDates'
import createDiscountDatesReducer from './ABMDiscountDates'
import discountDaysReducer from './discountDays'
import createDiscountDaysReducer from './ABMDiscountDays'

const rootReducer = combineReducers({
  discount,
  newEdit,
  deleteDiscount,
  discountDatesReducer,
  createDiscountDatesReducer,
  discountDaysReducer,
  createDiscountDaysReducer
})

export default rootReducer
