import { combineReducers } from 'redux'
import calendar from './calendar'
import cost from './cost'
import extras from './extras'
import flags from './flags'
import items from './items'
import transport from './transport'
import payment from './payment'
import proposals from './proposals'
import prospect from './prospect'

const rootReducer = combineReducers({
  calendar,
  cost,
  extras,
  flags,
  items,
  transport,
  proposals,
  prospect,
  payment
})

export default rootReducer
