import React from 'react'
import styles from './InfoCard.module.scss'

type InfoCard = {
  title: string
  id: number
  name: string
  lastName: string
}

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard = ({ id, lastName, name, title }: InfoCard) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>{title}:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
      <InfoItem data={lastName} label={'Apellido'} />
    </div>
  </header>
)
