import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/usersTable'
import {
  GetAssociatedUsersRequest,
  GetAssociatedUsersSuccess,
  GetAssociatedUsersTypes,
  RESET_FILTERS,
  UsersSearchFilter
} from '../types/usersTable'

const UsersActionsCreator = {
  getAssociatedAccounts({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: UsersSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetAssociatedUsersRequest = {
        type: GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { satelliteUsers, total } = await API.AccountManager.Satellite.satelliteUsers({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetAssociatedUsersSuccess = {
          type: GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_SUCCESS,
          payload: {
            associatedUsers: satelliteUsers,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_FAILURE, dispatch)
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.CorporateUsers.CLEAN_FILTERS), 50)
    }
  }
}

export default UsersActionsCreator
