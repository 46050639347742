import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type APIResponse = {
  quoted_m3: number
  real_m3: number
  total_deposit_operations: number
  m3_total_deposit_operations: number
  quantity_won_deposit_operations: number
  m3_won_deposit_operations: number
  quantity_cancelled_deposit_operations: number
  m3_cancelled_deposit_operations: number
  quantity_validated_deposit_operations: number
  m3_validated_deposit_operations: number
  quantity_deposited_deposit_operations: number
  m3_deposited_deposit_operations: number
  total_removals: number
  m3_removals: number
  quantity_clients_with_full_removals: number
  quantity_won_prospects: number
  total_rps: number
  m3_total_rps: number
}

export type DashboardMetrics = CamelCasedPropertiesDeep<APIResponse>

type Response = {
  metrics: DashboardMetrics
}

export type DashboardMetricsParams = {
  periodFrom?: Moment
  periodTo?: Moment
  countryCode: CountryIdCode
}

export function metrics({ periodFrom, periodTo, countryCode }: DashboardMetricsParams): Promise<Response> {
  const formattedParams = snakecaseKeys({
    periodFrom: periodFrom?.format('YYYY-MM-DD'),
    periodTo: periodTo?.format('YYYY-MM-DD'),
    countryCode
  })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/dashboard-metrics`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Hubo un error al obtener las métricas generales')
      const metrics: DashboardMetrics = camelcaseKeys(response.data || {})
      return { metrics }
    })
    .catch((error) => {
      throw error
    })
}
