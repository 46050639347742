import { TransactionStatusId } from '../../projectApi/TransactionHandler/Transaction/common'
import { TripStatusId } from '../../projectApi/Trips/Trip/common'

export const TransactionStatusBadges = [
  {
    value: TransactionStatusId.CREADA,
    text: 'Creada',
    styleProps: {
      borderColor: '#175CD3',
      backgroundColor: '#EFF8FF'
    }
  },
  {
    value: TransactionStatusId.PENDIENTE,
    text: 'Pendiente',
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: TransactionStatusId.ASIGNADA_A_VIAJE,
    text: 'Asignada',
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: TransactionStatusId.REALIZADA,
    text: 'Realizada',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: TransactionStatusId.ERRONEA,
    text: 'Errónea',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: TransactionStatusId.CANCELADA,
    text: 'Cancelada',
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  },
  {
    value: TransactionStatusId.LEVANTADA,
    text: 'Levantada',
    styleProps: {
      borderColor: '#C11574',
      backgroundColor: '#FDF2FA'
    }
  },
  {
    value: TransactionStatusId.ENTREGADA,
    text: 'Entregada',
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: TransactionStatusId.EN_CURSO,
    text: 'En curso',
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  },
  {
    value: TransactionStatusId.EN_DESTINO,
    text: 'En destino',
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  },
  {
    value: TransactionStatusId.LISTO_PARA_VIAJAR,
    text: 'Listo para viajar',
    styleProps: {
      borderColor: '#755006',
      backgroundColor: '#FCEAC5'
    }
  }
]

export const TripStatusBadges = [
  {
    value: TripStatusId.PROGRAMADO,
    text: 'Programado',
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: TripStatusId.ASIGNADO,
    text: 'Asignado',
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: TripStatusId.EN_CURSO,
    text: 'En curso',
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  },
  {
    value: TripStatusId.FINALIZADO,
    text: 'Finalizado',
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: TripStatusId.CANCELADO,
    text: 'Cancelado',
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  }
]
