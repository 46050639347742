import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import {
  AllowedCountriesCodes,
  CLEAR_STATE,
  CREATE_NEW_ATTRIBUTE_FAILURE,
  CREATE_NEW_ATTRIBUTE_REQUEST,
  CREATE_NEW_ATTRIBUTE_SUCCESS,
  ClearState,
  CountryOption,
  CreateNewAttributeRequest,
  CreateNewAttributeSuccess,
  DELETE_COUNTRY_OPTION,
  DeleteCountryOption,
  GET_ATTRIBUTE_FAILURE,
  GET_ATTRIBUTE_REQUEST,
  GET_ATTRIBUTE_SUCCESS,
  NewEditAttributeState,
  SET_COUNTRY_ADD_OPTION,
  SET_COUNTRY_DATA_TYPE,
  SET_COUNTRY_DATA_TYPE_VALUE,
  SET_COUNTRY_LABEL,
  SET_COUNTRY_REMOVE_OPTION,
  SET_DATA_TYPE,
  SET_NAME,
  SET_UNIT,
  SET_VISIBLE,
  SetCountryAddOption,
  SetCountryDataType,
  SetCountryDataTypeValue,
  SetCountryLabel,
  SetCountryRemoveOption,
  SetDataType,
  SetName,
  SetUnit,
  SetVisible,
  UPDATE_ATTRIBUTE_REQUEST,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_COUNTRY_DATATYPE,
  UPDATE_COUNTRY_VALUE,
  UpdateAttributeRequest,
  UpdateAttributeSuccess,
  UpdateCountryDataType,
  UpdateCountryValue
} from '../types/NewEditAttribute'
import { AttributeParams } from '../../../projectApi/ObjectAdministration/Attribute/create'
import snakecaseKeys from 'snakecase-keys'
import eventEmitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { AttributeEditParams } from '../../../projectApi/ObjectAdministration/Attribute/update'

const actions = {
  getAttribute(id: number): AppThunk {
    return async function (dispatch) {
      const toast = setToastLoading('Buscando atributo, por favor espere...')
      dispatch({ type: GET_ATTRIBUTE_REQUEST })
      try {
        const response = await API.ObjectAdministration.Attribute.list({
          id,
          limit: 10,
          offset: 0
        })
        const { attributes } = response
        dispatch({ type: GET_ATTRIBUTE_SUCCESS, payload: { attribute: attributes[0] } })
        setToastSuccessUpdate(toast, { render: 'Atributo cargado con éxito.' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al cargar el atributo...' })
        handleErrorsWithAction(e, GET_ATTRIBUTE_FAILURE, dispatch)
      }
    }
  },
  createNewAttribute:
    (newAttribute: NewEditAttributeState): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const request: CreateNewAttributeRequest = {
        type: CREATE_NEW_ATTRIBUTE_REQUEST
      }
      dispatch(request)
      const toast = setToastLoading('Creando atributo...')
      const formatAttribute: AttributeParams = {
        name: newAttribute.name,
        data_type: newAttribute.dataType,
        is_visible: newAttribute.isVisible,
        unit: newAttribute.unit,
        countries: Object.keys(newAttribute.countries).map((country) => {
          return {
            country,
            options: snakecaseKeys(newAttribute.countries[country as AllowedCountriesCodes].options),
            label: newAttribute.countries[country as AllowedCountriesCodes].label
          }
        })
      }
      try {
        await API.ObjectAdministration.Attribute.create(formatAttribute)

        const success: CreateNewAttributeSuccess = {
          type: CREATE_NEW_ATTRIBUTE_SUCCESS
        }

        setToastSuccessUpdate(toast, { render: 'Atributo creado exitosamente' })
        eventEmitter.emit(Events.Attributes.ATTRIBUTE_CREATED)
        dispatch(success)
        return true
      } catch (error) {
        const message = handleErrorsWithAction(error, CREATE_NEW_ATTRIBUTE_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: message })
        return false
      }
    },
  editAttribute:
    (editAttribute: NewEditAttributeState): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const request: UpdateAttributeRequest = {
        type: UPDATE_ATTRIBUTE_REQUEST
      }
      dispatch(request)
      const toast = setToastLoading('Actualizando atributo...')
      const formatAttribute: AttributeEditParams = {
        id: editAttribute.id!,
        name: editAttribute.name,
        data_type: editAttribute.dataType,
        is_visible: editAttribute.isVisible,
        unit: editAttribute.unit,
        // Si esta deleleted true es porque es un dato ya guardado que se elimino de la lista, y si no tiene id es porque es una opcion nueva
        countries: Object.keys(editAttribute.countries).map((country) => {
          return {
            country,
            options: snakecaseKeys(
              editAttribute.countries[country as AllowedCountriesCodes].options.filter(
                (x) => x.deleted === true || x.id === null
              )
            ),
            label: editAttribute.countries[country as AllowedCountriesCodes].label
          }
        })
      }
      try {
        await API.ObjectAdministration.Attribute.update(formatAttribute)

        const success: UpdateAttributeSuccess = {
          type: UPDATE_ATTRIBUTE_SUCCESS
        }

        setToastSuccessUpdate(toast, { render: 'Atributo actualizado exitosamente' })
        eventEmitter.emit(Events.Attributes.ATTRIBUTE_UPDATED)
        dispatch(success)
        return true
      } catch (error) {
        const message = handleErrorsWithAction(error, CREATE_NEW_ATTRIBUTE_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: message })
        return false
      }
    },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  setVisible: (isVisible: boolean): SetVisible => ({ type: SET_VISIBLE, payload: { isVisible } }),
  setDataType: (dataType: string): SetDataType => ({ type: SET_DATA_TYPE, payload: { dataType } }),
  setName: (name: string): SetName => ({ type: SET_NAME, payload: { name } }),
  setUnit: (unit: string): SetUnit => ({ type: SET_UNIT, payload: { unit } }),
  setCountryLabel: (country: AllowedCountriesCodes, countryName: string): SetCountryLabel => ({
    type: SET_COUNTRY_LABEL,
    payload: { country, label: countryName }
  }),
  setCountryAddOption: (country: AllowedCountriesCodes, option: CountryOption): SetCountryAddOption => ({
    type: SET_COUNTRY_ADD_OPTION,
    payload: { country, option }
  }),
  setCountryRemoveOption: (country: AllowedCountriesCodes, index: number): SetCountryRemoveOption => ({
    type: SET_COUNTRY_REMOVE_OPTION,
    payload: { country, index }
  }),
  updateCountryDataType: (country: AllowedCountriesCodes, id: number, dataType: string): UpdateCountryDataType => ({
    type: UPDATE_COUNTRY_DATATYPE,
    payload: { country, dataType, id }
  }),
  setCountryValue: (country: AllowedCountriesCodes, id: number, value: string): UpdateCountryValue => ({
    type: UPDATE_COUNTRY_VALUE,
    payload: { country, value, id }
  }),
  deleteCountryOption: (country: AllowedCountriesCodes, id: number): DeleteCountryOption => ({
    type: DELETE_COUNTRY_OPTION,
    payload: { country, id }
  }),
  setCountryDataType: (country: AllowedCountriesCodes, index: number, dataType: string): SetCountryDataType => ({
    type: SET_COUNTRY_DATA_TYPE,
    payload: { country, index, dataType }
  }),
  setCountryDataTypeValue: (country: AllowedCountriesCodes, index: number, value: string): SetCountryDataTypeValue => ({
    type: SET_COUNTRY_DATA_TYPE_VALUE,
    payload: { country, index, value }
  })
}

export default actions
