import { API } from '../../projectApi'
import { AppThunk } from '../../store'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import {
  GET_TRANSACTION_REMITTANCE_FAILURE,
  GET_TRANSACTION_REMITTANCE_REQUEST,
  GET_TRANSACTION_REMITTANCE_SUCCESS,
  GetTransactionRemittancesRequest,
  GetTransactionRemittancesSuccess,
  SET_OPEN,
  SetOpen
} from './types'

const RemittanceModalActionCreators = {
  setOpen: (open: boolean, transactionId?: number): SetOpen => ({ type: SET_OPEN, payload: { open, transactionId } }),
  getTransactionRemittance:
    (transactionId: number): AppThunk =>
    async (dispatch) => {
      const request: GetTransactionRemittancesRequest = {
        type: GET_TRANSACTION_REMITTANCE_REQUEST,
        payload: {
          transactionId
        }
      }

      dispatch(request)

      try {
        const { remittances } = await API.TransactionHandler.Remittances.search({ transactionId })

        const success: GetTransactionRemittancesSuccess = {
          type: GET_TRANSACTION_REMITTANCE_SUCCESS,
          payload: {
            transactionId,
            remittance: remittances.length > 0 ? remittances[0] : null
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_TRANSACTION_REMITTANCE_FAILURE, dispatch)
      }
    }
}

export default RemittanceModalActionCreators
