import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'

type CreateParams = {
  firstName: string
  lastName: string
  email: string
  userGuid: string
  userId: number
  roleId: number
}

export function create(params: CreateParams): Promise<void> {
  const data = snakecaseKeys({
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    userGuid: params.userGuid,
    userId: params.userId,
    roleId: params.roleId
  })

  return apiPrivate
    .post(`${URL_BASE_MERCURIO}/bo/agents`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
