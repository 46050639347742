import React from 'react'
import { getPaymentMethodLabels } from '../Users.selectors'
import actions from '../actions/newUser'
import { useDispatch, useSelector } from 'react-redux'
import NewUserModal from '../components/newUserModal'
import addressActions from '../../../components/Address/actions'
import { bindActionCreators } from 'redux'

const NewUserModalContainer = (ownProps) => {
  const dispatch = useDispatch()
  const addressActionCreator = bindActionCreators(addressActions, dispatch)
  const actionsCreators = bindActionCreators(actions, dispatch)

  const { address, error: errorAddress } = useSelector((state) => state.Components.address)
  const proposals = useSelector((state) => state.NewProposal.proposals)
  const { newUser, paymentMethods, identificationTypes, roles } = useSelector((state) => state.Users)
  const countrySelectorState = useSelector((state) => state.CountrySelector)

  const props = {
    ...ownProps,
    ...actionsCreators,
    ...addressActionCreator,
    address,
    roles: roles.roles,
    user: {
      ...newUser,
      address: newUser.address,
      roleID: newUser.role.ID
    },
    proposals,
    errorAddress,
    loading: newUser.loading || paymentMethods.loading,
    loadingEmail: newUser.loadingEmail,
    loadingDNI: newUser.loadingDNI,
    paymentMethods: getPaymentMethodLabels(paymentMethods.paymentMethods),
    open: newUser.createModalOpen,
    editMode: newUser.editMode,
    errorDNI: newUser.errorDNI,
    dniAlreadyExists: newUser.dniAlreadyExists,
    identificationTypes: identificationTypes.identificationTypes,
    error: newUser.error,
    updateUsers: newUser.updateUsers,
    errorEmail: newUser.errorEmail,
    countrySelectorState
  }

  return <NewUserModal {...props} />
}

NewUserModalContainer.defaultProps = {
  byProposal: false
}

export default NewUserModalContainer
