import Select from '../../../components/Select'
import { getOptionValue } from '../../../common/operations/utils'
import React, { useMemo } from 'react'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { formatMoneyWithCountry } from '../../../utils/formatNumber'
import { Proposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'
import { Proposal as ProposalProspect } from '../../../projectApi/TransactionHandler/Proposals/getFromProspect'
import proposalsActionsCreotors from '../actions/proposals'
import addressActionsCreator from '../../../components/Address/actions'
import { BaseOption } from '../../../components/Select/Select'
import { ProposalMetrics } from '../../../projectApi/TransactionHandler/Proposals/metrics'
import { MetricTranslate } from '../constants'
import styles from './SelectProposal.module.scss'

type SelectProposalTypes = {
  selectedProspect: Prospect | null
  selectedProposal: Proposal | null
  metrics: ProposalMetrics
  proposals: ProposalProspect[]
  addressActions: typeof addressActionsCreator
  proposalActions: typeof proposalsActionsCreotors
}

type ProposalOption = BaseOption<number>

const SelectProposal = (props: SelectProposalTypes) => {
  const { selectedProspect, selectedProposal, proposals, proposalActions, addressActions, metrics } = props
  const proposalCountry = useCountryInfo(selectedProspect?.countryCode)

  const proposalsOptions = useMemo<ProposalOption[]>(
    () => [
      ...proposals.map((proposal) => {
        return {
          value: proposal.id,
          label: `Cotizacion ID ${proposal.id} - ${proposal.createdAt.local().format('dd[.] DD MMM YY HH:mm')} - ${
            proposal.type
          } - ${formatMoneyWithCountry(proposalCountry.currency, proposal.totalAmount)} - ${
            proposal.status.description
          }`
        }
      }),
      {
        value: 0,
        label: 'Crear una nueva cotización'
      }
    ],
    [proposals]
  )

  const selectedProposalOption = useMemo(
    () => proposalsOptions.find((option) => option.value === (selectedProposal?.id || 0)),
    [proposalsOptions, selectedProposal]
  )

  const handleOnChangeProposal = (option: ProposalOption) => {
    if (option.value !== 0) {
      proposalActions.getProposal(getOptionValue(option))
    } else {
      addressActions.cleanAddress()
      proposalActions.setNewProposalFromScratch()
    }
  }

  return (
    <div className={styles.metricsContainer}>
      {Object.entries(metrics).map(([key, value]) => (
        <MetricCard key={key} title={MetricTranslate[key as keyof typeof MetricTranslate]} value={value} />
      ))}
      <Select
        className={styles.select}
        placeholder="Cotización"
        selected={selectedProposalOption}
        options={proposalsOptions}
        onSelect={handleOnChangeProposal}
      />
    </div>
  )
}

const MetricCard = (props: { title: string; value: number }) => {
  const { title, value } = props
  return (
    <div className={styles.metrics}>
      <div className={styles.title}>{title}</div>
      <div className={styles.value}>{value}</div>
    </div>
  )
}

export default SelectProposal
