import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'

const styles = {
  modal: {
    maxWidth: 298
  },
  reasonContainer: {
    marginTop: 10
  },
  photo: {
    width: 250,
    height: 250,
    marginBottom: 10,
    margin: 'auto',
    objectFit: 'contain'
  },
  error: {
    color: 'red',
    textAlign: 'right'
  }
}

const RemovePanel = (props) => {
  const [errorReason, setErrorReason] = useState('')

  const onChangeReason = (e) => {
    setErrorReason('')
    props.setDeletedDescription(e.target.value)
  }

  const onOk = () => {
    if (props.deletedDescription.length < 5) {
      setErrorReason('La razón debe tener al menos 5 caracteres')
      return
    }
    props.removePhoto(props.objectId, props.photoId, props.deletedDescription)
  }

  return (
    <Modal
      title="Eliminar foto"
      visible={props.open}
      onOk={onOk}
      onCancel={props.cancel}
      okText="Eliminar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: props.isLoading }}
      okButtonProps={{ disabled: Boolean(errorReason) }}
      confirmLoading={props.isLoading}
      closable={!props.isLoading}
      maskClosable={!props.isLoading}
      style={styles.modal}>
      <img src={props.url} alt="-" style={styles.photo} />
      <div style={styles.reasonContainer}>
        <InputFieldWithError
          error={errorReason}
          label="Razón"
          value={props.deletedDescription}
          onChange={onChangeReason}
        />
      </div>
      {props.error !== '' ? <div style={styles.error}>{props.error}</div> : null}
    </Modal>
  )
}

RemovePanel.propTypes = {
  objectId: PropTypes.string.isRequired,
  photoId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  removePhoto: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired
}

export default RemovePanel
