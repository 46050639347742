import { TransportActions, TransportTypes } from '../types/transport'
import { ProposalTypes } from '../types/proposals'
import { TransportTypesPricing } from '../constants'
import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/getByTransportAndService'
import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'
import { Services } from '../../../projectApi/TransactionHandler/ServiceType/common'
import { ProspectSearchTypes } from '../types/prospect'
import { Reducer } from 'redux'
import { TransportDetail } from '../../../projectApi/TransactionHandler/Compute/cost'

const initialState = {
  serviceType: '',
  serviceTypes: [],
  loadingServiceTypes: false,
  errorServiceTypes: '',
  transportModes: [],
  loadingTransportModes: false,
  errorTransportModes: '',
  selectedTransport: 0,
  requireAddressOrigin: false,
  deposits: [],
  loadingDeposits: false,
  errorDeposits: '',
  selectedDeposit: 0,
  fetchedDeposits: false,
  where: '',
  transportDetail: null,
  loadingCost: false,
  cost: 0,
  fetchedCost: 0,
  transportTypePricing: TransportTypesPricing.COMMON,
  error: ''
}

export type TransportState = {
  serviceType: string
  serviceTypes: Services[]
  loadingServiceTypes: boolean
  errorServiceTypes: string
  transportModes: TransportMode[]
  loadingTransportModes: boolean
  errorTransportModes: string
  selectedTransport: number
  requireAddressOrigin: boolean
  deposits: Deposit[]
  loadingDeposits: boolean
  errorDeposits: string
  selectedDeposit: number
  fetchedDeposits: boolean
  where: string
  transportDetail: TransportDetail | null
  loadingCost: boolean
  cost: number
  fetchedCost: number
  transportTypePricing: TransportTypesPricing
  error: string
}

const root: Reducer<TransportState, TransportActions> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return initialState
    case ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH:
      return { ...state, fetchedCost: 0 }
    case TransportTypes.GET_SERVICE_TYPES_REQUEST:
      return {
        ...state,
        loadingServiceTypes: true
      }
    case TransportTypes.GET_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        loadingServiceTypes: false,
        serviceTypes: action.payload.serviceTypes
      }
    case TransportTypes.GET_SERVICE_TYPES_FAILURE:
      return {
        ...state,
        loadingServiceTypes: false,
        errorServiceTypes: action.payload.error
      }
    case TransportTypes.SET_SERVICE_TYPE:
      return {
        ...state,
        serviceType: action.payload.serviceType
      }
    case TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST:
      return {
        ...state,
        fetchedDeposits: false,
        loadingTransportModes: true,
        errorTransportModes: '',
        deposits: [],
        selectedDeposit: action.payload.removeSelectedDeposit ? 0 : state.selectedDeposit
      }
    case TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS: {
      const { transportModes } = action.payload
      return {
        ...state,
        loadingTransportModes: false,
        transportModes,
        requireAddressOrigin: transportModes.length === 0 ? true : state.requireAddressOrigin
      }
    }
    case TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_FAILURE:
      return {
        ...state,
        loadingTransportModes: false,
        error: action.payload.error
      }
    case TransportTypes.SET_TRANSPORT_MODE: {
      const { transportMode, requireAddressOrigin } = action.payload
      return {
        ...state,
        transportDetail: initialState.transportDetail,
        selectedTransport: transportMode,
        requireAddressOrigin,
        cost: requireAddressOrigin ? state.cost : 0
      }
    }
    case TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST:
      return {
        ...state,
        loadingDeposits: true,
        fetchedDeposits: false,
        errorDeposits: '',
        deposits: []
      }
    case TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS: {
      const { deposits } = action.payload
      return {
        ...state,
        loadingDeposits: false,
        fetchedDeposits: true,
        deposits
      }
    }
    case TransportTypes.GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE:
      return {
        ...state,
        loadingDeposits: false,
        fetchedDeposits: true,
        errorDeposits: action.payload.error
      }
    case TransportTypes.SET_DEPOSIT: {
      const { deposit } = action.payload
      return {
        ...state,
        selectedDeposit: deposit
      }
    }
    case TransportTypes.FETCH_COST_REQUEST:
      return {
        ...state,
        loadingCost: true,
        error: ''
      }
    case TransportTypes.FETCH_COST_SUCCESS: {
      const { cost, fetchedCost, where, transportDetail } = action.payload

      return {
        ...state,
        loadingCost: false,
        fetchedCost,
        cost: state.requireAddressOrigin ? cost : 0,
        where,
        transportDetail
      }
    }
    case TransportTypes.FETCH_COST_FAILURE:
      return {
        ...state,
        loadingCost: false,
        error: action.payload.error
      }
    case TransportTypes.SET_CUSTOM_TRANSPORT_COST:
      return {
        ...state,
        transportTypePricing: action.payload.transportTypePricing,
        cost: action.payload.newCost
      }
    case ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS: {
      const { proposal } = action.payload
      const { depositId, destination, transportModeId, transportType, transportCostInCents } = proposal

      return {
        ...state,
        serviceType: proposal.type,
        cost: Math.round(transportCostInCents / 100),
        selectedTransport: transportModeId,
        selectedDeposit: depositId,
        transportTypePricing: transportType as TransportTypesPricing,
        where: transportModeId === 1 ? destination : ''
      }
    }
    default:
      return state
  }
}

export default root
