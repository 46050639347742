export const genders = [
  { label: 'Masculino', value: 'M' },
  { label: 'Femenino', value: 'F' },
  { label: 'Desconocido', value: 'U' },
  { label: 'Otro', value: 'O' }
]

export const steps = {
  NEXT_ACTION: 'NEXT_ACTION',
  LOSE_PROSPECT: 'LOSE_PROSPECT',
  QUOTE_PROSPECT: 'QUOTE_PROSPECT',
  QUOTE_NEW_PROSPECT: 'QUOTE_NEW_PROSPECT',
  DISCARD_PROSPECT: 'DISCARD_PROSPECT',
  FINALIZE_DEPOSIT: 'FINALIZE_DEPOSIT',
  USER_PROFILE: 'USER_PROFILE'
}

export const FUNNEL_STATE_OPTIONS = {
  1: 'PEND',
  2: 'DESC',
  3: 'MQL',
  4: 'SQL',
  5: 'MQLP',
  6: 'SQLP',
  7: 'WIN',
  8: 'CANCEL'
}

export const DUP_OPTIONS = {
  0: 'No',
  1: 'Prospecto',
  2: 'Usuario'
}

export const DUPLICITY_OPTIONS = Object.entries(DUP_OPTIONS).map(([key, value]) => ({
  label: value,
  value: Number(key)
}))

export const STATUS_ID = {
  NEW: 13
}

export const LIMIT_REPORT = 2000

export const EMAIL_OR_PHONE_REQUIRED = 'Es necesario completar el email o el teléfono'

export enum STATUS_VALUES {
  DISCARDED = 10,
  LOST = 11,
  NEW = 13,
  PROPOSED = 14,
  CONVERTED = 16
}

export enum STEP_OPTION {
  INTEREST = 'Interes',
  DISCARD = 'Descarte',
  NOT_SELECTED = ''
}

export enum FunnelState {
  PENDING = 'PEND',
  DISCARD = 'DESC',
  MQL = 'MQL',
  SQL = 'SQL',
  MQLP = 'MQLP',
  SQLP = 'SQLP',
  WINNER = 'WIN',
  CANCELED = 'CANC',
  LACK_INTEREST = 'LACK',
  RP_VALIDATED = 'WINV',
  TEST = 'TEST'
}

export enum filterFunnelStatesNames {
  PEND = 'Pendientes',
  DESC = 'No calificados',
  MQL = 'Calificados',
  SQL = 'Cotizados'
}

export const STATUS_FILTERS = [
  {
    text: 'Nuevo',
    value: STATUS_VALUES.NEW,
    nextAction: 'Iniciar',
    styleProps: {
      borderColor: '#52C41A',
      backgroundColor: '#F6FFED'
    }
  },
  {
    text: 'Cotización enviada',
    value: STATUS_VALUES.PROPOSED,
    nextAction: 'Definir cierre',
    styleProps: {
      borderColor: '#FAAF19',
      backgroundColor: '#FFFBE6'
    }
  },
  {
    text: 'Convertido',
    value: STATUS_VALUES.CONVERTED,
    nextAction: 'Iniciar',
    styleProps: {
      borderColor: '#1890FF',
      backgroundColor: '#E6F7FF'
    }
  },
  {
    text: 'Descartado',
    value: STATUS_VALUES.DISCARDED,
    nextAction: 'Reactivar',
    styleProps: {
      borderColor: '#8619FA',
      backgroundColor: '#E8D7FA'
    }
  },
  {
    text: 'Perdido',
    value: STATUS_VALUES.LOST,
    nextAction: 'Reactivar',
    styleProps: {
      borderColor: '#FF4D4F',
      backgroundColor: '#FFF2F0'
    }
  }
]

export const FUNNEL_STATE_FILTERS = [
  { value: 1, text: 'PEND' },
  { value: 2, text: 'DESC' },
  { value: 3, text: 'MQL' },
  { value: 4, text: 'SQL' },
  { value: 5, text: 'MQLP' },
  { value: 6, text: 'SQLP' },
  { value: 7, text: 'WIN' },
  { value: 8, text: 'CANCEL' }
]

export const FUNNEL_STATE = Object.entries(FUNNEL_STATE_OPTIONS).map(([key, value]) => ({
  label: value,
  value: Number(key)
}))

export const allowedFunnelStates = [
  FunnelState.PENDING,
  FunnelState.MQL,
  FunnelState.SQL,
  FunnelState.WINNER,
  FunnelState.CANCELED,
  FunnelState.RP_VALIDATED
]
