import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import camelcaseKeys from 'camelcase-keys'

export interface APIResponse {
  description: {
    location: Location
  }
  success: string
}

export interface APILocation {
  ID: number
  Description: string
  Active: boolean
  Type: string
  Code: string
  DepositID: number
  QuantityObjects: number
  MaxLengthInCm: number
  MaxWidthInCm: number
  MaxHeightInCm: number
  VolumeInCm: number
  WeightInGr: number
  MaxVolumeInCm: number
  MaxWeightInGr: number
  OccupancyRate: number
  LoadRate: number
}

export type Location = CamelCasedPropertiesDeep<APILocation>

export function getByCode(code: string): Promise<Location> {
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/location/${code}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo encontrar la ubicación')

      return camelcaseKeys(response.data.description.location, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
