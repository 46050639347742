import { Tooltip } from 'antd'
import React from 'react'
import { Edit, QRIcon } from '../../../icons'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { APIObject, FormattedObject } from '../../../projectApi/ObjectAdministration/common'
import { getIdentificationCode, getSortProps } from '../utils'
import { formatMoney } from '../../../utils/formatNumber'
import { formatDate } from '../../Locations/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import {
  PhotographObjectsTableSearchFilter,
  PhotographObjectsTableSearchKey,
  PhotographObjectsTableSort
} from '../types/PhotographObjectsTable'
import { getSearchProps } from '../../../components/DynamicTable/utils'

export type PhotographObjectsProps = {
  objects: FormattedObject[]
  pagination: Pagination
  loading: boolean
  sort: PhotographObjectsTableSort
  handleOpenQRModal: (code: string) => void
  handleSort: (newSorter: PhotographObjectsTableSort) => void
  openPhotosModal: (obj: APIObject) => void // TODO: Update this after modal refactor to TS
  handleSearchFilters: (key: PhotographObjectsTableSearchKey, text: string) => void
  searchFilters: PhotographObjectsTableSearchFilter[]
}

export const PhotographObjectsTable: React.FC<PhotographObjectsProps> = ({
  objects,
  handleOpenQRModal,
  pagination,
  loading,
  openPhotosModal,
  handleSort,
  sort,
  handleSearchFilters,
  searchFilters
}) => {
  const column: Column<FormattedObject>[] = [
    {
      key: 'realId',
      label: 'ID',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps('id', 'ID', handleSearchFilters, searchFilters)
    },
    {
      key: 'identificationCode',
      label: 'Código',
      search: getSearchProps('identificationCode', 'Codigo', handleSearchFilters, searchFilters),
      renderDataCell: (code) => {
        const identificationCode = getIdentificationCode(code)
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i style={{ cursor: 'pointer' }} onClick={() => handleOpenQRModal(identificationCode)}>
                <QRIcon />
              </i>
            </Tooltip>
            {identificationCode}
          </div>
        )
      }
    },
    {
      key: 'product.description',
      label: 'Producto',
      search: getSearchProps('product', 'Producto', handleSearchFilters, searchFilters)
    },
    {
      key: 'description',
      label: 'Descripción'
    },
    {
      key: 'rentPrice',
      label: 'Precio de renta',
      renderDataCell: ({ rentPrice }) => formatMoney(rentPrice)
    },
    {
      key: 'creationDate',
      label: 'Creación',
      renderDataCell: ({ creationDate }) => formatDate(creationDate)
    },
    {
      key: 'objectStatus.description',
      label: 'Estado'
    }
  ]

  const PhotographObjectsActions: MenuAction<FormattedObject>[] = [
    {
      label: 'Agregar o Eliminar Foto',
      icon: <Edit />,
      onClick: (row) => openPhotosModal(row.unformattedAPIObject)
    }
  ]
  return (
    <>
      <DynamicTable
        columns={column}
        rows={objects}
        keyExtractor={({ realId }) => realId}
        pagination={pagination}
        loading={loading}
        actions={PhotographObjectsActions}
        customHeightOffset={180}
      />
    </>
  )
}
