import { apiPrivate } from '@/api'
import { URL_BASE_CATEGORIES } from '@/endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

type APIProduct = {
  description: string
  weight_in_gr: number
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  product_catalog_id: string
  catalog_name: string
  category: {
    id: string
    name: string
    catalog_name: string
  }
  product_by_countries: {
    name: string
    country_id: string
    photo: {
      url: string
    }
  }[]
  solution: {
    description: string
  }
}

type APIResponse = {
  content: {
    products: APIProduct[]
  }
}
export type ProductCatalogue = CamelCasedPropertiesDeep<APIProduct>

type Response = {
  product: ProductCatalogue[]
}

interface Params {
  description: string
}

export function search(params: Params): Promise<Response> {
  const { description } = params
  const searchParams = { description, with_category: true, limit: 1 }
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/catalog/search-product`, { params: searchParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Error al obtener los productos')

      const camelCaseResponse: Response = {
        product: camelcaseKeys(response.data.content.products, { deep: true })
      }
      return camelCaseResponse
    })
    .catch((error) => {
      throw error
    })
}
