import { SatelliteAccount } from '../../../../projectApi/AccountManager/Satellite/corporate'
import { Pagination, SearchFilter } from '../../../../utils/searchFilterUtils'

export type UsersTableSearchKey = 'id'
export type UsersSearchFilter = SearchFilter<UsersTableSearchKey>

export type Filters = {
  id?: number
}

export type UsersTableTypes = {
  associatedUsers: SatelliteAccount[]
  pagination: Pagination
  isLoading: boolean
  searchFilters: UsersSearchFilter[]
  filters: Filters
  error: string
}

export enum GetAssociatedUsersTypes {
  GET_ASSOCIATED_USERS_REQUEST = 'CORPORATE/USERS/GET_ASSOCIATED_USERS_REQUEST',
  GET_ASSOCIATED_USERS_SUCCESS = 'CORPORATE/USERS/GET_ASSOCIATED_USERS_SUCCESS',
  GET_ASSOCIATED_USERS_FAILURE = 'CORPORATE/USERS/GET_ASSOCIATED_USERS_FAILURE'
}

export interface GetAssociatedUsersRequest {
  type: GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: UsersSearchFilter[]
  }
}

export interface GetAssociatedUsersSuccess {
  type: GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_SUCCESS
  payload: {
    associatedUsers: SatelliteAccount[]
    total: number
  }
}

export interface GetAssociatedUsersFailure {
  type: GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_FAILURE
  payload: {
    error: string
  }
}

type GetAssociatedUsersActions = GetAssociatedUsersRequest | GetAssociatedUsersSuccess | GetAssociatedUsersFailure

export const RESET_FILTERS = 'CORPORATE/USERS/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export type UserTableActions = GetAssociatedUsersActions
