import {
  Attribute,
  AttributesActions,
  AttributesTableCategoryFilter,
  AttributesTableSearchFilter,
  AttributesTableSearchKey,
  AttributesTableSort,
  CLEAR_STATE,
  GET_ATTRIBUTES_FAILURE,
  GET_ATTRIBUTES_REPORT_FAILURE,
  GET_ATTRIBUTES_REPORT_REQUEST,
  GET_ATTRIBUTES_REPORT_SUCCESS,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  Pagination
} from '../types/AttributesTable'
import { SortDirection } from '../../../components/DynamicTable/types/types'

export type AttributesState = {
  loading: boolean
  isLoadingReport: boolean
  error: string
  attributes: Attribute[]
  pagination: Pagination
  categoryFilter: AttributesTableCategoryFilter
  searchFilters: AttributesTableSearchFilter[]
  sort: AttributesTableSort
}

const initialAttributesState: AttributesState = {
  loading: false,
  isLoadingReport: false,
  error: '',
  attributes: [],
  pagination: {
    page: 1,
    total: 0,
    pageSize: 10
  },
  searchFilters: [
    { key: AttributesTableSearchKey.ID, text: '' },
    { key: AttributesTableSearchKey.NAME, text: '' },
    { key: AttributesTableSearchKey.VISIBLE, text: '' }
  ],
  sort: {
    direction: SortDirection.DESC,
    field: 'id'
  },
  categoryFilter: {
    is_visible: []
  }
}

const AttributesReducer = (
  state: AttributesState = initialAttributesState,
  action: AttributesActions
): AttributesState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialAttributesState
    case GET_ATTRIBUTES_REQUEST: {
      return {
        ...state,
        loading: true,
        sort: action.payload.sort || initialAttributesState.sort,
        categoryFilter: action.payload.categoryFilter || initialAttributesState.categoryFilter,
        searchFilters: action.payload.searchFilters || initialAttributesState.searchFilters,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    }
    case GET_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        attributes: action.payload.attributes,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    }
    case GET_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    case GET_ATTRIBUTES_REPORT_REQUEST:
      return {
        ...state,
        isLoadingReport: true
      }
    case GET_ATTRIBUTES_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false
      }
    case GET_ATTRIBUTES_REPORT_FAILURE:
      return {
        ...state,
        isLoadingReport: false
      }
    default:
      return state
  }
}
export default AttributesReducer
