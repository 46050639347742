import { Provider, ProvidersListSortKey } from '../../../projectApi/Trips/Providers/list'
import { Pagination, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type ProvidersTableSearchKey = 'id' | 'name'
export type ProvidersSearchFilter = SearchFilter<ProvidersTableSearchKey>

export type ProvidersTableSort = Sort<ProvidersListSortKey>

export type Filters = {
  id?: number
}

export type ProviderListStateType = {
  fetchingProviders: boolean
  providers: Provider[]
  pagination: Pagination
  searchFilters: ProvidersSearchFilter[]
  filters: Filters
  loadingCSV: boolean
  sort: ProvidersTableSort
}

export enum GetProviderListTypes {
  GET_PROVIDER_REQUEST = 'PROVIDER/TABLE/GET_PROVIDER_REQUEST',
  GET_PROVIDER_SUCCESS = 'PROVIDER/TABLE/GET_PROVIDER_SUCCESS',
  GET_PROVIDER_FAILURE = 'PROVIDER/TABLE/GET_PROVIDER_FAILURE'
}

export interface GetProviderListRequest {
  type: GetProviderListTypes.GET_PROVIDER_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: ProvidersSearchFilter[]
    sort: ProvidersTableSort
  }
}

export interface GetProviderListSuccess {
  type: GetProviderListTypes.GET_PROVIDER_SUCCESS
  payload: {
    providers: Provider[]
    total: number
  }
}

export interface GetProviderListFailure {
  type: GetProviderListTypes.GET_PROVIDER_FAILURE
  payload: {
    error: string
  }
}

type GetProviderListActions = GetProviderListRequest | GetProviderListSuccess | GetProviderListFailure

export enum SendCSVListTypes {
  SEND_CSV_REQUEST = 'PROVIDER/TABLE/SEND_CSV_REQUEST',
  SEND_CSV_SUCCESS = 'PROVIDER/TABLE/SEND_CSV_SUCCESS',
  SEND_CSV_FAILURE = 'PROVIDER/TABLE/SEND_CSV_FAILURE'
}

export interface SendCSVListRequest {
  type: SendCSVListTypes.SEND_CSV_REQUEST
}

export interface SendCSVListSuccess {
  type: SendCSVListTypes.SEND_CSV_SUCCESS
}

export interface SendCSVListFailure {
  type: SendCSVListTypes.SEND_CSV_FAILURE
}

type SendCSVListActions = SendCSVListRequest | SendCSVListSuccess | SendCSVListFailure

export enum SetterProviderTypes {
  SET_PROVIDER_FILTERS = 'PROVIDER/TABLE/SET_PROVIDER_FILTERS'
}

export interface SetProviderFilters {
  type: SetterProviderTypes.SET_PROVIDER_FILTERS
  payload: {
    id?: number
  }
}

export type SetterProviderActions = SetProviderFilters

export const RESET_FILTERS = 'PROVIDER/TABLE/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export type ProviderListActions = GetProviderListActions | SetterProviderActions | ResetFilters | SendCSVListActions
