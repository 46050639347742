export enum DonationsEventsEnum {
  CLEAN_FILTERS = 'DONATIONS/CLEAN_FILTERS',
  ACCEPT = 'DONATIONS/ACCEPT',
  REJECT = 'DONATIONS/REJECT',
  DONATE = 'DONATIONS/DONATE'
}

export type DonationsEvents = {
  [DonationsEventsEnum.CLEAN_FILTERS]: () => void
  [DonationsEventsEnum.ACCEPT]: () => void
  [DonationsEventsEnum.REJECT]: () => void
  [DonationsEventsEnum.DONATE]: () => void
}
