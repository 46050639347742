import { combineReducers } from 'redux'
import { RemovalsTableReducer } from './reducers/removalsTable'
import RemovalHistory from './reducers/removalHistory'
import NewCancelReducer from './reducers/cancel'
import CancelReducer from './CancelOld/reducers/cancel'
import RemovalMetricsReducer from './reducers/metrics'

export default combineReducers({
  table: RemovalsTableReducer,
  history: RemovalHistory,
  cancel: NewCancelReducer,
  cancelOld: CancelReducer,
  metrics: RemovalMetricsReducer
})
