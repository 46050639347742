import React from 'react'

const ComplaintIcon: React.FC<{
  stroke?: string
  className?: string
  width?: string
  height?: string
}> = ({ stroke = 'white', height = 20, width = 21, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: 'scaleX(-1)'
      }}>
      <path
        className={className}
        d="M11.4996 9.5V6M11.4996 13H11.5096M11.4996 18.5C16.194 18.5 19.9996 14.6944 19.9996 10C19.9996 5.30558 16.194 1.5 11.4996 1.5C6.8052 1.5 2.99962 5.30558 2.99962 10C2.99962 10.95 3.15547 11.8636 3.443 12.7166C3.55119 13.0376 3.60529 13.1981 3.61505 13.3214C3.62469 13.4432 3.6174 13.5286 3.58728 13.6469C3.55677 13.7668 3.48942 13.8915 3.35472 14.1408L1.71906 17.1684C1.48575 17.6002 1.36909 17.8161 1.3952 17.9828C1.41794 18.1279 1.50337 18.2557 1.6288 18.3322C1.7728 18.4201 2.01692 18.3948 2.50517 18.3444L7.62619 17.815C7.78127 17.799 7.85881 17.791 7.92949 17.7937C7.999 17.7963 8.04807 17.8029 8.11586 17.8185C8.18478 17.8344 8.27145 17.8678 8.44478 17.9345C9.39283 18.2998 10.4228 18.5 11.4996 18.5Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ComplaintIcon
