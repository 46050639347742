import { StatusOptions, StatusStrings, TypeOptions, TypeStrings } from '../../projectApi/Sales/Requests/common'
import { formatMoney } from '../../utils/formatNumber'

export const getExpressOption = (percent: number, basePrice: number) => {
  const value = percent * basePrice
  const label = `${percent * 100}% - ${formatMoney(value)}`
  return { label, value }
}

export const MAX_PRICE_LENGTH = 8

export const STATUS_OPTIONS = [
  {
    value: StatusStrings.RECEIVED,
    text: StatusOptions.RECEIVED,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: StatusStrings.IN_PROGRESS,
    text: StatusOptions.IN_PROGRESS,
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: StatusStrings.REJECTED,
    text: StatusOptions.REJECTED,
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: StatusStrings.CANCELLED,
    text: StatusOptions.CANCELLED,
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: StatusStrings.APPROVED,
    text: StatusOptions.APPROVED,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  }
]

export const TYPE_OPTIONS = [
  {
    value: TypeStrings.DONATION_REQUEST,
    text: TypeOptions.DONATION_REQUEST,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: TypeStrings.SALE_REQUEST,
    text: TypeOptions.SALE_REQUEST,
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  }
]
