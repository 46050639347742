import React, { useEffect } from 'react'
import AMModalDeposits from '../components/AMDeposits'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ABMDepositsActionsCreator from '../actions/ABMDeposits'
import { findAddressComponent } from '../../../components/Address/utils'
import { parsePostalCode } from '../../../common/operations/utils'
import { isEmpty } from 'ramda'
import { GeocodeResult } from 'use-places-autocomplete'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import DeleteDepositModal from '../components/DeleteDeposit'

const ABMDepositsContainer = () => {
  const dispatch = useDispatch()

  const {
    setModalVisibility,
    setFormField,
    setDepositName,
    setAddress,
    createDeposit,
    setAddressError,
    getAddressDetials,
    setDeleteModalVisibility,
    changeAddress: changeAddres,
    editDeposit,
    deleteDeposit
  } = bindActionCreators(ABMDepositsActionsCreator, dispatch)

  const {
    modalVisible,
    depositId,
    addressDetails,
    changeAddress,
    depositName,
    selectedDeposit,
    depositCountryCode,
    addressError,
    creatingDeposit,
    updatingDeposit,
    deletingDeposit,
    deleteModalVisible,
    disabledDeleteButton,
    editMode,
    depositAddressId,
    fetchingAddressDetails
  } = useSelector((state) => state.ABMStorageDepositModalReducer)

  useEffect(() => {
    if (editMode) {
      getAddressDetials({ addressId: depositAddressId ?? 0 })
    }
  }, [editMode])

  useEvents([Events.StorageDeposits.CREATE_DEPOSIT, Events.StorageDeposits.EDIT_DEPOSIT], () => {
    setModalVisibility({ modalVisible: false })
  })

  useEvents([Events.StorageDeposits.DELETE_DEPOSIT], () => {
    setDeleteModalVisibility(false)
  })

  const handlegGoogleSuggest = (suggest: GeocodeResult) => {
    const addressGoogle = suggest.formatted_address
    const street = findAddressComponent(suggest.address_components, 'route')
    const number = findAddressComponent(suggest.address_components, 'street_number')

    const postalCode = findAddressComponent(suggest.address_components, 'postal_code')
    const formattedPostalCode = postalCode ? parsePostalCode(postalCode, 'ARG') : ''

    const city = findAddressComponent(suggest.address_components, 'administrative_area_level_2')
    const province = findAddressComponent(suggest.address_components, 'administrative_area_level_1')
    const country = findAddressComponent(suggest.address_components, 'country', 'long_name')

    setAddressError(!isEmpty(street) && isEmpty(number) ? 'Ingresar el número de calle en la dirección' : '')

    const formattedAddress = {
      completeAddress: addressGoogle,
      addressStreet: street,
      addressNumber: number,
      addressPostalCode: formattedPostalCode,
      addressCity: city,
      addressProvince: province,
      country
    }

    setAddress(formattedAddress)
  }

  const disableDetailFields = !addressDetails.addressStreet || !isEmpty(addressError)
  const disabledSuccessButton = disableDetailFields || creatingDeposit || !depositName || updatingDeposit

  const handleCreateDeposit = () => {
    if (editMode) {
      editDeposit({
        addressId: depositAddressId,
        depositId,
        addressDetails,
        depositName,
        depositCountryCode: depositCountryCode ?? CountryIdCode.ARGENTINA
      })
      return
    }
    createDeposit({
      addressDetails,
      depositName,
      depositCountryCode
    })
  }

  const handleDeleteDiscount = () => {
    deleteDeposit({ depositId })
  }

  const deleteDepositModalProps = {
    selectedDeposit,
    deletingDeposit,
    deleteModalVisible,
    disabledDeleteButton,
    setDeleteModalVisibility,
    handleDeleteDiscount
  }

  const AMModalDepositProps = {
    addressDetails,
    addressError,
    modalVisible,
    changeAddress,
    editMode,
    depositName,
    disableDetailFields,
    disabledSuccessButton,
    fetchingAddressDetails,
    setFormField,
    setModalVisibility,
    setDepositName,
    changeAddres,
    handlegGoogleSuggest,
    handleCreateDeposit
  }

  return (
    <>
      <AMModalDeposits {...AMModalDepositProps} />
      <DeleteDepositModal {...deleteDepositModalProps} />
    </>
  )
}

export default ABMDepositsContainer
