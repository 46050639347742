import {
  CLOSE_MODAL,
  CREATE_ADDRESS_DATA_FAILURE,
  CREATE_ADDRESS_DATA_REQUEST,
  CREATE_ADDRESS_DATA_SUCCESS,
  CloseModal,
  CreateAddressDataRequest,
  CreateAddressDataSuccess,
  GET_ADDRESS_DATA_FAILURE,
  GET_ADDRESS_DATA_REQUEST,
  GET_ADDRESS_DATA_SUCCESS,
  GetAddressDataRequest,
  GetAddressDataSuccess,
  OPEN_MODAL,
  OpenModal,
  UPDATE_ADDRESS_DATA_FAILURE,
  UPDATE_ADDRESS_DATA_REQUEST,
  UPDATE_ADDRESS_DATA_SUCCESS,
  UpdateAddressDataRequest,
  UpdateAddressDataSuccess
} from '../types/addressModal'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import addressActions from '../../../components/Address/actions'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { Address } from '../../../components/Address/types'

export const AddressModalActionCreator = {
  addNewAddress:
    (address: Address, userId: number): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const request: CreateAddressDataRequest = { type: CREATE_ADDRESS_DATA_REQUEST }
      dispatch(request)
      try {
        await API.AccountManager.Addresses.create({ address, userId })
        const success: CreateAddressDataSuccess = { type: CREATE_ADDRESS_DATA_SUCCESS }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, CREATE_ADDRESS_DATA_FAILURE, dispatch)
      }
    },
  updateAddress:
    (address: Address, userId: number, addressId: number): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const request: UpdateAddressDataRequest = { type: UPDATE_ADDRESS_DATA_REQUEST }
      dispatch(request)
      try {
        await API.AccountManager.Addresses.update({ address, userId, addressId })
        const success: UpdateAddressDataSuccess = { type: UPDATE_ADDRESS_DATA_SUCCESS }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, UPDATE_ADDRESS_DATA_FAILURE, dispatch)
      }
    },
  getAddress:
    (addressId: number): AppThunk =>
    async (dispatch) => {
      const request: GetAddressDataRequest = { type: GET_ADDRESS_DATA_REQUEST }
      dispatch(request)

      try {
        const address = await API.AccountManager.Addresses.getById({ addressId })
        const success: GetAddressDataSuccess = { type: GET_ADDRESS_DATA_SUCCESS }
        dispatch(addressActions.setAddress({ ...address, postalCode: address.postalCode.Code }))
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_ADDRESS_DATA_FAILURE, dispatch)
      }
    },
  openModal: (userId: number, isEdit = false, addressId?: number): OpenModal => ({
    type: OPEN_MODAL,
    payload: { userId, isEdit, addressId: addressId || 0 }
  }),
  closeModal: (): CloseModal => ({ type: CLOSE_MODAL })
}
