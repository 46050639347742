import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'

type CreateParams = {
  sirenaSource: string
  mercurioSource: number
}

export function edit(id: number, params: CreateParams): Promise<void> {
  const data = snakecaseKeys({
    sirenaSource: params.sirenaSource,
    mercurioSource: params.mercurioSource
  })

  return apiPrivate
    .put(`${URL_BASE_MERCURIO}/sirena-sources/${id}`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
