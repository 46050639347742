import React from 'react'
import { Icon, Radio } from 'antd'
import moment from 'moment'
import RemovalsTable, { RemovalsTableProps } from './table'
import { RadioChangeEvent } from 'antd/es/radio'
import DebtStatusModal from '../containers/debtStatus'
import InformPaymentModal from '../containers/informPayment'
import CloseRemoval from '../containers/closeRemoval'
import { TableActionBar } from '../../../components/TableActionBar'

moment.locale('es')

export enum DateFilters {
  TODAY = 'today',
  TOMORROW = 'tomorrow'
}

const styles = {
  containerStyles: {
    justifyContent: 'space-between'
  },
  button: {
    paddingLeft: 20,
    paddingRight: 20
  }
}

export type RemovalComponentProps = {
  dateFilterValue: any
  cleanFilters: () => void
  handleButtonFilterChange: (e: RadioChangeEvent) => void
} & RemovalsTableProps

const Removals = ({ dateFilterValue, cleanFilters, handleButtonFilterChange, ...props }: RemovalComponentProps) => {
  return (
    <div className="animated fadeIn">
      <TableActionBar>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={cleanFilters} />
          <Radio.Group value={dateFilterValue} onChange={handleButtonFilterChange} size="large">
            <Radio.Button style={styles.button} value={DateFilters.TODAY}>
              <Icon type="search" /> Para hoy
            </Radio.Button>
            <Radio.Button style={styles.button} value={DateFilters.TOMORROW}>
              <Icon type="search" /> Para mañana
            </Radio.Button>
          </Radio.Group>
        </TableActionBar.Wrapper>
      </TableActionBar>

      <RemovalsTable {...props} />
      <DebtStatusModal />
      <InformPaymentModal />
      <CloseRemoval />
    </div>
  )
}

export default Removals
