import { SortOrder } from 'antd/es/table/interface'
import { PaginationConfig } from 'antd/es/table'
import { InformPaymentSuccess, UpdateDebtStatusSuccess } from './nextStatus'

export enum RemovalTableTypes {
  FETCH_REMOVALS = 'REMOVALS_VALIDATION/FETCH_REMOVALS',
  FETCH_REMOVALS_SUCCESS = 'REMOVALS_VALIDATION/FETCH_REMOVALS_SUCCESS',
  FETCH_REMOVALS_FAIL = 'REMOVALS_VALIDATION/FETCH_REMOVALS_FAIL',
  SET_SORTER = 'REMOVALS_VALIDATION/SET_SORTER',
  SET_FILTER = 'REMOVALS_VALIDATION/SET_FILTER',
  SET_PAGINATION = 'REMOVALS_VALIDATION/SET_PAGINATION',
  SET_SEARCH_FILTERS = 'REMOVALS_VALIDATION/SET_SEARCH_FILTERS',
  CLEAN_FILTERS = 'REMOVALS_VALIDATION/CLEAN_FILTERS'
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export type Params = {
  Limit: number
  Offset: number
  Column: string
  Order: Order
} & { [key: string]: string | number | undefined }

type ColumnsFilter = 'payment_type' | 'status'

export enum ColumnsSearchFilters {
  ID = 'id',
  USER_ID = 'user_id',
  OPERATION_NUMBER = 'operation_number',
  CREATED_AT = 'created_at',
  TRANSPORT_DATETIME = 'transport_datetime'
}

export type RemovalFilter = Partial<Record<ColumnsFilter, string[]>>

export type RemovalSorter = {
  order: SortOrder
  field: string
  columnKey: string
}

export type RemovalSearchFilter = {
  key: ColumnsSearchFilters
  text: string | Array<unknown> | undefined
}

export type User = {
  id: number
  last_name: string
  name: string
}

export type Removal = {
  address: {
    address_string: string
    apartment: string
    city: string
    comment: string
    floor: string
    number: string
    postal_code: string
    province: string
    street: string
  }
  card_last_digits: string
  card_type: string
  created_at: string
  country_code: string
  datetime: string
  debt_amount: number
  discount_code: string
  discount_id: string
  full_address_string: string
  guid: string
  id: number
  core_id: number
  operation_number: number
  payment_type: string
  status: string
  transport_cost: number
  transaction: Transaction
  user: User
}

export type Operation = {
  id: number
  coreID: number
  coreType: string
  status: string
}

export type Photo = {
  id: number
  mainPhoto: boolean
  url: string
}

type Transaction = {
  id: number
  transaction_status_id: number
}

export type FetchRemovals = {
  type: RemovalTableTypes.FETCH_REMOVALS
}

export type FetchRemovalsSuccess = {
  type: RemovalTableTypes.FETCH_REMOVALS_SUCCESS
  payload: {
    removals: Removal[]
    quantity: number
    params: Params
  }
}

export type FetchRemovalsFail = {
  type: RemovalTableTypes.FETCH_REMOVALS_FAIL
  payload: {
    error: string
  }
}

export type SetSorter = {
  type: RemovalTableTypes.SET_SORTER
  payload: {
    sorter: RemovalSorter
  }
}

export type SetFilter = {
  type: RemovalTableTypes.SET_FILTER
  payload: {
    filter: RemovalFilter
  }
}

export type SetPagination = {
  type: RemovalTableTypes.SET_PAGINATION
  payload: {
    pagination: PaginationConfig
  }
}

export type SetSearchFilters = {
  type: RemovalTableTypes.SET_SEARCH_FILTERS
  payload: {
    searchFilters: RemovalSearchFilter[]
  }
}

export type CleanFilters = {
  type: RemovalTableTypes.CLEAN_FILTERS
}

export type RemovalActions =
  | FetchRemovals
  | FetchRemovalsSuccess
  | FetchRemovalsFail
  | SetSorter
  | SetFilter
  | SetPagination
  | SetSearchFilters
  | CleanFilters
  | UpdateDebtStatusSuccess
  | InformPaymentSuccess
