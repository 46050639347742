import { Modal } from 'antd'
import React from 'react'
import { useLocalization } from '@/packages/localization'
import { WarningSign } from '@/icons'
import Text from 'antd/lib/typography/Text'
import styles from './ComplaintUpdateView.module.scss'
import { TableActionBar } from '@/components/TableActionBar'

export type ConfirmCloseModalProps = {
  confirmationOpen: boolean
  setConfirmationOpen: ({ confirmationOpen }: { confirmationOpen: boolean }) => void
  setOpenResolve: ({ open }: { open: boolean }) => void
  handleConfirmClose: () => void
}

const ConfirmResolveComponent: React.FC<ConfirmCloseModalProps> = ({
  confirmationOpen,
  setConfirmationOpen,
  setOpenResolve,
  handleConfirmClose
}) => {
  const { strings } = useLocalization()

  return (
    <Modal
      centered
      closable={false}
      width={700}
      onOk={() => {}}
      okText={strings.generic.confirm}
      visible={confirmationOpen}
      cancelText={strings.generic.cancel}
      title=""
      destroyOnClose
      footer={
        <div className={styles.confirmResolveFooter}>
          <TableActionBar.ButtonAction
            customClassName={styles.btnActions}
            variant="secondary"
            customIcon={<></>}
            onClickButton={() => {
              setOpenResolve({ open: true })
              setConfirmationOpen({ confirmationOpen: false })
            }}
            titleButton={strings.generic.cancel}
          />
          <TableActionBar.ButtonAction
            customIcon={<></>}
            titleButton={strings.generic.confirm}
            customClassName={styles.btnActions}
            onClickButton={handleConfirmClose}
          />
        </div>
      }>
      <div className={styles.confirmResolve}>
        <WarningSign />
        <Text>{strings.ComplaintsViewScreen.resolveModal.confirmation}</Text>
      </div>
    </Modal>
  )
}

export default ConfirmResolveComponent
