import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { History } from 'history'
import { FlagOption, SearchFilter, Sorter } from '../entities'
import { getSearchProps, getSortProps } from '../utils'

type KeysTableProps = {
  flags: FlagOption[]
  pagination: Pagination
  searchFilters: SearchFilter[]
  loading: boolean
  sorter: Sorter<FlagOption>
  history: History
  actions: MenuAction<FlagOption>[]
  handleSort: (newSorter: Sorter<FlagOption>) => void
  handleSearch: (key: string, newValue: string) => void
}

const FlagsTable: React.FC<KeysTableProps> = ({
  flags,
  searchFilters,
  loading,
  pagination,
  sorter,
  actions,
  handleSort,
  handleSearch
}) => {
  const columns: Column<FlagOption>[] = [
    {
      key: 'ID',
      label: 'ID',
      search: getSearchProps('ID', 'ID', handleSearch, searchFilters),
      sort: getSortProps('ID', sorter, handleSort)
    },
    {
      key: 'Name',
      label: 'Nombre',
      search: getSearchProps('Name', 'Nombre', handleSearch, searchFilters),
      renderDataCell: ({ Name }) => Name
    },
    {
      key: 'Value',
      label: 'Valor',
      renderDataCell: ({ Value }) => Value
    }
  ]
  return (
    <DynamicTable
      columns={columns}
      loading={loading}
      rows={flags}
      keyExtractor={(entity) => entity.ID}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default FlagsTable
