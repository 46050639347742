import React from 'react'
import styles from './SubComponents.module.scss'
import { StatusCardSkeleton } from '../StatusCard'
import clsx from 'clsx'

export type StatusCardContainerProps = {
  children: React.ReactNode
  fetchingMetrics?: boolean
  lengthSkeletons?: number
  customClassName?: string
}

export const StatusCardContainer: React.FC<StatusCardContainerProps> = ({
  children,
  fetchingMetrics,
  lengthSkeletons,
  customClassName
}) => {
  return (
    <div className={clsx(styles.statusCardContainer, customClassName)}>
      {fetchingMetrics ? (
        Array.from({ length: lengthSkeletons || 1 }, (_, index) => (
          <StatusCardSkeleton key={`StatusCardSkeleton-request-${index + 1}`} loadingColor="#5D58F7" />
        ))
      ) : (
        <>{children}</>
      )}
    </div>
  )
}
