import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { SaveUserBillingInformationRequest } from './common'

export function save(params: SaveUserBillingInformationRequest): Promise<void> {
  const snakeParams = snakecaseKeys({ ...params })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/user-billing-information`, snakeParams)
    .then((response: AxiosResponse<void>) => {})
    .catch((err) => {
      throw err
    })
}
