import React from 'react'
import actionCreators from '../actions/delete'
import { useDispatch, useSelector } from 'react-redux'
import DeleteLead from '../components/delete'
import { bindActionCreators } from 'redux'

const CreateLeadContainer = (ownProps) => {
  const dispatch = useDispatch()
  const { delete: deleteLead } = useSelector((state) => state.Leads)
  const actions = bindActionCreators(actionCreators, dispatch)

  const props = {
    ...deleteLead,
    ...actions
  }

  return <DeleteLead {...props} />
}

export default CreateLeadContainer
