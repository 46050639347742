import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { Pagination } from '../../../utils/searchFilterUtils'
import {
  GET_AGENTS_FAILURE,
  GET_AGENTS_REQUEST,
  GET_AGENTS_SUCCESS,
  GetAgentsRequest,
  GetAgentsSuccess
} from '../types/agentsTable'

const AgentsTableActionCreator = {
  getAgents:
    ({ pagination }: { pagination: Pagination }): AppThunk =>
    async (dispatch) => {
      const request: GetAgentsRequest = {
        type: GET_AGENTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page
        }
      }

      dispatch(request)

      try {
        const { agents, total } = await API.Mercurio.BO.Agents.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize
        })

        const success: GetAgentsSuccess = {
          type: GET_AGENTS_SUCCESS,
          payload: { agents, total }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_AGENTS_FAILURE, dispatch)
      }
    }
}

export default AgentsTableActionCreator
