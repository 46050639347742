import React from 'react'
import styles from './tripsMetrics.module.scss'
import StatusCard, { StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { StatusCardContainer } from '../../../components/StatusCard/subComponents/StatusCardContainer'
import { TripsMetric } from '../types/tripsMetrics'
import { formatNumber } from '../../../utils/formatNumber'

export type ObjectMetricsProps = {
  metrics: TripsMetric[]
  fetchingMetrics: boolean
}

const TripsMetrics: React.FC<ObjectMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const lengthSkeletons = Object.keys(mapOfTripsMetricTypes).length

  return (
    <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={lengthSkeletons}>
      {metrics.map(({ id, quantity, metric }) => (
        <StatusCard
          key={id}
          type={mapOfTripsMetricTypes[id]}
          customClassname={styles.customCard}
          titleCard={metric}
          total={formatNumber(quantity, 0, true)}
        />
      ))}
    </StatusCardContainer>
  )
}

export default TripsMetrics

const mapOfTripsMetricTypes: { [key: string]: StatusCardTypes } = {
  total: 'infoSolid',
  quantityProgrammedTrips: 'info',
  quantityAssignedTrips: 'success',
  quantityInProgressTrips: 'warning',
  quantityFinishedTrips: 'success',
  quantityCancelledTrips: 'danger'
}

export const mapOfTripsMetrics: { [key: string]: string } = {
  total: 'Total',
  quantityProgrammedTrips: 'Programados',
  quantityAssignedTrips: 'Asignados',
  quantityInProgressTrips: 'En Curso',
  quantityFinishedTrips: 'Finalizados',
  quantityCancelledTrips: 'Cancelados'
}
