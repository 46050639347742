import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import singleDepositsActionCreators from '../actionCreators/singleDeposit'
import SingleDeposit from '../components/singleDeposit'
import CarouselModal from '../../../components/CarouselModal/containers/CarouselModal'
import RemittanceModalActionCreators from '../../RemittanceModal/actionCreators'
import RemittanceModalContainer from '../../RemittanceModal/container'

interface ParamTypes {
  depositId: number
}

const useParsedParams = (): ParamTypes => {
  const { depositId } = useParams<{ depositId: string }>()

  return { depositId: parseInt(depositId) }
}

const SingleDepositContainer = () => {
  const singleDepositViewState = useSelector((root) => root.SingleDeposit.main)
  const countries = useSelector((root) => root.CountrySelector.countries)
  const dispatch = useDispatch()
  const fetchSingleDepositData = bindActionCreators(singleDepositsActionCreators.fetchSingleDepositData, dispatch)
  const { depositId } = useParsedParams()
  const openRemittanceModal = bindActionCreators(RemittanceModalActionCreators.setOpen, dispatch)

  const handleOpenRemmitanceModal = (transactionId: number) => {
    openRemittanceModal(true, transactionId)
  }

  useEffect(() => {
    if (!depositId) return

    fetchSingleDepositData({ depositId })
  }, [depositId])

  return (
    <>
      <RemittanceModalContainer />
      <CarouselModal />
      <SingleDeposit
        {...singleDepositViewState}
        countries={countries}
        openRemittanceModal={handleOpenRemmitanceModal}
      />
    </>
  )
}

export default SingleDepositContainer
