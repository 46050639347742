import clsx from 'clsx'
import React from 'react'
import { formatMoney } from '../../../../utils/formatNumber'
import styles from '../SalesRequestView.module.scss'

interface DataProps {
  data: DataDisplay[]
}

export interface DataDisplay {
  label: string
  value: number | null
}

export const DataDisplayBox: React.FC<DataProps> = ({ data }) => {
  return (
    <div className={styles.summary}>
      {data.map((d) => (
        <p key={d.label}>
          <span>{d.label}:</span>
          <span className={clsx(styles.values, d.value && d.value < 0 && styles.negativeNum)}>
            {!d.value || d.value === 0 ? '$ -' : formatMoney(d.value)}
          </span>
        </p>
      ))}
    </div>
  )
}
