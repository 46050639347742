import { Reducer } from 'redux'
import {
  BillingByClientAction,
  BillingByClientState,
  GET_BILLING_BY_CLIENT_FAILURE,
  GET_BILLING_BY_CLIENT_REQUEST,
  GET_BILLING_BY_CLIENT_SUCCESS,
  GET_CLIENT_DISCOUNTS_FAILURE,
  GET_CLIENT_DISCOUNTS_REQUEST,
  GET_CLIENT_DISCOUNTS_SUCCESS,
  GET_PDF_BY_CLIENT_FAILURE,
  GET_PDF_BY_CLIENT_REQUEST,
  GET_PDF_BY_CLIENT_SUCCESS,
  GetClientBillingInformationTypes,
  SET_QUERY_DATA
} from '../types/billingByClientTable'

const initialState: BillingByClientState = {
  clientBillingInformation: null,
  fetchingClientBillingInformation: false,
  errorClientBillingInformation: '',
  billingsByClient: [],
  loadingBillingsByClient: false,
  loadingBillingDiscounts: false,
  discounts: [],
  error: '',
  queryData: {
    userId: 0,
    name: '',
    lastName: ''
  },
  errorBillingDiscounts: '',
  pagination: {
    page: 1,
    total: 0,
    pageSize: 10
  }
}

const BillingByClientReducer: Reducer<BillingByClientState, BillingByClientAction> = (
  state = initialState,
  action
): BillingByClientState => {
  switch (action.type) {
    case GET_BILLING_BY_CLIENT_REQUEST:
      return {
        ...state,
        loadingBillingsByClient: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }

    case GET_BILLING_BY_CLIENT_SUCCESS:
      return {
        ...state,
        billingsByClient: action.payload.billingsByClient,
        loadingBillingsByClient: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }

    case GET_BILLING_BY_CLIENT_FAILURE:
      return { ...state, loadingBillingsByClient: false, error: action.payload.error }
    case GET_PDF_BY_CLIENT_REQUEST: {
      return {
        ...state,
        loadingBillingsByClient: true,
        error: ''
      }
    }
    case GET_PDF_BY_CLIENT_SUCCESS: {
      return {
        ...state,
        loadingBillingsByClient: false,
        error: ''
      }
    }
    case GET_PDF_BY_CLIENT_FAILURE:
      return {
        ...state,
        loadingBillingsByClient: false,
        error: ''
      }
    case GET_CLIENT_DISCOUNTS_REQUEST:
      return {
        ...state,
        loadingBillingDiscounts: true,
        error: ''
      }
    case GET_CLIENT_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        loadingBillingDiscounts: false,
        billingsByClient: state.billingsByClient.map((b) =>
          b.id === action.payload.billingId
            ? {
                ...b,
                objects: b.objects.map((obj) => ({
                  ...obj,
                  objectApplications: state.billingsByClient.map((b) =>
                    b.applications
                      ? b.applications.filter((app) => app.entityType === 'OBJECT' && app.entityId === obj.itemId)
                      : null
                  )[0],
                  discount: action.payload.discounts.find((d) => d.billingDetailId === obj.id) || null
                }))
              }
            : b
        )
      }
    }
    case GET_CLIENT_DISCOUNTS_FAILURE:
      return {
        ...state,
        loadingBillingDiscounts: false,
        error: ''
      }
    case SET_QUERY_DATA:
      return {
        ...state,
        queryData: action.payload.queryData
      }
    case GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_REQUEST:
      return {
        ...state,
        fetchingClientBillingInformation: true,
        errorClientBillingInformation: ''
      }
    case GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_SUCCESS:
      return {
        ...state,
        fetchingClientBillingInformation: false,
        clientBillingInformation: action.payload.clientBillingInformation
      }
    case GetClientBillingInformationTypes.GET_CLIENT_BILLING_INFORMATION_FAILURE:
      return {
        ...state,
        fetchingClientBillingInformation: false,
        errorClientBillingInformation: action.payload.error
      }
    default:
      return { ...state }
  }
}
export default BillingByClientReducer
