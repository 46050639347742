import { combineReducers } from 'redux'
import ProposalReducer from './reducers/proposals'
import sendByEmailReducer from './reducers/sendByEmail'
import Details from './reducers/details'
import proposalMetricsReducer from './reducers/metrics'

export default combineReducers({
  table: ProposalReducer,
  sendByEmail: sendByEmailReducer,
  selectedProposal: Details,
  metrics: proposalMetricsReducer
})
