import React from 'react'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDays/list'
import { DiscountWeekdaysFilterKey, Weekdays } from '../../../projectApi/Timeslot/Discounts/SpecificDays/common'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { formatDate } from '../../../utils/formatDate'
import {
  DiscountDaysDateRangeFilter,
  DiscountDaysTableCategoryFilter,
  DiscountDaysTableDateRangeKey,
  DiscountDaysTableSort
} from '../types/discountDays'
import { Moment } from 'moment'
import { getRangePickerProps } from '../../../components/DynamicTable/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'

type DiscountDaysPageParams = {
  categoryFilters: DiscountDaysTableCategoryFilter
  discounts: Discount[]
  fetchingDiscounts: boolean
  handleRangePicker: (key: DiscountDaysTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  dateRangeFilters: DiscountDaysDateRangeFilter[]
  sort: DiscountDaysTableSort
  handleSort: (newSorter: DiscountDaysTableSort) => void
  handleWeekdayFilter: (categoryFilters: DiscountDaysTableCategoryFilter) => void
  pagination: Pagination
  actions: MenuAction<Discount>[]
}

const weekdaysFilter = [
  {
    text: Weekdays.LUNES,
    value: Weekdays.LUNES
  },
  {
    text: Weekdays.MARTES,
    value: Weekdays.MARTES
  },
  {
    text: Weekdays.MIERCOLES,
    value: Weekdays.MIERCOLES
  },
  {
    text: Weekdays.JUEVES,
    value: Weekdays.JUEVES
  },
  {
    text: Weekdays.VIERNES,
    value: Weekdays.VIERNES
  },
  {
    text: Weekdays.SABADO,
    value: Weekdays.SABADO
  },
  {
    text: Weekdays.DOMINGO,
    value: Weekdays.DOMINGO
  }
]

const DiscountDaysPage = ({
  categoryFilters,
  discounts,
  fetchingDiscounts,
  pagination,
  handleRangePicker,
  dateRangeFilters,
  sort,
  handleSort,
  handleWeekdayFilter,
  actions
}: DiscountDaysPageParams) => {
  const columns: Column<Discount>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'weekday',
      label: 'Día',
      filters: {
        multiple: false,
        options: weekdaysFilter,
        selected: categoryFilters.weekday,
        onConfirm: (selected) =>
          handleWeekdayFilter({ ...categoryFilters, weekday: selected as DiscountWeekdaysFilterKey[] })
      }
    },
    {
      key: 'discount',
      label: 'Descuento (%)',
      renderDataCell: ({ discount }) => `${discount}%`
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      renderDataCell: ({ createdAt }) => formatDate(createdAt, '-'),
      rangePicker: getRangePickerProps('createdAt', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'updatedAt',
      label: 'Fecha de modificación',
      renderDataCell: ({ updatedAt }) => formatDate(updatedAt, '-')
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDiscounts}
      rows={discounts}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default DiscountDaysPage
