import { CountryPhoneCode } from '../components/CountrySelector/constants/constants'

export type CountryPrefig = {
  value: CountryPhoneCode
  label: string
  image: string
  alt: string
}

export const countriesList = [
  {
    value: CountryPhoneCode.SPAIN,
    label: CountryPhoneCode.SPAIN,
    image: '/img/newflags/es-color.svg',
    alt: 'España'
  },
  {
    value: CountryPhoneCode.FRANCE,
    label: CountryPhoneCode.FRANCE,
    image: '/img/newflags/fr-color.png',
    alt: 'Francia'
  },
  {
    value: CountryPhoneCode.PORTUGAL,
    label: CountryPhoneCode.PORTUGAL,
    image: '/img/newflags/pr-color.png',
    alt: 'Portugal'
  },
  {
    value: CountryPhoneCode.ITALY,
    label: CountryPhoneCode.ITALY,
    image: '/img/newflags/it-color.png',
    alt: 'Italia'
  },
  {
    value: CountryPhoneCode.GERMANY,
    label: CountryPhoneCode.GERMANY,
    image: '/img/newflags/gr-color.png',
    alt: 'Alemania'
  },
  {
    value: CountryPhoneCode.SWITZERLAND,
    label: CountryPhoneCode.SWITZERLAND,
    image: '/img/newflags/sw-color.png',
    alt: 'Suiza'
  },
  {
    value: CountryPhoneCode.ARGENTINA,
    label: CountryPhoneCode.ARGENTINA,
    image: '/img/newflags/arg-color.svg',
    alt: 'Argentina'
  },
  {
    value: CountryPhoneCode.BRAZIL,
    label: CountryPhoneCode.BRAZIL,
    image: '/img/newflags/br-color.svg',
    alt: 'Brazil'
  },
  {
    value: CountryPhoneCode.COLOMBIA,
    label: CountryPhoneCode.COLOMBIA,
    image: '/img/newflags/co-color.svg',
    alt: 'Colombia'
  },
  {
    value: CountryPhoneCode.COSTA_RICA,
    label: CountryPhoneCode.COSTA_RICA,
    image: '/img/newflags/cr-color.svg',
    alt: 'Costa Rica'
  },
  {
    value: CountryPhoneCode.ECUADOR,
    label: CountryPhoneCode.ECUADOR,
    image: '/img/newflags/ec-color.svg',
    alt: 'Ecuador'
  },
  {
    value: CountryPhoneCode.MEXICO,
    label: CountryPhoneCode.MEXICO,
    image: '/img/newflags/mx-color.svg',
    alt: 'Mexico'
  },
  {
    value: CountryPhoneCode.PERU,
    label: CountryPhoneCode.PERU,
    image: '/img/newflags/pe-color.svg',
    alt: 'Peru'
  },
  {
    value: CountryPhoneCode.URUGUAY,
    label: CountryPhoneCode.URUGUAY,
    image: '/img/newflags/uy-color.svg',
    alt: 'Uruguay'
  },
  {
    value: CountryPhoneCode.UNITED_STATES,
    label: CountryPhoneCode.UNITED_STATES,
    image: '/img/newflags/us-color.svg',
    alt: 'United States'
  }
]
