import { UserBillingInformation } from '../../../projectApi/TransactionHandler/UserBillingInformation/getByUserId'
import { TaxCondition } from '../Users.constants'

export enum BillingModalTypes {
  OPEN_BILLING_MODAL = 'USERS/BILLING/OPEN_BILLING_MODAL',
  CLOSE_BILLING_MODAL = 'USERS/BILLING/CLOSE_BILLING_MODAL',
  CANCEL_BILLING_MODAL = 'USERS/BILLING/CANCEL_BILLING_MODAL',

  GET_BILLING_DATA_BY_USER_ID_REQUEST = 'USERS/BILLING/GET_BILLING_DATA_BY_USER_ID_REQUEST',
  GET_BILLING_DATA_BY_USER_ID_SUCCESS = 'USERS/BILLING/GET_BILLING_DATA_BY_USER_ID_SUCCESS',
  GET_BILLING_DATA_BY_USER_ID_FAILURE = 'USERS/BILLING/GET_BILLING_DATA_BY_USER_ID_FAILURE',

  UPDATE_BILLING_DATA_BY_ID_REQUEST = 'USERS/BILLING/UPDATE_BILLING_DATA_BY_USER_ID_REQUEST',
  UPDATE_BILLING_DATA_BY_ID_SUCCESS = 'USERS/BILLING/UPDATE_BILLING_DATA_BY_USER_ID_SUCCESS',
  UPDATE_BILLING_DATA_BY_ID_FAILURE = 'USERS/BILLING/UPDATE_BILLING_DATA_BY_USER_ID_FAILURE',

  SAVE_BILLING_DATA_BY_USER_ID_REQUEST = 'USERS/BILLING/SAVE_BILLING_DATA_BY_USER_ID_REQUEST',
  SAVE_BILLING_DATA_BY_USER_ID_SUCCESS = 'USERS/BILLING/SAVE_BILLING_DATA_BY_USER_ID_SUCCESS',
  SAVE_BILLING_DATA_BY_USER_ID_FAILURE = 'USERS/BILLING/SAVE_BILLING_DATA_BY_USER_ID_FAILURE',

  SET_USER_ID = 'USERS/BILLING/SET_USER_ID',
  SET_IDENTIFICATION_TYPE = 'USERS/BILLING/SET_IDENTIFICATION_TYPE',
  SET_IDENTIFICATION_CODE = 'USERS/BILLING/SET_IDENTIFICATION_CODE',
  SET_IDENTIFICATION_NAME_CLIENT = 'USERS/BILLING/SET_IDENTIFICATION_NAME_CLIENT',
  SET_BILLING_ADDRESS = 'USERS/BILLING/SET_BILLING_ADDRESS',
  SET_TAX_CONDITION = 'USERS/BILLING/SET_TAX_CONDITION',
  SET_SALE_CONDITION = 'USERS/BILLING/SET_SALE_CONDITION',
  SET_EMAIL = 'USERS/BILLING/SET_EMAIL',
  SET_CITY = 'USERS/BILLING/SET_CITY',
  SET_PROVINCE = 'USERS/BILLING/SET_PROVINCE',
  SET_POSTAL_CODE = 'USERS/BILLING/SET_POSTAL_CODE',
  SET_BILLING_ENABLED = 'USERS/BILLING/SET_BILLING_ENABLED',
  SET_AUTHORIZATION_ENABLED = 'USERS/BILLING/SET_AUTHORIZATION_ENABLED'
}

export interface OpenBillingModal {
  type: BillingModalTypes.OPEN_BILLING_MODAL
  payload: {
    userId: number
    country: string
  }
}

export interface CloseBillingModal {
  type: BillingModalTypes.CLOSE_BILLING_MODAL
}

export interface CancelBillingModal {
  type: BillingModalTypes.CANCEL_BILLING_MODAL
}

export interface GetBillingDataByUserIdRequest {
  type: BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_REQUEST
}

export interface GetBillingDataByUserIdSuccess {
  type: BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_SUCCESS
  payload: UserBillingInformation
}

export interface GetBillingDataByUserIdFailure {
  type: BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_FAILURE
  payload: {
    error: string
  }
}

export interface UpdateBillingDataByIdRequest {
  type: BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_REQUEST
}

export interface UpdateBillingDataByIdSuccess {
  type: BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_SUCCESS
}

export interface UpdateBillingDataByIdFailure {
  type: BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_FAILURE
  payload: {
    error: string
  }
}

export interface SaveBillingDataByUserIdRequest {
  type: BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_REQUEST
}

export interface SaveBillingDataByUserIdSuccess {
  type: BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_SUCCESS
}

export interface SaveBillingDataByUserIdFailure {
  type: BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_FAILURE
  payload: {
    error: string
  }
}

export interface SetIdentificationType {
  type: BillingModalTypes.SET_IDENTIFICATION_TYPE
  payload: {
    identificationType: string
  }
}

export interface SetIdentificationCode {
  type: BillingModalTypes.SET_IDENTIFICATION_CODE
  payload: {
    identificationCode: string
  }
}

export interface SetIdentificationNameClient {
  type: BillingModalTypes.SET_IDENTIFICATION_NAME_CLIENT
  payload: {
    identificationNameClient: string
  }
}

export interface SetBillingAddress {
  type: BillingModalTypes.SET_BILLING_ADDRESS
  payload: {
    billingAddress: string
  }
}

export interface SetTaxCondition {
  type: BillingModalTypes.SET_TAX_CONDITION
  payload: {
    taxCondition: TaxCondition
  }
}

export interface SetSaleCondition {
  type: BillingModalTypes.SET_SALE_CONDITION
  payload: {
    saleCondition: number
  }
}

export interface SetEmail {
  type: BillingModalTypes.SET_EMAIL
  payload: {
    email: string
  }
}

export interface SetCity {
  type: BillingModalTypes.SET_CITY
  payload: {
    city: string
  }
}

export interface SetProvince {
  type: BillingModalTypes.SET_PROVINCE
  payload: {
    province: string
  }
}

export interface SetPostalCode {
  type: BillingModalTypes.SET_POSTAL_CODE
  payload: {
    postalCode: string
  }
}

export interface SetBillingEnabled {
  type: BillingModalTypes.SET_BILLING_ENABLED
  payload: {
    billingEnabled: boolean
  }
}

export interface SetAuthorizationEnabled {
  type: BillingModalTypes.SET_AUTHORIZATION_ENABLED
  payload: {
    authorizationEnabled: boolean
  }
}

export type BillingModalActions =
  | OpenBillingModal
  | CloseBillingModal
  | CancelBillingModal
  | GetBillingDataByUserIdRequest
  | GetBillingDataByUserIdSuccess
  | GetBillingDataByUserIdFailure
  | UpdateBillingDataByIdRequest
  | UpdateBillingDataByIdSuccess
  | UpdateBillingDataByIdFailure
  | SaveBillingDataByUserIdRequest
  | SaveBillingDataByUserIdSuccess
  | SaveBillingDataByUserIdFailure
  | SetIdentificationType
  | SetIdentificationCode
  | SetIdentificationNameClient
  | SetBillingAddress
  | SetTaxCondition
  | SetSaleCondition
  | SetEmail
  | SetCity
  | SetProvince
  | SetPostalCode
  | SetBillingEnabled
  | SetAuthorizationEnabled
