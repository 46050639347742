/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { Moment } from 'moment'

export const MINUTES_BETWEEN_REPORTS = 10

interface Params {
  dateFrom: Moment
}

export function billings({ dateFrom }: Params): Promise<void> {
  const params = snakecaseKeys({ dateFrom: dateFrom.format('YYYY-MM-DDTHH:mm:ssZ') })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/reports/billings`, params)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
