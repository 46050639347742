import {
  CLEAR_STATE,
  ClearState,
  GET_METRICS_OBJECTS_FAILURE,
  GET_METRICS_OBJECTS_REQUEST,
  GET_METRICS_OBJECTS_SUCCESS,
  GET_OBJECTS_FAILURE,
  GET_OBJECTS_REQUEST,
  GET_OBJECTS_STATUS_FAILURE,
  GET_OBJECTS_STATUS_REQUEST,
  GET_OBJECTS_STATUS_SUCCESS,
  GET_OBJECTS_SUCCESS,
  GetMetricsObjectsRequest,
  GetMetricsObjectsSuccess,
  GetObjectsRequest,
  GetObjectsStatusSuccess,
  GetObjectsSuccess,
  ObjectsSearchFilter,
  ObjectsTableCategoryFilter,
  ObjectsTableSort,
  TOGGLE_ALL_OBJECTS,
  TOGGLE_EXACT_LOCATION_CODE,
  TOGGLE_SELECTED_OBJECT,
  ToggleAllObjects,
  ToggleExactLocationCode,
  ToggleSelectedObject
} from '../types/objectsTable'
import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import { ObjectStatus } from '../../../../projectApi/ObjectAdministration/ObjectStatus/search'
import { FormattedObject } from '../../../../projectApi/ObjectAdministration/common'

const ObjectsTableActionCreators = {
  toggleExactLocationCode: (): ToggleExactLocationCode => ({
    type: TOGGLE_EXACT_LOCATION_CODE
  }),
  toggleSelectedObject: (object: FormattedObject): ToggleSelectedObject => ({
    type: TOGGLE_SELECTED_OBJECT,
    payload: { object }
  }),
  toggleAllObjects: (): ToggleAllObjects => ({
    type: TOGGLE_ALL_OBJECTS
  }),
  getObjects:
    ({
      pagination,
      searchFilters,
      sort,
      categoryFilter,
      silentLoading
    }: {
      pagination: Pagination
      searchFilters?: ObjectsSearchFilter[]
      categoryFilter?: ObjectsTableCategoryFilter
      sort?: ObjectsTableSort
      silentLoading?: boolean
    }): AppThunk =>
    async (dispatch) => {
      const request: GetObjectsRequest = {
        type: GET_OBJECTS_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          searchFilters,
          categoryFilter,
          sort,
          silentLoading
        }
      }

      dispatch(request)

      try {
        const { objects, total } = await API.ObjectAdministration.Object.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          order: sort?.direction,
          column: sort?.field,
          status: categoryFilter?.status[0],
          situation: categoryFilter?.objectSituation.join(),
          withDeletedPhotos: true,
          withDeleted: true,
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetObjectsSuccess = {
          type: GET_OBJECTS_SUCCESS,
          payload: {
            objects,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_OBJECTS_FAILURE, dispatch)
      }
    },

  getObjectsStatusSuccess: (objectStatus: ObjectStatus[]): GetObjectsStatusSuccess => ({
    type: GET_OBJECTS_STATUS_SUCCESS,
    payload: { objectStatus }
  }),

  getStatusObjects(): AppThunk {
    return async (dispatch) => {
      dispatch({ type: GET_OBJECTS_STATUS_REQUEST })

      try {
        const response = await API.ObjectAdministration.ObjectStatus.search()
        dispatch(ObjectsTableActionCreators.getObjectsStatusSuccess(response))
      } catch (error) {
        handleErrorsWithAction(error, GET_OBJECTS_STATUS_FAILURE, dispatch)
      }
    }
  },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  getMetricsObjects:
    ({
      searchFilters,
      categoryFilter,
      ownerId
    }: {
      searchFilters?: ObjectsSearchFilter[]
      categoryFilter?: ObjectsTableCategoryFilter
      ownerId?: number
    }): AppThunk =>
    async (dispatch) => {
      const request: GetMetricsObjectsRequest = {
        type: GET_METRICS_OBJECTS_REQUEST,
        payload: {
          searchFilters,
          categoryFilter
        }
      }
      dispatch(request)

      try {
        const { total, totalM3, cataloging, locating, takingPhotos, saved } =
          await API.ObjectAdministration.Corporate.metrics({
            status: categoryFilter?.status[0],
            situation: categoryFilter?.objectSituation.join(),
            ...searchFiltersToParams(searchFilters),
            ownerId
          })

        const success: GetMetricsObjectsSuccess = {
          type: GET_METRICS_OBJECTS_SUCCESS,
          payload: { total, totalM3, cataloging, locating, takingPhotos, saved }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_METRICS_OBJECTS_FAILURE, dispatch)
      }
    }
}

export default ObjectsTableActionCreators
