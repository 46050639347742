import React from 'react'
import { Column } from '../../../components/DynamicTable/types/types'
import { DynamicTable } from '../../../components/DynamicTable'
import ActionBar from '../../../components/ActionBar/ActionBar'
import ActionBarButton from '../../../components/ActionBarButton/ActionBarButton'
import { Check as CheckIcon, Cross as CrossIcon, Edit as EditIcon } from '../../../icons'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import { useCountries } from '../../../components/CountrySelector/hooks/hooks'
import { Address } from '../../../projectApi/AccountManager/common'
import { AddressUserState } from '../types/address'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { AddressModalActionCreator } from '../actions/addressModal'
import { DeleteAddressActionCreator } from '../actions/deleteAddress'
import { DefaultAddressActionCreator } from '../actions/defaultAddress'
import AddressModal from '../containers/addressModal'
import DeleteAddress from '../containers/deleteAddress'
import DefaultAddress from '../containers/defaultAddress'
import { useHistory } from 'react-router'

export type AddressTableProps = {
  addressesState: AddressUserState
  addressModalActions: typeof AddressModalActionCreator
  deleteAddressActionsCreator: typeof DeleteAddressActionCreator
  defaultAddressActionsCreator: typeof DefaultAddressActionCreator
  userId: number
  name: string
  lastName: string
}

const AddressTable: React.FC<AddressTableProps> = (props: AddressTableProps) => {
  const {
    addressesState,
    addressModalActions,
    deleteAddressActionsCreator,
    defaultAddressActionsCreator,
    userId,
    name,
    lastName
  } = props
  const { addresses, loading } = addressesState
  const countries = useCountries()
  const history = useHistory()

  const handleReturnButton = () => {
    history.goBack()
  }

  const columns: Column<Address>[] = [
    {
      key: 'country',
      label: 'País',
      renderDataCell: ({ country }) => {
        const flag = getCurrentCountry(countries, country)
        if (!flag) return '-'
        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'userId',
      label: 'ID Cliente'
    },
    {
      key: 'province',
      label: 'Provincia'
    },
    {
      key: 'city',
      label: 'Ciudad'
    },
    {
      key: 'address',
      label: 'Dirección'
    },
    {
      key: 'number',
      label: 'Numero'
    },
    {
      key: 'floor',
      label: 'Piso',
      renderDataCell: ({ floor }) => {
        if (!floor) return '-'
        return floor
      }
    },
    {
      key: 'apartment',
      label: 'Departamento',
      renderDataCell: ({ apartment }) => {
        if (!apartment) return '-'
        return apartment
      }
    },
    {
      key: 'inDeliveryRange',
      label: 'Permite envíos',
      renderDataCell: ({ inDeliveryRange }) => {
        if (!inDeliveryRange) return 'No'
        return 'Sí'
      }
    },
    {
      key: 'main',
      label: 'Dirección Principal',
      renderDataCell: ({ main }) => {
        if (!main) return <CrossIcon />
        return <CheckIcon />
      }
    }
  ]

  /*
  const handleCloseCancelingModal = () => setCancelingModalId(null)
*/
  const AssignmentButton = () => (
    <ActionBarButton
      action={() => addressModalActions.openModal(userId)}
      icon="user"
      description={`Crear nueva dirección`}
    />
  )

  const GoBackButton = () => <ActionBarButton action={handleReturnButton} description="Volver" icon="arrow-left" />

  const addressActions: MenuAction<Address>[] = [
    {
      label: 'Editar Dirección',
      icon: <EditIcon />,
      onClick: ({ userId, id }) => {
        addressModalActions.openModal(userId, true, id)
      }
    },
    {
      label: 'Eliminar Dirección',
      icon: <CrossIcon height="12px" width="12px" fill="currentColor" />,
      onClick: ({ userId, id }) => {
        deleteAddressActionsCreator.openModal(userId, id)
      }
    },
    {
      label: 'Marcar como principal',
      icon: <CheckIcon height="12px" width="12px" fill="currentColor" />,
      onClick: ({ userId, id, address }) => {
        defaultAddressActionsCreator.openModal(userId, id, address)
      },
      disabled: (address) => !!address?.main
    }
  ]

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ActionBar>
          <GoBackButton />
        </ActionBar>
        <ActionBar>
          <AssignmentButton />
        </ActionBar>
      </div>
      <h3>
        Detalle del cliente: {name} {lastName}
      </h3>
      <DynamicTable
        columns={columns}
        loading={loading}
        actions={addressActions}
        rows={addresses}
        keyExtractor={(entity) => entity.id}
      />
      <AddressModal />
      <DeleteAddress />
      <DefaultAddress />
    </div>
  )
}

export default AddressTable
