import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

export type EditLocationParams = {
  code: string
  description: string
  locationId: number
  maxHeightInCm: number
  maxLengthInCm: number
  maxVolumeInCm: number
  maxWeightInKg: number
  maxWidthInCm: number
}

export type EditLocationSnakeCase = {
  code: string
  description: string
  location_id: number
  max_height_in_cm: number
  max_length_in_cm: number
  max_volume_in_cm: number
  max_weight_in_gr: number
  max_width_in_cm: number
}

export function edit(params: EditLocationParams): Promise<boolean> {
  const body: EditLocationSnakeCase = {
    code: params.code,
    description: params.description,
    location_id: params.locationId,
    max_height_in_cm: params.maxHeightInCm,
    max_length_in_cm: params.maxLengthInCm,
    max_width_in_cm: params.maxWidthInCm,
    max_weight_in_gr: params.maxWeightInKg * 1000,
    max_volume_in_cm: params.maxVolumeInCm
  }

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/locations`, body)
    .then(() => true)
    .catch((error) => {
      throw error
    })
}
