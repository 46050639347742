import { ProvidersAPI } from './Providers'
import { ShippersAPI } from './Shippers'
import { TripAPI } from './Trip'
import { VehiclesAPI } from './Vehicles'

export const TripsAPI = {
  Trip: TripAPI,
  Providers: ProvidersAPI,
  Shippers: ShippersAPI,
  Vehicles: VehiclesAPI
}
