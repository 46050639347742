import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../../component/EditDepositView'
import actions from '../../actions/EditDepositViewActionCreators/EditDepositView'
import costActionsCreators from '../../actions/EditDepositViewActionCreators/cost'
import itemsActionsCreators from '../../actions/EditDepositViewActionCreators/items'
import serviceTypesAction from '../../actions/EditDepositViewActionCreators/serviceTypes'
import calendarActionsCreators from '../../actions/EditDepositViewActionCreators/calendar'
import transportActionsCreators from '../../actions/EditDepositViewActionCreators/transport'
import aditionalsActionsCreators from '../../actions/EditDepositViewActionCreators/aditionals'
import addressActionsCreators from '../../actions/EditDepositViewActionCreators/address'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import moment, { Moment } from 'moment'
import { TotalPrices } from '../../../../common/operations/components/Summary/Summary'
import { getFormatFirstTimeInterval } from '../../component/subComponents/utils'
import { TransportDetail } from '../../../../projectApi/TransactionHandler/Compute/cost'
import { sendToastNotificationError } from '../../../../utils/notifications'
import { useGetDisabledCalendarLazyQuery } from '../../../../projectApi/BFF/Deposits/getDepositCalendar.query.generated'
import { buildCalendarArray, findCalendarDiscount } from '../../../../components/Calendar/utils'
import { CalendarDay, CalendarInitialStateDay } from '../../../../components/Calendar/calendar.types'
import { DEFAULT_TODAY } from '../../../NewProposal/reducers/calendar'
import { CalendarTextFieldProps } from '../../component/subComponents/OperationDate'

const EditDepositContainer = () => {
  const calendarState = useSelector((state) => state.Deposits.calendar)
  const { transaction, loading } = useSelector((state) => state.Deposits.editView)
  const { serviceTypeList, serviceType, loadingTypes } = useSelector((state) => state.Deposits.serviceTypes)
  const {
    extraServices: { shipmentAssistants }
  } = useSelector((state) => state.Deposits.aditionals)
  const transportState = useSelector((state) => state.Deposits.transport)
  const itemsState = useSelector((state) => state.Deposits.items)
  const costState = useSelector((state) => state.Deposits.cost)
  const addressState = useSelector((state) => state.Deposits.address)

  const dispatch = useDispatch()

  const { getTransactionByOpId, getConfigurationFlags, editDeposit, getModifyReasons, setModifyReason } =
    bindActionCreators(actions, dispatch)
  const { getServiceTypes, setServiceType, setPriceBy } = bindActionCreators(serviceTypesAction, dispatch)
  const calendarActions = bindActionCreators(calendarActionsCreators, dispatch)
  const transportActions = bindActionCreators(transportActionsCreators, dispatch)
  const itemsActions = bindActionCreators(itemsActionsCreators, dispatch)
  const costActions = bindActionCreators(costActionsCreators, dispatch)
  const addressActions = bindActionCreators(addressActionsCreators, dispatch)
  const { setDisabledShipmentAssistants, setUpdatedExtraValue, getAdditionalFlags } = bindActionCreators(
    aditionalsActionsCreators,
    dispatch
  )

  const useParsedParams = (): { depositId: number } => {
    const { depositId } = useParams<{ depositId: string }>()

    return { depositId: parseInt(depositId) }
  }

  const history = useHistory()
  const { depositId } = useParsedParams()

  useEffect(() => {
    if (!depositId) return

    // @ts-ignore
    getServiceTypes().then(() => getTransactionByOpId({ depositId }))
    calendarActions.getUnavailableDates()
    getModifyReasons()
  }, [depositId])

  useEffect(() => {
    serviceType.value &&
      transportActions.getTransportModeByService(serviceType.value) &&
      itemsActions.getInitialItemsFiltered(serviceType.value, transaction.generalData.countryId as CountryIdCode)
  }, [serviceType])

  useEffect(() => {
    const userID = transaction.generalData.user.id
    const countryID = transaction.generalData.countryId as CountryIdCode
    if (userID) {
      addressActions.getAddresses(userID)
      itemsActions.getActualM3Storage(userID)
      itemsActions.getInitialItem(countryID, serviceType.value)
      getConfigurationFlags(countryID)
    }
  }, [transaction.generalData])

  const [monthGrid, setMonthGrid] = useState<CalendarDay[]>([])
  const [currentMonth, setCurrentMonth] = useState<Moment>(DEFAULT_TODAY)
  const [localDate, setLocalDate] = useState<CalendarInitialStateDay | null>({
    isInSelectedMonth: true,
    date: null
  })

  const [getCalendar, { loading: loadingCalendar }] = useGetDisabledCalendarLazyQuery({
    onCompleted: (data) => {
      const { getCalendar: calendarResponse } = data

      const populatedGrid = monthGrid.map((existingDate) => {
        const discount = findCalendarDiscount(existingDate.date, calendarResponse)
        return {
          ...existingDate,
          isDisabled: discount?.depositDisabled
        }
      })

      setMonthGrid(populatedGrid)
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: () => {
      sendToastNotificationError('Hubo un error al obtener el calendario')
    }
  })

  useEffect(() => {
    const calendar = buildCalendarArray(currentMonth)
    if (calendar.length === 0) return
    setMonthGrid(calendar)

    const today = moment().startOf('day')
    const firstDay = calendar.find((day) => !day.isBeforeToday && day.date.isAfter(today))
    const startDate = moment(firstDay?.date).format('YYYY-MM-DD')
    const lastDate = calendar[calendar.length - 1].date
    const endDate = moment(lastDate).format('YYYY-MM-DD')

    const variables = {
      startDate,
      endDate
    }
    getCalendar({ variables })
  }, [currentMonth])

  useEffect(() => {
    if (calendarState.datetime) {
      const dateInSeconds = Number(moment(calendarState.datetime).format('X'))
      calendarActions.getTimeslots({ dateInSeconds, selectFirstTimeslot: false })
      calendarActions.getTimeIntervals({ timeslotId: calendarState.timeslots.selected })
      calendarActions.getUnavailableDates()
    }
  }, [calendarState.datetime])

  const handleChangeMonth = (direction: 'next' | 'prev') => {
    const newMonth = moment(currentMonth).add(direction === 'next' ? 1 : -1, 'month')
    setCurrentMonth(newMonth)
  }

  const handleDayPress = (day: CalendarDay) => {
    setLocalDate(day)
    calendarActions.setAvailableDate(day.date)
    if (!moment(day.date).isSame(currentMonth, 'month')) {
      return setCurrentMonth(day.date)
    }
  }

  useEffect(() => {
    if (transaction.generalData.countryId && shipmentAssistants.shipmentAssistantPrice === 0) {
      getAdditionalFlags({ countryCode: transaction.generalData.countryId })
    }
  }, [shipmentAssistants])

  const getRecalculateItems = () => {
    itemsActions.recalculateItemsFromStore()
  }

  useEvents(
    [
      Events.Proposal.SET_ITEM,
      Events.Proposal.CLEAN_ITEM,
      Events.Proposal.REMOVE_ITEM,
      Events.Proposal.REMOVE_ALL_ITEMS,
      Events.Proposal.MODIFY_ITEM_QUANTITY,
      Events.EditDepositView.GET_TRANSACTION_BY_ID_SUCCESS
    ],
    getRecalculateItems
  )

  useEvents(Events.EditDepositView.EDIT_DEPOSIT_SUCCESS, () => history.push('/deposits'))

  const saveEdit = (totalPrices: TotalPrices, transportDetail?: TransportDetail) => {
    editDeposit({
      destinationDeposit: transportState.destinationDeposit,
      addressId: addressState.addressID,
      date: calendarState.unavailableDates.selected,
      modifyReasonId: transaction.modifyReason.reasonId,
      operationId: depositId,
      selectedItems: itemsState.selectedItems,
      shipmentAssistantCount: shipmentAssistants.checkedAssistants ? shipmentAssistants.shipmentAssistantCount : 0,
      shipmentAssistantPrice: shipmentAssistants.shipmentAssistantPrice,
      timeInterval: getFormatFirstTimeInterval(calendarState.timeIntervals.selected, calendarState.timeIntervals.list)
        .label,
      timeslot: calendarState.timeslots.selected,
      timeslots: calendarState.timeslots.list,
      totalStorageCost: Math.round(totalPrices.pricesWithDiscount.spaceCostDiscounted),
      transportCost: Math.round(totalPrices.pricesWithDiscount.transportCostDiscounted),
      tollCostInCents: transportState.tollCostInCents,
      transportMode: transportState.transportMode,
      transportModes: transportState.transportModeList,
      transportType: transportState.transportTypePricing,
      validDiscount: costState.fetchDiscount.valid,
      discountCode: costState.discountCode,
      transportDetail
    })
  }

  const disabledEdit =
    (transportState.requireTransport && !addressState.addressID) ||
    !transaction.modifyReason.reasonId ||
    serviceType.value === ''

  const customDateComponentProps: CalendarTextFieldProps = {
    currentMonth,
    localDate,
    anticipationsDate: DEFAULT_TODAY,
    grid: monthGrid,
    loading: loadingCalendar,
    handleChangeMonth,
    handleDayPress
  }

  return (
    <Component
      calendar={calendarState}
      calendarActions={calendarActions}
      itemsState={itemsState}
      setPriceBy={setPriceBy}
      transportState={transportState}
      loadingServiceTypes={loadingTypes}
      serviceType={serviceType}
      serviceTypeList={serviceTypeList}
      setServiceType={setServiceType}
      setUpdatedExtraValue={setUpdatedExtraValue}
      setDisabledShipmentAssistants={setDisabledShipmentAssistants}
      setModifyReason={setModifyReason}
      loadingDataView={loading}
      transaction={transaction}
      aditionals={shipmentAssistants}
      costState={costState}
      costActions={costActions}
      addressActions={addressActions}
      saveEdit={saveEdit}
      disabledEdit={disabledEdit}
      customDateComponentProps={customDateComponentProps}
    />
  )
}

export default EditDepositContainer
