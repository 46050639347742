import { API } from '../../../projectApi'
import { UpdateRemovalBody } from '../../../projectApi/TransactionHandler/Operation/Removals/update'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { EditRemovalViewEventsEnum } from '../../../utils/eventEmitter/events/editRemovalEvents'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  ActiveEditMode,
  EditDataTypes,
  EditRemovalFailure,
  EditRemovalRequest,
  EditRemovalSuccess,
  EditRemovalTypes,
  GetRemovalData,
  GetRemovalDataFailure,
  GetRemovalDataRequest,
  GetRemovalDataSuccess
} from '../types/editMode'

const getItemsBody = (selectedItems: any[]): any[] => {
  return selectedItems.reduce((accum: any[], item): any[] => {
    if (!item.selectedItem) return accum

    const itemBody: any = {
      objectId: 'itemID' in item.selectedItem ? item.selectedItem.itemID : 0,
      floorByStairsCost: item.selectedItem.id,
      packagingCost: item.quantity,
      assemblyCost: item.selectedItem.description,
      deleted: item.deleted
    }

    return [...accum, itemBody]
  }, [])
}

const EditModeActionCreators = {
  openEditMode: ({
    active,
    userId,
    isDraft
  }: {
    active: boolean
    userId: number
    isDraft?: boolean
  }): ActiveEditMode => {
    return {
      type: EditDataTypes.ACTIVE_EDIT_MODE,
      payload: {
        activeMode: active,
        userId,
        isDraft
      }
    }
  },

  editRemoval:
    ({ operationId, body }: { operationId: number; body: UpdateRemovalBody }): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const request: EditRemovalRequest = {
        type: EditRemovalTypes.EDIT_REMOVAL_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Editando la devolución, por favor espere...')

      try {
        await API.TransactionHandler.Operation.Removals.update({ operationId, body })

        const success: EditRemovalSuccess = {
          type: EditRemovalTypes.EDIT_REMOVAL_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'La devolución se editó exitosamente' })
        Emitter.emit(EditRemovalViewEventsEnum.EDIT_REMOVAL_SUCCESS)
        return true
      } catch (error) {
        const failure: EditRemovalFailure = {
          type: EditRemovalTypes.EDIT_REMOVAL_FAILURE
        }

        dispatch(failure)
        setToastErrorUpdate(toastId, { render: 'Error al editar la devolución' })
        return false
      }
    },

  getRemovalData:
    ({ operationId }: { operationId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalDataRequest = {
        type: GetRemovalData.GET_REMOVAL_DATA_REQUEST
      }

      dispatch(request)

      try {
        const { operation } = await API.TransactionHandler.Operation.Removals.getById({ operationId })

        const success: GetRemovalDataSuccess = {
          type: GetRemovalData.GET_REMOVAL_DATA_SUCCESS,
          payload: { operation }
        }

        dispatch(success)
      } catch (error) {
        const failure: GetRemovalDataFailure = {
          type: GetRemovalData.GET_REMOVAL_DATA_FAILURE
        }

        dispatch(failure)
      }
    }
}

export default EditModeActionCreators
