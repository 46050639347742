import React from 'react'

const DiscoversIcon = () => {
  return (
    <svg width="512" height="86" viewBox="0 0 512 86" preserveAspectRatio="xMidYMid">
      <defs>
        <linearGradient x1="20.442%" y1="10.599%" x2="89.245%" y2="83.53%" id="a">
          <stop stopColor="#E25429" offset="0%" />
          <stop stopColor="#F99D3E" offset="100%" />
        </linearGradient>
        <path
          d="M270.356.365c-23.982 0-43.44 18.735-43.44 41.858 0 24.583 18.612 42.96 43.44 42.96 24.208 0 43.321-18.62 43.321-42.478 0-23.716-18.986-42.34-43.321-42.34z"
          id="b"
        />
        <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="c">
          <feMorphology radius="2" in="SourceAlpha" result="shadowSpreadInner1" />
          <feGaussianBlur stdDeviation="2.5" in="shadowSpreadInner1" result="shadowBlurInner1" />
          <feOffset dx="2" dy="2" in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.301658741 0" in="shadowInnerInner1" />
        </filter>
      </defs>
      <use fill="url(#a)" fillRule="evenodd" xlinkHref="#b" />
      <use filter="url(#c)" xlinkHref="#b" />
      <path
        d="M23.746 1.891h-23.353v81.454h23.232c12.325 0 21.24-2.921 29.059-9.398 9.278-7.695 14.781-19.298 14.781-31.289 0-24.048-17.965-40.766-43.719-40.766zm18.573 61.176c-5.022 4.531-11.486 6.488-21.76 6.488h-4.268v-53.873h4.268c10.274 0 16.491 1.834 21.76 6.593 5.495 4.886 8.772 12.452 8.772 20.265 0 7.829-3.277 15.66-8.772 20.527zm32.48 20.278h15.871v-81.454h-15.871v81.454zm54.727-50.209c-9.539-3.534-12.346-5.865-12.346-10.247 0-5.134 4.998-9.039 11.849-9.039 4.763 0 8.671 1.953 12.836 6.58l8.294-10.852c-6.839-5.998-15.021-9.047-23.946-9.047-14.398 0-25.399 10.02-25.399 23.32 0 11.247 5.126 16.981 20.031 22.369 6.233 2.188 9.401 3.646 10.993 4.643 3.175 2.077 4.769 4.998 4.769 8.416 0 6.605-5.257 11.483-12.351 11.483-7.574 0-13.674-3.782-17.341-10.865l-10.247 9.905c7.313 10.733 16.109 15.511 28.214 15.511 16.488 0 28.084-11.007 28.084-26.758 0-12.948-5.361-18.815-23.443-25.419zm28.449 9.521c0 23.965 18.815 42.525 43.006 42.525 6.839 0 12.701-1.352 19.915-4.758v-18.697c-6.361 6.358-11.98 8.916-19.189 8.916-15.997 0-27.364-11.606-27.364-28.102 0-15.626 11.721-27.965 26.638-27.965 7.561 0 13.311 2.686 19.915 9.159v-18.696c-6.962-3.531-12.712-4.985-19.552-4.985-24.067 0-43.369 18.935-43.369 42.604zm191.651 13.948l-21.743-54.715h-17.346l34.579 83.534h8.543l35.183-83.534h-17.218l-22 54.715zm46.439 26.739h45.066v-13.789h-29.188v-22.001h28.072v-13.792h-28.072v-18.079h29.188v-13.792h-45.066v81.454zm107.955-57.415c0-15.259-10.49-24.039-28.823-24.039h-23.577v81.454h15.895v-32.737h2.08l21.975 32.737h19.544l-25.667-34.311c11.988-2.451 18.573-10.638 18.573-23.104zm-31.882 13.452h-4.623v-24.683h4.877c9.916 0 15.287 4.165 15.287 12.092 0 8.178-5.372 12.59-15.541 12.59z"
        fill="#0B1015"
      />
    </svg>
  )
}

export default DiscoversIcon
