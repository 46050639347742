import { Button, Checkbox, Modal, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import InputGroup from '../InputGroup'
import { ItemGroup, ModifiableItem, ModifiableItemType } from './types'
import Select from '../../components/Select/Select'
import InputFieldWithError from '../InputFieldWithError/inputFieldWithError'
import NumberInput from '../numberInput'
import useDebounce from '../../utils/hooks/useDebounce'
import styles from './MassiveModificationModal.module.scss'
import Search from 'antd/lib/input/Search'

type MassiveModificationModalProps = {
  itemGroups: [ItemGroup, ...ItemGroup[]]
  title: string
  description?: React.ReactNode
  open: boolean
  loading: boolean
  disableConfirm: boolean
  error?: string
  onClose: () => void
  onConfirm: () => void
}

const MassiveModificationModal: React.FC<MassiveModificationModalProps> = ({
  itemGroups,
  title,
  description,
  open,
  loading,
  disableConfirm,
  error,
  onClose,
  onConfirm
}) => {
  return (
    <Modal
      width={660}
      title={title}
      visible={open}
      closable={false}
      maskClosable={!loading}
      destroyOnClose
      className={styles.modal}
      style={{
        maxWidth: 660
      }}
      onCancel={onClose}
      confirmLoading={loading}
      footer={[
        <>
          <Button key="back" onClick={onClose} disabled={loading}>
            Cancelar
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={onConfirm}
            loading={loading}
            disabled={loading || disableConfirm}>
            Confirmar
          </Button>
        </>
      ]}>
      {description}
      {itemGroups.map((itemGroup) => (
        <ItemGroupComponent {...itemGroup} key={itemGroup.title} />
      ))}
      {error && <div className={styles.error}>{error}</div>}
    </Modal>
  )
}

const DebouncedInputFieldWithError: React.FC<{
  value: string
  setValue: (newValue: string) => void
  disabled?: boolean
}> = ({ value, setValue, disabled }) => {
  const [localValue, setLocalValue] = useState(value)
  const debouncedValue = useDebounce(localValue, 50)

  useEffect(() => {
    setValue(debouncedValue)
  }, [debouncedValue])

  return <InputFieldWithError value={localValue} onChange={(e) => setLocalValue(e.target.value)} disabled={disabled} />
}

const SearchInput: React.FC<{
  onSearch: (query: string) => void
  value: string
  setValue: (newValue: string) => void
  disableSearch?: boolean
  loading?: boolean
  onlyNumbers?: boolean
}> = ({ onSearch, value, setValue, loading, onlyNumbers, disableSearch }) => {
  const [localValue, setLocalValue] = useState(value)
  const debouncedValue = useDebounce(localValue, 50)

  useEffect(() => {
    setValue(debouncedValue)
  }, [debouncedValue])

  return (
    <Search
      placeholder="ID de usuario"
      onSearch={disableSearch ? () => {} : onSearch}
      loading={loading}
      value={localValue}
      type={onlyNumbers ? 'number' : 'text'}
      onChange={(e) => setLocalValue(e.target.value)}
    />
  )
}

const renderItem = (item: ModifiableItem) => {
  switch (item.type) {
    case ModifiableItemType.AUTOCOMPLETE:
      return (
        <Select
          disabled={!item.enabled}
          loading={item.loading}
          // @ts-ignore
          selected={item.options.find((x) => x.value === item.currentValue)}
          onSelect={(selected) => {
            // @ts-ignore
            const selectedValue = selected?.value as string | number
            item.onChange(selectedValue)
          }}
          // @ts-ignore
          onInputChange={(newValue) => item.onInputChange?.(newValue)}
          // @ts-ignore
          options={item.options}
        />
      )
    case ModifiableItemType.AUTOCOMPLETE_SEARCH:
      return (
        <Select
          disabled={!item.enabled}
          loading={item.loading}
          // @ts-ignore
          selected={item.options.find((x) => x.value === item.currentValue)}
          onSelect={(selected) => {
            // @ts-ignore
            const selectedValue = selected?.value as string | number
            item.onChange(selectedValue)
          }}
          // @ts-ignore
          onInputChange={(newValue) => item.onInputChange?.(newValue)}
          // @ts-ignore
          options={item.options}
          autocomplete={{
            onInputChange: item.onInputChange,
            input: item.autocomplete,
            filterByInput: false
          }}
          onDelete={() => {
            item.setAutocomplete('')
            item.clearSelectedProducts()
          }}
        />
      )
    case ModifiableItemType.SWITCH:
      return (
        <div>
          <Switch checked={item.currentValue} disabled={!item.enabled} onChange={(v) => item.onChange(v)} />
        </div>
      )
    case ModifiableItemType.TEXT_FIELD:
      return (
        <DebouncedInputFieldWithError
          value={item.currentValue}
          setValue={(newValue) => item.onChange(newValue)}
          disabled={!item.enabled}
        />
      )
    case ModifiableItemType.NUMBER_INPUT:
      return (
        <NumberInput
          value={item.currentValue}
          disabled={!item.enabled}
          onAnyChange={(newValue) => item.onChange(newValue)}
        />
      )
    case ModifiableItemType.SEARCH_INPUT:
      return (
        <SearchInput
          onSearch={item.onSearch}
          setValue={item.onChange}
          value={item.currentValue}
          loading={item.loading}
          onlyNumbers={item.onlyNumbers}
          disableSearch={item.disableSearch}
        />
      )
    default:
      return <div />
  }
}
const ItemGroupComponent: React.FC<ItemGroup> = ({ items, title }) => {
  return (
    <InputGroup title={title}>
      <div className={styles.items}>
        {items.map((item, index) =>
          item.type === 'PRESENTATIONAL' ? (
            <div className={styles.presentationalWrapper} key={item.node?.toString()}>
              {item.node}
            </div>
          ) : (
            <span style={{ display: 'contents' }} key={item.label}>
              <Checkbox
                onChange={(e) => item.onEnable(e.target.value)}
                checked={item.enabled}
                disabled={item.disabledCheckbox}
              />
              <span style={{ opacity: item.enabled ? 1 : 0.4, transition: '.2s all ease' }}>{item.label}</span>
              {renderItem(item)}
            </span>
          )
        )}
      </div>
    </InputGroup>
  )
}

export default MassiveModificationModal
