import { useEffect } from 'react'
type Params = {
  handleScroll: () => void
}
export const useScroll = ({ handleScroll }: Params) => {
  useEffect(() => {
    const targetNode = document
    targetNode && targetNode.addEventListener('scroll', handleScroll)

    return () => targetNode && targetNode.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
}
