import snakecaseKeys from 'snakecase-keys'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { URL_BASE_COMPLAINTS } from '@/endpoints'
import { ComplaintPhoto, ComplaintStatus, Priority } from '@/sections/Complaints/types/updateView'
import { apiPrivate } from '@/api'
import { APIComplaint, Complaint } from './list'
import { convertToMoment } from '../utils'

type ComplaintUpdateParams = {
  userid: number
  priority: keyof typeof Priority
  status: keyof typeof ComplaintStatus
  assignedUserId: number | null
  description: string
  photos: ComplaintPhoto[]
  complaintId: number
}

type APIResponse = {
  content: APIComplaint
}

type Response = {
  complaint: Complaint
}

export function update(updatedComplaint: ComplaintUpdateParams): Promise<Response> {
  const formattedComplaint = snakecaseKeys({ ...updatedComplaint, complaintId: undefined }, { deep: true })
  return apiPrivate
    .put(`${URL_BASE_COMPLAINTS}/complaints/${updatedComplaint.complaintId}`, formattedComplaint)
    .then((response: AxiosResponse<APIResponse>) => {
      const complaint = camelcaseKeys(response.data.content, { deep: true })
      return {
        complaint: {
          ...complaint,
          createdAt: convertToMoment(complaint.createdAt),
          updatedAt: convertToMoment(complaint.updatedAt),
          deletedAt: convertToMoment(complaint.deletedAt),
          closeDate: convertToMoment(complaint.closeDate),
          complaintObjects: Array.isArray(complaint.complaintObjects)
            ? complaint.complaintObjects.map((complaintObject) => ({
                ...complaintObject,
                createdAt: convertToMoment(complaintObject.createdAt),
                updatedAt: convertToMoment(complaintObject.updatedAt),
                deletedAt: convertToMoment(complaintObject.deletedAt)
              }))
            : [],
          complaintHistories: Array.isArray(complaint.complaintHistories)
            ? complaint.complaintHistories.map((complaintHistory) => ({
                ...complaintHistory,
                createdAt: convertToMoment(complaintHistory.createdAt),
                updatedAt: convertToMoment(complaintHistory.updatedAt),
                deletedAt: convertToMoment(complaintHistory.deletedAt),
                photos: Array.isArray(complaintHistory.photos)
                  ? complaintHistory.photos.map((photo) => ({
                      ...photo,
                      createdAt: convertToMoment(photo.createdAt),
                      updatedAt: convertToMoment(photo.updatedAt),
                      deletedAt: convertToMoment(photo.deletedAt)
                    }))
                  : []
              }))
            : []
        }
      }
    })
    .catch((error) => {
      throw error
    })
}
