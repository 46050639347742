import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'
import { CategoryFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'
import { BillingPaymentStatusId } from '../constants'

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export enum BillingPaymentTableSearchKey {
  BILLING_ID = 'billingId'
}

export enum BillingPaymentCategoryFilterKey {
  STATUS = 'status'
}

export type BillingPaymentTableCategoryFilter = CategoryFilter<BillingPaymentCategoryFilterKey, BillingPaymentStatusId>

export type BillingPaymentTableSearchFilter = SearchFilter<BillingPaymentTableSearchKey>

export type BillingPaymentListSortKey = 'ID' | 'total' | 'nroFactura'

export type BillingPaymentTableSortKey = BillingPaymentListSortKey
export type BillingPaymentTableSort = Sort<BillingPaymentTableSortKey>

export const GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST = 'BILLING_PAYMENT/GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST'
export const GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS = 'BILLING_PAYMENT/GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS'
export const GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE = 'BILLING_PAYMENT/GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE'

export const GET_BILLINGS_BY_USER_REQUEST = 'BILLING_PAYMENT/GET_BILLINGS_BY_USER_REQUEST'
export const GET_BILLINGS_BY_USER_SUCCESS = 'BILLING_PAYMENT/GET_BILLINGS_BY_USER_SUCCESS'
export const GET_BILLINGS_BY_USER_FAILURE = 'BILLING_PAYMENT/GET_BILLINGS_BY_USER_FAILURE'

export const CLEAR_STATE = 'BILLING_PAYMENT/CLEAR_STATE'
export const CLEAR_SELECTED_BILLINGS = 'BILLING_PAYMENT/CLEAR_SELECTED_BILLINGS'
export const TOGGLE_ALL_BILLINGS = 'BILLING_PAYMENT/TOGGLE_ALL_BILLINGS'
export const TOGGLE_SELECTED_BILLING = 'BILLING_PAYMENT/TOGGLE_SELECTED_BILLING'
export const SELECT_BILLING = 'BILLING_PAYMENT/SELECT_BILLING'

export const GET_PDF_BY_CLIENT_REQUEST = 'BILLING_PAYMENT/GET_PDF_BY_CLIENT_REQUEST'
export const GET_PDF_BY_CLIENT_SUCCESS = 'BILLING_PAYMENT/GET_PDF_BY_CLIENT_SUCCESS'
export const GET_PDF_BY_CLIENT_FAILURE = 'BILLING_PAYMENT/GET_PDF_BY_CLIENT_FAILURE'

export const GET_CLIENT_PAYMENT_METHODS_REQUEST = 'BILLING_PAYMENT/GET_CLIENT_PAYMENT_METHODS_REQUEST'
export const GET_CLIENT_PAYMENT_METHODS_SUCCESS = 'BILLING_PAYMENT/GET_CLIENT_PAYMENT_METHODS_SUCCESS'
export const GET_CLIENT_PAYMENT_METHODS_FAILURE = 'BILLING_PAYMENT/GET_CLIENT_PAYMENT_METHODS_FAILURE'

export const SELECT_PAYMENT_METHOD = 'BILLING_PAYMENT/SELECT_PAYMENT_METHOD'

export const ADD_CLIENT_PAYMENT_METHOD_REQUEST = 'BILLING_PAYMENT/ADD_CLIENT_PAYMENT_METHOD_REQUEST'
export const ADD_CLIENT_PAYMENT_METHOD_SUCCESS = 'BILLING_PAYMENT/ADD_CLIENT_PAYMENT_METHOD_SUCCESS'
export const ADD_CLIENT_PAYMENT_METHOD_FAILURE = 'BILLING_PAYMENT/ADD_CLIENT_PAYMENT_METHOD_FAILURE'

export const PAY_CLIENT_BILLING_REQUEST = 'BILLING_PAYMENT/PAY_CLIENT_BILLING_REQUEST'
export const PAY_CLIENT_BILLING_SUCCESS = 'BILLING_PAYMENT/PAY_CLIENT_BILLING_SUCCESS'
export const PAY_CLIENT_BILLING_FAILURE = 'BILLING_PAYMENT/PAY_CLIENT_BILLING_FAILURE'

export const ADD_CREDIT_CARD_SPREEDLY_REQUEST = 'BILLING_PAYMENT/PAY_CLIENT_BILLING_REQUEST'
export const ADD_CREDIT_CARD_SPREEDLY_SUCCESS = 'BILLING_PAYMENT/PAY_CLIENT_BILLING_SUCCESS'
export const ADD_CREDIT_CARD_SPREEDLY_FAILURE = 'BILLING_PAYMENT/PAY_CLIENT_BILLING_FAILURE'

export const GET_USER_BY_ID_REQUEST = 'BILLING_PAYMENT/GET_USER_BY_ID_REQUEST'
export const GET_USER_BY_ID_SUCCESS = 'BILLING_PAYMENT/GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAILURE = 'BILLING_PAYMENT/GET_USER_BY_ID_FAILURE'

export interface UserCollpyWithCountry extends UserColppy {
  country: string
}

export interface GetUserByIdRequest {
  type: typeof GET_USER_BY_ID_REQUEST
}
export interface GetUserByIdSuccess {
  type: typeof GET_USER_BY_ID_SUCCESS
}
export interface GetUserByIdFailure {
  type: typeof GET_USER_BY_ID_FAILURE
  payload: { error: string }
}
export interface SelectBilling {
  type: typeof SELECT_BILLING
  payload: {
    billing: Billing
  }
}

export interface AddCreditCardSpreedlyRequest {
  type: typeof ADD_CREDIT_CARD_SPREEDLY_REQUEST
}

export interface AddCreditCardSpreedlySuccess {
  type: typeof ADD_CREDIT_CARD_SPREEDLY_SUCCESS
}

export interface AddCreditCardSpreedlyFailure {
  type: typeof ADD_CREDIT_CARD_SPREEDLY_FAILURE
  payload: {
    error: string
  }
}

export interface PayClientBillingRequest {
  type: typeof PAY_CLIENT_BILLING_REQUEST
}

export interface PayClientBillingSuccess {
  type: typeof PAY_CLIENT_BILLING_SUCCESS
}

export interface PayClientBillingFailure {
  type: typeof PAY_CLIENT_BILLING_FAILURE
  payload: {
    error: string
  }
}

export interface SelectPaymentMethod {
  type: typeof SELECT_PAYMENT_METHOD
  payload: {
    paymentMethod: PaymentMethod
  }
}
export interface AddClientPaymentMethodRequest {
  type: typeof ADD_CLIENT_PAYMENT_METHOD_REQUEST
}

export interface AddClientPaymentMethodSuccess {
  type: typeof ADD_CLIENT_PAYMENT_METHOD_SUCCESS
  payload: {
    paymentMethods: PaymentMethod[]
  }
}

export interface AddClientPaymentMethodFailure {
  type: typeof ADD_CLIENT_PAYMENT_METHOD_FAILURE
  payload: {
    error: string
  }
}

export interface GetClientPaymentMethodsRequest {
  type: typeof GET_CLIENT_PAYMENT_METHODS_REQUEST
}

export interface GetClientPaymentMethodsSuccess {
  type: typeof GET_CLIENT_PAYMENT_METHODS_SUCCESS
  payload: {
    paymentMethods: PaymentMethod[]
  }
}

export interface GetClientPaymentMethodsFailure {
  type: typeof GET_CLIENT_PAYMENT_METHODS_FAILURE
  payload: {
    error: string
  }
}

export interface GetPdfByClientRequest {
  type: typeof GET_PDF_BY_CLIENT_REQUEST
}

export interface GetPdfByClientSuccess {
  type: typeof GET_PDF_BY_CLIENT_SUCCESS
}

export interface GetPdfByClientFailure {
  type: typeof GET_PDF_BY_CLIENT_FAILURE
}

export interface ToggleSelectedBilling {
  type: typeof TOGGLE_SELECTED_BILLING
  payload: {
    billing: Billing
  }
}
export interface ClearState {
  type: typeof CLEAR_STATE
}

export interface ClearSelectedBillings {
  type: typeof CLEAR_SELECTED_BILLINGS
}
export interface ToggleAllBillings {
  type: typeof TOGGLE_ALL_BILLINGS
}

export type GetBillingsByUserRequest = {
  type: typeof GET_BILLINGS_BY_USER_REQUEST
  payload: {
    pageSize: number
    newPage: number
    sort?: BillingPaymentTableSort
    categoryFilter?: BillingPaymentTableCategoryFilter
  }
}

export type GetBillingsByUserSuccess = {
  type: typeof GET_BILLINGS_BY_USER_SUCCESS
  payload: {
    billings: Billing[]
    total: number
  }
}

export type GetBillingsByUserFailure = {
  type: typeof GET_BILLINGS_BY_USER_FAILURE
  payload: {
    error: string
  }
}

export type GetInfoAboutUserHasDebtsRequest = {
  type: typeof GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST
}

export type GetInfoAboutUserHasDebtsSuccess = {
  type: typeof GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS
  payload: {
    user: UserCollpyWithCountry
  }
}

export type GetInfoAboutUserHasDebtsFailure = {
  type: typeof GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE
  payload: {
    error: string
  }
}

export interface CreateCardSpreedly {
  userId: string
  cardNumber: string
  fullname: string
  cvc: string
  expireDate: string
  documentType: string
  document: string
}

export type BillingPaymentActions =
  | GetInfoAboutUserHasDebtsRequest
  | GetInfoAboutUserHasDebtsSuccess
  | GetInfoAboutUserHasDebtsFailure
  | GetBillingsByUserRequest
  | GetBillingsByUserSuccess
  | GetBillingsByUserFailure
  | ToggleSelectedBilling
  | ClearState
  | ClearSelectedBillings
  | ToggleAllBillings
  | GetPdfByClientRequest
  | GetPdfByClientSuccess
  | GetPdfByClientFailure
  | GetClientPaymentMethodsRequest
  | GetClientPaymentMethodsSuccess
  | GetClientPaymentMethodsFailure
  | SelectPaymentMethod
  | PayClientBillingRequest
  | PayClientBillingSuccess
  | PayClientBillingFailure
  | AddCreditCardSpreedlyRequest
  | AddCreditCardSpreedlySuccess
  | AddCreditCardSpreedlyFailure
  | SelectBilling
  | GetUserByIdRequest
  | GetUserByIdSuccess
  | GetUserByIdFailure
