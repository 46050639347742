import { Reducer } from 'react'
import {
  AddressModalActions,
  AddressModalState,
  CLOSE_MODAL,
  CREATE_ADDRESS_DATA_FAILURE,
  CREATE_ADDRESS_DATA_REQUEST,
  CREATE_ADDRESS_DATA_SUCCESS,
  GET_ADDRESS_DATA_FAILURE,
  GET_ADDRESS_DATA_REQUEST,
  GET_ADDRESS_DATA_SUCCESS,
  OPEN_MODAL,
  UPDATE_ADDRESS_DATA_FAILURE,
  UPDATE_ADDRESS_DATA_REQUEST,
  UPDATE_ADDRESS_DATA_SUCCESS
} from '../types/addressModal'

const initialState: AddressModalState = {
  openModal: false,
  loading: false,
  isEdit: false,
  userId: 0,
  addressId: 0,
  error: ''
}
const AddressModalReducer: Reducer<AddressModalState, AddressModalActions> = (
  state = initialState,
  action
): AddressModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        userId: action.payload?.userId,
        isEdit: action.payload?.isEdit,
        addressId: action.payload?.addressId
      }
    case CLOSE_MODAL:
      return initialState
    case CREATE_ADDRESS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CREATE_ADDRESS_DATA_SUCCESS:
      return initialState
    case CREATE_ADDRESS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case UPDATE_ADDRESS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_ADDRESS_DATA_SUCCESS:
      return initialState
    case UPDATE_ADDRESS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case GET_ADDRESS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case GET_ADDRESS_DATA_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case GET_ADDRESS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default AddressModalReducer
