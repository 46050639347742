import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

type AssignedAgent = {
  id: number
  created_at: string
  updated_at: string
  deleted_at: null | string
  first_name: string
  last_name: string
  email: string
  user_guid: string
  user_id: number
  role_id: number
}

type State = {
  state_id: number
  qualified: string
  descriptions: string
  available: boolean
  create_at: string
  update_at: string
  deleted_at: null | string
}

type APIResponse = {
  id: number
  created_at: string
  deleted_at: null | string
  prospect_id: number
  first_name: string
  last_name: string
  phone: string
  email: string
  country: string
  user_id: number
  gender: 'F' | 'M' | string
  provider: string
  provider_id: string
  source_id: number
  assigned_agent_id: number
  prospect_type_id: number
  qualified: number
  duplication_code: number
  rp_validate: {
    created_at: string
  }
  people_id: number
  contact_mediums_id: number
  win_date?: string
  status_id: number
  status_id_new: number
  status_updated_at: string
  assigned_agent: AssignedAgent
  state: State
}

export type ResponseLeadHistory = CamelCasedPropertiesDeep<APIResponse>

type Params = {
  leadId: number
  country: string
  limit?: number
  offset?: number
}

export function getHistory({ leadId, country }: Params): Promise<ResponseLeadHistory[]> {
  const params = { id: leadId, country }
  return apiPrivate
    .get(`${URL_BASE_MERCURIO}/bo/prospects/v1/prospectHistory`, { params })
    .then((response: AxiosResponse<ResponseLeadHistory[]>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
