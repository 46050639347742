import { combineReducers } from 'redux'
import objects from './objects'
import transport from './transport'
import calendar from './calendar'
import cost from './cost'
import paymentMp from './payment-mp'
import paymentCBU from './payment-cbu'
import common from './common'
import userSearch from './users'
import modalConfirmDelete from './confirmModal'
import editModeReducer from './editMode'
import modalReasonsReducer from './modalReasons'
import CreditNoteWarningReducer from './creditNoteWarning'

const rootReducer = combineReducers({
  common,
  objects,
  transport,
  calendar,
  cost,
  paymentMp,
  paymentCBU,
  userSearch,
  modalConfirmDelete,
  editMode: editModeReducer,
  modalReasons: modalReasonsReducer,
  creditNoteWarning: CreditNoteWarningReducer
})

export default rootReducer
