import { OperationTypeValues } from './common'

export type OperationDay = {
  day: string
  operationType: OperationTypeValues
  available: boolean
}

export type ProcessedOperationDay = {
  day: string
  availableOperations: OperationTypeValues[]
}

export interface OperationDaysState {
  daysData: ProcessedOperationDay[]
  loadingDays: boolean
  error: string
  selectedDay: string
  isEditModalOpen: boolean
}

export enum GetOperationDaysTypes {
  GET_DAYS_REQUEST = 'OPERATION_CALENDAR/DAYS/GET_DAYS_REQUEST',
  GET_DAYS_SUCCESS = 'OPERATION_CALENDAR/DAYS/GET_DAYS_SUCCESS',
  GET_DAYS_FAILURE = 'OPERATION_CALENDAR/DAYS/GET_DAYS_FAILURE'
}

export interface GetDaysRequest {
  type: typeof GetOperationDaysTypes.GET_DAYS_REQUEST
}

export interface GetDaysSuccess {
  type: typeof GetOperationDaysTypes.GET_DAYS_SUCCESS
  payload: {
    daysData: ProcessedOperationDay[]
  }
}

export interface GetDaysFailure {
  type: typeof GetOperationDaysTypes.GET_DAYS_FAILURE
  payload: {
    error: string
  }
}

type GetOperationDaysActions = GetDaysRequest | GetDaysSuccess | GetDaysFailure

export enum UpdateOperationDaysTypes {
  UPDATE_DAYS_REQUEST = 'OPERATION_CALENDAR/DAYS/UPDATE_DAYS_REQUEST',
  UPDATE_DAYS_SUCCESS = 'OPERATION_CALENDAR/DAYS/UPDATE_DAYS_SUCCESS',
  UPDATE_DAYS_FAILURE = 'OPERATION_CALENDAR/DAYS/UPDATE_DAYS_FAILURE'
}

export interface UpdateDayRequest {
  type: typeof UpdateOperationDaysTypes.UPDATE_DAYS_REQUEST
}

export interface UpdateDaySuccess {
  type: typeof UpdateOperationDaysTypes.UPDATE_DAYS_SUCCESS
}

export interface UpdateDayFailure {
  type: typeof UpdateOperationDaysTypes.UPDATE_DAYS_FAILURE
  payload: {
    error: string
  }
}

type UpdateOperationDaysActions = UpdateDayRequest | UpdateDaySuccess | UpdateDayFailure

export enum ModalEditDayOperationTypes {
  SET_EDIT_MODAL_OPEN = 'OPERATION_CALENDAR/DAYS/SET_EDIT_MODAL_OPEN',
  SET_SELECTED_DAYS_OPERATIONS = 'OPERATION_CALENDAR/DAYS/SET_SELECTED_DAYS_OPERATIONS'
}
export interface SetEditModalOpen {
  type: typeof ModalEditDayOperationTypes.SET_EDIT_MODAL_OPEN
  payload: {
    isEditModalOpen: boolean
    selectedDay: string
  }
}

export interface SetSelectedDayOperations {
  type: typeof ModalEditDayOperationTypes.SET_SELECTED_DAYS_OPERATIONS
  payload: {
    selectedOperations: OperationTypeValues[]
  }
}

type ModalEditDayOperationActions = SetEditModalOpen | SetSelectedDayOperations

export type OperationDaysAction = GetOperationDaysActions | UpdateOperationDaysActions | ModalEditDayOperationActions
