import {
  Cancel,
  SelectObject,
  SetLocation,
  SetHeight,
  SetWidth,
  SetLength,
  SetProduct,
  ModifyObjectSuccess,
  GetProductsSuccess,
  SetAttributeValue,
  DeleteAttribute,
  SetAttributeType
} from '../types/edit'

import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { API } from '../../../../projectApi'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { FormattedObject } from '../../../../projectApi/ObjectAdministration/common'
import { AppThunk } from '../../../../store'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { StringKeyOf } from 'type-fest'
import { ObjectTypes } from '../types/edit.enum'
import { EditCorporateObjectBody } from '../../../../projectApi/ObjectAdministration/Corporate/editObject'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'

let searchTimer: NodeJS.Timeout

const formatObjectResponseToUpdateRequest = (object: FormattedObject): EditCorporateObjectBody => ({
  // @ts-ignore
  productId: object.product?.realId,
  weightInGr: object.product?.weightInGr,
  lengthInCm: object.lengthInCm,
  widthInCm: object.widthInCm,
  heightInCm: object.heightInCm,
  locationId: object.location?.id,
  countryId: object.owner?.countryId,
  attributes: object.attributes?.map((attribute) => ({
    id: attribute.id,
    value: attribute.value,
    attributeId: attribute.attributeId,
    deleted: false
  }))
})

const makeObjectDifference = (
  oldObject: EditCorporateObjectBody,
  newObject: EditCorporateObjectBody
): EditCorporateObjectBody => {
  const objectKeys = Object.keys(oldObject) as StringKeyOf<EditCorporateObjectBody>[]
  return objectKeys.reduce<EditCorporateObjectBody>(
    (acc, key) => {
      if (JSON.stringify(newObject[key]) !== JSON.stringify(oldObject[key])) {
        // @ts-ignore
        acc[key] = newObject[key]
      }
      return acc
    },
    {
      countryId: newObject.countryId
    }
  )
}

const actions = {
  selectObject: (object: FormattedObject): SelectObject => ({
    type: ObjectTypes.SELECT_OBJECT,
    payload: { object: formatObjectResponseToUpdateRequest(object), rawObject: object }
  }),
  cancel: (): Cancel => ({ type: ObjectTypes.CANCEL }),
  setLocation: (location: number): SetLocation => ({
    type: ObjectTypes.SET_LOCATION,
    payload: { location }
  }),
  setProduct: (product: FormattedProduct | null): SetProduct => ({
    type: ObjectTypes.SET_PRODUCT,
    payload: { product }
  }),
  setHeight: (height: number): SetHeight => ({
    type: ObjectTypes.SET_HEIGHT,
    payload: { height }
  }),
  setWidth: (width: number): SetWidth => ({
    type: ObjectTypes.SET_WIDTH,
    payload: { width }
  }),
  setLength: (length: number): SetLength => ({
    type: ObjectTypes.SET_LENGTH,
    payload: { length }
  }),
  modifyObject: (id: number, oldObject: EditCorporateObjectBody, newObject: EditCorporateObjectBody): AppThunk => {
    return function (dispatch) {
      dispatch({ type: ObjectTypes.MODIFY_OBJECT_REQUEST })

      const toastId = setToastLoading('Modificando objeto, por favor espere...')

      const body = makeObjectDifference(oldObject, newObject)

      return API.ObjectAdministration.Object.update(id, body).then(
        (response) => {
          dispatch(actions.modifyObjectSuccess())
          setToastSuccessUpdate(toastId, { render: 'Objeto modificado con éxito' })
          Emitter.emit(Events.Objects.OBJECT_EDITED)
          return true
        },
        (error) => {
          setToastErrorUpdate(toastId, { render: 'Hubo un error al modificar el objeto' })
          handleErrorsWithAction(error, ObjectTypes.MODIFY_OBJECT_FAILURE, dispatch)
          return false
        }
      )
    }
  },
  modifyObjectSuccess: (): ModifyObjectSuccess => ({
    type: ObjectTypes.MODIFY_OBJECT_SUCCESS
  }),

  getProducts: (description: string, serviceTypeId: string, countryCode = CountryIdCode.ARGENTINA): AppThunk => {
    return function (dispatch, getState) {
      clearTimeout(searchTimer)
      searchTimer = setTimeout(() => {
        const lastSearchID = new Date().getMilliseconds()

        dispatch({ type: ObjectTypes.GET_PRODUCTS_REQUEST, payload: { lastSearchID } })

        return API.CategoryCreation.Product.listAdmin({
          limit: 50,
          offset: 0,
          name: description,
          serviceTypeId,
          countryCode
        }).then(
          (response) => {
            if (lastSearchID === getState().CorporativeObjects.edit.lastProductSearchID) {
              dispatch(actions.getProductsSuccess(response.products))
            }
          },
          (error) => {
            handleErrorsWithAction(error, ObjectTypes.GET_PRODUCTS_FAILURE, dispatch)
          }
        )
      }, 250)
    }
  },
  getProductsSuccess: (products: FormattedProduct[]): GetProductsSuccess => ({
    type: ObjectTypes.GET_PRODUCTS_SUCCESS,
    payload: { products }
  }),

  getProductAttributes(productId: number): AppThunk {
    return async (dispatch): Promise<boolean> => {
      dispatch({ type: ObjectTypes.GET_PRODUCT_ATTRIBUTES_REQUEST })

      try {
        const { attributes } = await API.ObjectAdministration.Attribute.list({ productId, limit: 100, offset: 0 })
        dispatch({ type: ObjectTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS, payload: { attributes: attributes || [] } })
        return true
      } catch (error) {
        handleErrorsWithAction(error, ObjectTypes.GET_PRODUCT_ATTRIBUTES_FAILURE, dispatch)
        return false
      }
    }
  },

  setAttributeType: (index: number, attributeId: number, newAttributeTemplate = true): SetAttributeType => ({
    type: ObjectTypes.SET_ATTRIBUTE_TYPE,
    payload: { index, attributeId, newAttributeTemplate }
  }),

  setAttributeValue: (index: number, value: string): SetAttributeValue => ({
    type: ObjectTypes.SET_ATTRIBUTE_VALUE,
    payload: { index, value }
  }),

  deleteAttribute: (index: number, newAttributeTemplate = false): DeleteAttribute => ({
    type: ObjectTypes.DELETE_ATTRIBUTE,
    payload: { index, newAttributeTemplate }
  }),

  getLocationByCode: (code: string): AppThunk => {
    return async (dispatch) => {
      dispatch({ type: ObjectTypes.GET_LOCATION_BY_CODE_REQUEST })
      try {
        const location = await API.CategoryCreation.LocationInfo.getByCode(code)
        dispatch({
          type: ObjectTypes.GET_LOCATION_BY_CODE_SUCCESS,
          payload: { location }
        })
      } catch (e) {
        handleErrorsWithAction(e, ObjectTypes.GET_LOCATION_BY_CODE_FAILURE, dispatch)
      }
    }
  }
}

export default actions
