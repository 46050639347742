import { Reducer } from 'redux'
import {
  AgentsTableAction,
  AgentsTableState,
  GET_AGENTS_FAILURE,
  GET_AGENTS_REQUEST,
  GET_AGENTS_SUCCESS
} from '../types/agentsTable'

const LIMIT = 10

const initalState: AgentsTableState = {
  agents: [],
  loadingAgents: false,
  error: '',
  pagination: {
    page: 1,
    total: 0,
    pageSize: LIMIT
  }
}

const AgentsTableReducer: Reducer<AgentsTableState, AgentsTableAction> = (
  state = initalState,
  action
): AgentsTableState => {
  switch (action.type) {
    case GET_AGENTS_REQUEST:
      return {
        ...state,
        loadingAgents: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        loadingAgents: false,
        agents: action.payload.agents,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_AGENTS_FAILURE:
      return {
        ...state,
        loadingAgents: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default AgentsTableReducer
