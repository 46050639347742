import React, { useMemo } from 'react'
import StatusCard from '../../../components/StatusCard'
import styles from './BillingWarnings.module.scss'
import { BillingWarning } from '../../../projectApi/TransactionHandler/BillingData/warnings'
import { StatusCardSkeleton } from '../../../components/StatusCard/StatusCard'
import { useSelectStatusCard } from '../../../components/StatusCard/hook'

export type BillingWarningsProps = {
  warnings: BillingWarning[]
  fetchingBillingWarnings: boolean
  handleGetBillingData: (newParams: { warningId?: number }) => void
  currentWarningId?: number
}

const mapOfCardNames: { [key: string]: string } = {
  billing_with_full_removal: 'Abono con devolución total',
  billing_with_agreed_removal: 'Devolución pactada',
  billing_with_suspicious_variation: 'Variación sospechosa',
  billing_with_possible_shortage: 'Posible faltante',
  extra_large_billing: 'Abono XL',
  billing_with_variation_to_be_confirmed: 'Variación a confirmar'
}

const BillingWarnings: React.FC<BillingWarningsProps> = ({
  warnings,
  fetchingBillingWarnings,
  handleGetBillingData,
  currentWarningId,
  children
}) => {
  const [warningList, handleClickStatusCard] = useSelectStatusCard<BillingWarning>({
    list: warnings,
    idListKey: 'id',
    callback: (list) => {
      handleGetBillingData({ warningId: list.find(({ clicked }) => clicked)?.id ?? 0 })
    },
    utilsEffect: {
      dependencies: [currentWarningId],
      conditionalReRender: currentWarningId === 0 || currentWarningId === undefined
    }
  })

  const warningListUpdateNames = useMemo(() => {
    return warningList.map((warning) => ({ ...warning, name: mapOfCardNames[warning?.name] }))
  }, [warningList])

  return (
    <div className={styles.statusContainer}>
      {children}
      {fetchingBillingWarnings ? (
        <>
          <StatusCardSkeleton loadingColor="#B25E09" />
          <StatusCardSkeleton loadingColor="#B25E09" />
          <StatusCardSkeleton loadingColor="#B25E09" />
          <StatusCardSkeleton loadingColor="#B25E09" />
          <StatusCardSkeleton loadingColor="#B25E09" />
          <StatusCardSkeleton loadingColor="#B25E09" />
        </>
      ) : (
        warningListUpdateNames.map((warning) => {
          return (
            <StatusCard
              key={warning.id}
              customClassname={styles.warningCard}
              titleCard={warning.name}
              description={
                <>
                  <b>{warning.name}</b>
                  <p>{warning.description}</p>
                </>
              }
              total={warning.quantity}
              disabled={warning.quantity === 0}
              clicked={warning.clicked}
              onClick={() => handleClickStatusCard(warning.id)}
            />
          )
        })
      )}
    </div>
  )
}

export default BillingWarnings
