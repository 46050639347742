import { create } from './create'
import { update } from './update'
import { getById } from './getById'
import { getHistory } from './getHistory'

export const ProspectAPI = {
  getById,
  create,
  update,
  getHistory
}
