import types from './Payments.types'
import { List, Map } from 'immutable'
import moment from 'moment'
import { combineReducers } from 'redux'
import { isEmpty } from 'ramda'

const LIMIT = 50

const deleteChargeRoot = Map({
  selectedCharge: Map({}),
  loading: false,
  deleteModalOpen: false,
  error: ''
})

function deleteChargeReducer(state = deleteChargeRoot, action) {
  switch (action.type) {
    case types.DELETE_CHARGE:
      return state.merge({
        loading: true,
        error: ''
      })
    case types.DELETE_CHARGE_SUCCESS:
      return state.merge({
        selectedCharge: Map({}),
        loading: false,
        error: '',
        deleteModalOpen: false
      })
    case types.DELETE_CHARGE_FAIL:
      return state.merge({
        loading: false,
        error: action.payload.error
      })
    case types.SET_DELETE_MODAL_OPEN: {
      const { deleteModalOpen, selectedCharge } = action.payload
      return state.merge({
        deleteModalOpen,
        selectedCharge: isEmpty(selectedCharge) ? state.get('selectedCharge') : Map(selectedCharge),
        error: ''
      })
    }
    default:
      return state
  }
}

const chargesTableRoot = Map({
  loading: true,
  charges: List(),
  params: Map({
    Limit: LIMIT,
    Offset: 0,
    Column: 'ID',
    Order: 'desc'
  }),
  sorter: Map({
    order: 'descend',
    field: 'ID',
    columnKey: 'RealID'
  }),
  filter: Map({
    type: [],
    payment_method: []
  }),
  searchFilters: List([
    { key: 'id', text: '' },
    { key: 'day', text: '' },
    { key: 'start_date', text: '' },
    { key: 'end_date', text: '' },
    { key: 'user_id', text: '' }
  ]),
  pagination: Map({
    current: 1,
    total: 0
  })
})

function chargesTableReducer(state = chargesTableRoot, action) {
  switch (action.type) {
    case types.FETCH_CHARGES:
      return state.merge({
        charges: List(),
        loading: true,
        error: ''
      })
    case types.FETCH_CHARGES_SUCCESS:
      return state.merge({
        charges: List(action.payload.charges),
        loading: false,
        params: Map(action.payload.params),
        pagination: state.get('pagination').merge({
          total: action.payload.quantity
        })
      })
    case types.FETCH_CHARGES_FAIL:
      return state.merge({
        loading: false,
        error: action.payload.error
      })
    case types.CLEAN_FILTERS: {
      return state.merge({
        params: chargesTableRoot.get('params').merge({
          Column: state.getIn(['params', 'Column']),
          Order: state.getIn(['params', 'Order'])
        }),
        filter: chargesTableRoot.get('filter'),
        searchFilters: chargesTableRoot.get('searchFilters'),
        pagination: chargesTableRoot.get('pagination')
      })
    }
    case types.SET_FILTER: {
      return state.merge({
        filter: Map(action.payload.filter)
      })
    }
    case types.SET_SORTER: {
      return state.merge({
        sorter: Map(action.payload.sorter)
      })
    }
    case types.SET_PAGINATION: {
      return state.merge({
        pagination: Map(action.payload.pagination)
      })
    }
    case types.SET_SEARCH_FILTERS: {
      return state.merge({
        searchFilters: List(action.payload.searchFilters)
      })
    }
    case types.NEW_CHARGE_SUCCESS: {
      return state.merge({
        charges: state.get('charges').unshift(action.payload.chargeRule)
      })
    }
    case types.DELETE_CHARGE_SUCCESS: {
      const { id } = action.payload
      return state.merge({
        charges: state.get('charges').filter((charge) => charge.ID !== id),
        pagination: state.get('pagination').merge({
          total: state.getIn(['pagination', 'total']) - 1
        })
      })
    }
    default:
      return state
  }
}

const createChargeRoot = Map({
  createModalOpen: false,
  loading: false,
  amount: undefined,
  type: 1,
  startDate: moment(),
  paymentMethod: 2,
  endDate: null
})

function createChargeReducer(state = createChargeRoot, action) {
  switch (action.type) {
    case types.SET_CREATE_MODAL_OPEN: {
      const { createModalOpen } = action.payload
      return createModalOpen ? createChargeRoot.merge({ createModalOpen }) : state.merge({ createModalOpen })
    }
    case types.NEW_CHARGE:
      return state.merge({
        loading: true,
        error: ''
      })
    case types.NEW_CHARGE_SUCCESS:
      return state.merge({
        loading: false,
        createModalOpen: false,
        error: ''
      })
    case types.NEW_CHARGE_FAIL:
      return state.merge({
        loading: false,
        error: action.payload.error
      })
    case types.SET_TYPE: {
      return state.merge({
        type: action.payload.type
      })
    }
    case types.SET_AMOUNT: {
      return state.merge({
        amount: action.payload.amount
      })
    }
    case types.SET_DAY_TO_CHARGE: {
      return state.merge({
        dayToCharge: action.payload.dayToCharge
      })
    }
    case types.SET_START_DATE: {
      return state.merge({
        startDate: action.payload.startDate
      })
    }
    case types.SET_END_DATE: {
      return state.merge({
        endDate: action.payload.endDate
      })
    }
    case types.SET_PAYMENT_METHOD: {
      return state.merge({
        paymentMethod: action.payload.paymentMethod
      })
    }
    default:
      return state
  }
}

const initialUserSearchState = {
  userID: 0,
  userSearch: {
    byID: {
      value: undefined,
      options: [],
      loading: false,
      error: ''
    },
    byName: {
      value: undefined,
      options: [],
      loading: false,
      error: ''
    },
    byLastName: {
      value: undefined,
      options: [],
      loading: false,
      error: ''
    }
  }
}

const userSearchReducer = (state = initialUserSearchState, action) => {
  switch (action.type) {
    case types.SET_USER_ID: {
      return {
        ...state,
        userID: action.payload.userID
      }
    }
    case types.SET_USER_SEARCH_BY_ID: {
      return {
        ...state,
        userID: 0,
        userSearch: {
          ...initialUserSearchState.userSearch,
          byID: {
            ...state.userSearch.byID,
            ...action.payload,
            error: ''
          }
        }
      }
    }
    case types.SET_USER_SEARCH_BY_NAME: {
      return {
        ...state,
        userID: 0,
        userSearch: {
          ...initialUserSearchState.userSearch,
          byName: {
            ...state.userSearch.byName,
            ...action.payload,
            error: ''
          }
        }
      }
    }
    case types.SET_USER_SEARCH_BY_LAST_NAME: {
      return {
        ...state,
        userID: 0,
        userSearch: {
          ...initialUserSearchState.userSearch,
          byLastName: {
            ...state.userSearch.byLastName,
            ...action.payload,
            error: ''
          }
        }
      }
    }
    case types.SET_USER_SEARCH_BY_ID_FAILURE: {
      return {
        ...state,
        userID: 0,
        userSearch: {
          ...initialUserSearchState.userSearch,
          byID: {
            ...state.userSearch.byID,
            error: action.payload.error,
            loading: false
          }
        }
      }
    }
    case types.SET_USER_SEARCH_BY_NAME_FAILURE: {
      return {
        ...state,
        userID: 0,
        userSearch: {
          ...initialUserSearchState.userSearch,
          byName: {
            ...state.userSearch.byName,
            error: action.payload.error,
            loading: false
          }
        }
      }
    }
    case types.SET_USER_SEARCH_BY_LAST_NAME_FAILURE: {
      return {
        ...state,
        userID: 0,
        userSearch: {
          ...initialUserSearchState.userSearch,
          byLastName: {
            ...state.userSearch.byLastName,
            error: action.payload.error,
            loading: false
          }
        }
      }
    }
    case types.SET_CREATE_MODAL_OPEN:
      return action.payload.createModalOpen ? initialUserSearchState : state
    default:
      return state
  }
}

export default combineReducers({
  table: chargesTableReducer,
  charge: createChargeReducer,
  deleteCharge: deleteChargeReducer,
  userSearch: userSearchReducer
})
