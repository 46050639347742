import React, { useEffect, useState } from 'react'
import AMModalVehicles from '../components/AMVehicles'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ABMVehiclesActionsCreator from '../actions/ABMVehicles'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { BaseOption, InputChangeReason } from '../../../components/Select/Select'
import DeleteVehicleModal from '../components/DeleteVehicle'
import { VehicleCreateUpdateParams } from '../../../projectApi/Trips/Vehicles/common'

const checkDimensions = (selectedVehicle: VehicleCreateUpdateParams): string => {
  const { usableCapacity, maxCapacity, maxHeight, maxWidth, maxLength } = selectedVehicle
  if (usableCapacity > maxCapacity) return 'La capacidad usable no puede ser mayor a la máxima'

  const maxCapacityM3 = maxHeight * maxWidth * maxLength
  if (maxCapacityM3 > maxCapacity)
    return 'Las dimensiones del vehículo no pueden ser mayores que la de la máxima capacidad'
  return ''
}

const ABMVehiclesContainer = () => {
  const [autoComplete, setAutoComplete] = useState('')

  const dispatch = useDispatch()

  const {
    setModalVisibility,
    setVehicleField,
    createVehicle,
    setDeleteModalVisibility,
    editVehicle,
    getProviders,
    deleteVehicle
  } = bindActionCreators(ABMVehiclesActionsCreator, dispatch)

  const {
    modalVisible,
    id,
    selectedVehicle,
    creatingVehicle,
    updatingVehicle,
    editMode,
    deletingVehicle,
    deleteModalVisible,
    disabledDeleteButton,
    selectedVehicleToDelete,
    providers,
    loadingProviders
  } = useSelector((state) => state.VehiclesReducer.ABM)

  useEvents([Events.Vehicles.CREATE_VEHICLE, Events.Vehicles.EDIT_VEHICLE, Events.Vehicles.DELETE_VEHICLE], () => {
    setModalVisibility({ modalVisible: false })
  })

  useEvents([Events.Vehicles.DELETE_VEHICLE], () => {
    setDeleteModalVisibility(false)
  })

  const [error, setError] = useState('')
  const disabledSuccessButton =
    Object.values(selectedVehicle).some(
      (vehicleField) => !vehicleField || (typeof vehicleField === 'number' && isNaN(vehicleField))
    ) || Boolean(error)

  const handleCreateVehicle = () => {
    const error = checkDimensions(selectedVehicle)
    if (error) {
      setError(error)
      return
    }
    if (editMode) {
      editVehicle({ id, params: selectedVehicle })
      return
    }
    createVehicle({ params: selectedVehicle })
  }

  const handleDeleteVehicle = () => {
    selectedVehicleToDelete && deleteVehicle({ vehicleId: selectedVehicleToDelete?.id })
  }

  const deleteVehicleModalProps = {
    selectedVehicle: selectedVehicleToDelete,
    deletingVehicle,
    disabledDeleteButton,
    deleteModalVisible,
    setDeleteModalVisibility,
    handleDeleteVehicle
  }

  const providerOptions: BaseOption<number>[] = providers.map((provider) => ({
    label: provider.name,
    value: provider.id
  }))

  const selectedProvider = providerOptions.find((provider) => provider.value === selectedVehicle.providerId)

  const onSelectTextChange = (text: string, reason: InputChangeReason) => {
    setAutoComplete(text)
    if (reason === 'textChange') {
      getProviders(text)
    }
  }

  const onDeleteSelection = () => {
    getProviders('')
    setVehicleField('providerId', 0)
  }

  useEffect(() => {
    if (!editMode && modalVisible) {
      getProviders('')
    }
  }, [editMode, modalVisible])

  const AMModalVehicleProps = {
    error,
    setError,
    autoComplete,
    onSelectTextChange,
    loading: creatingVehicle || updatingVehicle,
    loadingProviders,
    providerOptions,
    selectedProvider,
    modalVisible,
    editMode,
    selectedVehicle,
    disabledSuccessButton,
    onDeleteSelection,
    setModalVisibility,
    handleCreateVehicle,
    setVehicleField
  }

  return (
    <>
      <AMModalVehicles {...AMModalVehicleProps} />
      <DeleteVehicleModal {...deleteVehicleModalProps} />
    </>
  )
}

export default ABMVehiclesContainer
