import { combineReducers } from 'redux'
import locationsTable from './locationsTable'
import locationObjects from './locationObjects'
import AddEditLocationModalReducer from './AddEditLocationModal'
import LocationDelete from './LocationDelete'

const rootReducer = combineReducers({
  locationsTable,
  locationObjects,
  addEditLocationModal: AddEditLocationModalReducer,
  LocationDelete
})

export default rootReducer
