import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import types from '../types/objectChanges'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import { OBJECT_STATUS } from '../constants/objectStatus'
import { ObjectSituationLabels } from '../constants/objectSituations'

const formatObjects = (objects) => {
  const formattedObjects = objects.map((object) => {
    return {
      objectId: object.changeAudit.objectId,
      ownerId: object.detail.ownerId,
      deletedAt: object.detail.deletedAt,
      deletedDescription: object.detail.deletedDescription,
      objectDeletedReasonId: object.detail.objectDeletedReasonId,
      identificationCodes: object.detail.identificationCodes ? `${object.detail?.identificationCodes[0]?.code}` : '-',
      createdAt: object.changeAudit.createdAt,
      location: object.detail.location
        ? `${object.detail.location.code} (${object.detail.location.id})`
        : object.detail.locationId || 'Sin identificación definida',
      user: object.changeAudit.user
        ? `${object.changeAudit.user.name} ${object.changeAudit.user.lastName} (${object.changeAudit.user.id})`
        : object.changeAudit?.userId,
      objectStatusId: `${OBJECT_STATUS.find((x) => x.numberValue === object.detail.objectStatusId)?.value}   (${
        object.detail.objectStatusId
      })`,
      objectSituation: ObjectSituationLabels[object.detail.objectSituation] || '-',
      productId: object.detail.product?.id,
      product: object.detail.product
        ? `(${object.detail.product?.id}) ${object.detail.product?.name}`
        : object.detail?.productId || 'Sin identificación definida',
      description: object.detail.description || '-',
      heightInCm: object.detail.heightInCm,
      widthInCm: object.detail.widthInCm,
      lengthInCm: object.detail.lengthInCm,
      weightInGr: object.detail.weightInGr,
      source: object.changeAudit.source
    }
  })
  return formattedObjects
}

const actions = {
  getObjectChangesFromObjectId: (id, params) => {
    return function (dispatch) {
      dispatch({ type: types.GET_OBJECT_CHANGES_FROM_ID_REQUEST })
      return apiPrivate
        .get(URL_BASE_OBJECTS + `/object-changes/search?resource_id=${id}`, {
          params
        })
        .then((response) => {
          const objectChangesWithoutParse = keysToCamel(response?.data?.results)
          const objectChanges = formatObjects(objectChangesWithoutParse)
          const quantity =
            (response.data.paging.has_next ? response.data.paging.items + 1 : response.data.paging.items) +
            params.offset
          dispatch({
            type: types.GET_OBJECT_CHANGES_FROM_ID_SUCCESS,
            payload: { objectChanges, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.GET_OBJECT_CHANGES_FROM_ID_FAILURE, dispatch)
        })
    }
  },
  cleanFilters: () => {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({ type: types.CLEAN_FILTERS })
        resolve()
      })
    }
  },
  setSorter: (sorter) => ({
    type: types.SET_SORTER,
    payload: { sorter }
  }),
  setFilter: (filter) => ({
    type: types.SET_FILTER,
    payload: { filter }
  }),
  setPagination: (pagination) => ({
    type: types.SET_PAGINATION,
    payload: { pagination }
  }),
  setSearchFilters: (searchFilters) => ({
    type: types.SET_SEARCH_FILTERS,
    payload: { searchFilters }
  })
}

export default actions
