import React, { useEffect, useMemo, useState } from 'react'
import usePlacesAutocomplete, { GeocodeResult, getGeocode } from 'use-places-autocomplete'
import { CountryIdCode } from '../CountrySelector/constants/constants'
import Select from '../Select'
import { BaseOption } from '../Select/Select'

interface UsePlacesAutocompleteProps {
  disabled?: boolean
  handleSuggest: (address: GeocodeResult) => void
  onRemove?: () => void
  currentCountry: CountryIdCode
  initialValueAutocomplete?: string
  customClassname?: string
  defaultValue?: string
}

const FormatCurrentCountry = {
  [CountryIdCode.ARGENTINA]: 'ar',
  [CountryIdCode.SPAIN]: 'es',
  [CountryIdCode.ALL]: ['ar', 'es']
}

type Option = BaseOption<string>

export const PlacesAutocomplete: React.FC<UsePlacesAutocompleteProps> = ({
  handleSuggest,
  disabled = false,
  onRemove,
  currentCountry,
  initialValueAutocomplete,
  customClassname,
  defaultValue
}) => {
  const [selected, setSelected] = useState<Option | null>(null)

  useEffect(() => {
    const initialValue = initialValueAutocomplete
      ? { label: initialValueAutocomplete, value: initialValueAutocomplete }
      : null

    setSelected(initialValue)
  }, [initialValueAutocomplete])

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: FormatCurrentCountry[currentCountry]
      }
    },
    defaultValue
  })

  const onInputChange = (text: string) => setValue(text)

  const options = useMemo((): Option[] => {
    return data.map(({ place_id, description }) => ({ label: description, value: place_id }))
  }, [data])

  const onSelect = async (value: Option) => {
    const results = await getGeocode({ placeId: value.value })
    handleSuggest(results[0])
    setSelected(value)
  }

  const onDelete = () => {
    setSelected(null)
    onRemove?.()
  }

  return (
    <Select
      loading={!ready}
      disabled={disabled}
      options={options}
      placeholder="Ingrese la dirección"
      autocomplete={{
        filterByInput: false,
        input: value,
        onInputChange
      }}
      onDelete={onDelete}
      onSelect={onSelect}
      selected={selected}
      className={customClassname}
    />
  )
}
