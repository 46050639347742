import { ObjectAdministrationAPI } from './ObjectAdministration'
import { AccountManagerAPI } from './AccountManager'
import { TransactionHandlerAPI } from './TransactionHandler'
import { TripsAPI } from './Trips'
import { CategoryCreationAPI } from './CategoryCreation'
import { MercurioAPI } from './Mercurio'
import { PaymentsAPI } from './Payments'
import { UserProfileAPI } from './UserProfile'
import { SalesAPI } from './Sales'
import { TimeslotAPI } from './Timeslot'
import { PaymentCollectorAPI } from './PaymentCollector'
import { ComplaintsAPI } from './Complaints'

export const API = {
  TransactionHandler: TransactionHandlerAPI,
  ObjectAdministration: ObjectAdministrationAPI,
  AccountManager: AccountManagerAPI,
  Timeslot: TimeslotAPI,
  Trips: TripsAPI,
  UserProfile: UserProfileAPI,
  CategoryCreation: CategoryCreationAPI,
  Mercurio: MercurioAPI,
  Payments: PaymentsAPI,
  Sales: SalesAPI,
  PaymentCollector: PaymentCollectorAPI,
  Complaints: ComplaintsAPI
}
