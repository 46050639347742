import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

export type CreateParams = {
  userId: number
  startDate: Moment
  endDate: Moment
  userDiscountReasonId: number
  fixedAmountDiscountInCents?: number
  discountPercent?: number
}

export function create({ startDate, endDate, ...restParams }: CreateParams): Promise<void> {
  const formattedParams = {
    ...snakecaseKeys(restParams),
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString()
  }

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/user-discounts`, formattedParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
