import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export type Params = {
  limit: number
  offset: number
  column: string
  order: Order
}

interface User {
  id: number
  name: string
  lastName: string
}

interface IdentificationCode {
  code: string
  id: number
}

interface Location {
  code: string
  id: number
}

interface Product {
  id: number
  name: string
}

interface ChangeAudit {
  objectId: number
  objectGuid: string
  userId: number
  user: User
  source: string
  createdAt: string
}

interface Detail {
  createdMethod: string
  deletedAt: string | null
  deletedDescription: string | null
  description: string
  hasNewProduct: boolean
  heightInCm: number
  id: number
  identificationCodes: IdentificationCode[]
  lengthInCm: number
  location: Location
  locationId: number
  name: string
  needsAuthorization: boolean
  objectDeletedReasonId: number
  objectSituation: string
  objectSituationReason: string
  objectStatusId: number
  ownerId: number
  product: Product
  productId: number
  productTypeId: number
  registryDate: string | null
  rentEnabled: boolean
  rentPrice: number
  weightInGr: number
  widthInCm: number
}

interface Result {
  changeAudit: ChangeAudit
  detail: Detail
}
interface Response {
  paging: Paging
  results: Result[]
}

interface Paging {
  items: number
  limit: number
  offset: number
  hasNext: boolean
}

interface APIResponse {
  paging: Paging
  results: Result[]
}

export async function changes(objectID: number): Promise<Response> {
  const params = {
    order: Order.DESC
  }
  return apiPrivate
    .get<APIResponse>(`${URL_BASE_OBJECTS}/corporate/object/search?resource_id=${objectID}`, { params })

    .then((response: AxiosResponse<APIResponse>) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
