import { AgentEvents, AgentEventsEnum } from './events/agentEvents'
import { BillingEvents, BillingEventsEnum } from './events/billingEvents'
import { CardsEvents, CardsEventsEnum } from './events/creditCardEvents'
import { DepositsEvents, DepositsEventsEnum } from './events/depositsEvents'
import { EditDepositViewEvents, EditDepositViewEventsEnum } from './events/editDepositEvents'
import { EditRemovalViewEvents } from './events/editRemovalEvents'
import { GlobalEvents, GlobalEventsEnum } from './events/globalEvents'
import { LeadsEvents, LeadsEventsEnum } from './events/leadsEvents'
import { ObjectsEvents, ObjectsEventsEnum } from './events/objectsEvents'
import { OriginsEvents, OriginsEventsEnum } from './events/originsEvents'
import { PhotographObjectsEvents, PhotographObjectsEventsEnum } from './events/photographObjectEvents'
import { PriceBandsEventsEnum, PriceBandsEvents } from './events/priceBands'
import { ProductEvents, ProductEventsEnum } from './events/productEvents'
import { ProposalEvents, ProposalEventsEnum } from './events/proposalEvents'
import { RemovalEvents, RemovalEventsEnum } from './events/removalEvents'
import { SalesRequestEvents, SalesRequestEventsEnum } from './events/saleRequestEvents'
import { ServiceEvents, ServicesEventsEnum } from './events/servicesEvents'
import { TransactionEvents, TransactionEventsEnum } from './events/transactionEvents'
import { TripsEvents, TripsEventsEnum } from './events/tripsEvents'
import { UserEvents, UserEventsEnum } from './events/user'
import { OperationsEventsEnum, OperationsEvents } from './events/operationsEvents'
import { BillingPaymentEventsEnum, BillingPaymentEvents } from './events/billingPaymentEvents'
import { NewProductEvents, NewProductEventsEnum } from './events/newProductEvents'
import { UploadPhotosEvents, UploadPhotosEnum } from './events/uploadPhotos'
import { UploadFilesEnum, UploadFilesEvents } from './events/uploadFiles'
import { NewAttributesEvents, NewAttributesEventsEnum } from './events/newAttributesEvents'
import { CorporateDepositsEnum, CorporateDepositsEvents } from './events/corporateDeposits'
import { DiscountsEvents, DiscountsEventsEnum } from './events/discountsEvents'
import { CorporateUsersEnum, CorporateUsersEvents } from './events/corporateUsers'
import { CorporateGuestUsersEnum, CorporateGuestUsersEvents } from './events/corporateGuestUsers'
import { LocationEvents, LocationEventsEnum } from './events/locationsEvents'
import { StorageDepositsEnum, StorageDepositsEvents } from './events/storageDeposit'
import { OperationCalendarEvents, OperationCalendarEventsEnum } from './events/operationCalendar'
import { CorporateObjectsEnum, CorporateObjectsEvents } from './events/corporateObjects'
import { CorporateProductsEnum, CorporateProductsEvents } from './events/corporateProducts'
import { CreditCardComponentEvents, CreditCardComponentEventsEnum } from './events/creditCardComponentEvents'
import { ShippersEvents, ShippersEventsEnum } from './events/shippersEvents'
import { ProvidersEnum, ProvidersEvents } from './events/providerEvents'
import { VehiclesEvents, VehiclesEventsEnum } from './events/vehiclesEvents'
import { DonationsEvents, DonationsEventsEnum } from './events/donationsEvents'
import { RemovalsValidationEvents, RemovalsValidationEventsEnum } from './events/removalsValidation'
import { ComplaintsEvents, ComplaintsEventsEnum } from './events/complaintsEvents'
import { DevelopmentEnum, DevelopmentEvents } from './events/developmentEvents'
import { BillingDiscountEvents, BillingDiscountEventsEnum } from './events/billingDiscountEvents'
import { CBUEvents, CBUEventsEnum } from './events/cbuEvents'

export const Events = {
  Agents: AgentEventsEnum,
  Attributes: NewAttributesEventsEnum,
  Billing: BillingEventsEnum,
  BillingPayment: BillingPaymentEventsEnum,
  BillingDiscount: BillingDiscountEventsEnum,
  CreditCard: CardsEventsEnum,
  CBU: CBUEventsEnum,
  CreditCardComponent: CreditCardComponentEventsEnum,
  Deposits: DepositsEventsEnum,
  CorporateDeposits: CorporateDepositsEnum,
  StorageDeposits: StorageDepositsEnum,
  Development: DevelopmentEnum,
  CorporateUsers: CorporateUsersEnum,
  CorporateGuestUsers: CorporateGuestUsersEnum,
  CorporateObjects: CorporateObjectsEnum,
  CorporateProducts: CorporateProductsEnum,
  Discounts: DiscountsEventsEnum,
  Operations: OperationsEventsEnum,
  Global: GlobalEventsEnum,
  Leads: LeadsEventsEnum,
  Location: LocationEventsEnum,
  Objects: ObjectsEventsEnum,
  Origins: OriginsEventsEnum,
  EditDepositView: EditDepositViewEventsEnum,
  PhotographObjects: PhotographObjectsEventsEnum,
  Product: ProductEventsEnum,
  Proposal: ProposalEventsEnum,
  Removal: RemovalEventsEnum,
  RemovalsValidation: RemovalsValidationEventsEnum,
  SaleRequest: SalesRequestEventsEnum,
  Services: ServicesEventsEnum,
  Transaction: TransactionEventsEnum,
  Trips: TripsEventsEnum,
  User: UserEventsEnum,
  PriceBands: PriceBandsEventsEnum,
  NewProduct: NewProductEventsEnum,
  UploadPhotos: UploadPhotosEnum,
  UploadFiles: UploadFilesEnum,
  OperationCalendar: OperationCalendarEventsEnum,
  Shippers: ShippersEventsEnum,
  Provider: ProvidersEnum,
  Vehicles: VehiclesEventsEnum,
  Donations: DonationsEventsEnum,
  Complaints: ComplaintsEventsEnum
} as const

export type AllEvents = UserEvents &
  NewAttributesEvents &
  GlobalEvents &
  DepositsEvents &
  CorporateDepositsEvents &
  StorageDepositsEvents &
  CorporateUsersEvents &
  CorporateGuestUsersEvents &
  CorporateProductsEvents &
  DiscountsEvents &
  OperationsEvents &
  TripsEvents &
  LeadsEvents &
  LocationEvents &
  CardsEvents &
  ObjectsEvents &
  ServiceEvents &
  BillingEvents &
  BillingPaymentEvents &
  BillingDiscountEvents &
  AgentEvents &
  TransactionEvents &
  PhotographObjectsEvents &
  ProposalEvents &
  ProductEvents &
  SalesRequestEvents &
  RemovalEvents &
  RemovalsValidationEvents &
  EditDepositViewEvents &
  EditRemovalViewEvents &
  OriginsEvents &
  PriceBandsEvents &
  NewProductEvents &
  UploadPhotosEvents &
  UploadFilesEvents &
  CorporateObjectsEvents &
  CreditCardComponentEvents &
  OperationCalendarEvents &
  CorporateObjectsEvents &
  ShippersEvents &
  VehiclesEvents &
  DonationsEvents &
  ProvidersEvents &
  ComplaintsEvents &
  DevelopmentEvents &
  CBUEvents
