import moment, { Moment } from 'moment'
import React, { ReactElement } from 'react'
import { FilterOption, FiltersProps, RangePickerProps, SearchProps, SortDirection, SortProps } from '../types/types'
import { GenericObject } from '../../../utils/utilityTypes'
import { CategoryFilter, SearchFilter, Sorter } from './entities'

export const getSearchProps = (
  key: string,
  label: string,
  searchFunction: (key: string, newValue: string) => void,
  currentSearchFilters: SearchFilter[]
): SearchProps | undefined => {
  const found = currentSearchFilters.find((sf) => sf.key === key)
  if (!found) return

  return {
    label,
    value: found.text,
    onConfirm: (newValue) => searchFunction(key, newValue)
  }
}

export const getSortProps = <T extends GenericObject>(
  key: keyof T,
  currentSorter: Sorter<T>,
  sorterFunction: (newSorter: Sorter<T>) => void
): SortProps => {
  return {
    direction: currentSorter.order,
    isActive: currentSorter.columnKey === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        order: currentSorter.columnKey === key ? newSortDirection : SortDirection.ASC,
        columnKey: key,
        field: key
      })
  }
}

export const getFilterProps = <T extends string>(
  options: FilterOption[],
  currentFilter: CategoryFilter<T>,
  category: T,
  handleFiltering: (newFilter: CategoryFilter<T>) => void,
  multiple = false
): FiltersProps => {
  return {
    multiple,
    options: options,
    selected: currentFilter?.[category] || [],
    onConfirm: (selected) => handleFiltering({ ...currentFilter, [category]: selected })
  }
}

export const getRangePickerProps = <T extends string>(
  key: T,
  currentSearchFilters: SearchFilter[],
  handleRangePicker: (key: T, startDate?: Moment, endDate?: Moment) => void
): RangePickerProps => {
  const foundFilter = currentSearchFilters.find((sf) => sf.key === key)

  return {
    startDate: foundFilter?.text[0] && moment(foundFilter.text[0]).isValid() ? moment(foundFilter.text[0]) : undefined,
    endDate: foundFilter?.text[1] && moment(foundFilter.text[1]).isValid() ? moment(foundFilter.text[1]) : undefined,
    onConfirm: (startDate, endDate) => handleRangePicker(key, startDate, endDate)
  }
}

export const parseDate = (stringDate: string | Moment, hideTime?: boolean): ReactElement => {
  return moment(stringDate).isValid() ? (
    <span style={{ textAlign: 'left' }}>
      {moment(stringDate).format('dd DD[-]MM[-]YY')}
      {!hideTime && (
        <>
          <br />
          {moment(stringDate).format('HH[:]mm')}
        </>
      )}
    </span>
  ) : (
    <span />
  )
}

export const parseMomentDate = (date: Moment | null, textAlign?: 'left' | 'center' | 'right', noBreak?: boolean) => {
  const formattedDate = (
    <>
      {date?.format('dd DD[/]MM[/]YY')}
      {noBreak ? ' ' : <br />}
      {date?.format('HH[:]mm')}
    </>
  )
  return date?.isValid() ? (
    <span style={{ textAlign: textAlign || 'center', whiteSpace: 'nowrap' }}>
      {date.isAfter('01-01-0001') ? formattedDate : '-'}
    </span>
  ) : (
    ''
  )
}
