import { Reasons } from '../../../projectApi/Sales/reasons'
import { SalesRequest } from '../../../projectApi/Sales/SalesRequest/list'

export type SalesRequestViewState = {
  salesRequest: SalesRequest | null
  error: string
  isSellModalOpen: boolean
  isLoadingModal: boolean
  isOpenModal: boolean
  loadingView: boolean
  saleCostArg: number
  publicationPrice: number
  reasons: Reasons[]
  loadingReasons: boolean
  isCounterProposalActive: boolean
}

export const SET_COUNTER_PROPOSAL_VIEW = 'SALES_REQUEST/SET_COUNTER_PROPOSAL_VIEW'

export interface SetCounterProposalView {
  type: typeof SET_COUNTER_PROPOSAL_VIEW
  payload: {
    isCounterProposalActive: boolean
  }
}

export type AcceptProposalActionProps = {
  pvp: number
  saleCost: number
  saleMargin: number
}

export const GET_REASONS_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_REASONS_REQUEST'
export const GET_REASONS_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_REASONS_SUCCESS'
export const GET_REASONS_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_REASONS_FAILURE'
export interface GetReasonsRequest {
  type: typeof GET_REASONS_REQUEST
}

export interface GetReasonsSuccess {
  type: typeof GET_REASONS_SUCCESS
  payload: {
    reasons: Reasons[]
  }
}

export interface GetReasonsFailure {
  type: typeof GET_REASONS_FAILURE
  payload: { error: string }
}

export type GetReasonsAction = GetReasonsRequest | GetReasonsSuccess | GetReasonsFailure

export const SET_PUBLICATION_PRICE = 'SALES_REQUEST/SALES_REQUEST_VIEW/SET_PUBLICATION_PRICE'
export interface SetPublicationPrice {
  type: typeof SET_PUBLICATION_PRICE
  payload: {
    publicationPrice: number
  }
}

export const GET_SALE_COST_ARG_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_SALE_COST_ARG_REQUEST'
export const GET_SALE_COST_ARG_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_SALE_COST_ARG_SUCCESS'
export const GET_SALE_COST_ARG_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_SALE_COST_ARG_FAILURE'
export interface GetSaleCostArgRequest {
  type: typeof GET_SALE_COST_ARG_REQUEST
}

export interface GetSaleCostArgSuccess {
  type: typeof GET_SALE_COST_ARG_SUCCESS
  payload: {
    saleCostArg: number
  }
}

export interface GetSaleCostArgFailure {
  type: typeof GET_SALE_COST_ARG_FAILURE
  payload: {
    error: string
  }
}

export type GetSaleCostArgAction = GetSaleCostArgRequest | GetSaleCostArgSuccess | GetSaleCostArgFailure

export const SET_OPEN_MODAL = 'SALES_REQUEST/SALES_REQUEST_VIEW/SET_OPEN_MODAL'

export interface SetOpenModal {
  type: typeof SET_OPEN_MODAL
  payload: {
    isOpenModal: boolean
  }
}

export const GET_SALES_REQUEST_VIEW_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_SALES_REQUEST_VIEW_REQUEST'
export const GET_SALES_REQUEST_VIEW_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_SALES_REQUEST_VIEW_SUCCESS'
export const GET_SALES_REQUEST_VIEW_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/GET_SALES_REQUEST_VIEW_FAILURE'

export interface GetSalesRequestViewRequest {
  type: typeof GET_SALES_REQUEST_VIEW_REQUEST
}

export interface GetSalesRequestViewSuccess {
  type: typeof GET_SALES_REQUEST_VIEW_SUCCESS
  payload: {
    saleRequest: SalesRequest
  }
}

export interface GetSalesRequestViewFailure {
  type: typeof GET_SALES_REQUEST_VIEW_FAILURE
  payload: {
    error: string
  }
}

export type GetSalesRequestViewAction =
  | GetSalesRequestViewRequest
  | GetSalesRequestViewSuccess
  | GetSalesRequestViewFailure

export const UPDATE_SALE_REQUEST_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/UPDATE_SALE_REQUEST_REQUEST'
export const UPDATE_SALE_REQUEST_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/UPDATE_SALE_REQUEST_SUCCESS'
export const UPDATE_SALE_REQUEST_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/UPDATE_SALE_REQUEST_FAILURE'

export interface UpdateSaleRequestRequest {
  type: typeof UPDATE_SALE_REQUEST_REQUEST
}

export interface UpdateSaleRequestSuccess {
  type: typeof UPDATE_SALE_REQUEST_SUCCESS
}

export interface UpdateSaleRequestFailure {
  type: typeof UPDATE_SALE_REQUEST_FAILURE
  payload: { error: string }
}

export type UpdateSaleRequestAction = UpdateSaleRequestRequest | UpdateSaleRequestSuccess | UpdateSaleRequestFailure

export const CREATE_COUNTER_PROPOSAL_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/CREATE_COUNTER_PROPOSAL_REQUEST'
export const CREATE_COUNTER_PROPOSAL_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/CREATE_COUNTER_PROPOSAL_SUCCESS'
export const CREATE_COUNTER_PROPOSAL_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/CREATE_COUNTER_PROPOSAL_FAILURE'
export interface CreateCounterProposalRequest {
  type: typeof CREATE_COUNTER_PROPOSAL_REQUEST
}

export interface CreateCounterProposalSuccess {
  type: typeof CREATE_COUNTER_PROPOSAL_SUCCESS
}

export interface CreateCounterProposalFailure {
  type: typeof CREATE_COUNTER_PROPOSAL_FAILURE
  payload: {
    error: string
  }
}

export type CreateCounterProposalAction =
  | CreateCounterProposalRequest
  | CreateCounterProposalSuccess
  | CreateCounterProposalFailure

export const CLEAR_VIEW_STATE = 'SALES_REQUEST/SALES_REQUEST_VIEW/CLEAR_VIEW_STATE'

export interface ClearViewState {
  type: typeof CLEAR_VIEW_STATE
}

export const ACCEPT_PROPOSAL_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/ACCEPT_PROPOSAL_REQUEST'
export const ACCEPT_PROPOSAL_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/ACCEPT_PROPOSAL_SUCCESS'
export const ACCEPT_PROPOSAL_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/ACCEPT_PROPOSAL_FAILURE'

export interface AcceptProposalRequest {
  type: typeof ACCEPT_PROPOSAL_REQUEST
}

export interface AcceptProposalSuccess {
  type: typeof ACCEPT_PROPOSAL_SUCCESS
}

export interface AcceptProposalFailure {
  type: typeof ACCEPT_PROPOSAL_FAILURE
  payload: { error: string }
}

export type AcceptProposalAction = AcceptProposalRequest | AcceptProposalSuccess | AcceptProposalFailure

export const MANAGE_SELL_MODAL = 'SALES_REQUEST/SALES_REQUEST_VIEW/MANAGE_SELL_MODAL'

export interface ManageSellModal {
  type: typeof MANAGE_SELL_MODAL
  payload: {
    isSellModalOpen: boolean
  }
}

export type ManageSellModalAction = ManageSellModal

export const SELL_OBJECT_REQUEST = 'SALES_REQUEST/SALES_REQUEST_VIEW/SELL_OBJECT_REQUEST'
export const SELL_OBJECT_SUCCESS = 'SALES_REQUEST/SALES_REQUEST_VIEW/SELL_OBJECT_SUCCESS'
export const SELL_OBJECT_FAILURE = 'SALES_REQUEST/SALES_REQUEST_VIEW/SELL_OBJECT_FAILURE'
export interface SellObjectRequest {
  type: typeof SELL_OBJECT_REQUEST
}

export interface SellObjectSuccess {
  type: typeof SELL_OBJECT_SUCCESS
}

export interface SellObjectFailure {
  type: typeof SELL_OBJECT_FAILURE
  payload: { error: string }
}

export type SellObjectAction = SellObjectRequest | SellObjectSuccess | SellObjectFailure

export type SalesRequestViewAction =
  | GetSalesRequestViewAction
  | GetSaleCostArgAction
  | GetReasonsAction
  | UpdateSaleRequestAction
  | SetOpenModal
  | SetPublicationPrice
  | SetCounterProposalView
  | CreateCounterProposalAction
  | ClearViewState
  | GetReasonsAction
  | AcceptProposalAction
  | ManageSellModalAction
  | SellObjectAction
