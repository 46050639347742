import types from '../types/cancel'

const initialState = {
  operationId: 0,
  guid: '',
  loading: false,
  error: '',
  open: false
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.OPEN:
      return {
        ...state,
        open: true,
        operationId: action.payload.operationId,
        guid: action.payload.guid
      }
    case types.CLOSE:
      return initialState
    case types.CANCEL_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.CANCEL_SUCCESS:
      return { ...state, loading: false, open: false }
    case types.CANCEL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
