import React, { useEffect, useRef, useState } from 'react'
import styles from './addNewCard.module.scss'
import CreditCardComponent from '../../NewRemoval/components/payments/CreditCardComponent'
import { TextField } from '../../../components/TextField/TextField'
import Select, { BaseOption } from '../../../components/Select/Select'
import { Steps, creditCardRegx } from '../constants'
import Payment from 'payment'
import { CreateCardSpreedly } from '../types/BillingPayment'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'

type Option = BaseOption<number>

const options: Option[] = [
  { label: 'DNI', value: 1 },
  { label: 'Cédula', value: 2 },
  { label: 'L.C.', value: 3 },
  { label: 'L.E.', value: 4 },
  { label: 'Otro', value: 5 }
]

interface AddNewCardProps {
  searchUserActions: typeof searchUserActionCreators
  loading: boolean
  userColppyInfo: UserColppy | null
  setOptionRadio: React.Dispatch<React.SetStateAction<number>>
  setStep: React.Dispatch<React.SetStateAction<Steps>>
}
const handleCreditCardRegExpValidator = (value: string, regExp: RegExp) => {
  if (!value.length) return
  const onlyContainsNumbers = regExp.test(value)
  if (!onlyContainsNumbers) return true
}

const VALIDATE_DATE_MATCH = '(\\d{0,2})(\\d{0,2})'

const cvcRules = (cvc: string) => Payment.fns.validateCardCVC(cvc)
const cardNumberRule = (cardNumber: string) => Payment.fns.validateCardNumber(cardNumber)
const expiryRule = (expire: string) => Payment.fns.validateCardExpiry(expire)
const cardHolderRule = (cardHolder: string) => /^\w+(?:(?:,\s\w+)+|(?:\s\w+)+)$/gm.test(cardHolder)
const typeDocumentRule = (typeDocument: string) => typeDocument.length > 2
const documentRule = (document: string) => document.length > 3

export const AddNewCard = ({
  searchUserActions,
  loading,
  userColppyInfo,
  setStep,
  setOptionRadio
}: AddNewCardProps) => {
  const [cvc, setCvc] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cardholderName, setCardholderName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [document, setDocument] = useState('')
  const [typeDocument, setTypeDocument] = useState<Option | null>(null)
  const inputCard = useRef<HTMLInputElement>(null)
  const inputExpireDate = useRef<HTMLInputElement>(null)

  const handleChangeCardNumber = () => {
    if (!inputCard.current) return
    const cardValue = inputCard.current.value.replace(/\D/g, '')
    inputCard.current.value = Payment.fns.formatCardNumber(cardValue)
    const numbers = inputCard.current.value.replace(/(\D)/g, '')
    setCardNumber(numbers)
  }

  const handleChangeValidDate = () => {
    if (!inputExpireDate.current) return
    const expireValue = inputExpireDate.current.value.replace(/(\D)/g, '').match(VALIDATE_DATE_MATCH)
    if (!expireValue) return
    inputExpireDate.current.value = !expireValue[2] ? expireValue[1] : `${expireValue[1]}/${expireValue[2]}`
    const numbers = inputExpireDate.current.value.replace(/(\D)/g, '')
    setExpiry(numbers)
  }

  const handleSubmitNewCreditCard = async (params: CreateCardSpreedly) => {
    // @ts-ignore
    const success: boolean = await searchUserActions.saveSpreedlyCard({ ...params })
    if (success) {
      searchUserActions.getUserPaymentMethods(Number(userColppyInfo?.id) || 0)
      setStep(Steps.PAYMENT)
      setOptionRadio(0)
    }
  }

  const isDisableAddNewCard = !(
    cvcRules(cvc) &&
    cardNumberRule(cardNumber) &&
    cardHolderRule(cardholderName) &&
    typeDocumentRule(typeDocument?.label || '') &&
    documentRule(document) &&
    expiryRule(inputExpireDate?.current?.value || '')
  )

  useEffect(() => {
    handleChangeCardNumber()
  }, [cardNumber])

  useEffect(() => {
    handleChangeValidDate()
  }, [expiry])

  const handleSelect = (option: Option) => setTypeDocument(option)
  return (
    <div>
      <h3>Agregar nueva tarjeta</h3>
      <div className={styles.cardContainer}>
        <div>
          <CreditCardComponent
            cvc={cvc}
            expiry={expiry}
            name={cardholderName}
            number={cardNumber}
            locale={{
              valid: ''
            }}
            placeholders={{
              name: 'Nombre y Apellido'
            }}
          />
        </div>
        <div className={styles.fieldsContainer}>
          <TextField
            label="Número de tarjeta"
            onChange={handleChangeCardNumber}
            value={inputCard?.current?.value ?? ''}
            error={
              (handleCreditCardRegExpValidator(cardNumber, creditCardRegx) || !cardNumberRule(cardNumber)) &&
              cardNumber.length !== 0
            }
            errorText={cardNumber.length !== 16 ? 'Cantidad de caracteres inválida' : 'Revisá el número ingresado'}
            inputProps={{ maxLength: 19, ref: inputCard }}
          />
          <div>
            <TextField
              label="Nombre del titular"
              onChange={setCardholderName}
              value={cardholderName}
              error={cardholderName.length !== 0 && !cardHolderRule(cardholderName)}
              errorText="Cantidad de caracteres inválida"
            />
            {cardHolderRule(cardholderName) ||
              (cardholderName.length === 0 && <p className={styles.pInfo}>Tal cual esté impreso en la tarjeta</p>)}
          </div>
          <div className={styles.twoColumns}>
            <TextField
              label="Vencimiento"
              onChange={handleChangeValidDate}
              error={!expiryRule(inputExpireDate?.current?.value || '') && expiry.length !== 0}
              errorText="Fecha de vencimiento inválida"
              value={inputExpireDate?.current?.value ?? ''}
              inputProps={{ maxLength: 5, ref: inputExpireDate }}
            />
            <TextField
              label="CCV"
              onChange={setCvc}
              error={!cvcRules(cvc) && cvc.length !== 0}
              errorText="Cantidad de caracteres inválida"
              value={cvc}
              inputProps={{ maxLength: 4 }}
            />
          </div>
          <div className={styles.twoColumns}>
            <Select
              options={options}
              onSelect={handleSelect}
              selected={typeDocument}
              placeholder="Seleccione una opción"
            />
            <TextField
              label="Número"
              error={!documentRule(document) && document.length !== 0}
              errorText="Error en el documento"
              onChange={setDocument}
              value={document}
            />
          </div>
        </div>
      </div>
      <div className={styles.nextPageContainer}>
        <button
          disabled={isDisableAddNewCard || loading}
          className={isDisableAddNewCard || loading ? styles.buttonDisable : ''}
          onClick={() => {
            handleSubmitNewCreditCard({
              userId: userColppyInfo?.id || '',
              cvc,
              expireDate: expiry,
              cardNumber,
              document,
              documentType: typeDocument?.label || '',
              fullname: cardholderName
            })
          }}>
          Continuar
        </button>
      </div>
    </div>
  )
}
