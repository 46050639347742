import { Tooltip } from 'antd'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CaretDown, Check, Cross } from '../../icons'
import { Keys, useKeyPress } from '../../utils/hooks/useKeyPress'
import { useOutsideClick } from '../../utils/hooks/useOutsideClick'
import { useScroll } from '../../utils/hooks/useScroll'
import { LoadingIndicator } from '../LoadingIndicator'
import Portal from '../Portal'
import styles from './Select.module.scss'

type Position = 'top' | 'bottom'

type OptionValue = number | string

export type BaseOption<T extends OptionValue> = {
  label: string
  value: T
  disabled?: boolean
}

type SingleSelectProps<T extends OptionValue, U extends BaseOption<T>> = {
  onSelect: (option: U) => void
  onDelete?: () => void
  selected?: U | null
  multiple?: false | undefined
}

type MultiSelectProps<T extends OptionValue, U extends BaseOption<T>> = {
  onSelect: (options: U) => void
  onDelete: (option: U) => void
  selected: U[]
  multiple: true
}

export type InputChangeReason = 'optionSelected' | 'textChange'

type SelectProps<T extends OptionValue, U extends BaseOption<T>> = {
  className?: string
  name?: string
  id?: string
  options: U[]
  placeholder: string
  loading?: boolean
  disabled?: boolean
  autocomplete?: {
    input: string
    onInputChange: (text: string, reason: InputChangeReason) => void
    filterByInput: boolean
  }
  optionRenderer?: (option: U) => React.ReactNode
  position?: Position
} & (SingleSelectProps<T, U> | MultiSelectProps<T, U>)

const Select = <T extends OptionValue, U extends BaseOption<T>>({
  className,
  options,
  name,
  id,
  selected,
  multiple,
  autocomplete,
  loading,
  placeholder,
  disabled,
  position = 'bottom',
  onSelect,
  onDelete,
  optionRenderer
}: SelectProps<T, U>) => {
  const [open, setOpen] = useState(false)
  const [portalCoords, setPortalCoords] = useState({ left: 0, top: 0, width: 0 })
  const [keyedOption, setKeyedOption] = useState<{ option: U | null; index: number | null }>({
    option: null,
    index: null
  })
  const wrapperRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef(null)
  const inputRef = useRef(null)
  const HEIGHT_OPTION_WRAPPER = 254

  const handleClose = () => {
    setOpen(false)
    setKeyedOption({ option: null, index: null })
  }
  useOutsideClick([wrapperRef, popupRef, inputRef], handleClose)

  const handleOpen = () => {
    if (open) return handleClose()
    const rect = wrapperRef.current?.getBoundingClientRect()
    const maxNumberOptionsToDisplay = 7
    const minHeightOption = 40
    const variableHeight =
      filteredOptions.length >= maxNumberOptionsToDisplay
        ? HEIGHT_OPTION_WRAPPER
        : filteredOptions.length * (rect ? rect.height : minHeightOption)

    if (!rect) return
    const coords =
      position === 'top'
        ? {
            left: rect.x,
            top: rect.y - variableHeight,
            width: rect.width
          }
        : {
            left: rect.x,
            top: rect.y + rect.height,
            width: rect.width
          }
    setPortalCoords(coords)
    setOpen(true)
  }

  const handleScroll = useCallback(() => {
    const rect = wrapperRef.current?.getBoundingClientRect()
    if (!rect) return
    setPortalCoords({
      left: rect.x,
      top: rect.y + rect.height,
      width: rect.width
    })
  }, [])

  useScroll({ handleScroll })

  const isOptionSelected = useCallback(
    (option: U) =>
      multiple && Array.isArray(selected)
        ? !!selected.find((s) => s.value === option.value)
        : // @ts-ignore
          selected?.value === option.value,
    [multiple, selected]
  )

  const onOptionSelect = useCallback(
    (option: U) => {
      if (!multiple) handleClose()
      const optionIsSelected = isOptionSelected(option)
      if (autocomplete) autocomplete.onInputChange('', 'optionSelected')
      return multiple && optionIsSelected && onDelete ? onDelete(option) : onSelect(option)
    },
    [isOptionSelected, multiple, autocomplete, onDelete, onSelect]
  )

  const filteredOptions = useMemo(() => {
    const autocompleteIsFiltering = autocomplete?.filterByInput && autocomplete.input.length

    return autocompleteIsFiltering
      ? options.filter((o) => o.label.toLowerCase().indexOf(autocomplete?.input.toLowerCase()) !== -1)
      : options
  }, [autocomplete?.filterByInput, autocomplete?.input, options])

  // ENTER
  const enterCallback = useCallback(() => {
    if (keyedOption.option) onOptionSelect(keyedOption.option)
  }, [keyedOption, onOptionSelect])

  useKeyPress({
    key: Keys.ENTER,
    modifiers: [],
    callback: enterCallback
  })

  // ARROW DOWN
  const arrowDownCallback = useCallback(() => {
    if (!open) return
    if (!keyedOption.option) return setKeyedOption({ option: filteredOptions[0], index: 0 })
    if (keyedOption && keyedOption.index !== null && keyedOption.index < filteredOptions.length - 1) {
      setKeyedOption({ option: filteredOptions[keyedOption.index + 1], index: keyedOption.index + 1 })
    }
  }, [keyedOption, filteredOptions, open])
  useKeyPress({
    key: Keys.ARROW_DOWN,
    modifiers: [],
    callback: arrowDownCallback
  })

  // ARROW UP
  const arrowUpCallback = useCallback(() => {
    if (!open) return
    if (!keyedOption.option)
      return setKeyedOption({ option: filteredOptions[filteredOptions.length - 1], index: filteredOptions.length - 1 })
    if (keyedOption && keyedOption.index) {
      setKeyedOption({ option: filteredOptions[keyedOption.index - 1], index: keyedOption.index - 1 })
    }
  }, [keyedOption, filteredOptions, open])
  useKeyPress({
    key: Keys.ARROW_UP,
    modifiers: [],
    callback: arrowUpCallback
  })

  // ESCAPE
  useKeyPress({
    key: Keys.ESCAPE,
    modifiers: [],
    callback: () => {
      if (open) handleClose()
      setKeyedOption({ option: null, index: null })
    }
  })

  useEffect(() => {
    setKeyedOption({ option: null, index: null })
  }, [autocomplete?.input])

  return (
    <div className={clsx(styles.wrapper, disabled && styles.disabledWrapper, className)} ref={wrapperRef}>
      <div
        role="select"
        className={styles.select}
        onClick={handleOpen}
        style={disabled ? { backgroundColor: 'rgba(138, 138, 138, 0.2)' } : {}}>
        {((Array.isArray(selected) && !selected.length) || (!multiple && !selected)) && !autocomplete && (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        {selected && (
          <div className={styles.selected}>
            {Array.isArray(selected)
              ? selected.map((s) => (
                  <SelectedBadge
                    key={s.value}
                    label={s.label}
                    onDelete={() => {
                      handleClose()
                      if (onDelete) onDelete(s)
                    }}
                  />
                ))
              : optionRenderer
              ? optionRenderer(selected)
              : selected.label}
          </div>
        )}
        {autocomplete && (
          <input
            id={id}
            name={name}
            style={{ display: selected ? 'none' : '' }}
            className={styles.input}
            value={autocomplete.input}
            placeholder={!selected || (Array.isArray(selected) && !selected.length) ? placeholder : ''}
            ref={inputRef}
            onClick={(e) => {
              e.stopPropagation()
              handleOpen()
            }}
            onChange={(e) => {
              if (!open) handleOpen()
              autocomplete.onInputChange(e.target.value, 'textChange')
            }}
          />
        )}
        <div className={styles.rightHandSymbols}>
          {loading && (
            <div style={{ position: 'relative', top: 1 }}>
              <LoadingIndicator size={'small'} />
            </div>
          )}
          {!multiple && onDelete && selected && (
            <Tooltip title="Vaciar">
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  // @ts-ignore
                  if (onDelete) onDelete()
                }}
                className={styles.clearable}>
                <Cross fill={'grey'} />
              </div>
            </Tooltip>
          )}
          <CaretDown className={styles.caret} />
        </div>
      </div>
      <Portal>
        {open && filteredOptions.length >= 1 && (
          <div
            ref={popupRef}
            className={styles.optionWrapper}
            style={{
              top: portalCoords.top,
              left: portalCoords.left,
              width: portalCoords.width,
              maxHeight: HEIGHT_OPTION_WRAPPER
            }}>
            {filteredOptions.map((o) => {
              // const optionIsFiltered =
              //   autocomplete?.filterByInput &&
              //   autocomplete.input.length &&
              //   o.label.toLowerCase().indexOf(autocomplete?.input.toLowerCase()) === -1
              // if (optionIsFiltered) return null

              return (
                <Option
                  key={o.value}
                  label={optionRenderer ? optionRenderer(o) : o.label}
                  onClick={() => onOptionSelect(o)}
                  selected={isOptionSelected(o)}
                  disabled={o.disabled}
                  keyed={!!keyedOption.option && o.value === keyedOption.option?.value}
                />
              )
            })}
          </div>
        )}
      </Portal>
    </div>
  )
}

const SelectedBadge: React.FC<{ label: string; onDelete: () => void }> = ({ label, onDelete }) => {
  return (
    <div className={styles.selectedBadge}>
      <span>{label}</span>
      <button
        onClick={(e) => {
          e.stopPropagation()
          onDelete()
        }}>
        <Cross />
      </button>
    </div>
  )
}

const Option: React.FC<{
  label: string | React.ReactNode
  onClick: () => void
  selected: boolean
  disabled?: boolean
  keyed: boolean
}> = ({ label, selected, disabled, onClick, keyed }) => {
  return (
    <div
      className={clsx(
        styles.option,
        selected && styles.optionSelected,
        keyed && styles.optionKeyed,
        disabled && styles.disabledOption
      )}
      onClick={onClick}>
      <span className={styles.optionLabel}>{label}</span>
      <div style={{ opacity: selected ? 1 : 0 }}>
        <Check />
      </div>
    </div>
  )
}

export default Select
