import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import extraActionsCreators from '../actions/extras'
import ExtraService, { ExtraServiceProps } from '../components/ExtraService'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { PROPOSAL_MODES } from '../../../common/operations/constants'

type OperationTypeProps = {
  mode: typeof PROPOSAL_MODES[keyof typeof PROPOSAL_MODES]
}

const ExtraServiceContainer = (ownProps: OperationTypeProps) => {
  const dispatch = useDispatch()
  const extraActions = bindActionCreators(extraActionsCreators, dispatch)

  const { extraAssistant, additionalCost } = useSelector((root) => root.NewProposal.extras)
  const { extraAssistantPrice, loadingFlags } = useSelector((root) => root.NewProposal.flags)
  const { countryCode } = useSelector(
    (root) => root.NewProposal.prospect.selectedProspect || { countryCode: CountryIdCode.ARGENTINA }
  )

  const { currency: currencySymbol } = useCountryInfo(countryCode)

  const props: ExtraServiceProps = {
    globalDisabled: ownProps.mode === PROPOSAL_MODES.VIEW_PROPOSAL,
    extraActions,
    extraAssistantCount: extraAssistant.count,
    extraAssistantPrice,
    extraAssistantEnabled: extraAssistant.enabled,
    additionalCostPrice: additionalCost.amount,
    additionalCostEnabled: additionalCost.enabled,
    additionalCostComment: additionalCost.comment,
    loadingExtras: loadingFlags,
    currencySymbol
  }
  return <ExtraService {...props} />
}
export default ExtraServiceContainer
