import { Reducer } from 'redux'
import {
  CreateDiscountDatesActions,
  ModalCreateDiscountDayState,
  CreateDiscountDayTypes,
  ModalCreateDiscountDayTypes,
  UpdateDiscountDayTypes,
  DeleteDiscountDayTypes
} from '../types/ABMDiscountDays'

const initialState: ModalCreateDiscountDayState = {
  editMode: false,
  modalVisible: false,
  deleteModalVisible: false,
  creatingDiscount: false,
  updatingDiscount: false,
  deletingDiscount: false,
  selectedDiscount: null,
  selectedDay: null,
  discountRate: {
    error: '',
    value: undefined
  }
}

const createDiscountDaysReducer: Reducer<ModalCreateDiscountDayState, CreateDiscountDatesActions> = (
  state: ModalCreateDiscountDayState = initialState,
  action: CreateDiscountDatesActions
): ModalCreateDiscountDayState => {
  switch (action.type) {
    case CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_REQUEST: {
      return { ...state, creatingDiscount: true }
    }
    case CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_SUCCESS:
    case CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_FAILURE: {
      return { ...state, creatingDiscount: false }
    }
    case UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_REQUEST: {
      return { ...state, updatingDiscount: true }
    }
    case UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_SUCCESS:
    case UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_FAILURE: {
      return { ...state, updatingDiscount: false }
    }
    case DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_REQUEST: {
      return { ...state, deletingDiscount: true }
    }
    case DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_SUCCESS:
    case DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_FAILURE: {
      return { ...state, deletingDiscount: false }
    }
    case ModalCreateDiscountDayTypes.SET_DISCOUNT_DAY:
      return {
        ...state,
        selectedDay: action.payload.selectedDay
      }
    case ModalCreateDiscountDayTypes.SET_DISCOUNT_RATE:
      return {
        ...state,
        discountRate: {
          ...state.discountRate,
          value: Number(action.payload.discountRate)
        }
      }
    case ModalCreateDiscountDayTypes.SET_DISCOUNT_RATE_ERROR:
      return {
        ...state,
        discountRate: {
          ...state.discountRate,
          error: action.payload.errorRate
        }
      }
    case ModalCreateDiscountDayTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible, editMode } = action.payload

      return modalVisible
        ? {
            ...state,
            modalVisible,
            editMode: editMode || false
          }
        : initialState
    }
    case ModalCreateDiscountDayTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisible } = action.payload

      return deleteModalVisible
        ? {
            ...state,
            deleteModalVisible
          }
        : initialState
    }
    case ModalCreateDiscountDayTypes.SET_SELECTED_DISCOUNT:
      return {
        ...state,
        selectedDiscount: action.payload.selectedDiscount,
        selectedDay: action.payload.selectedDiscount?.weekday || state.selectedDay,
        discountRate: {
          ...state.discountRate,
          value: action.payload.selectedDiscount?.discount || state.discountRate.value
        }
      }
    default:
      return state
  }
}

export default createDiscountDaysReducer
