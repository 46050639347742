export enum TripsEventsEnum {
  TRIP_CREATED = 'TRIP/TRIP_CREATED',
  TRIP_UPDATED = 'TRIP/TRIP_UPDATED',
  TRIP_CANCELED = 'TRIP/TRIP_CANCELED'
}

export type TripsEvents = {
  [TripsEventsEnum.TRIP_CREATED]: () => void
  [TripsEventsEnum.TRIP_UPDATED]: (tripId: number) => void
  [TripsEventsEnum.TRIP_CANCELED]: (tripId: number) => void
}
