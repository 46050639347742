import {
  CBUActionTypes,
  CBUState,
  CBUStatusType,
  CLEAN_CBU,
  SET_CBU,
  SET_CBU_STATUS,
  SET_DEFAULT_HOLDER_NAME,
  SET_HOLDER_NAME,
  USE_DEFAULT_HOLDER_NAME
} from './types'
import { UserActionTypes } from '../../sections/NewRemoval/types/users'
import { ProposalTypes } from '../../sections/NewProposal/types/proposals'

const initialCBU = {
  defaultHolderName: '',
  holderName: '',
  cbu: '',
  cbuStatus: {
    status: CBUStatusType.typing,
    message: ''
  },
  isUsingDefaultHolderName: true
}

function reducer(state: CBUState = initialCBU, action: CBUActionTypes) {
  switch (action.type) {
    case CLEAN_CBU:
      return initialCBU
    case SET_HOLDER_NAME:
      return { ...state, holderName: action.payload.holderName }
    case SET_DEFAULT_HOLDER_NAME:
      return { ...state, defaultHolderName: action.payload.holderName }
    case USE_DEFAULT_HOLDER_NAME: {
      const { useDefaultHolderName } = action.payload
      return {
        ...state,
        isUsingDefaultHolderName: useDefaultHolderName,
        holderName: useDefaultHolderName ? state.defaultHolderName : ''
      }
    }
    case SET_CBU:
      return { ...state, cbu: action.payload.cbu }
    case SET_CBU_STATUS:
      return { ...state, cbuStatus: action.payload.cbuStatus }
    case UserActionTypes.GET_USER_SUCCESS: {
      const { user } = action.payload
      return { ...state, holderName: `${user.Name} ${user.LastName}` }
    }
    case ProposalTypes.GET_PROPOSALS_SUCCESS: {
      const { firstName, lastName } = action.payload.prospect
      const holderName = `${firstName} ${lastName}`
      return { ...state, holderName, defaultHolderName: holderName }
    }
    default:
      return state
  }
}

export default reducer
