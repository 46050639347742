export const SEND_PRE_BILLING_REPORT_REQUEST = 'BILLINGS_BY_CLIENT/SEND_PRE_BILLING_REPORT_REQUEST'
export const SEND_PRE_BILLING_REPORT_SUCCESS = 'BILLINGS_BY_CLIENT/SEND_PRE_BILLING_REPORT_SUCCESS'
export const SEND_PRE_BILLING_REPORT_FAILURE = 'BILLINGS_BY_CLIENT/SEND_PRE_BILLING_REPORT_FAILURE'

export interface SendPreBillingReportRequest {
  type: typeof SEND_PRE_BILLING_REPORT_REQUEST
}

export interface SendPreBillingReportSuccess {
  type: typeof SEND_PRE_BILLING_REPORT_SUCCESS
}

export interface SendPreBillingReportFailure {
  type: typeof SEND_PRE_BILLING_REPORT_FAILURE
  payload: {
    error: string
  }
}

export type SendPreBillingReportActions =
  | SendPreBillingReportRequest
  | SendPreBillingReportSuccess
  | SendPreBillingReportFailure
