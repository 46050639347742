import types from '../types/massBillingModal'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const massBillingGenerateTimeout = 2000

export default {
  massBillingGenerate() {
    return (dispatch) => {
      dispatch({ type: types.MASS_BILLING_REQUEST })
      return apiPrivate
        .post(URL_BASE_TRANSACTIONS + '/authorizations', undefined, {
          timeout: massBillingGenerateTimeout
        })
        .then((_) => {
          dispatch({ type: types.MASS_BILLING_SUCCESS })
        })
        .catch((error) => {
          const message = 'El proceso de facturación se inició correctamente'
          if (
            error.code === 'ECONNABORTED' &&
            error.message === `timeout of ${massBillingGenerateTimeout}ms exceeded`
          ) {
            dispatch({ type: types.MASS_BILLING_SUCCESS, payload: { message } })
          } else {
            handleErrorsWithAction(error, types.MASS_BILLING_FAILURE, dispatch)
          }
        })
    }
  },

  openMassBillingModal() {
    return { type: types.OPEN_MODAL_MASS_BILLING, payload: {} }
  },

  closeMassBillingModal() {
    return { type: types.CLOSE_MODAL_MASS_BILLING }
  }
}
