import { Reducer } from 'redux'
import { CarouselModalAction, CarouselModalState, SET_OPEN } from '../types/CarouselModal'

const initialState: CarouselModalState = {
  open: false,
  images: []
}

const CarouselModalReducer: Reducer<CarouselModalState, CarouselModalAction> = (
  state = initialState,
  action
): CarouselModalState => {
  switch (action.type) {
    case SET_OPEN:
      if (!action.payload.open) {
        return initialState
      }
      return { ...state, open: true, images: action.payload.imageUrls || initialState.images }
    default:
      return state
  }
}

export default CarouselModalReducer
