import {
  ItemsActionTypes,
  ItemsDataTypes,
  ItemsFetchInitialItemsTypes,
  ItemsFetchItemsTypes,
  ItemsGetActualM3StorageTypes,
  ItemsRecalculateItemsTypes,
  ItemsState,
  SelectedItem
} from '../types/items'
import { ITEM_TYPES_VALUES, PRICE_BY_VALUES } from '../../../common/operations/constants'
import { ProposalTypes } from '../types/proposals'
import { Item } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { ProspectSearchTypes } from '../types/prospect'
import { NewProductPrice } from '../../../projectApi/CategoryCreation/Product/common'
import { Reducer } from 'redux'
import { TransportTypes } from '../types/transport'

const updateItemsPrice = (items: SelectedItem[], products: NewProductPrice[]): SelectedItem[] =>
  items.map((item) => {
    if (!item.selectedItem) return item
    const foundProduct = products.find((product) => product.guid === item.selectedItem?.guid)
    // If product was not found, it will assign price as 0
    const price = foundProduct?.price || 0

    return {
      ...item,
      selectedItem: { ...item.selectedItem, price, priceRecalculate: price }
    }
  })

const initialState: ItemsState = {
  initialItems: [],
  loadingActualM3Storage: false,
  errorActualM3Storage: '',
  actualM3Storage: 0,
  fetched: false,
  loadingRecalculatePrice: false,
  errorRecalculatePrice: '',
  lastRecalculateSearchTime: 0,
  selectedItems: [
    {
      items: [],
      selectedItem: null,
      lastSearchID: null,
      quantity: 1,
      loading: true,
      type: ITEM_TYPES_VALUES.PRODUCT,
      packaging: false,
      disassemble: false,
      byProposal: false,
      deleted: false,
      floors: 0
    }
  ]
}

const newItem = (item: SelectedItem, propsToMerge: Partial<SelectedItem>): SelectedItem => ({
  ...item,
  ...propsToMerge
})

const modifyAnItem = (indexItem: number, selectedItems: SelectedItem[], propsToMerge: Partial<SelectedItem>) =>
  selectedItems.map((item, index) => {
    if (indexItem === index) return newItem(item, propsToMerge)
    return item
  })

const addEmptyItem = (state: ItemsState): SelectedItem => ({
  items: state.initialItems,
  lastSearchID: null,
  selectedItem: null,
  quantity: 1,
  loading: false,
  type: ITEM_TYPES_VALUES.PRODUCT,
  packaging: false,
  disassemble: false,
  floors: 0,
  byProposal: false,
  deleted: false
})

const ItemsReducer: Reducer<ItemsState, ItemsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return initialState
    case ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH: {
      return { ...initialState, initialItems: state.initialItems, selectedItems: [addEmptyItem(state)] }
    }
    case TransportTypes.RESET_ITEMS_BY_SOLUTION: {
      const { resetInitialItems } = action.payload

      return {
        ...state,
        selectedItems: resetInitialItems ? initialState.selectedItems : state.selectedItems
      }
    }
    case ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_REQUEST: {
      return {
        ...state,
        loadingActualM3Storage: true
      }
    }
    case ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_SUCCESS: {
      return {
        ...state,
        loadingActualM3Storage: false,
        actualM3Storage: action.payload.actualM3Storage
      }
    }
    case ItemsGetActualM3StorageTypes.GET_ACTUAL_M3_STORAGE_FAILURE: {
      return {
        ...state,
        loadingActualM3Storage: false,
        errorActualM3Storage: action.payload.error
      }
    }
    case ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_REQUEST: {
      return {
        ...state,
        loadingRecalculatePrice: true,
        lastRecalculateSearchTime: action.payload.lastSearchID
      }
    }
    case ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_SUCCESS: {
      return {
        ...state,
        loadingRecalculatePrice: false,
        selectedItems: updateItemsPrice(state.selectedItems, action.payload.products)
      }
    }
    case ItemsRecalculateItemsTypes.RECALCULATE_ITEMS_FAILURE: {
      return {
        ...state,
        loadingRecalculatePrice: false,
        errorRecalculatePrice: action.payload.error
      }
    }
    case ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_REQUEST: {
      return {
        ...state,
        selectedItems: modifyAnItem(state.selectedItems.length - 1, state.selectedItems, { loading: true })
      }
    }
    case ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_SUCCESS: {
      const { items } = action.payload
      return {
        ...state,
        initialItems: items,
        fetched: true,
        selectedItems: modifyAnItem(state.selectedItems.length - 1, state.selectedItems, {
          loading: false,
          items
        })
      }
    }
    case ItemsFetchInitialItemsTypes.FETCH_INITIAL_ITEMS_FAILURE: {
      return {
        ...state,
        initialItems: [],
        selectedItems: modifyAnItem(0, state.selectedItems, { loading: false })
      }
    }
    case ItemsDataTypes.SET_INITIAL_ITEMS: {
      const { index, lastSearchID } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, {
          items: state.initialItems,
          loading: false,
          lastSearchID
        })
      }
    }
    case ItemsDataTypes.CLEAN_ITEM: {
      return {
        ...state,
        selectedItems: modifyAnItem(action.payload.index, state.selectedItems, {
          selectedItem: null
        })
      }
    }
    case ItemsDataTypes.REMOVE_ITEM: {
      return {
        ...state,
        selectedItems: state.selectedItems.filter((item, index) => index !== action.payload.index)
      }
    }
    case ItemsDataTypes.SET_ITEM: {
      const { index, item } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, {
          selectedItem: item
        })
      }
    }
    case ItemsFetchItemsTypes.FETCH_ITEMS_REQUEST: {
      const { index, lastSearchID } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, {
          items: [],
          loading: true,
          lastSearchID
        })
      }
    }
    case ItemsFetchItemsTypes.FETCH_ITEMS_SUCCESS: {
      const { index, items } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, {
          items,
          loading: false
        })
      }
    }
    case ItemsFetchItemsTypes.FETCH_ITEMS_FAILURE: {
      const { index } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, {
          loading: false
        })
      }
    }
    case ItemsDataTypes.NEW_ITEM: {
      return {
        ...state,
        selectedItems: [...state.selectedItems, addEmptyItem(state)]
      }
    }
    case ItemsDataTypes.SET_ITEM_QUANTITY: {
      const { index, quantity } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, { quantity })
      }
    }
    case ItemsDataTypes.SET_ITEM_PACKAGING: {
      const { index, packaging } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, { packaging })
      }
    }
    case ItemsDataTypes.SET_DISASSEMBLE: {
      const { index, disassemble } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, { disassemble })
      }
    }
    case ItemsDataTypes.SET_ITEM_FLOORS: {
      const { index, floors } = action.payload
      return {
        ...state,
        selectedItems: modifyAnItem(index, state.selectedItems, { floors })
      }
    }
    case ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS: {
      const { proposal, addEmptyObject } = action.payload
      const { item, type } = proposal
      if (type === PRICE_BY_VALUES.M3) return state
      const selectedItems = item.map((item: Item): SelectedItem => {
        const product = { ...item.product, id: item.product.productId }
        return {
          items: [product],
          lastSearchID: null,
          selectedItem: product,
          quantity: item.amount,
          loading: false,
          byProposal: true,
          type: item.type as ITEM_TYPES_VALUES,
          packaging: item.product.packagingCostInCents > 0,
          disassemble: item.product.disassemblyCost > 0,
          floors: 1 // Put floors as 1 just for convenient. We don't save floors quantity on BE
        }
      })
      return {
        ...state,
        selectedItems: [...selectedItems, ...(addEmptyObject ? [addEmptyItem(state)] : [])]
      }
    }
    default:
      return state
  }
}

export default ItemsReducer
