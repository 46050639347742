import React from 'react'
import { Modal } from 'antd'
import styles from './AddEditLocationModal.module.scss'
import locationDeleteModalActions from '../actions/LocationDelete'
import { LocationDeleteStateType } from '../types/LocationDelete'

type LocationDeleteModalProps = {
  locationDeleteModalActions: typeof locationDeleteModalActions
  locationDeleteModalState: LocationDeleteStateType
  onOkClick: () => void
}

const LocationDeleteModal = (props: LocationDeleteModalProps) => {
  const { locationDeleteModalActions, locationDeleteModalState, onOkClick } = props
  const { setModal } = locationDeleteModalActions
  const { error, isModalOpen, loading, location } = locationDeleteModalState

  const onClose = () => {
    setModal({ isModalOpen: false, location: null })
  }

  return (
    <Modal
      width={750}
      title={'Eliminar ubicación'}
      visible={isModalOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={onClose}
      okButtonProps={{
        loading,
        disabled: loading,
        className: styles.modalButtonOk
      }}
      okText={'Eliminar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p className={styles.description}>¿Está seguro que desea eliminar la ubicación?</p>
      <p>
        {location?.code} - {location?.description}
      </p>
      {error && (
        <div className={styles.errorContainer}>
          <p>{error}</p>
        </div>
      )}
    </Modal>
  )
}

export default LocationDeleteModal
