import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_MERCURIO } from '../../../../endpoints'
import { APIOrigins, Origins } from './common'

interface Response {
  origins: Origins[]
}

type APIResponse = APIOrigins[]

export function list(): Promise<Response> {
  return apiPrivate.get(`${URL_BASE_MERCURIO}/sirena-sources/search`).then((response: AxiosResponse<APIResponse>) => {
    if (!response.data) throw new Error('Respuesta incorrecta al obtener los orígenes')

    const camelResponse: Response = {
      origins: camelcaseKeys(response.data, { deep: true }).map((origin: any) => ({
        createdAt: origin.createdAt,
        deletedAt: origin.deletedAt,
        id: origin.id,
        mercurioSource: {
          available: origin.mercurioSource.available,
          createdAt: origin.mercurioSource.createdAt,
          deletedAt: origin.mercurioSource.deletedAt,
          id: origin.id,
          spanishDisplay: origin.mercurioSource.spanishDisplay,
          updatedAt: origin.mercurioSource.updatedAt
        },
        sirenaSource: origin.sirenaSource,
        updatedAt: origin.updatedAt
      }))
    }
    return camelResponse
  })
}
