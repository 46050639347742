import React from 'react'
import { DynamicTable } from '../../../../components/DynamicTable'
import { Column, Pagination } from '../../../../components/DynamicTable/types/types'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { GuestUser } from '../../../../projectApi/AccountManager/Satellite/guest'
import moment from 'moment'
import { Check, Cross } from '../../../../icons'
import { GuestUsersSearchFilter, GuestUsersTableSearchKey } from '../types/userABM'

type UserTableProps = {
  isLoading: boolean
  isLoadingGuests: boolean
  guestUsers: GuestUser[]
  pagination: Pagination
  searchFilters: GuestUsersSearchFilter[]
  handleSearch: (key: GuestUsersTableSearchKey, newValue: string) => void
  actions: MenuAction<GuestUser>[]
}

const UsersGuestTable = ({ guestUsers, isLoadingGuests, pagination, actions }: UserTableProps) => {
  const columns: Column<GuestUser>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'role',
      label: 'Rol',
      renderDataCell: ({ role }) => role.descripcion
    },
    {
      key: 'used',
      label: 'Confirmado',
      renderDataCell: ({ used }) => (used ? <Check /> : <Cross />)
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      tooltip: 'Fecha de creación',
      minWidth: '150px',
      renderDataCell: ({ createdAt }) => {
        const formatDate = (date: string) => (
          <>
            {moment(date).local().format('dd DD[-]MM[-]YY')}
            <br />
            {moment(date).local().format('HH[:]mm')}
          </>
        )
        return formatDate(createdAt)
      }
    },
    {
      key: 'updatedAt',
      label: 'Fecha de actualización',
      tooltip: 'Fecha de actualización',
      minWidth: '150px',
      renderDataCell: ({ updatedAt }) => {
        const formatDate = (date: string) => (
          <>
            {moment(date).local().format('dd DD[-]MM[-]YY')}
            <br />
            {moment(date).local().format('HH[:]mm')}
          </>
        )
        return formatDate(updatedAt)
      }
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={isLoadingGuests}
      rows={guestUsers}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default UsersGuestTable
