// CU = Clave Uniforme (CBU / CVU)

import { APICBU } from '@/projectApi/UserProfile/Admin/CBU/common'

export type UserInfo = { userId: string; fullName: string }

export type CBUModalState = {
  modalVisibility: boolean
  addingNewCBU: boolean
  updatingCBU: boolean
  editMode: boolean
  asMain: boolean
  currentCU?: APICBU
  userInfo?: UserInfo
}

export enum CBUModalCommonTypes {
  SET_MODAL_VISIBILITY = 'USERS/CBUS/SET_MODAL_VISIBILITY',
  SET_AS_MAIN = 'USERS/CBUS/SET_AS_MAIN'
}

export interface SetModalVisibility {
  type: CBUModalCommonTypes.SET_MODAL_VISIBILITY
  payload: {
    isVisible: boolean
    editMode?: boolean
    currentCU?: APICBU
    userInfo?: UserInfo
  }
}

export interface SetAsMain {
  type: CBUModalCommonTypes.SET_AS_MAIN
  payload: {
    check: boolean
  }
}

type CBUModalCommonAction = SetModalVisibility | SetAsMain

export enum AddNewCBUModalTypes {
  ADD_NEW_CBU_REQUEST = 'USERS/CBUS/ADD_NEW_CBU_REQUEST',
  ADD_NEW_CBU_SUCCESS = 'USERS/CBUS/ADD_NEW_CBU_SUCCESS',
  ADD_NEW_CBU_FAILURE = 'USERS/CBUS/ADD_NEW_CBU_FAILURE'
}

export interface AddNewCBURequest {
  type: AddNewCBUModalTypes.ADD_NEW_CBU_REQUEST
}

export interface AddNewCBUSuccess {
  type: AddNewCBUModalTypes.ADD_NEW_CBU_SUCCESS
}

export interface AddNewCBUFailure {
  type: AddNewCBUModalTypes.ADD_NEW_CBU_FAILURE
}

type AddNewCBUModalAction = AddNewCBURequest | AddNewCBUSuccess | AddNewCBUFailure

export enum UpdateCBUModalTypes {
  UPDATE_CBU_REQUEST = 'USERS/CBUS/UPDATE_CBU_REQUEST',
  UPDATE_CBU_SUCCESS = 'USERS/CBUS/UPDATE_CBU_SUCCESS',
  UPDATE_CBU_FAILURE = 'USERS/CBUS/UPDATE_CBU_FAILURE'
}

export interface UpdateCBURequest {
  type: UpdateCBUModalTypes.UPDATE_CBU_REQUEST
}

export interface UpdateCBUSuccess {
  type: UpdateCBUModalTypes.UPDATE_CBU_SUCCESS
}

export interface UpdateCBUFailure {
  type: UpdateCBUModalTypes.UPDATE_CBU_FAILURE
}

type UpdateCBUModalAction = UpdateCBURequest | UpdateCBUSuccess | UpdateCBUFailure

export type CBUModalAction = CBUModalCommonAction | AddNewCBUModalAction | UpdateCBUModalAction
