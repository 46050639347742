import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDays/list'
import { DiscountWeekdaysFilterKey } from '../../../projectApi/Timeslot/Discounts/SpecificDays/common'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  SetDiscountDay,
  ModalCreateDiscountDayTypes,
  CreateDiscountDayRequest,
  CreateDiscountDayTypes,
  SetModalVisibility,
  SetSelectedDiscount,
  UpdateDiscountDayTypes,
  UpdateDiscountDayRequest,
  SetDeleteModalVisibility,
  DeleteDiscountDayRequest,
  DeleteDiscountDayTypes
} from '../types/ABMDiscountDays'

const createDiscountDaysActionsCreator = {
  createDiscount({ day, discountRate }: { day: DiscountWeekdaysFilterKey; discountRate: number }): AppThunk {
    return async (dispatch) => {
      const request: CreateDiscountDayRequest = {
        type: CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Creando descuento, por favor espere...')
      try {
        await API.Timeslot.discounts.SpecificDays.create({ day, discount: discountRate })

        dispatch({
          type: CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento creado correctamente.' })
        Emitter.emit(Events.Discounts.CREATE_DISCOUNT)
      } catch (error) {
        const messageError = handleErrorsWithAction(error, CreateDiscountDayTypes.CREATE_DISCOUNT_DAY_FAILURE, dispatch)
        setToastErrorUpdate(toastId, { render: messageError ?? 'Error al crear el descuento' })
      }
    }
  },

  updateDiscount({
    discountId,
    day,
    discountRate
  }: {
    discountId: number
    day: DiscountWeekdaysFilterKey
    discountRate: number
  }): AppThunk {
    return async (dispatch) => {
      const request: UpdateDiscountDayRequest = {
        type: UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Editando descuento, por favor espere...')
      try {
        await API.Timeslot.discounts.SpecificDays.update({ day, discount: discountRate, discountId })

        dispatch({
          type: UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento editado correctamente.' })
        Emitter.emit(Events.Discounts.UPDATE_DISCOUNT)
      } catch (error) {
        const messageError = handleErrorsWithAction(error, UpdateDiscountDayTypes.UPDATE_DISCOUNT_DAY_FAILURE, dispatch)
        setToastErrorUpdate(toastId, { render: messageError ?? 'Error al editar el descuento' })
      }
    }
  },

  deleteDiscount({ discountId }: { discountId: number }): AppThunk {
    return async (dispatch) => {
      const request: DeleteDiscountDayRequest = {
        type: DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Eliminando descuento, por favor espere...')
      try {
        await API.Timeslot.discounts.SpecificDays.delete({ discountId })

        dispatch({
          type: DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_SUCCESS
        })
        setToastSuccessUpdate(toastId, { render: 'Descuento eliminado correctamente.' })
        Emitter.emit(Events.Discounts.UPDATE_DISCOUNT)
      } catch (error) {
        const messageError = handleErrorsWithAction(error, DeleteDiscountDayTypes.DELETE_DISCOUNT_DAY_FAILURE, dispatch)
        setToastErrorUpdate(toastId, { render: messageError ?? 'Error al eliminar el descuento' })
      }
    }
  },

  setModalVisibility({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }): SetModalVisibility {
    return {
      type: ModalCreateDiscountDayTypes.SET_MODAL_VISIBILITY,
      payload: { modalVisible, editMode }
    }
  },

  setDeleteModalVisibility(deleteModalVisible: boolean): SetDeleteModalVisibility {
    return {
      type: ModalCreateDiscountDayTypes.SET_DELETE_MODAL_VISIBILITY,
      payload: { deleteModalVisible }
    }
  },

  setDiscountDay(selectedDay: DiscountWeekdaysFilterKey | null): SetDiscountDay {
    return {
      type: ModalCreateDiscountDayTypes.SET_DISCOUNT_DAY,
      payload: { selectedDay }
    }
  },

  setErrorDiscountRate(errorRate: string) {
    return {
      type: ModalCreateDiscountDayTypes.SET_DISCOUNT_RATE_ERROR,
      payload: { errorRate }
    }
  },

  setDiscountRate(discountRate: string): AppThunk {
    return (dispatch) => {
      dispatch({
        type: ModalCreateDiscountDayTypes.SET_DISCOUNT_RATE,
        payload: { discountRate }
      })

      if (Number(discountRate) > 100) {
        dispatch(createDiscountDaysActionsCreator.setErrorDiscountRate('El descuento no puede ser más del 100%'))
      }
    }
  },

  setSelectedDiscount(selectedDiscount: Discount): SetSelectedDiscount {
    return {
      type: ModalCreateDiscountDayTypes.SET_SELECTED_DISCOUNT,
      payload: { selectedDiscount }
    }
  }
}

export default createDiscountDaysActionsCreator
