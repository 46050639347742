import React from 'react'
import Modal from 'antd/es/modal'
import { ProductCatalogue } from '@/projectApi/CategoryCreation/Catalog/search'
import styles from './ModalProductDetail.module.scss'
import { Divider } from 'antd'
import { useHistory } from 'react-router'
import { useLocalization } from '@/packages/localization'

export const ModalProductDetail = ({
  visible,
  onClose,
  productDetail,
  isFetching
}: {
  visible: boolean
  onClose: () => void
  productDetail: ProductCatalogue | null
  isFetching: boolean
}) => {
  const history = useHistory()
  const { strings } = useLocalization()

  const active = visible && !!productDetail

  const handleClick = () => {
    history.push({
      pathname: '/newProduct',
      search: 'byCatalog=true'
    })
    onClose()
  }

  return (
    <Modal visible={active} onCancel={onClose} width={'65%'} okText="Agregar producto" onOk={handleClick}>
      {isFetching ? (
        <ProductDetailSkeleton />
      ) : (
        <div className={styles.container}>
          <img src={productDetail?.productByCountries[0].photo.url} alt="product" className={styles.img} />
          <div>
            <h3>{productDetail?.description}</h3>
            <Divider />
            <div className={styles.sizes}>
              <p>
                {strings.generic.measure.height}: {productDetail?.heightInCm} cm.
              </p>
              <p>
                {strings.generic.measure.width}: {productDetail?.widthInCm} cm.
              </p>
              <p>
                {strings.generic.measure.length}: {productDetail?.lengthInCm} cm.
              </p>
              <p>
                {strings.generic.measure.weight}: {(productDetail?.weightInGr || 0) / 100} kg.
              </p>
            </div>
            <p className={styles.categoryText}>
              {strings.ProductCatalog.attributes.category}: {productDetail?.category.name}
            </p>
          </div>
        </div>
      )}
    </Modal>
  )
}

const ProductDetailSkeleton = () => {
  return (
    <article style={{ height: '400px' }}>
      <svg
        role="img"
        width="100%"
        height="100%"
        fill="red"
        aria-labelledby="loading-aria"
        viewBox="0 0 390 42"
        preserveAspectRatio="none">
        <title id="loading-aria">Loading...</title>
        <rect x="0" y="0" width="100%" height="100%" clipPath="url(#clip-path)" style={{ fill: 'url(#fill)' }}></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="20" y="4" rx="1" ry="1" width="160" height="90" />
            <rect x="192" y="5" rx="5" ry="5" width="181" height="4" />
            <rect x="192" y="10" rx="5" ry="5" width="171" height="4" />
            <rect x="192" y="20" rx="5" ry="5" width="60" height="3" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0%" stopColor="#cccccc">
              <animate attributeName="offset" values="-2; 1" keyTimes="0; 1" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="50%" stopColor="#dddddd">
              <animate attributeName="offset" values="-1.5; 1.5" keyTimes="0; 1" dur="2s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#cccccc">
              <animate attributeName="offset" values="-1; 2" keyTimes="0; 1" dur="2s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </article>
  )
}
