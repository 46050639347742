import { Moment } from 'moment'

export enum STATUS_ID {
  RECIBIDA = 1,
  RECHAZADA_SG = 2,
  CANCELADA_CLIENTE = 3,
  EN_CONTRAPROPUESTA = 4,
  EN_DIAGNOSTICO = 5,
  DIAGNOSTICO_FINALIZADO = 6,
  ACEPTADA_CP_PRC = 7,
  ACEPTADA_VENTA_EXPRESS = 8,
  CONTRAPROPUESTA_RECHAZADA_USER = 9,
  ACEPTADA_PRC = 10,
  CONTRAPROPUESTA_ACEPTADA_VE = 11,
  VENDIDO = 12,
  CANCELADA = 13
}

export enum StatusOptions {
  RECIBIDA = 'Recibida',
  RECHAZADA_SG = 'Rechazada SG',
  CANCELADA_CLIENTE = 'Cancelada Cliente',
  EN_CONTRAPROPUESTA = 'En Contrapropuesta',
  EN_DIAGNOSTICO = 'En Diagnostico',
  DIAGNOSTICO_FINALIZADO = 'Diagnostico Finalizado',
  ACEPTADA_CP_PRC = 'Aceptada CP PRC',
  ACEPTADA_VENTA_EXPRESS = 'Aceptada Venta Express',
  CONTRAPROPUESTA_RECHAZADA_USER = 'Contrapropuesta Rechazada User',
  CONTRAPROPUESTA_ACEPTADA_VE = 'Contrapropuesta aceptada VE',
  VENDIDO = 'Vendido',
  ACEPTADA_PRC = 'Aceptada PRC',
  CANCELADA = 'Cancelada SG'
}

// always recived price in cents, for see in UI in cash convert thats numbers and then for send number convert to cents again
export const convertPrice = (price: number, type: 'cash' | 'cents' | undefined = 'cash') => {
  return type === 'cash' ? price / 100 : price * 100
}

export type SearchableParams = {
  id?: number
  userId?: number
  requestId?: number
}

export type SalesRequestTypes =
  | 'sales_requests'
  | 'accepted_sales_requests'
  | 'rejected_sales_requests'
  | 'pending_sales_requests'

export type ListParams = SearchableParams & {
  status?: number
  limit: number
  offset: number
  showDemoUsers?: boolean
  createdAtFrom?: Moment
  createdAtTo?: Moment
  updatedAtFrom?: Moment
  updatedAtTo?: Moment
  type?: SalesRequestTypes
  requestId?: number
}
