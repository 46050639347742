import { useState } from 'react'

export const useErrors = () => {
  const [errors, setErrors] = useState([])

  const removeError = (field) => {
    if (errors.hasOwnProperty(field)) {
      const newErrors = errors
      delete newErrors[field]
      setErrors({ ...newErrors })
    }
  }

  const addErrors = (errors) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        ...errors
      }
    })
  }

  const showError = (field) => {
    if (errors.hasOwnProperty(field)) {
      return errors[field]
    }
  }

  const existError = (field) => errors.hasOwnProperty(field)

  return {
    errors,
    addErrors,
    removeError,
    showError,
    existError,
    cleanAllErrors: () => setErrors([])
  }
}
