import React, { useEffect } from 'react'
import Component from '../component/cancel'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actionsCancel from '../actions/cancel'

const Container = () => {
  const cancelState = useSelector((state) => state.Removals.cancelOld)

  const dispatch = useDispatch()
  const actions = bindActionCreators(actionsCancel, dispatch)
  useEffect(() => {
    actions.getReasons()
  }, [])

  const props = {
    cancelState,
    actions
  }

  return <Component {...props} />
}

export default Container
