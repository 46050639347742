import React, { useEffect } from 'react'
import Component from '../component/DepositHistory'
import actions from '../actions/DepositHistory'
import { useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

interface ParamTypes {
  depositId: number
}

const DepositHistoryContainer = () => {
  const dispatch = useDispatch()
  const { audits } = useSelector((state) => state.Deposits.history)
  const { getAudits } = bindActionCreators(actions, dispatch)
  const useParsedParams = (): ParamTypes => {
    const { depositId } = useParams<{ depositId: string }>()

    return { depositId: parseInt(depositId) }
  }

  const { depositId } = useParsedParams()

  useEffect(() => {
    getAudits({ depositId })
  }, [depositId])

  const props = {
    audits
  }

  return <Component {...props} />
}

export default DepositHistoryContainer
