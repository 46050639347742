import React from 'react'
import { Modal } from 'antd'
import styles from './DeleteProduct.module.scss'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'

export type DeleteProductProps = {
  currentProduct?: FormattedProduct
  deletingProduct: boolean
  deleteModalVisible: boolean
  disabledDeleteButton: boolean
  handleCancelDeleteButton: () => void
  handleDeleteProduct: () => void
}

const DeleteProductModal = ({
  currentProduct,
  deletingProduct,
  deleteModalVisible,
  disabledDeleteButton,
  handleCancelDeleteButton,
  handleDeleteProduct
}: DeleteProductProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={handleCancelDeleteButton}
      title={'Eliminar producto'}
      confirmLoading={deletingProduct}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={handleDeleteProduct}
      okButtonProps={{ disabled: disabledDeleteButton }}
      destroyOnClose>
      <InfoCard id={currentProduct?.id} description={currentProduct?.description} />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar este producto?</h4>
    </Modal>
  )
}

export default DeleteProductModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; description?: string }> = ({ id, description }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos del producto:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={description} label={'Nombre completo'} />
    </div>
  </header>
)
