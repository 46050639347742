import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { ListParams } from './list'
import snakecaseKeys from 'snakecase-keys'
import { formatDates } from '../../utils'

export function sendTripsCSV(params: ListParams): Promise<void> {
  const {
    tripStatus,
    realStartDateTo,
    realStartDateFrom,
    realEndDateTo,
    realEndDateFrom,
    scheduledStartDateFrom,
    scheduledStartDateTo,
    scheduledEndDateFrom,
    scheduledEndDateTo,
    createdAtFrom,
    createdAtTo,
    limit,
    offset,
    sort,
    order,
    id
  } = params
  const snakeParams = snakecaseKeys({
    tripStatus,
    limit,
    offset,
    sort,
    order,
    id,
    ...formatDates({
      dates: {
        startDateTo: realStartDateTo,
        startDateFrom: realStartDateFrom,
        endDateTo: realEndDateTo,
        endDateFrom: realEndDateFrom,
        scheduledStartFrom: scheduledStartDateFrom,
        scheduledStartTo: scheduledStartDateTo,
        scheduledEndFrom: scheduledEndDateFrom,
        scheduledEndTo: scheduledEndDateTo,
        createdAtFrom,
        createdAtTo
      }
    })
  })
  return apiPrivate
    .get(`${URL_BASE_TRIPS}/trips/report`, { params: snakeParams })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
