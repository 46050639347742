import clsx from 'clsx'
import React from 'react'
import infoBannerStyles from './infoBanner.module.scss'

const InfoBanner: React.FC<{ title?: string }> = ({ children, title }) => {
  return (
    <div className={infoBannerStyles.infoBanner}>
      {title && <h2>{title}</h2>}
      <section>{children}</section>
    </div>
  )
}

export default InfoBanner

export const Item: React.FC<{ heading: string; value: string | number; onValueClick?: () => void }> = ({
  heading,
  value,
  children,
  onValueClick
}) => {
  return (
    <div className={infoBannerStyles.item}>
      <span className={infoBannerStyles.itemHeading}>{heading}</span>
      <span
        onClick={onValueClick}
        className={clsx(infoBannerStyles.itemValue, onValueClick && infoBannerStyles.clickable)}>
        {value}
        {children && <span className={infoBannerStyles.itemChildren}>{children}</span>}
      </span>
    </div>
  )
}
