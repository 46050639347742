import React from 'react'

const deposit: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M1 20V9.89412C1 5.00235 5.05 1 10 1C14.95 1 19 5.00235 19 9.89412V20.0588H1"
        stroke="#FFFFFF"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.17651 20.0588V10.7941C4.17651 10.0662 4.70063 9.47058 5.34122 9.47058H14.6589C15.2995 9.47058 15.8236 10.0662 15.8236 10.7941V20.0588"
        stroke="#FFFFFF"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5C10.5523 5 11 4.55228 11 4C11 3.44772 10.5523 3 10 3C9.44772 3 9 3.44772 9 4C9 4.55228 9.44772 5 10 5Z"
        stroke="#FFFFFF"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.17651 12.4353H15.8236"
        stroke="#FFFFFF"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.17651 14.9129H15.8236"
        stroke="#FFFFFF"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.17651 17.5176H15.8236"
        stroke="#FFFFFF"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default deposit
