import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from './actions'
import Component from './component'

const Container = ({ isEdit = false }) => {
  const dispatch = useDispatch()
  const cbuActions = bindActionCreators(actions, dispatch)
  const cbuReducer = useSelector((state) => state.Components.cbu)

  const props = {
    cbuActions,
    cbuReducer,
    isEdit
  }

  return <Component {...props} />
}

export default Container
