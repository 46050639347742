/* eslint-disable camelcase */
import axios, { AxiosError } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

interface APIError {
  message: string
  error: string
  status: number
  cause: string | null
}

interface CancelParams {
  operationId: number
  cancelReasonId: number
}

export function cancel({ operationId, cancelReasonId: cancelReason }: CancelParams): Promise<void> {
  const params = snakecaseKeys({ cancelReason })

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/operations/cancel/${operationId}`, null, { params: params })
    .then(() => {})
    .catch((error: AxiosError<APIError> | Error) => {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data.message
        throw errorMessage
      } else throw error
    })
}
