import React from 'react'
import { useSelector } from 'react-redux'
import Component from '../components/ObjectsData'

const Container = () => {
  const userModalState = useSelector((state) => state.NewRemoval.userSearch)
  const objectsState = useSelector((state) => state.NewRemoval.objects)
  const paymentMPState = useSelector((state) => state.NewRemoval.paymentMp)
  const paymentCBUState = useSelector((state) => state.NewRemoval.paymentCBU)

  const props = {
    paymentMPState,
    paymentCBUState,
    userModalState,
    objectsState
  }

  return <Component {...props} />
}

export default Container
