import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserExclusionModal, { UserExclusionModalComponentProps } from '../components/excludeUserModal'
import UserExclusionActionCreator from '../actionCreators/userExclusionModals'
import { bindActionCreators } from 'redux'
import { BaseOption } from '../../../components/Select/Select'
import { getPeriods } from '../utils'
import EnableUserModal, { EnableUserModalProps } from '../components/EnableUserModal'

const UserExclusionModalContainer = () => {
  const dispatch = useDispatch()

  const userExclusionActions = bindActionCreators(UserExclusionActionCreator, dispatch)
  const userExclusionState = useSelector((state) => state.BillingV2.userExclusionModal)

  const {
    openModal,
    periodFrom,
    periodTo,
    specificPeriodFrom,
    specificPeriodTo,
    selectedOption,
    selectedReason,
    loadingUserExclusionReasons,
    loading,
    user,
    enablingUser,
    openEnableModal
  } = userExclusionState

  const {
    getUserExclusionReasons,
    setModalOpen,
    setPeriodTo,
    setSpecificPeriodFrom,
    setSpecificPeriodTo,
    setSelectedOption,
    setSelectedReason,
    userExclusion,
    setEnableModalOpen,
    enableUser
  } = userExclusionActions

  useEffect(() => {
    getUserExclusionReasons()
  }, [])

  const reasonOptions: BaseOption<number>[] = userExclusionState.userExclusionReasons.map((reason) => ({
    value: reason.id,
    label: reason.description
  }))

  const handleUserExclusion = () => {
    const periods = getPeriods({
      periodFrom,
      periodTo,
      specificPeriodFrom,
      specificPeriodTo,
      periodOption: selectedOption
    })
    if (user && selectedReason?.value) {
      userExclusion(user.id, selectedReason.value, periods.periodFrom, periods.periodTo, periods.withDays)
    }
  }

  const handleEnableUser = () => {
    enableUser({ userId: user!.id })
  }

  const props: UserExclusionModalComponentProps = {
    handleClose: () => setModalOpen(false),
    handleUserExclusion,
    onSelectReason: setSelectedReason,
    onOptionChange: (e) => setSelectedOption(e.target.value),
    setPeriodTo,
    setSpecificPeriodFrom,
    setSpecificPeriodTo,
    reasonOptions,
    selectedOption,
    selectedReason,
    periodFrom,
    periodTo,
    specificPeriodFrom,
    specificPeriodTo,
    openModal,
    loading,
    loadingUserExclusionReasons,
    user
  }

  const enableUserModalProps: EnableUserModalProps = {
    userInfo: user,
    enablingUser,
    openEnableModal,
    setDeleteModalVisibility: setEnableModalOpen,
    handleEnableUser
  }

  return (
    <>
      <UserExclusionModal {...props} />
      <EnableUserModal {...enableUserModalProps} />
    </>
  )
}

export default UserExclusionModalContainer
