import { combineReducers } from 'redux'
import OperationDatesReducer from './operationDatesReducer'
import OperationDaysReducer from './operationDaysReducer'
import OperationCalendarReducer from './common'

export default combineReducers({
  Dates: OperationDatesReducer,
  Days: OperationDaysReducer,
  Common: OperationCalendarReducer
})
