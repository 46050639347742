import { Reducer } from 'redux'

import {
  ChangeRoleTypes,
  DeleteInvitationTypes,
  DeleteSatelliteUserTypes,
  FormTypes,
  GetCorporateRolesTypes,
  GetGuestUsersTypes,
  ModalDeleteInviteTypes,
  ModalDeleteTypes,
  ModalEditTypes,
  ModalInviteTypes,
  SendInvitationTypes,
  UserABMActions,
  UserABMTypes,
  VerifyEmailTypes
} from '../types/userABM'

export const PAGE_SIZE = 10

const initialState: UserABMTypes = {
  corporateRoles: [],
  email: '',
  roleId: 0,
  isEdit: false,
  guestUsers: [],
  isAddModalOpen: false,
  isDeleteInviteModalOpen: false,
  isDeleteModalOpen: false,
  selectedUser: null,
  isLoading: false,
  isLoadingGuests: false,
  selectedGuestUser: null,
  error: '',
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  searchFilters: [{ key: 'id', text: '' }],
  filters: {
    id: undefined
  }
}

const UserABMReducer: Reducer<UserABMTypes, UserABMActions> = (
  state: UserABMTypes = initialState,
  action
): UserABMTypes => {
  switch (action.type) {
    case GetCorporateRolesTypes.GET_CORPORATE_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GetCorporateRolesTypes.GET_CORPORATE_ROLES_SUCCESS:
      return {
        ...state,
        corporateRoles: action.payload.coporateRoles,
        isLoading: false
      }
    case GetCorporateRolesTypes.GET_CORPORATE_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case SendInvitationTypes.SEND_INVITATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case SendInvitationTypes.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case SendInvitationTypes.SEND_INVITATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case ModalInviteTypes.SET_MODAL_INVITE_OPEN: {
      const { isAddModalOpen } = action.payload
      return isAddModalOpen
        ? {
            ...state,
            isAddModalOpen,
            error: ''
          }
        : {
            ...state,
            isAddModalOpen: false,
            isLoading: false,
            error: ''
          }
    }
    case VerifyEmailTypes.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case VerifyEmailTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case VerifyEmailTypes.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GetGuestUsersTypes.GET_GUEST_USERS_REQUEST:
      return {
        ...state,
        isLoadingGuests: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GetGuestUsersTypes.GET_GUEST_USERS_SUCCESS:
      return {
        ...state,
        isLoadingGuests: false,
        guestUsers: action.payload.guestUsers,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetGuestUsersTypes.GET_GUEST_USERS_FAILURE:
      return {
        ...state,
        isLoadingGuests: false,
        error: action.payload.error
      }
    case DeleteInvitationTypes.DELETE_INVITATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case DeleteInvitationTypes.DELETE_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleteInviteModalOpen: false,
        selectedGuestUser: null
      }
    case DeleteInvitationTypes.DELETE_INVITATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case ModalDeleteInviteTypes.SET_MODAL_DELETE_INVITE_OPEN: {
      const { isDeleteInviteModalOpen, selectedGuestUser } = action.payload
      return isDeleteInviteModalOpen
        ? {
            ...state,
            isDeleteInviteModalOpen,
            selectedGuestUser,
            error: ''
          }
        : {
            ...state,
            isDeleteInviteModalOpen: false,
            isLoading: false,
            selectedGuestUser: null,
            error: ''
          }
    }
    case FormTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload.email
      }
    case FormTypes.SET_ROLE_ID:
      return {
        ...state,
        roleId: action.payload.roleId
      }
    case ChangeRoleTypes.CHANGE_ROLE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ChangeRoleTypes.CHANGE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case ChangeRoleTypes.CHANGE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case ModalDeleteTypes.SET_MODAL_DELETE_OPEN: {
      const { isDeleteModalOpen, selectedUser } = action.payload
      return isDeleteModalOpen
        ? {
            ...state,
            isDeleteModalOpen,
            selectedUser,
            error: ''
          }
        : {
            ...state,
            isDeleteModalOpen: false,
            isLoading: false,
            selectedUser: null,
            error: ''
          }
    }
    case DeleteSatelliteUserTypes.DELETE_SATELLITE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case DeleteSatelliteUserTypes.DELETE_SATELLITE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case DeleteSatelliteUserTypes.DELETE_SATELLITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case ModalEditTypes.SET_MODAL_EDIT_OPEN: {
      const { isAddModalOpen, selectedUser } = action.payload
      return isAddModalOpen
        ? {
            ...state,
            isAddModalOpen,
            isEdit: true,
            selectedUser,
            email: selectedUser.email,
            roleId: selectedUser.role.id,
            error: ''
          }
        : {
            ...state,
            isAddModalOpen,
            selectedUser: null,
            email: '',
            roleId: 0,
            error: '',
            isEdit: false
          }
    }
    default:
      return state
  }
}

export default UserABMReducer
