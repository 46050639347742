import { MetricsLabels } from './../../../projectApi/TransactionHandler/Transactions/Metrics/list'
import { Reducer } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { transactionCanBeSelected } from '../components/TransactionsTable'
import {
  CLEAR_SELECTED_TRANSACTIONS,
  CLEAR_STATE,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_METRICS_FAILURE,
  GET_TRANSACTIONS_METRICS_REQUEST,
  GET_TRANSACTIONS_METRICS_SUCCESS,
  GET_TRANSACTIONS_REPORT_FAILURE,
  GET_TRANSACTIONS_REPORT_REQUEST,
  GET_TRANSACTIONS_REPORT_SUCCESS,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  SET_METRICS_WITH_CONFLICT,
  TOGGLE_ALL_TRANSACTIONS,
  TOGGLE_SELECTED_TRANSACTION,
  TransactionsTableAction,
  TransactionsTableDateRangeKey,
  TransactionsTableSearchKey,
  TransactionsTableState
} from '../types/TransactionsTable'

const initialState: TransactionsTableState = {
  transactions: [],
  loadingTransactions: false,
  loadingReport: false,
  selectedTransactions: [],
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  categoryFilter: {
    transactionType: [],
    status: [],
    operationType: []
  },
  searchFilters: [
    { key: TransactionsTableSearchKey.CLIENT_ID, text: '' },
    { key: TransactionsTableSearchKey.ID_TX, text: '' },
    { key: TransactionsTableSearchKey.ID_OP, text: '' }
  ],
  dateRangeFilters: [
    { key: TransactionsTableDateRangeKey.CREATION_DATE, startDate: null, endDate: null },
    { key: TransactionsTableDateRangeKey.CONFIRMATION_DATE, startDate: null, endDate: null },
    { key: TransactionsTableDateRangeKey.RESERVATION_DATE, startDate: null, endDate: null }
  ],
  sort: {
    direction: SortDirection.DESC,
    field: 'ID'
  },
  conflict: false,
  error: '',
  metrics: {
    transactionsTripToday: {
      title: MetricsLabels.TODAY,
      totalObjects: 0,
      totalObjectsM3: 0,
      totalTransactions: 0,
      totalTransactionsWithTrip: 0
    },
    transactionsTripTomorrow: {
      title: MetricsLabels.TOMORROW,
      totalObjects: 0,
      totalObjectsM3: 0,
      totalTransactions: 0,
      totalTransactionsWithTrip: 0
    },
    transactionsWithConflict: {
      title: MetricsLabels.WITH_CONFLICT,
      totalObjects: 0,
      totalObjectsM3: 0,
      totalTransactions: 0,
      totalTransactionsWithConflicts: 0
    },
    transactionWithoutAssign: {
      title: MetricsLabels.WITHOUT_ASSIGN,
      totalObjects: 0,
      totalObjectsM3: 0,
      totalTransactions: 0
    }
  },
  loadingMetrics: false
}

const TransactionsTableReducer: Reducer<TransactionsTableState, TransactionsTableAction> = (
  state = initialState,
  action
): TransactionsTableState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case SET_METRICS_WITH_CONFLICT:
      return {
        ...state,
        conflict: action.payload.conflict
      }
    case GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loadingTransactions: !action.payload.silentLoading,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        dateRangeFilters: action.payload.dateRangeFilters || initialState.dateRangeFilters,
        categoryFilter: action.payload.categoryFilter || initialState.categoryFilter,
        sort: action.payload.sort || initialState.sort,
        selectedTransactions: action.payload.resetSelectedTransactions
          ? initialState.selectedTransactions
          : state.selectedTransactions,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        conflict: action.payload.conflict ?? initialState.conflict
      }
    case GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loadingTransactions: false,
        transactions: action.payload.transactions,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        },
        error: initialState.error
      }
    }
    case GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loadingTransactions: false,
        error: action.payload.error
      }
    case CLEAR_SELECTED_TRANSACTIONS:
      return { ...state, selectedTransactions: initialState.selectedTransactions }
    case TOGGLE_SELECTED_TRANSACTION: {
      const filtered = state.selectedTransactions.filter((tx) => tx.id !== action.payload.transaction.id)
      if (state.selectedTransactions.length === filtered.length) filtered.push(action.payload.transaction)
      return {
        ...state,
        selectedTransactions: filtered
      }
    }
    case TOGGLE_ALL_TRANSACTIONS: {
      const allSelectableTransactionsAreSelectedOrDisabled = state.transactions.every(
        (tx) => state.selectedTransactions.find((stx) => stx.id === tx.id) || !transactionCanBeSelected(tx)
      )

      return {
        ...state,
        selectedTransactions: allSelectableTransactionsAreSelectedOrDisabled
          ? initialState.selectedTransactions
          : state.transactions.filter((tx) => transactionCanBeSelected(tx))
      }
    }

    case GET_TRANSACTIONS_METRICS_REQUEST:
      return {
        ...state,
        loadingMetrics: !action.payload.silentLoading
      }

    case GET_TRANSACTIONS_METRICS_SUCCESS:
      return {
        ...state,
        metrics: action.payload.metrics,
        loadingMetrics: false
      }

    case GET_TRANSACTIONS_METRICS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case GET_TRANSACTIONS_REPORT_REQUEST:
      return {
        ...state,
        loadingReport: true
      }
    case GET_TRANSACTIONS_REPORT_SUCCESS:
    case GET_TRANSACTIONS_REPORT_FAILURE:
      return {
        ...state,
        loadingReport: false
      }
    default:
      return state
  }
}

export default TransactionsTableReducer
