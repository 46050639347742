import React from 'react'
import styles from './DepositsMetrics.module.scss'
import { DepositMetric } from '../types/metrics'
import StatusCard from '../../../components/StatusCard'
import { StatusCardSkeleton, StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { useSelectStatusCard } from '../../../components/StatusCard/hook'
import { formatNumber } from '../../../utils/formatNumber'

export type DepositsMetricsProps = {
  metrics: DepositMetric[]
  fetchingMetrics: boolean
}

const DepositsMetrics: React.FC<DepositsMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const [DepositsMetrics] = useSelectStatusCard<DepositMetric>({
    list: metrics,
    idListKey: 'id'
  })

  return (
    <div className={styles.statusContainer}>
      {fetchingMetrics ? (
        <>
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-1'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-2'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-3'} loadingColor="#5D58F7" />
        </>
      ) : (
        DepositsMetrics.map((metric) => {
          return (
            <StatusCard
              key={metric.id}
              type={mapOfMetricTypes[metric.id]}
              customClassname={`${styles.warningCard} ${styles[mapOfMetricsColor[metric.id]]}`}
              titleCard={metric.metric}
              total={formatNumber(metric.quantity, 0, true)}
              clicked={metric.clicked}
              description={
                metric.metric === mapOfMetrics.programmedQuantity ? <span>Programada + Programada (M)</span> : undefined
              }
              useOnlyCustomClass={true}
            />
          )
        })
      )}
    </div>
  )
}

export default DepositsMetrics

const mapOfMetricTypes: { [key: string]: StatusCardTypes } = {
  programmedQuantity: 'info',
  inTripQuantity: 'info',
  validatedQuantity: 'info'
}

export const mapOfMetrics: { [key: string]: string } = {
  programmedQuantity: 'Ingresos programados',
  inTripQuantity: 'Ingresos en viaje',
  validatedQuantity: 'Ingresos validados'
}

export const mapOfMetricsColor: { [key: string]: string } = {
  programmedQuantity: 'spaceguru',
  inTripQuantity: 'orange',
  validatedQuantity: 'green'
}
