import { API } from '../../../projectApi'
import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  OPEN_MODAL_PAYMENT,
  OpenModalPayment,
  PAY_BILLING_FAILURE,
  PAY_BILLING_REQUEST,
  PAY_BILLING_SUCCESS,
  PayBillingRequest,
  PayBillingSuccess
} from '../types/paymentModal'

export const PaymentModalActionCreator = {
  payBilling:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: PayBillingRequest = {
        type: PAY_BILLING_REQUEST
      }
      dispatch(request)

      try {
        await API.TransactionHandler.Billings.payBilling({ billingId })
        const success: PayBillingSuccess = {
          type: PAY_BILLING_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.Billing.BILLING_PAID)
      } catch (err) {
        handleErrorsWithAction(err, PAY_BILLING_FAILURE, dispatch)
      }
    },

  openPaymentModal: ({
    billing,
    modalOpen: openModal
  }: {
    modalOpen: boolean
    billing?: Billing
  }): OpenModalPayment => ({
    type: OPEN_MODAL_PAYMENT,
    payload: { billing, modalOpen: openModal }
  })
}

export default PaymentModalActionCreator
