import React, { useMemo } from 'react'
import styles from './ProspectData.module.scss'
import { MailLine, PhoneLine, Source, UserLine } from '../../../icons'
import ColorBadge from '../../../components/ColorBadge'
import { UserInfoToDisplay } from '../utils/getUserInfoToDisplay'

type ProspectDataProps = {
  config: any
  loadingConfig: boolean
  userInfo: UserInfoToDisplay
  actualM3Storage: number
}

const ProspectData = (props: ProspectDataProps) => {
  const { userInfo } = props

  const prospectData = useMemo(() => {
    return [
      {
        key: 1,
        icon: <UserLine />,
        label: userInfo.name
      },
      {
        key: 2,
        icon: <MailLine />,
        label: userInfo.email
      },
      {
        key: 3,
        icon: <PhoneLine />,
        label: userInfo.phone
      },
      {
        key: 4,
        icon: <Source />,
        label: userInfo.source
      }
    ]
  }, [userInfo])

  return (
    <div className={styles.container}>
      <div className={styles.prospectDataContainer}>
        {prospectData.map(({ icon, label, key }) => (
          <div className={styles.prospectDataElement} key={key}>
            <span className={styles.icon}>{icon}</span> {label}
          </div>
        ))}
      </div>
      <div>
        <ColorBadge backgroundColor="#DDEEFD" borderColor="" className={styles.leadBadge}>
          Lead
        </ColorBadge>
      </div>
    </div>
  )
}

export default ProspectData
