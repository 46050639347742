import { Reducer } from 'redux'
import { GetAssociatedUsersTypes, UserTableActions, UsersTableTypes } from '../types/usersTable'

export const PAGE_SIZE = 10

const initialState: UsersTableTypes = {
  associatedUsers: [],
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  searchFilters: [{ key: 'id', text: '' }],
  filters: {
    id: undefined
  },
  isLoading: false,
  error: ''
}

const UsersTableReducer: Reducer<UsersTableTypes, UserTableActions> = (
  state: UsersTableTypes = initialState,
  action
): UsersTableTypes => {
  switch (action.type) {
    case GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        associatedUsers: action.payload.associatedUsers,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetAssociatedUsersTypes.GET_ASSOCIATED_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default UsersTableReducer
