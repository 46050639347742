import { Reducer } from 'redux'
import {
  ABMServiceTypes,
  CreateServiceTypes,
  DeleteServiceTypes,
  GetDepositTypes,
  GetDepositsByIdTypes,
  SELECT_SERVICE,
  SET_SELECTED_DEPOSIT,
  ServiceModalState,
  ServicesModalAction,
  UpdateServiceTypes
} from '../types/serviceABM'
import { ServiceOperationType, ServiceTypeId } from '../../../projectApi/TransactionHandler/ServiceType/common'

const initialState: ServiceModalState = {
  open: false,
  deposits: [],
  selectedDeposit: [],
  deleteModalVisible: false,
  isLoadingDeposits: false,
  isCreatingService: false,
  isDeletingService: false,
  isSaving: false,
  isCreate: false,
  error: '',
  selectedService: {
    id: '',
    multiplier: 0,
    type: '',
    operationType: ServiceOperationType.IP,
    deposits: [],
    solution: ServiceTypeId.ITEMS,
    solutionId: 0
  }
}

const ServiceABMReducer: Reducer<ServiceModalState, ServicesModalAction> = (
  state = initialState,
  action
): ServiceModalState => {
  switch (action.type) {
    case SELECT_SERVICE:
      return {
        ...state,
        open: true,
        isCreate: false,
        selectedService: {
          id: action.payload.id,
          multiplier: action.payload.multiplier,
          operationType: action.payload.operationType,
          type: action.payload.type,
          deposits: action.payload.deposits,
          solution: action.payload.solution,
          solutionId: action.payload.solutionId
        }
      }
    case ABMServiceTypes.SET_OPEN:
      return {
        ...state,
        open: action.payload.open,
        isCreate: action.payload.isCreate
      }
    case ABMServiceTypes.CANCEL:
      return {
        ...state,
        open: false,
        isCreate: false,
        selectedService: {
          id: initialState.selectedService.id,
          multiplier: initialState.selectedService.multiplier,
          operationType: initialState.selectedService.operationType,
          type: initialState.selectedService.type,
          deposits: initialState.selectedService.deposits,
          solution: initialState.selectedService.solution,
          solutionId: initialState.selectedService.solutionId
        },
        selectedDeposit: initialState.selectedDeposit
      }
    case ABMServiceTypes.SET_MULTIPLIER:
      return {
        ...state,
        selectedService: {
          ...state.selectedService,
          multiplier: action.payload.multiplier
        }
      }
    case ABMServiceTypes.SET_TYPE:
      return {
        ...state,
        selectedService: {
          ...state.selectedService,
          type: action.payload.type
        }
      }
    case ABMServiceTypes.SET_ID:
      return {
        ...state,
        selectedService: {
          ...state.selectedService,
          id: action.payload.id
        }
      }
    case UpdateServiceTypes.UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: ''
      }
    case UpdateServiceTypes.UPDATE_SERVICE_SUCCESS:
      return {
        ...initialState
      }
    case UpdateServiceTypes.UPDATE_SERVICE_FAILURE:
      return { ...state, isSaving: false, error: action.payload.error }
    case GetDepositTypes.GET_DEPOSITS_REQUEST:
      return {
        ...state,
        isLoadingDeposits: true
      }
    case GetDepositTypes.GET_DEPOSITS_SUCCESS:
      return {
        ...state,
        deposits: action.payload.deposits,
        isLoadingDeposits: false
      }
    case GetDepositTypes.GET_DEPOSITS_FAILURE:
      return {
        ...state,
        isLoadingDeposits: false
      }
    case GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingDeposits: true
      }
    case GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_SUCCESS:
      return {
        ...state,
        selectedDeposit: action.payload.selectedDeposits,
        isLoadingDeposits: false
      }
    case GetDepositsByIdTypes.GET_DEPOSITS_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingDeposits: false
      }
    case SET_SELECTED_DEPOSIT: {
      return {
        ...state,
        selectedDeposit: action.payload.selectedDeposit
      }
    }
    case CreateServiceTypes.CREATE_SERVICE_REQUEST:
      return {
        ...state,
        isLoadingDeposits: true
      }
    case CreateServiceTypes.CREATE_SERVICE_SUCCESS:
      return {
        ...initialState
      }
    case CreateServiceTypes.CREATE_SERVICE_FAILURE:
      return {
        ...initialState
      }
    case DeleteServiceTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisibility, service } = action.payload

      const data = deleteModalVisibility
        ? {
            ...state,
            deleteModalVisible: action.payload.deleteModalVisibility,
            selectedService: service
          }
        : initialState

      return data
    }
    default:
      return state
  }
}

export default ServiceABMReducer
