import React, { useMemo } from 'react'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import { Col, Input, Modal, Row } from 'antd'
import InputGroup from '../../../components/InputGroup'
import Select from '../../../components/Select'
import { CreateServiceType, SelectedDeposit } from '../types/serviceABM'
import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'
import styles from './abmService.module.scss'
import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'

export interface ServiceModalProps {
  open: boolean
  error: string
  isSaving: boolean
  isCreate: boolean
  deposits: Deposit[]
  isLoadingDeposits: boolean
  selectedDeposit: SelectedDeposit[]
  selectedService: ServiceSearch
  onCancel: () => void
  setMultiplier: (multiplier: number) => void
  updateService: ({
    id,
    multiplier,
    deposits,
    solutionId
  }: {
    id: string
    multiplier: number
    deposits: number[]
    solutionId: number
  }) => void
  setSelectedDeposit: (args: SelectedDeposit[]) => void
  createServiceType: (args: CreateServiceType) => void
  setId: (id: string) => void
  setType: (type: string) => void
}

const ServiceModal: React.FC<ServiceModalProps> = ({
  open,
  error,
  isSaving,
  onCancel,
  setMultiplier,
  updateService,
  isCreate,
  deposits,
  isLoadingDeposits,
  selectedDeposit,
  setSelectedDeposit,
  createServiceType,
  setId,
  setType,
  selectedService
}) => {
  const { id, type, operationType, multiplier, solution, solutionId } = selectedService
  const depositsOptions: SelectedDeposit[] = useMemo(() => {
    return deposits.map((deposit) => ({
      label: `${deposit.id} - ${deposit.name} - ${
        deposit.owner.id === 0 ? 'SpaceGuru' : deposit.owner.name + ' ' + deposit.owner.lastName
      }`,
      value: deposit.id
    }))
  }, [deposits])

  const loading = isLoadingDeposits

  const handleOk = () => {
    const depositsId = selectedDeposit.map((deposit) => deposit.value)
    return isCreate
      ? createServiceType({
          deposits: depositsId,
          displayOrder: 1,
          selectedService
        })
      : updateService({ id, multiplier, deposits: depositsId, solutionId })
  }

  const isReadyToSubmitCreate = loading || selectedDeposit.length <= 0 || !selectedService.id || !selectedService.type
  const isReadyToSubmitEdit = loading || selectedDeposit.length <= 0 || !selectedService.id || !selectedService.type

  return (
    <Modal
      title={isCreate ? 'Crear Tipo de Servicio' : 'Editar Tipo de Servicio'}
      visible={open}
      onCancel={() => onCancel()}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      onOk={() => handleOk()}
      okButtonProps={{
        loading,
        disabled: isCreate ? isReadyToSubmitCreate : isReadyToSubmitEdit,
        className: styles.modalButtonOk
      }}
      cancelText="Cancelar"
      okText={isCreate ? 'Crear' : 'Editar'}
      confirmLoading={isSaving}
      destroyOnClose>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col sm={24} xs={24}>
          <InputGroup title="Tipo de servicio">
            <Row gutter={[16, 16]}>
              <Col sm={12} xs={24}>
                <InputFieldWithError
                  label="Tipo de operacion"
                  value={operationType}
                  disabled={true}
                  onChange={() => {}}
                />
              </Col>
              <Col sm={12} xs={24}>
                <InputFieldWithError label="Solución" value={solution} disabled={true} onChange={() => {}} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col sm={12} xs={24}>
                <InputFieldWithError
                  label="ID"
                  value={id}
                  disabled={!isCreate}
                  onChange={(e) => setId(e.target.value)}
                />
              </Col>
              <Col sm={12} xs={24}>
                <InputFieldWithError
                  label="Tipo"
                  value={type}
                  disabled={!isCreate}
                  onChange={(e) => setType(e.target.value)}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col sm={12} xs={24}>
                <p className={styles.title}>Multiplicador</p>
                <Input
                  value={multiplier.toString()}
                  type="number"
                  min={0}
                  onChange={(e) => setMultiplier(Number(e.target.value))}
                />
              </Col>
            </Row>
            <Row>
              <p className={styles.title}>
                Depósito <span className={styles.asterisk}>*</span>{' '}
              </p>
              <Select
                onSelect={(option) => setSelectedDeposit([...selectedDeposit, option])}
                onDelete={(option) => {
                  setSelectedDeposit(selectedDeposit.filter((s) => s.value !== option.value))
                }}
                selected={selectedDeposit}
                multiple
                options={depositsOptions}
                placeholder={'Seleccione depósitos'}
                disabled={isLoadingDeposits}
              />
            </Row>
          </InputGroup>
        </Col>
      </Row>
      <div className="container">
        <p style={{ color: 'red', textAlign: 'right' }}>{error}</p>
      </div>
    </Modal>
  )
}

export default ServiceModal
