import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { URL_BASE_USERS } from '../../../endpoints'

type APIResponse = {
  quantity_users: number
  quantity_accepted_terms: number
  quantity_not_accepted_terms: number
  active_users: number
  not_active_users: number
}
type Response = CamelCasedPropertiesDeep<APIResponse>

export interface MetricsParams {
  date_from?: string
  date_to?: string
  accepted?: string
  email?: string
  has_card?: boolean
  phone?: string
  role?: number
  has_cbu?: boolean
}

type Params = Omit<MetricsParams, 'role'> & {
  role_id?: number
}

export function metrics(newParams: MetricsParams): Promise<Response> {
  const { role, ...rest } = newParams

  const params: Params = {
    ...rest,
    role_id: role || undefined
  }

  return apiPrivate
    .get(`${URL_BASE_USERS}/users/metrics`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
