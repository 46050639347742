import { getById } from './getById'
import { getByTransportAndService } from './getByTransportAndService'
import { AdminDepositAPI } from './Admin'
import { list } from './list'
import { create } from './create'
import { update } from './update'
import { deleteit } from './delete'
import { create as createOP } from './createOP'
import { metrics } from './metrics'

export const DepositsAPI = {
  create,
  createOP,
  getById,
  getByTransportAndService,
  Admin: AdminDepositAPI,
  list,
  delete: deleteit,
  update,
  metrics
}
