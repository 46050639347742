import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { salesRequestActionCreator } from '../actionCreators/salesRequestTable'
import { SalesRequestTable, SalesRequestTableProps } from '../components/SalesRequestTable'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import {
  Pagination,
  SalesRequestCategoryFilter,
  SalesRequestDateRangeFilter,
  SalesRequestSearchFilter,
  SalesRequestSearchKey,
  SalesRequestTableDateRangeKey
} from '../types/salesRequestTable'
import { Moment } from 'moment'
import { useHistory } from 'react-router'
import SalesActionCreator from '../actionCreators/common'
import { Envelope, MoneyIcon, SignIn, Trash } from '../../../icons'
import { SalesRequest } from '../../../projectApi/Sales/SalesRequest/list'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import {
  OBJECT_SITUATION,
  ObjectSituacionsToSell,
  SaleRequestSituationToCancel,
  SaleRequestSituationToSell
} from '../../../projectApi/ObjectAdministration/common'
import SalesRequestMetrics, { SalesRequestMetricsProps } from '../components/SalesRequestMetrics'
import { STATUS_OPTIONS } from '../utils'
import { STATUS_ID } from '../../../projectApi/Sales/SalesRequest/common'
import { NumberParam, useQueryParams } from 'use-query-params'
import { updateMultipleSearchFilters } from '../../../components/DynamicTable/queryParamsUtils'
import CancelSaleActionCreators from '../actionCreators/cancelSaleModal'
import CancelSaleModal from '../containers/CancelSaleModal'

const QueryTypes = {
  requestId: NumberParam
}

const SalesRequestContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const { getSalesRequest, getSalesRequestCSV, sendSaleRequestNotification, getMetrics } = bindActionCreators(
    salesRequestActionCreator,
    dispatch
  )
  const { getSalesStatus } = bindActionCreators(SalesActionCreator, dispatch)
  const { setOpenModal } = bindActionCreators(CancelSaleActionCreators, dispatch)
  const tableState = useSelector((state) => state.SalesRequest.table)
  const countryId = useSelector((root) => root.CountrySelector.countrySelected.code)
  const [downloadingCSV, setDownloadingCSV] = useState(false)

  const [query] = useQueryParams(QueryTypes)

  const emptyPagination = { ...tableState.pagination, page: 1 }
  const handleGetSalesRequest = (newParams: {
    pagination?: Pagination
    showDemoUsers?: boolean
    type?: string
    searchFilters?: SalesRequestSearchFilter[]
    categoryFilter?: SalesRequestCategoryFilter
    dateRangeFilters?: SalesRequestDateRangeFilter[]
  }) => {
    getSalesRequest({
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      showDemoUsers: newParams.showDemoUsers ?? tableState.showDemoUsers,
      type: newParams.type === undefined ? undefined : newParams.type || tableState?.type
    })
  }

  useEffect(() => {
    handleGetSalesRequest({
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: 'requestId', text: query.requestId?.toString() }
      ]),
      pagination: emptyPagination
    })
    getSalesStatus()
    getMetrics()
  }, [countryId])

  const handlePageChange = (newPage: number) => {
    handleGetSalesRequest({ pagination: { ...tableState.pagination, page: newPage }, type: tableState.type })
  }

  const handleSearch = (key: SalesRequestSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetSalesRequest({ searchFilters: newSearchFilters, pagination: emptyPagination, type: tableState.type })
  }

  const handleCategoryFilter = (newCategoryFilter: SalesRequestCategoryFilter) => {
    handleGetSalesRequest({ categoryFilter: newCategoryFilter, pagination: emptyPagination, type: tableState.type })
  }

  const handleDateRangePiker = (key: SalesRequestTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newDateRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetSalesRequest({ dateRangeFilters: newDateRangeFilters, pagination: emptyPagination, type: tableState.type })
  }

  const handleShowDemoUsers = (showDemoUsers: boolean) => {
    handleGetSalesRequest({ showDemoUsers, type: tableState.type })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetPagination = emptyPagination
    const resetRangeFilters = tableState.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))

    handleGetSalesRequest({
      pagination: resetPagination,
      searchFilters: resetSearchFilters,
      categoryFilter: { status: [] },
      dateRangeFilters: resetRangeFilters,
      showDemoUsers: false,
      type: undefined
    })
  }

  const onGoRequestTable = () => {
    history.push('/requests')
  }

  const handleView = (id: number) => {
    history.push(`/salesRequest/${id}`)
  }

  const onCSVDownload = async () => {
    setDownloadingCSV(true)
    await getSalesRequestCSV({
      pagination: tableState.pagination,
      searchFilters: tableState.searchFilters,
      categoryFilter: tableState.categoryFilter,
      dateRangeFilters: tableState.dateRangeFilters,
      showDemoUsers: tableState.showDemoUsers
    })
    setDownloadingCSV(false)
  }

  const paging = {
    currentPage: tableState.pagination.page,
    pageSize: tableState.pagination.pageSize,
    total: tableState.pagination.total,
    onPageChange: handlePageChange
  }

  const actions: MenuAction<SalesRequest>[] = [
    {
      label: (salesRequest) => {
        const found = STATUS_OPTIONS.find((sf) => sf.text === salesRequest?.status)
        return found?.value === STATUS_ID.RECIBIDA ? 'Iniciar' : 'Detalle'
      },
      icon: <SignIn />,
      onClick: ({ id }) => handleView(id),
      disabled: (salesRequest) => {
        const found = STATUS_OPTIONS.find((sf) => sf.text === salesRequest?.status)
        if (salesRequest?.object.objectSituation !== undefined && salesRequest?.object.objectSituation !== null) {
          return (
            OBJECT_SITUATION[salesRequest.object.objectSituation] === 'Vendido' ||
            ObjectSituacionsToSell.includes(OBJECT_SITUATION[salesRequest.object.objectSituation]) ||
            SaleRequestSituationToSell.includes(found?.text || '') ||
            found?.value === STATUS_ID.CANCELADA_CLIENTE ||
            found?.value === STATUS_ID.RECHAZADA_SG
          )
        } else {
          return true
        }
      }
    },
    {
      label: 'Notificar',
      icon: <Envelope />,
      disabled: tableState.loading,
      onClick: ({ id }) => sendSaleRequestNotification(id)
    },
    {
      label: 'Vender',
      icon: <MoneyIcon />,
      onClick: ({ id }) => handleView(id),
      disabled: (salesRequest) => {
        const found = STATUS_OPTIONS.find((sf) => sf.text === salesRequest?.status)
        return (
          !salesRequest?.object.objectSituation ||
          !ObjectSituacionsToSell.includes(OBJECT_SITUATION[salesRequest.object.objectSituation]) ||
          !SaleRequestSituationToSell.includes(found?.text || '')
        )
      }
    },
    {
      label: 'Cancelar Venta',
      icon: <Trash />,
      onClick: ({ id, user }) => {
        const data = {
          id: id.toString(),
          name: user.name,
          lastName: user.lastName
        }
        setOpenModal(true, data)
      },
      disabled: (salesRequest) => {
        const found = STATUS_OPTIONS.find((sf) => sf.text === salesRequest?.status)
        if (found) {
          return (
            !SaleRequestSituationToCancel.includes(found?.text) ||
            !SaleRequestSituationToCancel.includes(found?.text || '')
          )
        } else {
          return true
        }
      }
    }
  ]

  const salesRequestMetricsProps: SalesRequestMetricsProps = {
    type: tableState.type,
    metrics: tableState.metrics,
    fetchingMetrics: tableState.fetchingMetrics,
    handleGetSalesRequest
  }

  const Props: SalesRequestTableProps = {
    downloadingCSV,
    loading: tableState.loadingTable,
    salesRequest: tableState.salesRequest,
    pagination: paging,
    handleOpenQRCodeModal: openQRModal,
    handleSearch,
    handleCategoryFilter,
    handleResetFilters,
    handleDateRangePiker,
    handleShowDemoUsers,
    onGoRequestTable,
    onCSVDownload,
    actions,
    showDemoUsers: tableState.showDemoUsers,
    dateRangeFilters: tableState.dateRangeFilters,
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    statusOptions: tableState.statusOptions
  }

  return (
    <>
      <SalesRequestMetrics {...salesRequestMetricsProps} />
      <SalesRequestTable {...Props} />
      <CancelSaleModal />
    </>
  )
}

export default SalesRequestContainer
