export default {
  FETCH_DISCOUNT_REQUEST: 'DISCOUNT/FETCH_DISCOUNT_REQUEST',
  FETCH_DISCOUNT_SUCCESS: 'DISCOUNT/FETCH_DISCOUNT_SUCCESS',
  FETCH_DISCOUNT_FAILURE: 'DISCOUNT/FETCH_DISCOUNT_FAILURE',
  SET_PAGINATION: 'DISCOUNT/SET_PAGINATION',
  SET_SORTER: 'DISCOUNT/SET_SORTER',
  SET_FILTER: 'DISCOUNT/SET_FILTER',
  SET_SEARCH_FILTERS: 'DISCOUNT/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'BILLINGS/BILLINGS_BY_CLIENT/CLEAN_FILTERS'
}
