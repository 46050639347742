import { Location } from '../../../../projectApi/TransactionHandler/Locations/Corporative/list'
import { Pagination, SearchFilter } from '../../../../utils/searchFilterUtils'

export type LocationsTableSearchKey = 'id' | 'deposit_id' | 'owner_id'
export type LocationsSearchFilter = SearchFilter<LocationsTableSearchKey>

export type Filters = {
  id?: number
}

export type LocationListStateType = {
  fetchingLocations: boolean
  fetchingLocationsMetrics: boolean
  locations: Location[]
  locationsMetrics: LocationsMetrics
  pagination: Pagination
  searchFilters: LocationsSearchFilter[]
  filters: Filters
}

export type LocationsMetrics = {
  volumeInCm: number
  maxVolumeInCm: number
  weightInGr: number
  maxWeightInGr: number
  quantityObjects: number
  quantityLocations: number
  occupancyRate: number
  loadRate: number
  totalObjects?: number
  totalM3?: number
}

export enum GetLocationListTypes {
  GET_LOCATIONS_REQUEST = 'CORPORATIVE-LOCATION/LIST/GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS = 'CORPORATIVE-LOCATION/LIST/GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE = 'CORPORATIVE-LOCATION/LIST/GET_LOCATIONS_FAILURE'
}

export enum GetLocationsMetricsTypes {
  GET_LOCATIONS_METRICS_REQUEST = 'CORPORATIVE-LOCATION/METRICS/GET_LOCATIONS_METRICS_REQUEST',
  GET_LOCATIONS_METRICS_SUCCESS = 'CORPORATIVE-LOCATION/METRICS/GET_LOCATIONS_METRICS_SUCCESS',
  GET_LOCATIONS_METRICS_FAILURE = 'CORPORATIVE-LOCATION/METRICS/GET_LOCATIONS_METRICS_FAILURE'
}

export interface GetLocationListRequest {
  type: GetLocationListTypes.GET_LOCATIONS_REQUEST
  payload: {
    pagination: Pagination
    searchFilters?: LocationsSearchFilter[]
  }
}

export interface GetLocationListSuccess {
  type: GetLocationListTypes.GET_LOCATIONS_SUCCESS
  payload: {
    locations: Location[]
    total: number
  }
}

export interface GetLocationListFailure {
  type: GetLocationListTypes.GET_LOCATIONS_FAILURE
  payload: {
    error: string
  }
}

type GetLocationListActions = GetLocationListRequest | GetLocationListSuccess | GetLocationListFailure

export enum SetterLocationTypes {
  SET_LOCATION_FILTERS = 'CORPORATIVE-LOCATION/LIST/SET_LOCATION_FILTERS'
}

export interface SetLocationFilters {
  type: SetterLocationTypes.SET_LOCATION_FILTERS
  payload: {
    id?: number
  }
}

export const RESET_FILTERS = 'CORPORATIVE-LOCATION/LIST/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export interface GetLocationsMetricsRequest {
  type: GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_REQUEST
}

export interface GetLocationsMetricsSuccess {
  type: GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_SUCCESS
  payload: {
    locationsMetrics: LocationsMetrics
  }
}

export interface GetLocationsMetricsFailure {
  type: GetLocationsMetricsTypes.GET_LOCATIONS_METRICS_FAILURE
  payload: {
    error: string
  }
}

export type LocationListActions =
  | GetLocationListActions
  | SetLocationFilters
  | ResetFilters
  | GetLocationsMetricsRequest
  | GetLocationsMetricsSuccess
  | GetLocationsMetricsFailure
