import types from '../types/cbus'
import { URL_BASE_PROFILE } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import actions from './cbusModal'

export default {
  getCbus(userId, userName) {
    return (dispatch) => {
      dispatch({ type: types.GET_CBUS_DATA_REQUEST })
      return apiPrivate
        .get(URL_BASE_PROFILE + `/admin/cbu/user/id/${userId}`)
        .then((response) => {
          const cbus = response.data.description
          const quantity = response.data.description.length
          if (quantity === 0)
            dispatch(actions.setModalVisibility({ isVisible: true, userInfo: { userId, fullName: userName } }))
          dispatch({
            type: types.GET_CBUS_DATA_SUCCESS,
            payload: { cbus, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.GET_CBUS_DATA_FAILURE, dispatch)
        })
    }
  }
}
