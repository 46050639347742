import { Moment } from 'moment'
import { PriceBand } from '../../../projectApi/CategoryCreation/getPriceBands'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  CloseDeleteModal,
  CreatePriceBandTypes,
  FormattedPriceBand,
  OpenDeleteModal,
  PriceBandType,
  SetCreateModalOpen,
  SetDateFrom,
  SetEditModalOpen,
  SetPrice,
  SetType
} from '../types/create'
import { API } from '../../../projectApi'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const actions = {
  createPriceBand:
    (priceBand: FormattedPriceBand): AppThunk =>
    async (dispatch) => {
      dispatch({ type: CreatePriceBandTypes.ADD_PRICE_BAND_REQUEST })
      const toast = setToastLoading('Creando precio, por favor espere...')
      try {
        await API.CategoryCreation.PriceBand.createPriceBand(priceBand)
        dispatch({ type: CreatePriceBandTypes.ADD_PRICE_BAND_SUCCESS })
        Emitter.emit(Events.PriceBands.PRICE_BAND_CREATED)
        setToastSuccessUpdate(toast, { render: 'Precio creado con éxito' })
      } catch (error) {
        handleErrorsWithAction(error, CreatePriceBandTypes.ADD_PRICE_BAND_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'Hubo un error al crear el precio' })
      }
    },

  modifyProduct:
    (priceBand: PriceBandType): AppThunk =>
    async (dispatch) => {
      dispatch({ type: CreatePriceBandTypes.EDIT_PRICE_BAND_REQUEST })
      const toast = setToastLoading('Actualizando precio, por favor espere...')
      try {
        await API.CategoryCreation.PriceBand.updatePriceBand(priceBand)
        dispatch({ type: CreatePriceBandTypes.EDIT_PRICE_BAND_SUCCESS })
        Emitter.emit(Events.PriceBands.PRICE_BAND_UPDATED)
        setToastSuccessUpdate(toast, { render: 'Precio actualizado con éxito' })
      } catch (error) {
        handleErrorsWithAction(error, CreatePriceBandTypes.EDIT_PRICE_BAND_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'Hubo un error al actualizar el precio' })
      }
    },

  deletePriceBand:
    (id: number): AppThunk =>
    async (dispatch) => {
      dispatch({ type: CreatePriceBandTypes.DELETE_PRICE_BAND_REQUEST })
      const toast = setToastLoading('Eliminando precio, por favor espere...')
      try {
        await API.CategoryCreation.PriceBand.deletePriceBand({ id })
        dispatch({ type: CreatePriceBandTypes.DELETE_PRICE_BAND_SUCCESS })
        Emitter.emit(Events.PriceBands.PRICE_BAND_DELETED)
        setToastSuccessUpdate(toast, { render: 'Precio eliminado con éxito' })
      } catch (error) {
        handleErrorsWithAction(error, CreatePriceBandTypes.DELETE_PRICE_BAND_FAILURE, dispatch)
        setToastErrorUpdate(toast, { render: 'Hubo un error al eliminar el precio' })
      }
    },

  setCreateModalOpen(modalOpen: boolean): SetCreateModalOpen {
    return {
      type: CreatePriceBandTypes.SET_MODAL_OPEN,
      payload: { modalOpen }
    }
  },

  setEditModalOpen(priceBand: PriceBand): SetEditModalOpen {
    return {
      type: CreatePriceBandTypes.SET_EDIT_MODAL_OPEN,
      payload: { priceBand }
    }
  },

  setPrice(price: number): SetPrice {
    return {
      type: CreatePriceBandTypes.SET_PRICE,
      payload: { price }
    }
  },

  setDateFrom(dateFrom: Moment): SetDateFrom {
    return {
      type: CreatePriceBandTypes.SET_DATE_FROM,
      payload: { dateFrom }
    }
  },

  setTypePrice(type: 'm3' | 'kg'): SetType {
    return {
      type: CreatePriceBandTypes.SET_TYPE,
      payload: { type }
    }
  },

  setCountry(country: string) {
    return { type: CreatePriceBandTypes.SET_COUNTRY, payload: { country } }
  },
  openModal: (id: number): OpenDeleteModal => ({
    type: CreatePriceBandTypes.OPEN_DELETE_MODAL,
    payload: { id }
  }),
  closeModal: (): CloseDeleteModal => ({ type: CreatePriceBandTypes.CLOSE_DELETE_MODAL })
}

export default actions
