import { useMemo } from 'react'
import { BaseOption } from '../../../components/Select/Select'
import { states } from '../../Objects/constants/objectStates'

export type ObjectStatusOption = BaseOption<number>

export const useObjectStatusOptions = (): ObjectStatusOption[] =>
  useMemo(() => {
    return states.map((states) => ({
      label: states.text,
      value: states.value
    }))
  }, [])
