import { AddressDetail, Deposit } from '../../../../projectApi/TransactionHandler/Deposits/common'

export type ABMDepositsModalState = {
  editMode: boolean
  modalVisible: boolean
  deleteModalVisible: boolean
  changeAddress: boolean
  creatingDeposit: boolean
  updatingDeposit: boolean
  deletingDeposit: boolean
  disabledDeleteButton: boolean
  fetchingAddressDetails: boolean
  depositId: number
  depositName: string
  depositAddressId?: number
  selectedDeposit?: Deposit
  addressDetails: Partial<AddressDetail>
  depositCountryCode: string
  addressError: string
}

export type FormFields =
  | 'addressStreet'
  | 'addressNumber'
  | 'addressFloor'
  | 'addressApartment'
  | 'addressCity'
  | 'addressProvince'
  | 'addressPostalCode'
  | 'addressComment'

export enum SettersDepositsModalTypes {
  SET_MODAL_VISIBILITY = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_MODAL_VISIBILITY',
  SET_FORM_FIELD = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_FORM_FIELD',
  SET_DEPOSIT_NAME = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_DEPOSIT_NAME',
  SET_DEPOSIT_ADDRESS = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_DEPOSIT_ADDRESS',
  SET_ADDRESS_ERROR = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_ADDRESS_ERROR',
  SET_DELETE_MODAL_VISIBILITY = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_DELETE_MODAL_VISIBILITY',
  SET_CHANGE_ADDRESS = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_CHANGE_ADDRESS',
  SET_SELECTED_DEPOSIT = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/SET_SELECTED_DEPOSIT'
}

export interface SetModalVisibility {
  type: typeof SettersDepositsModalTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean; editMode?: boolean }
}

export interface SetFormField {
  type: typeof SettersDepositsModalTypes.SET_FORM_FIELD
  payload: { field: FormFields; value: string }
}

export interface SetDepositName {
  type: typeof SettersDepositsModalTypes.SET_DEPOSIT_NAME
  payload: { depositName: string }
}

export interface SetDepositAddress {
  type: typeof SettersDepositsModalTypes.SET_DEPOSIT_ADDRESS
  payload: { selectedAddress: Partial<AddressDetail> & { country?: string } }
}

export interface SetAddressError {
  type: typeof SettersDepositsModalTypes.SET_ADDRESS_ERROR
  payload: { errorMessage: string }
}

export interface SetDeleteModalVisibility {
  type: typeof SettersDepositsModalTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisibility: boolean }
}

export interface SetChangeAddress {
  type: typeof SettersDepositsModalTypes.SET_CHANGE_ADDRESS
  payload: { changeAddress: boolean }
}

export interface SetSelectedDeposit {
  type: typeof SettersDepositsModalTypes.SET_SELECTED_DEPOSIT
  payload: { selectedDeposit: Deposit }
}

type SettersDepositModalActions =
  | SetModalVisibility
  | SetFormField
  | SetDepositName
  | SetDepositAddress
  | SetAddressError
  | SetDeleteModalVisibility
  | SetChangeAddress
  | SetSelectedDeposit

export enum GetAddressDetailsTypes {
  GET_ADDRESS_DETAILS_REQUEST = 'DEPOSIT/CREATE_CORPORATIVE_DEPOSIT/GET_ADDRESS_DETAILS_REQUEST',
  GET_ADDRESS_DETAILS_SUCCESS = 'DEPOSIT/CREATE_CORPORATIVE_DEPOSIT/GET_ADDRESS_DETAILS_SUCCESS',
  GET_ADDRESS_DETAILS_FAILURE = 'DEPOSIT/CREATE_CORPORATIVE_DEPOSIT/GET_ADDRESS_DETAILS_FAILURE'
}

export interface GetAddressDetailsRequest {
  type: GetAddressDetailsTypes.GET_ADDRESS_DETAILS_REQUEST
}

export interface GetAddressDetailsSuccess {
  type: GetAddressDetailsTypes.GET_ADDRESS_DETAILS_SUCCESS
  payload: {
    addressDetails: Partial<AddressDetail> & { addressId?: number }
  }
}

export interface GetAddressDetailsFailure {
  type: GetAddressDetailsTypes.GET_ADDRESS_DETAILS_FAILURE
}

export type GetAddressDetailsActions = GetAddressDetailsRequest | GetAddressDetailsSuccess | GetAddressDetailsFailure

export enum CreateDepositTypes {
  CREATE_DEPOSIT_REQUEST = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/CREATE_DEPOSIT_REQUEST',
  CREATE_DEPOSIT_SUCCESS = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/CREATE_DEPOSIT_SUCCESS',
  CREATE_DEPOSIT_FAILURE = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/CREATE_DEPOSIT_FAILURE'
}

export interface CreateDepositRequest {
  type: CreateDepositTypes.CREATE_DEPOSIT_REQUEST
}

export interface CreateDepositSuccess {
  type: CreateDepositTypes.CREATE_DEPOSIT_SUCCESS
}

export interface CreateDepositFailure {
  type: CreateDepositTypes.CREATE_DEPOSIT_FAILURE
}

export type CreateDepositActions = CreateDepositRequest | CreateDepositSuccess | CreateDepositFailure

export enum DeleteDepositTypes {
  DELETE_DEPOSIT_REQUEST = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/DELETE_DEPOSIT_REQUEST',
  DELETE_DEPOSIT_SUCCESS = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/DELETE_DEPOSIT_SUCCESS',
  DELETE_DEPOSIT_FAILURE = 'DEPOSIT/ABM_CORPORATIVE_DEPOSITS/DELETE_DEPOSIT_FAILURE'
}

export interface DeleteDepositRequest {
  type: DeleteDepositTypes.DELETE_DEPOSIT_REQUEST
}

export interface DeleteDepositSuccess {
  type: DeleteDepositTypes.DELETE_DEPOSIT_SUCCESS
}

export interface DeleteDepositFailure {
  type: DeleteDepositTypes.DELETE_DEPOSIT_FAILURE
}

export type DeleteDepositActions = DeleteDepositRequest | DeleteDepositSuccess | DeleteDepositFailure

export enum EditDepositTypes {
  EDIT_DEPOSIT_REQUEST = 'DEPOSIT/CREATE_DEPOSIT/EDIT_DEPOSIT_REQUEST',
  EDIT_DEPOSIT_SUCCESS = 'DEPOSIT/CREATE_DEPOSIT/EDIT_DEPOSIT_SUCCESS',
  EDIT_DEPOSIT_FAILURE = 'DEPOSIT/CREATE_DEPOSIT/EDIT_DEPOSIT_FAILURE'
}

export interface EditDepositRequest {
  type: EditDepositTypes.EDIT_DEPOSIT_REQUEST
}

export interface EditDepositSuccess {
  type: EditDepositTypes.EDIT_DEPOSIT_SUCCESS
}

export interface EditDepositFailure {
  type: EditDepositTypes.EDIT_DEPOSIT_FAILURE
}

export type EditDepositActions = EditDepositRequest | EditDepositSuccess | EditDepositFailure

export type ABMDepositsActions =
  | SettersDepositModalActions
  | CreateDepositActions
  | DeleteDepositActions
  | GetAddressDetailsActions
  | EditDepositActions
