import React from 'react'
import { Button, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const styles = {
  container: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const
  },
  checkboxContainer: {
    minWidth: '80px'
  },
  checkbox: {
    fontSize: '12px'
  },
  floorsQuantity: {
    padding: '3px 0',
    border: '1px solid #5D58F7',
    borderRadius: '4px',
    display: 'flex',
    width: '75px'
  },
  floorsButtons: {
    fontSize: '9px'
  },
  itemQuantity: {
    flex: 1,
    textAlign: 'center' as const,
    padding: '0 5px'
  }
}

type ExtraEvents = {
  onFloorsCheckboxChange: (checked: boolean) => void
  onFloorsQuantityChange: (checked: number) => void
  onAssemblyCostCheckboxChange: (checked: boolean) => void
  onRemovePackagingCostChange: (checked: boolean) => void
}

type ExtraLabels = {
  floors: string
  removePackagingCost: string
  assemblyCost: string
}

type ExtraIndeterminates = {
  floors: boolean
  removePackagingCost: boolean
  assemblyCost: boolean
}

type ExtrasValues = {
  floors: boolean
  numberOfFloors: number
  removePackagingCost: boolean
  assemblyCost: boolean
}

type ExtrasFieldsProps = {
  events: ExtraEvents
  labels: ExtraLabels
  indeterminates?: ExtraIndeterminates
  values: ExtrasValues
  fromRemoval?: boolean
}

const ExtrasFields = (props: ExtrasFieldsProps) => {
  const {
    events,
    labels,
    indeterminates = {
      floors: false,
      removePackagingCost: false,
      assemblyCost: false
    },
    values,
    fromRemoval
  } = props

  const { floors, numberOfFloors, removePackagingCost, assemblyCost } = values
  const { onFloorsCheckboxChange, onFloorsQuantityChange, onAssemblyCostCheckboxChange, onRemovePackagingCostChange } =
    events

  const onFloorsCheckboxChangeHandler = (e: CheckboxChangeEvent) => onFloorsCheckboxChange(e.target.checked)
  const onNumberOfFloorsMinusClick = () => onFloorsQuantityChange(numberOfFloors - 1)
  const onNumberOfFloorsPlusClick = () => onFloorsQuantityChange(numberOfFloors + 1)
  const onRemovePackagingCostChangeHandler = (e: CheckboxChangeEvent) => onRemovePackagingCostChange(e.target.checked)
  const onAssemblyCostCheckboxChangeHandler = (e: CheckboxChangeEvent) => onAssemblyCostCheckboxChange(e.target.checked)

  const stairsButtonsDisabled = !floors && !indeterminates.floors

  const numberOfFloorsStyles = {
    ...styles.itemQuantity,
    ...(stairsButtonsDisabled ? { color: 'rgba(0, 0, 0, 0.25)' } : {})
  }

  return (
    <div style={styles.container}>
      <div style={styles.checkboxContainer}>
        <Checkbox
          style={styles.checkbox}
          indeterminate={indeterminates.floors && !floors}
          checked={floors}
          onChange={onFloorsCheckboxChangeHandler}
          disabled={fromRemoval}>
          {labels.floors}
        </Checkbox>
      </div>
      {!fromRemoval && (
        <div style={styles.floorsQuantity}>
          <Button
            style={styles.floorsButtons}
            icon="minus"
            type="link"
            size="small"
            onClick={onNumberOfFloorsMinusClick}
            disabled={stairsButtonsDisabled || numberOfFloors === 1}
          />
          <span style={numberOfFloorsStyles}>{numberOfFloors}</span>
          <Button
            style={styles.floorsButtons}
            disabled={stairsButtonsDisabled}
            icon="plus"
            type="link"
            size="small"
            onClick={onNumberOfFloorsPlusClick}
          />
        </div>
      )}
      <div style={styles.checkboxContainer}>
        <Checkbox
          style={styles.checkbox}
          indeterminate={indeterminates.removePackagingCost && !removePackagingCost}
          checked={removePackagingCost}
          onChange={onRemovePackagingCostChangeHandler}
          disabled={fromRemoval}>
          {labels.removePackagingCost}
        </Checkbox>
      </div>
      <div style={styles.checkboxContainer}>
        <Checkbox
          style={styles.checkbox}
          indeterminate={indeterminates.assemblyCost && !assemblyCost}
          checked={assemblyCost}
          onChange={onAssemblyCostCheckboxChangeHandler}
          disabled={fromRemoval}>
          {labels.assemblyCost}
        </Checkbox>
      </div>
    </div>
  )
}

export default ExtrasFields
