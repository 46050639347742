import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import proposalActionCreators from '../actions/proposals'
import addressActionsCreator from '../../../components/Address/actions'
import Component from '../components/SelectProposal'
import { bindActionCreators } from 'redux'

type SelectProposalProps = {
  mode: string
}

const Container = (ownProps: SelectProposalProps) => {
  const dispatch = useDispatch()

  const proposalActions = bindActionCreators(proposalActionCreators, dispatch)
  const addressActions = bindActionCreators(addressActionsCreator, dispatch)

  const selectedProspect = useSelector((state) => state.NewProposal.prospect.selectedProspect)
  const { selectedProposal, proposals, metrics } = useSelector((state) => state.NewProposal.proposals)

  const props = {
    addressActions,
    selectedProspect,
    selectedProposal,
    proposals,
    proposalActions,
    metrics
  }

  return <Component {...props} />
}

export default Container
