import { Reducer } from 'redux'
import {
  OperationDatesState,
  OperationDatesAction,
  GetOperationDatesTypes,
  CreateOperationDatesTypes,
  ModalCreateOperationDate,
  ModalDeleteOperationDate,
  DeleteOperationDatesTypes
} from '../types/operationDates'
import moment from 'moment'
import { OperationTypeValues } from '../types/common'

const initialState: OperationDatesState = {
  datesData: [],
  fetchingDates: true,
  postingDate: false,
  error: '',
  isCreateModalOpen: false,
  selectedCreateDate: null,
  selectedDeleteDate: {
    date: moment(),
    operationType: OperationTypeValues.DEPOSIT
  },
  selectedType: '',
  isDeleteModalOpen: false,
  deletingDate: false,
  selectedOperations: []
}

const OperationDatesReducer: Reducer<OperationDatesState, OperationDatesAction> = (
  state = initialState,
  action
): OperationDatesState => {
  switch (action.type) {
    case GetOperationDatesTypes.GET_DATES_REQUEST:
      return {
        ...state,
        fetchingDates: true
      }
    case GetOperationDatesTypes.GET_DATES_SUCCESS:
      return {
        ...state,
        datesData: action.payload.datesData,
        fetchingDates: false
      }
    case GetOperationDatesTypes.GET_DATES_FAILURE:
      return {
        ...state,
        fetchingDates: false
      }
    case CreateOperationDatesTypes.CREATE_DATE_REQUEST:
      return {
        ...state,
        fetchingDates: true
      }
    case CreateOperationDatesTypes.CREATE_DATE_SUCCESS:
    case CreateOperationDatesTypes.CREATE_DATE_FAILURE:
      return {
        ...state,
        fetchingDates: false
      }
    case ModalDeleteOperationDate.SET_DELETE_MODAL_OPEN:
      return {
        ...state,
        isDeleteModalOpen: action.payload.isDeleteModalOpen
      }
    case ModalCreateOperationDate.SET_CREATE_MODAL_OPEN:
      return {
        ...state,
        isCreateModalOpen: action.payload.isCreateModalOpen
      }
    case ModalCreateOperationDate.SET_SELECTED_CREATE_DATE:
      return {
        ...state,
        selectedCreateDate: action.payload.selectedCreateDate
      }
    case ModalDeleteOperationDate.SET_SELECTED_DELETE_DATE:
      return {
        ...state,
        selectedDeleteDate: action.payload.selectedDeleteDate
      }
    case DeleteOperationDatesTypes.DELETE_DATE_REQUEST:
      return {
        ...state,
        deletingDate: true
      }
    case DeleteOperationDatesTypes.DELETE_DATE_SUCCESS:
    case DeleteOperationDatesTypes.DELETE_DATE_FAILURE:
      return {
        ...state,
        deletingDate: false
      }

    default:
      return state
  }
}

export default OperationDatesReducer
