import { Moment } from 'moment'
import { ReactNode } from 'react'
import { GenericObject, Leaves } from '../../../utils/utilityTypes'
import { BaseOption } from '../../Select/Select'

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export type DataCellRenderer<T> = (item: T, index: number) => ReactNode
export type RowExpandedRenderer<T> = (item: T) => ReactNode

export type FilterOption = {
  text: string
  value: string | number
}

export type AutocompleteProps = {
  label: string
  value: number
  extraComponent?: ReactNode
  options: BaseOption<number>[]
  loadingOptions: boolean
  getOptions: (option: string) => void
  onConfirm: (newValue: number) => void
}

export type SearchProps = {
  label: string
  value: string
  onConfirm: (newValue: string) => void
  extraComponent?: ReactNode
  placeholder?: string
}

export type FiltersProps = {
  options: FilterOption[]
  multiple: boolean
  selected: (string | number)[]
  onConfirm: (selected: (string | number)[]) => void
}

export type SortProps = {
  direction: SortDirection
  isActive: boolean
  onClick: (newSortDirection: SortDirection) => void
}

export type SingleDatePickerProps = {
  date?: Moment
  onConfirm: (date?: Moment) => void
}

export type RangePickerProps = {
  startDate?: Moment
  endDate?: Moment
  onConfirm: (startDate?: Moment, endDate?: Moment) => void
}

export type Pagination = {
  currentPage: number
  total: number
  pageSize: number
  onPageChange: (newPage: number) => void
}

type BaseColumn = {
  label: ReactNode
  tooltip?: string
  minWidth?: string
  maxWidth?: string
  hidden?: boolean
  search?: SearchProps
  rangePicker?: RangePickerProps
  autocomplete?: AutocompleteProps
  singleDatePicker?: SingleDatePickerProps
  filters?: FiltersProps
  sort?: SortProps
  noPadding?: boolean
  alignment?: 'left' | 'center' | 'right'
}

export type Column<Entity extends GenericObject> = BaseColumn &
  (
    | {
        key: keyof Entity | Leaves<Entity>
        renderDataCell?: DataCellRenderer<Entity>
      }
    | {
        key: string
        renderDataCell: DataCellRenderer<Entity>
      }
  )
