export enum NewProductEventsEnum {
  PRODUCT_CREATED = 'NEW_PRODUCT/PRODUCT_CREATED',
  PRODUCT_UPDATED = 'NEW_PRODUCT/PRODUCT_UPDATED',
  PRODUCT_CREATED_BY_CATALOG = 'NEW_PRODUCT/PRODUCT_CREATED_BY_CATALOG'
}

export type NewProductEvents = {
  [NewProductEventsEnum.PRODUCT_CREATED]: () => void
  [NewProductEventsEnum.PRODUCT_UPDATED]: () => void
  [NewProductEventsEnum.PRODUCT_CREATED_BY_CATALOG]: () => void
}
