import { allCountriesOption } from '../constants/constants'
import { CountrySelectorActions, CountrySelectorState, CountrySelectorTypes } from '../types/CountrySelector'
import { REHYDRATE } from 'redux-persist/es/constants'
import { addCountryParam } from '../../../api'

const initialState: CountrySelectorState = {
  loadingCountries: false,
  error: '',
  countries: [],
  countrySelected: allCountriesOption
}

const CountrySelectorReducer = (
  state: CountrySelectorState = initialState,
  action: CountrySelectorActions
): CountrySelectorState => {
  switch (action.type) {
    case CountrySelectorTypes.GET_COUNTRIES_REQUEST: {
      return {
        ...state,
        loadingCountries: true
      }
    }
    case CountrySelectorTypes.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        loadingCountries: false,
        countries: action.payload.countries
      }
    }
    case CountrySelectorTypes.GET_COUNTRIES_FAILURE: {
      return {
        ...state,
        loadingCountries: false,
        error: action.payload.error
      }
    }

    case CountrySelectorTypes.SELECT_COUNTRY_FILTER: {
      return {
        ...state,
        countrySelected: action.payload.value
      }
    }
    case REHYDRATE: {
      if (action.payload?.CountrySelector) {
        addCountryParam(action.payload.CountrySelector.countrySelected.code)
      }
      return state
    }
    default:
      return state
  }
}

export default CountrySelectorReducer
