import React from 'react'
import { bindActionCreators } from 'redux'
import { DeleteAddressActionCreator } from '../actions/deleteAddress'
import Component from '../components/deleteAddress'
import { useDispatch, useSelector } from 'react-redux'
import { AddressActionCreator } from '../../Address/actions/address'

const Container = () => {
  const dispatch = useDispatch()
  const deleteAddressActionCreator = bindActionCreators(DeleteAddressActionCreator, dispatch)
  const addressActionsCreator = bindActionCreators(AddressActionCreator, dispatch)

  const { deleteAddress, address } = useSelector((state) => state.UserAddresses)

  const { params: paramsFromAddress } = address

  const props = {
    deleteAddressAction: deleteAddressActionCreator.deleteAddress,
    closeModal: deleteAddressActionCreator.closeModal,
    deleteAddress,
    getAddresses: addressActionsCreator.getAddresses,
    paramsFromAddress
  }

  return <Component {...props} />
}

export default Container
