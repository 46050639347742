import { CategoryFilter, DateRangeFilter, SearchFilter } from '../../../utils/searchFilterUtils'
import { BillingData } from '../../../projectApi/TransactionHandler/BillingData/list'
import { BillingMetrics } from '../../../projectApi/TransactionHandler/Billings/metrics'
import { BillingStatusValue } from '../constants'
import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'
import { BillingWarning } from '../../../projectApi/TransactionHandler/BillingData/warnings'

export type BillingTableSearchKey = 'userId'
export type BillingsSearchFilter = SearchFilter<BillingTableSearchKey>

export enum BillingsTableDateRangeKey {
  PERIOD = 'period'
}
export type BilingsTableDateRangeFilter = DateRangeFilter<BillingsTableDateRangeKey>

export type BillingsTableCategoryFilterKey = 'status' | 'excludedUsers'
export type BillingsTableCategoryFilter = CategoryFilter<BillingsTableCategoryFilterKey, BillingStatusValue | string>

export interface BillingState {
  loadingRows: number[]
  billings: BillingData[]
  billingMetrics: BillingMetrics | null
  billingWarnings: BillingWarning[]
  categoryFilter: BillingsTableCategoryFilter
  dateRangeFilters: BilingsTableDateRangeFilter[]
  searchFilters: BillingsSearchFilter[]
  warningId?: number
  loadingBillings: boolean
  fetchingBillingWarnings: boolean
  sendingCSV: boolean
  error: string
  pagination: Pagination
  retryChargeDays: number
  isLoadingCharge: boolean
  isLoadingDownload: boolean
  IsDebtModalOpen: boolean
  selectedBilling: BillingData | null
  billingsByClient: Billing[]
  clientSelected: number
  paginationByClient: Pagination
  isLoadingClient: boolean
  exportingPayments: boolean
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export const GET_BILLING_DATA_REQUEST = 'BILLINGS/GET_BILLING_DATA_REQUEST'
export const GET_BILLING_DATA_SUCCESS = 'BILLINGS/GET_BILLING_DATA_SUCCESS'
export const GET_BILLING_DATA_FAILURE = 'BILLINGS/GET_BILLING_DATA_FAILURE'

export interface GetBillingRequest {
  type: typeof GET_BILLING_DATA_REQUEST
  payload: {
    categoryFilter?: BillingsTableCategoryFilter
    searchFilters?: BillingsSearchFilter[]
    dateRangeFilters?: BilingsTableDateRangeFilter[]
    warningId?: number
    pageSize: number
    newPage: number
  }
}

export interface GetBillingSuccess {
  type: typeof GET_BILLING_DATA_SUCCESS
  payload: {
    billings: BillingData[]
    total: number
  }
}

export interface GetBillingFailure {
  type: typeof GET_BILLING_DATA_FAILURE
  payload: {
    error: string
  }
}

export type GetBillingActions = GetBillingRequest | GetBillingSuccess | GetBillingFailure

export const GET_METRICS_REQUEST = 'BILLINGS/GET_METRICS_REQUEST'
export const GET_METRICS_SUCCESS = 'BILLINGS/GET_METRICS_SUCCESS'
export const GET_METRICS_FAILURE = 'BILLINGS/GET_METRICS_FAILURE'

export interface GetMetricsRequest {
  type: typeof GET_METRICS_REQUEST
}

export interface GetMetricsSuccess {
  type: typeof GET_METRICS_SUCCESS
  payload: {
    billingMetrics: BillingMetrics
  }
}

export interface GetMetricsFailure {
  type: typeof GET_METRICS_FAILURE
  payload: {
    error: string
  }
}

export const RECALCULATE_BILLING_REQUEST = 'BILLINGS/RECALCULATE_BILLING_REQUEST'
export const RECALCULATE_BILLING_SUCCESS = 'BILLINGS/RECALCULATE_BILLING_SUCCESS'
export const RECALCULATE_BILLING_FAILURE = 'BILLINGS/RECALCULATE_BILLING_FAILURE'

export interface RecalculateBillingRequest {
  type: typeof RECALCULATE_BILLING_REQUEST
}

export interface RecalculateBillingSuccess {
  type: typeof RECALCULATE_BILLING_SUCCESS
}

export interface RecalculateBillingFailure {
  type: typeof RECALCULATE_BILLING_FAILURE
  payload: {
    error: string
  }
}

export type GetMetrics = GetMetricsRequest | GetMetricsSuccess | GetMetricsFailure

export type RecalculateBillingActions =
  | RecalculateBillingRequest
  | RecalculateBillingSuccess
  | RecalculateBillingFailure

export const GET_AUTHORIZATION_AFIP_REQUEST = 'BILLINGS/GET_AUTHORIZATION_AFIP_REQUEST'
export const GET_AUTHORIZATION_AFIP_SUCCESS = 'BILLINGS/GET_AUTHORIZATION_AFIP_SUCCESS'
export const GET_AUTHORIZATION_AFIP_FAILURE = 'BILLINGS/GET_AUTHORIZATION_AFIP_FAILURE'

export interface GetAuthorizationAfipRequest {
  type: typeof GET_AUTHORIZATION_AFIP_REQUEST
}

export interface GetAuthorizationAfipSuccess {
  type: typeof GET_AUTHORIZATION_AFIP_SUCCESS
}

export interface GetAuthorizationAfipFailure {
  type: typeof GET_AUTHORIZATION_AFIP_FAILURE
  payload: {
    error: string
  }
}

export type GetAuthorizationAfipActions =
  | GetAuthorizationAfipRequest
  | GetAuthorizationAfipSuccess
  | GetAuthorizationAfipFailure

export const GET_RETRY_CHARGE_DAYS_REQUEST = 'BILLINGS/GET_RETRY_CHARGE_DAYS_REQUEST'
export const GET_RETRY_CHARGE_DAYS_SUCCESS = 'BILLINGS/GET_RETRY_CHARGE_DAYS_SUCCESS'
export const GET_RETRY_CHARGE_DAYS_FAILURE = 'BILLINGS/GET_RETRY_CHARGE_DAYS_FAILURE'

export interface GetRetryChargeDaysRequest {
  type: typeof GET_RETRY_CHARGE_DAYS_REQUEST
}

export interface GetRetryChargeDaysSuccess {
  type: typeof GET_RETRY_CHARGE_DAYS_SUCCESS
  payload: {
    retryChargeDays: number
  }
}

export interface GetRetryChargeDaysFailure {
  type: typeof GET_RETRY_CHARGE_DAYS_FAILURE
  payload: {
    error: string
  }
}

export type GetRetryChargeDaysActions =
  | GetRetryChargeDaysRequest
  | GetRetryChargeDaysSuccess
  | GetRetryChargeDaysFailure

export const SEND_DEBT_REMINDER_REQUEST = 'BILLINGS/SEND_DEBT_REMINDER_REQUEST'
export const SEND_DEBT_REMINDER_SUCCESS = 'BILLINGS/SEND_DEBT_REMINDER_SUCCESS'
export const SEND_DEBT_REMINDER_FAILURE = 'BILLINGS/SEND_DEBT_REMINDER_FAILURE'

export interface SendDebtReminderRequest {
  type: typeof SEND_DEBT_REMINDER_REQUEST
}

export interface SendDebtReminderSuccess {
  type: typeof SEND_DEBT_REMINDER_SUCCESS
}

export interface SendDebtReminderFailure {
  type: typeof SEND_DEBT_REMINDER_FAILURE
  payload: {
    error: string
  }
}

export type SendDebtReminderActions = SendDebtReminderRequest | SendDebtReminderSuccess | SendDebtReminderFailure

export const OPEN_DEBT_MODAL = 'BILLINGS/OPEN_DEBT_MODAL'
export const CLOSE_DEBT_MODAL = 'BILLINGS/CLOSE_DEBT_MODAL'
export interface OpenDebtModal {
  type: typeof OPEN_DEBT_MODAL
  payload: {
    billingData: BillingData
  }
}

export interface CloseDebtModal {
  type: typeof CLOSE_DEBT_MODAL
}

export type DebtsModalActions = OpenDebtModal | CloseDebtModal
export const SEND_CSV_REQUEST = 'BILLINGS/TABLE/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'BILLINGS/TABLE/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'BILLINGS/TABLE/SEND_CSV_FAILURE'

export interface SendCSVRequest {
  type: typeof SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SEND_CSV_FAILURE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export enum GetBillingWarningsTypes {
  GET_BILLING_WARNINGS_REQUEST = 'BILLINGS/TABLE/GET_BILLING_WARNINGS_REQUEST',
  GET_BILLING_WARNINGS_SUCCESS = 'BILLINGS/TABLE/GET_BILLING_WARNINGS_SUCCESS',
  GET_BILLING_WARNINGS_FAILURE = 'BILLINGS/TABLE/GET_BILLING_WARNINGS_FAILURE'
}

export interface GetBillingWarningsRequest {
  type: typeof GetBillingWarningsTypes.GET_BILLING_WARNINGS_REQUEST
}

export interface GetBillingWarningsSuccess {
  type: typeof GetBillingWarningsTypes.GET_BILLING_WARNINGS_SUCCESS
  payload: {
    billingWarnings: BillingWarning[]
  }
}

export interface GetBillingWarningsFailure {
  type: typeof GetBillingWarningsTypes.GET_BILLING_WARNINGS_FAILURE
}

export type GetBillingWarningsActions =
  | GetBillingWarningsRequest
  | GetBillingWarningsSuccess
  | GetBillingWarningsFailure

export const REFRESH_BILLING_REQUEST = 'BILLINGS/REFRESH_BILLING_REQUEST'
export const REFRESH_BILLING_SUCCESS = 'BILLINGS/REFRESH_BILLING_SUCCESS'
export const REFRESH_BILLING_FAILURE = 'BILLINGS/REFRESH_BILLING_FAILURE'

export interface RefreshBillingRequest {
  type: typeof REFRESH_BILLING_REQUEST
  payload: {
    billingId: number
  }
}

export interface RefreshBillingSuccess {
  type: typeof REFRESH_BILLING_SUCCESS
  payload: {
    billing: BillingData
  }
}

export interface RefreshBillingFailure {
  type: typeof REFRESH_BILLING_FAILURE
  payload: {
    error: string
    billingId: number
  }
}

export type RefreshBillingActions = RefreshBillingRequest | RefreshBillingSuccess | RefreshBillingFailure

export const GET_CREDIT_NOTE_REQUEST = 'BILLINGS/GET_CREDIT_NOTE_REQUEST'
export const GET_CREDIT_NOTE_SUCCESS = 'BILLINGS/GET_CREDIT_NOTE_SUCCESS'
export const GET_CREDIT_NOTE_FAILURE = 'BILLINGS/GET_CREDIT_NOTE_FAILURE'

export interface GetCreditNoteRequest {
  type: typeof GET_CREDIT_NOTE_REQUEST
}

export interface GetCreditNoteSuccess {
  type: typeof GET_CREDIT_NOTE_SUCCESS
}

export interface GetCreditNoteFailure {
  type: typeof GET_CREDIT_NOTE_FAILURE
  payload: {
    error: string
  }
}

export type GetCreditNoteActions = GetCreditNoteRequest | GetCreditNoteSuccess | GetCreditNoteFailure

export const SET_QUERY_DATA = 'BILLINGS/BILLINGS_BY_CLIENT/SET_QUERY_DATA'

export const GET_BILLING_BY_CLIENT_REQUEST = 'BILLINGS/BILLINGS_BY_CLIENT/NEW_TABLE/GET_BILLING_BY_CLIENT_REQUEST'
export const GET_BILLING_BY_CLIENT_SUCCESS = 'BILLINGS/BILLINGS_BY_CLIENT/NEW_TABLE/GET_BILLING_BY_CLIENT_SUCCESS'
export const GET_BILLING_BY_CLIENT_FAILURE = 'BILLINGS/BILLINGS_BY_CLIENT/NEW_TABLE/GET_BILLING_BY_CLIENT_FAILURE'

export interface GetBillingByClientRequest {
  type: typeof GET_BILLING_BY_CLIENT_REQUEST
  payload: {
    userId: number
    pageSize: number
    newPage: number
  }
}

export interface GetBillingByClientSuccess {
  type: typeof GET_BILLING_BY_CLIENT_SUCCESS
  payload: {
    billingsByClient: Billing[]
    total: number
  }
}

export interface GetBillingByClientFailure {
  type: typeof GET_BILLING_BY_CLIENT_FAILURE
  payload: {
    error: string
  }
}

export type GetBillingByClientAction = GetBillingByClientRequest | GetBillingByClientSuccess | GetBillingByClientFailure

export enum ExportPaymentsTypes {
  EXPORT_PAYMENTS_REQUEST = 'BILLINGS/TABLE/EXPORT_PAYMENTS_REQUEST',
  EXPORT_PAYMENTS_SUCCESS = 'BILLINGS/TABLE/EXPORT_PAYMENTS_SUCCESS',
  EXPORT_PAYMENTS_FAILURE = 'BILLINGS/TABLE/EXPORT_PAYMENTS_FAILURE'
}

export interface ExportPaymentsRequest {
  type: typeof ExportPaymentsTypes.EXPORT_PAYMENTS_REQUEST
}

export interface ExportPaymentsSuccess {
  type: typeof ExportPaymentsTypes.EXPORT_PAYMENTS_SUCCESS
}

export interface ExportPaymentsFailure {
  type: typeof ExportPaymentsTypes.EXPORT_PAYMENTS_FAILURE
}

export type ExportPaymentsActions = ExportPaymentsRequest | ExportPaymentsSuccess | ExportPaymentsFailure

export type BillingActions =
  | GetBillingActions
  | RecalculateBillingActions
  | GetAuthorizationAfipActions
  | GetRetryChargeDaysActions
  | SendDebtReminderActions
  | DebtsModalActions
  | SendCSVActions
  | RefreshBillingActions
  | GetMetrics
  | GetCreditNoteActions
  | GetBillingByClientAction
  | GetBillingWarningsActions
  | ExportPaymentsActions
