/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export interface ObjectToValidate {
  objectId: number
  disassemblyCost: number
  floors: number
  floorsCostPerFloorInCents: number
  packagingCostInCents: number
}

interface ValidateParams {
  operationId: number
  extras: {
    kmsExtra: number
    kmsExtraPrice: number
    transportExtra: number
    shipmentAssistantCount: number
    shipmentAssistantPrice: number
  }
  objects: ObjectToValidate[]
}

export function validate(params: ValidateParams): Promise<void> {
  const data = snakecaseKeys({ ...params })
  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/deposits-operations/validate`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
