import { Moment } from 'moment'
import React from 'react'

export const formatDate = (date: Moment | null, separator: '-' | '/' = '/') => {
  if (!date) return '-'
  const formattedDate = (
    <>
      {date.format(`dd DD[${separator}]MM[${separator}]YY`)}
      <br />
      {date.format('HH[:]mm')}
    </>
  )

  return date.isValid() ? (
    <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{date.isAfter('01-01-0001') ? formattedDate : '-'}</span>
  ) : (
    ''
  )
}

export const formatDateMMYY = (date: Moment | null) => {
  if (!date) return '-'

  const formattedDate = date.format('MM[/]YY')

  return date.isValid() ? (
    <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{date.isAfter('01-01-0001') ? formattedDate : '-'}</span>
  ) : (
    ''
  )
}

export const formatDateWithoutHour = (date: Moment | null, separator: '-' | '/' = '/') => {
  if (!date) return '-'
  const formattedDate = <>{date.format(`dd DD[${separator}]MM[${separator}]YY`)}</>

  return date.isValid() ? (
    <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{date.isAfter('01-01-0001') ? formattedDate : '-'}</span>
  ) : (
    ''
  )
}
