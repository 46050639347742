import { SEND_CSV_REQUEST, SendCSVState, SendCSVActions, SEND_CSV_SUCCESS, SEND_CSV_FAILURE } from '../types/sendCSV'

const initialState: SendCSVState = {
  loading: false
}

function root(state = initialState, action: SendCSVActions): SendCSVState {
  switch (action.type) {
    case SEND_CSV_REQUEST:
      return { loading: true }
    case SEND_CSV_SUCCESS:
    case SEND_CSV_FAILURE:
      return { loading: false }
    default:
      return state
  }
}

export default root
