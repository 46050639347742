import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import ResolveModalComponent from '../components/ResolveModal'
import ComplaintResolveActionCreator from '../actionCreators/resolveModal'
import { useEvents } from '@/utils/eventEmitter'
import { Events } from '@/utils/eventEmitter/events'
import { Response as PhotoResponse } from '@/projectApi/CategoryCreation/Photos/uploadPhoto'

const ResolveModal = () => {
  const dispatch = useDispatch()
  const { setOpenResolve, setReasonId, setEvidence, setConfirmationOpen, getReasons, deleteEvidence } =
    bindActionCreators(ComplaintResolveActionCreator, dispatch)
  const { open, reasonId, photos, reasons } = useSelector((state) => state.Complaints.resolve)
  const { complaint } = useSelector((state) => state.Complaints.updateView)

  const handleClose = () => {
    setOpenResolve({ open: false })
    setReasonId(0)
  }

  const onUploadedPhoto = (photos: PhotoResponse[]) => {
    const photosEvidence = photos.map((photo) => ({ id: 0, url: photo.url }))
    setEvidence(photosEvidence)
  }

  useEvents(Events.UploadPhotos.UPLOAD_PHOTO_SUCCESS, onUploadedPhoto)

  useEffect(() => {
    if (open) {
      getReasons()
    }
  }, [open])

  const ResolveProps = {
    open,
    handleClose,
    setReasonId,
    reasonId,
    setConfirmationOpen,
    complaint,
    setOpenResolve,
    photos,
    reasons,
    deleteEvidence
  }

  return <ResolveModalComponent {...ResolveProps} />
}

export default ResolveModal
