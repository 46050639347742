import { Spin } from 'antd'
import React from 'react'

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkTokenText: {
    fontSize: 16,
    marginTop: 15
  }
}

type LoadingComponentProps = {
  description: string
}

const LoadingComponent = (props: LoadingComponentProps) => {
  return (
    <div className="app" style={styles.container}>
      <Spin size="large" />
      <p style={styles.checkTokenText}>{props.description}</p>
    </div>
  )
}

export default LoadingComponent
