import React, { useEffect, useRef, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { InputTitle } from '../NewCard/NewCard'
import styles from './Input.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from './actions'
import { useLocalization } from '@/packages/localization'

type InputUploadFileProps = {
  label?: string
  maxCount?: number
  maxSize?: number // MB
  fileType: 'complaints' | 'objects' | 'products' | 'categories'
  clear?: boolean
  handleRemovePhoto: (index: number) => void
}

const Input = ({ label, maxCount = 1, maxSize, fileType, clear, handleRemovePhoto }: InputUploadFileProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const { strings } = useLocalization()

  const uploadPhotosActions = bindActionCreators(actions, dispatch)
  const uploadPhotosState = useSelector((state) => state.Components.UploadPhotos)

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const newFiles = Array.from(files).map((file) => ({
        uid: Math.random().toString(36).substring(2),
        name: file.name,
        size: file.size,
        type: file.type,
        originFileObj: new File([file], file.name, { type: file.type })
      }))

      const remainingSlots = Math.max(0, maxCount - fileList.length)
      const filesToAdd = newFiles.slice(0, remainingSlots)

      const updatedFileList = [...fileList, ...filesToAdd]
      setFileList(updatedFileList)

      const modifiedFilesForUpload = filesToAdd.map((file) => file.originFileObj)
      uploadPhotosActions.uploadPhotos(modifiedFilesForUpload, fileType)
    }
  }

  const handleRemove = (uid: string, index: number) => {
    const updatedFileList = fileList.filter((file) => file.uid !== uid)
    setFileList(updatedFileList)
    handleRemovePhoto(index)
  }

  useEffect(() => {
    if (clear) {
      setFileList([])
    }
  }, [clear])

  return (
    <>
      <InputTitle title={label || ''} />
      <section className={styles.container}>
        <article className={styles.labelWrapper}>
          {fileList.length < 1 ? (
            <span>{strings.Component.UploadPhoto.inputPlaceholder}</span>
          ) : (
            fileList.map((file, index) => (
              <article className={styles.fileLabel} key={file.uid} title={file.name}>
                {file.type.startsWith('image/') && file.originFileObj && (
                  <>
                    <Tooltip
                      overlayStyle={{ width: 'min-content' }}
                      autoAdjustOverflow
                      placement="top"
                      className={styles.photoTooltip}
                      overlayClassName={styles.overlay}
                      title={
                        <img
                          src={URL.createObjectURL(file.originFileObj)}
                          alt={file.name}
                          className={styles.imageHover}
                        />
                      }>
                      <img
                        src={URL.createObjectURL(file.originFileObj)}
                        alt={file.name}
                        className={styles.imagePreview}
                      />
                    </Tooltip>
                  </>
                )}
                <span className={styles.fileText}>{file.name}</span>

                <button className={styles.removeButton} onClick={() => handleRemove(file.uid, index)}>
                  x
                </button>
              </article>
            ))
          )}
        </article>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="image/*"
          multiple={maxCount > 1}
        />
        <Tooltip title={`Hasta ${maxCount} fotos`}>
          <Button onClick={handleButtonClick} className={styles.selectFilesBtn} disabled={fileList.length === maxCount}>
            {strings.Component.UploadPhoto.btnFile}
          </Button>
        </Tooltip>
      </section>
      {uploadPhotosState.error && <p className={styles.error}>{uploadPhotosState.error}</p>}
    </>
  )
}

export default Input
