import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import { PAGE_SIZE } from '../reducers/depositsTable'
import {
  CorporativeDepositsSearchFilter,
  GetDepositListRequest,
  GetDepositListSuccess,
  GetCorporativeDepositListTypes,
  RESET_FILTERS,
  GetCorporativeDepositMetricsTypes
} from '../types/depositsTable'

const CoporativeDepositListActionsCreator = {
  getDeposits({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: CorporativeDepositsSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetDepositListRequest = {
        type: GetCorporativeDepositListTypes.GET_DEPOSIT_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { deposits, total } = await API.TransactionHandler.Deposits.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          deprecated: false
        })

        const success: GetDepositListSuccess = {
          type: GetCorporativeDepositListTypes.GET_DEPOSIT_SUCCESS,
          payload: {
            deposits,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetCorporativeDepositListTypes.GET_DEPOSIT_FAILURE, dispatch)
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.CorporateDeposits.CLEAN_FILTERS), 50)
    }
  },

  getMetrics({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: CorporativeDepositsSearchFilter[]
  }): AppThunk {
    return async (dispatch) => {
      dispatch({ type: GetCorporativeDepositMetricsTypes.GET_METRICS_REQUEST })
      try {
        const { locationsMetrics } = await API.TransactionHandler.Location.Corporative.metrics({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters)
        })
        dispatch({
          type: GetCorporativeDepositMetricsTypes.GET_METRICS_SUCCESS,
          payload: {
            depositsMetrics: locationsMetrics
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetCorporativeDepositMetricsTypes.GET_METRICS_FAILURE, dispatch)
      }
    }
  }
}

export default CoporativeDepositListActionsCreator
