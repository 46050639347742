export const OPEN_CHECKOUT_MODAL = 'USERS/CHECKOUT/OPEN_CHECKOUT_MODAL'
export const CLOSE_CHECKOUT_MODAL = 'USERS/CHECKOUT/CLOSE_CHECKOUT_MODAL'

export const GENERATE_CHECKOUT_LINK_REQUEST = 'USERS/CHECKOUT/GENERATE_CHECKOUT_LINK_REQUEST'
export const GENERATE_CHECKOUT_LINK_SUCCESS = 'USERS/CHECKOUT/GENERATE_CHECKOUT_LINK_SUCCESS'
export const GENERATE_CHECKOUT_LINK_FAILURE = 'USERS/CHECKOUT/GENERATE_CHECKOUT_LINK_FAILURE'

export const GET_EXISTING_CHECKOUT_LINK_REQUEST = 'USERS/CHECKOUT/GET_EXISTING_CHECKOUT_LINK_REQUEST'
export const GET_EXISTING_CHECKOUT_LINK_SUCCESS = 'USERS/CHECKOUT/GET_EXISTING_CHECKOUT_LINK_SUCCESS'
export const GET_EXISTING_CHECKOUT_LINK_FAILURE = 'USERS/CHECKOUT/GET_EXISTING_CHECKOUT_LINK_FAILURE'

export type OpenCheckoutModal = {
  type: typeof OPEN_CHECKOUT_MODAL
  payload: {
    id: number
    name: string
    lastName: string
    country: string
  }
}

export type CloseCheckoutModal = {
  type: typeof CLOSE_CHECKOUT_MODAL
}

export type GenerateCheckoutLinkRequest = {
  type: typeof GENERATE_CHECKOUT_LINK_REQUEST
}

export type GenerateCheckoutLinkSuccess = {
  type: typeof GENERATE_CHECKOUT_LINK_SUCCESS
  payload: {
    link: string
  }
}

export type GenerateCheckoutLinkFailure = {
  type: typeof GENERATE_CHECKOUT_LINK_FAILURE
  payload: {
    error: string
  }
}

export type GetExistingCheckoutLinkRequest = {
  type: typeof GET_EXISTING_CHECKOUT_LINK_REQUEST
}

export type GetExistingCheckoutLinkSuccess = {
  type: typeof GET_EXISTING_CHECKOUT_LINK_SUCCESS
  payload: {
    exists: boolean
    link: string
  }
}

export type GetExistingCheckoutLinkFailure = {
  type: typeof GET_EXISTING_CHECKOUT_LINK_FAILURE
  payload: {
    error: string
  }
}

export type GenerateCheckoutResponse = {
  code: string
  id: number
  urlCheckout: string
  userId: number
}

export type GenerateCheckoutLinkState = {
  generated: boolean
  userId: number
  userName: string
  userLastName: string
  userCountry: string
  open: boolean
  link: string
  loading: boolean
  loadingExistingLink: boolean
  error: string
}

export enum OperationEnum {
  CHECKOUT = 'checkout',
  DIRECT_DEBIT = 'direct_debit'
}

export type GenerateCheckoutLinkActions =
  | OpenCheckoutModal
  | CloseCheckoutModal
  | GenerateCheckoutLinkRequest
  | GenerateCheckoutLinkSuccess
  | GenerateCheckoutLinkFailure
  | GetExistingCheckoutLinkRequest
  | GetExistingCheckoutLinkSuccess
  | GetExistingCheckoutLinkFailure
