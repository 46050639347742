import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APIBilling, Billing, formatBilling } from './common'

export interface APIResponse {
  results: APIBilling[]
  paging: {
    total: number
    limit: number
    offset: number
  }
}

interface Response {
  billings: Billing[]
  total: number
}

type ListParams = {
  limit: number
  offset: number
  userId: number
  authorizationEnabled: boolean
  startDate?: string
  endDate?: string
  sourceTypes?: string
  withoutDetails?: boolean
  withoutApplication?: boolean
}

export function list(params: ListParams): Promise<Response> {
  const snakeParams = snakecaseKeys({ ...params })
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billings/search`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.results) {
        throw new Error('Respuesta incorrecta al obtener los datos de facturación')
      }
      const camelResponse: Response = {
        total: response.data.paging.total,
        billings: response.data.results.map((billing) => formatBilling(billing))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
