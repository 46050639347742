export type DepositsValidationMetrics = {
  id: string
  metric: string
  quantity: number
}

export type DepositsValidationMetricsState = {
  quantityMetrics: DepositsValidationMetrics[]
  fetchingMetrics: boolean
}

export enum GetDepositsValidationMetricsTypes {
  GET_DEPOSITS_VALIDATION_METRICS_REQUEST = 'DEPOSITS_VALIDATION/METRICS/GET_METRICS_REQUEST',
  GET_DEPOSITS_VALIDATION_METRICS_SUCCESS = 'DEPOSITS_VALIDATION/METRICS/GET_METRICS_SUCCESS',
  GET_DEPOSITS_VALIDATION_METRICS_FAILURE = 'DEPOSITS_VALIDATION/METRICS/GET_METRICS_FAILURE'
}

export interface GetDepositsValidationMetricsRequest {
  type: typeof GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_REQUEST
}

export interface GetDepositsValidationMetricsSuccess {
  type: typeof GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_SUCCESS
  payload: {
    quantityMetrics: DepositsValidationMetrics[]
  }
}

export interface GetDepositsValidationMetricsFailure {
  type: typeof GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_FAILURE
}

type GetDepositsValidationMetricsActions =
  | GetDepositsValidationMetricsRequest
  | GetDepositsValidationMetricsSuccess
  | GetDepositsValidationMetricsFailure

export type DepositsValidationMetricsActions = GetDepositsValidationMetricsActions
