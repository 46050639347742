import { Col, Modal, Row, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import styles from './changeEmailModal.module.scss'
import useDebounce from '../../../utils/hooks/useDebounce'

export type UserData = {
  id: number
  name: string
  lastName: string
  currentEmail: string
}

export type ChangeEmailModalProps = {
  open: boolean
  userData: UserData
  newEmail: string
  emailConfirmation: string
  errorNewEmail: string
  errorEmailConfirmation: string
  successNewEmail: string
  messageAvailableEmailConfirmation: string
  okDisabled: boolean
  fetchingCards: boolean
  errorFetchCards: string
  hasMPCards: boolean
  loading: boolean
  setNewEmail: (email: string) => void
  setErrorEmailConfirmation: (message: string) => void
  setEmailConfirmation: (email: string) => void
  setMessageAvailableEmailConfirmation: (message: string) => void
  handleCloseModal: () => void
  handleConfirm: () => void
}

const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({
  open,
  userData,
  newEmail,
  emailConfirmation,
  errorNewEmail,
  errorEmailConfirmation,
  successNewEmail,
  messageAvailableEmailConfirmation,
  okDisabled,
  loading,
  fetchingCards,
  errorFetchCards,
  hasMPCards,
  setNewEmail,
  setErrorEmailConfirmation,
  setEmailConfirmation,
  setMessageAvailableEmailConfirmation,
  handleCloseModal,
  handleConfirm
}) => {
  const emailRef = useRef(null)

  return (
    <Modal
      width={'80%'}
      destroyOnClose
      okText={'Modificar'}
      cancelText={'Cancelar'}
      onOk={handleConfirm}
      okButtonProps={{ disabled: okDisabled }}
      centered
      confirmLoading={loading}
      visible={open}
      onCancel={handleCloseModal}
      title="Modificación email de usuario">
      <UserCard
        id={userData?.id}
        name={userData?.name}
        lastName={userData?.lastName}
        currentEmail={userData?.currentEmail}
      />
      {fetchingCards || hasMPCards ? (
        <CheckedHasCard errorMessage={errorFetchCards} fetchingCards={fetchingCards} />
      ) : (
        <Row gutter={[16, 16]}>
          <Col sm={12} xs={24}>
            <DebouncedInputFieldWithError
              value={newEmail}
              errorMessage={errorNewEmail}
              successMessage={successNewEmail}
              setValue={setNewEmail}
            />
          </Col>
          <Col sm={12} xs={24}>
            <InputFieldWithError
              ref={emailRef}
              label="Repetir nuevo email"
              value={emailConfirmation}
              onChange={(e) => {
                setErrorEmailConfirmation('')
                setMessageAvailableEmailConfirmation('')
                setEmailConfirmation(e.target.value)
              }}
              error={errorEmailConfirmation}
              loading={false}
              successMessage={messageAvailableEmailConfirmation}
              required
            />
          </Col>
        </Row>
      )}
    </Modal>
  )
}

export default ChangeEmailModal

const InfoItem: React.FC<{ data: number | string; label: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.infoItemTitle}>{label}</span>
    {data}
  </div>
)

const UserCard: React.FC<UserData> = ({ id, name, lastName, currentEmail }) => (
  <header className={styles.userCard}>
    <h3 className={styles.TitleuserCard}>Datos del usuario:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
      <InfoItem data={lastName} label={'Apellido'} />
      <InfoItem data={currentEmail} label={'Email actual'} />
    </div>
  </header>
)

const DebouncedInputFieldWithError: React.FC<{
  value: string
  errorMessage: string
  successMessage: string
  setValue: (newValue: string) => void
}> = ({ value, errorMessage, successMessage, setValue }) => {
  const [localValue, setLocalValue] = useState(value)
  const debouncedValue = useDebounce(localValue, 500)

  useEffect(() => {
    setValue(debouncedValue)
  }, [debouncedValue])

  return (
    <InputFieldWithError
      label="Nuevo Email"
      value={localValue}
      onChange={(e) => {
        setLocalValue(e.target.value)
      }}
      successMessage={successMessage}
      error={errorMessage}
      loading={false}
      required
    />
  )
}

const CheckedHasCard = ({ errorMessage, fetchingCards }: { errorMessage: string; fetchingCards: boolean }) => {
  const message =
    errorMessage ||
    `El usuario posee tarjetas que no permiten el cambio de email. Comuníquese con soporte para resolverlo.`

  return (
    <div className={styles.checkedHasCard}>
      {fetchingCards ? (
        <Spin size="default" style={{ display: 'flex', alignItems: 'center' }} />
      ) : (
        <h3 className={errorMessage ? styles.hasCardError : ''}>{message}</h3>
      )}
    </div>
  )
}
