import React from 'react'
import { Col, Input, Row } from 'antd'
import { Attribute } from '../../../projectApi/ObjectAdministration/Attribute/list'
import { SelectedAttribute } from '../types/edit'
import Select from '../../../components/Select'
import { Trash } from '../../../icons'
import styles from './attributeComponent.module.scss'

interface AttributeComponentProps {
  country: string
  loadingProductAttributes: boolean
  attributes: Attribute[]
  selectedAttribute: SelectedAttribute
  setAttributeType: (index: number, type: number) => void
  setAttributeValue: (index: number, value: string) => void
  deleteAttribute: (index: number) => void
}

const AttributeComponent = (props: AttributeComponentProps) => {
  const {
    attributes,
    country,
    loadingProductAttributes,
    selectedAttribute,
    setAttributeType,
    setAttributeValue,
    deleteAttribute
  } = props

  const { realIndex } = selectedAttribute

  const attributeSelected = Boolean(selectedAttribute.attributeId)

  const attributeOptions = attributes.map((attribute) => ({
    label: attribute.name,
    value: attribute.id
  }))
  const selectedAttributeOption = attributeOptions.find(
    (attribute) => attribute.value === selectedAttribute.attributeId
  )
  const productAttribute = attributes.find((attribute) => attribute.id === selectedAttribute.attributeId)

  return (
    <Row gutter={[16, 16]} justify="center">
      <Col span={10}>
        <Select
          loading={loadingProductAttributes}
          position="top"
          options={attributeOptions}
          selected={selectedAttributeOption}
          placeholder="Seleccionar atributo"
          disabled={Boolean(selectedAttributeOption)}
          onSelect={(option) => setAttributeType(realIndex, option.value)}
        />
      </Col>
      <Col span={10}>
        {attributeSelected && (
          <AttributeValueComponent
            country={country}
            index={realIndex}
            productAttribute={productAttribute}
            selectedAttribute={selectedAttribute}
            setAttributeValue={setAttributeValue}
          />
        )}
      </Col>
      <Col span={4}>
        {attributeSelected && (
          <span className={styles.deleteIcon} onClick={() => deleteAttribute(realIndex)}>
            <Trash />
          </span>
        )}
      </Col>
    </Row>
  )
}

type AttributeValueComponentProps = {
  country: string
  index: number
  productAttribute?: Attribute
  selectedAttribute: SelectedAttribute
  setAttributeValue: (index: number, value: string) => void
}

const AttributeValueComponent = (props: AttributeValueComponentProps) => {
  const { country, index, productAttribute, selectedAttribute, setAttributeValue } = props

  if (!productAttribute) return null
  if (productAttribute.dataType === 'options') {
    const options = productAttribute.countries.find((c) => c.countryId === country)?.options || []
    const formattedOptions = options.map((option) => ({
      label: option.value,
      value: option.value
    }))
    return (
      <Select
        options={formattedOptions}
        selected={formattedOptions.find((option) => option.value === selectedAttribute.value)}
        placeholder="Seleccionar valor"
        onSelect={(option) => setAttributeValue(index, option.value)}
      />
    )
  }

  if (productAttribute.dataType === 'boolean') {
    const booleanOptions = [
      { label: 'SÍ', value: 'true' },
      { label: 'NO', value: 'false' }
    ]
    return (
      <Select
        options={booleanOptions}
        selected={booleanOptions.find((option) => option.value === selectedAttribute.value)}
        placeholder="Seleccionar valor"
        onSelect={(option) => setAttributeValue(index, option.value)}
      />
    )
  }

  if (productAttribute.dataType === 'number') {
    return (
      <Input
        min={0}
        value={selectedAttribute.value}
        onChange={(e) => setAttributeValue(index, e.target.value)}
        placeholder="Ingresar valor*"
      />
    )
  }

  return (
    <Input
      value={selectedAttribute.value}
      onChange={(e) => setAttributeValue(index, e.target.value)}
      placeholder="Ingresar valor*"
    />
  )
}

export default AttributeComponent
