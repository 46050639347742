/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { ObjectSituationKeys } from '../../../sections/Objects/constants/objectSituations'

export interface BulkUpdateParams {
  objectIds: number[]
  name?: string
  description?: string
  ownerId?: number
  objectSituation?: ObjectSituationKeys | null
  objectSituationReason?: string
  objectStatusId?: string
  product?: {
    id: number
    weightInGr: number
    lengthInCm: number
    widthInCm: number
    heightInCm: number
  }
}

export function bulkUpdate(params: BulkUpdateParams): Promise<void> {
  const data = {
    object_ids: params.objectIds,
    object: {
      name: params.name,
      description: params.description,
      owner_id: params.ownerId,
      object_situation: params.objectSituation,
      object_situation_reason: params.objectSituationReason,
      object_status_id: params.objectStatusId,
      ...(params.product
        ? {
            product_id: params.product.id,
            weight_in_gr: params.product.weightInGr,
            length_in_cm: params.product.lengthInCm,
            width_in_cm: params.product.widthInCm,
            height_in_cm: params.product.heightInCm
          }
        : {})
    }
  }
  return apiPrivate
    .put(`${URL_BASE_OBJECTS}/object/bulk-update`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
