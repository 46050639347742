import { CountryIdCode, CountryName } from '../../../components/CountrySelector/constants/constants'
import { Flag } from '../../../projectApi/ObjectAdministration/Value/getValue'

export const getCustomDateTransportCountry = (countryCode: CountryIdCode): Flag => {
  switch (countryCode) {
    case CountryIdCode.SPAIN:
      return Flag.CUSTOM_DATE_TRANSPORT_MULT_ESP
    case CountryIdCode.ARGENTINA:
    default:
      return Flag.CUSTOM_DATE_TRANSPORT_MULT_ARG
  }
}

export const getFlagsByCountry = (countryCode: CountryIdCode) => {
  switch (countryCode) {
    case CountryIdCode.SPAIN:
      return {
        extraAssistantPrice: Flag.EXTRA_ASSISTANT_PRICE_ESP
      }
    case CountryIdCode.ARGENTINA:
    default:
      return {
        extraAssistantPrice: Flag.EXTRA_ASSISTANT_PRICE_ARG
      }
  }
}

export const getSpanishNameByCountry = (countryCode: CountryIdCode) => {
  switch (countryCode) {
    case CountryIdCode.SPAIN:
      return CountryName.SPAIN
    case CountryIdCode.ARGENTINA:
    default:
      return CountryName.ARGENTINA
  }
}
