import React from 'react'
import AMModalProviders from '../components/AMProviders'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ABMProvidersActionsCreator from '../actions/ABMProviders'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import DeleteProviderModal from '../components/DeleteProvider'

const ABMProvidersContainer = () => {
  const dispatch = useDispatch()

  const {
    setModalVisibility,
    setProviderName,
    createProvider,
    setDeleteModalVisibility,
    editProvider,
    deleteProvider
  } = bindActionCreators(ABMProvidersActionsCreator, dispatch)

  const {
    modalVisible,
    providerId,
    providerName,
    selectedProvider,
    error,
    loadingProvider,
    deleteModalVisible,
    editMode
  } = useSelector((state) => state.ABMProviderModalReducer)

  useEvents([Events.Provider.CREATE_PROVIDER, Events.Provider.EDIT_PROVIDER], () => {
    setModalVisibility({ modalVisible: false })
  })

  useEvents([Events.Provider.DELETE_PROVIDER], () => {
    setDeleteModalVisibility(false)
  })

  const disabledSuccessButton = loadingProvider || !providerName

  const handleCreateProvider = () => {
    if (editMode) {
      editProvider({
        providerId,
        providerName
      })
      return
    }
    createProvider({
      providerName
    })
  }

  const handleDeleteProvider = () => {
    deleteProvider({ providerId })
  }

  const deleteProviderModalProps = {
    selectedProvider,
    loadingProvider,
    deleteModalVisible,
    setDeleteModalVisibility,
    handleDeleteProvider,
    error
  }

  const ABMModalProviderProps = {
    modalVisible,
    editMode,
    providerName,
    disabledSuccessButton,
    setModalVisibility,
    setProviderName,
    handleCreateProvider,
    error
  }

  return (
    <>
      <AMModalProviders {...ABMModalProviderProps} />
      <DeleteProviderModal {...deleteProviderModalProps} />
    </>
  )
}

export default ABMProvidersContainer
