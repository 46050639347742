import { Reducer } from 'redux'
import {
  ADD_ATTRIBUTE,
  AttributeModalActions,
  AttributeModalState,
  CLOSE_ATTRIBUTE_MODAL,
  GET_ATTRIBUTES_BY_PRODUCT_FAILURE,
  GET_ATTRIBUTES_BY_PRODUCT_REQUEST,
  GET_ATTRIBUTES_BY_PRODUCT_SUCCESS,
  GET_ATTRIBUTES_FAILURE,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  OPEN_ATTRIBUTE_MODAL,
  ProductAttribute,
  REMOVE_ATTRIBUTE,
  SET_ERROR,
  UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE,
  UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST,
  UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS
} from '../types/attributeModal'

const initialState: AttributeModalState = {
  open: false,
  loading: false,
  productId: null,
  productAttributes: [],
  attributes: [],
  error: ''
}

const AttributeModalReducer: Reducer<AttributeModalState, AttributeModalActions> = (
  state = initialState,
  action
): AttributeModalState => {
  switch (action.type) {
    case OPEN_ATTRIBUTE_MODAL:
      return {
        ...state,
        productId: action.payload.productId,
        open: true
      }
    case CLOSE_ATTRIBUTE_MODAL:
      return initialState
    case ADD_ATTRIBUTE:
      return {
        ...state,
        productAttributes: [...state.productAttributes, action.payload.productAttribute]
      }
    case REMOVE_ATTRIBUTE:
      return {
        ...state,
        productAttributes: state.productAttributes.reduce((acc, curr) => {
          if (curr.attribute.id === action.payload.attributeId) {
            if (curr.isNew) return acc
            return [...acc, { ...curr, deleted: true }]
          }
          return [...acc, curr]
        }, [] as ProductAttribute[])
      }
    case GET_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        attributes: action.payload.attributes
      }
    case GET_ATTRIBUTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case GET_ATTRIBUTES_BY_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ATTRIBUTES_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        productAttributes: action.payload.productAttributes,
        loading: false
      }
    case GET_ATTRIBUTES_BY_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false
      }
    case UPDATE_ATTRIBUTES_FROM_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_ATTRIBUTES_FROM_PRODUCT_SUCCESS:
      return initialState
    case UPDATE_ATTRIBUTES_FROM_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default AttributeModalReducer
