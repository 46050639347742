import React from 'react'
import styles from './CustomTooltip.module.scss'
import Divisor from '../../../Divisor'

const CustomTooltip: React.FC<{ active?: boolean; payload?: any; ref?: any }> = ({ active, payload, ref }) => {
  if (active && payload && payload.length) {
    const fullMonth = payload[0].payload.fullMonth
    const currentYear = new Date().getFullYear()

    return (
      <section className={styles.customTooltip}>
        <p className={styles.legendTitle}>
          {fullMonth} {currentYear}
        </p>
        <Divisor orientation="horizontal" margin={'5px 0'} />
        <article className={styles.containerResult}>
          {payload.map((entry: any) => (
            <div key={entry.dataKey} className={styles.wrapperResult}>
              <div className={styles.legendText}>
                <div
                  className={styles.legendCircle}
                  style={{
                    backgroundColor: entry.color
                  }}
                />
                <span
                  className={styles.legendLabel}
                  style={{
                    color: entry.color
                  }}>
                  {entry.name}
                </span>
              </div>
              <span
                style={{
                  color: entry.color
                }}
                className={styles.legendValue}>
                {entry.value}
              </span>
            </div>
          ))}
        </article>
      </section>
    )
  }
  return null
}

export default CustomTooltip
