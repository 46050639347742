import snakecaseKeys from 'snakecase-keys'
import { apiSpreedly } from '../api'
import { URL_BASE_SPREEDLY } from '../endpoints'
import config from '../config'

type PaymentMethod = {
  token: string
  created_at: string
  updated_at: string
  email: string
  storage_state: string
  test: boolean
  metadata: any
  last_four_digits: string
  first_six_digits: string
  card_type: string
  first_name: string
  last_name: string
  month: number
  year: number
  fingerprint: string
  verification_value: string
  number: string
}

type SpreedlyTransaction = {
  transaction: {
    token: string
    created_at: string
    updated_at: string
    succeeded: boolean
    transaction_type: string
    retained: boolean
    state: string
    message_key: string
    message: string
    payment_method?: PaymentMethod
  }
}

type Params = {
  number: string
  fullName: string
  verificationValue: string
  month: number
  year: number
}

export const createSpreedlyCardToken = async (params: Params): Promise<SpreedlyTransaction> => {
  const response = await apiSpreedly.post(
    URL_BASE_SPREEDLY + `/v1/payment_methods.json?environment_key=${config.SPREEDLY_ENVIRONMENT_KEY}`,
    {
      payment_method: {
        credit_card: snakecaseKeys(params),
        retained: true
      }
    }
  )
  return response.data
}
