export default {
  URL: process.env.REACT_APP_URL,
  ENV: process.env.NODE_ENV,
  MERCADOPAGO_PUBLIC_KEY: process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY,
  WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL,
  LANDING_URL: process.env.REACT_APP_LANDING_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  CHECKOUT_URL: process.env.REACT_APP_CHECKOUT_URL,
  SPREEDLY_ENVIRONMENT_KEY: process.env.REACT_APP_SPREEDLY_ENVIRONMENT_KEY || ''
}
