import { AppThunk } from '@/store'
import { AddNewCBUModalTypes, CBUModalCommonTypes, SetAsMain, UpdateCBUModalTypes, UserInfo } from '../types/cbusModal'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import { API } from '@/projectApi'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import { AddCBUParams } from '@/projectApi/UserProfile/Admin/CBU/add'
import { APICBU } from '@/projectApi/UserProfile/Admin/CBU/common'
import { Events } from '@/utils/eventEmitter/events'
import Emitter from '@/utils/eventEmitter'
import { Either } from '@/utils/utilityTypes'
import { UpdateCBUParams } from '@/projectApi/UserProfile/Admin/CBU/update'
import CBUComponentActionsCreator from '@/components/CBU/actions'

const CBUModalActionsCreator = {
  setModalVisibility:
    ({
      isVisible,
      editMode,
      currentCU,
      userInfo
    }: { isVisible: boolean } & Either<{ userInfo?: UserInfo }, { editMode: boolean; currentCU: APICBU }>): AppThunk =>
    (dispatch) => {
      dispatch({
        type: CBUModalCommonTypes.SET_MODAL_VISIBILITY,
        payload: { isVisible, editMode, currentCU, userInfo }
      })

      if (currentCU !== undefined) dispatch(CBUComponentActionsCreator.populateForm(currentCU))
      if (userInfo !== undefined) dispatch(CBUComponentActionsCreator.setUserInfo(userInfo.fullName))
      if (!isVisible) dispatch(CBUComponentActionsCreator.cleanCBU())
    },

  setAsMain: ({ check }: { check: boolean }): SetAsMain => ({
    type: CBUModalCommonTypes.SET_AS_MAIN,
    payload: { check }
  }),

  addNewCBU:
    (params: AddCBUParams): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Agregando nueva CBU/CVU, por favor espere...')

      dispatch({
        type: AddNewCBUModalTypes.ADD_NEW_CBU_REQUEST
      })

      try {
        await API.UserProfile.admin.CBU.add(params)

        setToastSuccessUpdate(toastId, { render: 'CBU/CVU agregada correctamente' })
        dispatch({
          type: AddNewCBUModalTypes.ADD_NEW_CBU_SUCCESS
        })
        Emitter.emit(Events.CBU.CREATED_CBU)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, AddNewCBUModalTypes.ADD_NEW_CBU_FAILURE, dispatch)
        })
      }
    },

  updateCBU:
    (params: UpdateCBUParams): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Actualizando CBU/CVU, por favor espere...')

      dispatch({ type: UpdateCBUModalTypes.UPDATE_CBU_REQUEST })

      try {
        await API.UserProfile.admin.CBU.update(params)

        setToastSuccessUpdate(toastId, { render: 'CBU/CVU actualizada correctamente' })
        dispatch({ type: UpdateCBUModalTypes.UPDATE_CBU_SUCCESS })
        Emitter.emit(Events.CBU.UPDATED_CBU)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, UpdateCBUModalTypes.UPDATE_CBU_FAILURE, dispatch)
        })
      }
    }
}

export default CBUModalActionsCreator
