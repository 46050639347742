export enum CorporateDepositsEnum {
  CLEAN_FILTERS = 'CORPORATIVE_DEPOSITS/CLEAN_FILTERS',
  CREATE_DEPOSIT = 'CORPORATIVE_DEPOSITS/CREATE_DEPOSIT',
  DELETE_DEPOSIT = 'CORPORATIVE_DEPOSITS/DELETE_DEPOSIT',
  EDIT_DEPOSIT = 'CORPORATIVE_DEPOSITS/EDIT_DEPOSIT'
}

export type CorporateDepositsEvents = {
  [CorporateDepositsEnum.CLEAN_FILTERS]: () => void
  [CorporateDepositsEnum.CREATE_DEPOSIT]: () => void
  [CorporateDepositsEnum.DELETE_DEPOSIT]: () => void
  [CorporateDepositsEnum.EDIT_DEPOSIT]: () => void
}
