import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

import {
  OPEN_MODAL_EMAIL_BILLED,
  OpenModalEmailBilled,
  SEND_EMAIL_BILLED_FAILURE,
  SEND_EMAIL_BILLED_REQUEST,
  SEND_EMAIL_BILLED_SUCCESS,
  SendEmailBilledRequest,
  SendEmailBilledSuccess
} from '../types/emailBilledModal'

export const EmailBilledModalActionCreator = {
  sendEmailBilled:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: SendEmailBilledRequest = {
        type: SEND_EMAIL_BILLED_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Enviando Email...')

      try {
        await API.TransactionHandler.Billings.sendEmailBilled(billingId)

        const success: SendEmailBilledSuccess = {
          type: SEND_EMAIL_BILLED_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, { render: handleErrorsWithAction(error, SEND_EMAIL_BILLED_FAILURE, dispatch) })
      }
    },
  openEmailBilledModal: ({
    modalOpen,
    billingId
  }: {
    modalOpen: boolean
    billingId?: number
  }): OpenModalEmailBilled => ({
    type: OPEN_MODAL_EMAIL_BILLED,
    payload: { billingId, modalOpen }
  })
}

export default EmailBilledModalActionCreator
