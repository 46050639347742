import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import moment, { Moment } from 'moment'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export interface Provider {
  id: number
  name: string
}

interface APIShipper {
  id: number
  user_id: number
  created_at: string
  updated_at: string
  deleted_at?: string | null
  provider: Provider
  name: string
  last_name: string
}

export type Shipper = Omit<CamelCasedPropertiesDeep<APIShipper>, 'created_at' | 'updated_at' | 'deleted_at'> & {
  createdAt: Moment
  updatedAt: Moment
  deletedAt?: Moment
}

interface APIResponse {
  shippers: APIShipper[]
  total: number
}

type Response = Omit<CamelCasedPropertiesDeep<APIResponse>, 'shippers'> & {
  shippers: Shipper[]
}

type paramsList = {
  format?: 'csv'
  limit?: number
  offset?: number
  column?: string
  order?: 'asc' | 'desc'
  id?: number
  userId?: number
  providerId?: number
}

const emptyResponse = {
  shippers: [],
  total: 0
}

export function list(params: paramsList): Promise<Response> {
  const formattedParams = snakecaseKeys(params)

  return apiPrivate
    .get(`${URL_BASE_TRIPS}/shipper`, { params: formattedParams })
    .then(({ data }: AxiosResponse<APIResponse>) => {
      if (!data.shippers) return emptyResponse

      const shippers = camelcaseKeys(data.shippers, { deep: true }).map((shipper) => ({
        ...shipper,
        createdAt: moment(shipper.createdAt),
        updatedAt: moment(shipper.updatedAt),
        deletedAt: shipper.deletedAt ? moment(shipper.deletedAt) : undefined
      })) as Shipper[]

      return {
        shippers,
        total: data.total
      }
    })
    .catch((error) => {
      throw error
    })
}
