import React, { useRef, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { Calendar } from './Calendar'
import { CalendarDay, CalendarProps } from './calendar.types'
import { TextField } from '../TextField/TextField'
import styles from './Calendar.module.scss'
import { useOutsideClick } from '../../utils/hooks/useOutsideClick'

const CalendarTextField = (props: CalendarProps) => {
  const [visible, setVisible] = useState(false)
  const handleDayPress = (day: CalendarDay) => {
    setVisible(false)
    props.handleDayPress(day)
  }

  const wrapperRef = useRef(null)

  useOutsideClick([wrapperRef], () => setVisible(false))

  return (
    <div className={styles.labelCalendar}>
      <Dropdown
        visible={visible}
        placement="topRight"
        overlay={
          <div ref={wrapperRef}>
            <Menu style={{ padding: 16 }}>
              <Calendar {...props} handleDayPress={handleDayPress} />
            </Menu>
          </div>
        }>
        <TextField
          readOnly
          label={props.isDeposit ? 'Fecha de ingreso' : 'Fecha de retiro'}
          value={props.localDate?.date?.format('DD/MM/YYYY') || ''}
          onChange={() => {}}
          onFocus={() => setVisible(!visible)}
        />
      </Dropdown>
    </div>
  )
}

export default CalendarTextField
