import { BaseOption } from '../../../../components/Select/Select'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'
import { LeafCategory } from '../../../../projectApi/CategoryCreation/getCategories'

export type CategoryOption = BaseOption<number>

export type ProductFormFields = {
  name: string
  dimmensionsWidthInCm: string
  dimmensionsHeightInCm: string
  dimmensionsLengthInCm: string
  dimmensionsWeightInKg: string
  deviationMinPercentage: string
  deviationMaxPercentage: string
  multimediaMinPhotos: string
  multimediaPhotoUrl: string
  multimediaPhotoKey: string
}

export type AMBCorporateProductsState = {
  editMode: boolean
  deleteModalVisible: boolean
  creatingProduct: boolean
  updatingProduct: boolean
  deletingProduct: boolean
  fetchingCategories: boolean
  disabledDeleteButton: boolean
  categories: LeafCategory[]
  selectedProduct?: FormattedProduct
  selectedCategory?: LeafCategory
  selectedCategoryOption?: CategoryOption
  productFormFields: ProductFormFields
}

export enum AMProductsTypes {
  SET_EDIT_MODE = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/SET_EDIT_MODE',
  SET_FORM_FIELD = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/SET_FORM_FIELD',
  SET_CATEGORY = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/SET_CATEGORY',
  CLEAN_STATE = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/CLEAN_STATE',
  SET_DELETE_MODAL_VISIBILITY = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/SET_DELETE_MODAL_VISIBILITY'
}

export interface SetEditMode {
  type: AMProductsTypes.SET_EDIT_MODE
  payload: {
    editMode: boolean
    selectedProduct?: FormattedProduct
  }
}

export interface SetFormField {
  type: AMProductsTypes.SET_FORM_FIELD
  payload: {
    field: keyof ProductFormFields
    value: string
  }
}

export interface SetCategory {
  type: AMProductsTypes.SET_CATEGORY
  payload: {
    selectedCategory?: CategoryOption
  }
}

export interface CleanState {
  type: AMProductsTypes.CLEAN_STATE
}

export interface SetDeleteModalVisible {
  type: AMProductsTypes.SET_DELETE_MODAL_VISIBILITY
  payload: {
    deleteModalVisible: boolean
    currentProduct?: FormattedProduct
  }
}

type AMProductsActions = SetEditMode | SetFormField | SetCategory | CleanState | SetDeleteModalVisible

export enum GetCategoriesProductsTypes {
  GET_CATEGORIES_REQUEST = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/GET_CATEGORIES_FAILURE'
}

export interface GetCategoriesRequest {
  type: GetCategoriesProductsTypes.GET_CATEGORIES_REQUEST
}

export interface GetCategoriesSuccess {
  type: GetCategoriesProductsTypes.GET_CATEGORIES_SUCCESS
  payload: {
    categories: LeafCategory[]
  }
}

export interface GetCategoriesFailure {
  type: GetCategoriesProductsTypes.GET_CATEGORIES_FAILURE
}

type GetCategoriesActions = GetCategoriesRequest | GetCategoriesSuccess | GetCategoriesFailure

export enum CreateProductTypes {
  CREATE_PRODUCT_REQUEST = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/CREATE_PRODUCT_FAILURE'
}

export interface CreateProductRequest {
  type: CreateProductTypes.CREATE_PRODUCT_REQUEST
}

export interface CreateProductSuccess {
  type: CreateProductTypes.CREATE_PRODUCT_SUCCESS
}

export interface CreateProductFailure {
  type: CreateProductTypes.CREATE_PRODUCT_FAILURE
}

type CreateProductActions = CreateProductRequest | CreateProductSuccess | CreateProductFailure

export enum UpdateProductTypes {
  UPDATE_PRODUCT_REQUEST = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/UPDATE_PRODUCT_FAILURE'
}

export interface UpdateProductRequest {
  type: UpdateProductTypes.UPDATE_PRODUCT_REQUEST
}

export interface UpdateProductSuccess {
  type: UpdateProductTypes.UPDATE_PRODUCT_SUCCESS
}

export interface UpdateProductFailure {
  type: UpdateProductTypes.UPDATE_PRODUCT_FAILURE
}

type UpdateProductActions = UpdateProductRequest | UpdateProductSuccess | UpdateProductFailure

export enum DeleteProductTypes {
  DELETE_PRODUCT_REQUEST = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE = 'CORPORATE/PRODUCTS/ABM_PRODUCTS/DELETE_PRODUCT_FAILURE'
}

export interface DeleteProductRequest {
  type: DeleteProductTypes.DELETE_PRODUCT_REQUEST
}

export interface DeleteProductSuccess {
  type: DeleteProductTypes.DELETE_PRODUCT_SUCCESS
}

export interface DeleteProductFailure {
  type: DeleteProductTypes.DELETE_PRODUCT_FAILURE
}

type DeleteProductActions = DeleteProductRequest | DeleteProductSuccess | DeleteProductFailure

export type AMBCorporativeProductsActions =
  | AMProductsActions
  | GetCategoriesActions
  | CreateProductActions
  | UpdateProductActions
  | DeleteProductActions
