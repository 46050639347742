import { Deposit } from '../../../../projectApi/TransactionHandler/Deposits/common'
import { Pagination, SearchFilter } from '../../../../utils/searchFilterUtils'
import { LocationsMetrics } from '../../Locations/types/locationsTable'

export type CoporativeDepositsTableSearchKey = 'depositId' | 'name'
export type CorporativeDepositsSearchFilter = SearchFilter<CoporativeDepositsTableSearchKey>

export type CorporativeDepositListStateType = {
  fetchingDeposits: boolean
  fetchingMetrics: boolean
  deposits: Deposit[]
  depositsMetrics: LocationsMetrics
  pagination: Pagination
  searchFilters: CorporativeDepositsSearchFilter[]
}

export enum GetCorporativeDepositListTypes {
  GET_DEPOSIT_REQUEST = 'DEPOSIT/CORPORATIVE_TABLE/GET_DEPOSIT_REQUEST',
  GET_DEPOSIT_SUCCESS = 'DEPOSIT/CORPORATIVE_TABLE/GET_DEPOSIT_SUCCESS',
  GET_DEPOSIT_FAILURE = 'DEPOSIT/CORPORATIVE_TABLE/GET_DEPOSIT_FAILURE'
}

export enum GetCorporativeDepositMetricsTypes {
  GET_METRICS_REQUEST = 'DEPOSIT/CORPORATIVE-TABLE/GET_METRICS_REQUEST',
  GET_METRICS_SUCCESS = 'DEPOSIT/CORPORATIVE-TABLE/GET_METRICS_SUCCESS',
  GET_METRICS_FAILURE = 'DEPOSIT/CORPORATIVE-TABLE/GET_METRICS_FAILURE'
}

export interface GetDepositListRequest {
  type: GetCorporativeDepositListTypes.GET_DEPOSIT_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: CorporativeDepositsSearchFilter[]
  }
}

export interface GetDepositListSuccess {
  type: GetCorporativeDepositListTypes.GET_DEPOSIT_SUCCESS
  payload: {
    deposits: Deposit[]
    total: number
  }
}

export interface GetDepositListFailure {
  type: GetCorporativeDepositListTypes.GET_DEPOSIT_FAILURE
  payload: {
    error: string
  }
}

type GetDepositListActions = GetDepositListRequest | GetDepositListSuccess | GetDepositListFailure

export const RESET_FILTERS = 'DEPOSIT/CORPORATIVE_TABLE/RESET_FILTERS'

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export type GetDepositMetricsRequest = {
  type: GetCorporativeDepositMetricsTypes.GET_METRICS_REQUEST
}

export type GetDepositMetricsSuccess = {
  type: GetCorporativeDepositMetricsTypes.GET_METRICS_SUCCESS
  payload: {
    depositsMetrics: LocationsMetrics
  }
}

export type GetDepositMetricsFailure = {
  type: GetCorporativeDepositMetricsTypes.GET_METRICS_FAILURE
  payload: {
    error: string
  }
}

export type DepositListActions =
  | GetDepositListActions
  | ResetFilters
  | GetDepositMetricsRequest
  | GetDepositMetricsSuccess
  | GetDepositMetricsFailure
