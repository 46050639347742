import * as React from 'react'
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    data-name="Capa 1"
    width={320}
    height={250}
    viewBox="0 0 725.98 435.94"
    {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:#e2e0ff}.cls-2{fill:#5d58f7;opacity:.07}.cls-11,.cls-2,.cls-7{isolation:isolate}.cls-3{fill:#75343d}.cls-4{fill:#96404e}.cls-6{fill:#ff6272}.cls-11,.cls-7{fill:#fff}.cls-7{opacity:.19}.cls-8{fill:#393991}.cls-9{fill:#282863}.cls-11{opacity:.12}.cls-12{fill:#3e3b40}.cls-14{fill:#d88b25}'
        }
      </style>
    </defs>
    <path
      d="M664.79 37.74H381.67a61.18 61.18 0 0 0-61.19 61.18v255.5a61.18 61.18 0 0 0 61.19 61.18h283.12a61.18 61.18 0 0 0 61.19-61.18V98.92a61.18 61.18 0 0 0-61.19-61.18Z"
      className="cls-1"
    />
    <path
      d="M522.23 369.68c87.8 0 159-15.37 159-34.34s-71.2-34.35-159-34.35-159 15.38-159 34.34 71.2 34.35 159 34.35Z"
      className="cls-2"
    />
    <path
      d="M585.09 257.99c0 1.42 19.88 91.16 19.88 91.16h-5.23l-27-89.13ZM458.25 257.99c0 1.43-19.47 91.17-19.47 91.17H444l26.65-89.26ZM490.2 232.87c0 1.42-19.48 91.17-19.48 91.17h5.23l26.6-89.3Z"
      className="cls-3"
    />
    <path d="M458.25 257.99a3.38 3.38 0 0 1 0 .45l9.51 1.46-26.61 89.26h2.91l26.59-89.29Z" className="cls-4" />
    <path d="M552.08 232.87c0 1.42 19.89 91.17 19.89 91.17h-5.23l-27-89.19Z" className="cls-3" />
    <path d="M585.09 257.99a1.51 1.51 0 0 0 .08.43l-9.5 1.5 27 89.19h-2.93l-27-89.09Z" className="cls-4" />
    <path
      d="M772.76 482.08c-29.45-18.06-98.82-18.06-128.29 0s-30.88 58.43-30.88 58.43l28.51 59.85h133l28.46-59.85s-1.35-40.39-30.8-58.43Z"
      style={{
        fill: '#db5669'
      }}
      transform="translate(-192.15 -359.01)"
    />
    <path
      d="M609.6 170.49c-30-6.39-24.15 36.12-46.16 50s-47 11.71-47 11.71-25.18 2.06-47-11.71-16-56.34-46-50c-43.71 9.17-8.27 72.9 9 85.17 27.34 19.37 84.1 16.62 84.1 16.62s56.75 2.75 84.09-16.62c17.22-12.33 52.67-76.07 8.97-85.17Z"
      className="cls-6"
    />
    <path
      d="M600.56 250.37c-27.34 19.37-84.09 16.62-84.09 16.62s-56.76 2.76-84.1-16.61c-10.75-7.63-28.54-35.19-29.92-57.12-1.6 22.55 18.29 54.09 29.92 62.38 27.34 19.37 84.1 16.62 84.1 16.62s56.75 2.75 84.09-16.62c11.63-8.25 31.48-39.79 29.91-62.38-1.44 22.02-19.15 49.48-29.91 57.11Z"
      className="cls-6"
    />
    <path
      d="M410.77 185.54a24.7 24.7 0 0 0-1.33 16.38 20.06 20.06 0 0 0 5.59 10.27c2 1.82 5.37 3 7.31 1 1.33-1.29 1.34-3.37 1.26-5.23l-.38-9.82a13.38 13.38 0 0 1 1.23-7.7c1.6-2.53 4.8-3.81 6.14-6.4a6.19 6.19 0 0 0 .63-3.56 6 6 0 0 0-1.48-3.3c-5.66-6.26-16.42 3.12-18.97 8.36ZM593.92 181.32a20.81 20.81 0 0 0-6 13.63c0 1 .27 2.14 1.19 2.44a2.2 2.2 0 0 0 1.08-.07 2.44 2.44 0 0 0 .93-.58c2.19-1.82 3.2-4.62 5-6.88a14.6 14.6 0 0 1 15.49-4.8 27.52 27.52 0 0 0 4.6 1.43 4.14 4.14 0 0 0 4.21-1.6c2.9-5.28-8-9.22-11.48-9.71-5.65-.93-11.36 2.08-15.02 6.14Z"
      className="cls-7"
    />
    <path
      d="M208.22-.01H95.94a23 23 0 0 0-23 23v101.52a23 23 0 0 0 23 23h112.28a23 23 0 0 0 23-23V22.99a23 23 0 0 0-23-23Z"
      className="cls-1"
    />
    <path
      d="M151.68 129.61c34.28 0 62.07-6 62.07-13.4s-27.79-13.4-62.07-13.4-62.07 6-62.07 13.4 27.79 13.4 62.07 13.4Z"
      className="cls-2"
    />
    <path d="m175.43 58.13 16.95 58h-2.89l-24.11-58Z" className="cls-8" />
    <path
      d="M168.25 65.05a59.93 59.93 0 0 1 6.26 2c.22.35 16.42 49.12 16.42 49.12h1.46l-17-58-9.08 2.32 1.62 3.92Z"
      className="cls-9"
    />
    <path d="m129.18 58.13-17 58h2.91l24.09-58Z" className="cls-8" />
    <path
      d="m128.5 60.45-1.43 4.85 3.23 5.55-16.76 45.32h1.58l22.83-55ZM148.03 55.26l3.13 46.63h3.77l2.25-49.35Z"
      className="cls-9"
    />
    <path
      d="M196.79 53.77c-.52 5.85-20 10.6-44.75 10.6s-44.74-4.74-44.74-10.6c0-.52-.33-3 .21-3.73 3.65-4.78 22-6.92 44.53-6.92 23.16 0 42 1.73 44.56 6.92a7.73 7.73 0 0 1 .19 3.73Z"
      className="cls-9"
    />
    <path
      d="M152.04 61.69c24.71 0 44.75-4.74 44.75-10.6s-20-10.6-44.75-10.6-44.74 4.75-44.74 10.6 20.03 10.6 44.74 10.6Z"
      className="cls-8"
    />
    <g
      style={{
        opacity: 0.12
      }}>
      <path
        d="m130.85 60.45 26.29-19.89h-6.16L113.9 56.75a78.1 78.1 0 0 0 17 3.7ZM175.19 41.99q-3.13-.45-6.59-.78l-29.41 20c4.07.29 8.37.45 12.84.45 5.19 0 10.2-.23 14.85-.61Z"
        className="cls-11"
      />
    </g>
    <path
      d="M205.59 209.7H37.2A37.19 37.19 0 0 0 0 246.9v151.85a37.19 37.19 0 0 0 37.2 37.24h168.39a37.19 37.19 0 0 0 37.2-37.19V246.9a37.19 37.19 0 0 0-37.2-37.2Z"
      className="cls-1"
    />
    <path
      d="M125.85 403.99c54.71-6.14 97.88-21.72 96.41-34.81s-47-18.72-101.73-12.59-97.88 21.73-96.41 34.81 47 18.69 101.73 12.59Z"
      className="cls-2"
    />
    <path
      d="M51.85 369.82c.19.53.54 24.34 1.07 24.34h3l4.27-22ZM79.99 377.1c.19.53.54 24.36 1.07 24.36h3l4.27-22ZM191.65 344.23c-.23.52-2.23 24.2-2.23 24.2h-2.66l-3.65-22.59ZM165.65 337.29c-.2.52-2.42 24.21-2.95 24.21l-3-.24-2.55-22.27Z"
      className="cls-12"
    />
    <path
      d="m239.9 642.46 1.1 87.87 33.21 10.14 111.35-33.6v-78.78l-28.28-5.38Z"
      style={{
        fill: '#ea9f26'
      }}
      transform="translate(-192.15 -359.01)"
    />
    <path
      d="m47.75 283.45 33.9 7.12 111.77-21.49-28.28-5.38ZM84.18 293.99v18.11l53-13.52v-15.34ZM139.75 283.24v14.8l50.46-11.77v-14ZM84.18 315.59v61.51l34-9.72v-60.5ZM120.58 306.27v60l38.33-11.21.74-58.3ZM162.11 296.23l-.71 57.59 28.81-7.47v-56.89Z"
      className="cls-14"
    />
    <path
      d="M106.47 291.68a5.28 5.28 0 0 0 5.86 3 8.61 8.61 0 0 0 5.87-5.31ZM102.85 313.19a5.37 5.37 0 0 0 2.4 2.56 5.28 5.28 0 0 0 3.47.5 8.77 8.77 0 0 0 3.58-2 8.59 8.59 0 0 0 2.28-3.38ZM146.2 302.26a5.16 5.16 0 0 0 5.86 3 8.64 8.64 0 0 0 5.86-5.3ZM177.28 294.9a5.29 5.29 0 0 0 2.39 2.55 5.34 5.34 0 0 0 3.47.49 8.56 8.56 0 0 0 3.57-2 8.44 8.44 0 0 0 2.29-3.36ZM165.69 279.4s1.38 3.84 5.07 3.05 5-5.31 5-5.31Z"
      className="cls-12"
    />
  </svg>
)
export default SvgComponent
