import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actionCreators/cancel'
import Component from '../../../common/operations/components/CancelModal'

const CancelContainer = () => {
  const {
    operationId,
    modalOpen,
    sendingData,
    loadingReasons,
    cancelReasonsList,
    cancelReasonId,
    error: { error, message }
  } = useSelector((state) => state.Removals.cancel)

  const dispatch = useDispatch()

  const { openModal, setReason, getCancelReasons, cancelOperation } = bindActionCreators(actions, dispatch)

  useEffect(() => {
    getCancelReasons()
  }, [operationId])

  const closeModal = () => {
    openModal({ isOpen: false })
  }

  const onOk = () => {
    cancelOperation({ operationId, cancelReasonId })
  }

  const isDisabled = !cancelReasonId || sendingData

  const selectData = {
    loading: loadingReasons,
    cancelOptions: cancelReasonsList,
    selected: cancelReasonId
  }

  const errorHandler = {
    message,
    error
  }

  return (
    <Component
      closeModal={closeModal}
      isDisabled={isDisabled}
      modalOpen={modalOpen}
      modalTitle="Cancelar devolución"
      onOk={onOk}
      errorHandler={errorHandler}
      operationId={operationId}
      selectData={selectData}
      sendingData={sendingData}
      setReason={setReason}
    />
  )
}

export default CancelContainer
