import { apiPrivate } from '@/api'
import { URL_BASE_CATEGORIES } from '@/endpoints'
import { AxiosResponse } from 'axios'

type APIResponse = {
  content: {
    suggestions: string[]
  }
}

type Response = ProductSuggestions

interface props {
  keyword: string
}

export interface ProductSuggestions {
  suggestions: string[]
}

enum CorporationName {
  MERCADO_LIBRE = 'mercado_libre'
}

export function getSuggestions({ keyword }: props): Promise<Response> {
  const formattedParams = {
    keyword,
    catalog_name: CorporationName.MERCADO_LIBRE
  }
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/product-suggestions`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return response.data.content
    })
}
