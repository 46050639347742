export enum CountryName {
  ALL = '',
  ARGENTINA = 'Argentina',
  SPAIN = 'Spain'
}

export enum CountryIdCode {
  ALL = '',
  ARGENTINA = 'ARG',
  SPAIN = 'ESP'
}

export enum CountryPhoneCode {
  ALL = '',
  ARGENTINA = '+54',
  BRAZIL = '+55',
  COLOMBIA = '+57',
  COSTA_RICA = '+506',
  ECUADOR = '+593',
  FRANCE = '+33',
  GERMANY = '+49',
  ITALY = '+39',
  MEXICO = '+52',
  PERU = '+51',
  PORTUGAL = '+351',
  SPAIN = '+34',
  SWITZERLAND = '+41',
  UNITED_STATES = '+1',
  URUGUAY = '+598'
}

export enum CountryCurrency {
  ALL = '$',
  ARGENTINA = '$',
  SPAIN = '€'
}

export type Country = {
  label: string
  code: CountryIdCode
  currency: CountryCurrency
} & CountryAdditionalInformation

export type CountryAdditionalInformation = {
  fullName: CountryName
  flagUrl: string
  phoneCode: CountryPhoneCode
}

export const allCountriesOption: Country = {
  fullName: CountryName.ALL,
  code: CountryIdCode.ALL,
  currency: CountryCurrency.ALL,
  phoneCode: CountryPhoneCode.ALL,
  label: 'Todos',
  flagUrl: `${process.env.PUBLIC_URL}/img/flags/all-v2.svg`
}
