export default {
  OPEN_REASON_MODAL: 'OBJECTS/REMOVE/OPEN_REASON_MODAL',
  CANCEL_REASON_MODAL: 'OBJECT/REMOVE/CANCEL_REASON_MODAL',
  SET_REASON: 'OBJECTS/REMOVE/SET_REASON',
  CLOSE_REASON_MODAL: 'OBJECTS/REMOVE/CLOSE_REASON_MODAL',
  GET_OBJECT_DELETED_REASONS_REQUEST: 'OBJECTS/GET_OBJECT_DELETED_REASONS_REQUEST',
  GET_OBJECT_DELETED_REASONS_SUCCESS: 'OBJECTS/GET_OBJECT_DELETED_REASONS_SUCCESS',
  GET_OBJECT_DELETED_REASONS_FAILURE: 'OBJECTS/GET_OBJECT_DELETED_REASONS_FAILURE',
  REMOVE_OBJECT_REQUEST: 'OBJECTS/REMOVE_OBJECT_REQUEST',
  REMOVE_OBJECT_SUCCESS: 'OBJECTS/REMOVE_OBJECT_SUCCESS',
  REMOVE_OBJECT_FAILURE: 'OBJECTS/REMOVE_OBJECT_FAILURE',
  SET_DELETED_REASON: 'OBJECTS/REMOVE/SET_DELETED_REASON'
}
