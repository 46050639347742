import { AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../api'
import { URL_BASE_USERS } from '../../endpoints'

interface APIUserExclusionReason {
  id: number
  description: string
}

export type UserExclusionReason = CamelCasedPropertiesDeep<APIUserExclusionReason>

export function userExclusionReasons(): Promise<UserExclusionReason[]> {
  return apiPrivate
    .get(`${URL_BASE_USERS}/user-exclusion-reasons`)
    .then((response: AxiosResponse<APIUserExclusionReason[]>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err: AxiosError) => {
      throw err
    })
}
