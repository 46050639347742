import { Reducer } from 'redux'
import {
  GetOperationDaysTypes,
  ModalEditDayOperationTypes,
  OperationDaysAction,
  OperationDaysState
} from '../types/operationDays'

const initialState: OperationDaysState = {
  daysData: [],
  selectedDay: '',
  loadingDays: false,
  error: '',
  isEditModalOpen: false
}

const OperationDaysReducer: Reducer<OperationDaysState, OperationDaysAction> = (
  state = initialState,
  action
): OperationDaysState => {
  switch (action.type) {
    case GetOperationDaysTypes.GET_DAYS_REQUEST:
      return {
        ...state,
        loadingDays: true
      }
    case GetOperationDaysTypes.GET_DAYS_SUCCESS:
      return {
        ...state,
        daysData: action.payload.daysData,
        loadingDays: false
      }
    case ModalEditDayOperationTypes.SET_EDIT_MODAL_OPEN:
      return {
        ...state,
        isEditModalOpen: action.payload.isEditModalOpen,
        selectedDay: action.payload.selectedDay
      }
    default:
      return state
  }
}

export default OperationDaysReducer
