import { CamelCasedPropertiesDeep } from 'type-fest'
import { AddressDetail } from './common'
import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'

type depositCreateParams = {
  name: string
  countryCode: string
  addressDetail: Partial<AddressDetail>
  corporative?: boolean
}

export interface APIResponse {
  id: number
  name: string
  address: string
  address_id: number
  country_id: string
  deprecated: boolean
  owner_id: number
  locations: null
}

export type ResponseApp = CamelCasedPropertiesDeep<APIResponse>

export function create({ corporative, ...params }: depositCreateParams): Promise<ResponseApp> {
  const formattedParams = snakecaseKeys(params, { deep: true })
  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/deposits`, formattedParams, {
      headers: { 'x-origin': corporative ? null : 'backoffice/adm-web' }
    })
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((error) => {
      throw error
    })
}
