import table from './reducers/table'
import deleteReducer from './reducers/delete'
import modalLeads from './reducers/ModalLeads'
import metricsLeads from './reducers/MetricsLeads'
import optionsModal from './reducers/OptionsModal'
import actionsModal from './reducers/ActionsModal'
import reasonsModal from './reducers/ReasonsModal'
import leadHistory from './reducers/LeadHistory'
import { combineReducers } from 'redux'

export default combineReducers({
  table,
  delete: deleteReducer,
  modal: modalLeads,
  metrics: metricsLeads,
  optionsModal,
  actionsModal,
  reasonsModal,
  history: leadHistory
})
