import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import moment, { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { formatDates } from '../../utils'
import { APITrip, Trip } from './common'

interface APIResponse {
  results: {
    trips: APITrip[]
  }
  paging: {
    limit: number
    offset: number
    total: number
  }
}

type Response = {
  trips: Trip[]
  total: number
}

export type TripsListSortKey = 'id' | 'start_date' | 'end_date' | 'scheduled_start' | 'scheduled_end' | 'created_at'

export interface ListParams {
  tripStatus?: string
  realStartDateTo?: Moment
  realStartDateFrom?: Moment
  realEndDateTo?: Moment
  realEndDateFrom?: Moment
  scheduledStartDateFrom?: Moment
  scheduledStartDateTo?: Moment
  scheduledEndDateFrom?: Moment
  scheduledEndDateTo?: Moment
  createdAtFrom?: Moment
  createdAtTo?: Moment
  limit?: number
  offset?: number
  sort?: TripsListSortKey
  order?: SortDirection
  id?: number
}

export function list(params: ListParams): Promise<Response> {
  const {
    tripStatus,
    realStartDateTo,
    realStartDateFrom,
    realEndDateTo,
    realEndDateFrom,
    scheduledStartDateFrom,
    scheduledStartDateTo,
    scheduledEndDateFrom,
    scheduledEndDateTo,
    createdAtFrom,
    createdAtTo,
    limit,
    offset,
    sort,
    order,
    id
  } = params
  const snakeParams = snakecaseKeys({
    tripStatus,
    limit,
    offset,
    sort,
    order,
    id,
    ...formatDates({
      dates: {
        startDateTo: realStartDateTo,
        startDateFrom: realStartDateFrom,
        endDateTo: realEndDateTo,
        endDateFrom: realEndDateFrom,
        scheduledStartFrom: scheduledStartDateFrom,
        scheduledStartTo: scheduledStartDateTo,
        scheduledEndFrom: scheduledEndDateFrom,
        scheduledEndTo: scheduledEndDateTo,
        createdAtFrom,
        createdAtTo
      }
    })
  })
  return apiPrivate
    .get(`${URL_BASE_TRIPS}/trips`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al obtener los viajes')

      const camelResponse: Response = {
        total: response.data.paging.total,
        trips: response.data.results.trips
          ? camelcaseKeys(response.data.results.trips, { deep: true }).map((trip) => ({
              id: trip.id,
              status: trip.status,
              transactionSummary: {
                deposits: trip.quantityDeposits,
                removals: trip.quantityRemovals
              },
              forecast: {
                objects: trip.quantityObjects,
                totalM3: trip.totalM3
              },
              driver: {
                name: trip.driver.name,
                lastName: trip.driver.lastName,
                userId: trip.driver.userId
              },
              createdBy: trip.createdBy,
              createdAt: moment(trip.createdAt),
              scheduledStartDate: moment(trip.scheduledStart),
              scheduledEndDate: moment(trip.scheduledEnd),
              realStartDate: moment(trip.startDate),
              realEndDate: moment(trip.endDate),
              country: trip.country
            }))
          : []
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
