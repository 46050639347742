import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GetRolesSuccess } from '../types/roles'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { Role } from '../../../projectApi/AccountManager/Roles/getAll'

const actions = {
  getRoles(): AppThunk {
    return async (dispatch) => {
      dispatch({ type: GET_ROLES_REQUEST })

      try {
        const response = await API.AccountManager.Roles.getAll()
        dispatch(actions.getRolesSuccess(response))
      } catch (error) {
        handleErrorsWithAction(error, GET_ROLES_FAILURE, dispatch)
      }
    }
  },
  getRolesSuccess: (roles: Role[]): GetRolesSuccess => ({
    type: GET_ROLES_SUCCESS,
    payload: { roles }
  })
}

export default actions
