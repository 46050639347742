import { Reducer } from 'redux'
import {
  CLEAN_STATES,
  GET_TRANSACTION_DATA_FAILURE,
  GET_TRANSACTION_DATA_REQUEST,
  GET_TRANSACTION_DATA_SUCCESS
} from '../../types/EditDepositViewTypes/EditDepositView'
import {
  GET_SERVICE_TYPES_FAILURE,
  GET_SERVICE_TYPES_REQUEST,
  GET_SERVICE_TYPES_SUCCESS,
  SET_PRICE_BY,
  SET_SERVICE_TYPE,
  ServiceTypeActions,
  ServiceTypeState
} from '../../types/EditDepositViewTypes/serviceType'

const initialState: ServiceTypeState = {
  serviceTypeList: [],
  serviceType: {
    label: '',
    value: ''
  },
  priceBy: {
    service: ''
  },
  loadingTypes: false,
  loadingTransactionData: false
}

export const ServiceTypeReduce: Reducer<ServiceTypeState, ServiceTypeActions> = (
  state = initialState,
  action
): ServiceTypeState => {
  switch (action.type) {
    case CLEAN_STATES:
      return initialState
    case GET_SERVICE_TYPES_REQUEST:
      return {
        ...state,
        loadingTypes: true
      }
    case GET_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        serviceTypeList: action.payload.serviceTypes,
        loadingTypes: false
      }
    case GET_SERVICE_TYPES_FAILURE:
      return {
        ...state,
        loadingTypes: false
      }
    case GET_TRANSACTION_DATA_REQUEST:
      return {
        ...state,
        loadingTransactionData: true
      }
    case GET_TRANSACTION_DATA_SUCCESS: {
      const { serviceType } = action.payload.transaction.serviceType
      const serviceTypeOption = state.serviceTypeList.find((item) => item.id === serviceType)
      return {
        ...state,
        serviceType: serviceTypeOption
          ? { label: serviceTypeOption.type, value: serviceTypeOption.id }
          : initialState.serviceType,
        loadingTransactionData: false
      }
    }
    case GET_TRANSACTION_DATA_FAILURE:
      return {
        ...state,
        loadingTransactionData: false
      }
    case SET_SERVICE_TYPE:
      return {
        ...state,
        serviceType: action.payload.serviceType
      }
    case SET_PRICE_BY:
      return {
        ...state,
        priceBy: {
          service: action.payload.service
        }
      }
    default:
      return state
  }
}

export default ServiceTypeReduce
