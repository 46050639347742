import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import AttributeActionCreators from '../actionCreators/Attributes'
import DeleteAttributesActionCreators from '../actionCreators/DeleteAttributes'
import DeleteAttributeModal from '../components/DeleteAttributeModal'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const attributesActions = bindActionCreators(AttributeActionCreators, dispatch)
  const deleteAttributesActions = bindActionCreators(DeleteAttributesActionCreators, dispatch)
  const deleteState = useSelector((state) => state.Attributes.DeleteAttribute)

  const props = {
    attributesActions,
    deleteAttributesActions,
    deleteState
  }

  return <DeleteAttributeModal {...props} />
}

export default Container
