import React from 'react'
import styles from './OperationBadge.module.scss'
import clsx from 'clsx'
import { OperationTypeName, OperationTypeValues } from '../../types/common'

const operationDetails: Record<OperationTypeValues, { displayName: string; styleProps: Record<string, string> }> = {
  [OperationTypeValues.DEPOSIT]: {
    displayName: OperationTypeName.DEPOSIT,
    styleProps: {
      borderColor: '#286809',
      backgroundColor: '#F6FFED',
      color: '#52C41A'
    }
  },
  [OperationTypeValues.REMOVAL]: {
    displayName: OperationTypeName.REMOVAL,
    styleProps: {
      borderColor: '#cc6266',
      backgroundColor: '#fffafa',
      color: '#cc6266'
    }
  },
  [OperationTypeValues.NONE]: {
    displayName: OperationTypeName.NONE,
    styleProps: {
      borderColor: '#414040',
      backgroundColor: '#cbc9ce',
      color: '#3f3844'
    }
  }
}

type ColorBadgeProps = {
  className?: string
  border?: string
  extraStyles?: React.CSSProperties
  operation: OperationTypeValues
}

const ColorBadge: React.FC<ColorBadgeProps> = ({ className, operation, border, extraStyles }) => {
  const { displayName, styleProps } = operationDetails[operation] || {
    displayName: 'Default',
    styleProps: {}
  }

  const combinedStyles: React.CSSProperties = {
    ...styleProps,
    border: border || '1px solid',
    ...extraStyles
  }

  const badgeClassName = clsx(styles.ColorBadge, className)

  const badgeContent = (
    <div className={badgeClassName} style={combinedStyles}>
      {displayName}
    </div>
  )

  return badgeContent
}

export default ColorBadge
