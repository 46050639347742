import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'

interface APIItem {
  product_id: number
  price_in_cents: number
  amount: number
  name: string
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  weight_in_gr: number
  comment?: string
  packaging_cost_in_cents: number
  disassembly_cost: number
  cost_per_floor_in_cents: number
}

interface APIAddress {
  street: string
  number: string
  floor: string
  apartment: string
  postal_code: number
  city: string
  province: string
  comment?: string
  address_string: string
}

interface APINotify {
  notify: boolean
  email: string
}

interface Detail {
  transport_cost?: TransportCost | null
}

interface TransportCost {
  volume_m3: number
  country_id: string
  description: string
  distance_km: number
  max_width_m: number
  max_height_m: number
  max_weight_m: number
  max_length_in_m: number
}

interface APIRequest {
  service_type_id: string
  origin: string
  prospect_id: number
  cubic_m_price_in_cents: number
  packaging_cost_in_cents: number
  transport_cost_in_cents: number
  cost_per_floor_in_cents: number
  disassembly_cost: number
  discount_code?: string
  storage_cost_in_cents: number
  additional_cost_in_cents?: number
  additional_cost_comment?: string
  transport_mode_id: number
  deposit_id: number
  toll_cost_in_cents: number
  people_id: number
  country_id: string
  shipment_assistant_price: number
  shipment_assistant_count: number
  total_amount: number
  transport_type: string
  items: APIItem[]
  address: APIAddress
  notify: APINotify
  detail?: Detail | null
}

export type Item = CamelCasedPropertiesDeep<APIItem>
export type CreateProposalRequest = CamelCasedPropertiesDeep<APIRequest>

type APIResponse = {
  proposal_id: number
  message: string
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export function create(request: CreateProposalRequest, config: AxiosRequestConfig): Promise<Response> {
  const formattedRequest = snakecaseKeys(request, { deep: true })
  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/proposal`, formattedRequest, config)
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err) => {
      throw err
    })
}
