import { FormattedObject } from '../../../projectApi/ObjectAdministration/common'
import { ObjectStatus } from '../../../projectApi/ObjectAdministration/ObjectStatus/search'
import { ObjectsListSortKey, SearchableParams } from '../../../projectApi/ObjectAdministration/Object/list'
import { ObjectsMetricSituations } from '../../../projectApi/ObjectAdministration/Object/metrics'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type ObjectsTableSearchKey = keyof SearchableParams
export type ObjectsSearchFilter = SearchFilter<ObjectsTableSearchKey>

export type ObjectsTableSortKey = ObjectsListSortKey
export type ObjectsTableSort = Sort<ObjectsTableSortKey>

export type ObjectsTableCategoryFilterKey = 'status' | 'objectSituation'
export type ObjectsTableCategoryFilter = CategoryFilter<ObjectsTableCategoryFilterKey>
export enum ObjectsTableDateRangeKey {
  CREATION_DATE = 'createdAt'
}
export type ObjectsTableDateRangeFilter = DateRangeFilter<ObjectsTableDateRangeKey>

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export enum ObjectTableSearchFilterKey {
  ID = 'id',
  IDENTIFICATION_CODE = 'identificationCode',
  PRODUCT = 'product',
  LOCATION_CODE = 'locationCode',
  OWNER_ID = 'ownerId',
  OWNER = 'owner',
  EXACT_LOCATION_CODE = 'exactLocationCode',
  LOCATION = 'location',
  CREATED_AT_FROM = 'createdAtFrom',
  CREATED_AT_TO = 'createdAtTo'
}

export enum ObjectTableDateRangeFilterKey {
  CREATED_AT_FROM = 'createdAtFrom',
  CREATED_AT_TO = 'createdAtTo'
}

export interface ObjectsTableState {
  objects: FormattedObject[]
  loadingObjects: boolean
  error: string
  categoryFilter: ObjectsTableCategoryFilter
  pagination: Pagination
  searchFilters: ObjectsSearchFilter[]
  sort: ObjectsTableSort
  selectedObjects: FormattedObject[]
  objectStatus: ObjectStatus[]
  metrics: Metrics
  loadingMetrics: boolean
  dateRangeFilter: ObjectsTableDateRangeFilter[]
}

export type Metrics = {
  totalObjects: number
  totalM3: number
  situations: ObjectsMetricSituations
}

export const CLEAR_STATE = 'OBJECTS/OBJECTS_TABLE/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
}

export const GET_OBJECTS_REQUEST = 'OBJECTS/TABLE/GET_OBJECTS_REQUEST'
export const GET_OBJECTS_SUCCESS = 'OBJECTS/TABLE/GET_OBJECTS_SUCCESS'
export const GET_OBJECTS_FAILURE = 'OBJECTS/TABLE/GET_OBJECTS_FAILURE'
export const GET_OBJECTS_STATUS_REQUEST = 'OBJECTS/TABLE/GET_OBJECTS_STATUS_REQUEST'
export const GET_OBJECTS_STATUS_SUCCESS = 'OBJECTS/TABLE/GET_OBJECTS_STATUS_SUCCESS'
export const GET_OBJECTS_STATUS_FAILURE = 'OBJECTS/TABLE/GET_OBJECTS_STATUS_FAILURE'

export interface GetObjectsRequest {
  type: typeof GET_OBJECTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: ObjectsSearchFilter[]
    categoryFilter?: ObjectsTableCategoryFilter
    sort?: ObjectsTableSort
    silentLoading?: boolean
    dateRangeFilter?: ObjectsTableDateRangeFilter[]
  }
}

export interface GetObjectsSuccess {
  type: typeof GET_OBJECTS_SUCCESS
  payload: {
    total: number
    objects: FormattedObject[]
  }
}

export interface GetObjectsFailure {
  type: typeof GET_OBJECTS_FAILURE
  payload: {
    error: string
  }
}

export interface GetObjectsStatusRequest {
  type: typeof GET_OBJECTS_STATUS_REQUEST
}

export interface GetObjectsStatusSuccess {
  type: typeof GET_OBJECTS_STATUS_SUCCESS
  payload: {
    objectStatus: ObjectStatus[]
  }
}

export interface GetObjectsStatusFailure {
  type: typeof GET_OBJECTS_STATUS_FAILURE
  payload: {
    error: string
  }
}

export type GetObjectsAction =
  | GetObjectsRequest
  | GetObjectsSuccess
  | GetObjectsFailure
  | GetObjectsStatusRequest
  | GetObjectsStatusSuccess
  | GetObjectsStatusFailure

export const TOGGLE_EXACT_LOCATION_CODE = 'OBJECTS/TABLE/TOGGLE_EXACT_LOCATION_CODE'
export const TOGGLE_EMPTY_LOCATIONS = 'OBJECTS/TABLE/TOGGLE_EMPTY_LOCATIONS'

export interface ToggleExactLocationCode {
  type: typeof TOGGLE_EXACT_LOCATION_CODE
}

export interface ToggleEmptyLocations {
  type: typeof TOGGLE_EMPTY_LOCATIONS
}

export const TOGGLE_SELECTED_OBJECT = 'OBJECTS/TABLE/TOGGLE_SELECTED_OBJECT'
export interface ToggleSelectedObject {
  type: typeof TOGGLE_SELECTED_OBJECT
  payload: {
    object: FormattedObject
  }
}

export const TOGGLE_ALL_OBJECTS = 'OBJECTS/TABLE/TOGGLE_ALL_OBJECTS'

export interface ToggleAllObjects {
  type: typeof TOGGLE_ALL_OBJECTS
}

export const GET_METRICS_OBJECTS_REQUEST = 'OBJECTS/TABLE/GET_METRICS_OBJECTS_REQUEST'
export const GET_METRICS_OBJECTS_SUCCESS = 'OBJECTS/TABLE/GET_METRICS_OBJECTS_SUCCESS'
export const GET_METRICS_OBJECTS_FAILURE = 'OBJECTS/TABLE/GET_METRICS_OBJECTS_FAILURE'

export interface GetMetricsObjectsRequest {
  type: typeof GET_METRICS_OBJECTS_REQUEST
  payload: {
    searchFilters?: ObjectsSearchFilter[]
    categoryFilter?: ObjectsTableCategoryFilter
    sort?: ObjectsTableSort
    dateRangeFilter?: ObjectsTableDateRangeFilter[]
  }
}

export interface GetMetricsObjectsSuccess {
  type: typeof GET_METRICS_OBJECTS_SUCCESS
  payload: {
    totalObjects: number
    totalM3: number
    situations: ObjectsMetricSituations
  }
}

export interface GetMetricsObjectsFailure {
  type: typeof GET_METRICS_OBJECTS_FAILURE
  payload: { error: string }
}

export type GetMetricsObjectsAction = GetMetricsObjectsRequest | GetMetricsObjectsSuccess | GetMetricsObjectsFailure

export type ObjectsTableAction =
  | GetObjectsAction
  | ToggleSelectedObject
  | ToggleAllObjects
  | ToggleExactLocationCode
  | ToggleEmptyLocations
  | ClearState
  | GetMetricsObjectsAction
