import React from 'react'
import { DatePicker, Modal } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment, { Moment } from 'moment'
import actions from '../actions/editDateService'
import styles from './EditDateService.module.scss'
import { EditDateServiceState } from '../types/editDateService'
import InputFieldWithError from '../../../../components/InputFieldWithError/inputFieldWithError'
import { InputProps, TimeInterval, Timeslot } from '../../../../sections/Deposits/component/subComponents/types'

interface EditDateServiceModalProps {
  editDateServiceActions: typeof actions
  editDateServiceState: EditDateServiceState
  calendar: {
    datetime: Moment | null
    unavailableDates: InputProps<Moment, Moment | null>
    timeslots: InputProps<Timeslot, number>
    timeIntervals: InputProps<TimeInterval, number>
  }
  anticipatedTimeInHours?: number
}

const SHOW_MONTHS = 3

const EditDateServiceModal = ({
  editDateServiceActions,
  editDateServiceState,
  calendar,
  anticipatedTimeInHours = 0
}: EditDateServiceModalProps) => {
  const { openModal, editDateService, setReason, setNewDateService } = editDateServiceActions
  const { modalOpen, operationId, newDateService, reason, loadingSave } = editDateServiceState
  const { unavailableDates, timeslots, timeIntervals, datetime } = calendar

  const disabledDate = (current: Moment) => {
    const today = moment().startOf('day')

    return (
      // Date not before today or anticipated date
      (current && current < moment().add(anticipatedTimeInHours, 'hour').startOf('day')) ||
      // Date is the current day
      current.isSame(today, 'day') ||
      // Date is not available
      unavailableDates.list.some((date) => date.isSame(current, 'day')) ||
      // Show month that can be selected
      current > moment().add(SHOW_MONTHS, 'month') ||
      // Disabled all Sundays
      current.day() === 0
    )
  }

  return (
    <Modal
      title="Editar fecha de servicio"
      visible={modalOpen}
      okText={'Guardar'}
      onOk={() => editDateService(operationId, { newDateService, reason })}
      cancelText={'Cerrar'}
      onCancel={() => openModal({ isOpen: false })}
      className={styles.modalContainer}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      okButtonProps={{ loading: loadingSave, disabled: loadingSave, className: styles.modalButtonOk }}>
      <div>
        <h2 className={styles.modalTitle}>{`Ingrese la nueva fecha de servicio para la operación #${operationId}.`}</h2>
        <div className={styles.datePickerContainer}>
          <p className={styles.inputTitle}>Nueva fecha de servicio</p>
          <DatePicker
            // @ts-ignore
            disabledDate={disabledDate}
            showTime
            placeholder="Nueva fecha de servicio"
            locale={locale}
            format="DD/MM/YYYY HH:mm:ss"
            popupStyle={{ zIndex: 10000 }}
            allowClear={false}
            onChange={(date) => setNewDateService(date ?? moment())}
            value={newDateService}
          />
        </div>
        <InputFieldWithError
          label="Razón"
          value={reason}
          className="disabledFields"
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
    </Modal>
  )
}

export default EditDateServiceModal
