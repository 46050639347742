import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo } from 'react'
import TripModal, { TripModalProps } from '../components/tripModal'
import TripModalActionCreators from '../actionCreators/tripModal'
import { bindActionCreators } from 'redux'
import TripsCommonActionCreators from '../actionCreators/common'
import { CommonDestination } from '../types/common'
import { TripStatusId } from '../../../projectApi/Trips/Trip/common'

const TripModalContainer = () => {
  const dispatch = useDispatch()
  const { setOpen, getDrivers, selectDriver, updateTrip, createTrip, findTransactionById } = bindActionCreators(
    TripModalActionCreators,
    dispatch
  )
  const { getTripTransactions } = bindActionCreators(TripsCommonActionCreators, dispatch)

  const modalState = useSelector((state) => state.Trips.modal)
  const tableState = useSelector((state) => state.Trips.table)

  useEffect(() => {
    if (modalState.open) {
      getDrivers()
    }
  }, [modalState.open])

  useEffect(() => {
    if (modalState.open && modalState.trip?.id) {
      getTripTransactions({ tripId: modalState.trip?.id, destination: CommonDestination.MODAL })
    }
  }, [modalState.open, modalState.trip])

  const handleClose = () => {
    setOpen({ open: false })
  }

  const handleConfirm = () => {
    const { trip, selectedDriverId, extraTransactionsToAdd } = modalState

    if (trip?.id) {
      updateTrip({
        driverId: trip?.driver.userId !== selectedDriverId ? selectedDriverId : undefined,
        transactionsToAdd: extraTransactionsToAdd.length ? extraTransactionsToAdd : undefined,
        tripId: trip?.id
      })
      return
    }

    const { transactions } = modalState

    const transactionDatesOrdered = transactions
      .map((tx) => tx.reservationDate)
      .sort((a, b) => (a.isBefore(b) ? -1 : 1))

    if (transactionDatesOrdered.length === 0) return

    createTrip({
      driverId: selectedDriverId,
      scheduledStartDate: transactionDatesOrdered[0],
      scheduledEndDate: transactionDatesOrdered[transactionDatesOrdered.length - 1],
      transactions,
      country: transactions[0].operation?.countryId
    })
  }

  const ableToAddTransactions = useMemo(() => {
    return (
      (modalState.trip?.status.id === TripStatusId.PROGRAMADO ||
        modalState.trip?.status.id === TripStatusId.ASIGNADO) &&
      !tableState.startedTripIds.find((tid) => tid === modalState.trip?.id) &&
      !tableState.canceledTripIds.find((tid) => tid === modalState.trip?.id)
    )
  }, [modalState?.trip?.id, modalState?.trip?.status.id, tableState.startedTripIds, tableState.canceledTripIds])

  const props: TripModalProps = {
    ...modalState,
    ableToAddTransactions,
    onFindTransactionById: findTransactionById,
    onSelectDriver: selectDriver,
    onClose: handleClose,
    onConfirm: handleConfirm
  }

  return <TripModal {...props} />
}

export default TripModalContainer
