import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { singleDateFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  GET_PRICE_BANDS_FAILURE,
  GET_PRICE_BANDS_REQUEST,
  GET_PRICE_BANDS_SUCCESS,
  GetPriceBandsRequest,
  GetPriceBandsSuccess,
  Pagination,
  PriceBandsSingleDateFilter
} from '../types/priceBandsTable'

export const priceBandsTableActionCreators = {
  getPriceBands:
    ({
      singleDateFilters,
      silentLoading,
      countryId,
      pagination
    }: {
      pagination: Pagination
      singleDateFilters?: PriceBandsSingleDateFilter[]
      silentLoading?: boolean
      countryId: CountryIdCode
    }): AppThunk =>
    async (dispatch) => {
      const request: GetPriceBandsRequest = {
        type: GET_PRICE_BANDS_REQUEST,
        payload: {
          singleDateFilters,
          silentLoading,
          pageSize: pagination.pageSize,
          newPage: pagination.page
        }
      }
      dispatch(request)

      try {
        const dateFrom = singleDateFilters?.find((filter) => filter.key === 'dateFrom')?.date

        const { priceBands, total } = await API.CategoryCreation.getPriceBands({
          countryId,
          ...singleDateFiltersToParams(singleDateFilters),
          all: !dateFrom // if dateFrom is null, fetch all price bands
        })

        const success: GetPriceBandsSuccess = {
          type: GET_PRICE_BANDS_SUCCESS,
          payload: {
            priceBands,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_PRICE_BANDS_FAILURE, dispatch)
      }
    }
}
