import { CreatePriceBandTypes, FormattedPriceBand, NewPriceBandActions } from '../types/create'

const initialNewPriceBand = {
  id: 0,
  price: 0,
  loading: false,
  modalOpen: false,
  modalDelete: false,
  editMode: false,
  error: '',
  country: '',
  dateFrom: null,
  typePrice: '',
  type: ''
}

export type CreatePriceBandState = FormattedPriceBand & {
  loading: boolean
  modalOpen: boolean
  modalDelete: boolean
  editMode: boolean
  error: string
}

function CreatePriceBandReducer(
  state: CreatePriceBandState = initialNewPriceBand,
  action: NewPriceBandActions
): CreatePriceBandState {
  switch (action.type) {
    case CreatePriceBandTypes.GET_PRICE_BAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case CreatePriceBandTypes.GET_PRICE_BAND_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    case CreatePriceBandTypes.GET_PRICE_BAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case CreatePriceBandTypes.SET_MODAL_OPEN:
      return {
        ...state,
        ...initialNewPriceBand,
        modalOpen: action.payload.modalOpen
      }
    case CreatePriceBandTypes.SET_EDIT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        country: action.payload.priceBand.country,
        dateFrom: action.payload.priceBand.dateFrom,
        price: action.payload.priceBand.price,
        typePrice: action.payload.priceBand.type,
        id: action.payload.priceBand.id,
        editMode: true
      }
    case CreatePriceBandTypes.SET_DATE_FROM:
      return {
        ...state,
        dateFrom: action.payload.dateFrom
      }
    case CreatePriceBandTypes.SET_PRICE:
      return {
        ...state,
        price: action.payload.price
      }
    case CreatePriceBandTypes.SET_TYPE:
      return {
        ...state,
        typePrice: action.payload.type
      }
    case CreatePriceBandTypes.SET_COUNTRY:
      return {
        ...state,
        country: action.payload.country
      }
    case CreatePriceBandTypes.ADD_PRICE_BAND_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CreatePriceBandTypes.ADD_PRICE_BAND_SUCCESS:
      return {
        ...state,
        loading: false,
        modalOpen: false
      }
    case CreatePriceBandTypes.ADD_PRICE_BAND_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case CreatePriceBandTypes.EDIT_PRICE_BAND_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CreatePriceBandTypes.EDIT_PRICE_BAND_SUCCESS:
      return {
        ...state,
        ...initialNewPriceBand
      }
    case CreatePriceBandTypes.EDIT_PRICE_BAND_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case CreatePriceBandTypes.OPEN_DELETE_MODAL:
      return {
        ...state,
        modalDelete: true,
        id: action.payload.id
      }
    case CreatePriceBandTypes.CLOSE_DELETE_MODAL:
      return {
        ...state,
        ...initialNewPriceBand
      }
    case CreatePriceBandTypes.DELETE_PRICE_BAND_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CreatePriceBandTypes.DELETE_PRICE_BAND_SUCCESS:
      return {
        ...state,
        ...initialNewPriceBand
      }
    case CreatePriceBandTypes.DELETE_PRICE_BAND_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }

    default:
      return state
  }
}

export default CreatePriceBandReducer
