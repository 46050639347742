import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { Steps } from '../constants'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../components/paymentProcess.module.scss'
import { formatMoney } from '../../../utils/formatNumber'
import { RadioChangeEvent } from 'antd/lib/radio'
import MenuCard from '../components/MenuCard'
import { CardSelection } from '../components/CardSelection'
import { AddNewCard } from '../components/AddNewCard'
import { BackIcon } from '../../../components/Button/icons/back'
import FinishPayment from '../components/FinishPayment'

type PaymentProcessMap = {
  [key in Steps]?: JSX.Element
}

export const PaymentProcess = () => {
  const dispatch = useDispatch()
  const searchUserActions = bindActionCreators(searchUserActionCreators, dispatch)
  const { billingPayment } = useSelector((state) => state.BillingPayment)
  const { error, loading, userColppyInfo, selectedBillings, paymentMethodSelected, paymentMethods } = billingPayment

  const history = useHistory()
  const [step, setStep] = useState<Steps>(0)
  const [optionRadio, setOptionRadio] = useState<number>(0)

  useEffect(() => {
    if (!userColppyInfo?.id) history.push('/billing_payment')
    searchUserActions.getUserPaymentMethods(Number(userColppyInfo?.id))
    return () => {
      searchUserActions.clearState()
    }
  }, [userColppyInfo?.id])

  const totalToPay = () => {
    const totalDebtsWithDiscounts = userColppyInfo?.saldo || 0
    const sumAllBillingDebts = selectedBillings.reduce((acc, currValue) => acc + Number(currValue.total), 0)
    return sumAllBillingDebts > totalDebtsWithDiscounts ? totalDebtsWithDiscounts : sumAllBillingDebts
  }

  const onChangeRadio = (e: RadioChangeEvent) => setOptionRadio(e.target.value)

  const stepsComponent: PaymentProcessMap = {
    [Steps.PAYMENT]: (
      <MenuCard
        selectedBillings={selectedBillings}
        userColppyInfo={userColppyInfo}
        onChangeRadio={onChangeRadio}
        optionRadio={optionRadio}
        paymentMethod={paymentMethodSelected}
        setStep={setStep}
        searchUserActions={searchUserActions}
        step={step}
        loading={loading}
        totalToPay={totalToPay()}
      />
    ),
    [Steps.FINISH_SUMMARY]: (
      <FinishPayment
        searchUserActions={searchUserActions}
        userColppyInfo={userColppyInfo}
        paymentMethod={paymentMethodSelected}
        totalToPay={totalToPay()}
      />
    ),
    [Steps.SELECT_CARD]: (
      <CardSelection
        optionRadio={optionRadio}
        setOptionRadio={setOptionRadio}
        setStep={setStep}
        paymentMethodSelected={paymentMethodSelected}
        paymentMethods={paymentMethods}
        searchUserActions={searchUserActions}
      />
    ),
    [Steps.ADD_CARD]: (
      <AddNewCard
        loading={loading}
        searchUserActions={searchUserActions}
        userColppyInfo={userColppyInfo}
        setStep={setStep}
        setOptionRadio={setOptionRadio}
      />
    )
  }

  const getStepComponent = () => {
    return stepsComponent[step] || null
  }

  const handleBackArrow = () => {
    if (step) {
      setStep(Steps.PAYMENT)
    } else {
      history.goBack()
    }
  }

  return (
    <>
      {step !== Steps.FINISH_SUMMARY && (
        <div>
          <button className={styles.backArrowButton} onClick={handleBackArrow}>
            <BackIcon />
          </button>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.containerComponent}>{getStepComponent()}</div>
        {step !== Steps.FINISH_SUMMARY && (
          <div className={styles.summary}>
            <div className={styles.header}>
              <span>Total a pagar</span>
            </div>
            <div className={styles.body}>
              {selectedBillings.map((sb) => {
                return (
                  <div className={styles.billingItem} key={sb.billingColppyId}>
                    <div>Factura N° {sb.number}</div>
                    <div>{formatMoney(sb.total)}</div>
                  </div>
                )
              })}
              <div className={styles.billingItemTotal}>
                <div>Total</div>
                <div>{formatMoney(totalToPay())}</div>
              </div>
            </div>
            {totalToPay() === 0 && (
              <p className={styles.nopayment}>No se puede realizar este pago, ya que posee saldo a favor</p>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default PaymentProcess
