import { User } from '@/projectApi/AccountManager/Users/common'
import { Agent } from '@/projectApi/Mercurio/BO/Agents/common'
import { FormattedObject } from '@/projectApi/ObjectAdministration/common'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'
import { CommonComplaintsActions } from './common'
import { OperationById } from '@/projectApi/TransactionHandler/Operation/getOperationById'

export type Client = {
  id: number
  name: string
  lastName: string
}

export type CreateComplaintModalState = {
  open: boolean
  fetchingUsers: boolean
  creatingComplaint: boolean
  complaint: Complaint
  users: User[]
  selectedObjects: string[]
  object: FormattedObject[]
  fetchingObject: boolean
  fetchingAgents: boolean
  agents: Agent[]
  reasons: ComplaintsReasons[]
  fetchingOperation: boolean
}

export enum Priority {
  URGENT = 'Urgente',
  HIGH = 'Alta',
  MEDIUM = 'Media',
  LOW = 'Baja'
}

export enum ComplaintType {
  DEPOSIT = 'Ingreso',
  REMOVAL = 'Devolución'
}

export enum ComplaintReason {
  DAMAGED_PRODUCT = 'Producto Dañado',
  INCORRECT_PRODUCT = 'Producto Incorrecto',
  MISSING_PARTS = 'Faltan Piezas',
  DELIVERY_DELAY = 'Retraso en la Entrega',
  PACKAGING_PROBLEM = 'Problema de Embalaje'
}

export type Photo = {
  id: number | string
  url: string
  fileName?: string
}

export type Complaint = {
  userId: number
  operationId: number | null
  complaintType: keyof typeof ComplaintType | null
  reasonId: number | null
  description: string
  photos: Photo[]
  objects: number[]
  assignedUserId: number | null
  priority: keyof typeof Priority | null
}

export const SET_OPEN = 'CARDS/CARD_MODAL/SET_OPEN'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    operationId?: number
    type?: keyof typeof ComplaintType
    userId?: number
  }
}

export enum SettersComplaintsTypes {
  SET_OPERATION_ID = 'COMPLAINTS/MODAL_CREATE/SET_OPERATION_ID',
  SET_TYPE = 'COMPLAINTS/MODAL_CREATE/SET_TYPE',
  SET_REASON = 'COMPLAINTS/MODAL_CREATE/SET_REASON',
  SET_DESCRIPTION = 'COMPLAINTS/MODAL_CREATE/SET_DESCRIPTION',
  SET_EVIDENCE = 'COMPLAINTS/MODAL_CREATE/SET_EVIDENCE',
  SET_AGENT = 'COMPLAINTS/MODAL_CREATE/SET_AGENT',
  SET_PRIORITY = 'COMPLAINTS/MODAL_CREATE/SET_PRIORITY',
  SET_OBJECTS = 'COMPLAINTS/MODAL_CREATE/SET_OBJECTS',
  SET_USER_ID = 'COMPLAINTS/MODAL_CREATE/SET_USER_ID'
}

export interface setObjects {
  type: typeof SettersComplaintsTypes.SET_OBJECTS
  payload: { objects: number[] }
}

export interface setClient {
  type: typeof SettersComplaintsTypes.SET_USER_ID
  payload: { userId: number }
}

export interface setOperationId {
  type: typeof SettersComplaintsTypes.SET_OPERATION_ID
  payload: { operationId: number }
}

export interface setComplaintType {
  type: typeof SettersComplaintsTypes.SET_TYPE
  payload: { type: keyof typeof ComplaintType }
}

export interface setComplaintReason {
  type: typeof SettersComplaintsTypes.SET_REASON
  payload: { reasonId: number }
}

export interface setComplaintDescription {
  type: typeof SettersComplaintsTypes.SET_DESCRIPTION
  payload: { description: string }
}

export interface setComplaintEvidence {
  type: typeof SettersComplaintsTypes.SET_EVIDENCE
  payload: { photo: Photo[] }
}

export interface setAgent {
  type: typeof SettersComplaintsTypes.SET_AGENT
  payload: { assignedUserId: number | null }
}

export interface setPriority {
  type: typeof SettersComplaintsTypes.SET_PRIORITY
  payload: { priority: keyof typeof Priority }
}

type SetterComplaintActions =
  | setOperationId
  | setComplaintType
  | setComplaintReason
  | setComplaintDescription
  | setComplaintEvidence
  | setAgent
  | setPriority
  | setObjects
  | setClient

export enum GetUsersComplaintTypes {
  GET_USERS_REQUEST = 'COMPLAINTS/MODAL_CREATE/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'COMPLAINTS/MODAL_CREATE/GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'COMPLAINTS/MODAL_CREATE/GET_USERS_FAILURE'
}

export interface GetUsersComplaintRequest {
  type: typeof GetUsersComplaintTypes.GET_USERS_REQUEST
}

export interface GetUsersComplaintSuccess {
  type: typeof GetUsersComplaintTypes.GET_USERS_SUCCESS
  payload: { users: User[] }
}

export interface GetUsersComplaintFailure {
  type: typeof GetUsersComplaintTypes.GET_USERS_FAILURE
}

type GetUsersComplaint = GetUsersComplaintRequest | GetUsersComplaintSuccess | GetUsersComplaintFailure

export enum GetObjectByIdTypes {
  GET_OBJECT_BY_ID_REQUEST = 'COMPLAINTS/MODAL_CREATE/GET_OBJECT_BY_ID_REQUEST',
  GET_OBJECT_BY_ID_SUCCESS = 'COMPLAINTS/MODAL_CREATE/GET_OBJECT_BY_ID_SUCCESS',
  GET_OBJECT_BY_ID_FAILURE = 'COMPLAINTS/MODAL_CREATE/GET_OBJECT_BY_ID_FAILURE'
}

export interface GetObjectByIdRequest {
  type: typeof GetObjectByIdTypes.GET_OBJECT_BY_ID_REQUEST
}

export interface GetObjectByIdSuccess {
  type: typeof GetObjectByIdTypes.GET_OBJECT_BY_ID_SUCCESS
  payload: { object: FormattedObject[] }
}

export interface GetObjectByIdFailure {
  type: typeof GetObjectByIdTypes.GET_OBJECT_BY_ID_FAILURE
}

type GetObjectByIdActions = GetObjectByIdRequest | GetObjectByIdSuccess | GetObjectByIdFailure

export enum GetAgentsTypes {
  GET_AGENTS_REQUEST = 'COMPLAINTS/MODAL_CREATE/GET_AGENTS_REQUEST',
  GET_AGENTS_SUCCESS = 'COMPLAINTS/MODAL_CREATE/GET_AGENTS_SUCCESS',
  GET_AGENTS_FAILURE = 'COMPLAINTS/MODAL_CREATE/GET_AGENTS_FAILURE'
}

export interface GetAgentsRequest {
  type: typeof GetAgentsTypes.GET_AGENTS_REQUEST
}

export interface GetAgentsSuccess {
  type: typeof GetAgentsTypes.GET_AGENTS_SUCCESS
  payload: { agents: Agent[] }
}

export interface GetAgentsFailure {
  type: typeof GetAgentsTypes.GET_AGENTS_FAILURE
}

type GetAgentsActions = GetAgentsRequest | GetAgentsSuccess | GetAgentsFailure

export enum CreateComplaintTypes {
  CREATE_COMPLAINT_REQUEST = 'COMPLAINTS/MODAL_CREATE/CREATE_COMPLAINT_REQUEST',
  CREATE_COMPLAINT_SUCCESS = 'COMPLAINTS/MODAL_CREATE/CREATE_COMPLAINT_SUCCESS',
  CREATE_COMPLAINT_FAILURE = 'COMPLAINTS/MODAL_CREATE/CREATE_COMPLAINT_FAILURE'
}

export interface CreateComplaintRequest {
  type: typeof CreateComplaintTypes.CREATE_COMPLAINT_REQUEST
}

export interface CreateComplaintSuccess {
  type: typeof CreateComplaintTypes.CREATE_COMPLAINT_SUCCESS
}

export interface CreateComplaintFailure {
  type: typeof CreateComplaintTypes.CREATE_COMPLAINT_FAILURE
}

type CreateComplaintActions = CreateComplaintRequest | CreateComplaintSuccess | CreateComplaintFailure

export enum GetOperationByIdTypes {
  GET_OPERATION_REQUEST = 'COMPLAINTS/MODAL_CREATE/GET_OPERATION_REQUEST',
  GET_OPERATION_SUCCESS = 'COMPLAINTS/MODAL_CREATE/GET_OPERATION_SUCCESS',
  GET_OPERATION_FAILURE = 'COMPLAINTS/MODAL_CREATE/GET_OPERATION_FAILURE'
}

export interface GetOperationByIdRequest {
  type: typeof GetOperationByIdTypes.GET_OPERATION_REQUEST
}

export interface GetOperationByIdSuccess {
  type: typeof GetOperationByIdTypes.GET_OPERATION_SUCCESS
  payload: { operation: OperationById }
}

export interface GetOperationByIdFailure {
  type: typeof GetOperationByIdTypes.GET_OPERATION_FAILURE
}

type GetOperationActions = GetOperationByIdRequest | GetOperationByIdSuccess | GetOperationByIdFailure

export type CreateComplaintModalActions =
  | SetOpen
  | SetterComplaintActions
  | CreateComplaintActions
  | GetUsersComplaint
  | GetObjectByIdActions
  | GetAgentsActions
  | CommonComplaintsActions
  | GetOperationActions
