import React, { useMemo } from 'react'
import { Modal } from 'antd'
import Select from '../../../../components/Select/Select'
import { Reason } from '../types/cancel'
import actions from '../actions/cancel'
import { CancelState } from '../reducers/cancel'

type OptionType = { label: string; value: number }
const styles = {
  dialogSubtitle: {
    marginBottom: 20
  },
  dialogContainer: {
    maxWidth: 700
  },
  error: {
    color: '#ff0000',
    textAlign: 'center' as const,
    marginTop: 10,
    marginBottom: 0
  },
  inlineSelect: {
    display: 'inline-block',
    width: '100%'
  }
}

interface CancelPanelProps {
  cancelState: CancelState
  actions: typeof actions
}

const CancelPanel = (props: CancelPanelProps) => {
  const { actions, cancelState } = props
  const { open, guid, loading, error, operationId, reasons, reasonOption } = cancelState
  const { close, cancel, setReason } = actions
  const reasonsOptions = useMemo(
    () =>
      reasons?.map((reason: Reason) => ({
        value: reason.id,
        label: reason.reason
      })),
    [reasons]
  )

  const handleCancel = (guid: string, reasonOptionValue = 0) => {
    if (reasonOptionValue === 0) {
      alert('Debe seleccionar un motivo')
    } else {
      cancel(guid, reasonOption?.value || 0)
    }
  }

  return (
    <Modal
      title="Cancelar Devolución"
      visible={open}
      closable={!loading}
      maskClosable={!loading}
      style={styles.dialogContainer}
      onCancel={close}
      onOk={() => handleCancel(guid, reasonOption?.value || 0)}
      okText={'Sí'}
      cancelText={'Cerrar'}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: loading || !reasonOption
      }}>
      <p>¿Esta seguro que desea cancelar la operación #{operationId}?</p>
      <br />
      <p>Selecciona una razón</p>
      <Select
        loading={loading}
        placeholder="Razón"
        selected={reasonOption}
        options={reasonsOptions || []}
        onSelect={(option: any) => setReason(option)}
      />
      <p style={styles.error}>{error}</p>
    </Modal>
  )
}

export default CancelPanel
