import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'
import { ServicesListSortKey } from '../../../projectApi/TransactionHandler/ServiceType/list'
import { Pagination, Sort } from '../../../utils/searchFilterUtils'

export type ServicesTableSortKey = ServicesListSortKey
export type ServicesTableSort = Sort<ServicesTableSortKey>

export interface ServiceTableState {
  services: ServiceSearch[]
  loadingServices: boolean
  error: string
  pagination: Pagination
  sort: ServicesTableSort
}

export const GET_SERVICES_REQUEST = 'SERVICES/TABLE/GET_SERVICES_REQUEST'
export const GET_SERVICES_SUCCESS = 'SERVICES/TABLE/GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAILURE = 'SERVICES/TABLE/GET_SERVICES_FAILURE'

export interface GetServicesRequest {
  type: typeof GET_SERVICES_REQUEST
}

export interface GetServicesSuccess {
  type: typeof GET_SERVICES_SUCCESS
  payload: {
    services: ServiceSearch[]
    total: number
  }
}

export interface GetServicesFailure {
  type: typeof GET_SERVICES_FAILURE
  payload: {
    error: string
  }
}

export type GetServicesActions = GetServicesRequest | GetServicesSuccess | GetServicesFailure

export type ServicesTableActions = GetServicesActions
