import React, { useEffect } from 'react'
import ShippersTable, { ShippersTableParams } from '../components/ShippersTable'
import shippersTableActionCreators from '../actions/shippersTable'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Pagination } from '../../../utils/searchFilterUtils'
import { ShippersSearchFilter, ShippersTableSearchKey } from '../types/shippersTable'
import { Edit, Trash } from '../../../icons'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Shipper } from '../../../projectApi/Trips/Shippers/list'
import { TableActionBar } from '../../../components/TableActionBar'
import { Events } from '../../../utils/eventEmitter/events'
import { useEvents } from '../../../utils/eventEmitter'
import ShippersABMContainer from './ShippersABM'
import shippersABMActionCreators from '../actions/shippersABM'

const ShippersContainer = () => {
  const dispatch = useDispatch()

  const tableState = useSelector((state) => state.ShippersCombinerReducer.shippersTable)

  const { setModalVisibility, setDeleteModalVisibility, setCurrentShipper, setDriver, setProvider } =
    bindActionCreators(shippersABMActionCreators, dispatch)
  const { cleanTableState, getShippers, sendCSVReportOfShippers } = bindActionCreators(
    shippersTableActionCreators,
    dispatch
  )

  useEffect(() => {
    getShippers({ pagination: tableState.pagination })
  }, [])

  useEvents(
    [
      Events.Shippers.CLEAN_FILTERS,
      Events.Shippers.CREATE_SHIPPER,
      Events.Shippers.UPDATE_SHIPPER,
      Events.Shippers.DELETE_SHIPPER
    ],
    () => getShippers({ pagination: { ...tableState.pagination, page: 1 } })
  )

  const requestShippersCSV = () => {
    sendCSVReportOfShippers({ pagination: tableState.pagination, searchFilters: tableState.searchFilters })
  }

  const handleGetDeposits = (newParams: { pagination?: Pagination; searchFilters?: ShippersSearchFilter[] }) => {
    getShippers({
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    })
  }

  const handlePageChange = (newPage: number) => {
    getShippers({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: ShippersTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetDeposits({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const handleUpdateShipper = (shipper: Shipper) => {
    setModalVisibility({ modalVisible: true, editMode: true })
    setCurrentShipper(shipper)
    setDriver({ lastName: shipper.lastName, name: shipper.name, userId: shipper.userId })
    setProvider({ name: shipper.provider.name, userId: shipper.provider.id })
  }

  const handleDeleteShipper = (shipper: Shipper) => {
    setDeleteModalVisibility({ deleteModalVisible: true })
    setCurrentShipper(shipper)
  }

  const menuActions: MenuAction<Shipper>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (shipper) => handleUpdateShipper(shipper)
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (shipper) => handleDeleteShipper(shipper)
    }
  ]

  const tableProps: ShippersTableParams = {
    shippers: tableState.shippers,
    fetchingShippers: tableState.fetchingShippers,
    actions: menuActions,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    handleSearch
  }

  return (
    <>
      <TableActionBar>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            toolTip
            variant="csv"
            disabled={tableState.sendingCSVReport}
            onClickButton={requestShippersCSV}
          />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={cleanTableState} />
        </TableActionBar.Wrapper>
        <TableActionBar.ButtonAction
          titleButton={'Crear transportista'}
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar>
      <ShippersTable {...tableProps} />
      <ShippersABMContainer />
    </>
  )
}

export default ShippersContainer
