import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'

export enum PaymentTypes {
  SET_PAYMENT_BY = 'NEW_PROPOSAL_V2/SET_PAYMENT_BY'
}

export interface SetPaymentBy {
  type: PaymentTypes.SET_PAYMENT_BY
  payload: {
    paymentBy: PAYMENT_BY_VALUES
  }
}

export type PaymentActions = SetPaymentBy
