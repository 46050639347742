import React, { useMemo } from 'react'
import moment, { Moment } from 'moment'
import { DynamicTable } from '../../components/DynamicTable'
import { Column, Pagination } from '../../components/DynamicTable/types/types'
import { MenuAction } from '../../components/actionMenu/baseMenu'
import { Prospect, ProspectFilterCategories, Source, stateLead } from './entities'
import { getFilterProps, getRangePickerProps, getSearchProps } from '../../components/DynamicTable/utils/utils'
import { CategoryFilter, SearchFilter, Sorter } from '../../components/DynamicTable/utils/entities'
import { Country } from '../../components/CountrySelector/constants/constants'
import { pathOr } from 'ramda'
import styles from './LeadsTable.module.scss'
import ClientIDLinkComponent from '../../components/ClientIDLink/ClientIDLink'
import FlagCountry from '../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../components/CountrySelector/utils/utils'
import { FunnelState } from './constants'
import { Tooltip } from 'antd'

type LeadsTableProps = {
  prospects: Prospect[]
  pagination: Pagination
  actions: MenuAction<Prospect>[]
  countries: Country[]
  loading: boolean
  config: Record<string, any>
  searchFilters: SearchFilter[]
  categoryFilter: CategoryFilter<ProspectFilterCategories>
  sorter: Sorter<Prospect>
  handleSearch: (key: string, newValue: string) => void
  handleSort: (newSorter: Sorter<Prospect>) => void
  handleFilter: (newFilter: CategoryFilter<ProspectFilterCategories>) => void
  handleRangePicker: (
    key: keyof Prospect,
    startDate?: Moment,
    endDate?: Moment,
    version?: string,
    format?: string
  ) => void
  OptionsModalOpen: (leadId: number) => void
  ActionsModalOpen: (leadId: number, leadFunnelState: string, interestReasonId: number) => void
  ReasonsModalOpen: (leadId: number) => void
  setReactivateLead: (leadId: number) => void
}

const LeadsTable: React.FC<LeadsTableProps> = ({
  prospects,
  actions,
  countries,
  loading,
  pagination,
  config,
  searchFilters,
  handleSearch,
  handleFilter,
  handleRangePicker,
  categoryFilter,
  setReactivateLead,
  OptionsModalOpen,
  ActionsModalOpen,
  ReasonsModalOpen
}) => {
  const getMediums = pathOr({}, ['contactMediums'])
  const getTypes = pathOr({}, ['prospectType'])
  const getProviders = pathOr([], ['providers'])

  const getSourceFilterList = useMemo(() => {
    const sources = config.sources
    if (sources && Array.isArray(sources))
      return sources.map((source: Source) => ({ text: source.spanishDisplay, value: source.id }))
    else return []
  }, [config.sources])

  const getContactFilters = (config: any) => {
    const contacts = getMediums(config)
    return Object.entries(contacts).map(([key, value]) => ({
      text: String(value),
      value: key
    }))
  }

  const getStatusFilters = (config: any) => {
    const status = config.state ?? []
    const options = status.map((state: any) => ({ text: state.qualified, value: state.stateId }))
    return options
  }

  const getProviderFilters = (config: any) => {
    const providers = getProviders(config)
    const options = providers.map((provider: string) => ({ text: provider, value: provider }))
    return options
  }

  const getTypeFilters = (config: any) => {
    const types = getTypes(config)
    return Object.entries(types).map(([key, value]) => ({
      text: String(value),
      value: key
    }))
  }

  const formatDateLeads = (date: string) => {
    const dateData = moment(date).local().format('dd DD[/]MM[/]YY')
    const hoursData = moment(date).local().format('HH:mm')

    return (
      <div style={{ textAlign: 'start' }}>
        {dateData} <br /> {hoursData}
      </div>
    )
  }

  const getListAgents = useMemo(() => {
    return [
      { text: '0 - No asignado', value: 0 },
      ...(config.agents?.map((a: any) => ({
        text: `${a.id} - ${a.firstName} ${a.lastName}`,
        value: a.id
      })) || [])
    ]
  }, [config.agents])

  const SOURCE_FILTERS = getSourceFilterList
  const MEDIUMS_FILTERS = getContactFilters(config)
  const STATUS_FILTER = getStatusFilters(config)
  const TYPE_FILTERS = getTypeFilters(config)
  const PROVIDERS_FILTER = getProviderFilters(config)

  const columns: Column<Prospect>[] = [
    {
      key: 'country',
      label: 'País',
      maxWidth: '30px',
      renderDataCell: ({ country }) => {
        const flag = getCurrentCountry(countries, country, 'fullName')

        if (!flag) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID Lead',
      tooltip: 'ID Oportunidad',
      minWidth: 'max-content',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      key: 'userId',
      label: 'ID Cliente',
      search: getSearchProps('user_id', 'ID Cliente', handleSearch, searchFilters),
      minWidth: 'max-content',
      renderDataCell: ({ userId }) => {
        return userId ? (
          <ClientIDLinkComponent id={userId} noPadding={true}>
            {userId}
          </ClientIDLinkComponent>
        ) : (
          '-'
        )
      }
    },
    {
      key: 'provider',
      label: 'Desde',
      minWidth: 'max-content',
      filters: getFilterProps(PROVIDERS_FILTER, categoryFilter, 'provider', handleFilter)
    },
    {
      key: 'createdAt',
      label: 'Creación',
      tooltip: 'Fecha de creacion del Lead',
      minWidth: '150px',
      rangePicker: getRangePickerProps('created_at', searchFilters, (...args) => handleRangePicker(...args, 'v2', '')),
      renderDataCell: ({ createdAt }) => {
        return formatDateLeads(createdAt)
      }
    },
    {
      key: 'updatedAt',
      label: 'Última mod.',
      tooltip: 'Última fecha de modificación del Lead',
      minWidth: '150px',
      rangePicker: getRangePickerProps('updated_at', searchFilters, (...args) => handleRangePicker(...args, 'v2', '')),
      renderDataCell: ({ updatedAt, state }) => {
        if (state?.qualified === FunnelState.PENDING) return '-'
        return formatDateLeads(updatedAt)
      }
    },
    {
      key: 'winDate',
      label: 'Fecha Lead ganado',
      tooltip: 'Fecha en la que el lead transicionó a WIN',
      minWidth: '190px',
      rangePicker: getRangePickerProps('win_date', searchFilters, (...args) => handleRangePicker(...args, '', '')),
      renderDataCell: ({ winDate, state }) => {
        if ((state?.qualified === FunnelState.WINNER || state?.qualified === FunnelState.RP_VALIDATED) && winDate)
          return formatDateLeads(winDate)
        return '-'
      }
    },
    {
      key: 'name',
      label: 'Nombre y Apellido',
      search: getSearchProps('name', 'Nombre', handleSearch, searchFilters),
      minWidth: 'max-content'
    },
    {
      key: 'phone',
      label: 'Teléfono',
      minWidth: '150px',
      search: getSearchProps('phone', 'Teléfono', handleSearch, searchFilters)
    },
    {
      key: 'email',
      label: 'Email',
      minWidth: 'max-content',
      search: getSearchProps('email', 'Email', handleSearch, searchFilters)
    },
    {
      key: 'source',
      label: 'Canal de origen',
      minWidth: 'max-content',
      filters: getFilterProps(SOURCE_FILTERS, categoryFilter, 'source', handleFilter, true),
      renderDataCell: ({ source, sourceMetaData }) => {
        const text = sourceMetaData.providerLeadId
          ? `${sourceMetaData.providerLeadId} - ${sourceMetaData.campaign} -  ${sourceMetaData.source}`
          : '-'
        return <Tooltip title={text}>{source.spanishDisplay}</Tooltip>
      }
    },
    {
      key: 'contactMedium',
      label: 'Medio de contacto',
      minWidth: '180px',
      filters: getFilterProps(MEDIUMS_FILTERS, categoryFilter, 'contact_medium_id', handleFilter, true),
      renderDataCell: ({ contactMediums }) => `${contactMediums?.spanishDisplay ?? '-'}`
    },
    {
      key: 'assignedAgent',
      label: 'Agente',
      minWidth: '120px',
      search: getSearchProps('agent', 'Agente', handleSearch, searchFilters),
      renderDataCell: ({ assignedAgent }) => {
        return assignedAgent ? `${assignedAgent?.firstName} ${assignedAgent?.lastName}` : 'No asignado'
      },
      filters: config.agents?.length
        ? getFilterProps(getListAgents, categoryFilter, 'agent_ids', handleFilter, true)
        : undefined
    },
    {
      key: 'status',
      label: 'Funnel',
      minWidth: '100px',
      filters: getFilterProps(STATUS_FILTER, categoryFilter, 'status', handleFilter, true),
      renderDataCell: ({ state }) => {
        return `${state?.qualified ?? '-'}`
      }
    },
    {
      key: 'leadType',
      label: 'Tipo',
      minWidth: 'max-content',
      renderDataCell: ({ prospectType }) => {
        return `${prospectType?.name ?? '-'}`
      },
      filters: getFilterProps(TYPE_FILTERS, categoryFilter, 'prospect_type_id', handleFilter, true)
    },
    {
      key: 'actions',
      label: 'Acciones',
      renderDataCell: (row) => {
        const { state, id, interestReasonId } = row
        const action = () => {
          switch (state?.qualified) {
            case FunnelState.PENDING:
              OptionsModalOpen(id)
              break
            case FunnelState.DISCARD:
              setReactivateLead(id)
              break
            case FunnelState.MQL:
            case FunnelState.SQL:
            case FunnelState.SQLP:
            case FunnelState.MQLP:
              ActionsModalOpen(id, state?.qualified, interestReasonId)
              break
            default:
              ReasonsModalOpen(id)
              break
          }
        }

        return <ButtonActionLeads state={state} action={action} />
      }
    }
  ]
  return (
    <DynamicTable
      columns={columns}
      loading={loading}
      rows={prospects}
      customClassNames={{
        table: styles.customTableProps
      }}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default LeadsTable

export const ButtonActionLeads: React.FC<{ state: stateLead; action: () => void }> = ({ state, action }) => {
  let text: string
  switch (state.qualified) {
    case FunnelState.PENDING:
      text = 'Iniciar'
      break
    case FunnelState.MQL:
      text = 'Continuar'
      break
    case FunnelState.DISCARD:
      text = 'Recuperar'
      break
    case FunnelState.MQLP:
    case FunnelState.SQLP:
      text = 'Reactivar'
      break
    case FunnelState.SQL:
      text = 'Definir'
      break
    case FunnelState.WINNER:
    case FunnelState.RP_VALIDATED:
      text = 'Finalizado'
      break
    case FunnelState.TEST:
      text = 'Test'
      break
    default:
      text = 'Iniciar'
      break
  }

  return (
    <button
      disabled={
        state?.qualified === FunnelState.WINNER ||
        state?.qualified === FunnelState.RP_VALIDATED ||
        state?.qualified === FunnelState.TEST ||
        state.qualified === FunnelState.CANCELED
      }
      className={
        state?.qualified === FunnelState.SQLP ||
        state?.qualified === FunnelState.MQLP ||
        state?.qualified === FunnelState.DISCARD
          ? styles.reactiveButton
          : styles.startButton
      }
      onClick={action}>
      {text}
    </button>
  )
}
