import { Reducer } from 'redux'
import { CreditNoteWarningActions, CreditNoteWarningState, CreditNoteWarningTypes } from '../types/CreditNoteWarning'

const creditNoteWarningInitialState: CreditNoteWarningState = {
  modalOpen: false
}

const CreditNoteWarningReducer: Reducer<CreditNoteWarningState, CreditNoteWarningActions> = (
  state = creditNoteWarningInitialState,
  action
): CreditNoteWarningState => {
  switch (action.type) {
    case CreditNoteWarningTypes.SET_MODAL_OPEN: {
      const { modalOpen } = action.payload

      return { ...state, modalOpen }
    }
    default:
      return state
  }
}

export default CreditNoteWarningReducer
