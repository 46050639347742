import { Reducer } from 'redux'
import { GetPickingsMetricsTypes, PickingsMetricsActions, PickingsMetricsState } from '../types/pickingsMetrics'

const initialState: PickingsMetricsState = {
  quantityMetrics: [],
  fetchingMetrics: false
}

const PickingsMetricsReducer: Reducer<PickingsMetricsState, PickingsMetricsActions> = (
  state = initialState,
  action
): PickingsMetricsState => {
  switch (action.type) {
    case GetPickingsMetricsTypes.GET_PICKINGS_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetPickingsMetricsTypes.GET_PICKINGS_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        quantityMetrics: action.payload.quantityMetrics
      }
    case GetPickingsMetricsTypes.GET_PICKINGS_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default PickingsMetricsReducer
