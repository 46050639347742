import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import calendarActionCreators from '../actions/calendar'
import { ANTICIPATED_HOURS } from '../constants'
import moment, { Moment } from 'moment/moment'
import DepositDate from '../components/DepositDate'
import CalendarTextField from '../../../components/Calendar/CalendarTextField'
import { buildCalendarArray, findCalendarDiscount } from '../../../components/Calendar/utils'
import { CalendarDay, CalendarInitialStateDay } from '../../../components/Calendar/calendar.types'
import { sendToastNotificationError } from '../../../utils/notifications'
import { useGetDisabledCalendarLazyQuery } from '../../../projectApi/BFF/Deposits/getDepositCalendar.query.generated'
import { DEFAULT_TODAY } from '../reducers/calendar'

const Container = () => {
  const dispatch = useDispatch()

  const calendar = useSelector((root) => root.NewProposal.calendar)
  const calendarActions = bindActionCreators(calendarActionCreators, dispatch)

  const { getUnavailableDates, getTimeslots } = calendarActions

  useEffect(() => {
    const dateInSeconds = Number(moment(calendar.datetime).format('X'))

    getUnavailableDates()
    getTimeslots({ dateInSeconds, selectFirstTimeslot: true })
  }, [])

  const [monthGrid, setMonthGrid] = useState<CalendarDay[]>([])
  const [currentMonth, setCurrentMonth] = useState<Moment>(DEFAULT_TODAY)
  const [localDate, setLocalDate] = useState<CalendarInitialStateDay | null>({
    isInSelectedMonth: true,
    date: null
  })

  const [getCalendar, { loading: loadingCalendar }] = useGetDisabledCalendarLazyQuery({
    onCompleted: (data) => {
      const { getCalendar: calendarResponse } = data

      const populatedGrid = monthGrid.map((existingDate) => {
        const discount = findCalendarDiscount(existingDate.date, calendarResponse)

        return {
          ...existingDate,
          isDisabled: discount?.depositDisabled
        }
      })
      setMonthGrid(populatedGrid)
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: () => {
      sendToastNotificationError('Hubo un error al obtener el calendario')
    }
  })

  useEffect(() => {
    const calendar = buildCalendarArray(currentMonth)
    if (calendar.length === 0) return
    setMonthGrid(calendar)

    const today = moment().startOf('day')
    const firstDay = calendar.find((day) => !day.isBeforeToday && day.date.isAfter(today))
    const startDate = moment(firstDay?.date).format('YYYY-MM-DD')
    const lastDate = calendar[calendar.length - 1].date
    const endDate = moment(lastDate).format('YYYY-MM-DD')

    const variables = {
      startDate,
      endDate
    }
    getCalendar({ variables })
  }, [currentMonth])
  const handleChangeMonth = (direction: 'next' | 'prev') => {
    const newMonth = moment(currentMonth).add(direction === 'next' ? 1 : -1, 'month')
    setCurrentMonth(newMonth)
  }

  const handleDayPress = (day: CalendarDay) => {
    setLocalDate(day)
    calendarActions.setAvailableDate(day.date)
    if (!moment(day.date).isSame(currentMonth, 'month')) {
      return setCurrentMonth(day.date)
    }
  }

  const customDateComponent = (
    <CalendarTextField
      transportBasePrice={0}
      currentMonth={currentMonth}
      localDate={localDate}
      anticipationsDate={DEFAULT_TODAY}
      grid={monthGrid}
      loading={loadingCalendar}
      handleChangeMonth={handleChangeMonth}
      handleDayPress={handleDayPress}
      showDiscount={false}
      isDeposit
    />
  )

  const props = {
    calendar,
    calendarActions,
    anticipatedTimeInHours: ANTICIPATED_HOURS,
    customDateComponent
  }

  return <DepositDate {...props} />
}

export default Container
