import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { ListParams } from './list'

export function sendProposalsCSV({ Column, Order, Limit, Offset, id, prospect_id }: ListParams): Promise<void> {
  const formattedParams = {
    Limit,
    Offset,
    Order,
    Column,
    id,
    prospect_id,
    format: 'csv'
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operation/proposals`, { params: formattedParams })
    .then((response: AxiosResponse) => {
      return response.data
    })
    .catch((error) => {
      console.error('Error sending proposals CSV:', error)
      throw error
    })
}
