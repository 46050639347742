import React, { useCallback } from 'react'
import actions from './actions'
import { UploadContainerProps } from './container'
import { UploadFilesState } from './reducer'
import { useDropzone } from 'react-dropzone'
import styles from './styles.module.scss'
import { Spin } from 'antd'

type UploadFilesProps = UploadContainerProps & {
  uploadFilesActions: typeof actions
  uploadFilesState: UploadFilesState
}

const defaultAccept = {
  'text/*': ['.csv', '.xls', 'text/plain', 'application/vnd.ms-excel', 'csv', 'plain', '.xlsx', 'xlsx'],
  'application/*': ['vnd.ms-excel', 'vnd.ms-excel']
}

const UploadFilesComponent = (props: UploadFilesProps) => {
  const { uploadFilesActions, uploadFilesState, dropzoneOptions, fileType, uploadedFile } = props
  const { error, loadingFile } = uploadFilesState

  const onDrop = useCallback((acceptedFiles) => {
    uploadFilesActions.uploadFiles(acceptedFiles, fileType)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ accept: defaultAccept, ...dropzoneOptions, onDrop })

  return (
    <div>
      {loadingFile ? (
        <div className={styles.spinContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          {uploadedFile ? (
            <img src={uploadedFile} alt="uploaded" className={styles.uploadedPhoto} />
          ) : (
            <div className={styles.dropzoneContainer}>
              <p className={styles.dropzoneHeader}>Subida de CSV</p>
              <p className={styles.dropzoneText}>Arrastra el archivo o haz click aquí</p>
            </div>
          )}
        </div>
      )}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

export default UploadFilesComponent
