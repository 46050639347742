import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'ramda'
import { QRIcon, ScanIcon } from '../../../icons'
import actions from '../actions/RegisterObjects'
import qrModalActions from '../../../components/QRModal/QRModalActions'
import RegisterObjectsTable from '../newComponents/registerObjectsTable'
import AddMissingObjectModal from '../newComponents/AddMissingObjectModal'
import userActions from '../../Users/Users.actions'
import ScanModal from '../newComponents/scanModal'
import { TableActionBar } from '../../../components/TableActionBar'

const RegisterObjects = () => {
  const dispatch = useDispatch()
  const registerObjectsActions = bindActionCreators(actions, dispatch)
  const usersActions = bindActionCreators(userActions, dispatch)
  const qrActions = bindActionCreators(qrModalActions, dispatch)

  const { objects, params, loading, pagination, searchFilters } = useSelector((state) => state.RegisterObjects.table)

  const {
    modalOpen,
    code,
    transactions,
    loadingTransactions,
    errorTransactions,
    transactionGUID,
    lastSearchID,
    searchTransactionID,
    loading: loadingObject,
    error,
    codeTEMP,
    errorCode,
    newValue
  } = useSelector((state) => state.RegisterObjects.missingObjectModal)

  const {
    modalOpen: modalOpenScan,
    editMode,
    object,
    objectCode,
    loadingObjects,
    errorObjects,
    lastSearchIDCode,
    products,
    loadingProducts,
    errorProducts,
    lastSearchIDProduct,
    product,
    loading: loadingScan,
    error: errorScan,
    height,
    length,
    width,
    weight,
    errors,
    serviceTypeId
  } = useSelector((state) => state.RegisterObjects.scanModal)

  useEffect(() => {
    registerObjectsActions.getObjectsToRecord(params)
  }, [])

  const getKeyFilter = (key) => {
    switch (key) {
      case 'Code':
        return 'code'
      default:
        return key
    }
  }

  const handleSearch = (dataIndex, text) => {
    const { params, setSearchFilters, setPagination, getObjectsToRecord, pagination } = props
    const currentPagination = pagination
    const currentSearchFilters = props.searchFilters

    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex ? { ...searchFilter, text } : searchFilter
    )

    const newParams = {
      ...params,
      [getKeyFilter(dataIndex)]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    getObjectsToRecord(newParams)
  }

  const handlePagination = (newPage) => {
    const { currentPagination, getObjectsToRecord, params, setPagination } = props

    const Offset = (newPage - 1) * params.Limit
    const newParams = { ...params, Offset }

    setPagination({ ...currentPagination, current: newPage })
    getObjectsToRecord(newParams)
  }

  const registerObjectsPagination = {
    pageSize: params.Limit,
    total: pagination.total,
    currentPage: pagination.current,
    onPageChange: handlePagination
  }

  const props = {
    ...registerObjectsActions,
    ...qrModalActions,
    objects,
    params,
    loading,
    loadingObject,
    searchFilters,
    pagination,
    modalOpen,
    modalOpenScan,
    code,
    transactions,
    loadingTransactions,
    errorTransactions,
    transactionGUID,
    lastSearchID,
    searchTransactionID,
    error,
    codeTEMP,
    errorCode,
    newValue,
    editMode,
    object,
    objectCode,
    loadingObjects,
    errorObjects,
    lastSearchIDCode,
    products,
    loadingProducts,
    errorProducts,
    lastSearchIDProduct,
    product,
    height,
    length,
    width,
    weight,
    errors,
    serviceTypeId
  }

  const menuActions = [
    {
      label: 'Escanear Etiquetas',
      icon: <ScanIcon width="18px" height="18px" />,
      onClick: ({ real_id, identification_codes, description, service_type_id }) => {
        registerObjectsActions.setScanModalOpen(true, {
          id: identification_codes[0].code,
          description,
          serviceTypeId: service_type_id
        })
      }
    },
    {
      label: 'Ver código QR',
      icon: <QRIcon width="16px" height="16px" />,
      onClick: ({ identification_codes }) => {
        const code = identification_codes[0]?.code
        qrActions.openQRModal(code)
      }
    }
  ]

  const handleCleanFilters = () => {
    registerObjectsActions.cleanFilters()
    registerObjectsActions.getObjectsToRecordFromStore()
  }

  return (
    <div>
      <TableActionBar hideBorder>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />
          <TableActionBar.ButtonAction
            variant="secondary"
            titleButton="Agregar objeto no registrado"
            onClickButton={() => registerObjectsActions.setMissingObjectModalOpen()}
          />
        </TableActionBar.Wrapper>
        <TableActionBar.ButtonAction
          iconType="scan"
          titleButton="Escanear etiquetas"
          onClickButton={() => registerObjectsActions.setScanModalOpen(false, 0)}
        />
      </TableActionBar>
      <RegisterObjectsTable
        {...props}
        objects={objects}
        pagination={registerObjectsPagination}
        loading={loading}
        handleSearch={handleSearch}
        searchFilters={searchFilters}
        actions={menuActions}
      />
      <AddMissingObjectModal {...props} />
      <ScanModal {...props} />
    </div>
  )
}

export default RegisterObjects
