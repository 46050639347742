import { CostActions, CostTypes } from '../types/cost'
import { Discount } from '../../../projectApi/TransactionHandler/Discounts/getByCode'
import { ProspectSearchTypes } from '../types/prospect'
import { Reducer } from 'redux'

const initialState = {
  discountCode: '',
  discount: null,
  loading: false,
  applyDiscount: false,
  error: ''
}

export type CostState = {
  discountCode: string
  discount: Discount | null
  loading: boolean
  applyDiscount: boolean
  error: string
}

const root: Reducer<CostState, CostActions> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return initialState
    case CostTypes.SET_DISCOUNT_CODE: {
      return { ...state, discountCode: action.payload.discountCode, error: '' }
    }
    case CostTypes.SET_APPLY_DISCOUNT: {
      return {
        ...state,
        applyDiscount: action.payload.applyDiscount,
        error: ''
      }
    }
    case CostTypes.FETCH_DISCOUNT_REQUEST:
      return { ...state, loading: true, error: '' }
    case CostTypes.FETCH_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discount: action.payload.discount,
        error: action.payload.discount.valid ? '' : 'Código inválido'
      }
    case CostTypes.FETCH_DISCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
