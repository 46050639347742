import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import PhotographObjectsTableActionCreators from '../actionCreators/PhotographObjectsTable'
import { PhotographObjectsProps, PhotographObjectsTable } from '../components/PhotographObjectsTable'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import {
  Pagination,
  PhotographObjectsTableSearchFilter,
  PhotographObjectsTableSearchKey,
  PhotographObjectsTableSort
} from '../types/PhotographObjectsTable'
import photosActions from '../../PhotographObjects/actions/photos'
import PhotosModal_OLD from '../../PhotographObjects/containers/photos'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const PhotographObjectsTableContainer = () => {
  const dispatch = useDispatch()
  const { getObjects } = bindActionCreators(PhotographObjectsTableActionCreators, dispatch)
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)

  const openPhotosModal = bindActionCreators(photosActions.openModal, dispatch)

  const { objects, pagination, loading, sort, searchFilters } = useSelector((state) => state.PhotographObjects.table)

  const emptyPagination = { ...pagination, page: 1 }

  const handleGetObjects = (newParams: {
    pagination?: Pagination
    silentLoading?: boolean
    sort?: PhotographObjectsTableSort
    searchFilters?: PhotographObjectsTableSearchFilter[]
  }) => {
    getObjects({
      pagination: newParams.pagination || pagination,
      silentLoading: newParams.silentLoading,
      sort: newParams.sort || sort,
      searchFilters: newParams.searchFilters || searchFilters
    })
  }

  useEvents(Events.PhotographObjects.OBJECT_UPDATED, () => handleGetObjects({ silentLoading: true }))

  useEffect(() => {
    handleGetObjects({ pagination: emptyPagination })
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetObjects({ pagination: { ...pagination, page: newPage } })
  }
  const paging = {
    currentPage: pagination.page,
    pageSize: pagination.pageSize,
    total: pagination.total,
    onPageChange: handlePageChange
  }

  const handleSort = (newSort: PhotographObjectsTableSort) => {
    handleGetObjects({ sort: newSort })
  }

  const handleSearchFilters = (key: PhotographObjectsTableSearchKey, newValue: string) => {
    const newSearchFilters = searchFilters.map((filter) =>
      filter.key === key ? { ...filter, text: newValue } : filter
    )
    handleGetObjects({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const props: PhotographObjectsProps = {
    objects,
    handleOpenQRModal: openQRModal,
    pagination: paging,
    openPhotosModal,
    loading,
    handleSort,
    sort,
    handleSearchFilters,
    searchFilters
  }

  return (
    <>
      <PhotographObjectsTable {...props} />
      <PhotosModal_OLD />
    </>
  )
}

export default PhotographObjectsTableContainer
