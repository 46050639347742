import React from 'react'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDates/list'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination, SortDirection } from '../../../components/DynamicTable/types/types'
import { formatDate, formatDateWithoutHour } from '../../../utils/formatDate'
import {
  DiscountDatesDateRangeFilter,
  DiscountDatesSearchFilter,
  DiscountDatesTableDateRangeKey,
  DiscountDatesTableSearchKey,
  DiscountDatesTableSort
} from '../types/discountDates'
import { Moment } from 'moment'
import { getRangePickerProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'

type DiscountDatesPageParams = {
  discounts: Discount[]
  fetchingDiscounts: boolean
  searchFilters: DiscountDatesSearchFilter[]
  handleRangePicker: (key: DiscountDatesTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  dateRangeFilters: DiscountDatesDateRangeFilter[]
  handleSearch: (key: DiscountDatesTableSearchKey, newValue: string) => void
  sort: DiscountDatesTableSort
  handleSort: (newSorter: DiscountDatesTableSort) => void
  pagination: Pagination
  actions: MenuAction<Discount>[]
}

const DiscountDatesPage = ({
  discounts,
  fetchingDiscounts,
  pagination,
  handleRangePicker,
  dateRangeFilters,
  handleSearch,
  searchFilters,
  sort,
  handleSort,
  actions
}: DiscountDatesPageParams) => {
  const columns: Column<Discount>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'date',
      label: 'Día',
      renderDataCell: ({ date }) => formatDateWithoutHour(date?.utc() || null, '-'),
      search: getSearchProps('date', 'Día', handleSearch, searchFilters, null, `Ej.: DD-MM-AAAA`),
      sort: {
        direction: sort.direction,
        isActive: sort.field === 'date',
        onClick: (newSortDirection) =>
          handleSort({
            direction: sort.field === 'date' ? newSortDirection : SortDirection.ASC,
            field: 'date'
          })
      }
    },
    {
      key: 'discount',
      label: 'Descuento (%)',
      renderDataCell: ({ discount }) => `${discount}%`
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      renderDataCell: ({ createdAt }) => formatDate(createdAt, '-'),
      rangePicker: getRangePickerProps('createdAt', dateRangeFilters, handleRangePicker)
    },
    {
      key: 'updatedAt',
      label: 'Fecha de modificación',
      renderDataCell: ({ updatedAt }) => formatDate(updatedAt, '-')
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDiscounts}
      rows={discounts}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default DiscountDatesPage
