export enum VehiclesEventsEnum {
  CLEAN_FILTERS = 'VEHICLES/CLEAN_FILTERS',
  CREATE_VEHICLE = 'VEHICLES/CREATE_VEHICLE',
  DELETE_VEHICLE = 'VEHICLES/DELETE_VEHICLE',
  EDIT_VEHICLE = 'VEHICLES/EDIT_VEHICLE'
}

export type VehiclesEvents = {
  [VehiclesEventsEnum.CLEAN_FILTERS]: () => void
  [VehiclesEventsEnum.CREATE_VEHICLE]: () => void
  [VehiclesEventsEnum.DELETE_VEHICLE]: () => void
  [VehiclesEventsEnum.EDIT_VEHICLE]: () => void
}
