import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Carousel from '../../components/Carousel/Carousel'
import { Remittance } from '../../projectApi/TransactionHandler/Remittances/search'
import styles from './styles.module.scss'
import { ClosedBox, Info } from '../../icons'

export type RemittanceModalProps = {
  open: boolean
  remittance: Remittance | null
  onClose: () => void
  error: string
  loading: boolean
}

const RemittanceModal: React.FC<RemittanceModalProps> = ({ open, remittance, error, onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const handleCloseModal = () => {
    onClose()
  }

  useEffect(() => {
    if (open) {
      setSelectedImage(remittance?.photos.length ? remittance?.photos[0].url : null)
    } else setSelectedImage(null)
  }, [remittance, open])

  return (
    <Modal
      visible={open}
      destroyOnClose
      title={'Ver remito'}
      onCancel={handleCloseModal}
      centered
      style={{ width: 'unset' }}
      bodyStyle={{ minHeight: remittance?.photos.length ? '80vh' : '' }}
      footer={null}>
      {!remittance?.photos.length ? (
        <div className={styles.noRemittance}>
          <Info fill="#5D58F7" dimensions={{ width: 32, height: 32 }} />
          {error || 'Esta transacción no posee remito.'}
        </div>
      ) : (
        <Carousel
          images={remittance.photos.map((p) => p.url)}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      )}
      {remittance?.quantityItems && (
        <div className={styles.quantityItems}>
          <ClosedBox />
          Cantidad de objetos: {remittance.quantityItems}
        </div>
      )}
    </Modal>
  )
}

export default React.memo(RemittanceModal)
