import React, { useState } from 'react'
import { Col, Modal, Row, Spin } from 'antd'
import Address from '../../../components/Address/container'
import InputGroup from '../../../components/InputGroup'
import { AddressModalState } from '../types/addressModal'
import { Params } from '../types/address'
import { AddressModalActionCreator } from '../actions/addressModal'
import { AddressActionCreator } from '../actions/address'
import { Address as AddressType } from '../../../components/Address/types'

const styles = {
  modal: {
    maxWidth: 700
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto',
    position: 'relative'
  } as React.CSSProperties,
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center' as const,
    marginTop: 10,
    marginBottom: 0
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red !important'
  },
  select: {
    width: '100%'
  },
  age: {
    marginLeft: 8
  },
  astrologicalSign: {
    marginBottom: 0,
    marginTop: 8
  },
  loadingContainer: {
    zIndex: 2,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  } as React.CSSProperties,
  loadingText: {
    fontSize: 16,
    marginTop: 15
  },
  actualPhone: {
    margin: 0,
    display: 'inline-block'
  },
  buttonModifyPhone: {
    float: 'right'
  }
}

type AddressModalComponentProps = {
  addressModal: AddressModalState
  addressFromAddress: AddressType
  errorFromAddress: string
  setErrorAddress: (error: string) => void
  addressModalActions: typeof AddressModalActionCreator
  cleanAddress: () => void
  addressTableActionCreator: typeof AddressActionCreator
  paramsFromAddress: Params
}

const AddressModal = (props: AddressModalComponentProps) => {
  const {
    errorFromAddress,
    addressFromAddress,
    addressModal,
    setErrorAddress,
    addressModalActions,
    cleanAddress,
    addressTableActionCreator,
    paramsFromAddress
  } = props
  const { openModal, loading, isEdit, error, userId, addressId } = addressModal
  const { addressGoogle, city, country, number, postalCode, province, street } = addressFromAddress
  const [errors, setErrors] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)

  const handleClose = () => {
    cleanAddress()
    setErrors([])
    addressModalActions.closeModal()
  }

  const HandleErrors = () => {
    let anyError = false
    setErrors([])
    if (addressGoogle === '') {
      anyError = true
      setErrorAddress('Falta completar la dirección')
    }

    if (city === '') {
      anyError = true
      setErrors((prevState) => [...prevState, 'Falta completar la ciudad'])
    }
    if (country === '') {
      anyError = true
      setErrors((prevState) => [...prevState, 'Falta completar el país'])
    }
    if (number === '') {
      anyError = true
      setErrors((prevState) => [...prevState, 'Falta completar el número del domicilio'])
    }
    if (street === '') {
      anyError = true
      setErrors((prevState) => [...prevState, 'Falta completar la calle de residencia'])
    }
    if (province === '') {
      anyError = true
      setErrors((prevState) => [...prevState, 'Falta completar la provincia'])
    }
    if (!postalCode) {
      anyError = true
      setErrors((prevState) => [...prevState, 'Falta completar el código postal'])
    }

    return anyError
  }

  const handleCreateNewAddress = async () => {
    if (!HandleErrors()) {
      setIsSaving(true)
      if (!isEdit) {
        await addressModalActions.addNewAddress(addressFromAddress, userId)
        await addressTableActionCreator.getAddresses(userId, paramsFromAddress)
        cleanAddress()
        setIsSaving(false)
        setErrors([])
      } else {
        await addressModalActions.updateAddress(addressFromAddress, userId, addressId)
        await addressTableActionCreator.getAddresses(userId, paramsFromAddress)
        cleanAddress()
        setIsSaving(false)
        setErrors([])
      }
    }
  }

  return (
    <Modal
      title={isEdit ? 'Editar dirección' : 'Agregar dirección'}
      visible={openModal}
      closable={true}
      keyboard={false}
      onOk={() => handleCreateNewAddress()}
      onCancel={() => handleClose()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading || isSaving
      }}
      okText="Guardar"
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      {loading && isEdit && !isSaving && (
        <div style={styles.loadingContainer}>
          <Spin size="large" />
          <p style={styles.loadingText}>Cargando dirección, por favor espere...</p>
        </div>
      )}
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24} id='"address'>
          <InputGroup title="Dirección">
            <Address />
          </InputGroup>
        </Col>
      </Row>
      <div className="container">
        {errors.map((errorArray, index) => (
          <div key={index} style={styles.error}>
            <p style={styles.errorFieldLabel}>{errorArray}</p>
          </div>
        ))}
        <p style={styles.error}>{errorFromAddress || error}</p>
      </div>
    </Modal>
  )
}

export default AddressModal
