import {
  CLOSE_CHECKOUT_MODAL,
  GENERATE_CHECKOUT_LINK_FAILURE,
  GENERATE_CHECKOUT_LINK_REQUEST,
  GENERATE_CHECKOUT_LINK_SUCCESS,
  GET_EXISTING_CHECKOUT_LINK_FAILURE,
  GET_EXISTING_CHECKOUT_LINK_REQUEST,
  GET_EXISTING_CHECKOUT_LINK_SUCCESS,
  GenerateCheckoutLinkActions,
  GenerateCheckoutLinkState,
  OPEN_CHECKOUT_MODAL
} from '../types/checkout'

const initialState: GenerateCheckoutLinkState = {
  generated: false,
  userId: 0,
  userName: '',
  userLastName: '',
  userCountry: '',
  open: false,
  link: '',
  loading: false,
  loadingExistingLink: false,
  error: ''
}

function root(state = initialState, action: GenerateCheckoutLinkActions): GenerateCheckoutLinkState {
  switch (action.type) {
    case OPEN_CHECKOUT_MODAL:
      return {
        ...state,
        open: true,
        userId: action.payload.id,
        userName: action.payload.name,
        userLastName: action.payload.lastName,
        userCountry: action.payload.country
      }
    case CLOSE_CHECKOUT_MODAL:
      return initialState
    case GET_EXISTING_CHECKOUT_LINK_REQUEST:
      return {
        ...state,
        loadingExistingLink: true,
        error: ''
      }
    case GET_EXISTING_CHECKOUT_LINK_SUCCESS: {
      const { exists, link } = action.payload
      return {
        ...state,
        generated: exists,
        loadingExistingLink: false,
        link
      }
    }
    case GET_EXISTING_CHECKOUT_LINK_FAILURE:
      return {
        ...state,
        loadingExistingLink: false,
        error: action.payload.error
      }
    case GENERATE_CHECKOUT_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case GENERATE_CHECKOUT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        link: action.payload.link
      }
    case GENERATE_CHECKOUT_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default root
