import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import CarouselModal from '../../../components/CarouselModal/containers/CarouselModal'
import RemittanceModalContainer from '../../RemittanceModal/container'
import singleRemovalActionCreators from '../actions/singleRemoval'
import SingleRemoval from '../components/singleRemoval'

interface ParamTypes {
  removalId: number
}

const useParsedParams = (): ParamTypes => {
  const { removalId } = useParams<{ removalId: string }>()
  return { removalId: parseInt(removalId) }
}

const SingleRemovalContainer = () => {
  const singleRemovalViewState = useSelector((state) => state.SingleRemoval.main)
  const countries = useSelector((state) => state.CountrySelector.countries)
  const dispatch = useDispatch()

  const actionsingle = bindActionCreators(singleRemovalActionCreators, dispatch)

  const { removalId } = useParsedParams()

  useEffect(() => {
    actionsingle.getRemovalDetails(removalId)
  }, [])

  return (
    <>
      <RemittanceModalContainer />
      <CarouselModal />
      {singleRemovalViewState.removalDetails && (
        <SingleRemoval
          {...singleRemovalViewState}
          removalDetails={singleRemovalViewState.removalDetails}
          removalId={removalId}
          countries={countries}
        />
      )}
    </>
  )
}

export default SingleRemovalContainer
