import { API } from '../../projectApi'
import { AppThunk } from '../../store'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import { UploadPhotoActionTypes, UploadPhotoRequest, UploadPhotoSuccess } from './types'
import Emitter from '../../utils/eventEmitter'
import { Events } from '../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../utils/notifications'

const actions = {
  uploadPhotos:
    (photos: File[], fileType: string): AppThunk =>
    async (dispatch) => {
      const toast = setToastLoading('Las fotos se están subiendo, por favor espera un momento')

      const requests = photos.map((photo) => API.CategoryCreation.Photo.uploadPhoto({ photo, fileType }))
      const requestAction: UploadPhotoRequest = { type: UploadPhotoActionTypes.UPLOAD_PHOTO_REQUEST }
      dispatch(requestAction)

      try {
        const responses = await Promise.all(requests)
        setToastSuccessUpdate(toast, { render: 'Las fotos se subieron correctamente' })
        const successAction: UploadPhotoSuccess = { type: UploadPhotoActionTypes.UPLOAD_PHOTO_SUCCESS }
        dispatch(successAction)
        Emitter.emit(Events.UploadPhotos.UPLOAD_PHOTO_SUCCESS, responses)
      } catch (error) {
        setToastErrorUpdate(toast, { render: 'Hubo un error al subir las fotos' })
        handleErrorsWithAction(error, UploadPhotoActionTypes.UPLOAD_PHOTO_FAILURE, dispatch)
      }
    }
}

export default actions
