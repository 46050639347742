import React, { Component } from 'react'
import { URL_BASE_CATEGORIES } from '../../endpoints'
import { Modal } from 'antd'
import { apiPrivate } from '../../api'

class DeletePanel extends Component {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.handleClose()
  }

  delete() {
    return apiPrivate
      .delete(URL_BASE_CATEGORIES + '/category/' + this.props.id)
      .then((response) => {
        this.handleClose()
      })
      .catch((error) => {
        if (error.response.data.description) {
          alert(error.response.data.description)
        }
      })
  }

  render() {
    return (
      <Modal
        title="Borrar Categoría"
        visible={this.props.open}
        onOk={this.delete}
        onCancel={this.handleClose}
        okText={'Eliminar'}
        cancelText={'Cancelar'}></Modal>
    )
  }
}

export default DeletePanel
