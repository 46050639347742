import { validFormatCodes } from './objectCodeValidation'

const attributeMap = {
  ErrorUpdateAttributes: 'Algún atributo está asociado a un objeto'
}

const removalsValidationMap = {
  'RHU-1': 'Hubo un error al leer el id de la devolución',
  'RHIP-1': 'Hubo un error al leer el id de la devolución',
  'RHU-2': 'Hubo un error al actualizar la devolución',
  'RHU-3': 'El monto cuando hay existen deudas no puede ser 0',
  'RHU-4': 'La transición del estado es incorrecta',
  'RMSG-1': 'Hubo un error al actualizar la devolución',
  'RMSG-2': 'Hubo un error al actualizar la devolución',
  'RMSU-1': 'Hubo un error al actualizar la devolución',
  'RHCR-1': 'Hubo un error al leer el id de la devolución',
  'RHCR-2': 'Hubo un error al actualizar la devolución',
  'RHCR-3': 'La transición del estado es incorrecta',
  'registry-date-error': 'La fecha de registro del objeto es inferior a 48 horas'
}

const transactionsHandler = {
  PCF: 'El cliente no esta asociado a colppy'
}

const serviceType = {
  'STPC-1': 'El tipo de servicio ya existió'
}

const addressCodeMap = {
  'AHC-1': 'El formato de los datos es incorrecto',
  'AHC-2': 'Fallo en la creación del registro',
  'AHU-1': 'El formato del ID es inválido',
  'AHU-2': 'El formato de los campos es incorrecto',
  'AHG-1': 'El formato de ID es inválido',
  'AHCM-1': 'El Formato del ID es inválido',
  'AHCM-2:': 'El Formato de userID invalido',
  'AHCM-3': 'La dirección no puede ser principal, ya que se encuentra fuera de rango',
  'AHCMS-1': 'El usuario no tiene dirección principal',
  'AHCMS-2': 'El usuario tiene más de una dirección principal',
  'AHD-1': 'La dirección no se puede eliminar, porque no pertenece al usuario',
  'AHD-2': 'El formato del ID es invalido',
  'AHD-3': 'El formato del userID es invalido',
  'AHD-4': 'El usuario no puede quedarse sin una dirección asociada'
}

const discountDates = {
  'specific-discount-not-found': 'Descuento no encontrado.',
  'there-is-already-specific-discount': 'Ya existe un descuento para el día seleccionado.',
  'date-before-to-today': 'La fecha seleccionada es posterior a la de hoy.',
  'discount-value-out-of-range': 'Valor de descuento fuera del rango permitido (entre -∞ y 100).',
  'delete-specific-discount-fails': 'Fallo a la hora de eliminar el descuento.',
  'update-specific-discount-fails': 'Fallo a la hora de editar el descuento.',
  'create-specific-discount-fails': 'Fallo a la hora de crear el descuento.',
  'list-specific-discount-fails': 'Fallo a la hora de listar los descuentos.'
}

const discountDays = {
  'discount-already-exist': 'Ya existe un descuento para ese día.',
  'discount-not-found': 'Descuento no encontrado.'
}

const ABMDepositErrors = {
  'error-getting-deposit': 'Error al obtener los depósitos.',
  'error-deposit-owner': 'No puedes eliminar un depósito del que no eres dueño.',
  'error-deposit-object-deposited': 'El depósito tiene objetos depositados.'
}

const objectsCodeMap = {
  'OHC-1': 'El GUID de la transacción es requerido',
  'OHC-2': 'Hubo un error al crear el objeto',
  'OHC-3': 'El código del objeto es requerido',
  'OHC-4': 'El código del objeto no es válido',
  'OHC-5': 'El código del objeto no tiene un formato válido',
  'OHC-6': (data) => {
    const foundFormatCode = validFormatCodes.find((formatCode) => data.message.match(formatCode) !== null)
    const lastCode = data.message.match(foundFormatCode)

    return `La diferencia entre el anteriormente ingresado y este es superior a 1000. Ultimo código ingresado: ${lastCode}`
  },
  'ODRSS-1': 'No se pudieron obtener las razones de eliminación de los objetos',
  'BHSB-1': 'Ya se ha realizado la generación de abonos del mes actual'
}

const tripCodeMap = {
  'TPI-1': 'Para iniciar el viaje debe asignar un transportista',
  'TCIT-1': 'Error al querer iniciar el viaje ya que la transacción tiene la picking abierta'
}

const paymentCollectorMap = {
  'PMPC-1': 'Este método de pago ya existe',
  'SCC-1': 'Su tarjeta no fue ingresada correctamente, por favor contáctese con soporte'
}

const payBillingColppy = {
  'ERR-1':
    'No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó. Estamos procesando tu pago.',
  'ERR-2': 'Revisa el número de tarjeta.',
  'ERR-3': 'Fecha de vencimiento invalida',
  'ERR-4': 'Datos incorrectos, Revisa los datos.',
  'ERR-5': 'El código de seguridad de la tarjeta es invalido',
  'ERR-6': 'No pudimos procesar tu pago',
  'ERR-7': 'Debes autorizar ante el pago con el pago de monto.',
  'ERR-8':
    'Llama a tu entidad bancaria para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.',
  'ERR-9': 'No pudimos procesar tu pago.',
  'ERR-10':
    'Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.Tu pago fue rechazado.',
  'ERR-11': 'Elige otro de los medios de pago, te recomendamos con medios en efectivo.',
  'ERR-12': 'Tu método de pago no tiene fondos suficientes.',
  'ERR-13': 'El metodo de pago no procesa pagos en cuotas.',
  'ERR-14': 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
  'ERR-15': 'El método de pago no procesó el pago.',
  'ERR-16': 'No se pudo procesar el pago consulte con un ascesor',
  'DEBTS-PAY-CONCEPT-COUNT-BIGGER-THAN-LIMIT-1': 'Se quiere cobrar mas de 50 facturas',
  'CON-INVALID-STATUS-1': 'El estado de la factura/s a cobrar son o es incorrecto',
  'CON-NOT-EXIST-IN-COLPPY-10': 'El concepto no existe en colppy',
  'CON-PAID-1': 'La factura ya fue pagada'
}

const loginErrors = {
  'APL-2': 'El email y/o contraseña son incorrectos.\nPor favor, revise sus credenciales.'
}

const userCorpo = {
  'user-belonging-to-corporate-account': 'El usuario pertenece a la cuenta corporativa.',
  'invite-already-used': 'La invitación no puede ser eliminada porque ya fue usada.',
  'the-user-has-a-pending-invite': 'El usuario tiene una invitación pendiente para esta cuenta.'
}

const locationsErros = {
  'error-location-owner': 'Error al tratar de obtener las ubicaciones, Debe poseer el depósito.',
  'error-location-object-deposited': 'No se puede eliminar la ubicación, ya que posee objetos depositados.',
  'error-location-volume-existing': 'El volumen no puede ser menor al volumen ya ocupado.'
}

const userDebtsReminder = {
  'err-billing-not-found': 'Abono no encontrado',
  'err-billing-with-incorrect-status': 'El abono se encuentra en un estado incorrecto.',
  'err-billing-is-not-monthly': 'El abono no es del tipo mensual.',
  'err-billing-was-billed-ten-days-ago': 'El abono se facturó hace menos de 10 días.',
  'err-user-billing-information-not-found': 'El usuario no posee información de facturación',
  'err-total-equals-zero': 'El usuario no posee deudas'
}

const salesRequest = {
  'invalid-status': 'La venta no se puede completar porque tiene un estado incorrecto.',
  'err-bad-request-pve': 'Los datos enviados no son correctos. Revíselos y vuelva a intentarlo.',
  'err-create-object-sale-request': 'El objeto ya es parte de una solicitud de venta.',
  'err-create-object-donation-request': 'El objeto ya es parte de una donación.'
}
const billings = {
  'user-disable': 'El usuario no está habilitado para facturar.',
  'APC-1': 'El abono ya ha sido facturado.',
  'AHA-9': 'El proceso se ha ejecutado en la última hora',
  'invalid-country-code': 'El código de país no es válido.',
  F: 'Algo salió mal.',
  U0: 'Error en los parámetros de la solicitud.'
}

const corporateProducts = {
  // CREATE PRODUCT
  'bad-request': 'Error al realizar la petición.',
  ErrorMapParentId: 'El parent Id no es válido.',
  'solution-not-found': 'No se encontró la solución.',
  'error-search-product-solution': 'Error al buscar la solución del producto.',
  'categories-not-found': 'Categoria no encontrada.',
  'err-product-create': 'Error al crear el producto.',
  'PSG-1': 'Error al obtener los paises.',
  'create-product-by-country-fails': 'Error al crear el producto por país.',
  'err-create-product-solution': 'Error al crear la solución del producto.',

  // UPDATE PRODUCT
  'err-product-not-found': 'Producto no encontrado.',
  'err-product-has-another-owner': 'El producto tiene otro dueño.',
  'invalid-minimum-value': 'El valor mínimo de desviación no es válido.',
  'invalid-maximum-value': 'El valor máximo de desviación no es válido.',
  'err-product-update': 'Error al actualizar el producto.',
  'PSC-3': 'Error al obtener productos por país.',
  'err-update-product-by-country': 'Error al actualizar el producto por país.',
  'error-delete-product-solution': 'Error al eliminar la solución del producto.',
  'err-product-is-in-use': 'El producto está en uso por un usuario.'
}

const providers = {
  'err-provider-not-exist': 'No existe el proveedor.',
  'err-provider-with-shippers': 'No se puede eliminar porque el proveedor tiene transportistas.',
  'err-provider-with-vehicle': 'No se puede eliminar porque el proveedor tiene vehículos.',
  'err-save-provider': 'Error al guardar proveedor.',
  'err-delete-provider': 'Error al eliminar proveedor.'
}

const shippers = {
  'err-update-shipper-dont-exist': 'No existe el registro de transportista al querer hacer update.',
  'err-exist-shipper': 'El transportista ya existe para el proveedor.',
  'err-get-user': 'No se encuentra el user para el cual quiere crearse el transportista.',
  'err-delete-shipper-dont-exist': 'El transportista que se quiere borrar no existe en la db.',
  'err-get-shippers': 'Error al obtener los transportistas de la base de datos.',
  'err-create-shipper': 'Error al crear el trasnportista en la base de datos.',
  'err-save-shipper': 'Error al actualizar transportista en la base de datos.',
  'err-delete-shipper': 'Error al querer borrar el registro de la base de datos.',
  'err-get-provider-create-shipper':
    'Error buscando el provider para asignarselo al transportista que estamos creando.',
  'err-shipper-is-assigned': 'El transportista tiene viajes asignados, no se puede eliminar.'
}

const operationCalendar = {
  'operation-specific-day-already-exist': 'Error al deshabilitar. Ya existe operación en la fecha seleccionada.'
}

const complaints = {
  'err-get-user-not-found': 'Error al obtener reclamos.'
}

const billingDiscounts = {
  'invalid-start-and-end-date': 'La fecha de inicio no puede ser mayor a la fecha de fin.',
  'invalid-discount-percent': 'El porcentaje de descuento no puede ser mayor a 100.',
  'invalid-discount-with-fixed-and-percentage': 'No se puede enviar descuento fijo y porcentaje al mismo tiempo.',
  'user-discount-without-fixed-and-percentage-not-supported': 'No se puede crear un descuento sin valor.',
  'user-discount-reason-not-found': 'Razón de descuento no encontrada.',
  'user-discount-not-found': 'Descuento para el usuario no encontrado.',
  'invalid-fixed-amount-discount': 'El monto de descuento no puede ser menor a 0.'
}

const errorCodeMap = {
  ...addressCodeMap,
  ...discountDates,
  ...discountDays,
  ...ABMDepositErrors,
  ...removalsValidationMap,
  ...objectsCodeMap,
  ...tripCodeMap,
  ...paymentCollectorMap,
  ...payBillingColppy,
  ...transactionsHandler,
  ...loginErrors,
  ...attributeMap,
  ...userCorpo,
  ...locationsErros,
  ...userDebtsReminder,
  ...billings,
  ...shippers,
  ...providers,
  ...salesRequest,
  ...corporateProducts,
  ...billings,
  ...billingDiscounts,
  ...operationCalendar,
  ...serviceType,
  ...complaints
}

export const getErrorMessageWithCode = (data) => {
  const errorCode = errorCodeMap[data.code]
  if (typeof errorCode === 'string') return errorCode
  if (typeof errorCode === 'function') return errorCode(data)
}
