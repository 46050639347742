import React from 'react'
import { Typography, Row, Col } from 'antd'
import InputGroup from '@/components/InputGroup'
import { InputTitle } from '@/components/NewCard/NewCard'
import ImagePlaceholder from '@/icons/imagePlaceholder'
import styles from '../ComplaintUpdateView.module.scss'
import ImageTooltip from '@/sections/DepositsValidation/components/ImageTooltip'
import { ComplaintType } from '../../types/createComplaintModal'
import { useLocalization } from '@/packages/localization'
import { Complaint } from '@/projectApi/Complaints/list'
import { parseMomentDate } from '@/components/DynamicTable/utils/utils'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'

const { Text } = Typography

type ClientDetailsProps = {
  complaint: Complaint
  reasons: ComplaintsReasons[]
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ complaint, reasons }) => {
  const { complaintObjects, complaintType, createdAt, description, id, photos, reasonId, clientData, createUserData } =
    complaint
  const { strings } = useLocalization()

  return (
    <div className={styles.clientDetailsContainer}>
      <InputGroup title={strings.ComplaintsViewScreen.updateView.clientDetail.title}>
        <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.clientName} />
        <Text>{clientData ? `${clientData.id} - ${clientData.name} ${clientData.lastName}` : '-'}</Text>
      </InputGroup>

      <div
        style={{
          overflow: 'hidden'
        }}>
        <InputGroup
          title={strings.ComplaintsViewScreen.createComplaintModal.fields.informationGroupTitle}
          className={styles.detailGroup}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.creationDateTitle} />
              <Text>{parseMomentDate(createdAt, 'left', true) || '-'}</Text>
            </Col>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.complaintId} />
              <Text>{id || '-'}</Text>
            </Col>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.creator} />
              <Text>
                {createUserData ? `${createUserData.id} - ${createUserData.name} ${createUserData.lastName}` : '-'}
              </Text>
            </Col>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.reasonTitle} />
              <Text>{reasons.find((reason) => reason.id === reasonId)?.description || '-'}</Text>
            </Col>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.typeTitle} />
              <Text>{ComplaintType[complaintType as keyof typeof ComplaintType] || '-'}</Text>
            </Col>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.operationIdTitle} />
              <Text>{complaint.operationId || '-'}</Text>
            </Col>
            <Col span={8}>
              <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.daysOpen} />
              <Text>{complaint.daysOpen || '-'}</Text>
            </Col>
          </Row>

          <Row>
            <div className={styles.rowObjects}>
              <div className={styles.titleObjects}>
                <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.objectId} />
                <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.objectName} />
              </div>

              {complaintObjects.length > 0 ? (
                complaintObjects.map((object, index) => (
                  <div key={index + object.objectId} className={styles.itemObject}>
                    <Text
                      style={{
                        width: '70px'
                      }}>
                      {object.objectId}
                    </Text>
                    <Text>{object.object.name || '-'}</Text>
                  </div>
                ))
              ) : (
                <Text>{strings.ComplaintsViewScreen.updateView.clientDetail.emptyObjects}</Text>
              )}
            </div>
          </Row>

          <Row>
            <InputTitle title={strings.ComplaintsViewScreen.updateView.complaintDetails.title} />
            <Text>{description}</Text>
          </Row>

          <div style={{ overflow: 'hidden' }}>
            {photos && photos.length > 0 ? (
              <div className={styles.clientCarouselContainer}>
                {photos.map((photo, index) => (
                  <div key={index} className={styles.clientCarousel}>
                    <div className={styles.imageWrapper}>
                      {photo.url ? (
                        <ImageTooltip
                          src={photo.url}
                          alt={`Photo ${index + 1}`}
                          maxWidth="50vw"
                          maxHeight="50vh"
                          imgHeight="8rem"
                          imgWidth="8.5rem"
                          placement={undefined}
                          placeholder="/img/image-placeholder.svg"
                        />
                      ) : (
                        <ImagePlaceholder />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <InputTitle title={strings.ComplaintsViewScreen.updateView.clientDetail.evidence} />
                <Text style={{ textAlign: 'center' }}>
                  {strings.ComplaintsViewScreen.updateView.clientDetail.emptyPhotos}
                </Text>
              </div>
            )}
          </div>
        </InputGroup>
      </div>
    </div>
  )
}

export default ClientDetails
