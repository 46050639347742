import { list } from './list'
import { main } from './main'
import { disable } from './disable'
import { add } from './add'

export const PaymentMethodsAPI = {
  list,
  main,
  disable,
  add
}
