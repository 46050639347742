import React from 'react'
import userActions from '../../sections/Users/Users.actions'
import { connect } from 'react-redux'
import { bool, func, number } from 'prop-types'
import { Button } from 'antd'
import { useHistory } from 'react-router'

const styles = {
  baseStyles: {
    height: 'auto'
  },
  buttonPadding: { padding: 0, paddingRight: 10 }
}

const ClientIDLinkComponent = ({ id, selectUserAndFilter, children, noPadding }) => {
  const history = useHistory()
  return (
    <Button
      style={{ ...styles.baseStyles, ...(noPadding ? { padding: 0 } : styles.buttonPadding) }}
      type="link"
      onClick={(e) => {
        e.stopPropagation()
        selectUserAndFilter(id, history)
      }}>
      {children}
    </Button>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectUserAndFilter: (id, history) => dispatch(userActions.selectUserAndFilter(id, history))
  }
}

ClientIDLinkComponent.propTypes = {
  id: number.isRequired,
  selectUserAndFilter: func.isRequired,
  noPadding: bool
}

export default connect(null, mapDispatchToProps)(ClientIDLinkComponent)
