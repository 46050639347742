import React, { useMemo } from 'react'
import { Checkbox, Modal } from 'antd'
import Select from '../../../../components/Select/Select'
import styles from './stepModals.module.scss'
import { LoseReasonOption, Option } from '../../entities'
import { STEP_OPTION, FunnelState } from '../../constants'
import { pathOr } from 'ramda'
import { useHistory } from 'react-router'
import { PROPOSAL_MODES } from '../../../../common/operations/constants'
import InputFieldWithError from '../../../../components/InputFieldWithError/inputFieldWithError'

export type ReasonsModalProps = {
  openModal: boolean
  interestReason: string
  discardReason: string
  loseReason: string
  disabledSelect: boolean
  leadFunnelState?: FunnelState | string
  config: any
  leadId: number
  lackInterestReason: string
  textReason: {
    inputOpen: boolean
    value: string
    errorMessage: string
    limitText: number
  }
  handleClose: () => void
  setInterestReason: (interestReason: string) => void
  setDiscardReason: (discardReason: string) => void
  setLoseReason: (loseReason: string) => void
  setCheckSelected: (interestReason: string) => void
  setLackInterestReason: (lackInterestReason: string) => void
  setTextReason: (textReason: string) => void
  setOpenInput: (inputOpen: boolean) => void
  onOk: () => void
}

export type ReasonsProps = {
  title?: string
  text?: string
  options?: Option[]
  checkOption?: boolean
  placeHolder?: string
  onClickCard?: () => void
  value?: string
  setValue?: (value: string) => void
}

const getReasons = ({
  leadFunnelState,
  discardReasons,
  interestReasons,
  loseOptionsMQL,
  loseOptionsSQL,
  discardReason,
  loseReason,
  lackInterestReason,
  setDiscardReason,
  setLoseReason,
  setLackInterestReason
}: {
  leadFunnelState?: FunnelState | string
  discardReasons?: Option[]
  interestReasons?: Option[]
  loseOptionsMQL?: Option[]
  loseOptionsSQL?: Option[]
  interestReason?: string
  discardReason?: string
  loseReason?: string
  lackInterestReason: string
  setInterestReason: (interestReason: string) => void
  setDiscardReason: (discardReason: string) => void
  setLoseReason: (loseReason: string) => void
  setLackInterestReason: (lackInterestReason: string) => void
}): ReasonsProps => {
  switch (leadFunnelState) {
    case FunnelState.MQL:
      return {
        title: 'MQL Perdido',
        text: '¿Por qué motivo se perdió el Lead?',
        options: loseOptionsMQL,
        placeHolder: 'Seleccione un motivo de perdida',
        setValue: setLoseReason,
        value: loseReason,
        checkOption: false
      }
    case FunnelState.SQL:
      return {
        title: 'SQL Perdido',
        text: '¿Por qué motivo se perdió el prospecto?',
        placeHolder: 'Seleccione un motivo de perdida',
        options: loseOptionsSQL,
        setValue: setLoseReason,
        value: loseReason,
        checkOption: false
      }
    case FunnelState.DISCARD:
      return {
        title: 'Lead descartado',
        text: '¿Por qué motivo se descartó el Lead?',
        placeHolder: 'Seleccione un motivo de descarte',
        options: discardReasons,
        setValue: setDiscardReason,
        value: discardReason
      }
    case FunnelState.LACK_INTEREST:
      return {
        title: 'Antes de avanzar con el Retiro indica el motivo de interés',
        placeHolder: 'Seleccione un motivo de interés',
        options: interestReasons,
        setValue: setLackInterestReason,
        value: lackInterestReason
      }
    default:
      return {}
  }
}

const ReasonsModal: React.FC<ReasonsModalProps> = ({
  openModal,
  interestReason,
  discardReason,
  loseReason,
  disabledSelect,
  leadFunnelState,
  config,
  leadId,
  lackInterestReason,
  textReason,
  handleClose,
  setInterestReason,
  setDiscardReason,
  setLoseReason,
  setTextReason,
  setLackInterestReason,
  setOpenInput,
  onOk
}) => {
  const history = useHistory()
  const Title = ({ title }: { title?: string }) => <h2 className={styles.reasonsTitle}>{title}</h2>

  const getOptions = (value: string) => {
    const options = pathOr({}, [value], config)
    return Object.entries(options).map(([key, value]) => ({
      label: value,
      value: Number(key)
    }))
  }

  const discardReasons = getOptions('discardReasons')
  const interestReasons = getOptions('interestReason')

  const getReasonsLose = config?.loseReasonsNew ?? []

  const loseOptionsMQL = useMemo(() => {
    return getReasonsLose
      .filter((reason: LoseReasonOption) => reason.state.qualified === FunnelState.MQLP)
      .map((mqlReason: LoseReasonOption) => {
        return { label: mqlReason.spanishDisplay, value: mqlReason.id }
      })
  }, [getReasonsLose])

  const loseOptionsSQL = useMemo(() => {
    return getReasonsLose
      .filter((reason: LoseReasonOption) => reason.state.qualified === FunnelState.SQLP)
      .map((mqlReason: LoseReasonOption) => {
        return { label: mqlReason.spanishDisplay, value: mqlReason.id }
      })
  }, [getReasonsLose])

  const isDisabled = !textReason.inputOpen
    ? leadFunnelState === STEP_OPTION.NOT_SELECTED ||
      (leadFunnelState === FunnelState.LACK_INTEREST && !lackInterestReason) ||
      (leadFunnelState === FunnelState.DISCARD && !discardReason) ||
      (leadFunnelState === FunnelState.MQL && !loseReason) ||
      (leadFunnelState === FunnelState.SQL && !loseReason)
    : textReason.value === ''

  const { title, text, options, checkOption, placeHolder, value, setValue } = getReasons({
    leadFunnelState,
    discardReasons,
    interestReasons,
    loseOptionsMQL,
    loseOptionsSQL,
    interestReason,
    discardReason,
    loseReason,
    lackInterestReason,
    setInterestReason,
    setDiscardReason,
    setLoseReason,
    setLackInterestReason
  })

  const sendData = () => {
    if (leadFunnelState === FunnelState.LACK_INTEREST) {
      onOk()
      history.push(`/newProposal/${leadId}?mode=${PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL}`)
      handleClose()
    } else {
      onOk()
    }
  }

  return (
    <Modal
      visible={openModal}
      destroyOnClose={true}
      onCancel={handleClose}
      className={styles.reasonsModal}
      title={<Title title={title} />}
      okText="Continuar"
      cancelText="Cancelar"
      onOk={sendData}
      okButtonProps={{ disabled: isDisabled }}>
      <div className={styles.reasonsContainer}>
        <p className={styles.reasonsText}>{text}</p>
        <Select
          // @ts-ignore
          selected={options?.find((x) => x.value === value)}
          // @ts-ignore
          options={options}
          // @ts-ignore
          onSelect={(e: any) => setValue(e.value)}
          disabled={disabledSelect}
          placeholder={placeHolder || ''}
        />{' '}
        <br />{' '}
        {checkOption ? (
          <Checkbox
            className={styles.checkbox}
            onChange={() => {
              setOpenInput(!textReason.inputOpen)
            }}>
            Otro motivo
          </Checkbox>
        ) : (
          ''
        )}
        {textReason.inputOpen ? (
          <InputFieldWithError
            placeholder="Indique el motivo"
            inputStyles={{ minHeight: '34.01px', marginTop: '20px' }}
            error={textReason.errorMessage}
            value={textReason.value}
            onChange={(e) => setTextReason(e.target.value)}
            maxLength={textReason.limitText}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}

export default ReasonsModal
