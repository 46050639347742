import { Agent } from '../../../projectApi/Mercurio/BO/Agents/common'

export interface AgentsTableState {
  agents: Agent[]
  loadingAgents: boolean
  error: string
  pagination: Pagination
}

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export enum ROLE_TYPE {
  'Admin. de Sistemas' = 1,
  'Administrador' = 2,
  'Vendedor' = 3
}

export type ParamsType = {
  limit: number
  offset: number
}

export const GET_AGENTS_REQUEST = 'AGENTS/AGENTS_TABLE/GET_AGENTS_REQUEST'
export const GET_AGENTS_SUCCESS = 'AGENTS/AGENTS_TABLE/GET_AGENTS_SUCCESS'
export const GET_AGENTS_FAILURE = 'AGENTS/AGENTS_TABLE/GET_AGENTS_FAILURE'

export interface GetAgentsRequest {
  type: typeof GET_AGENTS_REQUEST
  payload: {
    pageSize: number
    newPage: number
  }
}

export interface GetAgentsSuccess {
  type: typeof GET_AGENTS_SUCCESS
  payload: {
    agents: Agent[]
    total: number
  }
}

export interface GetAgentsFailure {
  type: typeof GET_AGENTS_FAILURE
  payload: { error: string }
}

export type GetAgentsAction = GetAgentsRequest | GetAgentsSuccess | GetAgentsFailure

export type AgentsTableAction = GetAgentsAction
