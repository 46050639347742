import { AxiosResponse } from 'axios'
import moment, { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type ListParams = {
  limit?: number
  offset?: number
  total?: number
  id: number
}

interface APIResponse {
  code: string
  created_at: string
  deposit_discount_months: number
  deposit_discount_percent: number
  description: string
  end_date: string
  id: number
  public_description: string
  start_date: string
  transport_discount_percent: number
  updated_at: string
}

interface Response {
  code: string
  createdAt: Moment
  depositDiscountMonths: Moment
  depositDiscountPercent: number
  description: string
  endDate: Moment
  id: number
  publicDescription: string
  startDate: Moment
  transportDiscountPercent: number
  updatedAt: Moment
}
export type DiscountDetail = Response

export function discount(params: ListParams): Promise<Response> {
  const { id } = params

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/discounts/${id}`)
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse: Response = {
        code: response.data.code,
        createdAt: moment(response.data.created_at),
        depositDiscountMonths: moment(response.data.deposit_discount_months),
        depositDiscountPercent: response.data.deposit_discount_percent,
        description: response.data.description,
        endDate: moment(response.data.end_date),
        id: response.data.id,
        publicDescription: response.data.public_description,
        startDate: moment(response.data.start_date),
        transportDiscountPercent: response.data.transport_discount_percent,
        updatedAt: moment(response.data.updated_at)
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
