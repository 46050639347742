/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

interface UpdateParams {
  driverId: number
  tripId: number
}

export function update({ tripId, driverId }: UpdateParams): Promise<void> {
  const data = snakecaseKeys({ driver: driverId })
  return apiPrivate
    .put(`${URL_BASE_TRIPS}/trips/${tripId}`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
