import { PaymentStatus, PaymentType } from '../../projectApi/Payments/Payment/list'

enum PaymentStatusLabel {
  REALIZADO = 'Realizado',
  ERRONEO = 'Erroneo',
  EN_PROCESO = 'En proceso',
  RECHAZADO = 'Rechazado',
  DEVUELTO = 'Devuelto',
  CANCELADO = 'Cancelado',
  DESCONOCIDO = 'Desconocido',
  PENDIENTE = 'Pendiente',
  PENDIENTE_MANUAL = 'Pendiente Manual'
}

enum PaymentTypeLabel {
  TRANSACCION = 'Transacción',
  DEPOSITO = 'Depósito',
  ALQUILER = 'Alquiler'
}

export const PaymentTypeKey = [
  {
    value: PaymentType.TRANSACCION,
    text: PaymentTypeLabel.TRANSACCION,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: PaymentType.DEPOSITO,
    text: PaymentTypeLabel.DEPOSITO,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: PaymentType.ALQUILER,
    text: PaymentTypeLabel.ALQUILER,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  }
]

export const PaymentStatusKey = [
  {
    value: PaymentStatus.REALIZADO,
    text: PaymentStatusLabel.REALIZADO,
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: PaymentStatus.ERRONEO,
    text: PaymentStatusLabel.ERRONEO,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: PaymentStatus.EN_PROCESO,
    text: PaymentStatusLabel.EN_PROCESO,
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: PaymentStatus.RECHAZADO,
    text: PaymentStatusLabel.RECHAZADO,
    styleProps: {
      borderColor: '#C4320A',
      backgroundColor: '#FFF6ED'
    }
  },
  {
    value: PaymentStatus.DEVUELTO,
    text: PaymentStatusLabel.DEVUELTO,
    styleProps: {
      borderColor: '#854D0E',
      backgroundColor: '#FEF9C3'
    }
  },
  {
    value: PaymentStatus.CANCELADO,
    text: PaymentStatusLabel.CANCELADO,
    styleProps: {
      borderColor: '#344054',
      backgroundColor: '#F2F4F7'
    }
  },
  {
    value: PaymentStatus.DESCONOCIDO,
    text: PaymentStatusLabel.DESCONOCIDO,
    styleProps: {
      borderColor: '#6941C6',
      backgroundColor: '#F9F5FF'
    }
  },
  {
    value: PaymentStatus.PENDIENTE,
    text: PaymentStatusLabel.PENDIENTE,
    styleProps: {
      borderColor: '#C11555',
      backgroundColor: '#FDF2f2'
    }
  },
  {
    value: PaymentStatus.PENDIENTE_MANUAL,
    text: PaymentStatusLabel.PENDIENTE_MANUAL,
    styleProps: {
      borderColor: '#C11574',
      backgroundColor: '#FDF2FA'
    }
  }
]
