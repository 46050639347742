const deleteChargeTypes = {
  DELETE_CHARGE: 'PAYMENTS/DELETE_CHARGE',
  DELETE_CHARGE_SUCCESS: 'PAYMENTS/DELETE_CHARGE_SUCCESS',
  DELETE_CHARGE_FAIL: 'PAYMENTS/DELETE_CHARGE_FAIL',
  SET_DELETE_MODAL_OPEN: 'PAYMENTS/SET_DELETE_MODAL_OPEN'
}

const chargeTableTypes = {
  FETCH_CHARGES: 'PAYMENTS/FETCH_CHARGES',
  FETCH_CHARGES_SUCCESS: 'PAYMENTS/FETCH_CHARGES_SUCCESS',
  FETCH_CHARGES_FAIL: 'PAYMENTS/FETCH_CHARGES_FAIL',
  CLEAN_FILTERS: 'PAYMENTS/CLEAN_FILTERS',
  SET_PAGINATION: 'PAYMENTS/SET_PAGINATION',
  SET_FILTER: 'PAYMENTS/SET_FILTER',
  SET_SORTER: 'PAYMENTS/SET_SORTER',
  SET_SEARCH_FILTERS: 'PAYMENTS/SET_SEARCH_FILTERS'
}

const newChargeTypes = {
  SET_CREATE_MODAL_OPEN: 'PAYMENTS/SET_CREATE_MODAL_OPEN',
  NEW_CHARGE: 'PAYMENTS/NEW_CHARGE',
  NEW_CHARGE_SUCCESS: 'PAYMENTS/NEW_CHARGE_SUCCESS',
  NEW_CHARGE_FAIL: 'PAYMENTS/NEW_CHARGE_FAIL',
  SET_TYPE: 'PAYMENTS/SET_TYPE',
  SET_AMOUNT: 'PAYMENTS/SET_AMOUNT',
  SET_DAY_TO_CHARGE: 'PAYMENTS/SET_DAY_TO_CHARGE',
  SET_START_DATE: 'PAYMENTS/SET_START_DATE',
  SET_END_DATE: 'PAYMENTS/SET_END_DATE',
  SET_PAYMENT_METHOD: 'PAYMENTS/SET_PAYMENT_METHOD'
}

const userSearchTypes = {
  SET_USER_ID: 'PAYMENTS/SET_USER_ID',
  SET_USER_SEARCH_BY_ID: 'PAYMENTS/SET_USER_SEARCH_BY_ID',
  SET_USER_SEARCH_BY_NAME: 'PAYMENTS/SET_USER_SEARCH_BY_NAME',
  SET_USER_SEARCH_BY_LAST_NAME: 'PAYMENTS/SET_USER_SEARCH_BY_LAST_NAME',
  SET_USER_SEARCH_BY_ID_FAILURE: 'PAYMENTS/SET_USER_SEARCH_BY_ID_FAILURE',
  SET_USER_SEARCH_BY_NAME_FAILURE: 'PAYMENTS/SET_USER_SEARCH_BY_NAME_FAILURE',
  SET_USER_SEARCH_BY_LAST_NAME_FAILURE: 'PAYMENTS/SET_USER_SEARCH_BY_LAST_NAME_FAILURE'
}

export default {
  ...deleteChargeTypes,
  ...chargeTableTypes,
  ...newChargeTypes,
  ...userSearchTypes
}
