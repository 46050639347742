import { Moment } from 'moment'
import { AppThunk } from '../../../store'
import { GetRemovalMetricsRequest, GetRemovalMetricsSuccess, GetRemovalMetricsTypes } from '../types/metrics'
import { API } from '../../../projectApi'
import { sendToastNotificationError } from '../../../utils/notifications'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { mapOfRemovalsMetrics } from '../components/RemovalMetrics'
const RemovalsMetricsActionCreator = {
  getMetrics:
    ({ dateFrom, dateTo }: { dateFrom?: Moment; dateTo?: Moment }): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalMetricsRequest = {
        type: GetRemovalMetricsTypes.GET_REMOVAL_METRICS_REQUEST
      }

      dispatch(request)

      try {
        const response = await API.TransactionHandler.Removals.metrics({
          dateFrom,
          dateTo
        })

        const quantityMetrics = Object.entries(response).map(([key, value]) => ({
          id: key,
          metric: mapOfRemovalsMetrics[key],
          quantity: value
        }))

        const success: GetRemovalMetricsSuccess = {
          type: GetRemovalMetricsTypes.GET_REMOVAL_METRICS_SUCCESS,
          payload: { quantityMetrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(error, GetRemovalMetricsTypes.GET_REMOVAL_METRICS_FAILURE, dispatch)
      }
    }
}
export default RemovalsMetricsActionCreator
