import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import searchUserActionCreators from '../actionCreators/SearchUser'
import SelectedBilling from '../components/SelectedBilling'

const SelectedBillingContainer = () => {
  const dispatch = useDispatch()
  const searchUserActions = bindActionCreators(searchUserActionCreators, dispatch)
  const { billingPayment } = useSelector((state) => state.BillingPayment)
  const { selectedBillings, loading } = billingPayment

  const props = {
    searchUserActions,
    selectedBillings,
    loading
  }

  return <SelectedBilling {...props} />
}

export default SelectedBillingContainer
