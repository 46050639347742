import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export function anulled(billingId: number): Promise<void> {
  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/billings/${billingId}/annul`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
