import snakecaseKeys from 'snakecase-keys'
import { AxiosError } from 'axios'
import { URL_BASE_COMPLAINTS } from '@/endpoints'
import { ErrorMessageResponse } from '../AccountManager/Satellite/create'
import { apiPrivate } from '@/api'
import { Complaint } from '@/sections/Complaints/types/createComplaintModal'

export async function create(complaint: Complaint): Promise<void> {
  const parsedParams = snakecaseKeys({ ...complaint }, { deep: true })

  return apiPrivate
    .post(`${URL_BASE_COMPLAINTS}/complaints`, parsedParams)
    .then(() => {})
    .catch((error: AxiosError<ErrorMessageResponse>) => {
      throw error
    })
}
