import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { APIVehicle, Vehicle, APIVehicleCreateUpdateParams, VehicleCreateUpdateParams } from './common'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'

export function update(id: number, params: VehicleCreateUpdateParams): Promise<Vehicle> {
  const formattedParams: APIVehicleCreateUpdateParams = snakecaseKeys(params, { deep: true })
  return apiPrivate
    .put(`${URL_BASE_TRIPS}/vehicles/${id}`, formattedParams)
    .then((response: AxiosResponse<APIVehicle>) => camelcaseKeys(response.data, { deep: true }))
    .catch((error) => {
      throw error
    })
}
