import { FormattedObject } from '../../../projectApi/ObjectAdministration/common'
import { SearchableParams } from '../../../projectApi/ObjectAdministration/Object/list'
import { SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type PhotographObjectTableKey = 'id'
export type PhotographObjectsTableSort = Sort<PhotographObjectTableKey>

export type PhotographObjectsTableSearchKey = keyof SearchableParams
export type PhotographObjectsTableSearchFilter = SearchFilter<PhotographObjectsTableSearchKey>

export type PhotographObjectState = {
  objects: FormattedObject[]
  total: number
  pagination: Pagination
  loading: boolean
  error: string
  sort: PhotographObjectsTableSort
  searchFilters: PhotographObjectsTableSearchFilter[]
}
export type Pagination = {
  page: number
  total: number
  pageSize: number
}
export const GET_OBJECTS_TABLE_REQUEST = 'PHOTOGRAPH_OBJECTS/PHOTOGRAPH_OBJECTS_TABLE/REQUEST'
export const GET_OBJECTS_TABLE_SUCCESS = 'PHOTOGRAPH_OBJECTS/PHOTOGRAPH_OBJECTS_TABLE/SUCCESS'
export const GET_OBJECTS_TABLE_FAILURE = 'PHOTOGRAPH_OBJECTS/PHOTOGRAPH_OBJECTS_TABLE/FAILURE'

export interface GetObjectsTableRequest {
  type: typeof GET_OBJECTS_TABLE_REQUEST
  payload: {
    newPage: number
    pageSize: number
    silentLoading?: boolean
    sort?: PhotographObjectsTableSort
    searchFilters?: PhotographObjectsTableSearchFilter[]
  }
}

export interface GetObjectsTableSuccess {
  type: typeof GET_OBJECTS_TABLE_SUCCESS
  payload: {
    objects: FormattedObject[]
    total: number
  }
}

export interface GetObjectsTableFailure {
  type: typeof GET_OBJECTS_TABLE_FAILURE
  payload: {
    error: string
  }
}

export type GetObjectTableAction = GetObjectsTableRequest | GetObjectsTableSuccess | GetObjectsTableFailure

export type PhotographObjectsTableAction = GetObjectTableAction
