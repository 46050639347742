import { FormikErrors } from 'formik'
import { BaseOption } from '../Select/Select'

export type FormikCardErrors = FormikErrors<{
  cardNumber: string
  expirationDate: string
  cvc: string
  cardOwnerName: string
  documentType: string
  documentNumber: string
}>

export type CreditCardState = {
  cardNumber: string
  cvc: string
  expirationDate: string
  cardOwnerName: string
  documentType: string
  documentNumber: string
  isLoading: boolean
  documentTypeOptions: IdentificationTypeOption[]
  errors: FormikCardErrors
}

export type IdentificationTypeOption = {
  id: string
  name: string
  type: string
  min_length: number
  max_length: number
}

export type CreditCardStringFields = 'fullName' | 'identificationType'
export type CreditCardNumberFields = 'creditCardNumber' | 'ccv' | 'expireDate'

export const DocumentTypeOptions: BaseOption<string>[] = [
  {
    label: 'DNI',
    value: 'DNI'
  },
  {
    label: 'Cédula',
    value: 'CI'
  },
  {
    label: 'L.C.',
    value: 'LC'
  },
  {
    label: 'L.E.',
    value: 'LE'
  },
  {
    label: 'Otro',
    value: 'Otro'
  }
]

export enum CommonActionTypes {
  CLEAR_STATE = 'CREDIT_CARD/CLEAR_STATE',
  SET_LOADING = 'CREDIT_CARD/SET_LOADING',
  SET_ERRORS = 'CREDIT_CARD/SET_ERRORS'
}

export type ClearState = {
  type: CommonActionTypes.CLEAR_STATE
}

export type SetLoading = {
  type: CommonActionTypes.SET_LOADING
  payload: {
    isLoading: boolean
  }
}

export type SetErrors = {
  type: CommonActionTypes.SET_ERRORS
  payload: {
    errors: FormikCardErrors
  }
}

type CommonActions = ClearState | SetLoading | SetErrors

export enum SetInputsActionTypes {
  SET_STRING_FIELD = 'CREDIT_CARD/SET_STRING_FIELD',
  SET_NUMBER_FIELD = 'CREDIT_CARD/SET_NUMBER_FIELD'
}

export enum SetDocumentTypesOptionsActionTypes {
  SET_DOCUMENT_TYPES_OPTIONS = 'CREDIT_CARD/SET_DOCUMENT_TYPES_OPTIONS'
}

export type SetDocumentTypeOption = {
  type: SetDocumentTypesOptionsActionTypes.SET_DOCUMENT_TYPES_OPTIONS
  payload: {
    documentTypesOptions: IdentificationTypeOption[]
  }
}

export type SetStringField = {
  type: SetInputsActionTypes.SET_STRING_FIELD
  payload: {
    field: CreditCardStringFields
    value: string
  }
}

export type SetNumberField = {
  type: SetInputsActionTypes.SET_NUMBER_FIELD
  payload: {
    field: CreditCardNumberFields
    value: number
  }
}

type SetLocationAddEditActions = SetStringField | SetNumberField

export type CreditCardAction = SetLocationAddEditActions | CommonActions | SetDocumentTypeOption
