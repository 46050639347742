import { apiPrivate } from '../../../api'
import { CountryName } from '../../../components/CountrySelector/constants/constants'
import { URL_BASE_MERCURIO } from '../../../endpoints'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import {
  GET_LAST_CHANGE_LEAD_FAILURE,
  GET_LAST_CHANGE_LEAD_REQUEST,
  GET_LAST_CHANGE_LEAD_SUCCESS,
  GET_LEAD_HISTORY_FAILURE,
  GET_LEAD_HISTORY_REQUEST,
  GET_LEAD_HISTORY_SUCCESS,
  GetLeadHistoryFailure,
  GetLeadHistoryRequest,
  GetLeadHistorySuccess
} from '../types/LeadHistory'
import { processRowToHistory } from '../utils/processRow'

const LeadHistoryActionCreators = {
  getHistoryLead:
    ({ leadId, country }: { leadId: number; country: CountryName }): AppThunk =>
    async (dispatch) => {
      const request: GetLeadHistoryRequest = {
        type: GET_LEAD_HISTORY_REQUEST
      }

      dispatch(request)

      try {
        const response = await API.Mercurio.Prospect.getHistory({ leadId, country })
        const success: GetLeadHistorySuccess = {
          type: GET_LEAD_HISTORY_SUCCESS,
          payload: {
            leadHistory: response
          }
        }
        dispatch(success)
      } catch (error) {
        const failure: GetLeadHistoryFailure = {
          type: GET_LEAD_HISTORY_FAILURE
        }

        dispatch(failure)
      }
    },
  getLastChangeLead:
    (leadId: number): AppThunk =>
    async (dispatch, getState) => {
      const {
        CountrySelector: { countrySelected }
      } = getState()

      const endpoint = `${URL_BASE_MERCURIO}/prospects/v1`
      const params = {
        id: leadId,
        country: countrySelected.fullName,
        Column: 'id',
        Order: 'desc'
      }

      dispatch({ type: GET_LAST_CHANGE_LEAD_REQUEST })

      try {
        const { data } = await apiPrivate.get(endpoint, { params })
        const camelCaseData = keysToCamel(data.description.prospects)
        const proccessedRow = processRowToHistory(camelCaseData[0])

        dispatch({
          type: GET_LAST_CHANGE_LEAD_SUCCESS,
          payload: { lead: proccessedRow }
        })
      } catch (error) {
        handleErrorsWithAction(error, GET_LAST_CHANGE_LEAD_FAILURE, dispatch)
        console.error('Failed to fetch the last change of the lead:', error)
      }
    }
}

export default LeadHistoryActionCreators
