import React from 'react'
import { Modal } from 'antd'

const styles = {
  modal: {
    maxWidth: 700
  }
}

const DeleteLeadModal = (props) => {
  const { openDelete, loading, prospect, deleteLead, setDeleteModalOpen } = props

  return (
    <Modal
      title="Eliminar Prospecto"
      visible={openDelete}
      onOk={() => deleteLead(prospect)}
      onCancel={() => setDeleteModalOpen(false, {})}
      okText={'Eliminar'}
      closable={!loading}
      maskClosable={!loading}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      cancelText={'Cancelar'}
      style={styles.modal}>
      ¿Está seguro que desea eliminar el prospecto con ID {prospect.id}?
    </Modal>
  )
}

export default DeleteLeadModal
