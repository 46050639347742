import React from 'react'
import styles from './developmentView.module.scss'
import { Button } from '../../../components/Button/Button'
export type DevelopmentViewProps = {
  objectLabelModal: boolean
  error: string
  isLoading: boolean
  handleOpenObjectLabelModal: () => void
}

export const DevelopmentView: React.FC<DevelopmentViewProps> = ({ isLoading, handleOpenObjectLabelModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.child}>
        <h3>Elija un módulo</h3>
        <div>
          <Button
            mode="outlined"
            label="Etiquetas genéricas"
            loading={isLoading}
            disabled={isLoading}
            className={styles.buttonSubmit}
            onClick={handleOpenObjectLabelModal}
          />
        </div>
      </div>
    </div>
  )
}
