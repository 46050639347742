import React, { Component } from 'react'
import ActivityTable from './ActivityTable'
import actions from './Activity.actions'
import { connect } from 'react-redux'
import moment from 'moment'
import { eqProps, equals, isEmpty } from 'ramda'
import PropTypes, { shape } from 'prop-types'
import { addFormatFields, getPaymentMethodOptions } from './Activity.selectors'
import userActions from '../Users/Users.actions'
import UpdateActivityModal from './UpdateActivityModal'
import { getNewFilterParams } from '../../utils/formatParams'
import { TableActionBar } from '../../components/TableActionBar'

moment.locale('es')

class Activity extends Component {
  componentDidMount() {
    const { paymentMethods, getPaymentMethods } = this.props
    this.props.getActivity(this.props.params)
    if (paymentMethods.length === 0) getPaymentMethods()
  }

  handleSearch = (dataIndex, text, confirm) => {
    const { params, currentPagination, currentSearchFilters, setSearchFilters, setPagination, getActivity } = this.props
    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text
          }
        : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    getActivity(newParams)

    confirm()
  }

  handleChange = (pagination, filters, sorter, _) => {
    const {
      currentFilter,
      currentSorter,
      currentPagination,
      getActivity,
      params,
      setFilter,
      setSorter,
      setPagination
    } = this.props

    if (!equals(filters, currentFilter)) {
      const newParams = getNewFilterParams(params, filters)

      setFilter(filters)
      setPagination({ ...currentPagination, current: 1 })
      getActivity(newParams)
      return
    }

    if (!(eqProps('field', sorter, currentSorter) && eqProps('order', sorter, currentSorter)) && !isEmpty(sorter)) {
      const { order, field } = sorter
      const Order = this.getOrder(order)
      const Column = field
      const Offset = 0

      setSorter(sorter)
      setPagination({ ...currentPagination, current: 1 })
      getActivity({ ...params, Offset, Column, Order })
      return
    }

    if (!eqProps('current', pagination, currentPagination)) {
      const { current, pageSize } = pagination
      const Offset = (current - 1) * pageSize
      const newParams = { ...params, Offset }

      setPagination(pagination)
      getActivity(newParams)
    }
  }

  getOrder = (order) => (order === 'descend' ? 'desc' : 'asc')

  render() {
    const {
      params,
      update,
      loading,
      activity,
      history,
      currentFilter,
      currentSearchFilters,
      setFilter,
      setSorter,
      currentPagination,
      cleanFilters,
      getActivity,
      paymentMethods,
      setUpdateModalOpen,
      setStatus,
      setReference,
      updateActivity
    } = this.props
    return (
      <div className="animated fadeIn">
        <TableActionBar hideBorder>
          <TableActionBar.ButtonAction
            variant="cleanFilters"
            onClickButton={() => cleanFilters().then(() => getActivity(this.props.params))}
          />
        </TableActionBar>

        <UpdateActivityModal
          charge={update}
          setUpdateModalOpen={setUpdateModalOpen}
          setStatus={setStatus}
          setReference={setReference}
          updateActivity={updateActivity}
        />
        <ActivityTable
          activity={activity}
          params={params}
          history={history}
          searchFilters={currentSearchFilters}
          filters={currentFilter}
          setUpdateModalOpen={setUpdateModalOpen}
          handleChange={this.handleChange}
          handleSearch={this.handleSearch}
          paymentMethods={paymentMethods}
          loading={loading}
          setFilter={setFilter}
          getActivity={getActivity}
          setSorter={setSorter}
          currentPagination={currentPagination}
        />
      </div>
    )
  }
}

Activity.propTypes = {
  activity: PropTypes.array.isRequired,
  update: PropTypes.shape({
    status_id: PropTypes.number.isRequired,
    payment_reference: PropTypes.string.isRequired
  }),
  paymentMethods: PropTypes.array.isRequired,
  params: PropTypes.shape({
    Limit: PropTypes.number,
    Offset: PropTypes.number,
    Column: PropTypes.string,
    Order: PropTypes.string
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  currentFilter: PropTypes.shape().isRequired,
  currentSorter: PropTypes.shape().isRequired,
  currentPagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  currentSearchFilters: PropTypes.arrayOf(shape()),
  getActivity: PropTypes.func.isRequired,
  cleanFilters: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setSorter: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  setUpdateModalOpen: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setReference: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { Activity, Users } = state
  const { paymentMethods } = Users
  const { table, update } = Activity
  return {
    paymentMethods: getPaymentMethodOptions(paymentMethods.paymentMethods),
    activity: addFormatFields(table.get('activity').toArray()),
    update: update.toObject(),
    params: table.get('params').toObject(),
    loading: table.get('loading'),
    currentSearchFilters: table.get('searchFilters').toArray(),
    currentFilter: table.get('filter').toObject(),
    currentSorter: table.get('sorter').toObject(),
    currentPagination: table.get('pagination').toObject()
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentMethods: () => dispatch(userActions.getPaymentMethods()),
    cleanFilters: () => dispatch(actions.cleanFilters()),
    getActivity: (params) => dispatch(actions.getActivity(params)),
    setFilter: (filter) => dispatch(actions.setFilter(filter)),
    setSorter: (sorter) => dispatch(actions.setSorter(sorter)),
    setPagination: (pagination) => dispatch(actions.setPagination(pagination)),
    setSearchFilters: (searchFilters) => dispatch(actions.setSearchFilters(searchFilters)),
    setUpdateModalOpen: (modalOpen, charge) => dispatch(actions.setUpdateModalOpen(modalOpen, charge)),
    setStatus: (status) => dispatch(actions.setStatus(status)),
    setReference: (reference) => dispatch(actions.setReference(reference)),
    updateActivity: (charge) => dispatch(actions.updateActivity(charge))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity)
