import { Radio } from 'antd'
import React from 'react'
import StepOption from './StepOption'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import { RadioChangeEvent } from 'antd/lib/radio'
import searchUserActionCreators from '../actionCreators/SearchUser'
import styles from './cardSelection.module.scss'
import { Steps } from '../constants'
import { SelectedCreditCard } from '../utils'

interface CardSelectionProps {
  paymentMethodSelected: PaymentMethod | null
  paymentMethods: PaymentMethod[]
  searchUserActions: typeof searchUserActionCreators
  optionRadio: number
  setOptionRadio: React.Dispatch<React.SetStateAction<number>>
  setStep: React.Dispatch<React.SetStateAction<Steps>>
}

export const CardSelection = ({
  paymentMethodSelected,
  paymentMethods,
  searchUserActions,
  setStep,
  setOptionRadio,
  optionRadio
}: CardSelectionProps) => {
  const handleSelectCard = (e: RadioChangeEvent) => {
    const newPayment = paymentMethods.find((x) => x.id === e.target.value)
    if (newPayment) searchUserActions.selectPaymentMethod(newPayment)
  }

  return (
    <div>
      <h3>Seleccionar tarjeta</h3>
      <div>
        <Radio.Group className={styles.itemContainer} onChange={handleSelectCard} value={paymentMethodSelected?.id}>
          {paymentMethods.map((pm) => (
            <StepOption
              key={pm.id}
              Icon={SelectedCreditCard(pm.paymentMethod.cardType)}
              cardType={pm.paymentMethod.cardType?.replace('_', ' ').toUpperCase()}
              fourDigits={pm.paymentMethod.lastFourDigits}
              month={pm.paymentMethod.month}
              year={pm.paymentMethod.year.toString().slice(2)}
              option={pm.id}
            />
          ))}
        </Radio.Group>
      </div>
      <div className={styles.nextPageContainer}>
        <button
          onClick={() => {
            setStep(Steps.PAYMENT)
            setOptionRadio(Steps.PAYMENT)
          }}>
          Continuar
        </button>
      </div>
    </div>
  )
}
