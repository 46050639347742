import { AttributeAPI } from './Attribute'
import { CorporateAPI } from './Corporate'
import { ObjectAPI } from './Object'
import { ValueAPI } from './Value'
import { ObjectSituationAPI } from './ObjectSituation'
import { ObjectStatusAPI } from './ObjectStatus'
import { ConfigAPI } from './Config'
import { identificationCodeAPI } from './IdentificationCode'

export const ObjectAdministrationAPI = {
  Attribute: AttributeAPI,
  Corporate: CorporateAPI,
  Value: ValueAPI,
  Config: ConfigAPI,
  Object: ObjectAPI,
  ObjectSituation: ObjectSituationAPI,
  ObjectStatus: ObjectStatusAPI,
  IdentificationCode: identificationCodeAPI
}
