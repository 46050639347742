export enum StatusStrings {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  CLOSED_DELAYED = 'CLOSED_DELAYED',
  IN_PROGRESS_DELAYED = 'IN_PROGRESS_DELAYED',
  NEW_DELAYED = 'NEW_DELAYED'
}

export enum StatusOptions {
  CLOSED = 'Resuelto',
  IN_PROGRESS = 'En curso',
  NEW = 'Nuevo',
  CLOSED_DELAYED = 'Resuelto atrasado',
  IN_PROGRESS_DELAYED = 'En curso atrasado',
  NEW_DELAYED = 'Nuevo atrasado'
}

export const STATUS_OPTIONS = [
  {
    value: StatusStrings.NEW,
    text: StatusOptions.NEW,
    styleProps: {
      borderColor: '#FF7A7F',
      backgroundColor: '#fffafa'
    }
  },
  {
    value: StatusStrings.NEW_DELAYED,
    text: StatusOptions.NEW_DELAYED,
    styleProps: {
      borderColor: '#FF7A7F',
      backgroundColor: '#fffafa'
    }
  },
  {
    value: StatusStrings.IN_PROGRESS,
    text: StatusOptions.IN_PROGRESS,
    styleProps: {
      borderColor: '#B25E09',
      backgroundColor: '#fff8eb'
    }
  },
  {
    value: StatusStrings.IN_PROGRESS_DELAYED,
    text: StatusOptions.IN_PROGRESS_DELAYED,
    styleProps: {
      borderColor: '#B25E09',
      backgroundColor: '#fff8eb'
    }
  },
  {
    value: StatusStrings.CLOSED,
    text: StatusOptions.CLOSED,
    styleProps: {
      borderColor: '#1DD4B3',
      backgroundColor: '#f0fffc'
    }
  },
  {
    value: StatusStrings.CLOSED_DELAYED,
    text: StatusOptions.CLOSED_DELAYED,
    styleProps: {
      borderColor: '#1DD4B3',
      backgroundColor: '#f0fffc'
    }
  }
]

export enum COMPLAINTS_TYPES {
  DEPOSIT = 'Ingreso',
  REMOVAL = 'Devolución'
}

export type COMPLAINTS_TYPES_KEYS = keyof typeof COMPLAINTS_TYPES

export enum PRIORITY_TYPES {
  LOW = 'Baja',
  MEDIUM = 'Media',
  HIGH = 'Alta',
  URGENT = 'Urgente'
}

export type PRIORITY_TYPES_KEYS = keyof typeof PRIORITY_TYPES

export const mapCoreTypeToComplaintType = (coreType: string) => {
  switch (coreType) {
    case 'deposit_operations':
      return 'DEPOSIT'
    case 'removal_operations':
      return 'REMOVAL'
    default:
      return null
  }
}
