import React from 'react'
import { Link } from 'react-router-dom'
import Hamburger from '../../../icons/hamburger'
import CountrySelector from '../../../components/CountrySelector/container/CountrySelector'
import styles from './Header.module.scss'
import AccountSelector from '../../Login/container/accountSelector'
import { useSelector } from 'react-redux'
import { Role } from '../../../projectApi/AccountManager/Roles/getByParentId'

type HeaderProps = {
  toggleNavHidden: () => void
}

const Header: React.FC<HeaderProps> = ({ toggleNavHidden }) => {
  const { accounts, fetchedAccounts } = useSelector((state) => state.Login)
  const isRoleCorporative = useSelector((state) => state.UserLogged.userProfile.role?.id === Role.CORPORATIVE)
  const parentAccountSelected = Boolean(localStorage.getItem('parent-id'))

  return (
    <header className={styles.header}>
      <div className={styles['left-section']}>
        <button className={styles.hamburger} onClick={toggleNavHidden}>
          <Hamburger />
        </button>
        <Link className={styles.brand} to="/">
          <picture>
            <source media="(min-width: 768px)" srcSet="/img/logo-spaceguru.svg" />
            <img src="/img/logo-spaceguru.svg" alt="" />
          </picture>
        </Link>
      </div>
      <div className={styles['right-section']}>
        {(accounts.length > 0 || !fetchedAccounts) && <AccountSelector />}
        {!parentAccountSelected && !isRoleCorporative && <CountrySelector />}
      </div>
    </header>
  )
}

export default Header
