import { AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPublic } from '../../api'
import { URL_BASE_USERS } from '../../endpoints'

interface APIUserInfo {
  token: string
  email: string
  name: string
  last_name: string
  id: string
  real_id: number
  country: string
  profile_picture: string
}

export type UserInfo = CamelCasedPropertiesDeep<APIUserInfo>

export type APIRequest = {
  email: string
  password: string
}

export function login(request: APIRequest): Promise<UserInfo> {
  return apiPublic
    .post(`${URL_BASE_USERS}/login/v2`, { ...request, dst: 'adm-web' })
    .then((response: AxiosResponse<APIUserInfo>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
    .catch((err: AxiosError) => {
      throw err
    })
}
