import { Reducer } from 'redux'
import {
  GetDepositsValidationMetricsTypes,
  DepositsValidationMetricsActions,
  DepositsValidationMetricsState
} from '../types/depositsValidationMetrics'

const initialState: DepositsValidationMetricsState = {
  quantityMetrics: [],
  fetchingMetrics: false
}

const depositsValidationMetricsReducer: Reducer<DepositsValidationMetricsState, DepositsValidationMetricsActions> = (
  state = initialState,
  action
): DepositsValidationMetricsState => {
  switch (action.type) {
    case GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        quantityMetrics: action.payload.quantityMetrics
      }
    case GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default depositsValidationMetricsReducer
