import types from '../types/table'

const LIMIT = 50

const initialRoot = {
  config: {},
  fetchedConfig: false,
  prospects: [],
  loadingProspects: false,
  loadingConfig: false,
  sendingReport: false,
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'id',
    Order: 'desc'
  },
  quantity: 0,
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'phone', text: '' },
    { key: 'email', text: '' },
    { key: 'user_id', text: '' },
    { key: 'created_at', text: '' },
    { key: 'updated_at', text: '' },
    { key: 'update_status_at', text: '' },
    { key: 'win_date', text: '' }
  ],
  sorter: {
    order: 'desc',
    field: 'id',
    columnKey: 'id'
  },
  filter: {
    source: [],
    status: [],
    contact_medium_id: [],
    agent_ids: [],
    providers: [],
    prospect_type_id: []
  },
  pagination: {
    current: 1,
    total: 0
  },
  filterBar: {
    funnelState: ''
  }
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.CLEAN_FILTERS: {
      return {
        ...state,
        params: initialRoot.params,
        searchFilters: initialRoot.searchFilters,
        filter: initialRoot.filter,
        pagination: initialRoot.pagination,
        filterBar: initialRoot.filterBar
      }
    }
    case types.FETCH_PROSPECTS:
      return {
        ...state,
        prospects: [],
        loadingProspects: true,
        error: ''
      }
    case types.FETCH_PROSPECTS_SUCCESS:
      return {
        ...state,
        prospects: action.payload.prospects,
        loadingProspects: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case types.FETCH_PROSPECTS_FAIL:
      return {
        ...state,
        loadingProspects: false,
        error: action.payload.error
      }
    case types.FETCH_LEADS_CONFIG:
      return { ...state, loadingConfig: true }
    case types.FETCH_LEADS_CONFIG_SUCCESS:
      return {
        ...state,
        loadingConfig: false,
        config: {
          ...action.payload.config
        },
        fetchedConfig: true
      }
    case types.FETCH_LEADS_CONFIG_FAIL:
      return { ...state, loadingConfig: false, error: action.payload.error }
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    case types.SET_FUNNEL_STATE_FILTER_BAR: {
      return {
        ...state,
        filterBar: { ...state.filterBar, funnelState: action.payload.state }
      }
    }
    case types.GET_REPORT_REQUEST:
      return {
        ...state,
        sendingReport: true
      }
    case types.GET_REPORT_SUCCESS:
      return {
        ...state,
        sendingReport: false
      }
    case types.GET_REPORT_FAILURE:
      return {
        ...state,
        sendingReport: false
      }
    default:
      return state
  }
}

export default root
