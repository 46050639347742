import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import { dateRangeFiltersToParams, Pagination, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import {
  ComplaintsCategoryFilter,
  ComplaintsDateRangeFilter,
  ComplaintsSearchFilter,
  GetComplaintsListTypes,
  GetComplaintsMetricsRequest,
  GetComplaintsMetricsSuccess,
  GetComplaintsMetricsTypes,
  RESET_FILTERS,
  ResetFilters
} from '../types/complaintsTable'
import { GetComplaintsReasonsTypes } from '../types/common'
import { sendToastNotificationError } from '@/utils/notifications'

const actions = {
  getComplaints({
    pagination,
    searchFilters,
    dateRangeFilters,
    categoryFilter
  }: {
    pagination: Pagination
    searchFilters?: ComplaintsSearchFilter[]
    dateRangeFilters?: ComplaintsDateRangeFilter[]
    categoryFilter?: ComplaintsCategoryFilter
  }): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: GetComplaintsListTypes.GET_COMPLAINTS_REQUEST,
        payload: {
          pagination,
          searchFilters,
          dateRangeFilters,
          categoryFilter
        }
      })
      const statuses = categoryFilter?.status.join()
      const priorities = categoryFilter?.priority.join()
      const complaintType = categoryFilter?.complaintType[0]
      const reasonId = categoryFilter?.reasonId[0]
      const closeReason = categoryFilter?.closeReason[0]

      try {
        const { complaints, total } = await API.Complaints.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters),
          ...(statuses && { statuses }),
          ...(priorities && { priorities }),
          reasonId,
          closeReason,
          complaintType,
          column: 'id',
          order: 'desc',
          withUsers: true
        })
        dispatch({
          type: GetComplaintsListTypes.GET_COMPLAINTS_SUCCESS,
          payload: {
            complaints,
            total
          }
        })
      } catch (error) {
        dispatch({
          type: GetComplaintsListTypes.GET_COMPLAINTS_FAILURE,
          payload: error instanceof Error ? error.message : 'Hubo un error'
        })
      }
    }
  },

  getReasons(): AppThunk {
    return async (dispatch) => {
      dispatch({
        type: GetComplaintsReasonsTypes.GET_REASONS_REQUEST
      })
      try {
        const { reasons } = await API.Complaints.reasons({
          column: 'id',
          order: 'desc'
        })
        dispatch({
          type: GetComplaintsReasonsTypes.GET_REASONS_SUCCESS,
          payload: {
            reasons
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetComplaintsReasonsTypes.GET_REASONS_FAILURE, dispatch)
      }
    }
  },

  resetFilters: (): ResetFilters => {
    setTimeout(() => Emitter.emit(Events.Complaints.RESETED_FILTERS), 50)
    return { type: RESET_FILTERS }
  },
  getMetrics: (): AppThunk => {
    return async (dispatch) => {
      const request: GetComplaintsMetricsRequest = {
        type: GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_REQUEST
      }
      dispatch(request)
      try {
        const metrics = await API.Complaints.metrics()

        const success: GetComplaintsMetricsSuccess = {
          type: GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_SUCCESS,
          payload: { metrics }
        }
        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener las métricas de reclamos')
        handleErrorsWithAction(error, GetComplaintsMetricsTypes.GET_COMPLAINTS_METRICS_FAILURE, dispatch)
      }
    }
  }
}

export default actions
