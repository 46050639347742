import { PaymentActions, PaymentTypes } from '../types/payment'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'

const initialState = {
  paymentBy: PAYMENT_BY_VALUES.OFFLINE
}

export type PaymentState = {
  paymentBy: PAYMENT_BY_VALUES
}

const root = (state: PaymentState = initialState, action: PaymentActions): PaymentState => {
  switch (action.type) {
    case PaymentTypes.SET_PAYMENT_BY: {
      return { ...state, paymentBy: action.payload.paymentBy }
    }
    default:
      return state
  }
}

export default root
