import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestsActionCreator } from '../actionCreators/requestsTable'
import { RequestsTable, RequestsTableProps } from '../components/RequestsTable'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import {
  Pagination,
  RequestsCategoryFilter,
  RequestsSearchFilter,
  RequestsSearchKey,
  RequestsTableSort,
  RequestsTableSortKey
} from '../types/requestsTable'
import { useHistory } from 'react-router'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Request, StatusStrings, TypeStrings } from '../../../projectApi/Sales/Requests/common'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  parseQueryParam,
  removeNullishValues,
  updateMultipleSearchFilters
} from '../../../components/DynamicTable/queryParamsUtils'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { List } from '../../../icons'

const queryParams = {
  page: NumberParam,
  userId: StringParam,
  sortField: StringParam,
  sortDirection: StringParam,
  id: StringParam,
  objectId: StringParam,
  statuses: StringParam
}

const RequestsContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [query, setQuery] = useQueryParams(queryParams)

  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const { getRequests } = bindActionCreators(requestsActionCreator, dispatch)
  const tableState = useSelector((state) => state.Requests.table)
  const countryId = useSelector((root) => root.CountrySelector.countrySelected.code)

  const emptyPagination = { ...tableState.pagination, page: 1 }
  const handleGetRequests = (params: {
    pagination?: Pagination
    showDemoUsers?: boolean
    type?: string
    searchFilters?: RequestsSearchFilter[]
    categoryFilter?: RequestsCategoryFilter
    sort?: RequestsTableSort
  }) => {
    const newParams = {
      pagination: params.pagination || tableState.pagination,
      searchFilters: params.searchFilters || tableState.searchFilters,
      categoryFilter: params.categoryFilter || tableState.categoryFilter,
      sort: params.sort || tableState.sort,
      showDemoUsers: params.showDemoUsers ?? tableState.showDemoUsers,
      countryId
    }
    getRequests(newParams)
    const newQuery = {
      page: newParams.pagination.page,
      ...searchFiltersToParams(newParams.searchFilters, true),
      sortDirection: newParams.sort.direction,
      sortField: newParams.sort.field,
      statuses: newParams.categoryFilter.status.length > 0 && newParams.categoryFilter.status,
      showDemoUsers: newParams.showDemoUsers ?? tableState.showDemoUsers
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  useEffect(() => {
    handleGetRequests({
      pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: 'userId', text: query.userId },
        { key: 'id', text: query.id },
        { key: 'objectId', text: query.objectId },
        { key: 'statuses', text: query.statuses }
      ]),
      categoryFilter: {
        ...tableState.categoryFilter,
        status: query.statuses
          ? (parseQueryParam(query.statuses, 'string') as StatusStrings[])
          : tableState.categoryFilter.status
      },
      sort:
        query.sortField && query.sortDirection
          ? {
              ...tableState.sort,
              field: query.sortField as RequestsTableSortKey,
              direction: query.sortDirection as SortDirection
            }
          : tableState.sort
    })
  }, [countryId])

  const handlePageChange = (newPage: number) => {
    handleGetRequests({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: RequestsSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetRequests({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleCategoryFilter = (newCategoryFilter: RequestsCategoryFilter) => {
    handleGetRequests({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleShowDemoUsers = (showDemoUsers: boolean) => {
    handleGetRequests({ showDemoUsers })
  }

  const handleSort = (newSort: RequestsTableSort) => {
    handleGetRequests({ sort: newSort })
  }

  const handleShowSaleRequests = () => history.push('/salesRequest')
  const handleShowDonationRequests = () => history.push('/donations')

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetPagination = emptyPagination

    handleGetRequests({
      pagination: resetPagination,
      searchFilters: resetSearchFilters,
      categoryFilter: { status: [], type: [] },
      showDemoUsers: false,
      type: undefined,
      sort: {
        direction: SortDirection.DESC,
        field: 'id'
      }
    })
  }

  const paging = {
    currentPage: tableState.pagination.page,
    pageSize: tableState.pagination.pageSize,
    total: tableState.pagination.total,
    onPageChange: handlePageChange
  }

  const redirectToRequest = (request: Request) => {
    const requestType = request.requestType
    const paths = {
      [TypeStrings.SALE_REQUEST]: `/salesRequest?requestId=${request.id}`,
      [TypeStrings.DONATION_REQUEST]: `/donations?requestId=${request.id}`
    }

    return history.push(paths[requestType])
  }

  const actions: MenuAction<Request>[] = [
    {
      label: 'Ver solicitud',
      icon: <List />,
      onClick: redirectToRequest
    }
  ]

  const Props: RequestsTableProps = {
    loading: tableState.loadingTable,
    requests: tableState.requests,
    pagination: paging,
    handleOpenQRCodeModal: openQRModal,
    handleSearch,
    handleCategoryFilter,
    handleResetFilters,
    handleShowSaleRequests,
    handleShowDonationRequests,
    handleSort,
    handleShowDemoUsers,
    actions,
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    sort: tableState.sort,
    showDemoUsers: tableState.showDemoUsers
  }

  return (
    <>
      <RequestsTable {...Props} />
    </>
  )
}

export default RequestsContainer
