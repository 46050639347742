import { Response as RoleAndPermissionsResponse } from '../../../projectApi/AccountManager/Roles/getByParentId'

export enum UserProfileTypes {
  GET_PARENT_ACCOUNT_PERMISSIONS_REQUEST = 'LOGIN/GET_PARENT_ACCOUNT_PERMISSIONS_REQUEST',
  GET_PARENT_ACCOUNT_PERMISSIONS_SUCCESS = 'LOGIN/GET_PARENT_ACCOUNT_PERMISSIONS_SUCCESS',
  GET_PARENT_ACCOUNT_PERMISSIONS_FAILURE = 'LOGIN/GET_PARENT_ACCOUNT_PERMISSIONS_FAILURE'
}

export type GetParentAccountPermissionsRequest = {
  type: UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_REQUEST
}

export type GetParentAccountPermissionsSuccess = {
  type: UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_SUCCESS
  payload: { roleAndPermissions: RoleAndPermissionsResponse }
}

export type GetParentAccountPermissionsFailure = {
  type: UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_FAILURE
  payload: { error: string }
}

export type GetUserProfileActions =
  | GetParentAccountPermissionsRequest
  | GetParentAccountPermissionsSuccess
  | GetParentAccountPermissionsFailure
