import { Reducer } from 'redux'
import {
  EmailBilledModalAction,
  EmailBilledState,
  OPEN_MODAL_EMAIL_BILLED,
  SEND_EMAIL_BILLED_FAILURE,
  SEND_EMAIL_BILLED_REQUEST,
  SEND_EMAIL_BILLED_SUCCESS
} from '../types/emailBilledModal'

const initialState: EmailBilledState = {
  modalOpen: false,
  error: '',
  loading: false
}

const EmailBilledModalReducer: Reducer<EmailBilledState, EmailBilledModalAction> = (
  state = initialState,
  action
): EmailBilledState => {
  switch (action.type) {
    case OPEN_MODAL_EMAIL_BILLED:
      return {
        ...state,
        modalOpen: action.payload.modalOpen,
        billingId: action.payload.billingId
      }
    case SEND_EMAIL_BILLED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case SEND_EMAIL_BILLED_SUCCESS:
      return {
        ...state,
        loading: false,
        modalOpen: false
      }
    case SEND_EMAIL_BILLED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        modalOpen: false
      }

    default:
      return state
  }
}

export default EmailBilledModalReducer
