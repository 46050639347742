import React from 'react'
import { Modal } from 'antd'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  },
  explainer: {
    marginTop: 5,
    fontSize: '0.75rem',
    color: '#a7a7a7'
  }
}

const DeletePaymentModal = (props) => {
  const { deleteChargeObj, deleteCharge, setDeleteModalOpen } = props
  const { selectedCharge, loading, error, deleteModalOpen } = deleteChargeObj
  const { ID, User = {} } = selectedCharge
  const { ID: userID, Name, LastName } = User

  const { title, buttonLabel } = {
    title: 'Eliminar Regla de Cobro',
    buttonLabel: 'Eliminar'
  }

  return (
    <Modal
      title={title}
      visible={deleteModalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={() => deleteCharge(ID)}
      onCancel={() => setDeleteModalOpen(false)}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={buttonLabel}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <div className="container">
        <p>
          ¿Desea eliminar la regla de cobro con ID {ID} del usuario ID {userID} - {Name} {LastName}?
        </p>
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

export default DeletePaymentModal
