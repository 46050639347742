import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'

interface APIOperationResponse {
  id: number
  created_at: string
  updated_at: string
  guid: string
  operation_number: number
  core_id: number
  core_type: string
  user_id: number
  status: string
  country_id: string
  original_address: string
  address_id: number
  transport_datetime: string
  transport_cost_in_cents: number
  transport_mode_type: string
  discount_code: string
  discount_id: number
  total_discount_percentage: number
  payment_type: string
  card_id: number
  card_type: string
  card_last_digits: string
  spreedly_pm_id: number
  cbuid: number
  additional_cost_in_cents: number
  additional_cost_comment: string
  origin: string
  detail: Record<string, unknown>
}

type APIResponse = {
  content: APIOperationResponse
}

export type OperationById = CamelCasedPropertiesDeep<APIOperationResponse>

interface GetByIdParams {
  id: number
  countryCode?: CountryIdCode
}

export function getOperationById({ id }: GetByIdParams): Promise<OperationById> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/admin/operation/${id}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.content) throw new Error('Respuesta incorrecta al obtener la operación')

      const camelResponse = camelcaseKeys(response.data.content, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
