import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TIMESLOT } from '../../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { OperationTypeValues } from '../../../../sections/OperationCalendar/types/common'

export interface Paging {
  total: number
  limit: number
  offset: number
}
type DaysData = {
  id: number
  day: string
  operationType: OperationTypeValues
  available: boolean
}

type APIResponse = {
  days: DaysData[]
}

export type ResponseOperationDays = CamelCasedPropertiesDeep<DaysData>[]

export async function getDays(): Promise<DaysData[]> {
  return apiPrivate
    .get(`${URL_BASE_TIMESLOT}/operation-days`)
    .then((response: AxiosResponse<APIResponse>) => {
      const camelcasedData = camelcaseKeys(response.data.days)
      return camelcasedData
    })
    .catch((error) => {
      throw error
    })
}
