import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import OriginsTableActionCreator from '../actionCreator/originsTable'
import EditOriginActionCreator from '../actionCreator/editOrigin'
import DeleteOriginActionCreator from '../actionCreator/deleteOrigin'
import OriginsTable, { OriginsTableProps } from '../components/originsTable'
import { Edit, Trash } from '../../../icons'
import { Origins } from '../../../projectApi/Mercurio/BO/Origins/common'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const OriginsTableContainer = () => {
  const dispatch = useDispatch()
  const { getOrigins, getSources } = bindActionCreators(OriginsTableActionCreator, dispatch)
  const editOriginActions = bindActionCreators(EditOriginActionCreator, dispatch)
  const deleteOriginActions = bindActionCreators(DeleteOriginActionCreator, dispatch)
  const { origins, loadingOrigins, sources } = useSelector((state) => state.Origins.table)
  const { openEditOriginModal } = editOriginActions
  const { openDeleteOriginModal } = deleteOriginActions

  const actions = [
    {
      label: 'Modificar orígen',
      icon: <Edit />,
      onClick: (row: Origins) => {
        const id = row.id
        const sirenaSource = row.sirenaSource
        const channel = row.mercurioSource.spanishDisplay
        openEditOriginModal({ id, sirenaSource, channel })
      }
    },
    {
      label: 'Eliminar orígen',
      icon: <Trash />,
      onClick: (row: Origins) => {
        const id = row.id
        const sirenaSource = row.sirenaSource
        const channel = row.mercurioSource.spanishDisplay
        openDeleteOriginModal({ id, sirenaSource, channel })
      }
    }
  ]

  useEffect(() => {
    getOrigins()
    getSources()
  }, [])

  useEvents(Events.Origins.ORIGINS_UPDATED, () => getOrigins())

  const props: OriginsTableProps = {
    origins,
    sources,
    loadingOrigins,
    actions
  }

  return <OriginsTable {...props} />
}

export default OriginsTableContainer
