import React, { useMemo } from 'react'
import { Col, Row } from 'antd'
import { STATUS_OP } from '../entities'
import Select from '../../../components/Select'
import styles from './EditDepositView.module.scss'
import { Transaction } from '../types/EditDepositViewTypes/EditDepositView'
import InputGroup from '../../../components/InputGroup'
import { Option, ServiceType } from '../types/EditDepositViewTypes/serviceType'
import { TransportState } from '../types/EditDepositViewTypes/transport'
import CostActionsCreators from '../actions/EditDepositViewActionCreators/cost'
import Summary, { SummaryProps, TotalPrices } from '../../../common/operations/components/Summary/Summary'
import DepositDate, { CalendarTextFieldProps } from './subComponents/OperationDate'
import ExtraValueData from '../../../common/operations/components/ExtraValueData/ExtraValueData'
import OperationData, { Data } from '../../../common/operations/components/OperationData/OperationData'
import { ItemsState } from '../types/EditDepositViewTypes/items'
import ServiceDataRowContainer from '../containers/EditDepositViewContainers/ServiceDataRow'
import { CostState } from '../types/EditDepositViewTypes/cost'
import { CalendarState } from '../types/EditDepositViewTypes/calendar'
import CalendarActionCreators from '../actions/EditDepositViewActionCreators/calendar'
import AddressActionCreators from '../actions/EditDepositViewActionCreators/address'
import TransportContainer from '../containers/EditDepositViewContainers/Transport'
import { BaseOption } from '../../../components/Select/Select'
import { ModifyReason } from '../../../projectApi/TransactionHandler/Operation/Modify/list'
import { TransportDetail } from '../../../projectApi/TransactionHandler/Compute/cost'

interface EditDepositViewProps {
  transaction: Transaction
  loadingDataView: boolean
  serviceType: Option
  loadingServiceTypes: boolean
  serviceTypeList: ServiceType[]
  aditionals: {
    checkedAssistants: boolean
    shipmentAssistantCount: number
    shipmentAssistantPrice: number
  }
  transportState: TransportState
  itemsState: ItemsState
  costState: CostState
  costActions: typeof CostActionsCreators
  calendar: CalendarState
  calendarActions: typeof CalendarActionCreators
  addressActions: typeof AddressActionCreators
  setPriceBy: (service: string) => void
  setServiceType: (serviceType: Option) => void
  setDisabledShipmentAssistants: () => void
  setUpdatedExtraValue: (updatedValue: number) => void
  setModifyReason: ({ reasonId }: { reasonId: number }) => void
  saveEdit: (totalPrices: TotalPrices, transportCost?: TransportDetail) => void
  disabledEdit: boolean
  customDateComponentProps: CalendarTextFieldProps
}

const EditDepositView: React.FC<EditDepositViewProps> = ({
  transaction,
  loadingDataView,
  serviceType,
  serviceTypeList,
  loadingServiceTypes,
  transportState,
  aditionals,
  costActions: { fetchDiscount, setDiscountCode },
  setPriceBy,
  setServiceType,
  setUpdatedExtraValue,
  setDisabledShipmentAssistants,
  setModifyReason,
  saveEdit,
  itemsState,
  costState,
  calendar,
  calendarActions,
  disabledEdit,
  customDateComponentProps
}) => {
  const { selectedItems } = itemsState

  const operationDataBase = [
    {
      label: 'Nombre',
      value: transaction.generalData.user.name
    },
    {
      label: 'País',
      value: transaction.generalData.countryId
    },
    {
      label: 'Apellido',
      value: transaction.generalData.user.lastName
    },
    {
      label: 'Fecha de ingreso',
      value: transaction.generalData.createdAt
    },
    {
      label: 'ID de usuario',
      value: transaction.generalData.user.id
    },
    {
      label: 'Estado de la Op.',
      value: STATUS_OP[transaction.generalData.status]?.toUpperCase()
    }
  ]

  const formatToValidOption = (
    array: ServiceType[],
    label: keyof Required<ServiceType> = 'type',
    value: keyof Required<ServiceType> = 'id'
  ): Option[] => {
    const options = array.map((item) => {
      return {
        label: item[label],
        value: item[value]
      }
    })

    // @ts-ignore
    return options
  }

  const formatModifyOptions: BaseOption<number>[] = useMemo(() => {
    return transaction.modifyReason.reasonsList.map((reason: ModifyReason) => ({
      value: reason.id,
      label: reason.name
    }))
  }, [transaction.modifyReason.reasonsList])

  const getOptionSelected = (reasonId: number): BaseOption<number> => {
    const { id, name } = transaction.modifyReason.reasonsList.find(
      (reason: ModifyReason) => reason.id === reasonId
    ) || { id: 0, name: '' }

    return { value: id, label: name }
  }

  const summaryProps: SummaryProps = {
    minBillableM3Price: transaction.flags.minBillableM3Price,
    shipmentAssistants: aditionals,
    discount: {
      transportDiscount: costState.fetchDiscount.transportDiscount,
      depositDiscount: costState.fetchDiscount.depositDiscount,
      discountCode: costState.discountCode,
      loadingDiscount: costState.fetchDiscount.loading,
      fetchDiscount,
      setDiscountCode,
      isValidDiscount: costState.fetchDiscount.valid,
      errorDiscount: costState.error
    },
    applyDiscount: costState.applyDiscount,
    loadingStoragePrice: itemsState.loadingRecalculatePrice,
    loadingTransportCost: transportState.loadingFetchCost,
    additionalCost: costState.additionalCost,
    transportCost: transportState.transportCost,
    tollCostInCents: transportState.tollCostInCents,
    selectedItems: itemsState.selectedItems.filter((selectedItem) => !selectedItem.deleted),
    serviceType: serviceType.value,
    onSave: (totalPrices: TotalPrices, transportCost?: TransportDetail) => saveEdit(totalPrices, transportCost),
    countryCode: transaction.generalData.countryId,
    disabledEdit,
    transportDetail: transportState?.transportDetail || undefined
  }

  return (
    <div className={styles.container}>
      <Col md={24} lg={17}>
        <OperationData loading={loadingDataView} title="Detalles del operación">
          {operationDataBase.map((operation) => (
            <Data key={`Item-${operation.label}-${operation.value}`} label={operation.label} value={operation.value} />
          ))}
        </OperationData>
        <InputGroup title="Adicionales">
          <div className={styles.control}>
            <ExtraValueData
              titleExtra="Asistentes de carga/descarga"
              loadingExtras={loadingDataView}
              currencySymbol="$"
              extraValueEnabled={aditionals.checkedAssistants}
              setExtraValueEnabled={() => setDisabledShipmentAssistants()}
              extraValueCount={aditionals.shipmentAssistantCount}
              setExtraValueCount={(e) => setUpdatedExtraValue(e)}
              extraValuePrice={aditionals.shipmentAssistantPrice}
            />
          </div>
        </InputGroup>
        <InputGroup title="Tipo de servicio">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Select
                loading={loadingDataView || loadingServiceTypes}
                placeholder="Tipo"
                selected={serviceType}
                disabled={serviceType.value !== ''}
                options={formatToValidOption(serviceTypeList, 'type', 'id')}
                onSelect={(option: Option) => {
                  setPriceBy(option.value)
                  setServiceType(option)
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 0]}>
                <ColumnHeader title={'Lista de Productos'} span={7} />
                <ColumnHeader title={'Cantidad'} span={3} />
                <ColumnHeader title={'Dimensiones'} span={4} />
                <ColumnHeader title={'Embalaje'} span={3} />
                <ColumnHeader title={'Desarmado'} span={3} />
                <ColumnHeader title={'Escaleras (pisos)'} span={4} />
              </Row>
              {selectedItems.map(
                (selectedItem, index) =>
                  !selectedItem.deleted && (
                    <ServiceDataRowContainer
                      key={index}
                      selectedItem={selectedItem}
                      index={index}
                      serviceType={serviceType.value}
                    />
                  )
              )}
            </Col>
          </Row>
        </InputGroup>
        <Col span={24}>
          <TransportContainer />
        </Col>
        <Col span={24}>
          <DepositDate
            calendar={calendar}
            calendarActions={calendarActions}
            calendarTextFieldProps={customDateComponentProps}
          />
        </Col>
      </Col>
      <Col md={24} lg={7} className={styles.summaryContainer}>
        <Row gutter={[16, 16]} className={styles.row}>
          <Col span={24}>
            <InputGroup title="Motivos de edición" className={styles.reasonsContainer}>
              <h2 className={styles.reasonsTitle}>¿Por qué motivo quiere modificar la operación?</h2>
              <Select
                loading={transaction.modifyReason.loadingReasons}
                placeholder="Motivo de edición"
                selected={getOptionSelected(transaction.modifyReason.reasonId)}
                options={formatModifyOptions}
                onSelect={({ value }) => {
                  setModifyReason({ reasonId: value })
                }}
              />
              {!transaction.modifyReason.reasonId && (
                <p className={styles.reasonsWarning}>
                  {transaction.modifyReason.errorMessage
                    ? transaction.modifyReason.errorMessage
                    : '*Debe seleccionar un motivo de edición'}
                </p>
              )}
            </InputGroup>
          </Col>
          <Col span={24}>
            <Summary {...summaryProps} />
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default EditDepositView

export const ColumnHeader = ({ title, span }: { title: string; span: number }) => (
  <Col span={span}>
    <p className={styles.inputTitle}>{title}</p>
  </Col>
)
