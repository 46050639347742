import React from 'react'
import actions from './actions'
import Component from './component'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { CountryIdCode } from '../CountrySelector/constants/constants'
import { GeocodeResult } from 'use-places-autocomplete'
import { Address } from './types'

export type AddressContainerProps = {
  onAddressChange?: (suggest: GeocodeResult, address: Address) => void
  country?: CountryIdCode
  disabled?: boolean
}

const Container = (ownProps: AddressContainerProps) => {
  const dispatch = useDispatch()
  const addressActions = bindActionCreators(actions, dispatch)
  const { address, error } = useSelector((state) => state.Components.address)

  const props = {
    ...ownProps,
    addressActions,
    address,
    error
  }

  return <Component {...props} />
}

export default Container
