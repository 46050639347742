import React from 'react'
import { useHistory } from 'react-router'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { History } from 'history'
import { RegisteredObjects, SearchFilter } from '../entities'
import { getSearchProps, parseDate } from '../utils'

type registeredObjectsTableProps = {
  objects: RegisteredObjects[]
  pagination: Pagination
  loading: boolean
  actions: MenuAction<RegisteredObjects>[]
  searchFilters: SearchFilter[]
  history: History
  handleSearch: (key: string, newValue: string) => void
}

const RegisteredObjectsTable: React.FC<registeredObjectsTableProps> = ({
  objects,
  loading,
  actions,
  pagination,
  searchFilters,
  handleSearch
}) => {
  const history = useHistory()
  const columns: Column<RegisteredObjects>[] = [
    {
      key: 'real_id',
      label: 'ID',
      maxWidth: '80px'
    },
    {
      key: 'identification_codes',
      label: 'Código de identificación',
      tooltip: 'ID Transacción',
      minWidth: '100px',
      search: getSearchProps('code', 'Código', handleSearch, searchFilters),
      renderDataCell: ({ identification_codes }) => identification_codes[0]?.code
    },
    {
      key: 'created_at',
      label: 'Fecha de creación',
      minWidth: '150px',
      renderDataCell: ({ created_at }) => {
        return parseDate(created_at)
      }
    },
    {
      key: 'owner.real_id',
      label: 'ID Dueño',
      minWidth: '50px',
      renderDataCell: ({ owner: { real_id } }) => (
        <ClientIDLinkComponent id={real_id} noPadding={true}>
          {real_id}
        </ClientIDLinkComponent>
      )
    },
    {
      key: 'owner',
      label: 'Dueño',
      minWidth: '150px',
      renderDataCell: ({ owner }) => `${owner?.name} ${owner?.last_name}`
    }
  ]
  return (
    <DynamicTable
      columns={columns}
      loading={loading}
      rows={objects}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default RegisteredObjectsTable
