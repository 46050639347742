import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/login'
import Component from '../components/accountSelector'
import { SG_OPTION } from '../reducers/login'

const AccountSelectorContainer = () => {
  const dispatch = useDispatch()
  const parentId = localStorage.getItem('parent-id')

  const loginActions = bindActionCreators(actions, dispatch)
  const loginReducer = useSelector((state) => state.Login)

  const { accounts, fetchedAccounts } = loginReducer
  const { getParentAccounts } = loginActions

  useEffect(() => {
    if (!fetchedAccounts) {
      getParentAccounts()
    }
  }, [fetchedAccounts])

  const accountOptions = [
    SG_OPTION,
    ...accounts.map((account) => ({
      value: account.parent.id,
      label: `${account.parent.name} ${account.parent.lastName} (${account.role.descripcion})`
    }))
  ]

  useEffect(() => {
    if (fetchedAccounts && parentId) {
      const accountOption = accountOptions.find((option) => option.value === parseInt(parentId))
      if (accountOption) loginActions.setAccount(accountOption)
    }
  }, [fetchedAccounts, parentId])

  const props = {
    accountOptions,
    loginActions,
    loginReducer
  }

  return <Component {...props} />
}

export default AccountSelectorContainer
