import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { TransportTypesPricing } from '../../../../common/operations/constants'
import { TransportDetail } from '../../Compute/cost'

export type ItemBody = {
  id?: number
  productId: number
  amount: number
  name: string
  height: number
  length: number
  width: number
  weight: number
  comment?: string
  priceInCents: number
  packagingCostInCents: number
  costPerFloorInCents: number
  disassemblyCost: number
  deleted?: boolean
}

type UpdateDepositBody = {
  serviceDate: string
  type: string
  items: ItemBody[]
  timeSlotId: number
  timeInterval: string
  shipmentAssistantCount: number
  shipmentAssistantPrice: number
  modifyReason: number
  depositId: number
  address?: number
  discount?: string
  transportCost?: number
  tollCostInCents?: number
  transportType?: TransportTypesPricing
  detail?: {
    transportCost?: TransportDetail
  } | null
}

type ListParams = {
  operationId: number
  body: UpdateDepositBody
}

export function update({ operationId, body }: ListParams): Promise<void> {
  const parsedBody = snakecaseKeys(body, { deep: true })

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/operations/deposit/modify/${operationId}`, parsedBody)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
