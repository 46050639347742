import React from 'react'
import { bindActionCreators } from 'redux'
import { DefaultAddressActionCreator } from '../actions/defaultAddress'
import Component from '../components/defaultAddress'
import { useDispatch, useSelector } from 'react-redux'
import { AddressActionCreator } from '../../Address/actions/address'
import { DefaultAddressState } from '../types/defaultAddress'
import { AddressUserState } from '../types/address'

const Container = () => {
  const dispatch = useDispatch()
  const defaultAddressActionCreator = bindActionCreators(DefaultAddressActionCreator, dispatch)
  const addressActionsCreator = bindActionCreators(AddressActionCreator, dispatch)

  const defaultAddress = useSelector((state) => state.UserAddresses.defaultAddress) as DefaultAddressState
  const address = useSelector((state) => state.UserAddresses.address) as AddressUserState

  const { params: paramsFromAddress } = address

  const props = {
    setDefaultAddress: defaultAddressActionCreator.setDefaultAddress,
    closeModal: defaultAddressActionCreator.closeModal,
    defaultAddress,
    getAddresses: addressActionsCreator.getAddresses,
    paramsFromAddress
  }

  return <Component {...props} />
}

export default Container
