import { OpenActionsModal, SET_MODAL_OPEN, SET_OPTION_SELECTED, SetOptionSelected } from '../types/ActionsModal'
import { STEP_OPTION } from '../constants'

const actionsModalActionCreators = {
  setOpenModal: (
    openModal: boolean,
    leadId?: number,
    leadFunnelState?: string,
    interestReasonId?: number
  ): OpenActionsModal => {
    return { type: SET_MODAL_OPEN, payload: { openModal, leadId, leadFunnelState, interestReasonId } }
  },

  setOptionSelected: (option: STEP_OPTION): SetOptionSelected => {
    return { type: SET_OPTION_SELECTED, payload: { option } }
  }
}

export default actionsModalActionCreators
