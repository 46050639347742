import React, { ReactNode, useCallback } from 'react'
import { Tooltip } from 'antd'
import clsx from 'clsx'
import { GenericObject } from '../../utils/utilityTypes'
import styles from './actionMenu.module.scss'

export type MenuAction<T> = {
  label: string | ((callbackArgs?: T) => ReactNode | string)
  icon?: ReactNode
  onClick: (callbackArgs: T) => void
  disabled?: boolean | ((callbackArgs?: T) => boolean)
  tooltip?: string
}

export type BaseMenuProps<T> = {
  actions: MenuAction<T>[]
  callbackArgs?: T
  onOptionClick?: (callbackArgs?: T) => void
}

const BaseMenu = <T extends GenericObject>({ actions, onOptionClick, callbackArgs }: BaseMenuProps<T>) => {
  const handleClick = useCallback(
    (cb: (...args: any[]) => void) => {
      if (onOptionClick) onOptionClick(callbackArgs)
      cb(callbackArgs)
    },
    [callbackArgs]
  )

  const isActionDisabled = (disabled?: boolean | ((callbackArgs?: T) => boolean)): boolean => {
    if (!disabled) return false
    if (typeof disabled === 'function') return disabled(callbackArgs)
    return disabled
  }

  return (
    <ul className={styles.baseMenu}>
      {actions.map((action, idx) => (
        <Tooltip overlay={action.tooltip} key={idx}>
          <li
            className={clsx({
              [styles.disabled]: isActionDisabled(action.disabled)
            })}
            onClick={() => !isActionDisabled(action.disabled) && handleClick(action.onClick)}>
            {action.icon && action.icon}
            <div>{typeof action.label === 'function' ? action.label(callbackArgs) : action.label}</div>
          </li>
        </Tooltip>
      ))}
    </ul>
  )
}

export default BaseMenu
