import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddressModal from '../components/addressModal'
import addressActions from '../../../components/Address/actions'
import { AddressModalActionCreator } from '../actions/addressModal'
import { AddressActionCreator } from '../../Address/actions/address'
import { bindActionCreators } from 'redux'
import { AddressUserState } from '../types/address'
import { AddressModalState } from '../types/addressModal'

const AddressModalContainer = () => {
  const dispatch = useDispatch()
  const addressActionCreator = bindActionCreators(addressActions, dispatch)
  const addressModalActions = bindActionCreators(AddressModalActionCreator, dispatch)
  const addressTableActionCreator = bindActionCreators(AddressActionCreator, dispatch)

  const { address: addressFromAddress, error: errorFromAddress } = useSelector((state) => state.Components.address)
  const addressModal = useSelector((state) => state.UserAddresses.addressModal) as AddressModalState
  const address = useSelector((state) => state.UserAddresses.address) as AddressUserState

  const { params: paramsFromAddress } = address

  useEffect(() => {
    if (addressModal.userId !== 0 && addressModal.isEdit && addressModal.addressId !== 0) {
      addressModalActions.getAddress(addressModal.addressId)
    }
  }, [addressModal.isEdit])

  const props = {
    ...addressActionCreator,
    addressModalActions,
    errorFromAddress,
    addressFromAddress,
    addressModal,
    addressTableActionCreator,
    paramsFromAddress
  }

  return <AddressModal {...props} />
}

export default AddressModalContainer
