import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import commonActionCreators from '../actions/common'
import userModalActionCreators from '../actions/users'
import modalReasonsActionsCreators from '../actions/ModalReasons'
import FindUser from '../components/FindUser'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import paymentMPActions from '../actions/payment-mp'
import transportActions from '../actions/transport'
import objectsActions from '../actions/objects'
import paymentCBUActions from '../actions/payment-cbu'
import { useFeatureManager } from '../../featureManager/utils'
import { FeatureType } from '../../featureManager/types'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()
  const commonActions = bindActionCreators(commonActionCreators, dispatch)
  const userModalActions = bindActionCreators(userModalActionCreators, dispatch)
  const { openModal } = bindActionCreators(modalReasonsActionsCreators, dispatch)
  const userModalState = useSelector((state) => state.NewRemoval.userSearch)
  const objectsState = useSelector((state) => state.NewRemoval.objects)

  const spreedlyFeature = useFeatureManager(FeatureType.SPREEDLY_PAYMENT)

  useEvents(Events.Removal.GET_USER, ({ id }) => {
    dispatch(spreedlyFeature ? paymentMPActions.getUserSpreedlyPM({ userId: id }) : paymentMPActions.getUserCards(id))
    dispatch(transportActions.getAddresses(id))
    dispatch(objectsActions.getObjects(id))
    dispatch(paymentCBUActions.getCbus(id))
  })

  const props = {
    commonActions,
    userModalActions,
    userModalState,
    objectsState,
    openReasonsModal: openModal
  }

  return <FindUser {...props} />
}

export default Container
