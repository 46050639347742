import { DepositStatus, TransportTypesPricing } from '../../entities'
import { Item } from '../../../../projectApi/TransactionHandler/Operation/common'
import { ModifyReason } from '../../../../projectApi/TransactionHandler/Operation/Modify/list'

export type TransactionData = Pick<Transaction, 'generalData'> & {
  extraServices: {
    shipmentAssistants: {
      shipmentAssistantCount: number
      shipmentAssistantPrice: number
    }
  }
  modifyReason: {
    reasonId: number
    name: string
    description: string
  } | null
  serviceType: {
    serviceType: string
    items: Item[]
  }
  transport: {
    transportMode: string
    transportType: TransportTypesPricing
    address: {
      addressId: number
      addressString: string
      apartment: string
      city: string
      comment: string
      floor: string
      number: string
      postalCode: string
      province: string
      street: string
    }
    transportCost: number
    depositId: number
    tollCostInCents: number
  }
  dateData: {
    datetime: string
    timeInterval: string
    timeSlotId: number
  }
  cost: {
    isPrevDiscount: boolean
    discountCode: string
    discountId: number
  }
}

export type Transaction = {
  generalData: {
    id: number
    guid: string
    status: DepositStatus
    countryId: string
    createdAt: string
    user: {
      id: number
      lastName: string
      name: string
    }
  }
  modifyReason: {
    reasonId: number
    reasonsList: ModifyReason[]
    loadingReasons: boolean
    errorMessage: string
  }
  flags: {
    loadingData: boolean
    minBillableM3: number
    storageCost: number
    minBillableM3Price: number
    customDateTransportMult: number
  }
}

export interface TransactionState {
  transaction: Transaction
  loading: boolean
}

export const GET_TRANSACTION_DATA_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_TRANSACTION_DATA_REQUEST'
export const GET_TRANSACTION_DATA_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_TRANSACTION_DATA_SUCCESS'
export const GET_TRANSACTION_DATA_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_TRANSACTION_DATA_FAILURE'

export interface GetTransactionDataRequest {
  type: typeof GET_TRANSACTION_DATA_REQUEST
}

export interface GetTransactionDataSuccess {
  type: typeof GET_TRANSACTION_DATA_SUCCESS
  payload: {
    transaction: TransactionData
  }
}

export interface GetTransactionDataFailure {
  type: typeof GET_TRANSACTION_DATA_FAILURE
}

export type GetTransactionDataAction = GetTransactionDataRequest | GetTransactionDataSuccess | GetTransactionDataFailure

export const FETCH_CONFIGURATION_FLAGS_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/FETCH_CONFIGURATION_FLAGS_REQUEST'
export const FETCH_CONFIGURATION_FLAGS_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/FETCH_CONFIGURATION_FLAGS_SUCCESS'
export const FETCH_CONFIGURATION_FLAGS_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/FETCH_CONFIGURATION_FLAGS_REQUEST'

export interface FetchConfigurationFlagsRequest {
  type: typeof FETCH_CONFIGURATION_FLAGS_REQUEST
}

export interface FetchConfigurationFlagsSuccess {
  type: typeof FETCH_CONFIGURATION_FLAGS_SUCCESS
  payload: {
    m3Price: number
    minBillableM3: number
    minBillableM3Price: number
    customDateTransportMult: number
  }
}

export interface FetchConfigurationFlagsFailure {
  type: typeof FETCH_CONFIGURATION_FLAGS_FAILURE
}

export type FetchConfigurationFlagsAction =
  | FetchConfigurationFlagsRequest
  | FetchConfigurationFlagsSuccess
  | FetchConfigurationFlagsFailure

export const EDIT_DEPOSIT_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/EDIT_DEPOSIT_REQUEST'
export const EDIT_DEPOSIT_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/EDIT_DEPOSIT_SUCCESS'
export const EDIT_DEPOSIT_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/EDIT_DEPOSIT_FAILURE'

export interface EditDepositRequest {
  type: typeof EDIT_DEPOSIT_REQUEST
}

export interface EditDepositSuccess {
  type: typeof EDIT_DEPOSIT_SUCCESS
}

export interface EditDepositFailure {
  type: typeof EDIT_DEPOSIT_FAILURE
}

export type EditDepositActions = EditDepositRequest | EditDepositSuccess | EditDepositFailure

export enum GetModifyReasonsTypes {
  GET_MODIFY_REASONS_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_MODIFY_REASONS_REQUEST',
  GET_MODIFY_REASONS_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_MODIFY_REASONS_SUCCESS',
  GET_MODIFY_REASONS_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_MODIFY_REASONS_FAILURE'
}

export interface GetModifyReasonsRequest {
  type: typeof GetModifyReasonsTypes.GET_MODIFY_REASONS_REQUEST
}

export interface GetModifyReasonsSuccess {
  type: typeof GetModifyReasonsTypes.GET_MODIFY_REASONS_SUCCESS
  payload: { reasonsList: ModifyReason[] }
}

export interface GetModifyReasonsFailure {
  type: typeof GetModifyReasonsTypes.GET_MODIFY_REASONS_FAILURE
  payload: { errorMessage: string }
}

export type GetModifyReasonsActions = GetModifyReasonsRequest | GetModifyReasonsSuccess | GetModifyReasonsFailure

export const CLEAN_STATES = 'DEPOSIT/EDIT_DEPOSIT/CLEAN_STATES'
export const SET_MODIFY_REASON = 'DEPOSIT/EDIT_DEPOSIT/SET_MODIFY_REASON'

export interface CleanStates {
  type: typeof CLEAN_STATES
}

export interface SetModifyReason {
  type: typeof SET_MODIFY_REASON
  payload: { reasonId: number }
}

export type TransactionDataActions =
  | GetTransactionDataAction
  | GetModifyReasonsActions
  | FetchConfigurationFlagsAction
  | CleanStates
  | SetModifyReason
  | EditDepositActions
