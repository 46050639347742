import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import camelcaseKeys from 'camelcase-keys'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { Moment } from 'moment'
import { convertToMoment } from '../../utils'
import { SortDirection } from '../../../components/DynamicTable/types/types'

export interface APIResponse {
  providers: APIProvider[]
  total: number
}

export interface Response {
  providers: Provider[]
  total: number
}

export interface APIProvider {
  id: number
  name: string
  created_at: string
  updated_at: string
  deleted_at: string | null
}

export type Provider = {
  id: number
  name: string
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
}

export type ProvidersListSortKey = 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted_at'

interface ListParams {
  limit: number
  offset: number
  countryId?: string
  column?: ProvidersListSortKey
  order?: SortDirection
  id?: number
  name?: string
  format?: 'csv'
}

export function list({ countryId, format, ...params }: ListParams): Promise<Response> {
  const formattedParams = {
    country_id: countryId ?? apiPrivate.defaults.params?.country_code,
    order: params.order ?? 'desc',
    column: params.column ?? 'id',
    format,
    ...params
  }

  return apiPrivate
    .get(`${URL_BASE_TRIPS}/providers`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const providers = response.data.providers
      return {
        providers: providers
          ? camelcaseKeys(providers, { deep: true }).map((provider) => ({
              ...provider,
              createdAt: convertToMoment(provider.createdAt),
              updatedAt: convertToMoment(provider.updatedAt),
              deletedAt: provider.deletedAt ? convertToMoment(provider.deletedAt) : null
            }))
          : [],
        total: response.data.total
      }
    })
    .catch((error) => {
      throw error
    })
}
