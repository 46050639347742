import React from 'react'

const CubeIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.75 10.9999V4.99993C14.7497 4.73688 14.6803 4.47853 14.5487 4.2508C14.417 4.02306 14.2278 3.83395 14 3.70243L8.75 0.702427C8.52197 0.570774 8.2633 0.501465 8 0.501465C7.7367 0.501465 7.47803 0.570774 7.25 0.702427L2 3.70243C1.7722 3.83395 1.58299 4.02306 1.45135 4.2508C1.31971 4.47853 1.25027 4.73688 1.25 4.99993V10.9999C1.25027 11.263 1.31971 11.5213 1.45135 11.7491C1.58299 11.9768 1.7722 12.1659 2 12.2974L7.25 15.2974C7.47803 15.4291 7.7367 15.4984 8 15.4984C8.2633 15.4984 8.52197 15.4291 8.75 15.2974L14 12.2974C14.2278 12.1659 14.417 11.9768 14.5487 11.7491C14.6803 11.5213 14.7497 11.263 14.75 10.9999Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.45251 4.21997L8.00001 8.00747L14.5475 4.21997"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 15.56V8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CubeIcon
