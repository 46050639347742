import { CleanStates, GetTransactionDataAction } from './EditDepositView'
import { BaseOption } from '../../../../components/Select/Select'

export type Option = BaseOption<string>

export type ServiceType = {
  id: string
  multiplier?: number
  operationType?: string
  type: string
}

export interface ServiceTypeState {
  serviceTypeList: ServiceType[]
  serviceType: Option
  priceBy: {
    service: string
  }
  loadingTypes: boolean
  loadingTransactionData: boolean
}

export const GET_SERVICE_TYPES_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_SERVICE_TYPES_REQUEST'
export const GET_SERVICE_TYPES_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_SERVICE_TYPES_SUCCESS'
export const GET_SERVICE_TYPES_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_SERVICE_TYPES_FAILURE'

export interface GetServiceTypesRequest {
  type: typeof GET_SERVICE_TYPES_REQUEST
}

export interface GetServiceTypesSuccess {
  type: typeof GET_SERVICE_TYPES_SUCCESS
  payload: {
    serviceTypes: ServiceType[]
  }
}

export interface GetServiceTypesFailure {
  type: typeof GET_SERVICE_TYPES_FAILURE
}

export type GetServiceTypesActions = GetServiceTypesRequest | GetServiceTypesSuccess | GetServiceTypesFailure

export const SET_SERVICE_TYPE = 'DEPOSIT/EDIT_DEPOSIT/SET_SERVICE_TYPE'
export const SET_PRICE_BY = 'DEPOSIT/EDIT_DEPOSIT/SET_PRICE_BY'

export interface SetServiceType {
  type: typeof SET_SERVICE_TYPE
  payload: { serviceType: Option }
}

export interface SetPriceBy {
  type: typeof SET_PRICE_BY
  payload: { service: string }
}

export type SetDataServiceActions = SetServiceType | SetPriceBy

export type ServiceTypeActions = GetServiceTypesActions | SetDataServiceActions | GetTransactionDataAction | CleanStates
