import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import {
  Attribute,
  AttributesTableCategoryFilter,
  AttributesTableSearchFilter,
  AttributesTableSearchKey,
  AttributesTableSort,
  SHOW_HIDDEN_FILTERS
} from '../types/AttributesTable'
import attributesActionCreators from '../actionCreators/Attributes'
import { getFilterProps, getSearchProps, getSortProps } from '../../../components/DynamicTable/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Edit, Trash } from '../../../icons'
import { useHistory } from 'react-router'
import DeleteAttributeModal from '../containers/DeleteAttributeModal'
import { parseDate } from '../../ObjectsV2/utils'
import { DATA_TYPE_OPTIONS } from '../types/NewEditAttribute'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'
import { Permissions } from '../../UserLogged/permissions'
import { TableActionBar } from '../../../components/TableActionBar'

export type AttributesTableProps = {
  attributesActions: typeof attributesActionCreators
  openDeleteModal: (id: number) => void
  error: string
  loading: boolean
  attributes: Attribute[]
  categoryFilter: AttributesTableCategoryFilter
  pagination: Pagination
  sort: AttributesTableSort
  handleSort: (newSorter: AttributesTableSort) => void
  handleCategoryFilter: (newFilter: AttributesTableCategoryFilter) => void
  handleSearch: (key: AttributesTableSearchKey, newValue: string) => void
  handleResetAllFilters: () => void
  searchFilters: AttributesTableSearchFilter[]
  handleSendReportCSV: () => void
  isLoadingReport: boolean
}

export const AttributeTable: React.FC<AttributesTableProps> = (props: AttributesTableProps) => {
  const {
    loading,
    isLoadingReport,
    attributes,
    pagination,
    sort,
    searchFilters,
    handleSort,
    handleSearch,
    handleResetAllFilters,
    handleCategoryFilter,
    categoryFilter,
    openDeleteModal,
    handleSendReportCSV
  } = props

  const route = useHistory()
  const permissions = usePermissions()

  const editAttribute = (attributeId: number) => {
    const path = `/editAttribute/${attributeId}`
    route.push({
      pathname: path
    })
  }

  const columns: Column<Attribute>[] = [
    {
      key: 'id',
      label: 'ID',
      minWidth: 'max-content',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps(AttributesTableSearchKey.ID, 'ID de atributo', handleSearch, searchFilters)
    },
    {
      key: 'name',
      label: 'Nombre',
      sort: getSortProps('name', sort, handleSort),
      search: getSearchProps(AttributesTableSearchKey.NAME, 'Nombre', handleSearch, searchFilters)
    },
    {
      key: 'unit',
      label: 'Unidad'
    },
    {
      key: 'dataType',
      label: 'Tipo de dato',
      sort: getSortProps('data_type', sort, handleSort),
      renderDataCell: ({ dataType }) => DATA_TYPE_OPTIONS[dataType as keyof typeof DATA_TYPE_OPTIONS]
    },
    {
      key: 'isVisible',
      label: 'Visible',
      minWidth: 'max-content',
      filters: getFilterProps(SHOW_HIDDEN_FILTERS, categoryFilter, 'is_visible', handleCategoryFilter),
      renderDataCell: ({ isVisible }) => {
        return isVisible ? 'SÍ' : 'NO'
      }
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      minWidth: 'max-content',
      sort: getSortProps('created_at', sort, handleSort),
      renderDataCell: ({ createdAt }) => (createdAt ? parseDate(createdAt) : '-')
    },
    {
      key: 'deletedAt',
      label: 'Fecha de eliminado',
      minWidth: 'max-content',
      sort: getSortProps('deleted_at', sort, handleSort),
      renderDataCell: ({ deletedAt }) => (deletedAt ? parseDate(deletedAt) : '-')
    }
  ]

  const menuActions: MenuAction<Attribute>[] = [
    {
      label: 'Editar Atributo',
      icon: <Edit />,
      onClick: ({ id }) => editAttribute(id)
    },
    {
      label: 'Eliminar Atributo',
      icon: <Trash />,
      onClick: ({ id }) => openDeleteModal(id)
    }
  ]

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.Wrapper>
          {permissions.includes(Permissions.GenerarReporteDeAtributos) && (
            <TableActionBar.ButtonAction variant="csv" toolTip onClickButton={handleSendReportCSV} />
          )}

          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={() => handleResetAllFilters()} />
        </TableActionBar.Wrapper>
        <TableActionBar.ButtonAction titleButton={'Crear Atributo'} onClickButton={() => route.push('/newAttribute')} />
      </TableActionBar>
      <DynamicTable
        columns={columns}
        actions={menuActions}
        loading={loading}
        pagination={pagination}
        rows={attributes}
        keyExtractor={(entity) => entity.id}
      />
      <DeleteAttributeModal />
    </>
  )
}
