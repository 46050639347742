import { clone } from 'ramda'
import { Reducer } from 'redux'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import {
  CLEAR_VALIDATION_VIEW_STATE,
  GET_BILLING_BY_TRANSACTION_FAILURE,
  GET_BILLING_BY_TRANSACTION_REQUEST,
  GET_BILLING_BY_TRANSACTION_SUCCESS,
  GET_DEPOSIT_OPERATION_BY_ID_FAILURE,
  GET_DEPOSIT_OPERATION_BY_ID_REQUEST,
  GET_DEPOSIT_OPERATION_BY_ID_SUCCESS,
  POPULATE_VIEW_FAILURE,
  POPULATE_VIEW_REQUEST,
  POPULATE_VIEW_SUCCESS,
  RECALCULATE_OPERATION_FAILURE,
  RECALCULATE_OPERATION_REQUEST,
  RECALCULATE_OPERATION_SUCCESS,
  SET_FETCHED_RECALCULATION,
  SET_OBJECT_FLOORS,
  SET_TRANSPORT_EXTRA_COUNT,
  SET_TRANSPORT_EXTRA_PRICE,
  TOGGLE_OBJECT_EXTRA,
  TOGGLE_TRANSPORT_EXTRA,
  VALIDATE_OPERATION_FAILURE,
  VALIDATE_OPERATION_REQUEST,
  VALIDATE_OPERATION_SUCCESS,
  ValidationViewAction,
  ValidationViewObject,
  ValidationViewState
} from '../types/validationView'

const initialState: ValidationViewState = {
  operationCountryCode: CountryIdCode.ARGENTINA,
  objects: [],
  discountCode: '',
  quotedTransportCost: 0,
  loadingProvisionalBilling: false,
  provisionalBilling: null,
  baseInfo: {
    id: 0,
    fullAddress: '',
    transactionId: 0,
    user: {
      id: 0,
      name: '',
      lastName: ''
    },
    createdAt: null,
    datetime: null,
    status: null
  },
  loading: false,
  validating: false,
  error: '',
  extraPrices: {
    assistants: {
      enabled: false,
      price: 0,
      count: 1
    },
    kmsExtra: {
      enabled: false,
      price: 0,
      count: 1
    },
    transport: {
      enabled: false,
      price: 0,
      tollCostInCents: 0
    }
  },
  headerToggles: {
    disassembly: false,
    stairs: false,
    packaging: false
  },
  headerCounters: {
    stairs: 1
  },
  fetchedRecalculation: false,
  loadingDepositOperation: false
}

const ValidationViewReducer: Reducer<ValidationViewState, ValidationViewAction> = (
  state = initialState,
  action
): ValidationViewState => {
  switch (action.type) {
    case POPULATE_VIEW_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case POPULATE_VIEW_SUCCESS: {
      const {
        fromRecalculation,
        operationCountryCode,
        objects,
        quotedTransportCost,
        baseInfo,
        extraAssistantPrice,
        extraKmPrice,
        discountCode,
        provisionalBilling,
        tollCostInCents
      } = action.payload

      return {
        ...state,
        loading: false,
        operationCountryCode,
        objects: fromRecalculation ? state.objects : objects,
        discountCode,
        quotedTransportCost,
        baseInfo,
        provisionalBilling,
        extraPrices: {
          ...state.extraPrices,
          assistants: {
            ...state.extraPrices.assistants,
            price: extraAssistantPrice
          },
          kmsExtra: {
            ...state.extraPrices.kmsExtra,
            price: extraKmPrice
          },
          transport: {
            ...state.extraPrices.transport,
            tollCostInCents
          }
        }
      }
    }
    case POPULATE_VIEW_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case TOGGLE_TRANSPORT_EXTRA:
      return {
        ...state,
        extraPrices: {
          ...state.extraPrices,
          [action.payload.type]: {
            ...state.extraPrices[action.payload.type],
            enabled: !state.extraPrices[action.payload.type].enabled
          }
        }
      }
    case SET_TRANSPORT_EXTRA_COUNT:
      return {
        ...state,
        extraPrices: {
          ...state.extraPrices,
          [action.payload.type]: {
            ...state.extraPrices[action.payload.type],
            count: action.payload.count
          }
        }
      }
    case SET_TRANSPORT_EXTRA_PRICE:
      return {
        ...state,
        extraPrices: {
          ...state.extraPrices,
          [action.payload.type]: {
            ...state.extraPrices[action.payload.type],
            price: action.payload.price
          }
        }
      }
    case SET_FETCHED_RECALCULATION:
      return {
        ...state,
        fetchedRecalculation: action.payload.fetchedRecalculation
      }
    case TOGGLE_OBJECT_EXTRA: {
      if (action.payload.objectId === 'all') {
        const objectExtras = (obj: ValidationViewObject) => {
          let enabled = false

          switch (action.payload.type) {
            case 'disassembly': {
              enabled = obj.extras.disassembly.disassemblyCost > 0 ? !state.headerToggles.disassembly : false
              break
            }
            case 'stairs': {
              enabled = !state.headerToggles.stairs && !obj.deletedAt
              break
            }
            case 'packaging': {
              enabled = obj.extras.packaging.packagingCostInCents > 0 ? !state.headerToggles.packaging : false
              break
            }
          }

          return {
            extras: {
              ...obj.extras,
              [action.payload.type]: {
                ...obj.extras[action.payload.type],
                enabled
              }
            }
          }
        }

        return {
          ...state,
          headerToggles: {
            ...state.headerToggles,
            [action.payload.type]: !state.headerToggles[action.payload.type]
          },
          objects: clone(state.objects).map((obj) => ({
            ...obj,
            ...objectExtras(obj)
          }))
        }
      }

      const newObjects = clone(state.objects).map((obj) =>
        obj.objectId === action.payload.objectId
          ? {
              ...obj,
              extras: {
                ...obj.extras,
                [action.payload.type]: {
                  ...obj.extras[action.payload.type],
                  enabled: !obj.extras[action.payload.type].enabled
                }
              }
            }
          : obj
      )

      return {
        ...state,
        headerToggles: {
          ...state.headerToggles,
          [action.payload.type]: newObjects.every((obj) => {
            const isEnabled = obj.extras[action.payload.type].enabled
            switch (action.payload.type) {
              case 'disassembly':
                return isEnabled || obj.extras.disassembly.disassemblyCost === 0
              case 'packaging':
                return isEnabled || obj.extras.packaging.packagingCostInCents === 0
              case 'stairs':
              default:
                return isEnabled // stairs always can be enabled
            }
          })
        },
        objects: newObjects
      }
    }
    case SET_OBJECT_FLOORS:
      if (action.payload.objectId === 'all') {
        return {
          ...state,
          headerCounters: {
            ...state.headerCounters,
            stairs: action.payload.floors
          },
          objects: clone(state.objects).map((obj) => ({
            ...obj,
            extras: {
              ...obj.extras,
              stairs: {
                ...obj.extras.stairs,
                floors: action.payload.floors
              }
            }
          }))
        }
      }

      return {
        ...state,
        objects: clone(state.objects).map((obj) => {
          return obj.objectId === action.payload.objectId
            ? {
                ...obj,
                extras: {
                  ...obj.extras,
                  stairs: {
                    ...obj.extras.stairs,
                    floors: action.payload.floors
                  }
                }
              }
            : obj
        })
      }
    case VALIDATE_OPERATION_REQUEST:
      return { ...state, validating: true }
    case VALIDATE_OPERATION_SUCCESS:
      return { ...state, validating: false }
    case VALIDATE_OPERATION_FAILURE:
      return { ...state, validating: false, error: action.payload.error }
    case CLEAR_VALIDATION_VIEW_STATE:
      return initialState
    case RECALCULATE_OPERATION_REQUEST:
      return { ...state, loading: true }
    case RECALCULATE_OPERATION_SUCCESS:
      return { ...state, loading: false, fetchedRecalculation: true }
    case RECALCULATE_OPERATION_FAILURE:
      return { ...state, loading: false }
    case GET_BILLING_BY_TRANSACTION_REQUEST:
      return { ...state, loadingProvisionalBilling: true }
    case GET_BILLING_BY_TRANSACTION_SUCCESS:
      return { ...state, loadingProvisionalBilling: false, provisionalBilling: action.payload.provisionalBilling }
    case GET_BILLING_BY_TRANSACTION_FAILURE:
      return { ...state, loadingProvisionalBilling: false }
    case GET_DEPOSIT_OPERATION_BY_ID_REQUEST:
      return { ...state, loadingDepositOperation: true }
    case GET_DEPOSIT_OPERATION_BY_ID_SUCCESS: {
      const { objects, discountCode } = action.payload
      return { ...state, loadingDepositOperation: false, objects, discountCode }
    }
    case GET_DEPOSIT_OPERATION_BY_ID_FAILURE:
      return { ...state, loadingDepositOperation: false }
    default:
      return state
  }
}

export default ValidationViewReducer
