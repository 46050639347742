import React from 'react'

const VisaIcon = () => {
  return (
    <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4667 7.35042H8.60352L9.76887 0.671051H11.6319L10.4667 7.35042Z" fill="#00579F" />
      <path
        d="M17.2208 0.834475C16.8533 0.699328 16.2704 0.550108 15.5497 0.550108C13.7098 0.550108 12.4141 1.45959 12.4062 2.75988C12.3909 3.71923 13.3338 4.25208 14.0391 4.57196C14.7599 4.89882 15.0049 5.11217 15.0049 5.40352C14.9976 5.85099 14.4224 6.05726 13.886 6.05726C13.1422 6.05726 12.7436 5.95091 12.1379 5.70196L11.8926 5.59524L11.6318 7.09462C12.0688 7.27916 12.8739 7.44292 13.7098 7.4501C15.6647 7.4501 16.9375 6.55469 16.9525 5.16902C16.96 4.40866 16.4621 3.82605 15.3886 3.34996C14.737 3.04434 14.3379 2.83827 14.3379 2.52557C14.3455 2.2413 14.6754 1.95013 15.411 1.95013C16.0167 1.93587 16.4618 2.07083 16.7989 2.20588L16.9674 2.27681L17.2208 0.834475Z"
        fill="#00579F"
      />
      <path
        d="M19.6971 4.98416C19.8505 4.60044 20.4409 3.11533 20.4409 3.11533C20.4332 3.12959 20.5941 2.72452 20.6861 2.47585L20.8163 3.05139C20.8163 3.05139 21.1691 4.65021 21.2457 4.98416C20.9545 4.98416 20.0651 4.98416 19.6971 4.98416ZM21.9969 0.671051H20.5557C20.1113 0.671051 19.7737 0.791749 19.5819 1.22524L16.8145 7.35033H18.7694C18.7694 7.35033 19.0913 6.52594 19.1604 6.34839C19.3749 6.34839 21.2767 6.34839 21.5526 6.34839C21.6061 6.58289 21.7749 7.35033 21.7749 7.35033H23.5L21.9969 0.671051Z"
        fill="#00579F"
      />
      <path
        d="M7.04724 0.671051L5.22259 5.22574L5.02321 4.302C4.68587 3.23612 3.6279 2.07806 2.44727 1.50224L4.11858 7.34334H6.08881L9.01738 0.671051H7.04724Z"
        fill="#00579F"
      />
      <path
        d="M3.52831 0.671051H0.530667L0.5 0.80601C2.83835 1.36029 4.38699 2.69638 5.02326 4.30228L4.3716 1.23251C4.26432 0.805915 3.93462 0.685123 3.52831 0.671051Z"
        fill="#00579F"
      />
    </svg>
  )
}

export default VisaIcon
