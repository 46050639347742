import { Reducer } from 'react'
import {
  AttributesActions,
  CLOSE_DELETE_MODAL,
  DELETE_ATTRIBUTE_FAILURE,
  DELETE_ATTRIBUTE_REQUEST,
  DELETE_ATTRIBUTE_SUCCESS,
  DeleteAttributeState,
  OPEN_DELETE_MODAL
} from '../types/DeleteAttribute'

const initialState = {
  id: null,
  deleteAttributeModalOpen: false,
  loading: false,
  error: ''
}

const DeleteAttributeReducer: Reducer<DeleteAttributeState, AttributesActions> = (
  state = initialState,
  action
): DeleteAttributeState => {
  switch (action.type) {
    case DELETE_ATTRIBUTE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: ''
      }
    }
    case DELETE_ATTRIBUTE_SUCCESS: {
      return initialState
    }
    case DELETE_ATTRIBUTE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }
    case OPEN_DELETE_MODAL: {
      return {
        ...state,
        id: action.payload.id,
        deleteAttributeModalOpen: true,
        error: ''
      }
    }
    case CLOSE_DELETE_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default DeleteAttributeReducer
