import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'

interface DeleteParams {
  id: number
}

export function deletePriceBand(params: DeleteParams): Promise<void> {
  const { id } = params

  return apiPrivate
    .delete(URL_BASE_CATEGORIES + `/price-bands/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
