const newUserActionTypes = {
  SET_NAME: 'USERS/SET_NAME',
  SET_LAST_NAME: 'USERS/SET_LAST_NAME',
  SET_ACCOUNT_TYPE: 'USERS/SET_ACCOUNT_TYPE',
  SET_COMPANY_NAME: 'USERS/SET_COMPANY_NAME',
  SET_CUIL: 'USERS/SET_CUIL',
  SET_BILLING_TYPE: 'USERS/SET_BILLING_TYPE',
  SET_PASSWORD: 'USERS/SET_PASSWORD',
  SET_EMAIL: 'USERS/SET_EMAIL',
  SET_LANDLINE: 'USERS/SET_LANDLINE',
  SET_PHONE: 'USERS/SET_PHONE',
  SET_DOCUMENT_TYPE: 'USERS/SET_DOCUMENT_TYPE',
  SET_DNI: 'USERS/SET_DNI',
  SET_COUNTRY: 'USERS/SET_COUNTRY',
  SET_DATE_BIRTH: 'USERS/SET_DATE_BIRTH',
  SET_GENDER: 'USERS/SET_GENDER',
  SET_PAYMENT_METHOD: 'USERS/SET_PAYMENT_METHOD',
  SET_ROLE: 'USERS/SET_ROLE',
  SET_COUNTRY_PHONE_CODE: 'USERS/SET_COUNTRY_PHONE_CODE',
  SET_COUNTRY_ALTERNATIVE_PHONE_CODE: 'USERS/SET_COUNTRY_ALTERNATIVE_PHONE_CODE',
  SET_CREATE_MODAL_OPEN: 'USERS/SET_CREATE_MODAL_OPEN',
  CREATE_NEW_USER: 'USERS/CREATE_NEW_USER',
  CREATE_NEW_USER_SUCCESS: 'USERS/CREATE_NEW_USER_SUCCESS',
  CREATE_NEW_USER_FAIL: 'USERS/CREATE_NEW_USER_FAIL',
  EDIT_USER: 'USERS/EDIT_USER',
  EDIT_USER_SUCCESS: 'USERS/EDIT_USER_SUCCESS',
  EDIT_USER_FAIL: 'USERS/EDIT_USER_FAIL',
  SET_EDIT_MODAL_OPEN: 'USERS/SET_EDIT_MODAL_OPEN',
  VERIFY_DNI_REQUEST: 'USERS/VERIFY_DNI_REQUEST',
  VERIFY_DNI_SUCCESS: 'USERS/VERIFY_DNI_SUCCESS',
  VERIFY_DNI_FAILURE: 'USERS/VERIFY_DNI_FAILURE',
  VERIFY_EMAIL_REQUEST: 'USERS/VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS: 'USERS/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'USERS/VERIFY_EMAIL_FAILURE'
}

export default newUserActionTypes
