import { TaxCondition } from '../Users.constants'
import { BillingModalTypes, BillingModalActions } from '../types/billingModal'

export interface BillingModalState {
  id: number
  userId: number
  country: string
  identificationType: string
  identificationCode: string
  identificationNameClient: string
  billingAddress: string
  taxCondition: TaxCondition
  saleCondition: number
  email: string
  city: string
  province: string
  postalCode: string
  billingEnabled: boolean
  authorizationEnabled: boolean
  openModal: boolean
  loading: boolean
  error: string
}

const initialState: BillingModalState = {
  id: 0,
  userId: 0,
  country: '',
  identificationType: 'CUIT',
  identificationCode: '',
  identificationNameClient: '',
  billingAddress: '',
  taxCondition: TaxCondition.RI,
  saleCondition: 0,
  email: '',
  city: '',
  province: '',
  postalCode: '',
  billingEnabled: false,
  authorizationEnabled: false,
  openModal: false,
  loading: false,
  error: ''
}

function billingModalReducer(state: BillingModalState = initialState, action: BillingModalActions): BillingModalState {
  switch (action.type) {
    case BillingModalTypes.SET_IDENTIFICATION_TYPE:
      return {
        ...state,
        identificationType: action.payload.identificationType
      }
    case BillingModalTypes.SET_IDENTIFICATION_CODE:
      return {
        ...state,
        identificationCode: action.payload.identificationCode
      }
    case BillingModalTypes.SET_IDENTIFICATION_NAME_CLIENT:
      return {
        ...state,
        identificationNameClient: action.payload.identificationNameClient
      }
    case BillingModalTypes.SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload.billingAddress
      }
    case BillingModalTypes.SET_TAX_CONDITION:
      return {
        ...state,
        taxCondition: action.payload.taxCondition
      }
    case BillingModalTypes.SET_SALE_CONDITION:
      return {
        ...state,
        saleCondition: Number(action.payload.saleCondition)
      }
    case BillingModalTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload.email
      }
    case BillingModalTypes.SET_CITY:
      return {
        ...state,
        city: action.payload.city
      }
    case BillingModalTypes.SET_PROVINCE:
      return {
        ...state,
        province: action.payload.province
      }
    case BillingModalTypes.SET_POSTAL_CODE:
      return {
        ...state,
        postalCode: action.payload.postalCode
      }
    case BillingModalTypes.SET_BILLING_ENABLED:
      return {
        ...state,
        billingEnabled: action.payload.billingEnabled
      }
    case BillingModalTypes.SET_AUTHORIZATION_ENABLED:
      return {
        ...state,
        authorizationEnabled: action.payload.authorizationEnabled
      }
    case BillingModalTypes.OPEN_BILLING_MODAL:
      return {
        ...state,
        userId: action.payload.userId,
        country: action.payload.country,
        openModal: true
      }
    case BillingModalTypes.CLOSE_BILLING_MODAL:
      return initialState
    case BillingModalTypes.CANCEL_BILLING_MODAL:
      return initialState

    case BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_REQUEST:
      return {
        ...state,
        loading: true
      }
    case BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_SUCCESS:
      return initialState
    case BillingModalTypes.SAVE_BILLING_DATA_BY_USER_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_REQUEST:
      return {
        ...state,
        loading: true
      }
    case BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case BillingModalTypes.GET_BILLING_DATA_BY_USER_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      }

    case BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_SUCCESS:
      return initialState

    case BillingModalTypes.UPDATE_BILLING_DATA_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    default:
      return state
  }
}

export default billingModalReducer
