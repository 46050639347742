import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { URL_BASE_USERS } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'

export interface Paging {
  total: number
  limit: number
  offset: number
}

type Permission = {
  iD: number
  description: string
}

export interface APIRole {
  id: number
  description: string
  is_corporation: boolean
  permissions: Permission[]
}

interface APIResponse {
  paging: Paging
  results: APIRole[]
}

export type Role = CamelCasedPropertiesDeep<APIRole>

interface Response {
  roles: Role[]
  total: number
}

export type GetRolesParams = {
  isCorporate?: boolean
}

export function list(params: GetRolesParams): Promise<Response> {
  const parsedParams = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .get(`${URL_BASE_USERS}/roles/v2`, { params: parsedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return {
        roles: camelcaseKeys(response.data.results, { deep: true }),
        total: response.data.paging.total
      }
    })
    .catch((error) => {
      throw error
    })
}
