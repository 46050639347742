import React, { ReactNode, useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { Input } from 'antd'
import styles from './CellPhone.module.scss'
import Select from '../Select'
import { CountryPhoneCode } from '../CountrySelector/constants/constants'
import clsx from 'clsx'
import { CountryPrefig } from '../../utils/countryCodeList'

type Option = {
  value: string
  label: string
  image: ReactNode
}

export interface InputsPhoneProps {
  countries: CountryPrefig[]
  countryCode: string
  setCountryCode: (countryCode: string) => void
  codeArea: string
  setCodeArea: (codeArea: string) => void
  numberPhone: string
  setNumberPhone: (numberPhone: string) => void
  limit?: number
  errorCodeArea?: boolean
  errorPhoneNumber?: boolean
  disabled?: boolean
  tabsIndex?: number[]
}

export interface CellPhoneProps extends InputsPhoneProps {
  editMode?: boolean
  currentNumber?: string
  cleanErrors?: () => void
}

export const CellPhone: React.FC<CellPhoneProps> = ({
  editMode = false,
  currentNumber,
  countries,
  countryCode,
  setCountryCode,
  codeArea,
  setCodeArea,
  numberPhone,
  setNumberPhone,
  errorCodeArea,
  errorPhoneNumber,
  tabsIndex,
  cleanErrors,
  limit,
  disabled
}) => {
  const [editNumber, setEditNumber] = useState<boolean>(false)
  const props = {
    countries,
    numberPhone,
    setNumberPhone,
    countryCode,
    setCountryCode,
    codeArea,
    setCodeArea,
    errorCodeArea,
    errorPhoneNumber,
    tabsIndex,
    limit,
    disabled
  }

  const changeMood = () => {
    setCodeArea('')
    setNumberPhone('')
    setEditNumber(!editNumber)
    cleanErrors && cleanErrors()
  }

  return (
    <>
      <div className={styles.inputTitle}>
        <span>Celular*</span>
        {editNumber && (
          <div className={styles.inputTitleEdit}>
            <span>Celular actual </span>
            <button onClick={changeMood}>{currentNumber}</button>
          </div>
        )}
      </div>
      {!editMode ? (
        <InputsPhone {...props} />
      ) : !editNumber ? (
        <div className={styles.showCurrenNumberContainer}>
          <span className={styles.currentNumber}>{currentNumber}</span>
          <button onClick={changeMood} className={styles.buttonEditCurrentNumber}>
            Modificar
          </button>
        </div>
      ) : (
        <InputsPhone {...props} />
      )}
    </>
  )
}

export const InputsPhone: React.FC<InputsPhoneProps> = ({
  countries,
  countryCode,
  setCountryCode,
  codeArea,
  setCodeArea,
  numberPhone,
  setNumberPhone,
  errorCodeArea,
  errorPhoneNumber,
  tabsIndex,
  limit,
  disabled
}) => {
  const isCountryCodeArgentina = countryCode === CountryPhoneCode.ARGENTINA

  const phoneBodyDisabled = Boolean(isCountryCodeArgentina && (isEmpty(codeArea) || errorCodeArea))

  const countryOptions: Option[] = useMemo(
    () =>
      countries.map((c: CountryPrefig) => ({
        value: c.value,
        label: c.label,
        image: <img width="100%" src={c.image} alt={c.alt} />
      })),
    [countries]
  )

  const userCountrySelected = useMemo(
    () => countryOptions.find((countryOption) => countryOption.value === countryCode),
    [countryOptions, countryCode]
  )

  const handleChangeCountryCode = (option: Option) => {
    setCountryCode(option.value)
  }

  const formatOptionLabel = (option: Option) => {
    return (
      <div className={styles.containerOptionSelect}>
        <picture className={styles.imageOptionSelect}>{option.image}</picture>
        <div className={styles.contentOptionSelect}>{option.label}</div>
      </div>
    )
  }

  return (
    <div className={styles.containerPhoneFields}>
      <div className={styles.containerCountryCode}>
        <Select
          placeholder="País"
          className={styles.selectComponent}
          selected={userCountrySelected}
          options={countryOptions}
          optionRenderer={formatOptionLabel}
          onSelect={handleChangeCountryCode}
          disabled={disabled}
        />
      </div>
      {isCountryCodeArgentina && (
        <div>
          <Input
            className={clsx(styles.inputArea, errorCodeArea && styles.errorField)}
            placeholder="Area*"
            value={codeArea}
            onChange={(e) => {
              const value = e.target.value
              setCodeArea(value)
            }}
            disabled={disabled}
            autoComplete="nope"
            maxLength={4}
            tabIndex={tabsIndex && tabsIndex[1]}
          />
        </div>
      )}
      <div>
        <Input
          className={clsx(styles.inputNumber, errorPhoneNumber && styles.errorField)}
          placeholder="Número*"
          value={numberPhone}
          onChange={(e) => {
            const value = e.target.value
            setNumberPhone(value)
          }}
          maxLength={limit ?? 15}
          disabled={phoneBodyDisabled || disabled}
          autoComplete="nope"
          tabIndex={tabsIndex && tabsIndex[2]}
        />
      </div>
    </div>
  )
}
