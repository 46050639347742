import { Reducer } from 'redux'
import {
  VehicleListActions,
  GetVehiclesListTypes,
  VehiclesListStateType,
  RESET_FILTERS,
  SendVehiclesCSVTypes
} from '../types/vehiclesTable'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { EditVehicleTypes } from '../types/ABMVehicles'

export const PAGE_SIZE = 10

const initialState: VehiclesListStateType = {
  fetchingVehicles: false,
  sendingCSV: false,
  vehicles: [],
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  sort: {
    direction: SortDirection.DESC,
    field: 'id'
  },
  searchFilters: [
    { key: 'providerId', text: '' },
    { key: 'id', text: '' }
  ]
}

const VehiclesListReducer: Reducer<VehiclesListStateType, VehicleListActions> = (
  state: VehiclesListStateType = initialState,
  action: VehicleListActions
): VehiclesListStateType => {
  switch (action.type) {
    case RESET_FILTERS:
      return initialState
    case GetVehiclesListTypes.GET_VEHICLE_REQUEST:
      return {
        ...state,
        fetchingVehicles: true,
        searchFilters: action.payload.searchFilters || initialState.searchFilters,
        sort: action.payload.sort,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GetVehiclesListTypes.GET_VEHICLE_SUCCESS:
      return {
        ...state,
        fetchingVehicles: false,
        vehicles: action.payload.vehicles,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetVehiclesListTypes.GET_VEHICLE_FAILURE:
      return {
        ...state,
        fetchingVehicles: false
      }
    case SendVehiclesCSVTypes.SEND_VEHICLES_CSV_REQUEST:
      return { ...state, sendingCSV: true }
    case SendVehiclesCSVTypes.SEND_VEHICLES_CSV_FAILURE:
    case SendVehiclesCSVTypes.SEND_VEHICLES_CSV_SUCCESS:
      return { ...state, sendingCSV: false }
    case EditVehicleTypes.EDIT_VEHICLE_SUCCESS: {
      const updatedVehicle = action.payload.updatedVehicle
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id === updatedVehicle.id) {
            return updatedVehicle
          }
          return vehicle
        })
      }
    }
    default:
      return state
  }
}

export default VehiclesListReducer
