import React from 'react'
import { OPERATION_TYPE_VALUES } from '../../common/operationsTable/constants'
import { reduce } from 'ramda'

export const M3Label = () => (
  <span>
    m<sup>3</sup>
  </span>
)

const formatItems = (items) => items.map((item) => ({ ...item, custom: item.product_id === 0 }))

const getFullName = ({ name, last_name }) => name + ' ' + last_name

const getQuantity = (deposit) => {
  let m3 = 0
  switch (deposit.type) {
    case OPERATION_TYPE_VALUES.M3: {
      m3 = deposit.cubic_m
      break
    }
    case OPERATION_TYPE_VALUES.ITEMS: {
      m3 = reduce((acc, item) => acc + getM3(item), 0, deposit.items)
      break
    }
  }
  return m3.toFixed(2)
}

const getM3 = (item) => {
  return (item.amount * item.height * item.width * item.length) / 1000000
}

const getItemAmount = (items) => {
  const amount = reduce((acc, item) => acc + item.amount, 0, items)
  return amount > 0 ? amount : ''
}

export const addFormatFields = (deposits) =>
  deposits.map((deposit) => ({
    ...deposit,
    quantity: getQuantity(deposit),
    itemAmount: getItemAmount(deposit.items),
    user: {
      ...deposit.user,
      fullName: getFullName(deposit.user)
    },
    items: formatItems(deposit.items)
  }))
