import { create } from './create'
import { deleteit } from './delete'
import { list } from './list'
import { update } from './update'

export const ShippersAPI = {
  list,
  create,
  update,
  delete: deleteit
}
