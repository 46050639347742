import {
  CleanFilters,
  LocationFilter,
  LocationSearchFilter,
  LocationSorter,
  LocationsTableTypes,
  Params,
  SetFilter,
  SetPagination,
  SetSearchFilters,
  SetSorter
} from '../types/locationsTable'
import { URL_BASE_CATEGORIES, URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { PaginationConfig } from 'antd/lib/pagination'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import { API } from '../../../projectApi'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { keysToSnake } from '../../../utils/camelCaseToSnake'

const getNewParams = (params: Params): Params => ({
  ...params,
  show: params?.show || 'ALL'
})

const actions = {
  getLocationInfo(params: Params): AppThunk {
    const newParams = getNewParams(params)

    return (dispatch) => {
      dispatch({ type: LocationsTableTypes.FETCH_LOCATIONS_INFO_REQUEST })
      return apiPrivate
        .get(URL_BASE_CATEGORIES + '/location-info', { params: newParams })
        .then((response) => {
          const locationInfo = keysToCamel(response.data.locationInfo)
          dispatch({
            type: LocationsTableTypes.FETCH_LOCATIONS_INFO_SUCCESS,
            payload: { locationInfo }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, LocationsTableTypes.FETCH_LOCATIONS_INFO_FAILURE, dispatch)
        })
    }
  },
  updateLocationInfo(): AppThunk {
    return async (dispatch) => {
      dispatch({ type: LocationsTableTypes.UPDATE_LOCATIONS_REQUEST })
      const toastId = setToastLoading('Actualizando información de ubicaciones. Por favor espere...')

      try {
        await API.CategoryCreation.LocationInfo.update()

        setToastSuccessUpdate(toastId, { render: 'Ubicaciones actualizadas correctamente' })
        dispatch({ type: LocationsTableTypes.UPDATE_LOCATIONS_SUCCESS })

        dispatch(actions.getLocationsFromStore())
      } catch (error) {
        setToastErrorUpdate(toastId, { render: 'Hubo un error al actualizar las ubicaciones' })
        return handleErrorsWithAction(error, LocationsTableTypes.UPDATE_LOCATIONS_FAILURE, dispatch)
      }
    }
  },
  getLocations(params: Params): AppThunk {
    const newParams = getNewParams(params)
    const snakeParams = keysToSnake(newParams)

    return (dispatch) => {
      dispatch({ type: LocationsTableTypes.FETCH_LOCATIONS_REQUEST })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + '/locations/v2', { params: snakeParams })
        .then((response) => {
          const locations = keysToCamel(response.data.locations)
          const quantity = response.data.total
          dispatch({
            type: LocationsTableTypes.FETCH_LOCATIONS_SUCCESS,
            payload: { locations, quantity, params: newParams }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, LocationsTableTypes.FETCH_LOCATIONS_FAILURE, dispatch)
        })
    }
  },

  getLocationsFromStore(): AppThunk {
    return (dispatch, getState) => {
      const params = getState().Locations.locationsTable.params
      dispatch(actions.getLocations(params))
      dispatch(actions.getLocationInfo(params))
    }
  },

  setPagination(pagination: PaginationConfig): SetPagination {
    return { type: LocationsTableTypes.SET_PAGINATION, payload: { pagination } }
  },
  setSorter: (sorter: LocationSorter): SetSorter => {
    return { type: LocationsTableTypes.SET_SORTER, payload: { sorter } }
  },
  setFilter: (filter: LocationFilter): SetFilter => {
    return { type: LocationsTableTypes.SET_FILTER, payload: { filter } }
  },
  setSearchFilters: (searchFilters: LocationSearchFilter[]): SetSearchFilters => {
    return { type: LocationsTableTypes.SET_SEARCH_FILTERS, payload: { searchFilters } }
  },
  cleanFilters: (): CleanFilters => {
    return { type: LocationsTableTypes.CLEAN_FILTERS }
  }
}

export default actions
