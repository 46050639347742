import axios from 'axios'
import { CountryIdCode } from './components/CountrySelector/constants/constants'

export const apiExternal = axios.create()

export const apiPublic = axios.create({
  headers: { 'x-origin': 'backoffice/adm-web' }
})

export const apiPrivate = axios.create({
  headers: { Authorization: '', 'x-origin': 'backoffice/adm-web' }
})

export const apiSpreedly = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

export const addCountryParam = (countryCode: CountryIdCode) => {
  apiPrivate.defaults.params = {
    ...(apiPrivate.defaults.params || {}),
    country_code: countryCode || undefined
  }
}
