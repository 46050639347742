import { Origins } from '../../../projectApi/Mercurio/BO/Origins/common'
import { Source } from '../../../projectApi/Mercurio/BO/Config/list'

export interface OriginsTableState {
  origins: Origins[]
  sources: Source[]
  loadingOrigins: boolean
  error: string
}

export type ParamsType = {
  limit: number
  offset: number
}

export const GET_ORIGINS_REQUEST = 'ORIGINS/ORIGINS_TABLE/GET_ORIGINS_REQUEST'
export const GET_ORIGINS_SUCCESS = 'ORIGINS/ORIGINS_TABLE/GET_ORIGINS_SUCCESS'
export const GET_ORIGINS_FAILURE = 'ORIGINS/ORIGINS_TABLE/GET_ORIGINS_FAILURE'

export const GET_SOURCES_SUCCESS = 'ORIGINS/ORIGINS_TABLE/GET_SOURCES_SUCCESS'
export const GET_SOURCES_FAILURE = 'ORIGINS/ORIGINS_TABLE/GET_SOURCES_FAILURE'

export interface GetSourcesSuccess {
  type: typeof GET_SOURCES_SUCCESS
  payload: { sources: Source[] }
}

export interface GetOriginsRequest {
  type: typeof GET_ORIGINS_REQUEST
}

export interface GetOriginsSuccess {
  type: typeof GET_ORIGINS_SUCCESS
  payload: {
    origins: Origins[]
  }
}

export interface GetOriginsFailure {
  type: typeof GET_ORIGINS_FAILURE
  payload: { error: string }
}

export type GetOriginsAction = GetSourcesSuccess | GetOriginsRequest | GetOriginsSuccess | GetOriginsFailure

export type OriginsTableAction = GetOriginsAction
