import { Reducer } from 'redux'
import { TransportModeName } from '../../../../common/operations/constants'
import { TransportTypesPricing } from '../../entities'
import {
  CLEAN_STATES,
  GET_TRANSACTION_DATA_FAILURE,
  GET_TRANSACTION_DATA_REQUEST,
  GET_TRANSACTION_DATA_SUCCESS
} from '../../types/EditDepositViewTypes/EditDepositView'
import {
  FETCH_COST_FAILURE,
  FETCH_COST_REQUEST,
  FETCH_COST_SUCCESS,
  GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE,
  GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST,
  GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS,
  GET_TRANSPORT_MODE_FAILURE,
  GET_TRANSPORT_MODE_REQUEST,
  GET_TRANSPORT_MODE_SUCCESS,
  SET_CUSTOM_TRANSPORT_COST,
  SET_DESTINATION_DEPOSIT,
  SET_TRANSPORT_MODE,
  SET_WHERE_FETCH_COST,
  TransportActions,
  TransportState
} from '../../types/EditDepositViewTypes/transport'

const transportInitialState: TransportState = {
  loadingTransportMode: false,
  transportModeList: [],
  transportMode: '',
  requireTransport: false,
  loadingDestinationDeposits: false,
  destinationDepositsList: [],
  destinationDeposit: 0,
  address: {
    addressId: 0,
    addressString: '',
    apartment: '',
    city: '',
    comment: '',
    floor: '',
    number: '',
    postalCode: '',
    province: '',
    street: ''
  },
  loadingFetchCost: false,
  fetchedCost: 0,
  transportCost: 0,
  tollCostInCents: 0,
  transportTypePricing: TransportTypesPricing.COMMON,
  where: '',
  transportDetail: null
}

const TransportReducer: Reducer<TransportState, TransportActions> = (
  state = transportInitialState,
  action
): TransportState => {
  switch (action.type) {
    case CLEAN_STATES:
      return transportInitialState
    case GET_TRANSACTION_DATA_REQUEST:
      return {
        ...state
      }
    case GET_TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        destinationDeposit: action.payload.transaction.transport.depositId,
        transportCost:
          action.payload.transaction.transport.transportMode === TransportModeName.COLLECT
            ? action.payload.transaction.transport.transportCost
            : 0,
        transportMode: action.payload.transaction.transport.transportMode,
        transportTypePricing: action.payload.transaction.transport.transportType,
        fetchedCost: action.payload.transaction.transport.transportCost,
        tollCostInCents: action.payload.transaction.transport.tollCostInCents,
        address: action.payload.transaction.transport.address,
        requireTransport: action.payload.transaction.transport.transportMode === TransportModeName.COLLECT,
        where: action.payload.transaction.transport.address.addressString
      }
    case GET_TRANSACTION_DATA_FAILURE:
      return {
        ...state
      }
    case GET_TRANSPORT_MODE_REQUEST:
      return {
        ...state,
        loadingTransportMode: true
      }
    case GET_TRANSPORT_MODE_SUCCESS:
      return {
        ...state,
        transportModeList: action.payload.transportModeList,
        loadingTransportMode: false
      }
    case GET_TRANSPORT_MODE_FAILURE:
      return {
        ...state,
        loadingTransportMode: false
      }
    case GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST:
      return {
        ...state,
        loadingDestinationDeposits: true
      }
    case GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS: {
      const { deposits } = action.payload
      const currentDeposit = deposits.find((deposit) => deposit.id === state.destinationDeposit)

      return {
        ...state,
        destinationDepositsList: deposits,
        destinationDeposit: currentDeposit ? currentDeposit.id : deposits[0].id,
        loadingDestinationDeposits: false
      }
    }
    case GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE:
      return {
        ...state,
        loadingDestinationDeposits: false
      }
    case FETCH_COST_REQUEST:
      return {
        ...state,
        loadingFetchCost: true
      }
    case FETCH_COST_SUCCESS:
      return {
        ...state,
        loadingFetchCost: false,
        where: action.payload.where,
        fetchedCost: action.payload.fetchedCost,
        transportCost: action.payload.cost,
        transportDetail: action.payload.transportDetail,
        tollCostInCents: action.payload.tollCostInCents
      }
    case FETCH_COST_FAILURE:
      return {
        ...state,
        loadingFetchCost: false
      }
    case SET_TRANSPORT_MODE: {
      const transportMode = action.payload.transportMode
      const transportModeInfo = state.transportModeList.find((tm) => tm.name === transportMode)
      const requireTransport =
        transportModeInfo?.requireAddressOrigin || action.payload.transportMode === TransportModeName.COLLECT
      return {
        ...state,
        transportMode,
        requireTransport,
        transportDetail: null,
        transportCost: requireTransport ? state.transportCost : 0
      }
    }
    case SET_DESTINATION_DEPOSIT:
      return {
        ...state,
        destinationDeposit: action.payload.destinationDepositId
      }
    case SET_WHERE_FETCH_COST:
      return {
        ...state,
        where: action.payload.where
      }
    case SET_CUSTOM_TRANSPORT_COST:
      return {
        ...state,
        transportTypePricing: action.payload.transportTypePricing,
        transportCost: action.payload.newTransportCost
      }
    default:
      return state
  }
}

export default TransportReducer
