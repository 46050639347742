import React from 'react'

const ObjectsToSearch: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.40001 0.1125L12.1125 2.25L15.825 4.3875L16.0125 4.5V12.6375L17.5875 13.575L17.2125 14.2125L13.0125 11.775C11.475 13.95 8.51251 14.475 6.33751 12.9375C4.16251 11.4 3.63751 8.4375 5.17501 6.2625C6.71251 4.0875 9.67501 3.5625 11.85 5.1C13.8 6.45 14.4375 9.0375 13.3875 11.175L15.2625 12.2625V5.3625L13.875 6.15C13.7625 5.925 13.6125 5.7375 13.4625 5.55L14.925 4.725L11.7375 2.8875L8.21251 0.8625L6.48751 1.875L9.22501 3.45C8.77501 3.45 8.36251 3.4875 7.91251 3.5625L5.73751 2.2875L4.68751 2.8875L4.01251 3.3L5.96251 4.425C5.73751 4.575 5.55001 4.725 5.32501 4.9125L3.26251 3.7125L1.53751 4.725L4.23751 6.2625C4.12501 6.4875 4.01251 6.7125 3.90001 6.9375L1.16251 5.3625V13.0875L1.91251 13.5V11.0625H2.66251V13.95L3.41251 14.3625V11.8125H4.16251V14.775H4.08751L4.68751 15.1125L7.83751 16.95V14.4375C8.10001 14.475 8.32501 14.55 8.58751 14.55V16.95L14.5125 13.5L15.2625 13.9125L12.1125 15.75L8.40001 17.8875L8.21251 18L8.02501 17.8875L4.31251 15.75L0.600006 13.6125L0.412506 13.5V4.5L0.600006 4.3875L3.07501 2.9625L4.31251 2.25L5.55001 1.5375L5.73751 1.425L8.02501 0.1125L8.21251 0L8.40001 0.1125ZM5.73751 9C5.73751 8.1 6.07501 7.275 6.71251 6.6375C7.35001 6 8.17501 5.6625 9.07501 5.6625V6.4125C8.40001 6.4125 7.72501 6.675 7.23751 7.1625C6.75001 7.65 6.48751 8.325 6.48751 9H5.73751ZM11.9625 6.1125C10.35 4.5375 7.76251 4.5375 6.18751 6.1125C4.61251 7.6875 4.61251 10.3125 6.18751 11.8875C7.76251 13.4625 10.3875 13.4625 11.9625 11.8875C12.7125 11.1375 13.1625 10.0875 13.1625 9C13.1625 7.9125 12.75 6.8625 11.9625 6.1125Z"
        fill="#263238"
      />
    </svg>
  )
}

export default ObjectsToSearch
