import { Reducer } from 'redux'
import {
  OPEN_MODAL_PAYMENT,
  PAY_BILLING_FAILURE,
  PAY_BILLING_REQUEST,
  PAY_BILLING_SUCCESS,
  PaymentModalAction,
  PaymentModalState
} from '../types/paymentModal'

const initialState: PaymentModalState = {
  modalOpen: false,
  billing: null,
  error: '',
  loading: false
}

const PaymentModalReducer: Reducer<PaymentModalState, PaymentModalAction> = (
  state = initialState,
  action
): PaymentModalState => {
  switch (action.type) {
    case OPEN_MODAL_PAYMENT:
      return {
        ...state,
        modalOpen: action.payload.modalOpen,
        billing: action.payload.billing
      }
    case PAY_BILLING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case PAY_BILLING_SUCCESS:
      return {
        ...initialState
      }
    case PAY_BILLING_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default PaymentModalReducer
