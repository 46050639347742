import React from 'react'
import Component, { DeleteOriginModalProps } from '../components/deleteOrigin'
import { bindActionCreators } from 'redux'
import actions from '../actionCreator/deleteOrigin'
import { useDispatch, useSelector } from 'react-redux'

const DeleteOriginContainer = () => {
  const dispatch = useDispatch()
  const deleteOriginState = useSelector((state) => state.Origins.delete)
  const deleteOriginActions = bindActionCreators(actions, dispatch)

  const props: DeleteOriginModalProps = {
    deleteOriginState,
    deleteOriginActions
  }

  return <Component {...props} />
}

export default DeleteOriginContainer
