import React from 'react'
import { Table, Tooltip } from 'antd'
import moment from 'moment'
import { Trash } from '../../icons'
import { formatToFilters, getLabelFromOptions } from './Payment.selectors'
import { chargeTypes } from './Payments.constants'
import { formatNumber } from '../../utils/formatNumber'
import ClientIDLinkComponent from '../../components/ClientIDLink/ClientIDLink'
import { getColumnSearchProps } from '../../utils/columnSearchProps'
import { usePaginationLabel } from '../../utils/hooks/usePaginationLabel'
import styles from './PaymentsTable.module.scss'

const renderActions = (setDeleteModalOpen, charge) => (
  <div>
    <Tooltip placement="top" title={'Eliminar regla'}>
      <i style={{ marginLeft: '8px' }} onClick={() => setDeleteModalOpen(true, charge)}>
        <Trash width="16px" height="16px" />
      </i>
    </Tooltip>
  </div>
)

const PaymentsTable = (props) => {
  const {
    params,
    filter,
    searchFilters,
    handleSearch,
    handleChange,
    charges,
    paymentMethods,
    loading,
    history,
    currentPagination,
    setDeleteModalOpen
  } = props
  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      defaultSortOrder: 'descend',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('id', 'ID', handleSearch, searchFilters)
    },
    {
      title: 'Tipo',
      dataIndex: 'Type',
      key: 'type',
      width: 120,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      filters: formatToFilters(chargeTypes),
      filterMultiple: false,
      filteredValue: filter.type,
      render: (text) => getLabelFromOptions(text, chargeTypes)
    },
    {
      title: 'Forma de Pago',
      dataIndex: 'PaymentMethod',
      key: 'payment_method',
      width: 120,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      filters: formatToFilters(paymentMethods),
      filterMultiple: false,
      filteredValue: filter.payment_method,
      render: (text) => getLabelFromOptions(text, paymentMethods)
    },
    {
      title: 'ID Usuario',
      dataIndex: 'User.ID',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (id) => (
        <ClientIDLinkComponent id={id} history={history}>
          {id}
        </ClientIDLinkComponent>
      ),
      ...getColumnSearchProps('user_id', 'ID Usuario', handleSearch, searchFilters)
    },
    {
      title: 'Nombre',
      dataIndex: 'User.Name',
      sorter: false
    },
    {
      title: 'Apellido',
      dataIndex: 'User.LastName',
      sorter: false
    },
    {
      title: 'Monto',
      dataIndex: 'Amount',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (n) => '$' + formatNumber(n)
    },
    {
      title: 'Día',
      dataIndex: 'DayToCharge',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <span>{record.Type === 1 ? text : ''}</span>,
      ...getColumnSearchProps('day', 'Día', handleSearch, searchFilters)
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'StartDate',
      key: 'start_date',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
      ...getColumnSearchProps('start_date', 'Fecha de inicio', handleSearch, searchFilters, 'date')
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'EndDate',
      key: 'end_date',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => {
        const today = moment()
        const endDate = moment(text)
        return endDate.diff(today, 'years') < 10 ? <span>{endDate.format('DD/MM/YYYY')}</span> : null
      },
      ...getColumnSearchProps('end_date', 'Fecha de Fin', handleSearch, searchFilters, 'date')
    },
    {
      title: 'Acciones',
      key: 'Actions',
      render: (_, record) => renderActions(setDeleteModalOpen, record)
    }
  ]

  const showTotal = usePaginationLabel(currentPagination.total, 'reglas de cobro')

  const pagination = {
    pageSize: params.Limit,
    total: currentPagination.total,
    current: currentPagination.current,
    size: 'small',
    style: {
      margin: '12px 0'
    },
    showTotal
  }

  return (
    <Table
      columns={columns}
      dataSource={charges}
      loading={loading}
      pagination={pagination}
      rowKey={(record) => record.ID}
      style={{ overscrollX: 'auto' }}
      scroll={{ x: true }}
      onChange={handleChange}
      className={styles.customTableProps}
    />
  )
}

export default PaymentsTable
