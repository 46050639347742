import React from 'react'
import styles from './excludeUserModal.module.scss'
import { Col, Modal, Row, Radio, DatePicker } from 'antd'
import InputGroup from '../../../components/InputGroup'
import { Option, Periods } from '../types/userExclusionModals'
import { RadioChangeEvent } from 'antd/es/radio'
import moment, { Moment } from 'moment'
import Select from '../../../components/Select'
import { BaseOption } from '../../../components/Select/Select'
import { UserResponse } from '../../../projectApi/TransactionHandler/BillingData/list'

const { MonthPicker } = DatePicker

type SetterPeriodProps = {
  selectedPeriod: Periods
  periodFrom: Moment
  periodTo: Moment
  setPeriodFrom?: (periodFrom: Moment) => void
  setPeriodTo: (periodTo: Moment) => void
}

const MONTH_FORMAT = 'MM YYYY'
const SPECIFIC_DATE_FORMAT = 'DD-MM-YYYY'

const SpecificDatesComponent = ({
  selectedPeriod,
  periodFrom,
  periodTo,
  setPeriodTo,
  setPeriodFrom
}: SetterPeriodProps) => {
  return (
    <div className={styles.fromToContainer}>
      <div>
        Desde:{' '}
        <DatePicker
          style={{ width: 130 }}
          format={SPECIFIC_DATE_FORMAT}
          defaultValue={periodFrom}
          onChange={(date) => (setPeriodFrom ? setPeriodFrom(date || moment()) : undefined)}
          allowClear={false}
          disabledDate={(current) => current?.isBefore(moment()) || false}
        />{' '}
      </div>
      <div>
        Hasta:{' '}
        <DatePicker
          style={{ width: 130 }}
          format={SPECIFIC_DATE_FORMAT}
          defaultValue={periodTo}
          onChange={(date) => setPeriodTo(date || moment())}
          allowClear={false}
          disabled={selectedPeriod !== Periods.SPECIFIC_DATES}
          disabledDate={(current) => current?.isBefore(periodFrom) || false}
        />
      </div>
    </div>
  )
}

const SeveralPeriodComponent = ({ selectedPeriod, periodFrom, periodTo, setPeriodTo }: SetterPeriodProps) => (
  <div className={styles.fromToContainer}>
    <div>
      Desde: <MonthPicker style={{ width: 100 }} value={periodFrom} format={MONTH_FORMAT} allowClear={false} disabled />{' '}
    </div>
    <div>
      Hasta:{' '}
      <MonthPicker
        style={{ width: 100 }}
        value={periodTo}
        format={MONTH_FORMAT}
        allowClear={false}
        onChange={(date) => setPeriodTo(date || moment())}
        disabled={selectedPeriod !== Periods.SEVERAL_PERIODS}
        disabledDate={(current) => current?.isBefore(periodFrom) || false}
      />
    </div>
  </div>
)

export type UserExclusionModalComponentProps = {
  handleUserExclusion: () => void
  handleClose: () => void
  specificPeriodFrom: Moment
  specificPeriodTo: Moment
  periodFrom: Moment
  periodTo: Moment
  setPeriodTo: (periodTo: Moment) => void
  setSpecificPeriodFrom: (specificPeriodFrom: Moment) => void
  setSpecificPeriodTo: (specificPeriodTo: Moment) => void
  selectedOption: Periods
  selectedReason: BaseOption<number> | null
  reasonOptions: BaseOption<number>[]
  onOptionChange: (e: RadioChangeEvent) => void
  onSelectReason: (reason: BaseOption<number>) => void
  openModal: boolean
  loading: boolean
  loadingUserExclusionReasons: boolean
  user?: UserResponse
}

const UserExclusionModal = (props: UserExclusionModalComponentProps) => {
  const {
    handleUserExclusion,
    handleClose,
    selectedOption,
    onOptionChange,
    openModal,
    loading,
    loadingUserExclusionReasons,
    specificPeriodFrom,
    specificPeriodTo,
    periodFrom,
    periodTo,
    setPeriodTo,
    setSpecificPeriodFrom,
    setSpecificPeriodTo,
    selectedReason,
    reasonOptions,
    onSelectReason,
    user
  } = props

  const options: Option<Periods>[] = [
    { value: Periods.THIS_PERIOD, label: `Período actual (${periodFrom.format('MM/YYYY')})` },
    {
      value: Periods.SPECIFIC_DATES,
      label: 'Fechas específicas',
      extraComponent: selectedOption === Periods.SPECIFIC_DATES && (
        <SpecificDatesComponent
          selectedPeriod={selectedOption}
          setPeriodFrom={setSpecificPeriodFrom}
          setPeriodTo={setSpecificPeriodTo}
          periodFrom={specificPeriodFrom}
          periodTo={specificPeriodTo}
        />
      )
    },
    {
      value: Periods.SEVERAL_PERIODS,
      label: 'Varios períodos',
      extraComponent: selectedOption === Periods.SEVERAL_PERIODS && (
        <SeveralPeriodComponent
          selectedPeriod={selectedOption}
          setPeriodTo={setPeriodTo}
          periodFrom={periodFrom}
          periodTo={periodTo}
        />
      )
    },
    { value: Periods.FOREVER, label: 'Para siempre' }
  ]

  const disabled = Boolean(!selectedReason)

  return (
    <Modal
      className={styles.modal}
      title={'Excluir usuario de la facturación'}
      visible={openModal}
      closable={true}
      keyboard={false}
      onOk={() => handleUserExclusion()}
      onCancel={() => handleClose()}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: disabled || loading
      }}
      okText="Confirmar"
      cancelText={'Cancelar'}>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24}>
          <InputGroup title="Información del usuario">
            <div className={styles.userContainer}>
              <div>ID: {user?.id}</div>
              <div>Nombre: {user?.name}</div>
              <div>Apellido: {user?.lastName}</div>
            </div>
          </InputGroup>
          <InputGroup title="Duración">
            <Radio.Group style={{ width: '100%' }} value={selectedOption} onChange={onOptionChange}>
              {options.map(({ value, label, extraComponent }) => (
                <div key={value}>
                  <Radio className={styles.radio} value={value}>
                    {label}
                  </Radio>
                  {extraComponent}
                </div>
              ))}
            </Radio.Group>
          </InputGroup>
          <InputGroup title="Motivo">
            <Select
              loading={loadingUserExclusionReasons}
              placeholder="Seleccione una razón"
              selected={selectedReason}
              options={reasonOptions}
              onSelect={onSelectReason}
            />
          </InputGroup>
        </Col>
      </Row>
    </Modal>
  )
}

export default UserExclusionModal
