import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_SALES } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'

export type APIResponse = {
  sales_requests: number
  accepted_sales_requests: number
  rejected_sales_requests: number
  pending_sales_requests: number
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export function list(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_SALES}/metrics/sales-requests`)
    .then(({ data }: AxiosResponse<APIResponse>) => {
      return {
        salesRequests: data.sales_requests,
        acceptedSalesRequests: data.accepted_sales_requests,
        rejectedSalesRequests: data.rejected_sales_requests,
        pendingSalesRequests: data.pending_sales_requests
      }
    })
    .catch((error) => {
      throw error
    })
}
