import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FeatureType } from './types'

export function useFeatureManager(featureType: FeatureType): boolean {
  const features = useSelector((root) => root.FeatureManager.features)

  const isActive = useMemo(() => {
    const f = features.find((f) => f.type === featureType)
    if (!f) return false
    return f.active
  }, [featureType, features])

  return isActive
}
