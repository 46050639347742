export enum UploadPhotoActionTypes {
  UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST',
  UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE'
}

export interface UploadPhotoRequest {
  type: UploadPhotoActionTypes.UPLOAD_PHOTO_REQUEST
}

export interface UploadPhotoSuccess {
  type: UploadPhotoActionTypes.UPLOAD_PHOTO_SUCCESS
}

export interface UploadPhotoFailure {
  type: UploadPhotoActionTypes.UPLOAD_PHOTO_FAILURE
  payload: {
    error: string
  }
}

export type UploadPhotosAction = UploadPhotoRequest | UploadPhotoSuccess | UploadPhotoFailure
