import React from 'react'
import { Modal } from 'antd'
import styles from './DeleteVehicle.module.scss'
import { Vehicle } from '../../../projectApi/Trips/Vehicles/common'

type DeleteVehicleModalProps = {
  selectedVehicle?: Vehicle | null
  deletingVehicle: boolean
  disabledDeleteButton: boolean
  deleteModalVisible: boolean
  setDeleteModalVisibility: (modalVisible: boolean) => void
  handleDeleteVehicle: () => void
}

const DeleteVehicleModal = ({
  selectedVehicle,
  deletingVehicle,
  disabledDeleteButton,
  deleteModalVisible,
  setDeleteModalVisibility,
  handleDeleteVehicle
}: DeleteVehicleModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={() => setDeleteModalVisibility(false)}
      title={'Eliminar vehículo'}
      confirmLoading={deletingVehicle}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={handleDeleteVehicle}
      okButtonProps={{ disabled: disabledDeleteButton }}
      destroyOnClose>
      <InfoCard
        id={selectedVehicle?.id}
        name={selectedVehicle?.name}
        provider={`${selectedVehicle?.provider.id} - ${selectedVehicle?.provider.name}`}
      />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar este vehículo?</h4>
    </Modal>
  )
}

export default DeleteVehicleModal

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; name?: string; provider?: string }> = ({ id, name, provider }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos del Vehículo:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={name} label={'Nombre'} />
      <InfoItem data={provider} label={'Proveedor'} />
    </div>
  </header>
)
