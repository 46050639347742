import { Address, AddressActions, AddressTypes } from './types'
import userTypes from '../../sections/Users/types/newUser'
import { ProposalTypes } from '../../sections/NewProposal/types/proposals'

const initialAddress = {
  address: {
    addressGoogle: '',
    street: '',
    number: '',
    postalCode: '',
    province: '',
    city: '',
    country: '',
    floor: '',
    apartment: ''
  },
  error: ''
}

export type AddressState = {
  address: Address
  error: string
}

function reducer(state: AddressState = initialAddress, action: AddressActions): AddressState {
  switch (action.type) {
    case userTypes.SET_CREATE_MODAL_OPEN: {
      // @ts-ignore
      const { byProposal } = action.payload
      return byProposal ? state : initialAddress
    }
    case userTypes.SET_EDIT_MODAL_OPEN: {
      // @ts-ignore
      return { ...initialAddress, address: action.payload.user.address }
    }
    case AddressTypes.CLEAN_ADDRESS:
      return initialAddress
    case AddressTypes.SET_ADDRESS:
      return {
        ...state,
        address: { ...state.address, ...action.payload.address }
      }
    case AddressTypes.SET_ERROR_ADDRESS:
      return { ...state, error: action.payload.error }
    case ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS: {
      const { address, country, original, postalCode, floor, city, province, number, apartment } =
        action.payload.proposal.prospectAddress
      return {
        ...state,
        address: {
          addressGoogle: original,
          street: address,
          number,
          floor,
          apartment,
          postalCode: postalCode.postalCode,
          city,
          province,
          country
        }
      }
    }
    default:
      return state
  }
}

export default reducer
