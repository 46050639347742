export interface FilesModalState {
  modalOpen: boolean
  error: string
  loading: boolean
}
export const OPEN_MODAL_FILES = 'BILLINGS/OPEN_MODAL_FILES'

export interface OpenModalFiles {
  type: typeof OPEN_MODAL_FILES
  payload: {
    modalOpen: boolean
  }
}

export type FilesModalAction = OpenModalFiles
