/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_MERCURIO } from '../../../endpoints'

interface CreateParams {
  country: string
  email: string
  phone: string
  gender: string
  name?: string
  lastName?: string
  source?: string
  contactMedium?: string
}

export function create(lead: CreateParams): Promise<void> {
  const formData = new FormData()
  const { name, lastName, email, phone, gender, source, country, contactMedium } = lead
  formData.append('country', country)
  formData.append('email', email)
  formData.append('gender', gender)
  formData.append('phone', phone)
  if (name) formData.append('first_name', name)
  if (lastName) formData.append('last_name', lastName)
  if (source) formData.append('source', source)
  if (contactMedium) formData.append('contact_medium_id', contactMedium)
  formData.append('event', 'create')

  return apiPrivate
    .post(`${URL_BASE_MERCURIO}/bo/prospects/v1`, formData)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
