import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  CANCEL_SEND_BY_EMAIL_MODAL,
  CLOSE_SEND_BY_EMAIL_MODAL,
  CancelSendByEmailModal,
  CloseSendByEmailModal,
  OPEN_SEND_BY_EMAIL_MODAL,
  OpenSendByEmailModal,
  SEND_BY_EMAIL_FAILURE,
  SEND_BY_EMAIL_REQUEST,
  SEND_BY_EMAIL_SUCCESS,
  SET_EMAIL,
  SET_ERROR,
  SendByEmailRequest,
  SendByEmailSuccess,
  SetEmail,
  SetError
} from '../types/sendByMail'

const SendByEmailActionCreators = {
  setEmail: ({ email }: { email: string }): SetEmail => ({
    type: SET_EMAIL,
    payload: { email }
  }),
  setError: ({ error }: { error: string }): SetError => ({
    type: SET_ERROR,
    payload: { error }
  }),
  openSendByEmail: ({ proposalId }: { proposalId: string }): OpenSendByEmailModal => {
    return {
      type: OPEN_SEND_BY_EMAIL_MODAL,
      payload: { proposalId }
    }
  },
  closeSendByEmail: (): CloseSendByEmailModal => ({
    type: CLOSE_SEND_BY_EMAIL_MODAL
  }),
  cancelSendByEmail: (): CancelSendByEmailModal => ({
    type: CANCEL_SEND_BY_EMAIL_MODAL
  }),
  sendByEmail:
    ({ proposalId, email }: { proposalId: string; email: string }): AppThunk =>
    async (dispatch) => {
      const request: SendByEmailRequest = {
        type: SEND_BY_EMAIL_REQUEST
      }

      dispatch(request)

      try {
        await API.TransactionHandler.Proposals.sendEmail({ proposalId, email })

        const success: SendByEmailSuccess = {
          type: SEND_BY_EMAIL_SUCCESS
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, SEND_BY_EMAIL_FAILURE, dispatch)
      }
    }
}

export default SendByEmailActionCreators
