import React from 'react'
import { Col, Input, Modal, Row } from 'antd'
import InputGroup from '../../components/InputGroup'
import Select from '../../components/Select'
import { getOptionValue } from '../Payments/Payment.selectors'
import { formatStatusFiltersToOptions } from './Activity.selectors'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

const UpdateActivityModal = (props) => {
  const { charge, setUpdateModalOpen, updateActivity, setStatus, setReference } = props
  const { modalOpen, status_id, payment_reference, error, loading } = charge

  const { title, buttonLabel } = {
    title: 'Actualizar Pago',
    buttonLabel: 'Actualizar'
  }

  return (
    <Modal
      title={title}
      visible={modalOpen}
      closable={!loading}
      maskClosable={!loading}
      onOk={() => updateActivity(charge)}
      onCancel={() => setUpdateModalOpen(false)}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading: loading,
        disabled: loading
      }}
      okText={buttonLabel}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24}>
          <InputGroup title="Estado de Pago">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <p style={styles.inputTitle}>Estado*</p>
                <Select
                  style={styles.inlineSelect}
                  name="Estado"
                  placeholder="Estado"
                  value={status_id}
                  options={formatStatusFiltersToOptions()}
                  clearable={false}
                  menuStyle={{ maxHeight: 120 }}
                  searchable={false}
                  onChange={(option) => setStatus(getOptionValue(option))}
                />
              </Col>
              <Col span={24}>
                <Row>
                  <p style={styles.inputTitle}>Referencia</p>
                  <Input
                    placeholder="Referencia"
                    value={payment_reference}
                    onChange={(e) => setReference(e.target.value)}
                    style={styles.inputFullWidth}
                  />
                </Row>
              </Col>
            </Row>
          </InputGroup>
        </Col>
      </Row>
      <div className="container">
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

export default UpdateActivityModal
