import React from 'react'
import {
  TripWithTransactions,
  TripsDateRangeFilter,
  TripsSearchFilter,
  TripsTableCategoryFilter,
  TripsTableDateRangeKey,
  TripsTableSearchKey,
  TripsTableSort,
  TripsTableSortKey
} from '../types/tripsTable'
import { Column, Pagination, SortDirection, SortProps } from '../../../components/DynamicTable/types/types'
import { DynamicTable } from '../../../components/DynamicTable'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Moment } from 'moment'
import { Transaction } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import styles from './tripsTable.module.scss'
import { getFilterProps, getRangePickerProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { TransactionStatusBadges, TripStatusBadges } from '../constants'
import ColorBadge from '../../../components/ColorBadge'
import { Trip, TripStatusId } from '../../../projectApi/Trips/Trip/common'
import { Tooltip } from 'antd'
import { Check, Cross, Edit, User, UserSlashed } from '../../../icons'
import ConfirmationModal from '../../../components/ConfirmationModal/confirmationModal'
import { TransactionStatusId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { formatDate } from '../../../utils/formatDate'
import clsx from 'clsx'
import { TRANSACTION_TYPES } from '../../Transactions/components/TransactionsTable'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import { useCountries } from '../../../components/CountrySelector/hooks/hooks'
import { Button } from '../../../components/Button/Button'
import { TableActionBar } from '../../../components/TableActionBar'

export const getSortProps = (
  key: TripsTableSortKey,
  currentSorter: TripsTableSort,
  sorterFunction: (newSorter: TripsTableSort) => void
): SortProps => {
  return {
    direction: currentSorter.direction,
    isActive: currentSorter.field === key,
    onClick: (newSortDirection) =>
      sorterFunction({
        direction: currentSorter.field === key ? newSortDirection : SortDirection.ASC,
        field: key
      })
  }
}

export type TripsTableProps = {
  trips: TripWithTransactions[]
  selectedTrips: TripWithTransactions[]
  loadingTrips: boolean
  loadingTransactionsForTripIds: number[]
  pagination: Pagination
  searchFilters: TripsSearchFilter[]
  categoryFilter: TripsTableCategoryFilter
  dateRangeFilters: TripsDateRangeFilter[]
  sort: TripsTableSort
  loadingTripIds: number[]
  startedTripIds: number[]
  canceledTripIds: number[]
  finishedTripIds: number[]
  cancelingModalId: number | null
  canceling: boolean
  selectedTransactions: Transaction[]
  noCountrySelected: boolean
  sendingTXByTripsReport: boolean
  handleOpenAssignmentModal: () => void
  handleEditTransactionAssignment: (transaction: Transaction) => void
  handleToggleSelectedTransaction: (transaction: Transaction) => void
  handleRemoveTransactionFromTrip: ({ tripId, transactionId }: { tripId: number; transactionId: number }) => void
  handleToggleTripTransactions: (trip: TripWithTransactions) => void
  handleStartTrip: (tripId: number) => void
  handleFinishTrip: (tripId: number) => void
  handleCancelTrip: (tripId: number) => void
  handleSort: (newSorter: TripsTableSort) => void
  handleResetFilters: () => void
  handleSearch: (key: TripsTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newFilter: TripsTableCategoryFilter) => void
  handleRangePicker: (key: TripsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleExpansion: (tripId: number) => void
  handleOpenEditModal: (trip: Trip) => void
  setCancelingModalId: (tripId: number | null) => void
  sendingCSV: boolean
  handleSendCSV: () => void
  handleToggleSelectedTrip: (trip: TripWithTransactions) => void
  handleToggleAllTrips: () => void
  handleSendTXByTripsReport: () => void
}

const TripsTable: React.FC<TripsTableProps> = ({
  trips,
  selectedTrips,
  loadingTrips,
  loadingTransactionsForTripIds,
  pagination,
  categoryFilter,
  searchFilters,
  dateRangeFilters,
  sort,
  loadingTripIds,
  startedTripIds,
  canceledTripIds,
  finishedTripIds,
  cancelingModalId,
  canceling,
  selectedTransactions,
  noCountrySelected,
  sendingTXByTripsReport,
  handleOpenAssignmentModal,
  handleEditTransactionAssignment,
  handleRemoveTransactionFromTrip,
  handleToggleSelectedTransaction,
  handleToggleTripTransactions,
  setCancelingModalId,
  handleOpenEditModal,
  handleRangePicker,
  handleCategoryFilter,
  handleSort,
  handleResetFilters,
  handleSearch,
  handleExpansion,
  handleStartTrip,
  handleFinishTrip,
  handleCancelTrip,
  sendingCSV,
  handleSendCSV,
  handleToggleSelectedTrip,
  handleToggleAllTrips,
  handleSendTXByTripsReport
}) => {
  const countries = useCountries()
  const columns: Column<TripWithTransactions>[] = [
    {
      key: 'country',
      label: 'País',
      renderDataCell: ({ country }) => {
        const flag = getCurrentCountry(countries, country)
        if (!flag) return '-'
        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('id', sort, handleSort)
    },
    {
      key: 'createdBy',
      label: 'Creado por'
    },
    {
      key: 'createdAt',
      label: 'F. de creación',
      alignment: 'left',
      renderDataCell: ({ createdAt }) => formatDate(createdAt),
      rangePicker: getRangePickerProps('createdAt', dateRangeFilters, handleRangePicker),
      sort: getSortProps('created_at', sort, handleSort)
    },
    {
      key: 'scheduledStartDate',
      label: 'Inicio prog.',
      alignment: 'left',
      renderDataCell: ({ scheduledStartDate }) => formatDate(scheduledStartDate),
      rangePicker: getRangePickerProps('scheduledStartDate', dateRangeFilters, handleRangePicker),
      sort: getSortProps('scheduled_start', sort, handleSort)
    },
    {
      key: 'scheduledEndDate',
      label: 'Fin prog.',
      alignment: 'left',
      renderDataCell: ({ scheduledEndDate }) => formatDate(scheduledEndDate),
      rangePicker: getRangePickerProps('scheduledEndDate', dateRangeFilters, handleRangePicker),
      sort: getSortProps('scheduled_end', sort, handleSort)
    },
    {
      key: 'realStartDate',
      label: 'Inicio real',
      alignment: 'left',
      renderDataCell: ({ realStartDate }) => formatDate(realStartDate),
      rangePicker: getRangePickerProps('realStartDate', dateRangeFilters, handleRangePicker),
      sort: getSortProps('start_date', sort, handleSort)
    },
    {
      key: 'realEndDate',
      label: 'Fin real',
      alignment: 'left',
      renderDataCell: ({ realEndDate }) => formatDate(realEndDate),
      rangePicker: getRangePickerProps('realEndDate', dateRangeFilters, handleRangePicker),
      sort: getSortProps('end_date', sort, handleSort)
    },
    {
      key: 'transactionSummary',
      label: 'TXs',
      renderDataCell: ({ transactionSummary }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          I={transactionSummary.deposits}
          <br />
          D={transactionSummary.removals}
        </span>
      )
    },
    {
      key: 'forecast',
      label: 'Previsión',
      renderDataCell: ({ forecast }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {forecast.totalM3} m³/
          <br />
          {forecast.objects} Obj.
        </span>
      )
    },
    {
      key: 'driver',
      label: 'Transportista',
      minWidth: 'max-content',
      renderDataCell: ({ driver }) =>
        driver.lastName || driver.name ? `${driver.userId} - ${driver.name} ${driver.lastName}` : '-'
    },
    {
      key: 'status',
      label: 'Estado',
      maxWidth: 'min-content',
      // @ts-ignore
      filters: getFilterProps(TripStatusBadges, categoryFilter, 'tripStatus', handleCategoryFilter, true),
      renderDataCell: ({ status, id }) => {
        const tripWasStarted = !!startedTripIds.find((tid) => tid === id)
        const tripWasFinished = !!finishedTripIds.find((tid) => tid === id)
        const tripWasCanceled = !!canceledTripIds.find((tid) => tid === id)
        const found = TripStatusBadges.find((tsb) =>
          tripWasFinished
            ? tsb.value === TripStatusId.FINALIZADO
            : tripWasCanceled
            ? tsb.value === TripStatusId.CANCELADO
            : tripWasStarted
            ? tsb.value === TripStatusId.EN_CURSO
            : tsb.value === status.id
        )
        if (!found) return '-'

        const props = {
          ...found.styleProps
        }
        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    },
    {
      key: 'button',
      label: '',
      maxWidth: 'min-content',
      renderDataCell: ({ id, status, transactionSummary }) => {
        const tripWasStarted = !!startedTripIds.find((tid) => tid === id) || status.id === TripStatusId.EN_CURSO
        const tripWasCanceled = !!canceledTripIds.find((tid) => tid === id) || status.id === TripStatusId.CANCELADO
        const tripWasFinished = !!finishedTripIds.find((tid) => tid === id) || status.id === TripStatusId.FINALIZADO
        const tripHasNoTXs = Object.values(transactionSummary).every(
          (transactionTypeCounter) => transactionTypeCounter === 0
        )

        return (
          <Button
            disabled={tripWasCanceled || tripWasFinished || tripHasNoTXs}
            onClick={() => (tripWasStarted ? handleFinishTrip(id) : handleStartTrip(id))}
            compact
            label={
              tripWasCanceled ? (
                'Cancelado'
              ) : tripWasStarted ? (
                'Finalizar'
              ) : tripWasFinished ? (
                <Check height="12px" width="12px" fill="currentColor" />
              ) : (
                'Iniciar'
              )
            }
            loading={!!loadingTripIds.find((tid) => tid === id)}
          />
        )
      }
    }
  ]

  const tripIsNotEditable = (trip?: Trip) =>
    (trip?.status.id !== TripStatusId.PROGRAMADO && trip?.status.id !== TripStatusId.ASIGNADO) ||
    !!startedTripIds.find((tid) => tid === trip.id) ||
    !!canceledTripIds.find((tid) => tid === trip.id)

  const tripActions: MenuAction<Trip>[] = [
    {
      label: () => 'Editar viaje',
      icon: <Edit />,
      onClick: (trip) => handleOpenEditModal(trip),
      disabled: (trip) => tripIsNotEditable(trip)
    },
    {
      label: 'Cancelar viaje',
      icon: <Cross height="12px" width="12px" fill="currentColor" />,
      onClick: ({ id }) => setCancelingModalId(id),
      disabled: (trip) => tripIsNotEditable(trip)
    }
  ]

  const handleCloseCancelingModal = () => setCancelingModalId(null)

  const noCountrySelectedTip = 'Debes seleccionar un único país en el filtro superior para poder asignar TXs'

  const assignTransactionsButtonProps = {
    iconType: 'user',
    titleButton: `Asignar transacciones${selectedTransactions.length ? ` (${selectedTransactions.length})` : ''}`,
    disabled: !selectedTransactions.length || noCountrySelected
  }

  const onlyOnePage = pagination.total > pagination.pageSize

  const notSelectedTrips = selectedTrips.length <= 0
  const sendTransactionsReportTooltip = notSelectedTrips ? 'Debes seleccionar al menos un viaje' : ''

  return (
    <div>
      <TableActionBar>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction toolTip variant="csv" disabled={sendingCSV} onClickButton={handleSendCSV} />
          <TableActionBar.ButtonAction
            variant="csv"
            toolTip
            toolTipCustomMessage={sendTransactionsReportTooltip}
            disabled={notSelectedTrips || sendingTXByTripsReport}
            onClickButton={handleSendTXByTripsReport}
            titleButton="Exportar CSV de Transacciones"
          />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        </TableActionBar.Wrapper>
        {noCountrySelected ? (
          <TableActionBar.ButtonAction
            toolTip
            {...assignTransactionsButtonProps}
            toolTipCustomMessage={noCountrySelectedTip}
          />
        ) : (
          <TableActionBar.ButtonAction onClickButton={handleOpenAssignmentModal} {...assignTransactionsButtonProps} />
        )}
      </TableActionBar>

      <DynamicTable
        columns={columns}
        loading={loadingTrips}
        actions={tripActions}
        rows={trips}
        keyExtractor={(entity) => entity.id}
        rowExpander={{
          render: (trip) => (
            <TransactionExpanded
              tripRealStartDate={trip.realStartDate}
              selectedTransactions={selectedTransactions}
              transactions={trip.transactions}
              noCountrySelected={noCountrySelected}
              canAddMoreTransactions={!tripIsNotEditable(trip)}
              handleOpenEditModal={() => handleOpenEditModal(trip)}
              editTransactionAssignment={handleEditTransactionAssignment}
              toggleSelectedTransaction={handleToggleSelectedTransaction}
              removeTransactionFromTrip={handleRemoveTransactionFromTrip}
              toggleAllTransactions={() => handleToggleTripTransactions(trip)}
              loading={!!loadingTransactionsForTripIds.find((tripID) => tripID === trip.id)}
            />
          ),
          onExpansionClick: ({ id }) => handleExpansion(id),
          triggerFunctionOnce: false
        }}
        checkboxes={{
          headerIsChecked: () => trips.every((tx) => selectedTrips.find((stx) => stx.id === tx.id)),
          onHeaderCheckboxClick: handleToggleAllTrips,
          headerIsDisabled: () => onlyOnePage,
          onRowCheckboxClick: handleToggleSelectedTrip,
          rowIsChecked: (trip) => selectedTrips.some((st) => st.id === trip.id),
          rowIsDisabled: () => false
        }}
        pagination={pagination}
        customHeightOffset={370}
      />
      <ConfirmationModal
        open={!!cancelingModalId}
        bodyText="¿Confirmás que querés cancelar este viaje?"
        closingDisabled={canceling}
        onClose={handleCloseCancelingModal}
        onConfirm={() => cancelingModalId && handleCancelTrip(cancelingModalId)}
      />
    </div>
  )
}

const TransactionExpanded: React.FC<{
  transactions: Transaction[]
  selectedTransactions: Transaction[]
  loading: boolean
  tripRealStartDate: Moment
  noCountrySelected: boolean
  canAddMoreTransactions: boolean
  handleOpenEditModal: () => void
  editTransactionAssignment: (transaction: Transaction) => void
  toggleSelectedTransaction: (transaction: Transaction) => void
  removeTransactionFromTrip: ({ tripId, transactionId }: { tripId: number; transactionId: number }) => void
  toggleAllTransactions: () => void
}> = ({
  transactions,
  selectedTransactions,
  loading,
  tripRealStartDate,
  canAddMoreTransactions,
  noCountrySelected,
  handleOpenEditModal,
  editTransactionAssignment,
  removeTransactionFromTrip,
  toggleAllTransactions,
  toggleSelectedTransaction
}) => {
  const columns: Column<Transaction>[] = [
    {
      key: 'id',
      label: 'ID TX',
      maxWidth: 'max-content'
    },
    {
      key: 'client.name',
      label: 'Nombre',
      maxWidth: 'max-content',
      renderDataCell: ({ client: { name, lastName, id } }) => `${id} - ${name} ${lastName}`
    },
    {
      key: 'type',
      label: 'Tipo',
      maxWidth: 'max-content',
      renderDataCell: ({ typeId }) => {
        const type = TRANSACTION_TYPES.find((t) => t.value === typeId)
        if (!type) return '-'

        return type.text
      }
    },
    {
      key: 'createdAt',
      label: 'F. de creación',
      maxWidth: '120px',
      alignment: 'left',
      renderDataCell: ({ createdAt }) => formatDate(createdAt)
    },
    {
      key: 'reservationDate',
      label: 'Fecha prog.',
      maxWidth: '120px',
      alignment: 'left',
      renderDataCell: ({ reservationDate }) => formatDate(reservationDate)
    },
    {
      key: 'confirmationDate',
      label: 'Inicio real',
      maxWidth: '120px',
      alignment: 'left',
      renderDataCell: () => formatDate(tripRealStartDate)
    },
    {
      key: 'forecast',
      label: 'Previsión',
      maxWidth: 'max-content',
      renderDataCell: ({ contents: { forecast } }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {forecast.m3.toFixed(2)} m³/
          <br />
          {forecast.items} Obj.
        </span>
      )
    },
    {
      key: 'actual',
      label: 'Real',
      maxWidth: 'max-content',
      renderDataCell: ({ contents: { real } }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {real.m3.toFixed(2)} m³/
          <br />
          {real.objects} Obj.
        </span>
      )
    },
    {
      key: 'destinationAddress',
      label: 'Destino',
      minWidth: '220px',
      maxWidth: 'min-content'
    },
    {
      key: 'transactionStatusId',
      label: 'Estado',
      // maxWidth: 'min-content',
      renderDataCell: ({ transactionStatusId, operators }) => {
        const found = TransactionStatusBadges.find((tsb) => tsb.value === transactionStatusId)
        if (!found) return '-'
        const props = {
          ...found.styleProps
        }

        const isAssignedToOperators = !!operators.length

        const OperatorIcon = (
          <Tooltip
            title={
              isAssignedToOperators ? (
                <div className={styles.operatorTooltip}>
                  Operarios asignados:
                  <ul>
                    {operators.map((p) => (
                      <li key={p.id}>
                        {p.id} - {p.name} {p.lastName}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                'Esta TX no tiene operarios asignados'
              )
            }>
            <div className={clsx(styles.operatorIcon, isAssignedToOperators && styles.isAssignedToOperators)}>
              {isAssignedToOperators ? <User /> : <UserSlashed />}
            </div>
          </Tooltip>
        )
        return (
          <div className={styles.transactionStatus}>
            {OperatorIcon}
            <ColorBadge {...props}>{found.text}</ColorBadge>
          </div>
        )
      }
    }
  ]

  const isntInAllowedStatus = (tx: Transaction) =>
    tx.transactionStatusId !== TransactionStatusId.ASIGNADA_A_VIAJE &&
    tx.transactionStatusId !== TransactionStatusId.EN_CURSO &&
    tx.transactionStatusId !== TransactionStatusId.EN_DESTINO &&
    tx.transactionStatusId !== TransactionStatusId.LISTO_PARA_VIAJAR

  const actions: MenuAction<Transaction>[] = [
    {
      label: 'Asignar operarios',
      onClick: (tx) => editTransactionAssignment(tx),
      disabled: (tx) => !tx || isntInAllowedStatus(tx) || noCountrySelected
    },
    {
      label: 'Quitar del viaje',
      onClick: (tx) => removeTransactionFromTrip({ tripId: tx.tripId, transactionId: tx.id })
    }
  ]

  return (
    <div style={{ padding: '12px 0', maxHeight: 500 }}>
      <DynamicTable
        columns={columns}
        loading={loading}
        rows={transactions}
        actions={actions}
        keyExtractor={(entity) => entity.id}
        checkboxes={{
          headerIsChecked: (txs) => txs.every((tx) => selectedTransactions.some((st) => st.id === tx.id)),
          onHeaderCheckboxClick: toggleAllTransactions,
          headerIsDisabled: (rows) => !!rows.find((r) => isntInAllowedStatus(r)),
          onRowCheckboxClick: (row) => toggleSelectedTransaction(row),
          rowIsChecked: (row) => selectedTransactions.some((st) => st.id === row.id),
          rowIsDisabled: (row) => isntInAllowedStatus(row)
        }}
        customClassNames={{
          table: styles.transactionsTable,
          loadingContainer: styles.loadingContainer
        }}
        customStrings={{
          emptyState: (
            <div className={styles.txEmptyState}>
              <span>Este viaje no tiene transacciones.</span>
              {canAddMoreTransactions && (
                <Button label="Agregar transacciones" leftIcon="add" compact onClick={handleOpenEditModal} />
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default TripsTable
