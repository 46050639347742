import { combineReducers } from 'redux'
import cbusReducer from './cbus'
import cbusModalReducer from './cbusModal'
import defaultCbusReducer from './defaultCBUs'

const rootReducer = combineReducers({
  cbus: cbusReducer,
  cbusModal: cbusModalReducer,
  defaultCbus: defaultCbusReducer
})

export default rootReducer
