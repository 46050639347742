import React, { useEffect } from 'react'
import DiscountDatesPage from '../components/DiscountDates'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import discountDatesActionsCreator from '../actions/discountDates'
import {
  DiscountDatesDateRangeFilter,
  DiscountDatesSearchFilter,
  DiscountDatesTableDateRangeKey,
  DiscountDatesTableSearchKey,
  DiscountDatesTableSort
} from '../types/discountDates'
import { Pagination } from '../../../utils/searchFilterUtils'
import { Moment } from 'moment'
import { TableActionBar } from '../../../components/TableActionBar'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import ABMDiscountDatesContainer from './ABMDiscountDates'
import createDiscountDatesActionsCreator from '../actions/ABMDiscountDates'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Discount } from '../../../projectApi/Timeslot/Discounts/SpecificDates/list'
import { Edit, Trash } from '../../../icons'

const DiscountDatesContainer: React.FC = () => {
  const dispatch = useDispatch()

  const { discounts, fetchingDiscounts, ...tableState } = useSelector((state) => state.Discount.discountDatesReducer)

  const { getDiscountDates, resetFilters } = bindActionCreators(discountDatesActionsCreator, dispatch)
  const { setModalVisibility, setSelectedDiscount, setDeleteModalVisibility } = bindActionCreators(
    createDiscountDatesActionsCreator,
    dispatch
  )

  const handleGetDiscountDates = async (newParams: {
    pagination?: Pagination
    sort?: DiscountDatesTableSort
    dateRangeFilters?: DiscountDatesDateRangeFilter[]
    searchFilters?: DiscountDatesSearchFilter[]
  }) => {
    const tripsSuccess = await getDiscountDates({
      pagination: newParams.pagination || tableState.pagination,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      sort: newParams.sort || tableState.sort,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    })

    return tripsSuccess
  }

  const emptyPagination = { ...tableState.pagination, page: 1 }

  useEffect(() => {
    handleGetDiscountDates({})
  }, [])

  useEvents([Events.Discounts.CLEAN_FILTERS, Events.Discounts.CREATE_DISCOUNT, Events.Discounts.UPDATE_DISCOUNT], () =>
    handleGetDiscountDates({})
  )

  const handlePageChange = (newPage: number) => {
    handleGetDiscountDates({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleRangePicker = (key: DiscountDatesTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetDiscountDates({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
  }

  const handleSearch = (key: DiscountDatesTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetDiscountDates({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleSort = (newSort: DiscountDatesTableSort) => {
    handleGetDiscountDates({ sort: newSort })
  }

  const handleResetFilters = () => resetFilters()

  const menuActions: MenuAction<Discount>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (discount) => {
        setModalVisibility({ modalVisible: true, editMode: true })
        setSelectedDiscount(discount)
      }
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (discount) => {
        setDeleteModalVisibility(true)
        setSelectedDiscount(discount)
      }
    }
  ]

  const tableProps = {
    discounts,
    fetchingDiscounts,
    handleRangePicker,
    dateRangeFilters: tableState.dateRangeFilters,
    handleSearch,
    searchFilters: tableState.searchFilters,
    sort: tableState.sort,
    handleSort,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    actions: menuActions
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
        <TableActionBar.ButtonAction
          titleButton="Crear descuento"
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar>
      <DiscountDatesPage {...tableProps} />
      <ABMDiscountDatesContainer />
    </>
  )
}

export default DiscountDatesContainer
