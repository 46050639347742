/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { APIUser, User } from './common'
import { convertToMoment } from '../../utils'

interface APIResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  results: APIUser[]
}

type Response = {
  users: User[]
  total: number
}

interface Params {
  email: string
}

export function search({ email }: Params): Promise<Response> {
  const params = {
    email
  }

  return apiPrivate
    .get(`${URL_BASE_USERS}/users/search`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.results) throw new Error('Respuesta incorrecta al obtener los usuarios')

      return {
        total: response.data.paging.total,
        users: camelcaseKeys(response.data.results, { deep: true }).map((u) => ({
          ...u,
          realId: u.realId,
          dateOfBirth: convertToMoment(u.dateOfBirth)
        }))
      }
    })
    .catch((err) => {
      console.error(err)
      throw err
    })
}
