import React from 'react'
import styles from './selectedBilling.module.scss'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { useHistory } from 'react-router'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import CrossIcon from '../../../icons/cross'

type SelectedBillingProps = {
  searchUserActions: typeof searchUserActionCreators
  selectedBillings: Billing[]
  loading: boolean
}

const SelectedBilling = ({ selectedBillings, searchUserActions }: SelectedBillingProps) => {
  const history = useHistory()
  const { clearSelectedBillings } = searchUserActions
  return (
    <div className={styles.container}>
      <div>
        <button onClick={clearSelectedBillings} className={styles.buttonCross}>
          <CrossIcon height="10" width="10" fill="#1D2433" className={styles.iconCross} />
        </button>
        <span>{selectedBillings.length} seleccionados</span>
      </div>
      <div>
        <button
          onClick={() => {
            history.push('/billing_payment_process')
          }}
          className={styles.payButton}>
          Realizar cobro total
        </button>
      </div>
    </div>
  )
}

export default SelectedBilling
