import { combineReducers } from 'redux'
import ScanModalObjectsReducer from './ScanObjectModal'
import MissingObjectModalReducer from './MissingObjectModal'
import RegisterObjectsTableReducer from './RegisterObjectsTable'

export default combineReducers({
  missingObjectModal: MissingObjectModalReducer,
  scanModal: ScanModalObjectsReducer,
  table: RegisterObjectsTableReducer
})
