export type Currency = {
  symbol: string // '$' | '€' | '£' | etc.
  decimal: string // ',' | '.'
  thousand: string // ',' | '.'
  showCents: boolean
}

export const formatPrice = ({
  value,
  currency = { symbol: '$', decimal: ',', thousand: '.', showCents: false },
  isInCents
}: {
  value: number
  currency?: Currency
  isInCents: boolean
}): string => {
  if (isInCents) value = value / 100
  const { symbol, decimal, thousand, showCents } = currency
  const [integer, decimalPart] = value.toFixed(2).split('.')
  const integerPart = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousand)
  return `${symbol} ${integerPart}${showCents ? decimal + decimalPart : ''}`
}
