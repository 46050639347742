export default {
  GET_OBJECT_CHANGES_FROM_ID_REQUEST: 'OBJECTS/OBJECT_CHANGES/GET_OBJECT_CHANGES_FROM_ID_REQUEST',
  GET_OBJECT_CHANGES_FROM_ID_SUCCESS: 'OBJECTS/OBJECT_CHANGES/GET_OBJECT_CHANGES_FROM_ID_SUCCESS',
  GET_OBJECT_CHANGES_FROM_ID_FAILURE: 'OBJECTS/OBJECT_CHANGES/GET_OBJECT_CHANGES_FROM_ID_FAILURE',
  SET_SORTER: 'OBJECTS/OBJECT_CHANGES/SET_SORTER',
  SET_FILTER: 'OBJECTS/OBJECT_CHANGES/SET_FILTER',
  SET_PAGINATION: 'OBJECTS/OBJECT_CHANGES/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'OBJECTS/OBJECT_CHANGES/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'OBJECTS/OBJECT_CHANGES/CLEAN_FILTERS'
}
