import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

const styles = {
  modal: {
    maxWidth: 298
  },
  photo: {
    width: 250,
    height: 250,
    margin: 'auto',
    objectFit: 'contain'
  },
  error: {
    color: 'red',
    textAlign: 'right'
  }
}

const MarkPhotoAsMainPanel = (props) => {
  return (
    <Modal
      title="Marcar foto como principal"
      visible={props.open}
      onOk={() => props.markPhotoAsMain(props.objectId, props.photoId)}
      onCancel={props.cancel}
      okText="Marcar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: props.isLoading }}
      confirmLoading={props.isLoading}
      closable={!props.isLoading}
      maskClosable={!props.isLoading}
      style={styles.modal}>
      <img src={props.url} alt="-" style={styles.photo} />
      {props.error !== '' ? <div style={styles.error}>{props.error}</div> : null}
    </Modal>
  )
}

MarkPhotoAsMainPanel.propTypes = {
  objectId: PropTypes.string.isRequired,
  photoId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  markPhotoAsMain: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired
}

export default MarkPhotoAsMainPanel
