export default {
  FETCH_FLAGS_REQUEST: 'FLAGS/FETCH_FLAGS_REQUEST',
  FETCH_FLAGS_SUCCESS: 'FLAGS/FETCH_FLAGS_SUCCESS',
  FETCH_FLAG_FAIL: 'FLAGS/FETCH_FLAGS_FAIL',
  SET_SORTER: 'FLAGS/SET_SORTER',
  SET_PAGINATION: 'FLAGS/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'FLAGS/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'FLAGS/CLEAN_FILTERS',
  SET_EDIT_MODAL_OPEN: 'FLAGS/SET_EDIT_MODAL_OPEN',
  SET_EDIT_MODAL_CLOSE: 'FLAGS/SET_EDIT_MODAL_CLOSE',
  EDIT_FLAG_REQUEST: 'EDIT_FLAG_REQUEST',
  EDIT_FLAG_SUCCESS: 'EDIT_FLAG_SUCCESS',
  EDIT_FLAG_FAIL: 'EDIT_FLAG_FAIL',
  CHANGE_FLAG_VALUE: 'CHANGE_FLAG_VALUE'
}
