import types from '../types/photos'
import deletePhotoTypes from '../types/deletePhoto'
const initialState = {
  open: false,
  object: {
    Photos: []
  },
  pages: 1,
  loading: false
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_MODAL: {
      return { ...state, object: action.payload.object, open: true }
    }
    case types.CLOSE_MODAL: {
      return { ...state, open: false }
    }
    case types.ADD_PHOTO_REQUEST:
    case types.SET_DEFAULT_PHOTO_REQUEST: {
      return { ...state, loading: true, error: '' }
    }
    case types.ADD_PHOTO_SUCCESS:
      return { ...state, loading: false, object: action.payload.object }
    case types.SET_DEFAULT_PHOTO_SUCCESS: {
      return { ...state, loading: false }
    }
    case types.ADD_PHOTO_FAILURE:
    case types.SET_DEFAULT_PHOTO_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case deletePhotoTypes.DELETE_PHOTO_SUCCESS: {
      const { photoID } = action.payload
      return {
        ...state,
        object: {
          ...state.object,
          Photos: state.object.Photos.filter((photo) => photo.ID !== photoID)
        }
      }
    }
    default:
      return state
  }
}

export default root
