import { combineReducers } from 'redux'
import table from './reducers/table'
import validationView from './reducers/validationView'
import depositsValidationMetricsReducer from './reducers/depositsValidationMetrics'

export default combineReducers({
  table,
  validationView,
  metrics: depositsValidationMetricsReducer
})
