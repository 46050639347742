import React from 'react'
import { Modal } from 'antd'
import styles from './SaleModal.module.scss'
import { SalesRequestViewState } from '../types/salesRequestView'

type SaleModalProps = {
  closeModal: () => void
  sellRequestHandler: () => void
  salesRequestViewState: SalesRequestViewState
}

const SaleModal = (props: SaleModalProps) => {
  const { closeModal, salesRequestViewState, sellRequestHandler } = props
  const { error, isLoadingModal, isSellModalOpen } = salesRequestViewState

  const onOkClick = () => {
    sellRequestHandler()
  }

  return (
    <Modal
      width={697}
      title="Solicitud de venta"
      visible={isSellModalOpen}
      closable={true}
      keyboard={false}
      onOk={onOkClick}
      onCancel={closeModal}
      okButtonProps={{
        className: styles.modalButtonOk,
        disabled: isLoadingModal
      }}
      okText={'Confirmar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel,
        disabled: isLoadingModal
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <p className={styles.description}>¿Está seguro que desea vender el objeto?</p>
      {error && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      )}
    </Modal>
  )
}

export default SaleModal
