import { Discount, DiscountDatesSortKey } from '../../../projectApi/Timeslot/Discounts/SpecificDays/list'
import { DiscountWeekdaysFilterKey } from '../../../projectApi/Timeslot/Discounts/SpecificDays/common'

import { CategoryFilter, DateRangeFilter, Pagination, Sort } from '../../../utils/searchFilterUtils'

export type DiscountDaysState = {
  categoryFilters: DiscountDaysTableCategoryFilter
  discounts: Discount[]
  fetchingDiscounts: boolean
  pagination: Pagination
  sort: DiscountDaysTableSort
  dateRangeFilters: DiscountDaysDateRangeFilter[]
}

export enum DiscountDaysTableCategoryFilterKey {
  WEEKDAY = 'weekday'
}
export type DiscountDaysTableCategoryFilter = CategoryFilter<
  DiscountDaysTableCategoryFilterKey,
  DiscountWeekdaysFilterKey
>

export type DiscountDaysTableSort = Sort<DiscountDatesSortKey>

export type DiscountDaysTableDateRangeKey = 'createdAt'

export type DiscountDaysDateRangeFilter = DateRangeFilter<DiscountDaysTableDateRangeKey>

export enum GetDiscountDaysTypes {
  GET_DISCOUNT_DAYS_REQUEST = 'DISCOUNTS/DISCOUNT_DATES/GET_DISCOUNT_DAYS_REQUEST',
  GET_DISCOUNT_DAYS_SUCCESS = 'DISCOUNTS/DISCOUNT_DATES/GET_DISCOUNT_DAYS_SUCCESS',
  GET_DISCOUNT_DAYS_FAILURE = 'DISCOUNTS/DISCOUNT_DATES/GET_DISCOUNT_DAYS_FAILURE'
}

export interface GetDiscountDaysRequest {
  type: typeof GetDiscountDaysTypes.GET_DISCOUNT_DAYS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    sort?: DiscountDaysTableSort
    dateRangeFilters?: DiscountDaysDateRangeFilter[]
    categoryFilters?: DiscountDaysTableCategoryFilter
  }
}

export interface GetDiscountDaysSuccess {
  type: typeof GetDiscountDaysTypes.GET_DISCOUNT_DAYS_SUCCESS
  payload: {
    discounts: Discount[]
    total: number
  }
}

export interface GetDiscountDaysFailure {
  type: typeof GetDiscountDaysTypes.GET_DISCOUNT_DAYS_FAILURE
}

type GetDiscountDaysActions = GetDiscountDaysRequest | GetDiscountDaysSuccess | GetDiscountDaysFailure

export enum DiscountDaysTableTypes {
  RESET_FILTERS = 'DISCOUNTS/DISCOUNT_DATES/RESET_FILTERS'
}

export interface ResetFilters {
  type: typeof DiscountDaysTableTypes.RESET_FILTERS
}

export type DiscountDaysActions = GetDiscountDaysActions | ResetFilters
