import { getByUserId } from './getByUserId'
import { save } from './save'
import { update } from './update'
import { exclude } from './exclude'

export const UserBillingInformationAPI = {
  getByUserId,
  save,
  update,
  exclude
}
