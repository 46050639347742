import React, { ChangeEvent } from 'react'
import { Input, Modal } from 'antd'
import InputGroup from '../../../components/InputGroup'
import styles from './AMVehicles.module.scss'
import { VehicleCreateUpdateParams } from '../../../projectApi/Trips/Vehicles/common'
import Select from '../../../components/Select'
import { BaseOption, InputChangeReason } from '../../../components/Select/Select'

type AMModalVehiclesTypes = {
  error: string
  setError: (error: string) => void
  loadingProviders: boolean
  autoComplete: string
  onDeleteSelection: () => void
  onSelectTextChange: (text: string, reason: InputChangeReason) => void
  loading: boolean
  providerOptions: BaseOption<number>[]
  selectedProvider?: BaseOption<number>
  modalVisible: boolean
  editMode: boolean
  disabledSuccessButton: boolean
  selectedVehicle: VehicleCreateUpdateParams
  setModalVisibility: ({ modalVisible, editMode }: { modalVisible: boolean; editMode?: boolean }) => void
  setVehicleField: (field: keyof VehicleCreateUpdateParams, value: VehicleCreateUpdateParams[typeof field]) => void
  handleCreateVehicle: () => void
}

type NumberFieldsMap = {
  key: keyof Omit<VehicleCreateUpdateParams, 'name' | 'provider'>
  label: string
  value: number
}

const AMModalVehicles = ({
  error,
  setError,
  loadingProviders,
  autoComplete,
  onDeleteSelection,
  onSelectTextChange,
  loading,
  modalVisible,
  editMode,
  disabledSuccessButton,
  selectedVehicle,
  setModalVisibility,
  setVehicleField,
  handleCreateVehicle,
  providerOptions,
  selectedProvider
}: AMModalVehiclesTypes) => {
  const modalTitle = editMode ? 'Editar vehículo' : 'Creación de vehículos'
  const textConfirmButton = editMode ? 'Editar' : 'Crear'

  const numberFieldsMap: NumberFieldsMap[] = [
    {
      key: 'maxCapacity',
      label: 'Capacidad Máx* (m³)',
      value: selectedVehicle.maxCapacity
    },
    {
      key: 'usableCapacity',
      label: 'Capacidad utilizable* (m³)',
      value: selectedVehicle.usableCapacity
    },
    {
      key: 'maxWeight',
      label: 'Peso Máx.* (kg)',
      value: selectedVehicle.maxCapacity
    },
    {
      key: 'maxHeight',
      label: 'Alto Máx.* (m)',
      value: selectedVehicle.maxHeight
    },
    {
      key: 'maxLength',
      label: 'Largo Máx.* (m)',
      value: selectedVehicle.maxLength
    },
    {
      key: 'maxWidth',
      label: 'Ancho Máx.* (m)',
      value: selectedVehicle.maxWidth
    }
  ]

  const handleNumberInput = (numberField: NumberFieldsMap) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setVehicleField(numberField.key, parseFloat(value))
    setError('')
  }

  return (
    <Modal
      width={540}
      title={modalTitle}
      closable={!loading}
      okText={textConfirmButton}
      visible={modalVisible}
      onCancel={() => setModalVisibility({ modalVisible: false })}
      cancelText={'Cancelar'}
      onOk={handleCreateVehicle}
      okButtonProps={{ disabled: disabledSuccessButton, loading }}
      destroyOnClose>
      <div>
        <InputGroup title="Datos obligatorios">
          <div className={styles.modalContainer}>
            <label className={styles.label}>
              Nombre del vehículo*
              <Input value={selectedVehicle.name} onChange={({ target }) => setVehicleField('name', target.value)} />
            </label>
            <label className={styles.label}>
              Proveedor*
              <Select
                loading={loadingProviders}
                onDelete={onDeleteSelection}
                placeholder="Selecciona un proveedor..."
                autocomplete={{
                  filterByInput: true,
                  input: autoComplete,
                  onInputChange: onSelectTextChange
                }}
                options={providerOptions}
                selected={selectedProvider}
                onSelect={(option) => setVehicleField('providerId', option.value)}
              />
            </label>
            <div className={styles.numberFieldsContainer}>
              {numberFieldsMap.map((numberField) => (
                <label key={numberField.key} className={styles.labelNumbers}>
                  {numberField.label}
                  <Input
                    type="number"
                    defaultValue={selectedVehicle[numberField.key]}
                    onChange={handleNumberInput(numberField)}
                  />
                </label>
              ))}
            </div>
          </div>
        </InputGroup>
        {error && <p className={styles.errorFieldLabel}>{error}</p>}
      </div>
    </Modal>
  )
}

export default AMModalVehicles
