import { Reducer } from 'redux'
import {
  GET_ORIGINS_FAILURE,
  GET_ORIGINS_REQUEST,
  GET_ORIGINS_SUCCESS,
  GET_SOURCES_SUCCESS,
  OriginsTableAction,
  OriginsTableState
} from '../types/originsTable'

const initalState: OriginsTableState = {
  origins: [],
  sources: [],
  loadingOrigins: false,
  error: ''
}

const OriginsTableReducer: Reducer<OriginsTableState, OriginsTableAction> = (
  state = initalState,
  action
): OriginsTableState => {
  switch (action.type) {
    case GET_SOURCES_SUCCESS:
      return {
        ...state,
        sources: action.payload.sources
      }
    case GET_ORIGINS_REQUEST:
      return {
        ...state,
        loadingOrigins: true
      }
    case GET_ORIGINS_SUCCESS:
      return {
        ...state,
        loadingOrigins: false,
        origins: action.payload.origins
      }
    case GET_ORIGINS_FAILURE:
      return {
        ...state,
        loadingOrigins: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default OriginsTableReducer
