export const formatNumber = (number = 0, decimals = 2, withThousandSeparators = false) => {
  const formattedNumber = number
    .toFixed(decimals) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,

  if (withThousandSeparators) {
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  return formattedNumber
}

export const formatMoneyWithoutDecimals = (number) => formatMoney(number, { minimumFractionDigits: 0 })
export const formatMoney = (number, { minimumFractionDigits = 2 } = {}) =>
  Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits
  }).format(number)

export const formatMoneyWithCountry = (
  currency = '$',
  number,
  { decimals = 0, withSpace = true, withThousandSeparator = false, negative = false } = {}
) => {
  const numberFormatted = formatNumber(number, decimals, withThousandSeparator)
  const space = withSpace ? ' ' : ''
  const negativeSign = negative ? '-' : ''
  return `${negativeSign}${currency}${space}${numberFormatted}`
}
