import types from '../types/markPhotoAsMain'

const initialRoot = {
  open: false,
  objectId: '',
  photoId: 0,
  url: '',
  isLoading: false,
  error: ''
}

function root(state = initialRoot, action) {
  switch (action.type) {
    case types.SELECT_PHOTO:
      return {
        ...state,
        open: true,
        objectId: action.payload.objectId,
        url: action.payload.url,
        photoId: action.payload.photoId
      }
    case types.CANCEL:
      return initialRoot
    case types.MARK_PHOTO_AS_MAIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case types.MARK_PHOTO_AS_MAIN_SUCCESS:
      return initialRoot
    case types.MARK_PHOTO_AS_MAIN_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
