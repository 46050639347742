import { TransportMode } from '../../../projectApi/TransactionHandler/TransportMode/common'
import { NoTransportChargeReason } from '../../../projectApi/TransactionHandler/NoTransportChargeReasons/list'
import { GetRemovalDataActions } from './editMode'
import { TransportModeName } from '@/common/operations/constants'

export enum TransportTypes {
  GET_ADDRESSES_REQUEST = 'NEW_REMOVAL/TRANSPORT/GET_ADDRESSES_REQUEST',
  GET_ADDRESSES_SUCCESS = 'NEW_REMOVAL/TRANSPORT/GET_ADDRESSES_SUCCESS',
  GET_ADDRESSES_FAILURE = 'NEW_REMOVAL/TRANSPORT/GET_ADDRESSES_FAILURE',
  SET_ADDRESS_ID = 'NEW_REMOVAL/TRANSPORT/SET_ADDRESS_ID',
  SET_TRANSPORT_COST = 'NEW_REMOVAL/TRANSPORT/SET_TRANSPORT_COST',
  SAVE_ADDRESS_REQUEST = 'NEW_REMOVAL/TRANSPORT/SAVE_ADDRESS_REQUEST',
  SAVE_ADDRESS_SUCCESS = 'NEW_REMOVAL/TRANSPORT/SAVE_ADDRESS_SUCCESS',
  SAVE_ADDRESS_FAILURE = 'NEW_REMOVAL/TRANSPORT/SAVE_ADDRESS_FAILURE',
  FETCH_COST_REQUEST = 'NEW_REMOVAL/TRANSPORT/FETCH_COST_REQUEST',
  FETCH_COST_SUCCESS = 'NEW_REMOVAL/TRANSPORT/FETCH_COST_SUCCESS',
  FETCH_COST_FAILURE = 'NEW_REMOVAL/TRANSPORT/FETCH_COST_FAILURE',
  GET_TRANSPORT_MODE_BY_SERVICE_REQUEST = 'NEW_REMOVAL/TRANSPORT/GET_TRANSPORT_MODE_BY_SERVICE_REQUEST',
  GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS = 'NEW_REMOVAL/TRANSPORT/GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS',
  GET_TRANSPORT_MODE_BY_SERVICE_FAILURE = 'NEW_REMOVAL/TRANSPORT/GET_TRANSPORT_MODE_BY_SERVICE_FAILURE',
  GET_NO_TRANSPORT_CHARGE_REASONS_REQUEST = 'NEW_REMOVAL/TRANSPORT/GET_NO_TRANSPORT_CHARGE_REASONS_REQUEST',
  GET_NO_TRANSPORT_CHARGE_REASONS_SUCCESS = 'NEW_REMOVAL/TRANSPORT/GET_NO_TRANSPORT_CHARGE_REASONS_SUCCESS',
  GET_NO_TRANSPORT_CHARGE_REASONS_FAILURE = 'NEW_REMOVAL/TRANSPORT/GET_NO_TRANSPORT_CHARGE_REASONS_FAILURE',
  SET_NO_TRANSPORT_CHARGE = 'NEW_REMOVAL/TRANSPORT/SET_NO_TRANSPORT_CHARGE',
  SET_NO_TRANSPORT_CHARGE_REASON = 'NEW_REMOVAL/TRANSPORT/SET_NO_TRANSPORT_CHARGE_REASON',
  SET_NO_TRANSPORT_CHARGE_REASON_MODAL_OPEN = 'NEW_REMOVAL/TRANSPORT/SET_NO_TRANSPORT_CHARGE_REASON_MODAL_OPEN',
  SET_TRANSPORT_MODE = 'NEW_REMOVAL/TRANSPORT/SET_TRANSPORT_MODE',
  FETCH_TOLL_COST_REQUEST = 'NEW_REMOVAL/TRANSPORT/FETCH_TOLL_COST_REQUEST',
  FETCH_TOLL_COST_SUCCESS = 'NEW_REMOVAL/TRANSPORT/FETCH_TOLL_COST_SUCCESS',
  FETCH_TOLL_COST_FAILURE = 'NEW_REMOVAL/TRANSPORT/FETCH_TOLL_COST_FAILURE'
}
// TODO type when we move get / save address to projectAPI
export type Address = any
export interface GetAddresses {
  type: TransportTypes.GET_ADDRESSES_REQUEST
}

export interface GetAddressesSuccess {
  type: TransportTypes.GET_ADDRESSES_SUCCESS
  payload: {
    addresses: Address[]
  }
}

export interface GetAddressesError {
  type: TransportTypes.GET_ADDRESSES_FAILURE
  payload: {
    error: string
  }
}

export type GetAddressesTypes = GetAddresses | GetAddressesSuccess | GetAddressesError

export interface SetAddressID {
  type: TransportTypes.SET_ADDRESS_ID
  payload: {
    address_id: number
    addressString: string
  }
}

export interface SetTransportCost {
  type: TransportTypes.SET_TRANSPORT_COST
  payload: {
    cost: number
  }
}

export interface SaveAddress {
  type: TransportTypes.SAVE_ADDRESS_REQUEST
}

export interface SaveAddressSuccess {
  type: TransportTypes.SAVE_ADDRESS_SUCCESS
  payload: {
    address: Address
  }
}

export interface SaveAddressError {
  type: TransportTypes.SAVE_ADDRESS_FAILURE
  payload: {
    error: string
  }
}

export type SaveAddressTypes = SaveAddress | SaveAddressSuccess | SaveAddressError

export interface GetTransportModeByService {
  type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_REQUEST
}

export interface GetTransportModeByServiceSuccess {
  type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_SUCCESS
  payload: {
    serviceType: string
    transportModes: TransportMode[]
  }
}

export interface GetTransportModeByServiceError {
  type: TransportTypes.GET_TRANSPORT_MODE_BY_SERVICE_FAILURE
  payload: {
    error: string
  }
}

export type GetTransportModeByServiceTypes =
  | GetTransportModeByService
  | GetTransportModeByServiceSuccess
  | GetTransportModeByServiceError

export interface GetNoTransportChargeReasons {
  type: TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_REQUEST
}

export interface GetNoTransportChargeReasonsSuccess {
  type: TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_SUCCESS
  payload: {
    reasons: NoTransportChargeReason[]
  }
}

export interface GetNoTransportChargeReasonsError {
  type: TransportTypes.GET_NO_TRANSPORT_CHARGE_REASONS_FAILURE
  payload: {
    error: string
  }
}

export type GetNoTransportChargeReasonsTypes =
  | GetNoTransportChargeReasons
  | GetNoTransportChargeReasonsSuccess
  | GetNoTransportChargeReasonsError

export type SetNoTransportCharge = {
  type: TransportTypes.SET_NO_TRANSPORT_CHARGE
  payload: {
    noTransportCharge: boolean
  }
}

export type SetNoTransportChargeReason = {
  type: TransportTypes.SET_NO_TRANSPORT_CHARGE_REASON
  payload: {
    noTransportChargeReason: number
  }
}

export type SetNoTransportChargeReasonModalOpen = {
  type: TransportTypes.SET_NO_TRANSPORT_CHARGE_REASON_MODAL_OPEN
  payload: {
    modalOpen: boolean
  }
}

export interface SetTransportMode {
  type: TransportTypes.SET_TRANSPORT_MODE
  payload: {
    transportMode: TransportModeName
    requireAddressDestination: boolean
    shouldFetchCost: boolean
  }
}

export interface FetchCost {
  type: TransportTypes.FETCH_COST_REQUEST
}

export interface FetchCostSuccess {
  type: TransportTypes.FETCH_COST_SUCCESS
  payload: {
    cost: number
    where: string
  }
}

export interface FetchCostError {
  type: TransportTypes.FETCH_COST_FAILURE
  payload: {
    error: string
  }
}

export type FetchCostTypes = FetchCost | FetchCostSuccess | FetchCostError

export interface FetchTollCost {
  type: TransportTypes.FETCH_TOLL_COST_REQUEST
}

export interface FetchTollCostSuccess {
  type: TransportTypes.FETCH_TOLL_COST_SUCCESS
  payload: {
    tollCostInCents: number
  }
}

export interface FetchTollCostError {
  type: TransportTypes.FETCH_TOLL_COST_FAILURE
  payload: {
    error: string
  }
}

export type FetchTollCostTypes = FetchTollCost | FetchTollCostSuccess | FetchTollCostError

export type TransportActionTypes =
  | GetTransportModeByServiceTypes
  | GetNoTransportChargeReasonsTypes
  | SetTransportMode
  | SetNoTransportCharge
  | SetNoTransportChargeReason
  | SetNoTransportChargeReasonModalOpen
  | SetAddressID
  | GetAddressesTypes
  | SaveAddressTypes
  | FetchCostTypes
  | SetTransportCost
  | GetRemovalDataActions
  | FetchTollCostTypes
