import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory, useParams } from 'react-router'
import { TableActionBar } from '../../../../components/TableActionBar'
import ObjectChangesTable from '../components/objectChangesTable'
import objectChangesActionCreator from '../actionCreators/objectChanges'

type ParamTypes = {
  objectID: number
}

const ObjectChangesContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { objectChanges, error, params, pagination, loading } = useSelector(
    (state) => state.CorporativeObjects.objectChanges
  )
  const { getObjectChanges } = bindActionCreators(objectChangesActionCreator, dispatch)

  const useParsedParams = (): ParamTypes => {
    const { objectID } = useParams<{ objectID: string }>()
    return { objectID: parseInt(objectID) }
  }

  const { objectID } = useParsedParams()

  if (!objectID) {
    history.push('corporative/objects')
  }

  const handleReturnButton = () => {
    history.goBack()
  }

  const handleGetObjectChanges = (objectID: number) => {
    getObjectChanges(objectID)
  }

  useEffect(() => {
    handleGetObjectChanges(objectID)
  }, [])

  const props = {
    objectChanges,
    error,
    loading,
    params,
    pagination
  }

  return (
    <>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction onClickButton={handleReturnButton} titleButton="Volver" iconType="arrow-left" />
      </TableActionBar>
      <ObjectChangesTable {...props} />
    </>
  )
}

export default ObjectChangesContainer
