import { Reducer } from 'redux'
import { EditDataTypes, EditModeActions, EditModeState, GetRemovalData } from '../types/editMode'
import commonTypes from '../types/common'
import { OperationStatus } from '../../../projectApi/TransactionHandler/Operation/Removals/list'

const editModeInitialState: EditModeState = {
  editMode: false,
  isDraft: false,
  userId: 0
}

const EditModeReducer: Reducer<EditModeState, EditModeActions> = (
  state = editModeInitialState,
  action
): EditModeState => {
  switch (action.type) {
    case commonTypes.FINISH:
      return editModeInitialState
    case GetRemovalData.GET_REMOVAL_DATA_SUCCESS: {
      return {
        ...state,
        isDraft: action.payload.operation.status === OperationStatus.DRAFT
      }
    }
    case EditDataTypes.ACTIVE_EDIT_MODE: {
      const { userId, activeMode, isDraft } = action.payload

      return activeMode && userId
        ? {
            ...state,
            editMode: activeMode,
            userId,
            isDraft: isDraft || false
          }
        : editModeInitialState
    }
    default:
      return state
  }
}

export default EditModeReducer
