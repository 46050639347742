import React, { useMemo, useState } from 'react'
import { Button, Col, InputNumber, Modal, Row } from 'antd'
import InputGroup from '../../../../components/InputGroup'
import { formatNumber } from '../../../../utils/formatNumber'
import InputFieldWithError from '../../../../components/InputFieldWithError/inputFieldWithError'
import Select from '../../../../components/Select'
import actions from '../actionCreators/edit'
import { EditObjectState } from '../reducers/edit'
import { BaseOption } from '../../../../components/Select/Select'
import { Attribute } from '../../../../projectApi/ObjectAdministration/Attribute/list'
import { SelectedAttribute } from '../types/edit'
import { useCountryInfo } from '../../../../components/CountrySelector/hooks/hooks'
import AttributeComponent from '../../../Objects/components/attributeComponent'

const styles = {
  modal: {
    maxWidth: 600
  },

  switch: {
    display: 'block',
    marginTop: 8
  },
  error: {
    color: 'red',
    textAlign: 'right' as const
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  },
  inputGroup: {
    marginTop: 32
  },
  selectStyles: {
    marginTop: '5px'
  }
}

const TotalVolume = ({ totalVolume }: { totalVolume: number }) => (
  <span>
    Volumen: {formatNumber(totalVolume)}{' '}
    <span>
      m<sup>3</sup>
    </span>
  </span>
)

type EditProps = {
  actions: typeof actions
  editState: EditObjectState
  onSetAttributeType: (index: number, attributeId: number) => void
  onDeleteAttribute: (index: number) => void
  remainingAttributes: Attribute[]
  selectedAttributes: SelectedAttribute[]
}

const EditPanel = (props: EditProps) => {
  const [locationCode, setLocationCode] = useState('')
  const [autocomplete, setAutocomplete] = useState('')

  const { remainingAttributes, selectedAttributes, onSetAttributeType, onDeleteAttribute } = props

  const {
    cancel,
    modifyObject,
    setProduct,
    setHeight,
    setWidth,
    setLength,
    setAttributeValue,
    getProducts,
    getProductAttributes,
    getLocationByCode
  } = props.actions

  const setProductFn = (selected: BaseOption<number>) => {
    const product = products.find((product) => product.id === selected?.value) || selectedProduct!
    setProduct(product)
    getProductAttributes(product.id)
  }

  const calculateDimensions = (height = 0, width = 0, length = 0) => {
    return (height * width * length) / 1000000
  }

  const {
    open,
    id,
    serviceTypeId,
    currentObject,
    currentObjectBody,
    newObject,
    newLocation,
    selectedProduct,
    products,
    productAttributes,
    isSaving,
    loadingLocation,
    loadingProducts,
    loadingProductAttributes,
    error,
    locationError
  } = props.editState

  const country = useCountryInfo()

  const onProductInputChange = (description: string) => {
    if (description === '') return
    setAutocomplete(description)
    getProducts(description, serviceTypeId)
  }

  const productOptions = useMemo(() => {
    return products.reduce<BaseOption<number>[]>((acc, l) => {
      if (!l.deprecated || l.id === newObject?.productId || 0) {
        acc.push({
          value: l.id,
          label: `${l.description} - ${l.heightInCm} x ${l.widthInCm} x ${l.lengthInCm} (cm) = ${formatNumber(
            calculateDimensions(l.heightInCm, l.widthInCm, l.lengthInCm)
          )} m³ ${l?.deprecated ? ' - (DEPRECADO)' : ''}`
        })
      }
      return acc
    }, [])
  }, [currentObject, products])

  const selectedProductOption = useMemo(
    () => productOptions.find((productOption) => productOption.value === newObject.productId),
    [productOptions, newObject.productId]
  )

  const actualProductVolume = useMemo(() => {
    if (!currentObject?.product) return 0
    return calculateDimensions(
      currentObject.product.heightInCm,
      currentObject?.product.widthInCm,
      currentObject?.product.lengthInCm
    )
  }, [currentObject?.product])
  const newObjectVolume = useMemo(() => {
    const { heightInCm, widthInCm, lengthInCm } = newObject
    return calculateDimensions(heightInCm, widthInCm, lengthInCm)
  }, [newObject])

  const { minNewObjectVolume, maxNewObjectVolume } = useMemo(() => {
    const {
      heightInCm = 0,
      widthInCm = 0,
      lengthInCm = 0,
      minVolumeDeviation = 0,
      maxVolumeDeviation = 0
    } = selectedProduct || {}
    const newProductVolume = calculateDimensions(heightInCm, widthInCm, lengthInCm)
    const minNewObjectVolume = newProductVolume - newProductVolume * minVolumeDeviation
    const maxNewObjectVolume = newProductVolume + newProductVolume * maxVolumeDeviation
    return { newProductVolume, minNewObjectVolume, maxNewObjectVolume }
  }, [selectedProduct])

  const errorVolume = useMemo(() => {
    if (newObjectVolume < minNewObjectVolume) return 'El volumen es menor al mínimo indicado'
    if (newObjectVolume > maxNewObjectVolume) return 'El volumen es mayor al máximo indicado'
    return ''
  }, [newObjectVolume])

  const newObjectBody = {
    ...newObject,
    attributes: newObject.attributes?.filter((attribute) => attribute.attributeId)
  }

  const modify = () => {
    modifyObject(id, currentObjectBody, newObjectBody)
  }

  const isAnyChange = JSON.stringify(currentObjectBody) !== JSON.stringify(newObjectBody)
  const isAllAttributesSelected = selectedAttributes.every(
    (attribute) => (Boolean(attribute.attributeId) && attribute.value !== '') || Boolean(!attribute.attributeId)
  )

  return (
    <Modal
      title="Modificar Objeto"
      visible={open}
      onCancel={cancel}
      closable={isSaving}
      style={styles.modal}
      confirmLoading={isSaving}
      footer={[
        <>
          <Button key="back" onClick={cancel} loading={isSaving} disabled={isSaving}>
            Cancelar
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={modify}
            loading={isSaving}
            disabled={
              isSaving ||
              Boolean(errorVolume) ||
              loadingLocation ||
              !isAnyChange ||
              Boolean(!newObject.productId) ||
              !isAllAttributesSelected
            }>
            Guardar
          </Button>
        </>
      ]}>
      <InputGroup title="Catalogación">
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <span style={styles.inputTitle}>Producto Actual:</span>
            <p>
              {currentObject?.product?.description}
              {currentObject?.product?.deprecated && ' (Deprecado)'} - {currentObject?.product?.heightInCm}x
              {currentObject?.product?.widthInCm}x{currentObject?.product?.lengthInCm} (cm) ={' '}
              {formatNumber(actualProductVolume, 2)} m<sup>3</sup>
            </p>
          </Col>
          <Col span={9}>
            <span style={styles.inputTitle}>Medidas del Objeto Actual:</span>
            <p>
              {newObject.heightInCm}x{newObject.widthInCm}x{newObject.lengthInCm} (cm) ={' '}
              {formatNumber(newObjectVolume, 2)} m<sup>3</sup>
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <span style={styles.inputTitle}>Nuevo producto:</span>
            <Select
              loading={loadingProducts}
              placeholder="Seleccionar producto"
              selected={selectedProductOption}
              onSelect={setProductFn}
              autocomplete={{
                onInputChange: onProductInputChange,
                input: autocomplete,
                filterByInput: false
              }}
              options={productOptions}
              onDelete={() => {
                setAutocomplete('')
                setProduct(null)
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <p style={styles.inputTitle}>Alto (cm)*</p>
            <InputNumber
              min={0}
              value={newObject.heightInCm}
              onChange={(value = 0) => setHeight(value)}
              placeholder="Alto*"
            />
          </Col>
          <Col span={8}>
            <p style={styles.inputTitle}>Ancho (cm)*</p>
            <InputNumber
              value={newObject.widthInCm}
              min={0}
              onChange={(value = 0) => setWidth(value)}
              placeholder="Ancho*"
            />
          </Col>
          <Col span={8}>
            <p style={styles.inputTitle}>Largo (cm)*</p>
            <InputNumber
              value={newObject.lengthInCm}
              min={0}
              onChange={(value = 0) => setLength(value)}
              placeholder="Largo*"
            />
          </Col>
          {newObjectVolume !== 0 && (
            <>
              <Col span={8}>
                <span style={{ color: errorVolume ? 'red' : 'green' }}>
                  <TotalVolume totalVolume={newObjectVolume} />
                </span>
              </Col>
              <Col span={16}>
                <span>
                  Posibles valores: -{(selectedProduct?.minVolumeDeviation || 0) * 100}% (
                  {formatNumber(minNewObjectVolume)}m³) ~ +{(selectedProduct?.maxVolumeDeviation || 0) * 100}% (
                  {formatNumber(maxNewObjectVolume)}m³)
                </span>
              </Col>
              <Col span={24}>
                <span style={{ color: 'red' }}>{errorVolume}</span>
              </Col>
            </>
          )}
        </Row>
      </InputGroup>
      <InputGroup style={styles.inputGroup} title="Logística y Ubicación">
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ paddingTop: 14 }}>
            <InputFieldWithError
              label="Código de ubicación"
              inputStyles={{ height: 36 }}
              value={locationCode}
              loading={loadingLocation}
              error={locationError}
              onChange={(e) => setLocationCode(e.target.value)}
              onBlur={() => {
                if (locationCode) getLocationByCode(locationCode)
              }}
            />
          </Col>
          <Col span={12}>
            <span style={styles.inputTitle}>Ubicación Actual:</span>
            <p>{currentObject?.location?.description ? `${currentObject?.location?.description}` : '-'}</p>
          </Col>
          <Col span={12}>
            <span style={styles.inputTitle}>Nueva ubicación:</span>
            <p>
              {newLocation?.description ? `${newLocation?.description}` : currentObject?.location?.description || '-'}
            </p>
          </Col>
        </Row>
      </InputGroup>
      {Boolean(newObject.productId) && productAttributes.length > 0 && (
        <InputGroup style={styles.inputGroup} title="Atributos">
          {selectedAttributes.map((attribute) => {
            return (
              <AttributeComponent
                loadingProductAttributes={loadingProductAttributes}
                key={attribute.realIndex}
                country={country.code}
                attributes={attribute.attributeId ? productAttributes : remainingAttributes}
                selectedAttribute={attribute}
                setAttributeType={onSetAttributeType}
                setAttributeValue={setAttributeValue}
                deleteAttribute={onDeleteAttribute}
              />
            )
          })}
        </InputGroup>
      )}
      <div style={styles.error}>{error}</div>
    </Modal>
  )
}

export default EditPanel
