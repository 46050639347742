import { Reducer } from 'redux'
import {
  CANCEL_DELETE_PRODUCT_MODAL,
  CLOSE_DELETE_PRODUCT_MODAL,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DeleteProductActions,
  DeleteProductState,
  OPEN_DELETE_PRODUCT_MODAL,
  SET_ERROR
} from '../types/deleteModal'

const initialState: DeleteProductState = {
  open: false,
  product: null,
  error: ''
}

const DeleteProductReducer: Reducer<DeleteProductState, DeleteProductActions> = (
  state = initialState,
  action
): DeleteProductState => {
  switch (action.type) {
    case OPEN_DELETE_PRODUCT_MODAL:
      return {
        ...state,
        open: true,
        product: action.payload.product
      }
    case CANCEL_DELETE_PRODUCT_MODAL:
      return initialState
    case CLOSE_DELETE_PRODUCT_MODAL:
      return initialState
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state
      }
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        open: false
      }
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default DeleteProductReducer
