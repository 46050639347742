export enum OperationTypeValues {
  DEPOSIT = 'deposit_operation',
  REMOVAL = 'removal_operation',
  NONE = ''
}
export enum OperationTypeName {
  DEPOSIT = 'Depósito',
  REMOVAL = 'Devolución',
  NONE = 'Sin Actividad'
}

export type OperationCalendarState = {
  selectedOperations: OperationTypeValues[]
  availableOperations: OperationTypeValues[]
}

export enum ModalCalendarOperationTypes {
  SET_SELECTED_DAYS_OPERATIONS = 'OPERATION_CALENDAR/COMMON/SET_SELECTED_DAYS_OPERATIONS',
  SET_AVAILABLE_OPERATIONS = 'OPERATION_CALENDAR/COMMON/SET_AVAILABLE_OPERATIONS'
}

export interface SetSelectedOperations {
  type: typeof ModalCalendarOperationTypes.SET_SELECTED_DAYS_OPERATIONS
  payload: {
    selectedOperations: OperationTypeValues[]
  }
}

export interface SetAvailableOperations {
  type: typeof ModalCalendarOperationTypes.SET_AVAILABLE_OPERATIONS
  payload: {
    availableOperations: OperationTypeValues[]
  }
}

type ModalCalendarOperations = SetSelectedOperations | SetAvailableOperations

export type OperationCalendarAction = ModalCalendarOperations
