import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  DISABLE_CARD_FAILURE,
  DISABLE_CARD_REQUEST,
  DISABLE_CARD_SUCCESS,
  DisableCardRequest,
  DisableCardSuccess,
  GET_CARDS_FAILURE,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS,
  GetCardsRequest,
  GetCardsSuccess,
  MARK_MAIN_CARD_FAILURE,
  MARK_MAIN_CARD_REQUEST,
  MARK_MAIN_CARD_SUCCESS,
  MarkMainCardRequest,
  MarkMainCardSuccess
} from '../types/cardTable'

const CardsTableActionCreators = {
  getCards:
    ({ userId, silentLoading }: { userId: string; silentLoading?: boolean }): AppThunk =>
    async (dispatch) => {
      const request: GetCardsRequest = {
        type: GET_CARDS_REQUEST,
        payload: {
          userId,
          silentLoading
        }
      }

      dispatch(request)

      try {
        const { payments } = await API.UserProfile.paymentMethods.list({ userId })

        const success: GetCardsSuccess = {
          type: GET_CARDS_SUCCESS,
          payload: {
            cards: payments
          }
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_CARDS_FAILURE, dispatch)
      }
    },

  markMainCard:
    ({ cardId }: { cardId: number }): AppThunk =>
    async (dispatch) => {
      const request: MarkMainCardRequest = {
        type: MARK_MAIN_CARD_REQUEST,
        payload: { cardId }
      }

      const toastId = setToastLoading('Marcando como principal, por favor espere...')
      dispatch(request)

      try {
        await API.UserProfile.paymentMethods.main({ cardId })

        setToastSuccessUpdate(toastId, { render: `Se marco como principal la tarjeta con id: ${cardId}` })
        const success: MarkMainCardSuccess = {
          type: MARK_MAIN_CARD_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.CreditCard.MARK_MAIN_CARD)
      } catch (error) {
        const message = handleErrorsWithAction(error, MARK_MAIN_CARD_FAILURE, dispatch, { cardId })
        setToastErrorUpdate(toastId, { render: message })
      }
    },

  disableCard:
    ({ cardId, userId }: { cardId: number; userId: number }): AppThunk =>
    async (dispatch) => {
      const request: DisableCardRequest = {
        type: DISABLE_CARD_REQUEST,
        payload: { cardId }
      }

      const toastId = setToastLoading('Dando de baja tarjeta, por favor espere...')
      dispatch(request)

      try {
        await API.UserProfile.paymentMethods.disable({ cardId, userId })

        setToastSuccessUpdate(toastId, { render: `Se dio de baja la tarjeta con id: ${cardId}` })
        const success: DisableCardSuccess = {
          type: DISABLE_CARD_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.CreditCard.DISABLE_CARD)
      } catch (error) {
        const message = handleErrorsWithAction(error, DISABLE_CARD_FAILURE, dispatch, { cardId })
        setToastErrorUpdate(toastId, { render: message })
      }
    }
}

export default CardsTableActionCreators
