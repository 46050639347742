import React from 'react'
import { useHistory } from 'react-router'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { TableActionBar } from '../../../components/TableActionBar'
import { formatDate, formatDateMMYY } from '../../../utils/formatDate'
import { Card } from '../types/cardTable'
import { Check, CircleCheck, Cross, Deny } from '../../../icons'
import styles from './cardsTable.module.scss'
import moment from 'moment'
import { Tooltip } from 'antd'

export interface CardsTableProps {
  cards: Card[]
  userId: string
  userName: string | string[] | null
  userLastName: string | string[] | null
  setModalOpen: (open: boolean) => void
  loadingCards: boolean
  markMainCard: ({ cardId }: { cardId: number }) => void
  disableCard: ({ cardId, userId }: { cardId: number; userId: number }) => void
}

const CardsTable = (props: CardsTableProps) => {
  const { userId, userName, userLastName, cards, loadingCards, setModalOpen, markMainCard, disableCard } = props
  const history = useHistory()

  const handleReturnButton = () => {
    history.goBack()
  }

  const columns: Column<Card>[] = [
    {
      key: 'cardId',
      label: 'ID Card'
    },
    {
      key: 'createdAt',
      renderDataCell: ({ createdAt }) => formatDate(createdAt),
      label: 'Fecha de creación'
    },
    {
      key: 'main',
      label: 'Principal',
      renderDataCell: ({ main }) =>
        main ? <Check fill="green" width="14px" height="14px" /> : <Cross fill="red" width="14px" height="14px" />
    },
    {
      key: 'lastDigits',
      label: 'Últimos números'
    },
    {
      key: 'type',
      label: 'Tipo',
      renderDataCell: ({ type }) => type.toUpperCase()
    },
    {
      key: 'expirationDate',
      renderDataCell: ({ expirationDate }) => {
        const today = moment()
        const isExpired = today.isAfter(expirationDate)

        if (isExpired)
          return (
            <Tooltip title={'La tarjeta está vencida. No podrá marcarse como principal.'}>
              <span style={{ color: 'red' }}>{formatDateMMYY(expirationDate)}</span>
            </Tooltip>
          )

        return formatDateMMYY(expirationDate)
      },
      label: 'Fecha de expiración'
    }
  ]

  const cardsActions: MenuAction<Card>[] = [
    {
      label: 'Marcar como principal',
      icon: <CircleCheck width={'16px'} height={'16px'} fill={'#000'} />,
      onClick: ({ cardId }) => markMainCard({ cardId }),
      disabled: (card) => {
        const today = moment()
        const isExpired = today.isAfter(card?.expirationDate)

        return (card?.main ?? false) || isExpired
      }
    },
    {
      label: 'Dar de baja',
      icon: <Deny width={'16px'} height={'16px'} fill={'#000'} />,
      onClick: ({ cardId }) => disableCard({ cardId, userId: Number(userId) }),
      disabled: (card) => card?.main ?? false
    }
  ]

  return (
    <>
      <TableActionBar>
        <TableActionBar.ButtonAction
          customClassName={styles.buttonBack}
          titleButton="Volver"
          onClickButton={handleReturnButton}
        />
        <TableActionBar.ButtonAction titleButton="Agregar tarjeta" onClickButton={() => setModalOpen(true)} />
      </TableActionBar>
      <div className={styles.titleContainer}>
        <h1>{`Estas son las tarjetas del usuario: ${userId} - ${userName} ${userLastName}`}</h1>
      </div>
      <DynamicTable
        actions={cardsActions}
        loading={loadingCards}
        keyExtractor={(entity) => entity.userID}
        rows={cards}
        columns={columns}
      />
    </>
  )
}

export default CardsTable
