import { AppThunk } from '../../../store'
import {
  SEND_PRE_BILLING_REPORT_FAILURE,
  SEND_PRE_BILLING_REPORT_REQUEST,
  SEND_PRE_BILLING_REPORT_SUCCESS,
  SendPreBillingReportRequest,
  SendPreBillingReportSuccess
} from '../types/preBillingReport'
import { API } from '../../../projectApi'
import moment from 'moment/moment'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate,
  setToastWarningUpdate
} from '../../../utils/notifications'
import reportsActions from '../../Reports/actions'
import { ReportsType } from '../../Reports/types'
import { MINUTES_BETWEEN_REPORTS } from '../../../projectApi/TransactionHandler/Reports/billings'

const BillingReportActionCreators = {
  sendPreBillingReport:
    (lastDateReport: string | null): AppThunk =>
    async (dispatch) => {
      const request: SendPreBillingReportRequest = {
        type: SEND_PRE_BILLING_REPORT_REQUEST
      }
      const toastId = setToastLoading('Solicitando Reporte, por favor espere...')

      const now = moment()
      const nextDateReport = lastDateReport ? moment(lastDateReport).add(MINUTES_BETWEEN_REPORTS, 'minutes') : now

      if (nextDateReport.isAfter(now)) {
        const minutesLeft = nextDateReport.diff(now, 'minutes')
        setToastWarningUpdate(toastId, {
          render: `Un reporte de abonos se solicitó hace poco. Por favor espere ${minutesLeft} minutos para volver a soliticarlo`
        })

        return
      }

      dispatch(request)

      try {
        await API.TransactionHandler.Reports.billings({
          dateFrom: moment().subtract(60, 'days')
        })
        const success: SendPreBillingReportSuccess = {
          type: SEND_PRE_BILLING_REPORT_SUCCESS
        }

        dispatch(success)
        dispatch(reportsActions.setLastRequestReportTime(ReportsType.PRE_BILLING_REPORT, now))
        setToastSuccessUpdate(toastId, { render: 'Reporte solicitado' })
      } catch (err) {
        handleErrorsWithAction(err, SEND_PRE_BILLING_REPORT_FAILURE, dispatch)
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte.'
        })
      }
    }
}

export default BillingReportActionCreators
