export const STATUS_OPTIONS = [
  {
    value: 'RECEIVED',
    text: 'Recibida',
    styleProps: {
      borderColor: '#2647B2',
      backgroundColor: '#DBEAFE'
    }
  },
  {
    value: 'REJECTED',
    text: 'Rechazada',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  },
  {
    value: 'ACCEPTED',
    text: 'Aceptada',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: 'DONATED',
    text: 'Donado',
    styleProps: {
      borderColor: '#027A48',
      backgroundColor: '#ECFDF3'
    }
  },
  {
    value: 'CANCELLED',
    text: 'Cancelada',
    styleProps: {
      borderColor: '#B42318',
      backgroundColor: '#FEF3F2'
    }
  }
]
