import React from 'react'
import { Checkbox, Table, Tooltip } from 'antd'
import actions from '../actions/locationsTable'
import { List, QRIcon, Trash, Edit } from '../../../icons'
import { useHistory } from 'react-router'
import { getColumnSearchProps } from '../../../utils/columnSearchProps'
import { isActive, typesOptions } from '../location.constants'
import { formatNumber } from '../../../utils/formatNumber'
import { LocationsTableState } from '../reducers/locationsTable'
import { PaginationConfig, SorterResult } from 'antd/es/table'
import {
  ColumnsSearchFilters,
  Location,
  LocationFilter,
  LocationsTableDateRangeFilter,
  Params
} from '../types/locationsTable'
import { SortOrder } from 'antd/lib/table'
import { usePaginationLabel } from '../../../utils/hooks/usePaginationLabel'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'
import { Permissions } from '../../UserLogged/permissions'
import { formatDateLocal } from '../utils'
import { TableActionBar } from '../../../components/TableActionBar'
import LocationsMetricsComponent from '../../Corporative/Locations/components/locationsMetrics'
import styles from './AddEditLocationModal.module.scss'

type LocationsTableProps = {
  locationsActions: typeof actions
  locationsTable: LocationsTableState
  handleChange: (pagination: PaginationConfig, filters: LocationFilter, sorter: SorterResult<any>) => void
  handleSearch: (dataIndex: string, text: string, confirm: () => void) => void
  params: Params
  QRModalActions: any // TODO move QRModalActions to typescript
  openModalCreateLocation: () => void
  handleOpenModalDelete: (selectedLocation: Location) => void
  handleOpenModalEdit: (selectedLocation: Location) => void
  handleCleanFilters: () => void
  dateRangeFilters: LocationsTableDateRangeFilter[]
}

const LocationsTable = (props: LocationsTableProps) => {
  const {
    locationsActions,
    locationsTable,
    handleChange,
    handleSearch,
    QRModalActions,
    params,
    openModalCreateLocation,
    handleOpenModalDelete,
    handleOpenModalEdit,
    handleCleanFilters
  } = props

  const { updateLocationInfo } = locationsActions
  const { locations, locationInfo, loadingLocationInfo, loadingLocations, pagination, filter, searchFilters } =
    locationsTable
  const { openQRModal } = QRModalActions

  const history = useHistory()

  const showObjectsByLocation = (locationCode: string) => {
    history.push(`/locations/${locationCode}`)
  }
  const permissions = usePermissions()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend' as SortOrder,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
      ...getColumnSearchProps(ColumnsSearchFilters.ID, 'ID', handleSearch, searchFilters)
    },
    {
      title: 'Código de Ubicación',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps(ColumnsSearchFilters.CODE, 'Código de Ubicación', handleSearch, searchFilters)
    },
    {
      title: 'ID Depósito',
      dataIndex: 'depositId',
      key: 'deposit_id',
      sorter: true,
      ...getColumnSearchProps(ColumnsSearchFilters.DEPOSITID, 'ID Depósito', handleSearch, searchFilters)
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'created_at',
      render: formatDateLocal
    },
    {
      title: 'Fecha de modificación',
      dataIndex: 'updatedAt',
      key: 'updated_at',
      render: formatDateLocal
    },
    {
      title: 'Depósito',
      dataIndex: 'depositName',
      key: 'deposit_name',
      ...getColumnSearchProps(ColumnsSearchFilters.DEPOSIT_NAME, 'Depósito', handleSearch, searchFilters)
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps(ColumnsSearchFilters.DESCRIPTION, 'Descripción', handleSearch, searchFilters)
    },
    {
      title: 'Activo',
      dataIndex: 'active',
      key: 'active',
      filters: isActive,
      filterMultiple: false,
      filteredValue: filter.active,
      render: (n: boolean) => (n ? <span>SÍ</span> : <span>NO</span>)
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      filters: typesOptions,
      filterMultiple: false,
      filteredValue: filter.type
    },
    {
      title: 'Objetos',
      dataIndex: 'quantityObjects',
      key: 'quantity_objects',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[]
    },
    {
      title: 'Peso / Peso Máx. [Kg]',
      dataIndex: 'weightInGr',
      key: 'weight_in_gr',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (weightInGr: number, record: Location) => {
        const weight = formatNumber(weightInGr / 1000, 2)
        const maxWeight = formatNumber(record.maxWeightInGr / 1000, 2)
        return (
          <span>
            {weight} / {maxWeight}
          </span>
        )
      }
    },
    {
      title: 'Volumen / Volumen Máx. m³',
      dataIndex: 'volumeInCm',
      key: 'volume_in_cm',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (volumeInCm: number, record: Location) => {
        const volume = formatNumber(volumeInCm / 1000000, 2)
        const maxVolume = formatNumber(record.maxVolumeInCm / 1000000, 2)
        return (
          <span>
            {volume} / {maxVolume}
          </span>
        )
      }
    },
    {
      title: 'Ocupación %',
      dataIndex: 'occupancyRate',
      key: 'occupancy_rate',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (n: number) => formatNumber(n, 2)
    },
    {
      title: 'Carga %',
      dataIndex: 'loadRate',
      key: 'load_rate',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (n: number) => formatNumber(n, 2)
    },
    {
      title: 'Código QR',
      dataIndex: 'code',
      key: 'openQRModal',
      render: (Code: string) => {
        return (
          (
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => openQRModal(Code)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
          ) || '-'
        )
      }
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (record: Location) => (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '6px' }}>
          {record.code && (
            <Tooltip title="Ver Objetos">
              <i
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={() => showObjectsByLocation(record.code)}>
                <List width="22px" height="22px" />
              </i>
            </Tooltip>
          )}
          {permissions.includes(Permissions.EditarUbicacionAdmin) && (
            <Tooltip title="Editar Ubicación">
              <i style={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleOpenModalEdit(record)}>
                <Edit />
              </i>
            </Tooltip>
          )}
          {permissions.includes(Permissions.EliminarUbicacionAdmin) && (
            <Tooltip title="Eliminar Ubicación">
              <i style={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleOpenModalDelete(record)}>
                <Trash width="22px" height="22px" />
              </i>
            </Tooltip>
          )}
        </div>
      )
    }
  ]

  const showTotal = usePaginationLabel(pagination?.total || 0, 'ubicaciones')

  const locationsPagination = {
    ...pagination,
    size: 'small',
    style: {
      margin: '12px 0'
    },
    showTotal
  }

  const onExactLocationChange = () => {
    const newParams = { ...params, exactLocationCode: !params.exactLocationCode }

    locationsActions.getLocationInfo(newParams)
    locationsActions.getLocations(newParams)
  }

  return (
    <div className="animation fadeIn">
      <div className={styles.headerContainer}>
        <LocationsMetricsComponent locationsMetrics={locationInfo} fetchingLocationsMetrics={loadingLocationInfo} />
      </div>
      <TableActionBar>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleCleanFilters} />
          <TableActionBar.ButtonAction
            variant="secondary"
            iconType="reload"
            titleButton="Actualizar ubicaciones"
            onClickButton={updateLocationInfo}
          />

          <div style={{ borderLeft: '1.5px solid #e1e6ef ' }} />
          <TableActionBar.Filters>
            <Checkbox
              style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 'max-content'
              }}
              checked={params.exactLocationCode}
              onChange={onExactLocationChange}>
              Búsqueda por código de ubicación exacta
            </Checkbox>
          </TableActionBar.Filters>
        </TableActionBar.Wrapper>

        {permissions.includes(Permissions.CrearUbicacionAdmin) && (
          <TableActionBar.ButtonAction titleButton="Crear ubicación" onClickButton={openModalCreateLocation} />
        )}
      </TableActionBar>
      <Table
        columns={columns}
        dataSource={locations}
        loading={loadingLocations}
        pagination={locationsPagination}
        scroll={{ x: true }}
        onChange={handleChange}
        className={styles.customTableProps}
        rowKey="ID"
      />
    </div>
  )
}

export default LocationsTable
