import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import transportActionCreators, { fetchCost } from '../actions/transport'
import Component from '../components/Transport'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { PROPOSAL_MODES } from '../../../common/operations/constants'

type TransportProps = {
  mode: typeof PROPOSAL_MODES[keyof typeof PROPOSAL_MODES]
}

const Container = (ownProps: TransportProps) => {
  const { mode } = ownProps
  const flags = useSelector((state) => state.NewProposal.flags)
  const transport = useSelector((state) => state.NewProposal.transport)
  const prospect = useSelector((state) => state.NewProposal.prospect)
  const selectedProposal = useSelector((state) => state.NewProposal.proposals.selectedProposal)

  const dispatch = useDispatch()
  const transportActions = bindActionCreators(transportActionCreators, dispatch)

  useEvents(
    [
      Events.EditDepositView.SET_WHERE,
      Events.EditDepositView.SET_ITEM_PACKAGING,
      Events.EditDepositView.SET_ITEM_FLOORS,
      Events.EditDepositView.SET_ITEM_DISASSEMBLE,
      Events.EditDepositView.SET_PREV_TRANSPORT_COST,
      Events.EditDepositView.SET_TRANSPORT_MODE,
      Events.Proposal.SET_ITEM,
      Events.Proposal.CLEAN_ITEM,
      Events.Proposal.REMOVE_ITEM,
      Events.Proposal.MODIFY_ITEM_QUANTITY,
      Events.Proposal.GET_PROPOSAL
    ],
    () => {
      if (transport.requireAddressOrigin) {
        dispatch(fetchCost)
      }
    }
  )

  const props = {
    globalDisabled: mode === PROPOSAL_MODES.VIEW_PROPOSAL,
    transport,
    transportActions,
    flags,
    prospect,
    selectedProposal
  }

  return <Component {...props} />
}

export default Container
