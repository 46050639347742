import types from './Activity.types'
import { URL_BASE_PAYMENTS } from '../../endpoints'
import { apiPrivate } from '../../api'
import { handleErrorsWithAction } from '../../utils/HandleErrors'

export default {
  cleanFilters() {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({ type: types.CLEAN_FILTERS })
        resolve()
      })
    }
  },

  getActivity(params) {
    return (dispatch) => {
      dispatch({ type: types.FETCH_ACTIVITY })
      return apiPrivate
        .get(URL_BASE_PAYMENTS + '/charges/charges', { params })
        .then((response) => {
          const activity = response.data.description.charges

          const quantity = response.data.description.quantity

          dispatch({
            type: types.FETCH_ACTIVITY_SUCCESS,
            payload: { activity, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_ACTIVITY_FAIL, dispatch)
        })
    }
  },

  setSorter(sorter) {
    return { type: types.SET_SORTER, payload: { sorter } }
  },

  setFilter(filter) {
    return { type: types.SET_FILTER, payload: { filter } }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  },

  setUpdateModalOpen(modalOpen, charge) {
    return { type: types.SET_UPDATE_MODAL_OPEN, payload: { modalOpen, charge } }
  },

  setStatus(status_id) {
    return { type: types.SET_STATUS, payload: { status_id } }
  },

  setReference(payment_reference) {
    return { type: types.SET_REFERENCE, payload: { payment_reference } }
  },

  updateActivity(charge) {
    const { id, status_id, payment_reference } = charge

    const formData = new FormData()
    formData.append('status_id', status_id)
    formData.append('reference', payment_reference)

    return (dispatch) => {
      dispatch({ type: types.UPDATE_ACTIVITY })
      return apiPrivate
        .put(URL_BASE_PAYMENTS + '/charges/charges/' + id, formData)
        .then((response) => {
          const charge = response.data.description.charge

          dispatch({ type: types.UPDATE_ACTIVITY_SUCCESS, payload: { charge } })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.UPDATE_ACTIVITY_FAIL, dispatch)
        })
    }
  }
}
