import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

interface AuthorizationAFIPParams {
  billingId: number
}

export function authorizationAfip({ billingId }: AuthorizationAFIPParams): Promise<boolean> {
  const config = {
    headers: {
      'billing-id': billingId
    }
  }

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/authorization-afip`, {}, config)
    .then((response: AxiosResponse) => {
      if (!response.data) throw new Error('Hubo un error al facturar un abono.')
      return true
    })
    .catch((err) => {
      throw err
    })
}
