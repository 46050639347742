import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'

export interface PaymentModalState {
  modalOpen: boolean
  billing?: Billing | null
  error: string
  loading: boolean
}
export const OPEN_MODAL_PAYMENT = 'BILLINGS/BILLINGS_BY_CLIENT/PAYMENT/OPEN_MODAL_PAYMENT'

export interface OpenModalPayment {
  type: typeof OPEN_MODAL_PAYMENT
  payload: {
    modalOpen: boolean
    billing?: Billing
  }
}

export const SET_BILLING_ID = 'BILLINGS/BILLINGS_BY_CLIENT/PAYMENT/SET_BILLING_ID'

export const PAY_BILLING_REQUEST = 'BILLINGS/BILLINGS_BY_CLIENT/PAYMENT/PAY_BILLING_REQUEST'
export const PAY_BILLING_SUCCESS = 'BILLINGS/BILLINGS_BY_CLIENT/PAYMENT/PAY_BILLING_SUCCESS'
export const PAY_BILLING_FAILURE = 'BILLINGS/BILLINGS_BY_CLIENT/PAYMENT/PAY_BILLING_FAILURE'

export interface PayBillingRequest {
  type: typeof PAY_BILLING_REQUEST
}

export interface PayBillingSuccess {
  type: typeof PAY_BILLING_SUCCESS
}

export interface PayBillingFailure {
  type: typeof PAY_BILLING_FAILURE
  payload: {
    error: string
  }
}

export type PaymentModalAction = OpenModalPayment | PayBillingRequest | PayBillingSuccess | PayBillingFailure
