import { compose, find, prop, propEq, propOr } from 'ramda'

export const getOptionValue = propOr('', 'value')

export const getUserOptionValue = propOr(undefined, 'value')

export const getLabelFromOptions = (chargeTypeID, options) =>
  compose(prop('label'), find(propEq('value', chargeTypeID)))(options)

export const formatToFilters = (a) => a.map(({ label, value }) => ({ value, text: label }))
