import {
  NextStatusTypes,
  SetCloseRemoval,
  SetDebtAmount,
  SetDebtStatus,
  ToggleCloseRemoval,
  ToggleInformPayment,
  ToggleUpdateDebtStatus
} from '../types/nextStatus'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { apiPrivate } from '../../../api'
import { AppThunk } from '../../../store'
import { CloseRemovalTypes, DebtsStatus } from '../constants'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Operation } from '../types/removals'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'

const actions = {
  toggleUpdateDebtStatusModal: (open: boolean, removalId?: number): ToggleUpdateDebtStatus => ({
    type: NextStatusTypes.TOGGLE_UPDATE_DEBT_STATUS_MODAL,
    payload: { open, removalId }
  }),
  toggleInformPaymentModal: (open: boolean, removalId?: number, debtAmount?: number): ToggleInformPayment => ({
    type: NextStatusTypes.TOGGLE_INFORM_PAYMENT_MODAL,
    payload: { open, removalId, debtAmount }
  }),
  toggleCloseRemovalModal: (open: boolean, removalId?: number): ToggleCloseRemoval => ({
    type: NextStatusTypes.TOGGLE_CLOSE_REMOVAL_MODAL,
    payload: { open, removalId }
  }),

  setDebtStatus: (status: DebtsStatus): SetDebtStatus => ({
    type: NextStatusTypes.SET_DEBT_STATUS,
    payload: { status }
  }),
  setDebtAmount: (amount: number): SetDebtAmount => ({
    type: NextStatusTypes.SET_DEBT_AMOUNT,
    payload: { amount }
  }),
  setCloseRemoval: (closeRemoval: CloseRemovalTypes): SetCloseRemoval => ({
    type: NextStatusTypes.SET_CLOSE_REMOVAL,
    payload: { closeRemoval }
  }),

  updateDebtStatus(removalId: number, debtStatus: DebtsStatus, debtAmount: number): AppThunk {
    const data = {
      debt_status: debtStatus,
      debt_amount: debtAmount
    }
    return (dispatch) => {
      dispatch({ type: NextStatusTypes.UPDATE_DEBT_STATUS_REQUEST })
      const toast = setToastLoading('Actualizando devolución. Por favor espere...')
      return apiPrivate
        .put(URL_BASE_TRANSACTIONS + `/admin/operation/removal/${removalId}/update-debt-status`, data)
        .then((response) => {
          const debtAmount: number = response.data.debt_amount
          const operation: Operation = keysToCamel(response.data.operation)
          setToastSuccessUpdate(toast, {
            render: 'Estado de la devolución actualizado correctamente.'
          })
          dispatch({
            type: NextStatusTypes.UPDATE_DEBT_STATUS_SUCCESS,
            payload: { debtAmount, operation }
          })
        })
        .catch((error) => {
          const message = handleErrorsWithAction(error, NextStatusTypes.UPDATE_DEBT_STATUS_FAILURE, dispatch)
          setToastErrorUpdate(toast, { render: message })
        })
    }
  },

  informPayment(removalId: number): AppThunk {
    return (dispatch) => {
      dispatch({ type: NextStatusTypes.INFORM_PAYMENT_REQUEST })
      const toast = setToastLoading('Actualizando devolución. Por favor espere...')
      return apiPrivate
        .put(URL_BASE_TRANSACTIONS + `/admin/operation/removal/${removalId}/inform-payment`)
        .then((response) => {
          const debtAmount: number = response.data.debt_amount
          const operation: Operation = keysToCamel(response.data.operation)
          setToastSuccessUpdate(toast, {
            render: 'Estado de la devolución actualizado correctamente.'
          })
          dispatch({
            type: NextStatusTypes.INFORM_PAYMENT_SUCCESS,
            payload: { debtAmount, operation }
          })
        })
        .catch((error) => {
          const message = handleErrorsWithAction(error, NextStatusTypes.INFORM_PAYMENT_FAILURE, dispatch)
          setToastErrorUpdate(toast, { render: message })
        })
    }
  },

  closeRemoval(removalId: number, closeRemovalType: CloseRemovalTypes): AppThunk {
    const data = {
      type: closeRemovalType
    }
    return (dispatch) => {
      dispatch({ type: NextStatusTypes.UPDATE_DEBT_STATUS_REQUEST })
      const toast = setToastLoading('Cerrando devolución. Por favor espere...')
      return apiPrivate
        .put(URL_BASE_TRANSACTIONS + `/admin/operation/removal/${removalId}/close`, data)
        .then((response) => {
          const debtAmount: number = response.data.debt_amount
          const operation: Operation = keysToCamel(response.data.operation)
          setToastSuccessUpdate(toast, {
            render: 'Devolución cerrada correctamente.'
          })
          dispatch({
            type: NextStatusTypes.UPDATE_DEBT_STATUS_SUCCESS,
            payload: { debtAmount, operation }
          })
        })
        .catch((error) => {
          const message = handleErrorsWithAction(error, NextStatusTypes.UPDATE_DEBT_STATUS_FAILURE, dispatch)
          setToastErrorUpdate(toast, { render: message })
        })
    }
  }
}

export default actions
