import { combineReducers } from 'redux'
import AddressReducer from './address'
import AddressModalReducer from './addressModal'
import DefaultAddressReducer from './defaultAddress'
import DeleteAddressReducer from './deleteAddress'

const rootReducer = combineReducers({
  address: AddressReducer,
  addressModal: AddressModalReducer,
  deleteAddress: DeleteAddressReducer,
  defaultAddress: DefaultAddressReducer
})

export default rootReducer
