import { combineReducers } from 'redux'
import tableReducer from './removals'
import nextStatusReducer from './nextStatus'
import metricsReducer from './metrics'

const rootReducer = combineReducers({
  table: tableReducer,
  nextStatus: nextStatusReducer,
  metrics: metricsReducer
})

export default rootReducer
