import { Reducer } from 'redux'
import {
  AditionalsActions,
  AditionalsInitialState,
  GET_ADDITIONAL_FLAGS_FAILURE,
  GET_ADDITIONAL_FLAGS_REQUEST,
  GET_ADDITIONAL_FLAGS_SUCCESS,
  SET_DISABLED_SHIPMENT_ASSISTANTS,
  SET_UPDATED_EXTRA_VALUE
} from '../../types/EditDepositViewTypes/additionals'
import {
  CLEAN_STATES,
  GET_TRANSACTION_DATA_FAILURE,
  GET_TRANSACTION_DATA_REQUEST,
  GET_TRANSACTION_DATA_SUCCESS
} from '../../types/EditDepositViewTypes/EditDepositView'

const initialState: AditionalsInitialState = {
  extraServices: {
    shipmentAssistants: {
      checkedAssistants: false,
      shipmentAssistantCount: 0,
      shipmentAssistantPrice: 0
    }
  },
  loading: false
}

export const AditionalsReduce: Reducer<AditionalsInitialState, AditionalsActions> = (
  state = initialState,
  action
): AditionalsInitialState => {
  switch (action.type) {
    case CLEAN_STATES:
      return initialState
    case SET_DISABLED_SHIPMENT_ASSISTANTS: {
      const { checkedAssistants: checkedAssitants } = state.extraServices.shipmentAssistants
      return {
        ...state,
        extraServices: {
          ...state.extraServices,
          shipmentAssistants: {
            ...state.extraServices.shipmentAssistants,
            checkedAssistants: !checkedAssitants
          }
        }
      }
    }
    case SET_UPDATED_EXTRA_VALUE:
      return {
        ...state,
        extraServices: {
          ...state.extraServices,
          shipmentAssistants: {
            ...state.extraServices.shipmentAssistants,
            shipmentAssistantCount: action.payload.updatedValue
          }
        }
      }
    case GET_ADDITIONAL_FLAGS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ADDITIONAL_FLAGS_SUCCESS:
      return {
        ...state,
        extraServices: {
          shipmentAssistants: {
            ...state.extraServices.shipmentAssistants,
            shipmentAssistantPrice: action.payload.extraAssistantPrice
          }
        },
        loading: false
      }
    case GET_ADDITIONAL_FLAGS_FAILURE:
      return {
        ...state,
        loading: false
      }
    case GET_TRANSACTION_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_TRANSACTION_DATA_SUCCESS: {
      return {
        ...state,
        extraServices: {
          shipmentAssistants: {
            ...state.extraServices.shipmentAssistants,
            ...action.payload.transaction.extraServices.shipmentAssistants,
            checkedAssistants: !!action.payload.transaction.extraServices.shipmentAssistants.shipmentAssistantCount
          }
        },
        loading: false
      }
    }
    case GET_TRANSACTION_DATA_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default AditionalsReduce
