export enum Weekdays {
  LUNES = 'Lunes',
  MARTES = 'Martes',
  MIERCOLES = 'Miercoles',
  JUEVES = 'Jueves',
  VIERNES = 'Viernes',
  SABADO = 'Sabado',
  DOMINGO = 'Domingo'
}

export type DiscountWeekdaysFilterKey =
  | Weekdays.LUNES
  | Weekdays.MARTES
  | Weekdays.MIERCOLES
  | Weekdays.JUEVES
  | Weekdays.VIERNES
  | Weekdays.SABADO
  | Weekdays.DOMINGO
