/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { convertToMoment } from '../../utils'
import { APIBilling, Billing } from './common'
import { SortDirection } from '../../../components/DynamicTable/types/types'

export enum BillingStatus {
  PROCESSED = 'PROCESSED',
  BILLED = 'BILLED'
}

interface APIResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  results: APIBilling[]
}

type Response = {
  billings: Billing[]
  total: number
}

interface GetBillingByTransactionParams {
  transactionId: number
  userId?: number
  status?: number
  order?: string
  orderType?: SortDirection
  authorizationEnabled?: boolean // false for provisional billings
}

export function getBillingByTransaction({
  transactionId,
  userId,
  status,
  authorizationEnabled,
  order,
  orderType
}: GetBillingByTransactionParams): Promise<Response> {
  const params = {
    transaction_id: transactionId,
    user_id: userId,
    status,
    authorization_enabled: authorizationEnabled,
    order,
    order_type: orderType?.toUpperCase()
  }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/billings/billing-by-transaction`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.results) throw new Error('Hubo un error al buscar los billings en el endpoint')
      const camelResponse: Response = {
        total: response.data.paging.total,
        billings: camelcaseKeys(response.data.results, { deep: true }).map((billing) => ({
          ...billing,
          authorizationEnabled: billing.authorizationEnabled,
          startDate: convertToMoment(billing.startDate),
          endDate: convertToMoment(billing.endDate),
          totalPrice: billing.total,
          createdAt: convertToMoment(billing.createdAt),
          objects:
            billing.details?.map((detail) => ({
              ...detail,
              startDate: convertToMoment(detail.startDate),
              endDate: convertToMoment(detail.endDate),
              proportionalPrice: detail.price,
              totalPrice: detail.total
            })) || []
        }))
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
