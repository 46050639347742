import {
  GetMetricsSuccess,
  GetProposalByIdFailure,
  GetProposalByIdSuccess,
  ProposalTypes,
  SaveDepositByProposalFailure,
  SaveDepositByProposalSuccess,
  SaveProposalFailure,
  SaveProposalSuccess,
  SetNewProposalFromScratch
} from '../types/proposals'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import newUserActions from '../../Users/actions/newUser'
import costActions from './cost'
import transportActions from './transport'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import eventEmitter from '../../../utils/eventEmitter'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'
import {
  Proposal as ProposalProspect,
  Prospect
} from '../../../projectApi/TransactionHandler/Proposals/getFromProspect'
import { Proposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import { isAnyItemSelected } from '../../../common/operations/utils'
import { TotalPrices } from '../containers/Summary'
import newProposal from '../reducers'
import { CreateProposalRequest, Item } from '../../../projectApi/TransactionHandler/Proposals/create'
import { AddressState } from '../../../components/Address/reducer'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { Events } from '../../../utils/eventEmitter/events'
import { ProposalMetrics } from '../../../projectApi/TransactionHandler/Proposals/metrics'
import { PAYMENT_BY_VALUES, PRICE_BY_VALUES } from '../../../common/operations/constants'
import { CreateDepositRequest } from '../../../projectApi/TransactionHandler/Deposits/createOP'
import { CBUState } from '../../../components/CBU/types'
import { formatItemsForDeposit } from '../../../common/operations/selectors/items'
import moment from 'moment/moment'
import { removeCBUSpaces } from '../../../utils/cbuChecker'
import snakecaseKeys from 'snakecase-keys'
import { TransportTypesPricing } from '../constants'

const calculateWithCents = (value: number): number => Math.round(value * 100)

const getFormattedProposalRequest = (
  totals: TotalPrices,
  newProposalReducer: ReturnType<typeof newProposal>,
  addressReducer: AddressState
): CreateProposalRequest => {
  const { cost, flags, extras, items, transport, prospect } = newProposalReducer
  const { address } = addressReducer

  const { extraAssistant, additionalCost } = extras
  const { serviceType, selectedTransport, selectedDeposit, transportTypePricing } = transport
  const { m3Price, extraAssistantPrice, tollCostInCents } = flags
  const { discountCode } = cost

  const formattedItems = items.selectedItems.reduce<Item[]>((acc, item): Item[] => {
    if (!item.selectedItem) return acc

    const { selectedItem, quantity, floors, disassemble, packaging } = item

    return [
      ...acc,
      {
        productId: selectedItem.id,
        priceInCents: calculateWithCents(selectedItem.price),
        amount: quantity,
        name: selectedItem.description,
        lengthInCm: selectedItem.lengthInCm,
        widthInCm: selectedItem.widthInCm,
        heightInCm: selectedItem.heightInCm,
        weightInGr: selectedItem.weightInGr,
        packagingCostInCents: packaging ? selectedItem.packagingCostInCents : 0,
        disassemblyCost: disassemble ? selectedItem.disassemblyCost : 0,
        costPerFloorInCents: selectedItem.depositFloorsByStairsCostInCents * floors
      }
    ]
  }, [])

  const { spaceCostDiscounted, transportCostDiscounted, disassembleDiscounted, packagingDiscounted, floorsDiscounted } =
    totals.pricesWithDiscount

  return {
    serviceTypeId: serviceType,
    origin: String(prospect.selectedProspect?.sourceId) || '',
    prospectId: prospect.selectedProspect?.id || 0,
    cubicMPriceInCents: calculateWithCents(m3Price),
    packagingCostInCents: calculateWithCents(packagingDiscounted),
    transportCostInCents: calculateWithCents(transportCostDiscounted),
    tollCostInCents,
    costPerFloorInCents: calculateWithCents(floorsDiscounted),
    disassemblyCost: disassembleDiscounted,
    storageCostInCents: calculateWithCents(spaceCostDiscounted),
    transportModeId: selectedTransport,
    depositId: selectedDeposit,
    peopleId: prospect.selectedProspect?.peopleId || 0,
    countryId: prospect.selectedProspect?.countryCode || CountryIdCode.ARGENTINA,
    shipmentAssistantCount: extraAssistant.count,
    shipmentAssistantPrice: extraAssistantPrice,
    // TODO review with backend if it should have decimals
    totalAmount: Math.round(totals.total),
    transportType: transportTypePricing,
    items: formattedItems,
    detail: {
      transportCost: transport.transportDetail
    },
    address: {
      street: address.street,
      number: address.number,
      floor: address.floor,
      apartment: address.apartment,
      postalCode: parseInt(address.postalCode),
      city: address.city,
      province: address.province,
      addressString: address.addressGoogle
    },
    notify: {
      notify: false,
      email: ''
    },
    ...(discountCode && { discountCode }),
    ...(additionalCost.enabled && {
      additionalCostInCents: calculateWithCents(additionalCost.amount),
      additionalCostComment: additionalCost.comment
    })
  }
}

const getFormattedDepositRequest = (
  totals: TotalPrices,
  newProposalReducer: ReturnType<typeof newProposal>,
  addressReducer: AddressState,
  cbuReducer: CBUState,
  userReducer: any
): CreateDepositRequest => {
  const { calendar, cost, flags, extras, items, transport, payment, prospect, proposals } = newProposalReducer
  const { address } = addressReducer
  const { timeslots, unavailableDates, timeIntervals } = calendar
  const { hour, minute } = timeslots.list.find((t) => t.id === timeslots.selected) || { hour: 0, minute: 0 }

  const { extraAssistant, additionalCost } = extras
  const {
    serviceType,
    selectedTransport,
    selectedDeposit,
    transportTypePricing,
    requireAddressOrigin,
    transportDetail
  } = transport
  const { extraAssistantPrice, tollCostInCents } = flags
  const { discountCode } = cost

  const { totalForPackaging, totalForDisassemble, spaceCost, transportCost } = totals

  const { email, phone, docType, dni, landline, dateBirth, gender, name, lastName, countryCode } = userReducer
  const leadCreationDate = moment(prospect.selectedProspect?.createdAt).format('YYYY-MM-DD')

  return {
    serviceTypeId: serviceType,
    totalWeight: String(totals.totalWeight * 1000),
    items: JSON.stringify(formatItemsForDeposit(items.selectedItems)),
    detail: JSON.stringify(
      transportDetail && { transport_cost: snakecaseKeys({ ...transportDetail }, { deep: true }) }
    ),
    packagingCost: String(Math.round(totalForPackaging)),
    disassemblyCost: String(Math.round(totalForDisassemble)),
    costPerFloorInCents: String(Math.round(totals.totalForFloors * 100)),
    prospectId: String(prospect.selectedProspect?.id),
    proposalId: String(proposals.selectedProposal?.id),
    firstName: name,
    lastName,
    email,
    phone,
    identificationType: docType,
    dni,
    landline,
    countryCode,
    leadCreationDate,
    gender,
    timeSlotId: String(timeslots.selected),
    timeInterval: String(timeIntervals.selected),
    date: String(unavailableDates.selected.set('hour', hour).set('minute', minute).format('X')),
    transportCost: String(transportCost),
    tollCostInCents: transportCost ? proposals.selectedProposal?.tollCostInCents || tollCostInCents : 0,
    storageCost: String(spaceCost),
    transportModeTypeId: String(selectedTransport),
    depositId: String(selectedDeposit),
    peopleId: String(prospect.selectedProspect?.peopleId),
    shipmentAssistantCount: String(extraAssistant.count),
    shipmentAssistantPrice: String(extraAssistantPrice),
    transportTypePricing: requireAddressOrigin ? transportTypePricing : TransportTypesPricing.FREE,
    paymentType: payment.paymentBy,
    ...(dateBirth && { dateOfBirth: dateBirth.format('YYYY-MM-DD') }),
    ...(additionalCost.enabled && {
      totalAdditionalCost: String(additionalCost.amount),
      additionalCostComment: additionalCost.comment
    }),
    ...(requireAddressOrigin && {
      addressStreet: address.street,
      addressNumber: address.number,
      addressFloor: address.floor,
      addressApartment: address.apartment,
      addressPostalCode: address.postalCode,
      addressCity: address.city,
      addressProvince: address.province,
      addressString: address.addressGoogle
    }),
    ...(payment.paymentBy === PAYMENT_BY_VALUES.CBU && {
      cbu: removeCBUSpaces(cbuReducer.cbu),
      cbuHolder: cbuReducer.holderName
    }),
    ...(discountCode && {
      discountCode,
      applyDiscount: String(true)
    })
  }
}

const actions = {
  getProposalsFromProspect: (prospectID: number, statusID = 0, recalculatePrice = true): AppThunk => {
    return async function (dispatch) {
      dispatch({
        type: ProposalTypes.GET_PROPOSALS_REQUEST,
        payload: {
          statusID,
          openModal: statusID === 0
        }
      })
      try {
        const { prospect, proposal } = await API.TransactionHandler.Proposals.getFromProspect(prospectID, {
          statusID,
          recalculatePrice
        })

        if (statusID === 0) {
          if (prospect.email) dispatch(newUserActions.verifyIfEmailExists(prospect.email, true))
        }
        if (proposal.length > 0) {
          const lastProposal = proposal[0]
          dispatch(actions.getProposal(lastProposal.id, prospect.countryCode))
        } else {
          dispatch(transportActions.getServiceTypes(prospect.countryCode))
        }
        dispatch(actions.getProposalsFromProspectSuccess(prospect, proposal))
      } catch (e) {
        handleErrorsWithAction(e, ProposalTypes.GET_PROPOSALS_FAILURE, dispatch)
      }
    }
  },

  getProposalsFromProspectSuccess: (prospect: Prospect, proposals: ProposalProspect[]) => ({
    type: ProposalTypes.GET_PROPOSALS_SUCCESS,
    payload: {
      prospect,
      proposals
    }
  }),

  getProposalsFromProspectError: (message: string) => ({
    type: ProposalTypes.GET_PROPOSALS_FAILURE,
    payload: {
      error: message
    }
  }),

  getProposal: (proposalID: number, countryCode = CountryIdCode.ARGENTINA, addEmptyObject = true): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: ProposalTypes.GET_PROPOSAL_BY_ID_REQUEST })

      try {
        const proposal = await API.TransactionHandler.Proposals.getById({
          proposalId: proposalID,
          recalculatePrice: false,
          countryCode
        })
        const { discountCode } = proposal
        if (discountCode) {
          dispatch(costActions.setDiscountCode(discountCode))
          dispatch(costActions.fetchDiscount(discountCode))
        }
        dispatch(actions.getProposalSuccess(proposal, addEmptyObject))
        dispatch(transportActions.getTransportModesAndDepositsFromProposal(proposal))
      } catch (error) {
        handleErrorsWithAction(error, ProposalTypes.GET_PROPOSAL_BY_ID_FAILURE, dispatch)
      }
    }
  },

  getProposalSuccess: (proposal: Proposal, addEmptyObject: boolean): GetProposalByIdSuccess => ({
    type: ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS,
    payload: { proposal, addEmptyObject }
  }),

  getProposalError: (message: string): GetProposalByIdFailure => ({
    type: ProposalTypes.GET_PROPOSAL_BY_ID_FAILURE,
    payload: {
      error: message
    }
  }),

  saveProposal: (
    totals: TotalPrices,
    newProposalReducer: ReturnType<typeof newProposal>,
    addressReducer: AddressState
  ): AppThunk => {
    return async function (dispatch) {
      if (!isAnyItemSelected(newProposalReducer.items.selectedItems)) {
        dispatch(actions.saveProposalError('No se seleccionaron Items'))
        return Promise.resolve(false)
      }

      dispatch({ type: ProposalTypes.SAVE_PROPOSAL_REQUEST })
      const toastId = setToastLoading('Guardando cotización, por favor espere...')

      const config = {
        headers: {
          'X-Request-Origin': 'backoffice|proposal'
        },
        params: {
          country_code: newProposalReducer.prospect.selectedProspect?.countryCode
        }
      }

      const request = getFormattedProposalRequest(totals, newProposalReducer, addressReducer)
      try {
        const response = await API.TransactionHandler.Proposals.create(request, config)
        setToastSuccessUpdate(toastId, { render: 'Cotización guardada con éxito' })
        dispatch(actions.saveProposalSuccess())
        eventEmitter.emit(Events.Proposal.SAVE_PROPOSAL_SUCCESS, { proposalId: response.proposalId })
      } catch (error) {
        handleErrorsWithAction(error, ProposalTypes.SAVE_PROPOSAL_FAILURE, dispatch)
        setToastErrorUpdate(toastId, { render: 'Error al guardar la cotización' })
      }
    }
  },

  saveProposalSuccess: (): SaveProposalSuccess => ({
    type: ProposalTypes.SAVE_PROPOSAL_SUCCESS
  }),

  saveProposalError: (message: string): SaveProposalFailure => ({
    type: ProposalTypes.SAVE_PROPOSAL_FAILURE,
    payload: {
      error: message
    }
  }),

  setNewProposalFromScratch: (): SetNewProposalFromScratch => ({
    type: ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH
  }),

  getMetrics: (peopleId: number): AppThunk => {
    return async function (dispatch) {
      dispatch({ type: ProposalTypes.GET_METRICS_REQUEST })
      try {
        const metrics = await API.TransactionHandler.Proposals.metrics({ peopleId })
        dispatch(actions.getMetricsSuccess(metrics))
      } catch (error) {
        handleErrorsWithAction(error, ProposalTypes.GET_METRICS_FAILURE, dispatch)
      }
    }
  },

  getMetricsSuccess: (metrics: ProposalMetrics): GetMetricsSuccess => ({
    type: ProposalTypes.GET_METRICS_SUCCESS,
    payload: { metrics }
  }),

  saveDepositByProposal: (
    totals: TotalPrices,
    newProposalReducer: ReturnType<typeof newProposal>,
    addressReducer: AddressState,
    cbuReducer: CBUState,
    userReducer: any
  ): AppThunk => {
    return async function (dispatch, getState) {
      const state = getState()

      if (
        newProposalReducer.transport.serviceType === PRICE_BY_VALUES.ITEMS &&
        !isAnyItemSelected(newProposalReducer.items.selectedItems)
      ) {
        dispatch(actions.saveDepositByProposalError('No se seleccionaron Items'))
        return Promise.resolve(false)
      }

      dispatch({ type: ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_REQUEST })

      const toastId = setToastLoading('Guardando ingreso, por favor espere...')

      const config = {
        headers: {
          'X-Request-Origin': 'backoffice|proposal'
        },
        params: {
          country_code: state.Users.newUser.countryCode
        }
      }
      const request = getFormattedDepositRequest(totals, newProposalReducer, addressReducer, cbuReducer, userReducer)

      try {
        await API.TransactionHandler.Deposits.createOP(request, config)
        dispatch(actions.saveDepositByProposalSuccess())
        setToastSuccessUpdate(toastId, { render: 'Ingreso guardado con éxito' })
        eventEmitter.emit(Events.Proposal.SAVE_DEPOSIT_SUCCESS)
      } catch (e) {
        handleErrorsWithAction(e, ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_FAILURE, dispatch)
        setToastErrorUpdate(toastId, { render: 'Error al guardar el ingreso' })
      }
    }
  },

  saveDepositByProposalSuccess: (): SaveDepositByProposalSuccess => ({
    type: ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_SUCCESS
  }),

  saveDepositByProposalError: (message: string): SaveDepositByProposalFailure => ({
    type: ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_FAILURE,
    payload: {
      error: message
    }
  })
}

export default actions
