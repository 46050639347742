import React from 'react'
import DiscountTable from './table.tsx'

const Discount = (props) => {
  return (
    <div className="animated fadeIn">
      <DiscountTable {...props} />
    </div>
  )
}

export default Discount
