import { CountrySelectorTypes, GetCountriesRequest, GetCountriesSuccess } from '../types/CountrySelector'
import Emitter from '../../../utils/eventEmitter'
import { Country } from '../constants/constants'
import { AppThunk } from '../../../store'
import { Events } from '../../../utils/eventEmitter/events'
import { addCountryParam } from '../../../api'
import { API } from '../../../projectApi'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { CountryResponse } from '../../../projectApi/AccountManager/Countries/getAll'
import { formatCountryResponse } from '../utils/utils'

const actions = {
  getCountries: (): AppThunk => async (dispatch) => {
    const request: GetCountriesRequest = {
      type: CountrySelectorTypes.GET_COUNTRIES_REQUEST
    }

    dispatch(request)

    try {
      const countriesResponse: CountryResponse[] = await API.AccountManager.Countries.getAll()
      const countries = formatCountryResponse(countriesResponse)

      const success: GetCountriesSuccess = {
        type: CountrySelectorTypes.GET_COUNTRIES_SUCCESS,
        payload: {
          countries
        }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, CountrySelectorTypes.GET_COUNTRIES_FAILURE, dispatch)
    }
  },
  SelectCountryFilter:
    (value: Country): AppThunk =>
    (dispatch) => {
      dispatch({ type: CountrySelectorTypes.SELECT_COUNTRY_FILTER, payload: { value } })
      addCountryParam(value.code)
      Emitter.emit(Events.Global.CHANGE_COUNTRY, value)
    }
}

export default actions
