import React, { Component } from 'react'
import CategoriesTable from './CategoriesTable'
import AddPanel from './AddPanel'
import AddButton from './AddButton'
import EditPanel from './EditPanel'
import DeletePanel from './DeletePanel'
import { URL_BASE_CATEGORIES } from '../../endpoints'
import { apiPrivate } from '../../api'

const initialState = {
  addOpen: false,
  addParentID: -1,
  addName: '',

  deleteOpen: false,
  deleteID: -1,
  renderAgain: false,

  editOpen: false,
  editID: -1,
  editDescription: '',
  editPhotoURL: '',

  categories: [],
  categoryTree: []
}

class Products extends Component {
  constructor() {
    super()
    this.state = initialState
    this.showAddPanel = this.showAddPanel.bind(this)
    this.hideAddPanel = this.hideAddPanel.bind(this)
    this.showEditPanel = this.showEditPanel.bind(this)
    this.hideEditPanel = this.hideEditPanel.bind(this)
    this.showDeletePanel = this.showDeletePanel.bind(this)
    this.hideDeletePanel = this.hideDeletePanel.bind(this)

    this.changeName = this.changeName.bind(this)
    this.changeEditDescription = this.changeEditDescription.bind(this)
    this.renderAgain = this.renderAgain.bind(this)

    this.getCategoryTree = this.getCategoryTree.bind(this)
    this.getCategoryTree()
  }

  formatCategories(tree) {
    for (let i = 0; i < tree.length; i++) {
      tree[i].children = tree[i].Children
      tree[i].Children = null
      tree[i].id = tree[i].ID
      tree[i].ID = null
      tree[i].parentId = -1
      tree[i].key = tree[i].id

      if (tree[i].children != null && tree[i].children.length > 0) {
        const children1 = tree[i].children
        for (let j = 0; j < children1.length; j++) {
          children1[j].children = children1[j].Children
          children1[j].Children = null
          children1[j].id = children1[j].ID
          children1[j].ID = null
          children1[j].parentId = tree[i].id
          children1[j].key = children1[j].id

          if (children1[j].children != null && children1[j].children.length > 0) {
            const children2 = children1[j].children

            for (let k = 0; k < children2.length; k++) {
              children2[k].children = children2[k].Children
              children2[k].Children = null
              children2[k].id = children2[k].ID
              children2[k].ID = null
              children2[k].parentId = children1[j].id
              children2[k].key = children2[k].id

              if (children2[k].children != null && children2[k].children.length > 0) {
                const children3 = children2[k].children
                for (let l = 0; l < children3.length; l++) {
                  children3[l].children = children3[l].Children
                  children3[l].Children = null
                  children3[l].id = children3[l].ID
                  children3[l].ID = null
                  children3[l].parentId = children2[k].id
                  children3[l].key = children3[l].id

                  if (children3[l].children != null && children3[l].children.length > 0) {
                    const children4 = children3[l].children
                    for (let m = 0; m < children4.length; m++) {
                      children4[m].children = children4[m].Children
                      children4[m].Children = null
                      children4[m].id = children4[m].ID
                      children4[m].ID = null
                      children4[m].parentId = children3[l].id
                      children4[m].key = children4[m].id

                      if (children4[m].children != null && children4[m].children.length > 0) {
                        const children5 = children4[m].children
                        for (let n = 0; n < children5.length; n++) {
                          children5[n].children = children5[n].Children
                          children5[n].Children = null
                          children5[n].id = children5[n].ID
                          children5[n].ID = null
                          children5[n].parentId = children4[m].id
                          children5[n].key = children5[n].id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return tree
  }

  getCategoryTree() {
    return apiPrivate
      .get(URL_BASE_CATEGORIES + '/category?all=true&Column=ID&Order=desc')
      .then((response) => {
        const formattedData = this.formatCategories(response.data.description)

        this.setState({
          categoryTree: formattedData
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert('La sesión ha expirado')
          window.location.assign('/#/login')
        }
      })
  }

  showAddPanel(parentID) {
    this.setState({
      addOpen: true,
      addParentID: parentID,
      renderAgain: false
    })
  }

  hideAddPanel() {
    this.setState({
      addOpen: false,
      addParentID: -1,
      addName: '',
      renderAgain: false
    })
    this.getCategoryTree()
  }

  showEditPanel(id, description, photoURL) {
    this.setState({
      editOpen: true,
      editID: id,
      editDescription: description,
      editPhotoURL: photoURL,
      renderAgain: false
    })
  }

  hideEditPanel() {
    this.setState({
      editOpen: false,
      editID: -1,
      editDescription: '',
      editPhotoURL: '',
      renderAgain: false
    })
    this.getCategoryTree()
  }

  showDeletePanel(id) {
    this.setState({
      deleteOpen: true,
      deleteID: id,
      renderAgain: false
    })
  }

  hideDeletePanel() {
    this.setState({
      deleteOpen: false,
      deleteID: -1,
      renderAgain: false
    })
    this.getCategoryTree()
  }

  changeName(value) {
    this.setState({
      addName: value
    })
  }

  changeEditDescription(value) {
    this.setState({
      editDescription: value
    })
  }

  renderAgain() {
    this.setState({
      renderAgain: true
    })
  }

  render() {
    return (
      <div className="animated fadeIn">
        <AddButton renderAgain={this.getCategoryTree} />

        <CategoriesTable
          showAddPanel={this.showAddPanel}
          showEditPanel={this.showEditPanel}
          showDeletePanel={this.showDeletePanel}
          categoryTree={this.state.categoryTree}
          renderAgain={this.state.editOpen}
          renderAgain2={this.state.deleteOpen}
          renderAgain3={this.state.renderAgain}
        />

        <AddPanel
          open={this.state.addOpen}
          parentID={this.state.addParentID}
          description={this.state.addName}
          handleClose={this.hideAddPanel}
          changeName={this.changeName}
        />

        <EditPanel
          open={this.state.editOpen}
          id={this.state.editID}
          description={this.state.editDescription}
          photoURL={this.state.editPhotoURL}
          handleClose={this.hideEditPanel}
          changeName={this.changeEditDescription}
        />

        <DeletePanel open={this.state.deleteOpen} id={this.state.deleteID} handleClose={this.hideDeletePanel} />
      </div>
    )
  }
}

export default Products
