import { Moment } from 'moment'
import { AppThunk } from '../../../store'
import {
  GetRemovalValidationMetricsRequest,
  GetRemovalValidationMetricsSuccess,
  GetRemovalValidationMetricsTypes
} from '../types/metrics'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { sendToastNotificationError } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import { mapOfMetrics } from '../component/ValidationMetrics'

const removalValidationMetricsActionsCreator = {
  getMetrics:
    ({ dateFrom, dateTo }: { dateFrom?: Moment; dateTo?: Moment }): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalValidationMetricsRequest = {
        type: GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_REQUEST
      }

      dispatch(request)
      try {
        const response = await API.TransactionHandler.Operation.Removals.validationMetrics({
          dateFrom,
          dateTo
        })

        const quantityMetrics = Object.entries(response).map(([key, value]) => ({
          id: key,
          metric: mapOfMetrics[key],
          quantity: value
        }))

        const success: GetRemovalValidationMetricsSuccess = {
          type: GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_SUCCESS,
          payload: { quantityMetrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(
          error,
          GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_FAILURE,
          dispatch
        )
      }
    }
}

export default removalValidationMetricsActionsCreator
