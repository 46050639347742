import React from 'react'

const Camera = ({ className }: { className?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M2.8125 4.35938H5.20312L5.77266 2.76328C5.812 2.65407 5.88411 2.55966 5.97912 2.49296C6.07414 2.42626 6.18743 2.39052 6.30352 2.39063H11.6965C11.9338 2.39063 12.1465 2.54004 12.2256 2.76328L12.7969 4.35938H15.1875C15.9645 4.35938 16.5938 4.98867 16.5938 5.76563V13.7812C16.5938 14.5582 15.9645 15.1875 15.1875 15.1875H2.8125C2.03555 15.1875 1.40625 14.5582 1.40625 13.7812V5.76563C1.40625 4.98867 2.03555 4.35938 2.8125 4.35938ZM2.67188 13.7812C2.67188 13.8586 2.73516 13.9219 2.8125 13.9219H15.1875C15.2648 13.9219 15.3281 13.8586 15.3281 13.7812V5.76563C15.3281 5.68828 15.2648 5.625 15.1875 5.625H11.9057L11.6051 4.78477L11.2025 3.65625H6.79922L6.39668 4.78477L6.09609 5.625H2.8125C2.73516 5.625 2.67188 5.68828 2.67188 5.76563V13.7812ZM9 6.75C10.5539 6.75 11.8125 8.00859 11.8125 9.5625C11.8125 11.1164 10.5539 12.375 9 12.375C7.44609 12.375 6.1875 11.1164 6.1875 9.5625C6.1875 8.00859 7.44609 6.75 9 6.75ZM9 11.25C9.93164 11.25 10.6875 10.4941 10.6875 9.5625C10.6875 8.63086 9.93164 7.875 9 7.875C8.06836 7.875 7.3125 8.63086 7.3125 9.5625C7.3125 10.4941 8.06836 11.25 9 11.25Z"
        fill="#595959"
      />
    </svg>
  )
}

export default Camera
