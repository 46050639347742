import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { APIRole, Role } from '../../AccountManager/Users/common'

type APIPickuper = {
  GUID: string
  ID: number
  LastName: string
  Name: string
  Role: APIRole
}

interface APIResponse {
  description: {
    pickupers: APIPickuper[]
    quantity: number
  }
  success: boolean
}

export type Operator = {
  guid: string
  id: number
  lastName: string
  name: string
  role?: Role
}

type Response = {
  operators: Operator[]
  total: number
}

interface ListParams {
  limit?: number
  offset?: number
}

export function list({ limit, offset }: ListParams): Promise<Response> {
  const params = { Limit: limit, Offset: offset }
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/pickupers`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.success || !response.data.description.pickupers)
        throw new Error('Respuesta incorrecta al obtener los viajes')

      const camelResponse: Response = {
        total: response.data.description.quantity,
        operators: camelcaseKeys(response.data.description.pickupers, { deep: true })
      }
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
