import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router'
import prospectActions from './actions/prospect'
import proposalActions from './actions/proposals'
import addressActions from '../../components/Address/actions'
import identificationTypesActions from '../Users/actions/identificationTypes'
import { PROPOSAL_MODES } from '../../common/operations/constants'
import { CountryIdCode } from '../../components/CountrySelector/constants/constants'

export type QueryParams = {
  mode: string
  isNewProposal: string
}

const Container = () => {
  const dispatch = useDispatch()

  const { prospectID, proposalID } = useParams<{ prospectID: string; proposalID: string }>()

  const parsedProspectID = parseInt(prospectID)
  const parsedProposalID = parseInt(proposalID)

  const history = useHistory()
  const prospectReducer = useSelector((state) => state.NewProposal.prospect)
  const proposalsReducer = useSelector((state) => state.NewProposal.proposals)
  const identificationTypesReducer = useSelector((state) => state.Users.identificationTypes)

  const getInitialProspect = bindActionCreators(prospectActions.getInitialProspect, dispatch)
  const prospect = prospectReducer.selectedProspect

  const { mode, isNewProposal } = queryString.parse(history.location.search) as QueryParams

  useEffect(() => {
    if (mode === PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL && !identificationTypesReducer.fetched) {
      dispatch(identificationTypesActions.getDocumentTypes())
    }
  }, [mode])

  useEffect(() => {
    getInitialProspect(parsedProspectID)
    dispatch(addressActions.cleanAddress())
  }, [])

  useEffect(() => {
    const statusId = mode === PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL ? 0 : 1
    if (prospect) {
      dispatch(proposalActions.getMetrics(prospect.peopleId))
      if (mode !== PROPOSAL_MODES.VIEW_PROPOSAL)
        dispatch(proposalActions.getProposalsFromProspect(parsedProspectID, statusId))
    }
  }, [prospect])

  useEffect(() => {
    if (mode === PROPOSAL_MODES.VIEW_PROPOSAL && prospect) {
      dispatch(proposalActions.getProposal(parsedProposalID, prospect?.countryCode as CountryIdCode, false))
    }
  }, [prospect, mode])

  return (
    <Component
      mode={mode}
      isNewProposal={isNewProposal}
      proposalsReducer={proposalsReducer}
      prospectReducer={prospectReducer}
    />
  )
}

export default Container
