import { forEachObjIndexed } from 'ramda'

export const getNewFilterParams = (params, filters) => {
  let formatFilters = {}
  const Offset = 0
  forEachObjIndexed((value, key) => {
    formatFilters = { ...formatFilters, [key]: value[0] }
  }, filters)
  return { ...params, ...formatFilters, Offset }
}
