import React, { NamedExoticComponent, ReactNode } from 'react'
import styles from './QuantityCard.module.scss'
import { Spin } from 'antd'

interface QuantityCardCompound {
  Cell: typeof Cell
  Title: typeof Title
  MainContent: typeof MainContent
  Type: typeof Type
}

type BaseQuantityCardProps = {
  children: React.ReactNode
  fetchingMetrics: boolean
}

const BaseQuantityCard: React.FC<BaseQuantityCardProps> = ({ children, fetchingMetrics }) => {
  if (!children) return null
  if (children && !Array.isArray(children)) return <article className={styles.card}>{children}</article>
  return (
    <article className={styles.card}>
      {fetchingMetrics ? (
        <div className={styles.spinContainer}>
          <Spin size="default" />
        </div>
      ) : (
        children
      )}
    </article>
  )
}

const Cell: React.FC<{
  children?: ReactNode
}> = ({ children }) => {
  if (!children) return null

  return <div className={styles.cell}>{children}</div>
}

const Title: React.FC<{ titleCard?: string }> = ({ titleCard }) => {
  return (
    <>
      <p className={styles.title}>{titleCard}</p>
    </>
  )
}

const MainContent: React.FC<{ content?: string | number }> = ({ content }) => {
  return <p className={styles.mainContent}>{content}</p>
}

const Type: React.FC<{ title: string }> = ({ title }) => {
  return <p className={styles.type}>{title}</p>
}

const MemoizedQuantityCard = React.memo(BaseQuantityCard) as NamedExoticComponent<BaseQuantityCardProps> &
  QuantityCardCompound

MemoizedQuantityCard.Cell = Cell
MemoizedQuantityCard.Title = Title
MemoizedQuantityCard.MainContent = MainContent
MemoizedQuantityCard.Type = Type

export const QuantityCard = MemoizedQuantityCard
