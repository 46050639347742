export enum ComplaintsEventsEnum {
  COMPLAINTS_CREATED = 'COMPLAINTS/COMPLAINTS_CREATED',
  COMPLAINTS_UPDATED = 'COMPLAINTS/COMPLAINTS_UPDATED',
  COMPLAINTS_DELETED = 'COMPLAINTS/COMPLAINTS_DELETED',
  RESETED_FILTERS = 'LOCATION/RESETED_FILTERS'
}

export type ComplaintsEvents = {
  [ComplaintsEventsEnum.COMPLAINTS_CREATED]: () => void
  [ComplaintsEventsEnum.COMPLAINTS_UPDATED]: () => void
  [ComplaintsEventsEnum.COMPLAINTS_DELETED]: () => void
  [ComplaintsEventsEnum.RESETED_FILTERS]: () => void
}
