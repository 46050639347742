import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import OperationDaysActionCreator from '../actions/operationDaysAction'
import EditOperationDayModal from '../components/EditOperationDayModal'
import OperationCalendarActionsCreator from '../actions/common'

const EditDayModalContainer = () => {
  const dispatch = useDispatch()
  const { isEditModalOpen, selectedDay } = useSelector((state) => state.OperationCalendar.Days)

  const { availableOperations, selectedOperations } = useSelector((state) => state.OperationCalendar.Common)

  const { openEditDayModal, updateOperation } = bindActionCreators(OperationDaysActionCreator, dispatch)
  const { setAvailableOperations, setSelectedOperations } = bindActionCreators(
    OperationCalendarActionsCreator,
    dispatch
  )

  const props = {
    isEditModalOpen,
    openEditDayModal,
    selectedDay,
    updateOperation,
    availableOperations,
    setSelectedOperations,
    selectedOperations,
    setAvailableOperations
  }

  return <EditOperationDayModal {...props} />
}

export default EditDayModalContainer
