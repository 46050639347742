import { ResponseLeadHistory } from '../../../projectApi/Mercurio/Prospects/getHistory'

export interface LeadHistoryState {
  leadHistory: ResponseLeadHistory[]
  fetchingLead: boolean
  processLastLead: boolean
}

export const GET_LEAD_HISTORY_REQUEST = 'LEADS/LEADS_HISTORY/GET_LEAD_HISTORY_REQUEST'
export const GET_LEAD_HISTORY_SUCCESS = 'LEADS/LEADS_HISTORY/GET_LEAD_HISTORY_SUCCESS'
export const GET_LEAD_HISTORY_FAILURE = 'LEADS/LEADS_HISTORY/GET_LEAD_HISTORY_FAILURE'

export interface GetLeadHistoryRequest {
  type: typeof GET_LEAD_HISTORY_REQUEST
}

export interface GetLeadHistorySuccess {
  type: typeof GET_LEAD_HISTORY_SUCCESS
  payload: {
    leadHistory: ResponseLeadHistory[]
  }
}

export interface GetLeadHistoryFailure {
  type: typeof GET_LEAD_HISTORY_FAILURE
}

export type GetLeadHistoryAction = GetLeadHistoryRequest | GetLeadHistorySuccess | GetLeadHistoryFailure

export const GET_LAST_CHANGE_LEAD_REQUEST = 'LEADS/LEADS_HISTORY/GET_LAST_CHANGE_LEAD_REQUEST'
export const GET_LAST_CHANGE_LEAD_SUCCESS = 'LEADS/LEADS_HISTORY/GET_LAST_CHANGE_LEAD_SUCCESS'
export const GET_LAST_CHANGE_LEAD_FAILURE = 'LEADS/LEADS_HISTORY/GET_LAST_CHANGE_LEAD_FAILURE'

export interface GetLastChangeLeadRequest {
  type: typeof GET_LAST_CHANGE_LEAD_REQUEST
  payload: {
    lead: ResponseLeadHistory
  }
}

export interface GetLastChangeLeadSuccess {
  type: typeof GET_LAST_CHANGE_LEAD_SUCCESS
  payload: {
    lead: ResponseLeadHistory
  }
}

export interface GetLastChangeLeadFailure {
  type: typeof GET_LAST_CHANGE_LEAD_FAILURE
}

export type GetLasChangeLeadAction = GetLastChangeLeadRequest | GetLastChangeLeadSuccess | GetLastChangeLeadFailure

export type LeadHistoryAction = GetLeadHistoryAction | GetLasChangeLeadAction
