import React, { useEffect, useMemo } from 'react'
import { Modal } from 'antd'
import developmentActions from '../actions/developmentView'
import { DevelopmentViewState } from '../types/developmentView'
import ColInfoContainer from '../../../components/ColInfoContainer/ColInfoContainer'
import ColInfo from '../../../components/ColInfo/ColInfo'
import Select from '../../../components/Select'
import { TextField } from '../../../components/TextField/TextField'
import { BaseOption } from '../../../components/Select/Select'
import styles from './developmentView.module.scss'

type ObjectLabelModalProps = {
  developmentActions: typeof developmentActions
  DevelopmentViewState: DevelopmentViewState
}

type Option = BaseOption<number>

const ObjectLabelModal = (props: ObjectLabelModalProps) => {
  const { developmentActions, DevelopmentViewState } = props
  const { setObjectLabelModal, getUsers, createGenericObjectLabels, clearState } = developmentActions
  const { error, isLoading, objectLabel } = DevelopmentViewState
  const { objectLabelModal, users, isLoadingUsers, codeGEN } = objectLabel
  const [userSelected, setUserSelected] = React.useState<Option | null>(null)
  const [amount, setAmount] = React.useState<number | null>(0)

  const [autocomplete, setAutocomplete] = React.useState('')

  const usersOptions: Option[] = useMemo(() => {
    return users.map((user) => ({
      label: `${user.realId} - ${user.name} ${user.lastName}`,
      value: user.realId
    }))
  }, [users])

  useEffect(() => {
    if (objectLabelModal) {
      developmentActions.getCodeGEN()
    }
  }, [objectLabelModal])

  const handleOnOkClick = async () => {
    if (!userSelected || !amount || !codeGEN) return
    await createGenericObjectLabels({
      identificationCode: codeGEN,
      ownerId: userSelected.value,
      quantityIdentificationCodes: amount
    })
    setAutocomplete('')
    setAmount(0)
    setUserSelected(null)
  }

  const onClose = () => {
    setObjectLabelModal(false)
    clearState()
    setAutocomplete('')
    setAmount(0)
    setUserSelected(null)
  }

  const RowInfo = {
    label: 'Nueva etiqueta:',
    value: codeGEN
  }

  const onUserInputChange = (id: string) => {
    if (id.length < 2 && id.length > 0) {
      getUsers()
    } else {
      getUsers(parseInt(id))
    }
  }

  const handleTextInput = (value: number) => {
    if (isNaN(Number(value))) return
    if (Number(value) < 0 || Number(value) > 25) return
    setAmount(value)
  }

  return (
    <Modal
      width={750}
      title={'Crear etiquetas genéricas'}
      visible={objectLabelModal}
      closable={true}
      keyboard={false}
      onOk={handleOnOkClick}
      onCancel={onClose}
      okButtonProps={{
        loading: isLoading,
        disabled: isLoading || !userSelected || !amount || amount < 1 || amount > 25,
        className: styles.modalButtonOk
      }}
      okText={'Crear etiquetas'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <ColInfoContainer loading={isLoading} blue>
        <ColInfo {...RowInfo} blue />

        <div className={styles.containerModal}>
          <p className={styles.itemTitle}>Buscar un usuario por id:</p>
          <Select
            onSelect={(value) => setUserSelected(value)}
            options={usersOptions}
            selected={userSelected}
            placeholder="Ingrese id"
            className={styles.select}
            loading={isLoadingUsers}
            autocomplete={{
              filterByInput: true,
              input: autocomplete,
              onInputChange: (text) => {
                setAutocomplete(text)
                onUserInputChange(text)
              }
            }}
            onDelete={() => setUserSelected(null)}
            disabled={isLoading}
          />
        </div>
        <div>
          <p className={styles.itemTitle}>Cantidad de etiquetas:</p>
          <TextField
            label=""
            onChange={(value) => handleTextInput(parseInt(value) || 0)}
            value={amount?.toString() || ''}
            maxLength={2}
            inputProps={{ min: 1, max: 25 }}
            className={styles.textField}
          />
        </div>
      </ColInfoContainer>
      {error && (
        <div className={styles.errorContainer}>
          <p>{error}</p>
        </div>
      )}
    </Modal>
  )
}

export default ObjectLabelModal
