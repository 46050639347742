import types from '../types/defaultCBUs'

const initialRoot = {
  userId: null,
  cbuId: null,
  cbuText: '',
  owner: '',
  bankName: '',
  defaulCbuModalOpen: false,
  loading: false,
  error: ''
}

function defaultCbuReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.DEFAULT_CBU_REQUEST: {
      return {
        ...state,
        loading: true,
        error: ''
      }
    }
    case types.DEFAULT_CBU_SUCCESS: {
      return initialRoot
    }
    case types.DEFAULT_CBU_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }
    case types.OPEN_DEFAULT_CBU_MODAL: {
      return {
        ...state,
        userId: action.payload.userId,
        cbuId: action.payload.cbuId,
        cbuText: action.payload.cbuText,
        owner: action.payload.owner,
        defaultCbuModalOpen: true,
        bankName: action.payload.bankName,
        error: ''
      }
    }
    case types.CLOSE_DEFAULT_CBU_MODAL: {
      return initialRoot
    }
    default:
      return state
  }
}

export default defaultCbuReducer
