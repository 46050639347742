import types from '../types/photos'
import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  openModal: (object) => ({ type: types.OPEN_MODAL, payload: { object } }),
  closeModal: () => ({ type: types.CLOSE_MODAL }),

  setDefaultPhoto: (photoID, objectID) => (dispatch) => {
    dispatch({ type: types.SET_DEFAULT_PHOTO_REQUEST })

    return apiPrivate
      .put(URL_BASE_OBJECTS + '/object/' + objectID + '/photo/' + photoID + '/main')
      .then((response) => {
        dispatch({
          type: types.SET_DEFAULT_PHOTO_SUCCESS,
          payload: { photoID }
        })
        alert('Foto marcada como principal con éxito')
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.SET_DEFAULT_PHOTO_FAILURE, dispatch)
      })
  },

  addPhotos: (files, objectID) => (dispatch, getState) => {
    dispatch({ type: types.ADD_PHOTO_REQUEST })

    const formData = new FormData()

    files.forEach((file) => {
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        formData.append('photos[]', file)
      }
    })

    return apiPrivate
      .post(URL_BASE_OBJECTS + '/object/' + objectID + '/photo', formData)
      .then((response) => {
        const object = response.data.description

        dispatch({ type: types.ADD_PHOTO_SUCCESS, payload: { object } })
        alert('Fotografía cargada con éxito')
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.ADD_PHOTO_FAILURE, dispatch)
      })
  }
}

export default actions
