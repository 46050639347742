import React from 'react'
import { TableActionBar } from '../../../../components/TableActionBar'
import { useHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import ProductsTableActionCreators from '../actions/productTable'
import { useDispatch } from 'react-redux'

const CorporativeProductsTableActionBar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { cleanState } = bindActionCreators(ProductsTableActionCreators, dispatch)

  const handleCreateProduct = () => {
    history.push('/corporative/newProduct')
  }

  return (
    <TableActionBar>
      <TableActionBar.Filters cleanFilters={cleanState} />
      <TableActionBar.ButtonAction titleButton="Crear producto" onClickButton={handleCreateProduct} />
    </TableActionBar>
  )
}

export default CorporativeProductsTableActionBar
