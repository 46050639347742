import { apiPrivate } from '@/api'
import { URL_BASE_PROFILE } from '@/endpoints'

export type AddCBUParams = {
  userId: string
  isMain: boolean
  holderName: string
  cbu: string
}

export function add({ userId, isMain, holderName, cbu }: AddCBUParams): Promise<void> {
  const formData = new FormData()

  formData.append('user_id', userId)
  formData.append('main', isMain.toString())
  formData.append('holder_name', holderName)
  formData.append('cbu', cbu)

  return apiPrivate
    .post(`${URL_BASE_PROFILE}/admin/cbu`, formData, { headers: { 'X-Request-Origin': 'adm-web|users|cbus' } })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
