import { FlagsActions, FlagsTypes } from '../types/flags'
import { ProspectSearchTypes } from '../types/prospect'
import { Reducer } from 'redux'
import { TransportTypes } from '../types/transport'

const initialState = {
  loadingFlags: true,
  error: '',
  m3Price: 0,
  minBillableM3: 0,
  minBillableM3Price: 0,
  customDateTransportMult: 0,
  extraAssistantPrice: 0,
  tollCostInCents: 0
}

export type FlagsState = {
  loadingFlags: boolean
  error: string
  m3Price: number
  minBillableM3: number
  minBillableM3Price: number
  customDateTransportMult: number
  extraAssistantPrice: number
  tollCostInCents: number
}

const root: Reducer<FlagsState, FlagsActions> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return initialState
    case FlagsTypes.FETCH_CONFIGURATION_FLAGS:
      return {
        ...state,
        loadingFlags: true
      }
    case FlagsTypes.FETCH_CONFIGURATION_FLAGS_SUCCESS: {
      const { m3Price, minBillableM3, customDateTransportMult, extraAssistantPrice, tollCostInCents } = action.payload
      return {
        ...state,
        loadingFlags: false,
        m3Price,
        minBillableM3,
        minBillableM3Price: m3Price * minBillableM3,
        customDateTransportMult,
        extraAssistantPrice,
        tollCostInCents
      }
    }
    case FlagsTypes.FETCH_CONFIGURATION_FLAGS_FAIL:
      return {
        ...state,
        loadingFlags: false,
        error: action.payload.error
      }
    case TransportTypes.SET_SERVICE_TYPE: {
      const { m3Price, minBillableM3 } = state
      const { multiplier } = action.payload
      return { ...state, minBillableM3Price: m3Price * minBillableM3 * multiplier }
    }
    default:
      return state
  }
}

export default root
