import types from '../types/terms'
import { URL_BASE_USERS } from '../../../endpoints'
import { handleErrorsWithActionLoginAs } from '../../../utils/HandleErrors'
import { apiPrivate } from '../../../api'
import config from '../../../config'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

const getCountryCodeForLanding = (countryCode) => {
  switch (countryCode) {
    case CountryIdCode.SPAIN:
      return 'es'
    case CountryIdCode.ARGENTINA:
      return 'ar'
    default:
      return countryCode
  }
}

const actions = {
  openPanel(id, name, lastName, country) {
    return {
      type: types.OPEN,
      payload: { id, name, lastName, country }
    }
  },
  close() {
    return { type: types.CLOSE }
  },
  generate(userId, countryCode) {
    return (dispatch) => {
      dispatch({ type: types.GENERATE_REQUEST })
      const formattedCountryCode = getCountryCodeForLanding(countryCode)

      return apiPrivate
        .post(URL_BASE_USERS + '/terms/' + userId)
        .then((response) => {
          const code = response.data.description.code
          const link = config.WEB_APP_URL + `/${formattedCountryCode}/tyc/${code}`
          dispatch(actions.generateSuccess(link))
        })
        .catch((error) => {
          handleErrorsWithActionLoginAs(error, types.GENERATE_FAILURE, dispatch)
        })
    }
  },
  generateSuccess: (link) => ({
    type: types.GENERATE_SUCCESS,
    payload: { link }
  })
}

export default actions
