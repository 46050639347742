import MassiveObjectsEditModal, { MassiveObjectsEditModalProps } from './component'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MassiveObjectsEditActionCreators } from './actionCreators'
import { useObjectSituationOptions } from '../Objects/hooks/useObjectSituationOptions'
import { useObjectStatusOptions } from '../Objects/hooks/useObjectStatusOptions'
import { useCountryInfo } from '../../components/CountrySelector/hooks/hooks'
import { Role } from '@/projectApi/AccountManager/Roles/getByParentId'

export const MassiveObjectsEditContainer: React.FC = () => {
  const dispatch = useDispatch()
  const countryInfo = useCountryInfo()
  const {
    enableItem,
    modifyItem,
    setOpen,
    searchProducts,
    selectProduct,
    setProductDimension,
    confirmMassiveEdit,
    findUser,
    clearSelectedProducts
  } = bindActionCreators(MassiveObjectsEditActionCreators, dispatch)
  // @ts-ignore
  const objectSituations = useSelector((root) => root.Objects.edit.objectSituations)
  const objectSituationOptions = useObjectSituationOptions(objectSituations)
  const objectStatusOptions = useObjectStatusOptions()

  useEffect(() => {
    objectStatusOptions.push({
      label: 'Eliminado',
      value: 12
    })
  }, [])

  const { fields, loadingConfirmation, open, objects, error } = useSelector((root) => root.MassiveObjectsEdit)
  const isRoleRoot = useSelector((state) => state.UserLogged.userProfile.role?.id === Role.ROOT)

  const handleConfirmMassiveEdit = () => {
    confirmMassiveEdit(
      objects.map((o) => o.realId),
      fields
    )
  }

  const props: MassiveObjectsEditModalProps = {
    open,
    loadingConfirmation,
    fields,
    objects,
    objectSituationOptions,
    objectStatusOptions,
    error,
    onSearchUserId: (id) => findUser(id),
    onSetProductDimension: setProductDimension,
    onSelectProduct: selectProduct,
    onEnableItem: enableItem,
    onModifyItem: modifyItem,
    onClose: () => setOpen(false),
    onSearchProducts: (name: string) => searchProducts(countryInfo.code, name),
    onConfirm: handleConfirmMassiveEdit,
    clearSelectedProducts,
    isRoleRoot
  }

  return <MassiveObjectsEditModal {...props} />
}
