import { Audit } from '../../../common/operations/audits/entities'

export interface DepositHistoryState {
  audits: Audit[]
  loading: boolean
}

export const GET_DEPOSIT_HISTORY_REQUEST = 'DEPOSIT/DEPOSIT_HISTORY/GET_DEPOSIT_HISTORY_REQUEST'
export const GET_DEPOSIT_HISTORY_SUCCESS = 'DEPOSIT/DEPOSIT_HISTORY/GET_DEPOSIT_HISTORY_SUCCESS'
export const GET_DEPOSIT_HISTORY_FAILURE = 'DEPOSIT/DEPOSIT_HISTORY/GET_DEPOSIT_HISTORY_FAILURE'

export interface GetDepositHistoryRequest {
  type: typeof GET_DEPOSIT_HISTORY_REQUEST
}

export interface GetDepositHistorySuccess {
  type: typeof GET_DEPOSIT_HISTORY_SUCCESS
  payload: {
    audits: Audit[]
  }
}

export interface GetDepositHistoryFailure {
  type: typeof GET_DEPOSIT_HISTORY_FAILURE
}

export type GetDepositHistoryAction = GetDepositHistoryRequest | GetDepositHistorySuccess | GetDepositHistoryFailure

export type DepositHistoryAction = GetDepositHistoryAction
