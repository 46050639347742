import * as Types from '../gqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTimeIntervalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTimeIntervalsQuery = { __typename?: 'Query', getTimeIntervalsByHour: Array<{ __typename?: 'TimeIntervalsByHourEntity', id: number, start: number, end: number, enabled: boolean, slotId: number, discount: number, operationType: Types.TimeIntervalOperationType }> };


export const GetTimeIntervalsDocument = gql`
    query GetTimeIntervals {
  getTimeIntervalsByHour {
    id
    start
    end
    enabled
    slotId
    discount
    operationType
  }
}
    `;

/**
 * __useGetTimeIntervalsQuery__
 *
 * To run a query within a React component, call `useGetTimeIntervalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeIntervalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeIntervalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeIntervalsQuery(baseOptions?: Apollo.QueryHookOptions<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>(GetTimeIntervalsDocument, options);
      }
export function useGetTimeIntervalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>(GetTimeIntervalsDocument, options);
        }
export function useGetTimeIntervalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>(GetTimeIntervalsDocument, options);
        }
export type GetTimeIntervalsQueryHookResult = ReturnType<typeof useGetTimeIntervalsQuery>;
export type GetTimeIntervalsLazyQueryHookResult = ReturnType<typeof useGetTimeIntervalsLazyQuery>;
export type GetTimeIntervalsSuspenseQueryHookResult = ReturnType<typeof useGetTimeIntervalsSuspenseQuery>;
export type GetTimeIntervalsQueryResult = Apollo.QueryResult<GetTimeIntervalsQuery, GetTimeIntervalsQueryVariables>;
export function refetchGetTimeIntervalsQuery(variables?: GetTimeIntervalsQueryVariables) {
      return { query: GetTimeIntervalsDocument, variables: variables }
    }