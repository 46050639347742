import React from 'react'
import { Modal } from 'antd'
import { ExclamationIcon } from '../../../icons'

const styles = {
  modal: {
    maxWidth: '480px'
  },
  bodyStyle: {
    padding: '36px',
    fontSize: '16px',
    fontWeight: 400,
    color: 'black'
  }
}

const ConfirmRemoveModal = (props) => {
  const { modalConfirmActions, modalConfirm, onDelete } = props
  const { objectTarget, isOpenModal } = modalConfirm

  const onDeleteClick = () => onDelete(objectTarget)

  const handleOk = () => {
    onDeleteClick()
    handleCancel()
  }

  const handleCancel = () => {
    modalConfirmActions.closeModalConfirm()
    modalConfirmActions.cleanState()
  }

  return (
    <Modal
      title=""
      closable={false}
      visible={isOpenModal}
      centered={true}
      okText="Sí, eliminar"
      cancelText="Cancelar"
      onOk={handleOk}
      onCancel={handleCancel}
      width={540}
      style={styles.modal}
      bodyStyle={styles.bodyStyle}>
      <ExclamationIcon fill="#ec7c26" width="20px" height="20px" /> ¿Está seguro que desea eliminar este ítem?
    </Modal>
  )
}

export default ConfirmRemoveModal
