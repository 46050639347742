import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CreateComplaintModalActions from '../actionCreators/CreateComplaintModal'
import CreateComplaintModalComponent from '../components/CreateComplaintModal'
import { useEvents } from '@/utils/eventEmitter'
import { Events } from '@/utils/eventEmitter/events'
import { Response as PhotoResponse } from '@/projectApi/CategoryCreation/Photos/uploadPhoto'

const CreateComplaintModalContainer = () => {
  const dispatch = useDispatch()

  const {
    setOpen,
    createComplaint,
    getUsers,
    setComplaintDescription,
    setComplaintEvidence,
    setComplaintReason,
    setComplaintType,
    setOperationId,
    setObjects,
    setAgent,
    setPriority,
    setUser,
    getObjectById,
    getAgents,
    handleClear,
    getReasons,
    getOperationById,
    deleteEvidence
  } = bindActionCreators(CreateComplaintModalActions, dispatch)
  const {
    open,
    users,
    complaint,
    creatingComplaint,
    fetchingUsers,
    fetchingObject,
    object,
    selectedObjects,
    agents,
    fetchingAgents,
    reasons
  } = useSelector((root) => root.Complaints.Create)

  const onUploadedPhoto = (photos: PhotoResponse[]) => {
    const photosEvidence = photos.map((photo) => ({ id: photo.s3Key, url: photo.url }))
    setComplaintEvidence(photosEvidence)
  }

  useEvents(Events.UploadPhotos.UPLOAD_PHOTO_SUCCESS, onUploadedPhoto)

  const handleClose = () => {
    setOpen({ open: false })
    handleClear()
  }

  const handleConfirm = () => {
    setOpen({ open: false })
    createComplaint(complaint)
  }

  useEffect(() => {
    if (open) {
      getAgents()
      getReasons()
    }
  }, [open])

  const createModalProps = {
    open,
    creatingComplaint,
    handleClose,
    handleConfirm,
    createComplaint,
    setComplaintDescription,
    setComplaintEvidence,
    setComplaintReason,
    setComplaintType,
    setOperationId,
    setObjects,
    setAgent,
    setPriority,
    setUser,
    fetchingUsers,
    users,
    getUsers,
    getObjectById,
    fetchingObject,
    object,
    selectedObjects,
    agents,
    fetchingAgents,
    getAgents,
    complaint,
    reasons,
    getOperationById,
    deleteEvidence
  }
  return <CreateComplaintModalComponent {...createModalProps} />
}

export default CreateComplaintModalContainer
