import React, { useState } from 'react'
import { Dropdown } from 'antd'
import BaseMenu, { BaseMenuProps } from './baseMenu'
import { ThreeDots } from '../../icons'
import styles from './actionMenu.module.scss'
import clsx from 'clsx'
import { GenericObject } from '../../utils/utilityTypes'

type ThreeDotMenuProps<T> = BaseMenuProps<T>

const ThreeDotMenu = <T extends GenericObject>({ actions, callbackArgs }: ThreeDotMenuProps<T>) => {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const overlay = (
    <BaseMenu actions={actions} onOptionClick={() => handleVisibleChange(false)} callbackArgs={callbackArgs} />
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
      <div className={clsx(styles.threeDotsContainer, visible && styles.threeDotsContainerActive)}>
        <ThreeDots />
      </div>
    </Dropdown>
  )
}

export default ThreeDotMenu
