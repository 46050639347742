import types from '../types/flags'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import {
  sendToastNotificationError,
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate
} from '../../../utils/notifications'

const actions = {
  getFlags(params) {
    return (dispatch) => {
      dispatch({ type: types.FETCH_FLAGS_REQUEST })
      return apiPrivate
        .get(URL_BASE_OBJECTS + '/config/key/?', { params })
        .then((response) => {
          const flags = response.data.description.keys
          const total = response.data.description.quantity
          dispatch({
            type: types.FETCH_FLAGS_SUCCESS,
            payload: { flags, params, total }
          })
        })
        .catch((error) => {
          if (error?.response?.data?.description) {
            const message = String(error.response.data.description)
            sendToastNotificationError({ render: message })
          } else {
            sendToastNotificationError('Error al cargar las flags')
          }
        })
    }
  },

  setEditModalOpen(row) {
    return (dispatch) => {
      dispatch({ type: types.SET_EDIT_MODAL_OPEN, payload: { row } })
    }
  },

  editFlag(name, value) {
    return (dispatch) => {
      const toastId = setToastLoading('Modificando valor, por favor espere...')
      const data = new FormData()
      data.append('value', value)

      return apiPrivate
        .put(URL_BASE_OBJECTS + '/config/key/' + name, data)
        .then((response) => {
          dispatch({ type: types.EDIT_FLAG_SUCCESS })
          dispatch({ type: types.SET_EDIT_MODAL_CLOSE })
          setToastSuccessUpdate(toastId, { render: 'Flag modificada correctamente' })
        })
        .catch((error) => {
          if (error.response.data.description !== undefined) {
            const message = String(error.response.data.description)
            setToastErrorUpdate(toastId, {
              render: message
            })
          } else {
            setToastErrorUpdate('Error al modificar la flag')
          }
        })
    }
  },

  getTripsFromStore() {
    return (dispatch, getState) => {
      const params = getState().Flags.Flags.params
      dispatch(actions.getFlags(params))
    }
  },

  changeFlagValue(newFlagValue, Name) {
    return { type: types.CHANGE_FLAG_VALUE, payload: { newFlagValue, Name } }
  },

  setEditModalClose() {
    return { type: types.SET_EDIT_MODAL_CLOSE }
  },

  setSorter(sorter) {
    return { type: types.SET_SORTER, payload: { sorter } }
  },

  setFilter(filter) {
    return { type: types.SET_FILTER, payload: { filter } }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  },

  cleanFilters() {
    return { type: types.CLEAN_FILTERS }
  }
}

export default actions
