import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Deposit } from '../../../projectApi/TransactionHandler/Deposits/common'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { useCountries } from '../../../components/CountrySelector/hooks/hooks'
import { DepositsSearchFilter, DepositsTableSearchKey } from '../types/depositList'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { getSearchProps } from '../../../components/DynamicTable/utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Link } from 'react-router-dom'

type DepositTableProps = {
  fetchingDeposits: boolean
  deposits: Deposit[]
  pagination: Pagination
  searchFilters: DepositsSearchFilter[]
  actions: MenuAction<Deposit>[]
  handleSearch: (key: DepositsTableSearchKey, newValue: string) => void
}

const DepositsTable = ({
  fetchingDeposits,
  deposits,
  pagination,
  handleSearch,
  searchFilters,
  actions
}: DepositTableProps) => {
  const countries = useCountries()

  const columns: Column<Deposit>[] = [
    {
      key: 'countryId',
      label: 'País',
      maxWidth: '200px',
      renderDataCell: ({ countryId }) => {
        const flag = getCurrentCountry(countries, countryId)

        if (!countryId) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID',
      maxWidth: '200px',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ id }) => {
        return (
          <Link style={{ textDecoration: 'none' }} to={`/locations?depositId=${id}`}>
            {id}
          </Link>
        )
      }
    },
    {
      key: 'ownerId',
      label: 'Dueño',
      maxWidth: '200px',
      search: getSearchProps('owner_id', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ owner: { id, lastName, name } }) => {
        if (id === 0) return 'SpaceGuru'
        return (
          <ClientIDLinkComponent id={id} noPadding={true}>
            {id}-{name} {lastName}
          </ClientIDLinkComponent>
        )
      }
    },
    {
      key: 'name',
      label: 'Nombre',
      minWidth: '1px',
      maxWidth: '300px',
      renderDataCell: ({ name }) => name,
      search: getSearchProps('name', 'Nombre', handleSearch, searchFilters)
    },
    {
      key: 'address',
      label: 'Dirección'
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDeposits}
      rows={deposits}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default DepositsTable
