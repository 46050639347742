import { create } from './create'
import { list } from './list'
import { update } from './update'
import { start } from './start'
import { finish } from './finish'
import { cancel } from './cancel'
import { removeTx } from './removeTx'
import { addTransactions } from './addTransactions'
import { sendTripsCSV } from './sendTripsCSV'
import { metrics } from './metrics'

export const TripAPI = { list, create, update, start, cancel, finish, removeTx, addTransactions, sendTripsCSV, metrics }
