import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_PAYMENTS } from '../../../endpoints'
import { convertToMoment } from '../../utils'

export type MovementListFormatParam = 'csv'

export type APIPayment = {
  Card: APICard
  ID: number
  MercadoPagoId: string
  PaymentDate: string
  PaymentMethod: number
  PaymentStatus: {
    ID: number
    Description: PaymentStatus
  }
  PaymentType: {
    ID: number
    Description: PaymentType
  }
  Total: number
  User: APIUser
}

type APICard = {
  Createdat: string
  DeletedAt: string | null
  ID: number
  LastDigits: string
  Main: boolean
  Type: string
  TypePhoto: string
  UpdatedAt: string
}

type APIUser = {
  Cards: null
  CreatedAt: string
  DeletedAt: string | null
  Email: {
    Email: string
  }
  ID: string
  LastName: string
  Name: string
  PayDay: number
  Phone: string
  Role: {
    Description: string
    ID: number
    Permissions: null
  }
  UpdatedAt: string
}

type Card = {
  createdat: Moment | null
  deletedAt: Moment | null
  id: number
  lastDigits: string
  main: boolean
  type: string
  typePhoto: string
  updatedAt: Moment | null
}

type User = {
  cards: null
  createdAt: Moment | null
  deletedAt: Moment | null
  email: string
  id: string
  lastName: string
  name: string
  payDay: number
  phone: string
  role: string
  updatedAt: Moment | null
}

export type Payment = {
  card: Card
  id: number
  mercadoPagoId: string
  paymentDate: Moment | null
  paymentMethod: number
  paymentStatus: { id: number; description: PaymentStatus }
  paymentType: PaymentType
  total: number
  user: User
}

export enum PaymentStatus {
  REALIZADO = 1,
  ERRONEO,
  EN_PROCESO,
  RECHAZADO,
  DEVUELTO,
  CANCELADO,
  DESCONOCIDO,
  PENDIENTE,
  PENDIENTE_MANUAL
}

export enum PaymentType {
  TRANSACCION = 1,
  DEPOSITO,
  ALQUILER
}

export type MovementListSortKey = 'id' | 'total'

export type MovementsListCategoryFilterKey = 'paymentStatus' | 'paymentType'

export type SearchableParams = {
  id?: number
  mercadoPagoId?: string
  email?: string
}

interface APIResponse {
  description: {
    payments: APIPayment[]
    quantity: number
  }
}

interface Response {
  payments: Payment[]
  total: number
}

interface ListParams extends SearchableParams {
  offset: number
  limit: number
  order?: SortDirection
  column?: MovementListSortKey
  status?: string
  paymentType?: string
  format?: MovementListFormatParam
}

const emptyCSVResponse = {
  payments: [],
  total: 0
}

export function list(params: ListParams): Promise<Response> {
  const axiosParams = {
    Offset: params.offset,
    Limit: params.limit,
    Order: params.order,
    Column: params.column,
    id: params.id,
    mercadoPagoId: params.mercadoPagoId,
    email: params.email,
    status: params.status,
    type: params.paymentType,
    format: params.format
  }

  return apiPrivate
    .get(`${URL_BASE_PAYMENTS}/payment`, { params: axiosParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (params.format === 'csv') return emptyCSVResponse
      if (!response.data) throw new Error('Hubo un problema al obtener los movimientos')

      const camelResponse: Response = {
        total: response.data.description.quantity,
        payments: response.data.description.payments
          ? camelcaseKeys(response.data.description.payments, { deep: true }).map((payment) => ({
              card: {
                createdat: convertToMoment(payment.card.createdat),
                deletedAt: convertToMoment(payment.card.deletedAt),
                id: payment.card.id,
                lastDigits: payment.card.lastDigits,
                main: payment.card.main,
                type: payment.card.type,
                typePhoto: payment.card.typePhoto,
                updatedAt: convertToMoment(payment.card.updatedAt)
              },
              id: payment.id,
              mercadoPagoId: payment.mercadoPagoId,
              paymentDate: convertToMoment(payment.paymentDate),
              paymentMethod: payment.paymentMethod,
              paymentStatus: {
                id: payment.paymentStatus.id,
                description: payment.paymentStatus.description
              },
              paymentType: payment.paymentType.description,
              total: payment.total,
              user: {
                cards: payment.user.cards,
                createdAt: convertToMoment(payment.user.createdAt),
                deletedAt: convertToMoment(payment.user.deletedAt),
                email: payment.user.email.email,
                id: payment.user.id,
                lastName: payment.user.lastName,
                name: payment.user.name,
                payDay: payment.user.payDay,
                phone: payment.user.phone,
                role: payment.user.role.description,
                updatedAt: convertToMoment(payment.user.updatedAt)
              }
            }))
          : []
      }
      return camelResponse
    })
    .catch((err) => {
      console.error(err)
      throw err
    })
}
