import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/changeEmail'
import Component, { ChangeEmailModalProps } from '../components/changeEmailModal'

const Container = () => {
  const dispatch = useDispatch()
  const changeEmailActions = bindActionCreators(actions, dispatch)
  const {
    updateEmail,
    setOpen,
    setNewEmail,
    setEmailConfirmation,
    setErrorNewEmail,
    setErrorEmailConfirmation,
    setSuccessNewEmail,
    setMessageAvailableEmailConfirmation
  } = changeEmailActions

  const { open, userData, newEmail, emailConfirmation, loading, fetchingCards, errorFetchCards, hasMPCards } =
    useSelector((state) => state.Users.changeEmail)

  useEffect(() => {
    if (emailConfirmation.value) {
      if (newEmail.value !== emailConfirmation.value) {
        setErrorEmailConfirmation('Ambos emails deben ser iguales')
        setMessageAvailableEmailConfirmation('')
      } else {
        setErrorEmailConfirmation('')
        setMessageAvailableEmailConfirmation('Email coincidente')
      }
    }
  }, [emailConfirmation.value, newEmail.value])

  const handleConfirm = () => {
    const { id, currentEmail } = userData

    if (id) {
      setOpen(false)
      updateEmail({ userId: id, newEmail: newEmail.value, oldEmail: currentEmail })
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const okDisabled =
    Boolean(emailConfirmation.errorMessage) ||
    Boolean(newEmail.errorMessage) ||
    Boolean(!newEmail.value) ||
    Boolean(!emailConfirmation.value) ||
    newEmail.value === ''

  const props: ChangeEmailModalProps = {
    open,
    userData,
    newEmail: newEmail.value,
    errorEmailConfirmation: emailConfirmation.errorMessage,
    errorNewEmail: newEmail.errorMessage,
    emailConfirmation: emailConfirmation.value,
    successNewEmail: newEmail.successMessage,
    messageAvailableEmailConfirmation: emailConfirmation.successMessage,
    okDisabled,
    loading,
    fetchingCards,
    errorFetchCards,
    hasMPCards,
    setNewEmail,
    setErrorEmailConfirmation,
    setMessageAvailableEmailConfirmation,
    setEmailConfirmation,
    handleCloseModal,
    handleConfirm
  }

  return <Component {...props} />
}

export default Container
