/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Tooltip } from 'antd'
import { Column } from '../../types/types'
import styles from './HeaderCell.module.scss'
import SearchDropdown from '../Dropdowns/SearchDropdown/SearchDropdown'
import FilterDropdown from '../Dropdowns/FilterDropdown/FilterDropdown'
import RangePickerDropdown from '../Dropdowns/RangePickerDropdown/RangePickerDropdown'
import Sorter from '../Sorter/Sorter'
import { GenericObject } from '../../../../utils/utilityTypes'
import SingleDatePickerDropdown from '../Dropdowns/SingleDatePickerDropdown/SingleDatePickerDropdown'
import AutocompleteDropdown from '../Dropdowns/AutocompleteDropdown/AutocompleteDropdown'

const HeaderCell = <T extends GenericObject>({ column, className }: { column: Column<T>; className?: string }) => {
  const getHeaderWithSort = () =>
    column.sort?.onClick ? <Sorter {...column.sort}>{column.label}</Sorter> : column.label

  const content = (
    <div className={styles.HeaderCell}>
      {column.tooltip ? (
        <Tooltip title={column.tooltip}>
          <div className={styles.TooltipDummy}>{getHeaderWithSort()}</div>
        </Tooltip>
      ) : (
        getHeaderWithSort()
      )}
      {column.search?.onConfirm && <SearchDropdown {...column.search} />}
      {column.filters?.onConfirm && column.filters?.options.length > 0 && <FilterDropdown {...column.filters} />}
      {column.rangePicker?.onConfirm && <RangePickerDropdown {...column.rangePicker} />}
      {column.singleDatePicker?.onConfirm && <SingleDatePickerDropdown {...column.singleDatePicker} />}
      {column.autocomplete?.onConfirm && <AutocompleteDropdown {...column.autocomplete} />}
    </div>
  )

  // @ts-ignore Typescript is on drugs, highlighting <th> as a non-existent element
  return <th className={className}>{content}</th>
}

export default HeaderCell
