import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

type deleteParams = {
  shipperId: number
}

export function deleteit({ shipperId }: deleteParams) {
  return apiPrivate
    .delete(`${URL_BASE_TRIPS}/shipper/${shipperId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
