export const typesOptions = [
  { text: 'Estático', label: 'Estático', value: 'static' },
  { text: 'Dinámico', label: 'Dinámico', value: 'dynamic' },
  { text: 'Transitorio', label: 'Transitorio', value: 'transitory' },
  { text: 'Ingreso/Egreso', label: 'Ingreso/Egreso', value: 'in_out' },
  { text: 'Transporte', label: 'Transporte', value: 'transport' },
  { text: 'Temporal', label: 'Temporal', value: 'temporary' }
]

export const isActive = [
  { text: 'SÍ', label: 'Activo', value: 'ACTIVE' },
  { text: 'NO', label: 'Inactivo', value: 'INACTIVE' }
]
