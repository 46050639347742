import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  GET_ORIGINS_FAILURE,
  GET_ORIGINS_SUCCESS,
  GET_SOURCES_FAILURE,
  GET_SOURCES_SUCCESS,
  GetOriginsSuccess,
  GetSourcesSuccess
} from '../types/originsTable'

const OriginsTableActionCreator = {
  getOrigins: (): AppThunk => async (dispatch) => {
    try {
      const { origins } = await API.Mercurio.BO.Origins.list()
      const success: GetOriginsSuccess = {
        type: GET_ORIGINS_SUCCESS,
        payload: { origins }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GET_ORIGINS_FAILURE, dispatch)
    }
  },

  getSources: (): AppThunk => async (dispatch) => {
    try {
      const { sources } = await API.Mercurio.BO.Config.list()
      const success: GetSourcesSuccess = {
        type: GET_SOURCES_SUCCESS,
        payload: { sources }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GET_SOURCES_FAILURE, dispatch)
    }
  }
}

export default OriginsTableActionCreator
