import React, { useEffect, useMemo, useState } from 'react'
import MassiveModificationModal from '../../components/MassiveModificationModal'
import { ItemGroup, ModifiableItemType } from '../../components/MassiveModificationModal/types'
import { FormattedObject } from '../../projectApi/ObjectAdministration/common'
import { calculateDimensions, getIdentificationCode } from '../ObjectsV2/utils'
import { Fields, ProductDimensions } from './types'
import styles from './styles.module.scss'
import { FormattedProduct } from '../../projectApi/CategoryCreation/Product/common'
import useDebounce from '../../utils/hooks/useDebounce'
import NumberInput from '../../components/numberInput'
import { formatNumber } from '../../utils/formatNumber'
import { Collapse } from 'antd'
import { ObjectSituationOption } from '../Objects/hooks/useObjectSituationOptions'
import { ObjectSituationKeys } from '../Objects/constants/objectSituations'
import { ObjectStatusKeys } from '../Objects/constants/objectStates'
import { ObjectStatusOption } from '../Objects/hooks/useObjectStatusOptions'
export interface MassiveObjectsEditModalProps {
  open: boolean
  fields: Fields
  loadingConfirmation: boolean
  objects: FormattedObject[]
  objectSituationOptions: ObjectSituationOption[]
  objectStatusOptions: ObjectStatusOption[]
  error: string
  onSetProductDimension: (dimensionType: keyof ProductDimensions, value: number) => void
  onSearchProducts: (description: string) => void
  onSelectProduct: (product: FormattedProduct) => void
  onClose: () => void
  onConfirm: () => void
  onModifyItem: <T extends string | boolean | ObjectSituationKeys | null>(value: T, key: keyof Fields) => void
  onEnableItem: (enabled: boolean, key: keyof Fields) => void
  onSearchUserId: (id: number) => void
  clearSelectedProducts: () => void
  isRoleRoot: boolean
}

const MassiveObjectsEditModal: React.FC<MassiveObjectsEditModalProps> = ({
  open,
  fields,
  loadingConfirmation,
  objects,
  objectSituationOptions,
  objectStatusOptions,
  error,
  onSetProductDimension,
  onSearchProducts,
  onSelectProduct,
  onClose,
  onConfirm,
  onModifyItem,
  onEnableItem,
  onSearchUserId,
  clearSelectedProducts,
  isRoleRoot
}) => {
  const [productSearch, setProductSearch] = useState('')
  const debouncedProductSearch = useDebounce(productSearch)

  useEffect(() => {
    if (open && !fields.product.value) onSearchProducts(debouncedProductSearch)
  }, [debouncedProductSearch, open])

  const product = fields.product.value
  const {
    heightInCm: newHeightInCm,
    lengthInCm: newLengthInCm,
    widthInCm: newWidthInCm,
    weightInGr: newWeightInGr
  } = fields.product.dimensions

  const { minNewVolume, maxNewVolume } = useMemo(() => {
    if (!product) return { minNewVolume: null, maxNewVolume: null }
    const newProductVolume = calculateDimensions(product.heightInCm, product.widthInCm, product.lengthInCm)
    const minNewVolume = newProductVolume - newProductVolume * product.minVolumeDeviation
    const maxNewVolume = newProductVolume + newProductVolume * product.maxVolumeDeviation
    return { minNewVolume, maxNewVolume }
  }, [product])

  const actualNewVolume = useMemo(
    () => calculateDimensions(newHeightInCm, newWidthInCm, newLengthInCm),
    [newHeightInCm, newLengthInCm, newWidthInCm]
  )

  const errorVolume = useMemo(() => {
    if (!minNewVolume || !maxNewVolume) return 'Aún no se ha seleccionado un producto'
    if (actualNewVolume < minNewVolume) return 'El volumen es menor al mínimo indicado'
    if (actualNewVolume > maxNewVolume) return 'El volumen es mayor al máximo indicado'
    return ''
  }, [actualNewVolume])

  const handleWeightChange = (inKilos: number) => {
    const inGrams = Math.round(inKilos * 1000)
    onSetProductDimension('weightInGr', inGrams)
  }

  const [autocomplete, setAutocomplete] = useState<string>('')

  const onProductInputChange = (newValue: string) => {
    setAutocomplete(newValue)
    setProductSearch(newValue)
  }

  // @ts-ignore
  const itemGroups: [ItemGroup, ...ItemGroup[]] = [
    {
      title: 'Datos básicos',
      items: [
        {
          label: 'Nombre',
          type: ModifiableItemType.TEXT_FIELD,
          enabled: fields.name.enabled && !loadingConfirmation,
          currentValue: fields.name.value,
          onEnable: () => onEnableItem(!fields.name.enabled, 'name'),
          onChange: (newValue) => onModifyItem(newValue, 'name')
        },
        {
          label: 'Descripción',
          type: ModifiableItemType.TEXT_FIELD,
          enabled: fields.description.enabled && !loadingConfirmation,
          currentValue: fields.description.value,
          onEnable: () => onEnableItem(!fields.description.enabled, 'description'),
          onChange: (newValue) => onModifyItem(newValue, 'description')
        }
        // {
        //   label: 'Poner en alquiler',
        //   type: ModifiableItemType.SWITCH,
        //   enabled: fields.rentEnabled.enabled,
        //   currentValue: fields.rentEnabled.value,
        //   onEnable: () => onEnableItem(!fields.rentEnabled.enabled, 'rentEnabled'),
        //   onChange: (newValue) => onModifyItem(newValue, 'rentEnabled')
        // }
      ]
    },
    {
      title: 'Dueño',
      items: [
        {
          label: 'Nuevo dueño',
          type: ModifiableItemType.SEARCH_INPUT,
          enabled: fields.owner.enabled && !loadingConfirmation,
          currentValue: fields.owner.value,
          onlyNumbers: true,
          disableSearch: !fields.owner.value.length,
          loading: fields.owner.loadingUsers,
          onEnable: () => onEnableItem(!fields.owner.enabled, 'owner'),
          onChange: (newValue) => onModifyItem(newValue, 'owner'),
          onSearch: (value) => {
            onSearchUserId(parseInt(value))
          }
        },
        {
          type: 'PRESENTATIONAL',
          node: (
            <div style={{ textAlign: 'center' }}>
              {fields.owner.loadingUsers
                ? 'Buscando usuario...'
                : fields.owner.user
                ? `Usuario encontrado: ${fields.owner.user.realId} - ${fields.owner.user.name} ${fields.owner.user.lastName}`
                : fields.owner.alreadySearchedOnce
                ? 'No se encontró un usuario con ese ID'
                : 'Aún no se ha buscado un usuario'}
            </div>
          )
        }
      ]
    },
    {
      title: 'Producto',
      items: [
        {
          label: 'Producto',
          type: ModifiableItemType.AUTOCOMPLETE_SEARCH,
          enabled: fields.product.enabled && !loadingConfirmation,
          loading: fields.product.loadingProducts,
          currentValue: fields.product.value ? fields.product.value.id : 0,
          options: fields.product.products
            .filter((p) => !p.deprecated)
            .map((p) => ({
              label: `${p.description} - ${p.heightInCm} x ${p.widthInCm} x ${p.lengthInCm} (cm) = ${formatNumber(
                calculateDimensions(p.heightInCm, p.widthInCm, p.lengthInCm)
              )} m³`,
              value: p.id
            })),
          onEnable: () => onEnableItem(!fields.product.enabled, 'product'),
          onInputChange: (newValue: string) => onProductInputChange(newValue),
          onChange: (newValue) => {
            const found = fields.product.products.find((p) => p.id === newValue)
            if (!found) return

            onSelectProduct(found)
          },
          autocomplete,
          setAutocomplete,
          clearSelectedProducts
        },
        {
          type: 'PRESENTATIONAL',
          node: (
            <div className={styles.productDetails}>
              <div className={styles.detail}>
                <span>Alto (cm)*</span>
                <NumberInput
                  disabled={!fields.product.enabled || !fields.product.value || loadingConfirmation}
                  value={newHeightInCm}
                  onAnyChange={(newValue) => onSetProductDimension('heightInCm', newValue)}
                />
              </div>
              <div className={styles.detail}>
                <span>Ancho (cm)*</span>
                <NumberInput
                  disabled={!fields.product.enabled || !fields.product.value || loadingConfirmation}
                  value={newWidthInCm}
                  onAnyChange={(newValue) => onSetProductDimension('widthInCm', newValue)}
                />
              </div>
              <div className={styles.detail}>
                <span>Largo (cm)*</span>
                <NumberInput
                  disabled={!fields.product.enabled || !fields.product.value || loadingConfirmation}
                  value={newLengthInCm}
                  onAnyChange={(newValue) => onSetProductDimension('lengthInCm', newValue)}
                />
              </div>
              <div className={styles.detail}>
                <span>Peso (kg)*</span>
                <NumberInput
                  acceptFloat
                  disabled={!fields.product.enabled || !fields.product.value || loadingConfirmation}
                  value={Math.round((newWeightInGr / 1000 + Number.EPSILON) * 1000) / 1000}
                  onAnyChange={(newValue) => handleWeightChange(newValue)}
                />
              </div>
              {product && (
                <div className={styles.volume}>
                  <div>
                    <div>
                      <b>Posibles valores:</b>
                    </div>
                    -{product.minVolumeDeviation * 100}% ({formatNumber(minNewVolume || 0)}m³) ~ +
                    {product.maxVolumeDeviation * 100}% ({formatNumber(maxNewVolume || 0)}m³)
                  </div>
                  <div style={{ color: errorVolume ? 'red' : 'green' }}>
                    <div>
                      <b>Volumen actual:</b>
                    </div>
                    {formatNumber(actualNewVolume)}{' '}
                    <span>
                      m<sup>3</sup>
                    </span>
                  </div>
                  <div style={{ color: 'red' }}>
                    <b>{errorVolume}</b>
                  </div>
                </div>
              )}
            </div>
          )
        }
      ]
    },
    {
      title: 'Situación',
      items: [
        {
          label: 'Nueva situación',
          type: ModifiableItemType.AUTOCOMPLETE,
          enabled: fields.objectSituation.enabled && !loadingConfirmation,
          currentValue: fields.objectSituation.value,
          options: objectSituationOptions,
          onEnable: () => {
            onEnableItem(!fields.objectSituation.enabled, 'objectSituation')
            onEnableItem(false, 'objectSituationReason')
          },
          onChange: (newValue: ObjectSituationKeys | null) => onModifyItem(newValue, 'objectSituation')
        },
        {
          label: 'Motivo',
          type: ModifiableItemType.TEXT_FIELD,
          enabled: fields.objectSituationReason.enabled && fields.objectSituation.enabled && !loadingConfirmation,
          disabledCheckbox: !fields.objectSituation.enabled,
          currentValue: fields.objectSituationReason.value,
          onEnable: () => onEnableItem(!fields.objectSituationReason.enabled, 'objectSituationReason'),
          onChange: (newValue) => onModifyItem(newValue, 'objectSituationReason')
        }
      ]
    },
    ...(isRoleRoot
      ? [
          {
            title: 'Estado',
            items: [
              {
                label: 'Nuevo estado',
                type: ModifiableItemType.AUTOCOMPLETE,
                enabled: fields.objectStatus.enabled && !loadingConfirmation,
                currentValue: fields.objectStatus.value,
                options: objectStatusOptions,
                onEnable: () => {
                  onEnableItem(!fields.objectStatus.enabled, 'objectStatus')
                },
                onChange: (newValue: ObjectStatusKeys | null) => onModifyItem(newValue, 'objectStatus')
              }
            ]
          }
        ]
      : [])
  ]
  const description = (
    <div className={styles.description}>
      <Collapse>
        <Collapse.Panel header={'Lista de objetos a modificar'} key={1}>
          <div className={styles.objects}>
            {objects.map((obj) => (
              <div key={obj.id}>
                {obj.realId} - {getIdentificationCode(obj)} {obj.product.description}
              </div>
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  )

  const isDisabled = Boolean(
    Object.values(fields).every((f) => !f.enabled) ||
      (fields.description.enabled && fields.description.value.length === 0) ||
      (fields.name.enabled && fields.name.value.length === 0) ||
      (fields.product.enabled && (!fields.product.value || errorVolume.length)) ||
      (fields.owner.enabled && !fields.owner.user) ||
      (fields.objectSituation.enabled && !fields.objectSituation.value) ||
      (fields.objectSituationReason.enabled && fields.objectSituationReason.value.length === 0)
  )

  return (
    <MassiveModificationModal
      itemGroups={itemGroups}
      title={'Edición masiva de objetos'}
      description={description}
      open={open}
      loading={loadingConfirmation}
      disableConfirm={isDisabled}
      onClose={onClose}
      onConfirm={onConfirm}
      error={error}
    />
  )
}

export default MassiveObjectsEditModal
