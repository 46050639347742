import { Role } from '../../../projectApi/AccountManager/Roles/getAll'

export const SEND_CSV_REQUEST = 'USERS/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'USERS/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'USERS/SEND_CSV_FAILURE'

export type SendCSVRequest = {
  type: typeof SEND_CSV_REQUEST
}

export type SendCSVSuccess = {
  type: typeof SEND_CSV_SUCCESS
  payload: {
    roles: Role[]
  }
}

export type SendCSVFailure = {
  type: typeof SEND_CSV_FAILURE
  payload: {
    error: string
  }
}

export type SendCSVState = {
  loading: boolean
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure
