export type RemovalMetric = {
  id: string
  metric: string
  quantity: number
}

export type RemovalMetricsState = {
  quantityMetrics: RemovalMetric[]
  fetchingMetrics: boolean
}

export enum GetRemovalMetricsTypes {
  GET_REMOVAL_METRICS_REQUEST = 'REMOVALS/REMOVALS_METRICS/GET_REMOVAL_METRICS_REQUEST',
  GET_REMOVAL_METRICS_SUCCESS = 'REMOVALS/REMOVALS_METRICS/GET_REMOVAL_METRICS_SUCCESS',
  GET_REMOVAL_METRICS_FAILURE = 'REMOVALS/REMOVALS_METRICS/GET_REMOVAL_METRICS_FAILURE'
}

export interface GetRemovalMetricsRequest {
  type: typeof GetRemovalMetricsTypes.GET_REMOVAL_METRICS_REQUEST
}

export interface GetRemovalMetricsSuccess {
  type: typeof GetRemovalMetricsTypes.GET_REMOVAL_METRICS_SUCCESS

  payload: {
    quantityMetrics: RemovalMetric[]
  }
}

export interface GetRemovalMetricsFailure {
  type: typeof GetRemovalMetricsTypes.GET_REMOVAL_METRICS_FAILURE
}

type GetRemovalMetricsActions = GetRemovalMetricsRequest | GetRemovalMetricsSuccess | GetRemovalMetricsFailure

export type RemovalMetricsActions = GetRemovalMetricsActions
