import React from 'react'

const ThreeDotsIcon = () => {
  return (
    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.99428 6.7439C2.94667 6.7439 3.70857 7.52439 3.70857 8.5C3.70857 9.47561 2.94667 10.2561 1.99428 10.2561C1.0419 10.2561 0.279999 9.47561 0.279999 8.5C0.279999 7.52439 1.0419 6.7439 1.99428 6.7439ZM0.279999 2.2561C0.279999 3.23171 1.0419 4.0122 1.99428 4.0122C2.94667 4.0122 3.70857 3.23171 3.70857 2.2561C3.70857 1.28049 2.94667 0.5 1.99428 0.5C1.0419 0.5 0.279999 1.28049 0.279999 2.2561ZM0.279999 14.7439C0.279999 15.7195 1.0419 16.5 1.99428 16.5C2.94667 16.5 3.70857 15.7195 3.70857 14.7439C3.70857 13.7683 2.94667 12.9878 1.99428 12.9878C1.0419 12.9878 0.279999 13.7683 0.279999 14.7439Z"
        fill="#5D58F7"
      />
    </svg>
  )
}

export default ThreeDotsIcon
