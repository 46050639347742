/* eslint-disable camelcase */
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

interface Params {
  userId: number
  addressId: number
}

export function deleteAddress({ addressId, userId }: Params): Promise<void> {
  const config = {
    headers: {
      'user-id': userId
    }
  }

  return apiPrivate
    .delete(URL_BASE_USERS + `/addresses/${addressId}`, config)
    .then(() => {})
    .catch((err) => {
      console.error(err)
      throw err
    })
}
