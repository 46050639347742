import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import DonationsViewActionCreator from '../actions/donationsView'
import { DonationsView, DonationsViewProps } from '../components/donationsView'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

type ParamsProps = {
  donationID: number
}

const useParsedParams = (): ParamsProps => {
  const { donationID } = useParams<{ donationID: string }>()

  return { donationID: parseInt(donationID) }
}

const SalesRequestViewContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { clearState, getDonationById, acceptDonation, donate, rejectDonation } = bindActionCreators(
    DonationsViewActionCreator,
    dispatch
  )
  const { donation, error, isLoading } = useSelector((state) => state.DonationsReducer.view)
  const { donationID } = useParsedParams()

  useEffect(() => {
    clearState()
    getDonationById(donationID)
  }, [])

  const handleAcceptDonation = async () => {
    await acceptDonation(donation?.id || donationID)
  }

  const handleDonate = async () => {
    await donate(donation?.id || donationID)
  }

  const handleRejectDonation = async () => {
    await rejectDonation(donation?.id || donationID)
  }

  const handleCancel = () => {
    history.goBack()
    clearState()
  }

  useEvents([Events.Donations.ACCEPT, Events.Donations.DONATE, Events.Donations.REJECT], () => {
    history.push('/donations')
    clearState()
  })

  const props: DonationsViewProps = {
    donation,
    error,
    isLoading,
    handleAcceptDonation,
    handleRejectDonation,
    handleCancel,
    handleDonate
  }

  return (
    <>
      <DonationsView {...props} />
    </>
  )
}

export default SalesRequestViewContainer
