import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { Address } from '../../../components/Address/types'
export interface PostalCode {
  ID: number
  Code: string
  AllowedDelivery: boolean
}

interface Params {
  address: Address
  userId: number
}

export function create(params: Params): Promise<void> {
  const { address, userId } = params
  const newAddress = {
    ...address,
    original: address.addressGoogle,
    user_id: userId,
    origin_channel: 'Backoffice',
    address: address.street,
    postal_code_id: address.postalCode
  }

  return apiPrivate
    .post(`${URL_BASE_USERS}/addresses`, newAddress)
    .then(() => {})
    .catch((err) => {
      console.error(err)
      throw err
    })
}
