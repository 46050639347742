import React, { useEffect, useState } from 'react'
import { apiPrivate } from '../../api'
import { URL_BASE_USERS } from '../../endpoints'
import { useDispatch } from 'react-redux'
import userProfileActions from '../UserLogged/actions/userProfile'
import { bindActionCreators } from 'redux'
import LoadingComponent from './LoadingComponent'
import { useEvents } from '../../utils/eventEmitter'
import { GlobalEventsEnum } from '../../utils/eventEmitter/events/globalEvents'

function tokenInvalid(push) {
  localStorage.clear()
  push('/login')
}

function tokenValidation(setLoading, { push }, actions) {
  return apiPrivate
    .post(URL_BASE_USERS + '/login/backoffice/isValid')
    .then(async (response) => {
      const { allowed } = response.data.description

      if (allowed) {
        const success = await actions.getParentAccountPermissions()
        if (success) {
          setLoading(false)
          return
        }
      }
      tokenInvalid(push)
    })
    .catch((e) => {
      tokenInvalid(push)
    })
}

export function CheckTokenValidation({ prevPath, history, children }) {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const actions = {
    getParentAccountPermissions: bindActionCreators(userProfileActions.getParentAccountPermissions, dispatch)
  }

  const updateHeaders = () => {
    const token = localStorage.getItem('token')
    const parentId = localStorage.getItem('parent-id')
    if (token) apiPrivate.defaults.headers.Authorization = token
    if (parentId) {
      apiPrivate.defaults.headers['parent-id'] = parentId
    } else {
      delete apiPrivate.defaults.headers['parent-id']
    }
  }

  useEffect(() => {
    updateHeaders()
    if (prevPath === '/login') {
      setLoading(false)
      history.replace()
    } else {
      tokenValidation(setLoading, history, actions)
    }
  }, [])

  useEvents(GlobalEventsEnum.CHANGE_ACCOUNT, () => {
    setLoading(true)
    updateHeaders()
    tokenValidation(setLoading, history, actions)
  })

  if (loading) {
    return <LoadingComponent description="Validando su sesión, por favor espere..." />
  }
  return <div className="app">{children}</div>
}
