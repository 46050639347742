import { Operator } from '../../projectApi/TransactionHandler/Pickupers/list'
import { Transaction } from '../../projectApi/TransactionHandler/Transaction/TripTransactions/list'

export interface TransactionOperatorsAssignmentState {
  open: boolean
  loadingOperators: boolean
  editMode: boolean
  allOperators: Operator[]
  selectedOperators: Operator[]
  transactions: Transaction[]
  error: string
  loadingAssignment: boolean
}

export const SET_ASSIGNMENT_MODAL_OPEN = 'TRANSACTION_OPERATORS_ASSIGNMENT/SET_ASSIGNMENT_MODAL_OPEN'

export interface SetAssignmentModalOpen {
  type: typeof SET_ASSIGNMENT_MODAL_OPEN
  payload: {
    open: boolean
    transactions?: Transaction[]
    operators?: Operator[]
  }
}

export const SET_SELECTED_OPERATORS = 'TRANSACTION_OPERATORS_ASSIGNMENT/SET_SELECTED_OPERATORS'

export interface SetSelectedOperators {
  type: typeof SET_SELECTED_OPERATORS
  payload: {
    operators: Operator[]
  }
}

export const GET_OPERATORS_REQUEST = 'TRANSACTION_OPERATORS_ASSIGNMENT/GET_OPERATORS_REQUEST'
export const GET_OPERATORS_SUCCESS = 'TRANSACTION_OPERATORS_ASSIGNMENT/GET_OPERATORS_SUCCESS'
export const GET_OPERATORS_FAILURE = 'TRANSACTION_OPERATORS_ASSIGNMENT/GET_OPERATORS_FAILURE'

export interface GetOperatorsRequest {
  type: typeof GET_OPERATORS_REQUEST
}

export interface GetOperatorsSuccess {
  type: typeof GET_OPERATORS_SUCCESS
  payload: {
    operators: Operator[]
  }
}

export interface GetOperatorsFailure {
  type: typeof GET_OPERATORS_FAILURE
  payload: {
    error: string
  }
}

export type GetOperatorsAction = GetOperatorsRequest | GetOperatorsSuccess | GetOperatorsFailure

export const ASSIGN_OPERATORS_REQUEST = 'TRANSACTION_OPERATORS_ASSIGNMENT/ASSIGN_OPERATORS_REQUEST'
export const ASSIGN_OPERATORS_SUCCESS = 'TRANSACTION_OPERATORS_ASSIGNMENT/ASSIGN_OPERATORS_SUCCESS'
export const ASSIGN_OPERATORS_FAILURE = 'TRANSACTION_OPERATORS_ASSIGNMENT/ASSIGN_OPERATORS_FAILURE'

export interface AssignOperatorsRequest {
  type: typeof ASSIGN_OPERATORS_REQUEST
}

export interface AssignOperatorsSuccess {
  type: typeof ASSIGN_OPERATORS_SUCCESS
}

export interface AssignOperatorsFailure {
  type: typeof ASSIGN_OPERATORS_FAILURE
  payload: {
    error: string
  }
}

export type AssignOperatorsAction = AssignOperatorsRequest | AssignOperatorsSuccess | AssignOperatorsFailure

export type TransactionOperatorsAssignmentAction =
  | SetAssignmentModalOpen
  | GetOperatorsAction
  | SetSelectedOperators
  | AssignOperatorsAction
