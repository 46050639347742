import { URL_BASE_OBJECTS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import snakecaseKeys from 'snakecase-keys'

type BodyParams = {
  locationId: number
}

export async function setLocation(objectId: number, params: BodyParams): Promise<void> {
  const body = snakecaseKeys(params)
  return apiPrivate
    .put(`${URL_BASE_OBJECTS}/object/${objectId}/location/v2`, body)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
