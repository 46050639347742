import { Reducer } from 'redux'
import {
  CreateDiscountDatesActions,
  ModalCreateDiscountDateState,
  CreateDiscountDateTypes,
  ModalCreateDiscountDateTypes,
  UpdateDiscountDateTypes,
  DeleteDiscountDateTypes
} from '../types/ABMDiscountDates'

const initialState: ModalCreateDiscountDateState = {
  editMode: false,
  modalVisible: false,
  deleteModalVisible: false,
  creatingDiscount: false,
  updatingDiscount: false,
  deletingDiscount: false,
  selectedDiscount: null,
  selectedDate: null,
  discountRate: {
    error: '',
    value: undefined
  }
}

const createDiscountDatesReducer: Reducer<ModalCreateDiscountDateState, CreateDiscountDatesActions> = (
  state: ModalCreateDiscountDateState = initialState,
  action: CreateDiscountDatesActions
): ModalCreateDiscountDateState => {
  switch (action.type) {
    case CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_REQUEST: {
      return { ...state, creatingDiscount: true }
    }
    case CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_SUCCESS:
    case CreateDiscountDateTypes.CREATE_DISCOUNT_DATE_FAILURE: {
      return { ...state, creatingDiscount: false }
    }
    case UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_REQUEST: {
      return { ...state, updatingDiscount: true }
    }
    case UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_SUCCESS:
    case UpdateDiscountDateTypes.UPDATE_DISCOUNT_DATE_FAILURE: {
      return { ...state, updatingDiscount: false }
    }
    case DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_REQUEST: {
      return { ...state, deletingDiscount: true }
    }
    case DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_SUCCESS:
    case DeleteDiscountDateTypes.DELETE_DISCOUNT_DATE_FAILURE: {
      return { ...state, deletingDiscount: false }
    }
    case ModalCreateDiscountDateTypes.SET_DISCOUNT_DATE:
      return {
        ...state,
        selectedDate: action.payload.selectedDate
      }
    case ModalCreateDiscountDateTypes.SET_DISCOUNT_RATE:
      return {
        ...state,
        discountRate: {
          ...state.discountRate,
          value: Number(action.payload.discountRate)
        }
      }
    case ModalCreateDiscountDateTypes.SET_DISCOUNT_RATE_ERROR:
      return {
        ...state,
        discountRate: {
          ...state.discountRate,
          error: action.payload.errorRate
        }
      }
    case ModalCreateDiscountDateTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible, editMode = false } = action.payload

      return modalVisible
        ? {
            ...state,
            modalVisible,
            editMode
          }
        : initialState
    }
    case ModalCreateDiscountDateTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisible } = action.payload
      return deleteModalVisible
        ? {
            ...state,
            deleteModalVisible
          }
        : initialState
    }
    case ModalCreateDiscountDateTypes.SET_SELECTED_DISCOUNT:
      return {
        ...state,
        selectedDiscount: action.payload.selectedDiscount,
        selectedDate: action.payload.selectedDiscount?.date || state.selectedDate,
        discountRate: {
          ...state.discountRate,
          value: action.payload.selectedDiscount?.discount || state.discountRate.value
        }
      }
    default:
      return state
  }
}

export default createDiscountDatesReducer
