import { RemovalReason } from '../../../projectApi/TransactionHandler/Operation/Removals/reasons'

export type ModalReasonState = {
  modalOpen: boolean
  removalReasons: {
    fetching: boolean
    list: RemovalReason[]
    error: string
  }
  reasonSelected: number | string
  anotherReason: string
}

export enum GetRemovalReasons {
  GET_REMOVAL_REASONS_REQUEST = 'REMOVALS/MODAL_REASONS/GET_REMOVAL_REASONS_REQUEST',
  GET_REMOVAL_REASONS_SUCCESS = 'REMOVALS/MODAL_REASONS/GET_REMOVAL_REASONS_SUCCESS',
  GET_REMOVAL_REASONS_FAILURE = 'REMOVALS/MODAL_REASONS/GET_REMOVAL_REASONS_FAILURE'
}

export interface GetRemovalReasonsRequest {
  type: typeof GetRemovalReasons.GET_REMOVAL_REASONS_REQUEST
}

export interface GetRemovalReasonsSuccess {
  type: typeof GetRemovalReasons.GET_REMOVAL_REASONS_SUCCESS
  payload: {
    reasonsList: RemovalReason[]
  }
}

export interface GetRemovalReasonsFailure {
  type: typeof GetRemovalReasons.GET_REMOVAL_REASONS_FAILURE
  payload: { messageError: string }
}

type GetRemovalReasonsActions = GetRemovalReasonsRequest | GetRemovalReasonsSuccess | GetRemovalReasonsFailure

export enum ModalReasonsTypes {
  SET_MODAL_OPEN = 'REMOVALS/MODAL_REASONS/SET_MODAL_OPEN',
  SET_REASON_SELECTED = 'REMOVALS/MODAL_REASONS/SET_REASON_SELECTED',
  SET_ANOTHER_REASON = 'REMOVALS/MODAL_REASONS/SET_ANOTHER_REASON'
}

export interface SetModalOpen {
  type: ModalReasonsTypes.SET_MODAL_OPEN
  payload: { modalOpen: boolean }
}

export interface SetReasonSelected {
  type: typeof ModalReasonsTypes.SET_REASON_SELECTED
  payload: { reasonSelected: number | string }
}

export interface SetAnotherReason {
  type: typeof ModalReasonsTypes.SET_ANOTHER_REASON
  payload: { anotherReason: string }
}

export type ModalReasonsActions = SetModalOpen | GetRemovalReasonsActions | SetReasonSelected | SetAnotherReason
