import { TransportTypesPricing } from '../../../../common/operations/constants'
import { TransportDetail } from '../../../../projectApi/TransactionHandler/Compute/cost'
import { DestinationDeposit } from '../../../../projectApi/TransactionHandler/ServiceType/common'
import { TransportMode } from '../../../../projectApi/TransactionHandler/TransportMode/common'
import { CleanStates, GetTransactionDataAction } from './EditDepositView'

export interface TransportState {
  loadingTransportMode: boolean
  transportModeList: TransportMode[]
  transportMode: string
  requireTransport: boolean
  loadingDestinationDeposits: boolean
  destinationDepositsList: DestinationDeposit[]
  destinationDeposit: number
  address: {
    addressId: number
    addressString: string
    apartment: string
    city: string
    comment: string
    floor: string
    number: string
    postalCode: string
    province: string
    street: string
  }
  loadingFetchCost: boolean
  fetchedCost: number
  transportCost: number
  tollCostInCents: number
  transportTypePricing: TransportTypesPricing
  where: string
  transportDetail?: TransportDetail | null
}

export const GET_TRANSPORT_MODE_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_TRANSPORT_MODE_REQUEST'
export const GET_TRANSPORT_MODE_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_TRANSPORT_MODE_SUCCESS'
export const GET_TRANSPORT_MODE_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_TRANSPORT_MODE_FAILURE'

export interface GetTransportModeRequest {
  type: typeof GET_TRANSPORT_MODE_REQUEST
}

export interface GetTransportModeSuccess {
  type: typeof GET_TRANSPORT_MODE_SUCCESS
  payload: {
    transportModeList: TransportMode[]
  }
}

export interface GetTransportModeFailure {
  type: typeof GET_TRANSPORT_MODE_FAILURE
}

export type GetTransportModeActions = GetTransportModeRequest | GetTransportModeSuccess | GetTransportModeFailure

export const GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST'
export const GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS'
export const GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE'

export interface GetDepositByTransportModeRequest {
  type: typeof GET_DEPOSIT_BY_TRANSPORT_MODE_REQUEST
}

export interface GetDepositByTransportModeSuccess {
  type: typeof GET_DEPOSIT_BY_TRANSPORT_MODE_SUCCESS
  payload: {
    deposits: DestinationDeposit[]
  }
}

export interface GetDepositByTransportModeFailure {
  type: typeof GET_DEPOSIT_BY_TRANSPORT_MODE_FAILURE
}

export type GetDepositByTransportModeActions =
  | GetDepositByTransportModeRequest
  | GetDepositByTransportModeSuccess
  | GetDepositByTransportModeFailure

export const FETCH_COST_REQUEST = 'DEPOSIT/EDIT_DEPOSIT/FETCH_COST_REQUEST'
export const FETCH_COST_SUCCESS = 'DEPOSIT/EDIT_DEPOSIT/FETCH_COST_SUCCESS'
export const FETCH_COST_FAILURE = 'DEPOSIT/EDIT_DEPOSIT/FETCH_COST_FAILURE'

export interface FetchCostRequest {
  type: typeof FETCH_COST_REQUEST
}

export interface FetchCostSuccess {
  type: typeof FETCH_COST_SUCCESS
  payload: {
    fetchedCost: number
    cost: number
    where: string
    transportDetail: TransportDetail
    tollCostInCents: number
  }
}

export interface FetchCostFailure {
  type: typeof FETCH_COST_FAILURE
}

export type FetchCostActions = FetchCostRequest | FetchCostSuccess | FetchCostFailure

export const SET_TRANSPORT_MODE = 'DEPOSIT/EDIT_DEPOSIT/SET_TRANSPORT_MODE'
export const SET_DESTINATION_DEPOSIT = 'DEPOSIT/EDIT_DEPOSIT/SET_DESTINATION_DEPOSIT'
export const SET_WHERE_FETCH_COST = 'DEPOSIT/EDIT_DEPOSIT/SET_WHERE_FETCH_COST'
export const SET_CUSTOM_TRANSPORT_COST = 'DEPOSTI/EDIT_DEPOSIT/SET_CUSTOM_TRANSPORT_COST'

export interface SetTransportMode {
  type: typeof SET_TRANSPORT_MODE
  payload: {
    transportMode: string
  }
}

export interface SetDestinationDeposit {
  type: typeof SET_DESTINATION_DEPOSIT
  payload: {
    destinationDepositId: number
  }
}

export interface SetWhereFetchCost {
  type: typeof SET_WHERE_FETCH_COST
  payload: {
    where: string
  }
}

export interface SetCustomTransportCost {
  type: typeof SET_CUSTOM_TRANSPORT_COST
  payload: {
    transportTypePricing: TransportTypesPricing
    newTransportCost: number
  }
}

export type SetTransportData = SetTransportMode | SetDestinationDeposit | SetWhereFetchCost | SetCustomTransportCost

export type TransportActions =
  | GetTransactionDataAction
  | GetTransportModeActions
  | GetDepositByTransportModeActions
  | SetTransportData
  | FetchCostActions
  | CleanStates
