import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import {
  GET_DEPOSIT_HISTORY_FAILURE,
  GET_DEPOSIT_HISTORY_REQUEST,
  GET_DEPOSIT_HISTORY_SUCCESS,
  GetDepositHistoryFailure,
  GetDepositHistoryRequest,
  GetDepositHistorySuccess
} from '../types/depositHistory'

const DepositHistoryActionCreators = {
  getAudits:
    ({ depositId }: { depositId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetDepositHistoryRequest = {
        type: GET_DEPOSIT_HISTORY_REQUEST
      }

      dispatch(request)

      try {
        const { audits } = await API.TransactionHandler.Operation.audit({ operationId: depositId })

        const success: GetDepositHistorySuccess = {
          type: GET_DEPOSIT_HISTORY_SUCCESS,
          payload: {
            audits
          }
        }

        dispatch(success)
      } catch (error) {
        const failure: GetDepositHistoryFailure = {
          type: GET_DEPOSIT_HISTORY_FAILURE
        }

        dispatch(failure)
      }
    }
}

export default DepositHistoryActionCreators
