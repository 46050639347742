import { Shipper } from '../../../projectApi/Trips/Shippers/list'

export type Driver = {
  name: string
  lastName: string
  userId: number
}

export type Provider = {
  name: string
  userId: number
}

export type ShippersABMStateTypes = {
  modalVisible: boolean
  deleteModalVisible: boolean
  editMode: boolean
  currentShipper: Shipper | null
  selectedDriver: Driver | null
  selectedProvider: Provider | null
  driverList: Driver[]
  providerList: Provider[]
  fetchingDrivers: boolean
  fetchingProviders: boolean
  creatingShipper: boolean
  updatingShipper: boolean
  deletingShipper: boolean
  disabledDeleteButton: boolean
}

export enum SettersShippersModalTypes {
  SET_MODAL_VISIBILITY = 'SHIPPERS/ABM_SHIPPERS/SET_MODAL_VISIBILITY',
  SET_DELETE_MODAL_VISIBILITY = 'SHIPPERS/ABM_SHIPPERS/SET_DELETE_MODAL_VISIBILITY',
  SET_CURRENT_SHIPPER = 'SHIPPERS/ABM_SHIPPERS/SET_CURRENT_SHIPPER',
  SET_DRIVER = 'SHIPPERS/ABM_SHIPPERS/SET_DRIVER',
  SET_PROVIDER = 'SHIPPERS/ABM_SHIPPERS/SET_PROVIDER'
}

export interface SetModalVisibility {
  type: typeof SettersShippersModalTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean; editMode?: boolean }
}

export interface SetDeleteModalVisibility {
  type: typeof SettersShippersModalTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisible: boolean }
}

export interface SetCurrentShipper {
  type: typeof SettersShippersModalTypes.SET_CURRENT_SHIPPER
  payload: { shipper: Shipper | null }
}

export interface SetDriver {
  type: typeof SettersShippersModalTypes.SET_DRIVER
  payload: { driver: Driver | null }
}

export interface SetProvider {
  type: typeof SettersShippersModalTypes.SET_PROVIDER
  payload: { provider: Provider | null }
}

type SettersShippersModalActions =
  | SetModalVisibility
  | SetDeleteModalVisibility
  | SetCurrentShipper
  | SetDriver
  | SetProvider

export enum GetDriverListTypes {
  GET_DRIVER_LIST_REQUEST = 'SHIPPERS/ABM_SHIPPERS/GET_DRIVER_LIST_REQUEST',
  GET_DRIVER_LIST_SUCCESS = 'SHIPPERS/ABM_SHIPPERS/GET_DRIVER_LIST_SUCCESS',
  GET_DRIVER_LIST_FAILURE = 'SHIPPERS/ABM_SHIPPERS/GET_DRIVER_LIST_FAILURE'
}

export interface GetDriverListRequest {
  type: typeof GetDriverListTypes.GET_DRIVER_LIST_REQUEST
}

export interface GetDriverListSuccess {
  type: typeof GetDriverListTypes.GET_DRIVER_LIST_SUCCESS
  payload: { drivers: Driver[] }
}

export interface GetDriverListFailure {
  type: typeof GetDriverListTypes.GET_DRIVER_LIST_FAILURE
}

type GetDriverListActions = GetDriverListRequest | GetDriverListSuccess | GetDriverListFailure

export enum GetProviderListTypes {
  GET_PROVIDER_LIST_REQUEST = 'SHIPPERS/ABM_SHIPPERS/GET_PROVIDER_LIST_REQUEST',
  GET_PROVIDER_LIST_SUCCESS = 'SHIPPERS/ABM_SHIPPERS/GET_PROVIDER_LIST_SUCCESS',
  GET_PROVIDER_LIST_FAILURE = 'SHIPPERS/ABM_SHIPPERS/GET_PROVIDER_LIST_FAILURE'
}

export interface GetProviderListRequest {
  type: typeof GetProviderListTypes.GET_PROVIDER_LIST_REQUEST
}

export interface GetProviderListSuccess {
  type: typeof GetProviderListTypes.GET_PROVIDER_LIST_SUCCESS
  payload: { providers: Provider[] }
}

export interface GetProviderListFailure {
  type: typeof GetProviderListTypes.GET_PROVIDER_LIST_FAILURE
}

type GetProvidersActions = GetProviderListRequest | GetProviderListSuccess | GetProviderListFailure

export enum CreateShipperTypes {
  CREATE_SHIPPER_REQUEST = 'SHIPPERS/ABM_SHIPPERS/CREATE_SHIPPER_REQUEST',
  CREATE_SHIPPER_SUCCESS = 'SHIPPERS/ABM_SHIPPERS/CREATE_SHIPPER_SUCCESS',
  CREATE_SHIPPER_FAILURE = 'SHIPPERS/ABM_SHIPPERS/CREATE_SHIPPER_FAILURE'
}

export interface CreateShipperRequest {
  type: typeof CreateShipperTypes.CREATE_SHIPPER_REQUEST
}

export interface CreateRequestSuccess {
  type: typeof CreateShipperTypes.CREATE_SHIPPER_SUCCESS
}

export interface CreateRequestFailure {
  type: typeof CreateShipperTypes.CREATE_SHIPPER_FAILURE
}

type CreateShipperActions = CreateShipperRequest | CreateRequestSuccess | CreateRequestFailure

export enum UpdateShipperTypes {
  UPDATE_SHIPPER_REQUEST = 'SHIPPERS/ABM_SHIPPERS/UPDATE_SHIPPER_REQUEST',
  UPDATE_SHIPPER_SUCCESS = 'SHIPPERS/ABM_SHIPPERS/UPDATE_SHIPPER_SUCCESS',
  UPDATE_SHIPPER_FAILURE = 'SHIPPERS/ABM_SHIPPERS/UPDATE_SHIPPER_FAILURE'
}

export interface UpdateShipperRequest {
  type: typeof UpdateShipperTypes.UPDATE_SHIPPER_REQUEST
}

export interface UpdateShipperSuccess {
  type: typeof UpdateShipperTypes.UPDATE_SHIPPER_SUCCESS
}

export interface UpdateShipperFailure {
  type: typeof UpdateShipperTypes.UPDATE_SHIPPER_FAILURE
}

type UpdateShipperActions = UpdateShipperRequest | UpdateShipperSuccess | UpdateShipperFailure

export enum DeleteShipperTypes {
  DELETE_SHIPPER_REQUEST = 'SHIPPERS/ABM_SHIPPERS/DELETE_SHIPPER_REQUEST',
  DELETE_SHIPPER_SUCCESS = 'SHIPPERS/ABM_SHIPPERS/DELETE_SHIPPER_SUCCESS',
  DELETE_SHIPPER_FAILURE = 'SHIPPERS/ABM_SHIPPERS/DELETE_SHIPPER_FAILURE'
}

export interface DeleteShipperRequest {
  type: typeof DeleteShipperTypes.DELETE_SHIPPER_REQUEST
}

export interface DeleteShipperSuccess {
  type: typeof DeleteShipperTypes.DELETE_SHIPPER_SUCCESS
}

export interface DeleteShipperFailure {
  type: typeof DeleteShipperTypes.DELETE_SHIPPER_FAILURE
}

type DeleteShipperActions = DeleteShipperRequest | DeleteShipperSuccess | DeleteShipperFailure

export type ShippersABMActions =
  | SettersShippersModalActions
  | GetDriverListActions
  | GetProvidersActions
  | CreateShipperActions
  | UpdateShipperActions
  | DeleteShipperActions
