import types from '../types/calculateBillingModal'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const calculateBillingFromDateTimeout = 2000

export default {
  calculateBillingFromDate(billingStart, retroactiveDate) {
    return (dispatch) => {
      dispatch({ type: types.CALCULATE_BILLING_REQUEST })
      return apiPrivate
        .post(
          URL_BASE_TRANSACTIONS +
            `/subscription-billing?billing_start=${billingStart}&retroactive_start=${retroactiveDate}`,
          undefined,
          { timeout: calculateBillingFromDateTimeout }
        )
        .then((_) => {
          dispatch({ type: types.CALCULATE_BILLING_SUCCESS })
        })
        .catch((error) => {
          const message = 'El proceso de calculo de abonos se inició correctamente'
          if (
            error.code === 'ECONNABORTED' &&
            error.message === `timeout of ${calculateBillingFromDateTimeout}ms exceeded`
          ) {
            dispatch({
              type: types.CALCULATE_BILLING_SUCCESS,
              payload: { message }
            })
          } else {
            handleErrorsWithAction(error, types.CALCULATE_BILLING_FAILURE, dispatch)
          }
        })
    }
  },

  openCalculateBillingModal() {
    return { type: types.OPEN_MODAL_CALCULATE_BILLING, payload: {} }
  },

  closeCalculateBillingModal() {
    return { type: types.CLOSE_MODAL_CALCULATE_BILLING }
  }
}
