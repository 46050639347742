import { apiPrivate } from '@/api'
import { URL_BASE_CATEGORIES } from '@/endpoints'
import snakecaseKeys from 'snakecase-keys'

export type ProductByCatalog = {
  description: string
  weightInGr: number
  lengthInCm: number
  widthInCm: number
  heightInCm: number
  photoMin: number
  multiplier: number
  solution: number
  oldCategory: number[]
  productCatalogId: string
  catalogName: string
  category: {
    externalId: string
    catalogName: string
  }
  productByCountries: ProductByCountry[]
}

type ProductByCountry = {
  name: string
  countryId: string
  photo: {
    url: string
  }
  depositCostPerFloorInCents: number
  packagingCostInCents: number
  disassemblyCost: number
  costPerFloorInCents: number
  assemblyCostInCents: number
  unpackagingCostInCents: number
  maxAmountAvowedInCents: number
  percentageAvowed: number
  maxRefundAmountInCents: number
  eligibleForSale: boolean
  eligibleForDonation: boolean
  eligibleForRecycled: boolean
  eligibleForRent: boolean
}

export function create(params: ProductByCatalog): Promise<void> {
  const body = snakecaseKeys({ ...params, weightInGr: params.weightInGr * 100, isFromCatalog: true })
  return apiPrivate
    .post(`${URL_BASE_CATEGORIES}/products`, body)
    .then(() => {})
    .catch(() => {})
}
