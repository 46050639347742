import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import ComplaintUpdateViewActionCreator from '../actionCreators/updateView'
import EvidenceModal from '../components/UpdateViewComponents/EvidenceModal'
import { useHistory, useParams } from 'react-router'
import { useLocalization } from '@/packages/localization'
import styles from '../components/ComplaintUpdateView.module.scss'
import ComplaintDetails from '../components/UpdateViewComponents/ComplaintDetails'
import ComplaintHistoryComponent from '../components/UpdateViewComponents/ComplaintHistory'
import ClientDetails from '../components/UpdateViewComponents/ClientDetails'
import { TableActionBar } from '@/components/TableActionBar'
import { useEvents } from '@/utils/eventEmitter'
import { Events } from '@/utils/eventEmitter/events'
import { Response as PhotoResponse } from '@/projectApi/CategoryCreation/Photos/uploadPhoto'
import { Spin } from 'antd'
import ResolveComplaintActionCreator from '../actionCreators/resolveModal'
import ResolveModal from './ResolveModal'
import ConfirmResolveComponent from '../components/ConfirmResolveModal'
import { StatusStrings } from '../utils'

const ComplaintUpdateViewContainer = () => {
  const dispatch = useDispatch()
  const {
    getComplaintById,
    setOpenEvidence,
    setDescription,
    setPriority,
    setAgent,
    setEvidence,
    updateComplaint,
    getReasons,
    getAgents,
    deleteEvidence
  } = bindActionCreators(ComplaintUpdateViewActionCreator, dispatch)

  const { setOpenResolve, closeComplaint, setConfirmationOpen, setReasonId } = bindActionCreators(
    ResolveComplaintActionCreator,
    dispatch
  )
  const {
    complaint,
    isFetchingComplaint,
    selectedEvidence,
    openEvidence,
    priority,
    assignedUserId,
    agents,
    fetchingAgents,
    reasons,
    photos,
    description: descriptionUpdate
  } = useSelector((state) => state.Complaints.updateView)

  const { photos: photosResolve, confirmationOpen, reasonId } = useSelector((state) => state.Complaints.resolve)

  const { strings } = useLocalization()
  const history = useHistory()

  const useParsedParams = (): { complaintId: number } => {
    const data = useParams<{ complaintId: string }>()

    return { complaintId: parseInt(data.complaintId) }
  }

  const { complaintId } = useParsedParams()

  const onUploadedPhoto = (photos: PhotoResponse[]) => {
    const photosEvidence = photos.map((photo) => ({ id: 0, url: photo.url, fileName: photo.s3Key }))
    setEvidence(photosEvidence)
  }

  const { loadingPhoto } = useSelector((state) => state.UploadPhotos)

  useEvents(Events.UploadPhotos.UPLOAD_PHOTO_SUCCESS, onUploadedPhoto)

  useEvents(Events.Complaints.COMPLAINTS_UPDATED, () => {
    getComplaintById(complaintId, true)
  })

  const handleCloseEvidence = () => {
    setOpenEvidence({ setOpenEvidence: false })
  }

  useEffect(() => {
    if (!complaintId) return
    getComplaintById(complaintId)
    getAgents()
    getReasons()
  }, [])

  if (isFetchingComplaint) {
    return (
      <div className={styles.containerCenter}>
        <Spin size="large" />
        {strings.ComplaintsViewScreen.updateView.loadingComplaint}
      </div>
    )
  }

  if (!complaint || !complaint.id) {
    return (
      <div className={styles.containerCenter}>
        {strings.ComplaintsViewScreen.updateView.emptyState}
        <TableActionBar.ButtonAction
          customIcon={<></>}
          titleButton={strings.generic.back}
          customClassName={styles.btnAction}
          onClickButton={() => history.push('/complaints')}
        />
      </div>
    )
  }

  const { status, createUserId, clientId } = complaint

  const handleConfirmClose = () => {
    closeComplaint({ complaintId, photos: photosResolve, reasonId })
    setConfirmationOpen({ confirmationOpen: false })
    setReasonId(0)
    history.push('/complaints')
  }

  const handleConfirm = () => {
    updateComplaint({
      userId: clientId,
      priority,
      status,
      assignedUserId,
      description: descriptionUpdate,
      photos,
      complaintId
    })
    // getComplaintById(complaintId)
  }

  const isCloseDisable = complaint.status === StatusStrings.CLOSED || complaint.status === StatusStrings.CLOSED_DELAYED

  const EvidenceModalProps = {
    handleCloseEvidence,
    selectedEvidence,
    openEvidence
  }

  const ComplaintDetailsProps = {
    setPriority,
    setDescription,
    descriptionUpdate,
    priority,
    setAgent,
    assignedUserId,
    fetchingAgents,
    agents,
    updateComplaint,
    createUserId,
    complaintId,
    handleConfirm,
    loadingPhoto,
    isCloseDisable,
    deleteEvidence
  }

  const complaintHistoryProps = {
    setOpenEvidence,
    complaintHistories: complaint.complaintHistories,
    isCloseDisable
  }

  const ConfirmResolveProps = {
    confirmationOpen,
    setConfirmationOpen,
    setOpenResolve,
    handleConfirmClose
  }

  return (
    <div
      style={{
        maxHeight: '100vh'
      }}>
      <h1 className={styles.headerTitle}>{strings.ComplaintsViewScreen.updateView.title}</h1>
      <div className={styles.containerView}>
        <section className={styles.sectionUpdateView}>
          {isCloseDisable ? null : <ComplaintDetails {...ComplaintDetailsProps} />}

          <ComplaintHistoryComponent {...complaintHistoryProps} />
        </section>

        <aside className={styles.clientView}>
          <ClientDetails complaint={complaint} reasons={reasons} />
        </aside>
      </div>
      {isCloseDisable ? null : (
        <TableActionBar
          hideBorder
          customStyles={{
            paddingBottom: '2rem'
          }}>
          <TableActionBar.ButtonAction
            customIcon={<></>}
            titleButton={strings.ComplaintsViewScreen.updateView.buttons.close}
            customClassName={styles.btnAction}
            onClickButton={() => setOpenResolve({ open: true })}
          />
        </TableActionBar>
      )}

      {selectedEvidence && <EvidenceModal {...EvidenceModalProps} />}
      <ResolveModal />
      <ConfirmResolveComponent {...ConfirmResolveProps} />
    </div>
  )
}

export default ComplaintUpdateViewContainer
