import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { GetParentAccountPermissionsSuccess, UserProfileTypes } from '../types/userProfile'
import { API } from '../../../projectApi'
import { Response as RoleAndPermissionsResponse } from '../../../projectApi/AccountManager/Roles/getByParentId'

const actions = {
  getParentAccountPermissions:
    (parentId: number): AppThunk =>
    async (dispatch): Promise<boolean> => {
      dispatch({ type: UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_REQUEST })

      try {
        const roleAndPermissions = await API.AccountManager.Roles.getByParentId({ parentId })
        dispatch(actions.getParentAccountPermissionsSuccess(roleAndPermissions))
        return true
      } catch (e) {
        handleErrorsWithAction(e, UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_FAILURE, dispatch)
        return false
      }
    },

  getParentAccountPermissionsSuccess: (
    roleAndPermissions: RoleAndPermissionsResponse
  ): GetParentAccountPermissionsSuccess => ({
    type: UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_SUCCESS,
    payload: { roleAndPermissions }
  })
}

export default actions
