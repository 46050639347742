/* eslint-disable camelcase */
import axios, { AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

export enum OperationType {
  DEPOSIT = 'deposit_operations',
  REMOVAL = 'removal_operations'
}

interface APIError {
  message: string
  error: string
  status: number
  cause: string | null
}

type APICancelReason = {
  id: number
  name: string
  description: string
  operation_type: string
  available: boolean
}

export type CancelReason = CamelCasedPropertiesDeep<APICancelReason>

type APIResponse = {
  cancel_reasons: APICancelReason[]
}

type Response = CamelCasedPropertiesDeep<APIResponse>

export interface ListParams {
  operationType: OperationType
}

export function list(listParams: ListParams): Promise<Response> {
  const params = snakecaseKeys({ ...listParams })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operations/cancel-reasons`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data.cancel_reasons || response.data.cancel_reasons?.length <= 0)
        throw new Error('Error al obtener los motivos de cancelación')

      const camelResponse: Response = {
        cancelReasons: camelcaseKeys(response.data.cancel_reasons)
      }

      return camelResponse
    })
    .catch((error: AxiosError<APIError> | Error) => {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data.message
        throw errorMessage
      } else throw error
    })
}
