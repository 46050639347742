/* eslint-disable camelcase */
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

interface UpdateParams {
  userId: number
  newEmail: string
  oldEmail: string
}

export function email({ userId, newEmail, oldEmail }: UpdateParams): Promise<void> {
  const data = snakecaseKeys({ userId, newEmail, oldEmail })
  return apiPrivate
    .put(`${URL_BASE_USERS}/change/email`, data)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
