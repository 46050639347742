import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CreateDateModal from '../components/createDateModal'
import OperationDatesActionCreator from '../actions/operationDatesAction'
import OperationCalendarActionsCreator from '../actions/common'

const CreateDateModalContainer = () => {
  const dispatch = useDispatch()
  const { isCreateModalOpen, selectedCreateDate } = useSelector((state) => state.OperationCalendar.Dates)
  const { selectedOperations, availableOperations } = useSelector((state) => state.OperationCalendar.Common)
  const { openCreateDateModal, setSelectedCreateDate, createDate } = bindActionCreators(
    OperationDatesActionCreator,
    dispatch
  )
  const { setSelectedOperations, setAvailableOperations } = bindActionCreators(
    OperationCalendarActionsCreator,
    dispatch
  )

  const props = {
    openCreateDateModal,
    isCreateModalOpen,
    selectedCreateDate,
    setSelectedCreateDate,
    createDate,
    setSelectedOperations,
    selectedOperations,
    availableOperations,
    setAvailableOperations
  }

  return <CreateDateModal {...props} />
}

export default CreateDateModalContainer
