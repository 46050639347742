import { Reducer } from 'redux'
import {
  GET_PRICE_BANDS_FAILURE,
  GET_PRICE_BANDS_REQUEST,
  GET_PRICE_BANDS_SUCCESS,
  PriceBandsTableAction,
  PriceBandsTableState
} from '../types/priceBandsTable'

const initialState: PriceBandsTableState = {
  priceBands: [],
  loading: false,
  error: '',
  pagination: {
    page: 1,
    total: 0,
    pageSize: 50
  },
  singleDateFilters: [
    {
      key: 'dateFrom',
      date: null
    }
  ]
}

const PriceBandsTableReducer: Reducer<PriceBandsTableState, PriceBandsTableAction> = (
  state = initialState,
  action
): PriceBandsTableState => {
  switch (action.type) {
    case GET_PRICE_BANDS_REQUEST:
      return {
        ...state,
        loading: !action.payload.silentLoading,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        singleDateFilters: action.payload.singleDateFilters || initialState.singleDateFilters
      }
    case GET_PRICE_BANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        priceBands: action.payload.priceBands,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GET_PRICE_BANDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default PriceBandsTableReducer
