import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { sendToastNotificationError } from '../../../utils/notifications'
import {
  DashboardDateRangeFilter,
  GetDashboardAnnualMetricsRequest,
  GetDashboardAnnualMetricsSuccess,
  GetDashboardAnnualMetricsTypes,
  GetDashboardMetricsRequest,
  GetDashboardMetricsSuccess,
  GetDashboardMetricsTypes
} from '../types/Dashboard.types'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { dateRangeFiltersToParams } from '../../../utils/searchFilterUtils'

const dashboardMetricsActionsCreator = {
  getMetrics:
    ({
      dateRangeFilter,
      countryCode
    }: {
      dateRangeFilter: DashboardDateRangeFilter[]
      countryCode: CountryIdCode
    }): AppThunk =>
    async (dispatch) => {
      const request: GetDashboardMetricsRequest = {
        type: GetDashboardMetricsTypes.GET_METRICS_REQUEST,
        payload: { dateRangeFilter }
      }

      dispatch(request)

      try {
        const { metrics } = await API.TransactionHandler.Dashboard.metrics({
          countryCode,
          ...dateRangeFiltersToParams(dateRangeFilter)
        })

        const success: GetDashboardMetricsSuccess = {
          type: GetDashboardMetricsTypes.GET_METRICS_SUCCESS,
          payload: { metrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(error, GetDashboardMetricsTypes.GET_METRICS_FAILURE, dispatch)
      }
    },
  getAnnualMetrics:
    ({
      countryCode,
      dateRangeFilter
    }: {
      countryCode: CountryIdCode
      dateRangeFilter: DashboardDateRangeFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetDashboardAnnualMetricsRequest = {
        type: GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_REQUEST
      }

      dispatch(request)
      try {
        const { annualMetrics } = await API.TransactionHandler.Dashboard.annualMetrics({
          countryCode,
          year: parseInt(dateRangeFilter[0].startDate.format('YYYY'))
        })

        const success: GetDashboardAnnualMetricsSuccess = {
          type: GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_SUCCESS,
          payload: { annualMetrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas anuales')
        handleErrorsWithAction(error, GetDashboardAnnualMetricsTypes.GET_ANNUAL_METRICS_FAILURE, dispatch)
      }
    }
}

export default dashboardMetricsActionsCreator
