import { list } from './list'
import { create } from './create'
import { update } from './update'
import { deleteit } from './delete'

export const ProvidersAPI = {
  list,
  create,
  update,
  delete: deleteit
}
