import React from 'react'
import { GenericObject } from '../utilityTypes'

const useInjectChildrenWithProps = <T extends GenericObject>(
  children: React.ReactNode,
  getExtraProps: (child: React.ReactElement) => T
) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, getExtraProps(child))
    }
    return child
  })

  return childrenWithProps
}

export default useInjectChildrenWithProps
