import { SortOrder } from 'antd/es/table/interface'
import { PaginationConfig } from 'antd/lib/pagination'
import { DateRangeFilter } from '../../../utils/searchFilterUtils'
import { Moment } from 'moment'

export enum LocationsTableTypes {
  FETCH_LOCATIONS_REQUEST = 'LOCATIONS/FETCH_LOCATIONS_REQUEST',
  FETCH_LOCATIONS_SUCCESS = 'LOCATIONS/FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATIONS_FAILURE = 'LOCATIONS/FETCH_LOCATIONS_FAIL',
  FETCH_LOCATIONS_INFO_REQUEST = 'LOCATIONS/FETCH_LOCATIONS_INFO_REQUEST',
  FETCH_LOCATIONS_INFO_SUCCESS = 'LOCATIONS/FETCH_LOCATIONS_INFO_SUCCESS',
  FETCH_LOCATIONS_INFO_FAILURE = 'LOCATIONS/FETCH_LOCATIONS_INFO_FAIL',
  UPDATE_LOCATIONS_REQUEST = 'LOCATIONS/UPDATE_LOCATIONS_REQUEST',
  UPDATE_LOCATIONS_SUCCESS = 'LOCATIONS/UPDATE_LOCATIONS_SUCCESS',
  UPDATE_LOCATIONS_FAILURE = 'LOCATIONS/UPDATE_LOCATIONS_FAIL',
  SET_PAGINATION = 'LOCATIONS/SET_PAGINATION',
  SET_SORTER = 'LOCATIONS/SET_SORTER',
  SET_FILTER = 'LOCATIONS/SET_FILTER',
  SET_SEARCH_FILTERS = 'LOCATIONS/SET_SEARCH_FILTERS',
  CLEAN_FILTERS = 'BILLINGS/BILLINGS_BY_CLIENT/CLEAN_FILTERS',
  TOGGLE_EXACT_LOCATION_CODE = 'LOCATIONS/TOGGLE_EXACT_LOCATION_CODE'
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export type Params = {
  Limit: number
  Offset: number
  column: string
  order: Order
  exactLocationCode: boolean
  depositId?: number
} & { [key: string]: string | number | boolean | undefined }

type ColumnsFilter = 'active' | 'type'

export enum ColumnsSearchFilters {
  ID = 'id',
  DEPOSITID = 'deposit_id',
  CODE = 'code',
  DESCRIPTION = 'description',
  DEPOSIT_NAME = 'deposit_name',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at'
}

export type LocationFilter = Partial<Record<ColumnsFilter, string[]>>

export type LocationSorter = {
  order: SortOrder
  field: string
  columnKey: string
}

export type LocationSearchFilter = {
  key: ColumnsSearchFilters
  text: string | undefined
}

export type Location = {
  active: boolean
  code: string
  depositId: number
  description: string
  id: number
  loadRate: number
  maxHeightInCm: number
  maxLengthInCm: number
  maxVolumeInCm: number
  maxWeightInGr: number
  maxWidthInCm: number
  occupancyRate: number
  quantityObjects: number
  type: string
  volumeInCm: number
  weightInGr: number
  createdAt: Moment
  updatedAt: Moment
}

export type LocationInfo = {
  volumeInCm: number
  maxVolumeInCm: number
  weightInGr: number
  maxWeightInGr: number
  quantityObjects: number
  quantityLocations: number
  occupancyRate: number
  loadRate: number
  totalObjects?: number
  totalM3?: number
}

export type FetchLocationsRequest = {
  type: LocationsTableTypes.FETCH_LOCATIONS_REQUEST
}

export type FetchLocationsSuccess = {
  type: LocationsTableTypes.FETCH_LOCATIONS_SUCCESS
  payload: {
    locations: Location[]
    quantity: number
    params: Params
  }
}

export type FetchLocationsFailure = {
  type: LocationsTableTypes.FETCH_LOCATIONS_FAILURE
  payload: {
    error: string
  }
}

export type FetchLocationInfoRequest = {
  type: LocationsTableTypes.FETCH_LOCATIONS_INFO_REQUEST
}

export type FetchLocationInfoSuccess = {
  type: LocationsTableTypes.FETCH_LOCATIONS_INFO_SUCCESS
  payload: {
    locationInfo: LocationInfo
  }
}

export type FetchLocationInfoFailure = {
  type: LocationsTableTypes.FETCH_LOCATIONS_INFO_FAILURE
  payload: {
    error: string
  }
}

export type UpdateLocationRequest = {
  type: LocationsTableTypes.UPDATE_LOCATIONS_REQUEST
}

export type UpdateLocationSuccess = {
  type: LocationsTableTypes.UPDATE_LOCATIONS_SUCCESS
}

export type UpdateLocationFailure = {
  type: LocationsTableTypes.UPDATE_LOCATIONS_FAILURE
  payload: {
    error: string
  }
}

export enum LocationsTableDateRangeKey {
  PERIOD = 'period'
}

export type LocationsTableDateRangeFilter = DateRangeFilter<LocationsTableDateRangeKey>

export type SetPagination = {
  type: LocationsTableTypes.SET_PAGINATION
  payload: {
    pagination: PaginationConfig
  }
}

export type SetSorter = {
  type: LocationsTableTypes.SET_SORTER
  payload: {
    sorter: LocationSorter
  }
}

export type SetFilter = {
  type: LocationsTableTypes.SET_FILTER
  payload: {
    filter: LocationFilter
  }
}

export type SetSearchFilters = {
  type: LocationsTableTypes.SET_SEARCH_FILTERS
  payload: {
    searchFilters: LocationSearchFilter[]
  }
}

export type CleanFilters = {
  type: LocationsTableTypes.CLEAN_FILTERS
}

export type LocationsTableActions =
  | FetchLocationsRequest
  | FetchLocationsSuccess
  | FetchLocationsFailure
  | FetchLocationInfoRequest
  | FetchLocationInfoSuccess
  | FetchLocationInfoFailure
  | SetPagination
  | SetSorter
  | SetFilter
  | SetSearchFilters
  | CleanFilters
  | UpdateLocationRequest
  | UpdateLocationSuccess
  | UpdateLocationFailure
