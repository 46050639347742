import { SortDirection } from '../../../components/DynamicTable/types/types'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  CreateGenericObjectLabelsTypes,
  CreateGenericObjectLabelsRequest,
  CreateGenericObjectLabelsSuccess,
  DevelopmentCommonTypes,
  GenericParams,
  ObjectLabelModal,
  FetchCodeGenTypes,
  GetUsersRequest,
  GetUsersTypes
} from '../types/developmentView'

const LIMIT = 10
let searchTimer: NodeJS.Timeout
let getUsersOldLastSearchId: number

const generateNewCode = (codeGEN: string) => {
  const digitsRegex = /(\d)*$/
  const codeNumbers = digitsRegex.exec(codeGEN)?.[0] || '0'
  const codeAdd = parseInt(codeNumbers) + 1
  const stringAdd = codeAdd.toString().padStart(codeNumbers.length, '0')
  return codeGEN.substring(0, codeGEN.length - codeNumbers.length) + stringAdd
}

const DevelopmentViewActionCreator = {
  clearState(): AppThunk {
    return (dispatch) => {
      dispatch({ type: DevelopmentCommonTypes.CLEAR_STATE })
    }
  },
  setObjectLabelModal: (objectLabelModal: boolean): ObjectLabelModal => {
    return { type: DevelopmentCommonTypes.OBJECT_LABEL_MODAL, payload: { objectLabelModal } }
  },
  createGenericObjectLabels:
    (genericParams: GenericParams): AppThunk =>
    async (dispatch) => {
      const request: CreateGenericObjectLabelsRequest = {
        type: CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_REQUEST
      }
      const toastId = setToastLoading('Generando etiquetas, por favor espere...')
      dispatch(request)

      try {
        await API.ObjectAdministration.Object.generic(genericParams)
        const success: CreateGenericObjectLabelsSuccess = {
          type: CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Etiquetas generadas con éxito.' })
        Emitter.emit(Events.Development.CREATE_OBJECT_LABELS)
      } catch (err) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(
            err,
            CreateGenericObjectLabelsTypes.CREATE_GENERIC_OBJECT_LABELS_FAILURE,
            dispatch
          )
        })
      }
    },
  getCodeGEN: (): AppThunk => async (dispatch) => {
    dispatch({ type: FetchCodeGenTypes.FETCH_CODE_GEN_REQUEST })

    try {
      const response = await API.ObjectAdministration.IdentificationCode.list({
        limit: 1,
        offset: 0,
        order: SortDirection.DESC,
        code: 'GEN'
      })

      const codeGEN = response.results[0].code

      dispatch({
        type: FetchCodeGenTypes.FETCH_CODE_GEN_SUCCESS,
        payload: { code: generateNewCode(codeGEN) }
      })
    } catch (err) {
      handleErrorsWithAction(err, FetchCodeGenTypes.FETCH_CODE_GEN_FAILURE, dispatch)
    }
  },
  getUsers:
    (id?: number): AppThunk =>
    (dispatch) => {
      const request: GetUsersRequest = {
        type: GetUsersTypes.GET_USERS_REQUEST
      }

      dispatch(request)
      clearTimeout(searchTimer)
      searchTimer = setTimeout(async () => {
        const params = {
          limit: LIMIT,
          offset: 0,
          id
        }

        const lastSearchID = new Date().getMilliseconds()
        getUsersOldLastSearchId = lastSearchID

        dispatch({ type: GetUsersTypes.GET_USERS_REQUEST })

        try {
          if (lastSearchID === getUsersOldLastSearchId) {
            const { users } = await API.AccountManager.Users.list(params)
            dispatch({
              type: GetUsersTypes.GET_USERS_SUCCESS,
              payload: {
                users
              }
            })
          }
        } catch (e: any) {
          handleErrorsWithAction(e, GetUsersTypes.GET_USERS_FAILURE, dispatch)
        }
      }, 250)
    }
}

export default DevelopmentViewActionCreator
