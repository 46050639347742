import { Moment } from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { Transaction } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import { Trip, TripStatusId } from '../../../projectApi/Trips/Trip/common'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import TransactionOperatorsAssignmentActionCreators from '../../TransactionOperatorsAssignment/actionCreators'
import TransactionOperatorsAssignmentContainer from '../../TransactionOperatorsAssignment/container'
import TripsCommonActionCreators from '../actionCreators/common'
import TripModalActionCreators from '../actionCreators/tripModal'
import TripsTableActionCreators from '../actionCreators/tripsTable'
import tripsReportsActionCreators from '../actionCreators/tripReports'
import TripsTable, { TripsTableProps } from '../components/tripsTable'
import { CommonDestination } from '../types/common'
import {
  Pagination,
  TripWithTransactions,
  TripsDateRangeFilter,
  TripsSearchFilter,
  TripsTableCategoryFilter,
  TripsTableDateRangeFilterKey,
  TripsTableDateRangeKey,
  TripsTableSearchFilterKey,
  TripsTableSearchKey,
  TripsTableSort
} from '../types/tripsTable'
import TripModalContainer from './tripModal'
import tripsMetricsActionsCreator from '../actionCreators/tripsMetrics'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import {
  removeNullishValues,
  updateMultipleDateRangeFilters,
  updateMultipleSearchFilters
} from '../../../components/DynamicTable/queryParamsUtils'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import TripsMetrics from '../components/tripsMetrics'
// import snakecaseKeys from 'snakecase-keys'

const TripsTableContainer = () => {
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.Trips.table)
  const { fetchingMetrics, quantityMetrics } = useSelector((root) => root.Trips.metrics)
  const dispatch = useDispatch()
  const {
    getTrips,
    startTrip,
    cancelTrip,
    finishTrip,
    setCancelingModalId,
    toggleSelectedTransaction,
    toggleTripTransactions,
    removeTransaccionFromTrip,
    toggleSelectedTrip,
    toggleAllTrips,
    clearSelectedTrips,
    clearState
  } = bindActionCreators(TripsTableActionCreators, dispatch)
  const { sendCSV, sendTransactionsReport } = bindActionCreators(tripsReportsActionCreators, dispatch)
  const { getTripTransactions } = bindActionCreators(TripsCommonActionCreators, dispatch)
  const { setOpen } = bindActionCreators(TripModalActionCreators, dispatch)
  const { setOpen: setOperatorAssignmentModalOpen } = bindActionCreators(
    TransactionOperatorsAssignmentActionCreators,
    dispatch
  )
  const { getMetrics } = bindActionCreators(tripsMetricsActionsCreator, dispatch)

  const [query, setQuery] = useQueryParams({
    id: StringParam,
    tripStatus: NumberParam,
    createdAtFrom: StringParam,
    createdAtTo: StringParam,
    scheduledStartDateFrom: StringParam,
    scheduledStartDateTo: StringParam,
    scheduledEndDateFrom: StringParam,
    scheduledEndDateTo: StringParam,
    realEndDateFrom: StringParam,
    realEndDateTo: StringParam,
    realStartDateFrom: StringParam,
    realStartDateTo: StringParam,
    limit: NumberParam,
    offset: NumberParam,
    sort: StringParam,
    order: StringParam,
    page: NumberParam
  })

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetTrips = async (newParams: {
    pagination?: Pagination
    searchFilters?: TripsSearchFilter[]
    dateRangeFilters?: TripsDateRangeFilter[]
    categoryFilter?: TripsTableCategoryFilter
    sort?: TripsTableSort
    silentLoading?: boolean
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      sort: newParams.sort || tableState.sort,
      silentLoading: newParams.silentLoading
    }

    const tripsSuccess = await getTrips(actualParams)

    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true),
      tripStatus: actualParams.categoryFilter.tripStatus,
      sort: actualParams.sort
    }
    setQuery(removeNullishValues(newQuery), 'push')

    handleGetTripsMetrics({ newRangeFilters: actualParams.dateRangeFilters })

    return tripsSuccess
  }

  const updateTripAndTransactions = async (tripId: number) => {
    const tripsSuccess = await handleGetTrips({ silentLoading: true })

    if (tripsSuccess as unknown as boolean) {
      const trip = tableState.trips.find((trip) => trip.id === tripId)
      const tripHasLoadedTransactions = trip && trip.transactions.length
      if (tripHasLoadedTransactions) handleGetTripTransactions(tripId)
    }
  }

  useEvents(Events.Trips.TRIP_CREATED, () => handleGetTrips({}))
  useEvents([Events.Trips.TRIP_UPDATED, Events.Trips.TRIP_CANCELED], async (tripId) =>
    updateTripAndTransactions(tripId)
  )
  useEvents(Events.Transaction.TRANSACTIONS_REMOVED_FROM_TRIP, async ({ tripId }) => updateTripAndTransactions(tripId))
  useEvents([Events.Transaction.OPERATORS_ASSIGNED_TO_TRANSACTIONS], ({ transactionIds }) =>
    handleUpdatedTransactions(transactionIds)
  )

  const handleGetTripsMetrics = ({ newRangeFilters }: { newRangeFilters?: TripsDateRangeFilter[] }) => {
    const currentPeriod = newRangeFilters || tableState.dateRangeFilters
    const createdAt = currentPeriod.find((dateRange) => dateRange.key === 'createdAt')

    getMetrics({
      dateFrom: createdAt?.startDate ?? undefined,
      dateTo: createdAt?.endDate ?? undefined
    })
  }

  const handleUpdatedTransactions = (transactionIds: number[]) => {
    tableState.trips.forEach((trip) => {
      const tripHasUpdatedTX = trip.transactions.some((tx) => transactionIds.includes(tx.id))
      if (tripHasUpdatedTX) getTripTransactions({ tripId: trip.id, destination: CommonDestination.TABLE })
    })
  }
  const refreshView = () => {
    handleGetTrips({
      pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: TripsTableSearchFilterKey.ID, text: query.id }
      ]),
      categoryFilter: {
        ...tableState.categoryFilter,
        tripStatus: query.tripStatus ? [query.tripStatus as TripStatusId] : tableState.categoryFilter.tripStatus
      },
      sort:
        query.sort && query.sort
          ? {
              ...tableState.sort,
              direction: query.sort as SortDirection
            }
          : tableState.sort,
      dateRangeFilters: updateMultipleDateRangeFilters(tableState.dateRangeFilters, [
        {
          key: TripsTableDateRangeFilterKey.CREATED_AT,
          startDate: query.createdAtFrom,
          endDate: query.createdAtTo
        },
        {
          key: TripsTableDateRangeFilterKey.START_DATE,
          startDate: query.realStartDateFrom,
          endDate: query.realStartDateTo
        },
        {
          key: TripsTableDateRangeFilterKey.END_DATE,
          startDate: query.realEndDateFrom,
          endDate: query.realEndDateTo
        },
        {
          key: TripsTableDateRangeFilterKey.SCHEDULED_START,
          startDate: query.scheduledStartDateFrom,
          endDate: query.scheduledStartDateTo
        },
        {
          key: TripsTableDateRangeFilterKey.SCHEDULED_END,
          startDate: query.scheduledEndDateFrom,
          endDate: query.scheduledEndDateTo
        }
      ])
    })

    return () => {
      clearState()
    }
  }

  useEffect(() => {
    refreshView()
  }, [countryCode])

  const handlePageChange = (newPage: number) => {
    handleGetTrips({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleRangePicker = (key: TripsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetTrips({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
  }

  const handleSearch = (key: TripsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetTrips({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleCategoryFilter = (newCategoryFilter: TripsTableCategoryFilter) => {
    handleGetTrips({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleSort = (newSort: TripsTableSort) => {
    handleGetTrips({ sort: newSort })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetRangeFilters = tableState.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))
    const resetSort: TripsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleGetTrips({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters,
      dateRangeFilters: resetRangeFilters,
      categoryFilter: { tripStatus: [] }
    })
    clearSelectedTrips()
  }

  const handleGetTripTransactions = (tripId: number) => {
    getTripTransactions({ tripId, destination: CommonDestination.TABLE })
  }

  const handleOpenEditModal = (trip: Trip) => setOpen({ open: true, trip })
  const handleStartTrip = (tripId: number) => startTrip({ tripId })
  const handleFinishTrip = (tripId: number) => finishTrip({ tripId })
  const handleCancelTrip = (tripId: number) => cancelTrip({ tripId })
  const handleToggleSelectedTransaction = (transaction: Transaction) => toggleSelectedTransaction(transaction)
  const handleToggleTripTransactions = (trip: TripWithTransactions) => toggleTripTransactions(trip)
  const handleOpenAssignmentModal = () =>
    setOperatorAssignmentModalOpen({ open: true, transactions: tableState.selectedTransactions })

  const handleEditTransactionAssignment = (transaction: Transaction) => {
    setOperatorAssignmentModalOpen({ open: true, transactions: [transaction], operators: transaction.operators })
  }
  const handleRemoveTransactionFromTrip = ({ tripId, transactionId }: { tripId: number; transactionId: number }) => {
    removeTransaccionFromTrip({ tripId, transactionId })
  }

  const handleSendCSV = () => {
    const params = {
      pagination: tableState.pagination,
      dateRangeFilters: tableState.dateRangeFilters,
      searchFilters: tableState.searchFilters,
      categoryFilter: tableState.categoryFilter,
      sort: tableState.sort
    }
    sendCSV(params)
  }

  const handleSendTXByTripsReport = () => {
    sendTransactionsReport({ selectedTrips: tableState.selectedTrips })
  }

  const props: TripsTableProps = {
    trips: tableState.trips,
    selectedTrips: tableState.selectedTrips,
    loadingTrips: tableState.loadingTrips,
    loadingTransactionsForTripIds: tableState.loadingTransactionsForTripIds,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    dateRangeFilters: tableState.dateRangeFilters,
    sort: tableState.sort,
    loadingTripIds: tableState.loadingTripIds,
    startedTripIds: tableState.startedTripIds,
    finishedTripIds: tableState.finishedTripIds,
    canceledTripIds: tableState.canceledTripIds,
    canceling: tableState.canceling,
    cancelingModalId: tableState.cancelingModalId,
    selectedTransactions: tableState.selectedTransactions,
    noCountrySelected: !countryCode.length,
    sendingTXByTripsReport: tableState.sendingTXByTripsReport,
    handleEditTransactionAssignment,
    handleRemoveTransactionFromTrip,
    handleOpenAssignmentModal,
    handleToggleTripTransactions,
    handleToggleSelectedTransaction,
    setCancelingModalId,
    handleStartTrip,
    handleFinishTrip,
    handleCancelTrip,
    handleOpenEditModal,
    handleSort,
    handleResetFilters,
    handleSearch,
    handleCategoryFilter,
    handleRangePicker,
    handleExpansion: handleGetTripTransactions,
    sendingCSV: tableState.sendingCSV,
    handleSendCSV,
    handleToggleSelectedTrip: toggleSelectedTrip,
    handleToggleAllTrips: toggleAllTrips,
    handleSendTXByTripsReport
  }

  return (
    <>
      <TripModalContainer />
      <TransactionOperatorsAssignmentContainer />
      <TripsMetrics fetchingMetrics={tableState.loadingTrips || fetchingMetrics} metrics={quantityMetrics} />
      <TripsTable {...props} />
    </>
  )
}

export default TripsTableContainer
