import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import snakecaseKeys from 'snakecase-keys'
import { Moment } from 'moment'

interface RecalculateParams {
  userId: number
  transactionId?: number
  createdFrom?: string
  countryCode?: string
  billingStart?: Moment
  recalculate?: boolean
}

export function billing({ userId, billingStart, ...restParams }: RecalculateParams): Promise<boolean> {
  const params = snakecaseKeys({
    ...restParams,
    ...(billingStart && { billingStart: billingStart.format('YYYY-MM-DD') })
  })

  const config = {
    headers: {
      'user-id': userId
    },
    params
  }

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/billings`, undefined, config)
    .then((response: AxiosResponse) => {
      if (!response.data) throw new Error('Hubo un error al recalcular los abonos en el endpoint')
      return true
    })
    .catch((err) => {
      throw err
    })
}
