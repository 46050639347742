import { Moment } from 'moment'
import { CamelCasedPropertiesDeep } from 'type-fest'
import camelcaseKeys from 'camelcase-keys'
import { formatDates } from '../../utils'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import snakecaseKeys from 'snakecase-keys'

interface Params {
  objectsId: number[]
  removalDate: Moment
}

type APIMinCostResponse = {
  object_id: number
  min_cost: number
}

type APIResponse = {
  objects: APIMinCostResponse[]
}

export type MinCostResponse = CamelCasedPropertiesDeep<APIMinCostResponse>

type Response = CamelCasedPropertiesDeep<APIResponse>

export function calculateMinCost(params: Params): Promise<Response> {
  const body = snakecaseKeys(
    {
      ...params,
      ...formatDates({
        dates: { removalDate: params.removalDate },
        format: null,
        utc: false
      })
    },
    { deep: true }
  )

  return apiPrivate
    .post(`${URL_BASE_OBJECTS}/object/calculate-min-cost`, body)
    .then((response: AxiosResponse<APIResponse>) => {
      return camelcaseKeys(response.data, { deep: true })
    })
}
