import { combineReducers } from 'redux'
import PhotographObjectTableReducer from './reducers/PhotographObjectsTable'
import photos from '../PhotographObjects/reducers/photos'
import deletePhoto from '../PhotographObjects/reducers/deletePhoto'

export default combineReducers({
  table: PhotographObjectTableReducer,
  photos,
  deletePhoto
})
