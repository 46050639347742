import { Reducer } from 'redux'
import {
  ChangeEmailActions,
  ChangeEmailModalState,
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  MESSAGE_AVAILABLE_EMAIL_CONFIRMATION,
  OPEN_CHANGE_EMAIL_MODAL,
  SET_EMAIL_CONFIRMATION,
  SET_ERROR_EMAIL_CONFIRMATION,
  SET_ERROR_NEW_EMAIL,
  SET_NEW_EMAIL,
  SET_SUCCESS_NEW_EMAIL,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  GetUserCards
} from '../types/changeEmail'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

const initialState: ChangeEmailModalState = {
  open: false,
  userData: {
    id: 0,
    name: '',
    country: CountryIdCode.ALL,
    lastName: '',
    currentEmail: ''
  },
  newEmail: {
    value: '',
    errorMessage: '',
    successMessage: '',
    loading: false,
    lastSearchTimestamp: 0
  },
  emailConfirmation: {
    value: '',
    errorMessage: '',
    successMessage: '',
    loading: false
  },
  fetchingCards: false,
  errorFetchCards: '',
  hasMPCards: false,
  error: '',
  loading: false
}

const ReducerChangeEmail: Reducer<ChangeEmailModalState, ChangeEmailActions> = (
  state = initialState,
  action
): ChangeEmailModalState => {
  switch (action.type) {
    case OPEN_CHANGE_EMAIL_MODAL:
      if (!action.payload.open) {
        return initialState
      }
      return { ...state, open: true, userData: action.payload.userData || state.userData }
    case SET_NEW_EMAIL:
      return { ...state, newEmail: { ...state.newEmail, value: action.payload.newEmail } }
    case SET_EMAIL_CONFIRMATION:
      return { ...state, emailConfirmation: { ...state.emailConfirmation, value: action.payload.emailConfirmation } }
    case MESSAGE_AVAILABLE_EMAIL_CONFIRMATION:
      return {
        ...state,
        emailConfirmation: {
          ...state.emailConfirmation,
          successMessage: action.payload.messageAvailableEmailConfirmation
        }
      }
    case SET_ERROR_NEW_EMAIL:
      return { ...state, newEmail: { ...state.newEmail, errorMessage: action.payload.errorNewEmail } }
    case SET_ERROR_EMAIL_CONFIRMATION:
      return {
        ...state,
        emailConfirmation: { ...state.emailConfirmation, errorMessage: action.payload.errorEmailConfirmation }
      }
    case SET_SUCCESS_NEW_EMAIL:
      return { ...state, newEmail: { ...state.newEmail, successMessage: action.payload.successNewEmail } }
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          errorMessage: initialState.newEmail.errorMessage,
          successMessage: initialState.newEmail.successMessage,
          loading: true,
          lastSearchTimestamp: action.payload.lastSearchTimestamp
        }
      }
    case VERIFY_EMAIL_SUCCESS:
      if (action.payload.lastSearchTimestamp !== state.newEmail.lastSearchTimestamp) return state
      return { ...state, newEmail: { ...state.newEmail, successMessage: 'El email esta disponible', loading: false } }
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          errorMessage: action.payload.error,
          successMessage: initialState.newEmail.successMessage,
          loading: false
        }
      }
    case EDIT_EMAIL_REQUEST:
      return { ...state, loading: true }
    case EDIT_EMAIL_SUCCESS:
      return initialState
    case EDIT_EMAIL_FAILURE:
      return { ...state, error: action.payload.errorNewEmail, loading: false }
    case GetUserCards.GET_USER_CARDS_REQUEST:
      return { ...state, fetchingCards: true, errorFetchCards: initialState.errorFetchCards }
    case GetUserCards.GET_USER_CARDS_SUCCESS:
      return { ...state, fetchingCards: false, hasMPCards: action.payload.hasMPCards }
    case GetUserCards.GET_USER_CARDS_FAILURE:
      return { ...state, fetchingCards: false, errorFetchCards: action.payload.errorMessage }
    default:
      return state
  }
}

export default ReducerChangeEmail
