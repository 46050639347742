import { getFlagsByCountry } from '../../../../common/operations/flags/utils'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import {
  GET_ADDITIONAL_FLAGS_FAILURE,
  GET_ADDITIONAL_FLAGS_REQUEST,
  GET_ADDITIONAL_FLAGS_SUCCESS,
  GetAdditionalFlagsFailure,
  GetAdditionalFlagsRequest,
  GetAdditionalFlagsSuccess,
  SET_DISABLED_SHIPMENT_ASSISTANTS,
  SET_UPDATED_EXTRA_VALUE,
  SetDisabledShipmentAssistants,
  SetUpdatedExtraValue
} from '../../types/EditDepositViewTypes/additionals'

const AditionalsActionCreators = {
  setDisabledShipmentAssistants: (): SetDisabledShipmentAssistants => {
    return {
      type: SET_DISABLED_SHIPMENT_ASSISTANTS
    }
  },
  setUpdatedExtraValue: (updatedValue: number): SetUpdatedExtraValue => {
    return {
      type: SET_UPDATED_EXTRA_VALUE,
      payload: { updatedValue }
    }
  },

  getAdditionalFlags:
    ({ countryCode }: { countryCode: string }): AppThunk =>
    async (dispatch) => {
      const request: GetAdditionalFlagsRequest = {
        type: GET_ADDITIONAL_FLAGS_REQUEST
      }

      dispatch(request)

      try {
        const extraAssistantPrice = await API.ObjectAdministration.Value.getValue({
          flag: getFlagsByCountry(countryCode as CountryIdCode).extraAssistantPrice
        })

        if (!extraAssistantPrice) throw new Error('No se halló el valor de precio por asistente')

        const success: GetAdditionalFlagsSuccess = {
          type: GET_ADDITIONAL_FLAGS_SUCCESS,
          payload: {
            extraAssistantPrice: parseInt(extraAssistantPrice, 10)
          }
        }
        dispatch(success)
      } catch (error) {
        const failure: GetAdditionalFlagsFailure = {
          type: GET_ADDITIONAL_FLAGS_FAILURE
        }
        dispatch(failure)
      }
    }
}

export default AditionalsActionCreators
