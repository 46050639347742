import { ProspectActions, ProspectSearchTypes } from '../types/prospect'
import { Prospect } from '../../../projectApi/Mercurio/Prospects/getById'
import { Reducer } from 'redux'

const initialState = {
  loadingProspect: false,
  selectedProspect: null,
  errorProspect: ''
}

export type ProspectState = {
  loadingProspect: boolean
  selectedProspect: Prospect | null
  errorProspect: string
}

const root: Reducer<ProspectState, ProspectActions> = (state = initialState, action) => {
  switch (action.type) {
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return {
        ...initialState,
        loadingProspect: true
      }
    case ProspectSearchTypes.GET_PROSPECT_SUCCESS: {
      const { prospect } = action.payload
      return {
        ...state,
        loadingProspect: false,
        selectedProspect: prospect
      }
    }
    case ProspectSearchTypes.GET_PROSPECT_FAILURE:
      return {
        ...state,
        loadingProspect: false,
        errorProspect: action.payload.error
      }
    default:
      return state
  }
}

export default root
