import {
  ModalCalendarOperationTypes,
  OperationTypeValues,
  SetAvailableOperations,
  SetSelectedOperations
} from '../types/common'

const OperationCalendarActionsCreator = {
  setSelectedOperations: ({
    selectedOperations
  }: {
    selectedOperations: OperationTypeValues[]
  }): SetSelectedOperations => {
    return {
      type: ModalCalendarOperationTypes.SET_SELECTED_DAYS_OPERATIONS,
      payload: { selectedOperations }
    }
  },
  setAvailableOperations: ({
    availableOperations
  }: {
    availableOperations: OperationTypeValues[]
  }): SetAvailableOperations => {
    return {
      type: ModalCalendarOperationTypes.SET_AVAILABLE_OPERATIONS,
      payload: { availableOperations }
    }
  }
}

export default OperationCalendarActionsCreator
