import { Deposit, User, UserActionTypes, UsersActions } from '../types/users'
import commonTypes from '../types/common'
import { UserColppy } from '../../../projectApi/TransactionHandler/Colppy/userInfo'

export type UserState = {
  open: boolean
  user: User | null
  userColppyInfo: UserColppy | null
  lastDeposit: Deposit | null
  loadingUser: boolean
  totalObjects: number
  error: string
}

const initialUserSearchState: UserState = {
  open: false,
  user: null,
  userColppyInfo: null,
  lastDeposit: null,
  loadingUser: false,
  totalObjects: 0,
  error: ''
}

const userSearchReducer = (state: UserState = initialUserSearchState, action: UsersActions): UserState => {
  switch (action.type) {
    case UserActionTypes.TOGGLE_USER_MODAL_OPEN: {
      return { ...state, open: action.payload.open }
    }
    case UserActionTypes.GET_USER_REQUEST: {
      return {
        ...state,
        loadingUser: true,
        user: null
      }
    }
    case UserActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        loadingUser: false,
        user: action.payload.user,
        lastDeposit: action.payload.lastDeposit || null,
        totalObjects: action.payload.totalObjects
      }
    }
    case UserActionTypes.GET_USER_FAILURE: {
      return {
        ...state,
        loadingUser: false,
        error: action.payload.error
      }
    }
    case UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST: {
      return {
        ...state,
        loadingUser: true,
        userColppyInfo: null
      }
    }
    case UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS: {
      return {
        ...state,
        loadingUser: false,
        userColppyInfo: action.payload.user
      }
    }
    case UserActionTypes.GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE: {
      return {
        ...state,
        loadingUser: false,
        error: action.payload.error
      }
    }
    case commonTypes.FINISH: {
      return { ...initialUserSearchState, open: state.open }
    }
    default:
      return state
  }
}
export default userSearchReducer
