const paymentMethodsTypes = {
  GET_PAYMENT_METHODS: 'USERS/GET_PAYMENT_METHODS',
  GET_PAYMENT_METHODS_SUCCESS: 'USERS/GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_FAIL: 'USERS/GET_PAYMENT_METHODS_FAIL'
}

const loginAsActionTypes = {
  SET_ADMIN_EMAIL: 'USERS/SET_ADMIN_EMAIL',
  SET_ADMIN_PASSWORD: 'USERS/SET_ADMIN_PASSWORD',
  SET_REDIRECTION: 'USERS/SET_REDIRECTION',
  SET_LOGIN_AS_MODAL_OPEN: 'USERS/SET_LOGIN_AS_MODAL_OPEN',
  LOGIN_AS_USER: 'USERS/LOGIN_AS_USER',
  LOGIN_AS_USER_SUCCESS: 'USERS/LOGIN_AS_USER_SUCCESS',
  LOGIN_AS_USER_FAIL: 'USERS/LOGIN_AS_USER_FAIL'
}

const usersTableActions = {
  FETCH_USERS: 'USERS/FETCH_USERS',
  FETCH_USERS_SUCCESS: 'USERS/FETCH_USERS_SUCCESS',
  FETCH_USERS_FAIL: 'USERS/FETCH_USERS_FAIL',
  SET_SORTER: 'USERS/SET_SORTER',
  SET_FILTER: 'USERS/SET_FILTER',
  SET_PAGINATION: 'USERS/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'USERS/SET_SEARCH_FILTERS',
  RESET_STATE: 'USERS/RESET_STATE',
  CLEAN_FILTERS: 'USERS/CLEAN_FILTERS'
}

export default {
  ...paymentMethodsTypes,
  ...loginAsActionTypes,
  ...usersTableActions
}
