import React from 'react'

const Source: React.FC = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 5.33337L9.66667 8.00004M9.66667 8.00004L7 10.6667M9.66667 8.00004H1M1.22521 4.66671C2.37791 2.67404 4.53239 1.33337 7 1.33337C10.6819 1.33337 13.6667 4.31814 13.6667 8.00004C13.6667 11.6819 10.6819 14.6667 7 14.6667C4.53239 14.6667 2.37791 13.326 1.22521 11.3334"
        stroke="#777692"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Source
