import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'

type providerDeleteParams = {
  providerId: number
}

export function deleteit({ providerId }: providerDeleteParams): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_TRIPS}/providers/${providerId}`, { headers: { 'x-origin': 'backoffice/adm-web' } })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
