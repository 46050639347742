import React from 'react'
import { Modal } from 'antd'

export type EmailBilledModalProps = {
  modalOpen: boolean
  loading: boolean
  billingId?: number | null
  error: string
  handleSend: () => void
  onCancel: () => void
}

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

export const EmailBilledModal: React.FC<EmailBilledModalProps> = ({
  modalOpen: openModal,
  loading,
  handleSend,
  onCancel,
  billingId,
  error
}) => {
  return (
    <Modal
      title="Enviar Factura"
      visible={openModal}
      onOk={handleSend}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        loading,
        disabled: loading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p>
        ¿Está seguro que quiere enviar al correo del usuario el comprobante de facturación con <b>ID {billingId}</b>?
      </p>
      <div className="container">
        <p style={{ color: 'red', textAlign: 'center', marginTop: '10', marginBottom: '0' }}>{error}</p>
      </div>
    </Modal>
  )
}
