import React from 'react'
import SearchUser from '../components/SearchUser'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import searchUserActionCreators from '../actionCreators/SearchUser'

const SearchUserContainer = () => {
  const dispatch = useDispatch()
  const searchUserActions = bindActionCreators(searchUserActionCreators, dispatch)
  const { billingPayment } = useSelector((state) => state.BillingPayment)
  const { error, loading, userColppyInfo } = billingPayment
  const props = {
    searchUserActions,
    error,
    loading,
    userColppyInfo
  }

  return <SearchUser {...props} />
}

export default SearchUserContainer
