import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import CorporativeLocationDeleteActionCreator from '../actions/CorporativeLocationDelete'
import LocationDeleteActionCreator from '../../../Locations/actions/LocationDelete'
import LocationDeleteModal from '../../../Locations/components/LocationDeleteModal'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()

  const corporateLocationDeleteModalActions = bindActionCreators(CorporativeLocationDeleteActionCreator, dispatch)
  const locationDeleteModalActions = bindActionCreators(LocationDeleteActionCreator, dispatch)
  const locationDeleteModalState = useSelector((state) => state.Locations.LocationDelete)

  const onOkClick = () => {
    const { location } = locationDeleteModalState
    if (location) {
      corporateLocationDeleteModalActions.deleteLocation(location?.id)
    }
  }

  const props = {
    locationDeleteModalActions,
    locationDeleteModalState,
    onOkClick
  }

  const handleSuccessDelete = () => {
    locationDeleteModalActions.setModal({ isModalOpen: false, location: null })
  }

  useEvents(Events.Location.LOCATION_DELETED, () => handleSuccessDelete())

  return <LocationDeleteModal {...props} />
}

export default Container
