import { AppThunk } from '../../../store'
import {
  GetDepositsValidationMetricsRequest,
  GetDepositsValidationMetricsSuccess,
  GetDepositsValidationMetricsTypes
} from '../types/depositsValidationMetrics'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { sendToastNotificationError } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import { mapOfDepositsValidationMetrics } from '../components/depositsValidationMetrics'
import { DepositsValidationDateRangeFilter, DepositsValidationTableCategoryFilter } from '../types/table'
import { dateRangeFiltersToParams } from '../../../utils/searchFilterUtils'

const depositValidationMetricsActionsCreator = {
  getMetrics:
    ({
      categoryFilter,
      dateRangeFilters
    }: {
      categoryFilter?: DepositsValidationTableCategoryFilter
      dateRangeFilters?: DepositsValidationDateRangeFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetDepositsValidationMetricsRequest = {
        type: GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_REQUEST
      }

      dispatch(request)
      try {
        const response = await API.TransactionHandler.Operation.Deposits.metrics({
          statuses: categoryFilter?.status,
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        const quantityMetrics = Object.entries(response).map(([key, value]) => ({
          id: key,
          metric: mapOfDepositsValidationMetrics[key],
          quantity: value
        }))

        const success: GetDepositsValidationMetricsSuccess = {
          type: GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_SUCCESS,
          payload: { quantityMetrics }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(
          error,
          GetDepositsValidationMetricsTypes.GET_DEPOSITS_VALIDATION_METRICS_FAILURE,
          dispatch
        )
      }
    }
}

export default depositValidationMetricsActionsCreator
