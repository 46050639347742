import { Shipper } from '../../../projectApi/Trips/Shippers/list'
import { Pagination, SearchFilter } from '../../../utils/searchFilterUtils'

export type ShippersTableSearchKey = 'id' | 'user_id'
export type ShippersSearchFilter = SearchFilter<ShippersTableSearchKey>

export type ShippersTableStateType = {
  shippers: Shipper[]
  fetchingShippers: boolean
  sendingCSVReport: boolean
  pagination: Pagination
  searchFilters: ShippersSearchFilter[]
}

export enum ShippersTableGeneralTypes {
  CLEAN_TABLE_STATE = 'SHIPPERS/SHIPPERS_TABLE/CLEAN_TABLE_STATE'
}

export interface CleanTableState {
  type: ShippersTableGeneralTypes.CLEAN_TABLE_STATE
}

export enum GetShippersListTypes {
  GET_SHIPPERS_REQUEST = 'SHIPPERS/SHIPPERS_TABLE/GET_SHIPPERS_REQUEST',
  GET_SHIPPERS_SUCCESS = 'SHIPPERS/SHIPPERS_TABLE/GET_SHIPPERS_SUCCESS',
  GET_SHIPPERS_FAILURE = 'SHIPPERS/SHIPPERS_TABLE/GET_SHIPPERS_FAILURE'
}

export interface GetShippersListRequest {
  type: GetShippersListTypes.GET_SHIPPERS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: ShippersSearchFilter[]
  }
}

export interface GetShippersListSuccess {
  type: GetShippersListTypes.GET_SHIPPERS_SUCCESS
  payload: {
    shippers: Shipper[]
    total: number
  }
}

export interface GetShippersListFailure {
  type: GetShippersListTypes.GET_SHIPPERS_FAILURE
}

type GetShippersListActions = GetShippersListRequest | GetShippersListSuccess | GetShippersListFailure

export enum SendCSVReportOfShippersTypes {
  SEND_CSV_REPORT_OF_SHIPPERS_REQUEST = 'SHIPPERS/SHIPPERS_TABLE/SEND_CSV_REPORT_OF_SHIPPERS_REQUEST',
  SEND_CSV_REPORT_OF_SHIPPERS_SUCCESS = 'SHIPPERS/SHIPPERS_TABLE/SEND_CSV_REPORT_OF_SHIPPERS_SUCCESS',
  SEND_CSV_REPORT_OF_SHIPPERS_FAILURE = 'SHIPPERS/SHIPPERS_TABLE/SEND_CSV_REPORT_OF_SHIPPERS_FAILURE'
}

export interface SendCSVReportOfShippersRequest {
  type: SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_REQUEST
}

export interface SendCSVReportOfShippersSuccess {
  type: SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_SUCCESS
}

export interface SendCSVReportOfShippersFailure {
  type: SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_FAILURE
}

type SendCSVReportOfShippersActions =
  | SendCSVReportOfShippersRequest
  | SendCSVReportOfShippersSuccess
  | SendCSVReportOfShippersFailure

export type ShippersTableActions = CleanTableState | GetShippersListActions | SendCSVReportOfShippersActions
