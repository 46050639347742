import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Pagination, dateRangeFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  DiscountDaysDateRangeFilter,
  DiscountDaysTableTypes,
  DiscountDaysTableSort,
  GetDiscountDaysRequest,
  GetDiscountDaysSuccess,
  GetDiscountDaysTypes,
  DiscountDaysTableCategoryFilter
} from '../types/discountDays'

const discountDaysActionsCreator = {
  getDiscountDays({
    categoryFilters,
    pagination,
    sort,
    dateRangeFilters
  }: {
    categoryFilters?: DiscountDaysTableCategoryFilter
    pagination: Pagination
    sort?: DiscountDaysTableSort
    dateRangeFilters?: DiscountDaysDateRangeFilter[]
  }): AppThunk {
    return async (dispatch) => {
      const request: GetDiscountDaysRequest = {
        type: GetDiscountDaysTypes.GET_DISCOUNT_DAYS_REQUEST,
        payload: {
          categoryFilters,
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          sort
        }
      }

      dispatch(request)

      try {
        const { discounts, total } = await API.Timeslot.discounts.SpecificDays.list({
          weekday: categoryFilters?.weekday[0],
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          column: sort?.field,
          order: sort?.direction,
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        const success: GetDiscountDaysSuccess = {
          type: GetDiscountDaysTypes.GET_DISCOUNT_DAYS_SUCCESS,
          payload: {
            discounts,
            total
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GetDiscountDaysTypes.GET_DISCOUNT_DAYS_FAILURE, dispatch)
      }
    }
  },

  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: DiscountDaysTableTypes.RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.Discounts.CLEAN_FILTERS), 50)
    }
  }
}

export default discountDaysActionsCreator
