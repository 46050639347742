import { GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, RolesActions, RolesState } from '../types/roles'

const initialState: RolesState = {
  loading: false,
  roles: [],
  error: ''
}

function root(state = initialState, action: RolesActions): RolesState {
  switch (action.type) {
    case GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload.roles
      }
    case GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default root
