import React, { useState } from 'react'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import Select from '../../../../components/Select'
import { BaseOption } from '../../../../components/Select/Select'
import { Trash } from '../../../../icons'
import styles from './ServiceDataRow.module.scss'
import NumberInput from '../../../../components/numberInput'
import clsx from 'clsx'
import ExtraValueData from '../ExtraValueData/ExtraValueData'
import { M3Label } from '../../utils'
import { formatMoneyWithCountry } from '../../../../utils/formatNumber'
import { Country, CountryIdCode } from '../../../../components/CountrySelector/constants/constants'

type Option = BaseOption<string>

interface ServiceDataRowProps {
  disabled: boolean
  onInputChange: (text: string) => void

  index: number
  country: Country
  loadingOptions: boolean
  options: Option[]
  selectedOption: Option | null
  onDeleteItem: (index: number) => void
  onSelectItem: (option: Option | null) => void

  itemQuantity: number
  setItemQuantity: (index: number, quantity: number) => void

  tooltipDimensions: string | React.ReactNode
  dimensions: string
  weight: string

  withPackagingCost: boolean
  packagingCost: number
  setItemPackaging: (index: number, check: boolean) => void

  withDisassemblyCost: boolean
  disassemblyCost: number
  setItemDisassembly: (index: number, check: boolean) => void

  floors: number
  floorCost: number
  setItemFloors: (index: number, floor: number) => void
}

const ServiceDataRow = ({
  index,
  country,
  loadingOptions,

  options,
  selectedOption,
  onSelectItem,
  onDeleteItem,

  itemQuantity,
  setItemQuantity,

  tooltipDimensions,
  dimensions,
  weight,

  packagingCost,
  withPackagingCost,
  setItemPackaging,

  disassemblyCost,
  withDisassemblyCost,
  setItemDisassembly,

  floors,
  floorCost,
  setItemFloors,

  disabled,
  onInputChange
}: ServiceDataRowProps) => {
  const [autocomplete, setAutocomplete] = useState('')

  const moneyConfig = {
    decimals: country.code === CountryIdCode.ARGENTINA ? 0 : 2
  }

  const floorTooltip = `Precio por piso: ${formatMoneyWithCountry(country.currency, floorCost, moneyConfig)}`

  return (
    <Row gutter={[16, 16]} type="flex" align="middle">
      <Col span={6}>
        <Select
          placeholder="Seleccionar Item"
          loading={loadingOptions}
          disabled={disabled}
          options={options}
          selected={selectedOption}
          onSelect={onSelectItem}
          autocomplete={{
            filterByInput: true,
            input: autocomplete,
            onInputChange: (text) => {
              setAutocomplete(text)
              onInputChange(text)
            }
          }}
        />
      </Col>
      {selectedOption && (
        <>
          <Col span={1}>
            <span className={styles.deleteIcon} onClick={() => onDeleteItem(index)}>
              <Trash />
            </span>
          </Col>
          <Col span={3}>
            <NumberInput
              value={itemQuantity}
              onAnyChange={(newValue) => setItemQuantity(index, newValue)}
              disabled={disabled}
              min={1}
            />
          </Col>
          <Col span={4}>
            <Tooltip title={tooltipDimensions}>
              <div className={styles.genericCell}>
                <span>
                  {dimensions} <M3Label />
                </span>
                <span>{weight} kg</span>
              </div>
            </Tooltip>
          </Col>
          <Col span={3}>
            {packagingCost > 0 && (
              <div className={styles.genericCell}>
                <Checkbox
                  checked={withPackagingCost}
                  disabled={disabled}
                  onChange={(e) => setItemPackaging(index, e.target.checked)}
                />
                <span className={clsx(styles.extraPrice, disabled && styles.extraDisabled)}>
                  {formatMoneyWithCountry(country.currency, packagingCost * itemQuantity, moneyConfig)}
                </span>
              </div>
            )}
          </Col>
          <Col span={3}>
            {disassemblyCost > 0 && (
              <div className={styles.genericCell}>
                <Checkbox
                  checked={withDisassemblyCost}
                  disabled={disabled}
                  onChange={(e) => setItemDisassembly(index, e.target.checked)}
                />
                <span className={clsx(styles.extraPrice, disabled && styles.extraDisabled)}>
                  {formatMoneyWithCountry(country.currency, disassemblyCost * itemQuantity, moneyConfig)}
                </span>
              </div>
            )}
          </Col>
          <Col span={4}>
            {disabled && floorCost > 0 && (
              <div className={styles.genericCell}>
                <Checkbox checked={true} disabled={true} />
                <span className={clsx(!floors && styles.extraDisabled)}>
                  {formatMoneyWithCountry(country.currency, floorCost * itemQuantity, moneyConfig)}
                </span>
              </div>
            )}
            {floorCost > 0 && !disabled && (
              <Tooltip title={floorTooltip}>
                <div className={styles.floorCell}>
                  <ExtraValueData
                    hideCheckbox
                    extraValueEnabled={!disabled}
                    setExtraValueCount={(value) => setItemFloors(index, value)}
                    extraValueCount={floors}
                    currencySymbol={country.currency}
                    extraValuePrice={floorCost * floors * itemQuantity}
                  />
                </div>
              </Tooltip>
            )}
          </Col>
        </>
      )}
    </Row>
  )
}

export default ServiceDataRow
