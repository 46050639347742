export const DELETE_ADDRESS_REQUEST = 'USER/ADDRESS/DELETE_ADDRESS_REQUEST'
export const DELETE_ADDRESS_SUCCESS = 'USER/ADDRESS/DELETE_ADDRESS_SUCCESS'
export const DELETE_ADDRESS_FAILURE = 'USER/ADDRESS/DELETE_ADDRESS_FAILURE'
export const OPEN_DELETE_MODAL = 'USER/ADDRESS/OPEN_DELETE_MODAL'
export const CLOSE_DELETE_MODAL = 'USER/ADDRESS/CLOSE_DELETE_MODAL'

export interface DeleteAddressState {
  userId: number
  addressId: number
  deleteAddressModalOpen: boolean
  loading: boolean
  error: string
}

export interface DeleteAddressRequest {
  type: typeof DELETE_ADDRESS_REQUEST
}

export interface DeleteAddressSuccess {
  type: typeof DELETE_ADDRESS_SUCCESS
}

export interface DeleteAddressFailure {
  type: typeof DELETE_ADDRESS_FAILURE
  payload: {
    error: string
  }
}

export interface OpenDeleteModal {
  type: typeof OPEN_DELETE_MODAL
  payload: {
    userId: number
    addressId: number
  }
}

export interface CloseDeleteModal {
  type: typeof CLOSE_DELETE_MODAL
}

export type DeleteAddressActions =
  | DeleteAddressRequest
  | DeleteAddressSuccess
  | DeleteAddressFailure
  | OpenDeleteModal
  | CloseDeleteModal
