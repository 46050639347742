import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type APIResponse = {
  programmed_quantity: number
  in_trip_quantity: number
  validated_quantity: number
  programmed_m_quantity: number
}

type Response = {
  programmedQuantity: number
  inTripQuantity: number
  validatedQuantity: number
}

type MetricsParams = {
  dateFrom?: Moment
  dateTo?: Moment
}

export function metrics({ dateFrom, dateTo }: MetricsParams): Promise<Response> {
  const formattedDateParams = {
    date_from: dateFrom?.format('YYYY-MM-DD'),
    date_to: dateTo?.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/deposits-operations/metrics`, { params: formattedDateParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const data = response.data

      const formattedResponse = {
        programmedQuantity: data.programmed_quantity + data.programmed_m_quantity,
        inTripQuantity: data.in_trip_quantity,
        validatedQuantity: data.validated_quantity
      }

      return formattedResponse
    })
    .catch((error) => {
      throw error
    })
}
