import React, { Fragment, useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { getUserOptionValue } from '../../sections/Payments/Payment.selectors'
import Select from '../Select'
import { BaseOption } from '../Select/Select'

const styles = {
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase' as const,
    fontSize: 10
  }
}

type AsyncSelectProps = {
  name: string
  label: string
  placeholder: string
  setUserID: (userID: number) => Promise<void>
  getUsers: (name: string, text: string) => void
  userID: number
  search: {
    text: string
    options: BaseOption<number>[]
    loading: boolean
    error: string
  }
  onSelectCallback: (userID: number) => void
}

const AsyncSelect = ({
  name,
  label,
  placeholder,
  setUserID,
  getUsers,
  userID,
  search,
  onSelectCallback
}: AsyncSelectProps) => {
  const { options, loading, error } = search

  const [autocomplete, setAutocomplete] = useState('')
  const userOption = useMemo(() => options.find((option) => option.value === userID), [options, userID])

  return (
    <Fragment>
      <p style={styles.inputTitle}>{label}</p>
      <Select
        loading={loading}
        selected={userOption}
        placeholder={placeholder}
        options={options}
        autocomplete={{
          filterByInput: true,
          input: autocomplete,
          onInputChange: (text) => {
            setAutocomplete(text)
            if (!isEmpty(text) && text !== search.text) getUsers(name, text)
          }
        }}
        onSelect={(option) => {
          const userID: number = getUserOptionValue(option)
          setUserID(userID).then(() => onSelectCallback(userID))
        }}
        onDelete={() => setUserID(0).then(() => onSelectCallback(0))}
      />
    </Fragment>
  )
}

export default AsyncSelect
