import { useMemo } from 'react'
import { ObjectToRemove, ObjectsToRemove } from './types/objects'
import { calculateObjectToRemoveValues } from './utils/objects'
import { MinCostResponse } from '../../projectApi/ObjectAdministration/Object/calculateMinCost'

type ObjectTotals = {
  totalWeight: number
  totalVolume: number
  totalStairs: number
  totalPackaging: number
  totalAssembly: number
  totalMinCost: number
  total: number
  subtotal: number
  positiveBalance: number
  quantity: number
}

export const useGetObjectsToRemove = (objectsToRemove: ObjectsToRemove) =>
  useMemo(() => Object.values(objectsToRemove).filter((obj) => !obj.deleted), [objectsToRemove])

export const useObjectsToRemoveTotals = (
  objectsToRemove: ObjectToRemove[],
  objectsMinCost: MinCostResponse[],
  transportCost: number,
  colppyBalance = 0
): ObjectTotals =>
  useMemo((): ObjectTotals => {
    const totals: ObjectTotals = {
      quantity: 0,
      totalWeight: 0,
      totalVolume: 0,
      totalStairs: 0,
      totalPackaging: 0,
      totalAssembly: 0,
      totalMinCost: 0,
      positiveBalance: 0,
      subtotal: 0,
      total: 0
    }

    const positiveBalance = -colppyBalance > 0 ? Math.abs(colppyBalance) : 0

    objectsMinCost.forEach((objectMinCost) => (totals.totalMinCost += objectMinCost.minCost))

    objectsToRemove.forEach((objectToRemove) => {
      const objectToRemoveValues = calculateObjectToRemoveValues(objectToRemove)

      totals.totalWeight += objectToRemoveValues.weight
      totals.totalVolume += objectToRemoveValues.volume
      totals.totalStairs += objectToRemoveValues.stairs
      totals.totalPackaging += objectToRemoveValues.packaging
      totals.totalAssembly += objectToRemoveValues.assembly
    })

    totals.quantity = objectsToRemove.length
    totals.total =
      totals.totalStairs +
      totals.totalPackaging +
      totals.totalAssembly +
      totals.totalMinCost +
      transportCost -
      positiveBalance
    totals.subtotal =
      totals.totalStairs + totals.totalPackaging + totals.totalAssembly + totals.totalMinCost + transportCost
    return totals
  }, [objectsToRemove, objectsMinCost, transportCost, colppyBalance])
