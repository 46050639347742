import { API } from '../../../../projectApi'
import { SatelliteAccount } from '../../../../projectApi/AccountManager/Satellite/corporate'
import { GuestUser } from '../../../../projectApi/AccountManager/Satellite/guest'
import { AppThunk } from '../../../../store'
import { getErrorMessage, handleErrorsWithAction } from '../../../../utils/HandleErrors'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import { PAGE_SIZE } from '../reducers/userABM'
import { Pagination, searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import {
  ChangeRoleRequest,
  ChangeRoleSuccess,
  ChangeRoleTypes,
  DeleteSatelliteUserRequest,
  DeleteSatelliteUserSuccess,
  DeleteSatelliteUserTypes,
  FormTypes,
  DeleteInvitationRequest,
  DeleteInvitationSuccess,
  DeleteInvitationTypes,
  GetCorporateRolesRequest,
  GetCorporateRolesSuccess,
  GetCorporateRolesTypes,
  GetGuestUsersRequest,
  GetGuestUsersSuccess,
  GetGuestUsersTypes,
  GuestUsersSearchFilter,
  ModalDeleteInviteTypes,
  ModalDeleteTypes,
  ModalEditTypes,
  ModalInviteTypes,
  RESET_FILTERS,
  SendInvitationRequest,
  SendInvitationSuccess,
  SendInvitationTypes,
  SetDeleteInviteModalOpen,
  SetDeleteModalOpen,
  SetEditModalOpen,
  SetEmail,
  SetRoleId,
  SetModalOpen,
  VerifyEmailFailure,
  VerifyEmailRequest,
  VerifyEmailSuccess,
  VerifyEmailTypes
} from '../types/userABM'

const UserInviteActionsCreator = {
  sendInvitation({ email, roleId }: { email: string; roleId: number }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      const request: SendInvitationRequest = {
        type: SendInvitationTypes.SEND_INVITATION_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Enviando invitación. Por favor espere...')

      try {
        await API.AccountManager.Satellite.create({ email, role: roleId })

        const success: SendInvitationSuccess = {
          type: SendInvitationTypes.SEND_INVITATION_SUCCESS
        }

        setToastSuccessUpdate(toastId, { render: 'Invitación enviada con éxito.' })
        dispatch(success)
        setTimeout(() => Emitter.emit(Events.CorporateGuestUsers.CLEAN_FILTERS), 50)
        return true
      } catch (error: any) {
        const errorText = getErrorMessage(error)
        setToastErrorUpdate(toastId, { render: errorText })
        handleErrorsWithAction(error, SendInvitationTypes.SEND_INVITATION_FAILURE, dispatch)
        return false
      }
    }
  },
  getCorporateRoles(): AppThunk {
    return async (dispatch) => {
      const request: GetCorporateRolesRequest = {
        type: GetCorporateRolesTypes.GET_CORPORATE_ROLES_REQUEST
      }
      const toastId = setToastLoading('Cargando roles. Por favor espere...')
      dispatch(request)

      try {
        const { roles } = await API.AccountManager.Roles.list({ isCorporate: true })

        const success: GetCorporateRolesSuccess = {
          type: GetCorporateRolesTypes.GET_CORPORATE_ROLES_SUCCESS,
          payload: {
            coporateRoles: roles
          }
        }

        setToastSuccessUpdate(toastId, { render: 'Roles cargados con éxito.' })
        dispatch(success)
      } catch (error: any) {
        const errorText = getErrorMessage(error)
        setToastErrorUpdate(toastId, { render: errorText })
        handleErrorsWithAction(error, GetCorporateRolesTypes.GET_CORPORATE_ROLES_FAILURE, dispatch)
      }
    }
  },
  verifyIfEmailExists(email: string): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      const request: VerifyEmailRequest = {
        type: VerifyEmailTypes.VERIFY_EMAIL_REQUEST
      }
      const toastId = setToastLoading('Comprobando si el email ya existe...')
      dispatch(request)

      try {
        const { exists } = await API.AccountManager.Email.checkIfEmailExists({ email })

        if (!exists) {
          const success: VerifyEmailSuccess = {
            type: VerifyEmailTypes.VERIFY_EMAIL_SUCCESS
          }

          setToastSuccessUpdate(toastId, { render: 'Email disponible.' })
          dispatch(success)
          return true
        } else {
          const failure: VerifyEmailFailure = {
            type: VerifyEmailTypes.VERIFY_EMAIL_FAILURE,
            payload: {
              error: 'Email no disponible'
            }
          }

          setToastErrorUpdate(toastId, { render: 'Email no disponible' })
          dispatch(failure)
          return false
        }
      } catch (error: any) {
        const errorText = getErrorMessage(error)
        setToastErrorUpdate(toastId, { render: errorText })
        handleErrorsWithAction(error, VerifyEmailTypes.VERIFY_EMAIL_REQUEST, dispatch)
        return false
      }
    }
  },
  openModalInvite: ({ isAddModalOpen }: { isAddModalOpen: boolean }): SetModalOpen => {
    return {
      type: ModalInviteTypes.SET_MODAL_INVITE_OPEN,
      payload: { isAddModalOpen }
    }
  },
  getGuestUsers({
    pagination,
    searchFilters
  }: {
    pagination: Pagination
    searchFilters?: GuestUsersSearchFilter[]
  }): AppThunk {
    const toastId = setToastLoading('Cargando invitados. Por favor espere...')
    return async (dispatch) => {
      const request: GetGuestUsersRequest = {
        type: GetGuestUsersTypes.GET_GUEST_USERS_REQUEST,
        payload: {
          pageSize: PAGE_SIZE,
          newPage: pagination.page,
          searchFilters
        }
      }

      dispatch(request)

      try {
        const { guestUsers, total } = await API.AccountManager.Satellite.satelliteGuestUsers({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          ...searchFiltersToParams(searchFilters)
        })

        const success: GetGuestUsersSuccess = {
          type: GetGuestUsersTypes.GET_GUEST_USERS_SUCCESS,
          payload: {
            guestUsers,
            total
          }
        }
        setToastSuccessUpdate(toastId, { render: 'Invitados cargados con éxito.' })
        dispatch(success)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, GetGuestUsersTypes.GET_GUEST_USERS_FAILURE, dispatch)
        })
      }
    }
  },
  resetFilters(): AppThunk {
    return (dispatch) => {
      dispatch({ type: RESET_FILTERS })
      setTimeout(() => Emitter.emit(Events.CorporateGuestUsers.CLEAN_FILTERS), 50)
    }
  },
  deleteInvitation({ guestId }: { guestId: number }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      const request: DeleteInvitationRequest = {
        type: DeleteInvitationTypes.DELETE_INVITATION_REQUEST
      }
      const toastId = setToastLoading('Eliminando invitación. Por favor espere...')
      dispatch(request)

      try {
        await API.AccountManager.Satellite.deleteInvitation(guestId)

        const success: DeleteInvitationSuccess = {
          type: DeleteInvitationTypes.DELETE_INVITATION_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.CorporateGuestUsers.CLEAN_FILTERS)
        setToastSuccessUpdate(toastId, { render: 'Se eliminó la invitación correctamente.' })
        return true
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, GetGuestUsersTypes.GET_GUEST_USERS_FAILURE, dispatch)
        })
        return false
      }
    }
  },
  deleteSatelliteUser(userId: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      const request: DeleteSatelliteUserRequest = {
        type: DeleteSatelliteUserTypes.DELETE_SATELLITE_REQUEST
      }
      const toastId = setToastLoading('Eliminando usuario. Por favor espere...')
      dispatch(request)

      try {
        await API.AccountManager.Satellite.deleteSatelliteUser(userId)

        const success: DeleteSatelliteUserSuccess = {
          type: DeleteSatelliteUserTypes.DELETE_SATELLITE_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.CorporateUsers.CLEAN_FILTERS)
        setToastSuccessUpdate(toastId, { render: 'Se eliminó correctamente.' })
        return true
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, DeleteSatelliteUserTypes.DELETE_SATELLITE_FAILURE, dispatch)
        })
        return false
      }
    }
  },
  changeRole({ userId, roleId }: { userId: number; roleId: number }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      const request: ChangeRoleRequest = {
        type: ChangeRoleTypes.CHANGE_ROLE_REQUEST
      }

      dispatch(request)
      const toastId = setToastLoading('Editando usuario. Por favor espere...')
      try {
        await API.AccountManager.Satellite.update({ userId, roleId })
        const success: ChangeRoleSuccess = {
          type: ChangeRoleTypes.CHANGE_ROLE_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.CorporateUsers.CLEAN_FILTERS)
        setToastSuccessUpdate(toastId, { render: 'Se editó correctamente.' })
        return true
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, ChangeRoleTypes.CHANGE_ROLE_FAILURE, dispatch)
        })
        return false
      }
    }
  },
  openGuestDeleteInviteModal: ({
    isDeleteInviteModalOpen,
    selectedGuestUser
  }: {
    isDeleteInviteModalOpen: boolean
    selectedGuestUser: GuestUser | null
  }): SetDeleteInviteModalOpen => {
    return {
      type: ModalDeleteInviteTypes.SET_MODAL_DELETE_INVITE_OPEN,
      payload: { isDeleteInviteModalOpen, selectedGuestUser }
    }
  },
  setEmail: (email: string): SetEmail => {
    return {
      type: FormTypes.SET_EMAIL,
      payload: {
        email
      }
    }
  },
  setRoleId: (roleId: number): SetRoleId => {
    return {
      type: FormTypes.SET_ROLE_ID,
      payload: {
        roleId
      }
    }
  },
  openDeleteUserModal: ({
    isDeleteModalOpen,
    selectedUser
  }: {
    isDeleteModalOpen: boolean
    selectedUser: SatelliteAccount | null
  }): SetDeleteModalOpen => {
    return {
      type: ModalDeleteTypes.SET_MODAL_DELETE_OPEN,
      payload: { isDeleteModalOpen, selectedUser }
    }
  },
  openEditUserModal: (selectedUser: SatelliteAccount, isAddModalOpen: boolean): SetEditModalOpen => {
    return {
      type: ModalEditTypes.SET_MODAL_EDIT_OPEN,
      payload: { selectedUser, isAddModalOpen }
    }
  }
}

export default UserInviteActionsCreator
