import React from 'react'
import Payment from 'payment'
import './CreditCardComponent.css'

interface ReactCreditCardsProps {
  cvc: string
  expiry: string
  name: string
  number: string
  focused: string
  locale: {
    valid: string
  }
  placeholders: {
    name: string
  }
}

export const defaultProps = {
  acceptedCards: [],
  locale: {
    valid: 'Valido'
  },
  placeholders: {
    name: 'Nombre'
  },
  preview: false
}

const ReactCreditCards: React.FC<ReactCreditCardsProps> = (props) => {
  const { cvc, expiry = '', focused, locale, name, number, placeholders } = props

  const getIssuer = () => {
    return getOptions().issuer
  }

  const getCVC = () => {
    const maxLength = ['amex'].includes(getIssuer()) ? 4 : 3
    let nextNumber = cvc

    if (isNaN(parseInt(nextNumber, 10))) {
      nextNumber = ''
    }

    while (nextNumber.length < maxLength) {
      nextNumber += '*'
    }

    return nextNumber
  }

  const getNumber = () => {
    let maxLength = defaultProps.preview ? 19 : getOptions().maxLength
    let nextNumber = typeof number === 'number' ? number : number.replace(/[A-Za-z]| /g, '')

    if (isNaN(parseInt(nextNumber, 10)) && !defaultProps.preview) {
      nextNumber = ''
    }

    if (maxLength > 16) {
      maxLength = nextNumber.length <= 16 ? 16 : maxLength
    }

    if (nextNumber.length > maxLength) {
      nextNumber = nextNumber.slice(0, maxLength)
    }

    while (nextNumber.length < maxLength) {
      nextNumber += '*'
    }

    if (['amex', 'dinersclub'].includes(getIssuer())) {
      const format = [0, 4, 10]
      const limit = [4, 6, 5]
      nextNumber = `${nextNumber.substr(format[0], limit[0])} ${nextNumber.substr(
        format[1],
        limit[1]
      )} ${nextNumber.substr(format[2], limit[2])}`
    } else if (nextNumber.length > 16) {
      const format = [0, 4, 8, 12]
      const limit = [4, 7]
      nextNumber = `${nextNumber.substr(format[0], limit[0])} ${nextNumber.substr(
        format[1],
        limit[0]
      )} ${nextNumber.substr(format[2], limit[0])} ${nextNumber.substr(format[3], limit[1])}`
    } else {
      for (let i = 1; i < maxLength / 4; i++) {
        const spaceIndex = i * 4 + (i - 1)
        nextNumber = `${nextNumber.slice(0, spaceIndex)} ${nextNumber.slice(spaceIndex)}`
      }
    }

    return nextNumber
  }

  const getExpiry = () => {
    const date = typeof expiry === 'number' ? expiry : expiry
    let month = ''
    let year = ''

    if (date.includes('/')) {
      ;[month, year] = date.split('/')
    } else if (date.length) {
      month = date.substr(0, 2)
      year = date.substr(2, 6)
    }

    while (month.length < 2) {
      month += 'M'
    }

    if (year.length > 2) {
      year = year.substr(2, 4)
    }

    while (year.length < 2) {
      year += 'A'
    }

    return `${month}/${year}`
  }

  const getOptions = () => {
    const issuer = Payment.fns.cardType(number) || 'unknown'

    let maxLength = 16

    if (issuer === 'amex') {
      maxLength = 15
    } else if (issuer === 'dinersclub') {
      maxLength = 14
    } else if (['hipercard', 'mastercard', 'visa'].includes(issuer)) {
      maxLength = 19
    }

    return {
      issuer,
      maxLength
    }
  }

  return (
    <div key="Cards" className="rccs">
      <div className={focused !== 'cvc' ? 'rccs__polygon' : ''} />
      <div
        className={[
          'rccs__card',
          `rccs__card--${getIssuer()}`,
          focused === 'cvc' && getIssuer() !== 'amex' ? 'rccs__card--flipped' : ''
        ]
          .join(' ')
          .trim()}>
        <div className="rccs__card--front">
          <div className="rccs__card__background" />
          <div className="rccs__issuer" />
          <div className={['rccs__cvc__front', focused === 'cvc' ? 'rccs--focused' : ''].join(' ').trim()}>
            {getCVC()}
          </div>
          <div
            className={[
              'rccs__number',
              getNumber().replace(/ /g, '').length > 16 ? 'rccs__number--large' : '',
              focused === 'number' ? 'rccs--focused' : '',
              getNumber().substr(0, 1) !== '•' ? 'rccs--filled' : ''
            ]
              .join(' ')
              .trim()}>
            {getNumber()}
          </div>
          <div
            className={['rccs__name', focused === 'name' ? 'rccs--focused' : '', name ? 'rccs--filled' : '']
              .join(' ')
              .trim()}>
            {name || placeholders.name}
          </div>
          <div
            className={[
              'rccs__expiry',
              focused === 'expiry' ? 'rccs--focused' : '',
              getExpiry().substr(0, 1) !== '•' ? 'rccs--filled' : ''
            ]
              .join(' ')
              .trim()}>
            <div className="rccs__expiry__valid">{locale.valid}</div>
            <div className="rccs__expiry__value">{getExpiry()}</div>
          </div>
          <div className="rccs__chip" />
        </div>
        <div className="rccs__card--back">
          <div className="rccs__card__background" />
          <div className="rccs__stripe" />
          <div className="rccs__signature" />
          <div className={['rccs__cvc', focused === 'cvc' ? 'rccs--focused' : ''].join(' ').trim()}>
            {getCVC()}
            <div className="rccs__circle" />
          </div>
          <div className="rccs__issuer" />
        </div>
      </div>
    </div>
  )
}

export default ReactCreditCards
