import types from '../types/newUser'
import userTypes from '../Users.types'
import { ProposalTypes } from '../../NewProposal/types/proposals'

export const initialNewUser = {
  editMode: false,
  guid: '',
  realID: 0,
  ID: '',
  role: {
    ID: 0
  },
  createModalOpen: false,
  name: '',
  lastName: '',
  accountType: 0,
  companyName: '',
  cuil: '',
  docType: 0,
  dni: '',
  country: '',
  billingType: 'B',
  password: '',
  email: '',
  actualLandline: '',
  landline: '',
  actualPhone: '',
  phone: '',
  countryPhoneCode: '',
  countryAlternativePhoneCode: '',
  dateBirth: null,
  gender: '',
  paymentMethod: 0,
  address: {
    addressGoogle: '',
    street: '',
    number: '',
    postalCode: '',
    province: '',
    city: '',
    country: '',
    floor: '',
    apartment: ''
  },
  loading: false,
  loadingEmail: false,
  loadingDNI: false,
  error: '',
  errorDNI: '',
  errorEmail: '',
  dniAlreadyExists: false,
  identificationTypes: [],
  errorIdentificationTypes: ''
}

function newUserReducer(state = initialNewUser, action) {
  switch (action.type) {
    case userTypes.RESET_STATE:
      return initialNewUser
    case types.SET_NAME:
      return {
        ...state,
        name: action.payload.name
      }
    case types.SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload.lastName
      }
    case types.SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload.accountType
      }
    case types.SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload.companyName
      }
    case types.SET_CUIL:
      return {
        ...state,
        cuil: action.payload.cuil
      }
    case types.SET_BILLING_TYPE:
      return {
        ...state,
        billingType: action.payload.billingType
      }
    case types.SET_PASSWORD:
      return {
        ...state,
        password: action.payload.password
      }
    case types.SET_EMAIL:
      return {
        ...state,
        email: action.payload.email
      }
    case types.SET_LANDLINE:
      return {
        ...state,
        landline: action.payload.landline
      }
    case types.SET_PHONE:
      return {
        ...state,
        phone: action.payload.phone
      }
    case types.SET_DOCUMENT_TYPE:
      return {
        ...state,
        docType: action.payload.docType
      }
    case types.SET_DNI:
      return {
        ...state,
        dni: action.payload.dni
      }
    case types.SET_COUNTRY:
      return {
        ...state,
        country: action.payload.country
      }
    case types.SET_DATE_BIRTH:
      return {
        ...state,
        dateBirth: action.payload.dateBirth
      }
    case types.SET_GENDER:
      return {
        ...state,
        gender: action.payload.gender
      }
    case types.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod
      }
    case types.SET_ROLE:
      return {
        ...state,
        role: { ID: action.payload.role }
      }
    case types.SET_COUNTRY_PHONE_CODE:
      return {
        ...state,
        countryPhoneCode: action.payload.countryPhoneCode
      }
    case types.SET_COUNTRY_ALTERNATIVE_PHONE_CODE:
      return {
        ...state,
        countryAlternativePhoneCode: action.payload.countryAlternativePhoneCode
      }
    case types.SET_CREATE_MODAL_OPEN: {
      const { createModalOpen } = action.payload
      return createModalOpen
        ? {
            ...initialNewUser,
            createModalOpen
          }
        : {
            ...state,
            createModalOpen,
            errorDNI: '',
            errorEmail: ''
          }
    }
    case ProposalTypes.GET_PROPOSALS_REQUEST:
      return { ...initialNewUser, createModalOpen: !action.payload.statusID }
    case ProposalTypes.GET_PROPOSALS_SUCCESS:
      return {
        ...state,
        ...action.payload.prospect,
        name: action.payload.prospect.firstName,
        actualPhone: action.payload.prospect.phone
      }
    case types.EDIT_USER:
    case types.CREATE_NEW_USER:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.EDIT_USER_SUCCESS:
      return action.payload.byProposal ? { ...state, createModalOpen: false, loading: false } : initialNewUser
    case types.CREATE_NEW_USER_SUCCESS:
      return initialNewUser
    case types.EDIT_USER_FAIL:
    case types.CREATE_NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.SET_EDIT_MODAL_OPEN:
      return {
        ...state,
        ...action.payload.user,
        createModalOpen: true,
        editMode: true,
        actualPhone: action.payload.user.phone,
        actualLandline: action.payload.user.landline
      }
    case types.VERIFY_EMAIL_REQUEST:
      return { ...state, loadingEmail: true, errorEmail: '' }
    case types.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        editMode: true,
        loadingEmail: false,
        actualPhone: action.payload.user.phone,
        actualLandline: action.payload.user.landline
      }
    case types.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        errorEmail: action.payload.error,
        editMode: false,
        loadingEmail: false
      }
    case types.VERIFY_DNI_REQUEST:
      return {
        ...state,
        loadingDNI: true,
        errorDNI: '',
        dniAlreadyExists: false
      }
    case types.VERIFY_DNI_SUCCESS: {
      const isSameUser = state.guid === action.payload.user.guid
      if (state.editMode) return { ...state, loadingDNI: false, dniAlreadyExists: !isSameUser }
      return {
        ...state,
        ...action.payload.user,
        editMode: true,
        loadingDNI: false,
        actualPhone: action.payload.user.phone,
        actualLandline: action.payload.user.landline
      }
    }
    case types.VERIFY_DNI_FAILURE:
      return { ...state, errorDNI: action.payload.error, loadingDNI: false }
    default:
      return state
  }
}

export default newUserReducer
