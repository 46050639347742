import {
  GET_OBJECT_CHANGES_FAILURE,
  GET_OBJECT_CHANGES_REQUEST,
  GET_OBJECT_CHANGES_SUCCESS,
  GetObjectChangesRequest,
  GetObjectChangesSuccess,
  ObjectChangesFormatted
} from '../types/objectChanges'
import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import { OBJECT_STATUS } from '../../../Objects/constants/objectStatus'
import { ObjectSituationLabels } from '../../../Objects/constants/objectSituations'
import { keysToCamel } from '../../../../utils/snakeCaseToCamel'

const formatObjects = (objects: any[]): ObjectChangesFormatted[] =>
  objects.map(({ changeAudit, detail }) => {
    const objectSituationKey = detail.objectSituation as keyof typeof ObjectSituationLabels
    const objectSituationLabel = ObjectSituationLabels[objectSituationKey] || '-'

    return {
      objectId: changeAudit.objectId,
      ownerId: detail.ownerId,
      deletedAt: detail.deletedAt,
      deletedDescription: detail.deletedDescription,
      objectDeletedReasonId: detail.objectDeletedReasonId,
      identificationCodes: detail.identificationCodes ? `${detail.identificationCodes[0]?.code}` : '-',
      createdAt: changeAudit.createdAt,
      location: detail.location
        ? `${detail.location.code} (${detail.location.id})`
        : detail.locationId || 'Sin identificación definida',
      user: changeAudit.user
        ? `${changeAudit.user.name} ${changeAudit.user.lastName} (${changeAudit.user.id})`
        : changeAudit.userId,
      objectStatusId:
        detail.objectStatusId === 0
          ? ` (${detail.objectStatusId})`
          : `${OBJECT_STATUS.find((x) => x.numberValue === detail.objectStatusId)?.value || '(0)'} (${
              detail.objectStatusId
            })`,
      objectSituation: objectSituationLabel,
      productId: detail.product?.id,
      product: detail.product
        ? `(${detail.product.id}) ${detail.product.name}`
        : detail.productId || 'Sin identificación definida',
      description: detail.description || '-',
      heightInCm: detail.heightInCm,
      widthInCm: detail.widthInCm,
      lengthInCm: detail.lengthInCm,
      weightInGr: detail.weightInGr,
      source: changeAudit.source
    }
  })

const objectChangesActionCreator = {
  getObjectChanges:
    (objectID: number): AppThunk =>
    async (dispatch) => {
      const request: GetObjectChangesRequest = {
        type: GET_OBJECT_CHANGES_REQUEST,
        payload: {
          objectID
        }
      }

      dispatch(request)

      try {
        const data = await API.ObjectAdministration.Corporate.changes(objectID)
        const formattedObjects = formatObjects(keysToCamel(data.results))

        const success: GetObjectChangesSuccess = {
          type: GET_OBJECT_CHANGES_SUCCESS,
          payload: {
            objectChanges: formattedObjects
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_OBJECT_CHANGES_FAILURE, dispatch)
      }
    }
}

export default objectChangesActionCreator
