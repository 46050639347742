import { Moment } from 'moment'

export interface CardsTableState {
  userId: string
  cards: Card[]
  loadingCards: boolean
}

export type Card = {
  cardId: number
  createdAt: Moment | null
  updatedAt: Moment | null
  deletedAt: Moment | null
  token: string
  userID: number
  mpCardID: string
  main: boolean
  lastDigits: string
  type: string
  typePhoto: string
  expiration: string
  expirationDate: Moment | null
  paymentType: string
}

export const DISABLE_CARD_REQUEST = 'CARDS/CARD_TABLE/DISABLE_CARD_REQUEST'
export const DISABLE_CARD_SUCCESS = 'CARDS/CARD_TABLE/DISABLE_CARD_SUCCESS'
export const DISABLE_CARD_FAILURE = 'CARDS/CARD_TABLE/DISABLE_CARD_FAILURE'

export interface DisableCardRequest {
  type: typeof DISABLE_CARD_REQUEST
  payload: {
    cardId: number
  }
}

export interface DisableCardSuccess {
  type: typeof DISABLE_CARD_SUCCESS
}

export interface DisableCardFailure {
  type: typeof DISABLE_CARD_FAILURE
}

export type DisableCardActions = DisableCardRequest | DisableCardSuccess | DisableCardFailure

export const MARK_MAIN_CARD_REQUEST = 'CARDS/CARD_TABLE/MARK_MAIN_CARD_REQUEST'
export const MARK_MAIN_CARD_SUCCESS = 'CARDS/CARD_TABLE/MARK_MAIN_CARD_REQUEST'
export const MARK_MAIN_CARD_FAILURE = 'CARDS/CARD_TABLE/MARK_MAIN_CARD_FAILURE'

export interface MarkMainCardRequest {
  type: typeof MARK_MAIN_CARD_REQUEST
  payload: {
    cardId: number
  }
}

export interface MarkMainCardSuccess {
  type: typeof MARK_MAIN_CARD_SUCCESS
}

export interface MarkMainCardFailure {
  type: typeof MARK_MAIN_CARD_FAILURE
}

export type MarkMainCardActions = MarkMainCardRequest | MarkMainCardSuccess | MarkMainCardFailure

export const GET_CARDS_REQUEST = 'CARDS/CARDS_TABLE/GET_CARDS_REQUEST'
export const GET_CARDS_SUCCESS = 'CARDS/CARDS_TABLE/GET_CARDS_SUCCESS'
export const GET_CARDS_FAILURE = 'CARDS/CARDS_TABLE/GET_CARDS_FAILURE'

export interface GetCardsRequest {
  type: typeof GET_CARDS_REQUEST
  payload: {
    userId: string
    silentLoading?: boolean
  }
}

export interface GetCardsSuccess {
  type: typeof GET_CARDS_SUCCESS
  payload: {
    cards: Card[]
  }
}

export interface GetCardsFailure {
  type: typeof GET_CARDS_FAILURE
}

export type GetCardsActions = GetCardsRequest | GetCardsSuccess | GetCardsFailure

export type CardsActions = GetCardsActions | MarkMainCardActions | DisableCardActions
