import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Card, Icon } from 'antd'
import moment from 'moment'
import { Info } from '../../../icons'

const styles = {
  container: {
    width: '100%',
    maxHeight: 750,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    overflowY: 'auto'
  },
  card: {
    width: 260,
    margin: 10,
    paddingTop: 32
  },
  internalCard: {
    width: 260,
    margin: 10,
    backgroundColor: '#C5C5C5'
  },
  deletedAtContainer: {
    opacity: 0.3
  },
  img: {
    width: 250,
    height: 250,
    margin: 'auto',
    objectFit: 'contain',
    cursor: 'pointer'
  },
  line: {
    margin: 13
  },
  deletedDescriptionContainer: {
    color: 'red',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}

const Photos = (props) => {
  const { Photos } = props

  const onImageClick = (url) => {
    window.open(url, '_blank')
  }

  const onMarkAsMain = (item) => {
    props.selectMainPhoto(props.ID, item.ID, item.Url)
  }

  const onRemove = (item) => {
    props.selectPhoto(props.ID, item.ID, item.Url)
  }

  if (Photos.length === 0)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 500,
          padding: '36px 0',
          flexDirection: 'column',
          gap: 12
        }}>
        <Info fill={'rgb(47, 44, 124)'} dimensions={{ height: 36, width: 36 }} />
        Este objeto no posee fotos
      </div>
    )

  return (
    <div style={styles.container}>
      {Photos.map((item) => (
        <Photo key={item.ID} item={item} onRemove={onRemove} onMarkAsMain={onMarkAsMain} onImageClick={onImageClick} />
      ))}
    </div>
  )
}

const PhotoType = PropTypes.shape({
  ID: PropTypes.number,
  Url: PropTypes.string.isRequired,
  MainPhoto: PropTypes.bool.isRequired,
  DeletedAt: PropTypes.string,
  DeletedDescription: PropTypes.string
})

Photos.propTypes = {
  Photos: PropTypes.arrayOf(PhotoType),
  selectMainPhoto: PropTypes.func.isRequired,
  selectPhoto: PropTypes.func.isRequired
}

const formatDate = (date) => {
  if (date === '0001-01-01T00:00:00Z') return ''
  return moment(date).format('DD/MM/YY')
}

const Photo = (props) => {
  const { item, onImageClick, onRemove, onMarkAsMain } = props
  const actions = useMemo(() => {
    if (item.DeletedAt) {
      return []
    } else if (item.IsInternal) {
      return [<Icon type="delete" key="delete" title="Eliminar" onClick={() => onRemove(item)} />]
    }
    return [
      !item.MainPhoto && (
        <Icon type="crown" key="crown" title="Marcar como principal" onClick={() => onMarkAsMain(item)} />
      ),
      <Icon type="delete" key="delete" title="Eliminar" onClick={() => onRemove(item)} />
    ]
  }, [item])

  const cover = useMemo(() => {
    if (item.DeletedAt) {
      return (
        <div style={styles.deletedAtContainer}>
          <img src={item.Url} alt="-" style={styles.img} onClick={() => onImageClick(item.Url)} />
        </div>
      )
    }
    return <img src={item.Url} alt="-" style={styles.img} onClick={() => onImageClick(item.Url)} />
  }, [item])

  const description = useMemo(() => {
    if (item.DeletedAt) {
      return (
        <div style={styles.deletedDescriptionContainer}>
          <span>Eliminado</span>
          {item.CreatedAt ? <div>Creación: {formatDate(item.CreatedAt)}</div> : '-'}
          <hr style={styles.line} />
          <span title={item.DeletedDescription}>Razón: {item.DeletedDescription ? item.DeletedDescription : '-'}</span>
        </div>
      )
    } else if (item.IsInternal) {
      return (
        <>
          <span style={{ fontWeight: 700, color: 'black' }}>USO INTERNO</span>
          {item.CreatedAt ? <div>Creación: {formatDate(item.CreatedAt)}</div> : '-'}
        </>
      )
    }
    return (
      <>
        {item.MainPhoto === true ? 'Principal' : '-'}
        {item.CreatedAt ? <div>Creación: {formatDate(item.CreatedAt)}</div> : '-'}
      </>
    )
  }, [item])

  return (
    <Card style={item.IsInternal ? styles.internalCard : styles.card} key={item.ID} cover={cover} actions={actions}>
      <Card.Meta description={description} />
    </Card>
  )
}

Photo.defaultProps = {
  DeletedDescription: '-'
}

Photo.propTypes = {
  item: PhotoType,
  onImageClick: PropTypes.func.isRequired,
  onMarkAsMain: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default Photos
