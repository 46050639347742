import { AxiosResponse } from 'axios'
import { Moment } from 'moment'
import { apiPrivate } from '../../api'
import { URL_BASE_COMPLAINTS } from '../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { convertToMoment } from '../utils'
import { OverrideProps } from '@/utils/utilityTypes'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ComplaintsReasonsType } from '@/sections/Complaints/types/common'

export interface APIMain {
  content: APIResponse
}

export interface APIResponse {
  reasons: APIReason[]
  total: number
}

export interface APIReason {
  id: number
  created_at: string
  updated_at: string
  description: string
  type: ComplaintsReasonsType
}

type CommonDateProps = 'createdAt' | 'updatedAt'
export type ComplaintsReasons = OverrideProps<CamelCasedPropertiesDeep<APIReason>, CommonDateProps, Moment | null>

export type Response = {
  reasons: ComplaintsReasons[]
  total: number
}

export type ReasonsParams = {
  order?: 'asc' | 'desc'
  column?: 'id'
  type?: ComplaintsReasonsType
  count?: boolean
}

export function reasons(params: ReasonsParams): Promise<Response> {
  const formattedParams = snakecaseKeys({ ...params, count: true })

  return apiPrivate
    .get(`${URL_BASE_COMPLAINTS}/reasons`, { params: formattedParams })
    .then((response: AxiosResponse<APIMain>) => {
      const reasons = response.data.content.reasons
      return {
        reasons:
          camelcaseKeys(reasons, { deep: true }).map((reason) => ({
            ...reason,
            createdAt: convertToMoment(reason.createdAt),
            updatedAt: convertToMoment(reason.updatedAt)
          })) ?? [],
        total: response.data.content.total
      }
    })
    .catch((error) => {
      throw error
    })
}
