import {
  CANCEL_FAILURE,
  CANCEL_REQUEST,
  CANCEL_SUCCESS,
  CLOSE_MODAL,
  GET_REASONS_FAILURE,
  GET_REASONS_REQUEST,
  GET_REASONS_SUCCESS,
  OPEN_MODAL,
  ReasonOption,
  SET_REASON,
  closeModal,
  openModal,
  setReason
} from '../types/cancel'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { handleErrorsWithActionLoginAs } from '../../../../utils/HandleErrors'
import { apiPrivate } from '../../../../api'
import { AppThunk } from '../../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'

const actions = {
  getReasons(): AppThunk {
    return (dispatch) => {
      dispatch({ type: GET_REASONS_REQUEST })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + '/removal-cancel-reasons/search')
        .then((response) => {
          const reasons = response.data.results
          dispatch({ type: GET_REASONS_SUCCESS, payload: { reasons } })
        })
        .catch((error) => {
          handleErrorsWithActionLoginAs(error, GET_REASONS_FAILURE, dispatch)
        })
    }
  },
  cancel(guid: string, reasonId: number): AppThunk {
    const toastId = setToastLoading('Cancelando devolución, por favor espere...')
    return (dispatch) => {
      dispatch({ type: CANCEL_REQUEST })
      return apiPrivate
        .delete(
          URL_BASE_TRANSACTIONS + `/admin/operation/removal/${guid}?removal_cancel_reason_id=${reasonId.toString()}`
        )
        .then((response) => {
          const { operation } = response.data
          setToastSuccessUpdate(toastId, {
            render: 'Devolución cancelada correctamente'
          })
          dispatch({ type: CANCEL_SUCCESS, payload: { operation } })
        })
        .catch((error) => {
          setToastErrorUpdate(toastId, {
            render: 'Error al cancelar la devolución'
          })
          handleErrorsWithActionLoginAs(error, CANCEL_FAILURE, dispatch)
        })
    }
  },
  openPanel: ({ operationId, guid }: { operationId: number; guid: string }): openModal => ({
    type: OPEN_MODAL,
    payload: { operationId, guid }
  }),
  close: (): closeModal => ({ type: CLOSE_MODAL }),
  setReason: (reason: ReasonOption): setReason => ({
    type: SET_REASON,
    payload: { reason }
  })
}

export default actions
