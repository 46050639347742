import React from 'react'
import Component from '../components/calculateBillingModal'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/calculateBillingModal'

const Container = (ownProps) => {
  const dispatch = useDispatch()
  const calculateBillingActions = bindActionCreators(actions, dispatch)

  const { calculateBillingModal } = useSelector((state) => state.Billing)

  const props = {
    ...ownProps,
    calculateBillingActions,
    calculateBillingModal
  }

  return <Component {...props} />
}

export default Container
