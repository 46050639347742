import React, { ReactNode } from 'react'
import { Country, CountryIdCode, CountryName, CountryPhoneCode, allCountriesOption } from '../constants/constants'
import { StyledCountryName, StyledFlagContainer, StyledFlagImage } from './styled'

export const formatCountryOptions = function <T extends CountryName | CountryIdCode>(
  countries: Country[],
  valueParam: 'fullName' | 'code' = 'fullName'
): Option<T>[] {
  return countries.map((c) => ({
    value: c[valueParam] as T,
    label: c.fullName,
    countryPhoneCode: c.phoneCode,
    fullCountry: c,
    customLabel: (
      <StyledFlagContainer>
        <StyledFlagImage src={c.flagUrl} />
        <StyledCountryName>{c.label}</StyledCountryName>
      </StyledFlagContainer>
    )
  }))
}

export const FlagComponent = ({ flagUrl, label }: { flagUrl?: string; label: string }) => (
  <StyledFlagContainer>
    <StyledFlagImage src={flagUrl || allCountriesOption.flagUrl} />
    <StyledCountryName>{label}</StyledCountryName>
  </StyledFlagContainer>
)

export type Option<T> = {
  value: T
  label: CountryName
  customLabel: ReactNode
  countryPhoneCode: CountryPhoneCode
  fullCountry: Country
}
