import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Moment } from 'moment'

export type RemovalsValidationMetricsKeys =
  | 'total'
  | 'quantity_programmed_removals'
  | 'quantity_pending_payment_removals'
  | 'quantity_authorized_removals'

interface APIResponse {
  total: number
  quantity_programmed_removals: number
  quantity_programmed_m_removals: number
  quantity_pending_payment_removals: number
  quantity_authorized_removals: number
}

type Response = Omit<CamelCasedPropertiesDeep<APIResponse>, 'quantityProgrammedMRemovals'>
export type QuantityRemovalsValidationMetrics = CamelCasedPropertiesDeep<APIResponse>

export interface MetricsParams {
  dateFrom?: Moment
  dateTo?: Moment
}

export function metrics({ dateFrom, dateTo }: MetricsParams): Promise<Response> {
  const params = {
    date_from: dateFrom?.format('YYYY-MM-DD'),
    date_to: dateTo?.format('YYYY-MM-DD')
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/removals/validateRemoval/metrics`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const {
        total,
        quantityAuthorizedRemovals,
        quantityPendingPaymentRemovals,
        quantityProgrammedMRemovals,
        quantityProgrammedRemovals
      } = camelcaseKeys(response.data, { deep: true })

      return {
        total,
        quantityProgrammedRemovals: quantityProgrammedRemovals + quantityProgrammedMRemovals,
        quantityPendingPaymentRemovals,
        quantityAuthorizedRemovals
      }
    })
    .catch((err) => {
      throw err
    })
}
