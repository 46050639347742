import { Moment } from 'moment'
import { UserExclusionReason } from '../../../projectApi/AccountManager/userExclusionReasons'
import { BaseOption } from '../../../components/Select/Select'
import { UserResponse } from '../../../projectApi/TransactionHandler/BillingData/list'

export enum UserExclusionTypes {
  GET_USER_EXCLUSION_REASONS_REQUEST = 'BILLINGS/USER_EXCLUSION/GET_USER_EXCLUSION_REASONS_REQUEST',
  GET_USER_EXCLUSION_REASONS_SUCCESS = 'BILLINGS/USER_EXCLUSION/GET_USER_EXCLUSION_REASONS_SUCCESS',
  GET_USER_EXCLUSION_REASONS_FAILURE = 'BILLINGS/USER_EXCLUSION/GET_USER_EXCLUSION_REASONS_FAILURE',
  USER_EXCLUSION_REQUEST = 'BILLINGS/USER_EXCLUSION/USER_EXCLUSION_REQUEST',
  USER_EXCLUSION_SUCCESS = 'BILLINGS/USER_EXCLUSION/USER_EXCLUSION_SUCCESS',
  USER_EXCLUSION_FAILURE = 'BILLINGS/USER_EXCLUSION/USER_EXCLUSION_FAILURE',

  SET_MODAL_OPEN = 'BILLINGS/USER_EXCLUSION/SET_MODAL_OPEN',
  SET_ENABLE_MODAL_OPEN = 'BILLINGS/USER_EXCLUSION/SET_ENABLE_MODAL_OPEN',
  SET_SELECTED_OPTION = 'BILLINGS/USER_EXCLUSION/SET_SELECTED_OPTION',
  SET_PERIOD_TO = 'BILLINGS/USER_EXCLUSION/SET_PERIOD_TO',
  SET_PERIOD_FROM = 'BILLINGS/USER_EXCLUSION/SET_PERIOD_FROM',
  SET_SPECIFIC_PERIOD_FROM = 'BILLINGS/USER_EXCLUSION/SET_SPECIFIC_PERIOD_FROM',
  SET_SPECIFIC_PERIOD_TO = 'BILLINGS/USER_EXCLUSION/SET_SPECIFIC_PERIOD_TO',
  SET_SELECTED_REASON = 'BILLINGS/USER_EXCLUSION/SET_SELECTED_REASON'
}

export enum EnableUserBillingRequestTypes {
  ENABLE_USER_BILLING_REQUEST = 'BILLINGS/USER_ENABLE_MODAL/ENABLE_USER_BILLING_REQUEST',
  ENABLE_USER_BILLING_SUCCESS = 'BILLINGS/USER_ENABLE_MODAL/ENABLE_USER_BILLING_SUCCESS',
  ENABLE_USER_BILLING_FAILURE = 'BILLINGS/USER_ENABLE_MODAL/ENABLE_USER_BILLING_FAILURE'
}

export enum Periods {
  THIS_PERIOD = 1,
  SEVERAL_PERIODS = 2,
  FOREVER = 3,
  SPECIFIC_DATES = 4
}

export type Option<T> = {
  value: T
  label: string
  extraComponent?: React.ReactNode
}

export interface UserExclusionState {
  openModal: boolean
  openEnableModal: boolean
  user?: UserResponse
  selectedOption: Periods
  selectedReason: BaseOption<number> | null
  specificPeriodFrom: Moment
  specificPeriodTo: Moment
  periodFrom: Moment
  periodTo: Moment
  userExclusionReasons: UserExclusionReason[]
  error: string
  loadingUserExclusionReasons: boolean
  loading: boolean
  enablingUser: boolean
}

export interface GetUserExclusionReasonsRequest {
  type: UserExclusionTypes.GET_USER_EXCLUSION_REASONS_REQUEST
}

export interface GetUserExclusionReasonsSuccess {
  type: UserExclusionTypes.GET_USER_EXCLUSION_REASONS_SUCCESS
  payload: {
    reasons: UserExclusionReason[]
  }
}

export interface GetUserExclusionReasonsFailure {
  type: UserExclusionTypes.GET_USER_EXCLUSION_REASONS_FAILURE
  payload: {
    error: string
  }
}

export type GetUserExclusionReasonsActions =
  | GetUserExclusionReasonsRequest
  | GetUserExclusionReasonsSuccess
  | GetUserExclusionReasonsFailure

export interface SetModalOpen {
  type: UserExclusionTypes.SET_MODAL_OPEN
  payload: {
    open: boolean
    user?: UserResponse
  }
}

export interface SetEnableModalOpen {
  type: UserExclusionTypes.SET_ENABLE_MODAL_OPEN
  payload: {
    openEnableModal: boolean
    user?: UserResponse
  }
}

export interface SetSelectedOption {
  type: UserExclusionTypes.SET_SELECTED_OPTION
  payload: {
    option: Periods
  }
}

export interface SetSpecificPeriodFrom {
  type: UserExclusionTypes.SET_SPECIFIC_PERIOD_FROM
  payload: {
    specificPeriodFrom: Moment
  }
}

export interface SetSpecificPeriodTo {
  type: UserExclusionTypes.SET_SPECIFIC_PERIOD_TO
  payload: {
    specificPeriodTo: Moment
  }
}

export interface SetPeriodTo {
  type: UserExclusionTypes.SET_PERIOD_TO
  payload: {
    periodTo: Moment
  }
}

export interface SetSelectedReason {
  type: UserExclusionTypes.SET_SELECTED_REASON
  payload: {
    reason: BaseOption<number> | null
  }
}

export interface UserExclusionRequest {
  type: UserExclusionTypes.USER_EXCLUSION_REQUEST
}

export interface UserExclusionSuccess {
  type: UserExclusionTypes.USER_EXCLUSION_SUCCESS
}

export interface UserExclusionFailure {
  type: UserExclusionTypes.USER_EXCLUSION_FAILURE
  payload: {
    error: string
  }
}

export interface EnableUserBillingRequest {
  type: EnableUserBillingRequestTypes.ENABLE_USER_BILLING_REQUEST
}

export interface EnableUserBillingSuccess {
  type: EnableUserBillingRequestTypes.ENABLE_USER_BILLING_SUCCESS
}

export interface EnableUserBillingFailure {
  type: EnableUserBillingRequestTypes.ENABLE_USER_BILLING_FAILURE
}

export type EnableUserBillingRequestActions =
  | EnableUserBillingRequest
  | EnableUserBillingSuccess
  | EnableUserBillingFailure

export type UserExclusionActions =
  | GetUserExclusionReasonsActions
  | EnableUserBillingRequestActions
  | SetModalOpen
  | SetEnableModalOpen
  | SetSelectedOption
  | SetPeriodTo
  | SetSpecificPeriodFrom
  | SetSpecificPeriodTo
  | SetSelectedReason
  | UserExclusionRequest
  | UserExclusionSuccess
  | UserExclusionFailure
