import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Provider } from '../../../projectApi/Trips/Providers/list'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { ProvidersSearchFilter, ProvidersTableSearchKey, ProvidersTableSort } from '../types/ProviderList'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { parseDate } from '../../../components/DynamicTable/utils/utils'
import { getSearchProps, getSortProps } from '../../../components/DynamicTable/utils'

type ProviderTableProps = {
  fetchingProviders: boolean
  providers: Provider[]
  pagination: Pagination
  searchFilters: ProvidersSearchFilter[]
  actions: MenuAction<Provider>[]
  handleSearch: (key: ProvidersTableSearchKey, newValue: string) => void
  handleSort: (newSort: ProvidersTableSort) => void
  sort: ProvidersTableSort
}

const ProvidersTable = ({
  fetchingProviders,
  providers,
  pagination,
  handleSearch,
  searchFilters,
  actions,
  sort,
  handleSort
}: ProviderTableProps) => {
  const columns: Column<Provider>[] = [
    {
      key: 'id',
      label: 'ID',
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('id', sort, handleSort),
      renderDataCell: ({ id }) => {
        return id
      }
    },
    {
      key: 'name',
      label: 'Nombre',
      renderDataCell: ({ name }) => name,
      search: getSearchProps('name', 'Nombre', handleSearch, searchFilters),
      sort: getSortProps('name', sort, handleSort)
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      minWidth: 'min-content',
      renderDataCell: ({ createdAt }) => (createdAt ? parseDate(createdAt) : '-'),
      sort: getSortProps('created_at', sort, handleSort)
    },
    {
      key: 'updatedAt',
      label: 'Fecha de actualización',
      minWidth: 'min-content',
      renderDataCell: ({ updatedAt }) => (updatedAt ? parseDate(updatedAt) : '-'),
      sort: getSortProps('updated_at', sort, handleSort)
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingProviders}
      rows={providers}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default ProvidersTable
