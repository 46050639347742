export enum TransactionEventsEnum {
  OPERATORS_ASSIGNED_TO_TRANSACTIONS = 'TRANSACTION/OPERATORS_ASSIGNED_TO_TRANSACTIONS',
  TRANSACTIONS_REMOVED_FROM_TRIP = 'TRANSACTION/TRANSACTIONS_REMOVED_FROM_TRIP'
}

export type TransactionEvents = {
  [TransactionEventsEnum.OPERATORS_ASSIGNED_TO_TRANSACTIONS]: ({ transactionIds }: { transactionIds: number[] }) => void
  [TransactionEventsEnum.TRANSACTIONS_REMOVED_FROM_TRIP]: ({
    transactionIds,
    tripId
  }: {
    transactionIds: number[]
    tripId: number
  }) => void
}
