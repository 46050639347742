import { CountryResponse } from '../../../projectApi/AccountManager/Countries/getAll'
import {
  Country,
  CountryAdditionalInformation,
  CountryCurrency,
  CountryIdCode,
  CountryName,
  CountryPhoneCode
} from '../constants/constants'
import { ValueOf } from 'type-fest'

const getAdditionalCountryInfo = (countryResponse: CountryResponse): CountryAdditionalInformation => {
  switch (countryResponse.id) {
    case CountryIdCode.ARGENTINA: {
      return {
        phoneCode: CountryPhoneCode.ARGENTINA,
        fullName: CountryName.ARGENTINA,
        flagUrl: `${process.env.PUBLIC_URL}/img/flags/argentina-v2.svg`
      }
    }
    case CountryIdCode.SPAIN: {
      return {
        phoneCode: CountryPhoneCode.SPAIN,
        fullName: CountryName.SPAIN,
        flagUrl: `${process.env.PUBLIC_URL}/img/flags/spain-v2.svg`
      }
    }
    default:
      return {
        phoneCode: CountryPhoneCode.ALL,
        fullName: CountryName.ALL,
        flagUrl: `${process.env.PUBLIC_URL}/img/flags/all-v2.svg`
      }
  }
}

export const formatCountryResponse = (countriesResponse: CountryResponse[]): Country[] =>
  countriesResponse.map((countryResponse) => ({
    label: countryResponse.name,
    code: countryResponse.id as CountryIdCode,
    currency: countryResponse.currency as CountryCurrency,
    ...getAdditionalCountryInfo(countryResponse)
  }))

export const getCurrentCountry = (
  countries: Country[],
  value: ValueOf<Country>,
  key: keyof Country = 'code'
): Country => countries.find((co) => co[key] === value) || countries[0]

export const fullCountryToCountryCode = (country: CountryName | string) => {
  switch (country) {
    case 'España':
      return CountryIdCode.SPAIN
    case 'Argentina':
    default:
      return CountryIdCode.ARGENTINA
  }
}
