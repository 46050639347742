import React, { useEffect, useState } from 'react'
import Select from '../../../components/Select/Select'
import { Button, Col, Input, InputNumber, Modal, Row, Spin } from 'antd'
import { isEmpty } from 'ramda'
import InputGroup from '../../../components/InputGroup'
import { formatNumber } from '../../../utils/formatNumber'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    minHeight: '30vh',
    maxHeight: '60vh'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  errorInput: {
    color: 'red',
    marginTop: 10,
    marginBottom: 0
  },
  successObject: {
    color: 'green',
    marginTop: 10,
    marginBottom: 0
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block'
  },
  inlineSelect: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '70%'
  },
  cont: {
    marginBottom: '6px'
  },
  editDimensionButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}

let codeRef = null
let productRef = null

const TotalVolume = ({ totalVolume }) => (
  <span>
    Volumen: {formatNumber(totalVolume)}{' '}
    <span>
      m<sup>3</sup>
    </span>
  </span>
)

const ScanModal = ({
  modalOpenScan: modalOpen,
  editMode,
  object,
  objectCode,
  loadingObjects,
  errorObjects,
  lastSearchIDCode,
  lastSearchIDProduct,
  products,
  loadingProducts,
  errorProducts,
  product,
  loading,
  error,
  height,
  length,
  width,
  weight,
  setProduct,
  setHeight,
  setLength,
  setWidth,
  setObjectCode,
  setScanModalClose,
  getObjectsToRecordByCode,
  getProductsByDescription,
  setWeight,
  recordObject,
  serviceTypeId
}) => {
  const productsOptions = products.map(({ guid, description }) => ({ label: description, value: guid }))

  const saveButtonDisabled = loadingObjects || object === 0 || isEmpty(product)

  const successObject = !editMode && object !== 0 && !loadingObjects

  const [editDimensions, setEditDimensions] = useState(false)
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    if (modalOpen) setEditDimensions(false)
    if (editMode) {
      if (productRef) productRef.focus()
    } else {
      if (codeRef) codeRef.focus()
    }
  }, [modalOpen, editMode])

  useEffect(() => {
    if (objectCode) {
      setObjectCode(objectCode)
      getObjectsToRecordByCode(objectCode)
    }
  }, [objectCode])

  useEffect(() => {
    const totalVolume = (height * width * length) / 1000000
    setVolume(totalVolume)
  }, [height, width, length])

  const [autocomplete, setAutocomplete] = React.useState('')

  return (
    <Modal
      title="Escanear Objetos"
      visible={modalOpen}
      onCancel={setScanModalClose}
      closable={!loading}
      footer={[
        <Button disabled={loading} key="back" onClick={setScanModalClose}>
          Volver
        </Button>,
        <Button
          type="primary"
          disabled={saveButtonDisabled}
          loading={loading}
          key="middle"
          onClick={() => {
            recordObject(object, product, true, editDimensions)
          }}>
          Guardar objeto
        </Button>,
        <Button
          type="primary"
          disabled={saveButtonDisabled}
          loading={loading}
          key="enter"
          onClick={() => {
            recordObject(object, product, false, editDimensions)
            setEditDimensions(false)
            codeRef.focus()
          }}>
          Guardar objeto y seguir
        </Button>
      ]}
      style={styles.modal}
      bodyStyle={styles.bodyModal}>
      <Row gutter={[32]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24}>
          <InputGroup title="Objeto y Producto">
            <span style={styles.inputTitle}>Código de barras: </span>
            <Input
              id="codebar"
              type="text"
              ref={(ref) => (codeRef = ref)}
              autoFocus={!editMode}
              disabled={editMode}
              placeholder="Código de barras"
              onChange={(e) => {
                const { value } = e.target
                setObjectCode(value)
                getObjectsToRecordByCode(value)
              }}
              suffix={
                loadingObjects && (
                  <span>
                    <Spin size="small" />
                  </span>
                )
              }
              value={objectCode}
            />
            {successObject && <p style={styles.successObject}>¡Código válido!</p>}
            {!isEmpty(errorObjects) && <p style={styles.errorInput}>{errorObjects}</p>}
            <span style={styles.inputTitle}>Producto: </span>
            <Select
              ref={(ref) => (productRef = ref)}
              placeholder="Buscar Producto"
              selected={productsOptions.find((x) => x.value === product)}
              autoFocus={editMode}
              options={productsOptions}
              loading={loadingProducts}
              autocomplete={{
                filterByInput: true,
                input: autocomplete,
                onInputChange: (value) => {
                  setAutocomplete(value)
                  if (!isEmpty(value)) getProductsByDescription(value, serviceTypeId)
                }
              }}
              onSelect={(option) => {
                if (!option) return setProduct(null)
                setProduct(option.value)
              }}
            />
            {!isEmpty(errorProducts) && <p style={styles.errorInput}>{errorProducts}</p>}
          </InputGroup>
          <InputGroup title="Dimensiones del producto">
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <p style={styles.inputTitle}>Alto (cm)*</p>
                <InputNumber
                  min={0}
                  value={height}
                  onChange={setHeight}
                  placeholder="Alto*"
                  disabled={!editDimensions}
                />
              </Col>
              <Col span={6}>
                <p style={styles.inputTitle}>Ancho (cm)*</p>
                <InputNumber
                  value={width}
                  min={0}
                  onChange={setWidth}
                  placeholder="Ancho*"
                  disabled={!editDimensions}
                />
              </Col>
              <Col span={6}>
                <p style={styles.inputTitle}>Largo (cm)*</p>
                <InputNumber
                  value={length}
                  min={0}
                  onChange={setLength}
                  placeholder="Largo*"
                  disabled={!editDimensions}
                />
              </Col>
              <Col span={6}>
                <p style={styles.inputTitle}>Peso (kg)*</p>
                <InputNumber
                  min={0}
                  value={weight}
                  onChange={setWeight}
                  placeholder="Peso*"
                  disabled={!editDimensions}
                />
              </Col>
              {volume !== 0 && (
                <Col span={24}>
                  <TotalVolume totalVolume={volume} />
                </Col>
              )}
              {!editDimensions && (
                <Col span={24} style={styles.editDimensionButton}>
                  <Button
                    type="primary"
                    disabled={isEmpty(product) || loadingObjects}
                    loading={loading}
                    key="middle"
                    onClick={() => setEditDimensions(true)}>
                    Modificar dimensiones
                  </Button>
                  ,
                </Col>
              )}
            </Row>
          </InputGroup>
          {!isEmpty(error) && <p style={styles.error}>{error}</p>}
        </Col>
      </Row>
    </Modal>
  )
}

export default ScanModal
