import React, { useMemo } from 'react'
import styles from './Sorter.module.scss'
import { SortDirection, SortProps } from '../../types/types'
import { ArrowDown, ArrowUp } from '../../../../icons'

const Sorter: React.FC<SortProps> = ({ children, direction, isActive, onClick }) => {
  const nextSortDirection: SortDirection = useMemo(() => {
    switch (direction) {
      case SortDirection.ASC:
        return SortDirection.DESC
      case SortDirection.DESC:
        return SortDirection.ASC
    }
  }, [direction])

  const handleClick = () => {
    onClick(nextSortDirection)
  }

  return (
    <div className={styles.Sorter} onClick={handleClick}>
      <div className={styles.ChildrenContainer}>{children}</div>
      <div className={styles.ArrowsContainer}>
        <ArrowUp fill={isActive && direction === SortDirection.ASC ? '#5D58F7' : '#979797'} />
        <ArrowDown fill={isActive && direction === SortDirection.DESC ? '#5D58F7' : '#979797'} />
      </div>
    </div>
  )
}

export default Sorter
