import React, { useEffect } from 'react'
import Select from '../../../components/Select/Select'
import { Button, Col, Input, Modal, Row } from 'antd'
import { isEmpty } from 'ramda'
import InputGroup from '../../../components/InputGroup'
import moment from 'moment'

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    minHeight: '30vh',
    maxHeight: '60vh'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  errorInput: {
    color: 'red',
    marginTop: 10,
    marginBottom: 0
  },
  successObject: {
    color: 'green',
    marginTop: 10,
    marginBottom: 0
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inline: {
    marginLeft: '6px',
    display: 'inline-block'
  },
  inlineSelect: {
    marginLeft: '6px',
    display: 'inline-block',
    width: '70%'
  },
  cont: {
    marginBottom: '6px'
  }
}

const formatDate = (date) => moment(date).utc().format('DD/MM/YY HH:mm')

const AddMissingObjectModal = (props) => {
  const {
    modalOpen,
    transactions,
    loadingTransactions,
    loadingObject,
    errorTransactions,
    transactionGUID,
    searchTransactionID,
    loading,
    error,
    errorCode,
    newValue,
    getPickedUpTransactions,
    addMissingObject,
    setTransactionGUID,
    setMissingObjectModalClose,
    setSearchClientID,
    getCodeTMP
  } = props

  const saveButtonDisabled = loading || isEmpty(newValue) || isEmpty(transactionGUID) || loadingObject

  const transactionsOptions = transactions.map((tr) => ({
    value: tr.ID,
    label: `${tr.RealID} - 
    ${formatDate(tr.ReserveDate)} - 
    ${tr.TransactionType.Description} - 
    ${tr.Client.RealID}
    ${tr.Client.Name}
    ${tr.Client.LastName}`
  }))

  useEffect(() => {
    if (modalOpen && !isEmpty(searchTransactionID)) {
      getPickedUpTransactions(searchTransactionID)
      getCodeTMP()
    }
  }, [searchTransactionID])

  const searchAndCheck = (e) => {
    if (/^([0-9]{0,5})$/.test(e)) setSearchClientID(e)
  }

  return (
    <Modal
      title="Agregar objeto no registrado"
      visible={modalOpen}
      onCancel={setMissingObjectModalClose}
      closable={!loading}
      footer={[
        <Button disabled={loadingObject} key="back" onClick={setMissingObjectModalClose}>
          Cerrar
        </Button>,
        <Button
          type="primary"
          disabled={saveButtonDisabled}
          loading={loadingObject}
          key="middle"
          onClick={() => addMissingObject(transactionGUID, newValue)}>
          Agregar objeto
        </Button>
      ]}
      style={styles.modal}
      bodyStyle={styles.bodyModal}>
      <Row gutter={[32]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
        <Col span={24}>
          <InputGroup title="Transacción y código">
            <span style={styles.inputTitle}>Filtrar por ID de Cliente</span>
            <Input
              id="transaction-id"
              maxLength={5}
              placeholder="ID de Cliente"
              value={searchTransactionID}
              onChange={(e) => searchAndCheck(e.target.value)}
            />
            <span style={styles.inputTitle}>Operaciones de Ingreso</span>
            <Select
              placeholder="Operación"
              selected={transactionsOptions.find((x) => x.value === transactionGUID)}
              clearable={false}
              searchable={false}
              autoFocus={true}
              options={transactionsOptions}
              loading={loadingTransactions}
              onSelect={(option) => setTransactionGUID(option.value)}
            />
            {!isEmpty(errorTransactions) && <p style={styles.errorInput}>{errorTransactions}</p>}
            <span style={styles.inputTitle}>Etiqueta de Identificación</span>
            <Input id="code" type="text" placeholder="Etiqueta" maxLength={10} value={newValue} disabled={true} />
            {!isEmpty(errorCode) && <p style={styles.errorInput}>{errorCode}</p>}
          </InputGroup>
          {!isEmpty(error) && <p style={styles.error}>{error}</p>}
        </Col>
      </Row>
    </Modal>
  )
}

export default AddMissingObjectModal
