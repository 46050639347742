import React, { useEffect } from 'react'
import PropTypes, { shape } from 'prop-types'
import { formatUsers } from '../Users.selectors'
import actions from '../Users.actions'
import newUserActions from '../actions/newUser'
import billingActions from '../actions/billingModal'
import sendCSVActionCreators from '../actions/sendCSV'
import { connect, useDispatch, useSelector } from 'react-redux'
import UsersTable from '../components/usersTable'
import termsActions from '../actions/terms'
import checkoutActions from '../actions/checkout'
import changeEmailActions from '../actions/changeEmail'
import { Buffer } from 'buffer'
import { equals, forEachObjIndexed, isEmpty } from 'ramda'
import { useHistory } from 'react-router'
import {
  CardIcon,
  CommentIcon,
  Edit,
  EnvelopeIcon,
  InvoiceIcon,
  MoneyIcon,
  ShoppingCart,
  SignIn,
  User,
  UserSlashed
} from '../../../icons'
import { bindActionCreators } from 'redux'

const UsersTableContainer = (props) => {
  const history = useHistory()
  const loadingCSV = useSelector((state) => state.Users.sendCSV.loading)
  const roles = useSelector((state) => state.Users.roles.roles)
  const dispatch = useDispatch()
  const sendCSVActions = bindActionCreators(sendCSVActionCreators, dispatch)

  const { fetchingMetrics, usersMetrics } = useSelector((state) => state.Users.metrics)

  useEffect(() => {
    const { resetState, getUsers } = props
    if (!history.location.state || !history.location.state.redirect) {
      resetState().then(() => getUsers(props.table.params))
    } else {
      history.replace('/users', { redirect: false })
    }
  }, [props.country])

  const handleSearch = (dataIndex, text) => {
    const {
      table: { params, pagination: currentPagination, searchFilters: currentSearchFilters },
      setSearchFilters,
      setPagination,
      getUsers
    } = props
    const searchFilters = currentSearchFilters.map((searchFilter) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text
          }
        : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(searchFilters)
    setPagination({
      ...currentPagination,
      current: 1
    })
    getUsers(newParams)
  }

  const getNewFilterParams = (params, filters) => {
    let formatFilters = {}
    const Offset = 0
    forEachObjIndexed((value, key) => {
      formatFilters = {
        ...formatFilters,
        [getKeyFilter(key)]: value[0]
      }
    }, filters)
    return {
      ...params,
      ...formatFilters,
      Offset
    }
  }

  const handleFilter = (newFilters) => {
    const { table, getUsers, setFilter, setPagination } = props
    const { pagination, filter, params } = table
    if (!equals(newFilters, filter)) {
      const newParams = { ...getNewFilterParams(params, newFilters) }

      setFilter(newFilters)
      setPagination({
        ...pagination,
        current: 1
      })
      getUsers(newParams)
    }
  }

  const handlePagination = (newPage) => {
    const { getUsers, setPagination, table } = props
    const { params, pagination } = table

    const Offset = (newPage - 1) * params.Limit
    const newParams = {
      ...params,
      Offset
    }

    setPagination({
      ...pagination,
      current: newPage
    })
    getUsers(newParams)
  }

  const getKeyFilter = (key) => {
    switch (key) {
      case 'TransactionDescription':
        return 'type'
      case 'Status':
        return 'status'
      default:
        return key
    }
  }

  const getOrder = (order) => (order === 'descend' ? 'desc' : 'asc')

  const handleByChangeUrl = (userId, name, lastName) => {
    const uriEncode = Buffer.from(escape(`name=${name}&lastName=${lastName}`)).toString('base64')
    history.push(`/users/${userId}/address?` + uriEncode)
  }

  const handleByChangeUrlCBUS = (userId, name, lastName) => {
    const uriEncode = Buffer.from(escape(`name=${name}&lastName=${lastName}`)).toString('base64')
    history.push(`/users/${userId}/cbus?` + uriEncode)
  }

  const handleByChangeUrlCards = (userId, name, lastName, country) => {
    const uriEncode = Buffer.from(escape(`name=${name}&lastName=${lastName}&country=${country}`)).toString('base64')
    history.push(`/users/${userId}/cards?` + uriEncode)
  }

  const {
    setLoginAsModalOpen,
    openBillingModal,
    setEditModalOpen,
    openCheckoutModal,
    toggleDeletePanel,
    toggleEnablePanel,
    openTermsPanel,
    cleanFilters,
    getUsersFromStore,
    setCreateModalOpen,
    openModalChangeEmail
  } = props

  const menuActions = [
    {
      label: 'Editar usuario',
      icon: <Edit />,
      onClick: (row) => setEditModalOpen(row)
    },
    {
      label: 'Deshabilitar usuario',
      icon: <UserSlashed />,
      onClick: (row) => toggleDeletePanel(true, row),
      disabled: ({ DisableDate }) => DisableDate
    },
    {
      label: 'Habilitar Usuario',
      icon: <User />,
      onClick: (row) => toggleEnablePanel(true, row),
      disabled: ({ DisableDate }) => !DisableDate
    },
    {
      label: 'Ingresar como el usuario',
      icon: <SignIn />,
      onClick: (row) => setLoginAsModalOpen(true, row)
    },
    {
      label: 'Datos de facturación',
      icon: <InvoiceIcon />,
      onClick: (row) => {
        openBillingModal(row.RealID, row.Country)
      }
    },
    {
      label: 'ABM de Direcciones',
      icon: <CommentIcon />,
      onClick: (row) => handleByChangeUrl(row.RealID, row.Name, row.LastName)
    },
    {
      label: 'CBU/CVUs',
      icon: <MoneyIcon />,
      onClick: (row) => handleByChangeUrlCBUS(row.RealID, row.Name, row.LastName)
    },
    {
      label: 'Tarjetas',
      icon: <CardIcon />,
      onClick: (row) => handleByChangeUrlCards(row.RealID, row.Name, row.LastName, row.Country)
    },
    {
      label: 'Generar Link de Checkout',
      icon: <ShoppingCart />,
      onClick: (row) => openCheckoutModal(row.RealID, row.Name, row.LastName, row.Country)
    },
    {
      label: 'Cambiar email',
      icon: <EnvelopeIcon />,
      onClick: ({ RealID, Name, LastName, Email, Country }) => {
        openModalChangeEmail(true, {
          id: RealID,
          name: Name,
          lastName: LastName,
          currentEmail: Email,
          country: Country
        })
      }
    }
  ]

  const getColumn = (column) => {
    switch (column) {
      case 'LastName':
        return 'last_name'
      case 'RealID':
        return 'ID'
      case 'CreationDate':
        return 'created_at'
      default:
        return column
    }
  }

  const handleDateRangePiker = (key, startDate, endDate) => {
    const { getUsers, setPagination, table } = props
    const { dateRangeFilters, params, pagination } = table
    const newDateRangeFilters = dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    setPagination({
      ...pagination,
      current: 1
    })
    getUsers({ ...params, dateRangeFilters: newDateRangeFilters })
  }

  const handleSort = (newSorter) => {
    const { currentPagination, setSorter, setPagination, params, getUsers } = props

    setSorter(newSorter)
    setPagination({
      ...currentPagination,
      current: 1
    })
    getUsers({
      ...params,
      ...(newSorter.order ? { Order: newSorter.order } : {}),
      Offset: 0,
      Column: getColumn(newSorter.field)
    })
  }

  const pagination = {
    pageSize: props.table.params.Limit,
    total: props.table.pagination.total,
    currentPage: props.table.pagination.current,
    onPageChange: handlePagination
  }

  const onSendCSV = () => {
    sendCSVActions.sendUsersCSV(props.table.params)
  }

  return (
    <UsersTable
      users={props.table.users}
      pagination={pagination}
      searchFilters={props.table.searchFilters}
      sorter={props.table.sorter}
      loading={props.table.loading}
      filter={props.table.filter}
      actions={menuActions}
      cleanFilters={cleanFilters}
      getUsersFromStore={getUsersFromStore}
      setCreateModalOpen={setCreateModalOpen}
      getOrder={getOrder}
      onSendCSV={onSendCSV}
      handleSearch={handleSearch}
      handleDateRangePiker={handleDateRangePiker}
      dateRangeFilters={props.table.dateRangeFilters}
      handleSort={handleSort}
      handleFilter={handleFilter}
      openTermsPanel={openTermsPanel}
      categoryFilter={props.table.filter}
      countries={props.countries}
      roles={roles}
      loadingCSV={loadingCSV}
      fetchingMetrics={fetchingMetrics}
      usersMetrics={usersMetrics}
    />
  )
}

UsersTableContainer.propTypes = {
  country: PropTypes.string,
  table: PropTypes.shape({
    users: PropTypes.array.isRequired,
    params: PropTypes.shape({
      Limit: PropTypes.number,
      Offset: PropTypes.number,
      Column: PropTypes.string,
      Order: PropTypes.string
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    filter: PropTypes.shape().isRequired,
    sorter: PropTypes.shape().isRequired,
    pagination: PropTypes.shape({
      current: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired,
    searchFilters: PropTypes.arrayOf(shape())
  })
}

const mapStateToProps = (state) => {
  const table = state.Users.table
  const { countries, countrySelected } = state.CountrySelector

  return {
    table: {
      ...table,
      users: formatUsers(table.users)
    },
    countries,
    country: countrySelected
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginAsModalOpen: (loginAsModalOpen, selectedUser) =>
      dispatch(actions.setLoginAsModalOpen(loginAsModalOpen, selectedUser)),
    openBillingModal: (userId, country) => dispatch(billingActions.openBillingModal(userId, country)),
    setCreateModalOpen: (boolean) => dispatch(newUserActions.setCreateModalOpen(boolean)),
    getUsers: (params) => dispatch(actions.getUsers(params)),
    getUsersFromStore: (params) => dispatch(actions.getUsersFromStore(params)),
    setFilter: (filter) => dispatch(actions.setFilter(filter)),
    setSorter: (sorter) => dispatch(actions.setSorter(sorter)),
    setPagination: (pagination) => dispatch(actions.setPagination(pagination)),
    setSearchFilters: (searchFilters) => dispatch(actions.setSearchFilters(searchFilters)),
    openTermsPanel: (id, name, lastName, country) => dispatch(termsActions.openPanel(id, name, lastName, country)),
    setEditModalOpen: (user) => dispatch(newUserActions.setEditModalOpen(user)),
    openCheckoutModal: (id, name, lastName, country) =>
      dispatch(checkoutActions.openModal(id, name, lastName, country)),
    openModalChangeEmail: (isOpen, data) => dispatch(changeEmailActions.setOpen(isOpen, data)),
    resetState: () => dispatch(actions.resetState()),
    cleanFilters: () => dispatch(actions.cleanFilters())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableContainer)
