import React from 'react'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { ServiceSearch } from '../../../projectApi/CategoryCreation/ServiceType/search'

export type ServicesTableProps = {
  services: ServiceSearch[]
  loadingServices: boolean
  pagination: Pagination
  actions: MenuAction<ServiceSearch>[]
}

export const ServicesTable: React.FC<ServicesTableProps> = ({ loadingServices, services, pagination, actions }) => {
  const columns: Column<ServiceSearch>[] = [
    {
      label: 'ID',
      key: 'id'
    },
    {
      label: 'Tipo',
      key: 'type'
    },
    {
      label: 'Multiplicador',
      key: 'multiplier'
    },
    {
      label: 'Tipo de operación',
      key: 'operationType'
    },
    {
      label: 'ID de la solución',
      key: 'solution'
    }
  ]

  return (
    <>
      <DynamicTable
        columns={columns}
        loading={loadingServices}
        rows={services}
        keyExtractor={(entity) => entity.id}
        pagination={pagination}
        actions={actions}
      />
    </>
  )
}
