import types from '../types/newUser'
import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import addressActions from '../../../components/Address/actions'
import { keysToCamel } from '../../../utils/snakeCaseToCamel'
import moment from 'moment'
import userActions from '../Users.actions'
import { formatEditUser } from '../Users.selectors'

function getCommonUserFields(formData, user, byProposal = false) {
  const { name, lastName, phone, landline, dni, gender, dateBirth, address, docType, country } = user

  formData.append('name', name)
  formData.append('last_name', lastName)
  formData.append('landline', landline)
  formData.append('phone', phone)
  formData.append('identification_type', docType)
  formData.append('dni', dni)
  formData.append('country_id', country)
  if (dateBirth !== null) formData.append('date_of_birth', dateBirth.format('YYYY-MM-DD'))
  formData.append('gender', gender)

  if (!byProposal) {
    const { addressGoogle, street, number, postalCode, floor, apartment, province, city } = address

    formData.append('address_street', street)
    formData.append('address_number', number)
    formData.append('address_postal_code', postalCode)
    formData.append('address_floor', floor)
    formData.append('address_apartment', apartment)
    formData.append('address_province', province)
    formData.append('address_city', city)
    formData.append('address_google_location', addressGoogle)
  }

  /*
  formData.append('payment_method_id', paymentMethod)
  formData.append('bill_type', billingType)
  formData.append('account_type', accountType)

  if (accountType === 1) {
    formData.append('company', companyName)
    formData.append('cuil', cuil)
  }
   */
}

function formatUserResults(result) {
  const { addresses, identification, ...user } = result

  const formattedUser = {
    ...keysToCamel(user),
    role: { ID: user.role.id },
    dateBirth: moment(user.date_of_birth),
    docType: identification.identification_type.id,
    dni: identification.identification_code
  }
  const address = addresses.find((address) => address.main_address) || addresses[0]

  const formattedAddress = {
    ...keysToCamel(address),
    addressGoogle: address.full,
    postalCode: address.postal_code.id,
    country: address.country.name
  }

  return { formattedUser, formattedAddress }
}

const newUserActions = {
  verifyIfDNIExists(identificationType, dni, byProposal = false) {
    return (dispatch) => {
      dispatch({ type: types.VERIFY_DNI_REQUEST })

      const params = {
        identification_type: identificationType,
        identification_code: dni
      }

      return apiPrivate
        .get(URL_BASE_USERS + '/users/search', { params })
        .then((response) => {
          if (response.data.results.length === 0) {
            dispatch({
              type: types.VERIFY_DNI_FAILURE,
              payload: { error: 'Doc. disponible' }
            })
            return false
          }

          const { formattedUser, formattedAddress } = formatUserResults(response.data.results[0])

          if (!byProposal) {
            dispatch(addressActions.setAddress(formattedAddress))
          }

          dispatch({
            type: types.VERIFY_DNI_SUCCESS,
            payload: { user: formattedUser }
          })
          return true
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.VERIFY_DNI_FAILURE, dispatch)
          return false
        })
    }
  },

  verifyIfEmailExists(email, byProposal = false) {
    return (dispatch) => {
      dispatch({ type: types.VERIFY_EMAIL_REQUEST })

      const params = { email }
      return apiPrivate
        .get(URL_BASE_USERS + '/users/search', { params })
        .then((response) => {
          if (response.data.results.length === 0) {
            dispatch({
              type: types.VERIFY_EMAIL_FAILURE,
              payload: { error: 'Email disponible' }
            })
            return false
          }

          const { formattedUser, formattedAddress } = formatUserResults(response.data.results[0])

          if (!byProposal) {
            dispatch(addressActions.setAddress(formattedAddress))
          }

          dispatch({
            type: types.VERIFY_EMAIL_SUCCESS,
            payload: { user: formattedUser }
          })
          return true
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.VERIFY_EMAIL_FAILURE, dispatch)
          return false
        })
    }
  },

  setName(name) {
    return { type: types.SET_NAME, payload: { name } }
  },

  setLastName(lastName) {
    return { type: types.SET_LAST_NAME, payload: { lastName } }
  },

  setAccountType(accountType) {
    return { type: types.SET_ACCOUNT_TYPE, payload: { accountType } }
  },

  setCompanyName(companyName) {
    return { type: types.SET_COMPANY_NAME, payload: { companyName } }
  },

  setCUIL(cuil) {
    return { type: types.SET_CUIL, payload: { cuil } }
  },

  setBillingType(billingType) {
    return { type: types.SET_BILLING_TYPE, payload: { billingType } }
  },

  setPassword(password) {
    return { type: types.SET_PASSWORD, payload: { password } }
  },

  setEmail(email) {
    return { type: types.SET_EMAIL, payload: { email } }
  },

  setLandline(landline) {
    return { type: types.SET_LANDLINE, payload: { landline } }
  },

  setPhone(phone) {
    return { type: types.SET_PHONE, payload: { phone } }
  },

  setDocumentType(docType) {
    return { type: types.SET_DOCUMENT_TYPE, payload: { docType } }
  },

  setDNI(dni) {
    return { type: types.SET_DNI, payload: { dni } }
  },

  setCountry(country) {
    return { type: types.SET_COUNTRY, payload: { country } }
  },

  setDateBirth(dateBirth) {
    return { type: types.SET_DATE_BIRTH, payload: { dateBirth } }
  },

  setGender(gender) {
    return { type: types.SET_GENDER, payload: { gender } }
  },

  setPaymentMethod(paymentMethod) {
    return { type: types.SET_PAYMENT_METHOD, payload: { paymentMethod } }
  },

  setCreateModalOpen(createModalOpen, byProposal) {
    return {
      type: types.SET_CREATE_MODAL_OPEN,
      payload: { createModalOpen, byProposal }
    }
  },

  setEditModalOpen(user) {
    return {
      type: types.SET_EDIT_MODAL_OPEN,
      payload: { user: formatEditUser(user) }
    }
  },

  setRole(role) {
    return { type: types.SET_ROLE, payload: { role } }
  },

  setCountryPhoneCode(countryPhoneCode) {
    return { type: types.SET_COUNTRY_PHONE_CODE, payload: { countryPhoneCode } }
  },

  setCountryAlternativePhoneCode(countryAlternativePhoneCode) {
    return { type: types.SET_COUNTRY_ALTERNATIVE_PHONE_CODE, payload: { countryAlternativePhoneCode } }
  },

  createNewUser(user) {
    return (dispatch, getState) => {
      dispatch({ type: types.CREATE_NEW_USER })

      const formData = new FormData()
      const { email, password } = user

      formData.append('email', email)
      formData.append('password', password)

      getCommonUserFields(formData, user)

      return apiPrivate
        .post(URL_BASE_USERS + '/users/v2', formData)
        .then((response) => {
          const params = getState().Users.table.params
          dispatch({ type: types.CREATE_NEW_USER_SUCCESS })
          dispatch(userActions.getUsers(params))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.CREATE_NEW_USER_FAIL, dispatch)
        })
    }
  },

  editUser(user, byProposal) {
    return (dispatch, getState) => {
      dispatch({ type: types.EDIT_USER })

      const formData = new FormData()

      const {
        guid: ID,
        role: { ID: roleID }
      } = user

      formData.append('role', roleID)

      getCommonUserFields(formData, user, byProposal)

      return apiPrivate
        .patch(URL_BASE_USERS + '/users/v2/' + ID, formData)
        .then((response) => {
          dispatch({ type: types.EDIT_USER_SUCCESS, payload: { byProposal } })
          const { params } = getState().Users.table
          dispatch(userActions.getUsers(params))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.EDIT_USER_FAIL, dispatch)
        })
    }
  }
}

export default newUserActions
