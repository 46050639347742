import { Col, Modal, Row } from 'antd'
import React, { useMemo } from 'react'
import InputGroup from '@/components/InputGroup'
import { InputTitle } from '@/components/NewCard/NewCard'
import Text from 'antd/lib/typography/Text'
import { useLocalization } from '@/packages/localization'
import styles from './ComplaintUpdateView.module.scss'
import { Input } from '@/components/UploadPhotos'
import RequiredField from '@/components/RequiredField/RequiredField'
import Select from '@/components/Select'
import { Complaint } from '@/projectApi/Complaints/list'
import { ComplaintType, Photo } from '../types/createComplaintModal'
import { parseMomentDate } from '@/components/DynamicTable/utils/utils'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'

export type ResolveModalProps = {
  handleClose: () => void
  setReasonId: (reasonId: number) => void
  setConfirmationOpen: ({ confirmationOpen }: { confirmationOpen: boolean }) => void
  setOpenResolve: ({ open }: { open: boolean }) => void
  reasonId: number
  open: boolean
  complaint: Complaint | null
  photos: Photo[]
  reasons: ComplaintsReasons[]
  deleteEvidence: (index: number) => void
}

const ResolveModalComponent: React.FC<ResolveModalProps> = ({
  handleClose,
  open,
  setReasonId,
  reasonId,
  setConfirmationOpen,
  complaint,
  setOpenResolve,
  photos,
  reasons,
  deleteEvidence
}) => {
  const { strings } = useLocalization()

  if (!complaint) return null

  const { id, createdAt, createUserData, reasonId: complaintReasonId, complaintType, operationId, daysOpen } = complaint

  const isDisabled = !reasonId

  const complaintReasons = useMemo(() => {
    if (!reasons) return []
    return reasons
      .filter((reason) => reason.type === 'CLOSE')
      .map((reason) => ({
        label: ` ${reason.description}`,
        value: reason.id
      }))
  }, [reasons])

  const reasonsDisabled = complaintReasons.length === 0

  return (
    <Modal
      centered
      closable={true}
      width={700}
      onOk={() => {
        setConfirmationOpen({ confirmationOpen: true })
        setOpenResolve({ open: false })
      }}
      okText={strings.ComplaintsViewScreen.resolveModal.title}
      visible={open}
      okButtonProps={{ disabled: isDisabled, className: styles.okBtn }}
      cancelButtonProps={{ className: styles.cancelBtn }}
      onCancel={handleClose}
      cancelText={strings.generic.cancel}
      title={strings.ComplaintsViewScreen.resolveModal.title}
      destroyOnClose>
      <article className={styles.resolveWarningContainer}>
        <span>{strings.ComplaintsViewScreen.resolveModal.warning}</span>
      </article>
      <InputGroup title={strings.ComplaintsViewScreen.resolveModal.fields.detailsTitleGroup}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.createdAt} />
            <Text>{createdAt ? parseMomentDate(createdAt, 'left', true) : '-'}</Text>
          </Col>
          <Col span={6}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.complaintId} />
            <Text>{id}</Text>
          </Col>
          <Col span={6}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.creator} />
            <Text>{`${createUserData.id} - ${createUserData.name} ${createUserData.lastName}`}</Text>
          </Col>
          <Col span={6}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.openDays} />
            <Text>{daysOpen}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={8}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.reason} />
            <Text>{reasons.find((reason) => reason.id === complaintReasonId)?.description || '-'}</Text>
          </Col>
          <Col span={8}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.type} />
            <Text>{ComplaintType[complaintType as keyof typeof ComplaintType]}</Text>
          </Col>
          <Col span={8}>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.operationId} />
            <Text>{operationId || '-'}</Text>
          </Col>
        </Row>
      </InputGroup>

      <InputGroup title={strings.ComplaintsViewScreen.resolveModal.fields.resolutionTitleGroup}>
        <div className={styles.resolveModalUpdateContainer}>
          <Row>
            <InputTitle title={strings.ComplaintsViewScreen.resolveModal.fields.statusTitle} />
            <Select
              loading={reasonsDisabled}
              disabled={reasonsDisabled}
              onSelect={({ value }) => setReasonId(value)}
              selected={complaintReasons.find((reason) => reason.value === reasonId)}
              options={complaintReasons}
              placeholder={strings.ComplaintsViewScreen.resolveModal.fields.statusPlaceholder}
            />
            <RequiredField />
          </Row>
          <Row>
            <Input
              fileType="objects"
              label={strings.ComplaintsViewScreen.createComplaintModal.fields.evidence}
              maxCount={10}
              handleRemovePhoto={deleteEvidence}
            />
          </Row>
        </div>
      </InputGroup>
    </Modal>
  )
}

export default ResolveModalComponent
