import { Card, CardField, PaymentMpActions, PaymentMpTypes } from '../types/payment-mp'
import commonTypes from '../types/common'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'

export type PaymentMpState = {
  operationId: number
  cards: Card[]
  spreedlyPM: PaymentMethod[]
  spreedlyPMID: number
  loadingCards: boolean
  errorCardFields: Record<CardField, string>
  errorCards: string
  loadingSaveCard: boolean
  errorSaveCard: string
  card_id: number
  number: string
  expiry: string
  cvc: string
  cardholderName: string
  documentType: string
  documentNumber: string
  type: string
  loading: boolean
  cardToken: string
  paymentMethod: string
  error: string
}

const initialState = {
  operationId: 0,
  cards: [],
  spreedlyPM: [],
  spreedlyPMID: 0,
  loadingCards: false,
  errorCardFields: {
    document: '',
    holder: '',
    number: '',
    expiry: '',
    cvc: ''
  },
  errorCards: '',
  loadingSaveCard: false,
  errorSaveCard: '',
  card_id: 0,
  number: '',
  expiry: '',
  cvc: '',
  cardholderName: '',
  documentType: 'DNI',
  documentNumber: '',
  type: '',
  loading: false,
  cardToken: '',
  paymentMethod: '',
  error: ''
}

const root = (state: PaymentMpState = initialState, action: PaymentMpActions): PaymentMpState => {
  switch (action.type) {
    case commonTypes.FINISH:
      return initialState
    case PaymentMpTypes.SET_CARD_ID:
      return { ...state, card_id: action.payload.cardId }
    case PaymentMpTypes.SET_SPREEDLY_PM_ID:
      return { ...state, spreedlyPMID: action.payload.spreedlyPMID }
    case PaymentMpTypes.SET_CARD_NUMBER:
      return {
        ...state,
        number: action.payload.number,
        errorCardFields: { ...state.errorCardFields, number: '' }
      }
    case PaymentMpTypes.SET_CARD_EXPIRY:
      return {
        ...state,
        expiry: action.payload.expiry,
        errorCardFields: { ...state.errorCardFields, expiry: '' }
      }
    case PaymentMpTypes.SET_CARD_CVC:
      return {
        ...state,
        cvc: action.payload.cvc,
        errorCardFields: { ...state.errorCardFields, cvc: '' }
      }
    case PaymentMpTypes.SET_CARDHOLDER_NAME:
      return {
        ...state,
        cardholderName: action.payload.cardholderName,
        errorCardFields: { ...state.errorCardFields, holder: '' }
      }
    case PaymentMpTypes.SET_CARD_FIELD_ERROR:
      return {
        ...state,
        errorCardFields: {
          ...state.errorCardFields,
          [action.payload.field]: action.payload.error
        }
      }
    case PaymentMpTypes.SET_DOCUMENT_TYPE:
      return { ...state, documentType: action.payload.documentType }
    case PaymentMpTypes.SET_DOCUMENT_NUMBER:
      return {
        ...state,
        documentNumber: action.payload.documentNumber,
        errorCardFields: { ...state.errorCardFields, document: '' }
      }
    case PaymentMpTypes.SET_CARD_TYPE:
      return { ...state, type: action.payload.type }
    case PaymentMpTypes.GET_CARDS_REQUEST:
      return { ...state, loadingCards: true, errorCards: '' }
    case PaymentMpTypes.GET_CARDS_SUCCESS: {
      const { cards } = action.payload
      return { ...state, loadingCards: false, cards }
    }
    case PaymentMpTypes.GET_CARDS_FAILURE:
      return { ...state, loadingCards: false, errorCards: action.payload.error }
    case PaymentMpTypes.GET_SPREEDLY_PM_REQUEST:
      return { ...state, loadingCards: true, errorCards: '' }
    case PaymentMpTypes.GET_SPREEDLY_PM_SUCCESS: {
      const { paymentMethods } = action.payload
      return { ...state, loadingCards: false, spreedlyPM: paymentMethods }
    }
    case PaymentMpTypes.SAVE_CARD_REQUEST:
      return { ...state, loadingSaveCard: true, errorSaveCard: '' }
    case PaymentMpTypes.SAVE_CARD_SUCCESS: {
      const { card } = action.payload
      return {
        ...state,
        loadingSaveCard: false,
        card_id: card.ID,
        cards: [card, ...state.cards]
      }
    }
    case PaymentMpTypes.SAVE_CARD_FAILURE:
      return {
        ...state,
        loadingSaveCard: false,
        errorSaveCard: action.payload.error
      }
    case PaymentMpTypes.SAVE_SPREEDLY_CARD_REQUEST:
      return { ...state, loadingSaveCard: true, errorSaveCard: '' }
    case PaymentMpTypes.SAVE_SPREEDLY_CARD_SUCCESS: {
      return {
        ...state,
        loadingSaveCard: false
      }
    }
    case PaymentMpTypes.SAVE_SPREEDLY_CARD_FAILURE:
      return {
        ...state,
        loadingSaveCard: false,
        errorSaveCard: action.payload.error
      }
    case PaymentMpTypes.SAVE_REMOVAL_REQUEST:
      return { ...state, loading: true, error: '' }
    case PaymentMpTypes.SAVE_REMOVAL_SUCCESS: {
      return { ...state, loading: false }
    }
    case PaymentMpTypes.SAVE_REMOVAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case PaymentMpTypes.CREATE_DRAFT_REMOVAL_REQUEST:
      return { ...state, loading: true, error: '' }
    case PaymentMpTypes.CREATE_DRAFT_REMOVAL_SUCCESS: {
      return { ...state, loading: false }
    }
    case PaymentMpTypes.CREATE_DRAFT_REMOVAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case PaymentMpTypes.PAY_DRAFT_REMOVAL_REQUEST:
      return { ...state, loading: true, error: '' }
    case PaymentMpTypes.PAY_DRAFT_REMOVAL_SUCCESS: {
      return { ...state, loading: false }
    }
    case PaymentMpTypes.PAY_DRAFT_REMOVAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case PaymentMpTypes.SET_REMOVAL_OPERATION_ID:
      return {
        ...state,
        operationId: action.payload.operationId
      }
    case PaymentMpTypes.CLEAN_ERRORS:
      return {
        ...state,
        error: '',
        errorCardFields: initialState.errorCardFields,
        errorCards: ''
      }
    case PaymentMpTypes.CLEAN_CREDIT_CARD_FIELDS:
      return {
        ...state,
        number: initialState.number,
        expiry: initialState.expiry,
        cvc: initialState.cvc,
        cardholderName: initialState.cardholderName,
        documentType: initialState.documentType,
        documentNumber: initialState.documentNumber,
        type: initialState.type
      }
    default:
      return state
  }
}

export default root
