import { Tooltip } from 'antd'
import React from 'react'
import { Copy } from '../../icons'
import IconButton from '../IconButton'

type CopyButtonProps = {
  textToCopy: string
  tooltipText?: string
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, tooltipText }) => {
  const [showTooltipText, setShowTooltipText] = React.useState<boolean>(false)

  const handleOnClick = () => {
    if (tooltipText) {
      setShowTooltipText(true)
      setTimeout(() => setShowTooltipText(false), 1000)
    }

    navigator.clipboard.writeText(textToCopy)
  }

  const button = (
    <IconButton onClick={handleOnClick}>
      <Copy />
    </IconButton>
  )

  return tooltipText ? (
    <Tooltip visible={showTooltipText} title={tooltipText}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}

export default CopyButton
