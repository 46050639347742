import React from 'react'
import { Modal } from 'antd'
import { UserABMTypes } from '../types/userABM'
import styles from './UserInviteModal.module.scss'
import Select from '../../../../components/Select'
import UserABMActionCreators from '../actions/userABM'
import InputGroup from '../../../../components/InputGroup'
import { Row } from '../../../NewProduct/components/subcomponents/GeneralData'
import { TextField } from '../../../../components/TextField/TextField'
import { BaseOption } from '../../../../components/Select/Select'

type FindUserProps = {
  userABMState: UserABMTypes
  userABMActions: typeof UserABMActionCreators
  handleCloseModal: () => void
  onOkClick: ({ email, roleId }: { email: string; roleId: number }) => void
  errorEmail: string
  handleChangeEmail: (email: string) => void
  formatRolesOptions: BaseOption<number>[]
  getOptionSelected: (roleId: number | null) => BaseOption<number>
}

const UserInviteModal = (props: FindUserProps) => {
  const {
    userABMState,
    handleCloseModal,
    errorEmail,
    onOkClick,
    handleChangeEmail,
    formatRolesOptions,
    userABMActions,
    getOptionSelected
  } = props
  const { setRoleId } = userABMActions
  const { error, isLoading, isAddModalOpen, email, roleId, isEdit } = userABMState

  const isNotReadyToSubmit = !email || !roleId || Boolean(errorEmail)

  return (
    <Modal
      width={500}
      title={isEdit ? 'Editar usuario' : 'Invitar usuario'}
      visible={isAddModalOpen}
      closable={true}
      keyboard={false}
      onOk={() => onOkClick({ email, roleId })}
      onCancel={handleCloseModal}
      okButtonProps={{
        className: styles.modalButtonOk,
        disabled: isNotReadyToSubmit || isLoading
      }}
      okText={isEdit ? 'Aceptar' : 'Enviar'}
      cancelButtonProps={{
        className: styles.modalButtonCancel
      }}
      cancelText={'Cancelar'}
      className={styles.modal}>
      <InputGroup title="Información del usuario" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Row className={styles.row}>
          <TextField
            label="Email:"
            disabled={isLoading || isEdit}
            onChange={(value) => handleChangeEmail(value)}
            value={email}
            error={Boolean(errorEmail)}
            errorText={errorEmail}
            leftIcon="user"
            type="text"
          />
        </Row>
        <Row>
          <Select
            placeholder={'Seleccione un rol'}
            loading={isLoading}
            options={formatRolesOptions}
            selected={getOptionSelected(roleId)}
            onSelect={(option) => setRoleId(option.value)}
            onDelete={() => setRoleId(0)}
          />
        </Row>
      </InputGroup>
      {error && <div className={styles.errorContainer}>{error}</div>}
    </Modal>
  )
}

export default UserInviteModal
