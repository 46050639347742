import { Transaction } from '../../../projectApi/TransactionHandler/Transaction/TripTransactions/list'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'
import { Picking, PickingsListSortKey } from '../../../projectApi/TransactionHandler/Pickings/list'
import { Operator } from '../../../projectApi/TransactionHandler/Pickupers/list'

export type PickingsTableSearchKey = 'id' | 'owner_id' | 'operation_id'
export type PickingsSearchFilter = SearchFilter<PickingsTableSearchKey>

export type PickingsTableDateRangeKey = 'picking_date' | 'start_date' | 'end_date' | 'created_at'

export type PickingsDateRangeFilter = DateRangeFilter<PickingsTableDateRangeKey>

export type PickingsTableSort = Sort<PickingsListSortKey>

export type PickingsTableCategoryFilterKey = 'status' | 'agent_id'
export type PickingsTableCategoryFilter = CategoryFilter<PickingsTableCategoryFilterKey>

export type Pagination = {
  page: number
  total: number
  pageSize: number
}

export interface PickingsTableState {
  loadingOperators: boolean
  operators: Operator[]
  pickings: Picking[]
  loadingPickings: boolean
  error: string
  categoryFilter: PickingsTableCategoryFilter
  pagination: Pagination
  searchFilters: PickingsSearchFilter[]
  dateRangeFilters: PickingsDateRangeFilter[]
  sort: PickingsTableSort
}

export const SET_CANCELING_MODAL_ID = 'PICKINGS/TABLE/SET_CANCELING_MODAL_ID'

export interface SetCancelingModalId {
  type: typeof SET_CANCELING_MODAL_ID
  payload: {
    id: number | null
  }
}

export const TOGGLE_SELECTED_TRANSACTION = 'PICKINGS/TABLE/TOGGLE_SELECTED_TRANSACTION'

export interface ToggleSelectedTransaction {
  type: typeof TOGGLE_SELECTED_TRANSACTION
  payload: {
    transaction: Transaction
  }
}

export const TOGGLE_TRIP_TRANSACTIONS = 'PICKINGS/TABLE/TOGGLE_TRIP_TRANSACTIONS'

export const GET_PICKINGS_REQUEST = 'PICKINGS/TABLE/GET_PICKINGS_REQUEST'
export const GET_PICKINGS_SUCCESS = 'PICKINGS/TABLE/GET_PICKINGS_SUCCESS'
export const GET_PICKINGS_FAILURE = 'PICKINGS/TABLE/GET_PICKINGS_FAILURE'

export interface GetPickingsRequest {
  type: typeof GET_PICKINGS_REQUEST
  payload: {
    pageSize: number
    newPage: number
    searchFilters?: PickingsSearchFilter[]
    dateRangeFilters?: PickingsDateRangeFilter[]
    categoryFilter?: PickingsTableCategoryFilter
    sort?: PickingsTableSort
    silentLoading?: boolean
  }
}

export interface GetPickingsSuccess {
  type: typeof GET_PICKINGS_SUCCESS
  payload: {
    total: number
    pickings: Picking[]
  }
}

export interface GetPickingsFailure {
  type: typeof GET_PICKINGS_FAILURE
  payload: {
    error: string
  }
}

export type GetPickingsAction = GetPickingsRequest | GetPickingsSuccess | GetPickingsFailure

export const GET_OPERATORS_REQUEST = 'PICKINGS/GET_OPERATORS_REQUEST'
export const GET_OPERATORS_SUCCESS = 'PICKINGS/GET_OPERATORS_SUCCESS'
export const GET_OPERATORS_FAILURE = 'PICKINGS/GET_OPERATORS_FAILURE'

export interface GetOperatorsRequest {
  type: typeof GET_OPERATORS_REQUEST
}

export interface GetOperatorsSuccess {
  type: typeof GET_OPERATORS_SUCCESS
  payload: {
    operators: Operator[]
  }
}

export interface GetOperatorsFailure {
  type: typeof GET_OPERATORS_FAILURE
  payload: {
    error: string
  }
}

export type GetOperatorsAction = GetOperatorsRequest | GetOperatorsSuccess | GetOperatorsFailure

export type PickingsTableAction = GetPickingsAction | GetOperatorsAction
