import types from '../types/RegisterObjects'

const LIMIT = 50

const initialTableObjects = {
  objects: [],
  loading: false,
  params: {
    Limit: LIMIT,
    Offset: 0,
    Column: 'ID',
    Order: 'desc'
  },
  quantity: 0,
  sorter: {
    order: 'descend',
    field: 'RealID',
    columnKey: 'RealID'
  },
  searchFilters: [{ key: 'code', text: '' }],
  pagination: {
    current: 1,
    total: 0
  }
}

function RegisterObjectsTableReducer(state = initialTableObjects, action) {
  switch (action.type) {
    case types.CLEAN_FILTERS: {
      return {
        ...state,
        params: {
          ...initialTableObjects.params,
          Column: state.params.Column,
          Order: state.params.Order
        },
        filter: {},
        searchFilters: initialTableObjects.searchFilters,
        pagination: initialTableObjects.pagination
      }
    }
    case types.FETCH_OBJECTS:
      return {
        ...state,
        objects: [],
        loading: true,
        error: ''
      }
    case types.RECORD_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        objects: state.objects.filter((o) => o.real_id !== action.payload.id)
      }
    case types.FETCH_OBJECTS_SUCCESS:
      return {
        ...state,
        objects: action.payload.objects,
        loading: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case types.FETCH_OBJECTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    case types.ADD_MISSING_OBJECT_SUCCESS: {
      return {
        ...state,
        objects: [action.payload.object, ...state.objects]
      }
    }
    default:
      return state
  }
}

export default RegisterObjectsTableReducer
