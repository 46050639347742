import moment from 'moment'
import { clone } from 'ramda'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ObjectsOperation as DepositAPIObject } from '../../../projectApi/TransactionHandler/DepositsOperations/getById'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { Flag } from '../../../projectApi/ObjectAdministration/Value/getValue'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  AttributesNameEnum,
  CLEAR_VALIDATION_VIEW_STATE,
  ClearValidationViewState,
  GET_BILLING_BY_TRANSACTION_FAILURE,
  GET_BILLING_BY_TRANSACTION_REQUEST,
  GET_BILLING_BY_TRANSACTION_SUCCESS,
  GET_DEPOSIT_OPERATION_BY_ID_FAILURE,
  GET_DEPOSIT_OPERATION_BY_ID_REQUEST,
  GET_DEPOSIT_OPERATION_BY_ID_SUCCESS,
  GetBillingByTransactionRequest,
  GetBillingByTransactionSuccess,
  GetDepositOperationByIdRequest,
  GetDepositOperationByIdSuccess,
  POPULATE_VIEW_FAILURE,
  POPULATE_VIEW_REQUEST,
  POPULATE_VIEW_SUCCESS,
  PopulateViewRequest,
  PopulateViewSuccess,
  RECALCULATE_OPERATION_FAILURE,
  RECALCULATE_OPERATION_REQUEST,
  RECALCULATE_OPERATION_SUCCESS,
  RecalculateOperationRequest,
  RecalculateOperationSuccess,
  SET_FETCHED_RECALCULATION,
  SET_OBJECT_FLOORS,
  SET_TRANSPORT_EXTRA_COUNT,
  SET_TRANSPORT_EXTRA_PRICE,
  SetFetchedRecalculation,
  SetObjectFloors,
  SetTransportExtraCount,
  SetTransportExtraPrice,
  TOGGLE_OBJECT_EXTRA,
  TOGGLE_TRANSPORT_EXTRA,
  ToggleObjectExtra,
  ToggleTransportExtra,
  VALIDATE_OPERATION_FAILURE,
  VALIDATE_OPERATION_REQUEST,
  VALIDATE_OPERATION_SUCCESS,
  ValidateOperationRequest,
  ValidateOperationSuccess,
  ValidationViewObject
} from '../types/validationView'
import { ObjectToValidate } from '../../../projectApi/TransactionHandler/DepositsOperations/validate'
import { sendToastNotificationInfo, sendToastNotificationSuccess } from '../../../utils/notifications'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { DepositStatus } from '../../../projectApi/TransactionHandler/Operation/list'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { getProvisionalBilling } from '../utils'

export const getFlagsByCountry = (countryCode: CountryIdCode): { extraKmPrice: Flag; extraAssistantPrice: Flag } => {
  switch (countryCode) {
    case CountryIdCode.SPAIN:
      return {
        extraAssistantPrice: Flag.EXTRA_ASSISTANT_PRICE_ESP,
        extraKmPrice: Flag.EXTRA_KM_PRICE_ESP
      }
    case CountryIdCode.ARGENTINA:
    default:
      return {
        extraAssistantPrice: Flag.EXTRA_ASSISTANT_PRICE_ARG,
        extraKmPrice: Flag.EXTRA_KM_PRICE_ARG
      }
  }
}

export const convertObjectsToValidationObjects = (
  operationObjects: CamelCasedPropertiesDeep<DepositAPIObject>[],
  enabled = false
): ValidationViewObject[] => {
  return clone(operationObjects)
    .sort((a, b) => a.object.product.id - b.object.product.id)
    .map((obj) => {
      const enabledPackagingAttribute =
        obj.objectXAttributes?.find((attr) => attr.attribute.name === AttributesNameEnum.PACKING)?.value || false
      return {
        objectId: obj.object.id,
        photos: obj.object.photos,
        productId: obj.object.product.id,
        productName: obj.object.product.name,
        storageCostInCents: obj.priceInCents,
        extras: {
          disassembly: {
            enabled,
            disassemblyCost: obj.object.product.entryCosts.disassemblyCost
          },
          stairs: {
            enabled,
            floors: obj.floors || 1,
            floorsCostPerFloorInCents: obj.object.product.entryCosts.depositCostPerFloorInCents
          },
          packaging: {
            enabled: enabledPackagingAttribute === 'true',
            packagingCostInCents: obj.object.product.entryCosts.packagingCostInCents
          }
        },
        deletedAt: obj.object.deletedAt,
        objectXAttributes: obj.objectXAttributes?.map((attr) => ({
          ...attr,
          attribute: {
            createdAt: attr.attribute.createdAt,
            dataType: attr.attribute.dataType,
            id: attr.attribute.id,
            isVisible: attr.attribute.isVisible,
            name: attr.attribute.name,
            unit: attr.attribute.unit
          },
          value: attr.value,
          id: attr.id,
          objectId: attr.objectId,
          attributeId: attr.attributeId,
          deletedAt: attr.deletedAt
        }))
      }
    })
}

const DepositValidationViewActionCreators = {
  clearValidationViewState: (): ClearValidationViewState => ({ type: CLEAR_VALIDATION_VIEW_STATE }),
  toggleTransportExtra: (type: 'assistants' | 'kmsExtra' | 'transport'): ToggleTransportExtra => ({
    type: TOGGLE_TRANSPORT_EXTRA,
    payload: { type }
  }),
  setTransportExtraCount: (type: 'assistants' | 'kmsExtra', count: number): SetTransportExtraCount => ({
    type: SET_TRANSPORT_EXTRA_COUNT,
    payload: { type, count }
  }),
  setTransportExtraPrice: (type: 'transport', price: number): SetTransportExtraPrice => ({
    type: SET_TRANSPORT_EXTRA_PRICE,
    payload: { type, price }
  }),
  setFetchedRecalculation: (fetchedRecalculation: boolean): SetFetchedRecalculation => ({
    type: SET_FETCHED_RECALCULATION,
    payload: { fetchedRecalculation }
  }),
  toggleObjectExtra: (objectId: number | 'all', type: 'disassembly' | 'stairs' | 'packaging'): ToggleObjectExtra => ({
    type: TOGGLE_OBJECT_EXTRA,
    payload: { objectId, type }
  }),
  setObjectFloors: (objectId: number | 'all', floors: number): SetObjectFloors => ({
    type: SET_OBJECT_FLOORS,
    payload: { objectId, floors }
  }),
  getDepositOperationById:
    ({ depositId }: { depositId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetDepositOperationByIdRequest = {
        type: GET_DEPOSIT_OPERATION_BY_ID_REQUEST
      }

      dispatch(request)

      try {
        const depositsOperationsResponse = await API.TransactionHandler.DepositsOperations.getById({ id: depositId })
        if (!depositsOperationsResponse) throw new Error('Falló la búsqueda en DepositsOperation')

        const {
          operation: { objectsOperation, discountCode }
        } = depositsOperationsResponse

        const success: GetDepositOperationByIdSuccess = {
          type: GET_DEPOSIT_OPERATION_BY_ID_SUCCESS,
          payload: {
            objects: convertObjectsToValidationObjects(objectsOperation),
            discountCode
          }
        }

        dispatch(success)
      } catch (e) {
        handleErrorsWithAction(e, GET_DEPOSIT_OPERATION_BY_ID_FAILURE, dispatch)
      }
    },
  getBillingByTransaction:
    (transactionId: number): AppThunk =>
    async (dispatch) => {
      const request: GetBillingByTransactionRequest = {
        type: GET_BILLING_BY_TRANSACTION_REQUEST
      }

      dispatch(request)

      try {
        const { billings } = await API.TransactionHandler.Billings.getBillingByTransaction({
          transactionId,
          authorizationEnabled: false,
          order: 'id',
          orderType: SortDirection.DESC
        })

        const provisionalBilling = getProvisionalBilling(billings)

        const success: GetBillingByTransactionSuccess = {
          type: GET_BILLING_BY_TRANSACTION_SUCCESS,
          payload: {
            provisionalBilling
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_BILLING_BY_TRANSACTION_FAILURE, dispatch)
      }
    },

  populateView:
    ({ depositId, fromRecalculation = false }: { depositId: number; fromRecalculation?: boolean }): AppThunk =>
    async (dispatch) => {
      const request: PopulateViewRequest = {
        type: POPULATE_VIEW_REQUEST
      }

      dispatch(request)

      try {
        const depositsOperationsResponse = await API.TransactionHandler.DepositsOperations.getById({ id: depositId })
        if (!depositsOperationsResponse) throw new Error('Falló la búsqueda en DepositsOperation')
        const countryCode: CountryIdCode = depositsOperationsResponse.operation.countryId as CountryIdCode

        const [operationResponse, extraAssistantPrice, extraKmPrice] = await Promise.all([
          API.TransactionHandler.Operation.getById({ id: depositId, countryCode }),
          API.ObjectAdministration.Value.getValue({ flag: getFlagsByCountry(countryCode).extraAssistantPrice }),
          API.ObjectAdministration.Value.getValue({ flag: getFlagsByCountry(countryCode).extraKmPrice })
        ])
        if (!operationResponse || !extraAssistantPrice || !extraKmPrice)
          throw new Error('Falló la búsqueda de datos de la operación')

        const {
          operation: { objectsOperation, discountCode, tollCostInCents }
        } = depositsOperationsResponse
        const {
          id: operationId,
          user,
          createdAt,
          datetime,
          status,
          transportCost: quotedTransportCost,
          transactionId,
          fullAddressString
        } = operationResponse

        if (status === DepositStatus.VALIDATED || status === DepositStatus.CANCELLED)
          throw new Error('Este ingreso no puede volver a validarse')

        const { billings } = await API.TransactionHandler.Billings.getBillingByTransaction({
          transactionId,
          authorizationEnabled: false,
          order: 'id',
          orderType: SortDirection.DESC
        })

        const provisionalBilling = getProvisionalBilling(billings)

        const success: PopulateViewSuccess = {
          type: POPULATE_VIEW_SUCCESS,
          payload: {
            fromRecalculation,
            operationCountryCode: countryCode,
            objects: convertObjectsToValidationObjects(objectsOperation),
            quotedTransportCost,
            discountCode,
            provisionalBilling,
            baseInfo: {
              fullAddress: fullAddressString,
              id: operationId,
              transactionId,
              user,
              createdAt: moment(createdAt).isValid() ? moment(createdAt) : null,
              datetime: moment(datetime).isValid() ? moment(datetime) : null,
              status
            },
            tollCostInCents,
            extraKmPrice: parseInt(extraKmPrice),
            extraAssistantPrice: parseInt(extraAssistantPrice)
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, POPULATE_VIEW_FAILURE, dispatch)
      }
    },
  validateOperation:
    ({
      depositId,
      objects,
      kmsExtra,
      kmsExtraPrice,
      transportExtra,
      shipmentAssistantCount,
      shipmentAssistantPrice
    }: {
      depositId: number
      objects: ValidationViewObject[]
      kmsExtra: number
      kmsExtraPrice: number
      transportExtra: number
      shipmentAssistantCount: number
      shipmentAssistantPrice: number
    }): AppThunk =>
    async (dispatch) => {
      const request: ValidateOperationRequest = {
        type: VALIDATE_OPERATION_REQUEST
      }

      dispatch(request)

      const convertedObjects: ObjectToValidate[] = objects.map((obj) => ({
        objectId: obj.objectId,
        disassemblyCost: obj.extras.disassembly.enabled ? obj.extras.disassembly.disassemblyCost : 0,
        floors: obj.extras.stairs.enabled ? obj.extras.stairs.floors : 0,
        floorsCostPerFloorInCents: obj.extras.stairs.enabled ? obj.extras.stairs.floorsCostPerFloorInCents : 0,
        packagingCostInCents: obj.extras.packaging.enabled ? obj.extras.packaging.packagingCostInCents : 0
      }))

      try {
        await API.TransactionHandler.DepositsOperations.validate({
          operationId: depositId,
          extras: {
            kmsExtra,
            kmsExtraPrice,
            transportExtra,
            shipmentAssistantCount,
            shipmentAssistantPrice
          },
          objects: convertedObjects
        })

        const success: ValidateOperationSuccess = {
          type: VALIDATE_OPERATION_SUCCESS
        }

        dispatch(success)
        sendToastNotificationSuccess('El ingreso se validó correctamente')
        Emitter.emit(Events.Deposits.DEPOSIT_VALIDATED)
      } catch (error) {
        handleErrorsWithAction(error, VALIDATE_OPERATION_FAILURE, dispatch)
        sendToastNotificationInfo('Hubo un error al validar el ingreso')
      }
    },

  recalculateOperation:
    ({ transactionId, userId }: { transactionId: number; userId: number }): AppThunk =>
    async (dispatch) => {
      const request: RecalculateOperationRequest = {
        type: RECALCULATE_OPERATION_REQUEST
      }
      dispatch(request)

      const recalculate = true
      const createdFrom = 'update_deposit_operation'

      try {
        await API.TransactionHandler.Billings.Recalculate.billing({
          transactionId,
          userId,
          createdFrom,
          recalculate
        })

        const success: RecalculateOperationSuccess = {
          type: RECALCULATE_OPERATION_SUCCESS
        }

        dispatch(success)
        sendToastNotificationSuccess('Se recalculo correctamente la operacion')
        return true
      } catch (error) {
        handleErrorsWithAction(error, RECALCULATE_OPERATION_FAILURE, dispatch)
        sendToastNotificationInfo('Hubo un error al recalcular la operacion')
        return false
      }
    }
}

export default DepositValidationViewActionCreators
