import React, { useMemo } from 'react'
import objectsActions from '../actions/objects'
import { Input, Pagination } from 'antd'
import SelectedObjectToRemove from './objectsToRemove/SelectedObjectToRemove'
import { ObjectResponse, ObjectToRemove } from '../types/objects'
import ExtrasFields from './objectsToRemove/ExtrasFields'
import { ObjectsState } from '../reducers/objects'
import CarouselModal from '../../../components/CarouselModal/containers/CarouselModal'
import modalActionsConfirm from '../actions/confirmModal'
import ConfirmRemoveModal from './ConfirmModal'
import { Country } from '../../../components/CountrySelector/constants/constants'

const styles = {
  objectsToRemoveHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    padding: '16px 38px 16px 44px'
  },
  objectsToRemoveQuantityContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '212px',
    color: '#5D58F7'
  },
  notObjectFound: {
    paddingTop: 16,
    textAlign: 'center' as const,
    fontWeight: 'bold' as const,
    fontSize: '14px'
  },
  objectsToRemoveQuantity: {
    marginLeft: '16px',
    fontSize: '24px',
    fontWeight: 'bold' as const
  },
  containerList: {
    display: 'flex',
    flexDirection: 'column' as const,
    paddingTop: 20
  },
  container: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    ':nthChild(odd)': {
      backgroundColor: 'white'
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'space-between'
  },
  searchInput: {
    maxWidth: 300,
    marginBottom: 16
  },
  objectDescription: {
    flex: 1,
    padding: '0 12px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 75,
    width: 87
  },
  image: {
    maxWidth: 87,
    maxHeight: 75
  },
  label: {
    fontWeight: 500 as const,
    color: '#595959'
  },
  productName: {
    fontWeight: 'bold' as const,
    fontSize: '16px',
    marginBottom: 4
  },
  pagination: {
    marginTop: 16,
    marginLeft: 'auto'
  }
}

const PAGE_SIZE = 20

const filterObjects = (objectsToRemove: ObjectToRemove[], search: string) =>
  objectsToRemove.filter(({ objectDetails: { Name, Product, RealID }, deleted }) => {
    return (
      (Name.toLowerCase().includes(search.toLowerCase()) ||
        Product.Description.toLowerCase().includes(search.toLowerCase()) ||
        String(RealID) === search) &&
      !deleted
    )
  })

type ExtrasProps = {
  objectsActions: typeof objectsActions
  objectsState: ObjectsState
  toggleCarouselOpen: (images: string[]) => void
  modalConfirmActions: typeof modalActionsConfirm
  modalConfirm: any
  userCountry: Country
}

const Extras = (props: ExtrasProps) => {
  const { objectsActions, objectsState, modalConfirmActions, modalConfirm, toggleCarouselOpen, userCountry } = props
  const { objectsToRemove } = objectsState

  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [numberOfFloors, setNumberOfFloors] = React.useState(1)

  const objectsToRemoveValue = useMemo(
    () => Object.values(objectsToRemove).filter((obj) => !obj.deleted),
    [objectsToRemove]
  )
  const filteredObjectsBySearch = useMemo(
    () => filterObjects(objectsToRemoveValue, search),
    [objectsToRemoveValue, search, page]
  )
  const filteredObjectsByPage = useMemo(
    () => filteredObjectsBySearch.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
    [filteredObjectsBySearch]
  )

  const onSearchChange = (value: string) => {
    setPage(1)
    setSearch(value)
  }
  const onPageChange = (page: number) => setPage(page)
  const onDeleteObject = (object: ObjectResponse) => [objectsActions.undoObjectToRemove(object)]

  const extrasQuantity = useMemo(
    () => ({
      floors: objectsToRemoveValue.filter((objectToRemove) => objectToRemove.floors).length,
      removePackagingCost: objectsToRemoveValue.filter((objectToRemove) => objectToRemove.removePackagingCost).length,
      assemblyCost: objectsToRemoveValue.filter((objectToRemove) => objectToRemove.assemblyCost).length
    }),
    [objectsToRemoveValue]
  )

  const extrasValues = useMemo(
    () => ({
      floors: extrasQuantity.floors === objectsToRemoveValue.length,
      numberOfFloors,
      removePackagingCost: extrasQuantity.removePackagingCost === objectsToRemoveValue.length,
      assemblyCost: extrasQuantity.assemblyCost === objectsToRemoveValue.length
    }),
    [extrasQuantity]
  )

  const extrasIndeterminate = useMemo(
    () => ({
      floors: extrasQuantity.floors > 0,
      removePackagingCost: extrasQuantity.removePackagingCost > 0,
      assemblyCost: extrasQuantity.assemblyCost > 0
    }),
    [extrasQuantity]
  )

  const extrasEvents = {
    onFloorsCheckboxChange: objectsActions.setAllObjectsFloors,
    onFloorsQuantityChange: (quantity: number) => {
      setNumberOfFloors(quantity)
      objectsActions.setAllObjectsNumberOfFloors(quantity)
    },
    onAssemblyCostCheckboxChange: objectsActions.setAllObjectsAssemblyCost,
    onRemovePackagingCostChange: objectsActions.setAllObjectsRemovePackagingCost
  }

  const extraLabels = {
    floors: 'Escaleras',
    removePackagingCost: 'Embalaje',
    assemblyCost: 'Armado'
  }

  return (
    <div style={styles.containerList}>
      <div style={styles.searchContainer}>
        <Input.Search
          allowClear
          style={styles.searchInput}
          placeholder="Buscar objetos por ID o nombre"
          onSearch={onSearchChange}
          enterButton
        />
      </div>
      <div style={styles.objectsToRemoveHeader}>
        <div style={styles.objectsToRemoveQuantityContainer}>
          <span>Objetos a devolver</span>
          <span style={styles.objectsToRemoveQuantity}>{objectsToRemoveValue.length}</span>
        </div>
        <ExtrasFields
          events={extrasEvents}
          labels={extraLabels}
          indeterminates={extrasIndeterminate}
          values={extrasValues}
        />
      </div>
      {filteredObjectsByPage.map((objectToRemove, index) => (
        <SelectedObjectToRemove
          index={index}
          userCountry={userCountry}
          modalConfirmActions={modalConfirmActions}
          objectActions={objectsActions}
          objectToRemove={objectToRemove}
          key={objectToRemove.objectDetails.ID}
          onDelete={onDeleteObject}
          toggleCarrouselOpen={toggleCarouselOpen}
        />
      ))}
      <CarouselModal />
      <ConfirmRemoveModal
        modalConfirmActions={modalConfirmActions}
        modalConfirm={modalConfirm}
        onDelete={onDeleteObject}
      />
      {filteredObjectsByPage.length === 0 && (
        <span style={styles.notObjectFound}>No se encontraron objetos. Realice otra búsqueda</span>
      )}
      {filteredObjectsBySearch.length > PAGE_SIZE && (
        <Pagination
          style={styles.pagination}
          current={page}
          pageSize={PAGE_SIZE}
          total={objectsToRemoveValue.length}
          onChange={onPageChange}
        />
      )}
    </div>
  )
}

export default Extras
