import React, { ChangeEvent, useMemo } from 'react'
import { Input } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import styled from 'styled-components'
import nextStatusActionCreators from '../actions/nextStatus'
import { NextStatusState } from '../reducers/nextStatus'
import { DebtsStatus } from '../constants'
import OptionsModal from '../../../components/OptionsModal'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { RemovalTableState } from '../reducers/removals'

const StyledLabel = styled.div`
  margin-bottom: 5px;
  color: grey;
  font-size: 9px;
  margin-left: 24px;
  text-transform: uppercase;
`

const StyledInputNumber = styled(Input)`
  margin: 0 0 24px 24px;
  max-width: 250px;
  width: 100%;
`

type DebtStatusProps = {
  nextStatusActions: typeof nextStatusActionCreators
  nextStatusState: NextStatusState
  removalsState: RemovalTableState
}

const DebtStatusModal = (props: DebtStatusProps) => {
  const { nextStatusActions, nextStatusState, removalsState } = props
  const { openDebtStatusModal, loading, debtStatus, debtAmount, removalId } = nextStatusState
  const { removals } = removalsState

  const onDebtStatusChange = (e: RadioChangeEvent) => nextStatusActions.setDebtStatus(e.target.value)

  const onClose = () => nextStatusActions.toggleUpdateDebtStatusModal(false)
  const onOk = () => nextStatusActions.updateDebtStatus(removalId, debtStatus, debtAmount)

  const onDebtAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (/^([0-9]{0,5}|(.*))$/.test(value)) {
      nextStatusActions.setDebtAmount(parseFloat(parseFloat(value).toFixed(2)))
    }
  }

  const removal = useMemo(() => removals.find((removal) => removal.core_id === removalId), [removals, removalId])
  const removalCountry = useCountryInfo(removal?.country_code || CountryIdCode.ARGENTINA)

  const modalProps = {
    title: 'Seleccione una opción',
    visible: openDebtStatusModal,
    closable: !loading,
    maskClosable: !loading,
    onCancel: onClose,
    onOk: onOk,
    centered: true,
    okText: 'Continuar',
    cancelText: 'Cancelar',
    cancelButtonProps: {
      disabled: loading
    },
    style: { maxWidth: '500px' },
    bodyStyle: { padding: 0 },
    okButtonProps: {
      loading,
      disabled: loading || (debtStatus === DebtsStatus.WITH_DEBTS && debtAmount <= 0)
    }
  }

  const options = useMemo(
    () => [
      { label: 'El cliente no tiene deudas', value: DebtsStatus.NO_DEBTS },
      {
        label: 'El cliente presenta deudas',
        value: DebtsStatus.WITH_DEBTS,
        extraComponent: debtStatus === DebtsStatus.WITH_DEBTS && (
          <DebtAmountInput
            currency={removalCountry.currency}
            label="Monto de la deuda"
            defaultValue={debtAmount}
            onChange={onDebtAmountChange}
            value={debtAmount}
          />
        )
      }
    ],
    [debtStatus, debtAmount]
  )

  const optionsProps = {
    options,
    selectedOption: debtStatus,
    onOptionChange: onDebtStatusChange
  }

  return <OptionsModal modalProps={modalProps} optionsProps={optionsProps} />
}

type DebtAmountInputProps = {
  currency: string
  label: string
  defaultValue: number
  value: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const DebtAmountInput = ({ currency, label, defaultValue, onChange, value }: DebtAmountInputProps) => (
  <>
    <StyledLabel>{label}</StyledLabel>
    <StyledInputNumber
      type="number"
      autoFocus
      min="0"
      prefix={currency}
      placeholder="Ingrese el monto de la deuda"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    />
  </>
)

export default DebtStatusModal
