import { Reducer } from 'redux'
import { GetRemovalMetricsTypes, RemovalMetricsActions, RemovalMetricsState } from '../types/metrics'

const initialState: RemovalMetricsState = {
  quantityMetrics: [],
  fetchingMetrics: false
}

const RemovalMetricsReducer: Reducer<RemovalMetricsState, RemovalMetricsActions> = (
  state = initialState,
  action
): RemovalMetricsState => {
  switch (action.type) {
    case GetRemovalMetricsTypes.GET_REMOVAL_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetRemovalMetricsTypes.GET_REMOVAL_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        quantityMetrics: action.payload.quantityMetrics
      }
    case GetRemovalMetricsTypes.GET_REMOVAL_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default RemovalMetricsReducer
