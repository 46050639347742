import { apiPrivate } from '../../../api'
import { URL_BASE_TRIPS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'
import { APIVehicleCreateUpdateParams, VehicleCreateUpdateParams } from './common'

export function create(params: VehicleCreateUpdateParams): Promise<void> {
  const formattedParams: APIVehicleCreateUpdateParams = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .post(`${URL_BASE_TRIPS}/vehicles`, formattedParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
