import { CloseRemovalTypes, DebtsStatus } from '../constants'
import { Operation } from './removals'

export enum NextStatusTypes {
  TOGGLE_UPDATE_DEBT_STATUS_MODAL = 'REMOVALS_VALIDATION/TOGGLE_UPDATE_DEBT_STATUS_MODAL',
  TOGGLE_INFORM_PAYMENT_MODAL = 'REMOVALS_VALIDATION/TOGGLE_INFORM_PAYMENT_MODAL',
  TOGGLE_CLOSE_REMOVAL_MODAL = 'REMOVALS_VALIDATION/TOGGLE_CLOSE_REMOVAL_MODAL',
  SET_DEBT_STATUS = 'REMOVALS_VALIDATION/SET_DEBT_STATUS',
  SET_DEBT_AMOUNT = 'REMOVALS_VALIDATION/SET_DEBT_AMOUNT',
  SET_CLOSE_REMOVAL = 'REMOVALS_VALIDATION/SET_CLOSE_REMOVAL',
  UPDATE_DEBT_STATUS_REQUEST = 'REMOVALS_VALIDATION/UPDATE_DEBT_STATUS_REQUEST',
  UPDATE_DEBT_STATUS_SUCCESS = 'REMOVALS_VALIDATION/UPDATE_DEBT_STATUS_SUCCESS',
  UPDATE_DEBT_STATUS_FAILURE = 'REMOVALS_VALIDATION/UPDATE_DEBT_STATUS_FAILURE',
  INFORM_PAYMENT_REQUEST = 'REMOVALS_VALIDATION/INFORM_PAYMENT_REQUEST',
  INFORM_PAYMENT_SUCCESS = 'REMOVALS_VALIDATION/INFORM_PAYMENT_SUCCESS',
  INFORM_PAYMENT_FAILURE = 'REMOVALS_VALIDATION/INFORM_PAYMENT_FAILURE',
  CLOSE_REMOVAL_REQUEST = 'REMOVALS_VALIDATION/CLOSE_REMOVAL_REQUEST',
  CLOSE_REMOVAL_SUCCESS = 'REMOVALS_VALIDATION/CLOSE_REMOVAL_SUCCESS',
  CLOSE_REMOVAL_FAILURE = 'REMOVALS_VALIDATION/CLOSE_REMOVAL_FAILURE'
}

export type ToggleUpdateDebtStatus = {
  type: typeof NextStatusTypes.TOGGLE_UPDATE_DEBT_STATUS_MODAL
  payload: {
    removalId?: number
    open: boolean
  }
}

export type ToggleInformPayment = {
  type: typeof NextStatusTypes.TOGGLE_INFORM_PAYMENT_MODAL
  payload: {
    removalId?: number
    open: boolean
    debtAmount?: number
  }
}

export type ToggleCloseRemoval = {
  type: typeof NextStatusTypes.TOGGLE_CLOSE_REMOVAL_MODAL
  payload: {
    removalId?: number
    open: boolean
  }
}

export type SetDebtStatus = {
  type: typeof NextStatusTypes.SET_DEBT_STATUS
  payload: {
    status: DebtsStatus
  }
}

export type SetDebtAmount = {
  type: typeof NextStatusTypes.SET_DEBT_AMOUNT
  payload: {
    amount: number
  }
}

export type SetCloseRemoval = {
  type: typeof NextStatusTypes.SET_CLOSE_REMOVAL
  payload: {
    closeRemoval: CloseRemovalTypes
  }
}

export type UpdateDebtStatusRequest = {
  type: typeof NextStatusTypes.UPDATE_DEBT_STATUS_REQUEST
}

export type UpdateDebtStatusSuccess = {
  type: typeof NextStatusTypes.UPDATE_DEBT_STATUS_SUCCESS
  payload: {
    debtAmount: number
    operation: Operation
  }
}

export type UpdateDebtStatusFailure = {
  type: typeof NextStatusTypes.UPDATE_DEBT_STATUS_FAILURE
  payload: {
    error: string
  }
}

export type InformPaymentRequest = {
  type: typeof NextStatusTypes.INFORM_PAYMENT_REQUEST
}

export type InformPaymentSuccess = {
  type: typeof NextStatusTypes.INFORM_PAYMENT_SUCCESS
  payload: {
    debtAmount: number
    operation: Operation
  }
}

export type InformPaymentFailure = {
  type: typeof NextStatusTypes.INFORM_PAYMENT_FAILURE
  payload: {
    error: string
  }
}

export type CloseRemovalRequest = {
  type: typeof NextStatusTypes.CLOSE_REMOVAL_REQUEST
}

export type CloseRemovalSuccess = {
  type: typeof NextStatusTypes.CLOSE_REMOVAL_SUCCESS
  payload: {
    operation: Operation
  }
}

export type CloseRemovalFailure = {
  type: typeof NextStatusTypes.CLOSE_REMOVAL_FAILURE
  payload: {
    error: string
  }
}

export type NextStatusActions =
  | ToggleUpdateDebtStatus
  | ToggleInformPayment
  | ToggleCloseRemoval
  | SetDebtStatus
  | SetDebtAmount
  | SetCloseRemoval
  | UpdateDebtStatusRequest
  | UpdateDebtStatusSuccess
  | UpdateDebtStatusFailure
  | InformPaymentRequest
  | InformPaymentSuccess
  | InformPaymentFailure
  | CloseRemovalRequest
  | CloseRemovalSuccess
  | CloseRemovalFailure
