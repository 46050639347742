import { Reducer } from 'redux'
import { CancelActions, CancelOperationType, CancelState, GetCancelReasonsTypes, ModalDataTypes } from '../types/cancel'

const initialCancelState: CancelState = {
  operationId: 0,
  modalOpen: false,
  sendingData: false,
  cancelReasonId: 0,
  cancelReasonsList: [],
  error: {
    message: '',
    error: false
  },
  loadingReasons: false
}

const CancelReducer: Reducer<CancelState, CancelActions> = (state = initialCancelState, action): CancelState => {
  switch (action.type) {
    case ModalDataTypes.SET_MODAL_OPEN: {
      const { isOpen, operationId } = action.payload

      return isOpen && operationId
        ? {
            ...initialCancelState,
            modalOpen: isOpen,
            operationId: operationId
          }
        : initialCancelState
    }
    case ModalDataTypes.SET_CANCEL_REASON:
      return {
        ...state,
        cancelReasonId: action.payload.reasonId
      }
    case GetCancelReasonsTypes.GET_CANCEL_REASONS_REQUEST:
      return {
        ...state,
        error: initialCancelState.error,
        loadingReasons: true
      }
    case GetCancelReasonsTypes.GET_CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        cancelReasonsList: action.payload.cancelReasonsList,
        error: initialCancelState.error,
        loadingReasons: false
      }
    case GetCancelReasonsTypes.GET_CANCEL_REASONS_FAILURE:
      return {
        ...state,
        error: {
          message: action.payload.errorMessage,
          error: true
        },
        loadingReasons: false
      }
    case CancelOperationType.CANCEL_OPERATION_REQUEST:
      return {
        ...state,
        sendingData: true
      }
    case CancelOperationType.CANCEL_OPERATION_SUCCESS:
      return initialCancelState
    case CancelOperationType.CANCEL_OPERATION_FAILURE:
      return {
        ...state,
        sendingData: false
      }
    default:
      return state
  }
}

export default CancelReducer
