export enum ROLE_VALUES {
  CONFIRMED = 'confirmed',
  CREATED = 'created',
  CANCELLED = 'cancelled',
  AUTHORIZED = 'authorized',
  PENDING_PAYMENT = 'pending_payment',
  CLOSED_TOTALLY = 'closed_totally',
  CLOSED_PARTIALLY = 'closed_partially',
  DEPOSITED = 'deposited'
}

export const CARDS_FILTERS = [
  {
    text: 'Sí',
    value: 'true'
  },
  {
    text: 'No',
    value: 'false'
  }
]

export const TC_FILTERS = [
  {
    text: 'Sí',
    value: 'true',
    styleProps: {
      borderColor: '#52C41A',
      backgroundColor: '#F6FFED'
    }
  },
  {
    text: 'No',
    value: 'false',
    styleProps: {
      borderColor: '#FF4D4F',
      backgroundColor: '#FFFBE6'
    }
  }
]

export const CLIENT_ROLE_ID = 4

export enum PAYMENT_PROCESSORS {
  MERCADOPAGO = 'mercado_pago',
  SPREEDLY = 'spreedly'
}
