import types from './Deposits.types'
import { URL_BASE_TRANSACTIONS } from '../../endpoints'
import { apiPrivate } from '../../api'
import { handleErrorsWithAction } from '../../utils/HandleErrors'
import Emitter from '../../utils/eventEmitter'
import { Events } from '../../utils/eventEmitter/events'
import { API } from '../../projectApi'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../utils/notifications'

export default {
  cleanFilters() {
    return (dispatch) => {
      dispatch({ type: types.CLEAN_FILTERS })
      setTimeout(() => Emitter.emit(Events.Deposits.CLEAN_FILTERS), 50)
    }
  },

  getDeposits(params) {
    return (dispatch) => {
      dispatch({ type: types.FETCH_DEPOSITS })
      return apiPrivate
        .get(URL_BASE_TRANSACTIONS + '/operation/deposit', { params })
        .then((response) => {
          const deposits = response.data.description.operations

          const quantity = response.data.description.quantity

          dispatch({
            type: types.FETCH_DEPOSITS_SUCCESS,
            payload: { deposits, params, quantity }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_DEPOSITS_FAIL, dispatch)
        })
    }
  },

  getDepositsReport({ statuses, ...anotherParams }) {
    return async (dispatch) => {
      const toast = setToastLoading('Generando reporte de depósitos...')
      dispatch({ type: types.FETCH_DEPOSITS_REPORT })
      try {
        await API.TransactionHandler.Operation.list({
          ...anotherParams,
          statuses: typeof statuses === 'string' ? statuses.split(',') : statuses,
          format: 'csv'
        })
        dispatch({
          type: types.FETCH_DEPOSITS_REPORT_SUCCESS
        })
        setToastSuccessUpdate(toast, {
          render: 'Reporte de depósitos generado. Por favor, revise su correo electrónico.'
        })
      } catch (error) {
        setToastErrorUpdate(toast, {
          render: 'Hubo un error al generar el reporte de depósitos.'
        })
        handleErrorsWithAction(error, types.FETCH_DEPOSITS_REPORT_FAIL, dispatch)
      }
    }
  },

  setSorter(sorter) {
    return { type: types.SET_SORTER, payload: { sorter } }
  },

  setFilter(filter) {
    return { type: types.SET_FILTER, payload: { filter } }
  },

  setPagination(pagination) {
    return { type: types.SET_PAGINATION, payload: { pagination } }
  },

  setSearchFilters(searchFilters) {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  }
}
