import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

export type ChangeEmailModalState = {
  open: boolean
  userData: {
    id: number
    country: CountryIdCode
    name: string
    lastName: string
    currentEmail: string
  }
  newEmail: {
    value: string
    errorMessage: string
    successMessage: string
    loading: boolean
    lastSearchTimestamp: number
  }
  emailConfirmation: {
    value: string
    errorMessage: string
    successMessage: string
    loading: boolean
  }
  fetchingCards: boolean
  errorFetchCards: string
  hasMPCards: boolean
  error: string
  loading: boolean
}

export const OPEN_CHANGE_EMAIL_MODAL = 'USERS/CHANGE_EMAIL/OPEN_CHANGE_EMAIL_MODAL'
export const SET_NEW_EMAIL = 'USERS/CHANGE_EMAIL/SET_NEW_EMAIL'
export const SET_EMAIL_CONFIRMATION = 'USERS/CHANGE_EMAIL/SET_EMAIL_CHANGE'
export const SET_ERROR_NEW_EMAIL = 'USERS/CHANGE_EMAIL/SET_ERROR_NEW_EMAIL'
export const SET_ERROR_EMAIL_CONFIRMATION = 'USERS/CHANGE_EMAIL/SET_ERROR_EMAIL_CONFIRMATION'
export const SET_SUCCESS_NEW_EMAIL = 'USERS/CHANGE_EMAIL/SET_SUCCESS_NEW_EMAIL'
export const VERIFY_EMAIL_REQUEST = 'USERS/CHANGE_EMAIL/VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'USERS/CHANGE_EMAIL/VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'USERS/CHANGE_EMAIL/VERIFY_EMAIL_FAILURE'
export const EDIT_EMAIL_REQUEST = 'USERS/CHANGE_EMAIL/EDIT_EMAIL_REQUEST'
export const EDIT_EMAIL_SUCCESS = 'USERS/CHANGE_EMAIL/EDIT_EMAIL_SUCCESS'
export const EDIT_EMAIL_FAILURE = 'USERS/CHANGE_EMAIL/EDIT_EMAIL_FAILURE'
export const MESSAGE_AVAILABLE_EMAIL_CONFIRMATION = 'USERS/CHANGE_EMAIL/MESSAGE_AVAILABLE_EMAIL_CONFIRMATION'

export enum GetUserCards {
  GET_USER_CARDS_REQUEST = 'USERS/CHANGE_EMAIL/GET_USER_CARDS_REQUEST',
  GET_USER_CARDS_SUCCESS = 'USERS/CHANGE_EMAIL/GET_USER_CARDS_SUCCESS',
  GET_USER_CARDS_FAILURE = 'USERS/CHANGE_EMAIL/GET_USER_CARDS_FAILURE'
}

export interface OpenChangeEmailModal {
  type: typeof OPEN_CHANGE_EMAIL_MODAL
  payload: {
    open: boolean
    userData?: {
      id: number
      name: string
      country: CountryIdCode
      lastName: string
      currentEmail: string
    }
  }
}

export interface SetNewEmail {
  type: typeof SET_NEW_EMAIL
  payload: { newEmail: string }
}

export interface SetEmailConfirmation {
  type: typeof SET_EMAIL_CONFIRMATION
  payload: { emailConfirmation: string }
}

export interface SetErrorNewEmail {
  type: typeof SET_ERROR_NEW_EMAIL
  payload: { errorNewEmail: string }
}

export interface SetErrorEmailConfirmation {
  type: typeof SET_ERROR_EMAIL_CONFIRMATION
  payload: { errorEmailConfirmation: string }
}

export interface SetSuccessNewEmail {
  type: typeof SET_SUCCESS_NEW_EMAIL
  payload: { successNewEmail: string }
}

export interface VerifyEmailRequest {
  type: typeof VERIFY_EMAIL_REQUEST
  payload: {
    lastSearchTimestamp: number
  }
}
export interface VerifyEmailSuccess {
  type: typeof VERIFY_EMAIL_SUCCESS
  payload: {
    lastSearchTimestamp: number
  }
}

export interface VerifyEmailFailure {
  type: typeof VERIFY_EMAIL_FAILURE
  payload: {
    error: string
  }
}

export interface EditEmailRequest {
  type: typeof EDIT_EMAIL_REQUEST
}

export interface EditEmailSuccess {
  type: typeof EDIT_EMAIL_SUCCESS
}

export interface EditEmailFailure {
  type: typeof EDIT_EMAIL_FAILURE
  payload: {
    errorNewEmail: string
  }
}

type EditEmailAction = EditEmailRequest | EditEmailSuccess | EditEmailFailure
type VerifyEmailAction = VerifyEmailRequest | VerifyEmailSuccess | VerifyEmailFailure

export interface GetUserCardsRequest {
  type: GetUserCards.GET_USER_CARDS_REQUEST
}

export interface GetUserCardsSuccess {
  type: GetUserCards.GET_USER_CARDS_SUCCESS
  payload: { hasMPCards: boolean }
}

export interface GetUserCardsFailure {
  type: GetUserCards.GET_USER_CARDS_FAILURE
  payload: { errorMessage: string }
}

type GetUserCardsAction = GetUserCardsRequest | GetUserCardsSuccess | GetUserCardsFailure

export interface MessageAvailableEmailConfirmation {
  type: typeof MESSAGE_AVAILABLE_EMAIL_CONFIRMATION
  payload: {
    messageAvailableEmailConfirmation: string
  }
}

export type ChangeEmailActions =
  | OpenChangeEmailModal
  | SetNewEmail
  | SetEmailConfirmation
  | VerifyEmailSuccess
  | MessageAvailableEmailConfirmation
  | EditEmailAction
  | SetErrorNewEmail
  | SetErrorEmailConfirmation
  | SetSuccessNewEmail
  | VerifyEmailAction
  | GetUserCardsAction
