import { Discount, DiscountDatesSortKey } from '../../../projectApi/Timeslot/Discounts/SpecificDates/list'
import { DateRangeFilter, Pagination, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type DiscountDatesState = {
  discounts: Discount[]
  fetchingDiscounts: boolean
  pagination: Pagination
  sort: DiscountDatesTableSort
  dateRangeFilters: DiscountDatesDateRangeFilter[]
  searchFilters: DiscountDatesSearchFilter[]
}

export type DiscountDatesTableSearchKey = 'date'
export type DiscountDatesSearchFilter = SearchFilter<DiscountDatesTableSearchKey>

export type DiscountDatesTableSort = Sort<DiscountDatesSortKey>

export type DiscountDatesTableDateRangeKey = 'createdAt'

export type DiscountDatesDateRangeFilter = DateRangeFilter<DiscountDatesTableDateRangeKey>

export enum GetDiscountDatesTypes {
  GET_DISCOUNT_DATES_REQUEST = 'DISCOUNTS/DISCOUNT_DATES/GET_DISCOUNT_DATES_REQUEST',
  GET_DISCOUNT_DATES_SUCCESS = 'DISCOUNTS/DISCOUNT_DATES/GET_DISCOUNT_DATES_SUCCESS',
  GET_DISCOUNT_DATES_FAILURE = 'DISCOUNTS/DISCOUNT_DATES/GET_DISCOUNT_DATES_FAILURE'
}

export interface GetDiscountDatesRequest {
  type: typeof GetDiscountDatesTypes.GET_DISCOUNT_DATES_REQUEST
  payload: {
    pageSize: number
    newPage: number
    sort?: DiscountDatesTableSort
    dateRangeFilters?: DiscountDatesDateRangeFilter[]
    searchFilters?: DiscountDatesSearchFilter[]
  }
}

export interface GetDiscountDatesSuccess {
  type: typeof GetDiscountDatesTypes.GET_DISCOUNT_DATES_SUCCESS
  payload: {
    discounts: Discount[]
    total: number
  }
}

export interface GetDiscountDatesFailure {
  type: typeof GetDiscountDatesTypes.GET_DISCOUNT_DATES_FAILURE
}

type GetDiscountDatesActions = GetDiscountDatesRequest | GetDiscountDatesSuccess | GetDiscountDatesFailure

export enum DiscountDatesTableTypes {
  RESET_FILTERS = 'DISCOUNTS/DISCOUNT_DATES/RESET_FILTERS'
}

export interface ResetFilters {
  type: typeof DiscountDatesTableTypes.RESET_FILTERS
}

export type DiscountDatesActions = GetDiscountDatesActions | ResetFilters
