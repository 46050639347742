export type UsersMetrics = {
  quantityUsers: number
  quantityAcceptedTerms: number
  quantityNotAcceptedTerms: number
  activeUsers: number
  notActiveUsers: number
}

export type MetricsParams = {
  accepted?: string
  date_from?: string
  date_to?: string
  email?: string
  has_card?: boolean
  phone?: string
  role?: number
  has_cbu?: boolean
}

export type UsersMetricsState = {
  usersMetrics: UsersMetrics | null
  fetchingMetrics: boolean
}

export enum GetUsersMetricsTypes {
  GET_USERS_METRICS_REQUEST = 'USERS/METRICS/GET_METRICS_REQUEST',
  GET_USERS_METRICS_SUCCESS = 'USERS/METRICS/GET_METRICS_SUCCESS',
  GET_USERS_METRICS_FAILURE = 'USERS/METRICS/GET_METRICS_FAILURE'
}

export interface GetUsersMetricsRequest {
  type: typeof GetUsersMetricsTypes.GET_USERS_METRICS_REQUEST
}

export interface GetUsersMetricsSuccess {
  type: typeof GetUsersMetricsTypes.GET_USERS_METRICS_SUCCESS
  payload: {
    usersMetrics: UsersMetrics
  }
}

export interface GetUsersMetricsFailure {
  type: typeof GetUsersMetricsTypes.GET_USERS_METRICS_FAILURE
}

type GetUsersMetricsActions = GetUsersMetricsRequest | GetUsersMetricsSuccess | GetUsersMetricsFailure

export type UsersMetricsActions = GetUsersMetricsActions
