import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Component from '../components/Payment'
import paymentActionsCreators from '../actions/payment'
import { bindActionCreators } from 'redux'

const Container = () => {
  const dispatch = useDispatch()
  const { paymentBy } = useSelector((state) => state.NewProposal.payment)
  const { setPaymentBy } = bindActionCreators(paymentActionsCreators, dispatch)

  const props = {
    paymentBy,
    setPaymentBy
  }

  return <Component {...props} />
}

export default Container
