import { Proposal } from '../../../projectApi/TransactionHandler/Proposals/getById'
import {
  Proposal as ProposalProspect,
  Prospect
} from '../../../projectApi/TransactionHandler/Proposals/getFromProspect'
import { GetProspectRequest } from './prospect'
import { ProposalMetrics } from '../../../projectApi/TransactionHandler/Proposals/metrics'

export enum ProposalTypes {
  GET_PROPOSALS_REQUEST = 'NEW_PROPOSAL_V2/PROPOSALS/GET_PROPOSALS_REQUEST',
  GET_PROPOSALS_SUCCESS = 'NEW_PROPOSAL_V2/PROPOSALS/GET_PROPOSALS_SUCCESS',
  GET_PROPOSALS_FAILURE = 'NEW_PROPOSAL_V2/PROPOSALS/GET_PROPOSALS_FAILURE',
  GET_PROPOSAL_BY_ID_REQUEST = 'NEW_PROPOSAL_V2/PROPOSALS/GET_PROPOSAL_BY_ID_REQUEST',
  GET_PROPOSAL_BY_ID_SUCCESS = 'NEW_PROPOSAL_V2/PROPOSALS/GET_PROPOSAL_BY_ID_SUCCESS',
  GET_PROPOSAL_BY_ID_FAILURE = 'NEW_PROPOSAL_V2/PROPOSALS/GET_PROPOSAL_BY_ID_FAILURE',
  SAVE_PROPOSAL_REQUEST = 'NEW_PROPOSAL_V2/PROPOSALS/SAVE_PROPOSAL_REQUEST',
  SAVE_PROPOSAL_SUCCESS = 'NEW_PROPOSAL_V2/PROPOSALS/SAVE_PROPOSAL_SUCCESS',
  SAVE_PROPOSAL_FAILURE = 'NEW_PROPOSAL_V2/PROPOSALS/SAVE_PROPOSAL_FAILURE',
  SAVE_DEPOSIT_BY_PROPOSAL_REQUEST = 'NEW_PROPOSAL_V2/PROPOSALS/SAVE_DEPOSIT_BY_PROPOSAL_REQUEST',
  SAVE_DEPOSIT_BY_PROPOSAL_SUCCESS = 'NEW_PROPOSAL_V2/PROPOSALS/SAVE_DEPOSIT_BY_PROPOSAL_SUCCESS',
  SAVE_DEPOSIT_BY_PROPOSAL_FAILURE = 'NEW_PROPOSAL_V2/PROPOSALS/SAVE_DEPOSIT_BY_PROPOSAL_FAILURE',
  SET_PROPOSAL = 'NEW_PROPOSAL_V2/PROPOSALS/SET_PROPOSAL',
  SET_NEW_PROPOSAL_FROM_SCRATCH = 'NEW_PROPOSAL_V2/PROPOSALS/SET_NEW_PROPOSAL_FROM_SCRATCH',
  GET_METRICS_REQUEST = 'NEW_PROPOSAL_V2/PROPOSALS/GET_METRICS_REQUEST',
  GET_METRICS_SUCCESS = 'NEW_PROPOSAL_V2/PROPOSALS/GET_METRICS_SUCCESS',
  GET_METRICS_FAILURE = 'NEW_PROPOSAL_V2/PROPOSALS/GET_METRICS_FAILURE'
}

export interface GetProposalRequest {
  type: ProposalTypes.GET_PROPOSALS_REQUEST
}

export interface GetProposalSuccess {
  type: ProposalTypes.GET_PROPOSALS_SUCCESS
  payload: {
    prospect: Prospect
    proposals: ProposalProspect[]
  }
}

export interface GetProposalFailure {
  type: ProposalTypes.GET_PROPOSALS_FAILURE
  payload: {
    error: string
  }
}

type GetProposalActions = GetProposalRequest | GetProposalSuccess | GetProposalFailure

export interface GetProposalByIdRequest {
  type: ProposalTypes.GET_PROPOSAL_BY_ID_REQUEST
}

export interface GetProposalByIdSuccess {
  type: ProposalTypes.GET_PROPOSAL_BY_ID_SUCCESS
  payload: {
    proposal: Proposal
    addEmptyObject: boolean
  }
}

export interface GetProposalByIdFailure {
  type: ProposalTypes.GET_PROPOSAL_BY_ID_FAILURE
  payload: {
    error: string
  }
}

type GetProposalByIdActions = GetProposalByIdRequest | GetProposalByIdSuccess | GetProposalByIdFailure

export interface SaveProposalRequest {
  type: ProposalTypes.SAVE_PROPOSAL_REQUEST
}

export interface SaveProposalSuccess {
  type: ProposalTypes.SAVE_PROPOSAL_SUCCESS
}

export interface SaveProposalFailure {
  type: ProposalTypes.SAVE_PROPOSAL_FAILURE
  payload: {
    error: string
  }
}

type SaveProposalActions = SaveProposalRequest | SaveProposalSuccess | SaveProposalFailure

export interface SaveDepositByProposalRequest {
  type: ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_REQUEST
}

export interface SaveDepositByProposalSuccess {
  type: ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_SUCCESS
}

export interface SaveDepositByProposalFailure {
  type: ProposalTypes.SAVE_DEPOSIT_BY_PROPOSAL_FAILURE
  payload: {
    error: string
  }
}

type SaveDepositByProposalActions =
  | SaveDepositByProposalRequest
  | SaveDepositByProposalSuccess
  | SaveDepositByProposalFailure

export interface SetProposal {
  type: ProposalTypes.SET_PROPOSAL
  payload: {
    proposal: Proposal
  }
}

export interface SetNewProposalFromScratch {
  type: ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH
}

export interface GetMetricsRequest {
  type: ProposalTypes.GET_METRICS_REQUEST
}

export interface GetMetricsSuccess {
  type: ProposalTypes.GET_METRICS_SUCCESS
  payload: {
    metrics: ProposalMetrics
  }
}

export interface GetMetricsFailure {
  type: ProposalTypes.GET_METRICS_FAILURE
  payload: {
    error: string
  }
}

type GetMetricsActions = GetMetricsRequest | GetMetricsSuccess | GetMetricsFailure

export type ProposalActions =
  | GetProposalActions
  | GetProposalByIdActions
  | SaveProposalActions
  | SaveDepositByProposalActions
  | SetProposal
  | GetProspectRequest
  | SetNewProposalFromScratch
  | GetMetricsActions
