import types from '../types/common'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'

const initialState = {
  paymentBy: PAYMENT_BY_VALUES.CREDIT_CARD_MP,
  notifyUser: false,
  route: '',
  guid: '',
  guidDate: null,
  storageCost: 110,
  openModalResume: false
}

const root = (state = initialState, action) => {
  switch (action.type) {
    case types.FINISH:
      return initialState
    case types.SET_STORAGE_COST:
      return { ...state, storageCost: action.payload.storageCost }
    case types.SET_NOTIFY_USER:
      return { ...state, notifyUser: action.payload.notifyUser }
    case types.SET_PAYMENT_BY:
      return { ...state, paymentBy: action.payload.paymentBy }
    case types.UPDATE_ROUTE:
      return { ...state, route: action.payload.route }
    case types.CREATE_GUID:
      return {
        ...state,
        guid: action.payload.guid,
        guidDate: action.payload.guidDate
      }
    case types.OPEN_MODAL_RESUME:
      return { ...state, openModalResume: true }
    case types.CLOSE_MODAL_RESUME:
      return { ...state, openModalResume: false }
    default:
      return state
  }
}

export default root
