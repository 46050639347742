import { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal: React.FC = ({ children }) => {
  const mount = document.getElementById('portal-root')
  const el = document.createElement('div')

  useLayoutEffect(() => {
    if (mount) mount.appendChild(el)
    return () => {
      if (mount) mount.removeChild(el)
    }
  }, [el, mount])

  return createPortal(children, el)
}

export default Portal
