import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import { API } from '../../../../projectApi'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'
import { getLeafCategories } from '../../../../projectApi/CategoryCreation/getCategories'
import { AppThunk } from '../../../../store'
import { handleErrorsWithAction } from '../../../../utils/HandleErrors'
import Emitter from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../../utils/notifications'
import {
  AMProductsTypes,
  CategoryOption,
  CleanState,
  CreateProductRequest,
  CreateProductSuccess,
  CreateProductTypes,
  DeleteProductRequest,
  DeleteProductSuccess,
  DeleteProductTypes,
  GetCategoriesProductsTypes,
  GetCategoriesRequest,
  GetCategoriesSuccess,
  ProductFormFields,
  SetCategory,
  SetDeleteModalVisible,
  SetEditMode,
  SetFormField,
  UpdateProductRequest,
  UpdateProductSuccess,
  UpdateProductTypes
} from '../types/ABMProducts'

const ABMCorporateProductsActionsCreator = {
  cleanState: (): CleanState => ({ type: AMProductsTypes.CLEAN_STATE }),
  setFormField: ({ field, value }: { field: keyof ProductFormFields; value: string }): SetFormField => ({
    type: AMProductsTypes.SET_FORM_FIELD,
    payload: { field, value }
  }),
  setEditMode: ({
    editMode,
    selectedProduct
  }: {
    editMode: boolean
    selectedProduct: FormattedProduct
  }): SetEditMode => ({
    type: AMProductsTypes.SET_EDIT_MODE,
    payload: { editMode, selectedProduct }
  }),
  setCategory: (categoryOption?: CategoryOption): SetCategory => ({
    type: AMProductsTypes.SET_CATEGORY,
    payload: { selectedCategory: categoryOption }
  }),
  setDeleteModalVisibility: ({
    deleteModalVisible,
    currentProduct
  }: {
    deleteModalVisible: boolean
    currentProduct?: FormattedProduct
  }): SetDeleteModalVisible => ({
    type: AMProductsTypes.SET_DELETE_MODAL_VISIBILITY,
    payload: { deleteModalVisible, currentProduct }
  }),
  getCategories: (): AppThunk => async (dispatch) => {
    const request: GetCategoriesRequest = {
      type: GetCategoriesProductsTypes.GET_CATEGORIES_REQUEST
    }

    dispatch(request)

    try {
      const { categories } = await API.CategoryCreation.getCategories()

      const leafCategories = getLeafCategories(categories)

      const success: GetCategoriesSuccess = {
        type: GetCategoriesProductsTypes.GET_CATEGORIES_SUCCESS,
        payload: { categories: leafCategories }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GetCategoriesProductsTypes.GET_CATEGORIES_FAILURE, dispatch)
    }
  },
  createProduct:
    ({
      newProduct
    }: {
      newProduct: ProductFormFields & { userCountryCode: CountryIdCode; categoryId: number }
    }): AppThunk =>
    async (dispatch) => {
      const request: CreateProductRequest = {
        type: CreateProductTypes.CREATE_PRODUCT_REQUEST
      }
      dispatch(request)
      const toast = setToastLoading('Creando producto...')
      try {
        await API.CategoryCreation.Product.Corporate.create({
          categoryId: newProduct.categoryId,
          description: newProduct.name,
          heightInCm: Number(newProduct.dimmensionsHeightInCm),
          lengthInCm: Number(newProduct.dimmensionsLengthInCm),
          weightInGr: Number(newProduct.dimmensionsWeightInKg) * 1000,
          widthInCm: Number(newProduct.dimmensionsWidthInCm),
          maxVolumeDeviation: Number(newProduct.deviationMaxPercentage),
          minVolumeDeviation: Number(newProduct.deviationMinPercentage),
          photoMin: Number(newProduct.multimediaMinPhotos),
          productsByCountry: [
            {
              countryId: newProduct.userCountryCode,
              description: newProduct.name,
              photoUrl: newProduct.multimediaPhotoUrl,
              photoKey: newProduct.multimediaPhotoKey
            }
          ]
        })
        const success: CreateProductSuccess = {
          type: CreateProductTypes.CREATE_PRODUCT_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Producto creado con éxito' })
        Emitter.emit(Events.CorporateProducts.CREATE_PRODUCT)
      } catch (error) {
        setToastErrorUpdate(toast, {
          render:
            handleErrorsWithAction(error, CreateProductTypes.CREATE_PRODUCT_FAILURE, dispatch) ||
            'Error al crear producto'
        })
      }
    },
  updateProduct:
    ({
      selectedProductId,
      updatedProduct
    }: {
      selectedProductId: number
      updatedProduct: ProductFormFields & { userCountryCode: CountryIdCode; categoryId: number }
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateProductRequest = {
        type: UpdateProductTypes.UPDATE_PRODUCT_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Actualizando producto...')
      try {
        await API.CategoryCreation.Product.Corporate.update({
          productId: selectedProductId,
          categoryId: updatedProduct.categoryId,
          description: updatedProduct.name,
          heightInCm: Number(updatedProduct.dimmensionsHeightInCm),
          lengthInCm: Number(updatedProduct.dimmensionsLengthInCm),
          weightInGr: Number(updatedProduct.dimmensionsWeightInKg) * 1000,
          widthInCm: Number(updatedProduct.dimmensionsWidthInCm),
          maxVolumeDeviation: Number(updatedProduct.deviationMaxPercentage),
          minVolumeDeviation: Number(updatedProduct.deviationMinPercentage),
          photoMin: Number(updatedProduct.multimediaMinPhotos),
          productsByCountry: [
            {
              countryId: updatedProduct.userCountryCode,
              description: updatedProduct.name,
              photoUrl: updatedProduct.multimediaPhotoUrl,
              photoKey: updatedProduct.multimediaPhotoKey
            }
          ]
        })

        const success: UpdateProductSuccess = {
          type: UpdateProductTypes.UPDATE_PRODUCT_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Producto actualizado con éxito' })
        Emitter.emit(Events.CorporateProducts.UPDATE_PRODUCT)
      } catch (error) {
        setToastErrorUpdate(toast, {
          render:
            handleErrorsWithAction(error, UpdateProductTypes.UPDATE_PRODUCT_FAILURE, dispatch) ||
            'Error al actualizar producto'
        })
      }
    },
  deleteProduct:
    (productId: number): AppThunk =>
    async (dispatch) => {
      const request: DeleteProductRequest = {
        type: DeleteProductTypes.DELETE_PRODUCT_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Eliminando producto...')
      try {
        await API.CategoryCreation.Product.Corporate.delete({ productId })

        const success: DeleteProductSuccess = {
          type: DeleteProductTypes.DELETE_PRODUCT_SUCCESS
        }
        dispatch(success)
        dispatch(ABMCorporateProductsActionsCreator.setDeleteModalVisibility({ deleteModalVisible: false }))
        setToastSuccessUpdate(toast, { render: 'Producto eliminado con éxito' })
        Emitter.emit(Events.CorporateProducts.DELETE_PRODUCT)
      } catch (error) {
        setToastErrorUpdate(toast, {
          render:
            handleErrorsWithAction(error, DeleteProductTypes.DELETE_PRODUCT_FAILURE, dispatch) ||
            'Error al eliminar producto'
        })
      }
    }
}

export default ABMCorporateProductsActionsCreator
