import React from 'react'
import styles from './depositsValidationMetrics.module.scss'
import StatusCard, { StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { StatusCardContainer } from '../../../components/StatusCard/subComponents/StatusCardContainer'
import { DepositsValidationMetrics } from '../types/depositsValidationMetrics'
import { formatNumber } from '../../../utils/formatNumber'

export type ObjectMetricsProps = {
  metrics: DepositsValidationMetrics[]
  fetchingMetrics: boolean
}

const MetricsDepositsValidation: React.FC<ObjectMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const lengthSkeletons = Object.keys(mapOfDepositsValidationMetricsTypes).length
  return (
    <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={lengthSkeletons}>
      {metrics.map(({ id, metric, quantity }) => (
        <StatusCard
          key={id}
          type={mapOfDepositsValidationMetricsTypes[id]}
          customClassname={styles.card}
          titleCard={metric}
          total={formatNumber(quantity, 0, true)}
        />
      ))}
    </StatusCardContainer>
  )
}

export default MetricsDepositsValidation

const mapOfDepositsValidationMetricsTypes: { [key: string]: StatusCardTypes } = {
  depositedQuantity: 'info',
  validatedQuantity: 'success',
  total: 'infoSolid'
}

export const mapOfDepositsValidationMetrics: { [key: string]: string } = {
  depositedQuantity: 'Ingresados',
  validatedQuantity: 'Guardados validados',
  total: 'Total'
}
