export const SpanishSpain = {
  currency: {
    symbol: '€',
    decimal: ',',
    thousand: '.',
    showCents: false
  },
  generic: {
    continue: 'Continuar',
    next: 'Siguiente',
    add: 'Agregar',
    more: 'Más',
    filter: 'Filtrar',
    skip: 'Omitir',
    forms: {
      required: 'Campo requerido'
    }
  },
  ValidationViewScreen: {
    infoBanner: {
      title: 'Detalle de información',
      fields: {
        depositId: 'ID Ingreso',
        clientId: 'ID Cliente',
        clientName: 'Nombre',
        clientLastName: 'Apellido',
        operationDate: 'F. operación',
        serviceDate: 'F. servicio',
        remittance: 'Albarán',
        openRemittance: 'Ver albarán',
        fullAddress: 'Dirección completa',
        copyFullAddress: 'Copiar dirección completa'
      }
    },

    transportExtras: {
      title: 'Extras transporte',
      extraKms: 'Adicional',
      extraTransport: 'Extras de transporte/embalaje',
      warningTooltip: 'El descuento no puede ser mayor al valor de transporte cotizado',
      assistants: 'Asistentes de carga/descarga'
    },
    extraServices: {
      title: 'Servicios extra',
      search: 'Buscar por ID o nombre',
      emptyState: 'No se encontraron objetos cargados',
      table: {
        columns: {
          objectId: 'ID Objeto',
          productName: 'Nombre producto',
          stairs: 'Escaleras',
          packaging: 'Embalaje',
          disassembly: 'Desmontaje'
        },
        rows: {
          deleted: 'Eliminado'
        },
        error: 'Hubo un error buscando la información'
      }
    },
    modal: {
      warning: 'Recordá que los datos ingresados no van a poder ser modificados.',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Entendido'
      }
    },
    overviewCard: {
      header: 'Valor final',
      fields: {
        objects: {
          title: 'Objetos'
        },
        proportionalBilling: {
          title: 'Mensualidad proporcional',
          period: (startDate: string, endDate: string) => `Del ${startDate} al ${endDate}`,
          emptyState: 'El abono aún no ha sido calculado.\n Intente de nuevo más tarde.',
          discountDisclaimer: 'Esta cifra no toma en cuenta descuentos.'
        },
        additionalCosts: {
          title: 'Servicios extra',
          subFields: {
            quotedTransport: 'Transporte presupuestado',
            extraKms: 'Adicional',
            extraTransport: 'Extras de transporte/embalaje',
            assistants: 'Mozos adicionales',
            packaging: 'Embalaje',
            stairs: 'Escaleras',
            disassembly: 'Desmontaje'
          }
        },
        total: {
          title: 'Total ingreso'
        },
        discounts: {
          title: 'Código de descuento'
        },
        confirm: {
          tooltip: 'Para confirmar un ingreso, se debe recalcular el abono y estar procesado.'
        }
      }
    }
  },
  CatalogScreen: {
    title: 'Explorá nuestro',
    titleSpan: 'catálogo de productos',
    subtitle: 'Buscá por nombre, código universal, o código de catálogo de Mercado Libre.'
  }
}
