import { apiPrivate } from '../../../api'
import { URL_BASE_OBJECTS } from '../../../endpoints'

export function deleteAttribute(id: number): Promise<boolean> {
  return apiPrivate
    .delete(`${URL_BASE_OBJECTS}/attribute/${id}`)
    .then(() => true)
    .catch((err) => {
      throw err
    })
}
