import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'

export interface ResponseApi {
  operation_id: number
  guid: string
  operation_number: number
  original_address: string
  address_id: number
  transport_datetime: null
  transport_cost_in_cents: number
  discount_code: string
  user_id: number
  card_type: string
  card_last_digits: string
  card_id: number
  status: string
  core_id: number
  core_type: string
  origin: string
  additional_cost_in_cents: number
  additional_cost_comment: string
  country_id: string
}

export type AdminParams = {
  objects: RemovalObject[]
  date: string
  minCost: number
  timeSlotId: number
  timeIntervalId: number
  transportCost: number
  tollCostInCents?: number
  discountCode?: string
  applyDiscount: boolean
  paymentType?: string
  cardId?: number
  spreedlyPmId?: number
  noTransportChargeReasonId?: number
  transportModeTypeId?: number
  addressId?: number
  notifyUser: boolean
  removalReason: RemovalReason
  isDraft: boolean
  totalDiscountPercentage: number
  userId: number
}

type RemovalObject = {
  objectId: string
  id: number
  floorsByStairsCost: number
  packagingCost: number
  assemblyCost: number
}

type RemovalReason = {
  id: number
  description: string
}

export type Response = CamelCasedPropertiesDeep<ResponseApi>

export function admin(params: AdminParams): Promise<Response> {
  const parsedBody = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/operations/removal/admin`, parsedBody)
    .then((response: AxiosResponse<ResponseApi>) => {
      return camelcaseKeys(response.data)
    })
    .catch((error) => {
      throw error
    })
}
