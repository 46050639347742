import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export interface GetByIdParams {
  proposalId: string | number
}

export function getPDF({ proposalId }: GetByIdParams): Promise<Blob> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/proposal/pdf/${proposalId}`, {
      responseType: 'blob'
    })
    .then((response: AxiosResponse<Blob>) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
