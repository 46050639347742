import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { Moment } from 'moment'
import { convertToMoment } from '../../utils'
import snakecaseKeys from 'snakecase-keys'

export interface APIResponse {
  id: number
  created_at: string
  updated_at: string
  amount_in_cents: number
  user_id: number
  preference_status: PreferenceStatus
  country_id: string
}

export interface PreferenceStatus {
  id: number
  description: string
}

export interface Response {
  id: number
  createdAt: Moment | null
  updatedAt: Moment | null
  amountInCents: number
  userId: number
  preferenceStatus: PreferenceStatus
  countryId: string
}

interface Concepts {
  billingColppyId: number
  entityId: number
  status: string
  entityType: string
  total: string
}

export interface payDebtsParams {
  paymentMethodToken: string
  userId: number
  amountInCents: number
  country: string
  concepts: Concepts[]
}

export function payDebts(payParams: payDebtsParams): Promise<Response> {
  const params = snakecaseKeys({ ...payParams }, { deep: true })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/debts/debt-payment`, { ...params })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelCase = camelcaseKeys(response.data, { deep: true })
      return {
        ...camelCase,
        createdAt: convertToMoment(camelCase.createdAt),
        updatedAt: convertToMoment(camelCase.updatedAt)
      }
    })
    .catch((err) => {
      throw err
    })
}
