import React from 'react'
import styles from './objectMetrics.module.scss'
import { Metrics } from '../types/objectsTable'
import StatusCard, { StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { StatusCardContainer } from '../../../components/StatusCard/subComponents/StatusCardContainer'
import { formatNumber } from '../../../utils/formatNumber'

export type ObjectMetricsProps = {
  metrics: Metrics
  fetchingMetrics: boolean
}

const ObjectsMetrics: React.FC<ObjectMetricsProps> = ({ metrics, fetchingMetrics }) => {
  return (
    <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={6}>
      <StatusCard
        type={'infoSolid'}
        customClassname={styles.totalObjects}
        titleCard={'Objetos'}
        total={formatNumber(metrics.totalObjects, 0, true)}
        percentage={formatNumber(metrics.totalM3, 2, true)}
        unit="m³"
        unitStyles={styles.unitStyles}
      />
      {metrics.situations
        ? Object.entries(metrics.situations).map(([id, metric]) => (
            <StatusCard
              key={id}
              type={mapOfSituationMetricTypes[id]}
              customClassname={styles.warningCard}
              titleCard={mapOfSituationsMetrics[id]}
              total={formatNumber(metric, 0, true)}
            />
          ))
        : null}
    </StatusCardContainer>
  )
}

export default ObjectsMetrics

const mapOfSituationMetricTypes: { [key: string]: StatusCardTypes } = {
  saved: 'info',
  sale: 'warning',
  donated: 'success',
  soldOut: 'success',
  savedNotValidated: 'danger'
}

export const mapOfSituationsMetrics: { [key: string]: string } = {
  saved: 'Guardados validados',
  donated: 'Donados',
  sale: 'En venta',
  soldOut: 'Vendidos',
  savedNotValidated: 'No validados'
}
