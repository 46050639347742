import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import flagsActionsCreators from '../actions/flags'
import itemsActionsCreators from '../actions/items'
import transportActionsCreators from '../actions/transport'
import Component from '../components/OperationType'
import { useEvents } from '../../../utils/eventEmitter'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Events } from '../../../utils/eventEmitter/events'
import { PROPOSAL_MODES } from '../../../common/operations/constants'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'

type OperationTypeProps = {
  mode: typeof PROPOSAL_MODES[keyof typeof PROPOSAL_MODES]
}

const Container = (ownProps: OperationTypeProps) => {
  const { mode } = ownProps
  const { flags, items, prospect, proposals, transport } = useSelector((state) => state.NewProposal)
  const selectedProspect = prospect.selectedProspect
  const { selectedProposal } = proposals
  const { loadingFlags } = flags

  const country = useCountryInfo()

  const dispatch = useDispatch()

  const flagsActions = bindActionCreators(flagsActionsCreators, dispatch)
  const itemsActions = bindActionCreators(itemsActionsCreators, dispatch)
  const transportActions = bindActionCreators(transportActionsCreators, dispatch)
  const { recalculateItemsFromStore } = itemsActions

  useEffect(() => {
    if (selectedProspect?.userId) itemsActions.getActualM3Storage(selectedProspect.userId)
  }, [selectedProspect?.userId])

  useEffect(() => {
    const countryCode = selectedProspect?.countryCode as CountryIdCode
    if (countryCode) {
      flagsActions.getConfigurationFlags(countryCode)
    }
  }, [selectedProspect?.countryCode])

  const getRecalculateItems = () => {
    if (selectedProspect?.userId) recalculateItemsFromStore()
  }

  useEvents(
    [
      Events.Proposal.SET_ITEM,
      Events.Proposal.CLEAN_ITEM,
      Events.Proposal.REMOVE_ITEM,
      Events.Proposal.MODIFY_ITEM_QUANTITY,
      Events.Proposal.GET_PROPOSAL
    ],
    getRecalculateItems
  )

  useEffect(() => {
    if (selectedProposal?.type && transport.serviceTypes.length && !loadingFlags) {
      const newServiceType = transport.serviceTypes.find((serviceType) => serviceType.id === selectedProposal.type)
      transportActions.setServiceType({
        newServiceType: newServiceType!,
        actualServiceType: selectedProposal.type,
        countryCode: country.code
      })
    }
  }, [selectedProposal?.id, transport.serviceTypes, loadingFlags])

  const { minBillableM3, minBillableM3Price } = flags

  const props = {
    minBillableM3,
    minBillableM3Price,
    globalDisabled: mode === PROPOSAL_MODES.VIEW_PROPOSAL,
    selectedProspect,
    items,
    flagsActions,
    itemsActions,
    transport,
    transportActions
  }

  return <Component {...props} />
}

export default Container
