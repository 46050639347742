import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import validateEmail from '../../../utils/validateEmail'
import {
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  EditEmailRequest,
  EditEmailSuccess,
  OPEN_CHANGE_EMAIL_MODAL,
  SET_EMAIL_CONFIRMATION,
  SET_ERROR_EMAIL_CONFIRMATION,
  SET_ERROR_NEW_EMAIL,
  SET_NEW_EMAIL,
  SET_SUCCESS_NEW_EMAIL,
  SetEmailConfirmation,
  SetErrorEmailConfirmation,
  SetErrorNewEmail,
  SetSuccessNewEmail,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  MESSAGE_AVAILABLE_EMAIL_CONFIRMATION,
  MessageAvailableEmailConfirmation,
  VerifyEmailFailure,
  VerifyEmailRequest,
  VerifyEmailSuccess,
  GetUserCardsRequest,
  GetUserCards,
  GetUserCardsSuccess,
  GetUserCardsFailure
} from '../types/changeEmail'
import userActions from '../Users.actions'

const ChangeEmailModalActionCreators = {
  setOpen:
    (
      open: boolean,
      userData?: { id: number; name: string; lastName: string; country: CountryIdCode; currentEmail: string }
    ): AppThunk =>
    (dispatch) => {
      userData?.country === CountryIdCode.ARGENTINA &&
        userData?.id &&
        dispatch(ChangeEmailModalActionCreators.getUserCards({ userId: userData.id.toString() }))

      dispatch({
        type: OPEN_CHANGE_EMAIL_MODAL,
        payload: { open, userData }
      })
    },

  setNewEmail:
    (newEmail: string): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: SET_NEW_EMAIL,
        payload: { newEmail }
      })

      if (validateEmail(newEmail)) {
        dispatch(ChangeEmailModalActionCreators.verifyIfEmailExists({ email: newEmail }))
      } else if (newEmail !== '') {
        const failureValidate: VerifyEmailFailure = {
          type: VERIFY_EMAIL_FAILURE,
          payload: {
            error: 'El email es invalido'
          }
        }
        dispatch(failureValidate)
      } else {
        const failureValidate: VerifyEmailFailure = {
          type: VERIFY_EMAIL_FAILURE,
          payload: {
            error: ''
          }
        }
        dispatch(failureValidate)
      }
    },

  setEmailConfirmation: (emailConfirmation: string): SetEmailConfirmation => ({
    type: SET_EMAIL_CONFIRMATION,
    payload: { emailConfirmation }
  }),

  setMessageAvailableEmailConfirmation: (
    messageAvailableEmailConfirmation: string
  ): MessageAvailableEmailConfirmation => ({
    type: MESSAGE_AVAILABLE_EMAIL_CONFIRMATION,
    payload: { messageAvailableEmailConfirmation }
  }),

  setErrorNewEmail: (errorNewEmail: string): SetErrorNewEmail => ({
    type: SET_ERROR_NEW_EMAIL,
    payload: { errorNewEmail }
  }),

  setErrorEmailConfirmation: (errorEmailConfirmation: string): SetErrorEmailConfirmation => ({
    type: SET_ERROR_EMAIL_CONFIRMATION,
    payload: { errorEmailConfirmation }
  }),

  setSuccessNewEmail: (successNewEmail: string): SetSuccessNewEmail => ({
    type: SET_SUCCESS_NEW_EMAIL,
    payload: { successNewEmail }
  }),

  verifyIfEmailExists:
    ({ email }: { email: string }): AppThunk =>
    async (dispatch) => {
      const lastSearchTimestamp = new Date().getMilliseconds()

      const request: VerifyEmailRequest = {
        type: VERIFY_EMAIL_REQUEST,
        payload: {
          lastSearchTimestamp
        }
      }

      dispatch(request)

      try {
        const { users } = await API.AccountManager.Users.search({ email })
        const success: VerifyEmailSuccess = {
          type: VERIFY_EMAIL_SUCCESS,
          payload: {
            lastSearchTimestamp
          }
        }
        const failure: VerifyEmailFailure = {
          type: VERIFY_EMAIL_FAILURE,
          payload: {
            error: 'El email ya está en uso'
          }
        }

        if (users.length === 0) {
          dispatch(success)
        } else {
          dispatch(failure)
        }
      } catch (error) {
        handleErrorsWithAction(error, VERIFY_EMAIL_FAILURE, dispatch)
      }
    },
  updateEmail:
    ({ userId, newEmail, oldEmail }: { userId: number; newEmail: string; oldEmail: string }): AppThunk =>
    async (dispatch, getState) => {
      const request: EditEmailRequest = {
        type: EDIT_EMAIL_REQUEST
      }

      dispatch(request)

      try {
        await API.AccountManager.Change.email({ userId, newEmail, oldEmail })

        const success: EditEmailSuccess = {
          type: EDIT_EMAIL_SUCCESS
        }

        dispatch(success)
        const { params } = getState().Users.table
        dispatch(userActions.getUsers(params))
      } catch (error) {
        handleErrorsWithAction(error, EDIT_EMAIL_FAILURE, dispatch)
      }
    },
  getUserCards:
    ({ userId }: { userId: string }): AppThunk =>
    async (dispatch) => {
      const request: GetUserCardsRequest = {
        type: GetUserCards.GET_USER_CARDS_REQUEST
      }

      dispatch(request)

      try {
        const { payments } = await API.UserProfile.paymentMethods.list({ userId })

        const hasMPCards = payments.some((card) => !card.isFromSpreedly)

        const success: GetUserCardsSuccess = {
          type: GetUserCards.GET_USER_CARDS_SUCCESS,
          payload: { hasMPCards }
        }
        dispatch(success)
      } catch (error) {
        const failure: GetUserCardsFailure = {
          type: GetUserCards.GET_USER_CARDS_FAILURE,
          payload: {
            errorMessage:
              'Error al intentar obtener los datos de tarjeta del usuario. Intente más tarde o comuniquese con soporte.'
          }
        }

        dispatch(failure)
      }
    }
}

export default ChangeEmailModalActionCreators
