import { Reducer } from 'redux'
import { CLEAN_STATES, GET_TRANSACTION_DATA_SUCCESS } from '../../types/EditDepositViewTypes/EditDepositView'
import {
  CostActions,
  CostState,
  FetchDiscountEnum,
  SetterDataDiscountEnum
} from '../../types/EditDepositViewTypes/cost'

const costInitialState: CostState = {
  fetchDiscount: {
    id: 0,
    transportDiscount: 0,
    depositDiscount: 0,
    valid: false,
    loading: false
  },
  applyDiscount: false,
  discountCode: '',
  additionalCost: 0,
  additionalCostComment: '',
  error: ''
}

const CostReducer: Reducer<CostState, CostActions> = (state = costInitialState, action): CostState => {
  switch (action.type) {
    case CLEAN_STATES:
      return costInitialState
    case GET_TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        discountCode: action.payload.transaction.cost.discountCode,
        applyDiscount: action.payload.transaction.cost.isPrevDiscount
      }
    case FetchDiscountEnum.FETCH_DISCOUNT_REQUEST:
      return {
        ...state,
        fetchDiscount: {
          ...state.fetchDiscount,
          loading: true
        },
        error: ''
      }
    case FetchDiscountEnum.FETCH_DISCOUNT_SUCCESS: {
      const { id, transport, deposit, valid } = action.payload

      return {
        ...state,
        fetchDiscount: {
          ...state.fetchDiscount,
          id,
          transportDiscount: transport,
          depositDiscount: deposit,
          valid,
          loading: false
        },
        applyDiscount: valid,
        error: valid ? '' : 'Código inválido'
      }
    }
    case FetchDiscountEnum.FETCH_DISCOUNT_FAILURE:
      return {
        ...state,
        fetchDiscount: {
          ...state.fetchDiscount,
          loading: false
        },
        error: ''
      }
    case SetterDataDiscountEnum.SET_ADDITIONAL_COST: {
      return { ...state, additionalCost: action.payload.additionalCost }
    }
    case SetterDataDiscountEnum.SET_ADDITIONAL_COST_COMMENT: {
      return {
        ...state,
        additionalCostComment: action.payload.additionalCostComment
      }
    }
    case SetterDataDiscountEnum.SET_DISCOUNT_CODE: {
      return { ...state, discountCode: action.payload.discountCode, error: '' }
    }
    case SetterDataDiscountEnum.SET_APPLY_DISCOUNT: {
      return {
        ...state,
        applyDiscount: action.payload.applyDiscount,
        error: ''
      }
    }
    default:
      return state
  }
}

export default CostReducer
