export enum PriceBandsEventsEnum {
  PRICE_BAND_CREATED = 'PRICE_BANDS/PRICE_BAND_CREATED',
  PRICE_BAND_UPDATED = 'PRICE_BANDS/PRICE_BAND_UPDATED',
  PRICE_BAND_DELETED = 'PRICE_BANDS/PRICE_BAND_DELETED'
}

export type PriceBandsEvents = {
  [PriceBandsEventsEnum.PRICE_BAND_CREATED]: () => void
  [PriceBandsEventsEnum.PRICE_BAND_UPDATED]: () => void
  [PriceBandsEventsEnum.PRICE_BAND_DELETED]: () => void
}
