import React from 'react'

const PaySuccessIcon = () => {
  return (
    <svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1543_189508)">
        <path
          d="M82 164C127.287 164 164 127.287 164 82C164 36.7127 127.287 0 82 0C36.7127 0 0 36.7127 0 82C0 127.287 36.7127 164 82 164Z"
          fill="#ABF2D6"
        />
        <path
          d="M146.358 31.1816C148.176 20.9009 141.316 11.0928 131.035 9.27444C120.755 7.45613 110.947 14.3162 109.128 24.5969C107.31 34.8776 114.17 44.6858 124.451 46.5041C134.731 48.3224 144.54 41.4623 146.358 31.1816Z"
          fill="#46B78A"
        />
        <path
          d="M120.824 28.7437L124.203 32.0237C124.302 32.1199 124.436 32.1737 124.574 32.1737C124.713 32.1737 124.846 32.1199 124.946 32.0237L134.644 22.3805"
          stroke="white"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M116.648 77.9875L111.455 122.913C111.387 123.511 111.202 124.09 110.91 124.616C110.618 125.143 110.226 125.606 109.755 125.981C109.284 126.356 108.743 126.634 108.164 126.8C107.586 126.965 106.98 127.015 106.382 126.947L35.1295 118.714C34.5328 118.646 33.9553 118.461 33.4301 118.17C32.9049 117.879 32.4423 117.487 32.0686 117.017C31.6949 116.547 31.4176 116.008 31.2524 115.43C31.0873 114.853 31.0375 114.249 31.106 113.652L36.2993 68.7051C36.3631 68.1491 36.536 67.6111 36.808 67.1221C37.0801 66.633 37.446 66.2025 37.8848 65.8551C38.3236 65.5077 38.8266 65.2504 39.3651 65.0978C39.9035 64.9452 40.4667 64.9004 41.0225 64.9659L112.909 73.2533C113.466 73.3184 114.004 73.4926 114.493 73.7658C114.982 74.039 115.413 74.4059 115.76 74.8455C116.107 75.2852 116.364 75.789 116.517 76.3281C116.669 76.8672 116.714 77.4311 116.648 77.9875Z"
          fill="#57E5AC"
        />
        <path
          d="M126.937 56.1427L126.445 106.436C126.439 107.106 126.301 107.768 126.039 108.385C125.777 109.002 125.396 109.561 124.918 110.03C124.439 110.499 123.873 110.869 123.252 111.119C122.63 111.369 121.965 111.494 121.295 111.487L41.4819 110.7C40.1325 110.686 38.844 110.136 37.899 109.173C36.9541 108.21 36.4299 106.911 36.4416 105.561L36.9445 55.268C36.9618 54.0133 37.4754 52.8166 38.3728 51.9396C39.2703 51.0626 40.4786 50.5769 41.7333 50.5885L122.279 51.3867C123.524 51.4096 124.711 51.9212 125.582 52.8109C126.453 53.7007 126.94 54.8973 126.937 56.1427Z"
          fill="#EEFCF7"
        />
        <path
          d="M55.3558 71.0667H50.1406C49.3734 71.061 48.615 70.9038 47.9087 70.6043C47.2024 70.3048 46.5622 69.8688 46.0249 69.3212C45.4875 68.7737 45.0635 68.1255 44.7773 67.4137C44.4911 66.702 44.3482 65.9407 44.3568 65.1736C44.3509 64.3944 44.5007 63.6218 44.7976 62.9013C45.0944 62.1808 45.5321 61.5268 46.0852 60.9778C46.6382 60.4289 47.2954 59.9959 48.0181 59.7045C48.7408 59.413 49.5144 59.2689 50.2936 59.2805L55.5197 59.3352C57.0539 59.3724 58.512 60.0111 59.5796 61.1135C60.6473 62.2159 61.2389 63.6937 61.2269 65.2283C61.2255 65.9964 61.0728 66.7568 60.7775 67.4659C60.4822 68.175 60.0502 68.819 59.506 69.3612C58.9618 69.9033 58.3162 70.333 57.606 70.6256C56.8957 70.9182 56.1348 71.0681 55.3667 71.0667H55.3558Z"
          fill="#57E5AC"
        />
        <path
          d="M116.408 66.3435H108.755C107.913 66.3155 107.115 65.9651 106.525 65.3647C105.934 64.7643 105.598 63.9599 105.584 63.1181C105.61 62.2654 105.966 61.4562 106.579 60.8622C107.191 60.2682 108.011 59.9362 108.864 59.9365L116.517 60.024C116.937 60.0269 117.351 60.1123 117.737 60.2754C118.124 60.4385 118.474 60.676 118.768 60.9745C119.063 61.273 119.296 61.6266 119.453 62.015C119.611 62.4034 119.691 62.8192 119.688 63.2384C119.643 64.0776 119.278 64.8677 118.668 65.4454C118.057 66.0232 117.248 66.3447 116.408 66.3435Z"
          fill="#57E5AC"
        />
        <path d="M44.0853 94.3641L44.0645 96.4632L86.8117 96.8884L86.8326 94.7893L44.0853 94.3641Z" fill="#57E5AC" />
        <path d="M44.0336 99.0308L44.0127 101.13L74.2747 101.431L74.2955 99.3319L44.0336 99.0308Z" fill="#57E5AC" />
        <path d="M81.3822 59.5864L81.3613 61.6855L102.691 61.8977L102.712 59.7986L81.3822 59.5864Z" fill="#57E5AC" />
      </g>
      <defs>
        <clipPath id="clip0_1543_189508">
          <rect width="164" height="164" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PaySuccessIcon
