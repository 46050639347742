import React from 'react'
import clsx from 'clsx'
import styles from './requiredField.module.scss'

interface RequiredFieldProps {
  text?: string
  className?: string
}

const RequiredField: React.FC<RequiredFieldProps> = ({ text = '*Campo obligatorio', className }) => {
  return <p className={clsx(styles.requiredField, className)}>{text}</p>
}

export default RequiredField
