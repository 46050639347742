import { handleErrorsWithAction } from './../../../utils/HandleErrors'
import {
  GET_SALES_REQUEST_FAILURE,
  GET_SALES_REQUEST_REQUEST,
  GET_SALES_REQUEST_SUCCESS,
  GetSalesRequestMetricsRequest,
  GetSalesRequestMetricsSuccess,
  GetSalesRequestMetricsTypes,
  GetSalesRequestRequest,
  GetSalesRequestSuccess,
  Pagination,
  RefreshTableDataTypes,
  SEND_SALES_REQUEST_NOTIFICATION_FAILURE,
  SEND_SALES_REQUEST_NOTIFICATION_REQUEST,
  SEND_SALES_REQUEST_NOTIFICATION_SUCCESS,
  SalesRequestCategoryFilter,
  SalesRequestDateRangeFilter,
  SalesRequestSearchFilter,
  SendSalesRequestNotificationRequest,
  SendSalesRequestNotificationSuccess
} from '../types/salesRequestTable'

import store, { AppThunk } from './../../../store'
import { API } from '../../../projectApi'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { downloadFile } from '../../../utils/downloadFile'
import { SalesRequestTypes } from '../../../projectApi/Sales/SalesRequest/common'

export const salesRequestActionCreator = {
  getSalesRequestCSV:
    ({
      pagination,
      showDemoUsers,
      searchFilters,
      categoryFilter,
      dateRangeFilters
    }: {
      pagination: Pagination
      showDemoUsers?: boolean
      searchFilters?: SalesRequestSearchFilter[]
      categoryFilter?: SalesRequestCategoryFilter
      dateRangeFilters?: SalesRequestDateRangeFilter[]
    }): AppThunk =>
    async () => {
      const toast = setToastLoading('Generando archivo CSV. Por favor espere...')
      try {
        const file = await API.Sales.SalesRequest.getCsv({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          status: categoryFilter?.status[0],
          showDemoUsers,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })
        setToastSuccessUpdate(toast, { render: 'Archivo CSV generado con éxito' })
        downloadFile(file, 'Solicitudes de venta.csv')
      } catch (err) {
        setToastErrorUpdate(toast, { render: 'No se pudo generar el archivo CSV' })
      }
    },

  getSalesRequest:
    ({
      pagination,
      showDemoUsers,
      type,
      searchFilters,
      categoryFilter,
      dateRangeFilters
    }: {
      pagination: Pagination
      showDemoUsers?: boolean
      type?: string
      searchFilters?: SalesRequestSearchFilter[]
      categoryFilter?: SalesRequestCategoryFilter
      dateRangeFilters?: SalesRequestDateRangeFilter[]
    }): AppThunk =>
    async (dispatch) => {
      const request: GetSalesRequestRequest = {
        type: GET_SALES_REQUEST_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          type,
          showDemoUsers,
          searchFilters,
          categoryFilter,
          dateRangeFilters
        }
      }

      dispatch(request)

      try {
        const { salesRequest: salesRequests, total } = await API.Sales.SalesRequest.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          status: categoryFilter?.status[0],
          showDemoUsers,
          type: type ? mapOfMetricsToParams[type] : undefined,
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        const success: GetSalesRequestSuccess = {
          type: GET_SALES_REQUEST_SUCCESS,
          payload: {
            salesRequest: salesRequests,
            total
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_SALES_REQUEST_FAILURE, dispatch)
      }
    },
  sendSaleRequestNotification:
    (saleRequestId: number): AppThunk =>
    async (dispatch) => {
      const request: SendSalesRequestNotificationRequest = {
        type: SEND_SALES_REQUEST_NOTIFICATION_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Enviando notificación de venta. Por favor espere...')
      try {
        await API.Sales.SalesRequest.notification(saleRequestId)

        const success: SendSalesRequestNotificationSuccess = {
          type: SEND_SALES_REQUEST_NOTIFICATION_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toast, { render: '¡Notificación de venta enviada con éxito!' })
      } catch (err) {
        setToastErrorUpdate(toast, {
          render: handleErrorsWithAction(err, SEND_SALES_REQUEST_NOTIFICATION_FAILURE, dispatch)
        })
      }
    },

  getMetrics: (): AppThunk => async (dispatch) => {
    const request: GetSalesRequestMetricsRequest = {
      type: GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_REQUEST
    }

    dispatch(request)

    try {
      const response = await API.Sales.Metrics.list()

      const formatedResponse = Object.entries(response).map(([key, value]) => ({
        id: key,
        metric: mapOfMetrics[key],
        quantity: value
      }))

      const success: GetSalesRequestMetricsSuccess = {
        type: GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_SUCCESS,
        payload: { metrics: formatedResponse }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GetSalesRequestMetricsTypes.GET_SALES_REQUEST_METRICS_FAILURE, dispatch)
    }
  },
  refreshTableData: (): AppThunk => async (dispatch) => {
    const { pagination, searchFilters, categoryFilter, dateRangeFilters } = store.getState().SalesRequest.table
    dispatch(salesRequestActionCreator.getSalesRequest({ pagination, searchFilters, categoryFilter, dateRangeFilters }))
    return {
      type: RefreshTableDataTypes.REFRESH_TABLE_DATA
    }
  }
}

const mapOfMetrics: { [key: string]: string } = {
  salesRequests: 'Nuevas solicitudes',
  acceptedSalesRequests: 'Contrapropuestas aceptadas',
  rejectedSalesRequests: 'Contrapropuestas rechazadas',
  pendingSalesRequests: 'Contrapropuestas pendientes'
}

const mapOfMetricsToParams: { [key: string]: SalesRequestTypes } = {
  salesRequests: 'sales_requests',
  acceptedSalesRequests: 'accepted_sales_requests',
  rejectedSalesRequests: 'rejected_sales_requests',
  pendingSalesRequests: 'pending_sales_requests'
}
