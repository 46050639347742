import React from 'react'
import styles from './InfoDiscountCard.module.scss'

const InfoItem: React.FC<{ data?: number | string; label?: string }> = ({ data, label }) => (
  <div className={styles.infoItem}>
    <span className={styles.titleInfoItem}>{label}</span>
    {data}
  </div>
)

export const InfoCard: React.FC<{ id?: number; day?: string; discountRate?: number }> = ({ id, day, discountRate }) => (
  <header className={styles.infoCard}>
    <h3 className={styles.titleInfoCard}>Datos actuales del descuento:</h3>
    <div className={styles.hero}>
      <InfoItem data={id} label={'ID'} />
      <InfoItem data={day} label={'Día'} />
      <InfoItem data={`${discountRate}%`} label={'Porcentaje'} />
    </div>
  </header>
)
