import { AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Paging, Role } from './common'
import { URL_BASE_USERS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import camelcaseKeys from 'camelcase-keys'

type APIParent = {
  id: number
}

type APISatelliteAccount = {
  id: number
  parent: APIParent
  user_id: number
  role: Role
  name: string
  last_name: string
  email: string
}

export type SatelliteAccount = CamelCasedPropertiesDeep<APISatelliteAccount>

interface APIResponse {
  paging: Paging
  results: APISatelliteAccount[]
}

interface Response {
  satelliteUsers: SatelliteAccount[]
  total: number
}

interface ListParams {
  limit: number
  offset: number
  order?: 'asc' | 'desc'
  column?: string
}

export function userList(params: ListParams): Promise<Response> {
  const formattedParams = {
    order: params.order ?? 'desc',
    column: params.column ?? 'id',
    ...params
  }
  return apiPrivate
    .get(`${URL_BASE_USERS}/satellite-user/corporate`, { params: formattedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return {
        satelliteUsers: camelcaseKeys(response.data.results, { deep: true }),
        total: response.data.paging.total
      }
    })
    .catch((error) => {
      throw error
    })
}
