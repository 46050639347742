import moment from 'moment'
import { Reducer } from 'redux'
import {
  CalendarActions,
  CalendarState,
  GET_TIMEINTERVALS_FAILURE,
  GET_TIMEINTERVALS_REQUEST,
  GET_TIMEINTERVALS_SUCCESS,
  GET_TIMESLOTS_FAILURE,
  GET_TIMESLOTS_REQUEST,
  GET_TIMESLOTS_SUCCESS,
  GET_UNAVAILABLE_DATES_FAILURE,
  GET_UNAVAILABLE_DATES_REQUEST,
  GET_UNAVAILABLE_DATES_SUCCESS,
  SET_AVAILABLE_DATE,
  SET_TIMESLOT,
  SET_TIME_INTERVALS
} from '../types/calendar'
import { ProspectSearchTypes } from '../types/prospect'
import { ProposalTypes } from '../types/proposals'

const CalendarInitialState: CalendarState = {
  datetime: '',
  unavailableDates: {
    list: [],
    selected: moment().add(1, 'days'),
    loading: false
  },
  timeslots: {
    list: [],
    selected: 0,
    loading: false
  },
  timeIntervals: {
    list: [],
    selected: 0,
    loading: false
  }
}

export const DEFAULT_TODAY = moment()

const CalendarReducer: Reducer<CalendarState, CalendarActions> = (state = CalendarInitialState, action) => {
  switch (action.type) {
    case ProposalTypes.SET_NEW_PROPOSAL_FROM_SCRATCH:
    case ProspectSearchTypes.GET_PROSPECT_REQUEST:
      return CalendarInitialState
    case GET_UNAVAILABLE_DATES_REQUEST:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: true
        }
      }
    case GET_UNAVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          list: action.payload.unavailableDates,
          loading: false
        }
      }
    case GET_UNAVAILABLE_DATES_FAILURE: {
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: false
        }
      }
    }
    case SET_AVAILABLE_DATE:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          selected: action.payload.availableDate
        },
        timeslots: {
          ...state.timeslots,
          selected: 0
        },
        timeIntervals: {
          ...state.timeIntervals,
          selected: 0
        }
      }
    case GET_TIMESLOTS_REQUEST:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          loading: true
        }
      }
    case GET_TIMESLOTS_SUCCESS:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          list: action.payload.timeslots,
          selected: action.payload.selectFirstTimeslot ? action.payload.timeslots[0].id : state.timeslots.selected,
          loading: false
        }
      }
    case GET_TIMESLOTS_FAILURE: {
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          loading: false
        }
      }
    }
    case SET_TIMESLOT:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          selected: action.payload.timeslot
        },
        timeIntervals: {
          ...state.timeIntervals,
          selected: 0
        }
      }
    case GET_TIMEINTERVALS_REQUEST:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          loading: true
        }
      }
    case GET_TIMEINTERVALS_SUCCESS:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          list: action.payload.timeIntervals,
          selected: action.payload.timeIntervals[0]?.id || 0,
          loading: false
        }
      }
    case GET_TIMEINTERVALS_FAILURE: {
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          loading: false
        }
      }
    }
    case SET_TIME_INTERVALS:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          selected: action.payload.timeInterval
        }
      }
    default:
      return state
  }
}

export default CalendarReducer
