import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  AgentCreateData,
  CREATE_AGENT_FAILURE,
  CREATE_AGENT_REQUEST,
  CREATE_AGENT_SUCCESS,
  CreateAgentRequest,
  CreateAgentSuccess,
  ERROR_EMAIL_TYPES,
  GET_AGENT_BY_EMAIL_FAILURE,
  GET_AGENT_BY_EMAIL_REQUEST,
  GET_AGENT_BY_EMAIL_SUCCESS,
  GET_USER_BY_EMAIL_FAILURE,
  GET_USER_BY_EMAIL_REQUEST,
  GET_USER_BY_EMAIL_SUCCESS,
  GetAgentByEmailFailure,
  GetAgentByEmailRequest,
  GetAgentByEmailSuccess,
  GetUserByEmailFailure,
  GetUserByEmailRequest,
  GetUserByEmailSuccess,
  OPEN_MODAL,
  OpenModal,
  SET_AGENT_ROLE_ID,
  SET_ERROR_MODAL_AGENT,
  SetAgentRoleId,
  SetErrorModalAgent
} from '../types/agentModal'

const AgentModalActionCreator = {
  openModal: ({ openModal }: { openModal: boolean }): OpenModal => ({
    type: OPEN_MODAL,
    payload: { openModal }
  }),
  getUserByEmail:
    ({ email }: { email: string }): AppThunk =>
    async (dispatch) => {
      const request: GetUserByEmailRequest = {
        type: GET_USER_BY_EMAIL_REQUEST
      }
      dispatch(request)

      try {
        const { total, users } = await API.AccountManager.Users.list({ email })

        const success: GetUserByEmailSuccess = {
          type: GET_USER_BY_EMAIL_SUCCESS,
          payload: { user: users[0], total, error: ERROR_EMAIL_TYPES.ACCEPTED }
        }

        const failure: GetUserByEmailFailure = {
          type: GET_USER_BY_EMAIL_FAILURE,
          payload: { error: ERROR_EMAIL_TYPES.NOTUSER }
        }

        if (users.length !== 0) {
          dispatch(success)
        } else {
          dispatch(failure)
        }
      } catch (err) {
        handleErrorsWithAction(err, GET_USER_BY_EMAIL_FAILURE, dispatch)
      }
    },
  createAgent:
    ({ firstName, lastName, email, userGuid, userId, roleId }: AgentCreateData): AppThunk =>
    async (dispatch) => {
      const request: CreateAgentRequest = {
        type: CREATE_AGENT_REQUEST
      }

      dispatch(request)

      try {
        await API.Mercurio.BO.Agents.create({
          firstName,
          lastName,
          email,
          userGuid,
          userId,
          roleId
        })

        const success: CreateAgentSuccess = {
          type: CREATE_AGENT_SUCCESS
        }
        dispatch(success)
        Emitter.emit(Events.Agents.AGENT_CREATED)
      } catch (err) {
        handleErrorsWithAction(err, CREATE_AGENT_FAILURE, dispatch)
      }
    },
  setAgentRoleId: (roleId: number): SetAgentRoleId => ({
    type: SET_AGENT_ROLE_ID,
    payload: { roleId }
  }),
  verifyAgentExists:
    ({ firstName, lastName, email, userGuid, userId, roleId }: AgentCreateData): AppThunk =>
    async (dispatch) => {
      const request: GetAgentByEmailRequest = {
        type: GET_AGENT_BY_EMAIL_REQUEST
      }

      dispatch(request)

      try {
        const { agent } = await API.Mercurio.BO.Agents.search({ email })

        const success: GetAgentByEmailSuccess = {
          type: GET_AGENT_BY_EMAIL_SUCCESS,
          payload: { agent: agent[0], error: ERROR_EMAIL_TYPES.ACCEPTED }
        }

        const failure: GetAgentByEmailFailure = {
          type: GET_AGENT_BY_EMAIL_FAILURE,
          payload: { error: ERROR_EMAIL_TYPES.ALREADY_IN_USE }
        }

        if (agent.length === 0) {
          dispatch(success)
          dispatch(AgentModalActionCreator.createAgent({ firstName, lastName, email, roleId, userGuid, userId }))
        } else {
          dispatch(failure)
        }
      } catch (err) {
        handleErrorsWithAction(err, GET_AGENT_BY_EMAIL_FAILURE, dispatch)
      }
    },
  setErrorModalAgent: (errorMessage: string): SetErrorModalAgent => ({
    type: SET_ERROR_MODAL_AGENT,
    payload: { errorMessage }
  })
}

export default AgentModalActionCreator
