import { handleErrorsWithAction } from './../../../utils/HandleErrors'
import {
  ACCEPT_PROPOSAL_FAILURE,
  ACCEPT_PROPOSAL_REQUEST,
  ACCEPT_PROPOSAL_SUCCESS,
  AcceptProposalRequest,
  AcceptProposalSuccess,
  CLEAR_VIEW_STATE,
  CREATE_COUNTER_PROPOSAL_FAILURE,
  CREATE_COUNTER_PROPOSAL_REQUEST,
  CREATE_COUNTER_PROPOSAL_SUCCESS,
  ClearViewState,
  CreateCounterProposalRequest,
  CreateCounterProposalSuccess,
  GET_REASONS_FAILURE,
  GET_REASONS_REQUEST,
  GET_REASONS_SUCCESS,
  GET_SALES_REQUEST_VIEW_FAILURE,
  GET_SALES_REQUEST_VIEW_REQUEST,
  GET_SALES_REQUEST_VIEW_SUCCESS,
  GET_SALE_COST_ARG_FAILURE,
  GET_SALE_COST_ARG_REQUEST,
  GET_SALE_COST_ARG_SUCCESS,
  GetReasonsRequest,
  GetReasonsSuccess,
  GetSaleCostArgRequest,
  GetSaleCostArgSuccess,
  GetSalesRequestViewRequest,
  GetSalesRequestViewSuccess,
  SET_COUNTER_PROPOSAL_VIEW,
  SET_OPEN_MODAL,
  SET_PUBLICATION_PRICE,
  SetCounterProposalView,
  SetOpenModal,
  SetPublicationPrice,
  UPDATE_SALE_REQUEST_FAILURE,
  UPDATE_SALE_REQUEST_REQUEST,
  UPDATE_SALE_REQUEST_SUCCESS,
  UpdateSaleRequestRequest,
  UpdateSaleRequestSuccess,
  MANAGE_SELL_MODAL,
  ManageSellModal,
  SellObjectRequest,
  SELL_OBJECT_REQUEST,
  SellObjectSuccess,
  SELL_OBJECT_SUCCESS,
  SELL_OBJECT_FAILURE
} from './../types/salesRequestView'
import { AppThunk } from './../../../store'
import { API } from '../../../projectApi'
import { Flag } from '../../../projectApi/ObjectAdministration/Value/getValue'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { SaleActions } from '../../../projectApi/Sales/reasons'
import { AcceptParams } from '../../../projectApi/Sales/SalesRequest/accept'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'

const SalesRequestViewActionCreator = {
  getSearchSalesRequest:
    (id: number): AppThunk =>
    async (dispatch) => {
      const request: GetSalesRequestViewRequest = {
        type: GET_SALES_REQUEST_VIEW_REQUEST
      }

      dispatch(request)

      try {
        const data = await API.Sales.SalesRequest.search({ id })

        const success: GetSalesRequestViewSuccess = {
          type: GET_SALES_REQUEST_VIEW_SUCCESS,
          payload: {
            saleRequest: data
          }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_SALES_REQUEST_VIEW_FAILURE, dispatch)
      }
    },
  setOpenModal: (isOpenModal: boolean): SetOpenModal => ({
    type: SET_OPEN_MODAL,
    payload: {
      isOpenModal
    }
  }),
  rejectSaleRequest:
    ({
      id,
      reason,
      saleRequestId,
      saleCost,
      saleMargin
    }: {
      id: number
      reason: string
      saleRequestId: number
      saleCost: number
      saleMargin: number
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateSaleRequestRequest = {
        type: UPDATE_SALE_REQUEST_REQUEST
      }

      dispatch(request)

      try {
        await API.Sales.SalesRequest.reject({ id, reason, saleRequestId, saleCost, saleMargin })

        const success: UpdateSaleRequestSuccess = {
          type: UPDATE_SALE_REQUEST_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.SaleRequest.REJECT_REQUEST)
      } catch (err) {
        handleErrorsWithAction(err, UPDATE_SALE_REQUEST_FAILURE, dispatch)
      }
    },
  clearViewState: (): ClearViewState => ({
    type: CLEAR_VIEW_STATE
  }),
  getSaleCostArg: (): AppThunk => async (dispatch) => {
    const request: GetSaleCostArgRequest = {
      type: GET_SALE_COST_ARG_REQUEST
    }

    dispatch(request)

    try {
      const value = await API.ObjectAdministration.Value.getValue({ flag: Flag.SALE_COST_ARG })

      const success: GetSaleCostArgSuccess = {
        type: GET_SALE_COST_ARG_SUCCESS,
        payload: { saleCostArg: Number(value) }
      }

      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GET_SALE_COST_ARG_FAILURE, dispatch)
    }
  },
  setPublicationPrice: (price: string): SetPublicationPrice => ({
    type: SET_PUBLICATION_PRICE,
    payload: {
      publicationPrice: Number(price)
    }
  }),
  getReasons:
    (action: SaleActions): AppThunk =>
    async (dispatch) => {
      const request: GetReasonsRequest = {
        type: GET_REASONS_REQUEST
      }

      dispatch(request)

      try {
        const { reasons } = await API.Sales.reasons({ action })

        const success: GetReasonsSuccess = {
          type: GET_REASONS_SUCCESS,
          payload: {
            reasons
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REASONS_FAILURE, dispatch)
      }
    },
  acceptProposal:
    ({
      id,
      pvp,
      saleCost,
      saleMargin,
      existExpressSale,
      expressSaleCost,
      expressSaleMargin,
      pve
    }: AcceptParams): AppThunk =>
    async (dispatch) => {
      const request: AcceptProposalRequest = {
        type: ACCEPT_PROPOSAL_REQUEST
      }
      dispatch(request)

      try {
        await API.Sales.SalesRequest.acceptProposal({
          id,
          pvp,
          saleCost,
          saleMargin,
          existExpressSale,
          expressSaleCost,
          expressSaleMargin,
          pve
        })

        const success: AcceptProposalSuccess = {
          type: ACCEPT_PROPOSAL_SUCCESS
        }

        dispatch(success)

        Emitter.emit(Events.SaleRequest.ACCEPT_PROPOSAL)
      } catch (err) {
        handleErrorsWithAction(err, ACCEPT_PROPOSAL_FAILURE, dispatch)
      }
    },
  createCounterProposal:
    ({
      id,
      isExpressSale,
      prc,
      pve,
      pvp,
      saleCost,
      saleMargin,
      expressSaleCost,
      expressSaleMargin
    }: {
      id: number
      isExpressSale: boolean
      prc: number
      pve?: number
      pvp: number
      saleCost: number
      saleMargin: number
      expressSaleCost?: number
      expressSaleMargin?: number
    }): AppThunk =>
    async (dispatch) => {
      const request: CreateCounterProposalRequest = {
        type: CREATE_COUNTER_PROPOSAL_REQUEST
      }

      dispatch(request)

      try {
        await API.Sales.SalesRequest.counterProposal({
          id,
          isExpressSale,
          prc,
          pve,
          pvp,
          saleCost,
          saleMargin,
          expressSaleCost,
          expressSaleMargin
        })
        const success: CreateCounterProposalSuccess = {
          type: CREATE_COUNTER_PROPOSAL_SUCCESS
        }

        dispatch(success)
        Emitter.emit(Events.SaleRequest.COUNTER_PROPOSAL)
      } catch (err) {
        handleErrorsWithAction(err, CREATE_COUNTER_PROPOSAL_FAILURE, dispatch)
      }
    },
  setCounterProposalView: (isCounterProposalActive: boolean): SetCounterProposalView => ({
    type: SET_COUNTER_PROPOSAL_VIEW,
    payload: { isCounterProposalActive }
  }),
  setSellOpenModal: (isSellModalOpen: boolean): ManageSellModal => ({
    type: MANAGE_SELL_MODAL,
    payload: {
      isSellModalOpen
    }
  }),
  sellObjectRequest:
    (id: number): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const request: SellObjectRequest = {
        type: SELL_OBJECT_REQUEST
      }

      dispatch(request)
      const toast = setToastLoading('Preparando la venta. Por favor espere...')
      try {
        await API.Sales.SalesRequest.confirm(id)

        const success: SellObjectSuccess = {
          type: SELL_OBJECT_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: '¡Venta enviada con éxito!' })
        dispatch(success)
        return true
      } catch (err) {
        setToastErrorUpdate(toast, { render: handleErrorsWithAction(err, SELL_OBJECT_FAILURE, dispatch) })
        return false
      }
    }
}

export default SalesRequestViewActionCreator
