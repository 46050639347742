import { Reducer } from 'redux'
import {
  GetRemovalValidationMetricsTypes,
  RemovalValidationMetricsActions,
  RemovalValidationMetricsState
} from '../types/metrics'

const initialState: RemovalValidationMetricsState = {
  quantityMetrics: [],
  fetchingMetrics: false
}

const removalValidationMetricsReducer: Reducer<RemovalValidationMetricsState, RemovalValidationMetricsActions> = (
  state = initialState,
  action
): RemovalValidationMetricsState => {
  switch (action.type) {
    case GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_REQUEST:
      return {
        ...state,
        fetchingMetrics: true
      }
    case GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_SUCCESS:
      return {
        ...state,
        fetchingMetrics: false,
        quantityMetrics: action.payload.quantityMetrics
      }
    case GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_FAILURE:
      return {
        ...state,
        fetchingMetrics: false
      }
    default:
      return state
  }
}

export default removalValidationMetricsReducer
