import { API } from '../../../projectApi'
import { CreateDateParams } from '../../../projectApi/Timeslot/OperationCalendar/SpecificDates/create'
import { DeleteOperationParams } from '../../../projectApi/Timeslot/OperationCalendar/SpecificDates/delete'
import { AppThunk } from '../../../store'
import { getErrorMessage, handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import {
  CreateDateSuccess,
  CreateOperationDatesTypes,
  DeleteOperationDatesTypes,
  GetDatesRequest,
  GetDatesSuccess,
  GetOperationDatesTypes,
  ModalCreateOperationDate,
  ModalDeleteOperationDate,
  SetCreateModalOpen,
  SetDeleteModalOpen,
  DeleteDateSuccess,
  DeleteDateRequest,
  SetSelectedDeleteDate,
  SetSelectedCreateDate,
  CreateDateRequest
} from '../types/operationDates'
import { Moment } from 'moment'

const OperationDatesActionCreator = {
  getDates: (): AppThunk => async (dispatch) => {
    const request: GetDatesRequest = {
      type: GetOperationDatesTypes.GET_DATES_REQUEST
    }
    dispatch(request)

    try {
      const { days } = await API.Timeslot.operations.Dates.getDates()
      const success: GetDatesSuccess = {
        type: GetOperationDatesTypes.GET_DATES_SUCCESS,
        payload: {
          datesData: days || []
        }
      }
      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GetOperationDatesTypes.GET_DATES_FAILURE, dispatch)
    }
  },
  createDate:
    (date: CreateDateParams): AppThunk =>
    async (dispatch) => {
      const toast = setToastLoading('Deshabilitando fecha')
      const request: CreateDateRequest = {
        type: CreateOperationDatesTypes.CREATE_DATE_REQUEST
      }
      dispatch(request)

      try {
        await API.Timeslot.operations.Dates.createDate(date)
        const success: CreateDateSuccess = {
          type: CreateOperationDatesTypes.CREATE_DATE_SUCCESS
        }
        dispatch(success)
        setToastSuccessUpdate(toast, { render: 'Fecha deshabilitada exitosamente' })
        Emitter.emit(Events.OperationCalendar.OPERATION_DATE_CREATED)
      } catch (error: any) {
        const message = getErrorMessage(error)
        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, CreateOperationDatesTypes.CREATE_DATE_FAILURE, dispatch)
      }
    },
  deleteDate:
    ({ date, operationType }: DeleteOperationParams): AppThunk =>
    async (dispatch) => {
      const request: DeleteDateRequest = {
        type: DeleteOperationDatesTypes.DELETE_DATE_REQUEST
      }
      dispatch(request)

      try {
        await API.Timeslot.operations.Dates.deleteDate({ date, operationType })
        const success: DeleteDateSuccess = {
          type: DeleteOperationDatesTypes.DELETE_DATE_SUCCESS
        }
        dispatch(success)
        Emitter.emit(Events.OperationCalendar.OPERATION_DATE_DELETED)
      } catch (error) {
        handleErrorsWithAction(error, DeleteOperationDatesTypes.DELETE_DATE_FAILURE, dispatch)

        setToastErrorUpdate('error', { render: 'Error al eliminar la fecha seleccionada' })
      }
    },
  openCreateDateModal: (isCreateModalOpen: boolean): SetCreateModalOpen => {
    return {
      type: ModalCreateOperationDate.SET_CREATE_MODAL_OPEN,
      payload: { isCreateModalOpen }
    }
  },
  openDeleteDateModal: (isDeleteModalOpen: boolean): SetDeleteModalOpen => {
    return {
      type: ModalDeleteOperationDate.SET_DELETE_MODAL_OPEN,
      payload: { isDeleteModalOpen }
    }
  },
  setSelectedCreateDate(selectedCreateDate: Moment | null): SetSelectedCreateDate {
    return {
      type: ModalCreateOperationDate.SET_SELECTED_CREATE_DATE,
      payload: { selectedCreateDate }
    }
  },
  setSelectedDeleteDate(selectedDeleteDate: DeleteOperationParams): SetSelectedDeleteDate {
    return {
      type: ModalDeleteOperationDate.SET_SELECTED_DELETE_DATE,
      payload: { selectedDeleteDate }
    }
  }
}

export default OperationDatesActionCreator
