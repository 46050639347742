import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductsTableActionCreators from '../actions/productTable'
import AttributeModalActionCreators from '../actions/attributeModal'
import DeleteProductActionCreators from '../actions/deleteModal'

import { CategoryOption, ProductsTable, ProductsTableProps, ServiceTypeOption } from '../components/productTable'
import {
  Pagination,
  ProductsCategoryFilter,
  ProductsSearchFilter,
  ProductsTableSearchKey,
  ProductsTableSort
} from '../types/productTable'
import { Edit, Trash, List } from '../../../icons'
import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'

import { SortDirection } from '../../../components/DynamicTable/types/types'
import { useHistory } from 'react-router'

export type CreateProductContainerTypes = {
  serviceTypes: ServiceTypeOption[]
  categories: CategoryOption[]
}

const ProductsTableContainer = (ownProps: CreateProductContainerTypes) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getProducts, getCategories, getServiceTypes, searchCategoryId, sendCSV } = bindActionCreators(
    ProductsTableActionCreators,
    dispatch
  )
  const { openDeleteModal } = bindActionCreators(DeleteProductActionCreators, dispatch)
  const { openAttributeModal } = bindActionCreators(AttributeModalActionCreators, dispatch)
  const {
    products,
    loadingProducts,
    pagination,
    searchFilters,
    sort,
    categoryFilter,
    categories: searchCategories,
    categoryId,
    sendingCSV
  } = useSelector((state) => state.Products.table)

  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const emptyPagination = { ...pagination, page: 1 }
  const { serviceTypes, categories } = ownProps
  const emptyFilters = { showInSearch: [], deprecated: [] }

  const handleGetProducts = (newParams: {
    pagination?: Pagination
    searchFilters?: ProductsSearchFilter[]
    sort: ProductsTableSort
    categoryFilter: ProductsCategoryFilter
    categoryId?: number
  }) => {
    getProducts({
      countryCode,
      pagination: newParams.pagination || pagination,
      searchFilters: newParams.searchFilters || searchFilters,
      sort: newParams.sort || sort,
      categoryFilter: newParams.categoryFilter || categoryFilter,
      categoryId: newParams.categoryId ?? categoryId
    })
  }

  useEffect(() => {
    getCategories()
    getServiceTypes()
  }, [])

  useEffect(() => {
    if (countryCode) handleGetProducts({ pagination: emptyPagination, sort, categoryFilter })
  }, [countryCode])

  const getKeyFilter = (newValue: string | number) => {
    if (typeof newValue === 'number') return newValue.toString().toLowerCase()

    switch (newValue.toLowerCase()) {
      case 'sí':
        return 'true'
      case 'no':
        return 'false'
      default:
        return newValue
    }
  }

  const handlePageChange = (newPage: number) => {
    handleGetProducts({ pagination: { ...pagination, page: newPage }, sort, categoryFilter })
  }

  const handleSearch = (key: ProductsTableSearchKey, newValue: string) => {
    const newSearchFilters = searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: getKeyFilter(newValue)
          }
        : filter
    )
    handleGetProducts({ pagination: emptyPagination, searchFilters: newSearchFilters, sort, categoryFilter })
  }

  const handleByCategoryId = (categoryId: number) => {
    handleGetProducts({ pagination: emptyPagination, searchFilters, sort, categoryFilter, categoryId })
  }

  const handleCategoryFilter = (newCategoryFilter: ProductsCategoryFilter) => {
    handleGetProducts({ categoryFilter: newCategoryFilter, pagination: emptyPagination, sort })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetSort: ProductsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination
    const resetCategoryFilter = emptyFilters

    handleGetProducts({
      pagination: resetPagination,
      searchFilters: resetSearchFilters,
      sort: resetSort,
      categoryFilter: resetCategoryFilter,
      categoryId: 0
    })
  }

  const viewEditNewProduct = ({ productTypeId }: { productTypeId: number }) => {
    const path = `/editProduct/${productTypeId}`
    history.push({
      pathname: path
    })
  }

  const handleSendCSV = () => {
    const actualParams = {
      countryCode,
      pagination,
      searchFilters,
      sort,
      categoryFilter,
      categoryId
    }

    sendCSV(actualParams)
  }

  const actions = [
    {
      label: 'Editar producto',
      icon: <Edit />,
      onClick: ({ id }: FormattedProduct) => viewEditNewProduct({ productTypeId: id })
    },
    {
      label: 'Eliminar producto',
      icon: <Trash />,
      onClick: (row: FormattedProduct) => openDeleteModal({ product: row })
    },
    {
      label: 'Editar atributos',
      icon: <List />,
      onClick: (row: FormattedProduct) => openAttributeModal(row.id)
    }
  ]

  const paging = {
    currentPage: pagination.page,
    pageSize: pagination.pageSize,
    total: pagination.total,
    onPageChange: handlePageChange
  }

  const props: ProductsTableProps = {
    products,
    loadingProducts,
    sendingCSV,
    pagination: paging,
    searchFilters,
    countryCode,
    handleSearch,
    handleResetFilters,
    actions,
    serviceTypes,
    categories,
    sort,
    categoryFilter,
    handleCategoryFilter,
    searchCategories,
    searchCategoryId,
    handleByCategoryId,
    handleSendCSV,
    categoryId
  }

  return <ProductsTable {...props} />
}

export default ProductsTableContainer
