import types from './Activity.types'
import { List, Map } from 'immutable'
import { combineReducers } from 'redux'

const LIMIT = 50

const initialActivityTable = Map({
  activity: List(),
  loading: false,
  params: Map({
    Limit: LIMIT,
    Offset: 0,
    Column: 'date',
    Order: 'desc'
  }),
  quantity: 0,
  sorter: Map({
    order: 'descend',
    field: 'date',
    columnKey: 'date'
  }),
  filter: Map({
    payment_method: [],
    status_id: []
  }),
  searchFilters: List([
    { key: 'id', text: '' },
    { key: 'user_id', text: '' },
    { key: 'date', text: '' },
    { key: 'rule_id', text: '' },
    { key: 'payment_id', text: '' }
  ]),
  pagination: Map({
    current: 1,
    total: 0
  })
})

function activityTable(state = initialActivityTable, action) {
  switch (action.type) {
    case types.CLEAN_FILTERS: {
      return state.merge({
        params: initialActivityTable.get('params').merge({
          Column: state.getIn(['params', 'Column']),
          Order: state.getIn(['params', 'Order'])
        }),
        filter: initialActivityTable.get('filter'),
        searchFilters: initialActivityTable.get('searchFilters'),
        pagination: initialActivityTable.get('pagination')
      })
    }
    case types.FETCH_ACTIVITY:
      return state.merge({
        activity: List(),
        loading: true,
        error: ''
      })
    case types.FETCH_ACTIVITY_SUCCESS:
      return state.merge({
        activity: List(action.payload.activity),
        loading: false,
        params: Map(action.payload.params),
        pagination: state.get('pagination').merge({
          total: action.payload.quantity
        })
      })
    case types.FETCH_ACTIVITY_FAIL:
      return state.merge({
        loading: false,
        error: action.payload.error
      })
    case types.SET_FILTER: {
      return state.merge({
        filter: Map(action.payload.filter)
      })
    }
    case types.SET_SORTER: {
      return state.merge({
        sorter: Map(action.payload.sorter)
      })
    }
    case types.SET_PAGINATION: {
      return state.merge({
        pagination: Map(action.payload.pagination)
      })
    }
    case types.SET_SEARCH_FILTERS: {
      return state.merge({
        searchFilters: List(action.payload.searchFilters)
      })
    }
    case types.UPDATE_ACTIVITY_SUCCESS: {
      const { charge } = action.payload
      const activity = state.get('activity').toArray()
      return state.merge({
        activity: List(activity.map((item) => (item.id === charge.id ? charge : item)))
      })
    }
    default:
      return state
  }
}

const initialUpdateActivity = Map({
  modalOpen: false,
  loading: false,
  status_id: 9,
  payment_reference: '',
  error: ''
})

function updateActivity(state = initialUpdateActivity, action) {
  switch (action.type) {
    case types.UPDATE_ACTIVITY:
      return state.merge({
        loading: true,
        error: ''
      })
    case types.UPDATE_ACTIVITY_SUCCESS:
      return initialUpdateActivity
    case types.UPDATE_ACTIVITY_FAIL:
      return state.merge({
        loading: false,
        error: action.payload.error
      })
    case types.SET_UPDATE_MODAL_OPEN: {
      const { modalOpen, charge } = action.payload
      return state.merge(modalOpen ? { modalOpen, ...charge } : initialUpdateActivity)
    }
    case types.SET_STATUS:
      return state.merge({
        status_id: action.payload.status_id
      })
    case types.SET_REFERENCE:
      return state.merge({
        payment_reference: action.payload.payment_reference
      })
    default:
      return state
  }
}

export default combineReducers({
  table: activityTable,
  update: updateActivity
})
