import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dropdown, Icon } from 'antd'
import styles from './SelectCheckbox.module.scss'
import Overlay from '../Overlay/Overlay'
import { OperationTypeValues } from '../../types/common'
import OperationBadge from '../OperationBadge'
import { CALENDAR_CHECKBOX_OPERATIONS } from '../../constants'

type FilterDropdownProps = {
  setSelectedOperations: (args: { selectedOperations: OperationTypeValues[] }) => void
  availableOperations: OperationTypeValues[]
  selectedOperations: OperationTypeValues[]
}

const SelectCheckbox = ({ setSelectedOperations, availableOperations, selectedOperations }: FilterDropdownProps) => {
  const [visible, setVisible] = useState(false)
  const [localSelected, setLocalSelected] = useState<OperationTypeValues[]>(selectedOperations)

  useEffect(() => {
    setLocalSelected(availableOperations)
  }, [availableOperations])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
    setSelectedOperations({ selectedOperations: localSelected })
  }

  const handleLocalCheckboxChange = (value: OperationTypeValues) => {
    setLocalSelected((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((item) => item !== value)
      } else {
        return [...prevSelected, value]
      }
    })
  }

  const handleConfirm = () => {
    setVisible(false)
    setSelectedOperations({ selectedOperations: localSelected })
  }

  const handleReset = () => {
    setVisible(false)
    setLocalSelected([])
    setSelectedOperations({ selectedOperations: [] })
  }

  const overlay = (
    <Overlay
      buttons={
        <>
          <Button onClick={handleReset} size="small" className={styles.reset}>
            Resetear
          </Button>
          <Button type="primary" onClick={handleConfirm} size="small" className={styles.save}>
            Guardar
          </Button>
        </>
      }>
      <div className={styles.OptionsContainer}>
        {CALENDAR_CHECKBOX_OPERATIONS.map((option) => (
          <div key={option.value}>
            <Checkbox
              className={styles.CheckBox}
              checked={localSelected.includes(option.value)}
              onChange={() => handleLocalCheckboxChange(option.value)}>
              {option.text}
            </Checkbox>
          </div>
        ))}
      </div>
    </Overlay>
  )

  return (
    <Dropdown
      overlay={overlay}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlayClassName={styles.overlay}>
      <div className={styles.fakeSelect}>
        <div className={styles.BadgeContainer}>
          {localSelected.length === 0 ? (
            <OperationBadge key={'none'} operation={OperationTypeValues.NONE} />
          ) : (
            localSelected.map((operation) => <OperationBadge key={operation} operation={operation} />)
          )}
        </div>

        <Icon type="caret-down" />
      </div>
    </Dropdown>
  )
}

export default SelectCheckbox
