import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { OperationTypeValues } from '../types/common'
import {
  GetDaysSuccess,
  SetEditModalOpen,
  GetDaysRequest,
  UpdateDayRequest,
  UpdateDaySuccess,
  GetOperationDaysTypes,
  UpdateOperationDaysTypes,
  ModalEditDayOperationTypes,
  SetSelectedDayOperations
} from '../types/operationDays'
import { processDaysResponse } from '../utils/processDays'

const OperationDaysActionCreator = {
  getDays: (): AppThunk => async (dispatch) => {
    const request: GetDaysRequest = {
      type: GetOperationDaysTypes.GET_DAYS_REQUEST
    }
    dispatch(request)

    try {
      const daysResponse = await API.Timeslot.operations.Days.getDays()
      const processedDays = processDaysResponse(daysResponse)

      const success: GetDaysSuccess = {
        type: GetOperationDaysTypes.GET_DAYS_SUCCESS,
        payload: {
          daysData: processedDays
        }
      }
      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GetOperationDaysTypes.GET_DAYS_FAILURE, dispatch)
    }
  },
  updateOperation:
    ({
      day,
      operationType,
      available
    }: {
      day: string
      operationType: OperationTypeValues
      available: boolean
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateDayRequest = {
        type: UpdateOperationDaysTypes.UPDATE_DAYS_REQUEST
      }

      dispatch(request)

      try {
        await API.Timeslot.operations.Days.updateOperation({ day, operationType, available })
        const success: UpdateDaySuccess = {
          type: UpdateOperationDaysTypes.UPDATE_DAYS_SUCCESS
        }
        dispatch(success)
        Emitter.emit(Events.OperationCalendar.OPERATION_DAY_UPDATED)
        return true
      } catch (error) {
        handleErrorsWithAction(error, UpdateOperationDaysTypes.UPDATE_DAYS_FAILURE, dispatch)
      }
    },
  openEditDayModal: ({
    isEditModalOpen,
    selectedDay
  }: {
    isEditModalOpen: boolean
    selectedDay: string
  }): SetEditModalOpen => {
    return {
      type: ModalEditDayOperationTypes.SET_EDIT_MODAL_OPEN,
      payload: { isEditModalOpen, selectedDay }
    }
  },
  setSelectedDayOperations: ({
    selectedOperations
  }: {
    selectedOperations: OperationTypeValues[]
  }): SetSelectedDayOperations => {
    return {
      type: ModalEditDayOperationTypes.SET_SELECTED_DAYS_OPERATIONS,
      payload: { selectedOperations }
    }
  }
}

export default OperationDaysActionCreator
