import React from 'react'
import { Table, Tooltip } from 'antd'
import moment from '../../../moment'
import PropTypes from 'prop-types'
import ActionBarButton from '../../../components/ActionBarButton/ActionBarButton'
import ActionBar from '../../../components/ActionBar/ActionBar'
import { useHistory } from 'react-router'
import DeletedObjectStatus from './deletedObjectStatus'

const determinateIfValueChange = (objects, index, key) => {
  return objects[index + 1] !== undefined && objects[index][key] !== objects[index + 1][key]
}

const renderObjectIDStatus = (deletedReasons, objectChanges, record, index) => {
  const value = record.objectStatusId
  const isDeleted = record.deletedAt

  const objectStatusChanged = determinateIfValueChange(objectChanges, index, 'objectStatusId')

  if (isDeleted) {
    const isDeletedChanged =
      determinateIfValueChange(objectChanges, index, 'deletedAt') ||
      determinateIfValueChange(objectChanges, index, 'deletedDescription') ||
      objectStatusChanged

    const { objectDeletedReasonId, deletedDescription } = record
    const objectDeletedReason = deletedReasons.find(
      (deletedReason) => deletedReason.id === objectDeletedReasonId
    )?.reason

    const deletedObject = (
      <DeletedObjectStatus
        objectStatus={record.objectStatusId}
        deletedDescription={deletedDescription}
        objectDeletedReason={objectDeletedReason}
      />
    )
    return isDeletedChanged ? changeSpan(deletedObject) : deletedObject
  }
  return objectStatusChanged ? changeSpan(value) : value
}

const formatDate = (d) => moment(d).format('DD/MM/YY HH:mm').toLocaleString()
const changeSpan = (value) => <span style={{ color: 'red' }}>{value}</span>

const TableObjectChanges = (props) => {
  const { objectChanges, loading, pagination, handleChange, removeObjectReducer } = props

  const history = useHistory()

  const handleReturnButton = () => {
    history.goBack()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'objectId'
    },
    {
      title: 'ID Cliente',
      dataIndex: 'ownerId',
      align: 'center',
      render: (n, record, index) => {
        return determinateIfValueChange(objectChanges, index, 'ownerId') ? changeSpan(n) : n
      }
    },
    {
      title: 'Etiqueta',
      dataIndex: 'identificationCodes',
      align: 'center',
      render: (n, record, index) =>
        determinateIfValueChange(objectChanges, index, 'identificationCodes') ? changeSpan(n) : n
    },
    {
      title: 'Fecha de Cambio',
      dataIndex: 'createdAt',
      render: formatDate
    },
    {
      title: 'Ubicación Id',
      dataIndex: 'location',
      align: 'center',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'location') ? changeSpan(n) : n)
    },
    {
      title: 'GURU',
      dataIndex: 'user',
      align: 'center',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'user') ? changeSpan(n) : n)
    },
    {
      title: 'Objeto ID estado',
      dataIndex: 'objectStatusId',
      align: 'center',
      render: (value, record, index) =>
        renderObjectIDStatus(removeObjectReducer.deletedReasons, objectChanges, record, index)
    },
    {
      title: 'Situación objeto',
      dataIndex: 'objectSituation',
      align: 'center',
      render: (n, record, index) =>
        determinateIfValueChange(objectChanges, index, 'objectSituation') ? changeSpan(n) : n
    },
    {
      title: 'Producto ID',
      dataIndex: 'product',
      align: 'center',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'productId') ? changeSpan(n) : n)
    },
    {
      title: 'Descripción',
      align: 'center',
      dataIndex: 'description',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'description') ? changeSpan(n) : n)
    },
    {
      title: 'Alto [CM]',
      align: 'center',
      dataIndex: 'heightInCm',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'heightInCm') ? changeSpan(n) : n)
    },
    {
      title: 'Ancho [CM]',
      align: 'center',
      dataIndex: 'widthInCm',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'widthInCm') ? changeSpan(n) : n)
    },
    {
      title: 'Largo [CM]',
      align: 'center',
      dataIndex: 'lengthInCm',
      render: (n, record, index) => (determinateIfValueChange(objectChanges, index, 'lengthInCm') ? changeSpan(n) : n)
    },
    {
      title: 'Peso [Kg]',
      align: 'center',
      dataIndex: 'weightInGr',
      render: (n, record, index) =>
        determinateIfValueChange(objectChanges, index, 'weightInGr') ? changeSpan(n / 1000) : n / 1000
    },
    {
      title: 'Fuente',
      align: 'center',
      dataIndex: 'source',
      render: (n, record, index) => (
        <Tooltip title={n}>
          <div style={{ maxWidth: '15ch' }}>
            {determinateIfValueChange(objectChanges, index, 'source') ? changeSpan(n) : n}
          </div>
        </Tooltip>
      )
    }
  ]

  return (
    <div>
      <ActionBar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%'
          }}>
          <ActionBarButton action={handleReturnButton} description="Volver" icon="arrow-left" />
        </div>
      </ActionBar>
      <Table
        columns={columns}
        dataSource={objectChanges}
        loading={loading}
        pagination={pagination}
        style={{ overscrollX: 'auto' }}
        scroll={{ x: true }}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  )
}

TableObjectChanges.propTypes = {
  deletedReasons: PropTypes.array.isRequired,
  objectChanges: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired
}

export default TableObjectChanges
