import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

export function deleteInvitation(guestId: number): Promise<void> {
  return apiPrivate
    .delete(`${URL_BASE_USERS}/user-invites/${guestId}`)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
