import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Photo } from '../Operation/Removals/common'
import { ListDetailsParams } from '../Operation/Removals/details'

type Timestamps = {
  CreatedAt?: string
  UpdatedAt?: string
  DeletedAt?: string | null
}

export type UserRemoval = Timestamps & {
  id: string
  name: string
  countryId: string
  lastName: string
  phone: string
}

type ProductCountryDetail = {
  name: string
  countryId: string
  productId: number
}

type ProductDetail = {
  realId: number
  id: string
  description: string
  weightInGr: number
  lengthInCm: number
  widthInCm: number
  heightInCm: number
  volumeInCm3: number
  packagingCostInCents: number
  disassemblyCost: number
  prodByCountry: ProductCountryDetail
}

export type ObjectDetail = {
  id: string
  name: string
  product: ProductDetail
  photos: Photo[]
  volumeInCm3: number
  column: string
  packagingCostInCents: number
  disassemblyCost: number
}

export type RemovalObject = {
  objectId: number
  floorsByStairsCost: number
  packagingCost: number
  assemblyCost: number
  object: ObjectDetail
  photos: Photo
}

type CoreDetail = Timestamps & {
  countryId: string
  discountCode: string
  originalAddress: string
  transportDatetime: string
  transportCostInCents: number
  user: UserRemoval
  status: string
  additionalCostInCents: number
  additionalCostComment: string
  removalObjects: RemovalObject[]
  totalPercentageDiscount: number
  detail: null
}

type APIResponse = {
  id: number
  debtAmount: number
  modifyReasonID: number
  core: CoreDetail
  removalReasonId: number
  totalMetersCubic: number
  totalCmCubic: number
  totalExtraCosts: number
  tollCost: number
  minCost: number
}

export default function details(params: ListDetailsParams): Promise<CamelCasedPropertiesDeep<APIResponse>> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/removals/${params.id}`)
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) {
        throw new Error('Hubo un error al obtener los detalles de la devolución')
      }
      const camelCaseResponse = camelcaseKeys(response.data, { deep: true })
      return camelCaseResponse
    })
    .catch((error) => {
      throw error
    })
}
