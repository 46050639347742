import types from '../types/DeleteDiscount'

export default {
  deleteDiscount(id) {
    return (dispatch) => {
      dispatch({ type: types.DELETE_DISCOUNT_REQUEST, payload: { id } })
      dispatch({ type: types.DELETE_DISCOUNT_SUCCESS })
      /* return apiPrivate.get(URL_BASE_TRANSACTIONS + '/discounts/search', {params})
            .then(response => {

            })
            .catch(error => {
                handleErrorsWithAction(error, types.DELETE_DISCOUNT_FAILURE, dispatch)
            })
            */
    }
  },
  openModal(id, code) {
    return { type: types.OPEN_DELETE_MODAL, payload: { id, code } }
  },
  closeModal() {
    return { type: types.CLOSE_DELETE_MODAL }
  }
}
