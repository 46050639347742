import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { URL_BASE_TRIPS } from '../../../endpoints'
import { APIVehicle, Vehicle } from './common'
interface APIResponse {
  vehicles: APIVehicle[] | null
  total: number
}

type Response = {
  vehicles: Vehicle[]
  total: number
}

export type VehiclesListSortKey =
  | 'id'
  | 'max_capacity'
  | 'usable_capacity'
  | 'max_width'
  | 'max_length'
  | 'max_weight'
  | 'max_height'

export interface ListParams {
  providerId?: number
  format?: string
  limit?: number
  offset?: number
  column?: VehiclesListSortKey
  order?: SortDirection
  id?: number
}

const emptyCSVResponse = {
  vehicles: [],
  total: 0
}

export function list(params: ListParams): Promise<Response> {
  const { providerId, format, limit, offset, column, order, id } = params
  const snakeParams = snakecaseKeys({
    providerId,
    format,
    limit,
    offset,
    column,
    order,
    id
  })
  return apiPrivate
    .get(`${URL_BASE_TRIPS}/vehicles`, { params: snakeParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (format === 'csv') return emptyCSVResponse

      const camelResponse: Response = {
        total: response.data.total,
        vehicles: camelcaseKeys(response.data.vehicles || [], { deep: true })
      }

      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
