import { Moment } from 'moment'
import { DeleteOperationParams } from '../../../projectApi/Timeslot/OperationCalendar/SpecificDates/delete'
import { OperationTypeValues } from './common'

export enum GetOperationDatesTypes {
  GET_DATES_REQUEST = 'OPERATION_CALENDAR/DATES/GET_DATES_REQUEST',
  GET_DATES_SUCCESS = 'OPERATION_CALENDAR/DATES/GET_DATES_SUCCESS',
  GET_DATES_FAILURE = 'OPERATION_CALENDAR/DATES/GET_DATES_FAILURE'
}

export interface GetDatesRequest {
  type: typeof GetOperationDatesTypes.GET_DATES_REQUEST
}

export interface GetDatesSuccess {
  type: typeof GetOperationDatesTypes.GET_DATES_SUCCESS
  payload: {
    datesData: DatesData[]
  }
}

export interface GetDatesFailure {
  type: typeof GetOperationDatesTypes.GET_DATES_FAILURE
}

type GetOperationDatesActions = GetDatesRequest | GetDatesSuccess | GetDatesFailure

export enum CreateOperationDatesTypes {
  CREATE_DATE_REQUEST = 'OPERATION_CALENDAR/DATES/CREATE_DATE_REQUEST',
  CREATE_DATE_SUCCESS = 'OPERATION_CALENDAR/DATES/CREATE_DATE_SUCCESS',
  CREATE_DATE_FAILURE = 'OPERATION_CALENDAR/DATES/CREATE_DATE_FAILURE'
}

export interface CreateDateRequest {
  type: typeof CreateOperationDatesTypes.CREATE_DATE_REQUEST
}

export interface CreateDateSuccess {
  type: typeof CreateOperationDatesTypes.CREATE_DATE_SUCCESS
}

export interface CreateDateFailure {
  type: typeof CreateOperationDatesTypes.CREATE_DATE_FAILURE
}

type CreateOperationDatesActions = CreateDateRequest | CreateDateSuccess | CreateDateFailure

export enum DeleteOperationDatesTypes {
  DELETE_DATE_REQUEST = 'OPERATION_CALENDAR/DATES/DELETE_DATE_REQUEST',
  DELETE_DATE_SUCCESS = 'OPERATION_CALENDAR/DATES/DELETE_DATE_SUCCESS',
  DELETE_DATE_FAILURE = 'OPERATION_CALENDAR/DATES/DELETE_DATE_FAILURE'
}

export interface DeleteDateRequest {
  type: typeof DeleteOperationDatesTypes.DELETE_DATE_REQUEST
}

export interface DeleteDateSuccess {
  type: typeof DeleteOperationDatesTypes.DELETE_DATE_SUCCESS
}
export interface DeleteDateFailure {
  type: typeof DeleteOperationDatesTypes.DELETE_DATE_FAILURE
}

type DeleteOperationDatesActions = DeleteDateRequest | DeleteDateSuccess | DeleteDateFailure

export type DatesData = {
  id: number
  date: Moment
  operationType: OperationTypeValues
}

export interface OperationDatesState {
  datesData: DatesData[]
  fetchingDates: boolean
  postingDate: boolean
  error: string
  isCreateModalOpen: boolean
  selectedCreateDate: Moment | null
  selectedDeleteDate: DeleteOperationParams
  selectedType: string
  isDeleteModalOpen: boolean
  deletingDate: boolean
  selectedOperations: OperationTypeValues[]
}

export enum ModalCreateOperationDate {
  SET_CREATE_MODAL_OPEN = 'OPERATION_CALENDAR/DATES/SET_CREATE_MODAL_OPEN',
  SET_SELECTED_CREATE_DATE = 'OPERATION_CALENDAR/DATES/SET_CREATE_DATE'
}

export interface SetCreateModalOpen {
  type: typeof ModalCreateOperationDate.SET_CREATE_MODAL_OPEN
  payload: {
    isCreateModalOpen: boolean
  }
}

export interface SetSelectedCreateDate {
  type: typeof ModalCreateOperationDate.SET_SELECTED_CREATE_DATE
  payload: {
    selectedCreateDate: Moment | null
  }
}

type ModalCreateOperations = SetSelectedCreateDate | SetCreateModalOpen

export enum ModalDeleteOperationDate {
  SET_DELETE_MODAL_OPEN = 'OPERATION_CALENDAR/DATES/SET_DELETE_MODAL_OPEN',
  SET_SELECTED_DELETE_DATE = 'OPERATION_CALENDAR/DATES/SET_DATE'
}

export interface SetDeleteModalOpen {
  type: typeof ModalDeleteOperationDate.SET_DELETE_MODAL_OPEN
  payload: {
    isDeleteModalOpen: boolean
  }
}

export interface SetSelectedDeleteDate {
  type: typeof ModalDeleteOperationDate.SET_SELECTED_DELETE_DATE
  payload: {
    selectedDeleteDate: DeleteOperationParams
  }
}

type ModalDeleteOperations = SetSelectedDeleteDate | SetDeleteModalOpen

export type OperationDatesAction =
  | GetOperationDatesActions
  | CreateOperationDatesActions
  | DeleteOperationDatesActions
  | ModalDeleteOperations
  | ModalCreateOperations
