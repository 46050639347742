import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import { downloadFile } from '../../../utils/downloadFile'

const actions = {
  getProposalPDF:
    (params: { proposalId: number }): AppThunk =>
    async () => {
      const toast = setToastLoading('Descargando PDF, por favor espere...')
      try {
        const blob = await API.TransactionHandler.Proposals.getPDF(params)
        const fileName = `Cotización ${params.proposalId}.pdf`
        downloadFile(blob, fileName)
        setToastSuccessUpdate(toast, { render: 'PDF descargado con éxito' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al descargar PDF' })
      }
    }
}

export default actions
