import { Reducer } from 'redux'
import {
  GetShippersListTypes,
  SendCSVReportOfShippersTypes,
  ShippersTableActions,
  ShippersTableGeneralTypes,
  ShippersTableStateType
} from '../types/shippersTable'

export const PAGE_SIZE = 10

const initialState: ShippersTableStateType = {
  shippers: [],
  fetchingShippers: false,
  sendingCSVReport: false,
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0
  },
  searchFilters: [
    { key: 'id', text: '' },
    { key: 'user_id', text: '' }
  ]
}

const shippersTableReducer: Reducer<ShippersTableStateType, ShippersTableActions> = (
  state = initialState,
  action
): ShippersTableStateType => {
  switch (action.type) {
    case ShippersTableGeneralTypes.CLEAN_TABLE_STATE:
      return initialState
    case GetShippersListTypes.GET_SHIPPERS_REQUEST:
      return {
        ...state,
        fetchingShippers: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage
        },
        searchFilters: action.payload.searchFilters || state.searchFilters
      }
    case GetShippersListTypes.GET_SHIPPERS_SUCCESS:
      return {
        ...state,
        fetchingShippers: false,
        shippers: action.payload.shippers,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }
    case GetShippersListTypes.GET_SHIPPERS_FAILURE:
      return {
        ...state,
        fetchingShippers: false
      }
    case SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_REQUEST:
      return {
        ...state,
        sendingCSVReport: true
      }
    case SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_SUCCESS:
    case SendCSVReportOfShippersTypes.SEND_CSV_REPORT_OF_SHIPPERS_FAILURE:
      return {
        ...state,
        sendingCSVReport: false
      }
    default:
      return state
  }
}

export default shippersTableReducer
