import { Reducer } from 'redux'
import {
  ABMDepositsActions,
  ABMDepositsModalState,
  CreateDepositTypes,
  DeleteDepositTypes,
  EditDepositTypes,
  GetAddressDetailsTypes,
  SettersDepositsModalTypes
} from '../types/ABMDeposits'
import { CountryName } from '../../../components/CountrySelector/constants/constants'
import { fullCountryToCountryCode } from '../../../components/CountrySelector/utils/utils'

const initialState: ABMDepositsModalState = {
  editMode: false,
  modalVisible: false,
  deleteModalVisible: false,
  changeAddress: false,
  creatingDeposit: false,
  updatingDeposit: false,
  deletingDeposit: false,
  disabledDeleteButton: false,
  fetchingAddressDetails: false,
  depositId: 0,
  depositName: '',
  selectedDeposit: undefined,
  depositAddressId: undefined,
  addressDetails: {},
  depositCountryCode: '',
  addressError: ''
}

export const ABMDepositModalReducer: Reducer<ABMDepositsModalState, ABMDepositsActions> = (
  state: ABMDepositsModalState = initialState,
  action
): ABMDepositsModalState => {
  switch (action.type) {
    case SettersDepositsModalTypes.SET_MODAL_VISIBILITY: {
      const { modalVisible, editMode } = action.payload

      return modalVisible
        ? {
            ...state,
            editMode: editMode ?? false,
            modalVisible
          }
        : initialState
    }
    case SettersDepositsModalTypes.SET_DELETE_MODAL_VISIBILITY: {
      const { deleteModalVisibility } = action.payload
      return deleteModalVisibility
        ? {
            ...state,
            deleteModalVisible: deleteModalVisibility
          }
        : initialState
    }
    case SettersDepositsModalTypes.SET_FORM_FIELD: {
      const { field, value } = action.payload

      return {
        ...state,
        addressDetails: {
          ...state.addressDetails,
          [field]: value
        }
      }
    }
    case SettersDepositsModalTypes.SET_DEPOSIT_NAME:
      return {
        ...state,
        depositName: action.payload.depositName
      }
    case SettersDepositsModalTypes.SET_DEPOSIT_ADDRESS: {
      const { country, ...addressDetails } = action.payload.selectedAddress
      return {
        ...state,
        depositAddressId: undefined,
        depositCountryCode: fullCountryToCountryCode(country || CountryName.ARGENTINA),
        addressDetails: {
          ...addressDetails,
          addressComment: ''
        }
      }
    }
    case SettersDepositsModalTypes.SET_CHANGE_ADDRESS:
      return {
        ...state,
        changeAddress: action.payload.changeAddress
      }
    case SettersDepositsModalTypes.SET_ADDRESS_ERROR:
      return {
        ...state,
        addressError: action.payload.errorMessage
      }
    case SettersDepositsModalTypes.SET_SELECTED_DEPOSIT: {
      const selectedDeposit = action.payload.selectedDeposit
      return {
        ...state,
        selectedDeposit,
        depositId: selectedDeposit.id,
        depositName: selectedDeposit.name,
        depositCountryCode: selectedDeposit.countryId,
        depositAddressId: selectedDeposit.addressId
      }
    }
    case CreateDepositTypes.CREATE_DEPOSIT_REQUEST:
      return {
        ...state,
        creatingDeposit: true
      }
    case CreateDepositTypes.CREATE_DEPOSIT_SUCCESS:
    case CreateDepositTypes.CREATE_DEPOSIT_FAILURE:
      return {
        ...state,
        creatingDeposit: false
      }
    case EditDepositTypes.EDIT_DEPOSIT_REQUEST:
      return {
        ...state,
        updatingDeposit: true
      }
    case EditDepositTypes.EDIT_DEPOSIT_SUCCESS:
    case EditDepositTypes.EDIT_DEPOSIT_FAILURE:
      return {
        ...state,
        updatingDeposit: false
      }
    case DeleteDepositTypes.DELETE_DEPOSIT_REQUEST:
      return {
        ...state,
        deletingDeposit: true
      }
    case DeleteDepositTypes.DELETE_DEPOSIT_SUCCESS:
      return {
        ...state,
        deletingDeposit: false
      }
    case DeleteDepositTypes.DELETE_DEPOSIT_FAILURE:
      return {
        ...state,
        deletingDeposit: false,
        disabledDeleteButton: true
      }
    case GetAddressDetailsTypes.GET_ADDRESS_DETAILS_REQUEST:
      return {
        ...state,
        fetchingAddressDetails: true
      }
    case GetAddressDetailsTypes.GET_ADDRESS_DETAILS_SUCCESS: {
      const { addressId, ...addressDetails } = action.payload.addressDetails

      return {
        ...state,
        depositAddressId: addressId,
        addressDetails,
        fetchingAddressDetails: false
      }
    }
    case GetAddressDetailsTypes.GET_ADDRESS_DETAILS_FAILURE:
      return {
        ...state,
        fetchingAddressDetails: false
      }
    default:
      return state
  }
}

export default ABMDepositModalReducer
