import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export interface APILocation {
  id: number
  description: string
  deposit_id: number
  active: boolean
  code: string
  type: string
  quantity_objects: number
  max_length_in_cm: number
  max_width_in_cm: number
  max_height_in_cm: number
  volume_in_cm: number
  weight_in_gr: number
  max_volume_in_cm: number
  max_weight_in_gr: number
  occupancy_rate: number
  load_rate: number
}

export interface APIResponse {
  locations: APILocation[] | null
  total: number
}

export type Location = CamelCasedPropertiesDeep<APILocation>

interface Response {
  locations: Location[]
  total: number
}

export interface LocationListParams {
  depositId?: number
  ownerId?: number
  id?: string
  limit: number
  offset: number
  order?: 'asc' | 'desc'
  column?: string
}

export function list(params: LocationListParams): Promise<Response> {
  const formatedParams = snakecaseKeys({ ...params })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/locations/v2`, { params: formatedParams })
    .then((response: AxiosResponse<APIResponse>) => {
      return {
        locations: camelcaseKeys(response.data.locations || []),
        total: response.data.total
      }
    })
    .catch((error) => {
      throw error
    })
}
