import React, { ReactNode, useEffect } from 'react'

import styles from './NewEditAttribute.module.scss'
import { Button as ButtonCustom } from '../../../components/Button/Button'
import {
  AllowedCountriesCodes,
  COUNTRY_OPTIONS_SELECT,
  DATA_TYPE_SELECT,
  DEFAULT_TO_OPEN_OPTIONS,
  NewEditAttributeState
} from '../types/NewEditAttribute'
import NewEditAttributeActionCreators from '../actionCreators/NewEditAttribute'
import InputGroup from '../../../components/InputGroup'
import { TextField } from '../../../components/TextField/TextField'
import clsx from 'clsx'
import { Button, Checkbox, Tooltip } from 'antd'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { useHistory } from 'react-router'
import Select from '../../../components/Select'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

interface NewEditAttributeProps {
  newEditAttributeState: NewEditAttributeState
  newEditAttributeActions: typeof NewEditAttributeActionCreators
  editMode: boolean
}

export const initialValueOption = {
  id: null,
  dataType: '',
  value: ''
}

export const NewEditAttribute: React.FC<NewEditAttributeProps> = ({
  newEditAttributeState,
  newEditAttributeActions,
  editMode
}) => {
  const { unit, countries, dataType, isVisible, name, loading } = newEditAttributeState
  const {
    setVisible,
    setDataType,
    setName,
    setUnit,
    setCountryLabel,
    setCountryAddOption,
    createNewAttribute,
    setCountryDataType,
    setCountryDataTypeValue,
    editAttribute,
    setCountryRemoveOption,
    deleteCountryOption,
    clearState
  } = newEditAttributeActions
  const router = useHistory()

  const handleCreateNewAttribute = async () => {
    let result = null
    if (editMode) {
      result = await editAttribute(newEditAttributeState)
    } else {
      result = await createNewAttribute(newEditAttributeState)
    }
    // @ts-ignore
    if (result) {
      router.push('/attributes')
      clearState()
    }
  }

  useEffect(() => {
    checkAmountOfOptions()
  }, [dataType])

  const checkAmountOfOptions = () => {
    const REQUIRED_AMOUNT_OF_OPTIONS = 2
    const isAmountRequiredCorrect = Object.keys(countries).every((x) => {
      return countries[x as AllowedCountriesCodes].options.length >= 2
    })

    if (!isAmountRequiredCorrect) {
      Object.keys(CountryIdCode).forEach((x) => {
        const country = CountryIdCode[x as keyof typeof CountryIdCode]
        if (CountryIdCode[x as keyof typeof CountryIdCode] !== '') {
          for (let i = 0; i < REQUIRED_AMOUNT_OF_OPTIONS; i++) {
            setCountryAddOption(country as AllowedCountriesCodes, initialValueOption)
          }
        }
      })
    }
  }

  const handleGoBack = () => {
    router.goBack()
    clearState()
  }

  const handleAddOption = (country: AllowedCountriesCodes) => {
    setCountryAddOption(country, initialValueOption)
  }

  const isReadyByCountry = () => {
    const isSomeOption = Object.keys(countries).every((x) => {
      const labelCountry =
        countries[x as AllowedCountriesCodes].label.length >= 3 ||
        countries[x as AllowedCountriesCodes].label.length > 40
      if (dataType === DEFAULT_TO_OPEN_OPTIONS) {
        return (
          countries[x as AllowedCountriesCodes].options.every((y) => y.value !== '' && y.dataType !== '') &&
          labelCountry
        )
      } else {
        return labelCountry
      }
    })
    return isSomeOption
  }

  const isNotReadyToSaveOrEdit = !name || !dataType || !isReadyByCountry() || name.length > 40
  const regexText = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]+$/
  const regexNum = /^[\d\s.,]+$/

  const handleChangeTextName = (value: string, valueSetter: (value: string) => void) => {
    if (regexText.test(value) || value === '') valueSetter(value)
  }

  const handleChangeTextNameWithCountry = (
    value: string,
    country: AllowedCountriesCodes,
    valueSetter: (country: AllowedCountriesCodes, countryName: string) => void
  ) => {
    if (regexText.test(value) || value === '') valueSetter(country, value)
  }

  return (
    <div className={styles.container}>
      <>
        <h1 className={styles.title}>Ingresá los datos del atributo</h1>
        <p className={styles.subtitle}>Esta información es única para todos los países</p>

        <div className={styles.groupContainer}>
          <InputGroup title="Características" style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Row>
              <div className={styles.defaultWidth}>
                <TextField
                  label="Nombre*"
                  error={name.length > 40}
                  inputProps={{ maxLength: 40 }}
                  errorText="Límite de 40 caracteres"
                  onChange={(value) => handleChangeTextName(value, setName)}
                  value={name}
                />
              </div>
              <div className={styles.defaultWidth}>
                <Select
                  onSelect={(value) => setDataType(value.value)}
                  onDelete={() => setDataType('')}
                  selected={DATA_TYPE_SELECT.find((x) => x.value === dataType)}
                  multiple={false}
                  options={DATA_TYPE_SELECT}
                  placeholder="Seleccione el tipo de dato*"
                  className={styles.select}
                />
              </div>
            </Row>
            <Row>
              <div className={styles.defaultWidth}>
                <TextField
                  label="Unidad"
                  error={unit.length > 20}
                  errorText="Límite de 20 caracteres"
                  onChange={(value) => handleChangeTextName(value, setUnit)}
                  value={unit}
                />
              </div>
              <div className={styles.defaultWidth}>
                <Tooltip
                  title={
                    'Si está visible lo podrán ver los clientes, caso contrario, solamente lo podrá ver SpaceGuru'
                  }>
                  <Checkbox
                    className={styles.search}
                    onChange={(e) => setVisible(e.target.checked)}
                    checked={isVisible}>
                    ¿Es visible?
                  </Checkbox>
                </Tooltip>
              </div>
            </Row>
          </InputGroup>
          {Object.keys(countries).map((countryCode, index) => {
            const country = countries[countryCode as AllowedCountriesCodes]
            const countryInfo = useCountryInfo(countryCode)
            let letSumToDelete = 0
            return (
              <InputGroup
                key={index + countryCode}
                country={countryInfo}
                title={countryInfo.label}
                style={{ paddingLeft: 16, paddingRight: 16 }}>
                <Row>
                  <div className={styles.defaultWidth}>
                    <TextField
                      label="Nombre*"
                      inputProps={{ maxLength: 40 }}
                      error={country.label.length > 40}
                      errorText="Límite de 40 caracteres"
                      onChange={(value) =>
                        handleChangeTextNameWithCountry(value, countryCode as AllowedCountriesCodes, setCountryLabel)
                      }
                      value={country.label}
                    />
                  </div>
                </Row>
                {dataType === DEFAULT_TO_OPEN_OPTIONS ? (
                  <InputGroup title={'Opciones'} style={{ paddingLeft: 16, paddingRight: 16 }}>
                    {country?.options?.map((countryOption, index) => {
                      if (countryOption?.deleted === true) return false
                      letSumToDelete++
                      return (
                        <Row key={'datatype-country-' + countryCode + index}>
                          <div>
                            <Select
                              onSelect={(value) => {
                                setCountryDataType(countryCode as AllowedCountriesCodes, index, value.value)
                                setCountryDataTypeValue(countryCode as AllowedCountriesCodes, index, '')
                              }}
                              onDelete={() => setCountryDataType(countryCode as AllowedCountriesCodes, index, '')}
                              selected={COUNTRY_OPTIONS_SELECT.find((x) => x.value === countryOption.dataType)}
                              multiple={false}
                              options={COUNTRY_OPTIONS_SELECT}
                              placeholder="Seleccione el tipo de dato"
                              className={styles.select}
                            />
                          </div>
                          <div>
                            <TextField
                              label="Valor"
                              disabled={countryOption.dataType === ''}
                              onChange={(value) => {
                                if (countryOption.dataType === 'string') {
                                  if (regexText.test(value) || value === '') {
                                    setCountryDataTypeValue(countryCode as AllowedCountriesCodes, index, value)
                                  }
                                } else {
                                  if (regexNum.test(value) || value === '') {
                                    setCountryDataTypeValue(countryCode as AllowedCountriesCodes, index, value)
                                  }
                                }
                              }}
                              value={countryOption.value}
                            />
                          </div>
                          {letSumToDelete > 2 && (
                            <Button
                              onClick={() => {
                                if (countryOption.id) {
                                  deleteCountryOption(countryCode as AllowedCountriesCodes, countryOption.id)
                                } else {
                                  setCountryRemoveOption(countryCode as AllowedCountriesCodes, index)
                                }
                              }}
                              loading={loading}
                              icon="minus"
                              type="primary"
                              size="large"
                            />
                          )}
                        </Row>
                      )
                    })}
                    <div className={styles.plusContainer}>
                      <Button
                        onClick={() => handleAddOption(countryCode as AllowedCountriesCodes)}
                        loading={loading}
                        icon="plus"
                        type="primary"
                        size="large"
                      />
                    </div>
                  </InputGroup>
                ) : (
                  <></>
                )}
              </InputGroup>
            )
          })}
        </div>
      </>
      <div className={styles.containerFooter}>
        <ButtonCustom
          label={'Cancelar'}
          mode="outlined"
          className={styles.cancelButton}
          loading={loading}
          onClick={() => handleGoBack()}
        />
        <ButtonCustom
          label={editMode ? 'Editar atributo' : 'Crear atributo'}
          className={styles.button}
          loading={loading}
          disabled={isNotReadyToSaveOrEdit}
          onClick={() => handleCreateNewAttribute()}
        />
      </div>
    </div>
  )
}

interface RowProps {
  children: ReactNode
  className?: string
}

export const Row: React.FC<RowProps> = ({ children, className }) => {
  return <div className={clsx(styles.row, className && className)}>{children}</div>
}
