import React from 'react'
import { Modal } from 'antd'
import { InfoCard } from '../InfoDiscountCard'
import { Discount } from '../../../../../projectApi/Timeslot/Discounts/SpecificDates/list'

type DeleteDiscountModalProps = {
  selectedDiscount: Discount | null
  deletingDiscount: boolean
  deleteModalVisible: boolean
  setDeleteModalVisibility: (modalVisible: boolean) => void
  handleDeleteDiscount: () => void
}

const DeleteDiscountModal = ({
  selectedDiscount,
  deletingDiscount,
  deleteModalVisible,
  setDeleteModalVisibility,
  handleDeleteDiscount
}: DeleteDiscountModalProps) => {
  return (
    <Modal
      width={'max-content'}
      onCancel={() => setDeleteModalVisibility(false)}
      title={'Eliminar descuento para una fecha específica'}
      confirmLoading={deletingDiscount}
      visible={deleteModalVisible}
      okText={'Eliminar'}
      cancelText="Cancelar"
      onOk={handleDeleteDiscount}
      destroyOnClose>
      <InfoCard
        id={selectedDiscount?.id}
        day={selectedDiscount?.date?.format('DD-MM-YYYY')}
        discountRate={selectedDiscount?.discount}
      />
      <h4 style={{ fontSize: 18, textAlign: 'center' }}>¿Está seguro que desea eliminar este descuento?</h4>
    </Modal>
  )
}

export default DeleteDiscountModal
