import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  CommonDestination,
  GET_TRIP_TRANSACTIONS_FAILURE,
  GET_TRIP_TRANSACTIONS_REQUEST,
  GET_TRIP_TRANSACTIONS_SUCCESS,
  GetTripTransactionsRequest,
  GetTripTransactionsSuccess
} from '../types/common'

const TripsCommonActionCreators = {
  getTripTransactions:
    ({ tripId, destination }: { tripId: number; destination: CommonDestination }): AppThunk =>
    async (dispatch) => {
      const request: GetTripTransactionsRequest = {
        type: GET_TRIP_TRANSACTIONS_REQUEST,
        payload: {
          destination,
          tripId
        }
      }

      dispatch(request)

      try {
        const { transactions } = await API.TransactionHandler.Transaction.TripTransactions.list({ tripId })

        const success: GetTripTransactionsSuccess = {
          type: GET_TRIP_TRANSACTIONS_SUCCESS,
          payload: {
            destination,
            transactions,
            tripId
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_TRIP_TRANSACTIONS_FAILURE, dispatch, { tripId, destination })
      }
    }
}

export default TripsCommonActionCreators
