import { find, propEq, propOr } from 'ramda'

export const getOptionValue = propOr('', 'value')

export const getFormattedUserOptions = (users) =>
  users.map((user) => ({
    value: user.RealID,
    label: user.RealID + ' - ' + user.Name + ' ' + user.LastName
  }))

export const getUserByID = (userID, users) => find(propEq('RealID', userID))(users)
