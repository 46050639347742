import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, DatePicker, Modal, Row } from 'antd'
import { formatDocumentTypeOptions, getOptionValue } from '../Users.selectors'
import { genders, phoneTypes, validAreaCodes } from '../Users.constants'
import InputGroup from '../../../components/InputGroup'
import locale from 'antd/lib/date-picker/locale/es_ES'
import { isEmpty } from 'ramda'
import MaskedInput from 'antd-mask-input'
import validateEmail from '../../../utils/validateEmail'
import InputFieldWithError from '../../../components/InputFieldWithError/inputFieldWithError'
import moment from '../../../moment'
import './newUserModalStyles.scss'
import Cellphone from './cellphone'
import Address from '../../../components/Address/container'
import { getAstrologicalSign } from '../utils/getAstrologicalSign'
import { areRequiredFieldsFulfilled } from '../utils/validateFields'
import LoadingScreenInformation from '../../../components/LoadingScreenInformation'
import { CountryIdCode, CountryPhoneCode } from '../../../components/CountrySelector/constants/constants'
import { formatCountryOptions } from '../../../components/CountrySelector/component/utils'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import Select from '../../../components/Select'

const styles = {
  modal: {
    maxWidth: 700
  },
  modalContainer: { position: 'relative' },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto',
    position: 'relative'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  errorFieldLabel: {
    color: 'red',
    marginBottom: 0
  },
  errorField: {
    borderColor: 'red !important'
  },
  select: {
    width: '100%'
  },
  age: {
    marginLeft: 8
  },
  astrologicalSign: {
    marginBottom: 0,
    marginTop: 8
  },
  loadingProposalContainer: {
    zIndex: 2,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingProposalText: {
    fontSize: 16,
    marginTop: 15
  },
  actualPhone: {
    margin: 0,
    display: 'inline-block'
  },
  buttonModifyPhone: {
    float: 'right'
  }
}

const getModalOptions = (editMode, byProposal, createNewUser, editUser, closeModal) =>
  editMode
    ? {
        title: 'Editar usuario',
        buttonLabel: 'Modificar',
        buttonAction: editUser
      }
    : {
        title: byProposal ? 'Completar datos del usuario' : 'Crear nuevo usuario',
        buttonLabel: byProposal ? 'Continuar' : 'Crear',
        buttonAction: byProposal ? closeModal : createNewUser
      }

function normalizeString(string) {
  const words = string.trim().split(' ')

  return words
    .map((word) => {
      if (isEmpty(word)) return ''
      return word[0].toUpperCase() + word.substring(1).toLowerCase()
    })
    .join(' ')
}

const checkAreaCode =
  (setErrorAreaCode, alternative = false) =>
  (areaCode) => {
    setErrorAreaCode(
      validAreaCodes.some((vAC) => vAC === areaCode) || (alternative && areaCode.length === 0) ? '' : 'Area inválida'
    )
  }
const checkPhoneBody =
  (setErrorPhoneBody, setPhone, alternative = false) =>
  (countryCode, areaCode, phoneBody) => {
    const phoneLength = areaCode.length + phoneBody.length

    if (alternative && phoneLength === 0) {
      setErrorPhoneBody('')
      return
    }
    if (countryCode === CountryPhoneCode.SPAIN) {
      if (phoneLength !== 9) {
        setErrorPhoneBody(`El número debe tener 9 dígitos`)
        return
      }
    }
    if (countryCode === CountryPhoneCode.ARGENTINA) {
      if (phoneLength !== 10) {
        setErrorPhoneBody(`El número debe tener ${10 - areaCode.length} dígitos`)
        return
      }
    } else {
      if (!/^\d+$/.test(phoneBody)) {
        setErrorPhoneBody('Número incorrecto')
        return
      }
    }
    setErrorPhoneBody('')

    const fullPhone = `${countryCode} ${areaCode ? `${areaCode} ` : ''}${phoneBody}`
    setPhone(fullPhone)
  }

const NewUserModal = (props) => {
  const { byProposal, proposals, identificationTypes } = props
  const {
    open,
    user,
    loading,
    error,
    editMode,
    loadingEmail,
    loadingDNI,
    setName,
    setLastName,
    setPassword,
    setEmail,
    setDocumentType,
    setLandline,
    setPhone,
    setCountryPhoneCode,
    setCountryAlternativePhoneCode,
    setDNI,
    setCountry,
    setDateBirth,
    setGender,
    createNewUser,
    setCreateModalOpen,
    editUser,
    setRole,
    roles,
    verifyIfDNIExists,
    verifyIfEmailExists,
    address,
    errorAddress,
    setErrorAddress,
    countrySelectorState
  } = props
  const {
    name,
    lastName,
    password,
    email,
    landline,
    phone,
    countryPhoneCode,
    countryAlternativePhoneCode,
    docType,
    country,
    dni,
    dateBirth,
    gender,
    roleID,
    actualPhone,
    actualLandline,
    createModalOpen,
    address: addressUser
  } = user
  const { addressGoogle } = address
  const { countries, countrySelected } = countrySelectorState

  const closeModal = () => {
    setCreateModalOpen(false, byProposal)
    cleanEditHeadLine()
    cleanEditPhone()
  }

  const { title, buttonLabel, buttonAction } = getModalOptions(
    editMode,
    byProposal,
    createNewUser,
    editUser,
    closeModal
  )

  const emailRef = useRef(null)
  const dniRef = useRef(null)

  const [errorEmail, setErrorEmail] = useState('')
  const [errorDNI, setErrorDNI] = useState('')
  const [emailAvailable, setEmailAvailable] = useState('')
  const [dniAvailable, setDniAvailable] = useState('')
  const [errorGender, setErrorGender] = useState('')
  const [errorName, setErrorName] = useState('')
  const [errorLastName, setErrorLastName] = useState('')
  const [errorDateBirth, setErrorDatebirth] = useState('')

  const [errorCellPhone, setErrorCellPhone] = useState('')
  const [errorAreaCode, setErrorAreaCode] = useState('')
  const [errorPhoneBody, setErrorPhoneBody] = useState('')

  const [phoneType, setPhoneType] = useState('landline')

  const [errorAreaCodeAlternative, setErrorAreaCodeAlternative] = useState('')
  const [errorPhoneBodyAlternative, setErrorPhoneBodyAlternative] = useState('')
  const [errorLandline, setErrorLandline] = useState('')
  const [showEditPhone, setShowEditPhone] = useState('none')
  const [showEditLandLine, setShowEditLandLine] = useState('none')
  const [reset, setReset] = useState(false)
  const [resetAlternativeCellPhone, setResetAlternativeCellPhone] = useState(false)

  const cleanErrors = () => {
    setErrorEmail('')
    setErrorDNI('')
    setErrorGender('')
    setErrorName('')
    setErrorLastName('')
    setErrorCellPhone('')
    setErrorLandline('')
    setErrorAddress('')
    setErrorDatebirth('')
    setErrorAreaCode('')
    setErrorPhoneBody('')
    setErrorAreaCodeAlternative('')
    setErrorPhoneBodyAlternative('')
  }

  useEffect(() => {
    if (open) {
      cleanErrors()
      setPhoneType('landline')
      setErrorAreaCode('')
      setErrorPhoneBody('')
      setErrorAreaCodeAlternative('')
      setErrorPhoneBodyAlternative('')
      cleanEditHeadLine()
      cleanEditPhone()
    }
  }, [open])

  const documentType = identificationTypes.find((identification) => identification.id === docType)

  useEffect(() => {
    setEmailAvailable(props.errorEmail ? 'Email disponible' : '')
  }, [props.errorEmail])

  useEffect(() => {
    setDniAvailable(props.errorDNI ? `${documentType.type} disponible` : '')
  }, [props.errorDNI])

  useEffect(() => {
    setErrorDNI(props.dniAlreadyExists ? `${documentType.type} en uso` : '')
  }, [props.dniAlreadyExists])

  const countryOptions = formatCountryOptions(countries, 'code')

  const optionFlag = (option) => {
    return option.customLabel
  }

  useEffect(() => {
    if (!editMode) {
      countrySelected?.code === '' ? setCountry(CountryIdCode.ARGENTINA) : setCountry(countrySelected?.code)
    }
  }, [open])

  const rolesOptions = useMemo(
    () =>
      roles.map((role) => ({
        value: role.id,
        label: role.description
      })),
    [roles]
  )
  const selectedRole = useMemo(() => rolesOptions.find((option) => option.value === roleID), [rolesOptions, roleID])

  const checkEmail = async () => {
    if (validateEmail(email)) {
      setErrorEmail('')
      const emailExists = await verifyIfEmailExists(email, byProposal)
      if (emailExists) {
        cleanErrors()
      } else {
        if (dniRef && dniRef.current) dniRef.current.input.focus()
      }
    } else {
      setErrorEmail('Email inválido')
    }
  }

  const isValidDocument = (identificationType) => {
    const docType = identificationTypes.find((identification) => identification.id === identificationType)
    if (!docType) return { validate: false, errorMessage: 'Tipo de DNI inválido' }
    const { rules } = docType
    const { min, max, input_type } = rules

    const minMax = `${min},${max}`

    const typeRegExp = {
      numeric: () => '0-9',
      text: () => 'a-zA-Z0-9'
    }

    const typeRequired = input_type === 'numeric' ? 'números' : 'caracteres'

    const validDocument = new RegExp(`^[${typeRegExp[input_type]}]{${minMax}}$`)

    if (validDocument.test(dni)) {
      return {
        validate: true,
        errorMessage: null
      }
    } else {
      const error =
        min !== max
          ? `El identificador debe contener entre ${min} o ${max} ${typeRequired}`
          : `El identificador debe contener ${min} ${typeRequired}`

      return {
        validate: false,
        errorMessage: error
      }
    }
  }

  const isValidName = ({ name, setValue, setError }) => {
    const string = normalizeString(name)
    const RegExrName = /^[A-ZÁÉÍÓÚ]{1}[a-z-A-Zñáéíóú\s]*$/

    if (RegExrName.test(string)) {
      setValue(string)
      setError('')
    } else {
      setError('Este casillero solo permite letras.')
    }
  }

  const checkDNI = async (identificationType) => {
    const { validate, errorMessage } = isValidDocument(identificationType)

    if (validate) {
      setErrorDNI('')
      const dniExists = await verifyIfDNIExists(identificationType, dni, byProposal)
      if (dniExists) {
        cleanErrors()
      }
    } else {
      setErrorDNI(errorMessage)
      setDniAvailable('')
    }
  }

  const handleShowEditPhone = () => {
    if (showEditPhone === 'none') {
      setShowEditPhone('')
      setReset(false)
    } else {
      cleanEditPhone()
    }
  }

  const cleanEditPhone = () => {
    setShowEditPhone('none')
    setReset(true)
    setErrorAreaCode('')
    setErrorPhoneBody('')
  }

  const handleLandLine = () => {
    setLandline('')
    if (showEditLandLine === 'none') {
      setShowEditLandLine('')
      setResetAlternativeCellPhone(false)
    } else {
      cleanEditHeadLine()
    }
  }

  const cleanEditHeadLine = () => {
    setShowEditLandLine('none')
    setErrorLandline('')
    setErrorAreaCodeAlternative('')
    setErrorPhoneBodyAlternative('')
    setResetAlternativeCellPhone(true)
  }

  const checkLandline = () => setErrorLandline(landline.includes('_') ? 'Teléfono invalido' : '')

  const emailAndDNINotOk = isEmpty(dniAvailable) || isEmpty(emailAvailable)
  const remainingFieldsAvailability = emailAndDNINotOk && !editMode

  const addressValidation = !byProposal && errorAddress
  const okDisabled =
    loading ||
    proposals.loadingProposals ||
    errorEmail ||
    errorDNI ||
    addressValidation ||
    errorCellPhone ||
    errorLandline ||
    errorName ||
    errorLastName ||
    errorAreaCode ||
    errorPhoneBody ||
    errorAreaCodeAlternative ||
    errorPhoneBodyAlternative

  const onOk = () => {
    const requiredFields = [
      { value: email, errorFn: setErrorEmail },
      { value: dni, errorFn: setErrorDNI },
      { value: name, errorFn: setErrorName },
      { value: lastName, errorFn: setErrorLastName },
      { value: phone, errorFn: setErrorCellPhone },
      { value: dateBirth, errorFn: setErrorDatebirth },
      { value: gender, errorFn: setErrorGender },
      ...(!byProposal ? [{ value: addressGoogle, errorFn: setErrorAddress }] : [])
    ]
    if (areRequiredFieldsFulfilled(requiredFields)) {
      buttonAction({ ...user, address }, byProposal)
    }
  }

  const onChangeDNI = (e) => {
    const { rules } = documentType
    const value = e.target.value
    setErrorDNI('')
    setDniAvailable('')
    if (value.length > rules.max) return
    setPassword(value)
    setDNI(value)
  }

  const onCellphoneBodyBlur = (countryCode, areaCode, phoneBody) => {
    setErrorCellPhone('')
    checkPhoneBody(setErrorPhoneBody, setPhone, false)(countryCode, areaCode, phoneBody)
  }

  const now = moment()

  const dniLabel = `Número de ${documentType?.type || 'DNI'}`

  const userCountry = useCountryInfo(country, 'fullName').code

  const checkCountryLabel = (value) => {
    return value.length > 3 ? userCountry : value
  }
  const userCountrySelected = useMemo(
    () => countryOptions.find((countryOption) => countryOption.value === checkCountryLabel(country)),
    [countryOptions, country]
  )

  const documentTypeOptions = useMemo(() => formatDocumentTypeOptions(identificationTypes), [identificationTypes])
  const selectedDocumentType = useMemo(
    () => documentTypeOptions.find((documentTypeOption) => documentTypeOption.value === docType),
    [documentTypeOptions, docType]
  )

  const selectedGender = useMemo(() => genders.find((genderOption) => genderOption.value === gender), [gender])
  const selectedPhone = useMemo(() => phoneTypes.find((phoneOption) => phoneOption.value === phoneType), [phoneType])

  return (
    <Modal
      width={'80%'}
      title={title}
      visible={open}
      closable={!loading && !byProposal}
      maskClosable={!loading && !byProposal}
      keyboard={false}
      onOk={onOk}
      onCancel={closeModal}
      cancelButtonProps={{
        disabled: loading,
        ...(byProposal && { style: { display: 'none' } })
      }}
      okButtonProps={{
        loading,
        disabled: okDisabled
      }}
      okText={buttonLabel}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <div style={styles.modalContainer}>
        {proposals.loadingProposals && (
          <LoadingScreenInformation description="Cargando datos del prospecto, por favor espere..." />
        )}
        <Row gutter={[16, 16]} style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
          <Col sm={12} xs={24}>
            <InputGroup title="Cuenta">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Select
                    wrapperStyle={styles.select}
                    name="userCountry"
                    selected={userCountrySelected}
                    options={countryOptions}
                    optionRenderer={optionFlag}
                    onSelect={(option) => {
                      const value = getOptionValue(option)
                      setCountry(value)
                    }}
                    disabled={loadingEmail || editMode}
                  />
                </Col>
                <Col span={24}>
                  <InputFieldWithError
                    ref={emailRef}
                    label="Email"
                    value={email}
                    disabled={editMode || loadingDNI}
                    className="disabledFields"
                    onChange={(e) => {
                      setErrorEmail('')
                      setDniAvailable('')
                      setEmail(e.target.value)
                    }}
                    onBlur={checkEmail}
                    error={errorEmail}
                    loading={loadingEmail}
                    successMessage={emailAvailable}
                    required
                  />
                </Col>
                <Col span={12}>
                  <p style={styles.inputTitle}>Tipo de doc.*</p>
                  <Select
                    wrapperStyle={styles.select}
                    name="documentType"
                    placeholder="Tipo de doc.*"
                    selected={selectedDocumentType}
                    options={documentTypeOptions}
                    onSelect={(option) => {
                      const value = getOptionValue(option)
                      setDocumentType(value)
                      checkDNI(value)
                    }}
                    disabled={loadingEmail}
                  />
                </Col>
                <Col span={12}>
                  <InputFieldWithError
                    label={dniLabel}
                    ref={dniRef}
                    value={dni}
                    onChange={onChangeDNI}
                    onBlur={() => checkDNI(docType)}
                    error={errorDNI}
                    loading={loadingDNI}
                    successMessage={dniAvailable}
                    disabled={loadingEmail || !docType}
                    required
                  />
                </Col>
                <Col span={24}>
                  <p style={styles.inputTitle}>Género*</p>
                  <Select
                    className={errorGender ? 'errorField' : ''}
                    placeholder="Género*"
                    selected={selectedGender}
                    options={genders}
                    onSelect={(option) => {
                      setErrorGender('')
                      setGender(getOptionValue(option))
                    }}
                    disabled={remainingFieldsAvailability}
                  />
                  {errorGender && <p style={styles.errorFieldLabel}>{errorGender}</p>}
                </Col>
                <Col span={24}>
                  <InputFieldWithError
                    label="Nombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => isValidName({ name, setValue: setName, setError: setErrorName })}
                    error={errorName}
                    required
                    disabled={remainingFieldsAvailability}
                  />
                </Col>
                <Col span={24}>
                  <InputFieldWithError
                    label="Apellido"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onBlur={() => isValidName({ name: lastName, setValue: setLastName, setError: setErrorLastName })}
                    error={errorLastName}
                    required
                    disabled={remainingFieldsAvailability}
                  />
                </Col>
                {!editMode && (
                  <Col span={24}>
                    <InputFieldWithError label="Contraseña" value={password} disabled className="disabledFields" />
                  </Col>
                )}
                {editMode && !byProposal && (
                  <Col span={24}>
                    <p style={styles.inputTitle}>Rol*</p>
                    <Select
                      style={styles.inlineSelect}
                      selected={selectedRole}
                      options={rolesOptions}
                      onSelect={(option) => setRole(getOptionValue(option))}
                    />
                  </Col>
                )}
              </Row>
            </InputGroup>
          </Col>
          <Col sm={12} xs={24}>
            <InputGroup title="Datos personales">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <p style={styles.inputTitle}>Fecha de nacimiento*</p>
                  <DatePicker
                    className={errorDateBirth ? 'errorField' : ''}
                    placeholder="Fecha de nacimiento*"
                    value={dateBirth}
                    locale={locale}
                    format="DD/MM/YYYY"
                    defaultPickerValue={moment('01/01/1980')}
                    popupStyle={{ zIndex: 10000 }}
                    onChange={(date) => {
                      setErrorDatebirth('')
                      setDateBirth(date)
                    }}
                    disabled={remainingFieldsAvailability}
                  />
                  {dateBirth && (
                    <span style={styles.age}>
                      Edad: <b>{now.diff(dateBirth, 'years')}</b> años
                    </span>
                  )}
                  {dateBirth && (
                    <p style={styles.astrologicalSign}>
                      Signo del Zodiaco: <b>{getAstrologicalSign(dateBirth)}</b>
                    </p>
                  )}
                  {errorDateBirth && <p style={styles.errorFieldLabel}>{errorDateBirth}</p>}
                </Col>
                {(editMode || byProposal) && actualPhone && (
                  <Col id="actual-phone-display" span={24}>
                    <p style={styles.inputTitle}>Celular actual</p>
                    <p style={styles.actualPhone}>{actualPhone}</p>
                    <Button type="primary" size="small" style={styles.buttonModifyPhone} onClick={handleShowEditPhone}>
                      Modificar
                    </Button>
                  </Col>
                )}
                <Col
                  style={{
                    display: actualPhone ? showEditPhone : ''
                  }}
                  span={24}>
                  <p style={styles.inputTitle}>Celular*</p>
                  <Cellphone
                    countries={countries}
                    reset={open || reset}
                    phone={phone}
                    errorAreaCode={errorAreaCode}
                    errorPhoneBody={errorPhoneBody}
                    onAreaCodeBlur={checkAreaCode(setErrorAreaCode, false)}
                    onPhoneBodyBlur={onCellphoneBodyBlur}
                    disabled={remainingFieldsAvailability}
                    createModalOpen={createModalOpen}
                    countrySelected={userCountrySelected}
                    countryPhoneCode={countryPhoneCode}
                    setCountryPhoneCode={setCountryPhoneCode}
                  />
                  {errorCellPhone && <p style={styles.errorFieldLabel}>{errorCellPhone}</p>}
                </Col>
                {editMode && actualLandline && (
                  <Col id="alternative-phone-display" span={24}>
                    <p style={styles.inputTitle}>Telefono Alternativo actual</p>
                    <p style={styles.actualPhone}>{actualLandline}</p>
                    <Button type="primary" size="small" style={styles.buttonModifyPhone} onClick={handleLandLine}>
                      Modificar
                    </Button>
                  </Col>
                )}
                <Col style={{ display: actualLandline ? showEditLandLine : '' }} span={24}>
                  <p style={styles.inputTitle}>Telefono Alternativo</p>
                  <Select
                    wrapperStyle={styles.select}
                    name="phoneType"
                    placeholder="Teléfono Alternativo"
                    selected={selectedPhone}
                    options={phoneTypes}
                    onSelect={(option) => {
                      setLandline('')
                      setPhoneType(getOptionValue(option))
                    }}
                    disabled={remainingFieldsAvailability}
                  />
                </Col>
                {phoneType === 'phone' ? (
                  <Col style={{ display: actualLandline ? showEditLandLine : '' }} span={24}>
                    <p style={styles.inputTitle}>Celular alternativo</p>
                    <Cellphone
                      countries={countries}
                      reset={resetAlternativeCellPhone}
                      phone={phone}
                      errorAreaCode={errorAreaCodeAlternative}
                      errorPhoneBody={errorPhoneBodyAlternative}
                      onAreaCodeBlur={checkAreaCode(setErrorAreaCodeAlternative, true)}
                      onPhoneBodyBlur={checkPhoneBody(setErrorPhoneBodyAlternative, setLandline, true)}
                      disabled={remainingFieldsAvailability}
                      countrySelected={userCountrySelected}
                      countryPhoneCode={countryAlternativePhoneCode}
                      setCountryPhoneCode={setCountryAlternativePhoneCode}
                      isAlternative={true}
                    />
                    {errorLandline && <p style={styles.errorFieldLabel}>{errorLandline}</p>}
                  </Col>
                ) : (
                  <Col style={{ display: actualLandline ? showEditLandLine : '' }} span={24}>
                    <p style={styles.inputTitle}>Teléfono fijo</p>
                    <MaskedInput
                      value={landline}
                      mask="1111-1111"
                      placeholder="Teléfono fijo"
                      onChange={(e) => setLandline(e.target.value)}
                      onBlur={checkLandline}
                      disabled={remainingFieldsAvailability}
                      autoComplete="nope"
                    />
                    {errorLandline && <p style={styles.errorFieldLabel}>{errorLandline}</p>}
                  </Col>
                )}
              </Row>
            </InputGroup>
          </Col>
          {!byProposal && (
            <Col span={24} id='"address'>
              <InputGroup title="Dirección">
                <Address disabled={remainingFieldsAvailability} country={country} />
              </InputGroup>
            </Col>
          )}
        </Row>
        <div className="container">
          <p style={styles.error}>{error}</p>
        </div>
      </div>
    </Modal>
  )
}

export default NewUserModal
