import React from 'react'
import { Checkbox, Modal } from 'antd'
import CBU from '@/components/CBU/container'
import InputGroup from '@/components/InputGroup'
import styles from './CBUModal.module.scss'
import { useLocalization } from '@/packages/localization'

export type CBUModalProps = {
  cbuQuantity: number
  isMain: boolean
  editMode: boolean
  modalVisibility: boolean
  disabledOkButton: boolean
  disabledCheckboxAsMain: boolean
  handleOkModal: () => void
  handleCancelModal: () => void
  handleSetAsMain: (check: boolean) => void
}

export const CBUModal = ({
  cbuQuantity,
  isMain,
  editMode,
  modalVisibility,
  disabledOkButton,
  disabledCheckboxAsMain,
  handleOkModal,
  handleCancelModal,
  handleSetAsMain
}: CBUModalProps) => {
  const { strings } = useLocalization()
  const modalTitle = editMode ? strings.Users.CU.Modal.Title.editMode : strings.Users.CU.Modal.Title.createMode
  const showCheckboxAsMain = cbuQuantity > 1 || !editMode

  return (
    <Modal
      title={modalTitle}
      visible={modalVisibility}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      okButtonProps={{
        disabled: disabledOkButton
      }}
      okText={strings.general.Button.save}
      cancelText={strings.general.Button.cancel}>
      <InputGroup title={strings.Users.CU.Modal.infoGroup}>
        <CBU isEdit={editMode} />
        {showCheckboxAsMain ? (
          <Checkbox
            className={styles.checkIsMain}
            onChange={(e) => handleSetAsMain(e.target.checked)}
            checked={isMain}
            disabled={disabledCheckboxAsMain}>
            {strings.Users.CU.Modal.markAsMain}
          </Checkbox>
        ) : null}
      </InputGroup>
    </Modal>
  )
}

export default CBUModal
