/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import moment, { Moment } from 'moment'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { TransactionStatusId, TransactionTypeId } from '../common'
import { OperationStatus } from '../../Operation/Removals/list'
import { convertToMoment } from '../../../utils'
import { OperationType } from '../../Transactions/list'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'

type APITransaction = {
  id: number
  guid: string
  type_id: TransactionTypeId
  reserve_date: string
  trip_id: number
  transaction_status_id: number
  destination: string
  created_at: string
  confirmation_date: string
  quantity_objects: number
  quantity_m3_objects: number
  quantity_items: number
  quantity_m3_items: number
  origin: string
  client: {
    id: number
    name: string
    last_name: string
    phone: string
  }
  operators: { id: number; name: string; last_name: string; guid: string }[]
  operation: {
    CoreID: number
    CoreType: OperationType
    ID: number
    OperationCoreID: number
    OperationCoreType: OperationType
    Status: OperationStatus
    TransportDateTime: string
    UserID: number
    Country: CountryIdCode
  }
}

type APIResponse = APITransaction[]

export type TripTransactionOperator = { id: number; name: string; lastName: string; guid: string }

export type TripTransactionOperation = {
  coreId: number
  coreType: OperationType
  id: number
  operationCoreId: number
  operationCoreType: OperationType
  status: OperationStatus
  transportDateTime?: Moment | null
  userId: number
  countryId: CountryIdCode
}

export type Transaction = {
  id: number
  typeId: TransactionTypeId
  guid: string
  reservationDate: Moment
  tripId: number
  transactionStatusId: TransactionStatusId
  destinationAddress: string
  originAddress: string
  createdAt: Moment
  confirmationDate: Moment
  contents: {
    forecast: {
      items: number
      m3: number
    }
    real: {
      objects: number
      m3: number
    }
  }
  client: {
    id: number
    name: string
    lastName: string
    phone: string
  }
  operators: TripTransactionOperator[]
  operation: TripTransactionOperation
}

type Response = {
  transactions: Transaction[]
}

interface Params {
  tripId: number
}

export function list(params: Params): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/trip/transactions`, { params: snakecaseKeys({ ...params }) })
    .then((response: AxiosResponse<APIResponse>) => {
      const camelResponse = {
        transactions: camelcaseKeys(response.data, { deep: true }).map((transaction) => {
          return {
            id: transaction.id,
            typeId: transaction.typeId,
            reservationDate: moment(transaction.reserveDate),
            tripId: transaction.tripId,
            transactionStatusId: transaction.transactionStatusId,
            destinationAddress: transaction.destination,
            originAddress: transaction.origin,
            createdAt: moment(transaction.createdAt),
            confirmationDate: moment(transaction.confirmationDate),
            guid: transaction.guid,
            contents: {
              forecast: {
                items: transaction.quantityItems,
                m3: transaction.quantityM3Items
              },
              real: {
                objects: transaction.quantityObjects,
                m3: transaction.quantityM3Objects
              }
            },
            client: transaction.client,
            operators: transaction.operators,
            operation: {
              // @ts-ignore - having consecutive uppercase letters breaks camelcaseKeys
              coreId: transaction.operation.coreId,
              coreType: transaction.operation.coreType,
              id: transaction.operation.id,
              // @ts-ignore - having consecutive uppercase letters breaks camelcaseKeys
              operationCoreId: transaction.operation.operationCoreId,
              operationCoreType: transaction.operation.operationCoreType,
              status: transaction.operation.status,
              transportDateTime: convertToMoment(transaction.operation.transportDateTime),
              userId: transaction.operation.userID,
              countryId: transaction.operation.country
            }
          }
        })
      }
      return camelResponse
    })
    .catch((err) => {
      console.error(err)
      throw err
    })
}
