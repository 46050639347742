import React from 'react'
import { DynamicTable } from '../../../../components/DynamicTable'
import { Deposit } from '../../../../projectApi/TransactionHandler/Deposits/common'
import { Column, Pagination } from '../../../../components/DynamicTable/types/types'
import { getCurrentCountry } from '../../../../components/CountrySelector/utils/utils'
import FlagCountry from '../../../../components/CountrySelector/component/FlagCountry'
import { useCountries } from '../../../../components/CountrySelector/hooks/hooks'
import { CorporativeDepositsSearchFilter, CoporativeDepositsTableSearchKey } from '../types/depositsTable'
import { getSearchProps } from '../../../../components/DynamicTable/utils'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { Link } from 'react-router-dom'

type CorporativeDepositTableProps = {
  fetchingDeposits: boolean
  deposits: Deposit[]
  pagination: Pagination
  searchFilters: CorporativeDepositsSearchFilter[]
  actions: MenuAction<Deposit>[]
  handleSearch: (key: CoporativeDepositsTableSearchKey, newValue: string) => void
}

const CorporativeDepositsTable = ({
  fetchingDeposits,
  deposits,
  pagination,
  handleSearch,
  searchFilters,
  actions
}: CorporativeDepositTableProps) => {
  const countries = useCountries()

  const columns: Column<Deposit>[] = [
    {
      key: 'countryId',
      label: 'País',
      maxWidth: '200px',
      renderDataCell: ({ countryId }) => {
        const flag = getCurrentCountry(countries, countryId)

        if (!countryId) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID',
      maxWidth: '200px',
      search: getSearchProps('depositId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ id }) => {
        return (
          <Link style={{ textDecoration: 'none' }} to={`/corporative/locations?depositId=${id}`}>
            {id}
          </Link>
        )
      }
    },
    {
      key: 'name',
      label: 'Nombre',
      minWidth: '1px',
      maxWidth: '300px',
      renderDataCell: ({ name }) => name,
      search: getSearchProps('name', 'Nombre', handleSearch, searchFilters)
    },
    {
      key: 'address',
      label: 'Dirección'
    }
  ]

  return (
    <DynamicTable
      columns={columns}
      loading={fetchingDeposits}
      rows={deposits}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
    />
  )
}

export default CorporativeDepositsTable
