import {
  CLOSE_DELETE_MODAL,
  CloseDeleteModal,
  DELETE_ADDRESS_FAILURE,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DeleteAddressRequest,
  DeleteAddressSuccess,
  OPEN_DELETE_MODAL,
  OpenDeleteModal
} from '../types/deleteAddress'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'

export const DeleteAddressActionCreator = {
  deleteAddress:
    (userId: number, addressId: number): AppThunk<Promise<void>> =>
    async (dispatch) => {
      const request: DeleteAddressRequest = { type: DELETE_ADDRESS_REQUEST }
      dispatch(request)

      try {
        await API.AccountManager.Addresses.deleteAddress({ userId, addressId })
        const success: DeleteAddressSuccess = {
          type: DELETE_ADDRESS_SUCCESS
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, DELETE_ADDRESS_FAILURE, dispatch)
      }
    },
  openModal: (userId: number, addressId: number): OpenDeleteModal => ({
    type: OPEN_DELETE_MODAL,
    payload: { userId, addressId }
  }),
  closeModal: (): CloseDeleteModal => ({ type: CLOSE_DELETE_MODAL })
}
