import React from 'react'
import OperationType from './containers/OperationType'
import { Col, Row, Spin } from 'antd'
import ProspectData from './containers/ProspectData'
import Transport from './containers/Transport'
import Summary from './containers/Summary'
import CreateUserModal from '../Users/containers/newUserModal'
import { PROPOSAL_MODES } from '../../common/operations/constants'
import SelectProposal from './containers/SelectProposal'
import DepositDate from './containers/DepositDate'
import ExtraService from './containers/ExtraService'
import { ProposalState } from './reducers/proposals'
import './component.scss'
import { ProspectState } from './reducers/prospect'
import Payment from './containers/Payment'

const styles = {
  container: {
    margin: 'auto',
    maxWidth: 1600,
    marginBottom: 16
  },
  loadingProposalContainer: {
    zIndex: 10,
    position: 'fixed' as const,
    display: 'flex',
    width: '100%',
    top: '0',
    left: '0',
    height: '100%',
    flexDirection: 'column' as const,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingProposalText: {
    fontSize: 16,
    marginTop: 15
  },
  row: {
    margin: 0
  },
  summaryContainer: {
    position: 'sticky' as const,
    top: 65
  }
}

type NewProposalComponentProps = {
  mode: string
  proposalsReducer: ProposalState
  prospectReducer: ProspectState
  isNewProposal: string
}

export const Component = (props: NewProposalComponentProps) => {
  const { mode, proposalsReducer, prospectReducer } = props

  return (
    <Row className="newProposalFontFamily" style={styles.container}>
      {(proposalsReducer.loadingProposalByID ||
        proposalsReducer.loadingProposals ||
        prospectReducer.loadingProspect) && (
        <div style={styles.loadingProposalContainer}>
          <Spin size="large" />
          <p style={styles.loadingProposalText}>Cargando datos de la cotización, por favor espere...</p>
        </div>
      )}
      <Col md={24} lg={17}>
        <Row gutter={[16, 16]} style={styles.row}>
          <Col span={24}>
            <ProspectData mode={mode} />
          </Col>
          {mode !== PROPOSAL_MODES.VIEW_PROPOSAL && (
            <Col span={24}>
              <SelectProposal mode={mode} />
            </Col>
          )}
          <Col span={24}>
            <OperationType mode={mode} />
          </Col>
          <Col span={24}>
            <ExtraService mode={mode} />
          </Col>
          <Col span={24}>
            <Transport mode={mode} />
          </Col>
          {mode === PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL && (
            <>
              <Col span={24}>
                <DepositDate />
              </Col>
              <Col span={24}>
                <Payment />
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Col md={24} lg={7} style={styles.summaryContainer}>
        <Row gutter={[16, 16]} style={styles.row}>
          <Col span={24}>
            <Summary mode={mode} />
          </Col>
        </Row>
      </Col>
      <CreateUserModal byProposal />
    </Row>
  )
}

export default Component
