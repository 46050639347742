import React from 'react'
import Component from '../../../common/operationsTable/components/pagePanel'
import { bindActionCreators } from 'redux'
import actions from '../actions/page'
import { connect } from 'react-redux'

const Container = (props) => {
  return <Component {...props} />
}

const mapStateToProps = (state) => {
  const { Deposits } = state
  return {
    ...Deposits.page
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
