import { NextStatusActions, NextStatusTypes } from '../types/nextStatus'
import { CloseRemovalTypes, DebtsStatus } from '../constants'

const initialState = {
  openInformPaymentModal: false,
  openDebtStatusModal: false,
  openCloseRemovalModal: false,
  removalId: 0,
  debtAmount: 0,
  debtStatus: DebtsStatus.NO_DEBTS,
  closeRemoval: CloseRemovalTypes.TOTALLY,
  loading: false,
  error: ''
}

export type NextStatusState = {
  openDebtStatusModal: boolean
  openInformPaymentModal: boolean
  openCloseRemovalModal: boolean
  removalId: number
  debtAmount: number
  debtStatus: DebtsStatus
  closeRemoval: CloseRemovalTypes
  loading: boolean
  error: string
}

const NextStatusReducer = (state: NextStatusState = initialState, action: NextStatusActions): NextStatusState => {
  switch (action.type) {
    case NextStatusTypes.TOGGLE_UPDATE_DEBT_STATUS_MODAL: {
      const { open, removalId = 0 } = action.payload
      return {
        ...state,
        openDebtStatusModal: open,
        removalId
      }
    }
    case NextStatusTypes.TOGGLE_INFORM_PAYMENT_MODAL: {
      const { open, removalId = 0, debtAmount = 0 } = action.payload
      return {
        ...state,
        openInformPaymentModal: open,
        removalId,
        debtAmount
      }
    }
    case NextStatusTypes.TOGGLE_CLOSE_REMOVAL_MODAL: {
      const { open, removalId = 0 } = action.payload
      return {
        ...state,
        openCloseRemovalModal: open,
        removalId
      }
    }
    case NextStatusTypes.SET_DEBT_AMOUNT: {
      const { amount } = action.payload
      return {
        ...state,
        debtAmount: amount
      }
    }
    case NextStatusTypes.SET_DEBT_STATUS: {
      const { status } = action.payload
      return {
        ...state,
        debtStatus: status
      }
    }
    case NextStatusTypes.SET_CLOSE_REMOVAL: {
      const { closeRemoval } = action.payload
      return {
        ...state,
        closeRemoval
      }
    }
    case NextStatusTypes.UPDATE_DEBT_STATUS_REQUEST:
      return { ...state, loading: true, error: '' }
    case NextStatusTypes.UPDATE_DEBT_STATUS_SUCCESS:
      return initialState
    case NextStatusTypes.UPDATE_DEBT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case NextStatusTypes.INFORM_PAYMENT_REQUEST:
      return { ...state, loading: true, error: '' }
    case NextStatusTypes.INFORM_PAYMENT_SUCCESS:
      return initialState
    case NextStatusTypes.INFORM_PAYMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default NextStatusReducer
