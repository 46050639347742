import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../../api'
import { URL_BASE_PROFILE } from '../../../../endpoints'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { APIAddress, Address, formatAddress } from './common'

interface Response {
  addresses: Address[]
}

interface APIResponse {
  description: APIAddress[]
  success: boolean
}

interface ListParams {
  userId: number
}

export function listByUser(params: ListParams): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_PROFILE}/admin/address`, { params: snakecaseKeys({ ...params }) })
    .then((response: AxiosResponse<APIResponse>) => {
      const responseAddress = camelcaseKeys(response.data.description, { deep: true })

      const camelResponse: Response = {
        addresses: responseAddress.map((address) => formatAddress(address))
      }

      return camelResponse
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
