import { GetProposalByIdSuccess } from '../../sections/NewProposal/types/proposals'

export enum AddressTypes {
  CLEAN_ADDRESS = 'ADDRESS/CLEAN_ADDRESS',
  SET_ADDRESS = 'ADDRESS/SET_ADDRESS',
  SET_ERROR_ADDRESS = 'ADDRESS/SET_ERROR_ADDRESS'
}

export interface CleanAddress {
  type: AddressTypes.CLEAN_ADDRESS
}

export interface SetAddress {
  type: AddressTypes.SET_ADDRESS
  payload: {
    address: Partial<Address>
  }
}

export interface SetErrorAddress {
  type: AddressTypes.SET_ERROR_ADDRESS
  payload: {
    error: string
  }
}

export type Address = {
  addressGoogle: string
  street: string
  number: string
  postalCode: string
  province: string
  city: string
  country: string
  floor: string
  apartment: string
}

export type AddressActions = CleanAddress | SetAddress | SetErrorAddress | GetProposalByIdSuccess
