import { STEP_OPTION } from '../constants'

export type StepModalState = {
  openModal: boolean
  loading: boolean
  error: string
  interestReason: string
  discardReason: string
  optionSelected: STEP_OPTION
  disabledSelect: boolean
  leadId: number
}

export const SET_MODAL_OPEN = 'LEADS/OPTIONS_MODAL/SET_MODAL_OPEN'

export interface OpenStepModal {
  type: typeof SET_MODAL_OPEN
  payload: { openModal: boolean; leadId: number }
}

export const SET_INTEREST_REASON = 'LEADS/OPTIONS_MODAL/SET_INTEREST_REASON'
export const SET_DISCARD_REASON = 'LEADS/OPTIONS_MODAL/SET_DISCARD_REASON'
export const SET_OPTION_SELECTED = 'LEADS/OPTIONS_MODAL/SET_OPTION_SELECTED'

export interface SetInterestReason {
  type: typeof SET_INTEREST_REASON
  payload: { interestReason: string }
}

export interface SetDiscardReason {
  type: typeof SET_DISCARD_REASON
  payload: { discardReason: string }
}

export interface SetOptionSelectd {
  type: typeof SET_OPTION_SELECTED
  payload: { option: STEP_OPTION }
}

type SetterDataActions = SetInterestReason | SetDiscardReason | SetOptionSelectd

export const UPDATED_LEAD = 'LEADS/OPTIONS_MODAL/EDIT_LEAD'
export const UPDATED_LEAD_SUCCESS = 'LEADS/OPTIONS_MODAL/EDIT_LEAD_SUCCESS'
export const UPDATED_LEAD_FAILURE = 'LEADS/OPTIONS_MODAL/UPDATED_LEAD_FAILURE'

export interface UpdatedLead {
  type: typeof UPDATED_LEAD
}

export interface UpdatedLeadSuccess {
  type: typeof UPDATED_LEAD_SUCCESS
  payload: { message: string }
}

export interface UpdatedLeadFailure {
  type: typeof UPDATED_LEAD_FAILURE
  payload: { message: string }
}

type UpdatedLeadActions = UpdatedLead | UpdatedLeadSuccess | UpdatedLeadFailure

export type StepModalActions = OpenStepModal | SetterDataActions | UpdatedLeadActions
