import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../api'
import { URL_BASE_CATEGORIES } from '../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

interface APIResponse {
  description: APICategory[]
}

type APICategory = {
  CategoryPhoto: {
    ID: number
    Url: string
  }
  Description: string
  ID: number
  IsLeaf: boolean
  Rentable: boolean
  Children: APICategory[] | null
}

type APICamelCasedCategory = CamelCasedPropertiesDeep<APICategory[]>

export type Category = {
  categoryPhotos: {
    id: number
    url: string
  }
  description: string
  id: number
  isLeaf: boolean
  rentable: boolean
  children: Category[] | null
}

export type LeafCategory = Omit<Category, 'children'>

interface Response {
  categories: Category[]
}

const formatCategories = (categories: APICamelCasedCategory): Category[] =>
  categories.map((category) => ({
    categoryPhotos: {
      id: category.categoryPhoto.id,
      url: category.categoryPhoto.url
    },
    description: category.description,
    isLeaf: category.isLeaf,
    rentable: category.rentable,
    id: category.id,
    children: category.children ? formatCategories(category.children) : null
  }))

export const getLeafCategories = (categories: Category[]): LeafCategory[] =>
  categories
    .reduce((acc: LeafCategory[], category: Category) => {
      const { children, ...categoryNode } = category
      if (category.isLeaf) return [...acc, categoryNode]
      if (children) return [...acc, ...getLeafCategories(children)]
      return acc
    }, [])
    .sort((a, b) => a.description.localeCompare(b.description))

export function getCategories(): Promise<Response> {
  return apiPrivate.get(`${URL_BASE_CATEGORIES}/category?all=true`).then((response: AxiosResponse<APIResponse>) => {
    if (!response.data) throw new Error('Respuesta incorrecta al obtener las categorias')

    const camelCasedCategories: APICamelCasedCategory = camelcaseKeys(response.data.description, { deep: true })

    const camelResponse: Response = {
      categories: formatCategories(camelCasedCategories)
    }

    return camelResponse
  })
}
