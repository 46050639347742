import React from 'react'
import { Modal } from 'antd'
import { CancelSubscriptionModalState } from '../types/cancelSubscriptionModal'

export type CancelSubscriptionModalProps = {
  cancelSubscriptionModalState: CancelSubscriptionModalState
  handleSend: () => void
  onCancel: () => void
}

const styles = {
  modal: {
    maxWidth: 600
  },
  bodyModal: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  inputTitle: {
    marginBottom: 5,
    color: 'grey',
    textTransform: 'uppercase',
    fontSize: 10
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  inputFullWidth: {
    width: '100%'
  }
}

export const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  handleSend,
  onCancel,
  cancelSubscriptionModalState
}) => {
  const { billingId, error, isLoading, modalOpen } = cancelSubscriptionModalState

  return (
    <Modal
      title="Anular abono"
      visible={modalOpen}
      onOk={handleSend}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: isLoading
      }}
      okButtonProps={{
        loading: isLoading,
        disabled: isLoading
      }}
      okText={'Aceptar'}
      cancelText={'Cancelar'}
      bodyStyle={styles.bodyModal}
      style={styles.modal}>
      <p>
        <b>Abono ID: {billingId}</b>
      </p>
      <p>Se generará una nota de crédito por el monto del abono seleccionado y se cancelarán cobros pendientes.</p>
      <p>Esta acción NO hace ninguna devolución de dinero.</p>
      <div className="container">
        <p style={{ color: 'red', textAlign: 'center', marginTop: '10', marginBottom: '0' }}>{error}</p>
      </div>
    </Modal>
  )
}
