import { getErrorMessage, handleErrorsWithAction } from '../../../utils/HandleErrors'
import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import {
  ADD_CREDIT_CARD_SPREEDLY_FAILURE,
  ADD_CREDIT_CARD_SPREEDLY_REQUEST,
  ADD_CREDIT_CARD_SPREEDLY_SUCCESS,
  BillingPaymentTableCategoryFilter,
  BillingPaymentTableSort,
  CLEAR_SELECTED_BILLINGS,
  CLEAR_STATE,
  ClearSelectedBillings,
  ClearState,
  CreateCardSpreedly,
  GET_BILLINGS_BY_USER_FAILURE,
  GET_BILLINGS_BY_USER_REQUEST,
  GET_BILLINGS_BY_USER_SUCCESS,
  GET_CLIENT_PAYMENT_METHODS_REQUEST,
  GET_CLIENT_PAYMENT_METHODS_SUCCESS,
  GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE,
  GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST,
  GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS,
  GET_PDF_BY_CLIENT_FAILURE,
  GET_PDF_BY_CLIENT_REQUEST,
  GET_PDF_BY_CLIENT_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GetClientPaymentMethodsRequest,
  GetClientPaymentMethodsSuccess,
  GetPdfByClientRequest,
  GetPdfByClientSuccess,
  GetUserByIdFailure,
  GetUserByIdRequest,
  GetUserByIdSuccess,
  PAY_CLIENT_BILLING_FAILURE,
  PAY_CLIENT_BILLING_REQUEST,
  PAY_CLIENT_BILLING_SUCCESS,
  Pagination,
  PayClientBillingRequest,
  PayClientBillingSuccess,
  SELECT_BILLING,
  SELECT_PAYMENT_METHOD,
  SelectBilling,
  SelectPaymentMethod,
  TOGGLE_ALL_BILLINGS,
  TOGGLE_SELECTED_BILLING,
  ToggleAllBillings,
  ToggleSelectedBilling
} from '../types/BillingPayment'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import { payDebtsParams } from '../../../projectApi/TransactionHandler/Debts/payDebts'
import { createSpreedlyCardToken } from '../../../services/spreedly'
import { BillingPaymentStatusId } from '../constants'

const actions = {
  getInfoAboutUserHasDebts:
    (userId: number): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const toast = setToastLoading('Buscando usuario, por favor espere...')
      dispatch({
        type: GET_INFO_ABOUT_USER_HAS_DEBTS_REQUEST
      })
      try {
        const response = await API.TransactionHandler.Colppy.userInfo(userId)
        const { users } = response

        const request: GetUserByIdRequest = {
          type: GET_USER_BY_ID_REQUEST
        }

        dispatch(request)

        try {
          const { users: usersFromList } = await API.AccountManager.Users.list({ id: userId })

          const success: GetUserByIdSuccess = { type: GET_USER_BY_ID_SUCCESS }

          const failure: GetUserByIdFailure = {
            type: GET_USER_BY_ID_FAILURE,
            payload: { error: 'No existe usuario con ese ID' }
          }

          if (usersFromList.length !== 0) {
            dispatch(success)
            const user = {
              ...users[0],
              id: userId,
              country: usersFromList[0].country
            }
            dispatch({ type: GET_INFO_ABOUT_USER_HAS_DEBTS_SUCCESS, payload: { user } })
            setToastSuccessUpdate(toast, { render: 'Usuario encontrado.' })
          } else {
            setToastErrorUpdate(toast, { render: 'Usuario no encontrado.' })
            dispatch(failure)
            return false
          }
        } catch (err) {
          handleErrorsWithAction(err, GET_USER_BY_ID_FAILURE, dispatch)
        }
        return true
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'El usuario no posee facturas en Colppy' })
        handleErrorsWithAction(e, GET_INFO_ABOUT_USER_HAS_DEBTS_FAILURE, dispatch)
        return false
      }
    },
  getBillingsByUser({
    userId,
    pagination,
    sort,
    categoryFilter
  }: {
    userId: number
    pagination: Pagination
    sort?: BillingPaymentTableSort
    categoryFilter?: BillingPaymentTableCategoryFilter
  }): AppThunk {
    return async function (dispatch) {
      const toast = setToastLoading('Buscando facturas, por favor espere...')
      dispatch({
        type: GET_BILLINGS_BY_USER_REQUEST,
        payload: {
          pageSize: pagination.pageSize,
          newPage: pagination.page,
          sort,
          categoryFilter
        }
      })
      try {
        const response = await API.TransactionHandler.Colppy.billingsByUserId({
          userId,
          limit: pagination?.pageSize || 0,
          offset: pagination ? (pagination.page - 1) * pagination.pageSize : 0,
          sort: sort?.field,
          order: sort?.direction,
          status: categoryFilter?.status as BillingPaymentStatusId[]
        })
        const { billings, total } = response
        dispatch({ type: GET_BILLINGS_BY_USER_SUCCESS, payload: { billings, total } })
        setToastSuccessUpdate(toast, { render: 'Facturas encontradas.' })
      } catch (e) {
        setToastErrorUpdate(toast, { render: 'Error al buscar las facturas...' })
        handleErrorsWithAction(e, GET_BILLINGS_BY_USER_FAILURE, dispatch)
      }
    }
  },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  clearSelectedBillings: (): ClearSelectedBillings => ({ type: CLEAR_SELECTED_BILLINGS }),
  toggleAllBillings: (): ToggleAllBillings => ({ type: TOGGLE_ALL_BILLINGS }),
  toggleSelectedBilling: (billing: Billing): ToggleSelectedBilling => ({
    type: TOGGLE_SELECTED_BILLING,
    payload: {
      billing
    }
  }),
  getPdfWithBillingId:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: GetPdfByClientRequest = {
        type: GET_PDF_BY_CLIENT_REQUEST
      }
      dispatch(request)

      try {
        const url = await API.TransactionHandler.OtherEndpoints.doesAuthorizationDocumentExist({
          id: billingId
        })

        const success: GetPdfByClientSuccess = {
          type: GET_PDF_BY_CLIENT_SUCCESS
        }

        Emitter.emit(Events.BillingPayment.AUTHORIZATION_EXISTS, { pdfUrl: url })
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_PDF_BY_CLIENT_FAILURE, dispatch)
        Emitter.emit(Events.BillingPayment.AUTHORIZATION_DOESNT_EXIST)
      }
    },
  getUserPaymentMethods:
    (userId: number): AppThunk =>
    async (dispatch) => {
      const request: GetClientPaymentMethodsRequest = {
        type: GET_CLIENT_PAYMENT_METHODS_REQUEST
      }
      dispatch(request)

      try {
        const paymentMethods = await API.PaymentCollector.paymentMethods.search({ userId })

        const paymentMethodMain = paymentMethods.find((pm: PaymentMethod) => pm.main) ?? paymentMethods[0]

        const success: GetClientPaymentMethodsSuccess = {
          type: GET_CLIENT_PAYMENT_METHODS_SUCCESS,
          payload: {
            paymentMethods
          }
        }
        dispatch(success)
        if (paymentMethodMain) {
          const selectPaymentMethod: SelectPaymentMethod = {
            type: SELECT_PAYMENT_METHOD,
            payload: {
              paymentMethod: paymentMethodMain
            }
          }
          dispatch(selectPaymentMethod)
        }
      } catch (err) {
        handleErrorsWithAction(err, GET_PDF_BY_CLIENT_FAILURE, dispatch)
        Emitter.emit(Events.BillingPayment.AUTHORIZATION_DOESNT_EXIST)
      }
    },
  selectPaymentMethod: (paymentMethod: PaymentMethod): SelectPaymentMethod => ({
    type: SELECT_PAYMENT_METHOD,
    payload: {
      paymentMethod
    }
  }),
  selectBilling: (billing: Billing): SelectBilling => ({
    type: SELECT_BILLING,
    payload: {
      billing
    }
  }),
  payBillings:
    (params: payDebtsParams): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const toast = setToastLoading('Pagando factura. Por favor espere...')
      const request: PayClientBillingRequest = {
        type: PAY_CLIENT_BILLING_REQUEST
      }
      dispatch(request)

      try {
        const paymentResult = await API.TransactionHandler.Debts.payDebts(params)

        const success: PayClientBillingSuccess = {
          type: PAY_CLIENT_BILLING_SUCCESS
        }
        setToastSuccessUpdate(toast, { render: '¡Factura Pagada!' })

        dispatch(success)
        return true
      } catch (err: any) {
        const message = getErrorMessage(err)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(err, PAY_CLIENT_BILLING_FAILURE, dispatch)
        return false
      }
    },
  saveSpreedlyCard:
    ({
      userId,
      cardNumber,
      fullname,
      cvc,
      expireDate,
      documentType,
      document
    }: CreateCardSpreedly): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      dispatch({ type: ADD_CREDIT_CARD_SPREEDLY_REQUEST })

      const toast = setToastLoading('Agregando tarjeta. Por favor espere...')
      const monthYear = expireDate.match(/.{1,2}/g) || []
      try {
        const spreedlyResponse = await createSpreedlyCardToken({
          number: cardNumber,
          fullName: fullname,
          verificationValue: cvc,
          month: Number(monthYear[0]),
          year: Number('20' + monthYear[1])
        })

        const token = spreedlyResponse.transaction.payment_method?.token

        if (!token) {
          setToastErrorUpdate(toast, { render: 'Hubo un error al crear la tarjeta. Inténtelo con una distinta' })
          return false
        }

        await API.PaymentCollector.paymentMethods.add({
          userId: Number(userId || 0),
          additionalInfo: {
            cardholderIdentificationType: documentType,
            cardholderIdentificationNumber: document
          },
          token
        })
        dispatch({ type: ADD_CREDIT_CARD_SPREEDLY_SUCCESS })
        setToastSuccessUpdate(toast, { render: 'Tarjeta guardada' })
        return true
      } catch (error: any) {
        const message = getErrorMessage(error)

        setToastErrorUpdate(toast, { render: message })
        handleErrorsWithAction(error, ADD_CREDIT_CARD_SPREEDLY_FAILURE, dispatch)

        return false
      }
    }
}

export default actions
