export const getUserSearchType = (types, key) => {
  switch (key) {
    case 'id':
      return { type: types.SET_USER_SEARCH_BY_ID, objectKey: 'byID' }
    case 'name':
      return { type: types.SET_USER_SEARCH_BY_NAME, objectKey: 'byName' }
    case 'last_name':
      return {
        type: types.SET_USER_SEARCH_BY_LAST_NAME,
        objectKey: 'byLastName'
      }
    default:
      return {}
  }
}

export const getUserSearchTypeFailure = (types, key) => {
  switch (key) {
    case 'id':
      return types.SET_USER_SEARCH_BY_ID_FAILURE
    case 'name':
      return types.SET_USER_SEARCH_BY_NAME_FAILURE
    case 'last_name':
      return types.SET_USER_SEARCH_BY_LAST_NAME_FAILURE
    default:
      return ''
  }
}
