import React from 'react'
import styles from './CollapsablePanel.module.scss'
import './CollapsablePanel.scss'
import { Collapse } from 'antd'
import ColorBadge from '../ColorBadge'
import clsx from 'clsx'
const { Panel } = Collapse

type CollapsablePanelProps = {
  expandIconPosition?: 'left' | 'right'
  header?: React.ReactNode
  children: React.ReactNode
  withoutBorder?: boolean
  withoutPadding?: boolean
}

export const CollapsablePanel = (props: CollapsablePanelProps) => {
  const { expandIconPosition = 'right', header = '', children, withoutPadding, withoutBorder } = props

  const collapseClassName = clsx(
    'antd-custom',
    styles.panel,
    withoutPadding && 'withoutPadding',
    withoutBorder && 'withoutBorder'
  )

  return (
    <Collapse
      className={collapseClassName}
      expandIconPosition={expandIconPosition}
      defaultActiveKey={['1']}
      onChange={() => {}}>
      <Panel header={header} className={styles.panel} key="1">
        {children}
      </Panel>
    </Collapse>
  )
}

type ProposalHeaderProps = {
  title: string
  badgeContent?: string
  badgeExtraStyles?: React.CSSProperties
}

export const ProposalHeader = (props: ProposalHeaderProps) => {
  const { title, badgeContent, badgeExtraStyles } = props
  const extraStyles = {
    minWidth: '0px',
    width: 'fit-content',
    color: '#0859A1',
    padding: '0px 8px',
    marginLeft: '8px',
    fontWeight: 500,
    fontSize: 12
  }

  return (
    <span className={styles.proposalHeader}>
      {title}
      {badgeContent && (
        <ColorBadge backgroundColor="#DDEEFD" borderColor="" extraStyles={{ ...extraStyles, ...badgeExtraStyles }}>
          {badgeContent}
        </ColorBadge>
      )}
    </span>
  )
}
