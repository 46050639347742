import types from '../types/common'

const actions = {
  finish: () => ({
    type: types.FINISH
  }),
  setNotifyUser: (notifyUser) => ({
    type: types.SET_NOTIFY_USER,
    payload: { notifyUser }
  }),
  setPaymentBy: (paymentBy) => ({
    type: types.SET_PAYMENT_BY,
    payload: { paymentBy }
  }),
  setStorageCost: (storageCost) => ({
    type: types.SET_STORAGE_COST,
    payload: { storageCost }
  }),
  openModalResume: () => ({
    type: types.OPEN_MODAL_RESUME
  }),
  closeModalResume: () => ({
    type: types.CLOSE_MODAL_RESUME
  })
}

export default actions
