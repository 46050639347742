import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import LocationDeleteActionCreator from '../actions/LocationDelete'
import LocationDeleteModal from '../components/LocationDeleteModal'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const Container = (): React.ReactElement => {
  const dispatch = useDispatch()

  const locationDeleteModalActions = bindActionCreators(LocationDeleteActionCreator, dispatch)
  const locationDeleteModalState = useSelector((state) => state.Locations.LocationDelete)

  const onOkClick = () => {
    const { location } = locationDeleteModalState
    if (location) {
      locationDeleteModalActions.deleteLocation(location?.id)
    }
  }

  const props = {
    locationDeleteModalActions,
    locationDeleteModalState,
    onOkClick
  }

  const handleSuccessDelete = () => {
    locationDeleteModalActions.setModal({ isModalOpen: false, location: null })
  }

  useEvents(Events.Location.LOCATION_DELETED, () => handleSuccessDelete())

  return <LocationDeleteModal {...props} />
}

export default Container
