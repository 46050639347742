import { Spin } from 'antd'
import React from 'react'

const styles = {
  loadingContainer: {
    zIndex: 2,
    position: 'absolute' as const,
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingText: {
    fontSize: 16,
    marginTop: 15
  }
}

type LoadingScreenInformationProps = {
  description: string
}

// Parent component should have position: relative active on modals

const LoadingScreenInformation = (props: LoadingScreenInformationProps) => {
  return (
    <div style={styles.loadingContainer}>
      <Spin size="large" />
      <p style={styles.loadingText}>{props.description}</p>
    </div>
  )
}

export default LoadingScreenInformation
