export default {
  FETCH_PROSPECTS: 'LEADS/FETCH_PROSPECTS',
  FETCH_PROSPECTS_SUCCESS: 'LEADS/FETCH_PROSPECTS_SUCCESS',
  FETCH_PROSPECTS_FAIL: 'LEADS/FETCH_PROSPECTS_FAIL',
  FETCH_LEADS_CONFIG: 'LEADS/FETCH_LEADS_CONFIG',
  FETCH_LEADS_CONFIG_SUCCESS: 'LEADS/FETCH_LEADS_CONFIG_SUCCESS',
  FETCH_LEADS_CONFIG_FAIL: 'LEADS/FETCH_LEADS_CONFIG_FAIL',
  SET_SORTER: 'LEADS/SET_SORTER',
  SET_FILTER: 'LEADS/SET_FILTER',
  SET_FUNNEL_STATE_FILTER_BAR: 'LEADS/SET_FUNNEL_STATE_FILTER_BAR',
  SET_PAGINATION: 'LEADS/SET_PAGINATION',
  SET_SEARCH_FILTERS: 'LEADS/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'LEADS/CLEAN_FILTERS',
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE'
}
