import { apiPrivate } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

type UpdateParams = {
  userId: number
  roleId: number
}

export function update({ userId, roleId }: UpdateParams): Promise<void> {
  const body = {
    role: roleId
  }

  return apiPrivate
    .put(`${URL_BASE_USERS}/satellite-user/${userId}`, body)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
