import { GetUserProfileActions, UserProfileTypes } from '../types/userProfile'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Response as RoleAndPermissionsResponse } from '../../../projectApi/AccountManager/Roles/getByParentId'

const initialRoot = {
  error: '',
  loading: false,
  country: CountryIdCode.ARGENTINA,
  role: null,
  fetched: false
}

export type UserProfileState = {
  error: string
  loading: boolean
  country: CountryIdCode
  role: RoleAndPermissionsResponse | null
  fetched: boolean
}

function root(state: UserProfileState = initialRoot, action: GetUserProfileActions): UserProfileState {
  switch (action.type) {
    case UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        fetched: false
      }
    case UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_SUCCESS: {
      const roleAndPermissions = action.payload.roleAndPermissions
      return {
        ...state,
        role: roleAndPermissions,
        loading: false,
        fetched: true
      }
    }
    case UserProfileTypes.GET_PARENT_ACCOUNT_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        fetched: false
      }
    default:
      return state
  }
}

export default root
