import { AxiosResponse } from 'axios'
import { apiPublic } from '../../../api'
import { URL_BASE_USERS } from '../../../endpoints'

type CheckIfEmailExistsParams = {
  email: string
}

export interface Main {
  exists: boolean
}

export interface ResponseCheckIfEmailExists {
  email: string
  exists: boolean
}

export function checkIfEmailExists(params: CheckIfEmailExistsParams): Promise<ResponseCheckIfEmailExists> {
  const bodyContent = JSON.stringify({
    email: params.email
  })
  return apiPublic
    .post(`${URL_BASE_USERS}/email/exists`, bodyContent)
    .then((response: AxiosResponse<Main>) => {
      const exists = response.data.exists
      return {
        email: params.email,
        exists
      }
    })
    .catch((err) => {
      throw err
    })
}
