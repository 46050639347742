export const useGetObjectsToRemove = (objectsToRemoveVolumes) => {
  return Object.values(objectsToRemoveVolumes).filter((obj) => !obj.deleted)
}

const calculateObjectToRemoveValues = (objectsToRemoveValues) => {
  const { objectDetails } = objectsToRemoveValues
  const { LengthInCm, WidthInCm, HeightInCm } = objectDetails
  const volume = ((LengthInCm || 0) * (WidthInCm || 0) * (HeightInCm || 0)) / 1000000
  return volume
}

export const objectsToRemoveTotalVolume = (objectsToRemoveValues) => {
  let totalVolume = 0
  objectsToRemoveValues.forEach((objectsToRemoveValues) => {
    const object = calculateObjectToRemoveValues(objectsToRemoveValues)
    totalVolume += object
  })
  return totalVolume
}
