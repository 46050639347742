import { handleErrorsWithAction } from './../../../utils/HandleErrors'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import {
  GET_SALES_STATUS_FAILURE,
  GET_SALES_STATUS_REQUEST,
  GET_SALES_STATUS_SUCCESS,
  GetSalesStatusRequest,
  GetSalesStatusSuccess
} from '../types/common'

const SalesActionCreator = {
  getSalesStatus: (): AppThunk => async (dispatch) => {
    const request: GetSalesStatusRequest = {
      type: GET_SALES_STATUS_REQUEST
    }
    dispatch(request)

    try {
      const { status } = await API.Sales.status()

      const success: GetSalesStatusSuccess = {
        type: GET_SALES_STATUS_SUCCESS,
        payload: { statusOptions: status }
      }
      dispatch(success)
    } catch (err) {
      handleErrorsWithAction(err, GET_SALES_STATUS_FAILURE, dispatch)
    }
  }
}

export default SalesActionCreator
