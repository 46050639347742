import React from 'react'
import styles from './BillingWarnings.module.scss'
import StatusCard, { StatusCardSkeleton } from '../../../components/StatusCard/StatusCard'
import { BillingMetrics as BillingMetricsType } from '../../../projectApi/TransactionHandler/Billings/metrics'
import { formatNumber } from '../../../utils/formatNumber'
export type BillingMetricsProps = {
  metrics: BillingMetricsType | null
  loadingBillings: boolean
}

const BillingMetrics: React.FC<BillingMetricsProps> = ({ metrics, loadingBillings }) => {
  return (
    <>
      {loadingBillings ? (
        <>
          <StatusCardSkeleton loadingColor="#B25E09" />
          <StatusCardSkeleton loadingColor="#B25E09" />
        </>
      ) : (
        <>
          <StatusCard
            customClassname={styles.warningCard}
            width="121px"
            titleCard="Abonos"
            disabled={!metrics?.billingQuantity}
            total={formatNumber(metrics?.billingQuantity || 0, 0, true)}
            positive={metrics?.positiveDifBillingQuantity}
            negative={metrics?.negativeDifBillingQuantity || 0}
          />
          <StatusCard
            customClassname={styles.warningCard}
            titleCard="Usuarios excluidos"
            total={formatNumber(metrics?.excludedUsers || 0, 0, true)}
            disabled={!metrics?.excludedUsers}
            positive={metrics?.positiveDifExcludedUsers}
            negative={metrics?.negativeDifExcludedUsers || 0}
          />
        </>
      )}
    </>
  )
}

export default BillingMetrics
