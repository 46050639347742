import React from 'react'
import { Tooltip } from 'antd'
import objectActions from '../../actions/objects'
import modalActionsConfirm from '../../actions/confirmModal'
import { ObjectResponse, ObjectToRemove } from '../../types/objects'
import { Trash } from '../../../../icons'
import ExtrasFields from './ExtrasFields'
import { formatMoneyWithCountry } from '../../../../utils/formatNumber'
import { Country } from '../../../../components/CountrySelector/constants/constants'
import ImgWithCarousel from '../../../../components/CarouselModal/components/ImgWithCarousel'

const styles = {
  container: {
    padding: '16px 8px',
    display: 'flex',
    alignItems: 'center'
  },
  objectDescription: {
    width: '200px',
    padding: '0 12px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 48,
    width: 48,
    cursor: 'pointer'
  },
  image: {
    maxWidth: 48,
    maxHeight: 48
  },
  label: {
    fontWeight: 500 as const,
    color: '#595959'
  },
  productName: {
    fontWeight: 'bold' as const,
    fontSize: '14px',
    marginBottom: 4
  }
}

type SelectedObjectToRemoveProps = {
  index: number
  objectActions: typeof objectActions
  objectToRemove: ObjectToRemove
  onDelete: (object: ObjectResponse) => void
  toggleCarrouselOpen: (images: string[]) => void
  modalConfirmActions: typeof modalActionsConfirm
  userCountry: Country
}

const moneyFormat = { withThousandSeparator: true }

const SelectedObjectToRemove = (props: SelectedObjectToRemoveProps) => {
  const { index, objectActions, objectToRemove, modalConfirmActions, userCountry } = props
  const { numberOfFloors, objectDetails, fromRemoval, floorCostFromRemoval } = objectToRemove
  const { currency } = userCountry

  const extraEvents = {
    onFloorsCheckboxChange: (checked: boolean) => objectActions.setFloors(objectDetails.ID, checked),
    onFloorsQuantityChange: (quantity: number) => objectActions.setNumberOfFloors(objectDetails.ID, quantity),
    onAssemblyCostCheckboxChange: (checked: boolean) => objectActions.setAssemblyCost(objectDetails.ID, checked),
    onRemovePackagingCostChange: (checked: boolean) => objectActions.setRemovePackagingCost(objectDetails.ID, checked)
  }

  const formatFloorLabel = `+${formatMoneyWithCountry(
    currency,
    (objectDetails.Product.FloorByStairsCostInCents / 100) * numberOfFloors,
    moneyFormat
  )}`

  const extraLabels = {
    floors: !floorCostFromRemoval ? formatFloorLabel : `$ ${floorCostFromRemoval}`,
    removePackagingCost: `+${formatMoneyWithCountry(
      currency,
      objectDetails.Product.RemovalPackagingCostInCents / 100,
      moneyFormat
    )}`,
    assemblyCost: `+${formatMoneyWithCountry(currency, objectDetails.Product.AssemblyCostInCents / 100, moneyFormat)}`
  }

  const showModalConfirm = (object: ObjectResponse) => {
    modalConfirmActions.openModalConfirm()
    modalConfirmActions.setObjectModal(object)
  }

  return (
    <div
      style={{
        ...styles.container,
        ...(index % 2 === 0 ? { backgroundColor: 'white' } : {})
      }}>
      <ImgWithCarousel
        carouselImages={objectDetails.Photos.map((p) => p.Url)}
        alt={objectDetails.Product.Description}
      />
      <div style={styles.objectDescription}>
        <p style={styles.productName}>{objectDetails.Product.Description}</p>
      </div>
      <ExtrasFields fromRemoval={fromRemoval} events={extraEvents} labels={extraLabels} values={objectToRemove} />
      <Tooltip placement="top" title={'Eliminar objeto de la lista'}>
        <i
          style={{ cursor: 'pointer', marginRight: '8px', height: '20px' }}
          onClick={() => showModalConfirm(objectToRemove.objectDetails)}>
          <Trash width="18px" height="18px" />
        </i>
      </Tooltip>
    </div>
  )
}

export default SelectedObjectToRemove
