import types from '../types/calculateBillingModal'

const initialRoot = {
  calculateBillingModalOpen: false,
  error: '',
  message: ''
}

function calculateBillingModalReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.OPEN_MODAL_CALCULATE_BILLING: {
      return {
        ...state,
        calculateBillingModalOpen: true,
        error: ''
      }
    }
    case types.CLOSE_MODAL_CALCULATE_BILLING: {
      return initialRoot
    }
    case types.CALCULATE_BILLING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: ''
      }
    }
    case types.CALCULATE_BILLING_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.payload.message
      }
    }
    case types.CALCULATE_BILLING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }
    default:
      return state
  }
}

export default calculateBillingModalReducer
