import React, { useEffect, useMemo } from 'react'
import AMCorporateProducts, { AMCorporateProductsProps } from '../components/AMProducts'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import ABMCorporateProductsActionsCreator from '../actions/ABMProducts'
import { bindActionCreators } from 'redux'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { Response as PhotoResponse } from '../../../../projectApi/CategoryCreation/Photos/uploadPhoto'
import { FormattedProduct } from '../../../../projectApi/CategoryCreation/Product/common'

type Props = {
  location: {
    state: {
      editMode?: boolean
      product: FormattedProduct
    }
  }
}

const AMCorporateProductsContainer = ({ location: { state: routeParams } }: Props) => {
  const history = useHistory()

  const {
    productFormFields,
    categories,
    selectedCategoryOption,
    selectedCategory,
    creatingProduct,
    updatingProduct,
    selectedProduct
  } = useSelector((state) => state.CorporativeProducts.ABM)
  const { country: userCountryCode } = useSelector((state) => state.UserLogged.userProfile)

  const dispatch = useDispatch()
  const { cleanState, setFormField, setEditMode, setCategory, createProduct, getCategories, updateProduct } =
    bindActionCreators(ABMCorporateProductsActionsCreator, dispatch)

  useEffect(() => {
    if (routeParams?.editMode) setEditMode({ editMode: true, selectedProduct: routeParams.product })

    getCategories()

    return () => {
      cleanState()
    }
  }, [])

  const onUploadedPhoto = (photos: PhotoResponse[]) => {
    const photo = photos[0]
    setFormField({ field: 'multimediaPhotoUrl', value: photo.url })
    setFormField({ field: 'multimediaPhotoKey', value: photo.s3Key })
  }

  useEvents(Events.UploadPhotos.UPLOAD_PHOTO_SUCCESS, onUploadedPhoto)
  useEvents([Events.CorporateProducts.CREATE_PRODUCT, Events.CorporateProducts.UPDATE_PRODUCT], () =>
    history.push('/corporative/products')
  )

  const categoryOptions = useMemo(() => {
    return categories
      .filter((c) => c.isLeaf)
      .map((category) => ({
        label: category.description,
        value: category.id
      }))
  }, [categories])

  const handleBack = () => history.goBack()

  const handleCreateProduct = () => {
    if (routeParams?.editMode) {
      const { id: productId } = routeParams.product
      updateProduct({
        selectedProductId: selectedProduct?.id ?? productId,
        updatedProduct: {
          ...productFormFields,
          userCountryCode,
          categoryId: selectedCategory?.id ?? selectedCategoryOption?.value ?? 0
        }
      })
    } else {
      createProduct({
        newProduct: { ...productFormFields, userCountryCode, categoryId: selectedCategory?.id ?? 0 }
      })
    }
  }

  const loadingButton = creatingProduct || updatingProduct
  const disabledSubmitButton =
    Object.entries(productFormFields)
      .filter(([key]) => key !== 'multimediaPhotoUrl' && key !== 'multimediaPhotoKey')
      .some(([, value]) => !value) || !selectedCategoryOption

  const AMProductsProps: AMCorporateProductsProps = {
    productFormFields,
    categoryOptions,
    selectedCategoryOption,
    editMode: routeParams?.editMode || false,
    loadingButton,
    disabledSubmitButton,
    handleBack,
    setCategory,
    setFormField,
    handleCreateProduct
  }

  return <AMCorporateProducts {...AMProductsProps} />
}

export default AMCorporateProductsContainer
