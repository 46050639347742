import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'

export interface DiscountDetails {
  id: number
  deposit: number
  general: number
  transport: number
  valid: boolean
}

export interface APIResponse {
  description?: DiscountDetails
  success: string
}

type Response = CamelCasedPropertiesDeep<DiscountDetails>

interface FetchDiscountParams {
  code: string
}

export function discount({ code }: FetchDiscountParams): Promise<Response> {
  const params = { code }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/compute/transaction/discount`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data?.success) throw new Error('No se pudo obtener el descuento')
      if (!response.data?.description) throw new Error('Respuesta incorrecta al obtener el descuento')

      const camelResponse = camelcaseKeys(response.data.description, { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
