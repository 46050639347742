import { Tooltip } from 'antd'
import clsx from 'clsx'
import React, { ReactElement, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { ArrowDown, Info, Tag } from '../../icons'
import styles from './overviewCard.module.scss'

const OverviewCard: React.FC = ({ children }) => {
  return <div className={styles.overviewCard}>{children}</div>
}

export const Header: React.FC<{ extraInfo?: ReactNode; withIcon?: boolean; backgroundColor?: string }> = ({
  children,
  extraInfo,
  backgroundColor,
  withIcon = true
}) => {
  return (
    <div className={styles.header} style={{ backgroundColor }}>
      {withIcon && (
        <span className={styles.icon}>
          <Tag />
        </span>
      )}
      <span className={styles.label}>{children}</span>
      {extraInfo && (
        <Tooltip title={extraInfo}>
          <div className={styles.tooltip}>
            <Info />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export const SubHeader: React.FC<{ Icon: React.FC }> = ({ Icon, children }) => {
  return (
    <Module className={styles.subHeader}>
      {Icon && <Icon />}
      <span>{children}</span>
    </Module>
  )
}

export const Module: React.FC<{ className?: string }> = ({ children, className }) => {
  return <div className={clsx(styles.module, className)}>{children}</div>
}

export const ExpandableModule: React.FC<{ title: ReactElement; startOpen?: boolean }> = ({
  children,
  title,
  startOpen = true
}) => {
  const [expandedHeight, setExpandedHeight] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const BASE_PADDING = 16

  useLayoutEffect(() => {
    if (ref.current) {
      const itemHeight = ref.current?.scrollHeight + BASE_PADDING * 2
      setExpandedHeight(itemHeight)
      setExpanded(startOpen)
    }
  }, [children])

  return (
    <div className={clsx(styles.expandableModule)}>
      <div className={styles.titleBar} onClick={() => setExpanded(!expanded)}>
        <span>{title}</span>
        <div className={clsx(styles.arrow, expanded && styles.rotate)}>
          <ArrowDown fill={'#5D58F7'} />
        </div>
      </div>
      <div
        className={styles.expandableBody}
        ref={ref}
        style={{
          maxHeight: expanded ? expandedHeight : 0,
          paddingTop: expanded ? BASE_PADDING : 0,
          paddingBottom: expanded ? BASE_PADDING : 0,
          borderTopWidth: expanded ? 1 : 0
        }}>
        {children}
      </div>
    </div>
  )
}

export default OverviewCard
