import {
  SEND_PRE_BILLING_REPORT_FAILURE,
  SEND_PRE_BILLING_REPORT_REQUEST,
  SEND_PRE_BILLING_REPORT_SUCCESS,
  SendPreBillingReportActions
} from '../types/preBillingReport'

const initialRoot = {
  loadingPreBillingReport: false,
  error: ''
}

type PreBillingReportState = {
  loadingPreBillingReport: boolean
  error: string
}

function preBillingReportReducer(
  state: PreBillingReportState = initialRoot,
  action: SendPreBillingReportActions
): PreBillingReportState {
  switch (action.type) {
    case SEND_PRE_BILLING_REPORT_REQUEST: {
      return {
        ...state,
        loadingPreBillingReport: true,
        error: ''
      }
    }
    case SEND_PRE_BILLING_REPORT_SUCCESS: {
      return {
        ...state,
        loadingPreBillingReport: false,
        error: ''
      }
    }
    case SEND_PRE_BILLING_REPORT_FAILURE: {
      return {
        ...state,
        loadingPreBillingReport: false,
        error: action.payload.error
      }
    }
    default:
      return state
  }
}

export default preBillingReportReducer
