import { ProductAPI } from './Product'
import { LocationInfoAPI } from './LocationInfo'
import { getStairsPrice } from './getStairsPrice'
import { getCategories } from './getCategories'
import { getPriceBands } from './getPriceBands'
import { PriceBandAPI } from './PriceBand'
import { SearchAPI } from './Search'
import { PhotoAPI } from './Photos'
import { SolutionAPI } from './Solutions'
import { ReportsAPI } from './Reports'
import { ServiceTypesAPI } from './ServiceType'
import { ProductSuggestionsAPI } from './ProductSuggestion'
import { CatalogAPI } from './Catalog'

export const CategoryCreationAPI = {
  Product: ProductAPI,
  LocationInfo: LocationInfoAPI,
  Search: SearchAPI,
  PriceBand: PriceBandAPI,
  Photo: PhotoAPI,
  Solution: SolutionAPI,
  getStairsPrice,
  getCategories,
  getPriceBands,
  Reports: ReportsAPI,
  ServiceTypes: ServiceTypesAPI,
  ProductSuggestions: ProductSuggestionsAPI,
  Catalog: CatalogAPI
}
