import React, { useCallback } from 'react'
import actions from './actions'
import { UploadContainerProps } from './container'
import { UploadPhotosState } from './reducer'
import { useDropzone } from 'react-dropzone'
import styles from './styles.module.scss'

type DropZoneProps = UploadContainerProps & {
  uploadPhotosActions: typeof actions
  uploadPhotosState: UploadPhotosState
}

const defaultAccept = {
  'image/*': ['.png', '.jpeg', '.jpg']
}

const DropZone: React.FC<DropZoneProps> = ({
  uploadPhotosActions,
  uploadPhotosState,
  dropzoneOptions,
  fileType,
  uploadedPhoto,
  onDropCallback
}) => {
  const { error } = uploadPhotosState

  const onDrop = useCallback((acceptedFiles) => {
    uploadPhotosActions.uploadPhotos(acceptedFiles, fileType)
    onDropCallback()
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ accept: defaultAccept, ...dropzoneOptions, onDrop })

  return (
    <div>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        {uploadedPhoto ? (
          <img src={uploadedPhoto} alt="uploaded" className={styles.uploadedPhoto} />
        ) : (
          <div className={styles.dropzoneContainer}>
            <p className={styles.dropzoneHeader}>Subida de imagen</p>
            <p className={styles.dropzoneText}>Arrastra la imagen o haz click aquí</p>
          </div>
        )}
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

export default DropZone
