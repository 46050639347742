import React from 'react'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import { DynamicTable } from '../../../components/DynamicTable'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { Button, DatePicker, Tooltip } from 'antd'
import styles from './newBillingTable.module.scss'
import { formatMoney, formatNumber } from '../../../utils/formatNumber'
import { BillingData } from '../../../projectApi/TransactionHandler/BillingData/list'
import { parseDate } from '../../../components/DynamicTable/utils/utils'
import { Billing, BillingStatus, BillingType } from '../../../projectApi/TransactionHandler/Billings/common'
import {
  BilingsTableDateRangeFilter,
  BillingTableSearchKey,
  BillingsSearchFilter,
  BillingsTableCategoryFilter,
  BillingsTableDateRangeKey
} from '../types/newBillingTable'
import moment, { Moment } from 'moment'
import { getFilterProps, getSearchProps } from '../../../components/DynamicTable/utils'
import { BillingExcludedUsersOptions, BillingStatusFilterOptions } from '../constants'
import { TableActionBar } from '../../../components/TableActionBar'
import { CURRENT_PERIOD } from '../reducers/newBillingTable'
import SendDebtReminderModalContainer from '../containers/sendDebtReminderModal'
import UserExclusionModalsContainer from '../containers/userExclusionModals'
import BillPeriodModalContainer from '../containers/billPeriodModal'
import EmailBilledContainer from '../containers/emailBilledModal'
import CancelSubscriptionModal from '../containers/cancelSubscriptionModal'
import { parseMomentDate } from './BillingByClientTable'
import { useLocalization } from '@/packages/localization'

const { MonthPicker } = DatePicker

const getMetricColor = (value: number): string =>
  value > 0 ? styles.metricPositive : value < 0 ? styles.metricNegative : ''

export type BillingsTableProps = {
  billings: BillingData[]
  loadingRows: number[]
  categoryFilter: BillingsTableCategoryFilter
  billingActions: MenuAction<BillingData>[]
  dateRangeFilters: BilingsTableDateRangeFilter[]
  error: string
  pagination: Pagination
  searchFilters: BillingsSearchFilter[]
  loadingBillings: boolean
  refreshBillingData: (billingId: number, periodFrom: Moment, periodTo: Moment) => void
  sendingCSV: boolean
  handleResetFilters: () => void
  handleSearch: (key: BillingTableSearchKey, newValue: string) => void
  handleCategoryFilter: (newFilter: BillingsTableCategoryFilter) => void
  handleRangePicker: (key: BillingsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleSendCSV: () => void
  handleBillPeriod: () => void
  billingsByClient: Billing[]
  isLoadingClient: boolean
  getNewDataFromClient: ({ userId, startDate, endDate }: { userId: number; startDate: string; endDate: string }) => void
  paginationClient: Pagination
  billingByClientActions: MenuAction<Billing>[]
  handleExportPayments: () => void
  exportingPayments: boolean
}

const monthFormat = 'YYYY MM'

const NewBillingTable: React.FC<BillingsTableProps> = ({
  loadingRows,
  billings,
  categoryFilter,
  dateRangeFilters,
  billingActions,
  loadingBillings,
  sendingCSV,
  pagination,
  searchFilters,
  refreshBillingData,
  handleResetFilters,
  handleSearch,
  handleRangePicker,
  handleCategoryFilter,
  handleSendCSV,
  handleBillPeriod,
  billingsByClient,
  isLoadingClient,
  getNewDataFromClient,
  paginationClient,
  billingByClientActions,
  handleExportPayments,
  exportingPayments
}) => {
  const monthPickerValue = dateRangeFilters.find((filter) => filter.key === BillingsTableDateRangeKey.PERIOD)

  const { strings } = useLocalization()

  const columns: Column<BillingData>[] = [
    {
      key: 'refreshing',
      label: '',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ billingId }) =>
        billingId && (
          <Tooltip title="Refrescar abono">
            <Button
              onClick={() =>
                refreshBillingData(
                  billingId,
                  monthPickerValue?.startDate || moment(),
                  monthPickerValue?.endDate || moment()
                )
              }
              icon="reload"
            />
          </Tooltip>
        )
    },
    {
      key: 'billingId',
      label: 'ID',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ id }) => id
    },
    {
      key: 'billingId',
      label: 'ID Abono',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      renderDataCell: ({ billingId }) => billingId
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      renderDataCell: ({ createdAt }) => parseDate(createdAt || '')
    },
    {
      key: 'user',
      label: 'Cliente',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      search: getSearchProps('userId', 'ID', handleSearch, searchFilters),
      renderDataCell: ({ userResponse: { id, name, lastName } }) => {
        return (
          <ClientIDLinkComponent id={id} noPadding>
            {id}
            <div>
              {name} {lastName}
            </div>
          </ClientIDLinkComponent>
        )
      }
    },
    {
      key: 'dates',
      label: 'Fechas',
      renderDataCell: ({ startDate, endDate }) => {
        return (
          <div>
            <div>{startDate?.utc().format('DD-MM-YY') || ''}</div>
            <div>{endDate?.utc().format('DD-MM-YY') || ''}</div>
          </div>
        )
      }
    },
    {
      key: 'amount',
      label: 'Monto ($)',
      renderDataCell: ({ total, billingMetricsResponse: { percentageDifTotal } }) => {
        const previousMonthTotal = total - (total / (100 + percentageDifTotal)) * 100
        return (
          <Tooltip
            title={`Diferencia con el período anterior: ${formatMoney(total - previousMonthTotal)} (${formatMoney(
              previousMonthTotal
            )})`}>
            <div>
              <div>{formatMoney(total)}</div>
              <div className={getMetricColor(percentageDifTotal)}>{percentageDifTotal}%</div>
            </div>
          </Tooltip>
        )
      }
    },
    {
      key: 'volume',
      label: 'Volumen (m³)',
      renderDataCell: ({ m3, billingMetricsResponse: { percentageDifM3 } }) => {
        const previousMonthM3 = m3 - (m3 / (100 + percentageDifM3)) * 100
        return (
          <Tooltip
            title={`Diferencia con el período anterior: ${formatNumber(m3 - previousMonthM3)} (${formatNumber(
              previousMonthM3
            )})`}>
            <div>
              <div>{formatNumber(m3)}</div>
              <div className={getMetricColor(percentageDifM3)}>{percentageDifM3}%</div>
            </div>
          </Tooltip>
        )
      }
    },
    {
      key: 'm3Price',
      label: 'Precio del m³ ($)',
      renderDataCell: ({ m3Value }) => formatMoney(m3Value)
    },
    {
      key: 'status',
      label: 'Estado',
      renderDataCell: ({ status }) => BillingStatus[status as keyof typeof BillingStatus] || status,
      // @ts-ignore
      filters: getFilterProps(BillingStatusFilterOptions, categoryFilter, 'status', handleCategoryFilter)
    },
    {
      key: 'excluded',
      label: 'Excluido',
      minWidth: 'min-content',
      maxWidth: '124px',
      // @ts-ignore
      filters: getFilterProps(BillingExcludedUsersOptions, categoryFilter, 'excludedUsers', handleCategoryFilter),
      renderDataCell: ({ excludedUserResponse }) => (
        <Tooltip
          title={
            excludedUserResponse.id ? (
              <div>
                <div>Período excluido:</div>
                <div>
                  {excludedUserResponse.dateTo
                    ? `A partir del ${excludedUserResponse.dateFrom
                        ?.utc()
                        .format('DD/MM/YYYY')} hasta el ${excludedUserResponse.dateTo
                        ?.utc()
                        .format('DD/MM/YYYY')} inclusive`
                    : 'Para siempre'}
                </div>
                <div>Razón:</div>
                <div>{excludedUserResponse.reason.description}</div>
              </div>
            ) : (
              ''
            )
          }>
          {excludedUserResponse.id ? 'Sí' : 'No'}
        </Tooltip>
      )
    },
    {
      key: 'previousOperations',
      label: 'Operaciones Ant.',
      tooltip: 'Operaciones realizadas durante el período anterior',
      renderDataCell: ({ billingMetricsResponse: { depositQuantity, removalsQuantity } }) => {
        return (
          <Tooltip title={`Operaciones realizadas durante el período anterior`}>
            <div>
              IN: {depositQuantity} DEV: {removalsQuantity}
            </div>
          </Tooltip>
        )
      }
    }
  ]

  return (
    <div>
      <div className={styles.topBar}>
        <div className={styles.metrics}></div>
        <TableActionBar>
          <TableActionBar.Wrapper>
            <TableActionBar.ButtonAction toolTip variant="csv" onClickButton={handleSendCSV} disabled={sendingCSV} />
            <TableActionBar.ButtonAction
              titleButton={strings.Billings.tableActions.exportPayments}
              onClickButton={handleExportPayments}
              disabled={exportingPayments}
            />
            <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={() => handleResetFilters()} />
            <div style={{ borderLeft: '1.5px solid #e1e6ef ' }} />

            <TableActionBar.Filters titleFilter="Período">
              <div className={styles.fieldContainer}>
                <MonthPicker
                  value={monthPickerValue?.startDate}
                  format={monthFormat}
                  allowClear={false}
                  onChange={(date) =>
                    handleRangePicker(
                      BillingsTableDateRangeKey.PERIOD,
                      date?.clone().startOf('month'),
                      date?.clone().endOf('month')
                    )
                  }
                  disabledDate={(current) => current?.isAfter(CURRENT_PERIOD) || false}
                />
              </div>
            </TableActionBar.Filters>
          </TableActionBar.Wrapper>
          <TableActionBar.ButtonAction onClickButton={handleBillPeriod} titleButton="Facturar abonos" />
        </TableActionBar>
        <DynamicTable
          loadingRows={loadingRows}
          columns={columns}
          rowHeight={65}
          loading={loadingBillings}
          actions={billingActions}
          rows={billings}
          keyExtractor={(entity) => entity.userResponse.id}
          pagination={pagination}
          rowExpander={{
            onExpansionClick: (row) => {
              getNewDataFromClient({
                userId: row.userResponse.id,
                startDate: row.startDate?.utc().format('YYYY-MM-DD') || '',
                endDate: row.endDate?.utc().format('YYYY-MM-DD') || ''
              })
            },
            render: () => (
              <NewBillingExpanded
                billingsByClient={billingsByClient}
                isLoadingClient={isLoadingClient}
                paginationClient={paginationClient}
                billingByClientActions={billingByClientActions}
              />
            )
          }}
          customHeightOffset={405}
        />
        <SendDebtReminderModalContainer />
        <BillPeriodModalContainer />
        <EmailBilledContainer />
        <UserExclusionModalsContainer />
        <CancelSubscriptionModal />
      </div>
    </div>
  )
}

const NewBillingExpanded: React.FC<{
  billingsByClient: Billing[]
  isLoadingClient: boolean
  paginationClient: Pagination
  billingByClientActions: MenuAction<Billing>[]
}> = ({ billingsByClient, isLoadingClient, paginationClient, billingByClientActions }) => {
  const columns: Column<Billing>[] = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'sourceType',
      label: 'Tipo de operación',
      renderDataCell: ({ sourceType }) => BillingType[sourceType] || sourceType || '-'
    },
    {
      key: 'createdAt',
      label: 'Fecha de creación',
      renderDataCell: ({ createdAt }) => parseMomentDate(createdAt)
    },
    {
      key: 'endDate',
      label: 'Fechas',
      renderDataCell: ({ startDate, endDate }) => {
        return (
          <div>
            <div>{startDate?.utc().format('DD-MM-YY') || ''}</div>
            <div>{endDate?.utc().format('DD-MM-YY') || ''}</div>
          </div>
        )
      }
    },
    {
      key: 'total',
      label: 'Monto ($)',
      renderDataCell: ({ totalPrice }) => formatMoney(totalPrice)
    },
    {
      key: 'm3',
      label: 'Volumen (m³)',
      renderDataCell: ({ m3 }) => formatNumber(m3, 3)
    },
    {
      key: 'm3Value',
      label: 'Precio del m³ ($)',
      renderDataCell: ({ m3Value }) => formatMoney(m3Value)
    },
    {
      key: 'status',
      label: 'Estado',
      renderDataCell: ({ status }) => BillingStatus[status]
    }
  ]

  return (
    <div style={{ padding: '12px 0', maxHeight: 500 }}>
      <DynamicTable
        columns={columns}
        loading={isLoadingClient}
        rows={billingsByClient}
        pagination={paginationClient}
        actions={billingByClientActions}
        keyExtractor={(entity) => entity.id}
        customClassNames={{
          table: styles.transactionsTable,
          loadingContainer: styles.loadingContainer
        }}
        customStrings={{
          emptyState: (
            <div className={styles.txEmptyState}>
              <span>Este abono no tiene ingresos o devoluciones.</span>
            </div>
          )
        }}
      />
    </div>
  )
}

export default NewBillingTable
