import { SaleStatusOptions } from '../../../projectApi/Sales/status'

export interface SalesState {
  status: SaleStatusOptions[]
}

export const GET_SALES_STATUS_REQUEST = 'SALES_REQUESTS/GET_SALES_STATUS_REQUEST'
export const GET_SALES_STATUS_SUCCESS = 'SALES_REQUESTS/GET_SALES_STATUS_SUCCESS'
export const GET_SALES_STATUS_FAILURE = 'SALES_REQUESTS/GET_SALES_STATUS_FAILURE'

export interface GetSalesStatusRequest {
  type: typeof GET_SALES_STATUS_REQUEST
}

export interface GetSalesStatusSuccess {
  type: typeof GET_SALES_STATUS_SUCCESS
  payload: { statusOptions: SaleStatusOptions[] }
}

export interface GetSalesStatusFailure {
  type: typeof GET_SALES_STATUS_FAILURE
  payload: { error: string }
}

export type GetSalesStatusAction = GetSalesStatusRequest | GetSalesStatusSuccess | GetSalesStatusFailure
