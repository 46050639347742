import * as React from 'react'
import styles from './DepositHistory.module.scss'
import { useHistory } from 'react-router'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { TableActionBar } from '../../../components/TableActionBar'
import { Audit } from '../../../common/operations/audits/entities'
import { formatDate } from '../../../utils/formatDate'
import { changeSpan, determinateIfValueChange } from '../../../common/operations/audits/utils'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

export interface DepositHistoryProps {
  audits: Audit[]
}

const DepositHistory = ({ audits }: DepositHistoryProps) => {
  const history = useHistory()

  const handleReturnButton = () => {
    history.goBack()
  }

  const columns: Column<Audit>[] = [
    {
      key: 'entityId',
      label: 'ID del ingreso'
    },
    {
      key: 'user',
      label: 'GURU',
      renderDataCell: ({ user }, index) => {
        const changeValue = determinateIfValueChange({ audits, index, keys: ['user', 'name'] })
        return user.name ? (changeValue ? changeSpan(user.name) : user.name) : '-'
      }
    },
    {
      key: 'date',
      label: 'Fecha de actualización',
      renderDataCell: ({ date }, index) => {
        const changeValue = determinateIfValueChange({ audits, index, keys: ['date'] })
        const formatedDate = formatDate(date)

        return changeValue ? changeSpan(formatedDate, 'start') : formatedDate
      }
    },
    {
      key: 'entityNew.type',
      label: 'Tipo de servicio',
      renderDataCell: ({ entityNew }, index) => {
        const changeValue = determinateIfValueChange({ audits, index, keys: ['entityNew', 'type'] })

        return changeValue ? changeSpan(capitalizeFirstLetter(entityNew.type)) : capitalizeFirstLetter(entityNew.type)
      }
    },
    {
      key: 'entityNew.serviceDate',
      label: 'Fecha de servicio',
      renderDataCell: ({ entityNew }, index) => {
        const { serviceDate } = entityNew
        const changeValue = determinateIfValueChange({
          audits,
          index,
          keys: ['entityNew', 'serviceDate']
        })
        const formatedDate = formatDate(serviceDate)

        return changeValue ? changeSpan(formatedDate, 'start') : formatedDate
      }
    },
    {
      key: 'entityNew.transportCost',
      label: 'Costo de transporte',
      renderDataCell: ({ entityNew }, index) => {
        const { transportCost } = entityNew
        const changeValue = determinateIfValueChange({
          audits,
          index,
          keys: ['entityNew', 'transportCost']
        })
        return transportCost ? (changeValue ? changeSpan(transportCost) : transportCost) : '-'
      }
    },
    {
      key: 'entityNew.address',
      label: 'Dirección de servicio',
      renderDataCell: ({ entityNew }, index) => {
        const { address } = entityNew
        const changeValue = determinateIfValueChange({ audits, index, keys: ['entityNew', 'address'] })
        return address ? (changeValue ? changeSpan(address) : address) : '-'
      }
    }
  ]

  return (
    <div className={styles.containerHistory}>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction iconType="arrow-left" titleButton="Volver" onClickButton={handleReturnButton} />
      </TableActionBar>
      <DynamicTable keyExtractor={(entity) => entity.entityId} rows={audits} loading={false} columns={columns} />
    </div>
  )
}

export default DepositHistory
