import React from 'react'
import actions from './actions'
import Component from './DropZone'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { DropzoneProps } from 'react-dropzone'

export type UploadContainerProps = {
  dropzoneOptions?: DropzoneProps
  onDropCallback: () => void
  uploadedPhoto?: string
  fileType: string
}

const Container = (ownProps: UploadContainerProps) => {
  const dispatch = useDispatch()
  const uploadPhotosActions = bindActionCreators(actions, dispatch)
  const uploadPhotosState = useSelector((state) => state.Components.UploadPhotos)

  const props = {
    ...ownProps,
    uploadPhotosActions,
    uploadPhotosState
  }

  return <Component {...props} />
}

export default Container
