import React, { useEffect } from 'react'
import { Button, Input, Modal } from 'antd'
import InputGroup from '../../../components/InputGroup'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import actions from '../actions/checkout'
import { GenerateCheckoutLinkState } from '../types/checkout'
import { sendToastNotificationSuccess } from '../../../utils/notifications'
import LoadingScreenInformation from '../../../components/LoadingScreenInformation'
import { PAYMENT_PROCESSORS } from './constants'

const styles = {
  modalContainer: {
    position: 'relative' as const,
    minHeight: 100
  },
  dialogSubtitle: {
    marginBottom: 20
  },
  dialogContainer: {
    maxWidth: 700,
    position: 'relative'
  },
  error: {
    color: 'red',
    textAlign: 'center' as const,
    marginTop: 10,
    marginBottom: 0
  }
}

type CheckoutComponentProps = {
  checkoutActions: typeof actions
  checkoutReducer: GenerateCheckoutLinkState
  paymentProcessor: PAYMENT_PROCESSORS
}

const CheckoutPanel = (props: CheckoutComponentProps) => {
  const { checkoutActions, checkoutReducer, paymentProcessor } = props
  const { closeModal, generateCheckoutLink, getExistingCheckoutLink } = checkoutActions

  const { generated, open, loading, loadingExistingLink, error, userId, userName, userLastName, userCountry, link } =
    checkoutReducer

  useEffect(() => {
    if (open) getExistingCheckoutLink(userId, userCountry, paymentProcessor)
  }, [open])

  const onCopy = () => sendToastNotificationSuccess('Link copiado al portapapeles')

  return (
    <Modal
      title="Generar link de checkout"
      visible={open}
      closable={!loading}
      maskClosable={!loading}
      onCancel={closeModal}
      footer={null}>
      <div style={styles.modalContainer}>
        {loadingExistingLink && (
          <LoadingScreenInformation description="Validando si existe link existente. Por favor espere..." />
        )}
        <p>
          Usuario: ({userId}) {userName} {userLastName}
        </p>
        <InputGroup style={{ border: 'none', display: 'flex' }}>
          <Button
            disabled={generated}
            onClick={() => generateCheckoutLink(userId, userCountry, paymentProcessor)}
            loading={loading}
            type="primary">
            Generar
          </Button>
          <Input type="text" value={link} contentEditable={false} />
          <CopyToClipboard text={link} onCopy={onCopy}>
            <Button disabled={link === ''}>Copiar</Button>
          </CopyToClipboard>
        </InputGroup>
        <p style={styles.error}>{error}</p>
      </div>
    </Modal>
  )
}

export default CheckoutPanel
