import { Provider } from '../../../projectApi/Trips/Providers/list'

export type ABMProvidersModalState = {
  editMode: boolean
  modalVisible: boolean
  deleteModalVisible: boolean
  loadingProvider: boolean
  providerId: number
  providerName: string
  selectedProvider?: Provider
  error: string
}

export enum SettersProvidersModalTypes {
  SET_MODAL_VISIBILITY = 'PROVIDER/ABM_PROVIDERS_MODAL/SET_MODAL_VISIBILITY',
  SET_PROVIDER_NAME = 'PROVIDER/ABM_PROVIDERS_MODAL/SET_PROVIDER_NAME',
  SET_DELETE_MODAL_VISIBILITY = 'PROVIDER/ABM_PROVIDERS/SET_DELETE_MODAL_VISIBILITY',
  SET_SELECTED_PROVIDER = 'PROVIDER/ABM_PROVIDERS/SET_SELECTED_PROVIDER'
}

export interface SetModalVisibility {
  type: typeof SettersProvidersModalTypes.SET_MODAL_VISIBILITY
  payload: { modalVisible: boolean; editMode?: boolean }
}

export interface SetProviderName {
  type: typeof SettersProvidersModalTypes.SET_PROVIDER_NAME
  payload: { providerName: string }
}

export interface SetDeleteModalVisibility {
  type: typeof SettersProvidersModalTypes.SET_DELETE_MODAL_VISIBILITY
  payload: { deleteModalVisibility: boolean }
}

export interface SetSelectedProvider {
  type: typeof SettersProvidersModalTypes.SET_SELECTED_PROVIDER
  payload: { selectedProvider: Provider }
}

type SettersProviderModalActions = SetModalVisibility | SetProviderName | SetDeleteModalVisibility | SetSelectedProvider

export enum CreateProviderTypes {
  CREATE_PROVIDER_REQUEST = 'PROVIDER/ABM_PROVIDERS_MODAL/CREATE_PROVIDER_REQUEST',
  CREATE_PROVIDER_SUCCESS = 'PROVIDER/ABM_PROVIDERS_MODAL/CREATE_PROVIDER_SUCCESS',
  CREATE_PROVIDER_FAILURE = 'PROVIDER/ABM_PROVIDERS_MODAL/CREATE_PROVIDER_FAILURE'
}

export interface CreateProviderRequest {
  type: CreateProviderTypes.CREATE_PROVIDER_REQUEST
}

export interface CreateProviderSuccess {
  type: CreateProviderTypes.CREATE_PROVIDER_SUCCESS
}

export interface CreateProviderFailure {
  type: CreateProviderTypes.CREATE_PROVIDER_FAILURE
  payload: {
    error: string
  }
}

export type CreateProviderActions = CreateProviderRequest | CreateProviderSuccess | CreateProviderFailure

export enum DeleteProviderTypes {
  DELETE_PROVIDER_REQUEST = 'PROVIDER/ABM_PROVIDERS_MODAL/DELETE_PROVIDER_REQUEST',
  DELETE_PROVIDER_SUCCESS = 'PROVIDER/ABM_PROVIDERS_MODAL/DELETE_PROVIDER_SUCCESS',
  DELETE_PROVIDER_FAILURE = 'PROVIDER/ABM_PROVIDERS_MODAL/DELETE_PROVIDER_FAILURE'
}

export interface DeleteProviderRequest {
  type: DeleteProviderTypes.DELETE_PROVIDER_REQUEST
}

export interface DeleteProviderSuccess {
  type: DeleteProviderTypes.DELETE_PROVIDER_SUCCESS
}

export interface DeleteProviderFailure {
  type: DeleteProviderTypes.DELETE_PROVIDER_FAILURE
  payload: {
    error: string
  }
}

export type DeleteProviderActions = DeleteProviderRequest | DeleteProviderSuccess | DeleteProviderFailure

export enum EditProviderTypes {
  EDIT_PROVIDER_REQUEST = 'PROVIDER/CREATE_PROVIDER/EDIT_PROVIDER_REQUEST',
  EDIT_PROVIDER_SUCCESS = 'PROVIDER/CREATE_PROVIDER/EDIT_PROVIDER_SUCCESS',
  EDIT_PROVIDER_FAILURE = 'PROVIDER/CREATE_PROVIDER/EDIT_PROVIDER_FAILURE'
}

export interface EditProviderRequest {
  type: EditProviderTypes.EDIT_PROVIDER_REQUEST
}

export interface EditProviderSuccess {
  type: EditProviderTypes.EDIT_PROVIDER_SUCCESS
}

export interface EditProviderFailure {
  type: EditProviderTypes.EDIT_PROVIDER_FAILURE
  payload: {
    error: string
  }
}

export type EditProviderActions = EditProviderRequest | EditProviderSuccess | EditProviderFailure

export type ABMProvidersActions =
  | SettersProviderModalActions
  | CreateProviderActions
  | DeleteProviderActions
  | EditProviderActions
