import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { GeneralDataCreateStates } from '../../../sections/NewProduct/types/common'
import { ProductCostsState } from '../../../sections/NewProduct/types/productCosts'

interface UpdateParams {
  generalData: GeneralDataCreateStates
  productCosts: ProductCostsState
}

export function update(params: UpdateParams): Promise<void> {
  const { generalData, productCosts } = params

  const argentinaFields = productCosts.ARG
  const NewProduct = new FormData()

  const productsByCountries = Object.entries(productCosts).map(([key, value]) => ({
    country_id: key,
    description: value.productName,
    deposit_cost_per_floor_in_cents: value.isDepositAutomaticPrice
      ? value.depositAutomaticPrice
      : Number(value.depositCostPerFloor) * 100,
    packaging_cost_in_cents: Number(value.depositPackagingCost) * 100,
    disassembly_cost: Number(value.depositDisassemblyCost),
    cost_per_floor_in_cents: value.isRemovalAutomaticPrice
      ? value.removalAutomaticPrice
      : Number(value.removalCostPerFloor) * 100,
    assembly_cost_in_cents: Number(value.removalAssemblyCost) * 100,
    unpackaging_cost_in_cents: Number(value.removalUnpackagingCost) * 100,
    max_amount_avowed_in_cents: Number(value.maxAmountAvowed) * 100,
    percentage_avowed: Number(value.percentageOnDeclaredPrice),
    max_refund_amount_in_cents: Number(value.maxRefundValue) * 100,
    eligible_for_sale: value.eligibility.isElegibleForSale,
    eligible_for_donation: value.eligibility.isElegibleForDonation,
    eligible_for_recycled: value.eligibility.isElegibleForRecycled,
    eligible_for_rent: value.eligibility.isElegibleForRent,
    photo_url: value.photoUrl,
    photo_key: value.photoKey
  }))

  NewProduct.append('countries_label', JSON.stringify({ countries: productsByCountries }))

  NewProduct.append('assembly_cost_in_cents', String(Math.round(Number(argentinaFields.removalAssemblyCost) * 100)))
  NewProduct.append(
    'removal_packaging_cost_in_cents',
    String(Math.round(Number(argentinaFields.removalUnpackagingCost) * 100))
  )

  NewProduct.append('description', argentinaFields.productName)
  NewProduct.append('packaging_cost_in_cents', String(Math.round(Number(argentinaFields.depositPackagingCost) * 100)))
  NewProduct.append('disassembly_cost', String(Math.round(Number(argentinaFields.depositDisassemblyCost))))
  NewProduct.append(
    'floors_by_stairs_cost_in_cents',
    String(Math.round(Number(argentinaFields.removalCostPerFloor) * 100))
  )

  if (generalData.categorySelected) {
    NewProduct.append('categories[]', generalData.categorySelected.value.toString())
  }
  NewProduct.append('weight_in_gr', String(Number(generalData.dimensionsWeight) * 1000))
  NewProduct.append('width_in_cm', generalData.dimensionsWidth)
  NewProduct.append('height_in_cm', generalData.dimensionsHeight)
  NewProduct.append('length_in_cm', generalData.dimensionsLength)
  NewProduct.append('min_volume_deviation', String(Number(generalData.minDeviation) / 100))
  NewProduct.append('max_volume_deviation', String(Number(generalData.maxDeviation) / 100))
  NewProduct.append('show_in_search', String(generalData.isProductSearchable))
  NewProduct.append('packaging_time', String(generalData.packagingTime))
  NewProduct.append('unpackaging_time', String(generalData.disassembleTime))
  NewProduct.append('multiplier', generalData.multiplier)
  NewProduct.append('photo_min', String(generalData.minPhotos))
  generalData.solutionsSelected.forEach((solution) => NewProduct.append('solutions[]', String(solution.value)))

  return apiPrivate
    .put(`${URL_BASE_CATEGORIES}/product/${generalData.guid}`, NewProduct)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
