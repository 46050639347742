import { FormattedProduct } from '../../../projectApi/CategoryCreation/Product/common'

export const SET_ERROR = 'PRODUCTS/SEND/SET_ERROR'
export const OPEN_DELETE_PRODUCT_MODAL = 'PRODUCTS/SEND/OPEN_DELETE_PRODUCT_MODAL'
export const CLOSE_DELETE_PRODUCT_MODAL = 'PRODUCTS/SEND/CLOSE_DELETE_PRODUCT_MODAL'
export const CANCEL_DELETE_PRODUCT_MODAL = 'PRODUCTS/SEND/CANCEL_DELETE_PRODUCT_MODAL'
export const DELETE_PRODUCT_REQUEST = 'PRODUCTS/SEND/DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'PRODUCTS/SEND/DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILURE = 'PRODUCTS/SEND/DELETE_PRODUCT_FAILURE'

export interface DeleteProductState {
  open: boolean
  product: FormattedProduct | null
  error: string
}
export interface SetError {
  type: typeof SET_ERROR
  payload: {
    error: string
  }
}
export interface OpenDeleteProductModal {
  type: typeof OPEN_DELETE_PRODUCT_MODAL
  payload: {
    product: FormattedProduct
  }
}
export interface CloseDeleteProductModal {
  type: typeof CLOSE_DELETE_PRODUCT_MODAL
}
export interface CancelDeleteProductModal {
  type: typeof CANCEL_DELETE_PRODUCT_MODAL
}

export type OpenCloseCancelAction = OpenDeleteProductModal | CloseDeleteProductModal | CancelDeleteProductModal
export interface DeleteProductRequest {
  type: typeof DELETE_PRODUCT_REQUEST
  payload: {
    id: string
  }
}
export interface DeleteProductSuccess {
  type: typeof DELETE_PRODUCT_SUCCESS
}
export interface DeleteProductFailure {
  type: typeof DELETE_PRODUCT_FAILURE
  payload: {
    error: string
  }
}

export type DeleteProductAction = DeleteProductRequest | DeleteProductSuccess | DeleteProductFailure

export type DeleteProductActions =
  | DeleteProductRequest
  | DeleteProductSuccess
  | DeleteProductFailure
  | OpenDeleteProductModal
  | CloseDeleteProductModal
  | CancelDeleteProductModal
  | SetError
