import { API } from '../../../../projectApi'
import { AppThunk } from '../../../../store'
import {
  GET_SERVICE_TYPES_FAILURE,
  GET_SERVICE_TYPES_REQUEST,
  GET_SERVICE_TYPES_SUCCESS,
  GetServiceTypesFailure,
  GetServiceTypesRequest,
  GetServiceTypesSuccess,
  Option,
  SET_PRICE_BY,
  SET_SERVICE_TYPE,
  SetPriceBy,
  SetServiceType
} from '../../types/EditDepositViewTypes/serviceType'

const ServiceTypesActionCreators = {
  getServiceTypes: (): AppThunk => async (dispatch) => {
    const request: GetServiceTypesRequest = {
      type: GET_SERVICE_TYPES_REQUEST
    }

    dispatch(request)

    try {
      const { services } = await API.TransactionHandler.ServiceType.list()
      if (!services) throw new Error('Error al obtener los tipos de servicios')

      const success: GetServiceTypesSuccess = {
        type: GET_SERVICE_TYPES_SUCCESS,
        payload: {
          serviceTypes: services
        }
      }

      dispatch(success)
    } catch (error) {
      const failure: GetServiceTypesFailure = {
        type: GET_SERVICE_TYPES_FAILURE
      }

      dispatch(failure)
    }
  },
  setServiceType: (serviceType: Option): SetServiceType => {
    return {
      type: SET_SERVICE_TYPE,
      payload: { serviceType }
    }
  },
  setPriceBy: (service: string): SetPriceBy => {
    return {
      type: SET_PRICE_BY,
      payload: { service }
    }
  }
}

export default ServiceTypesActionCreators
