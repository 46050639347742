import React from 'react'
import styles from './Overlay.module.scss'
import clsx from 'clsx'

const Overlay: React.FC<{ buttons?: React.ReactNode; customClassName?: string }> = ({
  children,
  buttons,
  customClassName
}) => (
  <div className={clsx(styles.Overlay, customClassName)}>
    <div className={styles.ChildrenContainer}>{children}</div>
    {buttons && <div className={styles.ButtonsContainer}>{buttons}</div>}
  </div>
)

export default Overlay
