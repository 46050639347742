export default {
  OPEN_MODAL: 'PHOTOGRAPH_OBJECTS/OPEN_MODAL',
  CLOSE_MODAL: 'PHOTOGRAPH_OBJECTS/CLOSE_MODAL',
  SET_DEFAULT_PHOTO_REQUEST: 'PHOTOGRAPH_OBJECTS/SET_DEFAULT_PHOTO_REQUEST',
  SET_DEFAULT_PHOTO_SUCCESS: 'PHOTOGRAPH_OBJECTS/SET_DEFAULT_PHOTO_SUCCESS',
  SET_DEFAULT_PHOTO_FAILURE: 'PHOTOGRAPH_OBJECTS/SET_DEFAULT_PHOTO_FAILURE',
  ADD_PHOTO_REQUEST: 'PHOTOGRAPH_OBJECTS/ADD_PHOTO_REQUEST',
  ADD_PHOTO_SUCCESS: 'PHOTOGRAPH_OBJECTS/ADD_PHOTO_SUCCESS',
  ADD_PHOTO_FAILURE: 'PHOTOGRAPH_OBJECTS/ADD_PHOTO_FAILURE'
}
