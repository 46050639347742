import { OperationTypeValues } from '../types/common'

export const getOperation = (operation: OperationTypeValues) => {
  switch (operation) {
    case 'removal_operation':
      return 'Devolución'
    case 'deposit_operation':
      return 'Depósito'
    default:
      return '-'
  }
}
