import React, { Component } from 'react'
import UsersTable from './containers/usersTable.js'
import DisablePanel from './DisablePanel.js'
import EnablePanel from './EnablePanel.js'
import PropTypes from 'prop-types'
import actions from './Users.actions'
import identificationTypesActions from './actions/identificationTypes'
import rolesActions from './actions/roles'
import { connect } from 'react-redux'
import NewUserModal from './containers/newUserModal.js'
import LoginAsModal from './LoginAsModal.js'
import TermsPanel from './containers/termsPanel'
import BillingModal from './containers/billingModal'
import CheckoutModal from './containers/checkoutModal'

const initialState = {
  deleteModalOpen: false,
  enableModalOpen: false,
  cardModalOpen: false,

  selectedUser: {},

  idTypeOptions: []
}

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = initialState

    this.props.getDocumentTypes()
    this.props.getRoles()
  }

  toggleDeletePanel = (open, user = {}) => {
    this.setState({
      deleteModalOpen: open,
      selectedUser: user
    })
  }

  toggleEnablePanel = (open, user = {}) => {
    this.setState({
      enableModalOpen: open,
      selectedUser: user
    })
  }

  render() {
    const {
      userAdmin,
      selectedUser,
      loginAsModalOpen,
      loadingLoginAs,
      errorLoginAs,
      setLoginAsModalOpen,
      setAdminEmail,
      setAdminPassword,
      setRedirection,
      loginAsUser
    } = this.props

    return (
      <div className="animated fadeIn">
        <UsersTable toggleDeletePanel={this.toggleDeletePanel} toggleEnablePanel={this.toggleEnablePanel} />

        <NewUserModal />

        <LoginAsModal
          open={loginAsModalOpen}
          user={selectedUser}
          userAdmin={userAdmin}
          loading={loadingLoginAs}
          error={errorLoginAs}
          setLoginAsModalOpen={setLoginAsModalOpen}
          setAdminEmail={setAdminEmail}
          setAdminPassword={setAdminPassword}
          setRedirection={setRedirection}
          loginAsUser={loginAsUser}
        />

        <DisablePanel
          open={this.state.deleteModalOpen}
          user={this.state.selectedUser}
          toggleDeletePanel={this.toggleDeletePanel}
        />

        <EnablePanel
          open={this.state.enableModalOpen}
          user={this.state.selectedUser}
          toggleEnablePanel={this.toggleEnablePanel}
        />

        <TermsPanel />
        <BillingModal />
        <CheckoutModal />
      </div>
    )
  }
}

Users.propTypes = {
  loginAsModalOpen: PropTypes.bool.isRequired,
  userAdmin: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  loadingDNI: PropTypes.bool,
  loadingEmail: PropTypes.bool,
  loadingLoginAs: PropTypes.bool,
  errorLoginAs: PropTypes.string,
  errorEmail: PropTypes.string,
  errorDNI: PropTypes.string,
  selectedUser: PropTypes.shape().isRequired,
  setAdminEmail: PropTypes.func.isRequired,
  setAdminPassword: PropTypes.func.isRequired,
  setRedirection: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getDocumentTypes: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { Users } = state
  const { identificationTypes, loginAsUser } = Users

  return {
    // paymentMethods: paymentMethods.paymentMethods,
    docTypesFetched: identificationTypes.fetched,
    loadingLoginAs: loginAsUser.loading,
    loginAsModalOpen: loginAsUser.loginAsModalOpen,
    selectedUser: loginAsUser.selectedUser,
    userAdmin: loginAsUser.userAdmin,
    errorLoginAs: loginAsUser.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: () => dispatch(rolesActions.getRoles()),
    getDocumentTypes: () => dispatch(identificationTypesActions.getDocumentTypes()),
    setLoginAsModalOpen: (loginAsModalOpen, selectedUser) =>
      dispatch(actions.setLoginAsModalOpen(loginAsModalOpen, selectedUser)),
    setAdminEmail: (email) => dispatch(actions.setAdminEmail(email)),
    setAdminPassword: (password) => dispatch(actions.setAdminPassword(password)),
    setRedirection: (redirectTo) => dispatch(actions.setRedirection(redirectTo)),
    loginAsUser: (userAdmin, userID) => dispatch(actions.loginAsUser(userAdmin, userID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
