import React from 'react'
import { Col, Row } from 'antd'
import styles from './Payment.module.scss'
import Select from '../../../components/Select'
import CBU from '../../../components/CBU/container'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'
import { CollapsablePanel, ProposalHeader } from '../../../components/CollapsablePanel/CollapsablePanel'

type PaymentProps = {
  paymentBy: PAYMENT_BY_VALUES
  setPaymentBy: (paymentBy: PAYMENT_BY_VALUES) => void
}

const PAYMENT_BY_LABELS = [
  {
    label: 'Offline',
    value: PAYMENT_BY_VALUES.OFFLINE
  },
  {
    label: 'CBU',
    value: PAYMENT_BY_VALUES.CBU
  }
]

const PaymentComponent = ({ paymentBy, setPaymentBy }: PaymentProps) => {
  const selectedPaymentBy = PAYMENT_BY_LABELS.find((payment) => payment.value === paymentBy)

  return (
    <CollapsablePanel header={<ProposalHeader title="5. Pago" />}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.labelWithMargin}>Tipo de pago</div>
          <Select
            position="top"
            placeholder="Tipo de Pago"
            selected={selectedPaymentBy}
            options={PAYMENT_BY_LABELS}
            onSelect={(option) => setPaymentBy(option.value)}
          />
        </Col>
      </Row>
      {paymentBy === PAYMENT_BY_VALUES.CBU && <CBU />}
    </CollapsablePanel>
  )
}

export default PaymentComponent
