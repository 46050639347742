import React from 'react'
import styles from './Overlay.module.scss'

const Overlay: React.FC<{ buttons?: React.ReactNode }> = ({ children, buttons }) => (
  <div className={styles.Overlay}>
    <div className={styles.ChildrenContainer}>{children}</div>
    {buttons && <div className={styles.ButtonsContainer}>{buttons}</div>}
  </div>
)

export default Overlay
