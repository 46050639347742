import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Dropdown } from 'antd'
import Overlay from '../Overlay/Overlay'
import HeaderIcon from '../../HeaderIcon/HeaderIcon'
import moment, { Moment } from 'moment'
import { Calendar as CalendarIcon } from '../../../../../icons'
import { RangePickerProps } from '../../../types/types'

const { RangePicker } = DatePicker

type RangePickerDropdownProps = RangePickerProps

const RangePickerDropdown = ({ startDate, endDate, onConfirm }: RangePickerDropdownProps) => {
  const [localStartDate, setLocalStartDate] = useState<Moment>(moment())
  const [localEndDate, setLocalEndDate] = useState<Moment>(moment().add(1, 'days'))
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (startDate && !startDate?.isSame(localStartDate)) setLocalStartDate(startDate)
  }, [startDate])

  useEffect(() => {
    if (endDate && !endDate?.isSame(localEndDate)) setLocalEndDate(endDate)
  }, [endDate])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleDatesChange = (newStartDate?: Moment | null, newEndDate?: Moment | null) => {
    if (!newStartDate || !newEndDate) return

    setLocalStartDate(newStartDate)
    setLocalEndDate(newEndDate)
  }

  const handleConfirm = (reset?: boolean) => {
    onConfirm(reset ? undefined : localStartDate, reset ? undefined : localEndDate)
    setVisible(false)
  }

  const overlay = (
    <Overlay
      buttons={
        <>
          <Button type="primary" onClick={() => handleConfirm(false)} icon="search" size="small">
            Buscar
          </Button>
          <Button onClick={() => handleConfirm(true)} size="small">
            Resetear
          </Button>
        </>
      }>
      <RangePicker
        onCalendarChange={([newStartDate, newEndDate]) => handleDatesChange(newStartDate, newEndDate)}
        value={[localStartDate, localEndDate]}
        size="small"
        format="DD/MM/YYYY"
      />
    </Overlay>
  )

  return (
    <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
      <div>
        <HeaderIcon overlayOpen={visible} active={Boolean(startDate || endDate)}>
          <CalendarIcon />
        </HeaderIcon>
      </div>
    </Dropdown>
  )
}

export default RangePickerDropdown
