import { getCurrentPriceBand } from './getCurrentPriceBand'
import { deletePriceBand } from './delete'
import { createPriceBand } from './create'
import { updatePriceBand } from './update'

export const PriceBandAPI = {
  getCurrentPriceBand,
  deletePriceBand,
  createPriceBand,
  updatePriceBand
}
