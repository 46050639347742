import React, { ReactElement } from 'react'
import { Icon } from 'antd'
import styled from 'styled-components'

type ContainerProps = { selected: boolean }

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 450px;
  height: 98px;
  border: 1px solid #d9d9d9;
  background-color: ${(props) => (props.selected ? 'rgba(247, 247, 247, 0.6)' : '#FFFFFF')};
  padding: 0 24px;

  :hover {
    background-color: ${(props) => (props.selected ? 'rgba(247, 247, 247, 0.6)' : '#F7F7F7')};
  }
`

const IconContainer = styled.div`
  height: auto;
  padding: 0 16px 0 0;
`

const PaymentDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #242424;
`

const Description = styled.span`
  font-size: 12px;
  color: #868686;
`

const SelectedIcon = styled(Icon)`
  font-size: 20px;
  color: #5d58f7;
`

export type PaymentTypesProps = {
  key: string
  selected: boolean
  title: string
  description: string
  icon: ReactElement
  onClick: () => void
}

const PaymentTypes = (props: PaymentTypesProps) => {
  const { title, description, icon, onClick, selected } = props

  return (
    <Container selected={selected} onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <PaymentDescription>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </PaymentDescription>
      {selected && <SelectedIcon type="check" />}
    </Container>
  )
}

export default PaymentTypes
