export enum EditDepositViewEventsEnum {
  SET_WHERE = 'EDIT_DEPOSITS/SET_WHERE',
  SET_ITEM_PACKAGING = 'EDIT_DEPOSITS/SET_ITEM_PACKAGING',
  SET_ITEM_FLOORS = 'EDIT_DEPOSITS/SET_ITEM_FLOORS',
  SET_ITEM_DISASSEMBLE = 'EDIT_DEPOSITS/SET_ITEM_DISASSEMBLE',
  SET_PREV_TRANSPORT_COST = 'EDIT_DEPOSITS/SET_PREV_TRANSPORT_COST',
  EDIT_DEPOSIT_SUCCESS = 'EDIT_DEPOSITS/EDIT_DEPOSIT_SUCCESS',
  SET_TRANSPORT_MODE = 'EDIT_DEPOSITS/SET_TRANSPORT_MODE',
  GET_TRANSACTION_BY_ID_SUCCESS = 'EDIT_DEPOSITS/GET_TRANSACTION_BY_ID_SUCCESS'
}

export type EditDepositViewEvents = {
  [EditDepositViewEventsEnum.SET_WHERE]: () => void
  [EditDepositViewEventsEnum.SET_ITEM_PACKAGING]: () => void
  [EditDepositViewEventsEnum.SET_ITEM_FLOORS]: () => void
  [EditDepositViewEventsEnum.SET_ITEM_DISASSEMBLE]: () => void
  [EditDepositViewEventsEnum.SET_PREV_TRANSPORT_COST]: () => void
  [EditDepositViewEventsEnum.EDIT_DEPOSIT_SUCCESS]: () => void
  [EditDepositViewEventsEnum.SET_TRANSPORT_MODE]: () => void
  [EditDepositViewEventsEnum.GET_TRANSACTION_BY_ID_SUCCESS]: () => void
}
