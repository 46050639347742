import {
  REQUEST_OBJECTS_REPORT_FAILURE,
  REQUEST_OBJECTS_REPORT_REQUEST,
  REQUEST_OBJECTS_REPORT_SUCCESS,
  ReportsAction,
  ReportsReducer,
  SET_LAST_REQUEST_REPORT_TIME,
  SET_OBJECT_REPORT_REQUEST_WITH_QUERIES
} from './types'
import { REHYDRATE } from 'redux-persist/es/constants'
import { RootState } from '../../store'

const initialReport: ReportsReducer = {
  requestingReport: false,
  lastReports: {
    PRE_BILLING_REPORT: null
  },
  lastObjectReports: {}
}

function reportsReducer(state: ReportsReducer = initialReport, action: ReportsAction): ReportsReducer {
  switch (action.type) {
    case SET_LAST_REQUEST_REPORT_TIME: {
      const { report, time } = action.payload
      return {
        ...state,
        lastReports: {
          ...state.lastReports,
          [report]: time.format()
        }
      }
    }
    case REQUEST_OBJECTS_REPORT_REQUEST:
      return { ...state, requestingReport: true }
    case SET_OBJECT_REPORT_REQUEST_WITH_QUERIES:
    case REQUEST_OBJECTS_REPORT_SUCCESS: {
      const { encodedQueries, time } = action.payload
      return {
        ...state,
        requestingReport: false,
        lastObjectReports: {
          ...state.lastObjectReports,
          [encodedQueries]: time.format()
        }
      }
    }
    case REQUEST_OBJECTS_REPORT_FAILURE: {
      return { ...state, requestingReport: false }
    }
    case REHYDRATE: {
      const oldState = action.payload as Partial<RootState>

      if (oldState?.Reports) {
        const { lastReports = initialReport.lastReports, lastObjectReports = initialReport.lastObjectReports } =
          oldState.Reports
        return {
          lastReports,
          lastObjectReports,
          requestingReport: false
        }
      }

      return state
    }
    default:
      return state
  }
}

export default reportsReducer
