import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

type PhotoBody = {
  photo: File
  fileType: string
}

type APIResponse = {
  url: string
  s3_key: string
}

export type Response = CamelCasedPropertiesDeep<APIResponse>

export async function uploadPhoto(body: PhotoBody): Promise<Response> {
  const formData = new FormData()
  formData.append('photo', body.photo)
  formData.append('file_type', body.fileType)

  return apiPrivate
    .post(`${URL_BASE_CATEGORIES}/photos/upload-photo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Respuesta incorrecta al subir la foto')

      return camelcaseKeys(response.data, { deep: true })
    })
}
