import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import proposalActionsCreators from '../actions/proposals'
import costActionsCreators from '../actions/cost'
import summaryActionsCreators from '../actions/summary'
import Component from '../components/Summary'
import { useHistory } from 'react-router'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { PROPOSAL_MODES } from '../../../common/operations/constants'
import newProposal from '../reducers'
import {
  getRoundedTotalDisassemblePrice,
  getRoundedTotalFloorsPrice,
  getRoundedTotalKG,
  getRoundedTotalM3,
  getRoundedTotalPackagingPrice,
  getRoundedTotalPrice,
  getTotalItems
} from '../../../common/operations/selectors/items'
import { applyDiscount, getSubtotalCost } from '../../../common/operations/selectors/cost'

export type TotalPrices = {
  totalItems: number
  totalVolume: number
  totalWeight: number
  totalExtras: number
  spaceCost: number
  totalForPackaging: number
  totalForDisassemble: number
  totalForFloors: number
  transportCost: number
  discount: number
  additionalCostAmount: number
  pricesWithDiscount: {
    spaceCostDiscounted: number
    transportCostDiscounted: number
    packagingDiscounted: number
    disassembleDiscounted: number
    floorsDiscounted: number
    extraAssistantDiscounted: number
  }
  subTotal: number
  extraAssistantTotal: number
  total: number
}

const getTotalPrices = (props: ReturnType<typeof newProposal>): TotalPrices => {
  const { cost, extras, flags, items, transport, proposals } = props
  const { selectedProposal } = proposals
  const { cost: transportCost } = transport
  const { extraAssistant, additionalCost } = extras
  const { minBillableM3Price, extraAssistantPrice, tollCostInCents } = flags

  const tollCost = (selectedProposal?.tollCostInCents ? selectedProposal.tollCostInCents : tollCostInCents) / 100

  const { selectedItems } = items

  const totalItems = getTotalItems(selectedItems)
  const totalVolume = getRoundedTotalM3(selectedItems)
  const totalWeight = getRoundedTotalKG(selectedItems)

  const calculatedSpaceCost = getRoundedTotalPrice(selectedItems)

  const spaceCost = minBillableM3Price > calculatedSpaceCost ? minBillableM3Price : calculatedSpaceCost
  const additionalCostAmount = additionalCost.enabled ? additionalCost.amount : 0
  const totalForPackaging = getRoundedTotalPackagingPrice(selectedItems)
  const totalForDisassemble = getRoundedTotalDisassemblePrice(selectedItems)
  const totalForFloors = getRoundedTotalFloorsPrice(selectedItems)

  const extraAssistantTotal = extraAssistant.enabled ? extraAssistantPrice * extraAssistant.count : 0
  const subTotal = spaceCost + transportCost
  const subTotalWithExtras = subTotal + totalForPackaging + totalForDisassemble + totalForFloors + extraAssistantTotal
  const totalExtras =
    totalForPackaging + totalForDisassemble + totalForFloors + extraAssistantTotal + additionalCostAmount

  const spaceCostDiscounted = applyDiscount(spaceCost, cost.discount?.deposit || 0)
  const transportCostDiscounted = applyDiscount(transportCost, cost.discount?.transport || 0)

  const subTotalDiscounted =
    getSubtotalCost(spaceCostDiscounted, transportCostDiscounted) +
    totalForPackaging +
    totalForDisassemble +
    totalForFloors +
    extraAssistantTotal
  const totalDiscounted = applyDiscount(subTotalDiscounted, cost.discount?.general || 0)

  const discount = subTotalWithExtras - totalDiscounted
  const pricesWithDiscount = {
    spaceCostDiscounted: applyDiscount(spaceCostDiscounted, cost.discount?.general || 0),
    transportCostDiscounted: applyDiscount(transportCostDiscounted, cost.discount?.general || 0),
    packagingDiscounted: applyDiscount(totalForPackaging, cost.discount?.general || 0),
    disassembleDiscounted: applyDiscount(totalForDisassemble, cost.discount?.general || 0),
    floorsDiscounted: applyDiscount(totalForFloors, cost.discount?.general || 0),
    extraAssistantDiscounted: applyDiscount(extraAssistantTotal, cost.discount?.general || 0)
  }

  const total = totalDiscounted + additionalCostAmount + (transportCostDiscounted ? tollCost : 0)

  return {
    totalItems,
    totalVolume,
    totalWeight,
    totalExtras,
    spaceCost,
    totalForPackaging,
    totalForDisassemble,
    totalForFloors,
    transportCost,
    additionalCostAmount,
    discount,
    pricesWithDiscount,
    subTotal: subTotalWithExtras,
    extraAssistantTotal,
    total
  }
}

type SummaryProps = {
  mode: typeof PROPOSAL_MODES[keyof typeof PROPOSAL_MODES]
}

const Container = (ownProps: SummaryProps) => {
  const { mode } = ownProps
  const [downloadPDF, setDownloadPDF] = useState(false)
  const [loadingPDF, setLoadingPDF] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const proposalActions = bindActionCreators(proposalActionsCreators, dispatch)
  const costActions = bindActionCreators(costActionsCreators, dispatch)
  const summaryActions = bindActionCreators(summaryActionsCreators, dispatch)

  const newProposalReducer = useSelector((state) => state.NewProposal)
  const addressReducer = useSelector((state) => state.Components.address)
  const cbuReducer = useSelector((state) => state.Components.cbu)
  const userReducer = useSelector((state) => state.Users.newUser)

  useEvents([Events.Proposal.SAVE_DEPOSIT_SUCCESS], () => {
    history.push('/leads')
  })

  useEvents([Events.Proposal.SAVE_PROPOSAL_SUCCESS], async ({ proposalId }) => {
    if (downloadPDF) {
      await summaryActions.getProposalPDF({ proposalId })
    }
    history.push('/leads')
  })

  const save = async () => {
    if (mode === PROPOSAL_MODES.DEPOSIT_FROM_PROPOSAL) {
      proposalActions.saveDepositByProposal(totalPrices, newProposalReducer, addressReducer, cbuReducer, userReducer)
    } else {
      proposalActions.saveProposal(totalPrices, newProposalReducer, addressReducer)
    }
  }

  const handleDownloadPDFAtViewProposal = async () => {
    const proposalId = newProposalReducer.proposals.selectedProposal?.id
    if (proposalId) {
      setLoadingPDF(true)
      await summaryActions.getProposalPDF({ proposalId })
      setLoadingPDF(false)
    }
  }

  const totalPrices = getTotalPrices(newProposalReducer)

  const props = {
    globalDisabled: mode === PROPOSAL_MODES.VIEW_PROPOSAL,
    addressReducer,
    newProposalReducer,
    costActions,
    totalPrices,
    handleDownloadPDFAtViewProposal,
    save,
    downloadPDF,
    loadingPDF,
    setDownloadPDF,
    mode
  }

  return <Component {...props} />
}

export default Container
