import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export type PaymentParams = {
  userToken: string
  operationId: number
  amountInCents: number
  spreedlyPmId: number
}

export function CollectRemoval({ userToken, operationId, amountInCents, spreedlyPmId }: PaymentParams): Promise<void> {
  const body = snakecaseKeys({ userToken, operationId, amountInCents, spreedlyPmId })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/operations/collect-removal`, body)
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
