import React, { useEffect, useState } from 'react'
import { Col, Input, Modal, Row } from 'antd'
import InputGroup from '../../../components/InputGroup'
import editOriginActions from '../actionCreator/editOrigin'
import { EditOriginState } from '../types/editOrigin'
import Select from '../../../components/Select'
import { BaseOption } from '../../../components/Select/Select'
import styles from './EditOrigin.module.scss'
import { Source } from '../../../projectApi/Mercurio/BO/Config/list'

export interface EditOriginModalProps {
  editOrigin: EditOriginState
  editOriginActions: typeof editOriginActions
  sources: Source[]
}

type Option = BaseOption<number>

const EditOriginComponent = (props: EditOriginModalProps) => {
  const { editOriginActions, editOrigin, sources } = props
  const { open, error, isLoading, sirenaSource, channel, id } = editOrigin
  const { cancelEditOriginModal } = editOriginActions

  useEffect(() => {
    setName('')
    setNewChannel(null)
  }, [open])

  const [newChannel, setNewChannel] = useState<Option | null>(null)
  const [name, setName] = useState('')
  const channelOptions = sources.map((option) => ({ label: option.spanishDisplay, value: option.id }))

  const handleEditOrigin = (id: number, sirenaSource: string, channel: Option | null) => {
    editOriginActions.editOrigin({ id, sirenaSource, channel: channel?.value || 0 })
  }

  return (
    <Modal
      title="Editar origen"
      visible={open}
      onOk={() => handleEditOrigin(id, name, newChannel)}
      onCancel={() => cancelEditOriginModal()}
      okText="Modificar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading }}
      confirmLoading={isLoading}
      closable={!isLoading}
      maskClosable={!isLoading}
      className={styles.modal}>
      <InputGroup title="Datos">
        <Row gutter={[24, 24]}>
          <Col sm={10} xs={24}>
            <div className={styles.inputContainer}>
              <p className={styles.inputTitle}>Nombre actual:</p>
              <span>{sirenaSource}</span>
            </div>
            <div>
              <p className={styles.inputTitle}>Canal actual:</p>
              <span>{channel}</span>
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div>
              <p className={styles.inputTitle}>Nuevo nombre: </p>
              <Input
                className={styles.inlineText}
                type="text"
                placeholder="Nuevo nombre"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className={styles.inputContainer}>
              <p className={styles.inputTitle}>Nuevo canal: </p>
              <Select
                options={channelOptions}
                placeholder="Seleccione canal"
                onSelect={(option) => {
                  setNewChannel(option)
                }}
                selected={newChannel}
              />
            </div>
          </Col>
        </Row>
      </InputGroup>
      {error !== '' ? <div className={styles.error}>{error}</div> : null}
    </Modal>
  )
}

export default EditOriginComponent
