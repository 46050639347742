export default {
  FETCH_BILLINGS_REQUEST: 'BILLINGS/BILLINGS_BY_CLIENT/FETCH_BILLINGS_REQUEST',
  FETCH_BILLINGS_SUCCESS: 'BILLINGS/BILLINGS_BY_CLIENT/FETCH_BILLINGS_SUCCESS',
  FETCH_BILLINGS_FAILURE: 'BILLINGS/BILLINGS_BY_CLIENT/FETCH_BILLINGS_FAIL',
  SET_PAGINATION: 'BILLINGS/BILLINGS_BY_CLIENT/SET_PAGINATION',
  SET_SORTER: 'BILLINGS/BILLINGS_BY_CLIENT/SET_SORTER',
  SET_FILTER: 'BILLINGS/BILLINGS_BY_CLIENT/SET_FILTER',
  SET_SEARCH_FILTERS: 'BILLINGS/BILLINGS_BY_CLIENT/SET_SEARCH_FILTERS',
  CLEAN_FILTERS: 'BILLINGS/BILLINGS_BY_CLIENT/CLEAN_FILTERS'
}
