import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

export type ResponseAPI = {
  name: string
}

export function listTypes(): Promise<ResponseAPI[]> {
  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/location-types`)
    .then((response: AxiosResponse<ResponseAPI[]>) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
