import React from 'react'
import { Table, Tooltip } from 'antd'
import { List, QRIcon } from '../../../icons'
import { useHistory } from 'react-router'
import { formatNumber } from '../../../utils/formatNumber'
import ActionBarButton from '../../../components/ActionBarButton/ActionBarButton'
import ActionBar from '../../../components/ActionBar/ActionBar'
import { usePaginationLabel } from '../../../utils/hooks/usePaginationLabel'
import DeletedObjectStatus from '../../Objects/components/deletedObjectStatus'
import { OBJECT_SITUATION } from '../../../projectApi/ObjectAdministration/common'

const renderObjectIDStatus = (object) => {
  const objectStatus = object.ObjectStatus.Description
  const isDeleted = object.DeleteAt

  if (isDeleted) {
    return (
      <DeletedObjectStatus
        objectDeletedReason={object.ObjectDeletedReason?.Reason}
        objectStatus={objectStatus}
        deletedDescription={object.DeleteDescription}
      />
    )
  }
  return objectStatus
}

const LocationObjects = (props) => {
  const { objects, loadingObjects, pagination, params, handleChange, openQRModal } = props

  const history = useHistory()

  const showObjectHistoryChanges = (objectID) => {
    history.push(`/objectChanges/${objectID}`)
  }

  const handleReturnButton = () => {
    history.goBack()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'RealID'
    },
    {
      title: 'Etiqueta',
      key: 'IdentificationCodes[0].Code',
      dataIndex: 'IdentificationCodes',
      render: (identificationCodes) => {
        const identificationCode = identificationCodes.find((code) => code.Actual)?.Code || identificationCodes[0]?.Code
        return identificationCode || '-'
      }
    },
    {
      title: 'ID Cliente',
      dataIndex: 'Owner[RealID]'
    },
    {
      title: 'Cliente',
      dataIndex: 'Owner',
      render: (owner) => `${owner.Name} ${owner.LastName}`
    },
    {
      title: 'Producto',
      dataIndex: 'Product.Description'
    },
    {
      title: 'Situacion',
      dataIndex: 'ObjectSituation',
      width: 160,
      render: (situation) => OBJECT_SITUATION[situation] || <div style={{ marginLeft: '20px' }}> - </div>
    },
    {
      title: 'Estado',
      dataIndex: 'ObjectStatus',
      width: 120,
      render: (_, record) => renderObjectIDStatus(record)
    },
    {
      title: (
        <span>
          Volumen m<sup>3</sup>
        </span>
      ),
      render: (object) => formatNumber((object.WidthInCm * object.LengthInCm * object.HeightInCm) / 1000000)
    },
    {
      title: 'Peso',
      dataIndex: 'WeightInGr',
      render: (n) => {
        const weight = formatNumber(n / 1000)
        return weight + ' kgs'
      }
    },
    {
      title: 'Código QR',
      width: '90px',
      dataIndex: 'IdentificationCodes',
      render: (identificationCodes) => {
        const identificationCode = identificationCodes.find((code) => code.Actual)?.Code || identificationCodes[0]?.Code
        if (identificationCode) {
          return (
            <Tooltip placement="top" title={'Ver código QR'}>
              <i style={{ display: 'flex', justifyContent: 'center' }} onClick={() => openQRModal(identificationCode)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
          )
        }
        return '-'
      }
    },
    {
      title: 'Acciones',
      width: '90px',
      render: (_) => (
        <>
          <Tooltip title="Ver Historial de cambios">
            <i style={{ display: 'flex', justifyContent: 'center' }} onClick={() => showObjectHistoryChanges(_.RealID)}>
              <List width="22px" height="22px" />
            </i>
          </Tooltip>
        </>
      )
    }
  ]

  const showTotal = usePaginationLabel(pagination.total, 'objetos')

  const currentPagination = {
    pageSize: params.Limit,
    total: pagination.total,
    current: pagination.current,
    showTotal
  }

  return (
    <>
      <div className="animation fadeIn">
        <ActionBar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%'
            }}>
            <ActionBarButton action={handleReturnButton} description="Volver" icon="arrow-left" />
          </div>
        </ActionBar>
        <Table
          columns={columns}
          dataSource={objects}
          loading={loadingObjects}
          pagination={currentPagination}
          style={{ overscrollX: 'auto' }}
          scroll={{ x: true }}
          rowKey="ID"
          onChange={handleChange}
        />
      </div>
    </>
  )
}

export default LocationObjects
