import types from '../types/BillingTable'

const LIMIT = 10

const initialRoot = {
  billings: [],
  loadingBillings: false,
  params: {
    limit: LIMIT,
    offset: 0,
    column: 'id',
    order: 'desc'
  },
  pagination: {
    current: 1,
    total: 0
  },
  searchFilters: [{ key: 'user_id', text: '' }],
  sorter: {
    order: 'descend',
    field: 'id',
    columnKey: 'id'
  },
  filter: {}
}

function billingTableReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.CLEAN_FILTERS: {
      return {
        ...state,
        params: initialRoot.params,
        searchFilters: initialRoot.searchFilters,
        filter: initialRoot.filter,
        pagination: initialRoot.pagination
      }
    }
    case types.FETCH_BILLINGS_REQUEST:
      return {
        ...state,
        billings: [],
        loadingBillings: true,
        error: ''
      }
    case types.FETCH_BILLINGS_SUCCESS:
      return {
        ...state,
        billings: action.payload.billings,
        loadingBillings: false,
        params: action.payload.params,
        pagination: {
          ...state.pagination,
          total: action.payload.quantity
        }
      }
    case types.FETCH_BILLINGS_FAILURE:
      return {
        ...state,
        loadingBillings: false,
        error: action.payload.error
      }
    case types.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      }
    }
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case types.SET_SORTER: {
      return {
        ...state,
        sorter: action.payload.sorter
      }
    }
    case types.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.searchFilters
      }
    }
    default:
      return state
  }
}

export default billingTableReducer
