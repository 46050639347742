import React from 'react'
import { Modal } from 'antd'
import deleteOriginActions from '../actionCreator/deleteOrigin'
import { DeleteOriginState } from '../types/deleteOrigin'
import styles from './DeleteOrigin.module.scss'

export interface DeleteOriginModalProps {
  deleteOriginState: DeleteOriginState
  deleteOriginActions: typeof deleteOriginActions
}

const DeleteOriginComponent = (props: DeleteOriginModalProps) => {
  const { deleteOriginActions, deleteOriginState } = props
  const { open, error, sirenaSource, id, isLoading, channel } = deleteOriginState
  const { cancelDeleteOriginModal, deleteOrigin } = deleteOriginActions

  return (
    <Modal
      title="Eliminar origen"
      visible={open}
      onOk={() => deleteOrigin({ id })}
      onCancel={() => cancelDeleteOriginModal()}
      okText="Eliminar"
      cancelText="Cancelar"
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading }}
      confirmLoading={isLoading}
      closable={!isLoading}
      maskClosable={!isLoading}
      className={styles.modal}>
      <div>
        <span className={styles.inlineText}>
          Desea eliminar el origen de Sirena<strong> {sirenaSource} </strong>
          asociado al canal<strong> {channel} </strong>?
        </span>
      </div>
      {error !== '' ? <div className={styles.error}>{error}</div> : null}
    </Modal>
  )
}

export default DeleteOriginComponent
