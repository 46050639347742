import * as React from 'react'
import { TableActionBar } from '../../../components/TableActionBar'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { ResponseLeadHistory } from '../../../projectApi/Mercurio/Prospects/getHistory'
import { parseDate } from '../../../components/DynamicTable/utils/utils'
import { changeSpan, determinateIfValueChange } from '../utils/determineValueChange'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { Country } from '../../../components/CountrySelector/constants/constants'

export interface LeadHistoryProps {
  leadHistory: ResponseLeadHistory[]
  handleReturn: () => void
  fetchingLead: boolean
  countries: Country[]
  processLastLead: boolean
}

const LeadHistoryTable = ({
  leadHistory,
  handleReturn,
  fetchingLead,
  processLastLead,
  countries
}: LeadHistoryProps) => {
  const columns: Column<ResponseLeadHistory>[] = [
    {
      key: 'prospectId',
      label: 'ID Lead'
    },
    {
      key: 'country',
      label: 'País',
      renderDataCell: ({ country }) => {
        const flag = getCurrentCountry(countries, country, 'fullName')
        if (!flag) return null
        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'firstName',
      label: 'Nombre',
      renderDataCell: ({ firstName }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['firstName'] }) ? changeSpan(firstName) : firstName
    },
    {
      key: 'lastName',
      label: 'Apellido',
      renderDataCell: ({ lastName }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['lastName'] }) ? changeSpan(lastName) : lastName
    },
    {
      key: 'email',
      label: 'Email',
      renderDataCell: ({ email }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['email'] }) ? changeSpan(email) : email
    },
    {
      key: 'phone',
      label: 'Teléfono',
      renderDataCell: ({ phone }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['phone'] }) ? changeSpan(phone) : phone
    },
    {
      key: 'statusUpdatedAt',
      label: 'Fecha de creación',
      minWidth: 'max-content',
      renderDataCell: ({ statusUpdatedAt }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['statusUpdatedAt'] })
          ? changeSpan(parseDate(statusUpdatedAt))
          : parseDate(statusUpdatedAt)
    },
    {
      key: 'createdAt',
      label: 'Fecha de actualización',
      minWidth: 'max-content',
      renderDataCell: ({ createdAt }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['createdAt'] })
          ? changeSpan(parseDate(createdAt))
          : parseDate(createdAt)
    },
    {
      key: 'assignedAgent',
      label: 'Agente asignado',
      minWidth: 'max-content',
      renderDataCell: ({ assignedAgent }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['assignedAgent', 'id'] }) ? (
          changeSpan(
            assignedAgent
              ? `(${assignedAgent.id}) ${assignedAgent.firstName} ${assignedAgent.lastName}`
              : 'Agente no asignado'
          )
        ) : (
          <span>
            {assignedAgent
              ? `(${assignedAgent.id}) ${assignedAgent.firstName} ${assignedAgent.lastName}`
              : 'Agente no asignado'}
          </span>
        )
    },
    {
      key: 'state.qualified',
      label: 'Estado',
      renderDataCell: ({ state: { qualified } }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['state', 'qualified'] })
          ? changeSpan(qualified)
          : qualified
    },
    {
      key: 'winDate',
      label: 'Fecha de Lead ganado',
      minWidth: 'max-content',
      renderDataCell: ({ winDate }, index) =>
        determinateIfValueChange({ leadHistory, index, keys: ['winDate'] })
          ? changeSpan(winDate ? parseDate(winDate) : '-')
          : winDate
          ? parseDate(winDate)
          : '-'
    }
  ]

  return (
    <div>
      <TableActionBar hideBorder>
        <TableActionBar.ButtonAction iconType="arrow-left" titleButton="Volver" onClickButton={handleReturn} />
      </TableActionBar>
      <DynamicTable keyExtractor={({ id }) => id} rows={leadHistory} loading={processLastLead} columns={columns} />
    </div>
  )
}

export default LeadHistoryTable
