import { Reducer } from 'redux'
import {
  CancelSubscriptionModalAction,
  CancelSubscriptionModalState,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  SET_MODAL_CANCEL_SUBSCRIPTION
} from '../types/cancelSubscriptionModal'

const initialState: CancelSubscriptionModalState = {
  modalOpen: false,
  billingId: 0,
  error: '',
  isLoading: false
}

const EmailBilledModalReducer: Reducer<CancelSubscriptionModalState, CancelSubscriptionModalAction> = (
  state = initialState,
  action
): CancelSubscriptionModalState => {
  switch (action.type) {
    case SET_MODAL_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        modalOpen: action.payload.modalOpen,
        billingId: action.payload.billingId || 0,
        error: ''
      }
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modalOpen: false,
        billingId: 0
      }
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}

export default EmailBilledModalReducer
