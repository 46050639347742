import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import MovementsTableActionCreator from '../actionCreator/MovementsTable'
import MovementsTable, { MovementsTableProps } from '../components/MovementsTable'
import {
  MovementsTableCategoryFilter,
  MovementsTableSearchFilter,
  MovementsTableSearchKey,
  MovementsTableSort,
  Pagination
} from '../types/MovementsTable'
import {
  DEFAULT_MOVEMENTS_CATEGORY_FILTERS,
  DEFAULT_MOVEMENTS_SEARCH_FILTERS,
  DEFAULT_MOVEMENTS_SORT
} from '../reducer/MovementsTable'

const MovementsTableContainer = () => {
  const dispatch = useDispatch()

  const { getMovements, sendCSV } = bindActionCreators(MovementsTableActionCreator, dispatch)

  const { payments, pagination, loading, searchFilters, categoryFilter, sort, sendingCSV } = useSelector(
    (state) => state.Movements
  )

  const emptyPagination = { ...pagination, page: 1 }

  const handleGetMovements = (newParams: {
    pagination?: Pagination
    searchFilters?: MovementsTableSearchFilter[]
    categoryFilter?: MovementsTableCategoryFilter
    sort?: MovementsTableSort
  }) => {
    getMovements({
      pagination: newParams.pagination || pagination,
      searchFilters: newParams.searchFilters || searchFilters,
      categoryFilter: newParams.categoryFilter || categoryFilter,
      sort: newParams.sort || sort
    })
  }
  useEffect(() => {
    handleGetMovements({ pagination: emptyPagination })
  }, [])

  const handlePageChange = (newPage: number) => {
    handleGetMovements({ pagination: { ...pagination, page: newPage } })
  }

  const handleSearch = (key: MovementsTableSearchKey, newValue: string) => {
    const newSearchFilters = searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetMovements({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleCategoryFilter = (newCategoryFilter: MovementsTableCategoryFilter) => {
    handleGetMovements({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleSort = (newSort: MovementsTableSort) => {
    handleGetMovements({ sort: newSort })
  }

  const handleResetFilters = () => {
    const resetPagination = emptyPagination

    getMovements({
      pagination: resetPagination,
      searchFilters: DEFAULT_MOVEMENTS_SEARCH_FILTERS,
      categoryFilter: DEFAULT_MOVEMENTS_CATEGORY_FILTERS,
      sort: DEFAULT_MOVEMENTS_SORT
    })
  }

  const handleSendCSV = () => {
    const currentParams = {
      pagination,
      searchFilters,
      categoryFilter,
      sort
    }

    sendCSV(currentParams)
  }

  const paging = {
    currentPage: pagination.page,
    pageSize: pagination.pageSize,
    total: pagination.total,
    onPageChange: handlePageChange
  }

  const props: MovementsTableProps = {
    payments,
    loading,
    sendingCSV,
    pagination: paging,
    searchFilters,
    categoryFilter,
    sort,
    handleSearch,
    handleCategoryFilter,
    handleSort,
    handleSendCSV,
    handleResetFilters
  }

  return <MovementsTable {...props} />
}

export default MovementsTableContainer
