import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import dashboardMetricsActionsCreator from '../actions/DashboardMetrics'
import { DashboardDateRangeFilter, DashboardDateRangeFilterKey } from '../types/Dashboard.types'
import { Moment } from 'moment'
import { Role } from '@/projectApi/AccountManager/Roles/getByParentId'
import DashboardComponent from '../components/Dashboard'
import { CountryIdCode } from '@/components/CountrySelector/constants/constants'
import { useLocalization } from '@/packages/localization'

const Dashboard: React.FC = () => {
  const { metrics, fetchingMetrics, fetchingAnnualMetrics, annualMetrics, dateRangeFilter } = useSelector(
    (root) => root.Dashboard
  )

  const selectedCountryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const dispatch = useDispatch()
  const { getAnnualMetrics, getMetrics } = bindActionCreators(dashboardMetricsActionsCreator, dispatch)
  const { role } = useSelector((state) => state.UserLogged.userProfile)

  const countryCode = useMemo(
    () => (selectedCountryCode === CountryIdCode.ALL ? CountryIdCode.ARGENTINA : selectedCountryCode),
    [selectedCountryCode]
  )

  const handleGetMetrics = (newParams?: { dateRangeFilter?: DashboardDateRangeFilter[] }) => {
    const actualParams = {
      dateRangeFilter: newParams?.dateRangeFilter || dateRangeFilter,
      countryCode
    }
    getMetrics(actualParams)
  }

  const handleGetAnnualMetrics = (newParams?: { dateRangeFilter?: DashboardDateRangeFilter[] }) => {
    const actualParams = {
      dateRangeFilter: newParams?.dateRangeFilter || dateRangeFilter,
      countryCode
    }

    getAnnualMetrics(actualParams)
  }

  const handleDateRangePicker = (key: DashboardDateRangeFilterKey, startDate: Moment, endDate: Moment) => {
    const currentFilter = dateRangeFilter.find((filter) => filter.key === key)

    const newDateRangeFilters = dateRangeFilter.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate,
            endDate
          }
        : filter
    )
    handleGetMetrics({ dateRangeFilter: newDateRangeFilters })

    if (!currentFilter || startDate.year() !== currentFilter.startDate.year()) {
      handleGetAnnualMetrics({ dateRangeFilter: newDateRangeFilters })
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.assign('/login')
    }
  }, [])

  const isRoleRoot = role?.id === Role.ROOT

  useEffect(() => {
    if (!isRoleRoot) return

    handleGetMetrics()
    handleGetAnnualMetrics()
  }, [countryCode])

  const userName = localStorage.getItem('name')

  const DashboardProps = {
    userName: userName || '',
    metrics,
    fetchingMetrics,
    annualMetrics,
    fetchingAnnualMetrics,
    handleDateRangePicker
  }

  const { strings } = useLocalization()

  return (
    <div className="animated fadeIn">
      {isRoleRoot ? <DashboardComponent {...DashboardProps} /> : <p>{strings.dashboardViewScreen.header.title}</p>}
    </div>
  )
}

export default Dashboard
