import moment, { Moment } from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { OperationType, Transaction, TypeTransaction } from '../../../projectApi/TransactionHandler/Transactions/list'
import { useEvents } from '../../../utils/eventEmitter'
import RemittanceModalActionCreators from '../../RemittanceModal/actionCreators'
import RemittanceModalContainer from '../../RemittanceModal/container'
import TransactionsTableActionCreators from '../actionCreators/TransactionsTable'
import TransactionsTable, { TransactionsTableProps } from '../components/TransactionsTable'
import newTripModalActionCreators from '../../Trips/actionCreators/tripModal'
import {
  FormattedForecast,
  Pagination,
  TransactionsTableCategoryFilter,
  TransactionsTableDateRangeFilter,
  TransactionsTableDateRangeKey,
  TransactionsTableSearchFilter,
  TransactionsTableSearchKey,
  TransactionsTableSort,
  TransactionsTableSortKey
} from '../types/TransactionsTable'
import { TripModalTransaction } from '../../Trips/types/tripModal'
import TripModalContainer from '../../Trips/containers/tripModal'
import { Events } from '../../../utils/eventEmitter/events'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  parseQueryParam,
  removeNullishValues,
  updateMultipleDateRangeFilters,
  updateMultipleSearchFilters,
  updateSingleSearchFilter
} from '../../../components/DynamicTable/queryParamsUtils'
import { convertArrayForGetRequests } from '../../../projectApi/utils'
import { TransactionStatusId } from '../../../projectApi/TransactionHandler/Transaction/common'
import { OperationStatus } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'

const TransactionsTableContainer = () => {
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.Transactions.table)
  const dispatch = useDispatch()
  const {
    clearSelectedTransactions,
    clearState,
    getTransactions,
    toggleSelectedTransaction,
    toggleAllTransactions,
    getTransactionMetrics,
    getTransactionsReport
  } = bindActionCreators(TransactionsTableActionCreators, dispatch)
  const openRemittanceModal = bindActionCreators(RemittanceModalActionCreators.setOpen, dispatch)
  const openNewTripModal = bindActionCreators(newTripModalActionCreators.setOpen, dispatch)

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    clientId: StringParam,
    sortField: StringParam,
    sortDirection: StringParam,
    transactionType: StringParam,
    creationDateFrom: StringParam,
    creationDateTo: StringParam,
    confirmationDateFrom: StringParam,
    confirmationDateTo: StringParam,
    reservationDateFrom: StringParam,
    reservationDateTo: StringParam,
    id: StringParam,
    operationId: StringParam,
    operationType: StringParam,
    status: StringParam
  })

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetTransactions = (newParams: {
    pagination?: Pagination
    searchFilters?: TransactionsTableSearchFilter[]
    dateRangeFilters?: TransactionsTableDateRangeFilter[]
    categoryFilter?: TransactionsTableCategoryFilter
    sort?: TransactionsTableSort
    silentLoading?: boolean
    resetSelectedTransactions?: boolean
    conflict?: boolean
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      sort: newParams.sort || tableState.sort,
      silentLoading: newParams.silentLoading,
      resetSelectedTransactions: newParams.resetSelectedTransactions,
      conflict: newParams.conflict ?? tableState.conflict
    }
    getTransactions(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      sortDirection: actualParams.sort.direction,
      sortField: actualParams.sort.field,
      transactionType: actualParams.categoryFilter.transactionType.join(),
      status: convertArrayForGetRequests(actualParams.categoryFilter.status as TransactionStatusId[]),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handleGetMetrics = (newParams: { silentLoading?: boolean; countryCode: CountryIdCode }) => {
    getTransactionMetrics({ silentLoading: newParams.silentLoading, countryCode })
  }

  useEvents(Events.Trips.TRIP_CREATED, () => {
    handleGetTransactions({ silentLoading: true, resetSelectedTransactions: true })
    handleGetMetrics({ silentLoading: true, countryCode })
  })

  useEffect(() => {
    handleGetTransactions({
      pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: TransactionsTableSearchKey.CLIENT_ID, text: query.clientId },
        { key: TransactionsTableSearchKey.ID_TX, text: query.id },
        { key: TransactionsTableSearchKey.ID_OP, text: query.operationId }
      ]),
      categoryFilter: {
        ...tableState.categoryFilter,
        transactionType: query.transactionType
          ? ([query.transactionType] as TypeTransaction[])
          : tableState.categoryFilter.transactionType,
        status: query.status
          ? (parseQueryParam(query.status, 'number') as TransactionStatusId[])
          : tableState.categoryFilter.status
      },
      sort:
        query.sortField && query.sortDirection
          ? {
              ...tableState.sort,
              field: query.sortField as TransactionsTableSortKey,
              direction: query.sortDirection as SortDirection
            }
          : tableState.sort,
      resetSelectedTransactions: true,
      conflict: tableState.conflict,
      dateRangeFilters: updateMultipleDateRangeFilters(tableState.dateRangeFilters, [
        {
          key: TransactionsTableDateRangeKey.CREATION_DATE,
          startDate: query.creationDateFrom,
          endDate: query.creationDateTo
        },
        {
          key: TransactionsTableDateRangeKey.CONFIRMATION_DATE,
          startDate: query.confirmationDateFrom,
          endDate: query.confirmationDateTo
        },
        {
          key: TransactionsTableDateRangeKey.RESERVATION_DATE,
          startDate: query.reservationDateFrom,
          endDate: query.reservationDateTo
        }
      ])
    })

    handleGetMetrics({ countryCode })

    return () => {
      clearState()
    }
  }, [countryCode])

  const handlePageChange = (newPage: number) => {
    handleGetTransactions({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleRangePicker = (key: TransactionsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetTransactions({ dateRangeFilters: newRangeFilters, pagination: emptyPagination, conflict: false })
  }

  const handleSearch = (key: TransactionsTableSearchKey, newValue: string) => {
    const newSearchFilters = updateSingleSearchFilter(tableState.searchFilters, key, newValue)

    handleGetTransactions({ searchFilters: newSearchFilters, pagination: emptyPagination, conflict: false })
  }

  const handleCategoryFilter = (newCategoryFilter: TransactionsTableCategoryFilter) => {
    handleGetTransactions({ categoryFilter: newCategoryFilter, pagination: emptyPagination, conflict: false })
  }

  const handleSort = (newSort: TransactionsTableSort) => {
    handleGetTransactions({ sort: newSort })
  }

  const handleResetTableFilters = ({ conflict }: { conflict?: boolean }) => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetRangeFilters = tableState.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))
    const resetSort: TransactionsTableSort = { direction: SortDirection.DESC, field: 'ID' }
    const resetPagination = emptyPagination

    handleGetTransactions({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters,
      dateRangeFilters: resetRangeFilters,
      categoryFilter: { status: [], transactionType: [], operationType: [] },
      conflict: conflict ?? false
    })
  }

  const handleToggleTransaction = (tx: Transaction) => toggleSelectedTransaction(tx)
  const handleOpenRemittanceModal = (transactionId: number) => {
    openRemittanceModal(true, transactionId)
  }

  const handleResetAllFilters = () => {
    handleResetTableFilters({})
  }

  const handleToggleConflict = () => {
    // all filters are to be reset, except for conflict
    handleResetTableFilters({ conflict: !tableState.conflict })
  }

  const transactionsDataMap = useMemo(() => {
    return tableState.selectedTransactions.reduce(
      (dataMap, currentTx): FormattedForecast => {
        if (currentTx.operation.operationCoreType === OperationType.DEPOSIT) {
          return {
            ...dataMap,
            objects: {
              ...dataMap.objects,
              totalObjects:
                dataMap.objects.totalObjects +
                currentTx.forecastMetrics.quantityObjects +
                currentTx.forecastMetrics.quantityItems,
              totalObjectsM3:
                dataMap.objects.totalObjectsM3 +
                currentTx.forecastMetrics.quantityM3Items +
                currentTx.forecastMetrics.quantityM3Objects
            },
            deposits:
              currentTx.operation.status !== OperationStatus.VALIDATED
                ? {
                    ...dataMap.deposits,
                    transactions: dataMap.deposits.transactions + 1,
                    items: dataMap.deposits.items + currentTx.forecastMetrics.quantityItems,
                    itemsM3: dataMap.deposits.itemsM3 + currentTx.forecastMetrics.quantityM3Items
                  }
                : {
                    ...dataMap.deposits,
                    transactions: dataMap.deposits.transactions + 1,
                    objects: dataMap.deposits.objects + currentTx.forecastMetrics.quantityObjects,
                    objectsM3: dataMap.deposits.objectsM3 + currentTx.forecastMetrics.quantityM3Objects
                  }
          }
        }
        if (currentTx.operation.operationCoreType === OperationType.REMOVAL) {
          return {
            ...dataMap,
            objects: {
              ...dataMap.objects,
              totalObjects: dataMap.objects.totalObjects + currentTx.forecastMetrics.quantityObjects,
              totalObjectsM3: dataMap.objects.totalObjectsM3 + currentTx.forecastMetrics.quantityM3Objects
            },
            removals: {
              ...dataMap.removals,
              transactions: dataMap.removals.transactions + 1,
              objects: dataMap.removals.objects + currentTx.forecastMetrics.quantityObjects,
              objectsM3: dataMap.removals.objectsM3 + currentTx.forecastMetrics.quantityM3Objects
            }
          }
        }
        return dataMap
      },
      {
        deposits: { transactions: 0, objects: 0, items: 0, itemsM3: 0, objectsM3: 0 },
        removals: { transactions: 0, objects: 0, items: 0, itemsM3: 0, objectsM3: 0 },
        objects: { totalObjects: 0, totalObjectsM3: 0 }
      }
    )
  }, [tableState.selectedTransactions])

  const handleOpenTripModal = () => {
    const mappedTransactions: TripModalTransaction[] = tableState.selectedTransactions.map((tx) =>
      transactionToTripTransaction(tx)
    )

    openNewTripModal({
      open: true,
      transactions: mappedTransactions
    })
  }

  const handleReport = () => {
    getTransactionsReport({
      pagination: tableState.pagination,
      dateRangeFilters: tableState.dateRangeFilters,
      searchFilters: tableState.searchFilters,
      categoryFilter: tableState.categoryFilter,
      sort: tableState.sort,
      conflict: tableState.conflict
    })
  }

  const props: TransactionsTableProps = {
    transactions: tableState.transactions,
    loadingTransactions: tableState.loadingTransactions,
    loadingReport: tableState.loadingReport,
    selectedTransactions: tableState.selectedTransactions,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    dateRangeFilters: tableState.dateRangeFilters,
    sort: tableState.sort,
    noCountrySelected: !countryCode.length,
    metrics: tableState.metrics,
    loadingMetrics: tableState.loadingMetrics,
    totalForecast: transactionsDataMap,
    error: tableState.error,
    conflict: tableState.conflict,
    setConflict: handleToggleConflict,
    handleResetFilters: handleResetAllFilters,
    handleToggleTransaction,
    handleSort,
    handleSearch,
    handleCategoryFilter,
    handleRangePicker,
    handleClearSelectedTransactions: clearSelectedTransactions,
    handleOpenTripModal,
    handleToggleAllTransactions: toggleAllTransactions,
    handleOpenRemittanceModal,
    handleReport
  }

  return (
    <>
      <TransactionsTable {...props} />
      <RemittanceModalContainer />
      <TripModalContainer />
    </>
  )
}

export default TransactionsTableContainer

export const transactionToTripTransaction = (tx: Transaction): TripModalTransaction => ({
  guid: tx.guid,
  quantityObjects: tx.forecast.elements,
  transactionType: tx.type.id,
  id: tx.id,
  statusId: tx.status.id,
  creationDate: moment(tx.creationDate),
  reservationDate: moment(tx.reservation.date),
  destinationAddress: tx.destination,
  client: {
    id: tx.client.id,
    name: tx.client.name,
    lastName: tx.client.lastName,
    phone: tx.client.phone
  },
  totalM3: tx.forecast.m3,
  operation: {
    id: tx.operation.id,
    coreId: tx.operation.operationCoreId,
    coreType: tx.operation.operationCoreType,
    operationCoreId: tx.operation.operationCoreId,
    operationCoreType: tx.operation.operationCoreType,
    status: tx.operation.status,
    userId: tx.operation.userId,
    countryId: tx.operation.countryId
  },
  contents: {
    itemsOrObjects: tx.forecast.elements,
    m3: tx.forecast.m3
  }
})
