export type RemovalValidationMetric = {
  id: string
  metric: string
  quantity: number
  description?: string
  tooltipIconColor?: string
}

export type RemovalValidationMetricsState = {
  quantityMetrics: RemovalValidationMetric[]
  fetchingMetrics: boolean
}

export enum GetRemovalValidationMetricsTypes {
  GET_REMOVALS_VALIDATION_METRICS_REQUEST = 'REMOVALS_VALIDATION/METRICS/GET_REMOVALS_VALIDATIONMETRICS_REQUEST',
  GET_REMOVALS_VALIDATION_METRICS_SUCCESS = 'REMOVALS_VALIDATION/METRICS/GET_REMOVALS_VALIDATIONMETRICS_SUCCESS',
  GET_REMOVALS_VALIDATION_METRICS_FAILURE = 'REMOVALS_VALIDATION/METRICS/GET_REMOVALS_VALIDATIONMETRICS_FAILURE'
}

export interface GetRemovalValidationMetricsRequest {
  type: typeof GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_REQUEST
}

export interface GetRemovalValidationMetricsSuccess {
  type: typeof GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_SUCCESS
  payload: {
    quantityMetrics: RemovalValidationMetric[]
  }
}

export interface GetRemovalValidationMetricsFailure {
  type: typeof GetRemovalValidationMetricsTypes.GET_REMOVALS_VALIDATION_METRICS_FAILURE
}

type GetRemovalValidationMetricsActions =
  | GetRemovalValidationMetricsRequest
  | GetRemovalValidationMetricsSuccess
  | GetRemovalValidationMetricsFailure

export type RemovalValidationMetricsActions = GetRemovalValidationMetricsActions
