import types from '../types/locationObjects'
import { URL_BASE_OBJECTS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'

const actions = {
  getObjects: (params) => {
    return (dispatch) => {
      dispatch({ type: types.FETCH_OBJECTS_REQUEST })
      return apiPrivate
        .get(URL_BASE_OBJECTS + '/object', { params: { ...params, country_code: undefined } })
        .then((response) => {
          const objects = response.data.description.objects
          const quantity = response.data.description.quantity

          dispatch(actions.getObjectsSuccess(objects, params, quantity))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.FETCH_OBJECTS_FAILURE, dispatch)
        })
    }
  },

  getObjectsSuccess: (objects, params, quantity) => {
    return {
      type: types.FETCH_OBJECTS_SUCCESS,
      payload: { objects, params, quantity }
    }
  },

  setPagination: (pagination) => ({
    type: types.SET_PAGINATION,
    payload: { pagination }
  }),
  setSorter: (sorter) => {
    return { type: types.SET_SORTER, payload: { sorter } }
  },
  setFilter: (filter) => {
    return { type: types.SET_FILTER, payload: { filter } }
  },
  setSearchFilters: (searchFilters) => {
    return { type: types.SET_SEARCH_FILTERS, payload: { searchFilters } }
  }
}

export default actions
