import { Col, Modal, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './CreateComplaintModal.module.scss'
import InputGroup from '@/components/InputGroup'
import Select from '@/components/Select'
import { InputTitle } from '@/components/NewCard/NewCard'
import { Complaint, ComplaintType, Priority } from '../types/createComplaintModal'
import useDebounce from '@/utils/hooks/useDebounce'
import RequiredField from '@/components/RequiredField/RequiredField'
import TextAreaInput from '@/components/TextAreaInput'
import { Input } from '@/components/UploadPhotos'
import { User } from '@/projectApi/AccountManager/Users/common'
import { FormattedObject } from '@/projectApi/ObjectAdministration/common'
import { Agent } from '@/projectApi/Mercurio/BO/Agents/common'
import { useLocalization } from '@/packages/localization'
import InputFieldWithError from '@/components/InputFieldWithError/inputFieldWithError'
import { generateOptions, getSelectedOption, handleRoleType } from '../utils/generateSelectOptions'
import { Trash } from '@/icons'
import { ComplaintsReasons } from '@/projectApi/Complaints/reasons'

type CreateClaimModalProps = {
  handleClose: () => void
  handleConfirm: () => void
  setOperationId: (operationId: number) => void
  setComplaintType: (type: keyof typeof ComplaintType) => void
  setComplaintReason: (reasonId: number) => void
  setComplaintDescription: (description: string) => void
  setAgent: (assignedUserId: number | null) => void
  setPriority: (priority: keyof typeof Priority) => void
  setObjects: (objects: number[]) => void
  setUser: (userId: number) => void
  getUsers: ({ userId, name }: { userId: string; name: string }) => void
  getObjectById: ({ objectId, userId }: { objectId?: number; userId: number }) => void
  getAgents: () => void
  open: boolean
  users: User[]
  fetchingUsers: boolean
  fetchingObject: boolean
  object: FormattedObject[]
  agents: Agent[]
  fetchingAgents: boolean
  complaint: Complaint
  reasons: ComplaintsReasons[]
  getOperationById: ({ operationId }: { operationId: number }) => void
  deleteEvidence: (index: number) => void
}

const priorityOptions = generateOptions(Priority)
const operationTypeOptions = generateOptions(ComplaintType)

const CreateComplaintModalComponent = ({
  open,
  handleClose,
  handleConfirm,
  setComplaintDescription,
  setComplaintReason,
  setComplaintType,
  setObjects,
  setAgent,
  setPriority,
  users,
  fetchingUsers,
  setUser,
  getUsers,
  getObjectById,
  fetchingObject,
  object,
  agents,
  fetchingAgents,
  complaint,
  reasons,
  getOperationById,
  deleteEvidence
}: CreateClaimModalProps) => {
  const { assignedUserId, reasonId, complaintType, description, objects, operationId, priority, userId } = complaint

  const { strings } = useLocalization()

  const [objectIdValue, setObjectIdValue] = useState('')
  const [agentName, setAgentName] = useState('')
  const [selectedObjects, setSelectedObjects] = useState<FormattedObject[]>([])
  const debouncedObjectIdValue = useDebounce(objectIdValue, 500)
  const [userSearchValue, setUserSearchValue] = useState(userId ? userId.toString() : '')
  const debouncedUserSearchValue = useDebounce(userSearchValue, 500)
  const [descriptionLocal, setDescriptionLocal] = useState('')
  const debouncedDescriptionValue = useDebounce(descriptionLocal, 500)
  const [operationSearchValue, setOperationSearchValue] = useState('')
  const debouncedOperationSearchValue = useDebounce(operationSearchValue, 700)

  useEffect(() => {
    setComplaintDescription(debouncedDescriptionValue)
  }, [debouncedDescriptionValue])

  useEffect(() => {
    if (debouncedOperationSearchValue) {
      getOperationById({ operationId: parseInt(debouncedOperationSearchValue) })
    }
  }, [debouncedOperationSearchValue])

  useEffect(() => {
    if (debouncedUserSearchValue) {
      const isNumeric = /^\d+$/.test(debouncedUserSearchValue)
      getUsers({
        userId: isNumeric ? debouncedUserSearchValue : '',
        name: isNumeric ? '' : debouncedUserSearchValue
      })
    }
  }, [debouncedUserSearchValue])

  useEffect(() => {
    if (userId) {
      setUserSearchValue(userId.toString())
      getUsers({ userId: userId.toString(), name: '' })
    }
  }, [userId])

  useEffect(() => {
    if (operationId) {
      setOperationSearchValue(operationId.toString())
    }
  }, [operationId])

  useEffect(() => {
    const objectId = debouncedObjectIdValue ? parseInt(debouncedObjectIdValue) : undefined
    getObjectById({ objectId, userId })
  }, [debouncedObjectIdValue, userId])

  const agentsOptions = useMemo(
    () =>
      agents.map((agent) => ({
        value: agent.userId,
        label: `${agent.userId} - ${agent.firstName} ${agent.lastName} - ${handleRoleType(agent.role.roleId)}`
      })),
    [agents]
  )

  const userOptions = useMemo(() => {
    if (!users) return []
    return users.map((user) => ({
      label: `${user.realId} - ${user.name} ${user.lastName}`,
      value: user.realId
    }))
  }, [users])

  const objectsOption = useMemo(() => {
    if (!object) return []
    return object.map((obj) => ({
      label: `${obj.realId} - ${obj.product.description} - ${obj.owner.name} ${obj.owner.lastName}`,
      value: obj.realId
    }))
  }, [object])

  const complaintReasons = useMemo(() => {
    if (!reasons) return []
    return reasons
      .filter((reason) => reason.type === 'COMPLAINTS')
      .map((reason) => ({
        label: ` ${reason.description}`,
        value: reason.id
      }))
  }, [reasons])

  const isDisabled = !userId || !reasonId || !description || !assignedUserId || !priority

  const reasonsDisabled = complaintReasons.length === 0

  const handleLocalClear = () => {
    setSelectedObjects([])
    setUserSearchValue('')
    setDescriptionLocal('')
    setOperationSearchValue('')
  }

  return (
    <Modal
      width={'max-content'}
      onCancel={() => {
        handleClose()
        handleLocalClear()
      }}
      title={strings.ComplaintsViewScreen.createComplaintModal.title}
      visible={open}
      okText={strings.generic.confirm}
      cancelText={strings.generic.cancel}
      onOk={() => {
        handleConfirm()
        handleLocalClear()
      }}
      className={styles.modal}
      bodyStyle={{ width: '700px' }}
      okButtonProps={{ disabled: isDisabled, className: styles.okBtn }}
      cancelButtonProps={{ className: styles.cancelBtn }}
      destroyOnClose>
      <Row
        gutter={[16, 16]}
        style={{ margin: 0, display: 'flex', flexDirection: 'column', gap: '8px', flexWrap: 'wrap' }}>
        <InputGroup title={strings.ComplaintsViewScreen.createComplaintModal.fields.clientGroupTitle}>
          <Row>
            <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.clientTitle} />
            <Select
              loading={fetchingUsers}
              disabled={fetchingUsers}
              selected={userOptions.find((user) => user.value === userId)}
              placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.searchClientPlaceholder}
              options={userOptions}
              autocomplete={{
                filterByInput: true,
                input: userSearchValue,
                onInputChange: (text) => setUserSearchValue(text)
              }}
              onSelect={(option) => {
                setUser(option.value)
              }}
              onDelete={() => setUser(0)}
            />
            <RequiredField />
          </Row>
        </InputGroup>
        <InputGroup
          title={strings.ComplaintsViewScreen.createComplaintModal.fields.informationGroupTitle}
          className={styles.inputGroup}>
          <Row gutter={[16, 16]}>
            <Col sm={12} xs={24}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.operationIdTitle} />
              <InputFieldWithError
                value={operationSearchValue}
                disabled={false}
                onChange={(e) => setOperationSearchValue(e.target.value)}
                className={styles.inputFields}
                placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.operationIdPlaceholder}
              />
            </Col>
            <Col sm={12} xs={24}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.typeTitle} />
              <Select
                onSelect={({ value }) => {
                  setComplaintType(value as keyof typeof ComplaintType)
                }}
                selected={getSelectedOption(operationTypeOptions, complaintType)}
                options={operationTypeOptions}
                placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.typePlaceholder}
              />
            </Col>
          </Row>
          <Row>
            <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.reasonTitle} />
            <Select
              loading={reasonsDisabled}
              disabled={reasonsDisabled}
              onSelect={({ value }) => setComplaintReason(value)}
              selected={complaintReasons.find((reason) => reason.value === reasonId)}
              options={complaintReasons}
              placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.reasonPlaceholder}
            />
            <RequiredField />
          </Row>
          <Row>
            <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.descriptionTitle} />
            <TextAreaInput
              value={descriptionLocal}
              onChange={(e) => setDescriptionLocal(e.target.value)}
              placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.descriptionPlaceholder}
            />
            <RequiredField text={strings.ComplaintsViewScreen.createComplaintModal.fields.requiredFieldDetails} />
          </Row>
          <Row>
            <Input
              fileType="objects"
              maxCount={10}
              label={strings.ComplaintsViewScreen.createComplaintModal.fields.evidence}
              handleRemovePhoto={deleteEvidence}
            />
          </Row>
        </InputGroup>
        <InputGroup title={strings.ComplaintsViewScreen.createComplaintModal.fields.objectsGroupTitle}>
          <Row>
            <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.objectTitle} />
            <Select
              loading={fetchingObject}
              disabled={fetchingObject || !userId}
              onSelect={(option) => {
                const objectExists = selectedObjects.find((obj) => obj.realId === option.value)
                if (objectExists) return
                const selectedObject = object.find((obj) => obj.realId === option.value)
                if (selectedObject) {
                  setSelectedObjects([...selectedObjects, selectedObject])
                  setObjects([...objects, selectedObject.realId])
                }
                setObjectIdValue('')
              }}
              selected={objectsOption.find(() => objects.includes(parseInt(objectIdValue)))}
              placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.objectsPlaceholder}
              options={objectsOption}
              autocomplete={{
                filterByInput: true,
                input: objectIdValue.toString(),
                onInputChange: (text) => setObjectIdValue(text)
              }}
              onDelete={() => setObjectIdValue('')}
            />

            <div className={styles.tableObject}>
              {selectedObjects
                ? selectedObjects.map((ob) => (
                    <div key={ob.realId} className={styles.itemObject}>
                      {ob.realId} - {ob.product.description} - {ob.owner.name} {ob.owner.lastName}
                      <span
                        className={styles.deleteIcon}
                        onClick={() => {
                          const filteredObjects = selectedObjects.filter((obj) => obj?.realId !== ob.realId)
                          setSelectedObjects(filteredObjects)
                        }}>
                        <Trash height="18px" width="18px" />
                      </span>
                    </div>
                  ))
                : null}
            </div>
          </Row>
        </InputGroup>

        <InputGroup title={strings.ComplaintsViewScreen.createComplaintModal.fields.priorityGroupTitle}>
          <Row gutter={[16, 16]}>
            <Col sm={12} xs={24}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.agentTitle} />
              <Select
                loading={fetchingAgents}
                disabled={fetchingAgents}
                onSelect={({ value }) => setAgent(value)}
                autocomplete={{
                  filterByInput: true,
                  input: agentName,
                  onInputChange: (text) => setAgentName(text)
                }}
                onDelete={() => setAgent(null)}
                selected={agentsOptions.find((agent) => agent.value === assignedUserId)}
                options={agentsOptions}
                placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.agentPlaceholder}
              />
              <RequiredField />
            </Col>
            <Col sm={12} xs={24}>
              <InputTitle title={strings.ComplaintsViewScreen.createComplaintModal.fields.priorityTitle} />
              <Select
                onSelect={({ value }) => setPriority(value)}
                selected={getSelectedOption(priorityOptions, priority)}
                options={priorityOptions}
                placeholder={strings.ComplaintsViewScreen.createComplaintModal.fields.priorityPlaceholder}
              />
              <RequiredField />
            </Col>
          </Row>
        </InputGroup>
      </Row>
    </Modal>
  )
}

export default CreateComplaintModalComponent
